import React,{ useState, useEffect } from 'react';
import { Box, Tabs, Tab } from '@material-ui/core';
import MetaTag from '../../meta_tag/meta_tag';
import { Button, DatePicker, Icon, Input, message, Typography } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import ButtonGroup from 'antd/lib/button/button-group';
import {history} from '../../../store/history'
import Table from './table';
import endorsementServices from './endorsementServices';
import AddModal from './addModal';
import moment from 'moment';
import { jsonToCSV } from 'react-papaparse';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{padding: 0}} p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const StatusChange = () => {
  const [dateRange, setDateRange] = React.useState({startDate: '', endDate: ''});
  const [exportBtnStyle, setExportBtnStyle] = React.useState({color: '#1DD28B', backgroundColor: 'transparent'})
  const user = localStorage.getItem('userType');
  const [selected, setSelected] = React.useState('All');
  const [tableData, setTableData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [addModalShow, setAddModalShow] = React.useState(false);
  const userType = localStorage.getItem('userType');
  const [searchVal, setSearchVal] = React.useState(null);
  const [visibleClearBtn, setVisibleClearBtn] = React.useState(false);

  const ButtonTab = ({rootStyle}) => {
    
    const status = ['All', 'Pending', 'Approved', 'Rejected'];
    
    const handleClick = (event) => {
      setSelected(event.target.value);
    }
    
    return (
      <div style={rootStyle}>
        <ButtonGroup>
          {
            status.map((btn, i) => {
              return (
                <Button
                  key={btn}
                  value={btn}
                  onClick={handleClick}
                  style={{...styles.btnGroup, 
                    color: btn === selected? '#FFF' : '#2B2D33', 
                    backgroundColor: btn === selected? '#0D3D76' : '#FFF',
                    borderRadius: i === 0? '10px 0 0 10px' : i === 3? '0 10px 10px 0' : 0 }}
                    >
                    {btn}
                </Button>
              )
            })
          }
        </ButtonGroup>
      </div>
    )
  }

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }

  const rangeSelect = (dates, dateString, id) => {
    setDateRange({...dateRange, [id]: moment(dateString).format('MM-DD-YYYY')});
  }

  const errorPrompt = (msg) => {
    message.error(msg);
  };

  const getData = async (search, type, start, end) => {
    setIsLoading(true);
    try {
      const res = await endorsementServices.getEndorsements(search, type, start, end);

      if(res.data) {
        setTableData(res.data);
      } 
       else {
        errorPrompt(res);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      errorPrompt('Please try again');
    }
  }

  const onHover = () => {
    setExportBtnStyle({
      color: '#FFF',
      backgroundColor: '#1DD28B'
    });
  }

  const onMouseLeave = () => {
    setExportBtnStyle({
      color: '#1DD28B',
      backgroundColor: 'transparent'
    });
  }

  const handleSearch = (e) => {
    setSearchVal(e.target.value);
  }

  const onFilterApply = () => {
    getData(searchVal, selected, dateRange.startDate, dateRange.endDate);
    setVisibleClearBtn(true);
  }

  const onExport = async () => {
    let arr = new Array;

    tableData&&tableData.map((item,i) => {
      arr.push({
        "Tranx Id": item.approval_id,
        "Ref No.": item.ref_no? item.ref_no : 'None',
        "Current / Prev Status": returnStatus(item.previous_status),
        "Target / New Status": returnStatus(item.target_status),
        "Transaction Type": item.transaction_type === 'PO'? 'Payout' : 'Payment Request',
        "Amount": 'PHP ' + item.amount,
        "Endorser": item.endorser,
        "Approver": item.approver? item.approver : 'None',
        "Approval Status": returnStatus(item.approval_step),
        "Date Created": moment(item.created_at).format('lll'),
      })
    });

    const csv = jsonToCSV(arr);
    const blob = new Blob([csv]);
		const a = window.document.createElement("a");
	    a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
	    a.download = 'Status_Endorsements.csv';
	    document.body.appendChild(a);
	    a.click();
	    document.body.removeChild(a);
  }

  const returnStatus = (key) => {
    switch (key) {
      case 'PE':
        return 'Pending';
      case 'PA':
        return 'Paid';
      case 'FA':
        return 'Failed';
      case 'SU':
        return 'Success';
      case 'CA':
        return 'Cancelled';
      case 'EX':
        return 'Expired';
      case 'RE':
        return 'Rejected';
      case 'AP':
        return 'Approved';
      default:
        return key;
    }
  }

  const redirectPage = () => {
      if(localStorage.getItem("jwtToken") === "" || !localStorage.getItem("jwtToken")){
        history.push('/login')
      }
      else if(['ME', 'CO'].includes(localStorage.getItem("userType"))){
        history.push('/dashboard')
      }
      else if(['PS', 'PF'].includes(localStorage.getItem("userType"))){
        history.push('/submissions_kyb')
      }
  }

  const resetFilters = () => {
    setDateRange({startDate: '', endDate: ''});
    setSearchVal('');
    setVisibleClearBtn(false);
    getData('', selected, '', '');
  }

  React.useEffect(() => {
    redirectPage();
    resetFilters();
  },[selected])

  return (
    <div style={{marginLeft: '-30px'}}>
      <MetaTag title="Payment/Payout Endorsement" />
      <div style={styles.titleContainer}>
        <Typography style={styles.title}>Endorsement</Typography>
        <div style={styles.btnContainer}>
          <ButtonTab />
          <div>
            <Button style={{...styles.exportBtn, ...exportBtnStyle}} onMouseOver={onHover} onMouseLeave={onMouseLeave} onClick={onExport} ><Icon type='download' /> Export CSV</Button>
            <Button style={styles.addBtn} onClick={() => setAddModalShow(true)} hidden={!['AD', 'AP', 'BS', 'EN'].includes(userType)}><Icon type="plus" />Add New</Button>
          </div>
        </div>
      </div>
      <div style={styles.body}>
      <div style={styles.subContainer}>
        <div style={styles.clearDiv}>
          <Typography style={styles.filterText}>Filter</Typography>
          <a style={styles.clearBtnDiv} hidden={!visibleClearBtn} onClick={resetFilters}>
            <Icon style={styles.clearIcon} type="close-circle" theme="filled" />
            <span style={styles.clearBtn}>Clear Filter</span>
          </a>
        </div>
        <Typography style={styles.reminder}><Icon type="exclamation-circle" theme="filled" /> Click <b>Apply</b> button to show results.</Typography>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end', padding: '0 0 24px 0'}}>
        <DatePicker 
          format='MM/DD/YYYY' 
          disabledDate={disabledDate} 
          // defaultValue={moment()}
          value={dateRange.startDate && moment(dateRange.startDate)} 
          size="large" picker="month" 
          onChange={ (dates, dateString) => rangeSelect(dates, dateString, 'startDate')} 
          placeholder='Start date' 
          style={styles.datePic} 
          suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{fontSize: 22}} /></div>}
          allowClear={false}/>
          <span style={{paddingTop: 8}}>-</span>
        <DatePicker 
          format='MM/DD/YYYY' 
          disabledDate={disabledDate} 
          // defaultValue={moment()}
          value={dateRange.endDate && moment(dateRange.endDate)} 
          size="large" 
          picker="month" 
          onChange={ (dates, dateString) => rangeSelect(dates, dateString, 'endDate')} 
          placeholder='End date' 
          style={styles.datePic}
          suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{fontSize: 22}} /></div>}
          allowClear={false}/>
        <Input
            placeholder="Search"
            style={{ width: '200px', height: '40px', borderRadius:'4px', padding: '0 8px 0 0px' }}
            suffix={<Icon type="search" style={{fontSize:'18px'}}/>}
            onChange={handleSearch}
            value={searchVal}
          />
        <Button style={styles.applyBtn} onClick={onFilterApply} >Apply</Button>
      </div>
        <Table 
          data={tableData} 
          onUpdate={()=>  getData('', selected, dateRange.startDate, dateRange.endDate)} 
          isLoading={isLoading} 
          // length={totalLength}
          selected={selected}
          // sort={tableSort}
          />
      </div>
      <AddModal visible={addModalShow} closeModal={() => {getData(searchVal, selected, dateRange.startDate, dateRange.endDate);  setAddModalShow(false);}} />
    </div>
  )
};

const useStyles = makeStyles({
  indicator: {
    backgroundColor: '#0D3D76',
    height: 4,
  },
  tabStyle: {
    textTransform: 'initial', 
    fontWeight: 'bold', 
    padding: '16px 0px 8px 0px',
    fontSize: 16,
  },
  spinner:{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: 500,
  }
});

const styles = {
  root: {

  },
  titleContainer: {
    padding: '42px 20px 0px 20px'
  },
  title: {
    backgroundColor: 'transparent',
    width: '100%',
    color: '#000',
    fontWeight: 'bold',
    fontSize: 28,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 8,
  },
  btnGroup: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '0 32px',
  },
  body: {
    backgroundColor: 'transparent',
    padding: '0px 16px',
    backgroundColor: '#FFF',
    border: '1px solid #E6EAF0',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    margin: '38px 20px'
  },
  addBtn: {
    color: '#FFF',
    backgroundColor: '#1DD28B',
    height: 40,
    borderRadius: 4,
    padding: '8px, 16px, 8px, 16px',
    fontSize: 16,
    fontWeight: '600'
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  datePic: {
    height: 40,
    fontSize: 14,
    color: '#2F3542',
    width: 178,
    padding: '0 8px 0 8px'
  },
  suffixIcon: {
    marginTop: '-12px', 
    marginRight: 12, 
    color: '#000'
  },
  exportBtn: {
    fontSize: 16,
    fontWeight: '600',
    padding: '8px, 16px, 8px, 16px',
    border: '1px solid #1DD28B',
    height: 40,
    marginRight: 12,
  },
  subContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 0'
  },
  filterText: {
    color: '#000',
    fontSize: 14, 
    fontWeight: 'bold'
  },
  reminder: {
    fontSize: 12,
    color: '#909196',
  },
  applyBtn: {
    height: 40,
    width: 92,
    fontSize: 16,
    fontWeight: '600',
    borderRadius: 4,
    backgroundColor: '#F5922F',
    color: '#FFF',
  },
  clearBtnDiv: {
    backgroundColor: '#E6EAF0',
    borderRadius: 24,
    padding: '4px 12px',
    margin: '0 12px'
  },
  clearBtn: {
    color: '#000000',
    fontSize: 12,
    paddingLeft: 4,
  },
  clearDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  clearIcon: {
    color: '#2B2D33',
    fontSize: 10,
  }
};

export default StatusChange;