import React from 'react';
import { Progress, Typography, Card } from 'antd';

const isHighDef = window.screen.width * window.devicePixelRatio > 1440 && window.screen.height * window.devicePixelRatio > 900;

const ChannelPercentage = (props) => {
    const { data } = props;
    const [channelData, setChannelData] = React.useState(data);

    const renderProps = (channel) => {
        switch (channel) {
            case 'otc':
                return {
                    title: 'Over-the-counter',
                    color: '#F08A34'
                };
            case 'ewallet':
                return {
                    title: 'E-wallet',
                    color: '#4993EE'
                };
            case 'web':
                return {
                    title: 'Online Banking',
                    color: '#54B763'
                };
            case 'cc':
                return {
                    title: 'Credit / Debit',
                    color: '#A83C23'
                };
            case 'billease':
                return {
                    title: 'Buy Now Pay Later',
                    color: '#EDC143'
                };
            default:
                return {
                    title: 'Over-the-counter',
                    color: '#E3632A'
                };
        }
    }

    React.useEffect(() => {
        setChannelData(data);
    },[data])

    console.log('CHANNEL', data)
    return (
        <div style={styles.root}>
            <Typography style={styles.title} hidden={channelData && !channelData.length}>Channel Percentage</Typography>
            <div style={styles.body}>
                {
                    channelData && channelData.channel_percentage && channelData.channel_percentage.map((channel, i) => {
                        return (
                            <Card
                                style={{ padding: 0, width: '18%', margin: i === 0 ? '20px 10px 20px 0' : i > data && i === data.channel_percentage.length ? '20px 0 20px 10px' : '20px 10px' }}
                                bodyStyle={styles.cardBodyStyle}
                            >
                                <Progress
                                    strokeColor={renderProps(channel.code).color}
                                    type='circle'
                                    percent={parseInt(channel.count)}
                                    style={{backgroundColor: '#FFF'}}
                                    children={{backgroundColor: '#FFF'}}
                                />
                                <Typography style={styles.subtitle}>{renderProps(channel.code).title}</Typography>
                            </Card>
                        )
                    })
                }
            </div>
        </div>
    )
}

const styles = {
    root: {
        width: isHighDef ? 1080 : 812,
    },
    body: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    title: {
        color: '#000',
        fontWeight: '700',
        fontSize: 18,
        textAlign: 'left',
    },
    subtitle: {
        color: '#2B2D32',
        fontSize: 12,
        fontWeight: '600',
        padding: '12px 0'
    },
    cardBodyStyle: {
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        display: 'flex',
        padding: '20px 14px'
    }
}

export default ChannelPercentage;