import React from "react";
import { Input, Select, Tabs, Typography } from "antd";
import { NATURE_BUSINESS } from "../../../../constants/constants";

const SubwalletTypeForm = ({
  subwalletTypeKeys,
  handleSubwalletTypeChange,
  data,
  styles,
  isLoading,
  subwalletTypes,
  handleChange,
  natureBusinessChange,
}) => {
  return (
    <Tabs
      defaultActiveKey={subwalletTypeKeys[0]}
      onChange={handleSubwalletTypeChange}
      size="small"
      tabBarGutter={0}
      tabBarStyle={{ width: "100%", textAlign: "center" }}
    >
      <Tabs.TabPane
        tab={subwalletTypes[subwalletTypeKeys[0]].label}
        key={subwalletTypeKeys[0]}
      >
        <IndividualForm
          data={data}
          handleChange={handleChange}
          isLoading={isLoading}
          styles={styles}
        />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={subwalletTypes[subwalletTypeKeys[1]].label}
        key={subwalletTypeKeys[1]}
      >
        <BranchForm
          data={data}
          handleChange={handleChange}
          isLoading={isLoading}
          styles={styles}
        />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={subwalletTypes[subwalletTypeKeys[2]].label}
        key={subwalletTypeKeys[2]}
      >
        <CompanyForm
          data={data}
          handleChange={handleChange}
          isLoading={isLoading}
          styles={styles}
          natureBusinessChange={natureBusinessChange}
        />
      </Tabs.TabPane>
    </Tabs>
  );
};

const IndividualForm = ({ handleChange, isLoading, data, styles }) => {
  return (
    <>
      <div>
        <label style={styles.labelFont}>First Name</label>
        <Input
          style={styles.input}
          name="first_name"
          value={data["first_name"]}
          placeholder="First Name"
          onChange={handleChange}
          disabled={isLoading}
        />
      </div>
      <div>
        <label style={styles.labelFont}>Last Name</label>
        <Input
          style={styles.input}
          name="last_name"
          value={data["last_name"]}
          placeholder="Last Name"
          onChange={handleChange}
          disabled={isLoading}
        />
      </div>
      <div>
        <label style={styles.labelFont}>Email</label>
        <Input
          style={styles.input}
          name="email"
          placeholder="Email"
          value={data["email"]}
          type="email"
          onChange={handleChange}
          disabled={isLoading}
        />
      </div>
      <div style={{ position: "relative" }}>
        <label style={styles.labelFont}>Mobile Number</label>
        <Input
          style={{ ...styles.input, paddingLeft: "18%", paddingTop: "4%" }}
          name="contact"
          value={data["contact"]}
          maxLength={10}
          onChange={handleChange}
          disabled={isLoading}
        />
        <Typography style={styles.mobilePrefix}>+63 | </Typography>
      </div>
    </>
  );
};

const BranchForm = ({ handleChange, isLoading, data, styles }) => {
  return (
    <>
      <div>
        <label style={styles.labelFont}>Branch Name</label>
        <Input
          style={styles.input}
          name="branch_name"
          value={data["branch_name"]}
          placeholder="Branch Name"
          onChange={handleChange}
          disabled={isLoading}
        />
      </div>
      <div>
        <label style={styles.labelFont}>Location</label>
        <Input
          style={styles.input}
          name="location"
          value={data["location"]}
          placeholder="Location"
          onChange={handleChange}
          disabled={isLoading}
        />
      </div>
      <div>
        <label style={styles.labelFont}>Authorized Person</label>
        <Input
          style={styles.input}
          name="first_name"
          value={data["first_name"]}
          placeholder="First Name"
          onChange={handleChange}
          disabled={isLoading}
        />
        <Input
          style={styles.input}
          name="last_name"
          value={data["last_name"]}
          placeholder="Last Name"
          onChange={handleChange}
          disabled={isLoading}
        />
      </div>
      <div>
        <label style={styles.labelFont}>Email</label>
        <Input
          style={styles.input}
          name="email"
          placeholder="Email"
          value={data["email"]}
          type="email"
          onChange={handleChange}
          disabled={isLoading}
        />
      </div>
      <div style={{ position: "relative" }}>
        <label style={styles.labelFont}>Mobile Number</label>
        <Input
          style={{ ...styles.input, paddingLeft: "18%", paddingTop: "4%" }}
          name="contact"
          value={data["contact"]}
          maxLength={10}
          onChange={handleChange}
          disabled={isLoading}
        />
        <Typography style={styles.mobilePrefix}>+63 | </Typography>
      </div>
    </>
  );
};

const CompanyForm = ({
  handleChange,
  isLoading,
  data,
  styles,
  natureBusinessChange,
}) => {
  return (
    <>
      <div>
        <label style={styles.labelFont}>Company Name</label>
        <Input
          style={styles.input}
          name="business_name"
          value={data["business_name"]}
          placeholder="Company Name"
          onChange={handleChange}
          disabled={isLoading}
        />
      </div>
      <div>
        <label style={styles.labelFont}>Address</label>
        <Input
          style={styles.input}
          name="address"
          value={data["address"]}
          placeholder="Address"
          onChange={handleChange}
          disabled={isLoading}
        />
      </div>
      <div>
        <label style={styles.labelFont}>Nature of Business</label>
        <Select
          style={{ width: "312px", margin: "4px 0px 12px 0px" }}
          name="nature_of_business"
          onChange={natureBusinessChange}
          value={data["nature_of_business"]}
        >
          {NATURE_BUSINESS.map((item, i) => {
            return <Select.Option value={i}>{item}</Select.Option>;
          })}
        </Select>
      </div>
      <div>
        <label style={styles.labelFont}>Authorized Person</label>
        <Input
          style={styles.input}
          name="first_name"
          value={data["first_name"]}
          placeholder="First Name"
          onChange={handleChange}
          disabled={isLoading}
        />
        <Input
          style={styles.input}
          name="last_name"
          value={data["last_name"]}
          placeholder="Last Name"
          onChange={handleChange}
          disabled={isLoading}
        />
      </div>
      <div>
        <label style={styles.labelFont}>Email</label>
        <Input
          style={styles.input}
          name="email"
          placeholder="Email"
          value={data["email"]}
          type="email"
          onChange={handleChange}
          disabled={isLoading}
        />
      </div>
      <div style={{ position: "relative" }}>
        <label style={styles.labelFont}>Mobile Number</label>
        <Input
          style={{ ...styles.input, paddingLeft: "18%", paddingTop: "4%" }}
          name="contact"
          value={data["contact"]}
          maxLength={10}
          onChange={handleChange}
          disabled={isLoading}
        />
        <Typography style={styles.mobilePrefix}>+63 | </Typography>
      </div>
    </>
  );
};

export default SubwalletTypeForm;
