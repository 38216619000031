import React from 'react'
import termsStyle from '../../static/css/Terms'
import { Button } from 'antd';
import {POLICY} from './da5_const'
import BTI_header from '../../static/img/bti_header.svg'
import { isMobile } from 'react-device-detect';

const DA5Terms = props => {
    const terms = termsStyle();
    const mainHeader = {
        fontWeight: 600,
        lineHeight: '24px',
        fontSize: '20px',
        textTransform: 'uppercase',
        fontFamily: 'Inter',
        marginBottom: '15px',
        marginTop: '15px',
    }
    return(
        <div>
            <div className={terms.btiBody}>
                <div style={{display: 'flex'}}>
                    <div style={mainHeader}>MONEY TRANSFER VIA DA5 PAYMENT SYSTEM (DAPS) IS PROVIDED ON THE FOLLOWING TERMS AND CONDITIONS</div>
                </div>
                <div className={terms.body}>
                    {
                        POLICY.map((item) => {
                            return(
                                <div>
                                    <div className={terms.title}>{item.q}</div>
                                    <div className={terms.text}>{item.d}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
        
    )

}

export default DA5Terms