import { makeStyles } from '@material-ui/core';

const faqStyles = makeStyles(theme => ({

    main: {
        margin: 0,
        width: '900px',
        padding: 32,
        backgroundColor: '#fff',
        marginBottom: 64
    },

    main1: {
        margin: 0,
        width: '900px',
        backgroundColor: '#fff',
        padding: '20px'
    },

    mainWeb: {
        [theme.breakpoints.down("sm")]: {
            border: '1px solid #E6EAF0',
            boxShadow: 'none',
            borderRadius: 'none',
        },
        [theme.breakpoints.down("xs")]: {

            padding: '30px 10px 50px 10px',

        }
        
        
    },

    mobileMain: {
        paddingBottom: '10%',
        
    },

    mainBody: {
        [theme.breakpoints.down("sm")]: {
            paddingTop: '15%',
        }
    },



    body: {
        borderRadius: '4px',
        marginTop: '30px',
        [theme.breakpoints.down("sm")]: {
            marginTop: '80px',
            
        }

    },

    head: {
        display: "flex",
        justifyContent: 'space-between'
    },

    header: {
        marginTop: '2%',
        fontWeight: '800',
        fontSize: '28px',
        color: '#000000',
        paddingLeft: '12px',
        [theme.breakpoints.down("sm")]: {
            paddingTop:'2%',
            paddingLeft: '16px',
            paddingRight: '16px',
            fontSize: '24px',
            lineHeight: '32px',
        }
    },

    mobileDiv: {
        // paddingLeft: '12px',
        // paddingRight: '12px',
        [theme.breakpoints.down("sm")]: {
            // paddingLeft: '12px',
            // paddingRight: '12px',
            marginBottom: '16px',
        },

        [theme.breakpoints.down("xs")]: {
            // paddingLeft: '2px'
        }
    },

    subHeader: {
        marginTop: '2%',
        marginBottom: '5%',
        fontWeight: '800',
        fontSize: '28px',
        color: '#000000',
        [theme.breakpoints.down("sm")]: {
            paddingTop:'2%',
            paddingLeft: '16px',
            fontSize: '16px',
        }
    },

    button: {
        color: '#F5922F',
        marginLeft:'-20px',
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        position: 'fixed',
        top: 100,
        width: '920px',
        zIndex: 1,
        borderRadius: '4px',
        marginTop: '-38px',
        paddingTop: '43px',
        paddingBottom: '20px',
       '&:hover': {
        color: '#F5922F'
       },

        [theme.breakpoints.down("sm")]: {
            left: 10,
            background: '#fff',
            paddingTop: '20px',
            paddingBottom: '20px',
            fontSize: '16px',
            paddingLeft: '32px'
        }
    },

    border: {
        borderBottom: '1px solid #E6EAF0',
    },

 

    cardButton: {
        outline: 0,
        width: '90%',
        // height: '180px',
        borderRadius: '10px',
        background: '#fff',
        border: '1px solid #e6eaf0',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.05)',
        textAlign: 'center',
        margin : 10,
        [theme.breakpoints.down("sm")]: {
            width: '90%',
            height: '100%',
            padding: '10px 16px'
        },
        [theme.breakpoints.down("xs")]: {
            width: '90%',
            height: '100%',
            padding: '10px 16px'

        },
        '&:hover': {
            background: '#F4F6F9',
            cursor: 'pointer'
        },
        display: 'flex',
        alignItems: 'center',
        padding: '20px 16px'
    },

    icons:{
        width: '35px',
        height: '35px',
    },

    cImage: {
        // marginTop: '10%',
        height: 32,
        width: 32
    },

    cText: {
        color: '#000000',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 'bold',
        margin: '0 0 0 10px',
        // marginTop: '20%',
    },

    faqHeader:{
        fontWeight: '600',
        fontSize: '20px',
    },

    question: {
        padding: '10px 0px 10px 0px',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '24px',
        color: '#000000',
        border: '#E6EAF0',
        backgroundColor: '#fff',
        [theme.breakpoints.down("sm")]: {
            fontSize: '14px',
        }
    },

    answer: {
        justifyContnent: 'left',
        fontWeight: '400',
        fontSize: '16px',
        color: '#000000',
        [theme.breakpoints.down("sm")]: {
            fontSize: '12px',
        }
    },
    
    signUpNow: {
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        borderRadius: '50px',
        margin: '10px 10px 0px 0px',
        height: '40px'
    },

}))

export default faqStyles

