import React from 'react';
import { Grid,TextField } from "@material-ui/core";
import {Alert, Input, Spin, Modal, } from 'antd'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Visibility from '@material-ui/icons/Visibility';
import accountStyle from '../../../static/css/Account';
import profile from "../../../static/icons/profile.svg";
import {Handle401} from '../../handle401/handle401';
import settingServices from '../settingServices';

const ChangePassword = props => {
   
    const acct = accountStyle();

    const [visible, setVisible] = React.useState(true);
    const [newPassword, setNewPassword] = React.useState("");
    const [oldPassword, setOldPassword] = React.useState("");
    const [alert, setAlert] = React.useState(true);
    const [showOldPassword, setShowOldPassword] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [status, setStatus] = React.useState("");

    function toggle () {

        setVisible(!visible)
        setNewPassword('');
        setOldPassword('');
        setAlert(true)
        
    }

    const changePass = () => {

        if(confirmPassword == newPassword){
            if(newPassword.length < 7){
                setAlert(false)
                setMessage("Password must contain atleast 7 characters")
            }
            else if(newPassword.search(/^[a-zA-Z]+$/) != -1){
                setAlert(false)
                setMessage("Password must contain atleast 1 number")

            }
            else if(newPassword.search(/^[a-z0-9]+$/) != -1){
                setAlert(false)
                setMessage("Password must contain atleast 1 capital letter")

            }
            else if(newPassword.search(/^[A-Z0-9]+$/) != -1){
                setAlert(false)
                setMessage("Password must contain atleast 1 small letter")
                
            }

            else{            
                let params = {
                    old_password: oldPassword,
                    new_password: newPassword,
                }
        
                changeUserPassword(params)
            }
        }
        else{
            setAlert(false)
            setMessage("Password does not match")
        }

    }

    async function changeUserPassword(value) {
        try{
            let res = await settingServices.changePassword(value);
            if(res.status === "success"){
                setMessage(res.message)
                setStatus(res.status)
                Handle401()
            }
            else{
                setAlert(false)
                setStatus(res.status)
                setMessage(res.message)
            }
        }
        catch(error){
            console.error(error);
        }
        
        
    }

    return (
        <Grid container>
        <Grid item lg={4} xs={12}>
            <div className={acct.piHeader} style={{color: '#000000'}}>
                Password: 
            </div> 
        </Grid>
        <Grid item lg={4} xs={12}>
            <div className={acct.piText}>
                ***********
            </div> 
        </Grid>
        <Grid item lg={4} xs={12}>
            <div className={acct.password}>
                <a onClick={toggle} style={{color: '#f5922f'}}>Change</a>
            </div> 
        </Grid>

        <Grid container hidden={visible} className={acct.personalPadding}>
            
            <Grid item lg={12}>

            <div hidden={alert} style={{width: '100%'}} >
                <Alert message={message} type={status} style={{marginBottom: '20px'}} showIcon/>
            </div>

            </Grid>

            <Grid item lg={12} xs={12}>
                <div>
                    <TextField
                        id="standard-basic"
                        onChange={e => setOldPassword(e.target.value)}
                        type={showOldPassword ? 'text' : 'password'}
                        style={{marginBottom: 10}}
                        label="Old Password"
                        fullWidth
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                aria-label="toggle password visibility"
                                onClick={e => setShowOldPassword(!showOldPassword)}
                                onMouseDown={e => setShowOldPassword(showOldPassword)}
                            >
                                {showOldPassword ? <Visibility /> : <VisibilityOutlinedIcon />}
                            </IconButton>
                            </InputAdornment>
                        ),
                        }}
                    />
                </div> 
            </Grid>

            <Grid item lg={12}>
                <div>
                <TextField
                    id="standard-basic"
                    onChange={e => setNewPassword(e.target.value)}
                    type={showNewPassword ? 'text' : 'password'}
                    label="New Password"
                    style={{marginBottom: 10}}
                    fullWidth
                    InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                        <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            onClick={e => setShowNewPassword(!showNewPassword)}
                            onMouseDown={e => setShowNewPassword(showNewPassword)}
                        >
                            {showNewPassword ? <Visibility /> : <VisibilityOutlinedIcon />}
                        </IconButton>
                        </InputAdornment>
                    ),
                    }}
                />
                </div> 
            </Grid>

            <Grid item lg={12}>
                <div >
                <TextField
                    id="standard-basic"
                    onChange={e => setConfirmPassword(e.target.value)}
                    type={showConfirmPassword ? 'text' : 'password'}
                    label="Confirm Password"
                    fullWidth
                    InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                        <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            onClick={e => setShowConfirmPassword(!showConfirmPassword)}
                            onMouseDown={e => setShowConfirmPassword(showConfirmPassword)}
                        >
                            {showConfirmPassword ? <Visibility /> : <VisibilityOutlinedIcon />}
                        </IconButton>
                        </InputAdornment>
                    ),
                    }}
                />
                </div> 
            </Grid>

            <Grid item lg={12}>
                <div align="right">
                    <button className={acct.cancel} onClick={toggle} style={{marginRight: '10px'}}>Cancel</button>

                    <button className={acct.changePassword} onClick={changePass}>Change Password</button>
                </div>
            </Grid>


        </Grid>
    </Grid>
    )
}

export default ChangePassword;