import React from 'react'
import pricingStyle from '../../static/css/Pricing';
import { Grid } from "@material-ui/core";
import { 
    OTC,
    SevenEleven, BayadCenter, Cebuana_Lhuillier, Ecpay, Expresspay, Ruralnet, Sm_new,
    Lbc, ML, Palawan, RD, Robinson, Rural, Sm, Pay_N_Go,DA5,Posible, USSC, Rustan, Shopwise, 
    Tambunting, CVM, Wellcome, Petnet, Villarica } from '../../static/icons/payment_method_icons/index'

const PaymentChannel = props => {
    const userType = localStorage.getItem('userType')
    const p = pricingStyle();

    const channels = [
        {
            icon: SevenEleven,
            name: '7-Eleven',
            min: '₱ 50.00',
            max: '₱ 10,000.00',
            emax: '₱ 10,000.00'
        },
        {
            icon: BayadCenter,
            name: 'Bayad Center',
            min: '₱ 50.00',
            max: '₱ 20,000.00',
            emax: '₱ 80,000.00'

        },
        {
            icon: Cebuana_Lhuillier,
            name: 'Cebuana Lhuillier',
            min: '₱ 50.00',
            max: '₱ 30,000.00',
            emax: '₱ 50,000.00'
        },
        {
            icon: ML,
            name: 'MLhuillier',
            min: '₱ 180.00',
            max: '₱ 30,000.00',
            emax: '₱ 50,000.00'
        },
        {
            icon: Ecpay,
            name: 'ECPay',
            min: '₱ 50.00',
            max: '₱ 30,000.00',
            emax: '₱ 50,000.00'
        },
        // {
        //     icon: Lbc,
        //     name: 'LBC',
        //     min: '₱ 50.00',
        //     max: '₱ 30,000.00',
        //     emax: '₱ 80,000.00'
        // },
        {
            icon: Robinson,
            name: 'Robinsons Dept Store',
            min: '₱ 50.00',
            max: '₱ 30,000.00',
            emax: '₱ 80,000.00'
        },
        {
            icon: Ruralnet,
            name: 'Ruralnet',
            min: '₱ 50.00',
            max: '₱ 30,000.00',
            emax: '₱ 80,000.00'
        },
        {
            icon: Sm_new,
            name: 'SM Department / Supermarket Savemore Counter',
            min: '₱ 50.00',
            max: '₱ 30,000.00',
            emax: '₱ 80,000.00'
        },
        // {
        //     icon: Expresspay,
        //     name: 'Expresspay',
        //     min: '₱ 50.00',
        //     max: '₱ 20,000.00',
        //     emax: '₱ 20,000.00'
        // },
        {
            icon: RD,
            name: 'RD Pawnshop',
            min: '₱ 50.00',
            max: '₱ 20,000.00',
            emax: '₱ 20,000.00'

        },
        {
            icon: Palawan,
            name: 'Palawan Pawnshop',
            min: '₱ 50.00',
            max: '₱ 20,000.00',
            emax: '₱ 20,000.00'
        },
        {
            icon: Pay_N_Go,
            name: 'Pay&Go*',
            min: '₱ 100.00',
            max: '₱ 5,000.00',
            emax: '₱ 5,000.00'
        },
        {
            icon: DA5,
            name: 'DA5',
            min: '₱ 50.00',
            max: '₱ 20,000.00',
            emax: '₱ 20,000.00'
        },
        {
            icon: Posible,
            name: 'Posible*',
            min: '₱ 50.00',
            max: '₱ 10,000.00',
            emax: '₱ 10,000.00',
        },
        {
            icon: USSC,
            name: 'USSC**',
            min: '₱ 50.00',
            max: '₱ 20,000.00',
            emax: '₱ 20,000.00',
        },
        // {
        //     icon: Rustan,
        //     name: 'Rustan Supercenter',
        //     min: '₱ 50.00',
        //     max: '₱ 20,000.00',
        //     emax: '₱ 20,000.00',
        // },
        // {
        //     icon: Shopwise,
        //     name: 'Shopwise',
        //     min: '₱ 50.00',
        //     max: '₱ 20,000.00',
        //     emax: '₱ 20,000.00',
        // },
        {
            icon: Tambunting,
            name: 'Tambunting Pawnshop**',
            min: '₱ 50.00',
            max: '₱ 20,000.00',
            emax: '₱ 20,000.00',
        },
        {
            icon: CVM,
            name: 'CVM Pawnshop',
            min: '₱ 50.00',
            max: '₱ 20,000.00',
            emax: '₱ 30,000.00',
        },
        // {
        //     icon: Wellcome,
        //     name: 'Wellcome',
        //     min: '₱ 50.00',
        //     max: '₱ 20,000.00',
        //     emax: '₱ 20,000.00',
        // },
        {
            icon: Villarica,
            name: 'Villarica**',
            min: '₱ 50.00',
            max: '₱ 20,000.00',
            emax: '₱ 20,000.00',
        },
        {
            icon: Petnet,
            name: 'Petnet, Inc.**',
            min: '₱ 50.00',
            max: '₱ 20,000.00',
            emax: '₱ 20,000.00',
        },
    ]

    channels.sort(function (a, b){
        let nameA = a.name.toLowerCase();
        let nameB = b.name.toLowerCase();
        if(nameA < nameB) {
            return -1;
        }

        if (nameA > nameB) {
            return 1;
        }

        return 0;
    });

    return(
        <div className="pricing-table-div top-20" hidden={props.hidden}>
            <div className="pricing-header">
                <div className="wc-header">
                    <img src={OTC} className="right-8" alt="icon" />
                    Over-the-counter channels
                </div>

                <div className="top-20 normal-text-size liveColor">
                    Transaction fees are <span className={`text-${props.segments} wc-header`}>₱ 20.00</span> across all OTC channels.
                </div>
            </div>

            <div className="padding-lr-40 padding-bot-40">
                <div className="twoCol wc-header padding-20">
                    <Grid container>
                        <Grid item lg={3}>
                            Channel
                        </Grid>

                        <Grid item lg={3}>
                        </Grid>

                        <Grid item lg={3}>
                            Minimum Amount
                        </Grid>

                        <Grid item lg={3}>
                            Maximum Amount
                        </Grid>
                    </Grid>
                </div>
                {
                    channels.map((item, key) => {
                        return(
                            <div className="pricing-data normal-text-size liveColor">
                                <Grid key={key} container>
                                    <Grid item lg={2}>
                                        <div align='left'>
                                            <img alt="icon" src = {item.icon} style={{maxWidth: '60%'}} />
                                        </div>
                                    </Grid>

                                    <Grid item lg={4}>
                                        <p className={p.amount}>{item.name}</p>
                                    </Grid>

                                    <Grid item lg={3}>
                                        <p className={p.amount}>{item.min}</p>
                                    </Grid>

                                    <Grid item lg={3}>
                                        <p className={p.amount}>
                                            {
                                                userType == 'CO' || userType == 'SW' ? 
                                                    item.emax :
                                                    item.max
                                            }
                                        </p>
                                    </Grid>  
                                </Grid>
                            </div>
                        )   
                    })
                }
                <div className="wc-header top-16">
                    *surcharge fees are additional fees charged to the customer.
                </div>
            </div>
        </div>
    )
}

export default PaymentChannel;