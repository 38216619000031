import { useEffect, useState } from "react";

export function useDataFetching(api) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api();
      setData(response);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {
      setData(null);
    };
  }, []);

  const refetchData = () => {
    fetchData();
  }

  return {
    data,
    loading,
    error,
    refetchData
  }
}
