import React,{ useState } from 'react';
import ArrowDown from '../../../static/icons/arrow-down.svg';
import { Modal, Typography, Button, Icon, Card, DatePicker, Input } from 'antd';
import moment from 'moment';

const EndorseModal = (props) => {
  const [btnStyle, setBtnStyle] = useState({color: '#F5922F', backgroundColor: '#FFF' })
  const [newStatus, setNewStatus] = React.useState(null);
  const [buxFee, setBuxFee] = React.useState("");
  const [channelFee, setChannelFee] = React.useState("");
  const [transDate, setTransDate] = React.useState("");
  const [transMoment, setTransMoment] = React.useState(null);
  const [selectOptions, setSelectOptions] = React.useState(null);
  const data = props.data;

  const handleMouseOver = () => {
    setBtnStyle({
      backgroundColor: '#F5922F',
      color: '#FFF'
    })
  }

  const handleMouseLeave = () => {
    setBtnStyle({
      backgroundColor: '#FFF',
      color: '#F5922F'
    })
  }

  const handleSelect = (e) => {
    setNewStatus(e.target.value);
    props.setTargetStatus(e.target.value);
  }

  const handleSelectB = (e) => {
    setBuxFee(e.target.value);
    props.setTargetBuxFee(e.target.value);
  }

  const handleSelectC = (e) => {
    setChannelFee(e.target.value);
    props.setTargetChannelFee(e.target.value);
  }


  const returnStatus = () => {
    return (
      props.selected === 'Payout'? props.POStatus&&props.POStatus : props.PRStatus&&props.PRStatus
    )
  }

  const disabledStartDate = startValue => {
    return startValue && startValue > moment().endOf('day');
  };

  const onStartChange = value => {
    if(value != null){
      setTransMoment(value)
      setTransDate(value.format("MM-DD-YYYY"))
      props.setTargetDate(value.format("MM-DD-YYYY"));
    }
    else{
      setTransMoment(null)
      setTransDate("")
      props.setTargetDate("")
    }
  }


  const POStatus = ['Success', 'Pending', 'Failed'];
  const PRStatus = ['Paid', 'Pending', 'Cancelled', 'Expired']

  React.useEffect(() => {
    setSelectOptions(props.selected === 'Payout'? POStatus : PRStatus);
  },[props.selected])

  return (
    <Modal 
      visible={props.visible} 
      bodyStyle={styles.root} 
      footer={null}
      centered
      width={360}
      onCancel={() => { setNewStatus(null); props.closeModal(); }}
      closeIcon={<Icon type="close" style={{color:'#F5922F'}}/>}
      destroyOnClose
    >
      <Typography style={styles.title}>Endorse</Typography>
      <Typography style={styles.msg}>Are you sure you want to endorse this?</Typography>
        <Card size="default" width='100%' style={styles.detailsCard} bodyStyle={{padding: '0 0 8px 0', width: 320}}>
            <div style={styles.tableHeader}>
              <span>
                Trans ID: {data && data.id}
              </span>
            </div>
            <div style={styles.text}>
              <span>Amount: <b>{data && data.amount}</b></span>
            </div>
            <div style={styles.text}>
              <span>Owner: <b>{data && data.owner}</b></span>
            </div>
            <div style={styles.text}>
              <span>Current Status: <b>{returnStatus()}</b></span>
            </div>
        </Card>
        <div style={styles.inputDiv}>
          <label style={styles.label}>Target Status</label>
          <select
            // value={params['natureOfBusiness']}
            name='transactionType'
            onChange={handleSelect}
            style={{...styles.selectStyle, color: newStatus != '' ? '#000' : '#D1D5DD'}}>
                <option disabled={newStatus} value="" style={{color: '#D1D5DD'}}>Select Status</option>
                {
                  selectOptions && selectOptions.map((item,i)=>{
                    return(
                    <option key={i} value={item} style={{color: '#000'}}>{item}</option>
                    )
                  })
                }
          </select>
        </div>

        <div style={styles.inputDiv} hidden={props.selected == 'Payout'}>
          <label style={styles.label}>BUx Fee</label>

          <Input
              placeholder=""
              value={buxFee}
              onChange={handleSelectB}
          />
        </div>

        <div style={styles.inputDiv} hidden={props.selected == 'Payout'}>
          <label style={styles.label}>Channel Fee</label>

          <Input
              placeholder=""
              value={channelFee}
              onChange={handleSelectC}
          />
        </div>

        <div style={styles.inputDiv} hidden={props.selected == 'Payout'}>
          <label style={styles.label}>Transaction Date</label><br/>
            
          <DatePicker
            style={{ width: '150px'}}
            disabledDate={disabledStartDate}
            size="large"
            format='MM-DD-YYYY'
            value={transMoment || null}
            placeholder="MM-DD-YYYY"
            onChange={onStartChange}
          />
        </div>
      <div style={styles.mainBtn}>
        <Button style={{...styles.btn, borderColor: '#F5922F', ...btnStyle}} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} onClick={() => { setNewStatus(null); props.closeModal(); }}>
          Cancel
        </Button>
        <Button style={{...styles.btn, color: '#FFF', backgroundColor: '#F5922F', opacity: '1'}} onClick={() => props.onEndorse(newStatus, buxFee, channelFee, transDate)}>
          Endorse
        </Button>
      </div>
    </Modal>
  )
}

const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 24,
    color: '#2B2D33',
    fontWeight: '300',
    textAlign: 'left',
    padding: '16px 0 0px 0'
  },
  msg: {
    fontSize: 16,
    color: '#000',
    textAlign: 'left',
    padding: '8px 0'
  },
  mainBtn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 10,
  },
  btn: {
    height: 48,
    width: '45%',
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 10,
  },
  inputDiv: {
    width: '100%',
    padding: '6px 0'
  },
  label: {
    fontSize: 12,
    color: '#212B36',
    padding: '4px 0'
  },
  selectStyle: {
    width: '100%',
    height: '48px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    appearance: 'none',
    background: `url(${ArrowDown})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '95% 50%',
    fontSize: '16px',
    borderColor: '#D1D5DD',
    borderRadius: 4,
    padding: '10px 40px 10px 10px',
    margin: '4px 0 0 0'
  },
  status: {
    borderRadius: 10,
    padding: 4,
    border: '1px solid #ddd',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 48,
  },
  subtitle: {
    padding: '0 0 16px 0',
    color: '#2B2D33',
    fontSize: '16px'
  },
  detailsCard: {
    textAlign: 'left',
    margin: '0 0 12px 0',
    width: '100%',
    borderRadius: 4
  },
  detailsContent: {
    display: 'flex',
    padding: '7px 20px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  text: {
    fontSize: 16,
    color: '#2B2D33',
    padding: '4px 12px'
  },
  tableHeader: {
    backgroundColor: '#E6EAF0',
    color: '#2B2D33',
    fontSize: 16,
    padding: '8px 12px'
  }
}

export default EndorseModal;