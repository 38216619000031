import React, { useState } from 'react';
import { Typography, Layout } from 'antd';
import { Tabs, Tab, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IndiSignUp from './individualSignup';
import CorpSignUp from './corporateSignup';
import {hideAlert} from '../../../alert/alertAction'
import { useDispatch } from '../../../dashboard/react-redux-hooks';
import {isMobile} from 'react-device-detect';
import logo from '../../../../static/icons/logo.svg';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{padding: 0}} p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const SignUp = () => {
  const [tab, setTab] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setTab(newValue);
    dispatch(hideAlert());
  }

  return (
    <div style={styles.root}>
      <Layout.Header hidden={isMobile === false} style={styles.header}>
        <img src={logo} style={{marginBottom: '20px', marginTop: '12px'}} alt="#" />
      </Layout.Header>
      <div style={styles.subroot}>
        <Typography style={styles.title}>Create an Account</Typography>
        <Tabs
          variant="fullWidth"
          centered
          textColor={'inherit'}
          classes={{indicator: classes.indicator }}
          onChange={handleChange}
          value={tab}
          aria-label="icon label tabs example"
          id='tab'
        >
          <Tab id='indiTab' label="Individual Account" style={{color: tab == 0 ? '#0D3D76' : '#909196', borderBottom: '4px solid #909196'}} className={classes.tabStyle}/>
          <Tab id='corpTab' label="Enterprise Account" style={{color: tab == 1 ? '#0D3D76' : '#909196', borderBottom: '4px solid #909196'}} className={classes.tabStyle}/>
        </Tabs>
        <TabPanel value={tab} index={0}>
          <IndiSignUp />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <CorpSignUp />
        </TabPanel>
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  indicator: {
      backgroundColor: '#0D3D76',
      height: 4,
  },
  tabStyle: {
    textTransform: 'initial', 
    fontWeight: 'bold', 
    padding: '16px 0px 8px 0px',
    fontSize: 16,
  },
  spinner:{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: 500,
  }
});

const styles = {
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: '#FFF',
    paddingBottom: 30,
  },
  title: {
    color: '#2B2D33',
    fontSize: 28,
    fontWeight: 'bold',
    marginTop: isMobile?  '26px' :'-22px'
  },
  subroot: {
    padding: isMobile? '0 20px' : null
  },
  header: {
    height: '60px', 
    display: 'flex', 
    justifyContent: 'center',
    backgroundColor: '#fff', 
    boxShadow: '0px 3px 5px rgba(57,63,72,0.1)',
  }
}

export default SignUp;