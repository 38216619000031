import React from "react";
import {
  Input,
  message,
  Select,
  Modal,
} from "antd";
import { history } from "../../../store/history";
import axios from "axios";
import { Handle401 } from "../../handle401/handle401";
import { isMobile } from "react-device-detect";
import MetaTag from "../../meta_tag/meta_tag";

import { GCash_Logo, ArrowDown, ConvertWelcomeBanner } from "../../../static/icons";

const { Option } = Select;

class GCashSubMidInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      industry: 0,
      sub_category: null,
      website: "",
      facebook: "",
      instagram: "",
      modalVisibility: false,
      industries: [],
      sub_categories: [],
      disableSubCategories: false,
      current_sub_category: [],
      hideSubmit: false,
      confirmModalVisibility: false,
    };
  }

  componentDidMount() {
    let current_path = window.location.pathname;
    if (
      localStorage.getItem("jwtToken") === "" ||
      localStorage.getItem("jwtToken") === null
    ) {
      history.push("/login?next=" + current_path);
    } else {
      if (
        localStorage.getItem("userType") == "AD" ||
        localStorage.getItem("userType") == "OP"
      ) {
        history.push("/orders");
      } else if (localStorage.getItem("userType") == "PS") {
        history.push("/orders");
      } else if (localStorage.getItem("userType") == "PF") {
        history.push("/recon/7_eleven");
      } else if (localStorage.getItem("userType") == "MA") {
        history.push("/overview");
      } else if (
        localStorage.getItem("userType") === "ME"
      ) {
        if (isMobile) {
          history.push("/mobile/gcash_submid");
          this.fetchGCashSubMidData();
        } else {
          this.fetchGCashSubMidData();
        }
      } else {
        history.push("/dashboard");
      } 
    }
  }

  fetchGCashSubMidData = async () => {
    let yourConfig = {
      headers: {
        Authorization: "Token " + localStorage.getItem("jwtToken"),
      },
    };
    try {
      let response = await axios.get(
        process.env.REACT_APP_API_URL + "/api/gcash_submid/",
        yourConfig
      );

      if (response.data.status == "success") {
        this.setState({
          industry: response.data.data.industry,
          sub_category: response.data.data.sub_category >=0 ? response.data.data.sub_category : null,
          website: response.data.data.website,
          facebook: response.data.data.facebook,
          instagram: response.data.data.instagram,
          sub_categories: response.data.data.sub_categories,
          industries: response.data.data.industries,
          current_sub_category: response.data.data.sub_categories[response.data.data.industry],
        })

        if(this.state.current_sub_category.length>0) {
          this.setState({
            disableSubCategories: false,
          }) 
        } else{
          this.setState({
            disableSubCategories: true,
          })
        }

        // check if business info is complete to hide submit button
        if(this.state.industry >= 0 && this.state.facebook && this.state.website){
          if(this.state.current_sub_category.length > 0 && this.state.sub_category >= 0) {
            this.setState({
              hideSubmit: true,
            })
          }
        }

        console.log(this.state.current_sub_category)
        console.log(this.state.sub_category)
      } else {
        return false;
      }
    } catch (error) {
      if (error.response && error.response.status == 401) {
        Handle401();
      }
    }
  }

  setModalVisibility = () => {
    this.setState({
      modalVisibility: !this.state.modalVisibility,
    });
  };

  setConfirmModalVisibility = () => {
    this.setState({
      confirmModalVisibility : !this.state.confirmModalVisibility,
    })
  }

  onChangeIndustry = (value) => {
    this.setState({
      industry: value,
    })
    if (this.state.sub_categories[value] === undefined || this.state.sub_categories[value].length == 0) {
      this.setState({
        disableSubCategories: true,
        current_sub_category: this.state.sub_categories[value],
        sub_category: null,
      })
    } else {
      this.setState({
        disableSubCategories: false,
        current_sub_category: this.state.sub_categories[value],
        sub_category: 0,

      })
    }
  };

  onChangeWebsite = (e) => {
    this.setState({
      website: e.target.value,
    })
  }

  onChangeFacebook = (e) => {
    this.setState({
      facebook: e.target.value,
    })
  }

  onChangeInstagram = (e) => {
    this.setState({
      instagram: e.target.value
    })
  }

  onChangeSubCategory = (value) => {
    this.setState({
      sub_category: value,
    })
  }

  submit = async() => {
    const siteRegex = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
    const facebookRegex = new RegExp(/^(ftp|http|https):\/\/(www\.)?facebook\.com\/[^ "]+$/);
    if(!this.state.facebook || (this.state.facebook && !facebookRegex.test(this.state.facebook))) {
      this.setState({
        confirmModalVisibility: !this.state.confirmModalVisibility,
      })
      return;
    }
    if(!this.state.website || (this.state.website && !siteRegex.test(this.state.website))) {
      message.error("Valid website is required")
      this.setState({
        confirmModalVisibility: !this.state.confirmModalVisibility,
      })
      return;
    }

    try {
      const yourConfig = {
        headers: {
          Authorization: "Token " + localStorage.getItem("jwtToken"),
        },
      };

      const params = {
        industry: this.state.industry,
        sub_category: this.state.sub_category,
        website: this.state.website,
        facebook: this.state.facebook,
        instagram: this.state.instagram,
      }
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/gcash_submid/",
        params,
        yourConfig
      );
      this.setState({
        confirmModalVisibility: !this.state.confirmModalVisibility,
      })
      if (response.data.status == "success") {
        this.setState({
          modalVisibility: true,
        })
      } else {
        message.error("Something went wrong.");
      }
    } catch (error) {}
  }

  render() {
    const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
    const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
    const isDell1 = window.screen.width * window.devicePixelRatio === 1366 && window.screen.height * window.devicePixelRatio === 768;
    const isMac = window.screen.width * window.devicePixelRatio === 1440 && window.screen.height * window.devicePixelRatio === 900;

    const isDellReso = (isSmallReso || isDell || isDell1)
    const segments = localStorage.getItem("segments");

    return (
      <>
      <div className={isDell ? "top-12" : "top-48"} style={{marginRight: isDellReso ? '2vw' : isMac ? '5vw' : isMobile ? 0 : '10vw', padding: isMobile ? 20 : 0, backgroundColor: isMobile && '#F4F6F9', height:"auto"}}>
        <MetaTag title="GCash Sub MID" />
        <div align="center">
          <div className="creds-note-div">
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <img
                style={{
                  height: "3em",
                  maxHeight: "4.5em",
                }}
                src={GCash_Logo}
              />
            </div>
            <div className="normalTextStyle top-12">
              Please be informed that GCash is implementing a new Submerchant
              Identification (ID) system for its Payment Facilitator and
              Aggregator Partners.
              <span style={{ fontWeight: "bold" }}> GCash</span> is strictly
              enforcing and mandating that our merchant partners register and
              use GCash Sub MIDs on or before
              <span style={{ fontWeight: "bold" }}> September 30, 2022</span>.
              <br />
              <br />
              Please complete or update your account details to continue using
              Gcash as a payment option for your business.
            </div>
          </div>
          <div>
            <div
              className="credentials-div-card top-32"
              style={{ marginTop: isMobile && "16px" }}
            >
              <div className="bold-text-size liveColor">
                Account Information
              </div>
              <div>
                <div className="top-20 smallTextStyle">Industry</div>
                <div className="flex">
                  <Select 
                    size="large" 
                    style={{...Style.selectStyle}} defaultValue={this.state.industry >=0 ? this.state.industry : 0} 
                    value={this.state.industry}
                    onChange={this.onChangeIndustry}>
                    {
                      this.state.industries.map((industry, index)=>{
                        return (
                          <Option value={index}>{industry}</Option>
                        )
                      })
                    }
                  </Select>
                </div>

                <div className="top-20 smallTextStyle">Sub Category</div>
                <div className="flex">
                  <Select 
                    size="large"
                    style={{...Style.selectStyle}}
                    disabled={this.state.disableSubCategories}
                    value={this.state.sub_category >=0 ? this.state.sub_category : null}
                    onChange={this.onChangeSubCategory}>
                    {
                      this.state.current_sub_category.map((sub_category, index) => {
                          return (
                            <Option value={index}>{sub_category}</Option>
                          )
                      })
                    }
                  </Select>
                </div>

                <div className="top-20 smallTextStyle">Website</div>
                <div
                  className="smallTextStyle"
                  style={{ margin: "12px 0px 5px 0px" }}
                >
                  e.g. https://sample.com
                </div>
                <div className="flex">
                  <Input size="large" value={this.state.website} onChange={this.onChangeWebsite}/>
                </div>

                <div className="top-20 smallTextStyle">Facebook</div>
                <div
                  className="smallTextStyle"
                  style={{ margin: "12px 0px 5px 0px" }}
                >
                  e.g. https://facebook.com/sample
                </div>
                <div className="flex">
                  <Input size="large" value={this.state.facebook} onChange={this.onChangeFacebook}/>
                </div>

                <div className="top-20 smallTextStyle">
                  Instagram (Optional)
                </div>
                <div
                  className="smallTextStyle"
                  style={{ margin: "12px 0px 5px 0px" }}
                >
                  e.g. https://instagram.com/sample
                </div>
                <div className="flex">
                  <Input size="large" value={this.state.instagram} onChange={this.onChangeInstagram}/>
                </div>
                <div className="flex" style={{ justifyContent: "flex-end" }}>
                  <button
                    className={`btn--${segments} btn-height top-20`}
                    onClick={()=>this.setConfirmModalVisibility()}
                    hidden={this.state.hideSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
          centered
          width={450}
          visible={this.state.confirmModalVisibility}
          onCancel={()=>this.setConfirmModalVisibility()}
          footer={null}>
            <div>
                <div className={`boldTextStyle`} style={{color: '#2B2D32', fontSize: '20px' }}>
                    Confirm submission?
                </div>

                <div style={{...Style.message, color: '#000000', textAlign: 'justify' }}>
                    <p>
                    You won't be able to change these information once submitted. Would you like to proceed?
                    </p>
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end', align: 'right', paddingTop:15 }}>
                    <button
                        className={`outline-btn--${segments}`}
                        style={{marginRight: 12}}
                        onClick={()=>this.setConfirmModalVisibility()}
                    >
                        Cancel
                    </button>
                    <button
                        className={`btn--${segments}`}
                        onClick={()=>this.submit()}
                    >
                        Proceed
                    </button>
                </div>
            </div>
        </Modal>
      <Modal
        centered
        width={450}
        visible={this.state.modalVisibility}
        onCancel={()=>this.setModalVisibility()}
        footer={null}>
          <div>
              <div style={{ padding: '30px 0px' }}>
                  <img src={ConvertWelcomeBanner} style={{ width: '100%' }} alt="logo" />
              </div>
              <div className={`boldTextStyle`} style={{color: '#2B2D32', fontSize: '18px' }}>
                  Successful submission
              </div>

              <div style={{...Style.message, color: '#000000', textAlign: 'justify' }}>
                  <p>
                  Thank you for completing your account information! Kindly allow us at least 24 hours to evaluate your submission. <br/> <br/>
                  Questions? Please contact us at <a className={`text-${segments}`} href="mailto:support@bux.ph" target="_blank">support@bux.ph</a>
                  </p>
              </div>

              <div style={{ display: 'flex', justifyContent: 'flex-end', align: 'right' }}>
                  <button
                      className={`btn--${segments}`}
                      onClick={()=>this.setModalVisibility()}
                  >
                      Got It
                  </button>
              </div>
          </div>
      </Modal>
      </>
    );
  }
}

const Style = {
  cardHeadStyle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: isMobile ? "14px" : "16px",
    color: "#000000",
    paddingBottom: 8,
  },
  labelStyle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12,
    // lineHeight: '24px',
    color: "#000000",
  },
  copyBtn: {
    backgroundColor: "rgb(245, 146, 47)",
    color: "#fff",
  },
  alert: {
    fontSize: "12px",
    color: "#54575f",
    fontWeight: "400",
    textAlign: "center",
    margin: "10px 0px 20px 0px",
  },
  resetBtn: {
    color: "#E24C4C",
    fontSize: 12,
    fontWeight: "500",
  },
  selectStyle : {
    width: '100%',
    height: '38px',
    borderRadius: '4px',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    appearance: 'none',
    background: `url(${ArrowDown})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '95% 50%',
    fontSize: '12px',
    outline: 'none'
  },
  message: {
    textAlign: 'left',
    marginTop: 16,
    fontSize: '16px',
    lineHeight: '24px',
  },


};

export default GCashSubMidInfo;
