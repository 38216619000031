import React from 'react'
import {Modal, Radio, Input, Button, message} from 'antd'
import axios from 'axios'
import { history } from '../../../store/history'

const CancelModal = props => {

    const {
        fetchData,
        cancelVisible,
        closeModal,
        idToBeCancel, 
        channelCancel,
    } = props

    const [reason, setReason] = React.useState("Item/s no longer available")
    const [otherReason, setOtherReason] = React.useState("")
    const testMode = (history.location.pathname.indexOf('/test') > -1&&localStorage.getItem("userType") == "CO")

    const cancelOrderNow = async() => {
      let params = {
        id: idToBeCancel,
        reason: reason != 'Others' ? reason : otherReason,
      }
      let yourConfig = {
          headers: {
              Authorization: "Token " + localStorage.getItem("jwtToken")
          }
      }
      const url = testMode? '/api/sandbox/cancel/':'/api/orders/cancel_order/';
      let response = await axios.post(process.env.REACT_APP_API_URL + url, params, yourConfig);
      
      if(response.data.status == "success"){
        closeModal()
        if (channelCancel === 'UnionBank') {
          message.success("Refund request was successfully sent")

        }

        else {
          message.success("Order was successfully cancelled")

        }
        fetchData()
      }
      else{
        message.error(response.data.message)
      }
    }

    return(
        <Modal
            visible={cancelVisible}
            footer={null}
            onCancel={closeModal}
          >
            <div style={{ padding: '40px 30px 40px 30px' }}>
              <div style={Style.cancelOrder}>Cancel Order</div>
              <div style={Style.choicesStyle}>
              Are you sure you want to cancel this order? This can’t be undone.
              </div>
              <div style={Style.choicesStyle}>Provide a reason</div>
              <div style={{marginBottom: '10px'}}>
                <Radio.Group onChange={(e)=>setReason(e.target.value)} value={reason}>
                  <Radio style={Style.radioStyle} value="Item/s no longer available">
                    Item/s no longer available
                  </Radio>
                  <Radio style={Style.radioStyle} value="Unavailable color or size">
                    Unavailable color or size
                  </Radio>
                  <Radio style={Style.radioStyle} value="Unable to ship the item">
                    Unable to ship the item
                  </Radio>
                  <Radio style={Style.radioStyle} value="Payment was declined">
                    Payment was declined
                  </Radio>
                  <Radio style={Style.radioStyle} value="Others">
                    Others <Input hidden={reason != "Others"} onChange={(e)=>setOtherReason(e.target.value)}/>
                  </Radio>
                </Radio.Group>
              </div>
              <div>
                <Button type="danger" style={Style.cancelButtonStyle} onClick={() => cancelOrderNow()}>Cancel and notify customer</Button>
              </div>
              <Button style={Style.dontCancelButton} onClick={closeModal}>No, don’t cancel</Button>
            </div>
        </Modal>
    )
}

const Style = {

    radioStyle: {
        display: 'block',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33',
        opacity: '0.8',
        marginBottom: '10px'
    },
    cancelOrder: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '34px',
        color: '#2b2d33',
        marginBottom: '10px'
    },
    choicesStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33',
        opacity: '0.8',
        marginBottom: '10px'
    },
    cancelButtonStyle: {
        borderRadius: '4px',
        width: '100%',
        height: '40px',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#FFFFFF',
        marginBottom: '10px',    
    },
    dontCancelButton: {
        borderRadius: '4px',
        width: '100%',
        height: '40px',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33',
        marginRight: '10%'
    }
}

export default CancelModal