import React from 'react';
import { Icon } from 'antd';
const ClearFilter = props => {
    
    const {
        hideFilterBtn, clearFilter,
    } = props;

    return(
        <div>
            <div style={{display: 'flex'}}>
                <div className="wc-header" >
                    Filter
                </div>
                <div style={{marginLeft: 10, marginTop: -10}} hidden={hideFilterBtn}>
                    <button 
                        className="clear-filter-btn"
                        onClick={clearFilter}
                    >
                        <Icon 
                            type="close-circle"
                            theme="filled"
                            style={{paddingRight: 4}}
                        />
                        Clear Filter
                    </button>
                </div>
            </div>

            {/* <div className="top-12">
                <div
                    className="grey-color smallTextStyle"
                    hidden={hideFilterBtn}
                >
                    <Icon 
                        type="info-circle" 
                        theme="filled"
                        style={{paddingRight: 4, transform: 'translate(0px, 1px)'}}
                    />
                    Click <b>Apply</b> button to show results
                </div>
            </div> */}
        </div>
    )
}

export default ClearFilter;