import React from 'react';
import cardsStyles from '../../../static/css/Cards';
import{BasicLevel, SubmitLevel, RejectLevel, ApproveLevel, WaveBG} from '../../../static/icons/index';
import LimitImg from '../../../static/img/limit_level.svg'
import {Modal, Icon} from 'antd';
import { history } from '../../../store/history';


const AccountCard = (props) => {

    const cs = cardsStyles();
    const current_step = localStorage.getItem("Step")
    const { isDellReso } = props;

    const [limitModal, setLimitModal] = React.useState(false)
    const firstName = localStorage.getItem("firstName")
    const level = localStorage.getItem("Level")
    const kybStep = localStorage.getItem("KYBStep")
    const step = localStorage.getItem("Step")
    const userType = localStorage.getItem("userType")
    const segments = localStorage.getItem("segments")
    const subwalletType = localStorage.getItem("subwallet_type")
    
    return(
        <div className={`accountCard ${
            level == "0" && step == 0 && (userType == "CO" && kybStep == "0" )?
        
            '' :
        
            (userType == "CO" && kybStep < 7) || (level == '0' && step >= 1 && step < 7) ?
            
            `pendingBorderTop` :
        
            (userType == "CO" && kybStep == 8 || kybStep == 9) || (level == "0" && step == 8 || step == 7) || (level == "1" && step == 12 || step == 13)?
        
            `pendingBorderTop` :
        
            (userType == "CO" && kybStep == 10) || (level == "0" && step == 9) 
            // || (level == "1" && step == 14) || step == 19 
            ?
        
            "rejectedBorderTop" :
        
            level == "1" && step == 10 || level == "2" || (level == "5" && kybStep == 11) || kybStep == 7 ?
        
            "approvedBorderTop" :
        
            ''
        }`}
        align="left" style={{ width: isDellReso ? '60vw' : '54vw', display: 'flex' }}>
            <div style={{position: 'relative'}}>
                {   (level == '0' && step >= 1 && step < 7) ?
                    <div className="progress-div">
                        <div className={`completeIcon border-pending text-pending`}>
                            {Math.min(
                            current_step == 3 || current_step == 4 ? 3 : 
                            current_step == 5 ? 4 : 
                            current_step == 6 ? 5 : current_step, 5) * 20}%
                        </div>
                    </div>
                    : 
                    (userType == "CO" || subwalletType == 'CMP') && kybStep <= 7 ?
                    <div className="progress-div">
                        <div className={`completeIcon ${kybStep == 7 ? "border-complete text-complete" : `border-pending text-pending`}`}>
                            {kybStep == 7 ? '100' : Math.min(kybStep,6) * 15 }%
                        </div>
                    </div>
                    :
                    <img
                        alt="#" 
                        src={
                            level == "0" && step == 0 && kybStep == "0"?
                                BasicLevel 
                            :

                            (kybStep == 8 || kybStep == 9) || (level == "0" && step == 8 || step == 7) || (level == "1" && step == 12 || step == 13) ?
                                SubmitLevel 
                            :

                            kybStep == 10 || (level == "0" && step == 9) 
                            // || (level == "1" && step == 14) || step == 19 
                            ?
                                RejectLevel 
                            :

                            level == "1"  || level == "2" || (level == "5" && kybStep == 11) ? 

                                ApproveLevel 
                            : 

                            "" 
                            } 
                        style={{height: level == "0" && step == 0 && kybStep == "0"? '86%' : '80%', marginTop: '19%', marginLeft: '25%'}} 
                        />

                }
                
            </div>

            <div className="progress-text-div" style={{padding: kybStep == 8 || kybStep == 11? '6px 32px 0px 16px' : '12px 16px'}}>
                <div className={`wc-header ${(userType == "ME" || userType == "SW") && level == '0' && step == '0' || (userType == "CO" && level == 0  && kybStep == 0) ? '' : 'top-16'}`}>
                    {(userType == "ME" || userType == "SW") && level == '0' && step == '0' || (userType == "CO" && level == 0  && kybStep == 0) ? 'Welcome to Bux' : 'Hello'} {userType == "CO" ? '' : firstName}
                </div>

                <div className="wc-text" style={{marginTop: kybStep == 11 ? '8px' : ''}}>
                    {
                        (userType == "ME" || userType == "SW") && subwalletType !== 'CMP' && level == "0" && step == 0 ?
                            <span>
                               To increase your limits, upgrade your account by completing your profile for FREE. Complete now.     
                            </span> 
                        :
                        
                        (level == '0' && step >= 1 && step < 3) ||
                        ((userType == "CO" || subwalletType === 'CMP') && kybStep < 4) ?
                            <span className="wc-text-padding">
                                Account is incomplete! Please complete your account application to activate your account.
                            </span>
                        :

                        (level == '0' && step >= 3 && step < 6) ||
                        ((userType == "CO" || subwalletType === 'CMP') && kybStep == 5 || (userType == "CO" || subwalletType === 'CMP') && kybStep < 7) ?
                            <span className="wc-text-padding">
                                You are almost done! Please complete your account application to activate your account.
                            </span>
                        :

                        (level == '0' && step == 6) ||
                        kybStep == 7 ?
                            <span className="wc-text-padding">
                                Your application is ready to be submitted, Before Submitting, make sure all the information is correct and accurate.
                            </span>
                        :

                        // step == 14 || step == 19 ?
                        //     `We have received your application but unfortunately your upgrade has been declined. Don't worry as you can still reapply after 1 month!`
                        // :

                        (level == "0" && step == 8 || step == 7) || (level == "1" && step == 12 || step == 13 || level == "2" && step == 17 || level == "2" && step == 18) ||
                        (kybStep == 8 || kybStep == 9) ?
                            "We’ve got your upgrade information, and currently reviewing it. Processing may take up to 24 hours."
                        :

                        (level == "0" && step == 9) || 
                        // (level == "1" && step == 14)) ||
                        kybStep == 10 ?
                            "Application was declined. Kindly check your submitted information and resubmit it."
                        :

                        level == "1" || level == "2" || level == "5" ? 
                            <span>
                                Congratulations! Upgrade application was approved.
                            </span>
                        :

                        ''
                    }
                    {
                        //ME
                        (level == '0' && (((userType == "ME" || userType == "SW") && subwalletType !== 'CMP') && step == 0) ||
                        (step >= 1 && step < 3) ||
                        (step == 6) ||
                        (step == 8 || step == 7) || 
                        (step >= 3 && step < 6)) ||
                        (step == 9 
                        // (level == "1" && step == 12 || step == 13 || level == "2" && step == 17 || level == "2" && step == 18)
                        // (level == "1" && step == 14)
                        ) ?
                            <a href={
                                (level == 0 && step < 7) ?
                                    '/complete_profile'
                                :
                                // (level == "0" && step == 8 || step == 7) || (level == "1" && step == 12 || step == 13) ?
                                //     '/upgrade/' 
                                // :
        
                                (level == "0" && step == 9) ?
                                    '/complete_profile/' 
                                : 

                                // (level == "1" && step == 14) || level == "2" && step == 19 ?

                                // '/upgrade_to_business/' 
                                // :

                                // level == "1" && step == 10 || level == "2"?
        
                                // '/upgrade/' 
                                // :

                                ''
                            } 
                            className={`text-${segments} smallBoldStyle`} style={{marginLeft: 4}}>
                                <span>
                                    {
                                        level == '0' && step == 6 ?
                                            'Review and Submit now'
                                            :
                                        (level == "0" && step == 8 || step == 7) || 
                                        // (level == "1" && step == 12 || step == 13 || level == "2" && step == 17 || level == "2" && step == 18) ||
                                        (level == "0" && step == 9) 
                                        // || (level == "1" && step == 14) 
                                        ?
                                            'Check details'
                                            :
                                            'Complete now'
                                    }
                                </span>
                            </a>
                        :

                        ((userType == "CO" || subwalletType === 'CMP') && 
                        (kybStep < 4) ||
                        (kybStep == 5 || (userType == "CO" || subwalletType === 'CMP') && kybStep < 7) ||
                        (kybStep == 8 || kybStep == 9) ||
                        (kybStep == 7)) ||
                        (kybStep == 10) ||
                        (kybStep == 11 && level == '5') ?
                            <a href="/upgrade_enterprise/" className={`text-${segments} smallBoldStyle`} style={{marginLeft: 4}}>
                                <span>
                                    {   
                                        kybStep == 7 ?
                                            'Review and Submit now'
                                            :
                                        (kybStep == 8 || kybStep == 9) || kybStep == 11 ?
                                            'Check details'
                                            :
                                        kybStep == 10 ?
                                            'Check and resubmit'
                                            :
                                            'Complete now'
                                    }
                                </span>
                            </a>
                        :
                        ''
                    }
                </div>
            </div>
            <Modal
                visible={limitModal}
                onCancel={()=>setLimitModal(false)}
                footer={null}
                width={690}
                >
                <div className={cs.dashboardStyle}>Limits by Level</div>
                <img src={LimitImg} style={{marginTop: '30px'}}/>
            </Modal>
        </div>
    )
}

export default AccountCard;