import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useRemittances } from "../api/get-remittances";
import { Table, Pagination, Dropdown, Icon, Menu } from "antd";
import { UserDataCell } from "../../../../../Transfer/components";

function RemittancesList({ searchQuery, startDate, endDate }) {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const { data, isLoading, error } = useRemittances(
    currentPage,
    searchQuery,
    startDate,
    endDate
  );

  return (
    <div>
      {error ? (
        <div style={{ padding: "16px", color: "red" }}>
          Error fetching data. Please try again by refreshing the page.
        </div>
      ) : (
        <>
          <Table
            rowClassName="table-row-light"
            dataSource={data ? data.results : []}
            columns={[
              {
                title: "Reference Code",
                dataIndex: "ref_code",
                fixed: "left",
              },
              {
                title: "Claim Code",
                dataIndex: "control_number",
              },
              {
                title: "Business",
                dataIndex: "merchant_business",
              },
              {
                title: "Channel",
                dataIndex: "channel",
              },
              {
                title: "Partner",
                dataIndex: "partner",
              },
              {
                title: "Merchant",
                dataIndex: "merchant_name",
                key: "merchant_name",
                render: (_, record) => (
                  <UserDataCell
                    name={record.merchant_name}
                    email={record.merchant_email}
                  />
                ),
              },
              {
                title: "Claim Date",
                dataIndex: "claim_date",
              },
              {
                title: "Amount",
                dataIndex: "amount",
              },
              {
                title: "Receiver",
                dataIndex: "receiver_name",
                render: (_, record) => (
                  <UserDataCell
                    name={record.receiver_name}
                    email={record.receiver_email}
                  />
                ),
              },
              {
                title: "Sender Name",
                dataIndex: "sender_name",
              },
              {
                title: "Action",
                key: "action",
                render: (_, record) => (
                  <Dropdown
                    overlay={<MenuItems record={record} />}
                    trigger={["click"]}
                    placement="bottomLeft"
                  >
                    <Icon type="ellipsis" />
                  </Dropdown>
                ),
              },
            ]}
            pagination={false}
            loading={isLoading}
            scroll={{ x: true }}
          />
          <Pagination
            size="small"
            total={(data && data.total) || 0}
            defaultPageSize={pageSize}
            pageSize={pageSize}
            current={currentPage}
            onChange={setCurrentPage}
            style={{ paddingTop: "12px", textAlign: "right" }}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
          />
        </>
      )}
    </div>
  );
}

function MenuItems({ record }) {
  return (
    <Menu>
      <Menu.Item>
        <Link to={`/cashbux/remittances/${record.id}`}>View</Link>
      </Menu.Item>
    </Menu>
  );
}

export default RemittancesList;
