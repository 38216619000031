import React, { useEffect, useState } from 'react';
import {Input, message, Modal} from 'antd';
import axios from "axios";
import { makeStyles } from '@material-ui/core';
import { Handle401 } from '../../handle401/handle401';

const MerchantIDsEditorModal = (props) => {
    const merchantIDsEditorFormStyles = makeStyles(theme => ({
        buttonsDiv: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '25px'
        },
        headerText: {
            fontSize: 'x-large',
            paddingRight: '20px'
        },
        subHeaderText: {
            fontSize: 'large',
            marginBottom: '20px'
        },
        confirmButton: {
            height: '40px',
            width: '120px',
            background: '#F5922F',
            border: '1px solid #f5922f',
            borderRadius: '10px',
            color: '#fff',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '24px',
            outline: 0,
            '&:hover': {
                opacity: 0.8,
                cursor: 'pointer'
            },
            '&:active': {
                background: '#DA7F24',
                opacity: 1
            }
        },
        cancelButton: {
            height: '40px',
            width: '120px',
            background: '#fff',
            border: '1px solid #f5922f',
            borderRadius: '10px',
            color: '#f5922f',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '24px',
            outline: 0,
            '&:hover': {
                background: '#f5922f',
                cursor: 'pointer',
                color: '#fff',
                opacity: 0.75
            },
            '&:active': {
                background: '#DA7F24',
                opacity: 1
            }
        },
    })) ();


    const {
        isEditorModalVisible,
        onCancelOrClose,
        userInFocus,
        merchantPlatformsInUse,
        postSaveReload,
        isSubMid
    } = props;
    
    const [userBeingEdited, setUserBeingEdited] = useState({full_name: '', business_name: ''});
    const [isGCashSubMID, setIsGCashSubMID] = useState(isSubMid);

    async function genericAxiosRequest(http_method, target_url, params=undefined) {
        try {
            let response = await axios({
                method: http_method,
                url: process.env.REACT_APP_API_URL + target_url,
                data: params,
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            });
            return response.data;

        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    Handle401()
                } else {
                    throw new Error(error.response.data.message);
                }
            } else {
                throw new Error('No response from backend server!');
            }
        }
    }

    async function saveEdits(target_id=null, edit_params) {
        if (!target_id) {
            return;
        }

        let merchant_ids = {};
        Object.keys(merchantPlatformsInUse).forEach((platformCode) => {
            merchant_ids[platformCode] = userBeingEdited[platformCode];
        });

        const endpoint_url = isGCashSubMID ? "/api/admin/users/submid_credentials/" :  "/api/admin/users/merchant_credentials/";
        const params = {
            user_id: target_id,
            merchant_ids
        };

        try {
            let response = await genericAxiosRequest('put', endpoint_url, params);
            if (response.status === 'failed') {
                throw new Error(response.message);
            }

            message.success(response.message);
            onCancelOrClose();
            postSaveReload();

        } catch (err) {
            message.error(err.message);
        }
    }


    useEffect(() => {
        if (!isEditorModalVisible) {
            return;
        }

        setUserBeingEdited({...userInFocus})
    }, [isEditorModalVisible])


    return(
        <Modal
            centered
            width={360}
            visible={isEditorModalVisible}
            onCancel={onCancelOrClose}
            footer={[
                <button
                    onClick={onCancelOrClose}
                    className="admin-download-btn right-8"
                >
                    Cancel
                </button>,
                <button
                    onClick={() => saveEdits(userBeingEdited.id)}
                    className="add-user"
                >
                    Save
                </button>
            ]}
        >
            <div className={merchantIDsEditorFormStyles.headerText}>
                {userBeingEdited.full_name}
            </div>
            <div className={merchantIDsEditorFormStyles.subHeaderText}>
                {userBeingEdited.business_name}
            </div>

            {Object.entries(merchantPlatformsInUse).map((platform) => {
                const [platformCode, platformName] = platform;
                return (
                    <div key={platformCode} style={{marginTop: '15px'}} hidden={platformCode==="ubpcs"}>
                        <b>{platformName} MID</b>
                        <Input
                            placeholder={`${platformName} MID`}
                            maxLength={128}
                            onChange={(e) => setUserBeingEdited({...userBeingEdited, [platformCode]: e.target.value})}
                            value={userBeingEdited[platformCode]}
                            size='large'
                        />
                    </div>
            )})}
        </Modal>
    )
}

export default MerchantIDsEditorModal;