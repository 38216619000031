import React from 'react'
import axios from 'axios'
import FeeStyles from './style'
import HeaderCard from './headerCard'
import InstapayPaymentChannelHeaderCard from './instapayPaymentChannelHeaderCard'
import ChannelCard from './channels/card'
import CustomModal from './customize_modal'
import SetDefaultModal from './default_modal'
import SalesForceModal from './addSalesforceId'
import { message, Spin } from 'antd'
import ChannelPercentage from './channel _percentage'
import { RadioButton } from './RadioButton'
import { Button } from './Button'

const Channels = props => {

    const pUid = props.props.match.params.id;

    const [activeChannels, setActiveChannels] = React.useState([])
    const [activeMethod, setActiveMethod] = React.useState()
    const [showModal, setShowModal] = React.useState(false)
    const [sfModal, setSfModal] = React.useState(false)
    const [accountId, setAccountId] = React.useState('')
    const [isValidId, setIsValidId] = React.useState(false)
    const [restoreModal, setRestoreModal] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const fee = FeeStyles();
    const [channelPercentage, setChannelPercentage] = React.useState([]);

    const [channels, setChannels] = React.useState([]);
    const [channelsDisabled, setChannelsDisabled] = React.useState([]);
    const [channelDisabledName, setChannelDisabledName] = React.useState([])

    const [userInstapayPaymentChannels, setUserInstapayPaymentChannels] = React.useState()
    const [userInstapayPaymentChannel, setUserInstapayPaymentChannel] = React.useState()

    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    let yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }

    async function getChannels(){

        try {

            let response = await axios.get(
                REACT_APP_API_URL + `/api/corporate/channels/${pUid}/`,yourConfig)

                if(response.status == 200){
                    setChannels(response.data.channels)
                    setChannelsDisabled(response.data.disabled)
                    setChannelDisabledName(response.data.disabled);
                }

                else {
                    console.log(response)
                }
        }

        catch (error) {
            console.log(error)
        }
    }

    async function getId(){

        try {
            let url = REACT_APP_API_URL + `/api/salesforce/${pUid}/`

            let response = await axios.get(url, yourConfig)
            
            console.log(response)
            if(response.data.status === 'success') {
                setIsValidId(true)
                setSfModal(false)
                setAccountId(response.data.account_id)
            }

            else {
                setIsValidId(false)
                errorMessage(response.data.message)
            }
        }

        catch (error) {
            setIsValidId(false)
            errorMessage(error.response.data.message)
        }

    }

    async function getUserInstapayPaymentChannels () {
        try {
            let url = REACT_APP_API_URL + `/api/instapay_payment_rails/${pUid}/`;
            let response = await axios.get(url, yourConfig);

            if (response.status == 200) {
                setUserInstapayPaymentChannels(response.data);
                for (const userInstapayChannel of response.data.rails) {
                    if (userInstapayChannel.is_selected) {
                        setUserInstapayPaymentChannel(userInstapayChannel.channel);
                        break;
                    }
                }
            }
        } catch (error) {
            errorMessage('Error fetching user Instapay payment channels');
        }
    }

    async function updateUserInstapayPaymentChannel(channel) {
        try {
            let url = REACT_APP_API_URL + `/api/instapay_payment_rails/${pUid}/`;
            let response = await axios.patch(
                url, 
                {
                    channel: channel,
                },
                yourConfig,
            );
            
            if (response.status == 200) {
                setUserInstapayPaymentChannel(response.data.channel);
                successMessage('Instapay payment channel change successful');
            }
        } catch (error) {
            errorMessage('Instapay payment channel change failed');
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await updateUserInstapayPaymentChannel(userInstapayPaymentChannel);
        setIsLoading(false);
    }

    const getChannelPercentage =  async () => {
        try {
            const res = await axios.get(REACT_APP_API_URL + '/api/'+pUid+'/user_history_stats/', yourConfig);
            if (res && res.data && res.data.status === 'success') {
                setChannelPercentage(res.data.data);
            }
            console.log('CHANNEL PERCENTAGE', res.data.data);
        } catch (error) {
            console.log(error)
            message.error('Error try again later')
        }
    }

    React.useEffect(() => {
        if(localStorage.getItem("userType") != "PS" && window.location.hash === '#channels'){
            getChannels()
            getId()
            getChannelPercentage();
            getUserInstapayPaymentChannels();
        }
    },[window.location.hash === '#channels'])

    const handleUserInstapayPaymentChannelChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (
            name == "payment-instapay-rail" && 
            type === 'radio' && 
            checked && 
            value != userInstapayPaymentChannel
        ) {
            setUserInstapayPaymentChannel(value);
        }
    }

    const successMessage = (msg) => {
        message.destroy();
        message.success(msg, 2);
    }

    const errorMessage = (msg) => {
        message.destroy()
        message.error(msg, 2)
    }
    
    function openModal(cs, name){
        setActiveChannels(cs)
        setActiveMethod(name)
        setShowModal(true)
    }

    function closeModal(){
        setShowModal(false);
        setChannelDisabledName(channelsDisabled);
    }
    
    function openRestoreModal(){
        setRestoreModal(true)
    }

    function closeRestoreModal(){
        setRestoreModal(false)
    }

    function setDisabled(channel_code, tier, channel){
        let disabled = [...channelsDisabled]
        let disabledName = [...channelDisabledName]

        let ind = disabled.indexOf(channel_code);
        let indName = disabledName.indexOf(channel_code);

        // if(ind == -1){
        //     disabled.push(channel_code);
        //     setChannelsDisabled(disabled);
        // }else{
        //     disabled.splice(ind, 1);
        //     setChannelsDisabled(disabled);
        // }

        if(indName == -1) {
            disabledName.push(channel_code);
            setChannelDisabledName(disabledName);
        }

        else {
            disabledName.splice(indName, 1);
            setChannelDisabledName(disabledName);
        }
    }
    console.log('CHANNEL PERCENTAGE', channelPercentage);
    return(
        <div align='center'> 
            <ChannelPercentage data={channelPercentage} />
            <div className={fee.feeCard} align="left">
                <HeaderCard openModal={openRestoreModal} />
                <ChannelCard openModal={openModal} name="Over-the-counter" channels={channels ? channels.filter(channel => channel.channel_type == "Non Bank OTC") : ''} disabled={channelsDisabled}/>
                <ChannelCard openModal={openModal} name="Web Banking" channels={channels ? channels.filter(channel => channel.channel_type == "Banking") : ''} disabled={channelsDisabled}/>
                <ChannelCard openModal={openModal} name="E-Wallet" channels={channels ? channels.filter(channel => channel.channel_type == "EWallet") : ''} disabled={channelsDisabled}/>
                <ChannelCard openModal={openModal} name="Buy Now Pay Later" channels={channels ? channels.filter(channel => channel.channel_type == "BNPL") : ''} disabled={channelsDisabled}/>
                <ChannelCard openModal={openModal} name="Study Now Pay Later" channels={channels ? channels.filter(channel => channel.channel_type == "SNPL") : ''} disabled={channelsDisabled}/>
                <CustomModal pUid={pUid} closeModal={closeModal} showModal={showModal} channels={activeChannels} activeMethod={activeMethod} disabled={channelsDisabled} disabledName={channelDisabledName} setDisabled={setDisabled} successUpdate={() => getChannels()}/>
                <SetDefaultModal closeModal={closeRestoreModal} openModal={restoreModal} errorMessage={errorMessage} />
            </div>
            <div className={fee.feeCard} align="left">
                <InstapayPaymentChannelHeaderCard />
                <div style={{paddingLeft: 20, paddingRight: 20, marginTop: -1}}>
                    <form onSubmit={handleSubmit}>
                        <article className={fee.instapayPaymentChannelCard}>
                            <div className="rail-container">
                                {
                                    userInstapayPaymentChannels && 
                                    userInstapayPaymentChannels.rails && 
                                    userInstapayPaymentChannels.rails.map((rail) => (
                                        <RadioButton
                                            label={rail.description}
                                            name="payment-instapay-rail"
                                            key={rail.channel}
                                            value={rail.channel}
                                            checked={userInstapayPaymentChannel && userInstapayPaymentChannel === rail.channel}
                                            onChange={handleUserInstapayPaymentChannelChange}
                                            disabled={rail.is_disabled}
                                        />
                                    )
                                )}
                            </div>
                        </article>
                        <div className={fee.instapayPaymentChannelSubmitWrapper}>
                            <Button className="button" type="submit" isLoading={isLoading}>
                                Submit
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Channels