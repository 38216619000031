import React from 'react';
import GrantView from '../../../components/AdminComponents/grants/grants'


class Grants extends React.Component {
  render() {
    return (
      <GrantView />
    )
  };
}

export default Grants;