import React from 'react'
import FeeStyles from '../style'
import ChannelList from './list_channels'

const ChannelCard = props => {

    const fee = FeeStyles()

    function openEdit(){
        props.openModal(props.channels, props.name)
    }

    return(
        <div style={{paddingLeft: 20, paddingRight: 20, marginTop: -1}}>
            <div className={fee.innerDiv}>
                <div className={fee.spaceBetween} style={{padding: 20, borderBottom: '1px solid', borderBottomColor: '#E6EAF0'}}>
                    <div>
                        <div><span className={fee.channelHeader}>{props.name}</span></div>
                    </div>

                    <div>
                        <button className={fee.editBtnTrans} onClick={ ()=> openEdit()}>Edit</button>
                    </div>
                </div>

                <ChannelList props={props} />
            </div>
        </div>
    )
}

export default ChannelCard