import React,{ useState } from 'react';
import { Card, Divider, Switch } from 'antd';
import {Handle401} from '../handle401/handle401';
import settingServices from './settingServices';
import { isMobile } from 'react-device-detect'
import emailSupport from '../../static/img/email_support.svg';

const TranxFeeToggle = ({initialSwitchValue, isEnterprise}) => {
  const [isSwitchOn, setIsSwitchOn] = useState(initialSwitchValue);
  const [isSwitchMovable, setIsSwitchMovable] = useState(! isEnterprise);

  const handleChange = (event) => {
    setIsSwitchMovable(false);
    valueUpdate(!isSwitchOn);
  }

    async function valueUpdate(value){

        let params = {
            "shoulder_enabled": value
        }

        try{
            let res = await settingServices.transactionFeeToggle(params);
            
            if (res.status == "success"){
              setIsSwitchOn(value);
            }
        }
        catch(error){
            if((error.response)&&(error.response.status == 401)){
                Handle401()
            }
        }
        
        setIsSwitchMovable(true);
        
    }
  return (
    <div>
    {
      isMobile
      ?
        <div>
          <div style={styles.twoCol}> 
            <div style={styles.font3}>
              Transaction Fee
            </div>
            <div>
              <Switch checked={isSwitchOn} onChange={handleChange} style={{margin: '12px 20px 12px 12px', backgroundColor: `${isSwitchOn ? '#F5922F' : '#D1D5DD'}`}} disabled={! isSwitchMovable}/>
            </div>
          </div>

          <div style={styles.textContent}>
            {isSwitchOn
            ?
              <p>Your Transaction Fees are currently <b>enabled</b>. The transaction fee will be deducted from the total amount requested.</p>
            :
              <p>Your Transaction Fees are currently <b>disabled</b>. The transaction fee will be added to the total amount upon customer checkout.</p>
            }
          </div>

          <div hidden={! isEnterprise}>
            <p style={{marginBottom: '3em', fontSize: 'smaller', fontStyle: 'italic'}}>To have this changed, please contact us at 
              <a href="mailto:support@bux.ph?subject=Request to adjust transaction fees" style={{color: '#f5922f'}}>
                <strong> support@bux.ph</strong>
              </a>
            </p>
          </div>
        </div>

      :
        <Card size="small" style={{...styles.root, width: 700}}>
          <div style={styles.main}>
            <div style={styles.textContent}>
              <strong style={styles.font1}>Transaction Fee</strong>
              { isSwitchOn
              ?
                <div>
                  <label style={styles.font2}>Your Transaction Fees are currently <strong>enabled</strong>.</label><br/>
                  <label style={styles.font2}>The transaction fee will be deducted from the total amount requested.</label>
                </div>
              :
                <div>
                  <label style={styles.font2}>Your Transaction Fees are currently <strong>disabled</strong>.</label><br/>
                  <label style={styles.font2}>The transaction fee will be added to the total amount upon customer checkout.</label>
                </div>
              }
            </div>
            <Switch 
              checked={isSwitchOn} 
              onChange={handleChange} 
              style={{marginRight: 5, backgroundColor: `${isSwitchOn ? '#F5922F' : '#D1D5DD'}`}}
              disabled={! isSwitchMovable}
            />
          </div>
          <Divider style={{margin: "15px 0px 10px 0px"}} hidden={! isEnterprise} />
          <div hidden={! isEnterprise}>
            <img src={emailSupport} alt="Email Us" style={{height: 65, paddingLeft: 8}} />
            <label style={{...styles.font2, marginLeft: 10}}>If you want to have this changed, please contact us at 
              <a href="mailto:support@bux.ph?subject=Request to adjust transaction fees" style={{color: '#f5922f'}}>
                <strong> support@bux.ph</strong>
              </a>
            </label>
          </div>
        </Card>
    }
    </div>
  )
}

const styles = {
  root: {
    margin: '0px 0px 20px 0px'
  },
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: "3px 0px 3px 5px"
  },
  textContent: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  font1: {
    color: '#0D3D76',
    fontSize: 16,
    marginBottom: 10
  },
  font2: {
    color: '#2B2D33',
    fontSize: 14,
    textAlign: 'left'
  },
  
  font3: {
    color: '#0D3D76',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '32px'
  },

  twoCol: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}

export default TranxFeeToggle;