import React from 'react';
import {Button} from 'antd';
import {history} from '../../store/history'

class VerifiedEmail extends React.Component{

    verifyStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '34px',
        color: '#2B2D33',
        marginBottom: '10px'
    }

    textStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.01em',
        color: '#2B2D33',
        opacity: '0.8',
        marginBottom: '20px'
    }

    signupBtnStyle = {        
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        height: '50px',
        marginTop: '20px',
        width: '100%',
        backgroundColor: '#F5922F'
    }

    dontHaveStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#2B2D33',
        display: 'flex',
        marginTop: '20px',
    }

    render() {
        return(
            <div style={{display:'flex', justifyContent: 'center'}}>
                <div style={{paddingLeft: '57px',paddingRight: '57px', paddingTop: '10vh', justifyContent: 'center', alignItems:'center', width: '559px'}}>
                    <div style={this.verifyStyle}>Verify Account</div>
                    <div style={this.textStyle}>Before continuing, we need to verify your email address. Please check your inbox for a confirmation link.</div>
                    <span>Haven't receive a verification link? <a style={{color: '#F5922F'}} onClick={()=>history.push('/resend_verification')}>click here to resend</a></span>
                    <Button type="primary" style={this.signupBtnStyle} onClick = {() => history.push('/login')}>Done</Button>
                </div>
            </div>
        )
    }
}

export default VerifiedEmail