import React, {useState, useEffect} from 'react'
import * as Style from '../../../components/orders/orderStyle'
import moment from 'moment'
import MetaTag from '../../../components/meta_tag/meta_tag'
import {Button, DatePicker, Input, Icon, Select, Table, Dropdown, Menu, Modal, Radio, message, Alert, Typography, Divider} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {getOrders} from '../../../components/orders/orderAction'
import Visa from '../../../static/icons/card_type/visa.svg'
import MasterCard from '../../../static/icons/card_type/mastercard.svg'
import {history} from '../../../store/history'
import axios from 'axios'
import Pagination from '@material-ui/lab/Pagination';
import crypto from 'crypto';
import Info from '../../../static/icons/info.svg'
import ExportModal from './export_modal'
import GroupButton from '../../../components/custom_components/groupButton'
import NoteCard from '../../../components/kyb/forms/noteCard'

const ButtonGroup = Button.Group;
const {Option} = Select
let requestToken = ""

const CreditDebit = () => {
    const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
    const segments = localStorage.getItem('segments');

    const dispatch = useDispatch();
    const orders = useSelector(state => state.orders.orders)
    const summary = useSelector(state => state.orders.summary)
    const token = useSelector(state => state.orders.token)

    const ubp_enabled = localStorage.getItem('cc_enabled') == 'UnionBank';
    const xendit_enabled = localStorage.getItem('cc_enabled') == 'Xendit';

    const testMode = (history.location.pathname.indexOf('/test') > -1 && localStorage.getItem("userType") == "CO")
    //Card and Tab Filter 
    const [tabSelected, setTabSelected] = useState("Charged")
    const [cardSelected, setCardSelected] = useState("All")
    const [summarySelected, setSummarySelected] = useState("Today")

    //Date Filter
    const [startMoment, setStartMoment] = useState("")
    const [startDate, setStartDate] = useState("")

    const [endMoment, setEndMoment] = useState("")
    const [endDate, setEndDate] = useState("")

    const [endOpen, setEndOpen] = useState("")

    //Input Filter
    const [searchValue, setSearchValue] = useState("")

    //Refund Modal
    const [refundVisible, setRefundVisible] = useState(false)
    const [reason, setReason] = useState("Item/s no longer available")
    const [idToBeCancel, setIDtoBeCancel] = useState('')
    const [otherText, setOtherText] = useState('')

    //Page
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    const [isExportModalOpen, setIsExportModalOpen] = useState(false)
    const [email, setEmail] = useState("")
    const [isApplyDisabled, setIsApplyDisabled] = useState(true)

    useEffect(()=>{
        // setPageSize(summary.page_total)
        dispatch(getOrders(generateToken(), tabSelected,1,pageSize, startDate, endDate, testMode, summarySelected, searchValue, "", "All","", cardSelected, true))
    },[])

    const generateToken = () => {
      let generatedRequestToken = crypto.randomBytes(16).toString('hex');
      requestToken = generatedRequestToken
      return generatedRequestToken
    }

    const disabledStartDate = startValue => {
      if (!startValue || !endMoment) {
        return false;
      }
      return startValue && startValue > moment().endOf('day');
    };
  
    const disabledEndDate = endValue => {
      if (!endValue || !startMoment) {
        return false;
      }
      return endValue.valueOf() <= startMoment.valueOf();
    };
    
    const onStartChange = value => {
      if(value != null && endDate != ""){
        setStartMoment(value)
        setStartDate(value.format("MM-DD-YYYY"))
        setSummarySelected("Custom")
        setIsApplyDisabled(false)
        setCurrentPage(1)
      }
      else if(value != null){
        setStartMoment(value)
        setStartDate(value.format("MM-DD-YYYY"))
        setSummarySelected("Custom")
      }
      else{
        setStartMoment(null)
        setStartDate("")
        setIsApplyDisabled(false)
        setCurrentPage(1)
      }
    }
    const onEndChange = value => {
      if(value != null && startDate != ""){
        setEndMoment(value)
        setEndDate(value.format("MM-DD-YYYY"))
        setSummarySelected("Custom")
        setIsApplyDisabled(false)
        setCurrentPage(1)
      }
      else{
        setEndMoment(null)
        setEndDate("")
        setIsApplyDisabled(false)
        setCurrentPage(1)
      }
    }
  
    const handleStartOpenChange = open => {
      if (!open) {
        setEndOpen(true)
      }
    };
  
    const handleEndOpenChange = open => {
      setEndOpen(open)
    };


    const refundOrderNow = async() => {
      let params = {
        id: idToBeCancel,
        reason: reason,
        otherText: otherText
      }
      let yourConfig = {
          headers: {
              Authorization: "Token " + localStorage.getItem("jwtToken")
          }
      }

      const url = '/api/orders/refund/';
      let response = await axios.post(process.env.REACT_APP_API_URL + url, params, yourConfig);
      
      if(response.data.status == "success"){
        setRefundVisible(false)
        message.success("Successfully requested refund")
        dispatch(getOrders(generateToken(), tabSelected,1,pageSize, startDate, endDate, testMode, summarySelected, searchValue, "", "All","", cardSelected, true))
        setCurrentPage(1)
      }else{
        message.error(response.data.message)
      }
    }

    const is_admin = () =>{
        return ["AD", "OP", "PS", "FI"].includes(localStorage.getItem("userType")); 
    }
    
    const charged_menu = (id,uid) => (
      <Menu style={{ width: '180px' }}>
        <Menu.Item key="0" style={Style.menuStyle} onClick={() => history.push(`${testMode?'/test/payment/':'/payment/'}`+uid+`/`)}>
          Customers Receipt
        </Menu.Item>
        <Menu.Item key="1" onClick={()=>history.push(`/charged_details/${id}/${uid}`)}style={Style.menuStyle}>
          Charge Details
        </Menu.Item>
        {
          tabSelected == "Charged" && !testMode && xendit_enabled &&
          <Menu.Item key="2" onClick={() => {setIDtoBeCancel(id); setRefundVisible(true)}} style={Style.menuStyle}>
            Issue Refund
          </Menu.Item>
        }
      </Menu>
    )
    
    const rowStyle = {
      fontSize: '14px', 
      color: '#2b2d33', 
      fontWeight: '500'
    }
    const columns = [
        {
            title: 'Charge ID / Reference Code',
            width: is_admin()  ? '18%' : '20%',
            render: (record) => {
              return <div style={rowStyle}> {record.code} </div>
            }
        },
        {
            title: tabSelected == 'Refunded' ? 'Amount Refunded' : 'Amount',
            width: tabSelected == 'Refunded' ? '18%' : '15%',
            render: (record) => {
              return <div style={{fontSize: '14px', color: '#2b2d33', fontWeight: '500'}}>₱ {record.amount} </div>
            }
        },
        {
            title: 'Card',
            width: is_admin()  ? '15%' : '18%',
            render: (record) => {
              return <div style={rowStyle}>
                      <img style={{marginRight: '10px'}} 
                        alt='card' 
                        src={(record.payload['card_brand'] === 'VISA' || record.payload['card_type'] === 'Visa') ? Visa : MasterCard}/>
                          •••• {
                            xendit_enabled ? record.payload['last_four_digits']
                            :
                            record.payload['last_four_digits'].slice(-4)
                          } 
                    </div>
            }
        },
        {
            title: 'Date Paid',
            width: is_admin() || tabSelected == "Refunded" ? '15%' : '20%',
            render: (record) => {
              return <div style={rowStyle}> {record.date_paid} </div>
            }
        },

        tabSelected == "Refunded" ?
        {
            title: 'Date Refunded',
            width: is_admin()  ? '13%' : '15%',
            render: (record) => {
              return <div style={rowStyle}> {record.cancelled_at} </div>
            }
        } : {} ,

        {
            title: 'Action',
            width: '15%',
            render: (record) => (
                <span>
                    <Dropdown overlay={() => charged_menu(record.id, record.uid)} placement="bottomCenter" trigger={['click']} >
                        <Icon type="ellipsis" style={{fontSize: '24px'}} />
                    </Dropdown>
                </span>
            )
        },
    ]

    const exportTable = async() => {
      let url = ''
      {
          testMode ?
          url = `/api/sandbox/orders/credit_cards/?card_type=${cardSelected}&type=` :
          url = `/api/orders/credit_cards/?card_type=${cardSelected}&type=`
      }
      let yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
  
      }
      let response = await axios.get(process.env.REACT_APP_API_URL+url+tabSelected+`&summary=${summarySelected}&page=1&page_size=10&email=${email}&start=${startDate}&end=${endDate}&search=${searchValue}&payment_methods=""&user_type="All"&is_export=true`, yourConfig);
      
      if(response.data.status=='success'){
        message.success("CSV reports successfully sent to your email address")
        setIsExportModalOpen(false)
      }else{
        message.error(response.data.message)
      }
    };

    const applyFilter = () => {
      dispatch(getOrders(generateToken(), tabSelected,1,pageSize, startDate, endDate, testMode, summarySelected, searchValue, "", "All","", cardSelected, true))

    }

    const clearFilter = () => {
      setIsApplyDisabled(true)
      setSummarySelected("All")
      setCardSelected("All")
      setStartMoment("")
      setEndMoment("")
      setSearchValue("")
      dispatch(getOrders(generateToken(), tabSelected,1,pageSize, startDate, endDate, testMode, "All", searchValue, "", "All","", cardSelected, true))
    }

    const tabs = ["Charged", "Refunded"]

    const changePage = (event,page) => {
      setCurrentPage(page)
      dispatch(getOrders(generateToken(), tabSelected,page,pageSize, startDate, endDate, testMode, summarySelected, searchValue, "", "All","", cardSelected, true))
    }

    
    return(
        <div>
            <div style={{padding: '32px 24px 32px 24px'}}>
                <MetaTag title="Payment Request"/>
                <div style={{ marginBottom: '25px', display: 'flex', justifyContent:'space-between'}} hidden={ubp_enabled}>
                      
                      <GroupButton status={tabs} selected={tabSelected} segments={segments} handleClick={(e) => 
                        {
                          setCurrentPage(1)
                          setTabSelected(e.target.value)
                          dispatch(getOrders(generateToken(), e.target.value, 1, pageSize, startDate, endDate, testMode, summarySelected, searchValue, "", "All","", cardSelected, true))
                        }}/>
               
                      <div>
                        <button className={`outline-btn--${segments}`} style={style.exportBtn} onClick={()=>setIsExportModalOpen(true)}><Icon type='download'/> Download CSV</button>
                      </div>
                </div>

                <div align="right" hidden={xendit_enabled} className="bottom-20">
                  <button className={`outline-btn--${segments}`} style={style.exportBtn} onClick={()=>setIsExportModalOpen(true)}><Icon type='download'/> Download CSV</button>
                </div>

                <div style={{paddingBottom: '20px'}}>
                  {
                    tabSelected === 'Refunded' ?
                      <NoteCard note={
                        <span>Refund option is only applicable to credit and debit card transactions. Contact 
                          <a href="mailto:support@bux.ph?subject=Refund in card payments" className="right-4 left-4 liveColor">
                            <strong>support@bux.ph</strong>
                          </a>
                          for your refund request.
                        </span>
                      }/>
                    :
                      <NoteCard note={
                        ubp_enabled ? 
                        `BUx processing fees are additional fees on top of UnionBank MDR fees which BUx will charge separately,  
                        For refund transactions, go to your EBC dashboard. Refund / voided transactions will not reflect in BUx.`
                        :
                        <span>
                          To view settlement and receivables of your transactions and payout, please go directly to your <a style={{textDecoration: 'underline', color: '#000000', fontWeight: 'bold'}} href="https://dashboard.xendit.co/login" target="blank">Xendit Dashboard.</a>
                        </span>
                      }/>
                  }
                </div>
        
                <div style={{backgroundColor: '#fff', padding: "8px 20px", borderBottom: '1px solid #D1D5DD',height: '100%', width: '100%' }}>
                  <div style={Style.filterDiv}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                          <Typography style={style.filter}>Filter</Typography>
                          <a style={style.clearBtnDiv} hidden={isApplyDisabled} onClick={()=>clearFilter()}>
                              <Icon style={style.clearIcon} type="close-circle" theme="filled" />
                              <span style={style.clearBtn}>Clear Filter</span>
                          </a>
                      </div>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                    <div style={{display: 'flex'}}>
                        <div 
                          hidden={localStorage.getItem("userType") != "ME" && localStorage.getItem("userType") != "CO"}>
                          <Select 
                            size="large"
                            value={summarySelected}
                            onChange={
                              (e)=> {
                                setSummarySelected(e)
                                setCurrentPage(1)
                                setIsApplyDisabled(false)
                              }
                            }
                            style={{width:'170px', marginRight: '12px', zIndex: 1, color: '#000'}}
                          >
                            <Option value="All">All Time</Option>
                            <Option value="Today">Today</Option>
                            <Option value="Week" >Last 7 days</Option>
                            <Option value="Month" >Last 30 days</Option>
                            <Option value="Custom" >Custom</Option>
                          </Select>
                        </div>
                        <Select 
                            size="large"
                            value={cardSelected}
                            placeholder="Card Type"
                            onChange={
                            (e)=> {
                                setCurrentPage(1)
                                setCardSelected(e)
                                setIsApplyDisabled(false)
                            }
                            }
                            style={{width:'143px', marginRight: '12px', zIndex: 1, color: '#000'}}
                        >
                            <Option value="All">Card Type</Option>
                            <Option value={ubp_enabled ? "Visa" : "VISA"} >Visa</Option>
                            <Option value={ubp_enabled ? "Mastercard" : "MASTERCARD"}>MasterCard</Option>
                        </Select>

                        <DatePicker
                            style={{zIndex: 1, width: '179px'}}
                            disabledDate={disabledStartDate}
                            size="large"
                            format='MM-DD-YYYY'
                            value={startMoment}
                            placeholder="Start Date"
                            onChange={onStartChange}
                            onOpenChange={handleStartOpenChange}
                            suffixIcon={<div style={style.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        />
                        <span style={{padding: '8px'}}>-</span> 
                        <DatePicker
                            style={{zIndex: 1, marginRight: '12px',width: '179px'}}
                            disabledDate={disabledEndDate}
                            size="large"
                            format='MM-DD-YYYY'
                            value={endMoment}
                            placeholder="End Date"
                            onChange={onEndChange}
                            open={endOpen}
                            onOpenChange={handleEndOpenChange}
                            suffixIcon={<div style={style.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        />
                        <Input
                            placeholder="Search"
                            style={style.searchInput}
                            suffix={<Icon type="search" style={{ fontSize: '18px', paddingRight: '8px'}} />}
                            onChange={(e)=>setSearchValue(e.target.value)}
                            value={searchValue}
                        />
                        <button
                            className={`btn--${segments}`} 
                            style={style.applyBtn} 
                            onClick={()=>applyFilter()}
                        >Apply</button>
                    </div>
                  </div>
                  <Divider/>
                  <div style={{display: 'flex', margin: '-10px 0px 20px 20px'}}>
                      <div style={{display: 'flex', marginRight: '32px'}}>
                          <Typography style={style.text}>No. of Paid Transactions: </Typography>
                          <Typography style={style.text}><b>{summary && summary.transaction}</b></Typography>
                      </div>
                      <div style={{display: 'flex', marginRight: '32px'}}>
                          <Typography style={style.text}>Total Amount: </Typography>
                          <Typography style={style.text}><b>₱ {summary && parseFloat(summary.total).toLocaleString('en-US', {minimumFractionDigits: 2})}</b></Typography>
                      </div>
                  </div>
                  <Table
                    size="middle"
                    dataSource={token == requestToken && orders}
                    pagination={{position: 'none'}} 
                    rowClassName="table-row-light"
                    columns={columns}/>
                  {
                    summary && orders.length > 0 && 
                    <div style={{marginTop: '20px', display:'flex', justifyContent:'space-between'}} >
                      <div>
                      {(currentPage - 1) * 10  + 1} 
                      - 
                      {(currentPage - 1) * 10  + pageSize} out of {summary.total_data}
                      </div>
                      <Pagination 
                        count={summary.pages} 
                        shape="rounded" 
                        size="small"
                        page={currentPage}
                        onChange={changePage}
                      />
                    </div>
                  }
                </div>
            </div>
            
            <ExportModal 
              segments={segments}
              isExportModalOpen={isExportModalOpen} 
              setIsExportModalOpen={setIsExportModalOpen} 
              exportTable={exportTable} 
              email={email} 
              setEmail={setEmail} />

            <Modal
              visible={refundVisible}
              footer={null}
              onCancel={()=>setRefundVisible(false)}
            >
              <div style={{ padding: '40px 30px 40px 30px' }}>
                <div style={Style.cancelOrder}>Refund</div>
                <div style={Style.choicesStyle}>
                Are you sure you want to refund this transaction? This can’t be undone.
                </div>
                <div style={Style.choicesStyle}>Provide a reason</div>
                <div style={{marginBottom: '10px'}}>
                  <Radio.Group onChange={e => setReason(e.target.value)} value={reason}>
                    <Radio style={Style.radioStyle} value="Item or service is no longer available">
                      Item or service is no longer available
                        </Radio>
                    <Radio style={Style.radioStyle} value="Wrong item or service selected">
                      Wrong item or service selected
                        </Radio>
                    <Radio style={Style.radioStyle} value="Unable to deliver the item or service">
                      Unable to deliver the item or service
                        </Radio>
                    <Radio style={Style.radioStyle} value="Payment was declined">
                      Payment was declined
                        </Radio>
                    <Radio style={Style.radioStyle} value="Others">
                      Others <Input hidden={reason!="Others"} onChange={e=>setOtherText(e.target.value)}/>
                      </Radio>
                  </Radio.Group>
                </div>
                <div>
                  <Button type="danger" style={Style.cancelButtonStyle} onClick={() => refundOrderNow()}>Proceed with Refund</Button>
                </div>
                <Button style={Style.dontCancelButton} onClick={()=>setRefundVisible(false)}>Cancel</Button>
              </div>
            </Modal>
        </div>
    )

}

const style = {
  exportBtn: {
    cursor: 'pointer',
    margin: 0,
    fontSize: '16px',
    fontWeight: '500',
    width: '166px',
    height: '40px'
  },
  applyBtn: {
    zIndex: 1, 
    height: 40,
    fontSize: 14,
    fontWeight: '500',
    borderRadius: 4,
    width: 72,
    cursor: 'pointer',
  },
  suffixIcon: {
    marginTop: '-12px',
    marginRight: 12,
    color: '#000'
  },
  searchInput:{
    zIndex: 1, 
    width: '180px', 
    height: '40px',
    borderRadius: '4px', 
    padding: '0 8px 0 0px' 
  },
  text: {
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: '24px',
    color: '#2B2D33',
    marginRight: '10px'
  },
  filter: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#000'
  },
  clearBtnDiv: {
    backgroundColor: '#E9EEF4',
    borderRadius: 20,
    padding: '6px 12px',
    margin: '0 12px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #D4D5D8'
  },
  clearBtn: {
      color: '#2b2d32',
      fontSize: 14,
      paddingLeft: 4,
      fontWeight: '500',
      opacity: '0.8'
  },
}

export default CreditDebit
