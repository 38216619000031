import { combineReducers } from 'redux'
const initialState = {
  otpParams: null,
  otpSuccess: false,
  otpUID: '',
  walletBalances: null,
  analytics: null,
  transferMoneyData: null,
  transMoneyBoxData: [],
  enableTransferBtn: false,
  totalTransBoxAmount: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VISIBLE_SUCCESS_LOAD_MODAL':
      return {
        ...state,
        successLoadModal: !!initialState.successLoadModal,
      }
    case 'SET_BALANCES':
      return {
        ...state,
        walletBalances: action.payload,
      }
    case 'SET_ANALYTICS':
      return {
        ...state,
        analytics: action.payload,
      }
    case 'SET_TRANSFER_MONEY_DATA':
      return {
        ...state,
        transferMoneyData: action.payload,
      }
    case 'SHOW_MODAL':
      return {
        ...state,
        [action.payload]: true,
      }
    case 'HIDE_MODAL':
      return {
        ...state,
        [action.payload]: false,
      }
    case 'SET_OTP_PARAMS':
      return {
        ...state,
        otpParams: action.payload,
      }
    case 'SET_OTP_SUCESS':
      return {
        ...state,
        otpSuccess: action.payload,
      }
    case 'SET_OTP_UID':
      return {
        ...state,
        otpUID: action.payload,
      }
    case 'SET_TRANSFER_MONEY_BOX':
      return {
        ...state,
        transMoneyBoxData: action.payload,
      }
    case 'SET_TRANSFER_BTN':
      return {
        ...state,
        enableTransferBtn: action.payload,
      }
    case 'SET_TRANSFER_BOX_TOTAL_AMOUNT':
      return {
        ...state,
        totalTransBoxAmount: action.payload,
      }
    default:
      return state;
  }
}