import { Button, Card, Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import { notification } from "antd";
import { getLimitsHistory, requestLimitUpdate } from "./api";
import { getCurrentLimits } from "../merchant_admin/api/getCurrentLimits";
import { extractSubdomain } from "../../../multiwallet/extractSubdomain";
import { StatusChip, LimitTypeChip, UserDataCell } from "../../components";
import NewLimitModal from "./components/NewLimitModal";

const adminLimitsStatusMap = {
  SU: "Success",
  FA: "Declined",
  PE: "Pending",
};

export function AdminLimits() {
  const [limitsRequest, setLimitsRequest] = useState(null);
  const [enterprisePeers, setEnterprisePeers] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const [formData, setFormData] = useState({
    daily_limit: "",
    transfer_limit: "",
    ownerId: "",
  });

  // Modal states
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    const currentUrl = window.location.href;
    const extractedSubdomain = extractSubdomain(currentUrl);
    getCurrentLimits(extractedSubdomain).then((data) => {
      setEnterprisePeers(data);
    });
  }, []);

  useEffect(() => {
    getLimitsHistory(currentPage).then((data) => {
      setLimitsRequest(data);
    });
  }, [currentPage]);

  const openNotification = (status, message) => {
    let header = status.charAt(0).toUpperCase() + status.slice(1);
    notification.info({
      message: header,
      description: message,
      placement: "topRight",
    });
  };

  const tableColumns = [
    {
      title: "Reference ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
    },
    {
      title: "Receiver",
      dataIndex: "owner_email",
      key: "owner_email",
      render: (text, record) => (
        <UserDataCell name={record.owner_name} email={record.owner_email} />
      ),
    },
    {
      title: "Requestor",
      dataIndex: "requestor_email",
      key: "requestor_email",
      render: (text, record) => (
        <UserDataCell
          name={record.requestor_name}
          email={record.requestor_email}
        />
      ),
    },
    {
      title: "Date Generated",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toLocaleString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });

        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <StatusChip status={text} label={adminLimitsStatusMap[text] || text} />
      ),
    },
    {
      title: "Limit Type",
      dataIndex: "limit_type",
      key: "limit_type",
      render: (text) => <LimitTypeChip label={text} />,
    },
    {
      title: "Previous Limit",
      dataIndex: "previous_limit_value",
      key: "previous_limit_value",
    },
    {
      title: "Requested Limit",
      dataIndex: "requested_limit_value",
      key: "requested_limit_value",
    },
    {
      title: "Updated By",
      dataIndex: "approver_email",
      key: "approver_email",
      render: (text, record) => (
        <UserDataCell
          name={record.approver_name}
          email={record.approver_email}
        />
      ),
    },
  ];

  const showModal = () => setOpen(true);
  const handleOk = (form) => {
    setConfirmLoading(true);

    requestLimitUpdate(formData)
      .then((res) => {
        setOpen(false);
        openNotification(res.status, res.message);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        getLimitsHistory(currentPage).then((data) => {
          setLimitsRequest(data);
        });
        setFormData({
          daily_limit: "",
          transfer_limit: "",
          ownerId: "",
        });
        form.setFieldsValue({
          subwallet: "",
          daily: "",
          transaction: "",
        });
        setConfirmLoading(false);
        setOpen(false);
      });
  };
  const handleCancel = () => {
    setFormData({
      daily_limit: "",
      transfer_limit: "",
      ownerId: "",
    });
    setOpen(false);
  };
  const updateFields = (fields) => {
    setFormData((prev) => {
      return { ...prev, ...fields };
    });
  };

  return (
    <div
      style={{
        margin: "5em 2.5em 2.5em 0",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1
          style={{
            width: "100%",
            color: "rgb(0, 0, 0)",
            fontWeight: "bold",
            fontSize: "28px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Admin Limits
        </h1>

        <Button
          onClick={showModal}
          type="primary"
          htmlType="submit"
          size="large"
          style={{
            backgroundColor: "rgb(0, 81, 176)",
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          Set New Limit
        </Button>
      </div>

      <Card>
        <Table
          rowClassName="table-row-light"
          columns={tableColumns}
          dataSource={limitsRequest ? limitsRequest.results : []}
          pagination={false}
          loading={false}
          scroll={{ x: true }}
          style={{ maxWidth: "82dvw" }}
        />
        <Pagination
          size="small"
          total={(limitsRequest && limitsRequest.total) || 0}
          defaultPageSize={pageSize}
          pageSize={pageSize}
          current={currentPage}
          onChange={setCurrentPage}
          style={{ paddingTop: "12px", textAlign: "right" }}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
        />
      </Card>
      <NewLimitModal
        {...formData}
        open={open}
        handleOk={handleOk}
        confirmLoading={confirmLoading}
        handleCancel={handleCancel}
        updateFields={updateFields}
        enterprisePeers={enterprisePeers}
      />
    </div>
  );
}
