import React,{useEffect, useRef} from 'react'
import moment from 'moment';
import axios from 'axios'
import * as Style from './complete_profile_style'
import {Card, Input, Button, 
    DatePicker, Checkbox, Select, Alert,  
    Collapse, Modal, Upload, message, Spin, Icon} from 'antd'
import {Grid} from '@material-ui/core'
import {NATURE_BUSINESS, POSITION} from '../constants/constants'
import mUploadIDImg from '../../static/img/mupload_id.svg'
import mSelfieImg from '../../static/img/mselfie.svg'
import tipsImage from '../../static/img/tipsImage.svg'
import uFail from '../../static/img/upgradeFail.svg'
import UpgradeAccount from '../../static/img/upgrade_account.svg'
import CameraIcon from '../../static/img/cameraImg.svg'
import Submitted from '../../static/icons/submitted.svg'
import TipsIcon from '../../static/icons/tips.svg'
import {history} from '../../store/history';
import {browserName, isIOS, isSafari, isMobileSafari } from 'react-device-detect';
import MetaTag from '../meta_tag/meta_tag'
import {ADDRESS} from '../constants/constants'

const {Option} = Select
const max_width = 1000;
const max_height = 1000;

const fb_browser = (browserName == 'Facebook') || (browserName == 'WebKit')


const ReviewApplication = props => {

    const canvasRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(()=>{
        let current_path = window.location.pathname
        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            history.push('/login?next='+current_path)
          }

        if(fb_browser){
            window.location.href = process.env.REACT_APP_BASE_URL + "/complete_profile/"
        }

        else{
        fetchProfile()

          }
    },[playerRef, canvasRef])


    const [messageModal, setMessageModal] = React.useState('')
    const [blockModal, setBlockModal] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [submittedModal, setSubmittedModal] = React.useState(false)
    const [tipsModal, setTipsModal] = React.useState(false)
    const [cameraModal, setCameraModal] = React.useState(false)
    const [firstName, setFirstName] = React.useState("")
    const [middleName, setMiddleName] = React.useState("")
    const [lastName, setLastName] = React.useState("")
    const [mothersMaidenName, setMothersMaidenName] = React.useState("")
    const [birthDateM, setBirthDateM] = React.useState("")
    const [birthDateD, setBirthDateD] = React.useState("")
    const [birthDateY, setBirthDateY] = React.useState("")
    const [birthPlace, setBirthPlace] = React.useState("")
    //present address
    const [presentStreet, setPresentStreet] = React.useState("")
    const [presentBarangay, setPresentBarangay] = React.useState("")
    const [presentCity, setPresentCity] = React.useState("")
    const [presentProvince, setPresentProvince] = React.useState("")
    const [presentPostCode, setPresentPostCode] = React.useState("")

    //permanent address
    const [permaStreet, setPermaStreet] = React.useState("")
    const [permaBarangay, setPermaBarangay] = React.useState("")
    const [permaCity, setPermaCity] = React.useState("")
    const [permaProvince, setPermaProvince] = React.useState("")
    const [permaPostCode, setPermaPostCode] = React.useState("")

    const [permanentAddressC, setPermanentAddressC] = React.useState(false)
    const [gender, setGender] = React.useState("M")
    const [nationality, setNationality] = React.useState("")
    const [civilStatus, setCivilStatus] = React.useState("")

    const [mobileNumber, setMobileNumber] = React.useState("")
    const [emailAddress, setEmailAddress] = React.useState("")

    const [step, setStep] = React.useState(0)

    const [employmentStatus, setEmploymentStatus] = React.useState("0")
    const [employerName, setEmployerName] = React.useState("")
    const [employerNatureBusiness, setEmployerNatureBusiness] = React.useState("")
    const [occupation, setOccupation] = React.useState("")
    const [sourceFunds, setSourceFunds] = React.useState("0")

    const [empStreet, setEmpStreet] = React.useState("")
    const [empBarangay, setEmpBarangay] = React.useState("")
    const [empCity, setEmpCity] = React.useState("")
    const [empProvince, setEmpProvince] = React.useState("")
    const [empPostCode, setEmpPostCode] = React.useState("")
    const [unemployed, setUnemployed] = React.useState(false)

    const [idType, setIdType] = React.useState("0")
    const [idNumber, setIdNumber] = React.useState("")
    const [idFormatError, setIdFormatError] = React.useState(false)

    const [loadingUploadID, setLoadingUploadID] = React.useState(false)
    const [loadingUploadBackID, setLoadingUploadBackID] = React.useState(false)
    const [loadingUploadSelfie, setLoadingUploadSelfie] = React.useState(false)

    const [idURL, setIdURL] = React.useState("")
    const [backURL, setBackURL] = React.useState("")
    const [selfieBlob, setSelfieBlob] = React.useState("")

    const [idValid, setIdValid] = React.useState()
    const [backValid, setBackValid] = React.useState()
    const [selfieValid, setSelfieValid] = React.useState()

    const [alertMsg, setAlertMsg] = React.useState("")
    const [alertType, setAlertType] = React.useState("")
    const [alertID, setAlertID] = React.useState(true)

    const [remarks, SetRemarks] = React.useState("")

    const Gender = {
        'F': 'Female', 
        'M': 'Male'
    }
       const CivilStatus = {
        'S': 'Single', 
        'M': 'Married',
        'W': 'Widowed', 
        'D': 'Divorced'
    }

    const EmploymentStatus = {
        'EMP': 'Employed',
        'CON': 'Consultant',
        'SEB': 'Self-employed (Business)',
        'SEF': 'Self-employed (Freelancer)',
        'UEH': 'Unemployed (Housewife)',
        'UER': 'Unemployed (Retired)',
        'UES': 'Unemployed (Student)'
    }

    const NatureOfWork = {
        'ADM': 'Administrative/Human Resources',
        'AGRI': 'Agriculture',
        'BNK': 'Banking Institutions',
        'IT': 'Computer and Information Technology Services',
        'CONS': 'Construction/Contractors',
        'AGN': 'Consultancy/Agencies',
        'EDUC': 'Education',
        'ENG': 'Engineering',
        'ENT': 'Entertainment',
        'FIN': 'Financial Services',
        'FOV': 'Government',
        'HRS': 'Hotel and Restaurant Services',
        'HSE': 'Household Employees',
        'INV': 'Manufacturing and Inventory',
        'MED': 'Medical and Health Services',
        'SOC': 'Community, Social, Personal Services',
        'OTH': 'Others',
        'PR': 'Public Relations',
        'EST': 'Real Estate',
        'RENT': 'Rental Services',
        'MKTG': 'Sales/Marketing/Advertising',
        'SCI': 'Science and Technology Services',
        'STD': 'Student',
        'TCS': 'Transportation and Communication Services'
    }

    const SourceFunds  = {
        '001': 'Allowance',
        '002':'Business Profit',
        '003':'Commissions',
        '004':'Inheritance',
        '005':'Loan',
        '006':'Salary',
        '007':'Pension',
        '008':'Remittance'
    }

    const IDType = { 
        'SSS': 'SSS',
        'GSIS': ' GSIS',
        // 'TIN': 'TIN',
        'DRL': `Driver's License`,
        'AICR': ' ACR',
        // 'NBI': 'NBI',
        'PAS': 'Passport',
        // 'PHID': ' Philhealth ID',
        // 'SCH': 'School ID',
        'UMID': ' UMID',
        // 'VID': 'Voters ID',
        'PRC': 'PRC ID',
        'POS': 'Digitize Postal ID'
    }


    const SourceOfFunds = ['Salary','Business Income','Rental Income','Investment',
                            'Personal Savings','Inheritance','Sale of Property','Pension','Others']
    const IdType = ['Drivers License', 'Passport', 'TIN ID', 'SSS or UMID', 'GSIS', 'PRC ID', 'IBP ID',
    'OWWA ID', 'Diplomat ID', 'OFW ID', 'Senior Citizen ID', 'Voters ID', 'Govt Office ID',
    'Digitized Postal ID']
  

    const [pi, setPi] = React.useState(false);
    const [ci, setCi] = React.useState(false);
    const [ei, setEi] = React.useState(false);
    const [iv, setIv] = React.useState(false);

    async function fetchProfile(){
    
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.get(process.env.REACT_APP_API_URL + '/api/kyc/', yourConfig)
        const { first_name, middle_name, last_name, birthday, birthplace, nationality, civil_status, present_address, employer_address } = response.data;
        console.log(response)
        
        //STEP 1
        setFirstName(first_name)
        setMiddleName(middle_name)
        setLastName(last_name)
        setMothersMaidenName(response.data.mothers_maiden_name)


        if(response.data.birthday != ""){
            setBirthDateY(birthday ? birthday.split("-")[0] : "")
            setBirthDateM(birthday ? birthday.split("-")[1] : "")
            setBirthDateD(birthday ? birthday.split("-")[2] : "")
        }

        setPresentStreet(response.data.present_address.street)
        setPresentBarangay(response.data.present_address.barangay)
        setPresentCity(response.data.present_address.city)
        setPresentProvince(response.data.present_address.province)
        setPresentPostCode(response.data.present_address.postalCode)

        // setPermaStreet(response.data.perma_street)
        // setPermaBarangay(response.data.perma_barangay)
        // setPermaCity(response.data.perma_city)
        // setPermaProvince(response.data.perma_province)
        // setPresentPostCode(response.data.present_address.postalCode)

        setBirthPlace(birthplace)
        setGender(response.data.gender)
        setNationality(nationality)
        setCivilStatus(civil_status)

        //STEP 2
        setMobileNumber(response.data.contact)
        setEmailAddress(response.data.email)
        
        //STEP 3
        
        setEmployerNatureBusiness(response.data.employer_industry)
        setEmploymentStatus(response.data.employment_status)
        setEmployerName(response.data.employer_name)
        setSourceFunds(response.data.source_of_funds)

        if(response.data.employer_address.street == ""){
            setUnemployed(true)
        }
        else{
            setEmpStreet(response.data.employer_address.street)
            setEmpBarangay(response.data.employer_address.barangay)
            setEmpCity(response.data.employer_address.city)
            setEmpProvince(response.data.employer_address.province)
            setEmpPostCode(response.data.employer_address.postalCode)
        }

        setEmpStreet(employer_address ? employer_address.street : "")
        setEmpBarangay(employer_address ? employer_address.barangay : "")
        setEmpCity(employer_address ? employer_address.city : "")
        setEmpProvince(employer_address ? employer_address.province : "")
        setEmpPostCode(employer_address ? employer_address.postalCode : "")


        //Step 4
        
        if(response.data.id_type){
            setIdType(response.data.id_type)
        }

        setIdNumber(response.data.id_number)
        setStep(response.data.step)

        setIdURL(response.data.front_id_image_url)
        setBackURL(response.data.back_id_image_url)
        setSelfieBlob(response.data.selfie_image_url)

        if(response.data.front_id_image_url != ""){
            setIdValid(!idValid)
        }

        if(response.data.back_id_image_url != ""){
            setBackValid(!backValid)
        }

        if(response.data.selfie_image_url != "") {
            setSelfieValid(!selfieValid)
        }


        SetRemarks(response.data.remarks)

        if(response.data.present_street == response.data.perma_street && response.data.present_barangay == response.data.perma_barangay && response.data.present_city == response.data.perma_city && response.data.present_province == response.data.perma_province){
            setPermanentAddressC(true)
        }

        else{
            setPermanentAddressC(false)

        }

        setBirthPlace(response.data.birthplace)

        if(response.data.employer_industry){
            setEmployerNatureBusiness(response.data.employer_industry)
        }

        if(response.data.occupation){
            setOccupation(response.data.occupation)
        }

        if(response.data.step == 0){
            window.location.href = '/complete_profile'
        }

        if(response.data.step == 10 || response.data.step == 7 || response.data.step == 8){
            window.location.href = '/mobile/upgrade'
        }

        if(response.data.step == 9 || response.data.step == 6) {
            setLoading(!loading)
        }

    }

    function closeCamera(){
        try{
            let stream = playerRef.current.srcObject;
            let tracks = stream.getTracks();
            tracks[0].stop()
        }catch(error){}

        setCameraModal(false)
    }

    function handleTakePhoto (dataUri) {
        setLoadingUploadSelfie(true)
    
        processfile(dataURItoBlob(dataUri), 4, '');
        try{
            let stream = playerRef.current.srcObject;
            let tracks = stream.getTracks();
            tracks[0].stop()
        }catch(error){}

        setCameraModal(false)


    }

    function captureImage(dataURI){
        const context = canvasRef.current.getContext('2d');
        context.drawImage(playerRef.current , 0, 0, canvasRef.current.width, canvasRef.current.height);

        var dataURL =  canvasRef.current.toDataURL();
		// set the source of the img tag
        // setSelfieBlob(dataURL)
        handleTakePhoto(dataURL)
        // const context = this.canvasElement.getContext('2d');
        // context.drawImage(this.props.video, 0, 0, 640, 480);
    }
    
    function handleCameraPermission(){

        playerRef.current.setAttribute('playsinline', '');
        playerRef.current.setAttribute('autoplay', '');
        playerRef.current.setAttribute('muted', '');
        playerRef.current.style.width = '100%';
        playerRef.current.style.height = '400px';

        /* Setting up the constraint */
        var facingMode = "user"; // Can be 'user' or 'environment' to access back or front camera (NEAT!)
        var constraints = {
          audio: false,
          video: {
           facingMode: facingMode
          }
        };


        if(!isIOS){
            setMessageModal('Please allow your camera access to take your selfie by enabling on your browser security settings. Remove bux.ph from list of blocked sites')

            navigator.permissions.query({name: 'camera'})
            .then((permissionObj) => {
                if(permissionObj.state == "denied"){
                    setBlockModal(true)
                }
                else{
                    navigator.getMedia = ( navigator.getUserMedia ||
                         navigator.webkitGetUserMedia ||
                         navigator.mozGetUserMedia ||
                         navigator.msGetUserMedia);

                    if (navigator.getUserMedia) {
                         navigator.getUserMedia(constraints, function success(stream) {
                           setCameraModal(true)
                           playerRef.current.srcObject = stream;
                        }, function(error){
                           
                           setBlockModal(true)
                        });
                     }
                }
            })
            .catch((error) => {
                setBlockModal(true)
            })
        }else{
            
            if(isMobileSafari || isSafari){
                setMessageModal(`iPhone Safari Blocked Access:
                Please allow camera access to bux.ph. If you have permanently blocked camera access, please follow these steps:
                Go to Setttings >  Go to Safari > Scroll to Privacy & Security > Enable Camera & Microphone Access`)
                navigator.mediaDevices.getUserMedia(constraints).then(function success(stream) {
                    setCameraModal(true)
                   playerRef.current.srcObject = stream;
                }).catch((error) => {
                    setBlockModal(true)
                });
    
            }

            else {
                setMessageModal(`Camera access is only enabled for Safari on iOS devices. Please use Safari to use the selfie feature`)
                setBlockModal(true)

            }
            /* Stream it to video element */
  

        }
    }

    function processfile(blob, step, type) {
        // read the files

        var reader = new FileReader();
        reader.readAsArrayBuffer(blob);
        
        reader.onload = function (event) {
          // blob stuff
          var blob = new Blob([event.target.result]); // create blob...
          window.URL = window.URL || window.webkitURL;
          var blobURL = window.URL.createObjectURL(blob); // and get it's URL
          
          // helper Image object
          var image = new Image();
          image.src = blobURL;
          image.onload = function() {
            // have to wait till it's loaded
            var resized = resizeMe(image); // resized image url
            submitPhoto(resized, step, type)
          }
        };
    }

    function resizeMe(img) {
      
        var canvas = document.createElement('canvas');
  
        var width = img.width;
        var height = img.height;
  
        // calculate the width and height, constraining the proportions
        if (width > height) {
          if (width > max_width) {
            //height *= max_width / width;
            height = Math.round(height *= max_width / width);
            width = max_width;
          }
        } else {
          if (height > max_height) {
            //width *= max_height / height;
            width = Math.round(width *= max_height / height);
            height = max_height;
          }
        }
        
        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        
        return canvas.toDataURL("image/jpeg",1);
        
        // you can get BLOB too by using canvas.toBlob(blob => {});
  
      }
  
      function dataURItoBlob(dataUri) {
          var byteString = atob(dataUri.split(',')[1]);
          var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
          }
          var blob = new Blob([ab], {type: mimeString});
          return blob
      }

      async function submitPhoto(dataURI, step, type) {
        
        var blob = dataURItoBlob(dataURI);

        const formData = new FormData();

        if(step == 4){
            setSelfieBlob(dataURI)
            formData.append('selfie_image', blob, 'selfie_image.png');
            formData.append('step', '4')

            const yourConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Token " + localStorage.getItem("jwtToken")
                }
            }

            let response = await axios.post(process.env.REACT_APP_API_URL + '/api/kyc/', formData, yourConfig)
            setLoadingUploadSelfie(!loadingUploadSelfie)
            if(response.data.status == 'success'){
                setLoadingUploadSelfie(false)
                setAlertID(true)
                setStep(step)
                setSelfieValid(true)
            }
            else{
                setLoadingUploadSelfie(false)
                setSelfieValid(false)
                if(response.data.error == "face"){
                    setAlertMsg("Can't detect face")
                    setAlertType("error")
                    setAlertID(false)

                }
                if(response.data.error == "count"){
                    setAlertMsg("More than 1 face detected")
                    setAlertType("error")
                    setAlertID(false)


                }
                if(response.data.error == "headwear"){
                    setAlertMsg("Please remove headwear")
                    setAlertType("error")
                    setAlertID(false)


                }
                if(response.data.error == "blur"){
                    setAlertMsg("Image uploaded is blurred")
                    setAlertType("error")
                    setAlertID(false)


                }
            }
        }else{
            if(type == 0){
                setIdURL(dataURI)
                formData.append('front_id_image', blob, 'id_image.png');
            }
            else{
                setBackURL(dataURI)
                formData.append('back_id_image', blob, 'id_image.png');
            }

            formData.append('step', '3')
            formData.append('id_type', idType);
            formData.append('id_number', idNumber)

            const yourConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Token " + localStorage.getItem("jwtToken")
                }
            }

            let response = await axios.post(process.env.REACT_APP_API_URL + '/api/kyc/', formData, yourConfig)
            console.log(response.data)

            if(response.data.status == "success"){
                if(type == 0){
                    setIdValid(true)
                    setLoadingUploadID(!loadingUploadID)

                }
                else{
                    setBackValid(true)
                    setLoadingUploadBackID(!loadingUploadBackID)
                    
                }
                setAlertID(true)
                setStep(response.data.step)
            }

            else if(response.data.status == "failed"){
                if(type == 0){
                    setIdValid(false)
                }
                else{
                    setBackValid(false)
                }

    
                if(response.data.error == "blur"){
                    setAlertMsg("Image uploaded is blurred")
                    setAlertType("error")
                    setAlertID(false)
    
                }
                if(response.data.error == "text"){
                    setAlertMsg("Personal information you entered should be the same with your ID.")
                    setAlertType("error")
                    setAlertID(false)
    
                }
    
                if(response.data.error == 'id'){
                    setAlertMsg("Please upload correct ID based on your ID details")
                    setAlertType("error")
                    setAlertID(false)
    
                }
                
    
            }
    
            else{
                setIdValid("")
                setBackValid("")
    
            }
    

        }
    }

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        return isJpgOrPng;
    }

    const handleUpload = async(info, type) => {
        if (info.file.status === 'uploading') {
            if(type == 0) {
                setLoadingUploadID(true)
            }
            else if(type == 1) {
                setLoadingUploadBackID(true)
            }

            return
        }

        console.log(type)

        processfile(info.file.originFileObj, 3, type)
        
      };

    async function verifyID(file){
        const formData = new FormData();
        formData.append('id_image',file );
        formData.append('step', '3')
        const yourConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': "Token " + localStorage.getItem("jwtToken")
            }
        }
        let response = await axios.post(process.env.REACT_APP_API_URL + '/api/kyc/', formData, yourConfig)

        if(response.data.status == "success"){
            setIdValid(true)
        }
        else if(response.data.status == "failed"){
            setIdValid(false)
        }

        else{
            setIdValid("")
        }

    }

    function editPi(){
        setPi(!pi);
    }

    function editCi(){
        setCi(!ci);
    }

    function editEi(){
        setEi(!ei);
    }

    function editIv(){
        setIv(!iv);
    }

    function handleCS(value){
        setCivilStatus(value);
    }

    function handleSourceFunds(value){
        setSourceFunds(value)
    }

    function handleIDtype(value){
        setIdType(value)
        if(idNumber){
            setIdFormatError(checkIdFormat(value, idNumber));
        }

    }

    function handleOccupation(value){
        setOccupation(value)
    }

    function handleEmployerNatureBusiness(value){
        setEmployerNatureBusiness(value)
    }

    function handleEmpProvince(value){
        setEmpProvince(value)
    }
    function handleEmpCity(value){
        setEmpCity(value)
    }

    function handleEmpBarangay(value){
        setEmpBarangay(value)
    }

    function handleEmploymentStatus(value){
        if(value=="UEH" || value == "UER" || value == "UES"){
            setUnemployed(true)
            setEmploymentStatus(value)
            setEmployerNatureBusiness("OTH")
            setEmployerName("")
            setSourceFunds("001")
            setEmpStreet("")
            setEmpBarangay("")
            setEmpProvince("")
            setEmpCity("")
            setEmpPostCode("")
        }
        else{
            if(employerName== ""){
                setEmployerNatureBusiness(NATURE_BUSINESS[0])
                setEmployerName("")
                setSourceFunds("001")
            }
            setEmploymentStatus(value)
            setEmployerNatureBusiness("")
            setUnemployed(false)
        }
        
    }

    function handlePresentProvince(value) {
        if(permanentAddressC){
            setPermaProvince(value)
        }
        setPresentProvince(value)
        setPresentCity("")
        setPresentBarangay("")
    }
    function handlePermaProvince(value){
        setPermaProvince(value)
        setPermaCity("")
        setPermaBarangay("")
    }

    function handlePresentCity(value) {
        if(permanentAddressC){
            setPermaCity(value)
        }
        setPresentCity(value)
        setPresentBarangay("")
    }
    function handlePermaCity(value) {
        setPermaCity(value)
        setPermaBarangay("")
    }

    function handleBirthMonth(value){
        setBirthDateM(value)
        setBirthDateD("01")
    }

    function getMonths(){
        let months = []
        for(let i=1; i <= 12; i++){
            let j = i.toString()
            if(i < 10){
                j = "0" + i.toString()
            }
            months.push(<option value={j}>{moment().month(j-1).format("MMMM")}</option>)
        }
        return months
    }
        function getDays(){
            let days = []
            let months_with_31 = ["01","03","05","07","08","10","12"]
            if(months_with_31.includes(birthDateM)){
                for(let i=1; i <= 31; i++){
                    let j = i.toString()
                    if(i < 10){
                        j = "0" + i.toString()
                    }
                    days.push(<Option value={j}>{j}</Option>)
                }
            }
            else if(birthDateM == "02"){
                for(let i=1; i <= 29; i++){
                    let j = i.toString()
                    if(i < 10){
                        j = "0" + i.toString()
                    }
                    days.push(<Option value={j}>{j}</Option>)
                }
            }
            else{
                for(let i=1; i <= 30; i++){
                    let j = i.toString()
                    if(i < 10){
                        j = "0" + i.toString()
                    }
                    days.push(<Option value={j}>{j}</Option>)
                }
            }
            return days
        }
        function getYears(){
            let years = []
            for(let i=1900; i <= 2002; i++){
                years.push(<Option value={i.toString()}>{i.toString()}</Option>)
            }
            return years
        }

    function handlePresentBarangay(value) {
        if(permanentAddressC){
            setPermaBarangay(value)
        }
        setPresentBarangay(value)
    }
    function handlePermaBarangay(value) {
        setPermaBarangay(value)
    }

    function getProvinces() {
        let provinces = []
        for(var i in ADDRESS){
            provinces.push(<Option value={i}>{i}</Option>);
        }
        return provinces
    }

    function getCities(type) {
        let cities = []
        if(type in ADDRESS){
            for(var i in ADDRESS[type]['Municipality_list']){
                cities.push(<Option value={i}>{i}</Option>);
            }
        }
        return cities
    }

    function getBarangay(typeCity,typeProvince) {
        let baranggays = []
        if(typeProvince in ADDRESS){
            if(typeCity in ADDRESS[typeProvince]['Municipality_list']){
                let baranggay_list = ADDRESS[typeProvince]['Municipality_list'][typeCity]['Barangay_list'];
                for(var i=0;i<baranggay_list.length;i++){
                    baranggays.push(<Option value={baranggay_list[i]}>{baranggay_list[i]}</Option>);
                }
            }
        }
        return baranggays
    }
    

    function disabledDate(current) {
        return current > moment().subtract(18, "years") ;
    }


    function handleGender(value){
        setGender(value)
    }

    function handleCivilStatus(value){
        setCivilStatus(value)
    }

    function setPermaAdd(){
        if(!permanentAddressC){
            setPermanentAddressC(true)
            setPermaStreet(presentStreet)
            setPermaBarangay(presentBarangay)
            setPermaCity(presentCity)
            setPermaProvince(presentProvince)
        }
        else{
            setPermanentAddressC(false)
        }
    }


    // function samePermaAddress(value){
    //     if(value.target.checked){
    //         showPermanentAddress(false)
    //         setSameAddresses(true)
    //         setPermanentAddress(presentAddress)
    //     }
    //     else{
    //         showPermanentAddress(true)
    //     }
    // }

    const handleIdNumberChange = (e) => {
        // let value = null;
        const value = e.target.value ;
        const formatValue = () => {
            switch (idType) {
                case "0":
                   return (value).replace(/^([a-zA-Z]\d{2})(\d{2})(\d{6})$/,"$1-$2-$3").toUpperCase();
                case "1":
                   return (value).replace(/^([a-zA-Z])(\d{7})([a-zA-Z])$/,"$1$2$3").toUpperCase();
                case "3":
                    return (value).replace(/^(\d{2}|\d{4})(\d{7})(\d)$/, "$1-$2-$3").toUpperCase();
                case "4":
                case "5":    
                case "6":
                    return (value).replace(/\D/g, '');
                case "7":
                case "8":
                case "9":
                case "10":
                case "12":
                    return (value).replace(/([\\~#%@+&$^)(*{}/:;<[>?|\"_`=,.'!-])/, "").toUpperCase();
                case "11": 
                    return (value).replace(/^(\d{4})(\d{4}[a-zA-Z])([a-zA-Z0-9]+)$/, "$1-$2-$3").toUpperCase();
                case "13":
                    return (value).replace(/^(\d{12})([a-zA-Z])$/, "$1 $2").toUpperCase();
                default:
                    return (value);
            };
        }
        setIdFormatError(checkIdFormat(idType, formatValue()))
        setIdNumber(formatValue());
    }

    const checkIdFormat = (id, value) => {
        switch (id) {
            case "0":
               if (value.match(/^([a-zA-Z]\d{2})-(\d{2})-(\d{6})$/)){
                return false
               } else {
                return true
               }
            case "1":
                if (value.match(/^([a-zA-Z])(\d{7})([a-zA-Z])$/)){
                    return false
                } else {
                    return true
                }
            case "3":
                if (value.match(/^(\d{2}|\d{4})-(\d{7})-(\d)$/)){
                    return false
                } else {
                    return true
                }
            case "4":
            case "5":
            case "6":
                if (value.match(/(^\d+$)/)){
                    return false
                } else {
                    return true
                }
            case "7":
            case "8":
            case "9":
            case "10":
            case "12":
                if (value.match(/(^[a-zA-Z0-9]+$)/)){
                    return false
                } else {
                    return true
                }
            case "11":
                if (value.match(/^(\d{4})-(\d{4}[a-zA-Z])-([a-zA-Z0-9]+)$/)){
                    return false
                } else {
                    return true
                }
            case "13":
                if (value.match(/^(\d{12}) ([a-zA-Z])$/)){
                    return false
                } else {
                    return true
                }
            default:
                if (value.match(/([a-zA-Z0-9]+)$/)){
                    return false
                } else {
                    return true
                }
        }
    }

    const idFormatErrorMessage = () => {
        switch (idType) {
            case "0":
                return (`Invalid Driver's License ID no. | 
                        Format should be X00-00-000000`)
            case "1":
                return (`Invalid Passport ID no. | 
                        Format should be X0000000X`)
            case "3":
                return (`Invalid SSS or UMID ID no. | 
                        Format should be 00-0000000-0 or 0000-0000000-0`)
            case "4":
                return (`Invalid GSIS ID no. | 
                        Format should be 00000000000`)
            case "5":
                return (`Invalid PRC ID no. |  
                        Format should be 0000000`)
            case "6":
                return (`Invalid IBP ID no. |  
                        Format should be 00000000`)
            case "7":
                return (`Invalid OWWA ID no. |  
                        Format should be 00000000`)
            case "8":
                return (`Invalid Diplomat ID no. |  
                        Format should be 00000000000`)
            case "9":
                return (`Invalid OFW ID no. |  
                        Format should be 0000000`)
            case "10":
                return (`Invalid Senior ID no. |  
                        Format should be 0000000`)
            case "11":
                return (`Invalid Voter's ID no. |  
                        Format should be 0000-0000X-X0000XXX00000`)
            case "12":
                return (`Invalid Govt Office ID no. |  
                        Format should be 00000000`)
            case "13":
                return (`Invalid Postal ID no. | 
                        Format should be 000000000000 X`)
            default:
                return (`Invalid ID no. | 
                        Format example: 000000000X`)
        }
    }

    const setIdNumberLength = () => {
        switch (idType) {
            case "0":
                return 13;
            case "1":
                return 9;
            case "3":
                return 14;
            case "4":
            case "5":
            case "6":
            case "7":
            case "8":
            case "9":
            case "10":
            case "12":
                return 20;
            case "11":
                return 30;
            case "13":
                return 14;
            default:
                return 30;
        }
    }

    function submitDetail(submittedStep){

        let params = {}

        if(submittedStep == 0){

                params = {
                    first_name: firstName,
                    middle_name: middleName,
                    last_name: lastName,
                    mothers_maiden_name: mothersMaidenName,
                    birthday: birthDateY+"-"+birthDateM+"-"+birthDateD, 
                    birthplace: birthPlace,
                    present_street: presentStreet,
                    present_barangay: presentBarangay,
                    present_city: presentCity,
                    present_province: presentProvince,
                    present_postcode: presentPostCode,
                    perma_street: permaStreet,
                    perma_barangay: permaBarangay,
                    perma_city: permaCity,
                    perma_province: permaProvince,
                    perma_postcode: permaPostCode,
                    gender: gender,
                    nationality,
                    civil_status: civilStatus,
                    step: 0,
                }
                
            setPi(!pi);

        }

        else if(submittedStep == 1){
            params = {
                contact: mobileNumber,
                email: emailAddress,
                step: 1,
            }

            setCi(!ci);

        }

        else if(submittedStep == 2){
            params = {
                employment_status: employmentStatus,
                employer_name: employerName,
                employer_industry: employerNatureBusiness,
                source_of_funds: sourceFunds,
                employer_street: empStreet,
                employer_barangay: empBarangay,
                employer_city: empCity,
                employer_province: empProvince,
                employer_postcode: empPostCode,
                step: 2,
            }

        setEi(!ei);

        }

        else if(submittedStep == 5){

            
            params = {
                id_type: idType,
                id_number: idNumber,
                step: 5,
            }
        setIv(!iv);
        }

        else if(submittedStep == 6) {
            params = {
                step: 6,
            }

        }

        else if(submittedStep == 9) {
            params = {
                step: 9,
            }

        }
        
        submission(params, submittedStep)
    }

    async function submission(val, submittedStep) {

        if(submittedStep == 6 || submittedStep == 9){
            setLoading(false)
        }

        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.post(
            process.env.REACT_APP_API_URL + "/api/kyc/",
            val,yourConfig
        );
        

        console.log(response)

        if((response.data.status == "success" && response.data.step == 7  && submittedStep == 6) || (response.data.status == "success" && response.data.step == 8 && submittedStep == 9)){
            setLoading(true)
            setSubmittedModal(true)
        }

        else{
            setLoading(true)
            alert(response.data.message)
        }
        
        // if((response.data.step == 7)||(response.data.step == 8)){
        //     window.location.href = '/mobile/upgrade'
        // }
        
    }

    function upgradeBtn(){
        window.location.href = '/mobile/upgrade'
        
    }
    
    return(
        <div>
            <MetaTag title="Review Application"/>
            <div align="center" style={{margin: 0,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateY(50%, 50%)'}} hidden={loading}>
                <Spin size="large" />
            </div> 

            <Modal
                visible={submittedModal}
                onCancel={()=>setSubmittedModal(false)}
                footer={null}
                >
                <div style={Style.modalBodyTips} align='center'>
                    <img src={Submitted} />
                    <div style={Style.tipsStyle}>Successfully Submitted</div>
                    <div style={Style.textStyle3}>We’ve got your Profile information, and currently reviewing it. Kindly give us a maximum of 48 hours to evaluate your application to upgrade your account.</div>
                    <div style={Style.textStyle3}>Haven’t received your status? Please contact us at <a href="mailto:support@bux.ph" style={{color: '#1d82b3'}}>support@bux.ph</a></div>
                    
                    <Button style={ Style.submitBtnStyle } onClick={() => upgradeBtn()}>Done</Button>

                </div>
            </Modal>

        <div style={Style.mobileMain} hidden={!loading}>
            <div style={Style.mheaderStyle}>{step == 9 ? "Upgrade Application" : "Review Application" }</div>

            <div style={Style.divRejected} hidden={step == 9 ? false : true}>
                <div>
                    <img src={uFail} /> 
                    <span style={{fontSize: '18px', color: '#2b2d33', paddingLeft: 16}}>Upgrade Application was declined</span>
                    <div style={{marginTop: 16}}>
                    We’re sorry for any inconvenience but we cannot proceed with your application due to inconsistencies with the submitted information/documents. 
                    
                    <div style={{marginTop: 16}}>Kindly check your submitted information and resubmit it.</div>
                    <div hidden={remarks == ""} style={{marginTop: 16}}><b>Remarks</b>: {remarks}</div>
                    </div>

                </div>
            </div>
            <div style={Style.reviewCard}>
                    <div>
                        <div style={Style.reviewCardHeader}>
                            <div style={Style.headerStyle}>Personal Information</div>
                            <div>
                            <a hidden={pi} onClick={editPi} style={{color: '#1d82b8', fontWeight: 'bold'}}>Edit</a>
                            <a hidden={!pi} onClick={editPi} style={{color: '#ed5249', fontWeight: 'bold'}}>Cancel</a>
                            </div>
                        </div>


                        {/** Personal Info View */}
                        <div hidden={pi}>
                        <Grid container style={{padding: '32px 32px 0px 32px'}}>
                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Name:</div>
                            </Grid>
                        
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{firstName} {middleName} {lastName}</div>
                            </Grid>
                        </Grid>
                        <Grid container style={{padding: '32px 32px 0px 32px'}}>
                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Mother's Maiden Name:</div>
                            </Grid>
                        
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{mothersMaidenName}</div>
                            </Grid>
                        </Grid>
                        <Grid container style={{padding: '32px 32px 0px 32px'}}>
                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Birthdate:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{birthDateY+"-"+birthDateM+"-"+birthDateD}</div>
                            </Grid>
                        </Grid>
                        <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Birthplace:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{birthPlace}</div>
                            </Grid>
                        </Grid>
                        <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Present Address:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{presentStreet}, {presentBarangay}, {presentCity}, {presentProvince}</div>
                            </Grid>
                        </Grid>

                        {/* <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Permanent Address:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{permaStreet}, {permaBarangay}, {permaCity}, {permaProvince}</div>
                            </Grid>
                        </Grid> */}

                        <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Postal Code:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{presentPostCode}</div>
                            </Grid>
                        </Grid>


                        <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Gender:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{Gender[gender]}</div>
                            </Grid>
                        </Grid>

                        <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Nationality:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{nationality}</div>
                            </Grid>
                        </Grid>
                        <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Civil Status:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{CivilStatus[civilStatus]}</div>
                            </Grid>
                        </Grid>

                        </div>

                        {/** Personal Info Edit View */}
                        <div style={{padding: 32}} hidden={!pi}>
                        <div style={{marginBottom: 16}}>
                            <div style={Style.textStyle}>First name</div>
                            <Input size="large" maxLength={25} value={firstName} onChange={(e)=>setFirstName(e.target.value)}/>
                        </div>

                        <div style={{marginBottom: 16}}>
                            <div style={Style.textStyle}>Middle name</div>
                            <Input size="large" maxLength={25} value={middleName} onChange={(e)=>setMiddleName(e.target.value)}/>
                        </div>
                        

                        <div style={{marginBottom: 16}}>
                            <div style={Style.textStyle}>Last name</div>
                            <Input size="large" maxLength={25} value={lastName} onChange={(e)=>setLastName(e.target.value)}/>
                        </div>
                        
                        <div style={{marginBottom: 16}}>
                            <div style={Style.textStyle}>Mother's Maiden Name</div>
                            <Input size="large" maxLength={25} value={mothersMaidenName} onChange={(e)=>setMothersMaidenName(e.target.value)}/>
                        </div>

                        <div style={{marginBottom: 16}}>
                            <div style={Style.textStyle}>Birthdate</div>
                            <Grid container style={{marginTop: 8}}>
                                <Grid xs={4} style={{marginRight: 12}}>
                                    <div style={Style.textStyle}>Month</div>
                                    <Select style={{height: '40px', width: 110}}
                                    size="large" value={birthDateM} onChange={handleBirthMonth}>
                                        {getMonths()}
                                    </Select>
                                </Grid>
                                <Grid xs={2} style={{marginRight: 32}}>
                                    <div style={Style.textStyle}>Days</div>
                                    <Select style={{height: '40px', width: 80}}
                                        size="large" value={birthDateD} onChange={(e) => setBirthDateD(e)}>
                                            {getDays()}
                                    </Select>
                                </Grid>
                                <Grid xs={4}>
                                    <div style={Style.textStyle}>Year</div>
                                    <Select style={{height: '40px', width: 100}}
                                        size="large" value={birthDateY} onChange={(e) => setBirthDateY(e)}>
                                            {getYears()}
                                    </Select>
                                </Grid>
                            </Grid>
                        </div>

                        <div style={{marginBottom: 16}}>
                            <div style={Style.textStyle}>Birthplace(City)</div>
                            <Input size="large" value={birthPlace} style={{width: '100%'}} onChange={(e)=>setBirthPlace(e.target.value)}/>
                        </div>

                        <div style={{width: '100%', marginTop: '20px', marginBottom: 16}}>
                            <div style={Style.headerStyle2}>Present Address</div>
                        </div>
                        <div style={{width: '100%', marginBottom: 16}}>
                            <div style={Style.textStyle}>Street</div>
                            <Input style={{height: '40px', width:' 100%'}} value={presentStreet} onChange={(e)=>setPresentStreet(e.target.value)}/>
                        </div>
                        <div style={{width: '100%', marginBottom: 16}}>
                            <div style={Style.textStyle}>Province</div>
                            <Select style={{width: '100%'}}
                            size="large" value={presentProvince} onChange={handlePresentProvince}>
                                {
                                    getProvinces()
                                }
                            </Select>
                        </div>
                        <div style={{width: '100%', marginBottom: 16}}>
                            <div style={Style.textStyle}>City</div>
                            <Select style={{width: '100%'}}
                                size="large" value={presentCity} onChange={handlePresentCity}>
                                {getCities(presentProvince)}
                            </Select>
                        </div>
                        <div style={{width: '100%', marginBottom: 16}}>
                            <div style={Style.textStyle}>Barangay</div>
                            <Select style={{width: '100%'}}
                            size="large" value={presentBarangay} onChange={handlePresentBarangay}>
                                {getBarangay(presentCity, presentProvince)}
                            </Select>
                        </div>

                        <div style={{width: '100%', marginBottom: 16}}>
                            <div style={Style.textStyle}>Postal Code</div>
                            <Input style={{height: '40px'}} value={presentPostCode} onChange={(e)=>setPresentPostCode(e.target.value)}/>
                        </div>

                        <div style={{width: '100%', marginTop: '20px'}} hidden={permanentAddressC}>
                            <div style={{width: '100%', marginTop: '20px', marginBottom: 16}}>
                                <div style={Style.headerStyle2}>Permanent Address</div>
                            </div>
                            
                            <div style={{width: '100%', marginBottom: 16}}>
                                <div style={Style.textStyle}>Street</div>
                                <Input style={{height: '40px'}} value={permaStreet} onChange={(e)=>setPermaStreet(e.target.value)}/>
                            </div>
                            <div style={{width: '100%', marginBottom: 16}}>
                                <div style={Style.textStyle}>Province</div>
                                <Select style={{width: '100%'}}
                                size="large" value={permaProvince} onChange={handlePermaProvince}>
                                    {
                                        getProvinces()
                                    }
                                </Select>
                            </div>
                            <div style={{width: '100%', marginBottom: 16}}>
                                <div style={Style.textStyle}>City</div>
                                <Select style={{width: '100%'}}
                                    size="large" value={permaCity} onChange={handlePermaCity}>
                                    {getCities(permaProvince)}
                                </Select>
                            </div>
                            <div style={{width: '100%'}}>
                                <div style={Style.textStyle}>Barangay</div>
                                <Select style={{width: '100%'}}
                                size="large" value={permaBarangay} onChange={handlePermaBarangay}>
                                    {getBarangay(permaCity, permaProvince)}
                                </Select>
                            </div>

                            <div style={{width: '100%', marginBottom: 16}}>
                                <div style={Style.textStyle}>Postal Code</div>
                                <Input style={{height: '40px'}} value={permaPostCode} onChange={(e)=>setPermaPostCode(e.target.value)}/>
                            </div>
                        </div>
                        {/* <div style={{marginTop: 16}}>
                            <div style={Style.PermaStyle}>Permanent Address</div>
                            <div style={{display: 'flex'}}>
                                <Checkbox style={{marginRight: '10px'}} onChange={setPermaAdd} checked={permanentAddressC}/>
                                <div style={Style.PresentStyle}>
                                    Same as Present Address
                                </div>
                            </div>
                        </div> */}

                        <div style={{marginTop: 16}}>
                            <div style={Style.textStyle}>Gender</div>
                            <Select
                                showSearch
                                style={{ width: '100%', height: '40px' }}
                                size="large"
                                value={Gender[gender]}
                                onChange={handleGender}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="M">Male</Option>
                                <Option value="F">Female</Option>
                            </Select>
                        </div>

                        <div style={{marginTop: 16}}>
                            <div style={Style.textStyle}>Nationality</div>
                            <Input style={{height: '40px', width: '100%'}} value={nationality} onChange={(e)=>setNationality(e.target.value)}/>
                        </div>

                        <div style={{marginTop: 16}}>
                            <div style={Style.textStyle}>Civil Status</div>
                            <Select
                                showSearch
                                style={{ width: '100%', height: '40px' }}
                                size="large"
                                value={CivilStatus[civilStatus]}
                                onChange={handleCivilStatus}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                            {
                                Object.keys(CivilStatus).map((key, i) => (
                                <Option value={key}>{CivilStatus[key]}</Option>
                                ))
                              }
                            </Select>
                        </div>

                        <div style={{marginTop: 32, marginBottom: 16}}>
                            <Button style={

                                firstName == "" ||
                                lastName == "" || 
                                birthPlace == "" || 
                                nationality == "" || 
                                mothersMaidenName == "" ||
                                presentPostCode == "" ||
                                gender == ""  

                                ?

                                Style.dsubmitBtnStyle : Style.submitBtnStyle}
                            disabled={
                                !firstName ||
                                !lastName ||
                                !birthPlace || 
                                !nationality ||
                                !mothersMaidenName ||
                                !presentPostCode ||
                                !gender
                            }
                            onClick={() => submitDetail(0)}>Save</Button>
                        </div>
                        </div>
                        

                        {/** Contact Info View */}
                        <div style={Style.reviewCardHeader}>
                            <div style={Style.headerStyle}>Contact Information</div>
                            <div>
                                <a hidden={ci} onClick={editCi} style={{color: '#1d82b8', fontWeight: 'bold'}}>Edit</a>
                                <a hidden={!ci} onClick={editCi} style={{color: '#ed5249', fontWeight: 'bold'}}>Cancel</a>

                            </div>
                        </div>

                            <div hidden={ci}>

                            <Grid container style={{padding: '32px 32px 0px 32px'}}>
                                <Grid xs={6}>
                                    <div style={Style.PresentStyle}>Mobile Number:</div>
                                </Grid>
                            
                                <Grid xs={6} align='right'>
                                    <div style={Style.PresentStyle}>{mobileNumber}</div>
                                </Grid>
                            </Grid>

                            <Grid container style={{padding: '32px 32px 0px 32px'}}>
                                <Grid xs={6}>
                                    <div style={Style.PresentStyle}>Email:</div>
                                </Grid>
                            
                                <Grid xs={6} align='right'>
                                    <div style={Style.PresentStyle}>{emailAddress}</div>
                                </Grid>
                            </Grid>

                            </div>
                            
                            {/** Contact Info Edit View */}

                            <div style={{padding: 32}} hidden={!ci}>
                            <div style={{marginBottom: 16}}>
                                <div style={Style.textStyle}>Mobile Number</div>
                                <Input addonBefore="+63" maxLength={10} size="large" value={mobileNumber} onChange={(e)=>setMobileNumber(e.target.value.replace(/\D/g, ''))}/>
                            </div>

                            <div style={{marginBottom: 16}}>
                                <div style={Style.textStyle}>Email</div>
                                <Input size="large" style={{width: '100%'}} value={emailAddress} onChange={(e)=>setEmailAddress(e.target.value)}/>
                            </div>

                            <div style={{marginTop: 32, marginBottom: 16}}>
                                <Button style={mobileNumber == "" || emailAddress == "" || mobileNumber.length <= 9 ? Style.dsubmitBtnStyle : Style.submitBtnStyle} onClick={() => submitDetail(1)}
                                disabled={!mobileNumber || !emailAddress || mobileNumber.length <= 9}>Save</Button>
                            </div>

                            </div>

                        <div style={Style.reviewCardHeader}>
                            <div style={Style.headerStyle}>Employment Information</div>
                            <div>
                                <a hidden={ei} onClick={editEi} style={{color: '#1d82b8', fontWeight: 'bold'}}>Edit</a>
                                <a hidden={!ei} onClick={editEi} style={{color: '#ed5249', fontWeight: 'bold'}}>Cancel</a>

                            </div>
                        </div>
                        
                        {/** Employment Info Edit View */}
                        <div hidden={ei}>
                        <Grid container style={{padding: '32px 32px 0px 32px'}}>
                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Employment Status:</div>
                            </Grid>
                        
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{EmploymentStatus[employmentStatus]}</div>
                            </Grid>
                        </Grid>
                        <Grid container style={{padding: '32px 32px 0px 32px'}}>
                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Employer Name:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{employerName}</div>
                            </Grid>
                        </Grid>
                        <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Employer Nature of Business:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{NatureOfWork[employerNatureBusiness]}</div>
                            </Grid>
                        </Grid>
                        {/* <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Occupation:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{occupation}</div>
                            </Grid>
                        </Grid> */}

                        <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Source of funds:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{SourceFunds[sourceFunds]}</div>
                            </Grid>
                        </Grid>

                        <Grid container style={{padding: '32px 32px 0px 32px', display: unemployed ? 'none': ''}}>
                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Employer Address:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{empStreet},{empBarangay}, {empCity}, {empProvince}</div>
                            </Grid>
                        </Grid>

                        </div>

                        <div hidden={!ei} style={{padding: 32}}>
                        <div style={{marginBottom: 16}}>
                            <div style={Style.textStyle}>Employment Status</div>
                            <Select
                                showSearch
                                style={{ width: '100%', height: '40px' }}
                                size="large"
                                onChange={handleEmploymentStatus}
                                value={EmploymentStatus[employmentStatus]}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                            {
                                Object.keys(EmploymentStatus).map((key, i) => (
                                <Option value={key}>{EmploymentStatus[key]}</Option>
                                ))
                              }
                            </Select>
                        </div>

                        <div style={{marginBottom: 16}}>
                            <div style={Style.textStyle}>Employer Name</div>
                            <Input style={{height: '40px', width: '100%'}} disabled={employmentStatus == "SEF" || employmentStatus == "SEB"} value={employerName} onChange={(e)=> setEmployerName(e.target.value)}/>
                        </div>

                        <div style={{marginBottom: 16}}>
                        <div style={Style.textStyle}>Employer Nature of Business</div>
                        <Select
                                showSearch
                                style={{ width: '100%', height: '40px' }}
                                size="large"
                                disabled={employmentStatus == "4"}
                                value={employmentStatus == "4" ? 'NA' : employerNatureBusiness}
                                onChange={handleEmployerNatureBusiness}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >

                            {
                                Object.keys(NatureOfWork).map((key, i) => (
                                <Option value={key}>{NatureOfWork[key]}</Option>
                                ))
                              }
                            </Select>
                        </div>

                        {/* <div style={{marginBottom: 16}}>
                            <div style={Style.textStyle}>Occupation</div>
                            <Select
                                value={occupation}
                                showSearch
                                style={{ width: '100%', height: '40px' }}
                                size="large"
                                onChange={handleOccupation}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                            {
                                    POSITION.length > 0 && POSITION.map((item, i) => {
                                    return (
                                    <Option key={i} value={item}>{item}</Option>
                                    )
                                }, this)
                            }
                            </Select>
                        </div> */}

                        <div style={{marginBottom: 16}}>
                            <div style={Style.textStyle}>Source of Funds</div>
                            <Select
                                showSearch
                                style={{ width: '100%', height: '40px' }}
                                size="large"
                                optionFilterProp="children"
                                value={SourceFunds[sourceFunds]}
                                onChange={handleSourceFunds}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >

                            {
                                Object.keys(SourceFunds).map((key, i) => (
                                <Option value={key}>{SourceFunds[key]}</Option>
                                ))
                              }
                            </Select>
                        </div>

                        <div hidden={unemployed}>
                        <div style={{width: '100%', marginTop: '20px', marginBottom: 16}}>
                            <div style={Style.headerStyle2}>Employer Address</div>
                        </div>
                        <div style={{width: '100%', marginBottom: 16}}>
                            <div style={Style.textStyle}>Street</div>
                            <Input style={{height: '40px', width:' 100%'}} value={empStreet} onChange={(e)=>setEmpStreet(e.target.value)}/>
                        </div>
                        <div style={{width: '100%', marginBottom: 16}}>
                            <div style={Style.textStyle}>Province</div>
                            <Select style={{width: '100%'}}
                            size="large" value={empProvince} onChange={handleEmpProvince}>
                                {
                                    getProvinces()
                                }
                            </Select>
                        </div>
                        <div style={{width: '100%', marginBottom: 16}}>
                            <div style={Style.textStyle}>City</div>
                            <Select style={{width: '100%'}}
                                size="large" value={empCity} onChange={handleEmpCity}>
                                {getCities(empProvince)}
                            </Select>
                        </div>
                        <div style={{width: '100%', marginBottom: 16}}>
                            <div style={Style.textStyle}>Barangay</div>
                            <Select style={{width: '100%'}}
                            size="large" value={empBarangay} onChange={handleEmpBarangay}>
                                {getBarangay(empCity, empProvince)}
                            </Select>
                        </div>

                        <div style={{width: '100%', marginBottom: 16}}>
                            <div style={Style.textStyle}>Postal Code</div>
                            <Input style={{height: '40px'}} value={empPostCode} onChange={(e)=>setEmpPostCode(e.target.value)}/>
                        </div>
                        </div>

                        <Button style={Style.submitBtnStyle} onClick={() => submitDetail(2)}>Save</Button>

                        </div>
                    </div>


                    <div style={Style.reviewCardHeader}>
                        <div style={Style.headerStyle}>Identity Verification</div>
                        <div>
                            <a hidden={iv} onClick={editIv} style={{color: '#1d82b8', fontWeight: 'bold'}}>Edit</a>
                            <a hidden={!iv} onClick={editIv} style={{color: '#ed5249', fontWeight: 'bold'}}>Cancel</a>

                        </div>
                    </div>

                    <div hidden={iv}>

                    <Grid container style={{padding: '32px 32px 0px 32px'}}>
                        <Grid xs={6}>
                            <div style={Style.PresentStyle}>ID Type:</div>
                        </Grid>
                    
                        <Grid xs={6} align='right'>
                            <div style={Style.PresentStyle}>{IDType[idType]}</div>
                        </Grid>
                    </Grid>

                    <Grid container style={{padding: '32px 32px 0px 32px'}}>
                        <Grid xs={6}>
                            <div style={Style.PresentStyle}>ID Number:</div>
                        </Grid>
                    
                        <Grid xs={6} align='right'>
                            <div style={Style.PresentStyle}>{idNumber}</div>
                        </Grid>
                    </Grid>

                    <Grid container style={{padding: '32px 32px 0px 32px'}}>
                        <Grid xs={6}>
                            <div style={Style.PresentStyle}>Front ID Photo:</div>
                        </Grid>
                    
                        <Grid xs={6} align='right'>
                        <div style={Style.PresentStyle}>
                            {idURL ? <img src={idURL} alt="avatar" style={{ width: '100%' }} /> : "None"}
                        </div>
                        </Grid>
                    </Grid>

                    <Grid container style={{padding: '32px 32px 0px 32px', display: idType == 'PAS' ? 'none' : ''}}>
                        <Grid xs={6}>
                            <div style={Style.PresentStyle}>Back ID Photo:</div>
                        </Grid>
                    
                        <Grid xs={6} align='right'>
                        <div style={Style.PresentStyle}>
                            {backURL ? <img src={backURL} alt="avatar" style={{ width: '100%' }} /> : "None"}
                        </div>
                        </Grid>
                    </Grid>

                    <Grid container style={{padding: '32px 32px 0px 32px'}}>
                        <Grid xs={6}>
                            <div style={Style.PresentStyle}>Selfie Photo:</div>
                        </Grid>
                    
                        <Grid xs={6} align='right'>
                            <div style={Style.PresentStyle}>
                            {selfieBlob ? <img src={selfieBlob} alt="avatar" style={{ width: '100%' , height: '130px'}}/> : "None"}
                                
                            </div>
                        </Grid>
                    </Grid>
                    </div>

                    <div hidden={!iv} style={{padding: 32}}>
                    <div style={{marginBottom: 16,}}>
                            <Button style={Style.mobileTipsBtnStyle} onClick={() => setTipsModal(true)}><img src={TipsIcon} style={{marginRight: '5px'}}/>Tips in Uploading ID</Button>
                        </div>

                        <div style={{marginBottom: 16}}>
                            <div style={Style.textStyle}>ID Type</div>
                            <Select
                                showSearch
                                style={{ width: '100%', height: '40px' }}
                                size="large"
                                onChange={handleIDtype}
                                value={IDType[idType]}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                            {
                                Object.keys(IDType).map((key, i) => (
                                <Option value={key}>{IDType[key]}</Option>
                                ))
                              }
                            </Select>
                        </div>

                        <div style={{marginBottom: 16,}}>
                            <div style={Style.textStyle}>ID Number</div>
                            <Input style={{height: '40px', width: '100%'}} value={idNumber} maxLength={setIdNumberLength()} onChange={handleIdNumberChange}/>
                        </div>

                        <div style={{marginBottom: 16}} hidden={!idValid ? false : true}>
                            <Alert type={alertType} style={{borderLeft: '4px solid red'}} message={alertMsg}/>
                        </div>

                        <div style={{marginBottom: 16}} hidden={ idFormatError ? false : true}>
                            <Alert type={alertType} style={{borderLeft: '4px solid red'}} message={idFormatErrorMessage()}/>
                        </div>

                        <div style={{marginBottom: 16}} hidden={!selfieValid ? false : true}>
                            <Alert type={alertType} style={{borderLeft: '4px solid red'}} message={alertMsg}/>
                        </div>

                        <div style={{marginBottom: 16}} hidden={alertID ? true : false}>
                            <Alert type={alertType} style={{borderLeft: '4px solid red'}} message={alertMsg}/>
                        </div>
                        
                        <div style={{marginBottom: 16}}>

                        <Card 
                        bodyStyle={{
                            padding:'15px 10px 15px 10px'
                        }}
                        style={{
                            width: '100%'

                        }}>

                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div style={Style.PresentStyle}>
                                <img src={mSelfieImg} style={{paddingRight: '10px'}} />
                                    Your Selfie</div>

                                <div hidden={!loadingUploadSelfie}> 
                                <Spin size="small"  />
                                </div>

                                    <div hidden={loadingUploadSelfie}
                                style=
                                {
                                    selfieValid == null ? 
                                    
                                    Style.statusStyle 
                                    
                                    : 
                                    
                                    !selfieValid ? 
                                    
                                    Style.invalidStyle 
                                    
                                    : 
                                    
                                    selfieValid ? 
                                    
                                    Style.validStyle : ""
                                }
                                
                                >
                                    {
                                        selfieValid == null ? 
                                    
                                        "Pending" 
                                        
                                        : 
                                        
                                        !selfieValid ? 
                                        
                                        "Invalid" 
                                        
                                        : 
                                        
                                        selfieValid ? 
                                        
                                        "Submitted" : ""
                                    }
                                </div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                            </div>


                            {selfieBlob ? <img src={selfieBlob} alt="avatar" style={{ width: '100%', height: '325px'}}/> : ""}
                            

                            <Button style={idNumber == "" || idNumber == null || loadingUploadID || loadingUploadBackID || loadingUploadSelfie ? Style.dtakeSelfieStyle : Style.mtakeSelfieStyle }                             
                                onClick={()=> handleCameraPermission()} disabled={loadingUploadID || loadingUploadBackID || idNumber == "" || idNumber == null ||  loadingUploadSelfie}>{selfieBlob ? 'Retake' : 'Take a Selfie'}</Button>

                            <div style={Style.textStyle2}>
                                Your selfie will helps us to prove your digital identity.
                            </div>
                        </Card>

                        </div>

                        <div style={{marginBottom: 16}}>

                        <Card 
                        bodyStyle={{
                        padding:'15px 10px 15px 10px'
                        }}
                        style={{
                        marginTop: 16,
                        marginRight: '2%',
                        width: '100%'
                        }}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div style={Style.PresentStyle}>
                            <img src={mUploadIDImg} style={{paddingRight: '10px'}}/>
                                <span>Front</span></div>
                            
                            <div hidden={!loadingUploadID}> 
                            <Spin size="small"  />
                            </div>
                            <div hidden={loadingUploadID}
                            style=
                            {
                                idValid == null ? 
                                
                                Style.statusStyle 
                                
                                : 
                                
                                !idValid ? 
                                
                                Style.invalidStyle 
                                
                                : 
                                
                                idValid ? 
                                
                                Style.validStyle : Style.statusStyle 
                            }
                            
                            >
                                {
                                    idValid == null ? 
                                
                                    "Pending" 
                                    
                                    : 
                                    
                                    !idValid ? 
                                    
                                    "Invalid" 
                                    
                                    : 
                                    
                                    idValid ? 
                                    
                                    "Submitted" : "Please Wait"
                                }
                            </div>
                            
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                        </div>
                        {idURL ? <img src={idURL} alt="avatar" style={{ width: '100%' }} /> : ""}

                        <Upload

                            name="avatar"
                            //listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            // beforeUpload={beforeUpload}
                            onChange={(e) => handleUpload(e, 0)}
                        >

                        <Button style={loadingUploadID || loadingUploadSelfie || loadingUploadBackID || idNumber == "" || idNumber == null || selfieBlob == "" ? Style.dtakeSelfieStyle : Style.mtakeSelfieStyle}
                        disabled={idNumber == "" || idNumber == null || loadingUploadSelfie || loadingUploadBackID || loadingUploadID}>{idURL ? "Replace photo" : "Upload ID"}</Button>   

                        </Upload>

                        <div style={Style.textStyle2}>
                            You can check here the <a onClick={() => setTipsModal(true)}>list of valid IDs</a>.
                        </div>
                        </Card>
                        </div>

                        <div style={{marginBottom: 16}}>

                        <Card 
                        bodyStyle={{
                            padding:'15px 10px 15px 10px'
                        }}
                        style={{
                            marginTop: 16,
                            marginRight: '2%',
                            width: '100%',
                            display: idType == 'PAS' ? 'none' : ''
                        }}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div style={Style.PresentStyle}>
                                <img src={mUploadIDImg} style={{paddingRight: '10px'}}/>
                                    <span>Back</span></div>
                                
                                <div hidden={!loadingUploadBackID}> 
                                <Spin size="small"  />
                                </div>
                                <div hidden={loadingUploadBackID}
                                style=
                                {
                                    backValid == null ? 
                                    
                                    Style.statusStyle 
                                    
                                    : 
                                    
                                    !backValid ? 
                                    
                                    Style.invalidStyle 
                                    
                                    : 
                                    
                                    backValid ? 
                                    
                                    Style.validStyle : Style.statusStyle 
                                }
                                
                                >
                                    {
                                        backValid == null ? 
                                    
                                        "Pending" 
                                        
                                        : 
                                        
                                        !backValid ? 
                                        
                                        "Invalid" 
                                        
                                        : 
                                        
                                        backValid ? 
                                        
                                        "Submitted" : "Please Wait"
                                    }
                                </div>
                                
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                            </div>
                            {backURL ? <img src={backURL} alt="avatar" style={{ width: '100%' }} /> : ""}



                            <Upload

                                name="avatar"
                                //listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                // beforeUpload={beforeUpload}
                                onChange={(e) => handleUpload(e, 1)}
                            >

                            <Button style={idURL == "" || idNumber == "" || idNumber == null || loadingUploadID || loadingUploadBackID || loadingUploadSelfie ? Style.dtakeSelfieStyle : Style.mtakeSelfieStyle}
                            disabled={loadingUploadID || loadingUploadBackID || idURL == "" || idNumber == "" || idNumber == null ||  loadingUploadSelfie}>{backURL ? "Replace photo" : "Upload ID"}</Button>   
                            
                            </Upload>



                            <div style={Style.textStyle2}>
                                You can check here the <a onClick={() => setTipsModal(true)}>list of valid IDs</a>.
                            </div>
                        </Card>
                        </div>


                        <div style={{marginTop: 32, marginBottom: 16}}>
                        <Button style={
                                idType == "" ||
                                idNumber == "" ||
                                idNumber == null ||
                                !idValid  ||
                                loadingUploadID ||
                                loadingUploadSelfie ||
                                loadingUploadBackID ||
                                !selfieValid ||
                                idFormatError ? Style.dsubmitBtnStyle : Style.submitBtnStyle} onClick={() => submitDetail(5)}

                        disabled={
                            (idType == "" ||
                            idNumber == null) ||
                            !idValid  ||
                            !selfieValid ||
                            loadingUploadID ||
                            loadingUploadSelfie ||
                            loadingUploadBackID ||
                            idFormatError
                            
                        }>Submit and Next</Button>
                        </div>

                    </div>
            </div>

        </div>
            
        <div style={Style.cameraCard} align='center' hidden={!cameraModal}>
            <div align="right"><button style={Style.closeCamera} onClick={() =>closeCamera()}><Icon type="close" /></button></div>
            <video ref={playerRef} style={{ width: '100%', height: '100%'}} playsInline />
            <button style={Style.captureBtn} onClick={() => captureImage(playerRef.current)}/>
            {/* <canvas ref={canvasRef} style={{ width: '100%', height: '360px', display: 'none'}} /> */}
            <canvas ref={canvasRef} width="360" height="600" style={{ visibility: 'invisible', position: 'absolute'}} />
        </div>
        <div style={Style.footerCard} align='center' hidden={!loading}>
            <div style={Style.footerDesc}>
            Before submitting make sure all the information is correct and accurate.
            </div>

            <div style={{marginTop: 32}}>
            <Button style={pi||ci||ei||iv ? Style.disabledSubmit : Style.submitBtnStyle} onClick={step == 9 ? () => submitDetail(9) : () => submitDetail(6)} disabled={pi||ci||ei||iv}>{step == 9 ? "Resubmit Application" : "Submit"}</Button>
            </div>
        </div> 

            <Modal
                visible={tipsModal}
                onCancel={()=>setTipsModal(false)}
                footer={null}
                >
                <div style={Style.modalBodyTips} align='center'>
                    <img src={tipsImage} />
                    <div style={Style.tipsStyle}>Tips in Uploading ID</div>
                    <ul align='left' style={{marginTop: 16}}>
                        <li style={{paddingBottom: 16, wordWrap: 'break-word'}}>The Personal information you entered should be the same with your ID.</li>
                        <li style={{paddingBottom: 16, wordWrap: 'break-word'}}>Make sure that your ID is readable and clear and has no glare before submission.</li>
                        <li style={{paddingBottom: 16, wordWrap: 'break-word'}}>Make sure that your ID is up to date and not expired.</li>
                        <li style={{paddingBottom: 16, wordWrap: 'break-word'}}>List of valid IDs
                            <div style={{padding: '16px 0px 0px 16px'}}>Driver’s License, Passport, SSS or UMID, GSIS, PRC ID, Digitized Postal ID, ACR ID</div>
                        </li>
                    </ul>

                    <button style={Style.submitBtnStyle} onClick={() => setTipsModal(false)}> Got It </button>
                </div>
            </Modal>

            <Modal
                visible={blockModal}
                onCancel={()=>setBlockModal(false)}
                footer={null}
                >
                <div style={Style.modalBodyTips} align='center'>
                    <img src={CameraIcon} />
                    <div style={Style.blockedStyle}>Camera access is disabled</div>
                    <div style={Style.textStyle3}>
                    {messageModal}
                    </div>

                    <button style={Style.submitBtnStyle} onClick={() => setBlockModal(false)}> Got It </button>
                </div>
            </Modal>

        </div>
    )
}

export default ReviewApplication;
