import { useState } from "react";
import { axios } from "../../../lib/axios";

export default function MerchantCategoriesViewModel() {
    const [success, setSuccess] = useState(false);
    const [messageDisplay, setMessageDisplay] = useState("");
    const [merchantCategoryData, setMerchantCategoryData] = useState([]);
    const [merchantsList, setMerchantsList] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMerchant, setIsLoadingMerchant] = useState(false);
    const [isLoadingChannel, setIsLoadingChannel] = useState(false);
    const [isLoadingCategory, setIsLoadingCategory] = useState(false);

    const headers = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }

    async function fetchMerchantCategoryList(searchString, page, startDate, endDate) {
        setIsLoading(true);
        try {
            const response = await axios.get(`api/admin/merchant_discount_rate/?page=${page}&search=${searchString}&start=${startDate}&end=${endDate}`);
            setMerchantCategoryData(response);
            setSuccess(true);
            setIsLoading(false);
        } catch (error) {
            setMessageDisplay("Failed to fetch merchant category list");
            setSuccess(false);
            setIsLoading(false);
        }
    }

    async function addMerchantCategory(params) {
        setMessageDisplay("");
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + `/api/admin/merchant_discount_rate/`, params, headers);
            setSuccess(true);
            setMessageDisplay(response.message);
            setIsLoading(false);
        } catch (error) {
            setSuccess(false);
            if (error.response.data.message != "") {
                setMessageDisplay(error.response.data.message);
            } else {
                setMessageDisplay("Failed to create merchant category");
            }
            setIsLoading(false);
        }
    }

    async function editMerchantCategory(params, id) {
        setMessageDisplay("");
        try {
            const response = await axios.patch(process.env.REACT_APP_API_URL + `/api/admin/merchant_discount_rate/${id}/`, params, headers);
            setSuccess(true);
            setMessageDisplay(response.message);
            setIsLoading(false);
        } catch (error) {
            setSuccess(false);
            if (error.response.data.message != "") {
                setMessageDisplay(error.response.data.message);
            } else {
                setMessageDisplay("Failed to update merchant category");
            }
            setIsLoading(false);
        }
    }

    async function fetchMerchants(searchString) {
        setMessageDisplay("");
        
        if (searchString == "") {
            setMerchantsList([]);
            setIsLoadingMerchant(false);
            setSuccess(true);
            return;
        }

        setIsLoadingMerchant(true);

        try {
            const response = await axios.get(`api/admin/merchant_discount_rate/merchants/?search=${searchString}`);
            if (response.data) {
                let merchantArray = [];

                response.data.map((item, key) => {
                    merchantArray.push({"name": item.merchant_name, "value": item.id, "email": item.merchant_email });
                });
                
                setMerchantsList(merchantArray);
                setIsLoadingMerchant(false);
            }
            
            setSuccess(true);
        } catch (error) {
            setMessageDisplay("Failed to fetch merchant list");
            setSuccess(false);
            setIsLoadingMerchant(false);
            setIsLoading(false);
        }
    }

    async function fetchChannel() {
        setMessageDisplay("");
        setIsLoadingChannel(true);

        try {
            const response = await axios.get(`api/admin/merchant_discount_rate/channels/`);
            if (response.data) {
                let channelArray = [];

                response.data.map((item) => {
                    channelArray.push({"name": item, "value": item });
                });

                setChannelList(channelArray);
                setIsLoadingChannel(false);
            }
            
            setSuccess(true);
        } catch (error) {
            setMessageDisplay("Failed to fetch channel list");
            setSuccess(false);
            setIsLoadingChannel(false);
            setIsLoading(false);
        }
    }

    async function fetchCategory(channel) {
        setMessageDisplay("");
        setIsLoadingCategory(true);

        try {
            const response = await axios.get(`api/admin/merchant_discount_rate/fees/?channel=${channel}`);
            if (response.data) {
                let categoryArray = [];

                response.data.map((item, key) => {
                    categoryArray.push({"name": `${item.name} (${item.category_code})`, "value": item.category_code, "channel_fee": item.channel_fee, "channel_fee_percent": item.channel_fee_percent });
                });

                setCategoryList(categoryArray);
                setIsLoadingCategory(false);
            }
            
            setSuccess(true);
        } catch (error) {
            setMessageDisplay("Failed to fetch merchant category list");
            setSuccess(false);
            setIsLoadingCategory(false);
            setIsLoading(false);
        }
    }

    async function syncFees(searchString, startDate, endDate) {
        setMessageDisplay("");
        try {
            const response = await axios.get(`api/admin/merchant_discount_rate/sync/?search=${searchString}&start=${startDate}&end=${endDate}`);
            setSuccess(true);
            setMessageDisplay(response.message);
            setIsLoading(false);
        } catch (error) {
            setSuccess(false);
            if (error.response.data.message != "") {
                setMessageDisplay(error.response.data.message);
            } else {
                setMessageDisplay("Failed to sync fees");
            }
            setIsLoading(false);
        }
    }

    return {
        fetchMerchantCategoryList,
        addMerchantCategory,
        editMerchantCategory,
        fetchMerchants,
        fetchChannel,
        fetchCategory,
        syncFees,
        merchantsList,
        channelList,
        categoryList,
        merchantCategoryData,
        success,
        messageDisplay,
        isLoading,
        isLoadingMerchant,
        isLoadingChannel,
        isLoadingCategory
    }
}