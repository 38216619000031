import React,{ useEffect, useState } from 'react';
import { Modal, Table, Typography, Pagination } from 'antd';
import batchUploadServices from './batchUploadServices';
import { randomize } from '../../constants/constants';

const testMode = (window.location.pathname.indexOf('/test') > -1&&localStorage.getItem("userType") == "CO");

const columnsRenderStructure = [
  {
    title: 'Amount',
    dataIndex: 'amount',
  },
  {
    title: 'Description',
    dataIndex: 'description'
  },
  {
    title: `Customer Name`,
    dataIndex: `buyer_name`
  },
  {
    title: `Customer Email`,
    dataIndex: `buyer_email`,
  },
  {
    title: `Expires at`,
    dataIndex: `expire_at`,
  },
  {
    title: `Mobile Number`,
    dataIndex: 'buyer_phone'
  },
  {
    title: 'Link URL',
    dataIndex: 'links_count',
    render: (cellText, batch_object, index) => (
      <a
        href={testMode ? `${process.env.REACT_APP_BASE_URL}/test/checkout/${batch_object.uid}` : `${process.env.REACT_APP_BASE_URL}/checkout/${batch_object.uid}`}
        target="_blank"
        rel="noopener noreferrer"
        style={styles.linkURL}
      >
        {batch_object.uid.substring(0,8) + "..."}
      </a>
    )
  },
]

const TableLinksReviewModal = ({isVisible, onModalClose, batchObject, testMode}) => {

  const [visibleTableData, setVisibleTableData] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalLinksCount, setTotalLinksCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);


  useEffect(() => {
    if (isVisible) {
      fetchPageFromDB(1, pageSize);
    } else {
      setVisibleTableData([]);
    }
  }, [isVisible]);


  function onPageChange(e) {
    setCurrentPage(e);
    fetchPageFromDB(e, pageSize);
  }

  function onPageSizeChange(_, s) {
    setCurrentPage(1);
    setPageSize(s);

    fetchPageFromDB(1, s)
  }

  async function fetchPageFromDB(page, pageSize) {
    setIsTableLoading(true);
    const payload = await batchUploadServices.getGeneratedLinksData(testMode, batchObject.id, page, pageSize);

    payload.results.forEach(entry => {
      entry['key'] = randomize();
    });

    setVisibleTableData(payload.results);
    setTotalLinksCount(payload.total);
    setIsTableLoading(false);
  }

  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={() => onModalClose()}
      width={964}
      centered
      bodyStyle={styles.root}>
      <Typography style={styles.title}>
        Batch Code: {batchObject && batchObject.batch_code}
      </Typography>
      <Table
        loading={isTableLoading}
        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        columns={columnsRenderStructure}
        pagination={{hideOnSinglePage: true, pageSize: pageSize}} 
        dataSource={visibleTableData}
      />

      <div style={{ width: '100%', textAlign: 'right', justifyContent: 'center', marginTop: '15px'}}>
        <Pagination 
          size="small"
          total={totalLinksCount}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          onChange={onPageChange} 
          defaultCurrent={1} 
          key={randomize()} 
          pageSize={pageSize} 
          current={currentPage}
          // showSizeChanger={true}
          // pageSizeOptions={[5, 10, 20, 50]}
          // onShowSizeChange={onPageSizeChange}
        />
      </div>
    </Modal>
  )  
}


const styles = {
  linkURL: {
    color: '#F5922F',
    fontSize: 14,
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 24,
    fontWeight: '300',
    color: '#2B2D33',
    padding: '0 0 10px 0'
  },
}

export default TableLinksReviewModal;