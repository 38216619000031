import React from 'react';
// import fraudServices from '../fraudServices';
import { Button, Icon, Input, Card, Typography, message } from 'antd';

const CredentialCard = (props) => {
    const { data } = props;
    const creds = data && data.user_credentials;
    let api_key, api_secret, client_id

    const copyAPIKey = () => {
        api_key.select()
        document.execCommand("copy")
        message.destroy()
        message.info("Copied API Key")
        api_key.blur()
    }

    const copyAPISecret = () => {
        var dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.value = creds && creds.api_secret;
        dummy.select();
        document.execCommand("copy")
        dummy.remove();
        message.destroy()
        message.info("Copied Client Secret")
        api_secret.blur()


    }

    const copyClientID = () => {
        client_id.select()
        document.execCommand("copy")
        message.destroy()
        message.info("Copied Client ID")
        client_id.blur()


    }

    return (
        <Card
            style={styles.root}
            bodyStyle={styles.rootBody}
        >
            <Typography style={styles.title}>Integration Credentials</Typography>
            <br />
            <label style={styles.label}>API Key</label>
            <div style={styles.inputDiv}>
                <Input
                    name='domaneName'
                    placeholder="API Key"
                    style={styles.input}
                    contentEditable={false}
                    value={creds && creds.api_key}
                    ref={(textarea) => api_key = textarea}
                />
                <Button style={styles.copyBtn} onClick={copyAPIKey}>Copy</Button>
            </div>
            <br />
            <label style={styles.label}>Client ID</label>
            <div style={styles.inputDiv} >
                <Input
                    name='domaneName'
                    placeholder="Client ID"
                    style={styles.input}
                    contentEditable={false}
                    value={creds && creds.client_id}
                    ref={(textarea) => client_id = textarea}
                />
                <Button style={styles.copyBtn} onClick={copyClientID}>Copy</Button>
            </div>
            <br />
            <label style={styles.label}>API Secret</label>
            <div style={styles.inputDiv} >
                <Input
                    name='domaneName'
                    placeholder="API Secret"
                    style={styles.input}
                    contentEditable={false}
                    type='password'
                    value={creds && creds.api_secret}
                    ref={(textarea) => api_secret = textarea}
                />
                <Button style={styles.copyBtn} onClick={copyAPISecret}>Copy</Button>
            </div>
        </Card>
    )
}

const styles = {
    root: {
        margin: '12px 12px 12px 0',
        padding: '24px'
    },
    rootBody: {
        padding: 0,
    },
    title: {
        color: '#2B2D32',
        fontSize: '18px',
        fontWeight: 'bold',
        padding: '12px 0',
        opacity: '0.8'
    },
    normalFont: {
        color: '#2B2D32',
        fontSize: '16px'
    },
    cancelBtn: {
        width: '48%',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 10,
        height: 48,
        border: '1px solid #F5922F',
    },
    confirmBtn: {
        height: 48,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 10,
        width: '48%',
    },
    footerBtn: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 20,
    },
    label: {
        fontSize: 12,
        color: '#212B36',
    },
    input: {
        padding: '11px 16px 11px 16px',
        fontSize: 16,
        height: 48,
        color: '#2B2D33',
        width: '80%',
        borderRadius: '4px 0 0 4px'
    },
    inputDiv: {
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    copyBtn: {
        width: '20%',
        height: 48,
        backgroundColor: '#0a315e',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        marginLeft: '-1px',
    }
}


export default CredentialCard;