import React, {useEffect} from 'react'
import { Button, Typography, Icon, Input, Select } from 'antd'
import Pagination from '@material-ui/lab/Pagination';
import axios from 'axios'

//Components
import MetaTag from '../../../meta_tag/meta_tag'
import GroupButton from '../../../custom_components/groupButton'
import MultiwalletUsersTable from './table'
import FilterDateModal from './filter_date_modal';

const MultiwalletUsers = props => {

    const {Option} = Select

    //Local storage
    const segments = localStorage.getItem('segments')

    //States
    const [selectedTab, setSelectedTab] = React.useState("All")
    const [searchValue, setSearchValue] = React.useState("")
    const [kycLevel, setKycLevel] = React.useState("all");
    const [modalVisibility, setModalVisibility] = React.useState(false);
    const [tableData, setTableData] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    //Start Date & End Date
    const [startMoment, setStartMoment] = React.useState("")
    const [startDate, setStartDate] = React.useState("")
    const [endMoment, setEndMoment] = React.useState("")
    const [endDate, setEndDate] = React.useState("")
    const [endOpen, setEndOpen] = React.useState(false)
    const [dateFilterType, setDateFilterType] = React.useState("created")

    //Pagination
    const [currentPage, setCurrentPage] = React.useState(1)
    const [pageSize, setPageSize] = React.useState(10)

    const types = ['All', 'Master Wallets', 'Sub-wallets'];


    useEffect(()=>{
        fetchUsers(selectedTab.split(/-| /)[0], currentPage, pageSize, startDate, endDate, dateFilterType, searchValue, kycLevel)
    },[])
    

    //Functions

    const fetchUsers = async(type, current, size, start, end, filter_type, search, level) => {
        setLoading(true)
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        };

        let encodedSearch = encodeURIComponent(search)
        let response = await axios.get(
            process.env.REACT_APP_API_URL + `/api/admin/multiwallet_users/?multiwallet_user_type=${type}&page=${current}&page_size=${size}&start=${start}&end=${end}&date_type_filter=${filter_type}&search=${encodedSearch}&kyb_kyc_status=${level}`,
            yourConfig
        );

        if(response.data){
            setLoading(false)
            setModalVisibility(false)
            setTableData(response.data)
        }
    }

    const isFiltered = () => {
        return searchValue != "" ||
        kycLevel != "all" ||
        startDate != "" ||
        endDate != ""
    }

    const applyFilter = () => {
        fetchUsers(selectedTab.split(/-| /)[0], currentPage, pageSize, startDate, endDate, dateFilterType, searchValue, kycLevel)
    }

    const clearFilter = () => {
        setKycLevel("all")
        setStartDate("")
        setEndDate("")
        setStartMoment("")
        setEndMoment("")
        setSearchValue("")
        fetchUsers("All", 1, pageSize, "", "", "created", "", "all")
    }
    return(
        <div>
            <MetaTag title="Multiwallet Users"/>
            <div style={style.mainDiv}>
                <Typography style={style.header}>Multi-wallet Users</Typography>
                <div style={{...style.tabDiv, margin: '32px 0px 32px 0px'}}>
                    <GroupButton status={types} selected={selectedTab} segments={segments} handleClick={(e) => 
                        {
                            setSelectedTab(e.target.value);
                            fetchUsers(e.target.value.split(/-| /)[0], currentPage, pageSize, startDate, endDate, dateFilterType, searchValue, kycLevel)
                        }} />
                </div>
                <div style={style.body} className="top-16">
                    <div style={{display: 'flex', justifyContent: 'space-between', padding: '0px 32px 0px 32px'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Typography style={style.filter}>Filter</Typography>
                            <a style={style.clearBtnDiv} hidden={!isFiltered()} onClick={()=>clearFilter()}>
                                <Icon style={style.clearIcon} type="close-circle" theme="filled" />
                                <span style={style.clearBtn}>Clear Filter</span>
                            </a>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent:'flex-start', padding: '16px 32px 24px 32px' }}>
                        <Select 
                            size="large"
                            value={kycLevel}
                            onChange={(e)=>setKycLevel(e)}
                            style={{width: '160px', zIndex: 1, color: '#000'}}
                            >
                            <Option value="all">All</Option>
                            <Option value="nonkyc">Non-KYC</Option>
                            <Option value="approved">Approved</Option>
                        </Select> 
                        <Button
                            onClick={()=>setModalVisibility(true)} 
                            style={{
                                ...style.filterDate,
                                justifyContent:'space-between',
                                color: '#2B2D32',
                                border: '1px solid #c5cacf'
                            }}>
                            <div>Filter date</div> 
                            <Icon style={{fontSize: '17px', color: '#2B2D32'}} type='calendar'/>
                        </Button>
                        <Input
                            placeholder="Search"
                            style={style.searchInput}
                            suffix={<Icon type="search" style={{ fontSize: '18px', paddingRight: '8px'}} />}
                            onChange={(e)=>setSearchValue(e.target.value)}
                            value={searchValue}
                        />
                        <button
                            className={`btn--${segments}`} 
                            style={style.applyBtn} 
                            onClick={()=>applyFilter()}
                        >Apply</button>
                    </div>
                    <div style={{padding: '0px 32px 0px 32px'}}>
                        <MultiwalletUsersTable loading={loading} data={tableData} pageSize={pageSize}/>

                        {
                            tableData && tableData.results.length > 0 &&
                            <div style={{padding: '20px 32px 20px 32px', display:'flex', justifyContent:'space-between'}}>
                                <div>
                                    {(currentPage - 1) * pageSize  + 1} 
                                    - 
                                    {(currentPage - 1) * pageSize  + tableData.page_total} out of {tableData.total_data}
                                </div>
                                <div style={{display: 'flex'}}>
                                    <Select 
                                        size="small" 
                                        style={{width: '100px'}} 
                                        value={pageSize}
                                        onChange={(e)=>
                                        {
                                            setPageSize(e);
                                            fetchUsers(selectedTab.split(/-| /)[0], currentPage, e, startDate, endDate, dateFilterType, searchValue, kycLevel)
                                        }}>
                                        <Option value={5}>Show 5</Option>
                                        <Option value={10}>Show 10</Option>
                                        <Option value={15}>Show 15</Option>
                                        <Option value={20}>Show 20</Option>
                                    </Select>
                                    <Pagination 
                                        count={tableData.pages} 
                                        shape="rounded"
                                        page={currentPage} 
                                        size="small"
                                        onChange={(event, page)=>{
                                            setCurrentPage(page);
                                            fetchUsers(selectedTab.split(/-| /)[0], page, pageSize, startDate, endDate, dateFilterType, searchValue, kycLevel)
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <FilterDateModal
                        applyFilter={()=>applyFilter()}
                        dateFilterType={dateFilterType}
                        setDateFilterType={setDateFilterType}
                        startMoment={startMoment}
                        setStartMoment={setStartMoment}
                        endMoment={endMoment}
                        setEndMoment={setEndMoment}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        endOpen={endOpen}
                        setEndOpen={setEndOpen}
                        segments={segments} 
                        visibility={modalVisibility} 
                        close={()=>setModalVisibility(false)} 
                    />
                </div>
            </div>
        </div>
    )
}

const style = {
    mainDiv:{
        padding: '32px 24px 32px 24px'
    },
    tabDiv: {
        display: 'flex', 
        justifyContent: 'space-between',
    },
    body:{
        width: '100%',
        padding: '32px 0px 32px 0px',
        backgroundColor: '#FFF'
    },
    header: {
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '20px',
        color: '#2B2D33'
    },
    filter: {
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#000',

    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    applyBtn: {
        zIndex: 1, 
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: 92,
        cursor: 'pointer',
    },
    clearBtnDiv: {
        backgroundColor: '#E9EEF4',
        borderRadius: 20,
        padding: '6px 12px',
        margin: '0 12px',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #D4D5D8'
    },
    clearBtn: {
        color: '#2b2d32',
        fontSize: 14,
        paddingLeft: 4,
        fontWeight: '500',
        opacity: '0.8'
    },
    clearIcon: {
        color: '#2b2d32',
        opacity: '0.8',
        fontSize: 12,
    },
    searchInput:{
        zIndex: 1, 
        width: '218px', 
        height: '40px',
        borderRadius: '4px', 
        padding: '0 8px 0 0px' 
    },
    filterDate:{
        zIndex: 1, 
        height: '40px', 
        width: '160px',
        paddingTop: '10px', 
        margin: '0px 12px 0px 12px',
        display: 'flex',
    },
    dropdownItems: {
        display: 'flex',
        alignItems: 'center',
        padding: '7px 4px 7px 16px',
        fontSize: 16,
        color: '#2B2D33',
        border: '1px solid #D1D5DD',
        borderRadius: 5,
    }

}

export default MultiwalletUsers