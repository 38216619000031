import { GetShipmentsActionTypes } from "./shipmentsAction";
const initialState = {
  shipments: [],
  loading: false,
  error: ""
};
export default function shipmentsReducer(state = initialState, action) {
  switch (action.type) {
    case GetShipmentsActionTypes.GET_SHIPMENTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GetShipmentsActionTypes.GET_SHIPMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        shipments: action.payload
      };
    case GetShipmentsActionTypes.GET_SHIPMENTS_FAILED:
      return {
        ...state,
        loading: false,
        shipments: [],
        error: action.payload
      };
    default:
      return initialState;
  }
}