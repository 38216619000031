import React from 'react'
import MetaTags from "react-meta-tags";

class MetaTag extends React.Component{
    render(){
        return(
            <div className="wrapper">
                <MetaTags>
                <title>BUx | {this.props.title}</title>
                {/*<meta
                    name="description"
                    content=""
                />
                <meta property="og:title" content={"Bux" | this.props.title} />
                <meta property="og:image" content="https://bux-api-prd-storage.s3-ap-southeast-1.amazonaws.com/static/images/buxcover.jpg"/>*/}
                </MetaTags>
            </div>
        )
    }
}
export default MetaTag