import React, { useState, useEffect } from "react";
import { Button, Modal, message, Icon, Checkbox, Divider } from "antd";
import { TextField, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./css/merchant_categories.css";
import MerchantCategoriesViewModel from "./merchant_categories_viewmodel";
import { validateNumberWithDecimal } from "../../../utils/validators/validateNumberWithDecimal";

const MerchantCategoriesModal = (props) => {
  const [isTouched, setIsTouched] = useState(false);
  const [openMerchant, setOpenMerchant] = useState(false);
  const [openChannel, setOpenChannel] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);

  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedChannel, setSelectedChannel] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const [formFieldData, setFormFieldData] = useState({
    merchantName: "",
    merchantID: "",
    emailAddress: "",
    channel: "",
    categoryName: "",
    categoryCode: "",
    channelFee: "",
    channelFeePercentage: "",
  });

  const {
    addMerchantCategory,
    editMerchantCategory,
    fetchMerchants,
    fetchChannel,
    fetchCategory,
    merchantsList,
    channelList,
    categoryList,
    success,
    messageDisplay,
    isLoadingChannel,
    isLoadingCategory,
    isLoadingMerchant,
  } = MerchantCategoriesViewModel();

  async function submitCategory() {
    if (
      formFieldData.emailAddress != "" &&
      formFieldData.channel != "" &&
      formFieldData.categoryName != ""
    ) {
      let params = {
        user_id: formFieldData.merchantID,
        channel: formFieldData.channel,
        category_code: formFieldData.categoryCode,
      };

      if (props.editData) {
        await editMerchantCategory(params, props.editData.id);
      } else {
        await addMerchantCategory(params);
      }
    } else {
      setIsTouched(true);
    }
  }

  async function clearForm() {
    setFormFieldData({
      merchantName: "",
      merchantID: "",
      emailAddress: "",
      channel: "",
      categoryName: "",
      categoryCode: "",
      channelFee: "",
      channelFeePercentage: "",
    });
    setIsTouched(false);
    await fetchMerchants("");
  }

  function updateFields(fields) {
    setFormFieldData({ ...formFieldData, ...fields });
  }

  useEffect(() => {
    if (props.editData) {
      setFormFieldData({
        merchantName: props.editData.user.merchant_name,
        merchantID: props.editData.user.id,
        emailAddress: props.editData.user.merchant_email,
        channel: props.editData.segment.channel,
        categoryName: `${props.editData.segment.name} (${props.editData.segment.category_code})`,
        categoryCode: props.editData.segment.category_code,
        channelFee: props.editData.segment.channel_fee,
        channelFeePercentage: props.editData.segment.channel_fee_percent,
      });

      fetchCategoryOptions(props.editData.segment.channel);
    }
  }, [props.editData]);

  useEffect(() => {
    displayMessage();
  }, [messageDisplay, success]);

  useEffect(() => {
    displayMessage();
  }, []);

  useEffect(() => {
    if (formFieldData.emailAddress == "" && openMerchant) {
      fetchMerchantOptions();
      return;
    }

    if (merchantsList && openMerchant) {
      const exists = merchantsList.some((merchant) =>
        merchant.email
          .toLowerCase()
          .includes(formFieldData.emailAddress.toLowerCase())
      );

      if (!exists) {
        fetchMerchantOptions();
      }
    }
  }, [formFieldData.emailAddress]);

  useEffect(() => {
    fetchChannelOptions();
  }, []);

  async function fetchMerchantOptions() {
    await fetchMerchants(formFieldData.emailAddress);
  }

  async function fetchChannelOptions() {
    await fetchChannel();
  }

  async function fetchCategoryOptions(channel) {
    await fetchCategory(channel);
  }

  function displayMessage() {
    setTimeout(() => {
      if (messageDisplay != "" && success == true) {
        message.destroy();
        message.success(messageDisplay);
        clearForm();

        if (props.editData) {
          props.closeModal();
        } else {
          props.submitModal();
        }
      } else if (messageDisplay != "" && success == false) {
        message.destroy();
        message.error(messageDisplay);
      }
    }, 1000);
  }

  function displayTextField(label, onChange, value, disabled, className = "") {
    return (
      <Grid item={true} className={className}>
        <TextField
          id="outlined-basic"
          label={label}
          disabled={disabled}
          margin="normal"
          variant="outlined"
          fullWidth
          className={isTouched && value == "" ? `error-field` : ``}
          onChange={onChange}
          value={value}
        />
        {/* {isTouched && value == "" && (
          <p className="error-field-message">This is a required field</p>
        )} */}
      </Grid>
    );
  }

  return (
    <Modal
      title={
        props.editData ? "Edit Merchant Category" : "Add Merchant Category"
      }
      centered
      visible={props.openModal}
      onCancel={() => {
        clearForm();
        props.closeModal();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            clearForm();
            props.closeModal();
          }}
          className="secondary-btn"
        >
          Cancel
        </Button>,
        <Button
          className="primary-btn"
          key="submit"
          type="primary"
          onClick={() => submitCategory()}
        >
          {props.editData ? "Update" : "Add"}
        </Button>,
      ]}
      className="merchant-category-modal"
    >
      <Grid container>
        <div className="field-row-container">
          <Grid item={true} className="full-width-grid-item">
            <Autocomplete
              style={{ width: "100%", marginBottom: "8px" }}
              open={openMerchant}
              onOpen={() => {
                setOpenMerchant(true);
              }}
              onClose={() => {
                setOpenMerchant(false);
              }}
              onBlur={() => {
                updateFields({ emailAddress: selectedEmail });
              }}
              getOptionSelected={(option, value) =>
                option.email === value.email
              }
              getOptionLabel={(option) => option.email}
              options={merchantsList}
              loading={isLoadingMerchant}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="User Email Address"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingMerchant ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setFormFieldData({
                        ...formFieldData,
                        emailAddress: "",
                        merchantName: "",
                        merchantID: "",
                        emailAddress: "",
                      });
                    } else {
                      updateFields({ emailAddress: e.target.value });
                    }
                  }}
                  value={formFieldData.emailAddress}
                />
              )}
              onChange={async (option, value) => {
                setFormFieldData({
                  ...formFieldData,
                  merchantName: value && value.name ? value.name : "",
                  merchantID: value && value.value ? value.value : "",
                  emailAddress: value && value.email ? value.email : "",
                });
                setSelectedEmail(value && value.value ? value.email : "");
              }}
              inputValue={formFieldData.emailAddress}
              disabled={props.editData}
            />

            {isTouched && formFieldData.emailAddress == "" && (
              <p className="error-field-message" style={{ marginTop: "2px" }}>
                This is a required field
              </p>
            )}
          </Grid>
        </div>

        <div className="field-row-container">
          {displayTextField(
            "User ID",
            (e) => updateFields({ merchantID: e.target.value }),
            formFieldData.merchantID,
            true
          )}
          {displayTextField(
            "User Name",
            (e) => updateFields({ merchantName: e.target.value }),
            formFieldData.merchantName,
            true
          )}
        </div>

        <div className="field-row-container">
          <Grid item={true} className="full-width-grid-item">
            <Autocomplete
              style={{
                marginBottom: "8px",
                marginTop: "16px",
              }}
              open={openChannel}
              onOpen={() => {
                setOpenChannel(true);
              }}
              onClose={() => {
                setOpenChannel(false);
              }}
              onBlur={() => {
                updateFields({ channel: selectedChannel });
              }}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={channelList}
              loading={isLoadingChannel}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Channel"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingChannel ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setFormFieldData({
                        ...formFieldData,
                        channel: "",
                        categoryName: "",
                        categoryCode: "",
                        channelFee: "",
                        channelFeePercentage: "",
                      });
                    } else {
                      updateFields({ channel: e.target.value });
                    }
                  }}
                  value={formFieldData.channel}
                />
              )}
              onChange={async (option, value) => {
                setSelectedChannel(value && value.name ? value.name : "");
                if (value && value.name) {
                  updateFields({
                    channel: value && value.name ? value.name : "",
                  });
                  fetchCategoryOptions(value.name);
                } else {
                  setFormFieldData({
                    ...formFieldData,
                    channel: "",
                    categoryName: "",
                    categoryCode: "",
                    channelFee: "",
                    channelFeePercentage: "",
                  });
                }
              }}
              inputValue={formFieldData.channel}
              value={formFieldData.channel}
              disabled={props.editData}
            />

            {isTouched && formFieldData.channel == "" && (
              <p className="error-field-message" style={{ marginTop: "2px" }}>
                This is a required field
              </p>
            )}
          </Grid>
        </div>

        <div className="field-row-container">
          <Grid item={true} className="full-width-grid-item">
            <Autocomplete
              style={{
                marginBottom: "8px",
                marginTop: "16px",
              }}
              open={openCategory}
              onOpen={() => {
                setOpenCategory(true);
              }}
              onClose={() => {
                setOpenCategory(false);
              }}
              onBlur={() => {
                updateFields({ categoryName: selectedCategory });
              }}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={categoryList}
              loading={isLoadingCategory}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category Name"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingCategory ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setFormFieldData({
                        ...formFieldData,
                        categoryName: "",
                        categoryCode: "",
                        channelFee: "",
                        channelFeePercentage: "",
                      });
                    } else {
                      updateFields({ categoryName: e.target.value });
                    }
                  }}
                  value={formFieldData.categoryName}
                />
              )}
              onChange={async (option, value) => {
                setFormFieldData({
                  ...formFieldData,
                  categoryName: value && value.name ? value.name : "",
                  categoryCode: value && value.value ? value.value : "",
                  channelFee:
                    value && value.channel_fee ? value.channel_fee : "",
                  channelFeePercentage:
                    value && value.channel_fee_percent
                      ? value.channel_fee_percent
                      : "",
                });
                setSelectedCategory(value && value.name ? value.name : "");
              }}
              inputValue={formFieldData.categoryName}
              value={formFieldData.categoryName}
              disabled={formFieldData.channel == ""}
            />

            {isTouched && formFieldData.categoryName == "" && (
              <p className="error-field-message" style={{ marginTop: "2px" }}>
                This is a required field
              </p>
            )}
          </Grid>
        </div>

        <div className="field-row-container">
          {displayTextField(
            "Channel Fee",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({ channelFee: e.target.value });
              }
            },
            formFieldData.channelFee,
            true
          )}
          {displayTextField(
            "Channel Fee Percent",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({ channelFeePercentage: e.target.value });
              }
            },
            formFieldData.channelFeePercentage,
            true
          )}
        </div>
      </Grid>
    </Modal>
  );
};

export default MerchantCategoriesModal;
