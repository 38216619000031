import React from 'react';
import {history} from '../../../store/history';
import {Menu} from 'antd';
import * as Tabs from './admin_tab_const';

const Admin = props => {
    const isMac = window.screen.width * window.devicePixelRatio === 1440 && window.screen.height * window.devicePixelRatio === 900;
    const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
    

    const { SubMenu } = Menu;
    const [activeMenu, setActive] = React.useState("");
    const [openKeys, setOpenKeys] = React.useState([''])

    const redirectTo = (item) => {
      if(item == 'Payment Request'){
        history.push('/orders')
      }
      else if(item == 'Multi-wallet Billing'){
        history.push('/recon/transfer_money/')
      }
      else{
          history.push(`/${item.toString().toLowerCase().replace(/\s+/g, '_')}`) 
      }
    }

    const {
      endpoints,
      collapsed,
      hoverState,
      hover,
      userType,
    } = props;

    const toMap = userType === 'PS' ? Tabs.partner_support_tabs : 
                  userType === 'KC' ? Tabs.kyc_support_tabs : 
                  userType === 'PF' ? Tabs.partner_finance_tabs : 
                  userType === 'MA' ? Tabs.marketing_tabs :
                  userType === 'SD' ? Tabs.sales_admin_tabs : Tabs.admin_side_tabs

    const subMenuKeys = ['Users', 'CashBux', 'Payouts', 'Wallets', 'Reconciliation', 'Submissions', 'Endorsement', 'Ledger', 'Marketing', 'Settings']

    const AdminSubMenu = (item) => {
      return(
        <SubMenu
          className='side-submenu' 
          name="sidebar-btn"
          style={{marginBottom: 8}}
          key={item.menu}
          title={
            <div className="menuTextStyle"
              style={{
              // marginLeft:
              //         isMac && collapsed ? '-1.2vw' :
              //         isMac && !collapsed ? '-1.2vw' :
              //         isHighReso && collapsed ? '-0.75vw':
              //         isHighReso && !collapsed ? '-0.75vw':
              //         !collapsed ? '-1.1vw' : '-1.4vw',
              marginTop: '8px'
            }}>
              <span style={{marginLeft: collapsed && '-5px'}}>
                <img alt="icon" 
                  className="menuIcon"
                  src={ activeMenu === item.menu || 
                  hover === item.menu ? 
                    item.active_icon 
                  : 
                    item.inactive_icon} />

                <span style={{color: "#2b2d23"}}>{ !collapsed ? item.menu : "" }</span>
              </span>
            </div>
          }>
            {
              item.sub_menu.map((item, key) => {
                return(
                  <Menu.Item onClick={() => {
                    history.push(item.endpoint)
                    setActive(item.menu)
                  }}
                    className={
                      `
                      ${collapsed ? 'sub-menu-collapse-state' : 'sub-menu-url-state'}
                      ${(activeMenu === item.menu || hover === item.menu) ? `admin-bg` : ""}
                      `
                    }
                    onMouseLeave={() => hoverState("")}
                    onMouseEnter={() => hoverState(item.menu)}
                    key={key+1}>
                    {item.menu}
                </Menu.Item>
                )
              })
            }
        </SubMenu>
      )
    }

    const onOpenChange = keys => {
      console.log(keys)
      const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
      if (subMenuKeys.indexOf(latestOpenKey) === -1) {
        setOpenKeys(keys);
      } 
      else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      }
    };

    React.useEffect(() => {
      if(collapsed) {
        setOpenKeys([''])
      }
    }, [collapsed])


    return(
        <div style={{height: '100%', marginBottom: '150px'}}>
          <Menu style={{ 
              height: '100%', 
              borderRight: 0,  
              backgroundColor: 'transparent', 
              marginTop: '24px'}} 
              mode="inline"
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              >
              {
                toMap.map((item, key) => {
                  return(
                    subMenuKeys.includes(item.menu) ?
                      AdminSubMenu(item)
                    
                    :

                    <a href
                      id='sidebar-btn'
                      onClick={() => {
                        redirectTo(item.menu)
                        setActive(item.menu)

                      }}>
                      
                      <div className={`menuTextStyle menuDivStyle
                                      ${activeMenu === item.menu ? "activeMenuTestStyle" : ""}`}>
                        <div className={`${collapsed ? "collapseState" : "urlState"}
                                      ${(activeMenu === item.menu || hover === item.menu) ? `admin-bg` : ""}
                                      `}
                              onMouseLeave={() => hoverState("")}
                              onMouseEnter={() => hoverState(item.menu)}>
                                
                          <span>
                            <img alt="icon" 
                              src={
                                activeMenu === item.menu || 
                                hover === item.menu ? 
                                  item.active_icon 
                                : 
                                  item.inactive_icon
                              } 
                              className="menuIcon" />

                              { !collapsed ? item.menu : ''}
                          
                          </span>
                        </div>
                      </div>
                    </a>
                  )
                }, [])
              }
          </Menu>
        </div>
    );           
};

export default Admin;