import React,{ useState, useEffect } from 'react';
import { Box, Tabs, Tab } from '@material-ui/core';
import MetaTag from '../../meta_tag/meta_tag';
import { Button, Typography } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Blacklisted from './blacklisted';
import { useSelector, useDispatch } from 'react-redux';
import ButtonGroup from 'antd/lib/button/button-group';
import fraudActions from './fraudActions';
import Mobile from './mobile';
import BuxUsers from './users';
import {history} from '../../../store/history'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{padding: 0}} p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const ButtonTab = ({rootStyle}) => {
  const dispatch = useDispatch();
  const selected = useSelector(state => state.fraud.fraudBtnSelected);
  
  const status = ['Email', 'Mobile Number', 'BUx Users'];
  
  const handleClick = (event) => {
    dispatch(fraudActions.setFraudSelectedBtn(event.target.value));
  }
  
  return (
    <div style={rootStyle}>
      <ButtonGroup>
        {
          status.map((btn, i) => {
            return (
              <Button
                key={btn}
                value={btn}
                onClick={handleClick}
                style={{...styles.btnGroup, 
                  color: btn === selected? '#FFF' : '#2B2D33', 
                  backgroundColor: btn === selected? '#0D3D76' : '#FFF',
                  borderRadius: i === 0? '10px 0 0 10px' : i === 2? '0 10px 10px 0' : 0 }}
                  >
                  {btn}
              </Button>
            )
          })
        }
      </ButtonGroup>
    </div>
  )
}

const FraudManagement = () => {

  useEffect(()=>{
    if(localStorage.getItem("jwtToken") === "" || !localStorage.getItem("jwtToken")){
      history.push('/login')
    }
    else if(['ME', 'CO'].includes(localStorage.getItem("userType"))){
      history.push('/dashboard')
    }
    else if(['PS', 'PF'].includes(localStorage.getItem("userType"))){
      history.push('/submissions_kyb')
    }
    else if(!['BS', 'KC', 'AD'].includes(localStorage.getItem("userType"))){
      history.push('/users')
    }
  },[])

  const selected = useSelector(state => state.fraud.fraudBtnSelected);

  return (
    <div style={{marginLeft: '-30px'}}>
      <MetaTag title="Fraud Management" />
      <div style={styles.titleContainer}>
        <Typography style={styles.title}>Fraud Management</Typography>
        <ButtonTab />
      </div>
      <TabPanel value={selected} index={'Email'}>
        <Blacklisted />
      </TabPanel>
      <TabPanel value={selected} index={'Mobile Number'}>
        <Mobile />
      </TabPanel>
      <TabPanel value={selected} index={'BUx Users'}>
        <BuxUsers />
      </TabPanel>
    </div>
  )
};

const styles = {
  root: {

  },
  titleContainer: {
    padding: '42px 42px 0px 42px'
  },
  title: {
    backgroundColor: 'transparent',
    width: '100%',
    color: '#000',
    fontWeight: 'bold',
    fontSize: 28,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 32,
  },
  btnGroup: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '0 32px',
  },
};

export default FraudManagement;