import React from "react";
import { Input, Select, Typography } from "antd";
import { NATURE_BUSINESS } from "../../../../constants/constants";

const SubwalletForm = ({
  data,
  handleChange,
  isLoading,
  natureBusinessChange,
  styles,
}) => {
  const subuserRole = localStorage.getItem("subuser_role");

  return (
    <>
      {subuserRole === "CO" && (
        <>
          <div>
            <label style={styles.labelFont}>Business Name</label>
            <Input
              style={styles.input}
              name="business_name"
              value={data["business_name"]}
              placeholder="Business Name"
              onChange={handleChange}
              disabled={isLoading}
            />
          </div>
          <div>
            <label style={styles.labelFont}>Nature of Business</label>
            <Select
              style={{ width: "312px", margin: "4px 0px 12px 0px" }}
              name="nature_business"
              onChange={natureBusinessChange}
              value={data["nature_business"]}
            >
              {NATURE_BUSINESS.map((item, i) => {
                return <Select.Option value={i}>{item}</Select.Option>;
              })}
            </Select>
          </div>
        </>
      )}
      <div>
        <label style={styles.labelFont}>First Name</label>
        <Input
          style={styles.input}
          name="first_name"
          value={data["first_name"]}
          placeholder="First Name"
          onChange={handleChange}
          disabled={isLoading}
        />
      </div>
      <div>
        <label style={styles.labelFont}>Last Name</label>
        <Input
          style={styles.input}
          name="last_name"
          value={data["last_name"]}
          placeholder="Last Name"
          onChange={handleChange}
          disabled={isLoading}
        />
      </div>
      <div>
        <label style={styles.labelFont}>Email</label>
        <Input
          style={styles.input}
          name="email"
          placeholder="Email"
          value={data["email"]}
          type="email"
          onChange={handleChange}
          disabled={isLoading}
        />
      </div>
      <div style={{ position: "relative" }}>
        <label style={styles.labelFont}>Mobile Number</label>
        <Input
          style={{ ...styles.input, paddingLeft: "18%", paddingTop: "4%" }}
          name="contact"
          value={data["contact"]}
          maxLength={10}
          onChange={handleChange}
          disabled={isLoading}
        />
        <Typography style={styles.mobilePrefix}>+63 | </Typography>
      </div>
    </>
  );
};

export default SubwalletForm;
