import React,{  } from "react";
import { Card, Dropdown, Button, Menu, Typography } from "antd";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const menu = (
  <Menu>
    <Menu.Item key="1">
      1st menu item
    </Menu.Item>
    <Menu.Item key="2">
      2nd menu item
    </Menu.Item>
    <Menu.Item key="3">
      3rd item
    </Menu.Item>
    <Menu.Item key="4">
      1st menu item
    </Menu.Item>
    <Menu.Item key="5">
      2nd menu item
    </Menu.Item>
    <Menu.Item key="6">
      3rd item
    </Menu.Item>
  </Menu>
);

const OrderCard = () => {
  const segments = localStorage.getItem('segments');
  return (
    <Card size='small' style={styles.root} >
      <Typography style={styles.title}>Order Physical Card</Typography>
      <div style={{ textAlign: 'left'}}>
        <Typography style={styles.subtitle}>EON Card Pickup Locator</Typography>
        <div style={styles.dropdown}>
          <label>Location</label>
          <Dropdown overlay={menu} trigger='click' overlayStyle={{padding: '12px 16px'}}>
            <Button style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: 48}}>
              Metro Manila <ExpandMoreIcon />
            </Button>
          </Dropdown>
        </div>
        <div>
          <label>Area</label>
          <Dropdown overlay={menu} trigger='click'>
            <Button style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: 48}}>
              Bulacan <ExpandMoreIcon />
            </Button>
          </Dropdown>
        </div>
        <div style={{ height: 338, }}>
          <button className={`btn--${segments}`} style={styles.mapBtn}> View Full Map</button>
        </div>
      </div>
    </Card>
  )
}

const styles = { 
  root: {
    borderRadius: 10,
    textAlign: 'center'
  },
  title: {
    fontSize: 16,
    display: 'center',
    padding: '8px 0px 8px 0px',
    color: '#000000',
    fontWeight: 'bold'
  },
  subtitle: {
    fontSize: 10,
    padding: '12px 0 8px 0',
    color: '#000000',
  },
  dropdown: {
    fontSize: 12,
    padding: '8px 0 8px 0',
    color: '#000000',
  },
  mapBtn: {
    margin: '16px 0px 0px 0px',
    height: 40,
    width: '100%',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
    top: 268
  }
}

export default OrderCard;