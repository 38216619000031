import { Button, message } from 'antd'
import React from 'react'
import Close from '../../../../../static/icons/close_icon_orange.svg'
import Info from '../../../../../static/icons/info.svg'

const ManualAddMoney = props => {
    const max_width = 2000;
    const max_height = 2000;

    let uploadRef = React.useRef()

    const [imageFile, setImageFile] = React.useState("")
    const [imageFileName, setImageFileName] = React.useState("")
    const [imageFileURL, setImageFileURL] = React.useState("")

    const bankDetails = [
        {
            label: 'Bank name:',
            details: 'Unionbank of the Philippines'
        },
        {
            label: 'Bank branch:',
            details: 'UBP Plaza'
        },
        {
            label: 'Account name:',
            details: 'UBX Philippines Corporation'
        },
        {
            label: 'Account type:',
            details: 'Corporate Regular Checking Account'
        },
        {
            label: 'Account no.:',
            details: '000-590-086-352'
        },
    ]

    function handleUploadImage(info) {
        console.log(info)

        if(!['image/png', 'inage/jpg', 'image/jpeg'].includes(info.type)){
            message.error('Invalid file type')
        }
        else{
            // read the files
            var reader = new FileReader();
            reader.readAsArrayBuffer(info);

            reader.onload = function (event) {
            // blob stuff
                var blob = new Blob([event.target.result]); // create blob...
                window.URL = window.URL || window.webkitURL;
                var blobURL = window.URL.createObjectURL(blob); // and get it's URL
                
                setImageFile(blob)
                setImageFileURL(blobURL)
                setImageFileName(info.name)

                var image = new Image();
                image.src = blobURL;
                image.onload = function() {
                    // have to wait till it's loaded
                    var resized = resizeMe(image); // resized image url
                    var blob = dataURItoBlob(resized);
                    props.setImageBlob(blob)
                }
            };   
        }
             
    }

    function resizeMe(img) {
      
        var canvas = document.createElement('canvas');
  
        var width = img.width;
        var height = img.height;
  
        // calculate the width and height, constraining the proportions
        if (width > height) {
          if (width > max_width) {
            //height *= max_width / width;
            height = Math.round(height *= max_width / width);
            width = max_width;
          }
        } else {
          if (height > max_height) {
            //width *= max_height / height;
            width = Math.round(width *= max_height / height);
            height = max_height;
          }
        }
        
        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        
        return canvas.toDataURL("image/jpeg",0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)
        
        // you can get BLOB too by using canvas.toBlob(blob => {});
    }

    function dataURItoBlob(dataUri) {
        var byteString = atob(dataUri.split(',')[1]);
        var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], {type: mimeString});
        return blob
    }

    function removeFile() {
        setImageFile("")
        setImageFileName("")
        setImageFileURL("")
        props.setImageBlob("")
    }

    return(
        <div style={{width: '100%'}}>
            <div style={Style.miniText}>Upload Deposit Slip or Proof of Payment</div>
            <div style={Style.uploadDiv}>
                <div style={{display: 'flex'}}>
                    <div style={{fontSize: '16px', color: '#909196', marginTop: '9px'}} hidden={imageFile != ""}>Upload File</div>
                    <div style={{display: 'flex'}} hidden={imageFile == ""}>
                        <img src={imageFileURL} alt="#"/>
                        <span style={Style.fileName}>{imageFileName}</span>
                    </div>
                </div>
                {
                    imageFile != "" ? 
                    <a onClick={()=>removeFile()} style={{marginTop: '9px'}}>
                        <img src={Close} alt="#" style={{color:'#F5922F', width: '12px'}}/>
                    </a>
                    :
                    <button className={`outline-btn--${props.segments} btn-height`} onClick={()=>uploadRef.current.click()}>Upload Photo</button>
                }
                <input type="file"
                    data-test="upload-file-input"
                    ref={uploadRef}
                    style={{display: 'none'}}
                    onChange={(e)=>handleUploadImage(e.target.files[0])}
                    accept=".jpg,.png,.jpeg"
                    >
                </input>
            </div>

            <div style={Style.note}>
                <img src={Info} alt="#"/>
                <div style={{marginLeft:'10px', marginTop: '-2px'}}>
                    This is the only acknowledged bank deposit account.
                </div>
            </div>

            <div style={Style.headerCard}>
                <div style={Style.headerText}>Transfer to BUx Bank Account</div>
            </div>
            <div style={Style.bodyCard}>
                <table>
                    {
                        bankDetails.map((item,i) => {
                            return(
                                <tr style={{marginBottom: '10px'}}>
                                    <td style={{...Style.label, width: '150px'}}>{item.label}</td>
                                    <td style={{...Style.label, fontWeight: '500'}}>{item.details}</td>
                                </tr>
                            )
                        })
                    }
                </table>
            </div>
        </div>
    )
}


const Style = {
    miniText: {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '12px',
        color: '#212B36'
    },

    headerText: {
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '24px',
        color: '#2B2D33'
    },

    label: {
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2B2D33',
    },

    fileName: {
        fontSize: '16px', 
        color: '#2b2d33',
        margin: '9px 0px 0px 10px',
        width: '400px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap', 
    },
    uploadDiv: {
        width: '100%',
        height: '82px',
        border: '1px solid #E6EAF0',
        borderRadius: '4px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px'
    },
    note: {
        width: '100%',
        height: '36px',
        border: '1px solid #77B2F2',
        background: 'rgba(119, 178, 242, 0.16)',
        borderRadius: '2px',
        display: 'flex',
        marginTop: '15px',
        padding: '8px 16px 8px 16px'
    },
    noteText: {
        fontSize: '14px',
        lineHeight: '20px',
        color: 'rgba(43, 45, 50, 0.8)'
    },

    headerCard: {
        marginTop: '10px',
        width: '100%',
        height: '44px',
        border: '1px solid #D1D5DD',
        borderRadius: '4px 4px 0px 0px',
        padding: '11px 0px 0px 20px'
    },

    bodyCard: {
        width: '100%',
        height: '186px',
        borderLeft: '1px solid #D1D5DD',
        borderRight: '1px solid #D1D5DD',
        borderBottom: '1px solid #D1D5DD',
        borderRadius: '0px 0px 4px 4px',
        padding: '25px 0px 0px 20px'
    },

    uploadPhoto: {
        width: '161px',
        height: '40px',
        borderRadius: '4px',
        border: '1px solid #F5922F',
        color: '#F5922F',
        fontWeight: '600'
    }


}

export default ManualAddMoney