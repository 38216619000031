import axios from 'axios';
import {Handle401} from '../../handle401/handle401';

const authorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const payoutAdvancingServices = {
  getPayoutAdvancingSetting: async (user_id="") => {
    try {

      const res = await authorizedAxiosRequest().get(`/api/admin/payout/advancing/settings/?user_id=${user_id}`)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  }, 
  getCorporateAccounts: async (type, start="", end="", page=1, search="") => {
    try {
      const res = await authorizedAxiosRequest().get(`/api/admin/users/?segment=${type}&page=${page}&page_size=20&search=${search}&start=${start}&end=${end}&level=5`)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },

  updatePayoutAdvancing: async (params) => {
    try {
      const res = await authorizedAxiosRequest().post(`/api/admin/payout/advancing/settings/`, {...params})
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },

  advanceDate: async (user_id, target_date, amount_limit) => {
    try {
      let params = {target_date: target_date, 
                    amount_limit: amount_limit,
                    user_id: user_id};
      const res = await authorizedAxiosRequest().post(`/api/advancing/date/`, params)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },

  advancePayout: async (params) => {
    try {
      const res = await authorizedAxiosRequest().post(`/api/payout/advancing/`, params)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
}


export default payoutAdvancingServices;