import Axios from "axios";

// const returnAxiosInstance = () => {
//     return Axios.create(initializers);
// }

const axiosRequest = (authorized=true) => {
    return Axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: authorized && {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    });
}

export const get = (url, authorized) => {
    const axios = axiosRequest(authorized);
    return axios.get(url);
}

export const post = (url, requesData, authorized) => {
    const axios = axiosRequest(authorized);
    return axios.post(url, requesData);
}

export const remove = (url, requesData, authorized) => {
    const axios = axiosRequest(authorized);
    return axios.delete(url, requesData);
}

export const patch = (url, requesData, authorized) => {
    const axios = axiosRequest(authorized);
    return axios.patch(url, requesData);
}

export const put = (url, requesData, authorized) => {
    const axios = axiosRequest(authorized);
    return axios.put(url, requesData);
}