import React from 'react'
import MobileAppBar from './MobileAppBar'
import {getLatestBalance} from '../Main'
import LogoutTimer from '../../components/timer/logout_timer'
import TermsModal from '../../components/terms_and_privacy/terms_modal'
import MobileFooter from './mobileFooterMenu'
import { connect } from 'react-redux';
import { SET_CHILD_ENDPOINTS } from './mobileAppConstants';
import { MetaTags } from 'react-meta-tags'

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchSetMobileEndpoints: (endpoints) => {
            dispatch({
                'type': SET_CHILD_ENDPOINTS,
                'endpoints': endpoints,
            })
        }
    }
}

class MobileMain extends React.Component{

    componentDidMount(){
        if(localStorage.getItem("jwtToken") != ""){
            this.getBalance()
        }
    }

    async getBalance(){
        let response = await getLatestBalance()
        if(response){
            this.setState({
                hidden: false
            })

            this.props.dispatchSetMobileEndpoints(response.endpoints);
        }
    }

    constructor(props){
        super(props)
    
        this.state = {
          hidden: true
        }
    }

    render(){
        const {children}= this.props;
        const pathname = window.location.pathname;
        let pathname_list = [
            '/mobile/dashboard',
            '/mobile/card',
            '/mobile/orders',
            '/funds_enterprise',
            '/mobile/funds' ,
            '/mobile/payout',
            '/payout_schedule',
            '/mobile/more',
            '/mobile/wallet',
        ]

        return(
            <div hidden={this.state.hidden}>
                <MetaTags>
                    <title>Bux | All-in-One Payment Gateway for Online Sellers, SMEs</title>
                    <meta
                        name="description"
                        content="Bux | All-in-One Payment Gateway for Online Sellers, SMEs"
                    />
                    <meta property="og:description" 
                        content="Send and receive payments through over-the-counter channels, online banking and e-wallets with Bux, the Philippines’ leading payment gateway."/>
                    <meta property="og:image" content="%PUBLIC_URL%/buxcover.jpg" />

                </MetaTags>
                <LogoutTimer/>
                <MobileAppBar title={this.props.title} subMenu={this.props.menu} setTitleBlank={this.props.setTitleBlank} />
                <TermsModal/>
                <div style={{flex:10, marginTop:'.5vh',zIndex: '1', marginTop: '60px', background: "#F4F6F9"}}>
                    {children}
                </div>
                <div hidden={!pathname_list.includes(pathname)}>
                    <MobileFooter/>
                </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(MobileMain);
