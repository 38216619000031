import React from 'react';
import { Dropdown, Icon, Menu, Table, message, Pagination, Typography, DatePicker, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import EndorseModal from './endorseModal';
import endorsementServices from './endorsementServices';
import moment from 'moment';
import ArrowDown from '../../../static/icons/arrow-down.svg';
import ApplyModal from './applyModal';
import crypto from 'crypto';

const AddModalTable = ({selected, selectedTab, onSuccess}) => {
  const [dateRange, setDateRange] = React.useState({startDate: '', endDate: ''});
  const [minPage, setMinPage] = React.useState(0);
  const [maxPage, setMaxPage] = React.useState(20);
  const [loading, setLoading] = React.useState(false);
  const [columns, setColumns] = React.useState(null);
  const [showEndorseModal, setShowEndorseModal] = React.useState(false);
  const [keyToEndorse, setKeyToEndorse] = React.useState(null);
  const user = localStorage.getItem('userType');
  const [tableData, setTableData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(null);
  const [currentPage,setCurrentPage] = React.useState(1);
  const [searchVal, setSearchVal] = React.useState('');
  const accountTypeList = ['Individual', 'Enterprise'];
  const [accountType, setAccountType] = React.useState('All');
  const [applyModal, setApplyModal] = React.useState(false);
  const [page, setPage] = React.useState('1');
  const [requestToken, setRequestToken] = React.useState(null);
  const [reqReturnToken, setReqReturnToken] = React.useState(null);
  const [summary, setSummary] = React.useState('All');
  const [POStatus, setPOStatus] = React.useState(selectedTab);
  const [targetStatus, setTargetStatus] = React.useState(null);
  const [targetBuxFee, setTargetBuxFee] = React.useState(null);
  const [targetChannelFee, setTargetChannelFee] = React.useState(null);
  const [targetDate, setTargetDate] = React.useState(null);
  const [selectedData, setSelectedData] = React.useState(null);

  const PRColumns = [
    {
      title: 'Trans Id',
      dataIndex: 'id',
    },
    {
      title: 'Reference no.',
      render: (record) => <div style={{whiteSpace: 'break-spaces', width: 200}}><Typography>{record.code ? record.code : "Pending"}</Typography><Typography>{record.description}</Typography></div>
    },
    {
      title: 'Business Name',
      dataIndex: 'business_name',
    },
    {
      title: 'Date generated',
      dataIndex: 'timestamp',
    },
    {
      title: 'Expires after',
      dataIndex: 'expire_at',
    },
    {
      title: 'Amount(₱)',
      dataIndex: 'amount',
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      render: text => <Typography style={{width: 100, whiteSpace: 'break-spaces' }}>{text}</Typography>

    },
    {
      title: 'Action',
      render: (record) => (
        // <Dropdown overlay={() => <Menu><Menu.Item><a onClick={()=> handleEndorseRow(record.id, '', record.amount, record.code)}>Endorse</a></Menu.Item></Menu>} placement="bottomCenter" trigger={['click']}>
        //   <Icon type="ellipsis" />
        // </Dropdown>
        <a style={styles.actionLink} onClick={()=> {handleEndorseRow(record.id, '', record.amount, record.code); setSelectedData(record);}}>Endorse</a>
      )
    }
  ]

  const PayoutColumns = [
    {
      title: 'Trans ID',
      dataIndex: 'id',
    },
    {
      title: 'Destination',
      dataIndex: 'account_number',
      render: (text, record) => (
        <div style={{whiteSpace: 'break-spaces', width: 150}}>
          <a>{record.account_number}</a>
          <div>{record.bank}<br/>{record.account_name}</div>
        </div>
      )
    },
    {
      title: 'Payment Date',
      dataIndex: 'date_paid',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
    },
    {
      title: 'Amount (PHP)',
      dataIndex: 'amount',
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      render: text => <Typography style={{width: 100, whiteSpace: 'break-spaces' }}>{text}</Typography>
    },
    {
      title: 'Action',
      render: (record) => (
        // <Dropdown overlay={() => <Menu><Menu.Item><a onClick={()=>handleEndorseRow(record.id, record.status, record.amount, '')}>Endorse</a></Menu.Item></Menu>} placement="bottomCenter" trigger={['click']}>
        //   <Icon type="ellipsis" />
        // </Dropdown>
        <a style={styles.actionLink} onClick={()=> {handleEndorseRow(record.id, record.status, record.amount, record.id); setSelectedData(record);}}>Endorse</a>
      )
    }
  ]

  const success = (msg) => {
    message.success(msg);
  };

  const errorPrompt = (msg) => {
    message.error(msg);
  };


  const returnStatus = (key) => {
    switch (key) {
      case 'Paid':
        return 'PA';
      case 'Pending':
        return 'PE';
      case 'Failed':
        return 'FA';
      case 'Success':
        return 'SU';
      case 'Cancelled':
        return 'CA';
      case 'Expired':
        return 'EX';
      default:
        return key;
    }
  }

  const handleEndorse = async (id) => {
    setLoading(true);
    keyToEndorse['target_status'] = returnStatus(targetStatus);

    if(selected === 'Payout') {
      keyToEndorse['previous_status'] = returnStatus(POStatus);
    } else {
      keyToEndorse['previous_status'] = returnStatus(selectedTab);
      if(targetBuxFee){
        keyToEndorse['bux_fee'] = targetBuxFee
      }
      if(targetChannelFee){
        keyToEndorse['channel_fee'] = targetChannelFee
      }
      if(targetDate){
        keyToEndorse['trans_date'] = targetDate
      }
    }

    try { 
      const res = await endorsementServices.endorse(keyToEndorse);
      if(res.status==='success'){
        success(res.message);
        getData(selected);
        setShowEndorseModal(false);
        onSuccess();
      } else {
        errorPrompt(res.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      errorPrompt(error);
    }
  }

  const handleEndorseRow = (id, status, amount, ref_no) => {
    setKeyToEndorse({payment_id: id, transaction_type: selected === 'Payout'? 'PO' : 'PR', amount: selected === 'Payout'? amount.substring(3).replace(/,/g, '') : amount, ref_no: ref_no});
    if(selected === 'Payout') {
      setPOStatus(status);
    }
    setShowEndorseModal(true);
  }

  const confirmEndorse = () => {
    handleEndorse(keyToEndorse);
  }

  const generateToken = () => {
    let generatedRequestToken = crypto.randomBytes(16).toString('hex');
    setRequestToken(generatedRequestToken);
    return generatedRequestToken
  }

  const getData = async (selected, page) => {
    setIsLoading(true);
    try {
      let res;
      if(selected === 'Payment Request') {
        res = await endorsementServices.getOrders(searchVal, page? page : 1, selectedTab, '', accountType, dateRange.startDate, dateRange.endDate, summary, generateToken());
      } else {
        res = await endorsementServices.getPayout(searchVal, page? page : 1, selectedTab, accountType, dateRange.startDate, dateRange.endDate, summary);
      }
      if(res.results) {
        setTableData(res.results);
        setTotalCount(res.total);
      } else if(res.orders) {
        setTableData(res.orders);
        setTotalCount(res.summary.total_data);
        setReqReturnToken(res.token);
      }
       else {
        errorPrompt(res);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      errorPrompt('Please try again');
    }
  }

  const handleChangePage = async (page) => {
    getData(selected, page);
  }

  const changePage = (page, pageSize) => {
    setMaxPage(page * pageSize)
    setMinPage((page - 1) * pageSize)
    setCurrentPage(page);
    handleChangePage(page);
  }

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }

  const rangeSelect = (dates, dateString, id) => {
    setDateRange({...dateRange, [id]: moment(dateString).format('MM-DD-YYYY')});
    setSummary('Custom');
    // if(id === 'startDate') {
    //   getData(selected, moment(dateString).format('MM-DD-YYYY'), dateRange.endDate)
    // } else {
    //   getData(selected, dateRange.endDate, moment(dateString).format('MM-DD-YYYY'));
    // }
  };

  const handleSearch = (e) => {
    setCurrentPage(1);
    setSearchVal(e.target.value);
  }

  const handleSelect = (e) => {
    setAccountType(e.target.value);
  }

  const applyFilter = () => {
    getData(selected);
  }

  const resetProps = () => {
    // setDateSearch(false);
    setDateRange({startDate: '', endDate: ''});
    setSummary('All');
    setAccountType('All');
    setSearchVal('');
  }

  React.useEffect(() => {
    if(selected && selectedTab){
      getData(selected);
    }
    resetProps();
    setColumns([]);
    setTimeout(()=> {
      setColumns(selected==='Payment Request'?PRColumns : PayoutColumns);
    },100)
  },[selected])

  React.useEffect(() => {
    if(selected || selectedTab){
      getData(selected);
    }
    resetProps();
  }, [selectedTab])
  
  return (
    <div>
      <div style={styles.dateBtn}>
        <div style={{...styles.inputDiv, width: 210}}>
          <select
            // value={params['natureOfBusiness']}
            name='transactionType'
            onChange={handleSelect}
            style={{...styles.selectStyle, color: accountType != '' ? '#000' : '#D1D5DD'}}>
                <option value="All" selected={accountType==='All'}>All</option>
                <option value="ME" selected={accountType==='ME'}>Individual</option>
                <option value="CO" selected={accountType==='CO'}>Enterprise</option>
                {/* {
                  accountTypeList.map((item,i)=>{
                    return(
                    <option key={i} value={i === 1 ? 'ME' : i === 2 ? 'CO' : 'All'} selected={accountType===(i===1? 'ME' : 'CO')} style={{color: '#000'}}>{item}</option>
                    )
                  })
                } */}
          </select>
        </div>
        <DatePicker 
          format='MM/DD/YYYY' 
          disabledDate={disabledDate} 
          // defaultValue={moment()}
          value={dateRange.startDate && moment(dateRange.startDate)} 
          size="large" picker="month" 
          onChange={ (dates, dateString) => rangeSelect(dates, dateString, 'startDate')} 
          placeholder='Start date' 
          style={styles.datePic} 
          suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{fontSize: 22}} /></div>}
          allowClear={false}/>
          <span style={styles.span}>-</span>
        <DatePicker 
          format='MM/DD/YYYY' 
          disabledDate={disabledDate} 
          // defaultValue={moment()}
          value={dateRange.endDate && moment(dateRange.endDate)} 
          size="large" 
          picker="month" 
          onChange={ (dates, dateString) => rangeSelect(dates, dateString, 'endDate')} 
          placeholder='End date' 
          style={styles.datePic}
          suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{fontSize: 22}} /></div>}
          allowClear={false}/>
        <Input
          placeholder="Search"
          style={{ width: '200px', height: '40px', borderRadius:'4px' }}
          suffix={<Icon type="search" style={{fontSize:'18px'}}/>}
          onChange={handleSearch}
          value={searchVal}
          />
        <Button style={styles.applyBtn} onClick={() => setApplyModal(true)}>Apply</Button>
      </div>
      <Table 
        rowClassName="table-row-light"
        columns={columns}
        dataSource={tableData ? tableData : []}
        pagination={{pageSize:10}}
        loading={isLoading || loading}
        pagination={false}
      />
      <Pagination
        size='small'
        total={totalCount}
        defaultPageSize={10}
        defaultCurrent={1}
        current={currentPage}
        onChange={(page, pageSize) => changePage(page, pageSize)}
        style={{padding:'12px 0 8px 0', textAlign: 'right'}}
      />
      <EndorseModal visible={showEndorseModal} closeModal={()=> setShowEndorseModal(false)} onEndorse={confirmEndorse} POStatus={POStatus} PRStatus={selectedTab} selected={selected} setTargetStatus={(newStatus) => setTargetStatus(newStatus)}  setTargetDate={(transDate) => setTargetDate(transDate)}  setTargetBuxFee={(buxFee) => setTargetBuxFee(buxFee)}  setTargetChannelFee={(channelFee) => setTargetChannelFee(channelFee)} data={selectedData}/>
      <ApplyModal visible={applyModal} closeModal={() => setApplyModal(false)} onApply={applyFilter} />
    </div>
  ) 
}

const styles = {
  dateBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 0 20px 0',
  },
  datePic: {
    height: 40,
    fontSize: 14,
    color: '#2F3542',
    width: 178,
    padding: '0 8px 0 8px'
  },
  suffixIcon: {
    marginTop: '-12px', 
    marginRight: 12, 
    color: '#000'
  },
  inputDiv: {
    width: 240,
    padding: '0 8px',
  },
  label: {
    fontSize: 12,
    color: '#212B36',
    padding: '4px 0'
  },
  selectStyle: {
    width: '100%',
    height: '40px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    appearance: 'none',
    background: `url(${ArrowDown})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '95% 50%',
    fontSize: '14px',
    borderColor: '#D1D5DD',
    borderRadius: 4,
    padding: '10px 40px 10px 10px',
  },
  applyBtn: {
    height: 40,
    width: 94,
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 4,
    color: '#FFF', 
    backgroundColor: '#F5922F', 
    borderColor: '#F5922F',
    margin: '0 8px',
  },
  actionLink: {
    color: '#F5922F',
    fontSize: 14,
    textDecoration: 'underline'
  }
}

export default AddModalTable;