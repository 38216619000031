import React from 'react';
import {Button, Input} from 'antd';
import {history} from '../../store/history'

class VerifyNumber extends React.Component{

    verifyStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '34px',
        color: '#2B2D33',
        marginBottom: '10px'
    }

    textStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.01em',
        color: '#2B2D33',
        opacity: '0.8',
        marginBottom: '20px'
    }

    submitBtnStyle = {
        width: '100%',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        height: '50px',
        marginTop: '20px',
        backgroundColor: '#E6EAF0',

    }

    dontHaveStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#2B2D33',
        display: 'flex',
        marginTop: '20px',
    }

    render() {
        return(
            <div style={{display:'flex', justifyContent: 'center'}}>
                <div style={{paddingLeft: '57px',paddingRight: '57px', paddingTop: '10vh', justifyContent: 'center', alignItems:'center', width: '559px'}}>
                    <div style={this.verifyStyle}>Verify Account</div>
                    <div style={this.textStyle}>You will receive a One-Time Password (OTP) on your registered mobile number ending in 7228.</div>
                    <Input style={{height: "50px", width: '100%'}}/>
                    <Button style={this.submitBtnStyle} onClick = {() => window.location.href = '/verify_email'} >Submit</Button>
                    <div style={{display:'flex', justifyContent: 'center'}}>
                        <pre style={this.dontHaveStyle}>Don’t have an account yet? <span onClick = {() => window.location.href = '/verify_email'}>Sign up</span></pre>
                    </div>
                </div>
            </div>
        )
    }
}

export default VerifyNumber