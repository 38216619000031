import React from 'react';
import { Card, Typography, Switch, Divider, Input, Icon, DatePicker, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import AdvanceEnableModal from './enable_advance_modal';
import payoutAdvancingServices from '../payoutAdvancingServices';

const AdvancePH = (props) => {
    const [advanceOn, setAdvanceOn] = React.useState(false);
    const [maxAmount, setMaxAmount] = React.useState(0);
    const [percent, setPercent] = React.useState(0);
    const [settingsParams, setSettingsParams] = React.useState({ fee: 0, payout_advancing_enable: false, max_amount: 0, min_amount: false });
    const [editField, setEditField] = React.useState(false);
    const [advancingParams, setAdvancingParams] = React.useState({  date: '',   remarks: '' });
    const segments = localStorage.getItem('segments');
    const [advanceEnableModal, setAdvanceEnableModal] = React.useState(false);
    const { uid } = props;
    const [noSetup, setNoSetup] = React.useState(true);
    const [merchant, setMerchant] = React.useState('');

    const autoFormat = (value) => value == null ? null : value.replace(/[^\d.]/g, '');

    const onChange = (e) => {
        e.preventDefault();
        
        const { name } = e.target;
        if (name === "max_amount" || name === "min_amount" || name === "fee") {
            let splitted_val = e.target.value.split(".")
            if(splitted_val.length == 1 || (splitted_val.length == 2 && splitted_val[1].length <= 2)){
                setSettingsParams({
                    ...settingsParams, [e.target.name]: autoFormat(e.target.value)
                })
            }
        } else {
            setSettingsParams({
                ...settingsParams, [e.target.name]: e.target.value
            })
        }
        if(e.target.name === "max_amount") {
            setMaxAmount(e.target.value);
        }
    }

    const AdvanceSettings = () => {
        return  (
                <Card
                    style={{ padding: 0, width: '48%' }}
                    bodyStyle={{}}
                >
                    <div style={styles.flexBetween}>
                        <div>
                            <Typography style={styles.onText}>Advance PH is {advanceOn ? 'ON' : 'OFF'}</Typography>
                            <Typography style={styles.normalText}>BUx advancing is BUx advance settlement payout.</Typography>
                        </div>
                        <Switch size='small' checked={settingsParams.payout_advancing_enable} 
                            // onClick={() => setAdvanceOn(!advanceOn)}
                                onClick={() => {noSetup? setAdvanceEnableModal(true) : EnableAdvancing("toggle")}} />
                    </div>
                    <Divider />
                    <div style={styles.flexBetween}>
                        <div>
                            <Typography style={styles.onText}>Max Amount</Typography>
                            <Typography hidden={editField} style={styles.normalText}>₱ { noSetup? '--' : (parseFloat(settingsParams.max_amount)).toFixed(2)}</Typography>
                            <Input hidden={!editField}

                                style={{ ...styles.input, display: !editField && 'none' }} size='large' name="max_amount" value={settingsParams.max_amount}
                                onChange={onChange} />
                        </div>
                        <button hidden={ noSetup || !settingsParams.payout_advancing_enable || editField} className={`outline-btn--${segments}`} style={{ ...styles.copyBtn, marginRight: 4 }} onClick={() => setEditField(true)}>
                            <Icon style={{ paddingRight: 4 }} theme='filled' type={'edit'} />{'Edit'}
                        </button>
                    </div>
                    <div>
                        <Typography style={styles.onText}>Fees (%)</Typography>
                        <Typography hidden={editField} style={styles.normalText}>{noSetup? '--' : settingsParams.fee + '%'}</Typography>
                        <Input hidden={!editField} style={styles.input} size='large' name='fee' value={settingsParams.fee} onChange={onChange} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} hidden={!editField}>
                        <button className={`outline-btn--${segments}`} style={{ ...styles.copyBtn, marginRight: 4 }} onClick={() => setEditField(false)}>
                            Cancel
                        </button>
                        <button className={`btn--${segments}`} style={{ ...styles.editBtn, width: 76 }}
                            onClick={() => EnableAdvancing("update")}
                        >Save</button>
                    </div>
                </Card>
                )
        }

    const getPayoutSettings = async () => {

        try {
            const res = await payoutAdvancingServices.getPayoutAdvancingSetting(uid);

            console.log('PAYOUT_ADVANCE_SETTING', res);
            if(res.status === 'success') {
                if(res && res.data) {
                    setSettingsParams(res.data)
                    setNoSetup(res.data.not_setup === 0? false : true);
                    setMerchant(res.data.business_name);
                }
            } else {
                message.warn('Error try again');
            }

        } catch (error) {
            console.log(error);
            message.warn('Error try again');
        }
    }

    React.useEffect(() => {
        getPayoutSettings();
    },[])

    const AdvanceTransactions = () => {

        return (
            <Card
                style={{ padding: 0, width: '48%' }}
                bodyStyle={{}}
            >
                <div style={styles.flexBetween}>
                    <div>
                        <Typography style={styles.onText}>BUx Advancing transaction</Typography>
                        <Typography style={styles.normalText}>Select specific date for BUx Advancing transaction.</Typography>
                    </div>
                </div>
                <div style={styles.flexRow}>
                    <Typography style={{ color: 'rgba(43, 45, 50, 0.5)'}} >Select date</Typography>
                    <DatePicker 
                    size='large'
                        style={{width: '100%'}}
                        placeholder='Select date'
                        value={advancingParams && advancingParams.date}
                    />
                </div>
                <div style={{...styles.flexRow, margin: '16px 0 16px 0'}}>
                    <Typography style={{ color: 'rgba(43, 45, 50, 0.5)'}}>Remarks</Typography>
                    <TextArea 
                        rows={2}
                        placeholder='Remarks'
                        name='remarks'
                        value={advancingParams && advancingParams.remarks}
                        onChange={(e) => setAdvancingParams({...advancingParams, [e.target.name]: e.target.value})}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <button className={`btn--${segments}`} style={{ ...styles.editBtn, width: 76 }}
                    >Save</button>
                </div>
            </Card>
        )
    }

    const EnableAdvancing = async (type) => {
        const enablePayout = type === "enable"? true : type === "update" ? settingsParams.payout_advancing_enable : !settingsParams.payout_advancing_enable;
        const params = {
            max_amount: settingsParams.max_amount,
            min_amount: 0,
            fee: settingsParams.fee,
            user_id: uid,
            enable_advancing: enablePayout,
        }

        try {
            const res = await payoutAdvancingServices.updatePayoutAdvancing(params)

            if(res && res.status === "success") {
                getPayoutSettings();
                if(type === "update") {
                    setEditField(false);
                }
                setAdvanceEnableModal(false);
            } else {
                if(res && res.message){
                    message.error(res.message);
                } else {
                    message.warn('Error try again');
                }
            }

        } catch (error) {
            console.error(error)
            message.warn('Error try again');
        }
    }

    return (
        <div style={styles.root}>
            <Card
                style={{ padding: 0, width: '48%' }}
                bodyStyle={{}}
            >
                <div style={styles.flexBetween}>
                    <div>
                        <Typography style={styles.onText}>Advance PH is {settingsParams && settingsParams.payout_advancing_enable ? 'ON' : 'OFF'}</Typography>
                        <Typography style={styles.normalText}>BUx advancing is BUx advance settlement payout.</Typography>
                    </div>
                    <Switch size='small' checked={settingsParams.payout_advancing_enable} 
                        // onClick={() => setAdvanceOn(!advanceOn)}
                            onClick={() => {noSetup? setAdvanceEnableModal(true) : EnableAdvancing("toggle")}} />
                </div>
                <Divider />
                <div style={styles.flexBetween}>
                    <div style={{width: '100%'}}>
                        <Typography style={styles.onText}>Max Amount</Typography>
                        <Typography hidden={editField} style={styles.normalText}>₱ { noSetup? '--' : (parseFloat(settingsParams.max_amount)).toLocaleString()}</Typography>
                        <Input hidden={!editField}
                            addonBefore="₱"
                            style={{ ...styles.input, display: !editField && 'none' }} size='large' name="max_amount" value={settingsParams.max_amount}
                            onChange={onChange} />
                    </div>
                    <button hidden={ noSetup || !settingsParams.payout_advancing_enable || editField} className={`outline-btn--${segments}`} style={{ ...styles.copyBtn, marginRight: 4 }} onClick={() => setEditField(true)}>
                        <Icon style={{ paddingRight: 4 }} theme='filled' type={'edit'} />{'Edit'}
                    </button>
                </div>
                <div>
                    <Typography style={styles.onText}>Fees (%)</Typography>
                    <Typography hidden={editField} style={styles.normalText}>{noSetup? '--' : settingsParams.fee + '%'}</Typography>
                    <Input hidden={!editField} style={styles.input} size='large' name='fee' value={settingsParams.fee} onChange={onChange} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} hidden={!editField}>
                    <button className={`outline-btn--${segments}`} style={{ ...styles.copyBtn, marginRight: 4 }} onClick={() => {setEditField(false); getPayoutSettings()}}>
                        Cancel
                    </button>
                    <button className={`btn--${segments}`} style={{ ...styles.saveBtn, width: 76 }}
                        onClick={() => EnableAdvancing("update")}
                    >Save</button>
                </div>
            </Card>
            <AdvanceTransactions />
            <AdvanceEnableModal 
                visible={advanceEnableModal} onClose={() => setAdvanceEnableModal(false)}  merchantName={merchant}
                onChange={onChange} settingsParams={settingsParams} onSave={() => EnableAdvancing('enable')} />
        </div>
    )
}

const styles = {
    root: {
        width: '1200px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        margin: '32px 0'
    },
    settingRoot: {
        width: '50%'
    },
    onText: {
        color: '#2B2D32',
        fontWeight: '700',
        fontSize: '18px'
    },
    normalText: {
        color: '#2B2D32',
        fontWeight: '400',
        fontSize: '16px'
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '24px 0'
    },
    copyBtn: {
        cursor: 'pointer',
        height: 34,
        padding: '0 12px',
        margin: '12px 8px 6px 8px',
        width: '82px'
    },
    input: {
        width: '100%',
        margin: '8px 0 12px 0'
    },
    flexRow: {
        display: 'flex', 
        alignItems: 'flex-start', 
        flexDirection: 'column', 
        margin: '8px 0'
    },
    saveBtn: {
        height: '34px',
        margin: '12px 0px 6px 4px'
    }
}

export default AdvancePH;