import React from "react";
import {history } from '../../store/history'
import { Layout, Button, Carousel} from "antd";
import { Grid, useMediaQuery} from "@material-ui/core";
import bux from "../../static/icons/bux_logo.svg";
// import Bux from "../../../public/bux_logo.svg";e
import shipment from "../../static/images/bux_gif.svg";
import s3_r1 from "../../static/images/s3_r1.svg";
import s3_r2 from "../../static/images/s3_r2.svg";
import s3_r3 from "../../static/images/s3_r3.svg";
import s3_r4 from "../../static/images/s3_r4.svg";
import s3_r5 from "../../static/images/s3_r5.svg";
import s3_r6 from "../../static/images/s3_r6.svg";
import s3_r7 from "../../static/images/s3_r7.svg";
import {HeaderImage, RightImage,HappyLaptop,Messenger,Performance, Access,
        RightEclipse, PricingImage,OTC,Banking, Credit, EWallet, Anna,
        Jonathan, James,Quote, Stores} from "../../static/images/home/index"
import homeWeb from "../../static/css/Home";
import MetaTags from "react-meta-tags";
import ScrollAnimation from 'react-animate-on-scroll'
import Footer from './footer'
import AOS from 'aos'
import 'aos/dist/aos.css'


const { Header } = Layout;

const HomeWeb = props => {

  AOS.init();

  const dellLaptopReso = useMediaQuery('(max-width: 1440px)')
  const macLaptopReso = useMediaQuery('(max-width: 1920px)')

  const isHighReso = window.screen.width * window.devicePixelRatio == 1440 && window.screen.height * window.devicePixelRatio >= 900;
  const isHighDef = window.screen.width * window.devicePixelRatio > 1440 && window.screen.height * window.devicePixelRatio > 900;

  const jumboTron = [
    {
      icon: HappyLaptop,
      header: "CREATE AN ACCOUNT FOR free",
      text: <div>Sign up through our chatbot or the web in just seconds.</div>
    },
    {
      icon: Messenger,
      header: "SEND PAYMENT LINKS",
      text:
        "Generate payment links as you chat with your buyers."
    },
    {
      icon: Performance,
      header: "Accept payments in multiple ways",
      text:
        "Your customers can pay at their convenience through our extensive payment network."
    },
    {
      icon: Access,
      header: "Instant access to your money",
      text:
        "Instantly transfer funds virtually from your BUx wallet to any bank account in the Philippines."
    }
  ];

  const carousel = [
    {
      comment: <div>
               Depositing money to banks, and accepting payments from our clients is a challenge to us. 
               With BUx we are able to convert inquiries into sales with its web-banking and numerous over-the-counter payment locations. 
               We’re super excited to see our business growing, especially with Bux.
              </div>,
      by: "John & Brigitte Pineda",
      job: "J&A Tickets and Tours",
      dp: Anna
    },
    {
      comment: <div>
                Integrating BUx with our Chatbot service, Chatbuddy, enabled our partner retailers to accept bank transfers, over-the-counter payments to pawnshops and remittance centers, and soon mobile wallets. <br/><br/>
                This partnership will definitely help SMEs in transitioning to digital payments especially during this time of pandemic.
              </div>,
      by: "Dennis San Juan",
      job: "President, Load One Telecomms, Inc",
      dp: Jonathan
    },
    {
      comment: <div>
                With the long queues and all the processes when depositing funds, it was inconvenient for our customers to pay for our goods. 
                But with BUx accepting payments has never been this easy. Generate, send, and wait for your customers to pay with their desired payment method, just like that!
              </div>,
      by: "Jannine Bernardo",
      job: "Corazon’s Kitchenette",
      dp: James

    },
  ]

  const pricingInfo = [
    {
      icon: OTC,
      header: 'Over-the-counter',
      info: '7-11, Bayad Center, Cebuana, LBC, SM and more',
      bg: '#ffeac6',
      pricing: 'PHP 20.00',
      padding: '31px 18px',

    },
    {
      icon: Banking,
      header: 'Online Banking',
      info: 'BPI, UnionBank and RCBC',
      bg: '#73f4c4',
      pricing: 'PHP 15.00',
      padding: '31px 18px',

    },
    {
      icon: Credit,
      header: 'Credit / Debit Card',
      info: '*Additional 1% International fee for cards issued outside the Philippines',
      bg: '#c1ccdb',
      pricing: <div><span style={{fontSize: '14px', fontWeight: 800}}>+ 3.5%</span>
                    <br/><span style={{fontSize: '14px', fontWeight: 800}}>PHP 12</span></div>,
      padding: '24px',
    },
    {
      icon: EWallet,
      header: 'E-Wallet',
      info: 'GCash, GrabPay',
      bg: '#afdefb',
      pricing: <div><span style={{fontSize: '14px', fontWeight: 800}}>+ 2%</span>
                    <br/><span style={{fontSize: '14px', fontWeight: 800}}>PHP 10</span></div>,
      padding: '24px',
    },
  ]

  const bodyInfo = [
    {
      image: s3_r1,
      header: "Accept multiple payment methods",
      text: `
      With BUx the days of dealing with multiple payment companies are over. Accept a wide range of payment methods in one solution from over-the-counter, online banking, to E-wallets, and more coming soon!
      `
    },
    {
      image: s3_r2,
      header: "Get paid easily with payment links",
      text: `
      Create payment links in seconds and instantly share with your customers via Facebook Messenger, Email, SMS, Viber, Whatsapp and more!
      `
    },
    {
      image: s3_r3,
      header: "Your own Universal My Checkout that is Digital, Printable and Customizable",
      text: `
      Have your very own universal My Checkout. Accept payments by printing or sharing your QR for your customers to scan and pay whether it be online or offline leading to your very own checkout page.
      `
    },
    {
      image: s3_r4,
      header: "Access to your own dedicated checkout page",
      text: `
      Share your very own customizable and universally accessible checkout page URL which you can use with or without a website.
      `
    },
    {
      image: s3_r5,
      header: "Monitor all payments in one place",
      text: `
      Tracking the payment status of your customers from multiple payment platforms can be tricky. Using BUx you can do this from one single view, so you can focus on running your business.
      `
    },
    {
      image: s3_r6,
      header: "Easy Integration",
      text: 
      <div>
        <div>
        BUx has APIs and ready made plugins for some of the most popular e-commerce platforms. Accept BUx payments immediately as you sell on <b><a href="https://sentro.ph" style={{color: '#F5922F'}}>Sentro.ph</a></b>, <b>Woocommerce, PrestaShop</b> and <b>Shopify</b>.
        </div>

        <div style={{marginTop: 32}}><img src={Stores} /></div>
      </div>
    },
    {
      image: s3_r7,
      header: "Do more with a BUx E-Money Account",
      text: `
      With the embedded BUx E-Money Account, you can instantly transfer funds from your BUx wallet to your Virtual BUx Visa Card and shop online. You can also upgrade to an EON Visa Card to transact anywhere where Visa is accepted and withdraw at any BancNet-affiliated ATM.
      `
    },
  ];


  const metaInfo = [
    {
      header: "BUx | Online Payment Gateway Built for Merchants",
      text: "BUx is the best payment gateway for online sellers. Sell products online, generate payment codes via Messenger app, and transfer funds to your bank."},
    {
      header: "BUx | The Best Online Payment App in the Philippines",
      text: "BUx allows online merchants to manage payments with ease. Generate your payment codes directly through the Messenger app and receive payments instantly."},
    {
      header: "BUx | Secure Online Payment Platform for Sellers",
      text: "BUx is the preferred payment plarform for online merchants of all sizes. Create a free account, accept payments in an instant, and get real-time access to your funds."}
  ];

  const home = homeWeb();
  const metaSelect = Math.floor(Math.random() * Math.floor(3));

  return (
      <div style={{overflow: 'hidden'}}>
         <MetaTags>
          <title>{metaInfo[metaSelect].header}</title>
          <meta
            name="description"
            content={metaInfo[metaSelect].text}/>
          <meta property="og:title" content={metaInfo[metaSelect].header} />
          <meta property="og:description" content={metaInfo[metaSelect].text}/>
          <meta property="og:image" content="https://bux-api-prd-storage.s3-ap-southeast-1.amazonaws.com/static/images/buxcover.jpg" />
        </MetaTags>
         <Header
          style={{
            height: "60px",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#fff",
            boxShadow: "0px 3px 5px rgba(57,63,72,0.1)",
            width: '100%',
            position: 'fixed',
            zIndex: 1,
            paddingLeft: '144px',
            paddingRight: '144px',
          }}
        >
          <img src={bux} alt="#" style={{maxWidth: 80}} />

          {
            localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null ?
            <div>
            <Button className={home.signUp} onClick={ () => window.location.href = "/signup"}>Sign up</Button>
            <Button className={home.login} onClick={ () => window.location.href = "/login"}>Log in</Button>
            </div>
            :

            <div>
            <Button className={home.dashboard} onClick={ () => window.location.href = "/dashboard"}>Dashboard</Button>
            </div>
          }
          
        </Header>

        <div className={home.spacer}>
        </div>

        <section className={home.mainBody}>
        <div className={home.header}>
            <div className={home.cont} style={{paddingTop: dellLaptopReso ? '120px' : macLaptopReso ? '204px' : ''}}>
              <div className={home.headerText} style={{fontSize: dellLaptopReso ? '36px' : macLaptopReso ? '40px' : '16px'}}>
                  BETTER PAYMENT EXPERIENCE 
              </div>
              <div className={home.subHeaderText}>
                for you and your customers
              </div>

              <div className={dellLaptopReso ? home.pText1 : home.pText}>
                <p>
                Accept multiple forms of payment and have instant access to your funds.
                </p>
              </div>

              <Button className={home.signUp2} onClick={ () => window.location.href = "/signup"}>Sign up</Button>

            </div>

            <div className={home.rightImage} style={{marginTop: isHighDef ? '52px' : isHighReso ? '-64px' : '-105px'}} align="right">
              <img src={RightImage} className={home.rightLaptop} />
            </div>

        </div>
      </section>
      
      <div className={home.jumboTron}>
          <p className={home.jtHeader}>It's easy to get started</p>

          <Grid container>
            {jumboTron.map((item, index) => {
              return (
                <Grid key={index} item lg={3}>
                  <div className={home.grid}>
                    <div className={home.jtgIcon}>
                      <img src={item["icon"]} className={home.jtgIcon} />
                    </div>
                    <div className={home.jtgHeader} style={{fontSize: dellLaptopReso ? 14 : ''}}>{item["header"]}</div>
                    <div className={home.jtgText} style={{fontSize: dellLaptopReso ? 14 : ''}}>{item["text"]}</div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>

        <div className={home.carouselDiv}>
          <div className={home.bHeader}>What our users say about us</div>
          <div style={{marginTop:'48px', display: 'flex', justifyContent: 'center'}}>
            {
              carousel.map((item, index) => {
                return(
                  <div key={index} className={home.testimonialsCard} 
                    style={{
                      marginTop: index %2 == 0 ? '16px' : '', 
                      width: index %2 == 0 ? '370px' : '370px', 
                      height: '100%'}}>
                    <div><img src={Quote} /></div>
                    <div className={home.comment}>{item.comment}</div>
                    <div className={home.testiDetails}>
                        <div style={{display:'flex'}}> <img src={item.dp} /> </div>
                        <div style={{marginLeft: '12px'}}> 
                            <div className={home.by}>{item.by} </div> 
                            <div> {item.job} </div> 
                        </div>
                    </div>
                  </div>
                )
              })
            }
     
          </div>
        </div>


        <div className={home.pricingDiv}>
          <div align="center">
            <div className={home.sideIcon}>
              <img src={RightEclipse} />
            </div>
            <div className={home.bHeader1}>Our Pricing</div>
              <div className={dellLaptopReso ? home.bText1 : home.bText}>
                We offer the lowest transaction fees in the market.
              </div>


              <div style={{marginTop: 40, width: isHighDef ? '60%' : '75%'}}>
                <Grid container>
                {
                 pricingInfo.map((item, index) => {
                   return(
                    <Grid key={index} item lg={6}>
                      <div className={home.pricingCard} style={{marginRight: 16}}>
                        <div style={{display: 'flex', padding: '53px 37px 38px 33px', width: '65%'}}>
                          <div style={{display: 'flex'}}>
                            <img src={item.icon} />
                          </div>
                          <div style={{marginLeft: '20px'}}>
                            <div className={home.pricingHeader}>{item.header}</div>
                            <div className={home.pricingText}>{item.info}</div>
                          </div>
                        </div>

                        <div style={{padding: '20px 28px 20px 0px', width: '35%'}}>
                          <div style={{background: item.bg, borderRadius: `1px solid ${item.bg}` , padding: `${item.padding}`}}
                              className={home.priceDiv}>
                              {item.pricing}
                          </div>
                          <div className={home.perTrans}>
                            per transaction
                          </div>
                        </div>
                      </div>
                    </Grid>
                   )
                 })
               }
                </Grid>
              </div>

              <div style={{marginTop: 64}}>
                <div className={home.smallHeader}>
                  Pricing can be adjusted based on the volume of your transactions
                </div>

                <div style={{marginTop: 32}}>
                  <button className={home.specialRates}>
                    <a style={{color: '#fff'}} 
                    href="mailto:support@bux.ph?subject=Request for Special Rates">
                      Request for Special Rates
                    </a>
                  </button>
                </div>
              </div>
          </div>
        </div>

        <div className={home.b}>
          <p className={home.bHeader}>We help merchants of all sizes</p>

          {bodyInfo.map((item, index) => {
            return (
              <div key={index}>
                {index % 2 == 0 ? (
                  <div className={home.merchantSize}>
                    <div data-aos="fade-right">
                      <div style={{width: dellLaptopReso ? '100%' : macLaptopReso ? '' : ''}}>
                        <img src={item["image"]} className={home.imageSize} />
                      </div>
                    </div>

                    <div>
                      <div className={home.bPadding} style={{padding:  dellLaptopReso ? '180px 64px 180px 64px' : macLaptopReso ? '180px' : '', 
                        marginTop: index == 0 ? '120px' : ''}}>
                        <div className={dellLaptopReso ? home.bHead1 : home.bHead}>{item["header"]}</div>
                        <div className={dellLaptopReso ? home.bText1 : home.bText}>{item["text"]}</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={home.merchantSize}>
                    <div style={{marginTop: index === 3 ? '128px' : ''}}>
                      <div className={home.bPadding} style={{padding:  dellLaptopReso ? '180px 64px 180px 64px' : macLaptopReso ? '180px' : ''}}>
                        <div className={dellLaptopReso ? home.bHead1 : home.bHead}>{item["header"]}</div>
                        <div className={dellLaptopReso ? home.bText1 : home.bText}>{item["text"]}</div>
                      </div>
                    </div>

                    <div data-aos="fade-left">
                      <div className={home.bImage} style={{width: dellLaptopReso ? '100%' : macLaptopReso ? '' : ''}} align="right">
                        <img src={item["image"]} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className={home.preFooter}>
            <div className={home.preFooterDiv}>
              <div className={home.preFooterText}>Let’s get you started!</div>
              <button className={home.preFooterBtn} onClick={ () => window.location.href = "/signup"}>Sign up now</button>
            </div>
        </div>

        <Footer />
  

      </div>

  );
};

export default HomeWeb;
