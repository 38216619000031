import React from 'react';
import FeeStyles from './style';
import CustomInput from './customInput';
import feesServices from '../feesServices';
import { message, Typography, Button, Modal, Icon } from 'antd';

const TransferMoney = (props) => {
    const fee = FeeStyles();
    const [editMode, setEditMode] = React.useState(false);
    const { uid } = props;
    const [tableData, setTableData] = React.useState([]);
    const [confirmModal, setConfirmModal] = React.useState(false);

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...tableData];
        list[index][name] = value;
        setTableData(list);
    }

    const getFeeDetails = async () => {
        try {
            const res = await feesServices.getUserTransferFee(uid);
            if (res) {
                setTableData(res);
            }
            else {
                message.error('Error fetching transfer fees');
            }
        } catch (error) {
            message.error('Error fetching transfer fees');
        }
    }

    const onConfirm = async () => {

        try {
            const res = await feesServices.updateTransferFee(uid, { fees: tableData });
            if (res && res.status === 'success') {
                getFeeDetails();
                message.success(res.message);
                setEditMode(false);
                setConfirmModal(false);
            }
            else {
                message.error('Error updating transfer fees');
            }

        } catch (error) {
            message.error('Error updating transfer fees');
        }
    }

    const params = () => {
        let newParams = [];
        tableData && tableData.map((item, i) => {
            if (item.type === 'sub_to_master') {
                newParams['stm_fee'] = item.fee;
                newParams['stm_minimum'] = item.minimum;
                newParams['stm_maximum'] = item.maximum;
            } else if (item.type === 'master_to_sub') {
                newParams['mts_fee'] = item.fee;
                newParams['mts_minimum'] = item.minimum;
                newParams['mts_maximum'] = item.maximum;
            } else if (item.type === 'sub_to_sub') {
                newParams['sts_fee'] = item.fee;
                newParams['sts_minimum'] = item.minimum;
                newParams['sts_maximum'] = item.maximum;
            }
            newParams['percent_fee'] = item.percent_fee;
        })
        return newParams;
    }

    const onSave = () => {
        setConfirmModal(true);
    }

    const ConfirmModal = (props) => {
        return (
            <Modal
                maskClosable
                onCancel={() => setConfirmModal(false)}
                visible={confirmModal}
                footer={false}
                closeIcon={<Icon type="close" style={{ color: '#F5922F' }} />}
                width={360}
                destroyOnClose
            >
                <Typography style={styles.title}>Update transfer fees?</Typography>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Button onClick={() => setConfirmModal(false)} className={fee.cancelBtn} style={{width: '45%'}} >Cancel</Button>
                    <Button style={{ ...styles.btn }} onClick={() => onConfirm()} >Send</Button>
                </div>
            </Modal>
        )
    }

    const renderMode = (mode) => {
        switch (mode) {
            case 'STM':
                return 'Sub to Master';
            case 'MTS':
                return 'Master to Sub';
            case 'STS':
                return 'Sub to Sub';
            default:
                return 'Sub to Master'
        }
    }

    React.useEffect(() => {
        getFeeDetails();
    }, [])

    return (
        <div>
            <div>
                <div style={styles.header}>
                    <div>
                        {/* <img alt='No Image' /> */}
                        <Typography className={fee.channelHeader}>Multiwallet</Typography>
                    </div>
                    <div>

                    </div>
                </div>
                <div style={styles.editDiv}>
                    <div hidden={!editMode}>
                        <button className={fee.cancelBtn} style={{ marginRight: 10 }} onClick={() => setEditMode(!editMode)}>Cancel</button>
                        <button className={fee.editBtn} onClick={onSave}>Save</button>
                    </div>
                    <div hidden={editMode}>
                        <Button type='link' style={{ fontSize: 16, color: '#F5922F', fontWeight: 'bold', padding: 0, }} onClick={() => setEditMode(!editMode)}>Edit Fee</Button>
                    </div>
                </div>
                <table style={{ width: '100%' }}>
                    <tr className={fee.tableHeader}>
                        <td className={fee.tableHeaderData}>
                            Transfer Mode
                        </td>
                        <td className={fee.tableHeaderData}>
                            Minimum Amount
                        </td>
                        <td className={fee.tableHeaderData}>
                            Maximum Amount
                        </td>
                        <td className={fee.tableHeaderData}>
                            Transfer Fee
                        </td>
                        <td className={fee.tableHeaderData}>
                            Percent Fee
                        </td>
                        <td className={fee.tableHeaderData}>
                            Total
                        </td>
                    </tr>
                    {
                        tableData && tableData.map((item, key) => {
                            return (
                                <tr>
                                    <td className={fee.tableChannelHeader}>
                                        {renderMode(item.mode)}
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <div hidden={editMode}>
                                            &#8369; {item.minimum}
                                        </div>
                                        <div hidden={!editMode}>
                                            <CustomInput name={"minimum"} value={item.minimum} placeholder={"Mimimum Amount"} handleChangeVal={handleChange} index={key} />
                                        </div>
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <div hidden={editMode}>
                                            &#8369; {item.maximum}
                                        </div>
                                        <div hidden={!editMode}>
                                            <CustomInput name={"maximum"} value={item.maximum} placeholder={"Maximum Amount"} handleChangeVal={handleChange} index={key} />
                                        </div>
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <div hidden={editMode}>
                                            &#8369; {item.fee}
                                        </div>
                                        <div hidden={!editMode}>
                                            <CustomInput name={"fee"} value={item.fee} placeholder={"Transfer Fee"} handleChangeVal={handleChange} index={key} />
                                        </div>
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <div hidden={editMode}>
                                            {item.percent_fee} %
                                        </div>
                                        <div hidden={!editMode}>
                                            <CustomInput value={item.percent_fee} name={"percent_fee"} handleChangeVal={handleChange} placeholder={"Percent Fee"} index={key} />
                                        </div>
                                    </td>

                                    <td className={fee.tableChannelText}>
                                        <div hidden={editMode}>
                                            &#8369; {item.fee} + {item.percent_fee} %
                                        </div>
                                    </td>
                                </tr>

                            )
                        })
                    }
                </table>
                <ConfirmModal params={params} />
            </div>
        </div>
    )
}

const styles = {
    root: {

    },
    title: {
        color: '#2B2D33',
        fontSize: 24,
        fontWeight: '400',
        textAlign: 'left',
        padding: '24px 0'
    },
    btn: {
        height: 40,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 4,
        width: '45%',
        borderRadius: 10
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    },
    editDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '12px 0'
    }
}

export default TransferMoney;
