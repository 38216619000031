import React from "react";
import { FormWrapper } from "../FormWrapper";

export function Confirmation({
  sender_name,
  sender_gender,
  sender_address,
  sender_mobile_number,
  sender_email,
  sender_source_fund,
  purpose,
  receiver_amount,
  receiver_account_type,
  receiver_bank,
  receiver_account_number,
  receiver_account_name,
  receiver_gender,
  receiver_address,
  receiver_mobile_number,
  receiver_email,
}) {
  return (
    <FormWrapper title="Please confirm the following details">
      <div
        style={{
          width: "100%",
          padding: "2em",
          backgroundColor: "#fff",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          fontSize: "13px",
        }}
      >
        <h2>Sender details</h2>
        <div>
          <p>
            <span style={{ fontWeight: "bold" }}>Name:</span> {sender_name}
          </p>
          <p style={{ textTransform: "capitalize" }}>
            <span style={{ fontWeight: "bold" }}>Gender:</span> {sender_gender}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Address:</span>{" "}
            {sender_address}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Mobile number:</span>{" "}
            {sender_mobile_number}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Email:</span> {sender_email}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Source of fund:</span>{" "}
            {sender_source_fund}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Purpose:</span> {purpose}
          </p>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          padding: "2em",
          backgroundColor: "#fff",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          fontSize: "13px",
        }}
      >
        <h2>Receiver details</h2>
        <div>
          <p>
            <span style={{ fontWeight: "bold" }}>Amount:</span>{" "}
            {receiver_amount}
          </p>
          <p style={{ textTransform: "capitalize" }}>
            <span style={{ fontWeight: "bold" }}>Account type:</span>{" "}
            {receiver_account_type}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Bank:</span> {receiver_bank}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Account number:</span>{" "}
            {receiver_account_number}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Account name:</span>{" "}
            {receiver_account_name}
          </p>
          <p style={{ textTransform: "capitalize" }}>
            <span style={{ fontWeight: "bold" }}>Gender:</span>{" "}
            {receiver_gender}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Address:</span>{" "}
            {receiver_address}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Mobile number:</span>{" "}
            {receiver_mobile_number}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Email:</span> {receiver_email}
          </p>
        </div>
      </div>
    </FormWrapper>
  );
}
