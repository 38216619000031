import React, { useState, useEffect } from "react";
import { Button, Modal, message, Icon, Checkbox, Divider } from "antd";
import { TextField, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./css/segment_fees.css";
import SegmentFeesViewModel from "./segment_fees_viewmodel";
import { validateNumberWithDecimal } from "../../../utils/validators/validateNumberWithDecimal";

const SegmentFeesModal = (props) => {
  const [isTouched, setIsTouched] = useState(false);
  const [openChannel, setOpenChannel] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState("");

  const [formFieldData, setFormFieldData] = useState({
    channel: "",
    categoryCode: "",
    categoryName: "",
    channelFee: "",
    channelFeePercentage: "",
  });

  const {
    addSegmentFee,
    editSegmentFee,
    fetchChannel,
    channelList,
    success,
    messageDisplay,
    isLoadingChannel,
  } = SegmentFeesViewModel();

  async function submitSegmentFee() {
    if (
      formFieldData.channel != "" &&
      selectedChannel != "" &&
      formFieldData.categoryCode != "" &&
      formFieldData.categoryName != "" &&
      formFieldData.channelFee != "" &&
      formFieldData.channelFeePercentage != ""
    ) {
      let params = {
        channel: formFieldData.channel,
        category_code: formFieldData.categoryCode,
        name: formFieldData.categoryName,
        channel_fee: formFieldData.channelFee,
        channel_fee_percent: formFieldData.channelFeePercentage,
      };

      if (props.editData) {
        await editSegmentFee(params, props.editData.id);
      } else {
        await addSegmentFee(params);
      }
    } else {
      setIsTouched(true);
    }
  }

  function clearForm() {
    setFormFieldData({
      channel: "",
      categoryCode: "",
      categoryName: "",
      channelFee: "",
      channelFeePercentage: "",
    });
    setIsTouched(false);
  }

  function updateFields(fields) {
    setFormFieldData({ ...formFieldData, ...fields });
  }

  useEffect(() => {
    if (props.editData) {
      setFormFieldData({
        channel: props.editData.channel,
        categoryCode: props.editData.category_code,
        categoryName: props.editData.name,
        channelFee: props.editData.channel_fee,
        channelFeePercentage: props.editData.channel_fee_percent,
      });
      setSelectedChannel(props.editData.channel);
    }
  }, [props.editData]);

  useEffect(() => {
    displayMessage();
  }, [messageDisplay, success]);

  useEffect(() => {
    fetchChannelOptions();
  }, []);

  async function fetchChannelOptions() {
    await fetchChannel();
  }

  function displayMessage() {
    setTimeout(() => {
      if (messageDisplay != "" && success == true) {
        message.destroy();
        message.success(messageDisplay);
        clearForm();

        if (props.editData) {
          props.closeModal();
        } else {
          props.submitModal();
        }
      } else if (messageDisplay != "" && success == false) {
        message.destroy();
        message.error(messageDisplay);
      }
    }, 1000);
  }

  function displayTextField(label, onChange, value, disabled, className = "") {
    return (
      <Grid item={true} className={className}>
        <TextField
          id="outlined-basic"
          label={label}
          disabled={disabled}
          margin="normal"
          variant="outlined"
          fullWidth
          className={isTouched && value == "" ? `error-field` : ``}
          onChange={onChange}
          value={value}
        />
        {isTouched && value == "" && (
          <p className="error-field-message">This is a required field</p>
        )}
      </Grid>
    );
  }

  return (
    <Modal
      title={props.editData ? "Edit Segment Fee" : "Add Segment Fee"}
      centered
      visible={props.openModal}
      onCancel={() => {
        clearForm();
        props.closeModal();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            clearForm();
            props.closeModal();
          }}
          className="secondary-btn"
        >
          Cancel
        </Button>,
        <Button
          className="primary-btn"
          key="submit"
          type="primary"
          onClick={() => submitSegmentFee()}
        >
          {props.editData ? "Update" : "Add"}
        </Button>,
      ]}
      className="segment-fees-modal"
    >
      <Grid container>
        <div className="field-row-container">
          <Grid item={true} className="full-width-grid-item">
            <Autocomplete
              style={{
                marginBottom: "8px",
                marginTop: "16px",
              }}
              open={openChannel}
              onOpen={() => {
                setOpenChannel(true);
              }}
              onClose={() => {
                setOpenChannel(false);
              }}
              onBlur={() => {
                updateFields({ channel: selectedChannel });
              }}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={channelList}
              loading={isLoadingChannel}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Channel"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingChannel ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  onChange={(e) => updateFields({ channel: e.target.value })}
                  value={formFieldData.channel}
                />
              )}
              onChange={async (option, value) => {
                updateFields({
                  channel: value && value.name ? value.name : "",
                });
                setSelectedChannel(value && value.name ? value.name : "");
              }}
              inputValue={formFieldData.channel}
              value={formFieldData.channel}
              disabled={props.editData}
            />

            {isTouched && formFieldData.channel == "" && (
              <p className="error-field-message" style={{ marginTop: "2px" }}>
                This is a required field
              </p>
            )}
          </Grid>
        </div>

        <div className="field-row-container">
          {displayTextField(
            "Category Code",
            (e) => updateFields({ categoryCode: e.target.value }),
            formFieldData.categoryCode,
            false,
            "full-width-grid-item"
          )}
        </div>

        <div className="field-row-container">
          {displayTextField(
            "Category Name",
            (e) => updateFields({ categoryName: e.target.value }),
            formFieldData.categoryName,
            false,
            "full-width-grid-item"
          )}
        </div>

        <div className="field-row-container">
          {displayTextField(
            "Channel Fee",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({ channelFee: e.target.value });
              }
            },
            formFieldData.channelFee,
            false
          )}
          {displayTextField(
            "Channel Fee Percent",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({ channelFeePercentage: e.target.value });
              }
            },
            formFieldData.channelFeePercentage,
            false
          )}
        </div>
      </Grid>
    </Modal>
  );
};

export default SegmentFeesModal;
