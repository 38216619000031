import React, { useEffect } from "react";
import cardsStyles from "../../../static/css/Cards";
import { BusinessToday, GCash, GCash_Logo } from "../../../static/icons/index";
import "../../../styles/screens/dashboard.scss";

const GCashSubMidCard = (props) => {
  const isMobile = props.isMobile || props.isTabled || props.smallReso;
  const cs = cardsStyles();
  const firstName = localStorage.getItem("firstName");
  const { isDellReso } = props;
  const segments = localStorage.getItem("segments")

  return (
      <div
      className={"accountCard"}
      align="left"
      style={{
        width: isMobile ? "100%" : isDellReso ? "60vw" : "54vw",
        height: isMobile ? 200 : 126,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div hidden={isMobile}>
        <img style={{ height: 100, marginLeft: "20px" }} src={GCash} />
      </div>

      <div className={cs.webCardText}>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <img
            style={{
              height: "2em",
              maxHeight: "3em",
            }}
            src={GCash_Logo}
          />
          <span 
            className={cs.wcHeader} 
            style={{fontSize: isMobile?"16px":"20px", marginLeft: 4, marginTop:2}}>
              announcement
          </span>
        </div>

        <div style={{fontSize: isMobile?"13px":"16px", lineHeight: isMobile?2:"auto"}}>
          Please be advised that GCash is requiring all merchants to register
          for a GCash Merchant ID by September 30, 2022. Register and complete
          your account details
          <a
            href="/gcash_submid"
            className={`text-${segments} smallBoldStyle ${cs.wcText}`}
            style={{ marginLeft: 4, fontSize: isMobile?"13px":"16px"}}
          >
            <span>here</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default GCashSubMidCard;
