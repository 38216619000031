import React from 'react';
import moment from 'moment';
import { Avatar, Button, Card, DatePicker, Divider, Icon, Input, message, Pagination, Select, Typography, Spin } from 'antd';
import { AngryColored, AngryHover, HappyColored, HappyHover, LoveColored, LoveHover, SadColored, SadHover, SmileColored, SmileHover } from '../../../static/images/rating_emoji';
import feedbackServices from './feedbackServices';
import DetailsDrawer from './details_drawer';

const FeedbackCards = () => {
    const [dateRange, setDateRange] = React.useState({ startDate: '', endDate: '' });
    const [selected, setSelected] = React.useState('All');
    const userType = localStorage.getItem('userType');
    const [segment, setSegment] = React.useState('all');
    const [searchVal, setSearchVal] = React.useState('');
    const segments = localStorage.getItem('segments');
    const [visibleClearBtn, setVisibleClearBtn] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [showDrawer, setShowdrawer] = React.useState(false);
    const [detailsData, setDetailsData] = React.useState([]);
    const [filterValue, setFilterValue] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [summary, setSummary] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const segments_list = ['NIL', 'ME_', 'GIG', 'SME', 'BIZ'];

    const dateFormat = 'MM-DD-YYYY';

    const datePickerHelperFunctions = {
        disabledEndDates: (current) => {
            // Can not select days before today
            let isDisabled = current && current < moment().subtract(1, 'days').endOf('day');

            // if (dateRange.endDate) {
            //     // Cannot select days after the start date, if available
            //     isDisabled = isDisabled || current > moment(dateRange.endDate, dateFormat).startOf('day');
            // }

            return isDisabled
        },

        disabledStartDates: (current) => {
            // Cannot select days in the future
            let isDisabled = current && current > moment().endOf('day');

            // if (dateRange.startDate) {
            //     // Cannot select days before the start date, if available
            //     isDisabled = current < moment(dateRange.startDate, dateFormat).startOf('day');
            // }

            return isDisabled;
        }
    };

    const rangeSelect = (dates, dateString, id) => {
        setDateRange({ ...dateRange, [id]: moment(dateString).format('MM-DD-YYYY') });
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value);
    }

    const onFilterApply = () => {
        getFeedbackGallery(page, dateRange.startDate, dateRange.endDate, searchVal);
        setVisibleClearBtn(true);
    }

    const onChangePage = (page) => {
        setPage(page);
        getFeedbackGallery(page, dateRange.startDate, dateRange.endDate, searchVal);
    }

    const resetFilters = () => {
        setSegment('all');
        setDateRange({ startDate: '', endDate: '' });
        setSearchVal('');
        setVisibleClearBtn(false);
        getFeedbackGallery(1, dateRange.startDate, dateRange.endDate, searchVal, 'all');
    }

    const renderRatingIcon = (rate) => {
        switch (rate) {
            case 0:
                return AngryColored;
            case 1:
                return AngryColored;
            case 2:
                return SadColored;
            case 3:
                return SmileColored;
            case 4:
                return HappyColored;
            case 5:
                return LoveColored;
            default:
                return LoveColored;
        }
    }

    const renderColor = (rate) => {
        switch (rate) {
            case 0:
                return {
                    color: '#C0492C',
                    border: '4px solid #C0492C'
                };                
            case 1:
                return {
                    color: '#C0492C',
                    border: '4px solid #C0492C'
                };
            case 2:
                return {
                    color: '#EC7C30',
                    border: '4px solid #EC7C30'
                };
            case 3:
                return {
                    color: '#EBB83F',
                    border: '4px solid #EBB83F'
                };
            case 4:
                return {
                    color: '#3363BB',
                    border: '4px solid #3363BB'
                };
            case 5:
                return {
                    color: '#59C378',
                    border: '4px solid #59C378'
                };
            default:
                return {
                    color: '#59C378',
                    border: '4px solid #59C378'
                };
        }
    }

    const renderSegmentColor = (segment) => {
        switch (segment) {
            case 'ME_':
                return 'rgba(13, 174, 208, 0.2)';
            case 'GIG':
                return 'rgba(65, 189, 92, 0.2)';
            case 'BIZ':
                return 'rgba(0, 81, 176, 0.2)';
            case 'SME':
                return 'rgba(227, 99, 42, 0.2)';
            default:
                return 'rgba(51, 99, 187, 0.5)';
        }
    }

    const renderFeedback = (data) => {
        if (loading) {
            return (
                <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spin size='large' />
                </Card>
            )
        }
        return (
            data && data.length ? data.map((d, i) => {
                return (
                    <Card style={styles.cardRoot} bodyStyle={{ padding: 0 }} hoverable onClick={() => setDetailsData(d) / setShowdrawer(true)}>
                        <br />
                        <div style={{ ...styles.flexBetween, borderLeft: renderColor(parseInt(d.survey_rating)).border }}>
                            <div style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '20px' }}>
                                <Typography style={{ ...styles.ratingVal, color: renderColor(parseInt(d.survey_rating)).color }}>{parseInt(d.survey_rating) || 1}</Typography>
                                <img alt='rating' src={renderRatingIcon(parseInt(d.survey_rating))} style={{ padding: '0 12px', height: '32px' }} />
                            </div>
                            <div style={{ ...styles.segments, backgroundColor: renderSegmentColor(d.segment) }}>
                                {d.segment !== 'NIL' ? ('BUx' + d.segment).replace('_', '') : d.segment}
                            </div>
                        </div>
                        <div style={{ width: '100%', padding: '0 12px' }}>
                            <Divider style={{ margin: '20px auto' }} />
                        </div>
                        <div style={{ padding: '12px' }}>
                            <div style={{ padding: '0px 0 12px 0', display: 'inline-flex', alignItems: 'center' }}>
                                {/* <img alt='avatar' /> */}
                                <Avatar icon={<Icon type="user" />} />
                                <Typography style={styles.name}>{d.survey_type === "CUSTOMERPAYMENT" ? d.customer_name : d.merchant_name}</Typography>
                            </div>
                            {
                                d.survey_details.feedback ?
                                    <div style={{ padding: '8px 0' }}>
                                        <Typography style={styles.question}>What do you like about BUx?</Typography>
                                        <Typography style={styles.answer}>{d.survey_details.feedback}</Typography>
                                    </div> : <></>
                            }

                            {
                                d.survey_details.suggestions ?
                                    <div style={{ padding: '8px 0' }}>
                                        <Typography style={styles.question}>How can we make your experience better?</Typography>
                                        <Typography style={styles.answer}>{d.survey_details.suggestions}</Typography>
                                    </div> : <></>
                            }

                        </div>
                    </Card>
                )
            })
                :
                <Card>
                    <Typography style={{ fontWeight: '700', fontSize: '32px', textAlign: 'center', width: '100%' }}>No Data</Typography>
                </Card>
        )
    }

    const renderSegments = () => {
        if (segment === 'all') {
            console.log('did this')
            return segments_list;
        }
        return segment;
    }

    const getFeedbackGallery = async (page = 1, start, end, search, segmentParams) => {
        setLoading(true);
        try {
            const res = await feedbackServices.getFeedbackGallery(segmentParams ? segmentParams : renderSegments(segment), page, start, end, search);
            console.log('FEEDBACK', res);
            if (res && res.data) {
                setData(res.data)
                setSummary(res.summary);
            }
            else {
                message.error('Error, try again')
            }
        } catch (error) {
            console.log(error);
            message.error('Error, try again')
        }
        setLoading(false);
    }

    React.useEffect(() => {
        getFeedbackGallery();
    }, [])

    return (
        <div style={styles.root}>
            <div style={styles.subContainer}>
                <div style={styles.clearDiv}>
                    <Typography style={styles.filterText}>Filter</Typography>
                    <a style={styles.clearBtnDiv} hidden={!visibleClearBtn} onClick={resetFilters}>
                        <Icon style={styles.clearIcon} type="close-circle" theme="filled" />
                        <span style={styles.clearBtn}>Clear Filter</span>
                    </a>
                </div>
                <Typography hidden style={styles.reminder}><Icon type="exclamation-circle" theme="filled" /> Click <b>Apply</b> button to show results.</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '0 0 24px 0' }}>
                <Select
                    size='large'
                    placeholder='Segments'
                    style={{ width: '16%' }}
                    onChange={(e) => setSegment(e)}
                    value={segment}
                >
                    <Select.Option key={'all'}>All Segments</Select.Option>
                    <Select.Option key={'ME_'}>ME</Select.Option>
                    <Select.Option key={'GIG'}>GIG</Select.Option>
                    <Select.Option key={'SME'}>SME</Select.Option>
                    <Select.Option key={'BIZ'}>BIZ</Select.Option>
                    <Select.Option key={'SUB'}>Sub-wallet</Select.Option>
                </Select>
                <DatePicker
                    format='MM/DD/YYYY'
                    disabledDate={datePickerHelperFunctions.disabledStartDates}
                    // defaultValue={moment()}
                    value={dateRange.startDate && moment(dateRange.startDate)}
                    size="large"
                    picker="month"
                    onChange={(dates, dateString) => rangeSelect(dates, dateString, 'startDate')}
                    placeholder='Start Date'
                    style={styles.datePic}
                    suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                    allowClear={false} />
                <span style={{ paddingTop: 8 }}>-</span>
                <DatePicker
                    format='MM/DD/YYYY'
                    disabledDate={datePickerHelperFunctions.disabledEndDates}
                    // defaultValue={moment()}
                    value={dateRange.endDate && moment(dateRange.endDate)}
                    size="large"
                    picker="month"
                    onChange={(dates, dateString) => rangeSelect(dates, dateString, 'endDate')}
                    placeholder='End Date'
                    style={styles.datePic}
                    suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                    allowClear={false} />
                <Input
                    placeholder="Search"
                    style={{ width: '200px', height: '40px', borderRadius: '4px', padding: '0 8px 0 0px' }}
                    suffix={<Icon type="search" style={{ fontSize: '18px' }} />}
                    onChange={handleSearch}
                    value={searchVal}
                />
                <button className={`btn--${segments}`} style={styles.applyBtn} onClick={onFilterApply} >Apply</button>
            </div>
            <div style={{ ...styles.cardWrapper, gridTemplateColumns: (data && data.length) ? 'repeat(3, 1fr)' : 'repeat(1, 1fr)' }}>
                {renderFeedback(data)}
            </div>
            <div style={styles.paginationWrapper}>
                <Card
                    style={styles.paginationButton}
                    bodyStyle={{ padding: 0 }}
                >
                    <Pagination
                        size='small'
                        total={summary && summary.total_data ? summary.total_data : 0}
                        defaultPageSize={9}
                        pageSize={9}
                        defaultCurrent={page}
                        current={page || 1}
                        onChange={(page, pageSize) => onChangePage(page, pageSize)}
                    />
                </Card>
            </div>
            <DetailsDrawer detailsData={detailsData} drawerVisibility={showDrawer} setDrawerVisibility={(show) => setShowdrawer(show)} />
        </div>
    )
}

const styles = {
    root: {
        padding: '32px 0'
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 178,
        padding: '0 8px 0 8px'
    },
    applyBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: 92,
        cursor: 'pointer',
        backgroundColor: '#0A315E',
        color: '#FFF',
        border: 0
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    ratingVal: {
        fontSize: '24px',
        fontWeight: '700'
    },
    segments: {
        borderRadius: '20px',
        fontWeight: '400',
        fontSize: '12px',
        padding: '2px 12px',
        // opacity: '0.7',
        color: '#2B2D32',
        marginRight: '20px'
    },
    cardRoot: {
        backgroundColor: '#FFF',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        width: '550px',
        maxWidth: '29vw',
        height: 'fit-content',
        overflowWrap: 'break-word',
    },
    name: {
        fontSize: '16px',
        color: '#000',
        fontWeight: '700',
        padding: '0 8px'
    },
    question: {
        fontSize: '12px',
        fontWeight: '700',
        color: '#2B2D32'
    },
    answer: {
        fontSize: '12px',
        fontWeight: '400',
        color: '#2B2D32'
    },
    cardWrapper: {
        width: '100%',
        margin: '0 6px 12px 0px',
        display: 'grid',
        gridGap: '1.5rem',
        columnGap: '14px',
        gridTemplateColumns: 'repeat(3, 1fr)',

        // display: 'flex',
        // flexWrap: 'wrap',
        // alignItems: 'flex-start',
    },
    paginationWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '32px'
    },
    paginationButton: {
        backgroundColor: '#FFF',
        padding: '4px',
        width: 'auto'
    },
    subContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 0'
    },
    clearBtnDiv: {
        backgroundColor: '#E9EEF4',
        borderRadius: 20,
        padding: '6px 12px',
        margin: '0 12px',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #D4D5D8'
    },
    clearBtn: {
        color: '#2b2d32',
        fontSize: 14,
        paddingLeft: 4,
        fontWeight: '500',
        opacity: '0.8'
    },
    clearDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    clearIcon: {
        color: '#2b2d32',
        fontSize: 12,
        opacity: '0.8'
    },
    filterText: {
        color: '#000',
        fontSize: 14,
        fontWeight: 'bold'
    },
}

export default FeedbackCards;