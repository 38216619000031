import { Button, Icon } from "antd";
import React from "react";

export function FilterBar({
  searchQuery,
  startDate,
  endDate,
  tempStartDate,
  tempEndDate,
  selectedStatus,
  clearFilters,
}) {
  return (
    <div
      style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
    >
      <p style={{ fontWeight: "bold", margin: "0" }}>Filter</p>
      {(searchQuery ||
        startDate ||
        endDate ||
        tempStartDate ||
        tempEndDate ||
        selectedStatus !== "All") && (
        <Button
          onClick={clearFilters}
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px solid rgb(212, 213, 216)",
            borderRadius: "20px",
            backgroundColor: "rgb(233, 238, 244)",
            padding: "6px 12px",
            margin: "0px 12px",
          }}
        >
          <Icon type="close-circle" theme="filled" />
          Clear Filter
        </Button>
      )}
    </div>
  );
}
