import axios from 'axios';
import {Handle401} from '../handle401/handle401'

const authorizedAxiosRequest = (value) => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const modalServices = {
  resendVerification: async (email) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL+`/api/resend_verification/`, email);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
}

export default modalServices;
