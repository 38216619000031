import { LocationActionTypes } from './locationAction'

const initialState = {
    branches: {},
    loading: false,
    error: ""
}

export default function locationReducers(state = initialState, action){
    switch(action.type){
        case LocationActionTypes.LOCATION_REQUEST:
            return{
                ...state,
                loading:true
            };
        case LocationActionTypes.LOCATION_SUCCESS:
            return{
                ...state,
                loading: false,
                branches: action.payload
            };
        case LocationActionTypes.LOCATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return initialState;
    }
}