import React from 'react'
import logo from "../../static/icons/logo.svg";
import ecomStyles from '../../static/css/Ecomx';
import { Spin, Button, Alert } from 'antd'
import { Grid, TextField} from "@material-ui/core";
import ecomx from '../../static/images/ecomx.svg'
import axios from 'axios'
import { history } from "../../store/history";


const SignUpWeb = props => {
    
    const ecom = ecomStyles();

    const [email, setEmail] = React.useState('')
    const [fname, setFname] = React.useState('')
    const [lname, setLname] = React.useState('')
    const [hideloading, setLoading] = React.useState(true)
    const [showerror, setError] = React.useState(true)
    const [errorMsg, setErrorMsg] = React.useState('')
    

    async function submitFields () {
        setLoading(false);
        let params = {
          first_name: fname,
          last_name: lname,
          email: email
        }
        let response = await axios.post(
            process.env.REACT_APP_API_URL + "/api/ecomx/register/", params
        )

        setLoading(true);
            console.log(response.data)
        if(response.data.status == "success"){
            let data = response.data
            console.log(data)
            history.push('/payment/'+data.data.uid+'/')
        }else{
            setError(false)
            setErrorMsg(response.data.message)
        }
    }

    return(
        <div>
            <div className={ecom.preMain}>
                <div className={ecom.main}>
                    <div className={ecom.logoDiv}>
                        <img src={logo} />
                    </div>
                    <div className={ecom.cardDiv}>
                        <Grid container>
                            <Grid lg={6}>
                                <div className={ecom.cdLeft}>
                                    <img src={ecomx} />

                                    <div className={ecom.clInfo}>
                                        <span>Date: December 10, 2019</span><br/>
                                        <span>Venue: UnionBank Plaza Bldg</span>
                                    </div>

                                    <div className={ecom.clInfo1}>
                                        <span>By: Nick Peroni</span> 
                                    </div>

                                    <div className={ecom.cld}>
                                        <div className={ecom.crHeader}>
                                            <span>Registration Fee</span>
                                        </div>

                                        <div className={ecom.crDiscoubt}>
                                            <span>P15,500.00</span>
                                        </div>

                                        <div className={ecom.crNow}>
                                            <span>P500.00</span>
                                        </div>

                                        <div className={ecom.clInfo1}>
                                            Powered by <img src={logo} className={ecom.clLogo}/>
                                        </div>
                                    </div>

                                </div>
                            </Grid>
                            <Grid lg={6} >
                                <div className={ecom.cRight}>
                                    <div className={ecom.crsTop}>
                                        <div className={ecom.crsHeader}>
                                            <span>Sign-up</span>
                                        </div>
                                        <div className={ecom.crbText}>
                                            <span>Limited seats only.</span>
                                            <p>First Come, First Served Basis.</p>
                                        </div>
                                        <div className={ecom.form}>
                                            <div hidden={showerror} style={{width: '100%'}} >
                                                <Alert message={errorMsg} type="error" closable showIcon />
                                            </div>
                                            <Grid container>
                                                <Grid lg={12}>
                                                <TextField
                                                id="outlined-basic"
                                                placeholder="Email"
                                                onChange={e => setEmail(e.target.value)}
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                                style={{opacity: '50%'}}
                                                />
                                                </Grid>
                                                <Grid lg={6}>
                                                <TextField
                                                    className={ecom.textField}
                                                    id="outlined-basic"
                                                    placeholder="First Name"
                                                    onChange={e => setFname(e.target.value)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    style={{opacity: '50%'}}
                                                    
                                                    />
                                                </Grid>
                                                <Grid lg={6}>

                                                <TextField
                                                    id="outlined-basic"
                                                    placeholder="Last Name"
                                                    onChange={e => setLname(e.target.value)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    style={{opacity: '50%'}}
                                                    
                                                    />
                                                </Grid>
                                            </Grid>
                                            <div className={ecom.submit} hidden={!hideloading}>
                                                <button className={ecom.butSubmit} onClick={() => submitFields()}>Sign-up now!</button>
                                            </div>


                                            <div style={{display:'flex', justifyContent: 'center', marginTop: '40px'}} hidden={hideloading}>

                                                <Spin size="large" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUpWeb;