import React from 'react'
import {history} from '../../store/history'
import {Icon, Card, Button} from 'antd'
import AppBar from '../appBar/AppBar'
import masterLogo from '../../static/icons/master_card.svg'


class CardDetail extends React.Component{

    headerStyle = {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '32px',
        lineHeight: '40px',
        color: '#000000',
        marginTop: '20px'
    }

    cardNoStyle = {
        fontSize: '26px',
        lineHeight: '32px',
    }

    expiryStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33'
    }

    btn = {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        width: '100%',
        height: '40px'
    }

    textStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#2b2d33'
    }
    
    render(){
        return(
            <div>
                <AppBar/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{width: '35%', padding: '40px'}}>
                        <Icon type="close" style={{float: 'right'}} onClick={()=> history.push('/my_profile/billing')}/>
                        <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                            <div style={this.headerStyle}>Debit</div>
                        </div>
                        <Card style={{height: '228px', paddingTop: '140px'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>
                                <div style={this.cardNoStyle}>**** 3238</div>
                                <div style={this.expiryStyle}>06/23</div>
                            </div>
                            <img src={masterLogo} alt="#"/>
                            </div>
                        </Card>
                        <div style={{marginTop: '20px'}}>
                            <Button type="primary" style={this.btn} onClick={()=> history.push('/update_card/')}>Update card</Button>
                        </div>
                        <div style={{marginTop: '10px'}}>
                            <Button style={this.btn}>Remove card</Button>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                            <Icon type="lock" style={{marginTop: '4px', marginRight: '10px'}}/>This is a secured connection
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CardDetail