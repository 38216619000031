import { makeStyles } from '@material-ui/core';

const kycStyles = makeStyles(theme => ({
    
    mainBody: {
        backgroundColor: '#f5f5f4',
        fontFamily: 'Inter',
        padding :'0px 0px 0px 50px',
        [theme.breakpoints.down("sm")]: {
            padding: '30px 15px 30px 15px',
            overflow: 'hidden',
            backgroundColor: '#f5f5f4',

        },

        [theme.breakpoints.down("xs")]: {
            padding: '30px 15px 30px 15px',
            overflow: 'hidden',
        }
    },

    amainBody: {
        backgroundColor: '#f5f5f4',
        fontFamily: 'Inter',
        paddingBottom :'50px',
        [theme.breakpoints.down("sm")]: {
            padding: '30px 15px 30px 15px',
            overflow: 'hidden',
            marginBottom: '200px',
            backgroundColor: '#f5f5f4',

        },

        [theme.breakpoints.down("xs")]: {
            padding: '30px 15px 30px 15px',
            overflow: 'hidden',
            marginBottom: '200px',

        }
    },
    
    header: {
        width: '800px',
        fontWeight: 'bold',
        paddingTop: '7%',
        fontSize: '25px',
        lineHeight: '32px',
        color: '#000000',
        [theme.breakpoints.down("sm")]: {
            fontSize: '20px',
            paddingTop: '3%',

        },
    },

    backHeader: {
        color: '#1D82B8',
        fontWeight: 600,
        fontSize: '25px',
    },

    spiel: {
        marginTop: '20px',
        width: '950px',
        color: '#000000',
        opacity: '0.8',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            fontWeight: '16px',
            lineHeight: '24px',
            color: '#000000'
        },

    },

    statusDiv: {
        marginTop: '32px',
        width: '800px',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
        fontFamily: 'Inter',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#54575F',
        backgroundColor: '#fff',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            fontSize: '16px',

        },
        [theme.breakpoints.down("xs")]: {
            width: '100%',
            fontSize: '16px',
            padding: '16px',

        }
    },

    statusInfo: {
        padding: '32px 32px 20px 32px',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d1d5dd'
    },

    statusHeader: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
        color: '#000000',
        opacity: 0.8
    },

    statusDate: {
        marginTop: 10,
        fontSize: 16,
        lineHeight: '24px',
        color: '#000000',
    },

    statusFooter: {
        padding: '20px 0px 20px 0px',
        [theme.breakpoints.down("xs")]: {
            padding: '1px 0px 20px 0px',


        }
    },

    seeMore: {
        fontSize: '17px',
        lineHeight: '22px',
        color: '#f5922f',
        fontWeight: '600',
    },

    limits: {
        marginTop: 40,
        width: '800px',
        [theme.breakpoints.down("xs")]: {
            width: '100%',

        },
    },

    basicCard: {
        borderRadius: 10,
        boxSizing: 'border-box',
        border: '3px solid #65C1F2',
        background: '#fff',
        width: '100%',
        height: '310px',
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            height: '100%',

        },
        
    },
    proCard: {
        borderRadius: 10,
        boxSizing: 'border-box',
        border: '3px solid #38ADA9',
        background: '#fff',
        width: '100%',
        height: '310px',
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            height: '100%',

        },
        
    },
    businessCard: {
        borderRadius: 10,
        boxSizing: 'border-box',
        border: '3px solid #1E3799',
        background: '#fff',
        width: '100%',
        height: '310px',
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            height: '100%',

        },
        
    },
    enterpriseCard: {
        borderRadius: 10,
        boxSizing: 'border-box',
        border: '3px solid #FFB142',
        background: '#fff',
        width: '100%',
        height: '310px',
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            height: '100%',

        },
        
    },

    proCard: {
        borderRadius: 10,
        boxSizing: 'border-box',
        border: '2px solid #38ADA9',
        background: '#fff',
        width: '100%',
        height: '310px',
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            height: '100%',

        },
        
    },

    businessCard: {
        borderRadius: 10,
        boxSizing: 'border-box',
        border: '2px solid #0d3d76',
        background: '#fff',
        width: '100%',
        height: '310px',
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            height: '100%',

        },
        
    },

    enterpriseCard: {
        borderRadius: 10,
        boxSizing: 'border-box',
        border: '2px solid #FFB142',
        background: '#fff',
        width: '100%',
        height: '310px',
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            height: '100%',

        },
        
    },



    basicHeader: {
        padding: '20px 0px 10px 0px',
        background: '#65C1F2',
        textTransform: 'uppercase',
        color: '#fff',
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight:  600,
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px'

    },

    proHeader: {
        padding: '20px 0px 10px 0px',
        textTransform: 'uppercase',
        background: '#38ADA9',
        color: '#fff',
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight:  600,
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px'

    },

    busiHeader: {
        padding: '20px 0px 10px 0px',
        textTransform: 'uppercase',
        background: '#1E3799',
        color: '#fff',
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight:  600,
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px'

    },

    enterpriseHeader: {
        padding: '20px 0px 10px 0px',
        textTransform: 'uppercase',
        background: '#FFB142',
        color: '#fff',
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight:  600,
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px'

    },


    currentStatus: {
        background: '#54575F',
        color: '#fff'
    },

    basicInfo: {
        marginTop: 15,
        fontSize: '16px',
        margin: '0px 12px 32px 12px',
    },

    upgradeBtn: {
        fontWeight: '600',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#fafafa',
        background: '#F5922F',
        border: '1px solid #F5922F',
        borderRadius: '4px',
        height: '32px',
        width: '80%',
        marginTop: '-10px',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            marginTop: '0px'
        },
    },

    disableBtn: {
        fontWeight: '600',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#fafafa',
        background: '#E6EAF0',
        border: '1px solid #E6EAF0',
        borderRadius: '4px',
        height: '32px',
        width: '80%',
        marginTop: '-10px',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            marginTop: '0px'
        },
    },

    comingBtn: {
        fontWeight: '600',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#fafafa',
        background: '#1D82B8',
        border: '1px solid #1d82b8',
        borderRadius: '4px',
        height: '48px',
        width: '100%',
        opacity: '0.5'
    },

    cancelBtn: {
        fontWeight: '600',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000',
        background: '#fff',
        border: '1px solid #2b3d33',
        borderRadius: '4px',
        height: '48px',
        width: '100%',
        opacity: '0.5'
    },

    businessHeader: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
        color: '#000000',
        opacity: 0.8,
        marginBottom: '16px',
    },

    ul: {
        paddingLeft: 16,
    },

    bills: {
        marginBottom: '8px',
    },

    addressDiv: {
        background: '#fafafa',
        border: '1px solid #E6EAF0',
        borderRadius: '4px',
        padding: '8px 16px 8px 16px',
    },

    matchAddress: {
        marginTop: '16px',
        marginBottom: '16px',
    },

    uploadDiv: {
        marginTop: '16px',
        marginBotton :'16px',
    },

    uploadButton: {
        marginTop: '16px',
        width: '100%',
        height: '50px',
        background: '#fff',
        border: '1px solid #1d82b8',
        color: '#1d82b8',
        borderRadius: '4px',
        fontWeight: '600'
    },

    submitButton: {
        width: '100%',
        height: '50px',
        background: '#1d82b8',
        border: '1px solid #1d82b8',
        color: '#fff',
        borderRadius: '4px',
        fontWeight: '600'
    },

    disabledSubmitButton: {
        width: '100%',
        height: '50px',
        background: '#1d82b8',
        border: '1px solid #1d82b8',
        color: '#fff',
        borderRadius: '4px',
        fontWeight: '600',
        opacity: '0.5'
    },

    modalHeader: {
        marginTop: '32px',
        color: '2b2d33',
        fontWeight: '600',
        fontSize: '22px',
        marginBottom: '32px',
    },

    previewDiv: {
        marginTop: '16px',
        padding: '32px',
        border: '1px solid #E6EAF0',
        borderRadius: '2px',
    },

    textStyle: {
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '18px',
        color: '#000000'
    },

    presentStyle:{
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#54575F',
        wordWrap: 'break-word'
    },

    permaStyle: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000000',
        wordWrap: 'break-word'
    },

    previewModal: {
        padding: '32px',
        boxShadow: '0px -7px 10px rgba(0, 0, 0, 0.04)',
        position: "fixed",
        zIndex: "200",
        bottom: "0",
        overflow: "scroll",
        width: '100%',
        height: '100%',
        background: '#f5f5f4'
    },


    replaceBtn: {
        marginTop: '16px',
        fontSize: '18px',
        width: '100%',
        height: '50px',
        background: '#1d82b8',
        border: '1px solid #1d82b8',
        color: '#fff',
        borderRadius: '4px',
        fontWeight: '600'
    },


    closePM: {
        marginTop: '60px',
        background: '#fff',
        border: '1px solid #fff',
        color: '#2b2d33',
        fontWeight: '600',
        borderRadius: '4px',
    },

    tipsStyle: {
        marginTop: 32,
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000'
    },

    textStyle3: {
        padding: '30px 0px 30px 0px',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#2b2d33',
    opacity: '80%',
    },

    submitBtnStyle: {
        fontWeight: '600',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#fafafa',
        backgroundColor: '#1D82B8',
        borderRadius: '4px',
        height: '48px',
        width: '100%'
    },

    rejectedDiv:{ 
        width: '100%',
        border: '1px solid #F8B966',
        borderRadius: '4px',
        padding: '20px',
        background: '#faf4ea',

    },

    rejectedHeader: {
        fontSize: '18px',
    },

    rejectedText: {
        fontSize: '16px',
        marginTop: '5px',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px',
        borderRadius: '4px'
    },

    currentStatus2: {
        backgroundColor: '#1DD28B', 
        borderRadius: '0px 2px 2px 0px', 
        color:'#fff', 
        height: '20px', 
        width: '65px',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '20px',
        textAlign: 'center',
    },

    requirementsDiv:{
        display: 'flex',
        padding: 16,
        justifyContent: 'space-between',
        background: '#D1D5DD',
        color: '#000000'
    },

    requirementsDataDiv:{
        display: 'flex',
        justifyContent: 'space-between',
        padding: 16,
        background: '#fff',
        color: '#000000'
    },

    rLeft:{
        marginRight: 40
    },

    rRight: {
        textAlign: 'right'
    },

    tableDiv:{
        padding: 16,
    }

}))

export default kycStyles;
