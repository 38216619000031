import React,{ useState } from 'react';
import ArrowDown from '../../../static/icons/arrow-down.svg';
import { Modal, Typography, Button, Icon, Card, Input, Select } from 'antd';
import { BANKS } from '../../constants/constants';

const {Option} = Select

const EndorseModal = (props) => {
  const [btnStyle, setBtnStyle] = useState({color: '#F5922F', backgroundColor: '#FFF' })
  const [newStatus, setNewStatus] = React.useState(null);
  const [selectOptions, setSelectOptions] = React.useState(null);
  const [accountName, setAccountName] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [accountType, setAccountType] = React.useState("SA");
  const [bank, setBank] = React.useState("");
  const [branch, setBranch] = React.useState("");
  const keyToEndorse = props.keyToEndorse;

  let at = [
      {code: 'SA', name: 'Savings'},
      {code: 'CU', name: 'Current'},
      {code: 'DI', name: 'Digital'},
      {code: 'CH', name: 'Checking'}
  ]

  let accountTypeChoices = at.length > 0 && at.map((item, i) => {
        return(
            <Option key={i} value={item.code}>{item.name}</Option>
        )
  })


  const handleSelectName = (e) => {
    setAccountName(e.target.value);
    props.setName(e.target.value);
  }


  const handleSelectNumber = (e) => {
    setAccountNumber(e.target.value);
    props.setNumber(e.target.value);
  }

  const handleSelectBank = (bank) => {
    setBank(bank);
    props.setBank(bank);
  }

  const handleSelectBranch = (e) => {
    setBranch(e.target.value);
    props.setBranch(e.target.value);
  }


  const handleMouseOver = () => {
    setBtnStyle({
      backgroundColor: '#F5922F',
      color: '#FFF'
    })
  }

  const handleMouseLeave = () => {
    setBtnStyle({
      backgroundColor: '#FFF',
      color: '#F5922F'
    })
  }

  const handleSelect = (e) => {
    setAccountType(e);
    props.setAccountType(e);
  }

  let bank_option = BANKS.length > 0 && BANKS.map((item, i) => {
      
    return (
      <Option key={i} value={item}>{item}</Option>
    )
  }, this)

  const resetProps = () => {
    setAccountName("");
    setAccountNumber("");
    setBank("");
    setBranch("");
    setAccountType('SA');
  }

  React.useEffect(() => {
    resetProps();
  },[props.visible, keyToEndorse])

  return (
    <Modal 
      visible={props.visible} 
      bodyStyle={styles.root} 
      footer={null}
      centered
      width={360}
      onCancel={() => { setNewStatus(null); props.closeModal(); resetProps(); }}
      closeIcon={<Icon type="close" style={{color:'#F5922F'}}/>}
      destroyOnClose
    >
      <Typography style={styles.title}>Endorse</Typography>
      <Typography style={styles.msg}>Are you sure you want to endorse this?</Typography>
        <Card size="default" width='100%' style={styles.detailsCard} bodyStyle={{padding: '0', width: 320}}>
            <div style={styles.tableHeader}>
              <span>
                Account ID: {keyToEndorse}
              </span>
            </div>
        </Card>
        <div style={styles.inputDiv}>
          <label style={styles.label}>Account Name</label>
          <Input
              placeholder=""
              value={accountName}
              onChange={handleSelectName}
              maxLength={24}
          />
        </div>
        <div style={styles.inputDiv}>
          <label style={styles.label}>Account Number</label>
          <Input
              placeholder=""
              value={accountNumber}
              onChange={handleSelectNumber}
          />
        </div>
        <div style={styles.inputDiv}>
          <label style={styles.label}>Bank</label>
          <Select
              data-test="input-bank"
              showSearch
              defaultValue={bank}
              value={bank}
              onChange={(ev)=>handleSelectBank(ev)}
              placeholder="Choose a bank"
              style={{ width: '100%' }}
          >
              {bank_option}

          </Select>
        </div>
        <div style={styles.inputDiv}>
          <label style={styles.label}>Account Type</label>
          <Select
            onChange={handleSelect}
            value={accountType}
            style={{...styles.selectStyle}}>
                {accountTypeChoices}
          </Select>
        </div>
        <div style={styles.inputDiv}>
          <label style={styles.label}>Branch</label>
          <Input
              placeholder=""
              value={branch}
              onChange={handleSelectBranch}
          />
        </div>
      <div style={styles.mainBtn}>
        <Button style={{...styles.btn, borderColor: '#F5922F', ...btnStyle}} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} onClick={() => { setNewStatus(null); props.closeModal(); }}>
          Cancel
        </Button>
        <Button style={{...styles.btn, color: '#FFF', backgroundColor: '#F5922F'}} onClick={() => props.onEndorse(newStatus)} disabled={!accountName ||!accountNumber || !bank || !accountType || !branch }>
          Endorse
        </Button>
      </div>
    </Modal>
  )
}

const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 24,
    color: '#2B2D33',
    fontWeight: '300',
    textAlign: 'left',
    padding: '16px 0 0px 0'
  },
  msg: {
    fontSize: 16,
    color: '#000',
    textAlign: 'left',
    padding: '8px 0'
  },
  mainBtn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 10,
  },
  btn: {
    height: 48,
    width: '45%',
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 10,
  },
  inputDiv: {
    width: '100%',
    padding: '6px 0'
  },
  label: {
    fontSize: 12,
    color: '#212B36',
    padding: '4px 0'
  },
  selectStyle: {
    width: '100%',
    borderRadius: '4px',
    backgroundColor: '#fff',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    appearance: 'none',
    // background: `url(${ArrowDown})`,
    // backgroundRepeat: 'no-repeat',
    // backgroundPosition: '95% 50%',
    fontSize: '16px',
    borderColor: '#D1D5DD',
    borderRadius: 4,
    // padding: '10px 40px 10px 10px',
    margin: '4px 0 0 0'
  },
  status: {
    borderRadius: 10,
    padding: 4,
    border: '1px solid #ddd',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 48,
  },
  subtitle: {
    padding: '0 0 16px 0',
    color: '#2B2D33',
    fontSize: '16px'
  },
  detailsCard: {
    textAlign: 'left',
    margin: '0 0 12px 0',
    width: '100%',
    borderRadius: 4
  },
  detailsContent: {
    display: 'flex',
    padding: '7px 20px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  text: {
    fontSize: 16,
    color: '#2B2D33',
    padding: '4px 12px'
  },
  tableHeader: {
    backgroundColor: '#E6EAF0',
    color: '#2B2D33',
    fontSize: 16,
    padding: '8px 12px'
  }
}

export default EndorseModal;