import axios from 'axios';
import { Handle401 } from '../../handle401/handle401';

const authorizedAxiosRequest = () => {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    });
}

const feesServices = {
    getUserFees: async (uid) => {
        try {
            const res = await authorizedAxiosRequest().get(`/api/corporate/fees/${uid}/`);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getAdminFees: async () => {
        try {
            const res = await authorizedAxiosRequest().get(`/api/fees/`)
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    resetDefaultUserFees: async (uid) => {
        try {
            const res = await authorizedAxiosRequest().delete(`/api/corporate/fees/${uid}/`);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    updateUserFees: async (uid, params) => {
        try {
            const res = await authorizedAxiosRequest().post(`/api/corporate/fees/${uid}/`, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    userTranxFeeInit: async (uid) => {
        try {
            const res = await authorizedAxiosRequest().get(`/api/corporate/settings/${uid}/`);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    userTranxFeeToggle: async (uid, params) => {
        try {
            const res = await authorizedAxiosRequest().post(`/api/corporate/settings/${uid}/`, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },

    multiwalletToggle: async (uid, enabled) => {
        try {
            const params = { 'user_id': uid, 'enabled': enabled }
            const res = await authorizedAxiosRequest().post(`/api/admin/users/masterwallet/`, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    addFee: async (params) => {
        try {
            const res = await authorizedAxiosRequest().post(`/api/fees/add/`, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    updateAdminFee: async (params) => {
        try {
            const res = await authorizedAxiosRequest().post(`/api/fees/edit/`, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getUserTransferFee: async (uid) => {
        try {
            const res = await authorizedAxiosRequest().get(`/api/multiwallet/transfer/fees/${uid}/`);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            } else {
                return { error: 'Error try agian.' }
            }
        }
    },
    updateTransferFee: async (uid, params) => {
        try {
            const res = await authorizedAxiosRequest().post(`/api/multiwallet/transfer/fees/${uid}/`, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            } else {
                return { error: 'Error try agian.' }
            }
        }
    },
    multiwalletCheckoutToggle: async (uid, enabled) => {
        try {
            const params = { 'user_id': uid, 'checkout_enabled': enabled }
            const res = await authorizedAxiosRequest().post(`/api/admin/users/masterwallet/`, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    paymentTypeToggle: async (uid, type) => {
        try {
            const params = { 'user_id': uid, 'payment_type': type }
            const res = await authorizedAxiosRequest().post(`/api/admin/users/masterwallet/`, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },

    getTransferFee: async (uid) => {
        try {
            const res = await authorizedAxiosRequest().get(`/api/corporate/transfer_fees/${uid}/`);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },

    updateTransferFee: async (uid, paymentType, fee) => {
        try {
            const params = { 'payment_type': paymentType, 'fee': fee}
            const res = await authorizedAxiosRequest().post(`/api/corporate/transfer_fees/${uid}/`, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    }
}

export default feesServices;
