import React, { useEffect } from "react";
import otpStyles from "../../../static/css/Otp";
import { Button, Alert, Icon, Divider } from "antd";
import OTPInput from "otp-input-react";
import axios from "axios";
import {isMobile} from 'react-device-detect'
import { history } from "../../../store/history";

const max_timer = 30;
const OtpForm = props => {

  const testMode = (history.location.pathname.indexOf('/test') > -1);

  const otp = otpStyles();
  const [OTP, setOTP] = React.useState("");
  const [alert, setAlert] = React.useState(true);
  const [mobileVerified, setMobileVerified] = React.useState(false);
  const [disable, setDisable] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [uid, setUid] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [msg, setMsg] = React.useState('');
  const [counter, setCounter] = React.useState(max_timer);
  const [emailOtp, setEmailOtp] = React.useState(false);
  const [btnStyle, setBtnStyle] = React.useState({ backgroundColor: 'transparent', color: '#F5922F' })


  useEffect(() => {
    console.log(props)
    if(uid == ''){
      setUid(props.otp_uid)

      if(props.mobile_verified){
        setMobileVerified(props.mobile_verified)
      }
    }
    const intervalId = setInterval(() => {
      if(counter>0){
        setCounter(counter - 1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [counter, props]);


  const calculateDuration = (duration) => {
      const minutes = Math.floor(duration/60).toString();
      const seconds = Math.floor(duration%60);
      let seconds_str = seconds.toString();

      if(seconds < 10){
          seconds_str = "0" +seconds.toString();
      }

      return minutes + ":" + seconds_str
  };

  async function refetchOTP() {
    try{
        setLoading(true);
        let response = await axios.get(
            process.env.REACT_APP_API_URL + "/api/otp/?id="+props.userid+"&uuid="+props.otp
        );
        setUid(response.data.uid);
        setMobileVerified(response.data.mobile_verified);
        setCounter(max_timer);
        setLoading(false);
    }
    catch(error){
        // if((error.response)&&(error.response.status == 401)){
        //     Handle401()
        // }
    }
    
}

  async function fetchEmailOtp() {
    setEmailOtp(true);
    try{
        setLoading(true);
        let response = await axios.get(
            process.env.REACT_APP_API_URL + `/api/email_otp/?subject=login&uuid=${props.otp}&id=${props.userid}`
        );
        if(response.status === 'success') {
          setCounter(max_timer);
          setLoading(false);
          setEmailOtp(true);
        } else {
          setLoading(false);
        }
    }
    catch(error){
        // if((error.response)&&(error.response.status == 401)){
        //     Handle401()
        // }
        console.log(error);
        setLoading(false);
    }
    
  }

async function submitAPI() {

  let params = {
    uid : uid,
    id: props.userid,
    uuid : props.otp,
    code: OTP
  }

  setDisable(true);
  setLoading(true);

  try{
      let response = {};
      if(emailOtp){

        response = await axios.post(
            process.env.REACT_APP_API_URL + "/api/email_otp/",params
        );
      }else{

        response = await axios.post(
            process.env.REACT_APP_API_URL + "/api/otp/",params
        );
      }
      console.log(response);
      if(response.data.status == 'success'){
          let token = response.data.access_token;
          let userDetails = response.data.user_details;
          console.log(userDetails)
          localStorage.setItem("jwtToken", token);
          localStorage.setItem("changePw", userDetails.prompt_password_change);
          localStorage.setItem("userId", userDetails.id);
          localStorage.setItem("firstName", userDetails.first_name);
          localStorage.setItem("lastName", userDetails.last_name);
          localStorage.setItem("middleName", userDetails.middle_name);
          localStorage.setItem("suffix", userDetails.suffix);
          localStorage.setItem("username", userDetails.username);
          localStorage.setItem("isAlias", userDetails.is_alias);
          localStorage.setItem("merchant_bias", userDetails.merchant_bias);
          localStorage.setItem("email", userDetails.email);
          localStorage.setItem("contact", userDetails.contact);
          localStorage.setItem("balance", userDetails.balance);
          localStorage.setItem("userType", userDetails.user_type)
          localStorage.setItem("isCompleted", userDetails.is_completed)
          localStorage.setItem("isVerified", userDetails.is_verified)
          localStorage.setItem("has_address", userDetails.address)
          localStorage.setItem('Level', userDetails.kyc_level)
          localStorage.setItem("Step", userDetails.kyc_step)
          localStorage.setItem("lastLogin", userDetails.last_login)
          localStorage.setItem("shipping_enabled", userDetails.shipping_enabled)
          localStorage.setItem("admin_711_enabled", userDetails.admin_711_enabled)
          localStorage.setItem("banks_disabled", JSON.stringify(userDetails.banks_disabled))
          localStorage.setItem("channels_disabled", JSON.stringify(userDetails.channels_disabled))
          let search = window.location.search;
          let params = new URLSearchParams(search);
          let next = params.get('next');

          if(next != null && next.length > 0) {
            window.location.href = next

          }

          else if (userDetails.prompt_password_change) {
            window.location.href = '/password_expiry'

          }
          
          else{

              if(isMobile){
                  window.location.href = '/mobile/dashboard'
              }else{
                if(testMode){
                  window.location.href = '/test/dashboard'
                }
                else {
                  window.location.href = '/dashboard'
                }
              }

          }
      }

      else{
        setStatus('error');
        setLoading(false);
        setMsg(response.data.message);
        setAlert(false);

      }


  }
  catch(error){
      // if((error.response)&&(error.response.status == 401)){
      //     Handle401()
      // }
  }
  setDisable(false);
  
}


  function submitOtp() {
    console.log(OTP);
    submitAPI();
  }

  function onOTPChange(val) {
    if (val.length == 6) {
      setDisable(false);
    } else if (val.length < 6) {
      setDisable(true);
    }
    setOTP(val)
  }

  const handleOtpEmail = () => {
    fetchEmailOtp();
  }

  const handleMouseOver = () => {
    setBtnStyle({
      backgroundColor: '#F5922F',
      color: '#FAFAFA'
    });
  }

  const handleMouseLeave = () => {
    setBtnStyle({
      backgroundColor: 'transparent',
      color: '#F5922F'
    });
  }

  function handleKeyPress(event) {
    if(event.key === 'Enter' && !disable){
      submitOtp();
    }
  }

  return (
      <div className={otp.main}>
          <Button className={otp.backBtn} onClick={() => window.location.href='/login'}>{<Icon type="left" />}Back</Button>

          <Alert
            message={msg}
            type={status}
            style={{
              display: alert ? "none" : "block",
              marginTop: "20px",
              borderLeft: status == 'error' ? "4px solid red" : status == ' success' ? "4px solid green" : '' 
            }}
          />

          <div className={otp.spiel}>
            <div className={otp.sHeader}>One-Time PIN</div>

            <div className={otp.sDesc}>
              {emailOtp?
                'You will receive a One-Time Password (OTP) on your registered email address.' :
                <div>
                  You will receive a One-Time Password (OTP) on your registered 
                  mobile number ending in <b>{props.mobile.substr(props.mobile.length - 4)}</b>.
                </div>
              }
            </div>
          </div>

          <div className={otp.inputDiv}  onKeyDown={(e)=>handleKeyPress(e)}>
            <OTPInput
              value={OTP}
              onChange={(e) => onOTPChange(e)}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              style={{justifyContent: 'center'}}
              inputStyles={{
                height: 89,
                width: 48,
                fontSize: "28px",
                color: "#2b2d33",
                fontWeight: "600",
                background: "#fff",
                boxSizing: "border-box",
                border: "2px solid #000",
                borderRadius: "4px",
                margin: '0 10px',
              }}
            />
          </div>

          <div className={otp.expSpiel} align="center" hidden={loading && !emailOtp || emailOtp}>
            <div>
              <Button className={otp.resBtn} style={{...btnStyle, fontWeight: "600", opacity: counter == 0 ? 1 : 0.5}}
              onClick={() => {if(counter==0)refetchOTP()}} disabled={counter != 0}
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              >
                Resend Code
              </Button>
            </div>
            <div>
            “<b>Resend Code</b>” will be enabled after <b>{ calculateDuration(counter) }</b>
            </div>
          </div>

          <div className={otp.submitDiv}>
            <Button
              style={{backgroundColor: '#F5922F', color: '#FAFAFA'}}
              className={!disable ? otp.submitBtn : otp.disableBtn}
              disabled={disable || loading}
              onClick={() => submitOtp()}
              // onMouseOver={handleMouseOver}
            >
              Submit
            </Button>
          </div>
          <div hidden={emailOtp || !mobileVerified} >
            <div style={{padding: '0 50px', textAlign: 'center'}}>
              <Divider style={{display: 'flex', alignItems: 'center', margin: '30px 0'}}>
                <strong>OR</strong>
              </Divider>
            </div>
            <div className={otp.btnEmail}>
              <a
                style={{ color: '#F5922F' }}
                onClick={handleOtpEmail}
                disabled={loading}
              >Send OTP code via Email</a>
            </div>
          </div>
        </div>
  );
};

export default OtpForm;
 