import React from 'react';
import axios from 'axios';
import { Modal, Button, message, Select } from 'antd';
import { Grid, TextField } from '@material-ui/core';
import ResendModal from './resend';
import UserMenu from '../userHistory/components/menu';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Visibility from '@material-ui/icons/Visibility';

const ConvertUserModal = props => {

    const {
        openModal, setOpenModal, data
    } = props;

    const [newPassword, setNewPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [buxSegment, setBuxSegment] = React.useState(null);
    
    const initiatedBy = localStorage.getItem("userId")

    const convertedBy = localStorage.getItem('userId');

    const errorMessage = (text) => {
        message.destroy()
        message.error(text, 3)
    }

    const successMessage = (text) => {
        message.destroy()
        message.success(text, 3)
        setOpenModal(false)
    }

    const buxSegments = {
        "ME_": "BUxME",
        "GIG": "BUxGIG",
        "SME": "BUxSME",
        "BIZ": "BUxBIZ"
    }

    let yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }


    async function convertBuxSegmentUser() {
        let userId = data.id;
        let response = await axios.get(
            process.env.REACT_APP_API_URL + '/api/admin/convert/?userId=' + userId + '&segment=' + buxSegment + '&convertedBy=' + convertedBy, yourConfig
        );

        if (response.data.status === "success") {
            successMessage("User is now converted!")
        }

        else {
            message.info(response.data.message, 3)
        }
    }

    return (
        <Modal
            title="Convert User"
            centered
            visible={openModal}
            width={400}
            onCancel={() => setOpenModal(false)}
            footer={[
                <Button key="back" onClick={() => setOpenModal(false)} className="admin-download-btn">
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={() => convertBuxSegmentUser()} className="add-user">
                    Convert
                </Button>,
            ]}>
            <Grid container>

                <Grid item lg={12} xs={12}>
                    <b>Current BUx Segment: </b> {buxSegments[data.bux_segment]}
                    <br />
                    <br />
                </Grid>

                <Grid item lg={12} xs={12}>
                    <Select
                        size="large"
                        placeholder="BUx Segment"
                        style={{ width: '100%', marginRight: '12px', zIndex: 1 }}
                        onChange={(e) => {
                            setBuxSegment(e);
                        }}
                    >
                        <Select.Option value="ME_">BUxME</Select.Option>
                        <Select.Option value="GIG">BUxGIG</Select.Option>
                        <Select.Option value="SME">BUxSME</Select.Option>
                        <Select.Option value="BIZ">BUxBIZ</Select.Option>
                    </Select>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default ConvertUserModal;

