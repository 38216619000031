import axios from "axios";
import {Handle401} from '../../handle401/handle401';

let yourConfig = {
  headers: {
     Authorization: "Token " + localStorage.getItem("jwtToken")
  }
}

const authorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

async function fetchUsersNow(page, search, start='', end='', type, level, 
    is_corporate_filtered, 
    years, nature, accept_online, channels, types, transaction, avg_ticket, activation, key_account_type,
    test_account_type='Real', kyb_step) {
  try {
    const endpoint_url = process.env.REACT_APP_API_URL + '/api/admin/users/';

    let query_params = [
      'page=' + page,
      'page_size=' + (20).toString(),
      'search=' + search,
      'start=' + start,
      'end=' + end,
      'segment=' + type,
    ];

    if(type !== "BUx") {
      query_params.push('key_account_type=' + key_account_type);
      query_params.push('test_account_type=' + test_account_type);
      query_params.push('level=' + level);
    }

    if(type === "Test"){
      query_params.push('key_account_type=' + key_account_type);
      query_params.push('test_account_type=' + test_account_type);
      query_params.push('level=All');
    }

    if(type === 'ME') {
      query_params.push('level=' + level);
    }

    if(kyb_step) {
      query_params.push('kyb_step=' + kyb_step);
    }

    if(is_corporate_filtered){
      query_params.push(
        // 'level=' + level,
        'years='+years,
        'nature='+nature,
        'accept='+accept_online,
        'channels='+channels,
        'types='+types,
        'transaction='+transaction,
        'avg_ticket='+avg_ticket,
        'activation='+activation,
      )
    }
    const full_url = endpoint_url + '?' + query_params.join("&");

    let response = await axios.get(
      full_url,
      yourConfig
    );

    console.log(response)
    return response;

  } catch (error) {
    console.log(error);
  }
}

const TagUserToggle = async (params) => {
  try {
    const res = await authorizedAxiosRequest().patch(`/api/admin/users/key_accounts/`, params);
    return res.data;
  } catch (error) {
    if((error.response)&&(error.response.status == 401)){
      Handle401()
    } else if(error.response&&error.response.status == 400) {
      return error.response.data;
    }
    console.log(error);
  }
}

const TagUserAsTestAccntToggle = async (params) => {
  try {
    const res = await authorizedAxiosRequest().patch(`/api/admin/users/test_accounts/`, params);
    return res.data;
  } catch (error) {
    if((error.response)&&(error.response.status == 401)){
      Handle401()
    } else if(error.response&&error.response.status == 400) {
      return error.response.data;
    } else {
      return error.response.data;
    }
    console.log(error);
  }
}

const GetEnterpriseSurveyAnswer = async(id) => {
  try {
    const res = await authorizedAxiosRequest().get(`/api/${id}/enterprise_survey_answer/`);
    return res.data;
  } catch (error) {
    if((error.response)&&(error.response.status == 401)){
      Handle401()
    } else if(error.response&&error.response.status == 400) {
      return error.response.data;
    }
    console.log(error);
  }
}

export { fetchUsersNow, TagUserToggle, TagUserAsTestAccntToggle };
