import React, {useEffect, useState} from 'react';
import multiwalletServices from '../../../multiwalletServices';
import { axios } from '../../../../../lib/axios';
import { Divider, Icon, message, Modal, Typography, Select, Checkbox } from 'antd';
import { history } from '../../../../../store/history';
import { useRestrictedPayoutBanks } from './api';
import SubwalletForm from './subwallet_form';
import SubwalletTypeForm from './subwallet_type_form';


const CreateSubWalletModal = (props) => {
    const subwalletTypes = {
      IND: {
        label: "Individual",
        fields: ["first_name", "last_name", "email", "contact"],
      },
      BRN: {
        label: "Branch",
        fields: [
          "branch_name",
          "location",
          "first_name",
          "last_name",
          "email",
          "contact",
        ],
      },
      CMP: {
        label: "Company",
        fields: [
          "business_name",
          "address",
          "first_name",
          "last_name",
          "email",
          "contact",
          "nature_of_business",
        ],
      },
    };
    const subwalletTypeKeys = Object.keys(subwalletTypes)
    const corporationFields = ['business_name','nature_business']

    const {Option} = Select;
    const subuserRole = localStorage.getItem('subuser_role');
    const subwalletTypesEnabled = localStorage.getItem('subwallet_types_enabled') === 'true';
    const { segments, onImportBatch } = props;
    const [isLoading, setIsloading] = React.useState(false);
    const [params, setParams] = React.useState({ merchantType: 'SW', first_name: '', last_name: '', email: '', contact: '' });
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const subwalletTransferEnabled = props.balance && props.balance.subwallet_transfer_enabled;
    const [selectedSubwalletType, setSelectedSubwalletType] = useState(subwalletTypeKeys[0])
    const [merchantDetails, setMerchantDetails] = useState({
        rail_type: "",
        sw_type: "admin",
        bank_code: "",
        multiwallet_privileges: {
            generate_payment_link_enabled: true,
            checkout_enabled: true,
            add_money_enabled: true,
            transfer_money_enabled: true,
            payout_enabled: true,
            payout_type: 0,
            is_payout_restricted: false,
            link_store_enabled: true,
            exclusive_banners_enabled: true,
            add_users_enabled: true,
            wallet_transfer_enabled: true,
            wallet_transfer_history_enabled: true,
            wallet_transfer_approval_enabled: true,
            bank_transfer_enabled: false,
            limits_configuration_enabled: true,
            limits_approval_enabled: true,
            limits_history_enabled: true,
            fund_transfer_history_enabled: true,
        }
    });

    const { data: restrictedPayoutBanksData } = useRestrictedPayoutBanks();

    const handleSubwalletTypeChange = (swType) => {
        setSelectedSubwalletType(swType)
    }

    useEffect(()=>{
        if(subuserRole == 'CO'){
            setParams({merchantType: 'SW', business_name: '', nature_business: 0, first_name: '', last_name: '', email: '', contact: '' })
        }
    },[])

    const validateFields = () => {

        if (Object.keys(params["first_name"]).length > 30) {
          message.error("First name exceeded to max limit of 30 characters");
        } else if (Object.keys(params["last_name"]).length > 30) {
          message.error("Last name exceeded to max limit of 30 characters");
        } else if (Object.keys(params["email"]).length > 100) {
          message.error("Email exceeded to max limit of 100 characters");
        } else if (
          params["contact"].charAt(0) !== "9" ||
          params["contact"].length !== 10
        ) {
          message.error("Invalid mobile number");
        } else {
          createSubWallet();
        }
    }

    const resetFields = () => {
        setParams({
            merchantType: 'SW',
            first_name: '',
            last_name: '',
            email: '',
            contact: ''
        })
    }

    const createSubWallet = async () => {
        try {
            const res = subwalletTransferEnabled
                ? await axios.post('/api/multiwallet/', {merchant_group_name: params['business_name'], ...params, ...merchantDetails, subwallet_type: selectedSubwalletType})
                : await multiwalletServices.createSubWallet(testMode, {...params, subwallet_type: selectedSubwalletType});

            if (res.status === 'success') {
                message.success('Sub-wallet successfully created');
                resetFields();
                setMerchantDetails({
                    rail_type: "",
                    sw_type: "admin",
                    bank_code: "",
                    multiwallet_privileges: {
                        generate_payment_link_enabled: true,
                        checkout_enabled: true,
                        add_money_enabled: true,
                        transfer_money_enabled: true,
                        payout_enabled: true,
                        payout_type: 0,
                        is_payout_restricted: false,
                        link_store_enabled: true,
                        exclusive_banners_enabled: true,
                        add_users_enabled: true,
                        wallet_transfer_enabled: true,
                        wallet_transfer_history_enabled: true,
                        wallet_transfer_approval_enabled: true,
                        bank_transfer_enabled: false,
                        limits_configuration_enabled: true,
                        limits_approval_enabled: true,
                        limits_history_enabled: true,
                        fund_transfer_history_enabled: true,
                    }
                })
                props.onClose();
            } else if (res.status === 'error') {
                message.error(res.message);
            }
        } catch (error) {
            console.log(error);
            message.error('Error please try again');
        }
    }

    const handleChange = (event) => {
        if (event.target.name === 'contact') {
            setParams({ ...params, [event.target.name]: autoFormat(event.target.value) })
        } else if (event.target.name === 'first_name' || event.target.name === 'last_name' || event.target.name == 'business_name') {
            setParams({ ...params, [event.target.name]: sliceSymbols(event.target.value) })
        } else {
            setParams({ ...params, [event.target.name]: event.target.value })
        }
    }

    const natureBusinessChange = (e) => {
        setParams({...params, nature_business: e, nature_of_business: e})
    }


    const autoFormat = (value) => {
        const formattedValue = value.replace(/\D/g, '')
        if (value !== null) {
            return formattedValue;
        }
        return value;
    }

    const sliceSymbols = (text) => {
        const formattedValue = text.replace(/[^a-zA-Z0-9 ]/g, "")
        if (text !== null) {
            return formattedValue;
        }
        return text;
    }

    const hasBlanks = (swType) => {
      const fields = subwalletTypes[swType].fields;
      const requiredFields = [...fields, ...(subuserRole === 'CO' ? corporationFields : [])];
      return requiredFields.some(field => params[field] === null || params[field] === undefined || params[field] === '')
    };
      

    return (
        <Modal
            maskClosable
            onCancel={() => {
                props.onClose();
                resetFields();
                setMerchantDetails((prev) => ({
                    ...prev,
                    rail_type: "",
                    bank_code: "",
                    multiwallet_privileges: {
                        ...prev.multiwallet_privileges,
                        bank_transfer_enabled: false,
                        is_payout_restricted: false
                    }
                }))}
            }
            visible={props.visible}
            footer={false}
            closeIcon={<Icon type="close" className={`text-${segments}`} />}
            width={360}
            destroyOnClose
            centered
        >
            <Typography style={styles.title}>Create Sub-wallet {(props.balance && props.balance.subwallet_transfer_enabled) ? "(Admin)": ""}</Typography>
            {!subwalletTypesEnabled ? 
                <SubwalletForm
                    data={params} 
                    handleChange={handleChange} 
                    isLoading={isLoading} 
                    natureBusinessChange={natureBusinessChange} 
                    styles={styles} 
                />
                :<SubwalletTypeForm
                    data={params}
                    handleSubwalletTypeChange={handleSubwalletTypeChange}
                    isLoading={isLoading}
                    styles={styles}
                    subwalletTypeKeys={subwalletTypeKeys}
                    subwalletTypes={subwalletTypes}
                    handleChange={handleChange}
                    natureBusinessChange={natureBusinessChange}
                />
            }
            {subwalletTransferEnabled ? (
                <div style={{ marginBottom: '1rem' }}>
                    <Checkbox onChange={(e) => setMerchantDetails((prev) => ({
                        ...prev,
                        rail_type: "",
                        multiwallet_privileges: {
                            ...prev.multiwallet_privileges,
                            bank_transfer_enabled: e.target.checked
                        }
                    }))}>
                        Enable Bank Transfer
                    </Checkbox>
                </div>
            ): ""}
            {subwalletTransferEnabled && merchantDetails.multiwallet_privileges.bank_transfer_enabled ? (
                <div>
                    <label style={styles.labelFont}>Rail Type</label>
                    <Select
                        style={{width: '312px', marginBottom: '1rem'}}
                        name='rail_type'
                        value={merchantDetails.rail_type}
                        size='large'
                        placeholder="Select default rail type"
                        onChange={(e) => setMerchantDetails({ ...merchantDetails, rail_type: e })}
                    >
                        <Option value="ALB">AllBank</Option>
                        <Option value="UBP">UnionBank</Option>
                    </Select>
                </div>
            ) : ""}
            {subwalletTransferEnabled ? (
                <div style={{ marginBottom: '1rem' }}>
                    <Checkbox onChange={(e) => setMerchantDetails((prev) => ({
                        ...prev,
                        bank_code: "",
                        multiwallet_privileges: {
                            ...prev.multiwallet_privileges,
                            is_payout_restricted: e.target.checked
                        }
                    }))}>
                        Restrict payout to specific bank
                    </Checkbox>
                </div>
            ) : ""}

            {
                (subwalletTransferEnabled &&
                merchantDetails.multiwallet_privileges.is_payout_restricted) ? (
                    <div style={{ marginBottom: "1rem" }}>
                        <label style={styles.labelFont}>
                            Choose the bank to restrict account to
                        </label>
                        <Select
                            style={{ width: "312px", marginBottom: "1rem" }}
                            name="bank_code"
                            size="large"
                            placeholder="Select bank"
                            onChange={(e) => setMerchantDetails({ ...merchantDetails, bank_code: e })}
                        >
                            {restrictedPayoutBanksData &&
                                restrictedPayoutBanksData.banks.map((bank) => (
                                    <Option value={bank.code}>{bank.bank_name}</Option>
                                ))}
                        </Select>
                    </div>
                ) : (
                    ""
                )
            }

            <button className={`btn--${segments} btn-height btn-mobile-width`} disabled={isLoading || hasBlanks(selectedSubwalletType) || (merchantDetails.multiwallet_privileges.bank_transfer_enabled === true && merchantDetails.rail_type === "") || (merchantDetails.multiwallet_privileges.is_payout_restricted === true && merchantDetails.bank_code === "")} onClick={validateFields}>Create</button>
            {subwalletTransferEnabled ? "" : (
                <>
                    <div style={{ display: 'inline-flex' }}>
                        <Divider style={styles.divider} />
                        <Typography style={styles.or}>OR</Typography>
                        <Divider style={styles.divider} />
                    </div>
                    <div>
                        <button className={`outline-btn--${segments} btn-height btn-mobile-width`} onClick={onImportBatch}>
                            <Icon type='download' className={`text-${segments} right-4`} />
                            Import batch list
                        </button>
                    </div>
                </>
            )}
        </Modal>
    )
}

const styles = {
    title: {
        color: '#2B2D32',
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'left',
        padding: '16px 0'
    },
    msg: {
        color: '#2B2D33',
        fontSize: 16,
        textAlign: 'left',
        padding: '12px 0'
    },
    btn: {
        height: 48,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 4,
        width: '100%',
    },
    input: {
        padding: '11px 16px 11px 16px',
        fontSize: 16,
        margin: '4px 0px 12px 0px',
        color: '#2B2D33'
    },
    labelFont: {
        fontSize: 12,
        color: '#212B36'
    },
    mobilePrefix: {
        position: 'absolute',
        top: `58%`,
        left: '10%',
        transform: 'translate(-50%, -50%)',
        fontSize: 16,
        color: '#2B2D33',
        fontWeight: 'normal',
        zIndex: '99',
    },
    or: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#909196',
        alignSelf: 'center',
        padding: '0 12px'
    },
    divider: {
        padding: '0 21px'
    },
    dloadBtn: {
        fontSize: 16,
        fontWeight: '600',
        padding: '8px, 16px, 8px, 16px',
        border: '1px solid #F5922F',
        height: 40,
        marginRight: 12,
        color: '#F5922F',
        backgroundColor: '#FFF',
        width: '100%'
    }
}

export default CreateSubWalletModal;
