import { makeStyles } from '@material-ui/core'
import {isMobile} from 'react-device-detect'

const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;

const expiryStyle = makeStyles(theme => ({

    mainBody: {
        marginTop: isHighReso ? '10%' : '',
        color: '#2B2D33',
        [theme.breakpoints.down("sm")]: {
            marginTop: '15%',
            padding: '32px',
            // fontSize:'12px',
        },
    },

    header: {
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        marginTop: 32,
    },

    message: {
        textAlign: 'center',
        marginTop: 32,
        fontSize: '16px',
        lineHeight: '24px',
    },

    message1: {
        textAlign: 'center',
        marginTop: 16,
        fontSize: '16px',
        lineHeight: '24px',
    },

    buttonsDiv: {
        marginTop: 56,
    },

    updatePassword: {
        outline: 0,
        width: '100%',
        height: '48px',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
        background: '#F5922F',
        border: '1px solid #F5922F',
        borderRadius: '10px',
        cursor: 'pointer',
        '&:hover': {
            opacity: '0.8',
        },
    },

    seperator: {
        marginTop: 32,
        marginBottom: 32,
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        textTransform: 'uppercase',
        textAlign: 'center'
    },

    waivePassword: {
        outline: 0,
        width: '100%',
        height: '48px',
        color: '#F5922F',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
        background: '#fff',
        border: '1px solid #F5922F',
        borderRadius: '10px',
        cursor: 'pointer',
        '&:hover': {
            background: '#F5922F',
            color: '#fff',

        },
    },

    miniText: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#212B36', 
        marginBottom: '5px'
    },

    inputDiv: {
        marginTop: 20,
    },

    link: {
        color: '#F5922F',
        fontWeight: 600,
        fontSize: '16px'
    },

    passwordCheckDiv: {
        marginTop: 20,
        marginBottom: isHighReso ? '' : 32,
        padding: 20,
        background: '#fff',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
    },

    pcHeader: {
        color: '#000000',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
    },

    invalid: {
        color: '#909196',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '24px',
    },

    valid: {
        color: '#1DD28B',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '24px',
    },

    miniPadding: {
        marginTop: '8px',
    },

    successText: {
        marginTop: 16,
        fontSize: 24,
        lineHeight: '32px',
        color: '#2B2D33',
        fontWeight: 300
    },
}))

export default expiryStyle;