import React,{useEffect} from 'react'
import {Button, Select, Input, DatePicker, Icon} from 'antd'
import ScheduleTable from './table';
import ScheduleModal from '../modal/schedule_modal';
import ArrowRight from '../../../static/icons/arrow_right.svg';
import moment from 'moment';
import { history } from '../../../store/history';
import recurringServices from '../recurringServices';
import Pagination from '@material-ui/lab/Pagination';
import TestModeBanner from '../../cards/test_mode/testModeBanner';

const PaymentSchedules = props => {

    const segmentsColor = {
        'me_':{
            color: '#0DAED0',
            border: '4px solid #0DAED0'
        },
        'gig':{
            color: '#41BD5C',
            border: '4px solid #41BD5C'
        },
        'sme':{
            color: '#E3632A',
            border: '4px solid #E3632A'
        },
        'biz':{
            color: '#0051B0',
            border: '4px solid #0051B0'
        }
    }

    const { Option } = Select;
    const segments = localStorage.getItem("segments")

    const [hoverImport, setHoverImport] = React.useState(false)
    const [hoverAdd, setHoverAdd] = React.useState(false)

    const [data, setData] = React.useState([])
    const [summary, setSummary] = React.useState([])
    const [currentPage, setCurrentPage] = React.useState(1)
    const [pageSize, setPageSize] = React.useState(10)

    const [startDate, setStartDate] = React.useState("")
    const [startMoment, setStartMoment] = React.useState("")

    const [endDate, setEndDate] = React.useState("")
    const [endMoment, setEndMoment] = React.useState("")
    const [endOpen, setEndOpen] = React.useState(false)

    const [searchVal, setSearchVal] = React.useState("");
    const [frequency, setFrequency] = React.useState("All");
    const [isFiltered, setIsFiltered] = React.useState(false);

    const [schedModalVisibility ,setSchedModalVisibility] = React.useState(false)
    const testMode = history.location.pathname.indexOf('/test') > -1;

    // disabled schedule payment request
    const userType = localStorage.getItem('userType');
    const kybStep = localStorage.getItem("KYBStep");
    const approvedStep = [11, 12]
    const level = localStorage.getItem("Level");
    const disablePaymentLink = (userType === "CO" && localStorage.getItem("KYBStep") < 11) && 
                                ((!testMode || localStorage.getItem("userType") === "CO") && 
                                (!approvedStep.includes(parseInt(kybStep)) && !testMode)) || parseInt(level) <= 0;
    const defined_wallet_settings = JSON.parse(localStorage.getItem('defined_wallet_settings'));
    let generatePaymentLinkEnabled = false;
    
    if(defined_wallet_settings != null){
        generatePaymentLinkEnabled = defined_wallet_settings.generate_payment_link_enabled ? defined_wallet_settings.generate_payment_link_enabled : '';
    }

    useEffect(()=>{

        if(disablePaymentLink || !generatePaymentLinkEnabled) {
            history.goBack();
        }

        fetchData(1, 10, startDate, endDate, frequency, searchVal)
    },[])

    const fetchData = async(page, size, start, end, frequenceSelected, search) => {
        let response = await recurringServices.getAllSchedules(
            page,
            size,
            start,
            end,
            frequenceSelected,
            search,
            testMode
        )
        // if(response.status && response.status == 'success'){
        //     // setExportModalVisibility(false)
        //     // message.success("CSV reports successfully sent to your email address")
        // }
        if(response.data){
            console.log(response.data)
            setData(response.data)
            setSummary(response.summary)
        }
    }

    const disabledStartDate = startValue => {
        if (!startValue || !endMoment) {
          return false;
        }
        return startValue && startValue > moment().endOf('day');
    }
    const disabledEndDate = endValue => {
        if (!endValue || !startMoment) {
            return false;
        }
        return endValue.valueOf() <= startMoment.valueOf();
    };

    const onStartChange = value => {
        setStartDate(value.format("MM-DD-YYYY"))
        setStartMoment(value)
        setIsFiltered(true)
    }

    const onEndChange = value => {
        setEndDate(value.format("MM-DD-YYYY"))
        setEndMoment(value)
        setIsFiltered(true)
    }
    
    const handleStartOpenChange = open => {
        if (!open) {
          setEndOpen(true)
        }
    }
    
    const handleEndOpenChange = open =>{
        setEndOpen(open)
    }

    const clearFilter = () => {
        setFrequency("All")
        setStartDate("")
        setEndDate("")
        setSearchVal("")
        setIsFiltered(false)

        fetchData(1, 10, "", "", "All", "")
    }

    const applyFilter = () => {
        fetchData(1, 10, startDate, endDate, frequency, searchVal)
    }

    const changePage = (event,page) => {
        setCurrentPage(page)
        fetchData(
            page,
            pageSize,
            startDate,
            endDate,
            frequency,
            searchVal
        )
    }

    return(
        <div>
            <TestModeBanner width={'100vw'} top={64} right={0} />
            <div style={{padding: testMode? '100px 42px 0px 42px':'80px 42px 0px 42px', width: '100%'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex'}}>
                        <Icon type="arrow-left" 
                            style={{color: `${segmentsColor[segments].color}`, fontSize: '20px', height: '25px', marginRight: '10px'}} 
                            onClick={()=>history.push(testMode? '/test/orders':'/orders')}
                        />
                        <div style={styles.title}>Payment request schedule</div>
                    </div>
                    <div style={{display: 'flex', marginTop: '-5px'}}>
                        {/* <Button 
                            onMouseEnter={()=>setHoverImport(true)}
                            onMouseLeave={()=>setHoverImport(false)}
                            style={{...styles.importBtn, 
                                backgroundColor: hoverImport ? '#F5922F' : 'rgba(76, 175, 80, 0)', 
                                color: hoverImport ? '#FFF': '#F5922F', marginRight: '10px'}}>
                            Import batch list
                        </Button> */}
                        <button className={`outline-btn--${segments}`} style={styles.importBtn} onClick={()=>setSchedModalVisibility(true)}> Add new schedule</button>
                    </div>
                </div>
                <div style={{width: '100%', padding: '32px', backgroundColor: '#FFF', marginTop: '28px'}}>
                    <div style={styles.subContainer}>
                        <div style={styles.clearDiv}>
                            <div style={styles.filterText}>Filter</div>
                            <a style={styles.clearBtnDiv} hidden={!isFiltered} onClick={clearFilter}>
                                <Icon style={styles.clearIcon} type="close-circle" theme="filled" />
                                <span style={styles.clearBtn}>Clear Filter</span>
                            </a>
                        </div>
                        {/* <div style={styles.reminder}><Icon type="exclamation-circle" theme="filled" /> Click <b>Apply</b> button to show results.</div> */}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '0 0 24px 0' }}>
                        <Select
                            value={frequency}
                            size="large"
                            style={{width: '149px', zIndex: 1, color: '#000'}}
                            onChange={(e)=>{
                                setFrequency(e);
                                setIsFiltered(true)
                            }}
                            >
                            <Option value="All">All</Option>
                            <Option value="DA">Daily</Option>
                            <Option value="WE" >7 Days</Option>
                            <Option value="HM" >15 Days</Option>
                            <Option value="MO">30 Days</Option>
                        </Select>   
                        <DatePicker
                            format='MM/DD/YYYY'
                            disabledDate={disabledStartDate}
                            // defaultValue={moment()}
                            value={startMoment}
                            size="large" picker="month"
                            onChange={onStartChange}
                            onOpenChange={handleStartOpenChange}
                            placeholder='Start date'
                            style={styles.datePic}
                            suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                            allowClear={false} />
                        <span style={{ paddingTop: 8 }}>-</span>
                        <DatePicker
                            format='MM/DD/YYYY'
                            disabledDate={disabledEndDate}
                            // defaultValue={moment()}
                            value={endMoment}
                            size="large"
                            picker="month"
                            onChange={onEndChange}
                            onOpenChange={handleEndOpenChange}
                            open={endOpen}
                            placeholder='End date'
                            style={styles.datePic}
                            suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                            allowClear={false} />
                        <Input
                            placeholder="Search"
                            style={{ width: '218px', height: '40px', borderRadius: '4px', padding: '0 8px 0 0' }}
                            suffix={<Icon type="search" style={{ fontSize: '18px', marginRight: '8px'}} />}
                            onChange={(e)=>{
                                setSearchVal(e.target.value);
                                setIsFiltered(true)
                            }}
                            value={searchVal}
                        />
                        <button
                            className={`btn--${segments}`} 
                            style={{...styles.applyBtn, opacity: 1}} 
                            onClick={()=>applyFilter()}
                        >Apply</button>
                    </div>
                    <ScheduleTable
                        data={data ? data : []}
                        fetchData={()=>fetchData(1,10,"","","All","",testMode)}
                    />
                    {
                        data && data.length > 0 &&
                        <div style={{padding: '20px', display:'flex', justifyContent:'space-between'}}>
                            <div>
                                {(currentPage - 1) * pageSize  + 1} 
                                -
                                {(currentPage - 1) * pageSize  + summary.page_total} out of {summary.total}
                            </div>
                            <div style={{display: 'flex'}}>
                                <Select 
                                size="small" 
                                style={{width: '100px'}} 
                                value={pageSize}
                                onChange={(e)=>
                                {
                                    setPageSize(e);
                                    fetchData(
                                        1,
                                        e,
                                        startDate,
                                        endDate,
                                        frequency,
                                        searchVal
                                    )
                                }
                                }>
                                    <Option value={5}>Show 5</Option>
                                    <Option value={10}>Show 10</Option>
                                    <Option value={15}>Show 15</Option>
                                    <Option value={20}>Show 20</Option>
                                </Select>
                                <Pagination 
                                    count={summary.pages} 
                                    shape="rounded"
                                    page={currentPage} 
                                    size="small"
                                    onChange={changePage}
                                />
                            </div>
                        </div>
                    }
                    <ScheduleModal visibility={schedModalVisibility} hideModal={()=>setSchedModalVisibility(false)} fetchData={()=>fetchData(1, pageSize, startDate,endDate, frequency, searchVal)}/>
                </div>
            </div>
        </div>
    )
}

const styles = {
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#2b2d33',
        lineHeight: '20px'
    },
    arrowStyle: {
        height: '23px',
        width: '23px',
        transform: 'rotate(180deg)',
        marginRight: '10px',
        fontWeight: '500',
        fontSize: '16px'
    },
    importBtn: {
        borderRadius: '4px',
        height: '40px',
        fontWeight: '500',
        fontSize: '16px',
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    applyBtn: {
        height: 40,
        width: 92,
        fontSize: 16,
        fontWeight: '600',
        borderRadius: 4,
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 158,
        padding: '0 8px 0 8px'
    },
    subContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 0'
    },
    clearDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    clearIcon: {
        color: '#2B2D33',
        fontSize: 10,
    },
    filterText: {
        color: '#000',
        fontSize: 14,
        fontWeight: 'bold'
    },
    clearBtnDiv: {
        backgroundColor: '#E6EAF0',
        borderRadius: 24,
        padding: '0px 12px 2px 12px',
        margin: '0 12px'
    },
    clearBtn: {
        color: '#000000',
        fontSize: 12,
        paddingLeft: 4,
    },
}

export default PaymentSchedules