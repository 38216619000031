import React from 'react'
import FeeStyles from '../../style'

const HeaderCard = props => {
    
    const fee = FeeStyles();
    const userType = localStorage.getItem("userType");
    return(
        <div>

            <div className={fee.spaceBetween}>
                <div className={fee.cardLeftDiv}>
                    <div className={fee.headerText}>
                        { userType == "PF" || userType == "PS" ? "Merchant Onboarding" : "Endorse Merchant"}
                    </div>
                    <div className={fee.normalText} style={{marginTop: 8}}>
                        { userType == "PF" || userType == "PS" ? "Approve Merchant and set partner config" : 
                            "Give Partner access to merchant details and endorse for approval"}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderCard