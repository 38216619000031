import { makeStyles, useMediaQuery } from '@material-ui/core'


 const userStyles = makeStyles(theme => ({

    //DIV 

    mainDiv: {
        marginTop: 64,
        fontFamily: 'Inter',
        paddingLeft: 32,
        paddingRight: 32
    },

    mobileMainDiv: {
        padding: 0,
        width: '100%'
    },

    twoCol: {
        marginBottom: 8,
        justifyContent: 'space-between',
        display: 'flex',
    },

    newBtn: {
        background: '#1DD28B',
        borderRadius: '10px',
        width: '176px',
        height: '48px',
        border: '1px solid #1dd28b',
        color: '#fff',
        fontWeight: 'bold',
        cursor: 'pointer',
        outline: 0,
        '&:hover' : {
            opacity: '0.8',
        },
        [theme.breakpoints.down("sm")]: {
            height: '40px',
            width: '163px',
            fontSize: 16,

        }

    },

    editBtn : {
        width: 172,
        height: '40px',
        background: '#F5922F',
        border: '1px solid #f5922f',
        borderRadius: '10px',
        color: '#fff',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
        outline: 0,
        '&:hover': {
            opacity: 0.8,
            cursor: 'pointer'
        },

        '&:active': {
            background: '#DA7F24',
            opacity: 1

        }
    },

    disabledBtn : {
        width: 110,
        height: '40px',
        background: '#F5922F',
        border: '1px solid #f5922f',
        borderRadius: '10px',
        color: '#fff',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
        outline: 0,
        cursor: 'not-allowed',
        opacity: '0.5',
    },

    cancelBtn: {
        width: 172,
        height: '40px',
        background: '#fff',
        border: '1px solid #f5922f',
        borderRadius: '10px',
        color: '#f5922f',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
        outline: 0,
        '&:hover': {
            background: '#f5922f',
            cursor: 'pointer',
            color: '#fff',
        },

        '&:active': {
            background: '#DA7F24',
            opacity: 1

        }
    },

    learnCard: {
        marginTop: 32,
        background: '#fff',
        borderRadius: '10px',
        border: '1px solid #fff',
        width: 300,
        paddingRight: '16px',
        height: '70px'
    },

    scopeCard: {
        width: 700,
    },  

    headerDiv: {
        color: '#000000',
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'center',
        background: '#D1D5DD',
        height: '44px',
        padding: '8px 32px 8px 32px',
        width: '120px'
    },

    dataDiv: {
        color: '#2B2D33',
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'center',
        background: '#fff',
        borderBottom: '1px solid #D1D5DD',
        height: '44px',
        padding: '8px 32px 8px 32px',
        width: '120px'
    },

    scopeTable: {
        marginTop: 32,
    },

    capsule: {
        padding: '4px',
        borderRadius: '40px',
    },  

    backBtn: {
        color: '#F5922F',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight:'24px',
        height: '40px',
        width: 100,
        border: '1px solid #fff',
        background: '#FFFFFF',
        cursor: 'pointer',
        outline: '0',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.102751)',
        borderRadius: '40px',
        '&:hover': {
         color: '#fff',
         border: '1px solid #F5922F',
         background: '#F5922F',
        }
    },

    headers: {
        fontSize: 28,
        lineHeight: '32px',
        fontWeight: 'bold',
        color: '#000000',
    },

    learnText: {
        marginTop: '20px',
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: 600,
        color: '#2B2D33',
    },

    link: {
        color: '#F5922F',
        fontWeight: 600,
        fontSize: '12px'
    },

    mobileCard: {
        background: '#fff',
        borderRadius: '10px',
        marginTop: 16,
    },

    mobileUserName: {
        fontWeight: 'bold',
        lineHeight: '24px',
        color: '#000000'
    },

    mobileContact: {
        lineHeight: '24px',
        color: '#000000'
    },

    mobileCardFooterDiv: {
        borderTop: '1px solid #E6EAF0',
        padding: 16,
    },

    nothingToShow: {
        color: '#D1D5DD',
        marginTop: 16,
        fontSize: 14,
        lineHeight: '16px',
        fontWeight: 'bold',
        width: '100%',
        height: '217px',
        border: '1px solid #e7eaf0',
        borderShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        boxSizing: 'border-box',
        borderRadius: '4px',
        background: '#fff',
        padding: 100,
    }

}))

export default userStyles