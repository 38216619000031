export const INITIAL_FORM_DATA = {
  sender_name: "",
  sender_gender: "",
  sender_address: "",
  sender_mobile_number: null,
  sender_email: null,
  sender_source_fund: "",
  purpose: "",
  receiver_amount: "",
  receiver_account_type: "",
  receiver_bank: "",
  code: "",
  receiver_account_number: "",
  receiver_account_name: "",
  receiver_gender: "",
  receiver_address: "",
  receiver_mobile_number: null,
  receiver_email: null,
};
