import React from 'react'
import { Modal, Select, Input, Alert, message } from 'antd'
import feeStyle from '../../components/AdminComponents/userAccount/style'
import { KYB_ROLE } from '../../components/constants/constants';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from "@material-ui/icons/VisibilityOutlined";
import VisibilityOff from "@material-ui/icons/Visibility";

const { Option } = Select

const NewUserModal = props => {

    const fee = feeStyle()

    const roles = KYB_ROLE.map((item, key) => {
        return (
            <Option key={key} value={item}>{item}</Option>
        )
    })

    const [role, setRole] = React.useState('')
    const [contact, setContact] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [fname, setFirstName] = React.useState('')
    const [lname, setLastname] = React.useState('')
    const [password1, setPassword1] = React.useState('')
    const [password2, setPassword2] = React.useState('')
    const [errorMessage, setErrorMessage] = React.useState('')
    const [showError, setShowError] = React.useState(true)
    const [showPassword1, setShowPassword1] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);
    const [pwMatch, setPwmatch] = React.useState(true);
    const segments = localStorage.getItem('segments');


    function handleSelectChange(e) {
        setRole(e)
    }

    function confirmPassword(e) {
        setPassword2(e)

        if (password1 !== e) {
            setPwmatch(false)
        }

        else {
            setPwmatch(true)
        }
    }

    function errorBox(errorMsg) {
        setShowError(false);
        setErrorMessage(errorMsg)
        setTimeout(() => {
            setShowError(true);
        }, 5000);
        return
    }

    function checkError() {

        if (email === "") {
            errorBox("Email is required")
        }
        else if (contact === "") {
            errorBox("Mobile Number is required")
        }
        else if (fname === "") {
            errorBox("First Name is required")
        }
        else if (lname === "") {
            errorBox("Last Name is required")
        }
        else if (password1 === "" || password2 === "") {
            errorBox("Password is required")
        }

        else if (!pwMatch) {
            errorBox("Password not matched")
        }

        else {
            let params = {
                email,
                contact,
                username: email,
                first_name: fname,
                last_name: lname,
                password: password1,
            }

            if (localStorage.getItem('userType') !== "CO") {
                params.parent_id = props.id
            }

            props.submitForm(params)
        }

    }

    // async function submitForm(params){

    //     try{

    //         let res = await settingServices.addChildAccount(params)

    //         if(res.status == "failed"){
    //             errorBox(res.message)
    //         }

    //         else {
    //             setEmail("")
    //             setContact("")
    //             setFirstName("")
    //             setLastname("")
    //             setPassword1("")
    //             setPassword2("")
    //             props.closeModal()
    //             message.success("User Added Successfully")
    //             props.getCorporate()
    //         }

    //     }

    //     catch(error){
    //         console.log(error.message)
    //     }
    // }

    React.useEffect(() => {
        setEmail("")
        setContact("")
        setFirstName("")
        setLastname("")
        setPassword1("")
        setPassword2("")
        props.getCorporate()
    }, [props.showModal])

    return (
        <Modal
            centered
            width={400}
            visible={props.showModal}
            onCancel={() => props.closeModal()}
            footer={null}>
            <div style={{
                fontSize: 24,
                lineHeight: '32px',
                fontWeight: '300',
                color: '#2b2d33'
            }}>Add User</div>

            <div style={{ marginTop: 16 }} hidden={showError}>
                <Alert style={{ borderLeft: '4px solid red' }} type="error" message={errorMessage} />
            </div>

            <div className={fee.twoCol} style={{ marginTop: '22px' }}>
                <div>
                    {/* Role
                    <div>
                        <Select 
                            size="large" 
                            placeholder="Role" 
                            style={{width:'170px',}}
                            onChange={(e)=>handleSelectChange(e)}
                            >
                                {roles}
                        </Select>
                    </div> */}
                    Email
                    <div>
                        <Input
                            value={email}
                            placeholder="Email Address"
                            style={{ width: '170px', height: '40px' }}
                            onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>
                <div>
                    Mobile Number
                    <div>
                        <Input
                            value={contact}
                            addonBefore="+63"
                            size="large"
                            type="number"
                            style={{ width: '170px' }}
                            onChange={(e) => {
                                if (e.target.value.length <= 10) {
                                    setContact(e.target.value)
                                }
                            }} />
                    </div>
                </div>
            </div>

            <div className={fee.twoCol} style={{ marginTop: '22px' }}>
                <div>
                    First Name
                    <div>
                        <Input
                            value={fname}
                            maxLength={30}
                            placeholder="First Name"
                            style={{ width: '170px', height: '40px' }}
                            onChange={(e) => setFirstName(e.target.value)} />
                    </div>
                </div>
                <div>
                    Last Name
                    <div>
                        <Input
                            value={lname}
                            maxLength={30}
                            placeholder="Last Name"
                            style={{ width: '170px', height: '40px' }}
                            onChange={(e) => setLastname(e.target.value)} />
                    </div>
                </div>
            </div>

            <div style={{ marginTop: 20 }}>
                <Input
                    value={password1}
                    type={showPassword1 ? 'text' : 'password'}
                    placeholder="Password"
                    style={{ width: '100%', height: '40px' }}
                    onChange={(e) => setPassword1(e.target.value)}
                    suffix={
                        <a className={`link-text--${segments}`} style={{fontSize: 12, fontWeight: '500'}} onClick={() => setShowPassword1(!showPassword1)}>
                            {showPassword1 ? 'Hide' : 'Show'}
                        </a>
                    }
                />
            </div>

            <div style={{ marginTop: 20 }}>
                <Input
                    value={password2}
                    type={showPassword2 ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    style={{ width: '100%', height: '40px', border: !pwMatch ? '1px solid red' : '' }}
                    onChange={(e) => confirmPassword(e.target.value)}
                    suffix={
                        <a className={`link-text--${segments}`} style={{fontSize: 12, fontWeight: '500'}} onClick={() => setShowPassword2(!showPassword2)}>
                            {showPassword2 ? 'Hide' : 'Show'}
                        </a>
                    }
                />
                <div hidden={pwMatch} style={{ color: 'red' }}>Password Not Match!</div>
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 20
            }}>
                <div style={styles.footerDiv}>
                    <button className={`outline-btn--${segments}`} style={{ ...styles.editBtn, margin: '0 4px', width: 70 }} onClick={() => props.closeModal()}>Cancel</button>
                    <button className={`btn--${segments}`} onClick={() => checkError()}
                        style={{ cursor: 'pointer', margin: 0, height: 34 }}>Confirm</button>
                </div>

            </div>

        </Modal>
    )
}

const styles = {
    editBtn: {
        cursor: 'pointer',
        height: 34,
        padding: '0 12px',
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        // width: 174,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    footerDiv: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
}

export default NewUserModal