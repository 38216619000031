import React, { useState, useEffect } from "react";
import { 
  Button, 
  Modal, 
  message,
} from "antd";
import { TextField, Grid } from "@material-ui/core";
import "./css/merchant_id_settings.css";
import MerchantIDSettingsViewModel from "./merchant_id_settings_viewmodel";

const MerchantIDSettingsModal = (props) => {
  const [isTouched, setIsTouched] = useState(false);

  const [formFieldData, setFormFieldData] = useState({
    channelCode: "",
    channelName: "",
    defaultValue: "",
    defaultMetadata: "",
    id: null,
  });

  const {
    createChannelSubmerchantIDSettings,
    updateChannelSubmerchantIDSettings,
    setDisplayMessage,
    isSuccess,
    messageString,
    displayMessage,
  } = MerchantIDSettingsViewModel();

  const {
    editData,
    openModal,
    closeModal,
    submitModal,
  } = props;

  async function submitCategory() {
    if (
      formFieldData.channelCode != "" 
    ) {
      let params = {
        default_value: formFieldData.defaultValue,
        default_metadata: formFieldData.defaultMetadata,
        channel_name: formFieldData.channelName,
      };

      if (editData) {
        updateChannelSubmerchantIDSettings(params, formFieldData.id);
      } else {
        createChannelSubmerchantIDSettings({...params, channel_code: formFieldData.channelCode});
      }
    } else {
      setIsTouched(true);
    }
  }

  async function clearForm() {
    setFormFieldData({
      channelCode: "",
      channelName: "",
      defaultValue: "",
      defaultMetadata: "",
      id: null,
    });
    setIsTouched(false);
  }

  function updateFields(fields) {
    setFormFieldData({ ...formFieldData, ...fields });
  }

  useEffect(() => {
    if (editData) {
      setFormFieldData({
        channelCode: editData.channel_code,
        channelName: editData.channel_name,
        defaultValue: editData.default_value,
        defaultMetadata: editData.default_metadata ? JSON.stringify(editData.default_metadata) : "",
        id: editData.id,
      });
    }
  }, [editData]);

  useEffect(() => {
    if (!displayMessage) {
      return;
    }

    if (isSuccess) {
      message.success(messageString, 3, () => { setDisplayMessage(false) });
      clearForm();

      if (editData) {
        closeModal();
      } else {
        submitModal();
      }
    } else {
      message.error(messageString, 3, () => { setDisplayMessage(false) });
    }
  }, [displayMessage]);

  function displayTextField(label, onChange, value, disabled, className = "") {
    return (
      <Grid item={true} className={className}>
        <TextField
          id="outlined-basic"
          label={label}
          disabled={disabled}
          margin="normal"
          variant="outlined"
          fullWidth
          className={isTouched && value == "" ? `error-field` : ``}
          onChange={onChange}
          value={value}
        />
      </Grid>
    );
  }

  return (
    <Modal
      title={
        editData ? "Edit Merchant ID Settings" : "Add Merchant ID Settings"
      }
      centered
      visible={openModal}
      bodyStyle={{ maxHeight: '400px', overflowY: 'auto' }}
      onCancel={() => {
        clearForm();
        closeModal();
      }}
      maskClosable={false}
      footer={[
        <Button
          key="back"
          onClick={() => {
            clearForm();
            closeModal();
          }}
          className="secondary-btn"
        >
          Cancel
        </Button>,
        <Button
          className="primary-btn"
          key="submit"
          type="primary"
          onClick={() => submitCategory()}
        >
          {editData ? "Update" : "Add"}
        </Button>,
      ]}
      className="merchant-category-modal"
    >
      <Grid container>
        <div className="field-row-container">
          {displayTextField(
            "Channel Code",
            (e) => updateFields({ channelCode: e.target.value }),
            formFieldData.channelCode,
            editData ? true : false,
            "full-width-grid-item",
          )}
        </div>

        <div className="field-row-container">
          {displayTextField(
            "Channel Name",
            (e) => updateFields({ channelName: e.target.value }),
            formFieldData.channelName,
            false,
            "full-width-grid-item",
          )}
        </div>

        <div className="field-row-container">
          {displayTextField(
            "Default Value",
            (e) => updateFields({ defaultValue: e.target.value }),
            formFieldData.defaultValue,
            false,
            "full-width-grid-item",
          )}
        </div>

        <div className="field-row-container">
          {displayTextField(
            "Default Metadata",
            (e) => updateFields({ defaultMetadata: e.target.value }),
            formFieldData.defaultMetadata,
            false,
            "full-width-grid-item",
          )}
        </div>
        
      </Grid>
    </Modal>
  );
};

export default MerchantIDSettingsModal;
