import React from "react";
import { Button } from "antd";

const ButtonGroup = Button.Group;

export function GroupButton({ options = [], selectedOption, onSelect }) {
  return (
    <ButtonGroup>
      {options.map((option, index) => (
        <Button
          key={index}
          type={option === selectedOption ? "primary" : "default"}
          size="large"
          onClick={() => onSelect(option)}
          style={{ fontSize: "12px", fontWeight: "bold" }}
        >
          {option}
        </Button>
      ))}
    </ButtonGroup>
  );
}
