import React, {useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from "@material-ui/icons/VisibilityOutlined";
import VisibilityOff from "@material-ui/icons/Visibility";
import { Button, Divider, Checkbox } from 'antd';
import facebook_icon from '../../../static/icons/facebook.png'
import google_icon from '../../../static/icons/google.png'
import { useDispatch, useSelector } from "react-redux";
import {isMobile} from 'react-device-detect';
import { sign_up, fb_sign_up, google_sign_up } from "../signUpActions";
import SocialButton from '../../social/socialButton'
import {showAlert} from '../../alert/alertAction'
import BuxAlert from '../../alert/alert'
import BuxModal from '../../BuxModal/modal'
import {NATURE_BUSINESS } from '../../constants/constants';
import * as Style from './signUpStyle'


const SignUpPromo = props => {

    const dispatch = useDispatch();
    const signUpLoading = useSelector(state => state.signup.loading)
    const [email, setEmail] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [mobileNumber, setMobileNumber] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [showPassword, setShowPassword] = React.useState("");
    const [natureBusiness, setNatureBusiness] = React.useState("");
    const [promo, setPromo] = React.useState(0);
    const [disable, setDisable] = React.useState(true);

    function submitCreds() {
        let params = {
          email: email,
          password: password,
          firstName: firstName,
          lastName: lastName,
          natureOfBusiness: natureBusiness,
          promo,

        };

        console.log(params)

        if(password.length < 8){
            dispatch(showAlert({ type: "error", message: "Password must contain atleast 8 characters" }));
        }
        else if(firstName.length <= 0){
            dispatch(showAlert({ type: "error", message: "Fill up your first name" }));
        }
        else if(lastName.length <= 0){
            dispatch(showAlert({ type: "error", message: "Fill up your last name" }));
        }
        else if(password.search(/^[a-zA-Z]+$/) != -1){
            dispatch(showAlert({ type: "error", message: "Password must contain atleast 1 number" }));
        }
        else if(password.search(/^[a-z0-9]+$/) != -1){
            dispatch(showAlert({ type: "error", message: "Password must contain atleast 1 capital letter" }));
        }
        else if(password.search(/^[A-Z0-9]+$/) != -1){
            dispatch(showAlert({ type: "error", message: "Password must contain atleast 1 small letter" }));
        }
        else if(natureBusiness.length <= 0){
            dispatch(showAlert({ type: "error", message: "Please select nature of business" }));
        }
        else{            
            dispatch(sign_up(params));
        }
      }

    function fbSubmitCreds(token) {
        token.messenger_id = window.fbuid;
        dispatch(fb_sign_up(token));
      }

    function acceptTerms() {
        setDisable(!disable)

    }


    function googleSubmitCreds(token) {
        token.messenger_id = window.fbuid;
        dispatch(google_sign_up(token));

      }

    const handleFBSocialLogin = (user) => {
        fbSubmitCreds(user.token);
    }

    const handleGoogleSocialLogin = (user) => {
        googleSubmitCreds(user.token)
    }
     
    const handleSocialLoginFailure = (err) => {
      console.error(err)
    }

   function loadFbPlugin() {
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'Messenger'));
        loadMessengerExt();
    }



   function loadMessengerExt() {

        window.extAsyncInit = function () {
             window.MessengerExtensions.getUserID(function success(uids) {
                window.fbuid = uids.psid;
            }, function error(err, errorMessage) {
                window.fbuid = '';

            });
        
        };

    }

    const handleNatureBusiness = e => {
        setNatureBusiness(e.target.value);
    };


    useEffect(() => {
        window.fbuid = ''
        if(isMobile){
            window.location.href = '/mobile/signup'
        }
        else{
            if(localStorage.getItem("jwtToken") != "" && localStorage.getItem("jwtToken") != null){
                window.location.href = '/mobile/dashboard'
              }
            else{
                loadFbPlugin();
                localStorage.setItem("jwtToken", "");
                localStorage.setItem("balance", "0 PHP");
            }
        }

    }, [])

    const natures = NATURE_BUSINESS;
    natures.sort();
        


    return(

        <div style={{justifyContent: 'center', alignItems:'center', paddingBottom: '20px'}}>
            <div style={Style.createStyle}>Create an account</div>
            <BuxAlert/>
            {/* <div style={{width: '100%', marginTop: '10px'}}>
                <Alert message="Sa" type="error" style={{marginBottom: '20px'}} showIcon/>
            </div> */}
            <div style={{display: 'flex', marginBottom: '23px', marginTop: '23px'}}>
            <TextField
                id="outlined-name"
                label="First Name"
                onChange={e => setFirstName(e.target.value)}
                variant="outlined"
                style = {{
                    height: '100%',
                    width: '49%',
                    marginRight: '2%',
                }}
            />

            <TextField
                id="outlined-name"
                label="Last Name"
                onChange={e => setLastName(e.target.value)}
                variant="outlined"
                style = {{
                    height: '100%',
                    width: '49%',
                }}
            />

            </div>
            
            <div>

            <TextField
                id="outlined-name"
                label="Email"
                onChange={e => setEmail(e.target.value)}
                variant="outlined"
                style = {{
                    height: '100%',
                    width: '100%',
                    marginBottom: '23px'
                }}
            />
            {/*
            <TextField
                id="outlined-name"
                label="Mobile number"
                onChange={e => setMobileNumber(e.target.value)}
                variant="outlined"
                style = {{
                    height: '100%',
                    width: '100%',
                    marginBottom: '23px'
                }}
            /> */}
            <TextField
                id="outlined-adornment-password"
                onChange={e => setPassword(e.target.value)}
                variant="outlined"
                style = {{
                    height: '100%',
                    width: '100%',
                    marginBottom: '23px'
                }}
                type={showPassword ? 'text' : 'password'}
                label="Password"
                InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                    <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={e => setShowPassword(!showPassword)}
                        onMouseDown={e => setShowPassword(showPassword)}
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    </InputAdornment>
                ),
                }}
            />

            {/* <FormControl variant="outlined" style={{width: '100%', marginBottom: '10px'}}>
                <InputLabel id="demo-simple-select-outlined-label">
                    Nature of Business
                </InputLabel>
                <Select
                    id="demo-simple-select-outlined"
                    onChange={handleNatureBusiness}
                    style={{
                        width: '100%'
                    }}
                    value={natureBusiness}
                    labelWidth={140}
                > 
                    {
                        natures.length > 0 && natures.map((item, i) => {
                            return (
                                <MenuItem key={i} value={item}>{item}</MenuItem>
                            )
                        }, this)
                    }
                </Select>
            </FormControl> */}
            
            <div style={{fontWeight: 'bold', fontSize: 16, color: '#000000'}}>Have a promo code?</div>
            <TextField
                id="outlined-name"
                label="Promo code"
                onChange={e => setPromo(e.target.value)}
                variant="outlined"
                style = {{
                    marginTop: '10px',
                    width: '100%',
                }}
            />
            <div style={{marginTop: 5, fontSize: 12, color: '#2b2d33', opacity: 0.8, marginBottom: '16px', wordWrap: 'break-word'}}>The amount of the Promo code will be added to your funds immediately.</div>

            <div style={{textAlign: 'left'}}>
               <span>
                <Checkbox style={{paddingRight: '10px', marginTop: '3px'}} onClick={acceptTerms}/>
                    <span style={Style.bySignStyle}>By signing up, I agree with the </span>
                    <a href="/terms" style={Style.bySignStyle}>
                    <strong><span style={{color: '#F5922F'}}>Terms and Conditions</span></strong>
                </a>
                </span>
            </div>
            </div>
            
            <div><Button loading={signUpLoading} style={!disable ? Style.signupBtnStyle : Style.signupBtnStyleDisable} onClick={() => submitCreds()} disabled={disable}>Sign up</Button></div>

            <Divider><span>or</span></Divider>
            
            <div style={{ display: "flex" }}>
                <SocialButton
                  provider='facebook'
                  appId='195672864228468'
                  onLoginSuccess={handleFBSocialLogin}
                  onLoginFailure={handleSocialLoginFailure}
                  style={Style.facebookBtnStyle}
                >
            <img src={facebook_icon} style={Style.icon_style} alt="#"/>Facebook</SocialButton>
                <SocialButton
                  provider='google'
                  appId='998270812585-n505g6elcp4gvijo16pt7d2koj1aj4je.apps.googleusercontent.com'
                  onLoginSuccess={handleGoogleSocialLogin}
                  onLoginFailure={handleSocialLoginFailure}
                  style={Style.googleBtnStyle}> <img src={google_icon} style={Style.icon_style} alt="#"/>Google</SocialButton>
            </div>
            
            <div style={{display:'flex', justifyContent: 'center'}}>
                <span style={Style.dontHaveStyle}>Already have an account?  <a href='/login' style={{ color: "#F5922F" }}>&nbsp;Login</a></span>
            </div>

            <BuxModal/>
        </div>
    )
    
}

export default SignUpPromo