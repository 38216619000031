import React,{ useState } from 'react';
import { Modal, Typography, Button, Icon, Table, message } from 'antd';
import SuccessModal from './successTagModal';
import fraudServices from '../fraudServices';

const TaggedViewModal = ({visible, closeModal, tagData, selected}) => {
  const [btnStyle, setBtnStyle] = useState({color: '#F5922F', backgroundColor: '#FFF' })
  const [successModal, setSuccessModal] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleMouseOver = () => {
    setBtnStyle({
      backgroundColor: '#F5922F',
      color: '#FFF'
    })
  }

  const handleMouseLeave = () => {
    setBtnStyle({
      backgroundColor: '#FFF',
      color: '#F5922F'
    })
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'merchant_id',
      visible: true
    },
    {
      title: 'Business Name',
      dataIndex: 'business_name',
      visible: true
    },
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      visible: selected === 'Individual Account' ? true : false,
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      render: (text, record) => <div style={{whiteSpace: 'pre-wrap', maxWidth: 100}}>{text}</div>,
      visible: true
    },
    {
      title: 'Mobile Number',
      dataIndex: 'contact',
      visible: true,
      render: (text, record) => '+63' + text,
    },
    {
      title: 'Level',
      dataIndex: 'eon_level',
      render: (text, record) => <div style={{...styles.level, backgroundColor: returnBGColor(text)}}>{returnLevel(text)}</div>,
      visible: selected === 'Individual Account' ? true : false,
    },
    {
      title: 'Action',
      render: (text, record) => (
       <a style={styles.actionLink} onClick={() => window.open(`/${selected==='Individual Account' ? 'submissions' : 'submissions_kyb'}/${record.link_id}/`,'_blank')}>View</a>
      ),
      visible: true
    }
  ]

  const returnBGColor = (level) => {
    switch(level) {
      case 0:
        return '#65C1F2';
      case 1:
        return '#38ADA9';
      case 2:
        return '#1E3799';
      case 3: 
        return '#FFB142';
      default:
        return '#65C1F2';
    }
  }

  const returnLevel = (level) => {
    switch(level) {
      case 0:
        return 'Basic';
      case 1:
        return 'Pro';
      case 2:
        return 'Advanced';
      case 3: 
        return 'Business';
      default:
        return 'Basic';
    }
  }

  const makeArray = (data) => {
    let arr = new Array;
    if(data) {
      arr.push({
        link_id: selected === 'Individual Account' ? data.kyc_id : data.user_id,
        merchant_id: data.merchant_id,
        business_name: data.business_name,
        full_name: data.full_name,
        email: data.email,
        contact: data.contact,
      })
    }
    return arr;
  }

  const returnTagId = () => {
    if(selected==='Individual Account') {
      return tagData && parseInt(tagData.tagged_kyc);
    }
    return tagData && parseInt(tagData.tagged_kyb);
  }

  const getTableData = async () => {
    setModalLoading(true);
    try {
      const res = await fraudServices.getUsersToTag(selected==='Individual Account' ? 'KYC' : 'KYB', 'id', returnTagId() ?returnTagId() : undefined);
      setTableData(makeArray(res));
      setModalLoading(false);
    } catch (error) {
      console.log(error);
      setModalLoading(false);
    }
  }

  const errorPrompt = (msg) => {
    message.error(msg);
  };

  const untagUser = async () => {
    setLoading(true);
    let params = {
      action: 'remove',
      blacklist_id: tagData.id,
    }

    selected === 'Individual Account' ? 
      params['kyc_id'] = tableData.kyb_id 
      : 
      params['kyb_id'] = tableData.kyb_id;

    try {
      const res = await fraudServices.tagToggle(selected === 'Individual Account' ? 'kyc' : 'kyb', params);
      if(res.status === 'success'){
        setSuccessModal(true);
      } else {
        errorPrompt(res.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorPrompt('There was an error try again');
      setLoading(false);
    }
  }

  React.useEffect(() => {
    tagData && getTableData();
  },[tagData])

  return (
    <Modal 
      visible={visible} 
      bodyStyle={styles.root} 
      footer={null}
      centered
      width={917}
      onCancel={closeModal}
      closeIcon={<Icon type="close" style={{color:'#F5922F'}}/>}
      loading={modalLoading}
    >
      <Typography style={styles.title}>{selected === 'Individual Account' ? 'KYC' : 'KYB'} Tagged to:</Typography>
      <Typography style={styles.userName}>{selected === 'Individual Account' && tagData ? tagData.first_name + ' ' + tagData.last_name : tagData&&tagData.business_name }</Typography>
      <div style={{width: '100%', overflowY: 'auto'}}>
        <Table
          rowClassName="table-row-light"
          columns={columns.filter(col => col.visible === true)}
          dataSource={tableData ? tableData : []}
          bodyStyle={{width: '100%'}}
          pagination={false}
        />
      </div>
      <div style={styles.mainBtn}>
        <Button style={{...styles.btn, borderColor: '#F5922F', marginRight: 12, ...btnStyle}} 
          onMouseOver={handleMouseOver} 
          onMouseLeave={handleMouseLeave} 
          onClick={closeModal}
          loading={loading}>
          Cancel
        </Button>
        <Button style={{...styles.btn, color: '#FFF', backgroundColor: '#E24C4C'}} 
          onClick={untagUser}
          loading={loading}>
          Remove
        </Button>
      </div>
      <SuccessModal visible={successModal} closeModal={() => setSuccessModal(false)/closeModal()} message='Removed' />
    </Modal>
  )
}

const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 24,
    color: '#2B2D33',
    fontWeight: '300',
    textAlign: 'left',
    padding: '24px 0 0px 0'
  },
  msg: {
    fontSize: 14,
    color: '#000',
    textAlign: 'left',
    padding: '10px 0'
  },
  mainBtn: {
    width: '100%',
    textAlign: 'right',
    padding: '16px 0'
  },
  btn: {
    height: 48,
    width: 172,
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 10,
  },
  userName: {
    fontSize: 14,
    color: '#000',
    textAlign: 'left',
    fontWeight: 'bold',
    paddingBottom: 16
  },
  actionLink: {
    color: '#F5922F', 
    fontWeight: '500', 
    fontSize: 14,
    textDecoration: 'underline'
  },
  level: {
    borderRadius: 40,
    color: '#FFF',
    fontSize: 12,
    textAlign: 'center',
    padding: 4,
    width: 76,
    fontWeight: '400',
  }
}

export default TaggedViewModal;