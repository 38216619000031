import { ModalActionTypes } from "./modalAction";
const initialState = {
  showModal: false,
  title: "",
  message: ""
};
export default function modalReducers(state = initialState, action) {
  switch (action.type) {
    case ModalActionTypes.SHOW_MODAL:
      return {
        ...state,
        showModal: true,
        title: action.payload.title,
        message: action.payload.message,
        returnType: action.payload.returnType,
        showResendLink: action.payload.showResendLink,
        email: action.payload.email,
      };
    case ModalActionTypes.HIDE_MODAL:
      console.log("try")
      return {
        ...state,
        showModal: false,
        title: "",
        message: "",
        returnType: "",
        showResendLink: false,
        email: "",
      };
    default:
      return initialState;
  }
}