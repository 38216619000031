import React from 'react'
import axios from 'axios';
import {Table, Button, Switch} from 'antd'
import { useEffect } from 'react'
import {history} from '../../../store/history'
import '../../../static/css/antd.css'
import { Handle401 } from '../../handle401/handle401';
import AddFeeModal from './addFeeModal';
import EditFeeModal from './editFeeModal';

const Fees = props => {

  let yourConfig = {
    headers: {
       Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  }
  
  const [fees, setFees] = React.useState([]);

  //Modals

  const [addModal,showAddModal] = React.useState(false)
  const [editModal,showEditModal] = React.useState(false)
  const [editData, setEditData] = React.useState('')
  //Ready

  const [ready, setReady] = React.useState(false)

  function closeModal(type){
    if(type == 1){
      showAddModal(false)
    }
    else {
      showEditModal(false)
    }
  }

  function openEditModal(record) {
    setEditData(record)
    showEditModal(true)
  }
    
  useEffect(() => {

    if(localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") == null){
      history.push('/login')
    }
    else{
      if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
        history.push('/dashboard')
      }
      else if(localStorage.getItem("userType") == "PS"){
        history.push('/orders')
      }
      else if(localStorage.getItem("userType") == "PF"){
        history.push('/recon/7_eleven')
      }
      else if(localStorage.getItem("userType") == "MA"){
        history.push('/overview')
      }
      else{
          fetchFees()
      }
      
    }
    
  }, [])

  const orderStyle = {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '32px',
      color: '#000000',
      marginBottom: '40px'
  }

  async function toggleEnable(data, enable) {
    let yourConfig = {
      headers: {
         Authorization: "Token " + localStorage.getItem("jwtToken")
      }
    }

    let params = {
        id: data.id,
        channel: data.channel,
        channel_code: data.channel_code,
        channel_type: data.channel_type, 
        channel_fee: data.channel_fee,
        maximum: data.maximum,
        enterprise_maximum: data.enterprise_maximum,
        minimum: data.minimum,
        bux_fee: data.bux_fee,
        channel_percent: data.channel_percent,
        bux_percent: data.bux_percent,
        channel_partner: data.channel_partner,
        tier: data.tier,
        enable: enable
    }
    console.log(params)
    let response = await axios.post(
      process.env.REACT_APP_API_URL + '/api/fees/edit/', params,yourConfig
    );

    console.log(response)
    if(response.data.status == 'success'){
      fetchFees();
    }
}

  const columns = [
      {
        title: 'Name',
        dataIndex: 'channel',
      },
      {
        title: 'Code',
        dataIndex: 'channel_code',
      },
      {
        title: 'Tier',
        dataIndex: 'tier',
      },
      {
        title: 'Partner',
        dataIndex: 'channel_partner',
        
      },
      {
        title: 'Type',
        dataIndex: 'channel_type',
        
      },
      {
        title: 'Minimum',
        dataIndex: 'minimum',
        
      },
      {
        title: 'Maximum',
        dataIndex: 'maximum',
        
      },
      {
        title: 'E. Maximum',
        dataIndex: 'enterprise_maximum',
        
      },
      {
        title: 'Fee',
        dataIndex: 'channel_fee',
      },
      {
        title: 'Fee %',
        dataIndex: 'channel_percent',
      },
      {
          title: 'BUx Fee',
          dataIndex: 'bux_fee',
      },
      {
          title: 'BUx %',
          dataIndex: 'bux_percent',
      },
      {
        title: 'Enable',
        dataIndex: 'enable',
        render: (text, record) => <Switch checked={text} onChange={() => {toggleEnable(record, !text); }} />
    },
      {
          title: 'Action',
          render: record => {
              return <a onClick={()=>openEditModal(record)}>Edit</a>
          }
      }, 
  ];
    
  async function fetchFees() {
    try{
        let response = await axios.get(
            process.env.REACT_APP_API_URL + '/api/fees/',
            yourConfig
        );
        setFees(response.data)
        setReady(true)
    }

    catch(error){
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  }

  return(
    <div style={{ paddingTop: '38px', paddingLeft: '42px', paddingRight: '42px' }} hidden={!ready}>
      <div style={{marginBottom: '20px'}}><span style={orderStyle}>Fees</span></div> 

      <Button onClick={()=>showAddModal(true)} type="primary" shape="round" icon="plus" style={{ marginBottom: '25px'}}>
            Add Fee
      </Button>

      <Table
      rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
      dataSource={fees}
      columns={columns} />
      
      <AddFeeModal fetchData={fetchFees} openModal={addModal} closeModal={closeModal} />
      <EditFeeModal fetchData={fetchFees} openModal={editModal} editData={editData} closeModal={closeModal} />
    </div>
  )
}

export default Fees;