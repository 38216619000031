import React,{ useEffect } from 'react';
import NoteCard from '../../../kyb/forms/noteCard';
import Close from '../../../../static/icons/close_icon_orange.svg'
import { message, Input } from 'antd'

const PromoBanner = props => {

    const max_width = 516;
    const max_height = 196;

    const {
        headerText, params, setParams, view_path, isLoading,
        handleChange, banner_count
    } = props;

    let uploadRef = React.useRef()

    const segments = localStorage.getItem('segments')

    const [imageFile, setImageFile] = React.useState("")
    const [imageFileName, setImageFileName] = React.useState("")
    const [imageFileURL, setImageFileURL] = React.useState("")

    const removeFile = () => {
        setImageFile("")
        setImageFileName("")
        setImageFileURL("")
        setParams({
            ...params, 'banner_blob': ""
        })
    }

    useEffect(()=>{
        setImageFile(params.banner_blob)
        // setImageFileURL(params.banner_blob)
    },[params])

    const handleUploadImage = (info) => {

        if(!['image/png', 'inage/jpg', 'image/jpeg'].includes(info.type)){
            message.error('Invalid file type')
        }
        else{
            // read the files
            var reader = new FileReader();
            reader.readAsArrayBuffer(info);

            reader.onload = function (event) {
            // blob stuff
                var blob = new Blob([event.target.result]); // create blob...
                window.URL = window.URL || window.webkitURL;
                var blobURL = window.URL.createObjectURL(blob); // and get it's URL
                
                setImageFile(blob)
                setImageFileURL(blobURL)
                setImageFileName(info.name)

                var image = new Image();
                image.src = blobURL;
                image.onload = function() {
                    // have to wait till it's loaded
                    var resized = resizeMe(image); // resized image url
                    var blob = dataURItoBlob(resized);
                    setParams({...params, 'banner_blob': blob})
                }
            };   
        }
    }

    const resizeMe = (img) => {
      
        var canvas = document.createElement('canvas');
  
        var width = img.width;
        var height = img.height;
  
        // calculate the width and height, constraining the proportions
        if (width > height) {
          if (width > max_width) {
            //height *= max_width / width;
            height = Math.round(height *= max_width / width);
            width = max_width;
          }
        } else {
          if (height > max_height) {
            //width *= max_height / height;
            width = Math.round(width *= max_height / height);
            height = max_height;
          }
        }
        
        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        
        return canvas.toDataURL("image/jpeg",0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)
        
        // you can get BLOB too by using canvas.toBlob(blob => {});
    }

    const dataURItoBlob = (dataUri) => {
        var byteString = atob(dataUri.split(',')[1]);
        var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], {type: mimeString});
        return blob
    }

    return(
        <div className="promotion-card padding-32 top-32" 
            hidden={view_path === 'view_promotion' && params['banner_blob'] === ""}>
            {
                view_path === 'view_promotion' ?
                <div>
                    <div className="promotion-header">
                        Banner Image
                    </div>
                    <div className="top-32">
                        <img src={params['banner_blob']} alt="#" />
                    </div>

                    {/* <div className="top-32">
                        <button className={`outline-btn--nil btn-height right-16`}>
                            Delete
                        </button>
                        <button className={`btn--nil btn-height`}>
                            Replace
                        </button>
                    </div> */}
                    <div className="upload-div padding-20 twoCol top-32">
                        <div className="wc-text liveColor">
                            Header text
                        </div>
                        <div className="wc-text medium-font-weight liveColor">
                            {params['banner_header']}
                        </div>
                    </div>
                </div>
                :
                <div>
                    <div className="promotion-header">
                        {headerText}
                    </div>

                    <div className="top-32">
                        <NoteCard note={
                            <span>
                                You can promote/highlight your voucher by simply adding banner in the
                                <b className="left-4">Exclusive For You</b> section in the Merchant's Dashboard.
                            </span>
                        }/>
                    </div>

                    <div className="top-40" align="left">
                        <div className="wc-text placeholder-text-color">
                            Banner image (516px width and 196px height)
                        </div>
                        
                        <div className="upload-div padding-20 twoCol top-4">
                            <div style={{display: 'flex'}}>
                                {
                                    imageFile == "" &&
                                    <div className="wc-text grey-color top-8">
                                        Upload File
                                    </div>
                                }
                                {
                                    imageFile != "" &&
                                    <div className="flex">
                                        <img className="image-size" src={imageFileURL ? imageFileURL : params['banner_blob']} alt="#"/>
                                        <span className="wc-text file-name">{imageFileName}</span>
                                    </div>
                                }
                            </div>

                            {
                                imageFile != "" ? 
                                <a onClick={()=>removeFile()} className="top-8" href>
                                    <img src={Close} alt="#" style={{color:'#F5922F', width: '12px'}}/>
                                </a>
                                :
                                <button className={`outline-btn--${segments} btn-height`} 
                                    onClick={()=>uploadRef.current.click()}>
                                        Upload Photo
                                </button>
                            }

                            <input type="file"
                            data-test="upload-file-input"
                            ref={uploadRef}
                            style={{display: 'none'}}
                            onChange={(e)=>handleUploadImage(e.target.files[0])}
                            accept=".jpg,.png,.jpeg"
                            >
                        </input>
                        </div>
                    </div>

                    <div className="top-32" align="left">
                        <div className="wc-text placeholder-text-color">
                            Header text
                        </div>
                        <div>
                            <Input 
                                placeholder="Header text"
                                name={'banner_header'}
                                value={params['banner_header']}
                                onChange={handleChange}
                                disabled={isLoading}
                                maxLength={40}/>
                        </div>
                    </div>

                    <div className="top-32 wc-header" align="left">
                        <span className="period-text">
                            Remaining <b>{banner_count} Banners</b> for Exclusive for you.
                        </span>
                    </div>
                </div>
            }
           
        </div>
    )
}

export default PromoBanner;