import React, {useEffect} from 'react';
import { Card, DatePicker, message, Spin, Table } from 'antd';
import dashboardStyles from '../../../static/css/Dashboard';
import moment from 'moment';
import axios from "axios";
import {history} from '../../../store/history';
import { Handle401 } from '../../handle401/handle401';

const AdminDashboard = props => {

  const db = dashboardStyles();
  const [report_data, setData] = React.useState(null);
  const [balance_data, setBal] = React.useState(null);
  //Setting date and moment
  const [startMoment, setStartMoment] = React.useState("")
  const [startDate, setStartDate] = React.useState("")
  const [endMoment, setEndMoment] = React.useState("")
  const [endDate, setEndDate] = React.useState("")
  const [endOpen, setEndOpen] = React.useState(false)
    
  const yourConfig = {
    headers: {
        Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  }

  const disabledStartDate = startValue => {
    if (!startValue || !endMoment) {
      return false;
    }
    return startValue && startValue > moment().endOf('day');
  };

  const disabledEndDate = endValue => {
    if (!endValue || !startMoment) {
      return false;
    }
    return endValue.valueOf() <= startMoment.valueOf();
  };
  
  const onStartChange = value => {
    if(value != null){
      setStartMoment(value)
      setStartDate(value.format("MM-DD-YYYY"));
    }
    else{
      setStartMoment(null);
      setStartDate("");
    }
  }

  const onEndChange = value => {
    if(value != null && startDate != ""){
      setEndMoment(value);
      setEndDate(value.format("MM-DD-YYYY"));
      fetchData(startDate,value.format("MM-DD-YYYY"));
    }
    else{
      setEndMoment(null);
      setEndDate("");
    }
  }

  const handleStartOpenChange = open => {
    if (!open) {
      setEndOpen(true);
    }
  };

  const handleEndOpenChange = open => {
    setEndOpen(open)
  };
  
  const renderColumns = () => {
    if(report_data)
      return report_data
    else
      return []
  }

  async function fetchData (start, end) {
    try{
      let response = await axios.get(
        process.env.REACT_APP_API_URL + '/api/admin/report/?start='+start+"&end="+end,
        yourConfig
      );
      setData(response.data)
    }

    catch(error){
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      } else {
        message.error(error.response.data.message);
      }
    } 
  }

  async function fetchBal () {
    try{
      let response = await axios.get(
        process.env.REACT_APP_API_URL + '/api/bank_balance/',
        yourConfig
      );
      console.log(response.data)
      setBal(response.data)
    }

    catch(error){
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  }

  useEffect(() => {
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
      history.push('/login')
    }
    else{
      if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
        history.push('/dashboard')
      }
      else if(localStorage.getItem("userType") == "PS"){
          history.push('/orders')
      }
      else if(localStorage.getItem("userType") == "PF"){
          history.push('/recon/7_eleven')
      }
      else if(localStorage.getItem("userType") == "MA"){
          history.push('/overview')
      }
      else{
          fetchData("", "")
          fetchBal()
      } 
    }
  }, []);

  const columns = [
    {
      title: 'Period',
      dataIndex: 'name',
    },
    {
      title: 'Start of Day Bank',
      dataIndex: 'sod_bank_balance',
    },
    {
      title: 'Start of Day Wallet',
      dataIndex: 'start_of_day',
    },
    {
      title: 'BUx Fees',
      dataIndex: 'fees',
    },
    {
      title: 'Transaction Amount',
      dataIndex: 'transactions_amount',
    },
    {
      title: 'Payout Amount',
      dataIndex: 'payout_amount',
    },
    {
      title: 'End of Day Wallet',
      dataIndex: 'total_balance',
    },
    {
      title: 'End of Day Bank',
      dataIndex: 'eod_bank_balance',
      }
  ];

  return(
    <div className={db.main}>
      <div className={db.dashboardStyle}>
          Summary
      </div>
      <div className={db.card}>
        <div>
          <div className={db.textStyle}>
              Select Period:
          </div>
          
          <div style={{ marginTop: '10px', display: 'flex'}}>
            <DatePicker
              style={{zIndex: 1}}
              disabledDate={disabledStartDate}
              size="large"
              format='MM-DD-YYYY'
              value={startMoment || null}
              placeholder="Start"
              onChange={onStartChange}
              onOpenChange={handleStartOpenChange}
            />
            <span style={{padding: '10px'}}>-</span> 
            <DatePicker
              style={{zIndex: 1, marginRight: '12px'}}
              disabledDate={disabledEndDate}
              size="large"
              format='MM-DD-YYYY'
              value={endMoment || null}
              placeholder="End"
              onChange={onEndChange}
              open={endOpen}
              onOpenChange={handleEndOpenChange}
            />
          </div>
        </div>
      
        <Card style={{ background: '#00000000', border: '0px', marginLeft: '200px', width: '400px', textAlign: 'right'}}>
            <div className={db.todayStyle} style={{ fontSize: '16px', paddingTop: '30px' }}>Balance:<span style={{ marginLeft: '20px'}}>{balance_data ? balance_data.eod_bank_balance : <Spin size="small" />}</span></div>
        </Card>
      </div>
      
      <Table
        dataKey={record => record.id}
        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        columns={columns}
        dataSource={renderColumns()}
        pagination={false} />

    </div>
    )
}

export default AdminDashboard