import React from 'react';
import { Radio, Icon, Typography, Divider, Switch, Input, Checkbox } from 'antd';
import { Grid } from '@material-ui/core'
import CustomInfoModal from './custom_info_modal';
import CustomAmountModal from './custom_amount_modal';
import { history } from '../../store/history';

const QrDetailsSection = (props) => {
    const { data, updateQrSettings, segments } = props;
    const [qrParams, setQrParams] = React.useState([]);
    const [amountType, setAmounttype] = React.useState(false);
    const [personalMsg, setPersonalMsg] = React.useState('');
    const [perMsgActive, setPerMsgActive] = React.useState(false);
    const [customFieldEdit, setCustomFieldEdit] = React.useState(false);
    const [field1, setField1] = React.useState('');
    const [field2, setField2] = React.useState('');
    const [socialMediaEdit, setSocialMediaEdit] = React.useState(false);
    const [fb, setFb] = React.useState('');
    const [ig, setIg] = React.useState('');
    const [web, setWeb] = React.useState('');
    const [amountListInfo, setAmountListInfo] = React.useState(false);
    const [createAmountModal, setCreateAmountModal] = React.useState(false);
    const [listAmount, setListAmount] = React.useState([]);
    const [amountLabel, setAmountLabel] = React.useState([]);
    const [postbackUrl, setPostbackUrl] = React.useState('');
    const [postbackUrlActive, setPostbackUrlActive] = React.useState(false);
    const testMode = (history.location.pathname.indexOf('/test') > -1);

    const arrangeQrParams = () => {
        setPersonalMsg(data && data.personal_message);
        setField1(data && data.field_1);
        setField2(data && data.field_2);
        setFb(data && data.fb);
        setIg(data && data.ig);
        setWeb(data && data.web);
        setListAmount(data && data.customize_amount ? data.customize_amount : [])
        setAmountLabel(data && data.customize_amount_label ? data.customize_amount_label : [])
        setPostbackUrl(data && data.notification_url);
        setQrParams({
            qr_enabled: data && data.qr_enabled,
            any_amount: data && data.any_amount,
            allow_enter_amount: data && data.allow_enter_amount,
            show_mobile: data && data.show_mobile,
            show_email: data && data.show_email,
            customize_amount: data && data.customize_amount ? data.customize_amount : [],
            customize_amount_label: data && data.customize_amount_label ? data.customize_amount_label : [],
            personal_message: data && data.personal_message,
            notification_url: data && data.notification_url,
            field_1: data && data.field_1,
            show_field_1: data && data.show_field_1,
            field_2: data && data.field_2,
            show_field_2: data && data.show_field_2,
            field_1_required: data && data.field_1_required,
            field_2_required: data && data.field_2_required,
            fb: data && data.fb,
            ig: data && data.ig,
            web: data && data.web,
        });
    }

    const onCancelPerMsg = () => {
        setPersonalMsg(data && data.personal_message);
        setPerMsgActive(false);
    }

    const onCancelPostbackUrl = () => {
        setPostbackUrl(data && data.notification_url);
        setPostbackUrlActive(false);
    }

    const onChangeMsg = (e) => {
        setPersonalMsg(e.target.value);
    }

    const onChangePostback = (e) => {
        setPostbackUrl(e.target.value);
    }

    const onChangeField = (field, e) => {
        if (field === 1) {
            setField1(e.target.value);
        } else {
            setField2(e.target.value);
        }
    }

    const onCancelCustomField = () => {
        setField1(data && data.field_1);
        setField2(data && data.field_2);
        setCustomFieldEdit(false);
    }

    const onSocialChange = (btn, e) => {
        if (btn === 1) {
            setFb(e.target.value);
        } else if (btn === 2) {
            setIg(e.target.value);
        } else if (btn === 3) {
            setWeb(e.target.value);
        }
        console.log('MY_SOCIAL', e.target.value)
    }

    console.log('MY_SOCIAL',fb)

    const onCancelSocialField = () => {
        setFb(data && data.fb);
        setIg(data && data.ig);
        setWeb(data && data.web);
        setSocialMediaEdit(false);
    }

    const onCancelAmountChange = () => {
        setListAmount(data && data.customize_amount ? data.customize_amount : [])
    }

    const onChange = (value, name) => {
        // setQrParams({
        //     ...qrParams,
        //     [e.target.name]: e.target.value
        // });
        const newData = qrParams && qrParams;

        if (field1 !== newData['field_1'] || field2 !== newData['field_2'] ) {
            newData['field_1'] = field1;
            newData['field_2'] = field2;
        }

        if (name === 'custom_fields') {
            newData['field_1'] = field1;
            newData['field_2'] = field2;
        } else if (name === 'socials') {
            newData['fb'] = fb;
            newData['ig'] = ig;
            newData['web'] = web;
        } else if (name === 'list_amount') {
            newData['customize_amount'] = listAmount;
            newData['customize_amount_label'] = amountLabel;
        } else {
            newData[name] = value;
        }
        console.log('UPDATED PARAMS', fb);
        // const name = e.target.name;
        // const item = newData && newData[name];
        // newData && newData.splice(name, 1, {
        //     ...item,
        //     [name]: value,
        // });

        // const params = {
        //     qr_enabled: qrParams.qrOn,
        //     any_amount: qrParams.amountType,
        //     allow_enter_amount: qrParams.allowCustAmount,
        //     customize_amount: qrParams.amountList,
        //     show_mobile: qrParams.mobileOn,
        //     show_email: qrParams.emailOn,
        //     personal_message: qrParams.personalMsg,
        //     field_1: qrParams.field1,
        //     show_field_1: qrParams.showField1,
        //     field_2: qrParams.field2,
        //     show_field_2: qrParams.showField2,
        //     fb: qrParams.fb,
        //     ig: qrParams.ig,
        //     web: qrParams.website,
        //     notification_url: qrParams.notifUrl,
        // }
        setQrParams(newData);
        updateQrSettings(newData);
        console.log('UPDATED PARAMS', window.location.protocol + '://' + fb);
    }
    
    React.useEffect(() => {
        arrangeQrParams();
    }, [data])

    const renderAmountList = () => (
        <Grid container style={styles.gridContainer}>
            {
                listAmount && listAmount.map((item, key) => {
                    return (
                        <Grid item xs={2} sm={2} md={3} style={styles.amountBox} >
                            ₱{item}
                        </Grid>
                    )
                })
            }
        </Grid>
    )

    return (
        <div hidden={qrParams && !qrParams.qr_enabled} style={{ padding: '0 0 24px 0' }}>
            <Divider />
            <div style={styles.sectionDiv}>
                <div style={styles.switchDiv}>
                    <Typography style={styles.sectionTitle}>Select Payment Request</Typography>
                    <button hidden={createAmountModal || listAmount && listAmount.length <= 0} className={`outline-btn--${segments}`} style={styles.editBtn} onClick={() => setCreateAmountModal(true)}>
                        <Icon style={{ paddingRight: 4 }} theme='filled' type={createAmountModal ? 'save' : 'edit'} />{createAmountModal ? 'save' : 'Edit'}
                    </button>
                </div>
                <div hidden={qrParams && qrParams.any_amount} style={{ ...styles.switchDiv, padding: '24px 0px 12px 0' }}>
                    <Typography style={{ ...styles.sectionTitle, padding: '0px 24px 0px 0' }}>Allow customer to enter desired amount on top of the list.</Typography>
                    <Switch size='small' checked={qrParams.allow_enter_amount} className={qrParams && qrParams.allow_enter_amount && `switch-btn--${segments}`}
                        onChange={(value) => onChange(value, 'allow_enter_amount')} />
                </div>
                <div style={{ ...styles.switchDiv, padding: '12px 0' }}>
                    <Radio.Group style={styles.radioDiv} onChange={() => onChange(!qrParams.any_amount, 'any_amount')} name='any_amount' value={qrParams && qrParams.any_amount}>
                        <Radio value={true} >Any amount</Radio>
                        <div>
                            <Radio value={false} >Custom amount list</Radio>
                            <Icon onClick={() => setAmountListInfo(true)} type="exclamation-circle" theme="filled" style={styles.exclamation} />
                        </div>
                    </Radio.Group>
                </div>
                <button hidden={listAmount && listAmount.length >= 1} className={`outline-btn--${segments}`} style={{ ...styles.editBtn, width: '' }}
                    onClick={() => setCreateAmountModal(true)}><Icon type='plus' />Create custom list</button>
                <div hidden={qrParams && qrParams.any_amount}>
                    {renderAmountList()}
                </div>
            </div>
            <Divider />
            <div style={styles.sectionDiv}>
                <div style={styles.switchDiv}>
                    <Typography style={styles.sectionTitle}>Show mobile number</Typography>
                    <Switch size='small' checked={qrParams.show_mobile} className={qrParams && qrParams.show_mobile && `switch-btn--${segments}`}
                        onChange={(value) => onChange(value, 'show_mobile')} />
                </div>
                <Typography style={styles.desc}>Anyone can see your mobile number as your contact information.</Typography>
            </div>
            <Divider />
            <div style={styles.sectionDiv}>
                <div style={styles.switchDiv}>
                    <Typography style={styles.sectionTitle}>Show email address</Typography>
                    <Switch size='small' checked={qrParams.show_email} className={qrParams && qrParams.show_email && `switch-btn--${segments}`}
                        onChange={(value) => onChange(value, 'show_email')} />
                </div>
                <Typography style={styles.desc}>Anyone can see your email address as your contact information.</Typography>
            </div>
            <Divider />
            <div style={styles.sectionDiv}>
                <div style={styles.switchDiv}>
                    <Typography style={styles.sectionTitle}>Add personal message</Typography>
                </div>
                <Typography style={styles.desc}>This will be displayed on the header part of your Checkout page.</Typography>
                <div style={styles.perMsgDiv}>
                    <Input.TextArea hidden={!perMsgActive} value={personalMsg} maxLength={200} onChange={onChangeMsg} rows={3} />
                    <Typography hidden={perMsgActive} style={styles.perMsg}>{qrParams && qrParams.personal_message}</Typography>
                    <div style={styles.editBtnDiv}>
                        <a className={`link-text--${segments}`} hidden={perMsgActive} onClick={() => setPerMsgActive(true)} >
                            <Icon style={{ paddingRight: 4 }} type={'edit'} theme='filled' /> {personalMsg !== '' ? ' Edit' : ' Add'} personal message</a>
                        <div style={styles.updateMsgBtn} hidden={!perMsgActive}>
                            <button className={`outline-btn--${segments}`} style={{ ...styles.editBtn, margin: '0 4px' }} onClick={onCancelPerMsg}>Cancel</button>
                            <button className={`btn--${segments}`} style={{ ...styles.editBtn, width: 76 }}
                                onClick={() => (onChange(personalMsg, 'personal_message') / setPerMsgActive(false))}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
            <Divider />
            <div style={styles.sectionDiv}>
                <div style={styles.switchDiv}>
                    <Typography style={styles.sectionTitle}>Add Postback URL</Typography>
                </div>
                <Typography style={styles.desc}>This endpoint will receive updates via API Postback for every payment/status change.</Typography>
                <div style={styles.perMsgDiv}>
                    <Input.TextArea hidden={!postbackUrlActive} value={postbackUrl} maxLength={200} onChange={onChangePostback} rows={3} />
                    <Typography hidden={postbackUrlActive} style={styles.perMsg}>{qrParams && qrParams.notification_url}</Typography>
                    <div style={styles.editBtnDiv}>
                        <a className={`link-text--${segments}`} hidden={postbackUrlActive} onClick={() => setPostbackUrlActive(true)} >
                            <Icon style={{ paddingRight: 4 }} type={'edit'} theme='filled' /> {postbackUrl !== '' ? ' Edit' : ' Add'} postback url</a>
                        <div style={styles.updateMsgBtn} hidden={!postbackUrlActive}>
                            <button className={`outline-btn--${segments}`} style={{ ...styles.editBtn, margin: '0 4px' }} onClick={onCancelPostbackUrl}>Cancel</button>
                            <button className={`btn--${segments}`} style={{ ...styles.editBtn, width: 76 }}
                                onClick={() => (onChange(postbackUrl, 'notification_url') / setPostbackUrlActive(false))}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
            <Divider />
            <div style={styles.sectionDiv}>
                <div style={styles.switchDiv}>
                    <div>
                        <Typography style={styles.sectionTitle}>Add custom fields</Typography>
                        <Typography style={{ ...styles.desc, padding: '4px 0' }}>Add additional custom fields on your Checkout page.</Typography>
                    </div>
                    <button hidden={customFieldEdit} className={`outline-btn--${segments}`} style={styles.editBtn} onClick={() => setCustomFieldEdit(true)}>
                        <Icon style={{ paddingRight: 4 }} theme='filled' type={customFieldEdit ? 'save' : 'edit'} />{customFieldEdit ? 'save' : 'Edit'}
                    </button>
                </div>
                <div style={styles.perMsgDiv} hidden={!customFieldEdit && (qrParams && !qrParams.field_1 || qrParams.field_1 === '')}>
                    <div style={{ ...styles.desc, fontSize: 16, display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ ...styles.desc, fontSize: 16, marginRight: 8 }}>Text field 1:</Typography>
                        <b hidden={customFieldEdit}>{qrParams && qrParams.field_1}</b>
                        <Input size='large' style={styles.paramsInputField} hidden={!customFieldEdit} value={field1} onChange={(e) => onChangeField(1, e)} />
                    </div>
                    <div style={styles.checkboxDiv}>
                        <Checkbox style={{ paddingRight: 8 }}
                            checked={qrParams && qrParams.show_field_1} onChange={() => onChange(!qrParams.show_field_1, 'show_field_1')} />
                        Display this custom field on My Checkout page.
                    </div>
                    <div style={styles.checkboxDiv}>
                        <Checkbox style={{ paddingRight: 8 }}
                            checked={qrParams && qrParams.field_1_required} onChange={() => onChange(!qrParams.field_1_required, 'field_1_required')} />
                        Required field
                    </div>
                </div>
                <div style={styles.perMsgDiv} hidden={!customFieldEdit && (qrParams && !qrParams.field_2 || qrParams.field_2 === '')}>
                    <div style={{ ...styles.desc, fontSize: 16, display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ ...styles.desc, fontSize: 16, marginRight: 8 }}>Text field 2:</Typography>
                        <b hidden={customFieldEdit}>{qrParams && qrParams.field_2}</b>
                        <Input size='large' style={styles.paramsInputField} hidden={!customFieldEdit} value={field2} onChange={(e) => onChangeField(2, e)} />
                    </div>
                    <div style={styles.checkboxDiv}>
                        <Checkbox style={{ paddingRight: 8 }}
                            checked={qrParams && qrParams.show_field_2} onChange={() => onChange(!qrParams.show_field_2, 'show_field_2')} />
                        Display this custom field on My Checkout page.
                    </div>
                    <div style={styles.checkboxDiv}>
                        <Checkbox style={{ paddingRight: 8 }}
                            checked={qrParams && qrParams.field_2_required} onChange={() => onChange(!qrParams.field_2_required, 'field_2_required')} />
                        Required field
                    </div>
                </div>
                <div style={styles.updateMsgBtn} hidden={!customFieldEdit}>
                    <button className={`outline-btn--${segments}`} style={{ ...styles.editBtn, margin: '0 4px' }} onClick={onCancelCustomField}>Cancel</button>
                    <button className={`btn--${segments}`} style={{ ...styles.editBtn, width: 76 }}
                        onClick={() => (onChange(true, 'custom_fields') / setCustomFieldEdit(false))}>Update</button>
                </div>
            </div>
            <Divider hidden={testMode} />
            <div hidden={testMode} style={styles.sectionDiv}>
                <div style={styles.switchDiv}>
                    <div>
                        <Typography style={styles.sectionTitle}>Add your social media and website</Typography>
                        <Typography style={{ ...styles.desc, padding: '4px 4px 4px 0px', width: '94%' }}>Display your Social Media account or website on your Checkout page.</Typography>
                    </div>
                    <button hidden={socialMediaEdit} className={`outline-btn--${segments}`} style={styles.editBtn} onClick={() => setSocialMediaEdit(true)}>
                        <Icon style={{ paddingRight: 4 }} theme='filled' type={socialMediaEdit ? 'save' : 'edit'} />{socialMediaEdit ? 'save' : 'Edit'}
                    </button>
                </div>
                <div style={styles.socialDiv} hidden={!socialMediaEdit && (qrParams && !qrParams.fb || qrParams.fb === '')}>
                    <Typography style={{ ...styles.sectionTitle, fontWeight: 'normal' }}>Facebook</Typography>
                    <label style={styles.link} className={`link-text--${segments}`} hidden={socialMediaEdit}
                        onClick={() => window.open(window.location.protocol + '//' + fb, "_blank")} target="_blank">{qrParams && qrParams.fb}</label>
                    <Input hidden={!socialMediaEdit} size='large' style={{ width: '100%' }} value={fb}
                        onChange={(e) => onSocialChange(1, e)} />
                </div>
                <div style={styles.socialDiv} hidden={!socialMediaEdit && (qrParams && !qrParams.ig || qrParams.ig === '')}>
                    <Typography style={{ ...styles.sectionTitle, fontWeight: 'normal' }}>Instagram</Typography>
                    <label style={styles.link} className={`link-text--${segments}`} hidden={socialMediaEdit}
                        onClick={() => window.open(window.location.protocol + '//' + ig, "_blank")} target="_blank">{qrParams && qrParams.ig}</label>
                    <Input hidden={!socialMediaEdit} size='large' style={{ width: '100%' }} value={ig}
                        onChange={(e) => onSocialChange(2, e)} />
                </div>
                <div style={styles.socialDiv} hidden={!socialMediaEdit && (qrParams && !qrParams.web || qrParams.web === '')}>
                    <Typography style={{ ...styles.sectionTitle, fontWeight: 'normal' }}>Website</Typography>
                    <label style={styles.link} className={`link-text--${segments}`} hidden={socialMediaEdit}
                        onClick={() => window.open(window.location.protocol + '//' + web, "_blank")} target="_blank">{qrParams && qrParams.web}</label>
                    <Input hidden={!socialMediaEdit} size='large' style={{ width: '100%' }} value={web}
                        onChange={(e) => onSocialChange(3, e)} />
                </div>
                <div style={styles.updateMsgBtn} hidden={!socialMediaEdit}>
                    <button className={`outline-btn--${segments}`} style={{ ...styles.editBtn, margin: '0 4px' }} onClick={onCancelSocialField}>Cancel</button>
                    <button className={`btn--${segments}`} style={{ ...styles.editBtn, width: 76 }} onClick={() => (onChange(true, 'socials') / setSocialMediaEdit(false))}>Update</button>
                </div>
            </div>
            <CustomInfoModal showModal={amountListInfo} setShowModal={() => setAmountListInfo(false)} setCustomModal={() => setCreateAmountModal(true)} />
            <CustomAmountModal customModal={createAmountModal} setCustomModal={() => setCreateAmountModal(false)} listAmount={listAmount} amountLabel={amountLabel} setListAmount={setListAmount}
                setAmountLabel={setAmountLabel} removeIcon={Icon.RemoveIcon} gettingParams={() => onChange(true, 'list_amount')} getDetails={() => arrangeQrParams()} />
        </div>
    )
}

const styles = {
    root: {
        padding: 0,
        width: 532
    },
    cardBody: {
        padding: 0
    },
    sectionDiv: {
        padding: '0 32px 0px 32px'
    },
    switchDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 0 8px 0'
    },
    desc: {
        fontSize: 14,
        color: '#2B2D32',
        opacity: '0.8',
        lineHeight: '20px',
    },
    sectionTitle: {
        color: '#2B2D32',
        fontSize: 16,
        fontWeight: '500'
    },
    perMsg: {
        color: '#2B2D32',
        fontSize: 16,
    },
    perMsgDiv: {
        border: '1px solid #D4D5D8',
        borderRadius: 4,
        padding: 20,
        margin: '20px 0 0 0'
    },
    editBtnDiv: {
        textAlign: 'right',
        padding: '16px 0 8px 0'
    },
    updateMsgBtn: {
        padding: '12px 0 0 0',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    editBtn: {
        cursor: 'pointer',
        height: 34,
        padding: '0 12px',
        margin: 0,
        width: 70,
        display: 'flex',
        alignItems: 'center'
    },
    checkboxDiv: {
        padding: '12px 0',
        fontSize: 14,
        color: 'rgba(43, 45, 50, 0.8)',
        display: 'flex'
    },
    paramsInputField: {
        width: '75%',
    },
    socialDiv: {
        padding: '8px 0',
        textAlign: 'left'
    },
    link: {
        fontSize: 16,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0',
        maxWidth: 328,
        textDecoration: 'none',
        fontWeight: '500',
        cursor: 'pointer'
    },
    radioDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    exclamation: {
        color: '#EEEEEE',
        border: '1px solid #6F7585',
        borderRadius: 25,
        backgroundColor: '#6F7585',
        fontSize: 14,
    },
    amountBox: {
        border: '1px solid #D4D5D8',
        borderRadius: 4,
        backgroundColor: '#EEEEEE',
        display: 'flex',
        justifyContent: 'center',
        padding: '12px 0',
        margin: '6px',
        maxWidth: '25%',
        flexBasis: '22%',
    },
    gridContainer: {
        // flexWrap: 'inherit'
    }
}

export default QrDetailsSection;