import React from 'react'
import { Dropdown, Typography, Icon, Menu, Table } from 'antd';
import { Pagination } from 'antd';

const SubwalletsTable = props => {

    const { segments, data, isLoading, pageSize, onChangePage } = props;
    const [loading, setLoading] = React.useState(false);
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL

    const returnKycLevel = (level) => {
        switch (level) {
            case 0:
                return 'Non-KYC';
            case 1:
                return 'Approved KYC';
            case 2:
                return 'Advanced';
            case 3:
                return 'Business';
            default:
                return 'Basic';
        }
    }

    const columns = [
        {
            title: 'Sub-wallet ID',
            dataIndex: 'sub_id',
            render: (text) => text && text !== ' ' ? `SW${text}` : 'N/A'
        },
        {
            title: 'Business Name',
            dataIndex: 'business_name',
            render: (text) => text && text !== ' ' ? text : 'N/A'
        },
        {
            title: 'Name',
            dataIndex: 'full_name',
            render: (text) => text && text !== ' ' ? text : 'N/A'
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            render: (text) => text ? text : 'N/A'
        },
        {
            title: 'Mobile Number',
            dataIndex: 'contact',
            render: (text) => text ? text : 'N/A'
        },
        {
            title: 'KYC Level',
            dataIndex: 'eon_level',
            render: (record) => returnKycLevel(record && record)
        },
        {
            title: 'Wallet Balance',
            dataIndex: 'balance',
            render: (record) => <Typography style={{ fontSize: 14, fontWeight: 'bold', color: '#2B2D32' }}>₱ {record.toLocaleString("en-US", { minimumFractionDigits: 2 })}</Typography>
        },
        {
            title: 'Action',
            render: (record) => (
                <Dropdown overlay={() => menu(record)} placement="bottomCenter" trigger={['click']}>
                    <Icon type="ellipsis" />
                </Dropdown>
            )
        },
    ]

    const menu = (record) => (
        <Menu>
            <Menu.Item key="0">
                <a onClick={() => window.open(`${REACT_APP_BASE_URL}/admin/user_details/${record.id}/#userInfo`)} >View</a>
            </Menu.Item>
            {/* <Menu.Item key="1" hidden={record.eon_level === 0 && !testMode}>
                <a onClick={() => setTransferModalShow(true) / setDetails(record)} >Transfer Money</a>
            </Menu.Item>
            <Menu.Item key="2" hidden={record.eon_level === 0 && !testMode}>
                <a onClick={() => setRequestModalShow(true) / setDetails(record)} >Request Payment</a>
            </Menu.Item> */}
        </Menu>
    )

    return(
        <div>
            <Table
                rowClassName="table-row-light"
                columns={columns}
                dataSource={data ? data.results : []}
                loading={isLoading || loading}
                pagination={false}
            />

            <Pagination
                size='small'
                total={data && data.total? data.total : 0}
                defaultCurrent={1}
                pageSize={10}
                current={data && data.page? data.page : 1}
                onChange={(page, pageSize, data) => onChangePage(page, pageSize)}
                style={{padding:'32px 0', textAlign: 'right'}}
            />

        </div>
    )
} 

export default SubwalletsTable