import React, { } from "react";
import { Typography, LinearProgress, withStyles } from "@material-ui/core";
import { Spin } from "antd";
import { isMobile } from "react-device-detect";
import '../../../App.scss'

const Meter = (data) => {
  const userType = localStorage.getItem('userType');
  const level = localStorage.getItem('Level');
  const segments = localStorage.getItem('segments');

  return (
    <div style={{ padding: '0 0 8px 0' }}>
      <div style={{ marginTop: '8px' }}>
        {data.limit ? 
        <BorderLinearProgress
          variant="determinate"
          color="secondary"
          value={data.current / data.limit * 100}
          style={{height:8}}
        />  
        :   
          <BorderLinearProgress
          variant="determinate"
          color="secondary"
          value={0}
          style={{height:8}}
        /> } 
      </div>
      <div className="twoCol" style={{marginTop: 4}}>
        <div className="smallTextStyle liveColor">{data.name}</div>
        <div style={styles.meterValue}>
            { data.current ?
              <div id={'cashin'} className="smallTextStyle liveColor" style={{marginRight: 2 }}>
                ₱ {data.current.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
            :
            <div id={'cashin'} className="smallTextStyle liveColor" style={{marginRight: 2 }}>
              ₱ 0
            </div>
            }
          {data.name == 'Cash Out' && level === '0' && userType !== "SW" || userType === "SW" && level === '0'  ? 
              <div className="smallTextStyle liveColor"> / ₱ 0</div>
            : data.limit && data.limit !== null ?
              <div className="smallTextStyle liveColor">
                / ₱ {data.limit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div> 
            : <div className="smallTextStyle liveColor"> / no limits</div>}
        </div>
      </div>
    </div>
  )
}

const renderMeter = (value) => {
  // console.log('RENNNNDER______METER', value)
  return (
    <div>
      {value.map((item, index) => {
        return (
          <Meter key={index} {...item} />
        )
      })}
    </div>
  )
}

const TrackerBody = (props) => {
  const { data } = props;

  
  return (
    <div style={styles.root}>
      {data ? renderMeter(data) : <Spin size="default" style={{padding: '50px 0 50px 0'}} />}
    </div>
  )
}

const styles = {
  root: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
  },
  meter: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 8,
  },
  meterValue: {
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center'
  },
  meterLimit: {
    fontSize: 10, 
    fontWeight: 'bold', 
    textAlign: 'right', 
    color: '#54575F'
  },
  divider: {
    borderTop: '1px solid #D1D5DD', 
    // margin: '13px 0px 0px 0px',
    margin: '8px 0px 0px 0px',
  },
  noLimit: {
    fontSize: 10, 
    color: '#54575F', 
    textAlign: 'right', 
    margin: '-5px 0px 0px 0px'
  }
}

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 30,
    backgroundColor: '#E6EAF0',
  },
  bar: {
    borderRadius: 30,
    // backgroundImage: 'linear-gradient(.25turn, #f8b966, #f5922f)',
    // backgroundImage: `${localStorage.getItem('Step') <= 5 || localStorage.getItem('Step') == 16 ?
    //   'linear-gradient(.25turn, #65C1F2, #65C1F2)' : 'linear-gradient(.25turn, #f8b966, #f5922f)'}`,
    backgroundColor: `${
      localStorage.getItem('segments') == 'me_' ? '#0DAED0' :
      localStorage.getItem('segments') == 'gig' ? '#41BD5C' :
      localStorage.getItem('segments') == 'sme' ? '#E3632A' :
      localStorage.getItem('segments') == 'biz' ? '#0051B0' :
      '#F5922F' }`
  },
})(LinearProgress);


export default TrackerBody;