import React from 'react';
import { LargePayoutIcon, WhiteTransferMoneyIcon } from '../../../../../static/images/multiwallet';

const SubwalletBalanceCards = (props) => {
    const { segments, balance, setTransferModalShow } = props;
    const kybRequired = localStorage.getItem("kybRequired") == 'true'
    const userLevel = localStorage.getItem('Level');

    const transfer_money_enabled = JSON.parse(localStorage.getItem('defined_wallet_settings')).transfer_money_enabled

    return (
        <div className="twoCol liveColor">
            <div>
                <div className="flex">
                    <img src={LargePayoutIcon} alt="icon" className="right-8" />
                    <div className="title-semi-bold right-8">
                        Transferable Balance:
                    </div>
                    <div className="large-bold-text">
                        {   balance ?
                                balance.floatBalance.toLocaleString("en-US", {minimumFractionDigits: 2})
                            :
                            '0.00'
                        }
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", gap: "1em" }}>
                <button className={`btn--${segments} btn-height`} onClick={() => setTransferModalShow(true)}
                    disabled={!transfer_money_enabled || (parseInt(userLevel) < 1 && kybRequired)}
                >
                    <img alt='#' src={WhiteTransferMoneyIcon} style={{ marginTop: '-8px', marginRight: 8 }} />Transfer Money
                </button>
            </div>
        </div>
    )
}

export default SubwalletBalanceCards;
