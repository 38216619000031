import React, {useEffect, useState} from 'react'
import {history} from '../../store/history'
import {isMobile} from 'react-device-detect'
import accountStyle from '../../static/css/Account';
import BankAccount from './bank_account/bankAccount'
import EnterpriseBusinessDetails from './kyb_businessDetails'
import BusinessDetails from './business_detail'
import GeneralSettings from './general_settings'
import MetaTag from '../meta_tag/meta_tag'
import MyEnterpriseProfile from './kyb_myprofile';
import MobileUserAccount from '../AdminComponents/userAccount/mobile/mobile_user_main'
import BuxQrMain from './bux_qr/bux_qr_main'
import MyProfile from './profile/profile'
import {Tabs, Icon} from 'antd';
import { useLocation } from 'react-router';
import MobileMain from '../../layouts/Mobile/MobileMain'
import {backToTop} from '../constants/constants'
import axios from 'axios';


const { TabPane } = Tabs;

const MobileAccount = props => {

    const acct = accountStyle();

    let user = localStorage.getItem("userType")
    let level = localStorage.getItem("Level")
    const [isQRHiddenFromAlias, setIsQRHiddenFromAlias] = useState(true);


    async function genericAxiosRequest(http_method, target_url, params=undefined) {
        try {
            let response = await axios({
                method: http_method,
                url: process.env.REACT_APP_API_URL + target_url,
                data: params,
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            });
            return response.data;

        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    return; // Handle401()
                } else {
                    throw new Error(error.response.data.message);
                }
            } else {
                throw new Error('No response from backend server!');
            }
        }
    }


    async function checkAliasCanViewQR() {
        const setting_name = 'qr_settings_alteration'
        const endpoint_url = `/api/enterprise/alias/settings/?name=${setting_name}`;

        try {
            let response = await genericAxiosRequest('get', endpoint_url);
            if (response.status === 'failed') {
                throw new Error(response.message);
            }
            setIsQRHiddenFromAlias(! response.message);

        } catch (err) {
            // do nothing
        }
    }


    useEffect(() => {
        let current_path = window.location.pathname
        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            history.push('/login?next='+current_path)
        }

        else{
            if(!isMobile){
                history.push('/messenger/error')
                return;
            } else if (isMobile) {
                getBalance();

                if (childAcct) {
                    checkAliasCanViewQR();
                }
            }
        }
        
    }, [])

    const location = useLocation();
    const [isSuccess, setIsSuccess] = useState(location.state? location.state.isSuccess : false);
    const [notifMsg, setNotifMsg] =  useState(location.state? location.state.notifMsg : null);
    const [settingsTitle, setSettingTitle] = useState("")
    const [endpoints, setEndpoints] = useState([]);
    const hideQr = level == 0 || user == "CO" && level != 5 || user == "CO" && localStorage.getItem("KYBStep") == 10;
    const childAcct = (user == "CO" && localStorage.getItem("isAlias") == "true") || localStorage.getItem("is_child") == "true" || localStorage.getItem("is_child") == true

    if(isSuccess) {
        setTimeout(() => {
            setIsSuccess(false);
            history.replace({ pathname:'/mobile/account_settings', state: {isSuccess: false}});
        }, 6000);
    }

    const notif = (
        <div
            style={{
                backgroundColor: 'rgba(29, 210, 139, 0.1)',
                fontSize: 12,
                fontWeight: 'normal',
                width: '100%',
                borderRadius: 4,
                padding: '8px 16px',
                textAlign: 'center',
                display: 'flex',
                // boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.24)',
                color: '#000',
                borderLeft: '5px solid #1DD28B',
                margin: '8px 0 16px 0'
            }}
            hidden={!isSuccess}
        >{notifMsg}</div>
    )

    const tabs = [
        {
            'title': 'My Profile',
            'component': <MyProfile />,
            'hidden': user == "CO" ? true : false
        },
        {
            'title': 'My Information',
            'component': <MyEnterpriseProfile />,
            'hidden': user == "ME" || user == "SW" ? true : false
        },
        {
            'title': 'My Checkout',
            'component': <BuxQrMain />,
            'hidden': user == 'SW' || hideQr || (childAcct && isQRHiddenFromAlias) ? true : false
        },
        {
            'title': 'Business Information',
            'component': <BusinessDetails />,
            'hidden': user == "CO" ? true : false
        },
        {
            'title': 'Bank Account',
            'component': <BankAccount />,
            'hidden': user == 'SW' || childAcct || isMobile ? true : false
        },
        {
            'title': 'General Settings',
            'component': <GeneralSettings />,
            'hidden':user == 'SW' || childAcct || level == 0 ? true : false
        },
        {
            'title': 'Business Information',
            'component': <EnterpriseBusinessDetails />,
            'hidden': user == "ME" || user == "SW" ? true : false
        },
        {
            'title': 'User Access',
            'component': <MobileUserAccount />,
            'hidden': user == "ME" || user == "SW" || childAcct || level == 0 ? true : false,
        }
    ]

    async function getBalance() {
        let url = process.env.REACT_APP_API_URL + "/api/get_latest_balance/"
        const yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.get(url,yourConfig)
        if (response.data) {
            setEndpoints(response.data.endpoints);
        }
    }

    function showComponent(item) {
        setSettingTitle(item)
    }

    function setTitleBlank() {
        setSettingTitle("")
        backToTop()

    }

    const titles = [
        '', 'Bank Account', 'General Settings', 'My Checkout'
    ]


    React.useEffect(() => {
        if (!childAcct || !endpoints.length) {
            console.log("not child or blank endpoints. returning")
            return;
        }

        // Redirect if URL was manually typed
        if(isMobile && !endpoints.includes('account_settings')) {
            console.log("mobile and endpoints doesn't contain. Redirecting")
            history.push(endpoints[0]);
        }
    }, [endpoints])


    // If endpoints have not been loaded yet for mobile view. Hook on endpoints will handle redirect
    if (childAcct && isMobile && !endpoints.includes('account_settings')) {
        console.log("child and endpoints doesn't contain. returning")
        return null;
    }


    return(
        <div className={acct.mainBody} style={{height: titles.includes(settingsTitle) ? '100vh' : '100%', overflow: settingsTitle == '' ? 'hidden' : ''}}>
            <MobileMain title={"Settings"} menu={settingsTitle} setTitleBlank={setTitleBlank} />
            <MetaTag title="Account Settings"/>

            <div className={acct.subBody}>
                {
                    tabs.map((item, i) => {
                        return (
                            <div>
                                <button className={acct.tabBtn}
                                    hidden={item.hidden || settingsTitle != ""}
                                    onClick={() => showComponent(item.title)}
                                    >
                                    <div className={acct.personalMenu}>
                                        <div>
                                            {item.title}
                                        </div>
                                        <div>
                                            <Icon type="right" style={{color: '#F5922F', fontWeight: 'bold'}}/>
                                        </div>
                                    </div>
                                </button>

                                <div hidden={item.title != settingsTitle} style={{display: 'flex', justifyContent: 'center'}}>
                                    {notif}
                                    {item.hidden ? "" : item.component}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}


export default MobileAccount;