import React from 'react';
import moment from 'moment';
import { Table, Pagination, Dropdown, Icon, Menu, Typography } from 'antd';

const MultiwalletTable = (props) => {
    const { data, isLoading, length, pageSize, selected, notAdmin } = props;
    const [loading, setLoading] = React.useState(false);
    const [minPage, setMinPage] = React.useState(0);
    const [maxPage, setMaxPage] = React.useState(10);
    const [searchValue, setSearchValue] = React.useState(null);
    const [currentPage, setCurrentPage] = React.useState(1);

    const returnStatus = (status) => {
        if (status === 'SU') {
            return 'Success'
        } else {
            return 'Failed'
        }
    }

    const columns = [
        {
            title: 'Reference no.',
            dataIndex: 'code',
            render: (text, record) => (
                text ?
                    <>
                        <Typography style={styles.columnField}><b>{text}</b></Typography>
                        <Typography style={styles.columnDesc}>{record.description}</Typography>
                    </>
                    :
                    <>
                        <Typography style={styles.columnDiv}>Pending</Typography>
                        <Typography style={styles.columnDesc}>{record.description}</Typography>
                    </>
            )
        },
        {
            title: selected === 'Paid' ? 'Date Paid' : selected === 'Pending' ? 'Date Expires' : selected === 'Cancelled' ? 'Date Cancelled' : 'Date Expired',
            dataIndex: selected === 'Paid' ? 'timestamp' : selected === 'Paid' ? 'date_paid' : selected === 'Cancelled' ? 'cancelled_at' : 'expire_at',
            render: (record) => moment(record).format('MMM D, YYYY h:mm A')
        },
        {
            title: 'Channel',
            dataIndex: 'channel_str',
            width: 120,
            render: (text, record) => {
                if (text)
                    return <div style={styles.columnDiv} >
                        {
                            text === record.terminal ?
                                <>
                                    <Typography style={styles.columnField}>{text}</Typography>
                                </>
                                :
                                <>
                                    <Typography style={styles.columnDiv}>{text}</Typography>
                                    <Typography style={styles.columnDesc}>{record.terminal}</Typography>
                                </>
                        }
                    </div>
                else
                    return <Typography style={styles.columnNoChannel}>Customer has not yet selected a payment channel.</Typography>
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (record) => <Typography style={{ ...styles.amount, color: '#2B2D32' }}>{"₱" + parseFloat(record).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</Typography>
        },
    ]

    const menu = (id, file, code, output_file) => (
        <Menu>
            <Menu.Item key="0">
                <a >User History</a>
            </Menu.Item>
            <Menu.Item key="1">
                <a >KYC Information</a>
            </Menu.Item>
            <Menu.Item key="2">
                <a >Transfer Money</a>
            </Menu.Item>
        </Menu>
    )


    const renderData = (history) => {
        let filteredData = [];

        if (history && history !== null) {
            filteredData = data.filter(data => data.date_str.toLowerCase().includes(history.toLowerCase())
                || data.name.toLowerCase().includes(history.toLowerCase())
                || data.id.toLowerCase().includes(history.toLowerCase()));
            return filteredData ? filteredData.slice(minPage, maxPage) : null;
        } else {
            return data ? data.slice(minPage, maxPage) : null;
        }

    }

    const changePage = (page, pageSize) => {
        setMaxPage(page * pageSize)
        setMinPage((page - 1) * pageSize)
        setCurrentPage(page)
    }

    React.useEffect(() => {
        setMinPage(0);
        setMaxPage(10);
        setCurrentPage(1);
    },[selected])

    return (
        <div>
            <Table
                rowClassName="table-row-light"
                columns={columns}
                dataSource={data && notAdmin && data['orders'] ? data['orders'] : !notAdmin && data ? renderData(searchValue) : []}
                loading={isLoading || loading}
                pagination={false}
                pageSize={!notAdmin && 20}
            />
            <Pagination
                size='small'
                total={notAdmin && data && data.summary ? data.summary.total_data : data && data.length}
                defaultPageSize={notAdmin ? 10 : 20}
                defaultCurrent={1}
                current={notAdmin && data && data.summary ? data.summary.page : currentPage}
                onChange={(page, pageSize) => changePage(page, pageSize)}
                style={{ padding: '12px 0 8px 0', textAlign: 'right' }}
            />
            {/* <ActionModal visible={showActionModal} closeModal={() => setShowActionModal(false)} onClick={(status) => handleAction(status)} type={actionType} /> */}
        </div>
    )
}

const styles = {
    positive: {
        borderRadius: 4,
        padding: '4px 8px',
        backgroundColor: 'rgb(232, 250, 243)',
        border: '1px solid #1DD28B',
        borderLeft: '5px solid #1DD28B',
    },
    negative: {
        borderRadius: 4,
        padding: '4px 8px',
        backgroundColor: '#f9dbdb',
        border: '1px solid #E24C4C',
        borderLeft: '5px solid #E24C4C',
    },
    receiverName: {
        color: '#2B2D32',
        fontSize: 14,
        fontWeight: '600',
        textAlign: 'left',
    },
    receiverEmail: {
        color: 'rgba(43, 45, 50, 0.64)',
        fontSize: 12,
        textAlign: 'left',
    },
    amount: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    columnDiv: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '120px'
    },
    columnField: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2B2D33'
    },
    columnDesc: {
        maxWidth: '150px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#2B2D33',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    columnNoChannel: {
        color: '#909196',
        fontSize: '10px',
        maxWidth: '112px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'break-spaces'
    }
}

export default MultiwalletTable;