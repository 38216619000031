import React, { useState } from "react";
import { Card, Typography, Switch, Button, Affix } from "antd";
import { history } from '../../store/history';
import CardInfoModal from "./modals/cardInfoModal";
import CancelIcon from '@material-ui/icons/Cancel';
import cardServices from './cardServices';
import crypto from 'crypto';

const CardSettings = ({ handleLockCard, lockCard, isMobile, cardInfo, lockLoading, lockCardError }) => {
  const [toggle, setToggle] = useState(false)
  const [randEncKey, setRandEncKey] = useState('')
  const [fullInfo, setFullInfo] = useState({})
  const [error, setError] = useState(lockCardError)
  const segments = localStorage.getItem('segments');

  const viewInfo = async () => {
    var key = crypto.randomBytes(16).toString('hex');
    try {
      const response = await cardServices.getFullCardInfo(key);
      if (response.apiCardNumber) {
        setRandEncKey(key);
        setFullInfo(response);
        setToggle(true);
      } else {
        setFullInfo({});
        setRandEncKey('');
      }

    } catch (error) {
      setFullInfo({});
      setRandEncKey('');
    }
  }

  return (
    <Card size='small' style={{ ...styles.root, margin: isMobile ? '5px 0' : null }} bordered={false}>
      {
        error ?
          <Affix offsetTop={20} style={{ position: 'fixed', zIndex: '100', top: '10%', left: '42%' }}>
            <div style={styles.notif}>
              <CancelIcon
                style={{
                  margin: '0 10px 0 0'
                }}
              />
              Error Locking/Unlocking Card Try Again
            </div>
          </Affix>
          : null
      }
      <CardInfoModal closeModal={() => { setToggle(false); setRandEncKey('') }} visible={toggle} cardInfo={fullInfo} encKey={randEncKey} />
      <Typography style={styles.title}>Card Settings</Typography>
      <div style={styles.main}>
        <div>
          <div style={styles.flexSubtitle}>
            <Typography style={styles.subtitle}>Lock Card</Typography>
            <Switch onChange={handleLockCard} checked={lockCard} disabled={lockLoading} size='small' className={lockCard && lockCard && `switch-btn--${segments}`} />
          </div>
          <Typography style={styles.textContent}>
            Locking your card will lock the ff. features:
            Bills payment, fund transfer, POS transactions, ATM withdrawal.
          </Typography>
        </div>
        <hr style={styles.divider} />
        <div>
          <div style={styles.flexSubtitle}>
            <Typography style={styles.subtitle}>Request New CVV</Typography>
            <button style={{ padding: '0 8px', fontWeight: 'bold' }}
              disabled={lockCard === true || lockLoading}
              className={`outline-btn--${segments}`}
              onClick={() => history.push({ pathname: '/request_cvv', state: { detail: cardInfo } })}
            >
              Request
            </button>
          </div>
          <Typography style={styles.textContent} >Last requested: Jan 13, 2020</Typography>
        </div>
        <hr style={styles.divider} />
        <div>
          <div style={styles.flexSubtitle}>
            <Typography style={styles.subtitle}>My Card Information</Typography>
            <button style={{ padding: '0 8px', fontWeight: 'bold' }}
              className={`outline-btn--${segments}`}
              onClick={() => { viewInfo() }}
              disabled={lockLoading}
            >View</button>
          </div>
        </div>
        {/*
        <div>
          <div style={styles.flexSubtitle}>
            <Typography style={styles.subtitle}>ATM PIN</Typography>
            <Button style={{padding: 0, color: '#F5922F', fontWeight: 'bold'}} type='link' onClick={()=>history.push('/update_atm_pin/step_1')}>Edit</Button>
          </div>
          <Typography style={styles.textContent} >Updated last Jan 16, 2019</Typography>
        </div>
        */}
        <hr style={styles.divider} />
        <div>
          <Typography style={{ ...styles.subtitle, padding: '7px 0' }}>Transaction Limit</Typography>
          <Typography style={styles.textContent} >
            The withdrawal limit for EON Card is <strong>PHP 50,000</strong> per day.
            This covers withdrawal via the ATM amount spent via VISA POS,
            and amount used for Online Transactions.
          </Typography>
        </div>
      </div>
    </Card>
  )
}

const styles = {
  root: {
    borderRadius: 4,
    padding: 8,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  lockCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 24,
    color: '#000000',
    padding: '7px 0'
  },
  subtitle: {
    fontSize: 16,
    fontWeight: '600',
    color: '#000000'
  },
  flexSubtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2px 0'
  },
  textContent: {
    // padding: '0px 0px 18px 0px',
    color: '#909196'
  },
  divider: {
    borderTop: '.2px solid #E6EAF0',
    width: '100%',
    right: 15,
  },
  notif: {
    backgroundColor: '#FFFFFF',
    fontSize: 14,
    fontWeight: 'bold',
    width: '100%',
    color: '#E24C4C',
    borderRadius: 4,
    padding: '8px 16px',
    textAlign: 'center',
    display: 'flex',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.24)'
  },
}

export default CardSettings;