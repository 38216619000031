export const mobileMain={
    padding: '24px 16px 200px 16px',
}

export const footerCard={
    padding: '32px 16px 16px 16px',
    boxShadow: '0px -7px 10px rgba(0, 0, 0, 0.04)',
    position: "fixed",
    zIndex: "100",
    bottom: "0",
    width: '100%',
    background: '#fff'
}


export const cameraCard={
    padding: '5px 10px 5px 5px',
    boxShadow: '0px -7px 10px rgba(0, 0, 0, 0.04)',
    position: "fixed",
    zIndex: "200",
    top: "0",
    width: '100%',
    height: '100%',
    background: '#2b2d33'
}

export const cameraCardWeb={
    boxShadow: '0px -7px 10px rgba(0, 0, 0, 0.04)',
    position: "fixed",
    zIndex: "200",
    top: "10%",
    left: "10%",
    width: '80%',
    height: '80%',
    background: '#2b2d33'
}

export const captureBtn = {
    height: '60px',
    width: '60px',
    zIndex: '999',
    borderRadius: '50%',
    border: '5px solid #f5f5f4',
    background: '#1d82b3',
    color: '#fff'

}

export const closeCamera = {
    background: '#fff',
    border: '1px solid #fff',
    color: '#2b2d33',
    fontWeight: '600',
    borderRadius: '4px',
    height: '50px',
    width: '50px',

}

export const footerHeader={
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    color: '#000000',
    paddingBottom: '16px',
    borderBottom: '2px solid #000000'
}

export const footerDesc={
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    color: '#000000'
}

export const aFooterDesc={
    marginTop: '16px',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    color: '#000000'
}

export const textStyle3 = {
    padding: '30px 0px 30px 0px',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#2b2d33',
    opacity: '80%',
}

export const reviewCard={
    width: '100%',
    background: '#fff',
    border: '1px solid #d1d5dd',
    borderRadius: '5px',
    marginTop:'20px',
    padding: '0px 0 32px 0',

}

export const aReviewCard={
    width: '100%',
    background: '#fff',
    border: '1px solid #d1d5dd',
    borderRadius: '5px',
    marginTop:'20px',
    padding: '0px 0 32px 0',
    marginBottom: '200px'

}

export const reviewCardHeader={
    display:'flex', 
    justifyContent: 'space-between', 
    borderBottom: '1px solid #54575F',
    padding: '32px 32px 16px 32px'
}

export const tableLimits={
    marginTop: 32,
    border: '1px solid #E6EAF0',
    borderRadius: '4px',
}

export const tlHeader={
    padding: '24px 0px 24px 32px',
    borderBottom: '1px solid #2B2D33',
    boxShadow: '0px 1px 0px #2B2D33',
}

export const tlText={
    padding: '16px 0px 16px 32px',
    borderBottom: '1px solid #E6EAF0',
    boxShadow: '0px 1px 0px #2B2D33',
}

export const collapseDiv={
    background: '#fff',
    borderRadius: '4px'
}

export const headerStyle={
    fontWeight: 'Bold',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-1%',
    color: '#000000'
}

export const mheaderStyle={
    fontWeight: 'Bold',
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '-1%',
    color: '#000000'
}

export const divRejected = {
    marginTop: 16,
    border: '1px solid #F8B966',
    borderRadius: '4px',
    background: 'rgba(248, 185, 102, 0.1)',
    padding: 20
}

export const titleStyle = {
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    color: '#000000'
}
export const modalBodyTips = {
    marginTop: '50px'
}
export const tipsStyle = {
    marginTop: 32,
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    color: '#000000'
}

export const blockedStyle = {
    marginTop: 32,
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    color: '#0D3D76'
}

export const StepStyle = {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000'
}
export const textStyle = {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#000000'
}

export const headerStyle2 = {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#000000'
}

export const PermaStyle = {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    wordWrap: 'break-word'
}

export const PresentStyle = {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#54575F',
    wordWrap: 'break-word'
}

export const submitBtnStyle = {
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#fafafa',
    backgroundColor: '#1D82B8',
    borderRadius: '4px',
    height: '48px',
    width: '100%'
}

export const backBtnStyle = {
    border: '1px solid #1d82b8',
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1d82b8',
    borderRadius: '4px',
    height: '48px',
    width: '100%'
}

export const disabledSubmit = {
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#fafafa',
    backgroundColor: '#1D82B8',
    borderRadius: '4px',
    height: '48px',
    width: '100%',
    opacity: '0.5'
}

export const dsubmitBtnStyle = {
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#fafafa',
    backgroundColor: '#1D82B8',
    borderRadius: '4px',
    height: '48px',
    width: '100%',
    opacity: '0.5'
}

export const tipsBtnStyle = {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '12ox',
    lineHeight: '16px',
    color: '#2B2D33',
    height: '32px',
    borderRadius: '33px',
    border: '1px solid #e6eaf0',
    alignItems: 'center'
}

export const mobileTipsBtnStyle = {
    width: '100%',
    height: '100%',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '12ox',
    lineHeight: '16px',
    color: '#2B2D33',
    height: '32px',
    borderRadius: '33px',
    border: '1px solid #e6eaf0',
    alignItems: 'center'
}



export const statusStyle = {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#F5922F',
}

export const validStyle = {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#1DD28B',
}

export const invalidStyle = {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#E24C4C',
}

export const takeSelfieStyle = {
    border: '1px solid #1d82b8',
    borderRadius: '4px',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#1d82b8',
    height: '32px',
    width: '100%',
    marginTop: '20px'
}

export const mtakeSelfieStyle = {
    border: '1px solid #1d82b8',
    borderRadius: '4px',
    height: '50px',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#1d82b8',
    width: '100%',
    marginTop: '20px'
}

export const dtakeSelfieStyle = {
    border: '1px solid #1d82b8',
    borderRadius: '4px',
    height: '50px',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#1d82b8',
    opacity: '0.5',
    width: '100%',
    marginTop: '20px'
}

export const textStyle2 = {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#54575F',
    textAlign: 'center',
    marginTop: '10px'
}

export const editStyle = {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#1D82B8',
}

export const remarkStyle = {
    fontSize: '16px',
    lineHeight: '24px',
    opacity: '0.8',
    color: '#2b2d33',
}

export const remarkStyle2 = {
    fontSize: '16px',
    lineHeight: '24px',
    opacity: '0.8',
    color: '#2b2d33',
    marginBottom: '20px',
    fontWeight: '600'
}

export const reviewStyle = {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '28px',
    lineHeight: '32px',
    color: '#000000',
    width: '906px',
    zIndex: '1',
    position: 'fixed',
    height: '100px',
    backgroundColor: '#f4f4f4',
    paddingTop: '50px',
    paddingBottom: '40px'
}

export const ApprovedStyle = {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1DD28B',
    wordWrap: 'break-word'
}

export const PendingStyle = {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'orange',
    wordWrap: 'break-word'
}

export const RejectedStyle = {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#E24C4C',
    wordWrap: 'break-word'
}

export const cancelBtnStyle =  {
    border: '2px solid #d1d5dd',
    backgroundColor: '#fff',
    color: '#2B2D33',
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '4px',
    height: '48px',
    width: '100%'

}