import { exportComponentAsPNG } from 'react-component-export-image';
import React, { useRef } from 'react';
import { message } from 'antd';
import * as ac from './style'
import * as Icons from '../../../static/icons/bux_qr/index'
import QRCode from 'react-qr-code'
import { isMobile } from 'react-device-detect'
import { Icon }  from 'antd'
import {history} from '../../../store/history'

const REACT_APP_ROOT_URL = process.env.REACT_APP_ROOT_URL
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const testMode = (history.location.pathname.indexOf('/test') > -1);
let remove_part = `.${process.env.REACT_APP_SUB_URL}`;
const isSubDomain = process.env.REACT_APP_ROOT_URL != window.location.hostname;
const master_trade_name = isSubDomain? window.location.hostname.replace(remove_part, "") : '';
const userType = localStorage.getItem('userType');

const FullQr = React.forwardRef((props, ref) => (
    
    <div align="center" style={{position: 'absolute', top: '-10000px'}}>
        <div style={ac.canvasStyle} ref={ref}>
            <div style={{width: '155px', 
                height: '64px',
                borderRadius: '16px',
                backgroundColor: '#FFF',
                position: 'relative',

                marginTop: 74
                }}>
                <img src={Icons.BuxLogo} style={{margin: '12px 0 4px 0'}} alt="BUx Logo"/>
            </div>

            <div style={ac.scanMe}>
                <div style={ac.qrDets}>
                    <QRCode value={testMode ? props.testQRLink : userType === 'SW'? props.subQRLink : props.mainQRLink} size={460} level="L" />
                </div>
            </div>

            <div style={ac.businessNameDiv}>
                {props.tradeName}
            </div>
            
            <div style={{marginTop: 50}}>
                <img src={Icons.How2Use} alt="how2use" />
            </div>

            <div style={{marginTop: -60, width: '100%', marginLeft: -12}}>
                <img src={Icons.ForMore} alt="formore" />
            </div>
        </div>
    </div>
));

const QrOnly = React.forwardRef((props, ref) => (
    <div align="center" style={{position: 'absolute', top: '-11000px'}}>
        <div ref={ref}>
            <QRCode value={testMode ? props.testQRLink : userType === 'SW'? props.subQRLink : props.mainQRLink} size={460} level="L" />
        </div>
    </div>
))

const ImgOnly = React.forwardRef((props, ref) => (
    <div align="center" style={{position: 'absolute', top: '-12000px'}}>
        <div ref={ref}>
            <img src={Icons.Accepted} />
        </div>
    </div>
))

const mobileMessage = () => {
    message.destroy()
    message.info("Please use Desktop to download your Checkout")
}

const dlBtn = (ref) => {

    if(isMobile) {
        mobileMessage()
    }

    else {
        message.destroy()
        message.loading("Please Wait...", 1.5)
        exportComponentAsPNG(ref)

    }
}



const DownloadQR = (props) => {
    const fullQrRef = useRef();
    const qrOnlyRef = useRef();
    const stickerRef = useRef();

    const btnType = [
        {
            title: 'QR Banner',
            ref: fullQrRef,
        },
        {
            title: 'QR Image only',
            ref: qrOnlyRef
        },
        {
            title: 'BUx Sticker',
            ref: stickerRef
        }
    ]

    let sub_remove_part = window.location.protocol+'//';
    const protocol = window.location.protocol;
    const subQRCheckoutUrlLink = `${REACT_APP_BASE_URL}/mycheckout/${props.tradeName}`;
    const subQRLink = protocol+'//'+master_trade_name+'.'+subQRCheckoutUrlLink.replace(sub_remove_part, "");
    const testQRLink = `https://${REACT_APP_ROOT_URL}/${props.tradeName}/test/`;
    const mainQRLink = `https://${REACT_APP_ROOT_URL}/${props.tradeName}`;

    return (
        <React.Fragment>
            <FullQr tradeName={props.tradeName} subQRLink={subQRLink} testQRLink={testQRLink} mainQRLink={mainQRLink} ref={fullQrRef} />
            <QrOnly tradeName={props.tradeName} subQRLink={subQRLink} testQRLink={testQRLink} mainQRLink={mainQRLink} ref={qrOnlyRef} />
            <ImgOnly ref={stickerRef} />

            {/* <a style={ac.btnstyle} onClick={() => dlBtn(fullQrRef, qrOnlyRef, imgOnlyRef)}>
                <img src={Icons.Download} alt="logo" />
                <div style={ac.btnText}>Download</div>
            </a> */}

            <div style={ac.dlDiv}>
                <a style={ac.iconPosition} onClick={() => props.setIsDownload(false)}>
                    <Icon type="left" style={{color:'#F5922F'}}/>
                </a>
                <div align="center" style={ac.dlHeader}>
                    Download QR Image
                </div>
            </div>

            <div style={{marginTop: 32, marginBottom: 32}} align='left'>
                <div style={ac.normalText} align="center">
                    Select to download
                </div>

                {
                    btnType.map((item, key) => {
                        return(
                            <div style={{marginTop: 12, marginbottom: 8}}>
                                <button style={ac.qrImageDownloadBtn} onClick={() => dlBtn(item.ref)}>
                                    {item.title}
                                </button>
                            </div>
                        )
                    })
                }
            </div>

        </React.Fragment>
    );
};

export default DownloadQR;