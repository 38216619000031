import {
    GET_SINGLE_PAYOUT_BEGIN,
    GET_SINGLE_PAYOUT_SUCCESS,
    GET_SINGLE_PAYOUT_ERROR,
} from './fundsConstants';
import axios from "axios";
import {Handle401} from '../handle401/handle401'
import {showAlert} from '../alert/alertAction'


export const getSinglePayoutBegin = () => {
    return {type: GET_SINGLE_PAYOUT_BEGIN};
}

export const getSinglePayoutSuccess = (single_payouts) => {
    console.log(single_payouts)
    return {type: GET_SINGLE_PAYOUT_SUCCESS, payload: single_payouts};
}

export const getSinglePayoutError = (errors) => {
    return {type: GET_SINGLE_PAYOUT_ERROR, payload: errors};
}

export const getSinglePayouts = (start='', end='', status, search, summary, email, is_export) => {
    const endpoint = '/api/payouts/?start='+start+'&end='+end+'&status='+status+'&search='+search+'&summary='+summary;
    return async (dispatch) => {
        dispatch(getSinglePayoutBegin());
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
         }

         try{
            let res = await axios.get(process.env.REACT_APP_API_URL + endpoint, yourConfig)
            dispatch(getSinglePayoutSuccess(res.data));
         }
         catch (error) {
            if((error.response)&&(error.response.status == 401)){
                Handle401()
            }
        }
       
        //  return res.data
            // .then(response => dispatch(getSinglePayoutSuccess(response.data)))
            // .catch(error => dispatch(getSinglePayoutError(error)));
    }
}