export const textStyle = {
        
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    color: '#2B2D33',
    opacity: '0.8',
    marginBottom: '20px'
}

export const bySignStyle = {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#000000',
}

export const byTerms = {
    
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#1d82b8',
}

export const createStyle = {
    
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '34px',
    color: '#2B2D33',
    marginBottom: '10px'
}

export const signupBtnStyle = {
    width: '100%',
    backgroundColor: '#F5922F',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    height: '50px',
    marginTop: '20px',
    color: '#fff',
    border: 0,
}

export const signupBtnStyleDisable = {
    width: '100%',
    backgroundColor: '#F5922F',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    height: '50px',
    marginTop: '20px',
    color: '#fff',
    opacity: '0.5',
    border: 0,
}

export const facebookBtnStyle = {
    backgroundColor: '#3B5998',
    border: '#3B5998',
    fontStyle: 'normal',
    borderRadius: '4px',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    height: '50px',
    width: '49%',
    marginRight: '2%'
}

export const googleBtnStyle = {
    backgroundColor: '#F1F1F1',
    borderRadius: '4px',
    border: '1px solid #f1f1f1',
    color: '#F1F1F1',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#2B2D33',
    height: '50px',
    width: '100%',
}

export const dontHaveStyle = {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '24px',
    color: '#2B2D33',
    display: 'flex',
    marginTop: '20px',
}

export const icon_style = {
    width: '20px',
    height: '20px',
    float: 'left',
    marginLeft: '20px'
}