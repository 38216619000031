import React from 'react'
import Main from "../../layouts/Main"
import {history } from '../../store/history'
import homeWeb from "../../static/css/Home";
import bux from "../../static/icons/bux_logo.svg";
import faqStyles from '../../static/css/Faq';
import { Layout, Button} from "antd";
import AppBar from '../appBar/appBarForm'
import FaqMain from './faq_main'
import {Grid} from '@material-ui/core'
import {Corporate, PI, Bill, Truck, Ecom, Kyc, Business, Fee} from '../../static/icons/index'
import FaqTopic from './faq_topics';
import eonLogo  from '../../static/images/eon_text_image.svg';
import HomeFooter from '../home/footer'
import { backToTop } from '../constants/constants'


const { Header } = Layout;

const FaqWeb = props => {

    const faq = faqStyles();
    const home = homeWeb();


    const [hide, setHide] = React.useState(false)
    const [show, setShow] = React.useState(true)
    const [showCorporate, setShowCorporate] = React.useState(true)
    const [showProfile, setShowProfile] = React.useState(true)
    const [showPayment, setshowPayment] = React.useState(true)
    const [showLogistic, setShowLogistic] = React.useState(true)
    const [showEcommerce, setShowEcommerce] = React.useState(true)
    const [showKyc, setShowKyc] = React.useState(true)
    const [showBusiness, setShowBusiness] = React.useState(true)
    const [showFees, setShowFees] = React.useState(true)
    const [showEon, setShowEon] = React.useState(true)
    const [showId, setShowId] = React.useState('')

    function showTopic(value){
        backToTop();
        let topics = value.toLowerCase().split(' ')[0]
        if(topics == 'enterprise'){
            setShowCorporate(false)
            setShowProfile(true)
            setshowPayment(true)
            setShowLogistic(true)
            setShowEcommerce(true)
            setShowKyc(true)
            setShowBusiness(true)
            setShowFees(true)
            setHide(true)
            setShow(false)
            setShowEon(true)
            window.location.href = `#${topics}`
        }
        else if(topics == 'profile'){
            setShowCorporate(true)
            setShowProfile(false)
            setshowPayment(true)
            setShowLogistic(true)
            setShowEcommerce(true)
            setShowKyc(true)
            setShowBusiness(true)
            setShowFees(true)
            setHide(true)
            setShow(false)
            setShowEon(true)
            window.location.href = `#${topics}`
        }

        else if(topics == 'payments'){
            setShowCorporate(true)
            setShowProfile(true)
            setshowPayment(false)
            setShowLogistic(true)
            setShowEcommerce(true)
            setShowKyc(true)
            setShowBusiness(true)
            setShowFees(true)
            setHide(true)
            setShow(false)
            setShowEon(true)
            window.location.href = `#${topics}`
        }

        else if(topics == 'logistics'){
            setShowCorporate(true)
            setShowProfile(true)
            setshowPayment(true)
            setShowLogistic(false)
            setShowEcommerce(true)
            setShowKyc(true)
            setShowBusiness(true)
            setShowFees(true)
            setHide(true)
            setShow(false)
            setShowEon(true)
            window.location.href = `#${topics}`
        }
        else if(topics == 'ecommerce'){
            setShowCorporate(true)
            setShowProfile(true)
            setshowPayment(true)
            setShowLogistic(true)
            setShowEcommerce(false)
            setShowKyc(true)
            setShowBusiness(true)
            setShowFees(true)
            setHide(true)
            setShow(false)
            setShowEon(true)
            window.location.href = `#${topics}`
        }

        else if(topics == 'know'){
            setShowCorporate(true)
            setShowProfile(true)
            setshowPayment(true)
            setShowLogistic(true)
            setShowEcommerce(true)
            setShowKyc(false)
            setShowBusiness(true)
            setShowFees(true)
            setHide(true)
            setShow(false)
            setShowEon(true)
            window.location.href = `#kyc`
        }

        else if(topics == 'business'){
            setShowCorporate(true)
            setShowProfile(true)
            setshowPayment(true)
            setShowLogistic(true)
            setShowEcommerce(true)
            setShowKyc(true)
            setShowBusiness(false)
            setShowFees(true)
            setHide(true)
            setShow(false)
            setShowEon(true)
            window.location.href = `#${topics}`
        }


        else if(topics == 'fees'){
            setShowCorporate(true)
            setShowProfile(true)
            setshowPayment(true)
            setShowLogistic(true)
            setShowEcommerce(true)
            setShowKyc(true)
            setShowBusiness(true)
            setShowFees(false)
            setHide(true)
            setShow(false)
            setShowEon(true)
            window.location.href = `#${topics}`
        }

        else if(topics == 'eon'){
            setShowCorporate(true)
            setShowProfile(true)
            setshowPayment(true)
            setShowLogistic(true)
            setShowEcommerce(true)
            setShowKyc(true)
            setShowBusiness(true)
            setShowFees(true)
            setHide(true)
            setShow(false)
            setShowEon(false)
            window.location.href = `#${topics}`
        }

    }

    function setBack(){
        backToTop();
        setHide(false)
        setShow(true)
        window.location.href = '#home'

    }

    const grid = [
        {
            icon: Corporate,
            title: 'Enterprise Account',
            id: 'enterprise'
        },
        {
            icon: PI,
            title: 'Profile Information',
            id: 'profile'
        },
        {
            icon: Bill,
            title: 'Payments',
            id: 'payment'
        },
        // {
        //     icon: Truck,
        //     title: 'Logistics'
        // },
        {
            icon: Ecom,
            title: 'Ecommerce Solutions',
            id: 'ecommerce'
        },
        
        {
            icon: Kyc,
            title: 'Know Your Customer',
            id: 'kyc'
        },

        {
            icon: Business,
            title: 'Business',
            id: 'business'
        },
        {
            icon: Fee,
            title: 'Fees',
            id: 'fees'
        },
        {
            icon: eonLogo,
            title: 'EON',
            id: 'eon'
        }
    ];

    return(
        <div>
            {
                localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null ?
                <div align='center' style={{background: '#F4F6F9'}}>
                 <Header
                 style={{
                 height: "60px",
                 display: "flex",
                 justifyContent: "space-between",
                 backgroundColor: "#fff",
                 boxShadow: "0px 3px 5px rgba(57,63,72,0.1)",
                 position:'fixed',
                 top: '1',
                 width: '100%',
                 zIndex: 1,
                 paddingLeft: '144px',
                 paddingRight: '144px',
                 }}
             >
                 <img src={bux} alt="#" style={{maxWidth: 80}} />
                 
                 {
                 localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null ?
 
                 <Button className={home.signUp} onClick={ () => window.location.href = "/login"}>Login</Button>
 
                 :
 
                 <Button className={home.signUp} onClick={ () => window.location.href = "/dashboard"}>Dashboard</Button>
 
                 }
 
             </Header>
             <div style={{paddingTop: '7%'}}>
             <div className={faq.main} align="left">
                 <div hidden={hide}>
                 <FaqMain />
 
                 <div style={{paddingBottom: '50px'}}>
                     <div className={faq.header}>
                         <p>Topics</p>
                     </div>
                     <div>
                         <Grid container spacing={3}> 
                             
                             {
                                 grid.map((item) => {
                                     return(
                                         <Grid xs={4}>
                                         <button className={faq.cardButton} onClick= {() => showTopic(item['title'])}>
                                             <img className={faq.cImage} style={{width: item['title'] === 'EON' ? 64 : 32}} src={item['icon']} />
                                             <p className={faq.cText}>{item['title']}</p>
                                         </button>
                                     </Grid>
                                     )
                                 })
                             }
                                     
                         </Grid>
                     </div>
                 </div>
                 </div>
 
                 <div hidden={show}>
                     <FaqTopic setBack={setBack} 
                    showCorporate={showCorporate}
                    showProfile={showProfile} 
                    showPayment={showPayment} showLogistic={showLogistic} 
                    showEcommerce={showEcommerce} showKyc={showKyc} 
                    showBusiness={showBusiness} showFees={showFees}
                    showEon={showEon}/>
                 </div>
 
             </div>
             </div>
            
              
                <HomeFooter />
             </div>

             :

             <Main>

            <div style={{paddingTop: '7%'}} align='center'>
            <div className={faq.main1} align="left">
                 <div hidden={hide}>
                 <FaqMain />
 
                 <div style={{paddingBottom: '50px'}}>
                     <div className={faq.header}>
                         <p>Topics</p>
                     </div>
                     <div>
                         <Grid container spacing={3}> 
                             
                             {
                                 grid.map((item, key) => {
                                     return(
                                         <Grid key={key} item xs={4}>
                                         <button className={faq.cardButton} onClick= {() => showTopic(item['title'])}>
                                             <img className={faq.cImage} style={{width: item['title'] === 'EON' ? 64 : 32}} src={item['icon']} />
                                             <p className={faq.cText}>{item['title']}</p>
                                         </button>
                                     </Grid>
                                     )
                                 })
                             }
                                     
                         </Grid>
                     </div>
                 </div>
                 </div>
 
                 <div hidden={show}>
                     <FaqTopic setBack={setBack}
                        showCorporate={showCorporate}
                        showProfile={showProfile}
                        showPayment={showPayment} showLogistic={showLogistic} 
                        showEcommerce={showEcommerce} showKyc={showKyc} 
                        showBusiness={showBusiness} showFees={showFees}
                        showEon={showEon}
                      />
                 </div>
 
             </div>
             </div>
                 
             </Main>
            }
           
        </div>
    )
}

export default FaqWeb