import axios from 'axios'


async function getLocation(){
    try {
        let response = await axios.get(
            process.env.REACT_APP_API_URL + '/api/maps/'
        );

        let data = response ? response.data : []

        return data;
    }

    catch (error) {
        console.log(error);
    }
}

async function searchLocation(value){
    console.log(value)
    try {
        let response = await axios.post(
            process.env.REACT_APP_API_URL + '/api/locator/',value
        )

        let data = response ? response.data : []

        return data;
    }

    catch (error) {
        console.log(error);
    }
    
}

export { getLocation, searchLocation}