import { updateDetailsNow } from "./basicInfoService";
import {showAlert} from '../alert/alertAction'


export const UpdateDetailsActionTypes = {
    UPDATE_INFO_REQUEST: "@@UPDATE_INFO_REQUEST",
    UPDATE_INFO_SUCCESS: "@@UPDATE_INFO_SUCCESS",
    UPDATE_INFO_FAILED: "@@UPDATE_INFO_FAILED",
  };
  export function updateDetails(value) {
    return async (dispatch) => {
      try {
        dispatch({ type: UpdateDetailsActionTypes.UPDATE_INFO_REQUEST });
        let response = await updateDetailsNow(value);
        dispatch({
          type: UpdateDetailsActionTypes.UPDATE_INFO_SUCCESS,
          payload: response.data
        });
        dispatch(showAlert({ type: "success", message: "Updated successfully" }));
      } catch (error) {
        dispatch({
          type: UpdateDetailsActionTypes.UPDATE_INFO_FAILED
        });
        console.log(error.message)
      }
    };
  }
