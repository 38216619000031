import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import configureStore from "./store/store";

import TagManager from 'react-gtm-module'
import { datadogLogs } from '@datadog/browser-logs'

datadogLogs.init({
  clientToken: 'pubab49a3fd3f07a951400f10ef933a14e3',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sampleRate: 100,
})

let user_id = localStorage.getItem('userId');

window.alert = function() {};

if(user_id==null){
	user_id = "";
}
const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    dataLayer: {
        userId: user_id,
        userProject: 'Bux'
    }
}
 
TagManager.initialize(tagManagerArgs)

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
