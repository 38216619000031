import React, {useEffect} from 'react'
import {Layout, Card, Input, Select, Button} from 'antd'
import logo from '../../static/icons/logo.svg'
import { Grid } from "@material-ui/core";
import axios from 'axios'
import {history} from '../../store/history'
import MetaTag from '../meta_tag/meta_tag'
import {ADDRESS} from '../constants/constants'

const BuyerShippingForm = props => {

    const { Header } = Layout;
    const { Option } = Select;

    const [code, setCode] = React.useState("09123-123123-123");
    const [description, setDescription] = React.useState("")


    const uid = props.match.params.uuid
    const [firstName, setFirstName] = React.useState("")
    const [lastName, setLastName] = React.useState("")
    const [contact, setContact] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [streetAddress, setStreetAddress] = React.useState("")
    const [barangayAddress, setBarangayAddress] = React.useState("")
    const [cityAddress, setCityAddress] = React.useState("")
    const [provinceAddress, setProvinceAddress] = React.useState("")
    const [countryAddress, setCountryAddress] = React.useState("")
    const [postalCode, setPostalCode] = React.useState("")
    
    
    function handleCountryAddress(value) {
        setCountryAddress(value)
    }
    function handleProvinceAddress(value) {
        setProvinceAddress(value)
        setCityAddress("")
        setBarangayAddress("")
    }
    function handleBarangayAddress(value) {
        setBarangayAddress(value)
    }

    function handleCityAddress(value) {
        setCityAddress(value)
        setBarangayAddress("")

    }

    function getProvinces() {
        let provinces = []
        for(var i in ADDRESS){
            provinces.push(<Option value={i}>{i}</Option>);
        }
        return provinces
    }

    function getCities() {
        let cities = []
        if(provinceAddress in ADDRESS){
            for(var i in ADDRESS[provinceAddress]['Municipality_list']){
                cities.push(<Option value={i}>{i}</Option>);
            }
        }
        return cities
    }

    function getBarangay() {
        let baranggays = []
        if(provinceAddress in ADDRESS){
            if(cityAddress in ADDRESS[provinceAddress]['Municipality_list']){
                let baranggay_list = ADDRESS[provinceAddress]['Municipality_list'][cityAddress]['Barangay_list'];
                for(var i=0;i<baranggay_list.length;i++){
                    baranggays.push(<Option value={baranggay_list[i]}>{baranggay_list[i]}</Option>);
                }
            }
        }
        return baranggays
    }

    useEffect(() => {

        fetchShippingDetails()

    }, [])


    const fetchShippingDetails = async() =>{

        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.get(process.env.REACT_APP_API_URL + `/api/shipping_details/${uid}/`, yourConfig);

        console.log(response.data)
        if(response.data.status == "success"){
            setCode(response.data.shipping_details.ref_code)
            setDescription(response.data.shipping_details.description)
            setFirstName(response.data.shipping_details.buyer_first_name)
            setLastName(response.data.shipping_details.buyer_last_name)
            setEmail(response.data.shipping_details.email)
            setContact(response.data.shipping_details.contact)
            setStreetAddress(response.data.shipping_details.street_address)
            setBarangayAddress(response.data.shipping_details.barangay_address)
            setCityAddress(response.data.shipping_details.city_address)
            setProvinceAddress(response.data.shipping_details.province_address)
            setPostalCode(response.data.shipping_details.postal_code)
        }
        else {
            history.push('/login')
        }
    }

    const addUpdateShippingDetails = async() =>{

        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let params = {
            'uid': uid,
            'first_name': firstName,
            'last_name': lastName,
            'email': email,
            'contact': contact,
            'street_address': streetAddress,
            'barangay_address': barangayAddress,
            'city_address': cityAddress,
            'province_address': provinceAddress,
            'postal_code': postalCode,
            'user_type': 'buyer'
        }

        let response = await axios.post(process.env.REACT_APP_API_URL + `/api/shipping_details/${uid}/`,params, yourConfig);
        if(response.data.status == "success"){
            window.location.reload()
        }
        else{
            console.log("haha")
        }
    }

    const headerStyle = {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '20px'
      }

    const orderCodeStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2B2D33',
    }

    const itemDescStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#000000',
    }

    const bodyStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#000000',
    }

    const refNoStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
        color: '#000000',
    }

    const gridStyle = {
        width: '100%',
        marginTop: '20px'
    }

    const inputStyle = {
        width: '95%',
    }
    return(
        <div>
            <MetaTag title="Shipment Form"/>

            <Header style={{height: '60px', display: 'flex', justifyContent: 'center', backgroundColor: '#fff', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)'}}>
                <img src={logo} style={{marginBottom: '20px', marginTop: '12px'}} alt="#" />
            </Header>

            <div style={{display: 'flex', justifyContent: 'center'}}> 
                <Grid item lg={5} xs={12} style={{padding: '20px 20px 20px 20px'}}>
                    <div style={headerStyle}>Shipment Details</div>
                    <Card title={<div style={refNoStyle}>Reference No: <span style={orderCodeStyle}>{code}</span></div>} extra="" >
                        <div style={itemDescStyle}>Item description</div>
                        <div style={bodyStyle}>{description}</div>
                    </Card>

                    <Grid container style={{paddingTop: '30px'}}>
                            <Grid item lg={6} xs={11} style={gridStyle}>
                                <span>First name</span>
                                <Input size="large" style={inputStyle} value={firstName} onChange={(e)=>setFirstName(e.target.value)} />
                            </Grid>
                            <Grid item lg={6} xs={11} style={gridStyle}>
                                <span>Last name</span>
                                <Input size="large" style={inputStyle}  value={lastName} onChange={(e)=>setLastName(e.target.value)} />
                            </Grid>
                            
                            <Grid item lg={6} xs={11} style={gridStyle}>
                                <span>Mobile Number</span>
                                <Input size="large" style={inputStyle }value={contact} onChange={(e)=>setContact(e.target.value)} />
                            </Grid>

                            <Grid item lg={6} xs={11} style={gridStyle}>
                                <span>Email address</span>
                                <Input size="large" style={inputStyle}  value={email} onChange={(e)=>setEmail(e.target.value)} />
                            </Grid>

                            <Grid item lg={6} xs={11} style={gridStyle}>
                                <span>Address</span>
                                <Input size="large" style={inputStyle}  value={streetAddress} onChange={(e)=>setStreetAddress(e.target.value)} />
                            </Grid>

                            <Grid item lg={6} xs={11} style={gridStyle}>
                                <div>Country</div>
                                <Select style={inputStyle}
                                size="large" defaultValue="ph" onChange={handleCountryAddress}>
                                    <Option value="ph">Philippines</Option>
                                </Select>
                            </Grid>

                            <Grid item lg={6} xs={11} style={gridStyle}>
                                <div>Province</div>
                                <Select style={inputStyle} 
                                size="large" value={provinceAddress} onChange={handleProvinceAddress}>
                                    {
                                        getProvinces()
                                    }
                                </Select>
                            </Grid>

                            <Grid item lg={6} xs={11} style={gridStyle}>
                                <div>City</div>
                                <Select style={inputStyle} 
                                size="large" value={cityAddress} onChange={handleCityAddress}>
                                    {getCities()}
                                </Select>
                            </Grid>

                            <Grid item lg={6} xs={11} style={gridStyle}>
                                <span>Barangay</span>
                                <Select style={inputStyle} 
                                size="large" value={barangayAddress} onChange={handleBarangayAddress}>
                                    {getBarangay()}
                                </Select>
                            </Grid>

                            <Grid item lg={6} xs={11} style={gridStyle}>
                                <span>Postal / ZIP code (optional)</span>
                                <Input size="large" style={inputStyle}  value={postalCode} onChange={(e)=>setPostalCode(e.target.value)} />
                            </Grid>
                            
                            <Button size="large" type="primary" style={{marginTop: '20px'}} onClick={()=>addUpdateShippingDetails()} block>Submit</Button>
                        </Grid>
                </Grid>
            </div>
            
        </div>
    )
}

export default BuyerShippingForm