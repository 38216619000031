import React from 'react';
import { PayoutIcon } from '../../static/images/multiwallet/';
import { Button, Dropdown, Icon, Input, Modal, Typography, Divider, Menu, Tooltip, Row, Col } from 'antd';
import { history } from '../../store/history';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BANKS_INSTAPAY, BANKS_PESONET, PAYMENT_MODE, BANKS } from '../../components/constants/constants';

const AdvancePayoutModal = (props) => {
    const { onClose, visible, bal, segments, payoutSettings } = props;
    const [params, setParams] = React.useState({ amount: '' });
    const { details, isLoading, buxBalance, onNext, reqMoney, reqMoneyDetails } = props;
    const userType = localStorage.getItem('userType');
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const [bank, setBank] = React.useState(null);
    const balance = payoutSettings && payoutSettings.balance? payoutSettings.balance : 0.00;
    const [invalid, setInvalid] = React.useState(false);
    const [bankDetails, setBankDetails] = React.useState([]);
    const uid = localStorage.getItem('userId');
    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    const mobileNumber = localStorage.getItem('contact');
    const email = localStorage.getItem('email');
    const [advancingParams, setAdvancingParams] = React.useState({
        amount: 0,
        receivable_amount: 0,
        tranx_fee: 0,
        fee: (payoutSettings && payoutSettings.fee) || 0,
        bank_account_id: (bankDetails && bankDetails.id) || null,
    })

    const handleChange = (e) => {
        // setParams({
        //     mode: userType === 'SW' ? "to_master" : "to_sub",
        //     target_user_id: details && details.sub_id,
        //     target_user_email: details && details.email,
        //     amount: autoFormat(event.target.value)
        // })

        const autoFormat = (value) => value == null ? null : value.replace(/[^\d.]\{0,2}$/g, '');

        setInvalid(false);
        let splitted_val = e.target.value.split(".")
        if (splitted_val.length == 1 || (splitted_val.length == 2 && splitted_val[1].length <= 2)) {        
            setAdvancingParams({
                ...advancingParams, [e.target.name]: autoFormat(e.target.value),
                receivable_amount: renderReceivable(e.target.value),
                tranx_fee: renderTransacFee(e.target.value),
                percent_fee: (payoutSettings && payoutSettings.fee) || 0,
                bank_account_id: bankDetails && bankDetails.id,
                employee_id: uid,
                firstName: firstName,
                lastName: lastName,
                mobile_number: '0' + mobileNumber,
                email: email
            })
        }

        console.log('VALUE_PAYOT', parseFloat(e.target.value) > balance);
        if(e.target.name === 'amount') {
            if((parseFloat(e.target.value) > balance) || (parseFloat(e.target.value) > (payoutSettings && payoutSettings.max_amount))) {
                setInvalid(true);
            }
        }
    }

    const resetFields = () => {
        setAdvancingParams({
            amount: 0,
            receivable_amount: 0,
            tranx_fee: 0,
            fee: (payoutSettings && payoutSettings.fee) || 0,
            bank_account_id: (bankDetails && bankDetails.id) || null,
        })
    }

    const insufficientBal = () => {
        if (((balance) < advancingParams['amount']) || (advancingParams['amount'] > (payoutSettings && payoutSettings.max_amount))) {
            return true;
        }
        return false;
    }

    const renderTransacFee = (amount) => { 
       const transacFee = (payoutSettings.fee / 100) * amount;
       return transacFee;
    }

    const renderReceivable = (amount) => {
        const finalAmount = amount - renderTransacFee(amount) 
        return finalAmount;
    }

    const destinationOptions = (
        <Menu>
            {BANKS.map((bank, i) => {
                return (
                    <Menu.Item key={bank} onClick={() => setBank(bank)}>
                        {bank}
                    </Menu.Item>
                )
            })}
            {/* <Menu.Item key="advanced" value={'2'}>
                Advanced
            </Menu.Item>
            <Menu.Item key="business" value={'3'}>
                Business
            </Menu.Item> */}
        </Menu>
    )
    
    const fetchBanks = async() => {
        let yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        } 
    
        let url = process.env.REACT_APP_API_URL + "/api/bank_accounts/"
    
        let res = await fetch(url, yourConfig);
    
        if (res.ok) { 
            let json = await res.json();
            setBankDetails(...json)
        }
        else {
            alert("HTTP-Error: " + res.status);
        }

    }

    console.log("BANK_DETAILS", bankDetails);
    

    React.useEffect(() => {
        resetFields();
        fetchBanks();
    }, [visible])

    // React.useEffect(() => {
    //     if (reqMoney) {
    //         setParams({
    //             mode: userType === 'SW' ? "to_master" : "to_sub",
    //             target_user_id: details && details.sub_id,
    //             target_user_email: details && details.email,
    //             amount: reqMoneyDetails && autoFormat(reqMoneyDetails.amount),
    //             description: reqMoneyDetails.description,
    //         })
    //     } else {
    //         setParams({
    //             mode: userType === 'SW' ? "to_master" : "to_sub",
    //             target_user_id: details && details.sub_id,
    //             target_user_email: details && details.email,
    //             amount: '',
    //         })
    //     }
    // }, [reqMoney])

    return (
        <Modal
            maskClosable
            onCancel={() => onClose()}
            visible={visible}
            footer={false}
            closeIcon={<Icon type="close" className={`text--${segments}`} />}
            width={530}
        >
            <Typography style={styles.title}>Pay me now</Typography>
            <div style={styles.balanceDiv}>
                <img alt='No Image' src={PayoutIcon} style={{ paddingRight: 10 }} />
                <div>
                <Typography style={styles.transferFont}>Funds available for advancing
                    <Tooltip title={`Balance available to request for advance payout`}><Icon type="exclamation-circle" style={{ padding: '0 4px' }} /></Tooltip></Typography>
                    <Typography style={{ ...styles.text, fontWeight: 'bold' }}>₱ {balance? balance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") : '0.00'}</Typography>
                </div>
            </div>
            <div>
                <Divider style={{margin: '10px 0 8px 0'}} />
                <Typography style={styles.maxLimit}>Max limit: ₱<b>{(payoutSettings && payoutSettings.max_amount && payoutSettings.max_amount.toLocaleString()) || 0.00}</b></Typography>
            </div>
            <div style={{ ...styles.inputDiv, padding: reqMoney ? '24px 0 0 0' : '12px 0 0 0' }}>
                <label style={styles.label}>Amount</label>
                <Input
                    size='large'
                    addonBefore="₱"
                    type='number'
                    name='amount'
                    value={advancingParams['amount']}
                    style={{ ...styles.input, border: insufficientBal() && '1px solid #C0492C', borderRadius: '2px' }}
                    onChange={handleChange}
                />
                <label hidden={!insufficientBal()} style={{...styles.label, color: '#C0492C' }}>
                    The amount cannot exceed to your funds available for advancing and your max limit</label>
            </div>
            <div style={{...styles.flexCenter, flexDirection: 'row', justifyContent: 'flex-between', border: '1px solid #D4D5D8', borderRadius: '4px', marginTop: '20px'}}>
                <div span={10} style={{...styles.flexCenter, width: '50%', borderRight: '1px solid #D4D5D8'}}>
                    <Typography style={styles.font}>Transaction Fee {`(${payoutSettings && payoutSettings.fee}%)`}</Typography>
                    <Typography style={{ ...styles.text, fontWeight: 'bold', color: '#C0492C' }}>-₱ {renderTransacFee(advancingParams.amount).toLocaleString()}</Typography>
                </div>
                <div span={10} style={{...styles.flexCenter, width: '50%'}}>
                    <Typography style={styles.font}>Receivable Amount</Typography>
                    <Typography style={{ ...styles.text, fontWeight: 'bold' }}>₱ {renderReceivable(advancingParams.amount).toLocaleString()}</Typography>
                </div>
            </div>
            <Typography style={{ ...styles.title, fontSize: 16, padding: '16px 0 0px 0' }}>Destination bank</Typography>
            <div style={styles.inputDiv}>
                {/* <label style={styles.label}>Destination</label> */}
                {/* <Dropdown overlay={destinationOptions} trigger={['click']}>
                    <a style={styles.dropdownItems} >
                         {returnKycLevel(kycLevelFilter)}
                        {bank ? bank : 'Choose destination bank'}
                        <ExpandMoreIcon style={styles.dropdownArror} />
                    </a>
                </Dropdown> */}
                <div style={styles.bankDetailsDiv}>
                    <Typography style={{ ...styles.text, fontWeight: 'bold', paddingBottom: 8 }}>{bankDetails && bankDetails.bank}</Typography>
                    <Typography style={styles.text}>{bankDetails && bankDetails.account_name}</Typography>
                    <Typography style={styles.text}>{bankDetails && bankDetails.account_number}</Typography>
                </div>
            </div>
            {/* <Divider style={styles.divider}>
                <div align="center" style={styles.or}>
                    OR
                </div>
            </Divider>
            <Button style={styles.linkBtn}>
                Link new bank account
            </Button> */}
            <div style={{ textAlign: 'right', paddingTop: 12 }}>
                <button className={`btn--${segments}`}
                    style={{ ...styles.nextBtn, cursor: advancingParams['amount'] < 1 || advancingParams['amount'] === "" || invalid ? 'not-allowed' : 'pointer',
                         opacity: isLoading || (bankDetails && !bankDetails.account_number) || advancingParams['amount'] < 1 || advancingParams['amount'] === "" || invalid ? '0.5' : '1' }}
                    onClick={() => onNext(advancingParams)} loading={isLoading}
                    disabled={ isLoading || (bankDetails && !bankDetails.account_number) || advancingParams['amount'] < 1 || advancingParams['amount'] === "" || invalid} >Send request</button>
            </div>
        </Modal>
    )
}

const styles = {
    title: {
        color: '#2B2D32',
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'left',
        paddingBottom: '20px'
    },
    transferFont: {
        color: '#2b2d32',
        opacity: '0.8',
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
        fontWeight: '400'
    },
    text: {
        color: '#2B2D32',
        fontSize: 14,
        fontWeight: '500',
        padding: 0
    },
    btn: {
        height: 40,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 4,
    },
    input: {
        fontSize: 16,
        margin: '4px 0px 0px 0px',
        color: '#2B2D33'
    },
    sourceFunds: {
        backgroundColor: '#F5F5F5',
        padding: '12px 16px'
    },
    balanceDiv: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        // paddingLeft: '24px'
    },
    font: {
        color: 'rgba(43, 45, 50, 0.8)',
        fontSize: 16,
        fontWeight: '500'
    },
    label: {
        fontSize: 12,
        color: 'rgba(43, 45, 50, 0.8)'
    },
    inputDiv: {
        padding: '6px 0 0 0'
    },
    userDetails: {
        display: 'flex',
        padding: 12
    },
    errorMsg: {
        color: '#C0492C',
        fontSize: 14,
        padding: '4px 0'
    },
    or: {
        color: '#9A9EA9',
        opacity: '0.8',
        fontSize: 16,
        fontWeight: 'bold'
    },
    divider: {
        display: 'flex',
        alignItems: 'center',
        margin: '32px 0',
        padding: '0 52px'
    },
    nextBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: '100%',
    },
    linkBtn: {
        color: '#2B2D32',
        fontSize: 16,
        fontWeight: 'bold',
        border: '1px solid #2B2D32',
        borderRadius: 4,
        width: '100%',
        height: 40,
        marginBottom: 32,
    },
    dropdownItems: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '7px 4px 7px 16px',
        fontSize: 16,
        color: '#2B2D33',
        border: '1px solid #D1D5DD',
        borderRadius: 5,
        marginTop: 4,
    },
    bankDetailsDiv: {
        padding: 16,
        border: '1px solid #D4D5D8',
        borderRadius: 8,
        textAlign: 'left'
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px',
        flexDirection: 'column',
        height: '100%'
    },
    maxLimit: {
        color: 'rgba(43, 45, 50, 0.64)',
        fontWeight: '400',
        fontSize: '16px',
        marginLeft: '34px'
    }
}

export default AdvancePayoutModal;