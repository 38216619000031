import React from 'react';
import axios from 'axios';
import { Handle401 } from "../../handle401/handle401";
import {Modal, Input, Button, notification, message, Spin} from 'antd';
import fundStyle from "../../../static/css/Funds";

const { TextArea } = Input;

const ActionModal = props => {

    var yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    };

    const f = fundStyle()
    const [remarks, setRemarks] = React.useState('')
    const [showSpin, setShowSpin] = React.useState(true);

    const {
        openModal,
        closeModal,
        statusSelected,
        rejectSelected,
        selectedIDs,
        clearTableSelection,
        setCurrentPage,
        fetchReprocess,
        fetchCounts,
        fetchCollections,
        onEndorseAction,
        onRejectAction,
        onApproveAction,
        onRetryPayoutAction,
    } = props;

    console.log(statusSelected, rejectSelected)


    async function endorseNow(){
        if (onEndorseAction) {
            onEndorseAction(setShowSpin, setRemarks, remarks);
            return;
        }

        setRemarks("")

        let params = {
            remarks,
            collection_ids: selectedIDs
        }

        try {
            let response = await axios.patch(
                process.env.REACT_APP_API_URL + "/api/admin/enterprise_collection/",
                params,
                yourConfig
            );

            let {errors} = response.data
            fetchCounts();
            setCurrentPage(1);
            fetchCollections("Checking", 1, "", "");
            closeModal()
            if (errors.length) {
                setShowSpin(true);
                errors.forEach((msg) => {
                    notification['error']({
                        message: 'Error',
                        description: msg,
                        style: {
                            width: 500,
                            marginLeft: 335 - 500,
                        },
                        duration: 0
                    });
                })
            } else {
                setShowSpin(true);
                message.success("All rows sucessfully endorsed", 6)
            }
        } catch (error) {
                setShowSpin(true);

                if(error.response){
                    closeModal()
                    message.error(error.response.data.detail, 10)
                }
      
                else if (error.response && error.response.status == 401) {
                    Handle401();
                }
        }
    }

    async function adminReject(){
        if (onRejectAction) {
            onRejectAction(setShowSpin, setRemarks, remarks);
            return;
        }

        setRemarks("")

        let params = {
            remarks,
            collection_ids: selectedIDs
        }

        try {
            let response = await axios.put(
                process.env.REACT_APP_API_URL + "/api/admin/enterprise_collection/",params,
                yourConfig
            );
      
            let { status } = response.data

            if(status=="Rejected")
            {
                fetchCounts();
                setCurrentPage(1);
                fetchCollections("Approval", 1,"" , "");
                closeModal()
                setShowSpin(true)
            }

        } catch (error) {
            setShowSpin(true);

            if(error.response){
                closeModal()
                message.error(error.response.data.detail, 10)
            }
            if (error.response && error.response.status == 401) {
                Handle401();
            }
        }
    }

    async function adminPayout(){
        if (onApproveAction) {
            onApproveAction(setShowSpin, setRemarks, remarks);
            return;
        }

        setRemarks("")

        let params = {
            collection_ids: selectedIDs
        }

        try {
            let response = await axios.post(
                process.env.REACT_APP_API_URL + "/api/admin/enterprise_collection/",params,
                yourConfig
            );
      
            let { proceeds, errors } = response.data
            fetchCounts();
            setCurrentPage(1);
            fetchCollections("Approval", 1, "", "");
            closeModal()
            if (proceeds.length) {
                setShowSpin(true);

                proceeds.forEach((msg) => {
                    notification['success']({
                        message: 'Approved and Paid Out',
                        description: msg,
                        style: {
                            width: 500,
                            marginLeft: 335 - 500,
                        },
                        duration: 0
                    });
                })
            }
      
            if (errors.length) {
                setShowSpin(true);

                errors.forEach((msg) => {
                    notification['error']({
                        message: 'Error',
                        description: msg,
                        style: {
                            width: 500,
                            marginLeft: 335 - 500,
                        },
                        duration: 0
                    });
                })
            }
        } catch (error) {
            setShowSpin(true);

            if(error.response){
                closeModal()
                message.error(error.response.data.detail, 10)
            }
            if (error.response && error.response.status == 401) {
                Handle401();
            }
        }
    }

    async function retryPayout(){
        if (onRetryPayoutAction) {
            onRetryPayoutAction(setShowSpin, setRemarks, remarks);
            return;
        }

        setRemarks("")
        let params = {
            for_reattempt: selectedIDs
        }

        try {
            let response = await axios.post(
                process.env.REACT_APP_API_URL + "/api/admin/enterprise_collection/payouts/",params,
                yourConfig
            );

            closeModal()
            setCurrentPage(1)
            let { success, errors } = response.data
            fetchReprocess("", 1)
      
            if (success.length) {
                setShowSpin(true);

                success.forEach((msg) => {
                    notification['success']({
                        message: 'Payout Reattempt Successful',
                        description: msg,
                        style: {
                            width: 500,
                            marginLeft: 335 - 500,
                        },
                        duration: 0
                    });
                })
            }
      
            if (errors.length) {
                setShowSpin(true);
                closeModal();

                errors.forEach((msg) => {
                    notification['error']({
                        message: 'Payout Reattempt Failed',
                        description: msg,
                        style: {
                            width: 500,
                            marginLeft: 335 - 500,
                        },
                        duration: 0
                    });
                })
            }
        } catch (error) {
            setShowSpin(true);

            if(error.response){
                closeModal()
                message.error(error.response.data.detail, 5)
            }
            if (error.response && error.response.status == 401) {
                Handle401();
            }
        }
    }


    return(
        <Modal
            title={
                statusSelected == "Reprocess" ? 
                <div style={{fontSize: 24, lineHeight: '32px'}}>
                    Funds Reprocess
                </div> : 
                statusSelected == "Checking" ? 
                <div style={{fontSize: 24, lineHeight: '32px'}}>
                    Funds Checking
                </div> : 
                statusSelected == "Approval" ? 
                <div style={{fontSize: 24, lineHeight: '32px'}}>
                    Funds Approval
                </div> : 

                ''
            }
            width={350}
            visible={openModal}
            onCancel={() => {
                setRemarks("");
                closeModal();
            }}
            footer={null}>

            <div style={{fontSize: 14, lineHeight: '24px'}}>
                Are you sure you want to proceed?
            </div> 

            <div style={{marginTop: 16}}>
                <div style={{marginBottom: 8, fontSize: 12, lineHeight: '24px'}}>
                    Add Remarks
                </div> 

            <TextArea maxLength={512} value={remarks} onChange={(e) => setRemarks(e.target.value)} placeholder="Enter Remarks" />

            </div>

            <div style={{marginTop: 32, display: 'flex', justifyContent: 'space-between'}}>
            <Button style={{
                            marginRight: '16px',
                            width: '50%',
                            fontWeight: 600,
                            backgroundColor: "#f5f5f4",
                            border: "#2b2d33",
                            height: "40px",
                            borderRadius: "10px",
            }}
            onClick={() => {
                setRemarks("");
                closeModal();
            }}
            >
                Cancel
            </Button>
            <Button
                style={{
                    width: '50%',
                    backgroundColor: rejectSelected ? "#d9534f" : "#1DD28B",
                    border: rejectSelected ? "#d9534f" : "#1DD28B",
                    height: "40px",
                    borderRadius: "10px",
                    opacity: !showSpin ? '0.5' : '',
                    cursor: !showSpin ? 'not-allowed' : 'pointer'
                }}

                disabled={selectedIDs.length == 0 || !showSpin}

                onClick={() => {
                    clearTableSelection();
                    setShowSpin(false);
                    statusSelected == "Reprocess" ? retryPayout() : 
                    statusSelected == "Approval" && !rejectSelected ? adminPayout() : 
                    statusSelected == "Approval" && rejectSelected ? adminReject() : 
                    endorseNow()
                    }

                }
            >
                <div hidden={showSpin}><Spin /></div>
                    <div hidden={!showSpin} className={f.textStyle}>
                    {
                        statusSelected == "Reprocess" ? "Retry Payout" : 
                        statusSelected == "Checking" ? "Endorse" : 
                        statusSelected == "Approval" && !rejectSelected ? "Payout" : 
                        statusSelected == "Approval" && rejectSelected ? "Reject" : ''}
                    </div>
            </Button>
            </div>
        </Modal>
    )
}

export default ActionModal;