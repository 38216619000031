import React, {
    useEffect,
    useState,
    useRef
} from 'react';
import * as Img from '../../../../static/images/vouchers/index';
import SampleImg from '../../../../static/img/courierph.svg';
import { Select, Input, Icon, message, Pagination } from 'antd';
import promotionServices from '../promotionServices';
import VoucherCard from './voucher_card';
import { Grid } from '@material-ui/core'
import VoucherModal from './voucher_modal';
import MetaTag from '../../../meta_tag/meta_tag';
import VoucherDrawer from './voucher_drawer';

const Vouchers = props => {
    
    let isVoucherViewed = localStorage.getItem('voucherModalView')
    const segments = localStorage.getItem('segments')
    const [promoSpan, setPromoSpan] = useState("")
    const [promoSearch, setPromoSearch] = useState("")
    const [page, setPage] = useState(1)
    const [promoData, setPromoData] = useState([])
    const [voucherData, setVoucherData] = useState([])
    const [modalState, setModalState] = useState(isVoucherViewed == 'true' ? false : true)
    const [drawerVisibility, setDrawerVisibility] = React.useState(false);
    const [featuredVoucher, setFeaturedVoucher] = useState([]);
    const [featuredSelectedData, setFeaturedSelectedData] = useState(null);
    const [activeScroll, setActiveScroll] = useState("right")

    const gradientColors = ['green', 'orange', 'blue', 'indigo', 
    'violet', 'red', 'pink', 'cyan', 'light-blue']

    const onSpanChange = (e) => {
        setPromoSpan(e)
    }

    const handlePromoSearch = (e) => {
        setPromoSearch(e.target.value)
    }

    const randomColor = (data) => {

        for(let key in data) {
            let randomItem = gradientColors[Math.floor(Math.random()*gradientColors.length)];
            data[key].color = randomItem
        }
        setVoucherData(data)
    }

    const getData = async (search, discountType, channels, start, end, page, pageSize, isExport, email, order_by) => {
        try {
            const res = await promotionServices.getPromotions(search, discountType, channels, start, end, page, pageSize, isExport, email, order_by);
            if (res.data) {
                setPromoData(res);
                randomColor(res.data)
                
            } else {
                message(res.message);
            }
        } catch (error) {
            console.log(error);
            message.error('Please try again');
        }
    }

    const getFeaturedPromos = async () => {
        try {
            const res = await promotionServices.getFeaturedVouhers();
            if (res.data) {
                console.log(res.data)
                setFeaturedVoucher(res.data)
            } else {
                message(res.message);
            }
        } catch (error) {
            console.log(error);
            message.error('Please try again');
        }
    }


    const onChangePage = (page) => {
        setPage(page);
        getData('', '', '', '', '', page, 9, '', '', promoSpan)

    }

    const onFilterApply = () => {
        getData(promoSearch, '', '', '', '', 1, 9, '', '', promoSpan)
    }

    useEffect(() => {
        if(['sme', 'biz'].includes(segments)) {
            return window.location.href = '/dashboard'
        }

        getData(promoSearch, '', '', '', '', 1, 9, '', '', promoSpan)
        getFeaturedPromos()
    }, [])
    
    const featuredCardDiv = useRef();

    const segmentsColor = {
        'me_':{
            color: '#0DAED0',
        },
        'gig':{
            color: '#41BD5C',
        },
    }

    const scroll = (scrollOffset) => {
        featuredCardDiv.current.scrollLeft += scrollOffset;
        if(scrollOffset > 0){
            setActiveScroll('left')
        }
        else{
            setActiveScroll('right')
        }
      };
    return(
        <div align="center">
            <MetaTag title="Voucher" />
            <VoucherModal modalState={modalState} setModalState={setModalState} Image={Img} segments={segments} />
            <VoucherDrawer detailsData={featuredSelectedData} drawerVisibility={drawerVisibility} setDrawerVisibility={setDrawerVisibility}/>

            <div className="voucher-new top-auto ">
                <div style={{marginLeft: '-24px'}}>
                    <img src={Img.NewWay} style={{width: 810}} alt="landing" />
                </div>
                {
                    featuredVoucher.length > 0 &&
                    <div>
                        <div className="top-32">
                            <div className="twoCol">
                                <div className="bold-text-size top-4">
                                    FEATURED PROMO
                                </div>
                            </div>
                        </div>

                        <div className="top-20">
                            <div style={{display: 'flex'}}>
                                <div style={featuredStyle.shadowDiv2} hidden={featuredVoucher.length < 3 || activeScroll == "right"}>
                                    <button onClick={()=>scroll(-500)} style={{...featuredStyle.arrowBtn, float:'left', marginLeft: '-16px'}}>
                                        <Icon type="left" style={{color: segmentsColor[segments].color}}/>
                                    </button>
                                </div>
                                <div ref={featuredCardDiv} className="featured-promo" style={featuredStyle.featureDiv}>
                                    {
                                        featuredVoucher.map((item, i) => {
                                            return(
                                                <div 
                                                    onClick={()=>{setFeaturedSelectedData(item); setDrawerVisibility(true);}} 
                                                    style={{...featuredStyle.card, backgroundImage: `url(${item.banner_blob})`, backgroundSize: 'cover', backgroundPosition: 'center', marginRight: i == 3 ? '0px' : '10px'}}>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div style={featuredStyle.shadowDiv} hidden={featuredVoucher.length < 3 || activeScroll == "left"}>
                                    <button onClick={()=>scroll(500)} style={{...featuredStyle.arrowBtn, float: 'right', marginRight: '-16px'}}>
                                        <Icon type="right" style={{color: segmentsColor[segments].color}}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                
                <div className="top-32">
                    <div className="twoCol">
                        <div className="bold-text-size top-4">
                            BUx PROMO
                        </div>
                        <div className="flex">
                            <Select
                                className="right-8"
                                placeholder="Key Account Type" 
                                style={{ width: '50%' }}
                                onChange={onSpanChange}
                                value={promoSpan}
                            >
                                <Select.Option key='1' value="latest">Latest</Select.Option>
                                <Select.Option key='2' value="oldest">Oldest</Select.Option>
                            </Select>
                            <Input
                                className="right-8"
                                placeholder="Search"
                                style={{ width: '200px', borderRadius: '4px' }}
                                suffix={<Icon type="search" style={{ fontSize: '18px' }} />}
                                onChange={handlePromoSearch}
                                value={promoSearch}
                            />
                            <button className={`btn--${segments}`} onClick={onFilterApply}>
                                Apply
                            </button>
                        </div>
                    </div>
                </div>

                <div className="top-20">
                    <Grid container>
                        {
                            voucherData && voucherData.map((item, key) => {
                                
                                return(
                                    <Grid item lg={4}>
                                        <VoucherCard data={item} color={item.color} segments={segments} />
                                    </Grid>
                                )
                            }, [])
                        }
                    </Grid>

                </div>
            
                <div align="right" className="top-20 padding-bot-20">
                    <Pagination 
                        size="small" 
                        total={promoData['total']}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        onChange={onChangePage}
                        defaultCurrent={1}
                        current={page}
                        pageSize={9}
                    />
                </div>
            </div>
        </div>
    )
}


const featuredStyle = {
    featureDiv: {
        position: 'relative', 
        maxWidth: '810px', 
        whiteSpace: 'nowrap', 
        overflow: 'scroll'
    },
    card: {
        height: '98px', 
        display: 'inline-block', 
        width: '258px', 
        borderRadius: '10px',
    },
    shadowDiv: {
        width: '120px', 
        height: '135px', 
        background: "linear-gradient(272.12deg, #F4F6F9 -0.78%, rgba(244, 246, 249, 0) 74.96%)", 
        position: 'absolute', 
        marginLeft: '640px',
        paddingTop: '36px'
    },
    shadowDiv2: {
        width: '120px', 
        height: '135px', 
        background: "linear-gradient(92.12deg, #F4F6F9 -0.78%, rgba(244, 246, 249, 0) 74.96%)", 
        position: 'absolute', 
        paddingTop: '36px',
        zIndex: 10,
    },
    arrowBtn: {
        height: '32px', 
        width: '32px', 
        borderRadius: '50%', 
        border: '0px', 
        backgroundColor: '#FFF', 
        boxShadow: "0px 8px 24px rgba(0, 35, 11, 0.12)" ,
        zIndex: 10,

    }
}

export default Vouchers;