import React,{  } from "react";
import { Modal, List, Button } from "antd";

const LearnMoreModal = ({ show, closeModal }) => {
  // const { show } = props;
  const segments = localStorage.getItem(`segments`);

  return (
    <Modal visible={show} footer={null} onCancel={closeModal} maskClosable={false} width={500}>
      <div style={{padding: '38px 0px 20px 8px', color: '#000000'}}><strong>BUx Visa Card lets you...</strong></div>
      <List 
        dataSource={[
          { title: 'Transfer your BUx Wallet Amount to your Card' },
          { title: 'Withdraw cash from any UnionBank/Bancnet/Visa ATM' },
          { title: 'View your balance and transactions in real-time' },
          { title: 'Pay your bills online' },
          { title:  'Transfer funds to a UBP/Local/eMoney Wallet Accounts' },
        ]}
        renderItem={item => (
          <List.Item style={{border: 0, padding: 8}}>
            <List.Item.Meta
              size="size"
              avatar={<span style={styles.listCircle}></span> }
              title={<label>{item.title}</label>}
            />
          </List.Item>
        )}
      />
      <button className={`btn--${segments}`} onClick={closeModal} style={styles.modalBtn}>Got It</button>
    </Modal>
  )
}

const styles ={ 
  listCircle: {
    height: 6, 
    width: 6, 
    backgroundColor: '#000000', 
    borderRadius: '50%', 
    display: 'inline-block', 
    marginTop: 8
  },
  modalBtn: {
    margin: '16px 0px 0px 0px',
    height: 40,
    width: '100%',
    fontSize: 14,
    fontWeight: '600',
  }
}

export default LearnMoreModal;