import React from 'react'
import FeeStyles from './style'
import {ChannelImg} from '../../../../static/img/feeTableIcon.js'

const HeaderCard = props => {
    
    const fee = FeeStyles();

    return(
        <div>

            <div className={fee.spaceBetween}>
                <div className={fee.cardLeftDiv}>
                    <div className={fee.headerText}>
                        Customize Channel
                    </div>
                    <div className={fee.normalText} style={{marginTop: 8}}>
                    Allows you to customize Payment Method and Channels for your Enterprise Users.
                    </div>
                </div>
                <div className={fee.cardRightDiv}>
                    <img src={ChannelImg} alt="channel" />
                </div>
            </div>
        </div>
    )
}

export default HeaderCard