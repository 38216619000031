import React from 'react'
import { Drawer, Collapse, Icon, Checkbox, Button, Select, DatePicker, Input, Row, Col} from 'antd'
import * as Style from './style';

const FilterDrawer = props => {

    const { Panel } = Collapse;
    const { Option } = Select;
    const CheckboxGroup = Checkbox.Group
    
    const {
        title, visible, onClose,
        checkAllOTC, onCheckAllOtcChange, OTC_LIST, otcCheckedList, onChangeOtc,
        checkAllWeb, onCheckAllWebChange, BANK_LIST, webCheckedList, onChangeWeb,
        checkAllWallet, onCheckAllWalletChange, MOBILE_LIST, walletCheckedList, onChangeWallet,
        setCCSelected,
        submitApplyFilter,
        disabledStartDate, disabledEndDate, onStartChange, onEndChange, handleStartOpenChange, handleEndOpenChange,
        startMoment, endMoment, endOpen
    } = props
   
      
    return (
        <Drawer
            placement="bottom"
            width="100%"
            height="100%"
            title={
                <div align="center" style={Style.titleText}>{title}</div>
            }
            visible={visible}
            onClose={onClose}>
                <div hidden={title != "Filter"} style={{marginBottom: 164}}>
                    <Collapse 
                        bordered={false} 
                        expandIconPosition="right" 
                        expandIcon={
                            ({isActive}) => 
                            <Icon style={{color: '#F5922F', paddingRight: 32}} type="up" rotate={isActive ? 0 : 180} />
                        }>
                        <Panel header={
                            <div style={Style.panelHeader}>
                                <Checkbox
                                    checked={checkAllOTC} 
                                    onChange={(e)=>onCheckAllOtcChange(e)} 
                                    value="All OTC" >
                                        Over-the-counter
                                </Checkbox>
                            </div>
                        }>  
                            <div style={Style.checkBoxStyle}>
                                <CheckboxGroup 
                                style={{width: 238}}
                                options={OTC_LIST}
                                    value={otcCheckedList} 
                                    onChange={(e)=>onChangeOtc(e)} />
                            </div>
                        </Panel>
                        <Panel header={
                            <div style={Style.panelHeader}>
                                <Checkbox checked={checkAllWeb} 
                                    onChange={(e)=>onCheckAllWebChange(e)} 
                                    value="All Web" >
                                        Web Banking
                                </Checkbox>
                            </div>
                        }> 
                            <div style={Style.checkBoxStyle}>

                            <CheckboxGroup options={BANK_LIST} 
                                value={webCheckedList} 
                                onChange={(e)=>onChangeWeb(e)} />

                            </div>
                        </Panel>
                        <Panel header={
                            <div style={Style.panelHeader}>
                                <Checkbox checked={checkAllWallet} 
                                    onChange={(e)=>onCheckAllWalletChange(e)} 
                                    value="All E-Wallet" >
                                        E-Wallet
                                </Checkbox>
                            </div>
                        }> 
                            <div style={Style.checkBoxStyle}>

                            <CheckboxGroup 
                                options={MOBILE_LIST} 
                                value={walletCheckedList} 
                                onChange={(e)=>onChangeWallet(e)} />
                            </div>
                        </Panel>
                        {
                            localStorage.getItem("userType") == "ME" ?
                            '' :
                            <Panel header={
                                <div style={Style.panelHeader}>
                                    Card Payments
                                </div>
                            }> 
                                <div style={Style.checkBoxStyle}>
    
                                <Checkbox onChange={e => 
                                    setCCSelected(e.target.checked)
                                    }>Visa/MasterCard</Checkbox>
                                </div>
                            </Panel>
                        }
               
                    </Collapse>
                </div>

                <div hidden={title != "Date Filter"} style={{padding: 16, textAlign: 'center'}}>
                    <DatePicker
                        style={{zIndex: 1,width: '45%'}}
                        disabledDate={disabledStartDate}
                        size="large"
                        format='MM-DD-YYYY'
                        value={startMoment || null}
                        placeholder="Start"
                        onChange={onStartChange}
                        onOpenChange={handleStartOpenChange}
                    />
                    <span style={{padding: '8px'}}>-</span> 
                    <DatePicker
                        style={{zIndex: 1, marginRight: '12px',width: '44%'}}
                        disabledDate={disabledEndDate}
                        suffixIcon={false}
                        size="large"
                        format='MM-DD-YYYY'
                        value={endMoment || null}
                        placeholder="End"
                        onChange={onEndChange}
                        open={endOpen}
                        onOpenChange={handleEndOpenChange}
                    />
                </div>


                <div style={title == "Filter" ? Style.fixedBtn : Style.none} hidden={!visible}>
                    <Button style={Style.mobileApplyBtn} onClick={submitApplyFilter}>
                        {
                            title == "Search Payout" ? 'Search' : 'Apply'
                        }
                    </Button>
                </div>
            
        </Drawer>
    )
}

export default FilterDrawer;