import React from 'react';

const TableValue = (name, sub_name="", amount=false) => {
    return(
        <div className="liveColor" style={{maxWidth: 112, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            {
                amount ?
                <span>
                    &#8369; {name}
                </span>
                :
                sub_name != "" ?
                <div>
                    <div><b>{name}</b></div>
                    <div>{sub_name}</div>
                </div>
                :
                name ? name : "-"
            }
        </div>
    )
} 

export default TableValue;