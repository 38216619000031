import React from "react";
import { Menu } from "antd";
import * as Icons from "../../../static/icons/sidebar/index";
import { history } from "../../../store/history";

const Merchant = (props) => {
  const {
    endpoints,
    userType,
    userKYCLevel,
    testMode,
    sideBarUpdateState,
    custId,
    isMultiwallet,
    getBalance,
    updateTitle,
    hoverState,
    hover,
    collapsed,
    subuserRole,
    subwalletTransferEnabled,
  } = props;

  const index = endpoints.indexOf("transfer");

  // Remove transfer in merchants where subwalletTransferEnabled is false
  if (index > -1 && !subwalletTransferEnabled) {
    endpoints.splice(index, 1);
  }

  // Remove transfer in masterwallet merchants where subwalletTransferEnabled is true
  if (index > -1 && subwalletTransferEnabled && userType === "CO") {
    endpoints.splice(index, 1);
  }

  const segments = localStorage.getItem("segments");

  const corporateUser =
    userType == "CO" || (userType == "SW" && subuserRole == "CO");

  let side_tabs = [
    {
      menu: "Dashboard",
      active_icon: Icons.Dashboard,
      inactive_icon: Icons.InDashboard,
      endpoint: "/dashboard",
    },
    {
      menu: "Payment Request",
      active_icon: Icons.PaymentReq,
      inactive_icon: Icons.InPaymentReq,
      endpoint: "/orders",
    },
    {
      menu: "Multi-wallet",
      active_icon: Icons.Multiwallet,
      inactive_icon: Icons.InMultiwallet,
      endpoint: "/multiwallet",
    },
    {
      menu: index > -1 && subwalletTransferEnabled ? "Account Balance" : "BUx Wallet",
      active_icon: Icons.Multiwallet,
      inactive_icon: Icons.InMultiwallet,
      endpoint: "/bux_wallet",
    },
    {
      menu: "Payout",
      active_icon: Icons.Cashout,
      inactive_icon: Icons.InCashout,
      endpoint: corporateUser ? "/funds_enterprise" : "/funds",
    },
    {
      menu: "Bank Transfer",
      active_icon: Icons.Finance,
      inactive_icon: Icons.InFinance,
      endpoint: "/transfer",
    },
    {
      menu: "Remittance",
      active_icon: Icons.Remittance,
      inactive_icon: Icons.InRemittance,
      endpoint: "/remittances",
    },
    {
      menu: "Peer Transfer",
      active_icon: Icons.Fees,
      inactive_icon: Icons.InFees,
      endpoint: "/fund_transfer_management",
    },
    {
      menu: "Revenue Wallet",
      active_icon: Icons.Fees,
      inactive_icon: Icons.InFees,
      endpoint: "/revenue_wallet",
    },
    {
      menu: "Reconciliation",
      active_icon: Icons.Card,
      inactive_icon: Icons.InCard,
      endpoint: "/recon",
    },
    // {
    //   'menu': 'Pay Bills',
    //   'icon': Paybills,
    //   'endpoint': '/cards/bills_payment',
    // },
    // {
    //   'menu': 'Shipment',
    //   'icon': Shipment,
    //   'endpoint': '/shipment_tracking',

    // },

    {
      menu: "Loans",
      active_icon: Icons.Loans,
      inactive_icon: Icons.InLoans,
      endpoint: "/refer_lender",
    },
    {
      menu: "BUx E-Money",
      active_icon: Icons.Card,
      inactive_icon: Icons.InCard,
      endpoint: "/card",
    },
    {
      menu: "Link Store",
      active_icon: Icons.LinkStore,
      inactive_icon: Icons.InLinkStore,
      endpoint: "/link_stores",
    },
    // {
    //   'menu':'Users',
    //   'icon': Developers,
    //   'endpoint': '/user_access'
    // },
    {
      menu: "Integration",
      active_icon: Icons.Developers,
      inactive_icon: Icons.InDevelopers,
      endpoint: "/integration",
    },
    {
      menu: "Voucher",
      active_icon: Icons.Voucher,
      inactive_icon: Icons.InVoucher,
      endpoint: "/vouchers",
    },
    {
      menu: "Limits Management",
      active_icon: Icons.ChartPie,
      inactive_icon: Icons.InChartPie,
      endpoint: "/limits_management",
    },
    {
      menu: "Peer Management",
      active_icon: Icons.AccountGroup,
      inactive_icon: Icons.InAccountGroup,
      endpoint: "/peer_management",
    },
    {
      menu: "Transfer Requests",
      active_icon: Icons.BankCheck,
      inactive_icon: Icons.InBankCheck,
      endpoint: "/transfer_requests",
    },
    {
      menu: "Limit Requests",
      active_icon: Icons.BankOff,
      inactive_icon: Icons.InBankOff,
      endpoint: "/limit_requests",
    },
    {
      menu: "Settings",
      active_icon: Icons.Settings,
      inactive_icon: Icons.InSettings,
      endpoint: "/account_settings",
    },
  ];

  const testAcess = ["CO", "SW"];

  function returnLink(link) {
    console.log(link);
    if (testMode && testAcess.includes(userType)) {
      history.push({ pathname: "/test" + link });
    } else history.push(link);
  }

  function multiwalletActiveState(menu) {
    const isUserHistory =
      history.location.pathname.indexOf("/user_history/subwallet/") > -1;
    const multiRoutes = ["/wallet/transfer_money", "/wallet/add_money"];
    if (
      (menu === "Multi-wallet" &&
        multiRoutes.includes(history.location.pathname)) ||
      (menu === "Multi-wallet" && isUserHistory)
    ) {
      return true;
    }
    return false;
  }

  return (
    <div>
      <Menu
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        style={{ borderRight: 0, marginTop: 20, background: "transparent" }}
        mode="inline"
      >
        <React.Fragment>
          {side_tabs.map((item, index) => {
            return (
              <a
                href
                id="sidebar-btn"
                hidden={
                  item.menu === "Loans" ||
                  !endpoints.includes(item.endpoint.slice(1)) ||
                  (corporateUser && item.menu === "BUx E-Money") ||
                  ((userType == "ME" ||
                    (userType == "SW" && subuserRole == "ME")) &&
                    ["Reconciliation", "Integration"].includes(item.menu)) ||
                  (corporateUser && userKYCLevel != 5 && index === -1) ||
                  (item.menu == "BUx E-Money" &&
                    userKYCLevel >= 0 &&
                    !custId) ||
                  (testMode && item.menu === "Payout") ||
                  (testMode && item.menu === "Reconciliation") ||
                  (testMode && item.menu === "Bank Transfer") ||
                  // (testMode && item.menu === "Remittance") ||
                  (testMode && item.menu === "Peer Transfer") ||
                  (testMode && item.menu === "Limits Management") ||
                  (testMode && item.menu === "Peer Management") ||
                  (testMode && item.menu === "Transfer Request") ||
                  (testMode && item.menu === "Limit Request") ||
                  (testMode && item.menu === "Revenue Wallet") ||
                  (testMode && userType !== "CO" && item.menu === "Settings") ||
                  (!isMultiwallet && item.menu == "Multi-wallet")
                }
              >
                <div
                  onClick={() => {
                    getBalance();
                    sideBarUpdateState(0);
                    updateTitle(item.menu);
                    returnLink(item.endpoint);
                  }}
                  className={`menuTextStyle menuDivStyle
                                  ${testMode ? "menuTestStyle" : ""}
                                  ${
                                    multiwalletActiveState(item.menu) ||
                                    history.location.pathname ===
                                      item.endpoint ||
                                    (history.location.pathname ===
                                      "/test" + item.endpoint &&
                                      testMode &&
                                      corporateUser)
                                      ? "activeMenuTextStyle"
                                      : ""
                                  }`}
                >
                  <div
                    className={`${collapsed ? "collapseState" : "urlState"}
                                      ${
                                        multiwalletActiveState(item.menu) ||
                                        (history.location.pathname ===
                                          item.endpoint &&
                                          !testMode) ||
                                        (hover === item.menu && !testMode)
                                          ? `bg-${segments}`
                                          : (history.location.pathname ===
                                              "/test" + item.endpoint &&
                                              testMode &&
                                              corporateUser) ||
                                            (hover === item.menu &&
                                              testMode &&
                                              corporateUser)
                                          ? `bg-${segments}`
                                          : ""
                                      }
                                    `}
                    onMouseLeave={() => hoverState("")}
                    onMouseEnter={() => hoverState(item.menu)}
                  >
                    <span>
                      <img
                        alt="icon"
                        src={
                          multiwalletActiveState(item.menu) ||
                          (history.location.pathname === item.endpoint &&
                            !testMode) ||
                          (hover === item.menu && !testMode)
                            ? item.active_icon
                            : (history.location.pathname ===
                                "/test" + item.endpoint &&
                                testMode &&
                                corporateUser) ||
                              (hover === item.menu && testMode && corporateUser)
                            ? item.active_icon
                            : testMode
                            ? item.active_icon
                            : item.inactive_icon
                        }
                        className={`menuIcon`}
                      />
                      {!collapsed ? item.menu : ""}
                    </span>
                  </div>
                </div>
              </a>
            );
          })}
        </React.Fragment>
      </Menu>
    </div>
  );
};

export default Merchant;
