import React from 'react'
import {Modal, Button, message, Input} from 'antd'
import axios from 'axios'
import { closeModal } from './content'

class UploadModal extends React.Component {

    state = {
        hover: false,
        hover2: false,
        file: null,
        fileName: '',
        uploading: false,
        modal: false,
        fromHere: false,
        uploadProgress: 0,
        name: '',
        content: ''
    }
    fileUpload = React.createRef();

    textStyle = {
        fontWeight: '300',
        color: '#000',
        textAlign: 'center',
    }

    closeAllModal = () => {
        closeModal();
    }
    handleFile = (file) => {
        var self = this;
        if(file != null){
            self.setState({
                file: file,
                fileName: file.name
            })
        }
    }
    
    uploadViaPost = () => {
        /* To Be Deprecated once all channels are covered */

        var self = this
        const yourConfig = {
            headers: {

                'Content-Type': 'multipart/form-data',
                'Authorization': "Token " + localStorage.getItem("jwtToken")
            }
        }

        const formData = new FormData();
        
        if(self.props.mode == 1)
            formData.append('file', self.state.file);
        else
            formData.append('content', self.state.content);
        formData.append('name', self.state.name);
        formData.append('type', self.props.mode);

        axios.post(process.env.REACT_APP_API_URL + `/api/content_settings/`,
            formData, yourConfig
        ).then(function (response) {
            if(response.data.status == "success"){
                self.setState({
                    modal: false,
                    uploading: false
                })
                message.success("Succesfully Uploaded")
                try{
                    window.location.reload()
                }catch(err){console.log(err)}
            }
            else{
                self.setState({
                    uploading: false
                })
                message.error(response.data.message)
                
            }
        })
        .catch(function (error) {
            self.setState({
                uploading: false
            })
            message.error("Error")
        });
    }

    getPresignedS3Request = async (channel, cutoff, fileName) => {
        const allocatorURL = process.env.REACT_APP_API_URL + `/api/recon/` + channel + `/`;
        const baseRequestParams = {
            'mode': 'get_url',
            'recon_file_name': fileName,
        };

        let allocatorRequestParams = {};
        if (channel === "7_eleven") {
            allocatorRequestParams = {
                ...baseRequestParams,
                'cutoff': cutoff,
            }

        } else {
            allocatorRequestParams = {
                ...baseRequestParams,
                'date': cutoff,
                channel,
            }
        }

        const allocatorRequestConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        };

        try {
            let response = await axios.patch(allocatorURL, allocatorRequestParams, allocatorRequestConfig);
            const presignedS3Request = response.data.s3_request;
            if (!Object.keys(presignedS3Request).length) {
                throw new Error("Presigned S3 Request is empty");
            }

            return presignedS3Request;

        } catch (err) {
            let msg = (err.response) ? err.response.data.message : err.message;
            throw new Error(`Allocation of S3 target path failed. ${msg}`);
        }
    };

    uploadFileToS3 = async (presignedS3Request, fileToUpload) => {
        const { 
            url: S3URL, 
            fields: S3Headers,
        } = presignedS3Request;

        const uploadConfig = {
            onUploadProgress: (progressEvent) => {
                const recomputedProgress = 100 * progressEvent.loaded / progressEvent.total;
                this.setState({uploadProgress: recomputedProgress});
            }
        }

        // Copy BE-generated S3 headers into the FormData we are about to send
        let s3FormData = new FormData();
        for (const header in S3Headers) {
            s3FormData.append(header, S3Headers[header]);
        }
        s3FormData.append('file', fileToUpload);

        try {
            await axios.post(S3URL, s3FormData, uploadConfig);

        } catch (err) {
            let msg = (err.response) ? err.response.data.message : err.message;
            throw new Error(`Direct file upload to S3 failed. ${msg}`);
        }

    };

    updateBackendFilePath = async (channel, cutoff, presignedS3Request) => {
        const { path: filePath } = presignedS3Request;

        const url = process.env.REACT_APP_API_URL + `/api/recon/` + channel + `/`;
        const baseUpdateParams = {
            'mode': 'update_path',
            'recon_file_attrib_name': 'recon_file',
            'recon_file_path': filePath,
        };

        let updateParams = {};
        if (channel === "7_eleven") {
            updateParams = {
                ...baseUpdateParams,
                'cutoff': cutoff,
            }

        } else {
            updateParams = {
                ...baseUpdateParams,
                'date': cutoff,
                channel,
            }
        }

        const updateConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        };

        try {
            await axios.patch(url, updateParams, updateConfig);

        } catch (err) {
            let msg = (err.response) ? err.response.data.message : err.message;
            throw new Error(`Update of target path failed. ${msg}`);
        }
    };

    triggerAsyncRecon = async (channel, cutoff) => {
        const triggerURL = process.env.REACT_APP_API_URL + `/api/recon/` + channel + `/`;
        const triggerConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': "Token " + localStorage.getItem("jwtToken")
            }
        }

        const formData = new FormData();
        let dateParamKey = (channel === "7_eleven") ? 'cutoff' : 'date';
        formData.append(dateParamKey, cutoff);

        try {
            let response = await axios.post(triggerURL, formData, triggerConfig);
            if (response.data.status === "failed") {
                throw response;
            }

        } catch (err) {
            let msg = (err.response) ? err.response.data.message : err.message;
            throw new Error(`Error triggering async recon. ${msg}`);
        }
    };

    uploadViaS3 = async () => {
        this.setState({
            uploadProgress: 0,
            uploading: true
        })

        try {
            const presignedS3Request = await this.getPresignedS3Request(this.props.channel, this.props.cutoff, this.state.fileName);
            await this.uploadFileToS3(presignedS3Request, this.state.file);
            await this.updateBackendFilePath(this.props.channel, this.props.cutoff, presignedS3Request);
            await this.triggerAsyncRecon(this.props.channel, this.props.cutoff);

            this.setState({
                uploadProgress: 0,
                modal: false,
                uploading: false
            })
            this.closeAllModal()
            message.success("Recon file uploaded. Accounting in progress. Check back later.")

        } catch (err) {
            this.setState({
                uploadProgress: 0,
                uploading: false
            })
            message.error(err.message);
        }
    }

    handleUpload = () => {
        this.uploadViaPost();
    };
    render(){
        return(
            <div>
                <Modal
                    visible={this.props.modal}
                    footer={false}
                    width="400px"
                    closable={false}
                    bodyStyle={{padding: '60px 20px 34px 20px'}}
                >
                <div>
                                
                    <label>Name</label>
                      <Input
                          placeholder=""
                          value={this.state.name}
                          onChange={(e) => {this.setState({name: e.target.value})}}
                      />
                </div>
                <div hidden={this.props.mode!=0}>
                                
                    <label>Content</label>
                      <Input
                          placeholder=""
                          value={this.state.content}
                          onChange={(e) => {this.setState({content: e.target.value})}}
                      />
                </div>
                <div style={{display: 'flex', justifyContent:'center', padding: '20px'}} hidden={this.props.mode==0}>
                    <input type="file"
                        ref={this.fileUpload}
                        style={{opacity: '0', position: 'fixed'}}
                        onChange={(e)=>this.handleFile(e.target.files[0])}
                        >
                    </input>
                    <Button 
                        onClick={()=>this.fileUpload.current.click()}
                        style={{
                            height: '40px',
                            border: '1px solid #1DD28B',
                            backgroundColor: this.state.hover2 ? '#1DD28B' : '#fff',
                            color: this.state.hover2 ? '#fff' : '#1DD28B',
                            borderRadius: '80px',
                            fontWeight: '600',
                            fontSize: '16px',
                            width: '164px'
                        }}
                        hidden={this.state.file != null}
                        onMouseEnter={()=>this.setState({hover2:true})}
                        onMouseLeave={()=>this.setState({hover2:false})}>
                        Choose File
                    </Button>
    
                    <div 
                        style={{
                            height: '40px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            border: '1px solid #1DD28B',
                            backgroundColor: this.state.hover2 ? '#1DD28B' : '#fff',
                            color: this.state.hover2 ? '#fff' : '#1DD28B',
                            borderRadius: '80px',
                            fontWeight: '600',
                            fontSize: '16px',
                            width: '164px',
                            textAlign: 'center', 
                            padding: '6px 5px 0px 10px'
                        }}
                        hidden={this.state.file == null}>
                        <div style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {this.state.fileName}
                        </div>
                        <Button 
                            onClick={() =>
                                this.setState({
                                    file: null,
                                    fileName: ''
                                })
                            }
                            style={{
                            color: '#fff', 
                            borderRadius: '50%', 
                            height: '25px', 
                            padding:'0px 5px 1px 6px', 
                            backgroundColor: '#262f38'}}>
                        x
                        </Button>
                    </div>
                </div>
                <div style={{display: 'flex', marginTop: '20px'}}>
                    <Button
                        style={{
                            backgroundColor: this.state.hover ? '#F5922F' : '#fff',
                            color: this.state.hover ? '#fff' : '#F5922F',
                            fontWeight: '600',
                            fontSize: '16px',
                            borderRadius: '10px',
                            height: '48px',
                            width: '49%',
                            border: '1px solid #F5922F'
                        }}
                        onClick={()=>
                                {
                                    this.closeAllModal()
                                }
                            }
                        onMouseEnter={()=>this.setState({hover:true})}
                        onMouseLeave={()=>this.setState({hover:false})}
                        disabled={this.state.uploading}
                    >Cancel</Button>
                    <Button 
                        style={{
                            backgroundColor: '#F5922F',
                            borderRadius: '10px',
                            height: '48px',
                            width: '49%',
                            color: '#fff',
                            fontWeight: '600',
                            fontSize: '16px',
                            border: '1px solid #F5922F',
                            opacity: this.state.uploading || (this.state.file == null  && this.state.content == '') ? '0.5' : 1, 
                            marginLeft: '2%'
                        }}
                        onClick={()=>this.handleUpload()}
                        disabled={this.state.file == null && this.state.content == ''}
                        loading={this.state.uploading}
                    >{this.state.uploading ? `Uploading ${Math.round(this.state.uploadProgress,)}%` : 'Upload'}
                    </Button>
                </div>
                </Modal>
    
            </div>
        )
    }
    
}

export default UploadModal