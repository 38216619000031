import React, {useEffect} from 'react'
import {Layout, Icon, Input, Card, Radio, Button, Modal, DatePicker, message, Select} from 'antd'
import {getOrders} from '../../orders/orderAction'
import {useSelector, useDispatch} from 'react-redux'
import * as Style from '../orders/mobile_order_style'
import axios from 'axios'
import MetaTag from '../../meta_tag/meta_tag'
import {history} from '../../../store/history'
import moment from 'moment'
import Pagination from '@material-ui/lab/Pagination';
import crypto from 'crypto';

let requestToken = ""

const SearchOrder = props => {
    
    const {Header} = Layout
    const { Option } = Select;

    const dispatch = useDispatch();

    const orders  = useSelector(state => state.orders.orders)
    const ordersSummary  = useSelector(state => state.orders.summary)
    const token = useSelector(state => state.orders.token)

    const [modalVisibility, setModalVisibility] = React.useState(false)
    const [cancelModalVisibility, setCancelModalVisibility] = React.useState(false)
    const [searchValue, setSearchValue] = React.useState(false)
    const [currentPage, setCurrentPage] = React.useState(1)
    const [summary, setSummary] = React.useState("Week")

    const orderType = props.match.params.orderType
    const searchType = props.location.search_type
    const paymentMethods  = props.location.payment_method

    const [startMoment, setStartMoment] = React.useState("")
    const [startDate, setStartDate] = React.useState("")

    const [endMoment, setEndMoment] = React.useState("")
    const [endDate, setEndDate] = React.useState("")

    const [endOpen, setEndOpen] = React.useState("")

    const [orderDetails, setOrderDetails] = React.useState(false)
    const [reason, setReason] = React.useState("Item/s no longer available")

    const testMode = (history.location.pathname.indexOf('/test') > -1 && localStorage.getItem("userType") == "CO")

    const generateToken = () => {
      let generatedRequestToken = crypto.randomBytes(16).toString('hex');
      requestToken = generatedRequestToken
      return generatedRequestToken
    }

    const disabledStartDate = startValue => {
      
      if (!startValue || !endMoment) {
        return false;
      }
      return startValue && startValue > moment().endOf('day');
    };
  
    const disabledEndDate = endValue => {
      
      if (!endValue || !startMoment) {
        return false;
      }
      return endValue.valueOf() <= startMoment.valueOf();
    };
    
    const onStartChange = value => {
      setStartMoment(value)
      setStartDate(value.format("MM-DD-YYYY"))
      setCurrentPage(1)
      setSummary("Custom")
    }
    const onEndChange = value => {
      setEndMoment(value)
      setEndDate(value.format("MM-DD-YYYY"))
      setCurrentPage(1)
      setSummary("Custom")
      dispatch(getOrders(generateToken(), orderType,1,startDate,value.format("MM-DD-YYYY"),testMode,"Custom","",paymentMethods,"All"))
    }

    const handleStartOpenChange = open => {
      if (!open) {
        setEndOpen(true)
      }
    };
  
    const handleEndOpenChange = open => {
      setEndOpen(open)
    };
    
    const orderDetailsStyle = {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        color: '#000000',
        textAlign: 'center'
    }

    const resend = async() =>{
      let params = {
        id: orderDetails.id
      }
      let yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
      }
      let response = await axios.post(process.env.REACT_APP_API_URL + "/api/orders/resend/", params, yourConfig);
      
      if(response.data.status == "success"){
        setModalVisibility(false)
        message.success('Successfully send')
      }
    }
    const cancelOrderNow = async() => {
        let params = {
          id: orderDetails.id,
          reason: reason
        }
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let response = await axios.post(process.env.REACT_APP_API_URL + "/api/orders/cancel_order/", params, yourConfig);
        
        if(response.data.status == "success"){
            SearchOrder("")
            setCancelModalVisibility(false)
            message.success("Successfully cancelled order")

        }
    }

    const changePage = (event,page) => {
      setCurrentPage(page)
      dispatch(getOrders(generateToken(), orderType,page,startDate,endDate,testMode,summary,"",paymentMethods,"All"))
    }

      

    return(
        <div style={{backgroundColor: '#f4f6f9', height: '100vh'}}>
            <MetaTag title="Search Order"/>

            <Header style={{width: '100%', height: '60px', backgroundColor: '#fff', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)'}}>
                    <div style={orderDetailsStyle}>Search Payment Request</div>
                    <Icon type="close" style={{float: 'right', marginTop: '-40px'}} onClick={()=>history.push('/mobile/orders')}/>
            </Header>
            <div style={{backgroundColor: '#fff', width: '100%', padding: '0px 20px 20px 20px'}} hidden={searchType != "date"}>
              <Select 
                size="large"
                value={summary}
                onChange={
                  (e)=> {
                    setSummary(e)
                    setCurrentPage(1)
                    if(e != "Custom"){
                      setStartDate("")
                      setStartMoment("")
                      setEndDate("")
                      setEndMoment("")
                    }
                    dispatch(getOrders(generateToken(), orderType,1,"","",testMode,e,"",paymentMethods,"All"))
                  }
                }
                style={{width:'100%', marginRight: '12px', zIndex: 1, color: '#000'}}
              >
                <Option value="All">All Time</Option>
                <Option value="Today">Today</Option>
                <Option value="Week" >Last 7 days</Option>
                <Option value="Month" >Last 30 days</Option>
                <Option value="Custom" >Custom</Option>
              </Select>

              <div style={{width: '100%', display: 'flex', marginTop: '16px'}}>
                <DatePicker
                  disabledDate={disabledStartDate}
                  size="large"
                  style={{width: '49%'}}
                  format='MM-DD-YYYY'
                  value={startMoment}
                  placeholder="Start"
                  onChange={onStartChange}
                  onOpenChange={handleStartOpenChange}
                />
                <div style={{margin: '8px'}}>-</div>
                <DatePicker
                  disabledDate={disabledEndDate}
                  size="large"
                  style={{width: '49%'}}
                  format='MM-DD-YYYY'
                  value={endMoment}
                  placeholder="End"
                  onChange={onEndChange}
                  open={endOpen}
                  onOpenChange={handleEndOpenChange}
                />
              </div>    
            </div>
            <div style={{padding:'20px'}} hidden={searchType != "search"}>
              <Input
                block 
                size="large" 
                prefix={<Icon type="search" size="large"/>} 
                onChange={(e)=>{
                    setSearchValue(e.target.value)
                    setCurrentPage(1)
                    dispatch(getOrders(generateToken(), orderType, 1,"","",testMode,"All",e.target.value,paymentMethods,"All"))
                  }
                } />
            </div>

            <div style={{padding: '20px 16px 20px 16px', background: '#f4f6f9', height: 'auto'}}>
            {
              token == requestToken && orders && orders.map((item, i) => {
                return (
                  <Card 
                    hoverable 
                    style={{
                      marginBottom: '10px',
                      backgroundColor: '#fff,',
                      borderRadius: '10px'}}
                    headStyle={{
                      paddingLeft:'16px',
                      paddingRight:'16px'
                    }}
                    bodyStyle={{
                      padding: '8px 16px 17px 16px'
                    }}
                    title={
                      <div>
                        <div style={{display:'flex'}}>
                          <div>
                            <div style={Style.codeStyle}>
                            {item.code}
                            </div>
                            <div style={Style.descriptionStyle}>
                            {item.description}
                            </div>
                          </div>
                          <Icon 
                            hidden={orderType == "Cancelled" || orderType == "Expired"}
                            type="ellipsis" 
                            style={{fontSize: '24px', position:'absolute', right: 16}} 
                            onClick={()=>setModalVisibility(true)/setOrderDetails(item)}/>
                        </div>
                        {
                          item.code == null ?
                          <div style={Style.emptyChannelStyle}>
                            Customer has not yet selected a payment channel.
                          </div> :
                          <div style={Style.codeStyle}>
                            {item.channel_str}
                          </div>
                        }
                      </div>
                    }>
                      <div>
                        <div style={Style.textStyle}>
                          {orderType == "Paid" ? "Paid On" : orderType == "Pending" ? "Expires After" : orderType == "Cancelled" ? "Cancelled On" : "Expired On"}
                        </div>
                        <div style={{display:'flex', justifyContent: 'space-between', marginTop:'5px'}}>
                          <div style={Style.expireStyle}>
                            { 
                            orderType == "Paid" ? moment(item.date_paid).format('MMMM DD YYYY h:mm A'): 
                            orderType == "Pending" ? moment(item.expire_at).format('MMMM DD YYYY h:mm A') : 
                            orderType == "Cancelled" ? moment(item.cancelled_at).format('MMMM DD YYYY h:mm A') : 
                            moment(item.expire_at).format('MMMM DD YYYY h:mm A')
                            
                            }
                          </div>
                          <div style={Style.codeStyle}>
                            PHP {item.amount}
                          </div>
                        </div>
                      </div>
                  </Card>
                )
              },this)
            }
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}} hidden={ordersSummary && ordersSummary.total_data / 10 < 1}>
              <Pagination 
                count={ordersSummary && ordersSummary.pages} 
                shape="rounded"
                page={currentPage} 
                size="small"
                onChange={changePage}
              />
            </div>
            <Modal
                style={{ top:  '50%' }}
                visible={modalVisibility}
                closable={false}
                footer={<div style={{display: 'flex', justifyContent: 'center', padding: '10px 0 10px 0'}}>
                <span style={Style.buttonStyle} onClick={()=>setModalVisibility(false)}> Cancel</span>
                </div>}
                >
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                  <a href={`/payment/${orderDetails.uid}/`} style={{color: '#2B2D33', opacity: '0.8'}}>
                    <span size="large" style={Style.buttonStyle}> View payment link</span>
                  </a>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                  <a href={`/order_details/${orderDetails.uid}/`} style={{color: '#2B2D33', opacity: '0.8'}}>
                    <span size="large" style={Style.buttonStyle}>View order details </span>
                  </a>
                </div>
                {
                  orderDetails.status == "Pending" && orderDetails.code ?
                  <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <span style={Style.buttonStyle} onClick={()=>resend()}>Resend payment link</span>
                  </div>
                  :""
                }
                <div style={{display: 'flex', justifyContent: 'center',color: '#E24C4C'}}>
                  <span style={Style.buttonStyle} onClick={()=>setCancelModalVisibility(true)/setModalVisibility(false)} >Cancel order</span>
                </div>
                </Modal>

                <Modal
                visible={cancelModalVisibility}
                footer={null}
                onCancel={()=>setCancelModalVisibility(false)}
                >
                <div style={{ padding: '20px 30px 20px 30px' }}>
                <div style={Style.cancelOrder}>Cancel Order</div>
                <div style={Style.choicesStyle}>Provide a reason</div>
                <div style={{marginBottom: '10px'}}>
                    <Radio.Group onChange={(e) => setReason(e.target.value)} defaultValue="Item/s no longer available">
                    <Radio style={Style.radioStyle} value="Item/s no longer available">
                        Item/s no longer available
                        </Radio>
                    <Radio style={Style.radioStyle} value="Unavailable color or size">
                        Unavailable color or size
                        </Radio>
                    <Radio style={Style.radioStyle} value="Unable to ship the item">
                        Unable to ship the item
                        </Radio>
                    <Radio style={Style.radioStyle} value="Payment was declined">
                        Payment was declined
                        </Radio>
                    </Radio.Group>
                </div>
                <div>
                    <Button type="danger" style={Style.cancelButtonStyle} onClick={() => cancelOrderNow()}>Cancel and notify customer</Button>
                </div>
                <Button style={Style.dontCancelButton} onClick={()=>setCancelModalVisibility(false)}>No, don’t cancel</Button>
                </div>
            </Modal>
        </div>


    )
}

export default SearchOrder