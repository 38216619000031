import axios from "axios";
import { history } from "../../store/history";

const testMode = (history.location.pathname.indexOf('/test') > -1);

async function signUp(value) {
  let params = {
    email: value.email,
    password: value.password,
    contact: value.contact,
    messenger_id: value.messenger_id,
    contact: value.mobileNumber,
    nature_business: value.natureOfBusiness,
    utm: Object.fromEntries(new URLSearchParams(window.location.search)),
    merchant_type: value.merchantType,
    referrer: value.referrer
  };

  if(value.merchantType === 'CO') {
    params.business_name = value.businessName
  } else {
    params.first_name = value.firstName
    params.last_name = value.lastName
  }

  if('promo' in value){
    params.promo = value.promo
  }
  
  let remove_part = `.${process.env.REACT_APP_SUB_URL}`;
  let trade_name = window.location.hostname.replace(remove_part, "");
  const endpoint = process.env.REACT_APP_ROOT_URL === window.location.hostname ?
                      '/api/sign_up/v3/'
                      :
                      testMode ? `/api/sandbox/signup/${trade_name}/` : `/api/signup/${trade_name}/`
                      ;
  
  let response = await axios.post(
    process.env.REACT_APP_API_URL + endpoint,
    params
  );
  
  return response;
}


async function fbSignUp(params) {

  let response = await axios.post(
    process.env.REACT_APP_API_URL + "/api/facebook/auth/",
    params
  );
  
  return response;
}


async function googleSignUp(params) {

  let response = await axios.post(
    process.env.REACT_APP_API_URL + "/api/google/auth/",
    params
  );
  
  return response;
}


export {signUp, fbSignUp, googleSignUp};
