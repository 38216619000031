import { useEffect, useState } from "react";
import { axios } from "../../../lib/axios";

/**
 * @typedef {Object} Revenue
 * @property {number} id
 * @property {string} business_name
 * @property {number} source_id
 * @property {string} source_name
 * @property {string} source_email
 * @property {string} source_mobile
 * @property {number} revenue_id
 * @property {string} revenue_name
 * @property {string} revenue_email
 * @property {string} revenue_mobile
 * @property {string} amount
 * @property {string} fee
 * @property {string} rail_type
 * @property {string} remarks
 * @property {string} created_at
 */

/**
 * @typedef {Object} RevenuesData
 * @property {Revenue[]} results
 * @property {number} total
 * @property {number} page
 * @property {number} pages
 * @property {number} total_fees
 */

/**
 * Fetches revenues from the API.
 * @param {number} currentPage
 * @returns {Promise<RevenuesData>}
 */
export function getRevenues(currentPage) {
  return axios.get(`/api/transfer/revenue/?page=${currentPage}`);
}

/**
 * Custom hook to fetch revenues.
 * @param {number} currentPage
 * @returns {{
 *   data: RevenuesData | null,
 *   isLoading: boolean,
 *   error: Error | null
 * }}
 */
export function useRevenues(currentPage) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getRevenues(currentPage);
        setData(response);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, [currentPage]);

  return { data, isLoading, error };
}
