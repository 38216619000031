import React,{ useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Icon } from 'antd';
import MainContent from './Main';
import News from './News';
import { history } from '../../../store/history';

const NotifPromotionPage = () => {
  const [initialized, setInitialized] = useState(false);

  if (!initialized) {
    const current_path = window.location.pathname
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
        history.push('/login?next=' + current_path)
    } else {
      if (isMobile) {
        history.push('/mobile/promotion_page');
      }
    }
    setInitialized(true);
  }

  return (
    <div style={styles.root}>
      <div>
        <div style={styles.btnDiv}>
          <Button style={styles.btnBack} onClick={() => history.goBack()}>{<Icon type='left' />} Back</Button>
        </div>
        <div style={styles.main}>
          <div style={{paddingRight: isMobile? 0 : 15}}>
            <MainContent />
          </div>
          <div style={{paddingLeft: isMobile? 0 : 15}}>
            <News />
          </div>
        </div>
      </div>
    </div>
  )   
}

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: isMobile ? '102px 20px 30px 20px' : '74px 0 0 0',
  },
  btnBack: {
    border: 0, 
    borderRadius: 40, 
    color: '#F5922F', 
    fontSize: 16
  },
  main: {
    padding: '30px 0 0 0',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: isMobile? 'column' : 'row',
  },
  btnDiv: {
    position: 'fixed',
    height: 104,
    width: '100%',
    backgroundColor: isMobile? '#fff' : '#F4F6F9',
    zIndex: '2',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    top: isMobile? 58 : 64,
  }
}

export default NotifPromotionPage;