import React,{ useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cardActions from '../../../components/card/cardActions';
import cardServices from '../../../components/card/cardServices';
import EonCard from '../../../components/card/eonCard';
import CardSettings from '../../../components/card/cardSettings';
import TransactionHistoryMobile from '../../../components/card/transactionHistoryMobile';
import MetaTag from '../../meta_tag/meta_tag';
import NoEon from '../../card/noEon';
import { Spin, Typography } from 'antd';
import { ErrorCard } from '../../../static/icons/dashboard_icons';
import { history } from '../../../store/history';

const CardMobile = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [visibleLearnModal, setVisibleLearnModal] = useState(false);
  const [lockCard, setLockCard] = useState(null);
  const cardInfo = useSelector(state => state.card.cardInfo);
  const buxBalance = useSelector(state => state.card.buxBalance);
  const [noEon, setNoEon] = React.useState(null);
  const [lockLoading, setLockLoading] = useState(false)
  const [custId, setCustId] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
      background: '#F4F6F9',
      position: 'fixed',
      width: '100%',
      zIndex: 100,
      marginTop: '-1px'
    },
    indicator: {
        backgroundColor: '#0D3D76',
        height: 2,
    },
    tabStyle: {
      textTransform: 'capitalize', 
      fontWeight: 'bold', 
      padding: '12px 0px 16px 0px',
      fontSize: 16,
    },
    spinner:{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: 500,
    }
});

const toggleLearnModal = () => {
  setVisibleLearnModal(!visibleLearnModal);
}

const getCardInfo = async () => {
  try {
    const response = await cardServices.getCardInfo();
    dispatch(cardActions.setBuxBalance(response.bux_balance));
    setNoEon(response.lastFourDigits)
    dispatch(cardActions.setCardInfo(response));
    setCustId(response.customer_id)
    if(localStorage.getItem('Level') >= 0 && !response.customer_id) {
        history.push('/mobile/dashboard')
      }
    if(response.status == '1'){
      setLockCard(false);
      dispatch(cardActions.setCardLock(false));
    }else{
      dispatch(cardActions.setCardLock(true));
      setLockCard(true);
    }
  } catch (error) {
    console.log(error);
  }
}

const handleLockCard = () => {
  setLockCard(!lockCard)
  callLock(!lockCard);
  dispatch(cardActions.setCardLock(!lockCard));
}

const callLock = async (status) => {
  setLockLoading(true);
  try {
    const response = await cardServices.callLock(status);
    // console.log(response);
    if (response.status == 'success') {
      
      console.log(lockCard)
    } else {
      setLockCard(!lockCard)
      dispatch(cardActions.setCardLock(!lockCard));
    }
    setLockLoading(false);
  } catch (error) {
    setLockCard(!lockCard)
    dispatch(cardActions.setCardLock(!lockCard));
    setLockLoading(false);
    console.error(error);
  }
}

if(!initialized) {
  if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
    getCardInfo();
  } else {
    history.push('/dashboard');
  }
  setInitialized(true);
}

const classes = useStyles();

const handleChange = (event, newValue) => {
    setTab(newValue);
}

const errorCard = (
  <div style={{display: 'flex', flexDirection: 'column', padding: 50 }}>
    <img alt={'No Image'} src={ErrorCard} style={{padding: '0 0 16px 0'}} />
    <Typography style={styles.disabledText}>We're sorry</Typography>
    <Typography style={styles.disabledText}>Unavailable at the moment</Typography>
    <Typography style={{...styles.disabledText, padding: '16px 0 0 0', fontWeight: 'bold'}}>Please try again later</Typography>
  </div>
)

  return (
    <div>
      <div className={classes.root}>
        <MetaTag title='Card' />
        <Tabs 
          variant="fullWidth"
          centered
          textColor={'inherit'}
          classes={{indicator: classes.indicator }}
          onChange={handleChange}
          value={tab}
          aria-label="icon label tabs example"
          >
          <Tab label="EON Card" style={{color: tab == 0 ? '#0D3D76' : '#2B2D33', background: '#FFF'}} className={classes.tabStyle}/>
          <Tab label="Transaction History" style={{color: tab == 1 ? '#0D3D76' : '#2B2D33', fontSize: 12, background: '#FFF'}} className={classes.tabStyle} />
          <Tab label="Settings" style={{color: tab == 2 ? '#0D3D76' : '#2B2D33', background: '#FFF'}} className={classes.tabStyle} />
        </Tabs>
      </div>
      <div style={{ padding: '70px 20px 10px 20px', backgroundColor: '#F4F6F9', height: '150vh' }}>
        { tab == 0 && cardInfo && lockCard !== null && noEon !== '' ? 
          <EonCard 
            toggleModal={toggleLearnModal} 
            sideBar={false} 
            cardActivated={false} 
            lockCard={lockCard} 
            isMobile={true}
            cardNumber={cardInfo ? cardInfo.lastFourDigits : '' } 
            balance={cardInfo ? cardInfo.account.availableBalance : null} 
            expiryDate={cardInfo ? cardInfo.expiryDate : null }
            buxBalance={buxBalance}
            noEon={cardInfo ? cardInfo.lastFourDigits : ''}
            physicalCard={cardInfo ? cardInfo.physical : null}
          /> 
          : tab == 1 && cardInfo && lockCard !== null && noEon !== ''  ? 
            <TransactionHistoryMobile /> 
          : tab == 2 && cardInfo && lockCard !== null && noEon !== ''  ?
            <CardSettings 
                isMobile={true} 
                cardInfo={cardInfo ? cardInfo : '' } 
                lockCard={lockCard}  
                lockLoading={lockLoading}
                handleLockCard={handleLockCard}   
              />
          : cardInfo && noEon == '' && !custId ? 
            <NoEon isMobile={true} />
          : cardInfo && noEon == '' && custId ?
            errorCard
          : <Spin size="large" className={classes.spinner} />
        }
      </div>
    </div>
  )
};

const styles = {
  disabledText: {
    color: '#909196', 
    fontSize: 16, 
    textAlign: 'center'
  }
}

// handleLockCard={handleLockCard} 
// lockCard={lockCard} 
// cardInfo={cardInfo}  
// lockLoading={lockLoading}

export default CardMobile;
