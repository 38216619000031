import React from 'react';
import enterpriseStyle from '../../static/css/Enterprise'
import Ubpcard from '../cards/web/ubpcard';
import {Input, Select} from 'antd';
import BusinessInformation from './enterprise/businessInformation';
import {isMobile} from 'react-device-detect';
import Main from '../../layouts/Main'

const UpgradeEnterprise = props => {

    const es = enterpriseStyle();

    const {Option} = Select;


    return(
        <div align='center'>
            <div className={es.mainBody}>
                <div className={es.mainHeader} align='left'>Enterprise Account</div>
                
                {/* <Ubpcard/> */}

                <div align="left">
                    <div>
                   <BusinessInformation/>
                   </div>
                </div>
            </div>
        </div>
    )
}

export default UpgradeEnterprise;