import React from 'react';
import { Card, Typography, Icon, Button, Tooltip } from 'antd';
import { PayoutIcon } from '../../static/images/multiwallet/';
import BuxBlue from '../../static/icons/bux_blue.svg'
import * as Icons from '../../static/new_segment/icons';
import { isMobile } from 'react-device-detect';
import { Link } from "react-router-dom";

const { Text } = Typography;

const HeaderInfo = (props) => {
    const { bal, payoutType, segments, onPayoutClick, onAdvanceClick, advancingData } = props;
    const userType = localStorage.getItem('userType');
    const payout_enabled = localStorage.getItem("payout_enabled");
    const is_bank_approved = localStorage.getItem("is_bank_approved") === 'true';
    const has_cashbux_privilege = localStorage.getItem("has_cashbux_privilege") === 'true';
    const defined_wallet_settings = JSON.parse(localStorage.getItem('defined_wallet_settings'));
    const payout_type = defined_wallet_settings["payout_type"] ? defined_wallet_settings["payout_type"] : 0;
    const floatBalance = localStorage.getItem("float_balance") && localStorage.getItem("float_balance").toLocaleString("en-US", { minimumFractionDigits: 2 }).replace(/[^\d\.]/g, '');
    const kybRequired = localStorage.getItem("kybRequired");
    const level = localStorage.getItem('Level');
    const disbaledPayoutForSub = ((localStorage.getItem("userType") === 'SW' && level == "0" && kybRequired && parseFloat(floatBalance) <= 0)) || payout_type == 1;
    const isChild = localStorage.getItem('is_child') === 'true';
    const kybStep = localStorage.getItem('KYBStep');
    const step = localStorage.getItem('Step');
    const buxBalance = localStorage.getItem('balance') || `₱ 0.00`;
    const isNotCorporate = userType === 'ME' || userType == "SW" || userType === "CO" && localStorage.getItem("is_multiwallet") === "true" || isChild;
    const hasCashbuxPrivilege = localStorage.getItem('has_cashbux_privilege');

    return (
       <div style={styles.wrapper} hidden={userType === 'AD'}>
            <Card
            style={{...styles.root, width: (isNotCorporate || isMobile || advancingData && !advancingData.payout_advancing_enable) && '100%', margin: isMobile? '6px' : '38px 6px 38px 20px'}}
            bodyStyle={styles.bodyStyle}
        >
            <div style={styles.div1}>
                {/* <Typography style={styles.title} hidden={isMobile}>Payout</Typography> */}
                <div style={styles.balanceDiv}>
                    <img alt='No Image' src={isNotCorporate ? BuxBlue : Icons.AdvancePayout} style={{ paddingRight: 10 }} />
                    <div>
                        {
                          /*** 
                            If CashBux user, display 'Account Balance'
                          */
                        }
                        <Typography style={styles.transferFont}>{hasCashbuxPrivilege === "true" ? "Account Balance" : isNotCorporate ? 'BUx Wallet Balance' : 'Transferable Amount'}
                            <Tooltip title={`Balance that you can transfer through ${!isNotCorporate ? ' another wallet or' : ''} payout`}><Icon type="exclamation-circle" style={{ padding: '0 4px' }} /></Tooltip></Typography>
                        <Typography style={{ ...styles.text, fontWeight: 'bold' }}>{bal}</Typography>
                    </div>
                </div>
            </div>
            {has_cashbux_privilege && ['SW'].includes(userType) ? (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                    <button 
                        className={`btn--${segments}`}
                        disabled={ !is_bank_approved }
                        onClick={onPayoutClick}
                        style={{
                            ...styles.payoutBtn,
                            opacity: !is_bank_approved ? '0.5' : '1',
                        }}
                    >
                        Payout {" "}
                        {!is_bank_approved && 
                            <Tooltip title="Please update your bank details in Settings > Bank Account to use this feature.">
                                <Icon type='exclamation-circle' />
                            </Tooltip>
                        }
                    </button>
                </div>
            ) : (
                <button 
                    className={`btn--${segments}`}
                    disabled={ payout_enabled != "true" || (level == 0 && ['ME', 'CO'].includes(userType)) || disbaledPayoutForSub }
                    onClick={onPayoutClick}
                    hidden={userType === "CO" && localStorage.getItem("is_multiwallet") != "true" || isChild || payout_type == 1}
                    style={{
                        ...styles.payoutBtn,
                        opacity:  payout_enabled != "true" || (level == 0 && ['ME', 'CO'].includes(userType)) || disbaledPayoutForSub ? '0.5' : '1',
                    }}
                >
                    Payout
                </button>
            )}
        </Card>
        <Card
            style={{...styles.root, width: isMobile && '100%', margin: isMobile? '6px' : '38px 6px 38px 6px'}}
            bodyStyle={styles.bodyStyle}
            hidden={isNotCorporate || (advancingData && !advancingData.payout_advancing_enable) }
        >
            <div style={styles.div2}>
                <div style={{ ...styles.flexDiv }}>
                    <img alt='No Image' src={Icons.AdvancePayout} style={{ paddingRight: 10 }} />
                    <div>
                        <Typography style={styles.transferFont}>Funds available for advancing
                            <Tooltip title={`Balance available to request for advance payout`}><Icon type="exclamation-circle" style={{ padding: '0 4px' }} /></Tooltip></Typography>
                        <Typography style={{ ...styles.text, fontWeight: 'bold' }}>{buxBalance}</Typography>
                    </div>
                </div>
                <button className={`btn--${segments}`} style={{...styles.payoutBtn, width: '140px'}} onClick={onAdvanceClick} >
                    Pay me now
                </button>
                <div hidden={payoutType === 'manual'} style={styles.flexDiv}>
                    <img alt='No Image' src={Icons.PayoutCalendar} style={{ paddingRight: 10 }} />
                    <Button type='link' style={styles.text}>View Payout schedule</Button>
                </div>
            </div>
        </Card>
       </div>
    )
}

const styles = {
    wrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        flexDirection: isMobile ? 'column' : 'row',
        padding: isMobile && 16
    },
    root: {
        margin: isMobile ? '6px' : '38px 20px',
        padding: '0px'
    },
    title: {
        color: '#000',
        fontWeight: 'bold',
        fontSize: 29
    },
    bodyStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flexDirection: isMobile ? 'row' : 'row',
        padding: '20px'
    },
    div1: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    div2: {
        width: '100%',
        display: 'flex',
        justifyContent: isMobile? 'space-between' : 'flex-start',
        alignItems: 'center',
        gap: '12px'
        // marginTop: isMobile && '16px'
    },
    balanceDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingLeft: 0
    },
    text: {
        color: '#2B2D32',
        fontSize: 14,
        fontWeight: '500',
        padding: 0
    },
    transferFont: {
        color: '#2b2d32',
        opacity: '0.8',
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
        fontWeight: '400'
    },
    flexDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    payoutBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: 100,
        cursor: 'pointer'
    },
    payNowBtn: {
        height: 40,
        width: 140,
        paddingRight: 10,
        cursor: 'pointer'
    }
}

export default HeaderInfo;
