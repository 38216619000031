import { isMobile } from 'react-device-detect'
import * as Icon from '../../../static/icons/bux_qr/index'

export const normalText = {
    fontWeight: 'normal',
    fontSize: isMobile ? '12px' : '16px',
    color: '#000000'
}

export const dpSupport = { 
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    borderRadius: '10px',
    boxSizing: 'border-box',
    border: '1px solid #E6EAF0'
}

export const payBtn = {
    width: '100%',
    height: isMobile ? '40px':'48px',
    color: '#fff',
    fontWeight: '600',
    backgroundColor: '#F5922F',
    border: '1px solid #F5922F',
    borderRadius: isMobile ? '10px' : '4px'
}

export const semiBoldText = {
    fontWeight: '600',
    fontSize: '16px',
}

export const canvasStyle = {
    
    border: "solid 1px gray",
    display: "block",
    background: '#192E66',
    width: 1187,
    height: '1700px',
    margin: '32px 0px 10px 0px',
    padding: 0,
    backgroundImage: `url(${Icon.DownloadBg})`,
    backgroundSize: '100%', 
    backgroundRepeat: 'no-repeat',
    position: 'relative',

}

export const scanMe = {
    position: 'relative',
    marginTop: '80px',
    width: '100%',
    height: '50%',
    marginLeft: '21%',
    backgroundImage: `url(${Icon.ScanMe})`,
    backgroundRepeat: 'no-repeat',
}

export const qrDets = {
    position: 'absolute',
    top: 250,
    left: 120,
    maxWidth: '662px', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    whiteSpace: 'nowrap',
}

export const businessNameDiv = {
    marginTop: 58,
    width: 680,
    height: '85px',
    background: ' #fff',
    color: '#0D3D76',
    fontWeight: 'bold',
    fontSize: 32,
    padding: 20,
    maxWidth: '672px', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    whiteSpace: 'nowrap',
}

export const tradeNameStyle = {
    marginTop: '16px',
    background: '#E6EAF0',
    borderRadius: '100px',
    width: 380,
    height: '68px',
    padding: '14px 40px 14px 40px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 40,
    lineHeight: '40px',
    color: '#0d3d76',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap', 
}

export const btnstyle = {
    outline: 0,
    background: 'transparent',
    border: '0',
    cursor: 'pointer',
    '&:hover': {
        transform: 'scale(1.2)'
    }
}

export const btnText = {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '12px',
    color: '#54575f',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap', 
}

export const whatsNewMain = {
    marginTop: 32,
    padding: 20,
}

export const WhatsNewText = {
    fontSize: 24,
    lineHeight: '32px',
    color: '#2b2d33',
    fontWeight: 'bold'
}

export const whatsNewShare = {
    color: '#0D3D76',
    fontSize: 20,
    lineHeight: isMobile ? '24px' : '14px',
    marginTop: 28,
}

export const whatsNewDet = {
    color: '#000000',
    fontSize: 16,
    lineHeihgt: '24px',
    marginTop: 16
}

export const whatsNewLink = {
    color: '#f5922f',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: '24px',
    paddingBottom: '4px',
    borderBottom: '1px solid #f5922f'
}

export const awesomeBtn = {
    color: '#fff',
    background: '#f5922f',
    fontWeight: 'bold',
    width: '100%',
    height: '48px',
    marginTop: 24,
    border: 'none',
    outline: 0,
    borderRadius: '24px',
    cursor: 'pointer'
}

export const dlDiv = {
    position: 'relative', 
    marginTop: 64, 
}

export const iconPosition = {
    position: 'absolute', 
    left: 0
}

export const dlHeader = {
    fontSize: '18px', 
    color: '#000000', 
    lineHeight: '20px', 
    fontWeight: 'bold'
}

export const qrImageDownloadBtn = {
    width: '100%',
    height: '40px',
    color: '#fff',
    background: '#f5922f',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 'bold',
    border: '1px solid #f5922f'
}

export const loanText = {
    fontSize: 16,
    lineHeight: '24px',
    color: '#2b2d33',
    textAlign: 'center',
    marginTop: 24
}

export const loanLinkText = {
    color: '#f5922f', 
    fontWeight: 'bold',
    textDecoration: 'underline'
}