import React,{useEffect} from 'react'
import {Card, Button} from 'antd'
import * as Style from './kyc_style'
import {isMobile} from 'react-device-detect'
import {setStep, submitDetails} from './application_main'

const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;

const ContactInfo = props => {

    useEffect(()=>{
        console.log(props.details)
    },[])

    const segments = localStorage.getItem("segments")
    const color = segments == 'me_' ? '#0DAED0' : segments == 'gig' ? '#41BD5C' : '#F5922F'

    const [selected, setSelected] = React.useState("")

    const [mobileNumber, setMobileNumber] = React.useState(props.details.contact)
    const [emailAddress, setEmailAddress] = React.useState("")

    const [step1ErrorAlert, setStep1ErrorAlert] = React.useState(false)
    const [step1ErrorMessage, setStep1ErrorMessage] = React.useState("")

    const [loading, setLoading] = React.useState(false)
    const [hover, setHover] = React.useState(false)


    async function checkDetails(){
        let details = {}

        if(mobileNumber.length == 0){
            setStep1ErrorMessage("Mobile Number is required")
            setStep1ErrorAlert(true)
        }
        else if(mobileNumber.length <= 9){
            setStep1ErrorMessage("Mobile Number must 10 digits")
            setStep1ErrorAlert(true)
        }

        else {
            setLoading(true)
            details = {
                contact: mobileNumber,
                email: localStorage.getItem("email"),
                step: 1
            }

            if(props.testMode) {
                props.fakeKycDetails(2)
                window.scrollTo(0, 0);
                setLoading(false)
            }

            else {
                let response = await submitDetails(details)
                if(response){
                    setLoading(false)
                }
            }
        
        }
    }
    return(
        <div>
            <Card 
                style={Style.formCardStyle}
                bodyStyle={{padding: isMobile? '12px 16px' : '32px'}}>
                
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        {/* <div style={{...Style.normalText, color: '#000'}}>Step 2 of 5</div> */}
                        <div hidden={isMobile} style={{...Style.headerStyle, fontSize: isMobile ? '16px' : '20px'}}>Contact Information</div>
                    </div>
                    <div hidden={props.details.step != 6 && props.details.step != 9}>
                    {
                         isMobile ? 
                         <div style={{...Style.boldText, color: '#F5922F'}} onClick={()=>setStep(6)}>Cancel</div>
                         :
                         <button 
                            onClick={()=>setStep(6)}                            
                            className={`outline-btn--${segments} btn-height`}> Cancel</button>
                    }
                    </div>
                </div>
                
                <div
                    style={{
                        backgroundColor: 'rgba(226, 76, 76, 0.1)',
                        borderLeft: '4px solid #E24C4C',
                        width: '100%',
                        height: '42px',
                        borderRadius: '4px',
                        margin: '20px 0px 20px 0px',
                        textAlign: 'center',
                        paddingTop: '10px',
                        display: step1ErrorAlert ? "block" : "none"
                    }}>
                    <span style={Style.normalText}>{step1ErrorMessage}</span>
                </div>

                <div style={{display: isMobile ? 'block' : 'flex', marginTop: '20px', marginBottom: '20px'}}>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Mobile Number</div>
                        <div style={{display:'flex'}}>
                            <div style={{position:'absolute', lineHeight: '38px', marginLeft: '10px'}}>+63 |</div>
                            <input 
                                data-test="input-mobile"
                                style={{...Style.inputStyle, 
                                    border: selected === "mobile" ? `1px solid ${color}` : '1px solid #D1D5DD',
                                    padding: '10px 10px 10px 50px'}} 
                                type="number"
                                value={mobileNumber}
                                onSelect={()=>setSelected("mobile")}
                                onMouseLeave={()=>setSelected("")}
                                onChange={e => { if(e.target.value.length <= 10){setMobileNumber(e.target.value)}}}
                            />
                        </div>
                    </div>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Email</div>
                        <input 
                            data-test="input-email"
                            style={{...Style.inputStyle, 
                                border: selected === "email" ? `1px solid ${color}` : '1px solid #D1D5DD',
                                padding: '10px'}} 
                            placeholder="Email"
                            disabled={true}
                            value={localStorage.getItem("email")}
                            onSelect={()=>setSelected("email")}
                            onMouseLeave={()=>setSelected("")}/>
                    </div>
                </div>
                
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}} hidden={isMobile}>
                    <div style={{...Style.backToDashboard, margin: '0px'}}> <a className={`text-${segments}`} onClick={()=>setStep(0)}>Back</a></div>
                    <Button 
                        data-test="button-submit"
                        loading={loading}
                        style={{...Style.submitBtnStyle,
                        opacity: mobileNumber.length != 10 ? 0.5 : 1.0,
                    }} 
                        onClick={()=>checkDetails()} 
                        hidden={isMobile}
                        disabled={mobileNumber.length != 10}
                    >
                        <div style={{...Style.boldText, display: 'inline-block', marginLeft: loading ? '20px' : '0px'}}>{props.details.step == 1 ? 'Submit and next' : 'Save'}</div>
                    </Button>
                </div>
            </Card>

            <div style={{
                width: '100%', 
                position: 'fixed', 
                display: 'flex',
                backgroundColor:'#fff', 
                height: '74px', 
                bottom: '0', 
                marginLeft: '-16px',
                padding:'10px 20px 10px 20px',
                justifyContent: 'space-between',
                alignItems: 'center'
            }} hidden={!isMobile}>
                <button onClick={()=>setStep(0)} style={Style.backBtnMobile} className={`outline-btn--${segments}`}>
                    Back
                </button>
                <Button 
                    loading={loading}
                    style={{...Style.submitBtnStyle,opacity: mobileNumber.length != 10 ? 0.5 : 1.0}}
                    disabled={mobileNumber.length != 10 } 
                    onClick={()=>checkDetails()}>
                    <div style={{...Style.boldText, display: 'inline-block', marginLeft: loading ? '20px' : '0px'}}>{props.details.step == 1 ? 'Submit and next' : 'Save'}</div>
                </Button>
            </div>
        </div>
    )
}

export default ContactInfo