import { makeStyles } from '@material-ui/core';

const payoutStyles = makeStyles(theme => ({

    body: {
        padding: '28px 24px 0px 24px',
    },

    mainBody: {
        paddingTop: '24px', 
        paddingLeft: '24px', 
        paddingRight: '24px' 
    },

    summaryDiv: {
        display: 'flex',
        fontSize: '16px',
        width: '100%', 
        height: '60px', 
        backgroundColor: '#fff', 
        marginTop: '20px',
        padding: '18px 20px 0px 50px'
    },

    summaryText: {
        color: '#000',
        marginRight: '5px'
    },

    summaryValue: {
        color:'#0D3D76', 
        fontWeight: 'bold', 
        marginRight: '32px'
    },

    btnDiv: {
        marginBottom: '25px', 
        display: 'flex', 
        float: 'right',
    },

    headerStyle: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '30px'
    },

    title: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '24px'
    },

    codeStyle: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 12,
        lineHeight: '24px',
        color: '#2B2D33'
      },

    descSyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#2B2D33',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '146px',
        whiteSpace: 'nowrap',
    },

    dataStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 13,
        lineHeight: '24px',
        color: '#000000'
      },
    
    menuStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33',
        padding: '10px',
        margin: 'auto'
    },

    summaryDiv: {
        display: 'flex',
        fontSize: '16px',
        width: '100%', 
        height: '60px', 
        backgroundColor: '#fff', 
        marginTop: '20px',
        padding: '18px 20px 0px 50px'
    },

    pillFlex: {
        marginBottom: '32px', 
        display: 'flex', 
        justifyContent: 'space-between' 
    },

    clearBtn: {
        borderRadius: '24px',
        background: '#E6EAF0',
        color: '#000000',
        fontSize: 12,
        width: 120,
        height: '29px',
        border: '1px solid #e6eaf0',
        cursor: 'pointer',
        outline: 0,
        '&:hover': {
            opacity: '0.8',
        },

        '&:active': {
            opacity: 1,
        }
    },

    exportBtn: {
        backgroundColor: 'transparent',
        border: '1px solid #0A315E',
        height: '40px',
        borderRadius:'4px',
        color:'#0A315E',
        fontWeight: '500',
        cursor: 'pointer',
        padding: '0 12px'
    },

    tableDiv: {
        padding: '32px 20px',
        background: '#fff',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    },

    noteStyle: {
        fontSize: 12,
        lineHeight: '21px',
        color: '#909196'
    },

    acSelectStyle: {
        //add reso later in width
        width:'16%', 
        marginRight: '12px', 
        zIndex: 1,
        color: '#000'
    },

    dataPickerStyle: {
        width: '16%',
        zIndex: 1,
    },

    searchStyle: {
        width: '16%', 
        height: '40px', 
        zIndex: 1,
        marginRight: '12px',
    },

    activeBtn: {
        background: '#f5922f',
        border: '1px solid #f5922f',
        borderRadius: '4px',
        color: '#fff',
        height: '40px',
        width: 93,
        cursor: 'pointer',
        outline: 0,
        '&:hover': {
            opacity: '0.8',
        },

        '&:active': {
            opacity: 1,
        }

    },

    disabledBtn: {
        outline: 0,
        background: '#f5922f',
        border: '1px solid #f5922f',
        borderRadius: '4px',
        color: '#fff',
        height: '40px',
        width: 93,
        opacity: 0.5,
        cursor: 'not-allowed'
    },

    newSummaryDiv: {
        display:'flex',
        justifyContent: 'flex-start',
        margin: '20px 0px 10px 0px',
        borderTop: '1px solid #D4D5D8',
        // borderBottom: '1px solid #E6EAF0',
    },

    totalDiv: {
        padding: '12px 0px 12px 0px',
        width: '', textAlign: 'center',
    },

    sumSubDiv: {
        color: '#000', 
        marginRight: '5px'
    },

    sumSubValue: {
        color:'#0D3D76', 
        fontWeight: 'bold', 
        marginRight: '32px'
    },

    colDiv: {
        fontsize: 12,
    },

    successDiv:{
        fontSize: 12,
        background: 'rgba(29, 210, 139, 0.1)',
        border: '1px solid #1dd28b',
        borderRadius: '4px',
        paddingLeft: 8 ,
        lineHeight: '32px',
        height: 32,
        width: 71,
        borderLeft: '4px solid #1dd28b',
        color: '#2B2D33'
    },

    pendingDiv: {
        fontSize: 12,
        background: 'rgba(245, 146, 47, 0.1)',
        border: '1px solid #F5922F',
        borderRadius: '4px',
        paddingLeft: 8 ,
        lineHeight: '32px',
        height: 32,
        width: 71,
        borderLeft: '4px solid #F5922F',
        color: '#2B2D33'
    },

    failedDiv: {
        fontSize: 12,
        background: 'rgba(226, 76, 76, 0.1)',
        border: '1px solid #E24C4C',
        borderRadius: '4px',
        paddingLeft: 8 ,
        lineHeight: '32px',
        height: 32,
        width: 71,
        borderLeft: '4px solid #E24C4C',
        color: '#2B2D33'
    }

}))

export default payoutStyles;