import React from 'react';
import fraudServices from '../fraudServices';
import fraudActions from '../fraudActions';
import DeleteRowModal from './deleteRowModal';
import TagModal from './tagModal';
import TaggedViewModal from './taggedViewModal';
import { Dropdown, Icon, Menu, Table, message, Pagination, Typography, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

const BlacklistTable = ({data, onDeleted, isLoading, length, selected, sort}) => {
  const currentPage = useSelector(state => state.fraud.tablePage);
  const [minPage, setMinPage] = React.useState(0);
  const [maxPage, setMaxPage] = React.useState(20);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [columns, setColumns] = React.useState(null);
  const [showDelModal, setShowDelModal] = React.useState(false);
  const [keyTodelete, setKeyToDelete] = React.useState([]);
  const [showTagModal, setShowTagModal] = React.useState(false);
  const [tagData, setTagData] = React.useState(null);
  const [showTaggedViewModal, setShowTaggedViewModal] = React.useState(false);

  const KYCColumns = [
    {
      title: 'Blacklist Code',
      dataIndex: 'kyb_code',
    },
    {
      title: 'Full Name',
      render: (record) => <Typography>{`${record.first_name} ${record.last_name}`}</Typography>
    },
    {
      title: 'Id Number',
      dataIndex: 'ID_number',
    },
    {
      title: 'Date of Birth',
      dataIndex: 'birthday',
    },
    {
      title: 'Referrer',
      dataIndex: 'referrer',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
    },
    {
      title: 'KYC Tag',
      width: '12%',
      render: (record) => record.tagged_kyc? 
        <div style={styles.tagContainer}>
          <a style={styles.tagActionLink} onClick={() => setShowTaggedViewModal(true)/setTagData(record)} ><Icon type='user' /> <span style={{textDecoration: 'underline', color: '#54575F'}}>{record.tagged_kyc}</span></a>
        </div> 
        : 
        <Typography style={{textAlign: 'center'}}>
          <a style={styles.actionLink} onClick={() => setShowTagModal(true)/setTagData(record)}>+ <span style={{textDecoration: 'underline'}}>Add</span></a>
        </Typography>
    },
    {
      title: 'Date Added',
      dataIndex: 'created_at',
    },
    {
      title: 'Action',
      render: (record) => (
        <Dropdown overlay={() => <Menu><Menu.Item><a onClick={()=>handleDeleteRow(record.id)}>Delete</a></Menu.Item></Menu>} placement="bottomCenter" trigger={['click']}>
          <Icon type="ellipsis" />
        </Dropdown>
      )
    }
  ]

  const KYBColumns = [
    {
      title: 'Blacklist Code',
      dataIndex: 'kyb_code',
    },
    {
      title: 'Business Name',
      dataIndex: 'business_name',
    },
    {
      title: 'Referrer',
      dataIndex: 'referrer',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
    },
    {
      title: 'KYB Tag',
      width: '12%',
      render: (record) => record.tagged_kyb ? 
        <div style={styles.tagContainer}>
          <a style={styles.tagActionLink} onClick={() => setShowTaggedViewModal(true)/setTagData(record)} ><Icon type='user' /> <span style={{textDecoration: 'underline', color: '#54575F'}}>{record.tagged_kyb}</span></a>
        </div> 
        : 
        <Typography style={{textAlign: 'center'}}>
          <a style={styles.actionLink} onClick={() => setShowTagModal(true)/setTagData(record)}>+ <span style={{textDecoration: 'underline'}}>Add</span></a>
        </Typography>
    },
    {
      title: 'Date Added',
      dataIndex: 'created_at',
    },
    {
      title: 'Action',
      render: (record) => (
        <Dropdown overlay={() => <Menu><Menu.Item><a onClick={()=>handleDeleteRow(record.id)}>Delete</a></Menu.Item></Menu>} placement="bottomCenter" trigger={['click']}>
          <Icon type="ellipsis" />
        </Dropdown>
      )
    }
  ]

  const success = (msg) => {
    message.success(msg);
  };

  const errorPrompt = (msg) => {
    message.error(msg);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const res = await fraudServices.deleteBlacklist(selected==='Individual Account'?'kyc':'kyb',{target_id:id})
      if(res.status==='success'){
        success(res.message);
        onDeleted();
      } else {
        errorPrompt(res.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      errorPrompt(error);
    }
  }

  const handleDeleteRow = (id) => {
    setKeyToDelete(id);
    setShowDelModal(true);
  }

  const confirmDeleteRow = () => {
    handleDelete(keyTodelete);
    setShowDelModal(false);
  }

  const handleChangePage = async (page) => {
    setLoading(true);
    try {
      const res = await fraudServices.getBlacklist('', page, returnType(), sort);
      if(res&&res.results){
        dispatch(fraudActions.setTableData(res.results));
      } 
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const returnType = () =>  {
    switch (selected) {
      case 'Individual Account':
        return 'kyc';
      case 'Enterprise Account':
        return 'kyb';
      default:
        return 'kyc';
    }
  }

  const changePage = (page, pageSize) => {
    setMaxPage(page * pageSize)
    setMinPage((page - 1) * pageSize)
    dispatch(fraudActions.setTablePage(page));
    handleChangePage(page);
  }

  React.useEffect(() => {
    setLoading(true);
    setColumns([]);
    setTimeout(()=> {
      setColumns(selected==='Individual Account'?KYCColumns:KYBColumns);
      setLoading(false);
    },100)
  },[selected])

  return (
    <div>
      <Table 
        rowClassName="table-row-light"
        columns={columns}
        dataSource={data}
        rowKey={record => record.id}
        loading={isLoading || loading}
        pagination={false}
      />
      <Pagination
        size='small'
        total={length}
        defaultPageSize={20}
        defaultCurrent={1}
        current={currentPage}
        onChange={(page, pageSize) => changePage(page, pageSize)}
        style={{padding:'12px 0 8px 0', textAlign: 'right'}}
      />
      <DeleteRowModal visible={showDelModal} closeModal={()=> setShowDelModal(false)} onDelete={confirmDeleteRow} />
      <TagModal visible={showTagModal} closeModal={() => setShowTagModal(false)} tagData={tagData} selected={selected} onSuccessTagged={() => changePage(1, 20)} />
      <TaggedViewModal visible={showTaggedViewModal} closeModal={() => setShowTaggedViewModal(false)/changePage(1, 20)} tagData={tagData} selected={selected} />
    </div>
  )
}

const styles = {
  actionLink: {
    fontWeight: '500',
    fontSize: 14,
    color: '#F5922F',
    textAlign: 'center'
  },
  tagContainer: {
    color: '#54575F',
    border: '1px solid #E6EAF0',
    borderRadius: 40,
    padding: '4px 2px',
    textAlign: 'center'
  },
  tagActionLink: {
    color: '#E6EAF0',
    fontSize: 12
  }
}

export default BlacklistTable;