export function determineSupportedModes(bank, railType, banks) {
  const lowerCaseBankName = bank.name.toLowerCase();

  const supportedModes = [];

  const specialBanks = {
    ALB: "allbank (a thrift bank)",
    UBP: "union bank of the philippines"
  };

  if (specialBanks[railType] && lowerCaseBankName === specialBanks[railType]) {
    supportedModes.push(railType === "ALB" ? "AllBank" : "UnionBank"); 
    return supportedModes;
  }

  const { instapay_banks, pesonet_banks } = banks;

  const railTypeInstapayCheck = {
    ALB: bank.code,
    UBP: bank.code.instapay
  };

  const railTypePesonetCheck = {
    ALB: bank.code,
    UBP: bank.code.instapay
  };

  const isInstaPayBank = instapay_banks.some(instapay_bank => instapay_bank.code === railTypeInstapayCheck[railType])
  const isPesoNetBank = pesonet_banks.some(pesonet_bank => pesonet_bank.code === railTypePesonetCheck[railType])

  if (isInstaPayBank){
    supportedModes.push("Instapay");
  }

  if (isPesoNetBank){
    supportedModes.push("PesoNet");
  }

  return supportedModes;
}
