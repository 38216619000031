import { useEffect, useState } from "react";
import { axios } from "../../../../../lib/axios";

export function getUbpBanks(excludeEWallets = false) {
  return axios.get(`api/ubp/banks/?exclude_ewallets=${excludeEWallets}`);
}

export function useUbpBanks() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getUbpBanks();
        setData(response);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, []);

  return { data, isLoading, error };
}
