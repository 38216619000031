import React from 'react';
import TestModeBanner from './testModeBanner';
import { Button, Card, Input, Typography } from 'antd';
import { isMobile } from 'react-device-detect';

const LoginCard = (props) => {
    const type = props.type;
    const [loading, setLoading] = React.useState(false);
    const [params, setParams] = React.useState({});

    const hasBlanks = () => {
        const isEmpty = Object.values(params).some(x => (x === null || x === ''));
        if(isEmpty === true || Object.keys(params).length < 2 && !params['mobile'] || params && params['mobile'] && Object.keys(params).length < 1){
          return true;
        }
        return false;
      }
    
    const handleChange = (event) => {
        if(event.target.name === 'mobile'){
            setParams({...params, [event.target.name]:autoFormat(event.target.value)})
        } else {
            setParams({...params, [event.target.name]:event.target.value})
        }
    }

    const autoFormat = (value) => {
        const formattedValue = value.replace(/\D/g, '')
        if (value !== null) {
            return formattedValue;
        } 
            return value;
    }
    

    const onLogin = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            props.onLogin();
        }, 3000)
    }

    const bankLogin = (
        <div>
            <div style={styles.inputDiv}>
                    <label style={styles.labelFont}>Username</label>
                    <Input style={styles.input}
                        name='username'
                        // value={params['firstName']}
                        placeholder="Username" 
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
                <div style={styles.inputDiv}>
                    <label style={styles.labelFont}>Password</label>
                    <Input style={styles.input}
                        name='password'
                        // value={params['firstName']}
                        placeholder="Password"
                        type='password'
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
        </div>
    )

    const ewalletLogin = (
        <div>
             <div style={{...styles.inputDiv, position: 'relative'}}>
                    <label style={styles.labelFont}>Mobile Number</label>
                    <Input style={{...styles.input, paddingLeft: 60}}
                        name='mobile'
                        value={params['mobile']}
                        onChange={handleChange}
                        disabled={loading}
                        maxLength={10}
                    />
                    <Typography style={styles.mobilePrefix}>+63 | </Typography>
                </div>
        </div>
    )

    return (
        <Card bodyStyle={styles.root} hidden={props.hidden}>
            <TestModeBanner type={type} />
            <div style={{padding: 16}}>
                <Typography style={styles.title}>Login to Pay</Typography>
                {
                    type === 'BANKING' ? 
                    bankLogin
                    :
                    ewalletLogin
                }
                <Button
                    style={{...styles.btn, opacity: hasBlanks() || !params || params && params['mobile'] && params['mobile'].length !== 10 ? '0.5' : '1' }}
                    onClick={onLogin}
                    loading={loading}
                    disabled={loading || hasBlanks() || !params || params && params['mobile'] && params['mobile'].length !== 10}
                >
                    Login
                </Button>
            </div>
        </Card>
    );
}

const styles = {
    root: {
        width: isMobile? '100%' : 568,
        padding: 0,
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    },
    btn: {
        margin: '20px 0px 0px 0px',
        height: 48,
        width: '100%',
        backgroundColor: '#F5922F',
        color: '#FAFAFA',
        borderRadius: 4,
        fontSize: 16,
        fontWeight: '600',
    },
    title: {
        textAlign: 'center',
        fontSize: 24,
        fontWeight: '500',
        color: '#000',
    },
    inputDiv: {
        width :'100%',
        padding: '6px 0'
    },
    input: {
        padding: '11px 16px 11px 16px',
        fontSize: 16,
        height: 48,
        margin: '4px 0px 0px 0px',
        color: '#2B2D33',
        border: '1px solid #D1D5DD',
        borderRadius: 4,
        width: '100%',
    },
      labelFont: {
        fontSize: 12,
        color: '#212B36'
    },
    mobilePrefix: {
        position: 'absolute',
        top: `64%`,
        left: isMobile ? '10%' : '6%',
        transform: 'translate(-50%, -50%)',
        fontSize: 16,
        color: '#909196',
        fontWeight: 'normal',
        zIndex: '99',
      }
}

export default LoginCard;