import React,{ useEffect, useState } from 'react';
import { Table, Dropdown, Icon, Menu, Button, Pagination} from 'antd';
import {Alert} from '@material-ui/lab/'
import TablePreviewModal from './tablePreviewModal';
import TableLinksReviewModal from './tableLinksReviewModal';
import DeleteRowModal from './deleteRowModal';
import UploadCsvModal from './uploadCsvModal';
import NotifModal from './notifModal';
import { setSelectedBtn } from '../orderAction';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import batchUploadServices from './batchUploadServices';
import {exportTemplate} from './batchUpload';
import { ExportToCsv } from 'export-to-csv';
import { randomize } from '../../constants/constants';

const BatchTable = ({data, isTableLoading, changePage, refetchTableData, currentTablePage, totalTableCount}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [showDelModal, setShowDelModal] = useState(false);
  const [keyTodelete, setKeyToDelete] = useState([]);
  const selectedTab = useSelector(state => state.orders.selectedBtn);
  const [showUpModal,setShowUpModal] = useState(false);
  const [idToReplace, setIdToReplace] = useState(null);
  const [idToGen, setIdToGen] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showNotidModal, setShowNotifModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifData, setNotifData] = useState(null);
  const [selectedInvalid, setSelectedInvalid] = useState(false);
  const [isPreviewModalTableLoading, setIsPreviewModalTableLoading] = useState(false);
  const dispatch = useDispatch();
  const testMode = (window.location.pathname.indexOf('/test') > -1&&localStorage.getItem("userType") == "CO");
  const disabledGenerate = localStorage.getItem("userType") == "CO" && localStorage.getItem("Level") != 5 && !testMode || localStorage.getItem("userType") == "CO" && localStorage.getItem("KYBStep") == 10 && !testMode;
  const segments = localStorage.getItem('segments');

  const columns = [
    {
      title: 'Batch Code',
      dataIndex: 'batch_code',
    },
    {
      title: 'No. of Payment Request',
      dataIndex: 'links_count',
      render: (links_count, batch_object) => {
        return batch_object.completion_rate ? 
        (
          <div>
            <span style={styles.generating}>Preview Unavailable</span>
            <br/>
            <span style={{color: '#606060'}}>Please check again later</span>
          </div>
        )
        : (
          <a onClick={()=> handlePreview(batch_object)} style={styles.numRequest}>{links_count} {links_count === 1 ? 'Request' : 'Requests'}</a>
        )
      }
    },
    {
      title: 'Date Uploaded',
      dataIndex: 'upload_date',
      render: (text) => (
        <div>{text}</div>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status, batch_object) => {
        return batch_object.completion_rate
        ? (
          <div style={styles.unavailable} >
            {batch_object.completion_rate}% done
          </div>
        )
        : (
          <div style={(status==='Ready' || selectedTab === 'Generated') ? styles.positive : styles.negative}>
            {status}
          </div>
        )
      }
    },
    {
      title: 'Action',
      render: (text, record, index) => {
        return record.completion_rate ? 
        (
          <div>
          </div>
        )
        : (
          <Dropdown overlay={() => menu(record.id,record.file_contents,record.batch_code, record.output_file)} placement="bottomCenter" trigger={['click']}>
            <Icon type="ellipsis" />
          </Dropdown>
        )
      }
    }
  ]

  const menu = (id, file, code, output_file) => (
    <Menu>
      <Menu.Item key="0" hidden={selectedTab==='Uploaded'}>
        <a href={output_file} target="_blank">Download</a>
      </Menu.Item>
      <Menu.Item key="1" hidden={selectedTab==='Generated'}>
        <a onClick={() => setIdToReplace(id)/setShowUpModal(true)}>Replace CSV</a>
      </Menu.Item>
      <Menu.Item key="2" hidden={selectedTab==='Generated'}>
        <a style={{color: '#E24C4C'}} onClick={() => handleDeleteRow(id)}>Delete</a>
      </Menu.Item>
    </Menu>
  )

  const handleDelete = async () => {
    try {
      const res = await batchUploadServices.deleteUploaded({'batch_ids':keyTodelete}, testMode);
      if(res.success.length >= 1){
        refetchTableData();
      }
    } catch (error) {
        return(
          <Alert severity="error">{error}</Alert>
        )
    }
  }

  const newExportPaymentLink = async(id, batch_code) => {
    try {
      const response = await batchUploadServices.getGeneratedLinksData(testMode, id, null, null, true);
      const orders = response.results;
      if(orders.length) {
        let options = { 
          filename: batch_code+'_Payment_Links'+moment().format("MM-DD-YYYY_HH:mm:ss"),
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true, 
          showTitle: true,
          title: 'BUx User List',
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true
        };
        let csvExporter = new ExportToCsv(options);

        let export_data = orders.map((order) => {
          return {
            'Customer Name': order.buyer_name,
            'Customer Email': order.buyer_email,
            'Customer Mobile Number': order.buyer_phone,
            'Owner Email': order.owner,
            'Amount': order.amount,
            'Description': order.description,
            'Link Expiry': order.expire_at,
            'Payment Link URL': testMode ? `${process.env.REACT_APP_BASE_URL}/test/checkout/${order.uid}` : `${process.env.REACT_APP_BASE_URL}/checkout/${order.uid}`
          };
        });
        csvExporter.generateCsv(export_data);
      }
    } 
    catch (error) {
      return(
        <Alert severity="error">{error}</Alert>
      )
    }
  }

  const handleGenerate = async () => {
    setLoading(true);
    try {
      const res = await batchUploadServices.generateLinks({batch_ids:idToGen}, testMode);
      if(res.success.length >= 1) {
        refetchTableData();
        dispatch(setSelectedBtn('Generated'));
      }
      setLoading(false);
      setNotifData(res);
    } catch (error) {
        setLoading(false);
        return(
          <Alert severity="error">{error}</Alert>
        )
    }
  }

  const handleChangeSelect = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);

    const isSelectionInvalid = selectedRows.some((item) => item.status !== 'Ready');
    setSelectedInvalid(isSelectionInvalid);

    const rowId = selectedRows.map((item) => {
      return item.id;
    })
    setIdToGen(rowId);
  }

  async function handlePreview(batch_object) {
    if (selectedTab === "Uploaded") {
      setIsPreviewModalTableLoading(true);
      setSelectedTable(batch_object.batch_code);
      setShowModal(true);
  
      const previewTableData = await batchUploadServices.getUploadedLinksData(testMode, batch_object.id);
      setSelectedData(previewTableData);
      setIsPreviewModalTableLoading(false);
    } else if (selectedTab === "Generated") {
      setSelectedTable(batch_object);
      setShowModal(true);
    }
  }
  
  const confirmDeleteRow = () => {
    handleDelete();
    setShowDelModal(false);
  }
  
  const handleDeleteRow = (id) => {
    setKeyToDelete([...keyTodelete, id]);
    setShowDelModal(true);
  }

  const handleNotifClose = () => {
    if(notifData&&notifData.success.length>0) {
      dispatch(setSelectedBtn('Generated'))
    }
    refetchTableData();
    setSelectedRowKeys([]);
    setShowNotifModal(false);
  }

  const flattenData = (file) => {
   const data = Object.keys(file).map((key,i) => {
      const d1 = file[key];
      return d1;
    })
    return data;
  }

  const footer = (
   <div style={styles.footer}>
      <button
          loading={loading[3]}
          className={`btn--${segments}`} 
          onClick={handleGenerate} disabled={selectedRowKeys.length===0 || selectedInvalid || disabledGenerate } 
          style={{...styles.btnGen, opacity: selectedRowKeys.length===0 || selectedInvalid || disabledGenerate? '.5':'1'}}
      >Generate Links</button>
   </div>
  )

  const tableCheckbox = {
    type: 'checkbox', 
    selectedRowKeys:selectedRowKeys, 
    onChange: (selectedRowKeys, selectedRows) => handleChangeSelect(selectedRowKeys, selectedRows)
  }

  useEffect(() => {
    setSelectedRowKeys([]);
  },[selectedTab])

  return (
    <div>
      <Table
        rowClassName="table-row-light"
        columns={columns}
        rowSelection={selectedTab==='Uploaded' ? {...tableCheckbox}: {}}
        dataSource={flattenData(data)}
        footer={selectedTab==='Uploaded'&&(()=> footer)}
        pagination={{hideOnSinglePage: true, pageSize: 10}}
        loading={isTableLoading}
      />

      <div style={{ width: '100%', textAlign: 'right', justifyContent: 'center', marginTop: '15px'}}>
        <Pagination 
          size="default"
          total={totalTableCount}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          onChange={(e) => changePage(e)} 
          defaultCurrent={1} 
          key={randomize()} 
          pageSize={10} 
          current={currentTablePage}
        />
      </div>
      {
        (selectedTab === 'Uploaded')
        ? <TablePreviewModal
            visible={showModal}
            closeModal={() => {setShowModal(false); setSelectedData([]);}}
            data={selectedData}
            batchCode={selectedTable}
            isPreviewModalTableLoading={isPreviewModalTableLoading}
          />
        : (selectedTab === 'Generated')
        ? <TableLinksReviewModal
            isVisible = {showModal}
            onModalClose={() => {setShowModal(false)}}
            batchObject={selectedTable}
            testMode={testMode}
          />
        : ''
      }
      <DeleteRowModal visible={showDelModal} closeModal={()=> setShowDelModal(false)} onDelete={confirmDeleteRow} />
      <UploadCsvModal closeModal={()=> setShowUpModal(false)} visible={showUpModal} closeLinkModal={() => setShowUpModal(false)} replace={true} replaceId={idToReplace} onSuccess={refetchTableData} exportTemplate={exportTemplate} />
      <NotifModal visible={showNotidModal}
        data={notifData} 
        closeModal={handleNotifClose}/>
    </div>
  )
}

const styles ={
  generating: {
    color: '#909090',
    fontSize: 14,
    fontWeight: 'bold',
  },
  numRequest: {
    color: '#F5922F',
    fontSize: 14,
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  footer: {
    textAlign: 'right',
  },
  btnGen: {
    height: 40,
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    padding: '0 26px'
  },
  positive: {
    borderRadius: 4,
    padding: '4px 8px',
    backgroundColor: 'rgb(232, 250, 243)',
    border: '1px solid #1DD28B',
    borderLeft: '5px solid #1DD28B',
  },
  negative: {
    borderRadius: 4,
    padding: '4px 8px',
    backgroundColor: '#f9dbdb',
    border: '1px solid #E24C4C',
    borderLeft: '5px solid #E24C4C',
  },
  unavailable: {
    borderRadius: 4,
    padding: '4px 8px',
    backgroundColor: 'rgb(240, 240, 240)',
    border: '1px solid rgb(192, 192, 192)',
    borderLeft: '5px solid rgb(192, 192, 192)',
  },
}

export default BatchTable;