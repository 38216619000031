import React, {useEffect} from 'react'
import axios from 'axios';
import {Layout, Button, Checkbox, Divider} from 'antd'
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Visibility from "@material-ui/icons/Visibility";
import { useDispatch, useSelector } from "react-redux";
import logo from '../../static/icons/logo.svg'
import ecommerceStyle from '../../static/css/Ecommerce'
import {showAlert} from '../../components/alert/alertAction'
import { history } from "../../store/history";
import * as Styles from '../../components/login/authComponents/authFormStyles'

const EcomLogin = props => {
    const ecom = ecommerceStyle();
    const { Header } = Layout;

    const dispatch = useDispatch()
    const loginLoading = useSelector(state => state.login.loading);
    const alert = useSelector(state => state.alert.showAlert);
    const [username, setUsername] = React.useState(""); 
    const [password, setPassword] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);

    function submitCreds() {
    
        let params = {
          username: username,
          password: password
        };

        login(params)
    
      }

    async function login(val){

        let response = await axios.post(
            process.env.REACT_APP_API_URL + "/api/login/", val

        )

        console.log(response)
        
        let token = response.data.access_token;
        let userDetails = response.data.user_details;
        
        if(response.data.status == "success") {

                localStorage.setItem("jwtToken", token);
                localStorage.setItem("userId", userDetails.id);
                localStorage.setItem("firstName", userDetails.first_name);
                localStorage.setItem("lastName", userDetails.last_name);
                localStorage.setItem("middleName", userDetails.middle_name);
                localStorage.setItem("suffix", userDetails.suffix);
                localStorage.setItem("username", userDetails.username);
                localStorage.setItem("email", userDetails.email);
                localStorage.setItem("contact", userDetails.contact);
                localStorage.setItem("balance", userDetails.balance);
                localStorage.setItem("userType", userDetails.user_type)
                localStorage.setItem("isCompleted", userDetails.is_completed)
                localStorage.setItem("isVerified", userDetails.is_verified)

                let search = window.location.search;
                history.push('/shopify/authorize' + search);
                
        }
        else{
            dispatch(showAlert({ type: "error", message: "Incorrect username or password" }));
        }

    }


    useEffect(() => {
        let search = window.location.search;
        if (localStorage.getItem("jwtToken") != "" && localStorage.getItem("jwtToken") != null){
   
            history.push('/authorize' + search)
        }
    })

    return(
        <div>
            <Header style={{height: '60px', display: 'flex', justifyContent: 'center', backgroundColor: '#fff', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)'}}>
                <img src={logo} style={{marginBottom: '20px', marginTop: '12px'}} alt="#" />
            </Header>
            <div align='center'>            
                <div className={ecom.main} align='left'>
                    <div className={ecom.header}>Login</div>

                    <div>
                        <TextField
                            error={alert}
                            color="secondary"
                            id="outlined-name"
                            label="Username"
                            onChange={e => setUsername(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            style={{
                            height: "100%",
                            width: "100%",
                            }}
                        />
                        </div>
                        <div>
                        <TextField
                            error={alert}
                            id="outlined-adornment-password"
                            variant="outlined"
                            onChange={e => setPassword(e.target.value)}
                            style={{
                            height: "100%",
                            width: "100%",
                            marginBottom: "30px"
                            }}
                            type={showPassword ? "text" : "password"}
                            label="Password"
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    aria-label="toggle password visibility"
                                    onClick={e => setShowPassword(!showPassword)}
                                    onMouseDown={e => setShowPassword(showPassword)}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOutlinedIcon />}
                                </IconButton>
                                </InputAdornment>
                            )
                            }}
                        />
                        </div>

                        <a
                            onClick={() => history.push("/forgot_password")}
                            className={ecom.forgotStyle}
                            >
                            Forgot Password?
                            </a>
                            <div>
                            <Button
                                loading={loginLoading}
                                type="primary"
                                className={ecom.loginBtnStyle}
                                onClick={() => submitCreds()}
                            >
                                Login
                            </Button>
                            </div>
                    
                </div> 
            </div>
        </div>

    )
}

export default EcomLogin