import React from 'react';
import MetaTag from '../../meta_tag/meta_tag';
import PropTypes from 'prop-types';
import ButtonGroup from 'antd/lib/button/button-group';
import { Button } from 'antd';
import OTCReconMobile from '../../recon_enterprise/recon_mobile';
import { Box } from '@material-ui/core';
import CreditDebitRecon from './credit_debit_recon';

const ReconMobile = () => {
    const ubp_enabled = localStorage.getItem('cc_enabled') == 'UnionBank';
    const xendit_enabled = localStorage.getItem('cc_enabled') == 'Xendit';
    const CCEnabled = ubp_enabled || xendit_enabled;
    const [selected, setSelected] = React.useState(0);

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value != index}
                id={`nav-tabpanel-${index}`}
                aria-labelledby={`nav-tab-${index}`}
                {...other}
            >
                {value == index && (
                    <Box style={{ padding: 0 }} p={2}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const ButtonTab = ({ rootStyle }) => {
        const status = ['OTC / Web / E-Wallet', 'Credit / Debit Card'];

        const handleClick = (event) => {
            setSelected(event.target.value);
        }

        return (
            <div style={styles.btnTabContainer}>
                <ButtonGroup>
                    {
                        status.map((btn, i) => {
                            return (
                                <Button
                                    key={i}
                                    value={i}
                                    onClick={handleClick}
                                    style={{
                                        ...styles.btnGroup,
                                        color: selected == i ? '#0D3D76' : '#909196',
                                        backgroundColor: '#FFF',
                                        borderBottom: selected == i ? '2px solid #0D3D76' : '2px solid #909196'
                                    }}
                                >
                                    {btn}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </div>
        )
    }

    return (
        CCEnabled ?
            <div>
                <MetaTag title="Reconciliation" />
                <ButtonTab />
                <TabPanel value={selected} index={0}>
                    <OTCReconMobile />
                </TabPanel>
                <TabPanel value={selected} index={1}>
                    <CreditDebitRecon />
                </TabPanel>
            </div>
            :
            <OTCReconMobile />
    )
}

const styles = {
    root: {

    },
    btnGroup: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: '0 32px',
    },
    btnGroup: {
        fontSize: 14,
        fontWeight: '500',
        textAlign: 'center',
        height: 40,
        padding: '16px',
        paddingBottom: '32px',
        borderColor: 'transparent',
        borderRadius: 0,
    },
    btnTabContainer: {
        backgroundColor: '#FFF',
        width: '100%',
        textAlign: 'center'
    },
};

export default ReconMobile;