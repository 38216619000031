import axios from "axios";
import { history } from "../../store/history";

const isSubDomain = process.env.REACT_APP_ROOT_URL != window.location.hostname;
const testMode = (history.location.pathname.indexOf('/test') > -1);

async function authUser(value) {
  let params = {
    username: value.username,
    password: value.password,
    messenger_id: value.messenger_id
  };

  try {
    let response = null;

    if(isSubDomain){
      let remove_part = `.${process.env.REACT_APP_SUB_URL}`;
      let trade_name = window.location.hostname.replace(remove_part, "");

      let endpoint = testMode ? `/api/sandbox/login/${trade_name}/` : `/api/login/${trade_name}/`
      response = await axios.post(
        process.env.REACT_APP_API_URL + endpoint,
        params
      );
    }else{

      response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/login/",
        params
      );
    }
    if(response.data.status == "success"){

      let userDetails = response.data.user_details;

      if(response.data.access_token){
            
        let token = response.data.access_token;
        localStorage.setItem("userId", userDetails.id);
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("username", userDetails.username);
        localStorage.setItem("email", userDetails.email);
        localStorage.setItem("userType", userDetails.user_type);

        if(userDetails.user_type == 'ME' || userDetails.user_type =='CO' || userDetails.user_type =='SW'){

          localStorage.setItem("firstName", userDetails.first_name);
          localStorage.setItem("lastName", userDetails.last_name);
          localStorage.setItem("middleName", userDetails.middle_name);
          localStorage.setItem("suffix", userDetails.suffix);
          localStorage.setItem("contact", userDetails.contact);
          localStorage.setItem("balance", userDetails.balance);
          localStorage.setItem("isCompleted", userDetails.is_completed)
          localStorage.setItem("isVerified", userDetails.is_verified)
          localStorage.setItem("merchant_bias", userDetails.merchant_bias)
          localStorage.setItem("has_address", userDetails.address)
          localStorage.setItem('Level', userDetails.kyc_level)
          localStorage.setItem("Step", userDetails.kyc_step)
          localStorage.setItem("lastLogin", userDetails.last_login)
          localStorage.setItem("shipping_enabled", userDetails.shipping_enabled)
          localStorage.setItem("admin_711_enabled", userDetails.admin_711_enabled)
          localStorage.setItem("banks_disabled", JSON.stringify(userDetails.banks_disabled))
          localStorage.setItem("channels_disabled", JSON.stringify(userDetails.channels_disabled))
        }

        var defined_wallet_settings = {
            'generate_payment_link_enabled': userDetails.generate_payment_link_enabled,
            'checkout_enabled': userDetails.checkout_enabled,
            'add_money_enabled': userDetails.add_money_enabled,
            'transfer_money_enabled': userDetails.transfer_money_enabled,
            'payout_enabled': userDetails.payout_enabled,
            'payout_type': userDetails.payout_type,
            'link_store_enabled': userDetails.link_store_enabled,
            'exclusive_banners_enabled': userDetails.exclusive_banners_enabled
        }

        localStorage.setItem("defined_wallet_settings", JSON.stringify(defined_wallet_settings));
        localStorage.setItem("GCash_date", userDetails.gcash_created_at)
      
      }

    }

    return response;

  } catch (error) {
    console.log('WEENT HERE');
  }
}
async function fbLogin(params) {

  let response = await axios.post(
    process.env.REACT_APP_API_URL + "/api/facebook/login/",
    params
  );
  
  return response;
}

async function googleLogin(params) {

  let response = await axios.post(
    process.env.REACT_APP_API_URL + "/api/google/login/",
    params
  );
  
  return response;
}

const authorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

async function fetchOtp(dispatch) {
  try {
    const response = await authorizedAxiosRequest().get(`/api/otp/`);
    return response;
  } catch (error) {
    if((error.response)&&(error.response.status == 401)){
    console.log(error);
    }
  }
}

export { authUser, fbLogin, googleLogin, fetchOtp };
