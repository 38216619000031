import moment from "moment";
import { axios } from "../../../../lib/axios";

export function getTransferHistory(
  currentPage,
  searchQuery = "",
  startDate = "",
  endDate = "",
  status = "All"
) {
  const formattedStartDate = startDate
    ? moment(startDate, "MM-DD-YYYY").format("MM-DD-YYYY")
    : "";
  const formattedEndDate = endDate
    ? moment(endDate, "MM-DD-YYYY").format("MM-DD-YYYY")
    : "";
  const summary = formattedStartDate || formattedEndDate ? "Custom" : false;

  return axios.get(
    `/api/payouts/?start=${formattedStartDate}&end=${formattedEndDate}&status=${status}&search=${searchQuery}&summary=${summary}&email=&page=${currentPage}&transfer=True`
  );
}
