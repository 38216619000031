import { Card, Divider, List, Typography } from 'antd';
import React from 'react';

const TransactionCards = (data) => {

    return (
        data &&
        <Card size='small' style={styles.card} bodyStyle={{ padding: 0 }}>
            <Typography style={styles.ref}>{data.ref_code}</Typography>
            <Divider style={{margin: 0}} />
            <div style={styles.detailsDiv}>
                <div style={styles.row}>
                    <Typography style={styles.text}>Total Amount</Typography>
                    <Typography style={{...styles.text, fontWeight: 'bold'}} >PHP {data.amount.toLocaleString('en-US', {minimumFractionDigits: 2})}</Typography>
                </div>
                <div style={styles.row}>
                    <Typography style={styles.text} >Receivable</Typography>
                    <Typography style={{...styles.text, fontWeight: 'bold'}}>PHP {data.receivable.toLocaleString('en-US', {minimumFractionDigits: 2})}</Typography>
                </div>
            </div>
            <Divider style={{margin: 0}} />
            <div style={styles.detailsDiv} >
                <div style={styles.row}>
                    <Typography style={styles.text} >Transaction Date</Typography>
                    <Typography style={styles.text}>{data.transaction_date}</Typography>
                </div>
                <div style={styles.row}>
                    <Typography style={styles.text}>Settlement Date</Typography>
                    <Typography style={styles.text}>{data.settlement_date}</Typography>
                </div>
            </div>
        </Card>
    )
}

const CreditDebitCards = (props) => {
    return (
        props.data &&
        <List
            loading={!props.data}
            dataSource={props.data}
            renderItem={item =>
                <div>
                    <TransactionCards {...item} />
                </div>
            }
        />
    )
}

const styles = {
    card: {
        margin: '16px 0',
        borderRadius: 4,
        padding: 0,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)'
    },
    ref: {
        color: '#2B2D33',
        fontSize: 14,
        fontWeight: '600',
        padding: 12,
        textAlign: 'left',
    },
    detailsDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 12
    },
    row: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    text: {
        fontSize: 14,
        color: '#2B2D33',
    }
}

export default CreditDebitCards;