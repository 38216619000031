import React from 'react';
import AppBarForm from './appBarForm'
class AppBar extends React.Component{
  constructor(props){
    super(props)
 
    }


    render(){
      const { children }= this.props;

        return(
          <AppBarForm children={children} />
        )
    }
}
export default AppBar