import React from 'react'
import termsStyle from '../../static/css/Terms'
import { Button } from 'antd';
import {POLICY, TERMS, SERVICE} from './bti_const'
import BTI_header from '../../static/img/bti_header.svg'
import { isMobile } from 'react-device-detect';

const BtiTerms = props => {
    const terms = termsStyle();
    return(
        <div>
            <div style={{padding: isMobile ? '0px 0px 0px 0px' : '0px 250px 0px 250px'}}>
                <img src={BTI_header} style={{width: '100%', borderBottom: '2px solid #03539c'}}/>
            </div>
            <div className={terms.btiBody}>
                <div style={{display: 'flex'}}>
                    <div className={terms.mainHeader}>Privacy Policy</div>
                </div>
                <div className={terms.body}>
                    {
                        POLICY.map((item) => {
                            return(
                                <div>
                                    <div className={terms.title}>{item.q}</div>
                                    <div className={terms.text}>{item.d}</div>
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{display: 'flex'}}>
                    <div className={terms.mainHeader}>Self-Service Payment Terminal Terms of Use</div>
                </div>
                <div className={terms.body}>
                    {
                        TERMS.map((item) => {
                            return(
                                <div>
                                    <div className={terms.title}>{item.q}</div>
                                    <div className={terms.text}>{item.d}</div>
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{display: 'flex'}}>
                    <div className={terms.mainHeader}>Self-Service Payment Terminal Services</div>
                </div>
                <div className={terms.body}>
                    {
                        SERVICE.map((item) => {
                            return(
                                <div>
                                    <div className={terms.title}>{item.q}</div>
                                    <div className={terms.text}>{item.d}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
        
    )

}

export default BtiTerms