import React from 'react';
import { isMobile } from 'react-device-detect';
import { BuxRoundBlue } from '../../../../static/icons/dashboard_icons/';
import { BuxWalletBalanceBG, MasterWalletIcon, SubwalletIcon, PayoutIcon } from '../../../../static/images//multiwallet';
import { Card, Typography, Icon, Tooltip } from 'antd';
import * as Icons from '../../../../static/icons/dashboard_icons/index';
import { segmentStroke } from '../../../constants/constants';

const WalletBalanceCards = (props) => {
    const { balance } = props;

    console.log(" =====> "+balance)
    const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
    const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
    const isDell1 = window.screen.width * window.devicePixelRatio === 1366 && window.screen.height * window.devicePixelRatio === 768;
    
    const isDellReso = isSmallReso || isDell || isDell1;

    return (
        <div className="wallet-balance-card">
            <div style={{display: 'flex'}}>
                <img src={Icons.BuxRoundBlue} style={{width: 24}} alt='#' />

                <div className="left-16">
                    <div className="normalSizeStyle liveColor">
                        BUx Wallet Balance
                    </div>
                    <div className="boldTextStyle liveColor" style={{fontSize: isSmallReso ? 18 : '1vw', marginTop: 4}}>
                        ₱ {balance && balance.total ? balance.total.toLocaleString("en-US", {minimumFractionDigits: 2}) : '0.00'}
                    </div>
                </div>
            </div>
            
            <div className="wallet-balance-div top-16">
                <img alt='No Image' src={PayoutIcon} />
                
                <div className="left-16">
                    <Typography className="normalSizeStyle">
                        Transferable Balance 
                        <Tooltip placement="bottom" title="Balance that you can transfer to another wallet or payout">
                            <Icon type="info-circle" theme="filled" style={{color: segmentStroke[1]}} className="left-4" />
                        </Tooltip>
                    </Typography>
                    <Typography className="boldTextStyle" >{balance && balance.float? '₱ ' + parseFloat(balance.float).toLocaleString('en-US', {minimumFractionDigits: 2}) : '₱ 0.00'}</Typography>
                </div>
            </div>

            <div className="wallet-balance-div">
              <img alt='No Image' src={MasterWalletIcon } />
              <div className="left-16">
                <Typography className="normalSizeStyle">Master Wallet Balance</Typography>
                <Typography className="boldTextStyle" >{balance && balance.master? '₱ ' + parseFloat(balance.master).toLocaleString('en-US', {minimumFractionDigits: 2}) : '₱ 0.00'}</Typography>
            </div>
          </div>

          <div className="wallet-balance-div" style={{border: 0}}>
              <img alt='No Image' src={SubwalletIcon } />
              <div className="left-16">
                <Typography className="normalSizeStyle">Sub-wallet(s) Balance</Typography>
                <Typography className="boldTextStyle">{balance && balance.sub_users? '₱ ' + parseFloat(balance.sub_users).toLocaleString('en-US', {minimumFractionDigits: 2}) : '₱ 0.00'}</Typography>
              </div>
          </div>
        </div>
    )
}

export default WalletBalanceCards;