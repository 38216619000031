import React, {useEffect} from 'react';
import Main from "../../../layouts/Main";
import * as Style from '../../upgrade_to_business/upgrade_to_business_style';
import * as Style2 from './kyc';
import {Grid} from "@material-ui/core";
import {Button, Input, message} from "antd";
import {isMobile} from 'react-device-detect';
import axios from 'axios'
import {history} from '../../../store/history'
import ProgressCard from '../../kyb/forms/progress_card'
import ReviewCard from './mainReview_admin'


const Kyb = props => {

    const pUid = props.props.match.params.id;
    const { TextArea } = Input;
    const [step, setStep] = React.useState("")
    const [remarks, setRemarks] = React.useState("")
    const [stepFailed, setStepFailed] = React.useState({})

    const [failedStep1, setFailedStep1] = React.useState(false)
    const [failedStep2, setFailedStep2] = React.useState(false)
    const [failedStep3, setFailedStep3] = React.useState(false)
    const [failedStep4, setFailedStep4] = React.useState(false)
    const [failedStep5, setFailedStep5] = React.useState(false)
    const [failedStep6, setFailedStep6] = React.useState(false)
    const [showAlert, setShowAlert] = React.useState(true)
    const [showSpin, setShowSpin] = React.useState(false)
    const [fetchDone, setFetchDone] = React.useState(false)

    const [submissionId,setSubmissionId] = React.useState("")
    const [alertMessage, setAlertMessage] = React.useState("")
    const [dateSubmitted, setDateSubmitted] = React.useState("")
    const [childData, setChildData] = React.useState()

    const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;

    const reviewCardsDivRefs = {
        'business_info_div_ref': React.createRef(),
        'contact_info_div_ref': React.createRef(),
        'business_document_div_ref': React.createRef(),
        'signatory_info_div_ref': React.createRef(),
        'moa_nda_div_ref': React.createRef(),
        'bank_account_div_ref': React.createRef(),
    };

    function backTopTop(){
        try {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          } catch (error) {
            window.scrollTo(0, 0);
          }
        }

    async function getRemarks(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.get(
            process.env.REACT_APP_API_URL + `/api/kyb/${pUid}/`,yourConfig)
            
        console.log(response)
            
            if(response.data.status == "failed"){
                setAlertMessage(response.data.message)
                setShowAlert(false)
                backTopTop()
                setShowSpin(true)

            }

            else {
                localStorage.setItem('spocEmail', response.data.email)
                setShowSpin(true)
                backTopTop()
                setStep(response.data.step)
                setFetchDone(true)
                setChildData(response.data)
                setShowAlert(true)
                setDateSubmitted(response.data.created_at)
                setSubmissionId(response.data.id)
                setStep(response.data.step)
                setRemarks(response.data.remarks)
                setFailedStep1(response.data.step_1_has_error)
                setFailedStep2(response.data.step_2_has_error)
                setFailedStep3(response.data.step_3_has_error)
                setFailedStep4(response.data.step_4_has_error)
                setFailedStep5(response.data.step_5_has_error)
                setFailedStep6(response.data.step_6_has_error)

            }
        
    }

    function getFailedSteps(step1, step2, step3, step4, step5, step6){
        let params = {
            step1, step2, step3, step4, step5, step6
        }        
        setStepFailed(params)
    }

    useEffect(() => {
        if(localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") == null){
            history.push('/login')
        }else{
            if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
              history.push('/dashboard')
            }
            else if(localStorage.getItem("userType") == "MA"){
              history.push('/overview')
            }
            else{
                getRemarks()
            }
            
        }
    }, [props.props])

    return(
             <div style={{
            display: isMobile ? 'block' : 'flex', 
            justifyContent:'center', 
            padding: isMobile ? '10px 16px 10px 16px' : '50px 0px 20px 0px',
        }}>
            <div style={{display: isMobile ? 'block' : 'flex'}}>
                <div style={{
                    position: isMobile ? 'relative' : 'fixed', 
                    marginRight: isMobile ? '0px' : '270px',
                    marginTop: isMobile ? '20px': '0px'
                }}>
                    <ProgressCard step={step} userId={pUid}
                                failedSteps={stepFailed}
                                 error1={failedStep1}
                                 error2={failedStep2}
                                 error3={failedStep3}
                                 error4={failedStep4}
                                 error5={failedStep5}
                                 error6={failedStep6}
                                 reviewCardsDivRefs={reviewCardsDivRefs}
                    />
                </div>

                <div style={{marginLeft: isMobile ? '0px' : isHighReso ? '300px' : '270px', marginTop: isMobile ? '20px' : '0px'}}>
                    <ReviewCard getFailedSteps={getFailedSteps} userId={pUid} cardDivsRefs={reviewCardsDivRefs} submissionId={submissionId}
                    childData={childData} setChildData={setChildData} backToTop={backTopTop} alertMessage={alertMessage} setAlertMessage={setAlertMessage}
                        showAlert={showAlert} setShowAlert={setShowAlert}
                        showSpin={showSpin} fetchDone={fetchDone} />

                </div>
            </div>

        </div>
            
    )
}

export default Kyb;