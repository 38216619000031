import React from 'react'
import axios from 'axios'
import FeeCard from './feeCard'
import OTCFeeCard from './channels/otc'
import WebBankingCard from './channels/web_banking'
import EWalletCard from './channels/e_wallet'
import CardPayment from './channels/cc_dc'
import BNPLCard from './channels/bnpl';
import CustomModal from './customize_modal'
import SetDefaultModal from './default_modal'
import TranxFeeToggle from './tranx_fee_toggle';
import MultiwalletToggle from './multiwallet_toggle';
import {Button, Icon, Typography, Input, message, Card} from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import feesServices from '../feesServices';
import TransferMoney from './transfer_money';
import MultiwalletCheckoutToggle from './multiwallet_checkout_toggle.';

const Fee = props => {
    const pUid = props.props.match.params.id;

    const [editModeValue, setEditModeValue] = React.useState()
    const [channelsParams, setChannelsParams] = React.useState()
    const [showModal, setShowModal] = React.useState(false)
    const [restoreModal, setRestoreModal] = React.useState(false)
    const [selectedTab, setSelectedTab] = React.useState('0');
    const [feesData, setFeesData] = React.useState(null);
    const [transferFeesData, setTransferFeesData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [tranxToggle, setTranxToggle] = React.useState(false)
    const [multiwalletToggle, setMultiwalletToggle] = React.useState(false)
    const [multiCheckoutToggle, setMultiCheckoutToggle] =React.useState(false);
    const [transferPaymentType, setTransferPaymentType] = React.useState("CU")

    function getEditModeValue(val){
        setEditModeValue(val)
    }

    async function submitFee(params) {
        setLoading(true);
        try {
            let res = await feesServices.updateUserFees(pUid, params)
                if(res.status == "success"){
                    setShowModal(false);
                    if(!res.message) {
                        window.location.reload()
                    } else {
                        successPrompt(res.message);
                        getFees();
                    }
                } else {
                    setShowModal(false);
                    errorPrompt('Something went wrong, try again.');
                }
                setLoading(false);
            }
        catch (error) {
            console.log(error)
            setShowModal(false);
            errorPrompt('Something went wrong, try again.');
            setLoading(false)
        }
    }

    async function submitDefault() {   
        setLoading(true);
        try {
            let res = await feesServices.resetDefaultUserFees(pUid);
                if(res.status == "success"){
                    setRestoreModal(false);
                    window.location.reload()
                } else {
                    errorPrompt('Something went wrong, try again.');
                    setRestoreModal(false);
                }
                setLoading(false);
            }
        catch (error) {
            console.log(error)
            setRestoreModal(false);
            errorPrompt('Something went wrong, try again.');
            setLoading(false);
        }
    }

    const errorPrompt = (msg) => {
        message.error(msg);
      };
    
    const successPrompt = (msg) => {
        message.success(msg);
    };

    const getFees = async () => {
        setLoading(true);
        try {
            const res = await feesServices.getUserFees(pUid);
            if(res){
                setFeesData(res);
            } else {
                errorPrompt('Something went wrong, try again.');
            }
            setLoading(false);
       } catch (error) {
           console.log(error);
           errorPrompt('Something went wrong, try again.');
           setLoading(false);
       }
    }


    const getTransferFees = async () => {
        setLoading(true);
        try {
            const res = await feesServices.getTransferFee(pUid);
            if(res.status == 'success'){
                setTransferFeesData(res.data);
                console.log(res.data)
            } else {
                errorPrompt('Something went wrong, try again.');
            }
            setLoading(false);
       } catch (error) {
           console.log(error);
           errorPrompt('Something went wrong, try again.');
           setLoading(false);
       }
    }

    const getSettings = async () => {
        try {
            const res = await feesServices.userTranxFeeInit(pUid);
            console.log(res)
            if(res){
                setTranxToggle(res.shoulder_enabled);
                setMultiwalletToggle(res.multiwallet_enabled);
                setMultiCheckoutToggle(res.subwallet_checkout_enabled);
                setTransferPaymentType(res.transfer_payment_type);
            } else {
                errorPrompt('Something went wrong, try again.');
            }
            setLoading(false);
       } catch (error) {
           console.log(error);
       }
    }

    const ButtonTab = () => {
        
        const status = ['Over-the-counter', 'Online Banking', 'E-Wallet', 'Credit / Debit Card', 'Buy Now Pay Later', 'Multiwallet'];
        
        const handleClick = (event) => {
        setSelectedTab(event.target.value);
        }
        
        return (
          <div>
            <ButtonGroup>
              {
                status.map((btn, i) => {
                  return (
                    <Button
                      key={btn}
                      value={i}
                      onClick={handleClick}
                      style={{...styles.btnGroup, 
                        color: btn === status[selectedTab]? '#FFF' : '#2B2D33', 
                        backgroundColor: btn === status[selectedTab]? '#0D3D76' : '#FFF',
                        borderRadius: i === 0? '10px 0 0 10px' : i === 5? '0 10px 10px 0' : 0 }}
                        >
                        {btn}
                    </Button>
                  )
                })
              }
            </ButtonGroup>
          </div>
        )
    }

    const RestoreSetting = () => {
        return (
            <div style={styles.restoreDiv} hidden={selectedTab == 5}>
                <Button type='link' style={styles.restoreBtn} onClick={openRestoreModal}>Restore Default Fees</Button>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-4px'}}>
                    <Icon type="exclamation-circle" theme="filled" style={{color: '#909196', backgroundColor: '#FFF', borderRadius: 20, fontSize: 12, paddingRight: 4}} />
                    <Typography style={{fontSize: 12, color: '#54575F'}}>Overwrite the current Channel’s Fee with the default settings.</Typography>
                </div>
            </div>
        )
    }

    function openModal(params){
        setChannelsParams(params)
        setShowModal(true)
    }

    function toggleAPIControl(params){
        setChannelsParams(params)
        submitFee(params);
    }

    function closeModal(){
        setShowModal(false)
    }
    
    function openRestoreModal(){
        setRestoreModal(true)
    }

    function closeRestoreModal(){
        setRestoreModal(false)
    }

    React.useEffect(() => {
        if(localStorage.getItem("userType") != "PS"){
            getFees();
            getTransferFees();
            getSettings();
        }
    },[selectedTab])

    return(
        <div style={styles.root}> 
            <TranxFeeToggle pUid={pUid} init_enabled={tranxToggle}/>
            <MultiwalletCheckoutToggle pUid={pUid} init_enabled={multiCheckoutToggle}/>
            <MultiwalletToggle pUid={pUid} init_enabled={multiwalletToggle} paymentType={transferPaymentType} transferFeesData={transferFeesData}/>
            <div align='left' style={styles.btnTab}>
                <ButtonTab />
                <RestoreSetting />
            </div>

            <Card bodyStyle={{padding: 20}} style={{margin: '20px 12px'}}>
                { selectedTab == 0 && (<OTCFeeCard openModal={openModal} pUid={pUid} getEditModeValue={getEditModeValue} editModeVal={editModeValue} data={feesData} loading={loading} toggleUserFeeModal={toggleAPIControl} /> )}
                { selectedTab == 1 && (<WebBankingCard openModal={openModal} pUid={pUid} getEditModeValue={getEditModeValue} editModeVal={editModeValue} data={feesData} loading={loading} toggleUserFeeModal={toggleAPIControl} /> )}
                { selectedTab == 2 && (<EWalletCard openModal={openModal} pUid={pUid} getEditModeValue={getEditModeValue} editModeVal={editModeValue} data={feesData} loading={loading} toggleUserFeeModal={toggleAPIControl} /> )}
                { selectedTab == 3 && (<CardPayment openModal={openModal} pUid={pUid} getEditModeValue={getEditModeValue} editModeVal={editModeValue} data={feesData} loading={loading} toggleUserFeeModal={toggleAPIControl} /> )}
                { selectedTab == 4 && (<BNPLCard openModal={openModal} pUid={pUid} getEditModeValue={getEditModeValue} editModeVal={editModeValue} data={feesData} loading={loading} toggleUserFeeModal={toggleAPIControl} /> )}
                { selectedTab == 5 && (<TransferMoney data={[]} uid={pUid} />) }
            </Card>
            <CustomModal closeModal={closeModal} submitFee={submitFee} showModal={showModal} channelsParams={channelsParams} loading={loading}/>
            <SetDefaultModal closeModal={closeRestoreModal} submitDefault={submitDefault} openModal={restoreModal} loading={loading}/>
        </div>
    )
}

const styles = {
    root: {
        width: '71vw'
    },
    btnGroup: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: '0 16px',
    },
    btnTab: {
        padding: '0 12px'
    },
    restoreDiv: {
        marginTop: 16, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'flex-end'
    }, 

    restoreBtn: {
        fontSize: 16,
        color: '#F5922F',
        fontWeight: 'bold',
        padding: 0,
    },

}

export default Fee;
