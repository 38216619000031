import React from 'react';
import { Modal, Typography, Icon } from 'antd';

const ChangeMobileModal = (props) => {
    const { segments, visible, onContinue, onClose } = props;

    return (
        <Modal
            visible={visible}
            footer={false}
            width={382}
            destroyOnClose
            style={{ borderRadius: 8 }}
            onClose={onClose}
            closeIcon={<Icon type="close" className={`link-text--${segments}`} />}
        >
             <Typography style={styles.title}>Change Mobile Number</Typography>
            <Typography style={styles.msg}>
                A temporary link will be sent to your registered email address and you may use it to update your mobile number
            </Typography>
            <div style={styles.btnDiv}>
                <button className={`btn--${segments}`} style={styles.continueBtn} onClick={onClose} >Got it</button>
            </div>
        </Modal>
    )
}

const styles = {
    title: {
        color: '#2B2D32',
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'left',
        paddingBottom: '20px'
    },
    msg: {
        color: '#2B2D33',
        fontSize: 16,
        textAlign: 'left',
        padding: '16px 0 32px 0'
    },
    cancelBtn: {
        cursor: 'pointer',
        margin: '0px 16px',
        height: 40,
        padding: '0 12px'
    },
    continueBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        cursor: 'pointer',
    },
    btnDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
}

export default ChangeMobileModal;