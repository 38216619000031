import React, { useState, useEffect } from "react";
import MetaTag from "../../../../../components/meta_tag/meta_tag";
import { Pagination, Table, Card, Typography, Button, Icon } from "antd";
import { SearchInput, FilterBar } from "../../components";
import { history } from "../../../../../store/history";
import FeeConfigModal from "./fee-config-modal";
import MerchantFeeConfigViewModel from "./fee-config-viewmodel";
import "./css/fee-config.css";

const MerchantFeeConfig = (props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [tempSearchQuery, setTempSearchQuery] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const { fetchMerchantFeeList, merchantFeeData, isLoading } =
    MerchantFeeConfigViewModel();

  function closeModal() {
    setOpenModal(false);
    fetchMerchantFeeList(tempSearchQuery, currentPage);
  }

  function onSubmitModal() {
    setOpenModal(false);
    setCurrentPage(1);
    fetchMerchantFeeList(tempSearchQuery, 1);
  }

  function openEditModal(record) {
    setEditData(record);
    setOpenModal(true);
  }

  useEffect(() => {
    if (
      localStorage.getItem("jwtToken") == "" ||
      localStorage.getItem("jwtToken") == null
    ) {
      history.push("/login");
    } else {
      if (
        localStorage.getItem("userType") == "ME" ||
        localStorage.getItem("userType") == "CO"
      ) {
        history.push("/dashboard");
      } else if (localStorage.getItem("userType") == "PS") {
        history.push("/orders");
      } else if (localStorage.getItem("userType") == "MA") {
        history.push("/overview");
      } else {
        fetchMerchantFeeList("", currentPage);
      }
    }
  }, []);

  useEffect(() => {
    fetchMerchantFeeList(searchQuery, currentPage);
  }, [currentPage]);

  const columns = [
    {
      title: "Merchant ID",
      dataIndex: "merchant_id",
      fixed: "left",
    },
    {
      title: "Merchant Name",
      dataIndex: "merchant_business",
    },
    {
      title: "Merchant Email Address",
      dataIndex: "merchant_email",
    },
    {
      title: "Settlement Merchant Name",
      dataIndex: "settle_merchant_business",
    },
    {
      title: "Settlement Merchant Email Address",
      dataIndex: "settle_merchant_email",
    },
    {
      title: "CashOut Terminal ID",
      dataIndex: "cashout_terminal_ids",
      render: (text, record) => {
        if (record.cashout_terminal_ids.length > 3) {
          const temp = record.cashout_terminal_ids.slice(0, 3);
          return (
            <span title={text.join(", ")}>{temp.join(", ") + ", ..."}</span>
          );
        }

        if (text && text.length > 0) {
          return text.join(", ");
        } else {
          return "";
        }
      },
    },
    {
      title: "Debit Terminal ID",
      dataIndex: "debit_terminal_ids",
      render: (text, record) => {
        if (record.debit_terminal_ids.length > 3) {
          const temp = record.debit_terminal_ids.slice(0, 3);
          return (
            <span title={text.join(", ")}>{temp.join(", ") + ", ..."}</span>
          );
        }

        if (text && text.length > 0) {
          return text.join(", ");
        } else {
          return "";
        }
      },
    },
    {
      title: "ISA Parent Name",
      dataIndex: "isa_parent_name",
    },
    {
      title: "ISA Parent Email Address",
      dataIndex: "isa_parent_email",
    },
    {
      title: "Cash Withdrawal Merchant Fee",
      dataIndex: "withdraw_merchant_fee",
    },
    {
      title: "Cash Withdrawal UBX Fee",
      dataIndex: "withdraw_ubx_fee",
    },
    {
      title: "Cash Withdrawal Bank Fee",
      dataIndex: "withdraw_bank_fee",
    },
    {
      title: "Cash Withdrawal Device Supplier Fee",
      dataIndex: "withdraw_supplier_fee",
    },
    {
      title: "POS Debit UBX Fee",
      dataIndex: "debit_ubx_fee",
    },
    {
      title: "POS Debit Bank Fee",
      dataIndex: "debit_bank_fee",
    },
    {
      title: "POS Debit Device Supplier Fee",
      dataIndex: "debit_supplier_fee",
    },
    {
      title: "ISA Merchant Fee",
      dataIndex: "isa_merchant_fee",
    },
    {
      title: "ISA Parent Fee",
      dataIndex: "isa_parent_fee",
    },
    {
      title: "Date Updated",
      dataIndex: "modified_at",
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
    },
    {
      title: "Action",
      render: (record) => {
        return <a onClick={() => openEditModal(record)}>Edit</a>;
      },
    },
  ];

  return (
    <div
      style={{
        margin: "0 2.5em 2.5em 2.5em",
        display: "flex",
        gap: "2em",
        flexDirection: "column",
        paddingBottom: "50px",
      }}
    >
      <MetaTag title="CashBux Merchant Configs" />
      <div>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "20px",
            color: "#2B2D33",
          }}
        >
          Merchant Configs
        </Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            size="large"
            type="primary"
            onClick={() => {
              setEditData(null);
              setOpenModal(true);
            }}
          >
            <Icon type="plus" />
            Add Merchant Configs
          </Button>
        </div>
        <Card className="fee-config-card">
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <FilterBar
              initialValue={""}
              selectedOption={""}
              searchQuery={searchQuery}
              clearFilters={() => {
                setCurrentPage(1);
                setSearchQuery("");
                setTempSearchQuery("");
                fetchMerchantFeeList("", 1);
              }}
            />
            <SearchInput
              tempSearchQuery={tempSearchQuery}
              setTempSearchQuery={setTempSearchQuery}
              handleApplySearch={() => {
                setCurrentPage(1);
                setSearchQuery(tempSearchQuery);
                fetchMerchantFeeList(tempSearchQuery, 1);
              }}
              placeholder="Search by Merchant Name"
            />
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              dataSource={merchantFeeData.results}
              columns={columns}
              pagination={false}
              loading={isLoading}
              scroll={{ x: true }}
            />

            <Pagination
              size="small"
              total={(merchantFeeData && merchantFeeData.total) || 0}
              defaultPageSize={pageSize}
              defaultCurrent={1}
              current={currentPage}
              onChange={setCurrentPage}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              style={{ paddingTop: "12px", textAlign: "right" }}
            />
          </div>
        </Card>
      </div>

      <FeeConfigModal
        editData={editData}
        openModal={openModal}
        closeModal={closeModal}
        submitModal={onSubmitModal}
      />
    </div>
  );
};

export default MerchantFeeConfig;
