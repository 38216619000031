import { isMobile, isAndroid, isIOS } from 'react-device-detect'
import ArrowDown from '../../../static/icons/arrow-down.svg'
const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
const segments = localStorage.getItem("segments")
//Card Style/s
export const progressCardStyle = {
    width: isMobile ? '100%' : isHighReso? '228px' : '258px',
    // backgroundColor: '#fff',
    // border: '1px solid #D1D5DD',
    // borderRadius: '10px',
    marginTop: isMobile ? '20px' : '0px'
}
export const trackerStyle = {
    backgroundColor: '#65C1F2',
    borderRadius: '40px',
    width: isHighReso? '55px' : '64px',
    height: isHighReso? '20px' : '24px',
    color: '#fff',
    textAlign: 'center',
    fontSize: isHighReso? '10px' : '12px',
    lineHeight: isHighReso? '20px' : '24px'
}

export const formCardStyle = {
    width: isMobile ? '100%' : isHighReso? '534px' : '634px',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)'
}

export const greatJobCard = {
    width: isMobile ? '100%' : isHighReso? '534px' : '634px',
    backgroundColor: '#fff',
    position: isMobile ? 'fixed' : 'relative',
    zIndex: isMobile ? 100 : 1,
    marginBottom: '10px',
    marginLeft: isMobile ? '-16px' : '0px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
}

export const reviewCard = {
    width: isMobile ? '100%' : isHighReso? '534px' : '634px',
    backgroundColor: '#fff',
    marginBottom: '16px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
    marginTop: isMobile && '36px'

}

export const resubmitDiv = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '80px',
    backgroundColor: '#1D82B8',
    paddingLeft: '180px'
}

export const resubmitDivMob = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '130px',
    backgroundColor: '#1D82B8',
    padding: '10px 16px 16px 16px'
}

export const rejectedCard = {
    backgroundColor: 'rgba(248, 185, 102, 0.1)',
    border: '1px solid #F8B966',
    borderRadius: '4px',
    padding: isMobile ? '16px 16px 16px 16px' : '32px 16px 16px 16px',
    width: isMobile ? '100%' : isHighReso? '534px' : '634px',
    marginBottom: '10px'
}

export const learnCard = {
    backgroundColor: '#fff',
    border: '1px solid #D1D5DD',
    borderRadius: '10px',
    width: isHighReso? '228px' : '258px'
}


//Font Style/s
export const headerStyle = {
    fontWeight: 'bold',
    color: '#2b2d33'
}

export const header2Style = {
    fontWeight: 'bold',
    color: '#000000',
    fontSize: isMobile ? '16px' : '20px',
    lineHeight: isMobile ? '16px' : '40px'
}

export const miniText = {
    fontSize: isHighReso? '10px' : '12px',
    lineHeight: '12px'
}

export const learnMore = {
    fontStyle: 'normal',
    fontSize:  '12px',
    fontWeight: '600',
    lineHeight: '18px',
}

export const normalText = {
    fontStyle: 'normal',
    fontSize: isMobile || isHighReso ? '12px' : '16px',
    fontWeight: 'normal',
    lineHeight: isMobile ? '18px' : '24px',
}

export const boldText = {
    fontStyle: 'normal',
    fontSize: isMobile || isHighReso ? '14px' : '16px',
    fontWeight: 'bold',
    lineHeight: isMobile ? '18px' : '24px',
}

export const requiredStyle = {
    fontStyle: 'normal',
    fontSize: isMobile || isHighReso ? '10px' : '12px',
    color: '#E24C4C',
    marginTop: '3px',
    lineHeight: isMobile ? '18px' : '24px',
}

// export const resubmitText = {
//     fontStyle: 'normal',
//     fontSize: '16px',
//     fontWeight: 'bold',
//     lineHeight: isMobile ? '18px' : '24px',
// }

export const resubmitText = {
    fontStyle: 'normal',
    fontSize: '16px',
    color: '#FFF',
}

//Component Style/s
export const submitBtnStyle = {
    backgroundColor: segments == 'me_' ? '#0DAED0' : segments == 'gig' ? '#41BD5C' : '#F5922F',
    width: isMobile ? '49%' : '173px',
    height: isHighReso? '42px' : '48px',
    color: '#fff',
}

export const backBtnMobile = {
    height: isHighReso ? '42px' : '48px',
    width: '49%',
    marginRight: '2%'
}

export const allGoodBtn = {
    backgroundColor: segments == 'me_' ? '#0DAED0' : segments == 'gig' ? '#41BD5C' : '#F5922F',
    borderRadius: '4px',
    width: '102',
    marginTop:'10px',
    height: '40px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: '600',
}
export const resubmitBtnStyle = {
    backgroundColor: '#F5922F',
    borderRadius: '10px',
    width: '256px',
    height: '48px',
    color: '#fafafa',
    fontSize: '16px',
    fontWeight: '600',
    border: '1px solid #F5922F'
}
export const editBtn = {
    border: '1px solid #2B2D32',
    width: '100px',
    borderRadius: '4px',
    height: '32px',
    fontSize: '16px',
    fontWeight: '500',
}
export const cancelBtn = {
    border: '1px solid #2B2D32',
    width: '100px',
    borderRadius: '4px',
    height: '32px',
    fontSize: '16px',
    fontWeight: '500',
}
export const inputStyle = {
    width: '100%',
    height: '38px',
    borderRadius: '4px',
    color: '#000',
    fontSize: '12px',
    WebkitAppearance: 'none',
    outline: 'none'
}

export const uploadIDStyle = {
    border: '1px solid #F5922F',
    borderRadius: '10px',
    width: '100%',
    height: '40px'
}
export const tipsBtnStyle = {
    borderRadius: '40px',
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#fff',
    height: '32px'
}

export const selectStyle = {
    width: '100%',
    height: '38px',
    borderRadius: '4px',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    appearance: 'none',
    background: `url(${ArrowDown})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '95% 50%',
    fontSize: '12px',
    outline: 'none'
}

export const cameraCardWeb = {
    boxShadow: '0px -7px 10px rgba(0, 0, 0, 0.04)',
    position: "fixed",
    zIndex: "200",
    top: "5%",
    left: "10%",
    width: '80%',
    height: '90%',
    background: '#2b2d33'
}

export const captureBtn = {
    height: '60px',
    width: '60px',
    zIndex: '999',
    borderRadius: '50%',
    border: '5px solid #f5f5f4',
    background: '#1d82b3',
    color: '#fff'

}

export const closeCamera = {
    background: '#fff',
    border: '1px solid #fff',
    color: '#2b2d33',
    fontWeight: '600',
    height: '50px',
    width: '50px',
}

export const reminderStyle = {
    padding: '8px', 
    display: 'flex', 
    justifyContent: 'end',
    width: isMobile? '100%' : '258px',
}

export const filledBtn = {
    background: '#f5922f',
    color: '#fff',
    borderRadius: '4px',
    fontWeight: 'bold'
}

export const backToDashboard = {
    fontSize: '16px',
    fontWeight: '700',
    margin:'-20px 0px 10px 0px'
}

export const uploadDiv ={
    width: '100%',
    height: '82px',
    border: '1px solid #E6EAF0',
    borderRadius: '4px',
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',

}

export const fileName= {
    fontSize: '16px', 
    color: '#2b2d33',
    margin: '15px 0px 0px 10px',
    width: '270px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap', 
}

export const uploadedImage= {
    height: '100%',
    maxWidth: '120px',
}

export const uploadPhoto = {
    height: '28px',
    border: '1px solid #2b2d32',
    color: '#2b2d32',
    fontWeight: 'normal',
    margin: '18px 0px 10px 0px'
}