const requirements = {
    "dti_registration_attachment": {
        title: "DTI Registration",
    },
    "business_permit_attachment": {
        title: "Business Permit",
    },
    // "bir_tax_document_attachment": {
    //     title: "BIR Tax Document",
    // },
    "bir_proof_of_receipt_attachment": {
        title: "BIR Proof of Receipt",
    },
    "mayors_permit_attachment": {
        title: "Mayor's Permit",
    },
    "sec_cert_articles_of_partnership": {
        title: "SEC Certificate of FIling of Articles of Partnership",
    },
    "articles_of_partnership": {
        title: "Article of Incorporation",
    },
    "sec_registration_attachment": {
        title: "SEC Registration",
    },
    "bir_2303_attachment": {
        title: "BIR 2303",
    },
    "valid_ids": {
        title: "Valid ID of Authorized Signatories and/or Owner",
    },
    "payment_facility_agreement": {
        title: "Signed Payment Facility Agreement",
    },
    "gis_attachment": {
        title: "Latest GIS",
    },
    "bylaws_attachment": {
        title: "By-Laws",
    },
    "signed_moa_attachment": {
        title: "Signed Memorandum of Agreement",
    },
    "signed_nda_attachment": {
        title: "Signed Non-Disclosure Agreement",
    },
    "secretary_certificate_attachment": {
        title: "Partnership Resolution"
    }
}

const requirements_per_type = {
    0: {
        'title': 'Sole Proprietorship Requirements',
        'requirements': [
            "dti_registration_attachment",
            "mayors_permit_attachment",
            "bir_2303_attachment",
            "valid_ids",
            "business_permit_attachment",
            // "bir_tax_document_attachment",
            "signed_moa_attachment",
            "signed_nda_attachment",
        ]
    },
    1: {
        'title': 'Partnership Requirements',
        'requirements': [
            "articles_of_partnership",
            "business_permit_attachment",
            "sec_cert_articles_of_partnership",
            "secretary_certificate_attachment",
            "bir_2303_attachment",
            "valid_ids",
            "signed_moa_attachment",
            "signed_nda_attachment",
        ]
    },
    2: {
        'title': 'Corporation Requirements',
        'requirements': [
            "sec_registration_attachment",
            "articles_of_partnership",
            "bylaws_attachment",
            "bir_2303_attachment",
            "gis_attachment",
            "business_permit_attachment",
            "valid_ids",
            "signed_moa_attachment",
            "signed_nda_attachment",
        ]
    },
}

export {requirements, requirements_per_type}