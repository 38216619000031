import React from 'react';
import * as bg from '../../static/new_segment/background_images_svg';
import { Modal, Typography } from 'antd';

import { getLatestBalance } from '../Transfer/api';

const ReminderModal = (props) => {
    const { segments, visible, onContinue, onClose } = props;

    const fetchUserIsPayoutRestricted = async () => {
        try {
          const response = await getLatestBalance();
          const is_payout_restricted = await response.is_payout_restricted;
          onContinue(is_payout_restricted);
        } catch (error) {
          console.log(error);
        }
    }

    return (
        <Modal
            maskClosable={false}
            closable={false}
            visible={visible}
            footer={false}
            width={382}
            destroyOnClose
            style={{ borderRadius: 8 }}
        >
            <img src={bg.PayoutDisclaimerBG} alt='No Image' style={{ paddingTop: 12 }} />
            <Typography style={styles.msg}>
                You acknowledge that you have provided your goods and services to your customers by continuing with the payout.
            </Typography>
            <div style={styles.btnDiv}>
                <button className={`outline-btn--${segments}`} style={styles.cancelBtn} onClick={onClose} >Cancel</button>
                <button className={`btn--${segments}`} style={styles.continueBtn} onClick={fetchUserIsPayoutRestricted} >Continue Payout</button>
            </div>
        </Modal>
    )
}

const styles = {
    msg: {
        color: '#2B2D33',
        fontSize: 16,
        textAlign: 'left',
        padding: '16px 0 32px 0'
    },
    cancelBtn: {
        cursor: 'pointer',
        margin: '0px 16px',
        height: 40,
        padding: '0 12px'
    },
    continueBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        cursor: 'pointer',
    },
    btnDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}

export default ReminderModal;