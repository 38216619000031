import React from 'react';

import {Input, Icon, Table, Button} from 'antd';
import axios from 'axios';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import {useEffect} from 'react';
import {history} from '../../../store/history';

const Wallet = () => {
    useEffect(() => {
        const redirectionPath = getRedirectionPath();
        redirectionPath ? history.push(redirectionPath) : getUserWallets();
    }, []);

    function getRedirectionPath() {
        if (! localStorage.getItem("jwtToken")) {
            return '/login';
        }

        let redirectionPath = null;
        const userType = localStorage.getItem("userType");
        if (userType === "ME" || userType === "CO") {
            redirectionPath = '/dashboard';
        } else if (userType === "PS") {
            redirectionPath = '/orders';
        } else if (userType === "PF") {
            redirectionPath = '/recon/7_eleven';
        }

        return redirectionPath;
    }


    const [searchValue, setSearchValue] = React.useState("");
    const [wallets, setWallets] = React.useState([]);
    const [ready, setReady] = React.useState(false);

    const orderStyle = {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '40px'
    };

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
        },
        {
            title: 'Xendit Balance',
            dataIndex: 'xendit_balance',
        },
    ];


    function visibleWallets(search_string=null){
        if (! search_string) {
            return wallets;
        }

        return wallets.filter((this_wallet) => {
            return this_wallet.email.toLowerCase().includes(search_string.toLowerCase());
        });
    }


    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        }
    };


    async function getUserWallets(){
        setReady(false);

        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        };

        let response = await axios.get(
            process.env.REACT_APP_API_URL + '/api/wallets/',
            yourConfig
        );

        setWallets(response.data);
        setReady(true);
    }


    function exportVisibleWallets() {
        const exportableWallets = visibleWallets(searchValue);
        if (! exportableWallets.length) {
            return;
        }

        const exportOptions = { 
            filename: 'Wallets_' + moment().format("MM-DD-YYYY_HH:mm:ss"),
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true, 
            showTitle: true,
            title: 'BUx Wallets',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true
        };

        let csvExporter = new ExportToCsv(exportOptions);
        csvExporter.generateCsv(exportableWallets);
    }

    return(
        <div style={{ paddingTop: '38px', paddingLeft: '42px', paddingRight: '42px' }} hidden={!ready}>

            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{marginBottom: '20px'}}><span style={orderStyle}>Wallet</span></div>
                <Button
                    style={{
                        backgroundColor: '#1DD28B',
                        border: '#1DD28B',
                        height: '40px',
                        borderRadius:'10px',
                        zIndex: '1'
                    }}
                    onClick={()=>exportVisibleWallets()}>
                    <div style={{fontSize: '14px', fontWeight:'600', color: '#fff'}}>Export CSV</div>
                </Button>
            </div>

            <div style={{float: 'right', marginBottom: '20px'}}>
                <Input
                    placeholder="Search"
                    style={{ width: '200px', height: '35px', zIndex: '1'}}
                    onChange={(e)=>setSearchValue(e.target.value)}
                    prefix={<Icon type="search" />}
                />
            </div>
            <Table
                rowKey={record => record.email}
                rowClassName={(_, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                dataSource={visibleWallets(searchValue)}
                rowSelection={rowSelection}
                columns={columns}
            />
        </div>
    )
    
}

export default Wallet;