import React from 'react';
import { Button, Icon, Input, Modal, Typography } from 'antd';

const RequestPaymentModal = (props) => {
    const [params, setParams] = React.useState({ amount: '', description: '' });
    const { segments, details, isLoading, onSend } = props;
    const userType = localStorage.getItem('userType');
    
    const handleChange = (event) => {
        if (event.target.name === 'amount') {
            setParams({
                ...params,
                target_user_email: details && details.email,
                [event.target.name]: autoFormat(event.target.value, 2)
            })
        } else {
            setParams({
                ...params,
                target_email: details && details.email,
                [event.target.name]: event.target.value
            })
        }
    }

    const autoFormat = (str) => {
        str = str.toString();
        if(str !== null && str.indexOf(".") > -1){
            str = str.slice(0, (str.indexOf(".")) + 2 + 1);
            return parseFloat(str.replace(/[^\d\.]/g, ''));
        }
        return str;
    }

    const resetFields = () => {
        setParams({ amount: '', description: '' });
    }

    React.useEffect(() => {
        resetFields();
    }, [props.visible])

    return (
        <Modal
            maskClosable
            onCancel={() => props.onClose()}
            visible={props.visible}
            footer={false}
            closeIcon={<Icon type="close"  className={`text-${segments}`} />}
            width={530}
        >
            <Typography style={styles.title}>Request Payment</Typography>
            <div style={styles.inputDiv}>
                <label style={styles.label}>Enter amount</label>
                <Input
                    size='large'
                    addonBefore="₱"
                    type='number'
                    name='amount'
                    value={params['amount']}
                    style={{ ...styles.input }}
                    onChange={handleChange}
                />
            </div>
            <div style={styles.inputDiv}>
                <label style={styles.label}>Description</label>
                <Input.TextArea
                    size='large'
                    name='description'
                    placeholder='Sample description'
                    value={params['description']}
                    style={styles.input}
                    onChange={handleChange}
                    maxLength={200}
                    rows={4}
                />
            </div>
            <div>
                <Typography style={{ ...styles.font, fontWeight: '500', padding: '32px 0 10px 0' }}>Request Payment from:</Typography>
                <div>
                    <Typography style={{ ...styles.font, fontWeight: 'bold', color: '#2B2D32' }}>{details && details.full_name}</Typography>
                    <Typography style={{ ...styles.font, color: 'rgba(43, 45, 50, 0.64)' }}>{details && details.email}</Typography>
                </div>
            </div>
            <div style={{ textAlign: 'right', paddingTop: 20 }}>
                <button 
                    className={`btn--${segments} btn-height`}
                    disabled={params['amount'] < 1 || !params['amount']}
                    onClick={() => onSend(params)} loading={isLoading}>Send request</button>
            </div>
        </Modal>
    )
}

const styles = {
    title: {
        color: '#000',
        fontSize: 28,
        fontWeight: 'bold',
        textAlign: 'left',
        paddingBottom: '20px'
    },
    msg: {
        color: '#2B2D33',
        fontSize: 16,
        textAlign: 'left',
        padding: '12px 0'
    },
    btn: {
        height: 40,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 4,
    },
    input: {
        fontSize: 16,
        margin: '4px 0px 0px 0px',
        color: '#2B2D33'
    },
    font: {
        color: 'rgba(43, 45, 50, 0.8)',
        fontSize: 16,
        fontWeight: '500'
    },
    label: {
        fontSize: 14,
        color: 'rgba(43, 45, 50, 0.8)'
    },
    inputDiv: {
        padding: '12px 0 0 0'
    },
    userDetails: {
        border: '2px solid #EEEEEE',
        display: 'flex',
        padding: 12
    },
    errorMsg: {
        color: '#C0492C',
        fontSize: 14,
        padding: '4px 0'
    }
}

export default RequestPaymentModal;