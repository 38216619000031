import React from 'react';
import moment from 'moment';
import ButtonGroup from 'antd/lib/button/button-group';
import reconServices from '../../recon_enterprise/reconServices';
import CardBalance from '../../recon_enterprise/card/cardBalance';
import Cards from './credit_debit_cards';
import { jsonToCSV } from 'react-papaparse';
import { Button, Card, DatePicker, Divider, Icon, Input, Pagination, Spin, Typography } from 'antd';

const CreditDebitRecon = () => {
    const [selected, setSelected] = React.useState('Accounted');
    const [dateRange, setDateRange] = React.useState({startDate: moment().format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY')});
    const [onSearch, setOnSearch] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [tableData, setTableData] = React.useState([]);
    const [incoming, setIncoming] = React.useState(0);
    const [outgoing, setOutgoing] = React.useState(0);
    const [totalLength, setTotalLength] = React.useState(0);
    const cc_balance = localStorage.getItem("cc_balance") ? parseFloat(localStorage.getItem("cc_balance")) : 0;

    const ButtonTab = ({ rootStyle }) => {

        const status = ['Accounted', 'Unaccounted'];

        const handleClick = (event) => {
            setSelected(event.target.value);
        }

        return (
            <div style={rootStyle}>
                <ButtonGroup style={{ width: '100%' }}>
                    {
                        status.map((btn, i) => {
                            return (
                                <Button
                                    key={btn}
                                    value={btn}
                                    onClick={handleClick}
                                    style={{
                                        ...styles.btnGroup,
                                        color: btn === selected ? '#FFF' : '#2B2D33',
                                        backgroundColor: btn === selected ? '#0D3D76' : '#FFF',
                                        borderRadius: i === 0 ? '10px 0 0 10px' : i === 1 ? '0 10px 10px 0' : 0
                                    }}
                                >
                                    {btn}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </div>
        )
    }

    const CardSpinner = () => {
        return (
            <Card style={{ borderRadius: 10 }} bodyStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 380 }}>
                <Spin size='large' />
            </Card>
        )
    }

    const Filter = () => {
        return (
            <div style={styles.filterContainer}>
                <a onClick={() => setOnSearch(true)}><Icon type='calendar' style={styles.icon} /></a>
                {/* <a onClick={() => setOnSearch(true)}><Icon type='search' style={{ ...styles.icon, fontSize: 20 }} /></a> */}
            </div>
        )
    }

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }


    const exportCsv = async () => {
        let arr = new Array;
        let data;
        let res;
        setLoading(true);
        try {
            res = await reconServices.getEnterpriseRecon(returnType(), dateRange['startDate'], dateRange['endDate']);
            if (res && res.data) {
                data = res.data;
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }

        data && data.map((item, i) => {
            if (returnType() === 'accounted') {
                arr.push({
                    "Reference Code": item.ref_code,
                    "Total Amount": item.amount,
                    "Receivable": item.receivable,
                    "Transaction Date": item.transaction_date,
                    "Settlement Date": item.settlement_date,
                })
            } else {
                arr.push({
                    "Reference Code": item.ref_code,
                    "Total Amount": item.amount,
                    "Receivable": item.receivable,
                    "Transaction Date": item.transaction_date,
                })
            }
        });

        const csv = jsonToCSV(arr);
        const blob = new Blob([csv]);
        const a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
        a.download = returnType() === 'accounted' ? 'Recon Credit/Debit_Card_Accounted.csv' : 'Recon_Credit/Debit_Card_Unaccounted.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const rangeSelect = (dates, dateString, id) => {
        setDateRange({ ...dateRange, [id]: moment(dateString).format('MM-DD-YYYY') });
        if (id === 'startDate') {
            getTableData(returnType(), moment(dateString).format('MM-DD-YYYY'), dateRange['endDate']);
        } else {
            getTableData(returnType(), dateRange['startDate'], moment(dateString).format('MM-DD-YYYY'));
        }
    };

    const getTableData = async (type, start, end) => {
        setLoading(true);
        try {
            const res = await reconServices.getEnterpriseRecon(type, start, end);
            setTableData(res.data);
            if (res.data.length > 0) {
                let total_inc = 0;
                let total_out = 0;
                for (var i = 0; i < res.data.length; i++) {
                    if (res.data[i].receivable > 0) {
                        total_inc += res.data[i].receivable;
                    } else {
                        total_out += -1 * res.data[i].receivable;
                    }
                }
                setOutgoing(total_out)
                setIncoming(total_inc)
            } else {
                setOutgoing(0)
                setIncoming(0)
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const returnType = () => {
        switch (selected) {
            case 'Accounted':
                return 'accounted';
            case 'Unaccounted':
                return 'unaccounted';
            default:
                return 'accounted';
        }
    }

    const search = (
        <Card bodyStyle={{ padding: 16 }} style={{ borderRadius: 4, margin: '16px 0' }}>
            <Typography style={styles.searchTitle}>Search Credit/Debit</Typography>
            <Icon type='close' style={styles.searchClose} onClick={() => setOnSearch(false)} />
            <div style={styles.dateRangeContainer}>
                <DatePicker
                    format='MM/DD/YYYY'
                    disabledDate={disabledDate}
                    defaultValue={moment()} 
                    value={dateRange&&moment(dateRange.startDate)} 
                    size="large" picker="month"
                    onChange={(dates, dateString) => rangeSelect(dates, dateString, 'startDate')}
                    placeholder='Start date'
                    style={styles.datePic}
                    suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                    allowClear={false} />
                <span style={styles.span}>-</span>
                <DatePicker
                    format='MM/DD/YYYY'
                    disabledDate={disabledDate}
                    defaultValue={moment()} 
                    value={dateRange&&moment(dateRange.startDate)} 
                    size="large"
                    picker="month"
                    onChange={(dates, dateString) => rangeSelect(dates, dateString, 'endDate')}
                    placeholder='End date'
                    style={styles.datePic}
                    suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                    allowClear={false} />
            </div>
            {/* <Input
                placeholder="Search"
                style={{ width: '100%', height: '40px', borderRadius: '4px' }}
                suffix={<Icon type="search" style={{ fontSize: '18px' }} />}
            onChange={handleSearch}
            /> */}
        </Card>
    )

    const resetProps = () => {
        setDateRange({ startDate: moment().format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY') });
    }

    React.useEffect(() => {
        resetProps();
        getTableData(returnType(), dateRange['startDate'], dateRange['endDate']);
    }, [selected])

    return (
        <div style={styles.root}>
            <CardBalance isLoading={loading} balance={cc_balance} />
            <div style={{textAlign: 'right'}}>
                <Button style={styles.exportBtn} onClick={exportCsv}>
                    Export CSV
            </Button>
            </div>
            <div hidden={onSearch}><ButtonTab /></div>
            <Card hidden={onSearch} size='small' style={styles.card} bodyStyle={{ padding: 0 }}>
                <Filter />
                <Divider style={{ margin: 0 }} />
                <div style={styles.detailsDiv}>
                    <div style={styles.row}>
                        <Typography style={styles.text}>Incoming Amount</Typography>
                        <Typography style={{ ...styles.text, fontWeight: 'bold', color: '#0D3D76' }}>₱ {loading ? <Spin size='small' /> : <span style={{ color: '#1DD28B' }}>
                            {incoming ? incoming.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                        </span>}</Typography>
                    </div>
                    <div style={styles.row}>
                        <Typography style={styles.text} >Outgoing Amount</Typography>
                        <Typography style={{ ...styles.text, fontWeight: 'bold', color: '#0D3D76' }}>₱ {loading ? <Spin size='small' /> : <span style={{ color: '#E24C4C' }}>
                            {outgoing ? outgoing.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                        </span>}</Typography>
                    </div>
                </div>
            </Card>
            <div hidden={!onSearch}>
                {search}
            </div>
            <div hidden={loading}>
                <Cards data={tableData && tableData} />
                <Pagination
                    size='small'
                    total={totalLength}
                    defaultPageSize={20}
                    defaultCurrent={1}
                    current={1}
                    // onChange={(page, pageSize) => changePage(page, pageSize)}
                    style={{ padding: '12px 0 8px 0', textAlign: 'right' }}
                />
            </div>
            <div hidden={!loading}>
                <CardSpinner />
            </div>
        </div >
    )
}

const styles = {
    root: {
        backgroundColor: '#F4F6F9',
        height: '100%',
        width: '100%',
        padding: 16,
    },
    btnGroup: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: '0 32px',
        width: '50%',
    },
    icon: {
        fontSize: 22,
        color: '#000',
        padding: '4px 8px',
    },
    filterContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '16px 0',
    },
    searchContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 4
    },
    searchTitle: {
        color: '#000',
        fontWight: '500',
        fontSize: 16,
        textAlign: 'center',
        padding: '4px 0 16px 0',
    },
    searchClose: {
        color: '#F5922F',
        fontSize: 24,
        position: 'absolute',
        right: 16,
        top: 20,
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: '48%',
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    dateRangeContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px 0 12px 0'
    },
    span: {
        padding: '0 8px'
    },
    detailsDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 12
    },
    row: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    text: {
        fontSize: 14,
        color: '#2B2D33',
        padding: '4px 0'
    },
    card: {
        margin: '16px 0',
        borderRadius: 4,
        padding: 0,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)'
    },
    exportBtn: {
        fontSize: 12,
        fontWeight: '600',
        height: 40,
        borderRadius: 10,
        borderColor: '#1DD28B',
        margin: '12px 0',
        color: '#FFF',
        backgroundColor: '#1DD28B'
    },
}

export default CreditDebitRecon;