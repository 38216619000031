import React, { useEffect, useState } from 'react';
import {Input, message, Modal, Spin, Tooltip} from 'antd';
import axios from "axios";
import { makeStyles } from '@material-ui/core';
import { Handle401 } from '../../handle401/handle401';

const KajabiOfferEditorModal = (props) => {
    const kajabiEditorStyles = makeStyles(theme => ({
        buttonsDiv: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '25px'
        },
        headerText: {
            fontSize: 'x-large',
            paddingRight: '20px'
        },
        subHeaderText: {
            fontSize: 'larger',
            fontWeight: 500,
        },
        confirmButton: {
            height: '40px',
            width: '120px',
            background: '#F5922F',
            border: '1px solid #f5922f',
            borderRadius: '10px',
            color: '#fff',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '24px',
            outline: 0,
            '&:hover': {
                opacity: 0.8,
                cursor: 'pointer'
            },
            '&:active': {
                background: '#DA7F24',
                opacity: 1
            }
        },
        confirmButtonDisabled: {
            height: '40px',
            width: '120px',
            background: '#F5922F',
            border: '1px solid #f5922f',
            borderRadius: '10px',
            color: '#fff',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '24px',
            outline: 0,
            opacity: 0.8,
        },
        cancelButton: {
            height: '40px',
            width: '120px',
            background: '#fff',
            border: '1px solid #f5922f',
            borderRadius: '10px',
            color: '#f5922f',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '24px',
            outline: 0,
            '&:hover': {
                background: '#f5922f',
                cursor: 'pointer',
                color: '#fff',
                opacity: 0.75
            },
            '&:active': {
                background: '#DA7F24',
                opacity: 1
            }
        },
        subsection :{
            marginTop: 15
        },
        subsection2:{
            marginTop: 35
        },
        textInputDiv: {
            marginTop: 15
        },
        bux_link: {
            fontWeight: 600,
            fontSize: 'large'
        },
        urlHelpText: {
            fontSize: 'small',
            fontStyle: 'italic',
            color: 'gray',
        },
        buxLinkBoxDiv: {
            padding: 5,
            borderRadius: 5,
            border: '1px solid #BBBBBB'
        }
    })) ();


    const {
        isEditorModalVisible,
        onCancelOrClose,
        offerInFocus,
        reloadOffers,
        isNewOffer,
        userID
    } = props;

    const blankOffer = {
        name: '',
        aliased_checkout_url: '',
        webhook_url: '',
        payment_redirect_url: '',
        bux_link: '',
    }
    
    const [offerBeingEdited, setOfferBeingEdited] = useState(blankOffer);
    const [isSaving, setIsSaving] = useState(false);


    async function genericAxiosRequest(http_method, target_url, params=undefined) {
        try {
            let response = await axios({
                method: http_method,
                url: process.env.REACT_APP_API_URL + target_url,
                data: params,
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            });
            return response.data;

        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    Handle401()
                } else {
                    throw new Error(error.response.data.message);
                }
            } else {
                throw new Error('No response from backend server!');
            }
        }
    }

    async function saveNewOffer(creation_params) {
        if (!creation_params.name
            || !creation_params.aliased_checkout_url
            || !creation_params.webhook_url) {
            message.error("Name, Offer Link, and Webhook URL are required.");
            return;
        }

        const endpoint_url = `/api/kajabi/${userID}/offers/`;
        const params = {
            name: creation_params.name,
            aliased_checkout_url: creation_params.aliased_checkout_url,
            webhook_url: creation_params.webhook_url,
            payment_redirect_url: creation_params.payment_redirect_url,
        };

        try {
            setIsSaving(true);
            let response = await genericAxiosRequest('post', endpoint_url, params);
            if (response.status === 'failed') {
                throw new Error(response.message);
            }

            message.success("New offer created successfully.");
            reloadOffers();
            onCancelOrClose();

        } catch (err) {
            message.destroy();
            message.error(err.message);
        } finally {
            setIsSaving(false);
        }
    }

    async function saveEdits(edit_params) {
        if (!edit_params.name
            || !edit_params.aliased_checkout_url
            || !edit_params.webhook_url) {
                message.error("Name, Offer Link, and Webhook URL are required.");
            return;
        }

        const endpoint_url = `/api/kajabi/${userID}/offers/`;
        const params = {
            offer_id: edit_params.id,
            name: edit_params.name,
            aliased_checkout_url: edit_params.aliased_checkout_url,
            webhook_url: edit_params.webhook_url,
            payment_redirect_url: edit_params.payment_redirect_url,
        };

        try {
            setIsSaving(true);
            let response = await genericAxiosRequest('put', endpoint_url, params);
            if (response.status === 'failed') {
                throw new Error(response.message);
            }

            message.success(response.data);
            reloadOffers();
            onCancelOrClose();

        } catch (err) {
            message.destroy();
            message.error(err.message);
        } finally {
            setIsSaving(false);
        }
    }



    function copyValueToClipboard(value) {
        var dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.value = value;
        dummy.select();
        document.execCommand("copy")
        dummy.remove();
        message.destroy()
        message.info("Copied to clipboard")
    }


    useEffect(() => {
        if (!isEditorModalVisible) {
            return;
        }

        setOfferBeingEdited(offerInFocus ? {...offerInFocus} : {...blankOffer});
    }, [isEditorModalVisible])


    return(
        <Modal
            centered
            width={'60vw'}
            visible={isEditorModalVisible}
            onCancel={onCancelOrClose}
            footer={null}
        >
            <div className={kajabiEditorStyles.headerText}>
                {isNewOffer ? 'New' : 'Edit'} Offer
            </div>

            <div className={kajabiEditorStyles.subsection}>
                <span className={kajabiEditorStyles.subHeaderText}>Name</span>
                <Input
                    placeholder="Name"
                    maxLength={256}
                    onChange={(e) => setOfferBeingEdited({...offerBeingEdited, name: e.target.value})}
                    value={offerBeingEdited.name}
                    size='large'
                />
            </div>

            <div className={kajabiEditorStyles.subsection2}>
                <div className={kajabiEditorStyles.textInputDiv}>
                    <span className={kajabiEditorStyles.subHeaderText}>Kajabi-generated Offer Link</span>
                    <Input
                        placeholder="Kajabi-generated Offer Link"
                        maxLength={256}
                        onChange={(e) => setOfferBeingEdited({...offerBeingEdited, aliased_checkout_url: e.target.value})}
                        value={offerBeingEdited.aliased_checkout_url}
                        size='large'
                    />
                    <span className={kajabiEditorStyles.urlHelpText}>
                        e.g. https://mysite.mykajabi.com/offers/FSJovB6v
                    </span>
                </div>

                <div className={kajabiEditorStyles.textInputDiv}>
                    <span className={kajabiEditorStyles.subHeaderText}>Activation Webhook URL</span>
                    <Input
                        placeholder="Activation Webhook URL"
                        maxLength={256}
                        onChange={(e) => setOfferBeingEdited({...offerBeingEdited, webhook_url: e.target.value})}
                        value={offerBeingEdited.webhook_url}
                        size='large'
                    />
                    <span className={kajabiEditorStyles.urlHelpText}>
                        e.g. https://checkout.kajabi.com/webhooks/offers/SM8tnKbWFRWLV9kH/2147730166/activate
                    </span>
                </div>

                <div className={kajabiEditorStyles.textInputDiv}>
                    <span className={kajabiEditorStyles.subHeaderText}>Payment Redirect Link</span>
                    <Input
                        placeholder="Payment Redirect Link"
                        maxLength={256}
                        onChange={(e) => setOfferBeingEdited({...offerBeingEdited, payment_redirect_url: e.target.value})}
                        value={offerBeingEdited.payment_redirect_url}
                        size='large'
                    />
                </div>
            </div>

            <div 
                className={kajabiEditorStyles.subsection2}
                hidden
                style={{textAlign:'center'}}
            >
                {/* <span className={kajabiEditorStyles.subHeaderText}>BUx URL</span> */}
                <div className={kajabiEditorStyles.buxLinkBoxDiv}>
                    <Tooltip
                        placement="bottom"
                        title={
                            <div>
                                <a onClick={() => copyValueToClipboard(offerBeingEdited.bux_link)} style={{color: 'white', fontStyle: 'italic'}}>
                                    Copy to clipboard
                                </a>
                            </div>
                        }
                    >
                        <span className={kajabiEditorStyles.bux_link}>
                            {offerBeingEdited.bux_link}
                        </span>
                    </Tooltip>
                </div>
            </div>

            <div className={kajabiEditorStyles.buttonsDiv}>
                <button
                    onClick={onCancelOrClose}
                    className={kajabiEditorStyles.cancelButton}
                >
                    Cancel
                </button>
                <button
                    onClick={() => isNewOffer ? saveNewOffer(offerBeingEdited) : saveEdits(offerBeingEdited)}
                    className={isSaving ? kajabiEditorStyles.confirmButtonDisabled : kajabiEditorStyles.confirmButton}
                    disabled={isSaving}
                >
                    <Spin hidden={!isSaving} size="small" style={{marginRight: 10}}/>
                    {isSaving ? 'Saving ' : 'Save'}
                </button>
            </div>
        </Modal>
    )
}

export default KajabiOfferEditorModal;