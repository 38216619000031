import { useEffect, useState } from "react";
import { axios } from "../../../../../../lib/axios";

export function getAdminCredentials(id) {
  return axios.get(`api/credentials/${id}/?type=live`);
}

export function useAdminCredentials(id) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getAdminCredentials(id);
      setData(response);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const refetch = () => {
    fetchData();
  };

  return { data, isLoading, error, refetch };
}
