import React, { useEffect } from 'react';
import { Input, Select, Icon, Tooltip, Spin } from 'antd';
import { Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import UpayList from '../../../checkout/NewCheckout/upayList';
import QRDetailsStyles from './styles.module.css'
import { PCHC_LIST, INSTAPAY_LIST } from '../../../constants/constants'

const QRCustomerDetails = props => {
    
    const { Option } = Select;
    const { TextArea } = Input; 
    const {
        qrStaticDetails,
        headerDetails, customPlaceholder,
        custName, setCustName,
        custEmail, setCustEmail,
        custMobile, setCustMobile,
        additional, setAdditional,
        additional2, setAdditional2,
        paymentChannels, paymentTypes, isInvalidReasons,
        paymentType, handlePaymentChange,
        paymentChannel, paymentTypeStringified, paymentChannelStringified,
        handleChannelChange, feeLoading,
        leaveNote, setLeaveNote, Icons,
        requireAdd1, requireAdd2, hasClicked,
    } = props;

    const [bankModalVisibility, setBankModalVisibility] = React.useState(false)

    const [instapayPaymentChannel, setInstapayPaymentChannel] = React.useState();
    
    useEffect(() => {
        if (qrStaticDetails) {
            setInstapayPaymentChannel(qrStaticDetails.instapay_payment_channel);
        }
    }, [qrStaticDetails]);

    const channelOption = 
        paymentType.code && 
        paymentChannels[paymentType.code] && 
        paymentChannels[paymentType.code].map((paymentChannel) => {
            return (
                <Option
                    style={{width: '100%'}}
                    key={paymentChannel.key}
                    disabled={!paymentChannel.is_valid}
                    value={JSON.stringify(paymentChannel)}
                >
                    {!paymentChannel.is_valid ? (
                        <Tooltip 
                            placement="topLeft" 
                            overlayClassName="my-checkout-channel-disabled-tooltip"
                            title={
                                <div style={{display: "flex", alignItems: "center", columnGap: "8px"}}>
                                    <Icon 
                                        type="info-circle"
                                        theme="filled" style={{color: "#f5922f"}} 
                                    />
                                    <span>
                                        {isInvalidReasons[paymentChannel.code]}
                                    </span>
                                </div>
                            }
                        >
                            <span style={{ width: '100%', display: "inline-block"}}>
                                {paymentChannel.name}
                            </span>
                        </Tooltip>
                    ) : (
                        paymentChannel.name
                    )}
                </Option>
            )
        });

    const emailError = (email) => {
        const emailFormat = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        if (!email || !email.length) { 
            return false;
        }
        if(emailFormat.test(email)){
            return(false);
        }
        else{
            return(true);
        }
    }

    return(
        <div className="qr-cust-info top-20" align="left">
            {headerDetails(`Customer Information`)}
            <div>
                <Grid container>
                    <Grid item lg={12} sm={12} xs={12}>
                        {customPlaceholder(`Customer Name`)}
                        <Input
                            placeholder="Customer Name"
                            size="large"
                            style={{ height: '40px' }}
                            name="name" value={custName}
                            onChange={(e) => setCustName(e.target.value)} />
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                        <div
                            className={QRDetailsStyles.email}
                        >
                            {customPlaceholder(`Email`)}
                            <Input placeholder={"Email Address"} type="email" size="large" 
                                name={"email"} value={custEmail} onChange={(e) => setCustEmail(e.target.value)} height="40px" 
                                style={{
                                    borderColor: emailError(custEmail) ? '#E24C4C' : ''
                                }} />
                            <div hidden={!emailError(custEmail)} align="left" style={{marginTop: 3, color: '#E24C4C', fontSize: '12px', lineHeight: '12px', marginBottom: '5px'}}>{'Email format is invalid'}</div>
                        </div>
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                        <div style={{ width: '100%' }}>
                            {customPlaceholder(`Mobile Number`)}
                            <Input
                                placeholder="9123456789"
                                type="number"
                                size="large"
                                addonBefore={'+63'}
                                name="mobile" value={custMobile}
                                onChange={(e) => { if (e.target.value.length <= 10) { setCustMobile(e.target.value) } }} />
                        </div>
                    </Grid>
                </Grid>

                <div hidden={qrStaticDetails && (!qrStaticDetails.show_field_1 && !qrStaticDetails.show_field_2)} className="top-20">
                    {headerDetails(`Additional Details`)}
                    <div className="top-12">
                        <div hidden={qrStaticDetails && !qrStaticDetails.show_field_1}>
                            {customPlaceholder(qrStaticDetails && qrStaticDetails.field_1)}
                            <Input
                                placeholder={qrStaticDetails && qrStaticDetails.field_1}
                                size="large"
                                value={additional}
                                style={{ height: '40px', border: requireAdd1 && hasClicked && '1px solid #C0492C' }}
                                name="addtional"
                                onChange={(e) => setAdditional(e.target.value)} />
                            <label hidden={!requireAdd1 || !hasClicked} style={{fontSize: 12, color: '#C0492C' }}>This field is required</label>
                        </div>

                        <div hidden={qrStaticDetails && !qrStaticDetails.show_field_2}>
                            {customPlaceholder(qrStaticDetails && qrStaticDetails.field_2)}
                            <Input
                                placeholder={qrStaticDetails && qrStaticDetails.field_2}
                                size="large"
                                value={additional2}
                                style={{ height: '40px', border: requireAdd2 && hasClicked && '1px solid #C0492C' }}
                                name="addtional"
                                onChange={(e) => setAdditional2(e.target.value)} />
                            <label hidden={!requireAdd2 || !hasClicked} style={{fontSize: 12, color: '#C0492C' }}>This field is required</label>
                        </div>
                    </div>
                </div>

                <div className="top-20">
                    {headerDetails(`Payment Method`)}
                    <Grid container>
                        <Grid item lg={6} sm={12} xs={12}>
                            <div 
                                className={QRDetailsStyles.paymentMethod}
                            >
                                {customPlaceholder(`Payment Method`)}
                                <Select
                                    style={{ width: '100%', height: "40px", color: '#2b2d32'}}
                                    size="large"
                                    value={paymentTypeStringified}
                                    optionFilterProp="children"
                                    suffixIcon={
                                        <Icon type="caret-down" style={{color: "#000000"}} />
                                    }
                                    onChange={paymentTypeStringified => handlePaymentChange(paymentTypeStringified)}
                                    dropdownMatchSelectWidth={false}
                                    loading={feeLoading}
                                    notFoundContent={feeLoading ? <Spin size="small" /> : null}

                                >
                                    {
                                        paymentTypes && paymentTypes.map((paymentType) => {
                                            return(
                                                <Option 
                                                    key={paymentType.code} 
                                                    value={JSON.stringify(paymentType)} 
                                                >
                                                    {paymentType.name}
                                                </Option>
                                            );
                                        })
                                    }
                                </Select>
                            </div>
                        </Grid>

                        <Grid item lg={6} sm={12} xs={12}>
                            <div style={{ width: '100%' }}>
                                {customPlaceholder(`Select Channel`)}
                                <Select
                                    disabled={!paymentType || !paymentType.code}
                                    style={{ width: '100%', height: "40px", color: '#2b2d32'}}
                                    placeholder="Channel"
                                    size="large"
                                    value={paymentChannelStringified}
                                    optionFilterProp="children"
                                    suffixIcon={
                                        <Icon type="caret-down" style={{color: "#000000"}} />
                                    }
                                    onChange={paymentChannelStringified => handleChannelChange(paymentChannelStringified)}
                                    dropdownMatchSelectWidth={false}
                                    loading={feeLoading}
                                    notFoundContent={feeLoading ? <Spin size="small" /> : null}
                                >
                                    {channelOption}
                                </Select>
                            </div>
                        </Grid>
                    </Grid>
                    {paymentChannel.code == instapayPaymentChannel || paymentChannel.code == "paygate" ?
                        (<div className="top-20" align="center">
                            <img 
                                style={{width: isMobile && '90%', }}
                                src={
                                paymentChannel.code == instapayPaymentChannel ?
                                    Icons.InstapayList :
                                        paymentChannel.code == "paygate" ? 
                                            Icons.PaygateList : ""
                            } alt={paymentChannel.code}/>

                            {
                                paymentChannel.code == instapayPaymentChannel &&
                                    <a href onClick={()=>setBankModalVisibility(true)} 
                                        style={{
                                            fontWeight: 'bold', 
                                            marginLeft: '-56px', 
                                            marginTop: '-12px' }}>
                                        <span className="right-8" style={{color: "#2b2d33"}} >
                                            and
                                        </span> 
                                        <span style={{ 
                                            color: '#F5922F', 
                                            textDecoration: 'underline', }}>
                                                more!
                                        </span>
                                    </a>
                            }
             
                        </div>
                        ):
                    ""}
                    <Grid item lg={12} sm={12} xs={12}>
                        {customPlaceholder(`Leave a Note`)}
                        <TextArea
                            placeholder="Type here"
                            rows={1}
                            size="large"
                            name="note" value={leaveNote}
                            onChange={(e) => setLeaveNote(e.target.value)} />
                    </Grid>
                </div>
            </div>

            <UpayList instapayPaymentChannel={instapayPaymentChannel} channel={paymentChannel.code} modalOpen={bankModalVisibility} modalClose={setBankModalVisibility} instaList={INSTAPAY_LIST} pchcList={PCHC_LIST} />

        </div>
    )
}

export default QRCustomerDetails;
