import React from 'react'

//Material UI
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

//Icons
import Invalid from '../../../../static/icons/invalid_icon.svg'
import Valid from '../../../../static/icons/valid_icon.svg'

//Other
import { isMobile } from 'react-device-detect'
import { history } from '../../../../store/history'

const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
const fromSignUp = history.location.pathname.indexOf('/signup') > -1 || history.location.pathname.indexOf('/login') > -1;

const PasswordInput = props => {

    const {
        password,
        setPassword,
        isPasswordValid,
        setIsPasswordValid,
        inputWidth,
        meterWidth,
    } = props

    const [showPassword, setShowPassword] = React.useState(false)
    const [strengthLevel, setStrengthLevel] = React.useState(0)    
    const [hasEnoughCharacter, setHasEnoughCharacter] = React.useState(false)
    const [hasCapital, setHasCapital] = React.useState(false)
    const [hasSmall, setHasSmall] = React.useState(false)
    const [hasNumber, setHasNumber] = React.useState(false)
    const [hasSpecialChar, setHasSpecialChar] = React.useState(false)
    const [hasSpace, setHasSpace] = React.useState(false)

    const [meterVisible, setMeterVisible] = React.useState(false)

    const useStyles = makeStyles((theme) => ({
        root: {
          "& .MuiOutlinedInput-root": {
            '& fieldset': {
                borderColor: 
                strengthLevel <= 0 ? '#e7e7e7' :
                strengthLevel > 0 && strengthLevel <= 2 ? '#C0492C' :
                strengthLevel > 2 && strengthLevel <= 4 ? '#F0CD5D' :
                '#62CE90'
            },
            '&:hover fieldset': {
                borderColor: 
                strengthLevel <= 0 ? '#e7e7e7' :
                strengthLevel > 0 && strengthLevel <= 2 ? '#C0492C' :
                strengthLevel > 2 && strengthLevel <= 4 ? '#F0CD5D' :
                '#62CE90'
            },
            '&.Mui-focused fieldset': {
                borderColor: 
                strengthLevel <= 0 ? '#e7e7e7' :
                strengthLevel > 0 && strengthLevel <= 2 ? '#C0492C' :
                strengthLevel > 2 && strengthLevel <= 4 ? '#F0CD5D' :
                '#62CE90'
            },
          }
        }
    }));

    const classes = useStyles();

    const checkUpperCase = (value) => {
        setHasCapital(/[A-Z]/.test(value))
    }

    const checkLowerCase = (value) => {
        setHasSmall(/[a-z]/.test(value))
    }

    const checkNumber = (value) => {
        setHasNumber(/[0-9]/.test(value))
    }

    const checkSpecialChar = (value) => {
        setHasSpecialChar(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value))
    }
    const checkEnoughCharacter = (value) => {
        if(value.length >= 8){
            setHasEnoughCharacter(true)
        }
        else {
            setHasEnoughCharacter(false)
        }
    }
    const checkSpaces = (value) => {
        setHasSpace(value.includes(" "))
    }
    
    const checkPassword = (val) => {
        let tempLevel = 0

        checkEnoughCharacter(val)
        checkUpperCase(val)
        checkLowerCase(val)
        checkNumber(val)
        checkSpecialChar(val)
        checkSpaces(val)

        setPassword(val)

        if(/[a-z]/.test(val)){
            tempLevel = tempLevel + 1
        }
        if(/[A-Z]/.test(val)){
            tempLevel = tempLevel + 1
        }

        if((/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val)) && (/[0-9]/.test(val))){
            tempLevel = tempLevel + 2
        }
        if(val.length >= 8){
            tempLevel = tempLevel + 2
        }
        setStrengthLevel(tempLevel)

        if(/[A-Z]/.test(val) && (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val)) && (/[0-9]/.test(val)) && val.length >= 8 && !hasSpace){
            setIsPasswordValid(true)
        }

        else{
            setIsPasswordValid(false)
        }
    }


    const validPasswordCategory = [
        {
            'icon': hasEnoughCharacter ? Valid : Invalid,
            'category': <div style={style.passCatergory}>At least 8 characters</div>
        },
        {
            'icon': hasSmall ? Valid : Invalid,
            'category': <div style={style.passCatergory}>At least one lowercase letter</div>
        },
        {
            'icon': hasCapital ? Valid : Invalid,
            'category': <div style={style.passCatergory}>At least one uppercase letter</div>
        },
        {
            'icon': hasNumber ? Valid : Invalid,
            'category': <div style={style.passCatergory}>Contains a number</div>
        },
        {
            'icon': hasSpecialChar ? Valid : Invalid,
            'category': <div style={style.passCatergory}>Contains a symbol</div>
        },

        {
            'icon': !hasSpace ? Valid : Invalid,
            'category': <div style={style.passCatergory}>Does not contain a space</div>
        },
    ]

    return(
        <div>
            <TextField
                className={classes.root}
                size="small"
                value={password}
                name="password"
                id="outlined-adornment-password"
                variant="outlined"
                onChange={e => checkPassword(e.target.value)}
                onFocus={()=>setMeterVisible(true)}
                style={{
                    height: "100%",
                    width: inputWidth,
                    marginBottom: meterVisible || !fromSignUp ? "10px" : "0px"
                }}
                type={showPassword ? "text" : "password"}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                name="passwordToggle"
                                edge="end"
                                aria-label="toggle password visibility"
                                onClick={e => setShowPassword(!showPassword)}
                                onMouseDown={e => setShowPassword(showPassword)}
                            >
                                {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <div style={{display: 'flex', marginBottom: '10px',  width: isMobile ? '100%' : meterWidth}} hidden={!meterVisible && fromSignUp}>
                    <div style={{...style.meterStyle, marginRight: '2px',
                        backgroundColor: strengthLevel <= 0 ? '#D4D5D8' : 
                                         strengthLevel > 0 && strengthLevel <=2 ? '#C0492C' : 
                                         strengthLevel > 2 && strengthLevel <= 4 ? '#F0CD5D' :
                                        '#62CE90'}}>            
                    </div>
                    <div style={{...style.meterStyle, marginRight: '2px',
                        backgroundColor: strengthLevel <= 0 ? '#D4D5D8' : 
                                         strengthLevel > 0 && strengthLevel <=2 ? '#C0492C' : 
                                         strengthLevel > 2 && strengthLevel <= 4 ? '#F0CD5D' :
                                        '#62CE90'}}>            
                    </div>
                    <div style={{...style.meterStyle, marginRight: '2px',
                        backgroundColor: strengthLevel == 3 || strengthLevel == 4  ? '#F0CD5D' :
                                         strengthLevel >= 5 ? '#62CE90' :
                                        '#D4D5D8'}}>            
                    </div>       
                    <div style={{...style.meterStyle, marginRight: '2px',
                        backgroundColor: strengthLevel == 3 || strengthLevel == 4  ? '#F0CD5D' :
                                         strengthLevel >= 5 ? '#62CE90' :
                                        '#D4D5D8'}}>            
                    </div>     
                    <div style={{...style.meterStyle, marginRight: '2px',
                        backgroundColor: strengthLevel >= 5 ? '#62CE90' :
                                        '#D4D5D8'}}>            
                    </div>       
                    <div style={{...style.meterStyle, 
                        backgroundColor: strengthLevel == 6 ? '#62CE90' :
                                        '#D4D5D8'}}>            
                    </div>
                </div>

                <div style={{display: 'flex', marginBottom: "10px"}} hidden={!meterVisible && fromSignUp}>
                    <span style={{fontSize: '12px', color: 'rgba(43, 45, 50, 0.8);', marginRight: '5px'}}>Password strength:</span>
                    <span style={{
                        fontSize: '12px',
                        color: strengthLevel <= 0 ? '#C0492C' :
                            strengthLevel > 0 && strengthLevel <= 2 ? '#C0492C' :
                            strengthLevel > 2 && strengthLevel <= 4 ? '#F0CD5D' :
                            '#62CE90'
                    }}>
                        {
                            strengthLevel <= 0 ? 'Weak' :
                            strengthLevel > 0 && strengthLevel <= 2 ? 'Weak' :
                            strengthLevel > 2 && strengthLevel <= 5 ? 'Medium' :
                            'Strong'
                        }
                    </span>
                </div>
                <div hidden={!meterVisible && fromSignUp}>
                    {
                        validPasswordCategory.map((item, key) => {
                            return (
                                <div style={{display: 'flex'}} key={key}>
                                    <div style={{marginTop: '-3px'}}>
                                        <img src={item.icon} /> 
                                    </div>

                                    <div style={{marginLeft: 12}}>
                                        {item.category}
                                    </div>
                                    
                                </div>
                            )
                        })
                    }
                </div>
        </div>
        
    )
    
}


const style = {
    meterStyle:{
        height: '4px',
        width: isMobile ? '15%' : isHighReso ? '74px' : '62px',
    }
}

export default PasswordInput