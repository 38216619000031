import axios from 'axios';
import { Handle401 } from '../../handle401/handle401';

const authorizedAxiosRequest = () => {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    });
}

const walletServices = {
    getData: async () => {
        try {
            const res = await authorizedAxiosRequest().get(`/api/dashboard/`)
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
}

export default walletServices;