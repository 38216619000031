import React, { useEffect, useState } from "react";
import { Form, Select, Tag, Typography, Tooltip } from "antd";
import { ServiceNotice } from "./ServiceNotice";
import { determineMode } from "../../utils/determineMode";
import { determineSupportedModes } from "../../utils/determineSupportedModes";

const { Paragraph } = Typography;

export function BankSelector({
  form,
  balance,
  mode,
  setMode,
  receiver_amount,
  updateFields,
  banks,
}) {
  const railType = balance.rail_type;
  const [selectedBank, setSelectedBank] = useState(null);

  const handleBankChange = (bankName) => {
    const bank = banks.banks.find((bank) => bank.name === bankName);

    setSelectedBank(bank);
    updateFields({
      receiver_bank: bank.name,
    });
  };

  useEffect(() => {
    if (selectedBank) {
      setMode(determineMode(selectedBank, receiver_amount, railType, banks));
      const code = railType === "ALB" 
        ? selectedBank.code : mode === "Instapay"
        ? selectedBank.code.instapay : selectedBank.code.pesonet
        
      updateFields({code});
    }
  }, [selectedBank, banks, railType, receiver_amount, mode, setMode]);

  return (
    <>
      <Form.Item label="Bank" style={{ margin: 0 }}>
        {form.getFieldDecorator("receiver_bank", {
          rules: [{ required: true, message: "Please select a bank" }],
        })(
          <Select
            onChange={(value) => handleBankChange(value)}
            placeholder="Select Bank"
            size="large"
            showSearch
            filterOption={(input, option) =>
              option.props.value.toLowerCase().includes(input.toLowerCase())
            }
          >
            {banks.banks.map((bank) => (
              <Select.Option key={bank.id} value={bank.name}>
                <div
                  style={{
                    display: "flex",
                    gap: "1em",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Tooltip title={bank.name}>
                    <Paragraph ellipsis style={{ margin: 0 }}>
                      {bank.name}
                    </Paragraph>
                  </Tooltip>
                  <div style={{ display: "flex" }}>
                    {determineSupportedModes(bank, railType, banks).map(
                      (supportedMode) => (
                        <Tag style={{ fontSize: "8px" }}>{supportedMode}</Tag>
                      ),
                    )}
                  </div>
                </div>
              </Select.Option>
            ))}
          </Select>,
        )}
      </Form.Item>
      <ServiceNotice mode={mode} />
    </>
  );
}
