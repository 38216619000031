import React from 'react';
import AppBar from '../components/appBar/AppBar'
import SideBar from './ProfileSideBar';
import {history} from '../store/history'

class Profile extends React.Component{

    componentDidMount(){

        if(localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") === null){
            history.push('/login')
        }
    }
    render(){
        const {children}= this.props;
        return(
        <div>
            <AppBar/>
            <div style={{display:'flex'}}>
            <div style={{flex: 1, height: '90vh'}}><SideBar/></div>
            <div style={{flex:10, marginTop:'15vh'}}> {children}</div>
            </div> 
        </div>)
    }
}

export default Profile