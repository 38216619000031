import axios from 'axios';
import {Handle401} from '../handle401/handle401'

// const authorizedAxiosRequest = () => {
//   return axios.create({
//     baseURL: process.env.REACT_APP_API_URL,
//     headers: {
//       Authorization: "Token " + localStorage.getItem("jwtToken")
//     }
//   });
// }

// const authorizedFormAxiosRequest = () => {
//   return axios.create({
//     baseURL: process.env.REACT_APP_API_URL,
//     headers: {
//       'Content-Type': 'multipart/form-data',
//       Authorization: "Token " + localStorage.getItem("jwtToken")
//     }
//   });
// }

const testModeServices = {
    getCheckoutDetails: async (uid) => {
      const endpoint = `${process.env.REACT_APP_API_URL}/api/sandbox/`;
      try {
        const response = await axios.get(endpoint+uid);
        return response.data;
      } catch (error) {
        if((error.response)&&(error.response.status == 401)){
          Handle401()
        }
      }
    },
    simulatePayment: async (uid) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sandbox/gateway/simulate/?uid=${uid}`);
          return response.data;
        } catch (error) {
          if((error.response)&&(error.response.status == 401)){
            Handle401()
          }
        }
    },
}

export default testModeServices;