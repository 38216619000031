import React,{} from "react";
import { Layout, Button } from "antd";
import {history} from '../../../store/history';
import Bux from '../../../static/icons/logo.svg';

const Header = () => {
 return (
  <Layout.Header style={styles.root}>
    <img src={Bux} style={styles.logo} onClick={() => history.push('/')} />
  </Layout.Header>
 )
}

const styles = {
  root: {
    height: 64,
    width: '100%',
    backgroundColor: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px 20px',
    position: 'fixed',
    zIndex: '3',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    border: '1px solid #E6EAF0',
  },
  signUp: {
    backgroundColor: '#F5922F',
    color: '#FFF',
    fontSize: 14,
    fontWeight: '600',
    border: 0,
    padding: '0 21px'
  },
  logIn: {
    backgroundColor: '#FF',
    color: '#F5922F',
    fontSize: 14,
    fontWeight: '600',
    border: 0,
    padding: '0 21px'
  },
  logo: {
    height: 32,
  }
}

export default Header;