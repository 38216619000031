import React,{useEffect} from 'react'
import cardsStyles from '../../../static/css/Cards';
import{UbpCardLogo} from '../../../static/icons/index';
import {Modal, Icon} from 'antd';


const Ubpcard = (props) => {

    const cs = cardsStyles();

    return(
        <div className={cs.ubpMobileCard}>
            <div style={{display: 'flex'}}>
                <div className={cs.webCardText}>
                    <div className={cs.ubpHeader}>
                    UBExpress KYC
                    </div>
                    <div className={cs.ubpText}>
                    Do you have a Unionbank Account? Enter your bank account details here so your business profile will be verified immediately!
                    </div>

                    <div align="right">
                        <div className={cs.wcLink}>
                            <a href="/complete_profile/">Proceed <Icon type="right"/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ubpcard;