import { makeStyles } from '@material-ui/core'
import {isMobile} from 'react-device-detect'


const adminStyle = makeStyles(theme => ({

    kybDiv: {
        paddingTop: '38px', 
        paddingLeft: isMobile ? '150px' : '42px', 
        paddingRight: '42px' 
    },

    btnDiv: {
        marginBottom: '8px', 
        display: 'flex', 
        justifyContent: 'space-between'
    },
    
    buttonStyle: {
        padding: '12px 18px 13px 18px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight:'16px',
        height:'40px',
    },
    
    orderStyle: {
          fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '40px'
    },

    exportBtn: {
        width: '120px',
        outline: 0,
        backgroundColor: '#1DD28B',
        marginRight: '20px',
        border: '#1DD28B',
        height: '40px',
        borderRadius:'10px',
        '&:hover': {
            cursor: 'pointer',
            color: '#1DD28B',
            opacity: 0.8
        },
    },

    createBtn: {
        width: '200px',
        outline: 0,
        backgroundColor: '#F5922F',
        border: '#F5922F',
        height: '40px',
        borderRadius:'10px',
        '&:hover': {
            cursor: 'pointer',
            color: '#F5922F',
            opacity: 0.8
        },
    },

    btnText: {
        fontSize: '14px', 
        fontWeight:'600', 
        color: '#fff'
    },

    approvedStyle: {
        fontSize: 12,
        background: 'rgba(29, 210, 139, 0.1)',
        border: '1px solid #1dd28b',
        borderRadius: '4px',
        paddingLeft: 16 ,
        lineHeight: '32px',
        height: 32,
        width: 100,
        borderLeft: '4px solid #1dd28b',
        color: '#2B2D33'
    },

    rejectedStyle: {
        fontSize: 12,
        background: 'rgba(226, 76, 76, 0.1)',
        border: '1px solid #E24C4C',
        borderRadius: '4px',
        paddingLeft: 16 ,
        lineHeight: '32px',
        height: 32,
        width: 100,
        borderLeft: '4px solid #E24C4C',
        color: '#2B2D33'
    },

    createdStyle: {
        fontSize: 12,
        background: 'rgba(101, 193, 242, 0.1)',
        border: '1px solid #65C1F2',
        borderRadius: '4px',
        paddingLeft: 16 ,
        lineHeight: '32px',
        height: 32,
        width: 100,
        borderLeft: '4px solid #65C1F2',
        color: '#2B2D33'
    },

    submittedStyle: {
        fontSize: 12,
        background: 'rgba(245, 146, 47, 0.1)',
        border: '1px solid #F5922F',
        borderRadius: '4px',
        paddingLeft: 16 ,
        lineHeight: '32px',
        height: 32,
        width: 100,
        borderLeft: '4px solid #F5922F',
        color: '#2B2D33'
    },
    

}))

export default adminStyle