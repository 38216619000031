import React from 'react'
import { Button, Input, Divider, Select, Radio } from 'antd'

const { Option } = Select;

class BusinessAddress extends React.Component{

    labelStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#2B2D33',
    }
    saveBtn = {
        width: '150px',
        height: '40px',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '24px',
        float: 'right',
        marginBottom: '20px'
    }
    render(){
        return (
            <div style={{width: '70%', padding: '48px 52px 0px 52px'}}>
                <div style={this.labelStyle}>Street</div>
                <Input style={{width: '100%', height: '40px', marginBottom: '20px'}}/>
                <div style={this.labelStyle}>Apt, suite, etc. (optional)</div>
                <Input style={{width: '100%', height: '40px', marginBottom: '20px'}}/>
                <div  style={{display: 'flex', width:'100%', marginBottom: '20px'}}>
                    <div style={{width: '49%', marginRight: '2%'}}>
                    <div style={this.labelStyle}>Country</div>
                    <Select
                        showSearch
                        style={{ 
                            width: '100%',
                        }}
                        size="large"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        >
                        <Option value="Philippines">Philippines</Option>
                    </Select>
                    </div>
                    <div style={{width: '49%'}}>
                    <div style={this.labelStyle}>Province</div>
                    <Select
                        showSearch
                        style={{ 
                            width: '100%',
                        }}
                        size="large"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        >
                        <Option value="Metro Manila">Metro Manila</Option>
                    </Select>
                    </div>
                </div>
                <div  style={{display: 'flex', width:'100%', marginBottom: '20px'}}>
                    <div style={{width: '49%', marginRight: '2%'}}>
                    <div style={this.labelStyle}>City</div>
                    <Select
                        showSearch
                        style={{ 
                            width: '100%',
                        }}
                        size="large"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        >
                        <Option value="Caloocan">Caloocan</Option>
                        <Option value="Las Pinas">Las Pinas</Option>
                        <Option value="Makati">Makati</Option>
                        <Option value="Malabon">Malabon</Option>
                        <Option value="Mandaluyong">Mandaluyong	</Option>
                        <Option value="Manila">Manila</Option>
                        <Option value="Marikina">Marikina</Option>
                        <Option value="Muntinlupa">Muntinlupa</Option>
                        <Option value="Navotas">Navotas</Option>
                        <Option value="Parañaque">Parañaque</Option>
                        <Option value="Pasay">Pasay</Option>
                        <Option value="Pasig">Pasig</Option>
                        <Option value="Pateros">Pateros</Option>
                        <Option value="Quezon City">Quezon City</Option>
                        <Option value="San Juan">San Juan</Option>
                        <Option value="Taguig">Taguig</Option>
                        <Option value="Valenzuela">Valenzuela</Option>
                    </Select>
                    </div>
                    <div style={{width: '49%'}}>
                    <div style={this.labelStyle}>Postal / ZIP code</div>
                    <Input style={{width: '100%', height: '40px'}}/>
                    </div>
                </div>
                <Divider></Divider>
                <Button type="primary" style={this.saveBtn}>Save changes</Button>
                
            </div>   
        )
    }
}

export default BusinessAddress