import React,{ useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Typography, Input, Button } from 'antd';
import { Checkbox, withStyles } from '@material-ui/core';
import { sign_up } from "../../signUpActions";
import {history} from '../../../../store/history'
import {showAlert} from '../../../alert/alertAction'
import BuxModal from '../../../BuxModal/modal';
import BuxAlert from '../../../alert/alert';
import {isMobile} from 'react-device-detect';
import ArrowDown from '../../../../static/icons/arrow-down.svg';
import { NATURE_BUSINESS } from '../../../constants/constants';

const CorpSignUp = () => {
  const dispatch = useDispatch();
  const alert = useSelector(state => state.alert.showAlert);
  const isLoading = useSelector(state => state.signup.loading)
  const [cleared, setCleared] = useState(false);
  const [params, setParams] = useState({merchantType:'CO', businessName: '', natureOfBusiness: '', email: '', mobileNumber: '', password: ''});
  const [signUpAgree, setSignUpAgree] = useState(false);
  const [showPass, setShowPass] = useState(false)
   
  const validateFields = () => {
      
      if(Object.keys(params['businessName']).length > 64){
          dispatch(showAlert({ type: "error", message: "Business name exceeded to max limit of 64 characters" }));
      }
      else if(Object.keys(params['email']).length > 100){
        dispatch(showAlert({ type: "error", message: "Email exceeded to max limit of 100 characters" }));
      }
      else if(Object.keys(params['password']).length > 128){
          dispatch(showAlert({ type: "error", message: "Password exceeded to max limit of 128 characters" }));
      }
      else if(Object.keys(params['password']).length < 7){
        dispatch(showAlert({ type: "error", message: "Password must contain at least 7 characters" }));
      }
      else if(params['password'].search(/[0-9]/) === -1){
        dispatch(showAlert({ type: "error", message: "Password must contain at least 1 number" }));
      }
      else if(params['password'].search(/[A-Z]/) === -1){
          dispatch(showAlert({ type: "error", message: "Password must contain at least 1 capital letter" }));
      }
      else if(params['password'].search(/[a-z]/) === -1){
          dispatch(showAlert({ type: "error", message: "Password must contain at least 1 small letter" }));
      }
      else {
        dispatch(sign_up(params));
      }
    }

  const hasBlanks = () => {
    const isEmpty = Object.values(params).some(x => (x === null || x === ''));
    if(isEmpty === true || Object.keys(params).length < 6 || !signUpAgree){
      return true;
    }
    return false;
  }

  const handleChange = (event) => {
    if(event.target.name === 'mobileNumber'){
      setParams({...params, [event.target.name]:autoFormat(event.target.value)})
    } else {
      setParams({...params, [event.target.name]:event.target.value})
    }
  }

  const autoFormat = (value) => {
    const formattedValue = value.replace(/\D/g, '')
    if (value !== null) {
      return formattedValue;
    } 
      return value;
  }

  function loadFbPlugin() {
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'Messenger'));
    loadMessengerExt();
  }

  function loadMessengerExt() {

    window.extAsyncInit = function () {
         window.MessengerExtensions.getUserID(function success(uids) {
            window.fbuid = uids.psid;
        }, function error(err, errorMessage) {
            window.fbuid = '';

        });
    
    };

  }

  useEffect(() => {
    window.fbuid = ''
    if(isMobile){
      if(localStorage.getItem("jwtToken") !== "" && localStorage.getItem("jwtToken") != null){
        history.push('/mobile/dashboard')
      }
      else  {
            if(!isMobile){
                history.push('/messenger/error')
            }
            else{
                loadFbPlugin();
                localStorage.setItem("jwtToken", "");
                localStorage.setItem("balance", "0 PHP");
            }
          }
        }
      else{
        if(localStorage.getItem("jwtToken") != "" && localStorage.getItem("jwtToken") != null){
            window.location.href = '/dashboard'
          }
        else{
            loadFbPlugin();
            localStorage.setItem("jwtToken", "");
            localStorage.setItem("balance", "0 PHP");
        }
    }
  }, [])

  return (
    <div>
      <BuxModal />
      <Typography style={styles.topMsg}>
        If you are fully registered business entity with business documents.
      </Typography>
      <div hidden={!alert} style={{padding: alert? '8px 0 2px 0' : null}}>
        <BuxAlert />
      </div>
      <div style={styles.inputContainer}>
        <div style={{...styles.inputDiv, width: '100%'}}>
          <label style={styles.labelFont}>Business Name</label>
          <Input style={styles.input}
            name='businessName' 
            placeholder="Business Name" 
            onChange={handleChange}
            disabled={isLoading}
            value={params['businessName']}
          />
        </div>
        <div style={{...styles.inputDiv, width: '100%'}}>
          <label style={styles.labelFont}>Nature of Business</label>
          <select
            value={params['natureOfBusiness']}
            name='natureOfBusiness'
            onChange={handleChange}
            style={{...styles.selectStyle, color: params['natureOfBusiness'] != '' ? '#000' : '#D1D5DD'}}>
                <option disabled={params['natureOfBusiness']} value="" style={{color: '#D1D5DD'}}>Select Nature of Business</option>
                {
                  NATURE_BUSINESS.map((item,i)=>{
                    return(
                    <option key={i} value={i} style={{color: '#000'}}>{item}</option>
                    )
                  })
                }
          </select>
        </div>
        <div style={styles.inputDiv}>
          <label style={styles.labelFont}>Email</label>
          <Input style={styles.input} 
            name='email'
            placeholder="Email" 
            type='email'
            value={params['email']}
            onChange={handleChange}
            disabled={isLoading}
          />
        </div>
        <div style={{...styles.inputDiv, position: 'relative'}}>
          <label style={styles.labelFont}>Mobile Number</label>
          <Input style={{...styles.input, paddingLeft: '32%', paddingTop: '6%'}} 
            name='mobileNumber'
            value={params['mobileNumber']}
            maxLength={10}
            onChange={handleChange}
            disabled={isLoading}
          />
          <Typography style={styles.mobilePrefix}>+63 | </Typography>
        </div>
        <div style={{...styles.inputDiv, width: '100%', position: 'relative'}}>
          <label style={styles.labelFont}>Password</label>
          <Input style={styles.input} 
            type={showPass?'text':'password'}
            name='password'
            value={params['password']}
            placeholder="Password" 
            onChange={handleChange}
            disabled={isLoading}
          />
          <a name='passwordToggle' style={styles.showBtn} onClick={()=>setShowPass(!showPass)}>{showPass?'Hide':'Show'}</a>
        </div>
      </div>
      <div style={styles.checkText}>
        <CustomCheckbox 
          name="agreeCheckBox"
          style={styles.checkBox}
          value={signUpAgree}
          onChange={() => setSignUpAgree(!signUpAgree)}
        />
        <div>
          {' By signing up, I agree with the '}
          <a style={styles.link} onClick={() => history.push('/terms')}>Terms and Conditions</a>
          {' and '}
          <a style={styles.link} onClick={() => history.push('/privacy_policy')}>Privacy Policy</a>
        </div>
      </div>
      <Button
        name="signUpBtn"
        disabled={ isLoading || hasBlanks() }
        style={{...styles.signUpBtn, opacity: isLoading || hasBlanks()? '.5' : '1' }}
        onClick={validateFields}
        loading={isLoading}
      >
        Sign up
      </Button>
      <div style={styles.footer} align='center'>
        {'Already have an account? '}
        <a style={styles.link} onClick={() => history.push('/login')}>Login</a>
      </div>
    </div>
  )
}

const CustomCheckbox = withStyles({
  root: {
    color: '#F5922F',
    '&$checked': {
      color: '#F5922F',
    },
  },
  checked: {},
})((props) => <Checkbox size='medium' color="default" {...props} />);

const styles = {
  root: {

  },
  topMsg: {
    padding: '16px 0 12px 0'
  },
  input: {
    padding: '11px 16px 11px 16px',
    fontSize: 16,
    height: 48,
    margin: '4px 0px 0px 0px',
    color: '#2B2D33'
  },
  labelFont: {
    fontSize: 12,
    color: '#212B36'
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  inputDiv: {
    width :'48%',
    padding: '6px 0'
  },
  checkText: {
    paddingTop: 14,
    color: '#2B2D33',
    fontSize: 16,
    display: 'flex',
    alignItems: 'start',
  },
  link: {
    color: '#F5922F',
    fontWeight: '600',
  },
  checkBox: {
    padding: '0 8px 0 0',
  },
  footer: {
    padding: '28px 0',
    color: '#2B2D33',
    fontSize: 16,
  },
  signUpBtn: {
    margin: '20px 0px 0px 0px',
    height: 48,
    width: '100%',
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    borderRadius: 10,
    fontSize: 14,
    fontWeight: '600',
  },
  divider: {
    fontSize: 12,
    color: '#333333',
  },
  fbBtn: {
    backgroundColor: '#3B5998',
    border: '#3B5998',
    fontStyle: 'normal',
    borderRadius: '4px',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    height: '50px',
    width: '49%',
    marginRight: '2%'
  },
  googleBtn: {
    backgroundColor: '#F1F1F1',
    borderRadius: '4px',
    border: '1px solid #f1f1f1',
    color: '#F1F1F1',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#2B2D33',
    height: '50px',
    width: '100%',
    position: 'relative',
  },
  iconStyle: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    left: '6%',
    top: '30%'
    // float: 'left',
    // marginLeft: '20px'
  },
  showBtn: {
    position: 'absolute',
    top: `64%`,
    right: '0%',
    transform: 'translate(-50%, -50%)',
    fontSize: 16,
    color: '#F5922F',
    fontWeight: 'bold',
    zIndex: '99',
  },
  mobilePrefix: {
    position: 'absolute',
    top: `64%`,
    left: '17%',
    transform: 'translate(-50%, -50%)',
    fontSize: 16,
    color: '#2B2D33',
    fontWeight: 'normal',
    zIndex: '99',
  },
  selectStyle: {
    width: '100%',
    height: '48px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    appearance: 'none',
    background: `url(${ArrowDown})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '95% 50%',
    fontSize: '16px',
    borderColor: '#D1D5DD',
    borderRadius: 4,
    padding: '10px 40px 10px 10px',
    margin: '4px 0 0 0'
  }
}

export default CorpSignUp;