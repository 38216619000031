export const makeStyles = (media) => ({
  breadCrumbStyle: {
    width: media.mobile ? "100%" : "600px",
    position: "fixed",
    top: "0",
    paddingTop: "35px",
    paddingBottom: "4px",
    marginTop: media.mobile ? "58px" : "63px",
    backgroundColor: "rgb(244, 246, 249)",
    zIndex: "2",
    paddingLeft: media.mobile ? "20px" : "0px",
    display: "flex",
    flexFlow: media.mobile ? "column" : "row",
    alignItems: media.mobile ? "flex-start" : "center",
    justifyContent: "space-between",
  },
  downloadBtnPosition: {
    width: media.mobile ? "100%" : "600px",
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "none",
    position: "relative",
    marginTop: "8rem",
    marginBottom: "-5rem",
    paddingRight: "1px",
    top: "0",
    zIndex: "1",
  },
  headerStyle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: media.mobile ? "16px" : "18px",
    lineHeight: "40px",
    color: "#2b2d33",
  },
  textStyle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: media.mobile ? "14px" : "16px",
    lineHeight: "24px",
    color: "#2B2D32",
  },
  textStyle1: {
    marginTop: "24px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: media.mobile ? "14px" : "16px",
    lineHeight: "24px",
    color: "#000",
  },
  loanStyle: {
    ontStyle: "normal",
    fontWeight: "normal",
    fontSize: media.mobile ? "10px" : "12px",
    lineHeight: "18px",
    color: "#000",
    opacity: "0.8",
  },
  orderStyle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: media.mobile ? "14px" : "16px",
    lineHeight: "24px",
    color: "#000",
    "&:hover": {
      color: "blue",
    },
  },

  labelStyle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: media.mobile ? "14px" : "16px",
    lineHeight: "24px",
    color: "#000",
  },
  referenceNumberStyle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: media.mobile ? "14px" : "16px",
    lineHeight: "24px",
    color: "#000",
    textAlign: "right",
  },
  detailStyle: {
    fontStyle: "normal",
    fontSize: media.mobile ? "14px" : "16px",
    lineHeight: "24px",
    color: "#000",
  },
  miniTextStyle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#000",
    marginTop: "10px",
  },
  loanDiv: {
    width: "100%",
    backgroundColor: "#fafafa",
    borderRadius: "10px",
    marginBottom: "40px",
    display: "flex",
    paddingLeft: "20px",
  },
  loanDivMobile: {
    width: "100%",
    backgroundColor: "#fafafa",
    borderRadius: "10px",
    marginBottom: "20px",
    marginTop: "100px",
    padding: "10px 10px 5px 10px",
  },
  applyButton: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#fff",
    backgroundColor: "#F5922F",
    borderRadius: "4px",
    height: "32px",
    width: "100%",
    border: 0,
  },
  stepHeader: {
    color: "#0D3D76",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "24px",
    marginLeft: media.mobile ? "10px" : "50px",
    marginBottom: "16px",
  },
  noteHeader: {
    color: "#E24C4C",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "24px",
    marginLeft: media.mobile ? "10px" : "50px",
    marginBottom: "16px",
  },

  disclaimer: {
    padding: media.mobile ? "0px 10px 20px 10px" : "0px 30px 20px 30px",
    color: "#000000",
  },
  dpDisclaimer: {
    borderTop: "1px solid #E6EAF0",
    borderBottom: "1px solid #E6EAF0",
    marginLeft: media.mobile ? "10px" : "30px",
    marginRight: media.mobile ? "10px" : "30px",
    padding: media.mobile ? "10px 0px 20px 0px" : "20px 0px 20px 0px",
  },
  blackLink: {
    color: "#000000",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  rowStyle: {
    display: "flex",
    justifyContent: "space-between",
    padding: media.mobile ? "0px 10px 20px 10px" : "0px 50px 20px 50px",
  },
  instructionStyle: {
    display: "flex",
    padding: media.mobile ? "0px 10px 20px 10px" : "0px 30px 20px 30px",
  },
  pleaseRememberDiv: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "rgba(244, 193, 10, 0.16)",
    border: "1px solid #F4B24A",
    borderRadius: "2px",
    padding: "16px 20px 16px 20px",
  },
  textStyle2: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2B2D33",
  },
  iconStyle: {
    color: "#F5922F",
    fontSize: "25px",
    float: "left",
    margin: "0px -20px 0px 10px",
  },
  redirectUrl: {
    width: "240px",
    height: "48px",
    backgroundColor: "#FFF",
    borderRadius: "50px",
    color: "#F5922F",
    fontSize: "16px",
    fontWeight: "bold",
    border: "0px #fff",
  },
  justifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  downloadReceiptDiv: {
    display: "flex",
    justifyContent: "center",
  },
  locatorBtn: {
    width: "198px",
    height: "40px",
    backgroundColor: "#F5922F",
    borderRadius: "4px",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "500",
    paddingTop: "2px",
    border: "1px solid #F5922F",
  },
  downloadReceiptBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    color: "#F5922F",
    fontSize: "16px",
    fontWeight: "500",
    paddingTop: "2px",
    border: "none",
  },
  accountDetailsRow: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
    padding: "0px 6px",
    margin: "6px 0",
  },
  accountDetailsLabel: {
    color: "rgba(43, 45, 50, 0.64)",
    fontWeight: "400",
    fontSize: "16",
    whiteSpace: "nowrap",
    width: "42%",
  },
  accountDetailsBtn: {
    height: "24px",
    fontSize: "14px",
    lineHeight: "1px",
    backgroundColor: "#E3632A",
    color: "#FFF",
    border: "none",
    borderRadius: "4px",
    padding: "4px 8px",
    cursor: "pointer",
  },
  accountDetailsInput: {
    fontWeight: "500",
    fontSize: "16px",
    border: "none",
    textAlign: "left",
    background: "transparent",
    color: "#2B2D32",
    whiteSpace: "break-spaces",
    // textOverflow: 'ellipsis',
    // overflow: 'hidden',
    maxWidth: media.mobile ? "140px" : "180px",
  },
  accountDetailsReminder: {
    display: "flex",
    backgroundColor: "rgba(119, 178, 242, 0.16)",
    border: "1px solid #77B2F2",
    padding: "4px 16px",
    alignItems: "center",
  },
  accountDetailsExc: {
    color: "#77B2F2",
    border: "1px solid #77B2F2",
    borderRadius: 25,
    backgroundColor: "#FFF",
    fontSize: 14,
    height: "fit-content",
    margin: "0px 12px 0 0",
  },
  accountDetailsReFont: {
    fontSize: 12,
    color: "#2B2D32",
    opacity: "0.8",
  },
});
