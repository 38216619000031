import React from 'react';
import surveyServices from '../surveyServices';
import QuestionCard from './questionCard';
import logo from '../../../static/icons/logo.svg';
import BG from '../../../static/icons/enterprise_survey_bg.svg';
import { Button, Layout } from 'antd';
import { isMobile } from 'react-device-detect';
import { history } from '../../../store/history';
import { Logout} from '../../../static/icons/options/index';

const Header = () =>  {
  return (
    <Layout.Header style={styles.header}>
      <div style={{display: 'flex', justifyContent: 'center', width: '100%', paddingLeft: '10%'}}>
        <img src={logo} alt='No Image' />
      </div>
      <Button type='link' onClick={logout} style={styles.logout}>
        {/* <img src={Logout} style={{paddingRight: 8, paddingBottom: 2}} />  */}
        <b>Logout</b></Button>
    </Layout.Header>
  )
}

const logout = async () => {

    try {
      const res = await surveyServices.logout();
      console.log(res);
      
      localStorage.setItem("userId", "");
      localStorage.setItem("jwtToken", "");
      localStorage.setItem("firstName", "");
      localStorage.setItem("lastName", "");
      localStorage.setItem("middleName", "");
      localStorage.setItem("suffix", "");
      localStorage.setItem("username", "");
      localStorage.setItem("email", "");
      localStorage.setItem("contact", "");
      localStorage.setItem("userType", "");
      localStorage.setItem("isCompleted", "");
      localStorage.setItem("isVerified", "");
      localStorage.setItem("Step", "")
      localStorage.setItem("Level", "")
      localStorage.setItem("lastLogin", "")
      localStorage.setItem("shipping_enabled", "")
      localStorage.setItem("isWaive", "")
      localStorage.setItem("changePw", "")
      localStorage.setItem("expiryDate", "");
      
      history.push(isMobile? "/mobile/login" : '/login');
    } 
    catch (error) {
      localStorage.setItem("userId", "");
      localStorage.setItem("jwtToken", "");
      localStorage.setItem("firstName", "");
      localStorage.setItem("lastName", "");
      localStorage.setItem("middleName", "");
      localStorage.setItem("suffix", "");
      localStorage.setItem("username", "");
      localStorage.setItem("email", "");
      localStorage.setItem("contact", "");
      localStorage.setItem("userType", "");
      localStorage.setItem("isCompleted", "");
      localStorage.setItem("isVerified", "");
      localStorage.setItem("Step", "")
      localStorage.setItem("Level", "")
      localStorage.setItem("lastLogin", "")
      localStorage.setItem("shipping_enabled", "")
      localStorage.setItem("isWaive", "")
      localStorage.setItem("changePw", "")
      localStorage.setItem("expiryDate", "");
      
      history.push(isMobile? "/mobile/login" : '/login');
    }
}

const EnterpriseSurvey = () => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getQuestions = async () => {
    setLoading(true);
    try {
      const res = await surveyServices.getEnterpriseQuestions();
      setData(res);
      setLoading(false);
    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  }

  const redirectPage = () => {
    let current_path = window.location.pathname
    if (localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
        history.push('/login?next='+current_path)
    }
    else  {
      if (localStorage.getItem("userType") != "CO" || localStorage.getItem("is_child") === true || localStorage.getItem("survey_enterprise") === true ){
        history.push('/dashboard')
      }
      else {
        getQuestions();
      }
    }  
  }

  React.useEffect(() => {
    redirectPage();
  },[])

  return (
    <div
      style={styles.root}
    >
      <Header />
      <div style={styles.body}>
        <QuestionCard data={data} isLoading={loading} />
      </div>
    </div>
  )
}

const styles = {
  root: {
    height: '100vh',
    width: '100%',
    backgroundImage: isMobile ? null : `url(${BG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'bottom',
    backgroundColor: '#F4F6F9',
    overflowY: 'scroll',
    position: 'fixed'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    height: isMobile ? 64 : '10%',
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)',
    width: '100%',
    position: 'fixed',
    zIndex: '1',
    textAlign: 'center',
    padding: '0 20px'
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: isMobile? null : '90%',
    zIndex: '0',
    paddingTop: isMobile? 28 : 66,
  },
  logout: {
    fontSize: 12,
    fontWeigth: '700',
    color: '#F5922F',
    padding: 0
  }
}

export default EnterpriseSurvey;