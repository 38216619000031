import React, {useEffect, useState, useRef} from 'react';
import axios from "axios";
import {Button, Dropdown, Icon, Input, Menu, message, Pagination, Table} from 'antd';
import { Handle401 } from '../../handle401/handle401';
import DeleteHolidayModal from './deleteHolidayModal';
import HolidayFormModal from './holidayFormModal';
import {history} from '../../../store/history';
import moment from 'moment';


const Holidays = () => {

    // Table
    const [tableData, setTableData] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [totalHolidaysCount, setTotalHolidaysCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');

    // Edit Form Modal
    const [isEditFormVisible, setIsEditFormVisible] = useState(false);
    const [editFormCallback, setEditFormCallback] = useState(null);
    const [editFormMode, setEditFormMode] = useState('');
    const [editFormValues, setEditFormValues] = useState({name: '', effective_date: null});

    // Deletion Modal
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [deletionModalDescription, setDeletionModalDescription] = useState('');
    const [deletionProcedure, setDeletionProcedure] = useState(null);

    // Export Button
    const [exportButtonStyle, setExportButtonStyle] = React.useState({color: '#1DD28B', backgroundColor: 'transparent'})


    const pageSize = 20;
    const isComponentMounted = useRef(false);


    const holiday_table_columns = [
        {
            title: "Holiday",
            key: 'holiday',
            width: '50%',
            render: (text, holiday) => {
                return (
                    <div>
                        <div><b>{holiday.name}</b></div>
                        <div style={{color: "#505050"}}>{holiday.effective_date}</div>
                    </div>
                )
            }
        },
        {
            title: 'Added by',
            key: 'added_by',
            dataIndex: 'added_by',
            width: '30%'
        },
        {
            title: 'Action',
            key: 'action',
            width: '20%',
            render: (text, holiday) => (
              <div>
                <Dropdown
                    overlay={() => dropdown_actions_menu(holiday)} 
                    placement="bottomCenter"
                    trigger={['click']}
                >
                    <Icon type="ellipsis" />
                </Dropdown>
              </div>
            )
        }
    ]

    function dropdown_actions_menu(holiday) {
        return (
            <Menu style={{ width: '120px' }}>
                <Menu.Item
                    key="edit_action"
                    onClick={() => {
                        setEditFormCallback(() => {
                            return (edit_params) => {
                                editHoliday(holiday.id, edit_params);
                            }
                        })

                        setEditFormValues({
                            name: holiday.name,
                            effective_date: moment(holiday.effective_date, 'DD-MMM-YYYY')
                        });
                        setEditFormMode('Edit');
                        setIsEditFormVisible(true);
                    }}
                >
                    Edit
                </Menu.Item>


                <Menu.Item
                    style={{marginTop: '15px', color: '#E24C4C'}}
                    key="delete_action"
                    onClick={() => {
                        setDeletionProcedure(() => {
                            return async() => {
                                await deleteHoliday(holiday.id);
                                fetchHolidays();
                                setIsDeleteModalVisible(false);
                            };
                        })
                        
                        setDeletionModalDescription(holiday.name + ' (' + holiday.effective_date + ")")
                        setIsDeleteModalVisible(true);
                    }}
                >
                    Delete
                </Menu.Item>
            </Menu>
        );
    }

    const exportButtonStyleChangers = {
        onHover: () => {
            setExportButtonStyle({
                color: '#FFF',
                backgroundColor: '#1DD28B'
            });
        },
        onMouseLeave: () => {
            setExportButtonStyle({
                color: '#1DD28B',
                backgroundColor: 'transparent'
            });
        }
    }

    async function genericAxiosRequest(http_method, target_url, params=undefined) {
        try {
            let response = await axios({
                method: http_method,
                url: process.env.REACT_APP_API_URL + target_url,
                data: params,
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            });
            return response.data;

        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    Handle401()
                } else {
                    throw new Error(error.response.data.message);
                }
            } else {
                throw new Error('No response from backend server!');
            }
        }
    }


    async function fetchHolidays(selectedPage=1, searchVal='') {
        setIsTableLoading(true);

        const endpoint_url = "/api/admin/holidays/";
        let query_params = [
            'page=' + selectedPage,
            'page_size=' + pageSize,
            'search=' + searchVal,
        ];
        const full_url = endpoint_url + "?" + query_params.join("&");

        try {
            let response = await genericAxiosRequest('get', full_url);
            if (response.status === 'failed') {
                throw new Error(response.message);
            }
            
            setTableData(response.holidays);
            setTotalHolidaysCount(response.total);

        } catch (err) {
            message.error(err.message);
            setTableData([]);

        } finally {
            setIsTableLoading(false);
        }
    }


    async function createHoliday(creation_params) {
        if (!creation_params.name || !creation_params.effective_date) {
            message.error("Fill out the form completely.");
            return;
        }

        const endpoint_url = "/api/admin/holidays/";
        const params = {
            date: creation_params.effective_date.format('MM-DD-YYYY'),
            name: creation_params.name
        };

        try {
            let response = await genericAxiosRequest('post', endpoint_url, params);
            if (response.status === 'failed') {
                throw new Error(response.message);
            }

            message.success(response.message);
            fetchHolidays();
            setIsEditFormVisible(false);

        } catch (err) {
            message.error(err.message);
        }
    }


    async function editHoliday(target_id=null, edit_params) {
        if (!target_id) {
            return;
        }

        if (!edit_params.name || !edit_params.effective_date) {
            message.error("Fill out the form completely.");
            return;
        }

        const endpoint_url = "/api/admin/holidays/";
        const params = {
            target_id,
            date: edit_params.effective_date.format('MM-DD-YYYY'),
            name: edit_params.name
        };

        try {
            let response = await genericAxiosRequest('patch', endpoint_url, params);
            if (response.status === 'failed') {
                throw new Error(response.message);
            }

            message.success(response.message);
            fetchHolidays();
            setIsEditFormVisible(false);

        } catch (err) {
            message.error(err.message);
        }
    }


    async function deleteHoliday(target_id=null) {
        if (!target_id) {
            return;
        }
        const endpoint_url = "/api/admin/holidays/";
        const params = {
            target_id
        };

        try {
            let response = await genericAxiosRequest('delete', endpoint_url, params);
            if (response.status === 'failed') {
                throw new Error(response.message);
            }

        } catch (err) {
            message.error(err.message);
        }
    }

    function onPageChange(selectedPage) {
        setCurrentPage(selectedPage);
        fetchHolidays(selectedPage, searchValue);
    }


    useEffect(() => {
        if(! isComponentMounted.current) {
            return;
        }

        isComponentMounted.current = true;
        fetchHolidays(currentPage, searchValue);
    }, [searchValue]);


    useEffect(() => {
        isComponentMounted.current = true;


        if(localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") == null){
            history.push('/login')
        } else {
            const userType = localStorage.getItem("userType");

            if (userType == "ME" || userType == "CO"){
                history.push('/dashboard')
            }
            else if (userType == "PS"){
                history.push('/orders')
            }
            else if (userType == "PF"){
                history.push('/recon/7_eleven')
            }
            else if (userType == "MA"){
                history.push('/overview')
            }
            else {
                fetchHolidays();
            }
        }
    }, []);


    return (
        <div style={{ paddingTop: '38px', paddingLeft: '42px', paddingRight: '42px' }}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{marginBottom: '20px'}}>
                    <span style={holidayStyles.boldHeader}>
                        Holidays
                    </span>
                </div>
                <div>
                    <Button
                        style={{...holidayStyles.exportButton, ...exportButtonStyle}}
                        onMouseOver={exportButtonStyleChangers.onHover}
                        onMouseLeave={exportButtonStyleChangers.onMouseLeave}
                        hidden // Hide the export button for now
                        // onClick={onExport}
                    >
                        <Icon type='download' />
                        Export CSV
                    </Button>
                    <Button
                        style={holidayStyles.addButton}
                        onClick={() => {
                            setEditFormCallback(() => createHoliday);
    
                            setEditFormValues({name: '', effective_date: null});
                            setEditFormMode('Create');
                            setIsEditFormVisible(true);
                        }}
                    >
                        <Icon type="plus" />
                        Add New
                    </Button>
                </div>
            </div>

            <div style={{float: 'right', marginBottom: '20px'}}>
                <Input
                    placeholder="Search"
                    style={holidayStyles.searchBar}
                    onChange={(e)=>setSearchValue(e.target.value)}
                    prefix={<Icon type="search" />}
                />
            </div>

            <Table
                rowKey={holiday => holiday.id}
                rowClassName={(_, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                dataSource={tableData}
                columns={holiday_table_columns}
                pagination={false}
                loading={isTableLoading}
            />

            <div style={holidayStyles.paginationDiv}>
                <Pagination
                    size="small"
                    total={totalHolidaysCount}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    onChange={onPageChange}
                    defaultCurrent={1}
                    pageSize={pageSize}
                    current={currentPage}
                />
            </div>

            <DeleteHolidayModal
                isDeleteModalVisible={isDeleteModalVisible}
                onCancelOrClose={() => setIsDeleteModalVisible(false)}
                onDeletionConfirm={() => deletionProcedure()}
                description={deletionModalDescription}
            />

            <HolidayFormModal
                isEditFormVisible={isEditFormVisible}
                onCancelOrClose={() => setIsEditFormVisible(false)}
                onConfirm={editFormCallback}
                formValues={editFormValues}
                setFormValues={setEditFormValues}
                editMode={editFormMode}
            />
        </div>
    )
}

const holidayStyles = {
    boldHeader: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '40px'
    },
    searchBar: {
        width: '200px',
        height: '35px',
        zIndex: '1'
    },
    paginationDiv: {
        width: '100%',
        textAlign: 'right',
        justifyContent: 'center',
        marginTop: '20px'
    },
    addButton: {
        color: '#FFF',
        backgroundColor: '#1DD28B',
        height: 40,
        borderRadius: 4,
        padding: '8px, 16px, 8px, 16px',
        fontSize: 16,
        fontWeight: '600'
    },
    exportButton: {
        fontSize: 16,
        fontWeight: '600',
        padding: '8px, 16px, 8px, 16px',
        border: '1px solid #1DD28B',
        height: 40,
        marginRight: 12,
    },
}

export default Holidays;