import axios from 'axios';
import { Handle401 } from '../../../handle401/handle401';

const authorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const buxLedgerServices = {
  getInbound: async (type = 'D', search = '', start, end, page = 1, pageSize = 10, isExport = '', email = '') => {
    try {
      const res = await authorizedAxiosRequest().get(`api/bux_ledger/?type=${type}&start=${start}&end=${end}&page=${page}&page_size=${pageSize}&email=${email}&export=${isExport}`)
      return res.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
}

export default buxLedgerServices;