import React from 'react'
import { Button, Input, Divider, Select, Radio } from 'antd'
import facebook_icon from './../../static/icons/facebook.png'
import google_icon from './../../static/icons/google.png'

const { Option } = Select;

class BusinessDetails extends React.Component{

    labelStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#2B2D33',
    }
    saveBtn = {
        width: '150px',
        height: '40px',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '24px',
        float: 'right',
        marginBottom: '20px'
    }

    facebookBtnStyle = {
        backgroundColor: '#3B5998',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#FFFFFF',
        height: '40px',
        width: '100%',
    }

    googleBtnStyle = {
        backgroundColor: '#F1F1F1',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#2B2D33',
        height: '40px',
        width: '100%',
    }

    icon_style = {
        width: '15px',
        height: '15px',
        float: 'left',
        marginTop: '3px'
    }
    render(){
        return (
            <div style={{width: '70%', padding: '48px 52px 0px 52px'}}>
                <div style={this.labelStyle}>Legal name of business</div>
                <Input style={{width: '100%', height: '40px', marginBottom: '20px'}}/>
                <div  style={{display: 'flex', width:'100%', marginBottom: '20px'}}>
                    <div style={{width: '49%', marginRight: '2%'}}>
                    <div style={this.labelStyle}>Industry</div>
                    <Select
                        showSearch
                        style={{ 
                            width: '100%',
                        }}
                        size="large"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        >
                        <Option value="Food and Beverages">Food and Beverages</Option>
                    </Select>
                    </div>
                    <div style={{width: '49%'}}>
                    <div style={this.labelStyle}>Number of employees</div>
                    <Select
                        showSearch
                        style={{ 
                            width: '100%',
                        }}
                        size="large"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        >
                        <Option value="1-10">1-10</Option>
                    </Select>
                    </div>
                </div>
                <div style={this.labelStyle}>Business type</div>
                <Radio.Group style={{marginBottom: '20px'}}>
                    <Radio value="Sole propietorship">Sole proprietorship</Radio>
                    <Radio value="Corporation">Corporation</Radio>
                    <Radio value="Partnership">Partnership</Radio>
                    <Radio value="Others">Others</Radio>
                </Radio.Group>
                <div  style={{display: 'flex', width:'100%', marginBottom: '20px'}}>
                    <div style={{width: '49%', marginRight: '2%'}}>
                    <div style={this.labelStyle}>Position in the company</div>
                    <Select
                        showSearch
                        style={{ 
                            width: '100%',
                        }}
                        size="large"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        >
                        <Option value="Owner">Owner</Option>
                    </Select>
                    </div>
                    <div style={{width: '49%'}}>
                    <div style={this.labelStyle}>Company Email</div>
                    <Input style={{width: '100%', height: '40px'}}/>
                    </div>
                </div>
                <div  style={{display: 'flex', width:'100%', marginBottom: '20px'}}>
                    <div style={{width: '49%', marginRight: '2%'}}>
                    <div style={this.labelStyle}>Contact Number</div>
                    <Input style={{width: '100%', height: '40px'}}/>
                    </div>
                    <div style={{width: '49%'}}>
                    <div style={this.labelStyle}>Website(optional)</div>
                    <Input style={{width: '100%', height: '40px'}}/>
                    </div>
                </div>
                <div style={this.labelStyle}>Link social media</div>
                <div  style={{display: 'flex', width:'100%', marginBottom: '20px'}}>
                    <div style={{width: '35%', marginRight: '2%'}}>
                    <Button style={this.facebookBtnStyle}><img src={facebook_icon} style={this.icon_style} alt="#"/>Connect Facebook</Button>
                    </div>
                    <div style={{width: '35%'}}>
                    <Button style={this.googleBtnStyle}><img src={google_icon} style={this.icon_style} alt="#"/>Connect Google</Button>
                    </div>
                </div>
                <Divider></Divider>
                <Button type="primary" style={this.saveBtn}>Save changes</Button>
                
            </div>   
        )
    }
}

export default BusinessDetails