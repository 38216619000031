import React from 'react';
import { Table, Icon, Input } from 'antd';
import { numberWithCommas } from '../../../constants/constants';
import RebateDeleteModal from './rebate_delete_modal';

const RebateTable = props => {
    const {
        data, showData, editState, handleEditState,
        handleTableChange, submitData, errorMessage,
        getRebatesList, handleDeleteTier, selectText,
        setData, saveStatus, setSaveStatus
    } = props;

    const segments = localStorage.getItem('segments')

    // data.sort()

    const { Column, ColumnGroup} = Table;

    const [deleteModalState, setDeleteModalState] = React.useState(false)
    const [modalData, setModalData] = React.useState()

    const submitChanges = () => {
        submitData();
    } 

    const handleDeleteModalState = (key, data) => {
        setDeleteModalState(true)
        setModalData(data)
    }

    const handleRebateFocus = (data, type) => {
        selectText(data, type);
        showData(data, "table")
    }

    const addRow = () => {
        let newData = {
            tier_minimum: 0,
            tier_maximum: 0,
            otc_rebates: 0,
            web_banking_rebates: 0,
            grabpay_rebates: 0,
            gcash_rebates: 0,
        }

        setData([...data, newData])

        setSaveStatus(false)
    }

    const deleteAddedData = (modalData) => {
        let list = [...data]
        setData(
            list.filter(item => item.tier_minimum !== modalData.tier_minimum)
        )

    }
    

    return(
        <div>
            <RebateDeleteModal 
                modalState={deleteModalState} 
                setModalState={setDeleteModalState} 
                deleteTier={handleDeleteTier}
                modalData={modalData}
                deleteAddedData={deleteAddedData}
                setSaveStatus={setSaveStatus}
                />
            <div align='right'>
                <div hidden={editState}>
                    <button className={`outline-btn--${segments}`} 
                        onClick={() => handleEditState()}
                        disabled={data.length == 0}>
                        <Icon type="edit" className="right-8" />
                        Edit table
                    </button>
                </div>

                <div hidden={!editState}>
                    <button className={`outline-btn--${segments} right-16 `}
                        onClick={() => handleEditState()/getRebatesList()}>
                        Cancel
                    </button>
                    <button className={`btn--${segments}`}
                        disabled={saveStatus}
                        onClick={() => submitChanges()}>
                        Save changes
                    </button>
                </div>
            </div>

            <div className="rebate-error-div top-20" hidden={!errorMessage}>
                <div className="wc-header">
                    Invalid Tier Values
                </div>
                <div className="wc-text top-8">
                    {errorMessage}
                </div>
            </div>
            <Table dataSource={data}
                className="top-20"
                pagination={false}
                rowClassName={() => 'editable-row'}
                scroll={{ x: 1500 }} >
                <Column title="Tiers (Average Monthly Transaction Volume)" fixed="left" width={!editState ? '200px' : '350px'}
                    render={(text, record) => (
                        <span className="userNameStyle liveColor">
                            {
                                editState ?
                                    <span>
                                        <Input value={record.tier_minimum} 
                                            style={{
                                                width: '140px',
                                                borderColor: 
                                                    (record.tier < 6 && 
                                                        (record.tier_minimum > record.tier_maximum || 
                                                            !record.tier_minimum )
                                                            ) ? '#E24C4C' : ''
                                            }} 
                                            className="right-16" 
                                            onChange={handleTableChange} name="tier_minimum" type="number"
                                            onFocus={() => showData(record, "table")} /> 
                                        - 
                                        <Input value={record.tier_maximum} 
                                            style={{
                                                width: '140px',
                                                borderColor: 
                                                    (record.tier < 6 && 
                                                        (record.tier_maximum < record.tier_minimum || 
                                                            !record.tier_maximum)
                                                            ) ? '#E24C4C' : ''
                                            }} 
                                            className="left-16" 
                                            onChange={handleTableChange} name="tier_maximum" type="number" 
                                            onFocus={() => showData(record, "table")} /> 
                                    </span>
                                    :
                                    <span>
                                        {numberWithCommas(record.tier_minimum)} - {numberWithCommas(record.tier_maximum)}
                                    </span>
                            }
                        </span>
                    )} />
                <ColumnGroup title="Over-the-counter" align="left">
                    <Column title="Fee" key="otc_fee"
                        render={() => (
                            <div>
                                ₱ 20.00
                            </div>
                        )} />
                    <Column title="Rebate" key="otc_rebate"
                        render={(text, record) => (
                            <span className="userNameStyle liveColor">
                                {
                                    editState ?
                                        <Input value={record.otc_rebates} 
                                        addonBefore="₱" 
                                        id={`otc-rebate-${record.tier}`}
                                        style={{
                                            width: '100px',
                                            borderColor: !record.otc_rebates ? '#E24C4C !important' : ''
                                        }} 
                                        className="right-16" 
                                        onChange={handleTableChange} name="otc_rebates" 
                                        type="number"
                                        onFocus={() => handleRebateFocus(record, "otc")} />
                                    :
                                    <span>
                                        ₱ {numberWithCommas(parseFloat(record.otc_rebates).toFixed(2))}
                                    </span>
                                }
                            </span>
                    )} />
                </ColumnGroup>
                <ColumnGroup title="Web Banking" align="left">
                    <Column title="Fee" render={() => (
                        <div>
                            ₱ 15.00
                        </div>
                    )} />
                    <Column title="Rebate" key="wb_rebate"  
                        render={(text, record) => (
                            <span className="userNameStyle liveColor">
                                {
                                    editState ?
                                        <Input value={record.web_banking_rebates} 
                                        addonBefore="₱" 
                                        id={`web-rebate-${record.tier}`}
                                        style={{
                                            width: '100px',
                                            borderColor: !record.web_banking_rebates ? '#E24C4C' : ''
                                        }} 
                                        className="right-16" 
                                        onChange={handleTableChange} name="web_banking_rebates" 
                                        type="number"
                                        onFocus={() => handleRebateFocus(record, "web")} />
                                    :
                                    <span>
                                        ₱ {numberWithCommas(parseFloat(record.web_banking_rebates).toFixed(2))}
                                    </span>
                                }
                            </span>
                        )} />
                </ColumnGroup>
                <ColumnGroup title="E-wallet (GrabPay)" align="left">
                    <Column title="Fee" render={() => (
                        <div>
                           2% + ₱ 10.00
                        </div>
                    )}  />
                    <Column title="Rebate" key="ew_grab_rebate"  
                        render={(text, record) => (
                            <span className="userNameStyle liveColor">
                                {
                                    editState ?
                                        <Input value={record.grabpay_rebates} 
                                        addonBefore="₱" 
                                        id={`ew-grabpay-rebate-${record.tier}`}
                                        style={{
                                            width: '100px',
                                            borderColor: !record.grabpay_rebates ? '#E24C4C' : ''
                                        
                                        }} 
                                        className="right-16" 
                                        onChange={handleTableChange} name="grabpay_rebates" 
                                        type="number"
                                        onFocus={() => handleRebateFocus(record, "ew-grabpay")}
                                         />
                                    :
                                    <span>
                                        ₱ {numberWithCommas(parseFloat(record.grabpay_rebates).toFixed(2))}
                                    </span>
                                }
                            </span>
                        )} />
                </ColumnGroup>
                <ColumnGroup title="E-wallet (GCash)" align="left">
                    <Column title="Fee" render={() => (
                        <div>
                           2% + ₱ 10.00
                        </div>
                    )} />
                    <Column title="Rebate" key="ew_gcash_rebate" 
                        render={(text, record) => (
                            <span className="userNameStyle liveColor">
                                {
                                    editState ?
                                        <Input value={record.gcash_rebates} 
                                        addonBefore="₱" 
                                        id={`ew-gcash-rebate-${record.tier}`}
                                        style={{
                                            width: '100px',
                                            borderColor: !record.gcash_rebates ? '#E24C4C' : ''
                                        
                                        }} 
                                        className="right-16" 
                                        onChange={handleTableChange} name="gcash_rebates" 
                                        type="number"
                                        onFocus={() => handleRebateFocus(record, "ew-gcash")}
                                        />
                                    :
                                    <span>
                                        ₱ {numberWithCommas(parseFloat(record.gcash_rebates).toFixed(2))}
                                    </span>
                                }
                            </span>
                        )} />
                </ColumnGroup>
                <Column 
                    fixed='right'
                    title="Action"
                    key="action"
                    width="100px"
                    render={(text, record) => (
                        <div>
                            {
                                editState ?
                                <a href className="text-nil" onClick={() => handleDeleteModalState(text, record)}>
                                    <Icon type="delete" className="right-8" />
                                </a>
                                :
                                <a href className="text-nil" onClick={() => showData(record)}>
                                    <Icon type="edit" className="right-8" />
                                    Edit
                                </a>
                            }
                            
                        </div>
                    )} />
            </Table>
            
            {
                editState && 
                <div className="top-32">
                    <button
                        disabled={data.length === 10}
                        className={`outline-btn--${segments} btn-100-width btn-height`}
                        onClick={() => addRow()}>
                        {
                            data.length < 10 ?
                                "+ Add new tier"
                                :
                                "Max of 10 tiers"
                        }
                    </button>
                </div>
            }

        </div>
    )
}

export default RebateTable;