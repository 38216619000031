import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab } from '@material-ui/core';
import MetaTag from '../../meta_tag/meta_tag';
import { Button, Typography } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import ButtonGroup from 'antd/lib/button/button-group';
// import fraudActions from './fraudActions';
import { history } from '../../../store/history'
import { segmentStroke } from '../../constants/constants';
import AdvancePH from './advance_ph';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ padding: 0 }} p={2}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const ButtonTab = (props) => {
    const { status, handleClick, selected } = props;

    return (
        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%'}}>
            <ButtonGroup>
                {
                    status.map((btn, i) => {
                        return (
                            <Button
                                key={btn}
                                value={btn}
                                onClick={handleClick}
                                style={{
                                    ...styles.btnGroup,
                                    color: btn === selected ? '#FFF' : '#2b2d32',
                                    backgroundColor: btn === selected ? segmentStroke[0] : '#FFF',
                                    borderRadius: i === 0 ? '4px 0 0 4px' : i === 1 ? '0 4px 4px 0' : 0
                                }}
                            >
                                {btn}
                            </Button>
                        )
                    })
                }
            </ButtonGroup>
        </div>
    )
}

const AdvancePayout = (props) => {
    const [selected, setSelected] = React.useState('Advance PH');
    const pUid = props.props.match.params.id;

    console.log('ADVANCING', props);

    const status = ['Recon', 'UBP FSC', 'Advance PH'];


    useEffect(() => {
        if (localStorage.getItem("jwtToken") === "" || !localStorage.getItem("jwtToken")) {
            history.push('/login')
        }
        else if (['ME', 'CO'].includes(localStorage.getItem("userType"))) {
            history.push('/dashboard')
        }
        else if (['PS', 'PF'].includes(localStorage.getItem("userType"))) {
            history.push('/submissions_kyb')
        }
        else if (!['BS', 'KC', 'AD'].includes(localStorage.getItem("userType"))) {
            history.push('/users')
        }
    }, [])


    const handleClick = (event) => {
        setSelected(event.target.value);
    }

    return (
        <div style={styles.root}>
            <ButtonTab handleClick={handleClick} status={status} selected={selected} />
            <TabPanel value={selected} index={'Recon'}>
                PAGE 1
            </TabPanel>
            <TabPanel value={selected} index={'UBP FSC'}>
                PAGE @
            </TabPanel>
            <TabPanel value={selected} index={'Advance PH'}>
                <AdvancePH uid={pUid} />
            </TabPanel>
        </div>
    )
};

const styles = {
    root: {
        width: '100%'
    },
    titleContainer: {
        padding: '42px 42px 0px 42px'
    },
    title: {
        backgroundColor: 'transparent',
        width: '100%',
        color: '#000',
        fontWeight: 'bold',
        fontSize: 28,
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 32,
    },
    btnGroup: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: '0 32px',
    },
};

export default AdvancePayout;