import React from 'react'
import axios from 'axios'
import {Button, Card, message, Icon, Divider} from 'antd'
import MyQRIcon from '../../../static/icons/my_qr_code.svg'
import SettingsIcon from '../../../static/icons/settings_icon.svg'
import ShareQrModal from '../../account_settings/bux_qr/share_qr_modal'
import {history} from '../../../store/history'
import { IconButton } from '@material-ui/core'
import { isMobile } from 'react-device-detect'
import qrServices from '../../account_settings/bux_qr/qr_services/qrServices'
import HelpModal from './help_modal'

const MyCheckoutCard = props => {

    const REACT_APP_ROOT_URL = process.env.REACT_APP_ROOT_URL;
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
    const testMode = history.location.pathname.indexOf('/test') > -1;
    const tName = localStorage.getItem("tradeName")
    const sbTname = localStorage.getItem("SbTradeName")
    const isChild = localStorage.getItem("is_child") == "true" || localStorage.getItem("is_child") == true
    const childAcct = (localStorage.getItem("userType") == "CO" && localStorage.getItem("isAlias") == "true")
    const segments = localStorage.getItem("segments");
    const noTradeName = testMode ? (sbTname === "null" || sbTname === null || sbTname === "") : (tName === "null" || tName === null || tName === "")
    const userRole = localStorage.getItem("userType")
    const subuserRole = localStorage.getItem("subuser_role")
    const kybRequired = localStorage.getItem("kybRequired")
    const username = localStorage.getItem("username")
    const level = localStorage.getItem("Level")
    const [shareModal, setShareModal] = React.useState(false)
    const [helpModal, setHelpModal] = React.useState(false)
    let remove_part = `.${process.env.REACT_APP_SUB_URL}`;
    const master_trade_name = window.location.hostname.replace(remove_part, "");
    
    function copyLink(){
        var dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.value = checkoutLink;
        dummy.select()
        document.execCommand("copy")
        dummy.remove();
        message.destroy()
        message.info("Copied My Checkout Link")
    }

    const yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    } 

    const REACT_APP_API_URL = process.env.REACT_APP_API_URL
    const [officialTradeName, setOfficialTradeName] = React.useState("")
    const [isForActivation, setIsForActivation] = React.useState(true)

    let tradeName = !noTradeName && (level > 0 || !kybRequired || (userRole == "SW" && subuserRole == "CO")) ? officialTradeName : 
                    userRole == "ME" || (userRole == "SW" && subuserRole == "ME") ? localStorage.getItem("firstName").replace(/\s/g, '') : 
                    username ? username.replace(/\s/g, '') : '';
   
    let sub_remove_part = window.location.protocol+'//';
    const protocol = window.location.protocol;
    const subQRCheckoutUrlLink = `${REACT_APP_BASE_URL}/mycheckout/${tradeName}`;
    const subQrCheckoutUrl = protocol + '//' + master_trade_name + '.' + subQRCheckoutUrlLink.replace(sub_remove_part, "");
    const mainCheckoutUrl = `${REACT_APP_BASE_URL}/${tradeName}`;
    const testmodeCheckoutUrl = `${REACT_APP_BASE_URL}/test/QR/${tradeName}`;

    // const checkoutLink = testMode ? process.env.REACT_APP_BASE_URL + `/${tradeName}` + '/test' : process.env.REACT_APP_BASE_URL + "/"+ tradeName
    // const checkoutLink = userRole ? master_trade_name+'.'+subQRCheckoutUrlLink.replace(sub_remove_part, "") : testMode ? 'https://' + tradeName + '.' + REACT_APP_ROOT_URL+'/test/qr' : 'https://' + tradeName + '.' + REACT_APP_ROOT_URL;
    const checkoutLink = userRole === 'SW'? subQrCheckoutUrl : testMode? testmodeCheckoutUrl : mainCheckoutUrl;
    
    // const hideOrDisabled = (childAcct && noTradeName) || level === 0
    const hideOrDisabled = level === 0 && (!testMode || noTradeName || (userRole == "SW" && subuserRole == "CO" && kybRequired))

    async function getTradeName() {

        try {
            let endpoint;

            if(isChild){
                endpoint = testMode ? `/api/sandbox/qr_details/?trade_name=${sbTname ? sbTname : tradeName}` 
                            : `/api/qr_details/?trade_name=${tName ? tName : tradeName}`
            }

            else {
                endpoint = testMode ? `/api/sandbox/qr_details/` : `/api/qr_details/`
            }

            let url = REACT_APP_API_URL + endpoint

            let response = await axios.get(url, yourConfig)
            console.log(response)
            if(response.status === 'failed') {
                console.log(response)
                message.error(response.data.message, 3)
                setOfficialTradeName(username)
            }

            else {
                setOfficialTradeName(response.data.trade_name)
                setIsForActivation(false)
            }
        }

        catch (error) {
            if(error && error.response && error.response.data && error.response.data.message === "QR Not Found" || error && error.response && error.response.data && error.response.data.message === "Test QR Not Found") {
                setOfficialTradeName(username)

            }

            else {
                // message.error(error.response.data.message)
                console.log(error.response)

            }
        }
    }

    async function setupQr () {
        let noSpace = tradeName.replace(/\s/g, '')
        let params = {
            trade_name: noSpace,
        }

        if(noSpace.length > 24) {
            message.error('Qr link is too long');
            return false;
        }

        console.log(params)

        try {
            const response = await qrServices.setupTradeName(testMode, params);

            if(response.data.status === "failed") {
                message.error(response.data.message, 5)
            }

            else if (response.data.status === "success") {
                localStorage.setItem("tradeName", response.data.trade_name)
                message.success('Your URL is successfully activated', 3)
                window.location.reload()
            }

        }

        catch (error) {
            console.log(error.response)
        }
    }

    React.useEffect(() => {
        
            getTradeName()
        
    }, [tradeName])

    return(
        <Card className="tracker-card" bodyStyle={{padding: '12px 16px'}}>
            <span className="boldTextStyle">My Checkout</span>
            <IconButton color="inherit" aria-label="info" variant="contained" style={{padding: 4, marginTop: '-4px'}} onClick={() => setHelpModal(true)}>
                <Icon type="question-circle" theme="outlined" className="questionIcon" />
            </IconButton>

            <div className="twoCol" style={{marginTop: 16}}>
                <div className="normalSizeStyle liveColor">
                    <div className="wrap">
                        {userRole === 'SW'? subQrCheckoutUrl : testMode? testmodeCheckoutUrl : mainCheckoutUrl}
                    </div>
                </div>

                <div style={{marginTop: -4}}>
                {
                    hideOrDisabled && !testMode  ?
                        <button className={`md-btn bg-${segments} border-${segments} disabled`} disabled>
                            For Activation
                        </button> 
                        :
                        <button className={`md-btn bg-${segments} border-${segments}`} onClick={()=> isForActivation ? setupQr() : copyLink()}>
                            {isForActivation ?
                            "Activate URL" : "Copy"}
                        </button>
                }
                </div>

            </div>

            <Divider style={{padding: 1, background: '#E9EEF4', opacity: testMode ? 0.5 : ''}} />
            
            <div align="center">
                <div className="space-around">
                    <div>
                        <Button type="link"
                            onClick={() => setShareModal(true)}
                            style={{opacity: (hideOrDisabled && !testMode) || (isForActivation) ? 0.5 : ''}}
                            disabled={
                                (hideOrDisabled && !testMode) || (isForActivation)
                            }>
                            <img src={MyQRIcon} alt="icon" className="icon-space" /> <span className={"card-text-size liveColor"}>My QR Code</span>
                        </Button>
                    </div>

                    <Divider type="vertical" style={{height: '35px', width: '1px', backgroundColor: '#d4d5d8'}} />

                    <div>
                        <Button type="link"
                            onClick={() => 
                                (!childAcct && !testMode) ? window.location.href = "/account_settings#my_checkout"
                                :
                                ((childAcct || !childAcct) && testMode) ? window.location.href = "/test/account_settings#my_checkout"
                                :
                                ""
                            }
                            style={{opacity: hideOrDisabled && !testMode ? 0.5 : ''}}
                            disabled={hideOrDisabled && !testMode}>
                            <img src={SettingsIcon} alt="icon" className="icon-space" /> 
                            <span className={"card-text-size liveColor"}>
                                {
                                    (hideOrDisabled || level == 0)  ?
                                    "Edit URL" : "Settings"
                                }
                            </span>
                        </Button>
                    </div>
                </div>
            </div>

            <ShareQrModal closeShareModal={()=>setShareModal(false)} showShareModal={shareModal} />
            <HelpModal helpModal={helpModal} setHelpModal={setHelpModal} />
        </Card>
    )
}

export default MyCheckoutCard