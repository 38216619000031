import { makeStyles } from '@material-ui/core';

const completeOrder = makeStyles(theme => ({
    
    mainBody: {
        backgroundColor: '#f5f5f4',
        fontFamily: 'Inter',
        paddingBottom :'50px',
        [theme.breakpoints.down("sm")]: {
            padding: '30px 15px 30px 15px',
            overflow: 'hidden',
        },

        [theme.breakpoints.down("xs")]: {
            padding: '30px 15px 30px 15px',
            overflow: 'hidden',
        }
    },

    header: {
        width: '750px',
        fontWeight: 'bold',
        marginTop: '7%',
        fontSize: '25px',
        lineHeight: '32px',
        color: '#000000',
        [theme.breakpoints.down("sm")]: {
            fontSize: '20px',

        },

        
    },

    breadCrumbs:{
        marginTop: '-2.7%',
        marginLeft: '-1.5%',
        padding: '30px 0px 30px 11%',
        position: 'fixed',
        background: '#f5f5f4',
        marginBottom: '20px',
        width: '100%',
        zIndex: '2',

        [theme.breakpoints.down("md")]: {
            padding: '30px 0px 30px 11%',

        },

        [theme.breakpoints.down("lg")]: {
            padding: '30px 0px 30px 11%',

        },

    },

    breadCrumbsMobile:{


    },

    breadCrumbsHover: {
        color: '#000000',
        '&:hover': {
            color: '#1d82b8',
            textDecoration: 'underline'   
        }
    },

    menu: {
      alignItems: 'center',
      paddingTop: '32px'
      
    },

    completeDiv: {
        padding: '32px',
        marginTop: '32px',
        width: '750px',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
        fontFamily: 'Inter',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#54575F',
        backgroundColor: '#fff',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            fontSize: '16px',
            padding: '32px',

        },
        [theme.breakpoints.down("xs")]: {
            width: '100%',
            fontSize: '16px',
            padding: '32px',

        }
    },

    cInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down("sm")]: {
            marginBottom: '10px'

        }
        
    },

    compleShareDiv: {
        marginTop: '40px',
        width: '750px',
        [theme.breakpoints.down("sm")]: {
            marginTop: '20px',
            width: '100%',

        }
  
    },

    csbHeader:{
        fontStyle: '16px',
        fontFamily: 'Inter',
        lineHeight: '24px',
        fontWeight: '600',
        color: '#000000',
    },

    btnMsgr: {
        borderRadius: '4px',
        backgroundColor: '#fff',
        color: '#0084FF',
        width: '230px',
        height: '50px',
        border: '2px solid #fff',
        fontFamily: 'Inter',
        lineHeight: '24px',
        fontSize: '16px',
        fontWeight: 600,
        [theme.breakpoints.down("sm")]: {
            marginTop: '20px',
            width: '100%',

        }

    },

    btnEmail: {
        borderRadius: '4px',
        backgroundColor: '#fff',
        color: '#2B2D33',
        width: '230px',
        height: '50px',
        border: '2px solid #F1F1F1',
        fontFamily: 'Inter',
        lineHeight: '24px',
        fontSize: '16px',
        fontWeight: 600,
        [theme.breakpoints.down("sm")]: {
            marginTop: '10px',
            width: '100%',

        }

    },

    Or: {
        width: '750px',
        marginTop: '50px',
        [theme.breakpoints.down("sm")]: {
            marginTop: '30px',
            width: '100%',

        }
    },

    inputDiv: {
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            marginTop: '16px'
        }
    },

    inputs:{
        marginTop: '16px',
    },

    sfDiv: {
        marginTop: '32px',
        width: '750px',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
        fontFamily: 'Inter',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#54575F',
        backgroundColor: '#fff',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            fontSize: '16px',

        },
        [theme.breakpoints.down("xs")]: {
            width: '100%',
            fontSize: '16px',

        }
    },

    sfSubDiv: {
        padding: '32px',
        [theme.breakpoints.down("sm")]: {
            padding: '32px'

        },
    },


    shippingForm: {
        width: '750px',
        marginTop: '40px',
        fontStyle: 'Inter',
        fontSize: '14px',
        color: '#212B36',

        [theme.breakpoints.down("sm")]: {
            width: '100%',
            marginTop: '16px'
        }
    },

    shippingFee: {
        width: '750px',
        marginTop: '40px',
        fontStyle: 'Inter',
        fontSize: '14px',
        color: '#212B36',

        [theme.breakpoints.down("sm")]: {
            width: '100%',
            marginTop: '16px'
        }
    },

    shippingFeeDesc: {
        marginTop: '20px',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#909196',

        [theme.breakpoints.down("sm")]: {
            width: '100%',
            marginTop: '16px'
        }
        
    },

    sfSummary: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '10px'
    },

    sfNote: {
        background: '#f1f1f1',
        padding: '15px 32px 20px 32px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '0px 0px 4px 4px',
        color: '#000000'
    },


    actionBtn: {
        width: '750px',
        marginTop: '50px',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        }
    },

    btns: {
        marginTop: '30px',
        display: 'flex',
        justifyContent: 'space-between'
    },

    cancelBtn: {
        border: '2px solid #F5922F',
        width: '150px',
        height: '50px',
        fontFamily: 'Inter',
        backgroundColor: '#fff',
        fontWeight: 600,
        fontSize: '16px',
        color: '#F5922F',
        borderRadius: '4px',

    },

    submitBtn: {
        border: '1px solid #F5922F',
        width: '150px',
        height: '45px',
        borderRadius: '4px',
        backgroundColor: '#F5922F',
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        color: '#fff'
    },

    personalMenu: {
        display: 'flex',
        justifyContent: 'space-between'
    },

    shipHeader: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
        color: '#000000',
    },

}))

export default completeOrder;