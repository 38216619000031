import React, {useEffect} from 'react'
import { Dropdown, Icon, Input, Menu} from 'antd';
import axios from 'axios';
import ClearFilter from '../filter_btns/clear_filter_btn';
import SwitchModal from '../../multiwallet/components/master_wallet_page/table/subwallet_table/switch_corporate_modal'
import SubwalletsTable from './table';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const SubWallets = props => {

    const pUid = props.props.match.params.id;

    const [searchVal, setSearchVal] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [tableData, setTableData] = React.useState(null);
    const [kycLevelFilter, setKycLevelFilter] = React.useState('');
    const [visibleClearBtn, setVisibleClearBtn] = React.useState(false);
    const [pageSize, setpageSize] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [switchModalVisible, setSwitchModalVisible] = React.useState(false)

    const segments = localStorage.getItem('segments')

    useEffect(()=>{
        getSubwalletUsers()
    },[])


    const authorizedAxiosRequest = () => {
        return axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        });
    }
    const getSubwalletUsers = async (page = "1") => {
        setIsLoading(true);
        try {
            let endpoint = `/api/corporate/subusers/${pUid}/`
            const res = await authorizedAxiosRequest().get(`${endpoint}?search=${searchVal}${kycLevelFilter && `&kyc_level=${kycLevelFilter}`}&page=${page}&page_size=${pageSize}`);

            if (res) {
                setTableData(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }


    const handleKycLevelChange = ({ item }) => {
        setKycLevelFilter(item.props.value);
        setVisibleClearBtn(true);
    }

    const kycLeveMenu = (
        <Menu onClick={handleKycLevelChange}>
            <Menu.Item key="basic" value={'0'}>
                Non-KYC
            </Menu.Item>
            <Menu.Item key="pro" value={'1'}>
                Approved KYC
            </Menu.Item>
        </Menu>
    )

    const returnKycLevel = (val) => {
        switch (val) {
            case '0':
                return 'Non-KYC';
            case '1':
                return 'Approved KYC';
            default:
                return 'KYC Level';
        }
    }

    const onFilterApply = () => {
        getSubwalletUsers();
        setVisibleClearBtn(true);
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value);
    }

    const applyBtnDisabled = () => {
        return !searchVal && (!kycLevelFilter || kycLevelFilter === '')
    }

    const resetFilters = () => {
        setSearchVal('');
        setVisibleClearBtn(false);
        setKycLevelFilter('')
        setpageSize(10);
        setPage(1);
        getSubwalletUsers();
    }

    const changePage = (page) => {
        getSubwalletUsers(page);
        setPage(page);
    }

    return(
        <div style={styles.root}>
            <div style={styles.body}>
                <div style={styles.subContainer} className="top-16">
                    <ClearFilter hideFilterBtn={!visibleClearBtn} clearFilter={resetFilters} />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{ display: 'flex', padding: '0 0 24px 0' }}>
                        <Dropdown overlay={kycLeveMenu} trigger={['click']}>
                            <a style={styles.dropdownItems} >
                                {returnKycLevel(kycLevelFilter)}
                                <ExpandMoreIcon style={styles.dropdownArror} />
                            </a>
                        </Dropdown>
                        <Input
                            placeholder="Search"
                            style={{ width: '200px', height: '40px', borderRadius: '4px', padding: '0 8px' }}
                            suffix={<Icon type="search" style={{ fontSize: '18px' }} />}
                            onChange={handleSearch}
                            value={searchVal}
                        />
                        <button className={`btn--${segments} btn-height`} style={{opacity: applyBtnDisabled() ? '0.5' : '1' }} disabled={applyBtnDisabled()} onClick={onFilterApply}>
                            Apply
                        </button>
                    </div>
                    <div hidden={tableData && tableData.subuser_role == 'CO'} style={{display: 'flex', paddingRight: '20px', cursor: 'pointer'}} onClick={()=>setSwitchModalVisible(true)}>
                        <Icon className={`text-${segments}`} type="user" style={{fontSize: '16px', margin:'3px 8px 0px 0px'}}/>
                        <div className={`text-${segments}`} style={{fontSize: '16px', fontWeight: '500'}}>Convert Sub-wallets</div>
                    </div>

                </div>
                <SwitchModal getSubWallet={()=>getSubwalletUsers()} pUid={pUid} segments={segments} visible={switchModalVisible} setVisible={setSwitchModalVisible}/>

                <SubwalletsTable
                    segments={segments}
                    data={tableData}
                    isLoading={isLoading}
                    onChangePage={changePage}
                    pageSize={pageSize}
                />
            </div>
        </div>
    )
}

const styles = {
    root: {
        width: '1000px',
        backgroundColor: '#FFF'
    },
    body: {
        padding: '0px 16px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    },
    btnContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    exportBtn: {
        fontSize: 16,
        fontWeight: '600',
        padding: '8px, 16px, 8px, 16px',
        border: '1px solid #1DD28B',
        height: 40,
        marginRight: 12,
        color: '#FFF',
        backgroundColor: '#1DD28B'
    },
    btnGroup: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: '0 32px',
    },
    subContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 0'
    },
    clearDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    clearIcon: {
        color: '#2B2D33',
        fontSize: 10,
    },
    filterText: {
        color: '#000',
        fontSize: 14,
        fontWeight: 'bold'
    },
    clearBtnDiv: {
        backgroundColor: '#E6EAF0',
        borderRadius: 24,
        padding: '4px 12px',
        margin: '0 12px'
    },
    clearBtn: {
        color: '#000000',
        fontSize: 12,
        paddingLeft: 4,
    },
    reminder: {
        fontSize: 12,
        color: '#909196',
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 178,
        padding: '0 8px 0 8px'
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    applyBtn: {
        height: 40,
        width: 92,
        fontSize: 16,
        fontWeight: '600',
        borderRadius: 4,
        backgroundColor: '#F5922F',
        color: '#FFF',
    },
    createBtn: {
        fontSize: 16,
        fontWeight: '600',
        height: 40,
        borderColor: '#1DD28B',
        marginRight: 8,
    },
    dropdownItems: {
        display: 'flex',
        alignItems: 'center',
        padding: '7px 4px 7px 16px',
        fontSize: 16,
        color: '#2B2D33',
        border: '1px solid #D1D5DD',
        borderRadius: 5,
    }
}


export default SubWallets