import React, { useState, useEffect, useRef } from "react";
import {Input} from 'antd'
import enterpriseStyle from '../../../kyb/forms/kybStyle'

const CustomInput = props => {

    const kyb = enterpriseStyle()

    const {
        value,
        placeholder,
        name,
        changeValue,
        handleChangeVal,
        index,

    } = props


    const [error, setError] = useState(true)
    const [errorMessage, setErrorMessage] = useState(`${placeholder} is required`)
  

    const handleChange = (e) => {
        setError(true)

        handleChangeVal(e, index)

    };

    return(
        <div>
        <Input type="number" style={{height: '40px', borderColor: !error ? '#E24C4C' : ''}} name={name} value={value} placeholder={placeholder} 
        onBlur={e => {if(e.target.value.length == 0){
            setError(false)
        }}}
        
        onChange={e => { if(name == "maximum") {
            if(e.target.value.length <= 5){
                handleChange(e)
            }
        }
        else {
            handleChange(e) 
        }
        }}/>
        <div hidden={error} align="left" className={kyb.miniText} style={{marginTop: 3, color: '#E24C4C'}}>{errorMessage}</div>

        </div>
    )
}
export default CustomInput