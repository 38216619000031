import React from 'react';
import ButtonGroup from 'antd/lib/button/button-group';
import moment from 'moment';
import Table from './table';
import { Button, DatePicker, Dropdown, Icon, Input, Typography, Menu } from 'antd';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import multiwalletServices from '../../../../multiwalletServices';
import crypto from 'crypto';
import { history } from '../../../../../../store/history';
import { segmentStroke } from '../../../../../constants/constants';
import ClearFilter from '../../../../../AdminComponents/filter_btns/clear_filter_btn';

const WalletHistoryTable = (props) => {
    const [selected, setSelected] = React.useState('Transfer Money')
    const [dateRange, setDateRange] = React.useState({ startDate: '', endDate: '' });
    const [visibleClearBtn, setVisibleClearBtn] = React.useState(false);
    const [searchVal, setSearchVal] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [createBtnStyle, setCreateBtnStyle] = React.useState({ color: '#1DD28B', backgroundColor: 'transparent' });
    const [tableData, setTableData] = React.useState(null);
    const [customRange, setCustomRange] = React.useState('All');
    const [statusFilter, setStatusFilter] = React.useState('');
    const [pageSize, setPageSize] = React.useState(10);
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const userType = localStorage.getItem('userType');
    const notAdmin = ['SW', 'ME', 'CO'].includes(userType);
    const userHistoytProps = props && props.props && props.props.props;
    const pUid = notAdmin? window.location.pathname.split("/").pop() : userHistoytProps && userHistoytProps.match.params.id;
    
    let requestToken = '';

    const ButtonTab = ({ rootStyle }) => {

        const status = ['Transfer Money', 'Add Money'];

        const handleClick = (event) => {
            setSelected(event.target.value);
        }

        return (
            <div style={rootStyle}>
                <ButtonGroup>
                    {
                        status.map((btn, i) => {
                            return (
                                <Button
                                    key={btn}
                                    value={btn}
                                    onClick={handleClick}
                                    style={{
                                        ...styles.btnGroup,
                                        color: btn === selected ? '#FFF' : '#2B2D33',
                                        backgroundColor: btn === selected ? segmentStroke[0] : '#FFF',
                                        borderRadius: i === 0 ? '4px 0 0 4px' : i === 1 ? '0 4px 4px 0' : 0
                                    }}
                                >
                                    {btn}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </div>
        )
    }

    const handleDayChange = ({ item }) => {
        setCustomRange(item.props.value)
        setDateRange({ startDate: '', endDate: '' });
    }

    const handleStatusChange = ({ item }) => {
        setStatusFilter(item.props.value)
    }

    const returnCustomRange = (key) => {
        switch (key) {
            case 'All':
                return 'All Time';
            case 'Today':
                return 'Today';
            case 'Week':
                return 'Last 7 days';
            case 'Month':
                return 'Last 30 days';
            case 'Custom':
                return 'Custom';
            default:
                return 'All Time';
        }
    }

    const dayMenu = (
        <Menu onClick={handleDayChange}>
            <Menu.Item value="All">All Time</Menu.Item>
            <Menu.Item value="Today">Today</Menu.Item>
            <Menu.Item value="Week" >Last 7 days</Menu.Item>
            <Menu.Item value="Month" >Last 30 days</Menu.Item>
        </Menu>
    )

    const statusMenu = (
        selected === 'Transfer Money' ?
            <Menu onClick={handleStatusChange}>
                <Menu.Item key="success" value={'success'}>
                    Success
                </Menu.Item>
                <Menu.Item key="failed" value={'failed'}>
                    Failed
                </Menu.Item>
            </Menu>
            :
            <Menu onClick={handleStatusChange}>
                <Menu.Item key="Paid" value={'Paid'}>
                    Paid
                </Menu.Item>
                <Menu.Item key="Pending" value={'Pending'}>
                    Pending
                </Menu.Item>
                <Menu.Item key="Cancelled" value={'Cancelled'}>
                    Cancelled
                </Menu.Item>
                <Menu.Item key="Expired" value={'Expired'}>
                    Expired
                </Menu.Item>
            </Menu>
    )

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    const rangeSelect = (dates, dateString, id) => {
        setDateRange({ ...dateRange, [id]: moment(dateString).format('MM-DD-YYYY') });
        setCustomRange('Custom');
    }

    const onFilterApply = () => {
        getTranxHistory(testMode, returnSelected(selected), (statusFilter === '' && selected === 'Add Money' ? 'Paid' : statusFilter), searchVal, dateRange.startDate, dateRange.endDate, customRange);
        setVisibleClearBtn(true);
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value);
    }


    const resetFilters = () => {
        setDateRange({ startDate: '', endDate: '' });
        setSearchVal('');        
        setStatusFilter(selected==='Add Money'? 'Paid' : '');
        setVisibleClearBtn(false);
        getTranxHistory(testMode, returnSelected(selected));
        setCustomRange('All');
    }

    const applyBtnDisabled = () => {
        return !dateRange.startDate &&
            !dateRange.endDate &&
            !statusFilter &&
            customRange === 'All' &&
            !searchVal
    }

    const onCreateBtnHover = () => {
        setCreateBtnStyle({
            color: '#FFF',
            backgroundColor: '#1DD28B'
        });
    }

    const onCreateBtnLeave = () => {
        setCreateBtnStyle({
            color: '#1DD28B',
            backgroundColor: 'transparent'
        });
    }

    const getTranxHistory = async (isTestMode, type, status, search, start, end, summary, pageSize, page) => {
        setIsLoading(true);
        let res;
        try {
            if (selected === 'Transfer Money') {
                res = await multiwalletServices.getSubwalletTransferTranxById(isTestMode, pUid, 'all', status, search, start, end, summary, pageSize, page);
            } else {
                res = await multiwalletServices.getAddMoneyHistoryById(isTestMode, pUid, status, search, start, end, summary, pageSize, page, generateToken(),);
            }

            if (res) {
                setTableData(res);
            }
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false);
    }

    const changePage = (page) => {
        getTranxHistory(testMode, returnSelected(selected), statusFilter, searchVal, dateRange.startDate, dateRange.endDate, customRange, pageSize, page);
    }

    const generateToken = () => {
        let generatedRequestToken = crypto.randomBytes(16).toString('hex');
        requestToken = generatedRequestToken
        return generatedRequestToken
    }

    const returnSelected = (selected) => {
        switch (selected) {
            case 'Transfer Money':
                return 'all';
            case 'Receive Money':
                return 'received';
            case 'Other Fees':
                return 'sent';
            default:
                return 'all';
        }
    }

    React.useEffect(() => {
        // getSubwalletUsers();
        let status = selected === 'Add Money' ? 'Paid' : '';
        setStatusFilter(status);
        getTranxHistory(testMode, returnSelected(selected), status, searchVal, dateRange.startDate, dateRange.endDate);
    }, [selected])

    return (
        <div style={styles.root}>
            <ButtonTab />
            <div style={styles.body} className="top-20">
                <div style={styles.subContainer} className="top-16">
                    <ClearFilter hideFilterBtn={!visibleClearBtn} clearFilter={resetFilters} />
                    {/* <div style={styles.clearDiv}>
                        <Typography style={styles.filterText}>Filter</Typography>
                        <a style={styles.clearBtnDiv} hidden={!visibleClearBtn} onClick={resetFilters}>
                            <Icon style={styles.clearIcon} type="close-circle" theme="filled" />
                            <span style={styles.clearBtn}>Clear Filter</span>
                        </a>
                    </div>
                    <Typography style={styles.reminder}><Icon type="exclamation-circle" theme="filled" /> Click <b>Apply</b> button to show results.</Typography> */}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '0 0 24px 0' }}>
                    <Dropdown overlay={dayMenu} trigger={['click']} >
                        <a style={styles.dropdownItems} >
                            <span style={styles.dropDownStyle}>{returnCustomRange(customRange)}</span>
                            <ExpandMoreIcon style={styles.dropdownArror} />
                        </a>
                    </Dropdown>
                    <div style={{ paddingLeft: 8 }}>
                        <Dropdown overlay={statusMenu} trigger={['click']}>
                            <a style={styles.dropdownItems} >
                                {!statusFilter ? 'Status' : statusFilter.charAt(0).toUpperCase() + statusFilter.slice(1)}
                                <ExpandMoreIcon style={styles.dropdownArror} />
                            </a>
                        </Dropdown>
                    </div>
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledDate}
                        // defaultValue={moment()}
                        value={dateRange.startDate && moment(dateRange.startDate)}
                        size="large" picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'startDate')}
                        placeholder='Start date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <span style={{ paddingTop: 8 }}>-</span>
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledDate}
                        // defaultValue={moment()}
                        value={dateRange.endDate && moment(dateRange.endDate)}
                        size="large"
                        picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'endDate')}
                        placeholder='End date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <Input
                        placeholder="Search"
                        style={{ width: '200px', height: '40px', borderRadius: '4px', padding: '0 8px 0 0px' }}
                        suffix={<Icon type="search" style={{ fontSize: '18px' }} />}
                        onChange={handleSearch}
                        value={searchVal}
                    />
                    <button className={`btn--${props.segments} btn-height`} disabled={applyBtnDisabled()} onClick={onFilterApply} >Apply</button>
                </div>
                <Table
                    data={tableData}
                    isLoading={isLoading}
                    pageSize={pageSize}
                    selected={selected}
                    status={statusFilter}
                    onChangePage={changePage}
                />
            </div>
        </div>
    )
}

const styles = {
    root: {
        width: '100%',
        padding: '40px 0'
        //   display: 'flex', 
        //   flexDirection: 'column', 
        // //   justifyContent: 'center', 
        //   alignItems: 'center'
    },
    body: {
        padding: '0px 16px',
        backgroundColor: '#FFF',
        // border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        // margin: '38px 0'
    },
    exportBtn: {
        fontSize: 16,
        fontWeight: '600',
        padding: '8px, 16px, 8px, 16px',
        border: '1px solid #1DD28B',
        height: 40,
        marginRight: 12,
        color: '#FFF',
        backgroundColor: '#1DD28B'
    },
    btnGroup: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: '0 32px',
    },
    btnTabContainer: {
        backgroundColor: '#FFF',
        width: '100%',
        padding: '20px, 20px 0 20px',
    },
    subContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 0'
    },
    clearDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    clearIcon: {
        color: '#2B2D33',
        fontSize: 10,
    },
    filterText: {
        color: '#000',
        fontSize: 14,
        fontWeight: 'bold'
    },
    clearBtnDiv: {
        backgroundColor: '#E6EAF0',
        borderRadius: 24,
        padding: '4px 12px',
        margin: '0 12px'
    },
    clearBtn: {
        color: '#000000',
        fontSize: 12,
        paddingLeft: 4,
    },
    reminder: {
        fontSize: 12,
        color: '#909196',
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 178,
        padding: '0 8px 0 8px'
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    applyBtn: {
        height: 40,
        width: 92,
        fontSize: 16,
        fontWeight: '600',
        borderRadius: 4,
        backgroundColor: '#F5922F',
        color: '#FFF',
    },
    createBtn: {
        fontSize: 16,
        fontWeight: '600',
        height: 40,
        borderColor: '#1DD28B',
        marginRight: 8,
    },
    dropdownItems: {
        display: 'flex',
        alignItems: 'center',
        padding: '7px 4px 7px 16px',
        fontSize: 16,
        color: '#2B2D33',
        border: '1px solid #D1D5DD',
        borderRadius: 5,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dropdownArror: {
        color: '#2B2D33',
        fontSize: 24,
        margin: '0 4px'
    },
    dropDownStyle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 56
    }
}

export default WalletHistoryTable;