import React from 'react';
import * as Icon from '../../../static/icons/how_it_works/index';
import * as Img from '../../../static/images/how_it_works/index';
import ContentCard from '../content_card';


const ProfileStep = props => {
    
    const {
        segments, step
    } = props;


    let profile_icon = segments == 'me_' ? Icon.Profile_me_ : 
                                segments == 'gig' ? Icon.Profile_gig : 
                                    segments == 'sme' ? Icon.Profile_sme : 
                                        segments == 'biz' ? Icon.Profile_biz: ""
                                        


    return (
        <div>
            <ContentCard step={step}
                    icon={profile_icon}
                    image={Img.Profile}
                    title={"Profile"}
                    content={"This page displays all necessary information about your account and business."} />
        </div>
    )
}

export default ProfileStep;