import React,{ useState } from 'react';
import { Modal, Typography, Button } from 'antd';

const NotifModal = ({visible, closeModal, data}) => {
  const [btnStyle, setBtnStyle] = useState({ })

  const handleMouseOver = () => {
    setBtnStyle({
      backgroundColor: '#F5922F',
      color: '#FFF'
    })
  }

  const handleMouseLeave = () => {
    setBtnStyle({
      backgroundColor: '#FFF',
      color: '#F5922F'
    })
  }

  return (
    <Modal 
      visible={visible} 
      bodyStyle={styles.root} 
      footer={null}
      centered
      width={360}
      onCancel={closeModal}
    >
      <Typography style={styles.title}>Generation Result</Typography>
      <div style={{padding: '12px 0'}}>
        <Typography>No. of success: <strong>{data&&data.success.length}</strong></Typography>
        <Typography>No. of errors: <strong>{data&&data.errors.length}</strong></Typography>
      </div>
      {data&&data.success.length>0&&data.success.map((item, index) => 
      <Typography style={{...styles.msg, backgroundColor: 'rgb(232, 250, 243)'}}>{item}</Typography>)}
      {data&&data.success.length>0&&<br />}
      {data&&data.errors.length>0&&data.errors.map((item, index) => 
      <Typography style={{...styles.msg, backgroundColor: 'rgb(254, 242, 231)'}}>{item}</Typography>)}
      <div style={styles.mainBtn}>
        <Button style={{...styles.btn, borderColor: '#F5922F'}}onClick={closeModal}>
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 24,
    color: '#2B2D33',
    fontWeight: '300',
    textAlign: 'left',
    padding: '24px 0 4px 0'
  },
  msg: {
    fontSize: 14,
    color: '#000',
    textAlign: 'left',
    padding: '6px 12px'
  },
  mainBtn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
  },
  btn: {
    height: 48,
    width: '45%',
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 10,
    color: '#FFF', 
    backgroundColor: '#F5922F'
  },

}

export default NotifModal;