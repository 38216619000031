import { PayoutActionTypes } from './payoutsAction'

const initialState = {
    payouts: [],
    loading: false,
    error: ""
}

export default function payoutsAllReducer(state = initialState, action){
    switch(action.type){
        case PayoutActionTypes.PAYOUT_REQUEST:
            return{
                ...state,
                loading:true
            };
        case PayoutActionTypes.PAYOUT_SUCCESS:
                console.log(action.payload)
            return{
                ...state,
                loading: false,
                payouts: action.payload
            };
        case PayoutActionTypes.PAYOUT_FAILED:

            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return initialState;
    }
}