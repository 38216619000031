import React, {useEffect} from 'react'
import {isMobile} from 'react-device-detect'
import PluginsMain from '../../components/integration/plugins_tab/plugins_main'
import TestModeBanner from '../../components/cards/test_mode/testModeBanner';
import MetaTag from '../../components/meta_tag/meta_tag'
import axios from 'axios'
import {Handle401} from '../../components/handle401/handle401'
import {history} from '../../store/history'
import ApiPortalCard from '../../components/integration/plugins_tab/api_portal_card';
import { useSelector } from 'react-redux';

const IntegrationMain = () =>{
    
    useEffect(()=>{
        if(isMobile){
            history.push('/mobile/integration')
        }
        else if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "SW"){
            history.push('/dashboard')
        }
        fetchAccessToken()
    },[])


    const [creds, setCreds] = React.useState({})

    const isMacPro13 = window.screen.width * window.devicePixelRatio >= 2304 && window.screen.height * window.devicePixelRatio >= 1440;
    const endpoints = useSelector(state => state.child_endpoints.endpoints);
    const isChild = localStorage.getItem("is_child") === "true"

    const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
    const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
    const isDell1 = window.screen.width * window.devicePixelRatio === 1366 && window.screen.height * window.devicePixelRatio === 768;
    const isMac = window.screen.width * window.devicePixelRatio === 1440 && window.screen.height * window.devicePixelRatio === 900;

    const isDellReso = (isSmallReso || isDell || isDell1)
    const segments = localStorage.getItem("segments");


    React.useEffect(() => {
        if (!isChild || !endpoints.length) {
          return;
        }
    
        // Redirect if URL was manually typed
        if(!endpoints.includes('integration')) {
          history.push(endpoints[0]);
        }
    }, [endpoints])


    async function fetchAccessToken(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try{
            let response = await axios.get(
                process.env.REACT_APP_API_URL + '/api/fetch_user_credential/', yourConfig
            );
    
            if(response.data.status == "success"){
                setCreds(response.data)
            }
            else{
                return false
            }
        }
        catch (error) {
            if((error.response)&&(error.response.status == 401)){
                Handle401()
            }
        }
        
    }

    // If endpoints have not been loaded yet for mobile view. Hook on endpoints will handle redirect
    if (isChild && !endpoints.includes('integration')) {
        return null;
    }


    return(
        <div className={isDell ? "top-12" : "top-48"} style={{marginRight: isDellReso ? '2vw' : isMac ? '5vw' : isMobile ? 0 : '10vw', padding: isMobile ? 20 : 0, backgroundColor: isMobile && '#F4F6F9'}} >
            <MetaTag title="Integration"/>
            <TestModeBanner width={'100vw'} paddingLeft={isMacPro13?'12%':0} top={64} right={0} zIndex={100} />
            <div align="center">
                <ApiPortalCard segments={segments} />
                <PluginsMain segments={segments} creds={creds} resetSecretSuccess={fetchAccessToken}/>
            </div>
        </div>
    )
}

export default IntegrationMain