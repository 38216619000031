import React from 'react';
import {Progress, Icon} from 'antd';
import complete_icon from "../../../static/icons/sidebar/complete_profile.svg";
import profile from "../../../static/icons/profile.svg";
import w_profile from "../../../static/icons/white_profile.svg";
import {history} from '../../../store/history';
import {Style} from './style';

const CorporateHeader = props => {

    const path = window.location.pathname;
    const {username, profilePicture, tradeName, kybStep, fname, lname, userType, subuserRole, is_child} = props.datas;
    const { collapsed, testMode } = props;
    const segments = localStorage.getItem('segments');
    const kybRequired = localStorage.getItem('kybRequired') == 'true';

    return (
        <div className={`${testMode ? "testColor" : "liveColor"}`}>
            <div style={{display: 'flex'}}>
                <a onClick={() => !testMode && history.push('/my_profile')}>
                    {
                        profilePicture ? 
                            <img src={profilePicture} style={{...Style.profilePic, marginTop: testMode ? 10 : 20}} alt="profile" />:
                            <img src={testMode ? w_profile : profile} style={{marginTop: 14, width: 40}} alt="profile" />
                    }
                </a>
                <div className={`${!tradeName ? "nameDiv" : "nameDivCorp"}`}hidden={collapsed}>
                {
                    fname && lname !== "" && fname !== null && fname !== undefined ?
                        <span>
                            <div className="nameStyle" style={{wordBreak: (username && username.search(' ')) ? 'normal' : 'break-all', maxWidth: '154px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>
                                {tradeName && tradeName !== null && tradeName !== "" && tradeName}
                            </div>
                            <div className="userNameStyle" style={{maxWidth: '154px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',}}>{(fname || '') + " " + (lname || '')}</div>
                          
                        </span>
                        :
                        <span
                            hidden={collapsed} 
                            style={{
                            wordBreak: (username && username.search(' ')) ? 'normal' : 'break-all'}}>
                            <div className={`nameStyle`}
                                style={{
                                    maxWidth: '154px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap' 
                                }}
                            >{username && username !== null? username : "" }</div>
                            <div className={`userNameStyle`} style={{wordBreak: (username && username.search(' ')) ? 'normal' : 'break-all', maxWidth: '154px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',}}>
                                {tradeName && tradeName !== null && tradeName !== "" && tradeName}
                            </div>
                        </span>
                }
                    <a hidden={testMode} className={`link-text--${segments}`} style={{ fontWeight: '400', fontSize: 12 }} onClick={() => !testMode && history.push('/my_profile')}>
                       View my business profile     
                    </a>
                </div>
            </div>
            <div style={{display: userType == 'SW' && subuserRole == 'CO' && kybStep == 0 && !is_child && kybRequired ? 'block' : 'none'}}>
                <div
                    hidden={collapsed} 
                    style={{
                        marginTop: 8,
                        textAlign: 'left', 
                    }}>
                    <div className="twoCol">
                        <div>
                            <div className={`normalTextStyle`}>
                            Complete Profile
                            </div>
                            <div className={`smallBoldStyle`}>
                                <div>Step {kybStep} of 6</div>
                            </div>
                        </div>
                        <div>
                            <Icon type="right" className="iconRight" onClick={()=>history.push('/upgrade_enterprise')}/>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: path === '/upgrade_enterprise' || kybStep == 0 || testMode  || !kybRequired ? 'none' : kybStep < 7 && path != '/upgrade_enterprise' ? 'block' : 'none'}}>
                <div
                    hidden={collapsed} 
                    style={{
                        marginTop: 8,
                        textAlign: 'left', 
                    }}>
                    <div className="twoCol">
                        <div>
                            <div className={`normalTextStyle`}>
                            Complete Profile
                            </div>
                            <div className={`smallBoldStyle`}>
                                <div>Step {kybStep} of 6</div>
                            </div>
                        </div>
                        <div>
                            <Icon type="right" className="iconRight" onClick={()=>history.push(kybStep != 0 ? '/upgrade_enterprise' : '/complete_profile')}/>
                        </div>
                    </div>
                </div>

                <div 
                    hidden={!collapsed}
                    style={{marginTop: 8}}>
                    
                    <div className="twoCol">
                        <img src={complete_icon} alt="complete" />

                        <div className={`smallBoldStyle`}>
                            <div>{kybStep}/6</div>
                        </div>
                    </div>

                </div>
            </div>

            <a 
            className="completeText"
            href
            hidden={collapsed}
            style={{
                display: testMode? 'none' : kybStep > 7 && kybStep <= 10 ? 'block' : kybStep == 11 ? 'none' : 'none'
            }} onClick={()=>history.push('/upgrade_enterprise')}>Upgrade Status</a>
        </div>
    );
};

export default CorporateHeader;