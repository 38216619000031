const initialState = {
  startDemo: false,
  startModal: false,
  demoStep: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DEMO_STEP':
      return {
        ...state,
        demoStep: action.payload,
      }
      case 'SET_START_DEMO':
        return {
          ...state,
          startDemo: action.payload,
        }
      case 'SET_TUTORIAL_START_MODAL':
        return {
          ...state,
          startModal: action.payload,
        }
    default:
      return state;
  }
}

