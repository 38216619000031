import { Card, Typography } from "antd";
import React from "react";

export function SummaryCard({ title, text }) {
  return (
    <Card>
      <Typography style={{ fontSize: "16px" }}>{title}</Typography>
      <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
        {text}
      </Typography>
    </Card>
  );
}
