import { useEffect, useState } from "react";
import { axios } from "../../../lib/axios";

/**
 * @typedef {Object} FundTransfer
 * @property {number} id
 * @property {number} requestor_id
 * @property {string} requestor_name
 * @property {string} requestor_email
 * @property {number} receiver_id
 * @property {string} receiver_name
 * @property {string} receiver_email
 * @property {number} approver_id
 * @property {string} approver_name
 * @property {string} approver_email
 * @property {string} status
 * @property {string} funds_value
 * @property {string} created_at
 */

/**
 * @typedef {Object} FundTransfersData
 * @property {string} merchant_group_name
 * @property {number} total
 * @property {number} page
 * @property {number} pages
 * @property {FundTransfer[]} results
 */

/**
 * Fetches fund transfers from the API.
 * @param {number} currentPage
 * @returns {Promise<FundTransfersData>}
 */
export function getFundTransfers(currentPage) {
  return axios.get(`api/transfer/request/history/?page=${currentPage}`);
}

/**
 * Custom hook to fetch fund transfers.
 * @returns {{
 *   data: FundTransfersData | null,
 *   isLoading: boolean,
 *   error: Error | null
 * }}
 */
export function useFundTransfers(currentPage) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getFundTransfers(currentPage);
        setData(response);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, [currentPage]);

  return { data, isLoading, error };
}
