import React from 'react'
import {Grid, Tooltip} from '@material-ui/core'
import { Modal, Input } from 'antd'
import accountStyles from '../../../static/css/Account'

const CustomAmountModal = props => {

    const ac = accountStyles();

    const userType = localStorage.getItem("userType")
    const maxAmount = process.env.REACT_APP_MAXIMUM_AMOUNT ? process.env.REACT_APP_MAXIMUM_AMOUNT : 30000
    // const entMaxAmount = process.env.REACT_APP_MAXIMUM_AMOUNT ? process.env.REACT_APP_MAXIMUM_AMOUNT : 80000
    const minAmount = process.env.REACT_APP_MINIMUM_AMOUNT ? process.env.REACT_APP_MINIMUM_AMOUNT : 50
    const {
        customModal, setCustomModal, listAmount, setListAmount,
        removeIcon, gettingParams, getDetails
    } = props

    const [error, setError] = React.useState([true, '', ''])

    const addAmount = () => {
        setListAmount([...listAmount, "50"])
    }

    const removeAmount = (index) => {
        const list = [...listAmount]
        list.splice(index, 1)
        setListAmount(list)
    }

    const handeleChangeAmount = (e, index) => {

        const { value } = e.target
        var numbers = /^[0-9]+$/;
        const list = [...listAmount]

        if(value.length <= 5) {
            list[index] = value
        }

        setListAmount(list)
        
        for(let i=0;i<list.length;i++){
            if(userType == "ME" && (parseFloat(list[i]) < minAmount || parseFloat(list[i]) > maxAmount)) {
                setError([false, 'Amount must be PHP 50 up to PHP 30, 000'], index)
                console.log(error)
                return
            }
    
            else if (userType == "CO" && parseFloat(list[i]) < minAmount) {
                setError([false, 'Amount must be PHP 50 up to PHP 80, 000'], index)
                console.log(error)
                return
            }

            // else if (!numbers.test(list[i])){
            //     setError([false, 'This field must numbers only', index])
            //     return
            // }

            else {
                setError([true, '', ''])
            }
        }
    }

    return (
        <Modal visible={customModal}
        onCancel={()=>{setCustomModal(false);getDetails()}}
        footer={null}>
            <div align="center">
                <h1 style={{marginTop: '32px'}}>Custom Amount List</h1>
                <Grid container>
                {
                    listAmount.map((item, key) => {
                        return(
                            <Grid item lg={6}>
                                <div align='left'>
                                    <div className={ac.fieldPlacehold}>
                                        Amount {key+1}
                                    </div>
                                    <div style={{width: '98%'}}>
                                        <Input 
                                            size="large"
                                            style={{height: '40px'}} 
                                            type="number"
                                            name="amount" value={item}
                                            addonBefore={'PHP'} 
                                            suffix={
                                                <Tooltip title="Remove" placement="top" hidden={key === 0}>
                                                    <a onClick={() => removeAmount(key)}>
                                                        <img src={removeIcon} />
                                                    </a>
                                                </Tooltip>
                                            }
                                            onChange={(e) => handeleChangeAmount(e, key)}/>
                                        <div style={{color: 'red'}} hidden={error[0] || error[2] != key}>{error[1]}</div>
                                    </div>
                                </div>
                            </Grid>
                        )
                    })
                }

                    <Grid item lg={6} hidden={listAmount.length >= 8}>
                        <div align='left'>
                            <div className={ac.fieldPlacehold}>
                                Amount {listAmount.length + 1}
                            </div>
                            <button onClick={() => addAmount()} className={ac.newAmountBtn}>+ Add New Amount</button>
                        </div>
                    </Grid>
                </Grid>

   
                <div style={{marginTop: 16}}>
                    <button className={ac.createcustomListBtn} 
                    disabled={!error[0]}
                    style={{opacity: !error[0] ? 0.5 : '', cursor: !error[0] ? 'not-allowed' : ''}}
                    onClick={() => gettingParams() }>
                        Save
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default CustomAmountModal;

