import React from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import ShareQrLink from '../../generate_drawer/share_qr_link'
import {history} from '../../../store/history'
import qrServices from './qr_services/qrServices'

const testMode = history.location.pathname.indexOf('/test') > -1;

const ShareQrModal = props => {

    const {
        showShareModal, closeShareModal,
    } = props

    const isChild = localStorage.getItem("is_child") == "true" || localStorage.getItem("is_child") == true

    const REACT_APP_API_URL = process.env.REACT_APP_API_URL
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL

    const [tradeName, setTradeName] = React.useState("")
    // const qrLink = testMode ? REACT_APP_BASE_URL+'/'+tradeName+'/test' : REACT_APP_BASE_URL+'/'+tradeName
    const REACT_APP_ROOT_URL = process.env.REACT_APP_ROOT_URL;

    // const qrLink = testMode ? 'https://' + tradeName + '.' + REACT_APP_ROOT_URL+'/test/qr' : 'https://' + tradeName + '.' + REACT_APP_ROOT_URL;
    
    const [data, setData] = React.useState({})
    let remove_part = `.${process.env.REACT_APP_SUB_URL}`;
    const master_trade_name = window.location.hostname.replace(remove_part, "");
    const userRole = localStorage.getItem("userType");
    let sub_remove_part = window.location.protocol+'//';
    const protocol = window.location.protocol;
    const subQRCheckoutUrlLink = `${REACT_APP_BASE_URL}/mycheckout/${localStorage.getItem('tradeName')}`;
    const subQrCheckoutUrl = protocol+'//'+master_trade_name+'.'+subQRCheckoutUrlLink.replace(sub_remove_part, "")
    const mainCheckoutUrl = `${REACT_APP_BASE_URL}/${tradeName}`;
    const testmodeCheckoutUrl = `${REACT_APP_BASE_URL}/test/QR/${tradeName}`;

    const qrLink = userRole  === 'SW'? subQrCheckoutUrl : testMode? testmodeCheckoutUrl : mainCheckoutUrl;

    const notFound = REACT_APP_BASE_URL+'/404/'
    const level = localStorage.getItem("Level")
    const isSubCo = userRole === "SW" && localStorage.getItem('subuser_role') === "CO";

    const [qrOn, setQrOn] = React.useState(false)

    async function getDetails () {
        try {

            let response
            let tradeName = testMode ? localStorage.getItem('SbTradeName') : localStorage.getItem('tradeName')

            if(isChild){
                response = await qrServices.getQrDetails(testMode, tradeName)

            }
            else{
                response = await qrServices.getQrDetails(testMode)
            }
            
            if(response.data.status === 'failed') {
                message.error(response.data.message, 3)
                setQrOn(false)
            }

            else {
                setData(response.data)
                setQrOn(response.data.qr_enabled)
                setTradeName(response.data.trade_name)
            }
        }

        catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        if(showShareModal) {
            if((level > 0 && localStorage.getItem("tradeName") != "") || testMode) {
                getDetails()
            }
        }
    }, [showShareModal])

    return(
        <Modal 
            visible={showShareModal}
            onCancel={() => closeShareModal()}
            footer={null}
            style={{overflow: 'hidden'}}>
            <div style={{padding: '0px 48px 0px 48px'}}>
                <ShareQrLink qrOn={qrOn} isSubCo={isSubCo} qrLink={qrLink} notFound={notFound} data={data}/>
            </div>
        </Modal>
    )
}

export default ShareQrModal