import React from "react";
import "./Drawer.css";

export function Drawer({ title, children, isOpen, onClose }) {
  return (
    <div className={`drawer ${isOpen ? "open" : ""}`}>
      <div className="overlay" onClick={onClose}></div>
      <div className="drawer-content">
        <div className="drawer-header">
          <h2
            style={{
              fontSize: "18px",
              lineHeight: "28px",
              fontWeight: "700",
              color: "rgb(43, 45, 50)",
            }}
          >
            {title}
          </h2>
          <button className="close-btn" onClick={onClose}>
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              class=""
              data-icon="close"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
            </svg>
          </button>
        </div>
        <div className="drawer-body">{children}</div>
      </div>
    </div>
  );
}
