import React, {useEffect} from 'react'
import axios from 'axios'
import {Divider, message} from 'antd'
import { history } from '../../../store/history'

const CustomerOrderDetails = ({details}) => {

    const testMode = (history.location.pathname.indexOf('/test') > -1);
    console.log(details)

    const displaySubtotal = () => {
        if (!testMode && details.seller_net) {
            return (parseFloat(details.seller_net).toLocaleString("en-US", {minimumFractionDigits: 2}))
        }
        return parseFloat(details.amount).toLocaleString("en-US", {minimumFractionDigits: 2})
    }

    const displayTranxFee = () => {
        if (!testMode) {
            return parseFloat(details.total_fee).toLocaleString("en-US", {minimumFractionDigits: 2})
        }
        return 0.00
    }

    const computeTotal = () => {
        const generatedAmount = (details && details.seller_net ? parseFloat(details.seller_net + details.total_fee) : parseFloat(details.amount));
        if(!testMode && details && details.promo_code) {
            const totalDeductionAmount = parseFloat(details.promo_discount);
            const promoMinimumPurchase = parseFloat(details.promo_minimun_purchase);
    
            if ( generatedAmount - totalDeductionAmount < promoMinimumPurchase ) {
                return (promoMinimumPurchase).toLocaleString("en-US", {minimumFractionDigits: 2});
            } 
             return (generatedAmount - totalDeductionAmount).toLocaleString("en-US", {minimumFractionDigits: 2});
        }
        return (generatedAmount).toLocaleString("en-US", {minimumFractionDigits: 2});
    }

    return(
        <div>
            {
                ((!testMode && details) || (testMode && details)) && 
                <div style={{paddingTop: '30px'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={style.header}>Customer Payment Details</div>
                        <div style={{
                            height: '34px',
                            borderRadius: '4px',
                            color: '#2b2d33',
                            fontSize: '12px',
                            textAlign:'center',
                            padding:'7px 10px 0px 10px',
                            border: details.status == 'PE' ? '1px solid #F5922F' :
                                    details.status == 'PA' ? '1px solid #1DD28B' : 
                                    details.status == 'CA' ? '1px solid #E24C4C' : '1px solid #909196',
                            backgroundColor: details.status == 'PE' ? 'rgba(245, 146, 47, 0.1)' :
                                            details.status == 'PA' ? 'rgba(29, 210, 139, 0.1)' : 
                                            details.status == 'CA' ? 'rgba(226, 76, 76, 0.1)' : 'rgba(144, 145, 150, 0.1)',
                            borderLeft: details.status == 'PE' ? '4px solid #F5922F' :
                                        details.status == 'PA' ? '4px solid #1DD28B' : 
                                        details.status == 'CA' ? '4px solid #E24C4C' : '1px solid #909196',
                        }}>
                            {
                                details.status == 'PE' ? 'Pending' :
                                details.status == 'PA' ? 'Paid' : 
                                details.status == 'CA' ? 'Cancelled' : 'Expired'
                            }
                        </div>
                    </div>
                    <div style={{...style.text1, marginTop: '5px'}} hidden={!details.code}>Reference No: <b>{details.code}</b></div>
                    <div style={{...style.text1, marginTop: '5px'}}>{details.description}</div>
                    <div style={style.infoCard}>
                        <div style={{...style.text2, margin:'0px 0px 10px 12px'}}><b>Customer Information</b></div>
                        <div style={style.insideCard}>
                            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom:'8px'}}>
                                <div style={style.text2}>Name:</div>
                                <div style={{...style.text2, fontWeight: '500'}}>{details.buyer_name}</div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom:'8px'}}>
                                <div style={style.text2}>Email Address:</div>
                                <div style={{...style.text2, fontWeight: '500'}}>{details.buyer_email}</div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div style={style.text2}>Mobile Number:</div>
                                <div style={{...style.text2, fontWeight: '500'}}>{details.buyer_phone}</div>
                            </div>
                        </div>
                    </div>
                    <div style={style.infoCard} hidden={!details.payload['param1']}>
                        <div style={{...style.text2, margin:'0px 0px 10px 12px'}}><b>Additional Information</b></div>
                        <div style={style.insideCard}>
                            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom:'8px'}}>
                                <div style={style.text2}>{details.payload['param1'] && details.payload['param1'].name}:</div>
                                <div style={{...style.text2, fontWeight: '500'}}>{details.payload['param1'] && details.payload['param1'].value}</div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom:'8px'}} hidden={(details.payload['param2'] && details.payload['param2'].name == '') || !details.payload['param2']}>
                                <div style={style.text2}>{details.payload['param2'] && details.payload['param2'].name}:</div>
                                <div style={{...style.text2, fontWeight: '500'}}>{details.payload['param2'] && details.payload['param2'].value}</div>
                            </div>
                        </div>
                    </div>
                    {/* <div style={style.infoCard} hidden={details.status != 'CA'}>
                        <div style={{...style.text2, margin:'0px 0px 10px 12px'}}><b>Reason</b></div>
                        <div style={style.insideCard}>
                            <div style={style.text1}><b>{details.remarks}</b></div>
                        </div>
                    </div> */}
                    <div style={{padding:'20px 20px 0px 20px'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom:'8px'}}>
                            <div style={style.text2}>Date Generated:</div>
                            <div style={{...style.text2, fontWeight: '500'}}>{details.timestamp}</div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom:'8px'}}>
                            <div style={style.text2}>{
                                details.status == 'PE' ? 'Date Expiry:' :
                                details.status == 'PA' ? 'Date Paid:' : 
                                details.status == 'CA' ? 'Date Cancelled:' : 'Date Expired:'
                            }</div>
                            <div style={{...style.text2, fontWeight: '500'}}>{
                                details.status == 'PE' ? details.expire_at :
                                details.status == 'PA' ? details.date_paid : 
                                details.status == 'CA' ? details.cancelled_at : details.expire_at
                            }</div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom:'8px'}} hidden={details.channel == '' || !details.channel}>
                            <div style={style.text2}>Channel:</div>
                            <div style={{...style.text2, fontWeight: '500'}}>{details.channel_str}</div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom:'8px'}}>
                            <div style={style.text2}>Sub Total:</div>
                            <div style={{...style.text2, fontWeight: '500'}}>
                                ₱{
                                    displaySubtotal()
                                }
                            </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom:'8px'}} hidden={details.channel == '' || !details.channel}>
                            <div style={style.text2}>Transaction Fee:</div>
                            <div style={{...style.text2, fontWeight: '500'}}>
                                ₱{
                                    displayTranxFee()
                                }
                            </div>
                        </div>
                        <Divider/>
                        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom:'8px'}}>
                            <div style={{fontSize: '20px', color: '#2b2d33'}}>Total:</div>
                            <div style={{fontSize: '24px', color: '#2b2d33', fontWeight: 'bold'}}>₱{computeTotal()}</div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

const style = {
    header: {
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '32px',
        color: '#000'
    },
    text1: {
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000'
    },
    text2: {
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2b2d33'
    },

    infoCard: {
        backgroundColor: '#E6EAF0',
        borderRadius: '4px',
        padding: '9px 2px 2px 2px',
        marginTop: '20px'
    },
    insideCard: {
        backgroundColor: '#fff',
        borderRadius: '0px 0px 4px 4px',
        padding: '20px 12px 20px 12px',
    }
}

export default CustomerOrderDetails