import React from 'react'
import axios from 'axios';
import {Input, Icon, Table, Button, Modal, Alert, Dropdown, Menu, message, Switch} from 'antd'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {TextField, Grid, makeStyles} from '@material-ui/core';
import { useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {history} from '../../../store/history'
import '../../../static/css/antd.css'
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { Handle401 } from '../../handle401/handle401';


const PartnersView = props => {
  
    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = React.useState(false);
    const [visible, setVisible] = React.useState(true);
    const [showPassword, setShowPassword] = React.useState([]);
    const [userDetails, setUserDetails] = React.useState({});
    const [searchValue, setSearchValue] = React.useState("");
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertVisibility, setAlertVisibility] = React.useState(false);
    const [submissions, setSubmissions] = React.useState([]);
    const [partner_name, setName] = React.useState("");
    const [partner_code, setCode] = React.useState("");
    const [ready, setReady] = React.useState(false);


    const useStyles = makeStyles(theme => ({
      container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginRight: '100%',
        width: 230,
      },
    }));


    const classes = useStyles();

    const handleOpenModal = () => {
      setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const info = () => {
      message.success("Successful");
    };


    const openModal = () => {
    setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const update = () => {


              
          let info = {
              name: partner_name,
              code: partner_code
          };

          updateSettings(info)
          console.log(info)

    }

    
    async function updateSettings (value) {
        let yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }
        try {
        let response = await axios.post(
          process.env.REACT_APP_API_URL + '/api/partners/add/', value,yourConfig
        );
  
        console.log(response)
  
        if(response.data.status == "success"){
            msgNotif(response.data.message)
            window.location.reload()
        }
        else{
          setVisible(false)
          msgNotif(response.data.message)
        }
      } catch (error) {
        if(error.response.data && error.response.data.message){
          msgNotif(error.response.data.message);
        } else {
          msgNotif('Error try again');
        }
      }
    }

    const msgNotif = (msg) => {
      if( msg && msg.data && msg.data.status === "success") {
        message.success(msg.data.message);
      } else if(msg && msg.data && msg.data.status === "failed") {
        message.error(msg.data.message);
      } else {
        message.error(msg);
      }
    }

    useEffect(() => {

      if(localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") == null){
        history.push('/login')
      }
      else{
        if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
          history.push('/dashboard')
        }
        else if(localStorage.getItem("userType") == "PS"){
          history.push('/orders')
        }
        else if(localStorage.getItem("userType") == "PF"){
          history.push('/recon/7_eleven')
        }
        else if(localStorage.getItem("userType") == "MA"){
          history.push('/overview')
        }
        else{
          fetchSubmissions()
        }
        
      }
      
    }, [])

    const orderStyle = {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '40px'
      }
    const menuStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33',
        padding: '10px',
        margin: 'auto'
    }



    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
        },
        {
          title: 'Partner Code',
          dataIndex: 'code',
        },
        {
          title: 'Client ID',
          dataIndex: 'client_id',
        },
        {
          title: 'Client Secret',
          dataIndex: 'client_secret',
          render: (text, record) => (
            <div>
                    <TextField
                        id="outlined-adornment-password"
                        variant="outlined"
                        style = {{
                            height: '30px',
                            width: '100%',
                            marginBottom: '8%'
                        }}
                        type={showPassword.includes(record.id) ? 'text' : 'password'}
                        value={text}
                        readonly
                        label=""
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        aria-label="toggle password visibility"
                                        onClick={e => {let x = showPassword.slice();
                                                        if(x.includes(record.id)){
                                                              x.pop(record.id)
                                        }else{
                                          x.push(record.id)
                                        }
                                        console.log(x)
                                        setShowPassword(x)
                                        }}
                                    >
                                        {showPassword.includes(record.id) ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                    </InputAdornment>
                                ),
                                }}
                            />
            </div>
          )
        }
      ];

    
    async function fetchSubmissions () {

      
        let yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }

        try{
          let response = await axios.get(
            process.env.REACT_APP_API_URL + '/api/partners/',
            yourConfig
          );
    
          setSubmissions(response.data)
          setReady(true)
        }

        catch(error){
          if((error.response)&&(error.response.status == 401)){
            Handle401()
          }
        }
  
        
    }

    return(
        <div style={{ paddingTop: '38px', paddingLeft: '42px', paddingRight: '42px' }} hidden={!ready}>
            <div style={{marginBottom: '20px'}}><span style={orderStyle}>Partners</span></div> 

            <Button onClick={handleOpenModal} type="primary" shape="round" icon="plus" style={{ marginBottom: '25px'}}>
              Add Partner
            </Button>

            <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
            dataSource={submissions}
            columns={columns} />

            <Modal
                title="Add Partner"
                centered
                visible={modalOpen}
                //onOk={}
                onCancel={closeModal}
                footer={[
                    <Button key="back" onClick={closeModal}>
                      Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={update}>
                      Update
                    </Button>,
                  ]}
                >
                    

                    <TextField
                        id="outlined-basic"
                        label="Name"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        value={partner_name}
                        className={classes.textField}
                        onChange={ e => setName(e.target.value)}

                        />

                    <TextField
                        id="outlined-basic"
                        label="Code"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        value={partner_code}
                        className={classes.textField}
                        onChange={ e => setCode(e.target.value)}

                        />
                </Modal>

        </div>
    )
    
}

export default PartnersView;