import React from 'react'
import {Layout, Icon, Input, Card, Alert, DatePicker} from 'antd'
import {useSelector, useDispatch} from 'react-redux'
import * as Style from '../orders/mobile_order_style'
import { getSinglePayouts } from '../../funds/fundsAction';
import moment from 'moment'
import MetaTag from '../../meta_tag/meta_tag'
import {history} from '../../../store/history'

const SearchPayout = props => {

    const {Header} = Layout
    const { MonthPicker, RangePicker } = DatePicker;

    const dispatch = useDispatch();

    const single_payouts  = useSelector(state => state.funds.single_payouts)
    const [searchValue, setSearchValue] = React.useState(false)
    const [orderType, setOrderType] = React.useState(props.match.params.payoutType)
    const [searchType, setSearchType] = React.useState(props.location.search_type)

    const [startMoment, setStartMoment] = React.useState("")
    const [startDate, setStartDate] = React.useState("")

    const [endMoment, setEndMoment] = React.useState("")
    const [endDate, setEndDate] = React.useState("")

    const [endOpen, setEndOpen] = React.useState("")

    const orderDetailsStyle = {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        color: '#000000',
        textAlign: 'center'
    }

    const disabledStartDate = startValue => {
      
        if (!startValue || !endMoment) {
          return false;
        }
        return startValue && startValue > moment().endOf('day');
    };
    
    const disabledEndDate = endValue => {
        
        if (!endValue || !startMoment) {
          return false;
        }
        return endValue.valueOf() <= startMoment.valueOf();
    };
      
    const onStartChange = value => {
        setStartMoment(value)
        setStartDate(value.format("MM-DD-YYYY"))
    }
    const onEndChange = value => {
        setEndMoment(value)
        setEndDate(value.format("MM-DD-YYYY"))
        dispatch(getSinglePayouts(startDate, value.format("MM-DD-YYYY")))
    }
  
    const handleStartOpenChange = open => {
        if (!open) {
          setEndOpen(true)
        }
    };
    
    const handleEndOpenChange = open => {
        setEndOpen(open)
    };

    const SearchPayout = (val) => {
    
    let filtered_by_type = []

    if(orderType != "All"){
            
        filtered_by_type = single_payouts.filter(single_payout => single_payout.status.toLowerCase().includes(orderType.toLowerCase()))
    }
    else{
        filtered_by_type = single_payouts
    }

    if(single_payouts == null){
        window.location.href = '/mobile/funds'
    }

    if((val!="")&&(val!=null))
    {
                    
        let filtered_single_payout = filtered_by_type.filter(single_payout => single_payout.account_number.toLowerCase().includes(val.toLowerCase()) || 
                                    single_payout.account_name.toLowerCase().includes(val.toLowerCase()) || 
                                    single_payout.bank.toLowerCase().includes(val.toLowerCase()))

        return (
            filtered_single_payout.length > 0 && filtered_single_payout.map((item, i) => {
                return (
                    <Card 
                    style={{marginBottom: '10px', border: '1px solid #d1d5dd', borderRadius: '10px'}}
                    headStyle={{paddingRight: '16px', paddingLeft: '16px'}}
                    bodyStyle={{padding: '16px'}}
                    title={
                        <div>
                            <div style={Style.bankStyle}>{item.bank}</div>
                            <div style={Style.bankStyle}>{item.account_number}</div>
                            <div style={{...Style.emptyChannelStyle, marginTop: '5px'}}>{item.account_name}</div>
                        </div>
                    }
                >
                    <div style={{display: 'flex', justifyContent:'space-between'}}>
                        <div 
                            align="center"
                            style={{
                                height: '32px', 
                                width: '132px',
                                borderRadius: '4px',
                                border: item.status.includes("Success") ? "1px solid #1DD28B" : item.status.includes("Pending") ? "1px solid #F5922F" : "1px solid #E24C4C",
                                backgroundColor: item.status.includes("Success") ? "rgba(29, 210, 139, 0.1)" : item.status.includes("Pending") ? "rgba(245, 146, 47, 0.1)" : "rgba(226, 76, 76, 0.1)",
                                borderLeft: item.status.includes("Success") ? "5px solid #1DD28B" : item.status.includes("Pending") ? "5px solid #F5922F" : "5px solid #E24C4C"
                            }}>
                            <div style={{fontSize: '12px',lineHeight:'32px', color: '#2b2d33'}}>
                            {item.status}
                            </div>
                        </div>
                        <div style={Style.bankStyle}>
                            {item.amount}
                        </div>
                    </div>
                    <div style={{marginTop: '10px'}} hidden={item.status.includes("Pending")}>
                        <div style={Style.expireStyle} hidden={!item.status.includes("Failed")}>
                            {item.remarks}
                        </div>
                        <div hidden={!item.status.includes("Success")}>
                            <div style={Style.bankStyle}>
                            Transfer ID
                            </div>
                            <div style={Style.emptyChannelStyle}>
                            {item.remarks}
                            </div>
                        </div>
                    </div>
                </Card>
                )
            },this)
        )
        
    }

    else{
        return (
            filtered_by_type.length > 0 && filtered_by_type.map((item, i) => {
                    return (
                        <Card 
                            style={{marginBottom: '10px', border: '1px solid #d1d5dd', borderRadius: '10px'}}
                            headStyle={{paddingRight: '16px', paddingLeft: '16px'}}
                            bodyStyle={{padding: '16px'}}
                            title={
                                <div>
                                    <div style={Style.bankStyle}>{item.bank}</div>
                                    <div style={Style.bankStyle}>{item.account_number}</div>
                                    <div style={{...Style.emptyChannelStyle, marginTop: '5px'}}>{item.account_name}</div>
                                </div>
                            }
                        >
                        <div style={{display: 'flex', justifyContent:'space-between'}}>
                            <div 
                                align="center"
                                style={{
                                    height: '32px', 
                                    width: '132px',
                                    borderRadius: '4px',
                                    border: item.status.includes("Success") ? "1px solid #1DD28B" : item.status.includes("Pending") ? "1px solid #F5922F" : "1px solid #E24C4C",
                                    backgroundColor: item.status.includes("Success") ? "rgba(29, 210, 139, 0.1)" : item.status.includes("Pending") ? "rgba(245, 146, 47, 0.1)" : "rgba(226, 76, 76, 0.1)",
                                    borderLeft: item.status.includes("Success") ? "5px solid #1DD28B" : item.status.includes("Pending") ? "5px solid #F5922F" : "5px solid #E24C4C"
                                }}>
                                <div style={{fontSize: '12px',lineHeight:'32px', color: '#2b2d33'}}>
                                {item.status}
                                </div>
                            </div>
                            <div style={Style.bankStyle}>
                                {item.amount}
                            </div>
                        </div>
                        <div style={{marginTop: '10px'}} hidden={item.status.includes("Pending")}>
                            <div style={Style.expireStyle} hidden={!item.status.includes("Failed")}>
                                {item.remarks}
                            </div>
                            <div hidden={!item.status.includes("Success")}>
                                <div style={Style.bankStyle}>
                                Transfer ID
                                </div>
                                <div style={Style.emptyChannelStyle}>
                                {item.remarks}
                                </div>
                            </div>
                        </div>
                    </Card>
                    )
                },this)
            )
        }
    }
    return(
        <div style={{backgroundColor: '#f4f6f9', height: '100vh'}}>
            <MetaTag title="Search Payout"/>

            <Header style={{width: '100%', height: '60px', backgroundColor: '#fff', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)'}}>
                    <div style={orderDetailsStyle}>Search payout</div>
                    <Icon type="close" style={{float: 'right', marginTop: '-40px'}} onClick={()=>history.push('/mobile/funds')}/>
            </Header>
            <div style={{padding:'20px'}}>
                {
                  searchType == "search" ? 
                  <Input size="large" prefix={<Icon type="search" size="large"/>} onChange={(e)=>setSearchValue(e.target.value)} block/>
                  : searchType == "date" ?
                  <div>
                    <div>Start Date:</div>
                    <DatePicker
                        disabledDate={disabledStartDate}
                        size="large"
                        format='MM-DD-YYYY'
                        value={startMoment}
                        placeholder="Start"
                        onChange={onStartChange}
                        onOpenChange={handleStartOpenChange}
                    />
                    <div style={{marginTop: '20px'}}>End Date:</div>
                    <DatePicker
                        disabledDate={disabledEndDate}
                        size="large"
                        format='MM-DD-YYYY'
                        value={endMoment}
                        placeholder="End"
                        onChange={onEndChange}
                        open={endOpen}
                        onOpenChange={handleEndOpenChange}
                    />
                    </div>
                  :""
                }

            </div>

            <div style={{padding: '20px 16px 20px 16px', background: '#f4f6f9', height: 'auto'}}>
                { SearchPayout(searchValue) }

            </div>
        

        </div>


    )
}

export default SearchPayout