import React, { useState } from "react";
import { useSelector, useDispatch} from 'react-redux';
import cardActions from '../../../card/cardActions';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Content from './content';
import {openPayoutDrawer} from '../../../payout_drawer/payout_drawer'
import PayoutDisclaimer from '../../../payout_drawer/payout_disclaimer'
import { isMobile } from 'react-device-detect';
import { history } from '../../../../store/history';
import ReminderModal from "../../../../screens/payout/reminder_modal";

const InstaPayPesonet = ({isMobile}) => {
  const [showContent, setShowContent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false)
  const dispatch = useDispatch();
  const level = localStorage.getItem('Level');
  const step = localStorage.getItem('Step');
  const segments = localStorage.getItem('segments');

  const styles = {
    root: {
      padding: `${isMobile == true ? '12px 0px 0px 0px' : '0px 8px'}`,
      boxShadow: isMobile && '0px 2px 4px rgba(0, 0, 0, 0.05)'
    },
    mainBtn: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#FFFFFF',
      padding: 12,
      alignItems: 'center',
      borderRadius: `${showContent ? '4px 4px 0px 0px' : '4px 4px 4px 4px'}`,
      height: 62,
    },
    regularFont: {
      fontSize: 16,
      color: '#2B2D33',
      fontWeight: '600'
    }
  }

  const toggleModal = () => {
    setShowModal(!showModal);
  }

  const handleClick = () => {
    // dispatch(cardActions.showModal('payout_drawer'));
    const defined_wallet_settings = JSON.parse(localStorage.getItem('defined_wallet_settings'));
    const payout_type = defined_wallet_settings["payout_type"] ? defined_wallet_settings["payout_type"] : 0;
    if(localStorage.getItem("payout_enabled") == "true" && localStorage.getItem("Level") != 0 && payout_type != 1){
      if(isMobile){
        setShowDisclaimer(true)

      }else{
        // openPayoutDrawer()
        setShowDisclaimer(true)
      }
    }
  }
  
  return (
    <div hidden={level=="0" && step>=6 || step >=1 && step < 7 || step==0} style={styles.root}>
      <button className="payout-btn" onClick={handleClick}>
          <div className="twoCol">
            <div style={{marginTop: 4}} className="boldTextStyle">Payout to banks / e-wallet</div>
            <ExpandMoreIcon className={`text-${segments}`} style={{marginTop: -4, fontSize: 32, transform: 'rotate(270deg)' }} />
          </div>
      </button>
      {/* <div style={styles.mainBtn}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label className="boldTextStyle">Payout to banks / E-Wallet</label>
        </div>
        <a style={{ display: 'flex' }} onClick={handeClick}>{showContent ? <ExpandMoreIcon style={{ color: '#2B2D33', fontSize: 32, transform: 'rotate(270deg)' }} /> : <ExpandMoreIcon style={{ color: '#F5922F', fontSize: 32, transform: 'rotate(270deg)' }} />}</a>
      </div> */}
      {showContent ? <Content sideBar={true} /> : null}
      <ReminderModal visible={showDisclaimer} 
            onClose={() => setShowDisclaimer(false)} 
            onContinue={() => openPayoutDrawer() / setShowDisclaimer(false)} 
            segments={segments} />
    </div>
  )
}

export default InstaPayPesonet;