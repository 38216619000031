import React from "react";
import { Typography } from "antd";

const statusMap = {
  SU: {
    backgroundColor: "rgba(29, 210, 139, 0.1)",
    borderColor: "rgb(29, 210, 139)",
  },
  PE: {
    backgroundColor: "rgba(245, 146, 47, 0.1)",
    borderColor: "rgb(245, 146, 47)",
  },
  FA: {
    backgroundColor: "rgba(226, 76, 76, 0.1)",
    borderColor: "rgb(226, 76, 76)",
  },
  AP: {
    backgroundColor: "rgba(29, 210, 139, 0.1)",
    borderColor: "rgb(29, 210, 139)",
  },
  RE: {
    backgroundColor: "rgba(226, 76, 76, 0.1)",
    borderColor: "rgb(226, 76, 76)",
  },
  RS: {
    backgroundColor: "rgba(245, 146, 47, 0.1)",
    borderColor: "rgb(245, 146, 47)",
  }
};

export function StatusChip({ status, label }) {
  const {
    backgroundColor = "rgb(240,240,240)",
    borderColor = "rgb(170,170,170)",
  } = statusMap[status] || {};

  return (
    <Typography
      style={{
        fontSize: "14px",
        padding: "4px 8px",
        borderRadius: "4px",
        borderWidth: "1px 1px 1px 5px",
        borderStyle: "solid",
        backgroundColor,
        borderColor,
      }}
    >
      {label}
    </Typography>
  );
}
