import React from 'react';
import { Icon, message } from 'antd';
import promotionServices from '../promotionServices';
import { history } from '../../../../store/history';
import DeleteModal from '../delete_model';

const PromotionDraft = props => {

    const [data, setData] = React.useState()

    const [modalData, setModalData] = React.useState([]);
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const ValueColumnDiv = (key, value) => {
        return(
            <div className="twoCol promotion-value-div liveColor">
                <div className="normalTextStyle">
                    {key}
                </div>
                <div className="normalTextStyle medium-font-weight liveColor">
                    {value}
                </div>
            </div>
        )
    }

    const handleDelete = async (data) => {

        setIsLoading(true);
        const params = ({ code: data['code'] });
        try {
            const res = await promotionServices.removePromoCode(params);
            if(res.status === 'success') {
                message.success(res.message);
                window.location.href = "/promotion"
            } else {
                message.success(res.message);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            message.error('Please try again');
        }
        setIsLoading(false);
        setDeleteModal(false);
    }

    const ViewPromo = ({params, key}) => {
        return(
            <div key={key}>
                <div className="twoCol">
                    <div className="screen-title-text value-color">
                        {params['code']}
                    </div>
                    <div>
                        <button className="outline-btn--nil right-8" onClick={() => (setDeleteModal(true) / setModalData(params))}>
                            Delete
                        </button>

                        <button className="btn--nil"
                            onClick={() => history.push(`/edit_promotion/${params['id']}`)}>
                            <Icon type="edit" className="right-4" />
                            Edit promo
                        </button>
                    </div>
                </div>
                <div className="top-40">
                    {ValueColumnDiv('Promo name', params['promo_name'])}
                    {ValueColumnDiv('Segment', 'BUxMe, BUxGIG')}
                    {ValueColumnDiv('Channels', 'All OTC Channels')}
                    {ValueColumnDiv('Description', params['description'])}
                </div>

                {/* <div className="top-32">
                    <div className="promotion-discount-text" align="left">
                        Promo Validity
                    </div>

                    <div className="top-32">
                        {ValueColumnDiv('Promo start', `${params['start_date']} ${params['start_time']}`)}
                        {ValueColumnDiv('Promo end', `${params['end_date']} ${params['end_time']}`)}
                    </div>
                </div> */}
            </div>
        )
    }

    const getDraft = async () => {
        try  {
            const res = await promotionServices.getDraftPromoCode();
            console.log(res)

            if(res.message == 'sucess') {
                setData(res.data)
            }
            
        }

        catch (error) {
            console.log(error);
        }
    }
    

    React.useEffect(() => {
        getDraft()
    }, [])

    return(
        <div align="center">
            <div className="promotion-new">
                <div align="left">
                    <a href="/promotion">
                        <Icon type="arrow-left" className="text-nil right-8 arrow-width"/>
                        <span className="wc-header">
                                Back
                        </span>
                    </a>
                </div>

                {
                    data && data.map((item, key) => {
                        return(
                            <div className="promotion-card padding-32 top-32">
                                <ViewPromo params={item} key={key} />
                            </div>
                        )
                    },[])
                }

            </div>
            <DeleteModal visible={deleteModal} onClose={() => setDeleteModal(false)} onDelete={() => handleDelete(modalData)} />
        </div>
    )
}

export default PromotionDraft;