import React from 'react'
import { Button, Input, Divider } from 'antd'
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import axios from 'axios'


class EmailAndPass extends React.Component{
    state={
        showPassword: false,
        showNew: false,
        oldPassword: '',
        newPassword: ''
    }

    labelStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#2B2D33',
    }

    saveBtn = {
        width: '150px',
        height: '40px',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '24px',
        float: 'right',
    }

    changePassword = async () => {
        let params = {
            old_password: this.state.oldPassword,
            new_password: this.state.newPassword
        }
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let response = await axios.post(process.env.REACT_APP_API_URL + "/api/change_password/", params, yourConfig);
       
        if(response.data.status == "success"){
           console.log('success change password')
        }

    };

    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
        console.log(this.showPassword)
    };

    handleClickShowPassword2 = () => {
        this.setState({
            showNew: !this.state.showNew
        })
        console.log(this.showPassword)
    };
    

    

    render(){
        return (
            <div style={{width: '70%', padding: '48px 52px 0px 52px'}}>
                <div style={this.labelStyle}>Email</div>
                <Input style={{width: '100%', height: '40px', marginBottom: '20px'}}/>
                <div  style={{display: 'flex', width:'100%', marginBottom: '20px'}}>
                    <div style={{width: '49%', marginRight: '2%'}}>
                    <div style={this.labelStyle}>Old password</div>
                    <TextField
                        id="outlined-bare"
                        variant="outlined"
                        style = {{
                            width: '100%',
                        }}
                        type={this.state.showPassword ? 'text' : 'password'}
                        onChange={(e) => this.setState({oldPassword : e.target.value})}
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                            >
                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            </InputAdornment>
                        ),
                        }}
                    />
                    </div>
                    <div style={{width: '49%'}}>
                    <div style={this.labelStyle}>New password</div>
                    <TextField
                        id="outlined-bare"
                        variant="outlined"
                        style = {{
                            width: '100%',
                        }}
                        type={this.state.showNew ? 'text' : 'password'}
                        onChange={(e) => this.setState({newPassword: e.target.value})}
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword2}
                            >
                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            </InputAdornment>
                        ),
                        }}
                    />
                    </div>
                </div>
                <Divider></Divider>
                <Button type="primary" style={this.saveBtn} onClick={()=>this.changePassword()}>Change password</Button>
                
            </div>   
        )
    }
}

export default EmailAndPass