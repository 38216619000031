import React from 'react'
import TermsAndPrivacy from '../../components/terms_and_privacy/terms_and_privacy'
import Main from '../../layouts/Main'
import MobileMain from '../../layouts/Mobile/MobileMain'
import {isMobile} from 'react-device-detect'
import logo from '../../static/icons/logo.svg';
import {Layout} from 'antd'
import {history} from '../../store/history'

const TermsPrivacyMain = () =>{

    const {Header} = Layout
    let jwtToken = localStorage.getItem("jwtToken")

    return(
        <div>
            {   
                (jwtToken == "" || jwtToken == null || localStorage.getItem("termsAgreed") != "true") ?
                <div>
                    <Header style={{width:'100%', height: '60px', display: 'flex', justifyContent: 'center', backgroundColor: '#fff', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)', position: 'fixed', zIndex: '1'}}>
                        <img src={logo} style={{marginBottom: '20px', marginTop: '12px'}} alt="#" onClick={()=>history.push('/dashboard')}/>
                    </Header>
                    <div style={{paddingTop: '70px', backgroundColor: '#E5E5E5', height: '100%'}}>
                        <TermsAndPrivacy/>
                    </div>
                </div>
                : isMobile ?
                <MobileMain title="Terms and Privacy">
                     <TermsAndPrivacy/>
                </MobileMain>
                :
                <Main>
                    <TermsAndPrivacy/>
                </Main>
            }
        </div>
    )
}

export default TermsPrivacyMain