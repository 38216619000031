import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Button, Modal, Icon, Input, DatePicker, message } from 'antd'
import { TextField, Grid, makeStyles } from '@material-ui/core';

const { TextArea } = Input


const EditContractExpiryModal = props => {

    let data = props.editData

    const useStyles = makeStyles(theme => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textArea: {
            marginRight: '100%',
            display: 'flex',
            fontSize: '16px'
        },
        headerText: {
            fontSize: 'x-large',
            padding: '20px 20px 20px 0'
        },
    }));

    const classes = useStyles();

    const [id, setID] = React.useState("");
    const [expiryDate, setExpiryDate] = React.useState("")
    const [notes, setNotes] = React.useState("")


    React.useEffect(() => {
        setID(data.id)
        setExpiryDate(moment(data.contract_expiry, 'MMMM DD YYYY'))
        setNotes(data.notes)
    }, [data])

    async function editContractExpiry() {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = {
            submission_id: id == "" ? data.id : id,
            contract_expiry: expiryDate.format('MMMM DD YYYY, hh:mm A'),
            notes: notes,
        }
        console.log(params)
        
        let response = await axios.patch(
            process.env.REACT_APP_API_URL + '/api/admin/kyb/contract_expiry/', params, yourConfig
        );
            
        console.log(response)
        
        if (response.data.status == 'success') {
            props.closeModal(3)
            props.fetchSubmissions(1, "All", "")
            message.success(response.data.message, 5)
        }
        else {
            message.error(response.data.message, 5)
        }
        
    }

    function onDateChange(date) {
        if (date != null) {
            setExpiryDate(date)
        }
    }

    function onSetNotes(text){
        setNotes(text)
    }

    return (
        <Modal
            key={data.id}
            centered
            visible={props.openModal}
            width={500}
            onCancel={()=>props.closeModal(3)}
            closeIcon={<Icon type="close" style={{ color: '#F5922F' }} />}
            footer={null}>
            <div className={classes.headerText}>
                Contract Expiry
            </div>
            <Grid container>
                <Grid item={true} lg={12}>
                    <label style={styles.label}>Expiry date</label>
                    <DatePicker
                        style={{ zIndex: 1, width: '100%', height:'40px' }}
                        format='DD/MM/YY'
                        defaultValue={data.contract_expiry ? moment(data.contract_expiry, 'MMMM DD YYYY') : moment('', 'MMMM DD YYYY')}
                        value={expiryDate}
                        placeholder="Effective Date"
                        onChange={onDateChange}
                    />
                </Grid>
                <Grid item={true} lg={12}>
                    <label style={styles.label}>Emailer note</label>
                    <TextArea
                        autoSize={{ minRows: 6, maxRows: 6 }}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        style={styles.input}
                        showCount maxLength={256}
                        value={notes}
                        onChange={e => onSetNotes(e.target.value)}
                    />
                </Grid>

                <Grid item={true} lg={12} style={{ paddingTop: '16px', paddingBottom: '16px' }}>
                    <Button style={styles.confirmBtn} block key="submit" type="primary" onClick={() => editContractExpiry()}>
                        Save
                    </Button>
                </Grid>

            </Grid>
        </Modal>
    )
}



const styles = {
    root: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'left',
    },
    title: {
        fontSize: 20,
        color: '#2B2D33',
        padding: '32px 0 18px 0',
    },
    cancelBtn: {
        width: '48%',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 10,
        height: 40,
        border: '1px solid #F5922F',
    },
    confirmBtn: {
        height: 48,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 10,
    },
    footerBtn: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 20,
    },
    label: {
        fontSize: 12,
        color: '#212B36',
    },
    input: {
        padding: '11px 16px 11px 16px',
        fontSize: 16,
        margin: '4px 0px 0px 0px',
        color: '#2B2D33'
    },
}
export default EditContractExpiryModal;