import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Button, Input, Spin, Affix } from "antd";
import { useLocation } from 'react-router';
import cardActions from './cardActions';
import visaCard from "../../static/images/bux_visa_card.svg";
import lockVisaCard from "../../static/images/lock_bux_visa_card.svg";
import { ErrorCard } from '../../static/icons/dashboard_icons';
import LoadCardModal from './modals/loadCardModal';
import SuccessLoadModal from './modals/successLoadModal';
import { history } from '../../store/history';
import EonIconButtons from './eonIconButtons';
import cardServices from "./cardServices";
import { Buffer } from 'buffer';
import crypto from 'crypto';
import CancelIcon from '@material-ui/icons/Cancel';
import eonVisaCard from '../../static/images/eon_visa_card.svg';
import eonImage from "../../static/images/eon_text_image.svg";
import { PaybillsEllipsisRound } from '../../static/icons/dashboard_icons/';
import Store from '../../static/images/eon_store.svg';
import EonHowToModal from "./modals/eon_how_to_modal";

const ButtonGroup = ({ btnClicked, handleClick }) => {
  return (
    <div style={{ padding: '20px 0px 0px 0px' }}>
      <Button.Group style={styles.groupBtn}>
        <Button value='bills-payment' style={{
          width: '100%',
          height: 48,
          color: `${btnClicked === 'bills-payment' ? '#FFFFFF' : '#2B2D33'}`,
          backgroundColor: `${btnClicked === 'bills-payment' ? '#0D3D76' : '#FFFFFF'}`,
          // borderTopLeftRadius: 10,
          // borderBottomLeftRadius: 10,
          borderRadius: 10,
          fontSize: 12,
          fontWeight: 'bold'
        }} onClick={handleClick}>Bills Payment</Button>
        {/* <Button value='top-ups' style={{
          width: '50%',
          height: 48,
          color: `${btnClicked === 'top-ups' ? '#FFFFFF' : '#2B2D33'}`,
          backgroundColor: `${btnClicked === 'top-ups' ? '#0D3D76' : '#FFFFFF'}`,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
          fontSize: 12,
          fontWeight: 'bold'
        }} onClick={handleClick}>Top-Ups</Button> */}
      </Button.Group>
    </div>
  )
}

const EonCard = ({ cardActivated, toggleModal, sideBar, balance, cardNumber, expiryDate, isMobile, buxBalance, noEon, lockLoading, physicalCard, custId }) => {
  const [showLoadCardModal, setShowLoadCardModal] = useState(false);
  const visibleLoadCardModal = useSelector(state => state.card['load_card_modal']);
  const [isLoading, setIsloading] = useState(false);
  const [visibleSuccessModal, setVisibleModalSuccess] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [btnClicked, setBtnClicked] = useState('bills-payment');
  const [btnStyle, setBtnStyle] = useState({ backgroundColor: 'transparent', color: '#F5922F' })
  const dispatch = useDispatch();
  const location = useLocation();
  const [cardActivateSuccess, setCardActivateSucccess] = useState(location.state ? location.state.success : false);
  const lockCard = useSelector(state => state.card.cardLock);
  const [isError, setIsError] = useState(location.state ? location.state.isError : false);
  const [isSuccess, setIsSuccess] = useState(location.state ? location.state.isSuccess : false);
  const loadError = useSelector(state => state.card['load_error_notif']);
  const [notifMsg, setNotifMsg] = useState(location.state ? location.state.notifMsg : '');
  const kyc_lvl = localStorage.getItem('Level');
  const segments = localStorage.getItem('segments');
  const [howToModal, setHowToModal] = React.useState(false);

  const handleClick = () => {
    // if (cardActivated) { 
    // setShowLoadCardModal(!showLoadCardModal)
    dispatch(cardActions.showModal('load_card_modal'))
  }

  const closeLoadCardModal = () => {
    dispatch(cardActions.hideModal('load_card_modal'));
  }

  const handleClickLoadBtn = () => {

  }


  const handleButtonGroupClick = (event) => {
    setBtnClicked(event.target.value);
    setIsButtonLoading(true);
    setTimeout(() => {
      setIsButtonLoading(false);
    }, 200);
  }

  const handleMouseOver = () => {
    setBtnStyle({
      backgroundColor: '#F5922F',
      color: '#FAFAFA'
    });
  }

  const handleMouseLeave = () => {
    setBtnStyle({
      backgroundColor: 'transparent',
      color: '#F5922F'
    });
  }

  if (isSuccess || isError || loadError) {
    setTimeout(() => {
      setIsError(false)
      setIsSuccess(false)
      if (window.location.pathname === '/dashboard') {
        history.replace({ pathname: '/dashboard', state: { isSuccess: false, isError: false } });
      } else if (isMobile) {
        if (window.location.pathname === '/mobile/dashboard') {
          history.replace({ pathname: '/mobile/dashboard', state: { isSuccess: false, isError: false } });
        } else {
          history.replace({ pathname: '/mobile/card', state: { isSuccess: false, isError: false } });
        }
      } else {
        history.replace({ pathname: '/card', state: { isSuccess: false, isError: false } });
      }
      dispatch(cardActions.hideModal('load_error_notif'));
    }, 3000);
  }

  const noEonCard = (
    <div style={{ width: '100%', position: 'relative', padding: isMobile ? '8px' : 0 }}>
      <img alt='No Image' src={visaCard} style={{ width: '100%' }} />
      <Typography style={styles.cardStyle}>xxxx xxxx xxxx</Typography>
    </div>
  )

  const buxCard = (
    <div hidden={cardNumber === "" && noEon == '' && !lockLoading} style={{ width: '100%', position: 'relative' }}>
      <img alt='No Image' src={visaCard} style={{ width: '100%' }} />
      <div style={{ ...cardStyle.text, top: '65%', left: '37%', fontSize: 16, fontWeight: 'bold', textAlign: 'left', opacity: lockCard ? '.5' : '1' }}>
        {cardNumber ? "**** " + cardNumber : ""}
      </div>
      <div style={{ ...cardStyle.text, width: '0%', top: '85%', left: '12%', fontSize: 6, fontWeight: '900', textAlign: 'left', lineHeight: '1`px' }}>{'VALID THRU'}</div>
      <div style={{ ...cardStyle.text, top: '85%', left: '46%', fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>
        {expiryDate}
      </div>
      <div style={{ ...cardStyle.text, top: '53%', left: '69%', fontSize: 12, textAlign: 'right', fontWeight: 'bold' }}>
        {lockCard ? null : 'Card Balance'}
      </div>
      <div style={{ ...cardStyle.text, width: '65%', top: '64%', left: '62%', fontSize: 16, fontWeight: 'bold', textAlign: 'right' }}>
        {lockCard ? null : balance ? 'PHP ' + parseFloat(balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : <Spin size="small" />}
      </div>
      {
        lockCard ? <img alt='No Image' src={lockVisaCard} style={{ ...cardStyle.text, top: '50%', left: '50%' }} /> : null
      }
    </div>
  )

  const eonCard = (
    <div style={{ width: '100%', position: 'relative' }}>
      <img alt='No Image' src={eonVisaCard} style={{ width: '100%' }} />
      <div style={{ ...cardStyle.text, top: '70%', left: '31%', fontSize: 16, fontWeight: 'bold', textAlign: 'left', opacity: lockCard ? '.5' : '1' }}>
        {cardNumber ? "**** " + cardNumber : ""}
      </div>
      <div style={{ ...cardStyle.text, width: '0%', top: '88%', left: '7%', fontSize: 7, fontWeight: '900', textAlign: 'left', lineHeight: '11px' }}>{'VALID THRU'}</div>
      <div style={{ ...cardStyle.text, top: '88%', left: '42%', fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>
        {expiryDate}
      </div>
      <div style={{ ...cardStyle.text, top: '61%', left: '68%', fontSize: 12, textAlign: 'right', fontWeight: '400' }}>
        {lockCard ? null : 'Card Balance'}
      </div>
      <div style={{ ...cardStyle.text, width: '62%', top: '72%', left: '62%', fontSize: 16, fontWeight: 'bold', textAlign: 'right' }}>
        {lockCard ? null : balance ? 'PHP ' + parseFloat(balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : <Spin size="small" />}
      </div>
      {
        lockCard ? <img alt='No Image' src={lockVisaCard} style={{ ...cardStyle.text, top: '50%', left: '50%' }} /> : null
      }
    </div>
  )

  const errorCard = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <img alt={'No Image'} src={ErrorCard} style={{ padding: '0 0 16px 0' }} />
      <Typography style={styles.disabledText}>We're sorry</Typography>
      <Typography style={styles.disabledText}>Unavailable at the moment</Typography>
      <Typography style={{ ...styles.disabledText, padding: '16px 0 0 0', fontWeight: 'bold' }}>Please try again later</Typography>
    </div>
  )

  const paybillsMoreClick = () => {
    dispatch(cardActions.setSelectedPaybills('Others'));
    if (isMobile) {
      history.push({ pathname: '/mobile/bills_payment' });
    } else {
      history.push({ pathname: '/cards/bills_payment', state: { no_eon: noEon } });
    }
  }

  const contentBody = (
    <div>
      <button hidden={cardNumber === "" && noEon == '' && !lockLoading} disabled={lockCard ? lockCard : isLoading} className={`btn--${segments}`} style={{ ...styles.actBtn, opacity: `${lockCard ? '50%' : '100%'}` }} onClick={handleClick}>Load your {physicalCard ? 'EON Physical Card' : 'Virtual Bux Visa Card'}</button>
      {!lockCard || lockCard && noEon === 'none' ?
        <div>
          {sideBar ? null :
            <button className={`outline-btn--${segments}`} style={{ ...styles.btnUpgrade }} onClick={() => history.push('/physical_card_upgrade')}>
              {physicalCard ? 'Change your EON Visa Card' : 'Upgrade to EON Visa Card'}
            </button>
          }
          {sideBar ?
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: sideBar && 16 }}>
                <Typography style={{ color: '#000000', fontSize: 16, fontWeight: 'bold', padding: sideBar ? '10px 0 6px 0' : '0 0 10px 0', textAlign: 'left' }}>Bills Payment</Typography>
                <a style={{ color: '#F5922F', fontSize: 16, fontWeight: 'bold' }} onClick={paybillsMoreClick}><img src={PaybillsEllipsisRound} alt='no Image' style={{ padding: '0 6px 4px 0' }} />See More</a>
              </div>
              <hr style={styles.divider} />
              <EonIconButtons
                noEon={cardNumber}
                isMobile={isMobile}
                type={btnClicked == 'bills-payment' ? 'bills-payment' : 'top-ups'}
              />
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '6px 0px -14px 0px' }}>
                <Typography style={{ fontSize: 12, color: '#909196' }}>Powered by</Typography>
                <img src={eonImage} style={{ width: 50, height: 38, paddingLeft: 2 }} />
              </div>
              <div hidden={noEon !== ''} style={{ padding: '32px 0 0 0' }}>
                <Typography style={styles.profMessage}>Complete your profile to avail of an EON Visa Card and Bills Payment</Typography>
                <Button type='link' style={styles.profLink} onClick={() => { window.location.href = '/complete_profile' }} >COMPLETE PROFILE NOW</Button>
              </div>
            </div>
            : null}
        </div>
        : null
      }
    </div>
  )

  return (
    <div>
      {
        isError || isSuccess || loadError ?
          <Affix offsetTop={20} style={{ position: 'fixed', zIndex: '100', top: '10%', left: '42%' }}>
            <div
              style={{
                ...styles.notif,
                backgroundColor: isError || loadError ? '#FFF' : '#1DD28B',
                color: isError || loadError ? '#E24C4C' : '#FFFFFF',
              }}
            >
              {isError || loadError ? <CancelIcon
                style={{
                  margin: '0 10px 0 0'
                }}
              /> : null}
              {loadError ? 'Load Error Try again' : notifMsg}
            </div>
          </Affix>
          : null
      }
      <LoadCardModal
        visible={visibleLoadCardModal}
        closeModal={closeLoadCardModal}
        clickLoadBtn={handleClickLoadBtn}
        buxBalance={buxBalance}
        cardNumber={cardNumber}
        physicalCard={physicalCard}
      />
      {/* <SuccessLoadModal closeModal={closeSuccessModal} visible={visibleSuccessModal} /> */}
      <Card size="small"
        bordered={false}
        style={{
          borderRadius: `${sideBar ? '0px 0px 4px 4px' : '4px'}`,
          padding: `${sideBar ? '0px 4px 8px 4px' : '20px 4px 0px 4px'}`,
          margin: `${isMobile ? '5px 0' : null}`
        }}
        bodyStyle={{ padding: '0 12px 12px 12px' }}
      >
        {
          // cardNumber === "" && noEon == '' && !lockLoading ?
          // <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          //   <Button style={{ ...styles.actBtn }} onClick={()=>{history.push('/complete_profile');}}>Complete your Profile</Button>
          // </div>
          // :
          !lockLoading && cardNumber !== null && noEon !== '' ?
            <div style={{ width: '100%', position: 'relative' }}>
              {physicalCard ? eonCard
                : buxCard}
              {contentBody}
            </div>
            : kyc_lvl < 1 && !custId ?
              <div>
                {noEonCard}
                {contentBody}
              </div>
              : kyc_lvl >= 1 && custId && !lockLoading ?
                errorCard
                :
                <div style={{ width: '100%', height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Spin size="small" />
                </div>
        }
      </Card>

      {sideBar || lockCard || lockLoading ? null
        // isButtonLoading 
        // ?  null
        // : <Spin
        //   style={{height: 94, display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        //   />
        :
        <Card
          bordered={false}
          size='small'
          style={{ margin: '0px 0 0 0', borderRadius: 4, padding: 8 }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: sideBar && 16 }}>
            <Typography style={{ color: '#0D3D76', fontSize: 16, fontWeight: 'bold', padding: '0 0 10px 0', textAlign: 'left' }}>Bills Payment</Typography>
            <a className={`link-text--${segments}`} style={{ fontSize: 16, fontWeight: 'bold', paddingBottom: 10, opacity: noEon === '' && true ? '0.5' : 1 }} onClick={paybillsMoreClick} disabled={noEon === '' && true} >
              <img src={PaybillsEllipsisRound} alt='no Image' style={{ padding: '0 6px 4px 0' }} />See More</a>
          </div>
          <hr style={{ ...styles.divider, marginTop: '-6px' }} />
          <EonIconButtons
            isMobile={isMobile}
            noEon={noEon}
            type={btnClicked == 'bills-payment' ? 'bills-payment' : 'top-ups'}
          />
        </Card>
      }
      <Card
        bordered={false}
        size='small'
        style={{ margin: '0px 0 0 0', borderRadius: 4, padding: 0, margin: '20px 0' }}
        bodyStyle={{ display: 'flex', padding: 0, alignItems: 'flex-end' }}
      >
        <img src={Store} alt='No Image' />
        <a style={{ padding: '16px 12px', fontWeight: '700', fontSize: 12, textAlign: 'left', textDecoration: 'underline' }} className={`link-text--${segments}`}
          onClick={() => setHowToModal(true)}
        >
          How to link/activate your EON visa card and where to purchase?
        </a>
      </Card>
      <EonHowToModal visible={howToModal} onClose={() => setHowToModal(false)} segments={segments} />
    </div>
  )
}

const cardStyle = {
  text: {
    position: 'absolute',
    color: '#FFF',
    transform: 'translate(-50%, -50%)',
    width: '50%'
  }
}

const styles = {
  regularFont: {
    fontSize: 12,
    color: '#000000'
  },
  input: {
    padding: '11px 16px 11px 16px',
    fontSize: 16,
    height: 48,
    margin: '4px 0px 0px 0px',
  },
  actBtn: {
    margin: '16px 0px 0px 0px',
    height: 40,
    width: '100%',
    // backgroundColor: '#F5922F',
    // color: '#FFFFFF',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
  },
  notif: {
    backgroundColor: '#1DD28B',
    fontSize: 14,
    fontWeight: 'bold',
    width: '100%',
    borderRadius: 4,
    padding: '8px 16px',
    textAlign: 'center',
    display: 'flex',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.24)'
  },
  groupBtn: {
    fontSize: 12,
    color: '#54575F',
    textAlign: 'center',
    padding: '0px 0px 16px 0px',
    width: '100%',
  },
  divider: {
    borderTop: '.2px solid #E6EAF0',
    width: '100%',
    margin: '0px 0px 20px 0px',
    position: 'relative',
    // right: 15,
  },
  btnUpgrade: {
    margin: '10px 0px 0px 0px',
    height: 40,
    width: '100%',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
  },
  cardStyle: {
    fontWeight: '600',
    position: 'absolute',
    color: '#FFF',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    top: '68%',
    left: '30%',
  },
  profMessage: {
    color: '#000',
    fontSize: 16,
    padding: '0 0 16px 0',
    textAlign: 'center',
  },
  profLink: {
    color: '#F5922F',
    fontSize: 14,
    fontWeight: '600',
    textAlign: 'center',
    width: '100%'
  },
  disabledText: {
    color: '#909196',
    fontSize: 16,
    textAlign: 'center'
  }
}

export default EonCard;