import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Buffer } from 'buffer';
import crypto from 'crypto';
import { Modal, Typography, Input, Button, Spin, Affix } from "antd";
import { BuxRoundBlue } from '../../../static/icons/dashboard_icons';
import { Card } from '../../../static/icons/sidebar';
import eonImage from "../../../static/images/eon_text_image.svg";
import CancelIcon from '@material-ui/icons/Cancel';
import SuccessLoadModal from './successLoadModal';
import cardActions from '../cardActions';
import cardServices from '../cardServices';
import { history } from '../../../store/history';
import Otp from '../otp/Otp';

const LoadCardModal = ({ visible, closeModal, clickLoadBtn, buxBalance, cardNumber, physicalCard }) => {
  const [insufficientAmount, setInsufficientAmount] = useState(false);
  const [loadAmount, setLoadAmount] = useState(null);
  const [loadError, setLoadError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const visibleSuccessModal = useSelector(state => state.card['success_load_modal']);
  const visibleOTP = useSelector(state => state.card['otp_mobile']);
  const attemptError = useSelector(state => state.card['attempt_error']);
  const [mobileNum, setMobileNum] = useState('');
  const [otpUid, setOtpUid] = useState(null);
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  const [bux_Balance, setBuxBalance] = useState(buxBalance);
  const [eonBalance, setEonBalance] = useState(null);
  const [reloadBal, setReloadBal] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null);
  const [otpSubj, setOtpSubj] = useState('load_card');
  const [attemptMsg, setAttemptMsg] = useState(null);
  const segments = localStorage.getItem('segments');

  const getBalance = async () => {
    try {
      const response = await cardServices.getBuxBalAndCardNum();
      setBuxBalance(response.bux_balance)
      setEonBalance(response.card.account.availableBalance)
    } catch (error) {
      setErrorMsg(errorMsg);
      setIsLoading(false)
      setInsufficientAmount(false);
      dispatch(cardActions.hideModal('success_load_modal'));
    }
  }

  if (!initialized && buxBalance) {
    setBuxBalance(buxBalance);
    setInitialized(true);
  }

  const returnError = (errorMsg) => {
    setLoadError(true)
    setErrorMsg(errorMsg);
    setIsLoading(false)
    setInsufficientAmount(false);
    setTimeout(() => {
      setLoadError(false);
      dispatch(cardActions.hideModal('attempt_error'));
    }, 5000);
  }

  const loadCard = async (transID) => {
    setIsLoading(true);
    try {
      const response = await cardServices.loadEonCard({ 'amount': parseFloat(loadAmount), 'trans_uid': transID });
      if (response.status === 'successs') {
        dispatch(cardActions.showModal('success_load_modal'));
        dispatch(cardActions.hideModal('load_card_modal'));
        dispatch(cardActions.showModal('refresh_balance'));
      } else {
        returnError(response.message)  
      }
    } catch (error) {
      console.log(error)
      returnError(error)
    }
  }

  const handleLoadCard = () => {
    if (loadAmount > buxBalance) {
      setInsufficientAmount(true);
    } else {
      setIsLoading(true)
      // loadCard();
      fetchOTP()

    }
  }

  const fetchOTP = async () => {
    try {
      const response = await cardServices.getOTP();
      if (response.status === 'success') {
        setMobileNum(response.mobile);
        setOtpUid(response.uid)
        dispatch(cardActions.showModal('otp_mobile'))
        dispatch(cardActions.resetOTPTimer(true))
      } else {
        if(response.code==='attempts'){
          dispatch(cardActions.setAttempMsg(response.unblock));
          dispatch(cardActions.showModal('attempt_error'));
          dispatch(cardActions.showModal('otp_mobile'));
        } else {
          returnError(response.message)
          setIsLoading(false)
          dispatch(cardActions.hideModal('otp_mobile'))
        }
      }
    } catch (error) {
      console.log(error)
      dispatch(cardActions.hideModal('otp_mobile'))
      returnError(error)
      setIsLoading(false)
    }
  }

  const handleChange = (event) => {
    const amount = autoFormat(event.target.value);
    setLoadAmount(amount)
  }

  const autoFormat = (value) => {
    const formattedValue = value.replace(/\D/g, '')
    if (value !== null) {
      return formattedValue;
    } else {
      return value;
    }
  }


  const handleClear = () => {
    setLoadAmount(null)
    setInsufficientAmount(false);
  }

  const closeSuccessModal = () => {
    dispatch(cardActions.hideModal('success_load_modal'));
    setIsLoading(false)
    setInsufficientAmount(false);
    // history.goBack()
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'PHP',
    minimumFractionDigits: 2
  })

  const currencyFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 })

  return (
    <Modal visible={visible}
      footer={null}
      onCancel={closeModal}
      afterClose={handleClear}
      maskClosable={false}
      width={384}
      centered
      closable={!isLoading}
    >
      {/* {
        loadError ?
        <Affix offsetTop={20} style={{position: 'fixed'}}>
          <div style={styles.notif}>{errorMsg}</div>
        </Affix>
        : null
      } */}
      <SuccessLoadModal closeModal={closeSuccessModal} visible={visibleSuccessModal} buxBalance={bux_Balance} eonBalance={eonBalance} prevBuxBalance={buxBalance} />
      <div><Typography style={{ fontSize: 24 }}>Load your Visa Card</Typography></div>
      <div style={styles.top}>
        <div style={{ display: 'flex', alignItems: 'center' }} >
          <img src={BuxRoundBlue} style={{ width: 28 }} />
          <Typography style={{ fontSize: 16, padding: '0px 8px 0px 8px' }}>BUx Wallet Balance:</Typography>
        </div>
        <div>
          {/* <Typography style={styles.balAmount}>PHP {balance.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography> */}
          <Typography style={styles.balAmount}>{bux_Balance || bux_Balance === 0 ? formatter.format(bux_Balance) : <Spin size='small' />   }</Typography>
        </div>
      </div>
      <hr style={styles.divider}></hr>
      {insufficientAmount || loadError ?
        <div style={styles.errorDiv}>
          <div>{insufficientAmount ? 'Insufficient Amount' : errorMsg}</div>
          {/* <div>{attemptError ? 'Otp will be unblock on ' + attemptError : null}</div> */}
        </div>
        : null}
      <div style={styles.amountDiv}>
        <label style={styles.regularFont}>Enter Amount</label>
        <Input
          // type="tel"
          disabled={isLoading}
          value={loadAmount}
          onChange={handleChange}
          size="large"
          // suffix={<CancelIcon style={{ color: '#E24C4C' }} />}
          style={{ borderColor: `${insufficientAmount ? '#E24C4C' : '#D1D5DD'}`, ...styles.input }}
        ></Input>
        <Typography style={styles.php}>PHP</Typography>
        {
          insufficientAmount ?
            <a><CancelIcon onClick={handleClear}
              style={styles.cancelIcon}
            /></a>
            : null
        }
      </div>
      <div style={{ padding: '20px 0px 20px 0px' }}>
        <Typography style={{ fontSize: 16, color: '#000000' }}>
          The amount you entered will be deducted from your <strong>BUx Wallet</strong>  and will be loaded to your <strong>{physicalCard? 'EON Physical Card' : 'Virtual BUx Visa Card'}</strong>.
        </Typography>
      </div>
      <div>
        <label style={styles.regularFont}>Your EON Card</label>
        <div style={styles.cardInfo}>
          <div>
            <img src={Card} style={{ backgroundColor: '#0A315E', borderRadius: '50%' }} />
            <img src={eonImage} style={{ width: 58 }} />
          </div>
          <strong>{cardNumber ? "**** " + cardNumber : ""}</strong>
        </div>
      </div>
      <button
        disabled={isLoading || !loadAmount}
        style={styles.btn}
        onClick={handleLoadCard}
        className={`btn--${segments}`}
      >
        {isLoading ? <Spin size='small' /> : 'Load your Card'}
      </button>
      <Otp
        visible={visibleOTP} 
        closeModal={() => (dispatch(cardActions.hideModal('otp_mobile')),
                            dispatch(cardActions.hideModal('attempt_error')), 
                            dispatch(cardActions.setAttempMsg(null)),
                            setIsLoading(false)
                            )} 
        mobileNum={mobileNum}
        otpUid={otpUid}
        otpSubj={otpSubj}
        onSuccess={loadCard}
        onResend={fetchOTP}
        onAttemptError={attemptError}
      />
    </Modal>
  )
}

const styles = {
  divider: {
    borderTop: '.2px solid #D1D5DD',
    margin: '8px 0px 20px 0px',
  },
  regularFont: {
    fontSize: 12,
    color: '#212B36'
  },
  input: {
    padding: '11px 56px',
    fontSize: 16,
    height: 48,
    margin: '4px 0px 0px 0px',
  },
  btn: {
    margin: '20px 0px 0px 0px',
    height: 40,
    width: '100%',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 0px 8px 0px',
  },
  balAmount: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#2B2D33',
  },
  notif: {
    backgroundColor: '#E24C4C',
    fontSize: 14,
    fontWeight: 'bold',
    width: '100%',
    color: '#FFFFFF',
    borderRadius: 4,
    padding: '8px 16px',
    textAlign: 'center'
  },
  errorDiv: {
    backgroundColor: 'rgba(226, 76, 76, 0.1)', 
    borderRadius: 4, 
    padding: '9px 16px', 
    borderLeft: '5px solid #E24C4C', 
    margin: '0 0 8px 0' 
  },
  amountDiv: {
    textAlign: "left", 
    margin: '0px 0px 0px 0px', 
    position: 'relative'
  },
  php: {
    position: 'absolute',
    top: `67%`,
    left: '8%',
    transform: 'translate(-50%, -50%)',
    fontSize: 16,
    color: '#2B2D33',
    zIndex: '100',
  },
  cancelIcon: {
    position: 'absolute',
    right: '0%',
    top: '68%',
    color: '#E24C4C',
    transform: 'translate(-50%, -50%)',
  },
  cardInfo: {
    backgroundColor: 'rgba(29, 130, 184, 0.1)', 
    borderRadius: 10, 
    padding: '20px 0px 20px 0px', 
    textAlign: 'center',
    margin: '4px 0 0 0', 
    display: 'flex', 
    justifyContent: 'space-between',
    alignItems: 'center', 
    padding: '12px 20px'
  }
}

export default LoadCardModal;