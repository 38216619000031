import { combineReducers } from "redux";
import authReducers from "../components/login/authReducer";
import signUpReducers from "../components/sign_up/signUpReducers";
import orderReducers from "../components/orders/orderReducer";
import fundsReducers from "../components/funds/fundsReducer";
import paymentReducers from "../components/payment/paymentReducer";
import customerReducers from "../components/customers/customerReducers";
import ledgerReducers from "../components/AdminComponents/ledger/ledgerReducer";
import usersReducers from "../components/AdminComponents/users/usersReducer";
import reconReducers from "../components/AdminComponents/recon/reconReducer";
import contentSettingsReducers from "../components/AdminComponents/settings/contentReducer";
import alertReducer from "../components/alert/alertReducers"
import spinReducer from "../components/spin/spinReducer"
import dashboardReducer from "../components/dashboard/dashboardReducer";
import locationReducer from "../components/locator/locationReducer";
import payoutsAllReducer from "../components/AdminComponents/payout/payoutReducer"
import modalReducers from "../components/BuxModal/modalReducer"
import shipmentsReducer from '../components/shipments/shipmentsReducer'
import tooltipReducer from '../layouts/AppBar/appBarComponent/appBarFormReducer'
import cardReducers from '../components/card/cardReducers'
import notificationReducers from '../components/notification/notificationReducers';
import fraudReducers from '../components/AdminComponents/fraud_management/fraudReducers';
import enterpriseReconReducers from '../components/recon_enterprise/reconReducers';
import tutorialReducers from '../components/tutorials/tutorialReducers';
import mobileOrderReducers from '../components/Mobile/orders/orderReducers';
import mobileMainReducers from '../layouts/Mobile/mobileAppReducer';
import multiwalletReducers from '../components/multiwallet/mutiwalletReducers';

const rootReducer = combineReducers({
  alert: alertReducer,
  login: authReducers,
  signup: signUpReducers,
  orders: orderReducers,
  funds: fundsReducers,
  payment: paymentReducers,
  customers: customerReducers,
  content: contentSettingsReducers,
  users: usersReducers,
  ledger: ledgerReducers,
  recon: reconReducers,
  dashboard: dashboardReducer,
  location: locationReducer,
  payouts: payoutsAllReducer,
  spin: spinReducer,
  modal: modalReducers,
  shipments: shipmentsReducer,
  tooltip: tooltipReducer,
  card: cardReducers,
  notification: notificationReducers,
  fraud: fraudReducers,
  enterpriseRecon: enterpriseReconReducers,
  tutorials: tutorialReducers,
  mobileOrders: mobileOrderReducers,
  child_endpoints: mobileMainReducers, 
  multiwallet: multiwalletReducers,
});

export default rootReducer;