import React from "react";
import { useParams } from "react-router-dom";
import { Breadcrumb, Icon } from "antd";
import MetaTag from "../../../../../../components/meta_tag/meta_tag";
import { RemittanceDetails } from "./remittance-details";
import { useRemittance } from "../api/get-remittance";

export function RemittanceView() {
  const { id } = useParams();
  const { data, isLoading, error: isError } = useRemittance(id);

  return (
    <div
      style={{
        margin: "0 2.5em 2.5em 2.5em",
        display: "flex",
        gap: "2em",
        flexDirection: "column",
      }}
    >
      <MetaTag title="CashBux Remittance" />
      <Breadcrumb>
        <Breadcrumb.Item href="/overview">
          <Icon type="home" />
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/cashbux/remittances">
          Remittance Details
        </Breadcrumb.Item>
        <Breadcrumb.Item>{id}</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ display: "flex", gap: "1em" }}>
        <div>
          <RemittanceDetails
            data={data}
            isLoading={isLoading}
            isError={isError}
          />
        </div>
      </div>
    </div>
  );
}
