import { DashboardActionTypes } from './dashboardAction';

const initialState = {
    dashboardGraph: null,
    dashboard: {},
    loading: false,
    error: ""
}

export default function dashboardReducers(state = initialState, action){
    switch(action.type){
        case DashboardActionTypes.DASHBOARD_REQUEST:
            return{
                ...state,
                loading:true
            };
        case DashboardActionTypes.DASHBOARD_SUCCESS:
            return{
                ...state,
                loading: false,
                dashboard: action.payload
            };
        case DashboardActionTypes.DASHBOARD_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case DashboardActionTypes.DASHBOARD_GRAPH_DATA_REQUEST:
            return {
                ...state,
                dashboardGraph: action.payload
            }   
        default:
            return initialState;
    }
}