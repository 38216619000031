import React, {createRef, useEffect} from 'react'
import {Card, Breadcrumb, Divider, Button, Icon, Affix, Typography, Input, message, Modal} from 'antd'
import Main from '../../layouts/Main'
import { history } from '../../store/history'
import BuxLogo from '../../static/icons/bux_footer.svg'
import Pending from '../../static/icons/pending.svg'
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { Handle401 } from '../handle401/handle401'
import { makeStyles } from './payment_details_design'
import { isMobile } from 'react-device-detect'
import OtcPD from './payment_instruction/otc_pd';
import BankPD from './payment_instruction/bank_pd'
import TestModeBanner from '../cards/test_mode/testModeBanner';
import ExpiredLink from './expiredLink'
import { channels } from './payment_const'
import HeaderInstruction from './headerInstruction'
import { AngryHover, AngrySelected, AngryUnselected, 
    HappyHover, HappySelected, HappyUnselected, LoveHover, 
    LoveSelected, LoveUnselected, SadHover, SadSelected, 
    SadUnselected, SmileHover, SmileSelected, SmileUnselected } from '../../static/images/rating_emoji';
import surveyServices from '../micro_survey/surveyServices'
import { ThankYouCircle } from '../../static/images/vouchers'
import moment from 'moment';
import { useScreenshot } from 'use-react-screenshot';
import { GcashInstapay } from '../../static/icons/payment_method_icons/index';
import { MOBILE } from '../constants/constants'
import useCustomMediaQuery from '../../hooks/useCustomMediaQuery'
import { MERCHANT_ACCOUNT_TRANSFERS } from '../constants/constants'

const PaymentDetails = props => {
    const media = useCustomMediaQuery()
    const styles = makeStyles(media)

    const ref = createRef(null)
    const [image, takeScreenshot] = useScreenshot()
    const getImage = () => takeScreenshot(ref.current)

    const [details, setDetails] = React.useState({ 'channel': '711_direct' })
    const [card, showCard] = React.useState(true)
    const [expired, setExpired] = React.useState(null)
    const [hover, setHover] = React.useState(false)
    const [isUnpaidKajabi, setIsUnpaidKajabi] = React.useState(false)
    const testMode = (history.location.pathname.indexOf('/test') > -1);

    const [showFeedback, setShowFeedback] = React.useState(false);
    const [container, setContainer] = React.useState(null);
    const [rating, setRating] = React.useState(0);
    const [hoverState, setHoverState] = React.useState(0);
    const [feedback, setFeedback] = React.useState('');
    const [thankYouSurvey, setThankYouSurvey] = React.useState(false);
    const [survey, setSurvey] = React.useState(false);
    const [modalVisible, setModalVisible] = React.useState(false);

    const jwtToken = localStorage.getItem("jwtToken")

    const isLoggedOut = (localStorage.getItem("jwtToken") === "" || !localStorage.getItem("jwtToken"));

    useEffect(()=>{
        getDetails()
        getCheckoutSurvey();
    },[])

    const channel_icon = details.channel in channels ? channels[details.channel].icon : '';
    const channel_name = details.channel in channels ? channels[details.channel].name : 'BUx';
    const channel_map = details.channel in channels ? channels[details.channel].map : `${process.env.REACT_APP_BASE_URL}/map`;

    const mode = details && details.mode

    async function getDetails() {
        const url = testMode ? '/api/sandbox/' : '/api/payment/'
        try {
            let response = await axios.get(

                process.env.REACT_APP_API_URL + `${url}${props.uid}/`
            );

            console.log(response)

            let search = window.location.search;
            let sparams = new URLSearchParams(search);
            let redirect_true = sparams.get('redirect');
            let refno = sparams.get('refno');
            let status = sparams.get('status');


            if (response.data.code == null && response.data.amount == null) {
                setExpired(true)
            }
            else if (response.data.code == null && response.data.amount != null) {
                const url = testMode ? '/test/checkout/' : '/checkout/';
                history.push(url + props.uid + '/');
            }
            else if (response.data.status === 'Pending'
                && response.data.payload
                && response.data.payload.webhook
                && response.data.payload.webhook === 'kajabi') {
                setIsUnpaidKajabi(true);
                setDetails(response.data);
                setExpired(false)
                showCard(false);
            }
            else if(response.data.redirect_url && !['AD','AP','BS', 'EN'].includes(localStorage.getItem('userType'))){
                if (response.data.channel === "instapay" && (response.data.payload && response.data.payload.ref_number && response.data.payload.account_name)) {
                    console.log(response.data)
                    setDetails(response.data);
                    setExpired(false)
                    showCard(false);
                } else {
                    let redir_url = response.data.redirect_url;
                    if(refno && status){
                        if(redir_url.includes("?")){
                            redir_url = redir_url +'&status='+status+'&refno='+refno;
                        }else{
                            redir_url = redir_url +'?status='+status+'&refno='+refno;
                        }
                    }else if( testMode ){
                        if(redir_url.includes("?")){
                            redir_url = redir_url +'&status=S&refno='+response.data.code;
                        }else{
                            redir_url = redir_url +'?status=S&refno='+response.data.code;
                        }
                    }
                    window.location.href = redir_url;
                }
            }
            // else if(response.data.payload.webhook == "sentro"){
            //     window.location.href = response.data.payload.redirect_url;
            // }
            else {
                console.log(response.data)
                setDetails(response.data);
                setExpired(false)
                showCard(false);
            }


        }
        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    }
    var linkStyle;

    if (hover) {
        linkStyle = {
            color: 'blue',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: media.mobile ? '14px' : '16px',
            lineHeight: '24px',
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    }
    else {
        linkStyle = {
            color: '#000',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: media.mobile ? '14px' : '16px',
            lineHeight: '24px',
        }
    }

    const paymentVia = () => {
        if (details.status == 'Pending' && details.code != null) {
            if (mode == 'wallet') { return `Add Money via ${channel_name}` }
            else { return `Payment via ${isInstapayGcash()? 'GCash InstaPay' : channel_name}` }
        }
        else if (details.status == 'Cancelled') {
            let text;
            if (details.channel === 'ubpcs') {
                text = 'Unsuccessful Payment'
            }

            else {
                text = 'Order Cancelled'
            }
            return text
        }
        else if (details.status == 'Paid') {
            if (mode == 'wallet') { return "Successful Add Money" }
            else { return "Successful Payment" }
        }
        else { return "Order Expired" }
    }

    const onSubmit = async (skip) => {
        
        const params = isLoggedOut? {
            survey_rating: rating,
            feedback: feedback,
            uid: props.uid,
            type: isMobile ? 'mobile' : 'desktop'
        } : {
            survey_rating: rating,
            feedback: feedback,
            code: 'MERCHANTPAYMENT',
            type: isMobile ? 'mobile' : 'desktop',
            uid: props.uid
        }

        try {
            const res = isLoggedOut? await surveyServices.submitCheckoutMicroSurvey(skip === true ? { maybe_later: true, code: 'MERCHANTPAYMENT' } : params) : await surveyServices.submitCheckoutMerchantMicroSurvey(skip === true ? { maybe_later: true } : params);
            console.log(res);
            if( res.status === 'success') {
                if (skip === true) {
                    setShowFeedback(false);
                } else {
                    setThankYouSurvey(true);
                }
            }
        } catch (error) {
            message.error('Survey error try again later')
        }
    }

    const getCheckoutSurvey = async () => {
        const params = {
            uid: props.uid
        }

        try {
            const res = isLoggedOut? await surveyServices.checkoutMicroSurvey(params) : await surveyServices.checkoutMerchantMicroSurvey(props.uid) ;
            
           if (isLoggedOut) {
                if(res && res.status === 'success' && !res.survey_rating) {
                    setSurvey(true);
                } else {
                    setSurvey(false);
                }
           } else {
                if(res && res.status === 'success' && !res.repeat_date) {
                    setSurvey(true);
                } else {
                    if(res && (moment() > moment(res.repeat_date))) {
                        setSurvey(true);
                    } else {
                        setSurvey(false);
                    }
                }
           }

        } catch (error) {
            console.log(error);
            message.error('Errpr try again later');
            setSurvey(false)
        }
    }

    const renderFeedback = () => {
        // const [skip, setSkip] = React.useState(false);
    
        const emojis = [
            {
                key: 1,
                active: AngrySelected,
                inactive: AngryUnselected,
                hover: AngryHover,
            },
            {
                key: 2,
                active: SadSelected,
                inactive: SadUnselected,
                hover: SadHover,
            },
            {
                key: 3,
                active: SmileSelected,
                inactive: SmileUnselected,
                hover: SmileHover,
            },
            {
                key: 4,
                active: HappySelected,
                inactive: HappyUnselected,
                hover: HappyHover,
            },
            {
                key: 5,
                active: LoveSelected,
                inactive: LoveUnselected,
                hover: LoveHover,
            },
        ]

        const renderSmileyRating = () => {

            const ratingChange = (e) => {
                console.log(e)
                setRating(e);
            }
    
            const onMouseHover = (e) => {
                console.log(e)
                setHoverState(e);
            }
    
            const onMouseLeave = () => {
                setHoverState(0);
            }
    
            return (
                <div style={{textAlign: 'center'}}>
                    {emojis.map((e, i) => {
                        return (
                            <a onClick={() => ratingChange(e.key)} style={{padding: '0 2px'}}
                                onMouseLeave={onMouseLeave}
                                onMouseOver={() => onMouseHover(e.key)}
                            ><img alt='sad' src={(hoverState == e.key && rating == e.key) ? e.active : hoverState == e.key ? e.hover :  rating == e.key ? e.active : e.inactive} />
                            </a>
                        )
                    })}
                </div>
            )
        }

        const renderThankYou = () => {
            return (
                <div hidden={!thankYouSurvey} style={{textAlign: 'center'}}>
                    <img alt='Thank you!' src={ ThankYouCircle } style={{padding: '20px 0px'}} />
                    <Typography style={localStyles.thankYouTitle}>Thank You</Typography>
                    <Typography style={localStyles.thankYouMsg}>Thank you for answering this survey. Your feedback is highly appreciated!</Typography>
                    <Button  style={{ ...localStyles.btn, }}
                        onClick={() => setSurvey(false)} >close</Button>
                </div>
            )
        }

        return (
            <Card hidden={!showFeedback}
                style={{width: '266px', height: '100%' }}
                bodyStyle={{padding: '20px'}}
            >
               <div hidden={thankYouSurvey}>
                    <Typography style={localStyles.msg}>How satisfied are you after using BUx?</Typography>

                    <div style={{ padding: '4px 0', width: '100%' }}>
                        {renderSmileyRating()}
                    </div>
                    <div>
                        <Input.TextArea
                            placeholder="Message here"
                            autoSize={{ minRows: 3, maxRows: 4 }}
                            style={{ marginTop: '4px' }}
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value) }
                        />
                    </div>
                    <div style={localStyles.buttonWrapper}>
                        <Button style={{ color: '#F5922F', border: '1px solid #F5922F', height: '40px', background: '#FFF', width: '48%' }} onClick={() => isLoggedOut? setShowFeedback(false) : onSubmit(true)} >Later</Button>
                        <Button  style={{ ...localStyles.btn, width: '48%', opacity: (!rating || rating <= 0)? '.5' : '1' }}
                            onClick={onSubmit} disabled={(!rating || rating <= 0)} >Submit</Button>
                    </div>
               </div>
                {renderThankYou()}
            </Card>
        )
    }

    const isInstapayGcash = () => {
        if ( (details && details.channel === "instapay") && (details && details.payload && details.payload.ref_number) && 
            (details && details.payload && details.payload.account_name) &&
            (details && details.payload && details.payload.receiving_bank)) {
                return true;
            }
        return false;
    }

    return(
        <div ref={setContainer}>
            <div hidden={!expired}>
                <ExpiredLink />
            </div>

            <div style={{ background: '#f4f4f4' }} hidden={expired}>
                <div className="wrapper">
                    <MetaTags>
                        <title>BUx | Payment</title>
                        <meta
                            name="description"
                            content={details.description ? details.description : "Please proceed to the nearest branch and present this barcode or reference number to the cashier."}
                        />
                        <meta property="og:description" content={details.description} />
                        {details.image_url ? <meta property="og:image" content={details.image_url} /> : <meta property="og:image" content="" />}

                    </MetaTags>
                </div>
            </div>

            <TestModeBanner width={'100%'} top={jwtToken == "" || media.mobile ? 60 : 64} right={0} backgroundColor='rgb(244, 246, 249)' height={33} />

            <div style={styles.justifyCenter} hidden={jwtToken == "" || jwtToken == null}>
                <div style={{ ...styles.breadCrumbStyle, top: testMode ? 32 : 0 }}>
                    <div>
                        <Breadcrumb>
                            {
                                mode == 'wallet' ?
                                    <Breadcrumb.Item>
                                        <a onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => history.push(testMode ? '/test/wallet/add_money' : '/wallet/add_money')} style={linkStyle}>Add Money</a>
                                    </Breadcrumb.Item> :
                                    <Breadcrumb.Item>
                                        <a onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => history.push(testMode ? '/test/orders' : '/orders')} style={linkStyle}>Payment Request</a>
                                    </Breadcrumb.Item>
                            }

                            <Breadcrumb.Item style={styles.textStyle}><strong>Customer Receipt</strong></Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div style={{ marginRight: '20px', lineHeight: '24px' }}>
                        <span style={styles.textStyle}><strong>Status</strong>: <img src={Pending} style={{ marginTop: '-1px', marginRight: '5px', display: details.status == "Pending" ? 'inline' : 'none' }} /><span style={styles.textStyle}>{details.status}</span></span>
                    </div>
                </div>
            </div>

            <Modal
                visible={modalVisible}
                footer={false}
                width={382}
                destroyOnClose
                style={{ borderRadius: 8 }}
                onCancel={() => setModalVisible(false)}
                closeIcon={<Icon type="close" />}
            >
                <img width={300} src={image} alt={'Screenshot'} />
                <div style={localStyles.btnDiv}>
                    <button style={{ ...localStyles.btn }} onClick={ () => setModalVisible(false)} >Done</button>
                </div>
            </Modal>
            <div style={styles.downloadReceiptDiv}>
                <div style={{ ...styles.downloadBtnPosition }}>
                    <Button
                        onClick={() => {
                                getImage();
                                setModalVisible(true);
                        }}
                        style={styles.downloadReceiptBtn}
                        type='link'>
                        <Icon
                            type="download"
                            style={{ color: '#F5922F', fontSize: '18px', float: 'left' }}
                            theme="outlined" />
                        Download
                    </Button>
                </div>
            </div>
            <div style={{ display: showCard ? 'flex' : 'none', justifyContent: 'center' }} ref={ref}>
                <div id='payment-card'>
                    {details.payload && details.payload.redirect_url && (
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '80px 0px 20px 0px' }}>
                            <div style={{ width: media.mobile ? '100%' : '600px' }}>
                                <Button
                                    onClick={() => window.location.href = details.payload.redirect_url}
                                    style={styles.redirectUrl}
                                    hidden={isUnpaidKajabi}
                                >
                                    <Icon
                                        type="left"
                                        style={styles.iconStyle}
                                        theme="outlined" />

                                    Back to Home
                                </Button>
                            </div>
                        </div>
                    )
                    }
                    <Card hidden={expired} loading={card} 
                        style={{
                            width: media.mobile ? '100%' : '600px',
                            marginTop:
                                details.payload && details.payload.redirect_url ?
                                    '30px' : '100px'
                        }}
                        bodyStyle={{
                            padding:
                                media.mobile ?
                                    '20px 30px 20px 30px' :
                                    '50px 30px 50px 30px'
                        }}>


                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <img src={props.logo} alt="logo" />

                            <div hidden={details.status == "Cancelled" || details.code == null}>
                                <span 
                                    hidden={channel_name != 'Xendit'} 
                                    style={{ fontWeight: '500', fontSize: '12px', color: '#000', marginRight: '10px' }}
                                >
                                    Powered by
                                </span> 
                                <img 
                                    height={details.channel === 'ubpcs' && '28px'} 
                                    src={channel_icon} 
                                    alt="logo" 
                                    hidden={
                                        details.channel == 'manual_add'
                                        || details.channel == MERCHANT_ACCOUNT_TRANSFERS.UnionBank.code
                                        || details.status == "Expired"
                                    } 
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: '40px' }}>
                            {
                                <div style={styles.headerStyle}>
                                    {
                                        paymentVia()
                                    }
                                </div>
                            }
                        </div>

                        <div style={{ marginTop: '10px' }}>
                            <HeaderInstruction details={details} channel_map={channel_map} channel_name={channel_name} mode={mode} />
                        </div>

                        <div 
                            hidden={
                                details.status != "Pending" 
                                || details.code == null 
                                || details.channel_type != 'OTC' 
                                || [
                                    'gcash', 
                                    'payngo', 
                                    'grabpay', 
                                    'visamc', 
                                    'billease', 
                                    'billease_sn', 
                                    'i2i', 
                                    'manual_add', 
                                    MERCHANT_ACCOUNT_TRANSFERS.UnionBank.code,
                                ].includes(details.channel)
                            }
                        >
                            <div style={{ ...styles.justifyCenter, marginTop: '20px' }}>
                                <img src={details.image_url} style={{ width: media.mobile ? '250px' : '300px' }} />
                            </div>

                            <div style={{ ...styles.justifyCenter, marginTop: '20px' }}>
                                <div style={styles.textStyle}> {details.code}</div>
                            </div>
                        </div>

                        <div style={{ width: '100%', backgroundColor: '#e6eaf0', height: '40px', marginTop: '20px', padding: media.mobile ? '8px 0px 0px 10px' : '8px 0px 0px 50px', marginBottom: details.code == null ? '30px' : '0px' }}>
                            <div style={styles.labelStyle}>Payment Details</div>
                        </div>

                        {
                            details.channel_type == 'OTC' && ![
                                "gcash", 
                                "grabpay", 
                                MOBILE.Maya.code, 
                                "visamc", 
                                "ubpcs", 
                                "billease", 
                                "billease_sn"
                            ].includes(details.channel) ?

                                <OtcPD details={details} map={channel_map} channel_name={channel_name} />
                                :

                                details.channel_type == 'BANKING' || [
                                    "gcash", 
                                    "grabpay", 
                                    MOBILE.Maya.code, 
                                    "visamc", 
                                    "ubpcs", 
                                    "billease", 
                                    "billease_sn"
                                ].includes(details.channel) ?

                                    <BankPD details={details} channel_name={channel_name} isInstapayGcash={isInstapayGcash()} />

                                    :

                                    ''
                        }

                    </Card>
                </div>

            </div>
            <Affix hidden={!survey} target={() => container} style={{position: 'fixed', bottom: 0, right: '10%'}}>
                <Button 
                    style={{
                        boxShadow: !showFeedback && '0px -8px 24px rgba(0, 0, 0, 0.12)',
                        background: '#FFFFFF',
                        borderRadius: '10px 10px 0px 0px',
                        padding: '14px',
                        height: '48px',
                        minWidth: '266px',
                        color: '#2B2D32',
                        fontWeight: '400',
                        fontSize: '16px'
                    }}
                    onClick={() => setShowFeedback(!showFeedback)}
                ><Icon type={!showFeedback? "caret-down" : "caret-up"} />Give us feedback</Button>
                {renderFeedback()}
            </Affix>
        </div>
    )
}

const localStyles = {
    root: {
        padding: '32px 24px 24px 24px'
    },
    title: {
        color: '#2B2D32',
        fontSize: 18,
        fontWeight: '700',
        textAlign: 'left',
        paddingTop: '24px'
    },
    msg: {
        color: '#2B2D32',
        fontSize: 12,
        fontWeight: '500',
        textAlign: 'left',
        padding: '6px'
    },
    label: {
        fontSize: 14,
        fontWeight: '400',
        color: 'rgba(43, 45, 50, 0.8)',
    },
    btn: {
        height: 40,
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        minWidth: '102px',
        background: '#F5922F',
        border: '1px solid #F5922F',
    },
    input: {
        padding: '11px 16px 11px 16px',
        fontSize: 16,
        height: 48,
        margin: '4px 0px 12px 0px',
        color: '#2B2D33'
    },
    buttonWrapper: {
        width: '100%',
        gap: '12px',
        padding: '24px 0 12px 0',
        display: 'flex',
        alignItems: 'center',
        justifyCenter: 'center'
    },
    thankYouTitle: {
        color: '#254A76',
        fontSize: 24,
        fontWeight: '700',
        textAlign: 'center'
    },
    thankYouMsg: {
        color: '#000000',
        fontSize: 14,
        fontWeight: '400',
        textAlign: 'center',
        padding: '6px 0px 24px 0'
    },
}

export default PaymentDetails