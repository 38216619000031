import { SignUpActionTypes } from "./signUpActions";

const initialState = {
  user: {},

  loading: false
};

export default function regReducers(state = initialState, action) {
  switch (action.type) {
    case SignUpActionTypes.SIGNUP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SignUpActionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload
      };
    case SignUpActionTypes.SIGNUP_FAILED:
      return {
        ...state,
        loading: false,
        user: {}
      };
    case SignUpActionTypes.CLEAR:
      return {
        initialState,
        user:{}
      };
    default:
      return initialState;
  }
}
