import React from 'react';
import { Modal, Icon } from 'antd';
import { isMobile } from 'react-device-detect';

const VideoModal = props => {

    const {
        source, openModal, setModalState, segments, smallReso
    } = props;

    const onClose = () => {
        var video = document.getElementById('woocommerceDemo');
        video.pause();
        video.currentTime = 0;

        setModalState(false)
    }

    return(
        <Modal visible={openModal}
            centered
            width={smallReso ? 650 : 950}
            style={{position: 'relative'}}
            closable={false}
            footer={null}>
            <div style={{width: '100%', background: "black"}}>
                <div style={{
                    position: 'absolute',
                    right: 0,
                    top: -64
                }}>
                    <button className={`btn--${segments} btn-height`} 
                        style={{borderRadius: 40}}
                        onClick={() => onClose()} > 
                        <Icon type="close" color="#fff" className="right-8" />
                        Close
                    </button>
                </div>
                <div>
                    <video id="woocommerceDemo" width={isMobile ? "100%" : smallReso ? "600" : "900"} height={smallReso ? "300" : "475"} controls >
                        <source src={source} type="video/mp4"/>
                    </video>
                </div>
            </div>
        </Modal>
    )
}

export default VideoModal;