import React from "react";
import { Typography } from "antd";

export function ServiceNotice({ mode = "Instapay" }) {
  switch (mode) {
    case "Instapay": {
      return <InstapayNotice />;
    }
    case "PesoNet": {
      return <PesoNetNotice />;
    }
    case "AmountLimitError": {
      return <IsInvalidNotice />;
    }
    default: {
      return null;
    }
  }
}

const noticeStyle = {
  title: { fontSize: "12px" },
  paragraph: { fontSize: "12px" },
};

const InstapayNotice = () => {
  return (
    <div>
      <Typography.Title style={noticeStyle.title}>Instapay</Typography.Title>
      <Typography.Paragraph style={noticeStyle.paragraph}>
        <ul>
          <li>
            Transaction limit is <strong>PHP 50,000</strong>.
          </li>
        </ul>
      </Typography.Paragraph>
    </div>
  );
};

const PesoNetNotice = () => {
  return (
    <div>
      <Typography.Title style={noticeStyle.title}>PesoNet</Typography.Title>
      <Typography.Paragraph style={noticeStyle.paragraph}>
        <ul>
          <li>
            Transactions before the 3PM cut-off are processed same banking day.
          </li>
          <li>
            Transactions after cut-off or holidays are processed the next
            banking day.
          </li>
        </ul>
      </Typography.Paragraph>
    </div>
  );
};

const IsInvalidNotice = () => {
  return (
    <div>
      <Typography.Title type="warning" style={noticeStyle.title}>
        Unavailable
      </Typography.Title>
      <Typography.Paragraph type="warning" style={noticeStyle.paragraph}>
        <ul>
          <li>
            The chosen bank only supports <strong>Instapay</strong> or amount
            less than or equal to <strong>PHP 50,000</strong>. Change the bank
            or adjust the amount to proceed.
          </li>
        </ul>
      </Typography.Paragraph>
    </div>
  );
};
