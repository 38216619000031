import React from 'react';
import { Dropdown, Icon, Menu, Table, message, Pagination, Typography } from 'antd';
import moment from 'moment';
import PromoModal from './promo_modal';
import DeleteModal from './delete_model';
import { OTC, OTC_LIST, OTC_NAME_PROMO } from '../../constants/constants';
import { history } from '../../../store/history';


const EndorementTable = (props) => {
  const { data, onUpdate, isLoading, length, selected, sort, onChangePage, currentPage, handleDelete } = props;
  const [minPage, setMinPage] = React.useState(0);
  const [maxPage, setMaxPage] = React.useState(20);
  const [loading, setLoading] = React.useState(false);
  const userType = localStorage.getItem('userType');
  const [promoModal, setPromoModal] = React.useState(false);
  const [modalData, setModalData] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);

  const renderDiscount = (row) => {
    if (row && row.discount_type === 'FI') {
      return <span>&#8369; {row.fixed_discount}</span>
    }
    return <span>{parseInt(row.percentage_discount)} %</span>;
  }

  const renderChannels = (channels) => {
    let rendered_channels = []

    try {
      channels.map((channel, i) => {
        if (OTC_NAME_PROMO[channel].name) {
          rendered_channels.push(OTC_NAME_PROMO[channel].name + ", ");
        }
      });
    } catch (error) {

    }
    return rendered_channels;
  }

  const renderSegments = (segments) => {

    let rendered_segments = []

        if(typeof(segments) == 'string') {
            segments = segments.split(',')
        }
    
        segments && segments.map((segment, i) => {
            // if (OTC_NAME_PROMO[channel].name) {
            //   rendered_channels.push(OTC_NAME_PROMO[channel].name + ", ");
            // }
            rendered_segments.push(displaySegments(segment))
          });
    
        return rendered_segments;
    
  }

  const displaySegments = (segment) => {
     switch (segment) {
      case 'ME_':
        return 'BUxME, ';
      case 'GIG':
        return 'BUxGIG, ';
      case 'SME':
        return 'BUxSME, ';
      case 'BIZ':
        return 'BUxBIZ, ';
      default:
        return 'BUxME, ';
    }
  }

  const EndorsementColumns = [
    {
      title: 'Promo code',
      dataIndex: 'code',
      render: (text, record) => <Typography onClick={() => props.onRowClick(record)} style={styles.boldtext}>{text}</Typography>
    },
    {
      title: 'Promo Name',
      dataIndex: 'promo_name',
      render: (text, record) => <Typography onClick={() => props.onRowClick(record)}>{text}</Typography>
    },
    {
      title: 'Segment',
      dataIndex: 'segments',
      render: (text, record) => renderSegments(text)
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (text, record) => <Typography onClick={() => props.onRowClick(record)}>{text}</Typography>
    },
    // {
    //   title: 'Channel',
    //   dataIndex: 'channels',
    //   render: (text, record) => <Typography style={{ maxWidth: 200, }}>{renderChannels(text)}</Typography>
    // },
    {
      title: 'Promo start',
      dataIndex: 'date_start',
      render: (text, record) => <Typography onClick={() => props.onRowClick(record)}>{moment(text).format('LLL')}</Typography>
    },
    {
      title: 'Promo end',
      dataIndex: 'date_expiry',
      render: (text, record) => <Typography onClick={() => props.onRowClick(record)}>{moment(text).format('LLL')}</Typography>
    },
    {
      title: 'Discount',
      dataIndex: 'percentage_discount',
      render: (text, record) => <Typography onClick={() => props.onRowClick(record)} style={{ whiteSpace: 'nowrap' }}>{renderDiscount(record)}</Typography>
    },
    {
      title: 'Action',
      render: (record) => (
        <Dropdown disabled={disabledActionButton(record)} overlay={() =>
          <Menu>
            <Menu.Item onClick={() => history.push(`/view_promotion/${record.id}#details`)} ><a>View Promotion</a></Menu.Item>
            <Menu.Item onClick={() => (setPromoModal(true) / setModalData(record))} ><a>Edit Promotion</a></Menu.Item>
            <Menu.Item onClick={() => (setDeleteModal(true) / setModalData(record))} ><a style={{ color: '#C0492C' }}>Delete</a></Menu.Item>
          </Menu>} placement="bottomCenter" trigger='click'>
          <Icon style={{ cursor: disabledActionButton(record) ? 'not-allowed' : 'pointer' }} type="ellipsis" />
        </Dropdown>
      )
    }
  ]

  const disabledActionButton = (data) => {
    const ongoingPromo = (moment().isSameOrAfter(data.date_start) && moment().isSameOrBefore(data.date_expiry));
    const enedPromo = moment().isSameOrAfter(data.date_expiry)

    // if((startDate <= moment().year() && moment().year() > endDate) || moment().year() > endDate ) {
    if (ongoingPromo) {
      return true;
    }
    return false;
  }

  const success = (msg) => {
    message.success(msg);
  };

  const errorPrompt = (msg) => {
    message.error(msg);
  };

  const changePage = (e) => {
    onChangePage(e);
  }

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 100)
  }, [])

  return (
    <div>
      <Table
        rowClassName="table-row-light"
        columns={EndorsementColumns}
        dataSource={data['data']}
        loading={isLoading || loading}
        // pagination={false}
        pagination={{ hideOnSinglePage: true, pageSize: 10 }}

      />

      <div align="right" className="top-20 padding-bot-20">
        <Pagination 
          size="small" 
          total={data['total']}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          onChange={changePage}
          defaultCurrent={1}
          current={currentPage}
          pageSize={10}
          />
      </div>

      <PromoModal mode={'edit'} visible={promoModal} onClose={() => setPromoModal(false)} data={modalData} onSuccess={onUpdate} />
      <DeleteModal visible={deleteModal} onClose={() => setDeleteModal(false)} onDelete={() => { handleDelete(modalData); setDeleteModal(false); }} />
    </div>
  )
}

const styles = {
  positive: {
    borderRadius: 4,
    padding: '4px 8px',
    backgroundColor: 'rgb(232, 250, 243)',
    border: '1px solid #1DD28B',
    borderLeft: '5px solid #1DD28B',
  },
  negative: {
    borderRadius: 4,
    padding: '4px 8px',
    backgroundColor: '#f9dbdb',
    border: '1px solid #E24C4C',
    borderLeft: '5px solid #E24C4C',
  },
  pending: {
    borderRadius: 4,
    padding: '4px 8px',
    backgroundColor: 'rgb(253 234 215)',
    border: '1px solid #F5922F',
    borderLeft: '5px solid #F5922F',
  },
  boldtext: {
    color: '#2B2D33',
    fontWeight: '600',
    fontSize: 14
  },
  merchantEmail: {
    color: '#909196',
    fontSize: 14,
    fontWeight: '500',
    lineHeight: '1'
  }
}

export default EndorementTable;