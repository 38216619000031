import React,{ useState, useEffect } from 'react'
import termsStyle from '../../static/css/Terms'
import { Button } from 'antd';
import {TERMS} from './terms_const'
import {history } from '../../store/history'
import axios from 'axios'

const TermsCondition = props => {

    const [initialized, setInitialized] = useState(false);
    const [termsCondition, setTermsCondition] = useState("");
    const terms = termsStyle();


    const [cmsPage, setCMSPage] = useState(false)

    useEffect(()=>{
        fetchTerms()
    },[])

    const fetchTerms = async() => {

        let response = await axios.get(process.env.REACT_APP_API_URL + "/api/content/?key=terms_condition")
            
        if(response.data.content){
            setTermsCondition(response.data.content)
            setCMSPage(true)
            setInitialized(true);
        }

        window.scrollTo(0, 0);
    }

    return(
        <div className={terms.mainBody}>

            <div style={{display: 'flex'}}>
                <Button  onClick={()=> history.goBack()} icon="left" shape="circle" className={terms.button} size="large"/>

                <div className={terms.mainHeader}>TERMS AND CONDITIONS OF USE</div>
            </div>
            
            <div className={terms.mainInstruction}>
                Kindly review these Terms and Conditions of Use before proceeding. Any use of this website creates a binding agreement and acceptance to comply with these Terms and Conditions and your continuous use of the website constitutes your continuing agreement and acceptance of this Terms and Conditions and to any of its future amendments or revisions. If you do not agree to these Terms and Conditions, you may exit this website immediately.
            </div>

            {
                cmsPage ? 
                <div className={terms.body} dangerouslySetInnerHTML={{__html: termsCondition}} hidden={!cmsPage}></div>
                :
                <div className={terms.body}>
                {
                    TERMS.map((item) => {
                        return(
                            <div>
                            <div className={terms.title}>{item.q}</div>
                            <div className={terms.text}>{item.d}</div>
                            </div>
                        )
                    })
                }
                </div>

            }
            <div className={terms.footer}>
                BY CONTINUING, YOU CONSENT TO THE TERMS AND CONDITIONS STATED ABOVE. 
            </div>

        </div>
    )

}

export default TermsCondition