import { Button } from "antd";
import React from "react";

export function GroupButton({ selectedStatus, onSelect }) {
  const statusOptions = ["All", "Success", "Failed", "Pending"];

  return (
    <div style={{ display: "flex" }}>
      {statusOptions.map((status, index) => (
        <Button
          key={status}
          type={status === selectedStatus ? "primary" : "default"}
          onClick={() => onSelect(status)}
          style={{
            fontStyle: "bold",
            borderRadius: "0",
            fontSize: "12px",
            fontWeight: "bold",
            height: "40px",
            borderTopLeftRadius: index === 0 ? "4px" : "0",
            borderTopRightRadius: index === 3 ? "4px" : "0",
            borderBottomLeftRadius: index === 0 ? "4px" : "0",
            borderBottomRightRadius: index === 3 ? "4px" : "0",
          }}
        >
          {status}
        </Button>
      ))}
    </div>
  );
}
