import React, {useEffect} from 'react';
import { Card, Divider, Spin } from 'antd';
import AppBar from '../appBar/AppBar'
import axios from 'axios'
import {isMobile} from 'react-device-detect';
import {history} from '../../store/history'
import Main from '../../layouts/Main'
import pricingStyle from '../../static/css/Pricing'
import MetaTag from '../meta_tag/meta_tag'
import Bullet from '../../static/icons/bullet.svg'

const OrderDetail = props => {

    const [amount, setAmount] = React.useState("")
    const [code, setCode] = React.useState("")
    const [description, setDesc] = React.useState("")
    const [cart, setCart] = React.useState("")
    const [address, setAddress] = React.useState("")
    const [name, setName] = React.useState("")
    const [contact, setContact] = React.useState("")
    const [createdAt, setCreatedAt] = React.useState("")
    const [spinVisibility, setSpinVisibility] = React.useState(true)

    const h = pricingStyle();

    const orderStyle = {
        
        marginTop: '32px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '5px'
      }

    const shippingDateStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#909196',
    }

    const orderCodeStyle = {
        
        fontStyle: 'normal',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#000000',
    }

    const headerStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#000000',
    }

    const bodyStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#000000',
    }

    useEffect(()=>{
        let current_path = window.location.pathname

        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            history.push('/login?next='+current_path)
        }
        else{
            if(isMobile){
                history.push('/mobile/order_details/'+props.match.params.id+'/')
            }
            else{
                getDetails()
            }
        }
        
        
    }, [])

    async function getDetails(){
        try {
            const yourConfig = {
                headers: {
                   Authorization: "Token " + localStorage.getItem("jwtToken")
                }
             }

            let response = await axios.get(
                process.env.REACT_APP_API_URL + '/api/payment/'+ props.match.params.id, yourConfig
            );
            
            
            let data = response ? response.data : []

            if(data.code == null){
                history.push(`/checkout/${props.match.params.id}`)
            }
            
            setAmount(data.amount)
            setCreatedAt(data.created)
            setDesc(data.description)
            setCart(data.cart)
            setAddress(data.address)
            setName(data.name)
            setContact(data.b_phone)
            setCode(data.code)
            setSpinVisibility(false)

            
            

        }
    
        catch (error) {
            console.log(error);
        }
    }
    return(
        <Main>
            <MetaTag title="Payment Details"/>

            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{marginTop: '50px', width: '70%'}}>
                    <div>
                        <a href="/orders" className={h.orderDetail}>Payment Request</a> / <b><span style={{color: '#000000'}}>Payment Details</span></b>
                    </div>
                    <div style={orderStyle}>Payment Details</div>
                    <div style = {{display: 'flex', justifyContent: 'space-between'}}>
                        <span style={shippingDateStyle}>Created on <Spin style={{ display: spinVisibility ? 'inline' : 'none', marginLeft: '10px'}}/> {createdAt}</span>
                        <span>Total: <strong><Spin style={{ display: spinVisibility ? 'inline' : 'none', marginLeft: '15px'}}/> {amount}</strong></span>
                    </div>
                    <div style={{marginTop: '20px', marginBottom: '10px'}}>
                        <Card title={
                            <div>
                                <span style={orderCodeStyle}>
                                    Reference No: 
                                </span> 
                                 <span style={{color: '#2B2D33', fontSize: '16px', paddingLeft: '5px'}}>
                                    <Spin style={{ display: spinVisibility ? 'inline' : 'none', marginLeft: '10px'}}/>{code}
                                 </span>
                            </div>} extra="" >
                            
                            <div style={headerStyle}>Item description</div>
                            <div style={bodyStyle}>{spinVisibility ? <div><br/><Spin/></div> : description }</div>
        
                        </Card>

                    </div>

                    <div style={{display: 'flex', width: '100%'}}>

                            <div align='right' style={{width: '48%', marginRight: '4%'}}>
                                <Card align='left' style={{width: '100%', opacity: cart && cart.length != 0 ? '1' : '0'}}>
                                    <div>
                                        <div style={{color: '#2B2D33', fontSize: '16px'}}>
                                            <b>Item List</b>
                                        </div>
                                    </div>
                                           
                                  { cart ?

                                    cart.map((item, index) => {
                                        return <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px', color: '#2B2D33', fontSize: '14px', borderBottom: index != cart.length -1  ? '1px solid #eee' : ''}}>
                                            <div style={{  padding: '5px 5px 10px 5px' }}><div style={{ display: 'flex' }}><img src={Bullet} style={{ marginRight: '15px'}}/>{ item.name }</div><div style={{ opacity: '0.8', paddingLeft: '22px', fontSize: '12px' }}>QTY: { item.quantity ? item.quantity : "1" }</div></div>
                                            <div style={{ padding: '5px 5px 10px 5px' }}>₱ {item.amount.toLocaleString('en-US', {minimumFractionDigits: 2})}</div>
                                        </div>
                                        })
                                        : ""
                                    }
                                </Card>
                            </div>
                            <div align='right' style={{width: '48%'}}>
                            <Card style={{width: '100%', display: !address ? 'none' : '', marginBottom: '15px', textAlign: 'left'}}>
                                <div>
                                    <div style={{color: '#2B2D33', fontSize: '16px'}}>
                                        <b>Shipping Address</b>
                                    </div>
                                    <div style={{marginTop: '10px', color: '#2B2D33', fontSize: '16px'}}>
                                        <div>{name}</div>
                                    </div>
                                    <div style={{marginTop: '10px', color: '#2B2D33', fontSize: '12px'}}>
                                        <div>{address}</div>
                                    </div>
                                    <div style={{marginTop: '10px', color: '#2B2D33', fontSize: '12px'}}>
                                        <div>{contact}</div>
                                    </div>
                                </div>
                            </Card>
                            <Card align='right' style={{width: '100%', textAlign: 'left'}} title={
                                <div>
                                    <div style={{color: '#2B2D33', fontSize: '16px'}}>
                                        <b>Total Summary</b>
                                    </div>
                                    {/* <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px', color: '#2B2D33', fontSize: '12px'}}>
                                        <div>Subtotal:</div>
                                        <div>{spinVisibility? <Spin style={{ marginLeft: '10px'}}/> : `₱ ${(parseFloat(amount.replace(",","")) - 20).toLocaleString('en-US', {minimumFractionDigits: 2})}` }</div>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px', color: '#2B2D33', fontSize: '12px'}}>
                                        <div>Transaction Fee:</div>
                                        <div>{spinVisibility? <Spin style={{ marginLeft: '10px'}}/>: "₱ 20.00" }</div>
                                    </div> */}
                                </div>} extra="">
                                
                                <div style={{display: 'flex', justifyContent: 'space-between', color: '#2B2D33', fontSize: '12px'}}>
                                    <div>Total: </div>
                                    <div style={{fontSize: '16px'}}>{spinVisibility? <Spin style={{ marginLeft: '10px'}}/> : `₱${amount}` } </div>
                                </div>
                            </Card>
                            </div>
                    </div>
                    {/*
                    <div style={{display: 'flex', width: '100%'}}>
                            <Card align='left' style={{width: '48%', marginRight: '4%', opacity: localStorage.getItem("shipping_enabled") == "true" && localStorage.getItem("admin_shipping_enabled") == "1" ? '1' : '0'}}>
                                <div>
                                    <div style={{color: '#2B2D33', fontSize: '16px'}}>
                                        <b>Shipping Address</b>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px', color: '#2B2D33', fontSize: '12px'}}>
                                        <div>Subtotal:</div>
                                        <div>{amount}</div>
                                    </div>
                                </div>
                                
                                <div style={{display: 'flex', justifyContent: 'space-between', color: '#2B2D33', fontSize: '12px'}}>
                                    <div>Total: </div>
                                    <div style={{fontSize: '16px'}}><Spin style={{ display: spinVisibility ? 'inline' : 'none', marginLeft: '10px'}}/>{amount} </div>
                                </div>
                            </Card>
                            <Card align='right' style={{width: '48%'}} title={
                                <div>
                                    <div style={{color: '#2B2D33', fontSize: '16px'}}>
                                        <b>Total Summary</b>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px', color: '#2B2D33', fontSize: '12px'}}>
                                        <div>Subtotal:</div>
                                        <div><Spin style={{ display: spinVisibility ? 'inline' : 'none', marginLeft: '10px'}}/>{amount}</div>
                                    </div>
                                </div>} extra="">
                                
                                <div style={{display: 'flex', justifyContent: 'space-between', color: '#2B2D33', fontSize: '12px'}}>
                                    <div>Total: </div>
                                    <div style={{fontSize: '16px'}}><Spin style={{ display: spinVisibility ? 'inline' : 'none', marginLeft: '10px'}}/>{amount} </div>
                                </div>
                            </Card>
                    </div> */}
                </div>
            </div>
        </Main>
    )
    
}

export default OrderDetail