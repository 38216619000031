import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Button, Modal, Icon, Input, Typography, Divider } from 'antd'
import { TextField, Grid, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const { TextArea } = Input

const EmailAdvisoryModal = props => {

    let data = props.viewData

    const useStyles = makeStyles(theme => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textArea: {
            marginRight: '100%',
            display: 'flex',
            fontSize: '16px'
        },
        headerText: {
            fontSize: 'x-large',
            padding: '20px 20px 20px 0'
        },
    }));

    const classes = useStyles();

    const [id, setID] = React.useState("");
    const [subject, setSubject] = React.useState("")
    const [headerText, setHeaderText] = React.useState("")
    const [advisoryMessage, setAdvisoryMessage] = React.useState("");


    React.useEffect(() => {
        setID(data.id)
        setSubject(data.subject)
        setHeaderText(data.header)
        setAdvisoryMessage(data.advisory_message)
        setSubject(data.subject)
    }, [data])

    const AdvisoryMessageField = () => {
        return (
            <span dangerouslySetInnerHTML={{ '__html': advisoryMessage.replace(/(?:\r\n|\r|\n)/g, '<br>')}} />
        )
    }

    return (
        <Modal
            key={data.id}
            centered
            visible={props.viewModal}
            width={400}
            onCancel={() => props.closeModal("closeViewModal")}
            closeIcon={<Icon type="close" style={{ color: '#F5922F' }} />}
            footer={null}>
            <Grid container>
                <Grid item={true} lg={12}>
                    <Typography.Title style={styles.title}>
                        {subject}
                    </Typography.Title>
                    <Typography.Paragraph style={styles.content}>
                        Advisory ID: {id}
                    </Typography.Paragraph>
                </Grid>
                <Divider style={styles.divider} />
                <Grid item={true} lg={12}>
                    <Typography.Title style={styles.title}>
                        {headerText}
                    </Typography.Title>
                    <div style={{ overflowY: 'auto', height: '200px' }}>
                        <Typography.Paragraph>
                            <AdvisoryMessageField/>
                            <br />
                        </Typography.Paragraph>
                    </div>
                </Grid>
                <Grid item={true} lg={12} style={{ paddingTop: '16px', paddingBottom: '16px' }}>
                    <Button style={styles.editBtn} block type="primary" onClick={() => props.closeModal("viewToEditModal", data)}>
                        Edit
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    )
}


const styles = {
    title: {
        fontSize: 20,
        color: '#000000',
        fontWeight: 'bold',
        padding: '10px 0px 0px 0px',
    },
    editBtn: {
        height: 48,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 10,
    },
    content: {
        fontSize: 18,
        color: '#000000',
    },
    divider: {
        backgroundColor: "#000000"
    },
}

export default EmailAdvisoryModal;