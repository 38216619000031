import { history } from "../../../store/history";
import NewCheckouBG from "../../../static/icons/newCheckoutBGTop.svg";
import NewCheckouBGTestmode from "../../../static/icons/newBG_checkout_testmode.svg";

const testMode = history.location.pathname.indexOf("/test") > -1;

export const makeStyles = (media) => {
  const isMediaMobile = media.mobile || media.tablet;

  return {
    contentDiv: {
      display: !isMediaMobile && "flex",
      backgroundColor: "#F1F2F6",
      backgroundImage: testMode
        ? `url(${NewCheckouBGTestmode})`
        : `url(${NewCheckouBG})`,
      backgroundRepeat: "no-repeat",
      paddingTop: testMode ? "110px" : !isMediaMobile ? "82px" : "0",
      paddingBottom: isMediaMobile ? "0" : "82px",
      paddingLeft: isMediaMobile ? "0" : "100px",
      paddingRight: isMediaMobile ? "0" : "100px",
      marginTop: isMediaMobile && "60px",
    },
    paymentDiv: {
      width: isMediaMobile ? "100%" : "70%",
      backgroundColor: "#F1F2F6",
      height: "100%",
      background: "transparent",
      marginRight: !isMediaMobile ? "32px" : "8px",
      zIndex: "9",
    },
    paymentMethodDiv: {
      padding: isMediaMobile ? "26px 16px 30% 16px" : "32px",
      borderRadius: isMediaMobile ? "0" : "12px",
      backgroundColor: "#FFF",
      minHeight: isMediaMobile && "972px",
    },
    infoDiv: {
      padding: isMediaMobile
        ? "32px 16px 230px 16px"
        : testMode
        ? "122px 32px 0px 32px"
        : "30px 32px 0px 32px",
      width: "100%",
    },
    infoRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      gap: 4,
    },
    infoLabel: {
      flex: 1,
      color: "#54575F",
    },
    infoValue: {
      flex: 1,
      color: "#2b2d33",
      textAlign: "right",
    },
    webHeader: {
      zIndex: 100,
      width: "30%",
      backgroundColor: "#fff",
      height: "65px",
      borderBottom: "1px solid #D1D5DD",
      display: "flex",
      justifyContent: "center",
      paddingTop: "15px",
      position: "fixed",
      right: 0,
    },

    mobileFooter: {
      position: "fixed",
      bottom: 0,
      height: "auto",
      width: "100%",
      padding: "23px 16px 18px 16px",
      backgroundColor: "#fff",
      boxShadow: "0px -7px 10px rgba(0, 0, 0, 0.14)",
      zIndex: "99",
    },
    expirationDiv: {
      backgroundColor: "#e6eaf0",
      width: "100%",
      height: isMediaMobile && testMode ? "75px" : "65px",
      display: "flex",
      justifyContent: "center",
      paddingTop: isMediaMobile && testMode ? "40px" : "20px",
    },

    paymentMethodCard: {
      // borderRadius: '10px',
      // border: '1px solid #E6EAF0',
      // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      border: 0,
    },
    owner: {
      fontWeight: "bold",
      fontSize: isMediaMobile ? "16px" : "18px",
      color: "#0D3D76",
    },
    header: {
      fontWeight: "bold",
      fontSize: isMediaMobile ? "18px" : "24px",
    },
    normalText: {
      fontWeight: "normal",
      fontSize: isMediaMobile ? "12px" : "16px",
    },
    semiBoldText: {
      fontWeight: "600",
      fontSize: "16px",
    },
    boldText: {
      fontWeight: "bold",
      fontSize: "16px",
    },

    loanText: {
      fontSize: 16,
      lineHeight: "24px",
      color: "#2b2d33",
      textAlign: "center",
      marginTop: 24,
    },

    loanLinkText: {
      color: "#f5922f",
      fontWeight: "bold",
      textDecoration: "underline",
    },

    choicesDisabled: {
      border: "2px solid #e6eaf0",
      boxSizing: "border-box",
      opacity: "0.6",
      borderRadius: "10px",
      background: "rgba(29, 130, 184, 0.06)",
      width: isMediaMobile ? "94px" : "138px",
      height: isMediaMobile ? "114px" : "138px",
      marginTop: "12px",
      position: "relative",
      paddingTop: "20px",
    },

    noteText: {
      fontWeight: "bold",
      fontSize: "11px",
      lineHeight: "24px",
      color: "#2b2d33",
      opacity: "0.8",
    },
    payBtn: {
      width: "100%",
      height: isMediaMobile ? "40px" : "48px",
      color: "#fff",
      fontWeight: "600",
      backgroundColor: "#F5922F",
      border: "1px solid #F5922F",
      borderRadius: isMediaMobile ? "10px" : "4px",
    },

    new: {
      backgroundColor: "#1DD28B",
      height: "24px",
      width: "64px",
      borderRadius: "40px",
      fontSize: "12px",
      color: "#fff1f1",
      textAlign: "center",
      paddingTop: "3px",
      margin: "0px 20px 0px 0px",
    },
    dpSupport: {
      marginTop: "8px",
      display: "flex",
      justifyContent: "space-between",
      padding: 20,
      borderRadius: "10px",
      boxSizing: "border-box",
      border: "1px solid #E6EAF0",
    },
    modalTitle: {
      fontWeight: "bold",
      fontSize: 20,
      lineHeight: "32px",
      color: "#000000",
      opacity: "0.8",
      marginBottom: 16,
    },
    promoFont: {
      color: "#2B2D32",
      fontSize: 16,
    },
    applyPromoBtn: {
      width: isMediaMobile ? "100%" : "24%",
      height: "40px",
      color: "#fff",
      fontWeight: "600",
      backgroundColor: "#F5922F",
      border: "1px solid #F5922F",
      borderRadius: isMediaMobile ? "10px" : "4px",
      margin: isMediaMobile ? "12px 0" : "0 0 0 8px",
    },
    inputDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: isMediaMobile ? "column" : "row",
    },
    exclamation: {
      color: "#77B2F2",
      border: "1px solid #77B2F2",
      borderRadius: 25,
      backgroundColor: "#FFF",
      fontSize: 14,
      height: "fit-content",
      margin: "0px 12px 0 0",
    },
    securedLogo: {
      textAlign: "center",
      paddingTop: 52,
    },
    securedLogoImg: {
      width: 230,
      maxWidth: "100%",
    },
  };
};
