import React, {useEffect} from 'react'
import {message} from 'antd'
import MultiwalletSettings from '../../../../../AdminComponents/users/usersComponent/userHistory/components/multiwallet_settings'
import axios from 'axios'

const SubWalletSettings = props => {

    const [data, setData] = React.useState(null)
    
    const userType = localStorage.getItem("userType")
    const notAdmin = ['SW', 'ME', 'CO'].includes(userType);
    const userHistoytProps = props && props.props && props.props.props;
    const pUid = notAdmin? window.location.pathname.split("/").pop() : userHistoytProps && userHistoytProps.match.params.id;

    React.useEffect(() => {
        fetchUserDetails();
    },[])

    const fetchUserDetails = async () => {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        try {
            const res = await axios.get(
                process.env.REACT_APP_API_URL + '/api/'+pUid+'/balance/',yourConfig
            );
            if(res.data) {
                setData(res.data);
            } else {
                message.error('Error, try again later');
            }

        } catch (error) {
            console.log(error);
            message.error('Error, try again later');
        }
    }

    return(
        <div style={{display: 'flex', justifyContent: 'center'}}>
            {
                data && <MultiwalletSettings data={data} id={pUid}/>
            }
        </div>
    )
}

export default SubWalletSettings