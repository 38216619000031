import axios from 'axios';
import {Handle401} from '../../handle401/handle401';

const authorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const reconServices = {
  getEnterpriseRecon: async (type, start, end) => {
    try {

      const res = await authorizedAxiosRequest().get(`/api/cc_recon/?accounting_status=${type}&filter_type=transaction_date&start=${start}&end=${end}`)
      return res;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  }, 
  getCorporateAccounts: async (type, start="", end="", page=1, search="") => {
    try {
      const res = await authorizedAxiosRequest().get(`/api/admin/users/?segment=${type}&page=${page}&page_size=20&search=${search}&start=${start}&end=${end}&level=5`)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },

  advanceTransaction: async (transaction_id) => {
    try {
      const res = await authorizedAxiosRequest().post(`/api/advancing/transaction/`, {transaction_id: transaction_id})
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },

  advanceDate: async (user_id, target_date, amount_limit) => {
    try {
      let params = {target_date: target_date,
                    amount_limit: amount_limit,
                    user_id: user_id};
      const res = await authorizedAxiosRequest().post(`/api/advancing/date/`, params)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
}


export default reconServices;