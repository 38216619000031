import React from "react";
import { Modal, Button, message, Icon } from "antd";
import axios from "axios";

class UploadModal extends React.Component {
  state = {
    hover: false,
    hover2: false,
    file: null,
    fileName: "",
    uploading: false,
    modal: false,
    fromHere: false,
    uploadProgress: 0,
  };
  fileUpload = React.createRef();

  textStyle = {
    fontWeight: "300",
    color: "#000",
    textAlign: "center",
  };

  authConfig = {
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken"),
    },
  };

  closeAllModal = () => {
    this.setState({
      file: null,
      fileName: "",
    });

    if (this.fileUpload.current) {
      this.fileUpload.current.value = "";
    }

    this.props.handleClose();
  };

  handleFile = (file) => {
    var self = this;
    const mb = 1000000;
    const limit = 10;
    const max_file_size = limit * mb;
    if (file != null) {
      if (file.size > max_file_size) {
        message.error(`File must not be more than ${limit}MB`);
        return;
      }

      self.setState({
        file: file,
        fileName: file.name,
      });
    }
  };

  getPresignedS3Request = async (fileName) => {
    const allocatorURL =
      process.env.REACT_APP_API_URL + `/api/admin/segment_fees/upload/`;
    const params = {
      mode: "get_url",
      upload_file_name: fileName,
    };

    try {
      let response = await axios.patch(allocatorURL, params, this.authConfig);
      const presignedS3Request = response.data.s3_request;
      if (!Object.keys(presignedS3Request).length) {
        throw new Error("Presigned S3 Request is empty");
      }

      return presignedS3Request;
    } catch (err) {
      let msg = err.response ? err.response.data.message : err.message;
      throw new Error(`Allocation of S3 target path failed. ${msg}`);
    }
  };

  uploadFileToS3 = async (presignedS3Request, fileToUpload) => {
    const { url: S3URL, fields: S3Headers } = presignedS3Request;

    const uploadConfig = {
      onUploadProgress: (progressEvent) => {
        const recomputedProgress =
          (100 * progressEvent.loaded) / progressEvent.total;
        this.setState({ uploadProgress: recomputedProgress });
      },
    };

    // Copy BE-generated S3 headers into the FormData we are about to send
    let s3FormData = new FormData();
    for (const header in S3Headers) {
      s3FormData.append(header, S3Headers[header]);
    }
    s3FormData.append("file", fileToUpload);

    try {
      await axios.post(S3URL, s3FormData, uploadConfig);
    } catch (err) {
      let msg = err.response ? err.response.data.message : err.message;
      throw new Error(`Direct file upload to S3 failed. ${msg}`);
    }
  };

  updateBackendFilePath = async (presignedS3Request) => {
    const { path: filePath } = presignedS3Request;

    const url =
      process.env.REACT_APP_API_URL + `/api/admin/segment_fees/upload/`;
    const params = {
      mode: "update_path",
      upload_file_attrib_name: "file",
      upload_file_path: filePath,
    };

    try {
      await axios.patch(url, params, this.authConfig);
    } catch (err) {
      let msg = err.response ? err.response.data.message : err.message;
      throw new Error(`Update of target path failed. ${msg}`);
    }
  };

  triggerAsyncUpload = async () => {
    const triggerURL =
      process.env.REACT_APP_API_URL + `/api/admin/segment_fees/upload/`;
    const triggerConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Token " + localStorage.getItem("jwtToken"),
      },
    };

    const formData = new FormData();

    try {
      let response = await axios.post(triggerURL, formData, triggerConfig);
      if (response.data.status === "failed") {
        throw response;
      }
    } catch (err) {
      let msg = err.response ? err.response.data.message : err.message;
      throw new Error(`Error triggering async upload. ${msg}`);
    }
  };

  handleUpload = async () => {
    this.setState({
      uploadProgress: 0,
      uploading: true,
    });

    try {
      const presignedS3Request = await this.getPresignedS3Request(
        this.state.fileName
      );
      await this.uploadFileToS3(presignedS3Request, this.state.file);
      await this.updateBackendFilePath(presignedS3Request);
      await this.triggerAsyncUpload();

      this.setState({
        uploadProgress: 0,
        modal: false,
        uploading: false,
      });
      this.closeAllModal();
      message.success("File uploaded");
    } catch (err) {
      this.setState({
        uploadProgress: 0,
        uploading: false,
      });
    }
  };

  handleDownload = async () => {
    const endpoint_url =
      process.env.REACT_APP_API_URL + "/api/admin/segment_fees/download/";

    try {
      let response = await axios.get(endpoint_url, this.authConfig);

      const blob = new Blob([response.data], { type: "text/csv" });
      const blobURL = window.URL.createObjectURL(blob);
      const tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.href = blobURL;
      tempLink.download = "segment_fee_template.csv";
      tempLink.setAttribute("target", "_blank");
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    } catch (error) {
      if (error.response.data.message != "") {
        message.error(error.response.data.message);
      } else {
        message.error("Failed to download segment fee template");
      }
    }
  };

  render() {
    return (
      <div>
        <Modal
          visible={this.props.modal}
          footer={false}
          width="400px"
          closable={false}
          bodyStyle={{ padding: "60px 20px 34px 20px" }}
        >
          <div>
            <div style={{ ...this.textStyle, fontSize: "32px" }}>
              Upload your CSV File
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{ ...this.textStyle, fontSize: "16px", width: "297px" }}
              >
                Upload the CSV file with the information you want to import
                here.
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <input
              type="file"
              ref={this.fileUpload}
              style={{ opacity: "0", position: "fixed" }}
              onChange={(e) => this.handleFile(e.target.files[0])}
              onCancel={(e) => this.handleFile(e.target.files[0])}
              accept=".csv"
            ></input>
            <Button
              onClick={() => this.fileUpload.current.click()}
              style={{
                height: "40px",
                border: "1px solid #1DD28B",
                backgroundColor: this.state.hover2 ? "#1DD28B" : "#fff",
                color: this.state.hover2 ? "#fff" : "#1DD28B",
                borderRadius: "80px",
                fontWeight: "600",
                fontSize: "16px",
                width: "164px",
              }}
              hidden={this.state.file != null}
              onMouseEnter={() => this.setState({ hover2: true })}
              onMouseLeave={() => this.setState({ hover2: false })}
            >
              Choose File
            </Button>

            <div
              style={{
                height: "40px",
                display: "flex",
                justifyContent: "space-between",
                border: "1px solid #1DD28B",
                backgroundColor: this.state.hover2 ? "#1DD28B" : "#fff",
                color: this.state.hover2 ? "#fff" : "#1DD28B",
                borderRadius: "80px",
                fontWeight: "600",
                fontSize: "16px",
                width: "164px",
                textAlign: "center",
                padding: "8px",
                alignItems: "center",
              }}
              hidden={this.state.file == null}
            >
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {this.state.fileName}
              </div>
              <Button
                onClick={() => {
                  this.setState({
                    file: null,
                    fileName: "",
                  });
                  this.fileUpload.current.value = "";
                }}
                style={{
                  color: "#fff",
                  borderRadius: "50%",
                  height: "25px",
                  padding: "5px",
                  backgroundColor: "#262f38",
                }}
              >
                <Icon type="close" style={{ display: "flex" }} />
              </Button>
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            <Button type="link" onClick={this.handleDownload}>
              <Icon type="download" />
              Download Template
            </Button>
          </div>

          <div style={{ display: "flex", marginTop: "20px" }}>
            <Button
              style={{
                backgroundColor: this.state.hover ? "#F5922F" : "#fff",
                color: this.state.hover ? "#fff" : "#F5922F",
                fontWeight: "600",
                fontSize: "16px",
                borderRadius: "10px",
                height: "48px",
                width: "49%",
                border: "1px solid #F5922F",
              }}
              onClick={() => {
                this.closeAllModal();
              }}
              onMouseEnter={() => this.setState({ hover: true })}
              onMouseLeave={() => this.setState({ hover: false })}
              disabled={this.state.uploading}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#F5922F",
                borderRadius: "10px",
                height: "48px",
                width: "49%",
                color: "#fff",
                fontWeight: "600",
                fontSize: "16px",
                border: "1px solid #F5922F",
                opacity:
                  this.state.uploading || this.state.file == null ? "0.5" : 1,
                marginLeft: "2%",
              }}
              onClick={() => this.handleUpload()}
              disabled={this.state.file == null}
              loading={this.state.uploading}
            >
              {this.state.uploading
                ? `Uploading ${Math.round(this.state.uploadProgress)}%`
                : "Upload"}
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default UploadModal;
