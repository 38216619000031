import React, {useEffect} from 'react';
import {history} from '../../store/history'
import {Card, Alert, Divider, Input, Select} from 'antd'
import { Grid,TextField } from "@material-ui/core";
import completeOrder from '../../static/css/Complete'
import {MailIcon, Messenger, CopyIcon} from '../../static/icons/index'
import {isMobile} from 'react-device-detect'
import axios from 'axios'
import Main from '../../layouts/Main'
import {Handle401} from '../handle401/handle401'
import MetaTag from '../meta_tag/meta_tag'
import {ADDRESS} from '../constants/constants'

const { Option } = Select;
const APP_BASE_URL = process.env.REACT_APP_BASE_URL
const FB_APP_ID = process.env.REACT_APP_FB_ID
const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL

const CompleteOrder = props => {

    const co = completeOrder();

    const uid = props.match.params.uuid
    const ownerEmail = localStorage.getItem("email")
    const [code, setCode] = React.useState("");
    const [description, setDescription] = React.useState("")
    const [amount, setAmount] = React.useState("")
    const [id, setID] = React.useState("")
    const [firstName, setFirstName] = React.useState("")
    const [lastName, setLastName] = React.useState("")
    const [contact, setContact] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [streetAddress, setStreetAddress] = React.useState("")
    const [barangayAddress, setBarangayAddress] = React.useState("")
    const [cityAddress, setCityAddress] = React.useState("")
    const [provinceAddress, setProvinceAddress] = React.useState("")
    const [countryAddress, setCountryAddress] = React.useState("")
    const [postalCode, setPostalCode] = React.useState("")
    const [shippingFee, setShippingFee] = React.useState("100")
    const [declaredInsurance, setDeclaredInsurance] = React.useState("0")
    const [message, setMessage] = React.useState("")
    const [alertVisibility, setAlertVisibility] = React.useState(false)
    const [alertType, setAlertType] = React.useState("")
    const insuranceFee = parseFloat(declaredInsurance).toFixed(2) * .10
    const totalFee = parseInt(shippingFee) + parseInt(insuranceFee)

    useEffect(() => {

        let current_path = window.location.pathname

        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            history.push('/login?next='+current_path)
        }
        else{
            if(isMobile) {
                history.push(`/mobile/complete_order/${uid}`)
            }
            fetchShippingDetails()
        }

        if (declaredInsurance < 10) {
            setDeclaredInsurance("100")
        }
        
        
    }, [])
    
    function handleCountryAddress(value) {
        setCountryAddress(value)
        
    }
    function handleProvinceAddress(value) {
        setProvinceAddress(value)
        setCityAddress("")
        setBarangayAddress("")
        if(value == "" || provinceAddress == null){
            setShippingFee('100')

        }
        else if(value != "Metro Manila"){
            setShippingFee('150')
        }

        else{
            setShippingFee('100')
        }
    }

    function handleCityAddress(value) {
        setCityAddress(value)
        setBarangayAddress("")
    }

    function handleBarangayAddress(value) {
        setBarangayAddress(value)
    }

    function getProvinces() {
        let provinces = []
        for(var i in ADDRESS){
            provinces.push(<Option value={i}>{i}</Option>);
        }
        return provinces
    }

    function getCities() {
        let cities = []
        if(provinceAddress in ADDRESS){
            for(var i in ADDRESS[provinceAddress]['Municipality_list']){
                cities.push(<Option value={i}>{i}</Option>);
            }
        }
        return cities
    }

    function getBarangay() {
        let baranggays = []
        if(provinceAddress in ADDRESS){
            if(cityAddress in ADDRESS[provinceAddress]['Municipality_list']){
                let baranggay_list = ADDRESS[provinceAddress]['Municipality_list'][cityAddress]['Barangay_list'];
                for(var i=0;i<baranggay_list.length;i++){
                    baranggays.push(<Option value={baranggay_list[i]}>{baranggay_list[i]}</Option>);
                }
            }
        }
        return baranggays
    }

    const fetchShippingDetails = async() =>{

        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try{
            let response = await axios.get(process.env.REACT_APP_API_URL + `/api/shipping_details/${uid}/`, yourConfig);

            console.log(response.data)
            if(response.data.status == "success"){
                setCode(response.data.shipping_details.ref_code)
                setDescription(response.data.shipping_details.description)
                setAmount(response.data.shipping_details.amount)
                setFirstName(response.data.shipping_details.buyer_first_name)
                setLastName(response.data.shipping_details.buyer_last_name)
                setEmail(response.data.shipping_details.email)
                setContact(response.data.shipping_details.contact)
                setStreetAddress(response.data.shipping_details.street_address)
                setBarangayAddress(response.data.shipping_details.barangay_address)
                setCityAddress(response.data.shipping_details.city_address)

                if(response.data.shipping_details.province_address == "" || response.data.shipping_details.province_address == "Metro Manila")
                {
                    setProvinceAddress("Metro Manila")
                    setShippingFee("100")
                }
                else{
                    setShippingFee("150")
                    setProvinceAddress(response.data.shipping_details.province_address)
                }
                setPostalCode(response.data.shipping_details.postal_code)
            }
            else {
                history.push('/orders')
            }
        }
        catch (error) {
            if((error.response)&&(error.response.status == 401)){
                Handle401()
            }
        }
        
    }

    const addUpdateShippingDetails = async() =>{

        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = {
            'uid': uid,
            'first_name': firstName,
            'last_name': lastName,
            'email': email,
            'contact': contact,
            'street_address': streetAddress,
            'barangay_address': barangayAddress,
            'city_address': cityAddress,
            'province_address': provinceAddress,
            'postal_code': postalCode,
            'shipping_fee': totalFee,
            'user_type': ''
        }

        try{
            let response = await axios.post(process.env.REACT_APP_API_URL + `/api/shipping_details/${uid}/`,params, yourConfig);
            if(response.data.status == "success"){
                setMessage("Successfully add/update shipping details")
                setAlertType("success")
                setAlertVisibility(true)
            }
            else{
                setMessage("Failed to add/update shipping details")
                setAlertType("error")
                setAlertVisibility(true)
            }
        }
        catch (error) {
            if((error.response)&&(error.response.status == 401)){
                Handle401()
            }
        }
    
        
    }

   
    return(

        <Main>
        <MetaTag title="Complete Order"/>

        <div className={co.mainBody}>

            <div className={co.menu} align='center'>

                <div align='left' className={co.breadCrumbs}>
                    <a href="/orders" className={co.breadCrumbsHover}>Orders</a> / <b><span style={{color: '#000000'}}>Setup Delivery</span></b>
                </div>

                <div className={co.header} align='left'>
                Setup Shipping Address
                </div>

                <div className={co.completeDiv}>
                    <div className={co.cInfo}>
                        <div>
                            Reference No: <span><b> {code} </b></span>
                        </div>
                        <div>
                            Total: <span><b> &#8369; {parseFloat(amount).toFixed(2)} </b></span>
                        </div>
                    </div>

                    <div align='left' style={{marginTop: '13px'}}>
                    {description}
                    </div>
                </div>

                <div className={co.compleShareDiv} align="left">
                    <div className={co.csbHeader}>
                        Send shipping form to your customer to fill-up their details
                    </div>
                    <Grid container style={{marginTop: '30px'}}>
                        <Grid item lg={4}>
                            { 
                                isMobile ?

                                <button className={co.btnMsgr}
                                    onClick={() => window.location.href =`fb-messenger://share/?link=${APP_BASE_URL}/payment/${ uid }/&app_id=195672864228468` }>

                                    <img src={Messenger} style={{paddingRight: '13px'}} />Send via Messenger
                                </button>
                                
                                    :

                                <button className={co.btnMsgr}
                                onClick={() => window.location.href = `${FB_SHARE_URL}redirect_uri=${APP_BASE_URL}&app_id=${FB_APP_ID}&link=${APP_BASE_URL}/payment/${uid}/`}>
                                <img src={Messenger} style={{paddingRight: '13px'}} />Send via Messenger
                                </button>
                            }
                           
                                
                        </Grid>
                        <Grid item lg={4}>
                            <button className={co.btnEmail}
                                onClick={() => window.location.href= `mailto: ${ownerEmail}`}>
                                <img src={MailIcon} style={{paddingRight: '27px'}}/> Send via Email
                            </button>
                        </Grid>

                        <Grid item lg={4}>
                            <button className={co.btnEmail}>
                                <img src={CopyIcon} style={{paddingRight: '27px'}}/>  Copy form link
                            </button>
                        </Grid>
                    </Grid>
                </div>

                <div className={co.Or}>
                    <Divider>
                        OR
                    </Divider>
                </div>

                <div className={co.shippingForm} align="left">
                    <div className={co.csbHeader}>
                    Fill-up shipping address form
                    </div>
                    <Alert message={message} type={alertType} style={{ margin: '20px 0px 0px 0px' ,display: alertVisibility ? "block" : "none" }}/>
                    <div className={co.completeDiv}>
                        <div className={co.shipHeader}>
                            Shipping to
                        </div>

                        <Grid container style={{paddingTop: '30px'}}>
                            <Grid item lg={6} style={{paddingRight: '15px'}}>
                                <span>First name</span>
                                <Input size="large" value={firstName} onChange={(e)=>setFirstName(e.target.value)} />
                            </Grid>
                            <Grid item lg={6}>
                                <span>Last name</span>
                                <Input size="large" value={lastName} onChange={(e)=>setLastName(e.target.value)} />
                            </Grid>

                            <Grid item lg={6} style={{marginTop:'15px', paddingRight: '15px'}}>
                                <span>Address</span>
                                <Input size="large" value={streetAddress} onChange={(e)=>setStreetAddress(e.target.value)} />
                            </Grid>

                            <Grid item lg={6} style={{marginTop:'15px'}}>
                                <div>Country</div>
                                <Select style={{width: 340}}
                                size="large" defaultValue="ph" onChange={handleCountryAddress}>
                                    <Option value="ph">Philippines</Option>
                                </Select>
                            </Grid>

                            <Grid item lg={6} style={{marginTop:'15px'}}>
                                <div>Province</div>
                                <Select style={{width: 330}}
                                size="large" value={provinceAddress} onChange={handleProvinceAddress}>
                                    {
                                        getProvinces()
                                    }
                                </Select>
                            </Grid>

                            <Grid item lg={6} style={{marginTop:'15px'}}>
                                <div>City</div>
                                <Select style={{width: 340}}
                                    size="large" value={cityAddress} onChange={handleCityAddress}>
                                    {getCities()}
                                </Select>
                            </Grid>

                            <Grid item lg={6} style={{marginTop:'15px'}}>
                                <span>Barangay</span>
                                <Select style={{width: 330}}
                                size="large" value={barangayAddress} onChange={handleBarangayAddress}>
                                    {getBarangay()}
                                </Select>
                            </Grid>


                            <Grid item lg={6} style={{marginTop:'15px'}}>
                                <span>Postal / ZIP code (optional)</span>
                                <Input size="large" value={postalCode} onChange={(e)=>setPostalCode(e.target.value)} />
                            </Grid>
                            
                            <Grid item lg={6} style={{marginTop:'15px', paddingRight: '15px'}}>
                                <span>Mobile Number</span>
                                <Input size="large" value={contact} onChange={(e)=>setContact(e.target.value)} />
                            </Grid>

                            <Grid item lg={6} style={{marginTop:'15px'}}>
                                <span>Email address</span>
                                <Input size="large" value={email} onChange={(e)=>setEmail(e.target.value)} />
                            </Grid>
                        </Grid>
                    </div>
                </div>


                <div className={co.shippingFee} align='left'>
                    <div className={co.csbHeader}>
                        Shipping Fee
                    </div>
                    
                    <div className={co.sfDiv}>
                        <div className={co.sfSubDiv}>
                         <Grid container>
                            <Grid item lg={6}>
                                <span>Insurance Declared Value</span>

                                <Alert message={"Must be atleast 100.00 PHP"} type="error" style={{ borderLeft: '4px solid red',margin: '20px 0px 10px 0px' ,display: insuranceFee < 10 ? "block" : "none"}}/>

                                <Input size="large" type="number" min="10" value={`${parseFloat(declaredInsurance).toFixed(2)}`} onChange={(e)=>setDeclaredInsurance(e.target.value)} />
                            </Grid>
                        </Grid>

                        <div className={co.shippingFeeDesc}>
                        Insurance Declared Value is the cost of a shipped item as stated by its shipper. Minimum Declared value is <b>PHP 10.00 or 1%</b> whichever is higher.
                        </div>

                        <div style={{marginTop: '32px'}}>
                            <div className={co.sfSummary}>
                                <div>Shipping Fee</div>
                                <div>&#8369; {parseFloat(shippingFee).toFixed(2)}</div>
                            </div>

                            <div className={co.sfSummary}>
                                <div>Insurance Fee</div>
                                <div>&#8369; {parseFloat(insuranceFee).toFixed(2)}</div>
                            </div>
                        </div>

                        <Divider/> 
                        
                        <div>
                            <div className={co.sfSummary}>
                                <b>Total Shipping Fee</b>
                                <div>&#8369; {parseFloat(totalFee).toFixed(2)}</div>
                            </div>
                        </div>
                        </div>

                        <div className={co.sfNote}>
                            <div>* Insurance Fee is automatically added to your overall shipping fee.</div>
                            <div style={{marginTop: '10px'}}>* Shipping Fee will be collected directly by the courier rider.</div>
                        </div>

                    </div>
                </div>


                <div className={co.actionBtn}>
                    <Divider/> 
                    <div className={co.btns}>
                        <button className={co.cancelBtn}>
                            Cancel
                        </button> 
                        <button className={co.submitBtn} onClick={addUpdateShippingDetails}>
                            Submit
                        </button> 
                    </div>
                </div>
            </div>


        </div>
        </Main>
    )
}

export default CompleteOrder;