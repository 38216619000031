import React,{ useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button, Typography, Icon } from "antd";
import { TopUpsGaming, TopUpsTelecom, TopUpsTranspo, TopUpsUtilities } from '../../static/icons/dashboard_icons';
import { PaybillsTelecoms, PaybillsElectricity, PaybillsCable, PaybillsWater } from '../../static/icons/dashboard_icons';
import PropTypes from 'prop-types';
import cardActions from './cardActions';
import { history } from '../../store/history';

const EonIconButtons = ({ toggleModal, sideBar, type, isMobile, noEon  }) => {
  const [showLoadCardModal, setShowLoadCardModal] = useState(false);
  const dispatch = useDispatch();
  const no_eon = useState(noEon ? noEon : null)
  // const selectedTopUps = useSelector(state => state.card.selectedTopUps);

  const handleClick = () => {
    
  }

  const topUpsButtons = [
    {
      name: 'Gaming',
      icon: <img src={TopUpsGaming} />,
      key: 'gaming'
    },
    {
      name: 'Telecoms',
      icon: <img src={TopUpsTelecom} />,
      key: 'telecom'
    },
    {
      name: 'Transpo',
      icon: <img src={TopUpsTranspo} />,
      key: 'transpo'
    },
    {
      name: 'Utilities',
      icon: <img src={TopUpsUtilities} />,
      key: 'utilities'
    },
  ]

  const paybillsButtons = [
    {
      name: 'Telecoms',
      icon: <img src={PaybillsTelecoms} />,
      key: 'Telecoms'
    },
    {
      name: 'Electricity',
      icon: <img src={PaybillsElectricity} />,
      key: 'Electricity'
    },
    {
      name: 'Cable',
      icon: <img src={PaybillsCable} />,
      key: 'Cable'
    },
    {
      name: 'Water',
      icon: <img src={PaybillsWater} />,
      key: 'Water'
    },
  ]

  const handleIconClick = (key) => {
    if (type == 'bills-payment') {
      dispatch(cardActions.setSelectedPaybills(key));
      if( isMobile ) {
        history.push({ pathname:'/mobile/bills_payment'});
      } else {
        history.push({ pathname:'/cards/bills_payment', state: {no_eon: '1234'}});
      }
    } else {
      dispatch(cardActions.setSelectedTopUps(key));
      if( isMobile ) {
        history.push({ pathname:'/mobile/top_ups'});
      } else {
        history.push({ pathname:'/cards/top_ups'});
      }
    }
  }

  const IconButton = ({icon, name, id, disabled}) => {
    return (
      <div>
        <div style={{...styles.btnIcon, opacity: noEon === '' || disabled ? '.5' : '1'}}>
          <a disabled={noEon==='' || disabled} onClick={() => handleIconClick(id)}>{icon}</a>
        </div>
        <Typography style={{...styles.iconName, opacity: noEon === '' || disabled ? '.5' : '1' }}>{name}</Typography>
      </div>
    )
  }

  IconButton.propTypes = {
    icon: PropTypes.element,
    name: PropTypes.string,
    key: PropTypes.string,
  };

  const renderButtons = (buttons) => {
    return (
      buttons.map((item, index) => {
        return (
          <IconButton key={index} name={item.name} icon={item.icon} id={item.key} disabled={item.key == 'government' || item.key == 'credit' || noEon==""}/>
        )
      })
    );
  }

  return (
    <div>
      <Card size="small" 
        bordered={false} 
        bodyStyle={styles.cardBody} 
        style={{ borderRadius:`${ sideBar ? '0px 0px 4px 4px' : '4px' }`, 
                 borderTop:  `${ sideBar ? '1px solid #D1D5DD' : null}` }}
      >
        <div style={styles.mainContent}>
        { renderButtons(type == 'bills-payment' ? paybillsButtons : topUpsButtons) }
        </div>
      </Card>
    </div>
  )
}

const styles = {
  regularFont: {
    fontSize: 12,
    color: '#000000'
  },
  input: {
    padding: '11px 16px 11px 16px',
    fontSize: 16,
    height: 48,
    margin: '4px 0px 0px 0px',
  },
  btn: {
    margin: '16px 0px 0px 0px',
    height: 48,
    width: '100%',
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    borderRadius: 10,
    fontSize: 14,
    fontWeight: '600',
  },
  mainContent: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  cardBody: {
    padding: 0,
  },
  iconName: {
    fontSize: 12, 
    color: '#54575F', 
    paddingTop: 8, 
    textAlign: 'center',
    fontWeight: '600'
  },
  btnIcon: {
    width: '100%', 
    border: '1px solid #D1D5DD', 
    borderRadius: 10, 
    padding: 10
  }
}

export default EonIconButtons;