import { get, post } from '../methods';
import { Handle401 } from '../../components/handle401/handle401';

const payoutServices = {
    getPayout: async (start = '', end = '', status, search='', summary='All', email='', is_export=false, page=1) => {
        try {
            const url = `/api/payouts/?start=${start}&end=${end}&status=${status}&search=${search}&summary=${summary}&email=${email}&page=${page}`;
            if(is_export) {
                url = '/api/payouts/?start='+start+'&end='+end+'&status='+status+'&search='+search+'&summary='+summary+'&email='+email+'&is_export='+is_export;
            }
            return (await get(url)).data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
}

export default payoutServices;