import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Handle401 } from '../../../handle401/handle401';
import { Button, Modal, Icon, Input, DatePicker, message, Select, Menu, TimePicker, Typography } from 'antd'
import { TextField, Grid, makeStyles } from '@material-ui/core';
import ArrowDown from '../../../../static/icons/arrow-down.svg'
import { OTC, BANK, MOBILE, BNPL, PAYMENT_MODE } from '../../../constants/constants'

const { TextArea } = Input
const { Option, OptGroup } = Select;
const segments = localStorage.getItem('segments')

const PAYMENT_CHANNELS = [...Object.keys(OTC), ...Object.keys(BANK), ...Object.keys(MOBILE),...Object.keys(BNPL)];
const PAYMENT_CHANNELS_CODES= [...Object.values(OTC), ...Object.values(BANK), ...Object.values(MOBILE),...Object.values(BNPL)];
const PAYOUT_CHANNELS = Object.keys(PAYMENT_MODE).map(function(key){
  return PAYMENT_MODE[key].value;
});
const options = [{ value: 'gold' }, { value: 'lime' }, { value: 'green' }, { value: 'cyan' }];
const CHANNELS = [
    {
      label: "Payment Channels",
      value: 'payment',
      options:
      [
        { label: '711 Direct', value: '711_direct', id: '1' },
        { label: 'Dragonpay', value: 'dragonpay', id: '2' },
        { label: 'BillEase', value: 'billease', id: '3' },
        { label: 'GCash', value: 'gcash', id: '4' },
        { label: 'GrabPay', value: 'grabpay', id: '5' },
        { label: 'Visa/Mastercard', value: 'visamc', id: '6' },
        { label: 'PESONet', value: 'pchc', id: '7' },
      ]
    },
    {
        label: "Payout Channels",
        value: 'payout',
        options:
        [
          { label: 'InstaPay', value: 'instapay', id: '8' },
          { label: 'UnionBank Transfer', value: 'transfers', id: '9' }
        ]
      }
];


function buildPayoutSelection() {
    let channels = { value:'' };
    return channels;
}

const EditEmailAdvisoryModal = props => {

    let data = props.editData;

    const useStyles = makeStyles(theme => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
        },
        textArea: {
            marginRight: '100%',
            display: 'flex',
            fontSize: '16px'
        },
        headerText: {
            fontSize: 20,
            padding: '20px 20px 40px 0px'
        },
        subHeaderText: {
            fontSize: 16,
            padding: '20px 20px 40px 0px',
            fontWeight: 'bold',
        },
    }));

    const classes = useStyles();
    const [id, setID] = React.useState("");
    const [scheduledDate, setScheduledDate] = React.useState("")
    const [subject, setSubject] = React.useState("")
    const [header, setHeader] = React.useState("")
    const [description, setDescription] = React.useState("")
    const [advisoryMessage, setAdvisoryMessage] = React.useState("")
    const [channel, setChannel] = React.useState("")

    

    const ModalActionButton = () => {
        if (Object.keys(props.editData).length == 0) {
            return (
                <button
                    className={`btn--${segments}`}
                    style={{height: '48px', fontWeight: '500', borderRadius: 4, width: '100%'}}
                    block
                    key="submit"
                    type="primary"
                    onClick={() => addContractExpiry()}>
                    Add
                </button>
            )
        } else {
            return (
                <button 
                    className={`btn--${segments}`}
                    style={{height: '48px', fontWeight: '500', borderRadius: 4, width: '100%'}}
                    block
                    key="submit"
                    type="primary"
                    onClick={() => editContractExpiry()}>
                    Save
                </button>
            )
        }
    };

    const SelectChannelButton = () => {
        let PaymentChannels = []
        for (var i = 0; i < PAYMENT_CHANNELS.length; i++) {
            PaymentChannels.push(<Option value={PAYMENT_CHANNELS_CODES[i].code}>{PAYMENT_CHANNELS[i]}</Option>);
        }

        let PayoutChannels = []
        for (var i = 0; i < PAYOUT_CHANNELS.length; i++) {
            PayoutChannels.push(<Option value={PAYMENT_MODE[i].key}>{PAYOUT_CHANNELS[i]}</Option>);
        }
        return (
            <Select
            showSearch
            placeholder="Channels"
            size="large"
            onChange={(value) => onSetChannel(value)}
            value={channel == "" ? "Channels" : channel}
            style={{ width: '100%' }}
        >
            <OptGroup label="Payment Channels">
                {PaymentChannels}
            </OptGroup>
            <OptGroup label="Payout Channels">
                {PayoutChannels}
            </OptGroup>
        </Select>

        )
    };

    React.useEffect(() => {
        setID(data.id);
        setScheduledDate(moment(data.scheduled_date, 'MMMM DD YYYY, h:mm A'));
        setSubject(data.subject);
        setHeader(data.header);
        setDescription(data.description);
        setAdvisoryMessage(data.advisory_message);
        setChannel(data.channel);
    }, [data])

    // API call onSubmit
    async function addContractExpiry() {
        if (!subject || !description || !header || !advisoryMessage || !channel || !scheduledDate) {
            message.error("Please check if all fields have valid values.")
            return null
        }

        try {
            let response = await axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL + '/api/admin/system_notification/email_advisory/',
                data: {
                    channel: channel,
                    subject: subject,
                    description: description,
                    header_text: header,
                    advisory_message: advisoryMessage,
                    scheduled_date: scheduledDate.format('MMMM DD YYYY, hh:mm A')
                },
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            });
            message.success("Email advisory created.", 5)
            props.closeModal("closeEditModal")


        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    Handle401()
                } else {
                    message.error(error.response.data.message);
                }
            } else {
                message.error('No response from backend server!');
            }
        }
    }

    async function editContractExpiry() {
        try {
            let response = await axios({
                method: 'patch',
                url: process.env.REACT_APP_API_URL + '/api/admin/system_notification/email_advisory/',
                data: {
                    target_id: id,
                    channel: channel,
                    subject: subject,
                    description: description,
                    header_text: header,
                    advisory_message: advisoryMessage,
                    scheduled_date: scheduledDate.format('MMMM DD YYYY, hh:mm A')
                },
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            });
            message.success(["Email advisory id:", id, 'updated'].join(' '))
            props.closeModal("closeEditModal")


        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    Handle401()
                } else {
                    message.error(error.response.data.message);
                }
            } else {
                message.error('No response from backend server!');
            }
        }
    }

    // onChange functions

    function onDateChange(date) {
        console.log(date)
        if (date != null) {
            setScheduledDate(date)
        }
    }

    function onSetSubject(text) {
        setSubject(text)
    }

    function onSetHeader(text) {
        setHeader(text)
    }

    function onSetDescription(text) {
        setDescription(text)
    }

    function onSetAdvisoryMessage(text) {
        setAdvisoryMessage(text)
    }

    function onSetChannel(text) {
        setChannel(text)
    }

    function getDisabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().startOf('day');
    }

    /**
    function onSetScope(text) {
        console.log(text)

        if(CHANNELS.find(obj => obj.label === "Payment Channels").options.find(opt => opt.value === text)){
            let selected_scope = CHANNELS.find(obj => obj.label === "Payment Channels").value
            setScope(selected_scope)
        }
        else if (CHANNELS.find(obj => obj.label === "Payout Channels").options.find(opt => opt.value === text)) {
            let selected_scope = CHANNELS.find(obj => obj.label === "Payout Channels").value
            setScope(selected_scope)
        }
    }
    **/


    return (
        <Modal
            key={data.id}
            centered
            visible={props.editModal}
            width={500}
            onCancel={() => props.closeModal("closeEditModal")}
            closeIcon={<Icon type="close" style={{ color: '#F5922F' }} />}
            footer={null}>
            <span className={classes.headerText}>
                Email Advisory
            </span>
            <br />
            <br />
            <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center', }}>
                <Grid item xs={12}>
                    <span className={classes.subHeaderText}>Partners</span>
                </Grid>
                <Grid item={true} lg={12}>
                    <Grid container
                        spacing={2}>
                        <Grid item xs={6}>
                            <div style={{ paddingBottom: '5px' }}>
                                <label style={styles.label}>Payment Channels</label>
                            </div>
                            <SelectChannelButton/>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ paddingBottom: '5px' }}>
                                <label style={styles.label}>Description</label>
                            </div>
                            <Input
                                placeholder="Description"
                                maxLength={100}
                                value={description}
                                style={styles.input}
                                onChange={e => onSetDescription(e.target.value)}
                            />
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <span className={classes.subHeaderText}>Emailer</span>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ paddingBottom: '5px' }}>
                        <label style={styles.label}>Subject</label>
                    </div>
                    <Input
                        placeholder="Subject"
                        maxLength={200}
                        value={subject}
                        style={styles.input}
                        onChange={e => onSetSubject(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <div style={{ paddingBottom: '5px' }}>
                        <label style={styles.label}>Header Text</label>
                    </div>
                    <Input
                        placeholder="Header Text"
                        maxLength={200}
                        value={header}
                        style={styles.input}
                        onChange={e => onSetHeader(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div style={{ paddingBottom: '5px' }}>
                        <label style={styles.label}>Advisory Message</label>
                    </div>
                    <TextArea
                        autoSize={{ minRows: 3, maxRows: 10 }}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        style={styles.input}
                        showCount
                        value={advisoryMessage}
                        onChange={e => onSetAdvisoryMessage(e.target.value)} />
                </Grid>
                <Grid item={true} lg={12}>
                    <div style={{ paddingBottom: '5px' }}>
                        <label style={styles.label}>Scheduled Date</label>
                    </div>
                    <Grid container
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}>
                        <Grid item={true} lg={12}>
                            <DatePicker
                                size="large"
                                style={{ zIndex: 1, width: '100%', height: '40px' }}
                                format='DD/MM/YY hh:mm A'
                                value={scheduledDate}
                                disabledDate={getDisabledDate}
                                placeholder="Scheduled Date"
                                onChange={onDateChange}
                                showTime={{ defaultValue: moment('12:00 PM', 'h:mm A'), format: "h:mm A" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item={true} lg={12} style={{ paddingTop: '16px', paddingBottom: '16px' }}>
                    <ModalActionButton />
                </Grid>

            </Grid>
        </Modal>
    )
}



const styles = {
    root: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'left',
    },
    title: {
        fontSize: 20,
        color: '#2B2D33',
        padding: '32px 0 18px 0',
    },
    cancelBtn: {
        width: '48%',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 10,
        height: 40,
        border: '1px solid #F5922F',
    },
    confirmBtn: {
        height: 48,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 10,
    },
    footerBtn: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 20,
    },
    label: {
        fontSize: 12,
        color: '#212B36',
        padding: "10px 0px 10px 0px",
    },
    input: {
        paddingTop: '8px',
        fontSize: 16,
        color: '#2B2D33',
        height: '40px',
        width: '100%'
    },
    dropdownBtn: {
        zIndex: 1,
        height: '40px',
        width: '100%',
        paddingTop: '8px',
        marginRight: '12px',
        display: 'flex',
        justifyContent: 'space-between',
    }
}
export default EditEmailAdvisoryModal;