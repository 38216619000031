import React from 'react'
import {Button, Layout} from 'antd';
import NotFound from '../../static/img/not_found.svg'
import logo from '../../static/icons/logo.svg';
import {isMobile} from 'react-device-detect'
import * as Style from './styles';
import PageNotFoundMobile from './mobile_404'

const PageNotFound = props => {
    const { Header } = Layout;

    return(
        <div>
            {
                isMobile ? 
                <PageNotFoundMobile/>
                :
                <div>
                    <Header style={Style.headerStyle}>
                        <img src={logo} style={Style.logoStyle} alt="#" />
                    </Header>
                    <div style={isMobile ? Style.mobileMainDiv : Style.mainDiv}>
                        <div>
                            <img src={NotFound} alt="#" />
                        </div>
                        <div style={isMobile ? Style.mobileChildDiv : Style.childDiv}>
                            <div style={Style.verifyStyle}>Page not found</div>
                            <div style={Style.textStyle}>The page you’re looking for doesn’t exist.</div>
                            <Button type="primary" style={Style.signupBtnStyle} onClick = {() => window.location.href = '/login'}>Back to Home</Button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default PageNotFound
