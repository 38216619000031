import {
    GET_SINGLE_PAYOUT_BEGIN,
    GET_SINGLE_PAYOUT_SUCCESS,
    GET_SINGLE_PAYOUT_ERROR,
} from './fundsConstants';

const initialState = {
    single_payouts: [],
    loading: false,
    error: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SINGLE_PAYOUT_BEGIN:
            return {...state, loading: true};
        case GET_SINGLE_PAYOUT_SUCCESS:
            return {...state, loading: false, single_payouts: action.payload};
        case GET_SINGLE_PAYOUT_ERROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state;
    }
}

export default reducer;