import React, {useEffect} from 'react'
import { Typography, Button, DatePicker, Select, Icon, message } from 'antd'
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment'
import axios from 'axios'

//Components
import StagesTable from './table'
import CheckList from './modals/checklist_modal'
import CreateEntryModal from './modals/create_entry_modal'
import ExportModal from './modals/export_modal';
import { history } from '../../../../store/history';

const KYBStages = () => {
    const ButtonGroup = Button.Group
    const { Option } = Select;

    //Local storages data
    const segments = localStorage.getItem('segments')
    const userType = localStorage.getItem("userType");

    //Entries data
    const [merchantEntries, setMerchantEntries] = React.useState([])
    const [pageSummary, setPageSummary] = React.useState({page: 1, page_total: 0, total_data: 0, pages: 0})

    //States
    const [selectedSegments, setSelectedSegments] = React.useState(['SME', 'BIZ'])
    const [selectedTab, setSelectedTab] = React.useState("signup")
    const [selectedBusinessType, setSelectedBusinessType] = React.useState("All")
    const [selectedKYBStatus,setSelectedKYBStatus] = React.useState("All")
    const [loading, setLoading] = React.useState(false)
    const [createModal, setCreateModal] = React.useState(false) 
    const [exportModal, setExportModal] = React.useState(false) 
    const [emailReceipt, setEmailReceipt] = React.useState("")

    //Start Date & End Date
    const [startMoment, setStartMoment] = React.useState("")
    const [startDate, setStartDate] = React.useState("")
    const [endMoment, setEndMoment] = React.useState("")
    const [endDate, setEndDate] = React.useState("")
    const [endOpen, setEndOpen] = React.useState(false)

    //Pagination
    const [currentPage, setCurrentPage] = React.useState(1)
    const [pageSize, setPageSize] = React.useState(10)

    // id to tag
    const [idToTag, setIdToTag] = React.useState(null);

    const createRole = ['AD', 'SD', 'BS']
    const kybTypes = ['SME', 'BIZ']

    const tabs = [
        {
            text: 'Account Sign up',
            value: 'signup'
        },
        {
            text: 'Pending Application',
            value: 'pending'
        },
        {
            text: 'Review Application',
            value: 'review'
        },
        {
            text: 'System Activated',
            value: 'live'
        },
    ]

    useEffect(()=>{
        fetchData(1, pageSize, selectedSegments, selectedTab, selectedBusinessType, startDate, endDate)
    },[])

    //Functions
    const fetchData = async(page, pageSize, segment, status, type, start, end, kyb_status) => {
        setLoading(true)
        setMerchantEntries([])

        let businessType = type

        if(status == 'review'){
            businessType = "All"
        }

        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        };

        let response = await axios.get(
            process.env.REACT_APP_API_URL + `/api/merchant_entries/?&page=${page}&page_size=${pageSize}&segment=${segment}&status=${status}&business_type=${businessType}&start=${start}&end=${end}&kyb_status=${kyb_status}`,
            yourConfig
        );

        if(response.data){
            setMerchantEntries(response.data.results)
            setPageSummary({
                page: response.data.page,
                page_total: response.data.page_total,
                total_data: response.data.total_data,
                pages: response.data.pages,
            })
            setLoading(false)
        }
    }

    const exportData = async() => {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        };

        let response = await axios.get(
            process.env.REACT_APP_API_URL + `/api/merchant_entries/?&page=${currentPage}&page_size=${pageSize}&segment=${selectedSegments}&status=${selectedTab}&business_type=${selectedBusinessType}&start=${startDate}&end=${endDate}&kyb_status=${selectedKYBStatus}&export=true&email=${emailReceipt}`,
            yourConfig
        );

        if(response.data.status == 'success'){
            message.success(response.data.message)
            setExportModal(false)
        }
    }
    //Date Filter
    const disabledStartDate = startValue => {
        if (!startValue || !endMoment) {
          return false;
        }
        return startValue && startValue > moment().endOf('day');
    };
    const disabledEndDate = endValue => {
        if (!endValue || !startMoment) {
          return false;
        }
        return endValue.valueOf() <= startMoment.valueOf();
    }; 
    const onStartChange = value => {
        if(value != null){
          setStartMoment(value)
          setStartDate(value.format("MM-DD-YYYY"));
        }
        else{
          setStartMoment(null);
          setStartDate("");
        }
    }
    const onEndChange = value => {
        if(value != null && startDate != ""){
          setEndMoment(value);
          setEndDate(value.format("MM-DD-YYYY"));
        }
        else{
          setEndMoment(null);
          setEndDate("");
        }
    }
    const handleStartOpenChange = open => {
        if (!open) {
          setEndOpen(true);
        }
    };
    const handleEndOpenChange = open => {
        setEndOpen(open)
    };

    const isFiltered = () => {
        return selectedBusinessType != 'All' || selectedKYBStatus != 'All' || startDate != "" || endDate != ""
    }
    const clearFilter = () => {
        setSelectedBusinessType("All")
        setSelectedKYBStatus("All")
        setStartDate("")
        setStartMoment(null)
        setEndDate("")
        setEndMoment("")
        fetchData(1, pageSize, selectedSegments, selectedTab, "All", "", "", "All")
    }

    const applyFilter = () => {
        fetchData(1, pageSize, selectedSegments, selectedTab, selectedBusinessType, startDate, endDate, selectedKYBStatus)
    }

    const updateSegments = (segment, currentSelected) => {
        let newSelected = []

        if(currentSelected.includes(segment)){
            newSelected = currentSelected.filter(function(item){
                return item !== segment
            })
            setSelectedSegments(newSelected)
        }
    
        else{
            newSelected = currentSelected.push(segment)
            setSelectedSegments(selectedSegments => [...selectedSegments, segment])
        }
    }

    const reloadData = () => {
        fetchData(currentPage, pageSize, selectedSegments, selectedTab, selectedBusinessType, startDate, endDate, selectedKYBStatus)
    }

    async function createKyb() {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let response = await axios.get(process.env.REACT_APP_API_URL + '/api/kyb/', yourConfig);
        console.log(response)
        localStorage.setItem('KYBType', 'creation')
        localStorage.setItem("enterpriseId", 0);
        history.push('/upgrade_enterprise')
    }

    return(
        <div style={style.root}>
            <CreateEntryModal visibility={createModal} setVisibility={setCreateModal}
                reloadFetch={() => reloadData()} setIdToTag={(id) => setIdToTag(id)} />
            <ExportModal 
                segments={segments} 
                emailReceipt={emailReceipt} 
                setEmailReceipt={setEmailReceipt} 
                exportModal={exportModal}
                hideModal={()=>setExportModal(false)}
                exportNow={()=>exportData()}
            />
            <Typography style={style.headerText}>KYB Submission</Typography>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '27px'}}>
                <div style={{display: 'flex'}}>
                    {
                        kybTypes.map((item, i) => {
                            return(
                                <Button
                                    onClick={()=>updateSegments(item, selectedSegments)} 
                                    style={{...style.quickFilterBtn, 
                                        backgroundColor: selectedSegments.includes(item) ? '#0A315E' : '#FFF',
                                        border: selectedSegments.includes(item) ? '0px' : '1px solid #0A315E',
                                        color: selectedSegments.includes(item) ? '#FFF' : '#0A315E',
                                        marginRight: i == 0 && '10px'}}
                                >
                                    {`BUx${item}`}
                                </Button>
                            )
                        })
                    }
                </div>
                <div style={{display: 'flex', gap: '12px'}}>
                    <Button onClick={()=>setExportModal(true)} style={{...style.downloadCSV}}>
                        <Icon type="download" style={{color: '#0A315E', marginRight: '5px'}}/> Download CSV
                    </Button>
                    <button
                        hidden={!createRole.includes(userType)}
                        className={`btn--${segments} btn-height`}
                        onClick={() => createKyb()}
                    >
                        Create New Account
                    </button>
                    <Button onClick={()=>setCreateModal(true)} style={style.createMerchant}>Create merchant entry</Button>
                </div>
            </div>
            <div style={{marginTop: '44px', display: 'flex'}}>
                <div style={{width: '100%'}}>
                    <ButtonGroup style={{display :'flex'}}>
                    {
                        tabs.map((tab, i) => {
                            return (
                            <Button
                                value={tab.value}
                                onClick={()=>{
                                    setSelectedTab(tab.value);
                                    fetchData(1, 10, selectedSegments, tab.value, selectedBusinessType, startDate, endDate, selectedKYBStatus)
                                }}
                                style={{
                                    ...style.btnGroup, 
                                    color: selectedTab == tab.value ? '#0A315E' : '#909196',
                                    borderBottom: selectedTab == tab.value ? '2px solid #0A315E' : '1px solid #D4D5D8'
                                }}
                                >
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                    {tab.text} 
                                    </div>
                            </Button>            
                            )
                        })
                    }
                    </ButtonGroup>
                </div>
                <div style={style.lineDiv}></div>
            </div>
            <div style={style.body} className="top-32">
                
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Typography style={style.filter}>Filter</Typography>
                    <a style={style.clearBtnDiv} hidden={!isFiltered()} onClick={()=>clearFilter()}>
                        <Icon style={style.clearIcon} type="close-circle" theme="filled" />
                        <span style={style.clearBtn}>Clear Filter</span>
                    </a>
                </div>
                
                <div style={{ display: 'flex', justifyContent:'flex-start', padding: '16px 0px 24px 0px' }}>
                    {
                        selectedTab == 'review' ? 
                        <Select 
                            size="large"
                            value={selectedKYBStatus}
                            suffixIcon={<Icon type="caret-down" />}
                            onChange={(e)=> setSelectedKYBStatus(e)}
                            style={{width: '180px', zIndex: 1, color: '#000'}}
                            >
                            <Option value="All">All</Option>
                            <Option value="8">Submitted</Option>
                            <Option value="9">Resubmitted</Option>
                            <Option value="10">Rejected</Option>
                        </Select>  :
                        <Select 
                            size="large"
                            value={selectedBusinessType}
                            suffixIcon={<Icon type="caret-down" />}
                            onChange={(e)=> setSelectedBusinessType(e)}
                            style={{width: '180px', zIndex: 1, color: '#000'}}
                            >
                            <Option value="All">Business Type</Option>
                            <Option value="0">Sole Proprietorship</Option>
                            <Option value="1" >Corporation</Option>
                            <Option value="2">Partnership</Option>
                        </Select>    
                    }
                    
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledStartDate}
                        value={startMoment}
                        size="large"
                        picker="month"
                        onChange={onStartChange}
                        onOpenChange={handleStartOpenChange}
                        placeholder='Start Date'
                        style={style.datePic}
                        suffixIcon={<div style={style.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <span style={{ paddingTop: 8 }}>-</span>
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledEndDate}
                        value={endMoment}
                        size="large"
                        picker="month"
                        onChange={onEndChange}
                        onOpenChange={handleEndOpenChange}
                        placeholder='End Date'
                        open={endOpen}
                        style={style.datePic}
                        suffixIcon={<div style={style.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <button
                        className={`btn--${segments}`} 
                        style={style.applyBtn} 
                        onClick={()=>applyFilter()}
                    >Apply</button>
                </div>
                <StagesTable
                    loading={loading}
                    reloadData={()=>reloadData()}
                    merchantEntries={merchantEntries}
                    selectedTab={selectedTab}
                    entryIdTotag={idToTag} />
                {
                        merchantEntries.length > 0 &&
                        <div style={{padding: '20px 32px 20px 32px', display:'flex', justifyContent:'space-between'}}>
                            <div>
                                {(currentPage - 1) * pageSize  + 1} 
                                - 
                                {(currentPage - 1) * pageSize  + pageSummary.page_total} out of {pageSummary.total_data}
                            </div>
                            <div style={{display: 'flex'}}>
                                <Select 
                                    size="small" 
                                    style={{width: '84px'}} 
                                    suffixIcon={<Icon type="caret-down" />}
                                    value={pageSize}
                                    onChange={(e)=>
                                    {
                                        setPageSize(e);
                                        fetchData(1, e, selectedSegments, selectedTab, selectedBusinessType, startDate, endDate, selectedKYBStatus)
                                    }}>
                                    <Option value={5}>5</Option>
                                    <Option value={10}>10</Option>
                                    <Option value={15}>15</Option>
                                    <Option value={20}>20</Option>
                                </Select>
                                <Pagination 
                                    count={pageSummary.pages} 
                                    shape="rounded"
                                    page={currentPage} 
                                    size="small"
                                    onChange={(event, page)=>{
                                        setCurrentPage(page);
                                        fetchData(page, pageSize, selectedSegments, selectedTab, selectedBusinessType, startDate, endDate, selectedKYBStatus)
                                    }}
                                />
                            </div>
                        </div>
                    }
                </div>
            
        </div>
    )
}

const style = {
    root:{
        padding: '32px 50px 32px 32px'
    },
    body:{
        width: '100%',
        padding: '32px',
        backgroundColor: '#FFF'
    },
    headerText: {
        fontWeight: '700',
        fontSize: '24px',
        color: '#2b2d32',
        lineHeight: '20px'
    },
    quickFilterBtn: {
        padding: '0px 12px 0px 12px',
        height: '36px',
        borderRadius: '20px',
        color: '#0A315E',
        color: '#FFF',
        fontSize: '16px'
    },
    downloadCSV: {
        width: '166px',
        height: '40px',
        border: '1px solid #254A76',
        borderRadius: '4px',
        color: '#254A76',
        fontWeight: '500'
    },
    createMerchant: {
        width: '221px',
        height: '40px',
        backgroundColor: '#0A315E',
        borderRadius: '4px',
        color: '#FFF',
        fontWeight: '500'
    },
    applyBtn: {
        zIndex: 1, 
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: 72,
        cursor: 'pointer',
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    datePic: {
        zIndex: 1, 
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 178,
        padding: '0 8px 0 8px'
    },
    clearBtnDiv: {
        backgroundColor: '#E9EEF4',
        borderRadius: 20,
        padding: '6px 12px',
        margin: '0 12px',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #D4D5D8'
    },
    clearBtn: {
        color: '#2b2d32',
        fontSize: 14,
        paddingLeft: 4,
        fontWeight: '500',
        opacity: '0.8'
    },
    clearIcon: {
        color: '#2b2d32',
        opacity: '0.8',
        fontSize: 12,
    },
    //Tabs
    btnGroup: {
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center',
        height: 60,
        padding: '5px 16px 20px 16px',
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        borderRadius: '0px'
    },

    lineDiv: {
        height: '60px', 
        borderBottom: '1px solid #D4D5D8', 
        width: '170%',
    }
}

export default KYBStages