import React,{ useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import EonCard from './eonCard';
import LearnMoreModal from './modals/learnMoreModal';
import OrderCard from "./orderCard";
import TransactionHistory from "./transactionHistory";
import TransactionHistoryTable from './transactionHistoryTable';
import cardServices from './cardServices';
import cardActions from './cardActions';
import eonImage from "../../static/images/eon_text_image.svg";
import CardSettings from './cardSettings';
import axios from 'axios'
import { Handle401 } from '../handle401/handle401'
import MetaTag from '../meta_tag/meta_tag'
import { isMobile } from 'react-device-detect';
import { history } from '../../store/history';
import NoEon from './noEon';
import { ErrorCard } from '../../static/icons/dashboard_icons';
import { Spin, Affix, Typography } from "antd";

const Card = () => {
  const [visibleLearnModal, setVisibleLearnModal] = useState(false);
  const dispatch = useDispatch();
  // const isLockCard = useSelector(state => state.card.cardLock);
  const [lockCard, setLockCard] = React.useState(null);
  const cardInfo = useSelector(state => state.card.cardInfo);
  const [buxBalance, setBuxBalance] = React.useState("0.00");
  const [expiryDate, setExpiryDate] = React.useState("");
  const [cardNumber, setCardNumber] = React.useState("");
  const [noEon, setNoEon] = React.useState(null);
  const [initialized, setInitialized] = useState(false);
  const [lockLoading, setLockLoading] = useState(false);
  const [lockCardError, setLockCardError] = useState(false);
  const [custId, setCustId] = useState(null);

  if (!initialized) {

    const current_path = window.location.pathname
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
        history.push('/login?next=' + current_path)
    } else {
      if (isMobile) {
        history.push('/mobile/card');
      } else if(localStorage.getItem("userType") === "ME" || localStorage.getItem("userType") === "CO") {
        setInitialized(true);
      } else {
        history.push('/dashboard');
      }
    }

  }

  const toggleLearnModal = () => {
    setVisibleLearnModal(!visibleLearnModal);
  }

  const handleLockCard = () => {
    setLockCard(!lockCard)
    callLock(!lockCard);
    dispatch(cardActions.setCardLock(!lockCard));
  }

  const callLock = async (status) => {
    setLockLoading(true);
    try {
      const response = await cardServices.callLock(status);
      // console.log(response);
      if (response.status == 'success') {
        
      } else {
        setLockCard(lockCard)
        dispatch(cardActions.setCardLock(lockCard));
        setLockCardError(true)
        setTimeout(() => {
          setLockCardError(false)
        }, 3000);
      }
      setLockLoading(false);
    } catch (error) {
      setLockCard(lockCard)
      dispatch(cardActions.setCardLock(lockCard));
      setLockLoading(false);
      setLockCardError(true)
      setTimeout(() => {
        setLockCardError(false)
      }, 3000);
      console.error(error);
    }
  }

  const getCardInfo = async () => {
    try {
      const response = await cardServices.getCardInfo();
      dispatch(cardActions.setCardInfo(response));
      setBuxBalance(response.bux_balance);
      setNoEon(response.lastFourDigits)
      setCustId(response.customer_id)
      if(localStorage.getItem('Level') >= 0 && !response.customer_id) {
        history.push('/dashboard')
      }
      if(response.status == '1'){
        setLockCard(false);
        dispatch(cardActions.setCardLock(false));
      }else{
        dispatch(cardActions.setCardLock(true));
        setLockCard(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
      // getHistory();
      getCardInfo();
  }, []);

  const errorCard = (
    <div style={{display: 'flex', flexDirection: 'column', padding: 50 }}>
      <img alt={'No Image'} src={ErrorCard} style={{padding: '0 0 16px 0'}} />
      <Typography style={styles.disabledText}>We're sorry</Typography>
      <Typography style={styles.disabledText}>Unavailable at the moment</Typography>
      <Typography style={{...styles.disabledText, padding: '16px 0 0 0', fontWeight: 'bold'}}>Please try again later</Typography>
    </div>
  )
  
  return (
    <div style={styles.root}>
      <MetaTag title='Card' />
      <LearnMoreModal show={visibleLearnModal} closeModal={toggleLearnModal} />
      <div hidden={cardNumber === "" && noEon == '' && !custId} style={styles.title}>
        <strong style={{...styles.regularFont, marginBottom: '-8px'}}>BUx E-Money Account</strong>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Typography style={{fontSize: 12, color: '#909196'}}>Powered by</Typography>
          <img src={eonImage} style={{ width: 50, height: 38, paddingLeft: 2 }} />
        </div>
      </div>
      { cardInfo && lockCard !== null && noEon !== '' ?
        <div style={styles.content}>
          <div style={styles.card}>
            <div style={styles.myCard}>
              <EonCard toggleModal={toggleLearnModal} 
                sideBar={false} 
                cardActivated={true}
                lockCard={lockCard}
                noEon={cardInfo ? cardInfo.lastFourDigits : ''}
                cardNumber={cardInfo || cardInfo.lastFourDigits === '' ? cardInfo.lastFourDigits : null} 
                balance={cardInfo ? cardInfo.account.availableBalance : null} 
                expiryDate={ cardInfo ? cardInfo.expiryDate : null}
                buxBalance={buxBalance}
                lockLoading={lockLoading}
                physicalCard={cardInfo ? cardInfo.physical : null}
                custId={custId}
              />
            </div>
            <div style={styles.cardSettings}>
              <CardSettings handleLockCard={handleLockCard} lockCard={lockCard} cardInfo={cardInfo}  lockLoading={lockLoading} lockCardError={lockCardError} />
            </div>
          </div>
          <div style={styles.transaction}>
            {/* <h1>Transaction History</h1> */}
            <TransactionHistoryTable />
          </div>
       </div> 
       :  cardNumber === "" && noEon == '' && custId ?
            errorCard
       :  cardNumber === "" && noEon == '' && !custId ? 
          <NoEon isMobile={isMobile} />
       :  <div style={{ width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin size="large" />
          </div> }
    </div>
  )
}

const styles ={
  root: {
    display: 'flex',
    justifyContent: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '28px 0 0 0px',
    padding: 20,
  },
  title: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'start',
    width: 728,
    padding: '20px 0 0 0',
    flexDirection: 'column',
  },
  regularFont: {
    fontSize: 28,
    color: '#000000',
  },
  myCard:{ 
    width: 352,
    padding: '22px 8px 8px 0',
  },
  cardSettings: {
    width: 352,
    padding: '22px 0 8px 8px',
  },
  orderCard: {
    padding: '8px 0 8px 0',
    width: 352, 
    height: 626
  }, 
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: 728,
  },
  card: {
    display: 'flex',
  },
  transaction: {
    padding: '20px 0px',
    width: 704
  },
  cardStyle: {
    fontWeight: '600',
    position: 'absolute', 
    color: '#FFF',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    top: '68%',
    left: '30%',
  },
  disabledText: {
    color: '#909196', 
    fontSize: 16, 
    textAlign: 'center'
  }
}

export default Card;