import React from 'react';
import { Dropdown, Icon, Menu, Table, message, Pagination, Typography, DatePicker, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import EndorseModal from './endorseModal';
import endorsementServices from './endorsementServices';
import moment from 'moment';
import ArrowDown from '../../../static/icons/arrow-down.svg';
import ApplyModal from './applyModal';
import crypto from 'crypto';

const AddModalTable = ({selected, selectedTab, onSuccess}) => {
  const [dateRange, setDateRange] = React.useState({startDate: '', endDate: ''});
  const [minPage, setMinPage] = React.useState(0);
  const [maxPage, setMaxPage] = React.useState(20);
  const [loading, setLoading] = React.useState(false);
  const [showEndorseModal, setShowEndorseModal] = React.useState(false);
  const [keyToEndorse, setKeyToEndorse] = React.useState(null);
  const user = localStorage.getItem('userType');
  const [tableData, setTableData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(null);
  const [currentPage,setCurrentPage] = React.useState(1);
  const [searchVal, setSearchVal] = React.useState('');
  const accountTypeList = ['Individual', 'Enterprise'];
  const [accountType, setAccountType] = React.useState('SME');
  const [applyModal, setApplyModal] = React.useState(false);
  const [page, setPage] = React.useState('1');
  const [requestToken, setRequestToken] = React.useState(null);
  const [reqReturnToken, setReqReturnToken] = React.useState(null);
  const [summary, setSummary] = React.useState('All');
  const [POStatus, setPOStatus] = React.useState(selectedTab);
  const [targetStatus, setTargetStatus] = React.useState(null);
  const [selectedData, setSelectedData] = React.useState(null);

  const [tName, setName] = React.useState(null);
  const [tNumber, setNumber] = React.useState(null);
  const [tBank, setBank] = React.useState(null);
  const [tAccountType, setBAccountType] = React.useState('SA');
  const [tBranch, setBranch] = React.useState(null);


  const columns = [
    {
      title: 'Trans Id',
      dataIndex: 'id',
    },
    {
      title: 'Email',
      dataIndex: 'email'},
    {
      title: 'Name',
      render: (record) => <div style={{whiteSpace: 'break-spaces', width: 200}}><Typography>{record.business_name ? record.business_name : record.full_name}</Typography><Typography>{record.description}</Typography></div>
    
    },
    {
      title: 'Action',
      render: (record) => (
        <a style={styles.actionLink} onClick={()=> {handleEndorseRow(record.id) }}>Endorse</a>
      )
    }
  ]

  const success = (msg) => {
    message.success(msg);
  };

  const errorPrompt = (msg) => {
    message.error(msg);
  };


  const returnStatus = (key) => {
    switch (key) {
      case 'Paid':
        return 'PA';
      case 'Pending':
        return 'PE';
      case 'Failed':
        return 'FA';
      case 'Success':
        return 'SU';
      case 'Cancelled':
        return 'CA';
      case 'Expired':
        return 'EX';
      default:
        return key;
    }
  }

  const resetBankAccountParams = () => {
    setName(null);
    setNumber(null);
    setBAccountType('SA');
    setBranch(null);
    setKeyToEndorse(null);
  }

  const handleEndorse = async (id) => {
    setLoading(true);

    let format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if(!tBank){
      errorPrompt("Please Select Bank")
    }
    else if(!tBranch){
        errorPrompt("Branch is required")
    }
    else if(!tName){
        errorPrompt("Account Name is required")
    }
    else if(!tNumber){
        errorPrompt("Account Number is required")
    }
    else if(!tAccountType){
        errorPrompt("Please select account type")
    }

    else if(format.test(tName)){
        errorPrompt("Account name should not contain any special characters")
    } else {

      try { 
        let params = {'user_id': id,
                      'bank_account' : {'account_name': tName,
                                        'account_number': tNumber,
                                        'bank': tBank,
                                        'branch': tBranch,
                                        'account_type': tAccountType ? tAccountType : 'SA'}
                    }
        const res = await endorsementServices.endorse(params);
        if(res.status==='success'){
          success(res.message);
          getData(selected);
          setShowEndorseModal(false);
          resetBankAccountParams();
          onSuccess();
        } else {
          errorPrompt(res.message);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        errorPrompt(error);
      }
    }
  }

  const handleEndorseRow = (id) => {
    setKeyToEndorse(id);
    setShowEndorseModal(true);
  }

  const confirmEndorse = () => {
    handleEndorse(keyToEndorse);
  }

  const generateToken = () => {
    let generatedRequestToken = crypto.randomBytes(16).toString('hex');
    setRequestToken(generatedRequestToken);
    return generatedRequestToken
  }

  const getData = async (selected, page) => {
    setIsLoading(true);
    try {
      let res;
      res = await endorsementServices.getUsers(searchVal, page? page : 1, accountType, dateRange.startDate, dateRange.endDate, summary);
      if(res.results) {
        setTableData(res.results);
        setTotalCount(res.total);
      } 
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      console.log('-----------ENDOSELOGG-----', error);
    }
  }

  const handleChangePage = async (page) => {
    getData(selected, page);
  }

  const changePage = (page, pageSize) => {
    setMaxPage(page * pageSize)
    setMinPage((page - 1) * pageSize)
    setCurrentPage(page);
    handleChangePage(page);
  }

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }

  const rangeSelect = (dates, dateString, id) => {
    setDateRange({...dateRange, [id]: moment(dateString).format('MM-DD-YYYY')});
    setSummary('Custom');
    // if(id === 'startDate') {
    //   getData(selected, moment(dateString).format('MM-DD-YYYY'), dateRange.endDate)
    // } else {
    //   getData(selected, dateRange.endDate, moment(dateString).format('MM-DD-YYYY'));
    // }
  };

  const handleSearch = (e) => {
    setCurrentPage(1);
    setSearchVal(e.target.value);
  }

  const handleSelect = (e) => {
    setAccountType(e.target.value);
  }

  const applyFilter = () => {
    getData(selected);
  }

  const resetProps = () => {
    // setDateSearch(false);
    setDateRange({startDate: '', endDate: ''});
    setSummary('All');
    setAccountType('SME');
    setSearchVal('');
  }

  React.useEffect(() => {
    if(selected && selectedTab){
      getData(selected);
    }
    resetProps();
  },[selected])

  React.useEffect(() => {
    if(selected || selectedTab){
      getData(selected);
    }
    resetProps();
  }, [selectedTab])
  
  return (
    <div>
      <div style={styles.dateBtn}>
        <div style={{...styles.inputDiv, width: 210}}>
          <select
            // value={params['natureOfBusiness']}
            onChange={handleSelect}
            value={accountType}
            style={{...styles.selectStyle}}>
                <option value="SME">BUxSME</option>
                <option value="BIZ">BUxBIZ</option>
                {/* {
                  accountTypeList.map((item,i)=>{
                    return(
                    <option key={i} value={i === 1 ? 'ME' : i === 2 ? 'CO' : 'All'} selected={accountType===(i===1? 'ME' : 'CO')} style={{color: '#000'}}>{item}</option>
                    )
                  })
                } */}
          </select>
        </div>
        <DatePicker 
          format='MM/DD/YYYY' 
          disabledDate={disabledDate} 
          // defaultValue={moment()}
          value={dateRange.startDate && moment(dateRange.startDate)} 
          size="large" picker="month" 
          onChange={ (dates, dateString) => rangeSelect(dates, dateString, 'startDate')} 
          placeholder='Start date' 
          style={styles.datePic} 
          suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{fontSize: 22}} /></div>}
          allowClear={false}/>
          <span style={styles.span}>-</span>
        <DatePicker 
          format='MM/DD/YYYY' 
          disabledDate={disabledDate} 
          // defaultValue={moment()}
          value={dateRange.endDate && moment(dateRange.endDate)} 
          size="large" 
          picker="month" 
          onChange={ (dates, dateString) => rangeSelect(dates, dateString, 'endDate')} 
          placeholder='End date' 
          style={styles.datePic}
          suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{fontSize: 22}} /></div>}
          allowClear={false}/>
        <Input
          placeholder="Search"
          style={{ width: '200px', height: '40px', borderRadius:'4px' }}
          suffix={<Icon type="search" style={{fontSize:'18px'}}/>}
          onChange={handleSearch}
          value={searchVal}
          />
        <Button style={styles.applyBtn} onClick={() => applyFilter()}>Apply</Button>
      </div>
      <Table 
        rowClassName="table-row-light"
        columns={columns}
        dataSource={tableData ? tableData : []}
        pagination={{pageSize:10}}
        loading={isLoading || loading}
        pagination={false}
      />
      <Pagination
        size='small'
        total={totalCount}
        defaultPageSize={10}
        defaultCurrent={1}
        current={currentPage}
        onChange={(page, pageSize) => changePage(page, pageSize)}
        style={{padding:'12px 0 8px 0', textAlign: 'right'}}
      />
      <EndorseModal visible={showEndorseModal} 
          closeModal={()=> setShowEndorseModal(false)/resetBankAccountParams()} 
          onEndorse={confirmEndorse} 
          selected={selected} 
          setAccountType={(e) => setBAccountType(e)} 
          setName={(e) => setName(e)} 
          setNumber={(e) => setNumber(e)} 
          setBank={(e) => setBank(e)}
          setBranch={(e) => setBranch(e)}
          keyToEndorse={keyToEndorse}/>
      <ApplyModal visible={applyModal} closeModal={() => setApplyModal(false)} onApply={applyFilter} />
    </div>
  ) 
}

const styles = {
  dateBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 0 20px 0',
  },
  datePic: {
    height: 40,
    fontSize: 14,
    color: '#2F3542',
    width: 178,
    padding: '0 8px 0 8px'
  },
  suffixIcon: {
    marginTop: '-12px', 
    marginRight: 12, 
    color: '#000'
  },
  inputDiv: {
    width: 240,
    padding: '0 8px',
  },
  label: {
    fontSize: 12,
    color: '#212B36',
    padding: '4px 0'
  },
  selectStyle: {
    width: '100%',
    height: '40px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    appearance: 'none',
    background: `url(${ArrowDown})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '95% 50%',
    fontSize: '14px',
    borderColor: '#D1D5DD',
    borderRadius: 4,
    padding: '10px 40px 10px 10px',
  },
  applyBtn: {
    height: 40,
    width: 94,
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 4,
    color: '#FFF', 
    backgroundColor: '#F5922F', 
    borderColor: '#F5922F',
    margin: '0 8px',
  },
  actionLink: {
    color: '#F5922F',
    fontSize: 14,
    textDecoration: 'underline'
  }
}

export default AddModalTable;