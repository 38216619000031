import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button, Select, Table, Divider, DatePicker, Pagination} from 'antd'
import {history} from '../../../store/history'
import { getRecon } from './reconAction';
import moment from 'moment';
import '../../../static/css/antd.css'
import {ExportToCsv} from 'export-to-csv'
import UploadModal from './upload_modal'

export function closeModal(){
  this.setState({
    modal: false
  })
}

const Option = Select
const { MonthPicker } = DatePicker
const ButtonGroup = Button.Group;


const mapStateToProps = (state) => {
  const { recon, cutoffs, loading, error } = state.recon;
  return {
    recon,
    cutoffs,
    loading,
    error,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRecon: bindActionCreators(getRecon, dispatch),
  };
};


class Recon extends React.Component{

  componentDidMount(){
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
      history.push('/login')
    }
    else{
      if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
        history.push('/dashboard')
      }
      else if(localStorage.getItem("userType") == "PS"){
        history.push('/orders')
      }
      else if(localStorage.getItem("userType") == "MA"){
        history.push('/overview')
      }else{
        this.props.getRecon('', '', this.state.page_size, 1, 'xendit');
      }

    }
  }

  constructor(props){
    super(props)
    closeModal = closeModal.bind(this)

  }
  state = {
    cutoff : '',
    page: 1,
    count: 0,
    page_size: 20,
    fileList: [],
    uploading: false,
    alertMessage: '',
    showAlert: false,
    alert: 'success',
    show: 'Unaccounted',
    modal: false
  }

  onChange(date, dateString) {
    let year = dateString.slice(0, 4)
    let month = dateString.slice(5, 7)
    let firstDay = new Date((new Date(year, month, 1)) - 0).getDate()
    let lastDay = new Date((new Date(year, month, 1)) - 1).getDate()
    let firstOfMonth = [month, firstDay, year]
    let endOfMonth = [month, lastDay, year]
    let startDate = firstOfMonth.join('-');
    let cutOffDate = endOfMonth.join('-');

    this.setState({
      startDate: startDate,
      cutoff: cutOffDate,
      page: 1
    });
    this.props.getRecon(startDate, cutOffDate, this.state.page_size, 1, 'xendit');

  }

  pageSelect = (e) => {
    console.log(e)
    this.setState({
      page: e,
    })
    this.props.getRecon(this.state.startDate, this.state.cutoff, this.state.page_size, e, 'xendit');
  }

  changePageSize = (e) => {
    console.log(e)
    this.setState({
      page_size: e,
      page: 1
    })
    this.props.getRecon(this.state.startDate, this.state.cutoff, e, 1, 'xendit');
  }

  getCount = () => {
    let recon = this.props.recon
    switch (this.state.show) {
      case 'Unaccounted':
        return recon.unaccounted.count
      case 'Accounted':
        return recon.accounted.count
      default:
        return 0
    }
  }


  handleChange(selectorFiles: FileList)
  {
      console.log(selectorFiles);
  }

  renderUnaccounted(){
    const {
      recon
    } = this.props;
    if(recon)
      return recon.unaccounted.transactions
    else
      return []
  }

  renderAccounted(){
    const {
      recon
    } = this.props;
    if(recon)
      return recon.accounted.transactions
    else
      return []
  }

  renderMissing(){
    const {
      recon
    } = this.props;
    if(recon)
      return recon.missing.transactions
    else
      return []
  }

  headerStyle = {
    
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    color: '#000000',
    marginBottom: '30px'
  }

  tableHeaderStyle = {
    
    fontStyle: 'normal',
    fontSize: '16px',
    color: '#333333',
    marginBottom: '20px',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '20px',
  }

  columns = [
    {
      title: 'Ref. Code',
      dataIndex: 'ref_code'
    },
    {
      title: 'Total Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Receivable',
      dataIndex: 'receivable',
    },
    {
      title: 'Date',
      dataIndex: 'date_paid',
    },
  ];

  status = [
    'Unaccounted', 'Accounted'
  ]

  exportTable = () => {  
    const {
      recon
    } = this.props;

    let options = { 
      filename: 'Recon_'+moment().format("MM-DD-YYYY_HH:mm:ss")+'_'+this.state.show,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'BUx Recon Xendit',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };
      
    let csvExporter = new ExportToCsv(options);
    let listToExport = []

    if(this.state.show == 'Unaccounted (Bux)'){
      listToExport = recon.unaccounted.transactions
    }
    else if(this.state.show == 'Accounted'){
      listToExport = recon.accounted.transactions
    }

    if(listToExport && listToExport.length > 1){
      csvExporter.generateCsv(listToExport);
    }
  };

  render(){
      return(
        <div style={{ paddingTop: '38px', paddingLeft: '42px', paddingRight: '42px' }}>
          <div style={this.headerStyle}>Xendit Recon</div>
          <div style={{ marginBottom: '25px', display: 'flex', justifyContent: 'space-between' }}>
            <ButtonGroup>
              {
                this.status.map((item) => {
                  return <Button
                    key={item.toLocaleLowerCase()}
                    onClick={() => this.setState({
                      show: item
                    })}
                    style={{
                      padding: '12px 18px 13px 18px',
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '12px',
                      lineHeight:'16px',
                      height:'40px',
                      borderTopLeftRadius: item == "Unaccounted" ? '10px' : '0px',
                      borderBottomLeftRadius: item == "Unaccounted" ? '10px' : '0px',
                      borderTopRightRadius: item == "Accounted" ? '10px' : '0px',
                      borderBottomRightRadius: item == "Accounted" ? '10px' : '0px',
                      color: this.state.show === item ? '#ffffff' : '#2b2d33',
                      backgroundColor: this.state.show === item ? '#0D3D76' : '#fff',
                    }}>{item}</Button>
                })
              }
            </ButtonGroup>
          </div>
          <div style={{ marginBottom: '20px', float: 'right' }}>
            <MonthPicker 
              placeholder="Select Month"
              onChange={this.onChange.bind(this)} 
              style={{marginRight: '10px', zIndex: 1}}
              />

            <Button
                style={{
                  backgroundColor: '#1DD28B',
                  border: '#1DD28B',
                  height: '40px',
                  borderRadius:'10px',
                  zIndex: 1
                }}
                onClick={()=>this.exportTable()}>
              
              <div style={{fontSize: '14px', fontWeight:'600', color: '#fff'}}>Export CSV</div>
            </Button>
          </div>
          <div hidden={this.state.show != 'Unaccounted'}>
            <Table
              rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
              columns={this.columns}
              dataSource={this.renderUnaccounted()}
              pagination={false} 
              />
          </div>
          <div hidden={this.state.show != 'Accounted'}>
            <Table
              rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
              columns={this.columns}
              dataSource={this.renderAccounted()}
              pagination={false} 
              />
          </div>

          <div style={{ width: '100%', display: 'flex', marginTop: '20px', }}>
            <Select
                showSearch
                onChange={(ev)=>this.changePageSize(ev)}
                placeholder="Page Size"
                style={{ width: '11%', marginBottom: '10px', marginLeft: 'auto', marginRight: '20px' }}
                size="small"
            >
                <Option key={1} value={5}>Show 5</Option>
                <Option key={2} value={10}>Show 10</Option>
                <Option key={3} value={20}>Show 20</Option>
                <Option key={4} value={50}>Show 50</Option>

            </Select>

            <Pagination 
            size="small"
            total={this.getCount()} 
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            onChange={this.pageSelect} 
            defaultCurrent={1} key={Math.random()} 
            pageSize={this.state.page_size} 
            current={this.state.page}/>
          </div>
        </div>
      )
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Recon);