import React from 'react';
import { Card, Typography, List } from 'antd';

const AccountCard = (props) => {
    const { data } = props;

    const renderSegment = (seg) => {
        switch (seg) {
            case 'ME_':
                return 'BUxME';
            case 'GIG': 
                return 'BUxGIG';
            case 'BIZ': 
                return 'BUxBIZ';
            case 'SME': 
                return 'BUxSME';
            default:
                return 'N/A';
        }
    }

    const info = [
        {
            title: 'Merchant ID:',
            value: data && data.bux_merchant_id,
            hidden: false
        },
        {
            title: 'Business Name:',
            value: data && data.business_name,
            hidden: false
        },
        {
            title: 'Segment:',
            value: data && renderSegment(data.bux_segment),
            hidden: false
        },
        {
            title: 'Level:',
            value: data && data.eon_level,
            hidden: false
        },
        {
            title: 'User Type:',
            value: data && data.multiwallet && data.is_parent ? 'Parent' : data && data.is_child ? 'Child' : 'N/A',
            hidden: data && data.user_type !== 'CO',
        },
        {
            title: 'Account Type:',
            value: data && data.key_account? 'Key' : 'Non-Key',
            hidden: false
        },
    ]

    return (
        <Card
            style={styles.root}
            bodyStyle={styles.rootBody}
        >
            <Typography style={styles.title}>Account</Typography>
            <div>
                <List
                    dataSource={info}
                    bordered={false}
                    renderItem={item => (
                        <List.Item style={{borderBottom: 0, padding: '8px 0', display: item && item.hidden && 'None'}}>
                            <Typography style={styles.item}>{item.title}</Typography>
                            <Typography style={styles.item}>{item.value}</Typography>
                        </List.Item>
                    )}
                />
            </div>
        </Card>
    )
}

const styles = {
    root: {
        margin: '12px 12px 12px 0',
        padding: '24px'
    },
    rootBody: {
        padding: 0,
    },
    title: {
        color: '#2B2D32',
        fontSize: '18px',
        fontWeight: 'bold',
        padding: '12px 0',
        opacity: '0.8'
    },
    normalFont: {
        color: '#2B2D32',
        fontSize: '16px'
    },
    content: {
        padding: '8px 0'
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 0'
    },
    item: {
        color: '#2B2D32',
        fontSize: 16
    },
}

export default AccountCard;