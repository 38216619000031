import React from 'react';
import { Button, Icon, Input, Modal, Typography } from 'antd';

const DeleteModal = (props) => {
    const segments = localStorage.getItem('segments');

    React.useEffect(() => {

    }, [props.visible])

    return (
        <Modal
            maskClosable
            onCancel={() => props.onClose()}
            visible={props.visible}
            footer={false}
            closeIcon={<Icon type="close" style={{ color: '#F5922F' }} />}
            width={360}
            destroyOnClose
        >
            <Typography style={styles.title}>Delete promo code</Typography>
            <div style={styles.footerBtn}>
                <button className={`btn--${segments}`} style={styles.cancelBtn} onClick={() => props.onClose()} >Cancel</button>
                <button className={`btn--${segments}`} style={{ ...styles.saveBtn }}
                    onClick={() => props.onDelete()} >
                    Delete
                </button>
            </div>
        </Modal>
    )
}

const styles = {
    title: {
        color: '#2B2D33',
        fontSize: 24,
        fontWeight: '400',
        textAlign: 'left'
    },
    msg: {
        color: '#2B2D33',
        fontSize: 16,
        textAlign: 'left',
        padding: '12px 0'
    },
    btn: {
        height: 48,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 4,
        width: '100%',
    },
    input: {
        padding: '11px 16px 11px 16px',
        fontSize: 16,
        height: 48,
        margin: '4px 0px 12px 0px',
        color: '#2B2D33'
    },
    saveBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: 86,
        cursor: 'pointer',
        backgroundColor: '#0A315E',
        color: '#FFF',
        border: 0
    },
    cancelBtn: {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: '1px solid #0A315E',
        borderRadius: 4,
        height: 40,
        fontSize: 16,
        fontWeight: '500',
        color: '#0A315E',
        padding: '8px 16px',
        width: 'auto',
        marginRight: 8
    },
    footerBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '12px 0'
    },
}

export default DeleteModal;