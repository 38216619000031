import React from 'react';
import { Icon, message } from 'antd'
import { OTC_PROMO,  OTC_LIST_PROMO, OTC_NAME_PROMO } from '../../constants/constants';
import PromoAddEditContainer from './custom_component/add_edit_promo';
import promotionServices from './promotionServices';
import PromoVoucherSetup from './custom_component/voucher_setup';
import PromoSocialMediaPost from './custom_component/social_media_post';
import SaveModal from './custom_component/save_modal';
import { history } from '../../../store/history';
import PromoBanner from './custom_component/banner_component';
import PromoPerformance from './performance';

const returnAllChannels = () => {
    let channel_selected = []
    try {
        OTC_LIST_PROMO.map((channel, i) => {
            if (OTC_PROMO[channel].code) {
                channel_selected.push(OTC_PROMO[channel].code);
            }
        });
    } catch (error) {
        return [];
    }
    return channel_selected;
}

const PromoAddEditView = props => {

    let pathname = window.location.pathname;
    let splitURL = pathname.toString().split("/");
    let view_path = splitURL[1]
    let id = splitURL[2]
    let hash = window.location.hash;

    const [isLoading, setIsloading] = React.useState(false);
    const [modalState, setModalState] = React.useState(false);
    const [params, setParams] = React.useState({
        code: '',
        promo_name: '', // new
        segments: ['ME_', 'GIG'],
        discount_type: 'FI',
        percentage_discount: 0,
        fixed_discount: 0,
        max_discount: 0, // new
        description: '',
        channels: returnAllChannels(),
        start_date: '',
        start_time: '', // new
        end_date: '',
        end_time: '', //new
        q_merchant: 0, //new
        q_day: 0, //new
        q_merchant_day: 0, //new
        min_amount: 0, //new
        is_draft: false,
        banner_blob: "",
        banner_header: "",
        facebook_post: "",
        instagram_post: "",
        twitter_post: ""

    });


    const [saveType, setSaveType] = React.useState('add')

    const autoFormatList = ['percentage_discount', 'fixed_discount']
    const limitFormatList = ['max_discount', 'q_merchant', 'q_day', 'q_merchant_day', 'min_amount']

    const autoFormat = (value) => {
        const formattedValue = value.replace(/\D/g, '')
        if (value !== null) {
            if(formattedValue > 20) {
                return 20;
            }
            return formattedValue;
        }
        return value;
    }

    const limitFormat = (value) => {
        const formattedValue = value.replace(/\D/g, '')
        if (value !== null) {
            if(formattedValue > 100) {
                return 100;
            }
            return formattedValue;
        }
        return value;
    }

    const handleChange = (event, type) => {
        const name = type ? type : event.target.name;
        const value = type ? event : event.target.value;

        if (autoFormatList.includes(name)) {
            setParams({ ...params, [name]: autoFormat(value) })
        } 
        else if (limitFormatList.includes(name)) {
            setParams({ ...params, [name]: limitFormat(value) })
        }
        else if (name === 'code') {
            setParams({ ...params, [name]: (value).toUpperCase() })
        } else {
            setParams({ ...params, [name]: value })
        }
    }

    const errorMessageDiv = (text) => {
        message.destroy()
        message.error(text, 3)
    }

    const checkParams = (type) => {
        setSaveType(type)
        for (const key in params) {
            if(params[key] == "") {
                if(key == "code") {
                    return errorMessageDiv('Promo code is required')
                }
                if(key == "promo_name") {
                    return errorMessageDiv('Promo name is required')
                }
                if(key == "description"){
                    return errorMessageDiv('Description is required')
                }
                if(key == "start_date") {
                    return errorMessageDiv('Start date is required')
                }
                if(key == "end_date") {
                    return errorMessageDiv('End date is required')
                }
                if(key == "start_time") {
                    return errorMessageDiv('Start time is required')
                }
                if(key == "end_time") {
                    return errorMessageDiv('End time  is required')
                }
            }
        }

        if(type == 'draft') {
            setParams({...params, 'is_draft': true})
            setSaveType('draft')
        }

        setModalState(true)

    }

    const tabs = [
        {
            'title': 'Details',
            'hash': '#details',
        },
        {
            'title': 'Performance',
            'hash': '#performance'
        }
    ]

    const addPromoCode = async (params) => {
        setIsloading(true);
        try {
            const res = await promotionServices.createPromoCode(params);
            if (res.status === 'success') {
                message.success(res.message);

                if(res.data['saved_draft']) {
                    history.push(`/promotion/`)
                }

                else {
                    window.location.href = `/view_promotion/${res.data['id']}#details`
                }
                
            } else if (res.status === 'error') {
                message.error(res.message);
            }
        } catch (error) {
            console.log(error);
            message.error('Error please try again');
        }
        setIsloading(false);
    }

    const updatePromoCode = async (params) => {
        setIsloading(true);
        try {
            const res = await promotionServices.updatePromoCode(params);
            if (res.status === 'success') {
                message.success(res.message);
                window.location.href = `/view_promotion/${id}#details`
                            
            } else if (res.status === 'error') {
                message.error(res.message);
            }
        } catch (error) {
            console.log(error);
            message.error('Error please try again');
        }
        setIsloading(false);
    }
    
    const getPromoById = async () => {
        try  {
            const res = await promotionServices.getPromoCodeById(id);
            console.log(res)
            if(res.status === 'success') {
                setParams({
                    id: res.data.id,
                    code: res.data.code,
                    promo_name: res.data.promo_name, // new
                    segments: res.data.segments,
                    discount_type: res.data.discount_type,
                    percentage_discount: res.data.percentage_discount,
                    fixed_discount: res.data.fixed_discount,
                    max_discount: res.data.max_discount, // new
                    description: res.data.description,
                    channels: returnAllChannels(),
                    start_date: res.data.date_start,
                    start_time: res.data.date_start_time, // new
                    end_date: res.data.date_expiry,
                    end_time: res.data.date_expiry_time, //new
                    q_merchant: res.data.q_merchant, //new
                    q_day: res.data.q_day, //new
                    q_merchant_day: res.data.q_merchant_day, //new
                    min_amount: res.data.min_amount, //new
                    is_draft: res.data.saved_draft,
                    banner_blob: res.data.banner_blob,
                    banner_header: res.data.banner_header,
                    facebook_post: res.data.facebook_post,
                    instagram_post: res.data.instagram_post,
                    twitter_post: res.data.twitter_post
                })
            }

            if(res.status === 'error') {
                message.destroy()
                message.error(res.message);
            }
        }

        catch (error) {
            console.log(error);
            message.error('Error please try again');
        }
    }

    React.useEffect(() => {
        if(view_path !== 'new_promotion') {
            getPromoById()
        }
    }, [])

    return (
        <div align="center">
            <div className="promotion-new">
                <div align="left">
                    <a href="/promotion">
                        <Icon type="arrow-left" className="text-nil right-8 arrow-width"/>
                        <span className="wc-header">
                            {
                                view_path != 'new_promotion' ?
                                'Back'
                                :
                                'Cancel'
                            }
                        </span>
                    </a>
                </div>
                
                {
                    view_path == 'new_promotion' &&
                    <div align="right" className='top-32'>
                        <button className={`outline-btn--nil right-8`} onClick={() => checkParams('draft')}>
                            Save as draft
                        </button>
                        <button className="btn--nil" onClick={() => checkParams('add')}>
                            Add promo
                        </button>
                    </div>
                }

                {
                    view_path == 'edit_promotion' &&
                    <div align="right" className='top-32'>
                        <button className={`outline-btn--nil right-8`}
                            onClick={() => history.push(`/view_promotion/${id}#details`)}>
                            Cancel
                        </button>

                        {/* Add function nalang par */}
                        <button className="btn--nil" onClick={() => checkParams('edit')}>
                            Save changes
                        </button>
                    </div>
                }

                {
                    view_path == 'view_promotion' &&
                    <div align="left" className="top-32 flex padding-bot-16 promotion-tab-div">
                        {
                            tabs.map((item, key) => {
                                return(
                                    <div className="right-16">
                                        <a className={
                                            item.hash === hash ? 
                                                'wc-header adminColor promotion-selected-tab padding-bot-16' 
                                                : 
                                                'modal-sub-text'}
                                            onClick={() => window.location.href = `/view_promotion/${id}${item.hash}`} 
                                            href>
                                            {item.title}
                                        </a>
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {
                    (view_path === 'new_promotion' || 
                    view_path === 'edit_promotion' ||
                    (view_path === 'view_promotion' && hash === '#details')) &&
                    <div>
                        <PromoAddEditContainer 
                            headerText={view_path === 'edit_promotion' ? 'Edit Promo' : 'Add Promo'}
                            handleChange={handleChange} 
                            params={params} 
                            setParams={setParams}
                            isLoading={isLoading}
                            view_path={view_path}
                            id={id} />

                        <PromoVoucherSetup 
                            headerText="Voucher Setup"
                            params={params}
                            setParams={setParams}
                            handleChange={handleChange} 
                            isLoading={isLoading}
                            view_path={view_path}
                            />
                        

                        <PromoSocialMediaPost 
                            headerText="Social Media Post Link"
                            params={params}
                            setParams={setParams}
                            handleChange={handleChange} 
                            isLoading={isLoading}
                            view_path={view_path}
                            />

                        <PromoBanner headerText="Add Banner (Optional)"
                            params={params}
                            setParams={setParams}
                            view_path={view_path}
                            handleChange={handleChange} />
                        
                        <SaveModal 
                            modalState={modalState} 
                            setModalState={setModalState}
                            addPromoCode={addPromoCode}
                            updatePromoCode={updatePromoCode}
                            params={params}
                            type={saveType} />
                    </div>
                }
                {
                    view_path === 'view_promotion' && hash === '#performance' &&
                    <div className="top-32">
                        <PromoPerformance id={id} />
                    </div>
                }
            </div>
        </div>
    )
}

export default PromoAddEditView;
