import React, {useEffect, useState, useRef} from 'react';
import axios from "axios";
import { Dropdown, Icon, Input, Menu, message, Pagination, Table } from 'antd';
import { Handle401 } from '../../handle401/handle401';
import KajabiOfferEditorModal from './offer_editor_modal';
import { history } from '../../../store/history';
import { makeStyles } from '@material-ui/core';
import ActionConfirmationModal from './delete_confirm_modal';
import EmbeddableScriptModal from './embeddable_script_modal';


const KajabiOffers = () => {
    const kajabiDashboardStyles = makeStyles(theme => ({
        boldHeader: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '28px',
            lineHeight: '32px',
            color: '#000000',
            marginBottom: '40px'
        },
        searchBar: {
            width: '200px',
            height: '35px',
            zIndex: '1'
        },
        paginationDiv: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px'
        },
        addButton: {
            fontSize: 14,
            fontWeight: 600,
            padding: '8px 16px 8px 16px',
            border: '1px solid #1BCD6F',
            borderRadius: 4,
            height: 40,
            backgroundColor:'#1BCD6F',
            color: "#FFFFFF",
            '&:hover': {
                cursor: 'pointer',
                opacity: 0.65,
            },
        },
        moduleWorkspaceDiv: {
            padding: '15px 20px',
            background: '#fff',
            border: '1px solid #E6EAF0',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        },
        clearFilterDiv: {
            marginBottom: '5px', 
            display: 'flex', 
            justifyContent: 'space-between' 
        },
        filterLabel: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: 16,
            lineHeight: '24px',
            color: '#2B2D33'
        },
        filterHelpText: {
            fontSize: 12,
            lineHeight: '21px',
            color: '#909196'
        },
        clearFilterButton: {
            borderRadius: '24px',
            background: '#E6EAF0',
            color: '#000000',
            fontSize: 12,
            width: 120,
            height: '29px',
            border: '1px solid #e6eaf0',
            cursor: 'pointer',
            outline: 0,
            '&:hover': {
                opacity: '0.8',
            },
            '&:active': {
                opacity: 1,
            }
        },
        applyButtonActive: {
            fontSize: 14,
            background: '#f5922f',
            border: '1px solid #f5922f',
            borderRadius: '4px',
            color: '#fff',
            height: '40px',
            width: 93,
            cursor: 'pointer',
            outline: 0,
            '&:hover': {
                opacity: '0.8',
            },
            '&:active': {
                opacity: 1,
            }
        },
        applyButtonDisabled: {
            outline: 0,
            background: '#f5922f',
            border: '1px solid #f5922f',
            borderRadius: '4px',
            color: '#fff',
            height: '40px',
            width: 93,
            opacity: 0.5,
            cursor: 'not-allowed'
        },
        tutorialLink: {
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '24px',
            color: '#54575F',
            // textDecoration: 'underline', // Uncomment when Tutorial is ready
            fontStyle: 'italic',
            // cursor: 'pointer',  // Uncomment when Tutorial is ready
        },
        view_snippet: {
            background: 'none',
            border: 'none',
            margin: 0,
            padding: 0,
            fontWeight: 600,
            cursor: 'pointer',
            color: '#0a315e',
            '&:hover': {
                opacity: '0.7',
                textDecoration: 'underline',
            },
        }
    })) ();

    const userID = localStorage.getItem("userId");

    // Filters
    const isComponentMounted = useRef(false);
    const [areFilterSettingsInDefaultState, setAreFilterSettingsInDefaultState] = useState(true);
    const [areFilterSettingsApplied, setAreFilterSettingsApplied] = useState(true);
    const isFilterResetFired = useRef(false);

    const defaultFilterSettings = {
        'searchValue': ''
    }
    const [filterSettings, setFilterSettings] = useState(defaultFilterSettings);

    // Table
    const [tableData, setTableData] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [totalOffersCount, setTotalOffersCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 20;

    // Edit Form Modal
    const [isEditorModalVisible, setIsEditorModalVisible] = useState(false);
    const [offerInFocus, setOfferInFocus] = useState();
    const [isNewOffer, setIsNewOffer] = useState(true);

    // Delete Confirmation Modal
    const [isConfirmModalVisible, setIsConfirmModalVisible] = React.useState(false);
    const [confirmModalHeaderText, setConfirmModalHeaderText] = React.useState('');
    const [confirmModalBodyText, setConfirmModalBodyText] = React.useState('');
    const [confirmModalCallback, setConfirmModalCallback] = React.useState(null);

    // Embeddable Script Modal
    const [isSnippetModalVisible, setIsSnippetModalVisible] = React.useState(false);

    const tableColumns = [
        {
            title: "Offer Details",
            key: 'offer',
            width: 'auto',
            fixed: 'left',
            render: (text, offer) => {
                return (
                    <div>
                        {offer.name}
                    </div>
                )
            }
        },
        {
            title: "BUx URL",
            key: 'bux_link',
            width: 'auto',
            render: (text, offer) => {
                return (
                    <div>
                        <span>
                            {offer.bux_link}
                        </span>
                        <br/>
                        <button
                            className={kajabiDashboardStyles.view_snippet}
                            onClick={() => {
                                setOfferInFocus({...offer});
                                setIsSnippetModalVisible(true);
                            }}
                        >
                            View Custom Code
                        </button>
                    </div>
                )
            }
        },
        {
            title: "Underlying URLs",
            key: 'urls',
            width: "auto",
            render: (text, offer) => {
                return (
                    <div>
                        Offer Link: <i>{offer.aliased_checkout_url}</i>
                        <br/>
                        Activation: <i>{offer.webhook_url}</i>
                        <br/>
                        Redirect: <i>{offer.payment_redirect_url}</i>
                    </div>
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            width: 'auto',
            fixed: 'right',
            render: (text, offer) => {
                return (
                    <Dropdown overlay={offerActionMenu(offer)} 
                        placement="bottomCenter"
                        trigger={['click']}
                    >
                      <Icon type="ellipsis" />
                    </Dropdown>
                  )
            }
        }
    ];

    const offerActionMenu = (offer) => (
        <Menu style={{ width: '150px' }}>
            <Menu.Item
                key="1"
                onClick={() => {
                    setOfferInFocus({...offer});
                    setIsNewOffer(false);
                    setIsEditorModalVisible(true);
                }}
            >
                Edit
            </Menu.Item>

            <Menu.Item
                key="2"
                onClick={() => {
                    setOfferInFocus({...offer});
                    setIsSnippetModalVisible(true);
                }}
            >
                View Custom Code
            </Menu.Item>

            <Menu.Item
                key="3"
                onClick={() => {
                    setConfirmModalCallback(() => {
                        return () => {
                            deleteKajabiOffer(offer.id)
                            setIsConfirmModalVisible(false);
                        }
                    });

                    setConfirmModalHeaderText("Delete Offer");
                    setConfirmModalBodyText(
                        <span>Are you sure you want to <b>delete</b> offer <b>{offer.name}</b>?</span>
                    );
                    setIsConfirmModalVisible(true);
                }}
                style={{
                    color: '#e61f1f',
                    marginTop: '15px'
                }}
            >
                Delete
            </Menu.Item>
        </Menu>
    );

    async function genericAxiosRequest(http_method, target_url, params=undefined) {
        try {
            let response = await axios({
                method: http_method,
                url: process.env.REACT_APP_API_URL + target_url,
                data: params,
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            });
            return response.data;

        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    Handle401()
                } else {
                    throw new Error(error.response.data.message);
                }
            } else {
                throw new Error('No response from backend server!');
            }
        }
    }


    async function fetchKajabiOffers(selectedPage=1, filterSettings=defaultFilterSettings) {
        setIsTableLoading(true);

        const endpoint_url = `/api/kajabi/${userID}/offers/`;
        let query_params = [
            'page=' + selectedPage,
            'page_size=' + pageSize,
            'search=' + filterSettings.searchValue,
        ];
        const full_url = endpoint_url + "?" + query_params.join("&");

        try {
            let response = await genericAxiosRequest('get', full_url);
            if (response.status === 'failed') {
                throw new Error(response.message);
            }
            
            setTableData(response.offers);
            setTotalOffersCount(response.total);

        } catch (err) {
            message.destroy();
            message.error(err.message);
            setTableData([]);
            setTotalOffersCount(0);
            setAreFilterSettingsApplied(false);

        } finally {
            setIsTableLoading(false);
        }
    }

    async function deleteKajabiOffer(target_id=null) {
        if (!target_id) {
            return;
        }

        const endpoint_url = `/api/kajabi/${userID}/offers/`;
        const params = {
            offer_id: target_id
        };

        try {
            let response = await genericAxiosRequest('delete', endpoint_url, params);
            if (response.status === 'failed') {
                throw new Error(response.message);
            }

            message.destroy();
            message.success(response.data);
            fetchKajabiOffers(currentPage, filterSettings);

        } catch (err) {
            message.destroy();
            message.error(err.message);
        }
    }


    function onPageChange(selectedPage) {
        setCurrentPage(selectedPage);
        fetchKajabiOffers(selectedPage, filterSettings);
    }

    function checkIfFiltersAreDefaultState() {
        // Add defaults of other new filters states here
        const areFiltersSameAsDefault = Object.keys(defaultFilterSettings).every((settingName) => {
            return filterSettings[settingName] === defaultFilterSettings[settingName];
        })

        setAreFilterSettingsInDefaultState(areFiltersSameAsDefault);
        return areFiltersSameAsDefault;
    }

    function clearFilterSettings() {
        isFilterResetFired.current = true;

        setFilterSettings(defaultFilterSettings);
        fetchKajabiOffers();
    }

    function applyFilterSettings() {
        fetchKajabiOffers(currentPage, filterSettings);
        setAreFilterSettingsApplied(true);
    }


    useEffect(() => {
        if (! isComponentMounted.current) {
            return;
        }

        const areFiltersSameAsDefault = checkIfFiltersAreDefaultState();
        if (areFiltersSameAsDefault && isFilterResetFired.current) {
            setAreFilterSettingsApplied(true);
            isFilterResetFired.current = false;
        } else {
            setAreFilterSettingsApplied(false);
        }
    }, [filterSettings]);  // Add other new filters used here


    useEffect(() => {
        isComponentMounted.current = true;

        if(localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") == null){
            history.push('/login')
        } else {
            const userType = localStorage.getItem("userType");

            if (userType !== "CO"){
                history.push('/dashboard')
            }
            else {
                fetchKajabiOffers();
            }
        }
    }, []);


    return (
        <div style={{ paddingTop: '38px', paddingLeft: '42px', paddingRight: '42px' }}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{margin: '0px 0px 5px 5px'}}>
                    <span className={kajabiDashboardStyles.boldHeader}>
                        Kajabi Offers
                    </span>
                </div>
            </div>

            <div className={kajabiDashboardStyles.moduleWorkspaceDiv}>
                <div className={kajabiDashboardStyles.clearFilterDiv}>
                    <div style={{display: 'flex'}}>
                        <div className={kajabiDashboardStyles.filterLabel} >
                            Filter
                        </div>
                        <div style={{marginLeft: 10}} hidden={areFilterSettingsInDefaultState}>
                            <button 
                                className={kajabiDashboardStyles.clearFilterButton}
                                onClick={clearFilterSettings}
                            >
                                <Icon 
                                    type="close-circle"
                                    theme="filled"
                                    style={{paddingRight: 4}}
                                />
                                Clear Filter
                            </button>
                        </div>
                    </div>
                    
                    <div>
                        <div
                            className={kajabiDashboardStyles.filterHelpText}
                            hidden={areFilterSettingsApplied}
                        >
                            <Icon 
                                type="info-circle" 
                                theme="filled"
                                style={{paddingRight: 4, transform: 'translate(0px, 1px)'}}
                            />
                            Click <b>Apply</b> button to show results
                        </div>
                    </div>
                </div>

                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '20px'}}>
                    <Input
                        placeholder="Search"
                        className={kajabiDashboardStyles.searchBar}
                        onChange={(e) => setFilterSettings({...filterSettings, 'searchValue': e.target.value})}
                        prefix={<Icon style={{paddingTop: 5}} type="search" />}
                        value={filterSettings.searchValue}
                        size="large"
                        style={{marginLeft: 15}}
                    />

                    <button
                        style={{marginLeft: 15}}
                        className={areFilterSettingsApplied ? kajabiDashboardStyles.applyButtonDisabled : kajabiDashboardStyles.applyButtonActive}
                        disabled={areFilterSettingsApplied}
                        onClick={applyFilterSettings}
                    >
                        Apply
                    </button>

                    <button
                        style={{marginLeft: 15}}
                        className={kajabiDashboardStyles.addButton}
                        onClick={() => {
                            setOfferInFocus(null);
                            setIsNewOffer(true);
                            setIsEditorModalVisible(true);
                        }}
                    >
                        <Icon type="plus" /> Add New
                    </button>
                </div>

                
                <Table
                    rowKey={user => user.id}
                    rowClassName={(_, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                    dataSource={tableData}
                    columns={tableColumns}
                    pagination={false}
                    loading={isTableLoading}
                    size="small"
                    style={{width: '70vw'}}
                    scroll={{x: true}}
                />


                <div className={kajabiDashboardStyles.paginationDiv}>
                    <div 
                        className={kajabiDashboardStyles.tutorialLink} 
                        // onClick={() => window.open('/kajabi_tutorial', '_blank')}
                    >
                        Coming Soon: How to enroll my offers?
                    </div>

                    <Pagination
                        size="small"
                        total={totalOffersCount}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        onChange={onPageChange}
                        defaultCurrent={1}
                        pageSize={pageSize}
                        current={currentPage}
                    />
                </div>

                <KajabiOfferEditorModal
                    isEditorModalVisible={isEditorModalVisible}
                    onCancelOrClose={() => setIsEditorModalVisible(false)}
                    offerInFocus={offerInFocus}
                    reloadOffers={fetchKajabiOffers}
                    isNewOffer={isNewOffer}
                    userID={userID}
                />

                <ActionConfirmationModal
                    isConfirmModalVisible={isConfirmModalVisible}
                    setIsConfirmModalVisible={setIsConfirmModalVisible}
                    confirmModalCallback={confirmModalCallback}
                    confirmModalHeaderText={confirmModalHeaderText}
                    confirmModalBodyText={confirmModalBodyText}
                />

                <EmbeddableScriptModal
                    isSnippetModalVisible={isSnippetModalVisible}
                    setIsSnippetModalVisible={setIsSnippetModalVisible}
                    offerInFocus={offerInFocus}
                />
            </div>

        </div>
    )
}

export default KajabiOffers;