import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Handle401 } from '../../../handle401/handle401';
import { Button, Modal, Icon, Input, Typography, Divider, message } from 'antd';
import { TextField, Grid, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { LensTwoTone } from '@material-ui/icons';

const { TextArea } = Input;
const segments = localStorage.getItem('segments');

const DeleteEmailAdvisoryModal = props => {

    let data = props.deleteData

    const useStyles = makeStyles(theme => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textArea: {
            marginRight: '100%',
            display: 'flex',
            fontSize: '16px'
        },
        headerText: {
            fontSize: 'x-large',
            padding: '20px 20px 20px 0'
        },
        deleteBtn: {
            height: 48,
            fontSize: 14,
            fontWeight: '600',
        },
        cancelBtn: {
            height: 48,
            backgroundColor: '#FFF',
            borderColor: '#F5922F',
            color: '#F5922F',
            fontSize: 14,
            fontWeight: '600',
            borderRadius: 10,
            '&:hover': {
                cursor: 'pointer',
                borderColor: '#F5922F',
                backgroundColor: '#F5922F',
                color: '#FFFFFF',
            },
        },
    }));



    const classes = useStyles();

    const [id, setID] = React.useState("");
    const [subject, setSubject] = React.useState("")


    React.useEffect(() => {
        setID(data.id)
        setSubject(data.subject)
    }, [data])

    async function deleteEmailAdvisory() {
        try {
            let response = await axios({
                method: 'delete',
                url: process.env.REACT_APP_API_URL + '/api/admin/system_notification/email_advisory/',
                data: {target_id : id == "" ? data.id : id },
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            });
            message.success("Email advisory deleted.", 5)
            props.closeModal("closeDeleteModal")


        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    Handle401()
                } else {
                    message.error(error.response.data.message);
                }
            } else {
                throw new Error('No response from backend server!');
            }
        }

    }

    return (
        <Modal
            key={data.id}
            centered
            visible={props.deleteModal}
            width={400}
            onCancel={() => props.closeModal("closeDeleteModal")}
            closeIcon={<Icon type="close" style={{ color: '#F5922F' }} />}
            footer={null}>
            <Grid container spacing={2}>
                <Grid item={true} lg={12}>
                    <Typography.Title style={styles.title}>
                        Advisory Emailer
                    </Typography.Title>
                </Grid>
                <Grid item={true} lg={12}>
                    <Typography.Paragraph style={styles.content}>
                        Are you sure you want to <span style={{ fontWeight: 'bold' }}>delete {data.subject}</span>
                    </Typography.Paragraph>
                </Grid>
                <Grid item={true} lg={6} style={{ paddingTop: '16px', paddingBottom: '16px' }}>
                    <button
                        className={`outline-btn--${segments}`}
                        style={{ height: 48, fontSize: 14, fontWeight: '600', width: '100%'}}
                        block
                        onClick={() => props.closeModal("closeDeleteModal")}>
                        Cancel
                    </button>
                </Grid>
                <Grid item={true} lg={6} style={{ paddingTop: '16px', paddingBottom: '16px' }}>
                    <button
                        className={`btn--${segments}`}
                        style={{height: 48, fontSize: 14, fontWeight: '600', width: '100%'}}
                        block
                        onClick={() => deleteEmailAdvisory()}>
                        Delete
                    </button>
                </Grid>
            </Grid>
        </Modal>
    )
}


const styles = {
    title: {
        fontSize: 24,
        color: '#000000',
        padding: '10px 0px 0px 0px',
    },

    content: {
        fontSize: 18,
        color: '#000000',
    },
    divider: {
        backgroundColor: "#000000"
    },
}

export default DeleteEmailAdvisoryModal;