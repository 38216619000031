import React from 'react'
import { Drawer, Button, message, Icon } from 'antd'
import axios from 'axios'
import * as Style from '../../layouts/AppBar/appBarComponent/appBarFormStyle'
import * as style from './style'
import GenerateLink from './generate_link'
import ShareQrLink from './share_qr_link'
import Beta from './beta.svg'
import { history } from '../../store/history'
import qrServices from '../account_settings/bux_qr/qr_services/qrServices'
import { segmentStroke } from '../constants/constants'
import PaymentSchedDiv from './payment_schedule'

const testMode = history.location.pathname.indexOf('/test') > -1;

const GenerateDrawer = props => {

    let trade_name = testMode ? localStorage.getItem('SbTradeName') : localStorage.getItem('tradeName')
    let noTradeName = trade_name == "null"
    const isChild = localStorage.getItem("is_child") == "true" || localStorage.getItem("is_child") == true
    const segments = localStorage.getItem("segments")

    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const REACT_APP_ROOT_URL = process.env.REACT_APP_ROOT_URL;

    const [tradeName, setTradeName] = React.useState("") 
    const [isSched, setIsSched] = React.useState(false)
    // const qrLink = testMode ? 'https://' + tradeName + '.' + REACT_APP_ROOT_URL+'/test/qr' : 'https://' + tradeName + '.' + REACT_APP_ROOT_URL;

    // new Link with Sub-wallet QR
    let remove_part = `.${process.env.REACT_APP_SUB_URL}`;
    const master_trade_name = window.location.hostname.replace(remove_part, "");
    const userRole = localStorage.getItem("userType");
    let sub_remove_part = window.location.protocol+'//';
    const protocol = window.location.protocol;
    const subQRCheckoutUrlLink = `${REACT_APP_BASE_URL}/mycheckout/${tradeName}`;
    const subQrCheckoutUrl = protocol+'//'+master_trade_name+'.'+subQRCheckoutUrlLink.replace(sub_remove_part, "")
    const mainCheckoutUrl = `${REACT_APP_BASE_URL}/${tradeName}`;
    const testmodeCheckoutUrl = `${REACT_APP_BASE_URL}/test/QR/${tradeName}`;

    const defined_wallet_settings = JSON.parse(localStorage.getItem('defined_wallet_settings'))

    let checkout_enabled = false;
    let generate_payment_link_enabled = false;

    if(defined_wallet_settings != null){

        checkout_enabled = defined_wallet_settings.checkout_enabled ? defined_wallet_settings.checkout_enabled : '';
        generate_payment_link_enabled = defined_wallet_settings.generate_payment_link_enabled ? defined_wallet_settings.generate_payment_link_enabled : '';
    }

    const qrLink = userRole  === 'SW'? subQrCheckoutUrl : testMode? testmodeCheckoutUrl : mainCheckoutUrl;
   
    // const qrLink = testMode ? REACT_APP_BASE_URL+'/'+tradeName+'/test' : REACT_APP_BASE_URL+'/'+tradeName
    const notFound = REACT_APP_BASE_URL+'/404/'

    const ButtonGroup = Button.Group;
    const level = localStorage.getItem("Level")

    const {
        visible, setShowDrawer
    } = props

    //const buttonText = ['Payment Link', 'My Checkout']
    const buttonText = [
        {
            'header': 'Payment Link',
            'disabled': !generate_payment_link_enabled
        },
        {
            'header': 'My Checkout',
            'disabled': !checkout_enabled
        }
    ]

    const segmentsColor = {
        'me_':{
            color: '#0DAED0',
        },
        'gig':{
            color: '#41BD5C',
        },
        'sme':{
            color: '#E3632A',
        },
        'biz':{
            color: '#0051B0',
        },
    }
    const [actionBtn, setActionBtn] = React.useState('Payment Link')
    const [data, setData] = React.useState({})
    const [qrOn, setQrOn] = React.useState(false)

    async function getDetails () {
        try {

            let response;

            if(testMode && isChild) {
                response = await qrServices.getQrDetails(testMode, trade_name)
            }

            else {
                response = await qrServices.getQrDetails(testMode)
            }

            console.log(response.data)
            if(response.data.status === 'failed') {
                message.error(response.data.message, 3)
                setQrOn(false)
                setActionBtn("Payment Link")
            }

            else {
                setData(response.data)
                setQrOn(response.data.qr_enabled)
                setTradeName(response.data.trade_name)

            }
        }

        catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        if(visible) {
            if((level > 0 && !noTradeName) || testMode) {
                getDetails()
            }
        }
    }, [visible])

    return(
        <Drawer
            className='voucher-drawer'
            placement="right"
            width="444px"
            closable={false}
            visible={visible}
          >
            <div style={{padding: '40px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div id="top" style={Style.titleStyle}>{isSched ? 'Request Payment Schedule' : 'Generate Payment'}</div>
                <div style={{marginBottom: '20px'}}>
                    <Icon type='close' onClick={()=>{setShowDrawer(false); setActionBtn('Payment Link')}} style={{fontSize: '20px', color: ["ME", "CO"].includes(userRole) && segmentsColor[segments].color}}/>
                </div>
            </div>
            
            <div style={{width: '100%'}} align="center">
                <ButtonGroup hidden={level === 0 || !actionBtn || isSched}>
                    {
                        buttonText.map((item) => {
                            return <Button
                                key={item.header.toLocaleLowerCase()}
                                onClick={() => setActionBtn(item.header)}
                                style={{
                                ...style.buttonStyle,
                                borderRadius: 4,
                                color: actionBtn === item.header ? '#ffffff' : '#2b2d33',
                                backgroundColor: actionBtn === item.header ? `${segmentStroke[0]}` : '#fff',
                                }}>{item.header}</Button>
                            })
                    }
                
                </ButtonGroup>
            </div>
            {
                actionBtn == 'Payment Link' ? 
                    <GenerateLink setShowDrawer={setShowDrawer} setIsSched={setIsSched} disabled={buttonText[0].disabled} color={["ME", "CO"].includes(userRole) ? segmentsColor[segments].color : '#F5922F'} /> : 
                    <ShareQrLink qrOn={qrOn} qrLink={qrLink} notFound={notFound} data={data} tradeName={tradeName} disabled={buttonText[1].disabled} />
            }

            </div>
          
              
          </Drawer>     
    )
}

export default GenerateDrawer;