import React from "react";
import axios from "axios";
import accountStyles from "../../../static/css/Account";
import * as Icon from "../../../static/icons/bux_qr/index";
import { isMobile } from "react-device-detect";
import { TextField, InputAdornment } from "@material-ui/core";
import { message, Typography } from "antd";
import { history } from "../../../store/history";
import qrServices from "./qr_services/qrServices";

const { Text } = Typography;

const SetupTradeName = (props) => {
  const ac = accountStyles();

  const userType = localStorage.getItem("userType");
  const firstName = localStorage.getItem("firstName");
  const [tradeName, setTradeName] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");
  const [hideError, setHideError] = React.useState(true);
  const testMode = history.location.pathname.indexOf("/test") > -1;
  const segments = localStorage.getItem("segments");
  let remove_part = `.${process.env.REACT_APP_SUB_URL}`;
  let base_domain = process.env.REACT_APP_SUB_URL;
  const master_trade_name = window.location.hostname.replace(remove_part, "");

  function settingTradeName(val) {
    if (val.length <= 24) {
      setTradeName(val);
    }

    if (val.length >= 24) {
      setHideError(false);
      setErrorMsg("Max of 24 Character Limit");
    }

    if (val.match(/[^0-9a-zA-Z]+/)) {
      setHideError(false);
      setErrorMsg("QR Link should not have special characters nor spaces.");
    } else {
      setHideError(true);
      setErrorMsg("");
    }
  }

  async function setupQr() {
    if (tradeName >= 24) {
      setHideError(false);
      setErrorMsg("Max of 24 Character Limit");
      return;
    } else if (tradeName.match(/[^0-9a-zA-Z]+/)) {
      setHideError(false);
      setErrorMsg("QR Link should not have special characters nor spaces.");
      return;
    } else if (!tradeName) {
      setHideError(false);
      setErrorMsg("Please enter your store/trade name.");
      return;
    }

    let noSpace = tradeName.replace(/\s/g, "");
    let params = {
      trade_name: noSpace,
    };

    try {
      const response = await qrServices.setupTradeName(testMode, params);

      console.log(response);
      if (response.data.status === "failed") {
        setErrorMsg(response.data.message);
        setHideError(false);
      } else if (response.data.status === "success") {
        if (testMode) {
          localStorage.setItem("SbTradeName", response.data.trade_name);
        } else {
          localStorage.setItem("tradeName", response.data.trade_name);
        }
        message.success("Trade name Added", 3);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div
      className={ac.qrDiv}
      style={{ width: "100%", padding: isMobile ? 32 : "0 32px 0px 32px" }}
    >
      <div align="center" className={ac.mainInfoDiv} style={{ padding: 0 }}>
        <img src={Icon.SetupImg} alt="Setup Image" />
        <div className={ac.qrDetailsHeader}>
          First let’s setup your Checkout!
        </div>

        <div align="left">
          <div className={ac.fieldPlacehold}>My Checkout</div>

          <TextField
            id="outlined-basic"
            autoFocus
            //placeholder={ userType !== "SW" ? localStorage.getItem("username") : firstName }
            variant="outlined"
            style={{ width: "100%" }}
            maxLength={24}
            // value={tradeName}
            onChange={(e) => settingTradeName(e.target.value)}
            InputProps={
              userType === "SW"
                ? {
                    startAdornment: (
                      <InputAdornment position="start">
                        <div
                          className={ac.adornmentStyle}
                        >{`${master_trade_name}.${base_domain}/`}</div>
                      </InputAdornment>
                    ),
                  }
                : {
                    startAdornment: (
                      <InputAdornment position="start">
                        <div
                          className={ac.adornmentStyle}
                        >{`${base_domain}/`}</div>
                      </InputAdornment>
                    ),
                  }
            }
          />
          {hideError && (
            <Text type="secondary">
              Please input your store/trade name in the field above. This will
              be your unique checkout link.
            </Text>
          )}

          <div style={{ color: "red" }} hidden={hideError}>
            {errorMsg}
          </div>

          <div style={{ textAlign: "right", padding: "16px 0" }}>
            <button
              className={`btn--${segments}`}
              style={{
                opacity: !hideError ? "0.5" : "1",
                cursor: !hideError ? "not-allowed" : "pointer",
                height: 40,
              }}
              onClick={() => setupQr()}
              disabled={!hideError}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupTradeName;

