import React from 'react'
import pricingStyle from '../../static/css/Pricing';
import { Grid } from "@material-ui/core";
import { Visa, Mastercard, CC } from '../../static/icons/payment_method_icons/index'
import NoteCard from '../kyb/forms/noteCard';

const CreditDebit = props => {
    
    const xendit_enabled = localStorage.getItem('cc_enabled') == 'Xendit';

    const userType = localStorage.getItem("userType")

    const p = pricingStyle();

    const channels = [
        {
            icon: Mastercard,
            name: 'Mastercard',
            min: '50.00',
            max: '₱ 1,000,000.00'
        },    
        {
            icon: Visa,
            name: 'Visa',
            min: '50.00',
            max: '₱ 1,000,000.00'
        },      

    ]

    return(
        <div className="pricing-table-div top-20" hidden={props.hidden || userType === "ME"}>
            <div className="pricing-header">
                <div className="wc-header">
                    <img src={CC} className="right-8" alt="icon" />
                    Credit / Debit Card
                </div>

                <div className="top-20 normal-text-size liveColor">
                    Transaction fees are <span className={`text-${props.segments} wc-header`}>{xendit_enabled ? "3.5% + ₱ 12.00" : "2-3.5% to ₱ 5-12.00"}</span> per transaction.
                </div>

                <div className="top-20">
                    <NoteCard note="Additional 1 % international fee for cards issued outside the Philippines" />
                </div>
            </div>

            <div className="padding-lr-40 padding-bot-40">
                <div className="twoCol wc-header padding-20">
                    <Grid container>
                        <Grid item lg={3}>
                            Channel
                        </Grid>

                        <Grid item lg={3}>
                        </Grid>

                        <Grid item lg={3}>
                            Minimum Amount
                        </Grid>

                        <Grid item lg={3}>
                            Maximum Amount
                        </Grid>
                    </Grid>
                </div>
                {
                    channels.map((item, key) => {
                        return(
                            <div className="pricing-data normal-text-size liveColor">
                                <Grid key={key} container>
                                    <Grid item lg={2}>
                                        <div align='left'>
                                            <img alt="icon" src = {item.icon} style={{maxWidth: '60%'}} />
                                        </div>
                                    </Grid>

                                    <Grid item lg={4}>
                                        <p className={p.amount}>{item.name}</p>
                                    </Grid>

                                    <Grid item lg={3}>
                                        <p className={p.amount}>{item.min}</p>
                                    </Grid>

                                    <Grid item lg={3}>
                                        <p className={p.amount}>
                                            {item.max}
                                        </p>
                                    </Grid>  
                                </Grid>
                            </div>
                        )   
                    })
                }
            </div>
        </div>
    )
}

export default CreditDebit;