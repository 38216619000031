import React from 'react';
import { Card, Divider, Typography } from 'antd';
import { MasterWalletIcon, UserHistory } from '../../../../static/images/multiwallet';

const UserDetailsCard = (props) => {
    const { details } = props;
    const level = details && details.eon_level;
    return (
        <Card
            className="top-20"
            style={styles.root}
            bodyStyle={styles.bodyStyle}
        >
            <div>
                <div style={styles.div1}>
                    <Typography style={styles.name} className="right-16">{details && details.full_name}</Typography>
                    {/* <Typography style={{ ...styles.level, backgroundColor: `${level == 0 ? '#65C1F2' : level == 1 ? '#38ADA9' : level == 2 ? '#1E3799' : level == 3 ? '#FFB142' : '#F5922F'}` }}>
                        {level == 0 ? 'Basic' : level == 1 ? 'Pro' : level == 2 ? 'Advanced' : level == 3 ? 'Business' : 'Enterprise'}
                    </Typography> */}
                </div>
                <div style={styles.mailContact} className="top-16">
                    <Typography style={styles.email}>{details && details.email}</Typography>
                    <Divider style={{backgroundColor: '#000', height: 22}} color={'#000'} type='vertical' />
                    <Typography  style={styles.email}>+63{details && details.contact}</Typography>
                </div>
                <div className="top-8" style={{ ...styles.text }}>Wallet Balance: <b>₱{details && details.balance ? details.balance.toLocaleString("en-US", {minimumFractionDigits: 2}): '0.00'}</b></div>
            </div>

           
           <div style={styles.imgPosition}>
                <img src={UserHistory} />
            </div>

        </Card>
    )
}

const styles = {
    root: {
        width: '100%',
        position: 'relative',
        padding: '12px 0px 12px 16px',
        // height: '168px',
    },
    name: {
        color: '#000',
        fontWeight: 'bold',
        fontSize: 28,
    },
    email: {
        color: '#000',
        fontSize: 16,
    },
    bodyStyle: {
        width: '100%',
        padding: '16px 24px'
    },
    div1: {
        width: '100%',
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
    },
    div2: {
        width: '100%',
        // display: 'flex',
        // justifyContent: 'space-between',
        padding: '4px 0',
    },
    text: {
        color: '#2B2D32',
        fontSize: 16,
    },
    level: {
        borderRadius: 40,
        padding: '2px 8px',
        fontSize: 10,
        width: 64,
        color: '#FFFFFF',
        textAlign: 'center',
    },
    mailContact: {
        display: 'flex',
    },

    imgPosition: {
        position: 'absolute',
        right: 0,
        top: 0
    }
}

export default UserDetailsCard;