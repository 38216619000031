import { AlertActionTypes } from "./alertAction";
const initialState = {
  showAlert: false,
  alertType: "error",
  message: ""
};

const translateDBErrorMessage = (errorMessage) =>
  errorMessage.includes('Cant\'t connect to MySQL server') ? 
  'Error connecting to the database. Please try again' :
  errorMessage

export default function alertReducers(state = initialState, action) {
    
  switch (action.type) {
    case AlertActionTypes.SHOW_ALERT:
      return {
        ...state,
        showAlert: true,
        alertType: action.payload.type,
        message: action.payload.type === 'error' ? 
        translateDBErrorMessage(action.payload.message) : 
        action.payload.message,
      };
    case AlertActionTypes.HIDE_ALERT:
      return initialState
    default:
      return initialState;
  }
}
