import React, {useEffect} from 'react'
import * as Style from '../../../complete_profile/complete_profile_style'
import {Card, Input,Button, Modal, message} from 'antd'
import UploadIDImg from '../../../../static/img/upload_id.svg'
import SignatureCanvas from 'react-signature-canvas'
import axios from 'axios'
import {goToStep} from '../mobile_main_enterprise'


const SignatoryInformation = props => {
    
    useEffect(()=>{
        window.scrollTo(0, 0)
        getDetails()
        getMoa()
    },[])
    const [idFile, setIdFile] = React.useState("")
    const [idFileURL, setIdFileURL] = React.useState("")

    const [authorizedSignatory, setAuthorizedSignatory] = React.useState("")
    const [designation, setDesignation] = React.useState("")

    const [memoModal, setMemoModal] = React.useState(false)
    const [trimmedSignature, setTrimmedSignature] = React.useState("")

    const [moaPdf, setMoaPdf] = React.useState("")

    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(false)

    let sigPad = {}

    let idUpload = React.useRef()

    const handleUploadImage = (info) =>{
        var reader = new FileReader();
        reader.readAsArrayBuffer(info);
        
        reader.onload = function (event) {
        // blob stuff
        var blob = new Blob([event.target.result]); // create blob...
        window.URL = window.URL || window.webkitURL;

        setIdFile(info)
        setIdFileURL(window.URL.createObjectURL(blob))
    
        }
    }



    const clear = () => {
        sigPad.clear()
    }

    async function getDetails(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let userId = localStorage.getItem('userId')
        try{
            let response = await axios.get(
                process.env.REACT_APP_API_URL + `/api/kyb/${userId}`,yourConfig)
            
            console.log(response.data)
            setAuthorizedSignatory(response.data.authorized_signatory)
            setDesignation(response.data.designation)
            setIdFileURL(response.data.signatory_id_attachment)

            
        }
        catch(error){
            console.log("Unable to proceed. Please try again");
        }

    }

    async function getMoa(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        try{
            let response = await axios.get(
                process.env.REACT_APP_API_URL + `/api/kyb/moa`,yourConfig)
            
            console.log(response.data)
            setMoaPdf(response.data)
            
        }
        catch(error){
            console.log("Unable to proceed. Please try again");
        }

    }


    async function submit(type){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        const formData = new FormData();

        if(type == 0){

            if(authorizedSignatory == ""){
                setError(true)
                setErrorMessage("Please fill up authorized signatory")
                window.scrollTo(0, 0)
                return
            }
            else if(designation == ""){
                setError(true)
                setErrorMessage("Please fill up designation")
                window.scrollTo(0, 0)
                return
            }
            else if(idFileURL == ""){
                setError(true)
                setErrorMessage("Please upload signatory valid id")
                window.scrollTo(0, 0)
                return
            }
    
            else{
                formData.append('authorized_signatory', authorizedSignatory)
                formData.append('designation', designation)
                formData.append('signatory_id_attachment', idFile)   
            }
            
        }
        else{

            formData.append('moa_signature', sigPad.getTrimmedCanvas().toDataURL('image/png'))
        }
        

        try{
            setLoading(true)

            let response = await axios.put(process.env.REACT_APP_API_URL + "/api/kyb/", formData,yourConfig)
            console.log(response.data)

            if(response){
                if(type == 0){
                    setLoading(false)
                    setMemoModal(true)
                }
                else{
                    setMemoModal(false)
                    message.success('Successfully upload signature')
                }


            }
        }
        catch(error){
            message.error("Unable to proceed. Please try again");
        }
    }

    return(
        <div>
            <Card style={{marginBottom: '20px'}}>
                <div style={Style.headerStyle}>
                    Signatory Information
                </div>

                <div
                    style={{
                        backgroundColor: 'rgba(226, 76, 76, 0.1)',
                        borderLeft: '4px solid #E24C4C',
                        width: '100%',
                        height: '42px',
                        borderRadius: '4px',
                        marginBottom: '20px',
                        marginTop: '20px',
                        textAlign: 'center',
                        paddingTop: '10px',
                        display: error ? "block" : "none"
                    }}>
                    <span style={Style.textStyle}>{errorMessage}</span>
                </div>

                <div style={{width: '100%', marginBottom: '16px', marginTop: '20px'}}>
                    <div style={Style.textStyle}>Authorized Signatory</div>
                    <Input style={{height: '40px'}} value={authorizedSignatory} onChange={(e)=>setAuthorizedSignatory(e.target.value)}/>
                </div>
                <div style={{width: '100%', marginBottom: '16px'}}>
                    <div style={Style.textStyle}>Designation</div>
                    <Input style={{height: '40px'}} value={designation} onChange={(e)=>setDesignation(e.target.value)}/>
                </div>

                <div style={{width: '100%', marginBottom: '16px'}}>
                    <div style={Style.StepStyle}>Signatory Photo</div>
                </div>

                <Card 
                    bodyStyle={{
                        padding:'15px 10px 15px 10px'
                    }}
                    style={{
                        width: '100%',
                    }}>

                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>

                        {idFileURL ? <img src={idFileURL} alt="avatar" style={{ width: '100%' }}/> : <img src={UploadIDImg}/>}
                        
                        </div>

                        <input type="file"
                            ref={idUpload}
                            style={{display: 'none'}}
                            onChange={(e)=>handleUploadImage(e.target.files[0])}
                            accept=".jpg,.png,.jpeg"
                            >
                        </input>

                        <Button style={Style.takeSelfieStyle} onClick={()=>idUpload.current.click()}>{idFileURL ? 'Replace' : 'Valid ID of Signatory'}</Button>
                        <div style={Style.textStyle2}>
                            You can check here the list of valid IDs.
                        </div>
                </Card>

                
                
            </Card>
            
            <Modal
                style={{top: 10}}
                title={<div>Memorandum of Agreement</div>}
                visible={memoModal}
                bodyStyle={{padding:'0px'}}
                onCancel={()=>setMemoModal(false)}
                footer={[
                    <div style={{textAlign: 'left'}}>
                        <div style={{marginBottom: '20px', display:'flex', justifyContent:'center'}}>
                            <SignatureCanvas 
                                penColor='black' 
                                backgroundColor="#f0f0f0" ref={(ref) => { sigPad = ref }}
                                canvasProps={{width: 250, height: 100, className: 'sigCanvas'}} />
                        </div>
                        
                        <Button style={Style.submitBtnStyle} onClick={()=>submit(1)}>Sign and Confirm</Button>
                    </div>
                ]}
                >

                


            </Modal>
            <div style={{display:'flex'}}>
                <div style={{width: '49%', marginRight: '2%'}}>
                    <Button style={Style.backBtnStyle} onClick={()=>goToStep(2)}>Back</Button>

                </div>
                <div style={{width: '49%'}}>
                    <Button loading={loading} style={Style.submitBtnStyle} onClick={()=>submit(0)}>Submit</Button>
                </div>
            </div>
        </div>
    )

}

export default SignatoryInformation