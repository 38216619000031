import React, {useEffect} from 'react';
import { Dropdown, Menu, Table, Input, Icon } from 'antd';
import {history} from '../../../store/history'
import {useDispatch} from 'react-redux'


const CustomerForm = props => {
    const dispatch = useDispatch();
    
    const orderStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '40px'
      }
    const menuStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33',
        padding: '10px',
        margin: 'auto'
      }
    
    const columns = [
        {
            title: 'Customer',
            dataIndex: 'customer',
            width: 350,
            render: (text) => {
            return <span onClick={() => history.push('/customer_details')}><strong>{text.name}</strong><div>{text.address}</div></span>
          }
        },
        {
            title: 'No. of transactions',
            dataIndex: 'no_transactions',
        },
        {
            title: 'Date of last transaction',
            dataIndex: 'date_last_transaction',
        },
        {
          title: '',
          key: '',
          render:() => (
            <span>
              <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']} >
                <Icon type="ellipsis" />
              </Dropdown>
            </span>
          ),
        },
      ];
    const data = [
        {
          key: '1',
          customer:{
              address: 'Ortigas, Pasig City, +1 more',
              name: 'Alagas, Juan'
          },
          no_transactions: '3 transactions',
          date_last_transaction: '24 Jul  12:09pm',
        },

      ];

    
    const menu = (
        <Menu style={{ width: '180px' }}>
          <Menu.Item key="0" style={menuStyle}>
            View
            </Menu.Item>
          <Menu.Item key="1" style={menuStyle}>
            Edit
            </Menu.Item>
          <Menu.Item key="2" style={menuStyle}>
            Delete
            </Menu.Item>
        </Menu>
      );
    
    return(
        <div style={{ paddingTop: '38px', paddingLeft: '42px', paddingRight: '42px' }}>
            <div style={orderStyle}>Customers</div>
            <div style={{ marginBottom: '25px', float: 'right' }}>
              <Input
                placeholder="Search"
                style={{ width: '200px', height: '35px' }}
                prefix={<Icon type="search" />}/>
            </div>
            <Table
            
            columns={columns}
            dataSource={data} />
      </div>
    )
}

export default CustomerForm