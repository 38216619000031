import { Input, Modal, Typography } from "antd";
import React from "react";

export function ExportCSVModal({
  open,
  handleOk,
  confirmLoading,
  handleCancel,
  email,
  setEmail,
}) {
  return (
    <Modal
      title="Export CSV"
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      width={360}
      centered
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <Typography>
          Please enter your email address to send CSV reports directly to your
          email.
        </Typography>
        <div>
          <label style={{ fontSize: 12 }}>Send to email</label>
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
}
