import React from 'react'
import { Modal } from 'antd'
import * as Icon from '../../../static/icons/bux_qr/index'
import * as Style from './style';
import {isMobile} from 'react-device-detect'
import {history} from '../../../store/history'

const WhatsNewQr = props => {

    const {
        showModal, closeModal
    } = props

    return(
        <Modal visible={showModal}
            onCancel={() => closeModal(false)}
            footer={null}>
                <div style={Style.whatsNewMain} align="center">
                    <div style={Style.WhatsNewText}>What's New</div>

                    <div style={{marginTop: 24}}>
                        <img src={Icon.WhatsNew} style={{width: isMobile ? 250 : 388}}/>
                    </div>

                    <div style={Style.whatsNewShare}>
                        Share your <b>BUx <span style={{color: '#F5922F'}}>QR</span></b> Code to get paid!
                    </div>

                    <div style={Style.whatsNewDet}>
                    Faster, easier way to get paid! You can customize your QR page  on the settings page.
                    </div>

                    {/* <div style={{marginTop: 16}}>
                        <a style={Style.whatsNewLink} href="/faq">LEARN MORE</a>
                    </div> */}

                    <button style={Style.awesomeBtn} onClick={() => {history.push('/account_settings#qr_code');closeModal(false)}}>
                        Set up my QR Page now!
                    </button>

                    <div style={{marginTop: 16}}>
                        <a style={Style.whatsNewLink} onClick={() => closeModal(false)}>Not now</a>
                    </div>
                </div>
        </Modal>
    )
}

export default WhatsNewQr