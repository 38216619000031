import { makeStyles, useMediaQuery } from '@material-ui/core'

const isHighReso = window.screen.width * window.devicePixelRatio == 1440 && window.screen.height * window.devicePixelRatio >= 900;
const isHighDef = window.screen.width * window.devicePixelRatio > 1440 && window.screen.height * window.devicePixelRatio > 900;

const msgStyles = makeStyles(theme => ({

    //DIV 
    feeCard: {
        background: '#FFFFFF',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
        width: isHighDef ? 1080 : 916,
        fontFamily: 'Inter',
        textAlign: 'left',
        marginTop: 20,
    },

    spaceBetween:{
        display: 'flex',
        justifyContent: 'space-between'
    },

    cardLeftDiv: {
        padding: '47px 0px 19px 23px'
    },

    cardRightDiv: {
        padding: '28px 50px 0px 0px'
    },

    innerDiv: {
        background: '#FFFFFF',
        // border: '1px solid #E6EAF0',
    },

    tableHeader: {
        background: '#D1D5DD',
        color: '#000000',
        fontSize: '12px',
        lineHeight: '16px',
    },

    tableHeaderData: {
        padding: 16,
        width: '14%'
    },

    tableChannelHeader: {
        width: '14%',
        padding: 16,
        color: '#2b2d33',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 600
    },

    tableChannelText: {
        padding: 16,
        color: '#2b2d33',
        fontSize: '14px',
        lineHeight: '16px',
    },

    //BUTTON
    editBtn : {
        width: 110,
        height: '40px',
        background: '#F5922F',
        border: '1px solid #f5922f',
        borderRadius: '10px',
        color: '#fff',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
        outline: 0,
        '&:hover': {
            opacity: 0.8,
            cursor: 'pointer'
        },

        '&:active': {
            background: '#DA7F24',
            opacity: 1

        }
    },

    disabledBtn : {
        width: 110,
        height: '40px',
        background: '#F5922F',
        border: '1px solid #f5922f',
        borderRadius: '10px',
        color: '#fff',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
        outline: 0,
        cursor: 'not-allowed',
        opacity: '0.5',
    },

    cancelBtn: {
        width: 110,
        height: '40px',
        background: '#fff',
        border: '1px solid #f5922f',
        borderRadius: '10px',
        color: '#f5922f',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
        outline: 0,
        '&:hover': {
            background: '#f5922f',
            cursor: 'pointer',
            color: '#fff',
        },

        '&:active': {
            background: '#DA7F24',
            opacity: 1

        }
    },

    restoreBtn : {
        background: 'transparent',
        border: 'none',
        color: '#f5922f',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
        outline: 0,
        '&:hover': {
            cursor: 'pointer',
            color: '#f5922f',
            opacity: 0.8
        },

        '&:active': {
            opacity: 1,
            color: '#DA7F24',
        }
    },

    restoreDisabledBtn: {
        background: 'transparent',
        border: 'none',
        color: '#909196',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
        outline: 0,
        cursor: 'not-allowed'
    },

    //TEXT
    headerText: { 
        fontSize: 20,
        lineHeight: '16px',
        fontWeight: 'bold',
        color: '#2B2D33',
    },

    normalText: {
        fontSize: 16,
        lineHeight: '24px',
        color: '#2B2D33'
    },

    channelHeader: {
        color: '#0D3D76',
        fontSize: 16,
        lineHeight: '19px',
        fontWeight: 'bold',
        marginLeft: '8px'
    },

    defaultBtn: {
        marginTop: 32,
        fontSize: 24,
        lineHeight: '32px',
        fontWeight: '300',
        color: '#2b2d33'
    },

    confirmText: {
        marginTop: 20,
        fontSize: '14px',
        lineHeight: '18px',
        color: '#2b2d33'
    },

    btnDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 20
    },

    cardTop: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 32,
        padding: '32px 0px 16px 0px',
        borderTop: '1px solid #E6EAF0',
    },

    cardText: {
        color: '#000000',
        fontSize: 16,
        lineHeight: '21px',
    },

    cardModalHeaderText:{ 
        color: '#2b2d33',
        fontWeight: 300,
        fontSize: 24,
        lineHeight: '32px',
        marginTop: 32
    },

    cardModalText: {
        color: '#000000',
        fontSize: 16,
        lineHeight: '24px',
        marginTop: 16
    },

    cardBtnDiv: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'space-between'
    },
    
    confirmBtn: { 
        background: '#f5922f',
        border: '1px solid #F5922F',
        color: '#fff',
        borderRadius: '4px',
        boxSizing: 'border-box',
        fontWeight: 'bold',
        width: 130,
        height: '40px',
        cursor: 'pointer',
        '&:hover': {
            opacity: '0.8'
        },

    }

}))

export default msgStyles