import React from 'react'
import { Input, Modal } from 'antd'

const ExportModal = props => {

    const {
        segments,
        emailReceipt,
        setEmailReceipt,
        exportModal,
        hideModal,
        exportNow

    } = props

    return(
        <Modal
            visible={exportModal}
            footer={null}
            width="360px"
            onCancel={hideModal}
          >
            <div style={{paddingTop: '20px'}}>
              <div style={{fontSize: '24px', color: '#2b2d33', marginBottom: '8px'}}>Export CSV</div>
              <div style={{fontSize: '16px', color: '#2b2d33', marginBottom: '16px'}}>
                Please enter your email address to send CSV reports directly to your email.
              </div>
              <div style={{fontSize:'14px', color: 'rgba(43, 45, 50, 0.8)', marginBottom: '5px'}}>Send to Email</div>
              <Input 
                size="large" 
                style={{width: '100%', marginBottom: '14px'}} 
                placeholder="Email"
                onChange={(e)=>setEmailReceipt(e.target.value)} />
              <button
                className={`btn--${segments}`} 
                onClick={exportNow}
                disabled={emailReceipt == ''}
                style={{
                  width: '100%',
                  height: '48px',
                  color: '#fff',
                  fontWeight: '600'
                }}>Send
              </button>
            </div>
        </Modal>
    )
}

export default ExportModal