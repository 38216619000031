const tutorialActions = {
    setDemoStep: (step) => ({
      type: 'SET_DEMO_STEP',
      payload: step,
    }),
    setStartDemo: (val) => ({
      type: 'SET_START_DEMO',
      payload: val,
    }),
    setTutorialStartModal: (val) => ({
      type: 'SET_TUTORIAL_START_MODAL',
      payload: val,
    }),
  }
  
  export default tutorialActions;