import React, {useEffect} from 'react'
import { Button, DatePicker, Input, Table, Select, Icon, Modal, message} from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import { history } from '../../../store/history';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import Warning from '../../../static/icons/warning.svg'
import crypto from 'crypto';
import axios from 'axios'
import addMoneySettlementServices from './add_money_services';

let requestToken;

const AddMoneySettlement = () => {

    const dispatch = useDispatch();
    const Option = Select
    const ButtonGroup = Button.Group;

    const yourConfig = {
        headers: {
           Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }

    const userType = localStorage.getItem("userType")
    const jwtToken = localStorage.getItem("jwtToken")

    const [data, setData] = React.useState([])
    const [responseSummary, setResponseSummary] = React.useState(null)

    //Page
    const [pageSize, setPageSize] = React.useState(10)
    const [currentPage, setCurrentPage] = React.useState(1)

    //Date
    const [startDate, setStartDate] = React.useState("")
    const [endDate, setEndDate] = React.useState("")
    const [summary, setSummary] = React.useState("Today")

    //Moment
    const [startMoment, setStartMoment] = React.useState("")
    const [endMoment, setEndMoment] = React.useState("")
    const [endOpen, setEndOpen] = React.useState(false)

    //Other State
    const [search, setSearch] = React.useState("")
    const [selected, setSelected] = React.useState("Pending")
    const [email, setEmail] = React.useState("")
    const [isFiltered, setIsFiltered] = React.useState(false)
    const [exportModal, setExportModal] = React.useState(false)
    const [imageModal, setImageModal] = React.useState(false)
    const [approveModal, setApproveModal] = React.useState(false)
    const [imageProofURL, setImageProofURL] = React.useState("")
    const [uidToBeApprove, setUidToBeApprove] = React.useState(null)

    const generateToken = () => {
        let generatedRequestToken = crypto.randomBytes(16).toString('hex');
        requestToken = generatedRequestToken
        return generatedRequestToken
    }

    const disabledStartDate = startValue => {
        if (!startValue || !endMoment) {
          return false;
        }
        return startValue && startValue > moment().endOf('day');
    }
    const disabledEndDate = endValue => {
        if (!endValue || !startMoment) {
            return false;
        }
        return endValue.valueOf() <= startMoment.valueOf();
    };

    const onStartChange = value => {
        setStartDate(value.format("MM-DD-YYYY"))
        setStartMoment(value)
        setIsFiltered(true)
        setSummary("Custom")
    }

    const onEndChange = value => {
        setEndDate(value.format("MM-DD-YYYY"))
        setEndMoment(value)
        setIsFiltered(true)
        setSummary("Custom")
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    const handleStartOpenChange = open => {
        if (!open) {
          setEndOpen(true)
        }
    }
    useEffect(()=>{
        if(jwtToken == "" || jwtToken == null){
            history.push('/login')
        }
        else{
            if(userType == "ME" || userType == "CO"){
                history.push('/dashboard')
            }
            else if(userType != "AD" && userType != "FI"){
                history.push('/orders')
            }
            else{
                fetchData(
                    generateToken(),
                    selected,
                    1,
                    pageSize,
                    startDate,
                    endDate,
                    summary,
                    search
                )
            }
        }
    },[])

    const columns = [
        {
            title: 'Trans ID',
            dataIndex: 'id',
            width: 100,
            fixed: 'left',
            render: text => {
              return text
            }
        },
        {
            title: 'Business Name',
            dataIndex: 'business_name',
            width: 150,
            fixed: 'left',
        },
        {
            title: 'Reference no.',
            width: 150,
            fixed: 'left',
            render: record => {
                return <div>
                        <a style={styles.codeStyle}>{record.code} </a>
                        <div style={styles.descSyle}>{record.description}</div>
                    </div>
            }
            
        },
        {
            title: 'Proof Image',
            dataIndex: 'image_proof',
            width: 200,
            render: text => {
              return (
                <a style={{...styles.dataStyle, textDecoration: 'underline'}} 
                    onClick={()=>{setImageProofURL(text); setImageModal(true)}}>
                    Open Image
                </a>
              )
            }
        },
        {
            title: 'Date Generated',
            dataIndex: 'timestamp',
            width: 200,
            render: text => {
              return <span style={styles.dataStyle}>{text}</span>
            }
        },
        {
            title: 'Date Expires',
            dataIndex: 'expire_at',
            width: 200,
            render: text => {
              return <span style={styles.dataStyle}>{text}</span>
            }
        },
        {
            title: 'Amount(₱)',
            dataIndex: 'amount',
            width: 140,
            render: text => {
              return <span style={styles.dataStyle}>₱ {numberWithCommas(text)}</span>
            }
        },

        selected == 'Pending' ?
        {
            title: 'Action',
            width: 100,
            fixed: 'right',
            render: (record) => {
                return(
                    <Button style={styles.approveBtn}onClick={()=>{setApproveModal(true); setUidToBeApprove(record.uid)}}>Approve</Button>
                )
            }
        } : {}
        
    ];

    const fetchData = async(token, type, page, size, start, end, summarySelected, search, is_export="", email="") => {
        let response = await addMoneySettlementServices.getAddMoneySettlement(
            token, 
            type, 
            page,
            size,
            start,
            end,
            summarySelected,
            search,
            is_export,
            email
        )
        if(response.status && response.status == 'success'){
            setExportModal(false)
            message.success("CSV reports successfully sent to your email address")
        }
        else if(response.token == requestToken && response.orders){
            console.log(response)
            setData(response.orders)
            setResponseSummary(response.summary)
        }
    } 

    const approveAddMoney = async() => {
        let params = {
            uid: uidToBeApprove
        }
        let response = await addMoneySettlementServices.approveAddMoney(params)
        if(response.status && response.status == 'success'){
            setApproveModal(false)
            message.success(response.message)
            fetchData(
                generateToken(),
                selected,
                1,
                pageSize,
                startDate,
                endDate,
                summary,
                search
            )
        }
        else{
            setApproveModal(false)
            message.success(response.message)
        }
    } 

    const exportTable = async() => {   

        let table_type = selected == "Approved" ? 'Paid' : 'Pending'
        
        let response = await axios.get(
          process.env.REACT_APP_API_URL + `/api/orders/export/?type=${table_type}&start=${startDate}&end=${endDate}&summary=${summary}&search=${search}&payment_methods=manual_add&email=${email}&is_settlement=True`,yourConfig
        );
        if(response.data.status=='success'){
            setExportModal(false)
            message.success(response.data.message)
        }
        else{
            setExportModal(false)
            message.error(response.data.message)
        }
    };


    const disabledApplyButton = () => {
        return startDate == '' && endDate == "" && summary == 'Today' && search == ""
    }

    const applyFilter = () => {
        fetchData(
            generateToken(),
            selected,
            1,
            pageSize,
            startDate,
            endDate,
            summary,
            search
        )
        setCurrentPage(1)
        setIsFiltered(true)
    }

    const clearFilter = () => {
        setSummary("Today")
        setStartDate("")
        setStartMoment("")
        setEndDate("")
        setEndMoment("")
        setIsFiltered(false)
        fetchData(
            generateToken(),
            selected,
            1,
            pageSize,
            "",
            "",
            "Today",
            ""
        )
    }

    const changePage = (event,page) => {
        setCurrentPage(page)
        fetchData(
            generateToken(),
            selected,
            page,
            pageSize,
            startDate,
            endDate,
            summary,
            search
        )
    }

    const status = ['Pending', 'Approved']

    return(
        
        <div style={{ paddingTop: '38px', paddingLeft: '42px', paddingRight: '42px' }}>
            <div style={styles.headerStyle}>Add Money Settlement</div>
            <div style={{ marginBottom: '25px', display: 'flex', justifyContent: 'space-between' }}>
                <ButtonGroup>
                {
                    status.map((item) => {
                    return <Button
                    key={item.toLocaleLowerCase()}
                    onClick={() => { 
                        setSelected(item);
                        fetchData(
                            generateToken(),
                            item,
                            1,
                            pageSize,
                            startDate,
                            endDate,
                            summary,
                            search
                        )
                    }}
                        style={{
                        padding: '12px 18px 13px 18px',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        lineHeight:'16px',
                        height:'40px',
                        borderTopLeftRadius: item == "Pending" ? '10px' : '0px',
                        borderBottomLeftRadius: item == "Pending" ? '10px' : '0px',
                        borderTopRightRadius: item == "Approved" ? '10px' : '0px',
                        borderBottomRightRadius: item == "Approved" ? '10px' : '0px',
                        color: selected === item ? '#ffffff' : '#2b2d33',
                        backgroundColor: selected === item ? '#0D3D76' : '#fff',
                        }}>{item}</Button>
                    })
                }
                </ButtonGroup>
                <div>
                    <Button
                        style={{
                            backgroundColor: '#1DD28B',
                            border: '#1DD28B',
                            height: '40px',
                            borderRadius:'10px',
                            zIndex: 1,
                            marginRight: '10px'
                        }}
                        onClick={()=> setExportModal(true)}>
                        <div style={{fontSize: '14px', fontWeight:'600', color: '#fff'}}>Export CSV</div>
                    </Button>
                </div>
            </div>
            <div style={{backgroundColor: '#FFF', border: '1px solid #D1D5DD'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', padding: '20px'}}>
                    <div style={{display: 'flex'}}>
                        <div style={{color: '#000', fontSize: '16px', fontWeight: 'bold'}}>Filter</div>
                        <a style={styles.clearFilter} hidden={!isFiltered} onClick={()=>clearFilter()}>
                            <Icon type="close-circle" theme="filled" style={{fontSize: '10px', marginRight: '2px'}}/> 
                            <span style={{fontSize: '12px', color: '#000'}}>  Clear Filter</span>
                        </a>
                    </div>
                    <div>
                        <img src={Warning} alt="Icon" style={{transform: 'rotate(180deg)'}}/> <span style={{color: '#909196', fontSize: '12px'}}>Click <b>Apply</b> button to show results.</span>
                    </div>
                </div>
                <div style={{float: 'right', padding: '0px 20px 20px 20px', zIndex: 1, display: 'flex'}}>
                    <Select
                        size="large"
                        value={summary}
                        onChange={(e)=> {setSummary(e); setIsFiltered(true)}}
                        style={{width: '150px', color: '#000'}}
                        >
                        <Option value="All">All Time</Option>
                        <Option value="Today">Today</Option>
                        <Option value="Week" >Last 7 days</Option>
                        <Option value="Month" >Last 30 days</Option>
                        <Option value="Custom" >Custom</Option>
                    </Select>   
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledStartDate}
                        // defaultValue={moment()}
                        value={startMoment}
                        size="large" picker="month"
                        onChange={onStartChange}
                        onOpenChange={handleStartOpenChange}
                        placeholder='Start date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <span style={{ paddingTop: 8 }}>-</span>
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledEndDate}
                        // defaultValue={moment()}
                        value={endMoment}
                        size="large"
                        picker="month"
                        onChange={onEndChange}
                        onOpenChange={(open)=>setEndOpen(open)}
                        open={endOpen}
                        placeholder='End date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    
                    <Input
                        placeholder="Search"
                        value={search}
                        style={{ width: '156px', height: '40px', borderRadius:'4px'}}
                        onChange={(e)=> {setSearch(e.target.value); setIsFiltered(true)}}
                        suffix={<Icon type="search" style={{fontSize:'18px'}}/>}
                    />
                    <Button
                        disabled={disabledApplyButton()}
                        onClick={()=>applyFilter()}
                        style={{
                            ...styles.applyBtn, 
                            marginLeft: '10px',
                            opacity:  disabledApplyButton() ? '0.5' : '1',
                            width: '93px'
                        }}>Apply
                    </Button>
                    
                </div>
                <div style={{width:'100%', display: 'flex', marginBottom: '20px', backgroundColor: '#FFF'}}>
                    <div style={styles.newSummary}>
                        <div style={{color: '#000', marginRight: '5px'}}>No. of Request:</div> <div style={{color:'#0D3D76', fontWeight: 'bold', marginRight: '32px'}}>{responseSummary && responseSummary.transaction}</div>
                    </div>
                    <div style={styles.newSummary}>
                        <div style={{color: '#000', marginRight: '5px'}}>Total Amount:</div> <div style={{color:'#0D3D76', fontWeight: 'bold', marginRight: '32px'}}>₱ {numberWithCommas(responseSummary ? responseSummary.total : 0)}</div>
                    </div>
                </div>
                <Table
                    scroll={{x: 1300 }}
                    style={{width: '70vw'}}
                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                    columns={columns}
                    dataSource={data ? data : []}
                    pagination={false} 
                    />
                
                {
                    responseSummary && data.length > 0 &&
                    <div style={{padding: '20px', display:'flex', justifyContent:'space-between'}}>
                    <div>
                        {(currentPage - 1) * pageSize  + 1} 
                        - 
                        {(currentPage - 1) * pageSize  + responseSummary.page_total} out of {responseSummary.total_data}
                    </div>
                    <div style={{display: 'flex'}}>
                        <Select 
                        size="small" 
                        style={{width: '100px'}} 
                        value={pageSize}
                        onChange={(e)=>
                        {
                            setPageSize(e);
                            fetchData(
                                generateToken(),
                                selected,
                                1,
                                e,
                                startDate,
                                endDate,
                                summary,
                                search
                            )
                        }
                        }>
                        
                        <Option value={5}>Show 5</Option>
                        <Option value={10}>Show 10</Option>
                        <Option value={15}>Show 15</Option>
                        <Option value={20}>Show 20</Option>
                        </Select>
                        <Pagination 
                            count={responseSummary.pages} 
                            shape="rounded"
                            page={currentPage} 
                            size="small"
                            onChange={changePage}
                        />
                    </div>
                    </div>
                }
            </div>
            <Modal
                visible={exportModal}
                footer={null}
                width="360px"
                onCancel={()=>setExportModal(false)}
            >
                <div style={{paddingTop: '20px'}}>
                <div style={{fontSize: '24px', color: '#2b2d33', marginBottom: '8px'}}>Export CSV</div>
                <div style={{fontSize: '16px', color: '#2b2d33', marginBottom: '16px'}}>
                    Please enter your email address to send CSV reports directly to your email.
                </div>
                <div style={{fontSize:'14px', color: 'rgba(43, 45, 50, 0.8)', marginBottom: '5px'}}>Send to Email</div>
                <Input 
                    size="large" 
                    style={{width: '100%', marginBottom: '14px'}} 
                    placeholder="Email"
                    onChange={(e)=>setEmail(e.target.value)} />
                <Button 
                    onClick={()=>exportTable()}
                    disabled={email == ''}
                    style={{
                    backgroundColor: '#F5922F',
                    width: '100%',
                    height: '48px',
                    color: '#fff',
                    border: '1px solid #F5922F',
                    fontWeight: '600'
                    }}>Send
                </Button>
                </div>
            </Modal>
            <Modal
                visible={imageModal}
                footer={null}
                width="500px"
                onCancel={()=>setImageModal(false)}
            >
                <div style={{paddingTop: '20px'}}>
                    <div style={{fontSize: '24px', color: '#2b2d33', marginBottom: '8px'}}>Attachment</div>
                    <img style={{width: '100%'}} src={imageProofURL != "" && imageProofURL}/>
                </div>
            </Modal>
            <Modal
                width={360}
                visible={approveModal}
                onCancel={()=>setApproveModal(false)}
                footer={null}>
            
                <div align='left'>
                    <div style={{fontSize: '24px', color: '#2b2d33', marginBottom: '8px'}}>Approve Add Money</div>
                    <div style={{fontSize: '16px', color: '#2b2d33', marginBottom: '16px'}}>
                    Are you sure you want to approve this manual add money?
                    </div>
                </div>
                <div style={{display: 'flex'}}>
                    <Button style={styles.cancelBtn}  onClick={()=>setApproveModal(false)}>Cancel</Button>
                    <Button style={styles.confirmBtn} onClick={()=>approveAddMoney()}>Confirm</Button>
                </div>
            </Modal>

        </div>
    )
}


const styles = {
    headerStyle: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '30px'
    },
    tableHeaderStyle: {
        fontStyle: 'normal',
        fontSize: '16px',
        color: '#333333',
        marginBottom: '20px',
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '20px',
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 158,
        padding: '0 8px 0 8px',
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    newSummary: {
        width: '50%', 
        border: '1px solid #D1D5DD',
        display: 'flex', 
        justifyContent: 'center', 
        padding: '5px 0px 5px 0px'
    },
    clearFilter: {
        padding: '3px 8px 4px 8px',
        backgroundColor: '#E6EAF0',
        borderRadius: '50px',
        height: '29px',
        marginLeft: '10px',
        color: '#000'
    },
    applyBtn: {
        color: '#fff',
        backgroundColor: '#F5922F',
        height: '40px',
        border: '1px solid #F5922F',
        borderRadius: '4px',
        fontWeight: '600',
        fontSize: '16px'
    },

    selectEnterprise: {
        width: '239px',
        height: '40px',
        borderRadius: '4px',
        padding: '8px 16px 8px 16px',
        border: '1px solid #D1D5DD',
        marginRight: '10px',
        display: 'flex',
        justifyContent: 'space-between'
    },

    selectedMasterText: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2b2d33',
        width: '200px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },

    businessNameStyle: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2B2D33'
    },
    emailStyle:{
          maxWidth: '150px',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '12px',
          lineHeight: '24px',
          color: '#2B2D33',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
    },

    cancelBtn: {
        height: '48px',
        width: '49%',
        marginRight: '2%',
        border: '1px solid #F5922F',
        borderRadius: '4px',
        color: '#F5922F',
        fontSize: '16px',
        fontWeight: '600'
    },

    confirmBtn: {
        height: '48px',
        width: '49%',
        border: '1px solid #F5922F',
        backgroundColor: '#F5922F',
        borderRadius: '4px',
        color: '#FFF',
        fontSize: '16px',
        fontWeight: '600'
    },
    codeStyle: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2B2D33'
    },
    descSyle: {
        maxWidth: '150px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#2B2D33',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    dataStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#000000'
    },

    approveBtn: {
        height: '40px',
        width: '90px',
        border: '1px solid #1DD28B',
        backgroundColor: '#1DD28B',
        borderRadius: '4px',
        color: '#FFF',
        fontSize: '14px',
        fontWeight: '600'
    },
}

export default AddMoneySettlement