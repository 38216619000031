import React from 'react'
import BasicInfoForm from './basicInfoComponent/basicInfoForm'
import {history} from '../../store/history'
class BasicInfo extends React.Component{

    componentDidMount(){
        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            window.location.href = '/login'
          }
    }
    render(){

        return(
            <BasicInfoForm/>
        )
    }
}

export default BasicInfo