import axios from 'axios';
import {Handle401} from '../../handle401/handle401'

const authorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const authorizedFormAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const batchUploadServices = {
  getTableData: async (testMode, status, page, startDate, endDate, searchString) => {
    const endpoint_url = testMode ? `/api/sandbox/payment_link_batch/` : `/api/payment_link_batch/`;

    let query_params = [
      'status=' + status,
      'page=' + page,
      'start=' + startDate,
      'end=' + endDate,
      'search=' + searchString,
    ]
    const full_url = endpoint_url + '?' + query_params.join("&");

    try {
      const response = await authorizedAxiosRequest().get(full_url);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getUploadedLinksData: async (testMode, batch_id) => {
    const endpoint_url = testMode ? `/api/sandbox/payment_link_batch/${batch_id}/`
                                  : `/api/payment_link_batch/${batch_id}/`;

    try {
      const response = await authorizedAxiosRequest().get(endpoint_url);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getGeneratedLinksData: async (testMode, batch_id, page=1, pageSize=5, dump=false) => {
    const endpoint_url = testMode ? `/api/sandbox/payment_link_batch/${batch_id}/`
                                  : `/api/payment_link_batch/${batch_id}/`;

    let query_params = [
      'page=' + page,
      'page_size=' + pageSize,
      'dump=' + dump,
    ]
    const full_url = endpoint_url + '?' + query_params.join("&");

    try {
      const response = await authorizedAxiosRequest().get(full_url);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  uploadCsv: async (csv, testMode) => {
    const endpoint = testMode ? `/api/sandbox/payment_link_batch/` : `/api/payment_link_batch/`;
    const formData = new FormData();
    formData.append('source_file', csv);
    try {
      const response = await authorizedFormAxiosRequest().put(endpoint, formData);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  deleteUploaded: async (id, testMode) => {
    const endpoint = testMode ? `/api/sandbox/payment_link_batch/` : `/api/payment_link_batch/`;
    try {
      const response = await authorizedAxiosRequest().delete(endpoint, {data:id});
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  replaceCsv: async (id, csv, testMode) => {
    const endpoint = testMode ? `/api/sandbox/payment_link_batch/` : `/api/payment_link_batch/`
    const formData = new FormData();
    formData.append("target_id", id);
    formData.append("source_file", csv);
    try {
      const response = await authorizedFormAxiosRequest().patch(endpoint, formData);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  generateLinks: async (params, testMode) => {
    const endpoint = testMode ? `/api/sandbox/payment_link_batch/` : `/api/payment_link_batch/`;
    try {
      const response = await authorizedAxiosRequest().post(endpoint, params);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
}

export default batchUploadServices;