import React from 'react'
import { Divider, Input, message, Button, Typography} from 'antd'
import * as Style from './style'
import QRCode from 'react-qr-code'
import * as Icon from '../../static/icons/bux_qr/index'
import Quick from './quick.svg'
import {isMobile} from 'react-device-detect'
import DownloadQr from '../account_settings/bux_qr/download_qr'
import {history} from '../../store/history'

const ShareQrLink = props => {

    const {
        qrOn, notFound, data, fromSettings, qrLink, disabled, isSubCo
    } = props

    const isChild = localStorage.getItem("is_child") == "true" || localStorage.getItem("is_child") == true
    const testMode = history.location.pathname.indexOf('/test') > -1;
    const segments = localStorage.getItem('segments');
    const isMultiwallet = localStorage.getItem('is_multiwallet');

    let buxQrLink;

    const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
    const FB_APP_ID = process.env.REACT_APP_FB_ID
    const [isDownload, setIsDownload] = React.useState(fromSettings ? true : false)
    
    const REACT_APP_ROOT_URL = process.env.REACT_APP_ROOT_URL;
    // const qrLink = testMode ? 'https://' + data.trade_name + '.' + REACT_APP_ROOT_URL+'/test/qr' : 'https://' + data.trade_name + '.' + REACT_APP_ROOT_URL;
    
    // const qrLink = testMode ? REACT_APP_BASE_URL+'/'+data.trade_name+'/test' : REACT_APP_BASE_URL+'/'+data.trade_name


    const customShareBtn = (icon, text) => {
        return (
            <button style={Style.btnstyle} onClick={() => shareButton(text)}>
                <img src={icon} alt="logo" />
                <div style={Style.btnText}>{text}</div>
            </button>
        )
    }

    function shareButton(text) {
        if(text === 'Copy Link') {
            message.destroy()
            buxQrLink.select()
            document.execCommand('copy')
            message.info('Copy to clipboard', 1)
        }

        else if(text === 'Messenger') {
            // return (<a href={`${FB_SHARE_URL}redirect_uri=${APP_BASE_URL}/dashboard&app_id=${FB_APP_ID}&link=${REACT_APP_BASE_URL}${testMode?'/test/checkout/':'/checkout/'}${response.uid}/%3Futm_source%3Dbux%26utm_medium%3Dcode-facebook`} target="_blank"><img src={share_messenger} style={{ width: '40px', marginRight: '5px' }} alt="#" /></a>);
            
            window.open(`${FB_SHARE_URL}redirect_uri=${REACT_APP_BASE_URL}/dashboard&app_id=${FB_APP_ID}&link=${qrLink}/%3Futm_source%3Dbux%26utm_medium%3Dcode-facebook`)
        }

        else if(text === 'Download'){
            setIsDownload(true)
        }
    }

    return(
        <div style={Style.marginTop} align="center">

            {
                disabled ? 
                <div style={{padding:'15px 0px'}}>
                    <Typography>Your Checkout page is OFF.</Typography>
                    {   isMultiwallet ?
                        <Typography>Enable Checkout page through your <a href="/account_settings#qr_code" target="_blank" className={`text-${segments} wc-header`}>Settings</a></Typography> :
                        <Typography>Contact us or your master wallet if you want to enable your Checkout page.</Typography>
                    }
                </div> : 
                <div style={{marginTop: 32}}>
                    <QRCode value={qrOn || isSubCo ? qrLink : notFound} size="120" level={'L'} />
                    <div style={{...Style.qrHeaderText, marginTop: 20}}>
                        {data.trade_name} 
                    </div>
                    <div style={Style.hideOverflow}>
                        <a className={`text-${segments} wc-header`} href>{qrLink}</a>
                    </div>
                    <button className={`btn--${segments} btn-height top-12 `} onClick={()=> window.open( qrOn || isSubCo ? qrLink : notFound, '_blank' )}>
                        View My Checkout page
                    </button>

                    <div hidden={!qrOn}>
                        <div style={Style.orDiv}>
                            <Divider> OR </Divider>
                        </div>

                        <div style={Style.shareQrDiv}>
                            <div style={Style.shareText}>
                                Share My Checkout to get paid
                            </div>
                            
                            <div align='center'>
                                <Input type="text" readOnly value={qrLink} size="large" style={{ position: 'absolute', top: '-10000px'}} ref={(textarea) => buxQrLink = textarea}/>

                                <div style={{display:'flex', justifyContent: 'center'}}>
                                    {customShareBtn(Icon.CopyLink, 'Copy Link')}
                                    {customShareBtn(Icon.Messenger, 'Messenger')}
                                    {customShareBtn(Icon.Download, 'Download')}
                                    {/* {customShareBtn(Icon.FStyle.book, 'FStyle.book')} */}
                                </div>
                            </div>
                        </div>

                        <div style={{marginTop: 32}}>
                            <div style={Style.questionText}>Have a question on My Checkout?</div>
                            <div style={{marginTop: 8}}>
                                <a href style={Style.qrLinkText} className={`text-${segments} wc-header`} onClick={() => window.open('https://ubx-bux.zendesk.com/hc/en-us')}>
                                    Click Here
                                </a>
                            </div>
                        </div>

                        <div style={{display: 'flex', ...Style.quickDiv}} hidden={isMobile || isChild || testMode}>
                            <div>
                                <img src={Quick} />
                            </div>
                            <div style={Style.rightTextDiv}>
                                <div style={Style.header}>QUICK TIP</div>
                                <div style={Style.text}>
                                    Customize My Checkout Personal Message, Custom amount and more through your <a href="/account_settings#my_checkout" target="_blank" className={`text-${segments} wc-header`}>Settings</a>.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            
        </div>
    )
}
export default ShareQrLink;