import React from 'react';
import * as Icon from '../../../static/icons/how_it_works/index';
import * as Img from '../../../static/images/how_it_works/index';
import ContentCard from '../content_card';


const PayoutStep = props => {
    
    const {
        segments, step
    } = props;


    let payout_icon = segments == 'me_' ? Icon.Payout_me_ : 
                                segments == 'gig' ? Icon.Payout_gig : 
                                    segments == 'sme' ? Icon.Payout_sme : 
                                        segments == 'biz' ? Icon.Payout_biz: ""
                                        


    return (
        <div>
            <ContentCard step={step}
                icon={payout_icon}
                image={Img.Payout}
                title="Payout"
                content={"This tab shows you all your payouts."} />
        </div>
    )
}

export default PayoutStep;