import { getAllPayouts } from './payoutsService';


export const PayoutActionTypes = {

    PAYOUT_REQUEST: "@PAYOUT_REQUEST",
    PAYOUT_SUCCESS: "@PAYOUT_SUCCESS",
    PAYOUT_FAILED: "@PAYOUT_FAILED",

}

export function getPayouts(page, page_size, search, type, start, end, mode, user_type, summary, email, is_export) {
    return async (dispatch) => {
        try {
            dispatch({ type: PayoutActionTypes.PAYOUT_REQUEST });
            let response = await getAllPayouts(page, page_size, search, type, start, end, mode, user_type, summary, email, is_export);

            dispatch({
                type: PayoutActionTypes.PAYOUT_SUCCESS,
                payload: response
            });
        }

        catch (error) {
            console.log(error)
            dispatch({
                type: PayoutActionTypes.PAYOUT_FAILED, payload: error
            });

            console.log(error.message)
        }
    }
}