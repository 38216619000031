import { makeStyles } from '@material-ui/core';

const alertStyle = makeStyles(theme => ({

    alertBox: {
        backgroundColor: '#fff1f0',
        borderTop: '1px solid #ffa39e',
        borderBottom: '1px solid #ffa39e',
        borderLeft: '4px solid #E24C4C',
        borderRight: '1px solid #ffa39e',
        borderRadius: '4px',
        padding: '5px 10px 5px 10px'
        
    },

    alertSide: {
        width: '5px',
        backgroundColor: 'red',
        borderRadius: '4px',
        padding: '5px 0px 5px 5px'
        
    },

}))

export default alertStyle

