import React from 'react';
import SalesforceCredentials from './salesforce';
import * as styles from './style'
import CustomCredentials from './tpp';
import Credential from '../../../../../static/icons/credential.svg'

const AdminCredentials = props => {
    const pUid = props.props.match.params.id;

    return(
        <div style={styles.cardDiv}>
            <div style={styles.header}>
                <img src={Credential} style={{marginRight: 8}} />
                Credentials</div>

                <CustomCredentials pUid={pUid} title={"Cybersource"} type={"live"} />
                <CustomCredentials pUid={pUid} title={"Sandbox Cybersource"} type={"sandbox"} />
                {/* <SalesforceCredentials pUid={pUid} /> */}
        </div>
    );
};

export default AdminCredentials;