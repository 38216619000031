import React, {useEffect} from 'react'
import {Card, Divider, Button, Icon, Badge, Select, Typography} from 'antd'
import {useDispatch, useSelector} from 'react-redux';
import {getOrders} from '../../orders/orderAction'
import {isMobile} from 'react-device-detect';
import {history} from '../../../store/history'
import * as Style from './mobile_order_style';
import moment from 'moment';
import MetaTag from '../../meta_tag/meta_tag'
import {OTC, BANK, MOBILE, CARD_PAYMENT} from '../../constants/constants'
import Pagination from '@material-ui/lab/Pagination';
import crypto from 'crypto';
import FilterDrawer from './filterDrawer';
import OptionModal from './modalOption';
import CancelModal from './modalCancel';
import SettingIcon from '../../../static/images/table_setting_icon.svg';
import TableSettingsModal from './table_settings_modal';
import GroupButton from '../../custom_components/groupButton';
import { BulbIcon } from '../../../static/new_segment/tutorials/pages_tutorials';

const MobileOrders = props => {

  useEffect(() => {
    let current_path = window.location.pathname
      if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
        history.push('/messenger?next='+current_path)
      }
      else{
        if(!isMobile){
          history.push('/messenger/error')
        }
        else{
          dispatch(getOrders(generateToken(), orderType,1, pageSize, "","",testMode,"Today","",getAllKeys(),"All"))
        }
      }
      
  }, [])

  // delete MLhuillier for individual
  if(localStorage.getItem('userType') === 'ME') {
    delete OTC['MLhuillier'];
  }

  const OTC_LIST = Object.keys(OTC)
  const BANK_LIST = Object.keys(BANK)
  const MOBILE_LIST = Object.keys(MOBILE)
  const CARD_LIST = Object.keys(CARD_PAYMENT)

  const dispatch = useDispatch();

  const orders  = useSelector(state => state.orders.orders)
  const summary  = useSelector(state => state.orders.summary)

  const userType = localStorage.getItem('userType');
  const ubp_enabled = localStorage.getItem('cc_enabled') == 'UnionBank';
  const xendit_enabled = localStorage.getItem('cc_enabled') == 'Xendit';
  const CCEnabled = ubp_enabled || xendit_enabled;
  const testMode = (history.location.pathname.indexOf('/test') > -1 && userType == "CO")
  const segments = localStorage.getItem('segments');

  const ordersType = ["Paid","Pending","Cancelled","Expired"]

  const [orderType, setOrderType] = React.useState("Paid")
  const [drawerTitle, setDrawerTitle] = React.useState("")
  const [dayFilter, setDayFilter] = React.useState("Today")
  const [startMoment, setStartMoment] = React.useState("")
  const [endMoment, setEndMoment] = React.useState("")
  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')
  const [searchValue, setSearchValue] = React.useState("")
  const [endOpen, setEndOpen] = React.useState("")

  const [otcCheckedList, setOtcCheckedList] = React.useState(OTC_LIST)
  const [webCheckedList, setWebCheckedList] = React.useState(BANK_LIST)
  const [walletCheckedList, setWalletCheckedList] = React.useState(MOBILE_LIST)
  const [cardCheckedList, setCardCheckedList] = React.useState(CARD_LIST)

  const [checkAllOTC, setCheckAllOTC] = React.useState(true)
  const [checkAllWeb, setCheckAllWeb] = React.useState(true)
  const [checkAllWallet, setCheckAllWallet] = React.useState(true)
  const [checkAllCard, setCheckAllCard] = React.useState(true)

  // const [ccSelected, setCCSelected] = React.useState(true)
  const [bnplSelected, setBNPLSelected] = React.useState(true)

  const [methodFiltered, setMethodFiltered] = React.useState(false) 
  const [dateFiltered, setDateFiltered] = React.useState(false) 
  const [cancelModalVisibility, setCancelModalVisibility] = React.useState(false)
  const [modalVisibility, setModalVisibility] = React.useState(false)
  const [orderDetails, setOrderDetails] = React.useState(false)
  const [hideClear, setHideClear] = React.useState(true)
  
  const [visible, setVisible] = React.useState(false);

  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(10)
  const [showTableSettingModal, setShowTableSettingModal] = React.useState(false);
  const [checkedColumns, setCheckedColumns] = React.useState([]);

  // tutorial
  const [infoActiveHover,setInfoActiveHover] = React.useState(false);

  const submitApplyFilter = () => {
    setHideClear(false)
    dispatch(getOrders(generateToken(), orderType,1,pageSize,startDate,endDate,testMode,dayFilter,searchValue,getAllKeys(),"All"))
    setVisible(false)
  }

  const clearFilter = () => {
    setCurrentPage(1)
    setOtcCheckedList(OTC_LIST)
    setWebCheckedList(BANK_LIST)
    setWalletCheckedList(MOBILE_LIST)
    setCheckAllCard(CARD_LIST)
    setCheckAllOTC(true)
    setCheckAllWeb(true)
    setCheckAllWallet(true)
    // setCCSelected(true)
    setBNPLSelected(true)
    setStartDate("")
    setStartMoment("")
    setEndDate("")
    setEndMoment("")
    setSearchValue("")
    setDayFilter("Today")
    setDateFiltered(false)
    setMethodFiltered(false)
    setHideClear(true)
    dispatch(getOrders(generateToken(), orderType,1,pageSize,"","",testMode,"Today","",getAllKeys([], [], []),"All"))

  }

  const showModal = (item) => {
    console.log(item)
    setModalVisibility(true)
    setOrderDetails(item)
  }

  const showDrawer = (e) => {
    if(e == 'payment') {
      setDrawerTitle('Payment Method')
    }

    else if (e == 'date') {
      setDrawerTitle('Date Filter')
    }

    else if (e == 'search') {
      setDrawerTitle('Search Payment Request')
    }

    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const onCheckAllOtcChange = e => {
    let new_list = e.target.checked ? OTC_LIST : []
    setOtcCheckedList(new_list)
    setCheckAllOTC(e.target.checked)
    setMethodFiltered(e.target.checked)
    setHideClear(false)
  };

  const onChangeOtc = list => {
    setOtcCheckedList(list)
    setCheckAllOTC(list.length == OTC_LIST.length)
    setMethodFiltered(true)
    setHideClear(false)
  };

  const onCheckAllWebChange = e => {
    let new_list = e.target.checked ? BANK_LIST : []
    setWebCheckedList(new_list)
    setCheckAllWeb(e.target.checked)
    setMethodFiltered(e.target.checked)
    setHideClear(false)
  }

  const onChangeWeb = list => {
    setWebCheckedList(list)
    setCheckAllWeb(list.length == BANK_LIST.length)
    setMethodFiltered(true)
    setHideClear(false)
  }

  const onCheckAllWalletChange = e => {
    let new_list = e.target.checked ? MOBILE_LIST : []
    setWalletCheckedList(new_list)
    setCheckAllWallet(e.target.checked)
    setMethodFiltered(e.target.checked)
    setHideClear(false)
  }

  const onChangeWallet = list => {
    setWalletCheckedList(list)
    setCheckAllWallet(list.length == MOBILE_LIST.length)
    setMethodFiltered(true)
    setHideClear(false)
  }

  const onCheckAllCardChange = e => {
    let new_list = e.target.checked ? CARD_LIST : []
    setCardCheckedList(new_list)
    setCheckAllCard(e.target.checked)
    setMethodFiltered(e.target.checked)
    setHideClear(false)
  }

  const onChangeCard = list => {
    setCardCheckedList(list)
    setCheckAllCard(list.length == CARD_LIST.length)
    setMethodFiltered(true)
    setHideClear(false)
  }

  const generateToken = () => {
    let generatedRequestToken = crypto.randomBytes(16).toString('hex');
    return generatedRequestToken
  }

  const disabledStartDate = startValue => {
      
    if (!startValue || !endMoment) {
      return false;
    }
    return startValue && startValue > moment().endOf('day');
  };

  const disabledEndDate = endValue => {
    if (!endValue || !startMoment) {
        return false;
    }
    return endValue.valueOf() <= startMoment.valueOf();
  };

  const onStartChange = value => {
    if(value != null && endDate != ""){
        setStartMoment(value)
        setStartDate(value.format("MM-DD-YYYY"))
        setHideClear(false)
        setDateFiltered(true)
        setDayFilter("Custom")
    }

    else if(value != null){
        setStartMoment(value)
        setStartDate(value.format("MM-DD-YYYY"))
        setDateFiltered(true)
        setDayFilter("Custom")
    }

    else{
        setStartMoment(null)
        setStartDate("")
        setHideClear(false)
        setDateFiltered(true)
        setDayFilter("Custom")
    }
  }

  const onEndChange = value => {
    if(value != null && startDate != ""){
        setEndMoment(value)
        setEndDate(value.format("MM-DD-YYYY"))
        setDateFiltered(true)
        setHideClear(false)

    }
    else{
        setEndMoment(null)
        setEndDate("")
        setDateFiltered(true)
        setHideClear(false)
    }
  }

  const handleStartOpenChange = open => {
    if (!open) {
        setEndOpen(true)
    }
  };

  const handleEndOpenChange = open => {
    setEndOpen(open)
  };

  const changePage = (event,page) => {
    setCurrentPage(page)
    dispatch(getOrders(generateToken(), orderType,page,pageSize,startDate,endDate,testMode,dayFilter,searchValue,getAllKeys(),"All"))
  }

  const getAllKeys = () => {
    let all_otc = []
    let all_web = []
    let all_wallet = []
    let all_card = []

    for(let i=0; i < otcCheckedList.length; i++){
      let name = otcCheckedList[i]
      all_otc.push(OTC[name].code)
    }
    for(let i=0; i < webCheckedList.length; i++){
      let name = webCheckedList[i]
      all_web.push(BANK[name].code)
    }
    for(let i=0; i < walletCheckedList.length; i++){
      let name = walletCheckedList[i]
      all_wallet.push(MOBILE[name].code)
    }

    for(let i=0; i< cardCheckedList.length; i++){
      let name = cardCheckedList[i]
      all_card.push(CARD_PAYMENT[name].code)
    }
    let otc_w_web = all_otc.concat(all_web)
    let c_wallet = otc_w_web.concat(all_card)
    let all_channels = c_wallet.concat(all_wallet)

    // if(ccSelected){
    //   all_channels.push('visamc')
    // }
    if(bnplSelected){
      all_channels.push("billease")
    }
    if(all_otc.includes('ussc')){
      all_channels.push('bc_ussc')
      all_channels.push('USSC')
    }
    if(all_otc.includes('cvm')){
        all_channels.push('CVM')
    }
    if(all_otc.includes('petnet')){
        all_channels.push('PRHB')
    }
    if(all_otc.includes('BAYD')){
      all_channels.push('bayad_center')
    }
    if(all_otc.includes('RLNT')){
      all_channels.push('bc_rlnt')
    }
    if(checkAllOTC && checkAllWeb && checkAllWallet && checkAllCard && bnplSelected){
      all_channels.push("All")
    }
    return all_channels
  }

  const summaries = [
    {
      text: `No. of ${orderType} Transaction:`,
      value: summary ? summary.total_data : 0
    },
    {
      text: 'Total Amount:',
      value: summary ? <span>&#8369; {parseFloat(summary.total).toFixed(2)}</span> : <span>&#8369; 0.00</span>,
    },
    {
      text: 'Receivable Amount:',
      value: summary ? <span>&#8369; {parseFloat(summary.receive).toFixed(2)}</span> : <span>&#8369; 0.00</span>,
    }
  ]

  const onChangeTableSettings = (e) => {
    var checkedCol = checkedColumns;
    const dateFilter = ['expire_at', 'cancelled_at', 'date_paid']
    if(e.target.checked){
      checkedCol = checkedCol.filter(id => {return id !== e.target.id})
    
    }
    else if(!e.target.checked){
      if(e.target.id === 'date') {
        dateFilter.map((d) => checkedCol.push(d));
      } else {
        checkedCol.push(e.target.id)
      }
    }

    if(userType === "SW") {
      checkedCol.filter(id => {return id !== ("param1" || "param2")})
    }

    setCheckedColumns(checkedCol);
  }

  const ifObject = (param) => {
    if(typeof param === 'object'){
      return true;
    }
    return false;
  }

  const ifNotNull = (param) => {
    if(param != null && param != undefined && param != "" && param != {} && param != []){
        return true;
    }
    return false;
  }

  const hideIfNull = (param) => {
    if(typeof param === 'object') {
      if(ifNotNull(param.name) === true && ifNotNull(param.value) === true) {
        return false;
      } else return true;
    } else {
      if(ifNotNull(param) === true) {
        return false;
      } else return true;
    }
  }
  
  return (
    <div style={{...Style.mainMobileOrders, height: orders != null && orders.length == 0 ? '100vh' : '100%', paddingTop: userType === 'CO' && CCEnabled ? '52px' : '20px'}}>
      <MetaTag title="Payment Request"/>

      <CancelModal visible={cancelModalVisibility} setCancelModalVisibility={setCancelModalVisibility} orderDetails={orderDetails}
        orderType={orderType} />

      <OptionModal visible={modalVisibility} setModalVisibility={setModalVisibility} orderDetails={orderDetails}
        orderType={orderType} setCancelModalVisibility={setCancelModalVisibility} />

      <FilterDrawer 
        title={drawerTitle} visible={visible} onClose={onClose}
        checkAllOTC={checkAllOTC} onCheckAllOtcChange={onCheckAllOtcChange} OTC_LIST={OTC_LIST} otcCheckedList={otcCheckedList} onChangeOtc={onChangeOtc}
        checkAllWeb={checkAllWeb} onCheckAllWebChange={onCheckAllWebChange} BANK_LIST={BANK_LIST} webCheckedList={webCheckedList} onChangeWeb={onChangeWeb}
        checkAllWallet={checkAllWallet} onCheckAllWalletChange={onCheckAllWalletChange} MOBILE_LIST={MOBILE_LIST} walletCheckedList={walletCheckedList} onChangeWallet={onChangeWallet}
        checkAllCard={checkAllCard} onCheckAllCardChange={onCheckAllCardChange} CARD_LIST={CARD_LIST} cardCheckedList={cardCheckedList} onChangeCard={onChangeCard}
        // setCCSelected={setCCSelected}
        setBNPLSelected={setBNPLSelected}
        submitApplyFilter={submitApplyFilter}
        dayFilter={dayFilter} setDayFilter={setDayFilter} setDateFiltered={setDateFiltered} endOpen={endOpen}
        disabledStartDate={disabledStartDate} startMoment={startMoment} onStartChange={onStartChange} handleStartOpenChange={handleStartOpenChange}
        disabledEndDate={disabledEndDate} endMoment={endMoment} onEndChange={onEndChange} handleEndOpenChange={handleEndOpenChange}
        searchValue={searchValue} setSearchValue={setSearchValue} 
        segments={segments}/>
      
      <div style={{padding: '25px 16px 0px 16px'}}>
        <div style={{display: 'inline-flex', alignItems: 'center', gap: '16px'}}>
          <div style={{...Style.headerStyle}}>Payment Request</div>
          <Button style={{...Style.infoBtn}} shape={!infoActiveHover && "circle"}
              onMouseOver={() => setInfoActiveHover(true)} onMouseLeave={() => setInfoActiveHover(false)}
              onClick={() => history.push('/payment_request/mobile/how_to_use')}>
              <img src={BulbIcon} alt="Info" />
              {infoActiveHover && "What is this?"}
          </Button>
        </div>
        <GroupButton status={ordersType} selected={orderType} segments={segments} handleClick={(e) => 
            {
                setOrderType(e.target.value);
                dispatch(getOrders(generateToken(), e.target.value, 1, pageSize, "", "", testMode, dayFilter, "", getAllKeys(), "All"))
            }} />
      </div>
      <div style={{display: 'flex', justifyContent:'space-between', padding: '21px 16px 21px 16px'}}>
          <button className={`btn--${segments} btn-height`} style={Style.dayPill} onClick={()=>showDrawer()}>
            {hideClear ? 'Today' : <span> <Icon type="close-circle"  className={`right-4`} theme="filled"/>Clear Filter </span> }
          </button>
          <button className={`btn--${segments} btn-height`} style={Style.filterBtn} onClick={()=>showDrawer()}>
            <Icon type="filter" className={`right-4`} theme="filled" style={{color: '#fff'}}/> Filter
          </button>
      </div>
      <Card bodyStyle={{padding: '19px 16px 19px 16px'}}>
        {
          summaries.map((item, i) => {
            return(
              <div style={{...Style.twoCol, marginBottom: i != 2 && '12px'}}>
                <div style={Style.textStyle1}>
                  {item.text}
                </div>

                <div style={Style.textStyle1}>
                  <b>{item.value}</b>
                </div>
              </div>
            )
          })
        }
      </Card>
      <div style={Style.transactionText}>
          Transactions
      </div>
      <div>
        {
          orders != null && orders.length != 0 ? 
            orders.length > 0 && orders.map((item, i) => {
              return (
                <div
                  style={Style.orderCard}>
                  <Card 
                    hoverable 
                    style={Style.orderInsideDiv}
                    headStyle={{padding: '0 16px', borderBottom: '2px solid #e8e8e8'}}
                    title={
                      <div>
                        <div style={{display:'flex'}}>
                          <div>
                            <div style={Style.codeStyle}>
                            Reference code
                            </div>
                            <div style={Style.textStyle}>
                            {item.code}
                            </div>
                          </div>
                          <Icon 
                            hidden={orderType == "Cancelled" || orderType == "Expired"}
                            type="ellipsis" 
                            style={{fontSize: '24px', position:'absolute', right: 16}} 
                            onClick={()=> showModal(item)}/>
                        </div>
                      </div>
                    }bodyStyle={{padding: 0}}>
                    <div>
                      <div style={{padding: '12px 16px 0 16px'}} hidden={(checkedColumns.includes('param1') && checkedColumns.includes('param2')) || (hideIfNull(item.payload.param1) && hideIfNull(item.payload.param2))} >
                        <div hidden={checkedColumns.includes('param1') || hideIfNull(item.payload.param1)}  >
                         { item && item.payload && item.payload.param1 && ifObject(item.payload.param1)? 
                          <div style={{...Style.twoCol, margin: 0, paddingBottom: '12px', width: '100%'}}>
                            <div style={Style.textStyle}>
                              {item && item.payload && item.payload.param1 && item.payload.param1.name && !ifObject(item.payload.param1.name) && item.payload.param1.name}
                            </div>
                            <div style={Style.codeStyle}>
                              {item && item.payload && item.payload.param1 && item.payload.param1.value && !ifObject(item.payload.param1.value) && item.payload.param1.value}
                            </div>
                          </div> :
                          <div style={{...Style.codeStyle, justifyContent: 'flex-end', paddingBottom: '12px', width: '100%'}}>
                            {item && item.payload && item.payload.param1 && item.payload.param1}
                          </div>
                            }
                        </div>
                        <div hidden={checkedColumns.includes('param2') || hideIfNull(item.payload.param2)}  >
                         { item && item.payload && item.payload.param2 && ifObject(item.payload.param2)? 
                         <div style={{...Style.twoCol, margin: 0, paddingBottom: '12px', width: '100%'}}>
                            <div style={Style.textStyle}>
                              {item && item.payload && item.payload.param2 && item.payload.param2.name && !ifObject(item.payload.param2.name) && item.payload.param2.name}
                            </div>
                            <div style={Style.codeStyle}>
                              {item && item.payload && item.payload.param2 && item.payload.param2.value && !ifObject(item.payload.param2.value) && item.payload.param2.value}
                            </div>
                          </div> :
                          <div style={{...Style.codeStyle, justifyContent: 'flex-end', paddingBottom: '12px', width: '100%'}}>
                            {item && item.payload && item.payload.param2 && !ifObject(item.payload.param2) && item.payload.param2}
                          </div>
                            }
                        </div>
                      </div>
                      <Divider style={{margin: 0}} hidden={(checkedColumns.includes('param1') && checkedColumns.includes('param2')) || (hideIfNull(item.payload.param1) && hideIfNull(item.payload.param2))} />
                      <div style={{padding: '12px 16px 0 16px'}}>
                        {
                          item.code != null && 
                          <div style={{...Style.twoCol, margin: 0, paddingBottom: '12px', alignItems: 'center'}}>
                            <div style={Style.newLabelText}>
                              Channel:
                            </div>
                            <div style={{...Style.newValueText, display: 'flex'}}>
                              {item.channel_str}
                              <Typography hidden={item.channel_str === item.terminal}
                                style={{maxWidth: 164, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', color: '#90919', paddingLeft: 4}}
                              >{item.terminal}</Typography>
                            </div>
                            
                          </div>
                        }
                        <div style={{...Style.twoCol, margin: 0, paddingBottom: '12px', alignItems: 'center'}}>
                          <div style={Style.newLabelText}>
                            Date Generated:
                          </div>
                          <div style={Style.newValueText}>
                            {moment(item.timestamp).format('MMMM DD YYYY h:mm A')}
                          </div>
                        </div>
                        <div style={{...Style.twoCol, margin: 0, paddingBottom: '12px', alignItems: 'center'}}>
                          <div style={Style.newLabelText}>
                            Amount:
                          </div>
                          <div style={Style.newValueText}>
                            PHP {item.amount}
                          </div>
                        </div>

                        <div style={{...Style.twoCol, margin: 0, paddingBottom: '12px', alignItems: 'center'}} hidden={orderType != "Paid"}>
                          <div style={Style.newLabelText}>
                            Receivable:
                          </div>
                          <div style={Style.newValueText} >
                            PHP {item.seller_net}.00
                          </div>
                        </div>
                      </div>
                      <div style={{display: 'flex', justifyContent:'center', padding: '32px 0px 24px 0px'}}>
                        <a href={`/order_details/${item.uid}/`}>
                          <div style={Style.seeMore} className={`text-${segments}`}>
                            See more details
                          </div>
                        </a>
                      </div>
                    </div>
                  </Card>
                </div>
              )
            },this)
          :
          <div style={Style.nothingToShow} align="center">
              Nothing to show
          </div>
        }
      </div>

      <div style={{display: 'flex', justifyContent: 'center'}} hidden={summary && summary.total_data / 10 < 1}>
        <Pagination 
          count={summary && summary.pages} 
          shape="rounded"
          page={currentPage} 
          size="small"
          onChange={changePage}
        />
      </div>
      <TableSettingsModal visible={showTableSettingModal} onClose={() => setShowTableSettingModal(false)} columnsChecked={checkedColumns} onChangeColumns={(e) => onChangeTableSettings(e)}  />
    </div>
  )
}

export default MobileOrders