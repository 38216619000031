import React, {
    useEffect,
    useState
} from 'react';
import { message } from 'antd';
import { history } from '../../../store/history'
import MetaTag from '../../meta_tag/meta_tag';
import qrServices from './qr_services/qrServices';
import PageNotFound from '../../404/404'
import QRDynamicDetails from './qr_dynamic_details'

const testMode = (history.location.pathname.indexOf('/test') > -1);

const QrDetails = props => {

    //props
    const isSubDomain = process.env.REACT_APP_ROOT_URL != window.location.hostname;

    let tradeName = props.match.params.trade_name;
    let isSub = false;

    if (isSubDomain && tradeName) {
        isSub = true;
    }

    if (isSubDomain && !tradeName) {
        let remove_part = `.${process.env.REACT_APP_SUB_URL}`;
        tradeName = window.location.hostname.replace(remove_part, "");
    }
    let remove_part = `.${process.env.REACT_APP_SUB_URL}`;
    const master_trade_name = isSubDomain && isSub ? window.location.hostname.replace(remove_part, "") : '';

    //state data
    const [qrStaticDetails, setQRStaticDetails] = useState();

    //state boolean
    const [pageNotFound, setPageNotFound] = useState(false);
    const [qrPage, setQRPage] = useState(false);

    //async functions
    const getQRStaticDetails = async () => {
        try {
            const response = await qrServices.getQrDetails(testMode, tradeName, master_trade_name)

            if (response.data.status === 'failed') {
                message.destroy();
                setPageNotFound(true);
            } else {
                if (response.data.qr_enabled) {
                    if (response.data.user_role == 'ME' && testMode) {
                        setPageNotFound(true);
                    } else {
                        setQRPage(true);
                        setQRStaticDetails(response.data);
                    }
                } else {
                    setPageNotFound(true);
                }
            }
        } catch (error) {
            // render something went wrong
        }
    }

    useEffect(() => {
        getQRStaticDetails();
    }, []);

    return (
        <div>
            <div hidden={!pageNotFound}>
                <PageNotFound />
            </div>
            <div className="qr-main-screen" align="center" hidden={!qrPage}>
                {tradeName ?
                    <MetaTag title={tradeName + "'s Checkout"} />
                    :
                    <MetaTag title="Not Found" />
                }

                <QRDynamicDetails qrStaticDetails={qrStaticDetails} tradeName={tradeName} />
            </div>
        </div>
    );
}

export default QrDetails;
