import React from 'react';
import HowItWorksMain from '../../components/how_it_works/how_it_works_main';
import { isMobile } from 'react-device-detect';
import MobileMain from '../../layouts/Mobile/MobileMain'
import Main from '../../layouts/Main'

const HowItWorks = props => {

    return(
        <div>
            {
                isMobile ?
                <MobileMain title={"How to use BUx"}>
                    <HowItWorksMain/>
                </MobileMain>
                :
                <Main>
                    <HowItWorksMain />
                </Main>
            }

        </div>
    )
}

export default HowItWorks;