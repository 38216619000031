import React from 'react'
import { Modal } from 'antd'
import accountStyles from '../../static/css/Account'
import * as Icon from '../../static/icons/bux_qr/index'

const CustomInfoModal = props => {
    const segments = localStorage.getItem('segments');
    const ac = accountStyles();

    const {
        showModal, setShowModal, setCustomModal, customImg
    } = props

    function showCustomModal() {
        setCustomModal(true)
        setShowModal(false)
    }

    return (
        <Modal visible={showModal}
        onCancel={setShowModal}
        footer={null}>
            <div align="center">
                <h1 style={{marginTop: '32px'}}>Custom Amount List</h1>
                <div>
                    <img src={Icon.CustomAmount} alt="Custom Amount" />
                </div>

                <div className={ac.customModalText}>
                    By Creating a customize Amount list to your Checkout page, 
                    Your customer can easily choose from your desired requested amount without typing and worrying the right amount.
                </div>

                <div style={{marginTop: 16}}>
                    <button style={styles.editBtn} className={`btn--${segments}`} onClick={() => showCustomModal()}>
                        + Create Custom List
                    </button>

                    <div style={{marginTop: 8}}>
                        <a className={`link-text--${segments}`} style={styles.link} onClick={setShowModal}>Not Now</a>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

const styles = {
    link: {
        fontSize: 16,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0',
        maxWidth: 328,
        textDecoration: 'none',
        fontWeight: '500',
        cursor: 'pointer'
    },
    editBtn: {
        cursor: 'pointer',
        height: 34,
        padding: '0 12px',
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        width: 174,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
}

export default CustomInfoModal;

