import { getDashboard } from "./dashboardServices";

export const DashboardActionTypes = {

    DASHBOARD_REQUEST: "@DASHBOARD_REQUEST",
    DASHBOARD_SUCCESS: "@DASHBOARD_SUCCESS",
    DASHBOARD_FAILED: "@DASHBOARD_FAILED",
    DASHBOARD_GRAPH_DATA_REQUEST: "GET_DASHBOARD_GRAPH_DATA",
};

export function getDashboardNow() {
    return async(dispatch) => {
        try {
            dispatch({type: DashboardActionTypes.DASHBOARD_REQUEST});
            let response = await getDashboard();
            // console.log(response)

            let res = {
                "week": response.week, 
                "data": {
                    pending: response.pending,
                    paid: response.paid,
                    expired: response.expired,
                },
                "cashin_limits": response.cashin_limits,
                "cashout_limits": response.cashout_limits,
                "bux_balance": response.bux_balance,
                "card": response.card,
            }
            // console.log(res)

            dispatch({
                type: DashboardActionTypes.DASHBOARD_SUCCESS,
                payload: res
            });
        }

        catch (error){
            console.log(error)
            dispatch({
                type: DashboardActionTypes.DASHBOARD_FAILED
            });

            console.log(error.message)
        }
    }     
}

export const dashboardActions = {
    setData: (data) => ({
      type: DashboardActionTypes.DASHBOARD_GRAPH_DATA_REQUEST,
      payload: data
    }),
  }