export const titleStyle = {    
    fontStyle: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    fontWeight: '600',
    fill: 'Solid',
    color: '#000',
    marginBottom: '20px',
}

export const headerStyle = {
  fontWeight: 'bold',
  fontSize: '18px',
  lineHeight: '20px',
  color: '#2b2d32',
}

export const infoBtn = {
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
  height: '32px',
  fontSize: '12px',
  fontWeight: '400',
  textDecoration: 'underline',
  gap: '12px',
  color: '#E68932',
  backgroundColor: '#FFF',
  borderRadius: '50px'
}

export const textStyle = {
  
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '16px',
  color: '#2B2D32'
}

export const newLabelText = {
  
  fontStyle: 'normal',
  fontWeight: '300',
  fontSize: '14px',
  lineHeight: '16px',
  color: '#2B2D32'
}

export const newValueText = {
  
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '16px',
  color: '#2B2D32'
}
  
export const codeStyle = {    
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'bold',
    color: '#2b2d33',
}

export const amountStyle = {    
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'normal',
    color: '#000',
    position: 'absolute',
    right: '0',
    marginRight: '20px'
}

export const descriptionStyle = {    
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 'normal',
    color: '#909196',
    marginBottom: '12px',
    width: '250px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}

export const expireStyle = {    
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 'normal',
    color: '#2B2D33',
}
export const completeOrderStyle = {    
  fontStyle: 'normal',
  fontSize: '12px',
  lineHeight: '24px',
  fontWeight: 'normal',
  color: '#1D82B8',
}
export const expiresInOrderStyle = {    
  fontStyle: 'normal',
  fontSize: '12px',
  lineHeight: '24px',
  fontWeight: 'normal',
  color: '#E24C4C',
}
export const refundedStyle = {
  fontStyle: 'normal',
  fontSize: '10px',
  lineHeight: '24px',
  fontWeight: 'normal',
  color: '#FFFFFF',
}
export const buttonStyle = {
  fontStyle: 'normal',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 'normal', 
  
}

export const cancelOrder = {

  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '28px',
  lineHeight: '34px',
  color: '#2b2d33',
  marginBottom: '10px'
}

export const choicesStyle = {
  
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '24px',
  color: '#2b2d33',
  opacity: '0.8',
  marginBottom: '10px'
}

export const cancelButtonStyle = {
  borderRadius: '4px',
  width: '100%',
  height: '40px',
  
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '24px',
  color: '#FFFFFF',
  marginBottom: '10px',
}

export const radioStyle = {
  display: 'block',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '24px',
  color: '#2b2d33',
  opacity: '0.8',
  marginBottom: '10px'
};

export const dontCancelButton = {
  borderRadius: '4px',
  width: '100%',
  height: '40px',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '24px',
  color: '#2b2d33'
}

export const labelStyle = {
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '24px',
  color: '#2B2D33'
}

export const emptyChannelStyle = {
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '16px',
  color: '#909196',
  whiteSpace:'pre-line'
}
export const bankStyle = {
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '24px',
  color: '#2B2D33'
}


export const applyBtn = {
  color: '#fff',
  backgroundColor: '#F5922F',
  height: '40px',
  border: '1px solid #F5922F',
  borderRadius: '4px',
  fontWeight: '600',
  fontSize: '16px'
}

export const mainMobileOrders = {
  backgroundColor: '#F4F6F9', 
  paddingBottom: 64,
}

export const mobileBtnGrp = {
  width: '100%', 
  marginBottom: '20px',
  padding: '0px 16px 0px 16px'
}

export const mobileBtn = {
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '12px',
  lineHeight:'16px',
  height:'40px',
}

export const cardStyle = {
  margin: 16
}

export const twoCol = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '12px',
  alignItems: 'center'
}

export const dayPill = {
  background: '#E6EAF0',
  border: '1px solid #e6eaf0',
  borderRadius: '24px',
  color: '#2B2D33',
  fontWeight: '600',
  fontSize: '12px',
  height: 30,
  minWidth: 100,
  textAlign: 'center',
}

export const textStyle1 = {
  color: "#2B2D33",
  fontSize: 14,
  lineHeight: '16px',

}

export const valuestyle = {
  color: "#0D3D76",
  fontSize: 14,
  lineHeight: '16px',
  fontWeight: 'bold'
}

export const transactionText = {
  padding: '16px 0px 0px 16px',
  color: '#909196',
  fontSize: 14,
  lineHeight: '16px',
  fontWeight: 'bold',
}

export const nothingToShow = {
  color: '#D1D5DD',
  marginTop: 16,
  fontSize: 14,
  lineHeight: '16px',
  fontWeight: 'bold',
  width: '100%',
  height: '217px',
  border: '1px solid #e7eaf0',
  borderShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
  boxSizing: 'border-box',
  borderRadius: '4px',
  background: '#fff',
  padding: 100,
}

export const orderCard = {
  padding: '10px 0px 10px 0px',
  backgroundColor: '#f4f6f9'
}

export const orderInsideDiv = {
  backgroundColor: '#fff',
  borderRadius: '4px'
}


export const panelHeader = {
  // borderBottom: '1px solid #E6EAF0',
  padding: '16px 32px',
}

export const mobileApplyBtn = {
  margin: 16,
  color: '#fff',
  width: '90%',
  height: '40px',
  borderRadius: '4px',
}

export const linkStyle = {
  color: '#000000', 
  position: 'relative'
}

export const badgePos = {
  position: 'absolute', 
  top: '-10px', 
  right: '-10px'
}

export const checkBoxStyle = {
  padding: '16px 32px',
}

export const fixedBtn = { 
  width: '100%',
  position: 'fixed',
  bottom: 0,
  padding: 16,
  background: '#fff'
}

export const none = {
  
}

export const filterBtn = {
  height: '32px',
  width: '90px',
  borderRadius: '4px'
}

export const paymentMethodBtn = {
  width: '100%',
  height: '40px',
  borderRadius: '4px',
  border: '1px solid #B7B9C0',
}

export const seeMore = {
  fontWeight: 'bold',
  fontSize: '16px',
  lineHeight: '24px',
}