import { Typography } from 'antd';
import React from 'react';
import Bux from '../../../static/icons/logo.svg';
import { SecuredLock } from '../../../static/images/test_mode';

const Footer = () => {
    return (
        <div style={styles.root}>
            <div style={styles.lock}>
                <img alt='No Image' src={SecuredLock} />
                <div style={styles.text}>
                    <Typography style={{color: '#1DD28B'}}>secured</Typography>
                    <Typography style={{color: '#909196'}}>payments by</Typography>
                </div>
            </div>
            <img alt='No Image' src={Bux} style={styles.bux} />
        </div>
    );
}

const styles = {
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px 0 32px 0'
    },
    lock: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 4
    },
    text: {
        fontSize: 12,
        fontWeight: '600',
        lineHeight: 1
    },
    bux: {
        padding: 4,
        width: 76
    }
}

export default Footer;