import React,{} from 'react';
import {
  GCashHero,
  GCashButton,
  GCashCheckoutSC
} from '../../../static/images/notif_promotion_page';
import { Typography } from 'antd';
import { isMobile } from 'react-device-detect';

const MainContent = () => {
  return (
    <div style={styles.root}>
      <div style={styles.img}><img src={GCashHero} style={{width: '100%'}} /></div>
      <div style={{padding: '0 20px'}}>
        <Typography style={styles.title}>GCash is now Live</Typography>
        <Typography style={styles.regText}>
          You now have the option to accept payments thru 
          GCash - a new payment method available now in Bux!
        </Typography>
        <div style={styles.regText}>
          Choose
          <img src={GCashButton} />
          on Checkout!
        </div>
        <div align='center' style={{...styles.img, padding: '6px 0 10px 0'}}><img src={GCashCheckoutSC} style={{width: '100%'}} /></div>
        <Typography style={styles.regText}>Please note that there is a transaction fee of 2% + Php 10.</Typography>
        <Typography style={styles.footer}>
          Make sure to like <a style={styles.link} href='https://www.facebook.com/buxph' target="_blank">Bux’s Facebook page</a> to be in the loop on the latest news and updates on Bux.
        </Typography>
      </div>
    </div>
  )
}

const styles= {
  root: {
    backgroundColor: '#FFFFFF',
    width: isMobile? '100%' : 540,
  },
  title: {
    fontSize: 20,
    color: '#000',
    fontWeight: 'bold',
    padding:'26px 0 0 0'
  },
  regText: {
    fontSize: 14,
    color: '#54575F',
    padding:' 10px 0'
  },
  footer: {
    fontSize: 14,
    color: '#000',
    padding: '22px 0'
  },
  link: {
    color: '#F5922F',
    textDecoration: 'underline',
    fontWeight: '600',
  },
  img: {
    width: '100%',
  }
}

export default MainContent;