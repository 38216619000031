import React from 'react'
import BusinessDocument from './mobile_components/business_documents'
import BusinessInformation from './mobile_components/business_information'
import SignatoryInformation from './mobile_components/signatory_information'
import * as Style from '../../complete_profile/complete_profile_style'
import UBCard from '../../cards/mobile/ubexpress'
import axios from 'axios'
import { render } from 'react-dom'

export function goToStep(value){
    this.setState({step: value})
}


class MobileMainEnterprise extends React.Component{

    constructor(props) {
        super(props)
    
        this.state = {
          step: 1
        }

        goToStep = goToStep.bind(this)

    }
        
    render(){
        
        return(
            <div style={{padding: '20px'}}>
                <UBCard/>

                <div style={{marginBottom: '20px', marginTop: '20px'}}>
                    <span style={Style.StepStyle}>Step {this.state.step} of 3</span>
                </div>

                {
                    this.state.step == 1 ?
                    <BusinessInformation />
                    : this.state.step == 2 ?
                    <BusinessDocument/>
                    : this.state.step == 3 ?
                    <SignatoryInformation/> : ""
                }

            </div>
        )
    }
    

}

export default MobileMainEnterprise