import { makeStyles } from '@material-ui/core'

const user = localStorage.getItem("userType") == "CO";

const accountStyle = makeStyles(theme => ({
    
    mainBody: {
        backgroundColor: '#f5f5f4',
        fontFamily: 'Inter',
        paddingBottom :'50px',  
        [theme.breakpoints.down("sm")]: {
            padding: '0px 0px 50px 0px',
            // overflow: 'hidden',
            height: '100vh',
        }
    },

    header: {
        width: user ? '90%' : '650px',
        fontWeight: 'bold',
        fontSize: '25px',
        lineHeight: '32px',
        color: '#000000',
        [theme.breakpoints.down("sm")]: {
            width: '380px',

        }
    },

    bankHeader: {
        fontWeight: 'bold',
        fontSize: '25px',
        lineHeight: '32px',
        color: '#000000',
        [theme.breakpoints.down("sm")]: {
            width: '380px',
        }
    },

    menu: {
        marginTop: '-2.5%',
        background: '#fff',
        marginLeft: '-100px',
        padding: '3% 40px 0px 112px',
        [theme.breakpoints.down("sm")]: {
            padding: '0px',
            marginLeft: '16px',
            overflow: 'hidden'
        },
        [theme.breakpoints.between("lg", "lg")]: {
             marginTop: '-1%'
        }
    },

    tabBody: {
        width: '100%',
        marginTop: 42,
        display: 'flex', 
        justifyContent: 'center',
        [theme.breakpoints.down("sm")]: {
            width: '350px',

        }
    },

    btnEmail: {
        fontSize: 16,
        color: '#F5922F',
        fontWeight: 'bold',
        textAlign: 'center',
        paddingBottom: 24,
      },
    personal: {
        padding: '32px',
        marginTop: '8px',
        width: '684px',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
        backgroundColor: '#fff',
        [theme.breakpoints.down("sm")]: {
            width: '350px',
            marginTop: '20px',
            padding: 16
        }
    },

    bank: {
        padding: '32px',
        marginTop: '32px',
        width: '540px',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
        backgroundColor: '#fff',
        [theme.breakpoints.down("sm")]: {
            width: '350px',
            marginTop: '20px',
        

        }
    },

    headerProfile:{ 
        color: '#0D3D76',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '32px',
    },

    profilePicBtn: {
        background: '#F5922F',
        borderRadius: '4px',
        color: '#fff',
        fontWeight: '600',
        height: '32px',
        width: 200,
        marginTop: '-5px',
        cursor: 'pointer',
        border: '1px solid #f5922f'
    },

    profilePics:{
        borderRadius: '50%',
        width: '64px',
        height: '64px'
    
    },

    infoDiv:{
        marginTop: 32,
    },

    linkAccountBtn:{
        background: '#F5922F',
        borderRadius: '10px',
        color: '#fff',
        fontWeight: '600',
        height: '40px',
        width: '192px',
        fontSize: '16px',
        cursor: 'pointer',
        border: '1px solid #f5922f',
        [theme.breakpoints.down("sm")]: {
            fontSize: '12px',
            width: '140px',

        }
    },

    acctDiv:{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '8px',
    },

    mobilePrefix: {
        position: 'absolute',
        top: `68%`,
        left: '19%',
        transform: 'translate(-50%, -50%)',
        fontSize: 16,
        color: '#2B2D33',
        fontWeight: 'normal',
        zIndex: '2',
    },

    placeholder:{
        color: '#212B36',
        fontSize: '12px',
        lineHeight: '24px',
        marginBottom: '4px',
    },

    input: {
        border: '1px solid #D1D5DD',
        boxSizing: 'border-box',
        borderRadius: '4px',
        width: '228px',
        height: '50px',
        padding: '14px',
    }, 

    saveBtn:{
        background: '#F5922F',
        borderRadius: '10px',
        color: '#fff',
        fontWeight: '600',
        height: '48px',
        width: '228px',
        cursor: 'pointer',
        border: '1px solid #f5922f'
    },

    bankAcctDiv:{ 
        marginTop: 20,
        padding: '20px 32px 20px 32px',
        background: '#FFFFFF',
        border: '1px solid #E6EAF0',
        boxShadow:' 0px 1px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down("sm")]: {
            padding: 12
        }

    },

    alert:{ 
        borderLeft: '5px solid red',
        fontSize: '12px',
        borderRadius: '4px',
        background: 'rgba(226, 76, 76, 0.1)',
        padding: '9px 22px 9px 22px',
        marginBottom: '20px',
    },

    modalDesign:{
        width: 360,
    },

    modalTitle:{
        fontWeight: 300,
        fontSize: '24px',
        lineHeight: '32px',
        color: '#2b2d33',
    },

    modalBankCard:{
        marginTop: '16px',
        border: '2px solid #e6eaf0',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.06)',
        borderRadius: '4px',
    },

    cardHeader:{
        background: '#e6eaf0',
        color: '#2b2d33',
        padding: '10px 12px 10px 12px'
    },

    cardDetails:{
        padding: '10px 12px 20px 12px',
        color: '#2b2d33',
    },

    modalHeader:{ 
        color: '#2B2D33',
        fontWeight: 600,
        lineHeight: '24px',
        fontSize: '16px',
        marginTop: '32px',

    },

    doneBtn:{
        width: 320,
        height: '48px',
        fontWeight: '600',
        fontSize: '16px',
        color: '#fff',
        background: '#F5922F',
        borderRadius: '10px',
        border: '1px solid #f5922f',
        marginTop: '32px'
    },

    cancelBtn:{
        width: 150,
        height: '48px',
        fontWeight: '600',
        fontSize: '16px',
        color: '#000000',
        background: '#f5f5f4',
        borderRadius: '10px',
        border: '1px solid #f5f5f4',
        marginRight: '11px',
    },

    proceedBtn:{
        width: 150,
        height: '48px',
        fontWeight: '600',
        fontSize: '16px',
        color: '#fff',
        background: '#F5922F',
        borderRadius: '10px',
        border: '1px solid #f5922f',
        marginLeft: '11px',

    },

    bankStyle:{
        color: '#000000',
        fontWeight: '600',
        marginBottom: '4px',

    },

    detailStyle:{
        opacity: '0.5',
        marginBottom: '20px'
    },

    primaryStyle:{
        fontWeight: 600,
        color:'#000000',
    },

    makePrimaryStyle: {
        fontWeight: 600,
    },

    edit:{
        color: 'gray',
        opacity: '0.5',        
        fontWeight: 600,

    },

    number:{
      fontWeight: 600,  
    },

    personalMenu: {
        display: 'flex',
        justifyContent: 'space-between'
    },

    personalHeader: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
        color: '#000000',
    },

    personalAction: {
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#1d82b8'
    },

    save: {
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#ed5249',
        marginRight: '10px'
    },

    personalInfo: {
 
        marginTop: '20px',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
    },

    flexing: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E6EAF0',
        [theme.breakpoints.down("sm")]: {
            display: 'block',
            justifyContent:'',

        }

    },

    halfContainer: {
        width: '50%',
        paddingRight: 20,
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            paddingRight: 0,

        }
    },

    personalContainer: {
        border: '1px solid #E6EAF0',
    },

    piHeader: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#54575f',
        marginTop: '17px',
        paddingLeft: '17px',
        paddingBottom: '20px',
    },


    piHeader1: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#54575f',
        marginTop: '17px',
        paddingLeft: '17px',
        paddingBottom: '20px',
        [theme.breakpoints.down("sm")]: {
            borderBottom: '1px solid #E6EAF0',
        }
    },

    piAddress: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#54575f',
        marginTop: '17px',
        paddingLeft: '17px',
        paddingBottom: '20px',
    },

    piAddressText: {
        fontWeight: 'normal',
        fontSize: '16px',
        paddingLeft: '15px',
        lineHeight: '24px',
        color: '#000000',
        marginTop: '17px',
        paddingBottom: '20px',
        [theme.breakpoints.down("sm")]: {
            paddingLeft: '17px',
            paddingRight: '17px',
        }

    },


    piText: {
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000000',
        marginTop: '17px',
        paddingLeft: '15px',
        paddingBottom: '20px',
        wordWrap: 'break-word',
        [theme.breakpoints.down("sm")]: {
            paddingLeft: '17px',
            paddingRight: 17,
            paddingBottom: '20px',
            fontSize: '14px',


            
        }

    },
    piInput: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000000',
        // borderBottom: '1px solid #E6EAF0',
        marginTop: '17px',
        paddingBottom: '12px',
        [theme.breakpoints.down("sm")]: {
            textAlign: 'center',
            paddingLeft: '17px',
            paddingBottom: '20px',
        }

    },

    piInput1: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000000',
        borderBottom: '1px solid #E6EAF0',
        marginTop: '17px',
        paddingBottom: '2px',
        [theme.breakpoints.down("sm")]: {
            textAlign: 'center',
            paddingLeft: '17px',
            paddingBottom: '20px',
        }

    },
    
    pass: {
        [theme.breakpoints.down("sm")]: {
            width: '100px'
        }
    },

    password: {
        fontWeight: 600,
        paddingLeft: '15px',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#F5922F',
        marginTop: '17px',
        paddingBottom: '20px',
        [theme.breakpoints.down("sm")]: {
            paddingLeft: '17px',
            paddingBottom: '20px',
        }
    },

    personalPadding: {
        padding: '20px 100px 40px 100px',
        [theme.breakpoints.down("sm")]: {
            padding: '20px 50px 40px 50px',
            
        }
    },

    cancel: {
        marginTop: '20px',
        backgroundColor: '#e5e5e5',
        border: '1px solid #e5e5e5',
        borderRadius: '4px',
        height: '32px',
        color: '#000000'
    },

    changePassword: {
        marginTop: '20px',
        backgroundColor: '#f5922f',
        border: '1px solid #f5922f',
        borderRadius: '4px',
        height: '32px',
        color: '#fff'
    },

    businessButton: {
        marginTop: '35px',
    },

    iconStyle: {
        width: '16px',
        height: '16px',
        marginTop: '5px',

    },

    facebookStyle: {
        paddingLeft: '20px',
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        background: '#3B5998',
        borderRadius: '4px',
        border: '1px solid #3B5998',
        width: '240px',
        height: '48px',
        color: '#fff',
        marginRight: '12px',
        marginBottom: '12px',
    },

    googleStyle: {
        paddingLeft: '20px',
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        background: '#f1f1f1',
        borderRadius: '4px',
        border: '1px solid #f1f1f1',
        width: '240px',
        height: '48px',
        color: '#2B2D33'
     
    },

    changeModalHeader:{
        fontWeight: 300,
        fontSize: 24,
        lineHeight: '32px',
        color: '#2b2d33'
    },

    changeModalText:{
        marginTop: 10,
        fontSize: 16,
        lineHeight: '24px',
        color: '#2b2d33',
    },

    changeModalBtn: {
        marginTop: 20,
        width: '100%',
        height: '48px',
        background: '#F5922F',
        color: '#fff',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
        borderRadius: '4px',
        border: '1px solid #F5922F',
    },

    changeNumberHeader:{ 
        fontWeight: 600,
        fontSize: 28,
        lineHeight: '32px',
        color: '#2b2d33'
    },

    changeText:{
        fontSize: 12,
        lineHeight: '24px',
        marginTop: 20,
    },


     spielOtp : {
        marginTop: 20,
        color: '#2b2d33',
        align: 'center'
      },
  
       otpHeader : {
        fontWeight: '300',
        fontSize: '28px',
        lineHeight: '32px',
    },
  
     inputDiv : {
      marginTop: 47,
    },
    
      sDesc : {
        marginTop: '10px',
        fontSize: '14px',
        lineHeight: '24px',
        opacity: '0.8',
        letterSpacing: '-0.01em'
    },
  
       otpSubmitBtn : {
      border: '1px solid #F5922F',
      borderRadius: '4px',
      color: '#fff',
      background: '#F5922F',
      width: '100%',
      cursor: 'pointer',
      height: 48,
  },
  
     otpDisableBtn : {
      border: '1px solid #F5922F',
      borderRadius: '4px',
      color: '#fff',
      background: '#F5922F',
      opacity: '0.5',
      cursor: 'not-allowed',
      width: '100%',
      height: 48,
  },

  otpResendBtnDisabled: {
    opacity: '0.5',
    cursor: 'not-allowed',
    height: 36,
    border: '1px solid #F5922F',
    borderRadius: '4px',
    background: '#fff',
    color: '#F5922F',
    fontWeight: 'bold',
    width: 220,
},

otpResendBtn: {
    cursor: 'pointer',
    height: 36,
    border: '1px solid #F5922F',
    borderRadius: '4px',
    background: '#fff',
    color: '#F5922F',
    fontWeight: 'bold',
    width: 220,
},

subBody: {
    padding: '32px 16px 0px 16px'
},

tabBtn: {
    width: '100%',
    height: '43px',
    marginBottom: 16,
    background: '#fff',
    border: '1px solid #D1D5DD',
    borderRadius: '4px',
    outline: 0,
    fontWeight: 'bold',
    color: '#2B2D33',
    padding: '0px 20px 0px 20px',
    zIndex: 10,
},

tabBtns: {
    display: 'flex',
    marginTop: 24,
    alignItems: 'center'
},

tabLink: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    // marginRight: 32,
    // paddingBottom: 16,
    padding: '0 12px 16px 12px'
},

qrDiv: {
    padding: '32px',
    // marginTop: '8px',
    width: '640px',
    // border: '1px solid #E6EAF0',
    // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    // borderRadius: '4px',
    backgroundColor: '#fff',
    [theme.breakpoints.down("sm")]: {
        width: '350px',
        padding: '0px',
        marginBottom: '64px',
    }
},

qrDetailsDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
},

qrHeaderText: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '20px',
    color: '#0d3d76',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap', 
},

qrLinkText: {
    color: '#f5922f',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '20px',
    borderBottom: '1px solid #f5922f',
    [theme.breakpoints.down("sm")]: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap', 
        width: '90%'
    }
},

qrDetails: {
    width: '60%',
    overflow: 'hidden',
    margin: '16px',
    [theme.breakpoints.down("sm")]: {
        width: '80%',
    }
},

hideOverflow: {
    marginTop: 12,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',  
},

qrSettingsDiv: {
    marginTop: 32,
    border: '1px solid #E6EAF0',
    [theme.breakpoints.down("sm")]: { 
        marginTop: 0,
    }
},

qrSettingsHeader: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '20px',
    color: '#000000'
},


qrSettingsText: {
    fontSize: 14,
    lineHeight: '21px',
    color: '#909196',
    marginTop: 8,
    [theme.breakpoints.down("sm")]: {
        marginTop: 16
    },
},

toggleSettings : {
    borderBottom: '1px solid #E6EAF0',
    padding: 20
},

fieldPlacehold: {
    fontSize: 12,
    lineHeight: '24px',
    color: '#212b36',
    marginBottom: 4,
    marginTop: 16,

},

checkBoxText: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#54575f',
    [theme.breakpoints.down("sm")]: {
        fontSize: 12,
    },
},

shareQrDiv: {
    marginTop: 32,
    textAlign: 'center'
},

shareText: {
    fontSize: 20,
    lineHeight: '32px',
    fontWeight: 300,
    color: '#0d3d76'
},

shareBtnDiv: {
    marginTop: 8,
    width: '50%',
    display: 'flex',
    justifyContent: 'center'
},

btnstyle: {
    outline: 0,
    background: 'transparent',
    border: '0',
    cursor: 'pointer',
    '&:hover': {
        transform: 'scale(1.2)'
    }
},

btnText: {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '12px',
    color: '#54575f',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap', 
},

customListBtn: {
    color: '#f5922f',
    border: '1px solid #f5922f',
    fontSize: '16px',
    fontWeight: 600,
    height: '40px',
    width: 200,
    background: '#fff',
    borderRadius: '4px',
    cursor: 'pointer',
    outline: 0,
},

customModalText: {
    marginTop: 16,
    fontSize: 16,
    lineHeight: '24px',
    color: '#000000',
    [theme.breakpoints.down("sm")]: {
        padding: 20
    },
},

createcustomListBtn: {
    border: '1px solid #f5922f',
    color: '#fff',
    background: '#f5922f',
    fontWeight: 'bold',
    borderRadius: '4px',
    width: '100%',
    height: '40px',
    cursor: 'pointer',
    [theme.breakpoints.down("sm")]: {
        width: '200px',
    },
},

newAmountBtn: {
    color: '#1dd28b',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '16px',
    border: '1px solid #d1d5dd',
    width: '100%',
    height: '40px',
    outline: '0',
    cursor: 'pointer',
    background: '#fff',
    borderRadius: '4px'
},

amountTable: {
    // border: '1px solid #d1d5dd',
    width: '100%',
    marginTop: 16
},

tdStyle: {
    padding: 16,
    textAlign: 'center',
    fontSize: '16px',
    color: '#000000',
    border: '1px solid #d1d5dd',
    [theme.breakpoints.down("sm")]: {
        width: '50%',
    },
}, 

cancelEdit: {
    border: '1px solid #f5922f',
    color: '#f5922f',
    fontWeight: 600,
    background: '#fff',
    width: '70px',
    height: '32px',
    outline: 0,
    cursor: 'pointer',
    borderRadius: '4px',
    marginRight: 8,
},

saveEdit: {
    border: '1px solid #f5922f',
    color: '#fff',
    fontWeight: 600,
    background: '#f5922f',
    width: '70px',
    height: '32px',
    outline: 0,
    cursor: 'pointer',
    borderRadius: '4px'
},

qrDetailsMainDiv: {
    background: '#F4F6F9', 
    padding: '32px 0px 32px 0px',
    [theme.breakpoints.down("sm")]: {
        padding: '8px 0px 32px 0px',
    },
},

detailsDiv: {
    padding: '0px 80px 0px 80px',
    [theme.breakpoints.down("sm")]: {
        padding: 20,
    },
},

qrDetailsHeader: {
    color: '#0D3D76',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '32px',
    marginTop: 16,
},

prDiv: {
    marginTop: 32,
    [theme.breakpoints.down("sm")]: {
        marginTop: 8,
    },
},

mainInfoDiv: {
    padding: '0px 64px 0px 64px',
    [theme.breakpoints.down("sm")]: {
        padding: '0px 20px 0px 20px',
    },
},

detailsHeader: {
    textAlign: 'left',
    marginTop: 32,
    color: '#000000',
    fontSize: 16,
    lineHeight: '16px',
    fontWeight: 'bold',
    paddingBottom: 10,
    borderBottom: '1px solid #000000',
    [theme.breakpoints.down("sm")]: {
        marginTop: 20,
    },
},

detailsInfo: {
    textAlign: 'left',
    marginTop: 12,
    [theme.breakpoints.down("sm")]: {
        marginTop: 8,
    },
},

subTotalDiv: {
    marginTop: 16,
    borderTop: '1px dashed #D1D5DD',
    borderBottom: '1px dashed #D1D5DD',
    padding: '16px 0px 16px 0px'
},

totalPlaceholder: {
    color: '#5f575f',
    fontSize: 16,
},

payNowBtn: {
    borderRadius: '4px',
    width: '100%',
    height: '40px',
    color: '#fff',
    fontWeight: 'bold',
    border: 0,
    background: '#f5922f',
    outline: 0,
    marginTop: 16,
    cursor: 'pointer',
    '&:hover': {
        opacity: '0.8'
    }
},

termsDiv: {
    fontSize: 11,
    color: '#2b2d33',
    opacity: 0.8,
    fontWeight:'bold',
    lineHeight: '24px',
    marginTop: 16,
    textAlign: 'center'
},

qrFooter: {
    border: '1px solid #E6EAF0',
    marginTop: 16,
    padding: '16px 0px 16px 0px'
}, 

footerFlex: {
    padding: 20,
    [theme.breakpoints.down("sm")]: {
        justifyContent: 'center',
    },
},

securedLogo: {
    paddingTop: 32,
},

adornmentStyle: {
    color: '#2B2D32',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '27px'
},

notSelected: {
    background: '#FAFAFA',
    height: '42px',
    border: '1px solid #d1d5dd',
    borderRadius: '9px',
    cursor: 'pointer',
    outline: 0,
    width: 103,
    fontSize: 16,
    color: '#000000',
    '&:hover': {
        background: '#f5f5f4'
    },
    [theme.breakpoints.down("sm")]: {
        width: 90,
        fontSize: 12,
    },
},

selected: {
    background: "#1DD28B",
    border: '1px solid #1DD28B',
    borderRadius: '9px',
    height: '42px',
    cursor: 'pointer',
    outline: 0,
    width: 103,
    fontSize: 16,
    color: '#fff',
    fontWeight: '500',
    '&:hover': {
        opacity: '0.8'
    },
    [theme.breakpoints.down("sm")]: {
        width: 90,
        fontSize: 12,
    },
},

textSize: {
    fontSize: 48,
},

viewMyCheckoutPage: {
    width: '180px',
    height: '40px',
    backgroundColor: '#F5922F',
    border: '1px solid #F5922F',
    borderRadius: '4px',
    color: '#fff',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '24px'
}


}))

export default accountStyle