import React from 'react';
import ButtonGroup from 'antd/lib/button/button-group';
import MetaTag from '../../meta_tag/meta_tag';
import { Button, Typography, Icon, DatePicker, Input, message } from 'antd';
import Table from './table';
import { history } from '../../../store/history';
import moment from 'moment';
import financeServices from './openFinanceServices';
import ExportCsvModal from './exportCsvModal';

const OpenFinance = () => {
    const [dateRange, setDateRange] = React.useState({ startDate: '', endDate: '' });
    const [selected, setSelected] = React.useState('All');
    const userType = localStorage.getItem('userType');
    const [searchVal, setSearchVal] = React.useState('');
    const [visibleClearBtn, setVisibleClearBtn] = React.useState(false);
    const [tableData, setTableData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [showExportModal, setShowExportModal] = React.useState(false);
    const [email, setEmail] = React.useState(null);
    const [page, setPage] = React.useState(1);

    const ButtonTab = ({ rootStyle }) => {

        const status = ['All', 'Pending', 'Approved', 'Rejected'];

        const handleClick = (event) => {
            setSelected(event.target.value);
        }

        return (
            <div style={rootStyle} hidden>
                <ButtonGroup>
                    {
                        status.map((btn, i) => {
                            return (
                                <Button
                                    key={btn}
                                    value={btn}
                                    onClick={handleClick}
                                    style={{
                                        ...styles.btnGroup,
                                        color: btn === selected ? '#FFF' : '#2B2D33',
                                        backgroundColor: btn === selected ? '#0D3D76' : '#FFF',
                                        borderRadius: i === 0 ? '10px 0 0 10px' : i === 3 ? '0 10px 10px 0' : 0
                                    }}
                                >
                                    {btn}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </div>
        )
    }

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    const rangeSelect = (dates, dateString, id) => {
        const days = id === 'endDate' ? (moment(dateString).daysInMonth() - 1) : 0
        setDateRange({ ...dateRange, [id]: moment(dateString).add(days, 'd').format('MM-DD-YYYY') });
        console.log('DAYS', days);
    }

    const errorPrompt = (msg) => {
        message.error(msg);
    };

    const handleSearch = (e) => {
        setSearchVal(e.target.value);
    }

    const onFilterApply = () => {
        getData(searchVal, dateRange.startDate, dateRange.endDate);
        setVisibleClearBtn(true);
    }

    const onChangePage = (page) => {
        setPage(page);
        getData(searchVal, dateRange.startDate, dateRange.endDate, page);
    }

    const getData = async (search, start, end, page, pageSize, isExport, email) => {
        setIsLoading(true);
        try {
            const res = await financeServices.getMonthlySummary(search, start, end, page, pageSize, isExport, email);

            if (isExport && res.status === 'success') {
                setShowExportModal(false);
                message.success(res.message);
            } else if (res.data) {
                setTableData(res);
            } else {
                errorPrompt(res.message);
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            errorPrompt('Please try again');
        }
    }

    const exportEmail = () => {
        getData(searchVal, dateRange.startDate, dateRange.endDate, page, 10, true, email);
    }

    const redirectPage = () => {
        if (localStorage.getItem("jwtToken") === "" || !localStorage.getItem("jwtToken")) {
            history.push('/login')
        }
        else if (!['ME', 'CO', 'SW'].includes(localStorage.getItem("userType"))) {
            history.push('/overview')
        }
        else if (['PS', 'PF'].includes(localStorage.getItem("userType"))) {
            history.push('/submissions_kyb')
        }
    }

    const resetFilters = () => {
        setDateRange({ startDate: '', endDate: '' });
        setSearchVal('');
        setVisibleClearBtn(false);
        getData('', '', '');
    }

    React.useEffect(() => {
        redirectPage();
        resetFilters();
    }, [selected])

    return (
        <div style={{ width: '116%' }}>
            <MetaTag title="Open Finance" />
            <div style={styles.titleContainer}>
                <div style={styles.btnContainer}>
                    <ButtonTab />
                    <div>
                        <Button style={styles.addBtn} onClick={() => setShowExportModal(true)} ><Icon type='download' /> Export CSV</Button>
                    </div>
                </div>
            </div>
            <div style={styles.body}>
                <div style={styles.subContainer}>
                    <div style={styles.clearDiv}>
                        <Typography style={styles.filterText}>Filter</Typography>
                        <a style={styles.clearBtnDiv} hidden={!visibleClearBtn} onClick={resetFilters}>
                            <Icon style={styles.clearIcon} type="close-circle" theme="filled" />
                            <span style={styles.clearBtn}>Clear Filter</span>
                        </a>
                    </div>
                    <Typography style={styles.reminder}><Icon type="exclamation-circle" theme="filled" /> Click <b>Apply</b> button to show results.</Typography>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 0 24px 0' }}>
                    <DatePicker.MonthPicker
                        // format='MM/DD/YYYY'  
                        // disabledDate={disabledDate}
                        // defaultValue={moment()}
                        value={dateRange.startDate && moment(dateRange.startDate)}
                        size="large"
                        picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'startDate')}
                        placeholder='Start Month'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <span style={{ paddingTop: 8 }}>-</span>
                    <DatePicker.MonthPicker
                        // format='MM/DD/YYYY'
                        // disabledDate={disabledDate}
                        // defaultValue={moment()}
                        value={dateRange.endDate && moment(dateRange.endDate)}
                        size="large"
                        picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'endDate')}
                        placeholder='End Date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <Input
                        placeholder="Search"
                        style={{ width: '200px', height: '40px', borderRadius: '4px', padding: '0 8px 0 0px' }}
                        suffix={<Icon type="search" style={{ fontSize: '18px' }} />}
                        onChange={handleSearch}
                        value={searchVal}
                    />
                    <Button style={styles.applyBtn} onClick={onFilterApply} >Apply</Button>
                </div>
                <Table
                    data={tableData}
                    // onUpdate={() => getData('', selected, dateRange.startDate, dateRange.endDate)}
                    isLoading={isLoading}
                    // length={totalLength}
                    selected={selected}
                    currentPage={page}
                    // sort={tableSort}
                    onChangePage={onChangePage}
                />
                <ExportCsvModal visible={showExportModal} onClose={() => setShowExportModal(false)}
                    onSend={exportEmail} onChange={(e) => setEmail(e)} />
            </div>
        </div>
    )
}

const styles = {
    root: {

    },
    titleContainer: {
        padding: '20px 0'
    },
    title: {
        backgroundColor: 'transparent',
        width: '100%',
        color: '#000',
        fontWeight: 'bold',
        fontSize: 28,
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 8,
    },
    btnGroup: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: '0 32px',
    },
    body: {
        backgroundColor: 'transparent',
        padding: '0px 16px',
        backgroundColor: '#FFF',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        margin: '38px'
    },
    addBtn: {
        color: '#FFF',
        backgroundColor: '#1DD28B',
        height: 40,
        borderRadius: 4,
        padding: '8px, 16px, 8px, 16px',
        fontSize: 16,
        fontWeight: '600'
    },
    btnContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    body: {
        backgroundColor: 'transparent',
        padding: '0px 16px',
        backgroundColor: '#FFF',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        margin: '0px 0px 38px -10%'
    },
    subContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 0'
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 178,
        padding: '0 8px 0 8px'
    },
    filterText: {
        color: '#000',
        fontSize: 14,
        fontWeight: 'bold'
    },
    reminder: {
        fontSize: 12,
        color: '#909196',
    },
    applyBtn: {
        height: 40,
        width: 92,
        fontSize: 16,
        fontWeight: '600',
        borderRadius: 4,
        backgroundColor: '#F5922F',
        color: '#FFF',
    },
    clearBtnDiv: {
        backgroundColor: '#E6EAF0',
        borderRadius: 24,
        padding: '4px 12px',
        margin: '0 12px'
    },
    clearBtn: {
        color: '#000000',
        fontSize: 12,
        paddingLeft: 4,
    },
    clearDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    clearIcon: {
        color: '#2B2D33',
        fontSize: 10,
    }
}

export default OpenFinance;

