export const titleText = {
    marginTop: 32,
    fontWeight: 'bold',
}

export const panelHeader = {
    // borderBottom: '1px solid #E6EAF0',
    padding: '16px 32px',
}

export const checkBoxStyle = {
    padding: '16px 32px',
}

export const mobileApplyBtn = {
    margin: 16,
    color: '#fff',
    width: '90%',
    height: '40px',
    background: '#f5922f',
    borderRadius: '4px',
}

export const fixedBtn = { 
    width: '100%',
    position: 'fixed',
    bottom: 0,
    padding: 16,
    background: '#fff'
}

export const none = {}