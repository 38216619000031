import React,{ useEffect, createRef, useState } from 'react';
import { CSVReader } from 'react-papaparse'
import { setBatchUploadFiles } from '../orderAction';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import batchUploadServices from './batchUploadServices';
import { Modal, Typography, Button, Upload, Icon, Affix, Alert } from 'antd';

const UploadCsvModal = (props) => {

  const { 
    visible, closeModal, exportTemplate, closeLinkModal, replace, replaceId, onSuccess, segments
  } = props; 
  
  const [fileUploaded, setFileUploaded] = useState(false);
  const uploadedFiles = useSelector(state => state.orders.batchUploadFiles);
  const dispatch = useDispatch();
  const buttonRef = createRef();
  const [uploadNotif, setUploadNotif] = useState(null);
  const [chooseBtnStyle, setChoosdeBtnStyle] = useState({color: '#1DD28B', backgroundColor: '#FFF'});
  const [cancelBtnStyle, setCancelBtnStyle] = useState({color: '#F5922F', backgroundColor: '#FFF'});
  const [csv, setCsv] = useState(null);
  const [loading, setLoading] = useState(false);
  const testMode = (window.location.pathname.indexOf('/test') > -1 && localStorage.getItem("userType") == "CO");

  const sampleData = [
    {
      batch_code: 'C001',
      num_req: 6,
      date_upload: moment().format(),
    },
    {
      batch_code: 'C002',
      num_req: 10,
      date_upload: moment().format(),
    },
    {
      batch_code: 'C003',
      num_req: 24,
      date_upload: moment().format(),
    }
  ]

  const handleOpenDialog = (e, data) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e)
    }
  };

  const uploadCsv = async (csv) => {
    setLoading(true);
    try {
      const res = await batchUploadServices.uploadCsv(csv, testMode);
      if(res.status === 'success') {
        onSuccess();
        closeModal();
      } else {
        setUploadNotif(res.message);
        resetProps();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setUploadNotif(error);
      resetProps();
    }
  }

  const replaceCsv = async (csv)  => {
    setLoading(true);
    try {
      const res = await batchUploadServices.replaceCsv(replaceId, csv, testMode);
      if(res.status === 'success') {
        onSuccess();
        closeModal();
      } else {
        setUploadNotif(res.message);
        resetProps();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setUploadNotif(error);
      resetProps();
    }
  }

  const handleUpload = async (results,data) => {
    setFileUploaded(true);

    // if(data.type !== 'text/csv') {
    //   setUploadNotif('File type must be in a csv format');
    //   resetProps();
    // } else {
      setCsv(data);
    // }
  }

  if(uploadNotif){
    setTimeout(() => {
      setUploadNotif(null);
    }, 5000);
  }

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e)
    }
  }

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err, file, inputElem, reason);
    setUploadNotif(reason);
  }

  const confirmUpload = () => {
    if(replace) {
      replaceCsv(csv);
    } else {
      uploadCsv(csv);     
    }
  }

  const resetProps = () => {
    setFileUploaded(false);
  }

  const chooseBtnHover = () => {
    setChoosdeBtnStyle({
      color: '#FFF',
      backgroundColor: '#1DD28B',
    })
  }

  const chooseBtnLeave = () => {
    setChoosdeBtnStyle({
      color: '#1DD28B',
      backgroundColor: '#FFF',
    })
  }

  const cancelBtnHover = () => {
    setCancelBtnStyle({
      color: '#FFF',
      backgroundColor: '#F5922F',
    })
  }

  const cancelBtnLeave = () => {
    setCancelBtnStyle({
      color: '#F5922F',
      backgroundColor: '#FFF',
    })
  }

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={closeModal}
      destroyOnClose
      afterClose={resetProps}
      width={392}
      centered
      closeIcon={<Icon type="close" className={`text-${segments}`}/>}
      bodyStyle={styles.root}>
      <div hidden={!uploadNotif} style={{paddingTop: 32}}><Alert message={uploadNotif} type="error" showIcon /></div>
      <Typography style={styles.title}>Upload your CSV File</Typography>
      <Typography style={{...styles.normalFont, width: '80%'}}>Upload the CSV file with the information you want to import here.</Typography>
      <CSVReader
        ref={buttonRef}
        onError={handleOnError}
        noClick
        noDrag
        progressBarColor='#1DD28B'
        onFileLoad={(results, file)=>handleUpload(results, file)}
        onRemoveFile={()=>setFileUploaded(false)}
        config={{header: true, skipEmptyLines: true}}
        noProgressBar={uploadNotif}
      >
        {({file}) => (
          <div style={{padding: '32px 0', position: 'relative'}}>
            <button href style={{padding: file&&fileUploaded?'5px 42px 5px 22px':'5px 22px',}}
              className={`outline-btn--${segments} pill-btn btn-height`} 
              onClick={(e, file)=>handleOpenDialog(e,file)}
              onMouseOver={chooseBtnHover}
              onMouseLeave={chooseBtnLeave}>
              {file&&fileUploaded? file.name : 'Choose File'}
            </button>
            {fileUploaded&&<a href style={styles.removeIcon} onClick={resetProps}><Icon type="close-circle" theme="filled" style={{color: '#909196', fontSize: 28, paddingLeft: 8}} bodyStyle={{backgroundColor: '#FFF'}} /></a>}
          </div>     
        )}
      </CSVReader>
      <Typography style={styles.normalFont}>Don't have a file?</Typography>
      <a className={`text-${segments} wc-header top-12`} href onClick={exportTemplate} >Download CSV Template</a>
      <div style={styles.footerBtn} className="top-20">
        <button 
          className={`outline-btn--${segments} btn-height btn-full-width right-8`}
          onClick={closeModal}
          onMouseLeave={cancelBtnLeave}
          onMouseOver={cancelBtnHover}>Cancel</button>
        <button className={`btn--${segments} btn-height btn-full-width`} 
                style={{opacity: !fileUploaded? '0.5' : '1' }} 
                disabled={!fileUploaded} 
                onClick={confirmUpload} 
                loading={loading}>Confirm</button>
      </div>
    </Modal>
  )
}

const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmBtn: {
    height: 48,
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    width: '48%',
  },
  title: {
    fontSize: 24,
    fontWeight: '300',
    color: '#000',
    padding: '40px 0 8px 0'
  },
  normalFont: {
    fontSize: 16,
    color: '#000',
    textAlign: 'center',
  },
  link: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#F5922F',
    marginBottom: 36,
  },
  chooseBtn: {
    border: '1px solid #1DD28B',
    fontSize: 12,
    borderRadius: 80,
    display: 'flex',
    alignItems: 'center',
    height: 40,
    fontWeight: '600'
  },
  cancelBtn: {
    width: '48%',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    height: 48,
    border: '1px solid #F5922F',
  },
  footerBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  removeIcon: {
    position: 'absolute', 
    color: '#FFF',
    transform: 'translate(-50%, -50%)',
    right: '-4%',
    top: '51%'
  }
}

export default UploadCsvModal;