import React from 'react'
import { Input, DatePicker, message } from 'antd'
import * as Icons from '../../../../static/images/home/index';
import { isMobile } from 'react-device-detect';
import Mastercard from '../../../../static/icons/card_type/mastercard.svg';
import Visa from '../../../../static/icons/card_type/visa.svg';
import { numberWithCommas } from '../../../constants/constants'

const UnsignedDataFields = props => {

    const { MonthPicker }= DatePicker;
    
    const {
        card_type,
        card_number,
        card_expiry_date,
        card_cvn,
        handleChange,
        payment,
        handleExpiryChange,
        cardExpiryMoment,
    } = props;

    const monthFormat = "MM-YYYY"

    return(
        <div>
            <input style={{width: "10vw"}} type="hidden" name="card_type" size="25" value={card_type}/>
            <div className="amount-div liveColor" align="center">
                <div className="normalTextStyle">
                    Amount to Pay
                </div>
                <div className="screen-title-text top-4">
                    ₱ {numberWithCommas(parseFloat(payment.amount).toFixed(2))}
                </div>
            </div>
            
            <div className="top-32 cust-info" align='left'>
                <div className="wc-header"> 
                    <img src={Icons.card} className="right-8" alt="info" /> 
                    Customer card information
                </div>

                <div className="top-20 liveColor">
                    <div className="smallTextStyle">
                        Card Number
                    </div>
                    <Input className="input-100 top-4" type="number" name="card_number" maxLength={16} value={card_number} onChange={handleChange} 
                        suffix={<img hidden={card_number.length === 0} src={card_type === "001" ? Visa : Mastercard} alt="card" />} />
                </div>

                <div className="top-16">
                    <div className={`${isMobile ?  "" : "twoCol"}`}>
                        <div className="top-16">
                            <div className="smallTextStyle liveColor">Expiry date</div>
                            <MonthPicker className="two-col-input top-4" 
                                format={monthFormat} 
                                value={cardExpiryMoment || null}
                                onChange={handleExpiryChange} />
                            <input type="hidden" name="card_expiry_date" size="25" value={card_expiry_date} />
                        </div>
                        <div className="top-16">
                            <div className="smallTextStyle liveColor">CVV</div>
                            <Input className="two-col-input top-4" 
                                type="password" 
                                pattern="[0-9]*" 
                                inputMode="numeric" 
                                name="card_cvn"
                                value={card_cvn} 
                                onChange={handleChange}
                                onBlur={
                                    e => {
                                        if(e.target.value.length < 3){
                                            message.error("CVV must aleast 3 digit", 3)
                                        }
                                    }
                                } />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnsignedDataFields