import { makeStyles } from '@material-ui/core';

const termsStyle = makeStyles(theme => ({

    mainBody: {
        padding: '30px 250px 50px 250px',
        backgroundColor: '#fafafa',
        [theme.breakpoints.down("sm")]: {
            padding: '50px 20px 50px 20px'
        }
    },

    btiBody: {
        padding: '30px 250px 50px 250px',
        backgroundColor: '#fff',
        [theme.breakpoints.down("sm")]: {
            padding: '50px 20px 50px 20px'
        }
    },

    button: {
        marginLeft: '-10%',
        boxShadow: '0px, 0px, 10px, rgba(0,0,0,0.1)',
        [theme.breakpoints.down("sm")]: {
        
            marginLeft: '1px',
            marginBottom: '10%',
            
        },
        float: 'left'
    },

    mainHeader: {
        textAlign: 'center',
        fontWeight: 600,
        lineHeight: '24px',
        fontSize: '20px',
        textTransform: 'uppercase',
        fontFamily: 'Inter',
        marginBottom: '30px',
        marginTop: '15px',
        width: '110%',


    },

    mainInstruction: {
        textAlign: 'justify',
        lineHeight: '24px',
        fontSize: '16px',
        color: '#000000',
        marginTop: '10px'
    },

    mainUnderline: {
        textDecoration: 'underline'
    },

    body:{
        marginTop: '30px',
        textAlign: 'justify'
    },

    title: {
        fontWeight: 600,
        fontSize: '16px',
        color: '#000000',
        marginBottom: '20px'
    },  
    
    text: {
        fontSize: '14px',
        color: '#000000',
        marginBottom: '20px'

    },

    footer: {
        marginTop: '30px',
        fontSize: '12px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        lineHeight: '24px',
        color: '#000000'
    }
}))

export default termsStyle;