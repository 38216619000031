import { LoginActionTypes } from "./authAction";
const initialState = {
  user: {},
  loading: false,
  error: ""
};
export default function authReducers(state = initialState, action) {
  switch (action.type) {
    case LoginActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LoginActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload
      };
    case LoginActionTypes.LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        user: {},
        error: action.payload
      };
    case LoginActionTypes.CLEAR:
      return {
        initialState,
        user: {}
      };
    default:
      return initialState;
  }
}