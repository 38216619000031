import React,{ useEffect, useState } from 'react';
import { Card, Divider, Input, message, Switch, Table } from 'antd';
import {Handle401} from '../../../handle401/handle401'
import feesServices from '../feesServices';

//Icoms
import WalletIcon from '../../../../static/icons/wallet.svg'
import PaymentIcon from '../../../../static/icons/payment.svg'
import SubMenu from 'antd/lib/menu/SubMenu';

const MultiwalletToggle = ({pUid, init_enabled, paymentType, transferFeesData}) => {

  const [enabled, setEnabled] = useState(init_enabled);
  const [disabled, setDisabled] = useState(false);

  const [custom, setCustom] = useState(paymentType == 'CU');
  const [subscribe, setSubscribe] = useState(paymentType == 'SU');
  const [payPerUse, setPayPerUse] = useState(paymentType == 'PP');

  const [customDisabled, setCustomDisabled] = useState(false)
  const [subscribeDisabled, setSubscribeDisabled] = useState(false)
  const [payPerUseDisabled, setPayPerUseDisabled] = useState(false)

  const [isCustomEdit, setIsCustomEdit] = useState(false)
  const [isSubscribeEdit, setIsSubscribeEdit] = useState(false)
  const [isPayPerUseEdit, setIsPayPerUseEdit] = useState(false)

  const [customFee, setCustomFee] = React.useState(0)
  const [subscriptionFee, setSubscriptionFee] = React.useState(0)
  const [payPerUseFee, setPayPerUseFee] = React.useState(0)

  const [editInputFee, setEditInputFee] = React.useState(0)


  useEffect(() => {
    setEnabled(init_enabled)
    setCustom(paymentType == 'CU')
    setSubscribe(paymentType == 'SU')
    setPayPerUse(paymentType == 'PP')
    setCustomFee(transferFeesData && transferFeesData.custom_user_fee)
    setSubscriptionFee(transferFeesData && transferFeesData.subscription_user_fee)
    setPayPerUseFee(transferFeesData && transferFeesData.payperuse_user_fee)

  }, [init_enabled, paymentType, transferFeesData])

  const handleChange = (event) => {
    setDisabled(true);
    valueUpdate(!enabled);
  }

  const handlePaymentChange = (event, selectedPayment) =>  {
    setCustomDisabled(true)
    setSubscribeDisabled(true)
    setPayPerUseDisabled(true)
    paymentUpdate(selectedPayment)
  }

  async function valueUpdate(value){
      let venabled = 0;
      if(value){
        venabled = 1;
      }
      try{
          let res = await feesServices.multiwalletToggle(pUid, venabled);

          if (res.status == "success"){
            setEnabled(value);
          }
      }
      catch(error){
          if((error.response)&&(error.response.status == 401)){
              Handle401()
          }
      }
      setDisabled(false);
  }

  async function paymentUpdate(selectedPayment){
      
      try{
        let res = await feesServices.paymentTypeToggle(pUid, selectedPayment);

        if (res.status == "success"){
          if(selectedPayment == 'CU'){
            setCustom(true)
            setSubscribe(false)
            setPayPerUse(false)
          }
          else if(selectedPayment == 'SU'){
            setCustom(false)
            setSubscribe(true)
            setPayPerUse(false)
          }
          else{
            setCustom(false)
            setSubscribe(false)
            setPayPerUse(true)
          }
        }
      }
      catch(error){
          if((error.response)&&(error.response.status == 401)){
              Handle401()
          }
      }

      setCustomDisabled(false)
      setSubscribeDisabled(false)
      setPayPerUseDisabled(false)
  }


  async function updateTransferFee(selectedPayment){

      try{
        
        if(parseInt(editInputFee) >= 0){
          let res = await feesServices.updateTransferFee(pUid, selectedPayment, editInputFee);
          if (res.status == "success"){
            setIsCustomEdit(false)
            setIsSubscribeEdit(false)
            setIsPayPerUseEdit(false)
            if(selectedPayment == 'CU'){
              setCustomFee(editInputFee)
            }
            else if(selectedPayment == 'SU'){
              setSubscriptionFee(editInputFee)
            }
            else{            
              setPayPerUseFee(editInputFee)
            }
  
          }
        }
        else{
          message.error("Invalid transfer fee")
        }
        
      }
      catch(error){
          if((error.response)&&(error.response.status == 401)){
              Handle401()
          }
      }
  }

  const PaymentMethod = ({switchValue, title, disabledVal, data, isEdit, setIsEdit}) => {

    return(
      <div style={{marginBottom : '10px'}}>
        <div style={styles.main}>
          <div style={styles.font3}>{title} is {switchValue ? <b>on.</b> : <b style={{color: '#77797c'}}>off.</b>} </div>
          <Switch checked={switchValue} 
            size="small" 
            onChange={(e)=>handlePaymentChange(e, title == 'Pay Per use' ? 'PP' : title.substring(0, 2).toUpperCase())} 
            style={{margin: '0px 20px 0px 12px', backgroundColor: `${switchValue ? '#0A315E' : '#D1D5DD'}`}} 
            disabled={disabledVal}/>
        </div>
        <Table columns={
            [
              {
                title: 'Sub-wallet',
                width: 183,
                dataIndex: 'subwallet',
                render: (text) => {
                  return <div style={styles.font3}>{text}</div>
                }
              },
              {
                title: 'Wallet Transfer Fee',
                width: 183,
                dataIndex: 'transfer_fee',
                render: (text) => {
                  return(
                    isEdit ? 
                    <div style={{...styles.font3, display: 'flex'}}> 
                      <div style={{margin: '3px 5px 0px 0px'}}>₱</div> 
                      <Input autoFocus="autoFocus" value={editInputFee} onChange={(e)=> setEditInputFee(e.target.value)}/>
                    </div> : 
                    <div style={styles.font3}>
                      ₱ {text}
                    </div>
                  )  
                }
              },
              {
                title: 'Action',
                render: () => {
                  return (
                    <div>
                      {
                        isEdit ?
                        <div style={{display: 'flex'}}>
                          <div style={{...styles.edit, marginRight: '10px'}} onClick={()=>updateTransferFee(title == 'Pay Per use' ? 'PP' : title.substring(0, 2).toUpperCase())}>Save</div>
                          <div style={{...styles.edit, color: 'red'}} onClick={()=>setIsEdit(false)}>Cancel</div>
                        </div>:
                        <div style={styles.edit} onClick={()=>{
                          title == 'Custom' ? setEditInputFee(customFee) :
                          title == 'Subscription' ? setEditInputFee(subscriptionFee) : 
                          setEditInputFee(payPerUseFee)
                          setIsEdit(true)
                        }}>Edit</div>
                      }
                    </div>
                  )
                }
              },
            ]
          } 
          dataSource={data} 
          style={{marginBottom: '32px', marginTop: '10px'}} 
          pagination={false}/>
      </div>
    )
  }
  return (
    <Card size="small" style={styles.root}>
      <div hidden={!enabled}>
        <div style={styles.main}>
          <div style={styles.textContent}>
            <img src={PaymentIcon} style={{marginRight: '10px'}}/> <span style={styles.font1}><strong>Payment Type</strong></span>
          </div>
        </div>
        <Divider/>
        <PaymentMethod switchValue={custom} title={"Custom"} disabledVal={customDisabled} data={[{'subwallet': 'Free', 'transfer_fee': customFee}]} isEdit={isCustomEdit} setIsEdit={setIsCustomEdit}/>
        <PaymentMethod switchValue={subscribe} title={"Subscription"} disabledVal={subscribeDisabled} data={[{'subwallet': '₱ 1,000 / month', 'transfer_fee': subscriptionFee}]} isEdit={isSubscribeEdit} setIsEdit={setIsSubscribeEdit}/>
        <PaymentMethod switchValue={payPerUse} title={"Pay Per use"} disabledVal={payPerUseDisabled} data={[{'subwallet': '₱ 1,000 / one time', 'transfer_fee': payPerUseFee}]} isEdit={isPayPerUseEdit} setIsEdit={setIsPayPerUseEdit}/>
      </div>
      <div style={styles.main}>
        <div style={styles.textContent}>
          <img src={WalletIcon} style={{marginRight: '10px'}}/> <span style={styles.font1}><strong>Multiwallet is {enabled ? 'enabled' : 'disabled'}</strong></span>
        </div>
        <Switch checked={enabled} size="small" onChange={handleChange} style={{margin: '0px 20px 0px 12px', backgroundColor: `${enabled ? '#0A315E' : '#D1D5DD'}`}} disabled={disabled}/>
      </div>
      <div style={{marginLeft: '34px',color: '#909196', fontSize: '16px'}}>
        Allows merchant to manage their own ecosystem of sub-wallets. 
      </div>
    </Card>
  )
}

const styles = {
  root: {
    margin: '0px 12px 20px 12px',
    padding: '23px 20px 23px 20px'
  },
  main: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'space-between',
  },
  textContent: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
  },
  font1: {
    color: '#0D3D76',
    fontSize: 16,
    fontWeight: '500'
  },
  font2: {
    color: '#909196',
    fontSize: 14,
    textAlign: 'left'
  },
  font3: {
    color: '#2b2d33',
    fontSize: '16px',
    fontWeight: 'normal'
  },
  edit: {
    color: '#0D3D76',
    fontSize: 14,
    fontWeight: 'bold',
    cursor: 'pointer'
  }
}

export default MultiwalletToggle;