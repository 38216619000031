import React from 'react';
import { Button } from 'antd';

const FilterBtn = props => {

    const {
        setSelectedSegments, setSelected, overAllDisabled, rootStyle, segments
    } = props;

    const ButtonGroup = Button.Group;

    const [meToggle, setMeToggle] = React.useState(true)
    const [gigToggle, setGigToggle] = React.useState(true)
    const [smeToggle, setSmeToggle] = React.useState(true)
    const [bizToggle, setBizToggle] = React.useState(true)
    const [nilToggle, setNilToggle] = React.useState(true)


    const handleSelected = () => {
      let bux_segment = [];
    
      if(meToggle) {
        bux_segment.push(segments.BUx_ME)
      }

      if(gigToggle) {
        bux_segment.push(segments.BUx_GIG)
      }

      if(smeToggle) {
        bux_segment.push(segments.BUx_SME)
      }

      if(bizToggle) {
        bux_segment.push(segments.BUx_BIZ)
      }

      if(nilToggle && window.location.pathname === '/users') {
        bux_segment.push(segments.Unsegmented)
      }

      setSelectedSegments(bux_segment)

    }

    const ButtonTab = () => {
        const status = [
          {
            text: "BUxME",
            value: "me",
            status: meToggle,
          },
          {
            text: "BUxGIG",
            value: "gig",
            status: gigToggle,
    
          },
          {
            text: "BUxSME",
            value: "sme",
            status: smeToggle,
          },
          {
            text: "BUxBIZ",
            value: "biz",
            status: bizToggle,
          },
          {
            text: "No Segment",
            value: 'nil',
            status: nilToggle
          }
        ];
        
        const handleClick = (event) => {
          let value = event.target.value
          console.log(value)
          if(value === "me") {
            setMeToggle(!meToggle)
          }
    
          else if(value === "gig") {
            setGigToggle(!gigToggle)
          }
    
          else if(value === "sme") {
            setSmeToggle(!smeToggle)
          }
    
          else if(value === "biz") {
            setBizToggle(!bizToggle)
          }
          else if(value === "nil") {
            setNilToggle(!nilToggle)
          }
        }
        
        return (
          <div style={rootStyle}>
            <ButtonGroup>
              {
                status.map((btn, i) => {
                  return (
                    <Button
                      hidden={btn.text === 'No Segment' && window.location.pathname !== '/users'}
                      disabled={overAllDisabled}
                      key={btn.value}
                      value={btn.value}
                      onClick={handleClick}
                      className={`
                        admin-btn-pill 
                        ${
                          btn.status ? "selected-value" : 
                          // overAllDisabled ? "disabled-btn" : 
                          "neutral-value"
                          } 
                       `}
                        >
                        {btn.text}
                    </Button>
                  )
                })
              }
            </ButtonGroup>
          </div>
        )
    }

    React.useEffect(() => {
        handleSelected()
    }, [meToggle, gigToggle, smeToggle, bizToggle, nilToggle])

    return(
        <ButtonTab />
    )
}

export default FilterBtn;