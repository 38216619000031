import React from 'react';
import { Modal } from 'antd';

const VoucherModal = props => {

    const {
        modalState, setModalState, Image, segments
    } = props;

    const onCloseModal = () => {
        localStorage.setItem('voucherModalView', true)
        setModalState(false)
    }

    return(
        <Modal
            centered
            footer={null} 
            closable={false}
            visible={modalState}
            width={552}>
                <div className="padding-32">
                    <div align="center">
                        <img src={Image.NewVoucher} alt="voucher" />

                        <div className="top-40 large-bold-text liveColor">
                            A new way to offer discounts with BUx Vouchers!
                        </div>

                        <div className="top-16 modal-sub-text">
                            See available vouchers, 
                            share them to your customers, 
                            and let them enjoy discounted amount for your goods and services.
                        </div>

                        <div className="top-40">
                            <button className={`btn--${segments} btn-height`} onClick={onCloseModal}>
                                Awesome, got it!
                            </button>
                        </div>
                    </div>
                </div>
        </Modal>
    )
}

export default VoucherModal;