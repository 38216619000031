const initialState = {
  cardSelectedKey: 'Accounted',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CARD_SELECTED_KEY':
      return{
        ...state,
        cardSelectedKey: action.payload
      }
    default:
      return state;
  }
}