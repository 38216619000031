const initialState = {
  refundDetails: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_REFUND_DETAILS':
      return {
       ...state,
        refundDetails: action.payload,
      }
    default:
      return state;
  }
}