import React,{ useState, useEffect } from 'react';
import MetaTag from '../../meta_tag/meta_tag';
import { DatePicker, Input, Button, Typography } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import OTC from './recon_enterprise';
import { isMobile } from 'react-device-detect';
import { history } from '../../../store/history';
import MerchantGroups from '../kyb_main/merchant_groups/components/index'
import { useSelector } from 'react-redux';
import ReconStyle from '../../../static/css/Recon'
import axios from 'axios'
import reconServices from './reconServices';
import { message } from 'antd';

const BuxAdvancing = props => {
  const pUid = props.props.match.params.id;
  const [tab, setTab] = useState(0);
  const user = localStorage.getItem('userType');
  const [selected, setSelected] = React.useState('Recon') 
  const ubp_enabled = localStorage.getItem('cc_enabled') == 'UnionBank';
  const xendit_enabled = localStorage.getItem('cc_enabled') == 'Xendit';
  const endpoints = useSelector(state => state.child_endpoints.endpoints);
  const isChild = localStorage.getItem("is_child") === "true"
  const [startDate, setStartDate] = React.useState('')  
  const [startMoment, setStartMoment] = React.useState("")
  const [remarks, setRemarks] = React.useState("")
  const [limit, setLimit] = React.useState("0")
    const rs = ReconStyle()

  async function submitPayout () {
      try {
        const res =  await reconServices.advanceDate(pUid, startDate, limit);
        
        if(res && res.message && res.message === 'Advance Payout endorsed') {
          message.success(res.message);
        } else {
          message.info(res.message);
        }

      } catch (error) {
        console.log('ADVANCING ERROR', error);
        message.error('Error advancing try again.');
      }
    }

  const onStartChange = value => {
      if(value != null){
            setStartMoment(value)
          setStartDate(value.format("MM-DD-YYYY"))
      }
  }


  return (
  <div style={{width: '800px', paddingTop: '50px'}}>
    <div className={rs.mainHeader}>Advance Payout</div>
    <div style={{width: '300px'}}>
      <div>Date</div>
      <DatePicker
          style={{zIndex: 1,width: '300px'}}
          size="large"
          format='MM-DD-YYYY'
          value={startMoment || null}
          placeholder="Start"
          onChange={onStartChange}
      />
    </div>
    <div style={{width: '300px', marginTop: '20px'}}>

      <div>Limit</div>
      <Input
        style={{ width: '300px', height: '40px', marginRight: '12px'}}
        onChange={(e)=> setLimit(e.target.value)}
        value={limit}
        />  
    </div>
    <div style={{width: '300px', marginTop: '20px'}}>


      <button className={rs.activeBtn} onClick={() => submitPayout()}>Submit</button>
          
    </div>      
    
    </div>
  )
};

const styles = {
  root: {

  },
  titleContainer: {
    marginTop: '-2.5%',
    background: '#fff',
    marginLeft: '-100px',
    padding: '3% 200px 0px 150px',
    // padding: '42px 42px 0px 42px',
    // background: '#fff'
  },
  title: {
    backgroundColor: 'transparent',
    width: '100%',
    color: '#000',
    fontWeight: 'bold',
    fontSize: 28,
    display: 'flex',
    alignItems: 'center',
  },
  btnGroup: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '20px 32px 24px 32px',
    borderColor: 'transparent',
    backgroundColor: 'transparent'
  },
  btnTabContainer: {
    padding: '20px, 20px 0 20px',
  },
};

export default BuxAdvancing;