import React, { useEffect } from 'react'
import {Button, Checkbox,Popover, Row, Col} from 'antd'
import {OTC, BANK, MOBILE, CARD_PAYMENT} from '../../components/constants/constants'
import { randomize } from '../../components/constants/constants';

//Icons and Image
import ArrowDown from '../../static/icons/arrow-down.svg'

const userType = localStorage.getItem('userType')
const isAdmin =  !['ME', 'CO', 'SW'].includes(userType)
const isEnterprise = userType == 'CO'
if(userType == 'ME') {
    delete OTC['MLhuillier'];
}
const OTC_LIST = Object.keys(OTC)
const WEB_BANK_LIST = Object.keys(BANK)
const EWALLET_LIST = Object.keys(MOBILE)
const CARD_LIST = Object.keys(CARD_PAYMENT)
const CheckboxGroup = Checkbox.Group

const PaymentMethodsPopup = ({reset, setChannels}) => {
    
    const [otcCheckedList, setOTCCheckedList] = React.useState(OTC_LIST)
    const [webCheckedList, setWebCheckedList] = React.useState(WEB_BANK_LIST)
    const [walletCheckedList, setWalletCheckedList] = React.useState(EWALLET_LIST)
    const [cardCheckedList, setCardCheckedList] = React.useState(CARD_LIST)
    const [checkAllOTC, setCheckAllOTC] = React.useState(true)
    const [checkAllBank, setCheckAllBank] = React.useState(true)
    const [checkAllWallet, setCheckAllWallet] = React.useState(true)
    const [checkAllCard, setCheckAllCard] = React.useState(true)
    const [bnplSelected, setBNPLSelected] = React.useState(true)
    const [snplSelected, setSNPLSelected] = React.useState(true)

    useEffect(() => {
        getAllKeys()
    }, [otcCheckedList, webCheckedList, walletCheckedList, cardCheckedList, bnplSelected, snplSelected])

    useEffect(() => {reset && resetCheckbox()}, [reset])

    const onChangeOtc = list => {
        setOTCCheckedList(list)
        setCheckAllOTC(list.length == OTC_LIST.length)
    };
    const onChangeWeb = list => {
        setWebCheckedList(list)
        setCheckAllBank(list.length == WEB_BANK_LIST.length)
    }
    const onChangeWallet = list => {
        setWalletCheckedList(list)
        setCheckAllWallet(list.length == EWALLET_LIST.length)
    }
    const onChangeCard = list => {
        setCardCheckedList(list)
        setCheckAllCard(list.length == CARD_LIST.length)
    }
    const onCheckAllOtcChange = e => {
        setOTCCheckedList(e.target.checked ? OTC_LIST : [])
        setCheckAllOTC(e.target.checked)
        setChannels(getAllKeys)
    };
    const onCheckAllWebChange = e => {
        setWebCheckedList(e.target.checked ? WEB_BANK_LIST : [])
        setCheckAllBank(e.target.checked)
    }
    const onCheckAllWalletChange = e => {
        setWalletCheckedList(e.target.checked ? EWALLET_LIST : [])
        setCheckAllWallet(e.target.checked)
    }
    const onCheckAllCardChange = e => {
        setCardCheckedList(e.target.checked ? CARD_LIST : [])
        setCheckAllCard(e.target.checked)
    }

    const getListCodes = (list, mapping) => {
        let listCodes = []
        for (const name of list) {
            listCodes.push(mapping[name].code)
        }
        return listCodes
    }

    const resetCheckbox = () => {
        setOTCCheckedList(OTC_LIST)
        setWebCheckedList(WEB_BANK_LIST)
        setWalletCheckedList(EWALLET_LIST)
        setCardCheckedList(CARD_LIST)
        setCheckAllOTC(true)
        setCheckAllBank(true)
        setCheckAllWallet(true)
        setCheckAllCard(true)
        setBNPLSelected(true)
        setSNPLSelected(true)
    }

    const getAllKeys = () => {        
        let all_otc = getListCodes(otcCheckedList, OTC)
        let all_web = getListCodes(webCheckedList, BANK)
        let all_wallet = getListCodes(walletCheckedList, MOBILE)
        let all_card = getListCodes(cardCheckedList, CARD_PAYMENT)
    
        let all_channels = all_otc.concat(all_web, all_wallet, all_card)
        
        if(all_otc.includes('ussc')){
            all_channels.push('bc_ussc')
            all_channels.push('USSC')
        }
        if(all_otc.includes('cvm')){
            all_channels.push('CVM')
        }
        if(all_otc.includes('petnet')){
            all_channels.push('PRHB')
        }
        if(all_otc.includes('BAYD')){
          all_channels.push('bayad_center')
        }
        if(all_otc.includes('RLNT')){
          all_channels.push('bc_rlnt')
        }
        if(bnplSelected){
          all_channels.push('billease')
        }
        if(snplSelected){
          all_channels.push('billease_sn')
        }
    
        if(checkAllOTC && checkAllBank && checkAllWallet && checkAllCard && bnplSelected && snplSelected)
        {
          all_channels.push("All")
        }

        setChannels(all_channels)
    }

    const activePaymentMethod = () => {
        return otcCheckedList != "" || 
            webCheckedList != "" || 
            walletCheckedList != "" ||
            cardCheckedList != "" ||
            // ((isEnterprise || isAdmin) && ccSelected) ||
            ((isEnterprise || isAdmin) && bnplSelected) ||
            ((isEnterprise || isAdmin) && snplSelected)
    }

    const payment_methods = () => (

        <div style={style.paymentMethodCB}>
            <div style={{margin:'10px 0px 10px 0px', fontWeight: '600'}}>Over-the-counter</div>
            <Row>
                <Col span={24}>
                    <Checkbox 
                        indeterminate={otcCheckedList.length && otcCheckedList.length < OTC_LIST.length} 
                        checked={checkAllOTC}
                        onChange={(e)=>onCheckAllOtcChange(e)}
                    >All OTC
                    </Checkbox>
                </Col> 
                <Col>
                    <CheckboxGroup 
                        class="cb_payment_methods"
                        style={{margin:'0px 0px 0px 24px'}} 
                        options={OTC_LIST}
                        value={otcCheckedList} 
                        onChange={(e)=>onChangeOtc(e)}/>
                </Col>
            </Row>
            <div style={{margin:'10px 0px 10px 0px', fontWeight: '600'}}>Web Banking</div>
            <Row>
                <Col span={24}>
                    <Checkbox 
                        indeterminate={webCheckedList.length && webCheckedList.length < WEB_BANK_LIST.length} 
                        checked={checkAllBank} 
                        onChange={(e)=>onCheckAllWebChange(e)}
                    >All Web
                    </Checkbox>
                </Col> 
                <Col>
                    <CheckboxGroup 
                        class="cb_payment_methods"
                        style={{margin:'0px 0px 0px 24px'}} 
                        options={WEB_BANK_LIST} 
                        value={webCheckedList} 
                        onChange={(e)=>onChangeWeb(e)}/>
                </Col>
            </Row>

            <div style={{margin:'10px 0px 10px 0px', fontWeight: '600'}}>E-Wallet</div>
            <Row>
                <Col span={24}>
                    <Checkbox 
                        indeterminate={walletCheckedList.length && walletCheckedList.length < EWALLET_LIST.length} 
                        checked={checkAllWallet}
                        onChange={(e)=>onCheckAllWalletChange(e)}
                    >All E-Wallet
                    </Checkbox>
                </Col> 
                <Col>
                    <CheckboxGroup 
                        class="cb_payment_methods"
                        style={{margin:'0px 0px 0px 24px'}} 
                        options={EWALLET_LIST} 
                        value={walletCheckedList} 
                        onChange={(e)=>onChangeWallet(e)}/>
                </Col>
            </Row>

            <div hidden={!isEnterprise && !isAdmin} style={{margin:'10px 0px 10px 0px', fontWeight: '600'}}>Card Payments</div>
            <Row hidden={!isEnterprise && !isAdmin}>
                <Col span={24}>
                    <Checkbox 
                        indeterminate={cardCheckedList.length && cardCheckedList.length < CARD_LIST.length} 
                        checked={checkAllCard}
                        onChange={(e)=>onCheckAllCardChange(e)} 
                    >All Card Payments
                    </Checkbox>
                </Col> 
                <Col>
                    <CheckboxGroup 
                        class="cb_payment_methods"
                        style={{margin:'0px 0px 0px 24px'}} 
                        options={CARD_LIST} 
                        value={cardCheckedList} 
                        onChange={(e)=>onChangeCard(e)} />
                </Col>
            </Row>

            <div hidden={!isEnterprise && !isAdmin}  style={{margin:'10px 0px 10px 0px', fontWeight: '600'}}>Buy Now Pay Later</div>
            <Row hidden={!isEnterprise && !isAdmin}>
                <Col span={24} key={randomize}>
                    <Checkbox onChange={e => setBNPLSelected(e.target.checked)} checked={bnplSelected}>BillEase</Checkbox>
                </Col>
            </Row>

            <div hidden={!isEnterprise && !isAdmin} style={{margin:'10px 0px 10px 0px', fontWeight: '600'}}>Study Now Pay Later</div>
            <Row hidden={!isEnterprise && !isAdmin}>
                <Col span={24} key={randomize}>
                    <Checkbox onChange={e => setSNPLSelected(e.target.checked)} checked={snplSelected}>BillEase</Checkbox>
                </Col>
            </Row>
        </div> 
    )

    return(
        <Popover content={payment_methods()} trigger="click" placement="bottomLeft">
            <Button 
                style={{
                    ...style.paymentMethod,
                    justifyContent:'space-between',
                    color: activePaymentMethod() ? '#0D3D76' : '#000',
                    border: activePaymentMethod() ? '1px solid #0D3D76' : '1px solid #c5cacf'
                }}>
                <div>Payment Method</div> 
                <img alt="Icon"
                    src={ArrowDown} 
                    style={{
                    marginLeft: '5px', 
                    color: activePaymentMethod() ? '#1DD28B' : '#000'
                }}/>
            </Button>
        </Popover>
    )
}



const style = {
    paymentMethod:{
        zIndex: 1, 
        height: '40px', 
        width: '172px',
        paddingTop: '8px', 
        marginRight: '12px',
        display: 'flex',
    },
    paymentMethodCB:{
        width: '220px', 
        backgroundColor: '#fff', 
        padding: '10px', 
        height: '200px',
        overflowY: 'scroll'
    },
}

export default PaymentMethodsPopup