import React, {useEffect} from 'react'
import { Grid } from "@material-ui/core";
import {Alert, message, Spin, Modal} from 'antd'
import accountStyle from '../../static/css/Account';
import ChangePassword from '../account_settings/profile/changePassword';
import settingServices from './settingServices';
import {backToTop} from '../constants/constants'

const EnterpriseMyProfile = props => {

    const [loading, setLoading] = React.useState(false)
    const [showModal, setShowModal] = React.useState(false)

    const [data, setData] = React.useState([])

    const acct = accountStyle()

    useEffect(() => {
        getData()
    }, [])

    async function getData(){
        try{
            let res = await settingServices.getProfileDetails();

            if(res.status == "failed"){
                message(res.message);
                backToTop();
            }
            else{
                setData(res);
                setLoading(true);
            }
            
        }
        
        catch(error){
            console.log(error)
        }
    }

    async function changeMobile(){

        let res = await settingServices.changeMobile();
            
        if(res.status == "success"){
            setShowModal(true)
        }
    }

    const myInfo = [
        {
            'placeholder': 'Name:',
            'value': data.first_name+" "+data.last_name
        },
        {
            'placeholder': 'Role:',
            'value': data.role
        },
        {
            'placeholder': 'UID - User ID:',
            'value': data.bux_id
        },
        {
            'placeholder': 'MID - Merchant ID:',
            'value': data.user_data ? data.user_data.mid : ''
        },
        {
            'placeholder': 'MCC -Merchant Category Code:',
            'value': data.user_data ? data.user_data.mcc : ''
        },
    ]

    const contact_information = [
        {
            'placeholder': 'Email Address:',
            'value': data.email
        },
        {
            'placeholder': 'Mobile Number:',
            'value': `+63${data.contact}`,
            'btn':  <a onClick={() => changeMobile()} 
                        style={{color: '#f5922f',opacity: data.contact ? '' : 0.5, cursor: data.contact ? 'pointer' : 'not-allowed' }} 
                        disabled={data.contact == ''}>Change</a>

        },
    ]

    return(
        <div className={acct.personal}>

            <Modal
                width={360}
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={null}>
                    <div>
                        <div className={acct.changeModalHeader}>
                            Change Mobile Number
                        </div>

                        <div className={acct.changeModalText}>
                        A temporary link will be sent to your registered email address 
                        and you may use it to update your mobile number.
                        </div>

                        <button className={acct.changeModalBtn} onClick={() => setShowModal(false)}>
                            Got It
                        </button>
                    </div>

            </Modal>

            <div className={acct.infoDiv}>
                <div className={acct.headerProfile}>
                    My Information
                </div>

                <div className={acct.personalInfo} align='left'>
                {
                        myInfo.map((item, i) => {
                            return( item.value&&
                                <Grid key={i} container>
                                    <Grid item lg={4} xs={12} style={{borderBottom: '1px solid #E6EAF0'}}>
                                        <div className={acct.piHeader} style={{color: '#000000'}}>
                                            {item.placeholder}
                                        </div> 
                                    </Grid>
                                    <Grid item lg={8} xs={12} style={{borderBottom: '1px solid #E6EAF0'}}>
                                    
                                        <div className={acct.piHeader} style={{color: '#000000'}}>
                                            <div hidden={loading}>
                                            <Spin size="small" />
                                            </div>
                                            <div  hidden={!loading}>
                                            {item.value ? item.value: '- -'}
                                            </div>
                                        </div> 
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </div>
            </div>

            <div className={acct.infoDiv}>
                <div className={acct.headerProfile}>
                    Contact Information
                </div>

                <div className={acct.personalInfo} align='left'>
                {
                        contact_information.map((item, index) => {
                            return(
                                <Grid key={index} container>
                                    <Grid item lg={4} xs={12}  style={{borderBottom: index === 0? '' : '1px solid #E6EAF0'}}>
                                        <div className={acct.piHeader} style={{color: '#000000'}}>
                                            {item.placeholder}
                                        </div> 
                                    </Grid>
                                    <Grid item lg={index ===0 ? false : 4} xs={12}  style={{borderBottom: index === 0? '' : '1px solid #E6EAF0'}}>
                                    
                                        <div className={acct.piHeader} style={{color: '#000000'}}>
                                            <div hidden={loading}>
                                            <Spin size="small" />
                                            </div>
                                            <div  hidden={!loading}>
                                            {item.value ? item.value: '- -'}
                                            </div>
                                        </div> 
                                    </Grid>

                                    <Grid item lg={index === 0 ? false : 4} xs={12} style={{borderBottom: '1px solid #E6EAF0'}}>
                                    <div className={acct.password} hidden={index === 0}>
                                        {item.btn}
                                    </div>
                                </Grid>
                                </Grid>
                            )
                        })
                    }

                    <ChangePassword />
                </div>
            </div>
        </div>
    )
}

export default EnterpriseMyProfile;
