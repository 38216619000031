import React from 'react'
import {Card, Input, Button, Collapse, Icon, message, Typography, Divider} from 'antd'
import SentroLogo from '../../static/icons/sentro.svg'
import WooCommerceLogo from '../../static/icons/woo_commerce.svg'
import PrestaShopLogo from '../../static/icons/prestashop.svg'
import Shopify from '../../static/icons/shopify.svg'
import {history} from '../../store/history'
import axios from 'axios'
import {Handle401} from '../../components/handle401/handle401'
import {isMobile} from 'react-device-detect';
import MetaTag from '../../components/meta_tag/meta_tag'

import Arrow from '../../static/icons/arrow_down_orange.svg'
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import SupportImg from '../../static/img/support.svg';
import PluginsMain from '../../components/integration/plugins_tab/plugins_main'

const { Panel } = Collapse;
const buxpluginwpver = process.env.REACT_APP_WP_PLUGIN_VERSION;
const buxpluginpsver = process.env.REACT_APP_PS_PLUGIN_VERSION;
const buxpluginzip = 'https://wordpress.org/plugins/bux-woocommerce/';
const buxpluginpszip = process.env.REACT_APP_STATIC_BASE_URL + "static/plugins/bux-plugin-ps-"+buxpluginpsver+".zip";


const Accordion = withStyles({
    root: {
      border: '1px solid #E6EAF0',
      borderRadius: '10px',
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
      backgroundColor: '#fff',
      borderRadius: '10px'
    },
    content: {
      margin: '18px 0',
      '&$expanded': {
        margin: '18px 0',
      },
    },
    expanded: {
    },
})(MuiAccordionSummary);
  
const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);


class LinkStores extends React.Component{
    constructor(props) {
        super(props)
    
        this.state = {
            creds: {},
            apiKey: '',
            apiSecret: '',
            clientId: '',
            wcDomain: '',
            psDomain: '',
            token: '',
            key: '',
            secret: ''
        }
      }

    componentDidMount(){
        let current_path = window.location.pathname
        if(localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") === null){
            history.push('/login?next='+current_path) 
        }
        else{
            if(localStorage.getItem("userType") == "AD" || localStorage.getItem("userType") == "OP"){
                history.push('/orders')
            }
            else if(localStorage.getItem("userType") == "PS"){
                history.push('/orders')
            }
            else if(localStorage.getItem("userType") == "PF"){
                history.push('/recon/7_eleven')
            }
            else if(localStorage.getItem("userType") == "MA"){
            history.push('/overview')
            }
            else if(localStorage.getItem("userType") === "ME" || localStorage.getItem("userType") === "CO"){
                if(isMobile)
                {
                    history.push('/mobile/link_stores')
                    this.fetchAccessToken()

                    
                }else{
                    this.fetchAccessToken()
                }
            }
            else{
                history.push('/dashboard')
            }
        }
    }

    set_wc_domain = async() =>{

        let yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
          };


        let details = {
            'domain': this.state.wcDomain
          };

        try{
            let response = await axios.post(
                process.env.REACT_APP_API_URL + '/api/woocommerce/set_domain/', details, yourConfig
            );

            if(response.data.status == "success"){
                message.success('Successfully set domain');
            }else{
                message.error('Could not set domain');

            }

        }
        catch (error) {
            message.error('Could not set domain');
        }
        

    }


    fetchAccessToken = async() =>{
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try{
            let response = await axios.get(
                process.env.REACT_APP_API_URL + '/api/fetch_user_credential/', yourConfig
            );
    
            if(response.data.status == "success"){
                this.setState({
                    creds: response.data,
                    apiKey: response.data.api_key,
                    apiSecret: response.data.api_secret,
                    clientID: response.data.client_id,
                    wcDomain: response.data.wc_domain,
                    psDomain: response.data.ps_domain,
                    token: response.data.shopify_token && response.data.shopify_token,
                    secret: response.data.shopify_secret && response.data.shopify_secret,
                    key: response.data.shopify_key && response.data.shopify_key,
                })
                console.log(response)
            }
            else{
                return false
            }
        }
        catch (error) {
            if((error.response)&&(error.response.status == 401)){
                Handle401()
            }
        }
        
    }

    saveCreds = async() => {
        try{

            const yourConfig = {
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            }        

            const params = {'shopify_token': this.state.token,
                            'shopify_key': this.state.key,
                            'shopify_secret': this.state.secret}
            let response = await axios.post(
                process.env.REACT_APP_API_URL + '/api/settings/',
                params,
                yourConfig
            );

            if(response.data.status == "success"){
                message.success('Successfully updated settings')
            }else{
                message.error('Could not update settings')
            }


        }catch(error){
        }

    }


    render(){
        const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
        const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
        const isDell1 = window.screen.width * window.devicePixelRatio === 1366 && window.screen.height * window.devicePixelRatio === 768;
        const isMac = window.screen.width * window.devicePixelRatio === 1440 && window.screen.height * window.devicePixelRatio === 900;

        const isDellReso = (isSmallReso || isDell || isDell1)
        const segments = localStorage.getItem("segments");

        return(
        <div className={isDell ? "top-12" : "top-48"} style={{marginRight: isDellReso ? '2vw' : isMac ? '5vw' : isMobile ? 0 : '10vw', padding: isMobile ? 20 : 0, backgroundColor: isMobile && '#F4F6F9'}} >
            <MetaTag title="Link Store"/>
            <div align="center">
                <div className="creds-note-div">
                    <div className="screen-title-text" hidden={isMobile}> 
                        Link Stores
                    </div>

                    <div className="top-16">
                        Integrate your existing e-commerce stores and keep track of orders and sales in one place.
                    </div>
                </div>

                <PluginsMain segments={segments} creds={this.state.creds} resetSecretSuccess={this.fetchAccessToken} />
            </div>
        </div>
        )
    }
}

export default LinkStores