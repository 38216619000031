import React,{ useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Typography, Spin } from "antd";
import { LoadCardSuccess, BuxRoundBlue } from '../../../static/icons/dashboard_icons'
import { Card } from '../../../static/icons/sidebar';
import eonImage from "../../../static/images/eon_text_image.svg";
import cardActions from '../cardActions';
import cardServices from '../cardServices';

const SuccessLoadModal = ({ closeModal, visible, buxBalance, eonBalance, prevBuxBalance }) => {
  const visibleSuccessModal = useSelector(state => state.card['success_load_modal']);
  const refreshBal = useSelector(state => state.card['refresh_balance']);
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false)
  const [bux_Balance, setBuxBalance] = useState(buxBalance);
  const [eon_Balance, setEonBalance] = useState(eonBalance);
  const segments = localStorage.getItem('segments');

  const getBalance = async () => {
    try {
      const response = await cardServices.getCardInfo();
      setBuxBalance(response.bux_balance)
      setEonBalance(response.account.availableBalance)
    } catch (error) {
      console.log(error)
    }
  }

  if(refreshBal) {
    getBalance()
    dispatch(cardActions.hideModal('refresh_balance'));
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'PHP',
    minimumFractionDigits: 2
  })

  return (
    <Modal visible={visibleSuccessModal} onCancel={closeModal} footer={null} maskClosable={false} centered width={359} bodyStyle={{padding: 20}}>
      <div style={styles.header}>
        <img src={LoadCardSuccess} alt="No Image" style={{ paddingTop: 32}} />
        <Typography style={{fontSize: 24, color: '#2B2D33', padding: 16}}>Successfully Loaded to your EON Physical Visa Card!</Typography>
      </div>
      <label style={{fontSize: 12}}>Update Balance:</label>
      <div style={styles.walletDiv}>
          <div style={styles.buxAmount}>
            <img src={BuxRoundBlue} style={{ height: 28}}/>
            <Typography style={{paddingLeft: 10}}>BUx Wallet:</Typography>
          </div>
          <strong>{ bux_Balance ? formatter.format(bux_Balance)
            : <Spin size='small' /> }
          </strong>
        </div>
        <div style={styles.walletDiv}>
          <div>
            <img src={Card} style={{ backgroundColor: '#0A315E', borderRadius: '50%'}}/>
            <img src={eonImage} style={{ width: 58}} />
          </div>
          <strong>PHP {eon_Balance ? eon_Balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :  <Spin size='small' />}</strong>
        </div>
      <button className={`btn--${segments}`} disabled={bux_Balance === prevBuxBalance} style={{...styles.btn, opacity: bux_Balance === prevBuxBalance ? '50%' : null }} onClick={closeModal} >{ bux_Balance === prevBuxBalance ? 'Updating New Balance' : 'Done'}</button>
    </Modal>
  )
}

const styles = {
  root: {

  },
  btn: {
    margin: '20px 0px 0px 0px',
    height: 40,
    width: '100%',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
  },
  walletDiv: {
    backgroundColor: 'rgba(29, 130, 184, 0.1)', 
    borderRadius: 10, 
    padding: '20px 0px 20px 0px', 
    textAlign: 'center', 
    margin: '4px 0 0 0', 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    padding: '12px 20px'
  },
  buxAmount: {
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center', 
    justifyContent: 'center'
  },
  header: {
    display: 'flex', 
    alignItems: 'center', 
    flexDirection: 'column', 
    padding: 8, 
    textAlign: 'center'
  }
}

export default SuccessLoadModal;