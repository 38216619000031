export const titleStyle = {
    fontStyle: 'bold',
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: '700',
    fill: 'Solid',
    color: '#2b2d32',
    marginBottom: '20px'
}
export const headerStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000'
}
export const labelStyle={

    fontStyle: 'regular',
    fontSize: '12px',
    lineHeight: '32px',
    fill: 'solid',
    color: '#000',
}

export const textStyle = {
    
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#FFFFFF'
}

export const menuStyle = {

    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#2b2d33'
}

export const ref_code_label = {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '20px',
    color: '#000000',
}

export const code_style = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#2b2d33'
}

export const linkStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#1D82B8',
}

export const linkDiv = {
    width: '250px',
    marginTop: '10px',
    textDecoration: 'underline',
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
}

export const tableHeader = {

    backgroundColor: '#0D3D76', 
    padding: '5%', 
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF'
}
export const dashboardStyle = {
    
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    color: '#000000',
    marginBottom: '10px'

}

export const fontHeader = {
    color: '#1D82B8', 
    fontWeight: 'bold', 
    fontSize: '16px', 
    lineHeight: '19px',
    padding: '1%'
}

export const bord = {
    border: '1px solid'
}

export const link = {
    color: '#000000',
    fontWeight: 500,
}

export const generateBtn = {
    color: '#fff',
    background: '#F5922F',
    border: '1px solid #F5922F'

}

export const generateBtn1 = {
    color: '#fff',
    background: '#F5922F',
    border: '1px solid #F5922F',
    opacity: '0.5'      
}

export const customerHeader = {
    fontStyle: 'normal',
    fontWeight: '600', 
    fontSize: '16px', 
    lineHeight: '24px',
    color: '#2b2d33', 
}

export const customerName = {
    fontStyle: 'normal',
    fontWeight: 'normal', 
    fontSize: '16px', 
    lineHeight: '24px',
    color: '#2b2d33', 
}

export const shareViaStyle = {

    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#333333',
    opacity: '0.8'
}

export const copyOrShare = {

    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#54575F',
}

export const profilePic = {
    width: '28px', 
    height: '28px',
    borderRadius: '50%',
}

export const paymetSchedText = {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    color: '#2b2d32',
    paddingTop: '5px'
}