import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { message } from 'antd';
import RebateTable from './rebate_table';
import RebateToggle from './rebate_toggle';
import RebateModal from './rebate_modal';
import MetaTag from '../../../meta_tag/meta_tag';


const Rebates = props => {
    
    const pUid = props.props.match.params.id;
    const [rebateStatus, setRebateStatus] = useState(false);
    const [modalData, setModalData] = useState();
    const [modalState, setModalState] = useState(false)
    const [data, setData] = useState([]);
    const [editTableState, setEditTableState] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    const [saveStatus, setSaveStatus] = useState(false)

    const yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    };

    const REACT_API_URL = process.env.REACT_APP_API_URL; 

    const handleEditState = () => {
        setEditTableState(!editTableState);
        setErrorMessage("")
    }

    const showData = (data, text) => {
        if(text !== "table") {
            setModalState(true)
        }

        setModalData(data)
    }

    const handleChange = (e) => {
        setErrorMessage("")
        setSaveStatus(false)
        let { name, value} = e.target
        const list = [...data]
        // find index of an object in table
        const index = list.findIndex(
            obj => "tier" in modalData ? obj.tier === modalData.tier : 
                        obj.tier_minimum === modalData.tier_minimum
                    )
        value = value == "" ? 0 : value
        list[index][name] = parseFloat(value)
        setData(list)
    }

    const selectText = (data, type) => {
        const input = document.getElementById(`${type}-rebate-${data.tier}`)
        input.focus()
        input.select()
    }

    const handleDeleteTier = async (record) => {
        try{

            let url = REACT_API_URL + '/api/admin/rebates/';
            let response = await axios.delete(url, {
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                },
                data: {
                    user_id: pUid,
                    tier: record.tier
                }
            });
            
            if(response.data.status === 'success') {
                message.destroy()
                message.success(response.data.message, 3)
                getRebatesList()
            }

        }

        catch (error) {
            message.destroy()
            if(error.response.data.hasOwnProperty("detail")) {
                message.error(error.response.data.detail, 3)

            }
            else {
                message.error(error.response.data.message, 3)
            }
        }
    }


    const handleRebateSwitch = async () => {
        try {
            let params = {
                user_id: pUid,
                enabled: !rebateStatus
            }

            let url = REACT_API_URL + '/api/admin/rebates/settings/'
            let response = await axios.post(url, params, yourConfig)
            
            if(response.data.status === 'success') {
                let enabled = response.data.is_enabled
                message.destroy()
                message.success(`Rebates Status ${enabled ? 'Enabled' : 'Disabled'}`)
                setRebateStatus(enabled)
                getRebatesList()
            }

        }

        catch (e) {
            console.log(e)
        }
        setRebateStatus(!rebateStatus);
    }

    const getRebateStatus = async () => {
        try {
            let url = REACT_API_URL + `/api/corporate/settings/${pUid}`;
            let response = await axios.get(url, yourConfig)

            setRebateStatus(response.data.rebates_settings)
            getRebatesList()

        }

        catch (e) {
            console.log(e)
        }
    }

    const submitData = async () => {
        try {
            let params = {
                user_id: pUid,
                rebates: data,
            }
            
            let url = REACT_API_URL + '/api/admin/rebates/'
            let response =  await axios.post(url, params, yourConfig)
            let response_status = response.data.status;


            if(response_status === 'success') {
                setModalState(false)
                message.destroy()
                message.success('Changes have been saved', 3)
                setErrorMessage("")
                setEditTableState(false)
                getRebatesList()
            }

            else if (response_status == 'failed') {
                setErrorMessage(response.data.message)
            }


        }

        catch (e) {
            setErrorMessage(e.response.data.message)

        }
    }

    const getRebatesList = async () => {
        try {
            let url = REACT_API_URL + `/api/admin/rebates/${pUid}`
            let response = await axios.get(url, yourConfig)

            console.log(response.data)

            if(response.data.status === 'success') {
                let data = response.data.data
                let sorted_data = data.sort((a,b) => a.tier - b.tier);
                setData(sorted_data)
            }

        }

        catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getRebateStatus()
    }, [])

    return(
        <div>
            <MetaTag title="Rebates" />
            <RebateToggle rebateStatus={rebateStatus} handleRebateSwitch={handleRebateSwitch} />

            <div className="rebate-div top-32">
                <RebateTable data={data} showData={showData} editState={editTableState} 
                handleEditState={handleEditState} handleTableChange={handleChange}
                submitData={submitData} errorMessage={errorMessage} 
                getRebatesList={getRebatesList} handleDeleteTier={handleDeleteTier}
                selectText={selectText} rebateStatus={rebateStatus} setData={setData}
                saveStatus={saveStatus} setSaveStatus={setSaveStatus}
            />
            </div>
            {
                modalState && 
                <RebateModal data={modalData} 
                    modalState={modalState} 
                    setModalState={setModalState} 
                    handleChange={handleChange}
                    submitData={submitData}
                    selectText={selectText} />
            }
        </div>
    )
}

export default Rebates;