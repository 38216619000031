import React from 'react'
import { Drawer } from 'antd'
import accountStyles from '../../../static/css/Account'

const CustomInfoDrawer = props => {

    const ac = accountStyles();

    const {
        showDrawer, setShowDrawer, setCustomDrawer, customImg, title,
    } = props

    function showCustomDrawer() {
        setCustomDrawer(true)
        setShowDrawer(false)
    }

    return (
        <Drawer
            placement="bottom"
            width="100%"
            height="100%"
            visible={showDrawer}
            onClose={() => setShowDrawer(false)}>
            
            <div align='center'>
                <h1 style={{marginTop: '64px'}}>Custom Amount List</h1>
                <div>
                    <img src={customImg} alt="Custom Amount" />
                </div>
                <div className={ac.customModalText}>
                    By Creating a customize Amount list to your Checkout page, 
                    Your customer can easily choose from your desired requested amount without typing and worrying the right amount.
                </div>
                <div style={{marginTop: 16}}>
                    <button className={ac.createcustomListBtn} onClick={() => showCustomDrawer()}>
                        + Create Custom List
                    </button>

                    <div style={{marginTop: 8}}>
                        <a className={ac.qrLinkText} onClick={() => setShowDrawer(false)}>Not Now</a>
                    </div>
                </div>
            </div>
        </Drawer>
    )
}

export default CustomInfoDrawer;

