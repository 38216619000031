import React from "react";
import { Alert } from "antd";
import "antd/dist/antd.css";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import alertStyle from "../../static/css/Alert";

const BuxAlert = props => {
  const a = alertStyle();
  let alert = useSelector(state => state.alert);
  return (
    <div style={{ display: alert.showAlert ? "block" : "none", marginBottom: '10px', width: props.width ? props.width : '100%'}} className={a.alertBox}>
      <span> {alert.message} </span>
    </div>
  );
};
export default BuxAlert;
