import React from 'react'
import {isMobile} from 'react-device-detect';
import MobileLearnMore from './learn_more_mobile'
import WebLearnMore from './learn_more_web'
class LearnMorePage extends React.Component{

    render(){
        return(
            <div>
                {
                    isMobile ? <MobileLearnMore/> 

                    :

                    <WebLearnMore/>

                }
            </div>
        )
    }
}

export default LearnMorePage 