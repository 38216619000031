import { makeStyles } from '@material-ui/core';

const pricingStyle = makeStyles(theme => ({
    //WEb 

    mainBody: {
        fontFamily: 'Inter',
        paddingBottom :'50px',
        [theme.breakpoints.down("sm")]: {
            padding: '0px 15px 15px 15px',
            overflow: 'hidden',
            background: '#f5f5f4'
        }
    },

    header: {
        width: '900px',
        fontWeight: 'bold',
        fontSize: '25px',
        lineHeight: '32px',
        color: '#000000',
        [theme.breakpoints.down("sm")]: {
            width: '100%',

        }
    },

    tabs: {
        marginTop: '32px'
    },

    cards:{
        border: '1px solid #e6eaf0',
        boxSizing: 'border-box',
        background: '#fff',
        marginBottom: '20px',
    },

    cardHeader:{ 
        padding: '30px 0px 30px 0px',
        borderBottom: '1px solid #000000',
        [theme.breakpoints.down("sm")]: {
            padding: 30

        }
    
    },

    cardInfo: {
        borderBottom: '1px solid #E6EAF0',
        padding: '20px'
    },

    cardPlaceholder: {
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2b2d33',
    },

    cardFee: {
        fontSize: '16px',
        lineHeight: '19px',
        color: '#000000',
        fontWeight: 'bold'
    },

    cardVal: {
        fontSize: '16px',
        lineHeight: '19px',
        color: '#54575F',
        fontWeight: 'bold'
    },

    cardVal2: {
        fontSize: '16px',
        lineHeight: '19px',
        color: '#54575F',
    },

    menu: {
      alignItems: 'center',
      
    },

    btnToTop: {
        background: '#f5f5f4',
        border: '1px solid #1d82b8',
        borderRadius: '4px',
        boxSizing: 'border-box',
        height: '50px',
        width: '100%',
        color: '#1d82b8',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '24px',

    },


    payment: {
        marginTop: '32px',
        width: '900px',
    },

    orderDetail: {
        color: 'black',
        '&:hover': {
            color: '#1d82b8',
            textDecoration: 'underline'   
        }
    },

    subPayment: {
        marginBottom: '20px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
        color: '#000000',
    },

    subDes:{
      marginTop: 16,
      paddingBottom: 32,  
    },

    paymentDiv: {
        padding: '32px',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
        backgroundColor: '#fff',
    },

    pdHeader: {
        padding: '12px',
    },

    pdDetails: {
        borderTop: '1px solid #2b2d33',
        border: '1px solid #E6EAF0',
        borderRadius: '4px',
        padding: '25px 25px 0px 25px',
    },

    fee: {
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#000000'
    },

    limit: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
        color: '#54575F'
    },

    name: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
        color: '#54575F',
        // marginTop: '10px'
        alignItems: 'center',
        margin: 0
    },

    amount: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#54575F',
        // marginTop: '10px',
        alignItems: 'center',
        margin: 0
    },

    funds: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#54575F'
    },

    type: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#54575F'
    },

}));

export default pricingStyle