import React, {useEffect} from 'react';
import {Spin, Button} from 'antd';
import {history} from '../../store/history'
import axios from 'axios'

const MessengerLink = props => {


    useEffect(() => {
        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            loadFbPlugin();
        }else{
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let next = params.get('next');

            if(next != null){
              window.location.href = next

            }
            else{
                window.location.href = "/mobile/generate_code";

            }
            
        }

    }, []);

    async function sendMessengerID (uid) {
        let response = await axios.post(
                process.env.REACT_APP_API_URL + "/api/messenger/",
                {'uid': uid}
        );

        if(response.data.status == "success"){

            let token = response.data.access_token;
            let userDetails = response.data.user_details;

            localStorage.setItem("jwtToken", token);
            localStorage.setItem("firstName", userDetails.first_name);
            localStorage.setItem("lastName", userDetails.last_name);
            localStorage.setItem("middleName", userDetails.middle_name);
            localStorage.setItem("suffix", userDetails.suffix);
            localStorage.setItem("username", userDetails.username);
            localStorage.setItem("email", userDetails.email);
            localStorage.setItem("contact", userDetails.contact);
            localStorage.setItem("balance", userDetails.balance);
            localStorage.setItem("userType", userDetails.user_type)
            localStorage.setItem("isCompleted", userDetails.is_completed)
            localStorage.setItem("isVerified", userDetails.is_verified)

            let search = window.location.search;
            let params = new URLSearchParams(search);
            let next = params.get('next');

            if(next != null && next.length > 0 && next[0] == '/') {
              window.location.href = next

            }
            else{
                window.location.href = "/mobile/generate_code";

            }
        }else{
            let search = window.location.search;
            window.location.href = '/mobile/login'+search;
        }
    }


   function loadFbPlugin() {
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'Messenger'));
        loadMessengerExt();
    }



   function loadMessengerExt() {

    window.extAsyncInit = function () {
        let search = window.location.search;
        window.location.href = '/mobile/login'+search;
        //  window.MessengerExtensions.getContext(process.env.REACT_APP_FB_ID ,function success(thread_context) {
        //     // sendMessengerID(thread_context.psid);
        // }, function error(err, errorMessage) {

        //     window.location.href = '/mobile/login'+search;

        // });
    
    };

    }
    const verifyStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '34px',
        color: '#2B2D33',
        marginBottom: '10px'
    }

    const textStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.01em',
        color: '#2B2D33',
        opacity: '0.8',
        marginBottom: '20px'
    }


    return(
        <div style={{display:'flex', justifyContent: 'center'}}>
            <div style={{paddingLeft: '57px',paddingRight: '57px', paddingTop: '10vh', justifyContent: 'center', alignItems:'center', width: '559px'}}>

                <div>
                    <div style={verifyStyle}>Account Linking</div>
                    <div style={textStyle}>Checking messenger account. Please wait ...</div>
                    <div style={{display:'flex', justifyContent: 'center'}}>
                        <Spin size="large" />
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default MessengerLink