import React, {useRef} from 'react'
import * as Images from '../../../static/images/marteting_promotions/index'
import { Card, Typography } from 'antd'
import { isMobile, isTablet } from 'react-device-detect'
import useScrollBox from './scrollabe_div'
import PropTypes from 'prop-types'

function MarketPromotion(props, {smallReso}) {
    const { isDellReso } = props;
    const scrollWrapperRef = useRef();
    const { isDragging } = useScrollBox(scrollWrapperRef);

    const isSmallReso = isMobile || isTablet || smallReso

    let promotion = [
        {
            title: 'Sign up for a FREE Sentro account now!',
            background: Images.Sentro,
            link: 'https://sentro.ph/sign_up?&utm_source=bux.ph&utm_medium=referral&utm_campaign=nakasentro&utm_content=sentrowebsitebanner'
        },
        {
            title: 'Loan up to Php 20M with SeekCap',
            background: Images.Seekcap,
            link: 'https://www.seekcap.ph/?&utm_source=bux.ph&utm_medium=referral&utm_campaign=buxxseekcap&utm_content=buxseekcapoffer'
        },
        {
            title: 'Low-cost additional business for you. Sign up now!',
            background: Images.I2I,
            link: 'https://www.i2i.ph/demo?&utm_source=bux.ph&utm_medium=referral&utm_campaign=kayamoi2i&utm_content=i2imatmwebsitebanner'
        }
    ]


    return (
        <div style={{width: isSmallReso ? '85vw' : isDellReso ? '60vw' : '54vw', paddingBottom: isMobile ? 64 : 0}}>
            <div style={styles.exclusiveText}>
                <strong>Exclusive for you!</strong>
            </div>

            <div style={{...styles.carouselDiv, maxWidth: isSmallReso ? '85vw' : ''}} ref={scrollWrapperRef}>
                <div style={{ pointerEvents: isDragging ? 'none' : undefined }}>
                {
                    promotion.map((item, i) => {
                        return(
                            <Card
                                key={i}
                                hoverable
                                cover={<img src={item.background} style={styles.cardImg} />}
                                style={styles.card}
                                bodyStyle={{ padding: '12px 8px' }}
                                onClick={() => window.open(item.link)}
                            >
                                <Typography style={styles.cardDesc}>{item.title}</Typography>
                            </Card>
                        )
                    })
                }
                </div>
            </div>
        </div>
    )
}

const styles = {
    root: {
        width: '48vw',
        display: 'flex',
        height: 158,
        justifyContent: 'space-between',
        position: 'relative',

    },

    exclusiveText: {
        padding: 0,
        marginTop: isMobile ? 0 : 24,
        color: '#0D3D76', 
        textAlign: 'left'
    },

    carouselDiv:{
        maxWidth: '54vw',  
        marginTop: isMobile ? 0 : 20,
        height: '100%', 
        position: 'relative', 
        overflow: isMobile ? 'auto' : 'hidden', 
        whiteSpace: 'nowrap',
    },

    card: {
        display: 'inline-block',
        marginRight: 12,
        borderRadius: 10,
        height: '100%',
        width: isMobile ? '93%' : '21vw',
        wordWrap: 'overflow-wrap',

    },
    cardImg: {
        borderRadius: '10px 10px 0px 0px',
        height: '100%'
    },
    cardDesc: {
        fontSize: isMobile ? '3vw' : '0.8vw',
        lineHeight: '21px',
        color: '#000',
        fontWeight: '600',

    },
}

// MarketPromotion.propTypes = {
//     children: PropTypes.node.isRequired,
//   };

export default MarketPromotion
