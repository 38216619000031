import React from 'react';
import { BankIconBlue, RoundCheck } from '../../../static/images/test_mode';
import { Card, Typography } from 'antd';

const SuccessCard = (props) => {
    const details = props.details;
    return (
        <div style={styles.root}  hidden={props.hidden}>
            <div style={{textAlign: 'center'}}>
                <img alt='No Image' src={RoundCheck} />
                <Typography style={styles.title}>
                    Successful Payment
                </Typography>
            </div>
            <Card style={styles.cardRoot} bodyStyle={styles.cardBody}>
                <img alt='No Image' src={BankIconBlue} style={styles.bankIcon} />
                <div style={{textAlign: 'center'}}>
                    <Typography style={styles.normalBold}>Amount Paid</Typography>
                    <Typography style={{...styles.amount, fontSize: details&&details.amount.length > 8 ? 22 : 28}}>₱ {details ? parseFloat(details.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '520.00' }</Typography>
                    <Typography style={styles.name}>Merchant Name: <b>{details ? details.name : 'EBETH DENCEL'}</b></Typography>
                </div>
            </Card>
        </div>
    )
}

const styles = {
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '62px 0'
    },
    title: {
        color: '#1DD28B',
        fontSize: 16,
        fontWeight: 'bold',
    },
    cardBody: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        padding: 0,
    },
    amount: {
        fontSize: 28,
        fontWeight: 'bold',
        color: '#0D3D76'
    },
    normalBold: {
        fontWeight: 'bold',
        fontSize: 16,
        color: '#2B2D33',
    },
    name: {
        fontSize: 12,
        color: '#54575F',
    },
    cardRoot: {
        margin: 12,
        borderRadius: 20,
        border: '1px solid #E6EAF0',
        backgroundColor: '#FFF',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        padding: '16px 36px',
    },
    bankIcon: {
        paddingRight: 24
    }
}

export default SuccessCard;