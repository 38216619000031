import { fetchShipmentsNow } from "./shipmentsService";

export const GetShipmentsActionTypes = {
    GET_SHIPMENTS_REQUEST: "@@GET_SHIPMENTS_REQUEST",
    GET_SHIPMENTS_SUCCESS: "@@GET_SHIPMENTS_SUCCESS",
    GET_SHIPMENTS_FAILED: "@@GET_SHIPMENTS_FAILED",
  };

  export function fetchShipments() {
    return async (dispatch) => {
      try {
        dispatch({ type: GetShipmentsActionTypes.GET_SHIPMENTS_REQUEST });
        let response = await fetchShipmentsNow();
        dispatch({
          type: GetShipmentsActionTypes.GET_SHIPMENTS_SUCCESS,
          payload: response.data
        });

      } catch (error) {
        dispatch({
          type: GetShipmentsActionTypes.GET_SHIPMENTS_FAILED
        });
        console.log(error.message)
      }
    };
  }
