import React from 'react'
import {Modal} from 'antd'
import * as Style from './payout_drawer_style'
import PayoutIcon from '../../static/icons/pmodal.svg'

const PayoutModal = props => {

    const [openModal, setOpenModal] = React.useState(true)
    const segments = localStorage.getItem('segments');

    function done() {
        window.location.reload();
    }
    // window.location.reload();
    return(
        <div>
             <Modal
                width={380}
                visible={props.openModal}
                onCancel={()=>setOpenModal(false)}
                footer={null}
                afterClose={props.onClose}>
                
                    <div align='center' style={{marginTop:'32px'}}>
                        <img src={PayoutIcon} />

                        <div style={{marginTop: '16px'}}>
                            <div style={Style.modalHeaderStyle}>Funds Successfully on process!</div>
                        </div>

                        <div style={Style.modalCardStyle}>
                            <div style={Style.bank}>
                                <div>
                                    {props.bank}
                                </div>

                                <div style={Style.primaryStyle} hidden={!props.primary}>
                                    Primary
                                </div>
                            </div>

                            <div style={Style.accountStyle}>
                                <div style={{marginBottom: 4}}>Account Name: <span style={Style.modalHeaderStyle}>{props.accountName}</span></div>
                                <div>Account Number: <span style={Style.modalHeaderStyle}>{props.accountNumber}</span></div>
                            </div>

                            <div style={Style.amountStyle}>
                                <div>
                                    Amount: 
                                </div>

                                <div style={Style.modalHeaderStyle}>
                                    PHP {props.amount}
                                </div>
                            </div>
                        </div>
                    
                       <div style={{textAlign: 'right'}}>
                            <button className={`btn--${segments}`} onClick={() => done()}>
                                Done
                            </button>
                       </div>
                    </div>
            </Modal>
        </div>
    )   
}


export default PayoutModal