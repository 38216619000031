import {
    GET_RECON_BEGIN,
    GET_RECON_SUCCESS,
    GET_RECON_ERROR,
    GET_SUBWALLET_PAYMENT_BEGIN,
    GET_SUBWALLET_PAYMENT_SUCCESS,
    GET_SUBWALLET_PAYMENT_ERROR,
    GET_CUTOFF_BEGIN,
    GET_CUTOFF_SUCCESS,
    GET_CUTOFF_ERROR
} from './reconConstants';

const initialState = {
    recon: {
        'unaccounted': {
            'count': 0,
            'transactions': [],
            'total_transfer': 0,
            'total_amount': 0,
            'total_fee': 0
        },
        'accounted': {
            'count': 0,
            'transactions': []
        },
        'missing': {
            'count': 0,
            'transactions': []
        },
    },
    
    cutoffs: {
        'unaccounted': {
            'count': 0,
            'transactions': [] 
        },
        'accounted': {
            'count': 0,
            'transactions': []
        },
        'missing': {
            'count': 0,
            'transactions': []
        },
    },
    loading: false,
    error: null,
}

const subwalletInitialState = {
    subwallet_payment_recon: {
        'unaccounted': {
            'count': 0,
            'transactions': [],
            'total_subwallet': 0,
            'total_subwallet_payment': 0,
        },
        'accounted': {
            'count': 0,
            'transactions': [],
        },
        'missing': {
            'count': 0,
            'transactions': []
        },
    },
    subwallet_payment_loading: false,
    subwallet_payment_error: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RECON_BEGIN:
            return {
                ...state, 
                loading: true,
                recon: initialState.recon
            };
        case GET_RECON_SUCCESS:
            return {
                ...state, 
                loading: false, 
                recon: action.payload,
            };
        case GET_RECON_ERROR:
            return {...state, loading: false, error: action.payload, recon: {}};
        
        case GET_SUBWALLET_PAYMENT_BEGIN:
            return {
                ...state, 
                subwallet_payment_loading: true,
                subwallet_payment_recon: subwalletInitialState.subwallet_payment_recon
            };
        case GET_SUBWALLET_PAYMENT_SUCCESS:
            return {
                ...state, 
                subwallet_payment_loading: false, 
                subwallet_payment_recon: action.payload,
            };
        case GET_SUBWALLET_PAYMENT_ERROR:
            return {...state, 
                subwallet_payment_loading: false, 
                subwallet_payment_error: action.payload, 
                subwallet_payment_recon: subwalletInitialState.subwallet_payment_recon
            };

        case GET_CUTOFF_BEGIN:
            return {...state, loading: true};
        case GET_CUTOFF_SUCCESS:
            return {...state, loading: false, cutoffs: action.payload};
        case GET_CUTOFF_ERROR:
            return {...state, loading: false, error: action.payload, cutoffs: {}};
        default:
            return state;
    }
}

export default reducer;