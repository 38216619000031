import React from 'react';
import { Card, Typography, List } from 'antd';

const BalanceCard = (props) => {
    const { data } = props;

    const renderSegment = (seg) => {
        switch (seg) {
            case 'ME_':
                return 'BUxME';
            case 'GIG': 
                return 'BUxGIG';
            case 'BIZ': 
                return 'BUxBIZ';
            case 'SME': 
                return 'BUxSME';
            default:
                return 'N/A';
        }
    }

    const info = [
        {
            title: 'Transferable Balance:',
            value: data && data.float_balance? data.float_balance : '₱ 0.00',
            hidden: false
        },
        {
            title: 'Master Wallet Balance:',
            value: data && data.master_balance? data.master_balance : '₱ 0.00',
            hidden: data && !data.master_balance
        },
        {
            title: 'Sub-wallet(s) Balance:',
            value: data && data.sub_balance? data.sub_balance : '₱ 0.00',
            hidden: data && !data.sub_balance
        },
    ]

    return (
        <Card
            style={styles.root}
            bodyStyle={styles.rootBody}
        >
            <div style={styles.flexBetween}>
                <Typography style={styles.title}>Wallet Balance</Typography>
                <Typography style={{...styles.item, fontWeight: 'bold'}}>{data && data.multiwallet && data.total_balance ? data.total_balance : data && data.balance}</Typography>
            </div>
            <div>
                <List
                    dataSource={info}
                    bordered={false}
                    renderItem={item => (
                        <List.Item style={{borderBottom: 0, padding: '8px 0', display: item && item.hidden && 'None'}}>
                            <Typography style={styles.item}>{item.title}</Typography>
                            <Typography style={styles.item}>{item.value}</Typography>
                        </List.Item>
                    )}
                />
            </div>
        </Card>
    )
}

const styles = {
    root: {
        margin: '12px 12px 12px 0',
        padding: '24px'
    },
    rootBody: {
        padding: 0,
    },
    title: {
        color: '#2B2D32',
        fontSize: '18px',
        fontWeight: 'bold',
        padding: '12px 0',
        opacity: '0.8'
    },
    normalFont: {
        color: '#2B2D32',
        fontSize: '16px'
    },
    content: {
        padding: '8px 0'
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 0',
        alignItems: 'center'
    },
    item: {
        color: '#2B2D32',
        fontSize: 16
    },
}

export default BalanceCard;