import React,{  } from "react";
import { Advisory } from "../../../static/icons";

const styles = {
  root: {
    width: '100%',
    border: '1px solid #F8B966',
    borderRadius: '4px',
    backgroundColor: 'rgba(248, 185, 102, 0.1)',
    display: 'flex',
    padding: 16,
    textAlign: 'justify',
    margin: '0px 0px 20px 0px',
    flexDirection: 'column',
  },
  title: {
    paddingBottom: 8, 
    fontSize: 16, 
    fontWeight: 'bold', 
    color: '#000000',
    flex: 10,
    alignSelf: 'center',
    textAlign: 'center',
  },
  icon: {
    flex: 1, 
    alignSelf: 'center', 
    margin: '0px 8px 0px 0px'
  },
  bankName: {
    fontSize: 14, 
    color: '#000000', 
    margin:'0px 0px 4px 0px',
  },
  date:{
    fontWeight: '600',
    display: 'inline'
  },
  inline:{ 
    display: 'inline'
  }
}

const renderAdvisory = (props) => {
  const Notification = (data) => {
    return (
      <div style={styles.bankName}>
        <strong>{data.name}</strong> - from
        <div style={styles.date}> {data.startDate}</div>
        { data.startTime ? <div style={styles.inline}> {data.startTime}</div>
          : null}
        { data.endDate ? <div style={styles.inline}> to
          <div style={styles.date}> {data.endDate}</div>
          { data.endTime ? <div style={styles.inline}> {data.endTime}.</div>
            : <div style={styles.inline}>.</div> }
          </div>
          : <div style={styles.inline}>.</div>}
      </div>
    )
  }

  return (
    <div>
      {props ? props.map((item, index) => {
        return (
          <Notification key={index} {...item} />
        )
      })
      :null}
    </div>
  )
}

const BankAdvisoryCard = () => {
  const advisory = JSON.parse(localStorage.getItem('banks_disabled'));
    return (
      advisory && advisory.length > 0 ? 
      <div style={styles.root}>
        <div style={{display: 'flex', margin: '0px 0px 6px 0px'}}>
          <div style={styles.icon}>
              <img src={Advisory} />
          </div>
          <div style={styles.title}>
              Upcoming Maintenance for the following banks:
          </div>
        </div>
        <div>
          {renderAdvisory(advisory)}
        </div>
        </div> 
      : null
    )
} 

export default BankAdvisoryCard;