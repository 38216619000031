import React from 'react';
import { List, Menu, Typography, Dropdown, Switch, Card, Pagination } from 'antd';
import { isMobile } from 'react-device-detect';


const sampleData = [
    {
        id: 'UB123456',
        bank: 'Unionbank of the Phil',
        account_name: 'Alvin Geronimo',
        account_number: '1234-1234-1234-1234',
        amount: 1800,
        date_paid: 'Sep 12, 2021, 3:28 PM',
        status: 'success',
    },
    {
        id: 'UB1098765',
        bank: 'Unionbank of the Phil',
        account_name: 'Mr Bean',
        account_number: '1234-1234-1234-1234',
        amount: 6000,
        date_paid: 'Nov 12, 2021, 3:28 PM',
        status: 'success',
    }
]

const PayoutTableCards = (props) => {
    const { data, selected, isLoading, hidden } = props;
    return (
        <List
            isLoading={!data}
            hidden={hidden}
            dataSource={data}
            renderItem={item =>
                <div>
                    <TransactionCards data={{ ...item }} selected={selected} isLoading={isLoading} />
                </div>
            }
        />
    )
}


const TransactionCards = (props) => {
    const [selectedData, setSelectedData] = React.useState([]);

    const { data, selected, isLoading } = props;

    const menu = (data) => (
        <Menu>
            <Menu.Item hidden onClick={() => { setSelectedData(data); }}>
                {data.can_view_settlement_report ? 'Stop settlement reports' : 'Subscribe to Settlement Reports'}
            </Menu.Item>
            <Menu.Item onClick={() => { setSelectedData(data); }}>Activate account</Menu.Item>
        </Menu>
    )

    const renderStatus = (status) => {
        if (status.includes('Success')) {
            return { status: 'Success', border: 'rgb(29, 210, 139)', background: 'rgba(29, 210, 139, 0.1)' }
        } else if (status.includes('Pending')) {
            return { status: 'Pending', border: 'rgb(245, 146, 47)', background: 'rgba(245, 146, 47, 0.1)' }
        } else if (status.includes('Failed')) {
            return { status: 'Failed', border: 'rgb(226, 76, 76)', background: 'rgba(226, 76, 76, 0.1)' }
        }
    }

    return (
        data ?
            <Card size='small' style={styles.cardRoot} bodyStyle={{ padding: 0 }} loading={isLoading}>
                <div style={styles.upper}>
                    <div style={{ textAlign: 'left' }}>
                        <Typography style={styles.tranxId} >Transfer ID</Typography>
                        <Typography style={{ ...styles.normalFont, fontSize: 14, fontWeight: 'normal' }} >{data.id}</Typography>
                    </div>
                </div>
                <div style={styles.middle}>
                    <div style={styles.spaceBetween}>
                        <Typography style={styles.normalFont}>Bank</Typography>
                        <Typography style={{ ...styles.normalFont, fontSize: 16, fontWeight: '500' }}>{data.bank}</Typography>
                    </div>
                    <div style={styles.spaceBetween}>
                        <Typography style={styles.normalFont}>Account name</Typography>
                        <Typography style={{ ...styles.normalFont, fontSize: 16, fontWeight: '500' }}>{data.account_name}</Typography>
                    </div>
                    <div style={styles.spaceBetween}>
                        <Typography style={styles.normalFont}>Account number</Typography>
                        <Typography style={{ ...styles.normalFont, fontSize: 16, fontWeight: '500' }}>{data.account_number}</Typography>
                    </div>
                    <div style={styles.spaceBetween}>
                        <Typography style={styles.normalFont}>Amount</Typography>
                        <Typography style={{ ...styles.normalFont, fontSize: 16, fontWeight: '500' }}>{"₱" + data.amount.slice(3).toLocaleString("en-US", {minimumFractionDigits: 2})}</Typography>
                    </div>
                    <div style={styles.spaceBetween}>
                        <Typography style={styles.normalFont}>Date generated</Typography>
                        <Typography style={{ ...styles.normalFont, fontSize: 16, fontWeight: '500' }}>{data.date_paid}</Typography>
                    </div>
                </div>
                <div style={styles.lower}>
                    <Typography style={styles.normalFont}>Status</Typography>
                    <Typography style={{ ...styles.status, backgroundColor: renderStatus(data.status).background, border: `1px solid ${renderStatus(data.status).border}` }}>{renderStatus(data.status).status}</Typography>
                </div>
            </Card>
            : null
    )
}

const MobilePayoutCards = (props) => {
    const [selectedData, setSelectedData] = React.useState([]);

    const { data, selected, currentPage, isLoading, onChangePage } = props;

    return (
        <div hidden={!isMobile}>
            <PayoutTableCards isLoading={isLoading} data={data ? data['results'] : []} selected={[]} />
            <Pagination
                size='small'
                total={data ? data['total'] : 0}
                defaultPageSize={data ? data['page_size'] : 10}
                pageSize={data['page_size'] ? data['page_size'] : 10}
                defaultCurrent={1}
                current={currentPage}
                onChange={onChangePage}
                style={{ padding: '12px 0 8px 0', textAlign: 'right' }}
            />
        </div>
    )
}

const styles = {
    root: {

    },
    cardRoot: {
        margin: '16px 0'
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        padding: '6px 0'
    },
    upper: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px',
        borderBottom: '1px solid #D1D5DD',
    },
    lower: {
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: '1px solid #D1D5DD',
        padding: '16px'
    },
    middle: {
        padding: '10px 16px 16px 16px',
    },
    normalFont: {
        color: '#2B2D32',
        fontSize: 16,
        fontWeight: '300'
    },
    status: {
        color: '#2B2D32',
        fontSize: 12,
        padding: '4px 8px',
        borderRadius: 4,
        textAlign: 'center'
    },
    tranxId: {
        color: '#2B2D32',
        fontSize: 16,
        fontWeight: 'bold'
    }
}

export default MobilePayoutCards;