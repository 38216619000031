import React from 'react';
import moment from 'moment';
import { Avatar, Col, Divider, Drawer, Icon, Row, Typography } from 'antd';
import { history } from '../../../store/history';

const DetailsDrawer = (props) => {
    const { detailsData, drawerVisibility, setDrawerVisibility } = props
    const segments = localStorage.getItem("segments")
    const noKyc = ['ME_', 'GIG'].includes(detailsData && detailsData.segment) && detailsData && !detailsData.kyc_id;
    const segmentsColor = {
        'me_': {
            color: '#0DAED0',
        },
        'gig': {
            color: '#41BD5C',
        },
        'nil': {
            color: '#254A76',
        }
    }

    const renderAction = (action) => {
        switch (action) {
            case 'LOGOUT':
                return 'Upon logout';
            case 'CUSTOMERPAYMENT':
                return 'Customer Payment';
            case 'MERCHANTPAYMENT':
                return 'Merchant Generating Payment Link';
            default:
                return 'N/A';
        }
    }

    const renderKYCLink = (surveyType) => {
        if (noKyc) {
            return false;
        }
        if(['ME_', 'GIG'].includes(detailsData && detailsData.segment)){
            window.open(process.env.REACT_APP_BASE_URL+`/submissions/${detailsData.kyc_id}`)
        } else if (['SUB', 'NIL'].includes(detailsData && detailsData.segment)) {
            return null
        } else {
            window.open(process.env.REACT_APP_BASE_URL + `/submissions_kyb/${detailsData && detailsData.user_id}/#kyb_form`)
        }
    }

    return (
        <Drawer
            className='voucher-drawer'
            placement="right"
            closable={false}
            maskClosable={true}
            visible={drawerVisibility}
            width='500px'
            onClose={() => setDrawerVisibility(false)}
        >
            <div style={drawerStyles.root}>
                <div style={{ marginBottom: '20px' }} align="right">
                    <Icon type='close' onClick={() => setDrawerVisibility(false)} style={{ fontSize: '20px', color: segmentsColor[segments].color }} />
                </div>
                <div style={drawerStyles.flexBetween}>
                    <div style={{ lineHeight: '18px', display: 'inline-flex', alignItems: 'center' }}>
                        <Avatar icon={<Icon type="user" />} />
                        <Typography style={{ ...drawerStyles.code, color: segmentsColor[segments].color, paddingLeft: '12px' }}>{detailsData.survey_type === "CUSTOMERPAYMENT" ? detailsData.customer_name : detailsData.merchant_name}</Typography>
                    </div>
                </div>
                <div style={{ padding: '8px 0' }}>
                    <Typography style={{ ...drawerStyles.sectionTitle, color: segmentsColor[segments].color }}>{detailsData.survey_type === "CUSTOMERPAYMENT" ? "Customer Details" : "KYC Details"}</Typography>
                    <Divider style={{ margin: '6px 0', backgroundColor: segmentsColor[segments].color }} />
                    <Row style={drawerStyles.row}>
                        <Col span={12} style={drawerStyles.field}>{detailsData.survey_type === "CUSTOMERPAYMENT" ? "Customer Name" : "Merchant Name"}</Col>
                        <Col span={12} style={drawerStyles.fieldValue}>{detailsData.survey_type === "CUSTOMERPAYMENT" ? detailsData.customer_name : detailsData.merchant_name}</Col>
                    </Row>

                    <Row style={drawerStyles.row}>
                        <Col span={12} style={drawerStyles.field}>Email</Col>
                        <Col span={12} style={drawerStyles.fieldValue}>{(detailsData && detailsData.email) || "N/A"}</Col>
                    </Row>
                    <Row style={drawerStyles.row}>
                        <Col span={12} style={drawerStyles.field}>Mobile No.</Col>
                        <Col span={12} style={drawerStyles.fieldValue}>{(detailsData && detailsData.mobile_number) || "N/A"}</Col>
                    </Row>
                    <Row style={drawerStyles.row}>
                        <Col span={12} style={drawerStyles.field}>{detailsData.survey_type === "CUSTOMERPAYMENT" ? "Merchant's Segment" : "Segment"}</Col>
                        <Col span={12} style={drawerStyles.fieldValue}>
                            {(detailsData && detailsData.segment && (detailsData.segment !== 'NIL' ? ('BUx' + detailsData.segment).replace('_', '') : detailsData.segment)) || "N/A"}
                        </Col>
                    </Row>

                </div>
                <div style={{ padding: '8px 0' }}>
                    <Typography style={{ ...drawerStyles.sectionTitle, color: segmentsColor[segments].color }} >Survey</Typography>
                    <Divider style={{ margin: '6px 0', backgroundColor: segmentsColor[segments].color }} />
                    <Row style={drawerStyles.row}>
                        <Col span={12} style={drawerStyles.field}>Action</Col>
                        <Col span={12} style={drawerStyles.fieldValue}>{(detailsData && renderAction(detailsData.survey_type)) || "N/A"}</Col>
                    </Row>
                    <Row style={drawerStyles.row} hidden={detailsData && detailsData.survey_type === 'LOGOUT'}>
                        <Col span={12} style={drawerStyles.field}>Receipt</Col>
                        <Col span={12} style={drawerStyles.fieldValue}>
                            <a href={process.env.REACT_APP_BASE_URL + `/payment/${detailsData && detailsData.uid}`} target="_blank" style={{color: '#254A76', textDecoration: 'underline'}}>Link</a></Col>
                    </Row>
                    <button onClick={renderKYCLink} 
                        className="btn--nil" style={{width: '100%', marginTop: '4px', 
                            opacity: detailsData && (detailsData.segment === "SUB" || detailsData.segment === "NIL" || noKyc) && '0.5', cursor: detailsData && (detailsData.segment === "SUB" || detailsData.segment === "NIL" || noKyc) && 'not-allowed'}} >
                            {detailsData && detailsData.survey_type === 'CUSTOMERPAYMENT' ? "Check Merchant KYC/KYB profile" : "Check KYC/KYB  profile"}
                    </button>
                </div>
            </div>
        </Drawer>
    )
}

const drawerStyles = {
    root: {
        padding: '24px',
        width:'500px'
    },

    cancelBtn: {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: '1px solid #0A315E',
        borderRadius: 4,
        height: 40,
        fontSize: 16,
        fontWeight: '500',
        color: '#0A315E',
        padding: '8px 16px',
        width: 'auto',
        marginRight: 8
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px 0 12px 0'
    },
    promocode: {
        fontSize: '12px',
        fontWeight: 'bold',
        marginBottom: '5px',
        color: 'rgba(43, 45, 50, 0.8)'
    },
    code: {
        color: '#F5922F',
        fontSize: '24px',
        fontWeight: 'bold'
    },
    sectionTitle: {
        fontSize: '16px',
        fontWeight: 'bold'
    },
    row: {
        padding: '8px 0',
    },
    fieldValue: {
        color: '#2B2D32',
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'right'
    },
    field: {
        color: '#2B2D32',
        fontSize: 16,
    },
    shareText: {
        fontSize: '16px',
        fontWeight: '500',
        color: '#2b2d32'
    }
}

export default DetailsDrawer; 