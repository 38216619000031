export const cardDiv = {
    background: '#FFFFFF',
    border: '1px solid #E6EAF0',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
    width: '34.5vw',
    fontFamily: 'Inter',
    textAlign: 'left',
    marginTop: 20,
    padding: 40
};

export const header = {
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '20px',
    color: '#0D3D76',
};


export const subDiv = {
    marginTop: 32,
    padding: 24,
    width: '30vw',
    border: '1px solid #e6eaf0',
    // boxShadow: '0px 2px 4px rgba(0,0,0,0.5)',
    height: '100%'
}

export const subHeader = {
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '20px',
    color: '#0D3D76',
};

export const twoCol = {
    display: 'flex',
    justifyContent: 'space-between',
};

export const customDiv = {
    marginTop: 16
};

export const placeHolder = {
    marginBottom: 8,
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: "20px",
    color: '#2b2d33'
};

export const btnStyle = {
    background: '#f5922f',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: 4,
    border: '1px solid #f5922f',
};

export const cancelBtn = {
    background: '#fff',
    color: '#000000',
    fontWeight: 600,
    borderRadius: 4,
    border: '1px solid #fff'
}