import React from 'react';
import { Button, message, Table } from 'antd';
import TagModal from './tagModal';
import fraudServices from '../fraudServices';
import SuccessTagModal from './successTagModal';

const TagModalTable = ({data, closeModal, selected, blacklistId, onSuccessTagged, tableLoading}) => {
  const [cancelBtnStyle, setCancelBtnStyle] = React.useState({color: '#F5922F', backgroundColor: '#FFF'});
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [selectedInvalid, setSelectedInvalid] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [successTagModal, setSuccessTagModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'merchant_id',
      visible: true,
    },
    {
      title: 'Business Name',
      dataIndex: 'business_name',
      visible: true,
    },
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      visible: selected === 'Individual Account' ? true : false,
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      render: (text, record) => <div style={{whiteSpace: 'pre-wrap', maxWidth: 100}}>{text}</div>,
      visible: true,
    },
    {
      title: 'Mobile Number',
      dataIndex: 'contact',
      visible: true,
      render: (text, record) => '+63' + text,
    },
    {
      title: 'Level',
      dataIndex: 'eon_level',
      render: (text, record) => <div style={{...styles.level, backgroundColor: returnBGColor(text)}}>{returnLevel(text)}</div>,
      visible: selected === 'Individual Account' ? true : false,
    },
    {
      title: 'Action',
      key: "action",
      render: (text, record) => {
        const link_id = selected === 'Individual Account' ? record.kyc_id : record.user_id;
        return (
          <a style={styles.actionLink} onClick={() => window.open(`/${selected==='Individual Account' ? 'submissions' : 'submissions_kyb'}/${link_id}/`,'_blank')}>View</a>
        );
      },
      visible: true,
    }
  ]

  const returnBGColor = (level) => {
    switch(level) {
      case 0:
        return '#65C1F2';
      case 1:
        return '#38ADA9';
      case 2:
        return '#1E3799';
      case 3: 
        return '#FFB142';
      default:
        return '#65C1F2';
    }
  }

  const returnLevel = (level) => {
    switch(level) {
      case 0:
        return 'Basic';
      case 1:
        return 'Pro';
      case 2:
        return 'Advanced';
      case 3: 
        return 'Business';
      default:
        return 'Basic';
    }
  }

  const handleChangeSelect = (selectedRowKeys, selectedRows) => {
    setSelectedInvalid(false);
    setSelectedRowKeys(selectedRowKeys);
    // const rowId = selectedRows.map((item) => {
    //   return item.id;
    // })
    selectedRows.map((item) => {
      if(item.is_tagged){
        setSelectedInvalid(true);
      }
    });
    setSelectedRowData(selectedRows[0]);
  }

  const tableCheckbox = {
    type: 'radio', 
    selectedRowKeys:selectedRowKeys, 
    onChange: (selectedRowKeys, selectedRows) => handleChangeSelect(selectedRowKeys, selectedRows)
  }

  const cancelBtnHover = () => {
    setCancelBtnStyle({
      color: '#FFF',
      backgroundColor: '#F5922F',
    })
  }

  const cancelBtnLeave = () => {
    setCancelBtnStyle({
      color: '#F5922F',
      backgroundColor: '#FFF',
    })
  }

  const errorPrompt = (msg) => {
    message.error(msg);
  };

  const tagUser = async () => {
    setLoading(true);
    let params = {
      action: 'assign',
      blacklist_id: blacklistId,
    }

    selected === 'Individual Account' ? 
      params['kyc_id'] = selectedRowData.kyc_id 
      : 
      params['kyb_id'] = selectedRowData.kyb_id;

    try {
      const res = await fraudServices.tagToggle(selected === 'Individual Account' ? 'kyc' : 'kyb', params);
      if(res.status === 'success'){
        setSuccessTagModal(true);
        onSuccessTagged();
      } else {
        errorPrompt(res.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorPrompt('There was an error try again');
      setLoading(false);
    }
  }

  const footer = (
    <div style={styles.btnContainer}>
      <Button
        style={{...styles.cancelBtn, ...cancelBtnStyle, marginRight: 16 }}
        onMouseLeave={cancelBtnLeave}
        onMouseOver={cancelBtnHover} loading={loading}
        onClick={closeModal}
      >Cancel</Button>
      <Button style={{...styles.tagBtn, opacity: !selectedRowData? '.5' : '1'}}
        disabled={!selectedRowData} 
        onClick={tagUser}
        loading={loading}  
      >Tag</Button>
    </div>
  )

  return (
    <div style={{width: '100%'}}>
      <Table
        rowClassName="table-row-light"
        columns={columns.filter(col => col.visible === true)}
        dataSource={data}
        bodyStyle={{width: '100%'}}
        pagination={{pageSize: 10, size: 'small'}}
        rowSelection={{...tableCheckbox}}
        loading={tableLoading}
       />
       {footer}
      <SuccessTagModal visible={successTagModal} closeModal={() => setSuccessTagModal(false)/closeModal()} message='Tagged' />
    </div>
  )
}

const styles ={
  cancelBtn: {
    width: 172,
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    height: 48,
    border: '1px solid #F5922F',
  },
  tagBtn: {
    height: 48,
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    width: 172,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  actionLink: {
    color: '#F5922F', 
    fontWeight: '500', 
    fontSize: 14,
    textDecoration: 'underline'
  },
  level: {
    borderRadius: 40,
    color: '#FFF',
    fontSize: 12,
    textAlign: 'center',
    padding: 4,
    width: 76,
    fontWeight: '400',
  }
}

export default TagModalTable;