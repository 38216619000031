import React, {useEffect, useState, useRef} from 'react';
import axios from "axios";
import { Button, Icon, Input, message, Pagination, Select, Table } from 'antd';
import { Handle401 } from '../../handle401/handle401';
import MerchantIDsEditorModal from './merchant_ids_editor_modal';
import { history } from '../../../store/history';
import { makeStyles } from '@material-ui/core';
import EditPencilFilled from '../../../static/img/edit_pencil_filled.svg'
import ExportCsvModal from './export_csv_modal';
import ClearFilter from '../filter_btns/clear_filter_btn';
import TableValue from '../table_value/table_render';

const AdminGCashSubMIDs = () => {
    const merchantIDStyles = makeStyles(theme => ({
        boldHeader: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '28px',
            lineHeight: '32px',
            color: '#000000',
            marginBottom: '40px'
        },
        searchBar: {
            width: '200px',
            height: '35px',
            zIndex: '1'
        },
        paginationDiv: {
            width: '100%',
            textAlign: 'right',
            justifyContent: 'center',
            marginTop: '20px'
        },
        addButton: {
            color: '#FFF',
            backgroundColor: '#1DD28B',
            height: 40,
            borderRadius: 4,
            padding: '8px, 16px, 8px, 16px',
            fontSize: 16,
            fontWeight: '600'
        },
        exportButton: {
            fontSize: 16,
            fontWeight: '600',
            padding: '8px, 16px, 8px, 16px',
            border: '1px solid #1DD28B',
            height: 40,
            backgroundColor:'#1DD28B',
            marginRight: 12,
            color: "#FFFFFF",
            '&:hover': {
                cursor: 'pointer',
                opacity: 0.8,
            },
        },
        moduleWorkspaceDiv: {
            padding: '15px 20px',
            background: '#fff',
            border: '1px solid #E6EAF0',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        },
        merchant_id_values: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap', 
        },
        clearFilterDiv: {
            marginBottom: '5px', 
            display: 'flex', 
            justifyContent: 'space-between' 
        },
        filterLabel: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: 16,
            lineHeight: '24px',
            color: '#2B2D33'
        },
        filterHelpText: {
            fontSize: 12,
            lineHeight: '21px',
            color: '#909196'
        },
        clearFilterButton: {
            borderRadius: '24px',
            background: '#E6EAF0',
            color: '#000000',
            fontSize: 12,
            width: 120,
            height: '29px',
            border: '1px solid #e6eaf0',
            cursor: 'pointer',
            outline: 0,
            '&:hover': {
                opacity: '0.8',
            },
            '&:active': {
                opacity: 1,
            }
        },
        applyButtonActive: {
            background: '#f5922f',
            border: '1px solid #f5922f',
            borderRadius: '4px',
            color: '#fff',
            height: '40px',
            width: 93,
            cursor: 'pointer',
            outline: 0,
            '&:hover': {
                opacity: '0.8',
            },
            '&:active': {
                opacity: 1,
            }
        },
        applyButtonDisabled: {
            outline: 0,
            background: '#f5922f',
            border: '1px solid #f5922f',
            borderRadius: '4px',
            color: '#fff',
            height: '40px',
            width: 93,
            opacity: 0.5,
            cursor: 'not-allowed'
        },
        editMIDsButton: {
            border: 'none',
            background: 'transparent',
            color: '#0A315E',
            outline: 'none',
            cursor: 'pointer',
            '&:hover': {
                opacity: '0.8',
            },
        },
        completionFilter: {
            width: 150
        }
    })) ();


    // Filters
    const isComponentMounted = useRef(false);
    const [areFilterSettingsInDefaultState, setAreFilterSettingsInDefaultState] = useState(true);
    const [areFilterSettingsApplied, setAreFilterSettingsApplied] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [completionFilterValue, setCompletionFilterValue] = useState('all');
    const isFilterResetFired = useRef(false);
    const { Option } = Select;

    // Table
    const [tableData, setTableData] = useState([]);
    const [merchantPlatformsInUse, setMerchantPlatformsInUse] = useState({});
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [totalMerchantIDsCount, setTotalMerchantIDsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 20;

    // Edit Form Modal
    const [isEditorModalVisible, setIsEditorModalVisible] = useState(false);
    const [userInFocus, setUserInFocus] = useState();

    // Export CSV Modal
    const [isExportModalVisible, setIsExportModalVisible] = useState(false);

    

    const columns = [
        {
            title: "BUx ID",
            key: 'bux_mid',
            width: '15%',
            render: (text, user) => {
                return (
                    <div className="liveColor">
                        <div><b>
                            {user.id}
                        </b></div>
                        <div>
                            {user.bux_mid}
                        </div>
                    </div>
                )
            }
        },
        {
            title: "Full name and Email",
            key: 'full_name',
            width: '17%',
            render: (text, user) => {
                return (
                    <div className="liveColor">
                        <div><b>
                            {user.full_name}
                            </b></div>
                        <div>{user.email}</div>
                    </div>
                )
            }
        },
        {
            title: "Business Name",
            dataIndex: 'business_name',
            width: '12%',
        },
        {
            title: "Industry",
            dataIndex: 'industry',
            width: '10%',
            render: (text, user) => {
                return(
                    <div>
                        {user.industry === "" ? "- -" : user.industry}
                    </div>
                )
            },
        },
        {
            title: "Sub Category",
            dataIndex: 'sub_category',
            width: '14%',
            render: (text, user) => {
                return(
                    <div>
                        {user.sub_category === "" ? "- -" : user.sub_category}
                    </div>
                )
            },
        },
        {
            title: "Website",
            dataIndex: 'website',
            width: '10%',
            render: (text, user) => {
                return(
                    <div>
                        {user.website === "" ? "- -" : user.website}
                    </div>
                )
            },
        },
        {
            title: "Facebook",
            dataIndex: 'facebook',
            width: '10%',
            render: (text, user) => {
                return(
                    <div>
                        {user.facebook === "" ? "- -" : user.facebook}
                    </div>
                )
            },
        },
        {
            title: 'GCash MID',
            width: '16%',
            render: (text, user) => {
                return(
                    <div>
                        {user.gcash === "" ? "- -" : user.gcash}
                    </div>
                )
            },
        },
        {
            title: 'Action',
            key: 'edit',
            width: '7vw',
            render: (text, user) => {
                return (
                    <div>
                        <button
                            className={merchantIDStyles.editMIDsButton}
                            onClick={() => {
                                setUserInFocus(user);
                                setIsEditorModalVisible(true);
                            }}
                            disabled={!Object.keys(merchantPlatformsInUse).length}
                        >
                            <img alt="Edit" src={EditPencilFilled} />  Edit                         
                        </button>
                    </div>
                )
            }
        }
    ];

    // const rightFixedColumns = [
    //     {
    //         title: 'Action',
    //         key: 'edit',
    //         width: '5vw',
    //         // fixed: 'right',
    //         render: (text, user) => {
    //             return (
    //                 <div>
    //                     <button
    //                         className={merchantIDStyles.editMIDsButton}
    //                         onClick={() => {
    //                             setUserInFocus(user);
    //                             setIsEditorModalVisible(true);
    //                         }}
    //                         disabled={!Object.keys(merchantPlatformsInUse).length}
    //                     >
    //                         <img alt="Edit" src={EditPencilFilled} />  Edit                         
    //                     </button>
    //                 </div>
    //             )
    //         }
    //     }
    // ];

    const getMerchantPlatformColumns = () => {
        if (!Object.keys(merchantPlatformsInUse).length) {
            return [];
        }

        let merchantPlatformColumns = [];
        for (const [platformID, platformProperName] of Object.entries(merchantPlatformsInUse)) {
            merchantPlatformColumns.push({
                title: `${platformProperName} MID`,
                render: (text, user) => {
                    return(
                        <div>
                            {user[platformID] === "" ? "- -" : user[platformID]}
                        </div>
                    )
                },
                width: `16%`
            })
        }

        return merchantPlatformColumns;
    }


    async function genericAxiosRequest(http_method, target_url, params=undefined) {
        try {
            let response = await axios({
                method: http_method,
                url: process.env.REACT_APP_API_URL + target_url,
                data: params,
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            });
            return response.data;

        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    Handle401()
                } else {
                    throw new Error(error.response.data.message);
                }
            } else {
                throw new Error('No response from backend server!');
            }
        }
    }


    async function fetchMerchantInfo(selectedPage=1, searchVal='', completion_type='all') {
        setIsTableLoading(true);

        const endpoint_url = "/api/admin/users/submid_credentials/";
        let query_params = [
            'page=' + selectedPage,
            'page_size=' + pageSize,
            'search=' + searchVal,
            'completion=' + completion_type,
        ];
        const full_url = endpoint_url + "?" + query_params.join("&");

        try {
            let response = await genericAxiosRequest('get', full_url);
            if (response.status === 'failed') {
                throw new Error(response.message);
            }
            console.log(response.data)
            setTableData(response.data.merchant_info);
            setMerchantPlatformsInUse(response.data.platforms);
            setTotalMerchantIDsCount(response.total);

        } catch (err) {
            message.error(err.message);
            setTableData([]);
            setMerchantPlatformsInUse([]);
            setTotalMerchantIDsCount(0);
            setAreFilterSettingsApplied(false);

        } finally {
            setIsTableLoading(false);
        }
    }

    async function exportToEmail(email='') {
        const endpoint_url = "/api/admin/users/submid_credentials/";
        let query_params = [
            'export=' + true,
            'email=' + email,
            'search=' + searchValue,
            'completion=' + completionFilterValue,
        ];
        const full_url = endpoint_url + "?" + query_params.join("&");

        try {
            let response = await genericAxiosRequest('get', full_url);
            if (response.status === 'failed') {
                throw new Error(response.message);
            }
            
            message.success(response.message);
            setIsExportModalVisible(false);

        } catch (err) {
            message.error(err.message);
        }
    }


    function onPageChange(selectedPage) {
        setCurrentPage(selectedPage);
        fetchMerchantInfo(selectedPage, searchValue, completionFilterValue);
    }


    function checkIfFiltersAreDefaultState() {
        // Add defaults of other new filters states here
        const areFiltersSameAsDefault = !searchValue
            && completionFilterValue === 'all';

        setAreFilterSettingsInDefaultState(areFiltersSameAsDefault);
        return areFiltersSameAsDefault;
    }


    function clearFilterSettings() {
        isFilterResetFired.current = true;

        // Reset default filter values here
        setSearchValue('');
        setCompletionFilterValue('all');
        setCurrentPage(1);

        fetchMerchantInfo();
    }


    function applyFilterSettings() {
        fetchMerchantInfo(currentPage, searchValue, completionFilterValue);
        setAreFilterSettingsApplied(true);
    }


    useEffect(() => {
        if(! isComponentMounted.current) {
            return;
        }

        const areFiltersSameAsDefault = checkIfFiltersAreDefaultState();
        if (areFiltersSameAsDefault) {
            if (isFilterResetFired.current) {
                setAreFilterSettingsApplied(true);
                isFilterResetFired.current = false;
            } else {
                setAreFilterSettingsApplied(false);
            }
        } else if (!areFiltersSameAsDefault) {
            setAreFilterSettingsApplied(false);
        }
    }, [searchValue, completionFilterValue]);  // Add other new filters used here


    useEffect(() => {
        isComponentMounted.current = true;


        if(localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") == null){
            history.push('/login')
        } else {
            const userType = localStorage.getItem("userType");

            if (userType == "ME" || userType == "CO"){
                history.push('/dashboard')
            }
            else if (userType == "PS"){
                history.push('/orders')
            }
            else if (userType == "PF"){
                history.push('/recon/7_eleven')
            }
            else if (userType == "MA"){
                history.push('/overview')
            }
            else {
                fetchMerchantInfo();
            }
        }
    }, []);


    return (
        <div style={{padding: '0 56px 64px 0'}}>
            <div className="twoCol">
                <div className="screen-title-text">
                    Sub MID 
                </div>
                <div>
                    <Button 
                        className="admin-download-btn"
                        onClick={() => setIsExportModalVisible(true)}>
                        <Icon type='download' />
                        Download CSV             
                    </Button>
                </div>
            </div>

            <div className="table-card-div">
                <ClearFilter hideFilterBtn={areFilterSettingsInDefaultState} clearFilter={clearFilterSettings} />

                <div style={{display: 'flex', marginTop: 24}}>
                    <Select 
                        defaultValue="all"
                        size="large"
                        className="dropdown"
                        onChange={setCompletionFilterValue}
                        value={completionFilterValue}
                    >
                        <Option value="all">All</Option>
                        <Option value="complete">Complete</Option>
                        <Option value="incomplete" >Incomplete</Option>
                    </Select>

                    <Input
                        placeholder="Search"
                        className="search-bar"
                        onChange={(e) => setSearchValue(e.target.value)}
                        prefix={<Icon style={{paddingTop: 5}} type="search" />}
                        value={searchValue}
                        size="large"
                        style={{marginLeft: 15}}
                    />

                    <button
                        style={{marginLeft: 15}}
                        className={`admin-apply-btn ${areFilterSettingsApplied ? "admin-disabled-btn" : ""}`}
                        disabled={areFilterSettingsApplied}
                        onClick={applyFilterSettings}
                    >
                        Apply
                    </button>
                </div>

                <Table
                    rowKey={user => user.id}
                    rowClassName={(_, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                    dataSource={tableData}
                    columns={columns}
                    pagination={false}
                    loading={isTableLoading}
                    size="middle"
                    className="top-20"
                />

                <div align="right" className="top-20">
                    <Pagination
                        size="small"
                        total={totalMerchantIDsCount}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        onChange={onPageChange}
                        defaultCurrent={1}
                        pageSize={pageSize}
                        current={currentPage}
                    />
                </div>
            </div>
            
            <MerchantIDsEditorModal
                isEditorModalVisible={isEditorModalVisible}
                onCancelOrClose={() => setIsEditorModalVisible(false)}
                userInFocus={userInFocus}
                merchantPlatformsInUse={merchantPlatformsInUse}
                postSaveReload={applyFilterSettings}
                isSubMid={true}
            />

            <ExportCsvModal
                isExportModalVisible={isExportModalVisible}
                onCancelOrClose={() => setIsExportModalVisible(false)}
                onSend={exportToEmail}
            />
                    
        </div>
    )
}

export default AdminGCashSubMIDs;