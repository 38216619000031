import React from 'react'
import {Modal, Button, Checkbox, Typography, Icon, List} from 'antd'
import DisabledCheckbox from '../../../static/images/disabled_checkbox_icon.svg';

const TableSettingsModal = props => {

    const {
        segments,
        tableSettingsModal,
        hideModal,
        checkedColumns,
        setCheckedColumns,
        testMode,
        userType
    } = props

    const tableSettingOptions = [
        {
          name:'Reference No.',
          dataIndex: 'code'
         },
         {
           name: 'Channel',
           dataIndex: 'channel_str'
         },
         {
           name: 'Date Generated',
           dataIndex: 'timestamp'
         },
         {
           name: 'Date Paid, Cancelled, Expired',
           dataIndex: 'date'
         },
         {
           name: 'Amount',
           dataIndex: 'amount'
         },
        {
          name: 'Promo Discount',
          dataIndex: 'promo_discount'
        },
        {
          name: 'Promo Code',
          dataIndex: 'promotion_code'
        },
         {
           name: 'Receivable',
           dataIndex: 'seller_net'
         },
         {
           name: 'Field 1',
           dataIndex: 'param1'
         },
         {
           name: 'Field 2',
           dataIndex: 'param2'
         },
    ]

    const onChangeTableSettings = (e) => {

        let newCheckedColumns = checkedColumns
        const dateFilter = ['expire_at', 'cancelled_at', 'date_paid']

        if(e.target.checked){
          newCheckedColumns = newCheckedColumns.filter(id => {return id !== e.target.id})
        }
        else if(!e.target.checked){
          if(e.target.id === 'date') {
            dateFilter.map((d) => newCheckedColumns.push(d));
          } else {
            newCheckedColumns.push(e.target.id)
          }
        }
        setCheckedColumns(newCheckedColumns)
    }

    const arrangColumns = (columns) => {
      var filtered = columns? columns : [];

      if(segments === 'sme' || segments === 'biz' || testMode || userType === 'SW'){
        filtered = filtered.filter(el => {return el.dataIndex != 'promo_discount' && el.dataIndex != 'promotion_code'})
      }
      return filtered;
    }

    return(
        <Modal
            visible={tableSettingsModal}
            onCancel={hideModal}
            footer={false}
            width={392}
            closeIcon={<Icon type="close" style={{color:'#F5922F'}}/>}
            centered
          >
            <div style={{ borderRight: 0, marginTop: 12, background: 'transparent'}} mode="inline">
              <Typography style={{backgroundColor: '#E6EAF0', color: '#000', fontSize: 14, padding: '12px 16px', marginTop: 24}}>Customize Columns</Typography>
                  <List 
                      dataSource={arrangColumns(tableSettingOptions)}
                      renderItem={ (item, i) => 
                        <div style={{
                            borderTop: '1px solid #E6EAF0',
                            borderBottom: '1px solid #E6EAF0',
                            padding: i === 3 ? '4px 14px' : 14,
                            margin: '4px 0',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                          {item.dataIndex !== 'param1' && item.dataIndex !== 'param2' ? 
                            <img style={{cursor: 'not-allowed'}} src={DisabledCheckbox} height={16} alt='No Image' />
                          :
                            <Checkbox 
                                id={item.dataIndex} 
                                disabled={item.dataIndex !== 'param1' && item.dataIndex !== 'param2' } 
                                onChange={onChangeTableSettings} 
                                defaultChecked
                                color={'#DDD'}/>
                            }
                            <Typography
                              style={{
                                color: '#2B2D33',
                                fontSize: 16,
                                paddingLeft: 32
                              }}>{item.name}</Typography>
                        </div>
                      }
                    />
                  <div style={Style.footerBtn}>
                    <button className={`btn--${segments}`} style={Style.saveBtn} onClick={hideModal}>Save</button>
                  </div>
            </div>
        </Modal>
    )
}

const Style = {
    footerBtn: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 28
    },
    saveBtn: {
        height: 48,
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 10,
        width: '48%',
    }
}

export default TableSettingsModal