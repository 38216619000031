import React from 'react'
import axios from 'axios';
import { Input, Icon, Table, Button, Dropdown, Menu, message, Pagination, Select, Spin, Typography } from 'antd'
import adminStyle from './style'
import { useEffect } from 'react'
import { history } from '../../../store/history'
import '../../../static/css/antd.css'
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { Handle401 } from '../../handle401/handle401';
import { isMobile } from 'react-device-detect'
import { randomize } from '../../constants/constants';
import EditContractExpiryModal from './contract_expiry/editContractExpiryModal';
import ContractExpiryModal from './contract_expiry/contractExpiryModal';
import ClearFilter from '../filter_btns/clear_filter_btn';

const { Text, Link } = Typography;
const KybView = props => {

  const ad = adminStyle()
  const ButtonGroup = Button.Group;
  const [searchValue, setSearchValue] = React.useState("");
  const [submissions, setSubmissions] = React.useState([]);
  const [statusSelected, setStatusSelected] = React.useState("All")
  const [segmentSelected, setSegmentSelected] = React.useState("All")
  const [page, setPage] = React.useState(1);
  const [totalSubmissions, setTotalSubmissions] = React.useState(0);
  const [ready, setReady] = React.useState(false);
  const pageSize = 10;
  const partnerRole = ['PF', 'PS']
  const createRole = ['AD', 'SD', 'BS']
  const userType = localStorage.getItem("userType");
  const isMounted = React.useRef(false);
  const [keyAccountType, setKeyAccountType] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false)
  const [isFiltered, setIsFiltered] = React.useState(false)

  //Modals

  const [viewModal, showViewModal] = React.useState(false)
  const [editModal, showEditModal] = React.useState(false)
  const [editData, setEditData] = React.useState('')
  const [viewData, setViewData] = React.useState('')

  const segments = localStorage.getItem('segments')

  let yourConfig = {
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  }

  const status = [
    'All', 'Created', 'Resubmitted', 'Submitted', 'Approved', 'Rejected',
  ]

  const partner_status = [
    'All', 'Pending', 'Approved', 'Rejected',
  ]

  const info = () => {
    message.success("Successful");
  };

  React.useEffect(() => {
    if (!isMounted.current) {
      return;
    }

    setPage(1);
    fetchSubmissions(1, statusSelected, searchValue, keyAccountType, segmentSelected);
  }, [statusSelected]);

  useEffect(() => {
    if (localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") == null) {
      history.push('/login')
    }
    else {
      if (localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO") {
        history.push('/dashboard')
      }
      else if (localStorage.getItem("userType") == "MA") {
        history.push('/overview')
      }
      else {
        fetchSubmissions(1, "All", "", "", "All")
        isMounted.current = true;
      }
    }
  }, [])

  let menu = (record) => (

    <Menu style={{ width: '180px' }}>

      <Menu.Item key="0" onClick={() => window.open(`/submissions_kyb/${record.user}/#kyb_form`, '_blank')}>
        View
      </Menu.Item>
      <Menu.Item key="0" onClick={() => openEditModal(record)}>
        Update Expiry date
      </Menu.Item>
      <Menu.Item key="0" onClick={() => openEditModal(record)}>
        Update Emailer note
      </Menu.Item>
    </Menu>
  );

  let columns = [];

  function closeModal(type, record = null) {

    if (type == 1) {
      showViewModal(false)
    }
    else if (type == 2) {
      showViewModal(false)
      setEditData(record)
      showEditModal(true)
    }
    else {
      showEditModal(false)
    }
  };

  function openEditModal(record) {
    setEditData(record)
    showEditModal(true)
  }

  function openViewModal(record) {
    setViewData(record)
    showViewModal(true)
  }

  if (!partnerRole.includes(userType)) {
    columns = [
      {
        title: 'User',
        dataIndex: 'user',
        width: 150,
        fixed: 'left',
        key: 'user',
        render: (text, record) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '100px', maxWidth: "150px", justifyContent: 'space-between' }}>
              {record.trade_name &&
                <>
                  <div style={{ maxHeight: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <span style={{fontWeight: 600}}>{record.trade_name}</span>
                  </div>
                  <br />
                </>
              }
              <div style={{ maxHeight: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <span>{record.business_name}</span>
              </div>
            </div>
          )
        },
      },
      {
        title: 'Date Submitted',
        width: 150,
        dataIndex: 'submission_date',
        key: 'submission_date',
      },
      {
        title: 'Card Payments',
        width: 150,
        dataIndex: 'card_payments',
        key: 'card_payments',
      },
      {
        title: 'Key Account Type',
        dataIndex: 'key_account',
        key: 'key_account',
        width: 200,
        render: (text) => text ? 'Key' : 'Non-Key'
      },
      {
        title: 'Remarks',
        dataIndex: 'step',
        key: 'remarks',
        width: 200,
        render: (record) => {
          return (
            <div>
              {
                record.step < 8 ? "Incomplete" :
                  record.remarks == null || record.remarks == "" ? "None" :
                    record.remarks
              }
            </div>
          )
        }
      },
      {
        title: 'Status',
        width: 150,
        key: 'status',
        render: (record) => {
          if (!partnerRole.includes(userType)) {
            return (
              <div>
                {
                  record.step < 8 ?
                    <div className={ad.createdStyle}>
                      Created
                    </div> :
                    record.step == 8 ?
                      <div className={ad.submittedStyle}>
                        Submitted
                      </div> :
                      record.step == 10 ?
                        <div className={ad.rejectedStyle}>
                          Rejected
                        </div> :
                        record.step == 9 ?
                          <div className={ad.submittedStyle}>
                            Resubmitted
                          </div> :
                          record.step == 11 || record.step == 12 ?
                            <div className={ad.approvedStyle}>
                              Approved
                            </div> : ""
                }
              </div>
            )
          } else {
            return (
              <div>
                {
                  record.approved == 0 ? "Pending" : record.approved == 1 ? "Approved" : "Rejected"
                }
              </div>
            )

          }
        }
      },
      {
        title: 'Contract Expiry',
        width: 150,
        key: 'contract_expiry',
        render: (record) => {
          if (record.step == 11 || record.step == 12) {
            return (
              <div>
                {
                  record.contract_expiry ? moment(record.contract_expiry, 'MMMM DD YYYY, hh:mm A').format("MMMM DD YYYY") : "N/A"
                }
              </div>
            )
          }
          else {
            return (
              <div>
                N/A
              </div>
            )
          }
        }
      },
      {
        title: 'Emailer Note',
        width: 150,
        key: 'notes',
        render: (record) => {
          if (record.step == 11 || record.step == 12) {
            if (record.notes) {
              return (
                <Button onClick={() => openViewModal(record)} type='link'>
                  <Text style={{
                    color: '#F5922F',
                    fontSize: 14,
                  }}
                    underline>View note</Text>
                </Button>
              )
            } else {
              return (
                <Button onClick={() => openEditModal(record)} type='link' >
                  <Text style={{
                    color: '#F5922F',
                    fontSize: 14,
                  }}
                    underline>+ Add note</Text>
                </Button>
              )
            }
          } else {
            return (
              <div>
                N/A
              </div>
            )
          }

        }
      },
      {
        title: 'Action',
        dataIndex: 'action',
        width: 150,
        key: 'action',
        render: (text, record) => (
          <div>
            <Dropdown overlay={() => menu(record)}
              disabled={record.step < 8}
              placement="bottomCenter" trigger={['click']} >
              <Icon type="ellipsis" />
            </Dropdown>
          </div>
        )
      }
    ];
  } else {
    columns = [
      {
        title: 'User',
        key: 'user',
        width: 150,
        fixed: 'left',
        render: (text, record) => {
          return (<div>{record.business_name}</div>)
        },
      },
      {
        title: 'Date Submitted',
        width: 150,
        dataIndex: 'submission_date',
        key: 'submission_date',
      },
      {
        title: 'Key Account Type',
        dataIndex: 'key_account',
        key: 'key_account',
        width: 200,
        render: (text) => text ? 'Key' : 'Non-Key'
      },
      {
        title: 'Remarks',
        key: 'remarks',
        width: 200,
        render: (record) => {
          return (
            <div>
              {
                record.step < 8 ? "Incomplete" :
                  record.remarks == null || record.remarks == "" ? "None" :
                    record.remarks
              }
            </div>
          )
        }
      },
      {
        title: 'Status',
        width: 150,
        key: 'status',
        render: (record) => {
          if (!partnerRole.includes(userType)) {
            return (
              <div>
                {
                  record.step == 8 ? "Submitted" : record.step == 10 ? "Rejected" :
                    record.step == 9 ? "Resubmitted" : record.step == 11 || record.step == 12 ? "Approved" : ""
                }
              </div>
            )
          } else {
            return (
              <div>
                {
                  record.approved == 0 ? "Pending" : record.approved == 1 ? "Approved" : "Rejected"
                }
              </div>
            )

          }
        }
      },
      {
        title: 'Contract Expiry',
        width: 150,
        dataIndex: 'contract_expiry',
        key: 'contract_expiry',
      },
      {
        title: 'Emailer Note',
        dataIndex: 'notes',
        width: 150,
        key: 'notes',
      },
      {
        title: 'Action',
        key: 'action',
        width: 150,
        render: (text, record) => (
          <div>
            <Dropdown overlay={() => menu(record.user)}
              disabled={record.step < 8}
              placement="bottomCenter" trigger={['click']} >
              <Icon type="ellipsis" />
            </Dropdown>
          </div>
        )
      }
    ];
  }

  const pageSelect = (e) => {
    setPage(e);
    fetchSubmissions(e, statusSelected, searchValue, keyAccountType, segmentSelected);
  }



  async function fetchSubmissions(page, status, search, account_type, segments) {
    const endpoint_url = process.env.REACT_APP_API_URL + '/api/admin/kyb/'
    let query_params = [
      'page=' + page,
      'status=' + status,
      'search=' + search,
      'key_account_type=' + account_type,
      'segments=' + segments,
    ]
    const full_url = endpoint_url + '?' + query_params.join("&")

    let response = await axios.get(
      full_url,
      yourConfig
    );

    setSubmissions(response.data.results)
    console.log(response.data.results)
    setTotalSubmissions(response.data.total)
    setReady(true)
  }

  function submissionKyb(record) {
    if (record.step <= 7) {
      localStorage.setItem('KYBType', 'creation')
      localStorage.setItem('enterpriseId', record.user)
      window.open(`/upgrade_enterprise`, '_blank')
    }

    else if (record.step > 7) {
      localStorage.setItem('enterpriseId', record.user)
    }

    else {
      localStorage.setItem('KYBType', '')
      localStorage.setItem('enterpriseId', 0)
      localStorage.setItem('spocEmail', '')
      window.open(`/submissions_kyb/${record.user}/#kyb_form`, '_blank')
    }

  }

  async function createKyb() {
    let response = await axios.get(process.env.REACT_APP_API_URL + '/api/kyb/', yourConfig);
    console.log(response)
    localStorage.setItem('KYBType', 'creation')
    localStorage.setItem("enterpriseId", 0);
    history.push('/upgrade_enterprise')
  }

  const exportTable = () => {
    let options = {
      filename: 'KYB_' + moment().format("MM-DD-YYYY_HH:mm:ss") + '_' + statusSelected,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'BUx KYB',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };

    let csvExporter = new ExportToCsv(options);

    if (submissions.length) {
      csvExporter.generateCsv(submissions);
    }
  };

  async function syncSalesforce() {
    try {
      setIsLoading(!isLoading)
      let url = process.env.REACT_APP_API_URL + `/api/salesforce/sync/`

      let params = {
        type: 'batch'
      }

      let response = await axios.post(url, params, yourConfig)
      if (response.data.status === 'success') {
        setIsLoading(isLoading)
        message.destroy()
        message.success(response.data.message, 5)
      }

      else {
        setIsLoading(isLoading)
        errorMessage(response.data.message)
      }
    }

    catch (error) {
      console.log(error.response)
      setIsLoading(isLoading)
      errorMessage(error.response.data.message)

    }
  }

  const errorMessage = (msg) => {
    message.destroy()
    message.error(msg, 2)
  }

  const clearFilter = () => {
    setIsFiltered(false)
    setStatusSelected("All")
    setSearchValue("")
    setKeyAccountType("All")
    setSegmentSelected("All")
    fetchSubmissions(1, "All", "", "All", "All");
  }

  const applyBtn = () => {
    fetchSubmissions(1, statusSelected, searchValue, keyAccountType, segmentSelected);
  }

  return (
    <div style={{padding: '0 56px 64px 0'}} hidden={!ready}>
      <div className="screen-title-text">
        KYB Submissions
      </div>

      <div className="top-16 twoCol">
        <div>
         {!partnerRole.includes(userType) && (
            <ButtonGroup>
              {
                status.map((item) => {
                  return <Button
                    key={item.toLocaleLowerCase()}
                    onClick={() => setStatusSelected(item)}
                    className={`userNameStyle ${item === statusSelected ? `bg-${segments}` : ``}`}
                    style={{
                      height: '40px',
                      borderRadius: item === "All" ? '4px 0px 0px 4px' : item === 'Rejected' ? '0px 4px 4px 0px' : 0,
                      color: statusSelected === item ? '#ffffff' : '#2b2d33',
                      // backgroundColor: statusSelected === item ? '#0D3D76' : '#fff',
                    }}>{item}</Button>
                })
              }
            </ButtonGroup>
          )}`

          {partnerRole.includes(userType) && (
            <ButtonGroup>
              {
                partner_status.map((item) => {
                  return <Button
                    key={item.toLocaleLowerCase()}
                    onClick={() => setStatusSelected(item)}
                    className={ad.buttonStyle}
                    style={{
                      borderRadius: item === "All" ? '10px 0px 0px 10px' : item === 'Rejected' ? '0px 10px 10px 0px' : 0,
                      color: statusSelected === item ? '#ffffff' : '#2b2d33',
                      backgroundColor: statusSelected === item ? '#0D3D76' : '#fff',
                    }}>{item}</Button>
                })
              }
            </ButtonGroup>
          )}
        </div>

        <div>
          <button
            className="admin-download-btn right-8"
            onClick={() => exportTable()}>
              <Icon type="download" className="right-4" />  Download CSV
          </button>

          <button
            hidden={!createRole.includes(userType)}
            className={`btn--${segments} btn-height`}
            onClick={() => createKyb()}
          >
            Create New Account
          </button>

        </div>
      </div>

      <div className="table-card-div">
        <ClearFilter hideFilterBtn={!isFiltered} clearFilter={clearFilter} />

        <div className="filter-div">
          <Select
            size="large"
            placeholder="BUx Segments"
            value={segmentSelected}
            style={{width:'10vw', marginRight: '12px', zIndex: 1}}
            onChange={(e) => {
              setSegmentSelected(e);
              setIsFiltered(true)
            }}
          >
            <Select.Option value="All">All</Select.Option>
            <Select.Option value="SME">BUxSME</Select.Option>
            <Select.Option value="BIZ">BUxBIZ</Select.Option>
          </Select>

          <Select
            size="large"
            value={keyAccountType}
            placeholder="Key Account Type"
            style={{width:'10vw', marginRight: '12px', zIndex: 1}}
            onChange={(e) => {
              setKeyAccountType(e);
              setIsFiltered(true)
            }}
          >
            <Select.Option value="All">All</Select.Option>
            <Select.Option value="Key">Key</Select.Option>
            <Select.Option value="Non-Key">Non-Key</Select.Option>
          </Select>

          <Input
            placeholder="Search"
            value={searchValue}
            style={{ width: '200px', height: '40px', zIndex: 100 }}
            onChange={(e) => setSearchValue(e.target.value)/setIsFiltered(true)}
            prefix={<Icon type="search" />}
          />

          <button
              style={{marginLeft: 15}}
              className={`admin-apply-btn ${!isFiltered ? "admin-disabled-btn" : ""}`}
              disabled={!isFiltered}
              onClick={applyBtn}
          >
              Apply
          </button>
        </div>

        <div className="flex-center">
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: event => submissionKyb(record)
              }
            }}
            style={{ width: '75vw', flexGrow: '100' }}
            pagination={{ hideOnSinglePage: true, pageSize: pageSize }}
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
            dataSource={submissions}
            columns={columns}
            rowKey={(record) => record.id}
            scroll={{ x: 1920 }}
          />
        </div>
        <div style={{ width: '100%', textAlign: 'right'}} className="top-20">
          <Pagination
            size="small"
            total={totalSubmissions}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            onChange={pageSelect}
            defaultCurrent={1}
            key={randomize()}
            pageSize={pageSize}
            current={page}
          />
        </div>
        <EditContractExpiryModal fetchSubmissions={fetchSubmissions} openModal={editModal} editData={editData} closeModal={closeModal} />
        <ContractExpiryModal fetchSubmissions={fetchSubmissions} openModal={viewModal} viewData={viewData} closeModal={closeModal} />
      </div>

    </div>
  )

}
export default KybView;