import React, {useEffect} from 'react'
import { Table, Typography, Dropdown, Icon, Menu, Modal, message} from 'antd'
import {history} from '../../../store/history'
import CustomerOrderDetails from './customer_order.details'
import CancelModal from './cancel_modal'
import axios from 'axios'
import { CC } from '../../../static/icons/payment_method_icons'
import Visa from '../../../static/icons/card_type/visa.svg'
import MasterCard from '../../../static/icons/card_type/mastercard.svg'

const TransactionsTable = props => {

    const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
    const isMac = window.screen.width * window.devicePixelRatio == 1440 && window.screen.height * window.devicePixelRatio == 900;
    const testMode = (history.location.pathname.indexOf('/test') > -1&&localStorage.getItem("userType") == "CO")
    const ubp_enabled = localStorage.getItem('cc_enabled') === 'UnionBank'

    const {
        dataSource,
        selectedTab,
        loading,
        checkedColumns,
        fetchData,
        segments
    } = props

    //Local storage datas
    const userType = localStorage.getItem('userType')
    const isAdmin =  !['ME', 'CO', 'SW'].includes(userType)

    //State
    const [tableColumns, setTableColumns] = React.useState([])
    const [idToBeCancel, setIDToBeCancel] = React.useState("")
    const [selectedOrderDetails, setSelectedOrderDetails] = React.useState(null)

    //Modal
    const [customerDetailsModal, setCustomerDetailsModal] = React.useState(false)
    const [cancelModal, setCancelModal] = React.useState(false)
    const [refundModal, setRefundModal] = React.useState(false)
    const [channelCancel, setChannelCancel] = React.useState('')

    const is_admin = () =>{
        return ["AD", "OP", "PS", "FI"].includes(localStorage.getItem("userType")); 
    }


    useEffect(()=>{
        setTableColumnsNow(selectedTab)
    },[selectedTab])


    const resend = async(id) =>{
        try {
            let params = {
                id: id
              }
              let yourConfig = {
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
              }
              const url = testMode? '/api/sandbox/resend/' : '/api/orders/resend/'
              let response = await axios.post(process.env.REACT_APP_API_URL + url, params, yourConfig);
              
              if(response.data.status == "success"){
                message.success('Successfully sent')
                fetchData()
              }
        } catch(error){
            message.destroy();
            message.error('An error has occured. Please try again later.');
            console.log(error);
        }
    }
    
    const simulatePayment = async(id) => {
        let yourConfig = {
          headers: {
              Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }
    
        let response = await axios.get(process.env.REACT_APP_API_URL + `/api/sandbox/simulate/?id=${id}`, yourConfig);
        
        if(response.data.status == "success"){
          message.success('Successfully Paid')
          fetchData()
        }
    }

    const resendPaymentNotif= async(id) => {
        let yourConfig = {
          headers: {
              Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }
    
        let response = await axios.get(process.env.REACT_APP_API_URL + `/payment_simulate/${id}/`, yourConfig);
        console.log(response)
        if(response.data.includes("successfully paid")){
          message.success('Successfully sent.')
          fetchData()
        }
    }

    const menu = (details) => {
        return (
            <Menu style={{ width: '214px' }}>
                <Menu.Item key="0" onClick={() => history.push(`${testMode?'/test/payment/':'/payment/'}`+details.uid+`/`)} style={Style.menuStyle}>
                    Customer checkout
                </Menu.Item>
                <Menu.Item key="1" onClick={() => {setSelectedOrderDetails(details); setCustomerDetailsModal(true)}}style={Style.menuStyle} hidden={testMode}>
                View payment details
                </Menu.Item>        
                <Menu.Item key="2" onClick={() => {setIDToBeCancel(details.id); setCancelModal(true)}} style={Style.menuStyle}>
                    Cancel payment
                </Menu.Item>
                {details.channel_str ? (
                    <Menu.Item key="3" onClick={() => resend(details.id)} style={Style.menuStyle}>
                        Resend payment instructions
                    </Menu.Item>
                ): ""}
                <Menu.Item key="4" onClick={() => simulatePayment(details.id)} style={Style.menuStyle} hidden={!testMode}>
                    Simulate Payment
                </Menu.Item>
            </Menu>
        )
    };
      
    const expired_cancelled_menu = (details) => (
        <Menu style={{ width: '180px' }}>
          <Menu.Item key="0" onClick={() => {setSelectedOrderDetails(details); setCustomerDetailsModal(true);}} style={Style.menuStyle} hidden={testMode}>
             View payment details
          </Menu.Item>    
        </Menu>
      )
    
    const paid_menu = (details) => (
        <Menu style={{ width: isAdmin?'230px': '180px' }}>
          <Menu.Item key="0" onClick={() => history.push(`${testMode?'/test/payment/':'/payment/'}`+details.uid+`/`)} style={Style.menuStyle} >
            Customer receipt
          </Menu.Item>
          <Menu.Item key="1" onClick={() => {setSelectedOrderDetails(details); setCustomerDetailsModal(true)}} style={Style.menuStyle} hidden={testMode}>
             View payment details
          </Menu.Item>
          <Menu.Item key="2" onClick={() => resendPaymentNotif(details.code)} style={Style.menuStyle} hidden={!isAdmin}>
            Resend payment notification
          </Menu.Item>
          {/* {
              details.channel === 'ubpcs' && isAdmin &&
                <Menu.Item key="3" onClick={() => {setIDToBeCancel(details.id); setCancelModal(true); setChannelCancel('UnionBank')}} style={Style.menuStyle} hidden={!isAdmin}>
                    Mark as Refund
                </Menu.Item>
          } */}
        </Menu>
    )

    const computeTotalAmount = (record) => {
        if (!testMode && record && record.promo_code) {
            const total = record && record.seller_net ? parseFloat( record.seller_net + record.total_fee - (record.promo_discount ? record.promo_discount : 0)) : parseFloat(record.amount - (record.promo_discount ? record.promo_discount : 0));
            if ( total < 50) {
                return parseFloat(50).toLocaleString("en-US", {minimumFractionDigits: 2});
            }
                return total.toLocaleString("en-US", {minimumFractionDigits: 2})
        } else {
            return parseFloat(record.amount).toLocaleString("en-US", {minimumFractionDigits: 2})
        }
    }
    
    const charged_menu = (id,uid) => (
        <Menu style={{ width: '180px' }}>
          <Menu.Item key="0" style={Style.menuStyle} onClick={() => history.push(`${testMode?'/test/payment/':'/payment/'}`+uid+`/`)}>
            Customers Receipt
          </Menu.Item>
          <Menu.Item key="1" onClick={()=>history.push(`/charged_details/${id}/${uid}`)}style={Style.menuStyle}>
            Charge Details
          </Menu.Item>
        </Menu>
      )

    const all_status_columns = [
        {
            title: 'Trans ID',
            dataIndex: 'id',
            width: 80,
            fixed: 'left',
            render: (text, record) => {
                return text
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 100,
            fixed: 'left',
            render: (text) => {
                const statuses = {
                    'PA': 'Paid',
                    'CA': 'Cancelled',
                    'EX': 'Expired',
                    'PE': 'Pending'
                  };
                  
                  return statuses[text] || text;
                  
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Business Name',
            dataIndex: 'business_name',
            width: 200,
            fixed: 'left',
            render: (text) => <Typography style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 200, overflow: 'hidden' }}>{text}</Typography>,
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Reference no.',
            dataIndex: 'code',
            width: 150,
            render: (text, record) => {
                return <div style={Style.dataStyle}>{record.code} </div>
                       
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Channel',
            dataIndex: 'channel_str',
            width: 120,
            render: (text, record) => {
                return <div style={Style.channelDivStyle}>
                    {
                        text === record.terminal?
                        <>
                            <Typography style={Style.dataStyle}>{text}</Typography>
                        </>
                        :
                        <>
                            <Typography style={Style.dataStyle}>{text}</Typography>
                            <Typography style={Style.descSyle}>{record.terminal}</Typography>
                        </>
                        }
                    </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: 160,
            render: text => <Typography style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 160, overflow: 'hidden' }}>{text}</Typography>,
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            },
        },
        {
            title: 'Field 1',
            dataIndex: 'param1',
            width: 90,
            render: (text, record) => {
                return <div>
                    {(record.payload && record.payload.param1) ? returnAddParam(record.payload.param1) : "--"}
                </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Field 2',
            dataIndex: 'param2',
            width: 90,
            render: (text, record) => {
                return <div>
                    {(record.payload && record.payload.param2) ? returnAddParam(record.payload.param2) : "--"}
                </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Date Generated',
            dataIndex: 'timestamp',
            width: 200,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Date Paid',
            dataIndex: 'date_paid',
            width: 200,
            render: text => {
                return <div>
                <Typography style={Style.dataStyle}>{text}</Typography>
                </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Amount(₱)',
            dataIndex: 'amount',
            width: 120,
            render: (text, record) => {
                return <span style={Style.dataStyle}>₱ {text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        // {
        //     title: 'Discounted Amount(₱)',
        //     dataIndex: 'amount',
        //     width: 120,
        //     render: (text, record) => {
        //         return <span style={Style.dataStyle}>₱ {computeTotalAmount(record)}</span>
        //     }
        // },
        {
            title: 'Receivable',
            dataIndex: 'seller_net',
            width: 120,
            render: text => {
                return <span style={Style.dataStyle}>₱ {parseFloat(text).toFixed(2)}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Promo Discount (₱)',
            dataIndex: 'promo_discount',
            width: 150,
            render: (text, record) => {
                return <span style={Style.dataStyle}>{record.promo_code? ("₱ " + parseFloat(text).toFixed(2)) : "-"}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Promo Code',
            dataIndex: 'promotion_code',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text? text : "-"}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Customer Name',
            dataIndex: 'buyer_name',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Customer Email',
            dataIndex: 'buyer_email',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Owner',
            width: 150,
            dataIndex: 'owner',
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Action',
            width: 70,
            fixed: 'right',
            render: (text, record) => (
                <span>
                    <Dropdown overlay={() => paid_menu(record)} placement="bottomLeft" trigger={['click']} >
                        <Icon type="ellipsis" style={{fontSize: '24px'}} />
                    </Dropdown>
                </span>
            ),
        }
    ]

    const paid_columns = [
        {
            title: 'Trans ID',
            dataIndex: 'id',
            width: 80,
            fixed: 'left',
            render: (text, record) => {
                return text
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Business Name',
            dataIndex: 'business_name',
            width: 120,
            fixed: 'left',
            render: (text) => <Typography style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 200, overflow: 'hidden' }}>{text}</Typography>,
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Reference no.',
            dataIndex: 'code',
            width: 150,
            render: (text, record) => {
                return <div style={Style.dataStyle}>{record.code} </div>
                       
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Channel',
            dataIndex: 'channel_str',
            width: 120,
            render: (text, record) => {
                return <div style={Style.channelDivStyle}>
                    {
                        text === record.terminal?
                        <>
                            <Typography style={Style.dataStyle}>{text}</Typography>
                        </>
                        :
                        <>
                            <Typography style={Style.dataStyle}>{text}</Typography>
                            <Typography style={Style.descSyle}>{record.terminal}</Typography>
                        </>
                        }
                    </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Field 1',
            dataIndex: 'param1',
            width: 90,
            render: (text, record) => {
                return <div>
                    {(record.payload && record.payload.param1) ? returnAddParam(record.payload.param1) : "--"}
                </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Field 2',
            dataIndex: 'param2',
            width: 90,
            render: (text, record) => {
                return <div>
                    {(record.payload && record.payload.param2) ? returnAddParam(record.payload.param2) : "--"}
                </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Date Generated',
            dataIndex: 'timestamp',
            width: 200,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Date Paid',
            dataIndex: 'date_paid',
            width: 200,
            render: text => {
                return <div>
                <Typography style={Style.dataStyle}>{text}</Typography>
                </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Amount(₱)',
            dataIndex: 'amount',
            width: 120,
            render: (text, record) => {
                return <span style={Style.dataStyle}>₱ {text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        // {
        //     title: 'Discounted Amount(₱)',
        //     dataIndex: 'amount',
        //     width: 120,
        //     render: (text, record) => {
        //         return <span style={Style.dataStyle}>₱ {computeTotalAmount(record)}</span>
        //     }
        // },
        {
            title: 'Receivable',
            dataIndex: 'seller_net',
            width: 120,
            render: text => {
                return <span style={Style.dataStyle}>₱ {parseFloat(text).toFixed(2)}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Promo Discount (₱)',
            dataIndex: 'promo_discount',
            width: 150,
            render: (text, record) => {
                return <span style={Style.dataStyle}>{record.promo_code? ("₱ " + parseFloat(text).toFixed(2)) : "-"}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Promo Code',
            dataIndex: 'promotion_code',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text? text : "-"}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Customer Name',
            dataIndex: 'buyer_name',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Customer Email',
            dataIndex: 'buyer_email',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Owner',
            width: 150,
            dataIndex: 'owner',
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Action',
            width: 70,
            fixed: 'right',
            render: (text, record) => (
                <span>
                    <Dropdown overlay={() => paid_menu(record)} placement="bottomLeft" trigger={['click']} >
                        <Icon type="ellipsis" style={{fontSize: '24px'}} />
                    </Dropdown>
                </span>
            ),
        }

    ];
    const pending_columns = [
        {
            title: 'Trans ID',
            dataIndex: 'id',
            width: 80,
            fixed: 'left',
            render: (text, record) => {
                return text
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Business Name',
            dataIndex: 'business_name',
            width: 120,
            fixed: 'left',
            render: (text) => <Typography style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 200, overflow: 'hidden' }}>{text}</Typography>,
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Reference no.',
            dataIndex: 'code',
            width: 150,
            render: (text, record) => {
                if(record.code){
                    return  <div style={Style.dataStyle}>{record.code} </div>
                }else{
                    return <a style={{ color: '#909196', fontSize: '14px'}}>Pending </a>        
                }
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Channel',
            dataIndex: 'channel_str',
            width: 120,
            render: (text, record) => {
                if(text)
                return  <div style={Style.channelDivStyle}>
                            {
                        text === record.terminal?
                        <>
                            <Typography style={Style.dataStyle}>{text}</Typography>
                        </>
                            :
                            <>
                            <Typography style={Style.dataStyle}>{text}</Typography>
                            <Typography style={Style.descSyle}>{record.terminal}</Typography>
                        </>
                        }
                        </div>
                else
                return <div style={Style.noChannelYet}>Customer has not yet selected a payment channel.</div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Field 1',
            dataIndex: 'param1',
            width: 90,
            render: (text, record) => {
                return <div>
                    {(record.payload && record.payload.param1) ? returnAddParam(record.payload.param1) : "--"}
                </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Field 2',
            dataIndex: 'param2',
            width: 90,
            render: (text, record) => {
                return <div>
                    {(record.payload && record.payload.param2) ? returnAddParam(record.payload.param2) : "--"}
                </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Date Generated',
            dataIndex: 'timestamp',
            width: 200,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Date Expires',
            dataIndex: 'expire_at',
            width: 200,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Amount(₱)',
            dataIndex: 'amount',
            width: 120,
            render: (text, record) => {
                return <span style={Style.dataStyle}>₱ {text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        // {
        //     title: 'Discounted Amount(₱)',
        //     dataIndex: 'amount',
        //     width: 120,
        //     render: (text, record) => {
        //         return <span style={Style.dataStyle}>₱ {computeTotalAmount(record)}</span>
        //     }
        // },
        {
            title: 'Promo Discount (₱) ',
            dataIndex: 'promo_discount',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>₱ {text? parseFloat(text).toFixed(2) : 0.00}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Promo Code (₱) ',
            dataIndex: 'promotion_code',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text? text : "-"}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Customer Name',
            dataIndex: 'buyer_name',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Customer Email',
            dataIndex: 'buyer_email',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Action',
            width: 70,
            fixed: 'right',
            render: (text, record) => (
                <span>
                    <Dropdown overlay={() => menu(record)} placement="bottomLeft" trigger={['click']} >
                        <Icon type="ellipsis" style={{fontSize: '24px'}}/>
                    </Dropdown>
                </span>
            ),
        },
        
    ];
    const cancelled_columns = [
        {
            title: 'Trans ID',
            dataIndex: 'id',
            width: 80,
            fixed: 'left',
            render: (text, record) => {
                return text
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Business Name',
            dataIndex: 'business_name',
            width: 120,
            fixed: 'left',
            render: (text) => <Typography style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 200, overflow: 'hidden' }}>{text}</Typography>,
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Reference no.',
            dataIndex: 'code',
            width: 150,
            render: (text, record) => {
                if(record.code){
                    return  <div style={Style.dataStyle}>{record.code} </div>
                }else{
                    return <a style={{ color: '#909196', fontSize: '14px'}}>Pending </a>        
                }
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Channel',
            dataIndex: 'channel_str',
            width: 120,
            render: (text, record) => {
                if(text)
                return  <div style={Style.channelDivStyle}>
                            <Typography style={Style.dataStyle}>{text}</Typography>
                            <Typography style={Style.descSyle}>{record.terminal}</Typography>
                        </div>
                else
                return <div style={Style.noChannelYet}>Customer has not yet selected a payment channel.</div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Field 1',
            dataIndex: 'param1',
            width: 90,
            render: (text, record) => {
                return <div>
                    {(record.payload && record.payload.param1) ? returnAddParam(record.payload.param1) : "--"}
                </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Field 2',
            dataIndex: 'param2',
            width: 90,
            render: (text, record) => {
                return <div>
                    {(record.payload && record.payload.param2) ? returnAddParam(record.payload.param2) : "--"}
                </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Date Generated',
            dataIndex: 'timestamp',
            width: 200,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Date Cancelled',
            dataIndex: 'cancelled_at',
            width: 200,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Reason',
            dataIndex: 'remarks',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Amount (₱)',
            dataIndex: 'amount',
            width: 120,
            render: (text, record) => {
                return <span style={Style.dataStyle}>₱ {text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        // {
        //     title: 'Discounted Amount(₱)',
        //     dataIndex: 'amount',
        //     width: 120,
        //     render: (text, record) => {
        //         return <span style={Style.dataStyle}>₱ {computeTotalAmount(record)}</span>
        //     }
        // },
        {
            title: 'Promo Discount (₱) ',
            dataIndex: 'promo_discount',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>₱ {text? parseFloat(text).toFixed(2) : 0.00}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Promo Code (₱) ',
            dataIndex: 'promotion_code',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text? text : "-"}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Customer Name',
            dataIndex: 'buyer_name',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Customer Email',
            dataIndex: 'buyer_email',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Action',
            width: 70,
            fixed: 'right',
            render: (text, record) => (
                <span>
                    <Dropdown overlay={() => expired_cancelled_menu(record)} placement="bottomLeft" trigger={['click']} >
                        <Icon type="ellipsis" style={{fontSize: '24px'}}/>
                    </Dropdown>
                </span>
            ),
        },
    ];

    const expired_columns = [
        {
            title: 'Trans ID',
            dataIndex: 'id',
            width: 80,
            fixed: 'left',
            render: (text, record) => {
                return text
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Business Name',
            dataIndex: 'business_name',
            width: 120,
            fixed: 'left',
            render: (text) => <Typography style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 200, overflow: 'hidden' }}>{text}</Typography>,
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Reference no.',
            dataIndex: 'code',
            width: 150,
            render: (text, record) => {
                if(record.code){
                    return  <div style={Style.dataStyle}>{record.code} </div>
                }else{
                    return <a style={{ color: '#909196', fontSize: '14px'}}>Pending </a>        
                }
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Channel',
            dataIndex: 'channel_str',
            width: 120,
            render: (text, record) => {
                if(text)
                return  <div style={Style.channelDivStyle} >
                        {
                        text === record.terminal?
                        <>
                            <Typography style={Style.dataStyle}>{text}</Typography>
                        </>
                            :
                            <>
                            <Typography style={Style.dataStyle}>{text}</Typography>
                            <Typography style={Style.descSyle}>{record.terminal}</Typography>
                        </>
                        }
                        </div>
                else
                return <Typography style={Style.noChannelYet}>Customer has not yet selected a payment channel.</Typography>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Field 1',
            dataIndex: 'param1',
            width: 90,
            render: (text, record) => {
                return <div>
                    {(record.payload && record.payload.param1) ? returnAddParam(record.payload.param1) : "--"}
                </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Field 2',
            dataIndex: 'param2',
            width: 90,
            render: (text, record) => {
                return <div>
                    {(record.payload && record.payload.param2) ? returnAddParam(record.payload.param2) : "--"}
                </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Date Generated',
            dataIndex: 'timestamp',
            width: 200,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Date Expired',
            dataIndex: 'expire_at',
            width: 200,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Amount (₱)',
            dataIndex: 'amount',
            width: 120,
            render: (text, record) => {
                return <span style={Style.dataStyle}>₱ {text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        // {
        //     title: 'Discounted Amount(₱)',
        //     dataIndex: 'amount',
        //     width: 120,
        //     render: (text, record) => {
        //         return <span style={Style.dataStyle}>₱ {computeTotalAmount(record)}</span>
        //     }
        // },
        {
            title: 'Promo Discount (₱) ',
            dataIndex: 'promo_discount',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>₱ {text? parseFloat(text).toFixed(2) : 0.00}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Promo Code (₱) ',
            dataIndex: 'promotion_code',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text? text : "-"}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Customer Name',
            dataIndex: 'buyer_name',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Customer Email',
            dataIndex: 'buyer_email',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Action',
            width: 70,
            fixed: 'right',
            render: (text, record) => (
                <span>
                    <Dropdown overlay={() => expired_cancelled_menu(record)} placement="bottomLeft" trigger={['click']} >
                        <Icon type="ellipsis" style={{fontSize: '24px'}}/>
                    </Dropdown>
                </span>
            ),
        },
    ];

    const refundedColumn = [
        {
            title: 'Charge ID / Reference Code',
            width: is_admin()  ? '18%' : '20%',
            render: (record) => {
              return <div style={Style.dataStyle}> {record.code} </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: selectedTab === 'Refunded' ? 'Amount Refunded' : 'Amount',
            width: selectedTab === 'Refunded' ? '18%' : '15%',
            render: (record) => {
              return <div style={{fontSize: '14px', color: '#2b2d33', fontWeight: '500'}}> ₱ {record.amount} </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Card',
            width: is_admin()  ? '15%' : '18%',
            render: (record) => {
              return <div style={Style.dataStyle}>
                        <img style={{marginRight: '10px'}} src={record.payload['card_brand'] == 'VISA' || record.payload['card_type'] == 'Visa' ? Visa : MasterCard}/>
                        •••• {ubp_enabled ? record.payload['last_four_digits'].slice(-4) : record.payload['last_four_digits']} 
                    </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },
        {
            title: 'Date Paid',
            width: is_admin() || selectedTab === "Refunded" ? '15%' : '20%',
            render: (record) => {
              return <div style={Style.dataStyle}> {record.date_paid} </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        },

        selectedTab === "Refunded" ?
        {
            title: 'Date Refunded',
            width: is_admin()  ? '13%' : '15%',
            render: (record) => {
              return <div style={Style.dataStyle}> {record.cancelled_at} </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {setSelectedOrderDetails(record); setCustomerDetailsModal(true)}, // click row
                };
            }
        } : {} ,

        {
            title: 'Action',
            width: '15%',
            render: (record) => (
                <span>
                    <Dropdown overlay={() => charged_menu(record.id, record.uid)} placement="bottomCenter" trigger={['click']} >
                        <Icon type="ellipsis" style={{fontSize: '24px'}} />
                    </Dropdown>
                </span>
            )
        },
    ]

    const returnAddParam = (param) => {
        if(typeof param === 'object') {
         return ( 
          <div>
            <Typography style={Style.dataStyle}>{param.name ? param.name : ""}</Typography>
            <Typography style={Style.descSyle}>{param.value ? param.value : "--"}</Typography>
          </div>
          )
        }
        return  <Typography style={Style.dataStyle}>{param != "" ? param : "--"}</Typography>
    }

    const setTableColumnsNow = (selected) => {
        switch (selected) {
            case 'Paid':
                return setTableColumns(paid_columns);
            case 'Pending':
                return setTableColumns(pending_columns);
            case 'Cancelled':
                return setTableColumns(cancelled_columns);
            case 'Expired':
                return setTableColumns(expired_columns);
            case 'Refunded':
                return setTableColumns(refundedColumn);
            default:
                return setTableColumns(all_status_columns);
        }
    }

    const arrangeTableColumns = (columns) => {
        const adminHeaders = !isAdmin ? ['id', 'business_name', 'owner'] : null;
        if(userType == "SW") {
          adminHeaders.push('param1', 'param2');
        }

        var filtered = columns? columns : [];
    
        adminHeaders && adminHeaders.map((col, i) => {
          filtered = filtered.filter(el => {return el.dataIndex !== col})
        })

        if(segments === 'sme' || segments === 'biz' || testMode || userType === 'SW'){
            filtered = filtered.filter(el => {return el.dataIndex != 'promo_discount' && el.dataIndex != 'promotion_code'})
        }

        if(checkedColumns.length > 0) {
          for(var i =0;i< checkedColumns.length; i++)
            filtered = filtered.filter(el => {return el.dataIndex !== checkedColumns[i]})
        }
        return filtered;
    }
    return(
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <Table 
                dataSource={dataSource}
                loading={loading}
                style={{width: isMac ? '81vw' : isHighReso ? '83vw' : '77vw' , display: 'block'}}
                scroll={{x: selectedTab === "Paid" ? 2400 : 2300 }}
                pagination={{position: 'none', pageSize: '20'}}
                size="middle"
                columns={arrangeTableColumns(tableColumns)}
            />
            <Modal
                visible={customerDetailsModal}
                footer={null}
                width="478px"
                onCancel={()=>setCustomerDetailsModal(false)}
                style={{top: '30px'}}
            >
                <CustomerOrderDetails details={selectedOrderDetails}/>
            </Modal>
            <CancelModal fetchData={fetchData} idToBeCancel={idToBeCancel} closeModal={()=>setCancelModal(false)} cancelVisible={cancelModal} channelCancel={channelCancel} />
        </div>
       
    )
}

const Style = {
    dataStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2B2D33'
    },
    menuStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33',
        padding: '10px',
        margin: 'auto'
    }
}
export default TransactionsTable