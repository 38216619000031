import { get, patch, post } from '../methods';
import { Handle401 } from '../../components/handle401/handle401';

const myProfileServices = {
    getProfileDetails: async (params) => {
        try {
            const url = `/api/details/`;
            return (await get(url, params)).data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getKybDetails: async (userId) => {
        try {
            const url = `/api/kyb/${userId}/`;
            return (await get(url)).data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getProfilePic: async () => {
        try {
            const url = `/api/upload_profile_picture/`;
            return (await get(url)).data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    uploadProfilePic: async (params) => {
        try {
            const url = `/api/upload_profile_picture/`;
            return (await post(url, params)).data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getKycDetails: async (params) => {
        try {
            const url = `/api/kyc/`;
            return (await get(url, params)).data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    updatePassword: async (params) => {
        try {
            const url = `/api/change_password/`;
            return (await post(url, params)).data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
            if ((error.response) && (error.response.status == 403)) {
                return {
                    'status': 'error',
                    'message':"ERROR: Please try again later."
                }
            }
        }
    },
    updateKycInfo: async (params) => {
        try {
            const url = `/api/update_address/`;
            return (await post(url, params)).data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    updateKybBusinessName: async (params) => {
        try {
            const url = `api/update_business/`;
            return (await patch(url, params)).data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    updateMobileNumber: async () => {
        try {
            const url = `/api/update_mobile/`;
            return (await get(url)).data;
        }
        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    upgradeAccountLevel: async () => {
        try {
            const url = `/api/user/convert/`;
            return (await post(url)).data;
        }
        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
}

export default myProfileServices;