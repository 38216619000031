import React from 'react';
import fraudServices from '../fraudServices';
import fraudActions from '../fraudActions';
import DeleteRowModal from './deleteRowModal';
import { Dropdown, Icon, Menu, Table, message, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

const BlacklistTable = ({data, onDeleted, isLoading, length, selected, sort}) => {
  const currentPage = useSelector(state => state.fraud.tablePage);
  const [minPage, setMinPage] = React.useState(0);
  const [maxPage, setMaxPage] = React.useState(20);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [showDelModal, setShowDelModal] = React.useState(false);
  const [keyTodelete, setKeyToDelete] = React.useState([]);
  const [columns, setColumns] = React.useState([]);

  const domainColumns = [
    {
      key: 'domain_code',
      title: 'Blacklist Code',
      dataIndex: 'domain_code',
    },
    {
      key: 'name',
      title: 'Domain Name',
      dataIndex: 'name',
    },
    {
      key: 'created_at',
      title: 'Date Added',
      dataIndex: 'created_at',
    },
    {
      key: 'Action',
      title: 'Action',
      render: (record) => (
        <Dropdown overlay={() => <Menu><Menu.Item><a onClick={()=>handleDeleteRow(record.id)}>Delete</a></Menu.Item></Menu>} placement="bottomCenter" trigger={['click']}>
          <Icon type="ellipsis" />
        </Dropdown>
      )
    }
  ]

  const emailColumns = [
    {
      key: 'email_code',
      title: 'Blacklist Code',
      dataIndex: 'email_code',
    },
    {
      key: 'email',
      title: 'Emain Address',
      dataIndex: 'email',
    },
    {
      key: 'created_at',
      title: 'Date Added',
      dataIndex: 'created_at',
    },
    {
      key: 'Action',
      title: 'Action',
      render: (record) => (
        <Dropdown overlay={() => <Menu><Menu.Item><a onClick={()=>handleDeleteRow(record.id)}>Delete</a></Menu.Item></Menu>} placement="bottomCenter" trigger={['click']}>
          <Icon type="ellipsis" />
        </Dropdown>
      )
    }
  ]

  const bounceColumns = [
    {
      key: 'email_code',
      title: 'Bounce List Code',
      dataIndex: 'email_code',
    },
    {
      key: 'email',
      title: 'Emain Address',
      dataIndex: 'email',
    },
    {
      key: 'created_at',
      title: 'Date Added',
      dataIndex: 'created_at',
    },
    {
      key: 'Action',
      title: 'Action',
      render: (record) => (
        <Dropdown overlay={() => <Menu><Menu.Item><a onClick={()=>handleDeleteRow(record.id)}>Delete</a></Menu.Item></Menu>} placement="bottomCenter" trigger={['click']}>
          <Icon type="ellipsis" />
        </Dropdown>
      )
    }
  ]

  const success = () => {
    message.success('Domain is deleted');
  };

  const errorPrompt = () => {
    message.error('There was an error try again');
  };

  const handleDelete = async (id) => {
    try {
      let res;

      if (selected === 'Email Domain'){
        res = await fraudServices.deleteBlacklist('domain', {target_id:id})
      } else if (selected === 'Email Address') {
        res = await fraudServices.deleteBlacklist('email',{target_id:id})
      } else if (selected === 'Bounce List') {
        res = await fraudServices.deleteBounceList({target_id:id})
      }

      if(res.status==='success'){
        success();
        onDeleted();
      } else {
        errorPrompt();
      }
    } catch (error) {
      console.log(error);
      errorPrompt();
    }
  }

  const handleDeleteRow = (id) => {
    setKeyToDelete(id);
    setShowDelModal(true);
  }

  const confirmDeleteRow = () => {
    handleDelete(keyTodelete);
    setShowDelModal(false);
  }

  const returnType = () =>  {
    switch (selected) {
      case 'Email Domain':
        return 'domain';
      case 'Email Address':
        return 'email';
      case 'Bounce List':
        /* falls through */
      default:
        return 'domain';
    }
  }

  const handleChangePage = async (page) => {
    setLoading(true);
    try {
      /* Notice how `type` is not needed when (selected === 'Bounce List') */

      const serviceToUse = selected === 'Bounce List' ? fraudServices.getBounceListData : fraudServices.getBlacklistData;
      const res = await serviceToUse('', page, returnType(), sort);
      if(res&&res.results){
        dispatch(fraudActions.setTableData(res.results));
      } 
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const changePage = (page, pageSize) => {
    setMaxPage(page * pageSize)
    setMinPage((page - 1) * pageSize)
    dispatch(fraudActions.setTablePage(page));
    handleChangePage(page);
  }

  React.useEffect(() => {
    setLoading(true);
    setColumns([]);
    setTimeout(()=> {
      setColumns(selected==='Email Domain'
        ? domainColumns
        : selected==='Bounce List'
          ? bounceColumns
          : emailColumns);
      setLoading(false);
    },100)
  }, [selected]);

  return (
    <div>
      <Table 
        rowClassName="table-row-light"
        columns={columns}
        dataSource={data}
        rowKey={record => record.id}
        loading={isLoading || loading}
        pagination={false}
      />
      <Pagination
        size='small'
        total={length}
        defaultPageSize={20}
        defaultCurrent={1}
        current={currentPage}
        onChange={(page, pageSize) => changePage(page, pageSize)}
        style={{padding:'12px 0 8px 0', textAlign: 'right'}}
      />
      <DeleteRowModal visible={showDelModal} closeModal={()=> setShowDelModal(false)} onDelete={confirmDeleteRow} />
    </div>
  )
}

export default BlacklistTable;