import React from 'react';
import { Modal } from 'antd';
import { isMobile } from 'react-device-detect';

const UpayList = props => {

    const {
        instapayPaymentChannel, channel, modalOpen, modalClose, instaList, pchcList
    } = props
    return(
        <Modal
        width={550}
        centered
        title={
            <div style={{ padding: 12 }} align='center'>{ channel == instapayPaymentChannel ? "InstaPay" : "PCHC Paygate" }</div>
        }
        visible={modalOpen}
        onCancel={() => modalClose(false)}
        footer={null}
    >
        { channel == instapayPaymentChannel && (
        <div style={{ padding: !isMobile ? '0px 16px' : '0px 0px 0px 0px' }}>
        { Object.keys(instaList).map((item, index) => {
            if(item != "EWallet")
            return (<div>

                    <div style={{ padding: 12, fontWeight: 'bold', background: '#CFD3D9', marginBottom: '10px'}}>{item}</div>
                    {
                        instaList[item].map((item2, index2) => {
                           return (<><div style={{ padding: 12 }}>{item2}</div><div><hr/></div></>)
                        })
            }</div>)
        })
        }
        </div>
        )}

        { channel == 'paygate' && (
        <div style={{ padding: !isMobile ? '0px 16px' : '0px 0px 0px 0px' }}>
        { Object.keys(pchcList).map((item, index) => {
            return (<div>

                    <div style={{ padding: 12, fontWeight: 'bold', background: '#CFD3D9', marginBottom: '10px'}}>{item}</div>
                    {
                        pchcList[item].map((item2, index2) => {
                           return (<><div style={{ padding: 12 }}>{item2}</div><div><hr/></div></>)
                        })
            }</div>)
        })
        }
        </div>
        )}
    </Modal>
    )
}

export default UpayList;