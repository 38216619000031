import React from 'react';
import { Switch } from 'antd';

const RebateToggle = props => {

    const {
        rebateStatus, handleRebateSwitch,
    } = props;

    return(
        <div className="rebate-div" align="left">
            <div className="twoCol">
                <div>
                    <div className="wc-header">
                        Rebate is {rebateStatus ? 'enabled' : 'disabled'}
                    </div>
                    <div className="admin-text-color normal-text-size">
                        Merchant will receive rebates from the transaction fees collected based on a tiered pricing schedule.
                    </div>
                </div>
                <div className="rebate-switch-div">
                    <Switch checked={rebateStatus} onChange={handleRebateSwitch} style={{backgroundColor: `${rebateStatus ? '#F5922F' : '#D1D5DD'}`}}/>
                </div>
            </div>
        </div>

    )
}

export default RebateToggle;