import React, {useEffect} from 'react';
import cardsStyles from '../../../static/css/Cards';
import {Basic, Approve, Exclamation, Clock} from '../../../static/icons/index';
import * as Style from '../../complete_profile/complete_profile_style'
import {Icon, Modal} from 'antd';
import {Grid} from '@material-ui/core';
import axios from 'axios';
import { history } from '../../../store/history';
import { isMobile } from 'react-device-detect';

const MobileAccountCard = (props) => {

    useEffect(() => {

        fetchProfile();
        console.log(step)
    }, [])

    const cs = cardsStyles();
    const current_step = localStorage.getItem("Step")
    
    const firstName = localStorage.getItem("firstName")
    const level = localStorage.getItem("Level")
    const step = localStorage.getItem("Step")
    const kybStep = localStorage.getItem("KYBStep")
    const userType = localStorage.getItem("userType")
    const [limitModal, setLimitModal] = React.useState(false)

    async function fetchProfile(){
    
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.get(process.env.REACT_APP_API_URL + '/api/kyc/', yourConfig)

        console.log(response.data)
    }

    return(
        <div>
            <div className={cs.basicCard} style={{ height: '100%', borderTop: 
                level == "0" && step == 0 && (userType == "CO" && kybStep == "0")  ?

                '' :

                (userType == "CO" && kybStep < 7 )|| level == '0' && step >= 1 && step < 7 ?
            
                '4px solid #FFC632' :

                (kybStep == 8 || kybStep == 9) || (level == "0" && step == 8 || step == 7) || (level == "1" && step == 12 || step == 13)?

                '4px solid #F5922F' :

                kybStep == 10 || (level == "0" && step == 9) || (level == "1" && step == 14) || step == 19?

                '4px solid #ee2545' :

                level == "1" && step == 10 || level == "2" || (level == "5" && kybStep == 11) || kybStep == 7 ?

                '4px solid #1DD28B' :

                ''}}>
                {/* <div className={cs.cardIcon} align='center' style={{backgroundColor: level == '0' && step >= 1 && step < 7? '#F5922F' : null}}>
                    <img src={
                        level == "0" && step == 0 && kybStep == "0"  ?

                        Basic :

                        (kybStep == 8 || kybStep == 9) || (level == "0" && step == 8 || step == 7) || (level == "1" && step == 12 || step == 13)?

                        Clock :

                        (kybStep == 10) || (level == "0" && step == 9) || (level == "1" && step == 14) || step == 19?

                        Exclamation :

                        level == "1" || level == "2" || (level == "5" && kybStep == 11) ? 

                        Approve : 

                        "" }/>
                        {level == '0' && step >= 1 && step < 7?
                            <div style={{ 
                                    color: '#fff',
                                    fontWeight: '600',
                                    fontSize: 17}}
                            >
                                {Math.min(
                                current_step == 3 || current_step == 4 ? 3 : 
                                current_step == 5 ? 4 : 
                                current_step == 6 ? 5 : current_step, 5) * 20}%
                            </div>
                        : 
                        userType == "CO" && kybStep <= 7 ?
                        <div>
                            <div className={cs.circleIcon} style={{background: kybStep == 7 ? '#1DD28B' : ''}}>
                                {kybStep == 7 ? '100' : Math.min(kybStep,6) * 15 }%
                            </div>
                        </div>
                        : null }
                </div> */}
                <div className={cs.basicHeader} style={{fontSize: isMobile? 16 : 24, marginTop: isMobile? 0 : 24}}>
                    {(userType == "ME" || userType === "SW") && level == '0' && step == '0' || (userType == "CO" && level == 0  && kybStep == 0)? 'Welcome to Bux' : 'Hello,'} {userType == "CO" ? '' : firstName}
                </div>

                <div className={cs.basicText}>
                    {
                        (userType == "ME" || userType === "SW") && level == "0" && step == 0 ?

                        <div style={{margin: '0 0 0 0'}}>To increase your limits, upgrade your account by completing your profile for FREE. Complete now.     </div>
                        
                        :

                        level == '0' && step >= 1 && step < 3 ?

                        <div>
                            Account is incomplete! Please complete your Upgrade account application to activate your account.
                        </div> :
                        

                        (userType == "CO" && kybStep < 4) ?
                        <div style={{padding: '0 0 16px 0'}}>
                            Account is incomplete! Please complete your application to activate your account.
                        </div> :

                        (userType == "CO" && kybStep == 5 || userType == "CO" && kybStep < 7) ?

                        <div style={{padding: '0 0 16px 0'}}>
                            You are almost done! Please complete your application to activate your account.
                        </div> :

                        level == '0' && step >= 3 && step < 6 ?

                        <div>
                            You are almost done! Please complete your Upgrade account application to activate your account.
                        </div> :

                        level == '0' && step == 6 ?

                        <div>
                             Your Upgrade Application is ready to be submitted, make sure all the information is correct and accurate.
                        </div> :

                        step == 14 || step == 19 ?

                        <div style={{height: '100%'}}>
                            We have received your application but unfortunately your upgrade has been <span style={{ color: '#E24C4C', fontWeight: 'bold'}}>declined</span>. Don't worry as you can still reapply after 1 month.    
                        </div> : 

                        kybStep == 7 ?

                        <div style={{fontSize: 16}}>
                            Your application is ready to be submitted. Before submitting, make sure all the information is correct and accurate.
                        </div> : 

                        (level == "0" && step == 8 || step == 7) || (level == "1" && step == 12 || step == 13 || level == "2" && step == 17 || level == "2" && step == 18 )?

                        'We’ve got your upgrade information, and currently reviewing it. Processing may take up to 1 business day.' :
                        
                        (userType == "CO" && kybStep == 8 || kybStep == 9) ?
                        `We’ve got your application, and currently reviewing it. Processing may take up to 1 business day.` :

                        (userType == "CO" && kybStep == 10) || (level == "0" && step == 9) || (level == "1" && step == 14)?

                        <span>Upgrade application was <span style={{ color: '#E24C4C', fontWeight: 'bold' }}>declined</span>. Kindly check your submitted information and resubmit it.</span>
                        
                        :

                        (level == "1" && step == 10) || level == '2' || level == '5' ?

                        <div> <span style={{color: '#1DD28B', fontWeight: 'bold'}}>Congratulations!</span> Upgrade application was approved.</div>
                        
                        :

                        ""
                    }
                
                </div>
                <div style={{display: 'flex', justifyContent: ((userType == "ME" || userType === "SW") && level == "0" && step == 0)? 'flex-end' : 'space-between', alignItems: 'baseline'}}>
                {(level == '0' && step >= 1 && step < 7)?
                    <div style={{ color: '#FFC632', fontWeight: 'bold', fontSize: 12}} >
                        {Math.min(
                        current_step == 3 || current_step == 4 ? 3 : 
                        current_step == 5 ? 4 : 
                        current_step == 6 ? 5 : current_step, 5) * 20}% COMPLETE
                    </div>
                    : 
                    userType == "CO" && kybStep <= 7 ?
                    <div style={{ color: '#FFC632', fontWeight: 'bold', fontSize: 12}} >
                        {kybStep == 7 ? '100' : Math.min(kybStep,6) * 15 }% COMPLETE
                    </div>
                : null }
                <div className={cs.button} hidden={level == "5" || step == 14 || step == 19} align='right'><a style={{color: '#F5922F', fontSize: 12}} onClick={() => window.location.href = 
                    (userType == "ME" || userType === "SW") && level == "0" && step <= 9 ? 
                    
                    '/complete_profile/'
                
                    :

                    (userType == "CO" && kybStep == 10 || kybStep < 7 || kybStep == 7 || kybStep == 8 || kybStep == 9 || level == "5")?

                    '/upgrade_enterprise/' 
                
                    : 

                    (level == "0" && step == 10) || (level == "1" && step == 12 || step == 13 || level == "1" && step == 10) || level == "2"?

                    '/mobile/upgrade/' : 

                    (level == "1" && step == 14) || level == "2" && step == 19 ?

                    '/mobile/business_upgrade/' :
                    
                    level == "1" || level == "2" ?
                    
                    '/mobile/enterprise_upgrade/' :
                    
                    ""
                    } 
                    
                    >
                        {
                            
                        (userType == "CO" && kybStep < 7) || level == "0" && step == 0 && kybStep == "0" ? 
                                            
                        'Proceed '

                        :
                        level == '0' && step >= 1 && step < 7 ? 
                        
                        'Proceed with Application '
                        
                        :

                        kybStep == 7 ?

                        'Review Application' : 
                        
                        (userType == "CO" && kybStep == 8 || kybStep == 9) || (level == "0" && step == 8) || (level == "1" && step == 12 || step == 13)?

                        'More details' :

                        (userType == "CO" && kybStep == 10) || (level == "0" && step == 9) || (level == "1" && step == 14) || level == "2" && step == 19 ?

                        'Check and resubmit' : 

                        // level == "1"  || level == "2" ?

                        // 'More details'

                        // : 

                        ""

                        }
                         <Icon hidden={level == "1"  || level == "2" || step == 7} type="right"/></a></div>
            </div>
            </div>

            <Modal
                visible={limitModal}
                onCancel={()=>setLimitModal(false)}
                footer={null}
                >
                <div style={Style.titleStyle}>Limits by Level</div>
                <div style={Style.tableLimits}>
                    <Grid container style={Style.tlHeader}>
                        <Grid xs={4}>
                            <div>Level</div>
                        </Grid>

                        <Grid xs={4}>
                            <div>Cash in</div>
                        </Grid>

                        <Grid xs={4}>
                            <div>Cash out</div>
                        </Grid>
                    </Grid>

                    <Grid container style={Style.tlText}>
                        <Grid xs={4}>
                            <div><b>Basic</b></div>
                        </Grid>

                        <Grid xs={4}>
                            <div>PHP 2,000</div>
                        </Grid>

                        <Grid xs={4}>
                            <div>PHP 0</div>
                        </Grid>
                    </Grid>

                    <Grid container style={Style.tlText}>
                        <Grid xs={4}>
                            <div><b>Pro</b></div>
                        </Grid>

                        <Grid xs={4}>
                            <div>PHP 50,000</div>
                        </Grid>

                        <Grid xs={4}>
                            <div>PHP 50,000</div>
                        </Grid>
                    </Grid>

                    <Grid container style={Style.tlText}>
                        <Grid xs={4}>
                            <div><b>Business</b></div>
                        </Grid>

                        <Grid xs={4}>
                            <div>PHP 400,000</div>
                        </Grid>

                        <Grid xs={4}>
                            <div>PHP 400,000</div>
                        </Grid>
                    </Grid>

                    <Grid container style={Style.tlText}>
                        <Grid xs={4}>
                            <div><b>Enterprise</b></div>
                        </Grid>

                        <Grid xs={4}>
                            <div>UNLIMITED</div>
                        </Grid>

                        <Grid xs={4}>
                            <div>UNLIMITED</div>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </div>
    )
}

export default MobileAccountCard;