import React from "react";
import { Modal, Typography } from "antd";
import { ServiceNotice } from "../../../components/Form/forms/receiver/ServiceNotice";
import PesoNet from "../../../../../static/icons/pesonet.svg";

export function PesonetDisclaimerModal({
  isPesonetModalVisible,
  setIsPesonetModalVisible,
  next,
  goTo,
  mode,
}) {
  return (
    <Modal
      visible={isPesonetModalVisible}
      onOk={() => {
        next();
        setIsPesonetModalVisible(false);
      }}
      onCancel={() => {
        goTo(1);
        setIsPesonetModalVisible(false);
      }}
      width={360}
      centered
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <img src={PesoNet} alt="PesoNet icon" style={{ width: "100px" }} />
        <Typography>
          The transfer will proceed using PesoNet service. Kindly read the
          disclaimer below and confirm if you would like to continue.
        </Typography>
        <ServiceNotice mode={mode} />
      </div>
    </Modal>
  );
}
