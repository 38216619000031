import React, { useState } from "react";
import MetaTag from "../../../../../../components/meta_tag/meta_tag";
import { Card, Typography } from "antd";
import { SubmissionsList } from "./submissions-list";
import { GroupButton, SearchInput, FilterBar } from "../../../components";

export function Submissions() {
  const statusList = ["All", "Approved", "Resubmitted", "Pending", "Rejected"];
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const [tempSearchQuery, setTempSearchQuery] = useState("");

  const handleStatusChange = (status) => setSelectedStatus(status);
  const handleApplySearch = () => setSearchQuery(tempSearchQuery);
  const clearFilters = () => {
    setSelectedStatus("All");
    setSearchQuery("");
    setTempSearchQuery("");
  };

  return (
    <div
      style={{
        margin: "0 2.5em 2.5em 2.5em",
        display: "flex",
        gap: "2em",
        flexDirection: "column",
      }}
    >
      <MetaTag title="CashBux Bank Submissions" />
      <div>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "20px",
            color: "#2B2D33",
          }}
        >
          Bank Submissions
        </Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <GroupButton
          options={statusList}
          selectedOption={selectedStatus}
          onSelect={handleStatusChange}
        />
        <Card>
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <FilterBar
              initialValue={statusList[0]}
              selectedOption={selectedStatus}
              searchQuery={searchQuery}
              clearFilters={clearFilters}
            />
            <SearchInput
              tempSearchQuery={tempSearchQuery}
              setTempSearchQuery={setTempSearchQuery}
              handleApplySearch={handleApplySearch}
              placeholder="Search by Account Number, Account Name."
            />
            <SubmissionsList
              selectedStatus={selectedStatus}
              searchQuery={searchQuery}
            />
          </div>
        </Card>
      </div>
    </div>
  );
}
