const cardActions = {
  setSuccessLoadModal: (value) => ({
    type: 'SET_VISIBLE_SUCCESS_LOAD_MODAL',
  }),
  showModal: (id) => ({
    type: 'SHOW_MODAL',
    payload: id,
  }),
  hideModal: (id) => ({
    type: 'HIDE_MODAL',
    payload: id,
  }),
  setSelectedTopUps: (value) =>({
    type: 'SET_SELECTED_TOPUPS',
    payload: value,
  }),
  setSelectedPaybills: (value) => ({
    type: 'SET_SELECTED_PAYBILLS',
    payload: value,
  }),
  setCardLock: (value) => ({
    type: 'SET_CARDLOCK',
    payload: value,
  }),
  setEonTransactionHistory: (value) => ({
    type: 'SET_EON_TRANSACTION_HISTORY',
    payload: value,
  }),
  setCardInfo: (value) => ({
    type: 'SET_CARD_INFO',
    payload: value,
  }),
  clearFields: (value) => ({
    type: 'CLEAR_FIELDS',
    payload: value,
  }),
  setBuxBalance: (bal) => ({
    type: 'SET_BUX_BALANCE',
    payload: bal,
  }),
  setCardNumber: (number) => ({
    type: 'SET_CARD_NUMBER',
    payload: number,
  }),
  resetOTPTimer: (value) => ({
    type: 'RESET_OTP_TIMER',
    payload: value,
  }),
  setAttempMsg: (msg) => ({
    type: 'SET_ATTEMPT_MSG',
    payload: msg
  }),
  setOtpParams: (params) => ({
    type: 'SET_OTP_PARAMS',
    payload: params
  }),
  setOtpSuccess: (success) => ({
    type: 'SET_OTP_SUCESS',
    payload: success
  }),
  setOtpUID: (value) => ({
    type: 'SET_OTP_UID',
    payload: value
  }),
}

export default cardActions;