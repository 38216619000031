import { fetchUsersNow } from "./usersService";

export const GetUsersActionTypes = {
  GET_USERS_REQUESTING: "@@GET_USERS_REQUESTING",
  GET_USERS_SUCCESS: "@@GET_USERS_SUCCESS",
  GET_USERS_FAILED: "@@GET_USERS_FAILED",
};

export function fetchUsers(
  page, search, start, end, type, level, 
  is_corporate_filtered, 
  years, nature, accept_online, channels, types, transaction, avg_ticket, activation, key_account,
  test_account, kyb_step) {

  return async (dispatch) => {
    try {
      dispatch({ type: GetUsersActionTypes.GET_USERS_REQUESTING });
      let response = await fetchUsersNow(page, search, start, end, type, level, is_corporate_filtered, years, nature, accept_online, channels, types, transaction, avg_ticket, activation, key_account, test_account, kyb_step);
      dispatch({
        type: GetUsersActionTypes.GET_USERS_SUCCESS,
        payload: response.data
      });

    } catch (error) {
      dispatch({
        type: GetUsersActionTypes.GET_USERS_FAILED
      });
      console.log(error.message)
    }
  };
}
