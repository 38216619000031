import { Input, Checkbox, message, Spin } from "antd";
import React from "react";
import { requirements, requirements_per_type } from "../constants";
import moment from 'moment';
import axios from "axios";

const ActivityLogs = (props) => {
    const {
        merchantEntryId,
        businessType
    } = props;

    const username = localStorage.getItem('username');
    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    const avatarName = firstName && lastName ? firstName + ' ' + lastName : username;

    const [activityLogs, setActivityLogs] = React.useState([]);
    const [textAreaValue, setTextAreaValue] = React.useState('');
    const [taggedRequirements, setTaggedRequirements] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true)
    //const [modalData, setModalData] = React.useState(data);

    let tagged_req = []

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

    const updateData = async (activity, activity_type) => {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken"),
            }
        };

        let created_at = moment().format('MMM DD, YYYY hh:mm A')
        let params = {
            'id': merchantEntryId,
            'activity_type': activity_type,
            'activity': activity,
            'tagged_req': JSON.parse(JSON.stringify(activity_type === 'comment'? taggedRequirements : tagged_req)),
        }

        try {
            let response = await axios.post(
                process.env.REACT_APP_API_URL + `/api/merchant_entry/track_requirements/`,
                params,
                yourConfig
            );

            if (response.data) {
                if (activity_type === "comment") {
                    setActivityLogs(modalData => [{
                        'type': activity_type,
                        'full_name': avatarName,
                        'activity': '<br/>' + activity.replace(/(?:\r\n|\r|\n)/g, '<br/>'),
                        'created_at': created_at
                    }, ...modalData]);
                } else if (activity_type === 'tagged'){
                    setActivityLogs(modalData => [{
                        'type': activity_type,
                        'full_name': avatarName,
                        'activity': activity.replace('Done', "<span style='font-weight:700;'>Done</span>"),
                        'created_at': created_at
                    }, ...modalData]);
                } else {
                    setActivityLogs(modalData => [{
                        'type': activity_type,
                        'full_name': avatarName,
                        'activity': activity,
                        'created_at': created_at
                    }, ...modalData]);
                }
            } else {
                message.error(response.data.message)
            }
        } catch {
            message.error("An error has occured")
        }

    }

    const fetchModalData = async () => {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken"),
            }
        };

        try {
            let response = await axios.get(
                process.env.REACT_APP_API_URL + `/api/merchant_entry/track_requirements/?id=${merchantEntryId}`,
                yourConfig
            );
            if (response.data) {
                setActivityLogs(response.data.data);
                setTaggedRequirements(response.data.tagged_req)
                
            } else {
                setActivityLogs([]);
                setTaggedRequirements([]);
            }
        } catch {
            setActivityLogs([])
            setTaggedRequirements([]);
            message.error("An error has occured")
        }
        //sleep(5000).then(() => { setIsLoading(false) });
        setIsLoading(false);
    }


    const handleTextAreaChange = (e) => {
        setTextAreaValue(e.target.value);
    };

    const clearTextArea = (e) => {
        setTextAreaValue(null);
    };

    const handleCommentEnter = (e) => {
        if (!(e.keyCode == 13 && e.shiftKey) && textAreaValue.trim().length !== 0) {
            //call async function, if success, update state
            updateData(textAreaValue, "comment");
            clearTextArea(e);
        }
    };

    const changeTaggedRequirements = (e, activity, activity_type) => {
        if (!taggedRequirements.includes(e.target.value)) {
            setTaggedRequirements((taggedRequirements) => {
                return [e.target.value, ...taggedRequirements];
            });
            tagged_req = [e.target.value, ...taggedRequirements];
        } else {
            let array = taggedRequirements;
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
            }
            setTaggedRequirements((array)=>{
                return array
            });
            tagged_req = array;
        }
        updateData(activity, activity_type)
    };

    function handleCheckboxChange(e) {
        
        let activity = '';
        let activity_type = '';
        if (!taggedRequirements.includes(e.target.value)) {
            //call async function, if success, update state
            activity = `tagged ${requirements[e.target.value]['title']} as Done.`;
            activity_type = 'tagged';

        } else {
            //call async function, if success, update state
            activity = `untagged ${requirements[e.target.value]['title']}`;
            activity_type = 'tagged';

        }
        changeTaggedRequirements(e, activity, activity_type);
    }


    const RequirementsCheckbox = () => {
        return (
            <div style={{ padding: '15px 0px' }}>
                <p style={styles.subHeader}>{requirements_per_type[businessType].title}</p>
                {
                    requirements_per_type[businessType].requirements.map((requirement, i) => {
                        return (
                            <>
                                <Checkbox
                                    onChange={(e) => handleCheckboxChange(e, requirement)}
                                    style={{ ...styles.info, width: '100%' }}
                                    checked={taggedRequirements.includes(requirement) ? true : false}
                                    value={requirement}>
                                    {requirements[requirement]['title']}
                                </Checkbox>
                                <br />
                            </>
                        )
                    })
                }
            </div>
        )
    }

    React.useEffect(() => {
        fetchModalData()
    }, [])

    //
    return (
        <>
            {isLoading ? 
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'150px', width:'100%'}}>
                    <Spin />
                </div> :
                <>
                    <RequirementsCheckbox />
                    <hr style={styles.hr} />
                    <div style={styles.activityDiv}>
                        <span style={styles.header}>Activity</span>
                        <div style={styles.activityLogDiv}>
                            <div
                                style={{
                                    ...styles.activityAvatar,
                                    color: 'white',
                                    backgroundColor: generateColor(getAvatarAlias(avatarName))
                                }}>
                                <span style={{ fontWeight: 600 }}>{getAvatarAlias(avatarName)}</span>
                            </div>
                            <Input.TextArea
                                id='comment-textarea'
                                style={{ width: '100%', marginLeft: '10px' }}
                                placeholder="Write a comment"
                                maxLength={200}
                                value={textAreaValue}
                                defaultValue={null}
                                autoSize={{ minRows: 1, maxRows: 5 }}
                                onChange={(e) => handleTextAreaChange(e)}
                                onPressEnter={(e) => handleCommentEnter(e)}
                                allowClear
                                showCount />

                        </div>
                        <div style={{ maxHeight: '300px', width: '100%', overflow: 'scroll' }}>
                            {
                                activityLogs.map((logs, i) => {
                                    if (logs.activity_type === 'comment') {
                                        return (
                                            <div style={styles.activityLogDiv}>
                                                <div style={{ ...styles.activityAvatar, color: 'white', backgroundColor: generateColor(getAvatarAlias(logs.full_name)) }}>
                                                    <span style={{ fontWeight: 600 }}>{getAvatarAlias(logs.full_name)}</span>
                                                </div>
                                                <div style={{ width: '100%', marginLeft: '10px' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginLeft: '0' }}>
                                                        <span style={{ color: '#000000', fontWeight: 700 }}>{logs.full_name}</span>
                                                        <span style={{ color: '#000000' }} dangerouslySetInnerHTML={{ __html: renderComment(logs) }} />
                                                        <span style={{ color: '#2b2d32', opacity: '0.6', margin: 0 }}>{logs.created_at}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div style={styles.activityLogDiv}>
                                                <div style={{ ...styles.activityAvatar, color: 'white', backgroundColor: generateColor(getAvatarAlias(logs.full_name)) }}>
                                                    <span style={{ fontWeight: 600 }}>{getAvatarAlias(logs.full_name)}</span>
                                                </div>
                                                <div style={{ width: '100%', marginLeft: '10px' }}>
                                                    <span style={{ color: '#000000', fontWeight: 700 }}>{logs.full_name}&nbsp;</span>
                                                    <span
                                                        style={{ fontSize: '14px', color: '#000000', margin: 0 }}
                                                        dangerouslySetInnerHTML={{ __html: renderComment(logs) }} />
                                                    <p style={{ color: '#2b2d32', opacity: '0.6', margin: 0 }}>{logs.created_at}</p>
                                                </div>
                                            </div>

                                        )
                                    }
                                }

                                )
                            }
                        </div>
                    </div>
                </>
            }
        </>
    )

};

const styles = {
    activityDiv: {
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '15px 0px'
    },
    activityLogDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '10px 0px',
        width: '100%',
    },
    activityAvatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '35px',
        height: '35px',
        borderRadius: '9999px',
        backgroundColor: 'blue',
        color: 'white'
    },
    header: {
        color: '#000000',
        fontWeight: '700',
        fontSize: '18px'
    },
    subHeader: {
        color: '#000000',
        fontWeight: '400',
        fontSize: '18px'
    },
    info: {
        color: '#000000',
        fontWeight: '400',
        fontSize: '16px'
    },
    hr: {
        border: '1px solid #EEEEEE',
    },
    title: {
        color: '#2B2D32',
        fontWeight: '700',
        fontSize: '18px'
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    body: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    label: {
        color: 'rgba(43, 45, 50, 0.8)',
        fontSize: '12px',
        fontWeight: '500',
        textAlign: 'center'
    }
};

const getAvatarAlias = (full_name = null) => {
    let arr = full_name.split(' ');
    if (arr.length > 1) {
        return arr[0].charAt(0).toUpperCase() + arr[arr.length - 1].charAt(0).toUpperCase();
    }
    return arr[0].charAt(0).toUpperCase();
};

const generateColor = (alias) => {
    // programmatically generate color for activity log avatar, per user
    var hex, i;
    let arr = alias.split('');
    hex = '';

    for (let i = 0; i < alias.length - 1; i++) {
        hex += alias.charCodeAt(i).toString(16);
    }

    if (hex.length < 6) {
        hex = hex.padStart(6, "4");
    }

    hex = '#' + hex;

    // make pastel-ish color in hsl
    var R = parseInt(hex.substring(1, 3), 16);
    var G = parseInt(hex.substring(3, 5), 16);
    var B = parseInt(hex.substring(5, 7), 16);

    var max = Math.max(R, G, B), min = Math.min(R, G, B);
    var h, s, l = (max + min) / 2;

    if (max == min) {
        h = s = 0; // achromatic
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case R: h = (G - B) / d + (G < B ? 6 : 0); break;
            case G: h = (B - R) / d + 2; break;
            case B: h = (R - G) / d + 4; break;
        }
        h /= 6;
    }

    s = s * (100);
    s = Math.round(s);
    l = l * (100);
    h = Math.round(360 * h);

    var hsl = 'hsl(' + h + ', ' +
        '50%, ' +
        '60%)';

    return hsl;
}

const renderComment = (logs) => {
    if (logs.activity_type === 'comment') {
        return logs.activity.replace(/(?:\r\n|\r|\n)/g, '<br/>')

    } else if (logs.activity_type === 'tagged'){
        var reg = new RegExp('(done)', 'gi');
        return logs.activity.replace(reg, "<span style='font-weight:700;'>Done</span>")
    }
    return logs.activity
}

export default ActivityLogs