import React, {useEffect} from 'react'
import {Input, Button, Checkbox, Select} from 'antd'
import { useDispatch, useSelector } from "../../../__test__/react-redux-hooks";
import NewBuxLogo from '../../../static/images/signup_and_login/new_bux_logo2.svg'
import { history } from '../../../store/history';
import { isMobile } from 'react-device-detect';
import {showAlert} from '../../../components/alert/alertAction'
import BuxAlert from '../../../components/alert/alert'
import BuxModal from '../../../components/BuxModal/modal';
import { sign_up } from '../../../components/sign_up/signUpActions';
import PasswordInput from './password_input/password_input';
import { SignUpActionTypes } from '../../../components/sign_up/signUpActions';
import axios from 'axios';

const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;


const SignUp = () => {

    const { Option } = Select;
    const dispatch = useDispatch();
    const isSubDomain = process.env.REACT_APP_ROOT_URL != window.location.hostname;

    const [firstName, setFirstName] = React.useState("")
    const [lastName, setLastName] = React.useState("")

    const [email, setEmail] = React.useState("")
    const [emailErrorVisible, setEmailErrorVisible] = React.useState(false)
    const [isEmailValid, setIsEmailValid] = React.useState(false)

    const [mobileNumber, setMobileNumber] = React.useState("")
    const [mobileErrorVisible, setMobileErrorVisible] = React.useState(false)
    const [mobileErrorMessage, setMobileErrorMessage] = React.useState("")
    const [isMobileValid, setIsMobileValid] = React.useState(false)

    const [password, setPassword] = React.useState("")
    const [isPasswordValid, setIsPasswordValid] = React.useState(false)

    const [selectedFindout, setSelectedFindout] = React.useState("Select")
    const [other, setOther] = React.useState("")
    const [otherErrorVisible, setOtherErrorVisible] = React.useState(true)
    const [signUpAgree, setSignUpAgree] = React.useState(false)

    let remove_part = `.${process.env.REACT_APP_SUB_URL}`;
    const trade_name = window.location.hostname.replace(remove_part, "");

    const findoutChoices = [
        'Select',
        'Social Media (Facebook, Instagram, etc.)',
        'Search Engine (Google, Yahoo, etc.)',
        'Referral (Friend, Family, Colleague, etc.)',
        'Third-party Reviewer',
        'Facebook Community Group',
        'Podcast Mention',
        'Other (please specify)'
    ]
    
    async function getDetails() {
      try {
        let url =
          process.env.REACT_APP_API_URL +
          `/api/enterprise/${trade_name}/details/`;

        let response = await axios.get(url);
        if (response.data.status === "failed") {
          window.location.href = process.env.REACT_APP_BASE_URL;
        } else {
          if (!response.data.multiwallet) {
            window.location.href = process.env.REACT_APP_BASE_URL;
          } else {
            if (!response.data.enterprise_signup_enabled) {
              history.push("/login");
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    function loadFbPlugin() {
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'Messenger'));
        loadMessengerExt();
    }

    function loadMessengerExt() {

        window.extAsyncInit = function () {
             window.MessengerExtensions.getUserID(function success(uids) {
                window.fbuid = uids.psid;
            }, function error(err, errorMessage) {
                window.fbuid = '';

            });
        
        };

    }

    function submitCreds() {

        let new_referrer = selectedFindout == "Other (please specify)" ? other : selectedFindout

        let params = {
          email: email,
          password: password,
          mobileNumber: mobileNumber,
          firstName: firstName,
          lastName: lastName,
          referrer: new_referrer,
          messenger_id: window.fbuid
        };
        
        
        if(firstName.length <= 0){
            dispatch(showAlert({ type: "error", message: "Fill up your first name" }));
        }
        else if(firstName.length > 30){
            dispatch(showAlert({ type: "error", message: "First name exceeded to max limit of 30 characters" }));
        }
        else if(lastName.length <= 0){
            dispatch(showAlert({ type: "error", message: "Fill up your last name" }));
        }
        else if(lastName.length > 30){
            dispatch(showAlert({ type: "error", message: "Last name exceeded to max limit of 30 characters" }));
        }
        else if(email.length == 0){
            dispatch(showAlert({ type: "error", message: "Email address is required" }));
        }
        else if(mobileNumber.charAt(0) != '9'){
            dispatch(showAlert({ type: "error", message: "Mobile number must start with 9" }));
        }
        else if(mobileNumber.length != 10){
            dispatch(showAlert({ type: "error", message: "Mobile number must consist of 10 digits" }));
        }
        else if(selectedFindout == 'Select'){
            dispatch(showAlert({ type: "error", message: "Please select how did you find out BUx" }));
        }
        else if(selectedFindout == "Other (please specify)" && other.length == 0){
            dispatch(showAlert({ type: "error", message: "Please specify how did you find out BUx" }));
        }

        else{            
            dispatch(sign_up(params));
        }
    }

    function handleMobileNumber(e){

        let onlyNums = e.replace(/\D/g, '');


        if(onlyNums.charAt(0) != '9'){
            setMobileErrorMessage("Mobile number must start with 9")
            setMobileErrorVisible(true)
            setIsMobileValid(false)
        }
        else if(onlyNums.length != 10){
            setMobileErrorMessage("Mobile number must consist of 10 digits")
            setMobileErrorVisible(true)
            setIsMobileValid(false)
        }
        else{
            setMobileErrorVisible(false)
            setIsMobileValid(true)
        }

        setMobileNumber(onlyNums);
    }

    function handleEmail(e){

        var re = /\S+@\S+\.\S+/;

        if(!re.test(e)){
            setEmailErrorVisible(true)
            setIsEmailValid(false)
        }
        else{
            setIsEmailValid(true)
            setEmailErrorVisible(false)
        }
        setEmail(e);
    }


    function handleSpecifyOther(e){

        if(e.length == 0){
            setOtherErrorVisible(true)
        }
        else{
            setOtherErrorVisible(false)
        }
        setOther(e.trim())
    }

    function handleLoginClick(){
        dispatch({
            type: SignUpActionTypes.CLEAR,
        });
        history.push('/login')
    };

    useEffect(() => {
      window.fbuid = "";
      if (
        localStorage.getItem("jwtToken") != "" &&
        localStorage.getItem("jwtToken") != null
      ) {
        window.location.href = "/dashboard";
      } else {
        if (isSubDomain) {
          getDetails();
        }

        loadFbPlugin();
        localStorage.setItem("jwtToken", "");
        localStorage.setItem("balance", "0 PHP");
      }
    }, []);

    return(
        <div id="terms" style={{...style.mainDiv, width: isMobile ? '100%' : isHighReso ? '529px' : '430px', overflowY: 'auto'}}>
            {
                isMobile ? 
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img src={NewBuxLogo} alt="#"/> 
                </div>
                :
                <div style={style.login}>
                    Sign up
                </div>

            }
            
            <div style={{display: 'flex', justifyContent: 'center', marginTop: isHighReso || isMobile ? '40px' : '20px'}}>
                <div>
                    <BuxAlert width={isMobile ? '312px' : '370px'}/>
                    <div style={{display: !isMobile && 'flex', width: isMobile ? '312px' : '370px'}}>
                        <div style={{marginRight: !isMobile && '2%'}}>
                            <div style={style.label}>First name</div>
                            <Input placeholder='First name' size="large" style={{width: isMobile ? '312px' : '180px'}} onChange={(e)=>setFirstName(e.target.value)}/>
                        </div>
                        <div style={{marginTop: isMobile && '20px'}}>
                            <div style={style.label}>Last name</div>
                            <Input placeholder='Last name' size="large" style={{width: isMobile ? '312px' : '180px'}} onChange={(e)=>setLastName(e.target.value)}/>
                        </div>
                    </div>
                    <div style={{display: !isMobile && 'flex', width: isMobile ? '312px' : '370px', marginTop: '20px'}}>
                        <div style={{marginRight: !isMobile && '2%'}}>
                            <div style={style.label}>Email address</div>
                            <Input placeholder='juan@gmail.com' size="large" style={{width: isMobile ? '312px' : '180px'}} onChange={(e)=>handleEmail(e.target.value)}/>
                            <div style={style.errorMessageStyle} hidden={!emailErrorVisible}>Invalid email address format</div>
                        </div>
                        <div style={{marginTop: isMobile && '20px'}}>
                            <div style={style.label}>Mobile number</div>
                            <Input size="large" 
                                placeholder='9123456789'
                                maxLength="10"
                                onChange={e => handleMobileNumber(e.target.value)} 
                                addonBefore="+63" 
                                value={mobileNumber}
                                style={{width: isMobile ? '312px' : '180px'}}/>
                            <div style={style.errorMessageStyle} hidden={!mobileErrorVisible}>{mobileErrorMessage}</div>
                        </div>
                    </div>
                    <div style={{marginTop: '20px'}}>
                        <div style={style.label}>Password</div>
                        <PasswordInput password={password}
                            isPasswordValid={isPasswordValid}
                            setIsPasswordValid={setIsPasswordValid} 
                            setPassword={setPassword} 
                            inputWidth={isMobile ? '312px' : '370px'} 
                            meterWidth={isMobile ? '312px' : '370px'}/>
                    </div>
                    <div style={{marginTop: '20px'}}>
                        <div style={style.label}>How did you find out about BUx?</div>
                        <Select
                            style={{width:  isMobile ? '312px' : '370px'}}
                            size="large"
                            onChange={(e)=>setSelectedFindout(e)}
                            value={selectedFindout}
                            >
                            {
                                findoutChoices.map((item, i)=>{
                                    return(
                                        <Option value={item}>{item}</Option>
                                    )
                                })
                            }
                        </Select>         
                    </div>
                    <div style={{marginTop: '20px'}} hidden={selectedFindout != "Other (please specify)"}>
                        <div style={style.label}>Specify here</div>
                        <Input type="large" style={{width:  isMobile ? '312px' : '370px', height: '40px'}} onChange={(e)=>handleSpecifyOther(e.target.value)}/>
                        <div style={style.errorMessageStyle} hidden={!otherErrorVisible}>Specification is required</div>
                    </div>
                    <div style={{width:  isMobile ? '312px' : '370px', marginTop: isMobile ? '16px' : '10px', display: 'flex'}}>
                        <Checkbox value={signUpAgree} onChange={() => setSignUpAgree(!signUpAgree)}/>
                        <div style={{fontSize: '14px', color: '#2b2d33', marginLeft: '10px'}}>I have read and agreed with the 
                        <a style={{color: '#fb922f'}} href="/terms"><b> Terms and Conditions</b></a> and the <a style={{color: '#f5922f'}} href="/privacy_policy"><b>Privacy Policy </b></a>of BUx</div>
                    </div>
                    <div style={{marginTop: '20px'}}>
                        <Button 
                            style={{...style.loginBtn, 
                                opacity: signUpAgree && isPasswordValid && isEmailValid && isMobileValid ? '1' : '0.5'
                            }} 
                            onClick={() => submitCreds()} 
                            disabled={!signUpAgree || !isPasswordValid || !isEmailValid || !isMobileValid}>
                                Sign up
                        </Button>
                    </div>
                    <div style={{display: 'flex', marginTop: '30px', justifyContent: 'center'}}>
                        <div style={style.noAccount}>With account? </div>
                        <a style={style.signUp} onClick={()=>handleLoginClick()}>Log in</a>
                    </div>
                    <div hidden={!isSubDomain} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '6px 0px -14px 0px' }}>
                        <span style={{ fontSize: 12, color: '#909196' }}>Powered by</span>
                        <img src={NewBuxLogo} style={{ width: 50, height: 38, paddingLeft: 2 }} />
                    </div>
                </div>
            </div>
            <BuxModal/>

        </div>
    )
}

const style = {
    mainDiv : {
        height: '100%',
        borderRadius:  !isMobile && '8px',
        backgroundColor: '#fff',
        boxShadow: '0px 8px 24px rgba(0, 35, 11, 0.12)',
        paddingTop: isHighReso ? '50px' : '20px',
        paddingBottom: '20px'
    },
    login:{
        display: 'flex', 
        justifyContent: 'center', 
        fontSize: '28px', 
        color: '#000', 
        fontWeight: 'bold'
    },
    label:{
        fontSize: '14px', 
        color: 'rgba(43, 45, 50, 0.8)', 
        fontWeight: 'normal'
    },
    loginBtn:{
        width:  isMobile ? '312px' : '370px',
        height: '40px',
        borderRadius:'4px',
        border: '1px solid #F5922F',
        backgroundColor: '#F5922F',
        fontSize: '16px',
        color: '#fff',
        fontWeight: 'bold'
    },
    googleBtn:{
        height: '40px',
        backgroundColor: '#4285F4',
        width: '370px',
        borderRadius: '4px',
        border: '1px solid #4285F4',
        color: '#FFF',
        fontSize: '16px',
        fontWeight: 'bold'
    },
    noAccount: {
        fontWeight: '400',
        fontSize: '16px',
        color: '#2b2d33',
        marginRight: '5px'
    },
    signUp:{
        fontWeight: '700',
        fontSize: '16px',
        color: '#f5922f'
    },
    errorMessageStyle:{
        fontSize: '10px',
        color: '#C0492C',
        marginTop: '5px'
    }
}

export default SignUp