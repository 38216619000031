import axios from 'axios'
import {Handle401} from '../../handle401/handle401'

let yourConfig = {
    headers: {
       Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  }

const authorizedAxiosRequest = () => {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
        Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    });
}

async function getAllPayouts(page,page_size, search, type, start='', end='', mode, user_type, summary, email, is_export){
    const endpoint = '/api/admin/payouts/?page='+page+'&page_size='+page_size+'&search='+search+'&status='+type+'&start='+start+'&end='+end+'&mode='+mode+'&user_type='+user_type+'&summary='+summary;
    try {
        let response = await axios.get(
            process.env.REACT_APP_API_URL + endpoint, yourConfig
        );

        let data = response ? response.data : []

        return data;
    }

    catch (error) {
        if((error.response)&&(error.response.status == 401)){
            Handle401()
        }
    }
}

const payoutServices = {
    exportCsv: async (page,page_size, search, type, start='', end='', mode, user_type, summary, email, is_export) => {
        try {
            const res = await authorizedAxiosRequest().get('/api/admin/payouts/?page='+page+'&page_size='+page_size+'&search='+search+'&status='+type+'&start='+start+'&end='+end+'&mode='+mode+'&user_role='+user_type+'&summary='+summary+'&email='+email+'&is_export='+is_export)
            return res.data;
        } catch (error) {
            if((error.response)&&(error.response.status == 401)){
            Handle401()
            }
        }
    },
}

export { getAllPayouts, payoutServices }
