import React from 'react';

let segments = localStorage.getItem('segments')

const data = [
    {
        sole: 'DTI Registration',
        corporate: 'SEC Registration',
        partnership: 'Articles of Partnership'
    },
    {
        sole: 'Business Permit',
        corporate: 'Articles of Incorporation',
        partnership: 'BIR 2303'
    },
    {
        sole:'BIR Registration',
        corporate: 'By-Laws',
        partnership: 'SEC Certificate of Filing of Articles of Partnership'
    },
    {
        sole: 'Mayor’s Permit',
        corporate: 'Latest General Information Sheet (GIS)',
        partnership: 'Business Permit'
    },
    {
        sole: 'Income Tax Return (optional)',
        corporate: 'Business Permit',
        partnership: 'Partnership Resolution'
    },
    {
        sole: 'Bank Account information for settlement',
        corporate: 'Secretary’s Certificate or Board Resolution for Authorized Representatives or Signatories',
        partnership: 'Bank Account information for settlement'
    },
    {
        sole: '',
        corporate: 'Latest Audited Financial Statement (optional)'
    },
    {
        sole: '',
        corporate: 'Bank Account information for settlement'
    },
]

export const POLICIES = [

    {
        q: 'Purpose & Scope',
        d: 
        <div>
            <p>
                BUx respects and values your privacy and the secrecy of your account information with us. This Privacy Policy (“Policy”) informs you how we collect, use, store, and process your personal data in our loan products.  We adhere to the data privacy principles of (1) legitimate purpose – we only process upon your consent, in compliance with law or contract; (2) transparency – we notify everything that happens to your data; and (3) proportionality – collection is limited based on purpose.    
            </p>
            <p>
                This Policy applies to data subjects who  visit or use the BUx website, BUx Facebook page and use the Application Programming Interfaces (API) or related services (the “Services”) of the platform. (Data Subjects”)   
            </p>
        </div>
        
    },
    {
        q: 'Collection of your Personal and Sensitive Personal Data',
        d: 
        <div>
            <p>
                Personal Data refers to any information that identifies or is linkable to a natural person. On the other hand, Sensitive Personal Data is any attribute that can distinguish, qualify or classify a natural person from the others such as data relating to your ethnicity, age, gender, health, religious or political beliefs, genetic or biometric data.
            </p>
            <p>
                We collect your Personal and Sensitive Personal Data when you register, sign-up or use our products and services or contact us about them. We also collect through your organization whether private corporation or government instrumentality you authorized. We may also obtain your information from other sources (i.e publicly available platforms, financial institutions, credit agencies, payment gateway processors, public authorities, and other registers) for purposes of identity verification and regulatory requirements by the Bangko Sentral ng Pilipinas (BSP).
            </p>
        </div>
    },
    {
        q: 'Kinds of Data We Process',
        d: 
        <div>
            <ul>
                <li>
                    <p>
                        <strong>Account Data and Know-Your-Customer (KYC) Information: </strong>: refer to Personal Data and Sensitive Personal Data we collect when you sign up or register to our products and services such as full legal name, gender, date of birth, nationality, civil status, permanent address, present address, tax identification number and other government-issued identification numbers, mobile number, home number, office contact details, company name, job position or rank, office address, source of funds, gross annual income, and such other information necessary to conduct due diligence and comply with BSP rules and regulations.
                    </p>
                    <p>
                        For entities, BUx collects the following information when your representatives or authorized personnel create or update your enterprise account in Bux:  business information (type, name, nature, address details, years in business, website/URL), social media pages or accounts (Facebook, Instagram, etc.), annual gross sales information, average ticket size, projected annual sales, services offered, list of stockholders, directors, and officers, and all other information that are required to be provided during account creation or updating. 
                    </p>
                    <p>
                        As part of Bux’s KYC and KYB, BUx also requires the submission of the following business-related documents for sole proprietorships and other entities that open enterprise accounts in Bux:
                    </p>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{border: '1px solid #000000', width: '30%'}} align='center'><b>Sole Proprietorship</b></td>
                                <td style={{border: '1px solid #000000', width: '40%'}} align='center'><b>Enterprise Entities</b></td>
                                <td style={{border: '1px solid #000000', width: '30%'}} align='center'><b>Partnership Entities</b></td>
                            </tr>
                            {
                                data.map((item, index) => {
                                    return(
                                        <tr key={index}>
                                                <td style={{padding: 5, width: '30%', border: '1px solid #000000'}} align='center'>
                                                    <div>{item.sole}</div>
                                                </td>

                                                <td style={{padding: 5, width: '40%', border: '1px solid #000000'}} align='center'>
                                                    <div>{item.corporate}</div>
                                                </td>

                                                <td style={{padding: 5, width: '30%', border: '1px solid #000000'}} align='center'>
                                                    <div>{item.partnership}</div>
                                                </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <p style={{marginTop: '10px'}}>
                        BUx also collects individual information from the authorized users of enterprise accounts as they are required to create individual accounts.  Upon successful creation of the enterprise account, the individual accounts shall be provided with their access rights.  On top of the usual information to be required and collected for individual accounts, enterprise-related individual accounts shall also provide their designation for the roles of: SPOC - Main Contact Person / Master Admin, Sales, Finance, Support, Admin and IT for enterprise accounts. 
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Biometric Data:</strong> upon your express consent and subject to limitations imposed by law, data processed for customer verification through: (1) facial recognition technology; (2) liveness detection mechanism; and (3) fingerprint recognition applications.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Payment and Transactional Data:</strong> linkable information to your Personal Data such as (1) bank account number, deposits, withdrawals, such other transfers made to or from your account, and details about them such as reference number, place and time these were made; (2) information when you contact us through our official channels such as branches, contact centers, web and mobile platforms; (3) credit card account number as well as purchases or transactions using your credit card; and (4) other forms of customer account number, payments, and transactions you have with us.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Financial Data:</strong> information about the value of your property and assets, your credit history and capacity, and other financial products and services you have with us.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Payouts:</strong> BUx collects and stores your bank account information needed to access and generate your checkout transactions.  These information will then be passed on to Bux’s partner banks.  
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Sensitive Personal Data:</strong> we may require the following Sensitive Personal Data upon your express consent: (1) your religion when you apply for insurance products with us; (2) for customer verification, your government-issued identification numbers or cards such as passport or driver’s license ID; or (3) any information that is necessary, incidental to contractual agreement or in connection with a requested product or service. 
                    </p>
                    <p>
                        The foregoing data are collectively referred to as “<b>Customer Data</b>” or “<b>Personal Information</b>”.
                    </p>
                </li>
            </ul>
        </div>
    },
    {
        q: 'Data Processing',
        d: <div>
            <p>
            Processing means any activity pertaining to the collection, recording, organization, storage, updating or modification, retrieval, consultation, use, consolidation, blocking, erasure or destruction of Customer Data.
            </p>
            <p>
            We process Customer Data only for legitimate purposes and with lawful basis such as your express consent, terms and conditions of product or service you signed up with us, and as required by law and regulation. We ensure that only authorized employees and third-party service providers, who satisfy our stringent risk management, governance, information security, and data privacy requirements, can process your data.
            </p>
            <p>
            BUx uses Google Analytics. This tool enables BUx to analyze your use of the Services, including information on the frequency of your visits, events you look at within the Services, usage and performance data.  BUx uses this data to improve its Services, better understand how the Services perform on different devices, and provide information that may be of interest to you.
            </p>
            <ul>
                <li>
                    <div><b>Data Storage</b></div>
                    <ul>
                        <li>We store Customer Data in secure and encrypted BUX-managed environments, devices, and media. For third-party managed environments such as cloud service providers, we employ BSP-sanctioned security protocols and procure BSP approval prior to deployment if needed.</li>
                        <li>We store physical copies of documents containing Customer Data in physical secure vaults.</li>
                    </ul>
                </li>
                <li>
                    <div><b>Data Access</b></div>
                    <ul>
                        <li>Customer Data can only be accessed by authorized personnel on a role-based manner following the proportionality principle that authorized personnel can only access Customer Data they need for their role and purpose in BUX.</li>
                    </ul>
                </li>
                <li>
                    <div><b>Data Use</b></div>
                    <ul>
                        <li>
                            <div>Customer</div>
                            <ul>
                                <li>We use your contact details with us to communicate with you about your relationship with us. We may ask for feedback, surveys or polls about our products and services. </li>
                                <li>We may send you email or mobile notifications, telephone calls, or newsletters about product and services enhancements and account security reminders. </li>
                            </ul>
                        </li>
                        <li>
                            <div>Marketing</div>
                            <ul>
                                <li>We may use your information for us to send out campaigns of commercial products and services we hope you find interesting, relevant, and useful. </li>
                                <li>We perform data analysis on results of our marketing campaigns to measure their effectiveness and relevance.</li>
                                <li>You have the right to withdraw your consent or unsubscribe from receiving personalized offers.</li>
                                <li>You have the right to withdraw your consent or unsubscribe from receiving personalized offers.</li>
                            </ul>
                        </li>
                        <li>
                            <div>Due Diligence and Regulatory Compliance</div>
                            <ul>
                                <li>We may use Customer Data to evaluate your eligibility for our products and services. </li>
                                <li>We process Customer Data in compliance with legal obligations and statutory requirements by BSP, and other regulatory agencies.</li>
                            </ul>
                        </li>
                        <li>
                            <div>Business Insights</div>
                            <ul>
                                <li>We perform data analysis and reporting based on your Customer Data and how we operationalize to aid our management make better decisions. </li>
                                <li>We analyze your behavioral data, your interactions with our products and services, and our communications with you to aid us understand the areas for improvement and development.</li>
                                <li>We analyze transactional data performed through our third-party service providers and partners in order to determine how we can jointly improve our products and services for you.</li>
                            </ul>
                        </li>
                        <li>
                            <div>Data Quality </div>
                            <ul>
                                <li>We shall process your Customer Data in compliance with the data quality standards imposed by BSP. We may contact you to ensure accuracy and integrity of your information in our data processing systems.</li>
                            </ul>
                        </li>
                        <li>
                            <div>Protection and Security</div>
                            <ul>
                                <li>We process Customer Data for your account protection against cybercrime, identity theft, estafa, fraud, financial crimes such as money laundering, terrorism financing, and tax fraud.</li>
                                <li>We use appropriate security based on the type and sensitivity of data being stored. As with any internet-enabled system, there is always a risk of unauthorized access.  Thus, it is important to protect your password and user credentials.  You have to immediately contact us if you suspect any unauthorized access to your BUx account. </li>
                                <li>We take appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal data that it collects and stores. These measures vary based on the type and sensitivity of the data. While we have the appropriate security measures in place, it cannot guarantee that communications between you and us, Services, or any information provided you have provided to us (in connection with the data we collect through the Services) always will be free from unauthorized access by third parties. Your password and user credentials are important aspects of our security system.  While we do our duty to keep the platform secure at all times, you also have to do your fair share in ensuring that your BUx account and credentials are known only to you.  Should you have reason to believe that your password or account has been compromised, you should change it immediately and contact us for any concerns.</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <div><b>Data Retention</b></div>
                    <ul>
                        <li>Pursuant to BSP Regulations, retention period for transaction records shall be five (5) years from the date of transaction except where specific laws and/or regulations require a different retention period, in which case, the longer retention period is observed. </li>
                        <li>For financial data and documents which indicate taxable transactions, data shall be preserved for ten (10) years per BIR Regulation.</li>
                        <li>We keep your data as long as it is necessary: a) for the fulfillment of the declared, specified, and legitimate purposes, or when the processing relevant to the purposes has been terminated; b) for the establishment, exercise or defense of legal claims; or c) for legitimate business purposes.</li>
                    </ul>
                </li>
                <li>
                    <div><b>Data Disposal</b></div>
                    <ul>
                        <li>After the expiration of the relevant retention period above, we dispose personal data in a secure manner in order to prevent further processing, unauthorized access, or disclosure to any other party.</li>
                    </ul>
                </li>
            </ul>
        </div>
    },
    {
        'q': 'Data Sharing and Purpose',
        'd': 
        <div>
            When you consent to the processing of your Customer Data with us, you also agree to us sharing Customer Data externally with our partners, upon your written and/or electronic consent, for value added services you may find useful and relevant on top of your account with us. 
            <ul>
                <li>
                    <div><b>Judicial and Investigative Authorities</b></div>
                    <div>We may be mandated to disclose certain Customer Data upon service of legal court orders (i.e. unexplained wealth under Section 8 of RA No. 3019) or express legal request from police, public prosecutors, courts, or dispute resolution providers allowed by law. In these cases, we would notify you of the disclosure to the requesting government authority, subject to limitations imposed by law.</div>
                </li>
                <li>
                    <div><b>Other Regulatory Authorities</b></div>
                    <div>We may also share to regulatory authorities when such other persons or entities we may deem as having authority or right to such disclosure of information as in the case of regulatory agencies, government or otherwise, which have required such disclosure from us and when the circumstances so warrant.</div>
                </li>
                <li>
                    <div><b>Value Added Services </b></div>
                    <div>With your express consent, we may disclose your Customer Data to our partners who collaborate with us to provide services to you and provide joint communications that we hope you find of interest.Through our digital channels, you may instruct other mobile financial technology applications to retrieve your account information, initiate payments or cash-in from your account with us via our Application Programming Interface (API) facility.</div>
                </li>
            </ul>
        </div>
    },
    {
        'q': 'Rights of Data Subjects ',
        'd': 
        <div>
            Under the Data Privacy Act of 2012, you have the following rights: 
            <ol>
                <li>
                    <p><b>Right to be informed - </b>you may demand the details as to how your Personal Data is being processed or have been processed by the us, including the existence of automated decision-making and profiling systems.</p>
                </li>
                <li>
                    <p><b>Right to access - </b>upon written request, you may demand reasonable access to your Personal Information, which may include the contents of your processed personal information, the manner of processing, sources where they were obtained, recipients and reason of disclosure.</p>
                </li>
                <li>
                    <p><b>Right to dispute - </b>you may dispute inaccuracy or error in your Personal Information in our systems through our contact center representatives.</p>
                </li>
                <li>
                    <p><b>Right to object - </b>you may suspend, withdraw, and remove your Personal Information in certain further processing, upon demand, which include your right to opt-out to any commercial communication or advertising purposes from the us.</p>
                </li>
                <li>
                    <p><b>Right to data erasure - </b>based on reasonable grounds, you have the right to suspend, withdraw or order blocking, removal or destruction of your personal data from our filing system, without prejudice to the Bank continuous processing for commercial, operational, legal, and regulatory purposes.</p>
                </li>
                <li>
                    <p><b>Right to data portability  - </b>you have the right to obtain from the us your Personal Information in an electronic or structured format that is commonly used and allows for further use.</p>
                </li>
                <li>
                    <p><b>Right to be indemnified for damages - </b>as data subject, you have every right to be indemnified for any damages sustained due to such violation of your right to privacy through inaccurate, false, unlawfully obtained or unauthorized use of your information.</p>
                </li>
                <li>
                    <p><b>Right to file a complaint - </b>you may file your complaint or any concerns with our Data Protection Officer and/or with the National Privacy Commission through www.privacy.gov.ph</p>
                </li>
            </ol>
        </div>
    },
    {
        'q': 'Contact our Data Protection Officer',
        'd': <p>If you have any questions, concerns, or disputes regarding our Privacy Policy, please feel free
            to contact our Data Protection Officer at <a href="mailto:dpo@ubx.ph" className={`text-${segments}`}>dpo@ubx.ph</a></p>
    },
    
]