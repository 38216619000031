import { makeStyles } from '@material-ui/core';

const fundsStyle = makeStyles(theme => ({

    mainBody: {
        paddingTop: '24px', 
        paddingLeft: '24px',
        paddingRight: '24px' 
    },

    answer: {
        justifyContnent: 'left',
        fontWeight: '400',
        fontSize: '16px',
        color: '#000000',
        [theme.breakpoints.down("sm")]: {
            fontSize: '12px',
        }
    },

    credSchedule:{ 
        background: '#FFFFFF',
        border: '1px solid #d1d5dd',
        borderRadius: '10px',
        marginTop: 32,
        color: '#2b2d33',
    },

    twoCol:{
        padding: '16px 20px 20px 20px',
        display: 'flex',
        justifyContent: 'space-between'
    },

    fundText:{
        fontSize: 12,
        lineHeight: '16px',
    },

    fundBold:{ 
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '16px',
    },

    statusDiv:{
        background: 'rgba(245, 146, 47, 0.1)',
        borderRadius: '4px',
        border: '1px solid #f5922f',
        boxSizing: 'border-box',
        borderLeft: '5px solid #f5922f',
        padding: 5,
    },

    header:{ 
       marginTop: 32,
       fontWeight: 600,
       fontSize: 16,
       lineHeight: '24px',
       color: '#000000' 
    },

    schedData:{
        padding: 16,
        background: '#fff',
        borderBottom: '1px solid #D1D5DD',
    },

    orderStyle:{
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '20px'
    },

    sched:{ 
        marginTop: 10,
        color: '#2b2d33',
        fontWeight: 600,
        lineHeight: '24px',
        textDecoration: 'underline',
    },

    textStyle: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#FFFFFF'
    },

    codeStyle:{
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2B2D33'
    },

    descSyle:{
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#2B2D33',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '146px',
        whiteSpace: 'nowrap',
    },

    yesDiv:{
        background: '#fff',
        borderRadius: '4px',
        border: '1px solid #1DD28B',
        boxSizing: 'border-box',
        borderLeft: '5px solid #1DD28B',
        padding: 5,
        textAlign:'center',
        width: '120px'

    },

    noDiv:{
        background: 'rgba(245, 146, 47, 0.1)',
        borderRadius: '4px',
        border: '1px solid #f5922f',
        boxSizing: 'border-box',
        borderLeft: '5px solid #f5922f',
        padding: 5,
        textAlign:'center',
        width: '120px'
    },

    pillFlex: {
        marginBottom: "32px",
        display: "flex",
        justifyContent: "space-between"
    },

    pillBtn: {
        padding: "13px 18px 13px 18px",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "16px",
        height: "40px",
    },

    exportBtn: {
        backgroundColor: 'transparent',
        border: '1px solid #0A315E',
        height: '40px',
        borderRadius:'4px',
        color:'#0A315E',
        fontWeight: '500',
        cursor: 'pointer',
        padding: '0 12px'
    },

    tableDiv: {
        padding: '32px 20px',
        background: '#fff',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    },

    clearBtn: {
        borderRadius: '24px',
        background: '#E6EAF0',
        color: '#000000',
        fontSize: 12,
        width: 120,
        height: '29px',
        border: '1px solid #e6eaf0',
        cursor: 'pointer',
        outline: 0,
        '&:hover': {
            opacity: '0.8',
        },

        '&:active': {
            opacity: 1,
        }
    },

    noteStyle: {
        fontSize: 12,
        lineHeight: '21px',
        color: '#909196'
    },

    filterDiv: {
        float: 'right', 
        marginBottom: '25px', 
    },

    searchStyle: {
        width: '16%', 
        height: '40px', 
        zIndex: 1,
        marginRight: '12px',
    },

    activeBtn: {
        background: '#f5922f',
        border: '1px solid #f5922f',
        borderRadius: '4px',
        color: '#fff',
        height: '40px',
        width: 93,
        cursor: 'pointer',
        outline: 0,
        '&:hover': {
            opacity: '0.8',
        },

        '&:active': {
            opacity: 1,
        }

    },

    disabledBtn: {
        outline: 0,
        background: '#f5922f',
        border: '1px solid #f5922f',
        borderRadius: '4px',
        color: '#fff',
        height: '40px',
        width: 93,
        opacity: 0.5,
        cursor: 'not-allowed'
    },

    newSummaryDiv: {
        display:'flex',
        justifyContent: 'flex-start',
        margin: '20px 0px 10px 0px',
        borderTop: '1px solid #D4D5D8',
    },

    totalDiv: {
        padding: '12px 0px 12px 0px',
        width: '', textAlign: 'center',
    },

    sumSubDiv: {
        color: '#000', 
        marginRight: '5px'
    },

    sumSubValue: {
        color:'#0D3D76', 
        fontWeight: 'bold', 
        marginRight: '32px'
    },

    rejectBtn: {
        width: 'auto',
        backgroundColor: "#fff",
        color: "#d9534f",
        border: "2px solid #d9534f",
        height: "40px",
        fontWeight: '600',
        marginRight: '10px',
        '&:hover': {
            background: '#d9534f',
            color: '#fff'
        }
    },

    submitBtn: {
        width: 'auto',
        backgroundColor: "#1DD28B",
        border: "#1DD28B",
        height: "40px", 
        borderRadius: "10px",
    },
    
    
}))

export default fundsStyle

