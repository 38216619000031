import { Button, Drawer, Form, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { useMultistepForm } from "../../../hooks";
import { FundTransferForm } from "./FundTransferForm";
import { submitOTP } from "../../Transfer/api";
import { OtpEmail, OtpSms } from "../../Transfer/components/Form/forms";
import { TransferReceipt } from "../../../components/multiwallet/components/subwallet_page/components/TransferReceipt";
import { createTransfer } from "../../../components/multiwallet/components/subwallet_page/components/createTransfer";
import { useEnterprisePeers } from "../api/getPeers";
import { extractSubdomain } from "../utils";
import { useHistory } from "react-router-dom";

function CreateFundTransfer({ form, open, onClose, placement, balance }) {
  const history = useHistory();
  const currentUrl = window.location.href;
  const extractedSubdomain = extractSubdomain(currentUrl);
  const { data } = useEnterprisePeers(extractedSubdomain);

  const mode = "to_peer";
  const [amount, setAmount] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const [otpDetails, setOtpDetails] = useState({
    code: "",
    otp_type: 1,
    uid: "",
  });
  const [smsOtpActivated, setSmsOtpActivated] = useState(false);
  const [otpError, setOtpError] = useState(null);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [transferId, setTransferId] = useState("");

  useEffect(() => {
    if (otpError) {
      setShowErrorAlert(true);
      const timer = setTimeout(() => {
        setShowErrorAlert(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [otpError]);

  const { step, next, isLastStep, currentStepIndex, goTo } = useMultistepForm([
    <FundTransferForm
      form={form}
      amount={amount}
      setAmount={setAmount}
      selectedUser={selectedUser}
      setSelectedUser={setSelectedUser}
      balance={balance}
    />,
    smsOtpActivated ? (
      <OtpSms
        otpDetails={otpDetails}
        setOtpDetails={setOtpDetails}
        showErrorAlert={showErrorAlert}
        otpError={otpError}
        setOtpError={setOtpError}
        isOtpLoading={isOtpLoading}
        setIsOtpLoading={setIsOtpLoading}
      />
    ) : (
      <OtpEmail
        otpDetails={otpDetails}
        setOtpDetails={setOtpDetails}
        otpError={otpError}
        showErrorAlert={showErrorAlert}
        setOtpError={setOtpError}
        smsOtpActivated={smsOtpActivated}
        setSmsOtpActivated={setSmsOtpActivated}
        isOtpLoading={isOtpLoading}
        setIsOtpLoading={setIsOtpLoading}
      />
    ),
    <TransferReceipt
      transferId={transferId}
      subwalletId={selectedUser}
      enterprisePeers={data}
      amount={amount}
    />,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, _) => {
      if (!err && currentStepIndex === 0) {
        return next();
      }

      if (currentStepIndex === 1) {
        const otpType = smsOtpActivated ? "/api/otp/" : "/api/email_otp/";
        setIsLoading(true);
        submitOTP(otpType, otpDetails)
          .then((response) => {
            const transferDetails = {
              mode,
              amount,
              target_user_id: selectedUser,
              transfer_money_uid: response.trans_uid,
            };

            createTransfer(transferDetails).then((data) => {
              setTransferId(data.transfer_id);
              setSmsOtpActivated(false);
            });

            return;
          })
          .then(() => {
            goTo(2);
          })
          .catch((err) => {
            const { status, code, message } = err.response.data;
            setOtpError({ status, code, message });
            return;
          })
          .finally(() => setIsLoading(false));
      }

      if (isLastStep) {
        setOtpDetails({
          code: "",
          otp_type: 1,
          uid: "",
        });
        setOtpError(null);
        setShowErrorAlert(false);
        setIsOtpLoading(false);
        setSelectedUser(null);
        setAmount(null);
        setTransferId("");
        setSmsOtpActivated(false);
        goTo(0);
        onClose();
        history.go(0);
      }
    });
  };

  return (
    <Drawer
      key={placement}
      title="Create Peer Transfer"
      visible={open}
      onClose={onClose}
      placement={placement}
      closable={false}
      width={480}
    >
      <Form onSubmit={handleSubmit} style={{ height: "100%", padding: "1rem" }}>
        {step}
        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            size="large"
            style={{
              backgroundColor: "rgb(0, 81, 176)",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default Form.create({ name: "create_fund_transfer" })(
  CreateFundTransfer
);
