import React from 'react'
import { Table, Typography, Select, Button, Icon, message, Dropdown, Menu} from 'antd'
import axios from 'axios'
import { history } from '../../../../store/history'
import ViewEntryModal from './modals/view_entry_modal'
import MoveTagModal from './modals/move_tag_modal'

const StagesTable = props => {
    const { Option } = Select;

    const {loading, merchantEntries, selectedTab, reloadData, entryIdTotag} = props

    const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
    const isMac = window.screen.width * window.devicePixelRatio == 1440 && window.screen.height * window.devicePixelRatio == 900;

    const [currentBizTypeSelected, setCurrentBizTypeSelected] = React.useState(0);
    const [viewEntryModalVisible, setViewEntryModalVisible] = React.useState(false);
    const [idToTag, setIdToTag] = React.useState(null);
    const [businessName, setBusinessName] = React.useState(null);
    const [businessEmail, setBusinessEmail] = React.useState(null);
    const [businessType, setBusinessType] = React.useState(null);
    const [tagModal, setTagModal] = React.useState(false);
    const [selectedRowData, setSelectedRowData] = React.useState(null);
    const [userId, setUserId] = React.useState(null);

    const updateBusinessType = async(id) => {

        const yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = { 
            'entry_id': id,
            'new_business_type': currentBizTypeSelected,
        }
        
        let response = await axios.put(
            process.env.REACT_APP_API_URL + `/api/merchant_entries/`, params,
            yourConfig
        );

        if(response.data.status == 'success'){
            message.success('Business type successfully updated')
            reloadData()
        }
    }

    const onOpenViewEntryModal = (record) => {
        setIdToTag(record.id);
        setBusinessName(record.business_name);
        setBusinessEmail(record.business_email);
        setBusinessType(record.business_type);
        setViewEntryModalVisible(!viewEntryModalVisible);
        setUserId(record.user_id);
    }

    const onCloseViewEntryModal = () => {
        setIdToTag(null)
        setViewEntryModalVisible(!viewEntryModalVisible)
    }

    const menu = (data) => (
        <Menu>
            <Menu.Item style={{ border: '1px solid #B7B9C0', borderRadius: '4px', gap: '12px', margin: '4px 12px'}} >
                Move to <Icon type="caret-down" />
            </Menu.Item>
        </Menu>
    )

    const statusDetails = {
        8: {
            'status': 'Submitted',
            'background': '#FBE4BA',
            'border': '1px solid #F4B24A'
        },
        9: {
            'status': 'Resubmitted',
            'background': '#FCF7E1',
            'border': '1px solid #EDC143'
        },
        10: {
            'status': 'Rejected',
            'background': '#F6E9E7',
            'border': '1px solid #E77352'
        },
        11: {
            'status': 'Approved',
            'background': '#E4F7EC',
            'border': '1px solid #59C378'
        },
        12: {
            'status': 'Approved',
            'background': '#E4F7EC',
            'border': '1px solid #59C378'
        }
    }
    const columns = [

        {
            title: 'Merchant Name',
            dataIndex: 'business_name',
            fixed: 'left',
            width: 200,
            render: (text) => <Typography style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 200, overflow: 'hidden' }}>{text}</Typography>,
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {onOpenViewEntryModal(record)}, // click row
                };
            }
        },
        {
            title: 'Email Address',
            dataIndex: 'business_email',
            width: 200,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {onOpenViewEntryModal(record)}, // click row
                };
            }
        },
        ['signup', 'pending'].includes(selectedTab) ? 
        {
            title: 'Date Created',
            dataIndex: selectedTab == 'signup' ? 'created_at' : 'kyb_created_at',
            width: 200,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {onOpenViewEntryModal(record)}, // click row
                };
            }
        } : selectedTab == 'review' ?
        {
            title: 'Date Submitted',
            dataIndex: 'date_submitted',
            width: 200,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {onOpenViewEntryModal(record)}, // click row
                };
            }
        } : 
        {
            title: 'Date Approved',
            dataIndex: 'date_approved',
            width: 200,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {onOpenViewEntryModal(record)}, // click row
                };
            }
        },
        ['review', 'live'].includes(selectedTab) ?
        {
            title: 'Action',
            fixed: 'right',
            width: 100,
            render: (record) => {
                return <span onClick={()=>history.push(`/submissions_kyb/${record.user_id}/`)} style={Style.viewStyle}>View</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {onOpenViewEntryModal(record)}, // click row
                };
            }
        } : 
        {
            title: 'Business Type',
            dataIndex: 'business_type',
            width: 200,
            render: (text, record) => (
                <Select
                    loading={loading}
                    style={{ width: 240 }}
                    defaultValue={parseInt(text)}
                    suffixIcon={<Icon type="caret-down" />}
                    onChange={(e)=>setCurrentBizTypeSelected(e)}
                    dropdownRender={menu => (
                    <div style={{padding: '10px'}}>
                        {menu}
                        <Button 
                            onClick={()=>updateBusinessType(record.id)}
                            onMouseDown={e => e.preventDefault()}
                            style={Style.confirmStyle}>Confirm</Button>
                    </div>
                    )}
                >
                    <Option key={0} value={0}>Sole Proprietorship</Option>
                    <Option key={1} value={1}>Corporation</Option>
                    <Option key={2} value={2}>Partnership</Option>
                </Select>
            ),
        },
    ];

    if(selectedTab === 'signup'){
        columns.splice(3, 0, {
            title: 'Tag KYB',
            width: 100,
            render: (record) => {
                return (
                    <a onClick={() => {setSelectedRowData(record); setTagModal(true)}} >
                        <span style={{...Style.dataStyle, fontWeight: '500', cursor: 'pointer'}} onClick={()=>console.log('tag kyb')}> 
                            <Icon type='plus' style={{marginRight: '5px'}}/> Tag
                        </span>
                    </a>
                )
            },
            
        })
    }

    if(selectedTab === 'pending'){
        columns.splice(4, 0, {
            title: 'Action',
            fixed: 'right',
            width: 100,
            render: (record) => {
                return <span onClick={()=>history.push(`/submissions_kyb/${record.user_id}/`)} style={Style.viewStyle}>View</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {onOpenViewEntryModal(record)}, // click row
                };
            } 
        })
    }

    
    if(['review', 'live'].includes(selectedTab)){
        columns.splice(3, 0, {
            title: 'Remarks',
            dataIndex: 'remarks',
            width: 170,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {onOpenViewEntryModal(record)}, // click row
                };
            }
        })
        columns.splice(4, 0, {
            title: 'Contract Expiry',
            dataIndex: 'contract_expiry',
            width: 150,
            render: text => {
                return <span style={Style.dataStyle}>{text}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {onOpenViewEntryModal(record)}, // click row
                };
            }
        })
        columns.splice(5, 0, {
            title: 'Status',
            dataIndex: 'step',
            width: 125,
            render: text => {
                return(
                    <div style={{
                        ...Style.status,
                        backgroundColor: statusDetails[text].background,
                        border: statusDetails[text].border
                    }}>
                        {statusDetails[text].status}
                    </div>
                )
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {onOpenViewEntryModal(record)}, // click row
                };
            }
        })
    }

    React.useEffect(() => {
        if (entryIdTotag) {
            setSelectedRowData(entryIdTotag);
            setTagModal(true);
        }
    },[entryIdTotag])

    return(
        <div>
            <Table
                pagination={{position: 'none', pageSize: '20'}}
                loading={loading}
                style={{width: isMac ? '78vw' : isHighReso ? '85vw' : '75vw' , display: 'block'}}
                scroll={{x: ['review', 'live'].includes(selectedTab) ? 1100 : 0 }}
                columns={columns} 
                dataSource={merchantEntries}/>
            <ViewEntryModal 
                visible={viewEntryModalVisible}
                onCancel={onCloseViewEntryModal}
                merchantId={idToTag}
                businessName={businessName}
                businessEmail={businessEmail}
                businessType={businessType}
                userId={userId} 
                selectedTab={selectedTab} />
            <MoveTagModal
                visible={tagModal}
                onClose={() => setTagModal(false)}
                selectedRowData={selectedRowData}
                taggedSuccess={() => {reloadData(); setTagModal(false);}}
            />
        </div>
    )
}

const Style = {
    dataStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2B2D32'
    },
    menuStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33',
        padding: '10px',
        margin: 'auto'
    },
    confirmStyle: {
        width: '100%',
        height: '40px',
        color: '#FFF',
        fontWeight: '500',
        fontSize: '14px',
        backgroundColor: '#0A315E'
    },
    status:{
        padding: '4px 8px',
        color: '#2b2d32',
        fontSize: '12px',
        fontWeight: '400',
        textAlign: 'center',
        borderRadius: '4px'
    },
    viewStyle: {
        fontWeight: '700',
        fontSize: '14px',
        color: '#254A76',
        textDecoration: 'underline',
        cursor: 'pointer'
    }
}

export default StagesTable