import React from 'react';
import { Card } from 'antd';
import { isMobile } from 'react-device-detect';
import TestModeBanner from '../../cards/test_mode/testModeBanner';
import { history } from '../../../store/history';

const SubUserLoginSignup = (props) => {
    const testMode = (history.location.pathname.indexOf('/test') > -1);

    const { children } = props;
    return (
        <div style={styles.root}>
            <TestModeBanner width={'100vw'} top={0} right={0} hidden={!testMode} />

            <div style={styles.children} bodyStyle={styles.bodyStyle}>
                {children}
            </div>
        </div>
    )
}

const styles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#E6EAF0',
        width: '100%',
        height: '100vh',
    },
    children: {
        display: 'flex', 
        justifyContent: 'center',
        width: 584,
        borderRadius: 8,
        margin: '78px 0'
    },
    bodyStyle: {
        padding: isMobile? 20 : 62
    }
}

export default SubUserLoginSignup;