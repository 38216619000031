import React, {useEffect} from 'react'
import WooCommerceLogo from '../../../static/icons/woo_commerce.svg'
import PrestaShopLogo from '../../../static/icons/prestashop.svg'
import SentroLogo from '../../../static/icons/sentro.svg'
import Shopify from '../../../static/icons/shopify.svg'
import Kajabi from '../../../static/icons/kajabi.svg'
import {Button, Card, Input, message, Icon} from 'antd'
import { isMobile } from 'react-device-detect'
import axios from 'axios';

import Arrow from '../../../static/icons/arrow_down_orange.svg'
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import {history} from '../../../store/history'
import { cardDiv } from '../../Mobile/funds/funds_mobile_style'
import { LaptopWindowsRounded } from '@material-ui/icons'

const Plugins = ({segments, creds}) => {
    const buxpluginpsver = process.env.REACT_APP_PS_PLUGIN_VERSION;
    const buxpluginzip = 'https://wordpress.org/plugins/bux-woocommerce/';
    const buxpluginpszip = '/bux-plugin-ps-1.0.2.zip';

    const [token, setToken] = React.useState("")
    const [key, setKey] = React.useState("")
    const [secret, setSecret] = React.useState("")

    const testMode = (history.location.pathname.indexOf('/test') > -1);

    async function saveCreds() {
        try{

            const yourConfig = {
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            }        

            const params = {'shopify_token': token,
                            'shopify_key': key,
                            'shopify_secret': secret}
            let response = await axios.post(
                process.env.REACT_APP_API_URL + '/api/settings/',
                params,
                yourConfig
            );

            if(response.data.status == "success"){
                message.success('Successfully updated settings')
            }else{
                message.error('Could not update settings')
            }


        }catch(error){
        }

    }


    useEffect(()=>{
        setToken(creds.shopify_token ? creds.shopify_token : '');
        setKey(creds.shopify_key ? creds.shopify_key : '');
        setSecret(creds.shopify_secret ? creds.shopify_secret : '');
    },[creds])


    const version={
        color: '#909196',
        fontWeight: 600,
        lineHeight: '12px',
        textAlign: 'right'
    }

    
    const how = {
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '24px',
        // color: '#54575F',
        textDecoration: 'underline'
    }

    const Accordion = withStyles({
        root: {
          border: '1px solid #E6EAF0',
          boxShadow: 'none',
          borderRadius: '10px',
          '&:before': {
            display: 'none',
          },
        },
        expanded: {},
    })(MuiAccordion);
    
    const AccordionSummary = withStyles({
        root: {
          backgroundColor: '#fff',
          borderRadius: '10px',
        },
        content: {
          '&$expanded': {
            margin: '12px 0',
          },
        },
        expanded: {},
    })(MuiAccordionSummary);
      
    const AccordionDetails = withStyles((theme) => ({
        root: {
          padding: theme.spacing(2),
        },
    }))(MuiAccordionDetails);

    const CommerceCard = (logo, click, placeholder, hidden, redirection) => {
        return(
            <div className="credentials-div-card top-20" hidden={hidden}>
                <div className="twoCol">
                    <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                        <img src={logo} alt="logo" />
                        <a hidden={!redirection} style={{...how, margin: click === '/integration/kajabi/'? '0' : '-18px 0 0 0px'}} className={`text-${segments}`}
                            onClick={() => window.open(redirection)} 
                            target="_blank" >
                            How to integrate your account?
                        </a>
                    </div>
                    
                    <div align="right" className="top-8"> 
                        <button 
                            onClick={() => window.open(click)} 
                            target="_blank" 
                            className={`outline-btn--${segments} btn-height `}>
                                {placeholder}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    const downloadLink = () => {
        return(
            <div style={{ fontSize: "10px" }}>
                <Icon type="download" className={`text-${segments} right-4`} />
                Download link
            </div>
        )
    }

    return(
        <div className="top-20 bottom-32">
            <div className="bold-text-size liveColor creds-note-div"> 
                E-commerce store
            </div>

            {CommerceCard(SentroLogo, 'https://sentro.ph', 'Visit Sentro')}
            {CommerceCard(PrestaShopLogo, buxpluginpszip, downloadLink(), false, '/presta_shop_tutorial')}
            {CommerceCard(WooCommerceLogo, buxpluginzip, downloadLink(), false, '/woocommerce_tutorial')}
            <div hidden={false} className="credentials-div-card top-20">
                <Accordion style={{border: 0}}>
                    <AccordionSummary style={{height: 89, padding: 0}} 
                        expandIcon={<Icon type="down" style={{width: 50}} className={`text-${segments}`} />}>
                        <div>
                            <div className="flex">
                                <img src={Shopify} style={{maxHeight:'40px', marginRight: '10px'}}/>
                                {/* <div style={{fontWeight: '900', fontSize: '20px', color: '#000', lineHeight: '24px', marginRight: '10px'}}>Shopify</div> */}
                                {/* <div style={beta}>Beta</div> */}
                            </div>
                            <div style={how} className={`text-${segments}`} onClick={()=> window.open('/shopify_tutorial')}>
                                How to integrate your account?
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="liveColor" style={{width: '100%'}}> 
                            <div style={{...version, fontSize: '16px'}} hidden={true}>
                                Coming Soon
                            </div>
                            <div className="top-12">
                                <span className="normalTextStyle">
                                    APP API Key
                                </span>
                            </div>
                            <Input value={key}  onChange={(e) => setKey(e.target.value)}/>

                            <div className="top-20">
                                <span className="normalTextStyle">
                                    APP Password
                                </span>
                            </div>
                            <Input value={secret} onChange={(e) => setSecret(e.target.value)}/>

                            <div className="top-20">
                                <span className="normalTextStyle">
                                    Webhook Token
                                </span>
                            </div>
                            <Input value={token} onChange={(e) => setToken(e.target.value)}/>

                            <div className="top-20" align="right">
                                <button className={`btn--${segments} btn-height`} onClick={()=>saveCreds()}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            {CommerceCard(Kajabi, `/integration/kajabi/`, "Go to offers", ['me_', 'gig'].includes(segments), '/kajabi_tutorial' )}
        </div>
    )
}

export default Plugins