import React from "react";
import { Typography, Skeleton, Card } from "antd";
import { MultiwalletInfoBG } from "../../../../../../static/new_segment/admin";

const { Text } = Typography;

export function MerchantDetails({ data, isLoading, error }) {
  if (isLoading) {
    return <Skeleton />;
  }

  if (error) {
    return <div>Error loading data.</div>;
  }

  return (
    <Card>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "4em",
        }}
      >
        <div
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            fontSize: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text strong>Business Name:</Text>
            <Text>{data && data.business_name}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text strong>Name:</Text>
            <Text>{data && data.full_name}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text strong>Email Address:</Text>
            <Text>{data && data.email}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text strong>Rail type:</Text>
            <Text>{data && data.rail_type}</Text>
          </div>
        </div>
        <div style={{ flex: "1", display: "flex", flexDirection: "column" }}>
          <img src={MultiwalletInfoBG} alt="Multiwallet Background" />
        </div>
      </div>
    </Card>
  );
}
