import React, {useEffect}from 'react'
import {Select, Button, Input, message} from 'antd'
import Avatar from '../../static/images/signup_and_login/login_avatar.png'
import NewBuxLogo from '../../static/images/signup_and_login/new_bux_logo.svg'
import {NATURE_BUSINESS} from '../../components/constants/constants'

import MeLogo from '../../static/images/more_about/me_icon.svg'
import GigLogo from '../../static/images/more_about/gig_icon.svg'
import SmeLogo from '../../static/images/more_about/sme_icon.svg'
import BizLogo from '../../static/images/more_about/biz_icon.svg'

import Background from '../../static/images/more_about/background.svg'
import MeBackground from '../../static/images/more_about/buxme.png'
import GigBackground from '../../static/images/more_about/buxgig.png'
import SmeBackground from '../../static/images/more_about/buxsme.png'
import BizBackground from '../../static/images/more_about/buxbiz.png'

import BuxAvatar from '../../static/images/more_about/bux_avatar.png'
import GigAvatar from '../../static/images/more_about/gig_avatar.png'
import MeAvatar from '../../static/images/more_about/me_avatar.png'
import SmeAvatar from '../../static/images/more_about/sme_avatar.png'
import BizAvatar from '../../static/images/more_about/biz_avatar.png'
import { isMobile } from 'react-device-detect'
import { history } from '../../store/history'
import { Handle401 } from '../../components/handle401/handle401'
import axios from 'axios'

const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
const isMac = window.screen.width * window.devicePixelRatio == 1440 && window.screen.height * window.devicePixelRatio == 900;

const MoreAboutMain = (props) => {

    useEffect(()=>{
        if(localStorage.getItem("jwtToken") == ""){
            history.push('/login')
        }
        if(localStorage.getItem("userType") != 'ME' && localStorage.getItem("userType") != 'CO'){
            history.push('/dashboard')
        }
        if(localStorage.getItem("segments") != 'nil'){
            history.push('/dashboard')
        }

    },[])
    const {Option} = Select
    const [selectedSegment, setSelectedSegment] = React.useState("")
    const [selectedNature, setSelectedNature] = React.useState(0)
    const [businessName, setBusinessName] = React.useState("")

    const submitSegment = async() => {
        let config = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let bodyRequest = {
            segment: selectedSegment,
            business_name: businessName,
            nature_business: selectedNature
        }
        try{
            let response = await axios.post(
                process.env.REACT_APP_API_URL + "/api/choose_segment/", bodyRequest, config
            );
    
            if(response.data.status == 'success'){
                localStorage.setItem('segments', selectedSegment.toLowerCase())
                window.location.href = '/welcome_page'
            }
            else{
                message.error(response.data.message)
            }
        }
        catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    Handle401()
                }
            } else {
                throw new Error('No response from backend server!');
            }
        }
        
    }

    const Segments = {
        '':{
            icon: NewBuxLogo,
            avatar: BuxAvatar,
            header1: 'We want to know more about you!',
            header2: 'We want to know more about you!',
        },
        'ME_':{
            icon: MeLogo,
            avatar: MeAvatar,
            header1: 'I will use BUx to manage my personal finance',
            header2: 'Personal Finance',
            text: 'Collect payments by sending payment links via any messaging app. Use your earnings to purchase online with a virtual debit card!',
        },
        'GIG':{
            icon: GigLogo,
            avatar: GigAvatar,
            header1: 'I will use BUx for my casual or freelance business',
            header2: 'Casual or Freelance Business',
            text: 'Send payment requests, provide a seamless and simplified Checkout page for users to pay 24/7 for your products or services.',
        },
        'SME':{
            icon: SmeLogo,
            avatar: SmeAvatar,
            header1: 'I will use BUx for my small or medium business',
            header2: 'Small or Medium Business',
            text: 'Customize your existing digital experience by integrating BUx via eCommerce plugins or direct API. Offer more and get more!',
        },
        'BIZ':{
            icon: BizLogo,
            backgroundStyle: {
                
            },
            avatar: BizAvatar,
            header1: 'I will use BUx for a large business or organization',
            header2: 'Corporate Business',
            text: 'Customized payment and business solutions to help your organization grow. BUx is here to help you and your organization.',
        },

    }

    return(
        <div>
            <div style={style.mainDiv}>
                
                <div style={{height: !isMobile && '90vh'}}>
                    <div style={{display: 'flex', justifyContent: isMobile && 'center'}}>
                        <img src={Segments[selectedSegment].icon} alt="#" style={{height: !isHighReso && '45px'}}/>
                    </div>
                    {
                        isMobile ? 
                        <div  style={{...style.titleText, marginTop: '24px', textAlign: 'center'}}>
                            <div style={{...style.titleText, marginTop: '24px'}}>{Segments[selectedSegment].header2}</div>
                        </div> :
                        <div style={{marginTop: '24px', width: '100%'}}>
                            <div style={{...style.titleText, marginTop: '24px',
                                marginRight: '30px'}}>
                                {Segments[selectedSegment].header1}
                            </div>
                        </div>
                    }
                    {
                        isMobile ?
                        <div style={{...style.textStyle}}> {Segments[selectedSegment].text}</div> :
                        <div style={{...style.textStyle, marginRight: '50px'}}>{Segments[selectedSegment].text}</div>
                    }
                    <img src={Segments[selectedSegment].avatar}  hidden={isMobile}
                        style={{...style.avatarStyle, 
                        marginLeft: 
                            selectedSegment == "" ? '10%' :
                            selectedSegment == 'BUxBIZ' ? '20%' :
                            (!isMac && selectedSegment != 'BUxSME') && '16%'
                        }}
                    />
                </div>
                <div style={{height: '100%', marginTop: !isMobile && '-2%'}}>
                    <div style={style.cardStyle}>
                        <div style={{display: 'flex', justifyContent: 'center'}} hidden={isMobile}>
                            <div style={style.cardHeader}>You are just a few steps away from using BUx. </div>

                        </div>
                        <div style={{marginTop: '20px'}}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <div style={{width: isMobile && '100%'}}>
                                    <div style={style.label}>How do you plan on using BUx?</div>
                                    <Select
                                        style={{width: isMobile ? '100%' : '430px'}}
                                        size="large"
                                        onChange={(e)=>{
                                            setSelectedSegment(e)}
                                        }
                                        value={selectedSegment}
                                        >
                                        <Option value="ME_">For personal use</Option>
                                        <Option value="GIG">For casual selling</Option>
                                        <Option value="SME">For a small or medium business</Option>
                                        <Option value="BIZ">For a corporation or large enterprise</Option>
                                    </Select>
                                    <div style={{marginTop: '20px'}} hidden={!['SME', 'BIZ'].includes(selectedSegment)}>
                                        <div style={style.label}>What’s the nature of your business?</div>
                                        <Select
                                            style={{width: isMobile ? '100%' :  '430px'}}
                                            size="large"
                                            value={NATURE_BUSINESS[selectedNature]}
                                            onChange={(e)=>{
                                                setSelectedNature(e)}
                                            }
                                            value={selectedNature}
                                            >
                                            {
                                                NATURE_BUSINESS.map((item,i)=>{
                                                    return(
                                                        <Option value={i}>{item}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                        <div  style={{marginTop: '20px'}}>
                                            <div style={style.label}>What’s the name of your business?</div>
                                            <Input maxLength="100" type="large" style={{width:  isMobile ? '100%' :  '430px', height: '40px'}} onChange={(e)=>setBusinessName(e.target.value)}/>
                                        </div>
                                    </div>   
                                    <div style={{marginTop: '20px'}}>
                                        <Button style={style.loginBtn} onClick={()=>submitSegment()}>Submit</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img src={
                selectedSegment == 'None' ? MeBackground :  
                selectedSegment == 'ME_' ? MeBackground :  
                selectedSegment == 'GIG' ? GigBackground :  
                selectedSegment == 'SME' ? SmeBackground:  
                BizBackground
            } 
            style={style.imageStyle} hidden={isMobile}/>

        </div>
        
    )
}

const style  = {
    imageStyle: {
        height: '100vh',
        width: '100%',
        position: 'absolute',
        zIndex: 1
    },
    mainDiv:{
        backgroundColor: isMobile && '#0A315E',
        position: 'absolute',
        width: '100%',
        height: '100%',
        padding: isMobile ? '37px 16px 0px 16px' : isHighReso ? '80px 80px 0px 80px' : '50px 60px 0px 60px',
        display: !isMobile && 'flex', 
        justifyContent: 'space-between',
        zIndex: 2,
        overflowX: 'hidden',
        overflowY: 'hidden',

    },
    titleText:{
        fontFamily: 'Montserrat',
        color: '#FFFFFF',
        fontSize: isMobile ? '18px' : isHighReso ? '40px' : '30px',
    },
    textStyle:{
        fontWeight: '400',
        fontSize: isMobile ? '14px' : '16px',
        color: '#FFF',
        marginTop: '10px',
        textAlign: isMobile && 'center'
    },
    avatarStyle:{
        height: '65%', 
        marginTop: isHighReso ? '30px' : '20px',
    },
    cardStyle:{
        width: isMobile ? '100%' : '529px',
        borderRadius: '8px',
        backgroundColor: '#fff',
        boxShadow: '0px 8px 24px rgba(0, 35, 11, 0.12)',
        paddingTop: isMobile ? '30px' : '50px',
        paddingBottom: isMobile ? '30px' : '50px',
        paddingLeft: isMobile && '16px',
        paddingRight: isMobile && '16px',
        marginTop: isMobile && '20px'
    },
    loginBtn:{
        width:  isMobile ? '100%' :  '430px',
        height: '40px',
        borderRadius:'4px',
        border: '1px solid #F5922F',
        backgroundColor: '#F5922F',
        fontSize: '16px',
        color: '#fff',
        fontWeight: 'bold'
    },
    cardHeader:{
        width: '430px',
        display: 'flex', 
        justifyContent: 'center', 
        fontSize: '28px', 
        color: '#000', 
        fontWeight: 'bold',
        textAlign: 'center'
    },
}

export default MoreAboutMain