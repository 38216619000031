import React,{ useState } from 'react';
import { Button, Modal, Table, Typography } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { useSelector } from 'react-redux';
import { setSelectedBtn } from '../orderAction';
import { randomize } from '../../constants/constants';

const rowBG = {
  render(text, record) {
    return {
      props: {
        style: { background: record.findings !==null? '#f9dbdb':'transparent' },
      },
      children: <div>{text}</div>,
    };
  },
}

const columns = [
  {
    title: 'Amount',
    dataIndex: 'Amount (PHP)',
    render(text, record) {
      return {
        props: {
          style: { background: record.findings !==null? '#f9dbdb':'transparent' },
        },
        children: <div>{text}</div>,
      };
    },
  },
  {
    title: 'Description',
    dataIndex: 'Description',
    render(text, record) {
      return {
        props: {
          style: { background: record.findings !==null? '#f9dbdb':'transparent' },
        },
        children: <div>{text}</div>,
      };
    },
  },
  {
    title: `Customer Name`,
    dataIndex: `Customer Name`,
    render(text, record) {
      return {
        props: {
          style: { background: record.findings !==null? '#f9dbdb':'transparent' },
        },
        children: <div>{text}</div>,
      };
    },
  },
  {
    title: `Customer Email`,
    dataIndex: `Customer Email`,
    render(text, record) {
      return {
        props: {
          style: { background: record.findings !==null? '#f9dbdb':'transparent' },
        },
        children: <div style={{whiteSpace: 'pre-wrap', maxWidth: 100}}>{text}</div>,
      };
    },
  },
  {
    title: `Link Expiry (Hours)`,
    dataIndex: `Link Expiration (Hours)`,
    render(text, record) {
      return {
        props: {
          style: { background: record.findings !==null? '#f9dbdb':'transparent' },
        },
        children: <div>{text} {text&&'Hours'}</div>,
      };
    },
  },
  {
    title: `Mobile Number`,
    dataIndex: 'Mobile Number',
    render(text, record) {
      return {
        props: {
          style: { background: record.findings !==null? '#f9dbdb':'transparent' },
        },
        children: <div>{text}</div>,
      };
    },
  },
  {
    title: `Remarks`,
    dataIndex: 'findings',
    render(text, record) {
      return {
        props: {
          style: { background: record.findings !==null? '#f9dbdb':'transparent' },
        },
        children: <div>{text}</div>,
      };
    },
  },
]

const TablePreviewModal = ({visible, closeModal, data, batchCode, isPreviewModalTableLoading}) => {
  const [selected, setSelected] = useState('All');
  const selectedBtn = useSelector(state => state.orders.selectedBtn);

  const ButtonTab = ({rootStyle}) => {
    const status = ['All', 'Error'];
    
    const handleClick = (event) => {
      setSelected(event.target.value);
    }

    const setTotalError = () => {
      let arr = 0;
        flatData.map((key,i) => {
          if(key.findings!==null){
           arr+=1;
          }
        });
      return arr;
    }
    
    return (
      <div style={rootStyle} hidden={selectedBtn==='Generated'}>
        <ButtonGroup style={{padding: '20px 0'}}>
          {
            status.map((btn, i) => {
              return (
                <Button
                  key={btn}
                  value={btn}
                  onClick={handleClick}
                  style={{...styles.btnGroup, 
                    color: btn === selected? '#FFF' : '#2B2D33', 
                    backgroundColor: btn === selected? '#0D3D76' : '#FFF',
                    borderRadius: i === 0? '10px 0 0 10px' : i === 1? '0 10px 10px 0' : 0 }}
                    >
                    {btn==='All'?btn:btn + ` (${setTotalError()})`}
                </Button>
              )
            })
          }
        </ButtonGroup>
      </div>
    )
  }

  const flatData = Object.keys(data).map((key,i) => {
    data[i]['key'] = randomize();
    return data[i];
  });
  
  const errorData = () => {
    let arr = new Array;
      flatData.map((key,i) => {
        if(key.findings!==null){
          arr.push(flatData[i]);
        }
      });
    return arr;
  }
  
  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={()=>{closeModal();setSelected('All')}}
      width={964}
      centered
      bodyStyle={styles.root}>
      <Typography style={styles.title}>Batch Code: {batchCode}</Typography>
      <ButtonTab />
      {selectedBtn==='Generated'&&<br />}
      <Table
        loading={isPreviewModalTableLoading}
        rowClassName="table-row-light"
        columns={columns}
        pagination={{pageSize: 10, size: 'small'}}
        dataSource={selected==='All'?flatData:errorData()}
      />
    </Modal>
  )  
}


const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 24,
    fontWeight: '300',
    color: '#2B2D33',
    padding: '0 0 0 0'
  },
  btnGroup: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '0 32px'
  },
}

export default TablePreviewModal;