import React from 'react';
import fraudServices from '../fraudServices';
import moment from 'moment';
import { Button, Icon, Input, Modal, Typography, message, Select } from 'antd';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const AddNewCorpModal = ({visible, closeModal, onSuccess}) => {
  const [cancelBtnStyle, setCancelBtnStyle] = React.useState({color: '#F5922F', backgroundColor: '#FFF'});
  const [isLoading, setIsLoading] = React.useState(false);
  const [domainName, setDomainName] = React.useState(null);
  const [params, setParams] = React.useState([]);
  const [month, setMonth] = React.useState(moment().month("January").format('M'))
  const [dayOptions, setDayOptions] =React.useState(null);
  const [year, setYear] = React.useState(moment().subtract(18, 'y'));
  const [day, setDay] = React.useState(1);

  const cancelBtnHover = () => {
    setCancelBtnStyle({
      color: '#FFF',
      backgroundColor: '#F5922F',
    })
  }

  const cancelBtnLeave = () => {
    setCancelBtnStyle({
      color: '#F5922F',
      backgroundColor: '#FFF',
    })
  }

  const handleChange = (event) => {
    setParams({...params, [event.target.name]:event.target.value})
  }

  const hasBlanks = () => {
    // const isEmpty = Object.values(params).some(x => (x === null || x === ''));
    if(!params['first_name'] || !params['last_name']){
      return true;
    }
    return false;
  }

  const success = (msg) => {
    message.success(msg);
  };

  const errorPrompt = (msg) => {
    message.error(msg);
  };

  const addNewDomain = async () => {
    setIsLoading(true);
    try {
      const res = await fraudServices.addNewBlacklist('kyc',params);
      if(res.status==='success'){
        success(res.message);
        onSuccess();
      } else {
        errorPrompt(res.message);
      };
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      errorPrompt(error);
    }
  }

  const getMonths = () => {
    const months = [];
    const dateStart = moment().month("January");
    const dateEnd = moment().add(12, 'month')
    while (dateEnd.diff(dateStart, 'months') >= dateEnd.format('M')) {
     months.push(<Select.Option key={dateStart.format('M')}>{dateStart.format('MMMM')}</Select.Option>)
     dateStart.add(1, 'month')
    }
    return months
  }

  const getMonthDays = (month, year) => {
    const months30 = ['4', '6', '9', '11'];
    const leapYear = year % 4 === 0;
    let monthDays = 0;
    const days = [];

    monthDays = month === '2'
      ? leapYear
        ? 29
        : 28
      : months30.includes(month)
        ? 30
        : 31;

    for(let i = 1; i <= monthDays; i++){
      days.push(<Select.Option key={i}>{i}</Select.Option>)
    }
    return days;
  }

  const getYears = () => {
    const years = []
    const dateStart = moment().year(1930)
    const dateEnd = moment().subtract(18, 'y')
    while (dateEnd.diff(dateStart, 'years') >= 0) {
      years.push(<Select.Option key={dateStart.format('YYYY')}>{dateStart.format('YYYY')}</Select.Option>)
      dateStart.add(1, 'year')
    }
    return years.reverse();
   }

  const resetProps = () => {
    const bday = `1/1/${moment(moment().subtract(18, 'y')).format('YYYY')}`
    setParams({birthday:moment(bday).format('YYYY-MM-DD')});
    setMonth(moment().month("January").format('M'));
    setYear(moment().subtract(18, 'y'));
    setDay(1);
    setIsLoading(false);
  }

  const handleMonthChange = (value) => {
    const bday = `${value}/${day}/${moment(year).format('YYYY')}`
    setMonth(value)
    setDayOptions(getMonthDays(value, year));
    setParams({...params, birthday:moment(bday).format('YYYY-MM-DD')});
  }

  const handleDayChange = (value) => {
    const bday = `${month}/${value}/${moment(year).format('YYYY')}`
    setDay(value);
    setParams({...params, birthday:moment(bday).format('YYYY-MM-DD')});
  }

  const handleYearChange = (value) => {
    const bday = `${month}/${day}/${value}`
    setYear(value);
    setDayOptions(getMonthDays(month, value))
    setParams({...params, birthday:moment(bday).format('YYYY-MM-DD')});
  }

  React.useEffect(() => {
    const bday = `${month}/${day}/${moment(year).format('YYYY')}`
    setDayOptions(getMonthDays(month, year));
    setParams({...params, birthday:moment(bday).format('YYYY-MM-DD')});
  },[])

  
  return (
    <Modal
    visible={visible}
    footer={null}
    onCancel={closeModal}
    destroyOnClose
    afterClose={resetProps}
    width={470}
    centered
    closeIcon={<Icon type="close" style={{color:'#F5922F'}}/>}
    bodyStyle={styles.root}>
    <Typography style={styles.title}>Add a Blacklisted Individual Account?</Typography>
   <div style={styles.nameContainer}>
    <div style={{width: '48%'}}>
      <label style={styles.label}>First Name</label>
      <Input 
          name='first_name'
          placeholder="First Name"
          style={styles.input}
          onChange={handleChange}
          disabled={isLoading}
      />
    </div>
    <div style={{width: '48%'}}>
      <label style={styles.label}>Last Name</label>
      <Input 
          name='last_name'
          placeholder="Last Name"
          style={styles.input}
          onChange={handleChange}
          disabled={isLoading}
      />
    </div>
   </div>
   <div>
     <Typography style={styles.bdayText}>Birthdate</Typography>
     <div style={styles.bdayPickerContainer}>
      <div style={{width: '32%'}}>
        <label style={styles.label}>Month</label>
        <Select
          defaultValue={getMonths()[0].props.children}
          style={{ height: 40 }}
          onChange={handleMonthChange}
          style={{...styles.input, width: '100%', padding: 0}}
          size='large'
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {getMonths()}
        </Select>
      </div>
      <div style={{width: '32%'}}>
        <label style={styles.label}>Day</label>
        <Select
          defaultValue={getMonthDays(month, moment().format('YYYY'))[0].props.children}
          onChange={handleDayChange}
          style={{...styles.input, width: '100%', padding: 0}}
          size='large'
        >
          {dayOptions}
        </Select>
      </div>
      <div style={{width: '32%'}}>
        <label style={styles.label}>Year</label>
        <Select
          defaultValue={getYears()[0].props.children}
          onChange={handleYearChange}
          style={{...styles.input, width: '100%', padding: 0}}
          size='large'
          showSearch
          optionFilterProp="children"
          removeIcon
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {getYears()}
        </Select>
      </div>
     </div>
   </div>
   <label style={styles.label}>Id Number</label>
    <Input 
        name='ID_number'
        placeholder="ID Number"
        style={styles.input}
        onChange={handleChange}
        disabled={isLoading}
    />
    <label style={styles.label}>Referrer</label>
    <Input 
        name='referrer'
        placeholder="Referrer"
        style={styles.input}
        onChange={handleChange}
        disabled={isLoading}
    />
    <label style={styles.label}>Remarks</label>
    <Input.TextArea
      name='remarks' 
      placeholder="Remarks"
      style={styles.input}
      onChange={handleChange}
      disabled={isLoading}
      autoSize={{ minRows: 3, maxRows: 5 }}
    />
    <div style={styles.footerBtn}>
      <Button style={{...styles.cancelBtn, ...cancelBtnStyle}}
        onClick={closeModal}
        onMouseLeave={cancelBtnLeave}
        onMouseOver={cancelBtnHover}>Cancel</Button>
      <Button style={{...styles.confirmBtn, opacity: hasBlanks()? '0.5' : '1' }} 
        disabled={hasBlanks()} 
        onClick={addNewDomain}
        loading={isLoading}>Confirm</Button>
    </div>
  </Modal>
  )
}

const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  title: {
    fontSize: 20,
    color: '#2B2D33',
    padding: '32px 0 2px 0',
  },
  cancelBtn: {
    width: '48%',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    height: 48,
    border: '1px solid #F5922F',
  },
  confirmBtn: {
    height: 48,
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    width: '48%',
  },
  footerBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 20,
  },
  label: {
    fontSize: 12,
    color: '#212B36',
    paddingTop: 16,
  },
  input: {
    padding: '11px 16px 11px 16px',
    fontSize: 16,
    height: 48,
    margin: '4px 0px 0px 0px',
    color: '#2B2D33'
  },
  nameContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 20,
  },
  bdayPickerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 4,
  },
  bdayText: {
    fontSize: 16,
    fontWeight: 'bold',
    paddingTop: 16,
    color: '#000'
  }
}


export default AddNewCorpModal;