import React, { useState } from "react";
import BuxWalletBalance from "./bux_wallet_balance/buxWalletBalance";
import MyCard from "./my_card/myCard";
import InstaPayPesonet from "./instapay_pesonet/instapayPesonet";
import TransactionHistory from './transaction_history/transactionHistory';
import cardServices from '../../card/cardServices';
import cardActions from '../../card/cardActions';
import { useDispatch } from "../react-redux-hooks";

const DashboardSidebar = ({isMobile, isTablet, smallReso, isTestMode, isDellReso}) => {
  const [initialized, setInitialized] = useState(false);
  const [balance, setBalance] = useState("0.00")
  const [buxbalance, setBuxBalance] = useState(null)
  const [expiryDate, setExpiryDate] = useState("")
  const [cardNumber, setCardNumber] = useState(null)
  const [lockCard, setLockCard] = useState(null);
  const [noEon, setNoEon] = useState(null);
  const [custId, setCustId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [physicalCard, setPhysicalCard] = useState(null);
  const dispatch = useDispatch();
  const level = localStorage.getItem('Level')
  const [xenditBal, setXenditBal] = useState(null);
  const [ccEnabled, setCCEnabled] = useState("");

  const getCardInfo = async () => {
    try {
      const response  = await cardServices.getCardInfo(isTestMode);
      // console.log('SIDEBARRRR____DASHBOARD',response);
      setBuxBalance(response.bux_balance)
      setXenditBal(response.cc_balance);
      setCCEnabled(response.cc_enabled);
      if(response.lastFourDigits){
          setNoEon(response.lastFourDigits);
          setBalance(response.account.availableBalance);
          setCardNumber(response.lastFourDigits);
          setExpiryDate(response.expiryDate);
          setLockCard(response.status == '1' ? false : true);
          dispatch(cardActions.setCardLock(response.status == '1' ? false : true));
          setCustId(response.customer_id);
          setPhysicalCard(response.physical);
      }
      console.log(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error)
      setNoEon('error');
      setIsLoading(false);
    }
  }

  if(!initialized) {
    getCardInfo();
    dispatch(cardActions.hideModal('otp_mobile'));
    setInitialized(true);
  }

  const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;

  return (
    <div style={{margin: 0, padding: 0}}>
      {
        isMobile || isTablet || smallReso ?
        <div>
          <BuxWalletBalance 
            id='buxWallet'  
            balance={buxbalance} 
            isMobile={isMobile} 
            isTablet={isTablet} 
            smallReso={smallReso} 
            isTestMode={isTestMode} 
            xenditBal={xenditBal} 
            ccEnabled={ccEnabled}/>
        </div>
        :
        <div style={{ flex: isMobile == true ? 0 : isHighReso ? 2.4 : 3, backgroundColor: isMobile? '#F4F6F9' : '#E9EEF4', height: isMobile && '100vh' }}>
        {/* <div style={{position: 'fixed'}}> */}
          <BuxWalletBalance id='buxWallet'  
          balance={buxbalance} 
          isDellReso={isDellReso}
          isMobile={isMobile}
          isTablet={isTablet} 
          smallReso={smallReso}  
          isTestMode={isTestMode} 
          xenditBal={xenditBal} 
          ccEnabled={ccEnabled}/>
          <div hidden={localStorage.getItem("userType") == "CO"} style={{padding: 16}}>
            <InstaPayPesonet isMobile={isMobile} />
    
            {/* <MyCard 
              balance={balance} 
              cardNumber={cardNumber} 
              buxBalance={buxbalance} 
              expiryDate={expiryDate} 
              isMobile={isMobile} 
              lockCard={lockCard} 
              noEon={noEon}  
              custId={custId}
              isLoading={isLoading}
              physicalCard={physicalCard}
              /> */}
          </div>
    
          <TransactionHistory isMobile={isMobile} custId={custId} isTestMode={isTestMode} />
    
        </div>
      }
      
    </div>

  )
}

export default DashboardSidebar;  