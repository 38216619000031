import {
    GET_ORDERS_BEGIN,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_ERROR,
    SET_BATCH_UPLOADED,
    SET_SELECTED_BTN,
    DEL_FILE_UPLOADED_ROW,
    UPDATE_UPLOADED_ROW,
    SET_BATCH_GENERATED,
} from './orderConstants';
import {history} from '../../store/history'

const initialState = {
    loading: false,
    error: null,
    batchUploaded: [],
    batchGenerated: [],
    selectedBtn: 'Uploaded',
}

const testMode = history.location.pathname.indexOf('/test') > -1&&localStorage.getItem("userType") == "CO";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDERS_BEGIN:
            return {...state, loading: true};
        case GET_ORDERS_SUCCESS:
            return {...state, loading: false, orders: action.payload.orders, summary: action.payload.summary, token: action.payload.token};
        case GET_ORDERS_ERROR:
            return {...state, loading: false, error: action.payload};
        case SET_BATCH_UPLOADED:
            return {...state, batchUploaded: action.payload};
        case SET_SELECTED_BTN:
            return {...state, selectedBtn: action.payload}; 
        case DEL_FILE_UPLOADED_ROW:
            // let copy = Object.assign({}, state) 
            // delete copy.batchUploadFiles[action.payload]
            // return {...state, copy}
            return {...state, batchUploaded: [
                ...state.batchUploaded.slice(0, action.payload),
                ...state.batchUploaded.slice(action.payload + 1)
            ]};     
        case UPDATE_UPLOADED_ROW:
            return {...state, batchUploaded: {
                [action.payload.id]:action.payload.data,
            }};
        case SET_BATCH_GENERATED:
            return {...state, batchGenerated: action.payload};        
        default:
            return state;
    }
}

export default reducer;