import React, {useEffect} from 'react';
import {Layout, Spin} from 'antd';
import logo from '../../static/icons/logo.svg';
import axios from 'axios';
import {history} from '../../store/history';

const Shopify = props => {

    const {Header} = Layout;
    const pid = props.match.params.id;
    const pUid = props.match.params.uuid;

    useEffect(() => {
       processRedirect()
    }, [])


    async function processRedirect() {
        try{

            let response = await axios.get(
                process.env.REACT_APP_API_URL + '/api/shopify/' +pid +'/fetch/' + pUid + '/'
            );

            if(response.data.status == "success"){

                    history.push('/payment/'+response.data.uid);
            }else{
                history.push('/404')
            }


        }catch(error){
        }

    }

    return(
        <div>
             <Header style={{zIndex: '100', position: 'fixed', top: 0, width: '100%', height: '60px', display: 'flex', justifyContent: 'center', backgroundColor: '#fff', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)'}}>
                <img src={logo} style={{marginBottom: '20px', marginTop: '12px'}} alt="#" />
            </Header>

            <div style={{margin: 0,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateY(50%, 50%)'}}>
                <Spin size="large" />
            </div>
        </div>
    )
}

export default Shopify;