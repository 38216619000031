import { getCustomerNow } from "./customerService";

export const GetCustomerActionTypes = {
    GET_CUSTOMERS_REQUEST: "@@GET_CUSTOMERS_REQUEST",
    GET_CUSTOMERS_SUCCESS: "@@GET_CUSTOMERS_SUCCESS",
    GET_CUSTOMERS_FAILED: "@@GET_CUSTOMERS_FAILED"
  };
export function getCustomer() {
  return async (dispatch) => {
    try {
      dispatch({ type: GetCustomerActionTypes.GET_CUSTOMERS_REQUEST });
      let response = await getCustomerNow();
      dispatch({
        type: GetCustomerActionTypes.GET_CUSTOMERS_SUCCESS,
        payload: response.data
      });

    } catch (error) {
      dispatch({
        type: GetCustomerActionTypes.GET_CUSTOMERS_FAILED
      });
      console.log(error.message)
    }
  };
}