import React from 'react'

export default function BayadCenterDisclaimer(props){

    return(
        <div style={{color: '#2B2D32'}}>
            <div>By using Bayad Center, you agree to our <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} target="_blank" href="https://bux.ph/terms">Terms and Conditions.</a></div>
            <div>Support: <a style={{color: '#000000', textDecoration: 'underline', fontWeight: 'bold'}} href="mailto:support@bayad.com">support@bayad.com</a> or via <a style={{color: '#000000', fontWeight: 'bold'}}>+632 8672-5777</a></div> 
        </div>
    )
}