import React from 'react'
import AccountLevelsWeb from './web'
import AccountLevelsMobile from './mobile'
import MobileMain from '../../layouts/Mobile/MobileMain'
import Main from '../../layouts/Main'
import { isMobile } from 'react-device-detect'

const AccountLevels = () => {

    return(
        <div>
            {
                isMobile ?
                <MobileMain title={"Account Levels"}>
                    <AccountLevelsMobile/>
                </MobileMain>
                :
                <Main>
                    <AccountLevelsWeb/>
                </Main>
            }
        </div>
    )
}

export default AccountLevels