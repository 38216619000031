import React from 'react';
import { Icon } from 'antd';
import { isMobile } from 'react-device-detect';
import { backToTop } from '../../components/constants/constants';

const Announcement = props => {

    const {
        icon, header, timeline, image,
        content,
    } = props;

    const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
    const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
    const isDell1 = window.screen.width * window.devicePixelRatio === 1366 && window.screen.height * window.devicePixelRatio === 768;

    const smallReso = isSmallReso || isDell || isDell1
    const segments = localStorage.getItem('segments')

    return(
        <div align="center" 
            style={{
                paddingTop: 
                    isMobile ? "10vh" :
                    smallReso ? "20vh" : "15vh", 
                paddingBottom: 64, 
                background: isMobile && '#e5e5e5'
            }}>
            
            <div className="main-div">
                {
                    !isMobile &&
                    <div align="left">
                        <a href="/dashboard">
                            <Icon type="left" className={`text-${segments} wc-header right-8`}/>
                            <span className={`text-${segments} wc-header`}>
                                Back
                            </span>
                        </a>
                    </div>
                }
                
                <div className={`${isMobile ? "top-32" : "top-64"} annoucement-div`}>
                    {
                        icon &&
                        <div className="announce-icon-div">
                            <img src={icon} alt="annouce" />
                        </div>
                    }
            
                    <div>
                        <div className="wc-header">
                            {header}
                        </div>

                        <div className="wc-text top-12">
                            {timeline === "Invalid date" ? "" : timeline}
                        </div>
                    </div>

                    {
                        image &&
                        <div className="top-32">
                            <img src={image} style={{width: isMobile && 375}} alt="gcash"/>
                        </div>
                    }


                    <div align="left" className="announcement-text-div">
                        {content}
                    </div>
                </div>

                <div className={`top-32`}>
                    <button className={`outline-btn--${segments} btn-height`} 
                        onClick={() => backToTop()}>
                        Back to top
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Announcement;