import React, {useEffect} from 'react'
import logo from '../../static/icons/logo.svg';
import SurveyAsset1 from '../../static/img/survey_asset_1.svg';
import SurveyAsset2 from '../../static/img/survey_asset_2.svg';
import SurveyField from './survey_fields'
import {history} from '../../store/history'
import {Layout} from 'antd'
import {isMobile} from 'react-device-detect'

const SurveyBusinessMain = props =>{
    const {Header} = Layout
    
    useEffect(()=>{
        let current_path = window.location.pathname
        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            history.push('/login?next='+current_path)
        }
        else{
            // if(localStorage.getItem("userType") != "ME"){
            //     history.push('/dashboard')
            // }
            // else {
            //     if(localStorage.getItem("contact") != "" && localStorage.getItem("industry") != ""){
            //         history.push('/dashboard')
            //     }
            // }  
            
        }
    },[])

    function logout() {
        localStorage.setItem("userId", "");
        localStorage.setItem("jwtToken", "");
        localStorage.setItem("firstName", "");
        localStorage.setItem("lastName", "");
        localStorage.setItem("middleName", "");
        localStorage.setItem("suffix", "");
        localStorage.setItem("username", "");
        localStorage.setItem("email", "");
        localStorage.setItem("contact", "");
        localStorage.setItem("userType", "")
        localStorage.setItem("isCompleted", "")
        localStorage.setItem("isVerified", "")
        localStorage.setItem("Step", "")
        localStorage.setItem("Level", "")
        localStorage.setItem("lastLogin", "")
        localStorage.setItem("shipping_enabled", "")
        localStorage.setItem('tradeName', "")
    
        history.push('/login')
    }
    return(
        <div style={{backgroundColor: '#f4f6f9'}}>
            <Header 
                style={{
                    width:'100%', 
                    height: '60px', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    backgroundColor: '#fff', 
                    boxShadow: '0px 3px 5px rgba(57,63,72,0.1)', 
                    position: 'fixed', 
                    zIndex: '1'}
                }>
                <img src={logo} style={{marginBottom: '20px', marginTop: '12px'}} alt="#" onClick={()=>history.push('/login')}/>
                <div style={{position: 'absolute', right: 20}}>
                    <a style={{fontSize: '16px', color: '#000'}} onClick={()=>logout()}>Logout</a>
                </div>
            </Header>
            <img src={SurveyAsset1} style={{position: 'fixed', bottom:'0', marginLeft: '20px'}} hidden={isMobile}/>

            <SurveyField/>
            <img src={SurveyAsset2} style={{position: 'fixed', bottom:'0', right: '0'}} hidden={isMobile}/>
            
        </div>
    )
}

export default SurveyBusinessMain