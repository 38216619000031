import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Icon, Dropdown, Badge, Menu, Divider, Alert} from 'antd';
import * as Icons from '../../../static/images/home/index';
import { history } from '../../../store/history';
import {me, gig, sme, biz } from '../../../static/images/home/index';
import {FAQ, Terms, How, Pricing, Logout, AccountLevels} from '../../../static/icons/options/index'
import NotificationDropdown from '../../../components/notification/header_dropdown';
import notificationServices from '../../../components/notification/notificationServices';
import notificationActions from '../../../components/notification/notificationActions';
import { NotifBell } from '../../../static/icons/notification_icons';
import { useDispatch, useSelector } from "react-redux";
import * as Style from '../../../layouts/AppBar/appBarComponent/appBarFormStyle'
import profile from "../../../static/icons/profile.svg";
import w_profile from "../../../static/icons/white_profile.svg";
import dropdown_icon from '../../../static/icons/dropdown_icon.svg';
import PayoutDrawer from '../../payout_drawer/payout_drawer';

const MobileAppBar = props => {

    const {
        segments, testMode, logout, pathname_list, pathname
    } = props;

    const notifCount = useSelector(state => state.notification.notificationCount)
    const dispatch = useDispatch();
    const has_merchant_bias = localStorage.getItem("merchant_bias") === "true";
    const userType = localStorage.getItem('userType')
    const disablePaymentLink = userType == "CO" && localStorage.getItem("Level") != 5 && !testMode || localStorage.getItem("userType") == "CO" && localStorage.getItem("KYBStep") == 10 && !testMode;
    const isAlias = localStorage.getItem("is_child") === "true";

    const [canAliasCreatePaymentLink, setCanAliasCreatePaymentLink] = useState(true);
    
    let logo = segments == 'me_' ? me : segments == 'gig' ? gig :
                segments == 'sme' ? sme : segments == 'biz' ? biz : Icons.WebBux;
    
    let profilePicture = localStorage.getItem("profilePicture")
    
    const menu = (
        <Menu  style={{width: '180px', marginRight:'10px', padding: '5px'}}>
            <Menu.Item style={Style.menuStyle} onClick={ () => window.location.href = 'https://faq.bux.ph/hc/en-us'}>
            <img src={FAQ} alt="FAQ" style={{marginRight: '4px'}}/> FAQ
            </Menu.Item>
            <Menu.Item style={Style.menuStyle} onClick={ () => history.push('/terms_and_privacy')}>
            <img src={Terms} alt="Terms Icon" style={{marginRight: '4px'}}/> Terms and Policy
            </Menu.Item>
            <Menu.Item style={Style.menuStyle} onClick={ () => history.push('/account_levels')}>
            <img src={AccountLevels} alt="Account Levels Icon" style={{marginRight: '4px'}}/> Account Levels
            </Menu.Item>
            <Menu.Item style={Style.menuStyle} hidden={userType === "SW"} 
            // onClick={()=>{
            //     dispatch(tutorialActions.setStartDemo(true));
            //     dispatch(tutorialActions.setTutorialStartModal(true));}
            //     }
            onClick={() => history.push('/how_it_works#1')}
            >
            <img alt="How Icon" src={How} style={{marginRight: '4px'}}/> How it works?
            </Menu.Item>
            <Menu.Item style={Style.menuStyle} onClick={ () => history.push('/pricing')}>
            <img src={Pricing} alt="Pricing Icon" style={{marginRight: '4px'}}/> Pricing Table
            </Menu.Item>
            <Divider style={{margin: '10px 0px 5px 0px'}}/>
            <Menu.Item style={Style.menuStyle} onClick={ () => logout()}>
            <img src={Logout} alt="Logout Icon" style={{marginRight: '8px'}}/>Logout
            </Menu.Item>
        </Menu>
    );

    const getNotifCount = async (params) => {
        try {
         const response = await notificationServices.getNotifications(params);
         
          dispatch(notificationActions.setNotificationCount(response))

        } catch (error) {
          return(
            <Alert severity="error">{error}</Alert>
          )
        }
    }

    async function genericAxiosRequest(http_method, target_url, params=undefined) {
        try {
            let response = await axios({
                method: http_method,
                url: process.env.REACT_APP_API_URL + target_url,
                data: params,
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            });
            return response.data;
    
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    logout();
                } else {
                    throw new Error(error.response.data.message);
                }
            } else {
                throw new Error('No response from backend server!');
            }
        }
    }

    async function checkCanAliasCreatePaymentLink() {
        const setting_name = 'payment_link_creation'
        const endpoint_url = `/api/enterprise/alias/settings/?name=${setting_name}`;
  
        try {
            let response = await genericAxiosRequest('get', endpoint_url);
            if (response.status === 'failed') {
                throw new Error(response.message);
            }
            setCanAliasCreatePaymentLink(response.message);
  
        } catch (err) {
            // do nothing
        }
    }

    useEffect(() => {
        getNotifCount({target: 'count'});
        
        if (isAlias) {
          checkCanAliasCreatePaymentLink();
        }
    }, []);

    return (
        <div style={{padding: '0 12px'}}>
            <div className={"headerContent"}
                hidden={!pathname_list.includes(pathname)}
                >
                <img 
                    src={logo} 
                    style={{width: 71}}
                    alt="BUx Logo" 
                    onClick={ 
                        () => 
                        userType === 'AD' || 
                        userType === "OP" ? history.push('/orders') : 
                        history.push('/dashboard') } />

                <div style={{ display: 'flex', right: 0}}>
                    <div>
                        <a onClick={() => !testMode && history.push('/my_profile')}>
                            {
                                profilePicture ?
                                    <img src={profilePicture} style={Style.profilePic} className="right-8" alt="profile" /> :
                                    <img src={testMode ? w_profile : profile} style={{ width: 28 }} className="right-8" alt="profile" />
                            }
                        </a>
                        {/* <img src={testMode ? w_profile : profile} style={{marginRight: 12, width: 28}} alt="profile" /> */}

                        {/* <button
                            onClick={() => history.push("/mobile/generate_code")}
                            disabled={disablePaymentLink}
                            className={`btn--${segments}`}
                            style={{
                                width: 150,
                                cursor: disablePaymentLink ? 'not-allowed' : 'pointer',
                                opacity:  disablePaymentLink ? '0.5' : 1,
                              }}
                            hidden={!canAliasCreatePaymentLink || has_merchant_bias || userType == 'SW'}
                        >
                            Generate Link
                        </button> */}
                    </div>

                    <div style={{ display:'flex', paddingRight: 20}}>
                        <div>
                            <Dropdown 
                                getPopupContainer={triggerNode => triggerNode.parentNode } 
                                overlay={<NotificationDropdown />} 
                                placement="bottomCenter" 
                                trigger={['click']} >
                                <Badge count={notifCount}>
                                {/* <Icon type="bell" style={{fontSize: '18px'}}/> */}
                                <a href><img src={NotifBell} alt="Bell" /></a>
                                </Badge>
                            </Dropdown>
                        </div>

                        <Dropdown
                            getPopupContainer={triggerNode => triggerNode.parentNode }
                            overlay={menu}
                            placement="bottomCenter"
                            trigger={["click"]}
                        >
                            <img src={dropdown_icon} alt="#" className="caret-img" 
                            style={{ margin: "16px 0 0px 4px" }}
                            />
                        </Dropdown>
                    </div>
                </div>
                <PayoutDrawer />    
            </div>
        </div>
    )
}

export default MobileAppBar;
