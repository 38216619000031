import React from 'react';
import axios from 'axios';
import {Card, Button, message} from 'antd';
import * as Style from './kyc_style';
import GreatJob from '../../../static/img/great_job.svg';
import {isMobile} from 'react-device-detect'
import {submitDetails} from './application_main'

const GreatJobCard = props =>{

    const [loading,setLoading] = React.useState(false)
    async function allGoodSubmit(){
        let details = {
            step: 6
        }
        setLoading(true)

        let response = await submitDetails(details)
        if(response){
            setLoading(false)
        }
    }

    async function fakeSubmit() {
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try {

            let params = {
                user: localStorage.getItem('email')
            }

            let response = await axios.patch(
                process.env.REACT_APP_API_URL + '/api/sandbox/kyc/fake_approve/',params, yourConfig
            )

            if(response.data.status === "success") {
                message.success(response.data.message, 3)
                window.location.href = '/test/bux_wallet'
            }
        }

        catch (error) {
            console.log(error.response)
        }
    }

    const {
        testMode,
        dataFilled,
        step,
        details,
    } = props

    return(
        <Card 
            hidden={(testMode && (!dataFilled)) || (!testMode && step != 6 || details.step == 9)} 
            style={Style.greatJobCard}
            bodyStyle={{padding: '32px'}}>

            <div style={{width: '100%'}}>
                <div style={Style.header2Style}>Submit your application</div>
                <div style={{...Style.normalText, color: '#000', marginTop: isMobile ? '10px' : '0px'}}>Please click on the <b>SUBMIT</b> button to complete your application.</div>
                <div align="right">
                    <Button style={Style.allGoodBtn} hidden={isMobile} onClick={()=> testMode ? fakeSubmit() : allGoodSubmit()} loading={loading}>Submit</Button>
                </div>
            </div>
            
        </Card>
    )
}

export default GreatJobCard