import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button, DatePicker, Input, Icon, Table, Row, Col, Checkbox, Popover, Divider, message} from 'antd'
import {history} from '../../../store/history'
import { getLedger } from './ledgerAction';
import moment from 'moment';
import '../../../static/css/antd.css'
import { ExportToCsv } from 'export-to-csv';
import ArrowDown from '../../../static/icons/arrow-down.svg'
import {CARD_PAYMENT, OTC, BANK, MOBILE, randomize} from '../../constants/constants'
import axios from "axios";
import Pagination from '@material-ui/lab/Pagination';
import ExportCsvModal from '../payout/exportCsvModal';

const ButtonGroup = Button.Group;
const CheckboxGroup = Checkbox.Group;

const mapStateToProps = (state) => {
    const { ledger, loading, summary, error } = state.ledger;
    return {
        ledger,
        summary,
        loading,
        error,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getLedger: bindActionCreators(getLedger, dispatch),
    };
};

class Ledger extends React.Component{

    componentDidMount(){
        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            history.push('/login')
        }
        else{
            if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
                history.push('/dashboard')
            }
            else if(localStorage.getItem("userType") == "PS"){
                history.push('/orders')
            }
            else if(localStorage.getItem("userType") == "PF"){
                history.push('/recon/7_eleven')
            }
            else if(localStorage.getItem("userType") == "MA"){
                history.push('/overview')
            }
            else{
                this.props.getLedger('', this.state.title, this.state.startDate, this.state.endDate);
            }
        }
    }

    state = {
        title : 'All',
        startDate: '',
        startMoment: '',
        endDate: '',
        endMoment: '',
        endOpen: '',
        searchValue: '',
        otcCheckedList: [],
        webCheckedList: [],
        walletCheckedList: [],
        cardCheckedList: [],
        areAllOTCChecked: false,
        areAllWebChecked: false,
        areAllWalletChecked: false,
        areAllCardChecked: false,
        emailReceipt: '',
        currentPage: 1,
        modalState: false,
    }

    status = [
        'All', 'Accounted', 'Unaccounted'
    ]

    handleOpenModal = () => {
        this.setState({
            modalState: true
        })
    }

    handleCloseModal = () => {
        this.setState({
            modalState: false
        })
    }


    changePage = (event,page) => {
        this.setState({
        currentPage: page
        })
        const allKeys = this.getAllKeys(this.state.otcCheckedList, this.state.webCheckedList, this.state.walletCheckedList, this.state.cardCheckedList);
        this.props.getLedger(this.state.searchValue, this.state.title, this.state.startDate, this.state.endDate, allKeys, page);
    }

    disabledStartDate = startValue => {
        var self = this
        if (!startValue || !self.state.endMoment) {
            return false;
        }
        return startValue && startValue > moment().endOf('day');
    };

    disabledEndDate = endValue => {
        var self = this
        if (!endValue || !self.state.startMoment) {
            return false;
        }
        return endValue.valueOf() <= self.state.startMoment.valueOf();
    };
    
    onStartChange = value => {
        var self = this;

        if(value != null && self.state.endDate != ""){
            self.setState({
                startDate: value.format("MM-DD-YYYY"),
                startMoment: value
            })
             this.setState({
              currentPage: 1
            })
            const allKeys = this.getAllKeys(this.state.otcCheckedList, this.state.webCheckedList, this.state.walletCheckedList, this.state.cardCheckedList);
            this.props.getLedger(this.state.searchValue, self.state.title, value.format("MM-DD-YYYY"), self.state.endDate, allKeys);
        }
        else if(value != null){
            self.setState({
                startDate: value.format("MM-DD-YYYY"),
                startMoment: value
            })
        }
        else{
            self.setState({
                startDate: "",
                startMoment: null
            })
        }
    }
    onEndChange = value => {
        var self = this
        if(value != null && self.state.startDate != ""){
            self.setState({
                endDate: value.format("MM-DD-YYYY"),
                endMoment: value
            })

             this.setState({
              currentPage: 1
            })
            const allKeys = this.getAllKeys(this.state.otcCheckedList, this.state.webCheckedList, this.state.walletCheckedList, this.state.cardCheckedList);
            this.props.getLedger(this.state.searchValue, self.state.title, self.state.startDate, value.format("MM-DD-YYYY"), allKeys);
        }
        else{
            self.setState({
                endDate: "",
                endMoment: null
            })

             this.setState({
              currentPage: 1
            })
            const allKeys = this.getAllKeys(this.state.otcCheckedList, this.state.webCheckedList, this.state.walletCheckedList, this.state.cardCheckedList);
            this.props.getLedger(this.state.searchValue, self.state.title, "","", allKeys);
        }
    }
    handleStartOpenChange = open => {
        if (!open) {
            this.setState({
                endOpen: true
            })
        }
    };

    handleEndOpenChange = open => {
        this.setState({
            endOpen: open
        })
    };

    renderColumns(val){
        let { ledger } = this.props;
        ledger.forEach((row, index) => row['key'] = index)
        if((val=="")||(val==null))
            return ledger
        let filtered_ledger = ledger.filter(ledgr => ledgr.ref_code.toLowerCase().includes(val.toLowerCase()) ||
                                                     ledgr.channel.toLowerCase().includes(val.toLowerCase()))
        return filtered_ledger
    }

    isPaymentMethodFilterActive = () => {
        return this.state.otcCheckedList.length
               || this.state.webCheckedList.length
               || this.state.walletCheckedList.length 
               || this.state.cardCheckedList.length;
    }

    getAllKeys = (otcList, webList, walletList, cardList) => {
        let all_otc = otcList.map(item => OTC[item].code);
        let all_web = webList.map(item => BANK[item].code);
        let all_wallet = walletList.map(item => MOBILE[item].code);
        let all_card = cardList.map(item => CARD_PAYMENT[item].code);

        return [...all_otc, ...all_web, ...all_wallet, ...all_card];
    }

    onChangeOtc = list => {
        this.setState({
            otcCheckedList: list,
            areAllOTCChecked: list.length === Object.keys(OTC).length,
            currentPage: 1
        })
        const allKeys = this.getAllKeys(list, this.state.webCheckedList, this.state.walletCheckedList, this.state.cardCheckedList);
        this.props.getLedger(this.state.searchValue, this.state.title, this.state.startDate, this.state.endDate, allKeys);
    };

    onChangeWeb = list => {
        this.setState({
            webCheckedList: list,
            areAllWebChecked: list.length === Object.keys(BANK).length,
            currentPage: 1
        })
        const allKeys = this.getAllKeys(this.state.otcCheckedList, list, this.state.walletCheckedList, this.state.cardCheckedList);
        this.props.getLedger(this.state.searchValue, this.state.title, this.state.startDate, this.state.endDate, allKeys);
    }

    onChangeWallet = list => {
        this.setState({
            walletCheckedList: list,
            areAllWalletChecked: list.length === Object.keys(MOBILE).length,
            currentPage: 1
        })
        const allKeys = this.getAllKeys(this.state.otcCheckedList, this.state.webCheckedList, list, this.state.cardCheckedList);
        this.props.getLedger(this.state.searchValue, this.state.title, this.state.startDate, this.state.endDate, allKeys);
    }

    onChangeCard = list => {
        this.setState({
            cardCheckedList: list,
            areAllCardChecked: list.length === Object.keys(CARD_PAYMENT).length,
            currentPage: 1
        })
        const allKeys = this.getAllKeys(this.state.otcCheckedList, this.state.webCheckedList, this.state.walletCheckedList, list);
        this.props.getLedger(this.state.searchValue, this.state.title, this.state.startDate, this.state.endDate, allKeys);
    }

    onCheckAllOtcChange = e => {
        let new_list = e.target.checked ? Object.keys(OTC) : []
        this.setState({
            otcCheckedList: new_list,
            areAllOTCChecked: e.target.checked,
            currentPage: 1
        })
        const allKeys = this.getAllKeys(new_list, this.state.webCheckedList, this.state.walletCheckedList, this.state.cardCheckedList);
        this.props.getLedger(this.state.searchValue, this.state.title, this.state.startDate, this.state.endDate, allKeys);
    };

    onCheckAllWebChange = e => {
        let new_list = e.target.checked ? Object.keys(BANK) : []
        this.setState({
            webCheckedList: new_list,
            areAllWebChecked: e.target.checked,
            currentPage: 1
        })
        const allKeys = this.getAllKeys(this.state.otcCheckedList, new_list, this.state.walletCheckedList, this.state.cardCheckedList);
        this.props.getLedger(this.state.searchValue, this.state.title, this.state.startDate, this.state.endDate, allKeys);
    }

    onCheckAllWalletChange = e => {
        let new_list = e.target.checked ? Object.keys(MOBILE) : []
        this.setState({
            walletCheckedList: new_list,
            areAllWalletChecked: e.target.checked,
            currentPage: 1
        })
        const allKeys = this.getAllKeys(this.state.otcCheckedList, this.state.webCheckedList, new_list, this.state.cardCheckedList);
        this.props.getLedger(this.state.searchValue, this.state.title, this.state.startDate, this.state.endDate, allKeys);
    }

    onCheckAllCardChange = e => {
        let new_list = e.target.checked ? Object.keys(CARD_PAYMENT) : []
        this.setState({
            cardCheckedList: new_list,
            areAllCardChecked: e.target.checked,
            currentPage: 1
        })
        const allKeys = this.getAllKeys(this.state.otcCheckedList, this.state.webCheckedList, this.state.walletCheckedList, new_list);
        this.props.getLedger(this.state.searchValue, this.state.title, this.state.startDate, this.state.endDate, allKeys);
    }

    payment_methods = () => (
        <div style={{ width: '205px', backgroundColor: '#fff', height: '200px', overflowY: 'scroll'}} >
            <Row>
                <Col>
                    <Checkbox
                        style={{margin:'0px 0px 5px 0px', fontWeight: '600'}}
                        indeterminate={this.state.otcCheckedList.length && this.state.otcCheckedList.length < Object.keys(OTC).length}
                        checked={this.state.areAllOTCChecked}
                        onChange={(e)=>this.onCheckAllOtcChange(e)}
                        key="All Over-the-counter"
                    >
                        Over-the-counter
                    </Checkbox>
                </Col> 
                <Col>
                    <CheckboxGroup
                        style={{margin:'0px 0px 0px 24px'}}
                        options={Object.keys(OTC)}
                        value={this.state.otcCheckedList}
                        onChange={(e)=>this.onChangeOtc(e)}
                />
                </Col>
            </Row>
            <Divider style={{margin: "10px 0px 5px 0px"}}/>
            <Row>
                <Col>
                    <Checkbox
                        style={{margin:'0px 0px 5px 0px', fontWeight: '600'}}
                        indeterminate={this.state.webCheckedList.length && this.state.webCheckedList.length < Object.keys(BANK).length}
                        checked={this.state.areAllWebChecked}
                        onChange={(e)=>this.onCheckAllWebChange(e)}
                        key="All Web Banking"
                    >
                        Web Banking
                    </Checkbox>
                    <CheckboxGroup
                        style={{margin:'0px 0px 0px 24px'}}
                        options={Object.keys(BANK)}
                        value={this.state.webCheckedList}
                        onChange={(e)=>this.onChangeWeb(e)} 
                    />
                </Col>
            </Row>
            <Divider style={{margin: "10px 0px 5px 0px"}}/>
            <Row>
                <Col>
                    <Checkbox
                        style={{margin:'0px 0px 5px 0px', fontWeight: '600'}}
                        indeterminate={this.state.walletCheckedList.length && this.state.walletCheckedList.length < Object.keys(MOBILE).length}
                        checked={this.state.areAllWalletChecked}
                        onChange={(e)=>this.onCheckAllWalletChange(e)}
                        key="All E-Wallet"
                    >
                        E-Wallet
                    </Checkbox>
                </Col> 
                <Col>
                    <CheckboxGroup
                        style={{margin:'0px 0px 0px 24px'}}
                        options={Object.keys(MOBILE)}
                        value={this.state.walletCheckedList}
                        onChange={(e)=>this.onChangeWallet(e)}
                    />
                </Col>
            </Row>
            <Divider style={{margin: "10px 0px 5px 0px"}}/>
            <Row>
                <Col>
                    <Checkbox
                        style={{margin:'0px 0px 5px 0px', fontWeight: '600'}}
                        indeterminate={this.state.cardCheckedList.length && this.state.cardCheckedList.length < Object.keys(CARD_PAYMENT).length}
                        checked={this.state.areAllCardChecked}
                        onChange={(e)=>this.onCheckAllCardChange(e)}
                        key="All Card"
                    >
                        Card Payment
                    </Checkbox>
                </Col> 
                <Col>
                    <CheckboxGroup
                        style={{margin:'0px 0px 0px 24px'}}
                        options={Object.keys(CARD_PAYMENT)}
                        value={this.state.cardCheckedList}
                        onChange={(e)=>this.onChangeCard(e)}
                    />
                </Col>
            </Row>
        </div> 
    )

    columns = [
        {
            title: 'Ref. Code',
            dataIndex: 'ref_code',
            render: text => {
                if(text === "Total" || text === "Individual" || text === "Corporation") return <b>{text}</b>
                else return text
            }
        },
        {
            title: 'Total Amount',
            dataIndex: 'amount',
        },
        {
            title: 'Partner Fee',
            dataIndex: 'total_fee',
        },
        {
            title: 'BUx Fee',
            dataIndex: 'bux_fee',
        },
        {
            title: 'Other Fee',
            dataIndex: 'other_fee',
        },
        {
            title: 'Merchant Net',
            dataIndex: 'seller_net',
            width: '15%'
        },
        {
            title: 'Channel',
            dataIndex: 'channel'
        },
        {
            title: 'Date Paid',
            dataIndex: 'date_paid',
        },
        {
            title: 'Done',
            dataIndex: 'accounted',
            render: text => {
                if(text == "2")
                    return " "
                else if(text == "1")
                    return <span style={style.doneStyle}>Yes</span>
                else
                    return <span style={style.pendingStyle}>No</span>
            }
        },
    ];

    exportTable = async() => {    
        // let options = { 
        //     filename: 'Ledger_'+moment().format("MM-DD-YYYY_HH:mm:ss")+'_'+this.state.title,
        //     fieldSeparator: ',',
        //     quoteStrings: '"',
        //     decimalSeparator: '.',
        //     showLabels: true, 
        //     showTitle: true,
        //     title: 'BUx Ledger',
        //     useTextFile: false,
        //     useBom: true,
        //     useKeysAsHeaders: true
        // };
            
        // let csvExporter = new ExportToCsv(options);

        // if(this.renderColumns(this.state.searchValue).length > 1){
        //         csvExporter.generateCsv(this.renderColumns(this.state.searchValue));
        // }

      const yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
       }
      let allKeys = this.getAllKeys(this.state.otcCheckedList, this.state.webCheckedList, this.state.walletCheckedList, this.state.cardCheckedList);
      let response = await axios.get(process.env.REACT_APP_API_URL+'/api/ledger/?export=True&type='+this.state.title+'&start='+this.state.startDate+'&end='+this.state.endDate+'&email='+this.state.emailReceipt+'&payment_method='+allKeys, 
                yourConfig);
      if(response.data.status=='success'){
        
        message.success(response.data.message)
        this.handleCloseModal()
      }else{
        message.error(response.data.message)
      }
    };
    render(){
        return(
            <div style={{ paddingTop: '38px', paddingLeft: '42px', paddingRight: '42px' }}>
                <div style={style.headerStyle}>Ledger</div>
                <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                    <ButtonGroup>
                        {
                            this.status.map((item,i) => {
                                return <Button
                                    key={item.toLocaleLowerCase()}
                                    onClick={() => {
                                        this.setState({
                                            title: item
                                        });
                                        this.props.getLedger(this.state.searchValue, item, this.state.startDate, this.state.endDate,
                                            this.getAllKeys(this.state.otcCheckedList, this.state.webCheckedList, this.state.walletCheckedList, this.state.cardCheckedList))
                                    }}
                                    style={{
                                        padding: '12px 18px 13px 18px',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        fontSize: '12px',
                                        lineHeight:'16px',
                                        height:'40px',
                                        borderTopLeftRadius: i === 0 ? '10px' : '0px',
                                        borderBottomLeftRadius: i === 0 ? '10px' : '0px',
                                        borderTopRightRadius: i === this.status.length - 1 ? '10px' : '0px',
                                        borderBottomRightRadius:i === this.status.length - 1 ? '10px' : '0px',
                                        color: this.state.title === item ? '#ffffff' : '#2b2d33',
                                        backgroundColor: this.state.title === item ? '#0D3D76' : '#fff',
                                    }}>{item}</Button>
                            })
                        }
                    </ButtonGroup>
                    <div>

                        <Button 
                            className="admin-download-btn"
                            onClick={() => this.handleOpenModal()}>
                            <Icon type='download' />
                            Download CSV             
                        </Button>

                    {/* <Input
                      placeholder="Email"
                      style={{ width: '150px', height: '40px',marginRight: '10px' }}
                      onChange={(e)=> {
                        this.setState({emailReceipt: e.target.value});  } }
                    /> 
                    <Button
                            style={{
                                backgroundColor: '#1DD28B',
                                border: '#1DD28B',
                                height: '40px',
                                borderRadius:'10px',
                            }}
                            onClick={()=>this.exportTable()}>
                        
                        <div style={{fontSize: '14px', fontWeight:'600', color: '#fff'}}>Export CSV</div>
                    </Button> */}
                    </div>
                </div>
                <div style={{ marginBottom: '25px', display:'flex', float: 'right' }}>
                    <Popover content={this.payment_methods()} trigger="click" placement="bottomLeft">
                        <Button 
                            style={{
                                zIndex: 1, 
                                height: '40px', 
                                width: '172px',
                                paddingTop: '8px', 
                                marginRight: '12px',
                                display: 'flex',
                                justifyContent:'space-between',
                                color:  this.isPaymentMethodFilterActive() ? '#0D3D76' : '#000',
                                border: this.isPaymentMethodFilterActive() ? '1px solid #0D3D76' : '1px solid #c5cacf'
                            }}>
                                <div>Payment Method</div> 
                                <img
                                    alt='Arrow Down' 
                                    src={ArrowDown} 
                                    style={{
                                        marginLeft: '5px', 
                                        color: this.isPaymentMethodFilterActive() ? '#1DD28B' : '#000'
                                    }}/>
                        </Button>
                    </Popover>
                    <DatePicker
                        style={{zIndex: 1, width: '200px'}}
                        disabledDate={this.disabledStartDate}
                        size="large"
                        format='MM-DD-YYYY'
                        value={this.state.startMoment ? this.state.startMoment : null}
                        placeholder="Start"
                        onChange={this.onStartChange}
                        onOpenChange={this.handleStartOpenChange}
                    />
                    <span style={{padding: '10px 8px 0px 8px'}}>-</span> 
                    <DatePicker
                        style={{zIndex: 1, marginRight: '12px',width: '200px'}}
                        disabledDate={this.disabledEndDate}
                        size="large"
                        format='MM-DD-YYYY'
                        value={this.state.endMoment ? this.state.endMoment : null}
                        placeholder="End"
                        onChange={this.onEndChange}
                        onOpenChange={this.handleEndOpenChange}
                    />
                    <Input
                            placeholder="Search"
                            style={{ width: '200px', height: '40px', zIndex: 1}}
                            onChange={(e)=>{this.setState({
                                searchValue: e.target.value
                            });
                            const allKeys = this.getAllKeys(this.state.otcCheckedList, this.state.webCheckedList, this.state.walletCheckedList, this.state.cardCheckedList);
                            this.props.getLedger(e.target.value, this.state.title, this.state.startDate, this.state.endDate, allKeys);

                            }}
                            prefix={<Icon type="search" />}
                    />
                </div>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                    columns={this.columns}
                    dataSource={this.props.ledger ? this.props.ledger : []}
                    pagination={false} 
                    rowKey={(record) => record.ref_code}
                />
              {
                this.props.summary && (
                <div style={{padding: '20px', display:'flex', justifyContent:'space-between'}} >
                  <Pagination 
                    count={this.props.summary.pages} 
                    shape="rounded"
                    page={this.state.currentPage} 
                    size="small"
                    onChange={this.changePage}
                  /> 
                </div>
                )}
                <ExportCsvModal visible={this.state.modalState} onClose={this.handleCloseModal} onSend={this.exportTable}
                    onChange={(e) => this.setState({emailReceipt: e})} />
            </div>
        )
    }
}

const style = {
    headerStyle: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '40px'
    },
    
    doneStyle: {
        color: '#50C878'
    },

    pendingStyle: {
        color: '#DE1738'
    },
}

export default connect(mapStateToProps, mapDispatchToProps)(Ledger);