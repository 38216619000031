import React, { useState } from "react";
import { Card, Typography, Modal } from "antd";

const { Title, Text } = Typography;

export function SubmissionDetails({ data, isLoading, isError }) {
  console.log(data);
  const [isImagePreviewVisible, setIsImagePreviewVisible] = useState(false);

  if (isError) {
    return <div>Error</div>;
  }

  if (isLoading) {
    return <div>Loading</div>;
  }

  return (
    <Card
      title="Submission Details"
      loading={isLoading}
      style={{ width: "500px" }}
    >
      {data && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Business Name:</Title>
            <Text type="secondary">{data.business_name}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Requestor Name:</Title>
            <Text type="secondary">{data.requestor_details.full_name}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Requestor Email:</Title>
            <Text type="secondary">{data.requestor_details.email}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Account Name:</Title>
            <Text type="secondary">{data.account_name}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Account Number:</Title>
            <Text type="secondary">{data.account_number}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Account Type:</Title>
            <Text type="secondary">{data.account_type}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Bank:</Title>
            <Text type="secondary">{data.bank}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Bank Branch:</Title>
            <Text type="secondary">{data.branch}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Proof of Ownership:</Title>
            <Card
              style={{ cursor: "pointer" }}
              onClick={() => setIsImagePreviewVisible(true)}
            >
              <img
                src={data.proof_of_ownership}
                alt="Proof of Ownership document"
                style={{ width: "100px" }}
              />
            </Card>
          </div>
          <ProofOfOwnershipPreview
            url={data.proof_of_ownership}
            isImagePreviewVisible={isImagePreviewVisible}
            setIsImagePreviewVisible={setIsImagePreviewVisible}
          />
        </>
      )}
    </Card>
  );
}

function ProofOfOwnershipPreview({
  url,
  isImagePreviewVisible = false,
  setIsImagePreviewVisible,
}) {
  return (
    <Modal
      title="Image Preview"
      visible={isImagePreviewVisible}
      onCancel={() => setIsImagePreviewVisible(false)}
      footer={null}
    >
      <img
        src={url}
        alt="Proof of Ownership preview"
        style={{ width: "100%" }}
      />
    </Modal>
  );
}
