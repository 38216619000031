import React from 'react'
import { Drawer, Icon, Spin, message } from 'antd'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BUx } from '../../static/icons/bux_qr'
import StaticQRPHStyles from './styles.module.css'
import { StaticQRPHServices } from './services';
import axios from 'axios'

const StaticQRPHDrawer = props => {
    const segments = localStorage.getItem("segments")

    const [staticQRPHImageURL, setStaticQRPHImageURL] = React.useState("");
    const [qrphImageIsRendering, setQRPHImageIsRendering] = React.useState(false);
    const [qrphImageOpacity, setQRPHImageOpacity] = React.useState(0);
    const [staticQRPHGetSuccess, setStaticQRPHGetSuccess] = React.useState(false);
    const [isDownloading, setIsDownloading] = React.useState(false);
    const [displayedName, setDisplayedName] = React.useState("");
    const [segmentColor, setSegmentColor] = React.useState("");
    
    const {
        visible, 
        setShowDrawer, 
        tradeName, 
    } = props

    const segmentsColor = {
        'me_':{
            color: '#0DAED0',
        },
        'gig':{
            color: '#41BD5C',
        },
        'sme':{
            color: '#E3632A',
        },
        'biz':{
            color: '#0051B0',
        },
    }

    const getSegmentColor = () => {
        try {
            setSegmentColor(segmentsColor[segments].color);
        } catch (error) {
            setSegmentColor("");
        }
    }

    const getStaticQRPH = async () => {
        setQRPHImageIsRendering(true);
        setQRPHImageOpacity(0);
        try {
            const { imageUrl, displayedName } = await StaticQRPHServices.getStaticQRPHApi();
            setStaticQRPHImageURL(imageUrl);
            setDisplayedName(displayedName);
            setStaticQRPHGetSuccess(true);
        }
        catch (error) {
            setQRPHImageIsRendering(false);
            setStaticQRPHGetSuccess(false);
        }
    }

    const saveStaticQRPHBuxImage = async () => {
        setIsDownloading(true);
        try {
            const buxImageUrl = await StaticQRPHServices.saveStaticQRPHBuxImageApi();
            const buxImageUrlResponse = await axios.get(
                buxImageUrl, 
                { 
                    responseType: 'blob',
                },
            );
            const buxImageFile = new Blob(
                [
                    buxImageUrlResponse.data,
                ],
                { type: "image/*" }
            );
            
            const link = document.createElement('a');
            link.href = URL.createObjectURL(buxImageFile);
            link.download = `${tradeName}-qr-code-${Date.now()}.png`;
            document.body.appendChild(link); 
            link.click(); 
            document.body.removeChild(link); 
        }
        catch (error) {
            message.error("Failed to download image.");
        }
        setIsDownloading(false);
    }

    const handleStaticQRPHOnLoad = () => {
        setQRPHImageIsRendering(false);
        setQRPHImageOpacity(1);
    }

    React.useEffect(() => {
        if (visible) {
            getSegmentColor();
            getStaticQRPH();
        }
    }, [visible]);

    return(
        <Drawer
            className="static-qrph-drawer"
            placement="right"
            closable={false}
            visible={visible}
            maskClosable={true}
            bodyStyle={{ display: "flex" }}
          >
            <div className={StaticQRPHStyles.drawerBodyContent}>
                <div className={StaticQRPHStyles.headers}>
                    <div 
                        id="top" 
                        className={StaticQRPHStyles.titleStyle}
                    >
                        My QR Code
                    </div>
                    <ExpandMoreIcon 
                        className={`text-${segments} ${StaticQRPHStyles.closeButtonMobile}`} 
                        onClick={()=>{
                            setShowDrawer(false);
                            setQRPHImageOpacity(0);
                        }} 
                    />
                    <div className={StaticQRPHStyles.closeButton}>
                        <Icon 
                            type='close' 
                            onClick={()=>{
                                setShowDrawer(false);
                                setQRPHImageOpacity(0);
                            }} 
                            style={{
                                fontSize: '20px', 
                                color: segmentColor,
                            }}
                        />
                    </div>
                </div>

                <div className={StaticQRPHStyles.headerDivider}>
                </div> 
                
                <div 
                    className={StaticQRPHStyles.bodyLoading}
                    hidden={!qrphImageIsRendering}
                >
                    <Spin 
                        size='large'
                        className={StaticQRPHStyles.spinner} 
                    />
                    <div
                        style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "#2B2D33",
                        }}
                    >
                        Loading QR image...
                    </div>
                </div>

                <div 
                    className={StaticQRPHStyles.bodyContentFailed}
                    hidden={qrphImageIsRendering || staticQRPHGetSuccess}
                >
                    <div
                        style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "#2B2D33",
                        }}
                    >
                        Something went wrong.
                    </div>
                    <div>
                        Reload the page and try again.
                    </div>
                </div>

                <div 
                    className={StaticQRPHStyles.bodyContent}
                    style={{
                        opacity: qrphImageOpacity,
                    }}
                    hidden={!visible || !staticQRPHGetSuccess}
                >
                    <div className={StaticQRPHStyles.buxImage}>
                        <img 
                            src={BUx}
                            className={StaticQRPHStyles.buxLogo}
                            alt="BUx logo"
                        />

                        <img
                            className={StaticQRPHStyles.qrphImage}
                            src={staticQRPHImageURL}
                            onLoad={handleStaticQRPHOnLoad}
                            alt="QRPH static QR code"
                        />

                        <div className={StaticQRPHStyles.tradeName}>
                            {displayedName}
                        </div>
                    </div>

                    <button 
                        className={`btn--${segments} btn-height`}
                        style={{
                            borderRadius: "4px",
                            width: "95%"
                        }}
                        onClick={saveStaticQRPHBuxImage}
                        disabled={isDownloading}
                    >
                        <div hidden={!isDownloading}>
                            <Spin className={StaticQRPHStyles.spinner} />
                        </div>
                        <div hidden={isDownloading}>
                            Download QR
                        </div>
                    </button>
                </div>
            </div>
        </Drawer>     
    )
}

export default StaticQRPHDrawer;