import React from 'react';
import MetaTag from '../../meta_tag/meta_tag';
import { Button, Typography, Icon, DatePicker, Input, message, Select, Popover, Checkbox, Row, Drawer, Divider, Col } from 'antd';
import { OTC_PROMO, OTC_LIST_PROMO } from '../../constants/constants';
import Table from './table';
import { history } from '../../../store/history';
import moment from 'moment';
import promotionServices from './promotionServices';
import ExportCsvModal from './exportCsvModal';
import PromoModal from './promo_modal';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Promotion = () => {
    const [dateRange, setDateRange] = React.useState({ startDate: '', endDate: '' });
    const [selected, setSelected] = React.useState('All');
    const userType = localStorage.getItem('userType');
    const [searchVal, setSearchVal] = React.useState('');
    const [visibleClearBtn, setVisibleClearBtn] = React.useState(false);
    const [tableData, setTableData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [showExportModal, setShowExportModal] = React.useState(false);
    const [email, setEmail] = React.useState(null);
    const [page, setPage] = React.useState(1);
    const segments = localStorage.getItem('segments');
    const [createModal, setCreateModal] = React.useState(false);
    const [checkedList, setCheckedList] = React.useState(OTC_LIST_PROMO);
    const [indeterminate, setIndeterminate] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(true);
    const [selectedChannels, setSelectedChannels] = React.useState([]);
    const [discountType, setDiscountType] = React.useState('');
    const [drawerDetails, setDrawerDetails] = React.useState(false);
    const [detailsData, setDetailsData] = React.useState([]);
  

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    const rangeSelect = (dates, dateString, id) => {
        setDateRange({ ...dateRange, [id]: moment(dateString).format('MM-DD-YYYY') });
    }

    const errorPrompt = (msg) => {
        message.error(msg);
    };

    const handleSearch = (e) => {
        setSearchVal(e.target.value);
    }

    const onFilterApply = () => {
        getData(searchVal, discountType, selectedChannels, dateRange.startDate, dateRange.endDate);
        setVisibleClearBtn(true);
    }

    const onChangePage = (e) => {
        setPage(e);
        getData(searchVal, discountType, selectedChannels, dateRange.startDate, dateRange.endDate, e, 10);
    }

    const handleDelete = async (data) => {
        setIsLoading(true);
        const params = ({ code: data.code });
        try {
            const res = await promotionServices.removePromoCode(params);
            if(res.status === 'success') {
                message.success(res.message);
                getData('', '', '', '');
            } else {
                message.success(res.message);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            errorPrompt('Please try again');
        }
        setIsLoading(false);
    }

    const getData = async (search, discountType, channels, start, end, page, pageSize, isExport, email) => {
        setIsLoading(true);
        try {
            const res = await promotionServices.getPromotions(search, discountType, channels, start, end, page, pageSize, isExport, email);
            if (isExport && res.status === 'success') {
                setShowExportModal(false);
                message.success(res.message);
            } else if (res.data) {
                setTableData(res);
            } else {
                errorPrompt(res.message);
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            errorPrompt('Please try again');
        }
    }


    const renderChannels = (e) => {
        // let filteredValues = []
        // Object.keys(choices).map(i => i != 0 && filteredValues.push(choices[i].id))

        const onChange = list => {
            setCheckedList(list);
            setIndeterminate(!!list.length && list.length < OTC_LIST_PROMO.length);
            setCheckAll(list.length === OTC_LIST_PROMO.length);
            let channel_selected = []
            try {
                list.map((channel, i) => {
                    if (typeof OTC_PROMO[channel] && OTC_PROMO[channel].code !== 'undefined') {
                        channel_selected.push(OTC_PROMO[channel].code);
                    }
                });
            } catch (error) {

            }
            setSelectedChannels(channel_selected);
        };

        const onCheckAll = (value) => {
            setCheckedList(value ? OTC_LIST_PROMO : []);
            setIndeterminate(false);
            setCheckAll(value);
            let channel_selected = []
            try {
                OTC_LIST_PROMO.map((channel, i) => {
                    if (OTC_PROMO[channel].code) {
                        channel_selected.push(OTC_PROMO[channel].code);
                    }
                });
            } catch (error) {

            }
            setSelectedChannels(value ? channel_selected : []);
        }

        return (
            <div style={styles.optionDiv}>
                <Row>
                    <Checkbox indeterminate={indeterminate} onChange={() => onCheckAll(!checkAll)} checked={checkAll} >
                        Check All
                    </Checkbox>
                    <Checkbox.Group style={{ display: 'flex', flexDirection: 'column' }} options={OTC_LIST_PROMO} onChange={(e) => onChange(e)} value={checkedList}>
                        {/* {
                        OTC_LIST.map((channel, i) => {
                            return <Row>
                                <Checkbox style={{ marginLeft: 0 }}></Checkbox>
                            </Row>
                        })
                    } */}
                    </Checkbox.Group>
                </Row>
            </div>
        )
    }

    const discountTypeChange = (e) => {
        if (e === 'Fixed') {
            setDiscountType('FI');
        } else if (e === 'Percentage') {
            setDiscountType('PE');
        } else {
            setDiscountType('All');
        }
    }

    const displayDiscountType = (discount) => {
        switch (discount) {
            case "FI":
                return 'Fixed'
            case 'PE':
                return 'Percentage'
            case 'All':
                return 'All'
            default:
                return 'All';
        }
    }

    const exportEmail = () => {
        getData(searchVal, discountType, selectedChannels, dateRange.startDate, dateRange.endDate, page, 10, true, email);
        
        // getData(searchVal, dateRange.startDate, dateRange.endDate, page, 10, true, email);
    }

    const redirectPage = () => {
        if (localStorage.getItem("jwtToken") === "" || !localStorage.getItem("jwtToken")) {
            history.push('/login')
        }
        // else if (['ME', 'CO'].includes(localStorage.getItem("userType"))) {
        //     history.push('/dashboard')
        // }
        else if (['PS', 'PF'].includes(localStorage.getItem("userType"))) {
            history.push('/submissions_kyb')
        }
    }


    const renderSegments = (segments) => {

        let rendered_segments = []

        if(typeof(segments) == 'string') {
            segments = segments.split(',')
        }
    
        segments && segments.map((segment, i) => {
            // if (OTC_NAME_PROMO[channel].name) {
            //   rendered_channels.push(OTC_NAME_PROMO[channel].name + ", ");
            // }
            rendered_segments.push(displaySegments(segment))
          });
    
        return rendered_segments;
        
    }

    const displaySegments = (segment) => {
        switch (segment) {
            case 'ME_':
            return 'BUxME, ';
            case 'GIG':
            return 'BUxGIG, ';
            case 'SME':
            return 'BUxSME, ';
            case 'BIZ':
            return 'BUxBIZ, ';
            default:
            return 'BUxME, ';
        }
    }

    const resetFilters = () => {
        setDateRange({ startDate: '', endDate: '' });
        setSearchVal('');
        setVisibleClearBtn(false);
        getData('', '', '', '', '', page, 10,);
        setSelectedChannels([]);
        setDiscountType('All');
    }

    React.useEffect(() => {
        redirectPage();
        resetFilters();
    }, [selected])

    return (
        <div style={{ marginLeft: '-30px', marginBottom: 102 }}>
            <MetaTag title="Promotion" />
            <div className="twoCol padding-top-40 padding-lr-20">
                <div>
                    <Typography style={styles.title}>
                        Promotion
                    </Typography>
                </div>
                <div className="flex-around">
                    {tableData['draft_count'] > 0 &&
                        <button className="draft-btn right-16 top-4 padding-lr-16" 
                                onClick={() => window.location.href = '/promotion_draft'}>
                            {tableData['draft_count']} Draft{tableData['draft_count'] > 0 && 's'}
                        </button>
                    }
                    
                    <button className="admin-download-btn right-16 padding-lr-16" onClick={() => history.push('/new_promotion')}>
                        <Icon type="plus" style={{padding: '0 4px 0 0'}} />
                        Create new
                    </button>
                    <button className={`admin-download-btn padding-lr-16`} 
                        onClick={() => setShowExportModal(true)} >
                        <Icon className="right-4" type='download' /> 
                        Download CSV
                    </button>
                </div>
            </div>
            <div style={styles.body}>
                <div style={styles.subContainer}>
                    <div style={styles.clearDiv}>
                        <Typography style={styles.filterText}>Filter</Typography>
                        <a style={styles.clearBtnDiv} hidden={!visibleClearBtn} onClick={resetFilters}>
                            <Icon style={styles.clearIcon} type="close-circle" theme="filled" />
                            <span style={styles.clearBtn}>Clear Filter</span>
                        </a>
                    </div>
                    <Typography hidden style={styles.reminder}><Icon type="exclamation-circle" theme="filled" /> Click <b>Apply</b> button to show results.</Typography>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '0 0 24px 0' }}>
                    <Popover overlayStyle={{ maxHeight: 432, overflow: 'auto' }} getPopupContainer={triggerNode => triggerNode.parentNode} content={renderChannels()} trigger='click' placement='bottomLeft'>
                        <Button size='large' style={styles.popOverBtn}>Select Channel<ExpandMoreIcon style={{ color: '#2B2D33', fontSize: 32, paddingLeft: 4 }} /></Button>
                    </Popover>
                    <Select
                        size='large'
                        placeholder='Discount Type'
                        style={{ width: '16%' }}
                        onChange={discountTypeChange}
                        value={displayDiscountType(discountType)}
                    >
                        <Select.Option key={'All'}>All</Select.Option>
                        <Select.Option key={'Fixed'}>Fixed</Select.Option>
                        <Select.Option key={'Percentage'}>Percentage</Select.Option>
                    </Select>
                    <DatePicker
                        format='MM/DD/YYYY'
                        // disabledDate={disabledDate}
                        // defaultValue={moment()}
                        value={dateRange.startDate && moment(dateRange.startDate)}
                        size="large"
                        picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'startDate')}
                        placeholder='Start Date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <span style={{ paddingTop: 8 }}>-</span>
                    <DatePicker
                        format='MM/DD/YYYY'
                        // disabledDate={disabledDate}
                        // defaultValue={moment()}
                        value={dateRange.endDate && moment(dateRange.endDate)}
                        size="large"
                        picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'endDate')}
                        placeholder='End Date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <Input
                        placeholder="Search"
                        style={{ width: '200px', height: '40px', borderRadius: '4px', padding: '0 8px 0 0px' }}
                        suffix={<Icon type="search" style={{ fontSize: '18px' }} />}
                        onChange={handleSearch}
                        value={searchVal}
                    />
                    <button className={`btn--${segments}`} style={styles.applyBtn} onClick={onFilterApply} >Apply</button>
                </div>
                <Table
                    data={tableData}
                    // onUpdate={() => getData('', '', '', '')}
                    isLoading={isLoading}
                    // length={totalLength}
                    // selected={selected}
                    currentPage={page}
                    handleDelete={(data) => handleDelete(data)}
                    onChangePage={onChangePage}
                    onRowClick={ (record) => {setDetailsData(record); setDrawerDetails(true); console.log('DRAWER')}}
                />
                <ExportCsvModal visible={showExportModal} onClose={() => setShowExportModal(false)}
                    onSend={exportEmail} onChange={(e) => setEmail(e)} />
                <PromoModal mode={'create'} visible={createModal} onClose={() => setCreateModal(false)}
                    onSuccess={() => getData(searchVal, dateRange.startDate, dateRange.endDate, selectedChannels)} />
                {/* <PromoInfoDrawer visible={drawerDetails} onClose={() => setDrawerDetails(false)} /> */}
                <Drawer
                        placement="right"
                        // closable={false}
                        onClose={() => setDrawerDetails(false)}
                        visible={drawerDetails}
                        width='378px'
                        className='voucher-drawer'
                    >
                    <div style={styles.root}>
                        <div style={drawerStyles.flexBetween}>
                            <div style={{lineHeight: '18px'}}>
                                <Typography style={drawerStyles.promocode}>PROMOCODE</Typography>
                                <Typography style={drawerStyles.code}>{(detailsData && detailsData.code) || "N/A"}</Typography>
                            </div>
                            <button className={`btn--${segments}`} style={drawerStyles.cancelBtn} onClick={() => history.push(`/edit_promotion/${detailsData.id}`)} ><Icon type='edit' /> Edit promo</button>
                        </div>
                        <div style={{padding: '8px 0'}}>
                            <Typography style={drawerStyles.sectionTitle}>Promo details</Typography>
                            <Divider style={{margin: '6px 0', backgroundColor: '#254A76'}} />
                            <Row style={drawerStyles.row}>
                                <Col span={8} style={drawerStyles.field}>Promo name</Col>
                                <Col span={16} style={drawerStyles.fieldValue}>{(detailsData && detailsData.promo_name) || "N/A"}</Col>
                            </Row>
                            <Row style={drawerStyles.row}>
                                <Col span={8} style={drawerStyles.field}>Segment</Col>
                                <Col span={16} style={drawerStyles.fieldValue}>{(detailsData && renderSegments(detailsData.segments)) || "N/A"}</Col>
                            </Row>
                            <Row style={drawerStyles.row}>
                                <Col span={8} style={drawerStyles.field}>Promo start</Col>
                                <Col span={16} style={drawerStyles.fieldValue}>{(detailsData && moment(detailsData.date_start).format('lll')) || "N/A"}</Col>
                            </Row>
                            <Row style={drawerStyles.row}>
                                <Col span={8} style={drawerStyles.field}>Promo end</Col>
                                <Col span={16} style={drawerStyles.fieldValue}>{(detailsData && moment(detailsData.date_expiry).format('lll')) || "N/A"}</Col>
                            </Row>
                            <Row style={drawerStyles.row}>
                                <Col span={8} style={drawerStyles.field}>With banner</Col>
                                <Col span={16} style={drawerStyles.fieldValue}>{(detailsData && detailsData.banner_blob ? "Yes" : "No" ) }</Col>
                            </Row>
                        </div>
                        <div style={{padding: '8px 0'}}>
                            <Typography style={drawerStyles.sectionTitle}>Voucher details</Typography>
                            <Divider style={{margin: '6px 0', backgroundColor: '#254A76'}} />
                            <Row style={drawerStyles.row}>
                                <Col span={8} style={drawerStyles.field}>Discount type</Col>
                                <Col span={16} style={drawerStyles.fieldValue}>{(detailsData && detailsData.discount_type === "FI" ? 'Fixed Amount' : 'Percentage') || "N/A"}</Col>
                            </Row>
                            {
                                detailsData && detailsData.discount_type == "FI" ?
                                <Row style={drawerStyles.row}>
                                    <Col span={10} style={drawerStyles.field}>Amount</Col>
                                    <Col span={14} style={drawerStyles.fieldValue}>₱{(detailsData && detailsData.fixed_discount && parseFloat(detailsData.fixed_discount).toFixed(2)) || "N/A"}</Col>
                                </Row> :
                                <Row style={drawerStyles.row}>
                                    <Col span={10} style={drawerStyles.field}>Percent</Col>
                                    <Col span={14} style={drawerStyles.fieldValue}>{detailsData && detailsData.percentage_discount}%</Col>
                                </Row> 

                            }
                            <Row style={drawerStyles.row}>
                                <Col span={8} style={drawerStyles.field}>Min. amount of purchase</Col>
                                <Col span={16} style={drawerStyles.fieldValue}>₱{(detailsData && detailsData.min_amount && parseFloat(detailsData.min_amount).toFixed(2)) || "N/A"}</Col>
                            </Row>
                            <Row style={drawerStyles.row}>
                                <Col span={8} style={drawerStyles.field}>Limit per merchant</Col>
                                <Col span={16} style={drawerStyles.fieldValue}>{(detailsData && detailsData.q_merchant) || 0}</Col>
                            </Row>
                            <Row style={drawerStyles.row}>
                                <Col span={8} style={drawerStyles.field}>Limit per day</Col>
                                <Col span={16} style={drawerStyles.fieldValue}>{(detailsData && detailsData.q_day) || 0}</Col>
                            </Row>
                            <Row style={drawerStyles.row}>
                                <Col span={8} style={drawerStyles.field}>Limit per merchant/day</Col>
                                <Col span={16} style={drawerStyles.fieldValue}>{(detailsData && detailsData.q_merchant_day) || 0 }</Col>
                            </Row>
                        </div>
                        <div style={{padding: '8px 0'}}>
                            <Typography style={drawerStyles.sectionTitle}>Description</Typography>
                            <Divider style={{margin: '6px 0', backgroundColor: '#254A76'}} />
                            <Row style={drawerStyles.row}>
                                <Col span={8} style={{...drawerStyles.field, width: '100%'}}>{(detailsData && detailsData.description) || "N/A"}</Col>
                            </Row>
                        </div>
                        <div style={{padding: '8px 0'}}>
                            <Typography style={drawerStyles.sectionTitle}>Channels</Typography>
                            <Divider style={{margin: '6px 0', backgroundColor: '#254A76'}} />
                            <Row style={drawerStyles.row}>
                                <Col span={8} style={{...drawerStyles.field, width: '100%'}}>All OTC Channels</Col>
                            </Row>
                        </div>
                    </div>
                </Drawer>
            </div>
        </div>
    )
}

const styles = {
    root: {
        padding: '24px' 
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '42px 20px 0px 20px'
    },
    title: {
        backgroundColor: 'transparent',
        width: '100%',
        color: '#000',
        fontWeight: 'bold',
        fontSize: 28,
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 8,
    },
    btnGroup: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: '0 32px',
    },
    body: {
        backgroundColor: 'transparent',
        padding: '0px 16px',
        backgroundColor: '#FFF',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        margin: '38px 20px'
    },
    addBtn: {
        color: '#FFF',
        backgroundColor: '#1DD28B',
        height: 40,
        borderRadius: 4,
        padding: '8px, 16px, 8px, 16px',
        fontSize: 16,
        fontWeight: '600'
    },
    btnContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    body: {
        backgroundColor: 'transparent',
        padding: '0px 16px',
        backgroundColor: '#FFF',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        margin: '38px 20px'
    },
    subContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 0'
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 178,
        padding: '0 8px 0 8px'
    },
    filterText: {
        color: '#000',
        fontSize: 14,
        fontWeight: 'bold'
    },
    reminder: {
        fontSize: 12,
        color: '#909196',
    },
    applyBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: 92,
        cursor: 'pointer',
        backgroundColor: '#0A315E',
        color: '#FFF',
        border: 0
    },
    clearBtnDiv: {
        backgroundColor: '#E9EEF4',
        borderRadius: 20,
        padding: '6px 12px',
        margin: '0 12px',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #D4D5D8'
    },
    clearBtn: {
        color: '#2b2d32',
        fontSize: 14,
        paddingLeft: 4,
        fontWeight: '500',
        opacity: '0.8'
    },
    clearDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    clearIcon: {
        color: '#2b2d32',
        fontSize: 12,
        opacity: '0.8'
    },
    exportBtn: {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: '1px solid #0A315E',
        borderRadius: 4,
        height: 40,
        fontSize: 16,
        fontWeight: '500',
        color: '#0A315E',
        padding: '8px 16px',
        width: '150px'
    },
    optionDiv: {
        display: 'flex',

    },
    popOverBtn: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 8px 0 0',
        paddingRight: 4
    }
}

const drawerStyles = {
    root: {

    },
    cancelBtn: {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: '1px solid #0A315E',
        borderRadius: 4,
        height: 40,
        fontSize: 16,
        fontWeight: '500',
        color: '#0A315E',
        padding: '8px 16px',
        width: 'auto',
        marginRight: 8
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px 0 12px 0'
    },
    promocode: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'rgba(43, 45, 50, 0.8)'
    },
    code: {
        color: '#F5922F',
        fontSize: '24px',
        fontWeight: 'bold'
    },
    sectionTitle: {
        color: '#254A76',
        fontSize: '16px',
        fontWeight: 'bold'
    },
    row: {
        padding: '8px 0',
    },
    fieldValue: {
        color: '#2B2D32',
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'right'
    },
    field: {
        color: '#2B2D32',
        fontSize: 16,
    },
}

export default Promotion;

