import React, {useEffect} from 'react'
import ProfileHolder from '../../../static/icons/profile.svg'
import axios from 'axios'
import { Button, Input, Divider } from 'antd'
import { useDispatch } from "react-redux";
import { updateDetails } from "../basicInfoAction";
import {history} from '../../../store/history'
import BuxAlert from '../../alert/alert'
import {isMobile} from 'react-device-detect';
import {Handle401} from '../../handle401/handle401'


const BasicInfoForm = props => {

    const dispatch = useDispatch();
    const [firstName, setFirstName] = React.useState(localStorage.getItem("firstName"))
    const [lastName, setLastName] = React.useState(localStorage.getItem("lastName"))
    const [middleName, setMiddleName] = React.useState(localStorage.getItem("middleName"))
    const [suffix, setSuffix] = React.useState(localStorage.getItem("suffix"))
    const [username, setUsername] = React.useState(localStorage.getItem("username"))
    const profileStyle = {
        borderRadius: '50%',
        width: '67px',
        height: '69px',
        marginRight: '10px'
    }

    useEffect(() => {
        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            window.location.href = '/login'
          }
        else{
            if(isMobile){
               window.location.href = '/mobile/my_profile/basic_info'
            }
            else{
                fetchMyAPI()
            }
        }
        
    }, [])
    
    async function fetchMyAPI() {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        

        try{
            let response = await axios.get(
                process.env.REACT_APP_API_URL + "/api/get_details/",
                yourConfig
            );
            console.log(response)
            if(response.data != ""){
                localStorage.setItem("firstName", response.data.first_name);
                localStorage.setItem("lastName", response.data.last_name);
                localStorage.setItem("middleName", response.data.middle_name);
                localStorage.setItem("suffix", response.data.suffix);
    
                setFirstName(response.data.first_name)
                setLastName(response.data.last_name)
                setMiddleName(response.data.middle_name)
                setSuffix(response.data.suffix)
            }
        }
        catch (error) {
            if((error.response)&&(error.response.status == 401)){
                Handle401()
            }
        }
        
    }

    function submitCreds() {
        let params = {
        firstName: firstName,
        lastName: lastName,
        middleName: middleName,
        suffix: suffix
        };
        dispatch(updateDetails(params));
    }
    
    const btn = {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        marginRight: '10px'
    }
    const labelStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#2B2D33',
    }
    const saveBtn = {
        width: '150px',
        height: '40px',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '24px',
        float: 'right',
        marginBottom: '20px'
    }
    return (
        <div style={{width: '70%', padding: '48px 52px 0px 52px'}}>
            
            <div style={{marginBottom: '20px'}}>
                <img src={ProfileHolder} style={profileStyle}/>
                <Button type="primary" style={btn}>Upload new picture</Button>
                <Button style={btn}>Delete</Button>
            </div>

            <BuxAlert/>
            <div  style={{display: 'flex', width:'100%', marginBottom: '20px', marginTop: '20px'}}>
                <div style={{width: '49%', marginRight: '2%'}}>
                <div style={labelStyle}>First name</div>
                <Input value={firstName} onChange={(e)=> setFirstName(e.target.value)} style={{width: '100%', height: '40px'}}/>
                </div>
                <div style={{width: '49%'}}>
                <div style={labelStyle}>Last name</div>
                <Input value={lastName} onChange={(e)=> setLastName(e.target.value)} style={{width: '100%', height: '40px'}}/>
                </div>
            </div>
            <div  style={{display: 'flex', width:'100%', marginBottom: '20px'}}>
                <div style={{width: '49%', marginRight: '2%'}}>
                <div style={labelStyle}>Middle name(optional)</div>
                <Input value={middleName} onChange={(e)=> setMiddleName(e.target.value)} style={{width: '100%', height: '40px'}}/>
                </div>
                <div style={{width: '49%'}}>
                <div style={labelStyle}>Suffix</div>
                <Input value={suffix} onChange={(e)=> setSuffix(e.target.value)} style={{width: '100%', height: '40px'}}/>
                </div>
            </div>
            <div style={labelStyle}>Username</div>
            <Input value={username} style={{width: '100%', height: '40px'}} readOnly/>
            <Divider></Divider>
            <Button type="primary" style={saveBtn} onClick={()=> submitCreds()}>Save changes</Button>
            
        </div>   
    )

}

export default BasicInfoForm