import { createAction } from "redux-actions";

export const TooltipActionTypes = {
  SHOW_GENERATE_TOOLTIP: "@@SHOW_GENERATE_TOOLTIP",
  HIDE_TOOLTIPS: "@@HIDE_TOOLTIPS",
  SHOW_CONTACTUS_TOOLTIP: "@@SHOW_CONTACTUS_TOOLTIP",
  SET_TEST_MODE: 'SET_TEST_MODE',
};

export const showGenerateTooltip = createAction(TooltipActionTypes.SHOW_GENERATE_TOOLTIP);
export const hideTooltips = createAction(TooltipActionTypes.HIDE_TOOLTIPS);
export const showContactUsTooltip = createAction(TooltipActionTypes.SHOW_CONTACTUS_TOOLTIP);
export const setTestMode = (data) => {
  return {type: TooltipActionTypes.SET_TEST_MODE, payload: data}; 
}