import React from 'react'
import {Card, Spin, Icon} from 'antd'
import axios from 'axios'
import enterpriseStyle from '../kybStyle'
import { isMobile } from 'react-device-detect'
import NoteCard from '../noteCard'
import ContactPerson from './contact_info_person';


const ContactInfo = props => {
    const kybStep = localStorage.getItem('KYBStep')

    const kyb = enterpriseStyle()
    const note = `All the information should be the same as indicated in your contract. Once the applicaton is approved, all contact person indicated in this form will have an account.`
    const [, setSelected] = React.useState("");

    const [contactPersons, setContactPersons] = React.useState([
        {department: 'MA', type: "Main Contact Person (SPOC)", first_name: "", middle_name: "", last_name: "", position: 16, mobile: "", email: ""},
        {add_text: 'Add Finance', department: 'FI', type: "Finance", first_name: "", middle_name: "", last_name: "", mobile: "", email: "", hidden: true},
        {add_text: 'Add IT', department: 'IT', type: "IT", first_name: "", middle_name: "", last_name: "", mobile: "", email: "", hidden: true},
        {add_text: 'Add Sales', department: 'SA', type: "Sales", first_name: "", middle_name: "", last_name: "", mobile: "", email: "", hidden: true},
        {add_text: 'Add Support', department: 'SU', type: "Support", first_name: "", middle_name: "", last_name: "", mobile: "", email: "", hidden: true}
    ]);
    const [contactPersonsErrors, setContactPersonsErrors] = React.useState([true, false, false, false, false]);
    const [isFormSubmittable, setIsFormSubmittable] = React.useState(false);
    const [showSpin, setShowSpin] = React.useState(true)

    const userRole = localStorage.getItem("userType")
    const adminRole = ['AD', 'SD']
    const [contactStep, setContactStep] = React.useState()

    let needsFirstDataFetch = true;

    async function fetchData(){
        try{
            const yourConfig = {
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            }

            let userId = ['CO','SW'].includes(localStorage.getItem('userType')) ? 
                            localStorage.getItem('userId') : 
                        localStorage.getItem("KYBType") == "creation" ? 
                            localStorage.getItem('enterpriseId') : props.userId

            let url = process.env.REACT_APP_API_URL + `/api/kyb/${userId}/`;
            let response = await axios.get(url,yourConfig)

            if(response.data.status == "failed"){
                console.log(response.data.message)
            }

            else {
                setContactStep(response.data.step)
                for(let i=0;i<response.data.contact_persons.length;i++){
                    const list = [...contactPersons]

                    if(response.data.contact_persons[i].department === "MA"){
                        const list = [...contactPersons]
                        list[0]['first_name'] = response.data.contact_persons[i].first_name
                        list[0]['middle_name'] = response.data.contact_persons[i].middle_name
                        list[0]['last_name'] = response.data.contact_persons[i].last_name
                        list[0]['position'] = response.data.contact_persons[i].position == null ? 16 : response.data.contact_persons[i].position
                        list[0]['mobile'] = response.data.contact_persons[i].mobile
                        list[0]['email'] = response.data.contact_persons[i].email
                        setContactPersons(list)
                        setContactPersonsErrors([false, false, false, false, false]);
                    }

                    else if(response.data.contact_persons[i].department === "FI"){
                        list[1]['first_name'] = response.data.contact_persons[i].first_name
                        list[1]['middle_name'] = response.data.contact_persons[i].middle_name
                        list[1]['last_name'] = response.data.contact_persons[i].last_name
                        list[1]['mobile'] = response.data.contact_persons[i].mobile
                        list[1]['email'] = response.data.contact_persons[i].email
                        if(response.data.contact_persons[i].first_name != "" ) {
                            list[1]['hidden'] = false
                        }
                        setContactPersons(list)
                    }

                    else if(response.data.contact_persons[i].department === "IT"){
                        list[2]['first_name'] = response.data.contact_persons[i].first_name
                        list[2]['middle_name'] = response.data.contact_persons[i].middle_name
                        list[2]['last_name'] = response.data.contact_persons[i].last_name
                        list[2]['mobile'] = response.data.contact_persons[i].mobile
                        list[2]['email'] = response.data.contact_persons[i].email
                        if(response.data.contact_persons[i].first_name != "" ) {
                            list[2]['hidden'] = false
                        }
                        setContactPersons(list)
                    }

                    else if(response.data.contact_persons[i].department === "SA"){
                        list[3]['first_name'] = response.data.contact_persons[i].first_name
                        list[3]['middle_name'] = response.data.contact_persons[i].middle_name
                        list[3]['last_name'] = response.data.contact_persons[i].last_name
                        list[3]['mobile'] = response.data.contact_persons[i].mobile
                        list[3]['email'] = response.data.contact_persons[i].email
                        if(response.data.contact_persons[i].first_name != "" ) {
                            list[3]['hidden'] = false
                        }
                        setContactPersons(list)
                    }

                    else if(response.data.contact_persons[i].department === "SU"){
                        list[4]['first_name'] = response.data.contact_persons[i].first_name
                        list[4]['middle_name'] = response.data.contact_persons[i].middle_name
                        list[4]['last_name'] = response.data.contact_persons[i].last_name
                        list[4]['mobile'] = response.data.contact_persons[i].mobile
                        list[4]['email'] = response.data.contact_persons[i].email
                        if(response.data.contact_persons[i].first_name != "" ) {
                            list[4]['hidden'] = false
                        }
                        setContactPersons(list)
                    }
                }
            }

            needsFirstDataFetch = false;
        }

        catch(error){
            console.log(error)
        }
    }

    React.useEffect(() => {
        if (needsFirstDataFetch) {
            fetchData();
        }
    }, [needsFirstDataFetch]);

    React.useEffect(() => {
        let all_contact_persons_passed = contactPersonsErrors.every((has_error) => {
            return (has_error === false);
        });
        setIsFormSubmittable(all_contact_persons_passed);
    }, [contactPersonsErrors])

    function is_complete(person) {
        return (person.first_name
                && person.last_name
                && person.mobile
                && person.email)
    }

    function submitStep2(){
        setShowSpin(false)
        let contacts_to_send = contactPersons.filter(person => is_complete(person))
        let params = {
            contact_persons: contacts_to_send,
            step_to_update: 2,
        }
        props.submitStep(params, 2)
    }

    function editBusinessInfo(val){
        props.openEdit(val)
    }

    function showField(item, key) {
        const newContactPersons = [...contactPersons]
        newContactPersons[key]['hidden'] = !item.hidden;

        setContactPersons(newContactPersons);
    }

    let hideAddContact = !contactPersons[1]['hidden'] &&
                            !contactPersons[2]['hidden'] &&
                            !contactPersons[3]['hidden'] &&
                            !contactPersons[4]['hidden']


    return(
        <div>
        <Card className={kyb.formCardStyle} bodyStyle={{padding: '20px 16px 20px 16px'}}>
            <div className={`bottom-32 twoCol`}>
                <div className={kyb.headerStyle}style={{ fontSize: isMobile ? '16px' : '20px'}}>Contact Information</div>
                <div>
                    <a href className={`text-${props.segments} medium-font-weight`} onClick={() => editBusinessInfo(2)}
                         hidden={kybStep == 1 || localStorage.getItem("KYBType") == "creation" && contactStep < 7}>
                            Cancel
                    </a>   
                </div>
            </div>

            <NoteCard note={note} />

            {props.alertMessage}

            <div>
                {
                    contactPersons.map((item, index) => {
                        return <ContactPerson
                                    key={index}
                                    person={item} index={index}
                                    segments={props.segments}
                                    showField={showField}
                                    stateData={{
                                        contactPersons,
                                        setContactPersons,
                                        contactPersonsErrors,
                                        setContactPersonsErrors,
                                        setSelected,
                                    }}
                                />
                    })
                } 

                <div className="top-32">
                    <div className={kyb.headerStrong} hidden={hideAddContact}>
                        Add more contact person
                    </div>
                    {
                        contactPersons.map((item, index) => {
                            return(
                                <div className="top-16" hidden={!contactPersons[index]['hidden']}>
                                    <button className={`outline-btn--${props.segments} ${isMobile ? "btn-mobile-width" : "btn-web-width"} btn-align-left btn-height`} onClick={() => showField(item, index)}>
                                        <Icon type="plus" className={`text-${props.segments} right-8`} /> {item.add_text}
                                    </button>
                                </div>
                            )
                        })
                    }
                </div>

            </div>

            <div hidden={isMobile} align="right">
                <button className={`btn--${props.segments} btn-height top-20 right-8`}
                    // style={{
                    //     opacity: !isFormSubmittable || !showSpin ? 0.5 : '',
                    //     cursor: !isFormSubmittable  || !showSpin ? 'not-allowed' : 'pointer'
                    // }}
                    // disabled={! isFormSubmittable || !showSpin}
                    onClick={() => submitStep2()}>
                    {/* <div hidden={showSpin}><Spin /></div> */}
                    <div>
                        {
                            adminRole.includes(userRole) && localStorage.getItem("KYBType") != "creation" ? "Save Changes" : 
                            kybStep >= 6 ? "Save" : 
                            "Submit and next"
                        }
                    </div> 
                </button>
                {props.footerNote}
                

            </div>
        </Card>

        <div className={kyb.stickyBtn} hidden={!isMobile}>
            <button className={`btn--${props.segments} btn-mobile-width`} 
                // style={{
                //     opacity: isFormSubmittable ? '' : 0.5,
                //     cursor: isFormSubmittable ? 'pointer' : 'not-allowed'
                // }}
                // disabled={! isFormSubmittable}
                onClick={() => submitStep2()}> 
                    {
                            adminRole.includes(userRole) ? "Save Changes" : 
                            kybStep >= 6 ? "Save" : 
                            "Submit and next"
                    }
            </button>
        </div>
        </div>
    )
}

export default ContactInfo