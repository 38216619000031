import React,{ useState, useEffect } from 'react';
import MetaTag from '../../meta_tag/meta_tag';
import { Button, Typography } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import OTC from './recon_enterprise';
import { isMobile } from 'react-device-detect';
import { history } from '../../../store/history';
import MerchantGroups from '../kyb_main/merchant_groups/components/index'
import BuxAdvancing from './bux_advancing'
import { useSelector } from 'react-redux';
import Main from '../../../layouts/Main';

const Advancing = props => {
  const pUid = props && props.match && props.match.params.id;
  const [tab, setTab] = useState(0);
  const user = localStorage.getItem('userType');
  const [selected, setSelected] = React.useState('Recon') 
  const ubp_enabled = localStorage.getItem('cc_enabled') == 'UnionBank';
  const xendit_enabled = localStorage.getItem('cc_enabled') == 'Xendit';
  const endpoints = useSelector(state => state.child_endpoints.endpoints);
  const isChild = localStorage.getItem("is_child") === "true"

  const ButtonTab = ({rootStyle}) => {

    const views = ['Recon', 'UBP FSC', 'Batch Advancing'];

    const status = ['OTC / Web / E-Wallet'];
    
    const handleClick = (event) => {
      setSelected(event.target.value);
    }
    
    return (
      <div style={styles.btnTabContainer}>
        <MetaTag title="Advancing"/>
        <ButtonGroup>
          {
            views.map((btn, i) => {
              return (
                <Button
                  hidden={i===1}
                  key={btn}
                  value={btn}
                  onClick={handleClick}
                  style={{...styles.btnGroup, 
                    color: selected === btn? '#0D3D76' : '#909196',
                    borderBottom: selected === btn? '4px solid #0D3D76' : '0' }}
                    >
                    {btn}
                </Button>
              )
            })
          }
        </ButtonGroup>
      </div>
    )
  }


  React.useEffect(() => {
    if (!isChild || !endpoints.length) {
      return;
    }

    // Redirect if URL was manually typed
    if(!endpoints.includes('recon')) {
      history.push(endpoints[0]);
    }
  }, [endpoints])

  // If endpoints have not been loaded yet. Hook on endpoints will handle redirect
  if (isChild && !endpoints.includes('recon')) {
    return null;
  }


  return (
    <Main>
    <ButtonTab/>
    { selected == 'Recon' ? 
    <OTC id={pUid}/>
    :
    ""}

    { selected == 'UBP FSC' ? 
    <MerchantGroups props={props}/>
    :
    ""}

    { selected == 'Batch Advancing' ? 
    <BuxAdvancing props={props}/>
    :
    ""}
    </ Main>
  )
};

const styles = {
  root: {

  },
  titleContainer: {
    marginTop: '-2.5%',
    background: '#fff',
    marginLeft: '-100px',
    padding: '3% 200px 0px 150px',
    // padding: '42px 42px 0px 42px',
    // background: '#fff'
  },
  title: {
    backgroundColor: 'transparent',
    width: '100%',
    color: '#000',
    fontWeight: 'bold',
    fontSize: 28,
    display: 'flex',
    alignItems: 'center',
  },
  btnGroup: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '20px 32px 24px 32px',
    borderColor: 'transparent',
    backgroundColor: 'transparent'
  },
  btnTabContainer: {
    padding: '20px, 20px 0 20px',
  },
};

export default Advancing;