import React from 'react'
import {Card, Progress, Divider, Icon} from 'antd'
import * as Style from './kyc_style'
import Checked from '../../../static/icons/checked.svg'
import Unchecked from '../../../static/icons/unchecked.svg'
import { isMobile } from 'react-device-detect'
import {setScroll} from './application_main'

const ProgressCard = props => {

    const userType = localStorage.getItem("userType")
    const firstName = localStorage.getItem("firstName")
    const segments = localStorage.getItem("segments")

    const [selected, setSelected] = React.useState("")

    const current_edit = props.step
    const current_step = props && props.details && props.details.step ? props.details.step : 0;
    const dataFilled = props.dataFilled
    const testMode = props && props.testMode;
    const headerCardOn = (!testMode && (dataFilled)) || (!testMode && current_step === 6 || current_step === 9 || current_step === 7 ||current_step === 8)

    const color = segments == 'me_' ? '#0DAED0' : segments == 'gig' ? '#41BD5C' : '#F5922F'
    const steps = [
        {
            step: 0,
            name: 'Personal Information',
            scroll: current_step == 7 || current_step == 8 || current_step == 9 ? '230' : '215'
        },
        {
            step: 1,
            name: 'Contact Information',
            scroll: current_step == 7 || current_step == 8 ? '865' : current_step == 9 ? '820' : '860'
        },
        {
            step: 2,
            name: 'Employment Information',
            scroll: current_step == 7 || current_step == 8 ? '1090' : current_step == 9 ? '1055' : '1095'
        },
        {
            step: 4,
            name: 'Identity Verification',
            scroll: current_step == 7 || current_step == 8 ? '1555' : current_step == 9 ? '1530' : '1570'
        },
        {
            step: 5,
            name: 'Business Information',
            scroll: '2200'
        },

    ]

    const renderTitle = (steps) => {
        switch (steps) {
            case 0:
                return 'Personal Information';
            case 1:
                return 'Contact Information';
            case 2:
                return 'Employment Information';
            case 3:
            case 4:
                return 'Identity Information';
            case 5:
                return 'Business Information';
            default:
                return '';
        }
    }

    const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;

    function backgroundColor(step,name){
        if(step == current_edit || name == selected){
            return '#E6EAF0'
        }
        
        else if((current_edit == 3 || current_edit == 4) && step == 4){
            return '#E6EAF0'
        }
        else{
            return ''
        }
    }

    const renderSteps = (steps) => {
        switch (steps) {
            case 0:
                return 1;
            case 1:
                return 2;
            case 2:
                return 3;
            case 3:
            case 4:
                return 4;
            case 5:
                return 5;
            default:
                return 5;
        }
    }

    return(
        <div>
            <div 
                style={Style.progressCardStyle}
                bodyStyle={{padding: '11px 0px 10px 0px'}}
                >
                <div style={{padding: isMobile ? '0 16px 12px 16px' : !headerCardOn ? '16px 16px 0 16px' : '0px 16px 0 16px', height: isMobile && '15vh'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{ lineHeight: isMobile && '1' }}>
                            <div style={{...Style.headerStyle, fontSize: isMobile ? '16px' : isHighReso? '20px' : '24px', lineHeight: '32px'}}>
                                {isMobile? renderTitle(current_edit) : 'Hello, ' + firstName}
                            </div>
                            <span hidden={!isMobile}>Step {renderSteps(current_edit)} of {steps.length} </span>
                            <div hidden={isMobile} style={{...Style.normalText, color: '#2b2d33', marginTop: '0px'}}>Complete your profile</div>
                        </div>
                        {/* <div style={{marginTop: '10px'}}>
                            <div style={Style.trackerStyle}>
                                Basic
                            </div>
                        </div> */}
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <Progress 
                            percent={
                                dataFilled ? 100 :
                                Math.min(
                                    renderSteps(current_edit)) * 20
                            } 
                            showInfo={false} 
                            trailColor="#D4D5D8"
                            strokeColor={{
                                '0%': current_step >= 6 || current_edit >= 6 ? color : color,
                                '100%': current_step >= 6 || current_edit >= 6 ? color : color,
                            }}/>
                        
                        <div style={{...Style.miniText, color: current_step >= 6 || current_edit >= 6 ? color : '#54575F', fontWeight: '300'}} align="right">
                            {
                                dataFilled ? 100 :
                                Math.min(
                                    renderSteps(current_edit)) * 20 
                            }%
                        </div>

                    </div>
                </div>
                {
                    isMobile ? "" :
                    <div style={{marginTop: '14px'}}>
                        {/* <Divider style={{marginBottom:'10px', marginTop: 14}}/> */}
                        {
                            steps.map((item,i)=>{
                                return(
                                    <a key={i}>
                                        <div style={{
                                                height: isHighReso? '38px' : '48px', 
                                                display: 'flex', 
                                                justifyContent:'space-between',
                                                backgroundColor: dataFilled ? 'transparent' : backgroundColor(item.step, item.name),
                                                padding: isHighReso? '12px 18px' : '12px 18px 0px 18px',
                                                marginBottom: '10px',
                                                borderRadius: '8px',
                                                alignItems: isHighReso? 'center' : null
                                            }}
                                            onClick={()=>{ if(current_step >= 6 || current_edit >= 6) setScroll(item.scroll)}}
                                            onMouseEnter={()=>setSelected(item.name)}
                                            onMouseLeave={()=>setSelected("")}>
                                            <div style={{...Style.normalText, color: '#2b2d33'}}>
                                                {item.name}
                                            </div>
                                            <div style={{marginTop: '3px'}}>
                                                {
                                                    (current_step == 6 || current_edit == 6) || dataFilled ? 
                                                    <Icon type="check-circle" theme="filled"  style={{fontSize: '21px', color: color}} /> :
                                                    current_step > item.step ? 
                                                    <Icon type="check-circle" theme="filled"  style={{fontSize: '21px', color: color}} /> : 
                                                    <div></div>
                                                }
                                            </div>
                                        </div>
                                    </a>
                                )
                            })
                        }
                        {/* {
                            current_edit == 6 || current_edit == 7 ? 
                            <div>
                                <Divider style={{marginBottom:'10px'}}/>
                                <div style={{padding: '0px 18px 0px 18px'}}>
                                    <div style={{...Style.miniText, fontWeight: 'bold', color: '#2b2d33'}}>Quick Tip</div>
                                    <div style={{fontSize: '14px', color: '#909196', marginTop: '10px'}}>You can review your application by clicking each tabs here.</div>
                                </div>
                            </div>
                            :""
                        } */}
                    </div>

                }
            </div>
        </div>
    )
}

export default ProgressCard