import React from 'react';
import axios from 'axios';
import {Button, Modal, message} from 'antd'
import {TextField, Grid, makeStyles} from '@material-ui/core';


const AddFeeModal = props => {

    const useStyles = makeStyles(theme => ({
        container: {
          display: 'flex',
          flexWrap: 'wrap',
        },
        textField: {
          marginRight: '100%',
          width: 230,
        },
      }));
    
    
    const classes = useStyles();

    const [channelCode, setChannelCode] = React.useState("");
    const [channel, setChannel] = React.useState("");
    const [channelType, setChannelType] = React.useState("");
    const [channelFee, setChannelFee] = React.useState("");
    const [channelMax, setChannelMax] = React.useState("");
    const [channelEntMax, setChannelEntMax] = React.useState("");
    const [channelMin, setChannelMin] = React.useState("");
    const [buxFee, setBuxFee] = React.useState("");
    const [channelPercent, setChannelPercent] = React.useState("");
    const [buxPercent, setBuxPercent] = React.useState("");
    const [channelPartner, setChannelPartner] = React.useState("");
    const [tier, setTier] = React.useState("");

    async function addFee() {
        let yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }

        let params = {
            channel: channel,
            channel_code: channelCode,
            channel_type: channelType,
            channel_fee: channelFee,
            maximum: channelMax,
            enterprise_maximum: channelEntMax,
            minimum: channelMin,
            bux_fee: buxFee,
            channel_percent: channelPercent,
            bux_percent: buxPercent,
            channel_partner: channelPartner,
            tier: tier,
        }

        console.log(params)
        try {        
          let response = await axios.post(
            process.env.REACT_APP_API_URL + '/api/fees/add/', params,yourConfig
          );
          if( response.data.status == 'success'){
              props.closeModal(1)
              props.fetchData();
          } else if(response.data.status === 'failed') {
              msgNotif(response);
          }
        } catch (error) {
            if(error.response.data && error.response.data.message){
              msgNotif(error.response.data.message);
            } else {
              msgNotif('Error try again');
            }
        }
    }
    
    const msgNotif = (msg) => {
      if( msg && msg.data && msg.data.status === "success") {
        message.success(msg.data.message);
      } else if(msg && msg.data && msg.data.status === "failed") {
        message.error(msg.data.message);
      } else {
        message.error(msg);
      }
    }
      
    return(
      <Modal
        title="Add Fee"
        centered
        visible={props.openModal}
        onCancel={() => props.closeModal(1)}
        footer={[
            <Button key="back" onClick={()=>props.closeModal(1)}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={()=>addFee()}>
              Add
            </Button>,
          ]}
        >
        <Grid container>
            <Grid item={true} lg={12}>
                <TextField
                    id="outlined-basic"
                    label="Channel"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    onChange={ e => setChannel(e.target.value)}

                    />
            </Grid>
            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Channel Code"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    onChange={ e => setChannelCode(e.target.value)}

                    />
            </Grid>
            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Channel Type"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    onChange={ e => setChannelType(e.target.value)}

                    />
            </Grid>

            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Channel Minimum"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    onChange={ e => setChannelMin(e.target.value)}

                    />
            </Grid>

            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Channel Maximum"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    onChange={ e => setChannelMax(e.target.value)}

                    />
            </Grid>

            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Enterprise Max"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    onChange={ e => setChannelEntMax(e.target.value)}

                    />
            </Grid>

            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Channel Fee"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    onChange={ e => setChannelFee(e.target.value)}

                    />
            </Grid>

            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Channel Percent"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    onChange={ e => setChannelPercent(e.target.value)}

                    />
            </Grid>

            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="BUx Fee"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    onChange={ e => setBuxFee(e.target.value)}

                    />
            </Grid>

            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="BUx Percent"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    onChange={ e => setBuxPercent(e.target.value)}

                    />
            </Grid>

            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Channel Partner"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    onChange={ e => setChannelPartner(e.target.value)}

                    />
            </Grid>
            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Tier"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    onChange={ e => setTier(e.target.value)}

                    />
            </Grid>
        </Grid>
      </Modal>  
    )
}

export default AddFeeModal;