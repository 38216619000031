import React, { useEffect } from 'react';
import MetaTag from '../../../meta_tag/meta_tag';
import { Typography, Button } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import AddMoneyTab from './add_money';
import HistoryTab from './transaction_history';
import { history } from '../../../../store/history';
import { isMobile } from 'react-device-detect';
import TestModeBanner from '../../../cards/test_mode/testModeBanner';
import { segments, segmentStroke } from '../../../constants/constants';
import * as Style from '../../../404/styles';
import NotFound from '../../../../static/img/not_found.svg'



const AddMoney = () => {
    const [selected, setSelected] = React.useState('Add Money');
    const isMultiwallet = localStorage.getItem('is_multiwallet') === 'true';
    const isSbMultiwallet = localStorage.getItem("is_sb_multiwallet") === 'true';

    const level = parseInt(localStorage.getItem("Level"));
    const isChild = localStorage.getItem("is_child") === 'true';
    const kybStep = localStorage.getItem("KYBStep");
    const kybRequired = localStorage.getItem("kybRequired") === 'true';
    const subuserRole = localStorage.getItem("subuser_role");
    const userType = localStorage.getItem("userType");
    const approvedStep = [11, 12]
    const testMode = history.location.pathname.indexOf('/test') > -1;
    const add_money_enabled = JSON.parse(localStorage.getItem('defined_wallet_settings')).add_money_enabled

    const notApprovedKyb = (userType == "CO" && localStorage.getItem("KYBStep") < 11) && ((!testMode || localStorage.getItem("userType") == "CO") && (!approvedStep.includes(parseInt(kybStep)) && !testMode));

    const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
    const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
    const isDell1 = window.screen.width * window.devicePixelRatio === 1366 && window.screen.height * window.devicePixelRatio === 768;
    const isMac = window.screen.width * window.devicePixelRatio === 1440 && window.screen.height * window.devicePixelRatio === 900;

    const isDellReso = (isSmallReso || isDell || isDell1)

    const ButtonTab = ({ rootStyle }) => {

        const status = ['Add Money', 'History'];

        const handleClick = (event) => {
            setSelected(event.target.value);
        }

        return (
            <div style={styles.btnTabContainer}>
                <ButtonGroup>
                    {
                        status.map((btn, i) => {
                            return (
                                <Button
                                    key={btn}
                                    value={btn}
                                    onClick={handleClick}
                                    style={{
                                        ...styles.btnGroup,
                                        color: selected === btn ? segmentStroke[0] : '#909196',
                                        paddingBottom: 32,
                                        borderBottom: selected === btn ? `2px solid ${segmentStroke[0]}` : '0'
                                    }}
                                >
                                    {btn}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </div>
        )
    }

    const redirectPage = () => {
        let current_path = window.location.pathname
        if (localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null) {
            history.push('/login?next=' + current_path)
        } else if (
            isChild ||
            (userType == 'SW' && subuserRole == 'ME' && level <= 0) ||
            (userType == 'SW' && subuserRole == 'CO' && kybRequired && level != 5) ||
            (userType === 'CO' && !isMultiwallet) ||
            (notApprovedKyb && !isMultiwallet) ||
            (!['CO', 'SW'].includes(localStorage.getItem("userType")))
            //!add_money_enabled
        ) {
            history.push('/dashboard')
        } else if (isMobile) {
            history.push('/mobile/multiwallet')
        }
    }

    React.useEffect(() => {
        redirectPage();
    })

    const AddMoneyComponent = () => {
        if (!add_money_enabled) {
            return (
                <div>
                {
                    isMobile ? 
                    <div style={Style.kycMainDiv}>
                    <div style={Style.mainDiv}>
                        <img src={NotFound} alt="#"></img>
                    </div>
                    <div style={Style.kycChildDiv}>
                        <div style={Style.verifyStyle}>Page not found</div>
                        <div style={Style.textStyle}>The page you’re looking for doesn’t exist.</div>
                        <Button type="primary" style={Style.signupBtnStyle} onClick = {() => window.location.href = '/login'}>Back to Home</Button>
                    </div>
                </div>
                    :
                    <div>
                        <div style={isMobile ? Style.mobileMainDiv : Style.mainDiv}>
                            <div>
                                <img src={NotFound} alt="#" />
                            </div>
                            <div style={isMobile ? Style.mobileChildDiv : Style.childDiv}>
                                <div style={Style.verifyStyle}>Page not found</div>
                                <div style={Style.textStyle}>The page you’re looking for doesn’t exist.</div>
                                <Button type="primary" style={Style.signupBtnStyle} onClick = {() => window.location.href = '/login'}>Back to Home</Button>
                            </div>
                        </div>
                    </div>
                }
            </div>
            )
        }
        return (
            <div className={isDell ? "top-12" : "top-40"} style={{ marginRight: isDellReso ? '2vw' : isMac ? '5vw' : '10vw' }} >
                <MetaTag title="Add Money" />
                <TestModeBanner width={'100vw'} top={64} right={0} />

                <div style={styles.titleContainer}>
                    <div className="screen-title-text">Add Money</div>
                    <ButtonTab />
                </div>
                {
                    selected === 'Add Money' ? <AddMoneyTab /> : <HistoryTab />
                }
            </div>
        )
    }

    return (
        <AddMoneyComponent />
    )
}

const styles = {
    root: {

    },
    title: {
        width: '100%',
        color: '#000',
        fontWeight: 'bold',
        fontSize: 24,
        display: 'flex',
        alignItems: 'center',
    },
    btnGroup: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: '20px 32px 24px 32px',
        borderColor: 'transparent',
        backgroundColor: 'transparent'
    },
    btnTabContainer: {
        padding: '20px, 20px 0 20px',
    },
    titleContainer: {
        marginTop: '-2.5%',
        marginLeft: '-100px',
        padding: '5% 200px 0px 150px',
    }
};

export default AddMoney;