import { useState } from "react";
import { axios } from "../../../../../lib/axios";

export default function MerchantFeeConfigViewModel() {
    const [success, setSuccess] = useState(false);
    const [messageDisplay, setMessageDisplay] = useState("");
    const [merchantFeeData, setMerchantFeeData] = useState([]);
    const [merchantsList, setMerchantsList] = useState([]);
    const [merchantDetail, setMerchantDetail] = useState();
    const [settlementMerchantsList, setSettlementMerchantsList] = useState([]);
    const [isaParentList, setIsaParentList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMerchant, setIsLoadingMerchant] = useState(false);
    const [isLoadingSettlementMerchant, setIsLoadingSettlementMerchant] = useState(false);
    const [isLoadingIsaParent, setIsLoadingIsaParent] = useState(false);

    const headers = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }

    async function fetchMerchantFeeList(searchString, page) {
        setIsLoading(true);
        try {
            const response = await axios.get(`api/cashbux/merchant_fees/?page=${page}&search=${searchString}`);
            setMerchantFeeData(response);
            setSuccess(true);
            setIsLoading(false);
        } catch (error) {
            setMessageDisplay("Failed to fetch merchant fee config list");
            setSuccess(false);
            setIsLoading(false);
        }
    }

    async function addMerchantFeeConfig(params) {
        setMessageDisplay("");
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + `/api/cashbux/merchant_fees/`, params, headers);
            setSuccess(true);
            setMessageDisplay("Successfully added");
            setIsLoading(false);
        } catch (error) {
            if (error.response.data.message != "") {
                setMessageDisplay(error.response.data.message);
            } else {
                setMessageDisplay("Failed to create merchant fee config");
            }
            
            setSuccess(false);
            setIsLoading(false);
        }
    }

    async function editMerchantFeeConfig(params, id) {
        setMessageDisplay("");
        try {
            const response = await axios.patch(process.env.REACT_APP_API_URL + `/api/cashbux/merchant_fees/${id}/`, params, headers);
            setSuccess(true);
            setMessageDisplay("Successfully updated");
            setIsLoading(false);
        } catch (error) {
            if (error.response.data.message != "") {
                setMessageDisplay(error.response.data.message);
            } else {
                setMessageDisplay("Failed to update merchant fee config");
            }
            setSuccess(false);
            setIsLoading(false);
        }
    }

    async function fetchMerchants(searchString, type) {
        setMessageDisplay("");
        
        if (searchString == "") {
            if (type == "settlement_merchant") {
                setSettlementMerchantsList([]);
                setIsLoadingSettlementMerchant(false);
            } else {
                setMerchantsList([]);
                setIsLoadingMerchant(false);
            }
            setSuccess(true);
            return;
        }

        if (type == "settlement_merchant") {
            setIsLoadingSettlementMerchant(true);
        } else {
            setIsLoadingMerchant(true);
        }

        try {
            const endpoint = {
              merchant: `api/cashbux/merchants/?search=${searchString}`,
              settlement_merchant: `api/cashbux/isa_merchants/?search=${searchString}`,
            };

            const response = await axios.get(endpoint[type]);
            if (response.data) {
                let merchantArray = [];

                response.data.map((item, key) => {
                    merchantArray.push({"name": item.merchant_business, "value": item.id, "other": item.merchant_email });
                });
                
                if (type == "settlement_merchant") {
                    setSettlementMerchantsList(merchantArray);
                    setIsLoadingSettlementMerchant(false);
                } else {
                    setMerchantsList(merchantArray);
                    setIsLoadingMerchant(false);
                }
            }
            
            setSuccess(true);
        } catch (error) {
            setMessageDisplay("Failed to fetch merchant fee config list");
            setSuccess(false);
            setIsLoadingMerchant(false);
            setIsLoading(false);
        }
    }

    async function fetchIsaParent(searchString, merchantId) {
        setMessageDisplay("");
        setIsLoadingIsaParent(true);
        let excludeMerchant = "&exclude_merchant=" + merchantId;

        if (searchString == "") {
            setIsaParentList([]);
            setIsLoadingIsaParent(false);
            setSuccess(true);
            return;
        }

        try {
            const response = await axios.get(`api/cashbux/isa_merchants/?search=${searchString}${excludeMerchant}`);
            if (response.data) {
                let merchantArray = [];

                response.data.map((item, key) => {
                    merchantArray.push({"name": item.merchant_business, "value": item.id, "other": item.merchant_email });
                });

                setIsaParentList(merchantArray);
                setIsLoadingIsaParent(false);
            }
            
            setSuccess(true);
        } catch (error) {
            setMessageDisplay("Failed to fetch merchant fee config list");
            setSuccess(false);
            setIsLoadingIsaParent(false);
            setIsLoading(false);
        }
    }

    async function fetchMerchantDetail(id) {
        setMessageDisplay("");
        try {
            const response = await axios.get(`api/cashbux/merchants/${id}/`);
            setMerchantDetail(response.data);
            setSuccess(true);
        } catch (error) {
            setMessageDisplay("Failed to fetch merchant fee config data");
            setSuccess(false);
        }
    }

    return {
        fetchMerchantFeeList,
        addMerchantFeeConfig,
        editMerchantFeeConfig,
        fetchMerchants,
        fetchMerchantDetail,
        fetchIsaParent,
        merchantDetail,
        merchantsList,
        settlementMerchantsList,
        isaParentList,
        merchantFeeData,
        success,
        messageDisplay,
        isLoading,
        isLoadingMerchant,
        isLoadingSettlementMerchant,
        isLoadingIsaParent
    }

}