import React from 'react';
import fraudServices from '../fraudServices';
import { Button, Icon, Input, Modal, Typography, message } from 'antd';

const AddNewModal = ({visible, closeModal, onSuccess}) => {
  const [cancelBtnStyle, setCancelBtnStyle] = React.useState({color: '#F5922F', backgroundColor: '#FFF'});
  const [isLoading, setIsLoading] = React.useState(false);
  const [domainName, setDomainName] = React.useState(null);
  const [params, setParams] = React.useState([]);

  const cancelBtnHover = () => {
    setCancelBtnStyle({
      color: '#FFF',
      backgroundColor: '#F5922F',
    })
  }

  const cancelBtnLeave = () => {
    setCancelBtnStyle({
      color: '#F5922F',
      backgroundColor: '#FFF',
    })
  }

  const autoFormat = (value) => value == null ? null : value.replace(/\D/g, '');

  const handleChange = (event) => {
    setParams({...params, [event.target.name]:autoFormat(event.target.value)})
  }

  const hasBlanks = () => {
    const isEmpty = Object.values(params).some(x => (x === null || x === ''));
    if(isEmpty || Object.keys(params).length < 1 ){
      return true;
    }
    return false;
  }

  const success = () => {
    message.success('New domain added');
  };

  const errorPrompt = (msg) => {
    message.error(msg);
  };

  const addNewBlackList = async () => {
    setIsLoading(true);
    try {
      const res = await fraudServices.addNewBlacklist('mobile',params);
      if(res.status==='success'){
        onSuccess();
        success();
      } else {
        errorPrompt(res.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      errorPrompt(error);
    }
  }

  const resetProps = () => {
    setParams([]);
    setIsLoading(false);
  }

  return (
    <Modal
    visible={visible}
    footer={null}
    onCancel={closeModal}
    destroyOnClose
    afterClose={resetProps}
    width={392}
    centered
    closeIcon={<Icon type="close" style={{color:'#F5922F'}}/>}
    bodyStyle={styles.root}>
    <Typography style={styles.title}>Add a Blacklisted Mobile Number?</Typography>
    <div style={{...styles.inputDiv, position: 'relative'}}>
      <label style={styles.label}>Mobile Number</label>
      <Input style={{...styles.input, paddingLeft: 64}} 
        name='mobile'
        value={params['mobile']}
        maxLength={10}
        onChange={handleChange}
        disabled={isLoading}
      />
      <Typography style={styles.mobilePrefix}>+63 | </Typography>
    </div>
    <div style={styles.footerBtn}>
      <Button style={{...styles.cancelBtn, ...cancelBtnStyle}}
        onClick={closeModal}
        onMouseLeave={cancelBtnLeave}
        onMouseOver={cancelBtnHover}>Cancel</Button>
      <Button style={{...styles.confirmBtn, opacity: hasBlanks() || params['mobile'].length <10? '0.5' : '1' }} 
        disabled={hasBlanks() || params['mobile'].length <10} 
        onClick={addNewBlackList}
        loading={isLoading}>Confirm</Button>
    </div>
  </Modal>
  )
}

const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  title: {
    fontSize: 20,
    color: '#2B2D33',
    padding: '32px 0 2px 0',
  },
  cancelBtn: {
    width: '48%',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    height: 48,
    border: '1px solid #F5922F',
  },
  confirmBtn: {
    height: 48,
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    width: '48%',
  },
  footerBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 20,
  },
  label: {
    fontSize: 12,
    color: '#212B36',
    paddingTop: 16,
  },
  input: {
    padding: '11px 16px 11px 16px',
    fontSize: 16,
    height: 48,
    margin: '4px 0px 0px 0px',
    color: '#2B2D33'
  },
  inputDiv: {
    width :'100%',
    padding: '6px 0'
  },
  mobilePrefix: {
    position: 'absolute',
    top: `64%`,
    left: '10%',
    transform: 'translate(-50%, -50%)',
    fontSize: 16,
    color: '#2B2D33',
    fontWeight: 'normal',
    zIndex: '99',
  }
}


export default AddNewModal;