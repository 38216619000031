import React from 'react'
import axios from 'axios'
import {Modal, message, Button, Radio, Input} from 'antd'
import * as Style from './mobile_order_style'

const OptionModal = props => {

    const {
        visible, setCancelModalVisibility, orderDetails,
    } = props

    const [reason, setReason] = React.useState("Item/s no longer available")
    const [otherReason, setOtherReason] = React.useState("")


    const cancelOrderNow = async() => {
        let params = {
          id: orderDetails.id,
          reason: reason != 'Others' ? reason : otherReason,
        }
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let response = await axios.post(process.env.REACT_APP_API_URL + "/api/orders/cancel_order/", params, yourConfig);
        
        if(response.data.status == "success"){
            window.location.reload()
            setCancelModalVisibility(false)
            message.success("Successfully cancelled order")
        }
    }


    return (
        <Modal
              visible={visible}
              footer={null}
              onCancel={()=>setCancelModalVisibility(false)}
            >
              <div style={{ padding: '20px' }}>
                <div style={Style.cancelOrder}>Cancel Order</div>
                <div style={Style.choicesStyle}>
                Are you sure you want to cancel this order? This can’t be undone.
                </div>
                <div style={Style.choicesStyle}>Provide a reason</div>
                <div style={{marginBottom: '10px'}}>
                  <Radio.Group onChange={(e) => setReason(e.target.value)} defaultValue="Item/s no longer available">
                    <Radio style={Style.radioStyle} value="Item or service is no longer available">
                      Item or service is no longer available
                    </Radio>
                    <Radio style={Style.radioStyle} value="Wrong item or service selected">
                      Wrong item or service selected
                    </Radio>
                    <Radio style={Style.radioStyle} value="Unable to deliver the item or service">
                      Unable to deliver the item or service
                    </Radio>
                    <Radio style={Style.radioStyle} value="Payment was declined">
                      Payment was declined
                    </Radio>
                    <Radio style={Style.radioStyle} value="Others">
                      Others <Input style={{width: '200px'}} hidden={reason!="Others"} onChange={e=>setOtherReason(e.target.value)}/>
                    </Radio>
                  </Radio.Group>
                </div>
                <div>
                  <Button type="danger" style={Style.cancelButtonStyle} onClick={() => cancelOrderNow()}>Cancel and notify customer</Button>
                </div>
                <Button style={Style.dontCancelButton} onClick={()=>setCancelModalVisibility(false)}>No, don’t cancel</Button>
              </div>
            </Modal>
    )
}

export default OptionModal