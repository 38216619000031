import React from 'react';
import { Button, Icon, Input, Modal, Typography } from 'antd';

const AdvanceModal = (props) => {

    // React.useEffect(() => {
    // }, [props.visible])
    console.log('MODAL',props)
    return (
        <Modal
            maskClosable
            onCancel={() => props.onClose()}
            visible={props.visible}
            footer={false}
            closeIcon={<Icon type="close" className={`text-${props.segments}`} />}
            width={360}
            destroyOnClose
        >
            <Typography style={styles.title}>Advance this?</Typography>
            {/* <Typography style={styles.msg}>Please enter your email address to send CSV reports directly to your email.</Typography> */}
            <div style={styles.btnContainer}>
                <button className={`outline-btn--${props.segments}`} style={{...styles.btnCancel,}} onClick={props.onClose}>Cancel</button>
                <button className={`btn--${props.segments} btn-height `} style={{width: '48%'}} onClick={props.onAdvance} >Advance</button>
            </div>
        </Modal>
    )
}

const styles = {
    title: {
        color: '#2B2D33',
        fontSize: 24,
        fontWeight: '400',
        textAlign: 'left'
    },
    msg: {
        color: '#2B2D33',
        fontSize: 16,
        textAlign: 'left',
        padding: '12px 0'
    },
    btn: {
        height: 48,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 4,
        width: '48%',
    },
    input: {
        padding: '11px 16px 11px 16px',
        fontSize: 16,
        height: 48,
        margin: '4px 0px 12px 0px',
        color: '#2B2D33'
    },
    btnCancel: {
        height: 40,
        width: '48%',
        borderRadius: 4,
        fontSize: 14,
        fontWeight: '600',
    },
    btnContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '24px'
    }
}

export default AdvanceModal;