export const years = [
    'Less than a year',
    '1-3 years',
    '3-5 years',
    '5 and up years'
]

export const channels = [
    'OTC',
    'Web Banking',
    'Credit/Debit Cards',
    'E-Wallet'
]

export const payments = [
    'Website',
    'Social Media',
    'Applications',
    'Plugins'
]

export const transactions = [
    '1-1,000',
    '1,000-5,000',
    '5,000-25,000',
    '25,000-50,000',
    '50,000 and up'
]

export const tickets = [
    '50-1,000 PHP',
    '2,000-5,000 PHP',
    '5,000-10,000 PHP',
    '10,000-20,000 PHP',
    '50,000 and up'
]

export const activations = [
    'Immediate',
    'Next 30 days',
    'Next 1-3 months',
    'More than 3 months'
]

export const year_choices = {
    'Less than a year': {
      value: '0'
    },
    '1-3 years': {
      value: '1-3'
    },
    '3-5 years': {
      value: '3-5'
    },
    '5 and up years': {
      value: '5'
    },
}
export const channel_choice = {
    'OTC': {
      value: '1',
    },
    'Web Banking': {
      value: '2',
    },
    'Credit/Debit Cards': {
      value: '3'
    },
    'E-Wallet': {
      value: '4',
    },
}

export const payment_choices = {
    'Website': {
      value: '1'
    },
    'Social Media': {
      value: '2'
    },
    'Applications': {
      value: '3'
    },
    'Plugins': {
      value: '4',
    },
}

export const transaction_choices = {
    '1-1,000': {
      value: '1-1'
    },
    '1,000-5,000': {
      value: '1-5'
    },
    '5,000-25,000': {
      value: '5-25'
    },
    '25,000-50,000': {
      value: '25-50'
    },
    '50,000 and up': {
      value: '50-up'
    }
}

export const ticket_choices = {
    '50-1,000 PHP': {
      value: '1-1'
    },
    '2,000-5,000 PHP': {
      value: '2-5'
    },
    '5,000-10,000 PHP': {
      value: '5-10'
    },
    '10,000-20,000 PHP': {
      value: '10-20'
    },
    '50,000 and up': {
      value: '50'
    },
}

export const activation_choices = {
    'Immediate': {
      value: '13'
    },
    'Next 30 days': {
      value: '14'
    },
    'Next 1-3 months': {
      value: '15'
    },
    'More than 3 months': {
      value: '16'
    },
}

export const key_accounts = [
  'Key',
  'Non-Key'
]

export const key_account_choices = {
  'Key': {
    value: 'Key'
  },
  'Non-Key': {
    value: 'Non-Key'
  },
}

export const test_accounts = [
  'All',
  'Test',
  'Real'
]

export const test_account_choices = {
  'All': {
    value: 'All'
  },
  'Test': {
    value: 'Test'
  },
  'Real': {
    value: 'Real'
  },
}