import React from 'react'
import {Form, Icon, Button, Modal, Select, Alert} from 'antd'
import {TextField, Grid,makeStyles} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import axios from "axios";


const AddUsers = props => {

    const useStyles = makeStyles(theme => ({
        container: {
          display: 'flex',
          flexWrap: 'wrap',
        },
        textField: {
          marginRight: '100%',
          width: 230,
        },
      }));

    const classes = useStyles();

    const [modalOpen, setModalOpen] = React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setConfirmPassword] = React.useState(false);
    const [role, setRole] = React.useState("Merchant");
    const [email, setEmail] = React.useState("");
    const [username, setUsername] = React.useState("");
    const [fname, setFname] = React.useState("");
    const [lname, setLname] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirm] = React.useState("");
    const [visible, setVisible] = React.useState(true);
    const [message, setMessage] = React.useState("");

    const roles = [
        {
          value: 'ME',
          label: 'Merchant',
        },
        {
          value: 'AD',
          label: 'Admin',
        },
        {
          value: 'OP',
          label: 'Operations',
        },
        {
          value: 'PS',
          label: 'Partner Support',
        },
        {
          value: 'PF',
          label: 'Partner Finance',
        },
        {
          value: 'MA',
          label: 'Marketing',
        },
        {
          value: 'EN',
          label: 'Endorser'
        },
        {
          value: 'AP',
          label: 'Approver'
        },
        {
          value: 'FI',
          label: 'Finance'
        },
        {
          value: 'KC',
          label: 'KYC Support'
        },
        {
          value: 'KB',
          label: 'KYB Support'
        },
        {
          value: 'BS',
          label: 'BUx Support'
        },
        {
          value: 'SD',
          label: 'Sales Admin'
        },
      ];

    const handleChange = event => {
        setRole(event.target.value);
      };

    const emailChange = e => {
        setEmail(e.target.value);
        setVisible(true);
    }


    const openModal = () => {
    setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const errorMessageObj = (visible, message) => {
      setVisible(visible)
      setMessage(message)
    }

    const register = () => {

        if (email === "" || email === null){
          errorMessageObj(false, 'Email is required')
        }

        else if (username === "" || username === null){
          errorMessageObj(false, 'Username is required')
        }

        else if(fname === "" || fname === null){
          errorMessageObj(false, 'First name is required')
        }

        else if(lname === "" || lname === null){
          errorMessageObj(false, 'Last name is required')
        }

        else if(password === "" || password === null){
          errorMessageObj(false, 'Must enter your password')
        }

        else if(password.length > 128) {
          errorMessageObj(false, 'Password exceeded to max limit of 128 characters')
        }

        else if(password.search(/[0-9]/) === -1) {
          errorMessageObj(false, 'Password must contain at least 1 number')
        }

        else if(password.search(/[A-Z]/) === -1) {
          errorMessageObj(false, 'Password must contain at least 1 capital letter')
        }

        else if(password.search(/[a-z]/) === -1) {
          errorMessageObj(false, 'Password must contain at least 1 small letter')
        }

        else if(confirmPassword === "" || confirmPassword === null){
          errorMessageObj(false, 'Please confirm your password')
        }

        else if(! secureCompare(password, confirmPassword)) {
          errorMessageObj(false, 'Passwords do not match')
        }

        else {
                  
            let info = {
                role: role,
                email: email,
                username: username,
                firstName: fname,
                lastName: lname,
                password: password,
                confirmPassword: confirmPassword
            };

            registerUser(info)
            console.log(info)

        }
    }

    function secureCompare (a, b) {
        // https://github.com/vadimdemedes/secure-compare/blob/master/index.js
        if (typeof a !== 'string' || typeof b !== 'string') return false;
        
        var mismatch = a.length === b.length ? 0 : 1;
        if (mismatch) {
            b = a;
        }
        
        for (var i = 0, il = a.length; i < il; ++i) {
            mismatch |= (a.charCodeAt(i) ^ b.charCodeAt(i));
        }
        
        return mismatch === 0;
    }

    async function registerUser (value) {
        let yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }
  
        let response = await axios.post(
          process.env.REACT_APP_API_URL + '/api/add_user/', value,yourConfig
        );
  
        console.log(response)
  
        if(response.data.status == "success"){
            window.location.reload()
        }
        else{
          setVisible(false)
          setMessage(response.data.message)
        }
    }

    return (

        <div className="right-16">

            <button 
              onClick={openModal} 
              className="add-user">
              <Icon type="plus" className="right-4" /> Add User
            </button>

            <Modal
                title="Add User"
                centered
                visible={modalOpen}
                //onOk={}
                onCancel={closeModal}
                footer={[
                    <Button key="back" onClick={closeModal} className="admin-download-btn right-8">
                      Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={register} className="add-user">
                      Add
                    </Button>,
                  ]}
                >

                <Grid container>
                    <div hidden={visible} style={{width: '100%'}} >
                      <Alert message={message} type="error" showIcon/>

                      </div>

                    <Grid item lg={12}>

                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Role"
                        className={classes.textField}
                        value={role}
                        onChange={handleChange}
                        SelectProps={{
                            native: true,
                            MenuProps: {
                            className: classes.menu,
                            },
                        }}
                        margin="normal"
                        variant="outlined"
                        >
                        {roles.map(option => (
                            <option key={option.value} value={option.value}>
                            {option.label}
                            </option>
                        ))}
                    </TextField>
                    </Grid>

                    <Grid item lg={6} sm={12}>
                        <TextField
                            id="outlined-basic"
                            label="Email"
                            margin="normal"
                            variant="outlined"
                            className={classes.textField}
                            onChange={emailChange}
                            fullWidth
                            />
                    </Grid>

                    <Grid item lg={6} sm={12}>
                        <TextField
                            id="outlined-basic"
                            label="Username"
                            margin="normal"
                            variant="outlined"
                            className={classes.textField}
                            onChange={e => setUsername(e.target.value)}
                            fullWidth
                            />
                    </Grid>

                    <Grid item lg={6} sm={12}>
                        <TextField
                            id="outlined-basic"
                            label="First Name"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                            onChange={ e => setFname(e.target.value)}

                            />
                    </Grid>
                    
                    <Grid item lg={6} sm={12}>
                        <TextField
                            id="outlined-basic"
                            label="Last Name"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                            onChange={ e => setLname(e.target.value)}

                            />
                    </Grid>
                    

                    <Grid item lg={12} sm={12}>
                    <TextField
                        id="outlined-adornment-password"
                        variant="outlined"
                        onChange={e => setPassword(e.target.value)}
                        style = {{
                            height: '30px',
                            width: '100%',
                            marginBottom: '8%'
                        }}
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        aria-label="toggle password visibility"
                                        onClick={e => setShowPassword(!showPassword)}
                                        onMouseDown={e => setShowPassword(showPassword)}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                    </InputAdornment>
                                ),
                                }}
                            />
                    </Grid>

                    <Grid item lg={12} sm={12}>
                    <TextField
                        id="outlined-adornment-password"
                        variant="outlined"
                        onChange={e => setConfirm(e.target.value)}
                        style = {{
                            height: '30px',
                            width: '100%',
                            marginBottom: '30px'
                        }}
                        type={showConfirmPassword ? 'text' : 'password'}
                        label="Confirm Password"
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        aria-label="toggle password visibility"
                                        onClick={e => setConfirmPassword(!showConfirmPassword)}
                                        onMouseDown={e => setConfirmPassword(showConfirmPassword)}
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                    </InputAdornment>
                                ),
                                }}
                            />
                    </Grid>

   
                </Grid>
                    

                </Modal>
        </div>
    )
}

export default AddUsers;


