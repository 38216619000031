import React from 'react'
import { Button, Icon, Card } from 'antd'
import masterLogo from '../../static/icons/master_card.svg'
import paypalLogo from '../../static/icons/paypal.svg'
import {history} from '../../store/history'


class Billing extends React.Component{

    cardNoStyle = {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#000000',
    }
    cardTypeStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33',
    }
    linkBtn = {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33',
        height: '40px',
        backgroundColor: '#D1D5DD'
    }
    
    render(){
        return (
            <div style={{width: '70%', padding: '48px 52px 0px 52px'}}>
                <Card 
                    size="small" 
                    style={{marginBottom: '20px', width: '400px'}}
                    hoverable="true"
                    onClick = {() => history.push('/card_detail')}
                >
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex'}}>
                            <img src={masterLogo} alt="#"/>
                            <div style={{marginLeft: '10px'}}>
                                <div style={this.cardNoStyle}>**** 8848</div>
                                <div style={this.cardTypeStyle}>Debit</div>
                            </div> 
                        </div>
                        <Icon style={{float: 'right'}} type="delete"/>  
                    </div>
                </Card>
                <Card 
                    size="small" 
                    style={{marginBottom: '20px', width: '400px'}}
                    hoverable="true"
                    onClick = {() => history.push('/card_detail')}
                >
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex'}}>
                            <img src={paypalLogo} alt="#"/>
                            <div style={{marginLeft: '10px'}}>
                                <div style={this.cardNoStyle}>**** 4351</div>
                                <div style={this.cardTypeStyle}>Prepaid</div>
                            </div> 
                        </div>
                        <Icon style={{float: 'right'}} type="delete"/>  
                    </div>
                </Card>
                <Button style={this.linkBtn} onClick={()=> history.push('/link_credit_card/')}><Icon type="plus"/>Link a credit card</Button>
            </div>   
        )
    }
}

export default Billing