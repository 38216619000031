import React, {useEffect} from 'react';
import axios from 'axios'
import enterpriseStyle from '../../../static/css/Enterprise';
import {Input, Select, message, Spin} from 'antd';
import {NATURE_BUSINESS, ADDRESS} from '../../constants/constants';
import {FB, IG} from '../../../static/icons/index'
import BusinessDocument from './businessDocument';
import {Grid, Step} from '@material-ui/core';

const BusinessInformation = props => {

    const {Option} = Select;
    const {TextArea} = Input;

    const es = enterpriseStyle();

    const [step, setStep] = React.useState(1);
    const [loading, setLoading] = React.useState(false)
    const [businessName, setBusinessName] = React.useState('');
    const [businessType, setBusinessType] = React.useState(1);
    const [businessWebsite, setBusinessWebsite] = React.useState('');
    const [fbPage, setFbPage] = React.useState('');
    const [igPage, setIgPage] = React.useState('');
    const [natureBusiness, setNatureBusiness] = React.useState(1);
    const [descriptionBusiness, setDescription] = React.useState('');
    const [address1, setAddress1] = React.useState('');
    const [address2, setAddress2] = React.useState('');
    const [provinceAddress, setProvinceAddress] = React.useState("")
    const [cityAddress, setCityAddress] = React.useState("")
    const [postalCode, setPostalCode] = React.useState("")
    const [remarks, setRemarks] = React.useState("")
    const country = "Philippines"
    const [disable, setDisable] = React.useState(false)
    const [createdAt, setCreatedAt] = React.useState("")

    const BusinessType = ['Sole Proprietor', 'Corporate']

    function disabledBtn(){
        
        if(businessName == "" || businessWebsite == "" || descriptionBusiness == "" ||
        address1 == "" || address2 == "" || provinceAddress == "" || cityAddress == ""
        || postalCode == ""){
            setDisable(true)
        }

        else{
            setDisable(false)
        }
    }
    function submitBusInfo(){
        let params = {
            business_name: businessName,
            business_type: businessType,
            website: businessWebsite,
            facebook: fbPage,
            instagram: igPage,
            nature_of_business: natureBusiness,
            description: descriptionBusiness,
            address_line_1: address1,
            address_line_2: address2,
            address_state: provinceAddress,
            address_city: cityAddress,
            address_postal_code: postalCode,
            step: step == 6 ? 7 : 2,
            country,
        }

        submitStep1(params)
    
    }

    async function fetchProfile(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let userId = localStorage.getItem('userId')
        let url = process.env.REACT_APP_API_URL + `/api/kyb/${userId}`
        
        try {
            let response = await axios.get(url,yourConfig)
        
            setBusinessName(response.data.business_name)
            setBusinessType(response.data.business_type)
            setBusinessWebsite(response.data.website)
            setFbPage(response.data.facebook)
            setIgPage(response.data.instagram)
            setNatureBusiness(response.data.nature_of_business)
            setDescription(response.data.description)
            setAddress1(response.data.address_line_1)
            setAddress2(response.data.address_line_2)
            setCityAddress(response.data.address_city)
            setProvinceAddress(response.data.address_state)
            setPostalCode(response.data.address_postal_code)
            setStep(response.data.step)
            setCreatedAt(response.data.created_at)
            setRemarks(response.data.remarks)

            console.log(response)
            if(response.data.step == 5 || response.data.step >= 10){
                setLoading(false)
                window.location.href = '/dashboard'
            }

            if(response.status){
                setLoading(true)
            }
        }

        catch(error){
            console.log(error)
            setLoading(true)
            //message.error("Unable to proceed. Please try again");
        }
        
    }


    async function submitStep1(val){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        
        try{
            let url = process.env.REACT_APP_API_URL + "/api/kyb/";
            
            let response;          

            if(createdAt == ""){
                response = await axios.post(url,val,yourConfig) 
                window.location.reload() 
            }

            else{
                response = await axios.put(url,val,yourConfig)
            }

            console.log(response)

            if(response.status == 201 || response.status == 200){
                addStep()
            }

            
        }
        
        catch(error){
            console.log(error)
            message.error("Unable to proceed. Please try again");
        }
    }

    function setBack(){
        if(step == 2){
            setStep(1)
        }

        else if(step == 3){
            setStep(2)
        }

        else if(step == 4){
            setStep(3)
        }
        else if(step == 7){
            setStep(6)
        }
        else if(step == 8){
            setStep(7)
        }
        else if(step == 9){
            setStep(8)
        }
    }

    function addStep(){
        if(step == 1){
            setStep(2)
        }
        else if(step == 2){
            setStep(3)
        }
        else if(step == 3){
            setStep(4)
        }
        else if(step == 6){
            setStep(7)
        }
        else if(step == 7){
            setStep(8)
        }
        else if(step == 8){
            setStep(9)
        }
    }


    function handleProvinceAddress(value) {
        setProvinceAddress(value)
        setCityAddress("")   
    }

    function handleNatureBusiness(value){
        setNatureBusiness(value)
    }

    function handleBusinessType(value){
        setBusinessType(value);
    }

    function getProvinces() {
        let provinces = []
        for(var i in ADDRESS){
            provinces.push(<Option value={i}>{i}</Option>);
        }
        return provinces
    }

    function getCities() {
        let cities = []
        if(provinceAddress in ADDRESS){
            for(var i in ADDRESS[provinceAddress]['Municipality_list']){
                cities.push(<Option value={i}>{i}</Option>);
            }
        }
        return cities
    }

    function handleCityAddress(value) {
        setCityAddress(value)

    }

    useEffect(() => {
        fetchProfile()
        disabledBtn()
    }, [])


    return(
        <div>
            <div align="center" style={{margin: 0,
                position: 'absolute',
                top: '80%',
                left: '50%',
                transform: 'translateY(50%, 50%)'}} hidden={loading == true}>
                <Spin size="large" />
            </div> 
                
            <div className={es.step} align='left' hidden={step >= 5 || loading == false}>
                Step {step} of 3
            </div>
            

            <div className={es.rejectedCard} hidden={step <= 5 || step >= 10}>
                <span style={{fontSize: '18px', color: '#2b2d33'}}>Upgrade Application was declined</span>
                <div style={{marginTop: 16}}>
                We’re sorry for any inconvenience but we cannot proceed with your application due to inconsistencies with the submitted information/documents. 
                
                <div style={{marginTop: 16}}>Kindly check your submitted information and resubmit it.</div>
                <div style={{marginTop: 16}}>Remarks: {remarks}</div>
                </div>
            </div>
            
            <div hidden={(step > 1 && step != 6) || loading == false}>
            <div className={es.biMain}>
                <div className={es.biHeader}>Business Information</div>
                
                <div className={es.biFields}>
                    <div className={es.biPlaceholder}>
                        Business Type
                    </div>
                    
                    <div className={es.biInput}>
                    { 
                        localStorage.getItem("userType") == 'AD' ?

                        <div> {businessType} </div>

                        :

                        <Select size="large" style={{width: '100%'}}
                        onChange={handleBusinessType} value={BusinessType[businessType-1]}>
                            <Option value={1}>Sole Proprietor</Option>
                            <Option value={2}>Enterprise</Option>
                        </Select> 
                    }
              
                    </div>

                    <div className={es.biPlaceholder}>
                        Business Name
                    </div>
                    
                    <div className={es.biInput}>
                    { 
                        localStorage.getItem("userType") == 'AD' ?

                        <div> {businessName} </div>

                        :

                        <Input size="large" value={businessName} onChange={e => setBusinessName(e.target.value)}/>

                    }
                    </div>

                    <div className={es.biPlaceholder}>
                        Business Website
                    </div>
                    
                    <div className={es.biInput}>
                    { 
                        localStorage.getItem("userType") == 'AD' ?

                        <div><a href={businessWebsite} target="_blank"> {businessWebsite}</a> </div>

                        :

                        <Input size="large" placeholder="http://www.businessname.com" value={businessWebsite} onChange={e => setBusinessWebsite(e.target.value)}/>
                    }

                    </div>

                    <div className={es.biInput}>
                        Link social media (Optional)
                    </div>

                    <div className={es.biPlaceholder}>
                        Facebook
                    </div>
                    
                    <div className={es.biInput}>
                    { 
                        localStorage.getItem("userType") == 'AD' ?

                        <div> <a href={`https://www.facebook.com/${fbPage}`} target="_blank">{fbPage} </a></div>

                        :

                        <Input addonBefore={
                            <div> <img src={FB}/> www.facebook.com/</div>
                        } size="large" placeholder="your-facebook-page" 
                        value={fbPage} onChange={e => setFbPage(e.target.value)}/>
                    }
                    </div>

                    <div className={es.biPlaceholder}>
                        Instagram
                    </div>
                    
                    <div className={es.biInput}>
                    { 
                        localStorage.getItem("userType") == 'AD' ?

                        <div> <a href={`https://www.instagram.com/${igPage}`} target="_blank">{igPage} </a></div>

                        :
                        <Input addonBefore={
                            <div> <img src={IG}/> www.instagram.com/</div>
                        } size="large" 
                        value={igPage} 
                        placeholder="your-instagram-handle" onChange={e => setIgPage(e.target.value)}/>
                    }
                    </div>

                    <div className={es.biPlaceholder}>
                        Nature of Business
                    </div>
                    <div className={es.biInput}>

                    { 
                        localStorage.getItem("userType") == 'AD' ?

                        <div> {natureBusiness}</div>

                        :

                        <Select
                            showSearch
                            style={{ width: '100%', height: '40px' }}
                            size="large"
                            onChange={handleNatureBusiness}
                            value={NATURE_BUSINESS[natureBusiness-1]}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                        {
                                NATURE_BUSINESS.length > 0 && NATURE_BUSINESS.map((item, i) => {
                                return (
                                <Option key={i} value={i}>{item}</Option>
                                )
                            }, this)
                        }
                        </Select>
                    }

                    </div>

                    <div className={es.biPlaceholder}>
                        Description of Business
                    </div>
                    
                    <div className={es.biInput}>
                    { 
                        localStorage.getItem("userType") == 'AD' ?

                        <div> {descriptionBusiness} </div>

                        :
                        <TextArea rows={4} size="large" 
                        value={descriptionBusiness} 
                        placeholder="Description ..." onChange={e => setDescription(e.target.value)}/>
                    }
                    </div>

                    <div className={es.bimainPlaceHolder}>
                        Business Address
                    </div>

                    <div className={es.biPlaceholder}>
                        Line 1
                    </div>
                    
                    <div className={es.biInput}>
                    { 
                        localStorage.getItem("userType") == 'AD' ?

                        <div> {address1} </div>

                        :
                        <Input size="large" 
                        value={address1} onChange={e => setAddress1(e.target.value)}/>
                    }
                    </div>

                    <div className={es.biPlaceholder}>
                        Line 2
                    </div>
                    
                    <div className={es.biInput}>
                    { 
                        localStorage.getItem("userType") == 'AD' ?

                        <div> {address2} </div>

                        :
                        <Input size="large" value={address2} onChange={e => setAddress2(e.target.value)}/>
                    }
                    </div>
                    
                    <Grid container>
                        <Grid item lg={6} xs={12}>
                            <div className={es.biPlaceholder}>
                                State
                            </div>

                            { 
                                localStorage.getItem("userType") == 'AD' ?

                                    <div> {provinceAddress} </div>

                                    :
                                    <Select style={{width: 260}}
                                    size="large" value={provinceAddress} onChange={handleProvinceAddress}>
                                        {
                                            getProvinces()
                                        }
                                    </Select>
                            }

                        </Grid>


                        <Grid item lg={6} xs={12}>
                            <div className={es.biPlaceholder}>
                                City
                            </div>
                            { 
                                localStorage.getItem("userType") == 'AD' ?

                                    <div style={{marginRight: '32px'}}> {cityAddress} </div>

                                    :

                                <Select style={{width: 260}}
                                size="large" value={cityAddress} onChange={handleCityAddress} disabled={provinceAddress == ""}>
                                    {
                                        getCities()
                                    }
                                </Select>
                            }
                        </Grid>

                    </Grid>

                    <Grid container>
                        <Grid item lg={6} xs={12}>
                            <div className={es.biPlaceholder}>
                                Postal Code
                            </div>

                            { 
                                localStorage.getItem("userType") == 'AD' ?

                                    <div> {postalCode} </div>

                                    :

                            <Input style={{width: 260}} value={postalCode} size="large" onChange={e => setPostalCode(e.target.value)}/>
                            }

                        </Grid>

                        <Grid item lg={6} xs={12}>
                            <div className={es.biPlaceholder}>
                                Country
                            </div>

                            <input className={es.inputReadOnly} style={{zIndex: '0'}} size="large" disabled value={country} />                        

                        </Grid>

                    </Grid>
                </div>
            
            </div>
                                
            <button className={es.submit} style={{opacity: businessName == "" || businessWebsite == "" || descriptionBusiness == "" ||
        address1 == "" || address2 == "" || provinceAddress == "" || cityAddress == ""
        || postalCode == "" ? '0.5' : '', cursor: 
            businessName == "" || businessWebsite == "" || descriptionBusiness == "" ||
            address1 == "" || address2 == "" || provinceAddress == "" || cityAddress == ""
            || postalCode == "" ? 'not-allowed' : 'pointer'}} 
                onClick={() => submitBusInfo()} onChange={()=> disabledBtn()} disabled={businessName == "" || businessWebsite == "" || descriptionBusiness == "" ||
                address1 == "" || address2 == "" || provinceAddress == "" || cityAddress == ""
                || postalCode == ""}  hidden={localStorage.getItem("userType") == 'AD'}>Submit and Next</button>
            </div>

            <div hidden={step == 1}>
                <BusinessDocument step={step} businessType={businessType} setBack={setBack} addStep={addStep} header={es.biHeader}/>
            </div>
        </div>
    )
}

export default BusinessInformation;