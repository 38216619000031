import React from 'react';
import axios from 'axios';
import { Modal, Button, message } from 'antd';
import { Grid, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Visibility from '@material-ui/icons/Visibility';

const ChangePasswordModal = props => {

    const {
        openModal, setOpenModal, data
    } = props;

    const [newPassword, setNewPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);

    const errorMessage = (text) => {
        message.destroy()
        message.error(text, 3)
    }

    const changePassword = () => {
        let params = {
          userId: data.id,
          newPassword,
        }
    
        if(confirmPassword == newPassword){
            changePasswordUser(params)
        }
        else{
            errorMessage("Passwords must match")
        }
    }

    async function changePasswordUser (value){
        let yourConfig = {
          headers: {
              Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }
    
        let response = await axios.post(
          process.env.REACT_APP_API_URL + '/api/admin/change_password/',value,yourConfig
        );
    
        if(response.data.status === "success"){
          message.success(response.data.message)
          window.location.reload()
        }
        else{
          errorMessage(response.data.message)
        }
      }

    return(
        <Modal
            title="Edit User"
            centered
            visible={openModal}
            onCancel={() => setOpenModal(false)}
            footer={[
                <Button key="back" onClick={() => setOpenModal(false)} className="admin-download-btn">
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={()=> changePassword()} className="add-user">
                    Save
                </Button>,
            ]}>
            <Grid container>
            
                <Grid item lg={12} xs={12}>
                    <TextField
                        id="outlined-adornment-password"
                        variant="outlined"
                        onChange={e => setNewPassword(e.target.value)}
                        type={showNewPassword ? 'text' : 'password'}
                        style={{marginBottom: 10}}
                        label="New password"
                        fullWidth
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                aria-label="toggle password visibility"
                                onClick={e => setShowNewPassword(!showNewPassword)}
                                onMouseDown={e => setShowNewPassword(showNewPassword)}
                            >
                                {showNewPassword ? <Visibility /> : <VisibilityOutlinedIcon />}
                            </IconButton>
                            </InputAdornment>
                        ),
                        }}
                    />
                </Grid>

                <Grid item lg={12} xs={12}>
                    <TextField
                        id="outlined-adornment-password"
                        variant="outlined"
                        onChange={e => setConfirmPassword(e.target.value)}
                        type={showConfirm ? 'text' : 'password'}
                        label="Confirm password"
                        fullWidth
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                aria-label="toggle password visibility"
                                onClick={e => setShowConfirm(!showNewPassword)}
                                onMouseDown={e => setShowConfirm(showNewPassword)}
                            >
                                {showConfirm ? <Visibility /> : <VisibilityOutlinedIcon />}
                            </IconButton>
                            </InputAdornment>
                        ),
                        }}
                    />
                </Grid>
            </Grid>
        </Modal>
    )
}

export default ChangePasswordModal;

