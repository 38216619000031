import { TooltipActionTypes } from "./appBarFormAction";
const initialState = {
  generateTooltip: false,
  contactUsTooltip: false,
  testMode: false,
};
export default function tooltipReducer(state = initialState, action) {
  switch (action.type) {
    case TooltipActionTypes.SHOW_GENERATE_TOOLTIP:
      return {
        ...state,
        generateTooltip: true,
        contactUsTooltip: false
      };
    case TooltipActionTypes.SHOW_CONTACTUS_TOOLTIP:
      return {
        ...state,
        generateTooltip: false,
        contactUsTooltip: true
      };
    case TooltipActionTypes.HIDE_TOOLTIP:
      return {
        ...state,
        generateTooltip: false,
        contactUsTooltip: false
      };
    case TooltipActionTypes.SET_TEST_MODE:
      return {
        ...state,
        testMode: !state.testMode,
      };
    default:
      return initialState;
  }
}