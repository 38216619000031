import React from 'react';
import SubwalletBalanceCards from '../../../components/multiwallet/components/subwallet_page/components/subwallet_balance_card';
import TranxHistoryTable from '../../../components/multiwallet/components/subwallet_page/components/table';
import { getLatestBalance } from '../../../layouts/Main';
import { history } from '../../../store/history';
import { isMobile } from 'react-device-detect';
import TestModeBanner from '../../../components/cards/test_mode/testModeBanner';

const SubwalletMainPage = () => {
    const [buxBal, setBal] = React.useState({ buxBalance: 0, floatBalance: 0 });
    const isSubDomain = process.env.REACT_APP_ROOT_URL != window.location.hostname;
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const [loggedIn, setLoggedIn] = React.useState(false);
    const [transferModalShow, setTransferModalShow] = React.useState(false);

    const segments = localStorage.getItem('segments');

    const getBalance = async () => {
        try {
            const res = await getLatestBalance();
            console.log(res)
            if (res) {
                setBal({
                    buxBalance: res.balance,
                    floatBalance: res.float_balance
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const redirectPage = () => {
        let current_path = window.location.pathname
        if (localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null) {
            history.push('/login?next=' + current_path)
        } else if (!['SW'].includes(localStorage.getItem("userType"))) {
            history.push('/dashboard')
        } else if (isMobile && isSubDomain) {
            history.push('/mobile/bux_wallet')
        } else if (isMobile) {
            history.push('/mobile/multiwallet')
        } else {
            getBalance();
            setTimeout(() => {
                setLoggedIn(true);
            }, 500);
        }
    }

    React.useEffect(() => {
        redirectPage();
    }, [])

    return (
        <div style={{ paddingTop: '56px' }} className="padding-right-32">
            <TestModeBanner width={'100vw'} top={64} right={0} hidden={!testMode} />
            <SubwalletBalanceCards segments={segments} balance={buxBal} transferModalShow={transferModalShow} setTransferModalShow={setTransferModalShow} />
            <TranxHistoryTable segments={segments} buxBalance={buxBal} onSuccessTransfer={() => getBalance()} loggedIn={loggedIn} transferModalShow={transferModalShow} setTransferModalShow={setTransferModalShow} />
        </div>
    )
}

export default SubwalletMainPage;
