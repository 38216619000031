import React, {useEffect, useState, useRef} from 'react';
import {Checkbox, Col, Collapse, Drawer, Icon, message, Row} from 'antd';
import feeStyle from '../style';
import settingServices from '../../../account_settings/settingServices';

const PermissionsDrawer = (props) => {
    const {
        isPermissionsDrawerVisible,
        setIsPermissionsDrawerVisible,
        pUid
    } = props;
    const { Panel } = Collapse;
    const fee = feeStyle();
    const userType = localStorage.getItem('userType');
    const parentID = (userType === 'CO' || userType === "ME") ? localStorage.getItem('userId') : pUid;
    const CORE_SETTINGS = [
        'dashboard_access',
        'integration_access',
        'payment_request_access',
        'payout_access',
        'reconciliation_access',
        'settings_access',
    ]

    const permissionsSnapshot = useRef([]);
    const [aliasPermissions, setAliasPermissions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [arePermissionsModified, setArePermissionsModified] = useState(false);


    async function loadAliasPermissions() {
        try {
            setIsLoading(true);

            let response = await settingServices.getAliasPermissions(parentID);
            const permissionsData = response.data;

            permissionsSnapshot.current = permissionsData.map(alias => {return {...alias}});
            setAliasPermissions(permissionsData);
        } catch (err) {
            message.error(err.message);
        } finally {
            setIsLoading(false);
        }
    }


    async function savePermission(alias_id, setting_name, isChecked) {
        setIsLoading(true);

        let params = {
            alias_id,
            'name': setting_name,
            'value': isChecked ? "True" : "False",
            'type': 'bool'
        }

        try {
            let response = await settingServices.saveAliasPermissions(parentID, params);
            if (response.status === 'failed') {
                throw new Error(response.message);
            }

        } catch (err) {
            message.error(err.message);
        } finally {
            setIsLoading(false);
        }
    }


    function checkPermissionsBeforeSaving() {
        for (const alias of aliasPermissions) {
            const snapshotCounterpart = permissionsSnapshot.current.find(snapshot => snapshot.alias_id === alias.alias_id);

            for (const setting_name of Object.keys(alias)) {
                if (snapshotCounterpart[setting_name] !== alias[setting_name]) {
                    savePermission(alias.alias_id, setting_name, alias[setting_name]);
                }
            }
        }
    }


    function handleCheckChange(alias_id, setting_name, isChecked) {
        let newPermissions = aliasPermissions.map(alias => {return {...alias}});
        let affected_alias = newPermissions.find(alias => (alias.alias_id === alias_id));
        affected_alias[setting_name] = isChecked;

        let wereCorePermissionsEmptied = ! CORE_SETTINGS.map(setting_name => affected_alias[setting_name])
                                                        .some(setting_val => !!setting_val);
        if (wereCorePermissionsEmptied) {
            message.error("At least one box must be checked for the options on the left side.");
            return;
        }

        setArePermissionsModified(true);
        setAliasPermissions(newPermissions);
    }


    useEffect(() => {
        if (! isPermissionsDrawerVisible) {
            return;
        }

        setArePermissionsModified(false);
        loadAliasPermissions();
    }, [isPermissionsDrawerVisible]);


    return(
        <Drawer
            style={{overflowY: 'hidden'}}
            placement="left"
            width="100vw"
            height="100vh"
            closable
            onClose={() => setIsPermissionsDrawerVisible(false)}
            visible={isPermissionsDrawerVisible}
            title={
                <div align="center"style={permissions_modal_styles.headerText}>
                    Edit Alias Permissions
                </div>
            }
        >
            <div>
                <Collapse
                    accordion
                    style={permissions_modal_styles.collapse}
                    expandIconPosition="right" 
                    expandIcon={({isActive}) => 
                        <Icon style={{color: '#F5922F', paddingRight: 32}} type="up" rotate={isActive ? 0 : 180} />
                    }
                >
                    {
                        aliasPermissions.map((alias_user, index) => {
                            return (
                                <Panel 
                                    header={
                                        <div>
                                            <div><b>{alias_user.full_name}</b></div>
                                            <div style={{color: "#505050"}}>{alias_user.email}</div>
                                        </div>
                                    }
                                    key={index}
                                >
                                    <div style={permissions_modal_styles.panelDesc}>
                                        <Row>
                                            <Col span={12}>
                                                <Checkbox
                                                    style={{margin: '1vh 0px'}}
                                                    checked={alias_user.dashboard_access}
                                                    onChange={(e) => handleCheckChange(alias_user.alias_id, 'dashboard_access', e.target.checked)}
                                                    disabled={alias_user.has_merchant_bias}
                                                >
                                                    Dashboard
                                                </Checkbox>
                                            </Col>
                                            <Col span={12}>
                                                <Checkbox
                                                    style={{margin: '1vh 0px'}}
                                                    checked={alias_user.test_mode_access}
                                                    onChange={(e) => handleCheckChange(alias_user.alias_id, 'test_mode_access', e.target.checked)}
                                                    disabled={alias_user.has_merchant_bias}
                                                >
                                                    Test Mode
                                                </Checkbox>
                                            </Col>
                                            <Col span={12}>
                                                <Checkbox
                                                    style={{margin: '1vh 0px'}}
                                                    checked={alias_user.payment_request_access}
                                                    onChange={(e) => handleCheckChange(alias_user.alias_id, 'payment_request_access', e.target.checked)}
                                                    disabled={alias_user.has_merchant_bias}
                                                >
                                                    Payment Request
                                                </Checkbox>
                                            </Col>
                                            <Col span={12}>
                                                <Checkbox
                                                    style={{margin: '1vh 0px'}}
                                                    checked={alias_user.settlement_reports_visibility}
                                                    onChange={(e) => handleCheckChange(alias_user.alias_id, 'settlement_reports_visibility', e.target.checked)}
                                                    disabled={alias_user.has_merchant_bias}
                                                >
                                                    Settlement Reports
                                                </Checkbox>
                                            </Col>
                                            <Col span={12}>
                                                <Checkbox
                                                    style={{margin: '1vh 0px'}}
                                                    checked={alias_user.integration_access}
                                                    onChange={(e) => handleCheckChange(alias_user.alias_id, 'integration_access', e.target.checked)}
                                                    disabled={alias_user.has_merchant_bias}
                                                >
                                                    Integration
                                                </Checkbox>
                                            </Col>
                                            <Col span={12}>
                                                <Checkbox
                                                    style={{margin: '1vh 0px'}}
                                                    checked={alias_user.qr_settings_alteration}
                                                    onChange={(e) => handleCheckChange(alias_user.alias_id, 'qr_settings_alteration', e.target.checked)}
                                                    disabled={alias_user.has_merchant_bias}
                                                >
                                                    Alter QR Settings
                                                </Checkbox>
                                            </Col>
                                            <Col span={12}>
                                                <Checkbox
                                                    style={{margin: '1vh 0px'}}
                                                    checked={alias_user.payout_access}
                                                    onChange={(e) => handleCheckChange(alias_user.alias_id, 'payout_access', e.target.checked)}
                                                    disabled={alias_user.has_merchant_bias}
                                                >
                                                    Payout
                                                </Checkbox>
                                            </Col>
                                            <Col span={12}>
                                                <Checkbox
                                                    style={{margin: '1vh 0px'}}
                                                    checked={alias_user.payment_link_creation}
                                                    onChange={(e) => handleCheckChange(alias_user.alias_id, 'payment_link_creation', e.target.checked)}
                                                    disabled={alias_user.has_merchant_bias}
                                                >
                                                    Create Payment Links
                                                </Checkbox>
                                            </Col>
                                            <Col span={24}>
                                                <Checkbox
                                                    style={{margin: '1vh 0px'}}
                                                    checked={alias_user.reconciliation_access}
                                                    onChange={(e) => handleCheckChange(alias_user.alias_id, 'reconciliation_access', e.target.checked)}
                                                    disabled={alias_user.has_merchant_bias}
                                                >
                                                    Reconciliation
                                                </Checkbox>
                                            </Col>
                                            <Col span={24}>
                                                <Checkbox
                                                    style={{margin: '1vh 0px'}}
                                                    checked={alias_user.settings_access}
                                                    onChange={(e) => handleCheckChange(alias_user.alias_id, 'settings_access', e.target.checked)}
                                                    disabled={alias_user.has_merchant_bias}
                                                >
                                                    Settings
                                                </Checkbox>
                                            </Col>
                                        </Row>
                                    </div>
                                </Panel>
                            );
                        })
                    }
                </Collapse>
            </div>

            <div style={permissions_modal_styles.buttonsDiv}>
                <button
                    className={fee.editBtn}
                    onClick={() => {
                        checkPermissionsBeforeSaving();
                        message.success("Settings saved.", 2);
                        setIsPermissionsDrawerVisible(false);
                    }}
                    style={{
                        width: '85vw',
                        opacity: isLoading || ! arePermissionsModified ? '0.5' : '',
                        marginBottom: '5vh'
                    }}
                    disabled={isLoading || ! arePermissionsModified}
                >
                    Save
                </button>
            </div>
        </Drawer>
    )
}

const permissions_modal_styles = {
    buttonsDiv: {
        textAlign: 'center',
    },
    headerText: {
        marginTop: 32,
        fontSize: 'large'
    },
    panelDesc: {
        paddingLeft: '1vw'
    },
    footerText: {
        fontSize: 'medium',
        textAlign: 'right',
        fontStyle: 'italic',
        paddingRight: '5px'
    },
    collapse: {
        margin: '10px 0px 30px 0px',
    },
    inactive: {
        width: "60px",
        margin: '0px 6px',
        backgroundColor: 'rgb(245, 245, 245)',
        border: '1px solid rgb(192, 192, 192)',
        fontSize: "x-small",
        padding: "2px"
    }
}

export default PermissionsDrawer;