import React, { useState } from "react";
import MetaTag from "../../../../../components/meta_tag/meta_tag";
import { Typography, Card, Button, Modal, Input, notification } from "antd";
import {
  SearchInput,
  GroupButton,
  FilterBar,
  DateRangeFilter,
} from "../../../cashbux/components";
import UsersList from "./users-list";
import { getUsers } from "../api/get-users";

function BuxTest() {
  const userTypes = ["BUx", "Test"];
  const [selectedUserType, setSelectedUserType] = useState("BUx");

  const [searchQuery, setSearchQuery] = useState("");
  const [tempSearchQuery, setTempSearchQuery] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");

  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = () => setIsOpen(false);
  const handleOk = () => {
    if (!email) return;
    setIsLoading(true);
    getUsers(
      "",
      searchQuery,
      email,
      startDate,
      endDate,
      selectedUserType,
      "All",
      selectedUserType === "BUx" ? false : true,
    )
      .then((_) => {
        setIsOpen(false);
        setEmail("");
        notification.success({
          message: "Success",
          description: "CSV sent successfully!",
        });
      })
      .catch((e) => {
        setIsOpen(false);
        setEmail("");
        notification.error({
          message: "Failed",
          description: "Something went wrong! Please try again.",
        });
      })
      .finally(() => setIsLoading(false));
  };
  const handleStatusChange = (type) => setSelectedUserType(type);
  const handleStartDateChange = (date) => setTempStartDate(date);
  const handleEndDateChange = (date) => setTempEndDate(date);
  const handleApplyFilters = () => {
    setSearchQuery(tempSearchQuery);
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
  };
  const clearFilters = () => {
    setSelectedUserType("BUx");
    setSearchQuery("");
    setTempSearchQuery("");
    setStartDate("");
    setTempStartDate("");
    setEndDate("");
    setTempEndDate("");
  };

  return (
    <div
      style={{
        margin: "0 2.5em 2.5em 2.5em",
        display: "flex",
        gap: "2em",
        flexDirection: "column",
      }}
    >
      <MetaTag title="CashBux Bank Submissions" />
      <div>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "20px",
            color: "#2B2D33",
          }}
        >
          BUx and Test accounts
        </Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <GroupButton
            options={userTypes}
            selectedOption={selectedUserType}
            onSelect={handleStatusChange}
          />
          <Button icon="download" size="large" onClick={() => setIsOpen(true)}>
            Download CSV
          </Button>
        </div>
        <Card>
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <FilterBar
              initialValue={userTypes[0]}
              selectedOption={selectedUserType}
              searchQuery={searchQuery}
              startDate={startDate}
              endDate={endDate}
              clearFilters={clearFilters}
            />
            <div style={{ display: "flex", gap: "1em" }}>
              <DateRangeFilter
                tempStartDate={tempStartDate}
                tempEndDate={tempEndDate}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                handleApplyDateFilter={false}
              />
              <SearchInput
                tempSearchQuery={tempSearchQuery}
                setTempSearchQuery={setTempSearchQuery}
                handleApplySearch={false}
                placeholder="Search by Full name, Email."
              />
              <Button onClick={handleApplyFilters}>Apply</Button>
            </div>

            <UsersList
              selectedUserType={selectedUserType}
              searchQuery={searchQuery}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </Card>
      </div>

      <Modal
        title="Export CSV"
        visible={isOpen}
        onOk={handleOk}
        confirmLoading={isLoading}
        onCancel={handleCancel}
        width={360}
        centered
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
          <Typography>
            Please enter your email address to send CSV reports directly to your
            email.
          </Typography>
          <div>
            <label style={{ fontSize: 12 }}>Send to email</label>
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default BuxTest;
