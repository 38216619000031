import React from 'react'
import axios from 'axios'
import FeeStyles from '../../style'
import HeaderCard from './headerCard'
import ApprovedCard from './approvedCard'
import {Input, message} from 'antd'

const SubMerchants = props => {
    const pUid = props.props && props.props.match && props.props.match.params.id;

    const fee = FeeStyles();
    const [partners, setPartners] = React.useState([]);

    async function getSubMerchants(){
        const yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try {

            let response = await axios.get(
                process.env.REACT_APP_API_URL + `/api/kyb/${pUid}/submerchants`,yourConfig)


                setPartners(response.data.data)
        }

        catch (error) {
            console.log(error)
        }

    }

    React.useEffect(() => {
        getSubMerchants()
    },[])

    return(
        <div align='center'> 
            <div className={fee.feeCard} align="left">
                <HeaderCard/>
                <ApprovedCard partners={partners} uid={pUid}/>
            </div>
        </div>
    )
}

export default SubMerchants