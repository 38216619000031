import React from 'react'
import axios from 'axios'
import {Modal} from 'antd'
import feeStyle from './style'


const CustomizeModal = props => {

    const fee = feeStyle()

    return(
        <Modal
        centered
        width={360}
        visible={props.showModal}
        onCancel={() => props.closeModal()}
        footer={null}>
            <div style={{
                marginTop: 32,
                fontSize: 24,
                lineHeight: '32px',
                fontWeight: '300',
                color: '#2b2d33'
            }}>Customize Fee</div>

            <div style={{
                marginTop: 20,
                fontSize: '14px',
                lineHeight: '18px',
                color: '#2b2d33'
            }}>
            Are you sure you want to update the current Channel’s Fee.
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 20
            }}>
                <div>
                    <button disabled={props.loading} className={fee.cancelBtn} onClick={() => props.closeModal()} style={{width: '150px'}}>Cancel</button>
                </div>

                <div>
                    <button disabled={props.loading} className={fee.editBtn} onClick={() => props.submitFee(props.channelsParams)} style={{width: '150px'}}>Confirm</button>
                </div>

            </div>

    </Modal>
    )
}

export default CustomizeModal