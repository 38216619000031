import React from 'react'
import {Modal, Button} from 'antd'
import {getLatestBalance} from '../../layouts/Main'
import {history} from '../../store/history'
import axios from 'axios'
import createActivityDetector from 'activity-detector';
import { SettingsRemoteRounded } from '@material-ui/icons'
import { isWidthDown } from '@material-ui/core'

const LogoutTimer = props => {

    const [isIdle, setIsIdle] = React.useState(false);
    const [seconds, setSeconds] = React.useState(300);
    const [secondsToGetBalance, setSecondsToGetBalance] = React.useState(300);
    const [disabledGetBalance, setDisabledGetBalance] = React.useState(true);
    const userType = localStorage.getItem('userType');

    const useIdle = (options) => {
        
        React.useEffect(() => {
            const activityDetector = createActivityDetector(options);
            activityDetector.on('idle', () => setIsIdle(['ME', 'CO', 'SW'].includes(userType) ? true : false));
            return() => activityDetector.stop();
        }, [])    
        return isIdle;
    }

    const activityDetector2 = createActivityDetector({
        timeToIdle: 1,
    });

    activityDetector2.on('active', () => {
        if(!disabledGetBalance){
            getLatestBalance();
            setSecondsToGetBalance(300)
            setDisabledGetBalance(true)
            activityDetector2.stop()
        }

    });


    React.useEffect(() => {

        if(isIdle) {
            const intervalId = setInterval(() => {
                setSeconds(seconds - 1);
            }, 1000);
    
            if (seconds == 0){
                clearInterval(intervalId)
                // logoutNow() <- commented this line so that user won't be auto logout
            }    
            return () => clearInterval(intervalId);
            
        }

        const intervalId2 = setInterval(() => {
            if(secondsToGetBalance != 0){
                setSecondsToGetBalance(secondsToGetBalance - 1);
            }
            else{
                setDisabledGetBalance(false)
            }
        }, 1000);

        return () => clearInterval(intervalId2);

    }, [isIdle, seconds, secondsToGetBalance])


    const secondToHMS = (d) => {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        return hDisplay + mDisplay + sDisplay; 
    }

    const idleTime = useIdle({timeToIdle: 600000, inactivityEvents: ['idle']})

    const clearLocalStorage = () => {
        localStorage.setItem("userId", "");
        localStorage.setItem("jwtToken", "");
        localStorage.setItem("firstName", "");
        localStorage.setItem("lastName", "");
        localStorage.setItem("middleName", "");
        localStorage.setItem("suffix", "");
        localStorage.setItem("username", "");
        localStorage.setItem("email", "");
        localStorage.setItem("contact", "");
        localStorage.setItem("userType", "")
        localStorage.setItem("isCompleted", "")
        localStorage.setItem("isVerified", "")
        localStorage.setItem("Step", "")
        localStorage.setItem("Level", "")
        localStorage.setItem("lastLogin", "")
        localStorage.setItem("shipping_enabled", "")
        localStorage.setItem('tradeName', "")
    }

    const logoutNow = async () => {
        try {
            let url = process.env.REACT_APP_API_URL + "/api/logout/"
            const yourConfig = {
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            } 

            let response = await axios.get(url,yourConfig)
            if (response.data.status == 'success') {  
                clearLocalStorage();
                history.push("/login/");
            }
        } 

        catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage();
                window.location.href = "/dashboard/";
            }
        }
    }
    
    const keepMeLogin = () => {
        getLatestBalance()
        setIsIdle(false)
        setSeconds(300)
    }

    return( 
        <div>
            <Modal 
                maskClosable={false}
                centered={true}
                visible={idleTime}
                footer={null}
                closable={false}
                onCancel={() =>
                    seconds === 0 && ['ME', 'CO', 'SW'].includes(userType) ? logoutNow() : keepMeLogin()
                }
                >

                    {
                        seconds > 0 ?
                            <div>
                                <div style={styles.headerText}>
                                    Hi! Are you still there?
                                </div>
                                <div style={styles.text}>
                                    To keep your account safe, we will automatically log you out if you are still inactive after <b>{secondToHMS(seconds)}.</b>
                                </div>
                                <Button data-test="keep_login" style={styles.keepBtn} 
                                    onClick={()=> keepMeLogin()}>
                                        Keep me logged in
                                </Button>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <a style={styles.logout} href
                                        onClick={()=> logoutNow()}> 
                                        Log me out now
                                    </a>
                                </div>
                            </div>
                            
                            :

                            <div>
                                 <div style={styles.headerText}>
                                    You have been logged out due to inactivity
                                </div>
                                <div style={styles.text}>
                                    Please log in again to continue using Bux. 
                                </div>
                                <Button data-test="logout" style={styles.keepBtn} onClick={()=>logoutNow()}>
                                    Ok
                                </Button>
                            </div>
                    }
            
            </Modal>
        </div>
    )
}

const styles = {
    headerText: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#2b2d33',
        lineHeight: '32px',
        margin: '20px 0px 12px 0px'
    },

    text: {
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#000',
        lineHeight: '24px'
    },

    keepBtn: {
        width: '100%',
        height: '62px',
        borderRadius: '10px',
        border: '1px solid #F5922F',
        backgroundColor: '#F5922F',
        color: '#fff',
        fontSize: '16px',
        fontWeight: '600',
        margin: '20px 0px 20px 0px'
    },

    logout: {
        fontSize: '16px',
        fontWeight: '600',
        color: '#909196',
        lineHeight: '24px'
    }
}
    

export default LogoutTimer