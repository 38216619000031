import React from 'react';
import Visa from '../../../../static/icons/card_type/visa.svg';
import MasterCard from '../../../../static/icons/card_type/mastercard.svg';
import { Card, Dropdown, List, Menu, Spin, Typography } from 'antd';
import { history } from '../../../../store/history';
import { useDispatch } from 'react-redux';
import orderActions from '../orderActions';

const TransactionCards = ({data, selected, onRefund}) => {
  const dispatch = useDispatch();

  const charged_menu = (id,uid, selected, onRefund, code, acc_no, amount, card_brand) => (
    <Menu style={{ width: '180px' }}>
      <Menu.Item key="0" onClick={() => history.push(`/payment/${uid}`)} >
        Customers Receipt
      </Menu.Item>
      <Menu.Item key="1" onClick={() => history.push(`/mobile/charged_details/${id}/${uid}/`)}>
        Charge Details
      </Menu.Item>
      <Menu.Item hidden={selected==='Refunded'} key="3" onClick={() => {onRefund();dispatch(orderActions.setRefundDetails({id, uid, code, acc_no, amount, card_brand}))}}>
        Issue Refund
      </Menu.Item>
    </Menu>
  )

  return (
    data ? 
    <Card size='small' style={styles.card} bodyStyle={{padding: 0}}>
      <div style={styles.upper}>
        <div>
          <Typography style={styles.refCode} >Charged ID / Reference Code</Typography>
          <Typography style={{...styles.boldText, padding: '0 0 10px 0'}}>{data.code}</Typography>
          <div style={{display: 'flex'}}>
            <img alt='No Image' src={data.payload.card_brand==='VISA' ? Visa : MasterCard } />
            <Typography style={{...styles.boldText, paddingLeft: 16}}>•••• {data.payload.last_four_digits}</Typography>
          </div>
        </div>
        <Dropdown overlay={() => charged_menu(data.id, data.uid, selected, onRefund, data.code, data.payload.last_four_digits, data.amount, data.payload.card_brand)} placement="bottomLeft" trigger={['click']} >
          <a style={styles.link}>...</a>
        </Dropdown>
      </div>
      <div style={styles.lower}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Typography style={{...styles.dateText, fontSize: 12}}>{selected==='Charged'?'Date Paid':'Date Refunded'}</Typography>
          <Typography style={styles.dateText}>{selected === 'Charged'? data.date_paid : data.cancelled_at}</Typography>
        </div>
        <Typography style={styles.amount}>{data.type === 'D'? 'PHP -' : 'PHP '} {data.amount}</Typography>
      </div>
    </Card>
    : null
  )
}
 
const Cards = ({data, selected, onRefund}) => {
  return (
    data&&
    <List 
      loading={!data}
      dataSource={data}
      renderItem={ item => 
        <div>
          <TransactionCards data={{...item}} selected={selected} onRefund={() => onRefund()} />
        </div>
      }
    />
    )
}

const styles = {
  root: {

  },
  upper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px'
  },
  lower: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #D1D5DD',
    padding: '10px 16px 16px 16px'
  },
  card: {
    margin: '16px 0',
    borderRadius: 10,
    padding: 0
  },
  boldText: {
    color: '#2B2D33',
    fontSize: 14,
    fontWeight: '600',
  },
  dateText: {
    color: '#2B2D33',
    fontSize: 14
  },
  amount: {
    color: '#2B2D33',
    fontSize: 14,
    fontWeight: '600',
    display: 'flex',
    alignItems: 'flex-end',
  },
  link: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'flex-end',
    height: 18,
    opacity: '.5'
  },
  refCode: {
    color: '#909196',
    fontSize: 12,
  },
}

export default Cards;