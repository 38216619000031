import React from "react";
import { FormWrapper } from "../../../components/Form/forms";

export function ViewTransferDetails({ currentViewDetails }) {
  const {
    account_name,
    account_number,
    bank,
    amount,
    account_type,
    gender,
    address,
    mobile_number,
    email,
    sender_details: {
      name: sender_name,
      gender: sender_gender,
      address: sender_address,
      mobile_number: sender_mobile_number,
      email: sender_email,
      source_of_fund: sender_source_fund,
      purpose,
    },
  } = currentViewDetails;

  return (
    <FormWrapper title={`Bank Transfer Details`}>
      <div
        style={{
          width: "100%",
          padding: "2em",
          backgroundColor: "#fff",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          fontSize: "13px",
        }}
      >
        <h2>Sender details</h2>
        <div>
          <p>
            <span style={{ fontWeight: "bold" }}>Name:</span> {sender_name}
          </p>
          <p style={{ textTransform: "capitalize" }}>
            <span style={{ fontWeight: "bold" }}>Gender:</span> {sender_gender}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Address:</span>{" "}
            {sender_address}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Mobile number:</span>{" "}
            {sender_mobile_number}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Email:</span> {sender_email}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Source of fund:</span>{" "}
            {sender_source_fund}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Purpose:</span> {purpose}
          </p>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          padding: "2em",
          backgroundColor: "#fff",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          fontSize: "13px",
        }}
      >
        <h2>Receiver details</h2>
        <div>
          <p>
            <span style={{ fontWeight: "bold" }}>Amount:</span> {amount}
          </p>
          <p style={{ textTransform: "capitalize" }}>
            <span style={{ fontWeight: "bold" }}>Account type:</span>{" "}
            {account_type}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Bank:</span> {bank}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Account number:</span>{" "}
            {account_number}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Account name:</span>{" "}
            {account_name}
          </p>
          <p style={{ textTransform: "capitalize" }}>
            <span style={{ fontWeight: "bold" }}>Gender:</span> {gender}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Address:</span> {address}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Mobile number:</span>{" "}
            {mobile_number}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Email:</span> {email}
          </p>
        </div>
      </div>
    </FormWrapper>
  );
}
