import React from 'react';
import Visa from '../../../../static/icons/card_type/visa.svg';
import MasterCard from '../../../../static/icons/card_type/mastercard.svg';
import { Button, Card, Divider, Icon, Spin, Typography } from 'antd';
import MobileMain from '../../../../layouts/Mobile/MobileMain'
import { history } from '../../../../store/history';
import orderServices from '../orderServices';
import RefundModal from './refundModal';
import orderActions from '../orderActions';
import { useDispatch, useSelector } from 'react-redux';
import SuccessRefundModal from './successRefundModal';

const ChargedDetailsMobile = (props) => {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [refundModalVisible, setRefundModalVisible] = React.useState(false);
  const [successRefund, setSuccessRefund] = React.useState(false);
  const refundDetails = useSelector(state => state.mobileOrders.refundDetails);
  const dispatch = useDispatch();

  const getPaymentDetails = async () => {
    setLoading(true);
    try {
      const res = await orderServices.getPaymentDetails(props.match.params.uuid);
      // console.log(res)
      // if(res.status) {
      //   setStatus(res.status);
      // } else {
        setStatus('success');
        setData(res);
        dispatch(orderActions.setRefundDetails({id: props.match.params.id, uid: props.match.params.uuid, code: res.code, acc_no: res.payload.last_four_digits, amount: res.amount, card_brand: res.payload.card_brand}))
      // }
      setLoading(false);
    } catch (error) {
      console.log(error)
      setLoading(false);
      setStatus('failed');
    }
  }

  React.useEffect(() => {
    getPaymentDetails();
    dispatch(orderActions.setRefundDetails({id: props.match.params.id, uid: props.match.params.uuid}))
  },[])

  const PaymentNotExist = () => (
    <Card style={styles.cardRoot}>
      <Typography style={styles.title}>Payment link does not exist</Typography>
    </Card>
  )

  const CardSpinner = () => {
    return (
      <Card bodyStyle={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 480}}>
        <Spin size='large' />
      </Card>
    )
  }

  return (
    <MobileMain title="Charge Details"> 
      <div style={styles.root}>
        <RefundModal visible={refundModalVisible} onCancel={() => setRefundModalVisible(false)} onSuccess={()=> {setRefundModalVisible(false); setSuccessRefund(true)}} />
        <SuccessRefundModal visible={successRefund} closeModal={() => setSuccessRefund(false)} data={refundDetails} />
        { !loading&&status === 'success' ?
        <div>
          <div style={styles.btnContainer}>
            <Button style={styles.backBtn} onClick={() => history.goBack()}>{<Icon type="left" />}  Charged Details</Button>
            <Button hidden={status === 'failed' || data.cancelled_at} style={styles.refundBtn} onClick={() => setRefundModalVisible(true)}>Refund</Button>
          </div>
          <Card hidden={status === 'failed'} style={styles.cardRoot} bodyStyle={styles.cardBody}>
            <div style={styles.row}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography style={styles.title}>Transaction Amount:</Typography>
                <Typography style={{...styles.value, fontSize: 16, color: '#1DD28B', fontWeight: '900'}}>
                  {data.amount.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}
                </Typography>
              </div>
              <Typography style={styles.charged}>Charged</Typography>
            </div>
            <Divider style={{margin: '16px 0'}} />
            <div style={styles.row}>
              <Typography style={styles.label}>Charged ID</Typography>
              <Typography style={styles.value}>{data.code}</Typography>
            </div>
            <Divider style={{margin: '16px 0'}} />
            <div style={styles.row}>
              <Typography style={styles.label}>Bank Reconciliation ID</Typography>
              <Typography style={styles.value}>{data.payload.bank_reconciliation_id}</Typography>
            </div>
            <Divider style={{margin: '16px 0'}} />
            <div style={styles.row}>
              <Typography style={styles.label}>Charge Approval Code</Typography>
              <Typography style={styles.value}>{data.payload.approval_code}</Typography>
            </div>
            <Divider style={{margin: '16px 0'}} />
            <div style={styles.row}>
              <Typography style={styles.label}>Date Created</Typography>
              <Typography style={styles.value}>{data.created}</Typography>
            </div>
            <Divider style={{margin: '16px 0'}} />
            <div style={styles.row}>
              <Typography style={styles.label}>Merchant Descriptor</Typography>
              <Typography style={styles.value}>{data.owner}</Typography>
            </div>
            <Divider style={{margin: '16px 0'}} />
            <div style={styles.row}>
              <Typography style={styles.descTitle}>Description</Typography>
              <Typography style={styles.descValue}>{data.description}</Typography>
            </div>
          </Card>
          <Card hidden={status === 'failed' || loading} style={{...styles.cardRoot, marginTop: 20}} bodyStyle={styles.cardBody}>
            <Typography style={styles.title}>Card Information</Typography>
            <Divider style={{margin: '16px 0'}} />
            <div style={styles.row}>
              <Typography style={styles.label}>Card Number</Typography>
              <Typography style={styles.value}>•••• {data.payload.last_four_digits}</Typography>
            </div>
            <Divider style={{margin: '16px 0'}} />
            <div style={styles.row}>
              <Typography style={styles.label}>Card type</Typography>
              <img alt='No Image' src={data.payload.card_brand === 'VISA' ? Visa : MasterCard} />
            </div>
          </Card>
          </div>
          :
          <div>
            <PaymentNotExist />
          </div>
          }
      <div hidden={!loading}>
        <CardSpinner />
      </div>
      </div>
    </MobileMain>
  )
}

const styles = {
  root: {
    backgroundColor: '#F4F6F9', 
    height: '100%',
    width: '100%',
    padding: 16,
  },
  cardRoot: {
    border: '1px solid #D1D5DD',
    borderRadius: 10
  },
  cardBody: {
    padding: 20,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: 16,
    fontWeight: '600',
    color: '#2B2D33'
  },
  label: {
    fontSize: 14,
    color: '#909196',
    fontWeight: 'bold',
  },
  value: {
    fontSize: 14,
    fontWeight: '500',
    color: '#2B2D33'
  },
  descTitle: {
    fontSize: 12,
    color: '#000',
    fontWeight: 'bold'
  },
  descValue: {
    fontSize: 14,
    color: '#000',
  },
  backBtn: {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.102751)',
    borderRadius: 40,
    color: '#F5922F',
    fontSize: 16,
    fontWeight: '600',
    height: 40,
  },
  refundBtn: {
    backgroundColor: '#F5922F',
    color: '#FFF',
    fontSize: 16,
    fontWeight: '600',
    borderRadius: 10,
    height: 40,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 0'
  },
  charged: {
    backgroundColor: 'rgb(29 210 140 / 0.10)',
    border: '1px solid #1DD28B',
    borderRadius: 4,
    borderLeft: '5px solid #1DD28B',
    color: '#2B2D33',
    fontSize: 12,
    padding: '4px 12px'
  }
}

export default ChargedDetailsMobile;