import {Input, Select, Typography} from 'antd';
import React from 'react';
import { isMobile } from 'react-device-detect';

import {POSITION} from '../../../constants/constants'
import KYBCustomInput from '../customInputKYB';
import enterpriseStyle from '../kybStyle';


const ContactPerson = (props) => {
    const {Option} = Select;
    const kyb = enterpriseStyle()
    const { Text } = Typography;
    const kybStep = localStorage.getItem('kybStep')

    const {person, index: person_index, showField, stateData, segments} = props;

    const {
        contactPersons,
        setContactPersons,
        setSelected,
        contactPersonsErrors: errorListOfParent,
        setContactPersonsErrors: setErrorListOfParent,
    } = stateData;

    const [personErrors, setPersonErrors] = React.useState({
        first_name: "",
        middle_name: "",
        last_name: "",
        position: 2,
        mobile: "",
        email: ""
    });

    React.useEffect(() => {
        let newerrorListOfParent = [...errorListOfParent];
        let personHasError = Object.values(personErrors).some((error_str) => error_str);

        newerrorListOfParent[person_index] = personHasError;
        setErrorListOfParent(newerrorListOfParent);
    }, [personErrors]);

    function is_empty(person) {
        return (!person.first_name
                && !person.last_name
                && !person.mobile
                && !person.email)
    }

    function validate_single_field(field_to_check) {
        if (person_index === 0) {
            let spoc_is_complete = person.first_name
                                   && person.last_name
                                   && person.position >= 0
                                   && person.mobile
                                   && person.email;
            let is_field_valid = person[field_to_check];

            if (field_to_check === "position") {
                is_field_valid = is_field_valid != null && person[field_to_check] >= 0;
            } else if (field_to_check === "middle_name") {
                is_field_valid = is_field_valid != null && person[field_to_check].length !== 1;
            }

            return (spoc_is_complete || is_field_valid) ? "" : "Required";
        } else {
            if (field_to_check === "middle_name") {
                return "";
            }

            return (is_empty(person) || person[field_to_check]) ? "" : "Required";
        }
    }

    function evaluate_all_person_fields() {
        let newPersonErrors = {...personErrors};

        for (const field_to_check of Object.keys(person)) {
            newPersonErrors[field_to_check] = validate_single_field(field_to_check);
        }

        setPersonErrors(newPersonErrors);
    }


    function handleTextInputChange (e) {
        const { name: field_name, value } = e.target;

        let newContactPersons = [...contactPersons];
        let newPerson = newContactPersons[person_index];
        newPerson[field_name] = value;
        setContactPersons(newContactPersons);

        let newPersonErrors = {...personErrors};
        newPersonErrors[field_name] = validate_single_field(field_name);
        setPersonErrors(newPersonErrors);
    };

    function handleDesignation(value) {
        const newContactPersons = [...contactPersons]
        newContactPersons[person_index]['position'] = value;

        setContactPersons(newContactPersons);
    }

    return(
        <div hidden={person.hidden}>
             <div style={{margin: '30px 0px 10px 0px'}}>
                <div className="bottom-32 twoCol">
                    <div className={kyb.headerStrong}>
                        {person.type}
                    </div>

                    <div hidden={person.department === 'MA'}>
                        <a href className={`text-${segments} wc-header right-8`} onClick={() => showField(person, person_index)}>
                            Remove
                        </a>
                    </div>
                </div>
           
            </div>
            <div className={kyb.displayResponsive}>
                <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={kyb.miniText}>First Name</div>
                    <KYBCustomInput
                        data-test="input-fname"
                        name="first_name" placeholder="First Name" value={person.first_name}
                        onChange={e => handleTextInputChange(e)} onBlur={e => evaluate_all_person_fields()}
                        helpText={personErrors["first_name"]}
                    />
                </div>

                <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={kyb.miniText}>Middle Name (Optional)</div>
                    <Input
                        type="text"
                        data-test="input-mname" name="middle_name" placeholder="Middle Name" value={person.middle_name} onChange={e => handleTextInputChange(e)}/>
                </div>

                <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={kyb.miniText}>Last Name</div>
                    <KYBCustomInput
                        data-test="input-lname"
                        name="last_name" placeholder="Last Name" value={person.last_name}
                        onChange={e => handleTextInputChange(e)} onBlur={e => evaluate_all_person_fields()}
                        helpText={personErrors["last_name"]}
                    />
                </div>
            </div>

            <div style={{marginTop: 20}} hidden={person.department !== "MA"}>
                <div className={kyb.miniText}>Position/Designation</div>
                <Select
                        showSearch
                        style={{
                            width: '98%'
                            // border: personErrors["position"] ? '1px solid red' : ''
                        }}
                        optionFilterProp="children"
                        value={POSITION[person.position]}
                        onChange={value => {handleDesignation(value); evaluate_all_person_fields()}}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                                POSITION.length > 0 && POSITION.map((person, i) => {
                                return (
                                <Option key={i} person_index={i} value={i}>{person}</Option>
                                )
                            }, this)
                        }
                </Select>
                {/* <Text className={kyb.miniText} style={{color: personErrors["position"] ? 'red' : ''}}>
                        {personErrors["position"]}
                </Text> */}
            </div>

            <div className={kyb.displayResponsive}>
                <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={kyb.miniText}>Mobile Number</div>
                    <div style={{display:'flex'}}>
                        <div style={{position:'absolute', lineHeight: '40px', marginLeft: '10px'}}>63 |</div>
                        <input 
                            data-test="input-mobile"
                            className={kyb.inputStyle}
                            name="mobile"
                            style={{
                                border: '1px solid #D1D5DD',
                                padding: '10px 10px 10px 50px',
                                borderColor: personErrors["mobile"] ? 'red' : '#D1D5DD'
                            }} 
                            type="number"
                            value={person.mobile}
                            onMouseEnter={() => setSelected(person_index)}
                            onSelect={()=>setSelected(person_index)}
                            onMouseLeave={()=>setSelected("")}
                            onChange={e => { if(e.target.value.length <= 10){handleTextInputChange(e)}}}
                            onBlur={() => evaluate_all_person_fields()}
                        />
                    </div>
                    <Text className={kyb.miniText} style={{color: personErrors["mobile"] ? 'red' : ''}}>
                        {personErrors["mobile"]}
                    </Text>
                </div>

                <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={kyb.miniText}>Email Address</div>
                    <KYBCustomInput
                        data-test="input-email"
                        name="email" placeholder="Email Address" value={person.email} readOnly={person.department === "MA"}
                        onChange={e => handleTextInputChange(e)} onBlur={e => evaluate_all_person_fields()}
                        helpText={personErrors["email"]}
                    />
                </div>
            </div>
        </div>
    )
}

export default ContactPerson;