import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Radio,
  Input,
  Form,
  Button,
  notification,
} from "antd";
import { updateAdminBankTransferSettings } from "../api/update-admin-bank-transfer-settings";

const { Text } = Typography;

function MerchantBankTransferSettings({
  form,
  data,
  refetchCashbuxMerchantSettings,
}) {
  const [railType, setRailType] = useState("UBP");
  const [bankFee, setBankFee] = useState(data.transfer_settings.bank_fee);
  const [merchantFee, setMerchantFee] = useState(
    data.transfer_settings.merchant_fee,
  );
  const [ubxFee, setUbxFee] = useState(data.transfer_settings.ubx_fee);
  const [justification, setJustification] = useState(
    data.transfer_settings.justification,
  );
  const [enableJustification, setEnableJustification] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    if (data && data.rail_type) {
      setRailType(data.rail_type === "AllBank (A Thrift Bank)" ? "ALB" : "UBP");
    }
  }, [data]);

  const handleRailTypeChange = (e) => {
    if (e.target.value !== railType) {
      setShowButtons(true);
      setRailType(e.target.value);
    } else {
      setShowButtons(false);
    }
  };

  const handleFeeChange = (key, value) => {
    setShowButtons(true);
    setEnableJustification(true);
    setJustification("");
    switch (key) {
      case "bank_fee":
        setBankFee(value);
        break;
      case "merchant_fee":
        setMerchantFee(value);
        break;
      case "ubx_fee":
        setUbxFee(value);
        break;
      default:
        break;
    }
  };

  const handleJustificationChange = (e) => {
    setJustification(e.target.value);
  };

  const handleCancel = () => {
    setShowButtons(false);
    setEnableJustification(false);

    setBankFee(data.transfer_settings.bank_fee);
    setMerchantFee(data.transfer_settings.merchant_fee);
    setUbxFee(data.transfer_settings.ubx_fee);
    setJustification(data.transfer_settings.justification);
    setRailType(data.rail_type === "AllBank (A Thrift Bank)" ? "ALB" : "UBP");

    form.resetFields();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          const updatedSettings = {
            rail_type: railType,
            ...(enableJustification && {
              transfer_settings: {
                bank_fee: bankFee,
                merchant_fee: merchantFee,
                ubx_fee: ubxFee,
                justification,
              },
            }),
          };

          const response = await updateAdminBankTransferSettings(
            data.id,
            updatedSettings,
          );

          refetchCashbuxMerchantSettings();

          setShowButtons(false);
          setEnableJustification(false);

          setBankFee(data.transfer_settings.bank_fee);
          setMerchantFee(data.transfer_settings.merchant_fee);
          setUbxFee(data.transfer_settings.ubx_fee);
          setJustification(data.transfer_settings.justification);
          setRailType(
            data.rail_type === "AllBank (A Thrift Bank)" ? "ALB" : "UBP",
          );

          form.resetFields();

          notification.success({
            message: "Success",
            description: response.message,
          });
        } catch (error) {
          console.log(error);
          notification.error({
            message: "Failed",
            description: `${error.message}. Please try again later.`,
          });
        }
      }
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      style={{ display: "flex", flexDirection: "column", gap: "1em" }}
    >
      <Card>
        <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text style={{ fontSize: "16px" }} strong>
              Payment Rail
            </Text>
            <Text type="secondary">
              The selected payment rail will be used for all bank transfer
              requests including sub-merchants.
            </Text>
          </div>

          <Form.Item label="Rail Type" style={{ margin: 0 }}>
            {form.getFieldDecorator("rail_type", {
              initialValue: railType,
              rules: [],
            })(
              <Radio.Group
                size="large"
                onChange={handleRailTypeChange}
                placeholder="Select Rail Type"
              >
                <Radio value="UBP">UnionBank of the Philippines</Radio>
                <Radio value="ALB">AllBank (A Thrift Bank)</Radio>
              </Radio.Group>,
            )}
          </Form.Item>
        </div>
      </Card>
      <Card>
        <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text style={{ fontSize: "16px" }} strong>
              Fees
            </Text>
            <Text type="secondary">
              The set fees will be applied for all bank transfer requests
              including sub-merchants.
            </Text>
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            {data && (
              <>
                <Form.Item label="Bank Fee" style={{ margin: 0 }}>
                  {form.getFieldDecorator("bank_fee", {
                    initialValue: data.transfer_settings.bank_fee,
                    rules: [
                      { required: true, message: "Please input bank fee!" },
                    ],
                  })(
                    <Input
                      type="number"
                      size="large"
                      addonBefore="₱"
                      onChange={(e) =>
                        handleFeeChange("bank_fee", e.target.value)
                      }
                    />,
                  )}
                </Form.Item>

                <Form.Item label="Merchant Fee" style={{ margin: 0 }}>
                  {form.getFieldDecorator("merchant_fee", {
                    initialValue: data.transfer_settings.merchant_fee,
                    rules: [
                      { required: true, message: "Please input merchant fee!" },
                    ],
                  })(
                    <Input
                      type="number"
                      size="large"
                      addonBefore="₱"
                      onChange={(e) =>
                        handleFeeChange("merchant_fee", e.target.value)
                      }
                    />,
                  )}
                </Form.Item>

                <Form.Item label="UBx Fee" style={{ margin: 0 }}>
                  {form.getFieldDecorator("ubx_fee", {
                    initialValue: data.transfer_settings.ubx_fee,
                    rules: [
                      { required: true, message: "Please input UBx fee!" },
                    ],
                  })(
                    <Input
                      type="number"
                      size="large"
                      addonBefore="₱"
                      onChange={(e) =>
                        handleFeeChange("ubx_fee", e.target.value)
                      }
                    />,
                  )}
                </Form.Item>

                <Form.Item label="Justification" style={{ margin: 0 }}>
                  {form.getFieldDecorator("justification", {
                    initialValue: justification,
                    rules: [
                      !enableJustification
                        ? {}
                        : {
                            required: true,
                            message: "Please provide justification!",
                          },
                    ],
                  })(
                    <Input
                      type="text"
                      size="large"
                      disabled={!enableJustification}
                      placeholder={
                        enableJustification &&
                        "Please input the reason for the request of change."
                      }
                      onChange={handleJustificationChange}
                    />,
                  )}
                  <Text type="secondary" style={{ margin: 0 }}>
                    This input will only be enabled when you request to change
                    the existing bank transfer fees.
                  </Text>
                </Form.Item>
              </>
            )}
          </div>
        </div>
      </Card>

      {showButtons && (
        <div
          style={{ display: "flex", gap: "1em", justifyContent: "flex-end" }}
        >
          <Form.Item style={{ margin: 0 }}>
            <Button size="large" onClick={handleCancel}>
              Cancel
            </Button>
          </Form.Item>

          <Form.Item style={{ margin: 0 }}>
            <Button size="large" type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </div>
      )}
    </Form>
  );
}

export default Form.create({ name: "edit_bank_transfer_settings" })(
  MerchantBankTransferSettings,
);
