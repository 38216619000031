import React from 'react'
import {Modal} from 'antd'
import { makeStyles } from '@material-ui/core';
import {isMobile} from 'react-device-detect'


const ActionConfirmationModal = (props) => {
    const fee = makeStyles(theme => ({
        editBtn : {
            width: 172,
            height: '40px',
            background: '#F5922F',
            border: '1px solid #f5922f',
            borderRadius: '10px',
            color: '#fff',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '24px',
            outline: 0,
            '&:hover': {
                opacity: 0.8,
                cursor: 'pointer'
            },
            '&:active': {
                background: '#DA7F24',
                opacity: 1
    
            }
        },
        cancelBtn: {
            width: 172,
            height: '40px',
            background: '#fff',
            border: '1px solid #f5922f',
            borderRadius: '10px',
            color: '#f5922f',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '24px',
            outline: 0,
            '&:hover': {
                background: '#f5922f',
                cursor: 'pointer',
                color: '#fff',
            },
            '&:active': {
                background: '#DA7F24',
                opacity: 1
    
            }
        },
    })) ();


    const {
        isConfirmModalVisible,
        setIsConfirmModalVisible,
        confirmModalCallback,
        confirmModalHeaderText,
        confirmModalBodyText,
    } = props;

    return(
        <Modal
            centered
            width={360}
            visible={isConfirmModalVisible}
            onCancel={() => setIsConfirmModalVisible(false)}
            footer={null}
        >
        <div style={confirmation_modal_styles.headerText}>
            {confirmModalHeaderText}
        </div>

        <div style={confirmation_modal_styles.bodyText}>
            {confirmModalBodyText}
        </div>

        <div style={confirmation_modal_styles.buttonsDiv}>
            <button
                className={fee.cancelBtn}
                onClick={() => setIsConfirmModalVisible(false)}
                style={{
                    width: isMobile ? '120px' : '150px',
                }}
            >
                Cancel
            </button>
            <button
                className={fee.editBtn}
                onClick={confirmModalCallback}
                style={{
                    width: isMobile ? '120px' : '150px',
                }}
            >
                Confirm
            </button>
        </div>
    </Modal>
    )
}

const confirmation_modal_styles = {
    buttonsDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    headerText: {
        fontSize: 'x-large',
        paddingRight: '20px'
    },
    bodyText: {
        padding: '15px 0px 20px 1px'
    }
}

export default ActionConfirmationModal;