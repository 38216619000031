import React from 'react';
import { ExportToCsv } from 'export-to-csv';
import { Card, Icon, message, Typography } from 'antd';
import feedbackServices from './feedbackServices';
import moment from 'moment';

const FeedbackSummaryReports = (props) => {
    const [statsData, setStatsData] = React.useState(null);
    const [data, setData] = React.useState([]);

    const exportTable = async() => {
        try {
            const res = await feedbackServices.exportFeedbackReport();
            if (res && res.message) {
                message.success(res.message)
            }
            else {
                message.error('Error, try again')
            }
        } catch (error) {
            console.log(error);
            message.error('Error, try again')
        }
    };

    const getFeedbackStats = async (mode = null, date = '') => {
        try {
            const res = await feedbackServices.getFeedbackStats();
            if (res && res.basis_date) {
                setStatsData(res);
                setData(res.summary_report)
            }
            else {
                message.error('Error, try again')
            }
        } catch (error) {
            console.log(error);
            message.error('Error, try again')
        }
    }

    React.useEffect(() => {
        getFeedbackStats();
    }, [])

    const renderColor = (rate) => {
        switch (rate) {
            case 1:
                return {
                    color: '#C0492C',
                    border: '4px solid #C0492C'
                };
            case 2:
                return {
                    color: '#EC7C30',
                    border: '4px solid #EC7C30'
                };
            case 3:
                return {
                    color: '#EBB83F',
                    border: '4px solid #EBB83F'
                };
            case 4:
                return {
                    color: '#3363BB',
                    border: '4px solid #3363BB'
                };
            case 5:
                return {
                    color: '#59C378',
                    border: '4px solid #59C378'
                };
            default:
                return {
                    color: '#2B2D32',
                    border: '4px solid #2B2D32'
                };
        }
    }

    return (
        <div>
            <div align="left" style={{ padding: '24px 0' }}>
                <a href="/user_feedback">
                    <Icon type="arrow-left" className="text-nil right-8 arrow-width" />
                    <span className="wc-header">Back</span>
                </a>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <Card
                    style={{ padding: 0, width: '762px' }}
                    bodyStyle={{ padding: '24px' }}
                >
                    <div style={{ ...styles.flexBetween, padding: '20px 0' }}>
                        <Typography style={styles.title}>2022 Summary Report</Typography>
                        <button className={`admin-download-btn padding-lr-16`} onClick={() => exportTable()} ><Icon type='download' /> Export CSV</button>
                    </div>
                    <table style={{ width: '100%', margin: '0 auto' }}>
                        <tr style={{ backgroundColor: '#CFD3D9', width: '100%', padding: '12px' }}>
                            <th style={styles.header}>
                                Month
                            </th>
                            <th style={styles.header}>
                                No. of Reviewer
                            </th>
                            <th style={styles.header}>
                                Desktop
                            </th>
                            <th style={styles.header}>
                                Mobile
                            </th>
                            <th style={styles.header}>
                                Rating
                            </th>
                        </tr>
                        <tbody>
                            {data && data.length? data.map((d, i) => {
                                return (
                                    <tr>
                                        <td style={{ ...styles.tdata, fontWeight: '700' }}>
                                            {(d && d.month) || '-'}
                                        </td>
                                        <td style={styles.tdata}>
                                            {(d && d.no_of_reviewer) || '-'}
                                        </td>
                                        <td style={styles.tdata}>
                                            {(d && d.desktop_count) || '-'}
                                        </td>
                                        <td style={styles.tdata}>
                                            {(d && d.mobile_count) || '-'}
                                        </td>
                                        <td style={{ ...styles.tdata, color: renderColor(parseInt(d && d.rating) || 0).color, fontWeight: '600' }}>
                                            {(d && d.rating) || '-'}
                                        </td>
                                    </tr>
                                )
                            })
                            :
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                    <Typography style={{display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                width: '100%', height: '102px', padding: '12px', fontSize: '24px', fontWeight: '600'}}>No Data</Typography>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <div>
                        <div style={{ textAlign: 'right', gap: '6px', display: 'inline-flex', alignItems: 'center', width: '100%', justifyContent: 'flex-end' }}>
                            <Typography style={styles.ovrText}>Overall Rating</Typography>
                            <Typography style={{ ...styles.overRating, color: renderColor(parseInt(statsData && statsData.overall_rating) || 0).color }}>
                                {(statsData && statsData.overall_rating) || 0}
                            </Typography>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    )
}

const styles = {
    title: {
        color: '#2B2D32',
        fontWeight: '700',
        fontSize: '18px'
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    header: {
        color: '#2B2D32',
        fontWeight: '700',
        fontSize: '14px',
        width: '20%',
        padding: '12px'
    },
    tdata: {
        color: '#2B2D32',
        fontWeight: '400',
        fontSize: '14px',
        width: '20%',
        padding: '12px',
        borderBottom: 'solid 1px #000000',
        wordWrap: 'break-word',
    },
    overRating: {
        fontSize: '48px',
        fontWeight: '700'
    },
    ovrText: {
        color: '#2B2D32',
        opacity: '0.8',
        fontSize: '12px',
        fontWeight: '500'
    }
}

export default FeedbackSummaryReports;