import React, {useEffect} from 'react'
import {Button, Input, Alert} from 'antd'
import {history} from '../../store/history'
import axios from 'axios'
import {isMobile} from 'react-device-detect'

const ResendVerification = props =>{

    const [enterEmailForm, setEnterEmailForm] = React.useState(false)
    const [viewEmailForm, setViewEmailForm] = React.useState(true)
    const [email, setEmail] = React.useState(true)
    const [alert, setAlert] = React.useState(false)
    const [message, setMessage] = React.useState("")

    const resend = () => {
        let params = {
          email: email,
        }
        resendVerification(params)
      }
  
    async function resendVerification (value) {

        let response = await axios.post(
            process.env.REACT_APP_API_URL + '/api/resend_verification/', value
        );
        if(response.data.status == "success"){
            history.push('/email/verify')
        }
        else{
            setAlert(true)
            if(response.data.detail) {
                setMessage(response.data.details)
            }

            else {
                setMessage(response.data.message)
            }
        }
        
    }
    useEffect(() => {

        if(isMobile){
            history.push('/mobile/resend_verification')
        }
        
      }, [])
    const verifyStyle = {
        marginTop: '20%',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '34px',
        color: '#2B2D33',
        marginBottom: '10px'
    }

    const textStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.01em',
        color: '#2B2D33',
        opacity: '0.8',
        marginBottom: '20px'
    }

    return(
        <div style={{display:'flex', justifyContent: 'center'}}>
            <div>
                <div style={verifyStyle}>Enter your email</div>
                <Alert message={message} type="error" showIcon style={{ display: alert ? "block" : "none", margin: '10px 0px 10px 0px' }}/>
                <div style={textStyle}>We will send to you your verification link</div>
                <Input placeholder="Enter email" block style={{height: '50px'}} onChange={(e)=>setEmail(e.target.value)}/>
                <div style={{display:'flex', justifyContent: 'center', marginTop: '10px'}}>
                    <Button type="primary" size="large" block style={{backgroundColor: '#F5922F', border: 0}} onClick={ () => resend()}>Send verification</Button>
                </div>
            </div>

        </div>
    )
}

export default ResendVerification