import React from 'react';
import { isMobile } from 'react-device-detect';



const data = [
    {
        agreement: 'BTI PAYMENTS WEBSITE',
        contract: 'btipayments.ph'
    },
    {
        agreement: 'Self-Service Payment Terminal',
        contract: 'BTI PAYMENT’s terminal that customers use to avail of automated payment services.'
    },
    {
        agreement:'“YOU” “YOUR”, or “Customer”',
        contract: 'An individual or legal entity who is consuming any service form BTI PAYMENTS. It includes all type of customers – irrespective of service and payment plans. Specifically, that customer also includes those using FREE SERVICES'
    },
    {
        agreement: 'Company Data',
        contract: 'Company data will not be considered Customer Data or Confidential Information  for purposes of this agreement.'
    },
    {
        agreement: 'Personal Information',
        contract: 'The name, e-mail address, phone number, account number, and similar information submitted to you via the Self-Service Payment Terminal '
    },
    {
        agreement: 'Service Fee',
        contract: 'The amount you pay for using any of BTI PAYMENTS services'
    },
    {
        agreement: 'Privacy Policy ',
        contract: 'Privacy Policy can be read here or at previous page of the consent.'
    },
    {
        agreement: 'Acceptable Use',
        contract: 'BTI PAYMENT’s acceptable use as stated in the link below.'
    },
]
export const POLICY = [

    {
        q: '',
        d: <div>
        <p>
            Our company, BTI Payments, operates Self-Service Payment Terminals and is 
            the controller of the personal information disclosed to us by our customers.
        </p>
        <p>
            The Privacy Policy informs you of our policies regarding the collection, use, and disclosure 
            of Personal Information we receive from the customer of our Self-Service Payment Terminals.
        </p>
        <p>
            We use your Personal Information only for improving our services to you. By using our machine, 
            you agree to the collection and use of your Personal Information in accordance with this policy.
        </p>
        </div>
        
    },
    {
        q: 'Personal Information Collection.',
        d: <div>
            <p>
                While using our machine, we may ask you to provide us certain Personal Information such as your:
            </p>
            <p>
                <ul>
                    <li>Mobile Number</li>
                    <li>Name</li>
                    <li>Account Number</li>
                    <li>E-mail Address</li>
                    <li>Home Address</li>
                    <li>Date of Birth</li>
                </ul>
            </p>
            <p>
                These collected personal information will be used to identify you for verification purposes
            </p>
            </div>
    },
    {
        q: 'Personal Information Use.',
        d: <div>
            <p>
                The collected Personal Information will be used by our data processing partner, 
                <strong>Electronic Transfer & Advance Payment, Inc.,</strong> to ensure the Persona Information you 
                submitted is accurate before processing the transaction.
            </p>

            <p>
                Your Personal Information will also be used by our Customer Help Desk to contact you in case of failed transactions. 
                This is important to quickly resolve issues.
            </p>
            </div>
    },
    {
        q: 'Communication',
        d: <div>
            <p>
                We will use your Personal Information to send you SMS or Viber Confirmation messages regarding the status of your transaction
            </p>
            <p>
                We may use your Personal Information to contact you with marketing and promotional material related to the Self-Service Payment Terminal.
            </p>
        </div>
    },
    {
        'q': 'Right to Withdraw Consent',
        'd': <p>
                Our customers reserve the right to withdraw consent at any time.
            </p>
    },
    {
        'q': 'Changes to this Privacy Policy',
        'd': <div>
            <p>
                The privacy policy is effective as of September 01, 2019 and will remain in effect except with respect 
                to any changes in its provision in the future, which will be in effect immediately after being updated 
                and published.
            </p>
            <p>
                We reserve the right to update or change our Privacy Policy at any time and you should monitor this privacy policy periodically.
            </p>
            <p>
                Your continued use of the service of our Self-Service Payment Terminals will constitute your acknowledgement 
                of the modifications and your consent to abide and be bound by the modified Privacy Policy.
            </p>
            <p>
                If we make any material changes to this Privacy Policy, we will notify you either through e-Mail or by placing a prominent notice on our website.
            </p>
            </div>
            
    },
    {
        'q': 'Contact Us',
        'd': <div>
            <p>
                If you have any questions about the Privacy Policy, please contact us at
            </p>
            <div>
                <div>Mobile numbers: 09617314993, 09350583263, 09101034875, 09351162973</div>
                <div>Facebook: <a href="https://www.facebook.com/payandgoph/">https://www.facebook.com/payandgoph/</a></div>
                <div>Messenger: <a href="http://m.me/payandgoph">http://m.me/payandgoph/</a></div>
                <div>Email: <a href="custcare@btipayments.ph">custcare@btipayments.ph/</a></div>
            </div>
            </div>
            
    },
    
]

export const TERMS = [

    {
        q: 'Scope',
        d: <div>
        <p>
            This is a contract between you (the Customer) and US (BTI PAYMENTS). It describes the service 
            we will provide to you, how we will work together, and other aspect of our business relationship.
        </p>
        <p>
            BTI PAYMENTS provides the service(s) (as defined below) to you, subject to and conditioned upon your 
            acceptance of this agreement. Without your acceptance, BTI PAYMENTS cannot provide said services to you
        </p>
        <p>
            BY ACCESSING AND USING OUR SERVICES IN ANY WAY, YOU AGREE TO BE BOUND BY ALL THE TERMS OF THIS AGREEMENT. 
            DO NOT USE THE SERVICES IF YOU DO NOT AGREE TO ANY OF THE TERMS.
        </p>
        <p>
            We periodically update these terms and we will let you know when we do, through notifications sent 
            via e-Mail or by posting a revised copy on our website. You agree to review the Terms of Service on 
            a regular basis and always remain in compliance. 
        </p>
        </div>
        
    },
    {
        q: 'Definitions',
        d: <table style={{border: '1px solid #000000', width: isMobile? '100%' : '80%', fontWeight: '600'}}>
                <tr>
                    <td style={{border: '1px solid #000000', width: '30%'}} align='center'>Agreement</td>
                    <td style={{border: '1px solid #000000', width: '70%'}} align='center'>This contract between BTI PAYMENTS and its Customers</td>
                </tr>
                {
                    data.map((item, index) => {
                        return(
                            <tr key={index}>
                                    <td style={{padding: 5, width: '30%', border: '1px solid #000000'}} align='center'>
                                        <div>{item.agreement}</div>
                                    </td>

                                    <td style={{padding: 5, width: '70%', border: '1px solid #000000'}}>
                                        <div>{item.contract}</div>
                                    </td>
                            </tr>
                        )
                    })
                }
            </table>
    },
 
    
]

export const SERVICE = [

    {
        q: 'Availability',
        d: <div>
        <p>
            We aim to make the terminals available 24 hours a day, seven days a week, except in cases of limited store hours of our 
            location partners, or Planned downtimes for maintenance. 
        </p>
        </div>
        
    },
    {
        q: 'Software Update',
        d: <div>
        <p>
            BTI PAYMENTS may update the platform periodically that may change the functionality and behavior of the services. 
            Nothing in this agreement prevents BTI Payments from updating the platform unless such change materially impacts 
            the services committed to customers. 
        </p>
        </div>
        
    },    
    {
        q: 'Use and Limitation of Usage',
        d: <div>
        <p>
            Any non-compliance of the stated terms of use can lead the suspension and termination of your 
            access to our services and you may not be entitled to refunds. 
        </p>
        </div>
        
    },
    {
        q: 'Prohibited and Unauthorized Use',
        d: <div>
        <p>
            You will not 
        </p>
        <p>
            <ul>
                <li>Use or launch any automated systems including “robots” , “spiders”, or “offline readers” 
                    that sends more request messages to our servers to a given period than a human can reasonably produce in the same period.
                </li>
                <li>Attempt to gain unauthorized access to our servers.</li>
                <li>Sell, resell, rent or lease the Self-Service Payment Terminal.</li>
                <li>Use the services to store or transmit malicious code.</li>
                <li>Create derivate works based on the services unless you have been explicitly authorized by us.</li>
                <li>Reverse engineer the services to (a) build a competitive product or service , or (b) copy any features, functions or graphics of the services.</li>
                <li>Use the Self-Service Payment Terminal for any purpose or in any manner that is unlawful or prohibited by this agreement.</li>
            </ul>
        </p>
        </div>
        
    },
]