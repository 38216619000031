import React from 'react'
import axios from 'axios'
import {Card, Select, Input, Icon, Spin} from 'antd'
import enterpriseStyle from '../kybStyle'
import { isMobile } from 'react-device-detect'
import {POSITION} from '../../../constants/constants'
import NoteCard from '../noteCard'
import MOA from '../../../../static/files/UBX-BUX-PFA.pdf'
import NDA from '../../../../static/files/BUX-CNDA-Generic-v4 2.26.21.docx (signed).pdf'
import UploadProgressBar from '../custom_component/upload_progress_bar';

const {Option} = Select

const MoaNda = props => {
    const kybStep = localStorage.getItem('KYBStep')
    let data = props.datas

    const kyb = enterpriseStyle()
    const note = <span>Original copy of the <b>MOA</b> will be collected after submission. 
                        Please sign both documents in all pages. 
                        Our account officer will contact you once approved.</span>

    //alert message
    const stripQueryParams = (myString) => myString.slice(65).replace(/\?.*/,'')

    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(false)

    const [moa, setMoa] = React.useState("")
    const [moaFile, setMoaFile] = React.useState("")
    const [moaFilename, setMoaFilename] = React.useState(!data || data.signed_moa_attachment == null ? null : stripQueryParams(data.signed_moa_attachment))

    const [nda, setNda] = React.useState("")
    const [ndaFile, setNdaFile] = React.useState("")
    const [ndaFilename, setNdaFilename] = React.useState(!data || data.signed_nda_attachment == null ? null : stripQueryParams(data.signed_nda_attachment))
    const [isTouched, setIsTouched] = React.useState(false)
    const [hideSpin, setHideSpin] = React.useState(true)

    const [moaURL, setMoaURL] = React.useState("")
    const [ndaURL, setNdaURL] = React.useState("")


    var filesToUploadCount = 0;
    var filesUploadedCount = 0;
    const [uploadProgress, setUploadProgress] = React.useState(0);

    const userRole = localStorage.getItem("userType")
    const adminRole = ['AD', 'SD']

    let moaUpload = React.useRef()
    let ndaUpload = React.useRef()

    let userId = ['CO','SW'].includes(localStorage.getItem('userType')) ? 
                    localStorage.getItem('userId') : 
                localStorage.getItem("KYBType") == "creation" ? 
                    data.id : props.userId

    let legal_docu = []

    React.useEffect(() => {
        fetchDocumentURLS()
    },[])
    
    async function fetchDocumentURLS(){
        
        try{
            let url = process.env.REACT_APP_API_URL + "/api/content/?key=moa";
            let response = await axios.get(url)
            setMoaURL(response.data.content)
        }
        
        catch(error){
            console.log(error.message)
        }
        try{
            let url1 = process.env.REACT_APP_API_URL + "/api/content/?key=nda";
            let response1 = await axios.get(url1)
            setNdaURL(response1.data.content)

        }
        
        catch(error){
            console.log(error.message)
        }
    }




    legal_docu = [
        {   
            title: isMobile ? 'MOA' : 'Memorandum of Agreement',
            text: isMobile ? 'MOA' : 'Download MOA file',
            name: 'Upload MOA(PFA) with Signature',
            error: 'MOA(PFA)',
            ref: moaUpload,
            file_name: moaFilename,
            value: moa,
            file: moaURL ? moaURL : MOA
        },
        {   
            title: 'NDA',
            text: isMobile ? 'NDA' : 'Download NDA file',
            name: 'Upload NDA with Signature',
            error: 'NDA',
            ref: ndaUpload,
            file_name: ndaFilename,
            value: nda,
            file: ndaURL ? ndaURL : NDA
        },
    ]

    //handle upload file
    const handleUploadFile = (info, type) =>{
        setIsTouched(true)
        setError(false)
        //Corporate documents
        if(type == 'MOA' || type == 'Memorandum of Agreement'){
            setMoaFile(info)
            setMoaFilename(info.name)

        }
        else if(type == 'NDA'){
            setNdaFile(info)
            setNdaFilename(info.name)

        }
    }  

    //removing file

    function handleRemoveFile(type){
        //Corporate documents
        setIsTouched(true)
        if(type == 'MOA' || type == 'Memorandum of Agreement'){
            setMoaFilename(null)
            setMoaFile("")
        }
        else if(type == 'NDA'){
            setNdaFilename(null)
            setNdaFile("")
        }
    }

    function validateFields(){
        let i;
        let mb = 10000000;
        setHideSpin(false)
        let acceptedFiles = ['application/pdf']

        for(i=0; i < legal_docu.length; i++){
            if(legal_docu[i].file_name == null){
                setError(true)
                setHideSpin(true)
                setErrorMessage(`Please download and review our ${legal_docu[i].error} and upload it with Signature`)
                window.scrollTo(0, 0)
                return
            }

            else if((legal_docu[i].file_name != null && legal_docu[i].ref.current != null) && (legal_docu[i].ref.current.files.length > 0)){
                if(!acceptedFiles.includes(legal_docu[i].ref.current.files[0].type)) {
                    setError(true)
                    setHideSpin(true)
                    setErrorMessage(`${legal_docu[i].name} file must be .pdf only`)
                    window.scrollTo(0, 0)
                    return

                }
                
                else if(legal_docu[i].ref.current.files[0].size > mb){
                    setError(true)
                    setHideSpin(true)
                    setErrorMessage(`${legal_docu[i].name} file size must not exceed 10MB`)
                    window.scrollTo(0, 0)
                    return

                }
            }
            

            else {
                console.log(legal_docu[i])
            }
        }

        submitForm()
    }

    const yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        },
        onUploadProgress: recalculateUploadProgress
    }

    function recalculateUploadProgress(progressEvent) {
        /* To manually invoke the multi-packet upload behavior, use the browser's Network throttling tool.
           If your internet is fast enough, axios only fires this function once, so throttling is necessary. */

        const recomputedProgress = filesUploadedCount + (progressEvent.loaded / progressEvent.total);
        setUploadProgress(100 * recomputedProgress / filesToUploadCount);
    }

    async function submitDocument(params){
    
        try{
            let url = process.env.REACT_APP_API_URL + "/api/kyb/";
            let response = await axios.patch(url,params,yourConfig)

        }
        
        catch(error){
            console.log(error.message)
        }
    }

    async function submitForm(){
        filesUploadedCount = 0;
        setUploadProgress(0);
        var formData = new FormData();

        formData.append('submission_id', userId)
        formData.append('step_to_update', 5)

        let attachmentsToUpload = {
            'signed_moa_attachment': moaFile,
            'signed_nda_attachment': ndaFile
        };

        filesToUploadCount = Object.values(attachmentsToUpload)
                                   .filter(attached_file => attached_file !== '')
                                   .length;

        if (filesToUploadCount) {
            for (const [attachmentName, attachedFile] of Object.entries(attachmentsToUpload)) {
                if (attachedFile === '') {
                    continue;
                }

                formData.append(attachmentName, attachedFile);
                await submitDocument(formData);

                filesUploadedCount++;
                formData.delete(attachmentName);
            }
        }

        setHideSpin(true);
        props.submitStep(formData, 5);
    }

    function editBusinessInfo(val){
        props.openEdit(val)
    }
    return(
        <div>
        <Card 
        className={kyb.formCardStyle}
        bodyStyle={{padding: '20px 16px 20px 16px'}}>
            <div className={`bottom-32 twoCol`}>
                 <div className={kyb.headerStyle}style={{ fontSize: isMobile ? '16px' : '20px'}}>Moa and NDA</div>
                <div>
                    <a href className={`text-${props.segments} medium-font-weight`} onClick={() => editBusinessInfo(5)} hidden={['CO','SW'].includes(localStorage.getItem('userType')) && kybStep < 7 || localStorage.getItem('KYBType') == 'creation' && data ? data.step < 7 : ''}>Cancel</a>
                </div>
            </div>
            <NoteCard note={note} />

            {props.alertMessage}

            <div
                style={{
                    backgroundColor: 'rgba(226, 76, 76, 0.1)',
                    borderLeft: '4px solid #E24C4C',
                    width: '100%',
                    borderRadius: '4px',
                    marginBottom: '20px',
                    marginTop: '20px',
                    textAlign: 'left',
                    padding: '10px',
                    display: error ? "block" : "none"
                }}>
                <span className={kyb.normalText}>{errorMessage}</span>
                </div>

                {
                    legal_docu.map((item, key) => {
                        return(
                            <div key={key}>
                                <div className="kyb-title-div top-20">
                                    <div className={`${kyb.headerStrong} liveColor`}>
                                        {item.title}
                                    </div>
                                </div>

                                <div className="twoCol top-20 liveColor">
                                    <div>
                                        <div className="smallBoldStyle">
                                            Step 1   
                                        </div>
                                        <div className="normalTextStyle top-4">
                                            Download the file and sign all pages.
                                        </div>
                                    </div>
                                    <div>
                                        <a href={item.file} download className={`text-${props.segments}`}>
                                            <button className={`outline-btn--${props.segments} btn-height right-8`}>
                                                <span> <Icon type="download" className={`right-4`}/> {item.text}</span>
                                            </button>
                                        </a>
                                    </div>
                                </div>

                                <div className="top-32 bottom-20 liveColor">
                                    <div>
                                        <div className="smallBoldStyle">
                                            Step 2   
                                        </div>
                                        <div className="normalTextStyle top-4">
                                            Upload the downloaded file with signatures.
                                        </div>
                                    </div>
                                </div>

                                <input type="file"
                                    data-test="upload-file-input"
                                    ref={item.ref}
                                    style={{display: 'none'}}
                                    onChange={(e)=>handleUploadFile(e.target.files[0], item.title)}
                                    accept=".pdf"
                                    >
                                </input>

                                <div className={kyb.uploadDiv}>
                                    <div className={kyb.uploadText} style={{padding: '7px 0px 7px 0px'}}>
                                    {/* upload */}
                                       <div style={{display: item.file_name == null ? 'block' : 'none'}}>Upload File</div> 
                                       {/* preview */}
                                       <div style={{display: item.file_name != null ? 'block' : 'none'}}>
                                            <div style={{display: 'flex', width: isMobile ? 250 : ''}}>
                                                <Icon type="file" className={`text-${props.segments} top-4 right-8`}/>
                                                <div data-test={item.file_name} id="fileName" className={kyb.fileName} style={{width: isMobile ? '150px' : ''}}>{item.file_name}</div>
                                            </div>  
                                       </div>
                                    </div>

                                    <div>
                                    {/* upload */}

                                        <div style={{display: item.file_name == null ? 'block' : 'none'}}>
                                            <button data-test="upload-btn" className={`outline-btn--${props.segments} btn-small-height`} onClick={()=>item.ref.current.click()}>
                                                <Icon type="upload" className="right-4" /> Upload
                                            </button>
                                        </div>
                                       {/* preview */}
                                      
                                       <div style={{display: item.file_name != null ? 'block' : 'none', position: 'absolute', right: 42, marginTop: 8}}>
                                            <a href onClick={()=>{item.ref.current.value = null; handleRemoveFile(item.title)}} style={{padding: '8px 0px 0px 0px', fontWeight: 600}} className={`text-${props.segments}`}><Icon type='close' style={{fontSize: 20, fontWeight: 'bold'}} /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            <div hidden={isMobile}>
            <button className={`btn--${props.segments} btn-height top-20 right-8`}
                    hidden={!hideSpin}
                    style={{
                        width: '100%',
                        opacity: (props.editMode && !isTouched) ? 0.5 : '', 
                        cursor: (props.editMode && !isTouched) ? 'not-allowed' : 'pointer'}}
                    disabled={(props.editMode && !isTouched)} 
                    onClick={() => validateFields()}
                    > 
                    {
                        adminRole.includes(userRole) ? "Save Changes" : 
                        kybStep >= 6 ? "Save Changes" : 
                        "Submit and next"
                    }
            </button>

            <button className={kyb.uploadingBtn} 
                    hidden={hideSpin}>

                <div style={{paddingLeft: '10px', paddingRight: '10px'}}>
                    <UploadProgressBar value={uploadProgress}/>
                </div>
            </button>

            {props.footerNote}

            </div>
        </Card>
        <div className={kyb.stickyBtn} hidden={!isMobile}>
            <button className={`btn--${props.segments} btn-mobile-width`} 
                    hidden={!hideSpin}
                    style={{
                        opacity: (props.editMode && !isTouched) ? 0.5 : '', 
                        cursor: (props.editMode && !isTouched) ? 'not-allowed' : 'pointer'}}
                    disabled={(props.editMode && !isTouched)} 
                    onClick={() => validateFields()}
                    > 
                    {
                        adminRole.includes(userRole) && localStorage.getItem('KYBType') != 'creation' ? "Save Changes" : 
                        kybStep >= 6 ? "Save Changes" : 
                        "Submit and next"
                    }
            </button>

            <button className={kyb.uploadingBtn} 
                    hidden={hideSpin}>

                <div style={{paddingLeft: '10px', paddingRight: '10px'}}>
                    <UploadProgressBar value={uploadProgress}/>
                </div>
            </button>


        </div>
        </div>
    )
}

export default MoaNda
