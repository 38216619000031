import React from 'react'
import axios from 'axios';
import {
  Input, Icon, Table, Button, Dropdown, Menu,
  message, Pagination, Select, Spin, Typography, DatePicker, Row, Col, Popover, List, Checkbox
} from 'antd'
import adminStyle from './style'
import { useEffect } from 'react'
import { history } from '../../../store/history'
import '../../../static/css/antd.css'
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { Handle401 } from '../../handle401/handle401';
import { isMobile } from 'react-device-detect'
import { PAYMENT_CHANNEL, randomize } from '../../constants/constants';
import ArrowDown from '../../../static/icons/arrow-down.svg'
import EmailAdvisoryModal from './email_advisory/viewAdvisoryModal'
import EditEmailAdvisoryModal from './email_advisory/editAdvisoryModal'
import DeleteEmailAdvisoryModal from './email_advisory/deleteAdvisoryModal'
import { OTC, BANK, MOBILE, BNPL, PAYMENT_MODE } from '../../constants/constants'
import { ConsoleLogger } from '@aws-amplify/core';



const { Text, Link } = Typography;
const CheckboxGroup = Checkbox.Group
const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
const segments = localStorage.getItem('segments');
const OTC_LIST = Object.keys(OTC)
const WEB_BANK_LIST = Object.keys(BANK)
const EWALLET_LIST = Object.keys(MOBILE)
const BNPL_LIST = Object.keys(BNPL)
const PAYOUT_CHANNELS = Object.keys(PAYMENT_MODE).map(function (key) {
  return PAYMENT_MODE[key].value;
});
const PAYMENT_CHANNELS = [...Object.keys(OTC), ...Object.keys(BANK), ...Object.keys(MOBILE), ...Object.keys(BNPL)]
const PAYMENT_CHANNELS_CODES = [...Object.values(OTC), ...Object.values(BANK), ...Object.values(MOBILE), ...Object.values(BNPL)];

const DOWNTIME_CHECKER_STATUS = [
  "Pending", "Done"
];

function is_admin() {
  return localStorage.getItem("userType") != 'ME' && localStorage.getItem("userType") != 'CO' && localStorage.getItem("userType") != 'SW';
}

const SystemNotification = props => {

  const ad = adminStyle()
  const ButtonGroup = Button.Group;
  const [searchValue, setSearchValue] = React.useState("");

  const [submissions, setSubmissions] = React.useState([])
  const [statusSelected, setStatusSelected] = React.useState("All")
  const [page, setPage] = React.useState(1);
  const [totalAdvisories, setTotalAdvisories] = React.useState(0);
  const [ready, setReady] = React.useState(false);
  const pageSize = 10;
  const partnerRole = ['PF', 'PS']
  const createRole = ['AD', 'SD', 'BS']
  const userType = localStorage.getItem("userType");
  const isMounted = React.useRef(false);
  const [keyAccountType, setKeyAccountType] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false)

  //Modals
  const [viewModal, showViewModal] = React.useState(false)
  const [editModal, showEditModal] = React.useState(false)
  const [deleteModal, showDeleteModal] = React.useState(false)
  const [addModal, showAddModal] = React.useState(false)
  const [editData, setEditData] = React.useState('')
  const [deleteData, setDeleteData] = React.useState('')
  const [viewData, setViewData] = React.useState('')
  const [addData, setAddData] = React.useState('')
  const [timeStampFilter, setTimeStampFilter] = React.useState('')
  const [scheduledDateFilter, setScheduledDateFilter] = React.useState('')

  // Filter Checkboxes
  const [checkedChannelFilter, setCheckedChannelFilter] = React.useState('')
  //OTC
  const [checkAllOTC, setCheckAllOTC] = React.useState(true)
  const [indeterminateOTC, setIndeterminateOTC] = React.useState(false);
  const [checkedOTCList, setCheckedOTCList] = React.useState(OTC_LIST)
  //Web Banking
  const [checkAllWeb, setCheckAllWeb] = React.useState(true)
  const [indeterminateWeb, setIndeterminateWeb] = React.useState(false);
  const [checkedWebList, setCheckedWebList] = React.useState(WEB_BANK_LIST)
  //EWallet
  const [checkAllEWallet, setCheckAllEWallet] = React.useState(true)
  const [indeterminateEWallet, setIndeterminateEWallet] = React.useState(false);
  const [checkedEWalletList, setCheckedEWalletList] = React.useState(EWALLET_LIST)
  //EWallet
  const [checkAllBNPL, setCheckAllBNPL] = React.useState(true)
  const [indeterminateBNPL, setIndeterminateBNPL] = React.useState(false);
  const [checkedBNPLList, setCheckedBNPLList] = React.useState(BNPL_LIST)
  //Payout Channels
  const [checkAllPayout, setCheckAllPayout] = React.useState(true)
  const [indeterminatePayout, setIndeterminatePayout] = React.useState(false);
  const [checkedPayoutList, setCheckedPayoutList] = React.useState(PAYOUT_CHANNELS)
  //Status
  const [checkedStatusList, setCheckedStatusList] = React.useState(DOWNTIME_CHECKER_STATUS)

  let yourConfig = {
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  }

  const status = [
    'All', 'Pending', 'Done',
  ]

  const info = () => {
    message.success("Successful");
  };

  React.useEffect(() => {
    if (!isMounted.current) {
      return;
    }

    setPage(1);
    fetchAdvisories(1, searchValue, scheduledDateFilter, timeStampFilter, checkedStatusList, checkedChannelFilter);
  }, [searchValue, scheduledDateFilter, timeStampFilter, checkedStatusList, checkedChannelFilter]);

  useEffect(() => {
    if (localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") == null) {
      history.push('/login')
    }
    else {
      if (localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO") {
        history.push('/dashboard')
      }
      else if (localStorage.getItem("userType") == "MA") {
        history.push('/overview')
      }
      else {
        fetchAdvisories(1, searchValue, scheduledDateFilter, timeStampFilter, checkedStatusList, checkedChannelFilter)
        isMounted.current = true;
      }
    }
  }, [])

  let menu = (record) => (

    <Menu style={{ width: '180px' }}>

      <Menu.Item key="0" onClick={() => openEditModal(record)}>
        Edit advisory
      </Menu.Item>
      <Menu.Item key="1" onClick={() => openDeleteModal(record)} style={{ color: '#FF0000' }}>
        Delete advisory
      </Menu.Item>
    </Menu>
  );

  let payment_channels = () => (
    <div
      style={{ width: '220px', height: '200px', backgroundColor: '#fff', padding: '10px', overflowY: 'scroll' }}
    >
      <div style={{ margin: '10px 0px 10px 0px', fontWeight: '600', color: '#0D3D76' }}>Payment Channels</div>
      <hr />
      <Row style={{ padding: '0px 0px 10px 0px' }}>
        <div style={{ fontWeight: '600' }}>Over-the-counter</div>
        <Col span={24}>
          <Checkbox
            checked={checkAllOTC}
            indeterminate={indeterminateOTC}
            onChange={onCheckAllOTCChange}
          >All OTC
          </Checkbox>
        </Col>
        <Col>
          <CheckboxGroup
            class="cb_payment_methods"
            style={{ margin: '0px 0px 0px 24px' }}
            options={OTC_LIST}
            value={checkedOTCList}
            onChange={onCheckOTC} />
        </Col>
      </Row>
      <Row style={{ padding: '0px 0px 10px 0px' }}>
        <div style={{ fontWeight: '600' }}>Web Banking</div>
        <Col span={24}>
          <Checkbox
            checked={checkAllWeb}
            indeterminate={indeterminateWeb}
            onChange={onCheckAllWebChange}
          >All Web
          </Checkbox>
        </Col>
        <Col>
          <CheckboxGroup
            class="cb_payment_methods"
            style={{ margin: '0px 0px 0px 24px' }}
            options={WEB_BANK_LIST}
            value={checkedWebList}
            onChange={onCheckWeb} />
        </Col>
      </Row >
      <Row style={{ padding: '0px 0px 10px 0px' }}>
        <div style={{ fontWeight: '600' }}>E-Wallet</div>
        <Col span={24}>
          <Checkbox
            checked={checkAllEWallet}
            indeterminate={indeterminateEWallet}
            onChange={onCheckAllEWAlletChange}
          >All E-Wallet
          </Checkbox>
        </Col>
        <Col>
          <CheckboxGroup
            class="cb_payment_methods"
            style={{ margin: '0px 0px 0px 24px' }}
            options={EWALLET_LIST}
            value={checkedEWalletList}
            onChange={onCheckEWallet} />
        </Col>
      </Row>
      <Row style={{ padding: '0px 0px 10px 0px' }}>
        <div style={{ fontWeight: '600' }}>Buy Now, Pay Later</div>
        <Col span={24}>
          <Checkbox
            checked={checkAllBNPL}
            indeterminate={indeterminateBNPL}
            onChange={onCheckAllBNPLChange}
          >All BNPL
          </Checkbox>
        </Col>
        <Col>
          <CheckboxGroup
            class="cb_payment_methods"
            style={{ margin: '0px 0px 0px 24px' }}
            options={BNPL_LIST}
            value={checkedBNPLList}
            onChange={onCheckBNPL} />
        </Col>
      </Row>
      <div style={{ margin: '10px 0px 10px 0px', fontWeight: '600', color: '#0D3D76' }}>Payout Channels</div>
      <hr />
      <Row>
        <Col span={24}>
          <Checkbox
            checked={checkAllPayout}
            indeterminate={indeterminatePayout}
            onChange={onCheckAllPayoutChange}
          >All Payout
          </Checkbox>
        </Col>
        <Col>
          <CheckboxGroup
            style={{ margin: '0px 0px 0px 24px' }}
            options={PAYOUT_CHANNELS}
            value={checkedPayoutList}
            onChange={onCheckPayout}>
          </CheckboxGroup>
        </Col>
      </Row>
    </div>
  )

  let downtime_checker_status = () => (
    <div
      style={{ width: '180px', backgroundColor: '#fff', padding: '10px', overflowY: 'scroll' }}
    >
      <Row>
        <Col span={24}>
          <CheckboxGroup
            style={{ width: '100%', fontSize: '14px' }}
            options={DOWNTIME_CHECKER_STATUS}
            value={checkedStatusList}
            onChange={onCheckStatus}
          >
          </CheckboxGroup>
        </Col>
      </Row>
    </div>
  )


  let columns = [];


  // OTC Channels
  const onCheckOTC = list => {
    setCheckedOTCList(list);
    setIndeterminateOTC(!!list.length && list.length < OTC_LIST.length);
    setCheckAllOTC(list.length === OTC_LIST.length);
  }

  const onCheckAllOTCChange = e => {
    setCheckedOTCList(e.target.checked ? OTC_LIST : []);
    setIndeterminateOTC(false);
    setCheckAllOTC(e.target.checked);
  };
  //Web Banking Channels
  const onCheckWeb = list => {
    setCheckedWebList(list);
    setIndeterminateWeb(!!list.length && list.length < WEB_BANK_LIST.length);
    setCheckAllWeb(list.length === WEB_BANK_LIST.length);
  }

  const onCheckAllWebChange = e => {
    setCheckedWebList(e.target.checked ? WEB_BANK_LIST : []);
    setIndeterminateWeb(false);
    setCheckAllWeb(e.target.checked);
  };
  //EWallet Channels
  const onCheckEWallet = list => {
    setCheckedEWalletList(list);
    setIndeterminateEWallet(!!list.length && list.length < EWALLET_LIST.length);
    setCheckAllEWallet(list.length === EWALLET_LIST.length);
  }

  const onCheckAllEWAlletChange = e => {
    setCheckedEWalletList(e.target.checked ? EWALLET_LIST : []);
    setIndeterminateEWallet(false);
    setCheckAllEWallet(e.target.checked);
  };
  //BNPL Channels
  const onCheckBNPL = list => {
    setCheckedBNPLList(list);
    setIndeterminateBNPL(!!list.length && list.length < BNPL_LIST.length);
    setCheckAllBNPL(list.length === BNPL_LIST.length);
  }

  const onCheckAllBNPLChange = e => {
    setCheckedBNPLList(e.target.checked ? BNPL_LIST : []);
    setIndeterminateBNPL(false);
    setCheckAllBNPL(e.target.checked);
  };
  // Payout Channels
  const onCheckPayout = list => {
    setCheckedPayoutList(list);
    setIndeterminatePayout(!!list.length && list.length < PAYOUT_CHANNELS.length);
    setCheckAllPayout(list.length === PAYOUT_CHANNELS.length);
  }

  const onCheckAllPayoutChange = e => {
    setCheckedPayoutList(e.target.checked ? PAYOUT_CHANNELS : []);
    setIndeterminatePayout(false);
    setCheckAllPayout(e.target.checked);
  };

  //Status
  const onCheckStatus = list => {
    setCheckedStatusList(list);
  }

  function closeModal(type, record = null) {

    if (type == "closeViewModal") {
      showViewModal(false)
    }
    else if (type == "closeEditModal") {
      showEditModal(false)
      fetchAdvisories(1, searchValue, scheduledDateFilter, timeStampFilter, checkedStatusList, checkedChannelFilter)

    }
    else if (type == "closeDeleteModal") {
      showDeleteModal(false)
      fetchAdvisories(1, searchValue, scheduledDateFilter, timeStampFilter, checkedStatusList, checkedChannelFilter)
    }
    else if (type == "viewToEditModal") {
      showViewModal(false)
      setEditData(record)
      showEditModal(true)
    }
  };

  function openEditModal(record) {
    setEditData(record)
    showEditModal(true)
  }

  function openViewModal(record) {
    setViewData(record)
    showViewModal(true)
  }

  function openDeleteModal(record) {
    setDeleteData(record)
    showDeleteModal(true)
  }

  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontWeight: 600 }}>
              {record.id}
            </span>
          </div>
        )
      },
    },
    {
      title: 'Subject',
      width: 150,
      key: 'subject',
      render: (record) => {
        if (record.subject) {
          return (
            <div>
              {record.subject}
            </div>
          )
        } else {
          return (
            <div>
              N/A
            </div>
          )
        }
      }
    },
    {
      title: 'Channel',
      width: 150,
      key: 'channel',
      render: (record) => {
        if (record.channel) {
          if (PAYMENT_CHANNELS_CODES.findIndex(x => x.code === record.channel) > -1) {
            return (
              <div>
                {PAYMENT_CHANNELS[PAYMENT_CHANNELS_CODES.findIndex(x => x.code === record.channel)]}
              </div>
            )
          }
          else if (PAYMENT_MODE.some(obj => obj["key"] === record.channel)) {
            return (
              <div>
                {PAYOUT_CHANNELS[PAYMENT_MODE.findIndex(x => x["key"] === record.channel)]}
              </div>
            )
          }
        } else {
          return (
            <span>
              N/A
            </span>
          )
        }
      }
    },
    {
      title: 'Description',
      key: 'description',
      render: (record) => {
        if (record.description) {
          return (
            <div>
              {record.description}
            </div>
          )
        } else {
          return (
            <span>
              N/A
            </span>
          )
        }
      }
    },
    {
      title: 'Timestamp',
      key: 'last_checked_timestamp',
      render: (record) => {
        if (record.last_checked_timestamp) {
          return (
            <div>
              {record.last_checked_timestamp}
            </div>
          )
        } else {
          return (
            <span>
              N/A
            </span>
          )
        }
      }
    },
    {
      title: 'Status',
      key: 'checker_status',
      render: (record) => {
        if (record.checker_status == "Done") {
          return (
            <div className={ad.doneStyle}>
              {record.checker_status}
            </div>
          )
        } else {
          return (
            <div className={ad.ongoingStyle}>
              {record.checker_status}
            </div>
          )
        }
      }
    },
    {
      title: 'Advisory Message',
      key: 'advisory_message',
      render: (record) => {
        if (record.advisory_message) {
          return (
            <Button onClick={() => openViewModal(record)} type='link'>
              <Text style={{
                color: '#F5922F',
                fontSize: 14,
              }}
                underline>View advisory</Text>
            </Button>
          )
        } else {
          return (
            <div>
              N/A
            </div >
          )
        }
      }
    },
    {
      title: 'Date Created',
      key: 'created_at',
      render: (record) => {
        return (
          <div>
            {record.created_at}
          </div>
        )
      }
    },
    {
      title: 'Scheduled Date',
      key: 'scheduled_date',
      render: (record) => {
        if (record.scheduled_date) {
          return (
            <div>
              {record.scheduled_date}
            </div>
          )
        }
        else {
          return (
            <div>
              N/A
            </div>
          )
        }
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <div>
          <Dropdown overlay={() => menu(record)}
            placement="bottomCenter" trigger={['click']} >
            <Icon type="ellipsis" />
          </Dropdown>
        </div>
      )
    }
  ];

  const pageSelect = (e) => {
    console.log(e)
    setPage(e);
    fetchAdvisories(e, searchValue, scheduledDateFilter, timeStampFilter, checkedStatusList, checkedChannelFilter);
  }

  async function fetchAdvisories(page, search, scheduled_date = moment().format('YYYY-MM-DD'), timestamp = moment().format('MMMM DD YYYY'), status = '', channels = '') {
    const endpoint_url = process.env.REACT_APP_API_URL + '/api/admin/system_notification/email_advisory/'
    if (scheduled_date !== "") {
      scheduled_date = moment(scheduled_date).format('YYYY-MM-DD')
    }
    if (timestamp !== "") {
      timestamp = moment(timestamp).format('YYYY-MM-DD')
    }
    let query_params = [
      'page=' + page,
      'search=' + search,
      'scheduled_date=' + scheduled_date,
      'timestamp=' + timestamp,
      'status=' + status,
      'channels=' + channels
    ]
    const full_url = endpoint_url + '?' + query_params.join("&")

    let response = await axios({
      method: 'get',
      url: full_url,
      headers: {
        Authorization: "Token " + localStorage.getItem("jwtToken")
      }
    });

    setSubmissions(response.data.results)
    setTotalAdvisories(response.data.total)
    setReady(true)

  }

  function onSetTimeStampFilter(date, dateString) {
    if (date != null) {
      setTimeStampFilter(date)
    }
    else {
      setTimeStampFilter('')
    }
  }

  function onSetScheduledDateFilter(date, dateString) {
    if (date != null) {
      setScheduledDateFilter(date)
    }
    else {
      setScheduledDateFilter('')
    }
  }

  function onClickApplyFilter() {

    //OTC
    let applyCheckedOTC = []
    if (checkedOTCList.length) {
      applyCheckedOTC = Object.keys(OTC).map(function (key) {
        if (checkedOTCList.includes(key)) {
          return OTC[key].code;
        }
      }).filter(function (e) {
        return e != null;
      })
    }
    //Web
    let applyCheckedWeb = []
    if (checkedWebList.length) {
      applyCheckedWeb = Object.keys(BANK).map(function (key) {
        if (checkedWebList.includes(key)) {
          return BANK[key].code;
        }
      }).filter(function (e) {
        return e != null;
      })
    }
    //EWallet
    let applyCheckedEWallet = []
    if (checkedEWalletList.length) {
      applyCheckedEWallet = Object.keys(MOBILE).map(function (key) {
        if (checkedEWalletList.includes(key)) {
          return MOBILE[key].code;
        }
      }).filter(function (e) {
        return e != null;
      })
    }
    //BNPL
    let applyCheckedBNPL = []
    if (checkedBNPLList.length) {
      applyCheckedBNPL = Object.keys(BNPL).map(function (key) {
        if (checkedBNPLList.includes(key)) {
          return BNPL[key].code;
        }
      }).filter(function (e) {
        return e != null;
      })
    }
    //Payout Channels
    let applyCheckedPayout = []
    if (checkedPayoutList.length) {
      applyCheckedPayout = Object.keys(PAYMENT_MODE).map(function (key) {
        if (checkedPayoutList.includes(PAYMENT_MODE[key].value))
          return PAYMENT_MODE[key].key;
      });
    }

    setCheckedChannelFilter(
      [...applyCheckedOTC,
      ...applyCheckedWeb,
      ...applyCheckedEWallet,
      ...applyCheckedBNPL,
      ...applyCheckedPayout
      ])

    fetchAdvisories(1, searchValue,
      scheduledDateFilter,
      timeStampFilter,
      checkedStatusList,
      checkedChannelFilter
    )
  }

  const isFiltered = () => {
    return (statusSelected != "All")
  }

  const clearFilter = () => {
  }


  function onSetSelectedStatus(value) {
    if (value == "All") {
      setCheckedStatusList(DOWNTIME_CHECKER_STATUS)
    }
    else {
      setCheckedStatusList([value])
    }
    setStatusSelected(value)
  }

  const exportTable = () => {
    let options = {
      filename: 'Email advisory_' + moment().format("MM-DD-YYYY_HH:mm:ss") + '_' + statusSelected,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'BUx Email Advisory',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };

    let csvExporter = new ExportToCsv(options);

    if (submissions.length) {
      csvExporter.generateCsv(submissions);
    }
  };

  const errorMessage = (msg) => {
    message.destroy()
    message.error(msg, 2)
  }

  return (
    <div className={ad.kybDiv} hidden={!ready}>
      <div style={{ marginBottom: '50px' }}>
        <span className={ad.orderStyle}>System Notification</span>
      </div>

      <div className={ad.btnDiv} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div>
          <button
            className={`outline-btn--${segments}`}
            style={{ height: '40px', fontWeight: '500', borderRadius: 4, width: 'auto', marginRight: '15px' }}
            onClick={() => exportTable()}>
            <Icon type='download' style={{ paddingRight: '5px' }} />
            Export CSV
          </button>
          <button
            className={`btn--${segments}`}
            style={{ height: '40px', fontWeight: '500', borderRadius: 4, width: 'auto' }}
            onClick={() => openEditModal({})}
          >
            <div className={ad.btnText}>+ Add new advisory</div>
          </button>
        </div>
      </div>
      <div className={ad.btnTabDiv}>
        <Typography className={ad.subHeader}>Email Advisory</Typography>
        <ButtonGroup>
          {
            status.map((item, i) => {
              return <Button
                key={item.toLocaleLowerCase()}
                onClick={() => onSetSelectedStatus(item)}
                selected={statusSelected}
                className={item === statusSelected ? `bg-${segments}` : `'`}
                style={{
                  ...styles.btnGroup,
                  color: item === statusSelected ? '#FFF' : '#2B2D33',
                  backgroundColor: '#FFF',
                  borderRadius: i === 0 ? '4px 0 0 4px' : statusSelected && i === status.length - 1 ? '0 4px 4px 0' : 0
                }}>{item}</Button>
            })
          }
        </ButtonGroup>
      </div>
      <div className={ad.btnTabDiv}>
        <hr className={ad.line} />
      </div>

      <div className={ad.filterDiv}>

        <Popover content={payment_channels()} trigger="click" placement="bottomLeft">
          <Button
            style={{
              zIndex: 1,
              height: '40px',
              width: !isHighReso && is_admin() ? '33%' : '200px',
              paddingTop: '8px',
              marginRight: '12px',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <div>Channels</div>
            <img
              alt="Icon"
              src={ArrowDown}
              style={{
                marginLeft: '5px',
              }} />
          </Button>
        </Popover>
        <div hidden={statusSelected == 'All' ? true : false} hidden={statusSelected != 'All' ? true : false}>
          <Popover content={downtime_checker_status()} trigger="click" placement="bottomLeft">
            <Button
              style={{
                zIndex: 2,
                height: '40px',
                width: !isHighReso && is_admin() ? '33%' : '172px',
                paddingTop: '8px',
                marginRight: '12px',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <div>Status</div>
              <img
                alt="Icon"
                src={ArrowDown}
                style={{
                  marginLeft: '5px',
                }} />
            </Button>
          </Popover>
        </div>

        <div className={ad.datePickerDiv}>
          <Typography className={ad.datePickerLabel}>Timestamp</Typography>
          <DatePicker
            size="large" picker="month" format='YYYY/MM/DD' onChange={onSetTimeStampFilter} value={timeStampFilter}
            style={{ width: '150px', marginRight: '12px', height: '40px', zIndex: 3 }} />
        </div>
        <div className={ad.datePickerDiv}>

          <Typography className={ad.datePickerLabel}>Scheduled date</Typography>
          <DatePicker
            size="large" picker="month" format='YYYY/MM/DD' onChange={onSetScheduledDateFilter} value={scheduledDateFilter}
            style={{ width: '150px', marginRight: '12px', height: '40px', zIndex: 4 }} />
        </div>
        <Input
          placeholder="Search"
          style={{ width: '200px', marginRight: '12px', height: '40px', zIndex: 100 }}
          onChange={(e) => setSearchValue(e.target.value)}
          prefix={<Icon type="search" />}
        />
        <button
          className={`btn--${segments}`}
          style={{ height: '40px', fontWeight: '500', borderRadius: 4, width: '72px' }}
          onClick={onClickApplyFilter}>
          Apply
        </button>

      </div>
      <div style={{ backgroundColor: '#FFF' }}>
        <Table
          style={{ padding: '20px' }}
          pagination={{ hideOnSinglePage: true, pageSize: pageSize }}
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
          dataSource={submissions}
          columns={columns}
          rowKey={(record) => record.id}
        />

        <div style={{ width: '100%', textAlign: 'right', justifyContent: 'center', marginTop: '20px' }}>
          <Pagination
            size="small"
            total={totalAdvisories}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            onChange={pageSelect}
            defaultCurrent={1}
            key={randomize()}
            pageSize={pageSize}
            current={page}
          />
        </div>
      </div>
      <EditEmailAdvisoryModal fetchAdvisories={fetchAdvisories} editModal={editModal} editData={editData} closeModal={closeModal} />
      <DeleteEmailAdvisoryModal deleteModal={deleteModal} deleteData={deleteData} closeModal={closeModal} />
      <EmailAdvisoryModal viewModal={viewModal} viewData={viewData} closeModal={closeModal} />
    </div>
  )

}

const styles = {
  btnGroup: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '0 16px',
  },
}

export default SystemNotification;