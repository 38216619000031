import React from 'react'
import axios from 'axios'

import {Table, Input, Modal, message} from 'antd'
import FeeStyles from '../../style'

const ApprovedCard = props => {
    const fee = FeeStyles()
    
    const [showModal, setShowModal] = React.useState(false)
    const [formAction, setFormAction] = React.useState("Add")
    const [SMID, setSMID] = React.useState("")
    const [businessName, setBusinessName] = React.useState("")
    const [whitelistUrl, setWhitelistUrl] = React.useState("")
    const [GCashSubMid, setGCashSubMid] = React.useState("")
    const [GrapPaySubMid, setGrapPaySubMid] = React.useState("")

    const partners = props.partners;
    const columns = [
            {
              title: 'BUx SMID',
              dataIndex: 'bux_submid',
            },
            {
              title: 'Business Name',
              dataIndex: 'business_name',
            },
            {
              title: 'Whitelist URL',
              dataIndex: 'whitelist_url',
            },
            {
                title: 'GCash SMID',
                width: '16%',
                render: (text, user) => {
                    return(
                        <div>
                            {user.gcash_submid === "" ? "- -" : user.gcash_submid}
                        </div>
                    )
                },
            },
            {
                title: 'GrabPay SMID',
                width: '16%',
                render: (text, user) => {
                    return(
                        <div>
                            {user.grabpay_submid === "" ? "- -" : user.grabpay_submid}
                        </div>
                    )
                },
            },
            {
                title: 'Action',
                key: 'edit',
                width: '7vw',
                render: (record) => {
                    return <a onClick={ ()=> { setData(record); setShowModal(true); setFormAction("Edit")}}>View</a>
                }
            }]
  
    const defaultData = {
        'bux_submid': "",
        'full_name': "",
        'business_name': "",
        'whitelist_url': "",
        'gcash_submid': "",
        'grabpay_submid': ""
    }
    const setData = (submerchant) => {
        setSMID(submerchant.bux_submid);
        setBusinessName(submerchant.business_name);
        setWhitelistUrl(submerchant.whitelist_url);
        setGCashSubMid(submerchant.gcash_submid);
        setGrapPaySubMid(submerchant.grabpay_submid);
    }

    async function requestApi(){
        const yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = {
            'business_name': businessName.trim(),
            'whitelist_url': whitelistUrl.trim(),
            'gcash_submid': GCashSubMid.trim(),
            'grabpay_submid': GrapPaySubMid.trim()
        }
        if (formAction === 'Add') {
            await axios
                .post(`${process.env.REACT_APP_API_URL}/api/kyb/${props.uid}/submerchants/`,params, yourConfig)
                .then(response => response.data)
                .then(data => {
                    if(data.status == 'success'){
                        message.success('Submerchant succesfully created!');
        
                        setTimeout(() => (window.location.reload()), 1000)
                    }else{
                        message.error(data.message)
                    }
                })
                .catch(error => {
                    message.error(error.response.data.message)
                    console.log(error.response)
                })
        } else {
            await axios
                .patch(`${process.env.REACT_APP_API_URL}/api/kyb/${props.uid}/submerchants/${SMID}/`,params, yourConfig)
                .then(response => response.data)
                .then(data => {
                    if(data.status == 'success'){
                        message.success('Submerchant succesfully updated!');
        
                        setTimeout(() => (window.location.reload()), 1000)
                    }else{
                        message.error(data.message)
                    }
                })
                .catch(error => {
                    message.error(error.response.data.message)
                    console.log(error.response)
                })
        }

    }
    
    return(
        <div style={{paddingLeft: 20, paddingRight: 20, marginTop: -1}}>
            <Modal
                visible={showModal}
                onCancel={() => setShowModal(false)}
                width={640}
                footer={null}>

                <div style={{
                    marginTop: 32,
                    fontSize: 24,
                    lineHeight: '32px',
                    fontWeight: '300',
                    width: 600
                }}>
                    <div className={fee.innerDiv} style={{ marginTop: -1}}>
                        <div className={fee.spaceBetween} style={{borderBottom: '1px solid', borderBottomColor: '#E6EAF0'}}>
                            <div>
                                <div><span className={fee.channelHeader}>{SMID === "" ? 'Details' : SMID}</span></div>
                            </div>
                        </div>
                        <div style={{ padding: 20}}>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>Business Name</div>
                            <Input value={businessName} onChange={(e) => setBusinessName(e.target.value)} style={{ marginTop: 10}}/>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>Whitelist URL</div>
                            <Input value={whitelistUrl} onChange={(e) => setWhitelistUrl(e.target.value)} style={{ marginTop: 10}}/>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>GCash SMID</div>
                            <Input value={GCashSubMid} onChange={(e) => setGCashSubMid(e.target.value)} style={{ marginTop: 10}}/>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>GrabPay SMID</div>
                            <Input value={GrapPaySubMid} onChange={(e) => setGrapPaySubMid(e.target.value)} style={{ marginTop: 10}}/>
                            <div align='right' style={{marginTop: 20}}>
                                <button className={fee.approveBtn} onClick={() => requestApi()}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className={fee.innerDiv}>
                <div className={fee.spaceBetween} style={{padding: 20, borderBottom: '1px solid', borderBottomColor: '#E6EAF0'}}>
                    <div>
                        <div><span className={fee.channelHeader}>Sub-merchant IDs</span></div>
                    </div>

                    <div>
                        <div>
                            <button className={fee.editBtnTrans} onClick={ ()=> {setData(defaultData); setShowModal(true); setFormAction("Add")}}>Add</button>
                        </div>
                    </div>

                </div>


                <div style={{ padding: 10}}>

                    <Table
                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                    dataSource={partners}
                    pagination={false}
                    columns={columns} />
                </div>
            </div>
        </div>
    )
}

export default ApprovedCard