import React,{ useState } from "react";
import { Card, Button, } from "antd";
import { history } from '../../../../store/history';

const Content = ({ toggleModal, sideBar,  }) => {
  const [showLoadCardModal, setShowLoadCardModal] = useState(false);

  const handleClick = () => {
      history.push('/funds')
  }

  return (
    <div>
      <Card size="small" bordered={false} style={{ borderRadius:`${ sideBar ? '0px 0px 10px 10px' : '10px' }`, padding: '8px 4px 8px 4px' }}>
        <h2>Transfer Funds to your Bank / E-wallet</h2>
        <Button style={styles.actBtn} onClick={handleClick} >Cashout</Button>
      </Card>
    </div>
  )
}

const styles = {
  regularFont: {
    fontSize: 12,
    color: '#000000'
  },
  input: {
    padding: '11px 16px 11px 16px',
    fontSize: 16,
    height: 48,
    margin: '4px 0px 0px 0px',
  },
  actBtn: {
    margin: '16px 0px 0px 0px',
    height: 48,
    width: '100%',
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    borderRadius: 10,
    fontSize: 14,
    fontWeight: '600',
  },
}

export default Content;