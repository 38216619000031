import React,{  } from "react";
import { useSelector } from "../../react-redux-hooks";
import { Card, Typography, Spin, Icon, Button, Tooltip, Divider, Dropdown } from "antd";
import * as Icons from '../../../../static/icons/dashboard_icons/index';
import { MasterWalletIcon, SubwalletIcon, WalletBalanceBG, PayoutIcon } from '../../../../static/images/multiwallet';
import dropdown_icon from '../../../../static/icons/dropdown_icon.svg'

const BuxWalletBalance = ({balance, isDellReso, isMobile, isTablet, smallReso, isTestMode, xenditBal, ccEnabled}) => {
  const isSmallReso = isMobile || isTablet || smallReso

  const balLength = balance ? balance.toString().length : 0;
  const isMultiwallet = localStorage.getItem('is_multiwallet') === 'true';
  const isChild = localStorage.getItem("is_child") === 'true';
  const walletBalances = useSelector(state => state.multiwallet.walletBalances);
  const buxBalance = isMultiwallet ? walletBalances && walletBalances.total : balance;
  const segments = localStorage.getItem('segments');
  const [showBalance, setShowBalance] = React.useState(false)
  const hasCashbuxPrivilege = localStorage.getItem('has_cashbux_privilege');
  
  console.log('-------------------------');
  console.log(ccEnabled)   

  const MultiwalletBalance = (props) => {
    const { data } = props;
    return  (
      <div className="balance-div" hidden={!isMultiwallet || isChild || !showBalance}>
        <div>
          <div className="wallet-balance-div">
              <img alt='No Image' src={PayoutIcon} style={{paddingRight: 8}} />
              <div>
              <Typography className="normalSizeStyle">Transferable Balance</Typography>
              <Typography className="boldTextStyle" >{walletBalances && walletBalances.float? '₱ ' + parseFloat(walletBalances.float).toLocaleString('en-US', {minimumFractionDigits: 2}) : '₱ 0.00'}</Typography>

              </div>
          </div>
          <div className="wallet-balance-div">
              <img alt='No Image' src={MasterWalletIcon } style={{paddingRight: 8}} />
              <div>
                <Typography className="normalSizeStyle">Master Wallet Balance</Typography>
                <Typography className="boldTextStyle" >{walletBalances && walletBalances.master? '₱ ' + parseFloat(walletBalances.master).toLocaleString('en-US', {minimumFractionDigits: 2}) : '₱ 0.00'}</Typography>
              </div>
          </div>

          <div className="wallet-balance-div" style={{border: 0}}>
              <img alt='No Image' src={SubwalletIcon } style={{paddingRight: 8}} />
              <div>
                <Typography className="normalSizeStyle">Sub-wallet Balance</Typography>
                <Typography className="boldTextStyle">{walletBalances && walletBalances.sub_users? '₱ ' + parseFloat(walletBalances.sub_users).toLocaleString('en-US', {minimumFractionDigits: 2}) : '₱ 0.00'}</Typography>
              </div>
          </div>
        </div>
      </div>
    )
  }

  
  return (
    <div style={{padding: isSmallReso ? 0 : 24, paddingBottom: isSmallReso ? 0 : 10, width: '100%'}}>
      <div hidden={!isTestMode} className="test-bg">Test Wallet</div>
        <div className="bux-wallet-card" 
        style={{position: 'relative',  height: 'fit-content', display: 'block', overflow:'hidden'}}
        // style={!isTestMode ? styles.bgImage : styles.testBgImage}>
        >
          <div className="twoCol" style={{padding: isSmallReso ? '32px 16px 16px 16px' : 16, zIndex: 1, position: 'relative'}}>
            <div style={{display: 'flex'}}>
              <img src={Icons.BuxRoundBlue} style={{width: isSmallReso || isDellReso ? 32 : ''}} alt='#' />

              <div style={{marginLeft: 16}}>
                <div className="normalSizeStyle liveColor">
                  { hasCashbuxPrivilege === "true" ? "Account Balance" : "BUx Wallet Balance" }
                </div>
                <div className="boldTextStyle liveColor" style={{fontSize: isSmallReso ? 18 : '1vw', marginTop: 4}}>
                  ₱ {buxBalance || buxBalance == 0 ? parseFloat(buxBalance).toLocaleString('en-US', {minimumFractionDigits: 2})
                  : isChild ? balance && balance.toLocaleString('en-US', {minimumFractionDigits: 2}) : <Spin size='small' />}
                </div>
              </div>
            </div>

            <a onClick={() => setShowBalance(!showBalance)} href hidden={!isMultiwallet || isChild}>
                <img src={dropdown_icon} alt="#" className="caret-img" style={{transform: showBalance ? 'rotate(180deg)' : 'rotate(0deg)'}} />
            </a>
          </div>

          <MultiwalletBalance showBalance={showBalance} />

          <img 
          alt="#"
          src={
            isTestMode ? Icons.test :
            segments === 'me_' ? Icons.me :
            segments === 'gig' ? Icons.gig :
            segments === 'sme' ? Icons.sme :
            segments === 'biz' ? Icons.biz :
            Icons.main
          } className="wallet-wave-img" />

          
        </div>


        
    </div>
  )
}

export default BuxWalletBalance;
