export const titleStyle = {
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    color: '#000000'
}

export const headerStyle = {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '32px',
    color: '#000000'
}

export const headerStyle2 = {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '32px',
    color: '#2b2d33',
    marginBottom : '10px'
}

export const textStyle1 = {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#2b2d33',
    marginBottom : '10px',
}

export const textStyle2 = {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#54575f',
    marginBottom : '10px'
}

export const textStyle3 = {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#54575f',
}

export const textStyle4 = {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
}
export const textStyle5 = {
    padding: '30px 0px 30px 0px',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#2b2d33',
    opacity: '80%',
}

export const infoStyle = {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#54575f'
}

export const editAddressStyle = {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '16px',
    color: '#1D82B8'
}

export const uploadBtnStyle = {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1D82B8',
    border: '1px solid #1D82B8',
    width: '100%',
    height: '48px',
}

export const submitBtn = {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#fff',
    backgroundColor: '#1D82B8',
    width: '100%',
    height: '48px',
}

export const replaceStyle = {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1D82B8',
    position: 'absolute',
    right: '0',
    marginRight: '50px'
}

export const inputTextStyle = {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '24px',
    color: '#212B36'
}