import React, { useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import ReconStyle from '../../static/css/Recon'
import { Button, Table, Select, DatePicker, Input, Icon, Checkbox, Row, Col, Popover } from 'antd'
import { ExportToCsv } from 'export-to-csv';
import { Handle401 } from '../../components/handle401/handle401';
import { OTC_LIST, BANK_LIST, MOBILE_LIST, BNPL_LIST, randomize, segmentStroke } from '../../components/constants/constants'
import { isMobile } from 'react-device-detect'
import MetaTag from '../../components/meta_tag/meta_tag'
import { history } from '../../store/history'
import ArrowDown from '../../static/icons/arrow-down.svg'
import ClearFilter from '../../components/AdminComponents/filter_btns/clear_filter_btn'

const { Option, OptGroup } = Select;

const ReconEnterprise = props => {

  useEffect(() => {
    if (isMobile) {
      window.location.href = '/mobile/recon'
    }
    else if (localStorage.getItem("userType") == "ME") {
      history.push('/dashboard')
    }
    else if (localStorage.getItem("userType") == "AD") {
      history.push('/overview')
    }
    else if (localStorage.getItem("userType") == "PS") {
      history.push('/orders')
    }
    else if (localStorage.getItem("userType") == "MA") {
      history.push('/overview')
    }
    else {
      getRecon(show, moment().format("MM-DD-YYYY"), moment().format("MM-DD-YYYY"))
      setStartDate(moment().format("MM-DD-YYYY"))
      setEndDate(moment().format("MM-DD-YYYY"))
      // getCCStatus();

      // delete MLhuillier for individual
      if (localStorage.getItem('userType') === 'ME') {
        delete OTC_LIST['MLhuillier'];
      }
    }

  }, [])

  // const getCCStatus = async () => {
  //   try {
  //     const res = await dbService.getCardInfo();
  //     setCCEnabled(res.cc_enabled);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
  const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
  const isDell1 = window.screen.width * window.devicePixelRatio === 1366 && window.screen.height * window.devicePixelRatio === 768;
  const isMac = window.screen.width * window.devicePixelRatio === 1440 && window.screen.height * window.devicePixelRatio === 900;

  const isDellReso = (isSmallReso || isDell || isDell1)

  const segments = localStorage.getItem('segments')

  const rs = ReconStyle()
  const ButtonGroup = Button.Group;
  const CheckboxGroup = Checkbox.Group;

  const [show, setShow] = React.useState('Accounted')
  const [searchValue, setSearchValue] = React.useState("")
  const [paymentChannels, setPaymentChannels] = React.useState([])
  const [searchValType, setValType] = React.useState('')
  const [datas, setDatas] = React.useState([])
  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')
  const [totalAmount, setTotalAmount] = React.useState(0)
  const [totalReceivable, setTotalReceivable] = React.useState(0)

  const ubp_enabled = localStorage.getItem('cc_enabled') == 'UnionBank';
  const xendit_enabled = localStorage.getItem('cc_enabled') == 'Xendit';
  const CCEnabled = ubp_enabled || xendit_enabled;

  //Setting date and moment
  const [startMoment, setStartMoment] = React.useState("")
  const [endMoment, setEndMoment] = React.useState("")

  const [endOpen, setEndOpen] = React.useState(false)

  const [ewallet, setEwallet] = React.useState("")
  const [cc, setCC] = React.useState("")
  const [bnpl, setBnpl] = React.useState("")
  const [snpl, setSnpl] = React.useState("")
  const [otcCheckedList, setOtcCheckedList] = React.useState([]);
  const [webCheckedList, setWebCheckedList] = React.useState([]);
  const [checkAll, setCheckAll] = React.useState(false);
  const [checkAllBank, setCheckAllBank] = React.useState(false);

  const [disabledBtn, setDisabledBtn] = React.useState(true);
  const [hideClear, setHideClear] = React.useState(true);

  const [grabpay, setGrabpay] = React.useState("");

  const clearFilter = () => {
    setCheckAll(false)
    setCheckAllBank(false)
    setOtcCheckedList([])
    setWebCheckedList([])
    setEwallet("")
    setCC("")
    setSearchValue("")
    setStartMoment("")
    setEndMoment("")
    setHideClear(true)
    setDisabledBtn(true)
    setGrabpay("");
    getRecon(show, moment().format("MM-DD-YYYY"), moment().format("MM-DD-YYYY"))
  }


  const onChangeOtc = list => {
    setOtcCheckedList(list);
    setCheckAll(list.length === OTC_LIST.length);
    setDisabledBtn(false)
  };

  const onChangeWeb = list => {
    setWebCheckedList(list);
    setCheckAllBank(list.length === BANK_LIST.length);
    setDisabledBtn(false)
  }

  const onCheckAllWebChange = e => {
    setWebCheckedList(e.target.checked ? BANK_LIST : []);
    setCheckAll(e.target.checked);
    setDisabledBtn(false)

  }

  const onCheckAllOtcChange = e => {
    setOtcCheckedList(e.target.checked ? OTC_LIST : []);
    setCheckAll(e.target.checked);
    setDisabledBtn(false)

  };

  const onChangeGrabpay = e => {
    setGrabpay(e.target.checked ? 'Grabpay' : "");
    setDisabledBtn(false)
  };

  const status = [
    'Accounted', 'Unaccounted'
  ]

  const payment_methods = (
    <Checkbox.Group
      style={{ width: '180px', backgroundColor: '#fff', padding: '10px', height: '200px', overflowY: 'scroll' }}
    >
      <div style={{ margin: '10px 0px 10px 0px', fontWeight: '600' }}>Over-the-counter</div>
      <Row>
        <Col span={24}>
          <Checkbox checked={checkAll} onChange={onCheckAllOtcChange} value="All OTC" >All</Checkbox>
        </Col>
        <Col>
          <CheckboxGroup options={OTC_LIST} value={otcCheckedList} onChange={onChangeOtc} />
        </Col>
      </Row>

      <div style={{ margin: '10px 0px 10px 0px', fontWeight: '600' }}>Web Banking</div>
      <Row>
        <Col span={24}>
          <Checkbox checked={checkAllBank} onChange={onCheckAllWebChange} value="All Web" >All</Checkbox>
        </Col>
        <Col>
          <CheckboxGroup options={BANK_LIST} value={webCheckedList} onChange={onChangeWeb} />
        </Col>
      </Row>

      <div style={{ margin: '10px 0px 10px 0px', fontWeight: '600' }}>E-Wallet</div>
      <Row>
        {
          MOBILE_LIST.map((item, i) => {
            return (
              <Col span={24} key={i}>
                <Checkbox onChange={e => setEwallet(e.target.value)} value={item}>{item}</Checkbox>
              </Col>
            )
          })
        }
        {/* <Col span={24} key={2}>
          <Checkbox checked={grabpay} onChange={onChangeGrabpay} value={'Grabpay'}>GrabPay</Checkbox>
        </Col> */}
      </Row>

      <div>
        <div style={{ margin: '10px 0px 10px 0px', fontWeight: '600' }}>Card Payments</div>
        <Row>
          <Col span={24} key={randomize}>
            <Checkbox onChange={e => setCC(e.target.value)} value={"Visa/MasterCard"}>Visa/MasterCard</Checkbox>
          </Col>
        </Row>
      </div>

      <div>
        <div style={{ margin: '10px 0px 10px 0px', fontWeight: '600' }}>Buy Now Pay Later</div>
        <Row>
          <Col span={24} key={randomize}>
            <Checkbox onChange={e => setBnpl(e.target.value)} value={"BillEase"}>BillEase</Checkbox>
          </Col>
        </Row>
      </div>
      {/* <div>
          <div style={{margin:'10px 0px 10px 0px', fontWeight: '600'}}>Study Now Pay Later</div>
          <Row>
            <Col span={24} key={randomize}>
                <Checkbox onChange={e => setSnpl(e.target.value)} value={"BillEase"}>BillEase</Checkbox>
            </Col>
          </Row>
      </div> */}
    </Checkbox.Group>
  )

  function applyButton() {
    let all_payment_methods = []
    if (otcCheckedList.concat(webCheckedList) === "" || !ewallet || !cc || !bnpl || !snpl) {
      all_payment_methods = []
    }
    else {
      all_payment_methods = otcCheckedList.concat(webCheckedList)
      all_payment_methods.push(ewallet)
      all_payment_methods.push(cc)
      // all_payment_methods.push(grabpay)
      all_payment_methods.push(bnpl)
    }

    setPaymentChannels(all_payment_methods)
    getRecon(show, startDate, endDate);
    renderColumns(searchValue, show, all_payment_methods)
    setHideClear(false);
  }

  const columns = [
    {
      title: 'Ref Code',
      render: (record) => {
        return (
          <div style={{ fontWeight: 'bold' }}>
            {record.ref_code}
          </div>
        )
      },
    },
    {
      title: 'Total Amount',
      render: (record) => {
        return (
          <div>₱ {record.amount.toFixed(2)}</div>
        )
      }

    },
    {
      title: 'Receivable',
      render: (record) => {
        return (
          <div>₱ {record.receivable.toFixed(2)}</div>
        )
      }

    },
    {
      title: 'Payment Method',
      render: (record) => {
        return (
          OTC_LIST.includes(record.payment_method) ?
            <div>
              <div><b>OTC</b></div>
              <div>{record.payment_method}</div>
            </div>

            :

            BANK_LIST.includes(record.payment_method) ?
              <div>
                <div><b>Web Banking</b></div>
                <div>{record.payment_method}</div>
              </div>

              :

              <div>{record.payment_method}</div>

        )
      }

    },
    {
      title: 'Transaction Date',
      width: 150,
      dataIndex: 'transaction_date'

    },
    show == "Unaccounted" ?
      {}
      :
      {
        title: 'Settlement Date',
        width: 150,
        dataIndex: 'settlement_date'
      },
  ]

  function exportTable() {
    let options = {
      filename: 'Recon_' + moment().format("MM-DD-YYYY_HH:mm:ss") + '_' + show,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: show + '_Summary',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };

    let csvExporter = new ExportToCsv(options);

    if (datas != null) {
      csvExporter.generateCsv(datas);
    }
  };


  const renderColumns = (val, status, channel) => {
    let status_filtered = [];
    let channel_filtered = [];
    let final_filtered = [];

    if (status !== "Accounted") {
      status_filtered = datas.filter(datas =>
        datas.settlement_date == null ? 'unaccounted' == status.toLowerCase() : '')
    }

    else {
      status_filtered = datas;
    }

    if (channel.length > 0) {
      channel_filtered = status_filtered.filter(status_filtered => status_filtered.payment_method !== "" && status_filtered.payment_method != null ?
        channel.includes(status_filtered.payment_method) : false)
    }

    else {
      channel_filtered = status_filtered;
    }

    if (val === "" || val === null) {
      return channel_filtered
    }

    else {
      final_filtered = channel_filtered.filter(channel_filtered => channel_filtered.ref_code !== null ?
        channel_filtered.ref_code.toLowerCase().includes(val.toLocaleLowerCase()) : false)
    }

    return final_filtered
  }

  function setShowDetails(val) {
    setShow(val)
    getRecon(val, startDate, endDate)
  }

  function changeSearchVal(val) {
    setSearchValue(val)
    setDisabledBtn(false)
    // renderColumns(val, show, paymentChannels)
  }
  const disabledStartDate = startValue => {

    if (!startValue || !endMoment) {
      return false;
    }
    return startValue && startValue > moment().endOf('day');
  };

  const disabledEndDate = endValue => {
    if (!endValue || !startMoment) {
      return false;
    }
    return endValue.valueOf() <= startMoment.valueOf();
  };

  const onStartChange = value => {
    if (value != null && endDate != "") {
      setStartMoment(value)
      setStartDate(value.format("MM-DD-YYYY"))
      setDisabledBtn(false)
    }
    else if (value != null) {
      setStartMoment(value)
      setStartDate(value.format("MM-DD-YYYY"))
      setDisabledBtn(false)
    }
    else {
      setStartMoment(null)
      setStartDate("")
    }
  }
  const onEndChange = value => {

    if (value != null && startDate != "") {
      setEndMoment(value)
      setEndDate(value.format("MM-DD-YYYY"))
      setDisabledBtn(false)
    }
    else {
      setEndMoment(null)
      setEndDate("")
    }
  }

  const handleStartOpenChange = open => {
    if (!open) {
      setEndOpen(true)
    }
  };

  const handleEndOpenChange = open => {
    setEndOpen(open)
  };

  const TableStats = ({ amount, receivable, isLoading }) => {
    return (
      <div className={rs.newSummaryDiv}>
        <div className={rs.totalDiv} style={{ borderRight: '1px solid #E6EAF0' }}>
          <div className={rs.sumSubDiv}>
            Total Amount: <span className={rs.sumSubValue}>{amount ? amount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}</span>
          </div>

        </div>

        <div className={rs.totalDiv} style={{ borderLeft: '1px solid #E6EAF0' }}>
          <div className={rs.sumSubDiv}>
            Receivable Amount: <span className={rs.sumSubValue}>₱ {receivable ? receivable.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}</span>
          </div>
        </div>

      </div>

    )
  }

  async function getRecon(val, start_date, end_date) {
    let yourConfig = {
      headers: {
        Authorization: "Token " + localStorage.getItem("jwtToken")
      }
    }

    try {
      let response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/recon/?accounting_status=${val.toLowerCase()}&filter_type=transaction_date&start=${start_date}&end=${end_date}`,
        yourConfig
      );

      setDatas(response.data)
      let amount = 0, receivable = 0;

      for (let i = 0; i < response.data.length; i++) {
        amount += response.data[i].amount
        receivable += response.data[i].receivable
      }
      datas.push(['Total', amount, receivable])

      setTotalAmount(amount)
      setTotalReceivable(receivable)

    }

    catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  }

  return (
    <div className={isDell ? "top-12" : "top-40"} style={{ marginRight: isDellReso ? '2vw' : isMac ? '5vw' : '10vw' }} >
      {/* <div className={rs.mainBody}> */}
      <MetaTag title="Reconciliation" />
      <div hidden={CCEnabled} className={rs.mainHeader}>Reconciliation</div>
      <div>
        <div className="twoCol bottom-16">
          <ButtonGroup>
            {
              status.map((item) => {
                return (
                  <Button
                    key={item.toLocaleLowerCase()}
                    onClick={() => setShowDetails(item)}
                    style={{
                      height: '40px',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      lineHeight: '24px',
                      color: show === item ? '#fff' : '#2b2d33',
                      border: show === item ? `${segmentStroke[0]}` : '',
                      borderTopLeftRadius: item === "Accounted" ? '4px' : '',
                      borderBottomLeftRadius: item === "Accounted" ? '4px' : '',
                      borderBottomRightRadius: item === "Unaccounted" ? '4px' : '',
                      borderTopRightRadius: item === "Unaccounted" ? '4px' : '',
                      backgroundColor: show === item ? `${segmentStroke[0]}` : '#fff',
                    }}>
                    {item}
                  </Button>
                )
              })
            }
          </ButtonGroup>

          <button className={`btn--${segments}`}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            disabled={datas.length == 0} onClick={() => exportTable()}>
            <Icon
              type="download"
              style={{ color: `${segments}`, fontSize: '18px', float: 'left', marginRight: '5px' }}
              theme="outlined" />
                Export CSV
          </button>
        </div>
      </div>

      <div className={rs.tableDiv}>
        <div className="top-20">
          <ClearFilter hideFilterBtn={hideClear} clearFilter={clearFilter} />
          {/* <div style={{display: 'flex'}}>
                  <div className={rs.codeStyle} style={{fontSize: 16}}>Filter</div>
                  <div style={{marginLeft: 10}} hidden={hideClear}>
                    <button className={rs.clearBtn} onClick={clearFilter}><Icon type="close-circle" theme="filled" style={{paddingRight: 4}} /> Clear Filter</button>
                  </div>
                </div>
                
                <div>
                  <div className={rs.noteStyle}><Icon type="info-circle" theme="filled" style={{paddingRight: 4}} />Click <b>Apply</b> button to show results.</div>
                </div> */}
        </div>

        <div style={{ display: 'flex' }} className="top-20">
          <Popover content={payment_methods} style={{ width: '24%' }} trigger="click" placement="bottomLeft">
            <Button
              style={{
                zIndex: 1,
                height: '40px',
                width: '24%',
                paddingTop: '1px',
                marginRight: '12px',
                color: paymentChannels != "" ? '#0D3D76' : '#000',
                border: paymentChannels != "" ? '1px solid #0D3D76' : '1px solid #c5cacf',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>Payment Method <img src={ArrowDown} style={{ marginLeft: '5px', color: paymentChannels != "" ? '#1DD28B' : '#000' }} /></Button>
          </Popover>

          <DatePicker
            style={{ zIndex: 1, width: '24%' }}
            disabledDate={disabledStartDate}
            size="large"
            format='MM-DD-YYYY'
            value={startMoment || null}
            placeholder="Start"
            onChange={onStartChange}
            onOpenChange={handleStartOpenChange}
          />
          <span style={{ padding: '8px' }}>-</span>
          <DatePicker
            style={{ zIndex: 1, marginRight: '12px', width: '24%' }}
            disabledDate={disabledEndDate}
            size="large"
            format='MM-DD-YYYY'
            value={endMoment || null}
            placeholder="End"
            onChange={onEndChange}
            open={endOpen}
            onOpenChange={handleEndOpenChange}
          />

          <Input
            placeholder="Search"
            style={{ width: '24%', height: '40px', marginRight: '12px' }}
            onChange={(e) => changeSearchVal(e.target.value)}
            suffix={<Icon type="search" />}
            value={searchValue}
          />

          <button disabled={disabledBtn} className={`btn--${segments} btn-height`} onClick={() => applyButton()}>Apply</button>

        </div>

        <TableStats amount={totalAmount} receivable={totalReceivable} />

        <Table
          // style={{marginTop: 20}}
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
          columns={columns}
          dataSource={renderColumns(searchValue, show, paymentChannels)}
          pagination={false}
        />
      </div>

    </div>
  )
}


export default ReconEnterprise