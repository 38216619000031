import React from "react";

export function UserDataCell({ name, email }) {
  return (
    <div style={{ display: "flex", flexFlow: "column nowrap" }}>
      <strong>{name}</strong>
      <small>{email}</small>
    </div>
  );
}
