import React from 'react';
import { Button, Card, Input, Switch, Alert, Steps, Select, Layout } from 'antd';
import AppBar from '../appBar/AppBar'
import {history} from '../../store/history'
import axios from 'axios'
import {BANKS} from '../constants/constants'
import { fadeInDown, fadeOutDown } from 'react-animations'
import {Handle401} from '../handle401/handle401'
import logo from '../../static/icons/logo.svg';


function onChange(checked) {
    console.log(`switch to ${checked}`);
  }

const { Header } = Layout;
const { Option } = Select;
const { Step } = Steps;
class SignUp extends React.Component{

    componentWillMount(){
        let current_path = window.location.pathname
        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            window.location.href = '/login?next='+current_path
        }
        else{
            if(localStorage.getItem('isCompleted') != "false"){
                if(window.location.href =='/mobile/username'){
                    window.location.href = '/mobile/dashboard'
                }else{
                    window.location.href = '/dashboard'
                }
            }else{
                 this.fetchMyAPI()
            }
        }    
    }
    
    state = {
        newUsername: "",
        firstName: "",
        lastName: "",
        readOnly: false,
        showAlert: true,
        stepCurrent: 0,
        hideAddressForm: true,
        hideUsernameForm: false,
        hideBankAccountForm: true,
        streetAddress: "",
        cityAddress: "",
        provinceAddress: "",
        postalCode: "",
        alertMessage: "",
        bank: "",
        accountNumber: "",
        accountName: ""
    }


    yourConfig = {
        headers: {
        Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }

   

    fetchMyAPI = async () => {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        

        try{
            let response = await axios.get(
                process.env.REACT_APP_API_URL + "/api/get_details/",
                yourConfig
            );
            if(response.data != ""){
                localStorage.setItem("firstName", response.data.first_name);
                localStorage.setItem("lastName", response.data.last_name);
                localStorage.setItem("email", response.data.email);
                localStorage.setItem("isCompleted", response.data.is_completed)
            }
        }
        catch(error){
            if((error.response)&&(error.response.status == 401)){
                Handle401()
            }
        }
        
    }

    updateUsername = async () => {
        
        
        // if(this.state.newUsername.length <= 4 || this.state.newUsername.length > 15){
        //     this.setState({
        //         showAlert: false,
        //         alertMessage: "Username must be 5 up to 15 characters"
        //     })
        // }
        // else if(this.state.newUsername.search(/^[a-zA-Z0-9_]+$/) == -1){
        //     this.setState({
        //         showAlert: false,
        //         alertMessage: "Special characters and spaces are not allowed"
        //     })
        // }
        // else if(this.state.newUsername.search(/^[0-9_]+$/) != -1){
        //     this.setState({
        //         showAlert: false,
        //         alertMessage: "Username must contain atleast one letter"
        //     })
        // }
        if(this.state.firstName.length == 0){
            this.setState({
                showAlert: false,
                alertMessage: "Fill up your first name"
            })
        }
        else if(this.state.lastName.length == 0){
            this.setState({
                showAlert: false,
                alertMessage: "Fill up your last name"
            })
        }
        else{
            let params = {
                first_name: this.state.firstName,
                last_name: this.state.lastName
            }
            try{
                let response = await axios.post(process.env.REACT_APP_API_URL + "/api/update_username/",params, this.yourConfig);

                if(response.status == 401){

                    Handle401()
                }
                if(response.data.status == "success"){
                    // this.setState({
                    //     readOnly: true,
                    //     stepCurrent: 1,
                    //     hideUsernameForm: true,
                    //     hideAddressForm: false,
                    //     showAlert: true
                    // })
                    
                    console.log(response.data.status)
                    localStorage.setItem("username", this.state.newUsername)
                    if(window.location.href =='/mobile/username'){
                        window.location.href = '/mobile/dashboard'
                    }else{
                        window.location.href = '/dashboard'
                    }

                }
                else{
                    this.setState({
                        showAlert: false,
                        alertMessage: response.data.message
                    })

                    
                }
            }catch (error) {
                if((error.response)&&(error.response.status == 401)){
                    Handle401()
                }

            }
        }
        

    };

    // updateAddress = async () =>{

    //     if(this.state.streetAddress == ""){
    //         this.setState({
    //             showAlert: false,
    //             alertMessage: "Please fill up your street address"
    //         })
    //     }
    //     else if(this.state.cityAddress == ""){
    //         this.setState({
    //             showAlert: false,
    //             alertMessage: "Please choose your city address"
    //         })
    //     }
    //     else if(this.state.provinceAddress == ""){
    //         this.setState({
    //             showAlert: false,
    //             alertMessage: "Please choose your province address"
    //         })
    //     }

    //     else{
    //         let params = {
    //             street_address: this.state.streetAddress,
    //             city_address: this.state.cityAddress,
    //             province_address: this.state.provinceAddress,
    //             postal_code: this.state.postalCode,
    
    //         }
    //         let response = await axios.post(process.env.REACT_APP_API_URL + "/api/update_address/",params, this.yourConfig);
           
    //         if(response.data.status == "success"){
    //             this.setState({
    //                 readOnly: true,
    //                 stepCurrent: 2,
    //                 hideBankAccountForm: false,
    //                 hideAddressForm: true,
    //                 showAlert: true

    //             })
    //         }
    //     }
        
    // }
    // updateBankAccount = async () =>{

    //     if(this.state.accountName == ""){
    //         this.setState({
    //             showAlert: false,
    //             alertMessage: "Please fill up your account name "
    //         })
    //     }
    //     else if(this.state.bank == ""){
    //         this.setState({
    //             showAlert: false,
    //             alertMessage: "Please choose your bank"
    //         })
    //     }
    //     else if(this.state.accountNumber == ""){
    //         this.setState({
    //             showAlert: false,
    //             alertMessage: "Please fill up your account number"
    //         })
    //     }

    //     else{
    //         let params = {
    //             account_name: this.state.accountName,
    //             account_number: this.state.accountNumber,
    //             bank: this.state.bank,
    
    //         }
    //         let response = await axios.post(process.env.REACT_APP_API_URL + "/api/update_bank_account/",params, this.yourConfig);
           
    //         if(response.data.status == "success"){
    //             window.location.href = "/dashboard"
    //         }
    //     }
        
    // }

    verifyStyle = {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '34px',
        color: '#2B2D33',
        marginTop: '40px',
        marginBottom: '20px',
    }

    textStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000000'
    }

    labelStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#000000'
    }

    nextStyle = {
        marginTop: '20px',
        width: '145px',
        height: '40px',
        
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
    }

    nominateStyle = {
        marginTop: '20px',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#000000',
        marginBottom: '20px'
    }


    centerItem = {
        display:'flex', 
        justifyContent: 'center'
    }

    onCitySelectChanged(value) {
        this.setState({
          cityAddress: value
        });
    }
    onProvinceSelectChanged(value) {
        this.setState({
          provinceAddress: value
        });
    }
    onBankChanged(value){
        this.setState({
            bank: value
          });
    }
    render(){

        const banks = BANKS;
        banks.sort();

        let bank = banks.length > 0 && banks.map((item, i) => {
        
        return (
            <Option key={i} value={item}>{item}</Option>
        )
        }, this)
        return(
            <div>
                <Header style={{height: '60px', display: 'flex', justifyContent: 'center', backgroundColor: '#fff', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)'}}>
                    <img src={logo} style={{marginBottom: '20px', marginTop: '12px'}} alt="#" />
                </Header>
                <div style={this.centerItem}>
                    <div style={{paddingLeft: '57px',paddingRight: '57px', marginTop: '50px', justifyContent: 'center', alignItems:'center', width: '600px'}}>
                        <div style={this.centerItem}>
                            <div style={this.verifyStyle}>You’re almost there</div>
                        </div>
                        <div style={this.centerItem}>
                            <div style={this.textStyle}>We only need few things from you to get started</div>
                        </div>
                        {/* <Steps size="small" current={this.state.stepCurrent} style={{marginTop: '20px'}}>
                            <Step title="Username" />
                            <Step title="Address" />
                            <Step title="Bank Account" />
                        </Steps> */}
                        <div hidden={this.state.hideUsernameForm}>
                            <Card style={{marginTop: "30px"}}>
                                <div hidden={this.state.showAlert}><Alert message={this.state.alertMessage} type="error" showIcon /></div>
                                <div style={this.nominateStyle}>Personal Information</div>
                                {/* <div style={this.labelStyle} readOnly={this.state.readOnly}>Username</div>
                                <Input style={{height: '40px', marginBottom: '20px'}} onChange={ (e) => this.setState({newUsername: e.target.value})}/> */}
                                <div style={this.labelStyle} readOnly={this.state.readOnly}>First Name</div>
                                <Input style={{height: '40px', marginBottom: '20px'}} onChange={ (e) => this.setState({firstName: e.target.value})}/>
                                <div style={this.labelStyle} readOnly={this.state.readOnly}>Last Name</div>
                                <Input style={{height: '40px', marginBottom: '20px'}} onChange={ (e) => this.setState({lastName: e.target.value})}/>

                                {/* <div style={this.nominateStyle}>Use only what you need</div>
                                <div style={{display: 'flex', justifyContent:'space-between'}}>
                                    <div style={this.textStyle}>Shipment Tracking</div>
                                    <Switch defaultChecked onChange={onChange} />
                                
                                </div> */}
                                {/* <div style={this.labelStyle}>Schedule pickup delivery and keep track of shipment status</div> */}
                            </Card>
                            <div style={{backgroundColor: '#F1F1F1', height: '80px', padding: '15px 20px 15px 20px'}}>
                                <Button style={{height: '50px', width: '100%', marginBottom: '20px'}} onClick={ () => this.updateUsername()}>Done</Button>
                            </div>
                        </div>
                        {/* <div hidden={this.state.hideAddressForm} style={{marginBottom: '20px'}}>
                            <Card style={{marginTop: "30px"}}>
                                <div hidden={this.state.showAlert}><Alert message="Username already exist" type="error" showIcon /></div>
                                <div style={this.nominateStyle}>Address Details</div>
                                <div style={this.labelStyle} onChange={ (e) => this.setState({streetAddress: e.target.value})} >Street Address</div>
                                <Input style={{height: '40px', marginBottom: '20px'}} onChange={ (e) => this.setState({streetAddress: e.target.value})}/>
                                <div  style={{display: 'flex', width:'100%', marginBottom: '20px'}}>
                                <div style={{width: '49%', marginRight: '2%'}}>
                                <div style={this.labelStyle}>Country</div>
                                <Select
                                    showSearch
                                    style={{ 
                                        width: '100%',
                                    }}
                                    size="large"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    >
                                    <Option value="Philippines">Philippines</Option>
                                </Select>
                                </div>
                                <div style={{width: '49%'}}>
                                <div style={this.labelStyle}>Province</div>
                                <Select
                                    showSearch
                                    style={{ 
                                        width: '100%',
                                    }}
                                    size="large"
                                    optionFilterProp="children"
                                    onChange={this.onProvinceSelectChanged.bind(this)}                                    
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    >
                                    <Option value="Metro Manila">Metro Manila</Option>
                                </Select>
                                </div>
                            </div>
                            <div  style={{display: 'flex', width:'100%', marginBottom: '20px'}}>
                                <div style={{width: '49%', marginRight: '2%'}}>
                                <div style={this.labelStyle}>City</div>
                                <Select
                                    showSearch
                                    style={{ 
                                        width: '100%',
                                    }}
                                    size="large"
                                    optionFilterProp="children"
                                    onChange={this.onCitySelectChanged.bind(this)}                                    
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    >
                                    <Option value="Caloocan">Caloocan</Option>
                                    <Option value="Las Pinas">Las Pinas</Option>
                                    <Option value="Makati">Makati</Option>
                                    <Option value="Malabon">Malabon</Option>
                                    <Option value="Mandaluyong">Mandaluyong	</Option>
                                    <Option value="Manila">Manila</Option>
                                    <Option value="Marikina">Marikina</Option>
                                    <Option value="Muntinlupa">Muntinlupa</Option>
                                    <Option value="Navotas">Navotas</Option>
                                    <Option value="Parañaque">Parañaque</Option>
                                    <Option value="Pasay">Pasay</Option>
                                    <Option value="Pasig">Pasig</Option>
                                    <Option value="Pateros">Pateros</Option>
                                    <Option value="Quezon City">Quezon City</Option>
                                    <Option value="San Juan">San Juan</Option>
                                    <Option value="Taguig">Taguig</Option>
                                    <Option value="Valenzuela">Valenzuela</Option>
                                </Select>
                                </div>
                                <div style={{width: '49%'}}>
                                <div style={this.labelStyle}>Postal / ZIP code</div>
                                <Input style={{width: '100%', height: '40px'}} onChange={ (e) => this.setState({postalCode: e.target.value}) }/>
                                </div>
                            </div>
                                
                            </Card>
                            <div style={{backgroundColor: '#F1F1F1', height: '80px', padding: '15px 20px 15px 20px'}}>
                                <Button style={{height: '50px', width: '49%', marginRight: '2%'}} onClick={ () => this.setState({stepCurrent: 2, hideBankAccountForm: false, hideAddressForm: true})}>Skip</Button>
                                <Button style={{height: '50px', width: '49%'}} onClick={ () => this.updateAddress()}>Next</Button>
                            </div>
                        </div>
                        <div hidden={this.state.hideBankAccountForm}>
                            <Card style={{marginTop: "30px"}}>
                                <div hidden={this.state.showAlert}><Alert message={this.state.alertMessage} type="error" showIcon /></div>
                                <div style={this.nominateStyle}>Bank Account</div>
                                <div style={this.labelStyle} readOnly={this.state.readOnly}>Bank</div>
                                <Select
                                    showSearch
                                    onChange={this.onBankChanged.bind(this)}
                                    placeholder="Choose a bank"
                                    style={{ width: '100%', marginBottom: '10px' }}
                                    size="large"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                {bank}

                                </Select>
                                <div  style={{display: 'flex', width:'100%', marginBottom: '20px'}}>
                                <div style={{width: '49%', marginRight: '2%'}}>
                                <div style={this.labelStyle}>Account Name</div>
                                <Input style={{width: '100%', height: '40px'}} onChange={ (e) => this.setState({accountName: e.target.value}) }/>
                                </div>
                                <div style={{width: '49%'}}>
                                <div style={this.labelStyle}>Account Number</div>
                                <Input style={{width: '100%', height: '40px'}} onChange={ (e) => this.setState({accountNumber: e.target.value}) }/>
                                </div>
                            </div>

                            </Card>
                            <div style={{backgroundColor: '#F1F1F1', height: '80px', padding: '15px 20px 15px 20px'}}>
                                <Button style={{height: '50px', width: '49%', marginRight: '2%'}} onClick={ () => window.location.href = '/dashboard'}>Skip</Button>
                                <Button style={{height: '50px', width: '49%'}} onClick={ () => this.updateBankAccount()}>Next</Button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default SignUp