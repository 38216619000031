import React from 'react';
import {Modal, Button, Input} from 'antd'

export default function ExportModal (props) {
    
    const {
        segments, isExportModalOpen, setIsExportModalOpen, exportTable, email, setEmail,
    } = props
    return(
        <Modal
            visible={isExportModalOpen}
            footer={null}
            width="360px"
            onCancel={()=> setIsExportModalOpen(false)}
          >
            <div style={{paddingTop: '20px'}}>
              <div style={{fontSize: '24px', color: '#2b2d33', marginBottom: '8px'}}>Export CSV</div>
              <div style={{fontSize: '16px', color: '#2b2d33', marginBottom: '16px'}}>
                Please enter your email address to send CSV reports directly to your email.
              </div>
              <div style={{fontSize:'14px', color: 'rgba(43, 45, 50, 0.8)', marginBottom: '5px'}}>Send to Email</div>
              <Input 
                size="large" 
                style={{width: '100%', marginBottom: '14px'}} 
                placeholder="Email"
                onChange={(e)=>setEmail(e.target.value)} />
              <button
                className={`btn--${segments}`} 
                onClick={()=>exportTable()}
                disabled={email == ''}
                style={{
                  width: '100%',
                  height: '48px',
                  color: '#fff',
                  fontWeight: '600'
                }}>Send
              </button>
            </div>
          </Modal>
    )
}