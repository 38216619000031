import React from 'react';

const QRBusinessName = props => {
    const {
        qrStaticDetails, profile
    } = props;

    return (
        <div>
            <div className="flex-center top-24 padding-bot-24 bottom-line">
                <div className="right-8">
                    <img src={qrStaticDetails && qrStaticDetails.profile_picture ? qrStaticDetails.profile_picture : profile}
                        className={qrStaticDetails && 'qr-profile-pic'}
                        alt="avatar" />
                </div>
                <div className="wc-header qr-business-name">
                    {qrStaticDetails && qrStaticDetails.business_name ? qrStaticDetails.business_name : ""}
                </div>
            </div>

            <div className="top-16 modal-sub-text">
                {qrStaticDetails && qrStaticDetails.personal_message ? qrStaticDetails.personal_message : ""}
            </div>
        </div>
    
    )
}

export default QRBusinessName;