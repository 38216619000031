import React from "react";
import "./InputField.css";

export function InputField({ label, name, value, onChange }) {
  return (
    <label className="fees">
      {label}
      <input
        className="fee-input"
        type="number"
        name={name}
        value={value}
        onChange={onChange}
      />
    </label>
  );
}
