import { axios } from "../../../lib/axios";

/**
 * API call to export revenues to the provided email address.
 * @param {string} email
 * @returns {Promise<{ status: string, message: string }>}
 */
export async function exportRevenues(email) {
  return axios.get(`/api/transfer/revenue/?is_export=true&email=${email}`);
}
