import React, { useEffect, useState } from "react";
import { Typography, Card, Skeleton, Result, Button, notification } from "antd";
import MetaTag from "../../../../../../components/meta_tag/meta_tag";
import {
  DateRangeFilter,
  FilterBar,
  GroupButton,
  SearchInput,
} from "../../../components";
import { RevenuesList } from "./revenues-list";
import { useRevenueWallets } from "../api/get-revenue-wallets";

const revenueWalletsMap = {
  UBP: "UnionBank",
  ALB: "AllBank",
  UBX: "UBx",
};

export function Revenues() {
  const [selectedRevenueWallet, setSelectedRevenueWallet] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const {
    data: revenueWallets,
    isLoading: revenueWalletsLoading,
    error: revenueWalletsError,
  } = useRevenueWallets();

  const handleWalletChange = (walletName) => {
    const selectedWallet = revenueWallets.data.find(
      (wallet) => revenueWalletsMap[wallet.name] === walletName,
    );
    setSelectedRevenueWallet(selectedWallet);
  };

  const handleStartDateChange = (date) => setTempStartDate(date);
  const handleEndDateChange = (date) => setTempEndDate(date);

  const handleApplyFilters = () => {
    if ((!tempStartDate && tempEndDate) || (tempStartDate && !tempEndDate)) {
      notification.error({
        message: "Error",
        description: "Both start date and end date are required for filtering.",
        placement: "topRight",
      });
      return;
    }

    setSearchQuery(tempSearchQuery);
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
  };

  const clearFilters = () => {
    setSearchQuery("");
    setTempSearchQuery("");
    setSelectedRevenueWallet(revenueWallets.data[0]);
    setStartDate("");
    setEndDate("");
    setTempStartDate("");
    setTempEndDate("");
  };

  useEffect(() => {
    if (!revenueWalletsLoading && !revenueWalletsError && revenueWallets) {
      setSelectedRevenueWallet(revenueWallets.data[0]);
    }
  }, [revenueWallets, revenueWalletsLoading, revenueWalletsError]);

  if (revenueWalletsLoading) {
    return (
      <div
        style={{
          margin: "0 2.5em 2.5em 2.5em",
          display: "flex",
          gap: "2em",
          flexDirection: "column",
        }}
      >
        <Skeleton active />
      </div>
    );
  }

  if (revenueWalletsError) {
    return (
      <div
        style={{
          margin: "0 2.5em 2.5em 2.5em",
          display: "flex",
          gap: "2em",
          flexDirection: "column",
        }}
      >
        <Result
          status="error"
          title={revenueWalletsError.message}
          subTitle="Please try again by refreshing the page or contact the developers."
        />
      </div>
    );
  }

  return (
    <div
      style={{
        margin: "0 2.5em 2.5em 2.5em",
        display: "flex",
        gap: "2em",
        flexDirection: "column",
      }}
    >
      <MetaTag title="CashBux Revenue Wallets" />
      <div>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "20px",
            color: "#2B2D33",
          }}
        >
          Revenue Monitoring
        </Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <GroupButton
          options={revenueWallets.data.map(
            (wallet) => revenueWalletsMap[wallet.name],
          )}
          selectedOption={revenueWalletsMap[selectedRevenueWallet.name]}
          onSelect={handleWalletChange}
        />
        <Card>
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <FilterBar
              initialValue={revenueWallets.data[0]}
              selectedOption={selectedRevenueWallet}
              searchQuery={searchQuery}
              startDate={startDate}
              endDate={endDate}
              clearFilters={clearFilters}
            />
            <div style={{ display: "flex", gap: "1em" }}>
              <DateRangeFilter
                tempStartDate={tempStartDate}
                tempEndDate={tempEndDate}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                handleApplyDateFilter={false}
              />
              <SearchInput
                tempSearchQuery={tempSearchQuery}
                setTempSearchQuery={setTempSearchQuery}
                placeholder="Search by Name, Email, or Merchant."
                handleApplySearch={false}
              />
              <Button onClick={handleApplyFilters}>Apply</Button>
            </div>
            {selectedRevenueWallet.id && (
              <RevenuesList
                walletId={selectedRevenueWallet.id}
                searchQuery={searchQuery}
                startDate={startDate}
                endDate={endDate}
              />
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}
