import React,{ useEffect, useState } from 'react';
import { Card, Switch } from 'antd';
import axios from 'axios';
import {Handle401} from '../../../handle401/handle401'
import feesServices from '../feesServices';

const TranxFeeToggle = ({pUid, init_enabled}) => {
  const [enabled, setEnabled] = useState(init_enabled);
  const [disabled, setDisabled] = useState(false);

    useEffect(() => {
      setEnabled(init_enabled)
    }, [init_enabled])

  const handleChange = (event) => {
    setDisabled(true);
    valueUpdate(!enabled);
  }

    async function valueUpdate(value){
        let params = {
            "shoulder_enabled": value
        }

        try{
            let res = await feesServices.userTranxFeeToggle(pUid, params);

            if (res.status == "success"){
              setEnabled(value);
            }
        }
        catch(error){
            if((error.response)&&(error.response.status == 401)){
                Handle401()
            }
        }
        
        setDisabled(false);
        
    }
  return (
    <Card size="small" style={styles.root}>
      <div style={styles.main}>
        <div style={styles.textContent}>
          <span style={styles.font1}><strong>Transaction Fee is {enabled ? 'enabled' : 'disabled'}</strong></span>
          { enabled ?
          <label style={styles.font2}>The <strong>Transaction Fee</strong> will be deducted from your total payment collection.</label>
          :
          <label style={styles.font2}>The <strong>Transaction Fee</strong> will be added to the total amount upon customer checkout.</label>
          }
        </div>
        <Switch checked={enabled} onChange={handleChange} style={{margin: '12px 20px 12px 12px', backgroundColor: `${enabled ? '#F5922F' : '#D1D5DD'}`}} disabled={disabled}/>

      </div>
    </Card>
  )
}

const styles = {
  root: {
    margin: '32px 12px 20px 12px'
  },
  main: {
    padding: 16,
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'space-between',
  },
  textContent: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  font1: {
    color: '#000000',
    fontSize: 16,
    fontWeight: '500'
  },
  font2: {
    color: '#909196',
    fontSize: 14,
    textAlign: 'left'
  },
}

export default TranxFeeToggle;