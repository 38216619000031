import React, {useEffect} from 'react'
import { Button, DatePicker, Input, Table, Select, Icon, Modal, Pagination, Menu, Typography, Dropdown, message} from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import { getRecon, getSubwalletRecon } from './../reconAction';
import { history } from '../../../../store/history';
import { getCutoffError } from './../reconAction';
import moment from 'moment';
import axios from 'axios'

//Components
import MetagTag from '../../../meta_tag/meta_tag'
import UploadModal from '../upload_modal';
import GroupButton from '../../../custom_components/groupButton';

//Icons
import HumanIcon from '../../../../static/icons/human.svg'
import ActiveCheck from '../../../../static/icons/active_check.svg'
import MetaTag from '../../../meta_tag/meta_tag';

const TransferMoneyRecon = () => {

    const dispatch = useDispatch();
    const Option = Select
    const ButtonGroup = Button.Group;

    const yourConfig = {
        headers: {
           Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }
    const {recon, loading, error}  = useSelector(state => state.recon)
    const {subwallet_payment_recon, subwallet_payment_loading, subwallet_payment_error}  = useSelector(state => state.recon)

    const userType = localStorage.getItem("userType")
    const jwtToken = localStorage.getItem("jwtToken")
    const segments = localStorage.getItem('segments');

    //Page
    const [pageSize, setPageSize] = React.useState(10)
    const [currentPage, setCurrentPage] = React.useState(1)

    const [subPageSize, setSubPageSize] = React.useState(10)
    const [subCurrentPage, setSubCurrentPage] = React.useState(1)

    //Date
    const [startDate, setStartDate] = React.useState("")
    const [endDate, setEndDate] = React.useState("")

    //Moment
    const [startMoment, setStartMoment] = React.useState("")
    const [endMoment, setEndMoment] = React.useState("")
    const [endOpen, setEndOpen] = React.useState(false)

    //Other State
    const [selected, setSelected] = React.useState("Paid")
    const [selectedTable, setSelectedTable] = React.useState("Wallet Transfers")
    const [mode, setMode] = React.useState("All")
    const [hover, setHover] = React.useState(false)
    const [modal, setModal] = React.useState(false)
    const [email, setEmail] = React.useState("")
    const [masterUsers, setMasterUsers] = React.useState(null)
    const [masterSelected, setMasterSelected] = React.useState("Choose Source")
    const [isFiltered, setIsFiltered] = React.useState(false)
    const [exportModal, setExportModal] = React.useState(false)
    const [masterUserModal, setMasterUserModal] = React.useState(false)
    const [temporarySelectedMaster, setTemporarySelectedMaster] = React.useState("")
    const [searchMaster, setSearchMaster] = React.useState("")

    const disabledStartDate = startValue => {
        if (!startValue || !endMoment) {
          return false;
        }
        return startValue && startValue > moment().endOf('day');
    }
    const disabledEndDate = endValue => {
        if (!endValue || !startMoment) {
            return false;
        }
        return endValue.valueOf() <= startMoment.valueOf();
    };

    const onStartChange = value => {
        setStartDate(value.format("MM-DD-YYYY"))
        setStartMoment(value)
        setIsFiltered(true)
    }

    const onEndChange = value => {
        setEndDate(value.format("MM-DD-YYYY"))
        setEndMoment(value)
        setIsFiltered(true)
    }
    
    const handleStartOpenChange = open => {
        if (!open) {
          setEndOpen(true)
        }
    }
    useEffect(()=>{
        if(jwtToken == "" || jwtToken == null){
            history.push('/login')
        }
        else{
            if(userType == "ME" || userType == "CO"){
                history.push('/dashboard')
            }
            else if(userType == "PS"){
                history.push('/orders')
            }
            else if(userType == "MA"){
                history.push('/overview')
            }
            else{
                getMasters(searchMaster)
            }
        }
    },[])

    const actions = (details, type) => (
        <Menu style={{ width: '180px'}}>
          <Menu.Item key="0" style={styles.menuStyle} onClick={()=>markAsPaid(details.id, type)}>
            Mark as {selected == 'Paid' ? 'unpaid' : 'paid'}
          </Menu.Item>
          {/* <Menu.Item key="1" style={styles.menuStyle}>
            Check details
          </Menu.Item> */}
        </Menu>
    )

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            fixed: 'left',
            render: (text) => {
                return <div style={styles.dataStyle}>{text}</div>
            }
        },
        {
            title: 'Source',
            dataIndex: 'source',
            render: (text) => {
                return <div style={styles.dataStyle}>{text}</div>
            }
        },
        {
            title: 'Destination',
            dataIndex: 'destination',
            render: (text) => {
                return <div style={styles.dataStyle}>{text}</div>
            }
        },
        {
            title: 'Total Amount',
            dataIndex: 'amount',
            render: (text) => {
                return <div style={styles.dataStyle}>{text}</div>
            }
        },
        {
            title: 'Fee',
            dataIndex: 'fee',
            render: (text) => {
                return <div style={styles.dataStyle}>{text}</div>
            }
        },
        {
            title: 'Payment Type',
            dataIndex: 'payment_type',
            render: (text) => {
                return <div style={styles.dataStyle}>{text}</div>
            }
        },
        {
            title: 'Action',
            fixed: 'right',
            render: (text, record) => (
                <span>
                    <Dropdown overlay={() => actions(record, 0)} placement="bottomLeft" trigger={['click']} >
                        <Icon type="ellipsis" style={{fontSize: '24px'}} />
                    </Dropdown>
                </span>
            ),
        }
    ];

    const columns2 = [
        {
            title: 'ID',
            dataIndex: 'id',
            fixed: 'left',
            width: '100px',
            render: (text) => {
                return <div style={styles.dataStyle}>{text}</div>
            }
        },
        {
            title: 'Sub-Wallet',
            width: '250px',
            render: (record) => {
                return (
                <div>
                    <div style={{...styles.dataStyle, fontWeight: '700'}}>{record.subuser_name}</div>
                    <div style={{...styles.dataStyle, fontSize: '12px'}}>{record.subuser_email}</div>
                </div>)
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            width: '200px',
            render: (text) => {
                return <div style={styles.dataStyle}>{text}</div>
            }
        },
        {
            title: 'Billing Period',
            dataIndex: 'created_at',
            width: '300px',
            render: (text) => {
                return <div style={styles.dataStyle}>{text}</div>
            }
        },
        {
            title: 'Action',
            fixed: 'right',
            render: (text, record) => (
                <span>
                    <Dropdown overlay={() => actions(record, 1)} placement="bottomLeft" trigger={['click']} >
                        <Icon type="ellipsis" style={{fontSize: '24px'}} />
                    </Dropdown>
                </span>
            ),
        }
    ];

    const masterUserColumns = [
        {
            title: 'Master Wallet',
            dataIndex: 'id',
            render: (text, record) => {
                return (
                <a style={{display: 'flex', justifyContent: 'space-between'}}
                    onClick={()=>setTemporarySelectedMaster(record.email)}>
                    <div>
                        <a style={styles.businessNameStyle}>{record.business_name}</a>
                        <div style={styles.emailStyle}>{record.email}</div>
                    </div>
                    <img src={ActiveCheck} hidden={record.email != temporarySelectedMaster}/>
                </a>
                )
                
            }
        },
    ]
    const closeModal = () => {
        setModal(false)
    }

    const getMasters = async(search) => {
        let response = await axios.get(
            process.env.REACT_APP_API_URL + '/api/admin/users/masterwallet/?search='+search, yourConfig
        );
        if(response.data.status=='success'){
            setMasterUsers(response.data.users)
        }else{
            message.error(response.data.message)
        }

    }
    const exportTable = async(tableType) => {   
        
        let response = {}
        let encodedMasterEmail = encodeURIComponent(masterSelected)

        if (tableType == 0){
            response = await axios.get(
                process.env.REACT_APP_API_URL + '/api/recon/transfer_money/?email='+email+'&type='+type_dict[selected]+'&start='+startDate+'&end='+endDate+'&export='+true+'&mode='+mode+'&master_email='+encodedMasterEmail,yourConfig
            );
        }
        else{
            response = await axios.get(
                process.env.REACT_APP_API_URL + '/api/recon/subwallet_payments/?email='+email+'&type='+type_dict[selected]+'&start='+startDate+'&end='+endDate+'&export='+true+'&master_email='+encodedMasterEmail,yourConfig
            );
        }
        
        
        if(response.data.status=='success'){
            setExportModal(false)
            message.success(response.data.message)
        }
        else{
            setExportModal(false)
            message.error(response.data.message)
        }
    };

    const markAsPaid = async(id, payment_type) => {
        let params = {
            id: id,
            payment_type: payment_type,
            to_unpaid: selected == 'Paid'
        }
        let response = await axios.post(
            process.env.REACT_APP_API_URL + "/api/recon/multiwallet_billing_paid/",
            params,yourConfig
        );
        if(response.data.status=='success'){
            if(payment_type == 0){
                dispatch(getRecon(startDate, endDate, pageSize, 1, 'transfer_money', type_dict[selected], mode, masterSelected))
            }
            else{
                dispatch(getSubwalletRecon(startDate, endDate, pageSize, currentPage, 'subwallet_payments', type_dict[selected], masterSelected))
            }
            message.success(response.data.message)
        }
        else{
            message.error(response.data.message)
        }
    }

    const pageSelect = (e, type) => {
        if(type == 0){
            setCurrentPage(e)
            dispatch(getRecon(startDate, endDate, pageSize, e, 'transfer_money', type_dict[selected], mode, masterSelected))
        }
        else{
            setSubCurrentPage(e)
            dispatch(getSubwalletRecon(startDate, endDate, pageSize, e, 'subwallet_payments', type_dict[selected], masterSelected))
        }

    }
    
    const changePageSize = (e, type) => {
        if(type == 0){
            setPageSize(e)
            setCurrentPage(1)
            dispatch(getRecon(startDate, endDate, e, 1, 'transfer_money', type_dict[selected], mode, masterSelected))
        }
        else{
            setSubPageSize(e)
            setSubCurrentPage(1)
            dispatch(getSubwalletRecon(startDate, endDate, e, 1, 'subwallet_payments', type_dict[selected], masterSelected))
        }
        
    }
    
    const getCount = () => {
        return recon && recon.count ? recon.count : 0;
    }

    const getCount2 = () => {
        return subwallet_payment_recon && subwallet_payment_recon.count ? subwallet_payment_recon.count : 0;
    }

    const disabledApplyButton = () => {
        return startDate == '' || endDate == "" || masterSelected == 'Choose Source'
    }

    const applyFilter = () => {
        setCurrentPage(1)
        dispatch(getRecon(startDate, endDate, pageSize, 1, 'transfer_money', type_dict[selected], mode, masterSelected))
        dispatch(getSubwalletRecon(startDate, endDate, pageSize, 1, 'subwallet_payments', type_dict[selected], masterSelected))
    }

    const clearFilter = () => {
        setMasterSelected("Choose Source")
        setTemporarySelectedMaster("")
        setMode("All")
        setStartDate("")
        setStartMoment("")
        setEndDate("")
        setEndMoment("")
        setIsFiltered(false)
        dispatch(getRecon("", "", 10, 1, 'transfer_money', type_dict[selected], mode, ""))
        dispatch(getSubwalletRecon("", "", 10, 1, 'subwallet_payments', type_dict[selected], ""))
        dispatch(getCutoffError("clear"))
    }

    const type_dict = {
        'Paid': 'Accounted',
        'Unpaid': 'Unaccounted', 
    }

    const table_types = [
        'Wallet Transfers',
        'Sub-wallet Payments'
    ]

    return(
        <div style={{ padding: '38px 42px 38px 42px'}}>
            <MetaTag title="Multi-wallet Billing"/>
            <div style={styles.headerStyle}>Multi-wallet Billing</div>
            <div style={{ marginBottom: '25px', display: 'flex', justifyContent: 'space-between' }}>
                
                <GroupButton status={Object.keys(type_dict)} selected={selected} segments={segments} handleClick={(e) => 
                    {
                        setSelected(e.target.value);
                        if(startDate != "" && endDate != "" && masterSelected != "Choose Source"){
                            dispatch(getRecon(startDate, endDate, pageSize, currentPage, 'transfer_money', type_dict[e.target.value], mode, masterSelected))
                            dispatch(getSubwalletRecon(startDate, endDate, pageSize, 1, 'subwallet_payments', type_dict[e.target.value], masterSelected))
                        }
                    }} />
                <div>
                    <button className={`outline-btn--${segments}`} style={{...styles.exportBtn, width: '141px', marginRight: '10px'}} onClick={()=> setModal(true)}><Icon type='upload'/> Import CSV</button>
                    <button className={`outline-btn--${segments}`} style={styles.exportBtn} onClick={()=> setExportModal(true)}><Icon type='download'/> Download CSV</button>
                    <UploadModal modal={modal} channel="transfer_money" cutoff={endDate} closeModal={closeModal}/>
                </div>
            </div>
            <div style={{backgroundColor: '#FFF', border: '1px solid #D1D5DD'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', padding: '20px'}}>
                    <div style={{display: 'flex'}}>
                        <div style={{color: '#000', fontSize: '16px', fontWeight: 'bold'}}>Filter</div>
                        <a style={styles.clearFilter} hidden={!isFiltered} onClick={()=>clearFilter()}>
                            <Icon type="close-circle" theme="filled" style={{fontSize: '10px', marginRight: '2px'}}/> 
                            <span style={{fontSize: '12px', color: '#000'}}>  Clear Filter</span>
                        </a>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent:'flex-start', padding: '0px 20px 20px 20px', zIndex: 1}}>
                    <a onClick={()=>setMasterUserModal(true)}>
                        <div style={styles.selectEnterprise}>
                            <div style={styles.selectedMasterText}>{masterSelected}</div>
                            <img src={HumanIcon} alt="#"/>
                        </div>
                    </a>
                    <Select
                        size="large"
                        value={mode}
                        onChange={(e)=> {setMode(e); setIsFiltered(true)}}
                        style={{width: '150px', color: '#000'}}
                        >
                        <Option value="All">All</Option>
                        <Option value="MTS">Master to Sub</Option>
                        <Option value="STM">Sub to Master</Option>
                    </Select>   
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledStartDate}
                        // defaultValue={moment()}
                        value={startMoment}
                        size="large" picker="month"
                        onChange={onStartChange}
                        onOpenChange={handleStartOpenChange}
                        placeholder='Start date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <span style={{ paddingTop: 8 }}>-</span>
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledEndDate}
                        // defaultValue={moment()}
                        value={endMoment}
                        size="large"
                        picker="month"
                        onChange={onEndChange}
                        onOpenChange={(open)=>setEndOpen(open)}
                        open={endOpen}
                        placeholder='End date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />

                    <button
                        disabled={disabledApplyButton()}
                        className={`btn--${segments}`} 
                        style={{
                            ...styles.applyBtn,
                            opacity:  disabledApplyButton() ? '0.5' : '1',
                            cursor: disabledApplyButton() ? 'not-allowed' : 'default'
                        }} 
                        onClick={()=>applyFilter()}
                    >Apply</button>
                </div>
                <div style={{display: 'flex', margin: '10px 32px 20px 52px'}}>
                    <div style={{display: 'flex', marginRight: '32px'}}>
                        <Typography style={styles.text}>Total Processing Fee: </Typography>
                        <Typography style={styles.text}><b>₱ {
                        (subwallet_payment_recon && recon) ? parseFloat(parseInt(subwallet_payment_recon.total_subwallet_payment ? subwallet_payment_recon.total_subwallet_payment : 0.00) + parseInt(recon.total_fee ? recon.total_fee : 0.00)).toFixed(2) : 0.00
                        }</b></Typography>
                    </div>
                    <div style={{display: 'flex', marginRight: '32px'}}>
                        <Typography style={styles.text}>Total Wallet Transfer Fee: </Typography>
                        <Typography style={styles.text}>₱ <b>{recon.total_fee ? parseFloat(recon.total_fee).toFixed(2) : 0.00}</b></Typography>
                    </div>
                    <div style={{display: 'flex'}}>
                        <Typography style={styles.text}>Total Sub-wallet fee: </Typography>
                        <Typography style={styles.text}>₱ <b>{subwallet_payment_recon && subwallet_payment_recon.total_subwallet_payment ? parseFloat(subwallet_payment_recon.total_subwallet_payment).toFixed(2) : 0.00}</b></Typography>
                    </div>
                    
                </div>
                <div style={{padding: '0px 20px 20px 20px', zIndex: 1}}>
                    <Button.Group >
                    {
                        table_types.map((btn, i) => {
                        return (    
                            <Button
                                key={btn}
                                value={btn}
                                onClick={()=>setSelectedTable(btn)}
                                style={{
                                    ...styles.btnGroup, 
                                    color: selectedTable === btn ? '#0A315E' : '#909196',
                                    borderBottom: selectedTable === btn ? '4px solid #0A315E' : '0' 
                                }}
                                >
                                {btn}
                            </Button>
                        )})
                    }
                    </Button.Group>
                </div>
                <div hidden={selectedTable != "Wallet Transfers"}>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                        loading={loading}
                        columns={columns}
                        dataSource={recon && recon.transactions ? recon.transactions : []}
                        pagination={false} 
                    />
                    <div style={{ width: '100%', display: 'flex', marginTop: '20px', }}>
                        <Select
                            showSearch
                            onChange={(ev)=> changePageSize(ev, 0)}
                            placeholder="Page Size"
                            style={{ width: '11%', marginBottom: '10px', marginLeft: 'auto', marginRight: '20px' }}
                            size="small"
                        >
                            <Option key={1} value={5}>Show 5</Option>
                            <Option key={2} value={10}>Show 10</Option>
                            <Option key={3} value={20}>Show 20</Option>
                            <Option key={4} value={50}>Show 50</Option>

                        </Select>
                        <Pagination 
                            size="small"
                            total={getCount()} 
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                            onChange={(e)=>pageSelect(e, 0)} 
                            defaultCurrent={1} key={Math.random()} 
                            pageSize={pageSize} 
                            current={currentPage}/>
                    </div>
                </div>
                <div hidden={selectedTable != "Sub-wallet Payments"}>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                        loading={loading}
                        columns={columns2}
                        dataSource={subwallet_payment_recon && subwallet_payment_recon.payments ? subwallet_payment_recon.payments : []}
                        pagination={false} 
                    />
                    <div style={{ width: '100%', display: 'flex', marginTop: '20px', }}>
                        <Select
                            showSearch
                            onChange={(ev)=> changePageSize(ev, 1)}
                            placeholder="Page Size"
                            style={{ width: '11%', marginBottom: '10px', marginLeft: 'auto', marginRight: '20px' }}
                            size="small"
                        >
                            <Option key={1} value={5}>Show 5</Option>
                            <Option key={2} value={10}>Show 10</Option>
                            <Option key={3} value={20}>Show 20</Option>
                            <Option key={4} value={50}>Show 50</Option>

                        </Select>
                        <Pagination 
                            size="small"
                            total={getCount2()} 
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                            onChange={(ev)=> pageSelect(ev, 1)}
                            defaultCurrent={1} key={Math.random()} 
                            pageSize={subPageSize} 
                            current={subCurrentPage}/>
                    </div>
                </div>
            </div>
            <Modal
                visible={exportModal}
                footer={null}
                width="360px"
                onCancel={()=>setExportModal(false)}
            >
                <div style={{paddingTop: '20px'}}>
                <div style={{fontSize: '24px', color: '#2b2d33', marginBottom: '8px'}}>Export CSV</div>
                <div style={{fontSize: '16px', color: '#2b2d33', marginBottom: '16px'}}>
                    Please enter your email address to send CSV reports directly to your email.
                </div>
                <div style={{fontSize:'14px', color: 'rgba(43, 45, 50, 0.8)', marginBottom: '5px'}}>Send to Email</div>
                <Input 
                    size="large" 
                    style={{width: '100%', marginBottom: '14px'}} 
                    placeholder="Email"
                    onChange={(e)=>setEmail(e.target.value)} />
                <Button 
                    onClick={()=>exportTable(selectedTable == "Wallet Transfers" ? 0 : 1)}
                    disabled={email == ''}
                    style={{
                    backgroundColor: '#F5922F',
                    width: '100%',
                    height: '48px',
                    color: '#fff',
                    border: '1px solid #F5922F',
                    fontWeight: '600'
                    }}>Send
                </Button>
                </div>
            </Modal>
            <Modal
                visible={masterUserModal}
                footer={null}
                width="360px"
                onCancel={()=>setMasterUserModal(false)}
            >
                <div style={{paddingTop: '20px'}}>
                    <div style={{fontSize: '24px', 
                        color: '#2b2d33', 
                        marginBottom: '10px', 
                        fontWeight: '300'
                    }}>
                    Choose Source
                    </div>
                    <Input size="large" 
                        placeholder="Search" 
                        style={{marginBottom: '10px'}}
                        onChange={(e)=>{
                            setSearchMaster(e.target.value);
                            getMasters(e.target.value)
                        }}/>
                    <Table
                        pagination={{pageSize: 5}}
                        size="middle"
                        columns={masterUserColumns}
                        dataSource={masterUsers}
                    />
                    <div style={{display: 'flex', marginTop: '20px'}}>
                        <Button style={styles.cancelBtn} onClick={()=>setMasterUserModal(false)}>Cancel</Button>
                        <Button style={styles.confirmBtn} onClick={()=>{setMasterUserModal(false); setMasterSelected(temporarySelectedMaster)}}>Confirm</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}


const styles = {
    headerStyle: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '30px'
    },
    text: {
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '24px',
        color: '#2B2D33',
        marginRight: '10px'
    },
    tableHeaderStyle: {
        fontStyle: 'normal',
        fontSize: '16px',
        color: '#333333',
        marginBottom: '20px',
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '20px',
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 158,
        padding: '0 8px 0 8px',
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    newSummary: {
        width: '33%', 
        border: '1px solid #D1D5DD',
        display: 'flex', 
        justifyContent: 'center', 
        padding: '5px 0px 5px 0px'
    },
    clearFilter: {
        padding: '3px 8px 4px 8px',
        backgroundColor: '#E6EAF0',
        borderRadius: '50px',
        height: '29px',
        marginLeft: '10px',
        color: '#000'
    },
    applyBtn: {
        zIndex: 1, 
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: 72,
        cursor: 'pointer',
    },

    selectEnterprise: {
        width: '190px',
        height: '40px',
        borderRadius: '4px',
        padding: '8px 16px 8px 16px',
        border: '1px solid #D1D5DD',
        marginRight: '10px',
        display: 'flex',
        justifyContent: 'space-between'
    },

    selectedMasterText: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2b2d33',
        width: '200px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },

    businessNameStyle: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2B2D33'
    },
    emailStyle:{
          maxWidth: '150px',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '12px',
          lineHeight: '24px',
          color: '#2B2D33',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
    },
    exportBtn: {
        cursor: 'pointer',
        margin: 0,
        fontSize: '16px',
        fontWeight: '500',
        width: '166px',
        height: '40px'
    },
    cancelBtn: {
        height: '48px',
        width: '49%',
        marginRight: '2%',
        border: '1px solid #F5922F',
        borderRadius: '4px',
        color: '#F5922F',
        fontSize: '16px',
        fontWeight: '600'
    },
    dataStyle:{
        fontSize: '14px',
        color: '#2b2d32',
        fontWeight: '400'
    },
    confirmBtn: {
        height: '48px',
        width: '49%',
        border: '1px solid #F5922F',
        backgroundColor: '#F5922F',
        borderRadius: '4px',
        color: '#FFF',
        fontSize: '16px',
        fontWeight: '600'
    },
    menuStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33',
        padding: '10px',
        margin: 'auto'
    },
    btnGroup: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: '20px 32px 24px 32px',
        borderColor: 'transparent',
        backgroundColor: 'transparent'
    },
}

export default TransferMoneyRecon