import { makeStyles } from '@material-ui/core';

const reconStyle = makeStyles(theme => ({
    mainBody: {
        paddingTop: '20px', 
        paddingLeft: '42px',
         paddingRight: '42px' 
    },

    mainHeader: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '20px'
    },

    actionDiv: {
        marginTop: 32,
        display: 'flex',
        justifyContent: 'space-between'
    },

    exportBtn: {
        width: '100px',
        backgroundColor: "#1DD28B",
        border: "#1DD28B",
        height: "40px",
        borderRadius: "10px",
        color: '#fff',
        fontWeight: '600',
        cursor: 'pointer'
    },

    normalText: {
        fontSize: 16,
        lineHeight: '24px',
        color: '#2b2d33',
        marginTop: '16px',
    },

    linkStyle: {
        color: '#F5922F',
        fontWeight: 'bold',
        fontSize: 16,
        lineHeight: '24px'
    },

    newSummaryDiv: {
        display:'flex',
        justifyContent: 'space-between',
        margin: '20px 0px 20px 0px',
        borderTop: '1px solid #E6EAF0',
        borderBottom: '1px solid #E6EAF0',
    },

    tableDiv: {
        padding: '15px 20px',
        background: '#fff',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    },

    pillFlex: {
        marginBottom: '8px', 
        display: 'flex', 
        justifyContent: 'space-between' 
    },

    activeBtn: {
        background: '#f5922f',
        border: '1px solid #f5922f',
        borderRadius: '4px',
        color: '#fff',
        height: '40px',
        width: 93,
        cursor: 'pointer',
        outline: 0,
        '&:hover': {
            opacity: '0.8',
        },

        '&:active': {
            opacity: 1,
        }

    },

    disabledBtn: {
        outline: 0,
        background: '#f5922f',
        border: '1px solid #f5922f',
        borderRadius: '4px',
        color: '#fff',
        height: '40px',
        width: 93,
        opacity: 0.5,
        cursor: 'not-allowed'
    },

    totalDiv: {
        padding: '12px 0px 12px 0px',
        width: '50%', textAlign: 'center',
    },

    sumSubDiv: {
        color: '#000', 
        marginRight: '5px'
    },

    sumSubValue: {
        color:'#0D3D76', 
        fontWeight: 'bold', 
        marginRight: '32px'
    },

    clearBtn: {
        borderRadius: '24px',
        background: '#E6EAF0',
        color: '#000000',
        fontSize: 12,
        width: 120,
        height: '29px',
        border: '1px solid #e6eaf0',
        cursor: 'pointer',
        outline: 0,
        '&:hover': {
            opacity: '0.8',
        },

        '&:active': {
            opacity: 1,
        }
    },

    btnGrp: {
        height: '40px',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '24px',
    },

    reconCard: {
        padding: '10px 0px 10px 0px',
        backgroundColor: '#f4f6f9',
        textAlign: 'left'
    },

    transactionText: {
        color: '#909196',
        fontSize: 14,
        lineHeight: '16px',
        fontWeight: 'bold',
        textAlign: 'left'
    },

    nothingToShow: {
        color: '#D1D5DD',
        marginTop: 16,
        fontSize: 14,
        lineHeight: '16px',
        fontWeight: 'bold',
        width: '100%',
        height: '217px',
        border: '1px solid #e7eaf0',
        borderShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        boxSizing: 'border-box',
        borderRadius: '4px',
        background: '#fff',
        padding: 100,
    },

    mainText: {
        fontWeight: 'bold',
        color: '#2b2d33',
        fontSize: 14,
        lineHeight: '24px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap', 
        width: '50%'
    },

    cardFooter: {
        marginTop: 16, 
        paddingTop: 16, 
        borderTop: '1px solid #E6EAF0'
    },

    dayPill: {
        background: '#E6EAF0',
        border: '1px solid #e6eaf0',
        borderRadius: '24px',
        color: '##2B2D33',
        fontWeight: '600',
        fontSize: '12px',
        height: 30,
        width: 100,
        textAlign: 'center',
        outline: 0
    },
    
    iconLinkStyle: {
        color: '#000000', 
        position: 'relative'
    },
    
    badgePos: {
        position: 'absolute', 
        top: '-10px', 
        right: '-10px'
    }



}))

export default reconStyle;