import React, {useEffect} from 'react'
import ecomStyles from '../../static/css/Ecomx';
import CheckSeat from './check_seat'
import CheckSeatMobile from './check_seat_mobile'
import SignUpEcomxMobile from './signup_mobile'
import SignUpWeb from './signup_web'
import RegFailed from './reg_failed'
import RegFailedMobile from './reg_failed_mobile'
import axios from 'axios'
import {isMobile} from 'react-device-detect'



const Ecom = props => {
    const ecom = ecomStyles();

    const [show, setShow] = React.useState(0);
    
    function showFields(){
        setShow(1)
    }

    function showError(){
        setShow(2)
    }


    async function checkSeats () {

        let response = await axios.get(
            process.env.REACT_APP_API_URL + "/api/ecomx/seats/"
        )

        if(response.data.seats > 0){
            showFields()
        }else{
            showError()
        }
    }
    

    useEffect(() => {
        checkSeats()
    }, []);

    return(
        <div>
            <div className={ecom.preMain} hidden={show != 0}>
                {isMobile ? <CheckSeatMobile/> :
                    <CheckSeat/>
                }
            </div>
            <div hidden={show != 1}>
                {isMobile ? <SignUpEcomxMobile/> :
                    <SignUpWeb/>
                }
            </div>
            <div hidden={show != 2}>
                {isMobile ? <RegFailedMobile/> :
                    <RegFailed/>
                }
            </div>
        </div>
    )
}

export default Ecom;