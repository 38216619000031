import React, {useEffect} from 'react';
import {Layout, Spin} from 'antd';
import logo from '../../static/icons/logo.svg';

const OnlineBanking = props => {

    const {Header} = Layout;

    useEffect(() => {
       let search = window.location.search;
       let params = new URLSearchParams(search);
       let uid = params.get('param1');
       let refno = params.get('refno');
       let status = params.get('status');
       window.location.href = '/payment/'+uid+'?redirect=1&status='+status+'&refno='+refno;
    }, [])

    return(
        <div>
             <Header style={{zIndex: '100', position: 'fixed', top: 0, width: '100%', height: '60px', display: 'flex', justifyContent: 'center', backgroundColor: '#fff', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)'}}>
                <img src={logo} style={{marginBottom: '20px', marginTop: '12px'}} alt="#" />
            </Header>

            <div style={{margin: 0,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateY(50%, 50%)'}}>
                <Spin size="large" />
            </div>
        </div>
    )
}

export default OnlineBanking;