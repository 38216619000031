import React, { useState } from 'react'
import * as Style from './mobAppBarStyle'
import * as Icons from '../../static/icons/mobile_main_icons/index'
import {history} from '../../store/history'
import {backToTop} from '../../components/constants/constants'
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        'endpoints': state.child_endpoints.endpoints
    }
}

const MobileFooterMenu = props => {

    const pathname = window.location.pathname;
    const userType = localStorage.getItem("userType");
    const has_merchant_bias = localStorage.getItem("merchant_bias") === 'true';
    const [forcedRenderKey, setForcedRenderKey] = useState(0);

    function goToLink(link) {
        history.push(link)
        backToTop()
        setForcedRenderKey(forcedRenderKey + 1);
    }

    return (
        <div style={Style.FooterMain}>
            {
                has_merchant_bias
                ?
                    <div style={{display: "flex", justifyContent: 'space-around'}}>
                        <div>
                            <a onClick={() => goToLink('/mobile/orders')}>
                                <img alt="Menu Icon" src={pathname === "/mobile/orders" ? Icons.RequestActive : Icons.Request} />
                            </a>
                        </div>
                    </div>
                :
                    <div style={Style.TwoColumn}>
                        <div hidden={!props.endpoints.includes('dashboard')}>
                            <a onClick={() => goToLink('/mobile/dashboard')}>
                                <img alt="Menu Icon" src={pathname === "/mobile/dashboard" ? Icons.DashboardActive : Icons.Dashboard} />
                            </a>
                        </div>
                        <div hidden={!props.endpoints.includes('orders')}>
                            <a onClick={() => goToLink('/mobile/wallet')}>
                                <img src={pathname === "/mobile/wallet" ? Icons.WalletActive : Icons.Wallet} />
                            </a>
                        </div>
                        <div hidden={!props.endpoints.includes('orders')}>
                            <a onClick={() => goToLink('/mobile/orders')}>
                                <img alt="Menu Icon" src={pathname === "/mobile/orders" ? Icons.RequestActive : Icons.Request} />
                            </a>
                        </div>
                        <div hidden={!props.endpoints.includes('funds_enterprise') && userType === 'CO' || !props.endpoints.includes('funds') && userType === 'ME' || !props.endpoints.includes('funds') && userType === 'SW'}>
                            <a onClick={() => goToLink(userType === "CO" ? '/funds_enterprise' : '/mobile/funds')}>
                                <img alt="Menu Icon" src={
                                    pathname === "/funds_enterprise" || 
                                    pathname === "/mobile/funds" || 
                                    pathname === "/mobile/payout" || 
                                    pathname === "/payout_schedule" ?
                                    Icons.PayoutActive 
                                    : 
                                    Icons.Payout} 
                                    />
                            </a>
                        </div>
                        <div
                            hidden={
                                ! (props.endpoints.includes('recon')
                                || props.endpoints.includes('integration')
                                || props.endpoints.includes('account_settings'))
                            }
                        >
                            <a onClick={() => goToLink('/mobile/more')}>
                                <img alt="Menu Icon" src={pathname === "/mobile/more" ? Icons.MoreActive : Icons.More} />
                            </a>
                        </div>
                    </div>
            }
        </div>
    )
}

export default connect(mapStateToProps)(MobileFooterMenu);