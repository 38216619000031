import React from 'react'
import pricingStyle from '../../static/css/Pricing';
import { Grid } from "@material-ui/core";
import { Metrobank, UB, Bpi, Rcbc, WB, MainPchc, MainInstapay } from '../../static/icons/payment_method_icons/index'

const WebBanking = props => {
    const userType = localStorage.getItem('userType')
    
    const p = pricingStyle();

    const channels = [
        {
            icon: MainInstapay,
            name: 'InstaPay',
            fee: '₱ 15.00 ',
            min: '₱ 50.00 ',
            max: '₱ 30,000.00 ',
            emax: '₱ 50,000.00 ',
            surcharge: 'Depends on Bank'
        },        
        {
            icon: MainPchc,
            name: 'PCHC Paygate',
            fee: '₱ 25.00 ',
            min: '₱ 50.00 ',
            max: '₱ 30,000.00 ',
            emax: '₱ 1,000,000.00 ',
            surcharge: 'Depends on Bank'
        },        
        {
            icon: UB,
            name: 'UnionBank Internet Banking',
            fee: '₱ 15.00 ',
            min: '₱ 50.00 ',
            max: '₱ 30,000.00 ',
            emax: '₱ 80,000.00 ',
            surcharge: '₱ 10.00 '
        },        
        {
            icon: Bpi,
            name: 'BPI Online/Mobile',
            fee: '₱ 15.00 ',
            min: '₱ 50.00 ',
            max: '₱ 30,000.00 ',
            emax: '₱ 49,000.00 ',
            surcharge: '₱ 15.00 '
        }, 
        {
            icon: Rcbc,
            name: 'RCBC Online Banking',
            fee: '₱ 15.00 ',
            min: '₱ 50.00',
            max: '₱ 30,000.00',
            emax: '₱ 80,000.00',
            surcharge: '₱ 5.00'
        }, 
        {
            icon: Metrobank,
            name: 'Metrobank Online Banking',
            fee: '₱ 15.00 ',
            min: '₱ 50.00',
            max: '₱ 30,000.00',
            emax: '₱ 80,000.00',
            surcharge: '₱ 5.00'
        }, 
    ]

    return(
        <div className="pricing-table-div top-20" hidden={props.hidden}>
            <div className="pricing-header">
                <div className="wc-header">
                    <img src={WB} className="right-8" alt="icon" />
                    Online banking channels
                </div>
                {/*
                <div className="top-20 normal-text-size liveColor">
                    Transaction fees are <span className={`text-${props.segments} wc-header`}> ₱ 15.00</span> per transaction.
                </div>*/}
            </div>

            <div className="padding-lr-40 padding-bot-40">
                <div className="twoCol wc-header padding-20">
                    <Grid container>
                        <Grid item lg={2}>
                            Channel
                        </Grid>

                        <Grid item lg={2}>
                        </Grid>

                        <Grid item lg={2}>
                            Fee
                        </Grid>

                        <Grid item lg={2}>
                            Min Amount
                        </Grid>

                        <Grid item lg={2}>
                            Max Amount
                        </Grid>

                        <Grid item lg={2}>
                            Surcharge
                        </Grid>
                    </Grid>
                </div>
                {
                    channels.map((item, key) => {
                        return(
                            <div className="twoCol pricing-data normal-text-size liveColor">
                                <Grid key={key} container>
                                    <Grid item lg={2}>
                                        <div align="left">
                                            <img alt="icon"
                                                style={{width: 100, height: '42px', padding: '0 0 4px 0'}} 
                                                src = {item.icon} />
                                        </div>
                                    </Grid>

                                    <Grid item lg={2}>
                                        <p className={p.amount}>{item.name}</p>
                                    </Grid>

                                    <Grid item lg={2}>
                                        <p className={p.amount}>{item.fee}</p>
                                    </Grid>

                                    <Grid item lg={2}>
                                        <p className={p.amount}>{item.min}</p>
                                    </Grid>

                                    <Grid item lg={2}>
                                        <p className={p.amount}>
                                        {
                                            userType == 'CO' || userType == 'SW' ? 
                                                item.emax :
                                                item.max
                                        }
                                        </p>
                                    </Grid>  

                                    <Grid item lg={2}>
                                        <p className={p.amount}>{item.surcharge}</p>
                                    </Grid> 
                                </Grid>
                            </div>
                        )   
                    })
                }
                <div className="wc-header top-16">
                    *surcharge fees are additional fees charged to the customer.
                </div>
            </div>
        </div>

    )
}

export default WebBanking;