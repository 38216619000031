import React from 'react';
import {Modal, Table, Button, Pagination, Typography} from 'antd';
import {ExportToCsv} from 'export-to-csv'
import moment from 'moment'
import "./css/cashbux-settlement.css";

const PaymentHistoryModal = props => {
    const {
        title,
        openModal,
        closeModal,
        totalHistory,
        onChangePage,
        onExport
    } = props;

    const columns = [
        {
            title: "Trace Number",
            width: 150,
            render: (text, record) => {
                return (
                    <div className="table-content">{record.trace_no}</div>
                )
            }
        },
        {
            title: "Merchant Name",
            width: 150,
            render: (text, record) => {
                return (
                    <div className="table-content">{record.merchant_name}</div>
                )
            }
        },
        {
            title: "Transaction Date",
            width: 200,
            render: (text, record) => {
                return (
                    <div className="table-content">{record.timestamp}</div>
                )
            }
        },
        {
            title: "Transaction Type",
            width: 200,
            render: (text, record) => {
                return (
                    <div className="table-content">{record.collection_type}</div>
                )
            }
        },
        {
            title: "Terminal ID",
            width: 200,
            render: (text, record) => {
                return (
                    <div className="table-content">{record.terminal_code}</div>
                )
            }
        },
        {
            title: "Transaction Amount",
            width: 150,
            render: (text, record) => {
                return (
                    <div className="table-content">&#8369; {record.transaction_amount}</div>
                )
            }
        },
        {
            title: "Net Settlement Amount",
            width: 150,

            render: (text, record) => {
                return (
                    <div className="table-content">&#8369; {record.settled_to_merchant}</div>
                )
            }
        },
    ]

    const changePage = (page, id) => {
        onChangePage(page, id);
    }

    return(
        <Modal title={title}
        visible={openModal}
        width={800}
        onCancel={()=>closeModal()}
        footer={null}>
            <div>
                <div style={{ textAlign: "right" }}>
                    <Button
                    type='primary'
                    onClick={onExport}>
                        Export CSV
                    </Button>
                </div>
           
            {
                totalHistory ? 
                    totalHistory.map((item, key) => {
                        return(
                            <div>
                            <Typography style={{fontSize: 14, color: '#000'}}>Business Name: <b>{item.merchant_business}</b></Typography>
                    
                            <Table
                                rowClassName={(key) =>
                                    key % 2 === 0 ? "table-row-light" : "table-row-dark"
                                }
                                dataSource={item ? item.transaction_list : ''}
                                columns={columns}
                                className='cashbux-history-table'
                                pagination={false}
                            />

                            <Pagination
                                size='small'
                                total={item.total_count}
                                defaultPageSize={5}
                                pageSize={5}
                                defaultCurrent={1}
                                // current={transactions && transactions['page_number']}
                                onChange={(page, pageSize) => changePage(page, item.id)}
                                style={{ padding: '12px 0 8px 0', textAlign: 'right' }}
                            />
                            </div>
                        )
                    })

                    : ''
            }
            </div>
        </Modal>
    )
}

export default PaymentHistoryModal;