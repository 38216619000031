import React from 'react'
import { Modal, Checkbox } from 'antd'
import * as Icon from '../../../static/icons/bux_qr/index'
import { isMobile } from 'react-device-detect'
import * as Style from './style';
import { disclaimer } from '../../payment_details/payment_const';
import { MOBILE } from '../../constants/constants'

const DisclaimerModal = props => {

    const {
        showModal, hideModal, channel, proceed, params, hasDisclaimer
    } = props;

    const [terms, setTerms] = React.useState(false) 


    return(
        <Modal
            width={550}
            centered
            title={
                <div style={{padding: 12}} align='center'><img src={Icon.Bux}/></div>
            }
            visible={showModal}
            onCancel={() => hideModal(false)}
            footer={null}
            >
            <div style={{padding: !isMobile ? '32px 16px 16px 16px' : '0px 0px 0px 0px'}}>
                { channel == "gcash" && (
                <div style={{...Style.normalText, color:'#2b2d33'}} align='left'>
                    We will now redirect you to <strong>GCash</strong>'s log-in page for your e-wallet payment.  Kindly refrain from sharing your PIN to anyone.  BUx will not be liable for any problem or concern that you may encounter with your GCash e-wallet. 
                </div>
                )}

                { channel == MOBILE.Maya.code && (
                <div style={{...Style.normalText, color:'#2b2d33'}} align='left'>
                    We will now redirect you to <strong>Maya</strong>'s page for your e-wallet payment.  Kindly refrain from sharing your PIN to anyone.  BUx will not be liable for any problem or concern that you may encounter with your Maya e-wallet. 
                </div>
                )}

                {channel == "instapay" && (
                    <div style={{ ...Style.normalText, color: '#2b2d33' }} align='left'>
                        We will now redirect you to <strong>UPay</strong> to complete your InstaPay payment.  Kindly refrain from sharing your credentials to anyone.  BUx will not be liable for any problem or concern that you may encounter with your Banking account.
                    </div>
                )}

                {channel == "paygate" && (
                    <div style={{ ...Style.normalText, color: '#2b2d33' }} align='left'>
                        We will now redirect you to <strong>UPay</strong> to complete your PCHC Paygate payment.  Kindly refrain from sharing your credentials to anyone.  BUx will not be liable for any problem or concern that you may encounter with your Banking account.
                    </div>
                )}
                { channel == "grabpay" && (
                <div style={{...Style.normalText, color:'#2b2d33'}} align='left'>
                    We will now redirect you to <strong>GrabPay</strong>'s log-in page for your e-wallet payment.  Kindly refrain from sharing your PIN to anyone.  BUx will not be liable for any problem or concern that you may encounter with your GrabPay e-wallet. 
                </div>
                )}
                { (channel == "billease" || channel == 'billease_sn') && (
                <div style={{...Style.normalText, color:'#2b2d33'}} align='left'>
                    We will now redirect you to <strong>BillEase</strong>'s log-in page for your e-wallet payment.  Kindly refrain from sharing your credentials to anyone.  BUx will not be liable for any problem or concern that you may encounter with your BillEase account.
                </div>
                )}
                <div 
                    style={{...Style.normalText, color:'#2b2d33'}} 
                    align='left' 
                    hidden={
                        channel == "ubpcs" || 
                        channel == "gcash" || 
                        channel == "grabpay" || 
                        channel == MOBILE.Maya.code ||
                        channel == "visamc"  || 
                        channel == "billease" || 
                        channel == 'billease_sn' || 
                        channel == 'instapay' || 
                        channel == 'paygate' 
                    }
                >
                We will now redirect you to <b>{channel == "BPIA" ? "BPI" : channel == "UBPB" ? "UBP" : channel == "MBTC" ? 'Metrobank' : channel}'s</b> login page to access your internet 
                web banking and successful payments will then be processed by <b>Dragonpay</b>. 
                Please avoid sharing your username and password to log in to other links 
                aside from your bank’s official and securely authorized websites. 
                BUx is not liable for any problem or concerns that you may encounter with your bank account.
                </div>
                <div style={{...Style.normalText, color:'#2b2d33'}} align='left' hidden={channel !== "visamc" }>
                    We will now redirect you to <b>Xendit</b>'s log-in page for your Credit / Debit card payment.  
                    Kindly refrain from sharing your credentials to anyone.  
                    BUx will not be liable for any problem or concern that you may encounter with your Xendit account.
                </div>
                <div style={{...Style.normalText, color:'#2b2d33'}} align='left' hidden={channel !== "ubpcs"}>
                    We will now redirect you to <strong>UnionBank of the Philippines</strong> page for your card payment.
                </div>
                
                <div align='left' style={{marginTop: 16, fontSize: isMobile ? '12px' : '16px'}}><Checkbox style={{paddingRight: '10px', marginTop: '3px'}} onClick={()=>setTerms(!terms)}/> 
                    {channel != "" && hasDisclaimer.includes(channel) && disclaimer[channel].tnc}
                
                </div>
                <div style={{marginTop: '8px'}}>
                    <div style={{fontSize: '12px', color: '#2b2d33'}}>
                    {channel != "" && hasDisclaimer.includes(channel) && disclaimer[channel].name}
                     Support:</div>
                    <div style={{...Style.dpSupport, ...Style.normalText}}>
                        <div style={{borderRight: channel.includes('BPIA', 'UBPB', 'RCBC')? '1px solid #D1D5DD' : '', paddingRight: 40}}>
                            <img src={Icon.EmailIcon} style={{paddingRight: 16}}/> 
                            {channel != "" && hasDisclaimer.includes(channel) && disclaimer[channel].support}

                        </div>
                        <div hidden={
                            channel == "gcash" || 
                            channel == "grabpay" || 
                            channel == MOBILE.Maya.code || 
                            channel == "visamc" || 
                            channel == "ubpcs"
                        }>
                            <img src={Icon.PhoneIcon} style={{paddingRight: 16}}/>{(channel == "billease" || channel == "billease_sn") ?  "(02) 5310 1303" : "+632-8655-6820"}.
                        </div>
                    </div>
                </div>
                <button style={{...Style.payBtn, marginTop: '20px', opacity: !terms ? '0.5' : '', cursor: !terms ? 'auto' : 'pointer'}} disabled={!terms} onClick={() => proceed(params)}>Proceed</button>
            </div>
        </Modal>
    )
}

export default DisclaimerModal;