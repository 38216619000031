import React, {useEffect} from 'react'
import termsStyle from '../../static/css/Terms'
import { Button } from 'antd';
import {POLICIES} from './privacy_const'
import axios from 'axios'

const PrivacyPolicy = props => {

    const [privacyPolicy, setPrivacyPolicy] = React.useState("")

    const [cmsPage, setCMSPage] = React.useState(false)

    useEffect(()=>{
        fetchPrivacy()
    },[])

    const fetchPrivacy = async() => {

        let response = await axios.get(process.env.REACT_APP_API_URL + "/api/content/?key=privacy_policy")
            
        if(response.data.content){
            setPrivacyPolicy(response.data.content)
            setCMSPage(true)
            window.scrollTo(0, 0);
        }

    }

    const terms = termsStyle();
    return(
        <div className={terms.mainBody}>

            <div style={{display: 'flex'}}>
                <Button  onClick={()=> window.location.href="/signup"} icon="left" shape="circle" className={terms.button} size="large"/>

                <div className={terms.mainHeader}>Data Privacy Policy</div>
            </div>
            {
                cmsPage ? 
                <div className={terms.body} dangerouslySetInnerHTML={{__html: privacyPolicy}}></div>
                :
                <div className={terms.body}>
                    {
                        POLICIES.map((item) => {
                            return(
                                <div>
                                <div className={terms.title}>{item.q}</div>
                                <div className={terms.text}>{item.d}</div>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )

}

export default PrivacyPolicy