import React from 'react';
import * as Icon from '../../static/icons/how_it_works/index';
import * as Img from '../../static/images/how_it_works/index';
import ContentCard from './content_card';
import GenerateLink from './reusable_content/generate_link'

const ContentStep6 = props => {
    
    const {
        segments
    } = props;

    const userType = localStorage.getItem('userType')

    const isIndividual = userType === 'ME'

    let recon_icon = segments == 'me_' ? Icon.Recon_me_ : 
                            segments == 'gig' ? Icon.Recon_gig : 
                                segments == 'sme' ? Icon.Recon_sme : 
                                    segments == 'biz' ? Icon.Recon_biz: ""



    return (
        <div>
            {
                isIndividual ?
                    <GenerateLink segments={segments} step={6} />
                    :
                    <ContentCard step={6}
                    icon={recon_icon}
                    image={Img.Recon}
                    title={"Reconciliation"}
                    content={"This tab shows you all the accounted and unaccounted transactions that are remitted to your settlement bank account."} />

            }
            
        </div>
    )
}

export default ContentStep6;