import React from 'react';
import { Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import * as Icons from '../../../../static/icons/bux_qr/index'

const QRFooter = props => {
    const {
        qrStaticDetails, tradeName
    } = props;

    const customShareBtn = (icon, text, hidden) => {
        return (
            <button className="qr-social-btn" onClick={() => goToSocials(text)} hidden={hidden}>
                <img src={icon} alt="logo" />
            </button>
        )
    }

    const goToSocials = (text) => {
        if (text === 'fb') {
            window.open(`https://www.facebook.com/${qrStaticDetails.fb}`)
        }

        else if (text === 'ig') {
            window.open(`https://www.instagram.com/${qrStaticDetails.ig}`)
        }

        else if (text === 'web') {
            window.open(`${qrStaticDetails.web}`)
        }
    }

    return(
        <div>
            <div className="qr-footer top-20">
                <div>
                    <Grid container>
                        <Grid item lg={qrStaticDetails && !qrStaticDetails.fb && !qrStaticDetails.ig && !qrStaticDetails.web ? 12 : 8} sm={12} xs={12}>
                            <Grid container>
                                <Grid item lg={qrStaticDetails && !qrStaticDetails.fb && !qrStaticDetails.ig && !qrStaticDetails.web ? 12 : 2} sm={12} xs={12}>
                                    <img src={qrStaticDetails && qrStaticDetails.profile_picture ? qrStaticDetails.profile_picture : Icons.Profile}
                                        className={qrStaticDetails && "qr-profile-pic"}
                                        alt="avatar" />
                                </Grid>

                                <Grid item lg={qrStaticDetails && !qrStaticDetails.fb && !qrStaticDetails.ig && !qrStaticDetails.web ? 12 : 9} sm={12} xs={12} align={isMobile || qrStaticDetails && !qrStaticDetails.fb && !qrStaticDetails.ig && !qrStaticDetails.web ? 'center' : 'left'}>
                                    <div className="wc-header" style={{ margin: isMobile ? 8 : 0, maxWidth: '264px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>
                                        {tradeName}
                                    </div>
                                    <div className="wc-header" style={{ fontSize: 12,}}>
                                        {qrStaticDetails && qrStaticDetails.show_email ? qrStaticDetails.email : ''} <span hidden={qrStaticDetails && (!qrStaticDetails.show_email || !qrStaticDetails.show_mobile)}>|</span> {qrStaticDetails && qrStaticDetails.show_mobile ? qrStaticDetails.mobile_number : ''}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item lg={4} sm={12} xs={12}>
                            <div className={`flex-center ${isMobile && "top-16"}`} style={{width: '50%'}}>
                                {customShareBtn(Icons.FacebookLogo, 'fb', qrStaticDetails && qrStaticDetails.fb ? false : true)}
                                {customShareBtn(Icons.InstagramLogo, 'ig', qrStaticDetails && qrStaticDetails.ig ? false : true)}
                                {customShareBtn(Icons.WebLogo, 'web', qrStaticDetails && qrStaticDetails.web ? false : true)}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>

            <div className="top-32">
                <img src={Icons.SecuredBux} alt="Secured Bux" />
            </div>
        </div>
    )
}

export default QRFooter;
