import React from "react";
import moment from 'moment';
import { Input, Button, DatePicker, TimePicker, Icon, message } from 'antd';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { history } from "../../../../store/history";
import promotionServices from '../promotionServices';
import DeleteModal from "../delete_model";

const PromoAddEditContainer = props => {
    const { TextArea } = Input;
    const dateFormat = 'MM-DD-YYYY';
    const [loading, setLoading] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);

    const {
        headerText, handleChange, params, setParams, isLoading, view_path, id
    } = props;

    const datePickerHelperFunctions = {
        disabledStartDates: (current) => {
            // Can not select days before today
            let isDisabled = current && current < moment().subtract(1, 'days').endOf('day');

            if(params.end_date) {
                 // Cannot select days after the start date, if available
                isDisabled = isDisabled || current > moment(params.end_date, dateFormat).startOf('day');
            }

            return isDisabled
        },
    
        disabledEndDates: (current) => {
            // Cannot select days in the future
            let isDisabled = current && current > moment().endOf('day');
    
            if (params.start_date) {
                // Cannot select days before the start date, if available
                isDisabled = current < moment(params.start_date, dateFormat).startOf('day');
            }
    
            return isDisabled;
        }
    };

    const timePickerHelperFunctions = {

        getDisabledHours: () => {
            var hours = [];

            if(params.start_date == params.end_date){
                for(var i =0; i < moment(params.start_time, 'h:mm A').hour(); i++){
                    hours.push(i);
                }            
            }
            return hours;
        },
        
        getDisabledMinutes (selectedHour) {
            var minutes= [];
            
            if(params.start_date == params.end_date){
                if (selectedHour === moment(params.start_time, 'h:mm A').hour()){
                    for(var i =0; i < moment(params.start_time, 'h:mm A').minute(); i++){
                        minutes.push(i);
                    }
                }
            }
            
            return minutes;
        }
    }


    const rangeSelect = (dates, dateString, id) => {
        console.log(moment(dateString).format('MM-DD-YYYY'))
        setParams({ ...params, [id]: moment(dateString).format('MM-DD-YYYY') });
    }

    const timeSelect = (time, timeString, id) => {
        console.log(timeString)
        console.log(id)
        setParams({ ...params, [id]: timeString });

    }

    const handleDelete = async (id) => {
        setLoading(true);
        const params = ({ code: id });
        try {
            const res = await promotionServices.removePromoCode(params);
            if(res.status === 'success') {
                message.success(res.message);
            } else {
                message.success(res.message);
            }
            window.location.href = "/promotion"
        } catch (error) {
            console.log(error);
            setLoading(false);
            message.error('Please try again');
        }
        setLoading(false);
        setDeleteModal(false);
    }

    const TwoColumnDiv = (params1, params2,) => {
        return(
            <div className="twoCol top-32" align='left'>
                <div style={{width: '49%'}} className="rigth-8">
                    <div className="wc-text placeholder-text-color">
                        {params1['placeholder']}
                    </div>
                    <div className="top-4">
                        {
                            params1['name'] &&
                            <Input 
                            placeholder={params1['placeholder']}
                            name={params1['name']}
                            value={params1['value']}
                            onChange={handleChange}
                            disabled={isLoading}
                            maxLength={params1['max']}/>
                        }
                        {
                            params1['type'] == 'dropdown' &&
                            <Button 
                                style={{width: '100%'}}
                                disabled 
                                className="twoCol padding-top-4">
                                    {params && params.segments ? 
                                        params.segments.toString(", ") : 
                                        'Select Segment'}
                                        <ExpandMoreIcon className="icon-style" />
                            </Button>
                        }

                        {
                            params1['type'] == 'date' &&
                            <DatePicker
                            style={{width: '100%'}}
                            format='MM/DD/YYYY'
                            disabledDate={params1['disableDate']}
                            // defaultValue={moment()}
                            value={params1['value']}
                            picker="month"
                            onChange={(dates, dateString) => rangeSelect(dates, dateString, params1['id'])}
                            placeholder={params1['p1']}
                            allowClear={false} />
                        }
              
                    </div>
                </div>
                <div style={{width: '49%'}} className="left-8">
                    <div className="wc-text placeholder-text-color">
                        {params2['placeholder']}
                    </div>
                    <div className="top-4">
                        {
                            params2['name'] &&
                            <Input 
                            placeholder={params2['placeholder']}

                            name={params2['name']}
                            value={params2['value']}
                            onChange={handleChange}
                            disabled={isLoading}
                            maxLength={params2['max']}/>
                        }
                        {
                            params2['type'] == 'dropdown' &&
                            <Button 
                                style={{width: '100%'}}
                                disabled
                                className="twoCol padding-top-4">
                                    {params && params.channels ? 
                                        'Check All' : 
                                        'Select Channel'}
                                    <ExpandMoreIcon className="icon-style" />            
                            </Button>
                        }
                        {
                            params2['type'] == 'time' &&
                            <TimePicker use12Hours
                                placeholder={params2['p2']}
                                style={{width: '100%'}}
                                value={params2['value']}
                                onChange={(time, timeString) => timeSelect(time, timeString, params2['id'])}
                                format="h:mm A" />
                        }

                    </div>
                </div>
            </div>
        )
    }

    const ValueColumnDiv = (key, value) => {
        return(
            <div className="twoCol promotion-value-div liveColor">
                <div className="normalTextStyle">
                    {key}
                </div>
                <div className="normalTextStyle medium-font-weight liveColor">
                    {value}
                </div>
            </div>
        )
    }

    const ViewPromo = () => {
        return(
            <div>
                <div className="twoCol">
                    <div className="screen-title-text value-color">
                        {params['code']}
                    </div>
                    <div>
                        <button className="outline-btn--nil right-8"
                            onClick={() => setDeleteModal(true)}
                        >
                            Delete
                        </button>

                        <button className="btn--nil"
                            onClick={() => history.push(`/edit_promotion/${id}`)}>
                            <Icon type="edit" className="right-4" />
                            Edit promo
                        </button>
                    </div>
                </div>
                <div className="top-40">
                    {ValueColumnDiv('Promo name', params['promo_name'])}
                    {ValueColumnDiv('Segment', 'BUxMe, BUxGIG')}
                    {ValueColumnDiv('Channels', 'All OTC Channels')}
                    {ValueColumnDiv('Description', params['description'])}
                </div>

                <div className="top-32">
                    <div className="promotion-discount-text" align="left">
                        Promo Validity
                    </div>

                    <div className="top-32">
                        {ValueColumnDiv('Promo start', `${params['start_date']} ${params['start_time']}`)}
                        {ValueColumnDiv('Promo end', `${params['end_date']} ${params['end_time']}`)}
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className="promotion-card padding-32 top-32">
            {
                view_path === 'view_promotion' ?
                <ViewPromo />
                :
                // <AddPromo />
                <div>
                    <div className="promotion-header">
                    {headerText}
                    </div>
            
                    {TwoColumnDiv(
                        {
                            placeholder: 'Promo code*',
                            name: 'code',
                            value: params['code'],
                            max: 8,
                        }, 
                        {
                            placeholder: 'Promo name*',
                            name: 'promo_name',
                            value: params['promo_name'],
                            max: 32,    
                        }
                    )}

                    {TwoColumnDiv(
                        {
                            placeholder: 'Select segment*',
                            type: 'dropdown',
                        }, 
                        {
                            placeholder: 'Select channel*',
                            type: 'dropdown'   
                        }
                    )}

                    <div className='top-32' align="left">
                        <div className="wc-text placeholder-text-color">
                        Description*
                        </div>
                        <div className="top-4">
                            <TextArea 
                                showCount 
                                maxLength={250} 
                                placeholder='Type description here'
                                style={{ height: 47 }}
                                onChange={handleChange}
                                name="description"
                                value={params['description']} />

                            <div align='right' className='top-8'>
                                {params['description'].length} / 250
                            </div>
                        </div>
                    </div>


                    {TwoColumnDiv(
                        {
                            placeholder: 'Promo start*',
                            type: 'date',
                            id: 'start_date',
                            // disableDate: datePickerHelperFunctions.disabledStartDates,
                            value: params.start_date && moment(params.start_date),
                            p1: 'Start date'
                        }, 
                        {
                            placeholder: 'Start time*',
                            type: 'time',
                            id: 'start_time',
                            value: params.start_time && moment(params.start_time , 'h:mm A'),
                            p2: 'Start Time'   
                        }
                    )}

                    {TwoColumnDiv(
                        {
                            placeholder: 'Promo end*',
                            type: 'date',
                            id: 'end_date',
                            disableDate: datePickerHelperFunctions.disabledEndDates,
                            value: params.end_date && moment(params.end_date),
                            p1: 'End date'

                        }, 
                        {
                            placeholder: 'End time*',
                            type: 'time',
                            id: 'end_time',
                            disableHours: timePickerHelperFunctions.getDisabledHours,
                            disableMinutes: timePickerHelperFunctions.getDisabledMinutes,
                            value: params.end_time && moment(params.end_time, 'h:mm A'),
                            p2: 'End time'
                        }
                    )}

                </div>
            }
            
            <DeleteModal visible={deleteModal} onClose={() => setDeleteModal(false)} onDelete={() => handleDelete((params && params['code'])) || null} />
        </div>
    )
}


export default PromoAddEditContainer;