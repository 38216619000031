import React from 'react'
import { Modal } from 'antd';


const RebateDeleteModal = (props) => {

    const {
        modalState, setModalState, modalData, 
        deleteTier, deleteAddedData, setSaveStatus
    } = props;

    const segments = localStorage.getItem("segments");

    const handleDeleteTier = () => {
        setModalState(false);
        if(!("tier" in modalData)) {
            deleteAddedData(modalData)
        }

        else {
            deleteTier(modalData);
        }

        setSaveStatus(true)
    }

    return(
        <Modal
            visible={modalState}
            centered
            onCancel={() => setModalState(false)}
            width="450px"
            footer={null}>
                <div>
                    <div className="modal-header-text">
                        Delete Tier
                    </div>

                    <div className="top-32 modal-sub-text">
                        Are you sure you want to delete this tier?

                        <div className="top-16">
                            The values of other tiers won't be affected unless manually edited.
                        </div>
                    </div>

                    <div className="top-48" align="right">
                        <button
                            className={
                                `outline-btn--${segments} btn-height right-8`
                                
                            }
                            onClick={() => setModalState(false)}>
                            Cancel
                        </button>
                        <button
                            className={
                                `btn--${segments} btn-height`
                            }
                            onClick={() => handleDeleteTier()}>
                            Delete
                        </button>
                    </div>
                </div>

            
        </Modal>
    )
}

export default RebateDeleteModal;