import axios from 'axios';
import { Handle401 } from '../../handle401/handle401';

const authorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const unauthorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
}

const promotionServices = {
  getPromotions: async (search = '', discountType = '', channels = [], start = '', end = '', page = 1, pageSize = 10, isExport = '', email = '', orderBy='') => {
    try {
      const res = await authorizedAxiosRequest().get(`/api/promo_codes/?search=${search}&discount_type=${discountType}&start=${start}&end=${end}&channels=${channels}&page=${page}&page_size=${pageSize}&email=${email}&export=${isExport}&order_by=${orderBy}`)
      return res.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  getFeaturedVouhers: async () => {
    try {
      const res = await authorizedAxiosRequest().get(`/api/featured_promo_codes/`)
      return res.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  createPromoCode: async (params) => {
    try {
      const res = await authorizedAxiosRequest().post(`/api/promo_codes/`, params)
      return res.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  updatePromoCode: async (params) => {
    try {
      const res = await authorizedAxiosRequest().patch(`/api/promo_codes/`, params)
      return res.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  validatePromoCode: async (code, segment, channel, link_amount) => {
    try {
      const res = await unauthorizedAxiosRequest().get(`/api/validate_promo_code/?code=${code}&segment=${segment}&channel=${channel}&payment_link_amount=${link_amount}`);
      return res.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  removePromoCode: async (params) => {
    try {
      const res = await authorizedAxiosRequest().delete(`/api/promo_codes/?code=${params.code}`, params);
      return res.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  getPromoCodeById: async (id) => {
    try {
      const res = await authorizedAxiosRequest().get(`/api/promo_codes/${id}/`)
      return res.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  getDraftPromoCode: async () => {
    try {
      const res = await authorizedAxiosRequest().get(`/api/promo_codes/draft/`)
      return res.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  getPromoPerformance: async (id, email,isExport) => {
    try {
      const res = await authorizedAxiosRequest().get(`/api/admin/promo_performance/?promo_id=${id}&email=${email}${isExport? '&is_export=true' : ''}`)
      return res.data;
    } catch (error) {
      if ((error.response) && (error.response.status === 401)) {
        Handle401()
      }
    }
  },
}

export default promotionServices;