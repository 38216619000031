import React from 'react';
// import fraudServices from '../fraudServices';
import { Button, Icon, Input, Modal, Typography, message } from 'antd';

const CredentialModal = ({ visible, closeModal, onSuccess, details }) => {
  const creds = details && details.user_credentials;
  let api_key, api_secret, client_id

  const copyAPIKey = () => {
    api_key.select()
    document.execCommand("copy")
    message.destroy()
    message.info("Copied API Key")
    api_key.blur()
  }

  const copyAPISecret = () => {
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.value = creds && creds.api_secret;
    dummy.select();
    document.execCommand("copy")
    dummy.remove();
    message.destroy()
    message.info("Copied Client Secret")
    api_secret.blur()


  }

  const copyClientID = () => {
    client_id.select()
    document.execCommand("copy")
    message.destroy()
    message.info("Copied Client ID")
    client_id.blur()


  }

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={closeModal}
      destroyOnClose
      width={392}
      centered
      closeIcon={<Icon type="close" style={{ color: '#F5922F' }} />}
      bodyStyle={styles.root}>
      <Typography style={styles.title}>Integration Credentials</Typography>
      <Typography><b>Business Name:</b> {details && details.business_name}</Typography>
      <br />
      <label style={styles.label}>API Key</label>
      <div style={styles.inputDiv}>
        <Input
          name='domaneName'
          placeholder="API Key"
          style={styles.input}
          contentEditable={false}
          value={creds && creds.api_key}
          ref={(textarea) => api_key = textarea}
        />
        <Button style={styles.copyBtn} onClick={copyAPIKey}>Copy</Button>
      </div>
      <br />
      <label style={styles.label}>Client ID</label>
      <div style={styles.inputDiv} >
        <Input
          name='domaneName'
          placeholder="Client ID"
          style={styles.input}
          contentEditable={false}
          value={creds && creds.client_id}
          ref={(textarea) => client_id = textarea}
        />
        <Button style={styles.copyBtn} onClick={copyClientID}>Copy</Button>
      </div>
      <br />
      <label style={styles.label}>API Secret</label>
      <div style={styles.inputDiv} >
        <Input
          name='domaneName'
          placeholder="API Secret"
          style={styles.input}
          contentEditable={false}
          type='password'
          value={creds && creds.api_secret}
          ref={(textarea) => api_secret = textarea}
        />
        <Button style={styles.copyBtn} onClick={copyAPISecret}>Copy</Button>
      </div>
      <br />
      <div style={styles.footerBtn}>
        <Button className="add-user"
          onClick={closeModal}
          >Done</Button>
      </div>
    </Modal>
  )
}

const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  title: {
    fontSize: 20,
    color: '#2B2D33',
    padding: '32px 0 18px 0',
  },
  cancelBtn: {
    width: '48%',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    height: 48,
    border: '1px solid #F5922F',
  },
  confirmBtn: {
    height: 48,
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    width: '48%',
  },
  footerBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 20,
  },
  label: {
    fontSize: 12,
    color: '#212B36',
  },
  input: {
    padding: '11px 16px 11px 16px',
    fontSize: 16,
    height: 48,
    color: '#2B2D33',
    width: '80%',
    borderRadius: '4px 0 0 4px'
  },
  inputDiv: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  copyBtn: {
    width: '20%',
    height: 48,
    backgroundColor: '#0a315e',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '600',
    marginLeft: '-1px',
  }
}


export default CredentialModal;