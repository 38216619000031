import axios from 'axios';
import {Handle401} from '../handle401/handle401';

const authorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const surveyServices = {
  getEnterpriseQuestions: async () => {
    try {
      const res = await authorizedAxiosRequest().get(`/api/enterprise_survey/`)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  submitAnswers: async (params) => {
    try {
      const res = await authorizedAxiosRequest().post(`/api/enterprise_survey/`, params)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  logout: async () => {
    try {
      const res = await authorizedAxiosRequest().get(`/api/logout/`)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
}

export default surveyServices;