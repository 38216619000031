import React from 'react'
import {Table, Icon, Dropdown, Menu, message} from 'antd'
import settingServices from '../../account_settings/settingServices';
import { Handle401 } from '../../handle401/handle401';

const UserTable = props => {

    const {userData, searchValue, getCorporate,
           setIsConfirmModalVisible, setConfirmModalCallback, setConfirmModalHeaderText, setConfirmModalBodyText
    } = props;

    const isAdmin = ! ['CO', 'ME'].includes(localStorage.getItem('userType'));

    const dropdown_options_menu = (alias_user) => (
        <Menu style={{ width: '240px' }}>
            <Menu.Item
                key="0"
                onClick={() => {
                    setConfirmModalCallback(() => {
                        return () => {
                            patchUser(alias_user.id, "subscribe_settlement_reports");
                            setIsConfirmModalVisible(false);
                        }
                    });

                    setConfirmModalHeaderText("Subscribe to Settlement Reports")
                    setConfirmModalBodyText(
                        <span>Are you sure you want to subscribe the account of <b>{alias_user.full_name}</b> to settlement reports?</span>
                    );
                    setIsConfirmModalVisible(true);
                }}
                hidden={alias_user.suspended || alias_user.can_view_settlement_report}
            >
                Subscribe to Settlement Reports
            </Menu.Item>
            <Menu.Item
                key="1"
                onClick={() => {
                    setConfirmModalCallback(() => {
                        return () => {
                            patchUser(alias_user.id, "stop_settlement_reports");
                            setIsConfirmModalVisible(false);
                        }
                    });

                    setConfirmModalHeaderText("Stop Settlement Reports")
                    setConfirmModalBodyText(
                        <span>Are you sure you want to stop sending settlement reports to <b>{alias_user.full_name}?</b></span>
                    );
                    setIsConfirmModalVisible(true);
                }}
                hidden={alias_user.suspended || !alias_user.can_view_settlement_report}
            >
                Stop Settlement Reports
            </Menu.Item>


            <Menu.Item
                style={{marginTop: '15px'}}
                key="2"
                onClick={() => {
                    setConfirmModalCallback(() => {
                        return () => {
                            const parent_ID = isAdmin ? props.id : localStorage.getItem('userId');
                            promoteUserToSPOC(parseInt(parent_ID), alias_user.id);
                            setIsConfirmModalVisible(false);
                        }
                    });

                    setConfirmModalHeaderText("Promote to Parent Account");
                    setConfirmModalBodyText(
                        <>
                            <span>Are you sure you want to promote <b>{alias_user.full_name}</b> as the new parent account / SPOC?</span>
                            <br/>
                            <br/>
                            <span style={{color: '#606060'}}>{isAdmin ? "Current SPOC" : "You"} will surrender parent account privileges and become an alias user.</span>
                        </>
                    );
                    setIsConfirmModalVisible(true);
                }}
                hidden={alias_user.suspended || (localStorage.getItem("userType") == "CO" && localStorage.getItem("isAlias") == "false")}
            >
                Promote to Parent Account
            </Menu.Item>
            <Menu.Item
                key="3"
                onClick={() => {
                    setConfirmModalCallback(() => {
                        return () => {
                            patchUser(alias_user.id, "suspend");
                            setIsConfirmModalVisible(false);
                        }
                    });

                    setConfirmModalHeaderText("Deactivate Account");
                    setConfirmModalBodyText(
                        <span>Are you sure you want to <b>deactivate</b> the account of <b>{alias_user.full_name}</b>?</span>
                    );
                    setIsConfirmModalVisible(true);
                }}
                style={{color: '#E24C4C'}}
                hidden={alias_user.suspended}
            >
                Deactivate
            </Menu.Item>
            <Menu.Item
                key="4"
                onClick={() => {
                    setConfirmModalCallback(() => {
                        return () => {
                            patchUser(alias_user.id, "reactivate");
                            setIsConfirmModalVisible(false);
                        }
                    });

                    setConfirmModalHeaderText("Reactivate Account")
                    setConfirmModalBodyText(
                        <span>Are you sure you want to reactivate the account of <b>{alias_user.full_name}</b>?</span>
                    );
                    setIsConfirmModalVisible(true);
                }}
                style={{color: "#1DD28B"}}
                hidden={!alias_user.suspended}
            >
                Reactivate
            </Menu.Item>
        </Menu>
    );

    async function promoteUserToSPOC(parent_id, alias_id) {
        try {
            let params = {
                spoc_id: parent_id,
                successor_id: alias_id
            };

            let res = await settingServices.swapSPOCandAlias(params);
            if (res.status === 'failed') {
                message.error(res.message)
            }            

            if (isAdmin) {
                message.success(res.message, 5);
                getCorporate();
            } else {
                message.success(res.message + " Logging you out in 5 seconds.", 5);
                setTimeout(() => {
                    Handle401();
                }, 5000);
            }
        }

        catch(err) {
            message.error(err.message);
        }
    }

    async function patchUser(id, action) {
        try {

            let params = {
                alias_id: id,
                action
            }

            if(localStorage.getItem('userType') !== "CO"){
                params.parent_id = props.id
            }

            let res = await settingServices.childAccountSuspendToggle(params);

            message.info(res, 2);
            getCorporate();
        }

        catch(error) {
            console.log(error)
        }
    }


    const columns = [
        {
            key:"0",
            title: 'Name',
            width: '21%',
            render:(alias_user) => {
                return(
                  <div style={{fontWeight: 'bold'}}>
                      {alias_user.full_name}
                  </div>
                )
              },
        },
        {
            key: "1",
            title: 'Email',
            width: '21%',
            render:(alias_user) => {
                return(
                  <div>
                      {alias_user.email}
                  </div>
                )
              },
        },
        {
            key: "2",
            title: 'Mobile Number',
            width: '18%',
            render:(alias_user) => {
                return(
                  <div>
                      +63{alias_user.contact}
                  </div>
                )
              },
        },
        {
            key: "3",
            title: 'Settlement Reports',
            width: '17%',
            render:(alias_user) => {
                return(
                    alias_user.can_view_settlement_report
                    ? <div>
                        Subscribed
                    </div>
                    : <div style={{color: "#909090"}}>
                        <i>Not Available</i>
                    </div>
                )
              },
        },
        {
            key: "4",
            title: 'Status',
            width: '13%',
            render:(alias_user) => {
                return(
                    alias_user.suspended
                    ? <div style={user_table_styles.suspended}>
                        Inactive
                    </div>
                    : <div style={user_table_styles.active}>
                        Active
                    </div>
                )
              },
        },
        {
            key: "5",
            title: 'Action',
            width: '10%',
            render: (text, alias_user) => (
              <div>
                <Dropdown
                    overlay={() => dropdown_options_menu(alias_user)} 
                    placement="bottomCenter"
                    trigger={['click']}
                >
                    <Icon type="ellipsis" />
                </Dropdown>
              </div>
            )
        }
    ]



    const renderSingleFields = (val) => {
        if(!val) {
          return userData;
        }
    
        return userData.filter(userData => userData.full_name.toLowerCase().includes(val.toLowerCase()) ||  
                               userData.email.toLowerCase().includes(val.toLowerCase()));
    }
    

    return(
        <div>
            <Table
                style={{marginTop: 10}}
                rowClassName={(alias_user, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                columns={columns}
                dataSource={renderSingleFields(searchValue)}
                pagination={false}
                rowKey={alias_user => alias_user.id}
            />
        </div>
    )
}

const user_table_styles = {
    active: {
        borderRadius: 4,
        padding: '4px 8px',
        backgroundColor: 'rgb(232, 250, 243)',
        border: '1px solid #1DD28B',
        borderLeft: '5px solid #1DD28B',
      },
    suspended: {
        borderRadius: 4,
        padding: '4px 8px',
        backgroundColor: 'rgb(240, 240, 240)',
        border: '1px solid rgb(192, 192, 192)',
        borderLeft: '5px solid rgb(192, 192, 192)',
    },
}

export default UserTable