import React from 'react'
import { Grid } from '@material-ui/core'
import { Switch, Input, Checkbox, Radio, message, Button } from 'antd'
import accountStyles from '../../../static/css/Account'
import { isMobile } from 'react-device-detect'
import QRCode from 'qrcode.react'
import * as Icon from '../../../static/icons/bux_qr/index'
import CustomInfoModal from './custom_info_modal' 
import CustomAmountModal from './custom_amount_modal' 
import CustomInfoDrawer from './custom_info_drawer'
import CustomAmountDrawer from './custom_amount_drawer'
import MetaTag from '../../meta_tag/meta_tag'
import SetupTradeName from './setup_trade_name'
import DownloadQR from './download_qr'
import {history} from '../../../store/history'
import qrServices from './qr_services/qrServices'

const testMode = history.location.pathname.indexOf('/test') > -1;

const BuxQrMain = props => {
    const FB_APP_ID = process.env.REACT_APP_FB_ID
    const REACT_APP_ROOT_URL = process.env.REACT_APP_ROOT_URL
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
    const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL

    const {TextArea} = Input;


    const yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }        

    let buxQrLink;
    let tName = localStorage.getItem('tradeName')
    let sbTname =  localStorage.getItem('SbTradeName') 
    let noTradeName = testMode ? (sbTname === "null" || sbTname === null || sbTname === "") : (tName === "null" || tName === null || tName === "")
    let trade_name = testMode ? sbTname : tName

    const [tradeName, setTradeName] = React.useState("")
    const qrLink = testMode ? 'https://' + tradeName + '.' + REACT_APP_ROOT_URL+'/test/qr' : 'https://' + tradeName + '.' + REACT_APP_ROOT_URL
    const notFound = REACT_APP_BASE_URL+'/404/'
    const isChild = localStorage.getItem("is_child") == "true" || localStorage.getItem("is_child") == true

    const [qrOn, setQrOn] = React.useState(true)
    const [toggleEdit, setToggleEdit] = React.useState(false)
    const [pr, setPr] = React.useState(true)
    const [allow, setAllow] = React.useState(true)
    const [showModal, setShowModal] = React.useState(false)
    const [customModal, setCustomModal] = React.useState(false)
    const [showDrawer, setShowDrawer] = React.useState(false)
    const [customDrawer, setCustomDrawer] = React.useState(false)
    const [listAmount, setListAmount] = React.useState([])

    const [mobileOn, setMobileOn] = React.useState(true)
    const [emailOn, setEmailOn] = React.useState(true)

    const [personalMessage, setPersonalMessage] = React.useState('')
    const [editPersonalMessage, setEditPersonalMessage] = React.useState(true)


    const [notificationURL, setNotificationURL] = React.useState('')
    const [editNotificationURL, setEditNotificationURL] = React.useState(true)

    const [field1, setField1] = React.useState('')
    const [showField1, setShowField1] = React.useState(false)
    const [field2, setField2] = React.useState('')
    const [showField2, setShowField2] = React.useState(false)
    const [editCustomField, setEditCustom] = React.useState(true)

    const [fb, setFb] = React.useState('')
    const [ig, setIg] = React.useState('')
    const [website, setWebsite] = React.useState('')
    const [editSocial, setEditSocial] = React.useState(true)

    const [errorMsg, setErrorMsg] = React.useState("")
    const [hideError, setHideError] = React.useState(true)

    const ac = accountStyles();

    const customComponentSettings = (headerSettings, settingsText , settingsType, checked) => {
        return (
            <div className={ac.toggleSettings}>
                <div className={ac.personalMenu}>
                    <div className={ac.qrSettingsHeader}>
                        {headerSettings}
                    </div>
                    <div className={ac.qrSettingsActions}>
                    <Switch checked={checked} onChange={() => radioChange(settingsType)}
                        size="small" style={{background: `${checked ? '#F5922F' : '#D1D5DD'}`}}/>
                    </div>
                </div>
                <div className={ac.qrSettingsText}> 
                    {settingsText}
                </div>
            </div>
        )
    }

    const customTextTitle = (headerText, infoText) => {
        return (
            <div>
                <div className={ac.qrSettingsHeader}>
                    {headerText}
                </div>
                <div className={ac.qrSettingsText}> 
                    {infoText}
                </div>
            </div>
        )
    }

    const customePlaceholder = (text) => {
        return(
            <div className={ac.fieldPlacehold}>
                {text}
            </div>
        )
    }

    const customShareBtn = (icon, text) => {
        return (
            <button className={ac.btnstyle} onClick={() => shareButton(text)}>
                <img src={icon} alt="logo" />
                <div className={ac.btnText}>{text}</div>
            </button>
        )
    }

    function shareButton(text) {
        if(text === 'Copy Link') {
            message.destroy()
            buxQrLink.select()
            document.execCommand('copy')
            message.info('Copy to clipboard', 0.5)
        }

        else if(text === 'Messenger') {
            // return (<a href={`${FB_SHARE_URL}redirect_uri=${APP_BASE_URL}/dashboard&app_id=${FB_APP_ID}&link=${REACT_APP_BASE_URL}${testMode?'/test/checkout/':'/checkout/'}${response.uid}/%3Futm_source%3Dbux%26utm_medium%3Dcode-facebook`} target="_blank"><img src={share_messenger} style={{ width: '40px', marginRight: '5px' }} alt="#" /></a>);
            
            window.open(`${FB_SHARE_URL}redirect_uri=${REACT_APP_BASE_URL}/dashboard&app_id=${FB_APP_ID}&link=${qrLink}/%3Futm_source%3Dbux%26utm_medium%3Dcode-facebook`)
        }
    }

    function radioChange(settingsType) {
        if(settingsType == 'qr') {
            setQrOn(!qrOn)
            gettingParams('qr', !qrOn)
        }

        else if(settingsType == 'pr') {
            setPr(!pr)
            gettingParams('pr', !pr)
        }

        else if(settingsType == 'allow') {
            setAllow(!allow)
            gettingParams('allow', !allow)
        }

        else if(settingsType == 'mobile') {
            setMobileOn(!mobileOn)
            gettingParams('mobile', !mobileOn)
        }

        else if(settingsType == 'email') {
            setEmailOn(!emailOn)
            gettingParams('email', !emailOn)

        }
    }

    function gettingParams(type, val) {
        let params = {
            qr_enabled: type == 'qr' ? val : qrOn,
            any_amount: type === 'pr'? val : pr,
            allow_enter_amount: type === 'allow' ? val : allow,
            customize_amount: listAmount,
            show_mobile: type === 'mobile' ? val : mobileOn,
            show_email: type === 'email' ? val : emailOn,
            personal_message: personalMessage,
            field_1: field1,
            show_field_1: type === 'sf1' ? val : showField1,
            field_2: field2,
            show_field_2: type === 'sf2' ? val : showField2,
            fb,
            ig,
            web: website,
            notification_url: notificationURL
        }

        const set = new Set(listAmount);
        const amountHasDuplicates = set.size < listAmount.length;
        if(amountHasDuplicates) {
            message.error('Duplicate amount is not allowed');
        } else {
            updateQrDetails(params)
        }
    }

    function settingTradeName(val) {
        if(val.length <= 24) {
            setTradeName(val)
        }
        
        if(val.length >= 24){
            setHideError(false)
            setErrorMsg("Max of 24 Character Limit")
        }

        else {
            setHideError(true)
            setErrorMsg("")
        }
    }

    function cancelEdit(){
        setToggleEdit(!toggleEdit); 
        getDetails();
        setHideError(true)

    }

    async function getDetails () {
        try {
            let response;

            if(testMode && isChild) {
                response = await qrServices.getQrDetails(testMode, trade_name)
            }

            else {
                response = await qrServices.getQrDetails(testMode)
            }

            console.log(response)

            if(response.data.status === 'failed') {
                // message.error(response.data.message, 3)
                
                if(testMode) {
                localStorage.setItem("SbTradeName", "")
                }
                else {
                localStorage.setItem("tradeName", "")
                }

                setQrOn(!qrOn)
            }

            else {
                setTradeName(response.data.trade_name)
                
                if(testMode) {
                    localStorage.setItem("SbTradeName", response.data.trade_name)
                }
                else {
                    localStorage.setItem("tradeName", response.data.trade_name)
                }

                setQrOn(response.data.qr_enabled)
                setPr(response.data.any_amount)
                setAllow(response.data.allow_enter_amount)
                setMobileOn(response.data.show_mobile)
                setEmailOn(response.data.show_email)
                setListAmount(response.data.customize_amount ? response.data.customize_amount : [])
                setPersonalMessage(response.data.personal_message)
                setField1(response.data.field_1)
                setShowField1(response.data.show_field_1)
                setField2(response.data.field_2)
                setShowField2(response.data.show_field_2)
                setFb(response.data.fb)
                setIg(response.data.ig)
                setWebsite(response.data.web)
                setNotificationURL(response.data.notification_url)
            }
        }

        catch (error) {
            console.log(error)
        }
    }

    async function updateQrDetails(params) {
        try {
            const response = await qrServices.updateQrDetails(testMode, params)

            if(response.data.status === 'failed') {
                message.error(response.data.message, 3)
            }

            else {
                setEditPersonalMessage(true)
                setEditNotificationURL(true)
                setEditCustom(true)
                setEditSocial(true)
                setCustomModal(false)
                setCustomDrawer(false)
                getDetails()
            }
        }

        catch (error) {
            console.log(error)
        }
    }

    async function editQR () {

        if(tradeName >= 24){
            setHideError(false)
            setErrorMsg("Max of 24 Character Limit")
            return
        }

        else if(!tradeName){
            setHideError(false)
            setErrorMsg("Please enter your trade name")
            return
        }

        let noSpace = tradeName.replace(/\s/g, '')
        let params = {
            new_qr_trade_name: noSpace,
        }

        try {
            const response = await qrServices.changeTradeName(testMode, params)
            if(response.data.status === "failed") {
                setErrorMsg(response.data.message)
                setHideError(false)
            }

            else if (response.data.status === "success") {
                localStorage.setItem("tradeName", response.data.trade_name)
                message.success('Trade name modified', 3)
                getDetails()
                setToggleEdit(false)
                setHideError(true)

            }
        }

        catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        if((localStorage.getItem("Level") > 0 && tName != "") || testMode) {
            getDetails()
        }
    }, [])

    return(
        <div>
            {/* <MetaTag title="My Checkout"/> */}
            <div hidden={!noTradeName}>
                <SetupTradeName myConfig={yourConfig} REACT_APP_API_URL={REACT_APP_API_URL} />
            </div>
            
            <div hidden={noTradeName}>
                <CustomInfoModal showModal={showModal} setShowModal={setShowModal} setCustomModal={setCustomModal} customImg={Icon.CustomAmount} />
                <CustomAmountModal customModal={customModal} setCustomModal={setCustomModal} listAmount={listAmount} setListAmount={setListAmount}
                    removeIcon={Icon.RemoveIcon} gettingParams={gettingParams} getDetails={getDetails} />
                <CustomInfoDrawer showDrawer={showDrawer} setShowDrawer={setShowDrawer} setCustomDrawer={setCustomDrawer} customImg={Icon.CustomAmountMobile} />
                <CustomAmountDrawer customDrawer={customDrawer} setCustomDrawer={setCustomDrawer} listAmount={listAmount} setListAmount={setListAmount}
                    removeIcon={Icon.RemoveIcon} gettingParams={gettingParams} getDetails={getDetails} />

                <div className={ac.qrDiv}>
                    <Grid container style={{padding: isMobile ? '32px 32px 8px 32px' : 0}}>
                        <Grid item lg={5} sm={12} xs={12} align={isMobile? "center" : 'right'}>
                            <div style={{paddingRight: 12}}>
                                <QRCode value={qrOn ? qrLink : notFound} size={120} level={'L'} />
                            </div>
                        </Grid>
                        <Grid item lg={7} sm={12} xs={12} align={isMobile ? "center" : 'left'}>
                            <div className={ac.qrDetails}>
                                <div className={ac.qrHeaderText}>
                                    {tradeName} 
                                </div>
                                <div className={ac.hideOverflow}>
                                    <a className={ac.qrLinkText} target="_blank">{qrLink}</a>
                                </div>
                                <Button className={ac.viewMyCheckoutPage} style={{marginTop: '10px'}} onClick={()=> window.open( qrOn ? qrLink : notFound, '_blank' )}>
                                    View My Checkout page
                                </Button>
                            </div>
                        </Grid>
                    </Grid>

                    <div className={ac.qrSettingsDiv}>
                        {customComponentSettings(
                            <div>
                                Your Checkout is <span style={{color: qrOn ? '#000000' : '#E24C4C'}}>{qrOn ? 'ON' : 'OFF'}</span>
                            </div>,
                            `${qrOn ? 'Anyone' : 'No one'} can see and send money to your BUx Account.`,
                            'qr', qrOn
                            )
                        }

                        <div hidden={!qrOn}>
                            <div className={ac.toggleSettings}>
                                <div className={ac.personalMenu}>
                                        <div style={{width: toggleEdit ?'100%' : ''}}>
                                            {customTextTitle('QR Checkout Link', 
                                                toggleEdit ?
                                                <Input  size="large" 
                                                        placeholder="your-new-trade-name"
                                                        maxLength={24} 
                                                        style={{width: '100%'}}
                                                        onChange={(e) => settingTradeName(e.target.value)} />
                                                :
                                                <span style={{color: '#000000'}}>{qrLink}</span>)
                                            }
                                            <div style={{color: 'red'}} hidden={hideError}>
                                                {errorMsg}
                                            </div>
                                         
                                        </div>
                                        <div hidden={toggleEdit}>
                                            <a style={{color: '#f5922f'}} onClick={() => setToggleEdit(!toggleEdit)}>
                                                <b>Edit</b>
                                            </a>
                                        </div>
                                </div>
                                <div align="right" style={{marginTop: 12}} hidden={!toggleEdit}>
                                    <button className={ac.cancelEdit} onClick={() => cancelEdit()}>
                                        Cancel
                                    </button>
                                    <button className={ac.saveEdit} onClick={() => editQR()}>
                                        Save
                                    </button>
                                </div>
                            </div>
                            <div className={ac.toggleSettings}>
                                <div className={ac.qrSettingsHeader}>Select Payment Request</div>
                                <div style={{marginTop: 8, display: 'flex'}}>
                                    <div>
                                        <Radio checked={pr} onChange={() => radioChange('pr')}/> <span>Any Amount</span>
                                    </div>
                                    <div style={{marginLeft: 16}}>
                                        <Radio checked={!pr} onChange={() => radioChange('pr')} /> <span>Custom Amount list</span>
                                    </div>
                                </div>

                                <div align="center" style={{marginTop: 8}} hidden={pr || listAmount.length > 0}>
                                    <button className={ac.customListBtn} onClick={() => isMobile ? setCustomDrawer(true) : setCustomModal(true)}>
                                        + Create Custom List
                                    </button>

                                    <div style={{marginTop: 8}}>
                                        <a className={ac.qrLinkText} 
                                        onClick={() => isMobile ? setShowDrawer(true) : setShowModal(true)}>
                                            What's this?
                                        </a>
                                    </div>
                                </div>

                                <div hidden={listAmount.length == 0 || pr}>
                                    <div className={ac.personalMenu} style={{marginTop: 12}}>
                                        <div className={ac.qrSettingsHeader}>
                                            Allow customer to enter desired amount on top of the list.
                                        </div>
                                        <div className={ac.qrSettingsActions}>
                                        <Switch checked={allow} onChange={() => radioChange('allow')}
                                            size="small" style={{background: `${allow ? '#F5922F' : '#D1D5DD'}`}}/>
                                        </div>
                                    </div>
                                    <Grid container className={ac.amountTable}>
                                        {
                                            listAmount.map((item, key) => {
                                                return(
                                                    <Grid item lg={4} sm={6} className={ac.tdStyle}>
                                                        PHP {item}
                                                    </Grid>                                           
                                                )
                                            })
                                        }
                                    </Grid>


                                    <div style={{marginTop: 8}} align='center'>
                                            <a className={ac.qrLinkText} onClick={() => isMobile ? setCustomDrawer(true) : setCustomModal(true)}>Edit List?</a>
                                    </div>
                                </div>
                            </div>

                            {customComponentSettings(
                                <div>
                                    Show Mobile Number
                                </div>,
                                'Anyone can see your mobile number as your contact information',
                                'mobile', mobileOn
                                )

                            }

                            {customComponentSettings(
                                <div>
                                    Show Email Address
                                </div>,
                                'Anyone can see your email address as your contact information',
                                'email', emailOn
                                )
                            }

                            <div className={ac.toggleSettings}>
                                <div className={ac.personalMenu}>
                                    <div>
                                        {customTextTitle('Add Personal Message', 'This will be displayed on the header part of your Checkout page.')}
                                    </div>
                                    <div hidden={!editPersonalMessage}>
                                        <a style={{color: '#f5922f'}} onClick={() => setEditPersonalMessage(false)}>
                                            <b>
                                                {!personalMessage ? 'Add' : 'Edit'}
                                            </b>
                                        </a>
                                    </div>
                                </div>
                                
                                <div hidden={editPersonalMessage}>
                                    {customePlaceholder('Personal Message')}
                                    <TextArea row={isMobile ? 8 : 6} value={personalMessage} maxLength={200} onChange={(e) => setPersonalMessage(e.target.value)}/>
                                    <div align="right" style={{marginTop: 12}}>
                                        <button className={ac.cancelEdit} onClick={() => setEditPersonalMessage(true)}>
                                            Cancel
                                        </button>
                                        <button className={ac.saveEdit} onClick={() => gettingParams()}>
                                            Save
                                        </button>
                                    </div>
                                </div>

                                <div hidden={!editPersonalMessage || !personalMessage} className={ac.qrDetails} >
                                    {customePlaceholder('Personal Message')}
                                    {personalMessage}
                                </div>
                            </div>
                                <div className={ac.toggleSettings}>
                                <div className={ac.personalMenu}>
                                    <div>
                                        {customTextTitle('Add Postback URL', 'This endpoint will receive updates via API Postback for every payment/status change')}
                                    </div>
                                    <div hidden={!editNotificationURL}>
                                        <a style={{color: '#f5922f'}} onClick={() => setEditNotificationURL(false)}>
                                            <b>
                                                {!notificationURL ? 'Add' : 'Edit'}
                                            </b>
                                        </a>
                                    </div>
                                </div>
                                
                                <div hidden={editNotificationURL}>
                                    {customePlaceholder('Notification URL')}
                                    <TextArea row={isMobile ? 8 : 6} value={notificationURL} maxLength={200} onChange={(e) => setNotificationURL(e.target.value)}/>
                                    <div align="right" style={{marginTop: 12}}>
                                        <button className={ac.cancelEdit} onClick={() => setEditNotificationURL(true)}>
                                            Cancel
                                        </button>
                                        <button className={ac.saveEdit} onClick={() => gettingParams()}>
                                            Save
                                        </button>
                                    </div>
                                </div>

                                <div hidden={!editNotificationURL || !notificationURL} className={ac.qrDetails} >
                                    {customePlaceholder('Postback URL')}
                                    {notificationURL}
                                </div>
                            </div>
                        
                            <div className={ac.toggleSettings}>
                                <div className={ac.personalMenu}>
                                    <div>
                                        {customTextTitle('Add Custom Field', 'Add additional custom fields on your Checkout page')}
                                    </div>
                                    <div hidden={!editCustomField}>
                                        <a style={{color: '#f5922f'}} onClick={() => setEditCustom(false)}>
                                            <b>
                                                {!field1  && !field2  ? 'Add' : 'Edit'}
                                            </b>
                                        </a>
                                    </div>
                                </div>

                                <div hidden={!field1  && !field2  && editCustomField}>
                                    {customePlaceholder('Field name 1')}
                                    <Input value={field1} size="large" maxLength={16} onChange={(e) => setField1(e.target.value)} hidden={editCustomField} />
                                    <div hidden={!editCustomField || !field1 }>
                                        {field1}
                                    </div>

                                    <div style={{marginTop: 4}}
                                        hidden={editCustomField}
                                        >
                                        <Checkbox 
                                        checked={showField1} 
                                        onChange={() => {setShowField1(!showField1); }}/> <span className={ac.checkBoxText}>Display this field on your Checkout Page</span>
                                    </div>

                                    {customePlaceholder('Field name 2')}
                                    <Input value={field2} size="large" maxLength={16}  onChange={(e) => setField2(e.target.value)} hidden={editCustomField}/>
                                    <div hidden={!editCustomField || !field2 }>
                                        {field2}
                                    </div>

                                    <div style={{marginTop: 4}}
                                        hidden={editCustomField}
                                        >
                                        <Checkbox 
                                        hidden={!editCustomField}
                                        checked={showField2} 
                                        onChange={() => {setShowField2(!showField2);}}/> <span className={ac.checkBoxText}>Display this field on your Checkout Page</span>
                                    </div>

                                    <div align="right" style={{marginTop: 12}} hidden={editCustomField}>
                                        <button className={ac.cancelEdit} onClick={() => setEditCustom(true)}>
                                            Cancel
                                        </button>
                                        <button className={ac.saveEdit} onClick={() => gettingParams()}>
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className={ac.toggleSettings} hidden={testMode}> 
                                <div className={ac.personalMenu}>
                                    <div>
                                        {customTextTitle('Add your Social Media and Website', 'Display your Social Media account or website on your Checkout Page')} 
                                    </div> 
                                    <div hidden={!editSocial}>
                                        <a style={{color: '#f5922f'}} onClick={() => setEditSocial(false)}>
                                            <b>
                                                {!fb  && !ig  && !website  ? 'Add' : 'Edit'}
                                            </b>
                                        </a>
                                    </div>
                                </div>

                                <div hidden={(!fb  && !ig  && !website  && editSocial)}>
                                    {customePlaceholder('Facebook')}
                                    <Input 
                                        hidden={editSocial}
                                        maxLength={32}
                                        addonBefore={
                                                <div hidden={editSocial}> 
                                                    <img src={Icon.FB} alt="fb logo"/> www.facebook.com/
                                                </div>
                                            } 
                                        size="large" placeholder="your-facebook-page" 
                                        value={fb} onChange={e => setFb(e.target.value)}/>

                                    <div className={ac.hideOverflow} style={{margin: 0}}>
                                        <a className={ac.qrLinkText} onClick={() => window.open(`https://www.facebook.com/${fb}`)} hidden={!editSocial || !fb }>
                                            {`www.facebook.com/${fb}`}
                                        </a>
                                    </div>
                                    
                                    {customePlaceholder('Instagram')}
                                    <Input 
                                        hidden={editSocial}
                                        maxLength={32}
                                        addonBefore={
                                                <div hidden={editSocial}> 
                                                    <img src={Icon.IG} alt="ig logo" />  www.instagram.com/
                                                </div>
                                            } size="large" placeholder="your-instagram-handle" 
                                            value={ig} onChange={e => setIg(e.target.value)}/>

                                    <div className={ac.hideOverflow} style={{margin: 0}}>
                                        <a className={ac.qrLinkText} onClick={() => window.open(`https://www.instagram.com/${ig}`)} hidden={!editSocial || !ig }>
                                            {`www.instagram.com/${ig}`}
                                        </a>
                                    </div>
                                    
                                    {customePlaceholder('Website')}
                                    <Input 
                                        placeholder="https://samplewebiste.com"
                                        hidden={editSocial}
                                        maxLength={64}
                                        size="large"
                                            value={website} onChange={e => setWebsite(e.target.value)}/>

                                    <div className={ac.hideOverflow} style={{margin: 0}}>
                                        <a className={ac.qrLinkText} onClick={() => window.open(`${website}`)} hidden={!editSocial || !website }>
                                            {website}
                                        </a>
                                    </div>
                                
                                    <div align="right" style={{marginTop: 12}} hidden={editSocial}>
                                        <button className={ac.cancelEdit} onClick={() => setEditSocial(true)}>
                                            Cancel
                                        </button>
                                        <button className={ac.saveEdit} onClick={() => gettingParams()}>
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={ac.shareQrDiv} hidden={isMobile || !qrOn}>
                    <div className={ac.shareText}>
                        Share My Checkout to get paid
                    </div>
                    
                    <div align='center'>
                        <Input type="text" readOnly value={qrLink} size="large" style={{ position: 'absolute', top: '-10000px'}} ref={(textarea) => buxQrLink = textarea}/>

                        <div className={ac.shareBtnDiv}>
                            {customShareBtn(Icon.CopyLink, 'Copy Link')}
                            {customShareBtn(Icon.Messenger, 'Messenger')}
                            {/* {customShareBtn(Icon.Download, 'Download')} */}
                            {/* {customShareBtn(Icon.Facebook, 'Facebook')} */}
                            {/* <DownloadQR tradeName={tradeName}/> */}
                        </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default BuxQrMain;