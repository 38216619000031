import React from 'react';
import BalanceCard from '../../../components/multiwallet/components/transfer_money/balance_card';
import TransferMoneyBoxEditable from '../../../components/multiwallet/components/transfer_money/transfer_money_box';
import multiwalletServices from '../../../components/multiwallet/multiwalletServices';
import multiwalletActions from '../../../components/multiwallet/multiwalletActions';
import { useSelector, useDispatch } from '../../../__test__/react-redux-hooks';
import { isMobile } from 'react-device-detect';
import { history } from '../../../store/history';
import EditableFormTableCellHooks from '../../../components/multiwallet/components/transfer_money/sub_wallet_editable_table';
import TestModeBanner from '../../../components/cards/test_mode/testModeBanner';
import EditableSubwalletTable from "../../../components/multiwallet/components/transfer_request/sub_wallet_editable_table";
import TransferMoneyRequestTable from "../../../components/multiwallet/components/transfer_request/transfer_request_table";

const TransferMoney = (props) => {
    const [subWallData, setSubWallData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const transMoneyBoxData = useSelector(state => state.multiwallet.transMoneyBoxData);
    const kybStep = localStorage.getItem("KYBStep");
    const userType = localStorage.getItem("userType");
    const approvedStep = [11, 12]
    const testMode = (history.location.pathname.indexOf('/test') > -1 && localStorage.getItem("userType") == "CO");
    const [bal, setBal] = React.useState(0);
    const notApprovedKyb = (userType == "CO" && localStorage.getItem("KYBStep") < 11) && ((!testMode || localStorage.getItem("userType") == "CO") && (!approvedStep.includes(parseInt(kybStep)) && !testMode));
    const isMultiwallet = localStorage.getItem('is_multiwallet') === 'true';
    const isSbMultiwallet = localStorage.getItem("is_sb_multiwallet") === 'true';
    const [subSearchValue, setSubSearchValue] = React.useState('');

    const isChild = localStorage.getItem("is_child") === 'true';
    const dispatch = useDispatch();

    const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
    const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
    const isDell1 = window.screen.width * window.devicePixelRatio === 1366 && window.screen.height * window.devicePixelRatio === 768;
    const isMac = window.screen.width * window.devicePixelRatio === 1440 && window.screen.height * window.devicePixelRatio === 900;

    const isDellReso = (isSmallReso || isDell || isDell1)
    const segments = localStorage.getItem("segments")

    const endpoints = useSelector(state => state.child_endpoints.endpoints);

    const definedWalletSettings = JSON.parse(localStorage.getItem('defined_wallet_settings'));
    const {is_maker_approver_enabled: isMakerApproverEnabled = false} = definedWalletSettings;

    const getSubwalletUsers = async (search, start, end, status, kycLevel, pageSize, page) => {
        setIsLoading(true);
        setSubSearchValue(search);
        try {
            const res = await multiwalletServices.getSubwalletUsers(testMode, search, start, end, status, testMode? null : 1, pageSize, page);
            if (res) {
                console.log(res)
                setSubWallData(res);
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    const hasEmptyAmount = (data) => {
        const isEmpty = data.every(item => item.amount && item.amount && item.amount >= 1);
        if (isEmpty) {
            return false;
        }
        return true;
    }

    const getTotalAmount = (data) => {
        let total = 0;
        data && data.map((row, i) => {
            total = total + parseFloat(row.amount ? row.amount : 0);
        })
        return total;
    }

    const toggleLoading = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 50);
    }

    const handleRemoveRow = (email) => {
        const dataSource = [...transMoneyBoxData];
        dispatch(multiwalletActions.setTransferMoneyBoxData(dataSource.filter((item) => item.email !== email)));
        dispatch(multiwalletActions.setTransferBtn(!hasEmptyAmount(dataSource.filter((item) => item.email !== email))));
        dispatch(multiwalletActions.setTransferBoxTotalAmount(getTotalAmount(dataSource.filter((item) => item.email !== email))));
        toggleLoading();
    };

    const changePage = (page) => {
        getSubwalletUsers(subSearchValue, '', '', '', '', 10, page);
    }

    const getMultiwalletAnalytics = async (isTestMode, start = '', end = '') => {
        try {
            const res = await multiwalletServices.getMultiwalletAnalytics(isTestMode, start, end);
            if (res) {
                dispatch(multiwalletActions.setBalances(res.balances));
                const analytics = {
                    master_to_sub: res.master_to_sub,
                    sub_to_master: res.sub_to_master,
                    total_sub_users: res.sub_users
                }
                dispatch(multiwalletActions.setAnalytics(analytics));
                setBal(res.balances.float);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onSuccessTransfer = () => {
        dispatch(multiwalletActions.setTransferBtn(false));
        dispatch(multiwalletActions.setTransferMoneyBoxData([]));
        dispatch(multiwalletActions.setTransferBoxTotalAmount(0));
        getMultiwalletAnalytics();
    }

    const onTransferRequestSuccess = () => {
      const subwalletData = JSON.parse(JSON.stringify(subWallData));

      setSubWallData(null);

      subwalletData.results.forEach((item) => {
        item.amount = "";
        item.transfer_id = "";
      });

      setTimeout(() => {
        setSubWallData(subwalletData);
      }, 0);
    };
    

    const onChangeAmount = (row, key) => {
        const newData = subWallData && subWallData;
        const index = newData && newData['results'].findIndex(item => row.sub_id === item.sub_id);
        const item = newData && newData['results'][index];
        newData && newData['results'].splice(index, 1, {
            ...item,
            ...row,
        });
        setSubWallData(newData);
    }

    const onTransBoxChangeAmount = (row, key) => {
        const newData = transMoneyBoxData && transMoneyBoxData;
        const index = newData && newData.findIndex(item => row.email === item.email);
        const item = newData && newData[index];
        newData && newData.splice(index, 1, {
            ...item,
            ...row,
        });
        dispatch(multiwalletActions.setTransferMoneyBoxData(newData));
    }

    const isApprover = !isChild || endpoints.includes('approver_wallet_transfer');
    const isMaker = !isChild || endpoints.includes('maker_wallet_transfer');

    const redirectPage = () => {
        let current_path = window.location.pathname
        if (localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null) {
            history.push('/login?next=' + current_path);
        }
        else if (testMode && !isSbMultiwallet) {
            history.push('/test/dashboard');
        }
        else if (!testMode && (notApprovedKyb || !['CO'].includes(localStorage.getItem("userType")) || !isMultiwallet || (isChild && (!isMakerApproverEnabled || (!isApprover && !isMaker))))) {
            history.push('/dashboard');
        }
        else if (isMobile) {
            history.push('/mobile/multiwallet');
        }
        else {
            getSubwalletUsers();
            getMultiwalletAnalytics(testMode);
        }
    }

    React.useEffect(() => {
        redirectPage();
    }, [])

    return (
      <div
        className={isDell ? "top-12" : "top-40"}
        style={{ marginRight: isDellReso ? "2vw" : isMac ? "5vw" : "8vw" }}
      >
        <TestModeBanner width={"85vw"} top={64} right={0} />
        <BalanceCard bal={bal} />
        {!isMakerApproverEnabled ? (
          <>
            <EditableFormTableCellHooks
              segments={segments}
              data={subWallData}
              isLoading={isLoading}
              onAmountChange={onChangeAmount}
              onApplyFilter={getSubwalletUsers}
              onChangePage={changePage}
              setLoading={() => toggleLoading()}
            />
            <TransferMoneyBoxEditable
              segments={segments}
              data={transMoneyBoxData}
              isLoading={isLoading}
              onRemoveRow={(data) => handleRemoveRow(data)}
              onAmountChange={(data) => onTransBoxChangeAmount(data)}
              onTransferSuccess={onSuccessTransfer}
              toggleLoading={() => toggleLoading()}
            />
          </>
        ) : (
          <>
            {isMaker && (
              <EditableSubwalletTable
                segments={segments}
                data={subWallData}
                isLoading={isLoading}
                onAmountChange={onChangeAmount}
                onApplyFilter={getSubwalletUsers}
                onChangePage={changePage}
                setLoading={() => toggleLoading()}
                onTransferRequestSuccess={onTransferRequestSuccess}
              />
            )}
            <TransferMoneyRequestTable
                enableTransfer={isApprover}
                segments={segments}
                data={transMoneyBoxData}
                isLoading={isLoading}
                onRemoveRow={(data) => handleRemoveRow(data)}
                onAmountChange={(data) => onTransBoxChangeAmount(data)}
                onTransferSuccess={onSuccessTransfer}
                toggleLoading={() => toggleLoading()}
                subwalletData={subWallData}
              />
          </>
        )}
      </div>
    );
}

export default TransferMoney;