import React from "react";
import { Input, Button} from 'antd';
import PromoActive from '../../../../static/icons/promo_active.svg';
import PromoInactive from '../../../../static/icons/promo_inactive.svg';
import PromoDisabled from '../../../../static/icons/promo_disabled.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const PromoVoucherSetup = props => {

    const {
        headerText, handleChange, params, setParams, isLoading, view_path
    } = props;
    
    const isDisabled = view_path === 'edit_promotion' && (params && !params['is_draft']) ? true : false
    const handleDiscountType = (type) => {
        setParams({...params, 'discount_type': type})
    }

    const dis_type = {
        FI: 'Fixed amount',
        PE: 'Percentage'
    }

    const TwoColumnDiv = (params1, params2,) => {
        return(
            <div className="twoCol top-32" align='left'>
                <div style={{width: '49%'}} className="rigth-8">
                    <div className="wc-text placeholder-text-color">
                        {params1['placeholder']}
                    </div>
                    <div className="top-4">
                        {
                            params1['name'] &&
                            <Input 
                            placeholder={params1['placeholder']}
                            type={params1['type']}
                            name={params1['name']}
                            value={params1['value']}
                            onChange={handleChange}
                            disabled={isLoading || isDisabled}
                            addonBefore={params1['addonBefore']}

                            />
                        }
                        {
                            params1['text'] &&
                            <Button 
                                style={{width: '100%'}}
                                disabled 
                                className="twoCol padding-top-4">
                                    {params1['text']}
                                    <ExpandMoreIcon className="icon-style" />
                            </Button>
                        }
              
                    </div>
                </div>
                <div style={{width: '49%'}} className="left-8">
                    <div className="wc-text placeholder-text-color">
                        {params2['placeholder']}
                    </div>
                    <div className="top-4">
                        {
                            params2['name'] &&
                            <Input 
                            placeholder={params2['placeholder']}
                            type={params2['type']}
                            name={params2['name']}
                            value={params2['value']}
                            onChange={handleChange}
                            disabled={isLoading || isDisabled} 
                            addonBefore={params2['addonBefore']}
                            />
                        }
                    

                    </div>
                </div>
            </div>
        )
    }

    const ValueColumnDiv = (key, value) => {
        return(
            <div className="twoCol promotion-value-div liveColor">
                <div className="normalTextStyle">
                    {key}
                </div>
                <div className="normalTextStyle medium-font-weight liveColor">
                    {value}
                </div>
            </div>
        )
    }


    const ViewVoucher = () => {
        return(
            <div>
                <div className="promotion-header">
                    Voucher details
                </div>

                <div className="top-32">
                    {ValueColumnDiv('Discount type', dis_type[params['discount_type']])}
                    {params['discount_type'] === 'FI' &&
                        ValueColumnDiv('Voucher amount', params['fixed_discount'])
                    }
                    { params['discount_type'] === 'PE' &&
                        <div>
                            {ValueColumnDiv('Voucher percentage', `${params['percentage_discount']}'%`)}
                            {ValueColumnDiv('Maximum discount amount', `₱ ${params['max_discount']}`)}
                        </div>
                    }
                    {ValueColumnDiv('Limit per user', params['q_merchant'])}
                    {ValueColumnDiv('Limit per day', params['q_day'])}
                    {ValueColumnDiv('Limit per user per day', params['q_merchant_day'])}
                </div>

                <div className="top-32">
                    <div className="promotion-discount-text" align="left">
                        Conditions 
                    </div>

                    <div className="top-32">
                        {ValueColumnDiv('Condition', 'Minimum amount of purchase')}
                        {ValueColumnDiv('Amount', `₱ ${params['min_amount']}`)}
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className="promotion-card padding-32 top-32">
            {
                view_path === 'view_promotion' ?
                <ViewVoucher />
                :
                <div>
                    <div className="promotion-header">
                    {headerText}
                    </div>

                    <div className="top-32" align='left'>
                        <div className="promotion-discount-text">
                            Discount type
                        </div>
                        <div className="top-32">
                            <button className="promotion-discount-btn right-16"
                                disabled={isDisabled}
                                onClick={() => handleDiscountType('FI')}>
                                <span className="right-8" style={{marginTop: '-4px'}}>
                                    {
                                        params['discount_type'] == 'FI' ?
                                            isDisabled ? 
                                                <img src={PromoDisabled} alt="check" />
                                                :
                                                <img src={PromoActive} alt="check" />
                                        :
                                        <img src={PromoInactive} alt="check" />

                                    }
                                </span>
                                Fixed amount
                            </button>
                            <button className="promotion-discount-btn"
                                disabled={isDisabled}
                                onClick={() => handleDiscountType('PE')}>
                                <span className="right-8" style={{marginTop: '-4px'}}>
                                    {
                                        params['discount_type'] == 'PE' ?
                                            isDisabled ? 
                                                <img src={PromoDisabled} alt="check" />
                                                :
                                                <img src={PromoActive} alt="check" />
                                            :
                                            <img src={PromoInactive} alt="check" />

                                    }
                                </span>
                                Percentage
                            </button>
                        </div>
                    </div>

                    <div className="top-32" align='left'>
                        {
                            params['discount_type'] == 'FI' &&
                            <div>
                                <div className="wc-text placeholder-text-color">
                                    Voucher amount*
                                </div>
                                <div className="top-4">
                                    <Input 
                                        disabled={isDisabled}
                                        style={{width: '100%'}}
                                        addonBefore="₱"
                                        value={params['fixed_discount']}
                                        name="fixed_discount"
                                        onChange={handleChange}
                                        />
                                </div>
                            </div>
                        }
                        {
                            params['discount_type'] == 'PE' &&
                            <div>
                            {TwoColumnDiv(
                                {
                                    placeholder: 'Voucher percentage*',
                                    name: 'percentage_discount',
                                    value: params['percentage_discount'],
                                    type: 'number',
                                    addonBefore: '%'
                                }, 
                                {
                                    placeholder: 'Maximum discount amount*',
                                    name: 'max_discount',
                                    value: params['max_discount'],
                                    type: 'number',
                                    addonBefore: '₱'
                                }
                            )}
                            </div>
                        }
            
                    </div>

                    {TwoColumnDiv(
                        {
                            placeholder: 'Limit per merchant*',
                            name: 'q_merchant',
                            value: params['q_merchant'],
                            type: 'number',
                        }, 
                        {
                            placeholder: 'Limit per day*',
                            name: 'q_day',
                            value: params['q_day'],
                            type: 'number',
                        }
                    )}
                    {TwoColumnDiv(
                        {
                            placeholder: 'Limit per merchant per day*',
                            name: 'q_merchant_day',
                            value: params['q_merchant_day'],
                            type: 'number',
                        },
                        {

                        }
                    )}

                    <div className="top-32" align='left'>
                        <div className="promotion-discount-text">
                            Conditions
                        </div>
                    </div>

                    {TwoColumnDiv(
                        {
                            placeholder: 'Conditions*',
                            text: 'Minimum amount of purchase',
                        }, 
                        {
                            placeholder: 'Amount',
                            name: 'min_amount',
                            value: params['min_amount'],
                            type: 'number',
                            addonBefore: "₱"
                        }
                    )}
                </div>

            }
           
        </div>
    )
}


export default PromoVoucherSetup;