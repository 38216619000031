import React from 'react'
import axios from 'axios'
import {Card, Spin, Input, Icon, Switch, message} from 'antd'
import enterpriseStyle from '../kybStyle'
import { isMobile } from 'react-device-detect'
import {NATURE_BUSINESS, NATIONALITY, POSITION} from '../../../constants/constants'
import Passed from '../../../../static/img/passed.svg'
import Failed from '../../../../static/img/failed.svg'
import MOA from '../../../../static/files/Updated_PFA.pdf'
import NDA from '../../../../static/files/Updated_NDA.pdf'

const ReviewMoaNda = props => {
    let datas = props.datas
    let segments = props.segments
    const userRole = localStorage.getItem("userType")
    const kyb = enterpriseStyle()
    const [data, setData] = React.useState(datas ? datas : [])    
    const [toggleOn, SetToggleOn] = React.useState(true)
    const [showSpin, setShowSpin] = React.useState(true)

    const [moaFile, setMoaFile] = React.useState("")
    const [moaFilename, setMoaFilename] = React.useState(!data || data.signed_moa_attachment == null ? null : data.signed_moa_attachment.slice(65))

    const [ndaFile, setNdaFile] = React.useState("")
    const [ndaFilename, setNdaFilename] = React.useState(!data || data.signed_nda_attachment == null ? null : data.signed_nda_attachment.slice(65))

    let kybStep = localStorage.getItem("KYBStep")
    let kybLevel = localStorage.getItem("Level")
    const [moaURL, setMoaURL] = React.useState("")
    const [ndaURL, setNdaURL] = React.useState("")

    let moaUpload = React.useRef()
    let ndaUpload = React.useRef()

    const adminRole = ['AD', 'KB', 'SD', 'OP', 'BS', 'AP', 'EN', 'FI',]
    const editRole = ['AD', 'CO', 'SW', 'SD', 'BS']
    const partnerRole = ['PS', 'PF']
    const createRole = ['AD', 'SD']
    const hiddenSteps = [8, 9, 11]
    
    async function fetchDocumentURLS(){
        
        try{
            let url = process.env.REACT_APP_API_URL + "/api/content/?key=moa";
            let response = await axios.get(url)
            setMoaURL(response.data.content)
        }
        
        catch(error){
            console.log(error.message)
        }
        try{
            let url1 = process.env.REACT_APP_API_URL + "/api/content/?key=nda";
            let response1 = await axios.get(url1)
            setNdaURL(response1.data.content)

        }
        
        catch(error){
            console.log(error.message)
        }
    }


    let legal_docus = []

    legal_docus = [
        {   
            title: isMobile ? 'MOA' : 'Memorandum of Agreement',
            text: 'Download MOA(PFA)',
            name: 'Upload MOA(PFA) with Signature',
            ref: moaUpload,
            file_name: moaFilename,
            file: moaURL ? moaURL : MOA
        },
        {   
            title: 'NDA',
            text: 'Download NDA',
            name: 'Upload NDA with Signature',
            ref: ndaUpload,
            file_name: ndaFilename,
            file: ndaURL ? ndaURL : NDA
        }
    ]

    const handleUploadFile = (info, type) =>{
        //Corporate documents
        if(type == 'MOA' || type == 'Memorandum of Agreement'){
            setMoaFile(info)
            setMoaFilename(info.name)
            props.setFile(info.name, 'MOA')
        }
        else if(type == 'NDA'){
            setNdaFile(info)
            setNdaFilename(info.name)
            props.setFile(info.name, 'NDA')

        }
    }  

    //removing file
    function handleRemoveFile(type){
        //Corporate documents
        if(type == 'MOA' || type == 'Memorandum of Agreement'){
            setMoaFilename(null)
            setMoaFile("")
            props.setFile('', 'MOA')

        }
        else if(type == 'NDA'){
            setNdaFilename(null)
            setNdaFile("")
            props.setFile('', 'NDA')

        }
    }

    const yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }   

    async function submitDocument(params){
    
        try{
            let url = process.env.REACT_APP_API_URL + "/api/admin/kyb/";
            let response = await axios.patch(url,params,yourConfig)

                     //response
            if(response.data.status == "failed"){
                message.error(response.data.message)
                setShowSpin(true)
            }
            else{
                message.success("Uploaded Counter signed MOA and NDA")
                window.scrollTo(0, 0)
                setShowSpin(true)
            }

        }
        
        catch(error){
            console.log(error.message)
        }
    }

    function validateFields(){
        let i;
        let mb = 10000000;
        let acceptedFiles = ['application/pdf']

        for(i=0; i < legal_docus.length; i++){
            if(legal_docus[i].file_name == null){
                message.error(`Please upload ${legal_docus[i].name} file`)
                // window.scrollTo(0, 0)
                return
            }

            else if((legal_docus[i].file_name != null && legal_docus[i].ref.current != null) && (legal_docus[i].ref.current.files.length > 0)){
                if(!acceptedFiles.includes(legal_docus[i].ref.current.files[0].type)) {
                    message.error(`${legal_docus[i].name} file must be .pdf only`)
                    // window.scrollTo(0, 0)
                    return

                } 
                
                else if(legal_docus[i].ref.current.files[0].size > mb){
                    message.error(`${legal_docus[i].name} file size must not exceed 10MB`)
                    // window.scrollTo(0, 0)
                    return

                }
            }
        }

        submitForm()
    }

    async function submitForm(){
        var formData = new FormData();
        setShowSpin(false)
        if(localStorage.getItem("userType") == "SD"){
            formData.append('submission_id', props.userId)
        }
        
        formData.append('submission_id', data.id)

        formData.append('signed_moa_attachment', moaFile);
        await submitDocument(formData);
        formData.delete('signed_moa_attachment');
        formData.append('signed_nda_attachment', ndaFile);
        await submitDocument(formData);
        formData.delete('signed_nda_attachment');
        
    }

    let legal_docu = []

    legal_docu = [
        {
            'name': 'MOA',
            'file_name': data.signed_moa_attachment == null ? null : data.signed_moa_attachment
        },
        {
            'name': 'NDA',
            'file_name': data.signed_nda_attachment == null ? null : data.signed_nda_attachment
        },
    ]

    function editBusinessInfo(val){
        props.openEdit(val)
    }

    function submitFailed(val){
        SetToggleOn(val)
        if(adminRole.includes(userRole) && localStorage.getItem("KYBType") != "creation"){
            props.setFailedStep(val, 5)
        }
    }

    React.useEffect(() => {
        fetchDocumentURLS()
        submitFailed(datas ? !datas.step_5_has_error : false)
    },[])

    return(
        <Card 
            className={`${kyb.formCardStyle} padding-16`}
            bodyStyle={{padding: '20px 16px 20px 16px'}}
            style={{
                border: toggleOn && 
                        adminRole.includes(userRole)
                        ? '2px solid #1DD28B' : 
                        !toggleOn && 
                        adminRole.includes(userRole)? 
                        '2px solid #E24C4C' : ''
            }}>

            {
                adminRole.includes(userRole) ?
                <div style={{position: 'relative', paddingBottom: 16, 
                            borderBottom: '1px solid #D1D5DD', marginBottom: 16}}>    
                     <div style={{position: 'absolute', top: '-20px', left: '-17px'}}>
                        <img src={toggleOn ? Passed : Failed} />    
                     </div>
                     <div align='right'>
                        <span className={kyb.miniText} style={{opacity: props.curStep >= 11 ? 0.5 : ''}}>
                            Mark as Passed: <Switch size="small" disabled={props.curStep >= 11} checked={toggleOn} onChange={() => submitFailed(!toggleOn)}/>
                        </span>
                     </div>
                </div>

                :
                
                ''
            }
            
            <div style={{margin: '0px 0px 32px 0px', display: 'flex', justifyContent: 'space-between'}} ref={props.cardDivRef}>
                <div className={`${kyb.greatText} liveColor`}>MOA and NDA</div>

                <div hidden={(!editRole.includes(userRole)) || (['CO','SW'].includes(localStorage.getItem('userType')) &&  hiddenSteps.includes(parseInt(kybStep)))}>
                    <button className={`outline-btn--${segments}`} onClick={() => editBusinessInfo(5)}>
                        <Icon type="edit" className={`text-${segments} right-4`}/>Edit
                    </button>
                </div>
            </div>

            <div className={`${kyb.tableStyle} liveColor`}>

            {
                legal_docu.map((item, key) => {
                    return(
                            <div className="twoCol top-20">
                            <div>
                                {item.name}
                            </div>
                            <div className={kyb.valueText} style={{fontWeight: 200}}>
                                <div>
                                    <a href={item.file_name} target="blank" className={`text-${segments} medium-font-weight`}>View</a>{adminRole.includes(userRole) || ['CO','SW'].includes(localStorage.getItem('userType')) && kybStep == 11 ?<span> | <a href={item.file_name} target="_blank" download className={`text-${segments} medium-font-weight`}>Download</a></span>  : ''}
                                </div>
                            </div>
                        </div>
                
                    )
                })
            }
            </div>

            <div hidden={['CO','SW'].includes(localStorage.getItem('userType')) || localStorage.getItem("KYBType") == "creation" || partnerRole.includes(userRole) || (adminRole.includes(userRole) && !toggleOn)} className="liveColor top-32">
            <div className={`${kyb.headerStrong}`}>Step 2</div>
            <div className={kyb.greatText}>Reupload MOA and NDA with countersign</div>
            {
                    legal_docus.map((item, key) => {
                        return(
                            <div key={key}>
                                <div style={{marginTop: 20}}>
                                    <div className={kyb.miniText}>{item.name}</div>
                                </div>

                                <input type="file"
                                    ref={item.ref}
                                    style={{display: 'none'}}
                                    onChange={(e)=>handleUploadFile(e.target.files[0], item.title)}
                                    accept=".pdf"
                                    >
                                </input>

                                <div className={kyb.uploadDiv}>
                                    <div className={kyb.uploadText} style={{padding: '7px 0px 7px 0px', width: '100%'}}>
                                    {/* upload */}
                                       <div style={{display: item.file_name == null ? 'block' : 'none'}}>Upload File</div> 
                                       {/* preview */}
                                       <div style={{display: item.file_name != null ? 'block' : 'none'}}>
                                            <div style={{display: 'flex'}}>
                                                <Icon type="file" className={`text-${props.segments} top-4 right-8`}/>
                                                <div id="fileName" className={kyb.fileName} style={{width: '50%'}}>{item.file_name}</div>
                                            </div>   
                                       </div>
                                    </div>

                                    <div>
                                    {/* upload */}

                                        <div style={{display: item.file_name == null ? 'block' : 'none'}}>
                                            <button data-test="upload-btn" className={`outline-btn--${props.segments} btn-small-height`} onClick={()=>item.ref.current.click()}>
                                                <div style={{display: 'flex'}}>
                                                    <Icon type="download" className="top-4 right-4" />
                                                    <div>
                                                        Upload  
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                       {/* preview */}
                                       <div style={{display: item.file_name != null ? 'block' : 'none', position: 'absolute', right: 42, marginTop: 8}}>
                                            <a href onClick={()=>{item.ref.current.value = null; handleRemoveFile(item.title)}} style={{padding: '8px 0px 0px 0px', fontWeight: 600}} className={`text-${props.segments}`}><Icon type='close' style={{fontSize: 20, fontWeight: 'bold'}} /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                
                <button className={`btn--${props.segments} btn-mobile-width top-20`} 
                style={{
                    opacity: 
                        !showSpin
                            ? 0.5 : '', 
                    cursor: 
                        !showSpin
                            ? 'not-allowed' : 'pointer'}}

                disabled={!showSpin} 
                onClick={() => validateFields()}
                >   
                    <div hidden={showSpin}><Spin /></div>
                    <div hidden={!showSpin}>Save</div>
                </button>
            </div>
            
    
            
        </Card>
    )
}

export default ReviewMoaNda