import React,{ useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import cardServices from '../cardServices';
import cardActions from '../cardActions';
import { history } from '../../../store/history';
import { isMobile } from 'react-device-detect';
import { Modal, Typography, Card, Button, Affix, Spin } from "antd";
import { Redirect } from "react-router";

const ConfirmPaybillsModal = ({visible, closeModal, inputs}) => {
  const visibleSuccessModal = useSelector(state => state.card['success_load_modal']);
  const [paybillsError, setPaybillsError] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const dispatch = useDispatch();
  const [paybillsSuccess, setPaybillsSuccess] = useState(false);
  const [id, setId] = useState(null)
  const otpUID = useSelector(state => state.card.otpUID);
  const segments = localStorage.getItem('segments');

  const value = {
    name: inputs[1] ? inputs[1]['Name'] : '',
    key: inputs[3] ? inputs[3] : '',
    amount: inputs[0] ? inputs[0] : 0,
    field1: inputs[1] ? inputs[1]['Field 1'] : '',
    val1: inputs[0] ? inputs[0]['Field 1'] : '',
    field2: inputs[1] ? inputs[1]['Field 2'] : '',
    val2: inputs[0] ? inputs[0]['Field 2'] : '',
    field3: inputs[1] ? inputs[1]['Field 3'] : '',
    val3: inputs[0] ? inputs[0]['Field 3'] : '',
    biller: inputs[2] ? inputs[2] : '',
  }

  const returnName = (key) => {
    switch (key) {
      case 'Electricity':
        return 'Electric Company';
      case 'Telecoms':
        return 'Telecom Compnay';
      case 'Cable':
        return 'Cable Provider';
      case 'Water':
        return 'Water Provider';
      case 'Credit Card':
        return 'Credit Card'; 
      case 'Schools':
        return 'School/University'; 
      case 'Insurance':
        return 'Insurance Company';
      case 'Others':
        return 'Selected Company';
      default:
        return 'Selected Company'
    }
  }

  const confirmPayment = async () => {
    setIsBtnLoading(true)
    const params = {
      'biller': inputs[2],
      'amount': value.amount['amount'],
      'input1': value.val1,
      'input2': value.val2 ? value.val2 : '',
      'input3': value.val3 ? value.val3 : '',
      'trans_uid': otpUID
    }
    try {
      const response = await cardServices.confirmPaybills(params);
      if(response.status === 'success') {
          setId(response.id);
          // paybills redirection
          setPaybillsSuccess(true)
          setIsBtnLoading(false);
          dispatch(cardActions.hideModal('confirm_paybills'));
        } else {
          setPaybillsError(true)
          setTimeout(() => {
            setPaybillsError(false)
          }, 3000);
          setIsBtnLoading(false);
        }
        console.log(response)
    } catch (error) {
      console.log(error);
      setPaybillsError(true);
    }
  }

  return (
    paybillsSuccess ? <Redirect to={{pathname: isMobile ? '/mobile/bills_payment_success' : '/bills_payment_success', state:{id: id, val: value } }} /> :
    <Modal visible={visible} footer={null} onCancel={closeModal} closable={!isBtnLoading} centered maskClosable={false} width={370} bodyStyle={{padding: 20}}>
        {paybillsError ? 
          <Affix offsetTop={38} style={{position: 'fixed', right: '47%', top: '18%', zIndex: '100'}} >
            <div style={styles.notif}>Payment error! Try again</div>
          </Affix>
        : null}
      <Typography style={styles.msg}>
        Please verify the following details to proceed with your payment:
      </Typography>
      <Card size="small" style={styles.detailsCard} bodyStyle={{padding: 0}}>
        <Typography style={styles.biller}>{value.key}</Typography>
        <div style={styles.detailsContent}>
          <Typography style={styles.textName}>{returnName(value.key)}</Typography>
          <Typography style={styles.textDesc}>{value.name}</Typography>
        </div>
        { value.field1 ? <div style={styles.detailsContent}>
            <Typography style={styles.textName}>{value.field1}</Typography>
            <Typography style={styles.textDesc}>{value.val1}</Typography>
          </div> 
        : null }
        { value.field2 ? <div style={styles.detailsContent}>
            <Typography style={styles.textName}>{value.field2}</Typography>
            <Typography style={styles.textDesc}>{value.val2}</Typography>
          </div> 
        : null }
         { value.field3 ? <div style={styles.detailsContent}>
            <Typography style={styles.textName}>{value.field3}</Typography>
            <Typography style={styles.textDesc}>{value.val3}</Typography>
          </div> 
        : null }
        <div style={styles.amount}>
          <Typography style={{color: '#2B2D33'}}>Amount</Typography>
          <Typography style={styles.textDesc}>
            { value.amount ? 
             'PHP ' + parseFloat(value.amount['amount']).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
              : 'PHP ' + 0
            }
          </Typography>
        </div>
      </Card>
      <div style={styles.btnContainer}>
        <button className={`btn--${segments}`} style={{...styles.btnCancel, opacity: isBtnLoading ? '50%' : null}} onClick={closeModal} disabled={isBtnLoading} >Cancel</button>
        <button className={`outline-btn--${segments}`} style={styles.btnPay} disabled={isBtnLoading} onClick={() => confirmPayment()}>
          {isBtnLoading ? <Spin size='small' /> : 'Pay'}
        </button>
      </div>
    </Modal>
  )
}

const styles = {
  root: {

  },
  detailsCard: {
    textAlign: 'left',
    margin: '16px 0px'
  },
    biller: {
    backgroundColor: '#E6EAF0',
    padding: '7px 20px',
    fontSize: 16,
    color: '#2B2D33'
  },
  textName: {
    fontSize: 12,
    color: '#2B2D33'
  },
  textDesc: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2B2D33'
  },
  detailsContent: {
    display: 'flex',
    padding: '7px 20px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  amount: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '7px 20px',
    borderTop: '.2px solid #E6EAF0'
  },
  msg: {
    color: '#000000',
    fontSize: 14,
    textAlign: 'left',
    padding: '44px 0 0 0',
    fontWeight: 'bold'
  },
  btnCancel: {
    height: 40,
    width: '48%',
    // color: '#F5922F',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
    // borderColor: '#F5922F',
  },
  btnPay: {
    height: 40,
    width: '48%',
    // backgroundColor: '#F5922F',
    // color: '#FFFFFF',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  notif: {
    backgroundColor: '#CF3D1D', 
    fontSize: 14, 
    fontWeight: 'bold', 
    width: '100%', 
    color: '#FFFFFF', 
    borderRadius: 4, 
    padding: '8px 16px', 
    textAlign: 'center'
  },

}

export default ConfirmPaybillsModal;