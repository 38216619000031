import React from 'react';
import { InfoMan } from '../../../static/images/test_mode';
import { Card, Typography } from 'antd';
import { isMobile } from 'react-device-detect';

const ReminderCard = (props) => {
    const type = props.type;
    return (
        <Card style={styles.root} bodyStyle={styles.rootBody} hidden={props.hidden}>
            <img alt='No Image' src={InfoMan} />
            <div>
                <Typography style={styles.title}>You are in <b>Test Mode: {type === 'BANKING' ? 'Online Banking' : 'E-Wallet'}</b></Typography>
                <div>
                    {type === 'BANKING' ? 
                    <Typography style={styles.subTitle}>
                        Your actions in this mode do not affect any live 
                        existing transactions. You can use your <b>BUx Account</b> or any 
                        <b> Dummy Account</b> to login.
                    </Typography>
                    :
                    <Typography style={styles.subTitle}>
                        Your actions in this mode do not affect any live existing transactions. 
                        You can use any <b>Mobile Number</b> to login.
                    </Typography>
                    }
                </div>
            </div>
        </Card>
    );
}

const styles = {
    root: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)', 
        borderRadius: 4,
        border: '1px solid #E6EAF0',
        margin: '16px 0'
    },
    rootBody: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16,
        width: isMobile ? '100%' : 568,
    },
    title: {
        fontWeight: '400',
        fontSize: isMobile? 14 : 16,
        color: '#000000',
        paddingBottom: 4
    },
    subTitle: {
        fontSize: isMobile? 10 : 12,
        color: '#000000',
    }
}

export default ReminderCard;