import React, {useEffect} from 'react'
import {Alert, Layout, Button} from 'antd'
import logo from '../../static/icons/logo.svg'
import ecommerceStyle from '../../static/css/Ecommerce'
import { history } from "../../store/history";
import axios from 'axios';
import Authorize from '../../static/img/authorize.svg'

const ShopifyAuthorize = props => {
    const ecom = ecommerceStyle();
    const { Header } = Layout;
    const [storeName, setStoreName] = React.useState("My Store")
    const [backURL, setBackURL] = React.useState("")
    const [showAlert, setShowAlert] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState("")
    const initbackURL = '/services/payments_partners/gateways/'+process.env.REACT_APP_SHOPIFY_CLIENT_ID+'/settings';
    

    function getLocation(href) {
        const l = document.createElement("a");
        l.href = href;
        return l;
    };

    useEffect(() => {
        let search = window.location.search;
        if (localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            history.push('/shopify/login_next' + search)
        }else{
            let params = new URLSearchParams(search);
            let shopify_store = encodeURIComponent(params.get('shop'));
            setStoreName(shopify_store);
            setBackURL("https://" + shopify_store + initbackURL);
        }
    }, [])


    // function getCredentials() {
    //     let referrer_loc = getLocation(document.referrer);
    //     let referrer = referrer_loc.protocol + "//" + referrer_loc.hostname;
    //     if(backURL == referrer){
    //         get_credentials()
    //     }else{
    //         setAlertMessage('Invalid Referrer. Ensure Redirect URL points to your website. Open again from Woocommerce/Prestashop Dashboard.')
    //         setShowAlert(true)
    //     }
    
    // }

    const AuthorizeStyle = {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        textAlign: 'center',
        color: '#000000',
        marginTop: '20px'
    }

    const textStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16ox',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#000000',
        marginTop: '20px'

    }

    const buttonStyle = {
        height: '48px',
        borderRadius: '4px',
        width: '173px',
        marginRight: '20px',
        color: '#1d82b8',
        border: '2px solid #1D82B8'
    }

    async function getCredentials(){

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let auth_code = params.get('code');
        let hmac = params.get('hmac');
        let shopify_store = encodeURIComponent(params.get('shop'));
        setStoreName(shopify_store);

        let yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
          };



        let response = await axios.get(
            process.env.REACT_APP_API_URL + '/api/shopify/authorize/?auth_code='+auth_code+'&hmac='+hmac+'&shop='+shopify_store, yourConfig
        );

        
        if(response.data.status == "success") {

                window.location.href = backURL;
        }

    }
    return(
        <div>
            <Header style={{height: '60px', display: 'flex', justifyContent: 'center', backgroundColor: '#fff', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)'}}>
                <img src={logo} style={{marginBottom: '20px', marginTop: '12px'}} alt="#" />
            </Header>

            <div style={{width: '100%', marginTop: '10px'}} hidden={!showAlert}>
                <Alert message={alertMessage} type="error" style={{marginBottom: '20px'}} showIcon/>
            </div>

            <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}>
                <div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img src={Authorize} alt="authorize_img" />
                    </div>
                    <div style={AuthorizeStyle}>
                        Authorize Shopify Access
                    </div>
                    <div style={textStyle}>
                        <span><strong>A Shopify Store: {storeName}</strong> </span>wants to request permission to access and link your BUx account.<br/>
                        This will redirect back to <span><strong>{backURL}</strong> </span><br/><br/>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                        <Button style={buttonStyle} onClick={() => window.location.href = backURL }>Cancel</Button>
                        <Button type="primary" style={{width: '173px', height: '48px'}}  onClick={() => getCredentials()}>Allow</Button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ShopifyAuthorize;