import React from 'react';
import { Button, Checkbox, Icon, List, Modal, Typography } from 'antd';
import DisabledCheckbox from '../../../static/images/disabled_checkbox_icon.svg';

const TableSettingsModal = (props) => {

    const tableSettingOptions = [
        {
          name:'Reference No.',
          dataIndex: 'code'
         },
         {
           name: 'Channel',
           dataIndex: 'channel_str'
         },
         {
           name: 'Date Generated',
           dataIndex: 'timestamp'
         },
         {
           name: 'Date Paid, Expires, Cancelled, Expired',
           dataIndex: 'date'
         },
         {
           name: 'Amount',
           dataIndex: 'amount'
         },
         {
           name: 'Receivable',
           dataIndex: 'seller_net'
         },
         {
           name: 'Field 1',
           dataIndex: 'param1'
         },
         {
           name: 'Field 2',
           dataIndex: 'param2'
         },
     ]

    return (
        <Modal
            visible={props.visible}
            onCancel={props.onClose}
            footer={false}
            width={356}
            closeIcon={<Icon type="close" style={{ color: '#F5922F' }} />}
            centered
        >

            <div style={{ borderRight: 0, marginTop: 12, background: 'transparent' }} mode="inline">
                <Typography style={{ backgroundColor: '#E6EAF0', color: '#000', fontSize: 14, padding: '12px 16px', marginTop: 24 }}>Customize Columns</Typography>
                <List
                    dataSource={tableSettingOptions}
                    renderItem={(item, i) =>
                        <div
                            style={{
                                borderTop: '1px solid #E6EAF0',
                                borderBottom: '1px solid #E6EAF0',
                                padding: i === 3 ? '4px 14px' : 14,
                                margin: '4px 0',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            {i < 6 ?
                                <img style={{ cursor: 'not-allowed' }} src={DisabledCheckbox} height={16} alt='No Image' />
                                :
                                <Checkbox id={item.dataIndex} disabled={i < 6}
                                    onChange={(e) => props.onChangeColumns(e)}
                                    defaultChecked
                                    color={'#DDD'}
                                ></Checkbox>
                            }
                            <Typography
                                style={{
                                    color: '#2B2D33',
                                    fontSize: 16,
                                    paddingLeft: 32
                                }}>{item.name}</Typography>
                        </div>
                    }
                />
                <div style={styles.footerBtn}>
                    <Button style={styles.saveBtn} onClick={props.onClose}>Save</Button>
                </div>
            </div>
        </Modal>
    )
}

const styles = {
    footerBtn: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 28
    },
    saveBtn: {
        height: 48,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 10,
        width: '48%',
    }
}

export default TableSettingsModal;