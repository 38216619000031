import React,{} from 'react';
import {history} from '../../../store/history';
import { BankIcon, EWalletIconWhite } from '../../../static/images/test_mode';

const TestModeBanner = (props) => {
  const testMode = (history.location.pathname.indexOf('/test') > -1);
  return (
    <div style={{...styles.root, ...props}} hidden={!testMode}>
      <div style={styles.body}>
        <img src={props.type === 'BANKING' ? BankIcon : EWalletIconWhite } alt='No Image' style={styles.icon} hidden={props.type == 'QR'}/>
        <label style={styles.label}>
          TEST MODE
        </label>
      </div>
    </div>
  )
}

const styles = {
  root: {
    borderTop: '4px #454545 solid', 
    textAlign: 'center',
    zIndex: '2',
    display: 'flex',
    justifyContent: 'center',
    width:'100%',
  },
  body: {
    backgroundColor: '#454545', 
    borderRadius: '0 0 10px 10px', 
    padding: '6px 16px 10px 16px', 
    fontSize: 14, 
    fontWeight: '900', 
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    paddingRight: 6
  },
  label: {
    paddingLeft: 6
  }
}

export default TestModeBanner;