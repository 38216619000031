import React from 'react'
import { Modal, Checkbox, Button } from 'antd'
import { isMobile } from 'react-device-detect'
import BuxLogo from '../../../static/icons/bux_qr/bux_logo.svg'
import Mail from '../../../static/icons/mail.svg'
import Phone from '../../../static/icons/dpphone.svg'
import Warning from '../../../static/icons/warning_icon.svg'

const InstaPayModalDisclaimer = props => {

    const { 
        modalVisibility,
        setModalVisibility,
        proceed,
        channel,
    } = props

    const [checked, setChecked] = React.useState(true)

    return(
        <Modal
            style={{top: '20px'}}
            bodyStyle={{padding: isMobile ? '25px 16px 25px 16px' : '40px'}}
            width={isMobile ? '90%' : '593px'}
            visible={modalVisibility}
            closable={false}
            footer={null}>
            
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <img src={BuxLogo}/>
            </div>

{/*              
            <div style={style.noteDiv}>
                <div style={{alignSelf: 'top'}}  hidden={isMobile}>
                    <img src={Warning} style={{marginRight: '17px'}}/>
                </div>
                <span>
                    <b>GCash</b> and <b>UBP</b> payment channels are currently not supported in Instapay and may result in an error. Please avoid selecting them within Instapay.
                </span>
            </div>
             */}

            <div style={{...style.disclaimer, marginTop: '20px'}}>
                We will now redirect you to UPay to complete your InstaPay payment. Kindly refrain from sharing your credentials to anyone. BUx will not be liable for any problem or concern that you may encounter with your Banking account.
            </div>

            <div style={{marginTop: '20px'}}>
                <label class="container"> <span style={style.disclaimer}>I agree to InstaPay <b>Terms & Conditions</b></span>
                    <input type="checkbox" checked={checked} onChange={(e)=>setChecked(e.target.checked)}/>
                    <span class="checkmark" style={{backgroundColor: checked ? '#F5922F' : '#EEE'}}></span>
                </label>
            </div>
            <div style={{...style.upayText, marginTop: '20px'}}>
                UPay Support
            </div>
            <div style={{marginTop: '20px'}}>
                <div style={{display: 'flex'}}>
                    <img src={Mail} style={{marginRight: '10px'}}/>
                    <div style={style.disclaimer}>customer.service@unionbankph.com</div>
                </div>
                <div style={{display: 'flex', marginTop: '16px'}}>
                    <img src={Phone} style={{marginRight: '10px'}}/>
                    <div style={style.disclaimer}>+632-8655-6820.</div>
                </div>
            </div>
            <div style={{marginTop: '30px', display: 'flex', justifyContent: !isMobile && 'flex-end'}}>
                <Button onClick={()=>setModalVisibility(false)} style={style.cancelBtn}>Cancel</Button>
                <Button onClick={()=>proceed()} disabled={!checked} style={style.proceedBtn}>Proceed</Button>
            </div>
        </Modal>
    )
}

const style = {
    noteDiv: {
        padding: '16px',
        borderRadius: '2px',
        border: '1px solid #F4B24A',
        backgroundColor: 'rgba(244, 193, 10, 0.16)',
        display: 'flex',
        color: '#2b2d32',
        fontSize: '14px',
        fontWeight: '400',
        marginTop: '20px'
    },
    disclaimer:{
        fontSize: '16px',
        fontWeight: '400',
        color: '#2b2d32'
    },
    upayText: {
        fontSize: '16px',
        fontWeight: '700',
        color: '#2b2d32',
        opacity: '0.8'
    },
    cancelBtn:{
        width: isMobile ? '50%' : '129px',
        height: '48px',
        backgroundColor: '#FFF',
        borderRadius: '4px',
        fontWeight: '600',
        fontSize: '20px',
        color: '#F5922F',
        border: '1px solid #F5922F',
        marginRight: '10px'
    },
    proceedBtn:{
        width: isMobile ? '50%' : '129px',
        height: '48px',
        backgroundColor: '#F5922F',
        borderRadius: '4px',
        fontWeight: '600',
        fontSize: '20px',
        color: '#FFF',
        border: '1px solid #F5922F',
    }
}

export default InstaPayModalDisclaimer