import React from 'react';
import {Modal, Table, Button, Typography, Pagination} from 'antd';
import fundStyle from "../../../static/css/Funds";
import {ExportToCsv} from 'export-to-csv'
import moment from 'moment'


const PaymentModal = props => {

    const f = fundStyle()
    const [totalPayout, setTotalPayout] = React.useState(0)
    const [totalReceivable, setTotalReceivable] = React.useState(0)
    const [totalOtherFee, setOtherFee] = React.useState(0)

    const {
        segments,
        title,
        openModal,
        closeModal,
        transactions,
        businessName,
        onChangePage,
        ecID,
    } = props;

    const exportTable = () => {    
        let options = { 
          filename: 'Funds_'+moment().format("MM-DD-YYYY_HH:mm:ss"),
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true, 
          showTitle: true,
          title: 'BUx Funds',
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true
        };
          
        let csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(transactions['results'] || []);
    
      };

    function footer() {
        computeTotalAmount()
        return(
            <table>
                <tr>
                    <td style={{width: '15% !important', fontWeight: 600}}>Total Amount:</td>
                    <td style={{width: '15% !important', fontWeight: 600}}>&#8369;  {parseFloat(totalPayout).toLocaleString('en-US', {minimumFractionDigits: 2})}</td>
                    <td style={{width: '15% !important', fontWeight: 600}}>Total Payout:</td>
                    <td style={{width: '15% !important', fontWeight: 600}}>&#8369;{parseFloat(totalReceivable).toLocaleString('en-US', {minimumFractionDigits: 2})} {totalOtherFee !== 0 && ` (${ parseFloat(totalReceivable - totalOtherFee).toLocaleString('en-US', {minimumFractionDigits: 2})})`}</td>
                    <td style={{width: '15% !important', fontWeight: 600}} hidden={totalOtherFee == 0}>Total Other Fee:</td>
                    <td style={{width: '15% !important', fontWeight: 600}} hidden={totalOtherFee == 0}>&#8369;  {parseFloat(totalOtherFee).toLocaleString('en-US', {minimumFractionDigits: 2})}</td>

                </tr>
            </table>
        )
    }

    function computeTotalAmount() {
        let total_amount = 0, total_receivable = 0, total_fee = 0;
        // console.log(transactions)
        if(transactions['results'] && transactions['results'][ecID]) {
            for(let entry of transactions['results'][ecID]) {
                total_amount += parseFloat(entry.amount)
                total_receivable += entry.seller_net
                total_fee += entry.other_fee
            }
        }
        // console.log(parseFloat(total_amount))

        setTotalReceivable(total_receivable)
        setTotalPayout(total_amount)
        setOtherFee(total_fee)
    }

    const columns = [
        {
            title: "Reference Number",
            width: 150,
            render: (text, record) => {
                return (
                <div>
                    <div className={f.descSyle}><b>{record.code}</b></div>
                    <div className={f.fundText}><b>{record.description}</b></div>
                </div>)
            }
        },
        {
            title: "Date Paid",
            width: 200,
            render: (text, record) => {
                return (
                <div>
                    <div className={f.descSyle}><b>{record.timestamp}</b></div>
                </div>)
            }
        },
        {
            title: "Terminal",
            width: 200,
            render: (text, record) => {
                return (
                <div>
                    <div className={f.descSyle}><b>{record.terminal}</b></div>
                </div>)
            }
        },
        {
            title: "Amount",
            width: 150,
            render: (text, record) => {
                return (
                <div>
                    <div className={f.descSyle}><b>&#8369; {record.amount}</b></div>
                </div>)
            }
        },
        {
            title: "Receivable",
            width: 150,

            render: (text, record) => {
                return (
                <div>
                    <div className={f.descSyle}><b>&#8369; {parseFloat(record.seller_net).toFixed(2)} {record.other_fee !== 0 && ` (${parseFloat(record.seller_net - record.other_fee).toLocaleString('en-US', {minimumFractionDigits: 2})})`}</b></div>
                </div>)
            }
        },
        totalOtherFee == 0 ?
        {} :
        {
            title: 'Other Fees',
            width: 150,
            render: (text, record) => {
                return (
                <div>
                    <div className={f.descSyle}><b>&#8369; {parseFloat(record.other_fee).toFixed(2)}</b></div>
                </div>)
            }
        }
    ]

    const changePage = (page, pageSize) => {
        onChangePage(page);
    }
    console.log('ENTERPRISE_PAYOUT', props);
    console.log('ENTERPRISE_PAYOUT', transactions['results'] && transactions['results'][ecID] && transactions['results'][ecID].length ? transactions['results'][ecID[0]] : []);
    return(
        <Modal title={title}
        visible={openModal}
        width={950}
        onCancel={()=>closeModal()}
        footer={null}>
            <div>
                <div className="twoCol bottom-32">
                    <Typography style={{fontSize: 14, color: '#000'}}>Business Name: <b>{businessName}</b></Typography>
                    <button
                        className={`btn--${segments}`}
                        onClick={()=>exportTable()}>
                            <div>Download CSV</div>
                    </button>
                </div>

                <Table
                    rowClassName={(record, index) =>
                        index % 2 === 0 ? "table-row-light" : "table-row-dark"
                    }
                    dataSource={transactions['results'] && transactions['results'][ecID] && transactions['results'][ecID].length ? transactions['results'][ecID[0]] : ''}
                    columns={columns}
                    footer={() => footer()}
                    pagination={false}
                    width={'100%'}
                />

                <Pagination
                    size='small'
                    total={transactions['total'] && transactions['total']}
                    defaultPageSize={10}
                    pageSize={10}
                    defaultCurrent={1}
                    current={transactions && transactions['page_number']}
                    onChange={(page, pageSize) => changePage(page, pageSize)}
                    style={{ padding: '12px 0 8px 0', textAlign: 'right' }}
                />
            </div>
        </Modal>
    )
}

export default PaymentModal;