import React,{ useState, useEffect } from 'react';
import MetaTag from '../meta_tag/meta_tag';
import { Button, Typography } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import OTC from '../../screens/recon_enterprise/recon_enterprise';
import DebitCreditCard from './card';
import { isMobile } from 'react-device-detect';
import { history } from '../../store/history';
import dbService from '../card/cardServices';
import { useSelector } from 'react-redux';
import { segmentStroke } from '../constants/constants';

const ReconEnterprise = () => {
  const [tab, setTab] = useState(0);
  const user = localStorage.getItem('userType');
  const [selected, setSelected] = React.useState('OTC / Web / E-Wallet') 
  const ubp_enabled = localStorage.getItem('cc_enabled') == 'UnionBank';
  const xendit_enabled = localStorage.getItem('cc_enabled') == 'Xendit';
  const ccEnabled = ubp_enabled || xendit_enabled;

  const endpoints = useSelector(state => state.child_endpoints.endpoints);
  const isChild = localStorage.getItem("is_child") === "true"

  const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
  const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
  const isDell1 = window.screen.width * window.devicePixelRatio === 1366 && window.screen.height * window.devicePixelRatio === 768;
  const isMac = window.screen.width * window.devicePixelRatio === 1440 && window.screen.height * window.devicePixelRatio === 900;

  const isDellReso = (isSmallReso || isDell || isDell1)

  const segments = localStorage.getItem('segments')


  const ButtonTab = ({rootStyle}) => {

    const status = ['OTC / Web / E-Wallet', 'Credit / Debit Card'];
    
    const handleClick = (event) => {
      setSelected(event.target.value);
    }
    
    return (
      <div style={styles.btnTabContainer}>
        <ButtonGroup>
          {
            status.map((btn, i) => {
              return (
                <Button
                  key={btn}
                  value={btn}
                  onClick={handleClick}
                  style={{...styles.btnGroup, 
                    color: selected === btn? `${segmentStroke[0]}` : '#909196',
                    borderBottom: selected === btn? `4px solid ${segmentStroke[0]}` : '0' }}
                    >
                    {btn}
                </Button>
              )
            })
          }
        </ButtonGroup>
      </div>
    )
  }

  const pageRedirect = () => {
    if(isMobile){
      window.location.href = '/mobile/recon'
    }
    else if(localStorage.getItem("userType") == "ME"){
        history.push('/dashboard')
    }
    else if(localStorage.getItem("userType")== "AD"){
        history.push('/overview')
    }
    else if(localStorage.getItem("userType") == "PS"){
        history.push('/orders')
    }
    else if(localStorage.getItem("userType") == "MA"){
        history.push('/overview')
    }
  }

  // const getCCStatus = async () => {
  //   try {
  //     const res = await dbService.getCardInfo();
  //     setCCEnabled(res.cc_enabled);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  React.useEffect(() => {
    pageRedirect();
  },[])


  React.useEffect(() => {
    if (!isChild || !endpoints.length) {
      return;
    }

    // Redirect if URL was manually typed
    if(!endpoints.includes('recon')) {
      history.push(endpoints[0]);
    }
  }, [endpoints])

  // If endpoints have not been loaded yet. Hook on endpoints will handle redirect
  if (isChild && !endpoints.includes('recon')) {
    return null;
  }


  return (
    ccEnabled ? 
    <div className={isDell ? "top-12" : "top-40"} style={{marginRight: isDellReso ? '2vw' : isMac ? '5vw' : '10vw'}} >
      <MetaTag title="Reconciliation" />
      <div>
        <Typography style={styles.title}>Reconciliation</Typography>
        <ButtonTab />
      </div>
      { selected === 'OTC / Web / E-Wallet'?
          <OTC /> : <DebitCreditCard /> }
    </div>
    : <OTC />
  )
};

const styles = {
  root: {

  },
  titleContainer: {
    marginTop: '-2.5%',
    background: '#fff',
    marginLeft: '-100px',
    padding: '3% 200px 0px 150px',
    // padding: '42px 42px 0px 42px',
    // background: '#fff'
  },
  title: {
    backgroundColor: 'transparent',
    width: '100%',
    color: '#000',
    fontWeight: 'bold',
    fontSize: 28,
    display: 'flex',
    alignItems: 'center',
  },
  btnGroup: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '20px 32px 24px 32px',
    borderColor: 'transparent',
    backgroundColor: 'transparent'
  },
  btnTabContainer: {
    padding: '20px, 20px 0 20px',
    background: 'transparent'
  },
};

export default ReconEnterprise;