export function validateAmount(amount) {
    const amountString = amount.toString();
    if (amountString.startsWith('0')) {
        return Promise.reject('Leading zeros are not allowed in the transfer amount!');
    }
    
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount) || parsedAmount <= 0) {
        return Promise.reject('Please enter a valid transfer amount!');
    }

    return Promise.resolve();
}
