import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import MetaTag from '../../meta_tag/meta_tag'
import * as Style from './funds_mobile_style'
import { Icon, Button, message, Card } from 'antd'
import { history } from '../../../store/history'
import {getSinglePayouts} from '../../funds/fundsAction'
import BuxBlue from '../../../static/icons/bux_blue.svg'
import Schedule from '../../../static/icons/sched.svg'
import { isMobile } from 'react-device-detect'
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import PayoutFilterDrawer from './filterDrawer'
import PayoutDisclaimer from '../../payout_drawer/payout_disclaimer'

const MobileFunds = props =>{

    const ButtonGroup = Button.Group;
    const dispatch = useDispatch()
    const level = localStorage.getItem("Level")
    const payout_enabled= localStorage.getItem("payout_enabled");
    const singlePayouts  = useSelector(state => state.funds.single_payouts)
    const userType = localStorage.getItem("userType")


    const payoutsType = ["All", "Success", "Pending", "Failed"]
    const filtersType = ["calendar", "search"] 

    const [payoutType, setPayoutType] = React.useState("All")
    const [drawerTitle, setDrawerTitle] = React.useState("")
    const [startMoment, setStartMoment] = React.useState("")
    const [endMoment, setEndMoment] = React.useState("")
    const [startDate, setStartDate] = React.useState('')
    const [endDate, setEndDate] = React.useState('')
    const [endOpen, setEndOpen] = React.useState("")
    const [searchValue, setSearchValue] = React.useState("")
    const [isDisclaimerModalVisible, setIsDisclaimerModalVisible] = React.useState(false)
    const [summary, setSummary] = React.useState('All');
    const endpoints = useSelector(state => state.child_endpoints.endpoints);

    const [visible, setVisible] = React.useState(false);


    function assignFilterType(type) {
        if (type == 'calendar') {
            setDrawerTitle('Date Filter')
        }
    
        else if (type == 'search') {
        setDrawerTitle('Search Payout')
        }
    
        setVisible(true)
    }

    function exportTable(){
        let options = { 
          filename: "Payout"+moment().format("MM-DD-YYYY_HH:mm:ss")+'_'+"Payout",
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true, 
          showTitle: true,
          title: 'Payout Summary',
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true
        };
          
        let csvExporter = new ExportToCsv(options);
    
        if(singlePayouts != null && singlePayouts.results){
          csvExporter.generateCsv(singlePayouts.results);
        }
    };

    const onClose = () => {
        setVisible(false)
        setSearchValue("")
    }

    const onStartChange = value => {
        if(value != null && endDate != ""){
            setStartMoment(value)
            setStartDate(value.format("MM-DD-YYYY"))
            setSummary('Custom');
        }
    
        else if(value != null){
            setStartMoment(value)
            setStartDate(value.format("MM-DD-YYYY"))
            setSummary('Custom');
        }
    
        else{
            setStartMoment(null)
            setStartDate("")
        }
    }
    
    const onEndChange = value => {
        if(value != null && startDate != ""){
            setEndMoment(value)
            setEndDate(value.format("MM-DD-YYYY"))
            setSummary('Custom');
    
        }
        else{
            setEndMoment(null)
            setEndDate("")
        }
    }
    
    const handleStartOpenChange = open => {
        if (!open) {
            setEndOpen(true)
        }
    };
    
    const handleEndOpenChange = open => {
        setEndOpen(open)
    };

    const submitApplyFilter = () => {
        dispatch(getSinglePayouts(startDate, endDate, payoutType, '', summary));
        setVisible(false)
    }

    function renderSingleFields(val, status) {
        
        let status_filtered = singlePayouts.results ? singlePayouts.results : [];

        // if(payoutType != "All"){
        //     status_filtered = singlePayouts.results.filter(single_payout => single_payout.status.toLowerCase().includes(payoutType.toLowerCase()))
            
        // }else{
        //     status_filtered = singlePayouts.results;
        // }

        if(val=="" || val==null) {
            return status_filtered
        }

        let filtered_data = status_filtered.filter(single_payout => single_payout.account_name.toLowerCase().includes(val.toLowerCase()))
        
        return filtered_data
    }

    useEffect( () =>{
        let current_path = window.location.pathname
        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            history.push('/messenger?next='+current_path)
          }
        else{

          if(!isMobile){
            history.push('/messenger/error')
          }
          else if(localStorage.getItem("userType") == "AD" || localStorage.getItem("userType") == "OP" || localStorage.getItem("userType") == "KB" || localStorage.getItem("userType") == "FI" || localStorage.getItem("userType") == "BS" || localStorage.getItem("userType") == "AP"){
            history.push('/orders')
          }
          else if(localStorage.getItem("userType") == "PS"){
              history.push('/orders')
          }
          else if(localStorage.getItem("userType") == "PF"){
            history.push('/recon/7_eleven')
          }
          else if(localStorage.getItem("userType") == "MA"){
              history.push('/overview')
          }
          else if(localStorage.getItem("userType") == "SW"){
            history.push('/mobile/more')
          }
          
          else{
            dispatch(getSinglePayouts('', '', payoutType, '', 'All'))
          }   
        }
        
    }, [])

    if(userType === "SW" && !endpoints.includes('funds')) {
        history.push(endpoints[0]);
    }

    return(
        <div style={{...Style.mainMobileFunds, height: renderSingleFields(searchValue).length == 0 ? '100vh' : '100%'}}>
            <MetaTag title="Funds"/>

            <PayoutFilterDrawer title={drawerTitle} visible={visible} setVisible={setVisible} onClose={onClose} endOpen={endOpen}
            searchValue={searchValue} setSearchValue={setSearchValue}
            startMoment={startMoment} onStartChange={onStartChange} handleStartOpenChange={handleStartOpenChange}
            endMoment={endMoment} onEndChange={onEndChange} handleEndOpenChange={handleEndOpenChange} submitApplyFilter={submitApplyFilter} 
            renderSingleFields={renderSingleFields}/>

            <PayoutDisclaimer visible={isDisclaimerModalVisible} closeModal={setIsDisclaimerModalVisible} />

            <ButtonGroup
              align="center"
              style={Style.btnGrpStyle}>
                {
                payoutsType.map((item) => {
                    return <Button
                    key={item.toLocaleLowerCase()}
                    onClick={() => dispatch(getSinglePayouts('', '', item, '', 'All'))/
                        setPayoutType(item)}
                    style={{
                        ...Style.btnInGrpStyle,
                        borderTopLeftRadius: item == "All" ? '10px' : '0px',
                        borderBottomLeftRadius: item == "All" ? '10px' : '0px',
                        borderTopRightRadius: item == "Failed" ? '10px' : '0px',
                        borderBottomRightRadius: item == "Failed" ? '10px' : '0px',
                        color: payoutType === item ? '#ffffff' : '#2b2d33',
                        backgroundColor: payoutType === item ? '#0D3D76' : '#fff',
                    }}>{item}</Button>
                })
                }
            </ButtonGroup>

            <ButtonGroup align="right"
                style={Style.filterBtnStyle}>
                {
                    filtersType.map((item, key) => {
                        return(
                            <Button 
                                key={item.toLocaleLowerCase()}
                                onClick={() => assignFilterType(item)}>
                                    <Icon type={item} />
                            </Button>
                        )
                    })
                }
            </ButtonGroup>

            <div style={Style.walletDiv}>
                <div style={{display: 'flex', width: '50%'}}>
                    <img src={BuxBlue} style={{marginRight:'10px', width: 34}}/>
                    <div style={{margin: 'auto'}}>
                        <div style={Style.walletText}>BUx Wallet Balance</div>
                        <div style={Style.walletBalance}>{localStorage.getItem("balance")}</div>
                    </div>
                </div>

                <div>
                    <Button
                        hidden={userType === "CO"}
                        style={{
                            ...Style.walletBtn,
                            opacity: payout_enabled != "true"|| level == 0 ? '0.5' : '1',
                            display: localStorage.getItem("userType") == "CO" ? 'none' : 'block',
                        }}
                        onClick={ ()=> level == 0 || level == null ? message.destroy()/message.error('Complete your profile first') : setIsDisclaimerModalVisible(true)}
                        disabled={payout_enabled != "true" || level == 0}
                        >
                        <div style={Style.textStyle}>Payout</div>
                    </Button>

                    <Button
                        hidden={userType === "ME" || userType === "SW"}
                        style={{
                            ...Style.walletBtn,
                            opacity: singlePayouts.length == 0 ? '0.5' : ''
                        }}
                        disabled={singlePayouts.length == 0}
                        onClick={() => exportTable()}
                        >
                        <div style={Style.textStyle}>Export CSV</div>
                        </Button>
                </div>
            </div>

            <div style={Style.schedule} hidden={userType !== "CO"}>
                <img src={Schedule} style={Style.schedImg}/>
                <a href="/payout_schedule" style={Style.sched}>View Payout Schedule</a>
            </div>

            {
                renderSingleFields(searchValue).length != 0 && renderSingleFields(searchValue) != null ?
                    renderSingleFields(searchValue).length > 0 && renderSingleFields(searchValue).map((item, i) => {
                    return (
                        <div
                            style={Style.cardDiv}>
                        <Card 
                            style={Style.dataCard}
                            headStyle={{paddingRight: '16px', paddingLeft: '16px'}}
                            bodyStyle={{padding: '16px'}}
                            title={
                                <div>
                                    <div style={Style.bankStyle}>{item.bank}</div>
                                    <div style={Style.acctNumStyle}>{item.account_number}</div>
                                    <div style={{...Style.emptyChannelStyle, marginTop: '5px'}}>{item.account_name}</div>

                                    <div style={{...Style.twoCol, marginTop: 16}}>
                                        <div style={{...Style.bankStyle, marginTop: 8}}>
                                            ₱{item.amount.slice(3)}
                                        </div>
                                        <div 
                                            align="center"
                                            style={{
                                                ...Style.statusDiv,
                                                border: item.status.includes("Success") ? "1px solid #1DD28B" : item.status.includes("Pending") ? "1px solid #F5922F" : "1px solid #E24C4C",
                                                backgroundColor: item.status.includes("Success") ? "rgba(29, 210, 139, 0.1)" : item.status.includes("Pending") ? "rgba(245, 146, 47, 0.1)" : "rgba(226, 76, 76, 0.1)",
                                                borderLeft: item.status.includes("Success") ? "5px solid #1DD28B" : item.status.includes("Pending") ? "5px solid #F5922F" : "5px solid #E24C4C"
                                            }}>
                                                {item.status}
                                        </div>
                                    </div>
                                </div>
                            }
                        >
                            <div style={Style.twoCol}>
                                <div style={Style.bankStyle}>
                                    Date Paid
                                </div>

                                <div style={Style.bankStyle}>
                                    {item.date_paid}
                                </div>
                            </div>
                      
                            <div style={{marginTop: '10px'}} hidden={item.status.includes("Pending")}>
                                <div style={Style.expireStyle} hidden={!item.status.includes("Failed")}>
                                    Remarks: {item.remarks}
                                </div>
                                <div hidden={!item.status.includes("Success")} style={Style.twoCol}>
                                    <div style={Style.bankStyle}>
                                    Transfer ID
                                    </div>
                                    <div style={Style.emptyChannelStyle}>
                                    {item.remarks}
                                    </div>
                                </div>
                            </div>
                        </Card>
                        </div>
                    )
                    },this)
                    :
                    <div style={Style.nothingToShow} align="center">
                        Nothing to show
                    </div>
            }

        </div>
    )
}

export default MobileFunds
