import React, {useEffect} from 'react'
import { isMobile } from "react-device-detect";
import FaqWeb from './faqWeb';
import FaqMobile from './faqMobile';


const Faq = props => {

    useEffect(() => {
        window.location.href="https://bux.ph/faq";
    }, [])

    return(
        <>
        </>
    )
}

export default Faq