import React from 'react'
import AppBar from '../appBar/AppBar'
import {Card, Input, Button, Collapse, Icon, message, Typography} from 'antd'
import SentroLogo from '../../static/icons/sentro.svg'
import WooCommerceLogo from '../../static/icons/woo_commerce.svg'
import PrestaShopLogo from '../../static/icons/prestashop.svg'
import Shopify from '../../static/icons/shopify.svg'
import {history} from '../../store/history'
import axios from 'axios'
import {Handle401} from '../handle401/handle401'
import MetaTag from '../meta_tag/meta_tag'

import Arrow from '../../static/icons/arrow_down_orange.svg'
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import SupportImg from '../../static/img/support.svg';

const buxpluginwpver = process.env.REACT_APP_WP_PLUGIN_VERSION;
const buxpluginpsver = process.env.REACT_APP_PS_PLUGIN_VERSION;
const buxpluginzip = 'https://wordpress.org/plugins/bux-woocommerce/';
const buxpluginpszip = process.env.REACT_APP_STATIC_BASE_URL + "static/plugins/bux-plugin-ps-"+buxpluginpsver+".zip";

const Accordion = withStyles({
    root: {
      border: '1px solid #E6EAF0',
      borderRadius: '10px',
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
      backgroundColor: '#fff',
      borderRadius: '10px'
    },
    content: {
      margin: '18px 0',
      '&$expanded': {
        margin: '18px 0',
      },
    },
    expanded: {
    },
})(MuiAccordionSummary);
  
const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);


class MobileLinkStores extends React.Component{
    constructor(props) {
        super(props)
    
        this.state = {
          apiKey: '',
          apiSecret: '',
          clientId: '',
          wcDomain: '',
          psDomain: '',
          token: '',
          secret: '',
          key: '',
        }
      }
    
    componentDidMount(){
        let current_path = window.location.pathname
        if(localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") === null){
            history.push('/login?next='+current_path) 
        }
        else{
            if(localStorage.getItem("userType") == "AD" || localStorage.getItem("userType") == "OP"){
                history.push('/orders')
            }
            else if(localStorage.getItem("userType") == "PS"){
                history.push('/orders')
            }
            else if(localStorage.getItem("userType") == "PF"){
                history.push('/recon/7_eleven')
            }
            else if(localStorage.getItem("userType") == "MA"){
            history.push('/overview')
            }
            else if(localStorage.getItem("userType") === "ME" || localStorage.getItem("userType") === "CO"){
                this.fetchAccessToken()
            }
            else{
                history.push('/dashboard');
            }
        }
    }

    set_wc_domain = async() =>{

        let yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
          };


        let details = {
            'domain': this.state.wcDomain
          };

        try{
            let response = await axios.post(
                process.env.REACT_APP_API_URL + '/api/woocommerce/set_domain/', details, yourConfig
            );

            if(response.data.status == "success"){
                message.success('Successfully set domain');
            }else{
                message.error('Could not set domain');

            }

        }
        catch (error) {
            message.error('Could not set domain');
        }
        

    }

    fetchAccessToken = async() =>{
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try{
            let response = await axios.get(
                process.env.REACT_APP_API_URL + '/api/fetch_user_credential/', yourConfig
            );
    
            if(response.data.status == "success"){
                this.setState({
                    apiKey: response.data.api_key,
                    apiSecret: response.data.api_secret,
                    clientID: response.data.client_id,
                    wcDomain: response.data.wc_domain,
                    psDomain: response.data.ps_domain,
                    token: response.data.shopify_token && response.data.shopify_token,
                    secret: response.data.shopify_secret && response.data.shopify_secret,
                    key: response.data.shopify_key && response.data.shopify_key,
                })
                console.log(response)
            }
            else{
                return false
            }
        }
        catch (error) {
            if((error.response)&&(error.response.status == 401)){
                Handle401()
            }
        }
        
    }

    saveCreds = async() => {
        try{

            const yourConfig = {
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            }        

            const params = {'shopify_token': this.state.token,
                            'shopify_key': this.state.key,
                            'shopify_secret': this.state.secret}
            let response = await axios.post(
                process.env.REACT_APP_API_URL + '/api/settings/',
                params,
                yourConfig
            );

            if(response.data.status == "success"){
                message.success('Successfully updated settings')
            }else{
                message.error('Could not update settings')
            }


        }catch(error){
        }

    }

    copyAPIKey = () => {
        const api_key = this.api_key
        api_key.select()
        document.execCommand("copy")
        message.success('API key copied to clipboard')
    }

    copyAPISecret = () => {
        var dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.value = this.state.apiSecret;
        dummy.select();
        document.execCommand("copy")
        dummy.remove();
        message.success('Client Secret copied to clipboard')
    }

    copyClientID = () => {
        const client_id = this.client_id
        client_id.select()
        document.execCommand("copy")
        message.success('Client ID copied to clipboard')
    }

    

    headerStyle = {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '34px',
        color: '#2b2d33',
    }
    subHeaderStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000000',
    }

    cardHeadStyle = {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '29px',
        color: '#000000',
    }

    labelStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '11px',
        lineHeight: '24px',
        color: '#2b2d33',
    }

    storeCard = {
        padding: 20,
        background: '#fff',
        display:'flex',
        justifyContent: 'space-between',
        borderRadius: 4,
        marginTop: 32,
        border:'1px solid #E6EAF0'
    }

    linkStyle={
        color: 'rgb(245, 146, 47)',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
    }

    copyBtn={
        backgroundColor: 'rgb(245, 146, 47)',
         color: '#fff'
    }

    panelStyle={
        backgroundColor: "#fff",
    }

    how = {
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#54575F',
        textDecoration: 'underline'
    }

    save = {
        height: '40px',
        width: '86px',
        borderRadius: '4px',
        backgroundColor: '#F5922F',
        color: '#fff',
        border: '1px solid #F5922F',
        fontWeight: '600',
        fontSize: '16px'
    }

    beta = {
        width: '44px',
        height: '21px',
        backgroundColor: '#D1D5DD',
        borderRadius: '4px',
        color: '#000',
        fontSize: '12px',
        fontWeight: 'bold',
        textAlign: 'center',
        paddingTop: '2px',
        marginTop: '2px'
    }
    render(){
        return(
            <div style={{padding: '40px 20px 40px 20px'}}>
                <MetaTag title="Link Store"/>
                <div>Integrate your existing e-commerce stores and keep track of orders and sales in one place.</div>

                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{width: '100%', marginTop: '40px'}}>
                        <div style={{width: '100%', marginRight: '2%'}}>
                            <Card style={{borderRadius: 4}}>
                                <div style={this.cardHeadStyle}>Integration Credentials</div>
                                <div style={this.labelStyle}>Copy the credentials to the BUx Configuration page of your E-commerce store.</div>
                                <div style={{marginTop: '20px'}}><span style={this.labelStyle}>API Key</span></div>
                                <Input className="copy" size="large" value={this.state.apiKey} ref={(textarea) => this.api_key = textarea} addonAfter={<a style={{color: '#fff'}} onClick={()=>this.copyAPIKey()}>Copy</a>}/>
                                <div style={{marginTop: '20px'}}><span style={this.labelStyle}>Client ID</span></div>
                                <Input className="copy" size="large" value={this.state.clientID} ref={(textarea) => this.client_id = textarea} addonAfter={<a style={{color: '#fff'}} onClick={()=>this.copyClientID()}>Copy</a>}/>
                                <div style={{marginTop: '20px'}}><span style={this.labelStyle}>Client Secret</span></div>
                                <Input className="copy" type="password" size="large" value={this.state.apiSecret} ref={(textarea) => this.api_secret = textarea} addonAfter={<a style={{color: '#fff'}} onClick={()=>this.copyAPISecret()}>Copy</a>}/>
                            </Card>
                            <div style={{border: '1px solid #E6EAF0', backgroundColor: '#FFF', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)'}}>
                                <div style={{padding: '10px 24px 16px 24px'}}>
                                    <div style={{display: 'flex'}}>
                                        <div style={{margin: 'auto'}}>
                                            <img src={SupportImg}/>
                                        </div>
                                        <div style={{margin: '10px 0px 0px 10px'}}>
                                            <div style={{fontStyle: 'normal'}}>
                                                To provide you assistance please send us an email request to
                                                <a style={{fontSize: '16px', fontWeight: '600', color: '#F5922F', paddingLeft: 4}} href="mailto:support@bux.ph?subject=API Support">
                                                    support@bux.ph
                                                </a>
                                                .
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{padding: '16px 8px', display: 'flex', justifyContent: 'center', width: '100%'}}>
                                <Icon type="exclamation-circle" theme="filled" style={{color: '#909196', backgroundColor: '#FFF', borderRadius: 20, fontSize: 16, paddingRight: 8}} />
                                <Typography style={{fontSize: 12, color: '#54575F'}}>Not available for international users, For individual BUx users only.</Typography>
                           </div>
                        </div>
                        <div style={{width: '100%'}}>
                            <div style={this.storeCard}>
                                <div style={{marginLeft: '-10px'}}>
                                    <img src={SentroLogo} />
                                </div>

                                <div style={{margin: 'auto', marginRight: 0}}>
                                <div style={{display: 'flex'}}> 
                                <a href="https://sentro.ph" target="_blank" style={this.linkStyle}>Visit Sentro</a>
                                </div>
                                </div>
                            </div>
                            <div style={this.storeCard}>
                                <div>
                                    <img src={PrestaShopLogo} />
                                </div>

                                <div style={{margin: 'auto', marginRight: 0}}>
                                <div style={{display: 'flex'}}> 
                                <a href="/bux-plugin-ps-1.0.2.zip" target="_blank" style={this.linkStyle}>Download Link</a>
                                </div>
                                </div>
                            </div>

                            <div style={this.storeCard}>
                                <div>
                                    <img src={WooCommerceLogo} />
                                </div>

                                <div style={{margin: 'auto', marginRight: 0}}>
                                <div style={{display: 'flex'}}> 
                                <a href={buxpluginzip} target="_blank" style={this.linkStyle}>Visit Store</a>
                                </div>
                                </div>
                            </div>
                            <div hidden={false}>
                                <Accordion style={{ marginTop: '20px', borderRadius: '4px'}}>
                                    <AccordionSummary style={{height: 89, marginRight: 0}} expandIcon={<img src={Arrow} />}>
                                        <div>
                                            <div style={{display: 'flex'}}>
                                                <img src={Shopify} style={{maxHeight:'36px', marginRight: '10px'}}/>
                                                {/* <div style={{fontWeight: '900', fontSize: '20px', color: '#000', lineHeight: '24px', marginRight: '10px'}}>Shopify</div>
                                                <div style={this.beta}>Beta</div> */}
                                            </div>
                                            <div style={this.how} onClick={()=> history.push('/shopify_tutorial')}>How to Integrate your Account?</div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style={{width: '100%'}}> 
                                            <div style={{marginTop: '10px'}}><span style={this.labelStyle}>Shopify Key</span></div>
                                            <Input value={this.state.key}  onChange={(e) => this.setState({key: e.target.value})}/>
                                            <div style={{marginTop: '20px'}}><span style={this.labelStyle}>Shopify Secret</span></div>
                                            <Input value={this.state.secret} onChange={(e) =>  this.setState({secret: e.target.value})}/>
                                            <div style={{marginTop: '20px'}}><span style={this.labelStyle}>Webhook Token</span></div>
                                            <Input value={this.state.token} onChange={(e) =>  this.setState({token: e.target.value})}/>
                                            <div style={{marginTop: '20px', textAlign: 'right'}}>
                                                <Button style={this.save} onClick={()=>this.saveCreds()}>
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MobileLinkStores