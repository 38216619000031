import React, {useRef, useEffect} from 'react';
import axios from 'axios';
import enterpriseStyle from '../../../static/css/Enterprise';
import {Grid} from '@material-ui/core';
import {Input, Upload, Button, message, Icon, Card, Modal} from 'antd';
import {UploadLogo} from '../../../static/icons/index';
import UploadIDImg from '../../../static/img/upload_id.svg'
import SelfieImg from '../../../static/img/selfie.svg'
import CameraIcon from '../../../static/img/cameraImg.svg'
import SignatoryInformation from './signatoryInformation';
import * as Style from '../../complete_profile/complete_profile_style'
import {browserName, isIOS, isSafari, isMobileSafari, isFirefox } from 'react-device-detect';

const max_width = 2000;
const max_height = 2000;

const BusinessDocument = props => {

    const canvasRef = useRef(null);
    const playerRef = useRef(null);

    const es = enterpriseStyle();

    const [step, setStep] = React.useState()
    const [businessType, setBusinessType] = React.useState()
    const [blockModal, setBlockModal] = React.useState(false)
    const [cameraModal, setCameraModal] = React.useState(false)
    const [messageModal, setMessageModal] = React.useState('')
    
    //Corporate documents
    const [secRegister, setSecRegister] = React.useState("")
    const [secRegisterFile, setSecRegisterFile] = React.useState("")
    const [secRegisterFileName, setSecRegisterFileName] = React.useState("")

    const [articlesOfInc, setArticlesOfInc] = React.useState("")
    const [articlesOfIncFile, setArticlesOfIncFile] = React.useState("")
    const [articlesOfIncFileName, setArticlesOfIncFileName] = React.useState("")

    const [byLaws, setByLaws] = React.useState("")
    const [byLawsFile, setByLawsFile] = React.useState("")
    const [byLawsFileName, setByLawsFileName] = React.useState("")

    const [latestGSIS, setLatestGSIS] = React.useState("")
    const [latestGSISFile, setLatestGSISFile] = React.useState("")
    const [latestGSISFileName, setLatestGSISFileName] = React.useState("")

    const [secretaryCert, setSecretaryCert] = React.useState("")
    const [secretaryCertFile, setSecretaryCertFile] = React.useState("")
    const [secretaryCertFileName, setSecretaryCertFileName] = React.useState("")

    const [bir2303, setBir2303] = React.useState("")
    const [bir2303File, setBir2303File] = React.useState("")
    const [bir2303FileName, setBir2303FileName] = React.useState("")

    const [businessPermit, setBusinessPermit] = React.useState("")    
    const [businessPermitFile, setBusinessPermitFile] = React.useState("")
    const [businessPermitFileName, setBusinessPermitFileName] = React.useState("")    

    //Sole documents
    const [dti, setDti] = React.useState("")
    const [dtiFile, setDtiFile] = React.useState("")
    const [dtiFileName, setDtiFileName] = React.useState("")   

    const [businessPermitNo, setBusinessPermitNo] = React.useState("")    
    const [businessPermitNoFile, setBusinessPermitNoFile] = React.useState("")
    const [businessPermitNoFileName, setBusinessPermitNoFileName] = React.useState("")    

    const [birTax, setBirTax] = React.useState("")   
    const [birTaxFile, setBirTaxFile] = React.useState("")
    const [birTaxFileName, setBirTaxFileName] = React.useState("")   

    const [mayorPermit, setMayorPermit] = React.useState("")
    const [mayorPermitFile, setMayorPermitFile] = React.useState("")
    const [mayorPermitFileName, setMayorPermitFileName] = React.useState("")    
    

    //proof of billings 
    const [electricityFile, setElectricityFile] = React.useState("")
    const [electricityFileName, setElectricityFileName] = React.useState("")   

    const [internetFile, setInternetFile] = React.useState("")
    const [internetFileName, setInternetFileName] = React.useState("")   


    //business photo
    const [interiorFile, setInteriorFile] = React.useState("")
    const [interiorFileURL, setInteriorFileURL] = React.useState("")

    const [exteriorFile, setExteriorFile] = React.useState("")
    const [exteriorFileURL, setExteriorFileURL] = React.useState("")

    //personal photo
    const [idFile, setIdFile] = React.useState("")
    const [idFileURL, setIdFileURL] = React.useState("")

    const [selfieFile, setSelfieFile] = React.useState("")
    const [selfieFileURL, setSelfieFileURL] = React.useState("")

    let secRegisterUpload = React.useRef()
    let articlesOfIncUpload = React.useRef()
    let byLawsUpload = React.useRef()
    let latestGSISUpload = React.useRef()
    let secretaryCertUpload = React.useRef()
    let bir2303Upload = React.useRef()
    let businessPermitUpload = React.useRef()

    let dtiUpload = React.useRef()
    let businessPermitNoUpload = React.useRef()
    let birTaxUpload = React.useRef()
    let mayorPermitUpload = React.useRef()

    let electricityUpload = React.useRef()
    let internetUpload = React.useRef()

    let interiorUpload = React.useRef()
    let exteriorUpload = React.useRef()

    let idUpload = React.useRef()

    let docus = []

    docus = 

    props.businessType == 1?
    [
        {
            name: 'DTI Registration Number',
            value: dti,
            ref: dtiUpload,
            file_name: dtiFileName,
        },
        {
            name: 'Business Permit No.',
            value: businessPermitNo,
            ref: businessPermitNoUpload,
            file_name: businessPermitNoFileName,
        },
        {
            name: 'BIR Tax Number',
            value: birTax,
            ref: birTaxUpload,
            file_name: birTaxFileName,
        },
        {
            name: 'Mayor’s Permit Number',
            value: mayorPermit,
            ref: mayorPermitUpload,
            file_name: mayorPermitFileName,
        },
    ]:
    
    [
        {
            name: 'Sec Register',
            value: secRegister,
            ref: secRegisterUpload,
            file_name: secRegisterFileName,
        },
        {
            name: 'Articles Of Incorporation',
            value: articlesOfInc,
            ref: articlesOfIncUpload,
            file_name: articlesOfIncFileName,
        },
        {
            name: 'By-Laws',
            value: byLaws,
            ref: byLawsUpload,
            file_name: byLawsFileName,
        },
        {
            name: 'Latest GSIS',
            value: latestGSIS,
            ref: latestGSISUpload,
            file_name: latestGSISFileName,
        },
        {
            name: 'Secretary Certificate',
            value: secretaryCert,
            ref: secretaryCertUpload,
            file_name: secretaryCertFileName,
        },
        {
            name: 'BIR 2303',
            value: bir2303,
            ref: bir2303Upload,
            file_name: bir2303FileName,
        },
        {
            name: 'Business Permit Number',
            value: businessPermit,
            ref: businessPermitUpload,
            file_name: businessPermitFileName,
        },
    
]

    const proof_of_billings_docs = [
        {
            name: 'Electricity',
            ref: electricityUpload,
            file_name: electricityFileName,
        },
        {
            name: 'Internet Service Provider or Wireless Network (Wi-Fi)',
            ref: internetUpload,
            file_name: internetFileName,
        },
    ]

    const business_photo = [
        {
            name: 'Interior',
            ref: interiorUpload,
            file_url: interiorFileURL,
        },
        {
            name: 'Exterior',
            ref: exteriorUpload,
            file_url: exteriorFileURL,
        },
    ]

    const handleUploadFile = (info, type) =>{
        console.log(info, type)

        //Corporate documents
        if(type == 'Sec Register'){
            setSecRegisterFile(info)
            setSecRegisterFileName(info.name)
        }
        else if(type == 'Articles Of Incorporation'){
            setArticlesOfIncFile(info)
            setArticlesOfIncFileName(info.name)
        }
        else if(type == 'By-Laws'){
            setByLawsFile(info)
            setByLawsFileName(info.name)
        }
        else if(type == 'Latest GSIS'){
            setLatestGSISFile(info)
            setLatestGSISFileName(info.name)
        }
        else if(type == 'Secretary Certificate'){
            setSecretaryCertFile(info)
            setSecretaryCertFileName(info.name)
        }
        else if(type == 'BIR 2303'){
            setBir2303File(info)
            setBir2303FileName(info.name)
        }
        else if(type == 'Business Permit Number'){
            setBusinessPermitFile(info)
            setBusinessPermitFileName(info.name)
        }

        //sole documents

        else if(type == 'DTI Registration Number'){
            setDtiFile(info)
            setDtiFileName(info.name)
        }
        else if(type == 'Business Permit No.'){
            setBusinessPermitNoFile(info)
            setBusinessPermitNoFileName(info.name)
        }
        else if(type == 'BIR Tax Number'){
            setBirTaxFile(info)
            setBirTaxFileName(info.name)
        }
        else if(type == 'Mayor’s Permit Number'){
            setMayorPermitFile(info)
            setMayorPermitFileName(info.name)
        }

        //proof of billings docu

        else if(type == 'Electricity'){
            setElectricityFile(info)
            setElectricityFileName(info.name)
        }
        else if(type == 'Internet Service Provider or Wireless Network (Wi-Fi)'){
            setInternetFile(info)
            setInternetFileName(info.name)
        }
    
    }
    
    const handleUploadImage = (info,type) =>{
        var reader = new FileReader();
        reader.readAsArrayBuffer(info);
        
        reader.onload = function (event) {
        // blob stuff
        var blob = new Blob([event.target.result]); // create blob...
        window.URL = window.URL || window.webkitURL;

            if(type == 'Interior'){
                setInteriorFile(info)
                setInteriorFileURL(window.URL.createObjectURL(blob)) // and get it's URL
            }
            else if(type == "Exterior"){
                setExteriorFile(info)
                setExteriorFileURL(window.URL.createObjectURL(blob)) // and get it's URL
            }
            else if(type == 'ID'){
                setIdFile(info)
                setIdFileURL(window.URL.createObjectURL(blob))
            }
    
        }
    }

    function resizeMe(img) {
      
        var canvas = document.createElement('canvas');
  
        var width = img.width;
        var height = img.height;
  
        // calculate the width and height, constraining the proportions
        if (width > height) {
          if (width > max_width) {
            //height *= max_width / width;
            height = Math.round(height *= max_width / width);
            width = max_width;
          }
        } else {
          if (height > max_height) {
            //width *= max_height / height;
            width = Math.round(width *= max_height / height);
            height = max_height;
          }
        }
        
        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        
        return canvas.toDataURL("image/jpeg",0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)
        
        // you can get BLOB too by using canvas.toBlob(blob => {});
  
      }
  
      function dataURItoBlob(dataUri) {
          var byteString = atob(dataUri.split(',')[1]);
          var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
          }
          var blob = new Blob([ab], {type: mimeString});
          return blob
      }

    function handleCameraPermission(){

        playerRef.current.setAttribute('playsinline', '');
        playerRef.current.setAttribute('autoplay', '');
        playerRef.current.setAttribute('muted', '');
        playerRef.current.style.width = '100%';
        playerRef.current.style.height = '400px';

        /* Setting up the constraint */
        var facingMode = "user"; // Can be 'user' or 'environment' to access back or front camera (NEAT!)
        var constraints = {
          audio: false,
          video: {
           facingMode: facingMode
          }
        };


        if(!isIOS){
            setMessageModal('Please allow your camera access to take your selfie by enabling on your browser security settings. Remove bux.ph from list of blocked sites')
            
            if(isFirefox){
                navigator.mediaDevices.getUserMedia(constraints)
                .then(function(stream) {
                    setCameraModal(true)
                    playerRef.current.srcObject = stream;
                })
                .catch(function(err) {
                    setBlockModal(true)
                });
            }
            else{
                navigator.permissions.query({name: 'camera'})
                .then((permissionObj) => {
                    if(permissionObj.state == "denied"){
                        setBlockModal(true)
                    }
                    else{
                        navigator.getMedia = ( navigator.getUserMedia ||
                            navigator.webkitGetUserMedia ||
                            navigator.mozGetUserMedia ||
                            navigator.msGetUserMedia);

                        if (navigator.getUserMedia) {
                            navigator.getUserMedia(constraints, function success(stream) {
                            setCameraModal(true)
                            playerRef.current.srcObject = stream;
                            }, function(error){
                            
                            setBlockModal(true)
                            });
                        }
                    }
                })
                .catch((error) => {
                    setBlockModal(true)
                })
            }
        }else{
            
            if(isMobileSafari || isSafari){
                setMessageModal(`iPhone Safari Blocked Access:
                Please allow camera access to bux.ph. If you have permanently blocked camera access, please follow these steps:
                Go to Setttings >  Go to Safari > Scroll to Privacy & Security > Enable Camera & Microphone Access`)
                navigator.mediaDevices.getUserMedia(constraints).then(function success(stream) {
                    setCameraModal(true)
                   playerRef.current.srcObject = stream;
                }).catch((error) => {
                    setBlockModal(true)
                });
    
            }

            else {
                setMessageModal(`Camera access is only enabled for Safari on iOS devices. Please use Safari to use the selfie feature`)
                setBlockModal(true)

            }
            /* Stream it to video element */
  

        }
    }


    function closeCamera(){
        try{
            let stream = playerRef.current.srcObject;
            let tracks = stream.getTracks();
            tracks[0].stop()
        }catch(error){}

        setCameraModal(false)
    }

    function captureImage(dataURI){

        const context = canvasRef.current.getContext('2d');
        context.drawImage(playerRef.current , 0, 0, canvasRef.current.width, canvasRef.current.height);

        var dataURL =  canvasRef.current.toDataURL();
		// set the source of the img tag
        // setSelfieBlob(dataURL)
        handleTakePhoto(dataURL)
        // const context = this.canvasElement.getContext('2d');
        // context.drawImage(this.props.video, 0, 0, 640, 480);
    }

    function handleTakePhoto (dataUri) {    
        processfile(dataURItoBlob(dataUri), 4);
        try{
            let stream = playerRef.current.srcObject;
            let tracks = stream.getTracks();
            tracks[0].stop()
        }catch(error){}

        setCameraModal(false)
    }

    function processfile(blob, step) {
        // read the files
        var reader = new FileReader();
        reader.readAsArrayBuffer(blob);
        
        reader.onload = function (event) {
          // blob stuff
          var blob = new Blob([event.target.result]); // create blob...
          window.URL = window.URL || window.webkitURL;
          var blobURL = window.URL.createObjectURL(blob); // and get it's URL
          setSelfieFileURL(blobURL)
          // helper Image object
          var image = new Image();
          image.src = blobURL;
          image.onload = function() {
            // have to wait till it's loaded
            var resized = resizeMe(image); // resized image url
            setSelfieFile(resized)
          }
        };
    }

    function handleChangeInput(value, type){
        //Corporate documents
        if(type == 'Sec Register'){
            setSecRegister(value)
        }
        else if(type == 'Articles Of Incorporation'){
            setArticlesOfInc(value)
        }
        else if(type == 'By-Laws'){
            setByLaws(value)
        }
        else if(type == 'Latest GSIS'){
            setLatestGSIS(value)
        }
        else if(type == 'Secretary Certificate'){
            setSecretaryCert(value)
        }
        else if(type == 'BIR 2303'){
            setBir2303(value)
        }
        else if(type == 'Business Permit Number'){
            setBusinessPermit(value)
        }

        //sole documents

        else if(type == 'DTI Registration Number'){
            setDti(value)    
        }
        else if(type == 'Business Permit No.'){
            setBusinessPermitNo(value)
        }
        else if(type == 'BIR Tax Number'){
            setBirTax(value)
        }
        else if(type == 'Mayor’s Permit Number'){
            setMayorPermit(value)
        }

    }

    function handleRemoveFile(type){
        //Corporate documents
        if(type == 'Sec Register'){
            setSecRegisterFileName(null)
            setSecRegisterFile("")
        }
        else if(type == 'Articles Of Incorporation'){
            setArticlesOfIncFileName(null)
            setArticlesOfIncFile("")
        }
        else if(type == 'By-Laws'){
            setByLawsFileName(null)
            setByLawsFile("")
        }
        else if(type == 'Latest GSIS'){
            setLatestGSISFileName(null)
            setLatestGSISFile("")
        }
        else if(type == 'Secretary Certificate'){
            setSecretaryCertFileName(null)
            setSecretaryCertFile("")
        }
        else if(type == 'BIR 2303'){
            setBir2303FileName(null)
            setBir2303File("")
        }
        else if(type == 'Business Permit Number'){
            setBusinessPermitFileName(null)
            setBusinessPermitFile("")
        }

        //sole documents

        else if(type == 'DTI Registration Number'){
            setDtiFileName(null)    
            setDtiFile("")    
        }
        else if(type == 'Business Permit No.'){
            setBusinessPermitNoFileName(null)
            setBusinessPermitNoFile("")
        }
        else if(type == 'BIR Tax Number'){
            setBirTaxFileName(null)
            setBirTaxFile("")
        }
        else if(type == 'Mayor’s Permit Number'){
            setMayorPermitFileName(null)
            setMayorPermitFile("")
        }

        //proof of billing
        else if(type == 'Electricity'){
            setElectricityFile("")
            setElectricityFileName(null)
        }
        else if(type == 'Internet Service Provider or Wireless Network (Wi-Fi)'){
            setInternetFile("")
            setInternetFileName(null)
        }

    }

    function validateFields(){
        let i;
        for(i=0; i < docus.length; i++){
            if(docus[i].value == null || docus[i].value == ""){
                message.error(`Please fill up ${docus[i].name}`,5)
                window.scrollTo(0, 0)
                return
            }
            if(docus[i].file_name == null){
                message.error(`Please upload ${docus[i].name} file`,5)
                window.scrollTo(0, 0)
                return
            }
        }

        if(businessType == 0){
            for(i=0; i < proof_of_billings_docs.length; i++){
                if(proof_of_billings_docs[i].file_name == null){
                    message.error(`Please upload ${proof_of_billings_docs[i].name} proof`)
                    window.scrollTo(0, 0)
                    return
                }
            }
        }

        for(i=0; i < business_photo.length; i++){
            if(business_photo[i].file_url == null){
                message.error(`Please upload ${business_photo[i].name} photo`)
                window.scrollTo(0, 0)
                return
            }
        }

        if(idFileURL == null){
            message.error(`Please upload your valid id`)
            window.scrollTo(0, 0)
            return
        }

        if(selfieFileURL == null){
            message.error(`Please take a selfie with your id`)
            window.scrollTo(0, 0)
            return
        }

        submitStep2()
    }

    async function submitStep2(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        
        const formData = new FormData();

        // if(businessType == 1)
        // {
        //     //numbers
        //     formData.append('dti_registration_number', dti)
        //     formData.append('business_permit_number', businessPermitNo)
        //     formData.append('bir_tax_number', birTax)
        //     formData.append('mayors_permit_number', mayorPermit)
        //     //files
        //     if(dtiFile != "" && dtiFileName != ""){
        //         formData.append('dti_registration_attachment', dtiFile)
        //     }

        //     else if(businessPermitNoFile != "" && businessPermitNoFileName != ""){
        //         formData.append('business_permit_attachment', businessPermitNoFile)
        //     }

        //     else if(birTaxFile != "" && birTaxFileName != ""){
        //         formData.append('bir_tax_number_attachment', birTaxFile)
        //     }

        //     else if(mayorPermitFile != "" && mayorPermitFileName != ""){
        //         formData.append('mayors_permit_attachment', mayorPermitFile)

        //     }
        //     //proof of billing
        //     else if(electricityFile != "" && electricityFileName != ""){
        //         formData.append('electricity_attachment', electricityFile)
        //     }

        //     else if(internetFile != "" && internetFileName != ""){
        //         formData.append('internet_attachment', internetFile)
        //     }

        // }
        // else{
        //     //numbers
        //     formData.append('sec_registration_number', secRegister)
        //     formData.append('articles_of_incorporation', articlesOfInc)
        //     formData.append('bylaws', byLaws)
        //     formData.append('gis', latestGSIS)
        //     formData.append('secretary_certificate', secretaryCert)
        //     formData.append('bir_2303', bir2303)
        //     formData.append('business_permit_number', businessPermit)
        //     //files
        //     if(secRegisterFile != "" && secRegisterFileName){
        //         formData.append('sec_registration_number_attachment', secRegisterFile)
        //     }
        //     else if(articlesOfIncFile != "" && articlesOfIncFileName != ""){
        //         formData.append('articles_of_incorporation_attachment', articlesOfIncFile)
        //     }

        //     else if(byLawsFile != "" && byLawsFileName != ""){
        //         formData.append('bylaws_attachment', byLawsFile)
        //     } 

        //     else if(latestGSISFile != "" && latestGSISFileName != ""){
        //         formData.append('gis_attachment', latestGSISFile)
        //     } 

        //     else if(secretaryCertFile != "" && secretaryCertFileName != ""){
        //         formData.append('secretary_certificate_attachment', secretaryCertFile)
        //     }

        //     else if(bir2303File != "" && bir2303FileName != ""){
        //         formData.append('bir_2303_attachment', bir2303File)
        //     }

        //     else if(businessPermitFile != "" && businessPermitFileName != ""){
        //         formData.append('business_permit_attachment', businessPermitFile)

        //     }

        // }

        //   //business photo
        // if (interiorFile != "" && interiorFileURL != ""){
        // formData.append('interior_attachment', interiorFile)
        // }

        // else if(exteriorFile != "" && exteriorFileURL != ""){
        //     formData.append('exterior_attachment', exteriorFile)
        // }
        // //owner id and selfie
        // else if(idFile != "" && idFileURL){
        //     formData.append('owner_id_attachment', idFile)
        // }

        // else if(selfieFile != "" && selfieFileURL != ""){
        //     formData.append('owner_selfie_attachment', selfieFile)
        // }

        if(businessType == 1)
        {
            //numbers
            formData.append('dti_registration_number', dti)
            formData.append('business_permit_number', businessPermitNo)
            formData.append('bir_tax_number', birTax)
            formData.append('mayors_permit_number', mayorPermit)
            //files
            formData.append('dti_registration_attachment', dtiFile)
            formData.append('business_permit_attachment', businessPermitNoFile)
            formData.append('bir_tax_number_attachment', birTaxFile)
            formData.append('mayors_permit_attachment', mayorPermitFile)
            //proof of billing
            formData.append('electricity_attachment', electricityFile)
            formData.append('internet_attachment', internetFile)
            //business photo
            formData.append('interior_attachment', internetFile)
            formData.append('exterior_attachment', exteriorFile)
            //owner id and selfie
            formData.append('owner_id_attachment', idFile)
            if(selfieFile != ""){
                formData.append('owner_selfie_attachment', selfieFile)

            }
        }
        else{
            //numbers
            formData.append('sec_registration_number', secRegister)
            formData.append('articles_of_incorporation', articlesOfInc)
            formData.append('bylaws', byLaws)
            formData.append('gis', latestGSIS)
            formData.append('secretary_certificate', secretaryCert)
            formData.append('bir_2303', bir2303)
            formData.append('business_permit_number', businessPermit)
            //files
            formData.append('sec_registration_number_attachment', secRegisterFile)
            formData.append('articles_of_incorporation_attachment', articlesOfIncFile)
            formData.append('bylaws_attachment', byLawsFile)
            formData.append('gis_attachment', latestGSISFile)
            formData.append('secretary_certificate_attachment', secretaryCertFile)
            formData.append('bir_2303_attachment', bir2303File)
            formData.append('business_permit_attachment', businessPermitFile)
            //business photo
            formData.append('interior_attachment', interiorFile)
            formData.append('exterior_attachment', exteriorFile)
            //owner id and selfie
            formData.append('owner_id_attachment', idFile)

            if(selfieFile != ""){
                formData.append('owner_selfie_attachment', selfieFile)

            }
        }


        formData.append('step', props.step == 7 ? 8 : 3)

        
        try{
            let response = await axios.put(process.env.REACT_APP_API_URL + "/api/kyb/", formData,yourConfig)
            console.log(response.data)
            props.addStep()
            
        }
        catch(error){
            message.error("Unable to proceed. Please try again");
        }
    }

    async function fetchProfile(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let userId = localStorage.getItem('userId')

        let response = await axios.get(
            process.env.REACT_APP_API_URL + `/api/kyb/${userId}`,yourConfig)

        if(response.data.business_type == 1){
            //numbers
            setDti(response.data.dti_registration_number)
            setBusinessPermitNo(response.data.business_permit_number)
            setBirTax(response.data.bir_tax_number)
            setMayorPermit(response.data.mayors_permit_number)
            //files
            setDtiFileName(response.data.dti_registration_attachment)
            setBusinessPermitNoFileName(response.data.business_permit_attachment)
            setBirTaxFileName(response.data.bir_tax_number_attachment)
            setMayorPermitFileName(response.data.mayors_permit_attachment)
            //proof of billing
            setElectricityFile(response.data.electricity_attachment)
            setInternetFile(response.data.internet_attachment)

        }
        else{
            //numbers
            setSecRegister(response.data.sec_registration_number)
            setArticlesOfInc(response.data.articles_of_incorporation)
            setByLaws(response.data.bylaws)
            setLatestGSIS(response.data.gis)
            setSecretaryCert(response.data.secretary_certificate)
            setBir2303(response.data.bir_2303)
            setBusinessPermit(response.data.business_permit_number)
            //files
            setSecRegisterFileName(response.data.sec_registration_number_attachment)
            setArticlesOfIncFileName(response.data.articles_of_incorporation_attachment)
            setByLawsFileName(response.data.bylaws_attachment)
            setLatestGSISFileName(response.data.gis_attachment)
            setSecretaryCertFileName(response.data.secretary_certificate_attachment)
            setBir2303FileName(response.data.bir_2303_attachment)
            setBusinessPermitFileName(response.data.business_permit_attachment)
        }

        //business photo
        if(interiorFileURL != "" && exteriorFileURL != ""){
            setInteriorFileURL(response.data.interior_attachment)
            setExteriorFileURL(response.data.exterior_attachment)
        }

        //owner id amd selfie
        setIdFileURL(response.data.owner_id_attachment)
        setSelfieFileURL(response.data.owner_selfie_attachment)

        //business photo
        // if(interiorFileURL != "" && exteriorFileURL != ""){
        //     setInteriorFileURL(response.data.interior_attachment)
        //     setExteriorFileURL(response.data.exterior_attachment)
        // }
        
        // //owner id and selfie
        // setIdFileURL(response.data.owner_id_attachment)
        // setSelfieFileURL(response.data.owner_selfie_attachment)
    
        setBusinessType(response.data.business_type)

        console.log(response.data)
    }


    useEffect(() => {
        fetchProfile()
    }, [])

    return(
        <div>
            
            <div hidden={props.step != 2 && props.step != 7}>
            <div className={es.biMain} >
                <div className={es.fileReq}>
                    <div className={es.fileReqHeader}>
                        Document file requirements
                    </div>
                    <div className={es.fileReqText}>
                    Photo or PDF with less than <b>10 MB</b> size are accepted.
                    </div>
                </div>

                <div className={es.biHeader}>Business Document</div>

                <div>

                    {
                        docus.map((item, key) => {
                            return(

                                <Grid container style={{marginBottom: 12}}>
                                    <Grid item lg={12}>
                                            <div className={es.biPlaceholder}>{item.name}</div>
                                    </Grid>

                                    <Grid item lg={6}>
                                        <Input style={{marginBottom: '10px', width: 260, height: '48px'}}
                                            size="large" value={item.value} onChange={(e)=>handleChangeInput(e.target.value,item.name)}/>
                                    </Grid>
                                    <Grid item lg={6}>

                                    <input type="file"
                                    ref={item.ref}
                                    style={{display: 'none'}}
                                    onChange={(e)=>handleUploadFile(e.target.files[0], item.name)}
                                    accept=".jpg,.png,.jpeg,.pdf"
                                    >
                                    </input>
                                    <div style={{display: item.file_name == null ? 'block' : 'none'}}>
                                    <Button className={es.uploadBtnStyle} onClick={()=>item.ref.current.click()}><Icon type="upload"/>Click to Upload</Button>   
                                    </div>
                                    <div style={{display: item.file_name != null ? 'block' : 'none'}}>
                                        <div style={{height: '48px', border: '1px solid #E6EAF0', borderRadius:'2px', padding: '12px', display: 'flex'}}>
                                            <Icon type="file" style={{marginTop: '3px', color: '#F5922F', marginRight: '10px'}}/>
                                            <div className={es.fileName}>{item.file_name}</div>
                                            <Icon type="close" onClick={()=>handleRemoveFile(item.name)} style={{marginTop: '5px', color: 'black', float: 'right'}}/>
                                        </div>
                                    </div>
                                    </Grid>
                                </Grid>

                            )
                        })
                    }
                    
                </div>

                <div style={{display: props.businessType == 1 ? 'block' : 'none'}}>
                    <div style={{width: '100%', marginBottom: '16px'}}>
                        <div style={Style.StepStyle}>Proof of Billings</div>
                    </div>
                    {
                        proof_of_billings_docs.map((item,i)=>{
                            return(
                                <div style={{width: '100%', marginBottom: '16px', marginTop: '20px'}}>
                                    <div className={es.biPlaceholder}>{item.name}</div>
                                    <input type="file"
                                        ref={item.ref}
                                        style={{display: 'none'}}
                                        onChange={(e)=>handleUploadFile(e.target.files[0], item.name)}
                                        accept=".jpg,.png,.jpeg,.pdf"
                                        >
                                    </input>
                                    <div style={{display: item.file_name.length == 0 ? 'block' : 'none'}}>
                                        <Button className={es.uploadBtnStyle} onClick={()=>item.ref.current.click()}><Icon type="upload"/>Upload Photo or PDF</Button>   
                                    </div>
                                    <div style={{display: item.file_name.length != 0 ? 'block' : 'none'}}>
                                        <div style={{height: '48px', border: '1px solid #E6EAF0', borderRadius:'2px', padding: '12px', display: 'flex'}}>
                                            <Icon type="file" style={{marginTop: '3px', color: '#F5922F', marginRight: '10px'}}/>
                                            <div className={es.fileName}>{item.file_name}</div>
                                            <Icon type="close" onClick={()=>handleRemoveFile(item.name)} style={{marginTop: '5px', color: 'black', float: 'right'}}/>
                                        </div>
                                    </div>
                                </div>      
                            )
                        })
                    }
                </div>

                <div style={{width: '100%', marginBottom: '16px'}}>
                    <div style={Style.StepStyle}>Business Photo</div>
                </div>
                {
                    business_photo.map((item,i)=>{
                        return(
                            <div style={{width: '100%', marginBottom: '16px', marginTop: '20px'}}>
                                <div className={es.biPlaceholder}>{item.name}</div>
                                <input type="file"
                                    ref={item.ref}
                                    style={{display: 'none'}}
                                    onChange={(e)=>handleUploadImage(e.target.files[0], item.name)}
                                    accept=".jpg,.png,.jpeg,.pdf"
                                    >
                                </input>
                                <div style={{display: item.file_url.length == 0 ? 'block' : 'none'}}>
                                    <Button className={es.uploadBtnStyle} onClick={()=>item.ref.current.click()}><Icon type="upload"/>Upload Photo</Button>   
                                </div>
                                <div style={{display: item.file_url.length != 0 ? 'block' : 'none'}}>
                                    <img src={item.file_url} style={{width:'100%'}}/>
                                </div>
                            </div>      
                        )
                    })
                }
            
                
                <div style={{width: '100%', marginBottom: '16px'}}>
                        <div style={Style.StepStyle}>Personal Photo</div>
                </div>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Card 
                    bodyStyle={{
                        padding:'15px 10px 15px 10px'
                    }}
                    style={{
                        width: '49%',
                        marginRight: '2%'
                    }}>

                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>

                        {idFileURL ? <img src={idFileURL} alt="avatar" style={{ width: '100%' }}/> : <img src={UploadIDImg}/>}
                        
                        </div>

                        <input type="file"
                            ref={idUpload}
                            style={{display: 'none'}}
                            onChange={(e)=>handleUploadImage(e.target.files[0], "ID")}
                            accept=".jpg,.png,.jpeg"
                            >
                        </input>

                        <Button style={Style.takeSelfieStyle} onClick={()=>idUpload.current.click()}>{idFileURL ? 'Replace' : 'Valid ID of Owner'}</Button>
                        <div style={Style.textStyle2}>
                            You can check here the list of valid IDs.
                        </div>
                </Card>
                <Card 
                    bodyStyle={{
                        padding:'15px 10px 15px 10px'
                    }}
                    style={{
                        width: '49%',
                    }}>

                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>

                        {selfieFileURL ? <img src={selfieFileURL} alt="avatar" style={{ width: '100%' }}/> : <img src={SelfieImg}/>}
                        
                        </div>

                        <Button style={Style.takeSelfieStyle} onClick={()=> handleCameraPermission()}>Take a Selfie</Button>
                        <div style={Style.textStyle2}>
                            Your selfie will help us prove your digital identity.
                        </div>
                </Card>
                          
                </div>
            </div>
        

            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <button className={es.Back} onClick={() => props.setBack()}>Back</button>
                </div>

                <div>
                    <button className={es.submit1} onClick={() => validateFields()}>Submit</button>
                </div>
            </div>
            </div>

            <div style={{
                padding: '5px 10px 5px 10px',
                boxShadow: '0px -7px 10px rgba(0, 0, 0, 0.04)',
                position: "fixed",
                zIndex: "200",
                top: "0",
                width: '500px',
                height: '100%',
                background: '#2b2d33'
            }} align='center' hidden={!cameraModal}>
                <div align="right"><button style={Style.closeCamera} onClick={() =>closeCamera()}><Icon type="close" /></button></div>
                <video ref={playerRef} style={{ width: '100%', height: '100%'}} playsInline />
                <button style={Style.captureBtn} onClick={() => captureImage(playerRef.current)}/>
                <canvas ref={canvasRef} style={{ width: '100%', height: '325px', display: 'none'}} />
            </div>

            <Modal
                visible={blockModal}
                onCancel={()=>setBlockModal(false)}
                footer={null}
                >
                <div style={Style.modalBodyTips} align='center'>
                    <img src={CameraIcon} />
                    <div style={Style.blockedStyle}>Camera access is disabled</div>
                    <div style={Style.textStyle3}>
                    {messageModal}
                    </div>

                    <button style={Style.submitBtnStyle} onClick={() => setBlockModal(false)}> Got It </button>
                </div>
            </Modal>


            <div hidden={props.step != 3 && props.step != 8}>
                <SignatoryInformation step={props.step} addStep={props.addStep} setBack={props.setBack}/>
            </div>
        </div>
    )
}

export default BusinessDocument;