import { Button, DatePicker, Input } from "antd";
import moment from "moment";
import React from "react";

export function SearchFilterComponent({
  tempStartDate,
  tempEndDate,
  tempSearchQuery,
  handleStartDateChange,
  handleEndDateChange,
  handleSearchChange,
  handleApplySearch,
}) {
  return (
    <div
      style={{
        marginBottom: "16px",
        display: "flex",
        gap: "1em",
        alignItems: "center",
      }}
    >
      <DatePicker
        onChange={handleStartDateChange}
        placeholder="Start Date"
        value={tempStartDate}
        disabledDate={(current) => current && current > moment().endOf("day")}
      />
      <DatePicker
        onChange={handleEndDateChange}
        placeholder="End Date"
        value={tempEndDate}
        disabledDate={(current) => current && current > moment().endOf("day")}
      />
      <Input
        placeholder="Search by Account Number, Account Name."
        onChange={(e) => handleSearchChange(e.target.value)}
        value={tempSearchQuery}
        style={{ width: "400px" }}
      />
      <Button onClick={handleApplySearch} htmlType="button">
        Apply
      </Button>
    </div>
  );
}
