export function determineMode(bank, amount, rail_type, banks) {
  const lowerCaseBankName = bank.name.toLowerCase();

  const specialBanks = {
    ALB: "allbank (a thrift bank)",
    UBP: "union bank of the philippines"
  };

  if (specialBanks[rail_type] && lowerCaseBankName === specialBanks[rail_type]) {
    return rail_type === "ALB" ? "AllBank" : "UnionBank";
  }

  const { instapay_banks, pesonet_banks } = banks;

  const railTypeInstapayCheck = {
    ALB: bank.code,
    UBP: bank.code.instapay
  };

  const railTypePesonetCheck = {
    ALB: bank.code,
    UBP: bank.code.instapay
  };

  const isInstaPayBank = instapay_banks.some(instapay_bank => instapay_bank.code === railTypeInstapayCheck[rail_type])
  const isPesoNetBank = pesonet_banks.some(pesonet_bank => pesonet_bank.code === railTypePesonetCheck[rail_type])

  if (isInstaPayBank && isPesoNetBank) {
    return amount <= 50000 ? "Instapay" : "PesoNet";
  }

  if (isInstaPayBank) {
    return amount <= 50000 ? "Instapay" : "AmountLimitError";
  }

  if (isPesoNetBank) {
    return "PesoNet";
  }

  return "UnsupportedBank";
}
