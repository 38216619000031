import React from 'react'
import axios from 'axios'

import {Table, Input, Modal, Select, message} from 'antd'
import CustomInput from './customInput'
import FeeStyles from '../../style'

const {Option} = Select

const ApprovedCard = props => {

    const [showModal, setShowModal] = React.useState(false)
    const [currPartner, setCurrPartner] = React.useState(null);
    const [addPartner, setAddPartner] = React.useState('');
    const [pubKey, setPubKey] = React.useState("")
    const [secretKey, setSecretKey] = React.useState("")
    const [MID, setMID] = React.useState("")
    const [MCC, setMCC] = React.useState("")
    const [channelMin, setChannelMin] = React.useState("")
    const [channelMax, setChannelMax] = React.useState("")
    const [buxFee, setBuxFee] = React.useState("")
    const [buxPercent, setBuxPercent] = React.useState("")
    const [channelFee, setChannelFee] = React.useState("")
    const [channelPercent, setChannelPercent] = React.useState("")
    const [username, setUsername] = React.useState("")

    async function pushCreds(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = {'mid': MID, 'mcc': MCC,
                      'public_key': pubKey, 'secret_key': secretKey,
                      'username': username}

        try {

            let response = await axios.post(
            process.env.REACT_APP_API_URL + `/api/kyb/${props.uid}/partners/`,params, yourConfig)
            if(response.data.status == 'success'){
                message.success('Credentials Updated')
            }else{
                message.error(response.data.message)
            }
        }

        catch (error) {
            console.log(error)
        }

    }

    async function pushFees(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = {'bux_fee': buxFee,
                      'bux_percent': buxPercent,
                      'channel_fee': channelFee,
                      'channel_percent': channelPercent,
                      'username': username}

        try {

            let response = await axios.post(
            process.env.REACT_APP_API_URL + `/api/kyb/${props.uid}/partners/`,params, yourConfig)
            if(response.data.status == 'success'){
                message.success('Fees Updated')
            }else{
                message.error(response.data.message)
            }
        }

        catch (error) {
            console.log(error)
        }

    }
    const partners = props.partners;
    const fee = FeeStyles()
    const columns = [
            {
              title: 'Username',
              dataIndex: 'username',
            },
            {
              title: 'Remarks',
              dataIndex: 'remarks',
            },
            {
                title: 'Action',
                render: record => {
                    return <a onClick={ ()=> { setData(record); setShowModal(true);}}>View</a>
                }
            }]
  
  const setData = (partner) => {
        if(partner.user_data.secret_key){
            setSecretKey(partner.user_data.secret_key)
            setPubKey(partner.user_data.public_key)
        }
        if(partner.user_data.mid){
            setMID(partner.user_data.mid)
        }
        if(partner.user_data.mcc){
            setMCC(partner.user_data.mcc)
        }
        if(partner.fees){
            // setChannelMin(partner.fees.minimum)
            // setChannelMax(partner.fees.maximum)
            setChannelFee(partner.fees.channel_fee)
            setChannelPercent(partner.fees.channel_percent)
            setBuxFee(partner.fees.bux_fee)
            setBuxPercent(partner.fees.bux_percent)
        }
        setUsername(partner.username)
    }
  const renderData = () => {
    let filteredData = partners;  


    filteredData = partners.filter(data => data.approved==1);
    return filteredData;
  }
    
    return(
        <div style={{paddingLeft: 20, paddingRight: 20, marginTop: -1}}>
            <Modal
                visible={showModal}
                onCancel={() => setShowModal(false)}
                width={640}
                footer={null}>

                <div style={{
                    marginTop: 32,
                    fontSize: 24,
                    lineHeight: '32px',
                    fontWeight: '300',
                    width: 600
                }}>
                    <div className={fee.innerDiv} style={{ marginTop: -1}}>
                        <div className={fee.spaceBetween} style={{borderBottom: '1px solid', borderBottomColor: '#E6EAF0'}}>
                            <div>
                                <div><span className={fee.channelHeader}>Details/Credentials</span></div>
                            </div>
                        </div>
                        <div style={{ padding: 20}}>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>MID</div>
                            <Input value={MID} onChange={(e) => setMID(e.target.value)} style={{ marginTop: 10}}/>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>MCC</div>
                            <Input value={MCC} onChange={(e) => setMCC(e.target.value)} style={{ marginTop: 10}}/>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>API Public key</div>
                            <Input value={pubKey} onChange={(e) => setPubKey(e.target.value)} style={{ marginTop: 10}}/>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>API Secret Key</div>
                            <Input value={secretKey} onChange={(e) => setSecretKey(e.target.value)} style={{ marginTop: 10}}/>
                            <div align='right' style={{marginTop: 20}}>
                                <button className={fee.approveBtn} onClick={() => pushCreds()}>Save</button>
                            </div>
                        </div>
                    </div>

                    <div className={fee.innerDiv} style={{ marginTop: -1 }}>
                        <div className={fee.spaceBetween} style={{borderBottom: '1px solid', borderBottomColor: '#E6EAF0'}}>
                            <div>
                                <div><span className={fee.channelHeader}>Fees</span></div>
                            </div>
                        </div>
                        <div style={{ padding: 20}}>

                            <table style={{width: '100%'}}>
                                <tr className={fee.tableHeader}>
                                    {/*<td className={fee.tableHeaderData}>
                                        Minimum Amount
                                    </td>
                                    <td className={fee.tableHeaderData}>
                                        Maximum Amount
                                    </td>*/}
                                    <td className={fee.tableHeaderData}>
                                        BUx Fee
                                    </td>
                                    <td className={fee.tableHeaderData}>
                                        BUx %
                                    </td>
                                    <td className={fee.tableHeaderData}>
                                        Xendit Fee
                                    </td>
                                    <td className={fee.tableHeaderData}>
                                        Xendit %
                                    </td>
                                </tr>
                                <tr>
                                    {/*<td className={fee.tableChannelText}>
                                        <CustomInput name={"minimum"} value={channelMin} placeholder={"Min Amount"} handleChangeVal={(e)=>setChannelMin(e.target.value)}/>
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <CustomInput name={"maximum"} value={channelMax} placeholder={"Max Amount"} handleChangeVal={(e)=>setChannelMax(e.target.value)}/>
                                    </td>*/}
                                    <td className={fee.tableChannelText}>
                                        <CustomInput name={"buxFee"} value={buxFee} placeholder={"BUx Fee"} handleChangeVal={(e)=>setBuxFee(e.target.value)}/>
                                    </td>
                                    <td className={fee.tableChannelText} align="center">
                                        <CustomInput value={buxPercent} name={"buxPercent"} placeholder={"BUx Percent"} handleChangeVal={(e)=>setBuxPercent(e.target.value)}/>
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <CustomInput value={channelFee} name={"channelFee"} placeholder={"Xendit Fee"} handleChangeVal={(e)=>setChannelFee(e.target.value)}/>
                                    </td>
                                    <td className={fee.tableChannelText} align="center">
                                        <CustomInput value={channelPercent} name={"channelPercent"} placeholder={"Xendit Percent"} handleChangeVal={(e)=>setChannelPercent(e.target.value)}/>
                                    </td>
                                </tr>
                            </table>
                            <div align='right' style={{marginTop: 20}}>
                                <button className={fee.approveBtn} onClick={() => pushFees()}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className={fee.innerDiv}>
                <div className={fee.spaceBetween} style={{padding: 20, borderBottom: '1px solid', borderBottomColor: '#E6EAF0'}}>
                    <div>
                        <div><span className={fee.channelHeader}>Approved</span></div>
                    </div>


                </div>


                <div style={{ padding: 10}}>

                    <Table
                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                    dataSource={renderData()}
                    pagination={false}
                    columns={columns} />
                </div>
            </div>
        </div>
    )
}

export default ApprovedCard