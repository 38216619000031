import React from 'react';
import { isMobile } from 'react-device-detect';
import MobileMain from '../../layouts/Mobile/MobileMain'
import Main from '../../layouts/Main'
import GcashAnnouncement from './list/gcash';

const Annoucement = props => {

    return(
        <div>
            {
                isMobile ?
                <MobileMain title={"Notifications"}>
                    <GcashAnnouncement/>
                </MobileMain>
                :
                <Main>
                    <GcashAnnouncement />
                </Main>
            }

        </div>
    )
}

export default Annoucement;