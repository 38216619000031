import {
    GET_ORDERS_BEGIN,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_ERROR,
    SET_BATCH_UPLOADED,
    SET_SELECTED_BTN,
    DEL_FILE_UPLOADED_ROW,
    SET_BATCH_GENERATED
} from './orderConstants';
import { USER_TYPE } from '../constants/constants';
import axios from "axios";
import {Handle401} from '../handle401/handle401'
import {history} from '../../store/history';

export const getOrdersBegin = () => {
    return {type: GET_ORDERS_BEGIN};
}

export const getOrdersSuccess = (orders) => {
    return {type: GET_ORDERS_SUCCESS, payload: orders};
}

export const getOrdersError = (errors) => {
    return {type: GET_ORDERS_ERROR, payload: errors};
}

const handleError = (error) => {
    if((error.response)&&(error.response.status == 401)){
        Handle401()
    }
}
export const getOrders = (token, table_type, page, page_size="10", start='', end='', testMode, summary='Today', search="", payment_methods="", user_type=`${USER_TYPE[0]}`, is_export="", card_type="All", credit=false) => {
    let url = ''
    {
        testMode && credit ?
        url = `/api/sandbox/orders/credit_cards/?card_type=${card_type}&type=` :
        testMode ?
        url = '/api/sandbox/orders/?type=' :
        credit ? 
        url = `/api/orders/credit_cards/?card_type=${card_type}&type=` :
        url = '/api/orders/?type='
    }
    return (dispatch) => {
        dispatch(getOrdersBegin());
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
         }
        axios.get(process.env.REACT_APP_API_URL + url+table_type+'&page='+page+'&page_size='+page_size+'&start='+start+'&end='+end+'&summary='+summary+'&search='+search+'&payment_methods='+encodeURI(payment_methods)+'&user_type='+user_type+'&is_export='+is_export+'&token='+token, yourConfig)
        .then(
            response => dispatch(getOrdersSuccess(response.data)))
        .catch(error => handleError(error));
        if((history.location.pathname.indexOf('/test') > -1&&localStorage.getItem("userType") !== "CO")){
            history.push('/dashboard');
        }
    }
}

export const setBatchUploaded = (data) => {
    return {type: SET_BATCH_UPLOADED, payload: data};
}

export const setSelectedBtn = (btn) => {
    return {type: SET_SELECTED_BTN, payload: btn};
}

export const delUpFileRow = (row) => {
    return {type: DEL_FILE_UPLOADED_ROW, payload: row};
}

export const setBatchGenerated = (data) => {
    return {type: SET_BATCH_GENERATED, payload: data};
}