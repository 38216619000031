import { useEffect, useState } from "react";
import { axios } from "../../../../../../lib/axios";

export function getRestrictedPayoutBanks() {
  return axios.get(`api/restricted/payout/banks`);
}

export function useRestrictedPayoutBanks() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getRestrictedPayoutBanks();
        setData(response);
        setIsLoading(false);
      } catch (error) {
        setIsError(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, isLoading, isError };
}