import { Button, Icon, Input, Modal, Typography, notification } from "antd";
import React, { useState } from "react";
import { exportRevenues } from "../api/exportRevenues";

export function ExportRevenues() {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const openNotification = (status, message) => {
    let header = status.charAt(0).toUpperCase() + status.slice(1);
    notification.info({
      message: header,
      description: message,
      placement: "topRight",
    });
  };

  const handleOk = async () => {
    setIsLoading(true);
    try {
      const response = await exportRevenues(email);
      const { status, message } = await response;
      openNotification(status, message);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
      setOpen(false);
      setEmail("");
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  return (
    <>
      <Button
        onClick={showModal}
        style={{
          height: "40px",
          backgroundColor: "rgb(0, 81, 176)",
          color: "#fff",
          fontWeight: "bold",
        }}
      >
        <Icon type="download" />
        Export CSV
      </Button>
      <Modal
        title="Export CSV"
        visible={open}
        onOk={handleOk}
        onCancel={handleCancel}
        width={360}
        centered
        confirmLoading={isLoading}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
          <Typography>
            Please enter your email address to send CSV reports directly to your
            email.
          </Typography>
          <div>
            <label style={{ fontSize: 12 }}>Send to email</label>
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="large"
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
