import { makeStyles } from '@material-ui/core';
import { isMobile } from 'react-device-detect';


const level = localStorage.getItem("Level")
const step = localStorage.getItem("Step")

const cardsStyles = makeStyles(theme => ({

    // MOBILE

    basicCard: {
        background: '#fff',
        alignItems: 'left !important',
        fontFamily: 'Inter',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        width: '100%',
        height: '100%',
        padding: isMobile? '16px 16px 10px 16px' : 16,
        color: '#000000',
        marginBottom: '16px',
        marginTop: '16px',
        position: 'relative'
     },

    basicHeader: {
        marginTop: isMobile? 0 : '16px',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: isMobile? '16px' : '24px',
    },

    basicText: {
        marginTop: '10px',
        fontSize: isMobile? 12 : '16px',
        height: isMobile? '100%' : 82
    },

    cardIcon: {
        background:'#fff',
        position: 'relative',
        marginTop: '-60px',
        // paddingTop: '12px',
        marginLeft :'47%',
        width: '57px',
        height: '57px',
        borderRadius: '50%',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '30%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    button: {
        // marginTop: '10px',
        fontWeight: '600',
        fontSize: '20px',
        color: '#f5922f',
    },


    //WEB

    dashboardStyle: {
        
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '10px',
        

      },

    basicWebCard: {
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        background: '#fff',
        width: '100%',
        height: '150px',
        color: '#000000',
        

    },

    webCard:{ 

    },

    webCardText: {
        padding: '12px 16px',
    },

    wcHeader:{ 
        color: '#000000',
        fontWeight: 'bold',
        fontSize: '20px',
        // lineHeight: '32px',
    },

    wcText: {
        // marginTop: '16px',
        fontSize: '16px',
    },

    wcAlert: {
        marginLeft: '8px',
        // paddingBottom: '8px',
        height: '30px',
        width: '120px',
        borderRadius: '4px',
        fontSize: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
       
        
    },

    wcLink: {
        fontWeight: '600',
        fontSize: '17px',
        color: '#F5922F',
    },

    ubpWebCard: {
        marginTop: '16px',
        width: '600px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        background: '#fff',
        height: '100%',
        color: '#000000',
        paddingBottom: '18px',
        [theme.breakpoints.down("sm")]: {
            width: '100%',


        },
        
    },

    ubpMobileCard: {
        marginTop: '16px',
        width: '100%',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        background: '#fff',
        height: '100%',
        color: '#000000',
        paddingBottom: '18px',
    },


    ubpHeader:{ 
        color: '#000000',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '32px',
    },

    ubpText: {
        fontSize: '16px',
        paddingBottom: '16px',
    },

    circleIcon: {
        height: 60, 
        width: 60, 
        backgroundColor: '#F5922F', 
        borderRadius: '50%', 
        display: 'flex',
        position: 'absolute', 
        transform: 'translate(-50%, -50%)',
        top: '47%',
        left: '59%',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontWeight: '600',
        fontSize: 17
    },
    completeText: {
        color: '#F5922F',
        fontSize: 12,
        fontWeight: '800',
        textAlign: 'center',
        padding: '0 0 0 24px',
        marginTop: '-8px'
    }

}));

export default cardsStyles;