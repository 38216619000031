import React, {
    useEffect,
    useState
} from 'react';
import axios from 'axios';
import { message, Spin } from 'antd';
import crypto from 'crypto';
import { history } from '../../../store/history'
import * as Icons from '../../../static/icons/bux_qr/index'

//Components
import DisclaimerModal from './disclaimerModal'
import InstaPayModalDisclaimer from '../../checkout/NewCheckout/instapayDisclaimerModal';

//QR Components
import QRBusinessName from './qr_details/qr_business_name';
import QRAmount from './qr_details/qr_amount';
import QRCustomerDetails from './qr_details/qr_customer_details';
import TestModeBanner from '../../test_mode/components/testModeBanner';

//
import qrServices from './qr_services/qrServices';
import promotionServices from '../../AdminComponents/promotion/promotionServices';
import QRSummary from './qr_details/qr_summary';
import QRFooter from './qr_details/qr_footer';

const testMode = (history.location.pathname.indexOf('/test') > -1);

const QRDynamicDetails = props => {

    const {
        qrStaticDetails,
        tradeName,
    } = props;

    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

    const withDisclaimerChannels = ["MBTC", "BPIA", "UBPB", "RCBC", "gcash", "grabpay", "visamc", "ubpcs", "billease", "billease_sn", "paygate", "bdo_instapay", "ps_instapay", "sb_instapay", "instapay"];
    const noDisclaimerChannels = ['instapay', 'paygate', 'instapay_allbank', 'bdo_instapay', 'ps_instapay', 'sb_instapay'];

    const surcharges = {
        "UBPB": {
            "value": "10.00",
            "label": "UBP",
        },
        "BPIA": {
            "value": "15.00",
            "label": "BPI",
        },
        "RCBC": {
            "value": "5.00",
            "label": "RCBC",
        },
        "MBTC": {
            "value": "5.00",
            "label": "Metrobank",
        },
    };

    //state data
    const [amount, setAmount] = useState(parseFloat(0).toFixed(2));
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [paymentChannels, setPaymentChannels] = useState({});
    const [paymentType, setPaymentType] = useState({
        code: "",
        name: "",
    });
    const [paymentChannel, setPaymentChannel] = useState({
        key: "",
        code: "",
        name: "",
        is_valid: false,
    });
    const [paymentTypeStringified, setPaymentTypeStringified] = useState("");
    const [paymentChannelStringified, setPaymentChannelStringified] = useState("");
    const [fees, setFees] = useState({});
    const [isInvalidReasons, setIsInvalidReasons] = useState({});
    const [sur, setSur] = React.useState({
        value: "",
        label: "",
    });
    const [leaveNote, setLeaveNote] = useState('');

    const [custName, setCustName] = useState('');
    const [custEmail, setCustEmail] = useState('');
    const [custMobile, setCustMobile] = useState('');
    const [additional, setAdditional] = useState('');
    const [additional2, setAdditional2] = useState('');

    // require addistional
    const [requireAdd1, setRequireAdd1] = useState(false);
    const [requireAdd2, setRequireAdd2] = useState(false);
    const [hasClicked, setHasClicked] = useState(false);

    //state boolean
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [instapayDisclaimerModal, setInstapayDisclaimerModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [feeLoading, setFeeLoading] = useState(false);

    // promo code
    const [promoCode, setPromoCode] = React.useState('');
    const [promoCodeStatus, setPromoCodeStatus] = React.useState([]);
    const [promoAmount, setPromoAmount] = React.useState(0);
    const [promoType, setPromoType] = React.useState('Fixed');
    const [promoMinAmount, setPromoMinAmount] = React.useState(50);
    const [promoMaxAmount, setPromoMaxAmount] = React.useState(20);

    //functions 

    const errorMsg = (text) => {
        message.destroy();
        message.error(text, 3);
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        setLoading(false);
    }

    const headerDetails = (text) => {
        return (
            <div className="qr-header-details">
                {text}
            </div>
        );
    }

    const customPlaceholder = (text) => {
        return (
            <div className="qr-placeholder">
                {text}
            </div>
        );
    }

    const selectedAmount = (amount) => {
        setAmount(amount);
        getChannels(amount);
    }

    const numberWithCommax = (text) => {
        return text.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const settingAmount = (amount) => {
        setAmount(amount);
        validatePromoCode(promoCode, qrStaticDetails && qrStaticDetails.segment, '', amount);
    }

    const handlePaymentChange = (paymentTypeStringified) => {
        setPaymentType(JSON.parse(paymentTypeStringified));
        setPaymentTypeStringified(paymentTypeStringified);
        setPaymentChannel({
            key: "",
            code: "",
            name: "",
            is_valid: false,
        });
        setPaymentChannelStringified("");
        validatePromoCode(promoCode, qrStaticDetails && qrStaticDetails.segment, '', amount);
    }

    const handleChannelChange = (paymentChannelStringified) => {
        const newPaymentChannel = JSON.parse(paymentChannelStringified);
        setPaymentChannel(newPaymentChannel);
        setPaymentChannelStringified(paymentChannelStringified);
        if (['BPIA', 'RCBC', 'UBPB', 'MBTC'].includes(newPaymentChannel.code)) {
            setSur(surcharges[newPaymentChannel.code]);
        }
        validatePromoCode(promoCode, qrStaticDetails && qrStaticDetails.segment, newPaymentChannel.code, amount);
    }

    const onPromoCodeChange = (e) => {
        if (e.target.value.length === 0) {
            setPromoCode('');
            setPromoCodeStatus([]);
            setPromoAmount(0);
        } else {
            setPromoCode((e.target.value).toUpperCase());
        }
    }

    const onApplyPromo = (code) => {
        let promo_code = promoCode;
        if (code && (typeof code !== 'object')) {
            setPromoCode((code).toUpperCase());
            promo_code = code;
        }
        validatePromoCode(promo_code, qrStaticDetails && qrStaticDetails.segment, paymentChannel.code, amount);
    }

    //async functions

    const checkoutQr = async (params) => {
        try {
            let response;
            if (params.channel === "instapay") {
                response = await qrServices.checkoutQRV2(testMode, params);
            } else {
                response = await qrServices.checkoutQR(testMode, params);
            }

            if (response.data.status == "success") {
                if (withDisclaimerChannels.includes(paymentChannel.code) && !showDisclaimer) {
                    setLoading(false);
                }
                if (response.data.banking_url) {
                    window.location.href = response.data.banking_url;
                } else {
                    if (testMode) {
                        window.location.href = `/test/payment/${response.data.uid}`;
                    } else {
                        window.location.href = `/payment/${response.data.uid}`;
                    }
                }
                setLoading(false);
            } else {
                errorMsg(response.data.message);
                setLoading(false);
                setInstapayDisclaimerModal(false);
            }
        } catch (error) {
            errorMsg(error);
        }
    }

    const gettingParams = async () => {
        let params = {};
        let pmethod = paymentChannel.code;

        setLoading(true);
        setShowDisclaimer(false);
        setHasClicked(true);

        const maxAmount = process.env.REACT_APP_MAXIMUM_AMOUNT ? process.env.REACT_APP_MAXIMUM_AMOUNT : 30000;
        const minAmount = process.env.REACT_APP_MINIMUM_AMOUNT ? process.env.REACT_APP_MINIMUM_AMOUNT : 50;

        if (!amount) {
            errorMsg("Amount is required", 3);
        } else if ((qrStaticDetails && qrStaticDetails.user_role === "ME") && (parseFloat(amount) < minAmount || parseFloat(amount) > maxAmount)) {
            errorMsg('Amount must be ₱ 50 up to ₱ 30, 000');
        } else if (qrStaticDetails && qrStaticDetails.user_role == "CO" && parseFloat(amount) < minAmount) {
            errorMsg('Amount must be at least ₱ 50');
        } else if (!custName || !custEmail || !custMobile) {
            errorMsg("Please complete Customer Information", 3);
        } else if (requireAdd1 || requireAdd2) {
            errorMsg("Please complete Additional Details", 3);
        } else if (custMobile.length < 10) {
            errorMsg("Invalid Mobile Number", 3);
        } else if (!pmethod) {
            errorMsg("Please Select a Channel");
        } else if (pmethod == 'bank') {
            errorMsg("Please Select a Bank");
        } else if(emailError(custEmail)) {
            errorMsg("Invalid Email");
        } else {
            params = {
                trade_name: tradeName,
                amount: parseFloat(amount).toFixed(2),
                name: custName,
                phone: custMobile,
                email: custEmail,
                channel: pmethod,
                note: !leaveNote ? custMobile : leaveNote,
                additional_field_1: {
                    name: qrStaticDetails.field_1,
                    value: additional,
                },
                additional_field_2: {
                    name: qrStaticDetails.field_2,
                    value: additional2,
                },
                test_mode: testMode,
                promo_code: promoCode
            };
            checkoutQr(params);
        }
    }

    const emailError = (email) => {
        const emailFormat = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!email || !email.length) { 
            return false;
        }
        if (emailFormat.test(email)){
            return (false);
        } else {
            return (true);
        }
    }
    
    const validatePromoCode = async (code, segment, channel, amount) => {
        if (!code) {
            return false;
        }

        const payment_link_amount = fees && fees[paymentChannel.code] ? parseFloat(fees[paymentChannel.code]) + parseFloat(amount) : amount;

        try {
            const res = await promotionServices.validatePromoCode(code, segment, channel, payment_link_amount);
            if (res.status === 'success') {
                setPromoAmount(res.discount_amount);
                setPromoType(res.discount_type);
                setPromoCodeStatus({
                    status: 'valid',
                    message: res.message
                });
                setPromoMinAmount(res.min_amount);
                if (res && res.max_amount) {
                    setPromoMaxAmount(res.max_amount);
                }
            } else if (res.status === 'error') {
                setPromoAmount(0);
                setPromoType('');
                setPromoCodeStatus({
                    status: 'invalid',
                    message: res.message
                });
                setPromoMinAmount(50);
                setPromoMaxAmount(20);
            }
        } catch (error) {
            setPromoAmount(0);
            setPromoType('');
            setPromoCodeStatus({
                status: 'invalid',
                message: 'Error please try again'
            });
            setPromoMinAmount(50);
            setPromoMaxAmount(20);
        }
    }

    const getChannels = async (amount) => {
        let generatedRequestToken = crypto.randomBytes(16).toString('hex');
        let params = {
            trade_name: tradeName,
            amount: amount,
            qr_details: {
                user_role: qrStaticDetails.user_role,
                cc_enabled: qrStaticDetails.cc_enabled,
                has_cashbux_privilege: qrStaticDetails.has_cashbux_privilege,
            },
            token: generatedRequestToken,
        }

        setPaymentTypes([]);
        setPaymentChannels({});
        setFeeLoading(true);
        try {
            let url = testMode ? REACT_APP_API_URL + `/api/sandbox/get_channels_my_checkout/` : REACT_APP_API_URL + `/api/get_channels_my_checkout/`;
            let response = await axios.post(url, params);

            if (generatedRequestToken == response.data.token) {
                setPaymentTypes(response.data.channel_types);
                setPaymentChannels(response.data.channels);
                setFees(response.data.fees);
                setIsInvalidReasons(response.data.is_invalid_reasons);
            } else {
                errorMsg(response.data.status);
            }
        } catch (error) {
            errorMsg("Failed to fetch channels");
        }
        setFeeLoading(false);
    }

    useEffect(() => {
        if((!additional || additional === "") && qrStaticDetails && qrStaticDetails.field_1_required && qrStaticDetails.show_field_1) {
            setRequireAdd1(true);
        } else {
            setRequireAdd1(false);
        }
        if((!additional2 || additional2 === "") && qrStaticDetails && qrStaticDetails.field_2_required && qrStaticDetails.show_field_2) {
            setRequireAdd2(true);
        } else {
            setRequireAdd2(false);
        }
    },[additional, additional2]);

    useEffect(() => {
        if (qrStaticDetails) {
            getChannels(amount);
        }
    }, [qrStaticDetails]);

    useEffect(() => {
        if (paymentChannel.code && isInvalidReasons[paymentChannel.code]) {
            setPaymentChannel({
                key: "",
                code: "",
                name: "",
                is_valid: false,
            });
            setPaymentChannelStringified("");
        }
    }, [isInvalidReasons]);

    return (
        <>
            <DisclaimerModal hasDisclaimer={withDisclaimerChannels} showModal={showDisclaimer} hideModal={setShowDisclaimer} channel={paymentChannel.code} proceed={gettingParams} />
            <InstaPayModalDisclaimer modalVisibility={instapayDisclaimerModal} setModalVisibility={setInstapayDisclaimerModal} channel={paymentChannel.code} proceed={gettingParams} />

            <div className="qr-details-div padding-bot-32">
                <div className="qr-header-div">
                    <img src={Icons.BUx} alt="BUx" />
                </div>

                {testMode && <TestModeBanner width={'100%'} type="QR" />}

                <div className="qr-info">

                    <QRBusinessName qrStaticDetails={qrStaticDetails} profile={Icons.Profile} />
                    <QRAmount qrStaticDetails={qrStaticDetails} getChannels={getChannels} amount={amount} settingAmount={settingAmount}
                        customPlaceholder={customPlaceholder} selectedAmount={selectedAmount}
                        numberWithCommax={numberWithCommax} />
                    <QRCustomerDetails
                        qrStaticDetails={qrStaticDetails}
                        headerDetails={headerDetails} customPlaceholder={customPlaceholder}
                        custName={custName} setCustName={setCustName}
                        custEmail={custEmail} setCustEmail={setCustEmail}
                        custMobile={custMobile} setCustMobile={setCustMobile}
                        additional={additional} setAdditional={(text) => {setAdditional(text); setHasClicked(true)}}
                        additional2={additional2} setAdditional2={(text) => {setAdditional2(text); setHasClicked(true)}}
                        paymentChannels={paymentChannels} paymentTypes={paymentTypes} isInvalidReasons={isInvalidReasons}
                        paymentType={paymentType} handlePaymentChange={handlePaymentChange}
                        paymentChannel={paymentChannel} paymentTypeStringified={paymentTypeStringified} paymentChannelStringified={paymentChannelStringified}
                        handleChannelChange={handleChannelChange} feeLoading={feeLoading}
                        leaveNote={leaveNote} setLeaveNote={setLeaveNote} Icons={Icons}
                        requireAdd1={requireAdd1} requireAdd2={requireAdd2} hasClicked={hasClicked} />

                    <QRSummary headerDetails={headerDetails} paymentType={paymentType}
                        paymentChannel={paymentChannel} sur={sur}
                        amount={amount} numberWithCommax={numberWithCommax}
                        qrStaticDetails={qrStaticDetails} fees={fees} promoAmount={promoAmount}
                        onPromoCodeChange={onPromoCodeChange}
                        onApplyPromo={onApplyPromo} promoCodeStatus={promoCodeStatus}
                        promoCode={promoCode} testMode={testMode} promoType={promoType}
                        promoMinAmount={promoMinAmount} promoMaxAmount={promoMaxAmount} />


                    <button className="submit-btn-bux top-20"
                        onClick={() => {
                            if (noDisclaimerChannels.includes(paymentChannel.code)) {
                                gettingParams()
                            } else if (withDisclaimerChannels.includes(paymentChannel.code)) {
                                setShowDisclaimer(true)
                            } else {
                                gettingParams()
                            }
                        }}
                        disabled={loading}
                        style={{ opacity: loading ? 0.5 : '', cursor: loading ? 'not-allowed' : '' }}>
                        <div hidden={!loading}>
                            <Spin />
                        </div>
                        <div hidden={loading}>
                            Pay Now
                        </div>
                    </button>


                    <div className="nameStyle top-20">
                        By clicking you agree to our <a a href="/terms_and_privacy" target="_blank" style={{ color: '#f5922f', textDecoration: 'underline' }}>Terms & Conditions</a> and <a target="_blank" href="/terms_and_privacy" style={{ color: '#f5922f', textDecoration: 'underline' }}>Privacy Policy</a>,
                        entailing that BUx is not responsible for nor does it ensure the delivery,
                        performance or fulfillment of the goods or service that you are paying for.
                        BUx only ensures that your payment is processed seamlessly and safely.
                    </div>
                </div>

                <QRFooter qrStaticDetails={qrStaticDetails} tradeName={tradeName} />

            </div>
        </>
    )
}

export default QRDynamicDetails; 
