import axios from 'axios';
import {Handle401} from '../../handle401/handle401';

const authorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const endorsementServices = {
  getUsers: async (search, page, accountType, start, end, summary) => {
    try {
      const res = await authorizedAxiosRequest().get(`api/admin/users/?page=${page}&page_size=10&search=${search}&start=${start}&end=${end}&mode=All&segment=${accountType}&summary=${summary}&level=5`)
      return res.data;
    } catch (error) {
      console.log(error)
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getEndorsements: async (search, type, start, end) => {
    try {
      const res = await authorizedAxiosRequest().get(`/api/user_endorsements/?search=${search}&type=${type}&user_type=&start=${start}&end=${end}`)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  endorse: async (params) => {
    try {
      const res = await authorizedAxiosRequest().post(`/api/user_endorsements/`, params)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  updateEndorsementStatus: async (status) => {
    try {
      const res = await authorizedAxiosRequest().patch(`/api/user_endorsements/`, status)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
}

export default endorsementServices;