import { useState } from "react";
import { axios } from "../../../lib/axios";

export default function MerchantIDsV2ViewModel() {
    const [isSuccess, setIsSuccess] = useState(false);
    const [messageString, setMessageString] = useState("");
    const [displayMessage, setDisplayMessage] = useState(false);
    const [allChannelSubmerchantIdSettings, setAllChannelSubmerchantIdSettings] = useState([]);
    const [channelSubmerchantIds, setChannelSubmerchantIds] = useState()
    const [userSelections, setUserSelections] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingUserSelections, setIsLoadingUserSelections] = useState(false);

    const headers = {
        Authorization: "Token " + localStorage.getItem("jwtToken"),
    }
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

    async function getAllChannelSubmerchantIDSettings() {
        setIsLoading(true);
        try {
            const response = await axios.get(
                `${REACT_APP_API_URL}/api/admin/channel_submerchant_id/settings/`,
                {
                    headers: headers,
                },
            );
            setAllChannelSubmerchantIdSettings(response.data);
            setIsSuccess(true);
        } catch (error) {
            setMessageString("Failed to get all channel submerchant ID settings");
            setIsSuccess(false);
            setDisplayMessage(true);
        }
        setIsLoading(false);
    }

    async function getChannelSubmerchantIDs(searchString, page, pageSize) {
        setIsLoading(true);
        try {
            const response = await axios.get(
                `${REACT_APP_API_URL}/api/admin/channel_submerchant_id/?page=${page}&page_size=${pageSize}&search=${searchString}`,
                {
                    headers: headers,
                },
            );
            setChannelSubmerchantIds(response);
            setIsSuccess(true);
        } catch (error) {
            setMessageString("Failed to get channel submerchant IDs");
            setIsSuccess(false);
            setDisplayMessage(true);
        }
        setIsLoading(false);
    }


    async function createOrUpdateChannelSubmerchantIDs(params, isUpdate = false) {
        setIsLoading(true);
        try {
            params.channel_submerchant_ids = params.channel_submerchant_ids.map((channelSubmerchantID) => {
                let metadata = null;
                if (channelSubmerchantID.metadata) {
                    metadata = JSON.parse(channelSubmerchantID.metadata);
                }
                
                return {
                    ...channelSubmerchantID,
                    metadata: metadata,
                }
            });

            await axios.post(
                `${REACT_APP_API_URL}/api/admin/channel_submerchant_id/`, 
                params, 
                {
                    headers: headers,
                },
            );
            setIsSuccess(true);
            
            if (isUpdate) {
                setMessageString("Channel submerchant ID/s successfully updated");
            } else {
                setMessageString("Channel submerchant ID/s successfully created");
            }
        } catch (error) {
            setIsSuccess(false);

            if (isUpdate) {
                setMessageString("Failed to update channel submerchant ID/s");
            } else {
                setMessageString("Failed to create channel submerchant ID/s");
            }
        }
        setDisplayMessage(true);
        setIsLoading(false);
    }

    async function getUserSelections(searchString) {        
        if (searchString == "") {
            setUserSelections([]);
            setIsLoadingUserSelections(false);
            setIsSuccess(true);
            return;
        }

        setIsLoadingUserSelections(true);

        try {
            const response = await axios.get(
                `${REACT_APP_API_URL}/api/admin/channel_submerchant_id/user_selections/?search=${searchString}`,
                {
                    headers: headers,
                },
            );
            setUserSelections(response.data);
            setIsSuccess(true);
        } catch (error) {
            setMessageString("Failed to get user selections");
            setIsSuccess(false);
            setIsLoading(false);
            setDisplayMessage(true);
        }

        setIsLoadingUserSelections(false);
    }

    return {
        getAllChannelSubmerchantIDSettings,
        getChannelSubmerchantIDs,
        createOrUpdateChannelSubmerchantIDs,
        getUserSelections,
        setDisplayMessage,
        isSuccess,
        messageString,
        displayMessage,
        allChannelSubmerchantIdSettings,
        channelSubmerchantIds,
        userSelections,
        isLoading,
        isLoadingUserSelections,
    }
}