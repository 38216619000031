import React from 'react';
import ButtonGroup from 'antd/lib/button/button-group';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import fraudActions from '../fraudActions';
import fraudServices from '../fraudServices';
import Table from './table';
import AddNewCorpModal from './addNewCorpModal';
import UploadCsvModal from './uploadCsvModal';
import ValidationModal from './validationModal';
import AddNewIndiModal from './addNewIndiModal';
import { Button, DatePicker, Dropdown, Icon, Input, Menu, message } from 'antd';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { jsonToCSV } from 'react-papaparse';

const ButtonTab = ({rootStyle}) => {
  const dispatch = useDispatch();
  const selected = useSelector(state => state.fraud.usersBlcklstSelected);
  
  const status = ['Individual Account', 'Enterprise Account'];
  
  const handleClick = (event) => {
    dispatch(fraudActions.setBlcklstUsersSelectedBtn(event.target.value));
    // setSelectedBtn(event.target.value);
  }
  
  return (
    <div style={styles.btnTabContainer}>
      <ButtonGroup>
        {
          status.map((btn, i) => {
            return (
              <Button
                key={btn}
                value={btn}
                onClick={handleClick}
                style={{...styles.btnGroup, 
                  color: selected === btn? '#0D3D76' : '#909196', 
                  backgroundColor: '#FFF',
                  borderBottom: selected === btn? '4px solid #0D3D76' : '4px solid #909196' }}
                  >
                  {btn}
              </Button>
            )
          })
        }
      </ButtonGroup>
    </div>
  )
}

const Gender = {
  'F': 'Female', 
  'M': 'Male'
}
 const CivilStatus = {
  'S': 'Single', 
  'M': 'Married',
  'W': 'Widowed', 
  'D': 'Divorced'
}

const EmploymentStatus = {
  'EMP': 'Employed',
  'CON': 'Consultant',
  'SEB': 'Self-employed (Business)',
  'SEF': 'Self-employed (Freelancer)',
  'UEH': 'Unemployed (Housewife)',
  'UER': 'Unemployed (Retired)',
  'UES': 'Unemployed (Student)'
}

const NatureOfWork = {
  'ADM': 'Administrative/Human Resources',
  'AGRI': 'Agriculture',
  'BNK': 'Banking Institutions',
  'IT': 'Computer and Information Technology Services',
  'CONS': 'Construction/Contractors',
  'AGN': 'Consultancy/Agencies',
  'EDUC': 'Education',
  'ENG': 'Engineering',
  'ENT': 'Entertainment',
  'FIN': 'Financial Services',
  'FOV': 'Government',
  'HRS': 'Hotel and Restaurant Services',
  'HSE': 'Household Employees',
  'INV': 'Manufacturing and Inventory',
  'MED': 'Medical and Health Services',
  'SOC': 'Community, Social, Personal Services',
  'OTH': 'Others',
  'PR': 'Public Relations',
  'EST': 'Real Estate',
  'RENT': 'Rental Services',
  'MKTG': 'Sales/Marketing/Advertising',
  'SCI': 'Science and Technology Services',
  'STD': 'Student',
  'TCS': 'Transportation and Communication Services'
}

const SourceFunds  = {
  '001': 'Allowance',
  '002':'Business Profit',
  '003':'Commissions',
  '004':'Inheritance',
  '005':'Loan',
  '006':'Salary',
  '007':'Pension',
  '008':'Remittance'
}

const IDType = { 
  'SSS': 'SSS',
  'GSIS': ' GSIS',
  // 'TIN': 'TIN',
  'DRL': `Driver's License`,
  'AICR': ' ACR',
  // 'NBI': 'NBI',
  'PAS': 'Passport',
  'PHID': ' Philhealth ID',
  // 'SCH': 'School ID',
  'UMID': ' UMID',
  'VID': 'Voters ID',
  'PRC': 'PRC ID',
  'POS': 'Digitize Postal ID'
}


const BuxUsers = () => {
  const [dateRange, setDateRange] = React.useState({startDate: null}, {endDate: null},);
  const [exportStyle, setExportStyle] = React.useState({ color: '#1DD28B', backgroundColor: 'transparent'});
  const [importStyle, setImportStyle] = React.useState({ color: '#1DD28B', backgroundColor: 'transparent'});
  const selected = useSelector(state => state.fraud.usersBlcklstSelected);
  const tableData = useSelector(state => state.fraud.tableData);
  const modalData = useSelector(state => state.fraud.checkModalData);
  const domainCSV = useSelector(state => state.fraud.fraudCSV);
  const [addNewCorpShow, setAddNewCorpModalShow] = React.useState(false);
  const [addNewIndiShow, setAddNewIndiModalShow] = React.useState(false);
  const [dateSearch, setDateSearch] = React.useState(false);
  const [totalLength, setTotalLength] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchVal, setSearchVal] = React.useState(null);
  const [uploadCsvShow, setUploadCsvShow] = React.useState(false);
  const [validationModalShow, setValidationModalShow] = React.useState(false);
  const [tableSort, setTableSort] = React.useState('newest');
  const dispatch = useDispatch();

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }

  const rangeSelect = (dates, dateString, id) => {
    setDateRange({...dateRange, [id]: moment(dateString)});
    if(id==='startDate') {
      getBlacklist('', 1, returnType(), tableSort, moment(dateString).format('MM-DD-YYYY'), dateRange['endDate']);
    } else {
      getBlacklist('', 1, returnType(), tableSort, dateRange['startDate'], moment(dateString).format('MM-DD-YYYY'));
    }
    setDateSearch(true);
  };

  const handleTableData = () => {
    switch (selected) {
      case 'Individual Account':
        return tableData;
      case 'Email':
      case 'Enterprise':
      default:
        return tableData;
    }
  }

  const exportCsv = async () => {
    let arr = new Array;
    let data;
    let res;
    setIsLoading(true);
    try {
      if(dateSearch){
        res = await fraudServices.getBlacklist('', 1, returnType(), tableSort, dateRange.startDate, dateRange.endDate, false);
      } else {
        res = await fraudServices.getBlacklist('', 1, returnType(), tableSort, '', '', true);
      }
      if(res&&res.results){
        data = res.results;
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }

    data&&data.map((item,i) => {
      if(returnType()==='kyc'){
        let row_to_add = {
          "Blacklist Code": item.kyb_code,
          "Blacklisted Name": `${item.first_name} ${item.last_name}`,
          "Blacklisted ID Number": item.ID_number,
          "Blacklisted Date of Birth": item.birthday,
          "Referrer": item.referrer,
          "Remarks": item.remarks,
          "Date Added": item.created_at,
          "Added By": item.added_by
        }

        let kyc_details = {};
        if (Object.keys(item.tagged_kyc_details).length) {
          kyc_details = {
            "KYC Full Name": `${item.tagged_kyc_details.first_name} ${item.tagged_kyc_details.middle_name} `
                            + `${item.tagged_kyc_details.last_name}`,
            "Birthplace": item.tagged_kyc_details.birthplace,
            "KYC Date of Birth": item.tagged_kyc_details.birthday,
            "Civil Status": CivilStatus[item.tagged_kyc_details.civil_status],
            "Contact": item.tagged_kyc_details.contact,
            "Email": item.tagged_kyc_details.email,
            "Employer Address": `${item.tagged_kyc_details.employer_address.street}, `
                                + `${item.tagged_kyc_details.employer_address.barangay}, `
                                + `${item.tagged_kyc_details.employer_address.city}, `
                                + `${item.tagged_kyc_details.employer_address.province} `
                                + `${item.tagged_kyc_details.employer_address.postalCode}`,
            "Employer Industry": NatureOfWork[item.tagged_kyc_details.employer_industry],
            "Employer Name": item.tagged_kyc_details.employer_name,
            "Emplyment Status": EmploymentStatus[item.tagged_kyc_details.employment_status],
            "Gender": Gender[item.tagged_kyc_details.gender],
            "KYC ID Number": item.tagged_kyc_details.id_number,
            "ID type": IDType[item.tagged_kyc_details.id_type],
            "Mother's Maiden Name": item.tagged_kyc_details.mothers_maiden_name,
            "Nationality": item.tagged_kyc_details.nationality,
            "Present Address": `${item.tagged_kyc_details.present_address.street}, `
                               + `${item.tagged_kyc_details.present_address.barangay}, `
                               + `${item.tagged_kyc_details.present_address.city}, `
                               + `${item.tagged_kyc_details.present_address.province} `
                               + `${item.tagged_kyc_details.present_address.postalCode}`,
            "Source of Funds": SourceFunds[item.tagged_kyc_details.source_of_funds]
          }
        } else {
          kyc_details = {
            "KYC Full Name": '',
            "Birthplace": '',
            "KYC Date of Birth": '',
            "Civil Status": '',
            "Contact": '',
            "Email": '',
            "Employer Address": '',
            "Employer Industry": '',
            "Employer Name": '',
            "Emplyment Status": '',
            "Gender": '',
            "KYC ID Number": '',
            "ID type": '',
            "Mother's Maiden Name": '',
            "Nationality": '',
            "Present Address": '',
            "Source of Funds": '',
          }
        }

        arr.push({...row_to_add, ...kyc_details});
      }else {
        arr.push({
          "Blacklist Code": item.kyb_code,
          "Business Name": item.business_name,
          "Referrer": item.referrer,
          "Remarks": item.remarks,
          "Date Added": item.created_at,
          "Added By": item.added_by,
        })
      }
    });

    const csv = jsonToCSV(arr);
    const blob = new Blob([csv]);
		const a = window.document.createElement("a");
	    a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
	    a.download = returnType()==='kyc'?'kyc_blacklisted.csv':'kyb_blacklisted.csv';
	    document.body.appendChild(a);
	    a.click();
	    document.body.removeChild(a);
  }

  const handleMouseLeave = (event) => {
    if(event.target.name==='exportBtn'){
         setExportStyle({
           color: '#1DD28B',
           backgroundColor: 'transparent'
         });
       } else {
         setImportStyle({
           color: '#1DD28B',
           backgroundColor: 'transparent'
         });
       }
     }
   
  const handleMouseOver = (event) => {
    if(event.target.name==='exportBtn'){
      setExportStyle({
        color: '#FFF',
        backgroundColor: '#1DD28B'
      });
    } else {
      setImportStyle({
        color: '#FFF',
        backgroundColor: '#1DD28B'
      });
    }
  }

  const handleSearch = (e) => {
    // setSearchVal(e.target.value);
    getBlacklist(e.target.value, '', returnType());
    resetProps();
  }

  const setDateFilter = (date) => {
    setDateRange({startDate: date?moment(date).format('MM-DD-YYYY'):moment().format('MM-DD-YYYY')}, {endDate: moment().format('MM-DD-YYYY')});
  }

  const returnType = () =>  {
    switch (selected) {
      case 'Individual Account':
        return 'kyc';
      case 'Enterprise Account':
        return 'kyb';
      default:
        return 'kyc';
    }
  }

  const errorPrompt = (msg) => {
    message.error(msg);
  };

  const getBlacklist = async (search, page, type, sort, start, end, dump) => {
    setIsLoading(true);
    try {
      const res = await fraudServices.getBlacklist(search?search:'', page?page:1, type?type:'kyc', sort&&sort, start, end, dump);
      if(res&&res.results){
        dispatch(fraudActions.setTableData(res.results));
        // setDateFilter(res.results&&res.results.slice(-1)[0]&&res.results.slice(-1)[0].created_at);
        setTotalLength(res.total);
        dispatch(fraudActions.setTablePage(1));
      } else {
        errorPrompt(res);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      errorPrompt('Please try again');
    }
  }

  const handleSortChange = ({key}) => {
    setTableSort(key)
    getBlacklist('', 1, returnType(), key);
    resetProps();
  }

  const menu = (
    <Menu onClick={handleSortChange}>
      <Menu.Item key="newest">
        Newest
      </Menu.Item>
      <Menu.Item key="oldest">
        Oldest
      </Menu.Item>
    </Menu>
  )

  const deletedSuccess = () => {
    getBlacklist('', 1, returnType(), tableSort);
  }

  const resetProps = () => {
    setDateSearch(false);
    setDateRange({startDate: moment(), endDate: moment()});
  }

  React.useEffect(()=> {
    dispatch(fraudActions.setTableData([]));
    getBlacklist('', 1, returnType(), tableSort);
    resetProps();
  },[selected])

  return (
    <div style={styles.root}>
      <div style={styles.btnMain}>
        <div>
          <Button 
            onClick={exportCsv}
            style={{...styles.dload,
              ...exportStyle,
            }}
              name='exportBtn'
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              >
              Export CSV
          </Button>
          <Button 
            onClick={()=>setUploadCsvShow(true)}
            style={{...styles.dload,
              ...importStyle,
            }}
              name='importBtn'
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              >
              Import CSV
          </Button>
          <Button 
            onClick={()=> selected==='Individual Account'? setAddNewIndiModalShow(true) : setAddNewCorpModalShow(true)}
            style={{...styles.import,
              color: '#FFF',
              backgroundColor: '#1DD28B',
            }}>
            Add New
          </Button>   
        </div>
        <UploadCsvModal visible={uploadCsvShow} closeModal={()=> setUploadCsvShow(false)} onSuccess={()=> {setUploadCsvShow(false);setValidationModalShow(true);}} />
        <AddNewCorpModal visible={addNewCorpShow} closeModal={()=> setAddNewCorpModalShow(false)} onSuccess={()=> {setAddNewCorpModalShow(false);getBlacklist('', 1, 'kyb');}} />
        <AddNewIndiModal visible={addNewIndiShow} closeModal={()=> setAddNewIndiModalShow(false)} onSuccess={()=> {setAddNewIndiModalShow(false);getBlacklist('', 1, 'kyc');}} />
        <ValidationModal visible={validationModalShow} closeModal={()=> {setValidationModalShow(false);}} data={modalData} csv={domainCSV} onSuccess={()=> {setValidationModalShow(false);getBlacklist('', 1, returnType());}} />
      </div>
      <ButtonTab />
      <div style={styles.body}>
        <div style={styles.dateBtn}>
          <Dropdown overlay={menu} trigger={['click']}>
            <a style={styles.dropdownItems} >
              {tableSort.charAt(0).toUpperCase() + tableSort.substring(1)}
              <ExpandMoreIcon style={styles.dropdownArror} />
            </a>
          </Dropdown>
          <DatePicker 
            format='MM/DD/YYYY' 
            disabledDate={disabledDate} 
            defaultValue={moment()} 
            value={dateRange.startDate || null} 
            size="large" picker="month" 
            onChange={ (dates, dateString) => rangeSelect(dates, dateString, 'startDate')} 
            placeholder='Start date' 
            style={styles.datePic} 
            suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{fontSize: 22}} /></div>}
            allowClear={false}/>
          <span style={styles.span}>-</span>
          <DatePicker 
            format='MM/DD/YYYY' 
            disabledDate={disabledDate} 
            defaultValue={moment()}
            value={dateRange&&moment(dateRange.endDate)} 
            size="large" 
            picker="month" 
            onChange={ (dates, dateString) => rangeSelect(dates, dateString, 'endDate')} 
            placeholder='End date' 
            style={styles.datePic}
            suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{fontSize: 22}} /></div>}
            allowClear={false}/>
          <Input
            placeholder="Search"
            style={{ width: '200px', height: '40px', borderRadius:'4px' }}
            suffix={<Icon type="search" style={{fontSize:'18px'}}/>}
            onChange={handleSearch}
          />
        </div>
        <Table data={tableData} 
          onDeleted={deletedSuccess} 
          isLoading={isLoading} 
          length={totalLength}
          selected={selected}
          sort={tableSort}
        />
      </div>
    </div>
  )
}

const styles = {
  root: {
    padding: '0 42px'
  },
  body: {
    backgroundColor: '#FFF',
    padding: '0 20px'
  },
  btnGroup: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '20px 32px 24px 32px',
    borderColor: 'transparent'
  },
  btnMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: '30px',
    marginTop: '-42px'
  },
  datePic: {
    height: 40,
    fontSize: 14,
    color: '#2F3542',
    width: 178,
    padding: '0 8px 0 8px'
  },
  dateBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '32px 0 20px 0',
  },
  suffixIcon: {
    marginTop: '-12px', 
    marginRight: 12, 
    color: '#000'
  },
  dload: {
    fontSize: 16,
    fontWeight: '600',
    height: 40,
    borderRadius: 10,
    borderColor: '#1DD28B',
    marginRight: 8,
  },
  import: {
    fontSize: 16,
    fontWeight: '600',
    height: 40,
    borderRadius: 10,
    marginLeft: 8,
  },
  btnTabContainer: {
    backgroundColor: '#FFF',
    width: '100%',
    padding: '20px, 20px 0 20px',
    borderBottom: '1px solid #D1D5DD'
  },
  dropdownItems: {
    display: 'flex', 
    alignItems: 'center', 
    padding: '7px 4px 7px 16px',
    fontSize: 16, 
    color: '#2B2D33',
    border: '1px solid #D1D5DD',
    borderRadius: 5,
  },
  dropdownArror: {
    color: '#2B2D33', 
    fontSize: 24, 
    margin: '0 4px'
  },
}

export default BuxUsers;