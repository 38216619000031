import React from 'react'
import ecomStyles from '../../static/css/Ecomx';
import logo from "../../static/icons/logo.svg";
import {Grid} from '@material-ui/core'
import ecomx from '../../static/images/ecomx.svg'
import {Spin} from 'antd'

const CheckSeat = props => {
    const ecom = ecomStyles();

    return(
            <div className={ecom.main}>
                <div className={ecom.logoDiv}>
                    <img src={logo} alt="#"/>
                </div>
                <div className={ecom.cardDiv}>
                    <Grid container>
                        <Grid lg={6}>
                            <div className={ecom.cLeft}>
                                <img src={ecomx} />

                                <div className={ecom.clInfo}>
                                    <span>Date: December 10, 2019</span><br/>
                                    <span>Venue: UnionBank Plaza Bldg</span>
                                </div>

                                <div className={ecom.clInfo1}>
                                    <span>By: Nick Peroni | Powered by <img src={logo} className={ecom.clLogo}/></span> 
                                </div>

                            </div>
                        </Grid>
                        <Grid lg={6}>
                            <div className={ecom.cRight}>
                                <div className={ecom.crTop}>
                                    <div className={ecom.crHeader}>
                                        <span>Registration Fee</span>
                                    </div>

                                    <div className={ecom.crDiscoubt}>
                                        <span>P15,500.00</span>
                                    </div>

                                    <div className={ecom.crNow}>
                                        <span>P500.00</span>
                                    </div>
                                </div>

                                <div className={ecom.crBot}>
                                    <div className={ecom.crbHeader}>
                                        <span>Don't miss the chance!</span>
                                    </div>
                                    <div className={ecom.crbText}>
                                        <span>Limited seats only.</span>
                                        <p>First Come, First Served Basis.</p>
                                    </div>

                                    <div style={{display:'flex', justifyContent: 'center', marginTop: '40px'}}>

                                        <Spin size="large" />
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
    )
}

export default CheckSeat