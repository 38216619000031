import React from 'react';
import UserDetailsCard from './user_details_card';
import UserHistoryTable from './user_history_table';
import { Button, Icon } from 'antd';
import { useLocation } from 'react-router';
import { history } from '../../../../store/history';
import { isMobile } from 'react-device-detect';
import TestModeBanner from '../../../cards/test_mode/testModeBanner';

const SubwalletUserHistory = () => {
    const location = useLocation();
    const details = location.state ? location.state.subWalletDetails : '';
    const isMultiwallet = localStorage.getItem('is_multiwallet') === 'true';
    const isSbMultiwallet = localStorage.getItem("is_sb_multiwallet") === 'true';
    const kybStep = localStorage.getItem("KYBStep");
    const userType = localStorage.getItem("userType");
    const approvedStep = [11, 12]
    const testMode = (history.location.pathname.indexOf('/test') > -1&&localStorage.getItem("userType") == "CO");
    const isChild = localStorage.getItem("is_child") === 'true';
    const notApprovedKyb = (userType == "CO" && localStorage.getItem("KYBStep") < 11) && ((!testMode || localStorage.getItem("userType") == "CO") && (!approvedStep.includes(parseInt(kybStep)) && !testMode));

    const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
    const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
    const isDell1 = window.screen.width * window.devicePixelRatio === 1366 && window.screen.height * window.devicePixelRatio === 768;
    const isMac = window.screen.width * window.devicePixelRatio === 1440 && window.screen.height * window.devicePixelRatio === 900;

    const isDellReso = (isSmallReso || isDell || isDell1)
    const segments = localStorage.getItem("segments")

    const redirect = () => {
        let current_path = window.location.pathname
        if (localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null) {
            history.push('/login?next=' + current_path)
        } 
        else if ((testMode && !isSbMultiwallet)) {
            history.push('/test/dashboard')
        }
        else if (!testMode && (!['CO'].includes(localStorage.getItem("userType")) || !isMultiwallet || notApprovedKyb || isChild)) {
            history.push('/dashboard')
        } 
        else if(isMobile) {
            history.push('mobile/multiwallet');
        }
    }

    React.useEffect(() => {
        redirect();
        window.scrollTo(0, 0);
    })

    return (
        <div className={isDell ? "top-12" : "top-40"} style={{marginRight: isDellReso ? '2vw' : isMac ? '5vw' : '8vw'}} >
            <TestModeBanner width={'85vw'} top={64} right={0}/>

            <div>
                <a href onClick={() => history.goBack()}>
                    <Icon type="arrow-left" className={`text-${segments} right-16`} style={{fontSize: 24}} /> 
                    <strong style={styles.regularFont}>User History</strong>
                </a>
                {/* <Button style={styles.btnBack} onClick={() => history.goBack()}>{<Icon type="close" />}Close</Button> */}
            </div>
            <UserDetailsCard segments={segments} details={details} />
            <UserHistoryTable segments={segments} />
        </div>
    )
}

const styles = {
    root: {
        paddingRight: 32,
    },
    titleDiv: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '50px 0px 40px 0px',
    },
    regularFont: {
        fontSize: 24,
        color: '#000000',
    },
    btnBack: {
        border: 0,
        borderRadius: 40,
        color: '#F5922F',
        fontSize: 16,
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.102751)'
    },
}

export default SubwalletUserHistory;