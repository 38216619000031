import React, { useRef, useEffect } from 'react'
import * as Component from './homeComponent/indexComponent'
import { isMobile, deviceType } from 'react-device-detect'
import homeWeb from "../../static/css/Home";
import { useTheme} from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import { backToTop } from '../constants/constants'

const LandingPage = props => {
    const pricingRef = useRef();
    const home = homeWeb()
    const theme = useTheme()
    const smallReso = useMediaQuery(theme.breakpoints.down('sm'));
    const [showChart, setShowChart] = React.useState(true)

    const goToPricing = () => {
        pricingRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
        })
    }

    const showComparativeChart = () => {
        setShowChart(!showChart)
        backToTop()
    }

    useEffect(() => {
        backToTop()
    }, [])

    return(
        <div>
            <div>
                <Component.Headers pricing={goToPricing} chart={showComparativeChart} />
            </div>
            <div hidden={showChart}>
                <Component.ComparativeChart />
            </div>
            <div hidden={!showChart}>
                <div>
                    <Component.Landing/>
                </div>
                <div>
                <Component.Pricing pricingRef={pricingRef} />
                </div>
                <div>
                    <Component.Partners chart={showComparativeChart} />
                </div>
                <div>
                    <Component.Details />
                </div>
                <div>
                    <Component.Testimonials />
                </div>
            </div>

            <div className={home.preFooter} align='center'>
                <div className={isMobile || smallReso ? home.none : home.preFooterDiv}>
                    <div className={home.preFooterText}>Let’s get you started!</div>
                    <button className={home.preFooterBtn} onClick={ () => window.location.href = "/signup"}>
                        Sign up now
                    </button>
                </div>
            </div>

            <div>
                <Component.Footer pricing={goToPricing}/>
            </div>
        </div>
    )
}

export default LandingPage