import React,{ useState } from 'react';
import { Modal, Typography, Button, Icon, Radio, message } from 'antd';
import ArrowDown from '../../../static/icons/arrow-down.svg';
import AddModalTable from './addModalTable';
import ButtonGroup from 'antd/lib/button/button-group';
import SearchById from './searchById';

const AddModal = ({visible, closeModal, onEndorse}) => {
  const [btnStyle, setBtnStyle] = useState({color: '#F5922F', backgroundColor: '#FFF' })
  const tranxTypeOptions = ['Payment Request', 'Payout'];
  const [tranxType, setTranxType] = React.useState('Payment Request');
  const [chooseFrom, setChooseFrom] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(null);;

  const handleMouseOver = () => {
    setBtnStyle({
      backgroundColor: '#F5922F',
      color: '#FFF'
    })
  }

  const handleMouseLeave = () => {
    setBtnStyle({
      backgroundColor: '#FFF',
      color: '#F5922F'
    })
  }

  const handleSelect = (e) => {
    setChooseFrom(0);
    setTranxType(e.target.value);
  }

  const handleRadio = (e) => {
    if(!selectedTab) {
      setSelectedTab(e.target.value == 0? 'Paid' : 'All')
    }
    setChooseFrom(e.target.value)
  }

  React.useEffect(() => {
    setSelectedTab(tranxType === 'Payout'? 'All' : 'Paid')
  },[tranxType])

  return (
    <Modal 
      visible={visible} 
      bodyStyle={styles.root} 
      footer={null}
      centered
      width={960}
      onCancel={closeModal}
      closeIcon={<Icon type="close" style={{color:'#F5922F'}}/>}
    >
      <Typography style={styles.title}>Add Endorsement</Typography>
      {/* <div style={styles.questionContainer} hidden={!tranxType}>
        <label style={styles.label}>Choose from table or insert id? </label>
        <Radio.Group buttonStyle={{backgroundColor: '#F5922F'}} onChange={handleRadio} >  
          <Radio color='#F5922F' value={0}>Table</Radio>
          <Radio color='#F5922F' value={1}>Insert Id</Radio>
        </Radio.Group>
      </div> */}
      <div>
        <AddModalTable selected={tranxType} selectedTab={selectedTab} onSuccess={closeModal}/>
      </div>
      <div hidden={chooseFrom!==1}>
        {/* <SearchById selected={tranxType} loading={isLoading} /> */}
      </div>
      <div style={styles.mainBtn}>
      </div>
    </Modal>
  )
}

const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 24,
    color: '#2B2D33',
    fontWeight: '300',
    textAlign: 'left',
    padding: '24px 0 10px 0'
  },
  msg: {
    fontSize: 14,
    color: '#000',
    textAlign: 'left',
    padding: '10px 0'
  },
  mainBtn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 10,
  },
  btn: {
    height: 48,
    width: '45%',
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 10,
  },
  selectStyle: {
    // width: '100%',
    height: '40px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    appearance: 'none',
    background: `url(${ArrowDown})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '95% 50%',
    fontSize: '16px',
    borderColor: '#D1D5DD',
    borderRadius: 4,
    padding: '6px 0px 10px 14px',
    margin: '4px 0 0 0'
  },
  questionContainer: {
    padding: '12px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start'
  },
  inputDiv: {
    // width: 240,
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0'
  },
  label: {
    fontSize: 16,
    color: '#000000',
    padding: '4px'
  },
  btnGroup: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '0 32px',
  },
}

export default AddModal;