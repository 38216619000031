import React, {useEffect} from 'react';
import accountStyle from '../../static/css/Account';
import EmailNotifsToggle from './email_notifs_toggle';
import TranxFeeToggle from './tranx_fee_toggle';
import settingServices from './settingServices';
import { isMobile, mobileModel } from 'react-device-detect'

const GeneralSettings = props => {
    useEffect(() => {
        async function loadCurrentValues() {
            await fetchGeneralSettings();
            const isChild = localStorage.getItem("is_child") === "true"
            
            if (!isChild) {
                await fetchUserDefinedSettings();
            }

            setLoading(false);
        }

        if(localStorage.getItem("jwtToken") !== "" && localStorage.getItem("jwtToken") !== null) {
            setLoading(true);
            loadCurrentValues()
        }
    }, [])
    const acct = accountStyle();

    async function fetchGeneralSettings() {
        try{
            let res = await settingServices.getGenSettings();
            setShoulderEnabled(res.shoulder_enabled);
            setDisabledChannels(res.disabled_channels);
            setChannels(res.channels);
        }
        catch(error){
            console.log(error);
        }
    }

    async function fetchUserDefinedSettings() {
        try{
            const userID = localStorage.getItem("userId");
            const query_params = `?name=receives_payment_related_emails`
            let res = await settingServices.getUserDefinedSettings(userID, query_params);

            let userWantsEmails = res.data.value;
            setUserReceivesEmails(userWantsEmails);
        }
        catch(error){
            console.log(error);
        }
    }

    const [shoulderEnabled, setShoulderEnabled] = React.useState(false);
    const [channels, setChannels] = React.useState([]);
    const [disabledChannels, setDisabledChannels] = React.useState([]);
    const [userReceivesEmails, setUserReceivesEmails] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const isEnterprise = localStorage.getItem('userType') === 'CO';

    return(
        !loading &&
            (isMobile
            ?
                <div className={acct.personal}>
                    <div className={acct.infoDiv}>
                        <TranxFeeToggle
                            initialSwitchValue={shoulderEnabled}
                            isEnterprise={isEnterprise}
                        />
                    </div>
                    <div className={acct.infoDiv}>
                        <EmailNotifsToggle initialSwitchValue={userReceivesEmails}/>
                    </div>
                </div>
            :
                <div>
                    <div className={acct.infoDiv}>
                        <TranxFeeToggle 
                            initialSwitchValue={shoulderEnabled}
                            isEnterprise={isEnterprise}
                        />
                    </div>
                    <div className={acct.infoDiv}>
                        <EmailNotifsToggle initialSwitchValue={userReceivesEmails}/>
                    </div>
                </div>
                
            )
    )
}

export default GeneralSettings;