import React from 'react';
import axios from 'axios';
import {Button, Modal} from 'antd'
import {TextField, Grid, makeStyles} from '@material-ui/core';


const EditFeeModal = props => {

    let data = props.editData

    const useStyles = makeStyles(theme => ({
        container: {
          display: 'flex',
          flexWrap: 'wrap',
        },
        textField: {
          marginRight: '100%',
          width: 230,
        },
      }));
    
    
    const classes = useStyles();

    const [id, setID] = React.useState("");
    const [edittedChannel, setEdittedChannel] = React.useState("");
    const [edittedChannelCode, setEdittedChannelCode] = React.useState("");
    const [edittedChannelType, setEdittedChannelType] = React.useState("");
    const [edittedChannelMax, setEdittedChannelMax] = React.useState("");
    const [edittedChannelEntMax, setEdittedChannelEntMax] = React.useState("");
    const [edittedChannelMin, setEdittedChannelMin] = React.useState("");
    const [edittedChannelFee, setEdittedChannelFee] = React.useState("");
    const [edittedBuxFee, setEdittedBuxFee] = React.useState("");
    const [edittedChannelPercent, setEdittedChannelPercent] = React.useState("");
    const [edittedBuxPercent, setEdittedBuxPercent] = React.useState("");
    const [edittedChannelPartner, setEdittedChannelPartner] = React.useState("");
    const [tier, setTier] = React.useState("");
    const [enable, setEnable] = React.useState("");

      React.useEffect(() => {
        setID(data.id)
        setEdittedChannel(data.channel)
        setEdittedChannelCode(data.channel_code)
        setEdittedChannelType(data.channel_type)
        setEdittedChannelEntMax(data.enterprise_maximum)
        setEdittedChannelMin(data.minimum)
        setEdittedChannelMax(data.maximum)
        setEdittedBuxFee(data.bux_fee)
        setEdittedBuxPercent(data.bux_percent)
        setEdittedChannelFee(data.channel_fee)
        setEdittedChannelPercent(data.channel_percent)
        setEdittedChannelPartner(data.channel_partner)
        setTier(data.tier)
        setEnable(data.enable)
      },[data])


    async function editFee() {
        let yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }

        let params = {
            id: id == "" ? data.id : id,
            channel: edittedChannel,
            channel_code: edittedChannelCode == "" ? data.channel_code : edittedChannelCode,
            channel_type: edittedChannelType == "" ? data.channel_type : edittedChannelType, 
            channel_fee: edittedChannelFee == "" ? data.channel_fee : edittedChannelFee,
            maximum: edittedChannelMax == "" ? data.maximum : edittedChannelMax,
            enterprise_maximum: edittedChannelEntMax == "" ? data.enterprise_maximum : edittedChannelEntMax,
            minimum: edittedChannelMin == "" ? data.minimum : edittedChannelMin,
            bux_fee: edittedBuxFee == "" ? data.bux_fee : edittedBuxFee,
            channel_percent: edittedChannelPercent == "" ? data.channel_percent : edittedChannelPercent,
            bux_percent: edittedBuxPercent == "" ? data.bux_percent : edittedBuxPercent,
            channel_partner: edittedChannelPartner,
            tier: tier,
            enable: enable,
        }
        console.log(params)
        let response = await axios.post(
          process.env.REACT_APP_API_URL + '/api/fees/edit/', params,yourConfig
        );

        console.log(response)
        if(response.data.status == 'success'){
            props.closeModal(2)
            props.fetchData();
        }
    }

    return(
      <Modal
        key={data.id}
        title="Edit Fee"
        centered
        visible={props.openModal}
        onCancel={() => props.closeModal(2)}
        footer={[
            <Button key="back" onClick={()=>props.closeModal(2)}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={()=>editFee()}>
              Update
            </Button>,
          ]}
        >
        <Grid container>
            <Grid item={true} lg={12}>
                <TextField
                    id="outlined-basic"
                    label="Channel"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    defaultValue={data.channel}
                    className={classes.textField}
                    onChange={ e => setEdittedChannel(e.target.value)}

                    />
            </Grid>
            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Channel Code"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    defaultValue={data.channel_code}
                    className={classes.textField}
                    onChange={ e => setEdittedChannelCode(e.target.value)}

                    />
            </Grid>
            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Channel Type"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    defaultValue={data.channel_type}
                    className={classes.textField}
                    onChange={ e => setEdittedChannelType(e.target.value)}

                    />
            </Grid>
            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Channel Min"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    defaultValue={data.minimum}
                    className={classes.textField}
                    onChange={ e => setEdittedChannelMin(e.target.value)}

                    />
            </Grid>
            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Channel Max"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    defaultValue={data.maximum}
                    className={classes.textField}
                    onChange={ e => setEdittedChannelMax(e.target.value)}

                    />
            </Grid>
            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Enterprise Max"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    defaultValue={data.enterprise_maximum}
                    className={classes.textField}
                    onChange={ e => setEdittedChannelEntMax(e.target.value)}

                    />
            </Grid>
            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Channel Fee"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    defaultValue={data.channel_fee}
                    className={classes.textField}
                    onChange={ e => setEdittedChannelFee(e.target.value)}

                    />
            </Grid>
            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Channel Percent"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    defaultValue={data.channel_percent}
                    className={classes.textField}
                    onChange={ e => setEdittedChannelPercent(e.target.value)}

                    />
            </Grid>
            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="BUx Fee"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    defaultValue={data.bux_fee}
                    className={classes.textField}
                    onChange={ e => setEdittedBuxFee(e.target.value)}

                    />
            </Grid>
            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="BUx Percent"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    defaultValue={data.bux_percent}
                    className={classes.textField}
                    onChange={ e => setEdittedBuxPercent(e.target.value)}

                    />
            </Grid>

            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Channel Partner"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    defaultValue={data.channel_partner}
                    onChange={ e => setEdittedChannelPartner(e.target.value)}

                    />
            </Grid>

            <Grid item={true} lg={6}>
                <TextField
                    id="outlined-basic"
                    label="Tier"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    defaultValue={data.tier}
                    onChange={ e => setTier(e.target.value)}

                    />
            </Grid>

        </Grid>
      </Modal>  
    )
}

export default EditFeeModal;