import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import PayoutIcon from "../../../../../static/icons/pmodal.svg";

export function TransferReceipt({ transferDetails, onClose }) {
  const { receiver_bank } = transferDetails.transfer_details;
  const {
    account_number,
    created_at,
    message,
    receiver_email,
    receiver_mobile,
    receiver_name,
    ref_num,
    sender_email,
    sender_mobile,
    sender_name,
    service_fee,
    status,
    sub_amount,
    total_amount,
  } = transferDetails.transferResults;
  const history = useHistory();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={PayoutIcon} alt="payout icon" />
        <p style={{ fontWeight: "bold" }}>{message}</p>
      </div>
      <div
        style={{
          width: "100%",
          padding: "2em",
          backgroundColor: "#fff",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          fontSize: "13px",
        }}
      >
        <p>
          <span style={{ fontWeight: "bold" }}>Reference Number:</span>{" "}
          {ref_num}
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Account Name:</span>{" "}
          {receiver_name}
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Account Number:</span>{" "}
          {account_number}
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Bank:</span> {receiver_bank}
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Receipt sent to:</span>{" "}
          {sender_email}
        </p>
        <hr
          style={{ margin: "1rem 0", border: "1px solid #ccc", height: "1px" }}
        />

        <p>
          <span style={{ fontWeight: "bold" }}>Transfer Amount:</span> PHP{" "}
          {sub_amount}
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Service Fee:</span> PHP{" "}
          {service_fee}
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Requested Date:</span>{" "}
          {created_at}
        </p>
        <hr
          style={{ margin: "1rem 0", border: "1px solid #ccc", height: "1px" }}
        />

        <p>
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>Total:</span>{" "}
          PHP {total_amount}
        </p>
      </div>
      <Button
        size="large"
        htmlType="button"
        style={{
          alignSelf: "flex-end",
          backgroundColor: "rgb(0, 81, 176)",
          color: "#fff",
          fontWeight: "bold",
        }}
        onClick={() => {
          onClose();
          history.go(0);
        }}
      >
        Done
      </Button>
    </div>
  );
}
