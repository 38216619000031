import React from 'react';
import Main from '../../../layouts/Main';
// import {Tabs} from 'antd';
import KYBSubmission from '../kyc/kyb-admin'
import Fee from '../fees/kyb_fee/index'
import Channels from '../channels/components/index'
import Partners from './partners/components/index'
import SubMerchants from './submerchants/components/index'
import UserAccounts from '../userAccount/user_account'
import AdminCredentials from './partners/credentials';
import accountStyles from '../../../static/css/Account'
import Rebates from './rebates';
import AdvancePayout from '../advance_payout';
import SubWallets from '../subwallets';
import feesServices from '../fees/feesServices';

const KYBIndividualMain = props => {
    const pUid = props.match.params.id;

    const kyb = accountStyles();
    const locationHash = window.location.hash
    const [hash, setHash] = React.useState(locationHash ? locationHash : '#kyb_form');
    const [isMultiwalletEnabled, setIsMultiwalletEnabled] = React.useState(false)
    const isHidden = localStorage.getItem('userType') == 'PS' ? true : false;

    function showComponent(item) {
      setHash(item)
      window.location.href = item
    }

    const checkMultiwallet = async () => {
      try {
          const res = await feesServices.userTranxFeeInit(pUid);
          if(res){
              setIsMultiwalletEnabled(res.multiwallet_enabled);
          }
     } catch (error) {
         console.log(error);
     }
    }

    React.useEffect(() => {
      localStorage.setItem("KYBType", "")
      checkMultiwallet()
    })

    const tabs = [
      {
        'title': 'KYB Form',
        'hash': '#kyb_form',
        'hidden': false,
        'component': <KYBSubmission props={props} />
      },
      {
        'title': 'Channels',
        'hash': '#channels',
        'hidden': isHidden,
        'component': <Channels props={props}/>
      },
      {
        'title': 'Fees',
        'hash': '#fees',
        'hidden': isHidden,
        'component': <Fee props={props} />
      },
      {
        'title': 'Rebates',
        'hash': '#rebates',
        'hidden': isHidden,
        'component': <Rebates props={props} />
      },
      {
        'title': 'Sub-wallets',
        'hash': '#subwallets',
        'hidden': isHidden || !isMultiwalletEnabled,
        'component': <SubWallets props={props}/>
      },
      {
        'title': 'User Accounts',
        'hash': '#user_accounts',
        'hidden': isHidden,
        'component': <UserAccounts props={props}/>
      },
      {
        'title': 'Card Payments',
        'hash': '#card_payments',
        'component': <Partners props={props}/>
      },
      {
        'title': 'Sub-merchants',
        'hash': '#sub-merchants',
        'hidden': isHidden,
        'component': <SubMerchants props={props}/>
      },
      {
        'title': 'Credentials',
        'hash': '#credentials',
        'hidden': isHidden,
        'component': <AdminCredentials props={props}/>
      },
      {
        'title': 'Advance Payout',
        'hash': '#advance_payout',
        'hidden': isHidden,
        'component': <AdvancePayout props={props}/>
      }
    ]

    return(
      <Main>
        <div className={kyb.mainBody}>
          <div className={kyb.menu}>
            <div className={kyb.header} align='left'>
                KYB Submission
            </div>

            <div className={kyb.tabBtns}>
              {
                tabs.map((item, key) => {
                  return(
                    <div key={key} className={kyb.tabLink}
                        style={{
                        borderBottom: item.hash == hash ? '2px solid #0d3d76' : ''
                        }}
                        hidden={item.hidden}>
                        <a href style={{color: item.hash == hash ? '#0D3D76' : 'gray'}}
                            onClick={() => showComponent(item.hash)}>
                            {item.title}
                        </a>
                    </div>
                  )
                }, [this])
              }
            </div>
          </div>

          <div style={{width: '100%'}}>
            {
              tabs.map((item, key) => {
                return (
                  <div key={key} hidden={item.hash != hash} className={kyb.tabBody}>
                      {
                       !item.hidden &&
                       <div>
                          {item.component}
                        </div>
                      }
                     
                  </div>
              )
              }, [])
            }
          </div>
        </div>
          
      </Main>
    )

}

export default KYBIndividualMain