import React from 'react';
import {Modal, Table, Button} from 'antd';
import fundStyle from "../../../static/css/Funds";
import {ExportToCsv} from 'export-to-csv'
import moment from 'moment'


const TotalPaymentModal = props => {

    const f = fundStyle()
    const exportTable = () => {    
        let options = { 
          filename: 'Funds_'+moment().format("MM-DD-YYYY_HH:mm:ss"),
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true, 
          showTitle: true,
          title: 'BUx Funds',
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true
        };
          
        let csvExporter = new ExportToCsv(options);
        
        for(let i = 0; i<totalHistory.length;i++){
            csvExporter.generateCsv(totalHistory[i].transaction_list);
        }
    
      };

    const {
        title,
        openModal,
        closeModal,
        totalHistory
    } = props;

    const columns = [
        {
            title: "Reference Number",
            width: 150,
            render: (text, record) => {
                return (
                <div>
                    <div className={f.descSyle}><b>{record.code}</b></div>
                    <div className={f.fundText}><b>{record.description}</b></div>
                </div>)
            }
        },
        {
            title: "Date Paid",
            width: 200,
            render: (text, record) => {
                return (
                <div>
                    <div className={f.descSyle}><b>{record.timestamp}</b></div>
                </div>)
            }
        },
        {
            title: "Terminal",
            width: 200,
            render: (text, record) => {
                return (
                <div>
                    <div className={f.descSyle}><b>{record.terminal}</b></div>
                </div>)
            }
        },
        {
            title: "Total Amount",
            width: 150,
            render: (text, record) => {
                return (
                <div>
                    <div className={f.descSyle}><b>&#8369; {record.amount}</b></div>
                </div>)
            }
        },
        {
            title: "Receivable",
            width: 150,

            render: (text, record) => {
                return (
                <div>
                    <div className={f.descSyle}><b>&#8369; {parseFloat(record.seller_net).toFixed(2)}</b></div>
                </div>)
            }
        },
    ]

    return(
        <Modal title={title}
        visible={openModal}
        width={800}
        onCancel={()=>closeModal()}
        footer={null}>
            <div>
            <Button
                style={{
                    backgroundColor: '#1DD28B',
                    border: '#1DD28B',
                    height: '40px',
                    borderRadius:'10px',
                    zIndex: '1',
                    float: 'right',
                    marginBottom: 32,
                }}
                onClick={()=>exportTable()}>
                    <div style={{fontSize: '14px', fontWeight:'600', color: '#fff'}}>Export CSV</div>
            </Button>
            {
                totalHistory ? 
                    totalHistory.map((item, key) => {
                        return(
                            <div>
                            <div className={f.descSyle} style={{marginBottom: 16}}><b>{item.user} Payment Breakdown</b></div>
                            <Table
                            rowClassName={(key) =>
                                key % 2 === 0 ? "table-row-light" : "table-row-dark"
                            }
                            dataSource={item ? item.transaction_list : ''}
                            columns={columns}
                            />
                            </div>
                        )
                    })

                    : ''
            }
            </div>
        </Modal>
    )
}

export default TotalPaymentModal;