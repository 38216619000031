import React from 'react'
import {Modal, Button} from 'antd'
import TermsImg from '../../static/img/terms_modal.svg'
import { isMobile } from 'react-device-detect'
import axios from 'axios'
import {Handle401} from '../handle401/handle401'
import '../../App.scss';

export function showTermsModal(){
    this.setState({
        modalVisibility: true
    })
}

class TermsModal extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            modalVisibility: false
        }

        showTermsModal = showTermsModal.bind(this)
    }


    async agree(){
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let response = await axios.get(process.env.REACT_APP_API_URL + '/api/agree_terms/', yourConfig)

        if(response.data.status == "success"){
            this.setState({
                modalVisibility: false
            })
            localStorage.setItem("termsAgreed", "true");
            window.location.reload();
        }
    }

    render(){
        let segments = localStorage.getItem('segments')

        return(
            <Modal
                centered
                className="terms-modal"
                width={isMobile ? '90%' : "512px"}
                // style={{top: isMobile ? 100 : 50}}
                visible={this.state.modalVisibility}
                closable={false}
                footer="">
                    <div>
                        <img src={TermsImg} style={{width: '100%'}} alt="modal-logo"/>
                        <div className='terms-modal-header top-32 liveColor'>
                            Terms and Policy Update
                        </div>
                        <div className="terms-sub-text top-16 liveColor">
                            We have updated our <a className={`text-${segments} wc-header`} href="/terms_and_privacy">Terms and Conditions</a> and/or <a className={`text-${segments} wc-header`} href="/terms_and_privacy">Privacy Policy</a>. 
                            To continue using BUx we just need you to confirm that you understand and agree to it.
                        </div>
                        {/* <div className="terms-sub-text top-16 liveColor">
                            Here is the updated <a className={`text-${segments} wc-header`} href="/terms_and_privacy">Terms and Conditions</a> and <a className={`text-${segments} wc-header`} href="/terms_and_privacy">Privacy Policy</a>
                        </div> */}
                        
                        <div align='center'>
                            <button data-test="agree" 
                                    className={`btn--${segments} btn-height top-40 btn-full-width`}
                                    // style={Style.buttonStyle} 
                                    onClick={()=>this.agree()}>
                                        I Agree and Continue
                            </button>
                            <div className="top-20">
                                <a className="grey-color normalTextStyle"
                                href
                                data-test="decline" 
                                onClick={()=>Handle401()}>
                                    Log out
                                </a>
                            </div>
                            <div className="smallTextStyle top-40 liveColor">
                                Have some concerns? Feel free to reach out to us at 
                                <span className="left-4">
                                    <a className={`text-${segments}`} 
                                    href="mailto:support@bux.ph">
                                        support@bux.ph
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
            </Modal>
        )
    }
    
}

export default TermsModal