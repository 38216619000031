import React, { useState } from "react";
import {
  Form,
  Card,
  Switch,
  Typography,
  Select,
  Button,
  notification,
} from "antd";
import { updateAdminBankTransferSettings } from "../api/update-admin-bank-transfer-settings";
import { useRestrictedPayoutBanks } from "../../../../../../components/multiwallet/components/master_wallet_page/create_subwallet/api";

const { Option } = Select;
const { Text } = Typography;

function PayoutSettings({ form, data }) {
  const [restrictedPayout, setRestrictedPayout] = useState(
    data.transfer_settings.is_payout_restricted,
  );
  const [selectedBank, setSelectedBank] = useState(null);
  const [showButtons, setShowButtons] = useState(false);

  const { data: restrictedPayoutBanksData } = useRestrictedPayoutBanks();

  const handleSwitchChange = async (checked) => {
    if (data.transfer_settings.is_payout_restricted) {
      try {
        const response = await updateAdminBankTransferSettings(data.id, {
          transfer_settings: {
            is_payout_restricted: checked,
          },
        });

        setShowButtons(false);
        notification.success({
          message: "Success",
          description: response.message,
        });
      } catch (error) {
        console.error("Error:", error);
        notification.error({
          message: "Failed",
          description: `${error.message}. Please try again later.`,
        });
      }
    }

    setRestrictedPayout(checked);
    setSelectedBank(null);
    setShowButtons(false);
  };

  const handleBankChange = (value) => {
    setSelectedBank(value);
    setShowButtons(true);
  };

  const handleCancel = () => {
    setSelectedBank(null);
    setShowButtons(false);
    if (data && data.transfer_settings.is_payout_restricted) {
      setRestrictedPayout(true);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await updateAdminBankTransferSettings(data.id, {
        transfer_settings: {
          is_payout_restricted: restrictedPayout,
          bank_code: selectedBank,
        },
      });

      setShowButtons(false);
      notification.success({
        message: "Success",
        description: response.message,
      });
    } catch (error) {
      console.error("Error:", error);
      notification.error({
        message: "Failed",
        description: `${error.message}. Please try again later.`,
      });
    }
  };

  return (
    <Card>
      <Form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text style={{ fontSize: "16px" }}>
              Restricted Payout is {restrictedPayout ? "enabled" : "disabled"}
            </Text>
            <Text type="secondary">
              When restricted payout is enabled, the merchant can only send
              payout to specified bank selected below.
            </Text>
          </div>
          <Switch checked={restrictedPayout} onChange={handleSwitchChange} />
        </div>
        {restrictedPayout && (
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Form.Item
              label="Choose the bank to restrict account to"
              style={{ margin: 0 }}
            >
              {form.getFieldDecorator("restricted_banks", {
                initialValue:
                  (data.transfer_settings.restricted_banks !== null &&
                    data.transfer_settings.restricted_banks[0]) ||
                  null,
                rules: [{ required: true, message: "Please select bank!" }],
              })(
                <Select onChange={handleBankChange}>
                  {restrictedPayoutBanksData &&
                    restrictedPayoutBanksData.banks.map((bank) => (
                      <Option value={bank.code}>{bank.bank_name}</Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
          </div>
        )}
      </Form>

      {showButtons && (
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "1em" }}
        >
          <Form.Item style={{ margin: 0 }}>
            <Button size="large" onClick={handleCancel}>
              Cancel
            </Button>
          </Form.Item>

          <Form.Item style={{ margin: 0 }}>
            <Button size="large" type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Form.Item>
        </div>
      )}
    </Card>
  );
}

export default Form.create({ name: "edit_payout_settings" })(PayoutSettings);
