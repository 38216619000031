import React from 'react';
import axios from 'axios';
import { Modal, Spin, Button, DatePicker, Icon, Table, Alert } from 'antd';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { history } from '../../../../../store/history';

const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
}
  
const UserHistoryModal = props => {

    const ButtonGroup = Button.Group;

    const {
        openModal, setOpenModal, data, setShowCredsModal, 
        setSurveyModalVisible, setSurveyAnswers
    } = props;

    let yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }

    const resetUserHistoryDate = () => {
        setStDate('');
        setEnDate('');
        setUserStats(null)
    }

    const orders_status = [
        'Paid', 'Pending', 'Cancelled', 'Expired'
    ]

    const payout_status = [
        'All', 'Success', 'Pending', 'Failed'
    ]

    const [user_balance, setUserBalance] = React.useState("");
    const [user_businessname, setUserBusinessName] = React.useState("");
    const [user_payouts, setUserPayouts] = React.useState([]);
    const [user_paid, setUserPaid] = React.useState([]);
    const [totalPaid, setTotalPaid]  = React.useState(0);
    const [user_pending, setUserPending] = React.useState([]);
    const [totalPending, setTotalPending] = React.useState(0)
    const [user_cancelled, setUserCancelled] = React.useState([]);
    const [user_expired, setUserExpired] = React.useState([]);
    const [userTranxFee, setUserTranxFee] = React.useState(false);
    const [userStats, setUserStats] = React.useState(null);

    const [stDate, setStDate] = React.useState("");
    const [enDate, setEnDate] = React.useState("");
    const [active_order_status, setOrderStatus] = React.useState("Paid");
    const [active_payout_status, setPayoutStatus] = React.useState("All");

    const footer = () => {
        return(
            <table>
                <tr>
                  <td className="table-format">Total Payout: {renderPayoutFields(active_payout_status)[0].length}</td>
                  <td className="table-format">Total Payout Amount: PHP {renderPayoutFields(active_payout_status)[1]}</td>
                </tr>
            </table>
        )
    }

    const renderPayoutFields = (status) => {

        let status_filtered = [];
        let total_amount = 0;
        if(status != "All"){
          status_filtered = user_payouts.filter(single_payout => single_payout.status.toLowerCase().includes(status.toLowerCase()))
          
        }else{
          status_filtered = user_payouts;
        }
  
        //remove PHP
        let filtered_data = status_filtered.map((item) => item.amount.slice(3))
        //remove commas in amount
        let removeCommas = filtered_data.map((item) => item.split(',').join('')),
          //string to number
          amount = removeCommas.map(Number)
  
        //sum of amount
        total_amount = amount.reduce((a,b) => a + b, 0)
        
        //thousand seperator
        let num_parts = parseFloat(total_amount).toFixed(2).toString().split('.')
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        
        return [status_filtered, num_parts.join(".")]
    }

    const payout_columns = [
        {
            title: 'Destination',
            dataIndex: 'account_number',
            width: 230,
            render: (text, record) => {
                return <div>
                      <a className="smallBoldStyle" href>{record.account_number} </a>
                    <div className="text-with-wrap">{record.bank}<br/>{record.account_name}</div>
                </div>
            }
        },
        {
            title: 'Date',
            dataIndex: 'date_paid',
            render: text => {
                return <span className="normalTextStyle">{text}</span>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: text => {
        
                return <Alert type={text.includes("Failed") ? "error" :  text.includes("Pending") ? "warning" : "success" } message={text} className="normalTextStyle" />
            }
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            render: text => {
                return <span className="normalTextStyle">{text}</span>
            }
        },
        {
            title: 'Amount(P)',
            dataIndex: 'amount',
            render: text => {
                return <span className="normalTextStyle">{text}</span>
            }
        },
    ];

    const paid_columns = [
        {
            title: 'Reference no.',
            dataIndex: 'code',
            render: (text, record) => {
                return <div onClick={() => history.push(`/order_details/`+record.uid+`/`)}>
                        <a className="smallBoldStyle" href>{record.code} </a>
                        <div className="text-with-wrap">{record.description}</div>
                        </div>
            }
        },
        {
            title: 'Date paid',
            dataIndex: 'date_paid',
            render: text => {
                return <span className="normalTextStyle">{text}</span>
            }
        },
        {
            title: 'Terminal',
            dataIndex: 'terminal',
            render: text => {
                return <span className="normalTextStyle">{text}</span>
            }
        },
        {
            title: 'Amount(P)',
            dataIndex: 'amount',
            render: text => {
                return <span className="normalTextStyle">{text}</span>
            }
        }
      ];

    const pending_columns = [
        {
            title: 'Reference no.',
            dataIndex: 'code',
            render: (text, record) => {
                return <div>
                        <a className="smallBoldStyle" href>{record.code}</a>
                        <div className="text-with-wrap">{record.description}</div>
                    </div>
            }
        },
        {
            title: 'Date generated',
            dataIndex: 'timestamp',
            render: text => {
                return <span className="normalTextStyle">{text}</span>
            }
        },
        {
            title: 'Expires after',
            dataIndex: 'expire_at',
            render: text => {
                return <span className="normalTextStyle">{text}</span>
            }
        },
        {
            title: 'Amount(P)',
            dataIndex: 'amount',
            render: text => {
                return <span className="normalTextStyle">{text}</span>
            }
        }
        
    ];

    const cancelled_columns = [
        {
          title: 'Reference no.',
          dataIndex: 'code',
          render: (text, record) => {
            return <div onClick={() => history.push(`/order_details/`+record.uid+`/`)}>
                    <a className="smallBoldStyle" href>{record.code}</a>
                    <div className="text-with-wrap">{record.description}</div>
                    </div>
          }
        },
        {
            title: 'Date cancelled',
            dataIndex: 'cancelled_at',
            render: text => {
                return <span className="normalTextStyle">{text}</span>
            }
        },
        {
            title: 'Amount (P)',
            dataIndex: 'amount',
            render: text => {
                return <span className="normalTextStyle">{text}</span>
            }
        },
        {
            title: 'Reason',
            dataIndex: 'remarks',
            render: text => {
                return <span className="normalTextStyle">{text}</span>
            }
        }
    ];
    
    const expired_columns = [
        {
          title: 'Reference no.',
          dataIndex: 'code',
          render: (text, record) => {
            return <div onClick={() => history.push(`/order_details/`+record.uid+`/`)}>
                        <a className="smallBoldStyle" href>{record.code}</a>
                        <div className="text-with-wrap">{record.description}</div>
                    </div>
          }
        },
        {
          title: 'Date expired',
          dataIndex: 'expire_at',
          render: text => {
                return <span className="normalTextStyle">{text}</span>
          }
        },
        {
          title: 'Amount (P)',
          dataIndex: 'amount',
          render: text => {
                return <span className="normalTextStyle">{text}</span>
          }
        },
    ];
    
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const qrLink = REACT_APP_BASE_URL+'/'+data.trade_name;

    const fetchHistory = async (id) => {
        let response = await axios.get(
            process.env.REACT_APP_API_URL + '/api/'+id+'/balance/',yourConfig
        );
      
        setUserBalance(response.data.balance)
        setUserBusinessName(response.data.business_name)
      
        let response_p = await axios.get(
            process.env.REACT_APP_API_URL + '/api/'+id+'/payouts/',yourConfig
        );
        setUserPayouts(response_p.data)
    
        let response_o = await axios.get(
            process.env.REACT_APP_API_URL + '/api/'+id+'/orders/?type=Paid',yourConfig
        );
    
        setUserPaid(response_o.data)
        setTotalPaid(response_o.data.length)
    
        response_o = await axios.get(
            process.env.REACT_APP_API_URL + '/api/'+id+'/orders/?type=Pending',yourConfig
        );
    
        setUserPending(response_o.data)
        setTotalPending(response_o.data.length)
    
        response_o = await axios.get(
            process.env.REACT_APP_API_URL + '/api/'+id+'/orders/?type=Cancelled',yourConfig
        );
    
        setUserCancelled(response_o.data)
    
        response_o = await axios.get(
            process.env.REACT_APP_API_URL + '/api/'+id+'/orders/?type=Expired',yourConfig
        );
        setUserExpired(response_o.data)
    
        const res = await axios.get(
            process.env.REACT_APP_API_URL + '/api/corporate/settings/'+id+'/',yourConfig
        );
        setUserTranxFee(res.data.shoulder_enabled);
    
        const res2 = await axios.get(
            process.env.REACT_APP_API_URL + '/api/'+id+'/user_history_stats/' ,yourConfig
        );
        if(res2 && res2.data && res2.data.status === 'success') {
        setUserStats(res2.data.data);
        }
        
        const response_answers = await axios.get(
            process.env.REACT_APP_API_URL + '/api/'+id+'/enterprise_survey_answer/' ,yourConfig
        )
        if(response_answers && response_answers.data && response_answers.data.status === 'success') {
        setSurveyAnswers(response_answers.data.data);
        }
    }

    const fetchUserOrders = async (id, st, en) => {
        if(st == '' || en == ''){
          return;
        }
       
        setUserPaid([]);
        setUserPending([]);
        setUserCancelled([]);
        setUserExpired([]);
        setUserPayouts([]);
        
        let response_p = await axios.get(
          process.env.REACT_APP_API_URL + '/api/'+id+'/payouts/',yourConfig
        );
        setUserPayouts(response_p.data)
    
        let response_o = await axios.get(
          process.env.REACT_APP_API_URL + '/api/'+id+'/orders/?type=Paid&start='+st+'&end='+en,yourConfig
        );
        console.log(response_o.data)
        setUserPaid(response_o.data)
        setTotalPaid(response_o.data.length)
    
        response_o = await axios.get(
          process.env.REACT_APP_API_URL + '/api/'+id+'/orders/?type=Pending&start='+st+'&end='+en,yourConfig
        );
        console.log(response_o.data)
        setUserPending(response_o.data)
        setTotalPending(response_o.data.length)
    
        response_o = await axios.get(
          process.env.REACT_APP_API_URL + '/api/'+id+'/orders/?type=Cancelled&start='+st+'&end='+en,yourConfig
        );
    
        setUserCancelled(response_o.data)
    
        response_o = await axios.get(
          process.env.REACT_APP_API_URL + '/api/'+id+'/orders/?type=Expired&start='+st+'&end='+en,yourConfig
        );
        setUserExpired(response_o.data)
        
        const res2 = await axios.get(
          process.env.REACT_APP_API_URL + '/api/'+id+'/user_history_stats/?start='+st+'&end='+en ,yourConfig
        );
        if(res2 && res2.data && res2.data.status === 'success') {
          setUserStats(res2.data.data);
        }
    }

    React.useEffect(() => {
        fetchHistory(data.id)
    }, [])

    return(
        <Modal
            title="User History"
            visible={openModal}
            width={800}
            onCancel={()=>{ setOpenModal(false); resetUserHistoryDate()}}
            footer={null}
            destroyOnClose
          >
            <Grid container>
                <Grid item lg={12} sm={12} className="bottom-16">
                    <b>BUx Merchant ID: </b> {data.bux_merchant_id}
                </Grid>
                <Grid item lg={6} sm={12}>
                    <b>Full Name: </b> {data.full_name}
                </Grid>
                <Grid item lg={6} sm={12}>
                    <b>Email: </b> {data.email}
                </Grid>
            </Grid>

            <Grid container className="top-16">
                <Grid item lg={6} sm={12}>
                    <b>{data.user_role === "Corporation"? 'KYB Level:' : 'KYC Level:'} </b> {data.eon_level}
                </Grid>
                <Grid item lg={6} sm={12}>
                    <b>KYC Step: </b> {data.kyc_level}
                </Grid>
            </Grid>

            <Grid container className="top-16">
                <Grid item lg={6} sm={12}>
                    <b>Balance: </b> { user_balance ? user_balance : <Spin size="small" className="left-16" />}
                </Grid>
                <Grid item lg={6} sm={12}>
                    <b>{ data.user_role === "Corporation" ? 'Trade Name' : 'Business Name'}: </b> 
                        { data.user_role === "Corporation" && data.trade_name ? data.trade_name 
                            : 
                            data.user_role === "Corporation" && !data.trade_name ? 'No Trade Name' 
                            : 
                            user_businessname}
                </Grid>
            </Grid>

            <Grid container className="top-16">
                <Grid item lg={6} sm={12}>
                    <span className="smallBoldStyle">
                        Transaction Fee: 
                    <span className="smallSemiBoldStyle">
                        {userTranxFee? ' On' : ' Off'}
                    </span></span>
                </Grid>
                <Grid item lg={6} sm={12}>
                    <span className="smallBoldStyle">
                        Account: 
                    <span className="smallSemiBoldStyle">
                        {data.key_account ? ' Key' : ' Non-Key'}
                    </span></span>
                </Grid>
            </Grid>

            <Grid container className="top-16">
              <Grid item lg={6} sm={12} className="with-link">
                <span className="smallBoldStyle" >QR Checkout Page: 
                  <span>
                    {" "}{data.trade_name ? 
                        <a href={qrLink} target='_blank' 
                        className="smallSemiBoldStyle link-color">
                            {qrLink}
                        </a> 
                        : 
                        <span className="smallSemiBoldStyle">
                            No QR Link
                        </span>}
                  </span>
                </span>
              </Grid>
              <Grid item lg={6} sm={12}>
                <span className="smallBoldStyle">
                    Integration Credentials: 
                <span className="smallSemiBoldStyle">
                    <Button onClick={() => setShowCredsModal(true)} 
                        type='link' 
                        className="smallBoldStyle link-color">
                            View
                    </Button>
                </span></span>
              </Grid>
            </Grid>

            <Grid container className="top-16">
                <Grid item lg={6} sm={12}>
                    <span className="smallBoldStyle">
                        Net Revenue: 
                    <span className="smallSemiBoldStyle">
                        {userStats && userStats.net_revenue? userStats.net_revenue : 0}
                    </span></span>
                </Grid>
                <Grid item lg={6} sm={12}>
                    <span className="smallBoldStyle">
                        Gross Revenue: 
                    <span className="semiBoldStyle">
                        {userStats && userStats.gross_revenue ? userStats.gross_revenue : 0}
                    </span></span>
                </Grid>
            </Grid>

            <Grid container className="top-16">
                <Grid item lg={6} sm={12}>
                    <span className="smallBoldStyle">
                        Average Ticket Size: 
                    <span className="smallSemiBoldStyle">
                        {userStats && userStats.ave_ticket_size ? userStats.ave_ticket_size : 0}
                    </span></span>
                </Grid>
                <Grid item lg={6} sm={12}>
                    <span className="smallBoldStyle">
                        Average Transaction per Day: 
                    <span className="smallSemiBoldStyle">
                        {userStats && userStats.daily_ave? userStats.daily_ave : 0}
                    </span></span>
                </Grid>
            </Grid>

            <Grid container className="top-16">
              <Grid item lg={6} sm={12}>
                <span className="smallBoldStyle">
                    Average Transaction per Month: 
                <span classNam="smallSemiBoldStyle">
                    {userStats && userStats.monthly_ave? userStats.monthly_ave : 0}
                </span></span>
              </Grid>
              <Grid item lg={6} sm={12}>
                <span className="smallBoldStyle">
                    Enterprise Survey Answers: 
                <span className="smallSemiBoldStyle">
                  <Button onClick={() => setSurveyModalVisible(true)} 
                  type='link' 
                  className="smallBoldStyle link-color">View</Button>
                </span></span>
              </Grid>
            </Grid>

            <div className="bottom-4 liveColor top-16">
                <b>Channel Percentage</b>
            </div> 

            <Grid container className="top-16">
                <Grid item lg={6} sm={12}>
                    <span className="smallBoldStyle" >
                        Otc: <span className="smallSemiBoldStyle">
                            {userStats && userStats.channel_percentage[0] ? userStats.channel_percentage[0].count : '0%'}
                            </span>
                    </span>
                </Grid>
                <Grid item lg={6} sm={12}>
                    <span className="smallBoldStyle" >
                        Web: <span className="smallSemiBoldSyle">
                            {userStats && userStats.channel_percentage[1] ? userStats.channel_percentage[1].count : '0%'}
                            </span>
                    </span>
                </Grid>
            </Grid>

            <Grid container className="top-16">
                <Grid item lg={6} sm={12}>
                    <span className="smallBoldStyle" >Ewallet: <span className="smallSemiBoldStyle">{userStats && userStats.channel_percentage[2]? userStats.channel_percentage[2].count : '0%'}</span></span>
                </Grid>
                <Grid item lg={6} sm={12}>
                    <span className="smallBoldStyle" >CC: <span className="smallSemiBoldStyle">{userStats && userStats.channel_percentage[3]? userStats.channel_percentage[3].count : '0%'}</span></span>
                </Grid>
            </Grid>

            <Grid container className="top-16">
                <Grid item lg={6} sm={12}>
                    <span className="smallBoldStyle" >BillEase: <span className="smallSemiBoldStyle">{userStats && userStats.channel_percentage[4]? userStats.channel_percentage[4].count : '0%'}</span></span>
                </Grid>
            </Grid>

            <div className="bottom-4 liveColor top-16">
                <b>Orders</b>
            </div> 

            <div>
                <DatePicker
                format='MM-DD-YYYY' 
                disabledDate={disabledDate} 
                className="filter-z-index"
                size="large" 
                picker="month"
                onChange={ (dates, dateString) => { setStDate(dateString); 
                        fetchUserOrders(data.id, dateString, enDate)}} 
                placeholder='Start date'
                suffixIcon={<Icon type="calendar"></Icon>}
                />
                <span className="padding-8">-</span> 
                <DatePicker
                className="right-8 filter-z-index"
                format='MM-DD-YYYY'
                disabledDate={disabledDate} 
                size="large" 
                picker="month" 
                onChange={ (dates, dateString) => { setEnDate(dateString); fetchUserOrders(data.id, stDate, dateString)}} 
                placeholder='End date'
                />
            </div>

            <div className="bottom-20 twoCol top-16">
                <ButtonGroup>
                {
                  orders_status.map((item) => {
                    return <Button
                      key={item.toLocaleLowerCase()}
                      onClick={() => setOrderStatus(item)}
                      className={`normalTextStyle ${item === active_order_status ? "selected-value" : ''}`}
                      >{item}</Button>
                  })
                }
                </ButtonGroup>
            </div>

            <div className="bottom-20 flex-center">
                <div className="new-summary">
                    <div className="liveColor right-4">No. of Paid Transactions:</div> 
                    <div className="wallet-text-color linkText">{totalPaid}</div>
                </div>
                <div className="new-summary">
                    <div className="liveColor right-4">No. of Pending Payment Links:</div> 
                    <div className="wallet-text-color linkText">{totalPending}</div>
                </div>
            </div>

            { 
                active_order_status == "Paid" ?

                    <Table
                      hidden={active_order_status=="Paid"}
                      rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                      columns={paid_columns}
                      dataSource={user_paid} />

                :
                    
                active_order_status== "Pending" ? 

                    <Table
                      rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                      columns={pending_columns}
                      dataSource={user_pending} />

                :

                active_order_status == "Cancelled" ?
                    
                    <Table
                      rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                      columns={cancelled_columns}
                      dataSource={user_cancelled} />

                :

                    <Table
                      rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                      columns={expired_columns}
                      dataSource={user_expired} />

            } 

            <div className="bottom-4 liveColor top-16">
                <b>Payouts</b>
            </div> 

            <div className="bottom-20 twoCol top-16">
                <ButtonGroup>
                {
                    payout_status.map((item) => {
                        return <Button
                        key={item.toLocaleLowerCase()}
                        onClick={() => setPayoutStatus(item)}
                        className={`normalTextStyle ${item === active_payout_status ? "selected-value" : ''}`}
                        >{item}</Button>
                    })
                }
                </ButtonGroup>
            </div>

            <Table
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                columns={payout_columns}
                dataSource={renderPayoutFields(active_payout_status)[0]}
                footer={() => footer()}/>

        </Modal>
    )
}

export default UserHistoryModal;