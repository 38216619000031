import React from 'react';
import ButtonGroup from 'antd/lib/button/button-group';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import fraudActions from '../fraudActions';
import Table from './table';
import AddNewModal from './addNewModal';
import UploadCsvModal from './uploadCsvModal';
import ValidationModal from './validationModal';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import fraudServices from '../fraudServices';
import { jsonToCSV } from 'react-papaparse';
import { Button, DatePicker, Dropdown, Icon, Input, Menu, message } from 'antd';

const ButtonTab = ({rootStyle}) => {
  const dispatch = useDispatch();
  const selected = useSelector(state => state.fraud.blcklstBtnSelected);
  
  const status = ['Individual Account', 'Enterprise Account'];
  
  const handleClick = (event) => {
    dispatch(fraudActions.setBlcklstSelectedBtn(event.target.value));
    // setSelectedBtn(event.target.value);
  }
  
  return (
    <div style={styles.btnTabContainer}>
      <ButtonGroup>
        {
          status.map((btn, i) => {
            return (
              <Button
                key={btn}
                value={btn}
                onClick={handleClick}
                style={{...styles.btnGroup, 
                  color: selected === btn? '#0D3D76' : '#909196', 
                  backgroundColor: '#FFF',
                  borderBottom: selected === btn? '4px solid #0D3D76' : '4px solid #909196' }}
                  >
                  {btn}
              </Button>
            )
          })
        }
      </ButtonGroup>
    </div>
  )
}


const MobileNumber = () => {
  const [dateRange, setDateRange] = React.useState({startDate: moment().format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY')});
  const [exportStyle, setExportStyle] = React.useState({ color: '#1DD28B', backgroundColor: 'transparent'});
  const [importStyle, setImportStyle] = React.useState({ color: '#1DD28B', backgroundColor: 'transparent'});
  const selected = useSelector(state => state.fraud.blcklstBtnSelected);
  const tableData = useSelector(state => state.fraud.tableData);
  const modalData = useSelector(state => state.fraud.checkModalData);
  const domainCSV = useSelector(state => state.fraud.fraudCSV);
  const [addNewShow, setAddNewModalShow] = React.useState(false);
  const [dateSearch, setDateSearch] = React.useState(false);
  const [totalLength, setTotalLength] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchVal, setSearchVal] = React.useState(null);
  const [uploadCsvShow, setUploadCsvShow] = React.useState(false);
  const [validationModalShow, setValidationModalShow] = React.useState(false);
  const [tableSort, setTableSort] = React.useState('newest');
  const dispatch = useDispatch();

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }

  const rangeSelect = (dates, dateString, id) => {
    setDateRange({...dateRange, [id]: moment(dateString).format('MM-DD-YYYY')});
    if(id==='startDate') {
      getBlacklist('', 1, 'mobile', tableSort, moment(dateString).format('MM-DD-YYYY'), dateRange['endDate']);
    } else {
      getBlacklist('', 1, 'mobile', tableSort, dateRange['startDate'], moment(dateString).format('MM-DD-YYYY'));
    }
    setDateSearch(true);
  };

  const exportCsv = async () => {
    let arr = new Array;
    let data;
    let res;
    setIsLoading(true);
    try {
      if(dateSearch){
        res = await fraudServices.getBlacklist('', 1, 'mobile', tableSort, dateRange.startDate, dateRange.endDate, false);
      } else {
        res = await fraudServices.getBlacklist('', 1, 'mobile', tableSort, '', '', true);
      }
      if(res&&res.results){
        data = res.results;
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }

    data&&data.map((item,i) => {
        arr.push({
          "Blacklist Code": item.mobile_code,
          "Mobile Number": item.mobile,
          "Date Added": item.created_at,
          "Added By": item.added_by,
        })
    });

    const csv = jsonToCSV(arr);
    const blob = new Blob([csv]);
		const a = window.document.createElement("a");
	    a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
	    a.download = 'mobile_number_blacklisted.csv';
	    document.body.appendChild(a);
	    a.click();
	    document.body.removeChild(a);
  }

  const handleMouseLeave = (event) => {
    if(event.target.name==='exportBtn'){
         setExportStyle({
           color: '#1DD28B',
           backgroundColor: 'transparent'
         });
       } else {
         setImportStyle({
           color: '#1DD28B',
           backgroundColor: 'transparent'
         });
       }
     }
   
  const handleMouseOver = (event) => {
    if(event.target.name==='exportBtn'){
      setExportStyle({
        color: '#FFF',
        backgroundColor: '#1DD28B'
      });
    } else {
      setImportStyle({
        color: '#FFF',
        backgroundColor: '#1DD28B'
      });
    }
  }

  const handleSearch = (e) => {
    getBlacklist(e.target.value, '');
    resetProps();
  }

  const setDateFilter = (date) => {
    setDateRange({startDate: date?moment(date).format('MM-DD-YYYY'):moment().format('MM-DD-YYYY')}, {endDate: moment().format('MM-DD-YYYY')});
  }

  const errorPrompt = (msg) => {
    message.error(msg);
  };

  const getBlacklist = async (search, page, type, tableSort, start, end, dump) => {
    setIsLoading(true);
    try {
      const res = await fraudServices.getBlacklist(search?search:'', page?page:1, type?type:'mobile', tableSort&&tableSort, start, end, dump);
      if(res&&res.results){
        dispatch(fraudActions.setTableData(res.results));
        setTotalLength(res.total);
        dispatch(fraudActions.setTablePage(1));
      } else {
        errorPrompt(res);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      errorPrompt('Please try again');
    }
  }

  const handleSortChange = ({key}) => {
    setTableSort(key)
    getBlacklist('', 1, 'mobile', key);
    resetProps();
  }

  const deletedSuccess = () => {
    getBlacklist('', 1, 'mobile', tableSort);
  }

  const resetProps = () => {
    setDateSearch(false);
    setDateRange({startDate: moment().format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY')});
  }

  React.useEffect(()=> {
    dispatch(fraudActions.setTableData([]));
    getBlacklist('', 1, 'mobile', tableSort);
    resetProps();
  },[selected])

  const menu = (
    <Menu onClick={handleSortChange}>
      <Menu.Item key="newest">
        Newest
      </Menu.Item>
      <Menu.Item key="oldest">
        Oldest
      </Menu.Item>
    </Menu>
  )

  return (
    <div style={styles.root}>
      <div style={styles.btnMain}>
        <div>
          <Button 
            onClick={exportCsv}
            style={{...styles.dload,
              ...exportStyle,
            }}
              name='exportBtn'
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              >
              Export CSV
          </Button>
          <Button 
            onClick={()=>setUploadCsvShow(true)}
            style={{...styles.dload,
              ...importStyle,
            }}
              name='importBtn'
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              >
              Import CSV
          </Button>
          <Button 
            onClick={()=>setAddNewModalShow(true)}
            style={{...styles.import,
              color: '#FFF',
              backgroundColor: '#1DD28B',
            }}>
            Add New
          </Button>   
        </div>
        <UploadCsvModal visible={uploadCsvShow} closeModal={()=> setUploadCsvShow(false)} onSuccess={()=> {setUploadCsvShow(false);setValidationModalShow(true);}} />
        <AddNewModal visible={addNewShow} closeModal={()=> setAddNewModalShow(false)} onSuccess={()=> {setAddNewModalShow(false);getBlacklist('', 1, 'mobile');}} />
        <ValidationModal visible={validationModalShow} closeModal={()=> {setValidationModalShow(false);}} data={modalData} csv={domainCSV} onSuccess={()=> {setValidationModalShow(false);getBlacklist('', 1, 'mobile');}} />
      </div>
      <div>
        <div style={styles.dateBtn}>
          <Dropdown overlay={menu} trigger={['click']}>
            <a style={styles.dropdownItems} >
              {tableSort.charAt(0).toUpperCase() + tableSort.substring(1)}
              <ExpandMoreIcon style={styles.dropdownArror} />
            </a>
          </Dropdown>
          <DatePicker 
            format='MM/DD/YYYY' 
            disabledDate={disabledDate} 
            defaultValue={moment()} 
            value={dateRange&&moment(dateRange.startDate)} 
            size="large" picker="month" 
            onChange={ (dates, dateString) => rangeSelect(dates, dateString, 'startDate')} 
            placeholder='Start date' 
            style={styles.datePic} 
            suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{fontSize: 22}} /></div>}
            allowClear={false}/>
          <span style={styles.span}>-</span>
          <DatePicker 
            format='MM/DD/YYYY' 
            disabledDate={disabledDate} 
            defaultValue={moment()}
            value={dateRange&&moment(dateRange.endDate)} 
            size="large" 
            picker="month" 
            onChange={ (dates, dateString) => rangeSelect(dates, dateString, 'endDate')} 
            placeholder='End date' 
            style={styles.datePic}
            suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{fontSize: 22}} /></div>}
            allowClear={false}/>
          <Input
            placeholder="Search"
            style={{ width: '200px', height: '40px', borderRadius:'4px' }}
            suffix={<Icon type="search" style={{fontSize:'18px'}}/>}
            onChange={handleSearch}
          />
        </div>
        <Table data={tableData} 
          onDeleted={deletedSuccess} 
          isLoading={isLoading} 
          length={totalLength}
          sort={tableSort}
        />
      </div>
    </div>
  )
}

const styles = {
  root: {
    padding: '0 42px'
  },
  body: {
    backgroundColor: '#FFF',
    padding: '0 20px'
  },
  btnGroup: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '20px 32px 24px 32px',
    borderColor: 'transparent'
  },
  btnMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: '30px',
    marginTop: '-42px'
  },
  datePic: {
    height: 40,
    fontSize: 14,
    color: '#2F3542',
    width: 178,
    padding: '0 8px 0 8px'
  },
  dateBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px 0 20px 0',
  },
  suffixIcon: {
    marginTop: '-12px', 
    marginRight: 12, 
    color: '#000'
  },
  dload: {
    fontSize: 16,
    fontWeight: '600',
    height: 40,
    borderRadius: 10,
    borderColor: '#1DD28B',
    marginRight: 8,
  },
  import: {
    fontSize: 16,
    fontWeight: '600',
    height: 40,
    borderRadius: 10,
    marginLeft: 8,
  },
  btnTabContainer: {
    backgroundColor: '#FFF',
    width: '100%',
    padding: '20px, 20px 0 20px',
    borderBottom: '1px solid #D1D5DD'
  },
  dropdownItems: {
    display: 'flex', 
    alignItems: 'center', 
    padding: '7px 4px 7px 16px',
    fontSize: 16, 
    color: '#2B2D33',
    border: '1px solid #D1D5DD',
    borderRadius: 5,
    backgroundColor: '#FFF'
  },
  dropdownArror: {
    color: '#2B2D33', 
    fontSize: 24, 
    margin: '0 4px'
  },
}

export default MobileNumber;