import { Card, DatePicker, Dropdown, Icon, message, Typography, Select } from 'antd';
import React from 'react';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { AngryColored, HappyColored, LoveColored, SadColored, SmileColored, SmileSelected } from '../../../static/images/rating_emoji';
import feedbackServices from './feedbackServices';
import moment from 'moment'
import { history } from '../../../store/history';
import { ExportToCsv } from 'export-to-csv';

const { Option } = Select;

const sampleCharData = [
    {
        rate: 1,
        feedback: 8,
    },
    {
        rate: 2,
        feedback: 10,
    },
    {
        rate: 3,
        feedback: 33,
    },
    {
        rate: 4,
        feedback: 89,
    },
    {
        rate: 5,
        feedback: 93,
    }
]

const UserFeedbackStats = (props) => {
    const [date, setDate] = React.useState(moment().format('YYYY-MM'));
    const [statsData, setStatsData] = React.useState(null);
    const [totalReviewer, setTotalReviewer] = React.useState(null);
    const [pickerMode, setPickerMode] = React.useState('');
    const [performanceFilter, setPerformanceFilter] = React.useState(null);
    const [performanceMoment, setPerformanceMoment] = React.useState(null);
    const [endOpen, setEndOpen] = React.useState(null)
    const [basisDate, setBasisDate] = React.useState(null);
    const [dateOpen, setDateOpen] = React.useState(false);

    const exportTable = async() => {
        try {
            const res = await feedbackServices.exportFeedbackReport();
            if (res && res.message) {
                message.success(res.message)
            }
            else {
                message.error('Error, try again')
            }
        } catch (error) {
            console.log(error);
            message.error('Error, try again')
        }
    };

    const getFeedbackStats = async (mode = null, date = '') => {
        try {
            const res = await feedbackServices.getFeedbackStats(mode ? mode : pickerMode, date);
            if (res && res.basis_date) {
                setStatsData(res);
                setTotalReviewer(res.total_reviewer);
                setBasisDate(res.basis_date);
            }
            else {
                message.error('Error, try again')
            }
        } catch (error) {
            console.log(error);
            message.error('Error, try again')
        }
    }

    React.useEffect(() => {
        getFeedbackStats(pickerMode, date);
    }, [])

    const renderColor = (rate) => {
        switch (rate) {
            case 0:
                return {
                    color: '#C0492C',
                    border: '12px solid #C0492C'
                };               
            case 1:
                return {
                    color: '#C0492C',
                    border: '12px solid #C0492C'
                };
            case 2:
                return {
                    color: '#EC7C30',
                    border: '12px solid #EC7C30'
                };
            case 3:
                return {
                    color: '#EBB83F',
                    border: '12px solid #EBB83F'
                };
            case 4:
                return {
                    color: '#3363BB',
                    border: '12px solid #3363BB'
                };
            case 5:
                return {
                    color: '#59C378',
                    border: '12px solid #59C378'
                };
            default:
                return {
                    color: '#59C378',
                    border: '12px solid #59C378'
                };
        }
    }

    const renderRatingIcon = (rate) => {
        switch (rate) {
            case 0:
                return AngryColored;
            case 1:
                return AngryColored;
            case 2:
                return SadColored;
            case 3:
                return SmileColored;
            case 4:
                return HappyColored;
            case 5:
                return LoveColored;
            default:
                return LoveColored;
        }
    }

    const returnRating = () => {
        if (statsData && statsData.overall_rating) {
            return parseInt(statsData.overall_rating);
        }
        return 1;
    }

    const arrangeArray = (data) => {
        let stats = []
        data && Object.keys(data).map((d, i) => {
            stats.push({
                rate: i + 1,
                feedback: data[i + 1]
            });
        })
        return stats;
    }

    const onDateChange = (date, dateString, mode) => {
        setDate(moment(dateString).format(pickerMode === 'Month' ? 'YYYY-MM' : 'YYYY'));
        getFeedbackStats(pickerMode, moment(dateString).format(pickerMode === 'Month' ? 'YYYY-MM' : 'YYYY'));
    }

    const onYearChange = (year) => {
        setDate(moment(year).format('YYYY'));
        getFeedbackStats(pickerMode, moment(year).format('YYYY'));
    }

    const onChangePickerMode = (mode) => {
        setPickerMode(mode);
        getFeedbackStats(mode, moment().format(mode === 'Month' ? 'YYYY-MM' : 'YYYY'));
        setDate(moment().format(mode === 'Month' ? 'YYYY-MM' : 'YYYY'))
        if (mode === 'Month') {
            //setPerformanceFilter(moment(value).format('MMM'))
        } else {
            //setPerformanceFilter(moment(value).format('YYYY'))
        }
    };

    const StatsCard = () => {
        return (
            <div style={{ width: '32%' }}>
                <Card
                    style={{ padding: '0', borderTop: renderColor(returnRating()).border, borderRadius: '4px', width: '100%' }}
                    bodyStyle={{ padding: '20px' }}
                >
                    <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <Typography style={{ ...styles.rateVal, color: renderColor(returnRating(returnRating())).color }}>{(statsData && statsData.overall_rating) || 0}</Typography>
                        <img alt='emoji' style={{ padding: '8px' }} src={renderRatingIcon(returnRating())} />
                    </div>
                    <Typography style={{ ...styles.label, display: 'inline-flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>Overall Ratings
                        <a onClick={() => history.push('/user_feedback_summary')} style={{color: '#254A76', position: 'absolute', right: '0'}} ><Icon type="right" style={{ marginLeft: '34%' }} /></a></Typography>
                </Card>
                <Card
                    style={{ padding: '0', borderTop: '12px solid #254A76', borderRadius: '4px', width: '100%', margin: '12px 0 12px 0' }}
                    bodyStyle={{ padding: '20px' }}
                >
                    <Typography style={styles.cardVal}>{(totalReviewer && totalReviewer.toLocaleString()) || 0}</Typography>
                    <Typography style={styles.label}>Total Reviewer ({(statsData && basisDate) || ''})</Typography>
                </Card>
                <div style={styles.flexBetween}>
                    <Card
                        style={{ padding: '0', borderTop: '12px solid #254A76', borderRadius: '4px', width: '48%' }}
                        bodyStyle={{ padding: '20px' }}
                    >
                        <Typography style={styles.cardVal}>{(statsData && statsData.desktop_count.toLocaleString()) || 0}</Typography>
                        <Typography style={styles.label}>Desktop</Typography>
                    </Card>
                    <Card
                        style={{ padding: '0', borderTop: '12px solid #254A76', borderRadius: '4px', width: '48%' }}
                        bodyStyle={{ padding: '20px' }}
                    >
                        <Typography style={styles.cardVal}>{(statsData && statsData.mobile_count.toLocaleString()) || 0}</Typography>
                        <Typography style={styles.label}>Mobile</Typography>
                    </Card>
                </div>
            </div>
        )
    }

    const FeedbackChart = () => {
        return (
            <div style={{ width: '66%', backgroundColor: '#FFF', padding: '16px' }}>
                <div style={styles.flexBetween}>
                    <Typography style={styles.chartTitle}>Performance</Typography>
                    <div>
                        {/* <Dropdown /> */}
                        <Select value={pickerMode} onChange={onChangePickerMode} style={{ width: '200px', paddingRight: '15px' }} size='large'>
                            <Option value="">All Time</Option>
                            <Option value="Month">By Month</Option>
                            <Option value="Year">By Year</Option>
                        </Select>
                        {
                            pickerMode === 'Month' ?
                                <DatePicker.MonthPicker
                                    format={pickerMode === 'Month' ? 'YYYY-MM' : 'YYYY'}
                                    onChange={(date, dateString) => onDateChange(date, dateString, pickerMode)}
                                    defaultValue={moment()}
                                    value={moment(date)}
                                    mode={pickerMode.toLowerCase()}
                                    size='large'
                                    placeholder={pickerMode === 'Month' ? 'Select month' : 'Select year'}
                                    style={styles.datePic}
                                    suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                                    allowClear={false}
                                />
                                :
                                <DatePicker
                                    format={pickerMode === 'Month' ? 'YYYY-MM' : 'YYYY'}
                                    onChange={(date, dateString) => onDateChange(date, dateString)}
                                    defaultValue={moment()}
                                    value={moment(date)}
                                    mode={'year'}
                                    size='large'
                                    placeholder={pickerMode === 'Month' ? 'Select month' : 'Select year'}
                                    style={styles.datePic}
                                    suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                                    allowClear={false}
                                    onPanelChange={((date) => setDateOpen(false) / onYearChange(date))}
                                    open={dateOpen}
                                    onFocus={() => setDateOpen(true)}
                                    onBlur={() => setDateOpen(false)}
                                />
                        }
                    </div>
                </div>
                <ResponsiveContainer width="100%" height={382}>
                    <BarChart
                        width={564}
                        height={'100%'}
                        data={(statsData && arrangeArray(statsData.performance)) || []}
                        margin={{
                            top: 20,
                            right: 20,
                            left: 5,
                            bottom: 20,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="rate" />
                        <YAxis dataKey={"feedback"} />
                        <Tooltip key={'feedback'} />
                        {/* <Legend /> */}
                        <Bar dataKey="feedback" fill="#F08A34" barSize={64} >
                            {
                                statsData && Object.keys(statsData.performance).map((entry, index) => (
                                    <Cell key={`cell-${index}`} opacity='.7' fill={renderColor(index + 1).color} />
                                ))
                            }
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        )
    }

    return (
        <div>
            <div style={{ ...styles.flexBetween, padding: '20px 0' }}>
                <Typography style={styles.title}>User's Feedback</Typography>
                <button className="btn--nil" onClick={() => exportTable()}> Export CSV</button>
            </div>
            <div style={styles.body}>
                <StatsCard />
                <FeedbackChart />
            </div>
        </div>
    )
}

const styles = {
    root: {

    },
    title: {
        color: '#2B2D32',
        fontWeight: '700',
        fontSize: '24px'
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    body: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    label: {
        color: 'rgba(43, 45, 50, 0.8)',
        fontSize: '12px',
        fontWeight: '500',
        textAlign: 'center',
        width: '100%',
    },
    rateVal: {
        color: '#41BD5C',
        fontSize: '48px',
        fontWeight: '700'
    },
    cardVal: {
        color: '#254A76',
        fontSize: '36px',
        fontWeight: '700',
        textAlign: 'center'
    },
    chartTitle: {
        color: '#2B2D32',
        fontSize: '18px',
        fontWeight: '700'
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 250,
        padding: '0 8px 0 8px'
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
}

export default UserFeedbackStats;