import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { Button, Table, Input, Icon, Menu, Dropdown, Modal, Radio, Select, DatePicker, Alert, message, Typography, Checkbox } from 'antd';
import {history} from '../../../store/history'
import axios from 'axios'
import {getOrders} from '../../orders/orderAction';
import {isMobile} from 'react-device-detect';
import '../../../static/css/antd.css'
import moment from 'moment';
import * as Style from '../../orders/orderStyle'
import MetaTag from '../../meta_tag/meta_tag'
import { ExportToCsv } from 'export-to-csv';
import {OTC,BANK,MOBILE} from '../../constants/constants'
import crypto from 'crypto';

const ButtonGroup = Button.Group;
const { Option, OptGroup } = Select;
let requestToken = ""

const mapStateToProps = (state) => {
  const { orders, loading, error, token } = state.orders;
  return {
    orders,
    loading,
    token,
    error,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    getOrders: bindActionCreators(getOrders, dispatch),
  };
};

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  return current && current < moment().endOf('day');
}


function disabledDate2(current) {
  return current && current > moment().endOf('day');
}


class ReverseRecon extends React.Component {

  componentWillMount(){
    let current_path = window.location.pathname

    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
        history.push('/login?next='+current_path)
    }

    else{
      if(isMobile)
      {
        if((localStorage.getItem("userType") != "AD")&&(localStorage.getItem("userType") != "OP")){
          history.push('/mobile/orders')
        }
        else{
          this.props.getOrders(this.generateToken(), this.state.title);
        }
      }
      else{
        if(localStorage.getItem("userType") == "PF"){
            history.push('/recon/7_eleven')
        }
        else{
          this.props.getOrders(this.generateToken(), this.state.title);
        }
      }
      
    }

  }
  state = { 
    title: 'Paid',
    cancelVisible: false,
    pickUpVisible: false,
    addPickUpModal: false,
    idToBeCancel: '',
    reason: 'Item/s no longer available',
    uid: '',
    searchValue: [],
    searchValueType: '',
    idToBeSched: [],
    showAlert: false,
    streetAddress: '',
    cityAddress: '',
    provinceAddress: '',
    postalCode: '',
    pickUpAddresses: [],
    pickUpAddressID: '',
    schedDate: '',
    startDate: '',
    endDate: '',
    userType: '',
    paymentMethod: []
  };

  generateToken = () => {
    let generatedRequestToken = crypto.randomBytes(16).toString('hex');
    requestToken = generatedRequestToken
    return generatedRequestToken
  }

  setSearchValue = (val) => {
    this.setState({
      searchValue: val,
      searchValueType: 'array'
    })

  }

  changeSearchVal = (val) => {
    this.setState({
      searchValue: val,
      searchValueType: ''
    })
  }


  showCancelModal = (id) => {
    this.setState({
      cancelVisible: !this.state.cancelVisible,
      idToBeCancel: id
    });
  };

  showPickUpModal = (value) => {
    this.setState({
      pickUpVisible: value,
    });
  };

  showAddPickUpModal = (value) => {
    this.setState({
      addPickUpModal: value,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      cancelVisible: false,
      pickUpVisible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      cancelVisible: false,
      pickUpVisible: false,
    });
  };

  handleCancelAddPick = e => {
    this.setState({
      addPickUpModal: false
    });
  }

  handleProvinceAddress(value){
    this.setState({
      provinceAddress: value
    })
  }

  handleCityAddress(value){
    this.setState({
      cityAddress: value
    })
  }

  handlePickUpAddress(value){
    this.setState({
      pickUpAddressID: value
    })
  }

  handleSchedDate(date, dateString){
    this.setState({
      schedDate: dateString
    })
  }

  handleUserType(value){
    this.setState({
      userType: value
    })
    this.renderSearch(this.state.searchValue, this.state.title, this.state.searchValueType, this.state.userType, this.state.paymentMethod)
  }

  handlePaymentMethod(value){

    this.setState({
      paymentMethod: value
    })
    
    this.renderSearch(this.state.searchValue, this.state.title, this.state.searchValueType, this.state.userType, value)
  }


  paid_columns = [
    localStorage.getItem("userType") == "AD" || localStorage.getItem("userType") == "OP" || localStorage.getItem("userType") == "PS" || localStorage.getItem("userType") == "FI"  ?
    {
      title: 'Trans ID',
      dataIndex: 'id',
      width: '10%',
      render: (text, record) => {
        return text
      }
    }
    :
    {},
    {
      title: 'Reference no.',
      dataIndex: 'code',
      width: localStorage.getItem("userType") == "AD" || localStorage.getItem("userType") == "OP" || localStorage.getItem("userType") == "PS" || localStorage.getItem("userType") == "FI"  ? '20%' : '20%',
      render: (text, record) => {
        const { history } = this.props;
        return text
      }
    },
    {
      title: 'Channel',
      dataIndex: 'channel_str',
      width: localStorage.getItem("userType") == "AD" || localStorage.getItem("userType") == "OP" || localStorage.getItem("userType") == "PS" || localStorage.getItem("userType") == "FI"  ? '10%' : '20%',
      render: text => {
        return <span style={this.dataStyle}>{text}</span>
      }
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: '12.5%',
      render: text => {
        return <span style={Style.dataStyle}>{text}</span>
      }
    },
    {
      title: 'Channel Fee',
      dataIndex: 'channel_fee',
      width: '12.5%',
      render: text => {
        return <span style={Style.dataStyle}>{parseFloat(text).toFixed(2)}</span>
      }
    },
    {
      title: 'BUx Receivable',
      dataIndex: 'seller_net',
      width: '12.5%',
      render: (text, record) => {
        return <span style={Style.dataStyle}>{parseFloat(record.seller_net + record.bux_fee).toFixed(2)}</span>
      }
    }
  ];
 
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let ids = []
      selectedRows.map((item) => {
        ids.push(item.id)
      });
      this.setState({
        idToBeSched: ids,
        showAlert: false
      });
      
    },
    getCheckboxProps: record => ({
      disabled: record.shipping_status != "Pending" && !record.shipping_ready, // Column configuration not to be checked
    })
  };


  onChange = e => {
    this.setState({
      reason: e.target.value,
    });
  };



  compareField(param, val, is_admin){
    if(is_admin){
      return param.toLowerCase().includes(val.toLowerCase())
    }else{
      return false
    }
  }

  rangeSelect(dates, dateString, id){
        this.setState({[id]: dateString});
        this.props.getOrders(this.generateToken(), this.state.title, dateString, dateString);
  }
  renderSearch(val, status, type, user_type, channels){
    const { orders } = this.props
    
    let userTypeFilteredOrders = []
    let paymentFilteredOrders = []
    let finalFilteredOrders = []

    let adminview = localStorage.getItem("userType") == "AD" || localStorage.getItem("userType") == "OP" || localStorage.getItem("userType") == "PS" || localStorage.getItem("userType") == "FI";
    
    if(user_type != "") {
      userTypeFilteredOrders = orders.filter(order => order.owner_type.toLowerCase().includes(user_type.toLowerCase()))
    }
    else{
      userTypeFilteredOrders = orders
    }

    if(channels != ""){

      paymentFilteredOrders = userTypeFilteredOrders.filter(userTypeFilteredOrder => userTypeFilteredOrder.channel_str != "" && userTypeFilteredOrder.channel_str != null ?
                                                            channels.includes(userTypeFilteredOrder.channel_str.toLowerCase()): false)
    }
    else{
      paymentFilteredOrders = userTypeFilteredOrders
    }
    
    if((val=="")||(val==null))
    {
      return paymentFilteredOrders
    }
    else{
        if(orders != null){
          if(status=="Paid"){
            finalFilteredOrders = paymentFilteredOrders.filter(paymentFilteredOrder => paymentFilteredOrder.code != null && paymentFilteredOrder.terminal != null ?
                                                    paymentFilteredOrder.code.toLowerCase().includes(val.toLowerCase()) || 
                                                    paymentFilteredOrder.description.toLowerCase().includes(val.toLowerCase()) || 
                                                    paymentFilteredOrder.amount.replace(",", "").includes(val.toLowerCase()) || 
                                                    paymentFilteredOrder.date_paid.toLowerCase().includes(val.toLowerCase())||
                                                    paymentFilteredOrder.date_paid.toLowerCase().includes(val.toLowerCase())||
                                                    paymentFilteredOrder.terminal.toLowerCase().includes(val.toLowerCase())||
                                                    this.compareField(paymentFilteredOrder.owner,val,adminview)
                                                    :
                                                    paymentFilteredOrder.description.toLowerCase().includes(val.toLowerCase()) || 
                                                    paymentFilteredOrder.amount.replace(",", "").includes(val.toLowerCase()) || 
                                                    paymentFilteredOrder.date_paid.toLowerCase().includes(val.toLowerCase())|| 
                                                    this.compareField(paymentFilteredOrder.owner,val,adminview))
          }

          return finalFilteredOrders
        } 
      
    }
  }
  exportTable = () => {
    const {
      orders
    } = this.props

    let filtered_orders = []
    let i;

    if(this.state.title == "Paid"){
      for(i=0;i<orders.length;i++){
        let details = {
          'id': orders[i].id,
          'uid': orders[i].uid,
          'code': orders[i].code,
          'description': orders[i].description,
          'amount': orders[i].amount,
          'timestamp': orders[i].timestamp,
          'expire_at': orders[i].expire_at,
          'date_paid': orders[i].date_paid,
          'owner': orders[i].owner,
          'buyer_name': orders[i].buyer_name,
          'buyer_location': orders[i].buyer_location,
          'terminal': orders[i].terminal,
          'channel_str': orders[i].channel_str
        }

        filtered_orders.push(details)

      }
    }

    let options = { 
      filename: 'Orders_'+moment().format("MM-DD-YYYY_HH:mm:ss")+'_'+this.state.title,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'BUx User List',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };
      
    let csvExporter = new ExportToCsv(options);

    if(orders != null){
      if(this.state.title == "Paid"){
        csvExporter.generateCsv(filtered_orders);
      }
      else{
        csvExporter.generateCsv(orders);
      }
    }
  };

  render() {

    const pickUpAddress = this.state.pickUpAddresses;

    let pickUpAddres = pickUpAddress.length > 0 && pickUpAddress.map((item, i) => {
      
      return (
        <Option key={item.id} value={item.id} >{item.street_address}</Option>
      )
    }, this)

    const { title } = this.state
    return (
      <div style={{ paddingTop: '38px', paddingLeft: '42px', paddingRight: '42px' }}>
        <MetaTag title="Payment Request"/>

        <div style={{ marginBottom: '25px', display: 'flex', justifyContent:'space-between'}}>
          <Button
              style={{
                backgroundColor: '#1DD28B',
                border: '#1DD28B',
                height: '40px',
                borderRadius:'10px',
              }}
              onClick={()=>this.exportTable()}>
            
            <div style={Style.textStyle}>Export CSV</div>
          </Button>
        </div>
        <div style={{display: 'flex', paddingBottom: 30}}>     
          <div style={{display: 'flex'}}>
            <Select
              mode="multiple"
              showSearch
              placeholder="Payment Method"
              style={{ width: '180px', marginRight: 16}}
              size="large"
              showArrow={true}
              onChange={(ev) => this.handlePaymentMethod(ev)}
            >
              <OptGroup label="Over-the-counter">
              {
                  Object.keys(OTC).map((item, i) => {
                      return(
                          <Option key={i} value={item.toLowerCase()}>{item}</Option>
                      )
                  })
              }
              </OptGroup>
              <OptGroup label="Web Banking">
              {
                  Object.keys(BANK).map((item, i) => {
                      return(
                          <Option key={i} value={item.toLowerCase()}>{item}</Option>
                      )
                  })
              }
              </OptGroup>
              <OptGroup label="E-Wallet">
              {
                  Object.keys(MOBILE).map((item, i) => {
                      return(
                          <Option key={i} value={item.toLowerCase()}>{item}</Option>
                      )
                  })
              }
              </OptGroup>
            </Select>
          </div>
          <div style={{display: 'flex'}}>
              <DatePicker
                format='MM-DD-YYYY' 
                disabledDate={disabledDate2} 
                size="large" 
                picker="month"
                onChange={ (dates, dateString) => this.rangeSelect(dates, dateString, 'startDate')} 
                placeholder='Recon date'
                suffixIcon={<Icon type="calendar"></Icon>}
              />
          </div>
        </div>    
        <Alert type="error" style={{ display: this.state.showAlert ? "inline-block" : "none", marginBottom: '30px' }} message="Please select at least one order for shipping"/>

          <Table
            rowSelection={

              localStorage.getItem("shipping_enabled") == "1" && localStorage.getItem("admin_shipping_enabled") == "1" ?
              this.rowSelection
              :
              {}
            }
            rowClassName="table-row-light"
            loading={this.props.loading}
            columns={this.paid_columns}
            dataSource={this.renderSearch(this.state.searchValue, this.state.title, this.state.searchValueType, this.state.userType, this.state.paymentMethod)} />

     
      </div>
    )
  }
}

// export default Orders
export default connect(mapStateToProps, mapDispatchToProps)(ReverseRecon);