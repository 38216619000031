export const labelStyle={
    
    fontStyle: 'regular',
    fontSize: '12px',
    lineHeight: '32px',
    fill: 'solid',
    color: '#000',
  }

export const titleStyle = {

    fontStyle: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    fontWeight: '600',
    fill: 'Solid',
    color: '#000',
    marginBottom: '20px'
}

export const headerStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000'
}

export const ref_code_label = {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '20px',
    color: '#000000',
}

export const code_style = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#2b2d33'
}
export const linkStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#1D82B8',
}

export const linkDiv = {
    width: '250px',
    marginTop: '10px',
    textDecoration: 'underline',
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
}

export const shareStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000',
    marginTop: '30px'
}

export const generateBtn = {
    color: '#fff',
    background: '#F5922F',
    border: 0
}

export const generateBtn1 = {
    color: '#fff',
    background: '#F5922F',
    opacity: '0.5',
    border: 0      
}
export const customerHeader = {
    fontStyle: 'normal',
    fontWeight: '600', 
    fontSize: '16px', 
    lineHeight: '24px',
    color: '#2b2d33', 
}

export const customerName = {
    fontStyle: 'normal',
    fontWeight: 'normal', 
    fontSize: '16px', 
    lineHeight: '24px',
    color: '#2b2d33', 
}

export const shareViaStyle = {

    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#333333',
    opacity: '0.8'
}

export const copyOrShare = {

    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#54575F',
}

export const btnstyle = {
    outline: 0,
    background: 'transparent',
    border: '0',
    cursor: 'pointer',
    '&:hover': {
        transform: 'scale(1.2)'
    }
}

export const btnText = {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '12px',
    color: '#54575f'
}