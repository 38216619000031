import React from 'react'
import pricingStyle from '../../static/css/Pricing';
import { Grid } from "@material-ui/core";
import { Gcash, Grabpay, MainInstapay, EW, PaymayaBlueBG } from '../../static/icons/payment_method_icons/index'

const EWallet = props => {
    const userType = localStorage.getItem('userType')
    
    const p = pricingStyle();

    const channels = [
        {
            icon: Gcash,
            name: 'GCash',
            min: '₱ 50.00',
            max: '₱ 30,000.00',
            emax: '₱ 80,000.00',
            surcharge: 'No Surcharge'

        },    
        {
            icon: Grabpay,
            name: 'Grabpay',
            min: '₱ 50.00',
            max: '₱ 30,000.00',
            emax: '₱ 80,000.00',
            surcharge: 'No Surcharge'

        },       
        {
            icon: MainInstapay,
            name: 'InstaPay',
            fee: '₱ 15.00 ',
            min: '₱ 50.00 ',
            max: '₱ 30,000.00 ',
            emax: '₱ 50,000.00 ',
            surcharge: 'Depends on E-wallet'

        },
        // {
        //     icon: PaymayaBlueBG,
        //     name: 'Maya',
        //     fee: '₱ 15.00 ',
        //     min: '₱ 50.00 ',
        //     max: '₱ 30,000.00 ',
        //     emax: '₱ 80,000.00 ',
        //     surcharge: 'No Surcharge'

        // },  
    ]

    return(
        <div className="pricing-table-div top-20" hidden={props.hidden}>
            <div className="pricing-header">
                <div className="wc-header">
                    <img src={EW} className="right-8" alt="icon" />
                    E-wallet payments
                </div>

                <div className="top-20 normal-text-size liveColor">
                    Transaction fees are <span className={`text-${props.segments} wc-header`}>2.0% + ₱ 10.00</span> per transaction.
                </div>
            </div>

            <div className="padding-lr-40 padding-bot-40">
                <div className="twoCol wc-header padding-20">
                    <Grid container>
                        <Grid item lg={2}>
                            Channel
                        </Grid>

                        <Grid item lg={2}>
                        </Grid>

                        <Grid item lg={2}>
                            Minimum Amount
                        </Grid>

                        <Grid item lg={2}>
                            Maximum Amount
                        </Grid>

                        <Grid item lg={4}>
                            Surcharge
                        </Grid>
                    </Grid>
                </div>
                {
                    channels.map((item, key) => {
                        return(
                            <div className="pricing-data normal-text-size liveColor">
                                <Grid key={key} container>
                                    <Grid item lg={2}>
                                        <div align='left'>
                                            <img alt="icon" src = {item.icon} style={{maxWidth: '60%'}} />
                                        </div>
                                    </Grid>

                                    <Grid item lg={2}>
                                        <p className={p.amount}>{item.name}</p>
                                    </Grid>

                                    <Grid item lg={2}>
                                        <p className={p.amount}>{item.min}</p>
                                    </Grid>

                                    <Grid item lg={2}>
                                        <p className={p.amount}>
                                            {
                                                userType == 'CO' || userType == 'SW' ? 
                                                    item.emax :
                                                    item.max
                                            }
                                        </p>
                                    </Grid>  
                                    <Grid item lg={4}>
                                        <p className={p.amount}>{item.surcharge}</p>
                                    </Grid> 
                                </Grid>
                            </div>
                        )   
                    })
                }
                <div className="wc-header top-16">
                    *surcharge fees are additional fees charged to the customer.
                </div>
            </div>
        </div>
    )
}

export default EWallet;