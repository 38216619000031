import React from 'react'
import {Card, Divider, Icon, Input, message} from 'antd'
import userStyles from './style'
import ActionConfirmationModal from './confirmation_modal'
import PermissionsModal from './edit_permissions_modal'
import UserTable from './user_table'
import UserModal from './new_user_modal'
import MetaTag from '../../meta_tag/meta_tag'
import settingServices from '../../account_settings/settingServices'
import './user_accounts.css'

const UserAccess = props => {
    const userType = localStorage.getItem('userType') 
    const pUid = userType == 'CO' || userType == "ME" ? '' : props.props.match.params.id;

    const styles_user = userStyles()
    const [showNewUserModal, setShowNewUserModal] = React.useState(false)
    const [failed, setFailed] = React.useState(false)
    const [userData, setUserData] = React.useState([])
    const [searchValue, setSearchValue] = React.useState('');

    // Confirmation Modal
    const [isConfirmModalVisible, setIsConfirmModalVisible] = React.useState(false);
    const [confirmModalHeaderText, setConfirmModalHeaderText] = React.useState('');
    const [confirmModalBodyText, setConfirmModalBodyText] = React.useState('');
    const [confirmModalCallback, setConfirmModalCallback] = React.useState(null);

    // Edit Permissions Modal
    const [isPermissionsModalVisible, setIsPermissionsModalVisible] = React.useState(false);


    React.useEffect(() => {
        getCorporate()
    }, [])


    async function getCorporate() {
        try {
            let res = await settingServices.getCorporateAlias(pUid === '' ? null : pUid);

            if(res.status == "failed"){
                message.error(res.message, 5);
                setFailed(true);
            }

            else {
                setUserData(res);
                setFailed(false);
            }
        }
        catch(error) {
            console.log(error)
        }
    }

    return (
        <div align='center'>
            <Card style={user_account_styles.mainCardDiv}>
                <MetaTag title="User's Access"/>

                <div style={user_account_styles.headerRowDiv}>
                    <div style={user_account_styles.cardTitle}>
                        User Access
                    </div>

                    <div style={user_account_styles.headerRowDiv}>
                        <Input
                            placeholder="Search"
                            style={{ width: '250px', height: '40px', borderRadius:'4px' }}
                            suffix={<Icon type="search" />}
                            onChange={(e)=> setSearchValue(e.target.value)}
                        />
                        <button
                            onClick={() => setShowNewUserModal(true)}
                            className={styles_user.newBtn} 
                            style={{
                                opacity: failed ? '0.5' : '',
                                cursor: failed ? 'not-allowed' : 'pointer',
                                height: '40px',
                                marginLeft: '15px',
                                fontSize: 'medium'
                            }}
                            disabled={failed}
                        >
                            + Add New User
                        </button>
                    </div>
                </div>

                <Divider style={{margin: '12px 0px 12px 0px'}} />

                <div style={user_account_styles.editButtonDiv}>
                    <button
                        onClick={() => setIsPermissionsModalVisible(true)}
                        className="hoverable-edit-button"
                        style={{
                            ...user_account_styles.editButton,
                            opacity: failed ? '0.5' : '',
                            cursor: failed ? 'not-allowed' : 'pointer',
                        }}
                        disabled={failed}
                    >
                        Edit Permissions
                    </button>
                </div>

                <div>
                    <UserTable
                        style={{marginTop: '5px'}}
                        id={pUid}
                        getCorporate={getCorporate}
                        userData={userData}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        setConfirmModalCallback={setConfirmModalCallback}
                        setIsConfirmModalVisible={setIsConfirmModalVisible}
                        setConfirmModalHeaderText={setConfirmModalHeaderText}
                        setConfirmModalBodyText={setConfirmModalBodyText}
                    />
                </div>
            </Card>
            <UserModal
                id={pUid}
                getCorporate={getCorporate}
                showModal={showNewUserModal}
                closeModal={() => setShowNewUserModal(false)}
            />
            <ActionConfirmationModal
                isConfirmModalVisible={isConfirmModalVisible}
                setIsConfirmModalVisible={setIsConfirmModalVisible}
                confirmModalCallback={confirmModalCallback}
                confirmModalHeaderText={confirmModalHeaderText}
                confirmModalBodyText={confirmModalBodyText}
            />
            <PermissionsModal
                isPermissionsModalVisible={isPermissionsModalVisible}
                setIsPermissionsModalVisible={setIsPermissionsModalVisible}
                userData={userData}
                pUid={pUid}
                getCorporate={getCorporate}
                disabled={!userData.length}
            />
        </div>
    );
} 


const user_account_styles = {
    mainCardDiv: {
        width:'60vw',
        margin: '32px 5px 20px 5px'
    },
    headerRowDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    cardTitle: {
        color: 'rgb(10, 49, 94)',
        fontSize: 'x-large',
        fontWeight: 600,
    },
    editButtonDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    editButton: {
        border: 'none',
        background: 'none',
        color: '#e6892e',
        outline: 'none',
        fontWeight: 600,
        margin: '20px 10px 0px 0px',
        fontSize: 'large'
    }
}

export default UserAccess