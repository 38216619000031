import React from 'react'
import axios from 'axios'
import {Card, Select, Input, Icon, Modal, message} from 'antd'
import enterpriseStyle from '../kybStyle'
import { isMobile } from 'react-device-detect'
import ReviewIcon from '../../../../static/img/review.svg'
import { history } from '../../../../store/history'
import Submitted from '../../../../static/icons/submitted.svg'
import Pending from '../../../../static/icons/pendingkyb.svg'
import NoteCard from '../noteCard';

const ReviewCard = props => {
    const datas = props.datas
    const kyb = enterpriseStyle()
    const kybStep = localStorage.getItem("KYBStep")
    const kybType = localStorage.getItem("KYBType")
    const [submittedModal, setSubmittedModal] = React.useState(false)
    let note = <span>After reviewing, click on the <b>Submit</b> button to complete your application.</span>
    let reviewNote = 
        <span>
            You further agree and authorize <b>UBX PH</b> or its duly authorized representatives to conduct verification or validation procedures on the information and documents you have provided in your application. 
            You also agree and accept that any derogatory findings on the <b>truthfulness, correctness, accuracy</b> or <b>completeness</b> of your submitted information or documents may cause the denial or rejection of your application.
        </span>
    const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
    const isSmallReso = window.screen.width * window.devicePixelRatio == 1331 && window.screen.height * window.devicePixelRatio == 669;

    function submitStep7() {
        let params = {
            step_1_has_error: false,
            step_2_has_error: false,
            step_3_has_error: false,
            step_4_has_error: false,
            step_5_has_error: false,
            step_6_has_error: false,
            step_to_update: kybStep == 10 ? 9 : 8
        }
        props.submitStep(params, kybStep == 10 ? 9 : 8)
    }

    async function adminSubmit() {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }  

        let url = process.env.REACT_APP_API_URL + "/api/kyb/";

        let params = {
            submission_id: datas.id,
        }

        let response = await axios.put(url,params,yourConfig)

        if(response.data.status == "failed") {
            setSubmittedModal(!submittedModal)
            message.error(response.data.message, 5)
            props.setOpenModal(false)
        }

        else {
            setSubmittedModal(!submittedModal)
            message.success(response.data.data, 5)
            props.setOpenModal(false)
        }

        console.log(response)

    }

    let remarks = props.remarks
    let submitted = props.submitted
    let segments = props.segments

    return(
        <div>
        <Card 
        className={`${kyb.formCardStyle} padding-16`}
        style={{
            display: kybType == "creation" || kybStep != 7 ? 'none' : 'block',
        }}
        bodyStyle={{padding: '20px 16px 20px 16px'}}>
        
            <div className="bottom-16">
                <div>
                    <div className={`${kyb.greatText} liveColor`}>Submit your application</div>
                    <div className={kyb.reviewText}>
                        {note}
                    </div>
                </div>
            </div>

            {/* <div className={kyb.reviewText}>
                {reviewNote}
            </div> */}

            <div style={{marginTop: 16}} align="right" hidden={isMobile || kybStep == 10}>
                <button className={`btn--${segments} btn-height normal-text-size`} onClick={() => props.submitApplication()}>Submit</button>
            </div>
        </Card>
        
        <div hidden={kybType == "creation" || kybStep != 7}  style={{width: isMobile ? '100%' : isHighReso ? '584px' : isSmallReso ? '550px' : '634px'}} className="bottom-16">
            <NoteCard note={reviewNote} />
        </div>
        
        <Card 
        className={kyb.formCardStyle}
        style={{
            display: kybType != "creation" ? 'none' : 'block',
        }}
        bodyStyle={{padding: '20px 16px 20px 16px'}}>
        
            <div style={{display: 'flex', paddingBottom: 16, marginBottom: 16, borderBottom: '1px solid #D1D5DD'}}>
                <div>                
                    <img src={ReviewIcon} />
                </div>

                <div style={{marginLeft: 16}}>
                    <div className={kyb.greatText}>Great Job!</div>
                    <div className={kyb.reviewText}>
                        {note}
                    </div>
                </div>
            </div>

            {/* <div className={kyb.reviewText}>
                {reviewNote}
            </div> */}

            <div style={{marginTop: 16, display: 'flex', justifyContent: 'space-between'}} hidden={isMobile || kybStep == 10}>
                <div>Created by: <b>{localStorage.getItem('username')}</b></div>
                <div><button className={kyb.allGoodBtn} onClick={() => props.submitApplication()}>Submit</button></div>
            </div>

            {/* <div style={{marginTop: 16}} align="right" hidden={isMobile || kybStep == 10}>
                <button className={kyb.allGoodBtn} onClick={() => setSubmittedModal(true)}>All Good! Submit</button>
             </div> */}
        </Card>

        <div className={kyb.stickyBtn} hidden={!isMobile || kybStep == 8 || kybStep == 9}>
            <button className={`btn--${segments} btn-mobile-width`}  onClick={() => props.submitApplication()} style={{width: '100%'}} >{kybStep == 10 ? 'Resubmit application' : 'Submit application'}</button>
        </div>

        <Card 
            style={{
                display: kybStep == 10 ? 'block' : 'none',
                background: 'rgba(248, 185, 102, 0.1)',
                border: '1px solid #F8B966',
                width: isMobile ? '100%' : isHighReso ? '584px' : isSmallReso ? '550px' :  '634px',
                borderRadius: '4px',
                marginBottom: 32
            }}>

                <div style={{
                    color: '#E24C4C',
                    fontWeight: '600',
                    lineHeight: '24px',
                    fontSize: 16,
                    marginBottom: 16,
                }}>
                    Application was declined
                </div>

                <div className={kyb.normalText} style={{marginBottom: 16}}>
                We’re sorry, we cannot proceed with your application due to inconsistencies with the submitted information / documents. 
                Kindly check our <b>remarks</b> and resubmit your application.
                </div>

                <div className={kyb.headerStrong}>
                    Remarks:
                </div>

                <div className={kyb.normalText}>
                    {remarks}
                </div>


        </Card>


        <Card 
        className={kyb.formCardStyle}
        style={{
            display: kybStep == 8 || kybStep == 9 ? 'block' : 'none',
        }}
        bodyStyle={{padding: '20px 16px 20px 16px'}}>
            <div className="twoCol liveColor">
                <div>
                    <div className={kyb.greatText}>
                        Application status
                    </div>

                    <div className="normalTextStyle top-12">
                        Date Application: {submitted}
                    </div>
                </div>

                <div style={{marginTop: 16}} hidden={isMobile}>
                    <span className={kyb.pending}> For reviewing</span>
                </div>
            </div>

            <div hidden={!isMobile}>
                <div className="top-16">
                    <span className={kyb.pending}> For reviewing</span>
                </div>
            </div>

            <div align="center" className="top-20 padding-top-16" style={{borderTop: '1px solid #eeeeee'}}>
                <span>Haven’t received your status yet? Please contact us at <a href="mailto:support@bux.ph" className={`text-${segments} wc-header`}>support@bux.ph</a></span>
            </div>
        </Card>

        <Modal
            visible={props.openModal}
            onCancel={() => {setSubmittedModal(false); props.setOpenModal(false)}}
            footer={null}
            width={440}
            closable={false}
            centered={false}
            >
            <div className="top-20 liveColor">
                <img src={Submitted} alt="submitted" />
                <div className={`${kyb.greatText} top-20`}>Successful submission</div>
                <div className="normal-text-size top-16">
                Thank you for submitting your application for Bux! 
                Kindly allow us at least 24 hours to evaluate your application. 
                An email will be sent to you informing you of the status of your application.
                </div>
                <div className="normal-text-size top-32">
                    Haven’t received your status? Please contact us at <a href="mailto:support@bux.ph" className={`text-${segments} wc-header`}>support@bux.ph</a>
                </div>
                
                <div className="top-20" align="right">
                    <button className={`btn--${segments}`} onClick={() => localStorage.getItem("KYBType") == "creation" ? adminSubmit() : submitStep7()}>Done</button>
                </div>
            </div>
        </Modal>

        </div>
    )
}

export default ReviewCard