import React from 'react';
import { isMobile } from 'react-device-detect';
import apiPortaIcon from '../../../static/icons/api_portal.svg';

const ApiPortalCard = (props) => {

    return (
        <div align="center">
            <div className={`portal-div-card ${isMobile ? '' : 'twoCol'}`}>
                <div style={{width: isMobile ? '100%' : '60%'}}>
                    <div className="bold-text-size liveColor"> 
                        BUx API Portal
                    </div>
                    <div className="normalTextStyle top-16"> 
                        API documents serve as a guide on how you can integrate BUx via API (application programming interface).
                    </div>

                    <div className="top-16">
                        <button className={`outline-btn--${props.segments} btn-height`} 
                        onClick={() => window.open('https://developers.bux.ph', "_blank")}>
                            Go to API portal
                        </button>
                    </div>
                </div>

                <div style={{marginTop: '-9%'}} hidden={isMobile}>
                <img src={apiPortaIcon} alt="img" />
                </div>
            </div>
        </div>
    )
}

export default ApiPortalCard;