import React from 'react';
import { history } from '../../../store/history';
import testModeServices from '../testModeServices';
import { Header, ProcessingCard, SuccessCard, Footer, ReminderCard, LoginCard, AuthorizedCard, OtpCard } from '../components';
import { Spin, message } from 'antd';
import { isMobile } from 'react-device-detect';

const TestModePayment = (props) => {
    const uid = props.match.params.uuid;
    const [details, setDetails] = React.useState(null);
    const [type, setType] = React.useState(null); 
    const [step, setStep] = React.useState(1);
    const [loading, setLoading] = React.useState(true);

    const channels = ['gcash', 'RCBC', 'UBPB', 'MBTC', 'BPIA', 'grabpay'];

    const errorPrompt = (msg) => {
        message.error(msg);
    };

    const getDetails = async () => {
        setLoading(true);
        try {
            const res = await testModeServices.getCheckoutDetails(uid);
            if(!res || res && res.status !== 'Pending' || !channels.includes(res.channel)) {
                history.push('/404');
            } else {
                setDetails(res);
                setType(res && res.channel_type === 'BANKING' ? 'BANKING' : 'GCASH');
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const simulatePayment = async () => {
        try {
            const res = await testModeServices.simulatePayment(uid);
            if(res.status === 'failed'){
                errorPrompt(res.message || 'Something went wrong, try again');
            } else if(res.status !== 'success'){
                errorPrompt('Something went wrong, try again')
            } else {
                setStep(step+1)
            }
        } catch (error) {
            console.log(error);
            errorPrompt('Something went wrong, try again')
        }
    }

    if(step === 3) {
        setTimeout(() => {
            setStep(step+1);
        },3000)
    }

    React.useEffect(() => {
        if(step==1){
            getDetails();
        }
        if(step ==4){
            window.location.href= '/test/payment/' + uid
        }
    },[step]);

    return (
        <div style={styles.root}>
            <Header />
            <div style={styles.body} hidden={loading}>
                <ReminderCard type={type} hidden={step > 2} />
                <LoginCard type={type} onLogin={() => setStep(type==='BANKING' ? step+1 : step+.5)} hidden={step!==1} />
                <OtpCard submitOtp={() => setStep(step+.5)} hidden={step!==1.5} />
                <AuthorizedCard type={type} onAuthorized={simulatePayment} hidden={step !==2 } details={details}  />
                <ProcessingCard hidden={step !== 3} />
                <div style={{padding: 8}} hidden={step!==4} ><SuccessCard details={details} /></div>
                <Footer />
            </div>
            <Spin size='large' style={styles.spinner} hidden={!loading} />
        </div>  
    )
}

const styles = {
    root: {
        backgroundColor: '#F1F2F6',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    body: {
        display: 'flex',
        width: '100%',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column',
        padding: isMobile? '64px 16px 16px 16px' :  '84px 0 0 0',
        height: '100vh',
        overflow: 'auto'
    },
    spinner: {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        width: '100%', 
        height: '100vh'
    }
} 

export default TestModePayment;