import React, {useEffect} from 'react'
import axios from 'axios'
import {Card, Select, Input, Icon, Spin, message, Collapse, Typography, Button, Modal} from 'antd'
import enterpriseStyle from '../kybStyle'
import { isMobile, isFirefox, isIOS, isMobileSafari, isSafari, isEdge } from 'react-device-detect'
import {POSITION} from '../../../constants/constants'
import NoteCard from '../noteCard'
import TextInput from '../custom_component/textInput'
import TipsIcon from '../../../../static/icons/tips.svg'
import CameraIcon from '../../../../static/img/cameraImg.svg'

const {Option} = Select

const { Panel } = Collapse;

const primaryIdList = [
    'Passport',
    'Professional Regulation Commission ID',
    'Integrated Bar of the Philippines ID',
    'NBI Clearance',
    'Government Service Insurance System eCard/ID',
    'Social Security System ID (SSS ID) or Unified Multi-Purpose ID',
    'School ID',
    'PhilSys ID',
    "Driver’s License",
]

const secondaryIdList = [
    "Alien Certificate of Registration or Immigrant Certificate of Registration",
    "Company ID",
    "Government office and GOCC ID",
    "National Council on Disability Affairs ID",
    "OFW ID",
    "Overseas Workers Welfare Administration ID",
    "PhilHealth Insurance Card ng Bayan",
    "Postal ID",
    "Seaman’s Book",
    "Senior Citizen Card",
    "TIN ID",
    "Voter’s ID",
]

const PrimaryIDType = { 
    'SSS': 'SSS',
    'GSIS': ' GSIS',
    'DRL': `Driver's License`,
    'PAS': 'Passport',
    'IBP': 'Integrated Bar of the Philippines ID',
    'PRC': 'PRC ID',
    'NBI': 'NBI Clearance',
    'SID': 'School ID',
    'PSID': 'PhilSys ID',
}

const SecondaryIDType = { 
    'AICR': 'Alien Certificate of Registration Identity Card',
    'CID': ' Company ID',
    'GOID': 'Government office and GOCC ID',
    'NCDA': 'National Council on Disability Affairs ID',
    'OFW': ' OFW ID',
    'OWWA': 'Overseas Workers Welfare Administration ID',
    'PICB': 'PhilHealth Insurance Card ng Bayan',
    'PID': 'Postal ID',
    'SBID': 'Seaman’s Book',
    'TIN': 'TIN ID',
    'VID': `Voter's ID`,
    'SCC': 'Senior Citizen Card'
}

const SignatoryInformation = props => {
    let datas = props.datas
    const kybStep = localStorage.getItem('KYBStep')
    const userRole = localStorage.getItem("userType")
    const adminRole = ['AD', 'SD']

    const kyb = enterpriseStyle()
    const note = "All the information should be the same as indicated in your contract."
    //alert message
    const [error, setError] = React.useState(true)
    const [errorMessage, setErrorMessage] = React.useState("")
    const [formSubmitable, setFormSubmitable] = React.useState(false)
    const [signatoryStep, setSignatoryStep] = React.useState()
    const [signatoryInfo, setSignatoryInfo] = React.useState([
        {
            first_name: "", 
            middle_name: "", 
            last_name: "", 
            position: 5, 
            name: 'Signatory Info',
            ref:  React.createRef(),
            ref1:  React.createRef(),
            ref2:  React.createRef(),
            ref_1_expired:  React.createRef(),
            ref_2_expired:  React.createRef(),
            ref_1_secondary_1:  React.createRef(),
            ref_2_secondary_1:  React.createRef(),
            ref_1_secondary_2:  React.createRef(),
            ref_2_secondary_2:  React.createRef(),
            file_name: null,
            signature_attachment: "",
            value: "",
            ID_set: "a",
            ID_type_main: null,
            ID_number_main: "",
            front_id_file_name_main: null,
            front_id_attachment_main: "",
            back_id_file_name_main: null,
            back_id_attachment_main: "",
            ID_type_expired: null,
            ID_number_expired: "",
            front_id_file_name_expired: null,
            front_id_attachment_expired: "",
            back_id_file_name_expired: null,
            back_id_attachment_expired: "",
            ID_type_secondary_1: null,
            ID_number_secondary_1: "",
            front_id_file_name_secondary_1: null,
            front_id_attachment_secondary_1: "",
            back_id_file_name_secondary_1: null,
            back_id_attachment_secondary_1: "",
            ID_type_secondary_2: null,
            ID_number_secondary_2: "",
            front_id_file_name_secondary_2: null,
            front_id_attachment_secondary_2: "",
            back_id_file_name_secondary_2: null,
            back_id_attachment_secondary_2: "",
            selfie_image: "",
        },
    ])
    const [isTouched, setIsTouched] = React.useState(false)
    const stripQueryParams = (myString) => myString.slice(65).replace(/\?.*/,'')

    const [idType, setIdType] = React.useState(1);
    const [idSet, setIdSet] = React.useState('a');
    const [idFormatError, setIdFormatError] = React.useState(false);
    const [idNumber, setIdNumber] = React.useState("");
    const [stepErrorMessage, setStepErrorMessage] = React.useState("");
    const [stepErrorAlert, setStepErrorAlert] = React.useState(false)

    const segments = localStorage.getItem('segments');

    //  selfie states
    const [selfieBlob, setSelfieBlob] = React.useState("");
    const [cameraModal, setCameraModal] = React.useState(false);
    const [blockModal, setBlockModal] = React.useState(false);
    const [messageModal, setMessageModal] = React.useState("");
    const [loading, setLoading] = React.useState(false)

    const canvasRef = React.useRef(null);
    const playerRef = React.useRef(null);

    const max_width = 1000;
    const max_height = 1000;

    const color = segments == 'me_' ? '#0DAED0' : segments == 'gig' ? '#41BD5C' : '#F5922F';

    function checkEmptyDynamic(){

        for(let signatory of signatoryInfo){
            // if(!signatory.first_name || !signatory.last_name || signatory.position < 0|| signatory.file_name == null){
            if(!signatory.first_name || !signatory.last_name || signatory.position < 0 || !signatory.selfie_image){
                setFormSubmitable(false) 
                return
            } else if ((!signatory.ID_type_main || !signatory.ID_number_main) && signatory.ID_set === "a") {
                setFormSubmitable(false)
                return
            } else if ((!signatory.ID_type_expired || !signatory.ID_number_expired || !signatory.ID_type_secondary_1 || !signatory.ID_type_secondary_2 ||
                        !signatory.ID_number_secondary_1 || !signatory.ID_number_secondary_2 || !signatory.front_id_file_name_expired ||
                        !signatory.back_id_file_name_expired || !signatory.front_id_file_name_secondary_1 || !signatory.front_id_file_name_secondary_2 ||
                        !signatory.back_id_file_name_secondary_1 || !signatory.back_id_file_name_secondary_1) && signatory.ID_set === "b") {
                setFormSubmitable(false)
                return
            }
        }
      
        setFormSubmitable(true)
    }


    useEffect(() => {
        checkEmptyDynamic()
    }, [signatoryInfo])

    useEffect(()=>{
        if(datas && datas.step){
            setSignatoryStep(datas.step)
            if (!datas.signatories.length) {
                return;
            }

            let newSignatories = [];

            for (const signatory of datas.signatories) {
                let signatoryToAdd = {
                    first_name: signatory.first_name, 
                    middle_name: signatory.middle_name, 
                    last_name: signatory.last_name,
                    position: signatory.position,
                    file_name: signatory.signature_attachment ? stripQueryParams(signatory.signature_attachment) : '',
                    name: 'Signatory Info',
                    ref: React.createRef(),
                    ref1:  React.createRef(),
                    ref2:  React.createRef(),
                    ref_1_expired:  React.createRef(),
                    ref_2_expired:  React.createRef(),
                    ref_1_secondary_1:  React.createRef(),
                    ref_2_secondary_1:  React.createRef(),
                    ref_1_secondary_2:  React.createRef(),
                    ref_2_secondary_2:  React.createRef(),
                    signature_attachment: "",
                    value: "",
                    ID_set: signatory.ID_set,
                    ID_type_main: signatory.ID_type_main,
                    ID_number_main: signatory.ID_number_main,
                    front_id_file_name_main: signatory.front_id_attachment_main ? stripQueryParams(signatory.front_id_attachment_main) : null,
                    front_id_attachment_main: "",
                    back_id_file_name_main:  signatory.back_id_attachment_main ? stripQueryParams(signatory.back_id_attachment_main) : null,
                    back_id_attachment_main: "",
                    ID_type_expired: signatory.ID_type_expired,
                    ID_number_expired: signatory.ID_number_expired,
                    front_id_file_name_expired: signatory.front_id_attachment_expired ? stripQueryParams(signatory.front_id_attachment_expired) : null,
                    front_id_attachment_expired: "",
                    back_id_file_name_expired: signatory.back_id_attachment_expired ? stripQueryParams(signatory.back_id_attachment_expired) : null,
                    back_id_attachment_expired: "",
                    ID_type_secondary_1: signatory.ID_type_secondary_1,
                    ID_number_secondary_1: signatory.ID_number_secondary_1,
                    front_id_file_name_secondary_1:  signatory.front_id_attachment_secondary_1 ? stripQueryParams(signatory.front_id_attachment_secondary_1) : null,
                    front_id_attachment_secondary_1: "",
                    back_id_file_name_secondary_1: signatory.back_id_attachment_secondary_1 ? stripQueryParams(signatory.back_id_attachment_secondary_1) : null,
                    back_id_attachment_secondary_1: "",
                    ID_type_secondary_2: signatory.ID_type_secondary_2,
                    ID_number_secondary_2: signatory.ID_number_secondary_2,
                    front_id_file_name_secondary_2: signatory.front_id_attachment_secondary_2 ? stripQueryParams(signatory.front_id_attachment_secondary_2) : null,
                    front_id_attachment_secondary_2: "",
                    back_id_file_name_secondary_2: signatory.back_id_attachment_secondary_2 ? stripQueryParams(signatory.back_id_attachment_secondary_2) : null,
                    back_id_attachment_secondary_2: "",
                    selfie_image: signatory.selfie_image ? signatory.selfie_image : "",
                }

                newSignatories.push(signatoryToAdd);
            }

            setSignatoryInfo(newSignatories);
        }
    },[]);


    //function input
    function handleChangeOfficers (e, index) {
        setIsTouched(true)
        const { name, value } = e.target
        const list = [...signatoryInfo]
        list[index][name] = value
        setSignatoryInfo(list)

        /* Modifying first_name or last_name implies a different person
           Force user to upload a different signature_attachment */
        if (["first_name", "last_name"].includes(name)) {
            signatoryInfo[index].ref.current.value = null;
            handleRemoveFile(signatoryInfo[index].name, index);
        }
    };

    //handle select
    function handleDesignation(value, key) {
        setIsTouched(true)
        const list = [...signatoryInfo]
        list[key]['position'] = value  
        setSignatoryInfo(list)
    }

    function handleRemoveFile(type, key, idSet, view, specificKey){
        setIsTouched(true)
        const list = [...signatoryInfo]
        if (idSet === "a" || idSet === "b") {
            if (view === "front"){
                list[key][specificKey? specificKey.name : 'front_id_file_name_main'] = null
                list[key][specificKey? specificKey.attachement :'front_id_attachment_main'] = ""
                setSignatoryInfo(list)
            } else {
                list[key][specificKey? specificKey.name : 'back_id_file_name_main'] = null
                list[key][specificKey? specificKey.attachement :'back_id_attachment_main'] = ""
                setSignatoryInfo(list)
            }
        } else {
            list[key]['file_name'] = null
            list[key]['signature_attachment'] = ""  
            setSignatoryInfo(list)
        }
    }

    const handleUploadFile = (info, type, idSet, key, view, specificKey) =>{
        let acceptedFiles = ['image/png', 'image/jpeg', 'image/jpg']
        let mb = 10000000;

        console.log(info)
        setIsTouched(true)

        if(!acceptedFiles.includes(info.type))
        {
            setError(false)
            setErrorMessage("File must be .jpg, .jpeg or .png only")
            return
        }

        else if(info.size > mb) {
            setError(false)
            setErrorMessage("File must not more than 10MB")
            return
        }

        else {
            setError(true)
            const list = [...signatoryInfo]
           if (idSet === "a" || idSet === "b") {
                if (view === "front"){
                    list[key][specificKey? specificKey.name : 'front_id_file_name_main'] = info.name
                    list[key][specificKey? specificKey.attachement : 'front_id_attachment_main'] = info
                    setSignatoryInfo(list)
                } else {
                    list[key][specificKey? specificKey.name : 'back_id_file_name_main'] = info.name
                    list[key][specificKey? specificKey.attachement : 'back_id_attachment_main'] = info
                    setSignatoryInfo(list)
                }
           } else {
                list[key]['file_name'] = info.name
                list[key]['signature_attachment'] = info
                setSignatoryInfo(list)
           }
        }
   
    }

    function handleIdSetChange(value, key) {
        setIsTouched(true)
        const list = [...signatoryInfo]
        list[key]['ID_set'] = value  
        setSignatoryInfo(list)
    }


    const handleRemoveClick = (index) => {
        setIsTouched(true);
        const list = [...signatoryInfo]
        list.splice(index);
        setSignatoryInfo(list);
    };

    //handle add data
    const addInfo = () => {
        setFormSubmitable(false);
        setSignatoryInfo([...signatoryInfo, { first_name: "", 
                                            middle_name: "", 
                                            last_name: "",
                                            position: 5,
                                            name: 'Signatory Info',
                                            ref: React.createRef(),
                                            ref1:  React.createRef(),
                                            ref2:  React.createRef(),
                                            ref_1_expired:  React.createRef(),
                                            ref_2_expired:  React.createRef(),
                                            ref_1_secondary_1:  React.createRef(),
                                            ref_2_secondary_1:  React.createRef(),
                                            ref_1_secondary_2:  React.createRef(),
                                            ref_2_secondary_2:  React.createRef(),
                                            file_name: null,
                                            signature_attachment: "",
                                            value: "",
                                            ID_set: "a",
                                            ID_type_main: null,
                                            ID_number_main: "",
                                            front_id_file_name_main: null,
                                            front_id_attachment_main: "",
                                            back_id_file_name_main: null,
                                            back_id_attachment_main: "",
                                            ID_type_expired: null,
                                            ID_number_expired: "",
                                            front_id_file_name_expired: null,
                                            front_id_attachment_expired: "",
                                            back_id_file_name_expired: null,
                                            back_id_attachment_expired: "",
                                            ID_type_secondary_1: null,
                                            ID_number_secondary_1: "",
                                            front_id_file_name_secondary_1: null,
                                            front_id_attachment_secondary_1: "",
                                            back_id_file_name_secondary_1: null,
                                            back_id_attachment_secondary_1: "",
                                            ID_type_secondary_2: null,
                                            ID_number_secondary_2: "",
                                            front_id_file_name_secondary_2: null,
                                            front_id_attachment_secondary_2: "",
                                            back_id_file_name_secondary_2: null,
                                            back_id_attachment_secondary_2: "",
                                            selfie_image: "",}])
      };

    function submitStep4(){

        let formData = new FormData()
        setStepErrorAlert(false)
        if (idFormatError) {
            setStepErrorMessage(idFormatErrorMessage())
            setStepErrorAlert(true)
            window.scrollTo(0, 0);
            return false;
        }

        let signatoryClone = signatoryInfo.map((item) => {
            return item
        })

        for(let i=0;i<signatoryClone.length;i++){
            delete signatoryClone[i].name
            delete signatoryClone[i].ref
            delete signatoryClone[i].value
            delete signatoryClone[i].file_name
            delete signatoryClone[i].ref1
            delete signatoryClone[i].ref2
            delete signatoryClone[i].ref_1_expired
            delete signatoryClone[i].ref_2_expired
            delete signatoryClone[i].ref_1_secondary_1
            delete signatoryClone[i].ref_2_secondary_1
            delete signatoryClone[i].ref_1_secondary_2
            delete signatoryClone[i].ref_2_secondary_2
            delete signatoryClone[i].front_id_file_name_main
            delete signatoryClone[i].back_id_file_name_main
            delete signatoryClone[i].front_id_file_name_expired
            delete signatoryClone[i].front_id_file_name_secondary_1
            delete signatoryClone[i].front_id_file_name_secondary_2
            delete signatoryClone[i].back_id_file_name_expired
            delete signatoryClone[i].back_id_file_name_secondary_1
            delete signatoryClone[i].back_id_file_name_secondary_2
        
        // We cannot nest a binary file inside a dictionary when form-data is used.
        // So, we send the signature_attachment of the [i]th entry as 'signature_attachment[i]', i starts at 1
            // formData.append("signature_attachment" + (i+1), signatoryClone[i].signature_attachment);
            // delete signatoryClone[i].signature_attachment
            if (signatoryClone[i].ID_set === "a") {
                formData.append("front_id_attachment_main" + (i+1), signatoryClone[i].front_id_attachment_main);
                formData.append("back_id_attachment_main" + (i+1), signatoryClone[i].back_id_attachment_main);
            } else if (signatoryClone[i].ID_set === "b") {
                formData.append("front_id_attachment_secondary_1" + (i+1), signatoryClone[i].front_id_attachment_secondary_1);
                formData.append("back_id_attachment_secondary_1" + (i+1), signatoryClone[i].back_id_attachment_secondary_1);
                formData.append("front_id_attachment_secondary_2" + (i+1), signatoryClone[i].front_id_attachment_secondary_2);
                formData.append("back_id_attachment_secondary_2" + (i+1), signatoryClone[i].back_id_attachment_secondary_2);
                formData.append("front_id_attachment_expired" + (i+1), signatoryClone[i].front_id_attachment_expired);
                formData.append("back_id_attachment_expired" + (i+1), signatoryClone[i].back_id_attachment_expired);
            } else {
                formData.append("signature_attachment" + (i+1), signatoryClone[i].signature_attachment);
            }     
            
            // convertion of selfie image
            if(signatoryClone[i].selfie_image instanceof Blob) {
                formData.append("selfie_image" + (i+1), signatoryClone[i].selfie_image, 'selfie_image.jpg');
            }

            // delete null value for id set b
            if (signatoryClone[i].ID_set === "a") {
                delete signatoryClone[i].ID_type_expired
                delete signatoryClone[i].ID_type_secondary_1
                delete signatoryClone[i].ID_type_secondary_2
            } else if (signatoryClone[i].ID_set === "b") {
                delete signatoryClone[i].ID_type_main
            } else {
                delete signatoryClone[i].ID_type_expired
                delete signatoryClone[i].ID_type_secondary_1
                delete signatoryClone[i].ID_type_secondary_2
                delete signatoryClone[i].ID_type_main
            }

            // deletion of all file attachments
            delete signatoryClone[i].signature_attachment
            delete signatoryClone[i].front_id_attachment_main
            delete signatoryClone[i].back_id_attachment_main
            delete signatoryClone[i].front_id_attachment_secondary_1
            delete signatoryClone[i].back_id_attachment_secondary_1
            delete signatoryClone[i].front_id_attachment_secondary_2
            delete signatoryClone[i].back_id_attachment_secondary_2
            delete signatoryClone[i].front_id_attachment_expired
            delete signatoryClone[i].back_id_attachment_expired
            delete signatoryClone[i].selfie_image

        }
        formData.append('signatories', JSON.stringify(signatoryClone));
        formData.append('step_to_update', 4)
        props.submitStep(formData, 4)
        console.log(formData)

    }

    function editBusinessInfo(val){
        props.openEdit(val)
    }

    const idFormatErrorMessage = () => {
        switch (idType) {
            case 'DRL':
                return (`Invalid Driver's License ID no. | 
                        Format should be X00-00-000000`)
            case 'PAS':
                return (`Invalid Passport ID no. | 
                        Format should be X0000000X`)
            case 'SSS':
                return (`Invalid SSS ID no. | 
                        Format should be 00-0000000-0`)
            case 'UMID':
                return (`Invalid SSS or UMID ID no. | 
                        Format should be 0000-0000000-0`)
            case 'GSIS':
                return (`Invalid GSIS ID no. | 
                        Format should be 0000-0000000-0`)
            case 'PRC':
                return (`Invalid PRC ID no. |  
                        Format should be 0000000`)
            case 'AICR':
                return (`Invalid ACR ID no. |  
                        Format should be X0000000000XX`)
            case 'POS':
                return (`Invalid Postal ID no. | 
                        Format should be 000000000000 X`)
            default:
                return (`Invalid ID no. | 
                        Format example: 000000000X`)
        }
    }

    const checkIdFormat = (id, value) => {
        switch (id) {
            case 'DRL':
               if (value.match(/^([a-zA-Z]\d{2})-(\d{2})-(\d{6})$/)){
                return false
               } else {
                return true
               }
            case 'PAS':
                if (value.match(/^([a-zA-Z])(\d{7})([a-zA-Z])$/)){
                    return false
                } 
                else if(value.match(/^([a-zA-Z])([a-zA-Z])(\d{7})$/)){
                    return false
                }
                else {
                    return true
                }
            case 'UMID':
                if (value.match(/^(\d{4})-(\d{7})-(\d)$/)){
                    return false
                } else {
                    return true
                }
            case 'SSS':
                if (value.match(/^(\d{2})-(\d{7})-(\d)$/)){
                    return false
                } else {
                    return true
                }     
            case 'GSIS':
                if (value.match(/^(\d{4})-(\d{7})-(\d)$/)){
                    return false
                } else {
                    return true
                } 
            case 'AICR':
                if (value.match(/^([a-zA-Z])(\d{10})([a-zA-Z])([a-zA-Z])$/)){
                    return false
                } else {
                    return true
                } 
            case 'POS':
                if (value.match(/^([a-zA-Z0-9]{12}) ([a-zA-Z])$/)){
                    return false
                } else {
                    return true
                }
            default:
                return false
        }
    }

    const setIdNumberLength = () => {
        switch (idType) {
            case "0":
                return 13;
            case "1":
                return 9;
            case "3":
                return 14;
            case "4":
            case "5":
            case "6":
            case "7":
            case "8":
            case "9":
            case "10":
            case "12":
                return 20;
            case "11":
                return 30;
            case "13":
                return 14;
            default:
                return 30;
        }
    }

    const handleIdNumberChange = (e, key, idType, specificKey) => {
        // let value = null;
        console.log('ARRAY', specificKey)
        const value = e.target.value ;
        const formatValue = () => {
            switch (idType) {
                case 'DRL':
                   return (value).replace(/^([a-zA-Z]\d{2})(\d{2})(\d{6})$/,"$1-$2-$3").toUpperCase();
                case 'PAS':
                   return (value).replace(/^([a-zA-Z])(\d{7})([a-zA-Z])$/,"$1$2$3").toUpperCase();
                case 'UMID':
                    return (value).replace(/^(\d{4})(\d{7})(\d)$/, "$1-$2-$3").toUpperCase();
                case 'GSIS':
                    return (value).replace(/^(\d{4})(\d{7})(\d)$/, "$1-$2-$3").toUpperCase();
                case 'SSS':
                    return (value).replace(/^(\d{2})(\d{7})(\d)$/, "$1-$2-$3").toUpperCase();
                case 'POS':
                    return (value).replace(/^([a-zA-Z0-9]{12})([a-zA-Z])$/, "$1 $2").toUpperCase();
                default:
                    return (value);
            };
        }
        // setIdFormatError(checkIdFormat(idType, value));
        setIdNumber(value);
        setIsTouched(true)
        const list = [...signatoryInfo]
        list[key][specificKey? specificKey : 'ID_number_main'] = value;
        setSignatoryInfo(list)
    }

    function handleCameraPermission(){

        playerRef.current.setAttribute('playsinline', '');
        playerRef.current.setAttribute('autoplay', '');
        playerRef.current.setAttribute('muted', '');
        playerRef.current.style.width = '100%';
        playerRef.current.style.height = '400px';

        /* Setting up the constraint */
        var facingMode = "user"; // Can be 'user' or 'environment' to access back or front camera (NEAT!)
        var constraints = {
          audio: false,
          video: {
           facingMode: facingMode
          }
        };

        if(!isIOS){
            setMessageModal('Please allow your camera access to take your selfie by enabling on your browser security settings. Remove bux.ph from list of blocked sites')
            
            if(isFirefox || isEdge){
                navigator.mediaDevices.getUserMedia(constraints)
                .then(function(stream) {
                    setCameraModal(true)
                    playerRef.current.srcObject = stream;
                })
                .catch(function(err) {
                    setBlockModal(true)
                });
            }
            else{
                navigator.permissions.query({name: 'camera'})
                .then((permissionObj) => {
                    if(permissionObj.state == "denied"){
                        setBlockModal(true)
                    }
                    else{
                        navigator.getMedia = ( navigator.getUserMedia ||
                            navigator.webkitGetUserMedia ||
                            navigator.mozGetUserMedia ||
                            navigator.msGetUserMedia);

                        if (navigator.getUserMedia) {
                            navigator.getUserMedia(constraints, function success(stream) {
                            setCameraModal(true)
                            playerRef.current.srcObject = stream;
                            }, function(error){
                            
                            setBlockModal(true)
                            });
                        }
                    }
                })
                .catch((error) => {
                    setBlockModal(true)
                })
            }
        }else{
            if(isMobileSafari || isSafari){
                setMessageModal(`iPhone Safari Blocked Access:
                Please allow camera access to bux.ph. If you have permanently blocked camera access, please follow these steps:
                Go to Setttings >  Go to Safari > Scroll to Privacy & Security > Enable Camera & Microphone Access`)
                navigator.mediaDevices.getUserMedia(constraints).then(function success(stream) {
                    setCameraModal(true)
                    playerRef.current.srcObject = stream;
                }).catch((error) => {
                    setBlockModal(true)
                });
    
            }
            else {
                setMessageModal(`Camera access is only enabled for Safari on iOS devices. Please use Safari to use the selfie feature`)
                setBlockModal(true)
            }
        }
    }

    function handleTakePhoto (dataUri, key) {
        processfile(dataURItoBlob(dataUri), key);
        try{
            let stream = playerRef.current.srcObject;
            let tracks = stream.getTracks();
            tracks[0].stop()
        }catch(error){}
        setCameraModal(false)

    }

    function closeCamera(){
        try{
            let stream = playerRef.current.srcObject;
            let tracks = stream.getTracks();
            tracks[0].stop()
        }catch(error){}

        setCameraModal(false)
    }

    function captureImage(dataURI, key){

        const context = canvasRef.current.getContext('2d');
        context.drawImage(playerRef.current , 0, 0, canvasRef.current.width, canvasRef.current.height);

        var dataURL =  canvasRef.current.toDataURL();
        handleTakePhoto(dataURL, key)
    }

    function processfile(info, key) {
        if(((info.type === 'image/jpeg' || info.type === 'image/png')))
        {            
            var reader = new FileReader();
            reader.readAsArrayBuffer(info);
            
            reader.onload = function (event) {
    
                var blob = new Blob([event.target.result]); 
                window.URL = window.URL || window.webkitURL;
                var blobURL = window.URL.createObjectURL(blob);
                console.log(info)

                setSelfieBlob(blobURL)
    
                var image = new Image();
                image.src = blobURL;
                image.onload = function() {
                    var resized = resizeMe(image); 
                    submitPhoto(resized, key)
                }
            };
        }   
    }

    function resizeMe(img) {
      
      var canvas = document.createElement('canvas');

      var width = img.width;
      var height = img.height;

      if (width > height) {
        if (width > max_width) {
          height = Math.round(height *= max_width / width);
          width = max_width;
        }
      } else {
        if (height > max_height) {
          width = Math.round(width *= max_height / height);
          height = max_height;
        }
      }
      
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      
      return canvas.toDataURL("image/jpeg",0.8); 

    }

    function dataURItoBlob(dataUri) {
        var byteString = atob(dataUri.split(',')[1]);
        var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], {type: mimeString});
        return blob
    }

    function blobToFile(theBlob){

        if (theBlob instanceof Blob) {
            //A Blob() is almost a File() - it's just missing the two properties below which we will add
            theBlob.lastModifiedDate = new Date();
            theBlob.name = 'selfie_image';
            return theBlob;
        } 
        return false;
    }

    async function submitPhoto(dataURI, key) {
        
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        var blob = dataURItoBlob(dataURI);
        const formData = new FormData();
        // formData.append('selfie_image', blob, 'selfie_image.png');

        setIsTouched(true)
        const list = [...signatoryInfo]
        list[key]['selfie_image'] = blobToFile(blob);
        setSignatoryInfo(list)

        //     formData.append('selfie_image', blob, 'selfie_image.png');
        //     formData.append('step', '4')

        
        // let response = await kycServices.submitDetails(formData);

        // if(response.status == "success"){
        //     setUploadingFrontID(false)
        //     setUploadingBackID(false)
        // }
        // else{
        //     setStep4ErrorMessage(response.message)
        //     setStep4ErrorAlert(true)
        //     setUploadingFrontID(false)
        //     setUploadingBackID(false)
        //     window.scrollTo(0, 0);
        // }

    }

    function displayBlobToImg(file, filename) {
        if(!file || file === "") {
            return null;
        }
        const url = URL.createObjectURL(file);
        return url;
    }

    function removeSelfie(key) {
        setSelfieBlob("");
        setIsTouched(true)
        const list = [...signatoryInfo]
        list[key]['selfie_image'] = "";
        setSignatoryInfo(list)
    }

    function handleIDtype(value, key, specificKey){
        setIdType(value);
        setIsTouched(true)
        const list = [...signatoryInfo]
        list[key][specificKey ? specificKey : 'ID_type_main'] = value  
        setSignatoryInfo(list)
        // if(idNumber){
        //     setIdFormatError(checkIdFormat(value, idNumber));
        // }

    }

    const uploadPrimaryId = (item, key) => {
        return (
            <div style={{marginTop: 20}}>
                {/* <div style={{marginBottom: 16,}}>
                    <Button className={kyb.mobileTipsBtnStyle}><img src={TipsIcon} style={{marginRight: '5px'}}/>Tips in Uploading ID</Button>
                </div> */}
                <div className={kyb.displayResponsive}>
                    <div style={{width: isMobile ? '100%' : '48%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div className={kyb.miniText}>Choose ID</div>
                        <Select
                            showSearch
                            style={{ width: '100%', height: '40px' }}
                            size="large"
                            onChange={(value) => handleIDtype(value, key)}
                            value={PrimaryIDType[item.ID_type_main]}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                        {
                            Object.keys(PrimaryIDType).map((key, i) => (
                            <Option value={key} >{PrimaryIDType[key]}</Option>
                            ))
                            }
                        </Select>
                    </div>
                    <div style={{width: isMobile ? '100%' : '48%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div className={kyb.miniText}>ID Number</div>
                        <Input style={{height: '40px', width: '100%'}} value={item.ID_number_main} maxLength={setIdNumberLength()} onChange={(e) => handleIdNumberChange(e, key, item.ID_type_main,)}/>
                    </div>
                </div>
                <div  style={{marginTop: 20}}>
                    <div className={kyb.miniText}>Front ID Photo</div>
                    <input type="file"
                        data-test="upload-file-input"
                        ref={item.ref1}
                        style={{display: 'none'}}
                        onChange={(e)=>handleUploadFile(e.target.files[0], item.name, item.ID_set, key, "front")}
                        accept=".jpg,.png,.jpeg"
                        >
                    </input>
                    <div className={kyb.uploadDiv}>
                        <div className={kyb.uploadText} style={{padding: '7px 0px 7px 0px'}}>
                        {/* upload */}
                            <div style={{display: item.front_id_file_name_main == null ? 'block' : 'none'}}>Upload File</div> 
                            {/* preview */}
                            <div style={{display: item.front_id_file_name_main != null ? 'block' : 'none'}}>
                                <div style={{display: 'flex'}}>
                                    <Icon type="file" className={`text-${props.segments} top-4 right-8`}/>
                                    <div className={kyb.fileName} style={{width: isMobile ? '150px' : ''}}>{item.front_id_file_name_main}</div>
                                </div>  
                            </div>
                        </div>

                        <div>
                        {/* upload */}

                            <div style={{display: item.front_id_file_name_main == null ? 'block' : 'none'}}>
                                <button data-test="upload-btn" className={`outline-btn--${props.segments} btn-small-height`} onClick={()=>item.ref1.current.click()}>
                                    <Icon type="upload" className="right-4" /> Upload
                                </button>
                            </div>
                            {/* preview */}
                            
                            <div style={{display: item.front_id_file_name_main != null ? 'block' : 'none', position: 'absolute', right: 42, marginTop: 8}}>
                                <a href onClick={()=>{handleRemoveFile(item.front_id_file_name_main, key, item.ID_set, "front")}} style={{padding: '8px 0px 0px 0px', fontWeight: 600}} className={`text-${props.segments}`}><Icon type='close' style={{fontSize: 20, fontWeight: 'bold'}} /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div  style={{marginTop: 20}}>
                    <div className={kyb.miniText}>Back ID Photo</div>
                    <input type="file"
                        data-test="upload-file-input"
                        ref={item.ref2}
                        style={{display: 'none'}}
                        onChange={(e)=>handleUploadFile(e.target.files[0], item.back_id_file_name_main, item.ID_set, key, 'back')}
                        accept=".jpg,.png,.jpeg"
                        >
                    </input>
                    <div className={kyb.uploadDiv}>
                        <div className={kyb.uploadText} style={{padding: '7px 0px 7px 0px'}}>
                        {/* upload */}
                            <div style={{display: item.back_id_file_name_main == null ? 'block' : 'none'}}>Upload File</div> 
                            {/* preview */}
                            <div style={{display: item.back_id_file_name_main != null ? 'block' : 'none'}}>
                                <div style={{display: 'flex'}}>
                                    <Icon type="file" className={`text-${props.segments} top-4 right-8`}/>
                                    <div className={kyb.fileName} style={{width: isMobile ? '150px' : ''}}>{item.back_id_file_name_main}</div>
                                </div>  
                            </div>
                        </div>

                        <div>
                        {/* upload */}

                            <div style={{display: item.back_id_file_name_main == null ? 'block' : 'none'}}>
                                <button data-test="upload-btn" className={`outline-btn--${props.segments} btn-small-height`} onClick={()=>item.ref2.current.click()}>
                                    <Icon type="upload" className="right-4" /> Upload
                                </button>
                            </div>
                            {/* preview */}
                            
                            <div style={{display: item.back_id_file_name_main != null ? 'block' : 'none', position: 'absolute', right: 42, marginTop: 8}}>
                                <a href onClick={()=>{handleRemoveFile(item.name, key, item.ID_set, "back")}} style={{padding: '8px 0px 0px 0px', fontWeight: 600}} className={`text-${props.segments}`}><Icon type='close' style={{fontSize: 20, fontWeight: 'bold'}} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const returnFrontFileName = (object, item) => {
        switch (object) {
            case 1:
                return item.front_id_file_name_secondary_1;
            case 2:
                return item.front_id_file_name_secondary_2;
            case 3:
                return item.front_id_file_name_expired;
            default:
                return item.front_id_file_name_secondary_1;
        }
    }

    const returtBackFileName = (object, item) => {
        switch (object) {
            case 1:
                return item.back_id_file_name_secondary_1;
            case 2:
                return item.back_id_file_name_secondary_2;
            case 3:
                return item.back_id_file_name_expired;
            default:
                return item.back_id_file_name_secondary_1;
        }
    }

    const returtIdType = (object, item) => {
        switch (object) {
            case 1:
                return item.ID_type_secondary_1;
            case 2:
                return item.ID_type_secondary_2;
            case 3:
                return item.ID_type_expired;
            default:
                return item.ID_type_secondary_1;
        }
    }

    const returtIdNumber = (object, item) => {
        switch (object) {
            case 1:
                return item.ID_number_secondary_1;
            case 2:
                return item.ID_number_secondary_2;
            case 3:
                return item.ID_number_expired;
            default:
                return item.ID_number_secondary_1;
        }
    }

    const returtIdTypeKey = (object, item) => {
        switch (object) {
            case 1:
                return 'ID_type_secondary_1';
            case 2:
                return 'ID_type_secondary_2';
            case 3:
                return 'ID_type_expired';
            default:
                return 'ID_type_secondary_1';
        }
    } 

    const returtIdNumberKey = (object, item) => {
        switch (object) {
            case 1:
                return 'ID_number_secondary_1';
            case 2:
                return 'ID_number_secondary_2';
            case 3:
                return 'ID_number_expired';
            default:
                return 'ID_number_secondary_1';
        }
    } 

    const returtUploadFrontFileKey = (object, item) => {
        switch (object) {
            case 1:
                return {
                        name:'front_id_file_name_secondary_1',
                        attachement: 'front_id_attachment_secondary_1'
                        };
            case 2:
                return {
                        name:'front_id_file_name_secondary_2',
                        attachement: 'front_id_attachment_secondary_2'
                        };
            case 3:
                return {
                        name:'front_id_file_name_expired',
                        attachement: 'front_id_attachment_expired'
                        };;
            default:
                return {
                        name:'front_id_file_name_secondary_1',
                        attachement: 'front_id_attachment_secondary_1'
                        };;
        }
    }

    const returtUploadBackFileKey = (object, item) => {
        switch (object) {
            case 1:
                return {
                        name:'back_id_file_name_secondary_1',
                        attachement: 'back_id_attachment_secondary_1'
                        };
            case 2:
                return {
                        name:'back_id_file_name_secondary_2',
                        attachement: 'back_id_attachment_secondary_2'
                        };
            case 3:
                return {
                        name:'back_id_file_name_expired',
                        attachement: 'back_id_attachment_expired'
                        };;
            default:
                return {
                        name:'back_id_file_name_secondary_1',
                        attachement: 'back_id_attachment_secondary_1'
                        };;
        }
    }

    const returtRef1 = (object, item) => {
        switch (object) {
            case 1:
                return item.ref_1_secondary_1;
            case 2:
                return item.ref_1_secondary_2;
            case 3:
                return item.ref_1_expired;
            default:
                return item.ref_1_secondary_1;
        }
    }  

    const returtRef2 = (object, item) => {
        switch (object) {
            case 1:
                return item.ref_2_secondary_1;
            case 2:
                return item.ref_2_secondary_2;
            case 3:
                return item.ref_2_expired;
            default:
                return item.ref_2_secondary_1;
        }
    } 

    const disabledSelect = (key, index, item) => {
        if(index === 1 && item.ID_type_secondary_2 === key) {
            return true;
        } else if(index === 2 && item.ID_type_secondary_1 === key) {
            return true;
        }
        return false;
    }

    // const returtUploadBackFileKey = (object, item) => {
    //     switch (object) {
    //         case 1:
    //             return 'back_id_file_name_secondary_1';
    //         case 2:
    //             return 'back_id_file_name_secondary_2';
    //         case 3:
    //             return 'back_id_file_name_expired';
    //         default:
    //             return 'back_id_file_name_secondary_1';
    //     }
    // } 

    const uploadSecondaryId = (item, key) => {
        return (
            <div style={{marginTop: 20}}>
                {/* <div style={{marginBottom: 16,}}>
                    <Button className={kyb.mobileTipsBtnStyle}><img src={TipsIcon} style={{marginRight: '5px'}}/>Tips in Uploading ID</Button>
                </div> */}
                <div style={{width: '100%', margin: '16px 0 4px 0'}}>
                    <NoteCard note={
                        <span>
                            In choosing “My primary ID is expired”, We will require you to submit at least two secondary ID with expired primary ID.
                        </span>
                    }/>
                </div>
               { [1,2,3].map((object, index) => {
                    const front_id_file_name = returnFrontFileName(object, item);
                    const back_id_file_name = returtBackFileName(object, item);
                    const id_type = returtIdType(object, item);
                    const id_number = returtIdNumber(object, item);
                    return (
                    <>
                        {/* <div
                            style={{
                                backgroundColor: 'rgba(226, 76, 76, 0.1)',
                                borderLeft: '4px solid #E24C4C',
                                width: '100%',
                                height: '100%',
                                borderRadius: '4px',
                                marginTop: '20px',
                                textAlign: 'center',
                                padding: '10px 30px',
                                display: stepErrorAlert ? "block" : "none"
                            }}>
                            <span classNam={kyb.normalText}>{stepErrorMessage}</span>
                        </div> */}
                        <div className={kyb.headerStrong} style={{marginTop: '16px'}}>
                            { object === 1 ? '1st Secondary ID' : object === 2 ? '2nd Secondary ID' : 'Expired Primary ID'}
                        </div>
                        <div className={kyb.displayResponsive}>
                            <div style={{width: isMobile ? '100%' : '48%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                                <div className={kyb.miniText}>Choose ID</div>
                                <Select
                                    showSearch
                                    style={{ width: '100%', height: '40px' }}
                                    size="large"
                                    onChange={(value) => handleIDtype(value, key, returtIdTypeKey(object))}
                                    value={object === 3? PrimaryIDType[id_type] : SecondaryIDType[id_type]}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                {
                                    Object.keys(object === 3? PrimaryIDType : SecondaryIDType).map((key, i) => (
                                    <Option value={key} disabled={disabledSelect(key, object, item)} >{object === 3? PrimaryIDType[key] : SecondaryIDType[key]}</Option>
                                    ))
                                    }
                                </Select>
                            </div>
                            <div style={{width: isMobile ? '100%' : '48%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                                <div className={kyb.miniText}>ID Number</div>
                                <Input style={{height: '40px', width: '100%'}} value={id_number} maxLength={setIdNumberLength()} onChange={(e) => handleIdNumberChange(e, key, id_type, returtIdNumberKey(object, item))}/>
                            </div>
                        </div>
                        <div  style={{marginTop: 20}}>
                            <div className={kyb.miniText}>Front ID Photo</div>
                            <input type="file"
                                data-test="upload-file-input"
                                ref={returtRef1(object, item)}
                                style={{display: 'none'}}
                                onChange={(e)=>handleUploadFile(e.target.files[0], item.name, item.ID_set, key, 'front', returtUploadFrontFileKey(object))}
                                accept=".jpg,.png,.jpeg"
                                >
                            </input>
                            <div className={kyb.uploadDiv}>
                                <div className={kyb.uploadText} style={{padding: '7px 0px 7px 0px'}}>
                                {/* upload */}
                                    <div style={{display: front_id_file_name == null ? 'block' : 'none'}}>Upload File</div> 
                                    {/* preview */}
                                    <div style={{display: item[returtUploadFrontFileKey(object).name] != null ? 'block' : 'none'}}>
                                        <div style={{display: 'flex'}}>
                                            <Icon type="file" className={`text-${props.segments} top-4 right-8`}/>
                                            <div className={kyb.fileName} style={{width: isMobile ? '150px' : ''}}>{front_id_file_name}</div>
                                        </div>  
                                    </div>
                                </div>

                                <div>
                                {/* upload */}

                                    <div style={{display: front_id_file_name == null ? 'block' : 'none'}}>
                                        <button data-test="upload-btn" className={`outline-btn--${props.segments} btn-small-height`} onClick={()=>returtRef1(object, item).current.click()}>
                                            <Icon type="upload" className="right-4" /> Upload
                                        </button>
                                    </div>
                                    {/* preview */}
                                    
                                    <div style={{display: front_id_file_name != null ? 'block' : 'none', position: 'absolute', right: 42, marginTop: 8}}>
                                        <a href onClick={()=>{handleRemoveFile(item.front_id_file_name_main, key, item.ID_set, "front", returtUploadFrontFileKey(object))}} style={{padding: '8px 0px 0px 0px', fontWeight: 600}} className={`text-${props.segments}`}><Icon type='close' style={{fontSize: 20, fontWeight: 'bold'}} /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div  style={{marginTop: 20}}>
                            <div className={kyb.miniText}>Back ID Photo</div>
                            <input type="file"
                                data-test="upload-file-input"
                                ref={returtRef2(object, item)}
                                style={{display: 'none'}}
                                onChange={(e)=>handleUploadFile(e.target.files[0], back_id_file_name, item.ID_set, key, 'back', returtUploadBackFileKey(object))}
                                accept=".jpg,.png,.jpeg"
                                >
                            </input>
                            <div className={kyb.uploadDiv}>
                                <div className={kyb.uploadText} style={{padding: '7px 0px 7px 0px'}}>
                                {/* upload */}
                                    <div style={{display: back_id_file_name == null ? 'block' : 'none'}}>Upload File</div> 
                                    {/* preview */}
                                    <div style={{display: item[returtUploadBackFileKey(object).name] != null ? 'block' : 'none'}}>
                                        <div style={{display: 'flex'}}>
                                            <Icon type="file" className={`text-${props.segments} top-4 right-8`}/>
                                            <div className={kyb.fileName} style={{width: isMobile ? '150px' : ''}}>{back_id_file_name}</div>
                                        </div>  
                                    </div>
                                </div>

                                <div>
                                {/* upload */}

                                    <div style={{display: back_id_file_name == null ? 'block' : 'none'}}>
                                        <button data-test="upload-btn" className={`outline-btn--${props.segments} btn-small-height`} onClick={()=>returtRef2(object, item).current.click()}>
                                            <Icon type="upload" className="right-4" /> Upload
                                        </button>
                                    </div>
                                    {/* preview */}
                                    
                                    <div style={{display: back_id_file_name != null ? 'block' : 'none', position: 'absolute', right: 42, marginTop: 8}}>
                                        <a href onClick={()=>{handleRemoveFile(item.name, key, item.ID_set, "back", returtUploadBackFileKey(object))}} style={{padding: '8px 0px 0px 0px', fontWeight: 600}} className={`text-${props.segments}`}><Icon type='close' style={{fontSize: 20, fontWeight: 'bold'}} /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )})
                }
            </div>
        )
    }

    return(
        <div>
        <Card 
        className={kyb.formCardStyle}
        bodyStyle={{padding: '20px 16px 20px 16px'}}>
            <div className="bottom-32 twoCol">
                <div className={kyb.headerStyle}style={{ fontSize: isMobile ? '16px' : '20px'}}>Signatory Information</div>
                <div>
                    <a href className={`text-${props.segments} medium-font-weight`} onClick={() => editBusinessInfo(4)}
                    hidden={['CO','SW'].includes(localStorage.getItem('userType')) && kybStep < 7 || localStorage.getItem('KYBType') == 'creation'  && signatoryStep < 7}
                        >Cancel
                    </a>
                </div>
            </div>

            <NoteCard note={note} />

            {props.alertMessage}

            <div hidden={error} 
                    style={{
                        backgroundColor: 'rgba(226, 76, 76, 0.1)',
                        borderLeft: '4px solid #E24C4C',
                        width: '100%',
                        borderRadius: '4px',
                        marginBottom: '20px',
                        marginTop: '20px',
                        textAlign: 'left',
                        padding: '10px',
                    }}>
                    {errorMessage}
            </div>
            <div
                style={{
                    backgroundColor: 'rgba(226, 76, 76, 0.1)',
                    borderLeft: '4px solid #E24C4C',
                    width: '100%',
                    height: '100%',
                    borderRadius: '4px',
                    marginTop: '20px',
                    textAlign: 'center',
                    padding: '10px 30px',
                    display: stepErrorAlert ? "block" : "none"
                }}>
                <span classNam={kyb.normalText}>{stepErrorMessage}</span>
            </div>

            <div style={{margin: '30px 0px 10px 0px'}}>
                <div className={kyb.headerStrong}>
                    Authorized Signatory Name
                </div>
            </div>

            {
                signatoryInfo.map((item, key) => {
                    return(
                        <div key={key}>
                            <div style={{margin: '30px 0px 10px 0px', display: 'flex', justifyContent: 'space-between'}}>
                                <div className={kyb.headerStrong}>
                                    Signatory {key+1}
                                </div>
                                
                                <div hidden={key == 0}>
                                    <div>
                                    {signatoryInfo.length !== 1 && <a href
                                    className={`text-${props.segments} wc-header right-8`}
                                    onClick={() => handleRemoveClick(key)}>Remove</a>}
                                    </div>
                                </div>
                            </div>
                            <div className={kyb.displayResponsive}>
                                <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                                    <div className={kyb.miniText}>First Name</div>
                                    <TextInput data-test="input-fn" maxLength={30} placeholder={"First name"} name={"first_name"} value={item.first_name} handlechangeSig={handleChangeOfficers} step={4} index={key}/>
                                    
                                </div>
            
                                <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                                    <div className={kyb.miniText}>Middle Name (Optional)</div>
                                    <Input data-test="input-mn" name="middle_name" placeholder="Middle Name" value={item.middle_name} onChange={e => handleChangeOfficers(e, key)}/>
                                    
                                </div>
            
                                <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                                    <div className={kyb.miniText}>Last Name</div>
                                    <TextInput data-test="input-ln" maxLength={30} placeholder={"Last name"} name={"last_name"} value={item.last_name} handlechangeSig={handleChangeOfficers} step={4} index={key}/>

                                </div>
                            </div>

                            <div style={{marginTop: 20}}>
                                <div className={kyb.miniText}>Position/Designation</div>
                                <Select
                                        data-test="input-pos"
                                        showSearch
                                        style={{ width: '98%'}}
                                        optionFilterProp="children"
                                        value={POSITION[item.position]}
                                        onChange={e => handleDesignation(e, key)}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                                POSITION.length > 0 && POSITION.map((item, i) => {
                                                return (
                                                <Option key={i} value={i}>{item}</Option>
                                                )
                                            }, this)
                                        }
                                </Select>

                            </div>   

                            <div style={{marginTop: 20}} hidden={item.ID_set !== ""}>
                                <div className={kyb.miniText}>Valid ID of Signatory</div>
                                <input type="file"
                                    data-test="upload-file-input"
                                    ref={item.ref}
                                    style={{display: 'none'}}
                                    onChange={(e)=>handleUploadFile(e.target.files[0], item.name, "d", key)}
                                    accept=".jpg,.png,.jpeg"
                                    >
                                </input>
                                <div className={kyb.uploadDiv}>
                                    <div className={kyb.uploadText} style={{padding: '7px 0px 7px 0px'}}>
                                    {/* upload */}
                                        <div style={{display: item.file_name == null ? 'block' : 'none'}}>Upload File</div> 
                                        {/* preview */}
                                        <div style={{display: item.file_name != null ? 'block' : 'none'}}>
                                            <div style={{display: 'flex'}}>
                                                <Icon type="file" className={`text-${props.segments} top-4 right-8`}/>
                                                <div className={kyb.fileName} style={{width: isMobile ? '150px' : ''}}>{item.file_name}</div>
                                            </div>  
                                        </div>
                                    </div>

                                    <div>
                                    {/* upload */}

                                        <div style={{display: item.file_name == null ? 'block' : 'none'}}>
                                            <button data-test="upload-btn" className={`outline-btn--${props.segments} btn-small-height`} onClick={()=>item.ref.current.click()}>
                                                <Icon type="upload" className="right-4" /> Upload
                                            </button>
                                        </div>
                                        {/* preview */}
                                        
                                        <div style={{display: item.file_name != null ? 'block' : 'none', position: 'absolute', right: 42, marginTop: 8}}>
                                            <a href onClick={()=>{handleRemoveFile(item.name, key)}} style={{padding: '8px 0px 0px 0px', fontWeight: 600}} className={`text-${props.segments}`}><Icon type='close' style={{fontSize: 20, fontWeight: 'bold'}} /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Primary Id */}
                            <div style={{marginTop: 20}}>
                                <div className={kyb.headerStrong}>
                                    List of Valid IDs
                                </div>
                                <Collapse accordion style={{color: '0DAED0', marginTop: '20px' }} expandIconPosition="right" defaultActiveKey={'1'} >
                                    <Panel header={<Typography style={{fontWeight: '700', fontSize: '12px', padding: '12px 0'}}>Primary ID</Typography>} key="1">
                                        <ul>
                                            {primaryIdList.map((id, i) => 
                                                <li>{id}</li>
                                            )}
                                        </ul>
                                    </Panel>
                                    <Panel header={<Typography style={{fontWeight: '700', fontSize: '12px', padding: '12px 0'}}>Secondary ID</Typography>} key="2">
                                        <ul>
                                            {secondaryIdList.map((id, i) => 
                                                <li>{id}</li>
                                            )}
                                        </ul>
                                    </Panel>
                                </Collapse>
                                <div className={kyb.headerStrong} style={{marginTop: 20}}>
                                    What type of valid ID you have?
                                </div>
                                <div style={{margin: '12px 0', gap: '12px'}}>
                                    <button className={item.ID_set === "a" ? `outline-btn--${props.segments}` : ``} 
                                        style={{borderRadius: '24px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)', backgroundColor: '#FFF', border: item.ID_set !== "a" && '1px solid #D4D5D8', height: '32px', fontWeight: '700', padding: '0 12px'}}
                                            onClick={() => {setIdSet("a"); handleIdSetChange("a", key);}}>
                                        I have primary ID</button>
                                    <button className={item.ID_set === "b" ? `outline-btn--${props.segments}` : ``} 
                                        style={{borderRadius: '24px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)', backgroundColor: '#FFF', border: item.ID_set !== "b" && '1px solid #D4D5D8', height: '32px', fontWeight: '700', padding: '0 12px', marginLeft: '12px'}}
                                        onClick={() => {setIdSet("b"); handleIdSetChange("b", key);}} >
                                        My primary ID is expired</button>
                                </div>
                            </div>
                            {
                               item.ID_set === "a"? uploadPrimaryId(item, key) : uploadSecondaryId(item, key)
                            }                           
                            <div style={{marginTop: 20}}>
                                <div className={kyb.headerStrong}>
                                    Selfie Photo
                                </div>
                                <div style={{width: '100%', margin: '16px 0 12px 0'}}>
                                    <NoteCard note={
                                        <span>
                                            Grant permission to access camera in your browser.
                                        </span>
                                    }/>
                                </div>
                                <div className={kyb.selfieUploadDiv} style={{ marginBottom: '20px'}}>
                                    <div style={{display: 'flex'}}>
                                        <div style={{display: 'flex'}} hidden={item.selfie_image === ""}>
                                            <img src={item.selfie_image instanceof Blob? displayBlobToImg(item.selfie_image) : item.selfie_image} alt="#" style={{height: '100%'}}/>
                                            <span className={kyb.selfieFileName} style={{ width: 300}} hidden={isMobile}>selfie.png</span>
                                        </div>
                                    </div>
                                    {
                                        item.selfie_image != "" ?
                                        <a onClick={()=>removeSelfie(key)} className={`text-${props.segments}`} style={{margin: '20px 20px 0 0'}}>
                                            <Icon type='close' style={{fontSize: 20, fontWeight: 'bold'}} />
                                        </a>
                                        :
                                        <Button className={kyb.uploadPhoto} onClick={()=>handleCameraPermission()}><Icon type="camera" style={{fontSize: '12px'}}/> Take a selfie</Button>
                                    }
                                </div>
                                
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}} hidden={isMobile}>
                                    {/* <Button loading={loading} className={kyb.submitBtnStyle } style={{ backgroundColor: segments == 'me_' ? '#0DAED0' : segments == 'gig' ? '#41BD5C' : '#F5922F', opacity: validatePhotos() ? 0.5 : 1}} onClick={()=>checkDetails()} disabled={validatePhotos()}>
                                        <div style={{...Style.boldText, color: "#fff", display: 'inline-block', marginLeft: loading ? '20px' : '0px'}}>{props.details.step == 3 || props.details.step == 4 || props.details.step == 5 ? 'Submit and next' : 'Save'}</div>
                                    </Button> */}
                                </div>

                                <div style={{
                                    width: '100%', 
                                    position: 'fixed', 
                                    display: 'flex',
                                    backgroundColor:'#fff', 
                                    height: '74px', 
                                    bottom: '0', 
                                    marginLeft: '-16px',
                                    padding:'10px 20px 10px 20px'
                                }} hidden={!isMobile}>
                                    {/* <button onClick={()=>setStep(2)} style={Style.backBtnMobile} className={`outline-btn--${segments}`}>
                                        Back
                                    </button> */}
                                    {/* <Button loading={loading} style={{...Style.submitBtnStyle, opacity: validatePhotos() ? 0.5 : 1}} onClick={()=>checkDetails()} disabled={validatePhotos()}>
                                        <div style={{...Style.boldText, color: "#fff", display: 'inline-block', marginLeft: loading ? '20px' : '0px'}}>{props.details.step == 3 || props.details.step == 4 || props.details.step == 5 ? 'Submit and next' : 'Save'}</div>
                                    </Button> */}
                                </div>

                                <Modal
                                    visible={blockModal}
                                    onCancel={()=>setBlockModal(false)}
                                    footer={null}
                                    >
                                    <div style={{marginTop: '50px'}} align='center'>
                                        <img src={CameraIcon} />
                                        <div className={kyb.boldText} style={{ color: '#2b2d33'}}>Camera access is disabled</div>
                                        <div className={kyb.normalText} style={{ marginTop: '20px'}}>
                                            {messageModal}
                                        </div>
                                        <button className={kyb.submitBtnStyle} style={{ marginTop: '20px', color:'#fff', fontWeight: '600'}} onClick={() => setBlockModal(false)}> Got It </button>
                                    </div>
                                </Modal>

                                <div className={kyb.cameraCardWeb} align='center' hidden={!cameraModal}>
                                    <div align="right"><button className={kyb.closeCamera} onClick={() =>closeCamera()}><Icon type="close" /></button></div>
                                    <video ref={playerRef} style={{ width: '100%', height: '90%'}} playsInline />
                                    <button className={kyb.captureBtn} onClick={() => captureImage(playerRef.current, key)}/>
                                    <canvas ref={canvasRef} width="1000" height="600" style={{ display: 'none', position: 'absolute'}} />
                                </div>
                            </div>
                        </div>
                        )
                    })
                }
                <div className="top-16">
                    {signatoryInfo.length !== 3 && 
                        <button className={`outline-btn--${props.segments} btn-mobile-width btn-align-left btn-height`} onClick={() => addInfo()}>
                            <Icon type="plus" className={`text-${props.segments} right-8`} /> Add new signatory
                        </button>
                    }
                </div>

            <div hidden={isMobile} align="right">
                
                <button className={`btn--${props.segments} btn-height top-20 right-8`}
                data-test="upload-btn"
                style={{
                        opacity: !formSubmitable || !props.offSpin || (props.editMode && !isTouched) ? 0.5 : '', 
                        cursor:  !formSubmitable || !props.offSpin || (props.editMode && !isTouched) ? 'not-allowed' : 'pointer'}}
                disabled={!formSubmitable||!props.offSpin||(props.editMode && !isTouched)}
                onClick={() => submitStep4()}
                        > 
                    <div hidden={props.offSpin}><Spin /></div>
                    
                    <div hidden={!props.offSpin}>
                    {
                        adminRole.includes(userRole) && localStorage.getItem('KYBType') != "creation" ? "Save Changes" : 
                        kybStep >= 6 ? "Save Changes" : 
                        "Submit and next"
                    }
                    </div>
                </button>
                {props.footerNote}

            </div>

        </Card>

        <div className={kyb.stickyBtn} hidden={!isMobile}>
                <button className={`btn--${props.segments} btn-mobile-width`} 
                    style={{opacity: !formSubmitable || !props.offSpin || (props.editMode && !isTouched) ? 0.5 : '', 
                            cursor:  !formSubmitable || !props.offSpin || (props.editMode && !isTouched) ? 'not-allowed' : 'pointer'}}
                    disabled={!formSubmitable||!props.offSpin||(props.editMode && !isTouched)}
                    onClick={() => submitStep4()}
                            > 
                    <div hidden={props.offSpin}><Spin /></div>
                    
                    <div hidden={!props.offSpin}>
                    {
                        adminRole.includes(userRole) ? "Save Changes" : 
                        kybStep >= 6 ? "Save Changes" : 
                        "Submit and next"
                    }
                    </div>
                </button>

        </div>
        </div>
    )
}

export default SignatoryInformation
