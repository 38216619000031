import React from 'react';
import { Dropdown, Icon, Menu, Table, message, Pagination, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

const EndorementTable = (props) => {
  const { data, onUpdate, isLoading, length, selected, sort, onChangePage, currentPage } = props;
  const [minPage, setMinPage] = React.useState(0);
  const [maxPage, setMaxPage] = React.useState(20);
  const [loading, setLoading] = React.useState(false);
  const userType = localStorage.getItem('userType');
  const showApprover = selected === 'Approved' || selected === 'Rejected';
  const [showActionModal, setShowActionModal] = React.useState(false);
  const [ID, setID] = React.useState(null);
  const [actionType, setActionType] = React.useState(null);
  const actionHidden = selected === 'Approved' || selected === 'Rejected';

  const EndorsementColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (text, record) => <Typography style={styles.boldtext}>{text}</Typography>
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (text, record) => <Typography style={{ whiteSpace: 'nowrap' }}>&#8369; {text}</Typography>
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      render: (text, record) => <Typography style={{ whiteSpace: 'nowrap' }}>&#8369; {text}</Typography>
    },
    {
      title: 'Transaction Date',
      dataIndex: 'tranx_date',
      render: (text, record) => <Typography>{text}</Typography>
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (text, record) => <Typography>{text}</Typography>
    },
  ]

  const returnStatus = (key) => {
    switch (key) {
      case 'PE':
        return 'Pending';
      case 'PA':
        return 'Paid';
      case 'FA':
        return 'Failed';
      case 'SU':
        return 'Success';
      case 'CA':
        return 'Cancelled';
      case 'EX':
        return 'Expired';
      default:
        return key;
    }
  }

  const success = (msg) => {
    message.success(msg);
  };

  const errorPrompt = (msg) => {
    message.error(msg);
  };

  const handleAction = async (status) => {
    // const param = {id: ID, status: status}
    // setLoading(true);
    // try {
    //   const res = await endorsementServices.updateEndorsementStatus(param)
    //   if(res.status==='success'){
    //     success(res.message);
    //     onUpdate();
    //     setShowActionModal(false);
    //   } else {
    //     errorPrompt(res.message);
    //   }
    //   setLoading(false);
    // } catch (error) {
    //   console.log(error);
    //   setLoading(false);
    //   errorPrompt(error);
    // }
  }

  const returnType = () => {
    switch (selected) {
      case 'Payment Request':
        return 'payment';
      case 'Payout':
        return 'payout';
      default:
        return 'payment';
    }
  }

  const changePage = (page, pageSize) => {
    onChangePage(page);
  }

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 100)
  }, [])

  return (
    <div>
      <Table
        rowClassName="table-row-light"
        columns={EndorsementColumns}
        dataSource={data ? data['data'] : []}
        loading={isLoading || loading}
        pagination={false}
      />
      <Pagination
        size='small'
        total={data? data['pages'] * data['page_size'] : 0}
        defaultPageSize={data? data['page_size'] : 10}
        pageSize={data['page_size']? data['page_size'] : 10}
        defaultCurrent={1}
        current={data? data['page_number'] : 1}
        onChange={(page, pageSize) => changePage(page, pageSize)}
        style={{ padding: '12px 0 8px 0', textAlign: 'right' }}
      />
    </div>
  )
}

const styles = {
  positive: {
    borderRadius: 4,
    padding: '4px 8px',
    backgroundColor: 'rgb(232, 250, 243)',
    border: '1px solid #1DD28B',
    borderLeft: '5px solid #1DD28B',
  },
  negative: {
    borderRadius: 4,
    padding: '4px 8px',
    backgroundColor: '#f9dbdb',
    border: '1px solid #E24C4C',
    borderLeft: '5px solid #E24C4C',
  },
  pending: {
    borderRadius: 4,
    padding: '4px 8px',
    backgroundColor: 'rgb(253 234 215)',
    border: '1px solid #F5922F',
    borderLeft: '5px solid #F5922F',
  },
  boldtext: {
    color: '#2B2D33',
    fontWeight: '600',
    fontSize: 14
  },
  merchantEmail: {
    color: '#909196',
    fontSize: 14,
    fontWeight: '500',
    lineHeight: '1'
  }
}

export default EndorementTable;