import React from 'react';
import { Modal, Input} from 'antd';

const RebateModal = props => {
    const {
        data, modalState, setModalState, handleChange, submitData,
        selectText
    } = props;

    const parsingString = (str) => {
        return parseFloat(str).toFixed(2)
    }

    return(
        <Modal visible={modalState} 
            onCancel={() => setModalState(false)}
            width="500px"
            footer={null}>
            <div>
                <div className="large-bold-text liveColor">Edit</div>
                <div className="wc-header top-20">
                    Average Monthly Transaction Volume
                </div>
                <div className="twoCol top-16">
                    <div>
                        <div className="normalTextStyle">
                            Minimum
                        </div>
                        <div className="top-4">
                            <Input 
                                onChange={handleChange} 
                                type="number" 
                                name="tier_minimum" 
                                value={data.tier_minimum}
                                style={{
                                    borderColor: 
                                        (data.tier < 6 && 
                                            (data.tier_minimum > data.tier_maximum || 
                                                !data.tier_minimum )
                                                ) ? '#E24C4C' : ''
                                }}/>
                        </div>
                    </div>
                    <div>
                        <div className="normalTextStyle">
                            Maximum
                        </div>
                        <div className="top-4">
                            <Input 
                                onChange={handleChange} 
                                type="number" 
                                name="tier_maximum" 
                                value={data.tier_maximum}
                                style={{
                                    borderColor: 
                                        (data.tier < 6 && 
                                            (data.tier_maximum < data.tier_minimum || 
                                                !data.tier_maximum)
                                                ) ? '#E24C4C' : ''
                                }}/>
                        </div>
                    </div>
                </div>

                <div className="top-32">
                    <div className="wc-header">
                        Rebates
                    </div>
                    <div className="twoCol top-16">
                        <div>
                            <div className="normalTextStyle">
                                Over-the-Counter
                            </div>
                            <div className="top-4">
                                <Input 
                                    style={{width: '98%'}} 
                                    addonBefore={"₱"} 
                                    type="number" 
                                    name="otc_rebates" 
                                    id={`otc-rebate-${data.tier}`}
                                    value={data.otc_rebates} 
                                    onChange={handleChange}
                                    onFocus={() => selectText(data, "otc")}
                                    />
                            </div>
                        </div>
                        <div>
                            <div className="normalTextStyle">
                                Web Banking
                            </div>
                            <div className="top-4">
                                <Input 
                                    addonBefore={"₱"} 
                                    type="number" 
                                    name="web_banking_rebates" 
                                    id={`web-rebate-${data.tier}`}
                                    value={data.web_banking_rebates} 
                                    onChange={handleChange}
                                    onFocus={() => selectText(data, "web")}
                                    />
                            </div>
                        </div>
                    </div>

                    <div className="twoCol top-16">
                        <div>
                            <div className="normalTextStyle">
                                E-wallet (GrabPay)
                            </div>
                            <div className="top-4">
                                <Input 
                                    style={{width: '98%'}} 
                                    addonBefore={"₱"} 
                                    type="number" 
                                    name="grabpay_rebates" 
                                    id={`ew-grabpay-rebate-${data.tier}`}
                                    value={data.grabpay_rebates} 
                                    onChange={handleChange}
                                    onFocus={() => selectText(data, "ew-grabpay")}
                                    />
                            </div>
                        </div>
                        <div>
                            <div className="normalTextStyle">
                                E-wallet (GCash)
                            </div>
                            <div className="top-4">
                                <Input 
                                    addonBefore={"₱"} 
                                    type="number" 
                                    name="gcash_rebates" 
                                    id={`ew-gcash-rebate-${data.tier}`}
                                    value={data.gcash_rebates} 
                                    onChange={handleChange}
                                    onFocus={() => selectText(data, "ew-gcash")}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="top-32" align="right">
                    <button className="outline-btn--nil btn-height right-8" onClick={() => setModalState(false)}>
                        Cancel
                    </button>
                    <button className="btn--nil btn-height" onClick={() => submitData()}>
                        Save
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default RebateModal;
