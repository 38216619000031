import React,{ useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Card, List, Badge, Button } from "antd";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from 'moment';
import { 
  NotifPaymentRequest,
  NotifPayout,
  NotifMultiwallet,
  NotifRecon,
  NotifPaymentRequestSched,
  NotifAddMoney,
  NotifSystemMaintenance,
  NotifKYBKYC,
  NotifPromotion,
  NotifRefund,
  NotifNewStore,
  NotifNewOTC,
  NotifNewEWallet,
  NotifNewCCDC,
  NotifNewBank,
  NotifUpgradeAccount  
} from '../../../static/icons/notification_icons';
import dropdown_icon from '../../../static/icons/dropdown_icon.svg';
import { history } from '../../../store/history';
import Payout from "../../AdminComponents/payout/payouts";
import notificationServices from '../notificationServices';
import notificationActions from '../notificationActions';



const segments = localStorage.getItem('segments')
const sampleNotifData = [
    {
      name: 'cashout',
      text: 'Hellow World',
      time: '2020-05-15 14:59:54.756+08',
      status: 'unread'
    },
    {
      name: 'card',
      text: 'Sa classroom may batas',
      time: '2020-05-15 12:32:54.756+08',
      status: 'unread'
    },
    {
      name: 'Notif 3',
      text: 'Bawal Lumabas',
      time: '2020-05-15 11:23:24.756+08',
      status: 'read'
    },
    {
      name: 'Notif 4',
      text: 'Ay pede na pala lumabas',
      time: '2020-05-15 08:59:54.756+08',
      status: 'unread',
    },
    {
      name: 'Notif 5',
      text: 'Pag nagcomply ka',
      time: '2020-05-15 08:32:54.756+08',
      status: 'read'
    },
    {
      name: 'Notif 6',
      text: 'Pags sinabing pag',
      time: '2020-05-15 10:23:24.756+08',
      status: 'read'
    }
];

const renderIcon = (type) => {
  switch (type) {
    case 'Payout':
      return NotifPayout;
    case 'Card':
      return NotifNewCCDC;
    case 'Payment':
      return NotifPaymentRequest;
    case 'Payment Schedule':
      return NotifPaymentRequestSched;
    case 'KYC':
      return NotifKYBKYC;
    case 'KYB':
      return NotifKYBKYC;
    case 'New Bank Channel':
      return NotifNewBank; 
    case 'Promo':
      return NotifPromotion;   
    case 'Conversion':
      return NotifUpgradeAccount;  
    default:
      return NotifSystemMaintenance
  }
}

const returnLink = (type, status, others) => {

  const link = type + ' ' + status + ' ' + localStorage.getItem('Step');
  if(type === 'Payout') {
    return '/funds';
  } 
  else if (type === 'Payment') {
      if(others) {
        return `/payment/${others}`
      }
      
      return '/orders';
  } 
  else if (type === 'KYB') {
      return '/upgrade_enterprise'
  } 
  else if(type === "GCash") {
    return '/announcement'
  }
    
  else {
        switch (link) {
          case 'KYC Approved 10':
          case 'KYC Rejected 9':  
            return '/complete_profile'
          case 'KYC Approved 10':
          case 'KYC Rejected 14':  
          case 'KYC Rejected 19':
            return '/dashboard'
          default:
            return '/dashboard';
    }
  }

}

const returnStatus = (type, status) => {
  const data = type + ' ' + status;
  switch (data) {
    case 'KYC Approved' :
      return 'Approved Application';
    case 'KYC Rejected':
      return 'Rejected Application';
    case 'KYC Pending':
      return 'Pending Application';
    default:
      return data;
  }
}

const segmentsColor = {
  'me_':{
      color: '#0DAED0',
      border: '2px solid #0DAED0'
  },
  'gig':{
      color: '#41BD5C',
      border: '2px solid #41BD5C'
  },
  'sme':{
      color: '#E3632A',
      border: '2px solid #E3632A'
  },
  'biz':{
      color: '#0051B0',
      border: '2px solid #0051B0'
  }
}


const RenderNotifCard = ({data, hideDropdown}) => {
    console.log(data)
    return (
      <Card size="small" style={{borderRadius: 0}} bodyStyle={{padding: 0}}>
        <List 
          loadMore={
            <div style={styles.btnDiv}>
              <a style={{fontWeight: '700', fontSize: '16px'}} 
                className={`text-${segments}`} 
                onClick={() => {
                  history.push('/notifications')
                  hideDropdown();
                }}>
                  See all
              </a>
            </div>
          }
          dataSource={data ? data : []}
          renderItem={ item =>
            <List.Item style={{ padding:16, backgroundColor: !item.is_read ? '#FAFAFA' : null}}>
              <a style={styles.link} href={returnLink(item.category, item.status, item.others)}>
                <Badge dot={item.is_read == false} style={{padding: 5, margin: '2px 5px', backgroundColor: '#E24C4C' }}>
                  <img src={renderIcon(item.category)} alt='category' />
                </Badge>
                <div style={styles.textContent}>
                  <label style={{fontSize: '12px', color: '#2B2D32'}}>{item.category} {item.status}</label>
                  <label style={{fontSize: '12px', color: '#909196'}}>{moment(item.created_at).startOf('minutes').fromNow()}</label>
                </div>
                <ArrowForwardIosIcon style={{fontSize: 14, color: '#2B2D33'}} />
              </a>
          </List.Item>
          }
        />
      </Card>
    )
}

const NotificationDropdown = props => {
    const dispatch = useDispatch();

    const notificationHeaderData = useSelector(state => state.notification.notifHeaderData);
    const notifCount = useSelector(state => state.notification.notificationCount)

    const [selectedTab, setSelectedTab] = React.useState("All")
    const [previewTotalNotifs, setPreviewTotalNotifs] = React.useState(0)

    const { hideDropdown } = props

    useEffect(()=>{
      getNotifData({target: 'preview'})
    },[])

    const markAllRead = async() => {
      const response = await notificationServices.getNotifications({target: 'read_all'})
  
      if(response){
        getNotifData({target: 'preview', type: selectedTab})
        dispatch(notificationActions.setNotificationData(response))
        dispatch(notificationActions.setNotificationCount(0))
      }
  
    }

    const getNotifData = async (params) => {
      try {
        const response = await notificationServices.getNotifications(params);
        setPreviewTotalNotifs(response.total)
        dispatch(notificationActions.setNotificationHeaderData(response.notifications))
      } 
      catch (error) {}
    }

    const ButtonTab = () => {

      const ButtonGroup = Button.Group
      const status = ['All', 'Unread'];
      
      const handleClick = (tab) => {
          setSelectedTab(tab);
          getNotifData({target: 'preview', type: tab});
      }
      
      return (
        <div style={styles.btnTabContainer}>
          <ButtonGroup>
            {
              status.map((btn, i) => {
                return (
                  <Button
                      value={btn}
                      onClick={()=>handleClick(btn)}
                      style={{
                          ...styles.btnGroup, 
                          color: selectedTab === btn ? segmentsColor[segments].color : '#909196',
                          borderBottom: selectedTab === btn ? segmentsColor[segments].border : '0' 
                      }}
                      >
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          {btn} 
                          <div 
                            onClick={()=>handleClick(btn)}
                            style={{...styles.countStyle, 
                              backgroundColor: selectedTab === btn ? segmentsColor[segments].color : '#909196'}}>
                                {
                                  btn == 'All' ? previewTotalNotifs : notifCount 
                                }
                          </div>
                        </div>
                  </Button>            
                )
              })
            }
          </ButtonGroup>
        </div>
      )
    }

    return (
      <div style={styles.root}>
        <div style={{padding: '16px 16px 0px 16px', display:'flex', justifyContent: 'space-between'}}>
            <div style={styles.header}>Notification</div>
            <div onClick={()=>markAllRead()} style={styles.markAll}>Mark all as read</div>
        </div>
        <ButtonTab/>
        <RenderNotifCard data={notificationHeaderData} hideDropdown={()=>hideDropdown()}/>
      </div>
    )
}

const styles = {
  root: {
    marginTop: 26, 
    height: '100%', 
    width: '314px', 
    overflowY: 'auto',
    backgroundColor: '#FFF',
  },
  header:{
    fontSize: '14px',
    fontWeight: '700',
    color: '#2b2d32',
  },
  markAll:{
    fontWeight: '400',
    fontSize: '12px',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#2b2d32'
  },
  link: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-evenly', 
    width: '100%',
  },
  textContent: {
    display:'flex', 
    flexDirection: 'column', 
    alignItems: 'start', 
    padding: '0 12px 0 12px', 
    width: '80%'
  },
  btnDiv: {
    padding: '12px 0px', 
    borderTop: '1px solid #DDDDDD',
    textAlign: 'center',
  },
  btn: {
    width: '100%', 
    fontSize: 12, 
    fontWeight: 'bold', 
    color: '#F5922F'
  },
  btnGroup: {
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '5px 20px 12px 20px',
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    borderRadius: '0px'
  },
  btnTabContainer: {
      padding: '20px 0px 0px 16px',
  },

  countStyle:{
    height: '20px',
    minWidth: '20px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: '700',
    color: '#FFF',
    marginLeft: '11px',
    padding: '1px 6px 0px 6px'
  }
}

export default NotificationDropdown;