import React,{useEffect} from 'react';
import { Card, Icon, Divider, Button, Table } from 'antd';
import { Grid } from '@material-ui/core';
import kycStyles from '../../static/css/Kyc';
import {isMobile} from 'react-device-detect';
import {history} from '../../store/history'
import axios from 'axios'
import MetaTag from '../meta_tag/meta_tag'
import Questionnaire from '../questionnaire/questionnaire'
import * as Style from './application_form/kyc_style'
import ReviewImg from '../../static/images/review.svg'

const UpgradeApplication = props => {

    const ButtonGroup = Button.Group;

    const kyc = kycStyles()
    const [dateApplied, setDateApplied] = React.useState("")
    const [applicationCooldown, setApplicationCooldown] = React.useState(null)
    const [period, setPeriod] = React.useState("Monthly")
    const [datas, setDatas] = React.useState({})
    const [showModal, setModal] = React.useState(false)

    function openModal(val){
        setModal(val)
    }
    const [step, setStep] = React.useState(0)

    useEffect(() => {

        let current_path = window.location.pathname
        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            history.push('/login?next='+current_path)
        }
        else{
            if(isMobile){
                window.location.href = '/mobile/upgrade'
            }
            else if(localStorage.getItem("userType") != "ME"){
                history.push('/dashboard')
            }
            else{
                fetchProfile()
                getDashboardNow()
            }
        }
        
    },[])

    const getDashboardNow = async () => {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try {
            let response = await axios.get(
                process.env.REACT_APP_API_URL + '/api/dashboard/', yourConfig
            );
            if(response.data){
                setDatas(response.data)
            }
        }

        catch (error) {

        }
    }

    async function fetchProfile(){
    
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try {
            let response = await axios.get(process.env.REACT_APP_API_URL + '/api/kyc/', yourConfig)
            
            setStep(response.data.step)
            setDateApplied(response.data.submission_date)
            if(response.data.application_cooldown){
                setApplicationCooldown(response.data.application_cooldown/60/60/24)
            }

        }
        catch(error){

        }
        
    }

    const  periods = [
        'Monthly Limits'
    ]


    const monthly = [
        {
            cashIn: 'PHP 5,000.00',
            cashOut: 'PHP 0.00'
        },
        {
            cashIn: 'PHP 100,000.00',
            cashOut: 'PHP 100,000.00'
        },
        {
            cashIn: 'PHP 500,000.00',
            cashOut: 'PHP 500,000.00'
        },
        {
            cashIn: 'PHP 1,000,000.00',
            cashOut: 'PHP 1,000,000.00'
        },
    ]

    const annual = [
        {
            cashIn: 'PHP 50,000.00',
            cashOut: 'PHP 0.00'
        },
        {
            cashIn: 'PHP 400,000.00',
            cashOut: 'PHP 400,000.00'
        },
        {
            cashIn: 'UNLIMITED',
            cashOut: 'UNLIMITED'
        },
        {
            cashIn: 'UNLIMITED',
            cashOut: 'UNLIMITED'
        },

    ]

    const requirements_column = [
        {
          title: 'Level',
          dataIndex: 'level',
          render: text => <strong>{text}</strong>,
        },
        {
          title: 'Requirements',
          dataIndex: 'requirements',
          render: text => <div>{text}</div>,
        },
        
    ];

    const requirements_data = [
        {
            key: '1',
            level: 'Basic',
            requirements: 
            <div><strong>Personal and Contact Information</strong> (Name, Email, Contact No. and Password)</div>,
        },
        {
            key: '2',
            level: 'Pro',
            requirements: <strong>Basic Requirements + Address, Employment Information and Valid ID</strong>,
        },
        {
            key: '3',
            level: 'Advanced',
            requirements: 
            <div><strong>20 Paid transactions</strong> at any given time.</div>,
        },
        {
            key: '4',
            level: 'Business',
            requirements: <div><strong>100 Paid transactions</strong> at any given time.</div>,
        },
    ]

    
    return(
        <div>
            <MetaTag title="Upgrade Account"/>
            <div className={kyc.mainBody} align="center">
                <div className={kyc.header} align='left'>Application Status</div>

                <Card
                    style={{...Style.reviewCard, width: '727px', marginTop: '20px', marginLeft: '-70px'}} 
                    bodyStyle={{padding: '20px 0px 20px 0px'}}>
                    <div style={{display:'flex', justifyContent: 'space-between', padding: '0px 20px 0px 20px'}}>
                        <div>
                            <div style={Style.header2Style} align="left">{localStorage.getItem('Step') > 10 ? 'PRO to ADVANCE Upgrade' : 'BASIC to PRO Upgrade'}</div>
                            <div style={{...Style.normalText, color: '#000'}} align="left">Date Application: <b>{dateApplied}</b></div>
                            <div style={{...Style.normalText, color: '#000', display:'flex', marginTop: '10px'}}>
                                Status 
                                <div style={{
                                    border: step == 7 ? '1px solid #F5922F' : step == 9 || step == 14 ? '1px solid #E24C4C' : step == 10 || step == 15 ? '1px solid #1DD28B' : '1px solid #F5922F', 
                                    height: '32px',
                                    width: '97px',
                                    borderLeft: step == 7 ? '4px solid #F5922F' : step == 9 || step == 14 ? '4px solid #E24C4C' : step == 10 || step == 15 ? '4px solid #1DD28B' : '4px solid #F5922F', 
                                    borderRadius: '4px',
                                    textAlign: 'center',
                                    backgroundColor: step == 7 ? 'rgba(245, 146, 47, 0.1)' : step == 9 || step == 14 ? 'rgba(226, 76, 76, 0.1)' : step == 10 || step == 15 ? 'rgba(29, 210, 139, 0.1)' : 'rgba(245, 146, 47, 0.1)',
                                    marginLeft: '10px'
                                }}>
                                    <div style={{...Style.miniText, color: '#000', lineHeight: '32px'}}>{step == 7 ? 'Reviewing' : step == 9 || step == 14 ? 'Rejected' : step == 10 ? 'Approved' : 'Pending'}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img src={ReviewImg} alt="#" style={{position:'relative', marginBottom: '-125px', width: isMobile ? '113px' : '162px'}}/>
                        </div>
                    </div>

                    <Divider/>

                    <div style={{textAlign: 'center'}}>
                        <div style={{...Style.normalText, color: '#000'}}>Haven’t received your status yet? Please contact us at <a href="mailto:support@bux.ph" style={{color: '#f5922f', fontWeight: '600'}}><u>support@bux.ph</u></a></div>
                    </div>
                </Card>

                <div style={{marginTop: '22px', width: '750px'}} align='right'>
                    <a href="/complete_profile" className={kyc.seeMore} hidden={localStorage.getItem('Step') == '10' || localStorage.getItem('Step') == '15'  || localStorage.getItem('Step') == '16'}>More Details <Icon type="right"/></a>
                </div>

                <div className={kyc.limits} align="center">
                    <div className={kyc.header} align='left'>Individual Account Levels</div>
                    <div align='left' style={{marginTop: '20px'}}>
                    <ButtonGroup>
                    {
                    periods.map((item) => {
                        return <Button
                        key={item.toLocaleLowerCase()}
                        onClick={() => setPeriod(item)}
                        style={{
                            padding: '13px 18px 13px 18px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            lineHeight:'16px',
                            height:'40px',
                            color: '#000000',
                            border: 'none',
                            background: '#f5f5f4'
                            // borderTopLeftRadius: item == "Monthly" ? '10px' : '0px',
                            // borderBottomLeftRadius: item == "Monthly" ? '10px' : '0px',
                            // borderTopRightRadius: item == "Annual" ? '10px' : '0px',
                            // borderBottomRightRadius: item == "Annual" ? '10px' : '0px',
                        }}>{item}</Button>
                    })
                    }
                    </ButtonGroup>
            
                    </div>
                    <Grid container style={{marginTop: 24}}>
                        <Grid lg={3} style={{paddingRight: 12}}>
                            <div className={kyc.basicCard}>
                                <div className={kyc.basicHeader}>
                                    Basic
                                </div>

                                <div className={kyc.basicInfo}>
                                    <div>Cash In</div>
                                    <b>
                                        {
                                            period == 'Monthly' ? 

                                            monthly[0].cashIn

                                            : annual[0].cashIn
                                        }
                                    </b>

                                    <div style={{width: '100%', height:'2px', backgroundColor: '#65C1F2', margin: '20px 0px 20px 0px'}}></div>

                                    <div>Cash Out</div>
                                    <b>
                                        {
                                            period == 'Monthly' ? 

                                            monthly[0].cashOut

                                            : annual[0].cashOut
                                        }
                                    </b>

                                </div>
                                <div>
                                    <Button 
                                        type="primary" 
                                        disabled={true} 
                                        className={kyc.disableBtn}>
                                        {localStorage.getItem("Level") == 0 ? "Current" : "Upgrade"}
                                    </Button>
                                </div>
                            </div>
                        </Grid>

                        <Grid lg={3} style={{paddingRight: 12}}>
                            <div className={kyc.proCard}>
                                <div className={kyc.proHeader}>
                                    Pro
                                </div>
                                
                                <div className={kyc.basicInfo}>
                                    <div>Cash In</div>
                                    <b>
                                        {
                                            period == 'Monthly' ? 

                                            monthly[1].cashIn

                                            : annual[1].cashIn
                                        }
                                    </b>

                                    <div style={{width: '100%', height:'2px', backgroundColor: '#38ADA9', margin: '20px 0px 20px 0px'}}></div>

                                    <div>Cash Out</div>
                                    <b>
                                        {
                                            period == 'Monthly' ? 

                                            monthly[1].cashOut

                                            : annual[1].cashOut
                                        }
                                    </b>

                                </div>
                                <Button 
                                    type="primary" 
                                    disabled={localStorage.getItem("Level") != 0} 
                                    className={localStorage.getItem("Level") == 0 ? kyc.upgradeBtn : kyc.disableBtn} 
                                    onClick={()=>window.location.href = '/complete_profile' }>
                                    {localStorage.getItem("Level") == 1 ? "Current" : "Upgrade"}
                                </Button>
                            </div>
                        </Grid>

                        <Grid lg={3} style={{paddingRight: 12}}>
                            <div className={kyc.businessCard}>
                                <div className={kyc.busiHeader}>
                                    Advanced
                                </div>

                                <div className={kyc.basicInfo}>
                                    <div>Cash In</div>
                                    <b>
                                        {
                                            period == 'Monthly' ? 

                                            monthly[2].cashIn

                                            : annual[2].cashIn
                                        }
                                    </b>

                                    <div style={{width: '100%', height:'2px', backgroundColor: '#1E3799', margin: '20px 0px 20px 0px'}}></div>


                                    <div>Cash Out</div>
                                    <b>
                                        {
                                            period == 'Monthly' ? 

                                            monthly[2].cashOut

                                            : annual[2].cashOut
                                        }
                                    </b>

                                </div>

                                <Button 
                                    type="primary" 
                                    disabled={localStorage.getItem("Level") != 1 || datas.paid < 20 || (applicationCooldown && applicationCooldown < 30)} 
                                    className={localStorage.getItem("Level") == 1 && datas.paid >= 20 && ((applicationCooldown && applicationCooldown >= 30) || !applicationCooldown) ? kyc.upgradeBtn : kyc.disableBtn} 
                                    onClick={()=> openModal(true)}>
                                    {localStorage.getItem("Level") == 2 ? "Current" : "Upgrade"}
                                </Button>
                            </div>
                        </Grid>

                        <Grid lg={3}>
                            <div className={kyc.enterpriseCard}>
                                <div className={kyc.enterpriseHeader}>
                                    Business
                                </div>

                                <div className={kyc.basicInfo}>
                                    <div>Cash In</div>
                                    <b>
                                        {
                                            period == 'Monthly' ? 

                                            monthly[3].cashIn

                                            : annual[3].cashIn
                                        }
                                    </b>

                                    <div style={{width: '100%', height:'2px', backgroundColor: '#1E3799', margin: '20px 0px 20px 0px'}}></div>


                                    <div>Cash Out</div>
                                    <b>
                                        {
                                            period == 'Monthly' ? 

                                            monthly[3].cashOut

                                            : annual[3].cashOut
                                        }
                                    </b>

                                </div>
                                <Button 
                                    type="primary" 
                                    disabled={ localStorage.getItem("Level") != 2 || datas.paid < 100 || (applicationCooldown && applicationCooldown < 30)}
                                    className={localStorage.getItem("Level") == 2 && datas.paid >= 100 && ((applicationCooldown && applicationCooldown >= 30) || !applicationCooldown) ? kyc.upgradeBtn : kyc.disableBtn} 
                                    onClick={() => openModal(true)}
                                    >
                                    {localStorage.getItem("Level") == 3 ? "Current" : "Upgrade"}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                    <div>
                        <div className={kyc.header} align='left'>Requirements</div>
                        <div style={{marginTop: '20px'}}>
                            <Table 
                            rowClassName="table-row-light"
                            columns={requirements_column} 
                            dataSource={requirements_data} />

                        </div>

                    </div>
                    <Questionnaire open={showModal} onClose={() => setModal(false)} />

                </div>
            </div>
        </div>
    )
}


export default UpgradeApplication;