import React,{  } from "react";
import { Advisory } from "../../../static/icons";

const styles = {
  root: {
    width: '48vw',
    border: '1px solid #F8B966',
    borderRadius: '4px',
    backgroundColor: 'rgba(248, 185, 102, 0.1)',
    display: 'flex',
    padding: 16,
    textAlign: 'justify',
    margin: '0px 0px 20px 0px'
  },
  title: {
    paddingBottom: 8, 
    fontSize: 18, 
    fontWeight: 'bold', 
    color: '#000000'
  },
  icon: {
    flex: 1, 
    alignSelf: 'center', 
    margin: '0px 8px 0px 0px'
  },
  bankName: {
    fontSize: 14, 
    color: '#000000', 
    margin:'0px 0px 4px 0px',
  },
  date:{
    fontWeight: '600',
    display: 'inline'
  },
  inline:{ 
    display: 'inline'
  }
}

const renderAdvisory = (props) => {
  const Notification = (data) => {
    return (
      <div style={styles.bankName}>
        <strong>{data.name}</strong> - from
        <div style={styles.date}> {data.startDate}</div>
        { data.startTime ? <div style={styles.inline}> {data.startTime}</div>
          : null}
        { data.endDate ? <div style={styles.inline}> to
          <div style={styles.date}> {data.endDate}</div>
          { data.endTime ? <div style={styles.inline}> {data.endTime}.</div>
            : <div style={styles.inline}>.</div> }
          </div>
          : <div style={styles.inline}>.</div>}
      </div>
    )
  }

  return (
    <div>
      {props ? props.map((item, index) => {
        return (
          <Notification key={index} {...item} />
        )
      })
      :null}
    </div>
  )
}

// [{"name": "Philippines Veterans Bank", "startDate": "April 15, 2020","startTime":"from 6:00 PM to 7:00 PM", "endDate": "April 25, 2020", "endTime":"from 2:00 AM to 11:00 AM"},
// {"name": "Land Bank", "startDate": "April 15, 2020","startTime":"from 6:00 PM to 7:00 PM", "endDate": "April 25, 2020", "endTime":"from 2:00 AM to 11:00 AM"}]

const BankAdvisoryCard = () => {
  let advisory = [];
  try{
    advisory = JSON.parse(localStorage.getItem('banks_disabled'));
  }catch(exception){
  }
    return (
      advisory && advisory.length > 0 ? 
      <div style={styles.root}>
        <div style={styles.icon}>
            <img src={Advisory} />
        </div>
        <div style={{flex: 10}}>
          <div style={styles.title}>
            Upcoming Maintenance for the following banks:
          </div>
          {renderAdvisory(advisory)}
        </div>
        </div> 
      : null
    )
} 

export default BankAdvisoryCard;