import React, {useEffect, useState} from 'react';
import axios from 'axios'
import { Button, DatePicker, Input, Table, Select, Icon, Modal, Pagination, message} from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import { getRecon } from './reconAction';
import { history } from '../../../store/history';
import ClearFilter from '../filter_btns/clear_filter_btn';
import moment from 'moment';
import ActiveCheck from '../../../static/icons/active_check.svg'
import HumanIcon from '../../../static/icons/human.svg'

const ReconTPP = props => {

  const dispatch = useDispatch();

  const segments = localStorage.getItem('segments');
  const {Option} = Select

  const yourConfig = {
    headers: {
       Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  }

  const {recon, loading, error}  = useSelector(state => state.recon)

  const userType = localStorage.getItem("userType")
  const jwtToken = localStorage.getItem("jwtToken")

  const [exportModal, setExportModal] = useState(false)
  const [isFiltered, setIsFiltered] = useState(false)

  //pagee
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)

  //Date
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  //Moment
  const [startMoment, setStartMoment] = useState("")
  const [endMoment, setEndMoment] = useState("")
  const [endOpen, setEndOpen] = useState(false)

  const [email, setEmail] = React.useState("")

  const [masterUsers, setMasterUsers] = useState(null)
  const [masterUserModal, setMasterUserModal] = useState(false)
  const [masterSelected, setMasterSelected] = useState("Choose user")
  const [temporarySelectedMaster, setTemporarySelectedMaster] = useState("")


  const disabledStartDate = startValue => {
    if (!startValue || !endMoment) {
      return false;
    }
    return startValue && startValue > moment().endOf('day');
  }
  const disabledEndDate = endValue => {
      if (!endValue || !startMoment) {
          return false;
      }
      return endValue.valueOf() <= startMoment.valueOf();
  };

  const onStartChange = value => {
    setStartDate(value.format("MM-DD-YYYY"))
    setStartMoment(value)
    setIsFiltered(true)
  }

  const onEndChange = value => {
      setEndDate(value.format("MM-DD-YYYY"))
      setEndMoment(value)
      setIsFiltered(true)
  }

  const handleStartOpenChange = open => {
      if (!open) {
        setEndOpen(true)
      }
  }

  const userSelected = () => {
    setIsFiltered(true)
    setMasterUserModal(false)
    setMasterSelected(temporarySelectedMaster);
  }

  const clearFilter = () => {
    setIsFiltered(false)
    setStartDate("")
    setStartMoment("")
    setEndDate("")
    setEndMoment("")
    setMasterSelected("Choose user")
    setTemporarySelectedMaster("")
    setPageSize(10)
    setCurrentPage(1)
  }

  const pageSelect = (e) => {
    setCurrentPage(e)
    dispatch(getRecon(startDate, endDate, pageSize, e, 'unionbank', 'Unaccounted', '', masterSelected === "Choose user" ? "" : masterSelected))
  }

  const changePageSize = (e) => {
      setPageSize(e)
      setCurrentPage(1)
      dispatch(getRecon(startDate, endDate, e, 1, 'unionbank', 'Unaccounted', '', masterSelected === "Choose user" ? "" : masterSelected))
  }

  const getCount = () => {
    return recon && recon.count ? recon.count : 0;
  }

  const applyFilter = () => {
    setCurrentPage(1)
    dispatch(getRecon(startDate, endDate, pageSize, 1, 'unionbank', 'Unaccounted', 'All', masterSelected === "Choose user" ? "" : masterSelected))
  }

  const columns = [
    {
      title: 'Ref. Code',
      dataIndex: 'ref_code',
      render: text => {
        if(text == "Total")
          return <b>{text}</b>
        else
          return text
      }
    },
    {
      title: 'Total Amount',
      dataIndex: 'amount',
      render: text => {
        return <div>₱ {text}</div>
      }
    },
    {
      title: 'Receivable BUx',
      dataIndex: 'receivable_bux',
      render: text => {
        return <div>₱ {parseFloat(text).toFixed(2)}</div>
      }
    },
    {
      title: 'Receivable Merchant',
      dataIndex: 'receivable_merchant',
      render: text => {
        return <div>₱ {parseFloat(text).toFixed(2)}</div>
      }
    },
    {
      title: 'BUx Fee',
      dataIndex: 'bux_fee',
      render: text => {
        return <div>₱ {parseFloat(text).toFixed(2)}</div>
      }
    },
    {
      title: 'UnionBank Fee',
      dataIndex: 'unionbank_fee',
      render: text => {
        return <div>₱ {parseFloat(text).toFixed(2)}</div>
      }
    },
    {
      title: 'Date Paid',
      dataIndex: 'date_paid',
    },
  ];

  const masterUserColumns = [
    {
        title: 'User',
        dataIndex: 'id',
        render: (text, record) => {
            return (
            <a href className="twoCol liveColor"
                onClick={()=>setTemporarySelectedMaster(record.email)}>
                <div>
                    <a href className="liveColor nameStyle">{record.business_name}</a>
                    <div className="text-with-ellipsis">{record.email}</div>
                </div>
                <img alt="#" src={ActiveCheck} hidden={record.email != temporarySelectedMaster}/>
            </a>
            )
            
        }
    },
  ]

  const exportTable = async() => {   
        
    let response = await axios.get(
      process.env.REACT_APP_API_URL + '/api/recon/unionbank/?email='+email+'&type=unaccounted&start='+startDate+'&end='+endDate+'&export='+true+'&mode=All&master_email='+masterSelected,yourConfig
    );
    if(response.data.status=='success'){
        setExportModal(false)
        message.success(response.data.message)
    }
    else{
        setExportModal(false)
        message.error(response.data.message)
    }
  };

  const getCybersource = async(search) => {
    let response = await axios.get(
        process.env.REACT_APP_API_URL + '/api/admin/users/cybersource/?search='+search, yourConfig
    );
    if(response.data.status=='success'){
        setMasterUsers(response.data.users)
    }else{
        message.error(response.data.message)
    }
  }

  const SummaryTable = () => {
    let total = recon && recon.total ? recon.total : 0
    console.log(total)
    // console.log(total[0].total_amount)
    return(
      <div className="flex bottom-20" style={{background: '#fff', width: '100%'}}> 
        <div className="total-summary normalSizeStyle">
          No. of Transactions: <b className="left-8">{getCount()}</b>
        </div>
        <div className="total-summary normalSizeStyle">
          Total Amount: <b className="left-8">{total && total[0].total_amount}</b>
        </div>
        <div className="total-summary normalSizeStyle">
          Total BUx Fee: <b className="left-8">{total && total[0].total_bux_fee}</b>
        </div>
      </div>
    )
  }

  const start_end = (text) => {
    if (text.length > 20) {
        return text.substr(0, 5) + '...' + text.substr(text.length-10, text.length);
    }
    console.log(text)
    return text;
}

  useEffect(() => {
    if(jwtToken == "" || jwtToken == null){
            history.push('/login')
        }
        else{
            if(userType == "ME" || userType == "CO"){
                history.push('/dashboard')
            }
            else if(userType == "PS"){
                history.push('/orders')
            }
            else if(userType == "MA"){
                history.push('/overview')
            }
            else{
              getCybersource(temporarySelectedMaster )
            }
        }
  }, [])

  return(
    <div style={{padding: '0 56px 64px 0'}}>
      <div className="screen-title-text">
          UnionBank(TPP) Recon
      </div>

      <div className="top-20" align="right">
        <button className={`outline-btn--${segments}`} 
          onClick={()=>setExportModal(true)}>
            <Icon type='download' className="right-4" /> 
            Download CSV</button>
      </div>

      <div className="top-20 table-card-div">
        <div>
          <ClearFilter hideFilterBtn={!isFiltered} clearFilter={clearFilter} />
        
          <div className="top-20">
            <button onClick={()=>setMasterUserModal(true)} 
              className="normal-text-size right-8 button-select-style">
              <span className="text-with-ellipsis" style={{fontSize: 16}}>{start_end(masterSelected)}</span>
              <img className="left-16" style={{marginTop: -4}} src={HumanIcon} alt="#"/>
            </button>
            <DatePicker
                format='MM/DD/YYYY'
                disabledDate={disabledStartDate}
                // defaultValue={moment()}
                size="large"
                value={startMoment}
                onChange={onStartChange}
                onOpenChange={handleStartOpenChange}
                placeholder='Start date'
                suffixIcon={
                  <div style={{marginTop: -12, marginRight: 12, color: '#000'}}>
                    <Icon type="calendar" style={{ fontSize: 22 }} />
                  </div>
                }
                allowClear={false} />

            <span style={{ paddingTop: 8 }}>-</span>

            <DatePicker
                format='MM/DD/YYYY'
                disabledDate={disabledEndDate}
                // defaultValue={moment()}
                size="large"
                value={endMoment}
                picker="month"
                onChange={onEndChange}
                onOpenChange={(open)=>setEndOpen(open)}
                open={endOpen}
                placeholder='End date'
                suffixIcon={
                  <div style={{marginTop: -12, marginRight: 12, color: '#000'}}>
                    <Icon type="calendar" style={{ fontSize: 22 }} />
                  </div>
                }
                allowClear={false} />

            <button
              style={{marginLeft: 15}}
              className={`admin-apply-btn ${!isFiltered ? "admin-disabled-btn" : ""}`}
              disabled={!isFiltered}
              onClick={() => applyFilter()}
            >
              Apply
          </button>
            </div>
        </div>
      </div>

      <SummaryTable />

      <Table
        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        loading={loading}
        columns={columns}
        dataSource={recon && recon.transactions ? recon.transactions : []}
        pagination={false} 
      />

      <div className="top-20 flex">
          <Select
              showSearch
              onChange={(ev)=> changePageSize(ev)}
              placeholder="Page Size"
              style={{ width: '11%', marginBottom: '10px', marginLeft: 'auto', marginRight: '20px' }}
              size="small"
          >
              <Option key={1} value={5}>Show 5</Option>
              <Option key={2} value={10}>Show 10</Option>
              <Option key={3} value={20}>Show 20</Option>
              <Option key={4} value={50}>Show 50</Option>

          </Select>
          <Pagination 
              size="small"
              total={getCount()} 
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              onChange={pageSelect} 
              defaultCurrent={1} key={Math.random()} 
              pageSize={pageSize} 
              current={currentPage}/>
      </div>

      <Modal
            visible={exportModal}
            footer={null}
            width="360px"
            onCancel={()=>setExportModal(false)}
        >
            <div className="top-20">
            <div className="screen-title-text bottom-20">
              Export CSV
            </div>
            <div className="normal-text-size liveColor bottom-16">
                Please enter your email address to send CSV reports directly to your email.
            </div>
            <div style={{fontSize:'14px', color: 'rgba(43, 45, 50, 0.8)', marginBottom: '5px'}}>Send to Email</div>
            <Input 
                size="large" 
                style={{width: '100%', marginBottom: '14px'}} 
                placeholder="Email"
                onChange={(e)=>setEmail(e.target.value)} />
            <button 
                onClick={()=>exportTable()}
                disabled={email == ''}
                className={`btn--${segments}`}>Send
            </button>
            </div>
            </Modal>

      <Modal
          visible={masterUserModal}
          footer={null}
          width="360px"
          onCancel={()=>setMasterUserModal(false)}
      >
          <div className="top-20">
              <div className="screen-title-text bottom-20">
              Choose user
              </div>
              <Input size="large" 
                        placeholder="Search" 
                        style={{marginBottom: '10px'}}
                        onChange={(e)=>{
                          setTemporarySelectedMaster(e.target.value);
                          getCybersource(e.target.value)
                        }}/>
              <Table
                  pagination={{pageSize: 5}}
                  size="middle"
                  columns={masterUserColumns}
                  dataSource={masterUsers}
              />
              {/* <div style={{display: 'flex', marginTop: '20px'}}>
                  <Button style={styles.cancelBtn} onClick={()=>setMasterUserModal(false)}>Cancel</Button>
                  <Button style={styles.confirmBtn} onClick={()=>{setMasterUserModal(false); setMasterSelected(temporarySelectedMaster)}}>Confirm</Button>
              </div> */}
          </div>

          <div className="top-20" align="right">
              <button className={`outline-btn--${segments} right-8`} onClick={()=>setMasterUserModal(false)}>Cancel</button>
              <button className={`btn--${segments}`} onClick={()=> userSelected()}>Confirm</button>
          </div>
      </Modal>

    </div>
  )
}

export default ReconTPP;