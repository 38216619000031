import { Form, Input, Select, Typography } from "antd";
import React from "react";
import { PayoutIconBalance } from "../../../static/images/multiwallet";
import { useEnterprisePeers } from "../api/getPeers";
import { extractSubdomain, formatToNumbers } from "../utils";
import { validateAmount, validateEnoughBalance } from "../../../utils/validators";

export function FundTransferForm({
  form,
  amount,
  setAmount,
  selectedUser,
  setSelectedUser,
  balance,
}) {
  const currentUrl = window.location.href;
  const extractedSubdomain = extractSubdomain(currentUrl);
  const { data } = useEnterprisePeers(extractedSubdomain);

  return (
    <>
      <div style={{ backgroundColor: "rgb(245, 245, 245)", padding: "1em" }}>
        <Typography>Source of funds</Typography>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", gap: "0.5em" }}>
            <img src={PayoutIconBalance} alt="Payout Icon" />
            <Typography>Transferable Balance</Typography>
          </div>

          <Typography style={{ fontWeight: "bold" }}>
            {balance ? balance.float_balance : "₱0.00"}
          </Typography>
        </div>
      </div>
      <Form.Item label="Enter transfer amount">
        {form.getFieldDecorator("amount", {
          initialValue: amount,
          rules: [
            { required: true, message: "Please input transfer amount!" },
            { validator: (_, value) => validateAmount(value) },
            { validator: (_, value) => validateEnoughBalance(value, formatToNumbers(balance.float_balance)) }
          ],
        })(
          <Input
            type="number"
            addonBefore="₱"
            size="large"
            onChange={(e) => setAmount(e.target.value)}
          />
        )}
      </Form.Item>

      <Form.Item label="Transfer money to">
        {form.getFieldDecorator("selectedUser", {
          initialValue: selectedUser,
          rules: [{ required: true, message: "Please select a user!" }],
        })(
          <Select
            placeholder="Select a user"
            size="large"
            onChange={(value) => setSelectedUser(value)}
          >
            {data &&
              data.enterprise_peers.map((peer) => (
                <Select.Option key={peer.id} value={peer.id}>
                  {`${peer.first_name} ${peer.last_name} (${peer.email})`}
                </Select.Option>
              ))}
          </Select>
        )}
      </Form.Item>
    </>
  );
}