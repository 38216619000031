import React from 'react';
import ButtonGroup from 'antd/lib/button/button-group';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Button } from 'antd';
import PaymentHistoryTable from './payment_request_table';
import WalletHistoryTable from './wallet_table';
import PayoutHistoryTable from './payout_table';
import SubWalletSettings from './subwallet_settings';
import { segmentStroke } from '../../../../constants/constants';
import { useLocation } from 'react-router';

const UserHistoryTable = (props) => {
    const [selected, setSelected] = React.useState('Payment Request');
    const userType = localStorage.getItem('userType');
    const notAdmin = ['SW', 'ME', 'CO'].includes(userType);
    const location = useLocation();
    const data = location && location.state && location.state.data ? location.state.data : [];

    const ButtonTab = ({ rootStyle }) => {

        const status = ['Payment Request', 'Wallet', 'Payout', 'Sub-wallet Settings'];

        const changeSelectedTab = (e) => {
            setSelected(e.target.value);
        }

        return (
            <div style={styles.btnTabContainer} className="top-20">
                <ButtonGroup>
                    {
                        status.map((btn, i) => {
                            return (
                                <Button
                                    key={btn}
                                    value={btn}
                                    onClick={changeSelectedTab}
                                    style={{
                                        ...styles.btnGroup,
                                        color: selected === btn ? segmentStroke[0] : '#909196',
                                        backgroundColor: 'transparent',
                                        borderBottom: selected === btn ? `2px solid ${segmentStroke[0]}` : 'transparent'
                                    }}
                                    hidden={ notAdmin && btn === "Payout" || ((!notAdmin && data && !data.multiwallet && !notAdmin && data && !data.is_subwallet) && btn === 'Wallet') }
                                >
                                    {btn}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </div>
        )
    }

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`nav-tabpanel-${index}`}
                aria-labelledby={`nav-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box style={{ padding: 0 }} p={2}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    return (
        <div style={{width: props && props.isUserHistory && '82%'}}>
            <ButtonTab />
            <div style={styles.btnContainer}>
            </div>
            {
                selected === 'Payment Request'? <PaymentHistoryTable segments={props.segments} props={{...props}} />
                : selected === 'Wallet' ? <WalletHistoryTable segments={props.segments} props={{...props}} />
                : selected === 'Sub-wallet Settings' ? <SubWalletSettings props={{...props}}/>
                : <PayoutHistoryTable segments={props.segments} props={{...props}} />
            }
        </div>
    )
}

const styles = {
    root: {
        width: '100%',
        padding: '40px 20px',
        margin: '20px 0',
        backgroundColor: '#FFF',
        border: '1px solid #E6EAF0'
    },
    body: {
        padding: '0px 16px',
        backgroundColor: '#FFF',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    },
    btnContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #D1D5DD',
    },
    exportBtn: {
        fontSize: 16,
        fontWeight: '600',
        padding: '8px, 16px, 8px, 16px',
        border: '1px solid #1DD28B',
        height: 40,
        marginRight: 12,
        color: '#FFF',
        backgroundColor: '#1DD28B'
    },
    btnGroup: {
        fontSize: 14,
        fontWeight: '500',
        textAlign: 'center',
        height: 40,
        padding: '10px 16px 36px 16px',
        background: 'none',
        borderColor: 'transparent'
    },
    btnTabContainer: {
        width: '100%',
        padding: '20px, 20px 0 20px',
    },
    subContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 0'
    },
    clearDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    clearIcon: {
        color: '#2B2D33',
        fontSize: 10,
    },
    filterText: {
        color: '#000',
        fontSize: 14,
        fontWeight: 'bold'
    },
    clearBtnDiv: {
        backgroundColor: '#E6EAF0',
        borderRadius: 24,
        padding: '4px 12px',
        margin: '0 12px'
    },
    clearBtn: {
        color: '#000000',
        fontSize: 12,
        paddingLeft: 4,
    },
    reminder: {
        fontSize: 12,
        color: '#909196',
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 178,
        padding: '0 8px 0 8px'
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    applyBtn: {
        height: 40,
        width: 92,
        fontSize: 16,
        fontWeight: '600',
        borderRadius: 4,
        backgroundColor: '#F5922F',
        color: '#FFF',
    },
    createBtn: {
        fontSize: 16,
        fontWeight: '600',
        height: 40,
        borderColor: '#1DD28B',
        marginRight: 8,
    },
    dropdownItems: {
        display: 'flex',
        alignItems: 'center',
        padding: '7px 4px 7px 16px',
        fontSize: 16,
        color: '#2B2D33',
        border: '1px solid #D1D5DD',
        borderRadius: 5,
    },
    dropdownArror: {
        color: '#2B2D33',
        fontSize: 24,
        margin: '0 4px'
    },
}

export default UserHistoryTable;