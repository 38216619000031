import axios from 'axios';
import { Handle401 } from '../handle401/handle401'

const authorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const kycServices = {
  fetchDetails: async () => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/kyc/`);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  submitDetails: async (params) => {
    try {
      const response = await authorizedAxiosRequest().post(`/api/kyc/`, params);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
}

export default kycServices;