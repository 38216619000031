import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Divider,
  Icon,
  Pagination,
  Table,
  Typography,
  notification,
} from "antd";
import { getLimitsRequest, setLimitRequest } from "./api";
import { useDataFetching } from "../../hooks";
import Invalid from "../../../../static/icons/invalid_icon.svg";
import Valid from "../../../../static/icons/valid_icon.svg";
import { StatusChip, LimitTypeChip, UserDataCell } from "../../components";
import ButtonGroup from "antd/lib/button/button-group";

const approverLimitsStatusMap = {
  SU: "Approved",
  PE: "Pending",
  FA: "Declined",
};

export function ApproverLimits() {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const { data, loading, error, refetchData } = useDataFetching(() =>
    getLimitsRequest(currentPage)
  );

  useEffect(() => {
    refetchData();
  }, [currentPage]);

  const openNotification = (status, message) => {
    notification.info({
      message: status,
      description: message,
      placement: "topRight",
    });
  };

  const handleLimitsRequestApprove = ({ id }) => {
    setLimitRequest(id, "SU").then((res) => {
      const { status, message } = res;
      openNotification(status, message);
      refetchData();
    });
  };

  const handleLimitsRequestDecline = ({ id }) => {
    setLimitRequest(id, "FA").then((res) => {
      const { status, message } = res;
      openNotification(status, message);
      refetchData();
    });
  };

  const tableColumns = [
    {
      title: "Action",
      key: "action",
      fixed: "left",
      render: (_, record) =>
        record.status === "PE" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              icon="check"
              size="small"
              onClick={() => handleLimitsRequestApprove(record)}
            />
            <Divider type="vertical" />
            <Button
              type="danger"
              icon="close"
              size="small"
              onClick={() => handleLimitsRequestDecline(record)}
            />
          </div>
        ),
    },
    {
      title: "Reference ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Receiver",
      dataIndex: "owner_email",
      key: "owner_email",
      render: (text, record) => (
        <UserDataCell name={record.owner_name} email={record.owner_email} />
      ),
    },
    {
      title: "Requestor",
      dataIndex: "requestor_email",
      key: "requestor_email",
      render: (text, record) => (
        <UserDataCell
          name={record.requestor_name}
          email={record.requestor_email}
        />
      ),
    },
    {
      title: "Date Generated",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <StatusChip
          status={text}
          label={approverLimitsStatusMap[text] || text}
        />
      ),
    },
    {
      title: "Limit Type",
      dataIndex: "limit_type",
      key: "limit_type",
      render: (text) => <LimitTypeChip label={text} />,
    },
    {
      title: "Previous Limit",
      dataIndex: "previous_limit_value",
      key: "previous_limit_value",
    },
    {
      title: "Requested Limit",
      dataIndex: "requested_limit_value",
      key: "requested_limit_value",
    },
  ];

  return (
    <div
      style={{
        margin: "5em 2.5em 2.5em 0",
      }}
    >
      <h1
        style={{
          width: "100%",
          color: "rgb(0, 0, 0)",
          fontWeight: "bold",
          fontSize: "28px",
          display: "flex",
          alignItems: "center",
        }}
      >
        Limits Request
      </h1>
      <Card>
        {error ? (
          <div style={{ padding: "16px", color: "red" }}>
            Error fetching data. Please try again.
          </div>
        ) : (
          <div>
            <Table
              rowClassName="table-row-light"
              columns={tableColumns}
              dataSource={data ? data.results : []}
              pagination={false}
              loading={loading}
              scroll={{ x: true }}
              style={{ maxWidth: "82dvw" }}
            />
            <Pagination
              size="small"
              total={(data && data.total) || 0}
              defaultPageSize={pageSize}
              pageSize={pageSize}
              current={currentPage}
              onChange={setCurrentPage}
              style={{ paddingTop: "12px", textAlign: "right" }}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
            />
          </div>
        )}
      </Card>
    </div>
  );
}
