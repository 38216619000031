import React from 'react'
import {Bux} from '../../static/icons/index'
import Ecomx from '../../static/images/ecomx.svg'
import RegFee from '../../static/images/reg_fee.svg'
import {Card, Divider} from 'antd'
import ecomStyles from '../../static/css/Ecomx';
import * as Style from '../learn_more_page_ecomx/learn_more_style'

const RegFailedMobile = props => {
    const ecom = ecomStyles();

    const dontMissStyle = {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#2B2D33',
        textAlign: 'center',
        marginBottom: '5px'
    }

    return(
        <div style={{paddingRight: '20px', paddingLeft: '20px', marginBottom: '20px'}}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <img src={Bux} alt="#" style={Style.icon_style}/>
            </div>
            <Card
                style={{ width: '100%'}}
            >
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <img src={Ecomx} alt="#" style={{ width: '100%'}}/>
                </div>

                <div style={Style.infoStyle}>
                    <span style={{marginRight: '5px'}}>Date:</span><span style={{fontWeight: '600'}}>December 10, 2019</span>
                </div>
                <div style={Style.infoStyle}>
                    <span style={{marginRight: '5px'}}>Venue:</span><span style={{fontWeight: '600'}}>47th UnionBank Plaza Bldg., Meralco Ave. Ortigas Center, Pasig City</span>
                </div>
                <div style={Style.byNickStyle}>By Nick Peroni</div>
                
                <div>
                    <img src={RegFee} style={{width: '100%', marginBottom: '20px', marginTop: '20px'}}/>
                </div>

                <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                    <span style={Style.byNickStyle}>Powered by</span> <img src={Bux} />
                </div>

                <Divider></Divider>

                <div>
                    <div className={ecom.crsHeader} style={{textAlign: 'center', marginBottom: '20px'}}>Sign up</div>
                    <div style={dontMissStyle}>Seats are already fully-booked at this time. Come back to check availability.</div>
                </div>
                
            </Card>
        </div>
    )
}

export default RegFailedMobile;