import React from 'react'
import {Modal, Button, message, Icon} from 'antd'
import axios from 'axios'

class UploadModal extends React.Component {

    state = {
        hover: false,
        hover2: false,
        file: null,
        fileName: '',
        uploading: false,
        modal: false,
        fromHere: false,
        uploadProgress: 0,
    }
    fileUpload = React.createRef();

    textStyle = {
        fontWeight: '300',
        color: '#000',
        textAlign: 'center',
    }

    closeAllModal = () => {
        this.setState({
            file: null,
            fileName: ''
        });

        if (this.fileUpload.current) {
            this.fileUpload.current.value = "";
        }

        var self = this;
        self.props.handleClose();
    }

    handleFile = (file) => {
        var self = this;
        const mb = 1000000;
        const limit = 10;
        const max_file_size = limit * mb;
        if(file != null){
            if(file.size > max_file_size) {
                message.error(`File must not be more than ${limit}MB`)
                return
            }

            self.setState({
                file: file,
                fileName: file.name
            })
        }
    }

    handleUpload = () => {
        var self = this
        const yourConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': "Token " + localStorage.getItem("jwtToken")
            }
        }

        const formData = new FormData();
        
        formData.append('file', self.state.file);

        self.setState({
            uploading: true
        })

        let endpoint = this.props.onUploadEndpoint ? this.props.onUploadEndpoint : `/api/admin/enterprise_collection/batch_adjust/`;

        axios.post(process.env.REACT_APP_API_URL + endpoint,
            formData, yourConfig
        ).then(function (response) {
            console.log(response.data.data)
            if(response.data.status == "success"){
                self.setState({
                    modal: false,
                    uploading: false
                })
                message.success("Succesfully adjusted!")
                self.closeAllModal();
                self.props.handleRefresh()
            }
            else{
                self.setState({
                    uploading: false
                })
                message.error("The uploaded file has some data that cannot be processed. Please try again.", 3)
                self.closeAllModal();
            }
            return
        })
        .catch(function (error) {
            self.setState({
                uploading: false
            })
            message.error("Error")
            self.closeAllModal();
        });

    };
    render(){
        return(
            <div>
                <Modal
                    visible={this.props.modal}
                    footer={false}
                    width="400px"
                    closable={false}
                    bodyStyle={{padding: '60px 20px 34px 20px'}}
                >
                <div>
                    <div style={{...this.textStyle, fontSize: '32px'}}>Upload your CSV File</div>
    
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{...this.textStyle, fontSize: '16px', width: '297px'}}>Upload the CSV file with the information you want to import here.</div>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent:'center', padding: '20px'}}>
                    <input type="file"
                        ref={this.fileUpload}
                        style={{opacity: '0', position: 'fixed'}}
                        onChange={(e)=>this.handleFile(e.target.files[0])}
                        onCancel={(e)=>this.handleFile(e.target.files[0])}
                        accept=".csv"
                        >
                    </input>
                    <Button 
                        onClick={()=>this.fileUpload.current.click()}
                        style={{
                            height: '40px',
                            border: '1px solid #1DD28B',
                            backgroundColor: this.state.hover2 ? '#1DD28B' : '#fff',
                            color: this.state.hover2 ? '#fff' : '#1DD28B',
                            borderRadius: '80px',
                            fontWeight: '600',
                            fontSize: '16px',
                            width: '164px'
                        }}
                        hidden={this.state.file != null}
                        onMouseEnter={()=>this.setState({hover2:true})}
                        onMouseLeave={()=>this.setState({hover2:false})}>
                        Choose File
                    </Button>
    
                    <div 
                        style={{
                            height: '40px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            border: '1px solid #1DD28B',
                            backgroundColor: this.state.hover2 ? '#1DD28B' : '#fff',
                            color: this.state.hover2 ? '#fff' : '#1DD28B',
                            borderRadius: '80px',
                            fontWeight: '600',
                            fontSize: '16px',
                            width: '164px',
                            textAlign: 'center', 
                            padding: '8px',
                            alignItems: 'center'
                        }}
                        hidden={this.state.file == null}>
                        <div style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {this.state.fileName}
                        </div>
                        <Button 
                            onClick={() => {
                                this.setState({
                                    file: null,
                                    fileName: ''
                                })
                                this.fileUpload.current.value = "";
                            }}
                            style={{
                            color: '#fff', 
                            borderRadius: '50%', 
                            height: '25px', 
                            padding: '5px', 
                            backgroundColor: '#262f38'}}>
                         <Icon type='close' style={{ display: 'flex' }}/>
                        </Button>
                    </div>
                </div>
                <div style={{display: 'flex', marginTop: '20px'}}>
                    <Button
                        style={{
                            backgroundColor: this.state.hover ? '#F5922F' : '#fff',
                            color: this.state.hover ? '#fff' : '#F5922F',
                            fontWeight: '600',
                            fontSize: '16px',
                            borderRadius: '10px',
                            height: '48px',
                            width: '49%',
                            border: '1px solid #F5922F'
                        }}
                        onClick={()=>
                                {
                                    this.closeAllModal()
                                }
                            }
                        onMouseEnter={()=>this.setState({hover:true})}
                        onMouseLeave={()=>this.setState({hover:false})}
                        disabled={this.state.uploading}
                    >Cancel</Button>
                    <Button 
                        style={{
                            backgroundColor: '#F5922F',
                            borderRadius: '10px',
                            height: '48px',
                            width: '49%',
                            color: '#fff',
                            fontWeight: '600',
                            fontSize: '16px',
                            border: '1px solid #F5922F',
                            opacity: this.state.uploading || this.state.file == null ? '0.5' : 1, 
                            marginLeft: '2%'
                        }}
                        onClick={()=>this.handleUpload()}
                        disabled={this.state.file == null}
                        loading={this.state.uploading}
                    >{this.state.uploading ? `Uploading ${Math.round(this.state.uploadProgress,)}%` : 'Upload'}
                    </Button>
                </div>
                </Modal>
    
            </div>
        )
    }
    
}

export default UploadModal
