import React from "react";
import { Modal } from "antd";

const DialogBoxModal = (props) => {
  return (
    <Modal
      centered
      width={360}
      visible={props.openModal}
      onCancel={() => props.closeModal()}
      footer={null}
      className="dialog-box-modal"
    >
      <div className="dialog-title">Sync Fees</div>

      <div className="dialog-desc">
        Are you sure you want to sync user fees based on merchant category?
      </div>

      <div className="btn-div">
        <button className="cancel-btn" onClick={() => props.closeModal()}>
          Cancel
        </button>
        <button className="confirm-btn" onClick={() => props.onSubmit()}>
          Confirm
        </button>
      </div>
    </Modal>
  );
};

export default DialogBoxModal;
