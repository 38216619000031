import React from "react";
import { RevenuesList, ExportRevenues } from "./components";

export function RevenueWallet() {
  return (
    <div
      style={{
        margin: "5em 2.5em 2.5em 0",
        display: "flex",
        gap: "1em",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <ExportRevenues />
      </div>
      <div>
        <RevenuesList />
      </div>
    </div>
  );
}
