import { makeStyles } from '@material-ui/core';

const otpStyles = makeStyles(theme => ({
    
    main: {
        fontFamily: 'Inter',
        [theme.breakpoints.down("sm")]: {
            padding: 32
        }
        

    },

    backBtn: {
        // color: "#F5922F",
        // fontWeight: '600',
        // fontSize: '17px',
        // lineHeight: '22px',
        textTranform: 'uppercase',
        border: 0, 
        borderRadius: 40, 
        color: '#F5922F', 
        fontSize: 16
    },

    spiel: {
        marginTop: 20,
        color: '#2b2d33'
    },

    sHeader: {
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '34px',
        textAlign: 'center',
    },

    sDesc: {
        marginTop: '10px',
        fontSize: '16px',
        lineHeight: '24px',
        opacity: '0.8',
        letterSpacing: '-0.01em',
        textAlign: 'center',
        color: '#000',
    },

    inputDiv: {
        marginTop: 38,
    },

    expSpiel: {
        marginTop: 30,
    },

    submitDiv: {
        marginTop: 40,
    },

    submitBtn: {
        height: 48,
        width: '100%',
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        borderRadius: 10,
        fontSize: 14,
        fontWeight: '600',
    },

    disableBtn: {
        height: 48,
        width: '100%',
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        borderRadius: 10,
        fontSize: 14,
        fontWeight: '600',
        opacity: '0.5',
    },

    goToSignUp: {
        marginTop: 20
    },

    btnEmail: {
        fontSize: 16,
        color: '#F5922F',
        fontWeight: 'bold',
        textAlign: 'center',
        paddingBottom: 24,
      },
    
    resBtn: {
        margin: '16px 0',
        height: 48,
        width: '60%',
        color: '#F5922F',
        borderRadius: 10,
        fontSize: 14,
        fontWeight: '600',
        borderColor: '#F5922F',
    },


}));

export default otpStyles;
