export const TwoColumn = {
    display: "flex", 
    justifyContent: "space-around" 
}

export const HeaderStyle = {
    backgroundColor: "#fff",
    width: "100%",
    padding: "0",
    height: "60px",
    position: "fixed",
    zIndex: 101,
    top: "0",
}

export const GenerateBtn = {
    
    width: "150px",
    marginRight: "16px",
    display: "inline",
    backgroundColor: '#F5922F',
    border: '1px solid #F5922F',
}

export const textStyle = {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#FFFFFF"
}

export const menuStyle = {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#2b2d33"
};

export const FooterMain = {
    backgroundColor: "#fff",
    width: "100%",
    padding: '0 8px',
    height: "56px",
    position: "fixed",
    zIndex: 101,
    bottom: "0",
    boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.16)'
}

export const moreDiv = {
    background: '#F4F6F9', 
    padding: 20, 
    height: '100vh'
}

export const mainMore = {
    background: '#fff',
    border: '1px solid #e6eaf0',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
    padding: 20,
    fontFamily: 'Inter', 
    backgroundSize: '100%', 
    backgroundRepeat: 'no-repeat',
}

export const NameStyle = {
    fontWeight: 'bold',
    marginTop: '16px',
    fontSize: '18px',
    lineHeight: '32px',
}

export const NameStyle1 = {
    fontWeight: 'bold',
    marginLeft: '10px',
    marginTop: '15px',
    fontSize: '25px',
    lineHeight: '32px',
    color: '#0D3D76'
}

export const subMore = {
    display: 'flex', 
    marginBottom: 32
}

export const pillDiv = {
    borderRadius: 20,
    height: '23px'

}
export const pillText = {
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '9px',
    color: '#fff',
    textAlign: 'center'
}

export const profilePic = {
    width: '48px', 
    height:'48px', 
    borderRadius: '50%'
}

export const UpgradeDiv = {
    textAlign: 'left',
    marginTop: 16
}

export const progress = {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#54575F'
}

export const progressStep = {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#54575F'
}

export const rightIcon = {
    color: '#F5922F', 
    fontSize: '16px', 
    marginTop: '10px'
}

export const upgradeBtn = {
    marginTop: 16,
    backgroundColor: '#F5922F', 
    border:'#F5922F',
    borderRadius: '4px', 
    fontWeight: '600', 
    fontSize: '16px', 
    lineHeight: '24px', 
    color: '#fafafa', 
    width: '70%', 
    height: '32px',
    borderRadius: '24px'
}

export const upgradeLink = {
    textDecoration: 'underline',
     color: '#F5922F' 
}

export const MenuBar = { 

}

export const SubMenu = {
    marginTop: 40,
    marginLeft: 16,
    textAlign: 'left'
}

export const itemText = {
    marginLeft: 16,
    color: '#0D3D76',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '19px'

}

export const titleStyle = {
    fontSize: 16,
    lineHeight: '24px',
    color: '#0d3d76',
    fontWeight: 'bold',
}

export const iconleftStyle = {
    // color: '#F5922F', 
    fontWeight: 'bold',
    marginRight: 20,
}