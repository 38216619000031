import React from 'react'
import {Table, Dropdown, Icon, Menu, Typography} from 'antd'
import { history } from '../../../../store/history'
import EditUserModal from '../modal/editUser'

const MultiwalletUsersTable = props => {

    const {data, pageSize, loading} = props
    const [showEditUserModal, setShowEditUserModal] = React.useState(false)
    const [selectedUser, setSelectedUser] = React.useState(null)


    const handleEditUser = (details) => {
        setSelectedUser(details)
        setShowEditUserModal(true)
    }

    const menu = (details) => (
        <Menu style={{ width: '214px' }}>
            <Menu.Item key="0" style={Style.menuStyle} onClick={() => {history.push({pathname: `/admin/user_details/${details.id}/#userInfo`, state: {data: details}}); }}>
              User details
            </Menu.Item>
            <Menu.Item key="1" className="menu-style" onClick={() => handleEditUser(details)}>
                Edit user
            </Menu.Item>
        </Menu>
    );

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            render: (text, record) => {
                return <Typography style={{...Style.dataStyle, fontWeight: 'bold'}}>{record.id}</Typography>
            }
        },
        {
            title: 'Business Name',
            dataIndex: 'business_name',
            render: (text) => <Typography style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 200, overflow: 'hidden' }}>{text}</Typography>
        },
        {
            title: 'Full name and Email',
            render: (text, record) => {
                return (
                    <div>
                        <Typography style={{...Style.dataStyle, fontWeight: 'bold'}}>{record.full_name}</Typography>
                        <Typography style={Style.dataStyle}>{record.email} </Typography>
                    </div>
                )
            }
        },
        {
            title: 'Role',
            dataIndex: 'role',
            render: (text, record) => {
                return <Typography style={Style.dataStyle}>
                    {
                        record.role == 'Sub-wallet' ?
                        <div>
                            <div>Sub-wallet of</div>
                            <div>
                                <a onClick={()=>history.push(`/submissions_kyb/${record.master_id}/`)} 
                                    style={{color: '#0a315e', textDecoration: 'underline'}}>
                                    <b>{record.master}</b>
                                </a>
                            </div>
                        </div> : 
                        record.role
                    }
                </Typography>
            }
        },
        {
            title: 'Date created',
            dataIndex: 'date_created',
            render: text => {
                return <Typography style={Style.dataStyle}>{text}</Typography>
            }
        },
        {
            title: 'Date approved',
            dataIndex: 'date_approved',
            render: text => {
                return <div>
                    <Typography style={Style.dataStyle}>{text}</Typography>
                </div>
            }
        },
        {
            title: 'Action',
            render: (text, record) => (
                <span>
                    <Dropdown overlay={() => menu(record)} placement="bottomLeft" trigger={['click']} >
                        <Icon type="ellipsis" style={{fontSize: '24px'}} />
                    </Dropdown>
                </span>
            ),
        }

    ];
    return(
        <div>
            <Table
                loading={loading}
                pagination={{position: 'none', pageSize: pageSize}}
                dataSource={data ? data.results : null} 
                columns={columns}/>
            <EditUserModal openModal={showEditUserModal} setOpenModal={setShowEditUserModal} data={selectedUser} />
        </div>
    )
}

const Style = {
    dataStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2B2D33'
    },
    menuStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33',
        padding: '10px',
        margin: 'auto'
    }
}

export default MultiwalletUsersTable