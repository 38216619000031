import {
    GET_LEDGER_BEGIN,
    GET_LEDGER_SUCCESS,
    GET_LEDGER_ERROR,
} from './ledgerConstants';
import axios from "axios";
import { Handle401 } from '../../handle401/handle401';

export const getLedgerBegin = () => {
    return {type: GET_LEDGER_BEGIN};
}

export const getLedgerSuccess = (ledger) => {
    return {type: GET_LEDGER_SUCCESS, payload: ledger};
}

export const getLedgerError = (errors) => {
    return {type: GET_LEDGER_ERROR, payload: errors};
}

const handle401 = (error) => {

    if((error.response)&&(error.response.status == 401)){
        Handle401()
    }
}

export const getLedger = (search, type, startDate, endDate, paymentMethods='', page=1) => {

    return (dispatch) => {
        dispatch(getLedgerBegin());
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
         }
        
        axios.get(
            process.env.REACT_APP_API_URL+
            '/api/ledger/?type='+type+
            '&search='+search+
            '&start='+startDate+
            '&end='+endDate+
            '&payment_method='+paymentMethods+
            '&page='+page, 
            yourConfig)
        .then(
            response => dispatch(getLedgerSuccess(response.data)))
        .catch((error) => handle401(error));

    }
}