import React, { useEffect, useState } from "react";
import CreateFundTransfer from "./components/CreateFundTransfer";
import { FundTransfersList } from "./components";
import { TransferPanel } from "../Transfer/routes/merchant/components";
import { getLatestBalance } from "../Transfer/api";

export function FundTransfers() {
  const [balance, setBalance] = useState({});
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("right");

  useEffect(() => {
    getLatestBalance().then((data) => setBalance(data));
  }, []);

  const showDrawer = () => {
    setOpen(true);
    document.body.style.overflow = "hidden";
  };

  const onClose = () => {
    setOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <div
      style={{
        margin: "5em 2.5em 2.5em 0",
        display: "flex",
        gap: "1em",
        flexDirection: "column",
      }}
    >
      <TransferPanel
        balance={balance.float_balance ? balance.float_balance : "₱ 0.00"}
        handleDrawerOpen={showDrawer}
      />

      <div>
        <CreateFundTransfer
          open={open}
          onClose={onClose}
          placement={placement}
          balance={balance}
        />
      </div>

      <div>
        <FundTransfersList />
      </div>
    </div>
  );
}
