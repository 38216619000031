import React from 'react';
import * as Icon from '../../static/icons/how_it_works/index';
import * as Img from '../../static/images/how_it_works/index';
import ContentCard from './content_card';
import PayoutStep from './reusable_content/payout';

const ContentStep3 = props => {
    
    const {
        segments
    } = props;

    const userType = localStorage.getItem('userType')

    const isIndividual = userType === 'ME'

    let multiwallet_icon = segments == 'me_' ? Icon.Multiwallet_me_ : 
                            segments == 'gig' ? Icon.Multiwallet_gig : 
                                segments == 'sme' ? Icon.Multiwallet_sme : 
                                    segments == 'biz' ? Icon.Multiwallet_biz: ""



    return (
        <div>
            {
                isIndividual ?
                    <PayoutStep segments={segments} step={3} />
                    :
                    <ContentCard step={3}
                        icon={multiwallet_icon}
                        image={Img.Multiwallet}
                        title={"Multi-wallet"}
                        content={"This tab displays multi-wallet balance, transaction summary, and list of sub-wallets."} />
            }
            
        </div>
    )
}

export default ContentStep3;