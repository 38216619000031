import React from 'react'
import axios from 'axios'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button, Select, Table, Divider, DatePicker, Pagination, Input, message} from 'antd'
import {history} from '../../../store/history'
import { getContent } from './contentAction';
import moment from 'moment';
import '../../../static/css/antd.css'
import {ExportToCsv} from 'export-to-csv'
import UploadModal from './upload_modal'

export function closeModal(){
  this.setState({
    modal: false
  })
}
const { Option } = Select;
const ButtonGroup = Button.Group;


const mapStateToProps = (state) => {
  const { content, loading, error } = state.content;
  return {
    content,
    loading,
    error,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    getContent: bindActionCreators(getContent, dispatch),
  };
};

const type_dict = {'Unaccounted (Bux)': 'Unaccounted',
                   'Accounted': 'Accounted',
                   'Unaccounted (Partner)': 'Missing'}

const dateFormat = 'MM-DD-YYYY';

function disabledDate(current) {
  // Can not select days before today and today
  return current && current.day() != 4 || current > moment().endOf('day');
}

class ContentSettings extends React.Component{

  componentDidMount(){
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
      history.push('/login')
    }
    else{
      if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
        history.push('/dashboard')
      }
      else if(localStorage.getItem("userType") == "PS"){
        history.push('/orders')
      }
      else if(localStorage.getItem("userType") == "MA"){
        history.push('/overview')
      }
      else{
        this.props.getContent();
      }

    }
  }

  constructor(props){
    super(props)
    closeModal = closeModal.bind(this)
  }

  state = {
      data : [],
      page: 1,
      count: 0,
      page_size: 10,
      fileList: [],
      uploading: false,
      alertMessage: '',
      showAlert: false,
      alert: 'success',
      show: 'Unaccounted (Bux)',
      emailReceipt: '',
      hover1: false,
      hover2: false,
      modal: false,
      mode: 0
  }

  handleChange(selectorFiles: FileList)
  {
      console.log(selectorFiles);
  }

  // renderUnaccounted(){
  //   const {
  //     recon
  //   } = this.props;
  //   if(recon)
  //     return recon.transactions
  //   else
  //     return []
  // }


  // renderAccounted(){
  //   const {
  //     recon
  //   } = this.props;
  //   if(recon)
  //     return recon.transactions
  //   else
  //     return []
  // }

  // renderMissing(){
  //   const {
  //     recon
  //   } = this.props;
  //   if(recon)
  //     return recon.transactions
  //   else
  //     return []
  // }

  headerStyle = {
    
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    color: '#000000',
    marginBottom: '30px'
  }


  tableHeaderStyle = {
    
    fontStyle: 'normal',
    fontSize: '16px',
    color: '#333333',
    marginBottom: '20px',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '20px',
  }

  columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Value',
      render: (text, record) => {
          if(record.type == 1) return record.file_content
          else return <div style={{width: '300px', whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}>{record.string_content}</div>
      }
    },
  ];


  render(){

    return(
      <div style={{ paddingTop: '38px', paddingLeft: '42px', paddingRight: '42px' }}>
        <div style={this.headerStyle}>Manage Content</div>
        <div style={{ marginBottom: '20px', float: 'right' }}>
          <Button
              style={{
                backgroundColor: this.state.hover1 ? '#1DD28B' : '#fff',
                border: '1px solid #1DD28B',
                height: '40px',
                borderRadius:'10px',
                zIndex: 1,
                marginRight: '20px'
              }}
              onClick={()=>this.setState({modal: true, mode: 0})}
              onMouseEnter={()=>this.setState({hover1: true})}
              onMouseLeave={()=>this.setState({hover1: false})}
              >
            <div style={{fontSize: '14px', fontWeight:'600', color: this.state.hover1 ? '#fff' : '#1DD28B'}}>Add New</div>
          </Button>
          <Button
              style={{
                backgroundColor: this.state.hover2 ? '#1DD28B' : '#fff',
                border: '1px solid #1DD28B',
                height: '40px',
                borderRadius:'10px',
                zIndex: 1
              }}
              onClick={()=>this.setState({modal: true, mode:1})}
              onMouseEnter={()=>this.setState({hover2: true})}
              onMouseLeave={()=>this.setState({hover2: false})}
              >
            <div style={{fontSize: '14px', fontWeight:'600', color: this.state.hover2 ? '#fff' : '#1DD28B'}}>Add New File</div>
          </Button>
          <UploadModal modal={this.state.modal} visible={this.state.modal} mode={this.state.mode}/>
        </div>
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
            columns={this.columns}
            dataSource={this.props.content ? this.props.content : []}
            />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentSettings);