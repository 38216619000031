import React, {useEffect} from 'react'
import {Alert, Layout, Button} from 'antd'
import logo from '../../static/icons/logo.svg'
import ecommerceStyle from '../../static/css/Ecommerce'
import { history } from "../../store/history";
import axios from 'axios';
import Authorize from '../../static/img/authorize.svg'

const EcomAuthorize = props => {
    const ecom = ecommerceStyle();
    const { Header } = Layout;
    const [storeName, setStoreName] = React.useState("My Store")
    const [backURL, setBackURL] = React.useState("")
    const [showAlert, setShowAlert] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState("")
    

    function getLocation(href) {
        const l = document.createElement("a");
        l.href = href;
        return l;
    };

    useEffect(() => {
        let search = window.location.search;
        if (localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            history.push('/login_next' + search)
        }else{
            let params = new URLSearchParams(search);
            let redirect_url = params.get('redirect_url');
            let back_url = getLocation(redirect_url);
            setBackURL(back_url.protocol + "//" + back_url.hostname)
            setStoreName(decodeURIComponent(params.get('store_name')));
        }
    }, [])


    function getCredentials() {
        let referrer_loc = getLocation(document.referrer);
        let referrer = referrer_loc.protocol + "//" + referrer_loc.hostname;
        if(backURL == referrer){
            get_credentials()
        }else{
            setAlertMessage('Invalid Referrer. Ensure Redirect URL points to your website. Open again from Woocommerce/Prestashop Dashboard.')
            setShowAlert(true)
        }
    
    }

    const AuthorizeStyle = {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        textAlign: 'center',
        color: '#000000',
        marginTop: '20px'
    }

    const textStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16ox',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#000000',
        marginTop: '20px'

    }

    const buttonStyle = {
        height: '48px',
        borderRadius: '4px',
        width: '173px',
        marginRight: '20px',
        color: '#1d82b8',
        border: '2px solid #1D82B8'
    }

    async function get_credentials(){

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let redirect_url = encodeURIComponent(params.get('redirect_url'));
        let ecom = params.get('ecom');
        setStoreName(decodeURIComponent(params.get('store_name')));

        let yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
          };



        let response = await axios.get(
            process.env.REACT_APP_API_URL + '/api/get_credentials/?ecom='+ecom+'&redirect_url='+redirect_url, yourConfig
        );

        
        if(response.data.status == "success") {

                window.location.href = response.data.url
        }

    }
    return(
        <div>
            <Header style={{height: '60px', display: 'flex', justifyContent: 'center', backgroundColor: '#fff', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)'}}>
                <img src={logo} style={{marginBottom: '20px', marginTop: '12px'}} alt="#" />
            </Header>

            <div style={{width: '100%', marginTop: '10px'}} hidden={!showAlert}>
                <Alert message={alertMessage} type="error" style={{marginBottom: '20px'}} showIcon/>
            </div>

            <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}>
                <div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img src={Authorize} alt="authorize_img" />
                    </div>
                    <div style={AuthorizeStyle}>
                        Authorize Application
                    </div>
                    <div style={textStyle}>
                        <span><strong>{storeName}</strong> </span>wants to request permission to access and link your BUx account.<br/>
                        This will redirect back to <span><strong>{backURL}</strong> </span><br/><br/>
                        If you do not own this website: <span><strong>{backURL}</strong></span>, do not continue
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                        <Button style={buttonStyle} onClick={() => window.location.href = backURL }>Cancel</Button>
                        <Button type="primary" style={{width: '173px', height: '48px'}}  onClick={() => getCredentials()}>Allow</Button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default EcomAuthorize;