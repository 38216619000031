import axios from "axios";
import {Handle401} from '../handle401/handle401'


let yourConfig = {
  headers: {
     Authorization: "Token " + localStorage.getItem("jwtToken")
  }
}

async function updateDetailsNow(value) {
  let params = {
    first_name: value.firstName,
    last_name: value.lastName,
    middle_name: value.middleName,
    suffix: value.suffix,
    username: localStorage.getItem("username")
  };


  try {
    let response = await axios.post(
      process.env.REACT_APP_API_URL + "/api/update_details/",
      params,yourConfig
    );
    
    if(response.data.status == "success"){
      localStorage.setItem("firstName", value.firstName);
      localStorage.setItem("lastName", value.lastName);
      localStorage.setItem("middleName", value.middleName);
      localStorage.setItem("suffix", value.suffix);
      localStorage.setItem("isCompleted", true)

      
    }
    return response;

  } catch (error) {
    if((error.response)&&(error.response.status == 401)){
      Handle401()
    }
  }
}


export { updateDetailsNow };
