import React from 'react';
import { Layout, Typography } from 'antd';
import { isMobile } from 'react-device-detect';
import { BUx } from '../../../static/icons/bux_qr';
import NewCheckouBG from '../../../static/icons/newCheckoutBGTop.svg';
import PrestaShopLogo from '../../../static/icons/prestashop.svg'
import { Step1, Step2, Step3, Step4, Step5, Step6 } from '../../../static/new_segment/tutorials/presta_shop';

const { Header } = Layout;

const stepStyle = {
    instruction: {
        color: '#000',
        fontWeight: '400',
        fontSize: '16px'
    },
}

const steps = [
    {
        instruction: <Typography style={stepStyle.instruction}>On your BUx Dashboard, Click <b>Link Store.</b></Typography>,
        img: Step1,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>Copy the <b>Integration Credentials</b> (API Key, Client ID, Client Secret).</Typography>,
        img: Step2,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>Click <b>Download link</b> beside the PrestaShop logo.</Typography>,
        img: Step3,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>Switch to your <b>PrestaShop</b> website, then go to <b>Modules</b>.</Typography>,
        img: Step4,
    },
    {
        instruction: <Typography style={stepStyle.instruction}><b>Upload new module</b> by selecting the file you downloaded from BUx, then click <b>Configure</b>.</Typography>,
        img: Step5,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>Paste the <b>Integration Credentials</b> from BUx, then click <b>Save</b> to complete.</Typography>,
        img: Step6,
    },
]

const PrestaShopTutorial = (props) => {

    return (
        <div style={styles.root}>
            {/* <div hidden={!isMobile} style={{ zIndex: '1', width: '100%', height: '60px', display: 'flex', justifyContent: 'center', background: 'linear-gradient(102.61deg, #004888 0%, #00BBFF 100%)', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)' }}>
                <img src={BUx} style={{ maxWidth: '72px' }} alt="#" />
            </div> */}
            <div style={{ zIndex: '1', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '24px 0' }}>
                <img src={BUx} alt="BUx" />
            </div>
            <div style={styles.body}>
                <Typography style={styles.title}>How to Integrate</Typography>
                <img src={PrestaShopLogo} alt="Prestashop" />
                {
                    steps.map((step, i) => {
                        return (
                            <>
                                <img src={step.img} alt={`Step ${i + 1}`} style={{ marginTop: '32px', width: isMobile && '100%' }} />
                                <div style={styles.inlineFlex}>
                                    <Typography style={styles.stepBtn}>Step {i + 1}</Typography>
                                    {step.instruction}
                                </div>
                            </>
                        )
                    })
                }
            </div>
        </div>
    )
}

const styles = {
    root: {
        backgroundColor: '#F1F2F6',
        backgroundImage: `url(${NewCheckouBG})`,
        backgroundRepeat: 'no-repeat',
        textAlign: 'center',
        width: '100%',
        paddingBottom: '2px'
    },
    body: {
        backgroundColor: '#FFF',
        borderRadius: '4px',
        padding: '40px 40px 72px 40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '80%',
        margin: '12px auto 72px auto',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)'
    },
    title: {
        color: '#000',
        fontSize: '24px',
        fontWeight: '700',
    },
    stepBtn: {
        color: '#FFF',
        fontSize: '16px',
        fontWeight: '700',
        textAlign: 'center',
        borderRadius: '10px',
        backgroundColor: '#F5922F',
        padding: '6px 8px',
        minWidth: '80px'
    },
    inlineFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '14px',
        flexDirection: isMobile ? 'column' : 'row'
    }
}

export default PrestaShopTutorial;