import { axios } from "../../../../../../lib/axios";
import { useState, useEffect } from "react";

export function getSubmission(id) {
  return axios.get(`api/cashbux/bank_submissions/${id}`);
}

export function useSubmission(id) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getSubmission(id);
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, [id]);

  return { data, isLoading, error };
}
