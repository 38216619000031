import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TagUserToggle, TagUserAsTestAccntToggle } from '../usersService';
import { Modal, Typography, Button, Icon, Dropdown, Menu, message } from 'antd';

const TagUserModal = (props) => {
    const [btnStyle, setBtnStyle] = React.useState({color: '#F5922F', backgroundColor: '#FFF' })
    const [elevationType, setElevationType] = React.useState(null);
    const { tagType } = props;
    const tagStatus = props && tagType === 'test'? props.userDetails.test_account : props.userDetails.key_account 

    const handleMouseOver = () => {
      setBtnStyle({
        backgroundColor: '#F5922F',
        color: '#FFF'
      })
    }
  
    const handleMouseLeave = () => {
      setBtnStyle({
        backgroundColor: '#FFF',
        color: '#F5922F'
      })
    }

    const handleSelectChange = (props) => {
        setElevationType(props.key)
    }

    const menu = (
        <Menu onClick={handleSelectChange}>
          <Menu.Item key="KAL">
            Enterprise
          </Menu.Item>
          <Menu.Item key="KLM">
            Large Merchant
          </Menu.Item>
          <Menu.Item key="KCP">
            Channel Partner
          </Menu.Item>
        </Menu>
    )

    const returnElevationType = (key) => {
        switch (key) {
            case 'KAL':
                return 'ENTERPRISE';
            case 'KLM':
                return 'Large Merchant';
            case 'KCP':
                return 'Channel Partner';
            default:
                return 'Select Elevation Type';
        }
    }

    const success = (msg) => {
      message.success(msg);
    };
  
    const errorPrompt = (msg) => {
      message.error(msg);
    };

    const tagUser = async () => {
      const action = tagType === 'test'? (!props.userDetails.test_account? 'tag' : 'untag') : (!props.userDetails.key_account? 'tag' : 'untag');
      const params = {
        user_id: props.userDetails.id,
        action: action,
      }
      try {
        const res = tagType === 'test'? await TagUserAsTestAccntToggle(params) :  await TagUserToggle(params);
        if(res.status === 'success') {
          success(res.message);
          props.closeModal();
          props.onSuccess();
        } else {
          errorPrompt(res.message);
        }
        console.log('TAG_RESPONSE',res);
      } catch (error) {
        console.log(error);
        errorPrompt(error);
      }
    }

    console.log('USERDETAILS', props.userDetails)
    return (
        <Modal 
            visible={props.visible} 
            bodyStyle={styles.root} 
            footer={null}
            centered
            width={360}
            onCancel={props.closeModal}
            closeIcon={<Icon type="close" style={{color:'#F5922F'}}/>}
        > 
            <Typography style={styles.title}>{tagType === 'test'? 'Test Account': 'Account Type'}</Typography>
            <Typography style={styles.msg}>Are you sure you want to {!tagStatus? 'tag' : 'untag'} <strong>{props.userDetails.full_name}</strong> as <b>{tagType === 'test'? 'Test Account' : 'Key Account'}</b>?</Typography>
            {/* <Dropdown overlay={menu} trigger={['click']}>
                <a style={{...styles.dropdownItems, backgroundColor: !elevationType? 'rgba(144, 145, 150, 0.1)' : 'transparent'}} >
                    {returnElevationType(elevationType)}
                <ExpandMoreIcon style={styles.dropdownArror} />
                </a>
            </Dropdown> */}
            <div style={styles.mainBtn}>
                <Button className="admin-download-btn" 
                onMouseOver={handleMouseOver} 
                onMouseLeave={handleMouseLeave} 
                onClick={props.closeModal}>
                    Cancel
                </Button>
                <Button className="add-user" onClick={props.closeModal} disabled={!props.userDetails.id} onClick={tagUser}>
                    Confirm
                </Button>
            </div>
        </Modal>
    )
}

const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    color: '#2B2D33',
    fontWeight: '300',
    textAlign: 'center',
    padding: '24px 0 10px 0'
  },
  msg: {
    fontSize: 14,
    color: '#000',
    textAlign: 'left',
    padding: '10px 0'
  },
  mainBtn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 20,
  },
  doneBtn: {
    height: 48,
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    width: '45%',
  },
  icon: {
    textAlign: 'center',
    width: 56,
    paddingTop: 40,
  },
  cancelBtn: {
    height: 48,
    width: '45%',
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 10,
  },
  dropdownItems: {
    display: 'flex', 
    alignItems: 'center', 
    padding: '7px 4px 7px 16px',
    fontSize: 16, 
    color: '#2B2D33',
    border: '1px solid #D1D5DD',
    borderRadius: 5,
  },
  dropdownArror: {
    color: '#2B2D33', 
    fontSize: 24, 
    margin: '0 4px'
  },
}

export default TagUserModal;