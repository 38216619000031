import React, {useEffect} from 'react'
import {Card, Input, Select, Button, message} from 'antd'
import * as Style from '../../../complete_profile/complete_profile_style'
import {FB, IG} from '../../../../static/icons/index'
import {ADDRESS} from '../../../constants/constants'
import {NATURE_BUSINESS} from '../../../constants/constants'
import axios from 'axios'
import {goToStep} from '../mobile_main_enterprise'

const BusinessInformation = props => {

    useEffect(()=>{
        window.scrollTo(0, 0)
        getDetails()
    },[])
    
    const { Option } = Select
    const { TextArea } = Input;


    const [businessType, setBusinessType] = React.useState("")
    const [businessName, setBusinessName] = React.useState("")
    const [businessWeb, setBusinessWeb] = React.useState("")
    const [facebook, setFacebook] = React.useState("")
    const [instagram, setInstagram] = React.useState("")
    const [natureBusiness, setNatureBusiness] = React.useState("")
    const [descriptionBusiness, setDescriptionBusiness] = React.useState("")

    const [line1, setLine1] = React.useState("")
    const [line2, setLine2] = React.useState("")
    const [cityAddress, setCityAddress] = React.useState("")
    const [stateAddress, setStateAddress] = React.useState("")
    const [postalCode, setPostalCode] = React.useState("")

    const [createdAt, setCreateAt] = React.useState("")

    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(false)

    function handleBusinessType(value){
        setBusinessType(value)
    }

    function handleNatureBusiness(value){
        setNatureBusiness(value)
    }

    function handleStateAddress(value) {
        setStateAddress(value)
        setCityAddress("")
    }
    function handleCityAddress(value) {
        setCityAddress(value)
    }

    function getStates() {
        let states = []
        for(var i in ADDRESS){
            states.push(<Option value={i}>{i}</Option>);
        }
        return states
    }
    function getCities() {
        let cities = []
        if(stateAddress in ADDRESS){
            for(var i in ADDRESS[stateAddress]['Municipality_list']){
                cities.push(<Option value={i}>{i}</Option>);
            }
        }
        return cities
    }

    async function getDetails(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let userId = localStorage.getItem('userId')
        try{
            let response = await axios.get(
                process.env.REACT_APP_API_URL + `/api/kyb/${userId}`,yourConfig)
            
            console.log(response.data)
            setBusinessName(response.data.business_name)
            setBusinessType(response.data.business_type)
            setBusinessWeb(response.data.website)
            setNatureBusiness(response.data.nature_of_business)
            setFacebook(response.data.facebook)
            setInstagram(response.data.instagram)
            setDescriptionBusiness(response.data.description)
            setLine1(response.data.address_line_1)
            setLine2(response.data.address_line_2)
            setStateAddress(response.data.address_state)
            setCityAddress(response.data.address_city)
            setPostalCode(response.data.address_postal_code)
            setCreateAt(response.data.created_at)
            
        }
        catch(error){
            console.log("Unable to proceed. Please try again");
        }

    }

    async function submitStep1(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        if(!businessType){
            setError(true)
            setErrorMessage("Please choose business type")
            window.scrollTo(0, 0)

        }
        else if(businessName == ""){
            setError(true)
            setErrorMessage("Please fill up business name")
            window.scrollTo(0, 0)
        }
        else if(natureBusiness == ""){
            setError(true)
            setErrorMessage("Please choose nature of business")
            window.scrollTo(0, 0)
        }
        else if(descriptionBusiness == ""){
            setError(true)
            setErrorMessage("Please fill up business description")
            window.scrollTo(0, 0)
        }
        else if(line1 == ""){
            setError(true)
            setErrorMessage("Please fill up line 1")
            window.scrollTo(0, 0)
        }
        else if(stateAddress == ""){
            setError(true)
            setErrorMessage("Please choose state address")
            window.scrollTo(0, 0)
        }
        else if(cityAddress == ""){
            setError(true)
            setErrorMessage("Please choose city address")
            window.scrollTo(0, 0)
        }
        else{
            setLoading(true)
            let details = {
                business_type: businessType,
                business_name: businessName,
                website: businessWeb,
                facebook: facebook,
                instagram: instagram,
                nature_of_business: natureBusiness,
                description: descriptionBusiness,
                address_line_1: line1,
                address_line_2: line2,
                address_state: stateAddress,
                address_city: cityAddress,
                address_postal_code: postalCode,
                country: 'Philippines',
                step: 2
            }
            try{
                if(createdAt == ""){
                    let response = await axios.post(process.env.REACT_APP_API_URL + "/api/kyb/", details,yourConfig)
                    if(response){
                        goToStep(2)
                        setLoading(false)
                    }
                }
                else{
                    let response = await axios.put(process.env.REACT_APP_API_URL + "/api/kyb/", details,yourConfig)
                    if(response){
                        goToStep(2)
                        setLoading(false)
                    }
                }
                
            }
            catch(error){
                message.error("Unable to proceed. Please try again");
            }
        }
        
    }

    const BUSINESS_TYPE = ['Sole Proprietor', 'Corporate']

    return(
        <div>
            <Card style={{marginBottom: '20px'}}>
                <div style={Style.headerStyle}>
                    Business  Information
                </div>
                <div
                    style={{
                        backgroundColor: 'rgba(226, 76, 76, 0.1)',
                        borderLeft: '4px solid #E24C4C',
                        width: '100%',
                        height: '42px',
                        borderRadius: '4px',
                        marginBottom: '20px',
                        marginTop: '20px',
                        textAlign: 'center',
                        paddingTop: '10px',
                        display: error ? "block" : "none"
                    }}>
                    <span style={Style.textStyle}>{errorMessage}</span>
                </div>
                <div style={{width: '100%', marginBottom: '16px', marginTop: '20px'}}>
                    <div style={Style.textStyle}>Business Type</div>
                    <Select
                        showSearch
                        style={{ width: '100%', height: '40px' }}
                        size="large"
                        value={BUSINESS_TYPE[businessType]}
                        optionFilterProp="children"
                        onChange={handleBusinessType}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                       }
                    >
                        <Option value="0">Sole Proprietor</Option>
                        <Option value="1">Enterprise</Option>
                    </Select>
                </div>
                <div style={{width: '100%', marginBottom: '16px'}}>
                    <div style={Style.textStyle}>Business Name</div>
                    <Input style={{height: '40px'}} value={businessName} onChange={(e)=>setBusinessName(e.target.value)}/>
                </div>
                <div style={{width: '100%', marginBottom: '16px'}}>
                    <div style={Style.textStyle}>Business Website</div>
                    <Input style={{height: '40px'}} value={businessWeb} placeholder="http://www.businessname.com" onChange={(e)=>setBusinessWeb(e.target.value)}/>
                </div>
                <div style={{width: '100%', marginBottom: '16px'}}>
                    <div style={Style.textStyle}>Link social media (Optional)</div>
                </div>
                <div style={{width: '100%', marginBottom: '16px'}}>
                    <div style={Style.textStyle}>Facebook</div>
                    <Input style={{height: '40px'}} value={facebook} addonBefore={<div><img src={FB} style={{marginRight: '5px'}}/><span>www.facebook.com/</span></div>} onChange={(e)=>setFacebook(e.target.value)}/>
                </div>
                <div style={{width: '100%', marginBottom: '16px'}}>
                    <div style={Style.textStyle}>Instagram</div>
                    <Input style={{height: '40px'}} value={instagram} addonBefore={<div><img src={IG} style={{marginRight: '5px'}}/><span>www.instagram.com/</span></div>} onChange={(e)=>setInstagram(e.target.value)}/>
                </div>

                <div style={{width: '100%', marginBottom: '16px', marginTop: '20px'}}>
                    <div style={Style.textStyle}>Nature of Business</div>
                    <Select
                        showSearch
                        style={{ width: '100%', height: '40px' }}
                        size="large"
                        optionFilterProp="children"
                        value={NATURE_BUSINESS[natureBusiness]}
                        onChange={handleNatureBusiness}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                                NATURE_BUSINESS.length > 0 && NATURE_BUSINESS.map((item, i) => {
                                return (
                                <Option key={i} value={i}>{item}</Option>
                                )
                            }, this)
                        }
                    </Select>
                </div>
                <div style={{width: '100%', marginBottom: '16px', marginTop: '20px'}}>
                    <div style={Style.textStyle}>Description of Business</div>
                    <TextArea rows={3} value={descriptionBusiness} onChange={(e)=>setDescriptionBusiness(e.target.value)} />
                </div>
                <div style={{width: '100%', marginBottom: '16px'}}>
                    <div style={Style.StepStyle}>Business Address</div>
                </div>
                <div style={{width: '100%', marginBottom: '16px'}}>
                    <div style={Style.textStyle}>Line 1</div>
                    <Input style={{height: '40px'}} value={line1} onChange={(e)=>setLine1(e.target.value)}/>
                </div>
                <div style={{width: '100%', marginBottom: '16px'}}>
                    <div style={Style.textStyle}>Line 2</div>
                    <Input style={{height: '40px'}} value={line2} onChange={(e)=>setLine2(e.target.value)}/>
                </div>
                <div style={{width: '100%', marginBottom: '16px', marginTop: '20px'}}>
                    <div style={Style.textStyle}>State</div>
                    <Select
                        showSearch
                        style={{ width: '100%', height: '40px' }}
                        size="large"
                        optionFilterProp="children"
                        value={stateAddress}
                        onChange={handleStateAddress}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        
                        {getStates()}
                    </Select>
                </div>
                <div style={{width: '100%', marginBottom: '16px', marginTop: '20px'}}>
                    <div style={Style.textStyle}>City</div>
                    <Select
                        showSearch
                        style={{ width: '100%', height: '40px' }}
                        size="large"
                        optionFilterProp="children"
                        value={cityAddress}
                        onChange={handleCityAddress}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {getCities()}
                    </Select>
                </div>
                <div style={{width: '100%', marginBottom: '16px'}}>
                    <div style={Style.textStyle}>Postal Code</div>
                    <Input style={{height: '40px'}} value={postalCode} onChange={(e)=>setPostalCode(e.target.value)}/>
                </div>
                <div style={{width: '100%', marginBottom: '16px'}}>
                    <div style={Style.textStyle}>Country</div>
                    <Input style={{height: '40px'}} value="Philippines" disabled/>
                </div>
            </Card>

            <Button loading={loading} style={Style.submitBtnStyle} onClick={()=>submitStep1()}>Submit and Next</Button>
        </div>
    )

}

export default BusinessInformation