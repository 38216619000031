import React,{} from 'react';
import { Modal, Typography, Button, Icon } from 'antd';
import BG from '../../../static/images/corpo_payment_link_modal_bg.svg';

const PaymentLinkModal = (props) => {
  const {
    visible, closeModal, exportTemplate, onSkip, segments
  } = props;

  return (
    <Modal 
      visible={visible}
      footer={null}
      onCancel={closeModal}
      width={500}
      centered
      closeIcon={<Icon type="close" className={`text-${segments}`} />}
      bodyStyle={styles.root}>
        <img src={BG} alt="#" />
        <Typography style={styles.title}>Import Batch Payment Link</Typography>
        <Typography style={{...styles.normalFont, paddingTop: 20}}>Start by downloading Batch CSV Template</Typography>
        <button className={`btn--${segments} top-20`} onClick={exportTemplate} >Download CSV Template</button>
        <Typography style={{...styles.normalFont, paddingTop: 48}}>I already have the Template</Typography>
        <a className={`text-${segments} wc-header bottom-20`} href onClick={onSkip}>Skip this Step</a>
    </Modal>
  ) 
}

const styles = {
  root: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    margin: '16px 0px 0px 0px',
    height: 48,
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    padding: '0 12px'
  },
  title: {
    fontSize: 24,
    fontWeight: '300',
    color: '#000',
    padding: '22px 0 20px 0'
  },
  normalFont: {
    fontSize: 16,
    color: '#000',
  },
  link: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#F5922F',
    marginBottom: 36,
  }
}

export default PaymentLinkModal;