import React, {useEffect} from 'react'
import * as Style from './mobAppBarStyle'
import * as Icons from '../../static/icons/mobile_main_icons/index'
import { useSelector } from 'react-redux';
import { Icon, Button, Progress, List, Card, Typography } from 'antd'
import { history } from "./../../store/history";

import profile from "./../../static/icons/profile.svg";
import * as Env from '../../static/icons/sidebar/index';

import {getLatestBalance} from '../Main'
import { DefaultCoverPhoto } from '../../static/new_segment/background_images_svg';
import myProfileServices from '../../services/my_profile';
import noProfile from "../../static/icons/profile.svg";
import { isMobile } from 'react-device-detect';


const testMode = (history.location.pathname.indexOf('/test') > -1&&localStorage.getItem("userType") == "CO");

const MobileMore = props => {

    const current_step = localStorage.getItem("Step")
    const kybStep = localStorage.getItem('KYBStep')
    const userType = localStorage.getItem("userType")
    const userLevel = localStorage.getItem("Level")
    const profilePic = localStorage.getItem('profilePicture')
    const segments = localStorage.getItem("segments")

    const industry = localStorage.getItem("industry")
    const fname = localStorage.getItem("firstName")
    const fullname = `${fname} ${localStorage.getItem("lastName")}`;
    const username = localStorage.getItem("username");

    // const [totalPaid, setTotalPaid] = React.useState(0);
    const [custId, setCustId] = React.useState(null);
    const isMultiwallet = localStorage.getItem("is_multiwallet") === 'true';
    const isChild = localStorage.getItem("is_child") === 'true';

    const endpoints = useSelector(state => state.child_endpoints.endpoints);

    const [profilePicURL, setProfilePicURL] = React.useState(null);
    const [contact, setContact] = React.useState(null);

    const isEnterprise = ['sme', 'biz'].includes(segments);

    let envelope = segments === 'me_' ? Env.ME_Env : 
                    segments === 'gig' ? Env.GIG_Env : 
                    segments === 'sme' ? Env.SME_Env : 
                    segments === 'biz' ? Env.BIZ_Env : 
                      Env.Envelope

    let side_tabs = [
      {
        'menu': 'Multiwallet',
        'icon': Icons.Multiwallet,
        'endpoint': '/mobile/multiwallet',
        'is_hidden': !isMultiwallet || isChild, 
      },
      {
        'menu': 'Reconciliation',
        'icon': Icons.BuxMoney,
        'endpoint': '/mobile/recon',
        'is_hidden': userType != "CO" || !endpoints.includes('recon')
      },
      // {
      //   'menu': 'Loans',
      //   'icon': Icons.Loan,
      //   'endpoint': '/mobile/refer_lender',
  
      // },
      {
        'menu':'Bux E-Money Account',
        'icon': Icons.BuxMoney,
        'endpoint': '/mobile/card',
        'is_hidden': userLevel >= 0 && !custId || userType === "SW"
      },
      {
        'menu':'Bux Wallet',
        'icon': Icons.Multiwallet,
        'endpoint': '/mobile/bux_wallet',
        'is_hidden': userType === "ME" || userType === "CO"
      },
      {
        'menu':'Link Store',
        'icon': Icons.LinkStore,
        'endpoint': '/mobile/link_stores',
        'is_hidden': userType == "CO" || userType === "SW"
      },
      {
        'menu': 'Integration',
        'icon': Icons.LinkStore,
        'endpoint': '/mobile/integration',
        'is_hidden': userType != "CO" || !endpoints.includes('integration')
      },
      {
        'menu': 'Settings',
        'icon': Icons.Settings,
        'endpoint': '/account_settings',
        'is_hidden': !endpoints.includes('account_settings')
      },
      {
        'menu': 'Help',
        'icon': Icons.HelpEmail,
        'endpoint': 'mailto:support@bux.ph',
        'is_hidden': !endpoints.includes('account_settings')
      },
    ]

    const renderSteps = (steps) => {
      switch (steps) {
          case 0:
              return 1;
          case 1:
              return 2;
          case 2:
              return 3;
          case 3:
          case 4:
              return 4;
          case 5:
          case 6:
          case 7:
          case 9:
              return 5;
          default:
              return 0;
      }
  }

    async function getBalance(){
      let data = await getLatestBalance()
      if(data){
        // setTotalPaid(data.total_paid)
        setCustId(data.customer_id)
      }
    }

    const getProfileDetails = async () => {
      try {
        const res = await myProfileServices.getProfileDetails();
        if(res.contact) {
          setContact(res.contact);
        }
        else {
          setProfilePicURL(null);
        }
      } catch (error) {
        setProfilePicURL(null);
        console.error(error);
      }
    }
  
    useEffect(() => {
      if (localStorage.getItem("jwtToken") != "") {
        getBalance();
        getProfileDetails();
      }
    }, []);

    const AccountHeader = () => {
      return (
          <Card 
          style={{
            height: '148px',
            padding: 0,
            margin: '20px 16px',
            borderRadius: '4px'
          }}
            bodyStyle={{
              padding: 0
            }}
          >
            <div style={{ ...headerStyle.coverBG, backgroundImage: `url(${DefaultCoverPhoto})` }}>
              <div style={headerStyle.userLogoDiv} >
                <img style={headerStyle.userLogo} src={profilePic ? profilePic : noProfile} alt='No Image' />
              </div>
            </div>
            <div hidden={userLevel == 0} style={headerStyle.lower}>
              <div>
                <Typography style={{ color: '#2B2D32', fontWeight: '700', fontSize: '18px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '200px'}}>{fullname !== "" ? fullname : username}</Typography>
                <Typography style={{ color: '#2B2D32', fontWeight: '400', fontSize: '16px'}}>+63{contact}</Typography>
              </div>
              <button className={`outline-btn--${segments}`} style={headerStyle.viewBtn}
                onClick={() => history.push('/my_profile')}
              >
                View Profile
              </button>
            </div>
            <div hidden={userLevel != 0} style={headerStyle.lower}>
              <div>
                <Typography style={{ color: '#2B2D32', fontWeight: 'normal', fontSize: '14px'}}>Complete Profile</Typography>
                <Typography style={{ color: '#2B2D32', fontWeight: '600', fontSize: '16px'}}>Step {isEnterprise? parseInt(kybStep) + 1 : renderSteps(parseInt(current_step))} of {isEnterprise? '6' : '5'}</Typography>
              </div>
              <a style={{color: '#2B2D32'}}
                onClick={() => history.push(isEnterprise? '/upgrade_enterprise' : '/complete_profile')}
              >
                <Icon type='right'/>
              </a>
            </div>
          </Card>
      )
    }

    const headerStyle  = {
      coverBG: {
        position: 'absolute',
        width: '100%',
        height: '50%',
        backgroundImage: `url(${DefaultCoverPhoto})`,
        backgroundRepeat: 'no-repeat',
        // backgroundSize: '100% 50%',
        backgroundSize: 'cover',
        backgroundPosition: '100% 50%',
        top: '0%',
        borderRadius: isMobile ? '0' : '20px 20px 0px 0px',
        display: 'flex',
        alignItems: 'center'
      },
      userLogoDiv: {
        display: 'block',
        width: '46px',
        height: '46px',
        borderRadius: '80px',
        backgroundColor: '#FFF',
        border: '0px solid #EEEEEE',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '16px'
      },
      userLogo: {
        display: 'block',
        width: '32px',
        height: '32px',
        borderRadius: '66px',
        backgroundColor: '#FFF'
      },
      lower: {
        height: '50%', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        bottom: '0%',
        position: 'absolute',
        width: '100%',
        padding: '16px'
      },
      viewBtn: {
      }
    }

    const Sidebar = () => {
      return (
        // <MoreSidebar status={side_tabs} selected={window.location.href} handleClick={(e) => {getBalance(); history.push(e.target.value)}} />

        <List
          dataSource={side_tabs}
          bordered={false}
          style={{backgroundColor: '#FFF'}}
          renderItem={item => (
              <List.Item 
              style={{
                  borderBottom: '1px solid rgb(221 221 221 / 50%)', padding: '8px 24px', display: item && item.is_hidden && 'None',
                  justifyContent: 'flex-start',
              }}>
                  <img alt="" src={item.icon} style={{ paddingRight: 12, width: 36, height: 25 }} />
                  <a style={{textDecoration: 'None', color: '#2B2D32'}} 
                    onClick={() => {getBalance(); item.menu === 'Help' ? window.location.assign(item.endpoint) : history.push(item.endpoint)}}>{item.menu}</a>
              </List.Item>
          )}
    />
      )
    }

    return (
      <div>
          <AccountHeader />
          <Sidebar />
      </div>
    )

    // return (
    //   <div style={Style.moreDiv} className="bottom-64">
    //     <div style={{...Style.mainMore, backgroundImage: `url(${Icons.MoreBG})`}}>
    //       <div align='center'>
    //           {
    //             userType != "ME" && userType != "SW" ?
    //             <div>
    //               <div align="center">
    //                   {
    //                   profilePic ? <img src={profilePic} alt="avatar" style={Style.profilePic}/> : 
    //                               <img src={profile} alt="avatar" style={{width: '48px'}}/>
    //                   }
                    
    //                 <div style={{
    //                   ...Style.NameStyle,
    //                   color: '#0d3d76'}}>
    //                   Hello {fname !== "" ? fname : username}
    //                 </div>

    //                 <div style={{...Style.UpgradeDiv,
    //                           display: kybStep == 0 ? 'none' : kybStep < 7 ? 'block' : testMode ? 'none' : 'none'
    //                           }}>
    //                   <div style={Style.TwoColumn}>
    //                     <div>
    //                       <div style={Style.progress}>
    //                         Complete Profile: <a>{Math.min(kybStep,6)*15}%</a>
    //                       </div>

    //                       <div style={Style.progressStep}>
    //                         <div>Step {kybStep} of 6</div>
    //                       </div>
    //                     </div>

    //                     <div>
    //                       <Icon type="right" style={Style.rightIcon} 
    //                       onClick={()=>window.location.href =('/upgrade_enterprise')}/>
    //                     </div>
    //                   </div>

    //                   <Progress percent={Math.min(Math.min(kybStep,6)*15)} showInfo={false} strokeColor={{
    //                                               '0%': '#f8b966',
    //                                               '100%': '#f5922f',
    //                                             }}/>
    //                 </div>
    //                 <Button style={{
    //                     ...Style.upgradeBtn,
    //                     display: kybStep > 7 && kybStep < 10 || kybStep == 0 ? 'block' : kybStep == 11 ? 'none' : 'none'
    //                     }} onClick={()=>history.push('/upgrade_enterprise')}>
    //                       Upgrade Status
    //                 </Button>
    //               </div>
    //             </div>

    //             :
    //             <div>

    //               <div align="center">
    //                   {
    //                   profilePic ? <img src={profilePic} alt="avatar" style={Style.profilePic}/> : 
    //                               <img src={profile} alt="avatar" style={{width: '48px'}}/>
    //                   }
    //                   <div style={Style.NameStyle1}>
    //                     {fullname !== "" ? fullname : username}
    //                   </div>
    //               </div>

    //               <div style={{...Style.UpgradeDiv,
    //                           display: current_step >= 1 && current_step < 7 ? 'block' : 'none'
    //                           }}>

    //                 <div style={Style.TwoColumn}>
    //                   <div>
    //                     <div style={Style.progress}>
    //                       Upgrade Application: <a>
    //                           {
    //                               Math.min(
    //                                   current_step == 3 || current_step == 4 ? 3 : 
    //                                   current_step == 5 && industry == "" ? 4 : 
    //                                   current_step == 6 && industry != "" ? 5 : current_step, 5) * 20
    //                           }%
    //                           </a>
    //                     </div>

    //                     <div style={Style.progressStep}>
    //                       Step {
    //                             Math.min(
    //                             current_step == 3 || current_step == 4 ? 3 : 
    //                             current_step == 5 && industry == "" || current_step == 6 && industry == "" ? 4 :
    //                             current_step == 6 && industry != "" ? 5 : current_step,5) + 1
    //                         } of 6 
    //                     </div>
    //                   </div>

    //                   <div>
    //                     <Icon type="right" style={Style.rightIcon} 
    //                     onClick={()=>window.location.href =('/complete_profile')}/>
    //                   </div>
    //                 </div>

    //                 <Progress 
    //                   percent={
    //                       Math.min(
    //                       current_step == 3 || current_step == 4 ? 3 :
    //                       current_step == 5 && industry == "" ? 4 :
    //                       current_step == 6 && industry == "" ? 5 : current_step,5) * 20 
    //                   } 
    //                   showInfo={false} 
    //                   strokeColor={{
    //                       '0%': '#f8b966',
    //                       '100%': '#f5922f',
    //                   }}/>
    //               </div>

    //               <Button style={{
    //                 ...Style.upgradeBtn,
    //                     display: (current_step == 0 || current_step > 6) && userLevel == 0 ? 'block' : 'none'
    //                     }} onClick={()=>window.location.href = '/complete_profile'}>
    //                       {current_step > 6 ? "Check Upgrade Status" : <div><Icon type="user" style={{marginRight: 4}}/> Complete Profile</div> }
    //               </Button>

    //               {/* <div hidden={userLevel == 3 || (userLevel == 1 &&  totalPaid < 20) ||  (userLevel == 2 &&  totalPaid < 100)}>
    //                 {
    //                 userLevel == "1" || userLevel == "2" ?
    //                     <a style={Style.upgradeLink} onClick={()=>history.push('/mobile/upgrade/')}>
    //                     Upgrade Application
    //                     </a>  : ""
    //                 }
    //               </div>  */}

    //             </div>
    //           }

    //           <div style={Style.MenuBar}>
    //             {
    //               side_tabs.map((item, index) => {
    //                 return(
    //                   <a
    //                     href
    //                     key={index}
    //                     hidden={item.is_hidden}
    //                     onClick={() => {getBalance(); history.push(item.endpoint)}}>
                        
    //                     <div style={Style.SubMenu}>
    //                       <img src={item.icon} alt="Menu Icon" /> 
    //                       <span style={Style.itemText}>{item.menu}</span>
    //                     </div>
    //                   </a>
    //                 )
    //               })
    //             }
    //           </div>

    //           <a href="mailto:support@bux.ph">
    //           <div className={`${testMode ? "test" : `bg-opaque-${segments}`} needHelpDiv `} hidden={userType !== "ME" && userType !== "CO"} >
    //               <img src={envelope} alt="email" />
    //               <span style={{marginLeft: '10px'}}>
    //                 <span className={`needHelpText text-${segments}`}>Need Help?</span>
    //               </span>
    //           </div>
    //         </a>
    //       </div>
    //     </div>
    //   </div>
    // )
}

export default MobileMore