import React, {useState} from 'react';
import { Modal, Popover, Button, Checkbox, Row, Col, Check, Radio } from 'antd';
import { NATURE_BUSINESS } from '../../../../constants/constants';
import { tickets, activations, years, channels, payments, transactions, key_accounts } from '../constants';
import ArrowDown from '../../../../../static/icons/arrow-down.svg'

const FilterUser = props => {

    const {
        openModal, setOpenModal,onCheckAllChange, onCheckChange, 
        acceptOnline, setAcceptOnline,
        yearsChecked, setYearsChecked,
        naturesChecked, setNatureChecked,
        channelsChecked, setChannelsChecked,
        typesChecked, setTypesChecked,
        transactionChecked, setTransactionChecked,
        avgTicketChecked,  setAvgTicketChecked,
        activationsChecked, setActivationChecked,
        keyAccountChecked, setKeyAccountChecked,
        setCorporateFiltered, applyBtn
    } = props;
    
    const CheckboxGroup = Checkbox.Group;

    const [checkAllYears, setCheckAllYears] = React.useState(false)
    const [checkAllNature, setCheckAllNature] = React.useState(false)
    const [checkAllChannels, setCheckAllChannels] = React.useState(false)
    const [checkAllTypes, setCheckAllTypes] = React.useState(false)
    const [checkAllTransaction, setCheckAllTransaction] = React.useState(false)
    const [checkAllAvgTicket, setCheckAllAvgTicket] = React.useState(false)
    const [checkAllActivation, setCheckAllActivation] = React.useState(false)
    const [checkAllKeyAccounts, setCheckAllKeyAccounts] = React.useState(false)


    const popup_box = (choice, value, allValue, allCheckFunction, checkFunction) => (
        <Checkbox.Group
          style={{ 
            width: choice == tickets || choice == activations ? '200px' : '180px', 
            backgroundColor: '#fff', 
            padding: '10px 10px 10px 5px',
            height: choice == NATURE_BUSINESS && '200px',
            overflowY: choice == NATURE_BUSINESS && 'scroll',
            zIndex: 5}} >
          <Row>
            <Col span={24}>
              <Checkbox checked={allValue} onChange={(e)=>onCheckAllChange(e, choice, checkFunction, allCheckFunction)}>All</Checkbox>
            </Col> 
            <Col>
              <CheckboxGroup options={choice} value={value} onChange={(e)=>onCheckChange(e, checkFunction, allCheckFunction)}/>
            </Col>
          </Row>
        </Checkbox.Group> 
      )

    return(
        <Modal
            style={{topo: 30}}
            visible={openModal}
            onCancel={() => setOpenModal(false)}
            footer={null}>
                <div className="screen-title-text">
                    Filter User
                </div>
                <div className="twoCol top-20">
                    <div style={{width: '49%'}}>
                        <div className="smallTextStyle">Years in Business</div>
                        <Popover content={
                            popup_box(years, yearsChecked, 
                            checkAllYears, setCheckAllYears, setYearsChecked)
                            } 
                            trigger="click" 
                            placement="bottomLeft" 
                            getPopupContainer={triggerNode => triggerNode.parentNode }
                            >
                            <Button className="filter-user-dropdown-btn top-16">
                                <div style={{float: 'left'}}>Year Range</div>
                                <img src={ArrowDown} className="arrow-down" alt="icon" />
                            </Button>
                        </Popover>
                    </div>

                    <div style={{width: '49%'}}>
                        <div className="smallTextStyle">Nature of Business</div>
                        <Popover content={popup_box(NATURE_BUSINESS, naturesChecked, checkAllNature, setCheckAllNature, setNatureChecked)} trigger="click" placement="bottomLeft" getPopupContainer={triggerNode => triggerNode.parentNode }>
                        <Button className="filter-user-dropdown-btn top-16">
                            <div style={{float: 'left'}}>Industry</div>
                            <img src={ArrowDown} className="arrow-down" alt="icon" />
                        </Button>
                        </Popover>
                    </div>
                </div>

                <div className="top-16">
                    <div className="normalTextStyle">Do user already accept online payments? </div>
                    <Radio.Group className="top-16" onChange={(e)=>setAcceptOnline(e.target.value)} value={acceptOnline}>
                    <Radio value="1">Yes</Radio>
                    <Radio value="2">No</Radio>
                    </Radio.Group>
                </div>

                <div className="twoCol top-20">
                    <div style={{width: '49%'}}>
                        <div className="smallTextStyle">Which Channel</div>
                        <Popover content={popup_box(channels, channelsChecked, checkAllChannels, setCheckAllChannels, setChannelsChecked)} trigger="click" placement="bottomLeft" getPopupContainer={triggerNode => triggerNode.parentNode }>
                            <Button className="filter-user-dropdown-btn top-16">
                                <div style={{float: 'left'}}>Channels</div>
                                <img src={ArrowDown} className="arrow-down" alt="icon" />
                            </Button>
                        </Popover>
                    </div>

                    <div style={{width: '49%'}}>
                        <div className="smallTextStyle">Where do user accept payment?</div>
                        <Popover content={popup_box(payments, typesChecked, checkAllTypes, setCheckAllTypes, setTypesChecked)} trigger="click" placement="bottomLeft" getPopupContainer={triggerNode => triggerNode.parentNode }>
                            <Button className="filter-user-dropdown-btn top-16">
                                <div style={{float: 'left'}}>Payment Types</div>
                                <img src={ArrowDown} className="arrow-down" alt="icon" />
                            </Button>
                        </Popover>
                    </div>
                </div>

                <div className="top-16">
                    <div className="smallTextStyle">Transaction count per month</div>
                    <Popover content={popup_box(transactions, transactionChecked, checkAllTransaction, setCheckAllTransaction, setTransactionChecked)} trigger="click" placement="bottomLeft" getPopupContainer={triggerNode => triggerNode.parentNode }>
                        <Button className="filter-user-dropdown-btn top-16">
                            <div style={{float: 'left'}}>Transaction Range</div>
                            <img src={ArrowDown} className="arrow-down" alt="icon" />
                        </Button>
                    </Popover>
                </div>

                <div className="top-16">
                    <div className="smallTextStyle">Average Ticket Size</div>
                    <Popover content={popup_box(tickets, avgTicketChecked, checkAllAvgTicket, setCheckAllAvgTicket, setAvgTicketChecked)} trigger="click" placement="bottomLeft" getPopupContainer={triggerNode => triggerNode.parentNode }>
                        <Button className="filter-user-dropdown-btn top-16">
                            <div style={{float: 'left'}}>Average Ticket Range</div>
                            <img src={ArrowDown} className="arrow-down" alt="icon" />
                        </Button>
                    </Popover>
                </div>

                <div className="top-16">
                    <div className="smallTextStyle">Activation account request</div>
                    <Popover content={popup_box(activations, activationsChecked, checkAllActivation, setCheckAllActivation, setActivationChecked)} trigger="click" placement="bottomLeft" getPopupContainer={triggerNode => triggerNode.parentNode }>
                        <Button className="filter-user-dropdown-btn top-16">
                            <div style={{float: 'left'}}>Activation Range</div>
                            <img src={ArrowDown} className="arrow-down" alt="icon" />
                        </Button>
                    </Popover>
                </div>

                <div className="top-16">
                    <div className="smallTextStyle">Key / Non Key</div>
                    <Popover content={popup_box(key_accounts, keyAccountChecked, checkAllKeyAccounts, setCheckAllKeyAccounts, setKeyAccountChecked)} trigger="click" placement="bottomLeft" getPopupContainer={triggerNode => triggerNode.parentNode }>
                        <Button className="filter-user-dropdown-btn top-16">
                            <div style={{float: 'left'}}>Activation Range</div>
                            <img src={ArrowDown} className="arrow-down" alt="icon" />
                        </Button>
                    </Popover>
                </div>

                <div align="right" className="top-16">
                    <Button 
                        onClick={()=>setOpenModal(false)}
                        className="admin-download-btn right-16">Cancel</Button>
                    <Button className="add-user" onClick={()=>
                        {
                        setCorporateFiltered(true);
                        setOpenModal(false)
                        applyBtn()
                        }}>Apply</Button>
                </div>
        </Modal>
    )
}

export default FilterUser;