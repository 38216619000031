import axios from 'axios'
import { Handle401 } from '../handle401/handle401';

const authorizedAxiosRequest = () => {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    });
}

const recurringServices = {

    getAllSchedules: async (page, page_size = 10, start = "", end = "", frequency = "All", search = "", testMode = false) => {
        try {
            let res = null;
            if (testMode) {
                res = await authorizedAxiosRequest().get(`api/sandbox/schedule_payment/?page=${page}&page_size=${page_size}&start=${start}&end=${end}&cycle=${frequency}&search=${search}`);
            }
            else {
                res = await authorizedAxiosRequest().get(`api/orders/recurring/?page=${page}&page_size=${page_size}&start=${start}&end=${end}&cycle=${frequency}&search=${search}`);
            }
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },

    deleteSchedule: async (id, testMode = false) => {
        try {

            let res = null
            if (testMode) {
                res = await authorizedAxiosRequest().delete(`api/sandbox/schedule_payment/?id=${id}`);

            }
            else {
                res = await authorizedAxiosRequest().delete(`api/orders/recurring/?id=${id}`);

            }
            return res.data;
        }
        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    }
}


export default recurringServices;
