import { Button, Card, Icon, notification } from "antd";
import React, { useEffect, useState } from "react";
import {
  getLatestBalance,
  getTransferHistory,
  sendTransferHistory,
} from "../../api";
import { Drawer, GroupButton } from "../../components";
import MultistepForm from "../../components/Form/MultistepForm";
import "./Transfer.css";
import {
  ExportCSVModal,
  FilterBar,
  HistoryTable,
  SearchFilterComponent,
  SummaryCard,
  TransferPanel,
} from "./components";
import { useBanks } from "./api/useBanks";

export function Transfer() {
  // User states
  const [balance, setBalance] = useState({});
  const [transferData, setTransferData] = useState([]);

  // Filter states
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");

  // Drawer state
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Modal states
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [email, setEmail] = useState("");

  const columnsPerPage = 10;

  useEffect(() => {
    getLatestBalance().then((data) => setBalance(data));
  }, []);

  const { data: banks } = useBanks(balance.rail_type);

  useEffect(() => {
    getTransferHistory(
      currentPage,
      searchQuery,
      startDate,
      endDate,
      selectedStatus,
    ).then((data) => setTransferData(data));
  }, [currentPage, searchQuery, startDate, endDate, selectedStatus]);

  const openNotification = (status, message) => {
    notification.error({
      message: status,
      description: message,
      placement: "topRight",
    });
  };

  const handleStatusSelect = (status) => {
    setSelectedStatus(status);
    setCurrentPage(1);
  };

  const handleSearchChange = (query) => setTempSearchQuery(query);

  const handleApplySearch = () => {
    if ((!tempStartDate && tempEndDate) || (tempStartDate && !tempEndDate)) {
      openNotification(
        "Error",
        "Both start date and end date are required for filtering.",
      );
      return;
    }

    setSearchQuery(tempSearchQuery);
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    setCurrentPage(1);
  };

  const handleStartDateChange = (date) => setTempStartDate(date);

  const handleEndDateChange = (date) => setTempEndDate(date);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    document.body.style.overflow = "auto";
  };

  const clearFilters = () => {
    setSearchQuery("");
    setTempSearchQuery("");
    setStartDate("");
    setEndDate("");
    setTempStartDate("");
    setTempEndDate("");
    setSelectedStatus("All");
    setCurrentPage(1);
  };

  const showModal = () => setOpen(true);

  const handleCancel = () => setOpen(false);

  const handleOk = () => {
    if (!email) return;
    setConfirmLoading(true);
    sendTransferHistory(email)
      .then((_) => {
        setOpen(false);
        setEmail("");
      })
      .finally(() => setConfirmLoading(false));
  };

  return (
    <div className="transfer-feature-container">
      <div className="transfer-feature-container-spacing">
        <TransferPanel
          balance={balance.float_balance ? balance.float_balance : "₱ 0.00"}
          handleDrawerOpen={handleDrawerOpen}
        />
        <div className="transfer-feature-container-spacing">
          <div className="summary-cards-container">
            <SummaryCard
              title="Total Amount"
              text={
                transferData.total_payouts &&
                transferData.total_payouts.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "PHP",
                })
              }
            />
            <SummaryCard title="Total Count" text={transferData.total} />
          </div>

          <div className="status-filter-and-export-container">
            <GroupButton
              selectedStatus={selectedStatus}
              onSelect={handleStatusSelect}
            />
            <Button onClick={showModal} style={{ height: "40px" }}>
              <Icon type="download" />
              Export CSV
            </Button>
          </div>

          <Card>
            <FilterBar
              searchQuery={searchQuery}
              startDate={startDate}
              endDate={endDate}
              tempStartDate={tempStartDate}
              tempEndDate={tempEndDate}
              selectedStatus={selectedStatus}
              clearFilters={clearFilters}
            />

            <SearchFilterComponent
              tempStartDate={tempStartDate}
              tempEndDate={tempEndDate}
              tempSearchQuery={tempSearchQuery}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              handleSearchChange={handleSearchChange}
              handleApplySearch={handleApplySearch}
            />

            {transferData && (
              <HistoryTable
                data={transferData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                columnsPerPage={columnsPerPage}
              />
            )}
          </Card>
        </div>
      </div>

      <Drawer
        title="Transfer"
        isOpen={isDrawerOpen}
        onClose={handleDrawerClose}
      >
        <MultistepForm
          onClose={handleDrawerClose}
          balance={balance}
          banks={banks}
        />
      </Drawer>

      <ExportCSVModal
        open={open}
        handleOk={handleOk}
        confirmLoading={confirmLoading}
        handleCancel={handleCancel}
        email={email}
        setEmail={setEmail}
      />
    </div>
  );
}
