import React from 'react'
import {Card, Divider} from 'antd'
import ReviewImg from '../../../static/images/review.svg'
import * as Style from './kyc_style'
import { isMobile } from 'react-device-detect'

const ReviewCard = props => {
    return(
        <Card style={Style.reviewCard} bodyStyle={{padding: '20px 0px 20px 0px'}} hidden={props.details.step != 7 && props.details.step != 8}>
            <div style={{display:'flex', justifyContent: 'space-between', padding: '0px 20px 0px 20px'}}>
                <div>
                    <div style={Style.header2Style}>Application status</div>
                    <div style={{...Style.normalText, color: '#000'}}>Date Application: {props.details.submission_date}</div>
                </div>
                <div style={{...Style.normalText, color: '#000', display:'flex', marginTop: '10px'}}>
                    <div style={{
                        border: '1px solid #F5922F', 
                        height: '32px',
                        width: '97px',
                        borderRadius: '4px',
                        textAlign: 'center',
                        backgroundColor: 'rgba(245, 146, 47, 0.1)',
                        marginLeft: '10px'
                    }}>
                        <div style={{...Style.miniText, color: '#000', lineHeight: '32px'}}>Reviewing</div>
                    </div>
                </div>
            </div>
            <Divider/>
            <div style={{textAlign: 'center'}}>
                <div style={{...Style.normalText, color: '#000'}}>Haven’t received your status yet? Please contact us at <a href="mailto:support@bux.ph" style={{color: '#f5922f', fontWeight: '600'}}><u>support@bux.ph</u></a></div>
            </div>
        </Card>
    )
}

export default ReviewCard