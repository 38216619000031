import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Divider,
  Pagination,
  Table,
  Typography,
  notification,
} from "antd";
import { getTransfersRequest, setTransferRequest } from "./api";
import Invalid from "../../../../static/icons/invalid_icon.svg";
import Valid from "../../../../static/icons/valid_icon.svg";
import { extractSubdomain } from "../../../multiwallet/extractSubdomain";
import { UserDataCell } from "../../components/UserDataCell";
import { StatusChip } from "../../components/StatusChip";

const approverTransfersStatusMap = {
  SU: "Approved",
  FA: "Declined",
  PE: "Pending",
};

export function ApproverTransfers() {
  const [transfersRequest, setTransfersRequest] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const fetchRequestData = () => {
    const currentUrl = window.location.href;
    const extractedSubdomain = extractSubdomain(currentUrl);
    getTransfersRequest(extractedSubdomain, currentPage)
      .then((data) => {
        setTransfersRequest(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchRequestData();
  }, [currentPage]);

  const openNotification = (status, message) => {
    notification.info({
      message: status,
      description: message,
      placement: "topRight",
    });
  };

  const handleTransferRequestApprove = ({ id }) => {
    setTransferRequest(id, "SU").then((res) => {
      const { status, message } = res;
      openNotification(status, message);
      fetchRequestData();
    });
  };

  const handleTransferRequestDecline = ({ id }) => {
    setTransferRequest(id, "FA").then((res) => {
      const { status, message } = res;
      openNotification(status, message);
      fetchRequestData();
    });
  };

  const tableColumns = [
    {
      title: "Action",
      key: "action",
      fixed: "left",
      render: (_, record) =>
        record.status === "PE" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              icon="check"
              size="small"
              onClick={() => handleTransferRequestApprove(record)}
            />
            <Divider type="vertical" />
            <Button
              type="danger"
              icon="close"
              size="small"
              onClick={() => handleTransferRequestDecline(record)}
            />
          </div>
        ),
    },
    {
      title: "Reference ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Receiver",
      dataIndex: "destination_email",
      key: "destination_email",
      render: (text, record) => (
        <UserDataCell
          name={record.destination_name}
          email={record.destination_email}
        />
      ),
    },
    {
      title: "Requestor",
      dataIndex: "source_email",
      key: "source_email",
      render: (text, record) => (
        <UserDataCell name={record.source_name} email={record.source_email} />
      ),
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <StatusChip
          status={status}
          label={approverTransfersStatusMap[status]}
        />
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => (
        <Typography style={{ whiteSpace: "nowrap" }}>{amount}</Typography>
      ),
    },
  ];

  return (
    <div
      style={{
        margin: "5em 2.5em 2.5em 0",
      }}
    >
      <h1
        style={{
          width: "100%",
          color: "rgb(0, 0, 0)",
          fontWeight: "bold",
          fontSize: "28px",
          display: "flex",
          alignItems: "center",
        }}
      >
        Transfers Request
      </h1>

      <Card>
        <Table
          rowClassName="table-row-light"
          columns={tableColumns}
          dataSource={transfersRequest ? transfersRequest.results : []}
          pagination={false}
          loading={false}
          scroll={{ x: true }}
          style={{ maxWidth: "82dvw" }}
        />
        <Pagination
          size="small"
          total={(transfersRequest && transfersRequest.total) || 0}
          defaultPageSize={pageSize}
          pageSize={pageSize}
          current={currentPage}
          onChange={setCurrentPage}
          style={{ paddingTop: "12px", textAlign: "right" }}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
        />
      </Card>
    </div>
  );
}
