import React from 'react';
import AdminGCashSubMIDs from '../../../components/AdminComponents/merchant_ids/gcash_submids';

class GCashSubMIDs extends React.Component {
    render() {
      return (
        <AdminGCashSubMIDs />
      )
    };
  }
  
  export default GCashSubMIDs;