import React from 'react'
import axios from 'axios'
import { Card, Select, Input, Icon, Switch, message, Spin, DatePicker } from 'antd'
import enterpriseStyle from '../kybStyle'
import { history } from '../../../../store/history'
import moment from 'moment';
import approvedCard from '../../../../static/icons/approved.svg'

const { TextArea } = Input;

const ContractExpiryCard = props => {
    const userRole = localStorage.getItem("userType")
    const adminRole = ['AD', 'KB', 'SD', 'OP', 'BS', 'AP', 'EN', 'FI',]
    const hiddenSteps = [8, 9, 11]
    let kybStep = localStorage.getItem("KYBStep")
    const kyb = enterpriseStyle()

    const [step, setStep] = React.useState("")
    const [contractExpiryDate, setContractExpiryDate] = React.useState("")
    const [submissionId, setSubmissionId] = React.useState("")
    const [remarks, setRemarks] = React.useState("")
    const [emailerNote, setEmailerNote] = React.useState("")
    const [approvedBy, setApprovedBy] = React.useState("")
    const [showSpin, setShowSpin] = React.useState(false)

    async function getRemarks() {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.get(
            process.env.REACT_APP_API_URL + `/api/kyb/${props.userId}/`, yourConfig)
        setShowSpin(true)
        setStep(response.data.step)
        setContractExpiryDate(moment(response.data.contract_expiry, 'MMMM DD YYYY'))
        setRemarks(response.data.remarks)
        setEmailerNote(response.data.notes)
        setSubmissionId(response.data.id)
        setApprovedBy(response.data.approved_by)
    }

    async function submitStatus(val) {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.put(
            process.env.REACT_APP_API_URL + `/api/admin/kyb/`, val, yourConfig)

        if (response.data.status == "failed") {
            message.error(response.data.message, 5)
        }

        else {
            message.success("Done")
            history.push('/submissions_kyb/')
        }
    }

    function submitStep(val) {
        let failed_steps = props.failedSteps

        let params = {
            submission_id: submissionId,
            is_approved: val == 9 ? false : true,
            remarks,
            step_1_has_error: failed_steps[0] == false ? true : false,
            step_2_has_error: failed_steps[1] == false ? true : false,
            step_3_has_error: failed_steps[2] == false ? true : false,
            step_4_has_error: failed_steps[3] == false ? true : false,
            step_5_has_error: failed_steps[4] == false ? true : false,
            step_6_has_error: failed_steps[5] == false ? true : false
        }

        submitStatus(params)
    }


    function approvedDiv() {
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D1D5DD' }}>
                    <div style={{ marginTop: 16, paddingBottom: 16, }}>
                        <div className={kyb.headerStyle}>
                            Contract Expiry Information
                        </div>
                    </div>
                </div>

                <div className={kyb.miniText} style={{ marginTop: 16 }}>
                    Approved by: <b>{approvedBy}</b>
                </div>
            </div>
        )
    }

    async function updateContractExpiry() {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = {
            submission_id: submissionId,
            contract_expiry: contractExpiryDate.format('MMMM DD YYYY, hh:mm A'),
            notes: emailerNote,
        }
        console.log(params)
        
        let response = await axios.patch(
            process.env.REACT_APP_API_URL + '/api/admin/kyb/contract_expiry/', params, yourConfig
        );
            
        console.log(response)
        
        if (response.data.status == 'success') {
            message.success(response.data.message, 5)
        }
        else {
            message.error(response.data.message, 5)
        }


        
    }

    function onDateChange(date) {
        if (date != null) {
            setContractExpiryDate(date)
        }
    }

    function onSetNotes(text){
        setEmailerNote(text)
    }

    React.useEffect(() => {
        getRemarks()
    }, [])


    return (
        <Card
            className={kyb.formCardStyle}
            bodyStyle={{ padding: '20px 16px 20px 16px' }}
        >

            <div hidden={showSpin} align="center">
                <Spin />
            </div>

            <div hidden={!showSpin}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ marginTop: 16, paddingBottom: 16, }}>
                            <div className={kyb.headerStyle}>
                                Contract Expiry Information
                            </div>
                        </div>
                    </div>

                    <div className={kyb.miniText} style={{ marginTop: 16 }}>
                        Contract Expiry Date:
                    </div>
                    <DatePicker
                        style={{ zIndex: 1, width: '100%' }}
                        format='DD/MM/YY'
                        defaultValue={contractExpiryDate ? moment(contractExpiryDate, 'MMMM DD YYYY') : moment('', 'MMMM DD YYYY')}
                        value={contractExpiryDate}
                        placeholder="Contract Expiry Date"
                        onChange={onDateChange}
                    />
                </div>
                <div style={{ marginTop: 20 }}>
                    <div className={kyb.miniText}>Emailer Note</div>
                    <TextArea autoSize={{ minRows: 4, maxRows: 4 }} maxLength={255} value={emailerNote} onChange={(e) => setEmailerNote(e.target.value)} />
                </div>

                <div align='right' style={{ marginTop: 20 }}>
                    <div hidden={((!adminRole.includes(userRole)) && hiddenSteps.includes(parseInt(kybStep)))}>
                        <button className={kyb.editBtn} onClick={() => updateContractExpiry()}>Update</button>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default ContractExpiryCard