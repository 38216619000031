import { PaymentActionTypes } from "./paymentAction";

const initialState = {
    payment: {},
    loading: false,
    error: ""
}

export default function paymentReducers(state = initialState, action){
    switch (action.type){
        case PaymentActionTypes.GET_PAYMENT_REQUEST:
            return {
                ...state,
                loading:true
            };
        case PaymentActionTypes.GET_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                payment: action.payload
            };
        case PaymentActionTypes.GET_PAYMENT_FAILED:
            return {
                ...state,
                loading: false,
                payment: {},
                error: action.payload
            };
        default:
                return initialState;
    }
}