import { makeStyles } from '@material-ui/core';

const enterpriseStyle = makeStyles(theme => ({

    mainBody: {
        fontFamily: 'Inter',
        width: 600,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 20
        },
    },

    mainHeader: {
        fontWeight: 600,
        fontSize: 25,
        lineHeight: '32px',
        marginTop: 48,
        color: '#000000',
        [theme.breakpoints.down('sm')]: {
            marginTop: '5%',
        },
    },

    step: {
        marginTop: 16,
        fontSize: 16,
        lineHeight: '24px',
        color: '#0000000'
    },

    uploadBtnStyle: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1D82B8',
        border: '1px solid #1D82B8',
        width: '100%',
        height: '48px',
    },

    biMain: {
        marginTop: 16,
        borderRadius: 4,
        padding: 32,
        background: '#fff',
        color: '#0000000',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        [theme.breakpoints.down('sm')]: {
            padding: 20,

        },

    },

    rejectedCard:{
        padding: '32px',
        marginTop: '16px',
        border: '1px solid #F8B966',
        borderRadius: '4px',
        background: 'rgba(248, 185, 102, 0.1)',
    },

    biHeader: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '32px'
    },

    biFields: {
        marginTop: 16,
    },

    biPlaceholder: {
        fontSize: 12,
    },

    biInput: {
        marginBottom: 16,
    },

    fbBtn: {
        width: 260,
        height: 48,
        background: '#3B5998',
        border: '1px solid #3b5998',
        borderRadius: '4px',
        color: '#fff',
        fontWeight: 600,
        fontSize: 16,
        cursor: 'pointer',

    },

    igBtn: {
        width: 260,
        height: 48,
        background: '#3E7199',
        border: '1px solid #3E7199',
        borderRadius: '4px',
        color: '#fff',
        fontWeight: 600,
        fontSize: 16,
        cursor: 'pointer',

    },

    bimainPlaceHolder: {
        marginBottom: 16,
    },

    fileName: {
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#54575F',
        width: '50%',
        textOverflow: 'ellipsis',
        marginRight: '30px'
    
    },

    inputReadOnly: {
        color: '#000000',
        opacity:'0.6',
        background: '#E6EAF0',
        border: '1px solid #E6EAF0',
        height: '38px',
        padding: '8px',
        width: '260px',
        borderRadius: '4px'
    },


    submit: {
        background: '#1d82b8',
        color: '#fff',
        fontWeight: 600,
        width: '100%',
        height: 48,
        borderRadius: 4,
        border: '1px solid #1d82b8',
        cursor: 'pointer',
        marginTop: 28,
    },

    submit1: {
        background: '#1d82b8',
        color: '#fff',
        fontWeight: 600,
        width: '280px',
        height: 48,
        borderRadius: 4,
        border: '1px solid #1d82b8',
        cursor: 'pointer',
        marginTop: 28,
    },

    fileReq:{
        background: '#E6EAF0',
        padding: '12px 16px 16px 16px',
        marginBottom: '20px',
        borderRadius: '4px',

    },

    fileReqHeader:{
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '32px',
        color: '#000000',
    },

    fileReqText:{
        fontSize: '14px',
        lineHeight: '17px',
        color: '#54575F',
    },

    text: {
        fontWeight: 600,
        fontSize: 16
    },

    Back: {
        background: '#f5f5f4',
        color: '#000000',
        fontWeight: 600,
        width: '280px',
        height: 48,
        borderRadius: 4,
        border: '2px solid #D1D5DD',
        cursor: 'pointer',
        marginTop: 28,
    },

    uploadBtn: {
        background: '#fff',
        border: '1px solid #1d82b8',
        color: '#1d82b8',
        fontWeight: 600,
        width: 260,
        height: 48,
        fontSize: '18px',
        cursor: 'pointer'
    },

    logoPos: {
        marginRight: 16,
    },

    moaHeader: {
        color: '#0D3D76',
        fontWeight: 600,
        fontSize: 28,
        lineHeight: '32px',
        paddingBottom: 32,
        borderBottom: '1px solid #D1D5DD',

    },

    footerDesign: {
        background: '#FFFFFF',
        border: '2px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
        width: 460,
        padding: '20px 60px 20px 60px',
        position: 'relative'
    },

    sigBtn: {
        width: 170,
        height: '46px',
        width: '171px',
        background: '#FFFFFF',
        borderRadius: '50px',
        border: '1px solid #fff',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
        outline: 0,
        cursor: 'pointer',
        color: '#000000',
        fontWeight: 500
    },
    
    signConfirmBtn: {
        width: 460,
        height: '46px',
        fontWeight: 600,
        marginTop: 32,
        color: '#fff',
        background: '#1d82b8 !important',
        border: '1px solid #1d82b8',
        borderRadius: '4px',
        marginBottom: 32,

    },

    nda: {
        marginTop: 16,
    }
}))

export default enterpriseStyle;