import React from 'react';
import moment from 'moment';
import { Typography, Divider, Card } from 'antd';
import promotionServices from '../../components/AdminComponents/promotion/promotionServices';

const PromoCards = () => {
    const userType = localStorage.getItem('userType');
    const [promos, setPromos] = React.useState([]);

    const getPromotions = async () => {
        // try {
        //     const res = await promotionServices.getPromotions();
        //     console.log('RESPONSE_PRMO', res);
        //     if(res.status === 'success'){ 
        //         setPromos(res.data);
        //     }
        // } catch (error) {
        //     console.log(error);
        // }

    }

    React.useEffect(() => {
        getPromotions();
    }, [])

    const renderCards = (data) => {
        return data && data.map((item, i) => {
            return (
                <div style={{ margin: '20px 0' }}>
                    <Card
                        style={styles.cardRoot}
                        bodyStyle={styles.cardBody}
                    >
                        <div style={styles.discountPrice}>
                            <Typography style={styles.discountText}>{item.fixed_discount}</Typography>
                            <Typography style={styles.discountText}>OFF</Typography>
                        </div>
                        <Divider style={styles.divider} type='vertical' dashed />
                        <div style={{ margin: '0 12px' }}>
                            <Typography style={{ ...styles.fontText }}>PROMO CODE</Typography>
                            <Typography style={{ ...styles.fontText, fontWeight: 'bold', fontSize: 18 }}>{item.code}</Typography>
                            <Typography style={{ ...styles.fontText, whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Valid until: {moment(item.date_expiry).format('LLL')}</Typography>
                        </div>
                    </Card>
                </div>
            )
        })
    }

    return (
        <div hidden>
            <div style={styles.content}>
                <div style={styles.switchDiv}>
                    <Typography style={styles.tranxSub}>Promotion</Typography>
                </div>
                <Typography style={styles.desc}>Share this promo code to your customer to get them discounted on the transaction fees upon checkout.</Typography>
                {renderCards(promos)}
            </div>
        </div>
    )
}

const styles = {
    content: {
        padding: '0 32px 20px 32px'
    },
    cardRoot: {
        padding: 0,
        borderRadius: '8px',
        borderLeft: '5px solid #41BD5C',
        width: '86%'
    },
    cardBody: {
        padding: 14,
        display: 'flex',
    },
    switchDiv: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '24px 0 8px 0'
    },
    desc: {
        fontSize: 14,
        color: '#2B2D32',
        opacity: '0.8',
        lineHeight: '14px',
    },
    tranxSub: {
        color: '#2B2D32',
        fontSize: 16,
        fontWeight: '500'
    },
    discountPrice: {
        display: 'block',
        width: '60px',
        height: '60px',
        borderRadius: '50px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#41BD5C',
        margin: '0 14px',
        lineHeight: 'normal',
        padding: 32
    },
    divider: {
        height: 'auto',
        margin: '0 12px'
    },
    discountText: {
        fontSize: 14,
        fontWeight: '700',
        color: '#FFF'
    },
    fontText: {
        color: '#2B2D32',
        fontSize: 12
    }
}

export default PromoCards;