import React from 'react';
import { Button, Icon, Input, message, Modal, Rate, Typography } from 'antd';
import { AngryHover, AngrySelected, AngryUnselected, 
    HappyHover, HappySelected, HappyUnselected, LoveHover, 
    LoveSelected, LoveUnselected, SadHover, SadSelected, 
    SadUnselected, SmileHover, SmileSelected, SmileUnselected } from '../../static/images/rating_emoji';
import surveyServices from './surveyServices';
import { isMobile } from 'react-device-detect';

const MicroSurveyModal = (props) => {
    const [rating, setRating] = React.useState(0);
    const [hoverState, setHoverState] = React.useState(0);
    const [feedback, setFeedback] = React.useState('');
    const [suggestions, setSuggestions] = React.useState('');
    // const [skip, setSkip] = React.useState(false);

    const emojis = [
        {
            key: 1,
            active: AngrySelected,
            inactive: AngryUnselected,
            hover: AngryHover,
        },
        {
            key: 2,
            active: SadSelected,
            inactive: SadUnselected,
            hover: SadHover,
        },
        {
            key: 3,
            active: SmileSelected,
            inactive: SmileUnselected,
            hover: SmileHover,
        },
        {
            key: 4,
            active: HappySelected,
            inactive: HappyUnselected,
            hover: HappyHover,
        },
        {
            key: 5,
            active: LoveSelected,
            inactive: LoveUnselected,
            hover: LoveHover,
        },
    ]

    const renderSmileyRating = () => {

        const ratingChange = (e) => {
            console.log(e)
            setRating(e);
        }

        const onMouseHover = (e) => {
            console.log(e)
            setHoverState(e);
        }

        const onMouseLeave = () => {
            setHoverState(0);
        }

        return (
            <div style={{textAlign: 'center'}}>
                {emojis.map((e, i) => {
                    return (
                        <a onClick={() => ratingChange(e.key)} style={{padding: '0 4px'}}
                            onMouseLeave={onMouseLeave}
                            onMouseOver={() => onMouseHover(e.key)}
                        ><img alt='sad' src={(hoverState == e.key && rating == e.key) ? e.active : hoverState == e.key ? e.hover :  rating == e.key ? e.active : e.inactive} />
                        </a>
                    )
                })}
            </div>
        )
    }

    const onSubmit = async (skip) => {
        const params = {
            survey_rating: rating,
            feedback: feedback,
            suggestions: suggestions,
            code: 'LOGOUT',
            type: isMobile? 'mobile' : 'desktop'
        }

        try {
            const res = await surveyServices.submitMicroSurvey(skip === true ? { maybe_later: true,  code: 'LOGOUT', type: isMobile? 'mobile' : 'desktop' } : params);
            console.log(res);
            if( res.status === 'success') {
                if (skip === true) {
                    props.logout();
                } else {
                    props.openThanYou();
                }
            }
        } catch (error) {
            message.error('Error try again')
        }
    }

    const resetFields = () => {
        setRating(0);
        setFeedback('');
        setSuggestions('');
        // setSkip(false);
    }

    React.useEffect(() => {
        resetFields();
    }, [props.visible])

    return (
        <Modal
            maskClosable
            onCancel={() => onSubmit(true) }
            visible={props.visible}
            footer={false}
            closeIcon={<Icon type="close" className={`text-${props.segments}`} />}
            width={450}
            destroyOnClose
            bodyStyle={styles.root}
        >
            <Typography style={styles.title}>We love to hear from you!</Typography>
            <Typography style={styles.msg}>How satisfied are you after using BUx?</Typography>

            <div style={{ padding: '24px 0', width: '100%' }}>
                {renderSmileyRating()}
                {/* <Rate character={<Icon type="smile" style={{ width: '32px' }} />} allowHalf allowClear={false} style={{ width: '100%' }} /> */}
            </div>
            <div>
                <label style={styles.label}>What do you like about BUx?</label>
                <Input.TextArea
                    placeholder="Message here"
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    style={{ marginTop: '4px' }}
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value) }
                />
            </div>
            <div style={{ paddingTop: '24px' }}>
                <label style={styles.label}>How can we make your experience better?</label>
                <Input.TextArea
                    placeholder="Tell us your comments, suggestions or any feedback that would help us improve our services."
                    autoSize={{ minRows: 4, maxRows: 6 }}
                    style={{ marginTop: '4px' }}
                    value={suggestions}
                    onChange={(e) => setSuggestions(e.target.value)}
                />
            </div>
            <div style={styles.buttonWrapper}>
                <button className={`outline-btn--${props.segments} btn-height`} style={{ marginRight: '12px' }} onClick={() => onSubmit(true)} >Maybe later</button>
                <button className={`btn--${props.segments}`} style={{ ...styles.btn, opacity: (!rating || rating <= 0)? '.5' : '1' }}
                    onClick={onSubmit} disabled={(!rating || rating <= 0)} >Submit</button>
            </div>
        </Modal>
    )
}

const styles = {
    root: {
        padding: '32px 24px 24px 24px'
    },
    title: {
        color: '#2B2D32',
        fontSize: 18,
        fontWeight: '700',
        textAlign: 'left',
        paddingTop: '24px'
    },
    msg: {
        color: '#2B2D32',
        fontSize: 16,
        fontWeight: '500',
        textAlign: 'left',
        padding: '6px 0 12px 0'
    },
    label: {
        fontSize: 14,
        fontWeight: '400',
        color: 'rgba(43, 45, 50, 0.8)',
    },
    btn: {
        height: 40,
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 4,
        minWidth: '102px'
    },
    input: {
        padding: '11px 16px 11px 16px',
        fontSize: 16,
        height: 48,
        margin: '4px 0px 12px 0px',
        color: '#2B2D33'
    },
    buttonWrapper: {
        width: '100%',
        textAlign: 'right',
        gap: '12px',
        padding: '24px 0 12px 0'
    }
}

export default MicroSurveyModal;