import Axios from "axios";
import { API_URL } from "../config";

export const axios = Axios.create({
  baseURL: API_URL
});

function authRequestInterceptor(config) {
  const token = localStorage.getItem("jwtToken");
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  config.headers.Accept = "application/json";
  return config;
};

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use((response) => {
  return response.data;
}, (error) => {
  return Promise.reject(error);
});
