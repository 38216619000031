import React from 'react';
import { Button, Icon, Input, Modal, Typography } from 'antd';

const AdvanceEnableModal = (props) => {
    const [email, setEmail] = React.useState(null);
    const segments = localStorage.getItem('segments');
    const { merchantName, visible, onSave, onClose, onChange, settingsParams } = props; 

    React.useEffect(() => {
        setEmail(null);
    },[visible])

    return (
        <Modal
            maskClosable
            onCancel={() => {setEmail(null); onClose();}}
            visible={visible}
            footer={false}
            closeIcon={<Icon type="close" style={{color:'#F5922F'}}/>}
            width={450}
            destroyOnClose
        >
            <Typography style={styles.title}>Enable Advance PH</Typography>
            <Typography style={styles.msg}>
                Are you sure you want to enable this feature to merchant <b>{merchantName}</b>, Inc? By enabling this feature Advance Payout max amount and fee should be set to this merchant.</Typography>
            <div style={{paddingTop: '8px'}}>
                <label style={{fontSize: 14}}>Advance Payout max amount</label>
                <Input addonBefore="₱"
                    style={{ ...styles.input}} size='large' name="max_amount" value={settingsParams.max_amount}
                    onChange={onChange} />
            </div>
            <div>
                <label style={{fontSize: 14}}>Fee (%)</label>
                <Input
                    style={{ ...styles.input}} size='large' name="fee" value={settingsParams.fee}
                    onChange={onChange} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <button className={`outline-btn--${segments}`} style={{ ...styles.copyBtn, marginRight: 4 }} onClick={onClose}>
                        Cancel
                    </button>
                <button className={`btn--${segments}`} style={{ ...styles.editBtn, width: 76 }}
                    disabled={settingsParams.max_amount === '' || settingsParams.fee === ''} onClick={onSave}>
                    Save</button>
            </div>
        </Modal>
    )
}

const styles = {
    title: {
        color: '#2B2D33',
        fontSize: 24,
        fontWeight: '400',
        textAlign: 'left'
    },
    msg: {
        color: '#2B2D33',
        fontSize: 16,
        textAlign: 'left',
        padding: '12px 0'
    },
    btn: {
        height: 48,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 4,
        width: '100%',
    },
    input: {
        // padding: '11px 16px 11px 16px',
        fontSize: 16,
        margin: '0px 0px 12px 0px',
        color: '#2B2D33'
    },
}

export default AdvanceEnableModal;