import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ButtonGroup from 'antd/lib/button/button-group';
import moment from 'moment';
import Table from './table';
import fraudServices from '../fraudServices';
import UploadCsvModal from './uploadCsvModal';
import AddNewModal from './addNewModal';
import fraudActions from '../fraudActions';
import { jsonToCSV } from 'react-papaparse';
import DomainCheckingModal from './domainCheckingModal';
import { Button, DatePicker, Dropdown, Icon, Input, Menu, message } from 'antd';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddEmailModal from './addEmailModal';

const ButtonTab = ({rootStyle}) => {
  const dispatch = useDispatch();
  const selected = useSelector(state => state.fraud.emailBlcklstSelected);
  
  const status = ['Email Domain', 'Email Address', 'Bounce List'];
  
  const changeSelectedTab = (event) => {
    dispatch(fraudActions.setBlcklstSelectedBtn(event.target.value));
  }
  
  return (
    <div style={styles.btnTabContainer}>
      <ButtonGroup>
        {
          status.map((btn, i) => {
            return (
              <Button
                key={btn}
                value={btn}
                onClick={changeSelectedTab}
                style={{
                  ...styles.btnGroup, 
                  color: selected === btn? '#0D3D76' : '#909196', 
                  backgroundColor: '#FFF',
                  borderBottom: selected === btn? '4px solid #0D3D76' : '4px solid #909196' 
                }}
              >
                {btn}
              </Button>
            )
          })
        }
      </ButtonGroup>
    </div>
  )
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{padding: 0}} p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Blacklisted = () => {
  const selected = useSelector(state => state.fraud.emailBlcklstSelected);
  const currentPage = useSelector(state => state.fraud.tablePage);
  const tableData = useSelector(state => state.fraud.tableData);
  const checkDomainModalData = useSelector(state => state.fraud.checkModalData);
  const domainCSV = useSelector(state => state.fraud.fraudCSV);
  const dispatch = useDispatch();

  const [dateSearch, setDateSearch] = React.useState(false);
  const [uploadCsvShow, setUploadCsvShow] = React.useState(false);
  const [addNewShow, setAddNewModalShow] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [checkModalShow, setCheckModalShow] = React.useState(false);
  const [emailModalShow, setAddEmailModalShow] = React.useState(false);

  const [dateRange, setDateRange] = React.useState({startDate: moment().format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY')});
  const [exportButtonStyle, setExportButtonStyle] = React.useState({ color: '#1DD28B', backgroundColor: 'transparent'});
  const [importButtonStyle, setImportButtonStyle] = React.useState({ color: '#1DD28B', backgroundColor: 'transparent'});
  const [totalLength, setTotalLength] = React.useState(null);
  const [sortOrder, setSortOrder] = React.useState('newest');


  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }

  const rangeSelect = (dates, dateString, id) => {
    setDateRange({...dateRange, [id]: moment(dateString).format('MM-DD-YYYY')});
    if(id==='startDate') {
      getDataFromBackEnd('', 1, returnType(), sortOrder, moment(dateString).format('MM-DD-YYYY'), dateRange['endDate']);
    } else {
      getDataFromBackEnd('', 1, returnType(), sortOrder, dateRange['startDate'], moment(dateString).format('MM-DD-YYYY'));
    }
    setDateSearch(true);
  };

  function extinguishButtonHighlight(event) {
    const setterFxn = event.target.name === 'exportBtn' ? setExportButtonStyle : setImportButtonStyle;
    setterFxn({
      color: '#1DD28B',
      backgroundColor: 'transparent'
    });
  }

  function highlightButtonGreen(event) {
    const setterFxn = event.target.name === 'exportBtn' ? setExportButtonStyle : setImportButtonStyle;
    setterFxn({
      color: '#FFF',
      backgroundColor: '#1DD28B'
    });
  }

  const exportCsv = async () => {
    let data, res, arr = [];

    setIsLoading(true);
    try {
      const serviceToUse = selected === 'Bounce List' ? fraudServices.getBounceListData : fraudServices.getBlacklistData;
      if(dateSearch){
        res = await serviceToUse('', 1, returnType(), sortOrder, dateRange.startDate, dateRange.endDate, false);
      } else {
        res = await serviceToUse('', 1, returnType(), sortOrder, '', '', true);
      }

      if(res && res.results){
        data = res.results;
      }

    } catch (error) {
      console.log(error);

    } finally {
      setIsLoading(false);
    }

    if (!data || !data.length) {
      message.info("Data is empty. Nothing to export.")
      return;
    }

    data.forEach(item => {
      if(returnType() === 'domain') {
        arr.push({
          "Blacklist Code": item.domain_code,
          "Domain Name": item.name,
          "Date Added": item.created_at,
          "Added By": item.added_by,
        });
      } else if (selected === "Bounce List") {
        arr.push({
          "Bounce List Code": item.email_code,
          "Email Address": item.email,
          "Date Added": item.created_at,
          "Added By": item.added_by,
        });
      } else {
        arr.push({
          "Blacklist Code": item.email_code,
          "Email Address": item.email,
          "Date Added": item.created_at,
          "Added By": item.added_by,
        });
      }
    });  
    
    const csv = jsonToCSV(arr);
    const blob = new Blob([csv]);
    const a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
    a.download = returnType() === 'domain'
      ? `blacklisted_email_domain.csv` 
      : returnType() === 'bounce'
        ? `unreachable_email_address.csv`
        : `blacklisted_email_address.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const handleSearch = (e) => {
    getDataFromBackEnd(e.target.value, 1, returnType());
    resetProps();
  }

  const getDataFromBackEnd = async (search, page, type, sort, start, end, dump) => {
    setIsLoading(true);
    try {
      /* Notice how `type` is not needed when (selected === 'Bounce List') */

      const serviceToUse = selected === 'Bounce List' ? fraudServices.getBounceListData : fraudServices.getBlacklistData;
      const res = await serviceToUse(search?search:'', page?page:1, type&&type, sort&&sort, start, end, dump);

      if(res && res.results){
        dispatch(fraudActions.setTableData(res.results));
        setTotalLength(res.total);
        dispatch(fraudActions.setTablePage(1));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const changeSortOrder = ({key}) => {
    setSortOrder(key);
    getDataFromBackEnd('',1,returnType(), key);
    resetProps();
  }

  const menu = (
    <Menu onClick={changeSortOrder}>
      <Menu.Item key="newest">
        Newest
      </Menu.Item>
      <Menu.Item key="oldest">
        Oldest
      </Menu.Item>
    </Menu>
  )

  const returnType = () =>  {
    switch (selected) {
      case 'Email Domain':
        return 'domain';
      case 'Email Address':
        return 'email';
      case 'Bounce List':
        return 'bounce';
      default:
        return 'domain';
    }
  }

  const resetProps = () => {
    setDateSearch(false);
    setDateRange({startDate: moment().format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY')});
  }

  useEffect(() => {
    getDataFromBackEnd('',1,returnType(), sortOrder);
    resetProps();
  },[selected])
  
  return (
    <div style={styles.root}>
      <UploadCsvModal
        visible={uploadCsvShow} 
        closeModal={() => setUploadCsvShow(false)} 
        onSuccess={() => {
          setUploadCsvShow(false);
          setCheckModalShow(true);
        }}
      />
      <AddNewModal
        visible={addNewShow}
        closeModal={() => setAddNewModalShow(false)}
        onSuccess={() => {
          setAddNewModalShow(false);
          getDataFromBackEnd('',1,returnType(), '');
        }}
      />
      <AddEmailModal
        visible={emailModalShow}
        closeModal={() =>setAddEmailModalShow(false)}
        onSuccess={() => {
          setAddEmailModalShow(false);
          getDataFromBackEnd('',1,returnType(), '');
        }}
        isHostile={selected === 'Email Address'}
      />
      <DomainCheckingModal
        visible={checkModalShow}
        closeModal={() => {setCheckModalShow(false);}}
        data={checkDomainModalData}
        csv={domainCSV}
        onSuccess={()=>{
          setCheckModalShow(false);
          getDataFromBackEnd('',1,returnType(), '');
        }}
      />
      <div style={styles.btnMain}>
        <div>
          <Button 
            onClick={exportCsv}
            name='exportBtn'
            onMouseOver={highlightButtonGreen}
            onMouseLeave={extinguishButtonHighlight}
            style={{...styles.dload,
              ...exportButtonStyle,
            }}
          >
              Export CSV
          </Button>
          <Button 
            onClick={()=>setUploadCsvShow(true)}
            name='importBtn'
            onMouseOver={highlightButtonGreen}
            onMouseLeave={extinguishButtonHighlight}
            style={{...styles.dload,
              ...importButtonStyle,
            }}
          >
              Import CSV
          </Button>
          <Button 
            onClick={() => selected === 'Email Domain' ? setAddNewModalShow(true) : setAddEmailModalShow(true)}
            style={{...styles.import,
              color: '#FFF',
              backgroundColor: '#1DD28B',
            }}
          >
            Add New
          </Button>   
        </div>
      </div>
      <ButtonTab />
      <div style={styles.body}>
        <div style={styles.dateBtn}>
          <Dropdown overlay={menu} trigger={['click']}>
            <a style={styles.dropdownItems} >
              {sortOrder.charAt(0).toUpperCase() + sortOrder.substring(1)}
              <ExpandMoreIcon style={styles.dropdownArror} />
            </a>
          </Dropdown>
          <DatePicker 
            format='MM/DD/YYYY' 
            disabledDate={disabledDate} 
            defaultValue={moment()} 
            value={dateRange && moment(dateRange.startDate)} 
            size="large" picker="month" 
            onChange={ (dates, dateString) => rangeSelect(dates, dateString, 'startDate')} 
            placeholder='Start date' 
            style={styles.datePic} 
            suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{fontSize: 22}} /></div>}
            allowClear={false}/>
          <span style={styles.span}>-</span>
          <DatePicker 
            format='MM/DD/YYYY' 
            disabledDate={disabledDate} 
            defaultValue={moment()}
            value={dateRange && moment(dateRange.endDate)} 
            size="large" 
            picker="month" 
            onChange={ (dates, dateString) => rangeSelect(dates, dateString, 'endDate')} 
            placeholder='End date' 
            style={styles.datePic}
            suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{fontSize: 22}} /></div>}
            allowClear={false}/>
          <Input
            placeholder="Search"
            style={{ width: '200px', height: '40px', borderRadius:'4px' }}
            suffix={<Icon type="search" style={{fontSize:'18px'}}/>}
            onChange={handleSearch}
          />
        </div>
        <Table data={tableData} 
          onDeleted={() => getDataFromBackEnd('',currentPage, returnType())} 
          isLoading={isLoading} 
          length={totalLength} 
          selected={selected}
          sort={sortOrder}
          handleChangePage={() => getDataFromBackEnd('',currentPage)} />
      </div>
    </div>
  )
}

const styles = {
  root: {
    padding: '0 42px'
  },
  body: {
    backgroundColor: '#FFF',
    padding: '0 20px'
  },
  btnGroup: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '20px 32px 24px 32px',
    borderColor: 'transparent'
  },
  btnMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: '30px',
    marginTop: '-42px'
  },
  datePic: {
    height: 40,
    fontSize: 14,
    color: '#2F3542',
    width: 178,
    padding: '0 8px 0 8px'
  },
  dateBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '32px 0 20px 0',
  },
  suffixIcon: {
    marginTop: '-12px', 
    marginRight: 12, 
    color: '#000'
  },
  dload: {
    fontSize: 16,
    fontWeight: '600',
    height: 40,
    borderRadius: 10,
    borderColor: '#1DD28B',
    marginRight: 8,
  },
  import: {
    fontSize: 16,
    fontWeight: '600',
    height: 40,
    borderRadius: 10,
    marginLeft: 8,
  },
  btnTabContainer: {
    backgroundColor: '#FFF',
    width: '100%',
    padding: '20px, 20px 0 20px',
    borderBottom: '1px solid #D1D5DD'
  },
  dropdownItems: {
    display: 'flex', 
    alignItems: 'center', 
    padding: '7px 4px 7px 16px',
    fontSize: 16, 
    color: '#2B2D33',
    border: '1px solid #D1D5DD',
    borderRadius: 5,
  },
  dropdownArror: {
    color: '#2B2D33', 
    fontSize: 24, 
    margin: '0 4px'
  },
}

export default Blacklisted;