import { useState, useEffect } from "react";
import { getAlbBanks } from "./getAlbBanks";
import { getUbpBanks } from "./getUbpBanks";

export function useBanks(railType) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response =
          railType === "UBP" ? await getUbpBanks() : await getAlbBanks();
        setData(response);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    if (railType) {
      fetchData();
    }
  }, [railType]);

  return { data, isLoading, error };
}
