import React from 'react'
import {Corporate, PI, Bill, Truck, Ecom, Kyc , Business, Fee} from '../../static/icons/index'
import MobileMain from '../../layouts/Mobile/MobileMain'
import faqStyles from '../../static/css/Faq';
import FaqMain from './faq_main';
import FaqTopic from './faq_topics';
import {Grid} from '@material-ui/core'
import bux from "../../static/icons/bux_logo.svg";
import {history } from '../../store/history'
import { Layout, Button} from "antd";
import eonLogo  from '../../static/images/eon_text_image.svg';
import { backToTop } from '../constants/constants'


const { Header } = Layout;


const FaqMobile = props => {

    const faq = faqStyles();


    const [hide, setHide] = React.useState(false)
    const [show, setShow] = React.useState(true)
    const [showCorporate, setShowCorporate] = React.useState(true)
    const [showProfile, setShowProfile] = React.useState(true)
    const [showPayment, setshowPayment] = React.useState(true)
    const [showLogistic, setShowLogistic] = React.useState(true)
    const [showEcommerce, setShowEcommerce] = React.useState(true)
    const [showKyc, setShowKyc] = React.useState(true)
    const [showBusiness, setShowBusiness] = React.useState(true)
    const [showFees, setShowFees] = React.useState(true)
    const [showEon, setShowEon] = React.useState(true)

    function showTopic(value){
        backToTop();
        let topics = value.toLowerCase().split(' ')[0]
        if(topics == 'enterprise'){
            setShowCorporate(false)
            setShowProfile(true)
            setshowPayment(true)
            setShowLogistic(true)
            setShowEcommerce(true)
            setShowKyc(true)
            setShowBusiness(true)
            setShowFees(true)
            setHide(true)
            setShow(false)
            setShowEon(true)
            window.location.href = `#${topics}`
        }
        if(topics == 'profile'){
            setShowCorporate(true)
            setShowProfile(false)
            setshowPayment(true)
            setShowLogistic(true)
            setShowEcommerce(true)
            setShowKyc(true)
            setShowBusiness(true)
            setShowFees(true)
            setShowEon(true)
            setHide(true)
            setShow(false)
            window.location.href = `#${topics}`
        }

        else if(topics == 'payments'){
            setShowCorporate(true)
            setShowProfile(true)
            setshowPayment(false)
            setShowLogistic(true)
            setShowEcommerce(true)
            setShowKyc(true)
            setShowBusiness(true)
            setShowFees(true)
            setShowEon(true)
            setHide(true)
            setShow(false)
            window.location.href = `#${topics}`
        }

        else if(topics == 'logistics'){
            setShowCorporate(true)
            setShowProfile(true)
            setshowPayment(true)
            setShowLogistic(false)
            setShowEcommerce(true)
            setShowKyc(true)
            setShowBusiness(true)
            setShowFees(true)
            setShowEon(true)
            setHide(true)
            setShow(false)
            window.location.href = `#${topics}`
        }
        else if(topics == 'ecommerce'){
            setShowCorporate(true)
            setShowProfile(true)
            setshowPayment(true)
            setShowLogistic(true)
            setShowEcommerce(false)
            setShowKyc(true)
            setShowBusiness(true)
            setShowFees(true)
            setShowEon(true)
            setHide(true)
            setShow(false)
            window.location.href = `#${topics}`
        }

        else if(topics == 'know'){
            setShowCorporate(true)
            setShowProfile(true)
            setshowPayment(true)
            setShowLogistic(true)
            setShowEcommerce(true)
            setShowKyc(false)
            setShowBusiness(true)
            setShowFees(true)
            setShowEon(true)
            setHide(true)
            setShow(false)
            window.location.href = `#kyc`
        }

        else if(topics == 'business'){
            setShowCorporate(true)
            setShowProfile(true)
            setshowPayment(true)
            setShowLogistic(true)
            setShowEcommerce(true)
            setShowKyc(true)
            setShowBusiness(false)
            setShowFees(true)
            setShowEon(true)
            setHide(true)
            setShow(false)
            window.location.href = `#${topics}`
        }


        else if(topics == 'fees'){
            setShowCorporate(true)
            setShowProfile(true)
            setshowPayment(true)
            setShowLogistic(true)
            setShowEcommerce(true)
            setShowKyc(true)
            setShowBusiness(true)
            setShowFees(false)
            setShowEon(true)
            setHide(true)
            setShow(false)
            window.location.href = `#${topics}`
        }

        else if(topics == 'eon'){
            setShowCorporate(true)
            setShowProfile(true)
            setshowPayment(true)
            setShowLogistic(true)
            setShowEcommerce(true)
            setShowKyc(true)
            setShowBusiness(true)
            setShowFees(true)
            setHide(true)
            setShow(false)
            setShowEon(false)
            window.location.href = `#${topics}`
        }

    }
    
    function faqTopics() {
        setHide(true)
        setShow(false)
    }

    function setBack(){
        setHide(false)
        setShow(true)  
        window.location.href = '#home'

    }

    const grid = [
        {
            icon: Corporate,
            title: 'Enterprise Account',
            id: 'enterprise'
        },
        {
            icon: PI,
            title: 'Profile Information'
        },
        {
            icon: Bill,
            title: 'Payments'
        },
        // {
        //     icon: Truck,
        //     title: 'Logistics'
        // },
        {
            icon: Ecom,
            title: 'Ecommerce Solutions'
        },

        {
            icon: Kyc,
            title: 'Know Your Customer',
        },

        {
            icon: Business,
            title: 'Business'
        },
        {
            icon: Fee,
            title: 'Fees'
        },
        {
            icon: eonLogo,
            title: 'EON',
            id: 'eon'
        }
    ];

    return(
        <div>     
            {
            localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null ?
            <div>
            <Header
            style={{
            height: "60px",
            width: '100%',
            zIndex: '100',
            position: 'fixed',
            display: "flex",
            padding: '0px',
            justifyContent: "space-between",
            backgroundColor: "#ffffff",
            boxShadow: "0px 3px 5px rgba(57,63,72,0.1)",
            
            }}
        >
            <img
            src={bux}
            style={{ marginBottom: "20px", marginTop: "12px", marginLeft: '20px' }}
            alt="#" />
            
            {

            localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null ?

            <Button type="primary" className={faq.signUpNow} onClick={ () => window.location.href = "/login"}>Login</Button>

            :

            <Button type="primary" className={faq.signUpNow} onClick={ () => window.location.href = "/dashboard"}>Dashboard</Button>
            }
    

        </Header>
        
        <div className={faq.mobileMain} align='left'>
            <div hidden={hide}>
                <FaqMain />

                <div>
                    <div className={faq.header}>
                        <p>Topics</p>
                    </div>
                    <div align='center'>
                        <Grid container> 
                            
                            {
                                grid.map((item) => {
                                    return(
                                        <Grid lg={12} xs={12} className={faq.mobileDiv}>
                                        <button className={faq.cardButton} onClick={() => showTopic(item['title'])}>
                                            <img className={faq.cImage} style={{width: item['title'] == 'EON' ? 64 : 32 }} src={item['icon']} />
                                            <p className={faq.cText}>{item['title']}</p>
                                        </button>
                                    </Grid>
                                    )
                                })
                            }
                                    
                        </Grid>
                    </div>
                </div>
            </div>

            <div hidden={show}>
                <FaqTopic setBack={setBack} 
                    showCorporate={showCorporate}
                    showProfile={showProfile} showPayment={showPayment} 
                    showLogistic={showLogistic} showEcommerce={showEcommerce} 
                    showKyc={showKyc} showBusiness={showBusiness} showFees={showFees} showEon={showEon}/>
            </div>
            </div>
            </div>

            :

            <MobileMain title={"FAQ"}>
        
            <div className={faq.mobileMain} align='left'>
                <div hidden={hide}>
                    <FaqMain />
    
                    <div>
                        <div className={faq.header}>
                            <p>Topics</p>
                        </div>
                        <div align='center'>
                            <Grid container> 
                                
                                {
                                    grid.map((item) => {
                                        return(
                                            <Grid lg={12} xs={12} className={faq.mobileDiv} >
                                            <button className={faq.cardButton} onClick={() => showTopic(item['title'])}>
                                                <img className={faq.cImage} style={{width: item['title'] == 'EON' ? 64 : 32 }} src={item['icon']} />
                                                <p className={faq.cText}>{item['title']}</p>
                                            </button>
                                        </Grid>
                                        )
                                    })
                                }
                                        
                            </Grid>
                        </div>
                    </div>
                </div>
    
                <div hidden={show}>
                    <FaqTopic setBack={setBack} 
                    showCorporate={showCorporate}
                    showProfile={showProfile} 
                    showPayment={showPayment} showLogistic={showLogistic} 
                    showEcommerce={showEcommerce} showKyc={showKyc} 
                    showBusiness={showBusiness} showFees={showFees}
                    showEon={showEon}
                    />
                </div>
                </div>
                </MobileMain>
            }
        </div>
    )
}


export default FaqMobile