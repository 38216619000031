import React, {useEffect, useRef} from 'react';
import { Grid,TextField } from "@material-ui/core";
import {Alert, Input, Spin, Modal} from 'antd'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Visibility from '@material-ui/icons/Visibility';
import accountStyle from '../../../static/css/Account';
import profile from "../../../static/icons/profile.svg";
import ChangePassword from './changePassword';
import settingServices from '../settingServices';


const max_width = 2000;
const max_height = 2000;

const MyProfile = props => {

    async function getProfPic(){
        let res = await settingServices.getProfilePic();
        setProfilePicURL(res.profile_picture)
        if(res.profile_picture){
            localStorage.setItem("profilePicture",res.profile_picture)
        }   

    }

    async function profileDetails(){
        let res = await settingServices.getProfileDetails();
        if(res && res.first_name){
            setFullname(res.first_name+ " "+ res.last_name)
            setEmailAddress(res.email)
            setMobileNumber(res.contact)
            setUID(res.bux_id)
        }
    }

    const naIfBlank = (val) => val || 'n/a';

    async function fetchDetails(){
        setLoading(false);
        let res = await settingServices.getKycDetails();

        if(res){
            //set my info
            setBirthDate(naIfBlank(res.birthday))
            setBirthPlace(naIfBlank(res.birthplace))
            setNationality(naIfBlank(res.nationality))

            //set contact info

            let a = res.present_address;
            setPresentStreet(a.street)
            setPresentBarangay(a.barangay)
            setPresentCity(a.city)
            setPresentProvince(a.province)
            setPresentPostCode(a.postalCode)
        } 

        setLoading(true);
    }


    useEffect(() => {
        if(localStorage.getItem("jwtToken") !== "" && localStorage.getItem("jwtToken") !== null){
            fetchDetails()
            getProfPic()
            profileDetails()
        }
    }, [])

    const acct = accountStyle();

    //profile pic
    const [profilePic, setProfilePic] = React.useState("")
    const [profilePicURL, setProfilePicURL] = React.useState("")
    let profilePicUpload = React.useRef()
    const [loading, setLoading] = React.useState(false)

    //my information
    const [uid, setUID] = React.useState("")
    const [fullName, setFullname] = React.useState("")
    const [birthdate, setBirthDate] = React.useState("")
    const [birthplace, setBirthPlace] = React.useState("")
    const [nationality, setNationality] = React.useState("")
    const [emailAddress, setEmailAddress] = React.useState("")
    const [mobileNumber, setMobileNumber] = React.useState("")
    const [presentStreet, setPresentStreet] = React.useState("")
    const [presentBarangay, setPresentBarangay] = React.useState("")
    const [presentCity, setPresentCity] = React.useState("")
    const [presentProvince, setPresentProvince] = React.useState("")
    const [presentPostCode, setPresentPostCode] = React.useState("")
    //change password

    const [message, setMessage] = React.useState("");
    const [showModal, setShowModal] = React.useState(false)

    function handleUploadImage(info, type) {
        // read the files
        var reader = new FileReader();
        reader.readAsArrayBuffer(info);
        
        reader.onload = function (event) {
          // blob stuff
          var blob = new Blob([event.target.result]); // create blob...
          window.URL = window.URL || window.webkitURL;
          var blobURL = window.URL.createObjectURL(blob); // and get it's URL
          
          if(type == "profilePicID"){
              setProfilePic(info)
              setProfilePicURL(blobURL)
          }
          // helper Image object
          var image = new Image();
          image.src = blobURL;
          image.onload = function() {
            // have to wait till it's loaded
            var resized = resizeMe(image); // resized image url
            uploadProfilePic(resized)
          }
        };
    }

    function resizeMe(img) {
      
        var canvas = document.createElement('canvas');
  
        var width = img.width;
        var height = img.height;
  
        // calculate the width and height, constraining the proportions
        if (width > height) {
          if (width > max_width) {
            //height *= max_width / width;
            height = Math.round(height *= max_width / width);
            width = max_width;
          }
        } else {
          if (height > max_height) {
            //width *= max_height / height;
            width = Math.round(width *= max_height / height);
            height = max_height;
          }
        }
        
        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        
        return canvas.toDataURL("image/jpeg",0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)
        
        // you can get BLOB too by using canvas.toBlob(blob => {});
  
      }

    function dataURItoBlob(dataUri) {
        var byteString = atob(dataUri.split(',')[1]);
        var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], {type: mimeString});
        return blob
    }
  

    async function uploadProfilePic(dataUri){
        var blob = dataURItoBlob(dataUri);

        const formData = new FormData();

        formData.append('profile_picture', blob, 'id_image.png')

        let res = await settingServices.uploadProfilePic(formData);

        let res2 = await fetch(res)
        if(res2.ok){
            window.location.reload()
            
        }

        else{
            message.error("ERROR" + res.status, 3)
        }
        setProfilePic(res.url);
    }


    async function changeMobile(){

        let res = await settingServices.changeMobile();
            
        if(res.status == "success"){
            setShowModal(true)
        }
    }



    const myInfo = [
        {
            'placeholder': 'BUx Merchant ID:',
            'value': uid
        },
        {
            'placeholder': 'Name:',
            'value': fullName
        },
        {
            'placeholder': 'Birthdate:',
            'value': birthdate
        },
        {
            'placeholder': 'Birthplace:',
            'value': birthplace
        },
        {
            'placeholder': 'Nationality:',
            'value': nationality
        }
    ]

    const contactInfo = [
        {
            'placeholder': 'Email:',
            'value': emailAddress,
            'btn':  <a style={{color: '#f5922f', opacity: 0.5, cursor: 'not-allowed'}} disabled={true}>Change</a>

        },
        {
            'placeholder': 'Contact Number:',
            'value': mobileNumber,
            'btn':  <a onClick={() => changeMobile()} 
                        style={{color: '#f5922f',opacity: mobileNumber ? '' : 0.5, cursor: mobileNumber ? 'pointer' : 'not-allowed' }} 
                        disabled={mobileNumber == ''}>Change</a>
                     
        },

    ]

    const address = [
        {
            'placeholder': 'Present Address:',
            'value': `${presentStreet? presentStreet + ', ' + presentBarangay + ', ' + presentCity + ', ' + presentProvince + ' ' + presentPostCode : 'n/a'}`
        }
    ]

    return(
        <div className={acct.personal}>
            <Modal
                width={360}
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={null}>
                    <div>
                        <div className={acct.changeModalHeader}>
                            Change Mobile Number
                        </div>

                        <div className={acct.changeModalText}>
                        A temporary link will be sent to your registered email address 
                        and you may use it to update your mobile number.
                        </div>

                        <button className={acct.changeModalBtn} onClick={() => setShowModal(false)}>
                            Got It
                        </button>
                    </div>

            </Modal>
            <div className={acct.profilePicDiv}>
                <div className={acct.headerProfile}>Profile Picture</div>

                <div style={{display: 'flex', marginTop: '16px'}}>
                    <div style={{marginRight: '8px'}} id="profile-pic-preview">
                        {profilePicURL ? <img src={profilePicURL} alt="avatar" className={acct.profilePics}/> : <img alt="avatar" src={profile}/>}
                    </div>
                    <div id="profile-pic-btn" style={{marginTop: '16px'}}>
                        <input type="file"
                            ref={profilePicUpload}
                            style={{display: 'none'}}
                            onChange={(e)=>handleUploadImage(e.target.files[0], "profilePicID")}
                            accept=".jpg,.png,.jpeg"
                            >
                        </input>

                        <button className={acct.profilePicBtn} onClick={()=>profilePicUpload.current.click()}>Update Profile Picture</button>
                    </div>
                </div>
            </div>

            <div className={acct.infoDiv}>
                <div className={acct.headerProfile}>My Information</div>
                
                <div className={acct.personalInfo} align='left'>
                
                {
                    myInfo.map((item, index) => {
                        return(
                            <Grid container key={index} style={{border: '1px solid #E6EAF0'}}>
                                <Grid item lg={6} xs={6}>
                                    <div className={acct.piHeader}>
                                        {item.placeholder}
                                    </div> 
                                </Grid>
                                <Grid item lg={6} xs={6}>
                                   
                                    <div className={acct.piText}>
                                        <div hidden={loading}>
                                        <Spin size="small" />
                                        </div>
                                        <div  hidden={!loading}>
                                        {item.value ? item.value: 'N/A'}
                                        </div>
                                    </div> 
                                </Grid>
                            </Grid>
                        )
                    })
                }
                </div>
            </div>

            <div className={acct.infoDiv}>
                <div className={acct.headerProfile}>Contact Information</div>
                <div className={acct.personalInfo} align='left'>
                
                {
                    contactInfo.map((item, index) => {
                        return(
                            <Grid container key={index} style={{border: '1px solid #E6EAF0'}}>
                                <Grid item lg={4} xs={6}>
                                    <div className={acct.piHeader}>
                                        {item.placeholder}
                                    </div> 
                                </Grid>
                                <Grid item lg={index ===0 ? 8 : 4} xs={6}>
                                    <div className={acct.piText}>
                                        <div hidden={loading}>
                                        <Spin size="small" />
                                        </div>
                                        <div  hidden={!loading}>

                                        {item.value ? item.value: 'N/A'}
                                        </div>

                                    </div> 
                                </Grid>
                                <Grid item lg={index === 0 ? false : 4} xs={12}>
                                    <div className={acct.password} hidden={index === 0}>
                                        {item.btn}
                                    </div>
                                </Grid>
                            </Grid>
                        )
                    })
                }

                <ChangePassword />

                </div>
            </div>
                        
            <div className={acct.infoDiv}>
                <div className={acct.headerProfile}>Address</div>
                
                <div className={acct.personalInfo} align='left'>
                
                {
                    address.map((item, index) => {
                        return(
                            <Grid container key={index}>
                                <Grid item lg={6} xs={12}>
                                    <div className={acct.piHeader1}>
                                        {item.placeholder}
                                    </div> 
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <div className={acct.piText}>
                                        {item.value ? item.value: <Spin size="small"/>}
                                    </div> 
                                </Grid>
                            </Grid>
                        )
                    })
                }
                </div>
            </div>
        </div>
    )
}

export default MyProfile