import React from "react";
import { DatePicker, Button } from "antd";
import moment from "moment";

export function DateRangeFilter({
  tempStartDate,
  tempEndDate,
  handleStartDateChange,
  handleEndDateChange,
  handleApplyDateFilter,
}) {
  return (
    <div
      style={{
        marginBottom: "16px",
        display: "flex",
        gap: "1em",
        alignItems: "center",
      }}
    >
      <DatePicker
        value={tempStartDate}
        onChange={handleStartDateChange}
        placeholder="Start Date"
        disabledDate={(current) => current && current > moment().endOf("day")}
      />
      <DatePicker
        value={tempEndDate}
        onChange={handleEndDateChange}
        placeholder="End Date"
        disabledDate={(current) => current && current > moment().endOf("day")}
      />
      {handleApplyDateFilter && (
        <Button onClick={handleApplyDateFilter} htmlType="button">
          Apply
        </Button>
      )}
    </div>
  );
}
