
export const icon_style = {
    height: '32px',
    width: '111.72px',
    marginTop: '56px',
    marginBottom: '32px',
}
export const infoStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#2B2D33',
    textAlign: "center",
    marginBottom: '10px'
}
export const byNickStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '20px',
    color: '#54575F',
    textAlign: "center",
}
export const dontMissStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '26px',
    color: '#2B2D33',
}

export const eventStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#2B2D33',
}
export const timeStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#54575f',
}