import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Card, List, Badge, Button, Typography, Spin, Icon } from "antd";
import { Alert } from '@material-ui/lab/'
import MetaTag from '../meta_tag/meta_tag';

//Redux
import notificationReducers from "./notificationReducers";
import notificationServices from './notificationServices';
import notificationActions from './notificationActions';

//Toots
import { isMobile } from 'react-device-detect';
import { history } from '../../store/history';
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination';

//Icons
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
  NotifPaymentRequest,
  NotifPayout,
  NotifMultiwallet,
  NotifRecon,
  NotifPaymentRequestSched,
  NotifAddMoney,
  NotifSystemMaintenance,
  NotifKYBKYC,
  NotifPromotion,
  NotifRefund,
  NotifNewStore,
  NotifNewOTC,
  NotifNewEWallet,
  NotifNewCCDC,
  NotifNewBank,
  NotifUpgradeAccount
 } from '../../static/icons/notification_icons';
import share_sms from '../../static/icons/share_sms.png';
import dropdown_icon from '../../static/icons/dropdown_icon.svg';

const renderIcon = (type) => {
  switch (type) {
    case 'Payout':
      return NotifPayout;
    case 'Card':
      return NotifNewCCDC;
    case 'Payment':
      return NotifPaymentRequest;
    case 'Payment Schedule':
      return NotifPaymentRequestSched;
    case 'KYC':
      return NotifKYBKYC;
    case 'KYB':
      return NotifKYBKYC;
    case 'New Bank Channel':
      return NotifNewBank; 
    case 'Promo':
      return NotifPromotion;   
    case 'Conversion':
      return NotifUpgradeAccount;  
    default:
      return NotifSystemMaintenance
  }
}

const returnLink = (type, status, others) => {
  const link = type + ' ' + status + ' ' + localStorage.getItem('Step');
  if(type === 'Payout') {
    return '/funds';
  } 
  else if (type === 'Payment') {
      if(others) {
        return `/payment/${others}`
      }
      
      return '/orders';
  } 
  else if (type === 'KYB') {
      return '/upgrade_enterprise'
  } 
  else if(type === "GCash") {
    return '/announcement'
  }
    
  else {
        switch (link) {
          case 'KYC Approved 10':
          case 'KYC Rejected 9':  
            return '/complete_profile'
          case 'KYC Approved 10':
          case 'KYC Rejected 14':  
          case 'KYC Rejected 19':
            return '/dashboard'
          default:
            return '/dashboard';
    }
  }

}

const RenderNotifications = ({data, selectedTab, currentPage, setCurrentPage, pageSummary, getData}) => {

  const readNotif = async (id) => {
    try {
      const response = await notificationServices.readNotification(id);

      getData({target: 'all', type: selectedTab, page_number: currentPage})
      getData({target: 'preview'})
      getData({target: 'count'})

    } catch (error) {
      return(
        <Alert severity="error">{error}</Alert>
      )
    }
  }

  const handleClick = (id) => {
    readNotif(id)
  }

  return (
    <Card style={{ width: isMobile ? '100%' : '644px' }} bodyStyle={{ padding: 0 }}>
      <List
        loadMore={
          
            data && data.length > 0 &&
            <div style={{padding: '20px 32px 20px 32px', display:'flex', justifyContent:'space-between'}}>
                <div>
                    {(currentPage - 1) * 10  + 1} 
                    - 
                    {(currentPage - 1) * 10  + data.length} out of {pageSummary.total_filtered}
                </div>
                <div style={{display: 'flex'}}>
                    <Pagination 
                        count={pageSummary.pages} 
                        shape="rounded"
                        page={currentPage} 
                        size="small"
                        onChange={(event, page)=>{
                            setCurrentPage(page);
                            getData({target: 'all', type: selectedTab, page_number: page})
                        }}
                    />
                </div>
            </div>
        
        }
        dataSource={data}
        renderItem={item =>
          <List.Item style={{ padding: 16, backgroundColor: !item.is_read && ' #FAFAFA'}}>
            <a style={styles.link} href onClick={() => (handleClick(item.id), history.push(returnLink(item.category, item.status, item.others)))}>
              <Badge dot={item.is_read == false} style={styles.badge}>
                <img src={renderIcon(item.category)} />
              </Badge>
              <div style={styles.textContent}>
                <label style={{ color: '#000000', fontSize: 16 }}>{item.category} {item.status}</label>
                <Typography style={{ padding: '4px 0px' }}>
                  {item.activity}
                </Typography>
                <label style={styles.timelabel}>{moment(item.created_at).startOf('minutes').fromNow()}</label>
              </div>
              <ArrowForwardIosIcon style={{ fontSize: 14, color: '#2B2D33' }} />
            </a>
          </List.Item>
        }
      />
    </Card>
  )
}

const Notification = () => {
  const dispatch = useDispatch();
  const ButtonGroup = Button.Group;

  //Reducers
  const notificationData = useSelector(state => state.notification.notifData);
  const notficationCount = useSelector(state => state.notification.notificationCount);
  const prevDataRef = useRef();

  //States
  const [inialized, setInitialized] = useState(false);
  const [showLimit, setShowLimit] = useState(10);
  const [selectedTab, setSelectedTab] = useState("All")

  const segments = localStorage.getItem("segments")

  //Pagination
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageSummary, setPageSummary] = React.useState({total: 0, total_filter: 0, pages: 1})

  //functions  
  const getData = async (params) => {
    try {
     const response = await notificationServices.getNotifications(params);

     console.log(response)
     if(params.target == 'all') {
        dispatch(notificationActions.setNotificationData(response.notifications))
        setPageSummary({total: response.total, total_filtered: response.total_filtered, pages: response.pages})
     }
     else if (params.target == 'preview'){
        dispatch(notificationActions.setNotificationHeaderData(response.notifications))
     } 
     else if (params.target == 'count') {
        dispatch(notificationActions.setNotificationCount(response))
     }
    } catch (error) {
       return(
         <Alert severity="error">{error}</Alert>
       )
    }
  }

  if (!inialized) {
  
    getData({target: 'all', type: selectedTab, page_number: currentPage});
    getData({target: 'count'});

    const current_path = window.location.pathname
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
        history.push('/login?next=' + current_path)
    } else {
      if (isMobile) {
        history.push('/mobile/notifications');
      } else {
        history.push('/notifications');
      }
    }
    setInitialized(true);
  }

  const markAllRead = async() => {
    const response = await notificationServices.getNotifications({target: 'read_all'})

    if(response){
      dispatch(notificationActions.setNotificationData(response))
      getData({target: 'preview'})
      getData({target: 'count'})
    }

  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const segmentsColor = {
    'me_':{
        color: '#0DAED0',
        border: '2px solid #0DAED0'
    },
    'gig':{
        color: '#41BD5C',
        border: '2px solid #41BD5C'
    },
    'sme':{
        color: '#E3632A',
        border: '2px solid #E3632A'
    },
    'biz':{
        color: '#0051B0',
        border: '2px solid #0051B0'
    }
  }

  const tabs = ["All", "Unread"]

  return (
    <div style={styles.root}>
      <MetaTag title="Notification" />
      <div style={styles.container}>
        <div style={styles.titleText}>Notification</div>
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
          <ButtonGroup>
              {
                tabs.map((btn, i) => {
                    return (
                        <Button
                            key={btn}
                            value={btn}
                            onClick={()=>{
                              setSelectedTab(btn);
                              getData({target: 'all', type: btn, page_number: currentPage})
                            }}
                            className={btn === selectedTab ? `bg-${segments}` : `'`}
                            style={{
                                ...styles.btnGroup,
                                color: btn === selectedTab ? '#FFF' : '#2B2D33',
                                backgroundColor: '#FFF',
                                borderRadius: i === 0 ? '4px 0 0 4px' : tabs && i === tabs.length - 1 ? '0 4px 4px 0' : 0
                            }}
                        >
                            {btn} ({i == 0 ? pageSummary.total : notficationCount})
                        </Button>
                    )
                })
              }
          </ButtonGroup>
          <button onClick={()=>markAllRead()} className={`outline-btn--${segments}`}>Mark all as read</button>
        </div>
        
        {
          notificationData && notificationData.length !== 0 ? 
            <RenderNotifications 
              data={notificationData.slice(0, showLimit)} 
              selectedTab={selectedTab}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSummary={pageSummary}
              getData={getData} 
            /> : 
          notificationData && notificationData.length=== 0? 
          <Typography style={styles.noData}>No Data</Typography> : 
          <Spin size="large" />
        }

        <div style={{display: 'flex', justifyContent: 'center'}}>
          <button
            onClick={()=>scrollToTop()}
            style={{...styles.upBtn, display: (notificationData.length == 0 || !notificationData) && 'none' }} 
            className={`outline-btn--${segments}`}>
              <Icon type="arrow-up" style={{color: segmentsColor[segments].color}}/> Back to top
          </button>
        </div>
      </div>
    </div>
  )
}

const styles = {
  root: {
    display:'flex',
    justifyContent: 'center',
    padding: isMobile ? '40px 10px 20px 10px' : '40px 0px 20px 0px',
    backgroundColor: isMobile && '#FFF',
  },
  container:{
    width: isMobile ? '100%' : '645px',
  },
  title: {
    width: isMobile ? '100%' : 540,
  },
  titleText: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#000000',
    padding: isMobile ? '16px 0' : '32px 0px'
  },
  btn: {
    margin: '32px 0px 0px 0px',
    height: 38,
    width: isMobile ? '100%' : 250,
    borderRadius: 10,
    fontSize: 16,
    fontWeight: '500',
    borderColor: '#F5922F',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%'
  },
  badge: {
    padding: 5,
    margin: '2px 5px',
    backgroundColor: '#E24C4C'
  },
  textContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '0 12px 0 6px',
    width: '80%'
  },
  timelabel: {
    color: '#909196',
    fontSize: 12,
    padding: '4px 0px 0px 0px'
  },
  noData: {
    fontSize: 24,
    fontWeight: '600'
  },
  btnGroup: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '0 16px',
  },
  applyBtn: {
    zIndex: 1, 
    height: 40,
    fontSize: 14,
    fontWeight: '500',
    borderRadius: 4,
    width: 72,
    cursor: 'pointer',
  },
  upBtn: {
    height: '40px',
    width: '139px',
    marginTop: '20px'
  }
}

export default Notification;
