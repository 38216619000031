export const sourceOfFunds = [
  "Employment Income",
  "Investments",
  "Business Income",
  "Gift or Inheritance",
  "Loans",
  "Savings",
  "Sale of Assets",
  "Government Benefits",
  "Other",
];
