import React,{ useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Card, Dropdown, Menu, Typography, Input, Select, Spin, Affix } from 'antd';
import { history } from '../../../store/history';
import cardActions from '../cardActions';
import cardServices from '../cardServices';
import { isMobile } from 'react-device-detect';
import { PaybillsTelecoms, PaybillsElectricity, PaybillsCable, PaybillsWater, PaybillsEllipsisRound } from '../../../static/icons/dashboard_icons';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { getInputClassName } from 'antd/lib/input/Input';
import ConfirmModal from '../modals/confirmPaybillModal';
import ConfirmPaybillsModal from '../modals/confirmPaybillModal';
import { useLocation } from 'react-router';
import NoEon from '../noEon';
import CancelIcon from '@material-ui/icons/Cancel';
import OtpCard from '../otp/otpCard';

const fieldStyle = {
  inputContainer: {
    display: 'flex', 
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between', 
    padding: '10px 0',
  },
  inputWidth: {
    width: isMobile ? '100%' : '48%'
  },
  btn: {
    width: '100%', 
    height: 40,
    fontSize: 16
  }
}

const returnProvider = (key) => {
  switch (key) {
    case 'Electricity':
      return 'Select Electric Company';
    case 'Telecoms':
      return 'Select Telecom Company';
    case 'Cable':
      return 'Select Cable Provider';
    case 'Water':
      return 'Select Water Provider';
    case 'Credit Cards':
      return 'Select Credit Card'; 
    case 'Schools':
      return 'Select School/University'; 
    case 'Insurance':
      return 'Select Insurance Company';
    case 'Others':
      return 'Payment For';
    default:
      return 'Payment For'
  }
}

const Fields = ({btn, billers}) => {
  const [selectedBiller, setSelectedBiller ] = useState(btn);
  const [selectedProvider, setSelectedProvider ] = useState('Select');
  const [key, setKey] = useState(null);
  let inputFields = selectedProvider === 'Select' ? null : billers[btn][selectedProvider];
  const [params, setParams] = useState([]);
  const [fieldName, setFieldName] = useState([]);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [paybillsError, setPaybillsError] = useState(false);
  const resetFields = useSelector(state => state.card.clearFields);
  const visiblePaybillsModal = useSelector(state => state.card['confirm_paybills']);
  const otpSuccess = useSelector(state => state.card.otpSuccess);
  const otpUID = useSelector(state => state.card.otpUID);
  const dispatch = useDispatch();
  const segments = localStorage.getItem('segments');

  if(resetFields) {
    setParams([]);
    setSelectedBiller(btn);
    setSelectedProvider('Select');
    dispatch(cardActions.clearFields(false));
  }
  
  const handleInputChange = (event) => {
    if(event.target.name === 'amount') {
      setParams({...params, [event.target.name]: autoFormat(event.target.value)})
    } else {
      setParams({...params, [event.target.name]:event.target.value})
    }
    
  }

  const closeConfirmModal = () => {
    dispatch(cardActions.hideModal('confirm_paybills'));
  }

  const handleProvideChange = (value) => {
    setSelectedProvider(value);
    pushFields(value)
    setParams([]);
  }

  const pushFields = (value) => {
    if(billers[selectedBiller][value]['Field 1'].length !== 0) {
      setParams({...params, 'Field 1':''})
    }
    if(billers[selectedBiller][value]['Field 2'].length !== 0) {
      setParams({...params, 'Field 2':''})
    }
    if(billers[selectedBiller][value]['Field 3'].length !== 0) {
      setParams({...params, 'Field 3':''})
    }
  }

  const autoFormat = (value) => {
    const formattedValue = value.replace(/\D/g, '')
    if (value !== null) {
      return formattedValue;
    } else {
      return value;
    }
  }

  const selectBiller = () => {
    let menu = []
      for(var i in billers){
        if (btn === 'utilities' && i === 'Electricity' || btn === 'utilities' && i === 'Cable' || btn === 'utilities' && i === 'Water' || btn === 'utilities' && i === 'Telecoms') {
          menu.push(<Select.Option value={i}>{i}</Select.Option>);
        }
        if (btn === 'credit' && i === 'Credit Cards' || btn === 'credit' && i === 'Loans') {
          menu.push(<Select.Option value={i}>{i}</Select.Option>);
        }
        if (btn === 'others' && i === 'Others' || btn === 'others' && i === 'Schools' || btn === 'others' && i === 'Insurance') {
          menu.push(<Select.Option value={i}>{i}</Select.Option>);
        }
        if (selectedBiller === 'Select' && resetFields) {
          menu = []
        }
      }
      return menu
  }

  const selectProvider = () => {
    let menu = []
    for(var i in billers[selectedBiller]){
      menu.push(<Select.Option value={i}>{ billers[selectedBiller][i]['Name']}</Select.Option>);
    }
    return menu
  }

  if(paybillsError) {
    setTimeout(() => {
      setPaybillsError(false);
      dispatch(cardActions.hideModal('attempt_error'));
    }, 3000);
  }

  const returnInput = (fields) => {
    return (
      <div>
      {
        paybillsError ?
        <Affix offsetTop={20} style={{position: 'fixed', zIndex: '100', top: '10%', left: '42%'}}>
          <div style={styles.notif}>
            <CancelIcon
              style={{ 
                margin: '0 10px 0 0'
              }} 
            />
            <div>
              <div align='left'>{'Error Try Again'}</div>
            </div>
          </div>
        </Affix>
        : null
      }
        <ConfirmPaybillsModal visible={visiblePaybillsModal} inputs={[params, inputFields, selectedProvider, selectedBiller]} closeModal={closeConfirmModal} />
        <div style={fieldStyle.inputContainer}>
          <div style={fieldStyle.inputWidth}>
            <label style={styles.labelFont}>{returnProvider(selectedBiller)}</label>
            <Select
              style={fieldStyle.btn}
              value={selectedProvider}
              onChange={handleProvideChange}
              disabled={selectedBiller==='Select'}
              placeholder="Select"
              size="large"
              disabled={isBtnLoading}
            >
              {selectProvider()}
            </Select>
          </div>
           {/* { fields && fields['Field 1'] !== 'Amount' || fields && fields['Field 2'] !== 'Amount' || fields && fields['Field 3'] !== 'Amount' ?  */}
          { fields ? 
              <div style={{...fieldStyle.inputWidth, position: 'relative'}}>
                <label style={styles.labelFont}>
                  Amount
                </label>
                <Typography 
                  style={{ 
                    position: 'absolute', 
                    top: '66%', 
                    left: '12%', 
                    transform: 'translate(-50%, -50%)',
                    fontSize: 16, 
                    color: '#2B2D33',
                    zIndex: '100' }}
                >
                  PHP
                </Typography>
                <Input name='amount' disabled={isBtnLoading} value={params['amount']} style={{...fieldStyle.btn, padding: '0 0 0 20%'}} onChange={handleInputChange}>
                </Input>
              </div>
          : null }
        </div>
        { fields && fields['Field 1'] !== '' ?
          <div style={fieldStyle.inputContainer}>  
            <div style={{...fieldStyle.inputWidth, margin: selectedProvider === 'MERALCO' ? '-21px 0 0 0': null}}>
              <label style={styles.labelFont}>
                {fields ? fields['Field 1'] : null}
              </label>
              <Input size='large' name='Field 1' disabled={isBtnLoading} value={params['Field 1']} style={fieldStyle.btn} onChange={handleInputChange}></Input>
            </div>
            {fields && fields['Field 2'] !== '' ?
              <div style={fieldStyle.inputWidth} >
                <label style={styles.labelFont}>
                  {fields ? fields['Field 2'] : null}
                </label>
                <Input name='Field 2' disabled={isBtnLoading} value={params['Field 2']} style={fieldStyle.btn } onChange={handleInputChange}></Input>
              </div>
              : null }
          </div>
          : null }
        <div>
        { fields && fields['Field 3'] !== '' ?
            <div style={fieldStyle.inputWidth}>
              <label style={styles.labelFont}>
                {fields ? fields['Field 3'] : null}
              </label>
              <Input name='Field 3' disabled={isBtnLoading} value={params['Field 3']} style={fieldStyle.btn} onChange={handleInputChange}></Input>
            </div>
            : null}
        </div>
      </div>
    )
  }

 

  const validatePayment = async () => {
    const par = {
      'biller': selectedProvider,
      'amount': params['amount'] ? parseFloat(params['amount']) : '',
      'input1': params['Field 1'] ? params['Field 1'] : '',
      'input2': params['Field 2'] ? params['Field 2'] : '',
      'input3': params['Field 3'] ? params['Field 3'] : "" ,
    }
    try {
      setIsBtnLoading(true);
      const response = await cardServices.validatePaybills(par);
      if (response.valid === true) {
        dispatch(cardActions.showModal('confirm_paybills'))
        setIsBtnLoading(false);
      } else {
        setPaybillsError(true)
        setIsBtnLoading(false);
      }
    } catch (error) {
      console.log(error)
    }
  }

  if(otpSuccess) {
    validatePayment();
    dispatch(cardActions.setOtpSuccess(false));
  }

  const returnOtpSubj = () => {
    const path = window.location.pathname
    console.log(path)
    switch (path) {
      case '/physical_card_upgrade':
      case '/mobile/physical_card_upgrade':
        return 'replace_card';
      case '/cards/bills_payment':
      case '/mobile/bills_payment':
        return 'bills_pay';
      case '/request_cvv':
      case '/mobile/request_cvv':
        return 'cvv';
      default:
        return 'payout';
    }
  }

  const fetchOTP = async () => {
    setIsBtnLoading(true);
    try {
      const response = await cardServices.getOTP();
      if (response.status === 'success') {
        dispatch(cardActions.setOtpParams({mobile:response.mobile, uid:response.uid, subj: returnOtpSubj() }));
        dispatch(cardActions.showModal('otp_mobile'))
        dispatch(cardActions.resetOTPTimer(true))
      } else {
        if(response.code==='attempts'){
          dispatch(cardActions.setAttempMsg(response.unblock));
          dispatch(cardActions.showModal('attempt_error'));
          dispatch(cardActions.showModal('otp_mobile'));
        } else {
          setIsBtnLoading(false)
          dispatch(cardActions.hideModal('otp_mobile'))
        }
      }
    } catch (error) {
      console.log(error)
      dispatch(cardActions.hideModal('otp_mobile'))
      setIsBtnLoading(false)
    }
  }

  const handleBillerChange = (value) => {
    setKey(value);
    setSelectedBiller(value);
    setSelectedProvider('Select')
    setParams([]);
  }

  const returnBlankFields = () => {
    let hasBlank = true;
   
    if (params['Field 1'] && params['Field 2'] == null && params['Field 3'] == null) {
      if(params['amount'] && params['amount'].length !== 0 && params['Field 1'].length !== 0) {
        hasBlank = false;
      } else hasBlank = true;
    }
    if(params['Field 1'] && params['Field 2'] && params['Field 3'] == null) {
      if(params['amount'] && params['amount'].length !== 0 && params['Field 1'].length !== 0 && params['Field 2'].length !== 0) {
        hasBlank = false;
     } else hasBlank = true; 
    }

    if (params['Field 1'] && params['Field 2'] && params['Field 3']) {
      if(params['amount'] && params['amount'].length !== 0 && params['Field 1'].length !== 0 && params['Field 2'].length !== 0 && params['Field 3'].length !== 0 ) {
        hasBlank = false;
      } else hasBlank = true;
    }

      return hasBlank;
    }

  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
      background: '#FFFFFF',
      position: 'fixed',
      width: '100%',
      zIndex: 100,
      marginTop: '-1px'
    },
    indicator: {
        backgroundColor: '#F5922F',
        height: 4,
    },
    select: {
      height: 48
    }
  });

  const classes = useStyles();

  useEffect(() => {
    // setKey(btn);
    setSelectedBiller(btn);
    setSelectedProvider('Select')
    setParams([]);
  },[btn])

  return (
    <div>
       {/* <div style={fieldStyle.inputContainer}>
        <div style={{width: '100%'}}>
          <label style={styles.labelFont}>Payment for</label>
          <Select
            style={fieldStyle.btn}
            value={selectedBiller}
            disabled={isBtnLoading}
            onChange={handleBillerChange}
            placeholder="Select"
            size="large"
          >
            {selectBiller()}
          </Select>
        </div>
      </div> */}
      {returnInput(inputFields)}
      <button 
        style={{
          ...styles.btnContinue, 
          opacity: returnBlankFields() ? '50%' : '100%'   
        }} 
        className={`btn--${segments}`}
        disabled={ returnBlankFields() || isBtnLoading} 
        onClick={fetchOTP}>{ isBtnLoading ? <Spin size='small'/> : 'Continue'}</button>
    </div>
  )
} 

const BillsPayment = () => {
  const dispatch = useDispatch();
  const selectedPaybills = useSelector(state => state.card.selectedPaybills);
  const [billers, setBillers] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const location = useLocation();
  const noEon = location.state ? location.state.no_eon : null;
  const attemptError = useSelector(state => state.card['attempt_error']);
  const visibleOTP = useSelector(state => state.card['otp_mobile']);
  const segments = localStorage.getItem('segments');

  const getBillers = async () => {
    try {
      const response = await cardServices.getBillerPaybills();
      setBillers(response);
      checkNoEon();
    } catch (error) {
      console.log(error);
    }
  }

  if (!initialized) {

    const current_path = window.location.pathname
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
        history.push('/login?next=' + current_path)
    } else {
      if (isMobile) {
        history.push('/mobile/bills_payment');
        getBillers();
        setInitialized(true);
      } else {
        getBillers();
        setInitialized(true);
      }
    }
  }

  const handleDropdownClick = ({key}) => {
    dispatch(cardActions.setSelectedPaybills(key));
    dispatch(cardActions.clearFields(true));
  }

  const setBorderColor = (key) => {
    switch (key) {
      case 'Telecoms':
        return '#78E08F';
      case 'Electricity':   
        return '#FD9644';
      case 'Cable':   
        return  '#4A69BD';
      case 'Water':   
        return '#45AAF2';
      default: 
        return '#FFCF90';
    }
  }

  const setIcon = (key) => {
    switch (key) {
      case 'Telecoms':
        return PaybillsTelecoms;
      case 'Electricity':   
        return PaybillsElectricity;
      case 'Cable':   
        return  PaybillsCable;
      case 'Water':   
        return PaybillsWater;
      case 'Others':   
        return PaybillsEllipsisRound;
      default: 
        return PaybillsTelecoms;
    }
  }

  const menu = (
    <Menu onClick={handleDropdownClick}>
      <Menu.Item key="Telecoms">
        Telecoms
      </Menu.Item>
      <Menu.Item key="Electricity">
        Electricity
      </Menu.Item>
      <Menu.Item key="Cable">
        Cable
      </Menu.Item>
      <Menu.Item key="Water">
        Water
      </Menu.Item>
      <Menu.Item key="Others">
        Others
      </Menu.Item>
    </Menu>
  );

  const getCardInfo = async () => {
    try {
      const response = await cardServices.getCardInfo();
      if(localStorage.getItem('Level') >= 0 && !response.customer_id) {
          history.push('/mobile/dashboard')
        }
    } catch (error) {
      console.log(error);
    }
  }

  const checkNoEon = () => {
    if(isMobile) {
      getCardInfo();
    } else {
      if(!noEon || noEon === 'none') {
        history.push('/dashboard')
      }
    }
  }

  return (
    <div style={styles.root}>
      <div style={styles.title}>
        <button className={`btn--${segments}`} style={styles.btnBack} hidden={isMobile}
          onClick={() => isMobile ? (history.go(-3), dispatch(cardActions.hideModal('otp_mobile'))) : 
                          (history.goBack(), dispatch(cardActions.hideModal('otp_mobile')))}>{<Icon className={`link-text--${segments}`} type="left" />} Back</button>
        <strong style={{...styles.titleFont, padding: isMobile ? '10px 0px' : visibleOTP? '8px 64px' : '8px 118px', textAlign: isMobile? 'left' :'center' }}>Bills Payment</strong>
      </div>
      <div hidden={visibleOTP} style={styles.cardContainer}>
        <Card 
          style={{...styles.card, borderTop: `4px solid ${setBorderColor(selectedPaybills)}`}} 
          bodyStyle={{borderTop: `3px solid ${setBorderColor(selectedPaybills)}`}}
        >
          <div style={styles.cardTitle}>
            <img alt='No Image' style={{height: selectedPaybills === 'Others' && 48}} src={setIcon(selectedPaybills)} />
            <Dropdown overlay={menu} trigger='click'>
              <a style={styles.dropdownItems} >
                {
                  selectedPaybills == 'telecom' ? selectedPaybills.charAt(0).toUpperCase() + selectedPaybills.substring(1) + 's' :
                  selectedPaybills.charAt(0).toUpperCase() + selectedPaybills.substring(1)
                }
                <ExpandMoreIcon style={styles.dropdownArror} />
              </a>
            </Dropdown>
          </div>
          <Fields btn={selectedPaybills} billers={billers} />
        </Card>
      </div>
      <div hidden={!visibleOTP} style={styles.cardContainer}>
        <OtpCard />
      </div>
    </div>
  )
}

const styles = {
  root: {
    width: '100%',
    display: isMobile? 'block' : 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F4F6F9',
    height: isMobile? '100vh' : '100%',
  },
  title: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    width: isMobile ? '100%' : 540, 
    padding: isMobile ? '8px 20px' : '50px 0px 44px 0px',
  },
  btnBack: {
    border: 0, 
    borderRadius: 40, 
    fontSize: 12,
    width: 102
  },  
  titleFont: {
    fontSize: 28,
    color: '#000000',
    width: '100%',
    margin: isMobile ? '0 42px 0 0' : 0, 
    textAlign: isMobile ? 'center' : 'left',
  },
  labelFont: {
    fontSize: 12,
    color: '#212B36'
  },
  btnContinue: {
    margin: '16px 0px 0px 0px',
    height: 40,
    width: '100%',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
  },
  card: {
    borderRadius: 10,
  },
  cardContainer : {
    padding: isMobile ? '0px 20px 20px 20px' : 0, 
    width: isMobile ? '100%' : 540,
  },
  cardTitle: {
    display: 'flex', 
    justifyContent: 'start', 
    alignItems: 'center', 
    padding: ' 0 0 10px 0'
  },
  dropdownItems: {
    display: 'flex', 
    alignItems: 'center', 
    padding: '0 16px', 
    fontWeight: '300', 
    fontSize: 24, 
    color: '#2B2D33'
  },
  dropdownArror: {
    color: '#2B2D33', 
    fontSize: 32, 
    margin: '0 4px'
  },
  notif: {
    backgroundColor: '#FFFFFF', 
    fontSize: 14, 
    fontWeight: 'bold', 
    width: '100%', 
    color: '#E24C4C', 
    borderRadius: 4, 
    padding: '8px 16px', 
    textAlign: 'center',
    display: 'flex',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.24)',
    alignItems: 'center',
  },
}

export default BillsPayment;