import React, {useEffect, useCallback} from 'react'
import {InputNumber, Input, Select, Button, Alert, Card, message, Checkbox, Divider, Typography} from 'antd'
import {BANKS, BANKS_INSTAPAY, BANKS_PESONET} from '../../constants/constants'
import {history} from '../../../store/history'
import { useSelector } from 'react-redux';
import axios from 'axios'
import MetaTag from '../../meta_tag/meta_tag'
import UpgradeAccount from '../../../static/img/upgrade_account.svg'
import PayoutMaintenance from '../../../static/img/payout_maintenance.svg'
import OTPInput, { ResendOTP } from "otp-input-react";
import Payoutips from '../../../static/icons/payout.svg'
import PayoutModal from '../../payout_drawer/payout_modal'


const MobilePayout = props => {

    const [mode, setMode] = React.useState("")
    const [accountName, setAccountName] = React.useState("")
    const [accountNumber, setAccountNumber] = React.useState("")
    const [amount, setAmount] = React.useState("")
    const [bank, setBank] = React.useState("")
    const [alert, showAlert] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState("")
    const [onProcess, setOnProcess] = React.useState(false)
    const [OTP, setOTP] = React.useState("")
    const [OtpDisabled, setOtpDisabled] = React.useState(true)
    const [OtpUid, setOtpUid] = React.useState("")
    const [OtpFinished, setOtpFinished] = React.useState(false)
    const [counter, setCounter] = React.useState(0);
    const [bankDetails, setBankDetails] = React.useState([])
    const [saveBank, setSaveBank] = React.useState(false)
    const [isPrimary, setIsPrimary] = React.useState(false)
    const [bankId, setBankId] = React.useState(0)
    const [fullName, setFullName] = React.useState("")
    const [startTimer, setStartTimer] = React.useState(false)
    const [edmOTP, setEdmOTP] = React.useState(false)
    const [mobile, setMobile] = React.useState("")
    const [openModal, setOpenModal] = React.useState(false)
    let intervalId = null
    const [otpLoading, setOtpLoading] = React.useState(false);
    const [initialized, setInitialized] = React.useState(false);
    const [otpMax, setOtpMax] = React.useState(false);
    const endpoints = useSelector(state => state.child_endpoints.endpoints);
    const isChild = localStorage.getItem("is_child") === "true"
  

    if(!initialized){
      getBankAccount();
      setInitialized(true);
    }

    useEffect(() => {
      let fname = localStorage.getItem("firstName")
      let lname = localStorage.getItem("lastName")
      setFullName(fname+" "+lname)
      // getBankAccount()

      intervalId = setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
      
      if (counter == 0){
        clearInterval(intervalId)
      }
      return () => clearInterval(intervalId);  

    }, [counter]);
  
    const calculateDuration = (duration) => {
        const minutes = Math.floor(duration/60).toString();
        const seconds = Math.floor(duration%60);
        let seconds_str = seconds.toString();

        if(seconds < 10){
            seconds_str = "0" +seconds.toString();
        }
  
        return minutes + ":" + seconds_str
    };


    const { Option } = Select;

    const titleStyle = {
        fontStyle: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        fontWeight: '600',
        fill: 'Solid',
        color: '#000',
        marginBottom: showAlert ? '20px' : '40px'
      }
    
    const subHeaderStyle = {
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight: '600',
        fill: 'Solid',
        color: '#000',
      }

    const generateBtn = {
        color: '#fff',
        background: '#1d82b8',
    
      }
    
    const generateBtn1 = {
        color: '#fff',
        background: '#1d82b8',
        opacity: '0.5'      
      }

    const descSyle = {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '24px',
      color: '#2B2D33'
    }

    const spielOtp = {
      marginTop: 20,
      color: '#2b2d33',
      align: 'center'
    }

    const otpHeader = {
      fontWeight: '300',
      fontSize: '28px',
      lineHeight: '32px',
  }

  const inputDiv = {
    marginTop: 47,
  }
  
   const sDesc = {
      marginTop: '10px',
      fontSize: '14px',
      lineHeight: '24px',
      opacity: '0.8',
      letterSpacing: '-0.01em'
  }

    const otpSubmitBtn = {
    border: '1px solid #F5922F',
    borderRadius: '10px',
    color: '#fff',
    background: '#F5922F',
    width: '100%',
    height: 48,
}

    const otpDisableBtn = {
    border: '1px solid #F5922F',
    borderRadius: '10px',
    color: '#fff',
    background: '#F5922F',
    opacity: '0.5',
    width: '100%',
    height: 48,
}

  const otpEmail = {
    color: '#F5922F',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
  }

  const otpResendBtn = {
    cursor: 'pointer',
    height: 36,
    border: '1px solid #F5922F',
    borderRadius: '4px',
    background: '#fff',
    color: '#F5922F',
    fontWeight: 'bold',
    width: 220,
  }


  const otpResendBtnDisabled = {
    opacity: '0.5',
    cursor: 'not-allowed',
    height: 36,
    border: '1px solid #F5922F',
    borderRadius: '4px',
    background: '#fff',
    color: '#F5922F',
    fontWeight: 'bold',
    width: 220,
  }

  const resendBtn = {
    height: 48,
    border: '1px solid #F5922F'
  }

  const linkBank = {
    marginTop: 16,
    marginBottom: 16,
    width: '100%',
    height: '48px',
    background: '#fff',
    color: '#F5922F',
    fontWeight: 'bold',
    fontSize: '16px',
    border: '1px solid #f5922f',
    borderRadius: '10px',
    cursor: 'pointer',
  }

  const accountDetails = {
    padding: '8px 0px 8px 8px',
    marginTop: 16,
    background: '#fff',
    border: '2px solid #E6EAF0',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
  }

  const primaryStyle = {
    padding: '8px 0px 8px 0px',
    textAlign: 'center',
    background: '#0D3D76',
    borderRadius: ' 20px 0px 0px 20px',
    color: '#fff',
    fontSize: '10px',
    lineHeight: '10px',
    fontWeight: 'bold',
    width: '72px',
    height: '23px',
  }

  const backBtn = {
    color: "#1d82b8",
    fontWeight: '600',
    fontSize: '17px',
    lineHeight: '22px',
    textTranform: 'uppercase'
  }

  const newTextStyle = {
  color: '#212B36',
  fontSize: '12px',
  lineHeight: '24px',
  marginBottom: '4px',
  }   

  const tipsDiv = {
    display: 'flex',
    marginTop: 32,
    borderTop: '1px solid #D1D5DD',
    paddingTop: 20,
    marginBottom: 64
  }

  const emailSent = {
    background: '#1DD28B',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.24)',
    borderRadius: '4px',
    color: '#fff',
    fontWeight: 600,
    fontSize: '16px',
    padding: 12,
    textAlign: 'center'
  }

  const prefix = {
    position: 'absolute',
    top: `56%`,
    left: '10%',
    transform: 'translate(-50%, -50%)',
    fontSize: 16,
    color: '#2B2D33',
    fontWeight: 'normal',
    zIndex: '2',
  }

  const isSelectedBank = () => {
    if(bank === 'GCash (G-Xchange Inc.)' || bank === 'Paymaya' || bank === 'DCPay Philippines, Inc.(Coins.ph)' && bank) {
        return false;
    }
    return true;
  }

    async function addBank(){
      setOnProcess(true)
      setBankId(0)
      let params = {
        bank,
        account_name: accountName,
        account_number: isSelectedBank()? accountNumber: '0'+accountNumber,
      }
      
            
      let yourConfig = {
        headers: {
          Authorization: "Token " + localStorage.getItem("jwtToken")
        }
      }

      let url = process.env.REACT_APP_API_URL + "/api/bank_accounts/"
      
      try {
        let response = await axios.post(url,params, yourConfig);
        if(response.status == 200){
          setBankId(response.data.id)
          showAlert(false)
          getBankAccount();
          fetchOTP()
        }
      } catch (error) {
        // message.warning(response.status, 5)
        showAlert(true)
        setAlertMessage(error.response.data);
        setOnProcess(false)
      }
    }

    async function getBankAccount(){
      let yourConfig = {
          headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
          }
      }

      let url = process.env.REACT_APP_API_URL + "/api/bank_accounts/"

          
      let res = await fetch(url, yourConfig);

      if (res.ok) { // if HTTP-status is 200-299
      // get the response body (the method explained below)
      let json = await res.json();
        setBankDetails(json)

      } else {
      message.error("HTTP-Error: " + res.status, 5);
      }


    }

    async function submit(payout_uid){
        setOnProcess(true)
        let params = {
            bank_account_id: bankId,
            amount: amount,
            account_name: accountName.replace(/[^\w\s]/gi, ''),
            account_number: isSelectedBank()? accountNumber : bankDetails.length > 0 && !saveBank? accountNumber : '0'+accountNumber,
            mode: mode,
            bank: bank,
            payout_uid: payout_uid,
        }
        let yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        if(amount <= 0){
          setAlertMessage("Amount must be Php 1.00 and up")
          
        }
        else if (accountName.length > 30) {
            showAlert(true)
            setAlertMessage('Account name must be 30 characters or below')
        }
        else{
          let res = await axios.post(process.env.REACT_APP_API_URL + '/api/payouts/create/',params, yourConfig)
          if(res.data.status == "success"){
              setOtpUid('')
              setOpenModal(true)
              setOnProcess(false)
            }
          else{
            setAlertMessage(res.data.message)
            showAlert(true)
            setOnProcess(false)
            setOtpUid('')
            clearFields();
          }
        }
        
    }

    const clearFields = () => {
      setAccountName('');
      setAccountNumber('')
      setBank('');
      setAmount('')
      setEdmOTP(false);
      setOtpDisabled(true);
    }

    async function fetchEmailOTP () {
      setOtpLoading(true);
      let yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
      }

      try{
        let response = await axios.get(
            process.env.REACT_APP_API_URL + "/api/email_otp/?subject=payout",
            yourConfig
        );
        console.log(response);
        if(response.data.status == "success"){
          clearInterval(intervalId)
          setCounter(120)
          setEdmOTP(true)
          setOtpLoading(false);
          setOtpMax(false);

        }else if(response.data.code == "mobile"){
          setOtpFinished(true)
          setEdmOTP(true)
          setOtpLoading(false);
        }
    }
    catch(error){
        // if((error.response)&&(error.response.status == 401)){
        //     Handle401()
        // }
    }
    }

    async function fetchOTP () {
      setOnProcess(true)
      let yourConfig = {
          headers: {
              Authorization: "Token " + localStorage.getItem("jwtToken")
          }
      }
      try{
          let response = await axios.get(
              process.env.REACT_APP_API_URL + "/api/otp/",
              yourConfig
          );
          console.log(response);
          if(response.data.status == "success"){
            setOtpUid(response.data.uid)
            setMobile(response.data.mobile)
            showAlert(false)
            setCounter(30)
            setStartTimer(true)
          } 

          else if(response.data.code == "attempts"){
            setAlertMessage(<div>{response.data.message}<div>Please try again on {response.data.unblock}</div></div>)
            showAlert(true)
            emailOTPmode();
          }

          else {
            showAlert(true)
            setAlertMessage(response.data.message)
          }
      }
      catch(error){
          // if((error.response)&&(error.response.status == 401)){
          //     Handle401()
          // }
      }
  }

  const emailOTPmode = () =>{
    setOtpMax(true);
    setOtpUid(true);
    setCounter(0);
    setTimeout(() => {
      showAlert(false);
    }, 3000);
  }


    async function submitOTP()  {
      let params
      let url
      if(edmOTP){
        url = process.env.REACT_APP_API_URL + "/api/email_otp/"
        params = {
          code: OTP,
        }
      }
      else {
        url = process.env.REACT_APP_API_URL + "/api/otp/"
        params = {
          uid : OtpUid,
          code: OTP,
          otp_type: 1
        }

      }
    
      setOtpLoading(true);  
    
      let yourConfig = {
          headers: {
              Authorization: "Token " + localStorage.getItem("jwtToken")
          }
      }
    
      try{
          let response = await axios.post(url, params, yourConfig
          );
          console.log(response);
          if(response.data.status == 'success'){
            setOtpFinished(true);
            submit(response.data.trans_uid);
            setOtpLoading(false);
            setOTP('');
          }
          else if(response.data.code == "attempts"){
            setAlertMessage(<div>{response.data.message}<div>Please try again on {response.data.unblock}</div></div>)
            showAlert(true)
            setOtpLoading(false);
            setOTP('');
          }

          else {
            showAlert(true)
            setAlertMessage(response.data.message)
            setOtpLoading(false);
            setOTP('');
          }
    
          setOnProcess(false)
    
    
      }
      catch(error){
          // if((error.response)&&(error.response.status == 401)){
          //     Handle401()
          // }
          setOTP('');
          setOtpLoading(false);
          showAlert(true)
          setAlertMessage(error.response.data)
      }
      
    }

    function setModes(value){

        if(value > 50000 && bank != "UnionBank of the Philippines"){
            setMode("PesoNet")
        } else if(value <= 50000 && bank != "UnionBank of the Philippines"){
            setMode("Instapay")
        }
        else {
          setMode("UnionBank")
        }
        setAmount(value)
     
    }

    function onBankChange(value){

    
        if(amount <= 50000 && (bankDetails.length == 0 ? value : value[0]) != "UnionBank of the Philippines"){
          
          setMode("Instapay")
    
          if(BANKS_INSTAPAY.includes((bankDetails.length == 0 ? value : value[0]))){
            setMode("Instapay")
          }
    
          else if (BANKS_PESONET.includes((bankDetails.length == 0 ? value : value[0]))){
            setMode("PesoNet")
            
          }
    
          else {
            setMode("UnionBank")
          }
        }
        
        else {
          if(BANKS_PESONET.includes((bankDetails.length == 0 ? value : value[0]))){
            setMode("PesoNet")
          }
    
          else{
            setMode("UnionBank")
    
          }
        }
        setBank((bankDetails.length == 0 ? value : value[0]))
        setAccountName((bankDetails.length == 0 ? '' : value[1]))
        setAccountNumber((bankDetails.length == 0 ? '' : value[2]))
        setIsPrimary((bankDetails.length == 0 ? '' : value[3]))
        setBankId((bankDetails.length == 0 ? '' : value[4]))
    }
    function renderPesonet(){
        return(
          <div>
            <ul style={{ width: '100%', marginLeft: '-20px', marginBottom: '20px' }}>
              <li style={{ fontWeight: '600' }}>PesoNet</li>
              <li>Transactions before the 3PM cut-off are processed same banking day</li>
              <li>Transactions after cut-off or holidays are processed the next banking day.</li>
            </ul>
         </div>
        )
    }

    function renderInstapay(){    
        return(
          <div>
            <ul style={{ width: '100%', marginLeft: '-20px', marginBottom: '20px' }}>
              <li style={{ fontWeight: '600' }}>Instapay</li>
              <li>Select partner banks</li>
              <li>Transaction limit is ₱50,000</li>
            </ul>
          </div>
        )
    }

    function renderUnionBank(){
        return(
          <div>
            <ul style={{ width: '100%', marginLeft: '-20px', marginBottom: '20px' }}>
              <li style={{ fontWeight: '600' }}>UnionBank of the Philippines</li>
            </ul>
          </div>
        )
    }

    function renderNone(){    
        return(
          <div>
            <ul style={{ width: '100%', marginLeft: '-20px', marginBottom: '20px' }}>
              <li style={{ fontWeight: '600' }}>Bank is unavailable</li>
            </ul>
          </div>
        )
    }

    function onOTPChange(val) {
      if (val.length == 4) {
        setOtpDisabled(false);
      } else if (val.length < 4) {
        setOtpDisabled(true);
      }
      setOTP(val)
    }

    function onSaveBank(val) {
      setSaveBank(val)
    }

    function resendCode(){
      setCounter(30)
      fetchOTP()
    }

    const banks = bankDetails.length == 0 ? BANKS : bankDetails;
    banks.sort();

    let bank_option = banks.length > 0 && banks.map((item, i) => {
      
      return (
        bankDetails.length != 0 ? 

        <Option key={i} value={[item.bank, item.account_name, item.account_number, item.is_primary, item.id]}>{item.bank}</Option>
        :
        <Option key={i} value={item}>{item}</Option>
      )
    }, this)



    React.useEffect(() => {
      if (!isChild || !endpoints.length) {
        return;
      }
  
      // Redirect if URL was manually typed
      if(!endpoints.includes('funds_enterprise')) {
        history.push(endpoints[0]);
      }
    }, [endpoints])
  
  
    // If endpoints have not been loaded yet for mobile view. Hook on endpoints will handle redirect
    if (isChild && !endpoints.includes('funds_enterprise')) {
      return null;
    }


    return(
        <div style={{padding: '30px 20px 10px 20px', height: '100%'}}>
            <MetaTag title="Payout"/>

            <div hidden={OtpUid}>
            <div style={titleStyle}>Single Payout</div>

            <PayoutModal openModal={openModal} primary={isPrimary} bank={bank} accountName={accountName} 
              accountNumber={accountNumber} amount={amount} onClose={clearFields}/>

            <Card style={{marginBottom: '20px', display: localStorage.getItem("Level") == "0" || localStorage.getItem("Level") == null ? 'block' : 'none'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
                    <img src={UpgradeAccount} alt="#" style={{marginRight: '20px'}}/>
                    <div>
                        <div className={subHeaderStyle}>
                        Complete your Profile
                        </div>
                        <div className={descSyle}>
                          To increase your limits, upgrade your account by completing your profile for FREE. Complete now.
                        </div>
                    </div>
                </div>
                <Button block size="large" style={{border: '2px solid #1D82B8', color: '#1D82B8'}} onClick={()=>history.push('/complete_profile')}>Proceed</Button>
            </Card>

            <Card style={{marginBottom: '20px', display: localStorage.getItem("Level") == "1" && localStorage.getItem("payout_enabled") == 0 ? 'block' : 'none'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
                    <img src={PayoutMaintenance} alt="#" style={{marginRight: '20px'}}/>
                    <div>
                        <div className={subHeaderStyle}>
                        Payout Temporarily Disabled
                        </div>
                        <div className={descSyle}>
                        Instapay and Pesonet is currently under maintenance. It will only take a while. Thank you for your patience.
                        </div>
                    </div>
                </div>
            </Card>

            <Alert message={alertMessage} type="error" showIcon style={{ display: alert ? "block" : "none", borderLeft: '4px solid red', marginBottom: '10px' }}/>
            
            <div style={newTextStyle}><span>Amount(P)</span></div>
            <InputNumber size="large" style={{ width: '100%', marginBottom: '20px' }} onChange={(ev)=>setModes(ev)} value={amount}/>

            <div style={{ marginBottom: '10px' }}> <span style={subHeaderStyle}>Choose Destination Bank Account</span> </div>
            <div style={{ marginBottom: '10px' }}> <span style={newTextStyle}>Destination</span> </div>

            <Select
                showSearch
                onChange={(ev)=>onBankChange(ev)}
                placeholder="Choose a bank"
                style={{ width: '100%', marginBottom: '10px' }}
                size="large"
                value={bank? bank : 'Choose a bank'}
            >
                {bank_option}

            </Select>
            
            {

                mode === "Instapay" ?
                
                renderInstapay()

                :

                mode === "PesoNet" ?

                renderPesonet()

                :

                mode === "UnionBank" ?

                renderUnionBank()

                :

                renderNone()

            }

            <div hidden={bankDetails.length != 0}>
            <div style={newTextStyle}><span>Account name</span></div>
            <Input value={accountName} onChange={(accountName) => setAccountName(accountName.target.value)} size="large" style={{ width: '100%', marginBottom: '20px' }} />
            <div style={{position: 'relative'}}>
              <div style={newTextStyle}><span>{isSelectedBank()?'Account number':'Mobile number'}</span></div>
              <Input type="number" value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} size="large" style={{ width: '100%', marginBottom: '20px', paddingLeft: isSelectedBank()? '' : 60 }} />
              {isSelectedBank()? null : <Typography style={prefix} >+63 | </Typography>}
            </div>
            <div style={{marginBottom: 32}}>
                <Checkbox checked={saveBank} onChange={(e) => onSaveBank(e.target.checked)}/> <span style={{fontSize: '16px', color: 'rgba(43, 45, 51, 0.8)', paddingLeft: 8}}>Save Bank Account</span>
            </div>
            </div>

            <div hidden={bankDetails.length == 3 || bankDetails.length == 0}>
              <div align="center" style={subHeaderStyle}>OR</div>
              <div>
                <button style={linkBank} onClick={() => {window.open('/link_bank')}}>
                  Link New Bank Account
                </button>
              </div>
            </div>

            <div style={{marginBottom: '10px'}} hidden={accountName == "" || bankDetails.length == 0}>

            <div style={subHeaderStyle}>Account Details</div>

            <div style={accountDetails}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div><b>{accountName}</b></div>
                <div style={primaryStyle} hidden={!isPrimary}>Primary</div>
              </div>
              <div style={{color: '#2B2D33', fontSize: '16px'}}>
                Account No: {accountNumber}
              </div>
            </div>

            </div>

            <Button 
                style={
                  mode == "" || 
                  amount == "" ||
                  accountName == "" || 
                  accountNumber == "" ||
                  bank == "" ||
                  localStorage.getItem('Level') == 0 || 
                  localStorage.getItem('Level') == null || 
                  onProcess ? otpDisableBtn : otpSubmitBtn} 

                onClick={() => saveBank ? addBank() : fetchOTP()} 
                size="large" 
                type="primary" 
                block 
                disabled={!mode || 
                    !amount ||
                    !accountName || 
                    !accountNumber || 
                    !bank ||
                    onProcess || 
                    localStorage.getItem('Level') == 0 || 
                    localStorage.getItem('Level') == null}
                loading={onProcess}
                > Send </Button>


                <div style={tipsDiv}>
                    <div>
                      <img src={Payoutips} />
                    </div>
                    <div>
                      <div style={subHeaderStyle}>QUICK TIP</div>
                      <div style={{color: '#909196', marginTop: 4,}}>
                        Customize your Linked Bank Accounts through your <a href="/account_settings#bank_account" style={{color: '#F5922F', fontWeight: 600}}>Settings</a>.
                      </div>

                    </div>
                 </div>

              </div>
        
        
            
            <div hidden={!OtpUid}>
            <div style={titleStyle}>Payout</div>
                <div style={spielOtp} align='center'>
                  <div style={otpHeader}>One-time password</div>
                  <div style={emailSent} hidden={true}>OTP PIN has been sent to your email</div>

                  <div style={sDesc}>
                    To proceed, a 6-digit code will be sent via 
                    {
                      edmOTP ? 
                      'email on your registered email address' :
                      <div>SMS on your registered number ending in <b>{mobile.substr(mobile.length - 4)}</b>.</div>
                    }
                  </div>
                </div>

                <Alert message={alertMessage} type="error" showIcon style={{ display: alert ? "block" : "none", borderLeft: '4px solid red', marginTop: '20px', marginBottom: '20px' }}/>

                <div style={inputDiv}>
                <OTPInput
                  value={OTP}
                  onChange={(e) => onOTPChange(e)}
                  autoFocus
                  style={{width: '100%', justifyContent: 'center'}}
                  OTPLength={6}
                  otpType="number"
                  inputStyles={{
                    height: 70,
                    width: 34,
                    fontSize: "32px",
                    color: "#2b2d33",
                    fontWeight: "600",
                    background: "#E6EAF0",
                    boxSizing: "border-box",
                    border: "1px solid #d1d5dd",
                    borderRadius: "4px",
                    margin: '0 10px'
                  }}
                  disabled={otpLoading || otpMax}
                />
              </div>

              <div style={{marginTop: 30}} align="center" hidden={edmOTP}>

              <div style={{marginTop: '16px'}}>
                  <button style={counter > 0 || edmOTP || otpMax ? otpResendBtnDisabled : otpResendBtn}
                    onClick={() =>  resendCode() } 
                    disabled={counter > 0 || otpLoading || otpMax }>Resend Code</button>
              </div>

              <div style={{marginTop: '16px'}} hidden={counter <= 0}>
                  <b>"Resend Code"</b> will be enabled after <b>{ calculateDuration(counter) }</b>
              </div>
            </div>


              <div style={inputDiv}>
                <Button
                  style={OtpDisabled ? otpDisableBtn : otpSubmitBtn}
                  disabled={OtpDisabled}
                  onClick={() => submitOTP()}
                  loading={otpLoading}
                >
                  Submit
                </Button>
              </div>

              <div style={{marginTop: 32}} hidden={edmOTP}>
                    <Divider>
                        OR
                    </Divider>
              </div>

              <div style={{marginTop: '16px'}} align='center' hidden={edmOTP}>
                <a style={otpEmail} onClick={() => fetchEmailOTP()}>Send OTP Code via Email</a>
              </div>
            </div>
        </div>
    )
}

export default MobilePayout
