import React from 'react';
import MobileOrders from './mobile_orders';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core'
import DebitCreditRequest from './debit_credit/debit_credit';
import { isMobile } from 'react-device-detect';
import { history } from '../../../store/history';
import { useSelector } from 'react-redux';

const PaymentRequestMobile = () => {
  const [tab, setTab] = React.useState(0);
  const userType = localStorage.getItem('userType');
  const ubp_enabled = localStorage.getItem('cc_enabled') == 'UnionBank';
  const xendit_enabled = localStorage.getItem('cc_enabled') == 'Xendit';
  const CCEnabled = ubp_enabled || xendit_enabled;

  
  const endpoints = useSelector(state => state.child_endpoints.endpoints);
  const isChild = localStorage.getItem("is_child") === "true"


  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
      background: '#FFFFFF',
      position: 'fixed',
      width: '100%',
      zIndex: 100,
      marginTop: '-1px'
    },
    indicator: {
        backgroundColor: '#F5922F',
        height: 4,
    },
  });
  
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setTab(newValue);
    if(newValue === 0) {

    }
  }

  const redirectPage = () => {
    let current_path = window.location.pathname
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
      history.push('/login?next='+current_path)
    }
    else{
      if(!isMobile){
        history.push('/orders')
      }
    }
  }

  React.useEffect(() => {
    redirectPage();
  },[])


  React.useEffect(() => {
    if (!isChild || !endpoints.length) {
      return;
    }

    // Redirect if URL was manually typed
    if(isMobile && !endpoints.includes('orders')) {
      history.push(endpoints[0]);
    }
  }, [endpoints])


  // If endpoints have not been loaded yet for mobile view. Hook on endpoints will handle redirect
  if (isChild && isMobile && !endpoints.includes('orders')) {
    return null;
  }

  return (
    userType === 'CO' && CCEnabled ?
    <div>
      <div className={classes.root}>
        <Tabs 
          variant="fullWidth"
          centered
          textColor={'inherit'}
          classes={{indicator: classes.indicator}}
          onChange={handleChange}
          value={tab}
          aria-label="icon label tabs example"
          >
          <Tab label="All Transactions" style={{color: tab == 0 ? '#F5922F' : '#909196', textTransform: 'capitalize', fontWeight: 'bold', padding: '12px 0px 16px 0px'}}/>
          <Tab label="Credit / Debit Card" style={{color: tab == 1 ? '#F5922F' : '#909196', textTransform: 'capitalize', fontWeight: 'bold', padding: '12px 0px 16px 0px'}} />
        </Tabs>
      </div>
      <div>
        <div hidden={tab===1}>
          <MobileOrders />
        </div>
        <div hidden={tab===0} style={{paddingTop: 50}}>
          <DebitCreditRequest />
        </div>
      </div>
    </div>
    : <MobileOrders />
  )
}

export default PaymentRequestMobile;