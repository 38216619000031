import React, {useEffect, useState} from 'react';
import {Layout, Spin} from 'antd';
import logo from '../../static/icons/logo.svg';
import axios from 'axios';
import {history} from '../../store/history';

const ShortCheckout = props => {

    const {Header} = Layout;
    const {merchant_id, offer_id} = props.match.params;

    let query_params = new URLSearchParams(window.location.search);
    // let param_order_id = query_params.get('order_id') || '';
    let param_amount_description = query_params.get('amount_description') || '';
    let param_firstname = query_params.get('first_name') || '';
    let param_lastname = query_params.get('last_name') || '';
    let param_email = query_params.get('email') || '';
    let param_phone = query_params.get('phone') || '';

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
       processRedirect()
    }, [])


    async function processRedirect() {
        try {
            let param_amount = param_amount_description.split(" - ")[1].replace("PHP", "").replace(",", "");
            let param_desc = param_amount_description.split(" - ")[0];
            let param_name = param_firstname + " " + param_lastname;
            let response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/short/${merchant_id}/generate`
                + `?amount=${param_amount}&description=${param_desc}&name=${param_name}&email=${param_email}&phone=${param_phone}`
            );

            window.location.href = response.data.link;
            setIsLoading(false);

        } catch (error) {
            setIsError(true);
            if (error.response) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('the BUx server is unavailable');
            }
        } 

    }

    return(
        <div>
             <Header style={{zIndex: '100', position: 'fixed', top: 0, width: '100%', height: '60px', 
                             display: 'flex', justifyContent: 'center', backgroundColor: '#fff', 
                             boxShadow: '0px 3px 5px rgba(57,63,72,0.1)'}}>
                <img src={logo} style={{marginBottom: '20px', marginTop: '12px'}} alt="#" />
            </Header>

            {isLoading
                ? (
                    <div style={{margin: 0,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translateY(50%, 50%)'}}>
                        <Spin size="large" />
                    </div>
                )
                :  isError ? (
                    <div style={{
                        padding: '35vh 0px 0px 7vw'
                    }}>
                        <div style={{
                            fontWeight: '900',
                            fontSize: 'xxx-large'
                        }}>
                            Oops!
                        </div>

                        <div style={{
                            fontWeight: '750',
                            fontSize: 'x-large',
                            marginTop: '30px'
                        }}>
                            We couldn't process your order because {errorMessage}.
                        </div>

                        <div style={{
                            fontWeight: '600',
                            fontSize: 'large',
                        }}>
                            Please contact us at
                            <a style={{fontWeight: 'bold', color: '#F5922F'}} href="mailto:support@bux.ph"> support@bux.ph</a>
                        </div>
                    </div>
                ):
                ''
            }
            
        </div>
    )
}

export default ShortCheckout;