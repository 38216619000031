import React from "react";
import moment from 'moment';
import AcquisitionAnalytics from './components/acquisitionAnalytic';
import BuxTrends from './components/buxTrends';
import AnalyticsCard from './components/analyticsCard';
import DownloadAnalyticsCard from "./components/downloadAnalyticsCards";
import TopMerchantsChannels from './components/top_merchants_channels';
import TopChannels from "./components/top_channels";
import overviewServices from '../overviewServices';
import { DatePicker } from "antd";
import { itemName } from "../../../Mobile/order_details/mobile_order_style";
import { segments } from "../../../constants/constants";
import ButtonTab from '../../filter_btns';

const Overview = () => {
  const [ACQloading, setACQLoading] = React.useState(false);
  const [trendloading, setTrendLoading] = React.useState(false);
  const [QAloading, setQALoading] = React.useState(false);
  const [PAloading, setPALoading] = React.useState(false);
  const [PeAloading, setPeALoading] = React.useState(false);
  const [TopLoading, setTopLoading] = React.useState(false);
  const [analyticsData, setAnalyticsData] = React.useState(null);
  const [acquiData, setAcquiData] = React.useState(null);
  const [QAData, setQAData] = React.useState(null);
  const [PAData, setPAData]= React.useState(null);
  const [PenAData, setPenAData]= React.useState(null);
  const [TopData, setTopData] = React.useState(null)
  const [selected, setSelected] = React.useState("All")
  const [selectedSegments, setSelectedSegments] = React.useState([segments.BUx_ME, segments.BUx_GIG, segments.BUx_SME, segments.BUx_BIZ])
  
  //Setting Date, Moment, and Disabled Date
  const [startMoment, setStartMoment] = React.useState("")
  const [startDate, setStartDate] = React.useState("")

  const [endMoment, setEndMoment] = React.useState("")
  const [endDate, setEndDate] = React.useState("")

  const [endOpen, setEndOpen] = React.useState(false)

  const overAllDisabled = ACQloading || trendloading || QAloading || PAloading || PeAloading

  const disabledStartDate = startValue => {
    if (!startValue || !endMoment) {
      return false;
    }
    return startValue && startValue > moment().endOf('day');
  };

  const disabledEndDate = endValue => {
    if (!endValue || !startMoment) {
      return false;
    }
    return endValue.valueOf() <= startMoment.valueOf();
  };

  const onStartChange = value => {
    if(value != null && endDate != ""){
      setStartMoment(value)
      setStartDate(value.format("MM-DD-YYYY"))
      getAllData(value.format('MM-DD-YYYY'), endDate, selectedSegments.toString());
    }
    else if(value != null){
      setStartMoment(value)
      setStartDate(value.format("MM-DD-YYYY"))
    }
    else{
      setStartMoment(null)
      setStartDate("")
      getAllData("", "", selectedSegments.toString());
    }
  }
  const onEndChange = value => {
    if(value != null && startDate != ""){
      setEndMoment(value)
      setEndDate(value.format("MM-DD-YYYY"))
      getAllData(startDate, value.format('MM-DD-YYYY'), selectedSegments.toString());
    }
    else if(value != null){
      setEndMoment(value)
      setEndDate(value.format("MM-DD-YYYY"))
    }
    else{
      setEndMoment(null)
      setEndDate("")
      getAllData("", "", selectedSegments.toString());
    }
  }

  const handleStartOpenChange = open => {
    if (!open) {
      setEndOpen(true)
    }
  };

  const handleEndOpenChange = open => {
    setEndOpen(open)
  };

  const getAnalytics = async (start, end, bux_segment) => {
    setTrendLoading(true);
    try {
      const res = await overviewServices.getAnalytics(start, end, bux_segment);
      setAnalyticsData(res)
      setTrendLoading(false);
    } catch (error) {
      setTrendLoading(false);
      console.log(error);
    }
  }

  const getUsersAnalytics = async (start, end, bux_segment) => {
    setACQLoading(true);
    try {
      const res = await overviewServices.getUserAnalytics(start, end, bux_segment);
      setAcquiData(res);
      setACQLoading(false);
      console.log(res)

    } catch (error) {
      console.log(error);
      setACQLoading(false);
    }
  }

  // const getQualityAnalytics = async (type, start, end) => {
  //   setQALoading(true);
  //   try {
  //     const res = await overviewServices.getQualityAnalytics(type, start, end);
  //     setQAData([
  //       {
  //         name: 'Code Generated',
  //         value: res.codes_generated
  //       },
  //       {
  //         name: 'Paid Transactions',
  //         value: res.transaction_count
  //       },
  //       {
  //         name: 'Total Transaction Value',
  //         value: res.total_transaction_value.toLocaleString('en-US', { style: 'currency', currency: 'PHP' }).split('.')[0]
  //       },
  //       {
  //         name: 'Ave Transaction Value',
  //         value: res.average_transaction_value.toLocaleString('en-US', { style: 'currency', currency: 'PHP' }).split('.')[0]
  //       }
  //     ]);
  //     setQALoading(false);
  //   } catch (error) {
  //     console.log(error)
  //     setQALoading(false);
  //   }
  // }
  let codes_generated = 0;
  let transaction_count = 0;
  let total_transaction_value = 0;
  let average_transaction_value = 0;

  const getPaidAnalytics = async (start, end, bux_segment) => {
    setPALoading(true);
    setQALoading(true);
    setPeALoading(true);
    
    try {
      const res = await overviewServices.getPaidAnalytics(start, end, bux_segment);
      transaction_count = res.transaction_count; 
      total_transaction_value = res.total_transaction_value;
      average_transaction_value = res.average_transaction_value;
      setPAData([
        {
          name: 'Active Users',
          value: res.active_users
        },
        {
          name: 'Monthly Active Users',
          value: res.monthly_active_user
        },
        {
          name: 'Daily Active Users',
          value: res.daily_active_user
        },
        {
          name: 'Churn',
          value: res.churn
        }
      ]);
      setPALoading(false);
    } catch (error) {
      console.log(error)
      setPALoading(false);
    }
  // }

  // const getPendingAnalytics = async (type, start, end) => {
    try {
      const res2 = await overviewServices.getPendingAnalytics(start, end, bux_segment);
      codes_generated = res2.count;
      setPenAData([
        {
          name: 'Active Users',
          value: res2.active_users
        },
        {
          name: 'Monthly Active Users',
          value: res2.monthly_active_user
        },
        {
          name: 'Daily Active Users',
          value: res2.daily_active_user
        },
        {
          name: 'Churn',
          value: res2.churn
        }
      ]);
      setPeALoading(false);
    } catch (error) {
      console.log(error)
      setPeALoading(false);
    }

    setQAData([
        {
          name: 'Code Generated',
          value: codes_generated
        },
        {
          name: 'Paid Transactions',
          value: transaction_count
        },
        {
          name: 'Total Transaction Value',
          value: total_transaction_value.toLocaleString('en-US', { style: 'currency', currency: 'PHP' }).split('.')[0]
        },
        {
          name: 'Ave Transaction Value',
          value: average_transaction_value.toLocaleString('en-US', { style: 'currency', currency: 'PHP' }).split('.')[0]
        }
      ]);
    setQALoading(false);
  }

  const getTopMerhantsChannels = async (start, end, bux_segment) => {
    try {
      const res = await overviewServices.getTopMerhantsChannels(start, end, bux_segment);
      setTopData(res)
      setTopLoading(false)
      console.log(res)
    } catch (error) {
      console.log(error)
      setTopLoading(false)
    }
  }

  const getAllData = (start, end, bux_segment) => {
    getAnalytics(start, end, bux_segment);
    getUsersAnalytics(start, end, bux_segment);
    // getQualityAnalytics(type, start, end);
    // getPaidAnalytics(start, end, bux_segment);
    // getPendingAnalytics(type, start, end);
    //getTopMerhantsChannels(start, end, bux_segment)
  }


  React.useEffect(() => {
    getAllData(startDate, endDate, selectedSegments.toString());
  }, [selectedSegments])

  

  // const ButtonTab = ({rootStyle}) => {
  //   const status = [
  //     {
  //       text: "Individual Account",
  //       value: "ME",
  //       status: meToggle
  //     },
  //     {
  //       text: "Enterprise Account",
  //       value: "CO",
  //       status: coToggle
  //     },
  //   ];
    
  //   const handleClick = (event) => {
  //     let value = event.target.value
  //     if(value === "CO") {
  //       setCoToggle(!coToggle)
  //     }

  //     else if(value === "ME") {
  //       setMeToggle(!meToggle)
  //     }
  //   }
    
  //   return (
  //     <div style={rootStyle}>
  //       <ButtonGroup>
  //         {
  //           status.map((btn, i) => {
  //             return (
  //               <Button
  //                 disabled={overAllDisabled}
  //                 key={btn.value}
  //                 value={btn.value}
  //                 onClick={handleClick}
  //                 className={`
  //                   admin-btn-pill 
  //                   ${
  //                       selected === "All" || btn.status ? "selected-value" : 
  //                       overAllDisabled ? "disabled-btn" : 
  //                       "neutral-value"
  //                     } 
  //                   `}
          
  //                   >
  //                   {btn.text}
  //               </Button>
  //             )
  //           })
  //         }
  //       </ButtonGroup>
  //     </div>
  //   )
  // }

  return (
    <div style={{padding: '0 56px 64px 0'}}>
      <div className="screen-title-text">
        Overview
      </div>

        <div className="twoCol filter-date-div">
          <ButtonTab rootStyle={{padding: '32px 0'}} 
              setSelected={setSelected} 
              overAllDisabled={overAllDisabled} 
              setSelectedSegments={setSelectedSegments}
              segments={segments} />

          <div className="date-container">
            <div className="period-text">
              Select Period
            </div>
            <DatePicker
              style={{zIndex: 1,marginLeft: '12px', width: '150px'}}
              disabledDate={disabledStartDate}
              size="large"
              format='MM-DD-YYYY'
              value={startMoment || null}
              placeholder="Start"
              onChange={onStartChange}
              onOpenChange={handleStartOpenChange}
            />
            <span style={{padding: '0 8px'}}>-</span> 
            <DatePicker
              style={{zIndex: 1, width: '150px'}}
              disabledDate={disabledEndDate}
              size="large"
              format='MM-DD-YYYY'
              value={endMoment || null}
              placeholder="End"
              onChange={onEndChange}
              open={endOpen}
              onOpenChange={handleEndOpenChange}
            />
          </div>
      </div>

      <div className="top-20">
          <div className="wc-header">
            User's submission
          </div>

          <AcquisitionAnalytics data={acquiData} isLoading={ACQloading} />
      </div>
{/*
      <div>
          <div className="wc-header top-20">
            Top 5 Merchants
          </div>
          <TopMerchantsChannels data={TopData} isLoading={TopLoading} />
      </div>
 */}
      {/*<div className="top-20">
        <div className="wc-header">
            Analytics
        </div>
        <div className="twoCol top-20">
          <AnalyticsCard title='Quality Analytics' data={QAData} isLoading={QAloading} />
          <AnalyticsCard title='Paid Analytics' data={PAData} isLoading={PAloading} />
          <AnalyticsCard title='Pending Analytics' data={PenAData} isLoading={PeAloading} />
        </div>
</div>*/}
      <div className="top-20">
        <div className="wc-header">
            Download
        </div>
        <div className="twoCol top-20">
          <DownloadAnalyticsCard title='Transaction Volume' />
          <DownloadAnalyticsCard title='Top Contributors' />
        </div>
      </div>

{/*
      <div>
          <div className="wc-header top-20">
            Top 5 Channels
          </div>
          <TopChannels data={TopData} isLoading={TopLoading} />
      </div>
 */}
    </div>
  )
}

export default Overview;