import React from 'react'
import axios from 'axios'
import FeeStyles from '../style'
import {WebBakingIcon} from '../../../../../static/img/feeTableIcon.js'
import CustomInput from '../customInput'
import { Button, Icon, Input, Spin, Switch } from 'antd'

const WebBankingCard = props => {

    const fee = FeeStyles()
    const [listWebBanking, setListWebBanking] = React.useState([]);
    
    const [editMode, setEditMode] = React.useState(false)
    const [searchValue, setSearchValue] = React.useState(null);

    function passEditMode(val){
        setEditMode(val)
        props.getEditModeValue(val)
    }

    function get_wb(data) {
        let BLANK_WB = [{'channel_code': '',
                            'channel_fee': 0,
                            'channel_percent': 0,
                            'bux_fee': '',
                            'bux_percent': '',
                            'minimum': '',
                            'maximum': '',
                            'total_fee': 0,
                            'tier': '',
                            'partner': '',
                            'other_fee': 0,
                            'other_fee_percent': 0,
                            'user_fee': false}];
        if (data && data.length) {
            let preloaded_otc = data.map((wb) => {
                return {
                    'channel_code': wb.channel_code,
                    'channel_fee': wb.channel_fee,
                    'channel_percent': wb.channel_percent,
                    'bux_fee': wb.bux_fee,
                    'bux_percent': wb.bux_percent,
                    'minimum': wb.minimum,
                    'maximum': wb.maximum,
                    'channel': wb.channel,
                    'total_fee': wb.total_fee,
                    'tier': wb.tier,
                    'partner': wb.channel_partner,
                    'other_fee': wb.other_fee,
                    'other_fee_percent': wb.other_fee_percent,
                    'user_fee': wb.user_fee,
                }
            });
            return preloaded_otc;
        }
        return BLANK_WB; 
    }

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
    }


    const renderData = (value) => {
        let filteredData = [];  
    
        if (listWebBanking && value && value !== null) {
            filteredData = listWebBanking.filter(data => data.channel.toLowerCase().includes(value.toLowerCase()));
            return filteredData;
        }   
            return listWebBanking;

    }

    function handleChange (e, index) {
        const { name, value } = e.target
        const list = [...listWebBanking]
        list[index][name] = value
        setListWebBanking(list)
    };

    function submitOtc(){
        let params = {
            fees: listWebBanking,
            user_id: props.pUid
        }

        props.openModal(params, true)
    }

    const userFeeChange = (toggle, fees) => {
        let params = {
            fees: fees,
            user_id: props.pUid,
            user_fee: toggle,
        }
        props.toggleUserFeeModal(params);
    }

    const setFees = () => {
        if(props.data){
            let wb = []
            for(let i = 0; i< props.data.length; i++){
                if(props.data[i].channel_type == "Banking"){
                    wb.push(props.data[i])
                }
            }

            setListWebBanking(get_wb(wb))
        }

        else {
            console.log(props.data)
        }
    }

    React.useEffect(() => {
        setFees();
    },[props.data])

    
    return(
        !props.loading?
        <div>
            <div className={fee.innerDiv} style={{padding: '12px 0'}}>
            <div className={fee.spaceBetween}>
                    <div>
                        <img src={WebBakingIcon} /> <span className={fee.channelHeader}>Web Banking</span>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center',}}>
                        <Input
                                placeholder="Search"
                                style={{ width: '200px', height: '40px', borderRadius:'4px' }}
                                prefix={<Icon type="search" style={{fontSize:'18px'}}/>}
                                onChange={handleSearch}
                            />
                    </div>
                </div>
                <div className={fee.spaceBetween} style={{padding: '12px 0'}}>
                    <div>

                    </div>

                    <div>
                        <div hidden={!editMode}>
                            <button className={fee.cancelBtn} style={{marginRight: 10}} onClick={() => passEditMode(!editMode)}>Cancel</button>
                            <button className={fee.editBtn} onClick={() => submitOtc()}>Save</button>
                        </div>

                        <div hidden={editMode}>
                            <Button type='link' style={{fontSize: 16, color: '#F5922F', fontWeight: 'bold', padding: 0, }} onClick={() => passEditMode(!editMode)} disabled={props.editModeVal}>Edit Fee</Button>
                        </div>
                    </div>
                </div>

                <table style={{width: '100%'}}>
                    <tr className={fee.tableHeader}>
                        <td className={fee.tableHeaderData}>
                            Channel
                        </td>
                        <td className={fee.tableHeaderData}>
                            Minimum Amount
                        </td>
                        <td className={fee.tableHeaderData}>
                            Maximum Amount
                        </td>
                        <td className={fee.tableHeaderData}>
                            BUx Fee
                        </td>
                        <td className={fee.tableHeaderData}>
                            BUx %
                        </td>
                        <td className={fee.tableHeaderData}>
                            Total
                        </td>
                        <td className={fee.tableHeaderData}>
                            Other Fee
                        </td>
                        <td className={fee.tableHeaderData}>
                            Other Fee %
                        </td>
                        <td className={fee.tableHeaderData}>
                            API Control
                        </td>
                    </tr>
                    {
                       renderData(searchValue) && renderData(searchValue).map((item, key) => {
                            return(
                            <tr>
                               <td className={fee.tableChannelHeader}>
                                    {item.channel}
                                </td>
                                <td className={fee.tableChannelText}>
                                    &#8369; {item.minimum} 
                                </td>
                                <td className={fee.tableChannelText}>
                                    <div hidden={editMode}>
                                    &#8369; {item.maximum} 
                                    </div>
                                    <div hidden={!editMode}>
                                        <CustomInput name={"maximum"} value={item.maximum} placeholder={"Max Amount"} handleChangeVal={handleChange} index={key}/>
                                    </div>
                                </td>
                                <td className={fee.tableChannelText}>
                                    <div hidden={editMode}>
                                    &#8369; {item.bux_fee} 
                                    </div>
                                    <div hidden={!editMode}>
                                        <CustomInput  value={item.bux_fee} name={"bux_fee"} handleChangeVal={handleChange} placeholder={"BUx Fee"} index={key}/>
                                    </div>
                                </td>
                                <td className={fee.tableChannelText}>
                                    <div hidden={editMode}>
                                    {item.bux_percent}%
                                    </div>
                                    <div hidden={!editMode}>
                                        <CustomInput value={item.bux_percent} name={"bux_percent"} placeholder={"Percent"}  handleChangeVal={handleChange} index={key}/>
                                    </div>
                                </td>
                                <td className={fee.tableChannelText}>
                                    <div>
                                    &#8369; {item.total_fee} 
                                    </div>
                                </td>
                                <td className={fee.tableChannelText}>
                                    <div hidden={editMode}>
                                    {item.other_fee}
                                    </div>
                                    <div hidden={!editMode}>
                                        <CustomInput value={item.other_fee} name={"other_fee"} placeholder={"Other Fee"}  handleChangeVal={handleChange} index={key}/>
                                    </div>
                                </td>
                                <td className={fee.tableChannelText}>
                                    <div hidden={editMode}>
                                    {item.other_fee_percent}%
                                    </div>
                                    <div hidden={!editMode}>
                                        <CustomInput value={item.other_fee_percent} name={"other_fee_percent"} placeholder={"Percent"}  handleChangeVal={handleChange} index={key}/>
                                    </div>
                                </td>
                                <td className={fee.tableChannelText}>
                                    <div>
                                        <Switch checked={item.user_fee} onChange={() => userFeeChange(!item.user_fee, item)} style={{margin: '12px 20px 12px 12px', backgroundColor: `${item.user_fee ? '#F5922F' : '#D1D5DD'}`}} disabled={editMode} />
                                    </div>
                                </td>
                            </tr>

                            )
                        })
                    }
                </table>
            </div>
        </div>
        :
        <Spin style={{width: '100%', height: 212, display: 'flex', alignItems: 'center', justifyContent: 'center'}} />
    )
}

export default WebBankingCard