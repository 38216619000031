import React from 'react'
import {Drawer, Row, Col, Typography, Divider, Icon} from 'antd'
import moment from 'moment'
import { TwitterShareButton, EmailShareButton, FacebookMessengerShareButton } from 'react-share'

//Icons
import FBIcon from '../../../../static/icons/voucher_drawer/fb.svg'
import IGIcon from '../../../../static/icons/voucher_drawer/ig.svg'
import TwitterIcon from '../../../../static/icons/voucher_drawer/twitter.svg'
import EmailIcon from '../../../../static/icons/voucher_drawer/email.svg'

const VoucherDrawer = props => {

    const { detailsData, drawerVisibility, setDrawerVisibility} = props
    const segments = localStorage.getItem("segments")

    const segmentsColor = {
        'me_':{
            color: '#0DAED0',
        },
        'gig':{
            color: '#41BD5C',
        },
    }

    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
    const FB_APP_ID = process.env.REACT_APP_FB_ID
    const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL

    const link = `${REACT_APP_BASE_URL}${'/voucher/'}${detailsData && detailsData.code}/${detailsData && encodeURIComponent(detailsData.description)}/${detailsData && encodeURIComponent(detailsData.banner_blob)}`

    const fbDisplay = () => {
        return(
            <a href={detailsData && detailsData.facebook_post}>
                <img style={{marginRight: '12px', height: '40px'}} src={FBIcon} alt="#"/>
            </a>
        )
    }

    const igDisplay = () => {
        return (
            <a href={detailsData && detailsData.instagram_post}>
                <img style={{marginRight: '12px', height: '40px'}} src={IGIcon} alt="#"/>
            </a>);
    }

    const twitterDisplay = () => {

        return(
            <a href={detailsData && detailsData.twitter_post}>
                <img style={{marginRight: '12px', height: '40px'}} src={TwitterIcon} alt="#"/>
            </a>
        )
    }

    const emailDisplay = () => {

        let emailSubject = `BUx Voucher | ${detailsData && detailsData.code}`

        return(
            <EmailShareButton subject={emailSubject} body={link}>
                <img style={{marginRight: '12px', height: '40px'}} src={EmailIcon} alt="#"/>
            </EmailShareButton>
        )
    }
    
    return(
        <Drawer
            className='voucher-drawer'
            placement="right"
            closable={false}
            visible={drawerVisibility}
            width='378px'
        >
        <div style={drawerStyles.root}>
            <div style={{marginBottom: '20px'}} align="right">
                <Icon type='close' onClick={()=>setDrawerVisibility(false)} style={{fontSize: '20px', color: segmentsColor[segments].color}}/>
            </div>
            <div style={drawerStyles.flexBetween}>
                <div style={{lineHeight: '18px'}}>
                    <Typography style={drawerStyles.promocode}>PROMOCODE</Typography>
                    <Typography style={{...drawerStyles.code, color: segmentsColor[segments].color}}>{(detailsData && detailsData.code) || "N/A"}</Typography>
                </div>
            </div>
            <div style={{padding: '8px 0'}}>
                <Typography style={{...drawerStyles.sectionTitle, color: segmentsColor[segments].color}}>Promo details</Typography>
                <Divider style={{margin: '6px 0', backgroundColor: segmentsColor[segments].color}} />
                <Row style={drawerStyles.row}>
                    <Col span={10} style={drawerStyles.field}>Promo name</Col>
                    <Col span={14} style={drawerStyles.fieldValue}>{(detailsData && detailsData.promo_name) || "N/A"}</Col>
                </Row>
               
                <Row style={drawerStyles.row}>
                    <Col span={10} style={drawerStyles.field}>Promo start</Col>
                    <Col span={14} style={drawerStyles.fieldValue}>{(detailsData && moment(detailsData.date_start).format('lll')) || "N/A"}</Col>
                </Row>
                <Row style={drawerStyles.row}>
                    <Col span={10} style={drawerStyles.field}>Promo end</Col>
                    <Col span={14} style={drawerStyles.fieldValue}>{(detailsData && moment(detailsData.date_expiry).format('lll')) || "N/A"}</Col>
                </Row>
                
            </div>
            <div style={{padding: '8px 0'}}>
                <Typography style={{...drawerStyles.sectionTitle, color: segmentsColor[segments].color}} >Voucher details</Typography>
                <Divider style={{margin: '6px 0', backgroundColor: segmentsColor[segments].color}} />
                <Row style={drawerStyles.row}>
                    <Col span={10} style={drawerStyles.field}>Discount type</Col>
                    <Col span={14} style={drawerStyles.fieldValue}>{(detailsData && detailsData.discount_type === "FI" ? 'Fixed Amount' : 'Percentage') || "N/A"}</Col>
                </Row>
                {
                    detailsData && detailsData.discount_type == "FI" ?
                    <Row style={drawerStyles.row}>
                        <Col span={10} style={drawerStyles.field}>Amount</Col>
                        <Col span={14} style={drawerStyles.fieldValue}>₱{(detailsData && detailsData.fixed_discount && parseFloat(detailsData.fixed_discount).toFixed(2)) || "N/A"}</Col>
                    </Row> :
                    <Row style={drawerStyles.row}>
                        <Col span={10} style={drawerStyles.field}>Percent</Col>
                        <Col span={14} style={drawerStyles.fieldValue}>{detailsData && detailsData.percentage_discount}%</Col>
                    </Row> 

                }
                <Row style={drawerStyles.row}>
                    <Col span={16} style={drawerStyles.field}>Min. amount of purchase</Col>
                    <Col span={8} style={drawerStyles.fieldValue}>₱{(detailsData && detailsData.min_amount && parseFloat(detailsData.min_amount).toFixed(2)) || "N/A"}</Col>
                </Row>
                <Row style={drawerStyles.row}>
                    <Col span={10} style={drawerStyles.field}>Limit per merchant</Col>
                    <Col span={14} style={drawerStyles.fieldValue}>{(detailsData && detailsData.q_merchant) || 0}</Col>
                </Row>
                <Row style={drawerStyles.row}>
                    <Col span={10} style={drawerStyles.field}>Limit per day</Col>
                    <Col span={14} style={drawerStyles.fieldValue}>{(detailsData && detailsData.q_day) || 0}</Col>
                </Row>
                <Row style={drawerStyles.row}>
                    <Col span={14} style={drawerStyles.field}>Limit per merchant/day</Col>
                    <Col span={10} style={drawerStyles.fieldValue}>{(detailsData && detailsData.q_merchant_day) || 0 }</Col>
                </Row>
            </div>
            <div style={{padding: '8px 0'}}>
                <Typography style={{...drawerStyles.sectionTitle, color: segmentsColor[segments].color}}>Description</Typography>
                <Divider style={{margin: '6px 0', backgroundColor: segmentsColor[segments].color}} />
                <Row style={drawerStyles.row}>
                    <Col span={10} style={{...drawerStyles.field, width: '100%'}}>{(detailsData && detailsData.description) || "N/A"}</Col>
                </Row>
            </div>
            <div style={{padding: '8px 0'}}>
                <Typography style={{...drawerStyles.sectionTitle, color: segmentsColor[segments].color}}>Channels</Typography>
                <Divider style={{margin: '6px 0', backgroundColor: segmentsColor[segments].color}} />
                <Row style={drawerStyles.row}>
                    <Col span={10} style={{...drawerStyles.field, width: '100%'}}>All OTC Channels</Col>
                </Row>
            </div>
            <div style={{paddingTop: '64px', display: 'flex', justifyContent: 'center'}}
                hidden={detailsData && ((!detailsData.facebook_post || detailsData.facebook_post == "") && 
                (!detailsData.instagram_post || detailsData.instagram_post == "") &&
                (!detailsData.twitter_post || detailsData.twitter_post == ""))}>
                <div style={drawerStyles.shareText}>Share this voucher to your customers!</div>
            </div>
            <div style={{padding: '10px 0px 30px 0px', display: 'flex', justifyContent: 'center'}} >
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div hidden={detailsData && (!detailsData.facebook_post || detailsData.facebook_post == "")}>
                        {fbDisplay()}
                    </div>
                    <div hidden={detailsData && (!detailsData.instagram_post || detailsData.instagram_post == "")}>
                        {igDisplay()}
                    </div>
                    <div hidden={detailsData && (!detailsData.twitter_post || detailsData.twitter_post == "")}>
                        {twitterDisplay()}
                    </div>
                    {/* {emailDisplay()} */}
                   
                </div>
            </div>
            
        </div>
        </Drawer>
    )
}

const drawerStyles = {
    root: {
        padding: '24px' 
    },

    cancelBtn: {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: '1px solid #0A315E',
        borderRadius: 4,
        height: 40,
        fontSize: 16,
        fontWeight: '500',
        color: '#0A315E',
        padding: '8px 16px',
        width: 'auto',
        marginRight: 8
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px 0 12px 0'
    },
    promocode: {
        fontSize: '12px',
        fontWeight: 'bold',
        marginBottom: '5px',
        color: 'rgba(43, 45, 50, 0.8)'
    },
    code: {
        color: '#F5922F',
        fontSize: '24px',
        fontWeight: 'bold'
    },
    sectionTitle: {
        fontSize: '16px',
        fontWeight: 'bold'
    },
    row: {
        padding: '8px 0',
    },
    fieldValue: {
        color: '#2B2D32',
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'right'
    },
    field: {
        color: '#2B2D32',
        fontSize: 16,
    },
    shareText:{
        fontSize: '16px',
        fontWeight: '500',
        color: '#2b2d32'
    }
}

export default VoucherDrawer 