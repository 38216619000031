import React, {useEffect} from 'react';
import {Alert, Input, Spin} from 'antd'
import accountStyle from '../../static/css/Account';
import settingServices from './settingServices';

const BusinessDetails = props => {

    const acct = accountStyle();
    useEffect(() => {
        if(localStorage.getItem("jwtToken") != "" && localStorage.getItem("jwtToken") != null){
            fetchMyAPI()
        }
    }, [])
    const [nameOfBusiness, setNameOfBusiness] = React.useState("")
    const [industry, setIndustry] = React.useState("")
    const [fb, setFB] = React.useState("")
    const [ig, setIG] = React.useState("")
    const [web, setWeb] = React.useState("")
    const [numberEmployees, setNumberEmployees] = React.useState("")
    const [businessType, setBusinessType] = React.useState("")
    const [position, setPosition] = React.useState("")
    const [businessContact, setBusinessContact] = React.useState("")
    const [stAddress, setStAddress] = React.useState("")
    const [cityAddress, setCityAddress] = React.useState("")
    const [provinceAddress, setProvinceAddress] = React.useState("")
    const [postalCode, setPostalCode] = React.useState("")
    const [mAddress, setMaddress] = React.useState("");
    const [businessEdit, setBusinessEdit] = React.useState(true);
    const [message, setMessage] = React.useState(true);
    const [businessAlert, setBusinessAlert] = React.useState(true);
    const [alertType, setAlertType] = React.useState("success");

    const [respData, setRespData] = React.useState("")

    function editBusiness(){
        setBusinessEdit(false)
    }

    function cancelBusiness(){
        setBusinessEdit(true)
        responseData(respData)
    }

    const blankIfNA = (val) => (val === 'n/a') ? '' : val;

    function updateBusiness(){

        let business_name_s = blankIfNA(nameOfBusiness);
        let industry_s = blankIfNA(industry);
        let fb_s = blankIfNA(fb);
        let ig_s = blankIfNA(ig);
        let web_s = blankIfNA(web);
        let employees_s = numberEmployees;
        let business_type_s = businessType;
        let position_s = blankIfNA(position);
        let contact_s = blankIfNA(businessContact);
        let street_address_s = blankIfNA(stAddress);
        let city_address_s = blankIfNA(cityAddress);
        let province_address_s = blankIfNA(provinceAddress);
        let postal_code_s = blankIfNA(postalCode);

        setNameOfBusiness(business_name_s);
        setIndustry(industry_s);
        setFB(fb_s);
        setIG(ig_s);
        setWeb(web_s);
        setPosition(position_s);
        setBusinessContact(contact_s);
        setStAddress(street_address_s);
        setCityAddress(city_address_s);
        setProvinceAddress(province_address_s);
        setPostalCode(postal_code_s);

        if(numberEmployees=="n/a"){
            setNumberEmployees(0)
            employees_s = 0
        }


        if(businessType=="n/a"){
            setBusinessType("")
            business_type_s = 0
        }

        if(numberEmployees <= 0){
            setMessage("Number of Employees must be at least 1");
            setBusinessAlert(false)
            setAlertType("error")
            return true
        }
        
        if(nameOfBusiness.length > 200 ){
            setMessage("Name of business must 200 characters or below");
            setBusinessAlert(false)
            setAlertType("error")
        }
        if(position.length > 200 ){
            setMessage("Position must 50 characters or below");
            setBusinessAlert(false)
            setAlertType("error")
        }
        if(stAddress.length > 35 ){
            setMessage("Street address must 35 characters or below");
            setBusinessAlert(false)
            setAlertType("error")
        }
        if(postalCode.length > 12 ){
            setMessage("Postal code must 12 characters or below");
            setBusinessAlert(false)
            setAlertType("error")
        }

        let params = {
            business_name: business_name_s ,
            industry: industry_s,
            facebook: fb_s,
            instagram: ig_s,
            website: web_s,
            employees: employees_s,
            business_type: business_type_s,
            position: position_s,
            contact: contact_s,
            street_address: street_address_s,
            city_address: city_address_s,
            province_address: province_address_s,
            postal_code: postal_code_s,
        }

        businessUpdate(params)

    }

    function responseData(res) {
        if(res) {
            setNameOfBusiness(naIfBlank(res.business.name));
            setIndustry(naIfBlank(res.business.industry));
            setNumberEmployees(naIfBlank(res.business.employees));
            setBusinessType(naIfBlank(res.business.business_type));
            setPosition(naIfBlank(res.business.position));
            setBusinessContact(naIfBlank(res.business.contact));
            setFB(naIfBlank(res.business.facebook));
            setIG(naIfBlank(res.business.ig));
            setWeb(naIfBlank(res.business.web));
        }
    } 

    const naIfBlank = (val) => val || 'n/a';

    async function businessUpdate(value){

        try{
            const res = await settingServices.businessInfoUpdate(value);

            if (res.status == "success"){
                setMessage(res.message)
                setBusinessAlert(false)
                setAlertType("success")

                window.location.reload()
            } else {
                setMessage(res.message);
                setBusinessAlert(false);
                setAlertType("error");
            }
        }
        catch(error){
            console.error(error);
        }
        
        
    }

    async function fetchMyAPI() {

        try{

            let res = await settingServices.fetchMyAPI();
            if(res != ""){
                setRespData(res);
                responseData(res);
            }
        }
        catch(error){
          console.error(error);
        }

    }

    return(
        <div className={acct.personal}>
            <div className={acct.personalMenu}>
                <div className={acct.personalHeader}>
                    Business Information
                </div>
                <div className={acct.personalAction}>
                    <a onClick={updateBusiness} className={acct.save} hidden={businessEdit}>Save</a>
                    <a onClick={cancelBusiness} hidden={businessEdit}>Cancel</a>

                    <a onClick={editBusiness} hidden={!businessEdit}>Edit</a>
                </div>
            </div>

            <div style={{width: '100%', marginTop: '10px'}} hidden={businessAlert}>
                <Alert message={message} type={alertType} style={{marginBottom: '20px'}} showIcon/>
            </div>


            <div className={acct.personalInfo} align='left'>
                <div className={acct.flexing}>
                    <div className={acct.halfContainer}>
                        <div className={acct.piHeader}>
                            Business Name:
                        </div> 
                    </div>

                    <div className={acct.halfContainer}>
                        <div className={acct.piText} hidden={!businessEdit}>
                            {nameOfBusiness ? nameOfBusiness: <Spin size="small" />}
                        </div> 

                        <div className={acct.piInput} hidden={businessEdit}>

                            <div style={{width: "250px"}}>
                            <Input placeholder="Name of Business" value={nameOfBusiness == "n/a" ? "" : nameOfBusiness} onChange={e => setNameOfBusiness(e.target.value)}/>
                            </div>
                        </div> 
                    </div>
                </div>

                <div className={acct.flexing}>
                    <div className={acct.halfContainer}>
                        <div className={acct.piHeader} >
                        Nature of Business:
                        </div> 

                       
                    </div>
                    <div className={acct.halfContainer}>
                        <div className={acct.piText} hidden={!businessEdit}>
                        {industry ? industry: <Spin size="small" />}

                        </div> 

                        <div className={acct.piInput} hidden={businessEdit}>

                            <div style={{width: "250px"}}>
                            <Input placeholder="Industry" value={industry  == "n/a" ? "" : industry} onChange={e => setIndustry(e.target.value)}/>
                            </div>
                        </div> 
                    </div>
                </div>

                <div className={acct.flexing}>
                    <div className={acct.halfContainer}>
                        <div className={acct.piHeader}>
                            Website:
                        </div> 
                    </div>
                    <div className={acct.halfContainer}>
                        <div className={acct.piText} hidden={!businessEdit}>
                            {web ? web: <Spin size="small" />}
                        </div> 

                        <div className={acct.piInput} hidden={businessEdit}>

                            <div style={{width: "250px"}}>
                                <Input placeholder="Website" value={web  == "n/a" ? "" : web} onChange={e => setWeb(e.target.value)}/>
                            </div>
                        </div> 
                    </div>
                </div>

                <div className={acct.flexing}>
                    <div className={acct.halfContainer}>
                        <div className={acct.piHeader}>
                            Facebook:
                        </div> 
                    </div>
                    <div className={acct.halfContainer}>
                        <div className={acct.piText} hidden={!businessEdit}>
                            {fb ? fb: <Spin size="small" />}
                        </div> 

                        <div className={acct.piInput} hidden={businessEdit}>

                            <div style={{width: "250px"}}>
                                <Input placeholder="Facebook" value={fb  == "n/a" ? "" : fb} onChange={e => setFB(e.target.value)}/>
                            </div>
                        </div> 
                    </div>
                </div>

                <div className={acct.flexing}>
                    <div className={acct.halfContainer}>
                        <div className={acct.piHeader}>
                            Instagram:
                        </div> 
                    </div>
                    <div className={acct.halfContainer}>
                        <div className={acct.piText} hidden={!businessEdit}>
                            {ig ? ig: <Spin size="small" />}
                        </div> 

                        <div className={acct.piInput} hidden={businessEdit}>

                            <div style={{width: "250px"}}>
                                <Input placeholder="Instagram" value={ig  == "n/a" ? "" : ig} onChange={e => setIG(e.target.value)}/>
                            </div>
                        </div> 
                    </div>
                </div>

                {/* <div className={acct.flexing}>
                    <div className={acct.halfContainer}>
                        <div className={acct.piAddress}>
                            Address:
                        </div> 
                    </div>
                    <div className={acct.halfContainer}>
                        <div className={acct.piAddressText} hidden={!businessEdit}>
                            {mAddress ? mAddress:  <Spin size="small" />}

                        </div> 

                        <div className={acct.piInput} hidden={businessEdit}>

                            <div style={{width: "250px", marginBottom: '10px'}}>
                            <Input value={stAddress} placeholder="Street Address" onChange={e => setStAddress(e.target.value)}/>
                            </div>

                            <div style={{width: "250px", marginBottom: '10px'}}>
                            <Input value={cityAddress} placeholder="City Address" onChange={e => setCityAddress(e.target.value)}/>
                            </div>

                            <div style={{width: "250px", marginBottom: '10px'}}>
                            <Input value={provinceAddress} placeholder="Province" onChange={e => setProvinceAddress(e.target.value)}/>
                            </div>

                            <div style={{width: "250px", marginBottom: '10px'}}>
                            <Input value={postalCode} placeholder="Postal Code" onChange={e => setPostalCode(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                </div> */}
            
            </div>
            {/* <div className={acct.businessButton} align='left'>
                <div className={acct.personalHeader}>
                    <button className={acct.facebookStyle}><img align="left" className={acct.iconStyle} src={facebook_icon} />Connect Facebook</button>
                    <button className={acct.googleStyle}><img align="left" src={google_icon} className={acct.iconStyle}/>Connect Google</button>
                </div>
            </div> */}
        </div>
    )
}

export default BusinessDetails;