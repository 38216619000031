import React,{ useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Buffer } from 'buffer';
import crypto from 'crypto';
import { Card, Input, Button, Typography, Dropdown, Menu, Icon, Select, Spin, Affix } from 'antd';
import visaCard from "../../static/images/bux_visa_card.svg";
import eonVisaCard from '../../static/images/eon_visa_card.svg';
import { isMobile } from 'react-device-detect';
import sampleCardBlue from '../../static/images/bux_sample_card_blue.svg';
import sampleCardOrange from '../../static/images/bux_sample_card_orange.svg';
import eonCardSample from '../../static/images/eon_sample_card.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { history } from '../../store/history';
import RetailShopResultModal from './modals/retailShopResultModal';
import cardActions from './cardActions';
import cardServices from './cardServices';
import CancelIcon from '@material-ui/icons/Cancel';
import OtpCard from './otp/otpCard';

import {ADDRESS} from '../constants/constants'

const menu = (
  <Menu>
    <Menu.Item key="1">
      1st menu item
    </Menu.Item>
    <Menu.Item key="2">
      2nd menu item
    </Menu.Item>
    <Menu.Item key="3">
      3rd item
    </Menu.Item>
    <Menu.Item key="4">
      1st menu item
    </Menu.Item>
    <Menu.Item key="5">
      2nd menu item
    </Menu.Item>
    <Menu.Item key="6">
      3rd item
    </Menu.Item>
  </Menu>
);

const UpgradePhysicalCard = () => {
  const dispatch = useDispatch();
  const [cardNumber, setCardNumber] = useState(null);
  const [encKey, setEncKey] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [activateError, setActivateError] = useState(false);
  const visibleOTP = useSelector(state => state.card['otp_mobile']);
  const [province, setProvince] = useState('Metro Manila');
  const [city, setCity] = useState('');
  const [locations, setLocations] = useState([]);
  const otpSuccess = useSelector(state => state.card.otpSuccess);
  const otpUID = useSelector(state => state.card.otpUID);
  const [notifMsg, setNotifMsg] = useState('');
  const [initialized, setInitialized] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [cardInfo, setCardInfo] = useState(null);
  const [physicalCard, setPhysicalCard] = useState(false);
  const [btnStyle, setBtnStyle] = useState({ backgroundColor: 'transparent', color: '#F5922F' })
  const segments = localStorage.getItem('segments');

  const getCardInfo = async () => {
    setPageLoading(true)
    try {
      const response = await cardServices.getCardInfo();
      setCardInfo(response);
      setPhysicalCard(response.physical)
      setPageLoading(false)
      if(localStorage.getItem('Level') >= 1 && !response.customer_id || response.lastFourDigits === "" && response.account.number == 'none' && !response.customer_id ) {
        history.push(isMobile? '/mobile/dashboard' : '/dashboard');
      }
    } catch (error) {
      console.log(error);
      setPageLoading(false)
    }
  }

  if (!initialized) {
    getCardInfo()
    const current_path = window.location.pathname
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
        history.push('/login?next=' + current_path)
    } else {
      if (isMobile) {
          history.push('/mobile/physical_card_upgrade');
          setInitialized(true);
      } else {
        setInitialized(true);
      }
    }
  }

  const closeRetailShopModal = () => {
    dispatch(cardActions.hideModal('retail_shop_result'));
  }
  const handleSearchClick = () => {
    fetchBranches(city);
  }

const { Option } = Select;


const getProvinces = () => {
    let provinces = []
    for(var i in ADDRESS){
        provinces.push(<Option value={i}>{i}</Option>);
    }
    return provinces
}

const getCities = (type) =>{
    let cities = []
    if(type in ADDRESS){
        for(var i in ADDRESS[type]['Municipality_list']){
            cities.push(<Option value={i}>{i}</Option>);
        }
    }
    return cities
}


  function encryptor(message) {
    let key = otpUID;
    let iv = crypto.randomBytes(16);
    let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from( key ), iv);
    let encryption = cipher.update(message);
    encryption = Buffer.concat([encryption, cipher.final()]);
    return iv.toString('base64') + ":" + encryption.toString('base64');
 }

  const activateCard = async () => {
    setBtnLoading(true);
    try {
      const encryptedCard = encryptor(cardNumber);
      const response = await cardServices.activateCard({'new_card': encryptor(cardNumber)})
      if (response.status === 'success') {
        history.push({ pathname: '/card', state: { isSuccess: true, notifMsg: 'Successfully Activated Card!' }});
        setBtnLoading(false);
      } else {
        setActivateError(true);
        setNotifMsg('Error Try Again');
        setBtnLoading(false);
      }
    } catch (error) {
      console.log(error);
      setActivateError(true)
      setNotifMsg(error);
      setBtnLoading(false);
    }
  }

  if(activateError) {
    setTimeout(() => {
      setActivateError(false);
    }, 3000);
  }

  const fetchBranches = async (value) => {
    setBtnLoading(true);
    try {
      const response = await cardServices.getBranches(value);
      setLocations(response);
      dispatch(cardActions.showModal('retail_shop_result'));
      setBtnLoading(false);
    } catch (error) {
      console.log(error)
      setBtnLoading(false);
    }
  }
  
  if(otpSuccess) {
    activateCard();
    dispatch(cardActions.setOtpSuccess(false));
  }

  const returnOtpSubj = () => {
    const path = window.location.pathname
    
    switch (path) {
      case '/physical_card_upgrade':
      case '/mobile/physical_card_upgrade':
        return 'replace_card';
      case '/cards/bills_payment':
      case '/mobile/bills_payment':
        return 'bills_pay';
      case '/request_cvv':
      case '/mobile/request_cvv':
        return 'cvv';
      default:
        return 'payout';
    }
  }

  const fetchOTP = async () => {
    setBtnLoading(true);
    try {
      const response = await cardServices.getOTP();
      if (response.status === 'success') {
        dispatch(cardActions.setOtpParams({mobile:response.mobile, uid:response.uid, subj: returnOtpSubj()}));
        dispatch(cardActions.showModal('otp_mobile'))
        dispatch(cardActions.resetOTPTimer(true))
      } else {
        if(response.code==='attempts'){
          dispatch(cardActions.setAttempMsg(response.unblock));
          dispatch(cardActions.showModal('attempt_error'));
          dispatch(cardActions.showModal('otp_mobile'));
        } else {
          setBtnLoading(false)
          setActivateError(true)
          setNotifMsg(response.message);
          dispatch(cardActions.hideModal('otp_mobile'))
        }
      }
    } catch (error) {
      console.log(error)
      setActivateError(true)
      setNotifMsg(error);
      dispatch(cardActions.hideModal('otp_mobile'))
      setBtnLoading(false)
    }
  }

  const handleMouseOver = () => {
    setBtnStyle({
      backgroundColor: '#F5922F',
      color: '#FAFAFA'
    });
  }

  const handleMouseLeave = () => {
    setBtnStyle({
      backgroundColor: 'transparent',
      color: '#F5922F'
    });
  }

  const buxCard = (
    <div style={{ width: '100%', position: 'relative', padding: isMobile ? '8px' : 0 }}>
      <img alt='No Image' src={visaCard} style={{width: '100%'}} />
      <Typography style={styles.buxCard}>xxxx xxxx xxxx</Typography>
    </div>
  );

  const eonCard = (
    <div style={{ width: '100%', position: 'relative' }}>
      <img alt='No Image' src={eonVisaCard} style={{width: '100%'}} />
      <div style={{ ...styles.cardStyle, top: '70%', left: '31%', fontSize: 16, fontWeight: 'bold', textAlign: 'left' }}>
        { cardInfo && pageLoading ? "**** " + cardInfo.lastFourDigits : ""}
      </div>
      <div style={{display: 'flex'}}>
        <div style={{...styles.cardStyle, width: '0%', top: '87%', left: '6%',fontSize: 6, fontWeight: '900', textAlign: 'left', lineHeight: '10px' }}> {'VALID THRU'}</div>
        <div style={{ ...styles.cardStyle, top: '87%', left: '40%', fontSize: 12, textAlign: 'left', fontWeight: 'bold'}}>
          {cardInfo ? cardInfo.expiryDate : ''}
        </div>
      </div>
      <div style={{ ...styles.cardStyle, top: '59%', left: '68%', fontSize: 12, textAlign: 'right', fontWeight: '400' }}>
        Card Balance
      </div>
      <div style={{ ...styles.cardStyle, width: '65%', top: '70%', left: '61%', fontSize: 16, fontWeight: 'bold', textAlign: 'right'}}>
        { cardInfo ? 'PHP ' + parseFloat(cardInfo.account.availableBalance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'PHP 0.0'}
      </div>
    </div>
  )

  const handleCardChange = (event) => {
    setCardNumber(event.target.value);
  }

  return (
    <div style={styles.root}>
      <RetailShopResultModal closeModal={closeRetailShopModal} locations={locations} city={city} province={province}/>
      {
        activateError ?
        <Affix offsetTop={20} style={styles.affix}>
          <div style={styles.notif}>
            <CancelIcon
              style={{ 
                margin: '0 10px 0 0'
              }} 
            />
            <div>
              <div align='left'>{notifMsg}</div>
            </div>
          </div>
        </Affix>
        : null
      }
      <div style={styles.title}>
        <button className={`btn--${segments}`} style={styles.btnBack} hidden={isMobile} onClick={() => history.push(isMobile ? '/mobile/card' : '/card')}>{<Icon className={`link-text--${segments}`} type="left" />} Back</button>
        <strong style={styles.regularFont}>{physicalCard ? 'Change your EON Visa Card' : 'Upgrade to EON Visa Card'}</strong>
      </div>
      <div hidden={visibleOTP} style={styles.content}>
        <div style={{display: 'flex', flexDirection: 'column', width: 290, margin:' 0px 8px 0px 0px', display: isMobile ? 'none' : 'content'}}>
          { pageLoading ? <Spin style={styles.spinner} /> : physicalCard ? eonCard : buxCard}
          <Typography style={{fontWeight: 'bold', color: '#000000', padding:'32px 0px 8px 8px'}}>Virtual BUx Visa Card lets you...</Typography>
          <div style={{padding: '4px 0px 0px 24px'}}>
            <Typography style={styles.visaAbout}>Transfer your BUx Wallet Amount to your Card</Typography>
            <Typography style={styles.visaAbout}>Withdraw cash from any UnionBank / Bancnet / Visa ATM</Typography>
            <Typography style={styles.visaAbout}>View your balance and transactions in real-time</Typography>
            <Typography style={styles.visaAbout}>Pay your bills online</Typography>
            <Typography style={styles.visaAbout}>Transfer funds to a UBP/Local/eMoney Wallet Accounts</Typography>
          </div>
        </div>
        <div style={{ margin: isMobile ? 0 : '0 0 0 8px'}}>
          <Card size="small" style={{borderRadius: 4, width: isMobile ? '100%' : 417}} bodyStyle={{padding: isMobile ? 16 : '12px 0px'}}>
            {isMobile && !pageLoading && !physicalCard ? buxCard 
              : isMobile && !pageLoading && physicalCard ? eonCard 
              : pageLoading && isMobile ? <Spin style={{...styles.spinner, height: 167}} /> 
              : null}
            <Spin hidden={!pageLoading} style={{...styles.spinner, height: isMobile ? 240 : 280}}  />
            <div hidden={pageLoading}>
              <Typography style={styles.subtitle}>{physicalCard ? 'Change your EON Visa Card' : 'Activate your Card'}</Typography>
              <Typography style={styles.msg}>
                {physicalCard ? 'To change ' : 'To activate '} this you will need to purchase an <strong>EON Visa Card</strong> and
                enter the <strong>New Visa Card Number</strong>.
              </Typography>
              <div  style={{textAlign: "left", padding: isMobile ? '14px 0' : '16px 32px'}}>
                <label style={styles.labelFont}>Visa Card No.</label>
                <Input style={styles.input} 
                  placeholder="0000 - 0000 - 0000 - 0000" 
                  onChange={handleCardChange}
                  disabled={btnLoading}
                ></Input>
                <button style={styles.btnActivate}
                  className={`btn--${segments}`}
                  onClick={fetchOTP} 
                  disabled={btnLoading || !cardNumber}
                >{btnLoading ? <Spin size='small' /> : cardInfo && cardInfo.physical ? 'Change Card' : 'Activate Card'}</button>
              </div>
            </div>
            <hr style={styles.divider} />
            <Typography style={styles.subtitle}>EON Visa Card</Typography>
            <div style={{padding: isMobile ? '16px 0' : '16px 32px'}}>
              <img alt="No Image" src={eonCardSample} style={{width: 122, margin: '0 8px 0 0' }} />
            </div>
            <Typography style={styles.subtitle}>EON Visa Card Retail Shop</Typography>
            <div style={{padding: isMobile ? '16px 0' : '16px 32px'}}>
              <div style={{display: 'flex', justifyContent: 'space-between' }}>
                <div style={{width: '48%'}}>
                  <label style={styles.labelFont}>Province</label>

                  <Select style={{width: '100%'}}
                  size="large" value={province} onChange={(value)=>{setProvince(value); setCity('');}}>
                      {
                          getProvinces()
                      }
                  </Select>
                </div>
                <div style={{width: '48%'}}>
                  <label style={styles.labelFont}>City</label>
                  <Select style={{width: '100%'}}
                  size="large" value={city} onChange={(value)=>setCity(value)}>
                      {
                          getCities(province)
                      }
                  </Select>
                </div>
              </div>
              <button style={{...styles.btnSearch}} 
                className={`outline-btn--${segments}`}
                onClick={handleSearchClick} 
                disabled={city == ""}
                // onMouseOver={handleMouseOver} 
                // onMouseLeave={handleMouseLeave}
              >Search</button>
            </div>
          </Card>
        </div>
      </div>
      <div hidden={!visibleOTP} style={styles.content}>
        <OtpCard />
      </div>
    </div>
  )
};

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F4F6F9',
    marginTop: 24
  },
  title: {
    width: isMobile ? '100%' : 720,
    // display: 'flex',
    textAlign: 'left',
    // alignItems: 'center',
    padding: isMobile ? '25px 16px 16px 16px' : '50px 0px 44px 0px',
  },
  input: {
    padding: '11px 16px 11px 16px',
    fontSize: 16,
    height: 48,
    margin: '4px 0px 0px 0px',
  },
  regularFont: {
    fontSize: isMobile ? 14 : 28,
    color: '#000000',
    padding: isMobile ? 0 : '8px 50px',
  },
  labelFont: {
    fontSize: 12,
    color: '#000000'
  },
  subtitle: {
    color: '#000000',
    padding: isMobile ? '8px 0 0 0' : '8px 32px 0 32px',
    fontWeight: 'bold',
    fontSize: 16
  },
  btnActivate: {
    margin: '16px 0px 0px 0px',
    height: 40,
    width: '100%',
    // backgroundColor: '#F5922F',
    // color: '#FFFFFF',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    width: isMobile ? '90%' : '100%'
  },
  divider: {
    borderTop: '.2px solid #E6EAF0', 
    width: '100%', 
    margin: '10px 0px 0px 0px',
  },
  btnSearch: {
    margin: '13px 0px 0px 0px',
    height: 40,
    width: '100%',
    // color: '#F5922F',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
    // borderColor: '#F5922F',
  },
  visaAbout: {
    color: '#54575F',
    fontSize: 16,
    padding: '4px 0px'
  },
  btnBack: {
    border: 0, 
    borderRadius: 40, 
    // color: '#F5922F', 
    fontSize: 16
  },
  msg: {
    color: '#54575F',
    padding: isMobile ? '8px 0 0 0':'8px 32px 0 32px',
    fontSize: 16
  },
  notif: {
    backgroundColor: '#FFFFFF', 
    fontSize: 14, 
    fontWeight: 'bold', 
    width: '100%', 
    color: '#E24C4C', 
    borderRadius: 4, 
    padding: '8px 16px', 
    textAlign: 'center',
    display: 'flex',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.24)',
    alignItems: 'center',
  },
  visaMobile: {
    backgroundImage: `url(${visaCard})`, 
    width: '82%', 
    height: 175, 
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    backgroundRepeat: 'no-repeat',
    display: isMobile ? 'content' : 'none',
    margin: '8px 32px',
  },
  affix: {
    position: 'fixed', 
    zIndex: '100', 
    top: '11%', 
    left: '45%'
  },
  buxCard: {
    fontWeight: '600',
    position: 'absolute', 
    color: '#FFF',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    top: '51%',
    left: '52%',
  },
  spinner: {
    height: 176,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardStyle: {
    position: 'absolute', 
    color: '#FFF',
    transform: 'translate(-50%, -50%)',
    width: '50%'
  },
}

export default UpgradePhysicalCard;