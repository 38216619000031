import { useEffect, useState } from "react";
import { axios } from "../../../../../../lib/axios";
import moment from "moment";

export function getPayouts(
  currentPage,
  searchQuery,
  selectedStatus,
  startDate,
  endDate,
  email = "",
) {
  const encodedSearchQuery = encodeURIComponent(searchQuery);

  const formattedStartDate = startDate
    ? moment(startDate, "MM-DD-YYYY").format("MM-DD-YYYY")
    : "";
  const formattedEndDate = endDate
    ? moment(endDate, "MM-DD-YYYY").format("MM-DD-YYYY")
    : "";
  const summary = formattedStartDate || formattedEndDate ? "Custom" : false;

  return axios.get(
    `api/cashbux/payout/?start=${formattedStartDate}&end=${formattedEndDate}&status=${selectedStatus}&search=${encodedSearchQuery}&summary=${summary}&email=${email}&page=${currentPage}&is_export=${email ? "true" : ""}`,
  );
}

export function usePayouts(
  currentPage,
  searchQuery,
  selectedStatus,
  startDate,
  endDate,
) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getPayouts(
          currentPage,
          searchQuery,
          selectedStatus,
          startDate,
          endDate,
        );
        setData(response);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, [currentPage, searchQuery, selectedStatus, startDate, endDate]);

  return { data, isLoading, error };
}
