import { useEffect, useState } from "react";
import { axios } from "../../../lib/axios";

/**
 * @typedef {Object} EnterprisePeer
 * @property {number} id
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} email
 */

/**
 * @typedef {Object} EnterprisePeersData
 * @property {string} tradeName
 * @property {EnterprisePeer[]} enterprisePeers
 */

/**
 * Fetches enterprise peers from the API.
 * @param {string} tradeName
 * @returns {Promise<EnterprisePeersData>}
 */
export function getEnterprisePeers(tradeName) {
  return axios.get(`api/enterprise/${tradeName}/transfer/peers`);
}

/**
 * Custom hook to fetch enterprise peers.
 * @param {string} tradeName
 * @returns {{
 *   data: EnterprisePeersData | null,
 *   isLoading: boolean,
 *   error: Error | null
 * }}
 */
export function useEnterprisePeers(tradeName) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getEnterprisePeers(tradeName);
        setData(response);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, [tradeName]);

  return { data, isLoading, error };
}
