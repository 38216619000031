import React from 'react';
import { Button, Layout, message, Typography } from 'antd';
import { isMobile } from 'react-device-detect';
import { BUx } from '../../../static/icons/bux_qr';
import NewCheckouBG from '../../../static/icons/newCheckoutBGTop.svg';
import Kajabi from '../../../static/icons/kajabi2.svg';
import * as Kaj from '../../../static/images/how_it_works/link_stores/kajabi/index';

const { Header } = Layout;

const stepStyle = {
    instruction: {
        color: '#000',
        fontWeight: '400',
        fontSize: '16px'
    },
    center: {
        display: 'flex',
        justifyContent: 'center'
    },
    textareaStyle: {
        backgroundColor: '#EEEEEE',
        width: isMobile ? '100%' : '84%',
        height: '100%',
        fontFamily: 'monospace',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#000',
        marginTop: '40px',
        border: '1px solid #EEEEEE',
        resize: 'none',
        borderRadius: '8px',
        padding: '24px'
    },
    copyBtn: {
        height: '40px',
        width: '86px',
        borderRadius: '4px',
        backgroundColor: '#F5922F',
        color: '#fff',
        border: '1px solid #F5922F',
        fontWeight: '600',
        fontSize: '16px',
        position: 'absolute',
        marginTop: '50px',
        marginLeft: '-100px'
    },
}

const kajabiCode =
    `<div style="display:flex; flex-direction:column; text-align: center;>
    <span>Fill in the details below and click "Pay via BUx" to be taken to the checkout page.</span>
  
    <!-- Styling is fully customizable → 
    <input id="bux_checkout_name" tabindex="1" placeholder="Name" 
        style="width: 400px; box-sizing: border-box; font-size:x-large;
             display: block; margin: 15px auto; padding: 10px;" />
  
    <input id="bux_checkout_email" tabindex="2" placeholder="Email" 
        style="width: 400px; box-sizing: border-box; font-size:x-large;
            display: block; margin: 15px auto; padding: 10px;" />
  
    <a id="checkout_link" href="" tabindex="3" 
     style="background-color: #f5922f; color: white; padding: 15px 25px;
          text-decoration: none; width:200px; font-weight: 700; text-align: center; 
          border-radius: 4px; display: block; margin: 20px auto; font-size: larger;
          box-shadow: 0px 3px 2px #aaaaaa;"> 
      Pay via BUx
    </a> 
  </div>`

let kajabi_code

function copyCode() {
    kajabi_code.select()
    document.execCommand("copy")
    message.destroy()
    message.info("Copied Kajabi script")
    kajabi_code.blur()
}

const steps = [
    {
        instruction: <Typography style={stepStyle.instruction}>On your <b>Kajabi</b> website, create a <b>Kajabi Offer</b> and choose <b>One-time Payment</b>.</Typography>,
        img: Kaj.Step1,
        note: 'Note: Make sure the currency is PHP - Philippine Peso'
    },
    {
        instruction: <Typography style={stepStyle.instruction}>Open the <b>Offer</b> and click <b>Get Link</b>.</Typography>,
        img: Kaj.Step2,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>Copy the <b>Offer Link</b> and paste it in notes.</Typography>,
        img: Kaj.Step3,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>Go to <b>More</b> and click <b>Webhooks</b>.</Typography>,
        img: Kaj.Step4,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>Copy the <b>Activation Webhook</b> and paste it in notes.</Typography>,
        img: Kaj.Step5,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>On your BUx Dashboard, Click <b>Link Store</b>.</Typography>,
        img: Kaj.Step6,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>Click <b>Go to offers</b> beside the Kajabi logo.</Typography>,
        img: Kaj.Step7,
    },
    {
        instruction: <div style={{ textAlign: 'left' }}>
            <Typography style={stepStyle.instruction}>On your <b>Kajabi Offers</b>, click <b>+ Add New</b> and edit offer.</Typography>
            <ul style={stepStyle.instruction}>
                <li>
                    Name can be the same as Kajabi for quick reference.
                </li>
                <li>
                    Input the Kajabi Offer Link.
                </li>
                <li>
                    Input the Kajabi Activation Webhook URL.
                </li>
                <li>
                    Input the URL of the Kajabi Thank You Page (for successful payments).
                </li>
            </ul>
            <Typography style={stepStyle.instruction}>Click <b>Save</b>.</Typography>
        </div>,
        img: Kaj.Step8,
        type: 'list'
    },
    {
        instruction: <Typography style={stepStyle.instruction}>On your <b>Kajabi Offers</b> page, open the Offer Page.</Typography>,
        img: Kaj.Step9,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>Add a <b>Custom Block</b> - this is where you’ll input the custom BUx Code.</Typography>,
        img: Kaj.Step10,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>Copy the <b>Custom Code</b> in BUx and paste it in your <b>Custom Code Block</b> on the Kajabi Offer Page.</Typography>,
        img: Kaj.Step11,
        specialInstruction: <div style={{ ...stepStyle.center, width: '100%' }}>
            <div style={{ width: isMobile ? '92%' : '664px', height: '386px', marginBottom: '24px' }}>

                <textarea style={stepStyle.textareaStyle} ref={(textarea) => kajabi_code = textarea} readOnly>
                    {kajabiCode}
                </textarea>
                <Button style={stepStyle.copyBtn} onClick={() => copyCode()}>Copy</Button>
            </div>
        </div>
    },
    {
        instruction: <div style={{ textAlign: 'left' }}>
            <Typography style={stepStyle.instruction}>You should be good to go!</Typography>
            <ul style={stepStyle.instruction}>
                <li>
                    The custom code (form) in BUx automatically embeds the name and/or email address of the user and the other details so that the customers only click pay then choose a payment option.
                </li>
                <li>
                    Feel free to customize the design of the form to match your Kajabi website theme/colors.
                </li>
                <li>
                    For easy tracking of BUx Payments/Users in your Kajabi Dashboard, add an Offer Automation.
                </li>
                <li>
                    When offer is granted {">"} Then add a tag BUx.
                </li>
                <li>
                    This will allow you to filter People based on the BUX tag.
                </li>
            </ul>
        </div>,
        type: 'list'
    },
]

const KajabiTutorial = (props) => {

    return (
        <div style={styles.root}>
            {/* <div hidden={!isMobile} style={{ zIndex: '1', width: '100%', height: '60px', display: 'flex', justifyContent: 'center', background: 'linear-gradient(102.61deg, #004888 0%, #00BBFF 100%)', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)' }}>
                <img src={BUx} style={{ maxWidth: '72px' }} alt="#" />
            </div> */}
            <div style={{ zIndex: '1', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '24px 0' }}>
                <img src={BUx} alt="BUx" />
            </div>
            <div style={styles.body}>
                <Typography style={styles.title}>How to Integrate</Typography>
                <img src={Kajabi} alt="Prestashop" />
                {
                    steps.map((step, i) => {
                        return (
                            <>
                                {step.specialInstruction && step.specialInstruction}
                                <img hidden={!step.img} src={step.img} alt={`Step ${i + 1}`} style={{ marginTop: '32px', width: isMobile && '100%' }} />
                                <div style={{ ...styles.inlineFlex, alignItems: (typeof (step.type) === "undefined" || isMobile) ? 'center' : 'flex-start', marginTop: steps.length === i + 1 && '32px' }}>
                                    <Typography style={styles.stepBtn}>Step {i + 1}</Typography>
                                    {step.instruction}
                                </div>
                                <Typography hidden={!step.note} style={styles.note}>{step.note}</Typography>
                            </>
                        )
                    })
                }
            </div>
        </div>
    )
}

const styles = {
    root: {
        backgroundColor: '#F1F2F6',
        backgroundImage: `url(${NewCheckouBG})`,
        backgroundRepeat: 'no-repeat',
        textAlign: 'center',
        width: '100%',
        paddingBottom: '2px'
    },
    body: {
        backgroundColor: '#FFF',
        borderRadius: '4px',
        padding: '40px 40px 72px 40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '80%',
        margin: '12px auto 72px auto',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)'
    },
    title: {
        color: '#000',
        fontSize: '24px',
        fontWeight: '700',
        marginBottom: '24px'
    },
    stepBtn: {
        color: '#FFF',
        fontSize: '16px',
        fontWeight: '700',
        textAlign: 'center',
        borderRadius: '10px',
        backgroundColor: '#F5922F',
        padding: '6px 8px',
        minWidth: '80px'
    },
    inlineFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '14px',
        flexDirection: isMobile ? 'column' : 'row',
        width: isMobile ? '90%' : '100%'
    },
    note: {
        color: 'rgba(43, 45, 50, 0.8)',
        fontSize: '16px',
        fontWeight: '400',
        fontStyle: 'italic'
    }
}

export default KajabiTutorial;