import React,{  } from "react";
import { Modal, List, Button, Typography } from "antd";
import ErrorIcon from '@material-ui/icons/Error';

const NewCvvModal = ({ visible, closeModal, cvv }) => {
  // const { show } = props;
  const segments = localStorage.getItem('segments');
  return (
    <Modal 
      visible={visible} 
      footer={null} 
      onCancel={closeModal} 
      maskClosable={false} 
      width={360}>
      <div style={styles.title}>
        <Typography style={styles.text}>New CVV</Typography>
        <Typography style={styles.cvv}>{cvv}</Typography>
      </div>
      <Typography style={styles.reminder}><ErrorIcon color="#F5922F" style={{fontSize: 20, marginRight: 6}} />Reminder</Typography>
      <Typography style={styles.msg}>
        Please familiarize yourself with your <strong>new CVV</strong> number. 
        We are not saving this information on our system for security purposes.
      </Typography>
      <button className={`btn--${segments}`} onClick={closeModal} style={styles.modalBtn}>Got It</button>
    </Modal>
  )
}

const styles ={ 
  title: {
    textAlign: 'center',
  },
  text: {
    color: '#2B2D33',
    fontSize: 16,
    fontWeight: '600',
    margin: '20px 0 0 0'
  },
  cvv: {
    color: '#2B2D33',
    fontSize: 28,
    fontWeight: 'bold',
    borderRadius: 10,
    border: '1px solid #E6EAF0',
    margin: '0 100px 20px 100px'
  },
  modalBtn: {
    margin: '16px 0px 0px 0px',
    height: 40,
    width: '100%',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 4
  },
  reminder: {
    color: '#F5922F',
    fontWeight: 'bold',
    padding: '8px 0px',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center'
  },
  msg: {
    color: '#000000',
    fontSize: 16
  }

}

export default NewCvvModal;