import React from 'react';
import { Typography, Card } from 'antd';
import { PaymentReqStepMob1, PaymentReqStepMob2, PaymentReqStepMob3, PaymentReqStepMob4, PaymentRequestBG, PaymentTutorialStart } from '../../../../static/new_segment/tutorials/pages_tutorials';
import { PaymentReq } from '../../../../static/icons/sidebar';
import { history } from '../../../../store/history';

const PaymentRequestTutorial = (props) => {
    const segments = localStorage.getItem('segments');
    const [step, setStep] = React.useState(0);
    const { userType } = props;

    const tutorialSteps = [
        {
            step: 0,
            bg: PaymentTutorialStart,
            title: "Payment Request",
            description: 'This tab displays all your payment request transactions and allows you to schedule payment requests.',
            margin: '32px 0 0 0'
        },
        {
            step: 1,
            bg: PaymentReqStepMob1,
            title: "Transaction Filter",
            description: 'This section allows you to filter all your transactions based on its status (Paid, Pending, Cancelled and Expired).',
            margin: '72px 0px 24px'
        },
        {
            step: 2,
            bg: PaymentReqStepMob2,
            title: "Payment Schedule",
            description: 'This button allows you to view all your scheduled payment requests and create a new one.',
            margin: '32px -66px 0 0'
        },
        {
            step: 3,
            bg: PaymentReqStepMob3,
            title: "Transaction Card Filter",
            description: 'This button allows you to search or filter all your transactions based on payment method and date.',
            margin: '32px -66px 0 0'
        },
        {
            step: 4,
            bg: PaymentReqStepMob4,
            title: "New Payment Request",
            description: 'This button in the Dashboard allows you to generate a new payment request link that you can share with your customers.',
            margin: '32px 0 0 -66px'
        },
    ]

    const addStep = () => { 
        if (step < tutorialSteps.length - 1) {
            setStep(step + 1)
        }
        else {
            setStep(step)
        }
    }

    const subtractStep = () => {
        if (step >= 1) {
            setStep(step - 1)
        }
        else {
            setStep(step)
        }
    }

    React.useEffect(() => {
        setStep(0);
    }, [props.visible])

    return (
        <div
            style={styles.root}
        >
            <Card
                style={styles.bg}
            >
                <div style={styles.contentWrapper}>
                    <img src={tutorialSteps[step].bg} alt={tutorialSteps[step].title} style={{ margin: tutorialSteps[step].margin }} />
                    <div style={{ paddingTop: '24px', gap: '12px', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className={`bg-${segments}`} style={styles.icon} hidden={step > 0}>
                            <img src={PaymentReq} alt="Payment Request" />
                        </div>
                        <Typography style={styles.title}>{tutorialSteps[step].title}</Typography>
                    </div>
                    <Typography style={styles.msg}>{tutorialSteps[step].description}</Typography>
                </div>
                <button
                    className={`btn--${segments}`}
                    onClick={() => setStep(1)}
                    hidden={step > 0}
                    style={{ width: '100%', height: '40px', marginTop: '10px' }}
                >
                    Next
                </button>
                <div style={styles.footerBtn} hidden={step === 0 || (step + 1 >= tutorialSteps.length)}>
                    <button className={`outline-btn--${segments}`} style={styles.cancelBtn} onClick={() => subtractStep()} >Previous</button>
                    <button className={`btn--${segments}`} style={{ ...styles.saveBtn }}
                        onClick={() => addStep()} >
                        Next
                    </button>
                </div>
                <button
                    className={`btn--${segments}`}
                    onClick={() => history.goBack()}
                    style={{ ...styles.saveBtn }}
                    hidden={step + 1 < tutorialSteps.length}
                >
                    All good
                </button>
            </Card>
        </div>
    )
}

const styles = {
    root: {
        height: '100vh',
        backgroundColor: '#F4F6F9',
        paddingTop: 1,
    },
    title: {
        color: '#2B2D3',
        fontSize: 28,
        fontWeight: '700',
        textAlign: 'center',
    },
    bg: {
        backgroundImage: `url(${PaymentRequestBG})`,
        // backgroundPosition: 'top auto',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '16px',
    },
    contentWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        background: 'transparent'
    },
    msg: {
        color: '#2B2D3',
        fontSize: 16,
        textAlign: 'center',
        padding: '24px 0',
        fontWeight: '400'
    },
    btn: {
        height: 48,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 4,
        width: '100%',
    },
    input: {
        padding: '11px 16px 11px 16px',
        fontSize: 16,
        height: 48,
        margin: '4px 0px 12px 0px',
        color: '#2B2D3'
    },
    saveBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: '100%',
        cursor: 'pointer',
        color: '#FFF',
        border: 0
    },
    cancelBtn: {
        cursor: 'pointer',
        borderRadius: 4,
        height: 40,
        fontSize: 16,
        fontWeight: '500',
        padding: '8px 16px',
        width: '100%',
        marginRight: 8
    },
    footerBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '12px 0',
        width: '100%',
        gap: '12px',
    },
    icon: {
        padding: '8px',
        borderRadius: '8px'
    }
}

export default PaymentRequestTutorial;