import { useState } from "react";
import { axios } from "../../../lib/axios";

export default function SegmentFeesViewModel() {
    const [success, setSuccess] = useState(false);
    const [messageDisplay, setMessageDisplay] = useState("");
    const [segmentFeeData, setSegmentFeeData] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingChannel, setIsLoadingChannel] = useState(false);
    
    const headers = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }

    async function fetchSegmentFeeList(searchString, page) {
        setIsLoading(true);
        try {
            const response = await axios.get(`api/admin/segment_fees/?page=${page}&search=${searchString}`);
            setSegmentFeeData(response);
            setSuccess(true);
            setIsLoading(false);
        } catch (error) {
            setMessageDisplay("Failed to fetch segment fee list");
            setSuccess(false);
            setIsLoading(false);
        }
    }

    async function addSegmentFee(params) {
        setMessageDisplay("");
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + `/api/admin/segment_fees/`, params, headers);
            setSuccess(true);
            setMessageDisplay(response.message);
            setIsLoading(false);
        } catch (error) {
            setSuccess(false);
            if (error.response.data.message != "") {
                setMessageDisplay(error.response.data.message);
            } else {
                setMessageDisplay("Failed to create segment fee");
            }
            setIsLoading(false);
        }
    }

    async function editSegmentFee(params, id) {
        setMessageDisplay("");
        try {
            const response = await axios.patch(process.env.REACT_APP_API_URL + `/api/admin/segment_fees/${id}/`, params, headers);
            setSuccess(true);
            setMessageDisplay(response.message);
            setIsLoading(false);
        } catch (error) {
            setSuccess(false);
            if (error.response.data.message != "") {
                setMessageDisplay(error.response.data.message);
            } else {
                setMessageDisplay("Failed to update segment fee");
            }
            setIsLoading(false);
        }
    }

    async function fetchChannel() {
        setMessageDisplay("");
        setIsLoadingChannel(true);

        try {
            const response = await axios.get(`api/admin/segment_fees/channels/`);
            if (response.data) {
                let channelArray = [];

                response.data.map((item) => {
                    channelArray.push({"name": item, "value": item });
                });

                setChannelList(channelArray);
                setIsLoadingChannel(false);
            }
            
            setSuccess(true);
        } catch (error) {
            setMessageDisplay("Failed to fetch channel list");
            setSuccess(false);
            setIsLoadingChannel(false);
            setIsLoading(false);
        }
    }

    return {
        fetchSegmentFeeList,
        addSegmentFee,
        editSegmentFee,
        fetchChannel,
        channelList,
        segmentFeeData,
        success,
        messageDisplay,
        isLoading,
        isLoadingChannel,
    }
}