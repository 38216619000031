import React from 'react';
import { Dropdown, Icon, Menu, Table, message, Pagination, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ActionModal from './actionModal';
import endorsementServices from './endorsementServices';

const EndorementTable = ({data, onUpdate, isLoading, length, selected, sort}) => {
  const currentPage = useSelector(state => state.fraud.tablePage);
  const [minPage, setMinPage] = React.useState(0);
  const [maxPage, setMaxPage] = React.useState(20);
  const [loading, setLoading] = React.useState(false);
  const userType = localStorage.getItem('userType');
  const showApprover = selected === 'Approved' || selected === 'Rejected';
  const [showActionModal, setShowActionModal] = React.useState(false);
  const [ID, setID] = React.useState(null);
  const [actionType, setActionType] = React.useState(null);
  const actionHidden = (selected === 'Approved' || selected === 'Rejected' || !['AD', 'AP', 'BS', 'EN'].includes(userType));

  const EndorsementColumns = [
    {
      title: 'Trans ID',
      dataIndex: 'approval_id',
    },
    {
      title: 'Reference No.',
      dataIndex: 'ref_no',
      render: (text, record) => <Typography>{text? text : 'None'}</Typography>
    },
    {
      title: selected === 'Approved' ? 'Previous Status' : 'Current Status',
      dataIndex: 'previous_status',
      render: (text, record) => <Typography>{returnStatus(text)}</Typography>
    },
    {
      title: 'Target Status',
      dataIndex: 'target_status',
      render: (text, record) => <Typography>{returnStatus(text)}</Typography>
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transaction_type',
      render: (text, record) => <Typography>{text === 'PR' ? 'Payment Request' : 'Payout'}</Typography>
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (text, record) => <Typography style={{whiteSpace: 'nowrap'}}>&#8369; {text}</Typography>
    },
    { 
      title: 'Endorser',
      dataIndex: 'endorser',
      render: (text, record) => <Typography>{text}</Typography>
    },
    !showApprover? {} : 
    { 
      title: 'Approver',
      dataIndex: 'approver',
      render: (text, record) => <Typography>{text}</Typography>
    }, 
    { 
      title: 'Approval Status',
      dataIndex: 'approval_step',
      render: (text, record) => <Typography style={text == 'AP'? styles.positive : text == 'RE'? styles.negative : styles.pending}>{text == 'AP'? 'Approved' : text == 'RE' ? 'Rejected' : 'Pending' }</Typography>
    },
    {
      title: 'Action',
      render: (record) => (
        <Dropdown overlay={() =>
          <Menu hidden={actionHidden}>
            <Menu.Item hidden={record.approval_step !== 'PE'} onClick={() => {setID(record.id); setActionType('Approve'); setShowActionModal(true); }}><a>Approved</a></Menu.Item>
            <Menu.Item hidden={record.approval_step !== 'PE'} onClick={() => {setID(record.id); setActionType('Reject'); setShowActionModal(true);}}><a>Reject</a></Menu.Item>
          </Menu>} placement="bottomCenter" trigger='click'>
          <Icon type="ellipsis" />
        </Dropdown>
      )
    }
  ]

  const returnStatus = (key) => {
    switch (key) {
      case 'PE':
        return 'Pending';
      case 'PA':
        return 'Paid';
      case 'FA':
        return 'Failed';
      case 'SU':
        return 'Success';
      case 'CA':
        return 'Cancelled';
      case 'EX':
        return 'Expired';
      default:
        return key;
    }
  }

  const success = (msg) => {
    message.success(msg);
  };

  const errorPrompt = (msg) => {
    message.error(msg);
  };

  const handleAction = async (status) => {
    const param = {id: ID, status: status}
    setLoading(true);
    try {
      const res = await endorsementServices.updateEndorsementStatus(param)
      if(res.status==='success'){
        success(res.message);
        onUpdate();
        setShowActionModal(false);
      } else {
        errorPrompt(res.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      errorPrompt(error);
    }
  }

  const returnType = () =>  {
    switch (selected) {
      case 'Payment Request':
        return 'payment';
      case 'Payout':
        return 'payout';
      default:
        return 'payment';
    }
  }

  const changePage = (page, pageSize) => {
    // setMaxPage(page * pageSize)
    // setMinPage((page - 1) * pageSize)
    // dispatch(fraudActions.setTablePage(page));
    // handleChangePage(page);
  }

  React.useEffect(() => {
    setLoading(true);
    setTimeout(()=> {
      setLoading(false);
    },100)
  }, [])

  return (
    <div>
      <Table 
        rowClassName="table-row-light"
        columns={EndorsementColumns}
        dataSource={data ? data : []}
        pagination={{pageSize:20}}
        loading={isLoading || loading}
        pagination={false}
      />
      <Pagination
        size='small'
        total={length}
        defaultPageSize={20}
        defaultCurrent={1}
        current={currentPage}
        onChange={(page, pageSize) => changePage(page, pageSize)}
        style={{padding:'12px 0 8px 0', textAlign: 'right'}}
      />
      <ActionModal visible={showActionModal} closeModal={()=> setShowActionModal(false)} onClick={(status)=> handleAction(status)} type={actionType} />
    </div>
  ) 
}

const styles = {
  positive: {
    borderRadius: 4,
    padding: '4px 8px',
    backgroundColor: 'rgb(232, 250, 243)',
    border: '1px solid #1DD28B',
    borderLeft: '5px solid #1DD28B',
  },
  negative: {
    borderRadius: 4,
    padding: '4px 8px',
    backgroundColor: '#f9dbdb',
    border: '1px solid #E24C4C',
    borderLeft: '5px solid #E24C4C',
  },
  pending: {
    borderRadius: 4,
    padding: '4px 8px',
    backgroundColor: 'rgb(253 234 215)',
    border: '1px solid #F5922F',
    borderLeft: '5px solid #F5922F',
  }
}

export default EndorementTable;