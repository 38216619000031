import { useEffect, useState } from "react";
import { axios } from "../../../lib/axios";

export function getRemittance(id){
    return axios.get(`api/remittances/${id}`);
}

export function useRemittance(id){
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await getRemittance(id);
                setData(response.data);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };

        fetchData();

        return () => {};
    }, [id]);

    return { data, isLoading, error };
}