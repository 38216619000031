import React, { useEffect, setState} from "react";
import axios from "axios";
import fundStyle from "../../../static/css/Funds";
import MetaTag from "../../meta_tag/meta_tag";
import { Spin, message, Button, DatePicker, Input, Icon, Table, Dropdown, Menu, Modal, notification, Pagination, Select, Divider } from "antd";
import { history } from "../../../store/history";
import { Handle401 } from "../../handle401/handle401";
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'
import PaymentModal from './paymentModal'
import TotalPaymentModal from './totalPaymentModal'
import { randomize } from '../../constants/constants';
import ActionModal from "./actionModal";
import ClearFilter from '../filter_btns/clear_filter_btn';
import { useDispatch, useSelector } from "react-redux";
import UploadModal from './upload_modal';
import PaymentMethodsPopup from '../../../screens/common/payment_methods_popup';

const ButtonGroup = Button.Group;
const { Option } = Select

const Fund = props => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (
            localStorage.getItem("jwtToken") === "" ||
            localStorage.getItem("jwtToken") == null
        ) {
            history.push("/login");
        } else {
            if (userType == "ME") {
                history.push("/dashboard");
            } else if (userType == "PS") {
                history.push("/orders");
            } else if (userType == "PF") {
                history.push("/recon");
            } else if (userType == "MA") {
                history.push("/overview");
            } else if (userType == "KC") {
                history.push("/submissions");
            } else {
                fetchCounts();
                if (userType == "AP" || userType == "EN") {
                    fetchCollections("", statusSelected, 1, "", "", 10)
                }
                fetchReprocess(searchValue, 1, 10)
            }
        }
    }, []);

    //variables
    var userType = localStorage.getItem("userType")
    var segments = localStorage.getItem("segments")
    var yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    };

    const f = fundStyle();

    const dateFormat = 'MM-DD-YYYY';
    const status = ["Checking", "Approval", "Reprocess", "Audit"]

    //variables state
    //strings
    const [statusSelected, setStatusSelected] = React.useState(userType == "EN" ? "Checking" : userType == "AP" ? 'Approval' : userType == "AD" ? 'Reprocess' : '')
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [searchValue, setSearchValue] = React.useState('');
    const [remarks, setRemarks] = React.useState('');
    const [collectionCounts, setCollectionCounts] = React.useState({ "Checking": 0, "Approval": 0, "Reprocess": 0, "Audit": 0 });
    const [cutoff, setCutoff] = React.useState('');
    //Payout Selection
    const [payoutSelection, setPayoutSelection] = React.useState('all')
    //Payment Method Filter
    const [selectedChannelValues, setSelectedChannelValues] = React.useState('');

    //nums
    const [totalPayout, setTotalPayout] = React.useState(0)
    const [adjustAmount, setAdjustAmount] = React.useState("0")
    const [selectedID, setSelectedID] = React.useState(0)
    const [totalMerchant, setTotalMerchant] = React.useState(0)
    // const [forCheckingCount, setForCheckingCount] = React.useState(0);
    // const [forApprovalCount, setForApprovalCount] = React.useState(0);
    // const [forReprocessCount, setForReprocessCount] = React.useState(0);
    const [totalCollectionCount, setTotalCollectionCount] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10)
    const [currentPage, setCurrentPage] = React.useState(1);
    const [paymentHistoryId, setPaymentHistoryId] = React.useState(null);

    //arrays
    const [rowsCheckedOnTable, setRowsCheckedOnTable] = React.useState([])
    const [rowsCheckedData, setRowsCheckedData] = React.useState([])
    const [selectedIDs, setSelectedIDs] = React.useState([])
    const [txnHistorySingle, setTxnHistorySingle] = React.useState([]);
    const [txnHistoryMultiple, setTxnHistoryMultiple] = React.useState([]);
    const [fundsData, setFundsData] = React.useState([]);
    const [reprocessData, setReprocessData] = React.useState([]);

    //booleans
    const [hideClear, setHideClear] = React.useState(true);
    const [ready, setReady] = React.useState(false)
    const [disabledBtn, setDisabledBtn] = React.useState(true);
    const [showPaymentModal, setShowPaymentModal] = React.useState(false);
    const [showTotalModal, setShowTotalModal] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [rejectSelected, setRejectSelected] = React.useState(false);
    const [showSpin, setShowSpin] = React.useState(true);
    const [businessName, setBusinessName] = React.useState("")
    const [adjustModal, showAdjustModal] = React.useState(false);
    const [showUploadCSVModal, setShowUploadCSVModal] = React.useState(false);

    useEffect(() => {
        hideClear && fetchCounts();
    }, [hideClear])

    //functions
    function disabledStartDates(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }

    function disabledEndDates(current) {
        // Cannot select days in the future
        let isDisabled = current && current > moment().endOf('day');

        if (startDate) {
            // Cannot select days before the start date, if available
            isDisabled = isDisabled || current < moment(startDate, dateFormat).startOf('day');
        }

        return isDisabled;
    }

    function onStartDateChange(date, dateString) {
        setStartDate(dateString);
        setDisabledBtn(false)
        setHideClear(false)
    }

    function onEndDateChange(date, dateString) {
        setEndDate(dateString);
        setDisabledBtn(false)
        setHideClear(false)
    }

    function closePaymentModal() {
        setShowPaymentModal(false)
        setShowTotalModal(false)
        setShowModal(false)
        setTxnHistorySingle([]);
        setTxnHistoryMultiple([]); 
    }

    function onPageChange(selectedPage) {
        clearTableSelection();
        setCurrentPage(selectedPage);
        if (statusSelected == "Reprocess") {
            fetchReprocess(searchValue, selectedPage, pageSize)
        }
        else {
            fetchCounts()
            fetchCollections(searchValue, statusSelected, selectedPage, startDate, endDate, pageSize);
        }
    }

    function onPageSizeChange(ev) {
        setPageSize(ev);
        setCurrentPage(currentPage);
        clearTableSelection();
        if (statusSelected == "Reprocess") {
            fetchReprocess(searchValue, currentPage, ev);
        }
        else {
            fetchCounts()
            fetchCollections(searchValue, statusSelected, currentPage, startDate, endDate, ev);
        }
    }

    function applyFilter() {
        fetchCounts()
        if (statusSelected == "Reprocess") {
            fetchReprocess(searchValue, 1, pageSize)
        }
        else {
            fetchCollections(searchValue, statusSelected, 1, startDate, endDate, pageSize);
        }
    }

    async function clearFilter() {
        setPayoutSelection("all")
        setSearchValue("")
        setStartDate("")
        setEndDate("")
        setHideClear(true)
        setDisabledBtn(true)
        // console.log(`Payout Selection value ${payoutSelection} before fetch count`);
        // fetchCounts()

        if (userType == "EN") {
            setStatusSelected("Checking")
            fetchCollections("", "Checking", 1, "", "", 10)
        }

        else if (userType == "AP") {
            setStatusSelected("Approval")
            setPageSize(10)
            fetchCollections("", "Approval", 1, "", "", 10)
        }

        else {
            setStatusSelected("Reprocess")
            setPageSize(10)
            fetchReprocess("", 1, 10)
        }
    }

    async function genericAxiosRequest(http_method, target_url, params = undefined) {
        try {
            let response = await axios({
                method: http_method,
                url: process.env.REACT_APP_API_URL + target_url,
                data: params,
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            });
            return response.data;

        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    Handle401()
                } else {
                    throw new Error(error.response.data.message);
                }
            } else {
                throw new Error('No response from backend server!');
            }
        }
    }

    async function resendReport(enterpriseCollection) {
        const endpoint_url = `/api/admin/enterprise_collection/payouts/`;
        const params = {
            target_id: enterpriseCollection.id,
        };

        try {
            let response = await genericAxiosRequest('put', endpoint_url, params);
            if (response.status === 'failed') {
                throw new Error(response.message);
            }

            message.success(response.message);

        } catch (err) {
            message.destroy();
            message.error(err.message);
        }
    }

    async function exportTable() {
        // let options = { 
        //     filename: 'Funds_'+moment().format("MM-DD-YYYY_HH:mm:ss")+'_'+statusSelected,
        //     fieldSeparator: ',',
        //     quoteStrings: '"',
        //     decimalSeparator: '.',
        //     showLabels: true, 
        //     showTitle: true,
        //     title: 'BUx Funds',
        //     useTextFile: false,
        //     useBom: true,
        //     useKeysAsHeaders: true
        // };

        // let csvExporter = new ExportToCsv(options);
        // let data_for_csv = statusSelected !== "Reprocess"
        //                                       ? fundsData
        //                                       : reprocessData;

        // if (data_for_csv.length) {
        //     csvExporter.generateCsv(data_for_csv);
        // }
        const endpoint_url = process.env.REACT_APP_API_URL + "/api/admin/enterprise_collection/";
        let query_params = [
            'type=collections',
            'status=' + statusSelected,
            'export=1',
            'start=' + startDate,
            'end=' + endDate,
            'collection_type=' + payoutSelection,
            'payment_methods=' + selectedChannelValues,
        ];
        const full_url = endpoint_url + '?' + query_params.join("&");

        try {
            let response = await axios.get(full_url, yourConfig);
            message.success(response.message);

        } catch (error) {
            if (error.response && error.response.data) {
                message.error(error.response.data.message, 10);
            } else if (error.response && error.response.status == 401) {
                Handle401();
            }
        }
    };

    const setChannelWrapper = (channels) => {
        setSelectedChannelValues(channels)
        setHideClear(false)
        setDisabledBtn(false)
    }

    const forReprocessingColumns = [
        {
            title: "Business Name",
            // width: '20%',
            render: (text, record) => {
                return (
                    <div>
                        <div className={f.descSyle}><b>{record.belongs_to}</b></div>
                    </div>)
            }
        },
        {
            title: "Account Details",
            // width: '20%',
            render: (record) => {
                return (
                    <div>
                        <div className={f.descSyle}><b>{record.bank}</b></div>
                        <div className={f.descSyle}>{record.account_name}</div>
                        <div>{record.account_number}</div>
                    </div>
                )
            }
        },
        {
            title: "Amount",
            // width: '25%',
            dataIndex: 'amount'
        },
        {
            title: 'Date Processed',
            // width: '25%',
            dataIndex: 'date_paid'
        }
    ]

    const workflowColumns = [
        {
            title: "Business Name",
            // width: 200,
            render: (text, record) => {
                return (
                    <div>
                        <div className={f.descSyle}><b>{record.user}</b></div>
                    </div>)
            }
        },
        {
            title: 'Amount',
            // width: 200,
            render: (text, record) => {
                return (
                    <div className={f.descSyle}>{"Php " + parseFloat(record.amount.replace(/,/g, '')).toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
                )
            }
        },
        {
            title: 'Bank Details',
            // width: 300,
            render: (text, record) => {
                return (
                    <div>

                        <div className={f.descSyle}><b>{record.bank}</b></div>
                        <div className={f.descSyle}>{record.account_name}</div>
                        <div>{record.account_number}</div>

                    </div>
                )
            }
        },
        {
            title: "Date Computed",
            dataIndex: "created_at"
        },
        {
            title: "Basis Date",
            // width: 200,
            render: (record) => {
                return (
                    <div>
                        {record.basis_date ? record.basis_date : ''}
                    </div>
                )
            }
        },
        {
            title: "Settlement Date",
            // width: 200,
            render: (record) => {
                return (
                    <div>
                        {(record.is_paid_out && record.payout_date) ? record.payout_date.split(",")[0] : 'Unpaid'}
                        <br />
                        {(record.is_paid_out && record.payout_date) ? record.payout_date.split(",")[1] : ''}
                    </div>
                )
            }
        },
        {
            title: "Remarks",
            // width: 200,
            render: (record) => {
                return (
                    <div>
                        {record.remarks}
                    </div>
                )
            }
        },
        {
            title: 'Action',
            render: (text, record) => (
                <div>
                    <Dropdown overlay={() => menu(record)}
                        disabled={record.step < 8}
                        placement="bottomCenter" trigger={['click']} >
                        <Icon type="ellipsis" />
                    </Dropdown>
                </div>
            )
        }
    ];

    const clearTableSelection = () => {
        setRowsCheckedOnTable([])
        recomputeTotals([])
        setSelectedIDs([])
    };

    const rowSelected = {
        selectedRowKeys: rowsCheckedOnTable,
        onChange: (selectedRowKeys, selectedRows) => {
            let c_ids = selectedRows.map((item) => {
                return item.id
            });
            setRowsCheckedData(selectedRows)
            setRowsCheckedOnTable(selectedRowKeys)

            setSelectedIDs(c_ids)
            recomputeTotals(c_ids)

        },
    };

    const recomputeTotals = (selected_ids) => {
        let total_amount = 0, merchant_count = 0

        if (statusSelected == "Reprocess") {
            for (let id of selected_ids) {
                let captured_row = reprocessData.find(x => x.id == id)
                total_amount += parseFloat(captured_row.amount.replace(/,/g, ''))
            }

            merchant_count = selected_ids.length
        }

        else {
            let unique_merchants = new Set()

            for (let id of selected_ids) {
                let captured_row = fundsData.find(x => x.id == id)
                unique_merchants.add(captured_row.user)
                total_amount += parseFloat(captured_row.amount.replace(/,/g, ''))
            }

            merchant_count = unique_merchants.size
        }
        setTotalMerchant(merchant_count)
        setTotalPayout(total_amount)
    };

    let menu = (record) => (
        <Menu style={{ width: '190px' }}>
            <Menu.Item key="0" onClick={() => paymentModal(record)}>
                Payment History
            </Menu.Item>
            <Menu.Item
                key="1"
                onClick={() => resendReport(record)}
                hidden={statusSelected !== 'Audit'}
            >
                Resend Settlement Email
            </Menu.Item>
            <Menu.Item
                key="2"
                onClick={() => downloadReport(record)}
                hidden={statusSelected !== 'Audit'}
            >
                Download Settlement Report
            </Menu.Item>
            <Menu.Item
                key="3"
                onClick={() => { setSelectedID(record.id); showAdjustModal(true); }}
                hidden={statusSelected !== 'Checking'}
            >
                Adjust Payout Amount
            </Menu.Item>
        </Menu>
    );


    async function downloadReport(enterpriseCollection) {
        if(enterpriseCollection.settlement_report != null){
            window.open(enterpriseCollection.settlement_report, '_blank')
        }
    }


    let totalMenu = () => (
        <Menu style={{ width: '180px' }}>
            <Menu.Item key="0" onClick={() => totalModal()}>
                Total Payment History
            </Menu.Item>
        </Menu>
    );

    //async functions
    async function paymentModal(record) {
        setPaymentHistoryId([record.id]);
        setBusinessName(record.user)
        let transactions = await fetchTransactions([record.id]);
        setTxnHistorySingle(transactions && transactions['results'].hasOwnProperty(record.id) ? transactions : [])
        setShowPaymentModal(true)
    }

    const onHistoryChangePage = async (page) => {
        let transactions = await fetchTransactions(paymentHistoryId, page, 10);
        setTxnHistorySingle(transactions && transactions['results'].hasOwnProperty(paymentHistoryId) ? transactions : [])
    }

    async function totalModal() {
        const ids_to_query = rowsCheckedData.map(row => row.id);
        let transactions = await fetchTransactions(ids_to_query);

        const multipleHistoryData = ids_to_query.map(ec_id => {
            let matchedRow = fundsData.find(x => x.id == ec_id);

            return {
                ...matchedRow,
                transaction_list: transactions.hasOwnProperty(ec_id) ? transactions[ec_id] : []
            }
        });

        setTxnHistoryMultiple(multipleHistoryData);
        setShowTotalModal(true)
    }

    async function fetchTransactions(ids_to_query, page = 1, page_size = 10) {
        if(!page_size) {
            page_size = 10;
        }
        const fetch_type = 'transactions'
        const endpoint_url = process.env.REACT_APP_API_URL + "/api/admin/enterprise_collection/"
        let query_params = [
            'type=' + fetch_type,
            'page=' + page,
            'page_size=' + page_size,
            'ids=[' + ids_to_query.join() + ']'
        ];
        const full_url = endpoint_url + '?' + query_params.join("&");

        try {
            let response = await axios.get(full_url, yourConfig);
            return response.data;

        } catch (error) {
            if (error.response && error.response.data) {
                message.error(error.response.data.message, 10);
                return {}
            } else if (error.response && error.response.status == 401) {
                Handle401();
            }
        }
    }

    async function fetchCounts() {
        const fetch_type = 'counts'
        const endpoint_url = process.env.REACT_APP_API_URL + "/api/admin/enterprise_collection/"
        let query_params = [
            'type=' + fetch_type,
            'collection_type=' + payoutSelection,
            'payment_methods=' + selectedChannelValues,
        ];
        const full_url = endpoint_url + '?' + query_params.join("&");

        try {
            let response = await axios.get(full_url, yourConfig);
            setCollectionCounts({
                Checking: response.data.checking,
                Approval: response.data.approval,
                Audit: response.data.audit,
            });
            setReady(true);
        } catch (error) {
            if (error.response && error.response.data) {
                message.error(error.response.data.message, 10);
            } else if (error.response && error.response.status == 401) {
                Handle401();
            }
        }
    }

    async function adjustSubmit(){

        let params = {
            id: selectedID,
            amount: adjustAmount
        }

        try {
            let response = await axios.post(
                process.env.REACT_APP_API_URL + "/api/admin/enterprise_collection/adjust/",
                params,
                yourConfig
            );


            fetchCounts();
            setCurrentPage(1);
            showAdjustModal(false);
            message.success("Amount Adjusted", 6)
            fetchCollections(searchValue, "Checking", 1, "", "");
        } catch (error) {

                if(error.response){
                    message.error(error.response.data, 10)
                }
      
                else if (error.response && error.response.status == 401) {
                    Handle401();
                }
        }
        showAdjustModal(false);
        setAdjustAmount(0);
    }
    async function fetchReprocess(search, page_number, page_size=10) {

        let fetch_type = 'payouts'
        const endpoint_url = process.env.REACT_APP_API_URL + "/api/admin/enterprise_collection/payouts/";
        let query_params = [
            'type=' + fetch_type,
            'collection_type=' + payoutSelection,
            'payment_methods=' + selectedChannelValues,
            'search=' + search,
            'page=' + page_number,
            'page_size=' + page_size
        ]
        const full_url = endpoint_url + '?' + query_params.join("&");
        try {
            let response = await axios.get(full_url, yourConfig);
            let x = collectionCounts;
            x['Reprocess'] = response.data.total;
            setCollectionCounts(x);
            setReprocessData(response.data.results);
            setTotalCollectionCount(response.data.total);

        } catch (error) {
            if (error.response && error.response.data) {
                message.error(error.response.data.message, 10);
                setFundsData([]);
            } else if (error.response && error.response.status == 401) {
                Handle401();
            }
        }
    }

    async function fetchCollections(search, approval_status, page_number, start_date, end_date, page_size) {

        if (!approval_status) {
            return;
        }

        if(!page_size) {
            page_size = 10;
        }

        const fetch_type = 'collections';
        const endpoint_url = process.env.REACT_APP_API_URL + "/api/admin/enterprise_collection/";
        let query_params = [
            'type=' + fetch_type,
            'collection_type=' + payoutSelection,
            'payment_methods=' + selectedChannelValues,
            'search=' + search,
            'status=' + approval_status,
            'page=' + page_number,
            'page_size=' + page_size,
            'start=' + start_date,
            'end=' + end_date
        ];
        const full_url = endpoint_url + '?' + query_params.join("&");

        try {
            let response = await axios.get(full_url, yourConfig);
            setFundsData(response.data.results);
            setTotalCollectionCount(response.data.total);
            setReady(true);

        } catch (error) {
            if (error.response && error.response.data) {
                message.error(error.response.data.message, 10);
                setFundsData([]);
            } else if (error.response && error.response.status == 401) {
                Handle401();
            }
        }
    }

    return (
        <div hidden={!ready}>
            <div className={f.mainBody}>
                <MetaTag title="Payout Selection" />
                <PaymentModal segments={segments} transactions={txnHistorySingle} openModal={showPaymentModal} closeModal={closePaymentModal} businessName={businessName} title={"Payment History"} onChangePage={onHistoryChangePage} ecID={paymentHistoryId} />
                <TotalPaymentModal totalHistory={txnHistoryMultiple} openModal={showTotalModal} closeModal={closePaymentModal} title={"Payment History"} />
                <ActionModal
                    openModal={showModal}
                    closeModal={closePaymentModal}
                    statusSelected={statusSelected}
                    rejectSelected={rejectSelected}
                    selectedIDs={selectedIDs}
                    clearTableSelection={clearTableSelection}
                    setCurrentPage={setCurrentPage}
                    fetchReprocess={fetchReprocess}
                    fetchCounts={fetchCounts}
                    fetchCollections={fetchCollections} />

                <Modal
                    title="Adjust Amount"
                    width={350}
                    visible={adjustModal}
                    onCancel={()=>showAdjustModal(false)}
                    footer={null}>

                    <div style={{marginTop: 16}}>
                        <div style={{marginBottom: 8, fontSize: 12, lineHeight: '24px'}}>
                            Enter new amount
                        </div> 

                    <Input
                        placeholder="Amount"
                        style={{width: '100%'}}
                        value={adjustAmount}
                        onChange={e => { setAdjustAmount(e.target.value);}}
                    />
                    </div>

                    <div style={{marginTop: 32, display: 'flex', justifyContent: 'space-between'}}>
                    <Button style={{
                                    marginRight: '16px',
                                    width: '50%',
                                    fontWeight: 600,
                                    backgroundColor: "#f5f5f4",
                                    border: "#2b2d33",
                                    height: "40px",
                                    borderRadius: "10px",
                    }}
                    onClick={() => showAdjustModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        style={{
                            width: '50%',
                            backgroundColor: rejectSelected ? "#d9534f" : "#1DD28B",
                            border: rejectSelected ? "#d9534f" : "#1DD28B",
                            height: "40px",
                            borderRadius: "10px",
                            opacity: !showSpin ? '0.5' : '',
                            cursor: !showSpin ? 'not-allowed' : 'pointer'
                        }}

                        onClick={() => adjustSubmit()}
                    >
                    Submit
                    </Button>
                    </div>
                </Modal>
                <div className={f.orderStyle}>Payout Selection</div>
                <div className={f.pillFlex}>
                    <ButtonGroup>
                        {status.map((item, i) => {
                            return (
                                <Button
                                    key={item.toLocaleLowerCase()}
                                    className={f.pillBtn}
                                    onClick={() => {
                                        clearTableSelection();
                                        setStatusSelected(item);
                                        setPageSize(pageSize ? pageSize : 10)
                                        setCurrentPage(1);
                                        if (item == 'Reprocess') {
                                            fetchReprocess(searchValue, 1, pageSize)
                                        }
                                        else {
                                            fetchCollections("", item, 1, startDate, endDate, pageSize);
                                        }
                                    }}
                                    style={{
                                        borderTopLeftRadius: item == "Checking" ? "10px" : "0px",
                                        borderBottomLeftRadius: item == "Checking" ? "10px" : "0px",
                                        borderTopRightRadius: item == "Audit" ? "10px" : "0px",
                                        borderBottomRightRadius: item == "Audit" ? "10px" : "0px",
                                        color: statusSelected === item ? '#fff' : '#2b2d33',
                                        backgroundColor: statusSelected === item ? 'rgb(10, 49, 94)' : '#fff',
                                    }}
                                >
                                    {item}
                                    ({collectionCounts[item]})
                                </Button>
                            );
                        })}
                    </ButtonGroup>


                    <div>
                        <button
                            hidden={statusSelected != "Checking"}
                            style={{
                                marginRight: '12px'
                            }}
                            className={f.exportBtn}
                            onClick={()=>setShowUploadCSVModal(true)}>
                            <div>
                                <Icon type="upload" style={{ paddingRight: 4 }} />
                                Upload CSV
                            </div>
                        </button>

                        <button
                            className={f.exportBtn}
                            onClick={() => exportTable()}>
                            <div>
                                <Icon type="download" style={{ paddingRight: 4 }} />
                                Download CSV
                            </div>
                        </button>
                        <UploadModal handleRefresh={() => fetchCollections("", "Checking", 1, "", "")} handleClose={()=>setShowUploadCSVModal(false)} modal={showUploadCSVModal} visible={showUploadCSVModal}/>
                    </div>
                </div>
                

                <div className={f.tableDiv}>
                    <div className={f.pillFlex}>
                        <div style={{ display: 'flex' }}>
                            <ClearFilter hideFilterBtn={hideClear} clearFilter={() => {clearFilter()}} />
                        </div>

                        <div hidden>
                            <div className={f.noteStyle}><Icon type="info-circle" theme="filled" style={{ paddingRight: 4 }} />Click <b>Apply</b> button to show results.</div>
                        </div>
                    </div>
                    
                    <div style={{ display: 'flex', justifyContent:'flex-start' }}>
                        <span>
                            <Select 
                                size="large"
                                value={payoutSelection}
                                onChange={(e) => {setPayoutSelection(e); setDisabledBtn(false); setHideClear(false)}}
                                style={{width: '128px', zIndex: 1, color: '#000', marginRight: '12px'}}
                                >
                                <Option value="all">All</Option>
                                <Option value="enterprise">Enterprise</Option>
                                <Option value="multiwallet" >Multiwallet</Option>
                            </Select> 
                        </span>
                        <span>
                            <PaymentMethodsPopup reset={hideClear} setChannels={setChannelWrapper}/> 
                        </span>
                        { 
                            statusSelected !== "Reprocess" && 
                            <span hidden={statusSelected === "Reprocess"} style={{ marginRight: 12 }}>
                                <DatePicker
                                    format={dateFormat}
                                    disabledDate={disabledStartDates}
                                    size="large"
                                    onChange={onStartDateChange}
                                    placeholder='Start Date'
                                    style={style.datePic}
                                    suffixIcon={<div style={style.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                                    allowClear={false}
                                    disabled={statusSelected === "Reprocess"} />
                                <span style={{ padding: '8px 8px 0 8px' }}>-</span>
                                <DatePicker
                                    format={dateFormat}
                                    disabledDate={disabledEndDates}
                                    size="large"
                                    onChange={onEndDateChange}
                                    placeholder='End Date'
                                    style={style.datePic}
                                    suffixIcon={<div style={style.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                                    allowClear={false}
                                    disabled={statusSelected === "Reprocess"} />                                
                            </span>
                        }
                        { 
                            (statusSelected === "Reprocess" || statusSelected === "Checking") &&
                            <span hidden={!(statusSelected === "Reprocess" || statusSelected === "Checking")}>
                                <Input
                                    placeholder="Search"
                                    style={style.searchInput}
                                    suffix={<Icon type="search" style={{ fontSize: '18px', paddingRight: '8px'}} />}
                                    onChange={e => { setSearchValue(e.target.value); setDisabledBtn(false); setHideClear(false) }}
                                    value={searchValue}
                                />
                            </span>
                        }
                        <button
                            className={disabledBtn ? f.disabledBtn : f.activeBtn}
                            disabled={disabledBtn}
                            style={{ backgroundColor: '#0A315E', borderRadius: 4, height: 40, border: '1px solid #0A315E' }}
                            onClick={() => applyFilter()}
                        >Apply</button>
                    </div>

                    <div className={f.newSummaryDiv}>
                        <div className={f.totalDiv}>
                            <div className={f.sumSubDiv}>
                                Total Payout: <span className={f.sumSubValue}>
                                    Php {parseFloat(totalPayout).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                                </span>
                            </div>

                        </div>

                        <div className={f.totalDiv}>
                            <div className={f.sumSubDiv}>
                                Total {statusSelected == "Reprocess" ? "Payout" : "Merchant"}(s): <span className={f.sumSubValue}>
                                    {totalMerchant} {statusSelected == "Reprocess" ? "payout" : "merchant"}(s)
                                </span>
                            </div>
                        </div>
                    </div>

                    <Table
                        rowKey={record => record.id}
                        size="middle"
                        pagination={false}
                        rowSelection={statusSelected == 'Audit' ? false : { ...rowSelected }}
                        rowClassName={(record, index) =>
                            index % 2 === 0 ? "table-row-light" : "table-row-dark"
                        }
                        dataSource={statusSelected == "Reprocess" ? reprocessData : fundsData}
                        columns={statusSelected == "Reprocess" ? forReprocessingColumns : workflowColumns}

                    />
                    <div align="right" style={{ width: '100%', marginTop: '20px' }} hidden={statusSelected == 'Audit'}>
                        <div hidden={userType !== 'EN' && userType !== 'AP' && userType !== 'OP' && userType !== "AD"}>
                            <button
                                className={`outline-btn--${segments} right-4`}
                                style={{
                                    display: statusSelected == "Approval" ? 'inline' : 'none'
                                }}
                                disabled={selectedIDs.length == 0}
                                onClick={() => {
                                    setShowModal(true);
                                    setRejectSelected(true)
                                }}
                            >
                                <div>Reject</div>
                            </button>

                            <button
                                className={`btn--${segments} right-8`}
                                disabled={selectedIDs.length == 0}
                                onClick={() => {
                                    setShowModal(true);
                                    setRejectSelected(false)
                                }}
                            >
                                <div className={f.textStyle}>
                                    {
                                        statusSelected == "Reprocess" ? "Retry Payout" :
                                            statusSelected == "Checking" ? "Endorse" :
                                                statusSelected == "Approval" ? "Payout" : ''}</div>
                            </button>
                            {statusSelected !== "Reprocess" ?
                                <Dropdown overlay={() => totalMenu()}
                                    disabled={selectedIDs.length == 0}
                                    style={{ marginRight: 16, cursor: selectedIDs.length == 0 ? 'not-allowed' : 'pointer' }}
                                    placement="bottomCenter" trigger={['click']} >
                                    <Icon type="ellipsis" />
                                </Dropdown>
                                : ''
                            }
                        </div>
                    </div>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <div style={{ width: '100%', display: 'flex', textAlign: 'right' }}>
                            <Select
                                showSearch
                                onChange={(ev) => {
                                    setPageSize(ev);
                                    onPageSizeChange(ev);
                                }}
                                placeholder="Page Size"
                                style={{ width: '110px', marginBottom: '10px', marginLeft: 'auto', marginRight: '20px' }}
                                size="small"
                            >
                                <Option key={1} value={10}>Show 10</Option>
                                <Option key={2} value={20}>Show 20</Option>
                                <Option key={3} value={50}>Show 50</Option>
                                <Option key={4} value={100}>Show 100</Option>
                                <Option key={5} value={150}>Show 150</Option>

                            </Select>

                            <Pagination
                                size="small"
                                total={totalCollectionCount}
                                showTotal={(total, range) => `${range[0]}-${range[1]} out of ${total}`}
                                onChange={onPageChange}
                                defaultCurrent={1}
                                key={randomize()}
                                pageSize={pageSize}
                                current={currentPage}
                            />
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
};


const style = {
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    datePic: {
        zIndex: 1, 
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 178,
    },
    searchInput:{
        zIndex: 1, 
        width: '180px', 
        height: '40px',
        borderRadius: '4px', 
        padding: '0 8px 0 0px' 
    },
}

export default Fund;
