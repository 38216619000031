import { useEffect, useState } from "react";
import moment from "moment";
import { axios } from "../../../../../lib/axios";

export function getUsers(
  currentPage,
  searchQuery,
  email,
  startDate,
  endDate,
  segment,
  level,
  isTestAccountType,
) {
  const pageSize = 10;
  const encodedSearchQuery = encodeURIComponent(searchQuery);
  const encodedEmail = encodeURIComponent(email);

  let formattedStartDate = startDate;
  let formattedEndDate = endDate;

  if (startDate) {
    formattedStartDate = moment(startDate).format("MM-DD-YYYY");
  }

  if (endDate) {
    formattedEndDate = moment(endDate).format("MM-DD-YYYY");
  }

  return email
    ? axios.get(
        `api/admin/users/download/?email=${encodedEmail}&search=${encodedSearchQuery}&start=${formattedStartDate}&end=${formattedEndDate}&segment=${segment}`,
      )
    : axios.get(
        `api/admin/users/?page=${currentPage}&page_size=${pageSize}&email=${encodedEmail}&search=${encodedSearchQuery}&start=${formattedStartDate}&end=${formattedEndDate}&segment=${segment}&test_account_type=${isTestAccountType}&level=${level}`,
      );
}

export function useUsers(
  currentPage,
  searchQuery,
  email,
  startDate,
  endDate,
  segment,
  level,
  isTestAccountType,
) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getUsers(
          currentPage,
          searchQuery,
          email,
          startDate,
          endDate,
          segment,
          level,
          isTestAccountType,
        );
        setData(response);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, [
    currentPage,
    searchQuery,
    email,
    startDate,
    endDate,
    segment,
    level,
    isTestAccountType,
  ]);

  return { data, isLoading, error };
}
