import React from 'react'
import Avatar from '../../static/images/signup_and_login/login_avatar.png'
import BackgroundImage from '../../static/images/signup_and_login/bg.png'
import NewBuxLogo from '../../static/images/signup_and_login/new_bux_logo.svg'
import MaintenanceImage from '../../static/images/maintenance0218.svg'
import { isMobile } from 'react-device-detect';
import BuxModal from '../../components/BuxModal/modal';

const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
const isMac = window.screen.width * window.devicePixelRatio == 1440 && window.screen.height * window.devicePixelRatio == 900;

const Maintenance = (props) => {

    const {children} = props

    return(
        <div>
            <BuxModal/>
            <div style={style.mainDiv}>
                <div style={style.subDiv}>
                <img src={NewBuxLogo} alt="#" style={{height: !isHighReso && '35px'}}/>
                </div>
                <div style={style.subDiv}>
                <img src={MaintenanceImage} style={style.avatarStyle}/>
                </div>
            </div>
        </div>
        
    )
}

const style  = {
    
    mainDiv:{
        padding: isMobile ? '0px' : isHighReso ? '90px 80px 0px 80px' : '50px 60px 0px 60px',
        width: '100%',
        height: '100vh',
        background:`url(${BackgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100vh",
    },
    subDiv:{
        padding: isMobile ? '0px' : isHighReso ? '90px 80px 0px 80px' : '50px 60px 0px 60px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center' 
    },
    titleText:{
        fontFamily: 'Montserrat',
        color: '#FFFFFF',
        fontSize: isHighReso ? '40px' : '30px',
    },
    avatarStyle:{
        height: '80%',  
        marginTop: isHighReso ? '30px' : '20px',
    }
}

export default Maintenance