import { axios } from "../../../../../lib/axios";

export function requestLimitUpdate(newLimit) {
  const { ownerId, daily_limit, transfer_limit } = newLimit;
  const limits = {};

  if (daily_limit && daily_limit !== "") limits.daily_limit = daily_limit;
  if (transfer_limit && transfer_limit !== "")
    limits.transfer_limit = transfer_limit;

  return axios.put(`/api/transfer/limits/`, {
    ownerId,
    ...limits,
  });
}
