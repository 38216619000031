import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Input, message } from 'antd';
import JSONPretty from 'react-json-pretty';

const PaymentModal = (props) => {

    const {
        modalState, setModalState
    } = props;

    const [code, setCode] = useState("");
    const [gcode, setGCode] = useState("");
    const [aamount, setAAmount] = useState("");
    const [resBody, setResBody] = useState();
    const segments = localStorage.getItem("segments");
    const yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    };
    const [idType, setIdType] = useState('');

    const checkStatusNow = async (type='ref_code') => {
        console.log(typeof(code))

        let url = process.env.REACT_APP_API_URL + `/api/admin/payment/ref_code/?code=${code}&type=${type}`
        console.log(yourConfig)
        console.log(url)
        try {
            let response = await axios.get(url, yourConfig);
            if(response.data.status === 'failed') {
                message.destroy()
                message.error(response.data.message)
            }
            else {
                setResBody(response.data);
            }
        }

        catch (e) {
            console.log(e.response);
        }
    };

    const tagAsPaid = async () => {
        console.log(typeof(code))
        let ref_code = code;
        if(resBody && resBody.ref_code != code){
            ref_code = resBody.ref_code;
        }
        let url = process.env.REACT_APP_API_URL + `/payment_simulate/${ref_code}/`
        console.log(yourConfig)
        console.log(url)
        try {
            setResBody("");
            let response = await axios.get(url, yourConfig);
            checkStatusNow(idType);
        }

        catch (e) {
            message.error(e.response.data.message)
        }
    };


    const tagAsPaidG = async () => {
        console.log(typeof(code))
        if(!gcode){
            message.error("Set Grabpay Reference")

        }
        let url = process.env.REACT_APP_API_URL + `/payment_simulate/${code}/?grabref=${gcode}`
        console.log(yourConfig)
        console.log(url)
        try {
            setResBody("");
            let response = await axios.get(url, yourConfig);
            checkStatusNow();
        }

        catch (e) {
            message.error(e.response.data.message)
        }
    };


    const tagAsPaidA = async () => {
        console.log(typeof(code))
        if(!aamount){
            message.error("Set Adjustment Amount")

        }
        let url = process.env.REACT_APP_API_URL + `/payment_simulate/${code}/?amount=${aamount}`
        console.log(yourConfig)
        console.log(url)
        try {
            setResBody("");
            let response = await axios.get(url, yourConfig);
            checkStatusNow();
        }

        catch (e) {
            message.error(e.response.data.message)
        }
    };

    const closeModal = () => {
        setModalState(false);
        setResBody();
        setCode("");
    }

    return(
        <Modal
            visible={modalState}
            onCancel={() => closeModal()}
            width={resBody ? 600 : 360}
            footer={null}>
                <div className="top-32">
                    <div className="bold-text-size">
                        Check Payment Link Status
                    </div>
                    <div className="top-12">
                        <Input
                            name="code" 
                            placeholder="Paste/Type Ref. Code here"
                            value={code} 
                            onChange={(e) => setCode(e.target.value)} />
                    </div>
                    <div>
                        <button className={`btn--${segments} top-12 btn-mobile-width`}
                        onClick={() => {checkStatusNow('ref_code'); setIdType('ref_code')}}>
                            Check Status Via Reference Code
                        </button>
                    </div>
                    <div>
                        <button className={`btn--${segments} top-12 btn-mobile-width`}
                        onClick={() => {checkStatusNow('trans_id'); setIdType('trans_id')}}>
                            Check Status Transaction ID
                        </button>
                    </div>
                    <div>
                        <button className={`btn--${segments} top-12 btn-mobile-width`}
                        onClick={() => tagAsPaid()}>
                            Tag as Paid
                        </button>
                    </div>
                    <div className="top-20" hidden={!resBody}>
                        <div className="top-20" hidden={resBody && resBody.ref_code != 'GRABPAY-PENDING'}>
                            <div className="top-12">
                                <Input
                                    name="gcode" 
                                    placeholder="Paste/Type Grabpay Ref"
                                    value={gcode} 
                                    onChange={(e) => setGCode(e.target.value)} />
                            </div>
                            <div>
                                <button className={`btn--${segments} top-12 btn-mobile-width`}
                                onClick={() => tagAsPaidG()}>
                                    Tag as Paid (Grabpay Ref)
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="top-20" hidden={!resBody}>
                        <div className="top-20" hidden={resBody && resBody.status != 'PA'}>
                            <div className="top-12">
                                <Input
                                    name="gcode" 
                                    placeholder="Adjustment Amount"
                                    value={aamount} 
                                    onChange={(e) => setAAmount(e.target.value)} />
                            </div>
                            <div>
                                <button className={`btn--${segments} top-12 btn-mobile-width`}
                                onClick={() => tagAsPaidA()}>
                                    Adjust Amount
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="top-20" hidden={!resBody}>
                        {
                            resBody &&
                            <JSONPretty 
                                valueStyle='font-weight:bold'
                                data={resBody} />
                        }
                    </div>
                </div>
        </Modal>
    )
}

export default PaymentModal;