import React from 'react'
import {Modal, Button, message, Icon} from 'antd'
import axios from 'axios'
import { closeModal } from './recon_dragonpay'
import { closeModal1 } from './recon'
import { closeModal2 } from './recon_gcash'
import { closeModal3 } from './recon_bti'
import { closeModal4 } from './recon_da5'
import { closeModal5 } from './recon_ecpay'
import { closeModal6 } from './recon_posible'
import { closeModalG } from './recon_generic'
import { closeModalBayad } from './recon_bayad_center'

class UploadModal extends React.Component {

    state = {
        hover: false,
        hover2: false,
        file: null,
        fileName: '',
        uploading: false,
        modal: false,
        fromHere: false,
        uploadProgress: 0,
    }
    fileUpload = React.createRef();

    textStyle = {
        fontWeight: '300',
        color: '#000',
        textAlign: 'center',
    }

    closeAllModal = () => {
        this.setState({
            file: null,
            fileName: ''
        });

        if (this.fileUpload.current) {
            this.fileUpload.current.value = "";
        }
        

        if(this.props.channel == 'dragonpay'){
            closeModal();
        }
        else if(this.props.channel == '7_eleven'){
            closeModal1();
        }
        else if(this.props.channel == 'gcash'){
            closeModal2()
        }
        else if(this.props.channel == 'payngo'){
            closeModal3()
        }
        else if(this.props.channel == 'da5'){
            closeModal4()
        }
        else if(this.props.channel == 'ecpay'){
            closeModal5()
        }
        else if(this.props.channel == 'bayad_center'){
            closeModalBayad()
        }
        else if(this.props.channel == 'posible'){
            closeModal6()
        }
        else if(this.props.channel == 'transfer_money'){
            this.props.closeModal()
        }
        else if(this.props.channel == 'cashbux_cashout'){
            this.props.closeModal()
        }
        else if(this.props.channel == 'cashbux_debit'){
            this.props.closeModal()
        }
        else{
            closeModalG()
        }
    }
    handleFile = (file) => {
        var self = this;
        if(file != null){
            self.setState({
                file: file,
                fileName: file.name
            })
        }
    }
    
    uploadViaPost = () => {
        /* To Be Deprecated once all channels are covered */

        var self = this
        const yourConfig = {
            headers: {

                'Content-Type': 'multipart/form-data',
                'Authorization': "Token " + localStorage.getItem("jwtToken")
            }
        }

        const formData = new FormData();
        
        formData.append('file', self.state.file);

        if(this.props.channel == "7_eleven"){
            formData.append('cutoff', this.props.cutoff);
        }
        else{
            formData.append('date', this.props.cutoff);
        }

        self.setState({
            uploading: true
        })

        axios.post(process.env.REACT_APP_API_URL + `/api/recon/`+this.props.channel+`/`,
            formData, yourConfig
        ).then(function (response) {
            if(response.data.status == "success"){
                self.setState({
                    modal: false,
                    uploading: false
                })
                message.success("Succesfully Accounted")
                try{
                    self.closeAllModal()
                }catch(err){console.log(err)}
            }
            else{
                self.setState({
                    uploading: false
                })
                message.error(response.data.message)
                
            }
        })
        .catch(function (error) {
            self.setState({
                uploading: false
            })
            message.error("Error")
        });
    }

    getPresignedS3Request = async (channel, startDate, cutoff, fileName) => {
        const allocatorURL = process.env.REACT_APP_API_URL + `/api/recon/` + channel + `/`;
        const baseRequestParams = {
            'mode': 'get_url',
            'recon_file_name': fileName,
        };

        let allocatorRequestParams = {};
        if (channel === "7_eleven") {
            allocatorRequestParams = {
                ...baseRequestParams,
                'cutoff': cutoff,
            }

        } else if (['cashbux_cashout','cashbux_debit'].includes(channel)) {
            allocatorRequestParams = {
                ...baseRequestParams,
                'start_date': startDate,
                'end_date': cutoff,
                channel,
            }
        } else {
            allocatorRequestParams = {
                ...baseRequestParams,
                'date': cutoff,
                channel,
            }
        }

        const allocatorRequestConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        };

        try {
            let response = await axios.patch(allocatorURL, allocatorRequestParams, allocatorRequestConfig);
            const presignedS3Request = response.data.s3_request;
            if (!Object.keys(presignedS3Request).length) {
                throw new Error("Presigned S3 Request is empty");
            }

            return {
                presignedS3Request, 
                paymentChannelReconId: response.data['payment_channel_recon_id'],
                weeklyCutoffId: response.data['weekly_cutoff_id'],
            };

        } catch (err) {
            let msg = (err.response) ? err.response.data.message : err.message;
            throw new Error(`Allocation of S3 target path failed. ${msg}`);
        }
    };

    uploadFileToS3 = async (presignedS3Request, fileToUpload) => {
        const { 
            url: S3URL, 
            fields: S3Headers,
        } = presignedS3Request;

        const uploadConfig = {
            onUploadProgress: (progressEvent) => {
                const recomputedProgress = 100 * progressEvent.loaded / progressEvent.total;
                this.setState({uploadProgress: recomputedProgress});
            }
        }

        // Copy BE-generated S3 headers into the FormData we are about to send
        let s3FormData = new FormData();
        for (const header in S3Headers) {
            s3FormData.append(header, S3Headers[header]);
        }
        s3FormData.append('file', fileToUpload);

        try {
            await axios.post(S3URL, s3FormData, uploadConfig);

        } catch (err) {
            let msg = (err.response) ? err.response.data.message : err.message;
            throw new Error(`Direct file upload to S3 failed. ${msg}`);
        }

    };

    updateBackendFilePath = async (
        channel, 
        startDate, 
        cutoff, 
        presignedS3Request,
        paymentChannelReconId,
        weeklyCutoffId,
    ) => {
        const { path: filePath } = presignedS3Request;

        const url = process.env.REACT_APP_API_URL + `/api/recon/` + channel + `/`;
        const baseUpdateParams = {
            'mode': 'update_path',
            'recon_file_attrib_name': 'recon_file',
            'recon_file_path': filePath,
        };

        let updateParams = {};
        if (channel === "7_eleven") {
            updateParams = {
                ...baseUpdateParams,
                'weekly_cutoff_id': weeklyCutoffId,
            }
        } else if (['cashbux_cashout','cashbux_debit'].includes(channel)) {
            updateParams = {
                ...baseUpdateParams,
                'start_date': startDate,
                'end_date': cutoff,
                channel,
            } 
        } else {
            updateParams = {
                ...baseUpdateParams,
                'payment_channel_recon_id': paymentChannelReconId,
                channel,
            }
        }

        const updateConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        };

        try {
            await axios.patch(url, updateParams, updateConfig);

        } catch (err) {
            let msg = (err.response) ? err.response.data.message : err.message;
            throw new Error(`Update of target path failed. ${msg}`);
        }
    };

    triggerAsyncRecon = async (
        channel, 
        startDate, 
        cutoff,
        paymentChannelReconId,
        weeklyCutoffId,
    ) => {
        const triggerURL = process.env.REACT_APP_API_URL + `/api/recon/` + channel + `/`;
        const triggerConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': "Token " + localStorage.getItem("jwtToken")
            }
        }

        const formData = new FormData();

        if(channel === '7_eleven') {
            formData.append('weekly_cutoff_id', weeklyCutoffId);
        } else if (['cashbux_cashout','cashbux_debit'].includes(channel)) {
            formData.append('start_date', startDate);
            formData.append('end_date', cutoff);
        } else {
            formData.append('payment_channel_recon_id', paymentChannelReconId)
        }

        try {
            let response = await axios.post(triggerURL, formData, triggerConfig);
            if (response.data.status === "failed") {
                throw response;
            }

        } catch (err) {
            let msg = (err.response) ? err.response.data.message : err.message;
            throw new Error(`Error triggering async recon. ${msg}`);
        }
    };

    uploadViaS3 = async () => {
        this.setState({
            uploadProgress: 0,
            uploading: true
        })

        try {
            const { presignedS3Request, paymentChannelReconId, weeklyCutoffId } = await this.getPresignedS3Request(this.props.channel, this.props.startDate, this.props.cutoff, this.state.fileName);
            await this.uploadFileToS3(presignedS3Request, this.state.file);
            await this.updateBackendFilePath(this.props.channel, this.props.startDate, this.props.cutoff, presignedS3Request, paymentChannelReconId, weeklyCutoffId);
            await this.triggerAsyncRecon(this.props.channel, this.props.startDate, this.props.cutoff, paymentChannelReconId, weeklyCutoffId);

            this.setState({
                uploadProgress: 0,
                modal: false,
                uploading: false
            })
            this.closeAllModal()
            message.success("Recon file uploaded. Accounting in progress. Check back later.")
        }
        catch (err) {
            this.setState({
                uploadProgress: 0,
                uploading: false
            })
            // message.error(err.message);
        }
    }

    handleUpload = () => {
        const S3_SUPPORTED_CHANNELS = ["7_eleven", "dragonpay", "gcash", "maya", "posible", "payngo", "ecpayg", "da5", "grabpay", "bayad_center", "billease", "i2i", "upay", "allbank", "cashbux_cashout", "cashbux_debit"];

        if (S3_SUPPORTED_CHANNELS.includes(this.props.channel)) {
            this.uploadViaS3();
        } else {
            this.uploadViaPost();
        }
    };
    render(){
        return(
            <div>
                <Modal
                    visible={this.props.modal}
                    footer={false}
                    width="400px"
                    closable={false}
                    bodyStyle={{padding: '60px 20px 34px 20px'}}
                >
                <div>
                    <div style={{...this.textStyle, fontSize: '32px'}}>Upload your CSV File</div>
    
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{...this.textStyle, fontSize: '16px', width: '297px'}}>Upload the CSV file with the information you want to import here.</div>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent:'center', padding: '20px'}}>
                    <input type="file"
                        ref={this.fileUpload}
                        style={{opacity: '0', position: 'fixed'}}
                        onChange={(e)=>this.handleFile(e.target.files[0])}
                        accept=".csv"
                        >
                    </input>
                    <Button 
                        onClick={()=>this.fileUpload.current.click()}
                        style={{
                            height: '40px',
                            border: '1px solid #1DD28B',
                            backgroundColor: this.state.hover2 ? '#1DD28B' : '#fff',
                            color: this.state.hover2 ? '#fff' : '#1DD28B',
                            borderRadius: '80px',
                            fontWeight: '600',
                            fontSize: '16px',
                            width: '164px'
                        }}
                        hidden={this.state.file != null}
                        onMouseEnter={()=>this.setState({hover2:true})}
                        onMouseLeave={()=>this.setState({hover2:false})}>
                        Choose File
                    </Button>
    
                    <div 
                        style={{
                            height: '40px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            border: '1px solid #1DD28B',
                            backgroundColor: this.state.hover2 ? '#1DD28B' : '#fff',
                            color: this.state.hover2 ? '#fff' : '#1DD28B',
                            borderRadius: '80px',
                            fontWeight: '600',
                            fontSize: '16px',
                            width: '164px',
                            textAlign: 'center', 
                            padding: '8px',
                            alignItems: 'center'
                        }}
                        hidden={this.state.file == null}>
                        <div style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {this.state.fileName}
                        </div>
                        <Button 
                            onClick={() => {
                                this.setState({
                                    file: null,
                                    fileName: ''
                                })
                                this.fileUpload.current.value = "";
                            }}
                            style={{
                            color: '#fff', 
                            borderRadius: '50%', 
                            height: '25px', 
                            padding: '5px', 
                            backgroundColor: '#262f38'}}>
                            <Icon type='close' style={{ display: 'flex' }}/>
                        </Button>
                    </div>
                </div>
                <div style={{display: 'flex', marginTop: '20px'}}>
                    <Button
                        style={{
                            backgroundColor: this.state.hover ? '#F5922F' : '#fff',
                            color: this.state.hover ? '#fff' : '#F5922F',
                            fontWeight: '600',
                            fontSize: '16px',
                            borderRadius: '10px',
                            height: '48px',
                            width: '49%',
                            border: '1px solid #F5922F'
                        }}
                        onClick={()=>
                                {
                                    this.closeAllModal()
                                }
                            }
                        onMouseEnter={()=>this.setState({hover:true})}
                        onMouseLeave={()=>this.setState({hover:false})}
                        disabled={this.state.uploading}
                    >Cancel</Button>
                    <Button 
                        style={{
                            backgroundColor: '#F5922F',
                            borderRadius: '10px',
                            height: '48px',
                            width: '49%',
                            color: '#fff',
                            fontWeight: '600',
                            fontSize: '16px',
                            border: '1px solid #F5922F',
                            opacity: this.state.uploading || this.state.file == null ? '0.5' : 1, 
                            marginLeft: '2%'
                        }}
                        onClick={()=>this.handleUpload()}
                        disabled={this.state.file == null}
                        loading={this.state.uploading}
                    >{this.state.uploading ? `Uploading ${Math.round(this.state.uploadProgress,)}%` : 'Upload'}
                    </Button>
                </div>
                </Modal>
    
            </div>
        )
    }
    
}

export default UploadModal
