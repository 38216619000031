import React from 'react'
import {Button, Layout} from 'antd';
import {history} from '../../store/history'
import NotFound from '../../static/img/not_found.svg'
import logo from '../../static/icons/logo.svg';
import * as Style from './styles'

const PageNotFoundMobile = props => {
    const { Header } = Layout;

    return(
        <div>
            <Header style={Style.headerStyle}>
                <img src={logo} style={Style.logoStyle} alt="#" />
            </Header>
            <div style={Style.kycMainDiv}>
                <div style={Style.mainDiv}>
                    <img src={NotFound} alt="#"></img>
                </div>
                <div style={Style.kycChildDiv}>
                    <div style={Style.verifyStyle}>Page not found</div>
                    <div style={Style.textStyle}>The page you’re looking for doesn’t exist.</div>
                    <Button type="primary" style={Style.signupBtnStyle} onClick = {() => window.location.href = '/login'}>Back to Home</Button>
                </div>
            </div>
        </div>
    )

}

export default PageNotFoundMobile
