import { useEffect, useState } from "react";
import { axios } from "../../../../../lib/axios";

export function getAlbBanks() {
  return axios.get("api/allbank/banks/");
}

export function useAlbBanks() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getAlbBanks();
        setData(response);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, []);

  return { data, isLoading, error };
}
