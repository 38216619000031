import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  Typography,
  Button,
  Input,
  notification,
  Skeleton,
} from "antd";
import { updateAdminCredentials } from "../api/update-admin-credentials";
import { useAdminCredentials } from "../api/get-admin-credentials";

const { Text } = Typography;

function Credentials({ form, merchantId }) {
  const { data, isLoading, refetch } = useAdminCredentials(merchantId);

  const [credentials, setCredentials] = useState({
    user_id: merchantId,
    type: "live",
    profile_id: "",
    profile_name: "",
    secret_key: "",
    access_key: "",
  });

  const [enableEditBtn, setEnableEditBtn] = useState(false);

  useEffect(() => {
    if (data && data.status === "success") {
      const { profile_id, profile_name, secret_key, access_key } = data.data;
      setCredentials((prev) => ({
        ...prev,
        profile_id,
        profile_name,
        secret_key,
        access_key,
      }));
      setEnableEditBtn(true);
    }
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          const response = await updateAdminCredentials(credentials);
          setEnableEditBtn(true);
          refetch();
          notification.success({
            message: "Success",
            description: response.message,
          });
        } catch (err) {
          console.log(err);
          notification.error({
            message: "Failed",
            description: `${err.message}. Please try again later.`,
          });
        }
      }
    });
  };

  const handleCancel = () => {
    const clearedCredentials = {
      profile_id: "",
      profile_name: "",
      secret_key: "",
      access_key: "",
    };
    setEnableEditBtn(true);
    if (data && data.status === "failed") {
      setCredentials((prev) => ({ ...prev, ...clearedCredentials }));
      form.setFieldsValue({ ...clearedCredentials });
    }
  };

  const updateFields = (fields) => {
    setCredentials({ ...credentials, ...fields });
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Card>
      <Form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text style={{ fontSize: "16px" }}>Cybersource Credentials</Text>
            <Text type="secondary">
              When provided, credit card payment channel will be activated for
              this merchant.
            </Text>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1em",
          }}
        >
          <Form.Item label="Profile Name" style={{ margin: 0 }}>
            {form.getFieldDecorator("profile_name", {
              initialValue: credentials.profile_name,
              rules: [{ required: true, message: "Please input profile name" }],
            })(
              <Input
                size="large"
                disabled={enableEditBtn}
                onChange={(e) => updateFields({ profile_name: e.target.value })}
                autoComplete="off"
              />,
            )}
          </Form.Item>
          <Form.Item label="Profile ID" style={{ margin: 0 }}>
            {form.getFieldDecorator("profile_id", {
              initialValue: credentials.profile_id,
              rules: [{ required: true, message: "Please input profile id" }],
            })(
              <Input
                size="large"
                disabled={enableEditBtn}
                onChange={(e) => updateFields({ profile_id: e.target.value })}
                autoComplete="off"
              />,
            )}
          </Form.Item>
          <Form.Item label="Access Key" style={{ margin: 0 }}>
            {form.getFieldDecorator("access_key", {
              initialValue: credentials.access_key,
              rules: [{ required: true, message: "Please input access key" }],
            })(
              <Input
                size="large"
                disabled={enableEditBtn}
                onChange={(e) => updateFields({ access_key: e.target.value })}
                autoComplete="off"
              />,
            )}
          </Form.Item>
          <Form.Item label="Secret Key" style={{ margin: 0 }}>
            {form.getFieldDecorator("secret_key", {
              initialValue: credentials.secret_key,
              rules: [{ required: true, message: "Please input secret key" }],
            })(
              <Input
                type="password"
                size="large"
                disabled={enableEditBtn}
                onChange={(e) => updateFields({ secret_key: e.target.value })}
                autoComplete="off"
              />,
            )}
          </Form.Item>
          {!enableEditBtn && (
            <div
              style={{
                marginTop: "1em",
                alignSelf: "flex-end",
                display: "flex",
                gap: "1em",
              }}
            >
              <Form.Item>
                <Button onClick={handleCancel} size="large" htmlType="button">
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" size="large" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>
          )}
        </div>
      </Form>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "1em",
        }}
      >
        {enableEditBtn && (
          <Form.Item style={{ alignSelf: "flex-end" }}>
            <Button
              onClick={() => setEnableEditBtn(false)}
              type="primary"
              size="large"
              htmlType="button"
            >
              Edit
            </Button>
          </Form.Item>
        )}
      </div>
    </Card>
  );
}

export default Form.create({ name: "cybersource_credentials" })(Credentials);
