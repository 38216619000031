import axios from 'axios'
import { Handle401 } from '../handle401/handle401'
import { USER_TYPE } from '../constants/constants';

const authorizedAxiosRequest = () => {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    });
}

const unauthorizedAxiosRequest = () => {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });
}

const surveyServices = {
    submitMicroSurvey: async (params) => {
        try {
            let endpoint = 'api/user/satisfaction_survey/'
            const res = await authorizedAxiosRequest().post(endpoint, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    checkoutMicroSurvey: async (params) => {
        try {
            let endpoint = `api/payment/satisfaction_survey/?uid=${params.uid}`;
            const res = await unauthorizedAxiosRequest().get(endpoint);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    submitCheckoutMicroSurvey: async (params) => {
        try {
            let endpoint = 'api/payment/satisfaction_survey/'
            const res = await unauthorizedAxiosRequest().post(endpoint, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    }, 
    checkoutMerchantMicroSurvey: async (uid) => {
        try {
            let endpoint = `/api/user/satisfaction_survey/?code=${'MERCHANTPAYMENT'}&uid=${uid}`;
            const res = await authorizedAxiosRequest().get(endpoint);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    submitCheckoutMerchantMicroSurvey: async (params) => {
        try {
            let endpoint = 'api/user/satisfaction_survey/'
            const res = await authorizedAxiosRequest().post(endpoint, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    }, 
}

export default surveyServices;