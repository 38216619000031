import React from 'react'
import axios from 'axios'

import {Table, Input, Modal, Select} from 'antd'
import FeeStyles from '../../style'

const { TextArea } = Input;
const {Option} = Select

const EndorsementCard = props => {

    const [showModal, setShowModal] = React.useState(false)
    const [currPartner, setCurrPartner] = React.useState(null);
    const [addPartner, setAddPartner] = React.useState("");
    const [remarks, setRemarks] = React.useState("")

    const partners = props.partners;
    const fee = FeeStyles()
    const columns = [
            {
              title: 'Username',
              dataIndex: 'username',
            },
            {
              title: 'Remarks',
              dataIndex: 'remarks',
            },
            {
                title: 'Action',
                render: record => {
                    return <a onClick={()=>removeEndorsement(record.username)}>Retract</a>
                }
            }]

    async function endorseUser(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = {
            username: addPartner,
            remarks: remarks,
            endorse: 1
        }


        try {

            let response = await axios.post(
                process.env.REACT_APP_API_URL + `/api/kyb/${props.pUid}/partners/`, params, yourConfig)
            if(response.data.status == 'success'){
                props.reloadPage()
                setShowModal(false)
            }
        }

        catch (error) {
            console.log(error)
        }

    }

    async function removeEndorsement(username){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = {
            username: username,
            endorse: 0
        }


        try {

            let response = await axios.post(
                process.env.REACT_APP_API_URL + `/api/kyb/${props.pUid}/partners/`, params, yourConfig)
            if(response.data.status == 'success'){
                props.reloadPage()
                setShowModal(false)
            }
        }

        catch (error) {
            console.log(error)
        }

    }


  const renderData = () => {
    let filteredData = partners;  


    filteredData = partners.filter(data => data.endorsed);
    return filteredData;
  }
    
    return(
        <div style={{paddingLeft: 20, paddingRight: 20, marginTop: -1}}>
            <Modal
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={null}>

                <div style={{
                    marginTop: 32,
                    fontSize: 24,
                    lineHeight: '32px',
                    fontWeight: '300'
                }}>
                    <div className={fee.inputHeader}>Partner</div>
                    <Select
                        showSearch
                        onChange={(val)=>setAddPartner(val)}
                        placeholder="Grant a user"
                        style={{ width: '100%', marginBottom: '10px', height: '50px' }}
                        size="large"
                    >
                        { partners.length > 0 && partners.map((item, i) => {
                          
                              return (
                                <Option key={i} value={item.username}>{item.username}</Option>
                              )
                            })
                        }

                    </Select>

                    <div className={fee.inputHeader}>Remarks</div>
                    <TextArea value={remarks} onChange={(e) => setRemarks(e.target.value)}/>

                    <div align='right' style={{marginTop: 20}}>
                        <button className={fee.rejectBtn} onClick={()=> setShowModal(false)}>cancel</button>
                        <button className={fee.approveBtn} onClick={() => endorseUser()}>Endorse</button>
                    </div>
                </div>
            </Modal>
            <div className={fee.innerDiv}>
                <div className={fee.spaceBetween} style={{padding: 20, borderBottom: '1px solid', borderBottomColor: '#E6EAF0'}}>
                    <div>
                        <div><span className={fee.channelHeader}>Endorsements</span></div>
                    </div>

                    <div>
                        <div>
                            <button className={fee.editBtnTrans} onClick={ ()=> setShowModal(true)}>Add</button>
                        </div>
                    </div>

                </div>


                <div style={{ padding: 10}}>

                    <Table
                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                    dataSource={renderData()}
                    pagination={false}
                    columns={columns} />
                </div>
            </div>
        </div>
    )
}

export default EndorsementCard