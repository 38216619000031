import React from 'react';
import ButtonGroup from 'antd/lib/button/button-group';
import moment from 'moment';
import Table from './table';
import { Button, DatePicker, Dropdown, Icon, Input, Typography, Menu } from 'antd';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import multiwalletServices from '../../../../multiwalletServices';
import crypto from 'crypto';
import { history } from '../../../../../../store/history';
import { segmentStroke } from '../../../../../constants/constants';
import ClearFilter from '../../../../../AdminComponents/filter_btns/clear_filter_btn';

const PaymentHistoryTable = (props) => {
    const [selected, setSelected] = React.useState('Paid')
    const [dateRange, setDateRange] = React.useState({ startDate: '', endDate: '' });
    const [visibleClearBtn, setVisibleClearBtn] = React.useState(false);
    const [searchVal, setSearchVal] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [createBtnStyle, setCreateBtnStyle] = React.useState({ color: '#1DD28B', backgroundColor: 'transparent' });
    const [tableData, setTableData] = React.useState(null);
    const [customRange, setCustomRange] = React.useState('All');
    let requestToken = '';
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const userType = localStorage.getItem('userType');
    const notAdmin = ['SW', 'ME', 'CO'].includes(userType);
    const userHistoytProps = props && props.props && props.props.props;
    const pUid = notAdmin ? window.location.pathname.split("/").pop() : userHistoytProps && userHistoytProps.match.params.id;

    const ButtonTab = ({ rootStyle }) => {

        const status = ['Paid', 'Pending', 'Expired', 'Cancelled'];

        if (!notAdmin) {
            status.splice(0, 2);
            status.unshift('All', 'Success');
            status.pop();
            status.push('Failed');
        }

        const handleClick = (event) => {
            setSelected(event.target.value);
        }

        return (
            <div style={rootStyle}>
                <ButtonGroup>
                    {
                        status.map((btn, i) => {
                            return (
                                <Button
                                    key={btn}
                                    value={btn}
                                    onClick={handleClick}
                                    style={{
                                        ...styles.btnGroup,
                                        color: btn === selected ? '#FFF' : '#2B2D33',
                                        backgroundColor: btn === selected ? segmentStroke[0] : '#FFF',
                                        borderRadius: i === 0 ? '10px 0 0 10px' : i === 3 ? '0 10px 10px 0' : 0
                                    }}
                                >
                                    {btn}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </div>
        )
    }

    const returnCustomRange = (key) => {
        switch (key) {
            case 'All':
                return 'All Time';
            case 'Today':
                return 'Today';
            case 'Week':
                return 'Last 7 days';
            case 'Month':
                return 'Last 30 days';
            case 'Custom':
                return 'Custom';
            default:
                return 'All Time';
        }
    }

    const handleDayChange = (props) => {
        setCustomRange(props.item.props.value);
        setDateRange({ startDate: '', endDate: '' });
    }

    const dayMenu = (
        <Menu onClick={handleDayChange}>
            <Menu.Item value="All">All Time</Menu.Item>
            <Menu.Item value="Today">Today</Menu.Item>
            <Menu.Item value="Week" >Last 7 days</Menu.Item>
            <Menu.Item value="Month" >Last 30 days</Menu.Item>
        </Menu>
    )

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    const rangeSelect = (dates, dateString, id) => {
        setDateRange({ ...dateRange, [id]: moment(dateString).format('MM-DD-YYYY') });
        setCustomRange('Custom');
    }

    const onFilterApply = () => {
        getTranxHistory(testMode, returnSelected(selected), searchVal, dateRange.startDate, dateRange.endDate, customRange, generateToken());
        setVisibleClearBtn(true);
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value);
    }


    const resetFilters = () => {
        setDateRange({ startDate: '', endDate: '' });
        setSearchVal('');
        setVisibleClearBtn(false);
        getTranxHistory(testMode, returnSelected(selected));
        setCustomRange('All');
    }

    const applyBtnDisabled = () => {
        return !dateRange.startDate &&
            !dateRange.endDate &&
            customRange === 'All' &&
            !searchVal
    }

    const onCreateBtnHover = () => {
        setCreateBtnStyle({
            color: '#FFF',
            backgroundColor: '#1DD28B'
        });
    }

    const onCreateBtnLeave = () => {
        setCreateBtnStyle({
            color: '#1DD28B',
            backgroundColor: 'transparent'
        });
    }

    const generateToken = () => {
        let generatedRequestToken = crypto.randomBytes(16).toString('hex');
        requestToken = generatedRequestToken
        return generatedRequestToken
    }

    const getTranxHistory = async (isTestMode, type, search, start, end, summary, token) => {
        setIsLoading(true);
        try {
            const res = notAdmin ? await multiwalletServices.getSubwalletPayoutHistory(isTestMode, pUid, type, search, start, end, summary, token) : await multiwalletServices.getPayoutUserHistory(pUid);
            if (res) {
                setTableData(res);
            }
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false);
    }

    const returnSelected = (selected) => {
        switch (selected) {
            case 'Transfer Money':
                return 'all';
            case 'Receive Money':
                return 'received';
            case 'Other Fees':
                return 'sent';
            default:
                return 'all';
        }
    }

    const TotatTranxCard = (props) => {
        const { data, hidden } = props;
        return (
            <div hidden={hidden && hidden} style={styles.totalCardDiv}>
                <Typography style={{ ...styles.totalLabel, borderRight: '1px solid #E6EAF0' }}>No. of Paid Transactions: <b style={{ color: '#0D3D76' }}>{data && data.total_payouts_count && data.total_payouts_count.toLocaleString("en-US")}</b></Typography>
                <Typography style={styles.totalLabel}>Total Amount: <b style={{ color: '#0D3D76' }}>₱{data && data.total_payouts_amount.toLocaleString("en-US", { minimumFractionDigits: 2 })}</b></Typography>
            </div>
        )
    }

    React.useEffect(() => {
        getTranxHistory(testMode, selected, searchVal, dateRange.startDate, dateRange.endDate, customRange, generateToken());
    }, [selected])

    React.useEffect(() => {
        if (!notAdmin) {
            setSelected('All');
        }
    }, [])

    return (
        <div style={styles.root}>
            <ButtonTab />
            <div style={{...styles.body, padding: notAdmin ? '0px 16px' : '20px 16px 0 16px'}} className="top-20">
                <div hidden={!notAdmin} style={styles.subContainer} className="top-16">
                    <ClearFilter hideFilterBtn={!visibleClearBtn} clearFilter={resetFilters} />
                </div>
                <div hidden={!notAdmin} style={{ display: 'flex', justifyContent: 'flex-start', padding: '0 0 24px 0' }}>
                    <Dropdown overlay={dayMenu} trigger={['click']}>
                        <a style={styles.dropdownItems} >
                            {returnCustomRange(customRange)}
                            <ExpandMoreIcon style={styles.dropdownArror} />
                        </a>
                    </Dropdown>
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledDate}
                        // defaultValue={moment()}
                        value={dateRange.startDate && moment(dateRange.startDate)}
                        size="large" picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'startDate')}
                        placeholder='Start date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <span style={{ paddingTop: 8 }}>-</span>
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledDate}
                        // defaultValue={moment()}
                        value={dateRange.endDate && moment(dateRange.endDate)}
                        size="large"
                        picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'endDate')}
                        placeholder='End date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <Input
                        placeholder="Search"
                        style={{ width: '200px', height: '40px', borderRadius: '4px', padding: '0 8px 0 8px' }}
                        suffix={<Icon type="search" style={{ fontSize: '18px' }} />}
                        onChange={handleSearch}
                        value={searchVal}
                    />
                    <button className={`btn--${props.segments} btn-height`} disabled={applyBtnDisabled()} onClick={onFilterApply} >Apply</button>
                </div>
                <TotatTranxCard hidden={!notAdmin} data={tableData && tableData.totals} />
                <Table
                    data={tableData && tableData.page_data && notAdmin ? tableData.page_data : tableData && !notAdmin ? tableData : []}
                    isLoading={isLoading}
                    selected={selected}
                    notAdmin={notAdmin}
                // sort={tableSort}
                />
            </div>
        </div>
    )
}

const styles = {
    root: {
        width: '100%',
        padding: '40px 0'
        //   display: 'flex', 
        //   flexDirection: 'column', 
        // //   justifyContent: 'center', 
        //   alignItems: 'center'
    },
    body: {
        padding: '0px 16px',
        backgroundColor: '#FFF',
        // border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        // margin: '38px 0'
    },
    exportBtn: {
        fontSize: 16,
        fontWeight: '600',
        padding: '8px, 16px, 8px, 16px',
        border: '1px solid #1DD28B',
        height: 40,
        marginRight: 12,
        color: '#FFF',
        backgroundColor: '#1DD28B'
    },
    btnGroup: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: '0 32px',
    },
    btnTabContainer: {
        backgroundColor: '#FFF',
        width: '100%',
        padding: '20px, 20px 0 20px',
    },
    subContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 0'
    },
    clearDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    clearIcon: {
        color: '#2B2D33',
        fontSize: 10,
    },
    filterText: {
        color: '#000',
        fontSize: 14,
        fontWeight: 'bold'
    },
    clearBtnDiv: {
        backgroundColor: '#E6EAF0',
        borderRadius: 24,
        padding: '4px 12px',
        margin: '0 12px'
    },
    clearBtn: {
        color: '#000000',
        fontSize: 12,
        paddingLeft: 4,
    },
    reminder: {
        fontSize: 12,
        color: '#909196',
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 178,
        padding: '0 8px 0 8px'
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    applyBtn: {
        height: 40,
        width: 92,
        fontSize: 16,
        fontWeight: '600',
        borderRadius: 4,
        backgroundColor: '#F5922F',
        color: '#FFF',
    },
    createBtn: {
        fontSize: 16,
        fontWeight: '600',
        height: 40,
        borderColor: '#1DD28B',
        marginRight: 8,
    },
    dropdownItems: {
        display: 'flex',
        alignItems: 'center',
        padding: '7px 4px 7px 16px',
        fontSize: 16,
        color: '#2B2D33',
        border: '1px solid #D1D5DD',
        borderRadius: 5,
    },
    dropdownArror: {
        color: '#2B2D33',
        fontSize: 24,
        margin: '0 4px'
    },
    totalCardDiv: {
        border: '1px solid #E6EAF0',
        display: 'flex',
        // flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    totalLabel: {
        fontSize: 14,
        color: '#2B2D33',
        width: '50%',
        textAlign: 'center',
        padding: '12px 0'
    }
}

export default PaymentHistoryTable;