import React from 'react'
import PaymentDetails from './payment_details'
import Main from '../../layouts/Main'
import MobileMain from '../../layouts/Mobile/MobileMain'
import * as Icons from '../../static/images/home/index';
import {Layout} from 'antd'
import {history} from '../../store/history'
import useCustomMediaQuery from '../../hooks/useCustomMediaQuery'

const PaymentDetailsRender = props =>{
    const media = useCustomMediaQuery()

    const uid = props.match.params.uuid;
    const {Header} = Layout

    let logo = Icons.WebBux;

    return(
        <div>
            {   
                (localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null) ?

                <div>
                    <Header style={{width:'100%', height: '60px', display: 'flex', justifyContent: 'center', backgroundColor: '#fff', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)', position: 'fixed', zIndex: '1'}}>
                        <img src={logo} 
                        style={{width: 84, marginLeft: 24}}
                        alt="BUx Logo"
                        onClick={()=>history.push('/login')}
                         />
                        {/* <img src={logo} style={{marginBottom: '20px', marginTop: '12px', maxWidth: 80}} alt="#" onClick={()=>history.push('/login')}/> */}
                    </Header>

                    <PaymentDetails logo={logo} uid={uid}/>
                </div>

                : media.mobile ?

                <MobileMain title={"Payment Details"}>
                    <PaymentDetails logo={logo} uid={uid}/>
                </MobileMain>

                :
                <Main>
                    <PaymentDetails logo={logo} uid={uid}/>
                </Main>
            }
        </div>
    )
}

export default PaymentDetailsRender