import axios from 'axios';
import {Handle401} from '../../handle401/handle401';

const authorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const orderServices = {
  getTableData: async (type, cardType, start, end, page, pageSize, search, summary) => {
    try {
      const res = await authorizedAxiosRequest().get(`/api/orders/credit_cards/?card_type=${cardType}&type=${type}&start=${start}&end=${end}&page=${page}&page_size=${pageSize}&search=${search}&summary=${summary}&user_type=All`)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getPaymentDetails: async (uuid) => {
    try {
      const res = await authorizedAxiosRequest().get(`/api/payment/${uuid}`)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  refundOrder: async (params) => {
    try {
      const res = await authorizedAxiosRequest().post(`/api/orders/refund/`, params)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
}

export default orderServices;