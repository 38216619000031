import { Input, Typography } from 'antd';
import React from 'react';
import enterpriseStyle from './kybStyle'
import { red } from '@material-ui/core/colors';

const { Text } = Typography;

const KYBCustomInput = (props) => {
    const kyb = enterpriseStyle();

    const {
        disabled = false,
        error = null,
        helpText = null,
        name = null,
        onBlur = () => {},
        onChange = () => {},
        opacity = 0,
        placeholder = null,
        readOnly = false,
        size = null,
        value = null,
    } = props;

    const warningColor = helpText ? 'red' : '';
    const background = readOnly ? '#f5f5f4' : '';
    const emailFormat = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i


    return (
        <div>
            <Input
                style={{
                    borderColor: warningColor,
                    background: background
                }}
                disabled={disabled}
                name={name}
                maxLength={name == "email" ? 128 : 30}
                onChange={e => onChange(e)}
                onBlur={e => onBlur(e)}
                placeholder={placeholder}
                readOnly={readOnly}
                size={size}
                value={value}
            />
            <Text className={kyb.miniText} style={{color: warningColor}}>
                {helpText ? helpText : ''}
            </Text>
        </div>
        
    );
}

export default KYBCustomInput;