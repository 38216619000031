import React, {useEffect} from 'react';
import {Spin} from 'antd';
import axios from 'axios'
import OtpForm from "../login/authComponents/otpForm";


const Sentro = props => {


    const [successFormHidden, setSuccessFormHidden] = React.useState(true)
    const [errorFormHidden, setErrorFormHidden] = React.useState(true)
    const [loadingFormHidden, setLoadingFormHidden] = React.useState(false)
    const [otpFormHidden, setOtpFormHidden] = React.useState(true)
    const [userId, setUserId] = React.useState('')
    const [otp, setOtp] = React.useState('')
    const [userContact, setUserContact] = React.useState('')
    const [otpUid, setOtpUid] = React.useState('')

    useEffect(() => {

        localStorage.setItem("userId", "");
        localStorage.setItem("jwtToken", "");
        localStorage.setItem("firstName", "");
        localStorage.setItem("lastName", "");
        localStorage.setItem("middleName", "");
        localStorage.setItem("suffix", "");
        localStorage.setItem("username", "");
        localStorage.setItem("email", "");
        localStorage.setItem("contact", "");
        localStorage.setItem("userType", "");
        localStorage.setItem("isCompleted", "");
        localStorage.setItem("isVerified", "");
        localStorage.setItem("Step", "")
        localStorage.setItem("Level", "")
        localStorage.setItem("lastLogin", "")
        localStorage.setItem("shipping_enabled", "")
        localStorage.setItem('tradeName', "")
        localStorage.setItem('segments', "me_")
        sentroLogin()
            
    }, []);

    async function sentroLogin () {

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let sentro_access_token = params.get('access');

        let next = params.get('next');
        let response = await axios.get(
            process.env.REACT_APP_API_URL + "/api/sentro/login/?access_token="+sentro_access_token
        );
        setLoadingFormHidden(true)
        if(response.data.status == "success"){
            let token = response.data.access_token;
            let userDetails = response.data.user_details;
            console.log(userDetails);

            if(response.data.access_token){
                localStorage.setItem("jwtToken", token);
                localStorage.setItem("userId", userDetails.id);
                localStorage.setItem("firstName", userDetails.first_name);
                localStorage.setItem("lastName", userDetails.last_name);
                localStorage.setItem("middleName", userDetails.middle_name);
                localStorage.setItem("suffix", userDetails.suffix);
                localStorage.setItem("username", userDetails.username);
                localStorage.setItem("email", userDetails.email);
                localStorage.setItem("contact", userDetails.contact);
                localStorage.setItem("balance", userDetails.balance);
                localStorage.setItem("userType", userDetails.user_type)
                localStorage.setItem("isCompleted", userDetails.is_completed)
                localStorage.setItem("isVerified", userDetails.is_verified)
                localStorage.setItem("has_address", userDetails.address)
                localStorage.setItem('Level', userDetails.kyc_level)
                localStorage.setItem("Step", userDetails.kyc_step)
                localStorage.setItem("lastLogin", userDetails.last_login)
                localStorage.setItem("shipping_enabled", userDetails.shipping_enabled)
                let segment = userDetails.bux_segment.toLowerCase();
                if(segment == 'nil'){
                  segment = "me_";
                }
                localStorage.setItem("segments", segment)
                setSuccessFormHidden(false)
                
                if(next != null && next.length > 0 && next[0] == '/') {
                  window.location.href = next

                }
                else{
                    window.location.href = '/dashboard'
                }
            }else{
                setOtp(response.data.otp)
                setUserId(userDetails.id)
                setUserContact(userDetails.contact)
                setOtpUid(response.data.otp_uid)
                setOtpFormHidden(false)
            }
        }else{
            setErrorFormHidden(false)
        }
    }
    
    const verifyStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '34px',
        color: '#2B2D33',
        marginBottom: '10px'
    }

    const textStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.01em',
        color: '#2B2D33',
        opacity: '0.8',
        marginBottom: '20px'
    }


    return(
        <div style={{display:'flex', justifyContent: 'center'}}>
            <div style={{paddingLeft: '57px',paddingRight: '57px', paddingTop: '10vh', justifyContent: 'center', alignItems:'center', width: '559px'}}>

                <div hidden={loadingFormHidden}>
                    <div style={verifyStyle}>Logging in to your account</div>
                    <div style={textStyle}>Please wait ...</div>
                    <div style={{display:'flex', justifyContent: 'center'}}>
                        <Spin size="large" />
                    </div>
                </div>
                <div hidden={otpFormHidden}>
                    <OtpForm otp={otp} userid={userId} mobile={userContact} otp_uid={otpUid}/>
                </div>

                <div hidden={successFormHidden}>
                    <div style={verifyStyle}>Login Successful</div>
                    <div style={textStyle}>Please wait while we redirect ...</div>
                    <div style={{display:'flex', justifyContent: 'center'}}>
                        <Spin size="large" />
                    </div>
                </div>

                <div hidden={errorFormHidden}>
                    <div style={verifyStyle}>Sentro Login Invalid</div>
                    <div style={textStyle}>Please try again.</div>
                </div>
            </div>
        </div>
    )
    
}

export default Sentro