import React,{} from 'react';
import bgImage from '../../../static/images/testModeInfoBG.svg';
import { Modal, Typography, Button } from 'antd';

const TestModeInfoModal = ({visible, closeModal, segments}) => {
  return (
    <Modal
      visible={visible} 
      footer={null} 
      onCancel={closeModal}  
      width={568}
      bodyStyle={styles.root}
      centered
    >
      <Typography style={styles.title}>
        Run your BUx in Test Mode
      </Typography>
      <img src={bgImage} />
      <Typography style={{...styles.regFont, paddingBottom: 0}}>
        Test Mode is a feature that allows you to do test transactions on your test environment. 
        You may also simulate a Payment by generating a payment link or via API.
      </Typography>
      <Typography style={styles.regFont}>
        You may then simulate a paid transaction by going to
        <br /><strong>Payment Request > Pending > Action > Simulate Payment</strong>
      </Typography>
      <button className={`btn--${segments} btn-height btn-mobile-width`} onClick={closeModal}>
        Awesome!
      </button>
    </Modal>
  )
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBtn: {
    margin: '16px 0px 0px 0px',
    height: 48,
    width: '100%',
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10
  },
  title: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 'bold',
    color: '#2B2D33',
    padding: '28px 0 22px 0'
  },
  regFont: {
    fontSize: 16,
    color: '#2B2D33',
    padding: '16px 0',
    textAlign: 'center',
  }
};

export default TestModeInfoModal;