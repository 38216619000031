import React,{ useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Card, Dropdown, Menu, Typography, Input } from 'antd';
import { history } from '../../../store/history';
import cardActions from '../cardActions';
import { isMobile } from 'react-device-detect';
import { TopUpsGaming, TopUpsTelecom, TopUpsTranspo, TopUpsUtilities } from '../../../static/icons/dashboard_icons';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const menu = (
  <Menu>
    <Menu.Item key="1">
      1st menu item
    </Menu.Item>
    <Menu.Item key="2">
      2nd menu item
    </Menu.Item>
    <Menu.Item key="3">
      3rd item
    </Menu.Item>
    <Menu.Item key="4">
      1st menu item
    </Menu.Item>
    <Menu.Item key="5">
      2nd menu item
    </Menu.Item>
    <Menu.Item key="6">
      3rd item
    </Menu.Item>
  </Menu>
);

const fieldStyle = {
  inputContainer: {
    display: 'flex', 
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between', 
    padding: '10px 0',
  },
  inputWidth: {
    width: isMobile ? '100%' : '48%'
  },
  btn: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'space-between', 
    width: '100%', 
    height: 48,
    fontSize: 16
  }
}

const renderFields = (key) => {
  return (
    <div>
       <div style={fieldStyle.inputContainer}>
        <div style={fieldStyle.inputWidth}>
          <label style={styles.labelFont}>{key == 'gaming' ? 'Select Game' : key == 'telecom' ? 'Network' : key == 'transpo' ? 'Beep' : 'Utilities'}</label>
          <Dropdown overlay={menu} trigger='click' overlayStyle={{padding: '12px 16px'}}>
            <Button style={fieldStyle.btn}>
              Metro Manila <ExpandMoreIcon />
            </Button>
          </Dropdown>
        </div>
        {key == 'transpo' ?
          <div style={fieldStyle.inputWidth}>
            <label style={styles.labelFont}>Card No.</label>
            <Input style={fieldStyle.btn}></Input>
          </div>
          : 
          <div style={fieldStyle.inputWidth}>
            <label style={styles.labelFont}>{key == 'gaming' ? 'Packages' : key == 'telecom' ? 'Prepaid' : 'Utilities'}</label>
            <Dropdown overlay={menu} trigger='click' overlayStyle={{padding: '12px 16px'}}>
              <Button style={fieldStyle.btn}>
                Metro Manila <ExpandMoreIcon />
              </Button>
            </Dropdown>
          </div>
        }
      </div>
      <Typography style={{fontSize: 16, color: '#000000', padding: '10px 0 6px 0', display: `${key}` == 'gaming' || key == 'telecom' ? 'block' : 'none'}}>Recipient Details</Typography>
      {key == 'gaming' ? 
        <div style={fieldStyle.inputContainer}>
          <div style={fieldStyle.inputWidth}>
            <label style={styles.labelFont}>Name</label>
            <Input style={fieldStyle.btn}></Input>
          </div>
          <div style={fieldStyle.inputWidth}>
            <label style={styles.labelFont}>Email</label>
            <Input style={fieldStyle.btn}></Input>
          </div>
        </div>
      : null
      }
      {
        key == 'gaming' || key == 'telecom' ? 
        <div style={fieldStyle.inputContainer}>
          <div style={fieldStyle.inputWidth}>
            <label style={styles.labelFont}>Mobile Number</label>
            <Input style={fieldStyle.btn}></Input>
          </div>
        </div>
      : key == 'utilities' ? 
        <div style={fieldStyle.inputContainer}>
          <div style={fieldStyle.inputWidth}>
            <label style={styles.labelFont}>Account No.</label>
            <Input style={fieldStyle.btn}></Input>
          </div>
        </div>
      : null }
    </div>
  )
} 

const TopUps = () => {
  const dispatch = useDispatch();
  const selectedTopUps = useSelector(state => state.card.selectedTopUps);
  const [initialized, setInitialized] = useState(false);
  const segments = localStorage.getItem('segments');

  if (!initialized) {

    const current_path = window.location.pathname
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
        history.push('/login?next=' + current_path)
    } else {
      if (isMobile) {
        history.push('/mobile/top_ups');
        setInitialized(true);
      } else {
        setInitialized(true);
      }
    }

  }

  const handleDropdownClick = ({key}) => {
    dispatch(cardActions.setSelectedTopUps(key));
  }

  const setBorderColor = (key) => {
    switch (key) {
      case 'gaming':
        return '#1E3799';
      case 'telecom':   
        return '#26DE81';
      case 'transpo':   
        return  '#2BCBBA';
      case 'utilities':   
        return '#45AAF2';
      default: 
        return '#1E3799';
    }
  }

  const setIcon = (key) => {
    switch (key) {
      case 'gaming':
        return TopUpsGaming;
      case 'telecom':   
        return TopUpsTelecom;
      case 'transpo':   
        return  TopUpsTranspo;
      case 'utilities':   
        return TopUpsUtilities;
      default: 
        return TopUpsGaming;
    }
  }

  const menu = (
    <Menu onClick={handleDropdownClick}>
      <Menu.Item key="gaming">
        Gaming
      </Menu.Item>
      <Menu.Item key="telecom">
        Telecoms
      </Menu.Item>
      <Menu.Item key="transpo">
        Transpo
      </Menu.Item>
      <Menu.Item key="utilities">
        Utilities
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={styles.root}>
       <div style={styles.title}>
        <button hidden={isMobile} className={`btn--${segments}`} style={styles.btnBack} onClick={() => history.goBack()}>{<Icon className={`link-text--${segments}`} type="left" />} Back</button>
        <strong style={styles.titleFont}>Top-Ups</strong>
      </div>
      <div style={styles.cardContainer}>
        <Card 
          style={{...styles.card, borderTop: `4px solid ${setBorderColor(selectedTopUps)}`}} 
          bodyStyle={{borderTop: `3px solid ${setBorderColor(selectedTopUps)}`}}
        >
          <div style={styles.cardTitle}>
            <img alt='No Image' src={setIcon(selectedTopUps)} />
            <Dropdown overlay={menu} trigger='click' >
              <a style={styles.dropdownItems} >
                {
                  selectedTopUps == 'telecom' ? selectedTopUps.charAt(0).toUpperCase() + selectedTopUps.substring(1) + 's' :
                  selectedTopUps.charAt(0).toUpperCase() + selectedTopUps.substring(1)
                }
                <ExpandMoreIcon style={styles.dropdownArror} />
              </a>
            </Dropdown>
          </div>
          {renderFields(selectedTopUps)}
          <button className={`btn--${segments}`} style={styles.btnContinue}>Continue</button>
        </Card>
      </div>  
    </div>
  )
}

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    width: isMobile ? '100%' : 540, 
    padding: isMobile ? '8px 20px' : '50px 0px 44px 0px',
  },
  btnBack: {
    border: 0, 
    borderRadius: 40, 
    fontSize: 14
  },  
  titleFont: {
    fontSize: 28,
    color: '#000000',
    width: '100%',
    padding: isMobile ? '10px 0px' : '8px 118px', 
    margin: isMobile ? '0 82px 0 0' : 0, 
    textAlign: isMobile ? 'center' : 'left',
  },
  labelFont: {
    fontSize: 12,
    color: '#212B36'
  },
  btnContinue: {
    margin: '16px 0px 0px 0px',
    height: 40,
    width: '100%',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
  },
  card: {
    borderRadius: 10,
  },
  cardContainer : {
    padding: isMobile ? '0px 20px 20px 20px' : 0, 
    width: isMobile ? '100%' : 540,
  },
  cardTitle: {
    display: 'flex', 
    justifyContent: 'start', 
    alignItems: 'center', 
    padding: ' 0 0 28px 0'
  },
  dropdownItems: {
    display: 'flex', 
    alignItems: 'center', 
    padding: '0 16px', 
    fontWeight: '300', 
    fontSize: 24, 
    color: '#2B2D33'
  },
  dropdownArror: {
    color: '#2B2D33', 
    fontSize: 32, 
    margin: '0 4px'
  },
}

export default TopUps;