 import React from 'react';
import GenerateLink from './reusable_content/generate_link'
import ProfileStep from './reusable_content/profile';

const ContentStep7 = props => {
    
    const {
        segments
    } = props;

    const userType = localStorage.getItem('userType')

    const isIndividual = userType === 'ME'

    return (
        <div>
            {
                isIndividual ?
                    <ProfileStep segments={segments} step={7} />
                    :
                    <GenerateLink segments={segments} step={7} />

            }
            
        </div>
    )
}

export default ContentStep7;