import React from 'react'
import FeeStyles from '../style'

const ChannelList = props => {
    let data = props.props;
    const fee = FeeStyles()
    const [channels, setChannels] = React.useState([]);
    
    React.useEffect(() => {
        let cs = [...data.channels];
        let enabled = [];
        let disabled = data.disabled;
        for(var i=0;i<cs.length;i++){

            let ind = disabled.indexOf(cs[i].channel_code);
            if(ind == -1){
                enabled.push(cs[i]);
            }
        }
        setChannels(enabled)
    },[data.disabled])
    
    return(
        <div style={{ padding: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {
            channels.map((item, key) => {
                return (<span className={fee.spanText}>{item.channel}</span>)
            })
        }
        </div>
    )
}

export default ChannelList