import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Typography,
  Form,
  Card,
  Select,
  Input,
  Upload,
  Button,
  Icon,
  Modal,
  notification,
} from "antd";
import { createBankAccount } from "./api";
import { validateAccountNameLength } from "../../../utils/validators";

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;

const eWallets = [
  "GCash (G-Xchange Inc.)",
  "Paymaya",
  "DCPay Philippines, Inc.(Coins.ph)",
];

function CreateBankAccount({ form, banksList }) {
  const [fileList, setFileList] = useState([]);
  const [formData, setFormData] = useState({
    bank: "",
    account_name: "",
    account_number: "",
    account_type: "",
    branch: "",
    proof_of_ownership: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [isImagePreviewVisible, setIsImagePreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const existingBank = location.state ? location.state : null;
  const filteredBanksList = banksList.filter(
    (bank) => !eWallets.includes(bank.name),
  );

  const handleInputChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields(async (err, _) => {
      if (!err) {
        const newFormData = new FormData();
        Object.keys(formData).forEach((key) => {
          // If there's an existing bank account and the user hasn't replaced the proof of ownership file
          // or if there's no existing bank account, pass the existing proof_of_ownership URL
          if (
            (existingBank &&
              key === "proof_of_ownership" &&
              fileList.length === 0) ||
            !existingBank
          ) {
            newFormData.append(key, formData[key]);
          } else if (key === "proof_of_ownership" && fileList.length > 0) {
            // If the user replaces the proof of ownership file, use the new file
            newFormData.append(key, fileList[0].originFileObj);
          } else {
            newFormData.append(key, formData[key]);
          }
        });

        setIsLoading(true);
        // Call API with formData
        // If there is not an existing bank, it will do a POST
        // Else, it will do a PUT
        try {
          await createBankAccount({ data: newFormData }, existingBank !== null);
          history.goBack();
          setIsLoading(false);
          notification.success({
            message: "Success",
            description: "Your bank account has been sent for approval!",
          });
        } catch (error) {
          console.error("Error creating bank account: ", error);
          setIsLoading(false);
          notification.error({
            message: "Failed",
            description: "Something went wrong! Please try again.",
          });
        }
      }
    });
  };

  const handleCancel = () => {
    setFormData({
      bank: "",
      account_name: "",
      account_number: "",
      account_type: "",
      branch: "",
      proof_of_ownership: "",
    });
    setFileList([]);
    history.goBack();
  };

  const onPreview = () => {
    setIsImagePreviewVisible(true);
  };

  useEffect(() => {
    if (existingBank) {
      const {
        bank,
        account_name,
        account_number,
        account_type,
        branch,
        proof_of_ownership,
      } = existingBank;
      setFormData({
        bank,
        account_name,
        account_number,
        account_type,
        branch,
        proof_of_ownership,
      });

      // If there's an existing proof_of_ownership URL, set it in the fileList state
      if (proof_of_ownership) {
        const fileObj = {
          uid: "-1", // a unique identifier is required
          name: "proof_of_ownership",
          status: "done",
          url: proof_of_ownership, // assign the URL to display the image
        };
        setFileList([fileObj]);
        setPreviewImage(proof_of_ownership);
      }
    }
  }, []);

  return (
    <Form
      onSubmit={handleSubmit}
      style={{ display: "flex", flexDirection: "column", gap: "1em" }}
    >
      <Title level={3}>Link your bank account to CashBux</Title>
      <Card>
        <Item label="Choose bank">
          {form.getFieldDecorator("bank", {
            initialValue: formData.bank,
            rules: [{ required: true, message: "Please select a bank!" }],
          })(
            <Select
              placeholder="Select a bank"
              size="large"
              showSearch
              filterOption={(input, option) =>
                option.props.value.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => handleInputChange("bank", value)}
            >
              {filteredBanksList.map((bank) => {
                return (
                  <Option key={bank.id} value={bank.name}>
                    {bank.name}
                  </Option>
                );
              })}
            </Select>,
          )}
        </Item>

        <Item label="Input your account name">
          {form.getFieldDecorator("account_name", {
            initialValue: formData.account_name,
            rules: [
              { required: true, message: "Please input an account name!" },
              { validator: (_, value) => validateAccountNameLength(value) },
            ],
          })(
            <Input
              type="text"
              size="large"
              onChange={(e) =>
                handleInputChange("account_name", e.target.value)
              }
            />,
          )}
        </Item>

        <Item label="Input your account number">
          {form.getFieldDecorator("account_number", {
            initialValue: formData.account_number,
            rules: [
              { required: true, message: "Please input an account number!" },
            ],
          })(
            <Input
              type="number"
              size="large"
              onChange={(e) =>
                handleInputChange("account_number", e.target.value)
              }
            />,
          )}
        </Item>

        <Item label="Choose an account type">
          {form.getFieldDecorator("account_type", {
            initialValue: formData.account_type,
            rules: [
              { required: true, message: "Please select an account type!" },
            ],
          })(
            <Select
              placeholder="Select an account type"
              size="large"
              onChange={(value) => handleInputChange("account_type", value)}
            >
              <Option key={1} value={"SA"}>
                Savings
              </Option>
              <Option key={2} value={"CH"}>
                Checking
              </Option>
            </Select>,
          )}
        </Item>

        <Item label="Input your bank's branch">
          {form.getFieldDecorator("branch", {
            initialValue: formData.branch,
            rules: [
              { required: true, message: "Please input your bank's branch!" },
            ],
          })(
            <Input
              type="text"
              size="large"
              onChange={(e) => handleInputChange("branch", e.target.value)}
            />,
          )}
        </Item>

        <Item label="Upload proof of ownership">
          {form.getFieldDecorator("proof_of_ownership", {
            initialValue: formData.proof_of_ownership,
            rules: [
              { required: true, message: "Please upload proof of ownership!" },
            ],
          })(
            <Upload
              name="proof_of_ownership"
              listType="picture"
              fileList={fileList}
              onChange={(info) => {
                let newFileList = [...info.fileList];
                newFileList = newFileList.slice(-1); // Restrict to only one file
                const file =
                  newFileList.length > 0 ? newFileList[0].originFileObj : "";
                setFormData({
                  ...formData,
                  proof_of_ownership: file,
                });

                if (file) {
                  const previewUrl = URL.createObjectURL(file);
                  setPreviewImage(previewUrl);
                } else {
                  setPreviewImage(null);
                }

                setFileList(newFileList);
              }}
              accept="image/jpeg, image/pjpeg, image/png, image/gif"
              beforeUpload={() => false} // Prevent automatic upload
              showUploadList={{ showRemoveIcon: false }}
              onPreview={onPreview}
            >
              <Button>
                <Icon type="upload" />{" "}
                {fileList.length > 0 ? "Replace" : "Click to upload"}
              </Button>
            </Upload>,
          )}
        </Item>
      </Card>

      <div style={{ display: "flex", justifyContent: "flex-end", gap: "1em" }}>
        <Item>
          <Button
            type="button"
            htmlType="button"
            size="large"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Item>

        <Item>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            size="large"
          >
            Submit
          </Button>
        </Item>
      </div>
      <ProofOfOwnershipPreview
        url={previewImage && previewImage}
        isImagePreviewVisible={isImagePreviewVisible}
        setIsImagePreviewVisible={setIsImagePreviewVisible}
      />
    </Form>
  );
}

export default Form.create({ name: "create_bank_account" })(CreateBankAccount);

function ProofOfOwnershipPreview({
  url,
  isImagePreviewVisible = false,
  setIsImagePreviewVisible,
}) {
  return (
    <Modal
      title="Image Preview"
      visible={isImagePreviewVisible}
      onCancel={() => setIsImagePreviewVisible(false)}
      footer={null}
    >
      <img
        src={url}
        alt="Proof of Ownership preview"
        style={{ width: "100%" }}
      />
    </Modal>
  );
}
