import React from 'react';
import { Select, Button, Icon, Input, Card, Spin, Typography, DatePicker } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import orderServices from '../orderServices';
import RefundModal from '../debit_credit/refundModal';
import Cards from '../debit_credit/cards';
import SuccessRefundModal from './successRefundModal';
import { useSelector } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';

const DebitCreditRequest = () => {
  const [dateRange, setDateRange] = React.useState({startDate: '', endDate: ''});
  const [cardType, setCardType] = React.useState('All');
  const [tableData, setTableData] = React.useState([]);
  const [selected, setSelected] = React.useState('Charged');
  const [refundModalVisible, setRefundModalVisible] = React.useState(false);
  const [refundSuccess, setRefundSuccess] = React.useState(false);
  const refundDetails = useSelector(state => state.mobileOrders.refundDetails);
  const [pageSize, setPageSize] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [minPage, setMinPage] = React.useState(0);
  const [maxPage, setMaxPage] = React.useState(pageSize);
  const [summary, setSummary] = React.useState(null);
  const [onSearch, setOnSearch] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState(false);
  const [tableSumamry, setTableSummary] = React.useState('All');
  
  const ButtonTab = ({rootStyle}) => {
    
    const status = ['Charged', 'Refunded'];
    
    const handleClick = (event) => {
      setSelected(event.target.value);
    }
    
    return (
      <div style={rootStyle}>
        <ButtonGroup style={{width: '100%'}}>
          {
            status.map((btn, i) => {
              return (
                <Button
                  key={btn}
                  value={btn}
                  onClick={handleClick}
                  style={{...styles.btnGroup, 
                    color: btn === selected? '#FFF' : '#2B2D33', 
                    backgroundColor: btn === selected? '#0D3D76' : '#FFF',
                    borderRadius: i === 0? '10px 0 0 10px' : i === 1? '0 10px 10px 0' : 0 }}
                    >
                    {btn}
                </Button>
              )
            })
          }
        </ButtonGroup>
      </div>
    )
  }

  const cardTypeChange = (e) => {
    setCardType(e);
    setTableSummary('All')
    getTableData(selected, e, dateRange.startDate, dateRange.endDate, currentPage, pageSize, searchValue, 'All');
  }

  const handleSearch = (e) => {
    setSearchValue(e);
    setTableSummary('All')
    getTableData(selected, cardType, dateRange.startDate, dateRange.endDate, currentPage, pageSize, e.target.value, 'All');
  }

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }

  const rangeSelect = (dates, dateString, id) => {
    setDateRange({...dateRange, [id]: moment(dateString).format('MM-DD-YYYY')});
    if(id==='startDate'&& dateRange.endDate) {
      getTableData(selected, cardType, moment(dateString).format('MM-DD-YYYY'), dateRange['endDate'], currentPage, pageSize, searchValue, 'Custom');
    } else if (id==='endDate' && dateRange.startDate) {
      getTableData(selected, cardType, dateRange['startDate'], moment(dateString).format('MM-DD-YYYY'), currentPage, pageSize, searchValue, 'Custom');
    } 
  };

  const CardSpinner = () => {
    return (
      <Card style={{borderRadius: 10}} bodyStyle={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 380}}>
        <Spin size='large' />
      </Card>
    )
  }

  const Filter = () => {
    return (
      <div style={styles.filterContainer}>
        <Select size="large" style={styles.select} defaultValue={cardType} onChange={(e) => cardTypeChange(e)} >
        <Select.Option value="All">Card Type</Select.Option>
        <Select.Option value="VISA">Visa</Select.Option>
        <Select.Option value="MasterCard">MasterCard</Select.Option>
        </Select>
        <a onClick={() => setOnSearch(true)}><Icon type='calendar' style={styles.icon} /></a>
        <a onClick={() => setOnSearch(true)}><Icon type='search' style={{...styles.icon, fontSize: 20}} /></a>
      </div>
    )
  }

  const search = (
    <Card bodyStyle={{padding: 16}} style={{borderRadius: 10}}>
      <Typography style={styles.searchTitle}>Search Credit/Debit</Typography>
      <Icon type='close' style={styles.searchClose} onClick={() => setOnSearch(false)} />
      <div style={styles.dateRangeContainer}>
        <DatePicker 
          format='MM/DD/YYYY' 
          disabledDate={disabledDate} 
          // defaultValue={moment()} 
          // value={dateRange&&moment(dateRange.startDate)} 
          size="large" picker="month" 
          onChange={ (dates, dateString) => rangeSelect(dates, dateString, 'startDate')} 
          placeholder='Start date' 
          style={styles.datePic} 
          suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{fontSize: 22}} /></div>}
          allowClear={false}/>
        <span style={styles.span}>-</span>
        <DatePicker 
          format='MM/DD/YYYY' 
          disabledDate={disabledDate} 
          // defaultValue={moment()}
          // value={dateRange&&moment(dateRange.endDate)} 
          size="large" 
          picker="month" 
          onChange={ (dates, dateString) => rangeSelect(dates, dateString, 'endDate')} 
          placeholder='End date' 
          style={styles.datePic}
          suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{fontSize: 22}} /></div>}
          allowClear={false}/>
      </div>
      <Input
        placeholder="Search"
        style={{ width: '100%', height: '40px', borderRadius:'4px' }}
        suffix={<Icon type="search" style={{fontSize:'18px'}} />}
        onChange={handleSearch}
      />
    </Card>
  )

const getTableData = async (tableType, cardType, start, end, page, pageSize, search, summary) => {
  setLoading(true);
  try {
    const res = await orderServices.getTableData(tableType, cardType, start, end, page, pageSize, search, summary);
    if(res.orders){
      setTableData(res.orders);
      setSummary(res.summary);
      setLoading(false);
    }else{
      setTableData([]);
      setSummary({});
      setLoading(false);

    }
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
}

const changePage = (page, pageSize) => {
  setMaxPage(page * pageSize)
  setMinPage((page - 1) * pageSize)
  if(summary.page_total > 1){
    setCurrentPage(page)
    getTableData(selected, cardType, dateRange.startDate, dateRange.endDate, page, pageSize, searchValue, true);
  }
}

const changePageSize = (current, size) => {
  setMaxPage(current * size)
  setMinPage((current - 1) * size)
  if(summary.page_total > 1){
    setPageSize(size)
    getTableData(selected, cardType, dateRange.startDate, dateRange.endDate, currentPage, size, searchValue,true);
  }
}

  React.useEffect(() => {
    getTableData(selected, cardType,'','', currentPage, pageSize, '', tableSumamry);
    setDateRange({startDate: ''}, {endDate: ''});
    setSearchValue('');
    setCardType('All');
  },[selected])

  return (
    <div style={styles.root}>
      <div hidden={onSearch}><ButtonTab /></div>
      <div hidden={onSearch}><Filter /></div>
      <div hidden={!onSearch}>
        {search}
      </div>
      <div hidden={loading}>
        <Cards data={tableData&&tableData} selected={selected} onRefund={() => setRefundModalVisible(true)} />
        <Pagination
            size='small'
            total={summary&&summary.page_total}
            defaultPageSize={pageSize}
            defaultCurrent={1}
            current={currentPage}
            showSizeChanger
            pageSize={pageSize}
            showTotal={(totalPage, range) => `${range[0]}-${range[1]} of ${totalPage} items`}
            onChange={(page, pageSize) => changePage(page, pageSize)}
            onShowSizeChange={(current, size) => changePageSize(current, size)}
            style={styles.pagination}
          />
      </div>
      <div hidden={!loading}>
        <CardSpinner />
      </div>
      <RefundModal visible={refundModalVisible} onCancel={() => setRefundModalVisible(false)} onSuccess={() => {setRefundModalVisible(false); setRefundSuccess(true)}}/>
      <SuccessRefundModal visible={refundSuccess} closeModal={() => {setRefundSuccess(false);setSelected('Refunded')}} data={refundDetails} />
    </div>
  )
}

const styles = {
  root: {
    backgroundColor: '#F4F6F9', 
    height: '100%',
    width: '100%',
    padding: 16,
  },  
  btnGroup: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '0 32px',
    width: '50%',
  },
  select: {
    width: '80%',
    height: 40,
    fontSize: 14,
    color: '#2B2D33',
    textAlign: 'center'
  },
  icon:{
    fontSize: 28,
    color: '#000',
    padding: '4px 8px',
  },
  filterContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0'
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 0'
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 4
  },
  searchTitle: {
    color: '#000',
    fontWight: '500',
    fontSize: 16,
    textAlign: 'center',
    padding: '4px 0 16px 0',
  },
  searchClose: {
    color: '#F5922F',
    fontSize: 24,
    position: 'absolute',
    right: 16,
    top: 20,
  },
  datePic: {
    height: 40,
    fontSize: 14,
    color: '#2F3542',
    width: '48%',
  },
  suffixIcon: {
    marginTop: '-12px', 
    marginRight: 12, 
    color: '#000'
  },
  dateRangeContainer: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    padding: '4px 0 12px 0'
  },
  span: {
    padding: '0 8px'
  }
}

export default DebitCreditRequest;