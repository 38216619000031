import React,{useEffect} from 'react';
import axios from 'axios'
import { Card, Icon, Divider, Spin, Button} from 'antd';
import kycStyles from '../../static/css/Kyc';
import * as Style from './application_form/kyc_style'
import {isMobile} from 'react-device-detect';
import MetaTag from '../meta_tag/meta_tag'
import {history} from '../../store/history'
import Questionnaire from '../questionnaire/questionnaire'
import ReviewImg from '../../static/images/review.svg'

const ButtonGroup = Button.Group;

const UpgradeMobile = props => {

    useEffect(() => {
            fetchProfile()
            getDashboardNow()
    }, [])

    const kyc = kycStyles();
    const [step, setStep] = React.useState(0)
    const [dateApplied, setDateApplied] = React.useState("")
    const [applicationCooldown, setApplicationCooldown] = React.useState(null)
    const [selectedLevel, setSelecetedLevel] = React.useState("Basic")
    const [datas, setDatas] = React.useState({})
    const [showModal, setModal] = React.useState(false)

    const level = parseInt(localStorage.getItem("Level"))

    const requirements_data = [
        {
            key: '1',
            level: 'Basic',
            requirements: 
            <div><strong>Personal and Contact Information</strong> (Name, Email, Contact No. and Password)</div>,
        },
        {
            key: '2',
            level: 'Pro',
            requirements: <strong>Basic Requirements + Address, Employment Information and Valid ID</strong>,
        },
        {
            key: '3',
            level: 'Advanced',
            requirements: 
            <div><strong>20 Paid transactions</strong> at any given time.</div>,
        },
        {
            key: '4',
            level: 'Business',
            requirements: <div><strong>100 Paid transactions at</strong> any given time.</div>,
        },
    ]

    const levels = [
        'Basic', 'Pro', 'Advanced', 'Business'
    ]

    const monthly = {
       
        'Basic': {
            level: 0,
            border: '2px solid #65C1F2',
            color: '#65C1F2',
            cashIn: 'PHP 5,000.00',
            cashOut: 'PHP 0.00',
            paid: 0
        },
        'Pro': {
            level: 1,
            border: '2px solid #38ADA9',
            color: '#38ADA9',
            cashIn: 'PHP 100,000.00',
            cashOut: 'PHP 100,000.00',
            paid: 0
        },
        'Advanced': {
            level: 2,
            border: '2px solid #1E3799',
            color: '#1E3799',
            cashIn: 'PHP 500,000.00',
            cashOut: 'PHP 500,000.00',
            paid: 20
        },
        'Business': {
            level: 3,
            border: '2px solid #FFB142',
            color: '#FFB142',
            cashIn: 'PHP 1,000,000.00',
            cashOut: 'PHP 1,000,000.00',
            paid: 100
        },

    }
    async function getDashboardNow(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try {
            let response = await axios.get(
                process.env.REACT_APP_API_URL + '/api/dashboard/', yourConfig
            );
            if(response.data){
                setDatas(response.data)
            }
        }

        catch (error) {

        }
    }
    async function fetchProfile(){

        const yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let response = await axios.get(process.env.REACT_APP_API_URL + '/api/kyc/', yourConfig)

        setStep(response.data.step)
        setDateApplied(response.data.submission_date)
        if(response.data.application_cooldown){
            setApplicationCooldown(response.data.application_cooldown/60/60/24)
        }
    }
    
    return(
        <div>
            <MetaTag title="Upgrade Account"/>
            <div className={kyc.mainBody}>
                <div className={kyc.header} align='left'>Application Status</div>

                <Card
                    style={{...Style.reviewCard, marginTop: '20px'}} 
                    bodyStyle={{padding: '20px 0px 20px 0px'}}>
                    <div style={{padding: '0px 20px 0px 20px'}}>
                        <div style={{width: '100%'}}>
                            <div style={Style.header2Style} align="left">{localStorage.getItem('Step') > 10 ? 'PRO to ADVANCE Upgrade' : 'BASIC to PRO Upgrade'}</div>
                            <div style={{...Style.normalText, color: '#000', marginTop: '5px'}}>Date Application: <b>{dateApplied}</b></div>
                            <div style={{...Style.normalText, color: '#000', display:'flex', marginTop: '10px'}}>
                                Status 
                                <div style={{
                                    border: step == 7 ? '1px solid #F5922F' : step == 9  || step == 14 ? '1px solid #E24C4C' : step == 10 || step == 15 ? '1px solid #1DD28B' : '1px solid #F5922F', 
                                    height: '32px',
                                    width: '97px',
                                    borderLeft: step == 7 ? '4px solid #F5922F' : step == 9 || step == 14 ? '4px solid #E24C4C' : step == 10 || step == 15 ? '4px solid #1DD28B' : '4px solid #F5922F', 
                                    borderRadius: '4px',
                                    textAlign: 'center',
                                    backgroundColor: step == 7 ? 'rgba(245, 146, 47, 0.1)' : step == 9 || step == 14 ? 'rgba(226, 76, 76, 0.1)' : step == 10 || step == 15 ? 'rgba(29, 210, 139, 0.1)' : 'rgba(245, 146, 47, 0.1)',
                                    marginLeft: '10px'
                                }}>
                                    <div style={{...Style.miniText, color: '#000', lineHeight: '32px'}}>{step == 7 ? 'Reviewing' : step == 9 || step == 14 ? 'Rejected' : step == 10 || step == 15 ? 'Approved' : 'Pending'}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img src={ReviewImg} alt="#" style={{position:'absolute', right:0, margin: '-53px 0px 0px 0px', width: isMobile ? '113px' : '162px'}}/>
                        </div>
                    </div>

                    <Divider/>

                    <div style={{textAlign: 'center'}}>
                        <div style={{...Style.normalText, color: '#000'}}>Haven’t received your status yet? Please contact us at <a href="mailto:support@bux.ph" style={{color: '#f5922f', fontWeight: '600'}}><u>support@bux.ph</u></a></div>
                    </div>
                </Card>                
                <div style={{marginTop: '22px'}} align='right' hidden={step == 10}>
                    <a onClick={() => history.push('/complete_profile')} className={kyc.seeMore}>More Details <Icon type="right"/></a>
                </div>

                <div className={kyc.limits}>
                    <div className={kyc.header} align='left'>Individual Account Level</div>
                    
                    <ButtonGroup style={{width:'100%', marginTop: '20px'}}>
                    {
                        levels.map((item) => {
                            return <Button
                            key={item.toLocaleLowerCase()}
                            onClick={() => setSelecetedLevel(item)}
                            style={{
                                padding: '13px 18px 13px 18px',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                lineHeight:'16px',
                                height:'40px',
                                width: item == 'Pro' || item == 'Basic' ? '22%' : '28%' ,
                                borderTopLeftRadius: item == "Basic" ? '10px' : '0px',
                                borderBottomLeftRadius: item == "Basic" ? '10px' : '0px',
                                borderTopRightRadius: item == "Business" ? '10px' : '0px',
                                borderBottomRightRadius: item == "Business" ? '10px' : '0px',
                                color: selectedLevel === item ? '#ffffff' : '#2b2d33',
                                backgroundColor: selectedLevel === item ? '#0D3D76' : '#fff',
                            }}>{item}</Button>
                        })
                    }
                    </ButtonGroup>

                    <div
                        style={{
                            marginTop: '10px',
                            borderRadius: '10px',
                            backgroundColor: '#fff',
                            width: '100%',
                            height: '185px',
                            border: monthly[selectedLevel].border
                        }}>
                        <div 
                            style={{
                                width: '101%', 
                                backgroundColor: monthly[selectedLevel].color, 
                                borderTopLeftRadius: '10px', 
                                borderTopRightRadius: '10px',
                                margin: '-1px',
                                height: '40px'
                            }}>
                            <div align="center" style={{paddingTop: '8px'}}>
                                <div style={{fontSize: '16px', fontWeight: '600', color: '#fff'}}>{selectedLevel}</div>
                            </div>
                        </div>

                        <div style={{
                            color: '#2b2d33', 
                            fontSize: '16px', 
                            display: 'flex',
                            margin: '20px 10px 10px 10px',
                            paddingBottom: '20px',
                            borderBottom: monthly[selectedLevel].border,
                            }}>
                            <div style={{width: '50%'}} align="center">
                                <div>Cash In</div>
                                <div><b>{monthly[selectedLevel].cashIn}</b></div>
                            </div>
                            <div style={{width: '50%'}} align="center">
                                <div>Cash Out</div>
                                <div><b>{monthly[selectedLevel].cashOut}</b></div>
                            </div>
                        </div>
                        
                        <div style={{padding: '0px 10px 0px 10px'}}>
                            <Button 
                                type="primary"
                                onClick={() => 
                                    {
                                        if(level == 0){ 
                                            window.location.href = '/complete_profile' 
                                        }
                                        else if([1,2].includes(level)){
                                            setModal(true)
                                        }
                                    }}
                                disabled={
                                    level == monthly[selectedLevel].level || 
                                    monthly[selectedLevel].level != level + 1 ||
                                    ([1,2].includes(level) && (applicationCooldown && applicationCooldown < 30)) ||
                                    monthly[selectedLevel].paid > datas.paid
                                } 
                                className={
                                    (
                                        level == monthly[selectedLevel].level ||
                                        monthly[selectedLevel].level != level + 1 ||
                                        monthly[selectedLevel].paid > datas.paid ||
                                        ([1,2].includes(level) && (applicationCooldown && applicationCooldown < 30))
                                    ) ? kyc.disableBtn : 
                                    (
                                        level + 1 == monthly[selectedLevel].level &&
                                        monthly[selectedLevel].paid <= datas.paid &&
                                        ([1,2].includes(level) && (!applicationCooldown || (applicationCooldown && applicationCooldown >= 0)))
                                    ) ? kyc.upgradeBtn : kyc.upgradeBtn}>
                                {localStorage.getItem("Level") == monthly[selectedLevel].level ? "Current" : "Upgrade"}
                            </Button>
                        </div>
                    </div>
                </div>


                <div className={kyc.header} align='left'>Requirements</div>
                
                <Card style={{borderRadius: '10px', border: '1px solid #D1D5DD', marginTop: '10px'}}>
                    {
                        requirements_data.map((item,i)=>{
                            return(
                                <div style={{fontSize: '14px', color: '#2b2d33'}}>
                                    <div style={{height: '44px', border: '1px solid #E6EAF0', width: '100%', padding: '12px'}}>
                                        <b>{item.level}</b>
                                    </div>
                                    <div style={{border: '1px solid #E6EAF0', width: '100%', padding: '12px'}}>
                                        {item.requirements}
                                    </div>
                                </div>
                            )
                        })
                    }
                </Card>
            </div>
            <Questionnaire open={showModal} />
        </div>
    )
}


export default UpgradeMobile;