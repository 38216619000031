import axios from 'axios'
import {Handle401} from '../handle401/handle401'

const authorizedAxiosRequest = () => {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
        Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    });
}

const fundServices = {
    exportCsv: async (start='', end='', status, search, summary, email, is_export) => {
        try {
            const res = await authorizedAxiosRequest().get('/api/payouts/?start='+start+'&end='+end+'&status='+status+'&search='+search+'&summary='+summary+'&email='+email+'&is_export='+is_export);
            return res.data;
        } catch (error) {
            if((error.response)&&(error.response.status == 401)){
            Handle401()
            }
        }
    },
}

export default fundServices;