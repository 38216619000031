import { useEffect, useState } from "react";
import { axios } from "../../../../../../lib/axios";
import moment from "moment";

export function getRemittances(currentPage, searchQuery, startDate, endDate, email, isExport) {

  const formattedStartDate = startDate
    ? moment(startDate, "MM-DD-YYYY").format("MM-DD-YYYY")
    : "";
  const formattedEndDate = endDate
    ? moment(endDate, "MM-DD-YYYY").format("MM-DD-YYYY")
    : "";
    const summary = formattedStartDate || formattedEndDate ? "Custom" : false;

  return axios.get(
    `api/remittances/?page=${currentPage}&search=${searchQuery}&start=${formattedStartDate}&end=${formattedEndDate}&email=${email}&is_export=${isExport}&summary=${summary}`,
  );
}

export function useRemittances(currentPage, searchQuery, startDate, endDate) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getRemittances(
          currentPage,
          searchQuery,
          startDate,
          endDate,
        );
        setData(response);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, [currentPage, searchQuery, startDate, endDate]);

  return { data, isLoading, error };
}
