import React, {useEffect} from 'react'
import {Button, Card, Icon} from 'antd'
import {isMobile} from 'react-device-detect'
import SurveyBG from '../../static/img/terms.svg'
import {TERMS} from '../terms/terms_const'
import {POLICIES} from '../terms/privacy_const'
import axios from 'axios'
import {history} from '../../store/history'
import {Handle401} from '../handle401/handle401'
import { backToTop } from '../constants/constants'

const TermsAndPrivacy = () => {

    const [termsCondition, setTermsCondition] = React.useState("");
    const [privacyPolicy, setPrivacyPolicy] = React.useState("")
    const [cmsTerms, setCmsTerms] = React.useState(false)
    const [cmsPrivacy, setCmsPrivacy] = React.useState(false)
    const [tabSelected, setTabSelected] = React.useState("Terms")
    const [hover,setHover] = React.useState(false)

    let segments = localStorage.getItem('segments')
    let isTermsAgree = localStorage.getItem("termsAgreed")

    useEffect(()=>{
        fetchTerms()
        fetchPrivacy()
    },[])

    const fetchTerms = async() => {

        let response = await axios.get(process.env.REACT_APP_API_URL + "/api/content/?key=terms_condition")
            
        if(response.data){
            setTermsCondition(response.data.content)
            setCmsTerms(true)
        }
    }

    const fetchPrivacy = async() => {

        let response = await axios.get(process.env.REACT_APP_API_URL + "/api/content/?key=privacy_policy")
            
        if(response.data){
            setPrivacyPolicy(response.data.content)
            setCmsPrivacy(true)
        }

    }

    async function agree(){
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let response = await axios.get(process.env.REACT_APP_API_URL + '/api/agree_terms/', yourConfig)

        if(response.data.status == "success"){
            history.push("/dashboard")
            localStorage.setItem("termsAgreed", "true")
        }
    }

    const divBackToTop = () => {
        const termsTop = document.getElementById("terms")
        const ppTop = document.getElementById("privacy")
        termsTop.scroll({top:0,behavior:'smooth'});
        ppTop.scroll({top:0,behavior:'smooth'});
    }
 
    return(
        <div className="padding-top-40 padding-bot-40">
            <div className="flex-center bottom-32" align="center">
                <a href onClick={()=>setTabSelected("Terms")} style={{width: isMobile ? '50%' : '211px'}}>
                <div className={`${tabSelected == "Terms" ? `border-bottom-${segments} text-${segments} wc-header padding-bot-20` : 
                                        "normal-text-size unselected-tab liveColor padding-bot-24"}`}>
                        Terms and Conditions
                    </div>
                </a>
                <a href onClick={()=>setTabSelected("Privacy")} style={{width: isMobile ? '50%' : '179px'}}>
                    <div className={`${tabSelected == "Privacy" ? `border-bottom-${segments} text-${segments} wc-header padding-bot-20` : 
                                        "normal-text-size unselected-tab liveColor padding-bot-24"}`}>
                        Privacy Policy
                    </div>
                </a>
            </div>
            <div className="flex-center mobile-main-terms">
                <div>  
                    <div className={`terms-card ${isTermsAgree == 'true' ? "agreed-padding" : ""}`} id="terms-top">
                        <div className="flex-center">
                            <div className="boldTextStyle top-20">
                                {   tabSelected == "Terms" ? 
                                        "TERMS AND CONDITION OF USE" 
                                        : 
                                        "PRIVACY POLICY"
                                }
                            </div>
                        </div>

                        <div id="terms"
                            className={`${isTermsAgree == "true" ? "agreed-height" : "not-agreed-height not-agreed-padding"} terms-agree top-32 scroll-thumb-${segments}`}
                            hidden={tabSelected != "Terms"}
                                >
                            <div className="normaltextStyle liveColor bottom-16">
                                Kindly review these Terms and Conditions of Use before proceeding. 
                                Any use of this website creates a binding agreement and acceptance to comply with these Terms and Conditions and your continuous use of the website constitutes your continuing agreement and acceptance of this Terms and Conditions and to any of its future amendments or revisions. If you do not agree to these Terms and Conditions, you may exit this website immediately.
                            </div>
                            {
                                cmsTerms ? 
                                <div dangerouslySetInnerHTML={{__html: termsCondition}}></div>
                                :
                                <div>
                                    {
                                        TERMS.map((item, key) => {
                                            return(
                                                <div key={key}>
                                                    <div className="wc-header bottom-16">
                                                        {item.q}
                                                    </div>
                                                    <div className="normaltextStyle liveColor bottom-16">
                                                        {item.d}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            }
                            <div className="userNameStyle liveColor">
                                BY CONTINUING, YOU CONSENT TO THE TERMS AND CONDITIONS STATED ABOVE. 
                            </div>

                            <div className={`card-text-size text-${segments} top-40`} align="right">
                                <a href onClick={() => isTermsAgree =='true' ? backToTop() : divBackToTop()} className={`text-${segments}`}>
                                    <Icon className="right-4" type="arrow-up" />
                                    <span>Back to top</span>
                                </a>
                            </div>
                        </div>

                        <div id="privacy" 
                        className={`${isTermsAgree == "true" ? "agreed-height" : "not-agreed-height not-agreed-padding"} terms-agree top-32 scroll-thumb-${segments}`}
                        hidden={tabSelected != "Privacy"}>
                            {
                                cmsPrivacy ? 
                                <div dangerouslySetInnerHTML={{__html: privacyPolicy}}></div>
                                :
                                <div>
                                {
                                    POLICIES.map((item, key) => {
                                        return(
                                            <div key={key}>
                                                <div className="wc-header bottom-16">
                                                    {item.q}
                                                </div>
                                                <div className="normaltextStyle liveColor bottom-16">
                                                    {item.d}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            }

                            <div className={`card-text-size text-${segments} top-40`} align="right">
                                <a href onClick={() => isTermsAgree =='true' ? backToTop() : divBackToTop()} className={`text-${segments}`}>
                                    <Icon className="right-4" type="arrow-up" />
                                    <span>Back to top</span>
                                </a>
                            </div>
                        </div>

                        <div className="terms-footer" hidden={isTermsAgree == "true"}>
                            <div className={`${isMobile ? '' : 'twoCol'} terms-footer-btn-padding`}>
                                <div>
                                    <div className="normalTextStyle charcoal-color">Have some concerns? Feel free to reach out to us at </div>
                                    <a className={`text-${segments} smallBoldStyle`} href="mailto:support@bux.ph">support@bux.ph </a>
                                </div>

                                <div style={{display: 'flex', marginTop: isMobile && '20px'}}>
                                    <button
                                        data-test="decline"
                                        onClick={()=>Handle401()}
                                        onMouseEnter={()=>setHover(true)}
                                        onMouseLeave={()=>setHover(false)}
                                        className={`outline-btn--${segments} btn-width btn-height right-8`}>
                                            Decline
                                    </button>
                                    <button
                                        data-test="agree" 
                                        onClick={()=>agree()}
                                        className={`btn--${segments} btn-width btn-height`}>
                                            Agree
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsAndPrivacy