import React from 'react'
import * as Style from './kyc_style'

const RejectedCard = props => {
    
    return(
        <div style={Style.rejectedCard} hidden={props.details.step != 9}>
            <div style={{...Style.boldText, color: '#E24C4C', marginBottom: '8px'}}>Application was declined</div>
            <div style={{...Style.normalText, color: '#000', marginBottom: '16px'}}>We’re sorry, we cannot proceed with your application due to inconsistencies with the submitted information / documents. Kindly check our notes and resubmit your application.</div>
            <div style={{...Style.boldText, color: '#2b2d33', marginBottom: '4px'}}>Note:</div>
            <div style={{...Style.normalText, color: '#2b2d33'}}>{props.details.remarks}</div>
        </div>
    )
}

export default RejectedCard