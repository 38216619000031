import React from "react";
import DashboardSidebar from '../../dashboard/dashboard_sidebar';
import walletServices from './walletServices';
import { history } from '../../../store/history';
import { useSelector } from 'react-redux';

const Wallet = () => {
    const [balance, setBalance] = React.useState("0.00");
    const [buxbalance, setBuxBalance] = React.useState("0.00");
    const [expiryDate, setExpiryDate] = React.useState("");
    const [cardNumber, setCardNumber] = React.useState(null);
    const [lockCard, setLockCard] = React.useState(null);
    const [noEon, setNoEon] = React.useState(null);
    const [custId, setCustId] = React.useState(null);
    const isChild = localStorage.getItem("is_child") === "true"
    const endpoints = useSelector(state => state.child_endpoints.endpoints);
    const userType = localStorage.getItem('userType');


    const getData = async () => {
        try {
            const res = await walletServices.getData();
            setBuxBalance(res.bux_balance)
            if(res.card.lastFourDigits){
                setNoEon(res.card.lastFourDigits);
                setBalance(res.card.account.availableBalance);
                setCardNumber(res.card.lastFourDigits);
                setExpiryDate(res.card.expiryDate);
                setLockCard(res.card.status == '1' ? false : true);
                setCustId(res.card.customer_id);

            }
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    }

    React.useEffect(() => {
        getData();
    },[])


    React.useEffect(() => {
        if (!isChild || !endpoints.length) {
            return;
        }

        // Redirect if URL was manually typed
        if(!endpoints.includes('orders')) {
            history.push(endpoints[0]);
        }

    }, [endpoints])

    // If endpoints have not been loaded yet for mobile view. Hook on endpoints will handle redirect
    if (isChild && !endpoints.includes('orders')) {
        return null;
    }

    return (
        <div style={{...styles.root, height: cardNumber? '150vh' : '180vh'}}>
            <DashboardSidebar 
                isMobile={true} 
                lockCard={lockCard} 
                balance={balance} 
                cardNumber={cardNumber} 
                buxbalance={buxbalance} 
                expiryDate={expiryDate} 
                noEon={noEon}
                custId={custId}
            />
        </div>
    )
}

const styles = {
    root: {
        padding: '16px 16px 50px 16px',
        background: '#F4F6F9',
    }
}

export default Wallet;