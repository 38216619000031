import React from "react";
import { Input, Button } from "antd";

export function SearchInput({
  tempSearchQuery,
  setTempSearchQuery,
  handleApplySearch,
  placeholder,
}) {
  return (
    <div style={{ display: "flex", gap: "1em" }}>
      <Input
        style={{ width: "400px" }}
        placeholder={placeholder}
        onChange={(e) => setTempSearchQuery(e.target.value)}
        value={tempSearchQuery}
      />
      {handleApplySearch && <Button onClick={handleApplySearch}>Apply</Button>}
    </div>
  );
}
