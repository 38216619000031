import React from 'react';
import { Card, Typography, List } from 'antd';
import { MultiwalletInfoBG } from '../../../../../../../static/new_segment/admin';

const MultiwalletCard = (props) => {
    const { data } = props;
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const testMode = (window.location.pathname.indexOf('/test') > -1);
    let sub_remove_part = window.location.protocol+'//';
    const reactBaseApp = REACT_APP_BASE_URL.replace(sub_remove_part, "");
    const [loginLink, setLoginLink] = React.useState(`${window.location.protocol+"//"}${data.trade_name}.${reactBaseApp}${testMode ? '/test' : ''}/signup`);
    const [masterLink, setMasterLink] = React.useState(`${window.location.protocol+"//"}${reactBaseApp}/admin/user_details/${data.master_id}/#userInfo`);

    // const setData = () => {
        const info = [
            {
                title: 'Email Address:',
                value: data && data.email,
            },
            {
                title: 'Mobile Number:',
                value: data && '0' + data.contact
            },
            {
                title: 'No. of Sub-wallets:',
                value: (data && data.subwallet_count? data.subwallet_count : 0) + ' Sub-wallets',
                link: "#subWallets",
                hidden: data && data.user_type === "SW"
            },
            {
                title: 'Master Wallet:',
                value: data && data.business_name,
                link: masterLink,
                hidden: data && data.multiwallet
            },
            {
                title: 'Signup Link:',
                value: loginLink,
                link: loginLink,
            }
        ]

    //     return info;
    // }

    React.useEffect(() => {
        // setData();
        setLoginLink(`${window.location.protocol+"//"}${data.trade_name}.${reactBaseApp}${testMode ? '/test' : ''}/signup`)
        setMasterLink(`${window.location.protocol+"//"}${reactBaseApp}/admin/user_details/${data.master_id}/#userInfo`)
    },[data])


    return (
        <Card
            style={styles.root}
            bodyStyle={styles.rootBody}
            hidden={(data && data.user_type === "ME") || (['BIZ', 'SME'].includes(data.bux_segment) && !data.multiwallet)}
        >
           <div style={styles.info}>
            <Typography style={styles.title}>{data && data.full_name} {data && data.multiwallet? '(Master Wallet)' : data && data.user_type === "SW" ? "(Sub Wallet)" : ""}</Typography>
                <div>
                    <List
                        dataSource={info}
                        bordered={false}
                        renderItem={(item, i) => (
                            <List.Item style={{borderBottom: 0, padding: '8px 0'}} hidden={item && item.hidden}>
                                <Typography style={styles.item}>{item.title}</Typography>
                                {
                                    i < 2 ?
                                    <Typography style={{...styles.value, fontWeight: i > 1 && 'bold', color: i > 1 && '#2B2D32', textDecoration: i > 1 && 'underline' }}>{item.value}</Typography>
                                    :
                                    <a type='link' 
                                        onClick={(i === 2 && data && data.multiwallet)? () => window.location.hash = item.link : (i === 3 && data && data.user_type === "SW")? () => window.open(item.link, '_blank') : i === 4 ?() => window.open(item.link, '_blank') : ''} 
                                        style={{...styles.value, fontWeight: i > 0 && 'bold', textDecoration: i > 0 && 'underline' }}>{item.value}</a>
                                }
                            </List.Item>
                        )}
                    />
                </div>
           </div>
           <div style={styles.img}>
                <img alt="card_bg" src={MultiwalletInfoBG} />
            </div>
        </Card>
    )
}

const styles = {
    root: {
        margin: '12px 12px 12px 0',
        padding: '0px',
        // backgroundImage: `url(${MultiwalletInfoBG})`,
        // backgroundRepeat: 'no-repeat',
        // backgroundPostitionX: 'right'
    },
    rootBody: {
        padding: 0,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    info: {
        width: '50%',
        padding: '32px'
    },
    title: {
        color: '#000000',
        fontSize: '24px',
        fontWeight: 'bold',
        padding: '12px 0',
        opacity: '0.8'
    },
    normalFont: {
        color: '#2B2D32',
        fontSize: '16px'
    },
    content: {
        padding: '8px 0'
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 0'
    },
    item: {
        color: '#2B2D32',
        fontSize: 16,
    },
    value: {
        color: '#0A315E',
        fontSize: 16,
        fontWeight: 'normal',
        maxWidth: '314px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        overflow: 'hidden',
    },
    img: {
        width: '50%',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '24px 24px 0 0'
    }
}

export default MultiwalletCard;