import React from 'react';
import { useDispatch, useSelector } from '../../../../__test__/react-redux-hooks';
import multiwalletActions from '../../multiwalletActions';
import { Table, Input, InputNumber, Popconfirm, Form, Typography, Button, Icon, Pagination, } from 'antd';
import { history } from '../../../../store/history';

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

const EditableCell = (props) => {
  const [isEditing, setIsEditing] = React.useState(false);

  const toggleEdit = () => {
    const editing = !isEditing;
    setIsEditing({ editing }, () => {
      if (editing) {
        props.input.focus();
      }
    });
  };

  const save = (e, form) => {
    const { record, handleSave } = props;
    form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      // toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  const renderCell = (form, input) => {
    // this.form = form;
    const { children, dataIndex, record, title } = props;
    // const { editing } = this.state;
    return !isEditing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: false,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(props.inputType === 'number' ?
          <Input type='number' style={{ width: '100%' }} ref={node => (input = node)} onPressEnter={(e) => save(e, form)} onBlur={(e) => save(e, form)} onChange={(e) => save(e, form)} />
          :
          <Input style={{ width: '100%' }} ref={node => (input = node)} onPressEnter={(e) => save(e, form)} onBlur={(e) => save(e, form)} />)}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  };

  const {
    editable,
    dataIndex,
    title,
    record,
    index,
    handleSave,
    children,
    ...restProps
  } = props;
  return (
    <td {...restProps}>
      {editable ? (
        <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>
      ) : (
        children
      )}
    </td>
  );
}

const EditableTable = (props) => {

  const { segments, data, isLoading, onApplyFilter, onChangePage, onAmountChange, setLoading } = props;
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [selectedRowsData, setSelectedRowsData] = React.useState([]);
  const [searchVal, setSearchVal] = React.useState('');
  const transferMoneyBoxData = useSelector(state => state.multiwallet.transMoneyBoxData);
  const dispatch = useDispatch();
  const testMode = (history.location.pathname.indexOf('/test') > -1 && localStorage.getItem("userType") == "CO");
  
  
  const tableColumns = [
    {
      title: 'User ID',
      dataIndex: 'sub_id',
    },
    {
      title: 'Name',
      dataIndex: 'full_name',
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'contact',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      editable: true,
      width: '30%'
    },
  ];

  const subusers = () => {

    if(data){
      let filtered_users = data["results"].filter(filtered_user => filtered_user.transfer_money_enabled == true)
      return filtered_users
    }
    else{
      return []
    }
  }

  const handleAdd = () => {
    const { count, dataSource } = this.state;
    const newData = {
      key: count,
      name: `Edward King ${count}`,
      age: 32,
      address: `London, Park Lane no. ${count}`,
    };
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    });
  };

  const handleSave = row => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  };

  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  };
  const columns = tableColumns.map(col => {
    // if (!col.editable) {
    //   return col;
    // }
    return {
      ...col,
      onCell: (record, index) => ({
        record,
        inputType: 'number',
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: (row) => onAmountChange(row, index),
      }),
    };
  });

  const hasEmptyAmount = (data) => {
    const isEmpty = data.every(item => item.amount && item.amount && item.amount >= 1);
    if (isEmpty) {
      return false;
    }
    return true;
  }

  const getTotalAmount = (data) => {
    let total = 0;
    data && data.map((row, i) => {
      total = total + parseFloat(row.amount ? row.amount : 0);
    })
    return total;
  }

  const addToTransferMoneyBox = (newData) => {
    const dataSource = newData;
    let filteredData = transferMoneyBoxData;
    if (transferMoneyBoxData && transferMoneyBoxData.length) {
      transferMoneyBoxData.map((row, i) => {
        dataSource.findIndex(item => {
          if (row.email === item.email) {
            filteredData = filteredData.filter((item) => row.email !== item.email)
          }
        });
      })
      dispatch(multiwalletActions.setTransferMoneyBoxData([...filteredData, ...dataSource]));
      dispatch(multiwalletActions.setTransferBtn(!hasEmptyAmount([...filteredData, ...dataSource])));
      dispatch(multiwalletActions.setTransferBoxTotalAmount(getTotalAmount([...filteredData, ...dataSource])));
    } else {
      dispatch(multiwalletActions.setTransferMoneyBoxData(newData));
      dispatch(multiwalletActions.setTransferBtn(!hasEmptyAmount(newData)));
      dispatch(multiwalletActions.setTransferBoxTotalAmount(getTotalAmount(newData)));
    }
    setLoading();
  };

  const tableCheckbox = {
    type: 'checkbox',
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => handleChangeSelect(selectedRowKeys, selectedRows)
  }

  const handleChangeSelect = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  }

  const onAddToMoneyBox = () => {
    const selectedRows = [];
    data && data['results'].map((row, i) => {
      if (selectedRowKeys.includes(i)) {
        selectedRows.push(row);
      }
    });

    addToTransferMoneyBox(selectedRows);
  }

  const handleSearch = (e) => {
    setSearchVal(e.target.value);
  }

  const changePage = (page, pageSize) => {
    onChangePage(page);
  }

  const onFilterApply = () => {
    onApplyFilter(searchVal);
  }

  React.useEffect(() => {
    setSelectedRowKeys([]);
  }, [data])

  return (
    <div style={styles.root}>
      <div style={styles.body}>
        <div className="twoCol"> 
          <div className="bold-text-size liveColor top-20">
            Select the sub-wallet you want to transfer funds.
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '24px 0' }}>
            <Input
              placeholder="Search"
              style={{ width: '200px', height: '40px', borderRadius: '4px', padding: '0 8px 0 0px' }}
              suffix={<Icon type="search" style={{ fontSize: '18px' }} />}
              onChange={handleSearch}
              value={searchVal}
            />
            <button className={`btn--${segments} btn-height`} onClick={onFilterApply} >Apply</button>
          </div>
        </div>
         
        <Table
          components={components}
          rowClassName="table-row-light"
          dataSource={subusers()}
          columns={columns}
          pagination={false}
          rowSelection={{ ...tableCheckbox }}
          loading={isLoading}
        />
        <Pagination
          size='small'
          total={data && data['total']} cd
          defaultPageSize={10}
          pageSize={10}
          defaultCurrent={data && data['page'] ? data['page'] : 1}
          current={data && data['page'] ? data['page'] : 1}
          onChange={(page, pageSize) => changePage(page, pageSize)}
          style={{ padding: '12px 0 8px 0', textAlign: 'right' }}
        />

        <div className="bottom-32 top-16" align="right">
          <button disabled={selectedRowKeys.length <= 0} 
            className={`btn--${segments} btn-height normal-text-size`}
            onClick={onAddToMoneyBox}>
              <Icon type='plus' className="right-4" />
              Add to Transfer Money Box ({selectedRowKeys.length})
          </button>
        </div>

      </div>
    </div>
  );
}

const styles = {
  root: {
    margin: '24px 0'
  },
  transferBtn: {
    fontSize: 16,
    fontWeight: '600',
    padding: '8px, 16px, 8px, 16px',
    border: '1px solid #1DD28B',
    height: 40,
    color: '#FFF',
    backgroundColor: '#1DD28B'
  },

  headerDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 0'
  },
  body: {
    backgroundColor: 'transparent',
    padding: '0px 16px',
    backgroundColor: '#FFF',
    border: '1px solid #E6EAF0',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
  },
  applyBtn: {
    height: 40,
    width: 92,
    fontSize: 16,
    fontWeight: '600',
    borderRadius: 4,
    backgroundColor: '#F5922F',
    color: '#FFF',
  },
}

const EditableFormTableCellHooks = Form.create()(EditableTable);

export default EditableFormTableCellHooks;