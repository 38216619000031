import React from 'react';
import axios from 'axios';
import { Modal, message, Button } from 'antd';

const ResendModal = props => {

    const {
        title, content, openModal, setOpenModal, type, data
    } = props;

    let yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }

    const errorMessage = (text) => {
        message.destroy()
        message.error(text, 3)
    }

    const successMessage = (text) => {
        message.destroy()
        message.success(text, 3)
        setOpenModal(false)
    }

    const onSubmit = (data) => {
        let params = {}
        if(type === 'reset_password') {
            params = {
                email: data.email
            }
            resetPassword(params)
        }

        else if(type === 'resend_verification') {
            params = {
                id: data.id,
                email: data.email,
                full_name: data.full_name
            }
            resendVerification(params)
        }

        else if(type === 'verify_user') {
            params = {
                userId: data.id,
            }
            verifyUserNow(params)
        }

        else if(type === 'unblock_user') {
            params = {
                userId: data.id
            }
            unblockUserNow(params)
        }

        else if(type === 'logout_user') {
            params = {
                id: data.id
            }
            logoutUserNow(params)
        }

        else if(type === 'convert_user') {
            let userId = data.id;
            let role = data.user_role == 'Corporation' ? 'ME' : data.user_role == 'Merchant' ? 'CO' : '';
            convertUserNow(userId, role)
        }

        else if(type === 'suspend_user') {
            params = {
                userId: data.id,
                suspend: !data.suspended,
            }

            suspendToggleNow(params)
        }
    }

    const resetPassword = async (val) => {
        let response = await axios.post(
            process.env.REACT_APP_API_URL + '/api/reset_password/',val
          );
      
        if(response.data.status === "success"){
            successMessage(response.data.message)
        }

        else{
            errorMessage(response.data.message)
        }
    }

    const resendVerification = async (val) => {
        let response = await axios.post(
            process.env.REACT_APP_API_URL + '/api/admin/resend_verification/', val,yourConfig
        );
    
        if(response.data.status == "success"){
            successMessage("Verification code successfully sent!")
        }
        else{
            setOpenModal(false)
        }
    }

    const verifyUserNow = async (value) => {

        let response = await axios.post(
          process.env.REACT_APP_API_URL + '/api/verify_user/',value,yourConfig
        );

        if(response.data.status === "success"){
            successMessage(response.data.message)
        }
    }

    const unblockUserNow = async (value) => {
        let response = await axios.post(
          process.env.REACT_APP_API_URL + '/api/unblock_user/',value,yourConfig
        );
        if(response.data.status === "success"){
            successMessage(response.data.message)
        }
        else{
            message.info(response.data.message, 3);
        }
    }

    const logoutUserNow = async (value) => {
        let response = await axios.post(
          process.env.REACT_APP_API_URL + '/api/admin/invalidate_token/', value,yourConfig
        );
    
        if(response.data.status == "success"){
            successMessage("User logged out successfully!")

        }
        else{
            errorMessage("Invalidate error!")
        }
    }

    const convertUserNow = async (userId, role) => {
        let response = await axios.get(
            process.env.REACT_APP_API_URL + '/api/admin/convert/?userId='+userId+'&role='+role,yourConfig
        );
  
        if(response.data.status === "success"){
            successMessage("User is now converted!")
        }

        else{
            message.info(response.data.message, 3)
        }
    }


    const suspendToggleNow = async (value) => {
        let response = await axios.post(
          process.env.REACT_APP_API_URL + '/api/user_suspend_toggle/',value,yourConfig
        );
    
        if(response.data.status === "success"){
            successMessage(response.data.message);
            window.location.reload()
        }

        else{
            message.info(response.data.message)
        }
    
    } 

    return(
        <Modal 
            title={title} 
            visible={openModal}
            onCancel={() => setOpenModal(false)}
            footer={[
                <Button key="back" onClick={() => setOpenModal(false)} className="admin-download-btn">
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={()=>onSubmit(data)} className="add-user">
                    Proceed
                </Button>,
            ]}>
                <div>
                    {content}
                </div>
        </Modal>
    )
}

export default ResendModal;