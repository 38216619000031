import React from 'react';
import { Button, Icon, Input, Modal, Typography } from 'antd';
import { PaymentReqStep1, PaymentReqStep2, PaymentReqStep3, PaymentReqStep4, PaymentReqStep5, PaymentReqStep6, PaymentReqStep7, PaymentRequestBG, PaymentTutorialStart } from '../../../static/new_segment/tutorials/pages_tutorials';
import { PaymentReq } from '../../../static/icons/sidebar';

const PaymentRequestTutorial = (props) => {
    const segments = localStorage.getItem('segments');
    const [step, setStep] = React.useState(0);
    const { userType } = props;

    const tutorialSteps = [
        {
            step: 0,
            bg: PaymentTutorialStart,
            title: "Payment Request",
            description: 'View and filter your payment transactions. You can also schedule payment requests here.',
            margin: '32px 0 0 0'
        },
        {
            step: 1,
            bg: PaymentReqStep1,
            title: "Transaction Filter",
            description: 'Filter your transactions based on their status.',
            margin: '72px 0px 24px'
        },
        {
            step: 2,
            bg: PaymentReqStep2,
            title: "Batch Upload",
            description: 'Click on this tab to create multiple payment requests in one upload.',
            margin: '32px 0 0 0'
        },
        {
            step: 3,
            bg: PaymentReqStep3,
            title: "Payment Schedule",
            description: 'Click See more to add and view your scheduled payments.',
            margin: '0px 0 0 0'
        },
        {
            step: 4,
            bg: PaymentReqStep4,
            title: "Table Settings",
            description: 'Click here to customize your Payment Request table.',
            margin: '16px 0 0 0'
        },
        {
            step: 5,
            bg: PaymentReqStep5,
            title: "Download CSV",
            description: 'Click on this button to download a CSV file version of the data being displayed on the table.',
            margin: '32px 0 0 0'
        },
        {
            step: 6,
            bg: PaymentReqStep6,
            title: "Table Filter",
            description: 'Search or filter your transactions based on payment method and date.',
            margin: '32px 0 0 0'
        },
        {
            step: 7,
            bg: PaymentReqStep7,
            title: "New Payment Request",
            description: 'Click here to generate a new payment request link you can share with your customers.',
            margin: '32px 0 0 0'
        },
    ]

    const addStep = () => {
        if (step === 1 && userType === "ME") {
            setStep(step + 2)
        } else if (step < tutorialSteps.length - 1) {
            setStep(step + 1)
        }
        else {
            setStep(step)
        }
    }

    const subtractStep = () => {
        if (step === 3 && userType === "ME") {
            setStep(step - 2)
        } else if (step >= 1) {
            setStep(step - 1)
        }
        else {
            setStep(step)
        }
    }

    console.log(step);
    console.log(tutorialSteps.length)

    React.useEffect(() => {
        setStep(0);
    }, [props.visible])

    return (
        <Modal
            maskClosable
            onCancel={() => props.onClose()}
            visible={props.visible}
            footer={false}
            closeIcon={<Icon type="close" className={`text-${segments}`} />}
            // height={402}
            // width={454}
            destroyOnClose
            style={{ borderRadius: '12px', height: 402, minWidth: '800px', marginBottom: '32px', top: 32 }}
            bodyStyle={styles.bg}
        >
            <div style={styles.contentWrapper}>
                <img src={tutorialSteps[step].bg} alt={tutorialSteps[step].title} style={{ margin: tutorialSteps[step].margin }} />
                <div style={{ paddingTop: '24px', gap: '12px', display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div className={`bg-${segments}`} style={styles.icon} hidden={step > 0}>
                        <img src={PaymentReq} alt="Payment Request" />
                    </div>
                    <Typography style={styles.title}>{tutorialSteps[step].title}</Typography>
                </div>
                <Typography style={styles.msg}>{tutorialSteps[step].description}</Typography>
            </div>
            <button
                className={`btn--${segments}`}
                onClick={() => setStep(1)}
                hidden={step > 0}
                style={{ width: '112px', height: '32px' }}
            >
                Next
            </button>
            <div style={styles.footerBtn} hidden={step === 0 || (step + 1 >= tutorialSteps.length)}>
                <button className={`outline-btn--${segments}`} style={styles.cancelBtn} onClick={() => subtractStep()} >Previous</button>
                <button className={`btn--${segments}`} style={{ ...styles.saveBtn }}
                    onClick={() => addStep()} >
                    Next
                </button>
            </div>
            <button
                className={`btn--${segments}`}
                onClick={() => props.onClose()}
                hidden={step + 1 < tutorialSteps.length}
                style={styles.saveBtn}
            >
                All good
            </button>
        </Modal>
    )
}

const styles = {
    title: {
        color: '#2B2D3',
        fontSize: 28,
        fontWeight: '700',
        textAlign: 'center'
    },
    bg: {
        backgroundImage: `url(${PaymentRequestBG})`,
        // backgroundPosition: 'top auto',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    contentWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    msg: {
        color: '#2B2D3',
        fontSize: 16,
        textAlign: 'center',
        padding: '12px 0',
        fontWeight: '400'
    },
    btn: {
        height: 48,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 4,
        width: '100%',
    },
    input: {
        padding: '11px 16px 11px 16px',
        fontSize: 16,
        height: 48,
        margin: '4px 0px 12px 0px',
        color: '#2B2D3'
    },
    saveBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: 86,
        cursor: 'pointer',
        color: '#FFF',
        border: 0
    },
    cancelBtn: {
        cursor: 'pointer',
        borderRadius: 4,
        height: 40,
        fontSize: 16,
        fontWeight: '500',
        padding: '8px 16px',
        width: 'auto',
        marginRight: 8
    },
    footerBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '12px 0'
    },
    icon: {
        padding: '8px',
        borderRadius: '8px'
    }
}

export default PaymentRequestTutorial;