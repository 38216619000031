export const verifyStyle = {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '34px',
    color: '#0D3D76',
    textAlign: 'center',
    marginBottom: '10px'
}

export const textStyle = {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    color: '#2B2D33',
    opacity: '0.8',
    textAlign: 'center',
    marginBottom: '20px'
}

export const signupBtnStyle = {        
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    height: '50px',
    marginTop: '20px',
    width: '100%'
}

export const dontHaveStyle = {
    
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '24px',
    color: '#2B2D33',
    display: 'flex',
    marginTop: '20px',
}

export const headerStyle = {
    height: '60px', 
    display: 'flex', 
    justifyContent: 'center', 
    backgroundColor: '#fff', 
    boxShadow: '0px 3px 5px rgba(57,63,72,0.1)'
}

export const logoStyle = {
    marginBottom: '20px', 
    marginTop: '12px'
}

export const mainDiv = {
    display:'flex', 
    justifyContent: 'center', 
    paddingTop: '15%'
}

export const mobileMainDiv = {
    textAlign: 'center',
    padding: 20
}

export const kycMainDiv = {
    justifyContent: 'center',
    padding:'15% 20px 0px 20px'
}

export const childDiv = {
    paddingLeft: '57px',
    paddingRight: '57px', 
    justifyContent: 'center', 
    alignItems:'center', 
    width: '450px'
}

export const mobileChildDiv = {
    marginTop: 20
    
}

export const kycChildDiv = {
    justifyContent: 'center', 
    alignItems:'center', 
    width: '100%', 
    marginTop: '20px'
}
