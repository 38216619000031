import React,{ useState } from 'react';
import eonImage from "../../../static/images/eon_text_image.svg";
import MetaTag from '../../meta_tag/meta_tag'
import { Card, Typography, Input, Modal, Button, Spin, Affix } from 'antd';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { history } from '../../../store/history';
import NewCvvModal from '../modals/newCvvModal';
import cardServices from '../cardServices';
import cardActions from '../cardActions';
import CancelIcon from '@material-ui/icons/Cancel';
import { Buffer } from 'buffer';
import crypto from 'crypto';
import { isMobile } from 'react-device-detect';
import OtpCard from '../otp/otpCard';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { useLocation } from 'react-router';

const RequestCvv = (props) => {
  const [btnStyle, setBtnStyle] = useState({ backgroundColor: 'transparent', color: '#F5922F' })
  const [cardInput, setCardInput] = useState(null);
  const [newCvv, setNewCvv] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [btnLoading, setBtnLoading ] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Error requesting new CVV. Please try again.");
  const [initialized, setInitialized] = useState(false)
  const visibleOTP = useSelector(state => state.card['otp_mobile']);
  const otpSuccess = useSelector(state => state.card.otpSuccess);
  const otpUID = useSelector(state => state.card.otpUID);
  const [randEncKey, setRandEncKey] = useState('')
  const location = useLocation();
  const dispatch = useDispatch();
  const noEon = location.state && location.state.details ? location.state.details.lastFourDigits : null;
  const segments = localStorage.getItem('segments');

  if(!initialized) {
    const current_path = window.location.pathname
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
        history.push('/login?next=' + current_path)
    } 
    // else if (!noEon || noEon == "") {
    //   if(isMobile) {
    //     history.push('/mobile/dashboard');
    //   } else {
    //     history.push('/dashboard');
    //   }
    // } 
    else {
      if (isMobile) {
        history.push('/mobile/request_cvv');
        setInitialized(true)
      } else {
        setInitialized(true);
      }
    }
  }

  const handleMouseOver = () => {
    setBtnStyle({
      backgroundColor: '#F5922F',
      color: '#FAFAFA'
    });
  }

  const handleMouseLeave = () => {
    setBtnStyle({
      backgroundColor: 'transparent',
      color: '#F5922F'
    });
  }

  const handleChange = (event) => {
    setCardInput(event.target.value);
  }

  const toggleModal = (success) => {
    setShowModal(!showModal)
    if(success) {
      history.push({ pathname: '/card', state: { isSuccess: true, notifMsg: 'Successfully Changed CVV' }});
    }
  }

  const compareCards = async () => {
    setBtnLoading(true)
    try {
      var key = crypto.randomBytes(16).toString('hex');
      setRandEncKey(key);
      const response = await cardServices.getFullCardInfo(key);
      if(decryptor(response.apiCardNumber, key)==cardInput.replace(' ','')){
        fetchOTP();
      }else{
        setBtnLoading(false)
        setError(true)
        setErrorMessage("Card Number does not match")
        setShowConfirmModal(false)
      }
    } catch (error) {
      console.log(error);
      setBtnLoading(false)
      setError(true)
      setErrorMessage(error)
      setShowConfirmModal(false)
    }
  }

  const returnOtpSubj = () => {
    const path = window.location.pathname
    console.log(path)
    switch (path) {
      case '/physical_card_upgrade':
      case '/mobile/physical_card_upgrade':
        return 'replace_card';
      case '/cards/bills_payment':
      case '/mobile/bills_payment':
        return 'bills_pay';
      case '/request_cvv':
      case '/mobile/request_cvv':
        return 'cvv';
      default:
        return 'payout';
    }
  }

  const fetchOTP = async () => {
    setBtnLoading(true);
    try {
      const response = await cardServices.getOTP();
      if (response.status === 'success') {
        setShowConfirmModal(false);
        dispatch(cardActions.setOtpParams({mobile:response.mobile, uid:response.uid, subj: returnOtpSubj()}));
        dispatch(cardActions.showModal('otp_mobile'))
        dispatch(cardActions.resetOTPTimer(true))
      } else {
        if(response.code==='attempts'){
          setShowConfirmModal(false);
          dispatch(cardActions.setAttempMsg(response.unblock));
          dispatch(cardActions.showModal('attempt_error'));
          dispatch(cardActions.showModal('otp_mobile'));
        } else {
          setShowConfirmModal(false);
          setBtnLoading(false)
          dispatch(cardActions.hideModal('otp_mobile'))
        }
      }
      // console.log(response)
    } catch (error) {
      console.log(error)
      setShowConfirmModal(false);
      dispatch(cardActions.hideModal('otp_mobile'))
      setBtnLoading(false)
    }
  }

  const generateCvv = async () =>{
    setBtnLoading(true);
    setShowConfirmModal(false);
    try {
      const response = await cardServices.setCvv({key: randEncKey, trans_uid: otpUID});
      if (response.status === 'success') {
        let x = decryptor(response.cvv, randEncKey);
        setNewCvv(x)
        setBtnLoading(false)
        setCardInput("");
        toggleModal();
      } else {
        setBtnLoading(false)
        setError(true)
        setErrorMessage(response.message)
      }
    } catch (error) {
      console.log(error)
      setBtnLoading(false)
      setErrorMessage(error)
      setError(true)
      }
  }

 function decryptor(encryptedMessage, key) {
    try{
      let encryptedMessageArray = encryptedMessage.split(':');
      let iv = Buffer.from(encryptedMessageArray.shift(), 'base64');
      let encryptedText = Buffer.from(encryptedMessageArray.join(':'), 'base64');
      let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from( key ), iv);
      let decryption = decipher.update(encryptedText);
      decryption = Buffer.concat([decryption, decipher.final()]);
      return decryption.toString();
    }catch(err){
      console.log(err)
    }
  }

  if(error) {
    setTimeout(() => {
      setError(false);
      dispatch(cardActions.hideModal('attempt_error'));
    }, 5000);

  }

  if(otpSuccess) {
    generateCvv();
    dispatch(cardActions.setOtpSuccess(false));
  }

  return (
    <div style={styles.root}>
      <MetaTag title='Card' />
      {
        error ?
        <Affix offsetTop={20} style={{position: 'fixed', zIndex: '100', top: '10%', left: '42%'}}>
          <div style={styles.notif}>
            <CancelIcon
              style={{ 
                margin: '0 10px 0 0'
              }} 
            />
            <div>
              <div align='left'>{errorMessage}</div>
            </div>
          </div>
        </Affix>
        : null
      }
      <NewCvvModal visible={showModal} closeModal={ () => toggleModal(true)} cvv={newCvv} /> 
      <div style={{...styles.title,  width: isMobile ? '100%' : visibleOTP? 475 : 402,}}>
        <strong style={styles.regularFont}>BUx E-Money Account</strong>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-6px'}}>
          <Typography style={{fontSize: 12, color: '#909196'}}>Powered by</Typography>
          <img src={eonImage} style={{ width: 50, height: 38, paddingLeft: 2 }} />
        </div>
        <IconButton hidden={!visibleOTP} 
          style={styles.otpCancel}
          onClick={() => window.location.href = '/request_cvv' }
          >
          <HighlightOffIcon style={{color: '#F5922F', fontSize: 32}} />
        </IconButton>
      </div>
      <Card hidden={visibleOTP} style={styles.card} bodyStyle={{padding: isMobile? 16 : 32}}>
        <Typography style={styles.subTitle}>Request New CVV</Typography>
        <Typography style={styles.msg}>
          You are requesting to change your 
          current <strong> CVV</strong>. 
          {/*Please provide your <strong>Card Number</strong> for this request.*/}
        </Typography>
        <div style={styles.inputWidth}>
          <label style={styles.labelFont}>
            Card Number
          </label>
          <Input style={styles.field} 
            placeholder="Enter Card Number"
            value={cardInput}
            onChange={handleChange}
            disabled={btnLoading}
          >
          </Input>
        </div>
        <button style={{...styles.btnGenerate, opacity: !cardInput ? '50%' : '100%'}} 
          disabled={!cardInput || btnLoading} 
          onClick={()=>setShowConfirmModal(true)}
          className={`btn--${segments}`}
        >
          {btnLoading ? <Spin size='small' /> : 'Generate New CVV'}
        </button>
        <button style={{...styles.btnCancel, opacity: btnLoading ? '50%' : '100%' }} 
          onMouseOver={handleMouseOver} 
          onMouseLeave={handleMouseLeave} 
          disabled={btnLoading}
          onClick={() => history.push(isMobile ? '/mobile/card' : '/card')}
          className={`outline-btn--${segments}`}>
            Cancel
        </button>  

        <Modal 
          visible={showConfirmModal} 
          footer={null} 
          onCancel={()=>setShowConfirmModal(false)} 
          maskClosable={false} 
          width={360}
          >
          <div style={{...styles.modalTitle, padding: '30px 0 0 0'}}>
            <Typography style={{...styles.modalText, margin: '0 0 20px 0', textAlign: 'left'}}>Do you wish to proceed?</Typography>
          </div>
          <Typography style={styles.modalMsg}>
            Changing your CVV will make your old one unusable. 
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <button disabled={btnLoading} onClick={()=>setShowConfirmModal(false)}
              className={`outline-btn--${segments}`} style={styles.modalBtnCancel}>Cancel</button>
            <button  disabled={btnLoading} onClick={compareCards} 
              className={`btn--${segments}`}style={styles.modalBtnGen}>{btnLoading ? <Spin size='small' /> :'Proceed'}</button>
          </div>
        </Modal>
      </Card>
      <OtpCard />
    </div>
  )
}

const styles =  {
  root: {
    display: 'flex',
    justifyContent: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '32px 0 0 0px',
    padding: 20,
    backgroundColor: '#F4F6F9',
    height: isMobile? '100vh' : '100%'
  },
  title: {
    textAlign: 'left',
    display: 'flex', 
    alignItems: 'start',
    padding: '20px 0',
    flexDirection: 'column',
    position: 'relative'
  },
  subTitle: {
    color: '#000000',
    fontSize: 28,
    textAlign: 'center',
  },
  regularFont: {
    fontSize: 28,
    color: '#000000',
  },
  msg: {
    color: '#000000',
    fontSize: 16,
    textAlign: 'center',
    padding: '20px 0 16px 0'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: isMobile ? '100%' : 402,
  },
  inputWidth: {
    width:'100%',
    padding: '0 0 8px 0'
  },
  field: {
    width: '100%', 
    height: 48,
    fontSize: 16
  },
  labelFont: {
    fontSize: 12,
    color: '#212B36'
  },
  btnCancel: {
    margin: '12px 0px 0px 0px',
    height: 40,
    width: '100%',
    // color: '#F5922F',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
    // borderColor: '#F5922F',
  },
  btnGenerate: {
    margin: '16px 0px 0px 0px',
    height: 40,
    width: '100%',
    // backgroundColor: '#F5922F',
    // color: '#FFFFFF',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
  },
  notif: {
    backgroundColor: '#FFFFFF', 
    fontSize: 14, 
    fontWeight: 'bold', 
    width: '100%', 
    color: '#E24C4C', 
    borderRadius: 4, 
    padding: '8px 16px', 
    textAlign: 'center',
    display: 'flex',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.24)',
    alignItems: 'center'
  },
  modalTitle: {
    textAlign: 'center'
  },
  modalText: {
    color: '#2B2D33',
    fontSize: 24,
    fontWeight: '600',
    margin: '0px 0px 30px 0px'
  },
  modalMsg: {
    color: '#000000',
    fontSize: 16,
    paddingBottom: 15
  },
  footer: {
    display: 'flex'
  },
  modalBtnGen:{
    margin: '12px 0px 0px 0px',
    height: 40,
    width: '48%',
    // backgroundColor: '#F5922F',
    // color: '#FFFFFF',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
  },
  modalBtnCancel: {
    margin: '12px 0px 0px 0px',
    height: 40,
    width: '48%',
    // color: '#F5922F',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
    // borderColor: '#F5922F',
  },
  otpCancel: {
    padding: 0,
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    top: isMobile? '80%' : '35%',
    right: '-4%'
  },
}

export default RequestCvv;