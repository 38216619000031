import React from 'react';
import { Icon, Tooltip, Typography } from 'antd';
import SentroLogo from '../../../static/icons/sentro.svg';
import { SentroShop } from '../../../static/icons/dashboard_icons';
import { isMobile } from 'react-device-detect';
import './sentro_card.css';

const { Text } = Typography;

const SentroCard = props => {

    const {
        hidden, width, segments, noMulti
    } = props;

    return(
        <React.Fragment>
            <div className='sentro-card top-20' hidden={hidden} style={{ width, display: "flex", flexDirection: "row", gap: "1em", alignItems: "center" }}>
                <div className='sentro-shop' hidden={!noMulti}>
                    <img src={SentroShop} alt="sentro shop logo" />
                </div>
                <div className='headline-container' style={{ flex: "1", display: "flex", flexDirection: "column", gap: "-1em" }}>
                    <div className='sentro-logo'>
                        <img src={SentroLogo} alt="sentro" />
                        <Tooltip placement="top" title={"In Sentro you can sell online, accept payments in various forms, and book deliveries here."}>
                            <Icon type="info-circle" style={{ color: '#4993EE', background: '#E5F2FD', borderRadius: 50, fontSize: 16 }} />
                        </Tooltip>
                    </div>
                    <div className='secondary-text' style={{ padding: "0 1em" }}>
                        <Text type='secondary'>Launch your online shop now!</Text>
                    </div>
                </div>
                <div className='visit-store-link-container' style={{ display: "flex", alignItems: "center" }}>
                    <a href="https://sentro.ph/login" target='blank' className={`text-${segments} boldTextStyle`} style={{ fontSize: "14px"}}>
                        Visit Store
                    </a>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SentroCard;
