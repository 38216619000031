import { Alert, Button, Spin } from "antd";
import OTPInput from "otp-input-react";
import React, { useEffect, useState } from "react";
import { fetchOTP } from "../../../../api";
import { FormWrapper } from "../FormWrapper";

export function OtpEmail({
  otpDetails,
  setOtpDetails,
  showErrorAlert,
  otpError,
  setSmsOtpActivated,
  isOtpLoading,
  setIsOtpLoading,
}) {
  const [resendDisabled, setResendDisabled] = useState(true);
  const [resendTimer, setResendTimer] = useState(0);

  useEffect(() => {
    setIsOtpLoading(true);
    fetchOTP("email_otp/?subject=payout")
      .then((data) => {
        setOtpDetails((prev) => {
          return { ...prev, uid: data.uid };
        });
        setResendDisabled(true);
        setResendTimer(30);
      })
      .finally(() => setIsOtpLoading(false));
  }, []);

  useEffect(() => {
    if (resendTimer > 0 && resendDisabled) {
      const interval = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else if (resendTimer === 0 && resendDisabled) {
      setResendDisabled(false);
      setResendTimer(30); // Reset the timer to 30 seconds
    }
  }, [resendTimer, resendDisabled]);

  const handleOTPChange = (otpValue) => {
    setOtpDetails({
      ...otpDetails,
      code: otpValue,
    });
  };

  const handleResendOtp = () => {
    if (!resendDisabled) {
      setResendDisabled(true);
      fetchOTP("email_otp/?subject=payout").then((data) => {
        setOtpDetails((prev) => {
          return { ...prev, uid: data.uid };
        });
      });
    }
  };

  if (isOtpLoading) {
    return (
      <Spin
        size="large"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      />
    );
  }

  return (
    <FormWrapper title="One-time PIN">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {showErrorAlert && otpError && (
          <Alert
            message={otpError.message}
            type="error"
            style={{ width: "100%", fontSize: "11px" }}
          />
        )}
        <p style={{ textAlign: "center" }}>
          To proceed, a 6-digit code will be sent to your registered email
          address.
        </p>
        <OTPInput
          value={otpDetails.code}
          onChange={handleOTPChange}
          autoFocus
          OTPLength={6}
          style={{
            margin: "auto",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          otpType="number"
          inputStyles={{
            height: 70,
            width: 34,
            fontSize: "32px",
            color: "#2b2d33",
            fontWeight: "600",
            background: "#E6EAF0",
            boxSizing: "border-box",
            border: "1px solid #d1d5dd",
            borderRadius: "4px",
          }}
        />
        <div style={{ alignSelf: "center" }}>
          <Button
            htmlType="button"
            disabled={resendDisabled}
            onClick={handleResendOtp}
          >
            <span style={{ color: "rgb(0, 81, 176)" }}>Resend code</span>
          </Button>
        </div>
        <div style={{ fontSize: "11px" }}>
          {resendDisabled ? (
            <p style={{ fontSize: "11px" }}>
              <span style={{ fontWeight: "bold" }}>"Resend code"</span> will be
              enabled after{" "}
              <span style={{ fontWeight: "bold" }}>{resendTimer}</span> seconds
            </p>
          ) : (
            <p>Click the "Resend code" button to get a new OTP code</p>
          )}
        </div>
        <p style={{ fontWeight: "bold" }}>OR</p>
        <p
          style={{
            fontWeight: "bolder",
            color: "rgb(0, 81, 176)",
            cursor: "pointer",
          }}
          onClick={() => setSmsOtpActivated(true)}
        >
          Send OTP code via SMS
        </p>
      </div>
    </FormWrapper>
  );
}
