import React,{ useEffect, useState } from 'react';
import ButtonGroup from 'antd/lib/button/button-group';
import moment from 'moment';
import { setSelectedBtn, setBatchUploaded, setBatchGenerated } from '../orderAction';
import {Alert} from '@material-ui/lab/'
import { ExportToCsv } from 'export-to-csv';
import { jsonToCSV } from 'react-papaparse';
import BatchTable from './table';
import PaymentLinkModal from './paymentLinkModal';
import UploadCsvModal from './uploadCsvModal';
import { Button, DatePicker, Icon, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import batchUploadServices from './batchUploadServices';
import TestModeBanner from '../../cards/test_mode/testModeBanner';
import { randomize } from '../../constants/constants';
import {history} from '../../../store/history'
import GroupButton from '../../custom_components/groupButton';

const template = [
  {
    "Amount (PHP)": 5000,
    "Description": 'Payment for product: Product Name',
    "Link Expiration (Hours)": 4,
    "Customer Name": 'John Wick',
    "Customer Email": 'JonhWick@ubx.ph',
    "Mobile Number": '09655555555',
  },
  {
    "Amount (PHP)": 2000,
    "Description": 'Payment for product: Product Name',
    "Link Expiration (Hours)": 4,
    "Customer Name": 'Johny Bravo',
    "Customer Email": 'JohnyBravo@ubx.ph',
    "Mobile Number": '09654444444',
  },
  {
    "Amount (PHP)": 2000,
    "Description": 'Payment for product: Product Name',
    "Link Expiration (Hours)": 4,
    "Customer Name": 'Johny Johny',
    "Customer Email": 'Johny@ubx.ph',
    "Mobile Number": '09653333333',
  },
]

export const exportTemplate = () => {
  const csv = jsonToCSV(template);
  const blob = new Blob([csv]);
  const a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
    a.download = "batch_payment_link_template.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

const BatchUpload = () => {

  const segments = localStorage.getItem('segments');
  const statuses = ['Uploaded', 'Generated'];

  const [paymentLinkShow, setPaymentLinkShow] = useState(false);
  const [uploadModalShow, setUploadModalShow] = useState(false);
  const batchUploaded = useSelector(state => state.orders.batchUploaded);
  const batchGenerated = useSelector(state => state.orders.batchGenerated);
  const selected = useSelector(state => state.orders.selectedBtn);
  const [searchVal, setSearchVal] = useState('');
  const dispatch = useDispatch();
  const testMode = (window.location.pathname.indexOf('/test') > -1&&localStorage.getItem("userType") == "CO");
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [currentTablePage, setCurrentTablePage] = useState(0);
  const [totalTableCount, setTotalTableCount] = useState(0);
  const [pickedStartDate, setPickedStartDate] = useState('');
  const [pickedEndDate, setPickedEndDate] = React.useState('');
  const defined_wallet_settings = JSON.parse(localStorage.getItem('defined_wallet_settings'));
  let generatePaymentLinkEnabled = false;

  if(defined_wallet_settings != null){
    generatePaymentLinkEnabled = defined_wallet_settings.generate_payment_link_enabled ? defined_wallet_settings.generate_payment_link_enabled : '';
}

  const dateFormat = 'MM-DD-YYYY';

  const handleTableData = () => {
    switch (selected) {
      case 'Generated':
        return batchGenerated;
      case 'Uploaded':
      default:
        return batchUploaded;
    }
  }

  const onSearchValueChange = (e) => {
    let searchString = e.target.value;

    setSearchVal(searchString);
    getData(1, pickedStartDate, pickedEndDate, searchString);
  }

  async function getData(page=1, startDate='', endDate='', search='') {
    try {
      setIsTableLoading(true);
      setCurrentTablePage(page);

      const res = await batchUploadServices.getTableData(testMode, selected.toLowerCase(), page, startDate, endDate, search);
      if(res.status === 'success') {

        res.data.forEach(entry => {
          entry['key'] = randomize();
        });

        const fxnToDispatch = selected === 'Uploaded' ? setBatchUploaded : setBatchGenerated;
        dispatch(fxnToDispatch(res.data));
        setTotalTableCount(res.total);
      }
      
      setIsTableLoading(false);
    } catch (error) {
        setCurrentTablePage(1);
        setTotalTableCount(0);
        setIsTableLoading(false);
        return(
          <Alert severity="error">{error}</Alert>
        )
    }
  }

  const datePickerHelperFunctions = {
    disabledStartDates: (current) => {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    },

    disabledEndDates: (current) => {
        // Cannot select days in the future
        let isDisabled = current && current > moment().endOf('day');

        if (pickedStartDate) {
            // Cannot select days before the start date, if available
            isDisabled = isDisabled || current < moment(pickedStartDate, dateFormat).startOf('day');
        }

        return isDisabled;
    },

    onStartDateChange: (date, dateString) => {
        setPickedStartDate(dateString);

        let isMissingEitherDate = !dateString ^ !pickedEndDate;
        if (isMissingEitherDate) {
            return;
        }

        setCurrentTablePage(1);
        getData(1, dateString, pickedEndDate, searchVal);
    },

    onEndDateChange: (date, dateString) => {
        setPickedEndDate(dateString);

        let isMissingEitherDate = !pickedStartDate ^ !dateString;
        if (isMissingEitherDate) {
            return;
        }

        setCurrentTablePage(1);
        getData(1, pickedStartDate, dateString, searchVal);
    }
  };

  function changePage(page) {
    getData(page, pickedStartDate, pickedEndDate, searchVal);
  }

  function resetFiltersWithRefetch() {
    getData();
    setPickedStartDate('');
    setPickedEndDate('');
    setSearchVal('');
  }

  useEffect(() => {
    if(localStorage.getItem("userType") == "CO" && localStorage.getItem("Level") != 5 && !testMode){
      history.push('/dashboard')
    }
    else{
      resetFiltersWithRefetch();
    }
  }, [selected])

  return (
    <div style={styles.root}>
      <TestModeBanner width={'100vw'} top={64} right={0} />
      <div style={styles.btnMain}>
        <GroupButton status={statuses} selected={selected} segments={segments} handleClick={(e) => 
            {
                dispatch(setSelectedBtn(e.target.value));
            }} />
        <div>
          <button 
            className={`outline-btn--${segments}`} 
            onClick={exportTemplate}
            style={{...styles.dload
              }}>
              Download Template
          </button>
          {
            generatePaymentLinkEnabled &&

            <button
              className={`btn--${segments}`} 
              onClick={()=>setPaymentLinkShow(true)}
              style={styles.import}>
              Import Batch Payment Link
            </button>   
          }
          
        </div>
      </div>
      <div style={styles.dateBtn}>
        <DatePicker
          placeholder="Start Date"
          size="large"
          disabledDate={datePickerHelperFunctions.disabledStartDates}
          format={dateFormat}
          onChange={datePickerHelperFunctions.onStartDateChange}
          style={{zIndex: 1}}
          value={pickedStartDate ? moment(pickedStartDate) : null}
        />
        <span style={{padding: '0 8px'}}>-</span> 
        <DatePicker
          placeholder="End Date"
          size="large"
          disabledDate={datePickerHelperFunctions.disabledEndDates}
          format={dateFormat}
          onChange={datePickerHelperFunctions.onEndDateChange}
          style={{marginRight: '10px', zIndex: 1}}
          value={pickedEndDate ? moment(pickedEndDate) : null}
        />
        <Input
          placeholder="Search"
          style={{ width: '200px', height: '40px', borderRadius:'4px' }}
          suffix={<Icon type="search" style={{fontSize:'18px'}}/>}
          onChange={onSearchValueChange}
          value={searchVal}
        />
      </div>
      <BatchTable 
        data={handleTableData()}
        changePage={changePage}
        refetchTableData={resetFiltersWithRefetch}
        isTableLoading={isTableLoading}
        currentTablePage={currentTablePage}
        totalTableCount={totalTableCount}
      />
      <PaymentLinkModal 
        visible={paymentLinkShow} 
        onSkip={()=> {setPaymentLinkShow(false);setUploadModalShow(true);}}
        closeModal={()=>setPaymentLinkShow(false)}
        exportTemplate={exportTemplate}
        segments={segments} />
      <UploadCsvModal 
        visible={uploadModalShow}
        closeModal={()=> setUploadModalShow(false)} 
        exportTemplate={exportTemplate} 
        onSuccess={resetFiltersWithRefetch}
        segments={segments}/>
    </div>
  )
};

const styles = {
  root: {
    padding: '0 32px'
  },
  btnGroup: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '0 32px',
  },
  dload: {
    width: '200px',
    fontSize: 16,
    fontWeight: '600',
    height: 40,
    marginRight: 8,
  },
  import: {
    fontSize: 16,
    fontWeight: '600',
    height: 40,
    width: '250px',
    marginLeft: 8,
  },
  btnMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '30px 0',
  },
  dateBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 30,
  },
  span: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  datePic: {
    height: 40,
    fontSize: 14,
    color: '#2F3542',
    width: 178,
    padding: '0 8px 0 8px'
  },
  suffixIcon: {
    marginTop: '-12px', 
    marginRight: 12, 
    color: '#000'
  }
};

export default BatchUpload;