import React from 'react';
import fraudServices from '../fraudServices';
import fraudActions from '../fraudActions';
import DeleteRowModal from './deleteRowModal';
import { Dropdown, Icon, Menu, Table, message, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

const BlacklistTable = ({data, onDeleted, isLoading, length, sort}) => {
  const currentPage = useSelector(state => state.fraud.tablePage);
  const [minPage, setMinPage] = React.useState(0);
  const [maxPage, setMaxPage] = React.useState(20);
  const [loading, setLoading] = React.useState(false);
  const [showDelModal, setShowDelModal] = React.useState(false);
  const [keyTodelete, setKeyToDelete] = React.useState([]);
  const dispatch = useDispatch();

  const columns = [
    {
      key: 'mobile_code',
      title: 'Blacklist Code',
      dataIndex: 'mobile_code',
    },
    {
      key: 'mobile',
      title: 'Mobile Number',
      dataIndex: 'mobile',
    },
    {
      key: 'created_at',
      title: 'Date Added',
      dataIndex: 'created_at',
    },
    {
      key: 'Action',
      title: 'Action',
      render: (record) => (
        <Dropdown overlay={() => <Menu><Menu.Item><a onClick={()=>handleDeleteRow(record.id)}>Delete</a></Menu.Item></Menu>} placement="bottomCenter" trigger={['click']}>
          <Icon type="ellipsis" />
        </Dropdown>
      )
    }
  ]

  const success = (msg) => {
    message.success(msg);
  };

  const errorPrompt = (msg) => {
    message.error(msg);
  };

  const confirmDeleteRow = async () => {
    setLoading(true);
    try {
      const res = await fraudServices.deleteBlacklist('mobile',{target_id:keyTodelete})
      if(res.status==='success'){
        success(res.message);
        onDeleted();
        setShowDelModal(false);
      } else {
        errorPrompt(res.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      errorPrompt(error);
    }
  }

  const handleDeleteRow = (id) => {
    setKeyToDelete(id);
    setShowDelModal(true);
  }

  const handleChangePage = async (page) => {
    setLoading(true);
    try {
      const res = await fraudServices.getBlacklist('', page, 'mobile', sort);
      if(res&&res.results){
        dispatch(fraudActions.setTableData(res.results));
      } 
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const changePage = (page, pageSize) => {
    setMaxPage(page * pageSize)
    setMinPage((page - 1) * pageSize)
    dispatch(fraudActions.setTablePage(page));
    handleChangePage(page);
  }

  return (
    <div>
      <Table 
        rowClassName="table-row-light"
        columns={columns}
        dataSource={data}
        rowKey={record => record.id}
        loading={isLoading || loading}
        pagination={false}
      />
      <Pagination
        size='small'
        total={length}
        defaultPageSize={20}
        defaultCurrent={1}
        current={currentPage}
        onChange={(page, pageSize) => changePage(page, pageSize)}
        style={{padding:'12px 0 8px 0', textAlign: 'right'}}
      />
      <DeleteRowModal visible={showDelModal} closeModal={()=> setShowDelModal(false)} onDelete={confirmDeleteRow} />
    </div>
  )
}

export default BlacklistTable;