import React,{} from 'react';
import { isMobile } from 'react-device-detect';
import {history} from '../../../store/history';
import TestModeIcon from '../../../static/icons/test_mode.svg'

const TestModeBanner = (props) => {
  const testMode = (history.location.pathname.indexOf('/test') > -1);
  return (
    <div style={{...styles.root, ...props}} hidden={!testMode}>
      <label style={styles.body}>
        <img hidden={!props.icon} src={TestModeIcon} style={styles.iconStyle}/>TEST MODE
      </label>
    </div>
  )
}

const styles = {
  root: {
    borderTop: '4px #454545 solid', 
    textAlign: 'center',
    position: 'fixed',
    zIndex: '2',
    display: 'flex',
    justifyContent: 'center',
    width:'100%',
  },
  body: {
    backgroundColor: '#454545', 
    borderRadius: '0 0 10px 10px', 
    padding: '4px 30px 8px 30px', 
    fontSize: 14, 
    fontWeight: '900', 
    color: '#FFF',
  },
  iconStyle:{
    marginTop: '-3px',
    marginRight: '10px'
  }
}

export default TestModeBanner;