import React from 'react';
import moment from 'moment';
import { Table, Pagination, Dropdown, Icon, Menu, Typography, Alert } from 'antd';

const MultiwalletTable = (props) => {
    const { data, isLoading, length, selected, notAdmin } = props;
    const [loading, setLoading] = React.useState(false);
    const [minPage, setMinPage] = React.useState(0);
    const [maxPage, setMaxPage] = React.useState(10);
    const [searchValue, setSearchValue] = React.useState(null);
    const [currentPage, setCurrentPage] = React.useState(1);

    const returnKycLevel = (level) => {
        switch (level) {
            case 0:
                return 'Basic';
            case 1:
                return 'Advanced';
            case 2:
                return 'Pro';
            case 3:
                return 'Business';
            default:
                return 'Basic';
        }
    }

    const returnStatus = (status) => {
        if (status === 'SU') {
            return 'Success'
        } else {
            return 'Failed'
        }
    }

    const columns = [
        {
            title: 'Transaction ID',
            dataIndex: 'id',
        },
        {
            title: 'Destination',
            dataIndex: 'bank',
        },
        {
            title: selected === 'Paid' ? 'Date Paid' : 'Date',
            dataIndex: 'date_paid',
            render: (record) => moment(record).format('MMM D, YYYY h:mm A')
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks'
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (record) => <Typography style={{ ...styles.amount, color: '#2B2D32' }}>{notAdmin? "₱ " + record && record.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") : record}</Typography>
        }
    ]

    const userHistoryColumns = [

        {
            title: 'Destination',
            dataIndex: 'account_number',
            width: 230,
            render: (text, record) => {
                return <div>
                      <a className="smallBoldStyle" href>{record.account_number} </a>
                    <div className="text-with-wrap">{record.bank}<br/>{record.account_name}</div>
                </div>
            }
        },
        {
            title: 'Date',
            dataIndex: 'date_paid',
            render: text => {
                return <span className="normalTextStyle">{text}</span>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: text => {
        
                return <Alert type={text && (text).includes("Failed") ? "error" : text && (text).includes("Pending") ? "warning" : "success" } message={text} className="normalTextStyle" />
            }
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            render: text => {
                return <span className="normalTextStyle">{text}</span>
            }
        },
        {
            title: 'Amount(P)',
            dataIndex: 'amount',
            render: text => {
                return <span className="normalTextStyle">{text}</span>
            }
        },
    ];

    const renderPayoutFields = (status) => {

        let status_filtered = [];
        let total_amount = 0;
        if (status != "All") {
            status_filtered = data.filter(single_payout => single_payout.status.toLowerCase().includes(status.toLowerCase()))

        } else {
            status_filtered = data;
        }

        //remove PHP
        let filtered_data = status_filtered.map((item) => item.amount.slice(3))
        //remove commas in amount
        let removeCommas = filtered_data.map((item) => item.split(',').join('')),
            //string to number
            amount = removeCommas.map(Number)

        //sum of amount
        total_amount = amount.reduce((a, b) => a + b, 0)

        //thousand seperator
        let num_parts = parseFloat(total_amount).toFixed(2).toString().split('.')
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")

        // return [status_filtered, num_parts.join(".")]
        return status_filtered && status_filtered.length ? status_filtered.slice(minPage, maxPage) : [];
    }

    const changePage = (page, pageSize) => {
        setMaxPage(page * pageSize)
        setMinPage((page - 1) * pageSize)
    }

    const renderColumns = () => {
        if (notAdmin) {
            return columns;
        } else {
            return userHistoryColumns; 
        }
    }

    return (
        <div>
            <Table
                rowClassName="table-row-light"
                columns={renderColumns()}
                dataSource={data && notAdmin && data['payouts'] ? data['payouts'] : data && !notAdmin ? renderPayoutFields(selected) : []}
                loading={isLoading || loading}
                pagination={false}
            />
            <Pagination
                size='small'
                total={notAdmin && data ? data.pages_total * data.per_page_size : !notAdmin && data ? renderPayoutFields(selected).length : 0}
                defaultPageSize={10}
                defaultCurrent={1}
                current={notAdmin && data && data['page'] ? data['page'] : currentPage}
                onChange={(page, pageSize) => changePage(page, pageSize)}
                style={{ padding: '12px 0 8px 0', textAlign: 'right' }}
            />
        </div>
    )
}

const styles = {
    positive: {
        borderRadius: 4,
        padding: '4px 8px',
        backgroundColor: 'rgb(232, 250, 243)',
        border: '1px solid #1DD28B',
        borderLeft: '5px solid #1DD28B',
    },
    negative: {
        borderRadius: 4,
        padding: '4px 8px',
        backgroundColor: '#f9dbdb',
        border: '1px solid #E24C4C',
        borderLeft: '5px solid #E24C4C',
    },
    receiverName: {
        color: '#2B2D32',
        fontSize: 14,
        fontWeight: '600',
        textAlign: 'left',
    },
    receiverEmail: {
        color: 'rgba(43, 45, 50, 0.64)',
        fontSize: 12,
        textAlign: 'left',
    },
    amount: {
        fontSize: 14,
        fontWeight: 'bold'
    }
}

export default MultiwalletTable;