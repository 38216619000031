
import { get, patch, post, put } from '../methods';
import { Handle401 } from '../../components/handle401/handle401';

const accountSettingsServices = {
    getQRDetails: async (testmode, tradeName, masterTradeName) => {
        try {
            let url1 = testmode ? '/api/sandbox/qr_details/' : '/api/qr_details/'
            let url2 = url1 + `?trade_name=${tradeName}&master_trade_name=${masterTradeName}`
            if (tradeName) {
                return (await get(url2, true)).data;
            } else {
                return (await get(url1, false)).data;
            }
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    updateQrDetails: async (testMode, params) => {
        try {
            const url = testMode ? '/api/sandbox/update_qr_details/' : '/api/update_qr_details/';
            return (await post(url, params)).data;
        }
        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getEnterpriseDetails: async (testMode, trade_name) => {
        try {
            let endpoint = testMode ? '/api/sandbox/enterprise/' : '/api/enterprise/'
            let url = process.env.REACT_APP_API_URL + endpoint + `${trade_name}/details/`;
            return (await get(url, false)).data;
        }
        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    updateMultiwalletBanner: async (testMode, params) => {
        try {
            const url = testMode ? '/api/sandbox/multiwallet/settings/' : '/api/multiwallet/settings/'
            return (await post(url, params)).data;
        }
        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getGenSettings: async () => {
        try {
            const url = `/api/settings/`;
            return (await get(url)).data;
        }
        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    toggleTranxFee: async (params) => {
        try {
            const url = `/api/settings/`;
            return (await post(url, params)).data;
        }
        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getUserDefinedSetting: async (userId, query_params) => {
        try {
            const url = `api/user/${userId}/custom_settings/` + query_params;
            return (await get(url)).data;
        }
        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    toggleEmailNotif: async (userId, params) => {
        try {
            const url = `api/user/${userId}/custom_settings/`;
            return (await put(url, params)).data;
        }
        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    toggleSmsNotif: async (userId, params) => {
        try {
            const url = `api/user/${userId}/custom_settings/`;
            return (await put(url, params)).data;
        }
        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getUserAccessData: async (userId, params) => {
        try {
            const url = userId ? `/api/enterprise/alias/?parent_id=${userId}` : `/api/enterprise/alias/`;
            return (await get(url, params)).data;
        }
        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    suspendChildToggle: async (params) => {
        try {
            const url = `/api/enterprise/alias/`;
            return (await patch(url, params)).data;
        }
        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    addChildAccount: async (params) => {
        try {
            const url = `/api/enterprise/alias/`;
            return (await post(url, params)).data;
        }
        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    changeTradeName: async (isTestMode, params) => {
        try {
            const url = isTestMode ? '/api/sandbox/change_qr_trade_name/' : '/api/change_qr_trade_name/'
            return (await post(url, params)).data;
        }
        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getMonthlySummary: async (search = '', start, end, page = 1, pageSize = 10, isExport = '', email = '', user_history = 'false') => {
        try {
            const url = `api/admin/monthy_summary_reports/?search=${search}&start=${start}&end=${end}&page=${page}&page_size=${pageSize}&email=${email}&export=${isExport}&user_history=${user_history}`;
            return (await get(url)).data;
        }
        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    multiwalletCheckoutToggle: async (uid, enabled) => {
        try {
            const url = `/api/admin/users/masterwallet/`;
            const params = { 'user_id': uid, 'checkout_enabled': enabled }
            return (await post(url, params)).data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getAliasPermissions: async (parentID) => {
        try {
            const url = `api/enterprise/${parentID}/alias_settings/`;
            return (await get(url)).data;
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    Handle401()
                } else {
                    throw new Error(error.response.data.message);
                }
            } else {
                throw new Error('No response from backend');
            }
        }
    },
    saveAliasPermissions: async (parentID, params) => {
        try {
            const url = `api/enterprise/${parentID}/alias_settings/`
            return (await patch(url, params)).data;
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    Handle401()
                } else {
                    throw new Error(error.response.data.message);
                }
            } else {
                throw new Error('No response from backend');
            }
        }
      },
}

export default accountSettingsServices;