import React from "react";
import { isMobile } from 'react-device-detect';
import ContentCard from "./content_card";
import * as Icon from '../../static/icons/how_it_works/index';
import * as Img from '../../static/images/how_it_works/index';
import ContentStep3 from "./step_3";
import ContentStep4 from "./step_4";
import LinkStore from "./link_store";
import ContentStep6 from "./step_6";
import ContentStep7 from "./step_7";
import ContentStep8 from "./step_8";
import ProfileStep from "./reusable_content/profile";
import SettingsStep from "./reusable_content/settings";

const HowItWorksMain = props => {

    const hash = window.location.hash;
    const segments = localStorage.getItem('segments')
    const userType = localStorage.getItem('userType')


    React.useEffect(() => {
        if (hash === '') {
            window.location.hash = '#1';
        }
    }, [])

    let dasboard_icon = segments == 'me_' ? Icon.Dashboard_me_ : 
                            segments == 'gig' ? Icon.Dashboard_gig : 
                                segments == 'sme' ? Icon.Dashboard_sme : 
                                    segments == 'biz' ? Icon.Dashboard_biz: "" 
    
    let payment_req_icon = segments == 'me_' ? Icon.Payment_Request_me_ : 
                                    segments == 'gig' ? Icon.Payment_Request_gig : 
                                        segments == 'sme' ? Icon.Payment_Request_sme : 
                                            segments == 'biz' ? Icon.Payment_Request_biz : "" 
    
    let hash_included = userType === 'ME' ? ["#5", "#6"] : ["#5", "#7"]

    return(
        <div style={{width: '100%'}} align="center">
            <div className={isMobile ? 'mobile-div' : 'web-div'} 
            style={{
                height: !hash_included.includes(hash) && '100vh'
            }}>
                <div hidden={isMobile} className="screen-title-text" align="left">
                    How to use BUx
                </div>
                
                {
                    hash === "#1" ? 
                        <ContentCard step={1} 
                            icon={dasboard_icon} 
                            image={Img.Dashboard}
                            title="Dashboard"
                            content={ "Your main dashboard provides you with an overview of your transactions."} />
                        :
                        hash === "#2" ?
                            <ContentCard step={2} 
                                icon={payment_req_icon} 
                                image={Img.Payment_Request}
                                title="Payment Request"
                                content={ "This tab displays all your payment request transactions and allows you to schedule payment requests."} />
                        :
                        hash === "#3" ?
                            <ContentStep3 segments={segments} />
                        :
                        hash === "#4" ?
                            <ContentStep4 segments={segments} />
                        :
                        hash === "#5" ?
                            <LinkStore segments={segments} />
                        :
                        hash === "#6" ?
                            <ContentStep6 segments={segments} />
                        :
                        hash === "#7" ?
                            <ContentStep7 segments={segments} />
                        :
                        hash === "#8" ?
                            <ContentStep8 segments={segments} />
                        :
                        userType === 'CO' && hash === "#9" ?
                            <ProfileStep segments={segments} step={9} />
                        :
                        userType === 'CO' && hash === "#10" ?
                            <SettingsStep segments={segments} step={10} />
                        :
                        (userType === "ME" && hash === "#9") || 
                        (userType === "CO" && hash === "#11") ?
                            <ContentCard step={userType === "ME" ? 9 : 11} 
                            image={Img.Complete}
                            title="You're all set!"
                            content={ "We’re excited to see you begin your journey on making payments easier for you and your customers! You’re only one step away from unlocking more of our features. Start by completing your profile now."} />
                        :
                        ""
                }
            </div>
        </div>
    ) 
}

export default HowItWorksMain;