import React, {useEffect} from 'react'
import MetaTags from 'react-meta-tags'
import { history } from '../../../../store/history'

const VoucherSinglePage = props => {


    const code = props.match.params.code;
    const description = props.match.params.description;
    const banner_url = props.match.params.banner_url;

    useEffect(()=>{
        history.push('/dashboard')
    },[])

    return(
        <div>
            <MetaTags>
                <title>BUx | {code}</title>
                <meta
                    name="description"
                    content={decodeURIComponent(description)}
                />
                <meta property="og:description" content={decodeURIComponent(description)} />
                <meta property="og:image" content={decodeURIComponent(banner_url)} />

            </MetaTags>
        </div>
    )
}

export default VoucherSinglePage