import React from 'react';
import ButtonGroup from 'antd/lib/button/button-group';
import moment from 'moment';
import MultiwalletTable from './table';
import { Button, DatePicker, Dropdown, Icon, Input, Menu, message, Typography } from 'antd';
import { history } from '../../../../../../store/history';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import multiwalletServices from '../../../../multiwalletServices';
import ClearFilter from '../../../../../AdminComponents/filter_btns/clear_filter_btn';
import SwitchModal from './switch_corporate_modal';

const SubwalletTable = (props) => {

    const [selected, setSelected] = React.useState('Sub-wallet')
    const [dateRange, setDateRange] = React.useState({ startDate: '', endDate: '' });
    const [visibleClearBtn, setVisibleClearBtn] = React.useState(false);
    const [searchVal, setSearchVal] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [createBtnStyle, setCreateBtnStyle] = React.useState({ color: '#1DD28B', backgroundColor: 'transparent' });
    const [tableData, setTableData] = React.useState(null);
    const [kycLevelFilter, setKycLevelFilter] = React.useState('');
    const [pageSize, setpageSize] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const { segments, setFilters, buxBalance, onTransferSuccess } = props;
    const [switchModalVisible, setSwitchModalVisible] = React.useState(false)
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const subwalletTypesEnabled = localStorage.getItem('subwallet_types_enabled') === 'true';
    const kybRequired = localStorage.getItem("kybRequired") === 'true';

    const ButtonTab = ({ rootStyle }) => {

        const status = ['Sub-wallet', 'Transaction History'];

        const handleClick = (event) => {
            setSelected(event.target.value);
        }

        return (
            <div style={rootStyle}>
                <ButtonGroup>
                    {
                        status.map((btn, i) => {
                            return (
                                <Button
                                    key={btn}
                                    value={btn}
                                    onClick={handleClick}
                                    style={{
                                        ...styles.btnGroup,
                                        color: btn === selected ? '#FFF' : '#2B2D33',
                                        backgroundColor: btn === selected ? '#0D3D76' : '#FFF',
                                        borderRadius: i === 0 ? '10px 0 0 10px' : i === 1 ? '0 10px 10px 0' : 0
                                    }}
                                >
                                    {btn}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </div>
        )
    }


    const handleKycLevelChange = ({ item }) => {
        setKycLevelFilter(item.props.value);
        setVisibleClearBtn(true);
    }

    const kycLeveMenu = (
        <Menu onClick={handleKycLevelChange}>
            <Menu.Item key="basic" value={'0'}>
                Non-KYC
            </Menu.Item>
            <Menu.Item key="pro" value={'1'}>
                Approved KYC
            </Menu.Item>
            {/* <Menu.Item key="advanced" value={'2'}>
                Advanced
            </Menu.Item>
            <Menu.Item key="business" value={'3'}>
                Business
            </Menu.Item> */}
        </Menu>
    )

    const returnKycLevel = (val) => {
        switch (val) {
            case '0':
                return 'Non-KYC';
            case '1':
                return 'Approved KYC';
            // case '2':
            //     return 'Advanced';
            // case '3':
            //     return 'Business';
            default:
                return 'KYC Level';
        }
    }

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    const rangeSelect = (dates, dateString, id) => {
        setDateRange({ ...dateRange, [id]: moment(dateString).format('MM-DD-YYYY') });
    }

    const onFilterApply = () => {
        getSubwalletUsers(testMode, searchVal, dateRange.startDate, dateRange.endDate, '', kycLevelFilter, pageSize, page);
        setVisibleClearBtn(true);
        setFilters([null ,searchVal, dateRange.startDate, dateRange.endDate, '', '', kycLevelFilter, pageSize, 1]);
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value);
    }

    const applyBtnDisabled = () => {
        return !dateRange.startDate &&
            !dateRange.endDate &&
            !searchVal && (!kycLevelFilter || kycLevelFilter === '')
    }

    const resetFilters = () => {
        setDateRange({ startDate: '', endDate: '' });
        setSearchVal('');
        setVisibleClearBtn(false);
        setKycLevelFilter('')
        setpageSize(10);
        setFilters([]);
        setPage(1);
        getSubwalletUsers(testMode, "", "", "", "", "", 10, 1);

    }


    const onCreateBtnHover = () => {
        setCreateBtnStyle({
            color: '#FFF',
            backgroundColor: '#1DD28B'
        });
    }

    const onCreateBtnLeave = () => {
        setCreateBtnStyle({
            color: '#1DD28B',
            backgroundColor: 'transparent'
        });
    }

    const changePage = (page) => {
        getSubwalletUsers(testMode, searchVal, dateRange.startDate, dateRange.endDate, '', kycLevelFilter, pageSize, page)
        setPage(page);
    }

    const onSuccessTransfer = () => {
        onTransferSuccess();
        getSubwalletUsers(testMode);
    }

    const onUpdateAuthorizedPerson = async (data) => {  
        try {
            await multiwalletServices.updateAuthorizedPerson(data);
            message.success('Successfully updated sub-wallet authorized person.', 3);
            getSubwalletUsers(testMode, searchVal, dateRange.startDate, dateRange.endDate, '', kycLevelFilter, pageSize, 1);
        } catch (error) {
            message.error(error.message, 3);
        }   
    }

    const onDeactivateSubwallet = async (user) => {
        try {
            await multiwalletServices.deactivateSubUser(user.id);
            message.success(`Successfully deactivated sub-wallet: ${user.email}`, 3);
            getSubwalletUsers(testMode, searchVal, dateRange.startDate, dateRange.endDate, '', kycLevelFilter, pageSize, 1);
        } catch (error) {
            message.error(error.message, 3);
        }
    }

    const getSubwalletUsers = async (isTestMode, search, start, end, status, kycLevel, pageSize, page) => {
        setIsLoading(true);
        try {
            const res = await multiwalletServices.getSubwalletUsers(isTestMode, search, start, end, status, kycLevel, pageSize, page);
            if (res) {
                console.log(res)
                setTableData(res);
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    React.useEffect(() => {
        getSubwalletUsers(testMode, searchVal, dateRange.startDate, dateRange.endDate, '', kycLevelFilter, pageSize, 1);
    }, [])

    return (
        <div style={styles.root}>
            <div style={styles.body}>
                <div style={styles.subContainer} className="top-16">
                    <ClearFilter hideFilterBtn={!visibleClearBtn} clearFilter={resetFilters} />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{ display: 'flex', padding: '0 0 24px 0' }}>
                        {/* <DatePicker
                            format='MM/DD/YYYY'
                            disabledDate={disabledDate}
                            // defaultValue={moment()}
                            value={dateRange.startDate && moment(dateRange.startDate)}
                            size="large" picker="month"
                            onChange={(dates, dateString) => rangeSelect(dates, dateString, 'startDate')}
                            placeholder='Start date'
                            style={styles.datePic}
                            suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                            allowClear={false} />
                        <span style={{ paddingTop: 8 }}>-</span>
                        <DatePicker
                            format='MM/DD/YYYY'
                            disabledDate={disabledDate}
                            // defaultValue={moment()}
                            value={dateRange.endDate && moment(dateRange.endDate)}
                            size="large"
                            picker="month"
                            onChange={(dates, dateString) => rangeSelect(dates, dateString, 'endDate')}
                            placeholder='End date'
                            style={styles.datePic}
                            suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                            allowClear={false} /> */}
                        <Dropdown overlay={kycLeveMenu} trigger={['click']}>
                            <a style={styles.dropdownItems} >
                                {returnKycLevel(kycLevelFilter)}
                                <ExpandMoreIcon style={styles.dropdownArror} />
                            </a>
                        </Dropdown>
                        <Input
                            placeholder="Search"
                            style={{ width: '200px', height: '40px', borderRadius: '4px', padding: '0 8px' }}
                            suffix={<Icon type="search" style={{ fontSize: '18px' }} />}
                            onChange={handleSearch}
                            value={searchVal}
                        />
                        <button className={`btn--${segments} btn-height`} style={{opacity: applyBtnDisabled() ? '0.5' : '1' }} disabled={applyBtnDisabled()} onClick={onFilterApply}>
                            Apply
                        </button>
                    </div>
                    <div hidden={testMode || (tableData && tableData.subuser_role == 'CO') || subwalletTypesEnabled || !kybRequired} style={{display: 'flex', paddingRight: '20px', cursor: 'pointer'}} onClick={()=>setSwitchModalVisible(true)}>
                        <Icon className={`text-${segments}`} type="user" style={{fontSize: '16px', margin:'3px 8px 0px 0px'}}/>
                        <div className={`text-${segments}`} style={{fontSize: '16px', fontWeight: '500'}}>Convert Sub-wallets</div>
                    </div>

                </div>
                <SwitchModal getSubWallet={()=>getSubwalletUsers(testMode)} segments={segments} visible={switchModalVisible} setVisible={setSwitchModalVisible}/>
                
                <MultiwalletTable
                    segments={segments}
                    data={tableData}
                    isLoading={isLoading}
                    selected={selected}
                    onChangePage={changePage}
                    pageSize={pageSize}
                    onTransferSuccess={onSuccessTransfer}
                    buxBalance={buxBalance}
                    onUpdateAuthorizedPerson={onUpdateAuthorizedPerson}
                    onDeactivateSubwallet={onDeactivateSubwallet}
                // sort={tableSort}
                />
            </div>
        </div>
    )
}

const styles = {
    root: {
        width: '100%',
    },
    body: {
        padding: '0px 16px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    },
    btnContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    exportBtn: {
        fontSize: 16,
        fontWeight: '600',
        padding: '8px, 16px, 8px, 16px',
        border: '1px solid #1DD28B',
        height: 40,
        marginRight: 12,
        color: '#FFF',
        backgroundColor: '#1DD28B'
    },
    btnGroup: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: '0 32px',
    },
    subContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 0'
    },
    clearDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    clearIcon: {
        color: '#2B2D33',
        fontSize: 10,
    },
    filterText: {
        color: '#000',
        fontSize: 14,
        fontWeight: 'bold'
    },
    clearBtnDiv: {
        backgroundColor: '#E6EAF0',
        borderRadius: 24,
        padding: '4px 12px',
        margin: '0 12px'
    },
    clearBtn: {
        color: '#000000',
        fontSize: 12,
        paddingLeft: 4,
    },
    reminder: {
        fontSize: 12,
        color: '#909196',
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 178,
        padding: '0 8px 0 8px'
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    applyBtn: {
        height: 40,
        width: 92,
        fontSize: 16,
        fontWeight: '600',
        borderRadius: 4,
        backgroundColor: '#F5922F',
        color: '#FFF',
    },
    createBtn: {
        fontSize: 16,
        fontWeight: '600',
        height: 40,
        borderColor: '#1DD28B',
        marginRight: 8,
    },
    dropdownItems: {
        display: 'flex',
        alignItems: 'center',
        padding: '7px 4px 7px 16px',
        fontSize: 16,
        color: '#2B2D33',
        border: '1px solid #D1D5DD',
        borderRadius: 5,
    },
}

export default SubwalletTable;