import React from 'react';
import moment from 'moment';
import { Table, Pagination, Dropdown, Icon, Menu, Typography } from 'antd';

const WalletTable = (props) => {
    const { data, isLoading, pageSize, onChangePage, status, selected } = props;
    const [loading, setLoading] = React.useState(false);



    const columns1 = [
        {
            title: 'Transaction ID',
            dataIndex: 'id',
        },
        {
            title: 'Date',
            dataIndex: 'timestamp',
            render: (record) => moment(record).format('MMM D, YYYY h:mm A')
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (record) => <Typography style={{ ...styles.amount, color: '#2B2D32' }}>₱{record && record.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</Typography>
        },
    ]

    const columns2 = [
        {
            title: 'Reference no.',
            dataIndex: 'code',
            render: (text, record) => (
                text ?
                    <>
                        <Typography style={styles.columnField}><b>{text}</b></Typography>
                        <Typography style={styles.columnDesc}>{record.description}</Typography>
                    </>
                    :
                    <>
                        <Typography style={styles.columnDiv}>Pending</Typography>
                        <Typography style={styles.columnDesc}>{record.description}</Typography>
                    </>
            )
        },
        {
            title: status === 'Paid' ? 'Date Paid' : status === 'Paid' ? 'Date Paid' : status === 'Cancelled' ? 'Date Cancelled' : 'Date Expired',
            dataIndex: status === 'Paid' ? 'timestamp' : status === 'Paid' ? 'date_paid' : status === 'Cancelled' ? 'cancelled_at' : 'expire_at',
        },
        {
            title: 'Channel',
            dataIndex: 'channel_str',
            width: 120,
            render: (text, record) => {
                if (text)
                    return <div style={styles.columnDiv} >
                        {
                            text === record.terminal ?
                                <>
                                    <Typography style={styles.columnField}>{text}</Typography>
                                </>
                                :
                                <>
                                    <Typography style={styles.columnDiv}>{text}</Typography>
                                    <Typography style={styles.columnDesc}>{record.terminal}</Typography>
                                </>
                        }
                    </div>
                else
                    return <Typography style={styles.columnNoChannel}>Customer has not yet selected a payment channel.</Typography>
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (record) => <Typography style={{ ...styles.amount, color: '#2B2D32' }}>₱{record && parseFloat(record).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</Typography>
        },
    ]

    const setTableData = () => {
        if (selected === 'Add Money') {
            return data && data['orders'] ? data['orders'] : [];
        }
        return data && data['results'] ? data['results'] : [];
    }

    const setTablePage = () => {
        if (selected === 'Add Money') {
            return data && data.summary? data.summary : null;
        }
        return data? data : null;
    }

    return (
        <div>
            <Table
                rowClassName="table-row-light"
                columns={selected === 'Add Money' ? columns2 : columns1}
                dataSource={setTableData()}
                loading={isLoading || loading}
                pagination={false}
            />
            <Pagination
                size='small'
                total={setTablePage() && setTablePage()[selected==='Add Money'? 'total_data' : 'total']}
                defaultPageSize={pageSize}
                pageSize={pageSize}
                defaultCurrent={setTablePage() && setTablePage()['page']}
                current={setTablePage() && setTablePage()['page']}
                onChange={(page, pageSize) => onChangePage(page, pageSize)}
                style={{ padding: '12px 0 8px 0', textAlign: 'right' }}
            />
        </div>
    )
}

const styles = {
    positive: {
        borderRadius: 4,
        padding: '4px 8px',
        backgroundColor: 'rgb(232, 250, 243)',
        border: '1px solid #1DD28B',
        borderLeft: '5px solid #1DD28B',
    },
    negative: {
        borderRadius: 4,
        padding: '4px 8px',
        backgroundColor: '#f9dbdb',
        border: '1px solid #E24C4C',
        borderLeft: '5px solid #E24C4C',
    },
    receiverName: {
        color: '#2B2D32',
        fontSize: 14,
        fontWeight: '600',
        textAlign: 'left',
    },
    receiverEmail: {
        color: 'rgba(43, 45, 50, 0.64)',
        fontSize: 12,
        textAlign: 'left',
    },
    amount: {
        fontSize: 14,
        fontWeight: 'bold'
    }
}

export default WalletTable;