import { Spin } from "antd";
import React from "react";
import "./Button.css";

export function Button({
  type = "button",
  className = "",
  isLoading = false,
  color = "#f5922f",
  ...props
}) {
  const buttonStyle = {
    backgroundColor: color,
    border: "1px solid #0051B0",
    cursor: isLoading ? "default" : "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <button
      className={`button ${className}`}
      style={buttonStyle}
      type={type}
      {...props}
      disabled={isLoading ? true : false}
    >
      {isLoading && <Spin spinning={isLoading} size="small" />}
      {props.children}
    </button>
  );
}
