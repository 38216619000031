import React,{ useState } from 'react';
import MetaTag from '../../components/meta_tag/meta_tag';
import { history } from '../../store/history';
import GreetingCard from '../../components/AdminComponents/overview/overview_version_2/components/greetingCard';
import OverviewVersion2 from '../../components/AdminComponents/overview/overview_version_2';
import '../../App.scss';

const OverviewV2 = () => {
  const [ready, setReady] = React.useState(false) 

  const pageRedirect = () => {
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
      history.push('/login')
    }
    else  {
      if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
        history.push('/dashboard')
      }
      else if (localStorage.getItem("userType") == "PS" || localStorage.getItem("userType") == "PF") {
          history.push('/submissions_kyb')
      }else{
        setReady(true)
      }
    }
  }

  React.useEffect(() => {
    pageRedirect();
  },[])

  return (
    <div>
      <MetaTag title="Overview" />
      { ready && (
      <>
        <OverviewVersion2/>
      </>
      )}
    </div>
  )
};

export default OverviewV2;