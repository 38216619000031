import React from 'react'
import {Card, Select, Button, Icon, Spin, Tooltip} from 'antd'
import enterpriseStyle from '../kybStyle'
import { isMobile } from 'react-device-detect'
import NoteCard from '../noteCard'
import axios from 'axios'
import UploadProgressBar from '../custom_component/upload_progress_bar';
import SecCert from '../../../../static/files/Bux_SecretaryCertificate.pdf'


const {Option} = Select


const BusinessDocument = props => {
    let data = props.datas
    const kybStep = localStorage.getItem('KYBStep')
    const kyb = enterpriseStyle()
    const note = "Photo or PDF are accepted. Maximum of 20mb per file."
        //alert message
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(false)

    const businessType = data ? data.business_type : props.businessType
    
    const stripQueryParams = (myString) => myString.slice(65).replace(/\?.*/,'')

    const [secRegisterFile, setSecRegisterFile] = React.useState("")
    const [secRegisterFileName, setSecRegisterFileName] = React.useState(!data || data.sec_registration_attachment == null ? null : stripQueryParams(data.sec_registration_attachment))

    const [articlesOfIncFile, setArticlesOfIncFile] = React.useState("")
    const [articlesOfIncFileName, setArticlesOfIncFileName] = React.useState(!data || data.articles_of_incorporation_attachment == null ? null : stripQueryParams(data.articles_of_incorporation_attachment))

    const [byLawsFile, setByLawsFile] = React.useState("")
    const [byLawsFileName, setByLawsFileName] = React.useState(!data || data.bylaws_attachment == null ? null : stripQueryParams(data.bylaws_attachment))

    const [latestGSISFile, setLatestGSISFile] = React.useState("")
    const [latestGSISFileName, setLatestGSISFileName] = React.useState(!data || data.gis_attachment == null ? null : stripQueryParams(data.gis_attachment))

    const [secretaryCertFile, setSecretaryCertFile] = React.useState("")
    const [secretaryCertFileName, setSecretaryCertFileName] = React.useState(!data || data.secretary_certificate_attachment == null ? null : stripQueryParams(data.secretary_certificate_attachment))

    const [bir2303File, setBir2303File] = React.useState("")
    const [bir2303FileName, setBir2303FileName] = React.useState(!data || data.bir_2303_attachment == null ? null : stripQueryParams(data.bir_2303_attachment))

    const [businessPermitFile, setBusinessPermitFile] = React.useState("")
    const [businessPermitFileName, setBusinessPermitFileName] = React.useState(!data || data.business_permit_attachment == null ? null : stripQueryParams(data.business_permit_attachment))    

    //Sole documents
    const [dtiFile, setDtiFile] = React.useState("")
    const [dtiFileName, setDtiFileName] = React.useState(!data || data.dti_registration_attachment == null ? null : stripQueryParams(data.dti_registration_attachment))   

    const [businessPermitNoFile, setBusinessPermitNoFile] = React.useState("")
    const [businessPermitNoFileName, setBusinessPermitNoFileName] = React.useState(!data || data.business_permit_attachment == null ? null : stripQueryParams(data.business_permit_attachment))    

    const [birTaxFile, setBirTaxFile] = React.useState("")
    const [birTaxFileName, setBirTaxFileName] = React.useState(!data || data.bir_tax_document_attachment == null ? null : stripQueryParams(data.bir_tax_document_attachment))  
    
    const [birProofFile, setBirProofFile] = React.useState("")
    const [birProofFileName, setBirProofFileName] = React.useState(!data || data.bir_proof_of_receipt_attachment == null ? null : stripQueryParams(data.bir_proof_of_receipt_attachment))   

    const [mayorPermitFile, setMayorPermitFile] = React.useState("")
    const [mayorPermitFileName, setMayorPermitFileName] = React.useState(!data || data.mayors_permit_attachment == null ? null : stripQueryParams(data.mayors_permit_attachment))   
    const [isTouched, setIsTouched] = React.useState(false)
    const [hideSpin, setHideSpin] = React.useState(true)
    const [uploadedCheck, setUploadedCheck] = React.useState(0)

    var filesToUploadCount = 0;
    var filesUploadedCount = 0;
    const [uploadProgress, setUploadProgress] = React.useState(0);

    const userRole = localStorage.getItem("userType")
    const adminRole = ['AD', 'SD']
    const userId = ['CO','SW'].includes(localStorage.getItem('userType'))? 
                        localStorage.getItem('userId') : 
                    localStorage.getItem("KYBType") == "creation" ? 
                        localStorage.getItem('enterpriseId') : props.userId


    // useRef
    let secRegisterUpload = React.useRef()
    let articlesOfIncUpload = React.useRef()
    let byLawsUpload = React.useRef()
    let latestGSISUpload = React.useRef()
    let secretaryCertUpload = React.useRef()
    let bir2303Upload = React.useRef()
    let businessPermitUpload = React.useRef()

    let dtiUpload = React.useRef()
    let businessPermitNoUpload = React.useRef()
    let birTaxUpload = React.useRef()
    let birProofUpload = React.useRef()
    let mayorPermitUpload = React.useRef()

    let docus = []

    docus = 

    businessType == 0 ?
    [
        {
            key: 'dti_registration_attachment',
            name: 'DTI Registration',
            ref: dtiUpload,
            file_name: dtiFileName,
            hidden: uploadedCheck > 0 ? false : true
        },
        {
            name: 'Mayor’s Permit/Business Permit',
            key: 'business_permit_attachment',
            ref: businessPermitUpload,
            file_name: businessPermitFileName,
            hidden: uploadedCheck > 1 ? false : true
        },
        {
            name: 'BIR 2303',
            key: 'bir_2303_attachment',
            ref: bir2303Upload,
            file_name: bir2303FileName,
            hidden: uploadedCheck > 2 ? false : true
        },
        // {
        //     name: 'BIR Proof of Receipt',
        //     key: 'bir_proof_of_receipt_attachment',
        //     ref: birProofUpload,
        //     file_name: birProofFileName,
        //     hidden: uploadedCheck > 3 ? false : true

        // },
        // {
        //     name: 'Mayor’s Permit',
        //     key: 'mayors_permit_attachment',
        //     ref: mayorPermitUpload,
        //     file_name: mayorPermitFileName,
        //     hidden: uploadedCheck > 4 ? false : true

        // },
    ]:
    
    [
        {
            name: data.business_type == 1 ? 'SEC Registration' : 'SEC Certificate of Filing of Articles of Partnership',
            key: 'sec_registration_attachment',
            ref: secRegisterUpload,
            file_name: secRegisterFileName,
            hidden: uploadedCheck > 0 ? false : true
        },
        {
            name: data.business_type == 1 ? 'Articles Of Incorporation' : 'Articles of Partnership',
            key: 'articles_of_incorporation_attachment',
            ref: articlesOfIncUpload,
            file_name: articlesOfIncFileName,
            hidden: uploadedCheck > 1 ? false : true
        },
        {
            name: data.business_type == 1 ? `Secretary's Certificate` : 'Partnership Resolution',
            key: 'secretary_certificate_attachment',
            ref: secretaryCertUpload,
            file_name: secretaryCertFileName,
            note: data.business_type == 1 ? 'Notifying that the signatory is the authorized one' : '',
            hidden: uploadedCheck > 2 ? false : true,
            text: `Secretary's Certificate Template`,
            file: SecCert

        },
        {
            name: 'BIR 2303',
            key: 'bir_2303_attachment',
            ref: bir2303Upload,
            file_name: bir2303FileName,
            hidden: uploadedCheck > 3 ? false : true

        },
        {
            name: 'Mayor’s Permit/Business Permit',
            key: 'business_permit_attachment',
            ref: businessPermitUpload,
            file_name: businessPermitFileName,
            hidden: uploadedCheck > 4 ? false : true
        },

        
    ]

    if(data.years_in_business > 1 && data.business_type == 1){
        docus.push(
            {
                name: 'Latest GIS',
                key: 'gis_attachment',
                ref: latestGSISUpload,
                file_name: latestGSISFileName,
                hidden: uploadedCheck > 5 ? false : true
    
            }
        )
    }

    if(data.business_type == 1) {
        docus.push({
            name: 'By-Laws',
            key: 'bylaws_attachment',
            ref: byLawsUpload,
            file_name: byLawsFileName,
            hidden: uploadedCheck > 6 ? false : true

        })
    }

    //function handleSelect
    const handleUploadFile = (info, type) =>{
        setIsTouched(true)
        setError(false)

        //Corporate documents
        if(type == 'SEC Registration'){
            setSecRegisterFile(info)
            setSecRegisterFileName(info.name)
        }

        else if(type == 'Articles Of Incorporation'){
            setArticlesOfIncFile(info)
            setArticlesOfIncFileName(info.name)
        }
        else if(type == 'By-Laws'){
            setByLawsFile(info)
            setByLawsFileName(info.name)
        }
        else if(type == 'Latest GIS'){
            setLatestGSISFile(info)
            setLatestGSISFileName(info.name)
        }
        else if(type == `Secretary's Certificate`){
            setSecretaryCertFile(info)
            setSecretaryCertFileName(info.name)
        }
        else if(type == 'BIR 2303'){
            setBir2303File(info)
            setBir2303FileName(info.name)
        }
        else if(type == 'Mayor’s Permit/Business Permit'){
            setBusinessPermitFile(info)
            setBusinessPermitFileName(info.name)
        }

        //Partnership
        else if(type == 'SEC Certificate of Filing of Articles of Partnership'){
            setSecRegisterFile(info)
            setSecRegisterFileName(info.name)
        }

        else if(type == 'Articles of Partnership') {
            setArticlesOfIncFile(info)
            setArticlesOfIncFileName(info.name)
        }

        else if(type == 'Partnership Resolution'){
            setSecretaryCertFile(info)
            setSecretaryCertFileName(info.name)
        }

        //sole documents

        else if(type == 'DTI Registration'){
            setDtiFile(info)
            setDtiFileName(info.name)
        }
        else if(type == 'Mayor’s Permit/Business Permit'){
            setBusinessPermitFile(info)
            setBusinessPermitFileName(info.name)
        }
        else if(type == 'BIR Tax Document'){
            setBirTaxFile(info)
            setBirTaxFileName(info.name)
        }

        // else if(type == 'BIR Proof of Receipt'){
        //     setBirProofFile(info)
        //     setBirProofFileName(info.name)
        // }
        
        // else if(type == 'Mayor’s Permit'){
        //     setMayorPermitFile(info)
        //     setMayorPermitFileName(info.name)
        // }

    }

    function handleRemoveFile(type){
        setIsTouched(true)
        //Corporate documents
        if(type == 'SEC Registration'){
            setSecRegisterFileName(null)
            setSecRegisterFile("")
        }
        else if(type == 'Articles Of Incorporation'){
            setArticlesOfIncFileName(null)
            setArticlesOfIncFile("")
        }
        else if(type == 'By-Laws'){
            setByLawsFileName(null)
            setByLawsFile("")
        }
        else if(type == 'Latest GIS'){
            setLatestGSISFileName(null)
            setLatestGSISFile("")
        }
        else if(type == `Secretary's Certificate`){
            setSecretaryCertFileName(null)
            setSecretaryCertFile("")
        }
        else if(type == 'BIR 2303'){
            setBir2303FileName(null)
            setBir2303File("")
        }
        else if(type == 'Mayor’s Permit/Business Permit'){
            setBusinessPermitFileName(null)
            setBusinessPermitFile("")
        }

        //Partnership

        else if(type == 'SEC Certificate of Filing of Articles of Partnership'){
            setSecRegisterFile("")
            setSecRegisterFileName(null)
        }

        else if(type == 'Articles of Partnership') {
            setArticlesOfIncFile("")
            setArticlesOfIncFileName(null)
        }

        else if(type == 'Partnership Resolution'){
            setSecretaryCertFile("")
            setSecretaryCertFileName(null)
        }


        //sole documents

        else if(type == 'DTI Registration'){
            setDtiFileName(null)    
            setDtiFile("")    
        }
        else if(type == 'Mayor’s Permit/Business Permit'){
            setBusinessPermitFileName(null)
            setBusinessPermitFile("")
        }
        else if(type == 'BIR Tax Document'){
            setBirTaxFileName(null)
            setBirTaxFile("")
        }
        // else if(type == 'BIR Proof of Receipt'){
        //     setBirProofFile("")
        //     setBirProofFileName(null)
        // }
        // else if(type == 'Mayor’s Permit'){
        //     setMayorPermitFileName(null)
        //     setMayorPermitFile("")
        // }

    }

    function validateFields(){
        let i;
        let mb = 10000000;
        let acceptedFiles = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']
        
        setHideSpin(false)

        for(i=0; i < docus.length; i++){

            if(docus[i].file_name == null){
                setError(true)
                setHideSpin(true)
                setErrorMessage(`Please upload ${docus[i].name} file`)
                window.scrollTo(0, 0)
                return
            }

            else if((docus[i].file_name != null && docus[i].ref.current != null) && (docus[i].ref.current.files.length > 0)){
                if(!acceptedFiles.includes(docus[i].ref.current.files[0].type)){
                    setError(true)
                    setHideSpin(true)
                    setErrorMessage(`${docus[i].name} file must be .png, .jpg, .jpeg or .pdf only`)
                    window.scrollTo(0, 0)
                    setHideSpin(true)
                    return
                }
            }
        }

        submitForm()
    }


    const yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }

    function recalculateUploadProgress(progressEvent) {
        /* To manually invoke the multi-packet upload behavior, use the browser's Network throttling tool.
           If your internet is fast enough, axios only fires this function once, so throttling is necessary. */

        const recomputedProgress = filesUploadedCount + (progressEvent.loaded / progressEvent.total);
        setUploadProgress(100 * recomputedProgress / filesToUploadCount);
    }


    async function submitForm(){
        console.log(docus)
        filesUploadedCount = 0;
        setUploadProgress(0);
        let docus_s3_get = [];
        let docus_s3_update = [];
        let docus_s3_content = {};



        for(var i=0; i < docus.length; i++){
            if(docus[i].file_name != null && docus[i].ref.current != null && docus[i].ref.current.files.length > 0){
                docus_s3_get.push({file_name: docus[i].file_name,
                               key: docus[i].key })
                docus_s3_content[docus[i].key] = docus[i].ref;
            }
        }

        filesToUploadCount = docus_s3_get.length;

        let params = {'user_id': userId,
                      'mode': 'get_url',
                      'uploads': docus_s3_get}

        try{
            let url = process.env.REACT_APP_API_URL + "/api/kyb/upload/";
            let response = await axios.post(url,params,yourConfig)
            let s3_responses = response.data.s3_responses;
            for(var key in s3_responses){
                let obj = s3_responses[key];
                docus_s3_update.push({key: key, path: obj.path})
                let fields = obj.fields
                let url = obj.url
                // console.log(docus_s3_content[key].current.files[0]);
                let f = new FormData();
                f.append("key", fields.key)
                f.append("x-amz-algorithm", fields['x-amz-algorithm'])
                f.append("x-amz-credential", fields['x-amz-credential'])
                f.append("x-amz-date", fields['x-amz-date'])
                f.append("policy", fields.policy)
                f.append("x-amz-signature", fields['x-amz-signature'])
                f.append('file',docus_s3_content[key].current.files[0])

                let s3conf = {
                    onUploadProgress: recalculateUploadProgress
                }

                let api_response = await axios.post(
                   url, f, s3conf
                )
                filesUploadedCount++;
                setUploadedCheck(filesUploadedCount)
            }

            let params2 = {'user_id': userId,
                          'mode': 'update_path',
                          'uploads': docus_s3_update}
            url = process.env.REACT_APP_API_URL + "/api/kyb/upload/";
            response = await axios.post(url,params2,yourConfig)

            if(response.data.status == "success"){

                setHideSpin(true);
                // let formData = new FormData();
                // formData.append('step_to_update',3)
                // props.submitStep(formData, 3)
                window.location.reload()
            }

        }
        
        catch(error){
            console.log(error.message)
        }

   
        setHideSpin(true);
    }

    function editBusinessInfo(val){
        props.openEdit(val)
    }

    return(
        <div>
        <Card 
        className={kyb.formCardStyle}
        bodyStyle={{padding: '20px 16px 20px 16px'}}>
            <div className="bottom-32 twoCol">
                <div className={kyb.headerStyle}style={{ fontSize: isMobile ? '16px' : '20px'}}>Business Document</div>
                <div>
                    <a href className={`text-${props.segments} medium-font-weight`} onClick={() => editBusinessInfo(3)}
                    hidden={['CO','SW'].includes(localStorage.getItem('userType')) && kybStep < 7 || localStorage.getItem("KYBType") == "creation" && data ? data.step < 7 : ''}
                        >Cancel
                    </a>
                </div>
            </div>


            <NoteCard note={note} />

            {props.alertMessage}

            <div
                style={{
                    backgroundColor: 'rgba(226, 76, 76, 0.1)',
                    borderLeft: '4px solid #E24C4C',
                    width: '100%',
                    borderRadius: '4px',
                    marginBottom: '20px',
                    marginTop: '20px',
                    textAlign: 'left',
                    padding: '10px',
                    display: error ? "block" : "none"
                }}>
                <span className={kyb.normalText}>{errorMessage}</span>
            </div>

            {
                docus.map((item, key) => {
                    return(
                        <div key={key} style={{marginTop: 20}} data-test={item.key}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div className={kyb.miniText} style={{marginTop: item.text ? 4 : ''}}>{item.name}</div>
                                {
                                    data.business_type == 1 ? 
                                    <Tooltip placement="top" title="Optional: Download our Secretary's Certificate Template">
                                        <div style={{marginBottom: 16}}>
                                            <a href={item.file} download><div className={`text-${props.segments}`}>{item.text}</div></a>
                                        </div>
                                    </Tooltip>
                                    :
                                    ''
                                }
                          
                            </div>
                            <input type="file"
                                data-test="upload-file-input"
                                ref={item.ref}
                                style={{display: 'none'}}
                                onChange={(e)=>handleUploadFile(e.target.files[0], item.name)}
                                accept=".jpg,.png,.jpeg,.pdf"
                                >
                            </input>
                            <div>
                                <div className={kyb.uploadDiv}>
                                    <div className={kyb.uploadText} style={{padding: '7px 0px 7px 0px'}}>
                                    {/* upload */}
                                       <div style={{display: item.file_name == null ? 'block' : 'none'}}>Upload File</div> 
                                       {/* preview */}
                                       <div style={{display: item.file_name != null ? 'block' : 'none'}}>
                                            <div style={{display: 'flex'}}>
                                                <Icon type="file" className={`text-${props.segments} top-4 right-8`}/>
                                                <div id="fileName" className={kyb.fileName} style={{width: isMobile ? '150px' : '50%'}}>{item.file_name}</div>
                                            </div>  
                                       </div>
                                    </div>

                                    <div>
                                    {/* upload */}

                                        <div style={{display: item.file_name == null ? 'block' : 'none'}}>
                                            <button data-test="upload-btn" className={`outline-btn--${props.segments} btn-small-height`} onClick={()=>item.ref.current.click()}>
                                                <Icon type="upload" className="right-4" /> Upload
                                            </button>
                                        </div>
                                       {/* preview */}
                                      
                                        <div style={{display: item.file_name != null ? 'block' : 'none', position: 'absolute', right: 42, marginTop: 8}}>
                                            <button className={kyb.uploadedCard} hidden={item.hidden}>
                                                Uploaded
                                            </button>
                                            <a onClick={()=>{item.ref.current.value = null; handleRemoveFile(item.name)}} style={{padding: '8px 0px 0px 0px', fontWeight: 600}} className={`text-${props.segments}`}><Icon type='close' style={{fontSize: 20, fontWeight: 'bold'}} /></a>
                                        </div>
                                    </div>
                                </div>
                                {/* <Button className={kyb.uploadBtnStyle} onClick={()=>item.ref.current.click()}><Icon type="upload"/>Click to Upload</Button>    */}
                            </div>
                            <div className={kyb.miniText} style={{marginTop: 8}}>{item.note}</div>

                    </div>
                    )
                })
            }
            
            <div hidden={isMobile} align="right">
            <button className={`btn--${props.segments} btn-height top-20 right-8`}
                    hidden={!hideSpin}
                    style={{
                        opacity: (props.editMode && !isTouched) ? 0.5 : '',
                        cursor: (props.editMode && !isTouched) ? 'not-allowed' : 'pointer'
                    }}
                    disabled={(props.editMode && !isTouched)} 
                    onClick={() => validateFields()}
                    >
             
                        {
                            adminRole.includes(userRole) && localStorage.getItem("KYBType") != "creation" ? "Save Changes" : 
                            kybStep >= 6 ? "Save Changes" : 
                            "Submit and next"
                        }
            </button>

            <button className={kyb.uploadingBtn} 
                    hidden={hideSpin}>

                <div style={{paddingLeft: '10px', paddingRight: '10px'}}>
                    <UploadProgressBar value={uploadProgress}/>
                </div>
            </button>


            {props.footerNote}

            </div>

        </Card>
        <div className={kyb.stickyBtn} hidden={!isMobile}>
        <button className={`btn--${props.segments} btn-mobile-width`} 
                    hidden={!hideSpin}
                    style={{
                        opacity: (props.editMode && !isTouched) ? 0.5 : '',
                        cursor: (props.editMode && !isTouched) ? 'not-allowed' : 'pointer'
                    }}
                    disabled={(props.editMode && !isTouched)} 
                    onClick={() => validateFields()}
                    >
             
                        {
                            adminRole.includes(userRole) ? "Save Changes" : 
                            kybStep >= 6 ? "Save Changes" : 
                            "Submit and next"
                        }
            </button>

            <button className={kyb.uploadingBtn} 
                    hidden={hideSpin}>

                <div style={{paddingLeft: '10px', paddingRight: '10px'}}>
                    <UploadProgressBar value={uploadProgress}/>
                </div>
            </button>
        </div>
        </div>
    )
}

export default BusinessDocument