import React from 'react';
import { Button } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';

const GroupButton = (props) => {
    const { status,rootStyle, segments, handleClick, selected } = props;
    
    const active = selected ? 'selected' : 'unselect'; 

    // const handleClick = (event) => {
    //     setSelected(event.target.value);
    // }

    return (
        <div style={{...rootStyle}}>
            <ButtonGroup>
                {
                    status.map((btn, i) => {
                        return (
                            <Button
                                key={btn}
                                value={btn}
                                onClick={handleClick}
                                className={btn === selected ? `bg-${segments}` : `'`}
                                style={{
                                    ...styles.btnGroup,
                                    color: btn === selected ? '#FFF' : '#2B2D33',
                                    backgroundColor: '#FFF',
                                    borderRadius: i === 0 ? '4px 0 0 4px' : status && i === status.length - 1 ? '0 4px 4px 0' : 0
                                }}
                            >
                                {btn}
                            </Button>
                        )
                    })
                }
            </ButtonGroup>
        </div>
    )
}

const styles = {
    btnGroup: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: '0 16px',
    },
}

export default GroupButton;