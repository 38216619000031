import React, { useState } from "react";
import { useRevenues } from "../api/get-revenues";
import { Table, Pagination, Tooltip, Typography } from "antd";
import { UserDataCell } from "../../../../../Transfer/components";

export function RevenuesList({ walletId, searchQuery, startDate, endDate }) {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const { data, isLoading, error } = useRevenues(
    currentPage,
    walletId,
    searchQuery,
    startDate,
    endDate,
  );

  return (
    <div>
      {error ? (
        <div style={{ padding: "16px", color: "red" }}>
          Error fetching data. Please try again by refreshing the page.
        </div>
      ) : (
        <>
          <Table
            rowClassName="table-row-light"
            dataSource={data ? data.results : []}
            columns={[
              {
                title: "Reference ID",
                dataIndex: "id",
                key: "id",
                fixed: "left",
              },
              {
                title: "Merchant",
                dataIndex: "business_name",
                key: "business_name",
              },
              {
                title: "Requestor",
                dataIndex: "source_name",
                key: "source_name",
                render: (_, record) => (
                  <UserDataCell
                    name={record.source_name}
                    email={record.source_email}
                  />
                ),
              },
              {
                title: "Account Name",
                dataIndex: "account_name",
                key: "account_name",
                render: (_, record) => (
                  <UserDataCell
                    name={record.account_name}
                    email={record.account_number}
                  />
                ),
              },
              {
                title: "Bank Rail",
                dataIndex: "rail_type",
                key: "rail_type",
              },
              {
                title: "Bank",
                dataIndex: "bank",
                key: "bank",
              },
              {
                title: "Amount Transferred",
                dataIndex: "amount",
                key: "amount",
              },
              { title: "Fees", dataIndex: "fee", key: "fee" },
              {
                title: "Date Generated",
                dataIndex: "created_at",
                key: "created_at",
              },
              {
                title: "Remarks",
                dataIndex: "remarks",
                key: "remarks",
                render: (remarks) => (
                  <Tooltip title={remarks}>
                    <Typography
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: 232,
                      }}
                    >
                      {remarks}
                    </Typography>
                  </Tooltip>
                ),
              },
            ]}
            pagination={false}
            loading={isLoading}
            scroll={{ x: true }}
          />
          <Pagination
            size="small"
            total={(data && data.total) || 0}
            defaultPageSize={pageSize}
            pageSize={pageSize}
            current={currentPage}
            onChange={setCurrentPage}
            style={{ paddingTop: "12px", textAlign: "right" }}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
          />
        </>
      )}
    </div>
  );
}
