import React from 'react'
import { Badge, Button, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { history } from '../../store/history';
import { isMobile } from 'react-device-detect';
import Transactions from './transactions';
import BatchUpload from '../../components/orders/batch_upload/batchUpload';
import CreditDebit from './credit_debit/credit_debit';
import MetaTag from '../../components/meta_tag/meta_tag';
import MobileOrders from '../../components/Mobile/orders/mobile_orders';
import PaymentModal from './transactions/payment_modal';
import { BulbIcon } from '../../static/new_segment/tutorials/pages_tutorials';
import PaymentRequestTutorial from './tutorial';


const PaymentRequest = () => {

    const segmentsColor = {
        'me_':{
            color: '#0DAED0',
            border: '4px solid #0DAED0'
        },
        'gig':{
            color: '#41BD5C',
            border: '4px solid #41BD5C'
        },
        'sme':{
            color: '#E3632A',
            border: '4px solid #E3632A'
        },
        'biz':{
            color: '#0051B0',
            border: '4px solid #0051B0'
        }
    }

    //Redux
    const endpoints = useSelector(state => state.child_endpoints.endpoints);
    
    //Local Storage datas
    const userType = localStorage.getItem("userType")
    const subuserRole = localStorage.getItem("subuser_role")
    const kybStep = localStorage.getItem("KYBStep")
    const kybRequired = localStorage.getItem("kybRequired") == 'true'
    const level = localStorage.getItem("Level")
    const jwtToken = localStorage.getItem("jwtToken")
    const isChild = localStorage.getItem("is_child") === "true"
 
    const ubp_enabled = localStorage.getItem('cc_enabled') == 'UnionBank';
    const xendit_enabled = localStorage.getItem('cc_enabled') == 'Xendit';
    const ccEnabled = ubp_enabled || xendit_enabled;
    const kybApproved = (userType == "CO" && kybStep >= 11) || 
                        (userType == 'SW' && !kybRequired) ||
                        (userType == 'SW' && level > 0)

    const segments = localStorage.getItem("segments")
    const testMode = (window.location.pathname.indexOf('/test') > -1 && (userType == "CO" || (userType == "SW" && subuserRole == "CO")));
    const [modalState, setModalState] = React.useState(false)
    //State
    const [selectedTab, setSelectedTab] = React.useState('All Transactions') 

    const [infoActiveHover,setInfoActiveHover] = React.useState(false);
    const [showTutorial, setShowTutorial] = React.useState(false);

    const ButtonTab = () => {

        const ButtonGroup = Button.Group
        const status = ['All Transactions', 'Credit / Debit Card', 'Batch Upload'];
        
        const handleClick = (event) => {
            setSelectedTab(event.target.value);
        }
        

        return (
          <div style={style.btnTabContainer}>
            <ButtonGroup>
              {
                status.map((btn, i) => {
                  return (
                    <Badge 
                        key={i} 
                        hidden={
                            (userType == 'SW' && i === 1) ||
                            (!ccEnabled && i === 1) || 
                            (testMode && i === 1) || 
                            (!kybApproved && i === 2 && !testMode)
                        } 
                        count={btn === 'Batch Upload' ? null : null} 
                        style={style.badge}>
                        <Button
                            key={btn}
                            value={btn}
                            onClick={handleClick}
                            style={{
                                ...style.btnGroup, 
                                color: selectedTab === btn ? segmentsColor[segments].color : '#909196',
                                borderBottom: selectedTab === btn ? segmentsColor[segments].border : '0' 
                            }}
                            >
                            {btn}
                        </Button>
                    </Badge>
                  )
                })
              }
            </ButtonGroup>
          </div>
        )
    }

    const redirect = () => {
        let current_path = window.location.pathname

        if(jwtToken == "" || jwtToken == null){
            history.push('/login?next=' + current_path)
        } 
        else if(['PS', 'PF'].includes(userType)){
          history.push('/submissions_kyb')
        } 
        else{
            if (!isChild || !endpoints.length) {
                return;
            }
            // Redirect if URL was manually typed
            if(!endpoints.includes('orders')) {
                history.push(endpoints[0]);
            }
        }
    }

    React.useEffect(() => {
        redirect();
    },[])

    // If endpoints have not been loaded yet. Hook on endpoints will handle redirect
    if (isChild && !endpoints.includes('orders')) {
        return null;
    }

    const isClient = ['ME', 'CO', 'SW']
    
    return(
        <div>
            {
                isMobile ? <MobileOrders/> :
                isClient.includes(userType) && !isMobile ?
                <div style={{marginLeft: '-30px'}}>
                    <MetaTag title="Payment Request" />
                    <div style={style.titleContainer}>
                        <div style={{display: 'inline-flex', alignItems: 'center', gap: '16px'}}>
                            <Typography style={style.title}>Payment Request</Typography>
                            <Button style={{...style.infoBtn}} shape={!infoActiveHover && "circle"}
                                onMouseOver={() => setInfoActiveHover(true)} onMouseLeave={() => setInfoActiveHover(false)}
                                onClick={() => setShowTutorial(true)}>
                                <img src={BulbIcon} alt="Info" />
                                {infoActiveHover && "What is this?"}
                            </Button>
                        </div>
                        <ButtonTab />
                    </div>
                    {
                        selectedTab === 'All Transactions' ? <Transactions /> : 
                        selectedTab === 'Credit / Debit Card' ? <CreditDebit /> : 
                        <BatchUpload />
                    }
                </div>
                : 
                <div style={{paddingTop: '5%'}}>
                    <div className="padding-left-24 btn-height">
                        <button className={`btn--${segments}`} onClick={() => setModalState(true)}>
                            Check Payment Status
                        </button>
                    </div>
                    <PaymentModal modalState={modalState} setModalState={setModalState} />
                    <Transactions />
                </div>
            }
            <PaymentRequestTutorial
                visible={showTutorial}
                onClose={() => setShowTutorial(false)}
                userType={userType}
            />
        </div>
        
    )
    
}

const style = {
    title: {
        width: '100%',
        color: '#000',
        fontWeight: 'bold',
        fontSize: 28,
        display: 'flex',
        alignItems: 'center',
    },
    btnGroup: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: '20px 32px 24px 32px',
        borderColor: 'transparent',
        backgroundColor: 'transparent'
    },
    btnTabContainer: {
        padding: '20px 0px 0px 0px',
    },
    titleContainer: {
        marginLeft: '-100px',
        padding: '5% 0px 0px 130px',
    },
    badge:{
        fontSize: 8, 
        margin: '12px 19px', 
        backgroundColor: '#D1D5DD', 
        color: '#2B2D33', 
        fontWeight: 'bold', 
        padding: '0 8px', 
        borderRadius: 4 
    },
    infoBtn: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
        height: '32px',
        fontSize: '12px',
        fontWeight: '400',
        textDecoration: 'underline',
        gap: '12px',
        color: '#E68932',
        backgroundColor: '#FFF',
        borderRadius: '50px'
    }
}

export default PaymentRequest   