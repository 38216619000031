import React,{useEffect} from 'react'
import {Card, Select, Button} from 'antd'
import * as Style from './kyc_style'
import {isMobile} from 'react-device-detect'
import moment from 'moment'
import {ADDRESS, NATIONALITY} from '../../constants/constants'
import {setStep, submitDetails} from './application_main'

const sorted_nationality = NATIONALITY.sort()
const current_year = new Date().getFullYear()

const PersonalInfo = props => {

    useEffect(()=>{
        console.log(props.details)
    },[])

    const segments = localStorage.getItem("segments")
    const color = segments == 'me_' ? '#0DAED0' : segments == 'gig' ? '#41BD5C' : '#F5922F'

    const [selected, setSelected] = React.useState("")

    const [firstName, setFirstName] = React.useState(props.details.first_name)
    const [middleName, setMiddleName] = React.useState(props.details.middle_name)
    const [lastName, setLastName] = React.useState(props.details.last_name)
    const [mothersFirstName, setMothersFirstName] = React.useState(props.details.mothers_first_name)
    const [mothersLastName, setMothersLastName] = React.useState(props.details.mothers_last_name)

    const [birthDateM, setBirthDateM] = React.useState(props.details.birthday != "" ? props.details.birthday.split("-")[1] : "")
    const [birthDateD, setBirthDateD] = React.useState(props.details.birthday != "" ? props.details.birthday.split("-")[2] : "")
    const [birthDateY, setBirthDateY] = React.useState(props.details.birthday != "" ? props.details.birthday.split("-")[0] : "")
    const [birthPlace, setBirthPlace] = React.useState(props.details.birthplace)

    //present address
    const [presentStreet, setPresentStreet] = React.useState(props.details.present_address.street)
    const [presentBarangay, setPresentBarangay] = React.useState(props.details.present_address.barangay)
    const [presentCity, setPresentCity] = React.useState(props.details.present_address.city)
    const [presentProvince, setPresentProvince] = React.useState(props.details.present_address.province)
    const [presentPostCode, setPresentPostCode] = React.useState(props.details.present_address.postalCode)

    const [nationality, setNationality] = React.useState("Philippines")
    const [civilStatus, setCivilStatus] = React.useState(props.details.civil_status)
    const [gender, setGender] = React.useState(props.details.gender)

    const [loading, setLoading] = React.useState(false)

    const [hover, setHover] = React.useState(false)
    const [currentField, setCurrentField] = React.useState(0)

    //error
    const [errorAlert, setErrorAlert] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("")

    const Genders = {
        '': 'Select gender',
        'M': 'Male',
        'F': 'Female'
    }

    const CivilStatus = {
        '': 'Select civil status',
        'S': 'Single', 
        'M': 'Married',
        'W': 'Widowed', 
        'D': 'Divorced'
    }

    function hanldeBirthMonth(e){
        setBirthDateM(e.target.value)
        setBirthDateD("01")
    }
    function handlePresentProvince(e) {
        setPresentProvince(e.target.value)
        setPresentCity("")
        setPresentBarangay("")
    }
    function handlePresentCity(e) {
        setPresentCity(e.target.value)
        setPresentBarangay("")
    }
    function handlePresentBarangay(e) {
        setPresentBarangay(e.target.value)
    }
    function getProvinces() {
        let provinces = []
        for(var i in ADDRESS){
            provinces.push(<option key={i} value={i}>{i}</option>);
        }
        return provinces
    }
    function getCities(type) {
        let cities = []
        if(type in ADDRESS){
            for(var i in ADDRESS[type]['Municipality_list']){
                cities.push(<option key={i} value={i}>{i}</option>);
            }
        }
        return cities
    }
    function getBarangay(typeCity,typeProvince) {
        let baranggays = []
        if(typeProvince in ADDRESS){
            if(typeCity in ADDRESS[typeProvince]['Municipality_list']){
                let baranggay_list = ADDRESS[typeProvince]['Municipality_list'][typeCity]['Barangay_list'];
                for(var i=0;i<baranggay_list.length;i++){
                    baranggays.push(<option key={i} value={baranggay_list[i]}>{baranggay_list[i]}</option>);
                }
            }
        }
        return baranggays
    }
    function getMonths(){
        
        let months = []
        for(let i=1; i <= 12; i++){
            let j = i.toString()
            if(i < 10){
                j = "0" + i.toString()
            }
            months.push(<option key={i} value={j}>{moment().month(j-1).format("MMMM")}</option>)
        }
        return months
    }
    function getDays(){
        let days = []
        let months_with_31 = ["01","03","05","07","08","10","12"]

        if(months_with_31.includes(birthDateM)){
            for(let i=1; i <= 31; i++){
                let j = i.toString()
                if(i < 10){
                    j = "0" + i.toString()
                }
                days.push(<option key={i} value={j}>{j}</option>)
            }
        }
        else if(birthDateM == "02"){
            for(let i=1; i <= 29; i++){
                let j = i.toString()
                if(i < 10){
                    j = "0" + i.toString()
                }
                days.push(<option key={i} value={j}>{j}</option>)
            }
        }
        else{
            for(let i=1; i <= 30; i++){
                let j = i.toString()
                if(i < 10){
                    j = "0" + i.toString()
                }
                days.push(<option key={i} value={j}>{j}</option>)
            }
        }
       
        return days
    }
    function getYears(){
        let years = []
        for(let i=1930; i <= current_year-18; i++){
            years.push(<option key={i} value={i.toString()}>{i.toString()}</option>)
        }
        return years
    }
    const genders = () =>{
        return(
            Object.keys(Genders).map((key, i) => (
                <option key={i} value={key}>{Genders[key]}</option>
            ))
        )
    }
    const civilStatuses = () =>{
        return(
            Object.keys(CivilStatus).map((key, i) => (
                <option key={i} value={key}>{CivilStatus[key]}</option>
            ))
        )
    }
    const nationalities = () => {
        return(
            sorted_nationality.map((item,i)=>{
                return(
                    <option key={i} value={item}>{item}</option>
                )
            })
        )
    }
    async function checkDetails(){
        setLoading(true)
        let details = {
            first_name: firstName,
            middle_name: middleName ? middleName : "",
            last_name:lastName,
            birthday: birthDateY+"-"+birthDateM+"-"+birthDateD, 
            birthplace: birthPlace,
            present_street: presentStreet,
            present_barangay: presentBarangay,
            present_city: presentCity,
            present_province: presentProvince,
            present_postcode: presentPostCode,
            nationality: nationality,
            civil_status: civilStatus,
            mothers_first_name: mothersFirstName,
            mothers_last_name: mothersLastName,
            gender: gender,
            step: 0
        }

        if(props.testMode) {
            props.fakeKycDetails(1)
            window.scrollTo(0, 0);
            setLoading(false)
        }

        else{
            let response = await submitDetails(details)
            if(response == 'success'){
                setLoading(false)
                localStorage.setItem("firstName", firstName)
                localStorage.setItem("lastName", lastName)
            }
            else{
                setLoading(false)
                setErrorAlert(true)
                setErrorMessage(response)
            }
        }

 
        
    }
    const customizeInput = (index, code, inputValue, inputText, setValue, dataTest) =>{
        return(
            <input
                data-test={dataTest}
                autoComplete="new-password"
                style={{...Style.inputStyle, 
                    border: selected === code ? `1px solid ${color}` : inputValue == "" && currentField > index && index != 2? '1px solid #E24C4C' : '1px solid #D1D5DD',
                    padding: '10px'}} 
                placeholder={inputText}
                value={inputValue}
                onChange={(e)=>setValue(e.target.value.replace(/[^\w\s]/gi, ''))}
                onFocus={()=>{setCurrentField(index); setSelected(code)}}
                onMouseLeave={()=>setSelected("")}/>
        )
    }

    const customizeSelect = (index, code, selectValue, setValue, option, selectText, dataTest) => {
        return(
            <select
                key={index}
                data-test={dataTest}
                onFocus={()=>{setSelected(code); setCurrentField(index)}}
                onChange={(e)=>{if(index == 14 || index == 15 || index == 16 || index == 9){setValue(e)}else{setValue(e.target.value)}}}
                onMouseLeave={()=>setSelected("")}
                value={selectValue}
                style={{...Style.selectStyle, 
                    border: selected === code ? `1px solid ${color}` : selectValue == "" && currentField > index && (index > 8)? '1px solid #E24C4C' : '1px solid #D1D5DD',
                    padding: '10px 40px 10px 10px'
                    }}>
                    {
                        index > 8 || index == 6 ? <option value="">Select {selectText}</option> : ""
                    }
                    {option}
            </select>
        )
    }
    return(
        <div>
            <Card
                style={Style.formCardStyle}
                bodyStyle={{padding: isMobile? '12px 16px' : '32px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        {/* <div style={{...Style.normalText, color: '#000'}}>Step 1 of 5</div> */}
                        <div hidden={isMobile} style={{...Style.headerStyle, fontSize: isMobile ? '16px' : '20px'}}>Personal Information</div>
                    </div>
                    <div hidden={props.details.step != 6 && props.details.step != 9}>
                    {
                         isMobile ? 
                         <div style={{...Style.boldText, color: '#F5922F'}} onClick={()=>setStep(6)}>Cancel</div>
                         :
                         <button 
                            onClick={()=>setStep(6)}                            
                            className={`outline-btn--${segments} btn-height`}> Cancel</button>
                    }
                    </div>
                </div>
                <div
                    style={{
                        backgroundColor: 'rgba(226, 76, 76, 0.1)',
                        borderLeft: '4px solid #E24C4C',
                        width: '100%',
                        height: '100%',
                        borderRadius: '4px',
                        marginTop: '20px',
                        textAlign: 'center',
                        padding: '10px 30px',
                        display: errorAlert ? "block" : "none"
                    }}>
                    <span style={Style.normalText}>{errorMessage}</span>
                </div>
                <div style={{display: isMobile ? 'block' : 'flex', marginTop: '20px'}}>
                    <div style={{width: isMobile ? '100%' : '31%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>First Name</div>
                        {customizeInput(1,"fname",firstName,"First Name",setFirstName, "input-first-name")}
                        <div style={{display: firstName == "" && currentField > 1 ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                    <div style={{width: isMobile ? '100%' : '31%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Middle Name (Optional)</div>
                        {customizeInput(2,"mname",middleName,"Middle Name",setMiddleName, "input-middle-name")}

                    </div>
                    <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Last Name</div>
                        {customizeInput(3,"lname",lastName,"Last Name",setLastName, "input-last-name")}
                        <div style={{display: lastName == "" && currentField > 3 ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                </div>
                <div style={{display: isMobile ? 'block' : 'flex', marginTop: '20px'}}>
                    <div style={{width: isMobile ? '100%' : '31%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Gender</div>
                        {customizeSelect(4,"gender",gender,setGender,genders(),"Gender", "input-gender")}
                        <div style={{display: gender == "" && currentField > 4 ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                    <div style={{width: isMobile ? '100%' : '31%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Civil Status</div>
                        {customizeSelect(5,"civil_status",civilStatus,setCivilStatus,civilStatuses(), "", "input-civil-status")}
                        <div style={{display: civilStatus == "" && currentField > 5 ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                    <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Nationality</div>
                        {customizeSelect(6,"nationality",nationality,setNationality,nationalities())}
                    </div>
                </div>
                <div style={{margin: '30px 0px 10px 0px'}}>
                    <div style={{...Style.boldText, color: '#000'}}>
                        Mother's Maiden Name
                    </div>
                </div>
                    <div style={{display: isMobile ? 'block' : 'flex', marginTop: '20px'}}>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Mother's First Name</div>
                        {customizeInput(7,"mother_fname",mothersFirstName,"Mother's First Name",setMothersFirstName, "input-mother-fn")}

                        <div style={{display: mothersFirstName == "" && currentField > 7 ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Mother's Last Name</div>
                        {customizeInput(8,"mother_lname",mothersLastName,"Mother's Last Name",setMothersLastName, "input-mother-ln")}
                        <div style={{display: mothersLastName == "" && currentField > 8 ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                </div>
                <div style={{margin: '30px 0px 10px 0px'}}>
                    <div style={{...Style.boldText, color: '#000'}}>
                        Birthdate
                    </div>
                </div>
                <div style={{display: isMobile ? 'block' : 'flex', marginTop: '20px'}}>
                    <div style={{width: isMobile ? '100%' : '31%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Month</div>
                        {customizeSelect(9,"bday_month",birthDateM,hanldeBirthMonth,getMonths(),"Month", "input-month")}
                        <div style={{display: birthDateM == "" && currentField > 9 ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                    <div style={{width: isMobile ? '100%' : '31%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Day</div>
                        {customizeSelect(10,"bday_day",birthDateD,setBirthDateD,getDays(),"Day", "input-day")}
                        <div style={{display: birthDateD == "" && currentField > 10 ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                    <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Year</div>
                        {customizeSelect(11,"bday_year",birthDateY,setBirthDateY,getYears(),"Year", "input-year")}
                        <div style={{display: birthDateY == "" && currentField > 11 ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                </div>
                <div style={{display: isMobile ? 'block' : 'flex', marginTop: '20px'}}>
                    <div style={{width: '100%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Birthplace (ex. Quezon City)</div>
                        {customizeInput(12,"birthplace",birthPlace,"Birthplace",setBirthPlace, "input-birthplace")}
                        <div style={{display: birthPlace == "" && currentField > 12 ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                </div>
                <div style={{margin: '30px 0px 10px 0px'}}>
                    <div style={{...Style.boldText, color: '#000'}}>
                        Present Address
                    </div>
                </div>
                <div style={{display: isMobile ? 'block' : 'flex', marginTop: '20px'}}>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Street</div>
                        {customizeInput(13,"street",presentStreet,"Street",setPresentStreet, "input-address-street")}
                        <div style={{display: presentStreet == "" && currentField > 13 ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Province</div>
                        {customizeSelect(14,"province",presentProvince,handlePresentProvince,getProvinces(),"Province", "input-address-province")}
                        <div style={{display: presentProvince == "" && currentField > 14 ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                </div>
                <div style={{display: isMobile ? 'block' : 'flex', marginTop: '20px'}}>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>City</div>
                        {customizeSelect(15,"city",presentCity,handlePresentCity,getCities(presentProvince),"City", "input-address-city")}
                        <div style={{display: presentCity == "" && currentField > 15 ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Barangay</div>
                        {customizeSelect(16,"barangay",presentBarangay,handlePresentBarangay,getBarangay(presentCity,presentProvince),"Barangay", "input-address-brgy")}
                        <div style={{display: presentBarangay == "" && currentField > 16 ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                </div>
                <div style={{display: isMobile ? 'block' : 'flex', marginTop: '20px', marginBottom: '20px'}}>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Country</div>
                        <select
                            onFocus={()=>setSelected("country")}
                            onMouseLeave={()=>setSelected("")}
                            value="PH"
                            disabled={true}
                            style={{...Style.selectStyle, 
                                border: selected === "country" ? '1px solid #F5922F' : '1px solid #D1D5DD',
                                padding: '10px 40px 10px 10px', backgroundColor: '#f4f4f4'
                                }}>
                            <option>PH</option>
                        </select>
                    </div>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Zipcode (must be 4 digits)</div>
                        <input
                            data-test='input-address-postal'
                            autoComplete="new-password"
                            style={{...Style.inputStyle, 
                                border: selected === "zip" ? `1px solid ${color}` : '1px solid #D1D5DD',
                                padding: '10px'}} 
                            placeholder="Zipcode"
                            onChange={e => { if(e.target.value.length <= 4){setPresentPostCode(e.target.value)}}}
                            value={presentPostCode}
                            type="number"
                            onSelect={()=>{setSelected("zip");setCurrentField(17)}}
                            onMouseLeave={()=>setSelected("")}/>
                    </div>
                </div>

                <div align="right">
                    <Button
                        data-test='button-submit'
                        loading={loading}
                        style={{...Style.submitBtnStyle,
                        opacity: firstName == "" || lastName == "" || mothersFirstName == "" || mothersLastName == "" || birthDateM == "" || birthDateD == "" || birthDateY == "" || birthPlace == "" || presentStreet == "" || presentProvince == "" || presentCity == "" || presentBarangay == "" || presentPostCode == "" || gender == "" || presentPostCode.length < 4? 0.5 : 1.0
                    }} 
                        onClick={()=>checkDetails()} 
                        hidden={isMobile}
                        disabled={firstName == "" || lastName == "" || mothersFirstName == "" || mothersLastName == "" || birthDateM == "" || birthDateD == "" || birthDateY == "" || birthPlace == "" || presentStreet == "" || presentProvince == "" || presentCity == "" || presentBarangay == "" || presentPostCode == "" || gender == "" || presentPostCode.length < 4}
                    >
                        <div style={{...Style.boldText, display: 'inline-block', marginLeft: loading ? '20px' : '0px'}}>{props.details.step == 0 ? 'Submit and next' : 'Save'}</div>
                    </Button>
                </div>
            </Card>

            <div style={{
                width: '100%', 
                position: 'fixed', 
                backgroundColor:'#fff', 
                height: '74px', 
                bottom: '0', 
                marginLeft: '-16px',
                padding:'10px 20px 10px 20px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'

            }} hidden={!isMobile}>
                <Button
                    loading={loading}
                    style={{...Style.submitBtnStyle,
                        width: '100%',
                        opacity: firstName == "" || lastName == "" || mothersFirstName == "" || mothersLastName == "" || birthDateM == "" || birthDateD == "" || birthDateY == "" || birthPlace == "" || presentStreet == "" || presentProvince == "" || presentCity == "" || presentBarangay == "" || presentPostCode == "" ? 0.5 : 1.0
                    }}
                    onClick={()=>checkDetails()}
                    disabled={firstName == "" || lastName == "" || mothersFirstName == "" || mothersLastName == "" || birthDateM == "" || birthDateD == "" || birthDateY == "" || birthPlace == "" || presentStreet == "" || presentProvince == "" || presentCity == "" || presentBarangay == "" || presentPostCode == "" }
                >
                    <div style={{...Style.boldText, display: 'inline-block', marginLeft: loading ? '20px' : '0px'}}>{props.details.step == 0 ? 'Submit and next' : 'Save'}</div>
                </Button>
            </div>
        </div>
    )
}
export default PersonalInfo