import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Pagination, Table, Dropdown, Icon, Menu } from "antd";
import { useSubmissions } from "../api/get-submissions";
import { UserDataCell, StatusChip } from "../../../../../Transfer/components";

const statusMap = {
  AP: "Approved",
  PE: "Pending",
  RE: "Rejected",
  RS: "Resubmitted",
};

export function SubmissionsList({ selectedStatus, searchQuery }) {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  // Find the status code using the selectedStatus value from the statusMap
  // If none is found, selectedStatusKey will be undefined
  const selectedStatusKey = Object.keys(statusMap).find(
    (key) => statusMap[key] === selectedStatus,
  );
  const { data, isLoading, error } = useSubmissions(
    currentPage,
    searchQuery,
    selectedStatusKey,
  );

  return (
    <div>
      {error ? (
        <div style={{ padding: "16px", color: "red" }}>
          Error fetching data. Please try again by refreshing the page.
        </div>
      ) : (
        <>
          <Table
            rowClassName="table-row-light"
            dataSource={data ? data.results : []}
            columns={[
              {
                title: "Reference ID",
                dataIndex: "id",
                key: "id",
                fixed: "left",
              },
              {
                title: "Business Name",
                dataIndex: "business_name",
                key: "business_name",
              },
              {
                title: "Requestor",
                dataIndex: "requestor",
                key: "requestor",
                render: (_, record) => (
                  <UserDataCell
                    name={record.requestor}
                    email={record.requestor_email}
                  />
                ),
              },
              {
                title: "Account Number & Name",
                dataIndex: "account_name",
                key: "account_name",
                render: (_, record) => (
                  <UserDataCell
                    name={record.account_number}
                    email={record.account_name}
                  />
                ),
              },
              {
                title: "Bank",
                dataIndex: "bank",
                key: "bank",
              },
              {
                title: "Branch",
                dataIndex: "branch",
                key: "branch",
              },
              {
                title: "Account Type",
                dataIndex: "account_type",
                key: "account_type",
                render: (account_type) => (
                  <div>{account_type === "SA" ? "Savings" : "Checking"}</div>
                ),
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (text) => (
                  <StatusChip status={text} label={statusMap[text] || text} />
                ),
              },
              {
                title: "Approved By",
                dataIndex: "approved_by",
                key: "approved_by",
              },
              {
                title: "Action",
                key: "action",
                render: (_, record) => (
                  <Dropdown
                    overlay={<MenuItems record={record} />}
                    trigger={["click"]}
                    placement="bottomLeft"
                  >
                    <Icon type="ellipsis" />
                  </Dropdown>
                ),
              },
            ]}
            pagination={false}
            loading={isLoading}
            scroll={{ x: true }}
          />
          <Pagination
            size="small"
            total={(data && data.total) || 0}
            defaultPageSize={pageSize}
            current={currentPage}
            onChange={setCurrentPage}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            style={{ paddingTop: "12px", textAlign: "right" }}
          />
        </>
      )}
    </div>
  );
}

function MenuItems({ record }) {
  return (
    <Menu>
      <Menu.Item>
        <Link to={`/cashbux/submissions/${record.id}`}>View</Link>
      </Menu.Item>
    </Menu>
  );
}
