import React,{} from 'react';
import { Typography, Card } from 'antd';
import {
  BuxLaptopNews,
  ShoppingNews,
} from '../../../static/images/notif_promotion_page';
import { isMobile } from 'react-device-detect';

const News = () => {
  return (
    <div style={styles.root}>
      <Typography style={styles.title}>MORE NEWS FOR YOU</Typography>
      <Card
        hoverable
        cover={<img src={BuxLaptopNews} style={styles.cardImg} />}
        style={styles.card}
        bodyStyle={{padding: '22px 8px'}}
      >
        <Typography style={styles.cardDesc}>Embedded Banking Services are now live!</Typography>
      </Card>
      <Card
        hoverable
        cover={<img src={ShoppingNews} style={styles.cardImg} />}
        style={styles.card}
        bodyStyle={{padding: '22px 8px'}}
      >
        <Typography style={styles.cardDesc}>Shop online from the comfort of your home!</Typography>
      </Card>
    </div>
  )
}

const styles = {
  root: {
    width: isMobile? '100%' : 258,
  },
  title: {
    background: '#1D82B8',
    borderRadius: '10px 10px 0px 0px',
    color: '#fff',
    padding: '12px 0',
    fontWeight: 'bold',
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 15,
  },
  card: {
    borderRadius: '10px 10px 0px 0px',
    marginBottom: 15,
  },
  cardImg: {
    borderRadius: '10px 10px 0px 0px',
  },
  cardDesc: {
    fontSize: 12,
    color: '#000',
    fontWeight: '600',
    textAlign: isMobile? 'center' : 'left',
  }
}

export default News;