import React, { useState } from "react";
import MetaTag from "../../../../../../components/meta_tag/meta_tag";
import {
  Card,
  Typography,
  Button,
  Icon,
  Modal,
  Input,
  notification,
} from "antd";
import { DateRangeFilter, FilterBar, SearchInput } from "../../../components";
import RemittancesList from "./remittances-list";
import { getRemittances } from "../api/get-remittances";

export function Remittances() {
  const [searchQuery, setSearchQuery] = useState("");
  const [tempSearchQuery, setTempSearchQuery] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");

  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleStartDateChange = (date) => setTempStartDate(date);
  const handleEndDateChange = (date) => setTempEndDate(date);
  const handleApplyFilters = () => {
    if ((!tempStartDate && tempEndDate) || (tempStartDate && !tempEndDate)) {
      notification.error({
        message: "Error",
        description: "Both start date and end date are required for filtering.",
        placement: "topRight",
      });
      return;
    }

    setSearchQuery(tempSearchQuery);
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
  };
  const clearFilters = () => {
    setSearchQuery("");
    setTempSearchQuery("");
    setStartDate("");
    setEndDate("");
    setTempStartDate("");
    setTempEndDate("");
  };

  const handleCancel = () => setIsOpen(false);
  const handleOk = () => {
    if (!email) return;
    setIsLoading(true);
    getRemittances("", searchQuery, startDate, endDate, email, true)
      .then((_) => {
        setIsOpen(false);
        setEmail("");
        notification.success({
          message: "Success",
          description: "CSV sent successfully!",
        });
      })
      .catch((e) => {
        setIsOpen(false);
        setEmail("");
        notification.error({
          message: "Failed",
          description: "Something went wrong! Please try again.",
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div
      style={{
        margin: "0 2.5em 2.5em 2.5em",
        display: "flex",
        gap: "2em",
        flexDirection: "column",
      }}
    >
      <MetaTag title="CashBux Users" />
      <div>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "20px",
            color: "#2B2D33",
          }}
        >
          Remittances
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button size="large" onClick={() => setIsOpen(true)}>
          <Icon type="download" />
          Export CSV
        </Button>
      </div>
      <Card>
        <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
          <FilterBar
            searchQuery={searchQuery}
            startDate={startDate}
            endDate={endDate}
            clearFilters={clearFilters}
          />
          <div style={{ display: "flex", gap: "1em" }}>
            <DateRangeFilter
              tempStartDate={tempStartDate}
              tempEndDate={tempEndDate}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              handleApplyDateFilter={false}
            />
            <SearchInput
              tempSearchQuery={tempSearchQuery}
              setTempSearchQuery={setTempSearchQuery}
              handleApplySearch={false}
              placeholder="Search by Control Number, Receiver/Merchant Details"
            />
            <Button onClick={handleApplyFilters}>Apply</Button>
          </div>
          <RemittancesList
            searchQuery={searchQuery}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </Card>

      <Modal
        title="Export CSV"
        visible={isOpen}
        onOk={handleOk}
        confirmLoading={isLoading}
        onCancel={handleCancel}
        width={360}
        centered
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
          <Typography>
            Please enter your email address to send CSV reports directly to your
            email.
          </Typography>
          <div>
            <label style={{ fontSize: 12 }}>Send to email</label>
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
