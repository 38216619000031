import { Button, Card } from "antd";
import React from "react";
import BuxBlue from "../../../../../static/icons/bux_blue.svg";

export function TransferPanel({ balance, handleDrawerOpen }) {
  return (
    <Card>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="wallet-balance-container">
          <div>
            <img src={BuxBlue} alt="BUx Icon" />
          </div>
          <div>
            <p className="wallet-balance-title">Account Balance</p>
            <p className="wallet-balance-amount">{balance}</p>
          </div>
        </div>

        <Button
          onClick={handleDrawerOpen}
          size="large"
          htmlType="button"
          style={{
            backgroundColor: "rgb(0, 81, 176)",
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          Transfer
        </Button>
      </div>
    </Card>
  );
}
