import React from 'react'
import axios from 'axios'
import FeeStyles from '../../style'
import HeaderCard from './headerCard'
import EndorsementCard from './endorsementCard'
import ApprovedCard from './approvedCard'
import {Spin, Input, message} from 'antd'
import CustomInput from './customInput'
import {Credentials, Fees} from '../../../../../static/icons/index'

const { TextArea } = Input;

const Partners = props => {
    const pUid = props.props.match.params.id;

    const fee = FeeStyles();
    const userType = localStorage.getItem("userType");
    const [partners, setPartners] = React.useState([]);
    const [approved, setApproved] = React.useState(0)
    const [eremarks, setERemarks] = React.useState("")
    const [remarks, setRemarks] = React.useState("")
    const [MID, setMID] = React.useState("")
    const [MCC, setMCC] = React.useState("")
    const [pubKey, setPubKey] = React.useState("")
    const [secretKey, setSecretKey] = React.useState("")
    const [channelMin, setChannelMin] = React.useState("")
    const [channelMax, setChannelMax] = React.useState("")
    const [buxFee, setBuxFee] = React.useState("")
    const [buxPercent, setBuxPercent] = React.useState("")
    const [channelFee, setChannelFee] = React.useState("")
    const [channelPercent, setChannelPercent] = React.useState("")

    const approval_status = ["Pending", "Approved", "Rejected"]

    async function getPartners(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try {

            let response = await axios.get(
                process.env.REACT_APP_API_URL + `/api/kyb/${pUid}/partners/`,yourConfig)

            if(userType == "PS" || userType == "PF"){
                setERemarks(response.data.remarks)
                setApproved(response.data.approved)
                if(response.data.user_data.secret_key){
                    setSecretKey(response.data.user_data.secret_key)
                    setPubKey(response.data.user_data.public_key)
                }
                if(response.data.user_data.mid){
                    setMID(response.data.user_data.mid)
                }
                if(response.data.user_data.mcc){
                    setMCC(response.data.user_data.mcc)
                }
                if(response.data.fees){
                    // setChannelMin(response.data.fees.minimum)
                    // setChannelMax(response.data.fees.maximum)
                    setChannelFee(response.data.fees.channel_fee)
                    setChannelPercent(response.data.fees.channel_percent)
                    setBuxFee(response.data.fees.bux_fee)
                    setBuxPercent(response.data.fees.bux_percent)
                }
            }else{

                setPartners(response.data.partners)
            }
        }

        catch (error) {
            console.log(error)
        }

    }

    async function pushApproval(approval){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = {'approved': approval, 'remarks': remarks}

        try {

            let response = await axios.post(
            process.env.REACT_APP_API_URL + `/api/kyb/${pUid}/partners/`,params, yourConfig)
            if(response.data.status == 'success'){
                message.success('Application was '+approval_status[approval])
                getPartners();
            }else{
                message.error(response.data.message)
            }
        }

        catch (error) {
            console.log(error)
        }

    }

    async function pushCreds(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = {'mid': MID, 'mcc': MCC,
                      'public_key': pubKey, 'secret_key': secretKey}

        try {

            let response = await axios.post(
            process.env.REACT_APP_API_URL + `/api/kyb/${pUid}/partners/`,params, yourConfig)
            if(response.data.status == 'success'){
                message.success('Credentials Updated')
            }else{
                message.error(response.data.message)
            }
        }

        catch (error) {
            console.log(error)
        }

    }

    async function pushFees(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = {'bux_fee': buxFee,
                      'bux_percent': buxPercent,
                      'channel_fee': channelFee,
                      'channel_percent': channelPercent}

        try {

            let response = await axios.post(
            process.env.REACT_APP_API_URL + `/api/kyb/${pUid}/partners/`,params, yourConfig)
            if(response.data.status == 'success'){
                message.success('Fees Updated')
            }else{
                message.error(response.data.message)
            }
        }

        catch (error) {
            console.log(error)
        }

    }


    React.useEffect(() => {
        getPartners()
    },[])
    
    function reloadPage(){
        getPartners()
    }


    return(
        <div align='center'> 
            <div className={fee.feeCard} align="left">
                <HeaderCard />
                { (userType == "PS" || userType == "PF")?

                <>

                    <div className={fee.innerDiv}>
                        {/*<div className={fee.spaceBetween} style={{padding: 20, borderBottom: '1px solid', borderBottomColor: '#E6EAF0'}}>
                            <div>
                                <div><span className={fee.channelHeader}>Approval</span></div>
                            </div>
                        </div>*/}
                        <div style={{ padding: 20}}>

                            <div>
                                <strong>Status:</strong> {approval_status[approved]}<br/>
                                <strong>Remarks:</strong> {eremarks}
                            </div>
                            <div style={{ marginTop: 20}}>
                                <div className={fee.inputHeader}>Remarks</div>
                                <TextArea value={remarks} onChange={(e) => setRemarks(e.target.value)} style={{ marginTop: 10}}/>
                                <div align='right' style={{marginTop: 20}}>
                                    <button className={fee.rejectBtn} onClick={()=>  pushApproval(2)}>Reject</button>
                                    <button className={fee.approveBtn} onClick={() => pushApproval(1)}>Approve</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={fee.innerDiv} style={{ marginTop: -1 }}>
                        <div className={fee.spaceBetween} style={{padding: 20, borderBottom: '1px solid', borderBottomColor: '#E6EAF0'}}>
                            <div>
                                <div><img src={Credentials} /> <span className={fee.channelHeader}>Credentials</span></div>
                            </div>
                        </div>
                        <div style={{ padding: 20}}>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>MID</div>
                            <Input value={MID} onChange={(e) => setMID(e.target.value)} style={{ marginTop: 10}}/>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>MCC</div>
                            <Input value={MCC} onChange={(e) => setMCC(e.target.value)} style={{ marginTop: 10}}/>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>API Public key</div>
                            <Input value={pubKey} onChange={(e) => setPubKey(e.target.value)} style={{ marginTop: 10}}/>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>API Secret Key</div>
                            <Input value={secretKey} onChange={(e) => setSecretKey(e.target.value)} style={{ marginTop: 10}}/>
                            <div align='right' style={{marginTop: 20}}>
                                <button className={fee.approveBtn} onClick={() => pushCreds()}>Save</button>
                            </div>
                        </div>
                    </div>

                    <div className={fee.innerDiv} style={{ marginTop: -1 }}>
                        <div className={fee.spaceBetween} style={{padding: 20, borderBottom: '1px solid', borderBottomColor: '#E6EAF0'}}>
                            <div>
                                <div><img src={Fees} /> <span className={fee.channelHeader}>Fees</span></div>
                            </div>
                        </div>
                        <div style={{ padding: 20}}>

                            <table style={{width: '100%'}}>
                                <tr className={fee.tableHeader}>
                                    {/*<td className={fee.tableHeaderData}>
                                        Minimum Amount
                                    </td>
                                    <td className={fee.tableHeaderData}>
                                        Maximum Amount
                                    </td>*/}
                                    <td className={fee.tableHeaderData}>
                                        BUx Fee
                                    </td>
                                    <td className={fee.tableHeaderData}>
                                        BUx %
                                    </td>
                                    <td className={fee.tableHeaderData}>
                                        Xendit Fee
                                    </td>
                                    <td className={fee.tableHeaderData}>
                                        Xendit %
                                    </td>
                                </tr>
                                <tr>
                                    {/*<td className={fee.tableChannelText}>
                                        <CustomInput name={"minimum"} value={channelMin} placeholder={"Min Amount"} handleChangeVal={(e)=>setChannelMin(e.target.value)}/>
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <CustomInput name={"maximum"} value={channelMax} placeholder={"Max Amount"} handleChangeVal={(e)=>setChannelMax(e.target.value)}/>
                                    </td>*/}
                                    <td className={fee.tableChannelText}>
                                        <CustomInput name={"buxFee"} value={buxFee} placeholder={"BUx Fee"} handleChangeVal={(e)=>setBuxFee(e.target.value)}/>
                                    </td>
                                    <td className={fee.tableChannelText} align="center">
                                        <CustomInput value={buxPercent} name={"buxPercent"} placeholder={"BUx Percent"} handleChangeVal={(e)=>setBuxPercent(e.target.value)}/>
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <CustomInput value={channelFee} name={"channelFee"} placeholder={"Xendit Fee"} handleChangeVal={(e)=>setChannelFee(e.target.value)}/>
                                    </td>
                                    <td className={fee.tableChannelText} align="center">
                                        <CustomInput value={channelPercent} name={"channelPercent"} placeholder={"Xendit Percent"} handleChangeVal={(e)=>setChannelPercent(e.target.value)}/>
                                    </td>
                                </tr>
                            </table>
                            <div align='right' style={{marginTop: 20}}>
                                <button className={fee.approveBtn} onClick={() => pushFees()}>Save</button>
                            </div>
                        </div>
                    </div>

                </>
                :
                <>
                    <EndorsementCard partners={partners} pUid={pUid} reloadPage={reloadPage}/>
                    <ApprovedCard partners={partners} uid={pUid}/>
                </>
                }

            </div>
        </div>
    )
}

export default Partners