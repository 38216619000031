const initialState = {
  notifData: [],
  notifHeaderData: [],
  notificationCount: 0,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATION_DATA':
      return {
        ...state,
        notifData: action.payload 
      };
    case 'SET_HEADER_NOTIFICATION_DATA':
      return {
        ...state,
        notifHeaderData: action.payload 
      };
    case 'SET_NOTIFICATION_COUNT':
      return {
        ...state,
        notificationCount: action.payload,
      };
    default:
      return state;
  }
}