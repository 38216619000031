import React, {useEffect} from 'react';
import ButtonGroup from 'antd/lib/button/button-group';
import moment from 'moment';
import TransactionTable from './table';
import { Button, DatePicker, Icon, Input, Typography, Select, Row, Col, Checkbox, Popover, Modal, message} from 'antd';
import multiwalletServices from '../../../multiwalletServices';
import crypto from 'crypto';
import {OTC, BANK, MOBILE, BANK_LIST, CARD_PAYMENT, segmentStroke} from '../../../../constants/constants'
import ArrowDown from '../../../../../static/icons/arrow-down.svg'
import Pagination from '@material-ui/lab/Pagination';
import {history} from '../../../../../store/history';
import ClearFilter from '../../../../AdminComponents/filter_btns/clear_filter_btn';

let requestToken = ""

const TransactionHistory = (props) => {

    const { Option } = Select;
    const CheckboxGroup = Checkbox.Group
    const isMaster = localStorage.getItem("userType") == 'CO'

    const OTC_LIST = Object.keys(OTC)
    const WEB_BANK_LIST = Object.keys(BANK)
    const EWALLET_LIST = Object.keys(MOBILE)
    const CARD_LIST = Object.keys(CARD_PAYMENT)

    let index_shopwise = OTC_LIST.indexOf('Shopwise')
    OTC_LIST.splice(index_shopwise, 1)

    let index_wellcome = OTC_LIST.indexOf('Wellcome')
    OTC_LIST.splice(index_wellcome, 1)

    let index_rustan = OTC_LIST.indexOf('Rustan Supercenters, Inc')
    OTC_LIST.splice(index_rustan, 1)

    const [data, setData] = React.useState([])
    const [responseSummary, setResponseSummary] = React.useState(0)
    const [currentPage, setCurrentPage] = React.useState(1)
    const [pageSize, setPageSize] = React.useState(10)
    
    const [selected, setSelected] = React.useState('Paid')

    const [startDate, setStartDate] = React.useState("")
    const [startMoment, setStartMoment] = React.useState("")

    const [endDate, setEndDate] = React.useState("")
    const [endMoment, setEndMoment] = React.useState("")
    const [endOpen, setEndOpen] = React.useState(false)

    const [otcCheckedList, setOtcCheckList] = React.useState(OTC_LIST)
    const [bankCheckedList, setBankCheckList] = React.useState(WEB_BANK_LIST)
    const [walletCheckedList, setWalletCheckList] = React.useState(EWALLET_LIST)
    const [cardCheckedList, setCardCheckedList] = React.useState(CARD_LIST)

    const [checkAllOTC, setCheckAllOTC] = React.useState(true)
    const [checkAllBank, setCheckAllBank] = React.useState(true)
    const [checkAllWallet, setCheckAllWallet] = React.useState(true)
    const [checkAllCard, setCheckAllCard] = React.useState(true)

    // const [ccSelected, setCCSelected] = React.useState(isMaster ? true : false)

    const [searchVal, setSearchVal] = React.useState("");
    const [summary, setSummary] = React.useState("Today");
    const [isLoading, setIsLoading] = React.useState(false);
    const [isFiltered, setIsFiltered] = React.useState(false);

    const [exportModalVisibility, setExportModalVisibility] = React.useState(false)
    const [exportEmail, setExportEmail] = React.useState("")
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const segments = localStorage.getItem('segments');

    useEffect(()=>{
        fetchHistory(
            testMode,
            generateToken(),
            selected,
            currentPage,
            pageSize,
            startDate,
            endDate,
            summary,
            searchVal,
            getAllKeys()
        )
    },[])

    const fetchHistory = async(isTestMode, token, type, page, size, start, end, summarySelected, search, payment_methods, is_export="", email="") => {
        let response = await multiwalletServices.getAddMoneyHistory(
            isTestMode,
            token, 
            type, 
            page,
            size,
            start,
            end,
            summarySelected,
            search,
            payment_methods,
            is_export,
            email
        )

        console.log(response)
        if(response.status && response.status == 'success'){
            setExportModalVisibility(false)
            message.success("CSV reports successfully sent to your email address")
        }
        else if(response.token == requestToken && response.orders){
            setData(response.orders)
            setResponseSummary(response.summary)
        }
    } 

    const disabledStartDate = startValue => {
        if (!startValue || !endMoment) {
          return false;
        }
        return startValue && startValue > moment().endOf('day');
    }
    const disabledEndDate = endValue => {
        if (!endValue || !startMoment) {
            return false;
        }
        return endValue.valueOf() <= startMoment.valueOf();
    };

    const onStartChange = value => {
        setStartDate(value.format("MM-DD-YYYY"))
        setStartMoment(value)
        setSummary("Custom")
    }

    const onEndChange = value => {
        setEndDate(value.format("MM-DD-YYYY"))
        setEndMoment(value)
        setSummary("Custom")
    }
    
    const handleStartOpenChange = open => {
        if (!open) {
          setEndOpen(true)
        }
    }
    
    const handleEndOpenChange = open =>{
        setEndOpen(open)
    }

    const generateToken = () => {
        let generatedRequestToken = crypto.randomBytes(16).toString('hex');
        requestToken = generatedRequestToken
        return generatedRequestToken
    }

    const ButtonTab = ({ rootStyle }) => {

        const status = ['Success', 'Pending', 'Expired', 'Cancelled'];

        const handleClick = (event) => {
            if(event.target.value == 'Success'){
                setSelected("Paid")
            }
            else{
                setSelected(event.target.value);
            }
            fetchHistory(
                testMode,
                generateToken(),
                event.target.value == 'Success' ? 'Paid' : event.target.value,
                currentPage,
                pageSize,
                startDate,
                endDate,
                summary,
                searchVal,
                getAllKeys()
            )

        }

        return (
            <div style={rootStyle}>
                <ButtonGroup>
                    {
                        status.map((btn, i) => {
                            return (
                                <Button
                                    key={btn}
                                    value={btn}
                                    onClick={handleClick}
                                    style={{
                                        ...styles.btnGroup,
                                        color: btn === selected || (selected == "Paid" && btn == "Success") ? '#FFF' : '#2B2D33',
                                        backgroundColor: btn === selected || (selected == "Paid" && btn == "Success") ? segmentStroke[0] : '#FFF',
                                        borderRadius: i === 0 ? '4px 0 0 4px' : i === 3 ? '0 4px 4px 0' : 0
                                    }}
                                >
                                    {btn}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </div>
        )
    }

    const disabledApplyButton = () => {
        return checkAllOTC && 
        checkAllBank && 
        checkAllWallet && 
        summary == 'Today' && 
        startDate == '' && 
        endDate == '' && 
        searchVal == ''
    }
    const onFilterApply = () => {
        fetchHistory(
            testMode,
            generateToken(),
            selected,
            1,
            pageSize,
            startDate,
            endDate,
            summary,
            searchVal,
            getAllKeys()
        )
        setCurrentPage(1)
        setIsFiltered(true)
    }

    const handleSearch = (e) => {
        if(checkAllOTC && checkAllBank && checkAllWallet && summary == 'Today' && e.target.value == ''){
            setIsFiltered(false)
        } 
        else {
            setIsFiltered(true)
        }
        setSearchVal(e.target.value);
    }

    const getAllKeys = () => {
        let all_otc = []
        let all_web = []
        let all_wallet = []
        let all_card = []
    
        for(let i=0; i < otcCheckedList.length; i++){
          let name = otcCheckedList[i]
          all_otc.push(OTC[name].code)
        }
        for(let i=0; i < bankCheckedList.length; i++){
          let name = bankCheckedList[i]
          all_web.push(BANK[name].code)
        }
        for(let i=0; i < walletCheckedList.length; i++){
          let name = walletCheckedList[i]
          all_wallet.push(MOBILE[name].code)
        }

        for(let i=0; i < cardCheckedList.length; i++){
            let name = cardCheckedList[i]
            all_card.push(CARD_PAYMENT[name].code)
        }

        if(all_otc.includes('BAYD')){
            all_otc.push('bayad_center')
        }
        if(all_otc.includes('ussc')){
            all_otc.push('bc_ussc')
        }
        if(all_otc.includes('RLNT')){
            all_otc.push('bc_rlnt')
        }
        if(all_otc.includes('711_direct')){
            all_otc.push('ecpay_711')
        }
        if(all_otc.includes('ECPY')){
            all_otc.push('ecpay')
        }
    
        let otc_w_web = all_otc.concat(all_web)
        let wallet_ch = otc_w_web.concat(all_wallet)
        let all_channels = wallet_ch.concat(all_card)
        
        // if(ccSelected){
        //     all_channels.push('visamc')
        // }
        if(checkAllOTC && checkAllBank && checkAllWallet && checkAllCard)
        {
          all_channels.push("All")
        }
        
        return all_channels
    }

    const setIsFilteredPaymentMethod = (via, list, origin, checked) => {
        if(via == 'change'){
            if(list == origin && summary == 'Today' && startDate == '' && endDate == '' && searchVal == ''){
                setIsFiltered(false)
            }
            else{
                setIsFiltered(true)
            }
        }
        else{
            if(!checked && summary == 'Today' && startDate == '' && endDate == '' && searchVal == ''){
                setIsFiltered(false)
            }
            else{
                setIsFiltered(true)
            }
        }
        
    }
    const onChangeOtc = list => {
        setOtcCheckList(list)
        setCheckAllOTC(list.length == OTC_LIST.length)
        setIsFilteredPaymentMethod('change', list.length, OTC_LIST.length, false)
       
    };
    
    const onChangeWeb = list => {
        setBankCheckList(list)
        setCheckAllBank(list.length == BANK_LIST.length)
        setIsFilteredPaymentMethod('change', list.length, BANK_LIST.length, false)

    }
    
    const onChangeWallet = list => {
        setWalletCheckList(list)
        setCheckAllWallet(list.length == EWALLET_LIST.length)    
        setIsFilteredPaymentMethod('change', list.length, EWALLET_LIST.length, false)
    }

    const onChangeCard = list => {
        setCardCheckedList(list)
        setCheckAllCard(list.length == CARD_LIST.length)
        setIsFilteredPaymentMethod('change', list.length, CARD_LIST.length, false)
    }
    
    const onCheckAllOtcChange = e => {
        setOtcCheckList(e.target.checked ? OTC_LIST : [])
        setCheckAllOTC(e.target.checked)
        setIsFilteredPaymentMethod('check', 0, OTC_LIST.length, e.target.checked)
    };
    
    const onCheckAllWebChange = e => {
        setBankCheckList(e.target.checked ? BANK_LIST : [])
        setCheckAllBank(e.target.checked)
        setIsFilteredPaymentMethod('check', 0, BANK_LIST.length, e.target.checked)

    }
    const onCheckAllWalletChange = e => {
        setWalletCheckList(e.target.checked ? EWALLET_LIST : [])
        setCheckAllWallet(e.target.checked)
        setIsFilteredPaymentMethod('check', 0, EWALLET_LIST.length, e.target.checked)
    }

    const onCheckAllCardChange = e => {
        setCardCheckedList(e.target.checked ? CARD_LIST : [])
        setCheckAllCard(e.target.checked)
        setIsFilteredPaymentMethod('check', 0, CARD_LIST.length, e.target.checked)

    }

    const activePaymentMethod = () => {
        return otcCheckedList != "" || 
        bankCheckedList != "" || 
        walletCheckedList != "" ||
        cardCheckedList != ""
    }

    const clearFilter = () => {
        setOtcCheckList(OTC_LIST)
        setBankCheckList(BANK_LIST)
        setWalletCheckList(EWALLET_LIST)
        setCardCheckedList(CARD_LIST)
        setCheckAllOTC(true)
        setCheckAllBank(true)
        setCheckAllWallet(true)
        setSummary("Today")
        setStartDate("")
        setStartMoment("")
        setEndDate("")
        setEndMoment("")
        setSearchVal("")
        setIsFiltered(false)

        fetchHistory(
            testMode,
            generateToken(),
            selected,
            1,
            pageSize
        )
    }    

    const changePage = (event,page) => {
        setCurrentPage(page)
        fetchHistory(
            testMode,
            generateToken(),
            selected,
            page,
            pageSize,
            startDate,
            endDate,
            summary,
            searchVal,
            getAllKeys()
        )
    }

    const exportTable = () => {
        fetchHistory(
            testMode,
            generateToken(),
            selected,
            currentPage,
            pageSize,
            startDate,
            endDate,
            summary,
            searchVal,
            getAllKeys(),
            "true",
            exportEmail
        )
    }
    
    const payment_methods = () => (
        <div
          style={{ width: '220px', backgroundColor: '#fff', padding: '10px', height: '200px', overflowY: 'scroll'}} 
        >
        <div style={{margin:'10px 0px 10px 0px', fontWeight: '600'}}>Over the Counter</div>
        <Row>
          <Col span={24}>
            <Checkbox 
              indeterminate={otcCheckedList.length && otcCheckedList.length < OTC_LIST.length} 
              checked={checkAllOTC}
              onChange={(e)=>onCheckAllOtcChange(e)} 
            >All OTC
            </Checkbox>
          </Col> 
          <Col>
            <CheckboxGroup 
              class="cb_payment_methods"
              style={{margin:'0px 0px 0px 24px'}} 
              options={OTC_LIST}
              value={otcCheckedList} 
              onChange={(e)=>onChangeOtc(e)} />
          </Col>
        </Row>
    
        <div style={{margin:'10px 0px 10px 0px', fontWeight: '600'}}>Web Banking</div>
        <Row>
          <Col span={24}>
            <Checkbox 
              indeterminate={bankCheckedList.length && bankCheckedList.length < WEB_BANK_LIST.length} 
              checked={checkAllBank} 
              onChange={(e)=>onCheckAllWebChange(e)} 
            >All Web
            </Checkbox>
          </Col> 
          <Col>
            <CheckboxGroup 
              class="cb_payment_methods"    
              style={{margin:'0px 0px 0px 24px'}} 
              options={WEB_BANK_LIST} 
              value={bankCheckedList} 
              onChange={(e)=>onChangeWeb(e)} />
          </Col>
        </Row>
    
        <div style={{margin:'10px 0px 10px 0px', fontWeight: '600'}}>E-Wallet</div>
        <Row>
          <Col span={24}>
            <Checkbox 
              indeterminate={walletCheckedList.length && walletCheckedList.length < EWALLET_LIST.length} 
              checked={checkAllWallet}
              onChange={(e)=>onCheckAllWalletChange(e)} 
            >All E-Wallet
            </Checkbox>
          </Col> 
          <Col>
            <CheckboxGroup
              class="cb_payment_methods"
              style={{margin:'0px 0px 0px 24px'}} 
              options={EWALLET_LIST} 
              value={walletCheckedList} 
              onChange={(e)=>onChangeWallet(e)} />
          </Col>
        </Row>
        <div style={{margin:'10px 0px 10px 0px', fontWeight: '600'}}>Card Payments</div>
        <Row>
            <Col span={24}>
                <Checkbox 
                    indeterminate={cardCheckedList.length && cardCheckedList.length < CARD_LIST.length} 
                    checked={checkAllCard}
                    onChange={(e)=>onCheckAllCardChange(e)} 
                >All Card Payments
                </Checkbox>
            </Col> 
            <Col>
                <CheckboxGroup 
                    class="cb_payment_methods"
                    style={{margin:'0px 0px 0px 24px'}} 
                    options={CARD_LIST} 
                    value={cardCheckedList} 
                    onChange={(e)=>onChangeCard(e)} />
            </Col>
            {/* <Col span={24} key={randomize}>
                <Checkbox onChange={e => setCCSelected(e.target.checked)} checked={ccSelected}>Visa / MasterCard</Checkbox>
            </Col> */}
        </Row>
        {/* <div style={{margin:'10px 0px 10px 0px', fontWeight: '600'}} hidden={!isMaster}>Card Payments</div>
        {
            isMaster && (
                <Row>
                    <Col span={24} >
                        <Checkbox onChange={e => setCCSelected(e.target.checked)} checked={ccSelected}>Visa / MasterCard</Checkbox>
                    </Col>
                </Row>
            )
        } */}
        
    </div> 
    )
    
    return (
        <div style={styles.root}>
            <div style={styles.btnContainer}>
                <ButtonTab />
            </div>
            <div style={styles.body} className="top-20">
                <div style={{borderBottom: '1px solid #B7B9C0'}} className="top-20 padding-bot-22" align='right'>
                    <button className={`outline-btn--${segments} btn-height`} onClick={()=>setExportModalVisibility(true)} >
                        <Icon type='download' className="right-4" />
                        Download CSV
                    </button>
                </div>

                <div style={styles.subContainer} className="top-16">
                    <ClearFilter hideFilterBtn={!isFiltered} clearFilter={clearFilter}/>
                    {/* <div style={styles.clearDiv}>
                        <Typography style={styles.filterText}>Filter</Typography>
                        <a style={styles.clearBtnDiv} hidden={!isFiltered} onClick={clearFilter}>
                            <Icon style={styles.clearIcon} type="close-circle" theme="filled" />
                            <span style={styles.clearBtn}>Clear Filter</span>
                        </a>
                    </div>
                    <Typography style={styles.reminder}><Icon type="exclamation-circle" theme="filled" /> Click <b>Apply</b> button to show results.</Typography> */}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '0 0 24px 0' }}>
                    <Popover content={payment_methods()} trigger="click" placement="bottomLeft">
                    <Button 
                        style={{
                        zIndex: 1, 
                        height: '40px', 
                        width: '172px',
                        paddingTop: '8px', 
                        marginRight: '12px',
                        display: 'flex',
                        justifyContent:'space-between',
                        color:  activePaymentMethod() ? '#0D3D76' : '#000',
                        border: activePaymentMethod() ? '1px solid #0D3D76' : '1px solid #c5cacf'
                        }}>
                        <div>Payment Method</div> 
                        <img 
                            alt="Icon"
                            src={ArrowDown} 
                            style={{
                            marginLeft: '5px', 
                            color: activePaymentMethod() ? '#1DD28B' : '#000'
                            }}/>
                    </Button>
                    </Popover>
                    <Select 
                    size="large"
                    value={summary}
                    onChange={
                        (e)=> {
                            setSummary(e); 
                            setCurrentPage(1); 
                            if(checkAllOTC && checkAllBank && checkAllWallet && e == 'Today' && searchVal == ''){
                                setIsFiltered(false)
                            } 
                            else {
                                setIsFiltered(true)
                            }
                        }}
                    style={{width: '120px', zIndex: 1, color: '#000'}}
                    >
                    <Option value="All">All Time</Option>
                    <Option value="Today">Today</Option>
                    <Option value="Week" >Last 7 days</Option>
                    <Option value="Month" >Last 30 days</Option>
                    <Option value="Custom" >Custom</Option>
                    </Select>   
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledStartDate}
                        // defaultValue={moment()}
                        value={startMoment}
                        size="large" picker="month"
                        onChange={onStartChange}
                        onOpenChange={handleStartOpenChange}
                        placeholder='Start date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <span style={{ paddingTop: 8 }}>-</span>
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledEndDate}
                        // defaultValue={moment()}
                        value={endMoment}
                        size="large"
                        picker="month"
                        onChange={onEndChange}
                        onOpenChange={handleEndOpenChange}
                        open={endOpen}
                        placeholder='End date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <Input
                        placeholder="Search"
                        style={{ width: '150px', height: '40px', borderRadius: '4px', padding: '0 8px 0 0px' }}
                        suffix={<Icon type="search" style={{ fontSize: '18px' }} />}
                        onChange={handleSearch}
                        value={searchVal}
                    />
                    <button disabled={disabledApplyButton()} 
                        className={`btn--${segments} btn-height`}
                        onClick={onFilterApply} >Apply</button>
                </div>
                <TransactionTable
                    data={data}
                    selected={selected}
                    pagination={{pagination: 'none', pageSize: 20}}
                    isLoading={isLoading}
                />
                {
                    data && data.length > 0 &&
                    <div style={{padding: '20px', display:'flex', justifyContent:'space-between'}}>
                        <div>
                            {(currentPage - 1) * pageSize  + 1} 
                            -
                            {(currentPage - 1) * pageSize  + responseSummary.page_total} out of {responseSummary.total_data}
                        </div>
                        <div style={{display: 'flex'}}>
                            <Select 
                            size="small" 
                            style={{width: '100px'}} 
                            value={pageSize}
                            onChange={(e)=>
                            {
                                setPageSize(e);
                                fetchHistory(
                                    testMode,
                                    generateToken(),
                                    selected,
                                    1,
                                    e,
                                    startDate,
                                    endDate,
                                    summary,
                                    searchVal,
                                    getAllKeys()
                                )
                            }
                            }>
                                <Option value={5}>Show 5</Option>
                                <Option value={10}>Show 10</Option>
                                <Option value={15}>Show 15</Option>
                                <Option value={20}>Show 20</Option>
                            </Select>
                            <Pagination 
                                count={responseSummary.pages} 
                                shape="rounded"
                                page={currentPage} 
                                size="small"
                                onChange={changePage}
                            />
                        </div>
                    </div>
                }
            </div>
            <Modal
                visible={exportModalVisibility}
                footer={null}
                width="360px"
                onCancel={()=>setExportModalVisibility(false)}
                closeIcon={<Icon type="close" className={`text-${segments}`} />}

            >
                <div style={{paddingTop: '20px'}}>
                <div style={{fontSize: '24px', color: '#2b2d33', marginBottom: '8px'}}>Export CSV</div>
                <div style={{fontSize: '16px', color: '#2b2d33', marginBottom: '16px'}}>
                    Please enter your email address to send CSV reports directly to your email.
                </div>
                <div style={{fontSize:'14px', color: 'rgba(43, 45, 50, 0.8)', marginBottom: '5px'}}>Send to email</div>
                <Input 
                    size="large" 
                    style={{width: '100%', marginBottom: '14px'}} 
                    placeholder="Email"
                    onChange={(e)=>setExportEmail(e.target.value)} />
                <button 
                    onClick={()=>exportTable()}
                    disabled={exportEmail == ''}
                    className={`btn--${segments} btn-height btn-mobile-width`}>Send
                </button>
                </div>
            </Modal>
        </div>
    )
}

const styles = {
    root: {
        width: '100%',
        padding: 40
        //   display: 'flex', 
        //   flexDirection: 'column', 
        // //   justifyContent: 'center', 
        //   alignItems: 'center'
    },
    body: {
        backgroundColor: 'transparent',
        padding: '0px 16px',
        backgroundColor: '#FFF',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        margin: '38px 0'
    },
    btnContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    exportBtn: {
        fontSize: 16,
        fontWeight: '600',
        padding: '8px, 16px, 8px, 16px',
        border: '1px solid #1DD28B',
        height: 40,
        marginRight: 12,
        color: '#FFF',
        backgroundColor: '#1DD28B'
    },
    btnGroup: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: '0 32px',
    },
    subContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 0'
    },
    clearDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    clearIcon: {
        color: '#2B2D33',
        fontSize: 10,
    },
    filterText: {
        color: '#000',
        fontSize: 14,
        fontWeight: 'bold'
    },
    clearBtnDiv: {
        backgroundColor: '#E6EAF0',
        borderRadius: 24,
        padding: '0px 12px 2px 12px',
        margin: '0 12px'
    },
    clearBtn: {
        color: '#000000',
        fontSize: 12,
        paddingLeft: 4,
    },
    reminder: {
        fontSize: 12,
        color: '#909196',
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 158,
        padding: '0 8px 0 8px'
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    applyBtn: {
        height: 40,
        width: 92,
        fontSize: 16,
        fontWeight: '600',
        borderRadius: 4,
        backgroundColor: '#F5922F',
        color: '#FFF',
    }
}

export default TransactionHistory;