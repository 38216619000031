import React from 'react'
import {Bux} from '../../static/icons/index'
import Ecomx from '../../static/images/ecomx.svg'
import RegFee from '../../static/images/reg_fee.svg'
import {Card, Button, Divider} from 'antd'
import {Grid} from '@material-ui/core'
import {history} from '../../store/history'
import * as Style from './learn_more_style'

class MobileLearnMore extends React.Component{

    event_sched = [
        {
            time: '8:00 AM - 8:15 AM',
            event: 'Welcome Remarks/Kick-Off'
        },
        {
            time: '8:15 AM - 10:00 AM',
            event: 'International Ecommerce (Dropshipping)'
        },
        {
            time: '10:00 AM - 12:00 PM',
            event: 'Local Ecommerce'
        },
        {
            time: '12:00 PM - 1:00 PM',
            event: 'Working Lunch / Q&A'
        },
        {
            time: '1:00 PM - 3:00 PM',
            event: 'International Print-on-Demand'
        },
        {
            time: '3:00 PM - 4:00 PM',
            event: 'Local Print-on-Demand'
        },
        {
            time: '4:00 PM - 4:45 PM',
            event: 'Q&A'
        },
        {
            time: '4:47 PM - 5:00 PM',
            event: 'Presentation by UnionBank GlobalLinker and Bux'
        },
        {
            time: '5:00 PM - ',
            event: 'Open Mic / Mastermind talks'
        },
    ]

    timeStyle = {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#54575f',
        textAlign: 'center',
        marginBottom: '10px'
    }

    eventStyle = {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#2B2D33',
        textAlign: 'center'
    }

    dontMissStyle = {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#2B2D33',
        textAlign: 'center'
    }

    render(){
        return(

            <div style={{paddingRight: '20px', paddingLeft: '20px'}}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img src={Bux} alt="#" style={Style.icon_style}/>
                </div>
                <Card
                    style={{ width: '100%'}}
                >
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                        <img src={Ecomx} alt="#" style={{ width: '100%'}}/>
                    </div>

                    <div style={Style.infoStyle}>
                        <span style={{marginRight: '5px'}}>Date:</span><span style={{fontWeight: '600'}}>December 10, 2019</span>
                    </div>
                    <div style={Style.infoStyle}>
                        <span style={{marginRight: '5px'}}>Venue:</span><span style={{fontWeight: '600'}}>47th UnionBank Plaza Bldg., Meralco Ave. Ortigas Center, Pasig City</span>
                    </div>
                    <div style={Style.byNickStyle}>By Nick Peroni</div>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                        <span style={Style.byNickStyle}>Powered by</span> <img src={Bux} />
                    </div>

                    <Divider></Divider>

                    <div>
                        <div>
                            <div>
                                <img src={RegFee} style={{width: '100%', marginBottom: '20px'}}/>
                            </div>
                        </div>
                        <div>
                            <div style={this.dontMissStyle}>
                                <span style={{fontWeight: '600'}}>Don’t miss the chance!</span>
                            </div>
                            <br></br>
                            <div style={this.dontMissStyle}>
                                <span style={{textAlign: 'center'}}>Limited seats only</span>
                            </div>
                            <div style={this.dontMissStyle}>
                                <span style={{textAlign: 'center'}}>First Come, First Served Basis</span>
                            </div>
                            <Button type="primary" size="large" block style={{marginTop: '20px'}} onClick={()=>history.push('/ecomx/registration')}> Sign up now!</Button>
                        </div>
                    </div>

                    <Divider></Divider>

                    <div style={this.dontMissStyle}>
                            <span style={{fontWeight: '600'}}>Event Schedule</span>
                    </div>
                    <br></br>
                    {this.event_sched.map((item, indx) => {
                            return (
                                <Card>
                                    <div lg={4} style={this.timeStyle}>{item.time}</div>
                                    <div lg={8} style={this.eventStyle}>{item.event}</div>
                                </Card>
                            );
                        })} 

                </Card>
            </div>
        )
    }
}

export default MobileLearnMore