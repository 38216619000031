import React from 'react'
import {Card, Button, Icon, message} from 'antd'
import SurveyBG from '../../static/img/survey_bg.svg'
import ArrowDown from '../../static/icons/arrow-down.svg'
import FacebookIcon from '../../static/icons/facebook_icon.svg'
import InstagramIcon from '../../static/icons/instagram_icon.svg'
import {isMobile} from 'react-device-detect'
import {NATURE_BUSINESS} from '../constants/constants'
import axios from 'axios'
import {history} from '../../store/history'

const SurveyFields = props =>{

    const [selected,setSelected] = React.useState("")

    const [accountType,setAccountType] = React.useState("ME")
    const [businessName,setBusinessName] = React.useState("")
    const [natureBusiness,setNatureBusiness] = React.useState("")
    const [mobileNumber,setMobileNumber] = React.useState(localStorage.getItem("contact") ? localStorage.getItem("contact") : "")
    const [fbPage,setFbPage] = React.useState("")
    const [igPage,setIgPage] = React.useState("")
    const [businessWeb,setBusinessWeb] = React.useState("")

    function handleNature(e){
        setNatureBusiness(e.target.value)
    }
    async function submit(){

        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let details = {
            business_name: businessName,
            nature_business: natureBusiness,
            mobile_number: mobileNumber,
            business_fb: fbPage,
            business_ig: igPage,
            business_web: businessWeb,
            account_type: accountType
        }

        if(mobileNumber.length != 10){
            message.error("Mobile number must contain 10 digits")
        }
        else if(!mobileNumber.startsWith("9")){
            message.error("Mobile number must starts with 9")
        }
        else {
            let response = await axios.post(process.env.REACT_APP_API_URL + '/api/update_business/', details, yourConfig)

            if(response.data.status == "success"){
                localStorage.setItem("lastLogin", 1)
                localStorage.setItem("contact", mobileNumber)
                history.push("/dashboard")
            }
        }
        
    }

    return(
        <div style={{display:'flex', justifyContent:'center'}}>
            <Card
                style={styles.cardStyle}
                bodyStyle={{
                    padding: isMobile? '43px 16px 32px 16px': '43px 58px 32px 58px'
                }}>
                <div style={{display:'flex', justifyContent:'center', marginBottom: '16px'}}>
                    <div style={styles.headerStyle}>
                        We want to learn more about your business
                    </div>
                </div>

                <div align="center" style={{padding: '0px 30px 0px 30px'}} hidden={isMobile}>
                    You are just a few steps away in using Bux. Complete the details below to get started now! 
                </div>

                <div style={{marginTop: '32px'}}>
                    <div style={{width: '100%', marginBottom: '20px'}}>
                        <div style={styles.labelStyle}>Acoount Type</div>
                        <div style={{display:'flex'}}>
                        <select
                            size="large"
                            value={accountType}
                            onFocus={()=>setSelected("account_type")}
                            onChange={(e)=>setAccountType(e.target.value)}
                            onMouseLeave={()=>setSelected("")}
                            style={{...styles.selectStyle, 
                                border: selected === "account_type" ? '1px solid #F5922F' : '1px solid #D1D5DD',
                                padding: '10px 40px 10px 10px'
                                }}>
                                <option value="ME">Individual</option>
                                <option value="CO">Corporate</option>
                        </select>
                        </div>
                    </div>
                    <div style={{marginBottom: '20px'}}>
                        <div style={styles.labelStyle}>Business Name</div>
                        <input 
                            style={{...styles.inputStyle, 
                                border: selected === "name" ? '1px solid #F5922F' : '1px solid #D1D5DD',
                                padding: '10px'}} 
                            placeholder="Business Name"
                            onChange={(e)=>setBusinessName(e.target.value)}
                            onSelect={()=>setSelected("name")}
                            onMouseLeave={()=>setSelected("")}/>
                    </div>
                    <div style={{display: isMobile ? '' : 'flex', marginBottom: '20px'}}>
                        <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginBottom: isMobile ? '20px' : '0px'}}>
                            <div style={styles.labelStyle}>Nature of Business</div>
                            <div style={{display:'flex'}}>
                            <select
                                size="large"
                                onFocus={()=>setSelected("nature")}
                                onChange={handleNature}
                                onMouseLeave={()=>setSelected("")}
                                style={{...styles.selectStyle, 
                                    border: selected === "nature" ? '1px solid #F5922F' : '1px solid #D1D5DD',
                                    padding: '10px 40px 10px 10px'
                                    }}>
                                    <option value="">Select</option>
                                {
                                    NATURE_BUSINESS.map((item,i)=>{
                                        return(
                                        <option key={i} value={item}>{item}</option>
                                        )
                                    })
                                    
                                }
                            </select>
                            </div>
                        </div>
                        <div style={{width: isMobile ? '100%' : '49%'}}>
                            <div style={styles.labelStyle}>Mobile Number</div>
                            <div style={{display:'flex'}}>
                                <div style={{position:'absolute', lineHeight: '50px', marginLeft: '10px'}}>+63 |</div>
                                <input 
                                    style={{...styles.inputStyle, 
                                        border: selected === "mobile" ? '1px solid #F5922F' : '1px solid #D1D5DD',
                                        padding: '10px 10px 10px 50px'}} 
                                    type="number"
                                    value={mobileNumber}
                                    onSelect={()=>setSelected("mobile")}
                                    onMouseLeave={()=>setSelected("")}
                                    onChange={e => { if(e.target.value.length <= 10){setMobileNumber(e.target.value)}}}
                                />
                            </div>
                            
                        </div>
                    </div>
                    <div style={styles.textStyle}>
                        Link Social Media
                    </div>
                    <div style={{marginBottom: '20px', marginTop: '20px'}}>
                        <div style={styles.labelStyle}>Facebook (Optional)</div>
                        <div style={{display:'flex'}}>
                            <div style={{position:'absolute', lineHeight: '50px', marginLeft: '10px'}}>
                                <img src={FacebookIcon} style={{marginRight:'10px'}}/>
                                <span hidden={isMobile} style={{marginTop:'5px'}}>www.facebook.com/</span>
                            </div>
                            <input 
                                style={{...styles.inputStyle, 
                                    border: selected === "facebook" ? '1px solid #F5922F' : '1px solid #D1D5DD',
                                    padding: isMobile ? '10px 10px 10px 30px' : '10px 10px 10px 175px'}} 
                                onChange={(e)=>setFbPage(e.target.value)}
                                placeholder="your-facebook-page"
                                onSelect={()=>setSelected("facebook")}
                                onMouseLeave={()=>setSelected("")}/>
                        </div>
                    </div>
                    <div style={{marginBottom: '20px', marginTop: '20px'}}>
                        <div style={styles.labelStyle}>Instagram (Optional)</div>
                        <div style={{display:'flex'}}>
                            <div style={{position:'absolute', lineHeight: '50px', marginLeft: '10px'}}>
                                <img src={InstagramIcon} style={{marginRight:'10px'}}/>
                                <span hidden={isMobile} style={{marginTop:'5px'}}>www.instagram.com/</span>
                            </div>
                            <input 
                                style={{...styles.inputStyle, 
                                    border: selected === "ig" ? '1px solid #F5922F' : '1px solid #D1D5DD',
                                    padding: isMobile ? '10px 10px 10px 30px' : '10px 10px 10px 178px'}} 
                                placeholder="your-instagram-handle"
                                onChange={(e)=>setIgPage(e.target.value)}
                                onSelect={()=>setSelected("ig")}
                                onMouseLeave={()=>setSelected("")}/>
                        </div>
                    </div>
                    <div style={{marginBottom: '20px'}}>
                        <div style={styles.labelStyle}>Business Website (Optional)</div>
                        <input 
                            style={{...styles.inputStyle, 
                                border: selected === "web" ? '1px solid #F5922F' : '1px solid #D1D5DD',
                                padding: '10px'}} 
                            placeholder="http://Businessname.com"
                            onChange={(e)=>setBusinessWeb(e.target.value)}
                            onSelect={()=>setSelected("web")}
                            onMouseLeave={()=>setSelected("")}/>
                    </div>
                    <Button 
                        style={ businessName == "" || natureBusiness == "" || mobileNumber == "" ? styles.disabledSubmitBtnStyle : styles.submitBtnStyle}
                        disabled ={ businessName == "" || natureBusiness == "" || mobileNumber == "" }
                        onClick={()=>submit()}
                    >
                        Save and Continue
                    </Button>
                    
                </div>
                
            </Card>
        </div>
    )
    
}
const styles = {
    cardStyle: {
        width: isMobile? '90%': '684px',
        marginTop:'100px',
        marginBottom:'50px',
        border: '1px solid #D1D5DD',
        borderRadius:'20px',
        backgroundSize: '100%',
        backgroundImage: `url(${SurveyBG})`,
        backgroundRepeat: 'no-repeat'
    },
    headerStyle: {
        width: isMobile ? '224px' : '444px',
        fontSize: isMobile ? '16px' : '32px',
        fontWeight: 'bold',
        lineHeight: isMobile ? '24px':'48px',
        textAlign: 'center',
        color: '#0D3D76'
    },
    subHeaderStyle: {
        fontSize: '16px',
        fontWeight: '300',
        lineHeight: '24px',
        color: '#2B2D33'
    },
    labelStyle: {
        fontSize: '12px',
        fontWeight: 'normal',
        lineHeight: '24px',
        color: '#212B36'
    },
    textStyle:{
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '32px',
        color: '#2b2d33'
    },
    inputStyle: {
        width: '100%',
        height: '50px',
        borderRadius: '4px',
        color: '#000'
    },
    selectStyle: {
        width: '100%',
        height: '50px',
        borderRadius: '4px',
        backgroundColor:'#fff',
        MozAppearance: 'none',
        WebkitAppearance: 'none',
        appearance: 'none',
        background: `url(${ArrowDown})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '95% 50%'
    },
    submitBtnStyle: {
        width:'100%',
        height: '48px',
        backgroundColor: '#F5922F',
        borderRadius:'10px',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '24px',
        color: '#fff'
    },
    disabledSubmitBtnStyle: {
        width:'100%',
        height: '48px',
        backgroundColor: '#F5922F',
        borderRadius:'10px',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '24px',
        color: '#fff',
        opacity: '0.5'
    }
}

export default SurveyFields