import React from 'react'
import { Modal, Radio, Input, Button, message } from 'antd'
import { TextField, Grid, makeStyles } from '@material-ui/core';
import recurringServices from '../recurringServices'
import axios from 'axios'
import { history } from '../../../store/history'

const DeleteScheduleModal = props => {

    const {
        fetchData,
        deleteData,
        deleteVisible,
        closeModal,
    } = props

    const testMode = (history.location.pathname.indexOf('/test') > -1 && localStorage.getItem("userType") == "CO")

    async function deleteSchedule(id) {

        let response = await recurringServices.deleteSchedule(id, testMode);
        console.log(response)
        if (response.status === 'success') {
            message.success("Payment Schedule successfully deleted.", 5)
            closeModal(true)
            fetchData()


        } else {
            message.error(response.message, 5)
            closeModal(true)
        } 
    }

    return (
        <Modal
            visible={deleteVisible}
            footer={null}
            onCancel={closeModal}
        >
            <div style={{ padding: '40px 30px 40px 30px' }}>
                <div style={Style.cancelOrder}>Delete Scheduled Payment</div>
                <div style={Style.choicesStyle}>
                    Are you sure you want to delete this scheduled payment?
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button type="danger" style={Style.cancelButtonStyle} onClick={() => deleteSchedule(deleteData.id)}>Delete</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button style={Style.dontCancelButton} onClick={closeModal}>Cancel</Button>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    )
}

const Style = {

    radioStyle: {
        display: 'block',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33',
        opacity: '0.8',
        marginBottom: '10px'
    },
    cancelOrder: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '34px',
        color: '#2b2d33',
        marginBottom: '10px'
    },
    choicesStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33',
        opacity: '0.8',
        marginBottom: '10px',
        paddingBottom: '10px'
    },
    cancelButtonStyle: {
        borderRadius: '4px',
        width: '100%',
        height: '40px',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#FFFFFF',
        marginBottom: '10px',
    },
    dontCancelButton: {
        borderRadius: '4px',
        width: '100%',
        height: '40px',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33',
        marginRight: '10%'
    }
}

export default DeleteScheduleModal