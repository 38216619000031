import { useState } from "react";
import { axios } from "../../../lib/axios";

export default function MerchantIDSettingsViewModel() {
    const [isSuccess, setIsSuccess] = useState(false);
    const [messageString, setMessageString] = useState("");
    const [displayMessage, setDisplayMessage] = useState(false);
    const [channelSubmerchantIDSettings, setChannelSubmerchantIDSettings] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const headers = {
        Authorization: "Token " + localStorage.getItem("jwtToken"),
    }
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    
    async function getChannelSubmerchantIDSettings(page, pageSize) {
        setIsLoading(true);
        try {
            const response = await axios.get(
                `${REACT_APP_API_URL}/api/admin/channel_submerchant_id_settings/?page=${page}&page_size=${pageSize}`,
                {
                    headers: headers,
                },
            );
            setChannelSubmerchantIDSettings(response);
            setIsSuccess(true);
        } catch (error) {
            setMessageString("Failed to get channel submerchant ID settings");
            setIsSuccess(false);
            setDisplayMessage(true);
        }
        setIsLoading(false);
    }

    async function createChannelSubmerchantIDSettings(params) {
        setIsLoading(true)
        try {
            let defaultMetadata = null;
            if (params.default_metadata) {
                defaultMetadata = JSON.parse(params.default_metadata);
            }

            await axios.post(
                `${REACT_APP_API_URL}/api/admin/channel_submerchant_id_settings/`, 
                {
                    ...params,
                    default_metadata: defaultMetadata,
                }, 
                {
                    headers: headers,
                },
            );
            setIsSuccess(true);
            setMessageString("Channel submerchant ID settings successfully created");
        } catch (error) {
            setIsSuccess(false);
            setMessageString("Failed to create channel submerchant ID settings");
        }
        setDisplayMessage(true);
        setIsLoading(false);
    }

    async function updateChannelSubmerchantIDSettings(params, channelSubmerchantIDSettingsID) {
        setIsLoading(true)
        try {
            let defaultMetadata = null;
            if (params.default_metadata) {
                defaultMetadata = JSON.parse(params.default_metadata);
            }

            await axios.patch(
                `${REACT_APP_API_URL}/api/admin/channel_submerchant_id_settings/${channelSubmerchantIDSettingsID}/`, 
                {
                    ...params,
                    default_metadata: defaultMetadata,
                },
                {
                    headers: headers,
                },
            );
            setIsSuccess(true);
            setMessageString("Channel submerchant ID settings successfully updated");
        } catch (error) {
            setIsSuccess(false);
            setMessageString("Failed to update channel submerchant ID settings");
        }
        setDisplayMessage(true);
        setIsLoading(false);
    }

    return {
        getChannelSubmerchantIDSettings,
        createChannelSubmerchantIDSettings,
        updateChannelSubmerchantIDSettings,
        setDisplayMessage,
        isSuccess,
        messageString,
        displayMessage,
        channelSubmerchantIDSettings,
        isLoading,
    }
}