import { Typography } from 'antd';
import React from 'react';
import { ProcessingImg } from '../../../static/images/test_mode';

const ProcessingCard = (props) => {
    return (
        <div style={styles.root}  hidden={props.hidden}>
            <img alt='No Image' src={ProcessingImg}style={{padding: '20px 0'}} />
            <Typography style={styles.textBlue}>Payment is still processing...</Typography>
            <Typography style={styles.normalText}>Page will automatically update once successful</Typography>
        </div>
    );
}

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 0 24px 0'
    },
    textBlue: {
        color: '#1F82B9',
        fontWeight: 'bold',
        fontSize: 16,
    },
    normalText: {
        color: '#000',
        fontSize: 12
    }
}

export default ProcessingCard;