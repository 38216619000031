import {
    GET_CONTENT_BEGIN,
    GET_CONTENT_SUCCESS,
    GET_CONTENT_ERROR
} from './contentConstants';

import axios from "axios";

export const getContentBegin = () => {
    return {type: GET_CONTENT_BEGIN};
}


export const getContentSuccess = (content) => {
    return {type: GET_CONTENT_SUCCESS, payload: content};
}

export const getContentError = (errors) => {
    return {type: GET_CONTENT_ERROR, payload: errors};
}

export const getContent = () => {
    return (dispatch) => {
        dispatch(getContentBegin());
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
         }
        axios.get(process.env.REACT_APP_API_URL+'/api/content_settings/', 
                  yourConfig)
        .then(
            response => {dispatch(getContentSuccess(response.data));console.log(response)})
        .catch(error => dispatch(getContentError(error)));
    

    }
}