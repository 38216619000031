import { Card, Divider, message, Typography } from 'antd';
import React from 'react';
import { Bar, BarChart, CartesianGrid, Tooltip, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import ExportCsvModal from '../exportCsvModal';
import promotionServices from '../promotionServices';


const PromoPerformance = (props) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [promoData, setPromoData] = React.useState(null);
    const [email, setEmail] = React.useState(false);
    const [showExportModal, setShowExportModal] = React.useState(false);
    
    const CountCard = (props) => {
        console.log(props);
        const { data } = props;
        return (
            <Card
                style={{borderTop: '12px solid #0A315E', padding: '0', margin: '20px 0 14px 0'}}
                bodyStyle={styles.countCardBody}
            >
                <div>
                    <Typography style={styles.countTitle}>Total Discount Amount</Typography>
                    <Typography style={styles.countVal}>₱{(data && data.total_discount && data.total_discount.toFixed(2)) || 0.00}</Typography>
                </div>
                <Divider type='vertical' style={{height: 54 }} />
                <div>
                    <Typography style={styles.countTitle}>Redemption</Typography>
                    <Typography style={styles.countVal}>{(data && data.total_count && data.total_count.toLocaleString()) || 0}</Typography>
                </div>
            </Card>
        )
    }

    const sampleData = [
        {
            day: 1,
            redeemed: 23,     
        },
        {
            day: 2,
            redeemed: 10,     
        },
        {
            day: 3,
            redeemed: 12,     
        },
        {
            day: 4,
            redeemed: 3,     
        },
        {
            day: 5,
            redeemed: 16,     
        },
        {
            day: 6,
            redeemed: 2,     
        },
        {
            day: 7,
            redeemed: 31,     
        },
        {
            day: 8,
            redeemed: 14,     
        }
    ]

    const CustomTooltip = ({ active, payload=null, label }) => {
        if (active && payload && payload[0] && payload.length) {
             const { count } = payload[0].payload;
            return (
                <div align='center' style={styles.tooltipWrapper}>
                    <Typography style={styles.tooltipText}>Redemption</Typography>
                    <Typography style={styles.tooltipText}>{count.toLocaleString() || 0}</Typography>
                </div>
            )
        }
        
        return null
    }

    const fetchPromoPerformance = async (isExport=false) => {
        setIsLoading(true);
        try {
            const res = await promotionServices.getPromoPerformance(props.id, email, isExport)
            console.log(res);
            if(res.status === 'success' && !isExport) {
                setPromoData(res.data);
            } else if(res.status === 'success' && isExport) {
                message.success(res.message);
            }  else {
                message.error(res.message)
            }
        } catch (error) {
            console.error(error);
            message.error('Unable to retrieve Promo Perfromace, try again.')
        }
        setIsLoading(false);
    }

    React.useEffect(() => {
        fetchPromoPerformance();
    },[])

    return (
        <div>
            <div style={{width: '100%', textAlign: 'right'}}>
                <button className="btn--nil" onClick={() => setShowExportModal(true)}>Send report</button>
            </div>
            <CountCard data={promoData || null} />
            <Card 
                style={{height: '100%'}}
                bodyStyle={{height: '80vh'}}   
                loading={isLoading} 
            >
                <div style={styles.spaceBetween}>
                    <Typography style={styles.title}>Voucher Performance</Typography>
                    <div style={{display: 'inline-flex', alignItems: 'center', gap: '8px' }}>
                        <div
                            style={{
                                height: '16px',
                                width: '24px',
                                border: '1px solid #F08A34',
                                background: '#F08A34',
                                clear: 'both',
                            }}
                        ></div>
                        <Typography style={styles.countTitle}>Redemption</Typography>
                    </div>
                </div>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={564}
                        height={524}
                        data={(promoData && promoData.promos) || []}
                        margin={{
                            top: 20,
                            right: 20,
                            left: 5,
                            bottom: 20,
                        }}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis dataKey={"count"} />
                        <Tooltip content={<CustomTooltip />} />
                        {/* <Legend /> */}
                        <Bar dataKey="count" fill="#F08A34" barSize={64} />
                    </BarChart>
                </ResponsiveContainer>
                <ExportCsvModal visible={showExportModal} onClose={() => setShowExportModal(false)}
                    onSend={() => fetchPromoPerformance(true)/setShowExportModal(false)} onChange={(e) => setEmail(e)} />
            </Card>
        </div>
    )
}

const styles = {
    root: {

    },
    countCardBody: {
        padding: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    countTitle: {
        color: '#50525',
        fontSize: '12px',
        fontWeight: '400'
    },
    countVal: {
        color: '#333437',
        fontSize: '16px',
        fontWeight: 'bold'
    },
    title: {
        color: '#2B2D32',
        fontWeight: '700',
        fontSize: '18px'
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    tooltipWrapper: {
        backgroundColor: '#2B2D32',
        borderRadius: '4px',
        padding: '12px',
    },
    tooltipText: {
        color: '#FFF',
        fontWeight: '500'
    }
}

export default PromoPerformance;