import React, {useEffect} from 'react'
import {Layout, Divider, Breadcrumb, Card, Icon} from 'antd'
import OrderIcon from '../../../static/icons/order_icon.svg'
import axios from 'axios'
import {isMobile} from 'react-device-detect';
import {Handle401} from '../../handle401/handle401'
import {history} from '../../../store/history'
import * as Style from './mobile_order_style';
import MetaTag from '../../meta_tag/meta_tag'
import MobileMain from '../../../layouts/Mobile/MobileMain'
import Dot from '../../../static/icons/dot.svg'
const MobileOrderDetails = props =>{

    const { Header } = Layout;
    const [amount, setAmount] = React.useState("")
    const [code, setCode] = React.useState("")
    const [description, setDesc] = React.useState("")
    const [createdAt, setCreatedAt] = React.useState("")
    const [address, setAddress] = React.useState("")
    const [name, setName] = React.useState("")
    const [contact, setContact] = React.useState("")
    const [hover, setHover] = React.useState(false)
    const [cart, setCart] = React.useState([])


    useEffect(()=>{
        let current_path = window.location.pathname
        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            history.push('/login?next='+current_path)
        }
        else{
            if(!isMobile){
                history.push('/messenger/error')
            }
            else if(localStorage.getItem("userType") == "PF"){
                history.push('/recon/7_eleven')
            }
            else if(localStorage.getItem("userType") == "MA"){
              history.push('/overview')
            }
            else{
                getDetails()
            }
        }
        
        
    }, [])

    async function getDetails(){
        try {
            const yourConfig = {
                headers: {
                   Authorization: "Token " + localStorage.getItem("jwtToken")
                }
             }

            let response = await axios.get(
                process.env.REACT_APP_API_URL + '/api/payment/'+ props.match.params.id, yourConfig
            );
            
            console.log(response.data)
            let data = response ? response.data : []
            setAmount(data.amount)
            setCreatedAt(data.created)
            setDesc(data.description)
            setAddress(data.address)
            setName(data.name)
            setContact(data.b_phone)
            setCode(data.code)
            setCart(data.cart)

            if(data.code == null){
                history.push(`/checkout/${props.match.params.id}`)
            }
            

        }
    
        catch (error) {
            if((error.response)&&(error.response.status == 401)){
                Handle401()
            }
        
        }
    }
    var linkStyle;
    if (hover) {
        linkStyle = {
            color: 'blue',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: isMobile ? '14px' : '16px',
            lineHeight: '24px',
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    } else {
        linkStyle = {
            color: '#000',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: isMobile ? '14px' : '16px',
            lineHeight: '24px',
        }
    }
    return(
        <MobileMain title="Payment Details">
            <MetaTag title="Payment Details"/>
            <div style={{padding: '30px 16px 20px 16px'}}>

                <div style={{marginBottom: '20px'}}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <a onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} onClick={()=>history.push('/orders')} style={linkStyle}>Payment Request</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item style={{marginTop: '5px', color: '#000', marginBottom: '20px'}}><strong>Payment details</strong></Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                
                <div style={Style.codeStyle}>Payment Details</div>
                <div style={{marginTop: '10px'}}>
                    <div style={Style.shippedStyle}>Created on {createdAt}</div>
                </div>
                <div style={{display: 'flex', marginBottom: '20px'}}>
                    <div style={Style.totalStyle}>Total:</div>
                    <div style={Style.orderDetailsStyle}>₱ {amount}</div>
                </div>

                <Card title={<div style={Style.textStyle}>Reference no.</div>}extra={<div style={Style.orderDetailsStyle}>{code}</div>} style={{ width: '100%' }}>
                <div style={Style.headerStyle}>
                    Item description
                </div>
                <div style={{marginTop: '5px', color: '#000'}}>
                    {description}
                </div>
                </Card>
                
                { address ?
                    <Card style={{marginTop: '20px'}}>
                        <div style={Style.headerStyle}>
                            Shipping address
                        </div>
                        <div style={{marginTop: '5px', color: '#000'}}>
                            { name }
                        </div>
                        <div style={Style.buyerInfoStyle}>
                            { address }
                        </div>
                        <div style={Style.buyerInfoStyle} style={{marginBottom: '20px'}}>
                            { contact }
                        </div>

                    </Card>

                    :

                    ""
                }


                {
                    cart.length != 0 ? 
                    <Card style={{marginTop: '20px'}}>
                        <div style={Style.headerStyle}>
                            Item List
                        </div>
                        {
                            cart.map((item,i)=>{
                                return(

                                    <div style={{marginTop: i == 0 ? '10px' : '0px'}}>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div style={{display: 'flex'}}>
                                                <img src={Dot} style={{marginRight: '5px'}} /> <div style={Style.itemName}>{item.name}</div>
                                            </div>
                                            
                                            <div style={Style.totalPrice} align="right">₱ {parseFloat(item.amount).toLocaleString('en-US', {minimumFractionDigits: 2})}</div>

                                        </div>                                
                                        <div style={Style.totalPrice}>QTY: { item.quantity ? item.quantity : "1" }</div>
                                        <Divider hidden={i == cart.length - 1}/>
                                    </div>                            
                                    
                                )
                            })
                        }
                        
                    </Card>
                    :
                    ""
                }
                <Card style={{marginTop: '20px'}}>
                    <div style={Style.headerStyle}>
                        Total Summary
                    </div>
                    {/* <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
                        <div style={Style.buyerInfoStyle}>Subtotal</div>
                        <div style={Style.buyerInfoStyle}> ₱ {(parseFloat(amount.replace(",","")) - 20).toLocaleString('en-US', {minimumFractionDigits: 2})}</div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={Style.buyerInfoStyle}>Transaction Fee</div>
                        <div style={Style.buyerInfoStyle}> ₱ 20.00</div>
                    </div> */}
                    <Divider/>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={Style.buyerInfoStyle}>Total</div>
                        <div style={Style.totalPrice}>₱ {amount}</div>
                    </div>
                    <div style={Style.buyerInfoStyle}>Paid via Bux</div>
                </Card>

                
            </div>
        </MobileMain>
    )
}

export default MobileOrderDetails