import React from "react";
import { Input, Modal, Typography, Select, Form } from "antd";
import { NATURE_BUSINESS } from "../../../../../components/constants/constants";
import { validateEmail, validateMobileNumber } from "../../../../../utils/validators";

export function AddUserModal({
  form,
  business_name,
  first_name,
  last_name,
  email,
  contact,
  multiwallet_privileges,
  open,
  handleOk,
  confirmLoading,
  handleCancel,
  walletDefinedSettings,
  updateFields,
}) {
  const handleRoleChange = (selectedRole) => {
    let updatedFields = { ...walletDefinedSettings.multiwallet_privileges };
    if (selectedRole === "approver_grouped_priveleges_names") {
      updatedFields = {
        ...walletDefinedSettings.multiwallet_privileges,
        role: selectedRole,
        wallet_transfer_approval_enabled: true,
        limits_approval_enabled: true,
        payout_enabled: false,
        fund_transfer_history_enabled: false,
      };

      updateFields({ sw_type: "approver", multiwallet_privileges: updatedFields });
    } else if (selectedRole === "maker_grouped_priveleges_names") {
      updatedFields = {
        ...walletDefinedSettings.multiwallet_privileges,
        role: selectedRole,
        wallet_transfer_enabled: true,
        wallet_transfer_history_enabled: true,
        bank_transfer_enabled: true,
        payout_enabled: false,
        fund_transfer_history_enabled: true,
      };

      updateFields({ sw_type: "maker", multiwallet_privileges: updatedFields });
    }
  };

  return (
    <Modal
      title={"Add new user"}
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      width={480}
      centered
    >
      <Form onSubmit={handleOk} style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <Form.Item label="First Name" style={{ margin: 0 }}>
          {form.getFieldDecorator("first_name", {
            initialValue: first_name,
            rules: [
              { required: true, message: "Please input first name" }
            ],
          })(
            <Input
              placeholder="First name"
              onChange={(e) => updateFields({ first_name: e.target.value })}
            />
          )}
        </Form.Item>

        <Form.Item label="Last Name" style={{ margin: 0 }}>
          {form.getFieldDecorator("last_name", {
            initialValue: last_name,
            rules: [
              { required: true, message: "Please input last name" }
            ],
          })(
            <Input
              placeholder="Last name"
              onChange={(e) => updateFields({ last_name: e.target.value })}
            />
          )}
        </Form.Item>

        <Form.Item label="Email" style={{ margin: 0 }}>
          {form.getFieldDecorator("email", {
            initialValue: email,
            rules: [
              { required: true, message: "Please input email address" },
              { validator: (_, value) => validateEmail(value) }
            ],
          })(
            <Input
              type="email"
              placeholder="Email"
              onChange={(e) => updateFields({ email: e.target.value })}
            />
          )}
        </Form.Item>

        <Form.Item label="Contact" style={{ margin: 0 }}>
          {form.getFieldDecorator("contact", {
            initialValue: contact,
            rules: [
              { required: true, message: "Please input a mobile number" },
              { validator: (_, value) => validateMobileNumber(value) }
            ],
          })(
            <Input
              addonBefore="+63"
              type="number"
              placeholder="Contact"
              onChange={(e) => updateFields({ contact: e.target.value })}
            />
          )}
        </Form.Item>

        <Form.Item label="Role" style={{ margin: 0 }}>
          {form.getFieldDecorator("role", {
            initialValue: multiwallet_privileges.role,
            rules: [
              { required: true, message: "Please select a role" }
            ],
          })(
            <Select
              style={{ width: "100%" }}
              placeholder="Select role"
              size="large"
              optionFilterProp="children"
              onChange={handleRoleChange}
            >
              <Select.Option
                key="approver_grouped_priveleges_names"
                value="approver_grouped_priveleges_names"
              >
                Approver
              </Select.Option>
              <Select.Option
                key="maker_grouped_priveleges_names"
                value="maker_grouped_priveleges_names"
              >
                Maker
              </Select.Option>
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}
