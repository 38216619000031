import React, { useEffect } from 'react'
import { Input, Select, Icon, Typography } from 'antd';
import { isMobile } from 'react-device-detect';
import * as Icons from '../../../../static/images/home/index';
import { numberWithCommas, COUNTRIES } from '../../../constants/constants'

const SignedDataFields = props => {

    // Constant Names for Leyte Addition MDD Fields
    const CONVENIENCE_SERVICE_FEE_KEY = 'merchant_defined_data29'
    const LGU_GOVT_PAYMENT_DETAILS_KEY = 'merchant_defined_data41'
    const LGU_GOVT_PAYMENT_REFS_KEY = 'merchant_defined_data42'
    const AMOUNT_FEE_KEY = 'merchant_defined_data43'

    const {
        handleChange,
        bill_to_forename,
        bill_to_surname,
        bill_to_address_line1,
        bill_to_address_city,
        bill_to_address_state,
        bill_to_address_postal_code,
        bill_to_email,
        bill_to_address_country,
        payment,
        merchant_defined_data41,
        merchant_defined_data42,
        handleCountryChange
    } = props

    const { Option } = Select;

    const DEFAULT_SIGNED_FIELD_NAMES = 'access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,auth_trans_ref_no,amount,currency,payment_method,bill_to_forename,bill_to_surname,bill_to_email,bill_to_address_line1,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code'

    const [signedFieldNames, setSignedFieldNames] = React.useState(DEFAULT_SIGNED_FIELD_NAMES)

    useEffect(() => {
        setSignedFieldNames(payment.is_display_mdd_fields ? `${DEFAULT_SIGNED_FIELD_NAMES},${getLeyteMDDFields()}` : DEFAULT_SIGNED_FIELD_NAMES)
    }, [payment])

    function getLeyteMDDFields() {
        return [
            CONVENIENCE_SERVICE_FEE_KEY,
            LGU_GOVT_PAYMENT_DETAILS_KEY,
            LGU_GOVT_PAYMENT_REFS_KEY,
            AMOUNT_FEE_KEY
        ].join(',')
    }

    function getCountries() {
        let countries = [];
        COUNTRIES.forEach((i, country) => {
            countries.push(<Option key={COUNTRIES[country].code} value={COUNTRIES[country].code}>{COUNTRIES[country].name}</Option>);
        });
        return countries;
    }

    function getTotalAdditionalFee() {
        let totalFee = 0;
        if (payment && payment.fees && payment.fees) {
            const fees = Object.values(payment.fees);
            totalFee = fees.reduce((accumulator, value) => accumulator + value, 0)
        }
        return numberWithCommas(parseFloat(totalFee).toFixed(2))
    }

    return (
        <div align="left">
            <input type="hidden" name="access_key" value={payment.payload && payment.payload.access_key} readOnly/>
            <input type="hidden" name="profile_id" value={payment.payload && payment.payload.profile_id} readOnly/>
            <input type="hidden" name="transaction_uuid" value={payment.payload && payment.payload.txn_uid} readOnly/>
            <input type="hidden" name="signed_field_names" value={signedFieldNames}/>
            <input type="hidden" name="unsigned_field_names" value="card_type,card_number,card_expiry_date" />
            <input type="hidden" name="signed_date_time" value={new Date().toISOString().split(".")[0]+"Z"} />
            <input type="hidden" name="locale" value="en" />
            <input type="hidden" name="transaction_type" size="25" value="sale"/>
            <input type="hidden" name="auth_trans_ref_no" size="25" value={payment && payment.code}/>
            <input type="hidden" name="currency" size="25" value="PHP" />
            <input type="hidden" name="payment_method" value="card" />
            <input type="hidden" name="amount" value={payment.amount} />
            { payment.is_display_mdd_fields &&
                <>
                    <input type="hidden" name={CONVENIENCE_SERVICE_FEE_KEY} value={getTotalAdditionalFee()} />
                    <input type="hidden" name={AMOUNT_FEE_KEY} value={payment.amount} />
                </>
            }
            <div className="amount-div liveColor" align="center">
                <div className="normalTextStyle">
                    Amount to Pay
                </div>
                <div className="screen-title-text top-4">
                    ₱ {numberWithCommas(parseFloat(payment.amount).toFixed(2))}
                </div>
            </div>

            <div className="top-32 liveColor">
                <div className="twoCol input-box-div">
                    <div className="normal-text-size liveColor">
                        Reference Number:
                    </div>
                    <div className="wc-header">
                        <div>
                            {payment && payment.code}
                        </div>
                        <input type="hidden" name="reference_number" size="25" value={payment && payment.code} readOnly/>
                    </div>
                </div>
            </div>

            <div className="top-20 cust-info">
                <div className="wc-header"> 
                    <img src={Icons.customer} className="right-8" alt="info" /> 
                    Customer information
                </div>

                <div className="top-16">
                    <div className={`${isMobile ?  "" : "twoCol"}`}>
                        <div className="top-16">
                            <div className="smallTextStyle liveColor">First Name</div>
                            <Input maxLength={60} className="two-col-input top-4" type="text" name="bill_to_forename" value={bill_to_forename} onChange={handleChange}  />
                        </div>
                        <div className="top-16">
                            <div className="smallTextStyle liveColor">Last Name</div>
                            <Input maxLength={60} className="two-col-input top-4" type="text" name="bill_to_surname" value={bill_to_surname} onChange={handleChange} />
                        </div>
                    </div>
                </div>

                <div className="top-16">
                    <div className="top-16">
                        <div className="smallTextStyle liveColor">Email Address</div>
                        <Input className="input-100 top-4" type="text" name="bill_to_email" value={bill_to_email} onChange={handleChange} />
                    </div>
                </div>
            </div>

            { payment.is_display_mdd_fields &&
                <>
                    <div className="top-20 cust-info">
                        <div className="wc-header"> 
                            Merchant information
                        </div>

                        <div className="top-16">
                            <div className="top-16">
                                <div className="smallTextStyle liveColor">LGU/Govt. Payment Details</div>
                                <Input className="input-100 top-4" type="text" name={LGU_GOVT_PAYMENT_DETAILS_KEY} value={merchant_defined_data41} onChange={handleChange} />
                            </div>
                        </div>

                        <div className="top-16">
                            <div className="top-16">
                                <div className="smallTextStyle liveColor">LGU/Govt. Payment Reference</div>
                                <Input className="input-100 top-4" type="text" name={LGU_GOVT_PAYMENT_REFS_KEY} value={merchant_defined_data42} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                </>
            }
            <div className="top-20 cust-info">
                <div className="wc-header"> 
                    <img src={Icons.location} className="right-8" alt="info" /> 
                    Billing address
                </div>

                <div style={{
                    display: 'flex',
                    alignItems: 'start',
                    borderRadius: 2,
                    border: '1px solid #F4B24A',
                    width: '100%',
                    backgroundColor: 'rgba(244, 193, 10, 0.16)',
                    padding: '6px 8px',
                    marginTop: 12
                }}>
                    <Icon type='exclamation-circle' style={{ color: '#F4B24A', paddingRight: 8, marginBottom: '-2px', fontSize: '22px', marginTop: '4px' }} />
                    <Typography style={{fontSize: '12px'}}>Note: Province is required for U.S., Canada and China. For U.S., Canada and China use the standard state, province and territory codes. ex: "AL" for Alabama</Typography>
                </div>

                <div className="top-16">
                    <div className="smallTextStyle liveColor">Address line 1</div>
                    <Input maxLength={60} className="input-100 top-4" type="text" name="bill_to_address_line1" value={bill_to_address_line1} onChange={handleChange} />
                </div>

            <div className="top-16">
                    <div>
                        <div className="top-16">
                            <div className="smallTextStyle liveColor">Country</div>
                            <input type="hidden" className="two-col-input top-4" name="bill_to_address_country" value={bill_to_address_country} />
                            <Select
                                data-test="country"
                                showSearch
                                className="top-4"
                                style={{ width: '100%'}}
                                optionFilterProp="children"
                                name="bill_to_address_country"
                                value={bill_to_address_country}
                                onChange={handleCountryChange}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {getCountries()}
                            </Select>
                        </div>
                    </div>
                </div>

                <div className="top-16">
                    <div className="smallTextStyle liveColor">Province</div>
                    <Input maxLength={60} className="input-100 top-4" type="text" name="bill_to_address_state" value={bill_to_address_state} onChange={handleChange} />
                </div>

                <div className="top-16">
                    <div className="smallTextStyle liveColor">City</div>
                    <Input maxLength={60} className="input-100 top-4" type="text" name="bill_to_address_city" value={bill_to_address_city} onChange={handleChange} />
                </div>

                <div className="top-16">
                    <div className={`${isMobile ?  "" : "twoCol"}`}>
                        <div className="top-16">
                            <div className="smallTextStyle liveColor">Postal Code</div>
                            <Input 
                                maxLength={10}
                                className="two-col-input top-4" 
                                type="text" 
                                name="bill_to_address_postal_code"
                                value={bill_to_address_postal_code} 
                                onChange={handleChange} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignedDataFields;