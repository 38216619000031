import React from 'react';
import MerchantId from '../../../components/AdminComponents/merchant_ids/merchant_ids';

class MerchantIDs extends React.Component {
    render() {
      return (
        <MerchantId />
      )
    };
  }
  
  export default MerchantIDs;