import React from 'react';
import UsersForm from '../../../components/AdminComponents/users/usersComponent/usersForm';


class Users extends React.Component {
  render() {
    return (
      <UsersForm />
    )
  };
}

export default Users;