import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import Xendit from 'xendit-js-node';
import { paymentGet } from '../../payment/paymentAction';
import TestModeBanner from '../../cards/test_mode/testModeBanner';
import { Grid } from '@material-ui/core';
import MetaTags from "react-meta-tags";
import { Redirect } from "react-router-dom";
import promotionServices from '../../AdminComponents/promotion/promotionServices';
import moment from 'moment';

//Antd
import { Layout, Card, Radio, message, Spin, Button, Select, Modal, Divider, Tooltip, Checkbox, Typography, Drawer, Input } from 'antd';
import { Icon as Icons } from 'antd';

//Icons
import * as Icon from '../../../static/icons/payment_method_icons/index';

import profile from "../../../static/icons/profile.svg";
import { WebBux } from '../../../static/images/home/index';
import { BUx } from '../../../static/icons/bux_qr/index';
import { history } from '../../../store/history';
import IconCheck from '../../../static/icons/check_icon.svg';
import BankInfo from '../../../static/icons/bank_info.svg';
import { isMobile as isDeviceMobile } from 'react-device-detect';
import { backToTop, UPAY_LIST, PCHC_LIST, INSTAPAY_LIST, OTC_NAME, MOBILE } from '../../constants/constants'
import IconSecured from '../../../static/icons/bux_qr/secured_bux.svg';
import { makeStyles } from './checkoutStyle'
import DisclaimerModal from '../../account_settings/bux_qr/disclaimerModal';
import InstaPayModalDisclaimer from './instapayDisclaimerModal';
import UpayList from './upayList';

// promo modal
import CheckoutPromoModal from '../../AdminComponents/promocode/checkout_modal';
import PromoLogo from '../../../static/img/checkout_promo_logo.svg';
import PageNotFound from '../../404/404';
import useCustomMediaQuery from '../../../hooks/useCustomMediaQuery';

const testMode = (history.location.pathname.indexOf('/test') > -1);
const userType = localStorage.getItem("userType")

const Checkout = props => {
    const media = useCustomMediaQuery()
    const styles = makeStyles(media)
    const isMediaMobile = media.mobile || media.tablet

    const dispatch = useDispatch()
    const paymentDetails = useSelector(state => state.payment);
    const paymentObj = paymentDetails.payment;
    const uid = props.match.params.uuid;

    const { Header } = Layout;
    const { Option } = Select;

    //States
    const [disabled, setDisable] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [modalVisibility, setModalVisibility] = React.useState(false)
    const [bankModalVisibility, setBankModalVisibility] = React.useState(false)
    const [instapayModalVisibility, setInstapayModalVisibility] = React.useState(false)

    //Channel State
    const [paymentMethod, setPaymentMethod] = React.useState('');
    const [channel, setChannel] = React.useState('');
    const [bank, setBank] = React.useState('Select a bank');
    const [sur, setSur] = React.useState(0)

    //Credit Card states
    const [ccModal, setShowCCModal] = React.useState(false);

    // promo code
    const [promoCode, setPromoCode] = React.useState('');
    const [promoCodeStatus, setPromoCodeStatus] = React.useState([]);
    const [promoAmount, setPromoAmount] = React.useState(0);
    const [promoType, setPromoType] = React.useState('Fixed');
    const [promoMinAmount, setPromoMinAmount] = React.useState(50);
    const [promoMaxAmount, setPromoMaxAmount] = React.useState(20);

    const [detailPaymentMethod, setDetailPaymentMethod] = React.useState('');
    const [mobileDetailsShow, setMobileDetailsShow] = React.useState(false);
    
    const [checkoutPromoModal, setCheckoutPromoModal] = React.useState(false);
    const [banksDisabled, setBanksDisabled] = useState(false);

    useEffect(() => {
        const perfEntries = performance.getEntriesByType('navigation');
        if (perfEntries.length && perfEntries[0].type === 'back_forward') {
            window.location.reload()
        }
        dispatch(paymentGet(uid, testMode));
        getTimeDate();
        Xendit.setPublishableKey(process.env.REACT_APP_XENDIT_PUBLIC_KEY);
        window.addEventListener('message', postBackRecv);

        console.log("------------------------");
        console.log(UPAY_LIST);
        console.log(UPAY_LIST['instapay']);
    }, [])

    useEffect(() => {
        if (paymentObj && paymentObj.disabled_channels) {
            const areAllBanksDisabled = banks.every(bank => paymentObj.disabled_channels.includes(bank.ac));
            setBanksDisabled(areAllBanksDisabled);
        }
    }, [paymentObj]);

    const list_bank = ['bank', 'BPIA', 'UBPB', 'RCBC', 'BDO', 'MBTC']

    const channel_list = [
        { 'name': '7-Eleven', 'code': 'ecpay_711', 'icon': Icon.SevenEleven, 'enabled': paymentObj.disabled_channels && (paymentObj.disabled_channels.indexOf('ecpay_711') == -1 && paymentObj.disabled_channels.indexOf('711_direct') == -1) },
        { 'name': 'Bayad Center', 'code': 'bayad_center', 'icon': Icon.BayadCenter, 'enabled': paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('bayad_center') == -1 },
        { 'name': 'Cebuana', 'code': 'CEBL', 'icon': Icon.Cebuana, 'enabled': paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('CEBL') == -1 },
        { 'name': 'ECPay', 'code': 'ECPY', 'icon': Icon.Ecpay, 'enabled': paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('ECPY') == -1 },
        // {'name': 'LBC','code': 'LBC', 'icon': Icon.Lbc, 'enabled': testMode? true : paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('LBC') == -1},
        { 'name': 'MLhuillier', 'code': 'MLH', 'icon': Icon.ML, 'enabled': paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('MLH') == -1 && paymentObj.amount && paymentObj.amount >= 180 && paymentObj.user_role == 'CO' },
        { 'name': 'Robinsons', 'code': 'RDS', 'icon': Icon.Robinson, 'enabled': paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('RDS') == -1 },
        { 'name': 'Palawan', 'code': 'PLWN', 'icon': Icon.Palawan, 'enabled': paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('PLWN') == -1 },
        { 'name': 'SM', 'code': 'SMR', 'icon': Icon.Sm, 'enabled': paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('SMR') == -1 },
        { 'name': 'RD Pawnshop', 'code': 'RDP', 'icon': Icon.RD, 'enabled': paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('RDP') == -1 },
        { 'name': 'RuralNet', 'code': 'RLNT', 'icon': Icon.Rural, 'enabled': paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('RLNT') == -1 },
        // {'name': 'Expresspay','code': 'EXPY', 'icon': Icon.Expresspay, 'enabled': testMode? true : paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('EXPY') == -1},
        { 'name': 'Pay&Go', 'code': 'payngo', 'icon': Icon.BTI, 'enabled': paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('payngo') == -1 },
        { 'name': 'DA5', 'code': 'da5', 'icon': Icon.DA5, 'enabled': paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('da5') == -1 },
        { 'name': 'Posible', 'code': 'posible', 'icon': Icon.Posible, 'enabled': paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('posible') == -1 },
        { 'name': 'USSC', 'code': 'ussc', 'icon': Icon.USSC, 'enabled': paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('ussc') == -1 },
        // {'name': 'Rustan','code': 'rustan', 'icon': Icon.Rustan, 'enabled': testMode? true : paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('rustan') == -1},
        // {'name': 'Shopwise','code': 'shopwisew', 'icon': Icon.Shopwise, 'enabled': testMode? true : paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('shopwise') == -1},
        // {'name': 'Wellcome','code': 'wellcome', 'icon': Icon.Wellcome, 'enabled': testMode? true : paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('wellcome') == -1},
        { 'name': 'Tambunting', 'code': 'tmbntng', 'icon': Icon.Tambunting, 'enabled': paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('tmbntng') == -1 },
        // {'name': 'CVM Pawnshop','code': 'cvm', 'icon': Icon.CVM, 'enabled': testMode? true : paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('cvm') == -1},
        {'name': 'Villarica','code': 'villarica', 'icon': Icon.Villarica, 'enabled': paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('villarica') == -1},
        {'name': 'Petnet, Inc','code': 'petnet', 'icon': Icon.Petnet, 'enabled': paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('petnet') == -1},
        {'name': 'i2i','code': 'i2i', 'icon': Icon.i2i, 'enabled': paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('i2i') == -1},   
    ]

    const instapay_payment_channel = paymentObj.instapay_payment_channel;
    const banks_ewallets_choices = [
        { 
            'code': instapay_payment_channel, 
            'enabled': testMode ? false : paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf(instapay_payment_channel) == -1, 
            'icon': Icon.MainInstapay,
            'groupIcon': Icon.InstapayPartners,
            'name': 'Instapay'
        },
        { 
            'code': 'paygate', 
            'enabled': testMode ? false : paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('paygate')  == -1, 
            'icon': Icon.MainPchc,
            'groupIcon': Icon.PaygatePartners,
            'name': 'PCHC'
        },
    ]

    const bank_choices = [
        {
            code: "UBPB",
            enabled: paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('UBPB') == -1,
            icon: Icon.UB,
            name: 'Unionbank'
        },
        {
            code: "BPIA",
            enabled: paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('BPIA') == -1,
            icon: Icon.Bpi,
            name: 'BPI'
        },
        {
            code: "RCBC",
            enabled: paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('RCBC') == -1,
            icon: Icon.Rcbc,
            name: 'RCBC'
        },
        {
            code: "MBTC",
            enabled: paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('MBTC') == -1,
            icon: Icon.Metrobank,
            name: 'Metrobank'
        },
    ]

    const banks = [
        { 'surcharge': '10', 'ac': 'UBPB', 'name': 'UnionBank Internet Banking' },
        { 'surcharge': '15', 'ac': 'BPIA', 'name': 'Bank of the Philippine Islands' },
        { 'surcharge': '5', 'ac': 'RCBC', 'name': 'RCBC Online Banking' },
        // { 'surcharge': '5', 'ac': 'BDO', 'name': 'BDO Internet Banking' },
        { 'surcharge': '5', 'ac': 'MBTC', 'name': 'Metrobank Online Banking' },
        // { 'surcharge': '0', 'ac': 'instapay', 'name': 'Instapay' },
        // { 'surcharge': '0', 'ac': 'paygate', 'name': 'PesoNet' },
    ]

    const gcash_enabled =  paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('gcash') == -1;
    const grabpay_enabled =  paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('grabpay') == -1;
    const maya_enabled =  paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf(MOBILE.Maya.code) == -1;
    const ubp_enabled =  paymentObj.disabled_channels && paymentObj.cc_enabled === 'UnionBank';
    const xendit_enabled =  paymentObj.disabled_channels && paymentObj.cc_enabled === 'Xendit';
    const bnpl_enabled = paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf('billease') == -1 && (paymentObj.user_role === 'CO' || paymentObj.user_role === 'SW');
    const snpl_enabled = paymentObj.snpl;
    
    const hasDisclaimer = [
        "MBTC", 
        "BPIA",
        "UBPB",
        "RCBC",
        "gcash",
        "grabpay",
        MOBILE.Maya.code,
        "visamc",
        "ubpcs",
        "billease",
        "billease_sn"
    ]

    function getTimeDate() {
        let today = new Date()
        let yHrs = today.getHours();

        if (yHrs >= 21 || yHrs < 6) {
            setDisable(true)
        }

        else {
            setDisable(false)
        }
    }

    function handleSelectBank(value) {

        setChannel(banks[value].ac)
        setBank(banks[value].name)
        setSur(banks[value].surcharge)
        validatePromoCode(promoCode, paymentObj && paymentObj.segment, banks[value].ac)
    }

    function postBackRecv(response) {
        try {
            xenditResponseHandler(null, JSON.parse(response.data));
        } catch (error) {
        }
    }

    function proceed(){
        if (hasDisclaimer.includes(channel) || channel == 'instapay'){
            let channel_checkout = channel;
            if (channel == 'EXPY') {
                channel_checkout = 'ECPY'
            }

            else if (channel == 'MBTC') {
                window.location.href = testMode ? `/test/payment/${uid}` : `/payment/${uid}`;
            }

            let search = window.location.search;
            let sparams = new URLSearchParams(search);
            let redirect_url = sparams.get('redirect_url');

            let params = {
                id: paymentDetails.payment.id,
                uid,
                channel: channel_checkout,
            };

            if (redirect_url) {
                params = {
                    id: paymentDetails.payment.id,
                    uid,
                    channel: channel_checkout,
                    redirect_url: redirect_url
                };
            }

            if (promoCodeStatus && promoCodeStatus.status === 'valid') {
                params['promo_code'] = promoCode;
                params['promo_discount'] = promoAmount;
            }
            setModalVisibility(false)
            setInstapayModalVisibility(false)
            backToTop()
            submitCheckout(params)

        }

        else {
            window.location.href = testMode ? `/test/payment/${uid}` : `/payment/${uid}`;

        }
    }

    function xenditResponseHandler(err, response) {
        if (err) {
            message.error("Unable to proceed. Please try again");
        } else {
            if (response.status === 'APPROVED' || response.status === 'VERIFIED') {
                setShowCCModal(false);
                performCharge(response);
            } else if (response.status === 'IN_REVIEW') {
                setShowCCModal(true);
                window.open(response.payer_authentication_url, 'sample-inline-frame');
            } else if (response.status === 'FRAUD') {
                setShowCCModal(false);
                message.error("Unable to proceed. Please try again");
            } else if (response.status === 'FAILED') {
                setShowCCModal(false);
                message.error("Unable to proceed. Please try again");
            }
        }
    }

    function payNow() {

        if (channel == "" || channel == "bank" || channel == "wallet") {
            message.destroy()
            message.error(`Please select ${channel ? channel : 'payment method'}`, 2);
            return;
        }
        
        else if (hasDisclaimer.includes(channel)){
            setModalVisibility(true);
        }

        else if (channel == 'instapay'){
            proceed();
        }
        
        else {
            let channel_checkout = channel;
            if (channel == 'EXPY') {
                channel_checkout = 'ECPY'
            }

            let search = window.location.search;
            let sparams = new URLSearchParams(search);
            let redirect_url = sparams.get('redirect_url');

            let params = {
                id: paymentDetails.payment.id,
                uid,
                channel: channel_checkout,
            };

            if (redirect_url) {
                params = {
                    id: paymentDetails.payment.id,
                    uid,
                    channel: channel_checkout,
                    redirect_url: redirect_url
                };
            }

            if (promoCodeStatus && promoCodeStatus.status === 'valid') {
                params['promo_code'] = promoCode;
                params['promo_discount'] = promoAmount;
            }

            backToTop()
            submitCheckout(params)
        }
    }

    let otcCount = 0;
    let webCount = 0;

    const availableOTC = channel_list.map((item, i) => {
        if (paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf(item.code) == -1) {
            otcCount++
        }
    })

    const availableWebBanking = banks.map((item, i) => {
        if (paymentObj.disabled_channels && paymentObj.disabled_channels.indexOf(banks[i].ac) != -1) {
            webCount++
        }
    })
    //Async Functions
    async function performCharge(val) {
        setLoading(true)
        try {
            const url = `/api/xendit/${uid}/`
            let response = await axios.post(
                process.env.REACT_APP_API_URL + url,
                val
            );
            if (response.data.status == "success") {

                message.success("Credit Card Payment Successful");
                if (response.data.redirect_url) {
                    window.location.href = response.data.redirect_url;

                } else {
                    setLoading(false)
                    proceed();
                }
            }
            else {
                setLoading(false)
                message.error("Unable to proceed. Please try again");
            }
        } catch (error) {
            setLoading(false)
            message.error("Unable to proceed. Please try again");
        }
    }

    const validatePromoCode = async (code, segment, channel) => {
        if (!code) {
            return false;
        }
 
        const payment_link_amount = channel && !paymentDetails.payment.shoulder_enabled ? parseFloat(paymentObj.amount) + parseFloat(paymentDetails.payment.fees[channel]) : paymentObj.amount;
        try {
            const res = await promotionServices.validatePromoCode(code, segment, channel, payment_link_amount);
            
            if (res.status === 'success') {
                setPromoAmount(res.discount_amount);
                setPromoType(res.discount_type);
                setPromoCodeStatus({
                    status: 'valid',
                    message: res.message
                });
                setPromoMinAmount(res.min_amount);
                if(res && res.max_amount) {
                    setPromoMaxAmount(res.max_amount);
                }
            } else if (res.status === 'error') {
                setPromoAmount('');
                setPromoType('');
                setPromoCodeStatus({
                    status: 'invalid',
                    message: res.message
                });
                setPromoMinAmount(50);
                setPromoMaxAmount(20);
            }
        } catch (error) {
            setPromoAmount('');
            setPromoType('');
            setPromoCodeStatus({
                status: 'invalid',
                message: 'Error please try again'
            });
            setPromoMinAmount(50);
            setPromoMaxAmount(20);
        }
    }

    async function submitCheckout(val) {
        setLoading(true)
        try {
            const url = testMode ? '/api/sandbox/checkout/' : '/api/orders/checkout/'
            let response = await axios.post(
                process.env.REACT_APP_API_URL + url,
                val
            );
            
            if(response.data.status == "success"){
                if(response.data.banking_url){
                    window.location.href = response.data.banking_url;

                } else if (response.data.redirect_url) {
                       window.location.href = response.data.redirect_url;

                } else {
                    setLoading(false)
                    proceed();
                }

            }
            else {
                setLoading(false)
                if (response.data.message) {

                    message.error(response.data.message);
                } else {
                    message.error("Unable to proceed. Please try again");
                }
            }
        } catch (error) {
            setLoading(false)
            message.error("Unable to proceed. Please try again");
        }
    }

    const channelContent = () => {
        return (
            <div className='checkout-channel-card-wrapper'>
                {
                    channel_list && channel_list.map((item, i) => {
                        return (
                            item.enabled && 
                            <div className='checkout-channel-card'>
                                <button
                                    id={item.code}
                                    className={channel == item.code ? 'otc-button-selected' : 'otc-button-not-selected'}
                                    onClick={() => setChannel(item.code) / validatePromoCode(promoCode, paymentObj && paymentObj.segment, item.code) / setDetailPaymentMethod(item.name)}>
                                    <div style={{ position: 'absolute', left: 16 }} >
                                        {
                                            channel != item.code ?
                                                <Radio />
                                                :
                                                <img src={IconCheck} alt="check"/>
                                        }
                                    </div>
                                    <img src={item.icon}
                                        alt="icon"
                                        style={{
                                            maxWidth: '48%',
                                            // marginBottom: isMobile && item.code === 'RDP' ? 4 : '10px'
                                        }} />
                                    {/* <div style={{ ...styles.normalText, color: '#000', lineHeight: isMobile && item.code === 'RDP' && '14px' }}>
                                        {item.name}
                                    </div> */}
                                </button>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const web_content = () => {
        return (
            <div className='checkout-channel-card-wrapper' >
                {bank_choices.map((item, i) => {
                    return (
                        item.enabled &&
                        <div key={i} className='checkout-channel-card'>
                            <button
                                className={channel == item.code ? 'ol-ew-btn-selected' : 'ol-ew-btn-not-selected'}
                                onClick={() => handleSelectBank(i) / setDetailPaymentMethod(item.name)}
                                disabled={!item.enabled}
                            >
                                <div style={{ position: 'absolute', left: 16 }} >
                                    {
                                        channel != item.code ?
                                            <Radio />
                                            :
                                            <img src={IconCheck} alt="check"/>
                                    }
                                </div>
                                
                                <img alt="icon" src={item.icon} style={{maxWidth: '48%', marginTop: item.code == 'paygate' ? -10 : item.code == 'instapay' ? 16 : 0}} />
                            </button>
                        </div>
                    )
                })}
            </div>

        )
    }

    const ewallets = [
        {
            code: "gcash",
            enabled: gcash_enabled,
            icon: Icon.Gcash
        },
        {
            code: "grabpay",
            enabled: grabpay_enabled,
            icon: Icon.Grabpay
        },
        {
            code: MOBILE.Maya.code,
            enabled: maya_enabled,
            icon: Icon.Maya
        }
    ]

    const ewallet_content = () => {
        return(
            <div style={{ width: '100%'}} >
                <div className='checkout-channel-card-wrapper' >
                    {ewallets.map((item, i) => {
                        return (
                            item.enabled &&
                            <div div className='checkout-channel-card' >
                                <button
                                    className={channel == item.code ? 'ol-ew-btn-selected' : 'ol-ew-btn-not-selected'}
                                    onClick={() => setChannel(item.code) / validatePromoCode(promoCode, paymentObj && paymentObj.segment, item.code) / setDetailPaymentMethod(item.name)}
                                    disabled={!item.enabled}
                                >
                                    <div style={{ position: 'absolute', left: 16 }} >
                                        {
                                            channel != item.code ?
                                                <Radio />
                                                :
                                                <img src={IconCheck} alt="check"/>
                                        }
                                    </div>

                                    <img alt="icon" src={item.icon} style={{ maxWidth: '48%', marginLeft: 15 }} />
                                    <div style={{ marginTop: '14px', color: '#000000', textoverflow: 'ellipsis' }}></div>
                                </button>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }


    const banks_wallets_content = () => {
        return (
           <div style={{width: '100%'}}>
               {
                    banks_ewallets_choices.map((item, i) => {
                        return (
                            item.enabled &&
                            <div item style={{width: '100%'}}>
                                <button
                                    id={item.code}
                                    style={{ width: '100%', height: isMediaMobile? '100%' : '92px', marginTop: i !== 0 && 20}}
                                    className={channel == item.code ? 'otc-button-selected' : 'otc-button-not-selected'}
                                    onClick={() => setChannel(item.code) / validatePromoCode(promoCode, paymentObj && paymentObj.segment, item.code) / setDetailPaymentMethod(item.name)}>
                                 <div style={{display: 'flex', justifyContent: 'flexStart', alignItems: 'center', width: '100%', height: isMediaMobile && '100%', flexDirection: isMediaMobile ? 'column' : 'row'}}>
                                    <div style={{width: isMediaMobile? '100%' : '25%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: isMediaMobile && '100%', padding: isMediaMobile && '12px 8px'}}>
                                        <div style={{ position: 'absolute', left: 16 }} >
                                                {
                                                    channel != item.code ?
                                                        <Radio />
                                                        :
                                                        <img src={IconCheck} alt="check"/>
                                                }
                                            </div>
                                        <div style={{display: 'flex', alignItems: 'center', flexDirection: isMediaMobile ? 'row' : 'column', justifyContent: 'center'}}>
                                                <img src={item.icon}
                                                    alt="icon"
                                                    style={{
                                                        maxWidth: '82%',
                                                        // marginBottom: isMobile && item.code === 'RDP' ? 4 : '10px'
                                                    }} />
                                                <span style={{ color: 'rgba(43, 45, 50, 0.64)', fontWeight: '500', fontSize: '10px', paddingLeft: isMediaMobile && '4px' }} >{item.code === instapay_payment_channel ? '1 Business Day' : '1-2 Business Days'}</span>
                                        </div>
                                            {/* <div style={{ ...styles.normalText, color: '#000', lineHeight: isMobile && item.code === 'RDP' && '14px' }}>
                                                {item.name}
                                            </div> */}
                                    </div>
                                    <Divider type={isMediaMobile? 'horizontal' : 'vertical'} style={{width: isMediaMobile? '103%' : '1px', height: !isMediaMobile && '92px', margin: 0}} />
                                    <div style={{width: isMediaMobile? '100%' : '75%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: isMediaMobile && '20px 8px', position: 'relative'}}>
                                        <img width={isMediaMobile? '100%' : '75%'} alt={item.code} src={item.groupIcon} />
                                        <a onClick={() => setBankModalVisibility(true)} hidden={item.code === 'paygate'} style={{
                                              position: 'absolute',
                                              top: isMediaMobile? '46%' : '42%',
                                              right: '17%',
                                              height: isMediaMobile? '28%' : '20px',
                                              width: '10vw',
                                              transform: 'translate(50%, 50%)',
                                        }}></a>
                                    </div>
                                 </div>
                                </button>
                            </div>
                        )
                    })
               }
                <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', width: '100%', marginTop: '30px'}}>
                    <img alt='Banks and E-wallets' src={Icon.BanksEwallerPoweredBy} />
                </div>
           </div>
        )
    }



    const card_payment = []
    
    if(xendit_enabled && !paymentObj.disabled_channels.includes('visamc') && userType != 'SW'){
        card_payment.push(
            {
                code: "visamc",
                enabled: true,
                icon: Icon.Xendit,
                name: 'Visa/MasterCard'
            },
        )
    }

    else if(ubp_enabled && !paymentObj.disabled_channels.includes('ubpcs') && userType != 'SW'){
        card_payment.push(
            {
                code: "ubpcs",
                enabled: true,
                icon: Icon.VISAMastercardLogo,
                name: 'VISA/MASTERCARD'
            },
        )
    }

    const card_content = () => {
        return (
            <div className='checkout-channel-card-wrapper' > {
            card_payment.map((item, i) => {
                return (
                    item.enabled &&
                    <div div className='checkout-channel-card'  item>
                        <button
                            id={item.code}
                            className={channel == item.code ? 'otc-button-selected' : 'otc-button-not-selected'}
                            onClick={() => setChannel(item.code) / validatePromoCode(promoCode, paymentObj && paymentObj.segment, item.code) / setDetailPaymentMethod(item.name)}>
                            <div style={{ position: 'absolute', left: 16 }} >
                                {
                                    channel != item.code ?
                                        <Radio />
                                        :
                                        <img src={IconCheck} alt="check"/>
                                }
                            </div>
                            <img src={item.icon}
                                alt="icon"
                                style={{
                                    maxWidth: '48%',
                                    // marginBottom: isMobile && item.code === 'RDP' ? 4 : '10px'
                                }} />
                            {/* <div style={{ ...styles.normalText, color: '#000', lineHeight: isMobile && item.code === 'RDP' && '14px' }}>
                                {item.name}
                            </div> */}
                        </button>
                    </div>
                )
            })
            }
        </div>
        )
    }

    const bnpl = [
        {
            code: "billease",
            enabled: bnpl_enabled && !testMode,
            icon: Icon.Billease,
            name: 'Billease'
        }
    ]

    const bnpl_content = () => {
        return (
            <div className='checkout-channel-card-wrapper' >
                {
                    !testMode && bnpl.map((item, i) => {
                        return (
                            item.enabled &&
                            <div div className='checkout-channel-card' >
                                <button
                                    className={channel == item.code ? 'ol-ew-btn-selected' : 'ol-ew-btn-not-selected'}
                                    onClick={() => setChannel(item.code) / validatePromoCode(item.code, paymentObj && paymentObj.segment, channel) / setDetailPaymentMethod(item.name)}
                                    disabled={!item.enabled}
                                >
                                    <div style={{ position: 'absolute', left: 16 }} >
                                        {
                                            channel != item.code ?
                                                <Radio />
                                                :
                                                <img src={IconCheck} alt="check"/>
                                        }
                                    </div>
        
                                    <img alt="icon" src={item.icon} style={{ maxWidth: '48%', marginLeft: 15, height: 36 }} />
                                    <div style={{ marginTop: '14px', color: '#000000', textoverflow: 'ellipsis' }}></div>
                                </button>
                            </div>
                        )
                    })
                }
            </div>
        )

    }

    const paymentMethodList = [
        {
            hidden: paymentDetails.payment.amount > 80000 ? true : otcCount === 0 ? true : false,
            code: 'OTC',
            text: 'Over-the-counter',
            iconSelected: Icon.OTC_Selected,
            icon: Icon.OTC_Selected,
            content: channelContent()
        },
        {
            hidden: paymentDetails.payment.amount > 80000 ? true : banksDisabled,
            code: 'WB',
            text: 'Online Banking',
            iconSelected: Icon.WB_Selected,
            icon: Icon.WB_Selected,
            content: web_content()
        }, 

    ]

    paymentMethodList.push(
        {
            hidden: paymentDetails.payment.amount > 80000 || (!gcash_enabled && !grabpay_enabled && !maya_enabled),
            code: 'EW',
            text: 'E-Wallets',
            iconSelected: Icon.EW_Selected,
            icon: Icon.EW_Selected,
            content: ewallet_content()
        }
    )

    paymentMethodList.push(
        {
            hidden: card_payment.length ===  0 || (paymentObj && paymentObj.user_role === "SW" && paymentObj.has_cashbux_privilege === false) || paymentObj && paymentObj.user_role === "ME",
            code: 'CC',
            text: 'Credit / Debit Cards',
            iconSelected: Icon.CC_Selected,
            icon: Icon.CC,
            content: card_content()
        }
    )

    paymentMethodList.push(
        {
            hidden: (paymentObj.disabled_channels && banks_ewallets_choices.every(option => paymentObj.disabled_channels.includes(option.code))) || testMode,
            code: 'BEW',
            text: 'Banks and E-wallets',
            iconSelected: Icon.CC_Selected,
            icon: Icon.BanksEwalletsIcon,
            content: banks_wallets_content()
        }
    )


    if (bnpl_enabled && (paymentDetails && paymentDetails.payment.amount >= 500)) {
        let bn_content = snpl_enabled ? 'Study Now Pay Later' : 'Buy Now Pay Later';
        paymentMethodList.push(
            {
                code: 'BNPL',
                text: bn_content,
                iconSelected: Icon.BNPL_Selected,
                icon: Icon.BNPL_Selected,
                content: bnpl_content()
            }
        )
    }

    const validateAmount = (amount) => {
        if ( amount < 50 ) {
            return 50;
        } 
        return amount;
    }

    const addSurcharge = (bank, surcharge) => {
        if(bank) {
            return parseInt(surcharge);
        }
        return 0;
    }
    
    const renderSurcharge = (surcharge) => {
        return (   
            list_bank.includes(channel) && surcharge &&
            <div
                style={{
                    background: 'rgba(244, 193, 10, 0.16)',
                    border: '1px solid #F4B24A',
                    borderRadius: 2,
                    fontSize: 14,
                    fontWeight: '400',
                    width: '100%',
                    display: 'inline-flex',
                    alignItems: 'center',
                    padding: '4px 0px 4px 12px',
                    marginTop: 10
                }}
            >
                <Icons type="exclamation-circle" theme="filled" 
                    style={{
                        color: '#F4B24A',
                        border: '1px solid #F4B24A',
                        borderRadius: 25,
                        backgroundColor: '#FFF',
                        fontSize: 12,
                        marginRight: 6
                    }} />
                <span>Separate ₱{(surcharge)} {detailPaymentMethod} surcharge may apply</span>
            </div>
        )
    }

    const computeDiscount = () => {
        const generatedAmount = channel && !paymentDetails.payment.shoulder_enabled ? (parseFloat(paymentDetails.payment.amount) + parseFloat(paymentDetails.payment.fees[channel])) : (paymentDetails.payment.amount);
        const totalDeductionAmount = promoType === 'Fixed'? promoAmount : ((promoAmount * generatedAmount)/ 100);

        const discountedAmount = promoType === 'Fixed' ? (generatedAmount - promoAmount) : ( generatedAmount - (promoAmount * generatedAmount)/ 100);

        if ( discountedAmount < promoMinAmount ) {
            
            if(((discountedAmount - promoMinAmount) + totalDeductionAmount) > promoMaxAmount) {
                return promoMaxAmount;
            }
          return (discountedAmount - promoMinAmount + totalDeductionAmount);
        } 

        return totalDeductionAmount > promoMaxAmount ? promoMaxAmount : totalDeductionAmount;
    }

    const returnSegmentType = (type) => {
        switch (type) {
            case 'CO':
                return 'SME';
            case 'ME':
                return 'ME';
            default:
                return type;
        }
    }

    const onPromoCodeChange = (e) => {
        console.log(e.target.value.length);
        if (e.target.value.length === 0) {
            setPromoCode('');
            setPromoCodeStatus([]);
            setPromoAmount(0);
        } else {
            setPromoCode((e.target.value).toUpperCase());
        }
    }

    const onApplyPromo = (code) => {
        let promo_code = promoCode;
        if(code && (typeof code !== 'object')) {
            setPromoCode((code).toUpperCase());
            promo_code = code;
        }
        setCheckoutPromoModal(false);
        validatePromoCode(promo_code, paymentObj && paymentObj.segment, channel);
    }

    const PromoCodeInput = () => (
        <div hidden={ paymentObj && paymentObj.segment && paymentObj.segment !== 'ME_' && paymentObj && paymentObj.segment && paymentObj.segment !== 'GIG' || testMode}>
            <hr style={{ borderTop: '1px dashed gray', margin: '20px 0px 20px 0px' }}></hr>
            <Typography style={{ ...styles.promoFont, fontSize: 14, opacity: '.8', paddingBottom: 4 }} >Enter Promo Code</Typography>
            <div style={styles.inputDiv}>
                <Input
                    placeholder='Enter Promo Code'
                    size='large'
                    // addonAfter={<Icons type='close' hidden={!promoCode} onClick={() => setPromoCode('')} />}
                    onChange={onPromoCodeChange}
                    value={promoCode}
                    allowClear
                    style={{ ...styles.promoFont, borderRadius: 4, border: promoCodeStatus && promoCodeStatus.status === 'valid' ? '2px solid #59C378' : promoCodeStatus && promoCodeStatus.status === 'invalid' ? '2px solid #C0492C' : null }}
                />
                <Button style={styles.applyPromoBtn} onClick={onApplyPromo}>Apply</Button>
            </div>
            <Typography style={{
                ...styles.promoFont, fontSize: 14, opacity: promoCodeStatus && promoCodeStatus.status === 'valid' && '.8',
                color: promoCodeStatus && promoCodeStatus.status !== 'valid' && '#C0492C'
            }} hidden={!promoCode}
            >{promoCodeStatus && promoCodeStatus.message}</Typography>
            <div hidden={promoCodeStatus && promoCodeStatus.status !== 'valid'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 16 }}>
                <Typography style={{ ...styles.promoFont }}>Promo Code</Typography>
                <Typography style={{ ...styles.promoFont, color: '#C0492C', fontWeight: '500' }}>
                    -₱{
                        parseFloat(computeDiscount()).toLocaleString('en-US', { minimumFractionDigits: 2 })
                }</Typography>
            </div>
            <div hidden={promoCodeStatus && promoCodeStatus.status !== 'valid'}
                style={{display: 'flex', alignItems: 'center', padding: '8px 0 0 0'}}>
                <Icons type="exclamation-circle" theme="filled" style={styles.exclamation} />
                <Typography style={{fontSize: 12, color: '#2B2D32'}}><b>{promoType === 'Fixed'? '₱' + parseFloat(promoAmount).toFixed(2) : promoAmount + '%' }</b> OFF with min amount of <b>₱ {promoMinAmount.toFixed(2)}</b></Typography>
            </div>
        </div>
    )

    const descriptionContent = () => {
        return (
        <>
            <div style={{ ...styles.boldText, marginTop: '36px', color: '#000' }}>Description</div>
            <div style={{ ...styles.normalText, fontSize: !isMediaMobile && '16px', marginTop: '5px', color: '#000' }}>{paymentObj.description}</div>
            {getParam1Param2()}
        </>
        );
    }

    const getParam1Param2 = () => {
        const payload = paymentObj.payload;
        
        let param1Name = "Param 1";
        let param1Value = "";
        let param2Name = "Param 2";
        let param2Value = "";

        if (payload && (payload.param1 || payload.param2)) {
            
            if (typeof payload.param1 === "object" || typeof payload.param2 === "object") {
                param1Name = (payload.param1 && payload.param1.name && payload.param1.name != "") ? payload.param1.name : "Param 1";
                param1Value = (payload.param1 && payload.param1.value) ? payload.param1.value : "";
                param2Name = (payload.param2 && payload.param2.name && payload.param2.name != "") ? payload.param2.name : "Param 2";
                param2Value = (payload.param2 && payload.param2.value) ? payload.param2.value : "";
            } else if (typeof payload.param1 === "string" || typeof payload.param2 === "string") {
                param1Value = payload.param1;
                param2Value = payload.param2;
            }
            
            return (  
                <>
                    <hr style={{ borderTop: '1px dashed gray', margin: '32px 0px 20px 0px' }}></hr>

                    {payload.param1 && 
                    <>
                        <div style={{ ...styles.infoRow }}>
                            <div style={{ ...styles.normalText, ...styles.infoLabel }}>{param1Name}</div><div style={{ ...styles.semiBoldText, ...styles.infoValue }}>{param1Value}</div>
                        </div>
                    </>
                    }

                    {payload.param2 && 
                    <>
                        <div style={{ ...styles.infoRow }}>
                            <div style={{ ...styles.normalText, ...styles.infoLabel }}>{param2Name}</div><div style={{ ...styles.semiBoldText, ...styles.infoValue }}>{param2Value}</div>
                        </div>
                    </>
                    }   
                    
                </>
            );
        }

        return <></>;
        
    }

    return (
        <div>
            {
            (paymentDetails.payment.generate_payment_link_enabled === false) ? <PageNotFound/> :
            (paymentDetails.payment.code && paymentDetails.payment.code != null) || (paymentDetails.payment.status && paymentDetails.payment.status != 'Pending') ? <Redirect to={testMode?`/test/payment/${uid}`:`/payment/${uid}`} payment/>
            :
            <div>
                <div className="wrapper">
                    <MetaTags>
                        <title>
                            BUx | Checkout
                        </title>

                        <meta
                            name="description"
                            content={paymentDetails.payment.description ? paymentDetails.payment.description : "Please proceed to the nearest branch and present this barcode or reference number to the cashier."}
                        />
                        <meta property="og:description" content={paymentDetails.payment.description} />
                        {paymentDetails.payment.image_urInputl ? <meta property="og:image" content={paymentDetails.payment.image_url} /> : <meta property="og:image" content="" />}

                    </MetaTags>
                </div>
                <Header hidden={!isMediaMobile} style={{ zIndex: '100', position: 'fixed', top: 0, width: '100%', height: '60px', display: 'flex', justifyContent: 'center', background: testMode? '#454545' : 'linear-gradient(102.61deg, #004888 0%, #00BBFF 100%)', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)' }}>
                    <img src={BUx} style={{ maxWidth: '72px' }} alt="#" />
                    <Typography hidden={!testMode} style={{ position: 'absolute', right: '22px', lineHeight: '60px', fontSize: 16, fontWeight: '500', color: '#FFF', textAlign: 'right'}}>Test Mode</Typography>
                </Header>
                <div hidden={isMediaMobile} style={{ zIndex: '1', position: 'fixed', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '24px 0'}}>
                    <img src={BUx} alt="BUx" />
                </div>
                {/* {isMobile && <TestModeBanner width={'100%'} top={60} />} */}
                <div
                    align="center"
                    style={{ margin: 0, position: 'absolute', top: '50%', left: '50%', transform: 'translateY(50%, 50%)' }}
                    hidden={paymentDetails.payment.amount}>
                    <Spin size="large" />
                </div>
                <div hidden={isMediaMobile || !testMode} style={{ zIndex: '99', background: '#312C2C', borderRadius: '30px', width: '124px', height: '44px', position: 'absolute', right: '106px', display: 'flex', alignItems: 'center', justifyContent: 'center', top: '46px' }} >
                    <Typography style={{color: '#FFF', fontSize: 18, fontWeight: '500'}}>Test Mode</Typography>
                </div>
                <div hidden={!paymentDetails.payment.amount} style={styles.contentDiv}>
                    <div style={styles.paymentDiv}>
                        <div hidden style={styles.expirationDiv}>
                            <div>
                                <img src={Icon.Clock} alt="clock" hidden={isMediaMobile} />
                                <span style={{ ...styles.semiBoldText, fontSize: isMediaMobile && '12px', color: '#000', marginLeft: '10px' }}>Payment link will expire on: </span>
                                <span style={{ ...styles.normalText, color: '#54575F', textAlign: 'right' }}>{paymentDetails.payment.expiry}</span>
                            </div>
                        </div>
                        <div style={styles.paymentMethodDiv}>
                            <div style={{ ...styles.header, color: '#2B2D32', marginBottom: '20px', textAlign: 'center' }}>Choose Payment Method</div>
                            {
                                paymentMethodList.map((item, i) => {
                                    return (
                                        <Card key={i}
                                            hidden={item.hidden}
                                            bodyStyle={{ padding: '0px' }}
                                            style={styles.paymentMethodCard}
                                        >

                                            <div style={{
                                                // borderRadius: paymentMethod == item.code && i != 2 ? '10px 10px 0px 0px' : '10px 10px 10px 10px',
                                                padding: '10px 0px',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                backgroundColor: '#FFFFFF',
                                            }}>
                                                <div style={{ backgroundColor: '#E9EEF4', padding: '12px 20px', width: '100%', cursor: isMediaMobile? 'pointer' : 'default', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                                    onClick={() => {
                                                        setPaymentMethod(item.code === paymentMethod ? '' : item.code)
                                                        if(item.code === 'CC'){
                                                            let cc_channel = xendit_enabled ? 'visamc' : ubp_enabled ? 'ubpcs' : ''
                                                            setChannel(cc_channel)
                                                            setDetailPaymentMethod(item.name);
                                                        }
                                                    }}
                                                >
                                                    {/* <img src={paymentMethod == item.code ? Icon.Circle_Selected : Icon.Circle} style={{ marginRight: '17px' }} /> */}
                                                   <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <img src={paymentMethod == item.code ? item.icon : item.icon} style={{ marginRight: '10px', color: paymentMethod == item.code ? '#2B2D32' : '#2B2D32' }} />
                                                        <span
                                                            style={{
                                                                fontWeight: '500',
                                                                color: paymentMethod == item.code ? '#2B2D32' : '#2B2D32'
                                                            }}>
                                                            {item.text}
                                                        </span>
                                                   </div>
                                                   <div hidden={!isMediaMobile}>
                                                       <Icons type={item.code === paymentMethod? 'up' : 'down'} />
                                                   </div>
                                                </div>
                                                {/* <div style={styles.new} hidden={i != 3}>
                                                    New
                                                </div> */}
                                            </div>
                                            <Grid
                                                spacing={1}
                                                container
                                                hidden={ !isMediaMobile ? false : mobileDetailsShow? true : paymentMethod != item.code}
                                                style={{ padding: '20px 2px 34px 2px', width: '100%'
                                                        // display: 'flex', justifyContent: !isMobile && item.code === 'OTC' ? 'flex-start' : 'flex-start',
                                                        // flexDirection: !isMobile && item.code === 'BEW' && 'column' 
                                                    }}
                                            >
                                                {item.content}
                                            </Grid>
                                        </Card>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div hidden={isMediaMobile} style={{ width: isMediaMobile ? '100%' : '30%', minWidth:'min-content', paddingBottom: '32px', backgroundColor: '#fff', marginBottom: isMediaMobile && '173px', borderRadius: isMediaMobile? '0' : 12, height: '100%', zIndex: '2', }}>
                        {/* <div hidden={isMobile} style={styles.webHeader}>
                            <img src={WebBux} style={{ height: '32px' }} onClick={() => history.push('/dashboard')} />
                        </div> */}
                        {/* {!isMobile && <TestModeBanner width={'25%'} top={82} />} */}
                        <div style={styles.infoDiv}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ display: 'flex' }}>
                                    <img style={{ width: '36px', height: '36px', borderRadius: paymentObj.profile_pic ? '50%' : '0%' }} src={paymentObj.profile_pic ? paymentObj.profile_pic : profile} alt="#" />
                                    <div style={{ ...styles.owner, margin: '5px 0px 0px 10px', maxWidth: 154, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{paymentObj.owner}</div>
                                </div>
                            </div>
                            <Divider />
                            {descriptionContent()}
                           {
                                paymentDetails.payment.cart ?
                                    paymentDetails.payment.cart.length != 0 ?
                                        <div>
                                            <hr style={{ borderTop: '1px dashed gray', margin: '20px 0px 20px 0px' }}></hr>
                                            <div style={{ ...styles.boldText, marginTop: '20px', color: '#000' }}>Item/s: {paymentDetails.payment.cart.length}</div>
                                            {
                                                paymentDetails.payment.cart.map((item, i) => {
                                                    return (
                                                        <div key={i} style={{ marginTop: '20px' }}>
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between'
                                                            }}>
                                                                <div style={{ ...styles.semiBoldText, color: '#000' }}>
                                                                    {item.name}
                                                                </div>
                                                                <div style={{ ...styles.semiBoldText, ...styles.infoValue }}>
                                                                    ₱{parseFloat(item.amount).toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                                    }</div>
                                                            </div>
                                                            <div>
                                                                QTY: <strong>{item.quantity}</strong>
                                                            </div>
                                                            <Divider style={{ margin: '15px 0px -5px 0px' }} hidden={paymentDetails.payment.cart.length == i + 1} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div> : "" : ""
                            }
                            <hr style={{ borderTop: '1px dashed gray', margin: '20px 0px' }}></hr>
                            <div style={{ ...styles.infoRow }}>
                                <div style={{ ...styles.normalText, ...styles.infoLabel }}>Payment Link expiration</div><div style={{ ...styles.semiBoldText, ...styles.infoValue }}>{paymentDetails && moment(paymentDetails.payment.expiry).format('LLL')}</div>
                            </div>
                            <hr hidden={!detailPaymentMethod} style={{ borderTop: '1px dashed gray', margin: '20px 0px 20px 0px' }}></hr>
                            <div hidden={!detailPaymentMethod} style={{ width: '100%' }}>
                                <div style={{ ...styles.infoRow }}>
                                    <div style={{ ...styles.normalText, ...styles.infoLabel }}>Payment Method</div><div style={{ ...styles.semiBoldText, ...styles.infoValue }}>{detailPaymentMethod}</div>
                                </div>
                                {renderSurcharge(sur)}
                            </div>
                            <hr style={{ borderTop: '1px dashed gray', margin: '20px 0px 20px 0px' }}></hr>
                            <div style={{ ...styles.infoRow }}>
                                <div style={{ ...styles.normalText, ...styles.infoLabel }}>Sub Total</div><div style={{ ...styles.semiBoldText, ...styles.infoValue }}>₱{parseFloat(paymentObj.amount).toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
                            </div>
                            {
                                !paymentDetails.payment.shoulder_enabled && paymentDetails.payment.fees &&
                                <div style={{ ...styles.infoRow, marginTop: '22px' }} hidden={paymentObj.shoulder_enabled}>
                                    <div style={{ ...styles.normalText, ...styles.infoLabel }}>Transaction Fee</div><div style={{ ...styles.semiBoldText, ...styles.infoValue }}>₱{channel == "" || channel == "wallet" || channel == "bank" ? '0.00' : parseFloat(paymentDetails.payment.fees[channel]).toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
                                </div>
                            }
                            {PromoCodeInput()}
                            <Button onClick={() => setCheckoutPromoModal(true)}  hidden={ paymentObj && paymentObj.segment && paymentObj.segment !== 'ME_' && paymentObj && paymentObj.segment && paymentObj.segment !== 'GIG' || testMode}
                                style={{height: '40px', border: '1px solid #3A73CC', width: '100%', marginTop: '12px', color: '#3A73CC', fontWeight: '700', fontSize: '16px'}}>
                                <img alt='promo' src={PromoLogo} style={{position: 'absolute', left: '8px', top: '8px'}} />
                                <label>See available voucher</label>
                            </Button>
                            {
                                list_bank.includes(channel) &&
                                <div hidden style={{ ...styles.infoRow, margin: '20px 0px 20px 0px' }}>
                                    <div style={{ ...styles.normalText, ...styles.infoLabel }}>{channel == "BPIA" ? "BPI" : channel == "UBPB" ? "UBP" : channel == 'MBTC' ? "Metrobank": channel}Surcharge</div>
                                    <div style={{ ...styles.semiBoldText, ...styles.infoValue }}>+ ₱{parseFloat(sur).toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
                                </div>

                            }
                            <div style={{ ...styles.normalText, color: '#2b2d33', marginTop: '20px' }} hidden={!['payngo', 'posible', 'MLH'].includes(channel) || !isMediaMobile}>
                                <b>Note:</b> Additional surcharge fee of <b>Php {channel === 'payngo' ? '10.00' : channel === 'posible' ? '12.00' : '20.00'}</b> on the top of the amount may apply.
                            </div>


                            {
                                !isMediaMobile &&
                                <div>
                                    <hr style={{ borderTop: '1px dashed gray', margin: '20px 0px 20px 0px' }}></hr>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ ...styles.header, color: '#2B2D32', fontSize: 18, fontWeight: 'normal' }}>Total</div><div style={{ ...styles.owner, color: '#2B2D32'}}> ₱
                                            {channel === "bank" || channel === "wallet" ?
                                                validateAmount(parseFloat(paymentDetails.payment.amount - computeDiscount())).toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                :
                                                channel && !paymentDetails.payment.shoulder_enabled ?
                                                (validateAmount(parseFloat(paymentDetails.payment.amount) + paymentDetails.payment.fees[channel] - computeDiscount())).toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                :
                                                validateAmount(parseFloat(paymentDetails.payment.amount - computeDiscount())).toLocaleString('en-US', { minimumFractionDigits: 2 })
                                            }
                                        </div>
                                    </div>
                                    <div style={{ ...styles.normalText, color: '#2b2d33', fontSize: '12px', marginTop: '20px' }} hidden={!['payngo', 'posible', 'MLH'].includes(channel)}>
                                        <b>Note:</b> Additional surcharge fee of <b>Php {channel === 'payngo' ? '10.00' : channel === 'posible' ? '12.00' : '20.00'}</b> on the top of the amount may apply.
                                    </div>
                                    <div style={styles.loanText} hidden={channel !== 'billease'}>
                                        <a href="https://billease.ph" target="blank" style={styles.loanLinkText}>BillEase Loan Calculator.</a>
                                    </div>
                                    <div style={{ ...styles.noteText, marginTop: '20px' }}>
                                        By clicking you agree to our <a href="/terms_and_privacy" target="_blank" style={{ color: '#2b2d33', textDecoration: 'underline' }}>Terms & Conditions</a> and <a target="_blank" href="/terms_and_privacy" style={{ color: '#2b2d33', textDecoration: 'underline' }}>Privacy Policy</a>, entailing that BUx is not responsible for nor does it ensure the delivery, performance or fulfillment of the goods or service that you are paying for. BUx only ensures that your payment is processed seamlessly and safely.
                                    </div>
                                    <Button loading={loading} onClick={() => payNow()} style={{ ...styles.payBtn, marginTop: '20px' }}>Pay Now</Button>
                                    <div style={styles.securedLogo}>
                                        <img style={styles.securedLogoImg} src={IconSecured} alt="Secured Bux"/>
                                    </div>
                                    <div style={{ ...styles.semiBoldText, color: '#54575F', marginTop: '50px', textAlign: 'center' }}>Need Help? <a href="mailto:support@bux.ph" style={{ color: '#F5922F', textDecoration: 'underline' }}>Contact Us</a></div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
                <div style={{...styles.mobileFooter, boxShadow: mobileDetailsShow? 'none' : '0px -7px 10px rgba(0, 0, 0, 0.14)'}} hidden={!isMediaMobile || (paymentDetails && paymentDetails.payment && !paymentDetails.payment.amount)}>
                    <hr hidden={!mobileDetailsShow} style={{ borderTop: '1px dashed gray', margin: '20px 0px 20px 0px' }}></hr>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <div style={{ fontSize: '18px', fontWeight: 'normal', color: '#2B2D32' }}>Total</div>
                            <div style={{ fontSize: '20px', fontWeight: 'bold', color: '#2B2D32', paddingLeft: 12 }}>₱
                            {channel === "bank" || channel === "wallet" ?
                                validateAmount(parseFloat(paymentDetails.payment.amount - computeDiscount())).toLocaleString('en-US', { minimumFractionDigits: 2 })
                                :
                                channel && !paymentDetails.payment.shoulder_enabled ?
                                (validateAmount(parseFloat(paymentDetails.payment.amount) + paymentDetails.payment.fees[channel] - computeDiscount())).toLocaleString('en-US', { minimumFractionDigits: 2 })
                                :
                                validateAmount(parseFloat(paymentDetails.payment.amount - computeDiscount())).toLocaleString('en-US', { minimumFractionDigits: 2 })
                            }
                            </div>
                      </div>
                      <Button type='link' style={{color: '#F5922F', fontWeight: '700', fontSize: '16px'}} onClick={() => setMobileDetailsShow(!mobileDetailsShow)}>
                          { mobileDetailsShow? 'Hide Summary' : 'Show Summary'}
                          <Icons type={mobileDetailsShow ? 'down' : 'up'} />
                      </Button>
                    </div>
                    <div style={styles.loanText} hidden={channel !== 'billease'}>
                        <a href="https://billease.ph" target="blank" style={styles.loanLinkText}>BillEase Loan Calculator.</a>
                    </div>
                    <Button loading={loading} onClick={() => payNow()} style={{ ...styles.payBtn, marginTop: '10px' }}>Pay Now</Button>
                    <div style={{ fontSize: '12px', fontWeight: '400', color: '#000', textAlign: 'center', margin: '15px 0px 15px 0px' }}>
                        By clicking you agree to our the <a href="/terms_and_privacy" style={{ color: '#F5922F' }}>Terms & Conditions</a> and <a href="/terms_and_privacy" style={{ color: '#F5922F' }}>Privacy Policy.</a>
                    </div>
                </div>
                
                <UpayList channel={channel} modalOpen={bankModalVisibility} modalClose={setBankModalVisibility} instaList={INSTAPAY_LIST} pchcList={PCHC_LIST} />
                <DisclaimerModal hasDisclaimer={hasDisclaimer} showModal={modalVisibility} hideModal={setModalVisibility} channel={channel} proceed={proceed} />
                <InstaPayModalDisclaimer modalVisibility={instapayModalVisibility} setModalVisibility={setInstapayModalVisibility} channel={channel} proceed={proceed} />

                <Modal
                    width={360}
                    centered
                    visible={ccModal}
                    onCancel={() => setShowCCModal(false)}
                    footer={null}
                    bodyStyle={{ padding: 0, height: 450 }}
                >
                    <iframe height="450" width="360" id="sample-inline-frame" name="sample-inline-frame"> </iframe>
                </Modal>
                <Drawer
                    title="Basic Drawer"
                    placement="bottom"
                    closable={false}
                    onClose={() => setMobileDetailsShow(false)}
                    visible={mobileDetailsShow}
                    getContainer={false}
                    drawerStyle={{
                        paddingBottom: '64px'
                    }}
                    height='100%'
                    zIndex={90}
                    // style={{ position: 'absolute' }}
                    >
                    <div style={styles.infoDiv}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ display: 'flex' }}>
                                <img style={{ width: '36px', height: '36px', borderRadius: paymentObj.profile_pic ? '50%' : '0%' }} src={paymentObj.profile_pic ? paymentObj.profile_pic : profile} alt="#" />
                                <div style={{ ...styles.owner, margin: '5px 0px 0px 10px', maxWidth: 154, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{paymentObj.owner}</div>
                            </div>
                        </div>
                        <Divider />
                        {descriptionContent()}
                        {
                            paymentDetails.payment.cart ?
                                paymentDetails.payment.cart.length != 0 ?
                                    <div>
                                        <hr style={{ borderTop: '1px dashed gray', margin: '20px 0px 20px 0px' }}></hr>
                                        <div style={{ ...styles.boldText, marginTop: '20px', color: '#000' }}>Item/s: {paymentDetails.payment.cart.length}</div>
                                        {
                                            paymentDetails.payment.cart.map((item, i) => {
                                                return (
                                                    <div key={i} style={{ marginTop: '20px' }}>
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between'
                                                        }}>
                                                            <div style={{ ...styles.semiBoldText, color: '#000' }}>
                                                                {item.name}
                                                            </div>
                                                            <div style={{ ...styles.semiBoldText, ...styles.infoValue }}>
                                                                ₱{parseFloat(item.amount).toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                                }</div>
                                                        </div>
                                                        <div>
                                                            QTY: <strong>{item.quantity}</strong>
                                                        </div>
                                                        <Divider style={{ margin: '15px 0px -5px 0px' }} hidden={paymentDetails.payment.cart.length == i + 1} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div> : "" : ""
                        }
                        <hr style={{ borderTop: '1px dashed gray', margin: '32px 0px 20px 0px' }}></hr>
                        <div style={{ ...styles.infoRow }}>
                            <div style={{ ...styles.normalText, ...styles.infoLabel }}>Payment Link expiration</div><div style={{ ...styles.semiBoldText, ...styles.infoValue }}>{paymentDetails && moment(paymentDetails.payment.expiry).format('LLL')}</div>
                        </div>
                        <hr hidden={!detailPaymentMethod} style={{ borderTop: '1px dashed gray', margin: '20px 0px 20px 0px' }}></hr>
                        <div hidden={!detailPaymentMethod} style={{ width: '100%' }}>
                            <div style={{ ...styles.infoRow }}>
                                <div style={{ ...styles.normalText, ...styles.infoLabel }}>Payment Method</div><div style={{ ...styles.semiBoldText, ...styles.infoValue }}>{detailPaymentMethod}</div>
                            </div>
                            {renderSurcharge(sur)}
                        </div>
                        <hr style={{ borderTop: '1px dashed gray', margin: '20px 0px 20px 0px' }}></hr>
                        <div style={{ ...styles.infoRow }}>
                            <div style={{ ...styles.normalText, ...styles.infoLabel }}>Sub Total</div><div style={{ ...styles.semiBoldText, ...styles.infoValue }}>₱{parseFloat(paymentObj.amount).toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
                        </div>
                        {
                            !paymentDetails.payment.shoulder_enabled && paymentDetails.payment.fees &&
                            <div style={{ ...styles.infoRow, marginTop: '22px' }} hidden={paymentObj.shoulder_enabled}>
                                <div style={{ ...styles.normalText, ...styles.infoLabel }}>Transaction Fee</div><div style={{ ...styles.semiBoldText, ...styles.infoValue }}>₱{channel == "" || channel == "wallet" || channel == "bank" ? '0.00' : parseFloat(paymentDetails.payment.fees[channel]).toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
                            </div>
                        }
                        {PromoCodeInput()}
                        <Button onClick={() => setCheckoutPromoModal(true)}  hidden={ paymentObj && paymentObj.segment && paymentObj.segment !== 'ME_' && paymentObj && paymentObj.segment && paymentObj.segment !== 'GIG' || testMode}
                            style={{height: '40px', border: '1px solid #3A73CC', width: '100%', marginTop: '12px', color: '#3A73CC', fontWeight: '700', fontSize: '16px'}}>
                            <img alt='promo' src={PromoLogo} style={{position: 'absolute', left: '8px', top: '8px'}} />
                            <label>See available voucher</label>
                        </Button>
                        {
                            list_bank.includes(channel) &&
                            <div hidden style={{ ...styles.infoRow, margin: '20px 0px 20px 0px' }}>
                                <div style={{ ...styles.normalText, ...styles.infoLabel }}>{channel == "BPIA" ? "BPI" : channel == "UBPB" ? "UBP" : channel == 'MBTC' ? "Metrobank": channel} Surcharge</div>
                                <div style={{ ...styles.semiBoldText, ...styles.infoValue }}>+ ₱{parseFloat(sur).toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
                            </div>

                        }
                        <div style={{ ...styles.normalText, color: '#2b2d33', marginTop: '20px' }} hidden={!['payngo', 'posible', 'MLH'].includes(channel) || !isMediaMobile}>
                            <b>Note:</b> Additional surcharge fee of <b>Php {channel === 'payngo' ? '10.00' : channel === 'posible' ? '12.00' : '20.00'}</b> on the top of the amount may apply.
                        </div>
                    </div>
                </Drawer>
                <CheckoutPromoModal visible={checkoutPromoModal} 
                    onClose={() => setCheckoutPromoModal(false)} uid={uid}
                    applyVoucher={(code) => {setPromoCode((code).toUpperCase()); onApplyPromo(code)} } />
            </div>
        }  
        </div>
    )
}

export default Checkout
