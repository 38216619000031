import React from 'react';
import { Progress, Icon, Button } from 'antd';
import { isMobile } from 'react-device-detect';
import profile from "../../../static/icons/profile.svg";
import complete_icon from "../../../static/icons/sidebar/complete_profile.svg";
import person from "../../../static/icons/person.svg";
import w_profile from "../../../static/icons/white_profile.svg";
import { history } from '../../../store/history';
import { Style } from './style';

const MerchantHeader = props => {

    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const remove_part = `.${process.env.REACT_APP_SUB_URL}`;
    const trade_name = window.location.hostname.replace(remove_part, "");

    const { userType, profilePicture, userKYCLevel, tradeName, current_step, industry, username, fname, lname, isChild } = props.datas;
    const { collapsed } = props;
    const level = localStorage.getItem('Level')
    const segments = localStorage.getItem('segments');


    return (
        <div className={`${testMode ? "testColor" : "liveColor"}`}>
            <div style={{ display: 'flex' }}>
                <a onClick={() => !testMode && history.push('/my_profile')}>
                    {
                        profilePicture ?
                            <img src={profilePicture} style={Style.profilePic} alt="profile" /> :
                            <img src={testMode ? w_profile : profile} style={{ marginTop: 12, width: 40 }} alt="profile" />
                    }
                </a>
                <div className="nameDiv" hidden={collapsed} style={{lineHeight: '1.2'}}>
                    {
                        fname && lname !== "" && fname !== null && fname !== undefined &&
                        <span className={`nameStyle`} >
                            {(fname || '') + " " + (lname || '')}
                            <div className={`userNameStyle`} hidden={userType !== "SW"}>{trade_name}</div>
                        </span>
                    }

                    <div style={{
                        wordBreak: (username && username.search(' ')) ? 'normal' : 'break-all',
                    }}
                    >
                        <div className={`nameStyle`} hidden={fname || tradeName}>{isChild ? "" : username}</div>
                        <div className={`userNameStyle`}>{tradeName && tradeName !== null && tradeName !== "" && tradeName}</div>
                    </div>
                    <a hidden={userKYCLevel == 0} className={`link-text--${segments}`} style={{ fontWeight: '400', fontSize: 12 }} onClick={() => !testMode && history.push('/my_profile')}>
                        View my profile
                    </a>
                </div>
            </div>

            <div style={{
                display: level >= 1 || current_step >= 7 || current_step == 0 || history.location.pathname.includes('/complete_profile') ? 'none' : 'block'
            }}>
                <div
                    hidden={collapsed}
                    style={{
                        marginTop: 8,
                        textAlign: 'left',
                    }}>
                    <div className="twoCol">
                        <div>
                            <div className={`normalTextStyle`}>
                                Upgrade Application
                            </div>
                            <div className={`smallBoldStyle`}>
                                Step {
                                    Math.min(
                                        current_step == 3 || current_step == 4 ? 3 :
                                            current_step == 5 && industry == "" || current_step == 6 && industry == "" ? 4 :
                                                current_step == 6 && industry != "" ? 5 : current_step, 5) + 1
                                } of 6
                            </div>
                        </div>
                        <div>
                            <Icon type="right" className="iconRight" onClick={() => window.location.href = '/complete_profile'} />
                        </div>
                    </div>
                </div>

                <div
                    hidden={!collapsed}
                    style={{ marginTop: 8 }}>

                    <div className="twoCol">
                        <img src={complete_icon} alt="complete" />

                        <div className={`smallBoldStyle`}>
                            <div> {
                                Math.min(
                                    current_step == 3 || current_step == 4 ? 3 :
                                        current_step == 5 && industry == "" || current_step == 6 && industry == "" ? 4 :
                                            current_step == 6 && industry != "" ? 5 : current_step, 5) + 1
                            }/6</div>
                        </div>
                    </div>

                </div>
            </div>

            <div id="upgrade-status-button" hidden={history.location.pathname.includes('/complete_profile') || collapsed} style={{marginTop: 8}}>
                <a  
                    href
                    className={`completeText `}
                    style={{
                        display: (current_step == 0 || current_step > 6) && userKYCLevel == 0 && userType == 'ME' ? 'block' : 'none'
                    }}
                    onClick={() => window.location.href = '/complete_profile'}>{current_step > 6 ? "Upgrade Status" : "Complete Profile"}
                </a>
                <button
                    className={`outline-btn--${segments}`}
                    style={{
                        display: (current_step == 0 || current_step > 6) && userKYCLevel == 0 && userType == 'SW' || testMode && level < 1 ? 'block' : 'none'
                    }}
                    onClick={() => window.location.href = testMode ? '/test/complete_profile' : '/complete_profile'}>
                    <img src={person} alt="#" style={{ margin: '-2px 6px 0px 0px' }} />{current_step > 6 ? "Upgrade Status" : "Complete Profile"}
                </button>
            </div>
        </div>
    );
};

export default MerchantHeader;