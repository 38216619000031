const notificationActions = {
    setNotificationData: (data) => ({
        type: 'SET_NOTIFICATION_DATA',
        payload: data
    }),
    setNotificationCount: (data) => ({
        type: 'SET_NOTIFICATION_COUNT',
        payload: data, 
    }),
    setNotificationHeaderData: (data) => ({
        type: 'SET_HEADER_NOTIFICATION_DATA',
        payload: data,
    }),
}

export default notificationActions;