import axios from 'axios';
import { Handle401 } from '../handle401/handle401'

let yourConfig = {
    headers: {
        Authorization: "Token " + localStorage.getItem("jwtToken")
    }
}

async function getDashboard() {

    try {

        let response = await axios.get(
            process.env.REACT_APP_API_URL + '/api/dashboard/', yourConfig
        );

        let data = response ? response.data : []

        return data;
    }

    catch (error) {
        if ((error.response) && (error.response.status == 401)) {
            Handle401()
        }

    }
}

const getDashboardGraphData = async (type) => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/api/trend/?type=${type}`, yourConfig);
        return response.data;
    } catch (error) {
        if ((error.response) && (error.response.status == 401)) {
            Handle401()
        }
    }
}


export { getDashboard, getDashboardGraphData };