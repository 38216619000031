import React from 'react';
import TestModeBanner from './testModeBanner';
import { Button, Card, Icon, Spin } from 'antd';
import OTPInput from "otp-input-react";
import { Typography } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

const OTP = (props) => {
    const type = props.type;    
    const [OTP, setOTP] = React.useState(null);
    const [counter, setCounter] = React.useState(0);
    const [btnStyle, setBtnStyle] = React.useState({ backgroundColor: 'transparent', color: '#F5922F' });
    const [resBtnLoading, setResBtnLoading] = React.useState(false);
    const [resetTimer, setResetTimer] = React.useState(true);
    const [loading, setLoading] = React.useState(false);

    const handleChange = (code) => {
        setOTP(code)
    }

    const handleMouseOver = () => {
        setBtnStyle({
          backgroundColor: '#F5922F',
          color: '#FAFAFA'
        });
    }
    
      const handleMouseLeave = () => {
        setBtnStyle({
          backgroundColor: 'transparent',
          color: '#F5922F'
        });
    }

    if (resetTimer) {
        setCounter(60);
        setResBtnLoading(false);
        setBtnStyle({
          backgroundColor: 'transparent',
          color: '#F5922F'
        });
        setOTP(null);
        setResetTimer(false);
      }

    const handleResend = () => {
        setResBtnLoading(true)
        setTimeout(() => {
            setResetTimer(true);
            setResBtnLoading(false);
        }, 1000);
    }

    const submitOTP = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            props.submitOtp();
        }, 3000);
    } 

    React.useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter])

    return (
        <Card bodyStyle={styles.root} hidden={props.hidden}>
            <TestModeBanner type={type} />
            <div style={styles.body}>
                <Typography style={styles.subtitle}>One-Time PIN</Typography>
                <Typography style={styles.normalFont}>To proceed, a 6-digit code will be sent via SMS on your registered number ending in
                    <strong> 0000</strong>.
                </Typography>
                <OTPInput
                    value={OTP}
                    onChange={handleChange}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={counter == 0}
                    style={styles.otp}
                    secure
                    inputStyles={{ ...styles.otpInput, opacity: counter == 0 ? '0.5' : '' }}
                    />
                <div align='center'>
                <Button
                    style={{ ...styles.resBtn, ...btnStyle, opacity: counter !== 0 ? '0.5' : '' }}
                    disabled={counter !== 0 || resBtnLoading}
                    onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleResend}
                >
                    {resBtnLoading ? <Spin size='small' /> : 'Resend Code'}
                </Button>
                <Typography style={{ ...styles.normalFont, padding: 0 }} >
                    “<strong>Resend Code</strong>” will be enabled after <strong>{counter}</strong>.
                </Typography>
                </div>
                <Button
                    onClick={submitOTP}
                    style={{ ...styles.subBtn, opacity: !OTP || OTP && OTP.length < 4 || counter === 0 || loading ? '.5' : '1' }}
                    disabled={loading || !OTP || OTP && OTP.length < 4 || counter === 0 }
                >
                    {loading ? <Spin size='small' /> : 'Submit'}
                </Button>
                <div style={styles.reminder}>
                    <Icon type="exclamation-circle" theme="filled" style={styles.excla} />
                    <Typography style={{fontSize: 12, color: '#54575F'}}>Enter any 6 digits code to proceed.</Typography>
                </div>
            </div>
        </Card>
    )
}

const styles = {
    root: {
        width: isMobile? '100%' : 568,
        padding: 0,
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    },
    btn: {
        margin: '20px 0px 0px 0px',
        height: 48,
        width: '100%',
        backgroundColor: '#F5922F',
        color: '#FAFAFA',
        borderRadius: 4,
        fontSize: 16,
        fontWeight: '600',
        textAlign: 'center',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '28px 16px 16px 16px'
    },
    subtitle: {
        color: '#000000',
        fontSize: 28,
        fontWeight: '300',
        // padding: '20px 0 0 0'
    },
    normalFont: {
        color: '#000000',
        fontSize: 16,
        textAlign: 'center',
        padding: '0 26px',
    },
    otp: {
        padding: '32px 0 20px 0'
    },
      otpInput: {
        height: 70,
        width: 32,
        fontSize: "28px",
        color: "#2b2d33",
        fontWeight: "600",
        background: "#E6EAF0",
        boxSizing: "border-box",
        border: "0px solid #d1d5dd",
        borderRadius: "4px",
        margin: '0 10px'
    },
    resBtn: {
        margin: '16px 0',
        height: 48,
        width: '60%',
        color: '#F5922F',
        borderRadius: 10,
        fontSize: 14,
        fontWeight: '600',
        borderColor: '#F5922F',
    },
    subBtn: {
        margin: '32px 0px 0px 0px',
        height: 48,
        width: '100%',
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        borderRadius: 10,
        fontSize: 14,
        fontWeight: '600',
    },
    reminder: {
        padding: '12px 0 20px 0', 
        display: 'flex', 
        justifyContent: 'center',  
        width: '100%'
    },
    excla:{
        color: '#909196', 
        backgroundColor: '#FFF', 
        borderRadius: 20, 
        fontSize: 18, 
        paddingRight: 8
    }
}

export default OTP;