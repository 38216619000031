import React from 'react'
import { Modal, Input, Select, Icon, Button, message } from 'antd'
import CloseIcon from '../../../../../static/icons/close_icon_blue.svg'
import Warning from '../../../../../static/icons/warning_entry.svg'
import axios from 'axios'

const CreateEntryModal = props => {
    const {Option} = Select
    const {visibility, setVisibility, reloadFetch, setIdToTag} = props;


    const [errorDiv, setErrorDiv] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("")

    const [businessName, setBusinessName] = React.useState("")
    const [businessEmail, setBusinessEmail] = React.useState("")
    const [businessType, setBusinessType] = React.useState("0")


    const createEntry = async() => {

        const yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = {
            'business_name': businessName,
            'business_email': businessEmail,
            'business_type': businessType,
        }
        let response = await axios.post(
            process.env.REACT_APP_API_URL + `/api/merchant_entries/`, params,
            yourConfig
        );

        if(response.data.status == 'success'){
            message.success('Merchant entry created successfully')
            setVisibility(false)
            setIdToTag({id: response.data.id, business_name: businessName});
            reloadFetch();
        }        
        else{
            setErrorDiv(true)
            setErrorMessage(response.data.message)
        }
    }

    const isDisabled = () => {
        return businessName == "" || businessEmail == ""
    }
    return(
        <Modal
            width="560px"
            bodyStyle={{padding: '32px'}}
            visible={visibility}
            closable={false}
            footer={null}>
            
            <div style={{display: 'flex', justifyContent:'space-between', marginBottom: '32px'}}>
                <div style={style.headerText}>Create Merchant Entry</div>
                <img onClick={()=>setVisibility(false)} src={CloseIcon} style={{cursor: 'pointer'}}/>
            </div>

            <div style={style.errorDiv} hidden={!errorDiv}>
                <img src={Warning} style={{marginRight: '10px'}}/> {errorMessage}
            </div>

            <div style={{width: '100%', display: 'flex', marginBottom: '24px'}}>
                <div style={{width: '50%', marginRight: '16px'}}>
                    <div style={style.label}>Merchant Name</div>
                    <Input value={businessName} onChange={(e)=>setBusinessName(e.target.value)} size='large' style={style.input}/>
                </div>
                <div style={{width: '50%'}}>
                    <div style={style.label}>Email Address</div>
                    <Input value={businessEmail} onChange={(e)=>setBusinessEmail(e.target.value)} size='large' style={style.input}/>
                </div>
            </div>
            <div style={{width: '100%', marginBottom: '32px'}}>
                <div style={style.label}>Business Type</div>
                <Select
                    size='large'
                    value={businessType} 
                    onChange={(e)=>setBusinessType(e)} 
                    style={{borderRadius: '4px', width: '100%'}}
                    suffixIcon={<Icon type="caret-down" />}
                    >
                    <Option value="0">Sole Proprietorship</Option>
                    <Option value="2">Partnership</Option>
                    <Option value="1">Corporation</Option>
                </Select>
            </div>
            <div style={{display: 'flex', justifyContent:'flex-end'}}>
                <Button 
                    onClick={()=>setVisibility(false)}
                    style={{...style.cancelBtn, marginRight: '10px'}}>Cancel</Button>
                <Button 
                    disabled={isDisabled()} 
                    onClick={()=>createEntry()}
                    style={{...style.createBtn, 
                        opacity: isDisabled() ? '0.5' : 1, 
                        cursor: isDisabled() ? 'not-allowed' : 'pointer'
                    }}>
                    Create
                </Button>
            </div>
        </Modal>
    )
}

const style = {
    headerText: {
        fontSize: '28px',
        fontWeight: '700',
        lineHeight: '32px',
        color: '#2b2d32'
    },
    label: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '20px',
        color: 'rgba(43, 45, 50, 0.8)'
    },
    input: {
        borderRadius: '4px',
        width: '100%'
    },
    errorDiv: {
        width: '100%',
        padding: '8px 16px',
        backgroundColor: '#F6E9E7',
        border: '1px solid #C0492C',
        borderRadius: '4px',
        color: '#A83C23',
        fontSize: '14px',
        fontWeight: '400',
        marginBottom: '32px'
    },
    createBtn: {
        width: '100px',
        height: '40px',
        background: '#0A315E',
        borderRadius: '4px',
        color: '#FFF',
        fontSize: '16px',
        fontWeight: '500'
    },

    cancelBtn: {
        width: '100px',
        height: '40px',
        background: '#FFF',
        border: '1px solid #0A315E',
        borderRadius: '4px',
        color: '#0A315E',
        fontSize: '16px',
        fontWeight: '500'
    }
}
export default CreateEntryModal