import React from 'react';
import { Button, Icon, Input, message, Modal, Radio, Typography } from 'antd';
import { useSelector } from 'react-redux';
import orderServices from '../orderServices';

const RefundModal = ({visible, onCancel, onSuccess}) => {
  const refundDetails = useSelector(state => state.mobileOrders.refundDetails);
  const [reason, setReason] = React.useState(null);
  const [otherText, setOtherText] =React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const refundOrder = async () => {
    const params = {
      id: refundDetails.id,
      reason: reason,
      otherText: otherText
    }
    try {
      setLoading(true);
      const res = await orderServices.refundOrder(params);
      if(res.status === 'success') {
        message.success("Successfully requested refund")
        onSuccess();
      } else {
        message.error(res.message)
      }
      setLoading(false);
    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  }

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={() => onCancel()}
      style={styles.root}
      bodyStyle={{padding: 20}}
      closeIcon={<Icon type="close" style={{color:'#F5922F'}}/>}
      >
      <Typography style={styles.title}>Refund</Typography>
      <Typography style={styles.msg}>
        Are you sure you want to refund this transaction? This can’t be undone.
      </Typography>
      <Typography style={{...styles.regFont, fontWeight: '500'}}>Provide a reason</Typography>
      <div style={{marginBottom: '10px'}}>
        <Radio.Group bodyStyle={{width: '100%'}} onChange={(e) => setReason(e.target.value) } >
          <Radio style={styles.regFont} value="Item or service is no longer available">
            Item or service is no longer available
              </Radio>
          <Radio style={styles.regFont} value="Wrong item or service selected">
            Wrong item or service selected
              </Radio>
          <Radio style={styles.regFont} value="Unable to deliver the item or service">
            Unable to deliver the item or service
              </Radio>
          <Radio style={styles.regFont} value="Payment was declined">
            Payment was declined
              </Radio><br />
          <Radio style={styles.regFont} value="Others">
            Others 
            </Radio>
          <Input.TextArea style={{width: '94%', marginLeft: '6%'}} placeholder='Provide reason...' hidden={reason!=='Others'} onChange={(e) => setOtherText(e.target.value)} autoSize={{ minRows: 2, maxRows: 6 }} />
        </Radio.Group>
      </div>
      <Button style={{...styles.proceedBtn, opacity: !reason ? '.5' : '1',}} disabled={!reason} loading={loading} onClick={() => {refundOrder()}} >Proceed with Refund</Button>
      <Button onClick={()=> onCancel()} style={styles.backBtn} >Cancel</Button>
    </Modal>
  )
}

const styles = {
  root: {
    padding: 20,
  },
  title: {
    color: '#2B2D33',
    fontSize: 24,
    fontWeight: '300',
    padding: '10px 0 0 0 '
  },
  msg: {
    color: '#2B2D33',
    fontSize: 16,
    padding: '8px 0'
  },
  regFont: {
    fontSize: 16,
    color: '#2B2D33',
    padding: '8px 0',
  },
  proceedBtn: {
    backgroundColor: '#E24C4C',
    color: '#FFF',
    borderRadius: 4,
    textAlign: 'center',
    fontSize: 14,
    fontWeight: '600',
    width: '100%',
    margin: '8px 0',
    height: 48,
  },
  backBtn: {
    fontSize: 16,
    color: '#2B2D33',
    fontWeight: '600',
    textAlign: 'center',
    width: '100%',
    border: '2px solid #D1D5DD',
    borderRadius: 4,
    margin: '8px 0',
    height: 48,
  }
}

export default RefundModal;