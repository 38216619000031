import React, {useEffect} from 'react';
import axios from 'axios'
import {Modal, Input, Spin} from 'antd';
import enterpriseStyle from '../../../static/css/Enterprise';
import SignatureCanvas from 'react-signature-canvas'
import Signature from '../../../static/icons/signature.svg'

const ESignatory = props => {

    const es = enterpriseStyle();

    const [sigURL, setSigURL] = React.useState('')
    const [moa, setMoa] = React.useState('')
    const [submitted, setSubmitted] = React.useState(false)
    const [hide, setHide] = React.useState(true)

    let sigPad = {}
    function done(){
        setSigURL(sigPad.getTrimmedCanvas().toDataURL('image/png'))
    }  

    function cancel(){
        sigPad.clear()
        setSigURL("")
    }


    async function fetchProfile(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.get(
            process.env.REACT_APP_API_URL + `/api/kyb/moa`,yourConfig)
            
            setMoa(response.data)

    }

    async function submitEsig(){
        setHide(false)
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }   

        const formData = new FormData();

        formData.append('moa_signature', sigPad.getTrimmedCanvas().toDataURL('image/png'))
        formData.append('step', props.step ==  9 ? 10 : 5)

        let response = await axios.put(
            process.env.REACT_APP_API_URL + '/api/kyb/moa/',formData, yourConfig)
            
            setMoa(response.data)
            setSubmitted(true)
        
    }

    function goDashboard(){
        window.location.href = '/dashboard'
    }

    useEffect(() => {
       fetchProfile();
    }, [])

    return(
        <Modal  
                width={900}
                onCancel={props.setBack}
                visible={props.step == 4 || props.step == 9}
                footer={
                    
                    <div align='center'>
                        <div hidden={submitted}>
                        <div className={es.footerDesign} align='left'>
                            {/* <div className={es.biHeader}>Your Name</div>
                            <Input size="large" /> */}

                            <div align='center' style={{marginTop: 32, borderBottom: '1px solid #000000'}}>
                                <SignatureCanvas penColor='black'
                                        canvasProps={{height: 100, className: 'sigCanvas'}}
                                        ref={(ref) =>  {sigPad = ref}}/>
                            </div>

                            <div style={{position: 'absolute', top: 80, right: '-130px'}}>
                                <button onClick={() => done()} className={es.sigBtn}>
                                    <img src={Signature} style={{marginRight: '5px'}} />Add Signature
                                </button>
                            </div>
                            <div style={{position: 'absolute', right: '-130px'}}>
                                <button onClick={() => cancel()} className={es.sigBtn}>
                                    Clear
                                </button>
                            </div>

                        </div>

                        <button className={es.signConfirmBtn} onClick={() => submitEsig()} hidden={!hide}>Sign and Confirm</button>
                        <Spin size="large" hidden={hide}/>
                        </div>

                        <button className={es.signConfirmBtn} onClick={() => submitEsig()} hidden={!submitted} onClick={() => goDashboard()}>Go to Dashboard</button>
                        

                    </div>
                    

                  
                    
                }
                >
                <div align='center' className={es.moaHeader}>Memorandum of Agreement</div>
                <div align='center'>
  
                </div>
        </Modal>
    )
}


export default ESignatory;