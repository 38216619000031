export const titleStyle = {
    fontStyle: 'bold',
    fontSize: '18px',
    lineHeight: '32px',
    fontWeight: '600',
    fill: 'Solid',
    color: '#2B2D32',
    marginBottom: '20px'
}

export const subHeaderStyle = {
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '600',
    fill: 'Solid',
    color: '#2B2D32',
}

export const linkBank = {
    // marginTop: 16,
    marginBottom: 72,
    width: '100%',
    height: '40px',
    background: '#fff',
    color: '#2B2D32',
    fontWeight: 'bold',
    fontSize: '16px',
    border: '1px solid #2B2D32',
    borderRadius: '4px',
    cursor: 'pointer',
}

export const accountDetails = {
    padding: '8px 0px 8px 8px',
    marginTop: 16,
    background: '#fff',
    border: '2px solid #E6EAF0',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
}

export const prefix = {
    position: 'absolute',
    bottom: '26%',
    left: '10%',
    transform: 'translate(-50%, -50%)',
    fontSize: 16,
    color: '#2B2D33',
    fontWeight: 'normal',
    zIndex: '2',
}

export const primaryStyle = {
    padding: '8px 0px 8px 0px',
    textAlign: 'center',
    background: '#0D3D76',
    borderRadius: ' 20px 0px 0px 20px',
    color: '#fff',
    fontSize: '10px',
    lineHeight: '10px',
    fontWeight: 'bold',
    width: '72px',
    height: '23px',
}

export const generateBtn = {
    color: '#fff',
    background: '#1d82b8',
}

export const generateBtn1 = {
    color: '#fff',
    background: '#1d82b8',
    opacity: '0.5'      
}

export const backBtn = {
    color: "#1d82b8",
    fontWeight: '600',
    fontSize: '17px',
    lineHeight: '22px',
    textTranform: 'uppercase'
}

export const newTextStyle = {
    color: '#2b2d32',
    opacity: '0.8',
    fontSize: '12px',
    lineHeight: '12px',
}

export const spiel = {
      marginTop: 20,
      color: '#2b2d33'
}

export const spielOtp = {
    marginTop: 20,
    color: '#2b2d33',
    align: 'center'
}

export const sHeader = {
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '34px',
}

export const otpHeader = {
    fontWeight: '300',
    fontSize: '28px',
    lineHeight: '32px',
}

export const sDesc = {
    marginTop: '10px',
    fontSize: '14px',
    lineHeight: '24px',
    opacity: '0.8',
    letterSpacing: '-0.01em'
}

export const inputDiv = {
    align: 'center',
    marginTop: 47,
}

export const expSpiel = {
      marginTop: 30,
}

export const submitDiv = {
    marginTop: 40,
}

export const submitBtn = {
    border: '1px solid #1d82b8',
    borderRadius: '4px',
    color: '#fff',
    background: '#1d82b8',
    width: '100%',
    height: 48,
}

export const tipsDiv = {
    display: 'flex',
    marginTop: 32,
    borderTop: '1px solid #D1D5DD',
    paddingTop: 20,
}

export const otpEmail = {
    // color: '#F5922F',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
}
export const otpResendBtn = {
    cursor: 'pointer',
    height: 36,
    border: '1px solid #F5922F',
    borderRadius: '4px',
    background: '#fff',
    color: '#F5922F',
    fontWeight: 'bold',
    width: 220,
}

export const otpResendBtnDisabled = {
    opacity: '0.5',
    cursor: 'not-allowed',
    height: 36,
    border: '1px solid #F5922F',
    borderRadius: '4px',
    background: '#fff',
    color: '#F5922F',
    fontWeight: 'bold',
    width: 220,
}

export const otpSubmitBtn = {
    height: 40,
    fontSize: 14,
    fontWeight: '500',
    borderRadius: 4,
    width: '100%',
    cursor: 'pointer'
}

export const otpDisableBtn = {
    height: 40,
    fontSize: 14,
    fontWeight: '500',
    borderRadius: 4,
    width: '100%',
    cursor: 'not-allowed',
    opacity: '0.5'
}

export const disableBtn = {
    border: '1px solid #1d82b8',
    borderRadius: '4px',
    color: '#fff',
    background: '#1d82b8',
    opacity: '0.5',
    width: '100%',
    height: 48,
}    
export const emailSent = {
    background: '#1DD28B',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.24)',
    borderRadius: '4px',
    color: '#fff',
    fontWeight: 600,
    fontSize: '16px',
    padding: 12,
    textAlign: 'center'
}

export const modalHeaderStyle = {
    color: '#2b2d33',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
}

export const modalCardStyle = {
    color: '#2B2D33',
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: '32px',
    border: '2px solid #E6EAF0',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px'
}

export const bank = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0px 10px 14px',
    background: '#E6EAF0'
}

export const accountStyle = {
    padding: 14,
    textAlign: 'left'
}

export const amountStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 14,
    borderTop: '2px solid #E6EAF0'
}


export const doneBtn = {
    background: '#F5922F',
    width: '100%',
    height: '48px',
    color: '#fff',
    fontWeight: 'bold',
    border: '1px solid #f5922f',
    borderRadius: '10px',
    marginTop: 16,
    cursor: 'pointer'
}

export const disclaimerIconDiv = {
    marginTop: 32,
    textAlign: 'center'
}

export const disclaimerTextDiv = {
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
    padding: 12,
    textAlign: 'center'
}

export const disclaimerBtnDiv = {
    padding: 12,
    display: 'flex',
    justifyContent: 'space-between'
}

export const cancelBtn = {
    background: '#fff',
    border: '1px solid #F5922F',
    borderRadius: '4px',
    boxSizing: 'border-box',
    fontWeight: 'bold',
    color: '#f5922f',
    width: 130,
    height: '40px',
    cursor: 'pointer',
}

export const payoutBtn = { 
    background: '#f5922f',
    border: '1px solid #F5922F',
    color: '#fff',
    borderRadius: '4px',
    boxSizing: 'border-box',
    fontWeight: 'bold',
    width: 130,
    height: '40px',
    cursor: 'pointer',

}