import React from 'react';
import VoucherDrawer from './voucher_drawer';
import ShareModal from './voucher_share_modal';

const VoucherCard = props => {
    const {
        data, color, segments
    } = props

    const [isHovering, setIsHovering] = React.useState(false);
    const [drawerVisibility, setDrawerVisibility] = React.useState(false);
    const [modalVisiblity, setModalVisibility] = React.useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };


    return(
        <div className={`voucher-card top-20 ${isHovering && `voucher-hover-${segments}`}`} 
            onMouseEnter={handleMouseOver} 
            onMouseLeave={handleMouseOut}>

            <VoucherDrawer detailsData={data} drawerVisibility={drawerVisibility} setDrawerVisibility={setDrawerVisibility}/>
            <ShareModal detailsData={data} color={color} modalVisiblity={modalVisiblity} setModalVisibility={setModalVisibility}/>

            <div className={`voucher-amount-div voucher-amount-text ${!isHovering && `bg-${color}-gradient`}`}>
                {data.discount_type === 'PE' && `${parseInt(data.percentage_discount)}% OFF`}
                {data.discount_type === 'FI' && `₱ ${parseInt(data.fixed_discount)} OFF`}
                {
                    isHovering && 
                    <div className="wc-header testColor voucher-with-wrap">
                        {data.promo_name}
                    </div>
                }
                
            </div>
            {
                !isHovering &&
                <div className="padding-16" align="left">
                    <div className={`wc-header text-${segments} voucher-with-wrap`}>
                        {data.promo_name}
                    </div>
                    <div className="top-4 code-size liveColor">
                        CODE: <b>{data.code}</b>
                    </div>
                    <div className="top-8 min-size liveColor">
                    ₱ {data.min_amount} min. spend
                    </div>
                    <div className="top-4 min-size grey-color">
                    Valid until {data.valid_until}
                    </div>
                </div>
            }
            {
                isHovering &&
                <div className="padding-lr-16 padding-bot-16">
                    <div>
                        <button onClick={()=>setDrawerVisibility(true)} className={`view-btn btn-mobile-width btn-height`}>
                            View
                        </button>
                    </div>
                    <div className="top-8">
                        <button onClick={()=>setModalVisibility(true)} className={`share-btn btn-mobile-width btn-height text-${segments}`}>
                            Share
                        </button>
                    </div>
                </div>
            }
            
        </div>
    )
}

export default VoucherCard;