import {history} from '../../store/history'
import axios from 'axios'

export function Handle401() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let next = params.get('next');

  localStorage.setItem("userId", "");
  localStorage.setItem("jwtToken", "");
  localStorage.setItem("firstName", "");
  localStorage.setItem("lastName", "");
  localStorage.setItem("middleName", "");
  localStorage.setItem("suffix", "");
  localStorage.setItem("username", "");
  localStorage.setItem("email", "");
  localStorage.setItem("contact", "");
  localStorage.setItem("userType", "")
  localStorage.setItem("isCompleted", "")
  localStorage.setItem("isVerified", "")
  localStorage.setItem("Step", "")
  localStorage.setItem("Level", "")
  localStorage.setItem("lastLogin", "")
  localStorage.setItem("shipping_enabled", "")
  localStorage.setItem("termsAgreed", "")
  localStorage.setItem('tradeName', "")
  localStorage.setItem("is_sb_multiwallet", "");
  localStorage.setItem('float_balance', "");
  localStorage.setItem("is_multiwallet", "")
  localStorage.setItem("is_child", "");
  localStorage.setItem("isAlias", "");

  if(window.location.pathname.includes('/payment')){
    window.location.reload()
  }
  else{
    if(next && (next !== null || next !== 'null') && next.length > 0) {
      history.push(`/login?next=${next}`);
    } else {
      history.push('/login');
    }
  }
}