import React from 'react'
import {Modal, Checkbox, message} from 'antd'
import SwitchModalImg from '../../../../../../static/img/switch_modal_img.svg'
import multiwalletServices from '../../../../multiwalletServices'

const SwitchModal = props => {

    const {
        getSubWallet,
        segments,
        visible,
        setVisible,
        pUid
    } = props


    const [isNotRequired, setIsNotRequired] = React.useState(false)


    function onChange(e) {
        setIsNotRequired(e.target.checked)
    }
    
    async function convertNow(){
        let params = {
            kyb_required: !isNotRequired,
        }

        if(pUid){
            params['user_id'] = pUid
        }
        
        const res = await multiwalletServices.convertSubUser(params)

        if(res.status == "success"){
            message.success(res.message)
            localStorage.setItem("subuser_role", "CO")
             getSubWallet()
        }
        else{
            message.error(res.message)
        }
        setVisible(false)

    }
    return(
        <Modal
            width="400px"
            visible={visible} 
            closable={false}
            footer={null}
            bodyStyle={{padding: '32px'}}>
            
            <img src={SwitchModalImg} alt="#" style={{width: '100%'}}/>
            <div style={style.textStyle}>
                Are you sure you want to switch this sub-wallets for use by businesses? This sub-wallets will have the ability to onboard users. This action cannot be undone.
            </div>
            <div style={{marginTop: '20px'}}>
                <Checkbox checked={isNotRequired} onChange={onChange}>
                    <span style={style.noteStyle}>The sub-wallets will be for internal use only and will not need KYB.</span>
                </Checkbox>
            </div>
            <div style={{displat: 'flex', marginTop: '20px'}} align="right">
                <button style={{width: '138px', marginRight: '16px'}} className={`outline-btn--${segments} btn-height`} onClick={()=>setVisible(false)}>Cancel</button>
                <button style={{width: '110px'}} className={`btn--${segments} btn-height`} onClick={()=>convertNow()}>Confirm</button>
            </div>
        </Modal>
    )
}

const style = {
    textStyle: {
        fontWeight: '400', 
        color: '#2b2d33', 
        fontSize: '16px',
        marginTop: '16px'
    },
    noteStyle:{
        color: '#2b2d32',
        fontSize: '14px'
    }
}

export default SwitchModal