import React from 'react'
import {Bux} from '../../static/icons/index'
import Ecomx from '../../static/images/ecomx.svg'
import RegFee from '../../static/images/reg_fee.svg'
import {Card, Button, Divider, Alert, Spin} from 'antd'
import ecomStyles from '../../static/css/Ecomx';
import {history} from '../../store/history'
import { Grid, TextField} from "@material-ui/core";
import * as Style from '../learn_more_page_ecomx/learn_more_style'
import axios from 'axios'

const SignUpEcomxMobile = props =>{

    const ecom = ecomStyles();
    const [email, setEmail] = React.useState('')
    const [fname, setFname] = React.useState('')
    const [lname, setLname] = React.useState('')
    const [hideloading, setLoading] = React.useState(true)
    const [showerror, setError] = React.useState(true)
    const [errorMsg, setErrorMsg] = React.useState('')

    const dontMissStyle = {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#2B2D33',
        textAlign: 'center',
        marginBottom: '5px'
    }

    async function submitFields () {
        setLoading(false);
        let params = {
          first_name: fname,
          last_name: lname,
          email: email
        }
        let response = await axios.post(
            process.env.REACT_APP_API_URL + "/api/ecomx/register/", params
        )

        setLoading(true);
            console.log(response.data)
        if(response.data.status == "success"){
            let data = response.data
            console.log(data)
            history.push('/payment/'+data.data.uid+'/')
        }else{
            setError(false)
            setErrorMsg(response.data.message)
        }
    }

    return(

        <div style={{paddingRight: '20px', paddingLeft: '20px', marginBottom: '20px'}}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <img src={Bux} alt="#" style={Style.icon_style}/>
            </div>
            <Card
                style={{ width: '100%'}}
            >
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <img src={Ecomx} alt="#" style={{ width: '100%'}}/>
                </div>

                <div style={Style.infoStyle}>
                    <span style={{marginRight: '5px'}}>Date:</span><span style={{fontWeight: '600'}}>December 10, 2019</span>
                </div>
                <div style={Style.infoStyle}>
                    <span style={{marginRight: '5px'}}>Venue:</span><span style={{fontWeight: '600'}}>47th UnionBank Plaza Bldg., Meralco Ave. Ortigas Center, Pasig City</span>
                </div>
                <div style={Style.byNickStyle}>By Nick Peroni</div>
                
                <div>
                    <img src={RegFee} style={{width: '100%', marginBottom: '20px', marginTop: '20px'}}/>
                </div>

                <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                    <span style={Style.byNickStyle}>Powered by</span> <img src={Bux} />
                </div>

                <Divider></Divider>

                <div>
                    <div className={ecom.crsHeader} style={{textAlign: 'center', marginBottom: '20px'}}>Sign up</div>
                    <div style={dontMissStyle}>Limited seats only.</div>
                    <div style={dontMissStyle}>First Come, First Served Basis</div>
                </div>
                <div hidden={showerror} style={{width: '100%'}} >
                    <Alert message={errorMsg} type="error" closable showIcon />
                </div>
                <Grid container style={{marginBottom: '20px'}}>
                    <Grid xs={12}>
                    <TextField
                        id="outlined-basic"
                        placeholder="Email"
                        onChange={e => setEmail(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        style={{opacity: '50%'}}
                    />
                    </Grid>
                    <Grid xs={6}>
                    <TextField
                        className={ecom.textField}
                        id="outlined-basic"
                        placeholder="First Name"
                        onChange={e => setFname(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        style={{opacity: '50%'}}
                        
                        />
                    </Grid>
                    <Grid xs={6}>

                    <TextField
                        id="outlined-basic"
                        placeholder="Last Name"
                        onChange={e => setLname(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        style={{opacity: '50%'}}
                        
                        />
                    </Grid>
                </Grid>

                <Button onClick={() => submitFields()} size="large" hidden={!hideloading} type="primary" block>Sign-up now!</Button>

                <div style={{display:'flex', justifyContent: 'center', marginTop: '40px'}} hidden={hideloading}>

                    <Spin size="large" />
                </div>
                
            </Card>
        </div>
    )
}

export default SignUpEcomxMobile