import React, {useEffect} from 'react'
import {isMobile} from 'react-device-detect'
import {Input, message, Spin} from 'antd'
import { Grid } from "@material-ui/core";
import accountStyle from '../../static/css/Account';
import {NATURE_BUSINESS, backToTop} from '../constants/constants'
import profile from "../../static/icons/profile.svg";
import settingServices from './settingServices';


const EnterpriseBusinessDetails = props => {

    const max_width = 2000;
    const max_height = 2000;

    const [loading, setLoading] = React.useState(false)
    const [data, setData] = React.useState([])
    const [profilePic, setProfilePic] = React.useState("")
    const [profilePicURL, setProfilePicURL] = React.useState("")
    const [isEditMode, setIsEditMode] = React.useState(false);
    const [tempTradeName, setTempTradeName] = React.useState('');

    let profilePicUpload = React.useRef()
    const acct = accountStyle()

    const fullAddress = data.address_unit || data.address_street ? (data.address_unit+" "+data.address_street+" "+data.address_subdivision+" "+data.address_barangay
                        +" "+data.address_city+" "+data.address_province+" "+data.address_zip_code) : "- -"

    useEffect(() => {
        getData()
        getProfPic()
    }, [])

    async function getProfPic(){

        let res = await settingServices.getProfilePic();
        
        if(res.profile_picture){
            localStorage.setItem("profilePicture", res.profile_picture);
            setProfilePicURL(res.profile_picture);
        }   else {
            setProfilePicURL(null);
        }

    }

    async function updateTradeName(trade_name) {
        let res = await settingServices.saveBusinessDetails({trade_name});

        if (res.errors.length) {
            message.error("ERROR: Settings not saved", 3)
            backToTop();
        } else {
            localStorage.setItem('username', tempTradeName);
            message.success("Settings saved. Updating your profile", 3);
            setTimeout(() => {
                window.location.reload();
            }, 2500);
        }
    }

    function handleUploadImage(info, type) {
        // read the files
        var reader = new FileReader();
        reader.readAsArrayBuffer(info);
        
        reader.onload = function (event) {
          // blob stuff
          var blob = new Blob([event.target.result]); // create blob...
          window.URL = window.URL || window.webkitURL;
          var blobURL = window.URL.createObjectURL(blob); // and get it's URL
          
          if(type == "profilePicID"){
              setProfilePic(info)
              setProfilePicURL(blobURL)
          }
          // helper Image object
          var image = new Image();
          image.src = blobURL;
          image.onload = function() {
            // have to wait till it's loaded
            var resized = resizeMe(image); // resized image url
            uploadProfilePic(resized)
          }
        };
    }

    function resizeMe(img) {
      
        var canvas = document.createElement('canvas');
  
        var width = img.width;
        var height = img.height;
  
        // calculate the width and height, constraining the proportions
        if (width > height) {
          if (width > max_width) {
            //height *= max_width / width;
            height = Math.round(height *= max_width / width);
            width = max_width;
          }
        } else {
          if (height > max_height) {
            //width *= max_height / height;
            width = Math.round(width *= max_height / height);
            height = max_height;
          }
        }
        
        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        
        return canvas.toDataURL("image/jpeg",0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)
        
        // you can get BLOB too by using canvas.toBlob(blob => {});
  
      }

    function dataURItoBlob(dataUri) {
        var byteString = atob(dataUri.split(',')[1]);
        var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], {type: mimeString});
        return blob
    }

    async function uploadProfilePic(dataUri){
        var blob = dataURItoBlob(dataUri);

        const formData = new FormData();

        formData.append('profile_picture', blob, 'id_image.png')

        let res = await settingServices.uploadProfilePic(formData);

        let res2 = await fetch(res)
        if(res2.ok){
            window.location.reload()
            
        }

        else{
            message.error("ERROR" + res.status, 3)
        }
        setProfilePic(res.data.url)
        
    }

    async function getData(){
        try{
            let userId = localStorage.getItem('userId')        

            let res = await settingServices.getKybDetails(userId);

            if(res.status == "failed"){
                message(res.message)
                backToTop()
            }
            else{
                setData(res)
                setTempTradeName(res.trade_name)
                setLoading(true)
            }
            
        }
        
        catch(error){
            console.log(error)
        }
    }


    const myInfo = [
        {
            'placeholder': 'Business Type',
            'value': data.business_type === 0 ? 'Sole Proprietor' : 'Corporate'
        },
        {
            'placeholder': 'Company Name',
            'value': data.business_name
        },
        {
            'placeholder': 'Business Name',
            'value': data.trade_name
        },
        {
            'placeholder': 'Nature of Business',
            'value': NATURE_BUSINESS[data.nature_of_business]
        },
    ]

    const business_declaration = [
        {
            'placeholder': 'Office Phone Number:',
            'value': data.phone
        },
        {
            'placeholder': 'Fax Number:',
            'value': data.fax,
        },
        {
            'placeholder': 'Email Address:',
            'value': data.email
        },
        {
            'placeholder': 'Website:',
            'value': data.website
        },
        {
            'placeholder': 'Facebook:',
            'value': data.facebook
        },
        {
            'placeholder': 'Instagram:',
            'value': data.instagram
        },


    ]

    const tradeNameRowItem = (item) => {
        return(
            <div 
                className={acct.piHeader}
                style={{
                    display: 'flex',
                    flexDirection: isEditMode ? 'column' : 'row',
                    justifyContent: isEditMode ? 'flex-start': 'space-between'
                }}>
                <div hidden={isEditMode}  style={{color: '#000000'}}>
                    <div hidden={loading}>
                    <Spin size="small" />
                    </div>
                    <div hidden={!loading}
                        style={{
                            wordBreak: (item.value && item.value.search(' ')) ? 'normal' : 'break-all',
                            overflowWrap: 'anywhere',
                            marginRight: isMobile ? '25px' : '50px',
                        }}
                    >
                        {item.value ? item.value: '- -'}
                    </div>
                </div>
                <div hidden={!isEditMode}
                    style={{
                        color: '#000000', 
                        paddingRight: '15px',
                    }}
                >
                    <Input
                        style={{marginTop: '-5px'}}
                        maxLength={64}
                        placeholder="Business Name"
                        value={tempTradeName}
                        onChange={e => setTempTradeName(e.target.value)}
                    />
                </div>
                <div
                    hidden={localStorage.getItem("isAlias") == 'true'}
                    style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: isMobile ? 'space-evenly' : 'flex-start',
                    marginTop: isEditMode ? '10px': '0px',
                }}>
                    <button
                        hidden={!isEditMode}
                        onClick={() => {
                            if (tempTradeName !== data.trade_name) {
                                updateTradeName(tempTradeName.trim());
                                setData({...data, trade_name: tempTradeName});
                            }
                            setIsEditMode(false);
                        }}
                        className="hoverable-edit-button"
                        style={{
                            border: 'none',
                            background: 'none',
                            color: '#ed5249',
                            outline: 'none',
                            marginRight: '10px',
                            fontSize: 'medium',
                            cursor: 'pointer',
                        }}
                    >
                        Save
                    </button>
                    <button
                        onClick={() => {
                            setIsEditMode(!isEditMode);
                            setTempTradeName(data.trade_name);
                        }}
                        className="hoverable-edit-button"
                        style={{
                            border: 'none',
                            background: 'none',
                            color: '#e6892e',
                            outline: 'none',
                            marginRight: '10px',
                            fontWeight: isEditMode ? '' : 600,
                            fontSize: isEditMode ? 'medium' : 'large',
                            cursor: 'pointer',
                        }}
                    >
                        {isEditMode ? "Cancel" : "Edit"}
                    </button>
                </div>
            </div>
        )
    }

    return(
        <div className={acct.personal}>
            <div className={acct.profilePicDiv}>
                <div className={acct.headerProfile}>Business Logo</div>

                <div style={{display: 'flex', marginTop: '16px'}}>
                    <div style={{marginRight: '15px', paddingTop: '3px'}} id="profile-pic-preview">
                        {profilePicURL ? <img src={profilePicURL} alt="avatar" className={acct.profilePics}/> : <img src={profile}/>}
                    </div>
                    <div id="profile-pic-btn" style={{marginTop: '16px'}}>
                        <input type="file"
                            ref={profilePicUpload}
                            style={{display: 'none'}}
                            onChange={(e)=>handleUploadImage(e.target.files[0], "profilePicID")}
                            accept=".jpg,.png,.jpeg"
                            >
                        </input>

                        <button className={acct.profilePicBtn} onClick={()=>profilePicUpload.current.click()}>Update Business Logo</button>
                    </div>
                </div>
            </div>
            <div className={acct.infoDiv}>
                <div className={acct.headerProfile}>
                    Business Information
                </div>

                <div className={acct.personalInfo} align='left'>
                {
                    myInfo.map((item, i) => {
                        return(
                            <Grid key={i} container>
                                <Grid item lg={4} xs={12}>
                                    <div className={acct.piHeader} style={{color: '#000000'}}>
                                        {item.placeholder}:
                                    </div> 
                                </Grid>
                                <Grid item lg={8} xs={12}>
                                    { item.placeholder !== "Business Name"
                                        ?   <div className={acct.piHeader} style={{color: '#000000'}}>
                                                <div hidden={loading}>
                                                <Spin size="small" />
                                                </div>
                                                <div  hidden={!loading}>
                                                {item.value ? item.value: '- -'}
                                                </div>
                                            </div>
                                        :   tradeNameRowItem(item)
                                    }
                                </Grid>
                            </Grid>
                        )
                    })
                }
                </div>
            </div>
            
            <div className={acct.infoDiv}>
                <div className={acct.headerProfile}>
                    Address
                </div>

                <div className={acct.personalInfo} align='left'>
                    <Grid container>
                        <Grid item lg={4} xs={12}>
                            <div className={acct.piHeader} style={{color: '#000000'}}>
                                Business Address:
                            </div> 
                        </Grid>
                        <Grid item lg={8} xs={12}>
                            
                            <div className={acct.piHeader} style={{color: '#000000'}}>
                                <div hidden={loading}>
                                <Spin size="small" />
                                </div>
                                <div  hidden={!loading}>
                                {/* {item.value ? item.value: 'N/A'} */}
                                {fullAddress ? fullAddress : "- -"}
                                </div>
                            </div> 
                        </Grid>
                    </Grid>
                </div>
            </div>

            <div className={acct.infoDiv}>
                <div className={acct.headerProfile}>
                    Business Declaration
                </div>

                <div className={acct.personalInfo} align='left'>
                {
                        business_declaration.map((item, i) => {
                            return(
                                <Grid key={i} container>
                                    <Grid item lg={4} xs={12}>
                                        <div className={acct.piHeader} style={{color: '#000000'}}>
                                            {item.placeholder}
                                        </div> 
                                    </Grid>
                                    <Grid item lg={8} xs={12}>
                                    
                                        <div className={acct.piHeader} 
                                        style={{color: '#000000',         
                                                width: 250,
                                                }}>
                                            <div hidden={loading}>
                                            <Spin size="small" />
                                            </div>
                                            <div style={{          
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',}}
                                                hidden={!loading}>
                                            {item.value ? item.value: '- -'}
                                            </div>
                                        </div> 
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default EnterpriseBusinessDetails