import React from 'react'
import axios from 'axios'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button, Alert, Input, Icon, Select, Table, Upload, message, DatePicker, Pagination} from 'antd'
import {history} from '../../../store/history'
import { getRecon } from './reconAction';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import '../../../static/css/antd.css'
import {ExportToCsv} from 'export-to-csv'

const dateFormat = 'MM-DD-YYYY';


const mapStateToProps = (state) => {
  const { recon, cutoffs, loading, error } = state.recon;
  return {
    recon,
    cutoffs,
    loading,
    error,
  };
}

const Option = Select

const mapDispatchToProps = (dispatch) => {
  return {
    getRecon: bindActionCreators(getRecon, dispatch),
  };
};

function disabledDate(current) {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
}

class Recon extends React.Component{

  componentDidMount(){
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
      history.push('/login')
    }
    else{
      if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
        history.push('/dashboard')
      }
      else if(localStorage.getItem("userType") == "PS"){
        history.push('/orders')
      }
      else if(localStorage.getItem("userType") == "MA"){
        history.push('/overview')
      }else{
        this.props.getRecon('', '', this.state.page_size, 1, 'sentro');
      }

    }
  }

  state = {
    cutoff : '',
    startDate: '',
    page: 1,
    count: 0,
    page_size: 20,
    fileList: [],
    uploading: false,
    alertMessage: '',
    showAlert: false,
    show: 'Accounted',
    alert: 'success'
  }

  handleStartDate(date, dateString) {
    this.setState({
      startDate: dateString
    });
  }

  handleChangeDate(date, dateString) {
    this.setState({
      cutoff: dateString,
      page: 1
    });
    this.props.getRecon(this.state.startDate, dateString, this.state.page_size, 1, 'sentro');
  }

  pageSelect = (e) => {
    console.log(e)
    this.setState({
      page: e,
    })
    // this.props.getRecon(this.state.startDate, this.state.cutoff, this.state.page_size, 1, 'sentro');
    this.props.getRecon(this.state.startDate, this.state.cutoff, this.state.page_size, e, 'sentro');
  }

  changePageSize = (e) => {
    console.log(e)
    this.setState({
      page_size: e,
      page: 1
    })
    this.props.getRecon(this.state.startDate, this.state.cutoff, e, 1, 'sentro');
  }

  getCount = () => {
    let recon = this.props.recon
    switch (this.state.show) {
      case 'Unaccounted (Bux)':
        return recon.unaccounted.count
      case 'Accounted':
        return recon.accounted.count
      case 'Unaccounted (Partner)':
        return recon.missing.count
      default:
        return 0
    }
  }

  handleChange(selectorFiles: FileList)
  {
      console.log(selectorFiles);
  }

  renderUnaccounted(){
    const {
      recon
    } = this.props;
    if(recon) {
      return recon.unaccounted.transactions
    }
    else{
      return []
    }
  }

  renderAccounted(){
    const {
      recon
    } = this.props;
    if(recon){
      return recon.accounted.transactions
    }
    else{
      return []
    }
  }

  renderMissing(){
    const {
      recon
    } = this.props;
    if(recon){
      return recon.missing.transactions
    }
    else{
      return []
    }
  }

  headerStyle = {
    
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    color: '#000000',
    marginBottom: '30px'
  }
  tableHeaderStyle = {
    fontStyle: 'normal',
    fontSize: '16px',
    color: '#333333',
    marginBottom: '20px',
    marginRight: '10px',
    marginTop: '20px',
  }

  columns = [
    {
      title: 'Ref. Code',
      dataIndex: 'ref_code',
      render: text => {
        if(text == "Total")
          return <b>{text}</b>
        else
          return text
      }
    },
    {
      title: 'Total Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Receivable Bux',
      dataIndex: 'receivable_bux',
    },
    {
      title: 'Receivable Merchant',
      dataIndex: 'receivable_merchant',
    },
    {
      title: 'BUx Fee',
      dataIndex: 'bux_fee',
    },
    {
      title: 'Sentro Fee',
      dataIndex: 'sentro_fee',
    },
    {
      title: 'Channel Fee',
      dataIndex: 'channel_fee',
    },
    {
      title: 'Date Paid',
      dataIndex: 'date_paid',
    },
    {
      title: 'Channel',
      dataIndex: 'channel'
    }
  ];

  async exportTable() {
    let options = { 
      filename: 'Recon_Sentro_'+moment().format("MM-DD-YYYY_HH:mm:ss")+'_'+this.state.show,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'BUx Recon Sentro',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };
      
    let csvExporter = new ExportToCsv(options);
    
    let listToExport = []

    let yourConfig = {
      headers: {
         Authorization: "Token " + localStorage.getItem("jwtToken")
      }
    }

    let response = await axios.get(
      process.env.REACT_APP_API_URL + '/api/recon/sentro/?start='+this.state.startDate+'&end='+this.state.cutoff+'&dump='+true ,yourConfig
    );


    if(response.data){
        listToExport = response.data.accounted.transactions
        csvExporter.generateCsv(listToExport);
    }
  };

  render(){
      return(
        <div style={{ paddingTop: '38px', paddingLeft: '42px', paddingRight: '42px' }}>
          <div style={this.headerStyle}>Sentro Recon</div>


          <div style={{ marginBottom: '20px', float: 'right'}}>
          <DatePicker
              placeholder="Start Date"
              size="large"
              disabledDate={disabledDate} 
              format={dateFormat} 
              onChange={this.handleStartDate.bind(this)} 
              style={{zIndex: 1}}
            />
          <span style={{padding: '0 8px'}}>-</span> 
          <DatePicker
              placeholder="End Date"
              size="large"
              disabledDate={disabledDate} 
              format={dateFormat} 
              onChange={this.handleChangeDate.bind(this)} 
              style={{marginRight: '10px', zIndex: 1}}
            />

            <Button
                  style={{
                    backgroundColor: '#1DD28B',
                    border: '#1DD28B',
                    height: '40px',
                    borderRadius:'10px',
                    zIndex: 1
                  }}
                  onClick={()=>this.exportTable()}>
                
                <div style={{fontSize: '14px', fontWeight:'600', color: '#fff'}}>Export CSV</div>
            </Button>
          </div>

          <div>
            <Table
              rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
              columns={this.columns}
              dataSource={this.renderAccounted()}
              pagination={false} 
              />
          </div>
          <div style={{ width: '100%', display: 'flex', marginTop: '20px', }}>
            <Select
                showSearch
                onChange={(ev)=>this.changePageSize(ev)}
                placeholder="Page Size"
                style={{ width: '11%', marginBottom: '10px', marginLeft: 'auto', marginRight: '20px' }}
                size="small"
            >
                <Option key={1} value={5}>Show 5</Option>
                <Option key={2} value={10}>Show 10</Option>
                <Option key={3} value={20}>Show 20</Option>
                <Option key={4} value={50}>Show 50</Option>

            </Select>

            <Pagination 
            size="small"
            total={this.getCount()} 
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            onChange={this.pageSelect} 
            defaultCurrent={1} key={Math.random()} 
            pageSize={this.state.page_size} 
            current={this.state.page}/>
          </div>
        </div>
      )
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Recon);