import { combineReducers } from 'redux'
const initialState = {
  // successLoadModal: false,
  selectedTopUps: 'gaming',
  selectedPaybills: 'Telecoms',
  cardLock: null,
  eonTransactionHistory: null,
  cardInfo: null,
  clearFields: false,
  buxBalance: 0,
  cardNumber: '',
  otpTimerReset: false,
  attemptErrorMsg: null,
  otpParams: null,
  otpSuccess: false,
  otpUID: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VISIBLE_SUCCESS_LOAD_MODAL':
     return {
      ...state,
      successLoadModal: !!initialState.successLoadModal,
     }
    case 'SHOW_MODAL':
      return {
        ...state,
        [action.payload]: true,
      }
    case 'HIDE_MODAL':
      return {
        ...state,
        [action.payload]: false,
      }
    case 'SET_SELECTED_TOPUPS':
      return {
        ...state,
        selectedTopUps: action.payload,
      } 
    case 'SET_SELECTED_PAYBILLS':
      return {
        ...state,
        selectedPaybills: action.payload,
      }
    case 'SET_CARDLOCK':
      return {
        ...state,
        cardLock: action.payload,
      } 
    case 'SET_EON_TRANSACTION_HISTORY':
      return {
        ...state,
        eonTransactionHistory: action.payload,
      }
    case 'SET_CARD_INFO':
      return {
        ...state,
        cardInfo: action.payload,
      }
    case 'CLEAR_FIELDS':
      return {
        ...state,
        clearFields: action.payload,
      }
    case 'SET_BUX_BALANCE':
      return {
        ...state,
        buxBalance: action.payload,
      }
    case 'SET_CARD_NUMBER':
      return {
        ...state,
        cardNumber: action.payload,
      }
    case 'RESET_OTP_TIMER':
      return {
        ...state,
        otpTimerReset: action.payload,
      }
    case 'SET_ATTEMPT_MSG':
      return {
        ...state,
        attemptErrorMsg: action.payload,
      }
    case 'SET_OTP_PARAMS':
      return {
        ...state,
        otpParams: action.payload,
      }
    case 'SET_OTP_SUCESS':
      return {
        ...state,
        otpSuccess: action.payload,
      }
    case 'SET_OTP_UID':
      return {
        ...state,
        otpUID: action.payload,
      }
    default:
      return state;
  }
}