import React, {useEffect} from 'react'
import { makeStyles } from '@material-ui/core';
import {Card, Input, Button, DatePicker, Checkbox, Select, Modal, message} from 'antd'
import UpgradeAccount from '../../../static/img/upgrade_account.svg'
import * as Style from './kyc'
import {NATURE_BUSINESS, POSITION} from '../../constants/constants'
import {history} from '../../../store/history'
import {Grid} from '@material-ui/core'
import CheckIcon from '../../../static/icons/check.svg'
import axios from 'axios'
import moment from 'moment';
import {isMobile} from 'react-device-detect'

import Main from "../../../layouts/Main";

const { TextArea } = Input;

const KYCIndividual = props => {

    useEffect(()=>{

        if(localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") == null){
            history.push('/login')
        }
        else if(isMobile){
            window.location.href = `/mobile/submissions/${pUid}`
        }
        else{
            if(localStorage.getItem("userType") == "ME"){

                history.push('/dashboard')
            }if(localStorage.getItem("userType") == "CO"){
                const mw = (history.location.pathname.indexOf('/subwallet/kyc') > -1);
                if(!mw){

                    history.push('/dashboard');
                }else{

                    fetchProfile()
                }
            }
            else if(localStorage.getItem("userType") == "PS"){
                history.push('/orders')
            }
            else if(localStorage.getItem("userType") == "PF"){
                history.push('/recon/7_eleven')
            }
            else if(localStorage.getItem("userType") == "MA"){
                history.push('/overview')
            }
            else{
                fetchProfile()
            }
            
        }
        
    },[])
    const { Option } = Select;

    const pUid = props.match.params.id;
    const [limitModal, setLimitModal] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const [firstName, setFirstName] = React.useState("")
    const [middleName, setMiddleName] = React.useState("")
    const [lastName, setLastName] = React.useState("")
    const [mothersMaidenName, setMothersMaidenName] = React.useState("")
    const [birthDate, setBirthDate] = React.useState("")
    const [birthPlace, setBirthPlace] = React.useState("")
    //present address
    const [presentStreet, setPresentStreet] = React.useState("")
    const [presentBarangay, setPresentBarangay] = React.useState("")
    const [presentCity, setPresentCity] = React.useState("")
    const [presentProvince, setPresentProvince] = React.useState("")
    const [presentPostCode, setPresentPostCode] = React.useState("")


    const [nationality, setNationality] = React.useState("")
    const [civilStatus, setCivilStatus] = React.useState("")
    const [gender, setGender] = React.useState("M")

    const [mobileNumber, setMobileNumber] = React.useState("")
    const [emailAddress, setEmailAddress] = React.useState("")

    const [employmentStatus, setEmploymentStatus] = React.useState("")
    const [employerName, setEmployerName] = React.useState("")
    const [employerNatureBusiness, setEmployerNatureBusiness] = React.useState("")
    const [occupation, setOccupation] = React.useState("")
    const [sourceFunds, setSourceFunds] = React.useState("")
    const [remarks, setRemarks] = React.useState("")

    const [empStreet, setEmpStreet] = React.useState("")
    const [empBarangay, setEmpBarangay] = React.useState("")
    const [empCity, setEmpCity] = React.useState("")
    const [empProvince, setEmpProvince] = React.useState("")
    const [empPostCode, setEmpPostCode] = React.useState("")

    const [idURL, setIdURL] = React.useState("")
    const [selfieBlob, setSelfieBlob] = React.useState("")
    const [idType, setIdType] = React.useState("")
    const [backURL, setBackURL] = React.useState("")
    const [idNumber, setIdNumber] = React.useState("")
    const [transID, setTransID] = React.useState("")

    const [selfieModal, setSelfieModal] = React.useState(false)
    const [idModal, setIDModal] = React.useState(false)
    const [backIdModal, setBackIDModal] = React.useState(false)
    const testMode = history.location.pathname.indexOf('/test') > -1;



    const [step, setStep] = React.useState(0)

    const kycStyles = makeStyles(theme => ({
        breadCrumbs:{
            marginTop: '-1.32%',
            marginLeft: '0.7%',
            padding: '30px 0px 30px 11%',
            position: 'fixed',
            background: '#f5f5f4',
            marginBottom: '20px',
            width: '100%',
            zIndex: '100',

            [theme.breakpoints.down("md")]: {
                padding: '30px 0px 30px 11%',

            },

            [theme.breakpoints.down("lg")]: {
                padding: '30px 0px 30px 11%',

            },

        },

        breadCrumbsMobile:{


        },

        breadCrumbsHover: {
            color: '#000000',
            '&:hover': {
                color: '#1d82b8',
                textDecoration: 'underline'   
            }
    },}))

    function disabledDate(current) {
        return current > moment().subtract(18, "years") ;
    }

    function handleSchedDate(date, dateString){
        setBirthDate(dateString)
    }

    function handleCivilStatus(value){
        setCivilStatus(value)
    }

    function handleEmploymentStatus(value){
        setEmploymentStatus(value)
    }

    function handleEmployerNatureBusiness(value){
        setEmployerNatureBusiness(value)
    }

    function handleOccupation(value){
        setOccupation(value)
    }

    function handleSourceFunds(value){
        setSourceFunds(value)
    }

    async function fetchProfile(){
    
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let response = null;
        if(testMode){

            response = await axios.get(process.env.REACT_APP_API_URL + '/api/kyc/' + pUid + '/?test=1', yourConfig);
        }else{

            response = await axios.get(process.env.REACT_APP_API_URL + '/api/kyc/' + pUid + '/', yourConfig);
        }

        console.log(response.data)

        setFirstName(response.data.first_name)
        setMiddleName(response.data.middle_name)
        setLastName(response.data.last_name)
        setMothersMaidenName(response.data.mothers_maiden_name)
        setBirthDate(response.data.birthday)
        setBirthPlace(response.data.birthplace)
        
        setPresentStreet(response.data.present_address.street)
        setPresentBarangay(response.data.present_address.barangay)
        setPresentCity(response.data.present_address.city)
        setPresentProvince(response.data.present_address.province)
        setPresentPostCode(response.data.present_address.postalCode)

        setNationality(response.data.nationality)
        setCivilStatus(response.data.civil_status)
        setGender(response.data.gender)

        setMobileNumber(response.data.contact)
        setEmailAddress(response.data.email)

        setEmploymentStatus(response.data.employment_status)
        setEmployerName(response.data.employer_name)
        setEmployerNatureBusiness(response.data.employer_industry)
        setSourceFunds(response.data.source_of_funds)
        setStep(response.data.step)
        setRemarks(response.data.remarks)

        if(response.data.employer_address.street){
            setEmpStreet(response.data.employer_address.street)
            setEmpBarangay(response.data.employer_address.barangay)
            setEmpCity(response.data.employer_address.city)
            setEmpProvince(response.data.employer_address.province)
            setEmpPostCode(response.data.employer_address.postalCode)
        }

        setIdType(response.data.id_type)
        setIdNumber(response.data.id_number)
        setIdURL(response.data.id_image_url)
        setBackURL(response.data.back_id_image_url)
        setSelfieBlob(response.data.selfie_image_url)
        setTransID(response.data.transaction_id)
    }

    async function approveSubmission(approval){
        setLoading(true);
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = {approved: approval, remarks: remarks}
        let response = await axios.post(process.env.REACT_APP_API_URL + '/api/kyc/' + pUid + '/', params, yourConfig)
        setLoading(false);
        if(response.data.status == 'success'){
            if(approval==1){
                message.success('Successfully approved');
            }else{
                message.success('Successfully rejected');
            }
            history.push('/submissions')
        }else{
            message.error('Failed ');
        }


    }
   const Gender = {
        'F': 'Female', 
        'M': 'Male'
    }
       const CivilStatus = {
        'S': 'Single', 
        'M': 'Married',
        'W': 'Widowed', 
        'D': 'Divorced'
    }

    const EmploymentStatus = {
        'EMP': 'Employed',
        'CON': 'Consultant',
        'SEB': 'Self-employed (Business)',
        'SEF': 'Self-employed (Freelancer)',
        'UEH': 'Unemployed (Housewife)',
        'UER': 'Unemployed (Retired)',
        'UES': 'Unemployed (Student)'
    }

    const NatureOfWork = {
        'ADM': 'Administrative/Human Resources',
        'AGRI': 'Agriculture',
        'BNK': 'Banking Institutions',
        'IT': 'Computer and Information Technology Services',
        'CONS': 'Construction/Contractors',
        'AGN': 'Consultancy/Agencies',
        'EDUC': 'Education',
        'ENG': 'Engineering',
        'ENT': 'Entertainment',
        'FIN': 'Financial Services',
        'FOV': 'Government',
        'HRS': 'Hotel and Restaurant Services',
        'HSE': 'Household Employees',
        'INV': 'Manufacturing and Inventory',
        'MED': 'Medical and Health Services',
        'SOC': 'Community, Social, Personal Services',
        'OTH': 'Others',
        'PR': 'Public Relations',
        'EST': 'Real Estate',
        'RENT': 'Rental Services',
        'MKTG': 'Sales/Marketing/Advertising',
        'SCI': 'Science and Technology Services',
        'STD': 'Student',
        'TCS': 'Transportation and Communication Services'
    }

    const SourceFunds  = {
        '001': 'Allowance',
        '002':'Business Profit',
        '003':'Commissions',
        '004':'Inheritance',
        '005':'Loan',
        '006':'Salary',
        '007':'Pension',
        '008':'Remittance'
    }

    const IDType = { 
        'SSS': 'SSS',
        'GSIS': ' GSIS',
        // 'TIN': 'TIN',
        'DRL': `Driver's License`,
        'AICR': ' ACR',
        // 'NBI': 'NBI',
        'PAS': 'Passport',
        'PHID': ' Philhealth ID',
        // 'SCH': 'School ID',
        'UMID': ' UMID',
        'VID': 'Voters ID',
        'PRC': 'PRC ID',
        'POS': 'Digitize Postal ID',
        'PIC': 'Philippine Identification Card'
    }

    // const KYCMain = () => {

    return(
        <Main noAppBar={ props && props.isAdminHistory }>
        <Grid container >
            <Grid lg={7}>
            <div>

                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{padding: '20px 0px 15px 0px', width: '524px'}} >
                        {localStorage.getItem("userType") == "CO" ? 
                        <div align='left' className={kycStyles.breadCrumbs}>

                            <a href={testMode ? '/test/multiwallet/' : '/multiwallet' } className={kycStyles.breadCrumbsHover}>Multiwallet</a> / <b><span style={{color: '#000000'}}>{firstName} {lastName}</span></b>
                       
                        </div>
                        : 
                        <div align='left' className={kycStyles.breadCrumbs}>

                            <a href="/submissions" className={kycStyles.breadCrumbsHover}>Submissions</a> / <b><span style={{color: '#000000'}}>{firstName} {lastName}</span></b>
                       
                        </div>

                    }
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                <Card
                    bodyStyle={{padding: '0px'}}
                    style={{
                        width: '524px',
                        marginTop:'10px',
                        borderRadius: '5px',
                        marginLeft: isMobile ? '150px': '0px'
                    }}>
                    <div style={{display: 'flex', justifyContent: 'space-between',padding: '20px 15px 15px 15px'}}>
                        <div style={Style.headerStyle}>Personal Information</div>
                    </div>
                    <hr></hr>
                    <div style={{padding: '20px 30px 15px 30px'}} >
                        <Grid container>
                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>Name:</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}>{firstName} {middleName} {lastName}</div>
                            </Grid>

                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>Mother's Maiden Name:</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}>{mothersMaidenName}</div>
                            </Grid>
                            
                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>Birthdate:</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}>{birthDate}</div>
                            </Grid>

                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>Birthplace:</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}>{birthPlace}</div>
                            </Grid>

                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>Present Address:</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}>{presentStreet}, {presentBarangay}, {presentCity}, {presentProvince}, {presentPostCode}</div>
                            </Grid>

                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>Nationality:</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}>{nationality}</div>
                            </Grid>
                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>Civil Status:</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}>{CivilStatus[civilStatus]}</div>
                            </Grid>

                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>Gender:</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}>{Gender[gender]}</div>
                            </Grid>


                        </Grid>
                    </div>
                    <hr></hr>
                    <div style={{display: 'flex', justifyContent: 'space-between',padding: '20px 15px 15px 15px'}}>
                        <div style={Style.headerStyle}>Contact information</div>
                    </div>
                    <hr></hr>
                    <div style={{padding: '20px 30px 15px 30px'}}>
                        <Grid container>
                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>Mobile Number:</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}>{mobileNumber}</div>
                            </Grid>
                            
                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>Email:</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}>{emailAddress}</div>
                            </Grid>
                        </Grid>
                    </div>
                    <hr></hr>
                    <div style={{display: 'flex', justifyContent: 'space-between',padding: '20px 15px 15px 15px'}}>
                        <div style={Style.headerStyle}>Employment Information</div>
                    </div>
                    <hr></hr>
                    <div style={{padding: '20px 30px 15px 30px'}}>
                        <Grid container>
                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>Employment Status:</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}>{EmploymentStatus[employmentStatus]}</div>
                            </Grid>
                            
                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>Employer Name:</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}>{employerName}</div>
                            </Grid>

                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>Employer Nature of Business:</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}>{NatureOfWork[employerNatureBusiness]}</div>
                            </Grid>
                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>Source of Funds:</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}>{SourceFunds[sourceFunds]}</div>
                            </Grid>
                            <div hidden={employmentStatus.includes("UE") || employmentStatus.includes("SE")}>
                                <Grid lg={5} xs={12}>
                                    <div style={Style.PresentStyle}>Employer Address</div>
                                </Grid>
                                <Grid lg={7} xs={12} style={{marginBottom: '16px'}} hidden={employmentStatus.includes("UE")}>
                                    <div style={Style.PermaStyle}>{empStreet}, {empBarangay}, {empCity}, {empProvince}, {empPostCode}</div>
                                </Grid>
                            </div>
                            
                        </Grid>
                    </div>
                    <hr></hr>
                    <div style={{display: 'flex', justifyContent: 'space-between',padding: '20px 15px 15px 15px'}}>
                        <div style={Style.headerStyle}>Identity Verification</div>
                    </div>
                    <hr></hr>
                    <div style={{padding: '20px 30px 15px 30px'}}>
                        <Grid container>
                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>ID Type:</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}>{IDType[idType]}</div>
                            </Grid>
                            
                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>ID Number:</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}>{idNumber}</div>
                            </Grid>

                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>ID Photo (Front):</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}><img src={idURL} style={{ width: '70%' }} onClick={()=>setIDModal(true)}/></div>
                            </Grid>
                        
                            <Grid lg={5} xs={12} hidden={idType == "PAS"}>
                                <div style={Style.PresentStyle}>ID Photo (Back):</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}} hidden={idType == "PAS"}>
                                <div style={Style.PermaStyle}><img src={backURL} style={{ width: '70%' }} onClick={()=>setBackIDModal(true)}/></div>
                            </Grid>

                            <Grid lg={5} xs={12}>
                                <div style={Style.PresentStyle}>Selfie Photo:</div>
                            </Grid>
                            <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                <div style={Style.PermaStyle}><img src={selfieBlob} style={{ width: '70%', height: '170px'}} onClick={()=>setSelfieModal(true)}/></div>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
                </div>
                </div>
            </Grid>

            {localStorage.getItem("userType") != "CO" ? 
            <Grid lg={4}>
                <div style={{justifyContent: 'center', marginTop: props.isAdminHistory? '68px' : '60px', background: '#FFF', marginLeft: isMobile ? '150px' : props.isAdminHistory? '24px' : '0px'}}>
                    
                    <div style={{display: 'flex', justifyContent: 'space-between',padding: '20px 20px 15px 20px'}}>
                        <div style={Style.headerStyle}>Approval</div>
                    </div>
                    <hr></hr>
                    <div style={{display: step > 7 ? '' : 'none', padding: '20px 20px 15px 20px'}} >

                        <div style={{justifyContent: 'center', marginBottom: '20px'}}>
                            <div style={{display: 'flex', width: '95%'}}>

                                <Grid lg={5}>
                                    <div style={Style.PresentStyle}>Status:</div>
                                </Grid>
                                <Grid lg={7}>
                                { step >= 10 ? 
                                    <div style={Style.ApprovedStyle}>Approved</div>
                                : step == 9 ?
                                    <div style={Style.RejectedStyle}>Rejected</div>
                                    
                                : step == 8 ?
                                    <div style={Style.PendingStyle}>Re-submitted</div>
                                    
                                : 
                                 <div style={Style.PendingStyle}>Pending</div>
                                    
                                }
                                </Grid>
                            </div>
                            <div style={{display: 'flex', width: '95%', marginTop: '15px'}}>

                                <Grid lg={5}>
                                    <div style={Style.PresentStyle}>EON Transaction ID:</div>
                                </Grid>
                                <Grid lg={7}>
                                    <div style={Style.PermaStyle}>BUXPRD{ pUid } / { transID }</div>
                                </Grid>
                            </div>
                            <div style={{display: 'flex', width: '95%', marginTop: '15px'}}>

                                <Grid lg={5}>
                                    <div style={Style.PresentStyle}>Remarks:</div>
                                </Grid>
                                <Grid lg={7}>
                                    <div style={Style.PermaStyle}>{ remarks }</div>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div style={{padding: '20px 20px 15px 20px'}} >

                        <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                            <div style={{width: '95%', justifyContent: 'center'}}>
                                <div style={Style.PresentStyle}>Remarks:</div>
                                <TextArea style={{ marginTop: '10px'}} rows={4} onChange={(e)=>setRemarks(e.target.value)} maxLength={200}/>
                            </div>
                        </div>
                        <Grid container>
                            <Grid lg={6}>
                                <Button style={Style.submitBtnStyle} onClick={() => approveSubmission(1)} disabled={loading}>Approve</Button>
                            </Grid>
                            <Grid lg={6}>
                                <Button style={Style.cancelBtnStyle} onClick={() => approveSubmission(0)} disabled={loading}>Reject</Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Grid>
            :
            ''
            }
            </Grid>
            <Modal
                visible={selfieModal}
                onCancel={()=>setSelfieModal(false)}
                footer={null}>
                
                <img src={selfieBlob} style={{width: '100%', height: '100%', marginTop: '20px'}}/>
            </Modal>
            <Modal
                visible={idModal}
                onCancel={()=>setIDModal(false)}
                footer={null}>
                
                <img src={idURL} style={{width: '100%', height: '100%', marginTop: '20px'}}/>
            </Modal>
            <Modal
                visible={backIdModal}
                onCancel={()=>setBackIDModal(false)}
                footer={null}>
                
                <img src={backURL} style={{width: '100%', height: '100%', marginTop: '20px'}}/>
            </Modal>
        </Main>
    )
    // }

    // return(
    //     props && props.isAdminHistory ?
    //     <KYCMain />
    //     :
    //     <Main>
    //         <KYCMain />
    //     </Main>
    // )
}

export default KYCIndividual