import React, {useEffect} from 'react'
import { Button,Input, Divider,Spin, Drawer, Alert, Select } from 'antd';
import  axios from 'axios';
import {history} from '../../../store/history'
import share_gmail from '../../../static/icons/share_gmail.png';
import share_email from '../../../static/icons/share_email.svg';
import share_messenger from '../../../static/icons/share_messenger.svg';
import share_sms from '../../../static/icons/share_sms.png';
import share_instagram from '../../../static/icons/share_instagram.png';
import share_viber from '../../../static/icons/share_viber.png';
import share_whatsapp from '../../../static/icons/share_whatsapp.png';
import {isMobile, isIOS, isAndroid, isWinPhone, browserName} from 'react-device-detect';
import MetaTag from '../../meta_tag/meta_tag'
import * as Style from './generate_code_style'
import Beta from '../../generate_drawer/beta.svg'
import * as style from '../../generate_drawer/style'
import ShareQrLink from '../../generate_drawer/share_qr_link'

import QRCode from 'react-qr-code';
import { Download } from '../../../static/icons/bux_qr/index';
import { exportComponentAsPNG } from 'react-component-export-image';
import { message as msg } from 'antd'

export const GenerateActionTypes = {
    GENERATE_REQUEST: "@@GENERATE_REQUEST",
    GENERATE_SUCCESS: "@@GENERATE_SUCCESS",
    GENERATE_FAILED: "@@GENERATE_FAILED",
    CLEAR: "@@CLEAR"
  };

const APP_BASE_URL = process.env.REACT_APP_BASE_URL
const FB_APP_ID = process.env.REACT_APP_FB_ID
const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL
const fb_browser = (browserName == 'Facebook') || (browserName == 'WebKit')
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
const REACT_APP_API_URL = process.env.REACT_APP_API_URL
const testMode = history.location.pathname.indexOf('/test') > -1;


const GenerateCode = props => {

  const yourConfig = {
    headers: {
        Authorization: "Token " + localStorage.getItem("jwtToken")
    }
} 

  const checkTranxFee = async () => {
    try{
        let response = await axios.get(
            process.env.REACT_APP_API_URL + "/api/settings/",
            yourConfig
        );
        setTranxFeeShouldered(response.data.shoulder_enabled);

    }
    catch(error){
        if((error.response)&&(error.response.status == 401)){
          console.error(error);
        }
    }
  }

  useEffect(() => {
    let current_path = window.location.pathname
      if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
          history.push('/messenger?next='+current_path)
        }else{


          if(!isMobile){
            history.push('/messenger/error')
          }
          else if(localStorage.getItem("userType") == "AD" || localStorage.getItem("userType") == "OP"){
            history.push('/orders')
          }
          else if(localStorage.getItem("userType") == "PS"){
              history.push('/orders')
          }
          else if(localStorage.getItem("userType") == "PF"){
              history.push('/recon/7_eleven')
          }
          else if(localStorage.getItem("userType") == "MA"){
              history.push('/overview')
          }
          else if(localStorage.getItem("userType") == 'SW'){
              history.push('/mobile/more')
          }
          // else if((localStorage.getItem("contact") == "" && !isMobile || localStorage.getItem("industry") == "") && localStorage.getItem("userType") == "ME" && !isMobile) {
          //   history.push('/survey_business')
          // }
          else{
            
            loadFbPlugin();
            checkTranxFee();
            getDetails();
          }
        }
  
        
  }, []);

  const [generation_form, hideGenerationForm] = React.useState(false);
  const [share_code_form, showGenerationForm] = React.useState(true);
  const [loading_form, showLoadingForm] = React.useState(true);
  const [alertAmountError, setAlertAmountError] = React.useState(false)
  const [message, setMessage] = React.useState(true)

  const { TextArea } = Input;

  const [amount, setAmount] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [expire_at, setExpireAt] = React.useState("6");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [expiryDate, setExpiryDate] = React.useState("");

  const [ref_code, setCode] = React.useState("");
  const [ref_code_image, setImage] = React.useState("");
  const [uid, setUID] = React.useState("");

  const maxAmount = process.env.REACT_APP_MAXIMUM_AMOUNT ? process.env.REACT_APP_MAXIMUM_AMOUNT : 30000
  // const entMaxAmount = process.env.REACT_APP_MAXIMUM_AMOUNT ? process.env.REACT_APP_MAXIMUM_AMOUNT : 80000
  const minAmount = process.env.REACT_APP_MINIMUM_AMOUNT ? process.env.REACT_APP_MINIMUM_AMOUNT : 50

  const [tranxFeeShouldered, setTranxFeeShouldered] = React.useState(false);

  // QR code
  const paymentQrRef = React.useRef();
  const paymentLink = uid && uid? `${REACT_APP_BASE_URL}${testMode ? '/test/checkout/' : '/checkout/'}${uid}` : '';

  async function generate (value) {
      let params = {
          amount: value.amount,
          description: value.description,
          expireAt: value.expireAt,
          name: value.name,
          email: value.email,
          phone: value.phone,
        };

        try {
          const yourConfig = {
              headers: {
                  Authorization: "Token " + localStorage.getItem("jwtToken")
              }
            }

          let response = await axios.post(
            process.env.REACT_APP_API_URL + "/api/orders/generate/",params,
            yourConfig
          );
          
          let data = response.data;
            console.log(data)
          if(response.data.status == "success"){
            window.message = {
                "attachment": {
                    "type": "template",
                    "payload": {
                        "template_type": "generic",
                        "elements": [{
                            "title": "Ref code: " + data.data.code,
                            "subtitle": value.amount + " PHP",
                            "image_url": data.data.image_url,
                            "default_action": {
                                "type": "web_url",
                                "url": process.env.REACT_APP_BASE_URL + '/payment/' + data.data.uid      
                            },
                        }]
                    }
                }
            };
            setExpiryDate(data.data.expiry)
            setCode(data.data.code)
            setImage(data.data.image_url)
            setUID(data.data.uid)
            showGenerationForm(false)
        }else{
          setAlertAmountError(true)
          showGenerationForm(true)
          hideGenerationForm(false)
          setMessage(response.data.message)
        }

        } catch (error) {
          showGenerationForm(true)
          setAlertAmountError(true)
          hideGenerationForm(false)
          setMessage("Something Went wrong")
        }
        showLoadingForm(true)
  }

  function loadFbPlugin() {
      (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) { return; }
          js = d.createElement(s); js.id = id;
          js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
          fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'Messenger'));
      loadMessengerExt();
  }

  function loadMessengerExt() {

      window.extAsyncInit = function () {
         window.MessengerExtensions.getContext(process.env.REACT_APP_FB_ID ,

          function success(thread_context) {
            window.fbuid = thread_context.psid;
          }, function error(err, errorMessage) {
            window.fbuid = '';

          });
      
      
      };

  }

  function handleExpiration(value){
    setExpireAt(value)
  }
  
  function sendBarCode() {
    window.location = `fb-messenger://share/?app_id=${FB_APP_ID}&link=${APP_BASE_URL}/checkout/${ uid }%3Futm_source%3Dbux%26utm_medium%3Dcode-facebook`;
    /*
      window.MessengerExtensions.beginShareFlow(function (share_response) {
          if (share_response.is_sent) {
              window.MessengerExtensions.requestCloseBrowser();
          }
      },
          function (errorCode, errorMessage) {
              // An error occurred in the process

          },
          window.message,
          "current_thread");
          */


  }

  const {Option} = Select
  

  function generateCodeNow() {
      let params = {
        amount: parseFloat(amount),
        description: description,
        expireAt: expire_at,
        name: name,
        email: email,
        phone: phone,
      };
      

        if((localStorage.getItem("userType") === "ME") && (parseFloat(amount) < minAmount || parseFloat(amount) > maxAmount)){
        setAlertAmountError(true)
        setMessage("Amount must be PHP 50 up to PHP 30, 000")
        window.scrollTo(0,0);

      }

      else if((localStorage.getItem("userType") === "CO") && (parseFloat(amount) < minAmount)) {
        setAlertAmountError(true)
        setMessage("Amount must be 50 PHP up to 80,000 PHP")
        window.scrollTo(0,0);
      }

      else if(amount == "" || description == "" || name == "" || phone == "" || email == ""){
        setAlertAmountError(true)
        setMessage("Please complete the form")
        window.scrollTo(0,0);

      }
      else if(description.length > 200){
        setAlertAmountError(true)
        setMessage("Description must 200 characters or below")
        window.scrollTo(0,0);

      }
      else if(phone.length != 10){
        setAlertAmountError(true)
        setMessage("Invalid mobile number format")
        window.scrollTo(0,0);

      }
  
      else if(email.length > 100){
        setAlertAmountError(true)
        setMessage("Email must 100 characters or below")
        window.scrollTo(0,0);

      }
      else{
        hideGenerationForm(true)
        showLoadingForm(false)
        generate(params);
      }
      
  }

  let code, plink;

  const copyClipBoard1 = () => {
      code.select()
      document.execCommand('copy')
  }

  const copyClipBoard = () => {
    plink.select()
    document.execCommand('copy')
  }
    
    
  let emailBody = `Hi BUx Customer<br>
  <br>Merchant has sent you a payment link. You may click or copy the Payment Link for you to be able to access the checkout page.<br>Payment Link Details:
  <br><br>Amount: ${amount}
  <br>Payment Link: ${APP_BASE_URL}/checkout/${ uid }
  <br>Payment link will expire on: ${expiryDate}
  <br><br>Payment details and merchant information can be found on the URL.
  <br><br>Thank you!`

  let emailBody2 = `Hi BUx Customer
      
Merchant has sent you a payment link. You may click or copy the Payment Link for you to be able to access the checkout page.

Payment Link Details:
Amount: ${amount}
Payment Link: ${APP_BASE_URL}/checkout/${ uid }
Payment link will expire on: ${expiryDate}

Payment details and merchant information can be found on the URL.

Thank you!`

  let y = encodeURIComponent(emailBody2)
  let z = encodeURIComponent(emailBody)
  
  let emailSubject = `Payment Request from ${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}: Payment Link will expire on ${expiryDate}`

  function onChange(e) {
    setExpireAt(e.target.value)
    
  }
  const smsDisplay = () => {
    if(isAndroid){
      return (<a href={`sms:?body=${ y }`}>
                    <img src={share_sms} style={{ width: '40px', marginRight: '10px'  }} alt="#" /></a>);
    }
    else if(isIOS){
      return (<a href={`sms:?&body=${ y }`}>
                    <img src={share_sms} style={{ width: '40px', marginRight: '10px' }} alt="#" /></a>);
    }
    else if(isWinPhone){
      return (<a href={`sms:/?body=${ y }`}>
                    <img src={share_sms} style={{ width: '40px', marginRight: '10px'  }} alt="#" /></a>);
    }
    else{
      return;
    }
  }

  const fbDisplay = () => {
      return (<a href={`fb-messenger://share/?app_id=195672864228468&link=${APP_BASE_URL}/checkout/${ uid }%3Futm_source%3Dbux%26utm_medium%3Dcode-facebook`}><img src={share_messenger} style={{ width: '40px', marginRight: '10px' }} alt="#" /></a>);
  }



  const viberDisplay = () => {
    return (<a href={`viber://pa?chatURI=buxph&text=${ y }`}><img src={share_viber} style={{ width: '40px', marginRight: '10px'  }} alt="#" /></a>);
    
  }


  const whatsappDisplay = () => {
    return (<a href={`whatsapp://send?text=${y}`}><img src={share_whatsapp} style={{ width: '40px', marginRight: '10px' }} alt="#" /></a>);
    
  }



  // const igDisplay = () => {
  //   return (<a href={`instagram://share/?link=${APP_BASE_URL}/payment/${ uid }/&app_id=195672864228468`}><img src={share_instagram} style={{ width: '40px', marginRight: '5px' }} alt="#" /></a>);
  // }

  function onChange(e) {
  setExpireAt(e.target.value)
  
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      generateCodeNow()
    }
  }
  //share qr link 
  const buttonText = ['Payment Link', 'My Checkout']

  const [actionBtn, setActionBtn] = React.useState('Payment Link')
  const [tradeName, setTradeName] = React.useState("")
  const qrLink = testMode ? REACT_APP_BASE_URL+'/'+tradeName+'/test' : REACT_APP_BASE_URL+'/'+tradeName
  const notFound = REACT_APP_BASE_URL+'/404/'
  const level = localStorage.getItem("Level")
  const ButtonGroup = Button.Group;
  const [qrOn, setQrOn] = React.useState(false)
  const [data, setData] = React.useState({})

  async function getDetails () {
    try {

        let url = REACT_APP_API_URL + `/api/qr_details/`

        let response = await axios.get(url, yourConfig)
        console.log(response.data)
        if(response.data.status === 'failed') {
            message.error(response.data.message, 3)
            setQrOn(!qrOn)
            setActionBtn("")
        }

        else {
            setData(response.data)
            setQrOn(response.data.qr_enabled)
            setTradeName(response.data.trade_name)

        }
    }

    catch (error) {
        console.log(error)
    }
  }

  const QrOnly = React.forwardRef(() => (
    <div align="center" style={{position: 'absolute', top: '-11000px'}}>
        <div ref={paymentQrRef} style={{padding: '4px', backgroundColor: '#FFF'}}>
            <QRCode value={paymentLink} size={460} level="L" />
        </div>
    </div>
  ))

  return(
    <div>
      <div hidden={level === 0 || !actionBtn || !qrOn} style={{paddingTop: 16, textAlign: 'center'}}>
        <ButtonGroup>
              {
                  buttonText.map((item) => {
                      return <Button
                          key={item.toLocaleLowerCase()}
                          onClick={() => setActionBtn(item)}
                          style={{
                          ...style.buttonStyle,
                          borderTopLeftRadius: item == 'Payment Link' ? '10px' : '0px',
                          borderBottomLeftRadius: item == "Payment Link" ? '10px' : '0px',
                          borderTopRightRadius: item == "My Checkout" ? '10px' : '0px',
                          borderBottomRightRadius: item == "My Checkout" ? '10px' : '0px',
                          color: actionBtn === item ? '#ffffff' : '#2b2d33',
                          backgroundColor: actionBtn === item ? '#0D3D76' : '#fff',
                          }}>{item}</Button>
                      })
              }
            
        </ButtonGroup>
      </div>
      <MetaTag title="Generate Link"/>
      <QrOnly />
      <div style={{padding: '20px'}} hidden={actionBtn != 'Payment Link'}>
          <div hidden={generation_form}>
          <Alert message={message} type="error" style={{ display: alertAmountError ? "block" : "none", borderLeft: '4px solid red'}}/>
          <div style={Style.labelStyle}>Amount (₱)</div>
          <Input data-test='amount_input' value={amount} type="number" onChange={e => { if(e.target.value.includes(".")){ 
                                                                    if(e.target.value.split(".")[1].length>2){ 
                                                                        setAmount(parseFloat(e.target.value).toFixed(2))
                                                                    }else{
                                                                          setAmount(e.target.value)
                                                                    }
                                                                }else{
                                                                    setAmount(e.target.value)
                                                                }
                                                              }} size="large"/>
          <div style={{paddingBottom: '10px'}}><span style={{fontFamily: 'Inter', fontWeight: '500', fontStyle: 'Italic', fontSize: '10px'}}>
            <strong>Transaction Fee</strong>{tranxFeeShouldered? ' will be deducted from your total payment collection.' : ` will be added to the total amount upon customer checkout.`} </span>
          </div>
          <div style={Style.labelStyle}>Description</div>
          <TextArea data-test='desc_input' value={description} rows="4" onChange={e => setDescription(e.target.value)} style={{marginBottom: '20px'}}/>

          <div style={Style.labelStyle}>Payment Link Expiration</div>
            <Select
                value={expire_at}
                data-test='expiry_select'
                showSearch
                style={{ width: '100%', height: '40px', marginBottom: '20px' }}
                size="large"
                onChange={handleExpiration}
                defaultValue="6"
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                <Option value="6">6 hours</Option>
                <Option value="12">12 hours</Option>
                <Option value="24">1 day</Option>
                <Option value="48">2 days</Option>
                <Option value="72">3 days</Option>
                <Option value="168">7 days</Option>
            </Select>

          <div style={Style.customerHeader}>Customer’s Information</div>
          <Divider style={{marginTop: '10px', marginBottom: '10px'}}></Divider>
          <div style={Style.labelStyle}>Customer Name</div>
          <Input value={name} data-test='name_input' size="large" style={{marginBottom: '10px'}} onChange={(e)=>setName(e.target.value)} maxLength={60}/>
          <div style={Style.labelStyle}>Customer Email</div>
          <Input value={email} data-test='email_input' size="large" style={{marginBottom: '10px'}} onChange={(e)=>setEmail(e.target.value)} maxLength={100}/>
          <div style={Style.labelStyle}>Customer Mobile Number </div>
          <Input value={phone} data-test='phone_input' size="large" type="number" style={{marginBottom: '30px'}} addonBefore="+63" maxLength={10} value={phone} onChange={e => { if(e.target.value.length <= 10){setPhone(e.target.value)}}} onKeyPress={(e)=>handleKeyPress(e)}/>
          
          <Button data-test='generate_btn' size="large" type="primary" style={amount == "" || description == "" || phone == "" || email == "" || name == "" ? Style.generateBtn1 : Style.generateBtn} block onClick={() => generateCodeNow()} disabled={amount == "" || description == "" || phone == ""  || email == ""  || name == "" ? true : false}> Generate Payment link </Button>

          </div>
          <div hidden={loading_form}>

              <div style={{display: 'flex', justifyContent:'center', marginTop: '50px'}}>
              <Spin size="large" />
              </div> 
              
          </div>
          <div hidden={share_code_form}>
              <div>
                  <div style={Style.titleStyle}>Share Link</div>

                  <div style={{height: '178px', backgroundColor: '#E6EAF0', borderRadius: '4px', padding: '2px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)'}}>
                      <div style={{width: '100%', height: '53px', backgroundColor: '#fff', borderRadius: '4px 4px 0px 0px', padding: '15px 0px 0px 15px'}}>
                        
                          <div style={Style.headerStyle}>Payment Request to:</div>
                                              
                      </div>
                      <div style={{width: '100%', height: '77px', backgroundColor: '#fff', marginTop: '2px', padding: '15px 0px 0px 15px'}}>

                          <div style={Style.headerStyle}>{name}</div>
                          <div style={Style.customerName}>{email}</div>

                      </div>

                      <div style={{display: 'flex', justifyContent: 'space-between', height:'41px', padding: '10px 17px 0px 17px', backgroundColor: '#F1F2F6'}}>
                          <div style={Style.customerName}>Amount:</div>
                          <div style={Style.headerStyle}>PHP {parseFloat(amount).toLocaleString('en-US', {minimumFractionDigits: 2})}</div>                              
                      </div>
                  </div>

                  <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '12px', marginTop: '32px'}}>
                    <QRCode value={`${REACT_APP_BASE_URL}${testMode ? '/test/checkout/' : '/checkout/'}${uid}`} size="140" level={'L'} />
                    <button style={Style.btnstyle} onClick={() => { msg.loading("Please Wait...", 1.5); exportComponentAsPNG(paymentQrRef);}}>
                        <img src={Download} alt="logo" />
                        <div style={Style.btnText}>Download</div>
                    </button>
                  </div>

                  <div style={{marginTop: '30px'}}>
                      <div style={Style.headerStyle}>Payment Link</div>
                      <div style={Style.copyOrShare}>Copy or Share this link for payment</div>
                  </div>
          
                  <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>

                    <Input type="text" readOnly value={`${REACT_APP_BASE_URL}/checkout/${uid}`} size="large" style={{ position: 'absolute', top: '-10000px'}} ref={(textarea) => plink = textarea}/>

                    <div style={Style.linkDiv}><a  style={Style.linkStyle} href={`${REACT_APP_BASE_URL}/checkout/${uid}`} target="_blank">{`${REACT_APP_BASE_URL}/checkout/${uid}`}</a></div>

                    <Button style={Style.generateBtn} onClick={()=>copyClipBoard()}>Copy</Button>
                  </div>

                  <div>
                    <Divider style={{marginTop: '20px', marginBottom: '20px'}}><div style={Style.shareViaStyle}>Or Share this link via</div></Divider>

                    <a href={`mailto:?subject=${emailSubject}&body=${z}`} title="Email">
                      <img src={share_email} style={{ width: '40px', marginRight: '10px', marginLeft: '20px' }} alt="#" />
                    </a>
                    { fbDisplay() }
                    { smsDisplay() }
                    { viberDisplay() }
                    { whatsappDisplay() }
                  </div>

                  <Button size="large" hidden={!fb_browser} block style={{ marginBottom: '15px', marginTop: '20px' }} onClick={()=>sendBarCode()}> Send to conversation </Button>
                  <div style={{ marginTop: fb_browser ? '0px' : '20px'}}>
                    <Button size="large" style={Style.generateBtn} block onClick={()=>history.push('/dashboard')}> Done </Button>
                  </div>

              </div> 
              
          </div>
      </div>
    
      <Drawer
            placement="bottom"
            width="100%"
            height="100%"
            visible={actionBtn == 'My Checkout'}
            onClose={() => setActionBtn('Payment Link')}>
              
              <div style={{padding: 20, marginTop: 32}}>
                <ShareQrLink qrOn={qrOn} qrLink={qrLink} notFound={notFound} data={data} tradeName={tradeName} />
              </div>

      </Drawer>
    </div>

  )
}
export default GenerateCode