import React,{ useEffect, useState } from 'react';
import { Card, Switch, message } from 'antd';
import axios from 'axios';
import {Handle401} from '../../../handle401/handle401'
import feesServices from '../feesServices';

const MultiwalletCheckoutToggle = ({pUid, init_enabled}) => {
  const [enabled, setEnabled] = useState(init_enabled);
  const [disabled, setDisabled] = useState(false);

    useEffect(() => {
      setEnabled(init_enabled)
    }, [init_enabled])

  const handleChange = (event) => {
    setDisabled(true);
    updateCheckoutEnabled(enabled);
  }

  const updateCheckoutEnabled = async (value) => {
    setDisabled(true);
    let venabled = 0;
    if(!value){
      venabled = 1;
    }
    try {
        const res = await feesServices.multiwalletCheckoutToggle(pUid, venabled);
        if (res && res.status !== 'success') {
            message.error('Someting went wrong, try again.');
            setEnabled(!venabled);
        } else {
          setEnabled(venabled)
        }
        console.log('TOGGLE', res);
    } catch (error) {
        console.error(error);
        message.error('Someting went wrong, try again.');
        setEnabled(!venabled);
    }
    setDisabled(false);
  }


  return (
    <Card size="small" style={styles.root}>
      <div style={styles.main}>
        <div style={styles.textContent}>
          <span style={styles.font1}><strong>Sub-wallet checkout is {enabled ? 'enabled' : 'disabled'}</strong></span>
        </div>
        <Switch checked={enabled} onChange={handleChange} style={{margin: '12px 20px 12px 12px', backgroundColor: `${enabled ? '#F5922F' : '#D1D5DD'}`}} disabled={disabled}/>

      </div>
    </Card>
  )
}

const styles = {
  root: {
    margin: '0px 12px 20px 12px'
  },
  main: {
    padding: 16,
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'space-between',
  },
  textContent: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  font1: {
    color: '#000000',
    fontSize: 16,
    fontWeight: '500'
  },
  font2: {
    color: '#909196',
    fontSize: 14,
    textAlign: 'left'
  },
}

export default MultiwalletCheckoutToggle;