import axios from 'axios';
import { Handle401 } from '../../handle401/handle401';

const authorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const unauthorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
}

const feedbackServices = {
  getFeedbackStats: async (summary='', filter_value='2022-07', start='', end='') => {
    try {
      const res = await authorizedAxiosRequest().get(`api/admin/user_feedback/stats/?summary=${summary}&filter_value=${filter_value}&start=${start}&end=${end}`)
      return res.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  getFeedbackGallery: async (segment='ME_,GIG,SME,BIZ,NIL', page=1, start='', end='', search='') => {
    if(segment==='all'){
      segment = 'ME_,GIG,SME,BIZ,NIL';
    }
    try {
      const res = await authorizedAxiosRequest().get(`api/admin/user_feedback/gallery/?segment=${segment}&page=${page}&start=${start}&end=${end}&search=${search}`)
      return res.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  exportFeedbackReport: async () => {
    try {
      const res = await authorizedAxiosRequest().get(`api/admin/user_feedback/export/`)
      return res.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  }
}


export default feedbackServices;