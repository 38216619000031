import React, {useRef} from 'react'
import {Input, message, Icon} from 'antd'
import { isMobile } from 'react-device-detect'
import SupportImg from '../../../static/img/support.svg'
import Warning from '../../../static/icons/warning.svg'
import ResetSecretModal from './reset_secret_modal';
import axios from 'axios';
import NoteCard from '../../kyb/forms/noteCard'

const Credentials = ({segments, apiKey, apiSecret, clientID, resetSecretSuccess}) => {
    const [resetModal, setResetModal] = React.useState(false);
    const userId = localStorage.getItem('userId');

    let api_key, api_secret, client_id


    function copyAPIKey(){
        api_key.select()
        document.execCommand("copy")
        message.destroy()
        message.info("Copied API Key")
        api_key.blur()
    }

    function copyAPISecret(){
        var dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.value = apiSecret;
        dummy.select();
        document.execCommand("copy")
        dummy.remove();
        message.destroy()
        message.info("Copied Client Secret")
        api_secret.blur()


    }

    function copyClientID(){
        client_id.select()
        document.execCommand("copy")
        message.destroy()
        message.info("Copied Client ID")
        client_id.blur()


    }

    const messageNotif = (type, msg) => {
        if(type === 'success') {
            message.success(msg)
        } else {
            message.error(msg);
        }
    }

    const resetSecret = async () => {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try{
            let response = await axios.get(
                process.env.REACT_APP_API_URL + '/api/user/reset_secret/', yourConfig
            );

            if(response.data.status === "success"){
                messageNotif('success', response.data.message);
                setResetModal(false);
                resetSecretSuccess();
            }
            else{
                messageNotif('error', response.data.message);
            }
        }
        catch (error) {
            if((error.response)&&(error.response.status == 401)){
                messageNotif('error', 'Error try again.');
            } else {
                messageNotif('error', 'Error try again.');
            }
        }
    }

    return(
        <div>
            <div className="credentials-div-card top-32" style={{marginTop: isMobile && '16px'}}>
                <div className="bold-text-size liveColor"> 
                    Integration Credentials
                </div>
                <div className="normalTextStyle top-12"> 
                    Copy your BUx credentials to your BUx configuration page of your website or app.
                </div>

                <div>
                    <div className="top-20 smallTextStyle">
                        API Key
                    </div>
                    <div className="flex">
                        <Input size="large" value={apiKey} ref={(textarea) => api_key = textarea} className="right-8"/> 
                        <button
                        className={`btn--${segments} btn-height`} 
                        onClick={() => copyAPIKey()}>
                            Copy
                        </button>
                    </div>

                    <div className="top-20 smallTextStyle">
                        Client ID
                    </div> 

                    <div className="flex">
                        <Input size="large" value={clientID} ref={(textarea) => client_id = textarea} className="right-8"/>

                        <button
                        className={`btn--${segments} btn-height`} 
                        onClick={() => copyClientID()}>
                            Copy
                        </button>
                    </div>
                    <div className="top-20 smallTextStyle">
                        Client Secret
                    </div>

                    <div className="flex">
                        <Input type="password" size="large" value={apiSecret} ref={(textarea) => api_secret = textarea} className="right-8"
                        suffix={<a href className={`text-${segments}`} onClick={() => setResetModal(true)} >Reset</a>}  />
                        <button
                        className={`btn--${segments} btn-height`} 
                        onClick={() => copyAPISecret()}>
                            Copy
                        </button>
                    </div>
                </div>
            </div>

            <div className="creds-note-div top-20">
                <NoteCard note={
                    <span>
                        To provide you assistance with your integration and to fully upgrade your Test Account send us an email request at 
                        <a href="mailto:support@bux.ph" className="nameStyle liveColor left-4">support@bux.ph</a>
                    </span>
                }/>
                
            </div>

            <ResetSecretModal segments={segments} visible={resetModal} closeModal={() => setResetModal(false)} resetSecret={resetSecret} />

            </div>
    )
}

const Style = {
    cardHeadStyle: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: isMobile ? '14px' : '16px',
        color: '#000000',
        paddingBottom: 8,
    },
    labelStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 12,
        // lineHeight: '24px',
        color: '#000000',
    },
    copyBtn: {
        backgroundColor: 'rgb(245, 146, 47)',
        color: '#fff',
    },
    alert:{
        fontSize: '12px',
        color: '#54575f',
        fontWeight: '400',
        textAlign: 'center',
        margin: '10px 0px 20px 0px'
    },
    resetBtn: {
        color: '#E24C4C',
        fontSize: 12,
        fontWeight: '500'
    }
    
}

export default Credentials