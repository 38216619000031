import React, {useEffect} from 'react';
import { Card, DatePicker, message, Spin, Table, Select, Icon} from 'antd';
import dashboardStyles from '../../../static/css/Dashboard';
import moment from 'moment';
import axios from "axios";
import {history} from '../../../store/history';
import { Handle401 } from '../../handle401/handle401';

const Option = Select;

const BankLedger = props => {

  const db = dashboardStyles();
  const [report_data, setData] = React.useState(null);
  const [balance_data, setBal] = React.useState(null);
  //Setting date and moment
  const [startMoment, setStartMoment] = React.useState("")
  const [startDate, setStartDate] = React.useState("")
  const [endMoment, setEndMoment] = React.useState("")
  const [endDate, setEndDate] = React.useState("")
  const [endOpen, setEndOpen] = React.useState(false)
  const [tranType, setTranType] = React.useState("C")
  const [showExportModal, setShowExportModal] = React.useState(false);
    
  const yourConfig = {
    headers: {
        Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  }

  const disabledStartDate = startValue => {
    if (!startValue || !endMoment) {
      return false;
    }
    return startValue && startValue > moment().endOf('day');
  };

  const disabledEndDate = endValue => {
    if (!endValue || !startMoment) {
      return false;
    }
    return endValue.valueOf() <= startMoment.valueOf();
  };
  
  const onStartChange = value => {
    if(value != null){
      setStartMoment(value)
      setStartDate(value.format("YYYY-MM-DD"));
    }
    else{
      setStartMoment(null);
      setStartDate("");
    }
  }

  const onEndChange = value => {
    if(value != null && startDate != ""){
      setEndMoment(value);
      setEndDate(value.format("YYYY-MM-DD"));
      fetchData(startDate,value.format("YYYY-MM-DD"));
    }
    else{
      setEndMoment(null);
      setEndDate("");
    }
  }

  const handleStartOpenChange = open => {
    if (!open) {
      setEndOpen(true);
    }
  };

  const handleEndOpenChange = open => {
    setEndOpen(open)
  };
  
  const renderColumns = () => {
    if(report_data)
      return report_data
    else
      return []
  }

  async function fetchData (start, end) {
    try{
      let response = await axios.get(
        process.env.REACT_APP_API_URL + '/api/admin/bank/ledger/?start='+start+"&end="+end+"&type="+tranType,
        yourConfig
      );
      console.log(response.data)
      setData(response.data)
    }

    catch(error){
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      } else {
        message.error(error.response.data.message);
      }
    } 
  }

  async function exportData () {
    try{
      let response = await axios.get(
        process.env.REACT_APP_API_URL + '/api/admin/bank/ledger/?export=True&start='+startDate+"&end="+endDate+"&type="+tranType,
        yourConfig
      );
      console.log(response.data)
      setData(response.data)
    }

    catch(error){
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      } else {
        message.error(error.response.data.message);
      }
    } 
  }

  useEffect(() => {
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
      history.push('/login')
    }
    else{
      if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
        history.push('/dashboard')
      }
      else if(localStorage.getItem("userType") == "PS"){
          history.push('/orders')
      }
      else if(localStorage.getItem("userType") == "PF"){
          history.push('/recon/7_eleven')
      }
      else if(localStorage.getItem("userType") == "MA"){
          history.push('/overview')
      }
      else{
          fetchData("", "")
      } 
    }
  }, []);

  const columns = [
    {
      title: 'TranID',
      dataIndex: 'tranId',
    },
    {
      title: 'Type',
      dataIndex: 'tranType',
    },
    {
      title: 'Date',
      dataIndex: 'tranDate',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Posting Date',
      dataIndex: 'postedDate',
    },
    {
      title: 'Description',
      dataIndex: 'tranDescription',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
    },
    {
      title: 'Remarks 2',
      dataIndex: 'remarks2',
      },
    {
      title: 'Running Balance',
      dataIndex: 'balance',
      }
  ];

  return(
    <div className={db.main}>
      <div className={db.dashboardStyle}>
          Summary
      </div>
      <div className={db.card}>
        <div>
          <div className={db.textStyle}>
              Select Period:
          </div>
          
          <div style={{ marginTop: '10px', display: 'flex'}}>
            <DatePicker
              style={{zIndex: 1}}
              disabledDate={disabledStartDate}
              size="large"
              format='YYYY-MM-DD'
              value={startMoment || null}
              placeholder="Start"
              onChange={onStartChange}
              onOpenChange={handleStartOpenChange}
            />
            <span style={{padding: '10px'}}>-</span> 
            <DatePicker
              style={{zIndex: 1, marginRight: '12px'}}
              disabledDate={disabledEndDate}
              size="large"
              format='YYYY-MM-DD'
              value={endMoment || null}
              placeholder="End"
              onChange={onEndChange}
              open={endOpen}
              onOpenChange={handleEndOpenChange}
            />

            <Select
                size="large"
                placeholder="Type"
                style={{width:'120px', marginRight: '12px', zIndex: 1, marginBottom: '20px'}}
                onChange={(e) => {
                    setTranType(e);
                    fetchData (startDate, endDate);
                }}>
                  <Option value="C" selected>Credit</Option>
                  <Option value="D">Debit</Option>
            </Select>

          
            <div>
                    <button className={`outline-btn`} onClick={() => exportData()} ><Icon type='download' /> Export CSV</button>
            </div>
          </div>
        </div>
      </div>
      
      <Table
        dataKey={record => record.id}
        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        columns={columns}
        dataSource={renderColumns()}
        pagination={false} />

    </div>
    )
}

export default BankLedger