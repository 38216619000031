import React, { useState } from "react";
import { Card, Typography, Modal } from "antd";

const { Title, Text } = Typography;

export function RemittanceDetails({ data, isLoading, isError }) {
  const [isImagePreviewVisible, setIsImagePreviewVisible] = useState(false);

  if (isError) {
    return <div>Error</div>;
  }

  if (isLoading) {
    return <div>Loading</div>;
  }

  return (
    <Card
      title="Remittance Details"
      loading={isLoading}
      style={{ width: "500px" }}
    >
      {data && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Business:</Title>
            <Text type="secondary">{data.merchant_business}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Merchant Name:</Title>
            <Text type="secondary">{data.merchant_name}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Merchant Email:</Title>
            <Text type="secondary">{data.merchant_email}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Receiver:</Title>
            <Text type="secondary">{data.receiver_details.contact_info.first_name} 
              {data.receiver_details.contact_info.last_name}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Receiver Email:</Title>
            <Text type="secondary">{data.receiver_details.email}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Sender</Title>
            <Text type="secondary">{data.sender_details.first_name} {data.sender_details.last_name}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Control Number:</Title>
            <Text type="secondary">{data.control_number}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Transaction Purpose</Title>
            <Text type="secondary">{data.receiver_details.transaction_purpose}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Identification:</Title>
            <Card
              style={{ cursor: "pointer" }}
              onClick={() => setIsImagePreviewVisible(true)}
            >
              <img
                src={data.proof_of_id}
                alt="Proof of Ownership document"
                style={{ width: "100px" }}
              />
            </Card>
          </div>
          <IdentificationPreview
            url={data.proof_of_id}
            isImagePreviewVisible={isImagePreviewVisible}
            setIsImagePreviewVisible={setIsImagePreviewVisible}
          />
        </>
      )}
    </Card>
  );
}

function IdentificationPreview({
  url,
  isImagePreviewVisible = false,
  setIsImagePreviewVisible,
}) {
  return (
    <Modal
      title="Image Preview"
      visible={isImagePreviewVisible}
      onCancel={() => setIsImagePreviewVisible(false)}
      footer={null}
    >
      <img
        src={url}
        alt="Proof of Ownership preview"
        style={{ width: "100%" }}
      />
    </Modal>
  );
}
