import React from "react";
import { Input, Modal, Select, Form, Button } from "antd";

function NewLimitModal({
  form,
  daily_limit,
  transfer_limit,
  ownerId,
  open,
  handleOk,
  confirmLoading,
  handleCancel,
  updateFields,
  enterprisePeers,
}) {
  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        if (!values.daily.trim() && !values.transaction.trim()) {
          form.setFields({
            daily: {
              errors: [
                new Error(
                  "Please enter a value for either daily or transaction."
                ),
              ],
            },
            transaction: {
              errors: [
                new Error(
                  "Please enter a value for either daily or transaction."
                ),
              ],
            },
          });
        } else {
          handleOk(form);
        }
      }
    });
  };

  const onCancel = () => {
    form.setFieldsValue({
      subwallet: "",
      daily: "",
      transaction: "",
    });
    handleCancel();
  };

  const validateDailyAndTransaction = (rule, value, callback) => {
    const dailyValue = form.getFieldValue("daily");
    const transactionValue = form.getFieldValue("transaction");

    if (!dailyValue.trim() && !transactionValue.trim()) {
      callback("Please enter a value for either daily or transaction.");
    } else {
      callback();
    }
  };

  return (
    <Modal
      title="Set new limit"
      visible={open}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      width={480}
      footer={null}
      centered
    >
      <Form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Form.Item label="Select subwallet to apply new limit">
          {form.getFieldDecorator("subwallet", {
            initialValue: ownerId,
            rules: [{ required: true, message: "Please select a subwallet!" }],
          })(
            <Select
              style={{ width: "100%" }}
              placeholder="Select Subwallet"
              size="large"
              optionFilterProp="children"
              onChange={(value) => updateFields({ ownerId: value })}
            >
              {enterprisePeers
                ? enterprisePeers.enterprise_peers.map((peer) => (
                    <Select.Option
                      key={peer.id}
                      value={peer.id}
                    >{`${peer.first_name} ${peer.last_name} (${peer.email})`}</Select.Option>
                  ))
                : ""}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Daily">
          {form.getFieldDecorator("daily", {
            initialValue: daily_limit,
            rules: [{ validator: validateDailyAndTransaction }],
          })(
            <Input
              type="number"
              placeholder="Set new daily limit"
              onChange={(e) => updateFields({ daily_limit: e.target.value })}
              size="large"
            />
          )}
        </Form.Item>

        <Form.Item label="Transaction">
          {form.getFieldDecorator("transaction", {
            initialValue: transfer_limit,
            rules: [{ validator: validateDailyAndTransaction }],
          })(
            <Input
              type="number"
              placeholder="Set new transaction limit"
              onChange={(e) => updateFields({ transfer_limit: e.target.value })}
              size="large"
            />
          )}
        </Form.Item>

        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{
              backgroundColor: "rgb(0, 81, 176)",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default Form.create({ name: "new_limit_form" })(NewLimitModal);
