import React from 'react';
import { IconButton, Step } from '@material-ui/core';
import tutorialActions from '../tutorialActions';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../store/history';
import { Button, Card, Icon, Typography, Switch, Avatar } from 'antd';
import { Dashboard, CardIcon, PaymentReq, Cashout, Paybills, Shipment, Loans, Settings, Dot, Email, LinkStore, Developers, Users, Multiwallet } from '../../../static/icons/sidebar/index';
import { AddMoney, Step0, Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8, Step9, StepIntegration, StepRecon, StepTestmode, ToggleEnabled } from '../../../static/new_segment/tutorials/individual_tutorials';
import * as Icons from '../../../static/icons/sidebar/index';
import { PaymentRequestBG } from '../../../static/new_segment/tutorials/pages_tutorials';

const userType = localStorage.getItem('userType');
const fullName = userType === 'ME' ? localStorage.getItem('firstName') + 's' + localStorage.getItem('lastName') : localStorage.getItem('username');

const indiTutorialSteps = [
  {
    step: 0,
    bg: Step0,
    title: "Profile",
    description: 'This page displays all necessary information about your account and business.',
    margin: '32px 0 0 0',
    icon: Icons.User
  },
  {
    step: 1,
    bg: Step1,
    title: "Dashboard",
    description: 'Your main dashboard provides you with an overview of your transactions.',
    margin: '32px 0px 0px',
    icon: Icons.Dashboard
  },
  {
    step: 2,
    bg: Step2,
    title: "Payment Request",
    description: 'This tab displays all your payment request transactions and allows you to schedule payment requests.',
    margin: '32px 0 0 0',
    icon: Icons.PaymentReq
  },
  {
    step: 3,
    bg: Step3,
    title: "Payout",
    description: 'This tab shows you all your payouts.',
    margin: '32px 0 0 0',
    icon: Icons.Cashout
  },
  {
    step: 4,
    bg: Step4,
    title: "Bux E-Money",
    description: 'This tab will display your EON account.',
    margin: '32px 0 0 0',
    icon: Icons.Card
  },
  {
    step: 5,
    bg: Step5,
    title: "Link Store",
    description: 'This tab allows you integrate BUx to ecommerce stores and other platforms via BUx API or plugins.',
    margin: '32px 0 0 0',
    icon: Icons.LinkStore
  },
  {
    step: 6,
    bg: Step6,
    title: "Voucher",
    description: 'This tab allows you to see available vouchers and share them to your customers',
    margin: '32px 0 0 0',
    icon: Icons.Voucher
  },
  {
    step: 7,
    bg: Step7,
    title: "Settings",
    description: 'This page allows you to perform configurations on fees, notifications, access, and others.',
    margin: '32px 0 0 0',
    icon: Icons.Settings
  },
  {
    step: 8,
    bg: Step8,
    title: "Generate Link",
    description: 'This feature allows you to create and share payment links to your customers.',
    margin: '32px 0 0 0',
    icon: Icons.Dashboard
  },
]

const entTutorialSteps = [
  {
    step: 0,
    bg: Step0,
    title: "Profile",
    description: 'This page displays all necessary information about your account and business.',
    margin: '32px 0 0 0',
    icon: Icons.User
  },
  {
    step: 1,
    bg: Step1,
    title: "Dashboard",
    description: 'Your main dashboard provides you with an overview of your transactions.',
    margin: '32px 0px 0px',
    icon: Icons.Dashboard
  },
  {
    step: 2,
    bg: Step2,
    title: "Payment Request",
    description: 'This tab displays all your payment request transactions and allows you to schedule payment requests.',
    margin: '32px 0 0 0',
    icon: Icons.PaymentReq
  },
  {
    step: 3,
    bg: Step4,
    title: "Multi-Wallet",
    description: 'This tab displays multi-wallet balance, transaction summary, and list of sub-wallets.',
    margin: '32px 0 0 0',
    icon: Icons.Multiwallet
  },
  {
    step: 4,
    bg: Step3,
    title: "Payout",
    description: 'This tab shows you all your payouts and payout schedule.',
    margin: '32px 0 0 0',
    icon: Icons.Cashout
  },
  {
    step: 5,
    bg: StepRecon,
    title: "Reconciliation",
    description: 'This tab shows you all the accounted and unaccounted transactions that are remitted to your settlement bank account.',
    margin: '32px 0 0 0',
    icon: Icons.Card
  },
    {
    step: 6,
    bg: StepIntegration,
    title: "Integration",
    description: 'This tab allows you integrate BUx to ecommerce stores and other platforms via BUx API or plugins.',
    margin: '32px 0 0 0',
    icon: Icons.Developers
  },
  {
    step: 7,
    bg: Step7,
    title: "Settings",
    description: 'This page allows you to perform configurations on fees, notifications, access, and others.',
    margin: '32px 0 0 0',
    icon: Icons.Settings
  },
  {
    step: 8,
    bg: StepTestmode,
    title: "Test Mode",
    description: 'A feature that allows you to simulate test transaction on your test environment.',
    margin: '32px 0 0 0',
    icon: ToggleEnabled
  },
  {
    step: 9,
    bg: Step8,
    title: "Generate Link",
    description: 'This feature allows you to create and share payment links to your customers.',
    margin: '32px 0 0 0',
    icon: Step9
  },
  {
    step: 10,
    bg: Step8,
    title: "Add Money",
    description: 'This feature allows you to add money to your BUx wallet account via OTC, Online Banking, E-Wallets and Manual bank transfer.',
    margin: '32px 0 0 0',
    icon: AddMoney
  },
]

const ProfileElement = () => {
  return (
    <div style={{ ...styles.flexCenter, ...styles.borderSelected, backgroundColor: '#FFF', padding: '8px', width: '214px', top: '100px', lineHeight: '20px', height: '62px' }}>
      <Avatar icon="user" />
      <div>
        <Typography style={{ color: '#2B2D32', fontSize: '20px', fontWeight: '700', wordBreak: (fullName && fullName.search(' ')) ? 'normal' : 'break-all', ...styles.fullName }}>{fullName}</Typography>
        <Typography style={{ color: '#0DAED0', fontSize: '12px', fontWeight: '400' }}>View Profile</Typography>
      </div>
    </div>
  )
}

const indi = [
  {
    step: 0,
    name: 'Profile',
    icon: null,
    description: 'This is your main dashboard, provides you with an overview of your transaction details.',
    top: 100,
    href: '/dashboard',
    element: <ProfileElement />
  },
  {
    step: 1,
    name: 'Dashboard',
    icon: Dashboard,
    description: 'This is your main dashboard, provides you with an overview of your transaction details.',
    top: 196,
    href: '/dashboard',
  },
  {
    step: 2,
    name: 'Payment Request',
    icon: PaymentReq,
    description: 'This tab allows you to monitor payment request status.',
    top: 246,
    href: '/orders',
  },
  {
    step: 3,
    name: 'Payout',
    icon: Cashout,
    description: 'This tab will show you all your payouts and payout schedule.',
    top: 296,
    href: '/funds',
  },
  // { 
  //   step: 4,
  //   name: 'Loans',
  //   icon: Loans,
  //   description: 'This tab will show you on how to apply personal loan for you and your customer.',
  //   top: 394,
  //   href: '/refer_lender',
  // },
  {
    step: 4,
    name: 'BUx E-Money',
    icon: CardIcon,
    description: 'This tab will display your EON account.',
    top: 340,
    href: '/card',
  },
  {
    step: 5,
    name: 'Link Store',
    icon: LinkStore,
    description: 'This tab allows you to link your existing e-commerce stores.',
    top: 342,
    href: '/link_stores',
  },
  {
    step: 6,
    name: 'Voucher',
    icon: Icons.Voucher,
    description: 'This tab allows you to link your existing e-commerce stores.',
    top: 390,
    href: '/vouchers',
  },
  {
    step: 7,
    name: 'Settings',
    icon: Icons.Settings,
    description: 'This tab allows you to link your existing e-commerce stores.',
    top: 436,
    href: '/account_settings#my_checkout',
  },
  {
    step: 8,
    name: 'Generate Payment Link',
    icon: Developers,
    description: 'This button allows you to create and share payment links for your customer.',
    top: 16,
  },
]

const corp = [
  {
    step: 0,
    name: 'Profile',
    icon: null,
    description: 'This is your main dashboard, provides you with an overview of your transaction details.',
    top: 100,
    href: '/dashboard',
    element: <ProfileElement />
  },
  {
    step: 1,
    name: 'Dashboard',
    icon: Dashboard,
    description: 'This is your main dashboard, provides you with an overview of your transaction details.',
    top: 200,
    href: '/dashboard'
  },
  {
    step: 2,
    name: 'Payment Request',
    icon: PaymentReq,
    description: 'This tab allows you to monitor payment request status.',
    top: 250,
    href: '/orders',
  },
  {
    step: 3,
    name: 'Multi-wallet',
    icon: Multiwallet,
    description: 'This tab allows you to monitor payment request status.',
    top: 298,
    href: '/multiwallet',
  },
  {
    step: 4,
    name: 'Payout',
    icon: Cashout,
    description: 'This tab will show you all your payouts and payout schedule.',
    top: 344,
    href: '/funds_enterprise',
  },
  {
    step: 5,
    name: 'Reconciliation',
    icon: CardIcon,
    description: 'This tab will show you all the accounted and unaccounted transactions that are remitted to your settlement bank account.',
    top: 394,
    href: '/recon',
  },
  {
    step: 6,
    name: 'Integration',
    icon: Developers,
    description: 'This tab will show you how to integrate BUx via API or Plugins.',
    top: 442,
    href: '/integration'
  },
  {
    step: 7,
    name: 'Settings',
    icon: Icons.Settings,
    description: 'This tab allows you to link your existing e-commerce stores.',
    top: 490,
    href: '/account_settings#my_checkout',
  },
  {
    step: 8,
    name: 'Test Mode',
    icon: Dashboard,
    description: ' For developers: A feature that will allow you to simulate test transaction on your test environment.',
    top: 576,
  },
  {
    step: 9,
    name: 'Generate link',
    icon: Dashboard,
    description: 'This button allows you to create and share payment links for your customer.',
    top: 12,
  },
  {
    step: 10,
    name: 'Add Money',
    icon: Dashboard,
    description: 'This button allows you to create and share payment links for your customer.',
    top: 12,
  },
]

const Guide = ({ custId }) => {
  const [backBtnStyle, setbackBtnStyle] = React.useState({ color: '#F5922F', backgroundColor: '#FFF' });
  const [toggleValue, setToggleValue] = React.useState(false);
  // const rightPixel = fullName.length <= 8? 94 : 106 + fullName.length;
  // const step = useSelector(state => state.tutorials.demoStep);
  const startDemo = useSelector(state => state.tutorials.startDemo);
  const [data, setData] = React.useState(null);
  const [start, setStart] = React.useState(false);
  const level = localStorage.getItem('Level');
  const current_step = parseInt(localStorage.getItem("Step"));
  const kybStep = localStorage.getItem("KYBStep");
  const kybUpgradeShow = (kybStep > 7 && kybStep < 10);
  const kycUpgradeShow = ((current_step > 0) && current_step <= 7 && userType == "ME")  || (localStorage.getItem("Level") == 0  && userType == "ME");
  const eonRejected = localStorage.getItem("Level") == 1 && current_step == 10;
  const noEon = (userType === 'ME' && !custId);
  const upgradeRejected = current_step == 14 || current_step == 19;
  const upgradeKycLinkHidden = document.getElementById('kyc-level-upgrade') && document.getElementById('kyc-level-upgrade').hidden;
  const upgradeKybStatusHidden = document.getElementById('upgrade-status-button') && document.getElementById('upgrade-status-button').hidden;
  const completeProfIndi = current_step > 0 && current_step <= 7 && userType == "ME";
  const completeProfCorp = kybStep > 0 && kybStep < 7 && userType == "CO";
  const tradeName = localStorage.getItem("tradeName");
  const dispatch = useDispatch();
  const [step, setStep] = React.useState(0);
  const segments = localStorage.getItem('segments');
  const isMultiwallet = localStorage.getItem('is_multiwallet') === "true";

  const backBtnHover = () => {
    setbackBtnStyle({
      color: '#FFF',
      backgroundColor: '#F5922F',
    })
  }

  const backBtnLeave = () => {
    setbackBtnStyle({
      color: '#F5922F',
      backgroundColor: '#FFF',
    })
  }

  const Toggle = () => (
    <div style={styles.toggle}>
      {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 256, height: '100%' }}>
        <div style={{ color: '#FFF', fontSize: 16, fontWeight: '600' }}>Test Mode</div>
        <IconButton color="inherit" aria-label="info" variant="contained" style={{ padding: 4 }} >
          <Icon type="question-circle" theme="filled" style={{ color: 'rgb(255 255 255 .5)', backgroundColor: '#FFF', borderRadius: 20, fontSize: 16 }} />
        </IconButton>
        <Switch checked={toggleValue}
          style={{ margin: '0 12px', backgroundColor: `${toggleValue ? '#F5922F' : '#D1D5DD'}` }} />
      </div> */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 256, height: '100%' }}>
        <div style={{ color: '#2B2D32', fontSize: 16, fontWeight: '600' }}>Test Mode</div>
        <IconButton color="inherit" aria-label="info" variant="contained" style={{ padding: 4 }} >
          <Icon type="question-circle" style={{ color: '#E3632A', backgroundColor: `bg-${segments}`, borderRadius: 20, fontSize: 16 }} />
        </IconButton>
        <Switch checked={toggleValue}
          style={{ margin: '0 12px', backgroundColor: `${toggleValue ? '#F5922F' : '#D1D5DD'}` }}
          size="small" />
      </div>
    </div>
  )

  const SideButtons = ({ icon, name, element }) => {
    return (
      <div style={{ padding: '2px 16px 8px 14px', position: 'relative' }}>
        {step === 0 ?
          element :
          <>
            {/* <div style={{ padding: '1.5px', backgroundColor: '#F5922F', borderRadius: '0 4px 4px 0', height: 64, position: 'absolute', left: 0 }}></div> */}
            <a style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              // backgroundColor: '#082547',
              borderRadius: 10,
              padding: '8px 4px',
              height: 48,
              width: 208,
              border: '4px solid #F5922F',
              filter: 'drop-shadow(0px 0px 14px #F5922B)'
            }}
              className={`bg-${segments}`}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={icon} alt='No Image'  style={{margin: '0 10px 0 12px'}} />
                <div style={{ color: '#FFF', fontSize: 14, fontWeight: '600' }}>{name}</div>
              </div>
            </a>
          </>}
      </div>
    )
  }

  const GenerateButton = () => {
    return (
      <div style={{ borderRadius: 4, width: 152, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', ...styles.borderSelected, }} className={`bg-${segments}`}>
        <Typography style={{ color: '#FFF', fontSize: 14, fontWeight: '600' }}>Generate link</Typography>
      </div>
    )
  }

  const AddMoneyButton = () => {
    return (
      <div  className={`outline-btn--${segments}`} style={{ borderRadius: 4, width: 152, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', ...styles.borderSelected, backgroundColor: '#FFF' }}>
        <Typography className={`text-${segments}`} style={{ fontSize: 14, fontWeight: '600' }}>Add Money</Typography>
      </div>
    )
  }

  const setCurrentData = (key, step) => {
    switch (key) {
      case 'ME':
        setData(indi[step]);
        return indi[step];
      default:
        setData(corp[step]);
        return corp[step];
    }
  }

  const prevStep = () => {
    if (step === 5 && userType === 'ME' && !custId) {
      dispatch(tutorialActions.setDemoStep(step - 2));
      history.push(setCurrentData(userType, step - 2).href)
    } else if (step === 4 && userType === 'CO' && !isMultiwallet) {
      dispatch(tutorialActions.setDemoStep(step - 2));
      history.push(setCurrentData(userType, step - 2).href)
    }  else {
      dispatch(tutorialActions.setDemoStep(step - 1));
      history.push(setCurrentData(userType, step - 1).href)
    }
  }

  const nextStep = () => {
    if (step === 3 && userType === 'ME' && !custId) {
      setCurrentData(userType, step + 2);
      dispatch(tutorialActions.setDemoStep(step + 2));
      history.push(setCurrentData(userType, step + 2).href)
    } else if (step === 2 && userType === 'CO' && !isMultiwallet) {
      setCurrentData(userType, step + 2);
      dispatch(tutorialActions.setDemoStep(step + 2));
      history.push(setCurrentData(userType, step + 2).href)
    } else {
      setCurrentData(userType, step - 1);
      dispatch(tutorialActions.setDemoStep(step + 1));
      history.push(setCurrentData(userType, step + 1).href)
    }
    // setCurrentData(userType, step - 1);
    // dispatch(tutorialActions.setDemoStep(step + 1));
    // history.push(setCurrentData(userType, step + 1).href)
  }

  const addStep = () => {
    if (step === 3 && userType === "ME" && !custId)  {
      setStep(step + 2)
    } else if (step === 2 && userType === "CO" && !isMultiwallet)  {
      setStep(step + 2)
    } else if (step < rendeStepLength() - 1) {
      setStep(step + 1)
    }
    else {
      setStep(step)
    }
    nextStep()
  }

  const subtractStep = () => {
    if (step === 5 && userType === "ME" && !custId) {
      setStep(step - 2)
    } else if (step === 4 && userType === "CO" && !isMultiwallet)  {
      setStep(step - 2)
    } else if (step >= 1) {
      setStep(step - 1)
    }
    else {
      setStep(step)
    }
    prevStep()
  }

  const rendeStepLength = () => {
    if (userType === "ME") {
      return indiTutorialSteps.length;
    } else if (userType === "CO" && !isMultiwallet) {
      return entTutorialSteps.length - 1;
    }
    return entTutorialSteps.length;
  } 

  const renderSteps = (step) => {
    if (userType === "ME") {
      return indiTutorialSteps[step];
    }
    return entTutorialSteps[step];
  }

  const done = () => {
    dispatch(tutorialActions.setStartDemo(false));
    localStorage.setItem('lastLogin', '1');
    dispatch(tutorialActions.setDemoStep(null));
    history.push('/dashboard');
  }

  React.useEffect(() => {
    const timerId = setInterval(() => {
      setToggleValue(x => !x)
    }, 1500)

    return () => clearInterval(timerId)
  })

  if (!start && step === 0) {
    if (userType === 'CO') {
      setData(corp[0]);
    } else {
      setData(indi[0]);
    }
    setStart(true);
  }
  return (
    data &&
    <div>
      <div
        style={{
          width: 402,
          position: 'fixed',
          // top: data.step === 7 ? 16
          //   : kybUpgradeShow && userType === 'CO' ? data.top + 92
          //     : (!kycUpgradeShow && !noEon && upgradeKycLinkHidden) || (eonRejected && !noEon && upgradeKycLinkHidden) ? data.top - 64
          //       : (upgradeRejected && tradeName && data.step !== 6) ? data.top + 76
          //         : (upgradeRejected && data.step !== 6) ? data.top + 18
          //           : (completeProfIndi) ? data.top + 36
          //             : (completeProfCorp) ? data.top + 80
          //               : (!upgradeKycLinkHidden && userType === 'ME' && !kycUpgradeShow) || (!upgradeKycLinkHidden && eonRejected) ? data.top + 22
          //                 : userType === 'ME' && custId && step === 5 ? 434
          //                   : userType === 'ME' && custId ? data.top - 70
          //                     : !upgradeKybStatusHidden && userType == "ME" ? data.top + 36
          //                       : tradeName && userType === 'CO' ? data.top + 32
          //                         : data.top + (userType === 'CO' ? 8 : 48),
          top:  ((tradeName === "null") && !completeProfIndi && userType === "ME" && data.step > 0 && data.step < 8) ? (data.top - 14 )
                : ((tradeName === "null") && !completeProfCorp && userType === "CO" && data.step > 0 && data.step < 4) ? (data.top - 8 )
                : ((tradeName === "null") && !completeProfCorp && !isMultiwallet && userType === "CO" && data.step > 3 && data.step < 8) ? (data.top - 56 )
                : ((completeProfIndi || kycUpgradeShow) && data.step > 0 && data.step < 8) ? (data.top + 22) 
                : (!noEon && data.step > 4 && data.step < 8 && userType === "ME")? (data.top + 48)
                : (kybUpgradeShow && userType === 'CO' && step > 0 && step < 4 ) ? (data.top + 8)
                : (kybUpgradeShow && !isMultiwallet && userType === "CO" && data.step > 3 && data.step < 9)? (data.top -38)
                : (!isMultiwallet && userType === "CO" && data.step > 3 && data.step < 9)? (data.top -46) : data.top,
          display: data.step === 6 || userType === 'CO' && data.step === 5 ? 'flex' : 'block',
          left: data.step === 8 && userType === 'CO' && 12,
          right: ((data.step === 8 && userType === "ME") || data.step === 9) ? -36 : (data.step === 10 && userType === 'CO') && 92,
          // right:data.step===7&&0,
          ...styles.root,
          zIndex: '100',
        }}
      >
        {((data.step === 8 && userType === "ME") || data.step === 9) ? <GenerateButton /> : (data.step === 10) ? <AddMoneyButton /> : userType === 'CO' && data.step === 8 ? <Toggle /> : <SideButtons icon={data.icon} name={data.name} element={data && data.element} />}
        {/* <div style={{marginTop: userType==='CO'&&data.step===6? '-136px':32, marginLeft: 32}}>
        <Card bodyStyle={{padding: 20, textAlign: 'center', width: 370}}>
          <div hidden={ (data.step === 6) || (userType === 'CO' && data.step === 5) } style={styles.arrowUp}></div>
          <div hidden={ (data.step !== 6) || (userType !== 'CO' && data.step !== 6) || (userType === 'ME' && data.step === 6)} style={{...styles.arrowLeft, top: userType === 'CO' && data.step === 6 ? 148 : 44,}}></div>
          <div hidden={ (data.step!==6 && userType !== 'CO') || (userType === 'CO' && data.step !== 5)} style={{...styles.arrowLeft, top: userType === 'CO' && data.step === 6? 148 : 0,}}></div>
          <Button shape='circle' size='small' style={styles.closeBtn} onClick={done}>X</Button>
          <Typography style={styles.description}>
            {data.description}
          </Typography>
          <div style={styles.footerBtn}>
            <Button hidden={data.step===1} style={{...styles.backBtn, ...backBtnStyle}}
              onClick={prevStep}
              onMouseLeave={backBtnLeave}
              onMouseOver={backBtnHover}>Back</Button>
            <Button style={{...styles.nextBtn, width: data.step===1? '100%':'48%',}} 
              onClick={data.step===7?done:nextStep}
              >{data.step===7?'Done':'Next'}</Button>
          </div>
        </Card>
      </div> */}
      </div>
      <Card
        style={styles.bg}
      >
        <div style={styles.contentWrapper}>
          <img src={renderSteps(step).bg} alt={renderSteps(step).title} style={{ margin: renderSteps(step).margin }} />
          <div style={{ paddingTop: '24px', gap: '12px', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className={!(step > 7 && userType === "CO") && `bg-${segments}`} style={{...styles.icon, marginBottom: (step === 8 && userType === "CO") && '-16px' }}>
              <img src={renderSteps(step).icon} alt="Icon" />
            </div>
            <Typography style={styles.title}>{renderSteps(step).title}</Typography>
          </div>
          <Typography style={styles.msg}>{renderSteps(step).description}</Typography>
        </div>
        <div style={{ textAlign: 'center', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <button
            className={`btn--${segments}`}
            onClick={() => { setStep(1); nextStep() }}
            hidden={step > 0}
            style={{ width: '112px', height: '40px' }}
          >
            Next
          </button>
          <div style={styles.footerBtn} hidden={step === 0 || (step + 1 >= rendeStepLength())}>
            <button className={`outline-btn--${segments}`} style={styles.cancelBtn} onClick={() => subtractStep()} >Previous</button>
            <button className={`btn--${segments}`} style={{ ...styles.saveBtn }}
              onClick={() => addStep()} >
              Next
            </button>
          </div>
          <button
            className={`btn--${segments}`}
            onClick={() => done()}
            hidden={step + 1 < rendeStepLength()}
            style={{ width: '112px', height: '40px' }}
          >
            All good
          </button>
        </div>
      </Card>
    </div>
  )
}

const styles = {
  root: {
    // width: '100%',
    // height: '100%',
    // position: 'absolute',
    // zIndex: 99,
    // backgroundColor: 'rgb(0 0 0 / 80%)',
    // overflowY: 'auto', 
  },
  nextBtn: {
    height: 48,
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
  },
  backBtn: {
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    height: 48,
    border: '1px solid #F5922F',
    width: '48%',
  },
  closeBtn: {
    position: 'absolute',
    fontSize: 12,
    top: '-12px',
    right: '-10px',
  },
  description: {
    color: '#2B2D33',
    paddingTop: 8,
    fontSize: 16
  },
  arrowUp: {
    width: 0,
    height: 0,
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderBottom: '24px solid #FFF',
    position: 'absolute',
    top: '-12px'
  },
  arrowLeft: {
    width: 0,
    height: 0,
    borderTop: '20px solid transparent',
    borderBottom: '20px solid transparent',
    borderRight: '28px solid #FFF',
    position: 'absolute',
    left: '-15px',
  },
  toggle: {
    height: 54,
    width: 214,
    backgroundColor: '#FFF',
    border: "4px solid #F5922F",
    filter: "drop-shadow(0px 0px 14px #F5922B)",
    borderRadius: "8px",
    left: '10px'
  },
  flexCenter: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '12px'
  },
  borderSelected: {
    border: "4px solid #F5922F",
    filter: "drop-shadow(0px 0px 14px #F5922B)",
    borderRadius: "8px",
  },
  contentWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  saveBtn: {
    height: 40,
    fontSize: 14,
    fontWeight: '500',
    borderRadius: 4,
    width: '100%',
    cursor: 'pointer',
    color: '#FFF',
    border: 0
  },
  cancelBtn: {
    cursor: 'pointer',
    borderRadius: 4,
    height: 40,
    fontSize: 16,
    fontWeight: '500',
    padding: '8px 16px',
    width: '100%',
    marginRight: 8
  },
  footerBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '12px 0',
    width: '70%',
    gap: '12px',
  },
  icon: {
    padding: '8px',
    borderRadius: '8px'
  },
  bg: {
    backgroundImage: `url(${PaymentRequestBG})`,
    // backgroundPosition: 'top auto',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '16px',
    position: 'fixed',
    width: '454px',
    height: '492px',
    top: "50%",
    left: "50%",
    marginTop: '-284px', /* Negative half of height. */
    marginLeft: '-222px', /* Negative half of width. */
    borderRadius: '12px'
  },
  title: {
    color: '#2B2D3',
    fontSize: 28,
    fontWeight: '700',
    textAlign: 'center',
  },
  msg: {
    color: '#2B2D3',
    fontSize: 16,
    textAlign: 'center',
    padding: '12px 0',
    fontWeight: '400'
  },
  fullName: {
    maxWidth: '154px', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    whiteSpace: 'nowrap',
  }
}


export default Guide;