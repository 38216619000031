import React from 'react';
import { Card, Typography, List, Button } from 'antd';

const MyCheckoutCard = (props) => {
    const { data } = props;

    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
    const qrLink = (REACT_APP_BASE_URL + '/' + data.trade_name)
    const info = [
        {
            title: 'Link:',
            value: data && qrLink
        },
    ]

    return (
        <Card
            style={styles.root}
            bodyStyle={styles.rootBody}
            hidden={data && !data.trade_name}
        >
            <Typography style={styles.title}>My Checkout Page</Typography>
            <div>
                <List
                    dataSource={info}
                    bordered={false}
                    renderItem={item => (
                        <List.Item style={{borderBottom: 0, padding: '8px 0'}}>
                            <Typography style={styles.item}>{item.title}</Typography>
                            <a onClick={() => window.open(qrLink)} style={styles.link}>{item.value}</a>
                        </List.Item>
                    )}
                />
            </div>
        </Card>
    )
}

const styles = {
    root: {
        margin: '12px 12px 12px 0',
        padding: '24px'
    },
    rootBody: {
        padding: 0,
    },
    title: {
        color: '#2B2D32',
        fontSize: '18px',
        fontWeight: 'bold',
        padding: '12px 0',
        opacity: '0.8'
    },
    normalFont: {
        color: '#2B2D32',
        fontSize: '16px'
    },
    content: {
        padding: '8px 0'
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 0'
    },
    item: {
        color: '#2B2D32',
        fontSize: 16
    },
    link: {
        color: '#0A315E',
        fontSize: 16,
        textDecoration: 'underline',
        fontWeight: 'bold',
        maxWidth: '314px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        overflow: 'hidden',
    }
}

export default MyCheckoutCard;