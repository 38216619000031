export const codeStyle = {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#2B2D33'
}

export const descSyle = {
      maxWidth: '200px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '24px',
      color: '#2B2D33',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',

  }

export const dataStyle = {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000000'
  }
export const orderStyle = {
    
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    color: '#000000',
}

export const textStyle = {
    
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#FFFFFF'
  }

export const cancelOrder = {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '34px',
    color: '#2b2d33',
    marginBottom: '10px'
  }

export const choicesStyle = {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#2b2d33',
    opacity: '0.8',
    marginBottom: '10px'
  }

export const cancelButtonStyle = {
    borderRadius: '4px',
    width: '100%',
    height: '40px',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#FFFFFF',
    marginBottom: '10px',
    
  }

export const dontCancelButton = {
    borderRadius: '4px',
    width: '100%',
    height: '40px',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#2b2d33',
    marginRight: '10%'
  }


export const radioStyle = {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#2b2d33',
    opacity: '0.8',
    marginBottom: '10px'
  };

export const menuStyle = {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#2b2d33',
    padding: '10px',
    margin: 'auto'
  }
  
export const labelStyle = {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#2B2D33'
}

export const summaryDiv = {
  display: 'flex',
  fontSize: '16px',
  width: '100%', 
  height: '60px', 
  backgroundColor: '#fff', 
  marginTop: '20px',
  padding: '18px 20px 0px 50px'
}

export const newSummary = {
  width: '33%', 
  border: '1px solid #D1D5DD',
  display: 'flex', 
  justifyContent: 'center', 
  padding: '5px 0px 5px 0px'
}

export const applyBtn = {
  color: '#fff',
  backgroundColor: '#F5922F',
  height: '40px',
  border: '1px solid #F5922F',
  borderRadius: '4px',
  fontWeight: '600',
  fontSize: '16px'
}

export const clearFilter = {
  padding: '3px 8px 4px 8px',
  backgroundColor: '#E6EAF0',
  borderRadius: '50px',
  height: '29px',
  marginLeft: '10px',
  marginTop: '-5px',
  color: '#000'
}

export const clearBtnDiv = {
  backgroundColor: '#E6EAF0',
  borderRadius: 24,
  padding: '4px 12px',
  margin: '0 12px'
}

export const clearBtn = {
  color: '#000000',
  fontSize: 12,
  paddingLeft: 4,
}

export const clearDiv = {
  display: 'flex',
  alignItems: 'center',
  padding: '0 20px 20px 20px',
  height: 40
}

export const clearIcon = {
  color: '#2B2D33',
  fontSize: 10,
}

export const filterText = {
  color: '#000',
  fontSize: 14, 
  fontWeight: 'bold'
}

export const channelDivStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '120px'
}

export const noChannelYet = {
  color: '#909196', 
  fontSize: '10px', 
  maxWidth: '112px', 
  overflow: 'hidden', 
  textOverflow: 'ellipsis',
  whiteSpace: 'break-spaces'
}

export const cancelBtn = {
  width: '48%',
  fontSize: 14,
  fontWeight: '600',
  borderRadius: 10,
  height: 48,
  border: '1px solid #F5922F',
}

export const footerBtn = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: 28
}

export const saveBtn = {
  height: 48,
  backgroundColor: '#F5922F',
  color: '#FFFFFF',
  fontSize: 14,
  fontWeight: '600',
  borderRadius: 10,
  width: '48%',
}

export const filterDiv = {
  display: 'flex', 
  justifyContent: 'space-between', 
  padding: '20px 0px'
}

export const summary = {
  display: 'flex', 
  justifyContent: 'center', 
  width: '50%'
}

export const scheduleDiv = {
  width: '428px',
  height: '40px',
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: '8px',
  borderLeft: '8px solid #F29A38',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
  backgroundColor: '#fff',
  padding: '9px 15px 0px 15px'
}