import React, {useRef, useEffect} from 'react'
import {Card, Button, Input, Icon, Modal, message} from 'antd'
import * as Style from '../../../complete_profile/complete_profile_style'
import SelfieImg from '../../../../static/img/selfie.svg'
import UploadIDImg from '../../../../static/img/upload_id.svg'
import CameraIcon from '../../../../static/img/cameraImg.svg'
import {browserName, isIOS, isSafari, isMobileSafari, isFirefox } from 'react-device-detect';
import axios from 'axios'
import {goToStep} from '../mobile_main_enterprise'
const max_width = 2000;
const max_height = 2000;

const BusinessDocuments = props => {

    useEffect(()=>{
        window.scrollTo(0, 0)
        getDetails()
    },[])
    

    const canvasRef = useRef(null);
    const playerRef = useRef(null);

    const [businessType, setBusinessType] = React.useState()


    //modals
    const [blockModal, setBlockModal] = React.useState(false)
    const [cameraModal, setCameraModal] = React.useState(false)
    const [messageModal, setMessageModal] = React.useState('')

    //Corporate documents
    const [secRegister, setSecRegister] = React.useState("")
    const [secRegisterFile, setSecRegisterFile] = React.useState("")
    const [secRegisterFileName, setSecRegisterFileName] = React.useState(null)

    const [articlesOfInc, setArticlesOfInc] = React.useState("")
    const [articlesOfIncFile, setArticlesOfIncFile] = React.useState("")
    const [articlesOfIncFileName, setArticlesOfIncFileName] = React.useState(null)

    const [byLaws, setByLaws] = React.useState("")
    const [byLawsFile, setByLawsFile] = React.useState("")
    const [byLawsFileName, setByLawsFileName] = React.useState(null)

    const [latestGSIS, setLatestGSIS] = React.useState("")
    const [latestGSISFile, setLatestGSISFile] = React.useState("")
    const [latestGSISFileName, setLatestGSISFileName] = React.useState(null)

    const [secretaryCert, setSecretaryCert] = React.useState("")
    const [secretaryCertFile, setSecretaryCertFile] = React.useState("")
    const [secretaryCertFileName, setSecretaryCertFileName] = React.useState(null)

    const [bir2303, setBir2303] = React.useState("")
    const [bir2303File, setBir2303File] = React.useState("")
    const [bir2303FileName, setBir2303FileName] = React.useState(null)

    const [businessPermit, setBusinessPermit] = React.useState("")    
    const [businessPermitFile, setBusinessPermitFile] = React.useState("")
    const [businessPermitFileName, setBusinessPermitFileName] = React.useState(null)    

    //Sole documents
    const [dti, setDti] = React.useState("")
    const [dtiFile, setDtiFile] = React.useState("")
    const [dtiFileName, setDtiFileName] = React.useState(null)   

    const [businessPermitNo, setBusinessPermitNo] = React.useState("")    
    const [businessPermitNoFile, setBusinessPermitNoFile] = React.useState("")
    const [businessPermitNoFileName, setBusinessPermitNoFileName] = React.useState(null)    

    const [birTax, setBirTax] = React.useState("")   
    const [birTaxFile, setBirTaxFile] = React.useState("")
    const [birTaxFileName, setBirTaxFileName] = React.useState(null)   

    const [mayorPermit, setMayorPermit] = React.useState("")
    const [mayorPermitFile, setMayorPermitFile] = React.useState("")
    const [mayorPermitFileName, setMayorPermitFileName] = React.useState(null)    
    

    //proof of billings 
    const [electricityFile, setElectricityFile] = React.useState("")
    const [electricityFileName, setElectricityFileName] = React.useState(null)   

    const [internetFile, setInternetFile] = React.useState("")
    const [internetFileName, setInternetFilName] = React.useState(null)   


    //business photo
    const [interiorFile, setInteriorFile] = React.useState("")
    const [interiorFileURL, setInteriorFileURL] = React.useState(null)

    const [exteriorFile, setExteriorFile] = React.useState("")
    const [exteriorFileURL, setExteriorFileURL] = React.useState(null)

    //personal photo
    const [idFile, setIdFile] = React.useState("")
    const [idFileURL, setIdFileURL] = React.useState(null)

    const [selfieFile, setSelfieFile] = React.useState("")
    const [selfieFileURL, setSelfieFileURL] = React.useState(null)

    //loading status
    const [loading, setLoading] = React.useState(false)

    //
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(false)

    let secRegisterUpload = React.useRef()
    let articlesOfIncUpload = React.useRef()
    let byLawsUpload = React.useRef()
    let latestGSISUpload = React.useRef()
    let secretaryCertUpload = React.useRef()
    let bir2303Upload = React.useRef()
    let businessPermitUpload = React.useRef()

    let dtiUpload = React.useRef()
    let businessPermitNoUpload = React.useRef()
    let birTaxUpload = React.useRef()
    let mayorPermitUpload = React.useRef()

    let electricityUpload = React.useRef()
    let internetUpload = React.useRef()

    let interiorUpload = React.useRef()
    let exteriorUpload = React.useRef()

    let idUpload = React.useRef()

    let docus = []

    docus = 

    businessType == 0?
    [
        {
            name: 'DTI Registration Number',
            ref: dtiUpload,
            file_name: dtiFileName,
            value: dti,
        },
        {
            name: 'Business Permit No.',
            ref: businessPermitNoUpload,
            file_name: businessPermitNoFileName,
            value: businessPermitNo,
        },
        {
            name: 'BIR Tax Number',
            ref: birTaxUpload,
            file_name: birTaxFileName,
            value: birTax,

        },
        {
            name: 'Mayor’s Permit Number',
            ref: mayorPermitUpload,
            file_name: mayorPermitFileName,
            value: mayorPermit,

        },
    ]:
    
    [
        {
            name: 'Sec Register',
            ref: secRegisterUpload,
            file_name: secRegisterFileName,
            value: secRegister,
        },
        {
            name: 'Articles Of Incorporation',
            ref: articlesOfIncUpload,
            file_name: articlesOfIncFileName,
            value: articlesOfInc,
        },
        {
            name: 'By-Laws',
            ref: byLawsUpload,
            file_name: byLawsFileName,
            value: byLaws,

        },
        {
            name: 'Latest GSIS',
            ref: latestGSISUpload,
            file_name: latestGSISFileName,
            value: latestGSIS,

        },
        {
            name: 'Secretary Certificate',
            ref: secretaryCertUpload,
            file_name: secretaryCertFileName,
            value: secretaryCert,

        },
        {
            name: 'BIR 2303',
            ref: bir2303Upload,
            file_name: bir2303FileName,
            value: bir2303,

        },
        {
            name: 'Business Permit Number',
            ref: businessPermitUpload,
            file_name: businessPermitFileName,
            value: businessPermit,

        },
    
]

    const proof_of_billings_docs = [
        {
            name: 'Electricity',
            ref: electricityUpload,
            file_name: electricityFileName,
        },
        {
            name: 'Internet Service Provider or Wireless Network (Wi-Fi)',
            ref: internetUpload,
            file_name: internetFileName,
        },
    ]

    const business_photo = [
        {
            name: 'Interior',
            ref: interiorUpload,
            file_url: interiorFileURL,
        },
        {
            name: 'Exterior',
            ref: exteriorUpload,
            file_url: exteriorFileURL,
        },
    ]

    async function getDetails(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let userId = localStorage.getItem('userId')
        try{
            let response = await axios.get(
                process.env.REACT_APP_API_URL + `/api/kyb/${userId}`,yourConfig)
                
            console.log(response.data)
            setBusinessType(response.data.business_type)

            //sole
            setDti(response.data.dti_registration_number)
            setDtiFileName(response.data.dti_registration_attachment)
            setBusinessPermitNo(response.data.business_permit_number)
            setBusinessPermitNoFileName(response.data.business_permit_attachment)
            setBirTax(response.data.bir_tax_number)
            setBirTaxFileName(response.data.bir_tax_number_attachment)
            setMayorPermit(response.data.mayors_permit_number)
            setMayorPermitFileName(response.data.mayors_permit_attachment)

            //corporate
            setSecRegister(response.data.sec_registration_number)
            setSecRegisterFileName(response.data.sec_registration_number_attachment)
            setBusinessPermit(response.data.business_permit_number)
            setBusinessPermitFileName(response.data.business_permit_attachment)
            setArticlesOfInc(response.data.articles_of_incorporation)
            setArticlesOfIncFileName(response.data.articles_of_incorporation_attachment)
            setBir2303(response.data.bir_2303)
            setBir2303FileName(response.data.bir_2303_attachment)
            setLatestGSIS(response.data.gis)
            setLatestGSISFileName(response.data.gis_attachment)
            setByLaws(response.data.bylaws)
            setByLawsFileName(response.data.bylaws_attachment)
            setSecretaryCert(response.data.secretary_certificate)
            setSecretaryCertFileName(response.data.secretary_certificate_attachment)

            //proof of billing
            setElectricityFileName(response.data.electricity_attachment)
            setInternetFilName(response.data.internet_attachment)

            //business photo
            setInteriorFileURL(response.data.interior_attachment)
            setExteriorFileURL(response.data.exterior_attachment)

            //owner id amd selfie
            setIdFileURL(response.data.owner_id_attachment)
            setSelfieFileURL(response.data.owner_selfie_attachment)


            
        }
        catch(error){
            console.log("Unable to proceed. Please try again");
        }

    }

    const handleUploadFile = (info, type) =>{
        console.log(info, type)

        //Corporate documents
        if(type == 'Sec Register'){
            setSecRegisterFile(info)
            setSecRegisterFileName(info.name)
        }
        else if(type == 'Articles Of Incorporation'){
            setArticlesOfIncFile(info)
            setArticlesOfIncFileName(info.name)
        }
        else if(type == 'By-Laws'){
            setByLawsFile(info)
            setByLawsFileName(info.name)
        }
        else if(type == 'Latest GSIS'){
            setLatestGSISFile(info)
            setLatestGSISFileName(info.name)
        }
        else if(type == 'Secretary Certificate'){
            setSecretaryCertFile(info)
            setSecretaryCertFileName(info.name)
        }
        else if(type == 'BIR 2303'){
            setBir2303File(info)
            setBir2303FileName(info.name)
        }
        else if(type == 'Business Permit Number'){
            setBusinessPermitFile(info)
            setBusinessPermitFileName(info.name)
        }

        //sole documents

        else if(type == 'DTI Registration Number'){
            setDtiFile(info)
            setDtiFileName(info.name)
        }
        else if(type == 'Business Permit No.'){
            setBusinessPermitNoFile(info)
            setBusinessPermitNoFileName(info.name)
        }
        else if(type == 'BIR Tax Number'){
            setBirTaxFile(info)
            setBirTaxFileName(info.name)
        }
        else if(type == 'Mayor’s Permit Number'){
            setMayorPermitFile(info)
            setMayorPermitFileName(info.name)
        }

        //proof of billings docu

        else if(type == 'Electricity'){
            setElectricityFile(info)
            setElectricityFileName(info.name)
        }
        else if(type == 'Internet Service Provider or Wireless Network (Wi-Fi)'){
            setInternetFile(info)
            setInternetFilName(info.name)
        }
    
    }
    
    const handleUploadImage = (info,type) =>{
        var reader = new FileReader();
        reader.readAsArrayBuffer(info);
        
        reader.onload = function (event) {
        // blob stuff
        var blob = new Blob([event.target.result]); // create blob...
        window.URL = window.URL || window.webkitURL;

            if(type == 'Interior'){
                setInteriorFile(info)
                setInteriorFileURL(window.URL.createObjectURL(blob)) // and get it's URL
            }
            else if(type == "Exterior"){
                setExteriorFile(info)
                setExteriorFileURL(window.URL.createObjectURL(blob)) // and get it's URL
            }
            else if(type == 'ID'){
                setIdFile(info)
                setIdFileURL(window.URL.createObjectURL(blob))
            }
    
        }
    }

    function resizeMe(img) {
      
        var canvas = document.createElement('canvas');
  
        var width = img.width;
        var height = img.height;
  
        // calculate the width and height, constraining the proportions
        if (width > height) {
          if (width > max_width) {
            //height *= max_width / width;
            height = Math.round(height *= max_width / width);
            width = max_width;
          }
        } else {
          if (height > max_height) {
            //width *= max_height / height;
            width = Math.round(width *= max_height / height);
            height = max_height;
          }
        }
        
        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        
        return canvas.toDataURL("image/jpeg",0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)
        
        // you can get BLOB too by using canvas.toBlob(blob => {});
  
      }
  
      function dataURItoBlob(dataUri) {
          var byteString = atob(dataUri.split(',')[1]);
          var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
          }
          var blob = new Blob([ab], {type: mimeString});
          return blob
      }

    function handleCameraPermission(){

        playerRef.current.setAttribute('playsinline', '');
        playerRef.current.setAttribute('autoplay', '');
        playerRef.current.setAttribute('muted', '');
        playerRef.current.style.width = '100%';
        playerRef.current.style.height = '400px';

        /* Setting up the constraint */
        var facingMode = "user"; // Can be 'user' or 'environment' to access back or front camera (NEAT!)
        var constraints = {
          audio: false,
          video: {
           facingMode: facingMode
          }
        };


        if(!isIOS){
            setMessageModal('Please allow your camera access to take your selfie by enabling on your browser security settings. Remove bux.ph from list of blocked sites')
            
            if(isFirefox){
                navigator.mediaDevices.getUserMedia(constraints)
                .then(function(stream) {
                    setCameraModal(true)
                    playerRef.current.srcObject = stream;
                })
                .catch(function(err) {
                    setBlockModal(true)
                });
            }
            else{
                navigator.permissions.query({name: 'camera'})
                .then((permissionObj) => {
                    if(permissionObj.state == "denied"){
                        setBlockModal(true)
                    }
                    else{
                        navigator.getMedia = ( navigator.getUserMedia ||
                            navigator.webkitGetUserMedia ||
                            navigator.mozGetUserMedia ||
                            navigator.msGetUserMedia);

                        if (navigator.getUserMedia) {
                            navigator.getUserMedia(constraints, function success(stream) {
                            setCameraModal(true)
                            playerRef.current.srcObject = stream;
                            }, function(error){
                            
                            setBlockModal(true)
                            });
                        }
                    }
                })
                .catch((error) => {
                    setBlockModal(true)
                })
            }
        }else{
            
            if(isMobileSafari || isSafari){
                setMessageModal(`iPhone Safari Blocked Access:
                Please allow camera access to bux.ph. If you have permanently blocked camera access, please follow these steps:
                Go to Setttings >  Go to Safari > Scroll to Privacy & Security > Enable Camera & Microphone Access`)
                navigator.mediaDevices.getUserMedia(constraints).then(function success(stream) {
                    setCameraModal(true)
                   playerRef.current.srcObject = stream;
                }).catch((error) => {
                    setBlockModal(true)
                });
    
            }

            else {
                setMessageModal(`Camera access is only enabled for Safari on iOS devices. Please use Safari to use the selfie feature`)
                setBlockModal(true)

            }
            /* Stream it to video element */
  

        }
    }


    function closeCamera(){
        try{
            let stream = playerRef.current.srcObject;
            let tracks = stream.getTracks();
            tracks[0].stop()
        }catch(error){}

        setCameraModal(false)
    }

    function captureImage(dataURI){

        const context = canvasRef.current.getContext('2d');
        context.drawImage(playerRef.current , 0, 0, canvasRef.current.width, canvasRef.current.height);

        var dataURL =  canvasRef.current.toDataURL();
		// set the source of the img tag
        // setSelfieBlob(dataURL)
        handleTakePhoto(dataURL)
        // const context = this.canvasElement.getContext('2d');
        // context.drawImage(this.props.video, 0, 0, 640, 480);
    }

    function handleTakePhoto (dataUri) {    
        processfile(dataURItoBlob(dataUri), 4);
        try{
            let stream = playerRef.current.srcObject;
            let tracks = stream.getTracks();
            tracks[0].stop()
        }catch(error){}

        setCameraModal(false)
    }

    function processfile(blob, step) {
        // read the files
        var reader = new FileReader();
        reader.readAsArrayBuffer(blob);
        
        reader.onload = function (event) {
          // blob stuff
          var blob = new Blob([event.target.result]); // create blob...
          window.URL = window.URL || window.webkitURL;
          var blobURL = window.URL.createObjectURL(blob); // and get it's URL
          setSelfieFileURL(blobURL)
          // helper Image object
          var image = new Image();
          image.src = blobURL;
          image.onload = function() {
            // have to wait till it's loaded
            var resized = resizeMe(image); // resized image url
            setSelfieFile(resized)
          }
        };
    }

    const headerStyle = {
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2F3542'
    }

    const fileName = {
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#54575F',
        width: '75%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginRight: '30px'

    }

    const textStyle = {
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '17px',
        color: '#54575F'
    }

    const uploadBtnStyle = {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1D82B8',
        border: '1px solid #1D82B8',
        width: '100%',
        height: '48px',
    }

    let businessTypeString = businessType

    function handleChangeInput(value, type){
        //Corporate documents
        if(type == 'Sec Register'){
            setSecRegister(value)
        }
        else if(type == 'Articles Of Incorporation'){
            setArticlesOfInc(value)
        }
        else if(type == 'By-Laws'){
            setByLaws(value)
        }
        else if(type == 'Latest GSIS'){
            setLatestGSIS(value)
        }
        else if(type == 'Secretary Certificate'){
            setSecretaryCert(value)
        }
        else if(type == 'BIR 2303'){
            setBir2303(value)
        }
        else if(type == 'Business Permit Number'){
            setBusinessPermit(value)
        }

        //sole documents

        else if(type == 'DTI Registration Number'){
            setDti(value)    
        }
        else if(type == 'Business Permit No.'){
            setBusinessPermitNo(value)
        }
        else if(type == 'BIR Tax Number'){
            setBirTax(value)
        }
        else if(type == 'Mayor’s Permit Number'){
            setMayorPermit(value)
        }
        

    }

    function handleRemoveFile(type){
        //Corporate documents
        if(type == 'Sec Register'){
            setSecRegisterFileName(null)
            setSecRegisterFile("")
        }
        else if(type == 'Articles Of Incorporation'){
            setArticlesOfIncFileName(null)
            setArticlesOfIncFile("")
        }
        else if(type == 'By-Laws'){
            setByLawsFileName(null)
            setByLawsFile("")
        }
        else if(type == 'Latest GSIS'){
            setLatestGSISFileName(null)
            setLatestGSISFile("")
        }
        else if(type == 'Secretary Certificate'){
            setSecretaryCertFileName(null)
            setSecretaryCertFile("")
        }
        else if(type == 'BIR 2303'){
            setBir2303FileName(null)
            setBir2303File("")
        }
        else if(type == 'Business Permit Number'){
            setBusinessPermitFileName(null)
            setBusinessPermitFile("")
        }

        //sole documents

        else if(type == 'DTI Registration Number'){
            setDtiFileName(null)    
            setDtiFile("")    
        }
        else if(type == 'Business Permit No.'){
            setBusinessPermitNoFileName(null)
            setBusinessPermitNoFile("")
        }
        else if(type == 'BIR Tax Number'){
            setBirTaxFileName(null)
            setBirTaxFile("")
        }
        else if(type == 'Mayor’s Permit Number'){
            setMayorPermitFileName(null)
            setMayorPermitFile("")
        }

        //proof of billing
        else if(type == 'Electricity'){
            setElectricityFile("")
            setElectricityFileName(null)
        }
        else if(type == 'Internet Service Provider or Wireless Network (Wi-Fi)'){
            setInternetFile("")
            setInternetFilName(null)
        }

    }

    function validateFields(){
        let i;


        for(i=0; i < docus.length; i++){
            
            if(docus[i].value == ""){
                setError(true)
                setErrorMessage(`Please fill up ${docus[i].name}`)
                window.scrollTo(0, 0)
                return
            }
            if(docus[i].file_name == null){
                setError(true)
                setErrorMessage(`Please upload ${docus[i].name} file`)
                window.scrollTo(0, 0)
                return
            }
        }

        if(businessType == 0){
            for(i=0; i < proof_of_billings_docs.length; i++){
                if(proof_of_billings_docs[i].file_name == null){
                    setError(true)
                    setErrorMessage(`Please upload ${proof_of_billings_docs[i].name} proof`)
                    window.scrollTo(0, 0)
                    return
                }
            }
        }

        for(i=0; i < business_photo.length; i++){
            if(business_photo[i].file_url == null){
                setError(true)
                setErrorMessage(`Please upload ${business_photo[i].name} photo`)
                window.scrollTo(0, 0)
                return
            }
        }

        if(idFileURL == null){
            setError(true)
            setErrorMessage(`Please upload your valid id`)
            window.scrollTo(0, 0)
            return
        }

        if(selfieFileURL == null){
            setError(true)
            setErrorMessage(`Please take a selfie with your id`)
            window.scrollTo(0, 0)
            return
        }

        submitStep2()
    }

    async function submitStep2(){
        setLoading(true)
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

    
        const formData = new FormData();

        if(businessType == 0)
        {
            //numbers
            formData.append('dti_registration_number', dti)
            formData.append('business_permit_number', businessPermitNo)
            formData.append('bir_tax_number', birTax)
            formData.append('mayors_permit_number', mayorPermit)
            //files
            formData.append('dti_registration_attachment', dtiFile)
            formData.append('business_permit_attachment', businessPermitNoFile)
            formData.append('bir_tax_number_attachment', birTaxFile)
            formData.append('mayors_permit_attachment', mayorPermitFile)
            //proof of billing
            formData.append('electricity_attachment', electricityFile)
            formData.append('internet_attachment', internetFile)
            //business photo
            formData.append('interior_attachment', internetFile)
            formData.append('exterior_attachment', exteriorFile)
            //owner id and selfie
            formData.append('owner_id_attachment', idFile)
            if(selfieFile != ""){
                formData.append('owner_selfie_attachment', selfieFile)

            }
        }
        else{
            //numbers
            formData.append('sec_registration_number', secRegister)
            formData.append('articles_of_incorporation', articlesOfInc)
            formData.append('bylaws', byLaws)
            formData.append('gis', latestGSIS)
            formData.append('secretary_certificate', secretaryCert)
            formData.append('bir_2303', bir2303)
            formData.append('business_permit_number', businessPermit)
            //files
            formData.append('sec_registration_number_attachment', secRegisterFile)
            formData.append('articles_of_incorporation_attachment', articlesOfIncFile)
            formData.append('bylaws_attachment', byLawsFile)
            formData.append('gis_attachment', latestGSISFile)
            formData.append('secretary_certificate_attachment', secretaryCertFile)
            formData.append('bir_2303_attachment', bir2303File)
            formData.append('business_permit_attachment', businessPermitFile)
            //business photo
            formData.append('interior_attachment', interiorFile)
            formData.append('exterior_attachment', exteriorFile)
            //owner id and selfie
            formData.append('owner_id_attachment', idFile)

            if(selfieFile != ""){
                formData.append('owner_selfie_attachment', selfieFile)

            }
        }
        
        try{
            let response = await axios.put(process.env.REACT_APP_API_URL + "/api/kyb/", formData,yourConfig)
            
            if(response){
                setLoading(false)
                goToStep(3)
            }
        }
        catch(error){
            message.error("Unable to proceed. Please try again");
        }
    }
    
    return(
        <div>
            
            <Card style={{marginBottom: '20px'}}>
                <div style={{backgroundColor: '#e6eaf0', padding: '16px', marginBottom: '20px'}}>
                    <div style={headerStyle}>Document file requirements</div>
                    <div style={textStyle}>Photo or PDF with less than 10 MB size are accepted.</div>
                </div>
                <div style={Style.headerStyle}>
                    Business Document
                </div>
                <div
                    style={{
                        backgroundColor: 'rgba(226, 76, 76, 0.1)',
                        borderLeft: '4px solid #E24C4C',
                        width: '100%',
                        borderRadius: '4px',
                        marginBottom: '20px',
                        marginTop: '20px',
                        textAlign: 'center',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        display: error ? "block" : "none"
                    }}>
                    <span style={Style.textStyle}>{errorMessage}</span>
                </div>
                {
                    
                    docus.map((item,i)=>{
                        return(
                            <div style={{width: '100%', marginBottom: '25px', marginTop: '20px'}}>
                                <div style={Style.textStyle}>{item.name}</div>
                                <Input style={{height: '40px', marginBottom: '10px'}} value={item.value} onChange={(e)=>handleChangeInput(e.target.value,item.name)}/>
                                <input type="file"
                                    ref={item.ref}
                                    style={{display: 'none'}}
                                    onChange={(e)=>handleUploadFile(e.target.files[0], item.name)}
                                    accept=".jpg,.png,.jpeg,.pdf"
                                    >
                                </input>
                                <div style={{display: item.file_name == null ? 'block' : 'none'}}>
                                    <Button style={uploadBtnStyle} onClick={()=>item.ref.current.click()}><Icon type="upload"/>Click to Upload</Button>   
                                </div>
                                <div style={{display: item.file_name != null ? 'block' : 'none'}}>
                                    <div style={{height: '48px', border: '1px solid #E6EAF0', borderRadius:'2px', padding: '12px', display: 'flex'}}>
                                        <Icon type="file" style={{marginTop: '3px', color: '#F5922F', marginRight: '10px'}}/>
                                        <div style={fileName}>{item.file_name}</div>
                                        <Icon type="close" onClick={()=>handleRemoveFile(item.name)} style={{marginTop: '5px', color: 'black', float: 'right'}}/>
                                    </div>
                                </div>
                            </div>      
                        )
                    })
                }

                <div style={{display: businessType == 0 ? 'block' : 'none'}}>
                    <div style={{width: '100%', marginBottom: '16px'}}>
                        <div style={Style.StepStyle}>Proof of Billings</div>
                    </div>
                    {
                        proof_of_billings_docs.map((item,i)=>{
                            return(
                                <div style={{width: '100%', marginBottom: '16px', marginTop: '20px'}}>
                                    <div style={Style.textStyle}>{item.name}</div>
                                    <input type="file"
                                        ref={item.ref}
                                        style={{display: 'none'}}
                                        onChange={(e)=>handleUploadFile(e.target.files[0], item.name)}
                                        accept=".jpg,.png,.jpeg,.pdf"
                                        >
                                    </input>
                                    <div style={{display: item.file_name == null  ? 'block' : 'none'}}>
                                        <Button style={uploadBtnStyle} onClick={()=>item.ref.current.click()}><Icon type="upload"/>Upload Photo or PDF</Button>   
                                    </div>
                                    <div style={{display: item.file_name != null  ? 'block' : 'none'}}>
                                        <div style={{height: '48px', border: '1px solid #E6EAF0', borderRadius:'2px', padding: '12px', display: 'flex'}}>
                                            <Icon type="file" style={{marginTop: '3px', color: '#F5922F', marginRight: '10px'}}/>
                                            <div style={fileName}>{item.file_name}</div>
                                            <Icon type="close" onClick={()=>handleRemoveFile(item.name)} style={{marginTop: '5px', color: 'black', float: 'right'}}/>
                                        </div>
                                    </div>
                                </div>      
                            )
                        })
                    }
                </div>

                <div style={{width: '100%', marginBottom: '16px'}}>
                    <div style={Style.StepStyle}>Business Photo</div>
                </div>
                {
                    business_photo.map((item,i)=>{
                        return(
                            <div style={{width: '100%', marginBottom: '16px', marginTop: '20px'}}>
                                <div style={Style.textStyle}>{item.name}</div>
                                <input type="file"
                                    ref={item.ref}
                                    style={{display: 'none'}}
                                    onChange={(e)=>handleUploadImage(e.target.files[0], item.name)}
                                    accept=".jpg,.png,.jpeg,.pdf"
                                    >
                                </input>
                                <div style={{display: item.file_url == null ? 'block' : 'none'}}>
                                    <Button style={uploadBtnStyle} onClick={()=>item.ref.current.click()}><Icon type="upload"/>Upload Photo</Button>   
                                </div>
                                <div style={{display: item.file_url != null ? 'block' : 'none'}}>
                                    <img src={item.file_url} style={{width:'100%'}}/>
                                </div>
                            </div>      
                        )
                    })
                }

                <div style={{width: '100%', marginBottom: '16px'}}>
                    <div style={Style.StepStyle}>Personal Photo</div>
                </div>

                <Card 
                    bodyStyle={{
                        padding:'15px 10px 15px 10px'
                    }}
                    style={{
                        width: '100%',
                    }}>

                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>

                        {idFileURL ? <img src={idFileURL} alt="avatar" style={{ width: '100%' }}/> : <img src={UploadIDImg}/>}
                        
                        </div>

                        <input type="file"
                            ref={idUpload}
                            style={{display: 'none'}}
                            onChange={(e)=>handleUploadImage(e.target.files[0], "ID")}
                            accept=".jpg,.png,.jpeg"
                            >
                        </input>

                        <Button style={Style.takeSelfieStyle} onClick={()=>idUpload.current.click()}>{idFileURL ? 'Replace' : 'Valid ID of Owner'}</Button>
                        <div style={Style.textStyle2}>
                            You can check here the list of valid IDs.
                        </div>
                </Card>
                <Card 
                    bodyStyle={{
                        padding:'15px 10px 15px 10px'
                    }}
                    style={{
                        width: '100%',
                        marginTop: '16px'
                    }}>

                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>

                        {selfieFileURL ? <img src={selfieFileURL} alt="avatar" style={{ width: '100%' }}/> : <img src={SelfieImg}/>}
                        
                        </div>

                        <Button style={Style.takeSelfieStyle} onClick={()=> handleCameraPermission()}>Take a Selfie</Button>
                        <div style={Style.textStyle2}>
                            Your selfie will help us prove your digital identity.
                        </div>
                </Card>
                          
            </Card>

            <div style={{display:'flex'}}>
                <div style={{width: '49%', marginRight: '2%'}}>
                    <Button style={Style.backBtnStyle} onClick={()=>goToStep(1)}>Back</Button>

                </div>
                <div style={{width: '49%'}}>
                    <Button style={Style.submitBtnStyle} loading={loading} onClick={()=>validateFields()}>Next</Button>
                </div>

            </div>

            <div style={{
                padding: '5px 10px 5px 10px',
                boxShadow: '0px -7px 10px rgba(0, 0, 0, 0.04)',
                position: "fixed",
                zIndex: "200",
                marginLeft: '-20px',
                top: "0",
                width: '100%',
                height: '100%',
                background: '#2b2d33'
            }} align='center' hidden={!cameraModal}>
                <div align="right"><button style={Style.closeCamera} onClick={() =>closeCamera()}><Icon type="close" /></button></div>
                <video ref={playerRef} style={{ width: '100%', height: '100%'}} playsInline />
                <button style={Style.captureBtn} onClick={() => captureImage(playerRef.current)}/>
                <canvas ref={canvasRef} style={{ width: '100%', height: '325px', display: 'none'}} />
            </div>

            <Modal
                visible={blockModal}
                onCancel={()=>setBlockModal(false)}
                footer={null}
                >
                <div style={Style.modalBodyTips} align='center'>
                    <img src={CameraIcon} />
                    <div style={Style.blockedStyle}>Camera access is disabled</div>
                    <div style={Style.textStyle3}>
                    {messageModal}
                    </div>

                    <button style={Style.submitBtnStyle} onClick={() => setBlockModal(false)}> Got It </button>
                </div>
            </Modal>
            
        </div>
    )

}

export default BusinessDocuments