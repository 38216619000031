import { makeStyles, useMediaQuery } from '@material-ui/core'
import * as Image from '../../static/images/home/index'
import { isMobile, isTablet, isIPad13 } from 'react-device-detect'


const isHighReso = window.screen.width * window.devicePixelRatio == 1440 && window.screen.height * window.devicePixelRatio >= 900;
const isHighDef = window.screen.width * window.devicePixelRatio > 1440 && window.screen.height * window.devicePixelRatio > 900;

const msgStyles = makeStyles(theme => ({

    body: {
        fontFamily: 'Inter',
    },

    webHeader: {
        height: "80px",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#fff",
        boxShadow: "0px 3px 5px rgba(57,63,72,0.1)",
        width: '100%',
        position: 'fixed',
        zIndex: 1,
        paddingLeft: '144px',
        paddingRight: '144px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '144px',
            paddingRight: '32px',
        },
        [theme.breakpoints.between('md', 'md')]: {
            paddingLeft: '80px',
            paddingRight: '80px',
        },
    },

    headerChoices: {
        display: 'flex', 
        justifyContent:'space-between',
        [theme.breakpoints.up('md')]: {
            width: '70%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '60%',

        },
        [theme.breakpoints.up('xl')]: {
            width: '50%',
        },
    }, 

    headerList: {
        padding: 8
    },

    headerLink: {
        color: '#0D3D76',
        fontWeight: 'bold',
        fontSize: 14,
        lineHeight: '24px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.between('md', 'md')]: {
            display: 'none',
            paddingLeft: 12,
            paddingRight: 12,
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 24,
            paddingRight: 24,
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: 32,
            paddingRight: 32,
        },
    },

    headerActionBtn: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
            width: '30%',

        },
        [theme.breakpoints.up('lg')]: {
            width: '18%',

        },
        [theme.breakpoints.up('xl')]: {
            width: '18%',
        },
    },

    signUp: {
        color: '#FFFFFF', 
        backgroundColor: '#F5922F',
        border: '1px solid #f5922f',
        borderRadius: '4px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',   
        '&:hover': {
            backgroundColor: '#fff',
            color: '#F5922F', 
            border: '1px solid #f5922f',
        },

        [theme.breakpoints.down('sm')]: {
            width: 93,
            height: '32px',
            marginTop: 24,

        },
        [theme.breakpoints.up('md')]: {
            width: 150,
            height: '48px',
            marginTop: 16,
            marginBottom: 8,
        },

        [theme.breakpoints.up('lg')]: {
            width: 172,
            height: '48px',
            marginTop: 16,
            marginBottom: 8,
        },

        [theme.breakpoints.up('xl')]: {
            width: 172,
            height: '48px',
            marginTop: 16,
            marginBottom: 8,
        },
    },

    loginBtnDiv: {
        marginTop: 8
    },

    login: {
        border: '0px',
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#F5922F',
        '&:hover': {
            color: '#0D3D76'
        },
    }, 

    dashboard: {
        height: '48px',
        width: 170,
        marginTop: 16,
        borderRadius: '4px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        backgroundColor: '#fff',
        border: '2px solid #F5922F',
        color: '#F5922F',
        [theme.breakpoints.down('sm')]: {
            width: 120,
            height: '40px',
            marginTop: 20,

        },
        '&:hover': {
            backgroundColor: '#F5922F',
            border: '2px solid #F5922F',
            color: '#fff'
        },
    },

    mainHeaderStyle:{
        height: isTablet ? "9%" : '13%',
        width: '100%',
        zIndex: '1',
        position: 'fixed',
        display: "flex",
        padding: '24px 18px 24px 18px',
        justifyContent: "space-between",
        background: '#fff',
        boxShadow: "0px 3px 5px rgba(57,63,72,0.1)",
        [theme.breakpoints.down("sm")]: {
            padding: '16px',
           
        },

        [theme.breakpoints.down("xs")]: {
            padding: '12px',
           
        },
    },

    buxLogo:{
        [theme.breakpoints.down("xs")]: {
            width: '50%'
        },  
    },

    spacer: {
        width: '100%',
        height:'64px',
        [theme.breakpoints.down("sm")]: {
            height:'110px'
        },
        [theme.breakpoints.down("xs")]: {
            height:'90px'
        },
    },

    webLanding:{
        height: '100%',
        backgroundImage: `url(${isIPad13 ? Image.MobileLanding : Image.WebLanding})`, 
        backgroundSize: 'cover', 
        backgroundRepeat: 'no-repeat',
        paddingBottom: "3%",
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            backgroundImage: `url(${Image.MobileLanding})`, 
        },
    },

    cont: {
        [theme.breakpoints.down('sm')]: {
            padding: '150px 0px 0px 16px',
            width: '100%',
        },
        [theme.breakpoints.between('md', 'md')]: {
            padding: '150px 0px 0px 80px',
            width: '100%',
            // background: 'green'

        },

        [theme.breakpoints.up('lg')]: {
            padding: '164px 0px 0px 144px',
            width: '100%',
            // background: 'blue'

        },

        [theme.breakpoints.up('xl')]: {
            padding: '184px 0px 0px 144px',
            width: '100%',
            // background: 'red'
        },
    },

    headerText:{    
        fontStyle: 'normal',
        fontWeight: '900',
        color: '#fff',
        fontSize: '3.5vw',
        [theme.breakpoints.down('sm')]: {
            fontSize: '48px',
            lineHeight: '48px',
            width: '80%'

        },
        [theme.breakpoints.up('md')]: {
            // fontSize: '48px',
            lineHeight: '48px',
            width: '60%',
        },

        [theme.breakpoints.up('lg')]: {
            // fontSize: '56px',
            lineHeight: '56px',
            width: '60%',
        },

        [theme.breakpoints.up('xl')]: {
            lineHeight: '64px',
            width: '60%',
        },
    },

    subHeaderText:{ 
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '2vw',
        color: '#fff',
        [theme.breakpoints.down('sm')]: {
            fontSize: '32px',
            lineHeight: '48px',
        },
        [theme.breakpoints.up('md')]: {
            // fontSize: '24px',
            lineHeight: '48px',
        },

        [theme.breakpoints.up('lg')]: {
            lineHeight: '48px',
            marginTop: 16
        },
    },

    pText: {
        marginTop: 28,
        fontStyle: 'normal',
        fontWeight: '500',
        color: '#fff',
        [theme.breakpoints.down('sm')]: {
            marginTop: 8,
            fontSize: '14px',
            lineHeight: '21px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '24px',
            lineHeight: '48px',
        },

        [theme.breakpoints.up('lg')]: {
            fontSize: '28px',
            lineHeight: '35px',
        },
    },

    signUp2: {
        zIndex:0,
        marginTop: 48,
        width: '190px',
        height: '48px',
        backgroundColor: '#F5922F',
        borderRadius: '4px',
        border: '1px solid #f5922f',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',    
        color: '#FFFFFF', 
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F5922F',
            color: '#FFFFFF', 
        },
    },

    mapCard: {
        [theme.breakpoints.down("sm")]: {
            marginTop: '52px',
            padding: 16
        },
        [theme.breakpoints.up("md")]: {
            marginTop: '5%',
            padding: '0px 32px 0px 80px',

        },
        [theme.breakpoints.up("lg")]: {
            marginTop: '5%',
            padding: '0px 32px 0px 144px',

        },

        [theme.breakpoints.up("xl")]: {
            marginTop: '8%',
            padding: '0px 110px 0px 130px',
        },

    },

    landingCard: {
        background: '#fff',
        boxShadow: '0px 12px 30px rgba(0, 0, 0, 0.1)',
        borderRadius: 10,
        padding: 64,
        width: '371px',
        height: '268px',
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            marginBottom: 16,
            height: '206px',
            padding: 32,

        },
        [theme.breakpoints.up("md")]: {
            width: '25vw',
            padding: 48,
            
        },

        [theme.breakpoints.up("lg")]: {
            width: '25vw',
            padding: 48,
            
        },


        [theme.breakpoints.up("xl")]: {
            width: '95%',
            padding: 64,

        },


    },

    landingText: {
        marginTop: 20,
        color: '#000000',
        fontSize: 16,
        lineHeight: '24px'
    },

    pricingDiv:{
        height: '100%',
        backgroundImage: `url(${Image.PricingBg})`, 
        backgroundSize: '100%', 
        backgroundRepeat: 'no-repeat',
        paddingBottom: "9%",
        [theme.breakpoints.down("sm")]: {
            padding: '16px',
            paddingBottom: '64px',
            background: `none`, 

        },
        // [theme.breakpoints.up("md")]: {
        // },
        // [theme.breakpoints.up("lg")]: {
        //     marginTop: '3%'
        // },
        // [theme.breakpoints.up("xl")]: {
        //     paddingTop: '10vh'
        // },
    },

    bHeader1: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '36px',
        lineHeight: '40px',
        color: '#0D3D76',
        [theme.breakpoints.down("sm")]: {
            paddingTop: '3vh',
            fontSize: '20px',
            lineHeight: '29px'
        },
        [theme.breakpoints.up("lg")]: {
            paddingTop: '10vh'
        },
        [theme.breakpoints.up("xl")]: {
            paddingTop: '3vh',
        },
        
    },

    bText: {
        marginTop: 30,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '1vw',
        lineHeight: '30px',
        color: '#2B2D33',
        letterSpacing: '-0.01em',
        opacity: 0.8,
        [theme.breakpoints.down("sm")]: {
            marginTop: 8,
            fontSize: '16px',
            padding: '0px 20px 0px 20px'
        },
    },

    
    pricingCard:{
        height: '150px',
        background: '#fff',
        boxShadow: '0px 12px 30px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '32px',
        width: '466px'
    },

    pricingCard1:{
        height: '100%',
        background: '#fff',
        boxShadow: '0px 12px 30px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        marginBottom: '32px',
    },

    pricingText1: {
        fontSize: 16,
        lineHeight: '24px',
        color: '#0d3d76',
        fontWeight: 'bold'
    },

    pricingBtn: {
        marginTop: 24,
        color: '#FFFFFF', 
        backgroundColor: '#F5922F',
        border: '1px solid #f5922f',
        borderRadius: '4px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',  
        width: 220, 
        height: '48px',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#F5922F', 
            border: '1px solid #f5922f',
        },
    },

    partnersDiv: {
        position: 'relative', 
        paddingBottom: '5%'
    },

    partnersImg: {
        marginTop: '5vh'
    },

    partnersMore: {
        marginTop: '5vh',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#0d3d76',
        [theme.breakpoints.down("sm")]: {
            padding: '0px 32px'
        },
    },

    bHead: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '2vw',
        lineHeight: '1.2em',
        color: '#000000',
        [theme.breakpoints.down("sm")]: {
            fontSize: 16,
            padding: '0px 20px 0px 20px'
        },

    },

    bPadding: {
        margin: 'auto',
        padding: '10vw'
    },

    preFooter: {
        background: 'linear-gradient(88.12deg, #2DA6D1 0%, #74BFE6 100%)',
        height: '261px',
        padding: '90px',
        [theme.breakpoints.down("sm")]: {
            padding: '32px !important',
            height: '156px'
        },
    },

    preFooterDiv:{
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',
            textAlign: 'center',
            justifyContent: 'center',
        },

    },

    preFooterText:{
        fontSize: 32,
        lineHeight: '80px',
        color: '#fff',
            [theme.breakpoints.down("sm")]: {
            marginTop: 8,
            fontSize: '20px',
            lineHeight: '20px',

        },
    },

    preFooterBtn:{
        marginLeft: '30px',
        marginTop: '16px',
        width: 256,
        height: '48px',
        color: '#fff',
        borderRadius: '10px',
        background: '#F5922F',
        border: '1px solid #F5922F',
        fontWeight: 'bold',
        cursor: 'pointer',
        outline: 0,
        color: '#fff',
            [theme.breakpoints.down("sm")]: {
            marginTop: 32,
            marginLeft: '0px',
            fontSize: '16px',
            lineHeight: '20px',

        },
    },

    comparativeDiv: {
        padding: '5% 144px',
        background: 'rgba(238, 238, 238, 0.4)',
        [theme.breakpoints.down("sm")]: {
            padding: '8% 16px'
        },
    },

    chartDiv: {
        padding: '3% 0px 1% 0px'
    },

    buxLogos: {
        width: 53,
        height: '53px',
        [theme.breakpoints.down("sm")]: {
            padding: 16,

        }
    },

    titleCard: {
        width: '8vw',
        height: '57px',
        textAlign: 'center',
        borderRadius: '12px 12px 0px 0px',
        color: '#2b2d32',
        fontWeight: 'bold',
        lineHeight: '19px',
        fontSize: '0.8vw',
        [theme.breakpoints.down("sm")]: {
            fontSize: '14px',
            lineHeight: '21px',
            width: '76px'

        },
    },

    chartCard: {
        background: '#fff',
        border: '1px solid #e6eaf0',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.05)',
        borderRadius: '12px',
        marginTop: '2%'
    },

    cardTitle: {
        padding: '20px 40px',
        color: '#2b459b',
        fontWeight: 'bold',
        fontSize: '1.2vw',
        lineHeight: '24px',
        borderBottom: '1px solid #E6EAF0',
        [theme.breakpoints.down("sm")]: {
            fontSize: '14px',
            lineHeight: '21px',
            padding: '20px 16px',

        },
    },

    cardText: {
        padding: '40px',
        color: '#333333',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '19px',
        [theme.breakpoints.down("sm")]: {
            fontSize: '12px',
            lineHeight: '18px',
            padding: '12px 16px'
        },

    },

    // mainBody:{
    //     height: '50%',
    //     backgroundImage: `url(${HeaderImage1})`, 
    //     backgroundSize: '100%', 
    //     backgroundRepeat: 'no-repeat',
    // },

    header:{
        paddingRight: 144
    },

    login: {
        border: '0px',
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#F5922F',
        '&:hover': {
            color: '#0D3D76'
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: '16px',
  
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: '14px',
    
        },
    }, 



    cont2: {
        marginTop: '90px',
    },


    pText1: {
        marginTop: 28,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#0D3D76',
        width: '68%',
        [theme.breakpoints.down("sm")]: {
            fontSize: '12px',
            marginTop: 16,


        },
    },


    watch: {
        height: '40px',
        width: 170,
        marginTop: 20,
        borderRadius: '4px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        backgroundColor: '#fafafa',
        border: '2px solid #1D82B8',
        color: '#1D82B8',
        '&:hover': {
            backgroundColor: '#1D82B8',
            border: '2px solid #1D82B8',
            color: '#fff'
        },
    },



    rightImage: {
        position: 'relative',
        
    },

    rightLaptop: {
        [theme.breakpoints.down("lg")]: {
            width: '50%',
        },
    },



    bgImage: {
        backgroundColor: '#F5F5F4',
        marginTop: -80, 
        // backgroundImage: `url(${bg})`, 
        backgroundSize: '100%', 
        backgroundRepeat: 'no-repeat'
    },

    grid: {
        padding: 16,
    },

    jumboTron: {
        marginTop: -100,
        paddingLeft: '32px',
        paddingRight: '32px',
    },

    jtHeader: {
        paddingTop: 90,
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 'bold ',
        fontSize: '36px',
        lineHeight: '40px',
        color: '#0D3D76',
        [theme.breakpoints.down("sm")]: {
            padding: 0,
            fontSize: 20,
            lineHeight: '40px'
        },
    },

    jtgIcon: {
        textAlign: 'center'
    },

    jtgHeader: {
        marginTop: '20px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#000000'
    },

    jtgText: {
        marginTop: '20px',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#2B2D33',
        opacity: 2

    },


    carouselDiv:{
        background: '#FAFAFA',
        [theme.breakpoints.up("md")]: {
            padding: '0px 204px 44px 204px',
            marginTop: '44px',
        }
    },

    testimonialsCard:{
        marginRight: 32,
        padding: '35px 20px 20px 20px',
        boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        background: '#fff',
        textAlign: 'left',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            padding: '32px 12px 16px 12px',
            margin: '20px 0px 40px 0px',
            display: 'block',
        
        },
    },

    comment:{
        marginTop: '17px',
        paddingBottom: '32px',
        fontStyle: 'italic',
        color: '#000000',
        borderBottom: '1px solid #E6EAF0',
    },

    testiDetails:{
        marginTop: 20,
        display: 'flex',
    },

    by:{
        fontWeight: 'bold',
        fontSize: 18,
        lineHeight: '27px'
    },

    // pricingDiv:{
    //     position: 'relative',
    //     marginBottom: 64
    // },

    sideIcon: {
        position: 'absolute',
        right: 0,
        [theme.breakpoints.down("sm")]: {
            top: -500,
            zIndex: -100
        },
    },

    sideIcon1: {
        position: 'absolute',
        right: 0,
        top: '-100px',
    },


    
    
    pricingHeader: {
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '24px',
        color: '#0D3D76',
    },

    pricingText: {
        marginTop: 4,
        fontSize: '12px',
        lineHeight: '16px',
        color: "#2B2D33",
    },

    priceDiv:{
        color: '#0D3D76',
        fontWeight: '800',
        fontSize: 16,
        lineHeight: '24px',
        borderRadius: '10px',
        textAlign:'center',
    },

    priceDivMobile:{
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        padding: 10,
        color: '#0D3D76',
        fontSize: 16,
        lineHeight: '24px',

    },

    perTrans:{
        color: '#909196',
        fontSize: '12px',
        lineHeight: '24px',
        textAlign: 'center',
    },

    b: {
        margin: 0,
        padding: 0,
        borderTop: '1px solid #D1D5DD'
    },
    
    bHeader: {
        paddingTop: 50,
        display: 'flex',
        justifyContent: 'center',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '36px',
        lineHeight: '40px',
        color: '#0D3D76',
    },



    merchantSize:{
        display: 'flex',
        justifyContent: 'space-between'
    },

    bImage: {
        width: '100%'
    },

    imageSize:{
          [theme.breakpoints.down("sm")]: {
            width: '90%',
          }
        // },
        // [theme.breakpoints.down("lg")]: {
        //     width: '100%',
        // },
        // [theme.breakpoints.down("xl")]: {
        //     width: '100%',
        // },
    },


    // bPadding: {
    //     padding: '180px 64px 180px 64px',
    //     margin: 'auto'
    // },

    // bHead: {
        
    //     fontStyle: 'normal',
    //     fontWeight: 'bold',
    //     fontSize: '32px',
    //     lineHeight: '32px',
    //     color: '#000000',

    //     [theme.breakpoints.down("sm")]: {
    //         fontSize: 16,
    //         padding: '0px 20px 0px 20px'
    //     },

    // },

    bHead1: {
        
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '32px',
        color: '#000000',

        [theme.breakpoints.down("sm")]: {
            fontSize: 16,
            padding: '0px 20px 0px 20px'
        },

    },

    bText1: {
        marginTop: 30,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2B2D33',
        letterSpacing: '-0.01em',
        opacity: 0.8,
    },


    footerLink:{
        padding: '0 16px', 
        color: '#F5922F',
        '&:hover':{
            color: '#0D3D76'
        },
        [theme.breakpoints.down("md")]: {
            marginTop: 32,

        },
    },

    h1: {
        paddingTop: 50,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
        textAlign: 'center',
        textTransform: 'uppercase'
    },

    img1: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30,
    },

    footer: {
        backgroundColor: '#fff',
        padding: '40px 144px',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.between('md', 'md')]: {
            paddingLeft: '80px',
            paddingRight: '80px',
        },
        [theme.breakpoints.between('lg', 'lg')]: {
            paddingLeft: '90px',
            paddingRight: '90px',
        },

    },

    fLeft: {
        marginLeft: '50px',
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 'bold',
    },

    fc:{
        marginRight: 30,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '-0.01em',
        color: '#333333',
        opacity: 0.5,
        textAlign: 'center'

    },

    fRight: {
        marginRight: '50px',
    },

    fUbx: {
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2B2D33',
        textDecoration: 'underline',
        '&:hover': {
            color: '#F5922F'
        }
    },

    // MOBILE

    makeYourStyle : {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "36px",
    color: "#000000",
    textAlign: "center"
  },

   labelStyle : {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#2B2D33",
    textAlign: "center",
    textTransform: "uppercase",
    marginTop: "20px"
  },

   labelStyle2 : {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#2B2D33",
    marginTop: "20px"
  },
   textStyle : {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2B2D33",
    textAlign: "center",
    opacity: "0.8",
    margin: "20px 0 20px 0"
  },

   textStyle2 : {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2B2D33",
    opacity: "0.8",
    margin: "20px 0 20px 0"
  },

//    signUp : {
//     fontStyle: "normal",
//     fontWeight: "600",
//     fontSize: "16px",
//     lineHeight: "24px",
//     color: "#fff",
//     textAlign: "center",
//     borderColor: "#1D82B8",
//     height: "50px",
//     margin: "20px 0 0 0",
//     backgroundColor: '#1D82B8'
//   },

   watchVideo : {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1D82B8",
    textAlign: "center",
    borderColor: "#1D82B8",
    height: "50px",
    margin: "20px 0 30px 0"
  },

   signUpNow : {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    margin: '10px 10px 0px 0px',
    height: '40px'
  },

   copyRightStyle : {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: 'center',
    color: '#333333',
    opacity: '0.5',
    marginBottom: '20px'
  },

  menuBtn:{
      background: '#fff',
      border: '1px solid #fff',
      cursor: 'pointer',
      height: '0px',
      marginRight: 16,
      outline: 0 
  },

  drawerLink:{
        fontSize: 16,
        lineHeight: '24px',
        color: '#0D3D76',
        fontWeight: 'bold',
        textTransform: 'uppercase'
  },

  drawerBtn:{
        border: '1px solid #F5922F',
        color: '#F5922F',
        width: '100%',
        height: '48px',
        background: '#fff',
        outlne: 0,
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
        borderRadius: '10px',
        boxSize: 'border-box'
  },

  smallHeader: {
    marginTop: 64,
    color: '#0D3D76',
    fontWeight: '800',
    fontSize: 20,
    lineHeight: '24px',
    [theme.breakpoints.down("sm")]: {
        margin: 0,
        fontSize: '16px',

    },
  },

  specialRates: {
    width: '250px',
    height: '40px',
    backgroundColor: '#F5922F',
    border: '1px solid #f5922f',
    borderRadius: '4px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',    
    color: '#FFFFFF', 
    cursor: 'pointer',
    // '&:hover': {
    //     backgroundColor: '#fff',
    //     color: '#F5922F', 
    //     cursor: 'pointer'
    // },
  }



}))

export default msgStyles