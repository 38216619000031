export const textStyle = {
        
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    color: '#2B2D33',
    opacity: '0.8',
}

export const text2Style = {
    
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    color: '#2B2D33',
    opacity: '0.8',
    marginBottom: '20px'
}

export const forgotStyle = {
    
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#2B2D33',
    opacity: '0.5',
    float: 'right'
}

export const rememberMeStyle = {
    
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#2B2D33',
    marginTop: '20px'
}

export const loginBtnStyle = {
    borderRadius: '4px',
    backgroundColor: '#F5922F',
    width: '100%',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    height: '50px',
    marginTop: '20px',
    border: 0,
}

export const loginBtnStyle1 = {
    borderRadius: '4px',
    opacity: '0.5',
    color: '#fff',
    background: '#F5922F',
    width: '100%',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    height: '50px',
    marginTop: '20px',
    border: 0,
}

export const facebookBtnStyle = {
    backgroundColor: '#3B5998',
    border: '#3B5998',
    fontStyle: 'normal',
    borderRadius: '4px',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    height: '50px',
    width: '49%',
    marginRight: '2%'
}

export const googleBtnStyle = {
    backgroundColor: '#F1F1F1',
    borderRadius: '4px',
    border: '1px solid #f1f1f1',
    color: '#F1F1F1',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#2B2D33',
    height: '50px',
    width: '100%',
}

export const dontHaveStyle = {
    
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    color: '#2B2D33',
    display: 'flex',
    marginTop: '20px',
}

export const icon_style = {
    width: '20px',
    height: '20px',
    float: 'left',
    marginLeft: '20px'
}

export const loginStyle = {

    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    color: '#000',
    marginBottom: '20px'
}