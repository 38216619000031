import React,{ useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Typography, Input, Spin, Button, Divider, Tooltip } from 'antd';
import { Checkbox, withStyles } from '@material-ui/core';
import facebook_icon from '../../../../static/icons/facebook.png';
import google_icon from '../../../../static/icons/google.png';
import SocialButton from '../../../social/socialButton';
import { sign_up, fb_sign_up, google_sign_up } from "../../signUpActions";
import {history} from '../../../../store/history'
import {showAlert} from '../../../alert/alertAction'
import BuxModal from '../../../BuxModal/modal';
import BuxAlert from '../../../alert/alert';
import {isMobile} from 'react-device-detect';

const IndiSignUp = () => {

  const dispatch = useDispatch();
  const alert = useSelector(state => state.alert.showAlert);
  const isLoading = useSelector(state => state.signup.loading)
  const [cleared, setCleared] = useState(false);
  const [params, setParams] = useState({merchantType:'ME', firstName: '', lastName: '', email: '', mobileNumber: '', password: ''});
  const [signUpAgree, setSignUpAgree] = useState(false);
  const [showPass, setShowPass] = useState(false)
   
  const validateFields = () => {

      if(Object.keys(params['firstName']).length > 30){
          dispatch(showAlert({ type: "error", message: "First name exceeded to max limit of 30 characters" }));
      }
      else if(Object.keys(params['lastName']).length > 30){
          dispatch(showAlert({ type: "error", message: "Last name exceeded to max limit of 30 characters" }));
      }
      else if(Object.keys(params['email']).length > 100){
        dispatch(showAlert({ type: "error", message: "Email exceeded to max limit of 100 characters" }));
      }
      else if(Object.keys(params['password']).length < 7){
        dispatch(showAlert({ type: "error", message: "Password must contain at least 7 characters" }));
      }
      else if(Object.keys(params['password']).length > 128){
          dispatch(showAlert({ type: "error", message: "Password exceeded to max limit of 128 characters" }));
      }
      else if(params['password'].search(/[0-9]/) === -1){
          dispatch(showAlert({ type: "error", message: "Password must contain at least 1 number" }));
      }
      else if(params['password'].search(/[A-Z]/) === -1){
          dispatch(showAlert({ type: "error", message: "Password must contain at least 1 capital letter" }));
      }
      else if(params['password'].search(/[a-z]/) === -1){
          dispatch(showAlert({ type: "error", message: "Password must contain at least 1 small letter" }));
      }
      else {
        dispatch(sign_up(params));
      }
      console.log(params)
    }

  const hasBlanks = () => {
    const isEmpty = Object.values(params).some(x => (x === null || x === ''));
    if(isEmpty === true || Object.keys(params).length < 6 || !signUpAgree){
      return true;
    }
    return false;
  }

  const handleChange = (event) => {
    if(event.target.name === 'mobileNumber'){
      setParams({...params, [event.target.name]:autoFormat(event.target.value)})
    } else if (event.target.name === 'firstName' || event.target.name === 'lastName') {
      setParams({...params, [event.target.name]:sliceSymbols(event.target.value)})
    } else {
      setParams({...params, [event.target.name]:event.target.value})
    }
  }

  const autoFormat = (value) => {
    const formattedValue = value.replace(/\D/g, '')
    if (value !== null) {
      return formattedValue;
    } 
      return value;
  }

  const sliceSymbols = (text) => {
    const formattedValue = text.replace(/[^a-zA-Z0-9 ]/g, "")
    if (text !== null) {
      return formattedValue;
    } 
      return text;
  }

  function fbSubmitCreds(token) {
    token.messenger_id = window.fbuid;
    dispatch(fb_sign_up(token));
  }

  function googleSubmitCreds(token) {
      token.messenger_id = window.fbuid;
      dispatch(google_sign_up(token));

    }

  const handleFBSocialLogin = (user) => {
      fbSubmitCreds(user.token);
  }

  const handleGoogleSocialLogin = (user) => {
      googleSubmitCreds(user.token)
  }
  
  const handleSocialLoginFailure = (err) => {
    console.error(err)
  }

  function loadFbPlugin() {
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'Messenger'));
    loadMessengerExt();
  }

  function loadMessengerExt() {

    window.extAsyncInit = function () {
         window.MessengerExtensions.getUserID(function success(uids) {
            window.fbuid = uids.psid;
        }, function error(err, errorMessage) {
            window.fbuid = '';

        });
    
    };

  }

  useEffect(() => {
    window.fbuid = ''
    if(isMobile){
        if(localStorage.getItem("jwtToken") !== "" && localStorage.getItem("jwtToken") != null){
          history.push('/mobile/dashboard')
        }
        else  {
            if(!isMobile){
                history.push('/messenger/error')
            }
            else{
                loadFbPlugin();
                localStorage.setItem("jwtToken", "");
                localStorage.setItem("balance", "0 PHP");
            }
          }
        }
      else{
        if(localStorage.getItem("jwtToken") != "" && localStorage.getItem("jwtToken") != null){
            window.location.href = '/dashboard'
          }
        else{
            loadFbPlugin();
            localStorage.setItem("jwtToken", "");
            localStorage.setItem("balance", "0 PHP");
        }
    }
  }, [])

  return (
    <div>
      <BuxModal />
      <Typography style={styles.topMsg}>
        If you are an online seller or small business without business registration documents.
      </Typography>
      <div hidden={!alert} style={{padding: alert? '8px 0 2px 0' : null}}>
        <BuxAlert />
      </div>
      <div style={styles.inputContainer}>
        <div style={styles.inputDiv}>
          <label style={styles.labelFont}>First Name</label>
          <Input style={styles.input}
            name='firstName'
            value={params['firstName']}
            placeholder="First Name" 
            onChange={handleChange}
            disabled={isLoading}
          />
        </div>
        <div style={styles.inputDiv}>
          <label style={styles.labelFont}>Last Name</label>
          <Input style={styles.input} 
            name='lastName'
            value={params['lastName']}
            placeholder="Last Name" 
            onChange={handleChange}
            disabled={isLoading}
          />
        </div>
        <div style={styles.inputDiv}>
          <label style={styles.labelFont}>Email</label>
          <Input style={styles.input} 
            name='email'
            placeholder="Email" 
            value={params['email']}
            type='email'
            onChange={handleChange}
            disabled={isLoading}
          />
        </div>
        <div style={{...styles.inputDiv, position: 'relative'}}>
          <label style={styles.labelFont}>Mobile Number</label>
          <Input style={{...styles.input, paddingLeft: '32%', paddingTop: '6%'}} 
            name='mobileNumber'
            value={params['mobileNumber']}
            maxLength={10}
            onChange={handleChange}
            disabled={isLoading}
          />
          <Typography style={styles.mobilePrefix}>+63 | </Typography>
        </div>
        <div style={{...styles.inputDiv, width: '100%', position: 'relative'}}>
          <label style={styles.labelFont}>Password</label>
          <Input style={styles.input} 
            type={showPass ? 'text':'password'}
            name='password'
            placeholder="Password"
            value={params['password']} 
            onChange={handleChange}
            disabled={isLoading}
          />
          <a name='passwordToggle' style={styles.showBtn} onClick={()=>setShowPass(!showPass)}>{showPass?'Hide':'Show'}</a>
        </div>
      </div>
      <div style={styles.checkText}>
        <CustomCheckbox 
          name="agreeCheckBox"
          style={styles.checkBox}
          value={signUpAgree}
          onChange={() => setSignUpAgree(!signUpAgree)}
        />
        <div>
          {' By signing up, I agree with the '}
          <a style={styles.link} onClick={() => history.push('/terms')}>Terms and Conditions</a>
          {' and '}
          <a style={styles.link} onClick={() => history.push('/privacy_policy')}>Privacy Policy</a>
        </div>
      </div>
      <Button
        name='signUpBtn'
        disabled={ isLoading || hasBlanks() }
        style={{...styles.signUpBtn, opacity: isLoading || hasBlanks()? '.5' : '1' }}
        onClick={validateFields}
        loading={isLoading}
      >
        Sign up
      </Button>
      <Divider><span style={styles.divider}>or sign up with</span></Divider>
      <div style={{width: '100%'}}>
        {/*<SocialButton
          provider='facebook'
          appId='195672864228468'
          style={styles.fbBtn}
          onLoginSuccess={handleFBSocialLogin}
          onLoginFailure={handleSocialLoginFailure}
        >
          <img src={facebook_icon} style={styles.iconStyle} alt="#"/>
          Facebook
        </SocialButton>*/}
        <SocialButton
          provider='google'
          appId='998270812585-n505g6elcp4gvijo16pt7d2koj1aj4je.apps.googleusercontent.com'
          style={styles.googleBtn}
          onLoginSuccess={handleGoogleSocialLogin}
          onLoginFailure={handleSocialLoginFailure}
        >
          <img src={google_icon} style={styles.iconStyle} alt="#"/>
          Google
        </SocialButton>
      </div>
      <div style={styles.footer} align='center'>
        {'Already have an account? '}
        <a style={styles.link} onClick={() => history.push('/login')}>Login</a>
      </div>
    </div>
  )
}

const CustomCheckbox = withStyles({
  root: {
    color: '#F5922F',
    '&$checked': {
      color: '#F5922F',
    },
  },
  checked: {},
})((props) => <Checkbox size='medium' color="default" {...props} />);

const styles = {
  root: {

  },
  topMsg: {
    padding: '16px 0 12px 0'
  },
  input: {
    padding: '11px 16px 11px 16px',
    fontSize: 16,
    height: 48,
    margin: '4px 0px 0px 0px',
    color: '#2B2D33'
  },
  labelFont: {
    fontSize: 12,
    color: '#212B36'
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  inputDiv: {
    width :'48%',
    padding: '6px 0'
  },
  checkText: {
    paddingTop: 14,
    color: '#2B2D33',
    fontSize: 16,
    display: 'flex',
    alignItems: 'start',
  },
  footer: {
    padding: '28px 0',
    color: '#2B2D33',
    fontSize: 16,
  },
  link: {
    color: '#F5922F',
    fontWeight: '600',
  },
  checkBox: {
    padding: '0 8px 0 0',
  },
  signUpBtn: {
    margin: '20px 0px 0px 0px',
    height: 48,
    width: '100%',
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    borderRadius: 10,
    fontSize: 14,
    fontWeight: '600',
  },
  divider: {
    fontSize: 12,
    color: '#333333',
  },
  fbBtn: {
    backgroundColor: '#3B5998',
    border: '#3B5998',
    fontStyle: 'normal',
    borderRadius: '4px',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    height: '50px',
    width: '49%',
    marginRight: '2%'
  },
  googleBtn: {
    backgroundColor: '#F1F1F1',
    borderRadius: '4px',
    border: '1px solid #f1f1f1',
    color: '#F1F1F1',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#2B2D33',
    height: '50px',
    width: '100%',
  },
  iconStyle: {
    width: '20px',
    height: '20px',
    float: 'left',
    marginLeft: '20px'
  },
  showBtn: {
    position: 'absolute',
    top: `64%`,
    right: '0%',
    transform: 'translate(-50%, -50%)',
    fontSize: 16,
    color: '#F5922F',
    fontWeight: 'bold',
    zIndex: '99',
  },
  mobilePrefix: {
    position: 'absolute',
    top: `64%`,
    left: '17%',
    transform: 'translate(-50%, -50%)',
    fontSize: 16,
    color: '#2B2D33',
    fontWeight: 'normal',
    zIndex: '99',
  }
}

export default IndiSignUp;