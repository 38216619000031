import React, {useEffect} from 'react'
import axios from 'axios'
import {Input,Button, Alert, Divider, Spin, Icon, Typography} from 'antd'
import accountStyle from '../../../static/css/Account'
import OTPInput, { ResendOTP } from "otp-input-react"
import {history} from '../../../store/history'
import {isMobile} from 'react-device-detect'

const ChangeNumber = props => {
    const acct = accountStyle();

    const [userid, setUserId] = React.useState("")
    const [uuid, setUuid] = React.useState("")
    const [contact, setContact] = React.useState("")
    const [alert, showAlert] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState("")
    const [onProcess, setOnProcess] = React.useState(false)
    const [OTP, setOTP] = React.useState("")
    const [OtpDisabled, setOtpDisabled] = React.useState(true)
    const [OtpUid, setOtpUid] = React.useState("")
    const [OtpFinished, setOtpFinished] = React.useState(false)
    const [counter, setCounter] = React.useState(60);
    const [emailOtp, setEmailOtp] = React.useState(false);

    useEffect(() => {

      let current_path = (window.location.pathname+window.location.search).substr(1)

      if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
        history.push('/login?next='+current_path)
      }
      else{

        let search = window.location.search;
        let sparams = new URLSearchParams(search);
        let beUid = sparams.get('uuid');
        let usid = sparams.get('userid');
        setUuid(beUid)
        setUserId(usid)
        
          const intervalId = setInterval(() => {
            setCounter(counter - 1);
          }, 1000);

          if (counter == 0){
            clearInterval(intervalId)
          }
          
          return () => clearInterval(intervalId);
      }
  
      }, [counter]);
    
      const calculateDuration = (duration) => {
          const minutes = Math.floor(duration/60).toString();
          const seconds = Math.floor(duration%60);
          let seconds_str = seconds.toString();
    
          if(seconds < 10){
              seconds_str = "0" +seconds.toString();
          }
    
          return minutes + ":" + seconds_str
      };

    

    function onOTPChange(val) {
        if (val.length === 6) {
          setOtpDisabled(false);
        } else if (val.length < 6) {
          setOtpDisabled(true);
        }
        setOTP(val)
      }

    async function fetchOTP () {
      setOnProcess(true)
      let yourConfig = {
          headers: {
              Authorization: "Token " + localStorage.getItem("jwtToken")
          }
      }
      try{
          let response = await axios.get(
              process.env.REACT_APP_API_URL + `/api/otp/?uuid=${uuid}&new_mobile=${contact}`,
              yourConfig
          );

          if(response.data.status == "success"){
            showAlert(false)
            setCounter(60)
            setOtpUid(response.data.uid)
          }
          else if(response.data.code == "attempts"){
            setAlertMessage(<div>{response.data.message}<div>Please try again on {response.data.unblock}</div></div>)
            showAlert(true)
          }

          else {
            showAlert(true)
            setAlertMessage(response.data.message)
          }
      }
      catch(error){
        if(error.response.status === 401){
          showAlert(true);
          setAlertMessage("You must login first to change your mobile number");
        }
      }

      setOnProcess(false)
        
    }

    async function fetchEmailOtp() {
      setEmailOtp(true);
      try{
          setOnProcess(true);
          let response = await axios.get(
              process.env.REACT_APP_API_URL + `/api/email_otp/?subject=change_mobile&uuid=${uuid}&id=${userid}&new_mobile=${contact}`
          );
          if(response.status === 'success') {
            showAlert(false)
            setCounter(60)
          } else {
            setOnProcess(false);
          }
      }
      catch(error){
          // if((error.response)&&(error.response.status == 401)){
          //     Handle401()
          // }
          console.log(error);
          setOnProcess(false);
      }
      
    }


    async function submitOTP()  {
        setOnProcess(true);
        let params = {
          new_mobile: contact,
          uuid: uuid,
          id: userid,
          uid : OtpUid,
          code: OTP,
          otp_type: 2,
        }
      
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let response = {};
        try{
            if(emailOtp){
              response = await axios.post(
                  process.env.REACT_APP_API_URL + "/api/email_otp/",params,
                    yourConfig
              );
            } else{

              response = await axios.post(
                  process.env.REACT_APP_API_URL + "/api/otp/",params,
                    yourConfig
              );
            }
            
            if(response.data.status == 'success'){
              setOtpFinished(true);
              if(isMobile){
                history.push({ pathname: '/my_profile', state: { isSuccess: true, notifMsg: 'Your mobile number is successfully updated' }})
              } else {
                history.push({ pathname: '/my_profile', state: { isSuccess: true, notifMsg: 'Your mobile number is successfully updated' }})
              }
            //   submit();
            }
            setOnProcess(false)
        } catch(error){
            // if((error.response)&&(error.response.status == 401)){
            //     Handle401()
            // }
            if(error.response.data.code == "attempts"){
              setAlertMessage(<div>{error.response.data.message}<div>Please try again on {error.response.data.unblock}</div></div>)
              showAlert(true)
            } else{
              setAlertMessage(error.response.data.message)
              showAlert(true)
            }
            setOnProcess(false)
        }
      }
  
    const btnStyle = {
      margin: '20px 0px 0px 0px',
      height: 48,
      width: '100%',
      backgroundColor: '#F5922F',
      color: '#FFFFFF',
      borderRadius: 4,
      fontSize: 14,
      fontWeight: '600',
    }

    return(
        <div style={{padding: isMobile ? 20 : ''}}>
            <div id="changeNumberDiv" style={{marginTop: '50%'}} hidden={OtpUid}>
                <div className={acct.changeNumberHeader}>
                    Change Mobile Number
                </div>

                <Alert message={alertMessage} type="error" showIcon style={{ display: alert ? "block" : "none", borderLeft: '4px solid red', marginTop: '20px', marginBottom: '20px' }}/>

                <div className={acct.changeText}>
                    Mobile Number
                </div>

                 <Input size="large" onChange={(e) => setContact(e.target.value)} addonBefore="+63" maxLength={10}/>

                 {onProcess?<Button style={btnStyle} disabled={onProcess} ><Spin /></Button> : <button onClick={() => fetchOTP()} className={acct.changeModalBtn} style={{opacity: contact == "" || contact.length != 10 ? '0.5' : '',
                                                                cursor: contact == "" || contact.length != 10? 'not-allowed': 'pointer'}} disabled={contact == "" || contact.length != 10}>
                     Save
                 </button>}
            </div>
            <div hidden={OtpUid} style={{padding: '8px', display: 'flex', justifyContent: 'end', width: '100%' }} >
              <Icon type="exclamation-circle" theme="filled" style={{color: '#909196', backgroundColor: 'transparent', borderRadius: 20, fontSize: 16, paddingRight: 8}} />
              <Typography style={{fontSize: 12, color: '#54575F'}}>Make sure your account is currently logged in.</Typography>
            </div>
        
            <div id="otpDiv">
            <div hidden={!OtpUid}>
                <div className={acct.spielOtp} align='center'>
                  <div className={acct.otpHeader}>One-time password</div>

                  <div className={acct.sDesc}>

                    {emailOtp?
                      'You will receive a One-Time Password (OTP) on your registered email address.' :
                      <div>
                        You will receive a One-Time Password (OTP) on your registered 
                        mobile number ending in <b>{contact.substr(contact.length - 4)}</b>.
                      </div>
                    }
                  </div>
                </div>

                <Alert message={alertMessage} type="error" showIcon style={{ display: alert ? "block" : "none", borderLeft: '4px solid red', marginTop: '20px', marginBottom: '20px' }}/>

                <div className={acct.inputDiv}>
                <OTPInput
                  value={OTP}
                  onChange={(e) => onOTPChange(e)}
                  style={{display: 'flex', justifyContent: 'center'}}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={counter <= 0}
                  inputStyles={{
                    height: 100,
                    width: isMobile? 48 : 60,
                    fontSize: "48px",
                    color: "#2b2d33",
                    fontWeight: "600",
                    background: "#E6EAF0",
                    boxSizing: "border-box",
                    border: "1px solid #d1d5dd",
                    borderRadius: "4px",
                    margin: '0 4px 0 4px'
                  }}
                />
              </div>

              <div style={{marginTop: 30}} align="center" hidden={onProcess && !emailOtp || emailOtp}>
                <div style={{marginTop: '16px'}}>
                    <button className={counter > 0 ? acct.otpResendBtnDisabled : acct.otpResendBtn}
                        onClick={() => fetchOTP() } 
                        disabled={counter > 0}>Resend Code</button>
                </div>

                <div style={{marginTop: '16px'}} hidden={counter == 0}>
                    <b>"Resend Code"</b> will be enabled after {calculateDuration(counter)}
                </div>
            </div>


              <div className={acct.inputDiv}>
               {onProcess? <Button style={btnStyle} disabled={onProcess} ><Spin /></Button> : <button
                  className={OtpDisabled ? acct.otpDisableBtn : acct.otpSubmitBtn}
                  disabled={OTP.length !== 6}
                  onClick={() => submitOTP()}
                >
                  Submit
                </button>}
              </div>
              {/*
              <div hidden={emailOtp} >
                <div style={{padding: '0 50px', textAlign: 'center'}}>
                  <Divider style={{margin: '30px 0'}}>
                    <strong>OR</strong>
                  </Divider>
                </div>
                <div className={acct.btnEmail}>
                  <a
                    style={{ color: '#F5922F' }}
                    onClick={() => fetchEmailOtp()}
                    disabled={onProcess}
                  >Send OTP code via Email</a>
                </div>
              </div>
            */}
            </div>
            </div>
        </div>
    )
}

export default ChangeNumber