import React from 'react';
import accountSettingsServices from '../../services/account_settings';
import { Typography, Divider, Card, Switch, message } from 'antd';
import { history } from '../../store/history';

const EmailNotif = (props) => {
    const [emailNotif, setEmailNotif] = React.useState(false);
    const isChild = localStorage.getItem("is_child") == 'true' ? 1 : 0;
    const userID = localStorage.getItem("userId");
    const query_params = `?name=receives_payment_related_emails&is_child=${isChild}`;
    const segments = localStorage.getItem('segments');
    const [loading, setLoading] = React.useState(false);
    const testMode = (history.location.pathname.indexOf('/test') > -1);

    const getUserDefinedSettings = async () => {
        setLoading(true);
        try {
            console.log(isChild)
            const res = await accountSettingsServices.getUserDefinedSetting(userID, query_params);
            console.log('USERDFINED', res);
            setEmailNotif(res.data.value);
        } catch (error) {

        }
        setLoading(false);
    }

    const onEmailToggle = async () => {
        setLoading(true);
        let params = {
            'name': 'receives_payment_related_emails',
            'type': 'bool',
            'value': !emailNotif,
            'is_child': isChild,
        }
        try {
            const res = await accountSettingsServices.toggleEmailNotif(userID, params);
            if (res.status === "success") {
                setEmailNotif(!emailNotif);
            } else {
                message.error('Someting went wrong, try again.');
            }
        } catch (error) {
            message.error('Someting went wrong, try again.');
        }
        setLoading(false);
    }

    React.useEffect(() => {
        if (testMode) {
            window.location.hash = '#my_checkout';
        }
        if (!isChild) {
            getUserDefinedSettings();
        }
        if (isChild) {
            getUserDefinedSettings();
        }
    }, [])

    return (
        <Card
            style={styles.root}
            bodyStyle={styles.cardBody}>
            <Typography style={styles.title}>Email Notification</Typography>
            <Divider />
            <div style={styles.content}>
                <div style={styles.switchDiv}>
                    <Typography style={styles.tranxSub}>Your email notifications are currently {emailNotif ? 'enabled' : 'disabled'}.</Typography>
                    <Switch checked={emailNotif} onClick={() => onEmailToggle()}
                        size='small' className={emailNotif && emailNotif && `switch-btn--${segments}`} disabled={loading} />
                </div>
                <Typography style={styles.desc}>
                    {emailNotif ? 'You will receive emails about your paid payment requests.' : 'No emails about your paid payment requests will be sent to you.'}
                </Typography>
            </div>
        </Card >
    )
}

const styles = {
    root: {
        padding: 0,
        width: '100%'
    },
    cardBody: {
        padding: 0
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#2B2D32',
        padding: '32px 32px 0 32px'
    },
    switchDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 0 8px 0'
    },
    desc: {
        fontSize: 14,
        color: '#2B2D32',
        opacity: '0.8',
        lineHeight: '20px',
    },
    tranxSub: {
        color: '#2B2D32',
        fontSize: 16,
        fontWeight: '500'
    },
    content: {
        padding: '0 32px 24px 32px'
    },
}

export default EmailNotif;