import axios from "axios";
import {Handle401} from '../handle401/handle401'

let yourConfig = {
  headers: {
     Authorization: "Token " + localStorage.getItem("jwtToken")
  }
}

async function fetchShipmentsNow() {
  try {
    console.log(yourConfig)
    let response = await axios.get(
      process.env.REACT_APP_API_URL + "/api/shipments/",yourConfig
    );
    console.log(response)
    return response;

  }
  catch (error) {
    if((error.response)&&(error.response.status == 401)){
        Handle401()
    }
  }
}

export { fetchShipmentsNow };
