import React, {useEffect} from 'react'
import { useSelector } from 'react-redux'
import Login from './login';
import OTPCard from '../../otp/otp';
import { isMobile } from 'react-device-detect';
import { history } from '../../../store/history';

const LoginMain = props => {

    const user = useSelector(state => state.login.user);
    const isSubDomain = process.env.REACT_APP_ROOT_URL != window.location.hostname;
    const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;

    useEffect(()=>{
        let search = window.location.search;
        if(isMobile){
            if(localStorage.getItem("jwtToken") !== "" && localStorage.getItem("jwtToken") !== null) {
                history.push('/dashboard');
            }
        }
        else{
            if(localStorage.getItem("jwtToken") !== "" && localStorage.getItem("jwtToken") !== null){

                let search = window.location.search;
                let params = new URLSearchParams(search);
                let next = params.get('next');
                   
                if(next != null && next.length > 0) {
                  window.location.href = next

                }
                else{
                    window.location.href = '/dashboard'
                }
            }
            else{
    
                localStorage.setItem("jwtToken", "");
                localStorage.setItem("balance", "0 PHP");
            }
        }
    },[])
    return(
        <div style={{width: isSubDomain && isHighReso ? '529px' : isSubDomain ? '430px' : '100%', height: '100%'}}>
            { user.otp ?
                <OTPCard otp={user.otp} userid={user.user_details.id} mobile={user.user_details.contact} otp_uid={user.otp_uid} mobile_verified={user.user_details.mobile_verified}/>
                :
                <Login/>
            }  
        </div>
    )
}

export default LoginMain