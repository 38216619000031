export function getStatusInfo(status) {
  if (status.includes("Success")) {
    return "SU";
  } else if (status.includes("Pending")) {
    return "PE";
  } else if (status.includes("Failed")) {
    return "FA";
  }

  return "";
}
