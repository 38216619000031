import { segmentStroke } from "../constants/constants"

export const buttonStyle = {
    padding: '12px 18px 13px 18px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight:'16px',
    height:'40px',
    width: '150px',
    textAlign: 'center'
}

export const marginTop = {
    marginTop: 12
}

export const qrHeaderText = {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: '20px',
    color: '#0d3d76',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap', 
}

export const hideOverflow = {
    marginTop: 12,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',  
}

export const qrLinkText = {
    fontSize: 16,
    lineHeight: '20px',
    borderBottom: `1px solid ${segmentStroke[0]}`
}

export const orDiv = {
    marginTop: 32,
    fontWeight: 'bold'
}

export const shareQrDiv = {
    marginTop: 32,
    textAlign: 'center'
}

export const shareText = {
    fontSize: 20,
    lineHeight: '32px',
    fontWeight: 300,
    color: '#0d3d76'
}

export const btnstyle = {
    outline: 0,
    background: 'transparent',
    border: '0',
    cursor: 'pointer',
    '&:hover': {
        transform: 'scale(1.2)'
    }
}

export const btnText = {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '12px',
    color: '#54575f'
}

export const shareBtnDiv = {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-between'
}

export const quickDiv = {
    marginTop: 32,
    padding: 20,
    color: '2b2d33',

}

export const rightTextDiv = {
    marginLeft: '16px',
    textAlign: 'left'
}

export const header  = {
    fontWeight: 'bold',
    lineHeight: '16px',
    fontSize: 16,
}

export const text = {
    fontSize: 12,
    lineHeight: '16px',
    color: '#909196'
}

export const questionText = {
    fontSize: 16,
    lineHeight: '16px',
    color: '#000000'
}

export const viewMyCheckoutPage = {
    width: '180px',
    height: '40px',
    backgroundColor: '#F5922F',
    border: '1px solid #F5922F',
    borderRadius: '4px',
    color: '#fff',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '24px'
}