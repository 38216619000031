import React from 'react'
import Checked from '../../../static/icons/checked.svg'
import Unchecked from '../../../static/icons/unchecked.svg'
import Crossed from '../../../static/icons/crossed.svg'
import userStyles from './style'
import {Icon} from 'antd'


const UserScope = props => {
    const u = userStyles()

    const users_headers = [ 'Role', 'Payout', 'Reconciliation', 'Generate Payment Link', 'Add User', 'Integration']
    const user_roles = [
        {
            'role': 'Master',
            'color': 'rgba(252, 224, 155, 0.5)',
            'payout': Checked,
            'recon': Checked,
            'gpl': Checked,
            'add_user': Checked,
            'integration': Checked
        },
        {
            'role': 'IT',
            'color': 'rgba(136, 134, 134, 0.5)',
            'payout': Checked,
            'recon': Checked,
            'gpl': Checked,
            'add_user': Checked,
            'integration': Checked
        },
        {
            'role': 'Admin',
            'color': 'rgba(255, 208, 181, 0.5)',
            'payout': Checked,
            'recon': Checked,
            'gpl': Checked,
            'add_user': Crossed,
            'integration': Checked
        },
        {
            'role': 'Operation',
            'color': '#E2F0CB',
            'payout': Checked,
            'recon': Checked,
            'gpl': Checked,
            'add_user': Crossed,
            'integration': Crossed
        },
        {
            'role': 'Finance',
            'color': 'rgba(165, 216, 216, 0.5)',
            'payout': Checked,
            'recon': Checked,
            'gpl': Crossed,
            'add_user': Crossed,
            'integration': Crossed
        },
        {
            'role': 'Support',
            'color': '#C7CEEA',
            'gpl': Crossed,
            'add_user': Crossed,
            'integration': Crossed
        },
    ]
    return(
        <div align="center" style={{marginTop: 32}}>
            <div className={u.scopeCard}>
                <div style={{position: 'absolute'}}>
                    <button className={u.backBtn}>
                        <Icon type="left"/> Back
                    </button>
                </div>
                <div className={u.headers}>User's Role</div>

                <div className={u.scopeTable}>
                    <table>
                        <tr className={u.headerDiv}>
                            {
                                users_headers.map((item) => {
                                    return (
                                        <td className={u.headerDiv}>
                                            {item}
                                        </td>
                                    )
                                })
                            }
                        </tr>
                        {
                            user_roles.map((item, key) => {
                                return (
                                    <tr>
                                        <td className={u.dataDiv}>
                                            <div className={u.capsule} style={{background: item.color, border: `1px solid ${item.color}`}}>
                                            {item.role}
                                            </div>
                                        </td>
                                        <td className={u.dataDiv}>
                                            <img src={item.payout} />
                                        </td>
                                        <td className={u.dataDiv}>
                                            <img src={item.recon} />
                                        </td>
                                        <td className={u.dataDiv}>
                                            <img src={item.gpl} />
                                        </td>
                                        <td className={u.dataDiv}>
                                            <img src={item.add_user} />
                                        </td>
                                        <td className={u.dataDiv}>
                                            <img src={item.integration} />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </table>
                </div>
            </div>
        </div>
    )
}

export default UserScope