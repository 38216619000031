import React, { useState, useEffect } from "react";
import { Card, Typography, Input, Button, notification } from "antd";
import { StatusChip } from "../../../../../Transfer/components";
import { updateSubmission } from "../api/update-submission";

const { Title } = Typography;
const { TextArea } = Input;

const statusMap = {
  AP: "Approved",
  PE: "Pending",
  RE: "Rejected",
  RS: "Resubmitted",
};

export function ApproveSubmission({ merchantId, data, isLoading, isError }) {
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");

  const isApprovalEnabled = ["AP", "RE"].includes(status);

  useEffect(() => {
    if (data) {
      setStatus(data.status);
      setRemarks(data.remarks);
    }
  }, [data]);

  const handleApprove = async (status) => {
    try {
      await updateSubmission({ merchantId, status, remarks });
      notification.success({
        message: "Success",
        description: "The bank submission has been approved.",
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Failed!",
        description: "Please make sure to provide remarks.",
      });
    }
  };

  const handleReject = async (status) => {
    try {
      await updateSubmission({ merchantId, status, remarks });
      notification.success({
        message: "Success",
        description: "The bank submission has been declined.",
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Failed!",
        description: "Please make sure to provide remarks.",
      });
    }
  };

  if (isError) {
    return <div>Error</div>;
  }

  return (
    <Card title="Approval" loading={isLoading} style={{ width: "400px" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          <Title level={4}>Status:</Title>
          <StatusChip status={status} label={statusMap[status] || status} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <Title level={4}>Remarks:</Title>
          <TextArea
            value={remarks}
            rows={4}
            onChange={(e) => setRemarks(e.target.value)}
            disabled={isApprovalEnabled}
          />
        </div>
        {!isApprovalEnabled && (
          <div style={{ display: "flex", gap: "1em", alignSelf: "flex-end" }}>
            <Button
              size="large"
              type="danger"
              onClick={() => handleReject("RE")}
            >
              Reject
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={() => handleApprove("AP")}
            >
              Approve
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
}
