const multiwalletActions = {
  setSuccessLoadModal: (value) => ({
    type: 'SET_VISIBLE_SUCCESS_LOAD_MODAL',
  }),
  setBalances: (bal) => ({
    type: 'SET_BALANCES',
    payload: bal,
  }),
  setAnalytics: (data) => ({
    type: 'SET_ANALYTICS',
    payload: data,
  }),
  showModal: (id) => ({
    type: 'SHOW_MODAL',
    payload: id,
  }),
  hideModal: (id) => ({
    type: 'HIDE_MODAL',
    payload: id,
  }),
  setTransferMoneyData: (data) => ({
    type: 'SET_TRANSFER_MONEY_DATA',
    payload: data,
  }),
  setOtpParams: (params) => ({
    type: 'SET_OTP_PARAMS',
    payload: params
  }),
  setOtpSuccess: (success) => ({
    type: 'SET_OTP_SUCESS',
    payload: success
  }),
  setOtpUID: (value) => ({
    type: 'SET_OTP_UID',
    payload: value
  }),
  setTransferMoneyBoxData: (value) => ({
    type: 'SET_TRANSFER_MONEY_BOX',
    payload: value
  }),
  setTransferBtn: (value) => ({
    type: 'SET_TRANSFER_BTN',
    payload: value
  }),
  setTransferBoxTotalAmount: (value) => ({
    type: 'SET_TRANSFER_BOX_TOTAL_AMOUNT',
    payload: value
  }),
}

export default multiwalletActions;