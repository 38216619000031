import { Table as AntTable, Pagination } from "antd";
import React from "react";

export function Table({
  columns,
  data,
  currentPage,
  setCurrentPage,
  pageSize,
}) {
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <AntTable
        rowClassName="table-row-light"
        columns={columns}
        dataSource={data.results}
        loading={!data}
        pagination={false}
        scroll={{ x: true }}
        style={{ maxWidth: "82dvw" }}
      />
      <Pagination
        size="small"
        total={(data && data.total) || 0}
        defaultPageSize={pageSize}
        pageSize={pageSize}
        current={currentPage}
        onChange={handlePageChange}
        style={{ paddingTop: "12px", textAlign: "right" }}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
      />
    </div>
  );
}
