import React, {useEffect} from 'react';
import {Spin, Button, Card} from 'antd';
import {history} from '../../store/history'
import axios from 'axios'
import BackgroundImage from '../../static/images/signup_and_login/bg.png'
import NewBuxLogo from '../../static/images/signup_and_login/new_bux_logo.svg'
import CheckVerify from '../../static/icons/check_verify.svg'
import CloseVerify from '../../static/icons/close_verify.svg'


const VerifyingEmailForm = props => {


    const [successFormHidden, setSuccessFormHidden] = React.useState(true)
    const [loadingFormHidden, setLoadingFormHidden] = React.useState(false)
    const [errorFormHidden, setErrorFormHidden] = React.useState(true)
    const [errorMessage, setErrorMessage] = React.useState("")
    
    useEffect(() => {
        console.log("verifying")
        verifyAccountNow()
    }, []);

    async function verifyAccountNow () {

        try{
            let response = await axios.get(
                process.env.REACT_APP_API_URL + "/api/email/verify/"+props.match.params.id+"/"+props.match.params.verification_code+"/"
            );
    
            if(response.data.status == "success"){
                setLoadingFormHidden(true)
                setSuccessFormHidden(false)
            }
            else{
                setLoadingFormHidden(true)
                setErrorMessage(response.data.message)
                setErrorFormHidden(false)
            }
        }
        catch(err){
            setLoadingFormHidden(true)
            setErrorMessage("Verification failed on this email")
            setErrorFormHidden(false)
        }

    }
    
    const verifyStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '34px',
        color: '#2B2D33',
        marginBottom: '10px'
    }

    const textStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2B2D33',
        marginTop: '24px'
    }

    const mainDiv = {
        width: '100%',
        height: '100vh',
        background:`url(${BackgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100vh",
        display: 'flex', 
        justifyContent: 'center',
        paddingTop: '40px'
    }
    

    const cardStyle = {
        marginTop: '40px',
        height: '424px',
        width: '500px',
        paddingTop: '50px'
    }

    const btn= {
        height: '40px',
        width: '312px',
        background: '#F5922F',
        borderRadius: '4px',
        color: '#FFF',
        fontWeight: 'bold',
        marginTop: '61px'
    }

    return(
        <div style={mainDiv}>
            <div align="center">
                
                <img src={NewBuxLogo} alt="#"/>
                <Card style={cardStyle}>
                    {
                        !loadingFormHidden ? 
                        <Spin size="large" />
                        :
                        !errorFormHidden ? 
                        <img src={CloseVerify}/>
                        :
                        <img src={CheckVerify}/>
                    }
                    <div style={{marginTop: '20px'}}>
                        <div hidden={loadingFormHidden}>
                            <div style={verifyStyle}>Verifying your account</div>
                            <div style={textStyle}>We are now verifying your account. Please wait ...</div>
                        </div>
                        <div hidden={errorFormHidden}>
                            <div style={verifyStyle}>Verification failed</div>
                            <div style={textStyle}>{errorMessage}</div>
                            <div style={{display:'flex', justifyContent: 'center'}}>
                                <Button style={btn} onClick={ () => window.location.href = '/resend_verification'}>Resend</Button>
                            </div>
                        </div>

                        <div hidden={successFormHidden}>
                            <div style={verifyStyle}>Successful Verification!</div>
                            <div style={textStyle}>Your email address has been verified.</div>
                            <div style={{display:'flex', justifyContent: 'center'}}>
                                <Button style={btn} onClick={ () => window.location.href = '/login'}>Continue</Button>
                            </div>
                        </div>
                    </div>
                    
                </Card>
            </div>
            {/* <div style={{paddingLeft: '57px',paddingRight: '57px', paddingTop: '10vh', justifyContent: 'center', alignItems:'center', width: '559px'}}>

                
            </div> */}
        </div>
    )
    
}

export default VerifyingEmailForm