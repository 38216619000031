import React, {useEffect, useState} from 'react'
import {Input, message, Modal} from 'antd'
import { makeStyles } from '@material-ui/core';

const {TextArea} = Input;

const EmbeddableScriptModal = (props) => {
    const embeddable_script_modal_styles = makeStyles(theme => ({
        headerDiv: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '10px'
        },
        headerText: {
            fontSize: 'x-large',
            paddingRight: '20px',
        },
        codeDiv: {
            backgroundColor: '#ecefff'
        },
        copyButton: {
            cursor: 'pointer',
        }
    })) ();


    const {
        isSnippetModalVisible,
        setIsSnippetModalVisible,
        offerInFocus,
    } = props;

    const blankOffer = {
        'name': '',
        'embeddable_script': ''
    }
    const [offerDetails, setOfferDetails] = useState(blankOffer);

    function copyValueToClipboard(value) {
        var dummy = document.createElement("textarea"); // 'textarea' (instead of 'input') preserves newlines
        document.body.appendChild(dummy);
        dummy.value = value;
        dummy.select();
        document.execCommand("copy");
        dummy.remove();
        message.destroy();
        message.info("Code copied to clipboard");
    }

    useEffect(() => {
        if (! isSnippetModalVisible) {
            return;
        }

        setOfferDetails({...offerInFocus});
    }, [isSnippetModalVisible]);

    return (
        <Modal
            centered
            width={'60vw'}
            visible={isSnippetModalVisible}
            onCancel={() => setIsSnippetModalVisible(false)}
            footer={null}
        >
            <div className={embeddable_script_modal_styles.headerDiv}>
                <span className={embeddable_script_modal_styles.headerText} >
                    {offerDetails.name}
                </span>

                <button 
                    className={embeddable_script_modal_styles.copyButton}
                    onClick={() => copyValueToClipboard(offerDetails.embeddable_script)}
                >
                    Copy code to clipboard
                </button>
            </div>

            <div>
                <TextArea 
                    className={embeddable_script_modal_styles.codeDiv}
                    autoSize={{ minRows: 15, maxRows: 20}}
                    size="small"
                    value={offerDetails.embeddable_script}
                />
            </div>
        </Modal>
    )
};

export default EmbeddableScriptModal;