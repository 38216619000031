import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Checked from '../../../../static/icons/checked.svg'
import Spinner from '../../../../static/icons/spinner.svg'

function UploadProgressBar(props) {
  return (
    <Box align="center">
      {/* <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" color='primary' {...props} />
      </Box> */}
      <Box minWidth={35}>
        <Typography variant="inherit" color="inherit" >
            <img src={
                props.value < 100 ? Spinner : Checked
              } 
              style={{
                width: props.value < 100 ? '4%' : '3%'
              }}
            /> Uploading All Documents {`${Math.round(props.value,)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default UploadProgressBar;