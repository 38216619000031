import React from 'react'
import pricingStyle from '../../static/css/Pricing';
import {Grid} from '@material-ui/core'
import {Icon} from 'antd'
import { Visa, Mastercard } from '../../static/icons/payment_method_icons/index'
import { backToTop } from '../constants/constants'


const MobileCreditDebit = props => {
    const xendit_enabled = localStorage.getItem('cc_enabled') == 'Xendit';
    
    const p = pricingStyle();
    const channels = [
        {
            icon: Mastercard,
            name: 'Mastercard',
            min: '₱ 50.00',
            max: '₱ 1,000,000.00'
        },    
        {
            icon: Visa,
            name: 'Visa',
            min: '₱ 50.00',
            max: '₱ 1,000,000.00'
        },      

    ]

    return(
        <div>
            <div align="center" className={p.subDes}>
            Below is the fees for the Credit and Debit Card Payments. Fees for Credit and Debit Card payments is <span className={`text-${props.segments} wc-header`}>{xendit_enabled ? "3.5% + ₱ 12.00" : "2-3.5% to ₱ 5-12.00"}</span> per transaction.
            </div>
            {
                channels.map((item => {
                    return(
                        <div className={p.cards}>
                            <div className={p.cardHeader} style={{display: 'flex', justifyContent: 'space-between'}}align='center'>
                                <div style={{paddingRight: '16px'}}><img height={32} src={item.icon} /></div>
                                <div style={{marginTop: '3px',}} align='right'>{item.name}</div>
                            </div>
                            
                            <Grid container className={p.cardInfo}>

                                <Grid item xs={7}>
                                    <div className={p.cardPlaceholder}>
                                        Minimum Amount
                                    </div>
                                </Grid>

                                <Grid item xs={5}>
                                    <div className={p.cardVal} align='right'>
                                        {item.min}
                                    </div>
                                </Grid>
                            
                            </Grid>

                            <Grid container className={p.cardInfo}>

                                <Grid item xs={7}>
                                    <div className={p.cardPlaceholder}>
                                        Maximum Amount
                                    </div>
                                </Grid>

                                <Grid item xs={5}>
                                    <div className={p.cardVal2} align='right'>
                                    {item.max}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    )
                }))
            }

            <div className={p.compleShareDiv} align='left'>
                <button className={`outline-btn--${props.segments} btn-height btn-mobile-width`} onClick={backToTop}><Icon type="up" style={{marginRight: '8px' }}/> Back to Top</button>
            </div>


        </div>
    )
}

export default MobileCreditDebit;