import React from 'react';
import { Typography } from 'antd';
import FeedbackCards from './feedback_cards';
import UserFeedbackStats from './feedback_stats';

const UserFeedback = () => {

    return (
        <div style={styles.root}>
            <UserFeedbackStats />
            <FeedbackCards />
        </div>
    )
}

const styles = {
    root: {
        width: '100%',
        paddingRight: '42px'
    },
    title: {
        color: '#2B2D32',
        fontWeight: '700',
        fontSize: '18px'
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    body: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    label: {
        color: 'rgba(43, 45, 50, 0.8)',
        fontSize: '12px',
        fontWeight: '500',
        textAlign: 'center'
    }
}

export default UserFeedback;