import React from 'react';
import cardsStyles from '../../../static/css/Cards';


const MobileBasicCard = props => {

    const cs = cardsStyles();

    const firstName = localStorage.getItem("firstName")


    return(
        <div className={cs.basicCard} align='left'>
           <div className={cs.basicHeader}>Hello, {firstName}</div>
           <div className={cs.basicText}>Here’s what’s happening in your business today.</div>
        </div>
    )
}

export default MobileBasicCard;