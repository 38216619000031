const fraudActions = {
  setBlcklstSelectedBtn: (btn) => ({
    type: 'SET__EMAIL_BLCKLST_SELECTED_BTN',
    payload: btn,
  }),
  setCheckModalData: (data) => ({
    type: 'SET_CHECK_DOMAIN_MODAL_DATA',
    payload: data,
  }),
  setDomainCSV: (data) => ({
    type: 'SET_DOMAIN_CSV',
    payload: data,
  }),
  setTablePage: (data) => ({
    type: 'SET_TABLE_PAGE',
    payload: data,
  }),
  setTableData: (data) => ({
    type: 'SET_TABLE_DATA',
    payload: data,
  }),
  setFraudSelectedBtn: (btn) => ({
    type: 'SET_FRAUD_SELECTED_BTN',
    payload: btn,
  }),
  setBlcklstUsersSelectedBtn: (btn) => ({
    type: 'SET_BLCKLST_USERS_SELECTED_BTN',
    payload: btn,
  }),
}

export default fraudActions;