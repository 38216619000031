import axios from 'axios';
import {Handle401} from '../handle401/handle401';

const authorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const reconServices = {
  getEnterpriseRecon: async (type, start, end) => {
    try {

      const res = await authorizedAxiosRequest().get(`/api/cc_recon/?accounting_status=${type}&filter_type=transaction_date&start=${start}&end=${end}`)
      return res;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
}


export default reconServices;