import React, {useEffect} from 'react'
import Main from '../../layouts/Main'
import {isMobile} from 'react-device-detect'
import MobileMain from '../../layouts/Mobile/MobileMain'
import MainKyb from '../../components/kyb/forms/mainKyb'

const MainEnterprise = props => {

    return(
        <div style={{padding: 0, margin: 0}}>
            {isMobile ?
            
            <MobileMain title="KYB Application">
                <MainKyb />
            </MobileMain>

            :

            <Main>
                <MainKyb />
            </Main>
        }
        </div>
    )
}

export default MainEnterprise