import React from 'react'
import axios from 'axios'
import {Card, Select, Input, Icon, Switch} from 'antd'
import enterpriseStyle from '../kybStyle'
import { isMobile } from 'react-device-detect'
import {NATURE_BUSINESS, NATIONALITY, POSITION} from '../../../constants/constants'
import Passed from '../../../../static/img/passed.svg'
import Failed from '../../../../static/img/failed.svg'


const ReviewContactInfo = props => {
    let datas = props.datas
    let segments = props.segments

    const kyb = enterpriseStyle()
    const[SPOC, setSPOC] = React.useState({})
    const[supportContact, setSupportContact] = React.useState({})
    const[sales, setSales] = React.useState({})
    const[it, setIT] = React.useState({})
    const[finance, setFinance] = React.useState({})
    const [toggleOn, SetToggleOn] = React.useState(true)
    let kybStep = localStorage.getItem("KYBStep")
    const userRole = localStorage.getItem("userType")
    const adminRole = ['AD', 'KB', 'SD', 'OP', 'BS', 'AP', 'EN', 'FI',]
    const editRole = ['AD', 'CO', 'SW', 'SD', 'BS']
    const createRole = ['AD', 'SD']
    const hiddenSteps = [8, 9, 11]
    let kybLevel = localStorage.getItem("Level")

    let contactInfo = [
        {
            'title': 'SPOC - Main Contact Person',
            'first_name': SPOC.first_name,
            'name': 'Name',
            'value': SPOC.middle_name ? (SPOC.first_name+" "+SPOC.middle_name+" "+SPOC.last_name) : (SPOC.first_name+" "+SPOC.last_name),
            'position': POSITION[SPOC.position],
            'contact': SPOC.mobile,
            'email': SPOC.email
        },
        {
            'title': 'Support',
            'first_name': supportContact.first_name,
            'name': 'Name',
            'value': supportContact.middle_name ? (supportContact.first_name+" "+supportContact.middle_name+" "+supportContact.last_name) : (supportContact.first_name+" "+supportContact.last_name),
            'position': 'Support',
            'contact': supportContact.mobile,
            'email': supportContact.email
        },
        {
            'title': 'Sales Department',
            'first_name': sales.first_name,
            'name': 'Name',
            'value': sales.middle_name ? (sales.first_name+" "+sales.middle_name+" "+sales.last_name) : (sales.first_name+" "+sales.last_name),
            'position': 'Sales',
            'contact': sales.mobile,
            'email': sales.email
        },
        {
            'title': 'IT Department',
            'first_name': it.first_name,
            'name': 'Name',
            'value': it.middle_name ? (it.first_name+" "+it.middle_name+" "+it.last_name) : (it.first_name+" "+it.last_name),
            'position': 'I.T',
            'contact': it.mobile,
            'email': it.email
        },
        {
            'title': 'Finance Department',
            'first_name': finance.first_name,
            'name': 'Name',
            'value': finance.middle_name ? (finance.first_name+" "+finance.middle_name+" "+finance.last_name) : (finance.first_name+" "+finance.last_name),
            'position': 'Finance',
            'contact': finance.mobile,
            'email': finance.email
        },

    ]

    React.useEffect(() => {
        // fetchData()
        if(datas) {
            submitFailed(!datas.step_2_has_error)
            for(let i=0;i<datas.contact_persons.length;i++) {
                if(datas.contact_persons[i].department === "MA"){
                    setSPOC(datas.contact_persons[i])
                }
                else if(datas.contact_persons[i].department === "SU"){
                    setSupportContact(datas.contact_persons[i]) 
                }
    
                else if(datas.contact_persons[i].department === "SA"){
                    setSales(datas.contact_persons[i]) 
                }
    
                else if(datas.contact_persons[i].department === "IT"){
                    setIT(datas.contact_persons[i]) 
                }
    
                else if(datas.contact_persons[i].department === "FI"){
                    setFinance(datas.contact_persons[i]) 
                }                    
            }
        }

    },[])


    function editBusinessInfo(val){
        props.openEdit(val)
    }
    //Call Back from mainReiew_admin.js
    function submitFailed(val){
        SetToggleOn(val)
        if(adminRole.includes(userRole) && localStorage.getItem("KYBType") != "creation"){
            props.setFailedStep(val, 2)
        }
    }


    return(
        <Card 
            className={`${kyb.formCardStyle} padding-16`}
            bodyStyle={{padding: '20px 16px 20px 16px'}}
            style={{
                
                border: toggleOn && 
                        adminRole.includes(userRole) ?
                        '2px solid #1DD28B' : 
                        !toggleOn && 
                        adminRole.includes(userRole) ? 
                        '2px solid #E24C4C' : ''
            }}>

            {
                adminRole.includes(userRole) ?
                <div style={{position: 'relative', paddingBottom: 16, 
                            borderBottom: '1px solid #D1D5DD', marginBottom: 16}}>    
                     <div style={{position: 'absolute', top: '-20px', left: '-17px'}}>
                        <img src={toggleOn ? Passed : Failed} />    
                     </div>
                     <div align='right'>
                        <span className={kyb.miniText} style={{opacity: props.curStep >= 11 ? 0.5 : ''}}>
                            Mark as Passed: <Switch size="small" disabled={props.curStep >= 11} checked={toggleOn} onChange={() => submitFailed(!toggleOn)}/>
                        </span>
                     </div>
                </div>

                :
                ''
            }

            <div className="bottom-32 twoCol" ref={props.cardDivRef}>
                <div className={`${kyb.greatText} liveColor`}>Contact Information</div>

                <div hidden={(!editRole.includes(userRole)) || (['CO','SW'].includes(localStorage.getItem('userType')) &&  hiddenSteps.includes(parseInt(kybStep)))}>
                    <button className={`outline-btn--${segments}`} onClick={() => editBusinessInfo(2)}>
                        <Icon type="edit" className={`text-${segments} right-4`}/>Edit
                    </button>
                </div>
            </div>

            <div className="kyb-margin-style">            
                {
                    contactInfo.map((item, key) => {
                        return(
                            <div key={key} className="bottom-32" hidden={typeof(item.first_name) === "undefined" || !item.first_name}>
                                <div className={kyb.headerStrong}>
                                    {item.title}
                                </div>
                                
                                <div className={`${kyb.tableStyle} liveColor`}>
                                    <div className="twoCol top-20">
                                        <div>
                                            {item.name}:
                                        </div>
                                        <div className={kyb.valueText}>
                                            {item.value}
                                        </div>
                                    </div>
                                    <div className="twoCol top-20">
                                        <div>
                                            Position:
                                        </div>
                                        <div className={kyb.valueText}>
                                            {item.position}
                                        </div>
                                    </div>
                                    <div className="twoCol top-20">
                                        <div>
                                            Contact:
                                        </div>
                                        <div className={kyb.valueText}>
                                            {item.contact}
                                        </div>
                                    </div>
                                    <div className="twoCol top-20">
                                        <div>
                                            Email:
                                        </div>
                                        <div className={kyb.valueText}>
                                            {item.email}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    })
                }
            </div>
        </Card>
    )
}

export default ReviewContactInfo