import React, {useEffect} from 'react'
import LoginAndSignUp from '../main'
import PasswordInput from '../sign_up/password_input/password_input';
import { Button, Spin, Alert } from 'antd'
import { history } from '../../../store/history';
import { isMobile } from 'react-device-detect';
import axios from 'axios'

const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;

const ResetPassword = props => {

    //Forms
    const [successFormHidden, setSuccessFormHidden] = React.useState(true)
    const [errorFormHidden, setErrorFormHidden] = React.useState(true)
    const [loadingFormHidden, setLoadingFormHidden] = React.useState(false)

    //States
    const [newPassword, setNewPassword] = React.useState("");
    const [isPasswordValid, setIsPasswordValid] = React.useState(false);
    const [message, setMessage] = React.useState(false);
    const [alert, showAlert] = React.useState(false);

    let query_params = new URLSearchParams(window.location.search);
    let isTestMode = query_params.get('sandbox') || '';

    useEffect(() => {
        verifyResetCode()
    }, []);

    async function verifyResetCode () {
        let response = await axios.get(
            process.env.REACT_APP_API_URL + "/api/password_reset/"+props.match.params.id+"/"+props.match.params.code+"/"
        );

        if(response.data.status == "success"){
            setLoadingFormHidden(true)
            setSuccessFormHidden(false)
        }
        else{
            setLoadingFormHidden(true)
            setErrorFormHidden(false)
        }
    }

    async function changePasswordUser(){
        
        let params = {
            password: newPassword
        }

        let response = await axios.post(
            process.env.REACT_APP_API_URL + "/api/password_reset/"+props.match.params.id+"/"+props.match.params.code+"/", params
        );

        if(response.data.status === "success"){
            if(isTestMode === "True") {
                history.push('/test/login')
            }
            else {
                history.push('/login')
            }
        }
        else{
            showAlert(true)
            setMessage(response && response.data.message)
        }
  
      }

    return(
        <LoginAndSignUp>
            <div style={{...style.mainDiv, width: isMobile ? '100%' : isHighReso ? '529px' : '430px', overflowY: 'auto'}}>
                <div style={{padding: '10px 20px 10px 20px', display: 'flex', justifyContent: 'center'}}>
                    <div hidden={loadingFormHidden}>
                        <div style={style.verifyStyle}>Verifying</div>
                        <div style={style.textStyle}>We are now verifying your reset code. Please wait ...</div>
                        <div style={{display:'flex', justifyContent: 'center'}}>
                            <Spin size="large" />
                        </div>
                    </div>
                    <div hidden={errorFormHidden}>
                        <div style={style.verifyStyle}>Failed to verify link</div>
                        <div style={style.textStyle}>Please request a new reset password link.</div>
                        <div style={{display:'flex', justifyContent: 'center'}}>
                            <Button style={style.btnStyle} size="large" block onClick={ () => history.push('/forgot_password')}>Request new</Button>
                        </div>
                    </div> 
                    <div hidden={successFormHidden}>
                        <div style={style.verifyStyle}>Enter your new password</div>
                        <Alert message={message} type="error" style={{ display: alert ? "block" : "none", borderLeft: '4px solid red'}}/>

                        <PasswordInput password={newPassword}
                            isPasswordValid={isPasswordValid}
                            setIsPasswordValid={setIsPasswordValid} 
                            setPassword={setNewPassword} 
                            inputWidth={isMobile ? '312px' : '370px'} 
                            meterWidth={isMobile ? '312px' : '370px'}/>

                        <div style={{ marginTop: '20px'}}>
                            <Button disabled={!isPasswordValid} style={style.btnStyle} size="large" onClick={()=>changePasswordUser()}>Change password</Button>
                        </div>
                    </div> 
                </div>
            </div>
        </LoginAndSignUp>
        
    )
}

const style = {
    mainDiv : {
        borderRadius:  !isMobile && '8px',
        backgroundColor: '#fff',
        boxShadow: '0px 8px 24px rgba(0, 35, 11, 0.12)',
        paddingTop: '50px',
        paddingBottom: '50px'
    },
    verifyStyle: {
        textAlign: 'center',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '34px',
        color: '#2B2D33',
        marginBottom: '30px'
    },
    textStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.01em',
        color: '#2B2D33',
        opacity: '0.8',
        marginBottom: '20px'
    },
    btnStyle: {
        width:  isMobile ? '312px' : '370px',
        height: '40px',
        borderRadius:'4px',
        border: '1px solid #F5922F',
        backgroundColor: '#F5922F',
        fontSize: '16px',
        color: '#fff',
        fontWeight: 'bold'
    }
}
export default ResetPassword