import React, {useEffect} from 'react';
import { Table, Input, Icon } from 'antd';
import {history} from '../../store/history'
import {useDispatch, useSelector} from 'react-redux'
import {fetchShipments} from './shipmentsAction'
import MetaTag from '../meta_tag/meta_tag'
import {isMobile} from 'react-device-detect'
const Shipments = props => {

    const dispatch = useDispatch();
    const shipments = useSelector(state => state.shipments.shipments)

    useEffect(()=>{
          let current_path = window.location.pathname
          if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            window.location.href = '/login?next='+current_path
          }
          else{
            if(localStorage.getItem("userType") == "AD" || localStorage.getItem("userType") == "OP"){
              history.push('/orders')
          }
          else if(localStorage.getItem("userType") == "PS"){
              history.push('/orders')
          }
          else if(localStorage.getItem("userType") == "PF"){
            history.push('/recon/7_eleven')
          }
          else if(localStorage.getItem("userType") == "MA"){
          history.push('/overview')
          }
          else{
              history.push('/404')
          }
          
      }
    },[])
    const orderStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '40px'
      }
    
    const columns = [
        {
            title: 'Customer',
            render: (record) => (
              <div>
              {record.buyer_first_name} {record.buyer_last_name}
              </div>
            )
        },
        {
          title: 'Ref. Code',
          dataIndex: 'code',
        },
        {
          title: 'Courier',
          render: (record) => (
            <div>
              Quad X
            </div>
          )
        },
        {
          title: 'Date last updated',
          dataIndex: 'date_last_updated',
        },
        {
          title: 'Tracking Code',
          render: (record) => (
            <div>
              Pending
            </div>
          )
        },
        {
          title: 'Status',
          dataIndex: 'status'
        },
      ];
    const data = shipments

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        }
      };
    
      return(
        <div style={{ paddingTop: '38px', paddingLeft: '42px', paddingRight: '42px' }}>
            <MetaTag title="Shipments"/>
          
            <div style={orderStyle}>Shipment Tracking</div>
            <div style={{ marginBottom: '25px', float: 'right' }}>
                <Input
                    placeholder="Search"
                    style={{ width: '200px', height: '35px' }}
                    prefix={<Icon type="search" />}
                />
            </div>
            <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data} />
        </div>
     )
}

export default Shipments