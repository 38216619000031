import React from 'react'
import {Modal, Icon, Typography} from 'antd'

const EnterpriseSurveyModal = props => {

    const {answers, visible, closeModal, details} = props;
    return(
        <Modal 
            footer={null}
            visible={visible}
            onCancel={closeModal}
            bodyStyle={styles.root}
            closeIcon={<Icon type="close" style={{ color: '#F5922F' }} />}
        >

            <Typography style={styles.title}>Enterprise Survey Answers</Typography>
            <Typography><b>Business Name:</b> {details && details.business_name}</Typography>
            {
                answers && answers.map((item, i) => {
                    return(
                        <div>
                            <br />
                            <label style={styles.label}>{i+1}. {item.question}</label>
                            <div style={styles.answer}>- {item.answer}</div>
                        </div>
                    )
                })
            }
        </Modal>
    )
}

const styles = {
    root: {
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'left',
    },
    title: {
      fontSize: 20,
      color: '#2B2D33',
      padding: '32px 0 18px 0',
    },
    label: {
        fontSize: 14,
        color: '#212B36',
    },
    answer: {
        fontWeight: 'bold',
        fontSize: 14,
        color: '#212B36',
    }
}

export default EnterpriseSurveyModal

