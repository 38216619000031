const initialState = {
  emailBlcklstSelected: 'Email Domain',
  fraudBtnSelected: 'Email',
  usersBlcklstSelected: 'Individual Account',
  checkModalData: [],
  fraudCSV: null,
  tablePage: 1,
  tableData: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET__EMAIL_BLCKLST_SELECTED_BTN':
      return {
      ...state,
      emailBlcklstSelected: action.payload,
      }
      case 'SET_CHECK_DOMAIN_MODAL_DATA':
        return {
        ...state,
        checkModalData: action.payload,
      }
      case 'SET_DOMAIN_CSV':
        return {
        ...state,
        fraudCSV: action.payload,
      }
      case 'SET_TABLE_PAGE':
        return {
        ...state,
        tablePage: action.payload,
      }
      case 'SET_TABLE_DATA':
        return {
        ...state,
        tableData: action.payload,
      }
      case 'SET_FRAUD_SELECTED_BTN':
        return {
        ...state,
        fraudBtnSelected: action.payload,
      }
      case 'SET_BLCKLST_USERS_SELECTED_BTN':
        return {
        ...state,
        usersBlcklstSelected: action.payload,
      }
    default:
    return state;
  }
}