import React, {useEffect} from 'react'
import * as Style from './upgrade_to_business_style'
import {Card, Icon, Button, Upload, Modal, Input,Checkbox,Divider, message} from 'antd'
import { codeStyle } from '../orders/orderStyle'
import axios from 'axios'
import {history} from '../../store/history'
import {isMobile} from 'react-device-detect'
import Submitted from '../../static/icons/submitted.svg'

const UpgradeToBusiness = props => {

   useEffect(()=>{


        let current_path = window.location.pathname
        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            history.push('/login?next='+current_path)
        }
        else{
            if(isMobile) {
                history.push('/mobile/business_upgrade')
            }
            else if(localStorage.getItem("userType") != "ME"){
                history.push('/dashboard')
            }
            else if(localStorage.getItem("Step") == "12" || localStorage.getItem("Step") == "13"){
                history.push('/upgrade')
            }
            else{
                fetchDetails()
            }   
            
        }
            

   },[])
    const [step, setStep] = React.useState("")
    const [presentAddress, setPresentAddress] = React.useState("")
    const [permanentAddress, setPermanentAddress] = React.useState("")

    const [file, setFile] = React.useState({})
    const [fileType, setFileType] = React.useState("")
    const [fileName, setFileName] = React.useState("")
    const [fileUrl, setFileURL] = React.useState("")

    const [uploadedFileDiv, setUploadedFileDiv] = React.useState(false)
    const [uploadedImageDiv, setUploadedImageDiv] = React.useState(false)
    const [uploadedFileBtn, setUploadedFileBtn] = React.useState(true)

    const [editAddressModal, setEditAddressModal] = React.useState(false)
    const [submittedModal, setSubmittedModal] = React.useState(false)
    const [loadingSubmitButton, setLoadingSubmitButton] = React.useState(false)
    const [viewPhoto, setViewPhoto] = React.useState(false)

    const [sameAddresses, setSameAddresses] = React.useState(false)
    const [permanentAddressInput, showPermanentAddress] = React.useState(true)

    const [fileTypeError, showFileTypeError] = React.useState(false)

    let fileUpload = React.createRef();

    const handleUploadFile = (info) =>{
        console.log(info.type)
        const isJpgOrPng = info.type === 'image/jpeg' || info.type === 'image/png' || info.type === 'application/pdf';
        if (!isJpgOrPng) {
          showFileTypeError(true)
        }
        else{
            showFileTypeError(false)
            setFile(info)
            setFileType(info.type)
            setFileName(info.name)
            
    
            if(info.type.includes('image')){
    
                var reader = new FileReader();
                    reader.readAsArrayBuffer(info);
                    
                    reader.onload = function (event) {
                    // blob stuff
                    var blob = new Blob([event.target.result]); // create blob...
                    window.URL = window.URL || window.webkitURL;
                    setFileURL(window.URL.createObjectURL(blob)) // and get it's URL
                    
                };
    
                setUploadedImageDiv(true)
                setUploadedFileDiv(false)
                setUploadedFileBtn(false)
                
            }
            else{
                setUploadedFileDiv(true)
                setUploadedImageDiv(false)
                setUploadedFileBtn(false)
            }
        }

        

        
    }

    function samePermaAddress(value){
        if(value.target.checked){
            showPermanentAddress(false)
            setSameAddresses(true)
            setPermanentAddress(presentAddress)
        }
        else{
            showPermanentAddress(true)
        }
    }

    async function fetchDetails(){
    
        const yourConfig = {
            headers: {
                
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.get(process.env.REACT_APP_API_URL + '/api/kyc/', yourConfig)

        const { present_address, permanent_address, step, pob_image_url } = response.data;

        if(step < 10) {
            window.location.href = '/complete_profile'
        }

        if(pob_image_url != ""){
            if(pob_image_url.includes('.png')||pob_image_url.includes('.jpg')){
                setFileURL(pob_image_url)
                setUploadedImageDiv(true)
                setUploadedFileDiv(false)
                setUploadedFileBtn(false)
            }
            else{
                setFileName(pob_image_url.substring(pob_image_url.lastIndexOf('/')+1))
                setUploadedFileDiv(true)
                setUploadedImageDiv(false)
                setUploadedFileBtn(false)
            }
        }
        
        setStep(step)
        setPresentAddress(present_address)
        setPermanentAddress(permanent_address)


    }

    async function submitDetails(type){
        let formData = new FormData()        
        if(type == 0)
        {
            formData.append('step', 10)
            formData.append('present_address', presentAddress)
            formData.append('permanent_address', permanentAddress)
        }

        else{
            setLoadingSubmitButton(true)

            if(file != {}){
                formData.append('pob_image', file)
                
            }
            formData.append('step', 11)
            
            
            
        }

        const yourConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.post(process.env.REACT_APP_API_URL + '/api/kyc/', formData, yourConfig)

        console.log(response.data)
        if(response.data.status == 'success'){
            setEditAddressModal(false)
            setStep(response.data.step)
            localStorage.setItem('Step', response.data.step)

            if(response.data.step == '12' || response.data.step == '13'){
                setSubmittedModal(true)
                setLoadingSubmitButton(false) 
            }
        }
        else{
            setLoadingSubmitButton(false) 
        }


    }

    return(
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{padding: '40px 0px 40px 0px'}}>

                <div style={{
                    display: step == '14' ? 'block' : 'none',
                    width: '524px', 
                    border: '1px solid #F8B966', 
                    borderRadius: '4px', 
                    backgroundColor: 'rgba(248, 185, 102, 0.1)', 
                    padding: '20px', 
                    marginBottom: '20px'
                }}>
                    <div style={Style.textStyle1}>
                        Upgrade Application was declined
                    </div>
                    <div>
                        Kindly check your uploaded file or image and resubmit it. Please make sure the document meets our requirements.
                    </div>
                </div>

                <div style={Style.titleStyle}>Upgrade to Business</div>

                <Card title={<div style={Style.headerStyle}>Proof of Billings</div>} style={{ width: '524px', marginTop: '20px' }}>
                    <div style={Style.headerStyle2}>Can be any of the following:</div>
                    <ul style={{marginLeft: '-20px'}}>
                        <li style={Style.textStyle1}>Bank/Credit Card Statement</li>
                        <li style={Style.textStyle1}>Phone/Telephone Bill</li>
                        <li style={Style.textStyle1}>Utility Bills (Electric, Water, Internet/Cable etc.)</li>
                        <li style={Style.textStyle1}>Insurance Statement</li>
                    </ul>
                    <div style={{display: 'flex', marginTop: '20px'}}>
                        <Icon type="check" style={{marginRight: '10px', color: '#1DD28B', fontSize: '17px', height: '10px'}}/>
                        <div style={Style.textStyle2}>Must be clear and readable</div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <Icon type="check" style={{marginRight: '10px', color: '#1DD28B', fontSize: '17px'}}/>
                        <div style={Style.textStyle2}>Dated within the last <strong>2 months</strong></div>
                    </div>
                    <div style={{display: 'flex', marginBottom: '20px'}}>
                        <Icon type="check" style={{marginRight: '10px', color: '#1DD28B', fontSize: '17px'}}/>
                        <div style={Style.textStyle2}>The address of the document you are about to upload should match with your existing information.</div>
                    </div>

                    <div style={{backgroundColor: '#FAFAFA', border: '1px solid #e6eaf0', padding: '10px', textAlign: 'center', marginBottom: '20px'}}>
                        <div style={Style.textStyle2}>{presentAddress}</div>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={Style.textStyle1}>Does it match the address in your document?</div>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <a style={Style.editAddressStyle} onClick={()=>setEditAddressModal(true)}>Edit Address </a>
                    </div>


                </Card>
                <Card style={{ width: '524px'}}>
                    <div style={Style.textStyle2}>Upload Proof of Billings</div>
                    <input type="file"
                        ref={fileUpload}
                        style={{opacity: '0'}}
                        onChange={(e)=>handleUploadFile(e.target.files[0])}
                        accept=".jpg,.png,.jpeg,.pdf"
                       >
                    </input>


                    <div style={{height: '42px', width: '100%', marginBottom: '20px', backgroundColor: 'rgba(226, 76, 76, 0.1)', borderRadius: '4px', borderLeft: '4px solid #E24C4C', display: fileTypeError ? 'block' : 'none'}}>
                        <span style={{lineHeight: '42px', marginLeft: '50px'}}>File type not allowed.</span>
                    </div>

                    <div style={{width: '100%', border: '1px solid #e6eaf0', padding: '10px 10px 10px 10px', height: '48px', display: uploadedFileDiv ? 'flex': 'none'}}>
                        <div style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '70%'}}><Icon type="file" style={{color: '#F5922F', marginRight: '10px'}}/><span style={Style.textStyle3}>{fileName}</span></div>
                        <a style={Style.replaceStyle} onClick={()=>fileUpload.current.click()}>Replace File</a>
                    </div>

                    <div style={{width: '100%', border: '1px solid #e6eaf0', padding: '10px 10px 10px 10px', display: uploadedImageDiv ? 'flex': 'none'}}>
                        
                        <img src={fileUrl} style={{width: '100px', height: '100px', border: '1px solid #E6EAF0', boxSizing: 'border-box'}}/>

                        <div style={{display: 'flex', marginLeft:'40px', marginTop: '40px'}}>

                            <a style={{
                                fontWeight: 'bold',
                                fontSize: '16px',
                                color: '#1D82B8',
                            }} onClick={()=>setViewPhoto(true)}>View Photo</a>
                            <Divider type="vertical" style={{height: '30px'}}/>
                            <a style={{
                                fontWeight: 'bold',
                                fontSize: '16px',
                                color: '#1D82B8',
                            }} onClick={()=>fileUpload.current.click()}>Replace Photo</a>
                        </div>
                    </div>

                    <div style={{display: uploadedFileBtn ? 'block' : 'none'}}>
                        <Button style={Style.uploadBtnStyle} onClick={()=>fileUpload.current.click()}><Icon type="upload"/>Upload Photo or PDF</Button>   
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px'}}>
                        <div style={Style.infoStyle}>Photo or PDF with less than <strong>10 MB</strong> size are accepted. </div>
                    </div>

                    <Button style={Style.submitBtn} onClick={()=>submitDetails(1)} loading={loadingSubmitButton}>Submit Proof of Billings</Button>
                </Card>
            </div>


            <Modal
                visible={viewPhoto}
                onCancel={()=>setViewPhoto(false)}
                footer={null}>
                
                <img src={fileUrl} style={{width: '100%', height: '100%', marginTop: '20px'}}/>
            </Modal>
            <Modal
                visible={submittedModal}
                onCancel={()=>setSubmittedModal(false)}
                footer={null}
                >
                <div style={{marginTop: '50px'}} align='center'>
                    <img src={Submitted} />
                    <div style={Style.titleStyle}>Successfully Submitted</div>
                    <div style={Style.textStyle5}>We’ve got your Proof of Billing, and currently reviewing it. Kindly give us a maximum of 48 hours to evaluate your application to upgrade your account.</div>
                    <div style={Style.textStyle5}>Haven’t received your status? Please contact us at <a href="mailto:support@bux.ph" style={{color: '#1d82b3'}}>support@bux.ph</a></div>
                    
                    <Button style={ Style.submitBtn } onClick={()=> history.push('/upgrade') }>Done</Button>

                </div>
            </Modal>    
            <Modal
                visible={editAddressModal}
                onCancel={()=>setEditAddressModal(false)}
                footer={null}>
                
                <div style={Style.titleStyle}>Edit Address</div>

                <div style={{padding: '20px 30px 15px 30px'}}>
                    
                    {/* <div
                        style={{
                            backgroundColor: 'rgba(226, 76, 76, 0.1)',
                            borderLeft: '4px solid #E24C4C',
                            width: '100%',
                            height: '42px',
                            borderRadius: '4px',
                            marginBottom: '20px',
                            textAlign: 'center',
                            paddingTop: '10px',
                            display: step1ErrorAlert ? "block" : "none"
                        }}>
                        <span style={Style.textStyle}>{step1ErrorMessage}</span>
                    </div> */}
                    <div style={{width: '100%', marginTop: '20px'}}>
                        <div style={Style.inputTextStyle}>Present Address</div>
                        <Input style={{height: '40px', width: '100%'}} value={presentAddress} onChange={(e)=>{setPresentAddress(e.target.value); if(sameAddresses){setPermanentAddress(e.target.value)}}}/>
                    </div>
                    <div style={{marginTop: '20px'}}>
                        <div style={Style.textStyle4}>Permanent Address</div>
                        <div style={{display: 'flex'}}>
                            <Checkbox style={{marginRight: '10px'}} onChange={samePermaAddress}/>
                            <div style={Style.textStyle2}>
                                Same as Present Address
                            </div>
                        </div>
                    </div>
                    <div style={{width: '100%', display: permanentAddressInput ? 'block' : 'none', marginBottom: '20px'}}>
                        <div style={Style.inputTextStyle}>Permanent Address</div>
                        <Input style={{height: '40px', width: '100%'}} value={permanentAddress} onChange={(e)=>setPermanentAddress(e.target.value)}/>
                    </div>
                    
                    <div style={{display: 'flex'}}>
                        <div style={{width: '49%', marginRight: '2%'}}>
                            <Button style={Style.uploadBtnStyle} onClick={()=>setEditAddressModal(false)}>Cancel</Button>
                        </div>
                        <div style={{width:'49%'}}>
                            <Button style={Style.submitBtn} onClick={()=>submitDetails(0)}>Save</Button>
                        </div>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default UpgradeToBusiness