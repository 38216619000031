import React from 'react'
import {Modal} from 'antd'
import feeStyle from './style'
import {isMobile} from 'react-device-detect'


const ActionConfirmationModal = (props) => {
    const {
        isConfirmModalVisible,
        setIsConfirmModalVisible,
        confirmModalCallback,
        confirmModalHeaderText,
        confirmModalBodyText,
    } = props;
    const fee = feeStyle()

    return(
        <Modal
            centered
            width={360}
            visible={isConfirmModalVisible}
            onCancel={() => setIsConfirmModalVisible(false)}
            footer={null}
        >
        <div style={confirmation_modal_styles.headerText}>
            {confirmModalHeaderText}
        </div>

        <div style={confirmation_modal_styles.bodyText}>
            {confirmModalBodyText}
        </div>

        <div style={confirmation_modal_styles.buttonsDiv}>
            <button
                className={fee.cancelBtn}
                onClick={() => setIsConfirmModalVisible(false)}
                style={{
                    width: isMobile ? '120px' : '150px',
                }}
            >
                Cancel
            </button>
            <button
                className={fee.editBtn}
                onClick={confirmModalCallback}
                style={{
                    width: isMobile ? '120px' : '150px',
                }}
            >
                Confirm
            </button>
        </div>
    </Modal>
    )
}

const confirmation_modal_styles = {
    buttonsDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    headerText: {
        fontSize: 'x-large',
        paddingRight: '20px'
    },
    bodyText: {
        padding: '15px 0px 20px 1px'
    }
}

export default ActionConfirmationModal;