import React from 'react';
import * as Icon from '../../static/icons/how_it_works/index';
import * as Img from '../../static/images/how_it_works/index';
import ContentCard from './content_card';
import PayoutStep from './reusable_content/payout';

const ContentStep4 = props => {
    
    const {
        segments
    } = props;

    const userType = localStorage.getItem('userType')

    const isIndividual = userType === 'ME'

    let emoney_icon = segments == 'me_' ? Icon.Emoney_me_ : 
                            segments == 'gig' ? Icon.Emoney_gig : 
                                segments == 'sme' ? Icon.Emoney_sme : 
                                    segments == 'biz' ? Icon.Emoney_biz: ""



    return (
        <div>
            {
                isIndividual ?
                    <ContentCard step={4}
                        icon={emoney_icon}
                        image={Img.Emoney}
                        title={"BUx E-money"}
                        content={"This tab will display your EON account."} />
                    :
                    <PayoutStep segments={segments} step={4} />

            }
            
        </div>
    )
}

export default ContentStep4;