import React from 'react';
import CustomerForm from './customerComponent/customerForm'
import {history} from '../../store/history'

class Customers extends React.Component{ 
  
    componentWillMount(){
      let current_path = window.location.pathname

      if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
          history.push('/login?next='+current_path)
      }  
    }
    render(){
        return(
          <CustomerForm/>
        )
    }
}

export default Customers