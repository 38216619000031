import React from 'react'
import {Card} from 'antd'

import MeLogo from '../../static/images/home/me.svg'
import GigLogo from '../../static/images/home/gig.svg'
import SmeLogo from '../../static/images/home/sme.svg'
import BizLogo from '../../static/images/home/biz.svg'

import Check from '../../static/images/home/check.svg'
import Ex from '../../static/images/home/eks.svg'


const AccountLevelsWeb = () => {

    const segments = localStorage.getItem('segments')

    const acccountLimits = [
        {
            limit: 'Daily Cash-in',
            me: '₱ 100,000.00',
            gig: '₱ 500,000.00',
            sme: 'No limits',
            biz: 'No limits'
        },
        {
            limit: 'Daily Cash-out',
            me: '₱ 100,000.00',
            gig: '₱ 500,000.00',
            sme: 'No limits',
            biz: 'No limits'
        },
        {
            limit: 'Monthly Cash-in',
            me: '₱ 100,000.00',
            gig: '₱ 500,000.00',
            sme: 'No limits',
            biz: 'No limits'
        },
        {
            limit: 'Monthly Cash-out',
            me: '₱ 100,000.00',
            gig: '₱ 500,000.00',
            sme: 'No limits',
            biz: 'No limits'
        },
        {
            limit: 'Annual Cash-in',
            me: '₱ 1,200,000.00',
            gig: '₱ 6,000,000.00',
            sme: 'No limits',
            biz: 'No limits'
        },
        {
            limit: 'Annual Cash-in',
            me: '₱ 1,200,000.00',
            gig: '₱ 6,000,000.00',
            sme: 'No limits',
            biz: 'No limits'
        },
    ]


    const features = [
        {
            limit: 'Payment Links (Invoicing)',
            me: true,
            gig: true,
            sme: true,
            biz: true,
        },
        {
            limit: 'Batch Payment Links (Invoicing)',
            me: false,
            gig: false,
            sme: true,
            biz: true,
        },
        // {
        //     limit: 'Custom Invoicing',
        //     me: false,
        //     gig: false,
        //     sme: true,
        //     biz: true,
        // },
        {
            limit: 'My Checkout / QR Payments',
            me: true,
            gig: true,
            sme: true,
            biz: true,
        },
        {
            limit: 'Plugins',
            me: true,
            gig: true,
            sme: true,
            biz: true,
        },
        {
            limit: 'API',
            me: false,
            gig: false,
            sme: true,
            biz: true,
        },
        {
            limit: 'Sentro',
            me: true,
            gig: true,
            sme: true,
            biz: true,
        },
        {
            limit: 'Multi-wallet',
            me: false,
            gig: false,
            sme: false,
            biz: true,
        },
    ]

    const paymentChannels = [
        {
            limit: 'Over-the-counter',
            me: true,
            gig: true,
            sme: true,
            biz: true,
        },
        {
            limit: 'Online Banking',
            me: true,
            gig: true,
            sme: true,
            biz: true,
        },
        {
            limit: 'E-wallets',
            me: true,
            gig: true,
            sme: true,
            biz: true,
        },
        {
            limit: 'Debit and Credit Cards',
            me: false,
            gig: false,
            sme: true,
            biz: true,
        },
        {
            limit: 'Buy Now Pay Later',
            me: false,
            gig: false,
            sme: true,
            biz: true,
        },
    ]

    return(
        <div style={style.mainDiv}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Card style={style.cardStyle} bodyStyle={style.cardBodyStyle}>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '34px'}}>
                        <div style={style.headerText}>Account Levels</div>
                        <img src={MeLogo} style={style.logoStyle}/>
                        <img src={GigLogo} style={style.logoStyle}/>
                        <img src={SmeLogo} style={style.logoStyle}/>
                        <img src={BizLogo} style={style.logoStyle}/>
                    </div>
                    <div style={style.tableHeaderStyle}>
                        <div style={{fontWeight: 'bold', fontSize: '14px', color:'#2b2d32'}}>Account Limits</div>
                    </div>
                    {
                        acccountLimits.map((item, i)=>{
                            return(
                                <div style={style.rowStyle}>
                                    <div style={{...style.rowText, width: '240px', textAlign: 'left'}}>{item.limit}</div>
                                    <div style={{...style.rowText, width: '180px'}}>{item.me}</div>
                                    <div style={{...style.rowText, width: '180px'}}>{item.gig}</div>
                                    <div style={{...style.rowText, width: '180px'}}>{item.sme}</div>
                                    <div style={{...style.rowText, width: '180px'}}>{item.biz}</div>
                                </div>
                            )
                        })
                    }

                    <div style={{...style.tableHeaderStyle, marginTop: '24px'}}>
                        <div style={{fontWeight: 'bold', fontSize: '14px', color:'#2b2d32'}}>Features</div>
                    </div>
                    {
                        features.map((item, i)=>{
                            return(
                                <div style={style.rowStyle}>
                                    <div style={{...style.rowText, width: '240px', textAlign: 'left'}}>{item.limit}</div>
                                    <div style={{...style.rowText, width: '180px'}}>{item.me ? <img src={Check} style={{height:'24px'}}/> : <img src={Ex} style={{height:'24px'}}/>}</div>
                                    <div style={{...style.rowText, width: '180px'}}>{item.gig ? <img src={Check} style={{height:'24px'}}/> : <img src={Ex} style={{height:'24px'}}/>}</div>
                                    <div style={{...style.rowText, width: '180px'}}>{item.sme ? <img src={Check} style={{height:'24px'}}/> : <img src={Ex} style={{height:'24px'}}/>}</div>
                                    <div style={{...style.rowText, width: '180px'}}>{item.biz ? <img src={Check} style={{height:'24px'}}/> : <img src={Ex} style={{height:'24px'}}/>}</div>
                                </div>
                            )
                        })
                    }

                    <div style={{...style.tableHeaderStyle, marginTop: '24px'}}>
                        <div style={{fontWeight: 'bold', fontSize: '14px', color:'#2b2d32'}}>Payment Channels</div>
                    </div>
                    {
                        paymentChannels.map((item, i)=>{
                            return(
                                <div style={style.rowStyle}>
                                    <div style={{...style.rowText, width: '240px', textAlign: 'left'}}>{item.limit}</div>
                                    <div style={{...style.rowText, width: '180px'}}>{item.me ? <img src={Check} style={{height:'24px'}}/> : <img src={Ex} style={{height:'24px'}}/>}</div>
                                    <div style={{...style.rowText, width: '180px'}}>{item.gig ? <img src={Check} style={{height:'24px'}}/> : <img src={Ex} style={{height:'24px'}}/>}</div>
                                    <div style={{...style.rowText, width: '180px'}}>{item.sme ? <img src={Check} style={{height:'24px'}}/> : <img src={Ex} style={{height:'24px'}}/>}</div>
                                    <div style={{...style.rowText, width: '180px'}}>{item.biz ? <img src={Check} style={{height:'24px'}}/> : <img src={Ex} style={{height:'24px'}}/>}</div>
                                </div>
                            )
                        })
                    }

                    
                </Card>
            </div>
            
             <div style={{...style.rowText, marginTop: '30px'}} align="center">Have some concerns? Feel free to reach out to us at </div>
             <div align="center"><a href="mailto:support@bux.ph" style={style.support} className={`link-text--${segments}`}>support@bux.ph</a></div>
            
        </div>
    )
}

const style = {
    mainDiv: {
        width: '100%',
        padding: '60px 0px 30px 0px'
    },
    cardStyle: {
        width: '1090px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px'
    },
    cardBodyStyle: {
        padding: '32px'
    },
    headerText: {
        width: '329px',
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '20px',
        color: '@2b2d32'
    },
    logoStyle: {
        width: '90px',
        marginRight: '96px'
    },
    tableHeaderStyle: {
        borderRadius: '8px 8px 0px 0px',
        height: '52px',
        width: '100%',
        backgroundColor: '#CFD3D9',
        padding: '16px'
    },
    rowStyle: {
        display: 'flex',
        width: '100%',
        borderBottom: '1px solid #D4D5D8',
        padding: '16px'
    },
    rowText: {
        fontSize: '14px',
        color: '#2b2d32',
        fontWeight: 'normal',
        lineHeight: '20px',
        textAlign: 'center'
    },
    support:{
        fontWeight: 'bold',
        fontSize: '14px'
    }
}

export default AccountLevelsWeb