import React from 'react'
import { Layout , Button, Drawer } from 'antd'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import HomeStyle from '../../../static/css/Home'
import * as Icon from '../../../static/images/home/index'
import { isMobile } from 'react-device-detect'

const { Header } = Layout

export default function Headers(props) {
    const home = HomeStyle()
    const theme = useTheme()
    const smallReso = useMediaQuery(theme.breakpoints.down('sm'))
    const mediumReso = useMediaQuery(theme.breakpoints.between('md', 'md'))
    const [openDrawer, setOpenDrawer] = React.useState(false)
    const headerList = [
        {
            'name': 'ENTERPRISE',
            'link': 'https://enterprise.bux.ph/'
        },
        {
            'name': 'PRICING',
            'link': props.pricing
        },
        {
            'name': 'FAQS',
            'link': 'https://faq.bux.ph'
        },
        {
            'name': 'CONTACT US',
            'link': 'mailto:support@bux.ph'
        },
    ]

    function goToPricingDiv() {
        setOpenDrawer(false)
        props.pricing()
    }

    React.useEffect(() => {
        if(!smallReso || !mediumReso) {
            setOpenDrawer(false)
        }
    }, [smallReso, mediumReso])

    return(
        <div>
            <Header className={isMobile || smallReso ? home.mainHeaderStyle : home.webHeader}>
                <div className={home.headerChoices}>
                    {
                        isMobile || smallReso || mediumReso ?
                        <div>
                            <button className={home.menuBtn} onClick={() => setOpenDrawer(!openDrawer)}>
                                <img src={openDrawer ? Icon.X : Icon.Menu}/>
                            </button>

                            <img
                            src={Icon.MobileBux}
                            className={home.buxLogo}
                            alt="#"
                            onClick={() => props.chart()} />
                        </div> 
                        :
                        <img src={Icon.WebBux} onClick={() => props.chart()} alt="#" style={{maxWidth: 80 }}/>

                    }

                    <div className={home.headerList}>
                        {
                            headerList.map((item, key) => {
                                return(
                                    <a href={item.link} onClick={() => key === 1 ? item.link() : console.log("clicked")} className={home.headerLink}>{item.name}</a>
                                )
                            })
                        }

                    </div>
                </div>
                
                {
                    localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null ?
                    <div className={home.headerActionBtn}>
                        <div>
                            <Button className={home.signUp} onClick={ () => window.location.href = "/signup"}>Sign up</Button>
                        </div>
                        <div className={home.loginBtnDiv}>
                            <Button className={home.login} onClick={ () => window.location.href = "/login"}>Login</Button>
                        </div>
                    </div> 
                    :
                    <div>
                        <Button className={home.dashboard} onClick={ () => window.location.href = "/dashboard"}>Dashboard</Button>
                    </div>
                }
                
            </Header>

            <Drawer
              placement="left"
              width="100%"
              style={{marginTop: mediumReso ? '8vh' : '12vh', borderTop: '1px solid #fff'}}
              closable={false}
              visible={openDrawer}>

                <div style={{padding: '32px 20px 32px 20px'}}>
                    {
                        headerList.map((item, key) => {
                            return(
                                <div style={{padding:32, textAlign: 'center', borderBottom: '1px solid #E6EAF0'}}>
                                    <a href={item.link} onClick={() => key === 1 ? goToPricingDiv() : console.log("clicked")} className={home.drawerLink}>{item.name}</a>
                                </div>
                            )
                        })
                    }
                  <div style={{marginTop: 20}}>
                    <button className={home.drawerBtn} onClick={() => window.location.href='/login'}>
                        {
                             localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null ?
                             "Login to " : "Go to "
                        }
                        Dashboard
                    </button>
                  </div>
                </div>

            </Drawer>
        </div>
    )
}