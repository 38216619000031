import React from 'react';
import {DatePicker, Input, Modal} from 'antd';

const HolidayFormModal = (props) => {
    const {
        isEditFormVisible,
        onCancelOrClose,
        onConfirm,
        formValues,
        setFormValues,
        editMode
    } = props;

    return(
        <Modal
            centered
            width={360}
            visible={isEditFormVisible}
            onCancel={onCancelOrClose}
            footer={null}
        >
            <div style={holiday_form_styles.headerText}>
                {editMode} Holiday
            </div>

            <div style={{marginTop: '15px'}}>
                <Input
                    placeholder="Holiday Name"
                    maxLength={64}
                    onChange={(e) => setFormValues({...formValues, name: e.target.value})}
                    value={formValues.name}
                />
            </div>

            <div style={{ marginTop: '15px'}}>
                <DatePicker
                    style={{zIndex: 1, width: '100%'}}
                    format='MM-DD-YYYY'
                    value={formValues.effective_date}
                    placeholder="Effective Date"
                    onChange={(pickedDate) => setFormValues({...formValues, effective_date: pickedDate})}
                />
            </div>

            <div style={holiday_form_styles.buttonsDiv}>
                <button
                    onClick={onCancelOrClose}
                    style={holiday_form_styles.cancelButton}
                >
                    Cancel
                </button>
                <button
                    onClick={() => onConfirm(formValues)}
                    style={holiday_form_styles.confirmButton}
                >
                    {editMode === "Create" ? "Create" : "Save"}
                </button>
            </div>
        </Modal>
    )
}

const holiday_form_styles = {
    buttonsDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '25px'
    },
    headerText: {
        fontSize: 'x-large',
        paddingRight: '20px'
    },
    confirmButton: {
        height: '40px',
        width: '120px',
        background: '#F5922F',
        border: '1px solid #f5922f',
        borderRadius: '10px',
        color: '#fff',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
        outline: 0,
        '&:hover': {
            opacity: 0.8,
            cursor: 'pointer'
        },
        '&:active': {
            background: '#DA7F24',
            opacity: 1
        }
    },
    cancelButton: {
        height: '40px',
        width: '120px',
        background: '#fff',
        border: '1px solid #f5922f',
        borderRadius: '10px',
        color: '#f5922f',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
        outline: 0,
        '&:hover': {
            background: '#f5922f',
            cursor: 'pointer',
            color: '#fff',
        },
        '&:active': {
            background: '#DA7F24',
            opacity: 1
        }
    },
}

export default HolidayFormModal;