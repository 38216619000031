import React from 'react'
import {Bux} from '../../static/icons/index'
import Ecomx from '../../static/images/ecomx.svg'
import RegFee from '../../static/images/reg_fee.svg'
import {Card, Button} from 'antd'
import {Grid} from '@material-ui/core'
import {history} from '../../store/history'
import * as Style from './learn_more_style'

class WebLearnMore extends React.Component{

    event_sched = [
        {
            time: '8:00 AM - 8:15 AM',
            event: 'Welcome Remarks/Kick-Off'
        },
        {
            time: '8:15 AM - 10:00 AM',
            event: 'International Ecommerce (Dropshipping)'
        },
        {
            time: '10:00 AM - 12:00 PM',
            event: 'Local Ecommerce'
        },
        {
            time: '12:00 PM - 1:00 PM',
            event: 'Working Lunch / Q&A'
        },
        {
            time: '1:00 PM - 3:00 PM',
            event: 'International Print-on-Demand'
        },
        {
            time: '3:00 PM - 4:00 PM',
            event: 'Local Print-on-Demand'
        },
        {
            time: '4:00 PM - 4:45 PM',
            event: 'Q&A'
        },
        {
            time: '4:47 PM - 5:00 PM',
            event: 'Presentation by UBX GlobalLinker and Bux'
        },
        {
            time: '5:00 PM - ',
            event: 'Open Mic / Mastermind talks'
        },
    ]
    render(){
        return(
            <div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img src={Bux} alt="#" style={Style.icon_style}/>
                </div>
                <Card
                    style={{margin: '0px 20% 0px 20%'}}
                >
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                        <img src={Ecomx} alt="#"/>
                    </div>

                    <div style={Style.infoStyle}>
                        <span>Date:</span><span style={{fontWeight: '600'}}>December 10, 2019</span>
                    </div>
                    <div style={Style.infoStyle}>
                        <span>Venue:</span><span style={{fontWeight: '600'}}>UnionBank Plaza Bldg</span>
                    </div>
                    <div style={Style.byNickStyle}>By Nick Peroni</div>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                        <span style={Style.byNickStyle}>Powered by</span> <img src={Bux} />
                    </div>
                </Card>
                <Card
                    style={{margin: '0px 20% 0px 20%'}}
                >
                <Grid container>
                    <Grid lg={6} style={{padding: '72px 0px 47px 32px'}}>
                        <div>
                           <img src={RegFee}/>
                        </div>

                    </Grid>
                    <Grid lg={6} style={{padding: '12px 40px 47px 32px'}}>
                        <div style={Style.dontMissStyle}>
                            <span style={{fontWeight: '600'}}>Don’t miss the chance!</span>
                        </div>
                        <br></br>
                        <div style={Style.dontMissStyle}>
                            Limited seats only
                        </div>
                        <div style={Style.dontMissStyle}>
                            First Come, First Served Basis
                        </div>
                        <Button type="primary" size="large" block style={{marginTop: '20px'}} onClick={()=>history.push('/ecomx/registration')}> Sign up now!</Button>
                    </Grid>
                </Grid>
                </Card>
                <Card
                    style={{margin: '0px 20% 20px 20%', paddingLeft: '20px'}}
                >
                       <div style={Style.dontMissStyle}>
                            <span style={{fontWeight: '600'}}>Event Schedule</span>
                       </div>
                       <br></br>
                       {this.event_sched.map((item, indx) => {
                            return (
                                <Card>
                                    <Grid container>
                                        <Grid lg={4} style={Style.timeStyle}>{item.time}</Grid>
                                        <Grid lg={8} style={Style.eventStyle}>{item.event}</Grid>
                                    </Grid>
                                </Card>
                            );
                        })} 
                </Card>
            </div>

        )
    }
}

export default WebLearnMore