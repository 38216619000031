import React from 'react';
import * as Icon from '../../static/icons/how_it_works/index';
import * as Img from '../../static/images/how_it_works/index';
import ContentCard from './content_card';
import SettingsStep from './reusable_content/settings';

const ContentStep8 = props => {
    
    const {
        segments
    } = props;

    const userType = localStorage.getItem('userType')

    const isIndividual = userType === 'ME'

    let testmode_icon = segments == 'sme' ? Icon.Test_sme : 
                            segments == 'biz' ? Icon.Test_biz: ""



    return (
        <div>
            {
                isIndividual ?
                    <SettingsStep segments={segments} step={8} />
                    :
                    <ContentCard step={8}
                    icon={testmode_icon}
                    image={Img.Testmode}
                    title={"Test Mode"}
                    content={"A feature that allows you to simulate test transaction on your test environment."} />

            }
            
        </div>
    )
}

export default ContentStep8;