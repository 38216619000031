import React from "react";

export function useMultistepForm(steps) {
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);

  const next = () =>
    setCurrentStepIndex((prev) => {
      if (prev === steps.length - 1) return prev;
      return prev + 1;
    });

  const back = () =>
    setCurrentStepIndex((prev) => {
      if (prev === 0) return prev;
      return prev - 1;
    });

  const goTo = (index) => setCurrentStepIndex(index);

  return {
    currentStepIndex,
    step: steps[currentStepIndex],
    steps,
    isFirstStep: currentStepIndex === 0,
    isLastStep: currentStepIndex === steps.length - 1,
    goTo,
    next,
    back,
  };
}
