export const GET_RECON_BEGIN = 'GET_RECON_BEGIN';
export const GET_RECON_SUCCESS = 'GET_RECON_SUCCESS';
export const GET_RECON_ERROR = 'GET_RECON_ERROR';

export const GET_SUBWALLET_PAYMENT_BEGIN = 'GET_SUBWALLET_PAYMENT_BEGIN';
export const GET_SUBWALLET_PAYMENT_SUCCESS = 'GET_SUBWALLET_PAYMENT_SUCCESS';
export const GET_SUBWALLET_PAYMENT_ERROR = 'GET_SUBWALLET_PAYMENT_ERROR';

export const GET_CUTOFF_BEGIN = 'GET_CUTOFF_BEGIN';
export const GET_CUTOFF_SUCCESS = 'GET_CUTOFF_SUCCESS';
export const GET_CUTOFF_ERROR = 'GET_CUTOFF_ERROR';