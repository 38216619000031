// import {restartTimer} from '../timer/logout_timer'
import {history} from '../../store/history'
import axios from 'axios'
import {Handle401} from '../handle401/handle401'

export async function getLatestBalance() {
    // restartTimer();

    let url = process.env.REACT_APP_API_URL + "/api/get_latest_balance/";
    const httpConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    };

    try {
        if (window.location.pathname === '/dashboard' || window.location.pathname === '/mobile/dashboard' || window.location.pathname === 'my_profile') {
            const step_param = localStorage.getItem("userType") === "CO" ? "KYBStep" : "Step";
            url += ("?step=" + localStorage.getItem(step_param));
        }

        let response = await axios.get(url, httpConfig);
        const responseData = response.data;
        const localStorageData = {
            'balance': responseData.balance,
            'Step': responseData.kyc_step,
            'KYBStep': responseData.kyb_step,
            'Level': responseData.kyc_level,
            'payout_enabled': responseData.payout_enabled,
            'shipping_enabled': responseData.shipping_enabled,
            'admin_shipping_enabled': responseData.admin_shipping_enabled,
            "admin_711_enabled": responseData.admin_711_enabled,
            "banks_disabled": JSON.stringify(responseData.banks_disabled),
            "channels_disabled": JSON.stringify(responseData.channels_disabled),
            "contact": responseData.contact,
            "industry": responseData.industry,
            "userType": responseData.user_type,
            "profilePicture": responseData.profile_picture,
            "termsAgreed": responseData.terms_agreed,
            "segments": responseData.bux_segment.toLowerCase(),
        }

        for (const [itemKey, itemValue] of Object.entries(localStorageData)) {
            localStorage.setItem(itemKey, itemValue);
        }

        if(responseData.payout_type){
            localStorage.setItem('payout_type', responseData.payout_type);
        }

        if (responseData) {
            // if ( // TODO What does this mean?
            //     (responseData.kyc_level >= 1
            //         || (responseData.kyc_level === 0 && responseData.kyc_step > 6))
            //     && (responseData.industry === "" || responseData.contact === "")
            //     && responseData.user_type === "ME") {
            //     history.push('/survey_business')
            // }
            return responseData;
        }
        else {
            return false;
        }

    }
    catch (error) {
        if (error.response.status === 401) {
            Handle401();
        }
    }
}