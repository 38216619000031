import React, { useState } from "react";
import { Modal, Button, Alert } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {hideModal} from './modalAction' 
import {history} from '../../store/history'
import modalServices from './modalServices';
import CloseIcon from '../../static/icons/close_icon_orange.svg'

const BuxModal  = props => {
  let showModal = useSelector(state => state.modal.showModal);
  let title = useSelector(state => state.modal.title);
  let message = useSelector(state => state.modal.message);
  let returnType = useSelector(state => state.modal.returnType);
  let showResendLink = useSelector(state => state.modal.showResendLink);
  let email = useSelector(state => state.modal.email);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);

  const dispatch = useDispatch();

  const resendVerification = async () => {
    setIsLoading(true);
    try {
      const res = await modalServices.resendVerification({email: email});
      if(res.status === 'success') {
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
      }
      setIsLoading(false);
      setAlertMsg(res.message);
      setShowAlert(true)
      // console.log(res);
    } catch (error) {
      console.log(error);
      setIsSuccess(false);
      setIsLoading(false);
      setAlertMsg("There's an error try again");
      setShowAlert(true)
    }
  }

  const handleResend = () => {
    resendVerification()
  }

  const hideModalNow = () =>{
    dispatch(hideModal({}))
    history.push(`/${returnType}`)
  }
  
  return (
    <Modal
      centered
      visible={showModal}
      footer={null}
      closable={false}
      width="376px"
      bodyStyle={{
        padding: '32px',
        height: '100%'
      }}
      onCancel={()=>hideModalNow()}>
      
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={style.header}>{title}</div>
            <img src={CloseIcon} onClick={()=>hideModalNow()}/>
        </div>
        { showAlert?
          <Alert style={{marginTop:20}} message={alertMsg} type={isSuccess?"success":"error"} showIcon />
          : null
        }
        <div style={style.text}>
          {message}
        </div>
        <br/>
        <a style={style.link} onClick={resendVerification} hidden={!showResendLink}>
          Resend verification link
        </a><br/>
        <div align="right">
          <Button loading={isLoading} style={style.btn} onClick={()=>hideModalNow()}>Done</Button>
        </div>
    </Modal>
  );
};

const style = {
  header: {
    fontSize: '18px',
    fontWeight: '700',
    color: '2b2d33'
  },
  text:{
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#2b2d33',
    marginTop: '16px'
  },
  link:{
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#f5922f',
    textDecoration: 'underline',
  },
  btn:{
    height: '40px',
    width: '89px',
    backgroundColor: '#f5922f',
    border: '1px solid #f5922f',
    borderRadius: '4px',
    color: '#fff',
    fontSize: '16px',
  }
}
export default BuxModal;
