import React, { useState } from 'react';
import WalletBalanceCards from '../../components/multiwallet/components/master_wallet_page/wallet_balance_cards';
import MultiwalletStatsCard from '../../components/multiwallet/components/db_multiwallet_card';
import { isMobile } from 'react-device-detect';
import Table from '../../components/multiwallet/components/master_wallet_page/table';
import MetaTag from '../../components/meta_tag/meta_tag';
import { useSelector } from '../../components/dashboard/react-redux-hooks';
import { history } from '../../store/history';
import TestModeBanner from '../../components/cards/test_mode/testModeBanner';
import { Grid } from '@material-ui/core';
import '../../App.scss';
import { axios } from '../../lib/axios';

const MasterWalletPage = () => {
    const [balance, setBalance] = useState(null);
    const walletBalances = useSelector(state => state.multiwallet.walletBalances);
    const isMultiwallet = localStorage.getItem('is_multiwallet') === 'true';
    const isSbMultiwallet = localStorage.getItem("is_sb_multiwallet") === 'true';

    const kybStep = localStorage.getItem("KYBStep");
    const userType = localStorage.getItem("userType");
    const approvedStep = [11, 12]
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const isChild = localStorage.getItem("is_child") === 'true';
    const notApprovedKyb = (userType == "CO" && localStorage.getItem("KYBStep") < 11) &&
                            ((!testMode || userType == "CO") &&
                            (!approvedStep.includes(parseInt(kybStep)) && !testMode));

    const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
    const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
    const isDell1 = window.screen.width * window.devicePixelRatio === 1366 && window.screen.height * window.devicePixelRatio === 768;
    const isMac = window.screen.width * window.devicePixelRatio === 1440 && window.screen.height * window.devicePixelRatio === 900;

    const isDellReso = (isSmallReso || isDell || isDell1)

    const redirect = () => {
        let current_path = window.location.pathname
        if (localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null) {
            history.push('/login?next=' + current_path)
        }
        else if ((testMode && !isSbMultiwallet)) {
            history.push('/test/dashboard')
        }
        else if ((!['CO'].includes(localStorage.getItem("userType")) || !isMultiwallet || notApprovedKyb) && !testMode) {
            history.push('/dashboard')
        }
        else if(isMobile) {
            history.push('mobile/multiwallet');
        }
    }

    React.useEffect(() => {
        redirect();
    })

    React.useEffect(() => {
        axios.get('/api/get_latest_balance/')
            .then((res) => setBalance(res))
            .catch((error) => console.log(error));
    }, []);

    return (
        <div className={isDell ? "top-12" : "top-40"} style={{marginRight: isDellReso ? '2vw' : isMac ? '5vw' : '10vw'}} >
            <MetaTag title="Multiwallet" />
            <TestModeBanner width={'100vw'} top={64} right={0}/>
            <div className="twoCol">
                <div style={{width: '30%'}}>
                    <WalletBalanceCards balance={walletBalances} />
                </div>

                <div style={{width: '68%'}}>
                    <MultiwalletStatsCard withWidth={false} />
                </div>
            </div>
            <Table balance={balance} buxBalance={testMode && walletBalances? walletBalances.master : !testMode && walletBalances? walletBalances.float : 0} />
        </div>
    )
}

export default MasterWalletPage;
