import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Select,
  Checkbox,
  Typography,
  Button,
  notification,
} from "antd";
import { NATURE_BUSINESS } from "../../../../../../components/constants/constants";
import MetaTag from "../../../../../../components/meta_tag/meta_tag";
import { createAdmin } from "../api/create-admin";
import { useRestrictedPayoutBanks } from "../../../../../../components/multiwallet/components/master_wallet_page/create_subwallet/api";
import {
  validateMobileNumber,
  validateEmail,
} from "../../../../../../utils/validators";

function CreateAdmin({ form }) {
  const history = useHistory();
  const [formData, setFormData] = useState({
    merchant_group_name: "",
    merchantType: "SW",
    business_name: "",
    nature_business: "",
    first_name: "",
    last_name: "",
    email: "",
    contact: "",
    rail_type: "",
    sw_type: "admin",
    bank_code: "",
    multiwallet_privileges: {
      generate_payment_link_enabled: true,
      checkout_enabled: true,
      add_money_enabled: true,
      transfer_money_enabled: true,
      payout_enabled: true,
      payout_type: 0,
      is_payout_restricted: false,
      link_store_enabled: true,
      exclusive_banners_enabled: true,
      add_users_enabled: true,
      wallet_transfer_enabled: true,
      wallet_transfer_history_enabled: true,
      wallet_transfer_approval_enabled: true,
      bank_transfer_enabled: false,
      limits_configuration_enabled: true,
      limits_approval_enabled: true,
      limits_history_enabled: true,
      fund_transfer_history_enabled: true,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const {
    data: restrictedBanks,
    isLoading: isRestrictedBanksLoading,
    isError: isRestrictedBanksError,
  } = useRestrictedPayoutBanks();

  const updateFormData = (fieldName, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, _) => {
      if (err) return;
      setIsLoading(true);

      try {
        const response = await createAdmin(formData);
        setIsLoading(false);
        notification.success({
          message: "Success",
          description: response.message,
        });
        history.push("/cashbux/admins");
      } catch (error) {
        setIsLoading(false);
        notification.error({
          message: "Failed",
          description: `${err.message}. Please try again later.`,
        });
      }
    });
  };

  return (
    <div
      style={{
        margin: "0 2.5em 2.5em 2.5em",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <MetaTag title="CashBux Users" />
      <div>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "20px",
            color: "#2B2D33",
          }}
        >
          Create new merchant
        </Typography>
      </div>
      <Card>
        <Form onSubmit={handleSubmit}>
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <Form.Item label="Business Name" style={{ margin: 0 }}>
              {form.getFieldDecorator("business_name", {
                initialValue: formData.business_name,
                rules: [
                  { required: true, message: "Please input business name!" },
                ],
              })(
                <Input
                  size="large"
                  onChange={(e) => {
                    updateFormData("business_name", e.target.value);
                    updateFormData("merchant_group_name", e.target.value);
                  }}
                />,
              )}
            </Form.Item>

            <Form.Item label="Nature of Business" style={{ margin: 0 }}>
              {form.getFieldDecorator("nature_of_business", {
                initialValue: formData.nature_business,
                rules: [
                  {
                    required: true,
                    message: "Please select nature of business",
                  },
                ],
              })(
                <Select
                  size="large"
                  placeholder="Select nature of business"
                  onChange={(value) => updateFormData("nature_business", value)}
                >
                  {NATURE_BUSINESS.map((business, index) => (
                    <Select.Option key={index} value={index}>
                      {business}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>

            <Form.Item label="First Name" style={{ margin: 0 }}>
              {form.getFieldDecorator("first_name", {
                initialValue: formData.first_name,
                rules: [
                  { required: true, message: "Please input first name!" },
                ],
              })(
                <Input
                  size="large"
                  onChange={(e) => updateFormData("first_name", e.target.value)}
                />,
              )}
            </Form.Item>

            <Form.Item label="Last Name" style={{ margin: 0 }}>
              {form.getFieldDecorator("last_name", {
                initialValue: formData.last_name,
                rules: [{ required: true, message: "Please input last name!" }],
              })(
                <Input
                  size="large"
                  onChange={(e) => updateFormData("last_name", e.target.value)}
                />,
              )}
            </Form.Item>

            <Form.Item label="Email" style={{ margin: 0 }}>
              {form.getFieldDecorator("email", {
                initialValue: formData.email,
                rules: [
                  { required: true, message: "Please input email!" },
                  { validator: (_, value) => validateEmail(value) },
                ],
              })(
                <Input
                  size="large"
                  onChange={(e) => updateFormData("email", e.target.value)}
                />,
              )}
            </Form.Item>

            <Form.Item label="Mobile Number" style={{ margin: 0 }}>
              {form.getFieldDecorator("contact", {
                initialValue: formData.contact,
                rules: [
                  { required: true, message: "Please input mobile number!" },
                  { validator: (_, value) => validateMobileNumber(value) },
                ],
              })(
                <Input
                  addonBefore="+63"
                  type="number"
                  size="large"
                  onChange={(e) => updateFormData("contact", e.target.value)}
                />,
              )}
            </Form.Item>

            <Form.Item style={{ margin: 0 }}>
              {form.getFieldDecorator("bank_transfer_enabled", {
                valuePropName: "checked",
                initialValue:
                  formData.multiwallet_privileges.bank_transfer_enabled,
              })(
                <Checkbox
                  onChange={(e) =>
                    updateFormData("multiwallet_privileges", {
                      ...formData.multiwallet_privileges,
                      bank_transfer_enabled: e.target.checked,
                    })
                  }
                >
                  Enable Bank Transfer
                </Checkbox>,
              )}
            </Form.Item>

            {formData.multiwallet_privileges.bank_transfer_enabled && (
              <Form.Item label="Rail Type" style={{ margin: 0 }}>
                {form.getFieldDecorator("rail_type", {
                  initialValue: formData.rail_type,
                  rules: [
                    { required: true, message: "Please select rail type" },
                  ],
                })(
                  <Select
                    size="large"
                    placeholder="Select rail type"
                    onChange={(value) => updateFormData("rail_type", value)}
                  >
                    <Select.Option key="UBP" value="UBP">
                      Unionbank
                    </Select.Option>
                    <Select.Option key="ALB" value="ALB">
                      AllBank
                    </Select.Option>
                  </Select>,
                )}
              </Form.Item>
            )}

            <Form.Item style={{ margin: 0 }}>
              {form.getFieldDecorator("is_payout_restricted", {
                valuePropName: "checked",
                initialValue:
                  formData.multiwallet_privileges.is_payout_restricted,
              })(
                <Checkbox
                  onChange={(e) =>
                    updateFormData("multiwallet_privileges", {
                      ...formData.multiwallet_privileges,
                      is_payout_restricted: e.target.checked,
                    })
                  }
                >
                  Restrict payout to specific bank
                </Checkbox>,
              )}
            </Form.Item>

            {formData.multiwallet_privileges.is_payout_restricted && (
              <Form.Item
                label="Choose the bank to restrict account to"
                style={{ margin: 0 }}
              >
                {form.getFieldDecorator("restricted_bank", {
                  initialValue: formData.bank_code,
                  rules: [{ required: true, message: "Please select bank" }],
                })(
                  <Select
                    loading={isRestrictedBanksLoading}
                    size="large"
                    placeholder="Select bank"
                    onChange={(value) => updateFormData("bank_code", value)}
                  >
                    {restrictedBanks.banks &&
                      restrictedBanks.banks.map((bank) => (
                        <Select.Option key={bank.code} value={bank.code}>
                          {bank.bank_name}
                        </Select.Option>
                      ))}
                  </Select>,
                )}
              </Form.Item>
            )}

            <Button
              size="large"
              htmlType="submit"
              type="primary"
              style={{ alignSelf: "flex-end" }}
              loading={isLoading}
            >
              Create
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
}

export default Form.create({ name: "create_user_modal" })(CreateAdmin);
