import React from 'react'
import axios from 'axios'
import {Card, Select, Input, Icon, Switch} from 'antd'
import enterpriseStyle from '../kybStyle'
import { isMobile } from 'react-device-detect'
import {NATURE_BUSINESS, NATIONALITY, POSITION} from '../../../constants/constants'
import Passed from '../../../../static/img/passed.svg'
import Failed from '../../../../static/img/failed.svg'

const ReviewBusinessDocu = props => {
    let datas = props.datas
    let segments = props.segments
    const userRole = localStorage.getItem("userType")
    const kyb = enterpriseStyle()
    const [data, setData] = React.useState(datas ? datas : [])    
    const [toggleOn, SetToggleOn] = React.useState(true)
    let kybStep = localStorage.getItem("KYBStep")
    let businessType = data.business_type
    let docus = []
    const adminRole = ['AD', 'KB', 'SD', 'OP', 'BS', 'AP', 'EN', 'FI',]
    const editRole = ['AD', 'CO', 'SD', 'BS', 'SW']
    const createRole = ['AD', 'SD']
    const hiddenSteps = [8, 9, 11]

    let kybLevel = localStorage.getItem("Level")

    docus = 

    businessType == 0?
    [
        {
            name: 'DTI Registration',
            file_name: data.dti_registration_attachment
        },
        {
            name: 'Mayor’s Permit/Business Permit',
            file_name: data.business_permit_attachment
        },
        {
            name: 'BIR 2303',
            file_name: data.bir_2303_attachment

        },
        // {
        //     name: 'Mayor’s Permit',
        //     file_name: data.mayors_permit_attachment
        // },
    ]:
    
    [
        {
            name: data.business_type == 1 ? 'SEC Registration' : 'SEC Certificate',
            file_name: data.sec_registration_attachment
        },
        {
            name: data.business_type == 1 ? 'Articles Of Incorporation' : 'Articles of Partnership',
            file_name: data.articles_of_incorporation_attachment
        },
        {
            name: data.business_type == 1 ? `Secretary's Certificate` : 'Partnership Resolution',
            file_name: data.secretary_certificate_attachment

        },
        {
            name: 'BIR 2303',
            file_name: data.bir_2303_attachment

        },
        {
            name: 'Mayor’s Permit/Business Permit',
            file_name: data.business_permit_attachment
        },
    ]

    if(data.years_in_business > 1 && data.business_type == 1){
        docus.push(
            {
                name: 'Latest GIS',
                file_name: data.gis_attachment
    
            },
        )
    }

    if(data.business_type == 1) {
        docus.push({
            name: 'By-Laws',
            file_name: data.bylaws_attachment

        })
    }

    function editBusinessInfo(val){
        props.openEdit(val)
    }

    function submitFailed(val){
        SetToggleOn(val)
        if(adminRole.includes(userRole) && localStorage.getItem("KYBType") != "creation"){
            props.setFailedStep(val, 3)
        }
    }

    React.useEffect(() => {
        submitFailed(datas ? !datas.step_3_has_error : false)
    },[])

    return(
        <Card 
            className={`${kyb.formCardStyle} padding-16`}
            bodyStyle={{padding: '20px 16px 20px 16px'}}
            style={{
                border: toggleOn && 
                        adminRole.includes(userRole)
                        ? '2px solid #1DD28B' : 
                        !toggleOn && 
                        adminRole.includes(userRole) ? 
                        '2px solid #E24C4C' : ''
            }}>

            {
                adminRole.includes(userRole) ?
                <div style={{position: 'relative', paddingBottom: 16, 
                            borderBottom: '1px solid #D1D5DD', marginBottom: 16}}>    
                     <div style={{position: 'absolute', top: '-20px', left: '-17px'}}>
                        <img src={toggleOn ? Passed : Failed} />    
                     </div>
                     <div align='right'>
                        <span className={kyb.miniText} style={{opacity: props.curStep >= 11 ? 0.5 : ''}}>
                            Mark as Passed: <Switch size="small" disabled={props.curStep >= 11} checked={toggleOn} onChange={() => submitFailed(!toggleOn)}/>
                        </span>
                     </div>
                </div>

                :
                ''
            }
            
            <div className="bottom-32 twoCol" ref={props.cardDivRef}>
                <div className={`${kyb.greatText} liveColor`}>Business Document</div>

                <div hidden={(!editRole.includes(userRole)) || (['CO','SW'].includes(localStorage.getItem('userType')) &&  hiddenSteps.includes(parseInt(kybStep)))}>
                    <button className={`outline-btn--${segments}`} onClick={() => editBusinessInfo(3)}>
                        <Icon type="edit" className={`text-${segments} right-4`}/>Edit
                    </button>
                </div>
            </div>
            <div className={kyb.tableStyle}>

            {
                docus.map((item, key) => {
                    return(
                        <div className="twoCol top-20 liveColor">
                            <div>
                                {item.name}
                            </div>
                            <div className={kyb.valueText} align={isMobile ? 'right' : ''} style={{fontWeight: 200}}>
                                <div>
                                    <a href={item.file_name} disabled={!item.file_name} target="blank" className={`text-${segments} medium-font-weight`}>{!item.file_name ? "Empty" : "View"}</a> {adminRole.includes(userRole) ?<span> |  <a href={item.file_name} target="blank" disabled={!item.file_name} download className={`text-${segments} medium-font-weight`}>Download</a></span>: ''}
                                </div>
                            </div>
                        </div>
                
                    )
                })
            }
            </div>
        </Card>
    )
}

export default ReviewBusinessDocu