import React, { useState } from 'react';
import { Layout, Modal, Icon, Switch, Divider } from 'antd';
import { history } from '../../store/history';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { showGenerateTooltip, setTestMode } from '../../layouts/AppBar/appBarComponent/appBarFormAction'
import { Envelope } from '../../static/icons/sidebar/index'
import Admin from './SideBar/Admin'
import PartnerSupport from './SideBar/PartnerSupport'
import PartnerFinance from './SideBar/PartnerFinance'
import Marketing from './SideBar/Marketing'
import KYCSupport from './SideBar/KYCSupport'
import Merchant from './SideBar/Merchant';
import { getLatestBalance } from '../../layouts/Main'
import SalesAdmin from './SideBar/SalesAdmin';
import { IconButton } from '@material-ui/core';
import TestModeInfoModal from '../cards/test_mode/testModeInfoModal';
// Headers
import MerchantHeader from './SidebarHeader/merchant';
import CorporateHeader from './SidebarHeader/corporate';
import AdminHeader from './SidebarHeader/admin';
import * as Icons from '../../static/icons/sidebar/index';

import '../../App.scss';

const { Sider } = Layout;
const testMode = (history.location.pathname.indexOf('/test') > -1)

const useStyles = theme => ({
  siderHover: {
    '&:hover': {
      background: '#1d82b8',
      opacity: '1',
    }
  }
});

export function sideBarUpdateState(value) {
  if (!this.amIMounted) {
    return;
  }
  this.setState({ showToolTip: value })
}
export function setInviModal(value) {
  this.setState({ inviModal: value })
}
export function hideTooltip() {
  this.setState({ contactUsTooltip: false })
}

export function showTestModeTooltip() {
  this.setState({ testMode: true })
}
export function showFundsTooltip() {
  this.setState({ showToolTip: 4 })
}

const TestModeToggle = ({ enabled, handleChange, isHidden, collapsed, segments }) => {
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
  }

  return (
    <div align="center" hidden={isHidden}>
      <span hidden={collapsed}>
        <span className={testMode ? "testText" : "testModeText"}>
          Test Mode
        </span>
        <IconButton color="inherit" aria-label="info" variant="contained" style={{ padding: 4 }} onClick={() => setShowModal(true)} >
          <Icon type="question-circle" theme="outlined" className={`questionIcon text-${segments}`} />
        </IconButton>
      </span>

      <Switch checked={enabled} onChange={handleChange} className={`switchMode ${testMode ? `bg-${segments}` : ""}`} size="small" />
      <TestModeInfoModal visible={showModal} closeModal={onCloseModal} segments={segments} />

    </div>
  )
}

class SideBar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      title: 'Dashboard',
      admin_title: 'Orders',
      balance: 0,
      showToolTip: 0,
      contactUsTooltip: false,
      inviModal: false,
      hover: '',
      kybStep: 0,
      custId: null,
      userType: '',
      doneFetch: false,
      endpoints: [],
      reconMenu: false,
      showShareModal: false,
      tradeName: '',
      current_step: 0,
      industry: "",
      kyc_level: 0,
      fname: localStorage.getItem("firstName"),
      lname: localStorage.getItem("lastName"),
      username: localStorage.getItem("username"),
      hasMerchantBias: false,
      shipping_enabled: false,
      profilePicture: "",
      isChild: (localStorage.getItem("userType") === "CO" && localStorage.getItem("isAlias") === "true"),
      ccEnabled: "",
      isMultiwallet: false,
      segments: localStorage.getItem("segments"),
      subuserRole: 'ME',
      kybRequired: true
    }

    this.amIMounted = false;

    sideBarUpdateState = sideBarUpdateState.bind(this)
    setInviModal = setInviModal.bind(this)
    hideTooltip = hideTooltip.bind(this)
    showFundsTooltip = showFundsTooltip.bind(this)
    this.getBalance = this.getBalance.bind(this)
  }


  async getBalance() {
    let data = await getLatestBalance()
    if (data) {
      if (!this.amIMounted) {
        return;
      }
      if (((data.user_type !== 'ME' && !(data.endpoints).includes('funds_enterprise')) || (data.user_type === 'ME' && !(data.endpoints).includes('funds'))) && (window.location.pathname.indexOf('/funds') > -1 || window.location.pathname.indexOf('/funds_enterprise') > -1)) {
        window.location.href = ('/dashboard');
      }

      this.setState({
        custId: data["customer_id"],
        userType: data["user_type"],
        doneFetch: true,
        // total_paid: data.total_paid,
        endpoints: data.endpoints,
        current_step: data.kyc_step,
        industry: data.industry,
        kybStep: data.kyb_step,
        userKYCLevel: data.kyc_level,
        tradeName: data.qr_trade_name,
        sbTradeName: data.sb_qr_trade_name,
        // fname: data.first_name,
        // lname: data.last_name,
        // username: data.username,
        hasMerchantBias: data.merchant_bias,
        profilePicture: data.profile_picture,
        ccEnabled: data.cc_enabled,
        is_child: data.is_child,
        child_test_mode: data.child_test_mode,
        isMultiwallet: data.is_multiwallet,
        isSbMultiWallet: data.is_sb_multiwallet,
        subuserRole: data.subuser_role,
        kybRequired: data.kyb_required == true,
        accent: data.accent.toLowerCase(),
        subwalletTransferEnabled: data.subwallet_transfer_enabled,
        subwalletType: data.subwallet_type
      })
    }
  }

  componentDidMount() {

    this.amIMounted = true;
    if (localStorage.getItem("jwtToken") !== "") {
      this.getBalance()
    }
  }

  componentWillUnmount() {
    this.amIMounted = false;
  }

  toggleTestMode = () => {
    this.setState({ testMode: !testMode });
    if (testMode) {
      window.location.href = ('/dashboard');
    } else {
      window.location.href = ('/test/dashboard');
    }
  }

  updateTitle = (title) => {
    this.setState({ title: title })
  }

  hoverState = (state) => {
    this.setState({ hover: state })
  }

  render() {
    const {
      userType,
      userKYCLevel,
      fname,
      hasMerchantBias,
      is_child,
      child_test_mode,
      endpoints,
      subuserRole,
      kybRequired,
      subwalletTransferEnabled,
      subwalletType
    } = this.state;

    const {
      collapsed, hovered, mouseState, exitMouseState, startDemo
    } = this.props;

    let collapsedState = !collapsed ? collapsed : startDemo ? false : !hovered
    let sg = this.state.segments
    let envelope = sg === 'me_' ? Icons.ME_Env :
      sg === 'gig' ? Icons.GIG_Env :
        sg === 'sme' ? Icons.SME_Env :
          sg === 'biz' ? Icons.BIZ_Env :
            Envelope

    return (
      <Sider trigger={null} className={`primarySideBar ${testMode ? "testModeBg" : ""}`} width={240} collapsible collapsed={collapsedState}
        onMouseEnter={() => mouseState()} onMouseLeave={() => exitMouseState()} style={{ boxShadow: hovered && collapsed ? "8px 0px 24px rgba(0, 0, 0, 0.12)" : "" }}>
        <div>
          {
            !['ME', 'CO', 'SW'].includes(userType) ?
              <AdminHeader datas={this.state} collapsed={collapsedState} />

              : userType === "CO" || (userType === 'SW' && subuserRole == 'CO') || (userType === 'SW' && ['CMP','BRN'].includes(subwalletType)) ?

                <CorporateHeader datas={this.state} collapsed={collapsedState} testMode={testMode} />

                : userType === "ME" || (userType === 'SW' && subuserRole == 'ME') || (userType === 'SW' && subwalletType === 'IND') ?

                  <MerchantHeader datas={this.state} collapsed={collapsedState} segments={this.state.segments} />

                  : ""
          }
        </div>

        <Divider style={{ padding: 1, background: '#E9EEF4', opacity: testMode ? 0.5 : '' }} />


        <div hidden={!this.state.doneFetch}>
          {
            !["CO", "ME", "SW"].includes(userType) ?

              <Admin
                endpoints={endpoints}
                collapsed={collapsedState}
                hoverState={this.hoverState}
                hover={this.state.hover}
                userType={userType}
              />

              :

              <Merchant endpoints={endpoints} userType={userType} userKYCLevel={userKYCLevel}
                testMode={testMode} sideBarUpdateState={sideBarUpdateState}
                custId={this.state.custId}
                isMultiwallet={this.state.isMultiwallet}
                getBalance={this.getBalance} updateTitle={this.updateTitle} hoverState={this.hoverState}
                hover={this.state.hover}
                collapsed={collapsedState}
                subuserRole={subuserRole}
                kybRequired={kybRequired} subwalletTransferEnabled={subwalletTransferEnabled} />
          }

        </div>

        <Divider style={{ padding: 1, background: '#E9EEF4', opacity: testMode ? 0.5 : '' }} />
        <TestModeToggle enabled={testMode} isHidden={userType !== 'CO' || hasMerchantBias || (is_child && !child_test_mode)} handleChange={this.toggleTestMode} collapsed={collapsedState} segments={this.state.accent} />

        <a href="mailto:support@bux.ph">
          <div className={`${testMode ? "test" : `bg-opaque-${this.state.segments}`} needHelpDiv `} hidden={userType !== "ME" && userType !== "CO" && userType != 'SW'} >
            <img src={envelope} alt="email" />
            <span style={{ marginLeft: '10px' }} hidden={collapsedState}>
              <span className={`needHelpText text-${this.state.segments}`}>Need Help?</span>
            </span>
          </div>
        </a>


        <Modal
          visible={this.state.inviModal}
          footer={null}
          style={{ opacity: '0', position: 'absolute', top: '-10000px' }}
          forceRender={true}
          maskClosable={false}
        >
        </Modal>
      </Sider>
    )
  }
}

export default connect(state => ({

  generateTooltip: state.tooltip.generateTooltip,
  testMode: state.tooltip.testMode,

}),
  dispatch => ({
    showGenerateTooltip() {
      dispatch(showGenerateTooltip())
    },
    setTestMode() {
      dispatch(setTestMode())
    },
  })
)(withStyles(useStyles)(SideBar));