import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import payoutStyles from "./payout_css";
import { history } from "../../../store/history";
import { Table, Pagination, Dropdown, Button, Icon, Menu, Modal, Input,DatePicker, Select, message } from "antd";
import { getPayouts } from "./payoutsAction";
import '../../../static/css/antd.css'
import axios from 'axios'
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { randomize } from '../../constants/constants'
import ExportCsvModal from './exportCsvModal';
import { payoutServices } from './payoutsService';
import ClearFilter from '../filter_btns/clear_filter_btn';

const Payout = props => {
  const dispatch = useDispatch();

  const payouts = useSelector(state =>
    state.payouts ? state.payouts.payouts : ''
  );

  const total = useSelector(state =>
    state.payouts.payouts.total ? state.payouts.payouts.total : 0
  );

  const total_payouts = useSelector(state =>
    state.payouts.payouts.total_payouts != null ? state.payouts.payouts.total_payouts : 0.00
  );

  const ButtonGroup = Button.Group;
  const {Option} = Select

  const [statusModal, setStatusModal] = React.useState(false);
  const [statusResponse, setStatusResponse] = React.useState([]);
  const [statusSelected, setStatusSelected] = React.useState("All");
  const [searchValue, setSearchValue] = React.useState("");
  const [summary, setSummary] = React.useState("Today");
  const [disabledBtn, setDisabledBtn] = React.useState(true);
  const [hideClear, setHideClear] = React.useState(true);
  
  const [startMoment, setStartMoment] = React.useState("")
  const [startDate, setStartDate] = React.useState("")

  const [endMoment, setEndMoment] = React.useState("")
  const [endDate, setEndDate] = React.useState("")

  const [endOpen, setEndOpen] = React.useState(false)

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10)
  const [range, setRange] = React.useState()
  const [totalPage, setTotalPage] = React.useState()

  const [selectedUserType, setSelectedUserType] = React.useState("All")
  const [selectedMode, setSelectedMode] = React.useState("All")
  const [showExportModal, setShowExportModal] = React.useState(false);
  const [email, setEmail] = React.useState(null);

  const segments = localStorage.getItem('segments');
  
  useEffect(() => {
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
      history.push('/login')
    }
    else{
      if(localStorage.getItem("userType") == "ME"){
        history.push('/dashboard')
      }
      else if(localStorage.getItem("userType") == "PS"){
        history.push('/orders')
      }
      else if(localStorage.getItem("userType") == "PF"){
        history.push('/recon/7_eleven')
      }
      else if(localStorage.getItem("userType") == "MA"){
        history.push('/overview')
      }
      else{
        dispatch(getPayouts(1, pageSize, "", "All",startDate,endDate, selectedMode, selectedUserType, summary));
      }

    }
    
  }, []);
  
  const status = [
    'All', 'Success', 'Pending', 'Failed'
  ]

  const pageSelect = (e) => {
    setPage(e)
    dispatch(getPayouts(e, pageSize, searchValue, statusSelected, startDate, endDate, selectedMode, selectedUserType, summary));
  }
 
  const payout = payoutStyles();

  const checkStatus = async(id) => {
    let yourConfig = {
      headers: {
         Authorization: "Token " + localStorage.getItem("jwtToken")
      }
      
    }
    let response = await axios.get(
      process.env.REACT_APP_API_URL + `/api/payouts/status/${id}/`,yourConfig
    );

    setStatusModal(true)
    setStatusResponse(JSON.stringify(response.data))

  }

  const menu = (id) => (
    <Menu style={{ width: '180px' }}>
      <Menu.Item key="0" className={payout.menuStyle} onClick={()=> checkStatus(id)}>
        Check status
      </Menu.Item>
    </Menu>
  );

  async function DownloadPayouts(){
      try {
        const res = await payoutServices.exportCsv(1, pageSize, searchValue, statusSelected, startDate, endDate, selectedMode, selectedUserType, summary, email, true);
        if(res.status === 'success'){
          message.success(res.message);
        } else {
          message.error(res.message);
        }
      } catch (error) {
        if(error.response.data && error.response.data.message){
          msgNotif(error.response.data.message);
        } else {
          msgNotif('Error try again');
      }
    }
  }

  const msgNotif = (msg) => {
    if( msg && msg.data && msg.data.status === "success") {
      message.success(msg.data.message);
    } else if(msg && msg.data && msg.data.status === "failed") {
      message.error(msg.data.message);
    } else {
      message.error(msg);
    }
  }

  const single_payouts = [
    {
      title: 'Trans ID',
      dataIndex: 'id',
      width: '6%',
      render: (text, record) => {
        return text
      }
    },
    {
      title: 'Destination',
      width: '8.5%',
      render: (text, record) => (
        <div className={payout.descSyle}>{record.bank}</div>
      )
    },
    {
      title: 'Account Details',
      width: '9%',
      render: (text, record) => (
        <div>
          <div className={payout.codeStyle}>{record.account_number}</div>
          <div className={payout.descSyle}>{record.account_name}</div>
        </div>
      )
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: '8%',
      render: text => {
        return <span className={payout.dataStyle}>{text}</span>;
      }
    },
    {
      title: 'Business Owner',
      width: '9%',
      render: (text, record) => (
        <div>
          <div className={payout.codeStyle}>{record.business_name}</div>
          <div className={payout.descSyle}>{record.owner}</div>
        </div>
      )
    },
    {
      title: 'Payment Date',
      dataIndex: 'date_paid',
      width: '8%',
      render: text => {
        return <span className={payout.dataStyle}>{text}</span>;
      }
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      width: '8%',
      render: text => {
        return <span className={payout.dataStyle}>{text}</span>;
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      width: '8%',
      render: (text, record) => (
        <div>
          <div>{record.mode}</div>
          <div className={
              record.status == "Success" ? payout.successDiv :
              record.status == "Pending" ? payout.pendingDiv : 
              record.status == "Failed" ? payout.failedDiv : ''}>
            {record.status}
          </div>
        </div>
      )
    },
    {
      title: 'Action',
      width: '8%',
      render: (record) => (
        <div>
        <Dropdown overlay={() => menu(record.id)} placement="bottomCenter" trigger={['click']} >
          <Icon type="ellipsis" />
        </Dropdown>
        </div>
      )
    }
  ];


  const disabledStartDate = startValue => {
      
    if (!startValue || !endMoment) {
      return false;
    }
    return startValue && startValue > moment().endOf('day');
  };

  const disabledEndDate = endValue => {
    
    if (!endValue || !startMoment) {
      return false;
    }
    return endValue.valueOf() <= startMoment.valueOf();
  };
  
  const onStartChange = value => {
    if(value != null){
      setStartMoment(value)
      setStartDate(value.format("MM-DD-YYYY"))
      setSummary('Custom')
      setDisabledBtn(false);
      setHideClear(false);

    }
    else{
      setStartMoment(null)
      setStartDate("")
    }
  }

  const onEndChange = value => {

    if(value != null && startDate != ""){
      setEndMoment(value)
      setEndDate(value.format("MM-DD-YYYY"))
      setDisabledBtn(false);
      setHideClear(false);

    }
    else{
      setEndMoment(null)
      setEndDate("");
      setDisabledBtn(false);
      setHideClear(false);

    }
  }

  const handleStartOpenChange = open => {
    if (!open) {
      setEndOpen(true)
    }
  };

  const handleEndOpenChange = open => {
    setEndOpen(open)
  };

  const clearFilter = () => {
    setStatusSelected("All")
    setSearchValue("")
    setStartMoment("")
    setEndMoment("")
    setSelectedMode("All")
    setSelectedUserType("All")
    setSummary("Today")
    setHideClear(true)
    dispatch(getPayouts(1, pageSize, "", "All","","", "All", "All", "Today"));

  }

  function GetData() {
    dispatch(getPayouts(1, pageSize, searchValue, statusSelected, startDate, endDate, selectedMode, selectedUserType, summary));
  }

  return (
    <div className={payout.body} hidden={payout == null}>
      <div className={payout.title}>All Payouts</div>
      <div className={payout.pillFlex}>
        <ButtonGroup>
            {
              status.map((item, i) => {
                return <Button
                  key={item.toLocaleLowerCase()}
                  onClick={() => { 
                    setStatusSelected(item); 
                    setPage(1);
                    setDisabledBtn(false);
                    dispatch(getPayouts(1, pageSize, searchValue, item, startDate, endDate, selectedMode, selectedUserType, summary));
                    }}
                  className={item === statusSelected ? `bg-${segments}` : ``}
                  style={{
                    padding: '12px 18px 13px 18px',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    lineHeight:'16px',
                    height:'40px',
                    // borderTopLeftRadius: item == "All" ? '10px' : '0px',
                    // borderBottomLeftRadius: item == "All" ? '10px' : '0px',
                    // borderTopRightRadius: item == "Failed" ? '10px' : '0px',
                    // borderBottomRightRadius: item == "Failed" ? '10px' : '0px',
                    color: statusSelected === item ? '#ffffff' : '#2B2D32',
                    backgroundColor: statusSelected === item ? '#0A315E' : '#FFF',
                    borderRadius: i === 0 ? '4px 0 0 4px' : status && i === status.length - 1 ? '0 4px 4px 0' : 0
                    // backgroundColor: statusSelected === item ? '#0D3D76' : '#fff', 
                  }}>{item}</Button>
              })
            }
        </ButtonGroup>
        
        <button className={payout.exportBtn}
              onClick={() => setShowExportModal(true)}>
            <div><Icon type="download" style={{paddingRight: 4}}/>Download CSV</div>
        </button>
      </div>

      <div className={payout.tableDiv}>
        <div className={payout.pillFlex}>
          <div style={{display: 'flex'}}>
            {/* <div style={{marginLeft: 10}} hidden={hideClear}>
              <button className={payout.clearBtn} onClick={clearFilter}><Icon type="close-circle" theme="filled" style={{paddingRight: 4}} /> Clear Filter</button>
            </div> */}
            <ClearFilter hideFilterBtn={hideClear} clearFilter={clearFilter} />
          </div>
          
          <div hidden>
            <div className={payout.noteStyle}><Icon type="info-circle" theme="filled" style={{paddingRight: 4}} />Click <b>Apply</b> button to show results.</div>
          </div>
        </div>

        <div style={{display:'flex'}}>
            <Select 
                size="large" 
                placeholder="Account Type" 
                style={{width: '120px', marginRight: '12px', zIndex: 1}}
                onChange={(e)=> {setSelectedUserType(e);
                  setDisabledBtn(false);
                  setHideClear(false);}}
                value={selectedUserType}
            >
                <Option value="All">All</Option>
                <Option value="ME_">BUxME</Option>
                <Option value="GIG" >BUxGIG</Option>
                <Option value="SME" >BUxSME</Option>
                <Option value="BIX" >BUxBIZ</Option>
                <Option value="SW" >Sub-Wallets</Option>
            </Select>
          {/* <Select 
            size="large" 
            className={payout.acSelectStyle}
            placeholder="Account Type" 
            onChange={(e)=>{
              setSelectedUserType(e);
              setDisabledBtn(false);
              setHideClear(false);

            }}
          >
            <Option value="All">All</Option>
            <Option value="ME">Individual</Option>
            <Option value="CO" >Enterprise</Option>
          </Select> */}

          <Select 
            size="large" 
            placeholder="Mode" 
            className={payout.acSelectStyle}
            onChange={(e)=> {
                setSelectedMode(e); 
                setDisabledBtn(false);
                setHideClear(false);

            }}
          >
            <Option value="All">All</Option>
            <Option value="EON">EON</Option>
            <Option value="Instapay" >Instapay</Option>
            <Option value="Pesonet">Pesonet</Option>
          </Select>

          <Select 
              size="large"
              value={summary}
              onChange={
                (e)=> {
                  setSummary(e);
                  setPage(1);
                  setDisabledBtn(false);
                  setHideClear(false);

                }
              }
              className={payout.acSelectStyle}
            >
              <Option value="All">All Time</Option>
              <Option value="Today">Today</Option>
              <Option value="Week" >Last 7 days</Option>
              <Option value="Month" >Last 30 days</Option>
              <Option value="Custom" >Custom</Option>
          </Select>

          <DatePicker
            className={payout.dataPickerStyle}
            disabledDate={disabledStartDate}
            size="large"
            format='MM-DD-YYYY'
            value={startMoment || null}
            placeholder="Start Date"
            onChange={onStartChange}
            onOpenChange={handleStartOpenChange}
          />

          <span style={{padding: '8px'}}>-</span> 

          <DatePicker
            className={payout.dataPickerStyle}
            style={{marginRight: '12px'}}
            disabledDate={disabledEndDate}
            size="large"
            format='MM-DD-YYYY'
            value={endMoment || null}
            placeholder="End Date"
            onChange={onEndChange}
            open={endOpen}
            onOpenChange={handleEndOpenChange}
          />

          <Input
          placeholder="Search"
          className={payout.searchStyle}
          onChange={(e)=> {setSearchValue(e.target.value); setDisabledBtn(false)}}
          prefix={<Icon type="search" />}
          />

          <button 
            className={disabledBtn ? payout.disabledBtn : payout.activeBtn}
            disabled={disabledBtn}
            style={{backgroundColor: '#0A315E', borderRadius: 4, height: 40, border: '1px solid #0A315E'}}
            onClick={GetData}>
            Apply
          </button>
        </div>
              
        <div className={payout.newSummaryDiv}>
          <div className={payout.totalDiv}>
            <div className={payout.sumSubDiv}>
              No. of Payouts: <span className={payout.sumSubValue}>{total} </span>
            </div> 
     
          </div>
          
          <div className={payout.totalDiv}>
            <div className={payout.sumSubDiv}>  
              Total Amount: <span className={payout.sumSubValue}>PHP {parseFloat(total_payouts).toLocaleString('en-US', {minimumFractionDigits: 2})} </span>
            </div> 
          </div>

        </div> 

        <Table 
        rowKey={record => record.id}
        columns={single_payouts}
        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        dataSource= {payouts.results}
        pagination={false} />

        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
          {/* <Pagination total={payouts.total} simple={true} onChange={pageSelect} defaultCurrent={1} key={Math.random()} pageSize={10} current={page}/> */}
          <div style={{width: '50%'}}>
            {totalPage ? `${range[0]}-${range[1]} out of ${totalPage}` : ''}
          </div>
          
          <div style={{width: '50%', display: 'flex', textAlign: 'right'}}>
            <Select
                showSearch
                onChange={(ev)=>{
                  setPageSize(ev);
                  dispatch(getPayouts(page, ev, searchValue, statusSelected, startDate, endDate, selectedMode, selectedUserType, summary));
                              }}
                placeholder="Page Size"
                style={{ width: '110px', marginBottom: '10px', marginLeft: 'auto', marginRight: '20px' }}
                size="small"
            >
                <Option key={1} value={5}>Show 5</Option>
                <Option key={2} value={10}>Show 10</Option>
                <Option key={3} value={20}>Show 20</Option>
                <Option key={4} value={50}>Show 50</Option>

            </Select>

            <Pagination  
              style={{position: 'relative'}}
              total={payouts.total} 
              onChange={pageSelect} 
              defaultCurrent={1} 
              pageSize={pageSize} current={page}
              size="small"
              showTotal={(total, range) => `${range[0]}-${range[1]} out of ${total}`}
              key={randomize()} 
            />
          </div>

        </div>
      </div>

      <Modal
        footer={null}
        visible={statusModal}
        onCancel={()=>setStatusModal(false)}
      > 
      {statusResponse}

      </Modal>
      <ExportCsvModal visible={showExportModal} onClose={() => setShowExportModal(false)} onSend={() => {DownloadPayouts(); setShowExportModal(false);}} onChange={(e) => setEmail(e) } />
    </div>
  );
};

export default Payout;
