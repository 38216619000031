import React,{} from 'react';
import visaCard from "../../static/images/bux_visa_card.svg";
import bg from '../../static/images/no_eon_bg.svg';
import { isMobile } from 'react-device-detect';
import { history } from '../../store/history';
import { Card, Typography, Button } from 'antd';

const EonCard = () => {
  return (
    <div style={styles.eonContainer}>
      <img alt='No Image' src={visaCard} style={{width: '100%'}} />
      <div style={styles.xxx}>
        xxxx xxxx xxxx
      </div>
  </div>
  )
}

const NoEon = () => {
  const segments = localStorage.getItem('segments');
  return (
    <Card style={styles.root} size='small' bodyStyle={styles.cardBody}>
      <div style={styles.content}>
        <EonCard />
        <Typography style={styles.msg}>
          Complete your profile to avail of an EON Visa Card and Bills Payment
        </Typography>
        <button className={`btn--${segments}`} style={{ ...styles.btn }} onClick={()=> window.location.href = '/complete_profile' }>Complete your Profile</button>
      </div>
    </Card>
  )
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: isMobile ? '100%' : 684,
    margin: '38px 0 0 0',
    borderRadius: 10,
    backgroundImage: `url(${bg})`,
    backgroundSize: 'content', 
    // backgroundPosition: 'center', 
    backgroundRepeat: 'no-repeat',
    padding: '42px 0'
  },
  cardBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  content: {
    width: 310,
  },
  btn: {
    margin: '8px 0',
    height: 40,
    width: '100%',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
  },
  cardNumber: {
    fontWeight: '600', 
    color: '#FFFFFF', 
    position: 'absolute', 
    transform: 'translate(-50%, -50%)',
    top: '75%', 
    left: '28%'
  },
  msg: {
    textAlign: 'center',
    padding: '16px 0',
    color: '#000000',
    fontSize: 16
  },
  eonContainer: {
    width: '100%', 
    position: 'relative',
    padding: '0 0 10px 0'
  },
  xxx: {
    position: 'absolute', 
    color: '#FFF',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    top: '75%', 
    left: '37%', 
    fontSize: 16, 
    fontWeight: 'bold', 
    textAlign: 'left'
  }
};

export default NoEon;