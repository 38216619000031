import React from 'react';
import { useSelector, useDispatch } from '../../../../__test__/react-redux-hooks';
import { CSVReader } from 'react-papaparse'
import moment from 'moment';
import multiwalletActions from '../../multiwalletActions';
import { Modal, Typography, Button, Upload, Icon, Affix, Alert, message } from 'antd';
import multiwalletServices from '../../multiwalletServices';
import { segmentStroke } from '../../../constants/constants';

const UploadCsvModal = ({ segments, visible, closeModal, exportTemplate, closeLinkModal, replace, replaceId, onSuccess, onConfirm, csvTemplate }) => {
  const [fileUploaded, setFileUploaded] = React.useState(false);
  const buttonRef = React.createRef();
  const [uploadNotif, setUploadNotif] = React.useState(null);
  const [chooseBtnStyle, setChoosdeBtnStyle] = React.useState({ color: segmentStroke[0], backgroundColor: '#FFF' });
  const [cancelBtnStyle, setCancelBtnStyle] = React.useState({ color: '#F5922F', backgroundColor: '#FFF' });
  const [csv, setCsv] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const testMode = (window.location.pathname.indexOf('/test') > -1 && localStorage.getItem("userType") == "CO");
  const transferMoneyData = useSelector(state => state.multiwallet.transferMoneyData);
  const [displayData, setDisplayData] = React.useState(null);
  const [dataTable, setDataTable] = React.useState(null);


  const definedWalletSettings = JSON.parse(
    localStorage.getItem("defined_wallet_settings")
  );
  const { wallet_transfer_id_enabled: walletTransferIdEnabled = false } =
    definedWalletSettings;

  const sampleData = [
    {
      batch_code: 'C001',
      num_req: 6,
      date_upload: moment().format(),
    },
    {
      batch_code: 'C002',
      num_req: 10,
      date_upload: moment().format(),
    },
    {
      batch_code: 'C003',
      num_req: 24,
      date_upload: moment().format(),
    }
  ]

  const handleOpenDialog = (e, data) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e)
    }
  };

  const uploadCsv = async (csv) => {
    setLoading(true);
    // try {
    //   const res = await batchUploadServices.uploadCsv(csv, testMode);
    //   if(res.status === 'success') {
    //     onSuccess();
    //     closeModal();
    //   } else {
    //     setUploadNotif(res.message);
    //     resetProps();
    //   }
    //   setLoading(false);
    // } catch (error) {
    //   console.log(error);
    //   setLoading(false);
    //   setUploadNotif(error);
    //   resetProps();
    // }
  }

  const replaceCsv = async (csv) => {
    setLoading(true);
    // try {
    //   const res = await batchUploadServices.replaceCsv(replaceId, csv, testMode);
    //   if(res.status === 'success') {
    //     onSuccess();
    //     closeModal();
    //   } else {
    //     setUploadNotif(res.message);
    //     resetProps();
    //   }
    //   setLoading(false);
    // } catch (error) {
    //   console.log(error);
    //   setLoading(false);
    //   setUploadNotif(error);
    //   resetProps();
    // }
  }

  const handleUpload = async (results, data) => {
    setFileUploaded(true);

    // if(data.type !== 'text/csv') {
    //   setUploadNotif('File type must be in a csv format');
    //   resetProps();
    // } else {
    setCsv(data);
    dispatch(multiwalletActions.setTransferMoneyData(results));
    setDataTable(results);
    validateCsv(results);
    // }
  }

  const flatData = (data) =>
    data && Object.keys(data).map((key, i) => {
      return {
        full_name: data[i]["data"][csvTemplate['full_name']],
        email: data[i]["data"][csvTemplate['email']],
        contact: data[i]["data"][csvTemplate['contact']],
        amount: data[i]["data"][csvTemplate['amount']],
        ...(walletTransferIdEnabled && {
          transfer_id: data[i]["data"][csvTemplate['transfer_id']],
        }),
      };
    });

  const validateCsv = async (data) => {
    try {
      const res = await multiwalletServices.validateTransferMoneyCsv(testMode, flatData(data))
      arrangeData(res, flatData(data));
      if(res.status==='error'){
        const excludeHeaders = ['id','merchant_id', 'created_at','modified_at'];
        const headers = Object.entries(csvTemplate)
          .filter(([key, _]) => !excludeHeaders.includes(key))
          .filter(([key, _]) => key !== 'transfer_id' || walletTransferIdEnabled)
          .map(([_, newKey]) => newKey)
          .join(", ");
        message.error(`CSV Template must contain the following headers: ${headers}`, 15);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const arrangeData = (res, data) => {
    let goodData = new Array;
    res && res.errors && data.map((arr, i) => {
      arr['remarks'] = res.errors[i][0];
      goodData.push(arr);
    });
    setDisplayData(goodData);
  }

  if (uploadNotif) {
    setTimeout(() => {
      setUploadNotif(null);
    }, 5000);
  }

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e)
    }
  }

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err, file, inputElem, reason);
    setUploadNotif(reason);
  }

  const confirmUpload = () => {
    // if(replace) {
    //   replaceCsv(csv);
    // } else {
    //   uploadCsv(csv);     
    // }
    onConfirm(displayData);

  }

  const resetProps = () => {
    setFileUploaded(false);
  }

  const chooseBtnHover = () => {
    setChoosdeBtnStyle({
      color: '#FFF',
      backgroundColor: segmentStroke[0],
    })
  }

  const chooseBtnLeave = () => {
    setChoosdeBtnStyle({
      color: segmentStroke[0],
      backgroundColor: '#FFF',
    })
  }

  const cancelBtnHover = () => {
    setCancelBtnStyle({
      color: '#FFF',
      backgroundColor: '#F5922F',
    })
  }

  const cancelBtnLeave = () => {
    setCancelBtnStyle({
      color: '#F5922F',
      backgroundColor: '#FFF',
    })
  }

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={closeModal}
      destroyOnClose
      afterClose={resetProps}
      width={392}
      centered
      closeIcon={<Icon type="close" className={`text-${segments}`} />}
      bodyStyle={styles.root}>
      <div hidden={!uploadNotif} style={{ paddingTop: 32 }}><Alert message={uploadNotif} type="error" showIcon /></div>
      <Typography style={styles.title}>Upload your CSV File</Typography>
      <Typography style={{ ...styles.normalFont, width: '80%' }}>Upload the CSV file with the information you want to import here.</Typography>
      <CSVReader
        ref={buttonRef}
        onError={handleOnError}
        noClick
        noDrag
        progressBarColor='#1DD28B'
        onFileLoad={(results, file) => handleUpload(results, file)}
        onRemoveFile={() => setFileUploaded(false)}
        config={{ header: true, skipEmptyLines: true }}
        noProgressBar={uploadNotif}
      >
        {({ file }) => (
          <div style={{ padding: '32px 0', position: 'relative' }}>
            <a href
              onClick={(e, file) => handleOpenDialog(e, file)}>
                  <button className={`${file && fileUploaded ? `outline-btn--${segments}` : `btn--${segments}`} btn-height`}>
                      {
                          file && fileUploaded ? 
                          <div>
                              <span className="right-32">{file.name}</span>
                              <a href style={styles.removeIcon} 
                                  onClick={resetProps}>
                                      <Icon type="close-circle" 
                                      heme="filled" 
                                      style={{ color: '#909196', fontSize: 28}} 
                                      bodyStyle={{ backgroundColor: '#FFF' }} />
                              </a>
                          </div> : 
                          <div>
                              <Icon type="download" className="right-4" />
                              Choose file
                          </div>
                      }
                  </button>
              </a>
          </div>
        )}
      </CSVReader>
      <Typography style={styles.normalFont}>Don't have a file?</Typography>
      <a className={`text-${segments} wc-header top-12`} onClick={exportTemplate} >Download CSV Template</a>
      <div style={styles.footerBtn} className="top-16">
        <button className={`btn--${segments} btn-height btn-mobile-width`} disabled={!fileUploaded} onClick={confirmUpload} loading={loading}>
          Confirm
        </button>
      </div>
    </Modal>
  )
}

const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmBtn: {
    height: 40,
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 4,
    width: '100%',
  },
  title: {
    fontSize: 24,
    fontWeight: '300',
    color: '#000',
    padding: '40px 0 8px 0'
  },
  normalFont: {
    fontSize: 16,
    color: '#000',
    textAlign: 'center',
  },
  link: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#F5922F',
    marginBottom: 36,
  },
  chooseBtn: {
    border: `1px solid ${segmentStroke[0]}`,
    fontSize: 12,
    borderRadius: 80,
    display: 'flex',
    alignItems: 'center',
    height: 40,
    fontWeight: '600'
  },
  cancelBtn: {
    width: '48%',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    height: 48,
    border: '1px solid #F5922F',
  },
  footerBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  removeIcon: {
    position: 'absolute',
    color: '#FFF',
    transform: 'translate(-50%, -50%)',
    right: '-2%',
    top: '51%'
  }
}

export default UploadCsvModal;