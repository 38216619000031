import React,{} from 'react'
import {Card, Button, Select} from 'antd'
import FacebookIcon from '../../../static/icons/facebook_icon.svg'
import InstagramIcon from '../../../static/icons/instagram_icon.svg'
import * as Style from './kyc_style'
import {isMobile} from 'react-device-detect'
import {submitDetails,setStep} from './application_main'
import axios from "axios";
import {NATURE_BUSINESS} from '../../constants/constants'
import { Handle401 } from "../../handle401/handle401";

const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
const { Option } = Select;

const BusinessInfo = props => {

    const segments = localStorage.getItem("segments")
    const color = segments == 'me_' ? '#0DAED0' : segments == 'gig' ? '#41BD5C' : '#F5922F'
    const excludedIndustries = [
        'Banking',
        'Trust Entities',
        'Cryptocurrency',
        'Forex',
        'Medical Practitioners'
    ]
    const [selected, setSelected] = React.useState("")
    
    const [businessName,setBusinessName] = React.useState(props.details.business_details.name)
    const [natureBusiness,setNatureBusiness] = React.useState(props.details.business_details.industry)
    const [industry, setIndustry] = React.useState(0);
    const [subCategory, setSubCategory] = React.useState(props.details.business_details.sub_category)
    const [fbPage,setFbPage] = React.useState(props.details.business_details.fb)
    const [igPage,setIgPage] = React.useState(props.details.business_details.ig)
    const [businessWeb,setBusinessWeb] = React.useState(props.details.business_details.web)
    
    const [hover,setHover] = React.useState(false)

    const [loading, setLoading] = React.useState(false)
    const [errorAlert, setErrorAlert] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const [industries, setIndustries] = React.useState([]);
    const [subCategories, setSubCategories] = React.useState([]);
    const [currentSubCategory, setCurrentSubCategory] = React.useState([]);
    const [disableSubCategories, setDisabledSubCategories] = React.useState(false);

    async function checkDetails(){
        setLoading(true)

        let details = {
            business_name: businessName,
            business_industry: natureBusiness,
            industry: industry,
            sub_category: subCategory,
            business_web: businessWeb,
            business_fb: fbPage,
            business_ig: igPage,
            step: 5
        }

        if(props.testMode) {
            props.fakeKycDetails(6)
            window.scrollTo(0, 0);
            setLoading(false)
        }

        else {
            const siteRegex = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
            const facebookRegex = new RegExp(/^(ftp|http|https):\/\/(www\.)?facebook\.com\/[^ "]+$/);

            if(!businessName){
                setLoading(false);
                setErrorAlert(true);
                setErrorMessage("Business name is required.")
                return;
            }
            if(!industry && industry!==0) {
                setLoading(false);
                setErrorAlert(true);
                setErrorMessage("Please select an industry.")
                return;
            }
            if(!disableSubCategories && (!subCategory && subCategory!==0) && currentSubCategory.length > 0) {
                setLoading(false);
                setErrorAlert(true);
                setErrorMessage("Please select a sub-category.")
                return;
            }
            if (!businessWeb) {
                setLoading(false);
                setErrorAlert(true);
                setErrorMessage("Website URL is required.");
                return;
            } else if (!siteRegex.test(businessWeb)) {
                setLoading(false);
                setErrorAlert(true);
                setErrorMessage("Valid website URL is required. Make sure to include 'http://' or 'https://'.");
                return;
            }
    
            if (!fbPage) {
                setLoading(false);
                setErrorAlert(true);
                setErrorMessage("Facebook URL is required.");
                return;
            } else if (!facebookRegex.test(fbPage)) {
                setLoading(false);
                setErrorAlert(true);
                setErrorMessage("Valid Facebook URL is required. Make sure to include 'http://' or 'https://' and profile link (e.g. https://facebook.com/myprofile).");
                return;
            }
            let response = await submitDetails(details)
            if(response == 'success'){
                setLoading(false)
            } else {
                setLoading(false);
                setErrorAlert(true);
                setErrorMessage(response);
            }
        }
   
    }

    const fetchGCashSubMidData = async () => {
        let yourConfig = {
          headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken"),
          },
        };
        try {
          let response = await axios.get(
            process.env.REACT_APP_API_URL + "/api/gcash_submid/",
            yourConfig
          );
    
          if (response.data.status == "success") {
            setIndustries(response.data.data.industries)
            setSubCategories(response.data.data.sub_categories)
            setFbPage(response.data.data.facebook)
            setBusinessWeb(response.data.data.website)
            setIgPage(response.data.data.instagram)
            setIndustry(response.data.data.industry)
            setSubCategory(response.data.data.sub_category)

            if(response.data.data.sub_category>=0) {
                setDisabledSubCategories(false);
                setCurrentSubCategory(response.data.data.sub_categories[response.data.data.industry]);
            } else{
                setDisabledSubCategories(true);
            }
          } else {
            return false;
          }
        } catch (error) {
          if (error.response && error.response.status == 401) {
            Handle401();
          }
        }
    }

    const onChangeIndustry = (value) => {
        setIndustry(value);
        if (subCategories[value] === undefined || subCategories[value].length == 0) {
            setDisabledSubCategories(true);
            setCurrentSubCategory(subCategories[value]);
            setSubCategory(null);
        } else {
            setDisabledSubCategories(false);
            setCurrentSubCategory(subCategories[value]);
            setSubCategory(0);
        }
    };

    const onChangeSubCategory = (value) => {
        setSubCategory(value);
    }

    React.useEffect(()=>{
        fetchGCashSubMidData();
    },[]) 

    const sortedIndustries = industries.map((industry, index) => ({ label: industry, value: index })).sort((a, b) => a.label.localeCompare(b.label));
    const filteredIndustries = sortedIndustries.filter((sortedIndustries) => !excludedIndustries.includes(sortedIndustries.label) || sortedIndustries.value === industry)

    return(
        <div>
            <Card 
                style={Style.formCardStyle}
                bodyStyle={{padding: isMobile ? '12px 16px' : '32px'}}>
                
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        {/* <div style={{...Style.normalText, color: '#000'}}>Step 5 of 5</div> */}
                        <div hidden={isMobile} style={{...Style.headerStyle, fontSize: isMobile ? '16px' : '20px'}}>Business Information</div>
                    </div>
                    <div hidden={props.details.step != 6 && props.details.step != 9}>
                    {
                         isMobile ? 
                         <div style={{...Style.boldText, color: '#F5922F'}} onClick={()=>setStep(6)}>Cancel</div>
                         :
                         <button 
                            onClick={()=>setStep(6)}                            
                            className={`outline-btn--${segments} btn-height`}> Cancel</button>
                    }
                    </div>
                </div>
                <div
                    style={{
                        backgroundColor: 'rgba(226, 76, 76, 0.1)',
                        borderLeft: '4px solid #E24C4C',
                        width: '100%',
                        height: '100%',
                        borderRadius: '4px',
                        marginTop: '20px',
                        textAlign: 'center',
                        padding: '10px 30px',
                        display: errorAlert ? "block" : "none"
                    }}>
                    <span style={Style.normalText}>{errorMessage}</span>
                </div>
                <div style={{marginTop: '20px'}}>
                    <div style={{width: '100%'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Business Name (Required)</div>
                        <input 
                            data-test="input-business-name"
                            style={{...Style.inputStyle, 
                                border: selected === "business_name" ? `1px solid ${color}` : '1px solid #D1D5DD',
                                padding: '10px'}}
                            placeholder="Business Name"
                            value={businessName}
                            onChange={(e)=>setBusinessName(e.target.value)}
                            onSelect={()=>setSelected("business_name")}
                            onMouseLeave={()=>setSelected("")}/>
                        
                    </div>
                </div>
                <div style={{marginTop: '20px'}}>
                    <div style={{width: '100%'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Industry (Required)</div>
                        <Select 
                            size="large" 
                            style={{...Style.selectStyle}}
                            defaultValue={industry ? industry : 0}
                            value={industry}
                            onChange={onChangeIndustry}>
                            {
                            filteredIndustries.map((industry, index)=>{
                                return (
                                <Option value={industry.value}>{industry.label}</Option>
                                )
                            })
                            }
                        </Select>
                    </div>
                </div>
                <div style={{marginTop: '20px'}}>
                    <div style={{width: '100%'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Sub Category {currentSubCategory.length === 0 ? "" : "(Required)"}</div>
                        <Select 
                            size="large"
                            style={{...Style.selectStyle}}
                            disabled={disableSubCategories || currentSubCategory.length === 0}
                            value={disableSubCategories ? null : subCategory}
                            onChange={onChangeSubCategory}>
                            {
                            currentSubCategory.map((sub_category, index) => {
                                return (
                                    <Option value={index}>{sub_category}</Option>
                                )
                            })
                            }
                        </Select>
                    </div>
                </div>
                <div style={{marginTop: '20px'}}>
                    <div style={{width: '100%'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Website (Required)</div>
                        <input 
                            data-test='input-business-web'
                            style={{...Style.inputStyle, 
                                border: selected === "website" ? `1px solid ${color}` : '1px solid #D1D5DD',
                                padding: '10px'}}
                            placeholder="https://www.sample.com"
                            value={businessWeb}
                            onChange={(e)=>setBusinessWeb(e.target.value)}
                            onSelect={()=>setSelected("website")}
                            onMouseLeave={()=>setSelected("")}/>
                        
                    </div>
                </div>
                <div style={{marginTop: '20px'}}>
                    <div style={{width: '100%',}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Facebook (Required)</div>
                        <div style={{display:'flex'}}>
                            <input 
                                data-test='input-business-fb'
                                value={fbPage}
                                style={{...Style.inputStyle, 
                                    border: selected === "facebook" ? `1px solid ${color}` : '1px solid #D1D5DD',
                                    padding: '10px'}} 
                                placeholder="https://facebook.com/sample"
                                onSelect={()=>setSelected("facebook")}
                                onChange={(e)=>setFbPage(e.target.value)}
                                onMouseLeave={()=>setSelected("")}/>
                        </div>
                    </div>                
                </div>
                <div style={{margin: '20px 0px 20px 0px'}}>
                    <div style={{width: '100%'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Instagram (Optional)</div>
                        <div style={{display:'flex'}}>
                            <input 
                                data-test='input-business-ig'
                                value={igPage}
                                style={{...Style.inputStyle, 
                                    border: selected === "ig" ? `1px solid ${color}` : '1px solid #D1D5DD',
                                    padding: '10px'}} 
                                placeholder="https://instagram.com/sample"
                                onSelect={()=>setSelected("ig")}
                                onChange={(e)=>setIgPage(e.target.value)}
                                onMouseLeave={()=>setSelected("")}/>
                        </div>
                    </div>
                </div>

                <div style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}} hidden={isMobile}>
                    <div style={{...Style.backToDashboard, margin: '0px'}}> <a className={`text-${segments}`} onClick={()=>setStep(4)}>Back</a></div>
                    <Button 
                        data-test='button-submit'
                        loading={loading}
                        style={Style.submitBtnStyle
                    }
                        onClick={()=>checkDetails()} 
                        hidden={isMobile}
                    >
                        <div style={{...Style.boldText, color: "#fff", display: 'inline-block', marginLeft: loading ? '20px' : '0px'}}>Submit and next</div>
                    </Button>
                </div>
            </Card>

            <div style={{
                width: '100%', 
                position: 'fixed', 
                display: 'flex',
                backgroundColor:'#fff',
                height: '74px',
                bottom: '0px',
                marginLeft: '-16px',
                padding: '10px 20px 10px 20px',
                justifyContent: 'space-between',
                alignItems: 'center',
            }} hidden={!isMobile}>
                <button onClick={()=>setStep(4)} style={Style.backBtnMobile} className={`outline-btn--${segments}`}>
                    Back
                </button>
                <Button 
                    loading={loading}
                    style={Style.submitBtnStyle}
                    onClick={()=>checkDetails()}>
                    <div style={{...Style.boldText, color: "#fff", display: 'inline-block', marginLeft: loading ? '20px' : '0px'}}>Submit and review</div>
                </Button>
            </div>
        </div>
    )
}

export default BusinessInfo