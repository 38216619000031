import React, { useEffect, useState } from "react";
import {Alert} from '@material-ui/lab/'
import { Layout,Button,Dropdown,Menu,Icon, Badge, Divider} from "antd";
import {FAQ, Terms, How, Pricing, Logout} from '../../static/icons/options/index'
import { history } from "./../../store/history";
import BuxIcon from '../../static/icons/bux_logo.svg'
import axios from "axios";
import { NotifBell } from '../../static/icons/notification_icons';
import NotificationDropdown from '../../components/notification/header_dropdown';
import notificationServices from '../../components/notification/notificationServices';
import notificationActions from '../../components/notification/notificationActions';
import { useDispatch, useSelector } from "react-redux";
import * as Style from './mobAppBarStyle'

const testMode = (history.location.pathname.indexOf('/test') > -1&&localStorage.getItem("userType") == "CO");


const MobileAppBar = props => {

  const userType = localStorage.getItem("userType")

  const { Header } = Layout;

  const notifCount = useSelector(state => state.notification.notificationCount)
  const dispatch = useDispatch();

  const disablePaymentLink = userType == "CO" && localStorage.getItem("Level") != 5 && !testMode || localStorage.getItem("userType") == "CO" && localStorage.getItem("KYBStep") == 10 && !testMode;
  const has_merchant_bias = localStorage.getItem("merchant_bias") === "true";
  const isAlias = localStorage.getItem("is_child") === "true";
  const [canAliasCreatePaymentLink, setCanAliasCreatePaymentLink] = useState(true);
  const segments = localStorage.getItem('segments');

  async function logout() {
    try {
      const yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
       }
  
      let response = await axios.get(
        process.env.REACT_APP_API_URL + "/api/logout/",
        yourConfig
      );

      localStorage.setItem("userId", "");
      localStorage.setItem("jwtToken", "");
      localStorage.setItem("firstName", "");
      localStorage.setItem("lastName", "");
      localStorage.setItem("middleName", "");
      localStorage.setItem("suffix", "");
      localStorage.setItem("username", "");
      localStorage.setItem("email", "");
      localStorage.setItem("contact", "");
      localStorage.setItem("userType", "");
      localStorage.setItem("isCompleted", "");
      localStorage.setItem("isVerified", "");
      localStorage.setItem("Step", "")
      localStorage.setItem("Level", "")
      localStorage.setItem("lastLogin", "")
      localStorage.setItem("shipping_enabled", "")
      localStorage.setItem("isWaive", "")
      localStorage.setItem("changePw", "")
      localStorage.setItem("expiryDate", "");
      localStorage.setItem("is_child", "");
      localStorage.setItem("isAlias", "");
      
      history.push("/mobile/login");
    } 
    catch (error) {
      localStorage.setItem("userId", "");
      localStorage.setItem("jwtToken", "");
      localStorage.setItem("firstName", "");
      localStorage.setItem("lastName", "");
      localStorage.setItem("middleName", "");
      localStorage.setItem("suffix", "");
      localStorage.setItem("username", "");
      localStorage.setItem("email", "");
      localStorage.setItem("contact", "");
      localStorage.setItem("userType", "");
      localStorage.setItem("isCompleted", "");
      localStorage.setItem("isVerified", "");
      localStorage.setItem("Step", "")
      localStorage.setItem("Level", "")
      localStorage.setItem("lastLogin", "")
      localStorage.setItem("shipping_enabled", "")
      localStorage.setItem("isWaive", "")
      localStorage.setItem("changePw", "")
      localStorage.setItem("expiryDate", "");
      localStorage.setItem("is_child", "");
      localStorage.setItem("isAlias", "");
      
      history.push("/mobile/login");
    }
    
  }



  const getNotifCount = async (params) => {
    try {
     const response = await notificationServices.getNotifications(params);
     dispatch(notificationActions.setNotificationCount(response))
     
    } catch (error) {
      return(
        <Alert severity="error">{error}</Alert>
      )
    }
   }

   async function genericAxiosRequest(http_method, target_url, params=undefined) {
    try {
        let response = await axios({
            method: http_method,
            url: process.env.REACT_APP_API_URL + target_url,
            data: params,
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        });
        return response.data;

    } catch (error) {
        if (error.response) {
            if (error.response.status === 401) {
                logout();
            } else {
                throw new Error(error.response.data.message);
            }
        } else {
            throw new Error('No response from backend server!');
        }
    }
  }


  async function checkCanAliasCreatePaymentLink() {
      const setting_name = 'payment_link_creation'
      const endpoint_url = `/api/enterprise/alias/settings/?name=${setting_name}`;

      try {
          let response = await genericAxiosRequest('get', endpoint_url);
          if (response.status === 'failed') {
              throw new Error(response.message);
          }
          setCanAliasCreatePaymentLink(response.message);

      } catch (err) {
          // do nothing
      }
  }


  useEffect(() => {
    getNotifCount({target: 'count'});

    if (isAlias) {
      checkCanAliasCreatePaymentLink();
    }
  }, []);


  const menu = (
    <Menu  style={{width: '180px', marginRight:'10px', padding: '5px'}}>
      <Menu.Item style={Style.menuStyle} onClick={ () => window.location.href = 'https://ubx-bux.zendesk.com/hc/en-us'}>
        <img src={FAQ} alt="FAQ" style={{marginRight: '4px'}}/> FAQ
      </Menu.Item>
      <Menu.Item style={Style.menuStyle} onClick={ () => history.push('/terms_and_privacy')}>
        <img src={Terms} alt="Terms Icon" style={{marginRight: '4px'}}/> Terms and Policy
      </Menu.Item>
      <Menu.Item style={Style.menuStyle} hidden={userType === "SW"} 
            // onClick={()=>{
            //     dispatch(tutorialActions.setStartDemo(true));
            //     dispatch(tutorialActions.setTutorialStartModal(true));}
            //     }
            onClick={() => history.push('/mobile/how_it_works')}
            >
            <img alt="How Icon" src={How} style={{marginRight: '4px'}}/> How it works?
            </Menu.Item>
      <Menu.Item style={Style.menuStyle} onClick={ () => history.push('/pricing')}>
        <img src={Pricing} alt="Pricing Icon" style={{marginRight: '4px'}}/> Pricing Table
      </Menu.Item>
      <Divider style={{margin: '10px 0px 5px 0px'}}/>
      <Menu.Item style={Style.menuStyle} onClick={ () => logout()}>
        <img src={Logout} alt="Logout Icon" style={{marginRight: '8px'}}/>Logout
      </Menu.Item>
    </Menu>
  );

  let pathname_list = [
    '/mobile/dashboard',
    '/mobile/orders',
    '/funds_enterprise',
    '/mobile/funds' ,
    '/mobile/payout',
    '/payout_schedule',
    '/mobile/more',
    '/mobile/wallet',
    '/mobile/multiwallet',
  ]

  const pathname = window.location.pathname;

  const {
    title, subMenu, setTitleBlank
  } = props

  return (
    <div>
    <Header
      style={{
        ...Style.HeaderStyle,
        boxShadow: pathname !== '/mobile/dashboard' ||
                    pathname !== '/mobile/wallet' ||  
                    pathname !== '/mobile/card' || 
                    pathname !== '/mobile/orders' && "0px 3px 5px rgba(57,63,72,0.3)",
        borderBottom: pathname == '/mobile/dashboard' ||
                      pathname == '/mobile/wallet' || 
                      pathname == '/mobile/card' ||
                      pathname == '/mobile/orders' ? '1px solid #FFFFFF' : null
      }}
    >
      <div style={{marginLeft: 16}} hidden={pathname_list.includes(pathname)}>
        <a onClick={() => {
            if (pathname === "/payment_request/mobile/how_to_use") {
                history.push('/orders');
              return;
            }

            if (pathname === "/payout/mobile/how_to_use") {
              history.push('/mobile/funds');
            return;
            }

            if (has_merchant_bias) {
              history.push('/mobile/orders');
              return;
            }

            title == "Settings" && subMenu ? setTitleBlank() :
            history.push( '/mobile/more')
          }}>

          <Icon type="arrow-left" style={Style.iconleftStyle} className={`link-text--${segments}`} /> 
          <span style={{...Style.titleStyle, color: subMenu ? '#909196' : '#0d3d76'}}>{title} <span style={Style.titleStyle} hidden={!subMenu}>
              / {subMenu}
            </span>
          </span>

        </a>
      </div>

      <div style={!canAliasCreatePaymentLink || has_merchant_bias ? {display: 'flex', justifyContent: 'space-between'} : Style.TwoColumn}
           hidden={!pathname_list.includes(pathname)}>
        <img src={BuxIcon} alt="Bux Logo" style={{ marginLeft: '20px', marginRight: '20px' }} onClick={() => history.push("/mobile/dashboard")}/>
        
        <div style={{ display: "flex" }}>
          <div>
            <Button
              disabled={disablePaymentLink}
              style={{
                ...Style.GenerateBtn,
                cursor: disablePaymentLink ? 'not-allowed' : 'pointer',
                opacity:  disablePaymentLink ? '0.5' : 1,
              }}
              hidden={!canAliasCreatePaymentLink || has_merchant_bias || userType == 'SW'}
            >
              <div
                style={Style.textStyle}
                onClick={() => history.push("/mobile/generate_code")}
              >
                Generate Link
              </div>
            </Button>      
          </div>

          <div style={{ display:'flex', paddingRight: 20}}>
              <div style={{marginRight: 10}}>
                <Dropdown 
                    getPopupContainer={triggerNode => triggerNode.parentNode } 
                    overlay={<NotificationDropdown />} 
                    placement="bottomCenter" 
                    trigger={['click']} >
                      <Badge count={notifCount}>
                    {/* <Icon type="bell" style={{fontSize: '18px'}}/> */}
                      <a><img src={NotifBell} alt="Bell" /></a>
                    </Badge>
                </Dropdown>
              </div>

              <Dropdown
                getPopupContainer={triggerNode => triggerNode.parentNode }
                overlay={menu}
                placement="bottomCenter"
                trigger={["click"]}
              >
                <Icon
                  type="caret-down"
                  style={{ margin: "25px 10px 0px 10px"}}
                />
              </Dropdown>
          </div>
        </div>
      </div>
    </Header>

    </div>
  );
};

export default MobileAppBar;
