import { GetUsersActionTypes } from "./usersAction";

const initialState = {
  users: [],
  loading: false,
  error: ""
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case GetUsersActionTypes.GET_USERS_REQUESTING:
      return {
        ...state,
        loading: true
      };

    case GetUsersActionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload
      };

    case GetUsersActionTypes.GET_USERS_FAILED:
      return {
        ...state,
        loading: false,
        users: [],
        error: action.payload
      };

    default:
      return initialState;
  }
};