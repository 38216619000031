import React from 'react'

//Material UI
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

//Other imports
import { Button, Divider, Alert, Modal } from 'antd';
import { isMobile } from 'react-device-detect'
import { Handle401 } from '../../../components/handle401/handle401';
import axios from 'axios'

//Icons
import Invalid from '../../../static/icons/invalid_icon.svg'
import Valid from '../../../static/icons/valid_icon.svg'
import {Reminder} from '../../../static/icons/index';
import { PasswordChange, ConvertWelcomeBanner } from '../../../static/icons/index'
import expiryStyle from '../../../static/css/Expiry';


const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;

const UpdatePassword = () => {

    //LocalStorage

    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    const userName = localStorage.getItem('username')
    const userType = localStorage.getItem('userType')

    const fullName = userType == 'ME' ? firstName+' '+lastName : userName

    const [hideDiv, setHideDiv] = React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false)
    const [password, setPassword] = React.useState("")
    const [strengthLevel, setStrengthLevel] = React.useState(0)
    const [isPasswordValid, setIsPasswordValid] = React.useState(false)
    const [alert, setAlert] = React.useState(true);
    const [message, setMessage] = React.useState("");
    const [status, setStatus] = React.useState("");
    const [showSpin, setShowSpin] = React.useState(false)

    const [hasEnoughCharacter, setHasEnoughCharacter] = React.useState(false)
    const [hasCapital, setHasCapital] = React.useState(false)
    const [hasNumber, setHasNumber] = React.useState(false)
    const [hasSpecialChar, setHasSpecialChar] = React.useState(false)
    const [hasSpace, setHasSpace] = React.useState(false)

    const [showLoginModal, setShowLoginModal] = React.useState(false)
    const [isWaive, setIsWaive] = React.useState()
    const [passwordExpiry, setPasswordExpiry] = React.useState()

    const es = expiryStyle();

    const modalMessage = 
        isWaive === 1 ?
            `
            You have waived changing your password. 
            We will remind you to update your password again on ${passwordExpiry}. You can also change your password through Settings > My Profile > Password > Change.
            `
            :

            `
            Your password has been changed successfully. 
            You can also change your password anytime through Settings > My Profile > Password > Change.    
            `


    const useStyles = makeStyles((theme) => ({
        root: {
          "& .MuiOutlinedInput-root": {
            '& fieldset': {
                borderColor: 
                strengthLevel <= 0 ? '#3A73CC' :
                strengthLevel > 0 && strengthLevel <= 2 ? '#C0492C' :
                strengthLevel > 2 && strengthLevel <= 4 ? '#F0CD5D' :
                '#62CE90'
            },
            '&:hover fieldset': {
                borderColor: 
                strengthLevel <= 0 ? '#3A73CC' :
                strengthLevel > 0 && strengthLevel <= 2 ? '#C0492C' :
                strengthLevel > 2 && strengthLevel <= 4 ? '#F0CD5D' :
                '#62CE90'
            },
            '&.Mui-focused fieldset': {
                borderColor: 
                strengthLevel <= 0 ? '#3A73CC' :
                strengthLevel > 0 && strengthLevel <= 2 ? '#C0492C' :
                strengthLevel > 2 && strengthLevel <= 4 ? '#F0CD5D' :
                '#62CE90'
            },
          }
        }
    }));

    const classes = useStyles();

    const checkUpperCase = (value) => {
        setHasCapital(/[A-Z]/.test(value))
    }

    const checkNumber = (value) => {
        setHasNumber(/[0-9]/.test(value))
    }

    const checkSpecialChar = (value) => {
        setHasSpecialChar(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value))
    }
    const checkEnoughCharacter = (value) => {
        if(value.length >= 8){
            setHasEnoughCharacter(true)
        }
        else {
            setHasEnoughCharacter(false)
        }
    }
    const checkSpaces = (value) => {
        setHasSpace(value.includes(" "))
    }
    
    const checkPassword = (val) => {
        let tempLevel = 0

        checkEnoughCharacter(val)
        checkUpperCase(val)
        checkNumber(val)
        checkSpecialChar(val)
        checkSpaces(val)

        setPassword(val)

        if(/[A-Z]/.test(val)){
            tempLevel = tempLevel + 2
        }
        if((/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val)) && (/[0-9]/.test(val))){
            tempLevel = tempLevel + 2
        }
        if(val.length >= 8){
            tempLevel = tempLevel + 2
        }
        setStrengthLevel(tempLevel)

        if(/[A-Z]/.test(val) && (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val)) && (/[0-9]/.test(val)) && val.length >= 8 && !hasSpace){
            setIsPasswordValid(true)
        }

        else{
            setIsPasswordValid(false)
        }
    }

    const validPasswordCategory = [
        {
            'icon': hasEnoughCharacter ? Valid : Invalid,
            'category': <div style={style.passCatergory}>At least 8 characters</div>
        },
        {
            'icon': hasCapital ? Valid : Invalid,
            'category': <div style={style.passCatergory}>At least one uppercase letter</div>
        },
        {
            'icon': hasNumber && hasSpecialChar ? Valid : Invalid,
            'category': <div style={style.passCatergory}>Contains a number and symbol</div>
        },

        {
            'icon': !hasSpace ? Valid : Invalid,
            'category': <div style={style.passCatergory}>Does not contain a space</div>
        },
    ]

    function done() {
        setShowLoginModal(false)
        window.location.href = '/dashboard';

    }

    const LoginModal = () => {
        return (
            <Modal
                centered
                width={400}
                visible={showLoginModal}
                onCancel={() => done()}
                footer={null}>
                <div>
                    <div align="center" className={es.inputDiv}>
                        <img src={PasswordChange} alt="logo" />
                        <div className={es.successText}>
                            Success!
                        </div>
                    </div>

                    <div className={es.message1} style={{ color: '#000000' }}>
                        <p>
                            {modalMessage}
                        </p>
                    </div>

                    <div>
                        <button
                            className={es.updatePassword}
                            onClick={() => done()}
                        >
                            Done
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }

    const changeUserPassword = async(val) => {
        setShowSpin(true)
        let params = {
            new_password: val === 1 ? '' : password,
            waived: val === 1 ? true : false,
        }

        let yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
          }
        
        try{
            let response = await axios.post(
                process.env.REACT_APP_API_URL + '/api/change_password/',params,yourConfig
            );
                
            if(response.data.status === "success") {
                setIsWaive(val)
                setShowLoginModal(true)
                setPasswordExpiry(response.data.expiry_date);
            }

            else{
                setShowSpin(false)
                setAlert(false)
                setStatus(response.data.status)
                setMessage(response.data.message)
                setTimeout(() => {
                    setAlert(true)
                }, 3000);
            }
        }

        catch(error){
           if((error.response)&&(error.response.status == 401)){
                Handle401()
            }
        }
    }

    return(
        <div id="terms" style={style.mainDiv}>
            {LoginModal()}

            <div align='center' hidden={hideDiv}>
                <img src={Reminder} style={{marginBottom: '30px'}}/>
                <div style={style.header}>
                    Update Password
                </div>
                <p style={style.message}>
                    Hi <span>{fullName}</span>, 
                    your password has expired. 
                    Please change your password now.
                </p>
                <p style={style.message}>
                    For your security, 
                    we recommend updating your password every <b>90 days.</b>
                </p>
                <Button style={{...style.updateBtn, marginTop: '30px'}} onClick={()=>setHideDiv(true)}>
                    Update password
                </Button>
                <div style={{width: '248px'}}>
                    <Divider>OR</Divider>
                </div>
                <Button style={style.waiveBtn} onClick={()=>changeUserPassword(1)}>
                    Waive password update
                </Button>
            </div>
            <div hidden={!hideDiv} style={{padding: '0px 40px 0px 40px'}}>
                <div align="right" style={{marginBottom: '32px'}}>
                    <a style={style.cancel} onClick={()=>setHideDiv(false)}>
                        Cancel 
                    </a>
                </div>
                <div align='center'>
                    <div style={style.header}>
                        Change Password
                    </div>
                </div>
                <div style={{marginTop: '40px'}}>
                    <div hidden={alert} style={{marginTop: 20, width: '100%'}} >
                        <Alert message={message} type={status} style={{marginBottom: '20px'}} showIcon/>
                    </div>
                    <div style={style.newPassword}>New password</div>
                    <TextField
                        className={classes.root}
                        size="small"
                        value={password}
                        name="password"
                        id="outlined-adornment-password"
                        variant="outlined"
                        onChange={e => checkPassword(e.target.value)}
                        style={{
                            height: "100%",
                            width: "100%",
                            marginBottom: "10px"
                        }}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        name="passwordToggle"
                                        edge="end"
                                        aria-label="toggle password visibility"
                                        onClick={e => setShowPassword(!showPassword)}
                                        onMouseDown={e => setShowPassword(showPassword)}
                                    >
                                        {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </div>

                <div style={{display: 'flex', marginBottom: '10px',  width: isMobile ? '100%' : isHighReso ? '449px' : '350px'}}>
                    <div style={{...style.meterStyle, marginRight: '2px',
                        backgroundColor: strengthLevel <= 0 ? '#D4D5D8' : 
                                         strengthLevel > 0 && strengthLevel <=2 ? '#C0492C' : 
                                         strengthLevel > 2 && strengthLevel <= 4 ? '#F0CD5D' :
                                        '#62CE90'}}>            
                    </div>
                    <div style={{...style.meterStyle, marginRight: '2px',
                        backgroundColor: strengthLevel <= 0 ? '#D4D5D8' : 
                                         strengthLevel > 0 && strengthLevel <=2 ? '#C0492C' : 
                                         strengthLevel > 2 && strengthLevel <= 4 ? '#F0CD5D' :
                                        '#62CE90'}}>            
                    </div>
                    <div style={{...style.meterStyle, marginRight: '2px',
                        backgroundColor: strengthLevel == 3 || strengthLevel == 4  ? '#F0CD5D' :
                                         strengthLevel >= 5 ? '#62CE90' :
                                        '#D4D5D8'}}>            
                    </div>       
                    <div style={{...style.meterStyle, marginRight: '2px',
                        backgroundColor: strengthLevel == 3 || strengthLevel == 4  ? '#F0CD5D' :
                                         strengthLevel >= 5 ? '#62CE90' :
                                        '#D4D5D8'}}>            
                    </div>     
                    <div style={{...style.meterStyle, marginRight: '2px',
                        backgroundColor: strengthLevel >= 5 ? '#62CE90' :
                                        '#D4D5D8'}}>            
                    </div>       
                    <div style={{...style.meterStyle, 
                        backgroundColor: strengthLevel >= 5 ? '#62CE90' :
                                        '#D4D5D8'}}>            
                    </div>
                </div>

                <div style={{display: 'flex', marginBottom: "10px"}}>
                    <span style={{fontSize: '12px', color: 'rgba(43, 45, 50, 0.8);', marginRight: '5px'}}>Password strength:</span>
                    <span style={{
                        fontSize: '12px',
                        color: strengthLevel <= 0 ? '#C0492C' :
                            strengthLevel > 0 && strengthLevel <= 2 ? '#C0492C' :
                            strengthLevel > 2 && strengthLevel <= 4 ? '#F0CD5D' :
                            '#62CE90'
                    }}>
                        {
                            strengthLevel <= 0 ? 'Weak' :
                            strengthLevel > 0 && strengthLevel <= 2 ? 'Weak' :
                            strengthLevel > 2 && strengthLevel <= 4 ? 'Medium' :
                            'Strong'
                        }
                    </span>
                </div>
                <div>
                    {
                        validPasswordCategory.map((item, key) => {
                            return (
                                <div style={{display: 'flex'}} key={key}>
                                    <div style={{marginTop: '-3px'}}>
                                        <img src={item.icon} /> 
                                    </div>

                                    <div style={{marginLeft: 12}}>
                                        {item.category}
                                    </div>
                                    
                                </div>
                            )
                        })
                    }
                </div>
                <Button 
                    disabled={!isPasswordValid}
                    style={{...style.updateBtn, width: '100%', marginTop: '30px', opacity: isPasswordValid ? '1' : '0.5'}} 
                    onClick={()=>changeUserPassword(2)}>
                    Change password
                </Button>
            </div>
        </div>
        
    )
}

const style = {
    mainDiv : {
        width: isMobile ? '100%' : isHighReso ? '529px' : '430px',
        height: isMobile ? '100%' : '95%',
        borderRadius: !isMobile && '8px',
        backgroundColor: '#fff',
        boxShadow: '0px 8px 24px rgba(0, 35, 11, 0.12)',
        paddingTop: isHighReso ? '60px' : '40px',
        paddingBottom: '30px',
        overflowY: 'auto'
    },
    header:{
        display: 'flex', 
        justifyContent: 'center', 
        fontSize: '28px', 
        color: '#2b2d33', 
        fontWeight: 'bold'
    },
    message:{
        width: '340px',
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '24px',
        color: '#2b2d33',
        marginTop: '26px'
    },
    newPassword:{
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '20px',
        color: 'rgba(43, 45, 50, 0.8);',
        marginBottom: '15px'
    },
    updateBtn:{
        width: '312px',
        height: '40px',
        borderRadius:'4px',
        border: '1px solid #F5922F',
        backgroundColor: '#F5922F',
        fontSize: '16px',
        color: '#fff',
        fontWeight: 'bold'
    },
    waiveBtn:{
        width: '312px',
        height: '40px',
        borderRadius:'4px',
        border: '1px solid #F5922F',
        backgroundColor: '#FFF',
        fontSize: '16px',
        color: '#F5922F',
        fontWeight: 'bold'
    },
    cancel:{
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '24px',
        color: '#F5922F',
    },
    inputStyle: {
        width: '100%',
        height: isHighReso? '38px' : '48px',
        borderRadius: '4px',
        color: '#000',
        fontSize: isHighReso? '12px' : '16px',
        WebkitAppearance: 'none',
    },
    passCatergory:{
        fontSize: '12px',
        fontWeight: 'normal',
        lineHeight: '16px',
        color: 'rgba(43, 45, 50, 0.8);',
    },
    meterStyle:{
        height: '4px',
        width: isMobile ? '15%' : isHighReso ? '74px' : '58px',
    }
}

export default UpdatePassword