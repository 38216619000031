import React, { useEffect, useRef } from 'react';
import {Spin, Button, Icon, Row, Col, Checkbox, Popover} from 'antd';
import logo from '../../static/icons/logo.svg'
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import { useDispatch, useSelector } from 'react-redux';
import { searchLocations } from './locationAction';
import GooglePlacesAutocomplete, {geocodeByPlaceId, getLatLng }from 'react-google-places-autocomplete';
import {isMobile} from 'react-device-detect'
import LocationIcon from '../../static/icons/location_icon.svg'
import LocationIcon2 from '../../static/icons/location_icon2.svg'
import LocationIcon3 from '../../static/icons/location_icon3.svg'
import LocationIcon4 from '../../static/icons/location_icon4.svg'
import ArrowDown from '../../static/icons/arrow-down.svg'
import ArrowWhite from '../../static/icons/arrow_white.svg'
import Close from '../../static/icons/close_icon.svg'
import {OTC}  from '../constants/constants'
import InfoWindowEx from './InfoWindow';

import {SevenEleven, BayadCenter, Cebuana, EcpayPNG, Expresspay, Bux,
  Lbc, ML, Palawan, RD, Robinson, Rural, Sm, Loan, USSC, Rustan, Shopwise, Tambunting, CVM, Wellcome, Pay_N_Go, DA5, Posible, Petnet, Villarica, i2i} from '../../static/icons/payment_method_icons/index'

const Location = props => {

  const {google} = props;

  const mapStyles = {
    width: '100%',
    height: '100%',
  };
  
  delete OTC['Shopwise']
  delete OTC['Wellcome']
  delete OTC['CVM Pawnshop']

  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />
  const CheckboxGroup = Checkbox.Group;

  const [place, selectedPlace] = React.useState({})
  const [marker, activeMarker] = React.useState({})
  const [infoVisibility, setInfoVisiblity] = React.useState(false)
  const [slat, setLat] = React.useState(14.5870)
  const [slong, setLong] = React.useState(121.0633)
  const [loading, setLoading] = React.useState(false)

  const [place2, setPlace2] = React.useState(null)
  const [hoverIndex, setHoverIndex] = React.useState(null)
  const [hoverButton, setHoverButton] = React.useState("")
  const [maxLength, setMaxLength] = React.useState(5)
  const [isDrawerHidden, setIsDrawerHidden] = React.useState(false)

  const [otcCheckedList, setOtcCheckedList] = React.useState(["7-Eleven", "Bayad Center", "Cebuana Lhuillier", "LBC", "ECPay", "SM / Supermarket / Savemore", "Robinsons Dept Store", "Palawan Pawnshop", "RD Pawnshop", "RuralNet", "DA5", "Pay&Go", "Posible", "USSC", "Tambunting", "Rustan Supercenters, Inc", "i2i", "Villarica", "Petnet, Inc."])

  const [checkAllOTC, setCheckAllOTC] = React.useState(true)

  const [itemRefs, setItemRefs] = React.useState([])
  
  const dispatch = useDispatch();
  const locations = useSelector(state => state.location ? state.location.branches : []);

  const channels = {

        '711_direct':
        {
            name: '7-Eleven',
            icon: SevenEleven
        },
        'BAYD':
        {
            name: 'Bayad Center',
            icon: BayadCenter
        },
        'LBC':
        {
            name: 'LBC',
            icon: Lbc
        },
        'MLH':
        {
            name: 'MLhuillier',
            icon: ML
        },
        'SMR':
        {
            name: 'SM / Supermarket / Savemore',
            icon: Sm
        },
        'CEBL':
        {
            name: 'Cebuana Lhuillier',
            icon: Cebuana
        },
        'RDS':
        {
            name: 'Robinsons Dept Store',
            icon: Robinson
        },
        'ecpay':
        {
            name: 'ECPay',
            icon: EcpayPNG
        },
        'PLWN':
        {
            name: 'Palawan Pawnshop',
            icon: Palawan
        },
        'RDP':
        {
            name: 'RD Pawnshop',
            icon: RD
        },
        'RLNT':
        {
            name: 'RuralNet',
            icon: Rural
        },
        'da5':
        {
            name: 'DA5',
            icon: DA5
        },
        'posible':{
            name: 'Posible',
            icon: Posible
        },
        'ussc':{
          
          name: 'USSC',
          icon: USSC,

        },
        'rustan':{
            
            name: 'Rustan Supercenters, Inc',
            icon: Rustan,
        },
        'shopwise':{
           
            name: 'Shopwise',
            icon: Shopwise,
        },
        'wellcome':{
            
            name: 'Wellcome',
            icon: Wellcome,
        },
        'tmbntng':{
            
            name: 'Tambunting',
            icon: Tambunting,
        },
        'cvm':{

            name: 'CVM Pawnshop',
            icon: CVM,
        },
        'payngo':{   

            name: 'Pay&Go',
            icon: Pay_N_Go,

        },
        'i2i':{
            name: 'i2i',
            icon: i2i,
        },
        'villarica':{

            name: 'Villarica',
            icon: Villarica,
        },
        'petnet':{

            name: 'Petnet, Inc',
            icon: Petnet,
        }

    }
  useEffect(() => {
    // dispatch(getLocations());
    loadScript("https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGLE_API_KEY+"&libraries=places");
    // dispatch(searchLocations({latitude: 14.587136, longitude: 121.063329}));
  }, [])

  const onMarkerClick = (props, marker, e) => {
    console.log(props)
    activeMarker(marker);
    selectedPlace(props);
    setLat(marker.position.lat());
    setLong(marker.position.lng());
    setInfoVisiblity(true)

    itemRefs[props.id].scrollIntoView();
  }
  
  const callCoordinates = (lat, lng) => {
    
    let params = {
      latitude: lat,
      longitude: lng,
    }

    setLat(lat)
    setLong(lng)
    setMaxLength(5)
    dispatch(searchLocations(params))
  }

  function searchLoc(value) {
    setLoading(true);
    geocodeByPlaceId(value.place_id)
    .then(results => getLatLng(results[0]))
    .then(({ lat, lng }) =>
    callCoordinates(lat,lng));
  }

  function useCurrentLocation(){
    navigator.geolocation.getCurrentPosition(function(position) {
      setLoading(true);
      callCoordinates(position.coords.latitude, position.coords.longitude);
    });
  }

  var loadScript = function(src) {
    var tag = document.createElement('script');
    tag.async = false;
    tag.src = src;
  }

  const getAllKeys = () => {
    let all_otc = []

    for(let i=0; i < otcCheckedList.length; i++){
      try{
        let name = otcCheckedList[i]
        all_otc.push(OTC[name].code)
      }catch(err){}
    }

    if(all_otc.includes('ECPY')){
      all_otc.push('ecpay')
    }

    return all_otc
  }

  const onChangeOtc = list => {
    setOtcCheckedList(list)
    setCheckAllOTC(list.length == Object.keys(OTC).length)
  };

  const onCheckAllOtcChange = e => {
    let new_list = e.target.checked ? Object.keys(OTC) : []
    setOtcCheckedList(new_list)
    setCheckAllOTC(e.target.checked)

  };


  const filterLocation = () => {

    let new_list = getAllKeys()

    if(locations[0]){
      let location_filtered = locations.filter(location => new_list.includes(location.channel))
      
      return location_filtered
    }
    else{
      return []
    }
  }
  const payment_methods = () => (
    <div style={{ width: '200px', backgroundColor: '#fff', height: '200px', overflowY: 'scroll'}} >
        <Row>
            <Col>
                <Checkbox
                    style={{margin:'0px 0px 5px 0px', fontWeight: '600'}}
                    indeterminate={otcCheckedList.length && otcCheckedList.length < Object.keys(OTC).length}
                    checked={checkAllOTC}
                    onChange={(e)=>onCheckAllOtcChange(e)}
                    key="All Channels"
                >
                    All
                </Checkbox>
            </Col> 
            <Col>
                <CheckboxGroup
                    style={{margin:'0px 0px 0px 24px'}}
                    options={Object.keys(OTC)}
                    value={otcCheckedList}
                    onChange={(e)=>onChangeOtc(e)}
            />
            </Col>
        </Row>
    </div> 
  )
  const zoom = 16


  const closeButton = () => {
    return(
      <a style={{ margin: 'auto'}} onClick={(e)=>{e.stopPropagation(); setInfoVisiblity(false)}}><img src={Close} alt="#"/></a>
    )
  }

  return (
    <div style={{ width: '100%', display: 'flex', overflowY: 'hidden'}}>
      <Map
        google={props.google}
        zoom={zoom}
        style={mapStyles}
        mapTypeControl={false}
        zoomControl={false}
        streetViewControl={false}
        initialCenter={{
          lat: slat,
          lng: slong
        }}
        center={{
          lat: slat,
          lng: slong
        }}>
        <a onClick={()=>setIsDrawerHidden(!isDrawerHidden)}
        style={{ position: 'absolute', 
          top: '24px',
          left: isDrawerHidden ? '0px' : '330px', 
          height: '50px', 
          width: '50px', 
          backgroundColor: '#0D3D76',
          zIndex: 10,
          borderRadius: '0px 4px 4px 0px',
          paddingTop: '13px'
        }}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <img src={ArrowWhite} alt="#" style={{transform: isDrawerHidden && 'rotate(180deg)'}}/>
          </div>
        </a>
        <div style={{ position: 'absolute', 
          left: '0', 
          height: '100vh', 
          width: isDrawerHidden ? '0px' : '330px', 
          boxShadow: '8px 4px 20px rgba(0, 0, 0, 0.3)', 
          backgroundColor: '#fff',
          overflowY: 'hidden'
        }}>
          <div style={{display: 'flex', justifyContent: 'center', margin: '20px 0px 40px 0px'}}>
            <img src={logo} style={{height: '24px'}} alt="#" />
          </div>

          <div style={{padding: '0px 20px 0px 20px'}}>
            <GooglePlacesAutocomplete
              onSelect={places => setPlace2(places)}
              placeholder="Type your location here"
              autocompletionRequest={{
                componentRestrictions: {
                  country: ['ph'],
                }
              }}
              suggestionsStyles={{
                suggestion: {
                  backgroundColor: '#FFF',
                  padding: '10px',
                  borderBottom: '1px solid #B7B9C0',
                  borderLeft: '1px solid #B7B9C0',
                  borderRight: '1px solid #B7B9C0'
                },
              }}
              style={{ margin: '5px'}}
              inputStyle={{ padding: '5px', 
                width: '100%', 
                zIndex: 10, 
                height: '40px', 
                border: '1px solid #B7B9C0',
                borderRadius: '4px',
                paddingRight: '40px'
              }}
            />

            <Button
              disabled={!place2} 
              onClick={()=>searchLoc(place2)}
              style={{
                height: '32px',
                width: '100%',
                backgroundColor: '#F5922F',
                borderRadius: '4px',
                border: '1px solid #F5922F',
                color: '#fff',
                fontWeight: 'bold',
                marginTop: '10px',
                opacity: !place2 && '0.5'
              }}>Search</Button>
            
            <a style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}} onClick={useCurrentLocation}>
              <div style={{color: '#F5922F', fontSize: '14px'}}> <img src={LocationIcon2}/> Use my current location</div>
            </a>
            <div style={{marginTop: '20px'}}>
              <div style={{fontSize: '14px', color: '#2B2D32', opacity: '0.8'}}>Filter nearby payment channels</div>
              <Popover content={payment_methods()} trigger="click" placement="bottomLeft">
                  <Button 
                      style={{
                          zIndex: 1, 
                          height: '40px', 
                          width: '100%',
                          paddingTop: '8px', 
                          marginTop: '10px', 
                          marginRight: '12px',
                          display: 'flex',
                          justifyContent:'space-between',
                          color:  '#000',
                          border: '1px solid #c5cacf'
                      }}>
                          <div>Payment Method</div> 
                          <img
                              alt='Arrow Down' 
                              src={ArrowDown} 
                              style={{
                                  marginLeft: '5px', 
                                  color: '#000'
                              }}/>
                  </Button>
              </Popover>
            </div>
            <div style={{margin: '20px 0px 30px 0px', fontWeight: 'bold', color: '#000', fontSize: '14px'}}>Nearby Me</div>
          </div>
          <div id="locations" hidden={isMobile} style={{ marginTop: '20px', overflowY : 'auto', height: window.innerHeight - 400}}>
            {
              filterLocation()[0] ?
                filterLocation().map((item, i) => {
                  return(  

                    (filterLocation().length <= 5) || i + 1 <= maxLength &&
                    <a>
                      <div
                        ref={el => (itemRefs[i] = el)}
                        onClick={()=>{
                          selectedPlace({name: `${item.name} - ${ item.address }`, storedIcon: channels[item.channel].icon })
                          setLat(item.latitude); 
                          setLong(item.longitude); 
                        }}
                        onMouseEnter={()=>setHoverIndex(i)} 
                        onMouseLeave={()=>setHoverIndex(null)}
                        style={{
                          padding: '16px 20px 16px 20px', 
                          display: 'flex', 
                          justifyContent: 'space-between', 
                          borderTop: '1px solid #EEEEEE', 
                          backgroundColor: hoverIndex == i || (slat == item.latitude && slong == item.longitude) ? '#EEEEEE' : '#FFF'
                        }}>
                        <div style={{display: 'flex'}}>
                          <img src={channels[item.channel].icon} alt="#" style={{height: '48px', width: '48px'}}/>
                          <div style={{
                            padding:'10px 0px 0px 5px', 
                            width: '115px', 
                            whiteSpace: 'nowrap', 
                            overflow: 'hidden', 
                            textOverflow: 'ellipsis', 
                            fontSize: '16px', 
                            color: '#2b2d32'
                          }}>{channels[item.channel].name}</div>
                        </div>
                        <div style={{ paddingTop: '10px', 
                          display: 'flex', 
                          width: '110px'
                        }}>
                          <img src={LocationIcon} alt="#" style={{height: '30px', width: '24px', marginRight: '5px', marginTop: '-3px'}}/>
                          <div style={{fontSize: '16px', color: '#2b2d33', fontWeight: '500'}}>{item.distance_str} KM</div>
                        </div>
                      </div>
                    </a>
                  )
                })
              :
              loading ?
              <div style={{ width: '100%', textAlign: 'center', height: '300px', lineHeight: '300px' }}>
                  <Spin indicator={antIcon} />
              </div>
              :
              <div style={{display: 'flex', justifyContent: 'center', color: '#2B2D32'}}>
                No nearby found
              </div>
            }
            <Button
              onClick={()=>setMaxLength(filterLocation().length)}
              onMouseEnter={()=>setHoverButton("see")}
              onMouseLeave={()=>setHoverButton("")}
              hidden={!filterLocation()[0] || filterLocation().length <= 5 || maxLength != 5}
              style={{
                margin: '10px 0px 0px 20px',
                width:'280px',
                height: '40px',
                border: '2px solid #F5922F',
                borderRadius: '4px',
                backgroundColor: hoverButton == 'see' ? '#F5922F': '#FFF',
                color: hoverButton == 'see' ? '#FFF': '#F5922F',
                fontWeight:'600'
              }}>See more
            </Button>
          </div>
        </div>
        {
          filterLocation()[0] &&
            filterLocation().map((item, index) => {
              
              return (
                (filterLocation().length <= 5) || index + 1 <= maxLength &&
                  <Marker position={{
                    lat: item.latitude,
                    lng: item.longitude
                  }}
                    icon={{ url: item.latitude == slat && item.longitude == slong ? LocationIcon4 : LocationIcon3, 
                            scaledSize:  new google.maps.Size(70, 70),
                            strokeColor: '#000000',
                            strokeOpacity: 1,
                            strokeWeight: 5,
                            strokeWidth: 5}}
                    storedIcon = {channels[item.channel].icon}
                    name={`${item.name} - ${ item.address.replace('Ncr', 'NCR') }`}
                    onClick={onMarkerClick}
                    id={index}
                    key={{index}} />

              )
            })
        }

        <InfoWindowEx
                marker={marker}
                visible={infoVisibility}>
                <div style={{display: 'flex', margin: 'auto'}}>
                  <img src={place.storedIcon} alt="#" style={{maxHeight: place.name && place.name.includes('ECPay') ? '50px' : '30px', maxWidth: place.name && ['VILLARICA', 'Petnet'].includes(place.name) && '70px',  margin: 'auto'}}/>
                  <div style={{fontWeight: '500', fontSize: '14px', color: '#fff', lineHeight:'24px', margin: '0px 15px 0px 15px'}}>{place.name}</div>
                  {
                    closeButton()
                  }
                </div>
        </InfoWindowEx>
      </Map>
    </div>
  )

}

export default GoogleApiWrapper({

  apiKey: process.env.REACT_APP_GOOGLE_API_KEY

})(Location)


