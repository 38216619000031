import React from "react";
import { TransferMoneySuccess } from "../../../../../static/images/multiwallet";
import { Card } from "antd";

export function TransferReceipt({ transferId, subwalletId, enterprisePeers, amount }) {
  const selectedSubwallet = enterprisePeers.enterprise_peers.find((peer) => peer.id === subwalletId);
  const { id, first_name, last_name, email } = selectedSubwallet;

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1em", fontSize: "18px" }}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1em" }}>
        <img src={TransferMoneySuccess} alt='Transfer Money Success Icon' />
        <p style={{ fontWeight: "bold" }}>Money Sent for Approval</p>
      </div>

      <Card style={{ width: "100%", padding: "1em", fontSize: "18px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>Transferred money to</p>
          <p style={{ color: "rgba(43, 45, 50, 0.8)", fontWeight: "500" }}>{first_name} {last_name}</p>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>Date</p>
          <p style={{ color: "rgba(43, 45, 50, 0.8)", fontWeight: "500" }}>{
            new Date().toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })
          }</p>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>Amount</p>
          <p style={{ color: "rgba(43, 45, 50, 0.8)", fontWeight: "500" }}>
            {Number(amount).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "PHP",
            })}
          </p>
        </div>
      </Card>

      <div>
        <p>TRANSACTION ID: {transferId}</p>
      </div>
    </div>
  )
}
