import { fbSignUp, googleSignUp, signUp } from "./signUpServices";
import {history} from "../../store/history";
import {showAlert} from '../alert/alertAction'
import {showModal} from '../BuxModal/modalAction'

export const SignUpActionTypes = {
  SIGNUP_REQUEST: "@@SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "@@SIGNUP_SUCCESS",
  SIGNUP_FAILED: "@@SIGNUP_FAILED",
  CLEAR: "@@CLEAR"
};

export function sign_up(value) {
  return async dispatch => {
    try {
      dispatch({ type: SignUpActionTypes.SIGNUP_REQUEST });
      let response = await signUp(value);

      if(response.data.status == "success"){
        dispatch({
          type: SignUpActionTypes.SIGNUP_SUCCESS,
          payload: response.data
        });
        dispatch(showModal({ title: "Verify Account", message: "Before continuing, we need to verify your email address. Please check your inbox for a confirmation link.", returnType: 'login', showResendLink: true, email: value.email }));
       }
       else{
        dispatch({
          type: SignUpActionTypes.SIGNUP_FAILED
        });
        dispatch(showAlert({ type: "error", message: response.data.message }));


       }
    } catch (error) {
      dispatch({
        type: SignUpActionTypes.SIGNUP_FAILED
      });
      // window.location.reload()
      console.log(error.message);
    }
  };
}


export function fb_sign_up(value) {
  return async dispatch => {
    try {
      dispatch({ type: SignUpActionTypes.SIGNUP_REQUEST });
      let response = await fbSignUp(value);

      if(response.data.status == "success"){
        dispatch({
          type: SignUpActionTypes.SIGNUP_SUCCESS,
          payload: response.data
        });

        let token = response.data.access_token;
        let userDetails = response.data.user_details;

        localStorage.setItem("jwtToken", token);
        localStorage.setItem("userId", userDetails.id);
        localStorage.setItem("firstName", userDetails.first_name);
        localStorage.setItem("lastName", userDetails.last_name);
        localStorage.setItem("middleName", userDetails.middle_name);
        localStorage.setItem("suffix", userDetails.suffix);
        localStorage.setItem("username", userDetails.username);
        localStorage.setItem("email", userDetails.email);
        localStorage.setItem("contact", userDetails.contact);
        localStorage.setItem("balance", userDetails.balance);
        localStorage.setItem("userType", userDetails.user_type)
        localStorage.setItem("isCompleted", userDetails.is_completed)
        localStorage.setItem("isVerified", userDetails.is_verified)
        localStorage.setItem("has_address", userDetails.address)
        localStorage.setItem("lastLogin", userDetails.last_login)
        localStorage.setItem("Step", userDetails.kyc_step)
        localStorage.setItem("Level", userDetails.kyc_level)


       
        window.location.href = '/survey_business'
        
       }else if(response.data.status == 'fail'){
        console.log(response.data.message)
        dispatch({
          type: SignUpActionTypes.SIGNUP_FAILED
        });
        dispatch(showModal({ title: "Failed to sign up", message: response.data.message, returnType: 'signup' }));
       
       }
    } catch (error) {
      window.location.reload()

      dispatch({
        type: SignUpActionTypes.SIGNUP_FAILED
      });
      console.log(error.message);
    }
  };
}


export function google_sign_up(value) {
  return async dispatch => {
    try {
      dispatch({ type: SignUpActionTypes.SIGNUP_REQUEST });
      let response = await googleSignUp(value);

      if(response.data.status == "success"){
        dispatch({
          type: SignUpActionTypes.SIGNUP_SUCCESS,
          payload: response.data
        });
        
        let token = response.data.access_token;
        let userDetails = response.data.user_details;

        localStorage.setItem("jwtToken", token);
        localStorage.setItem("userId", userDetails.id);
        localStorage.setItem("firstName", userDetails.first_name);
        localStorage.setItem("lastName", userDetails.last_name);
        localStorage.setItem("middleName", userDetails.middle_name);
        localStorage.setItem("suffix", userDetails.suffix);
        localStorage.setItem("username", userDetails.username);
        localStorage.setItem("email", userDetails.email);
        localStorage.setItem("contact", userDetails.contact);
        localStorage.setItem("balance", userDetails.balance);
        localStorage.setItem("userType", userDetails.user_type)
        localStorage.setItem("isCompleted", userDetails.is_completed)
        localStorage.setItem("isVerified", userDetails.is_verified)
        localStorage.setItem("has_address", userDetails.address)
        localStorage.setItem("lastLogin", userDetails.last_login)
        localStorage.setItem("Step", userDetails.kyc_step)
        localStorage.setItem("Level", userDetails.kyc_level)


        window.location.href = '/survey_business'
        
       }
       else{
        dispatch({
          type: SignUpActionTypes.SIGNUP_FAILED
        });
        dispatch(showModal({ title: "Failed to sign up", message: response.data.message, returnType: 'signup' }));
       }
    } catch (error) {
      window.location.reload()

      dispatch({
        type: SignUpActionTypes.SIGNUP_FAILED
      });
      console.log(error.message);
    }
  };
}





