import React from 'react'
import {Card} from 'antd'

import MeLogo from '../../static/images/home/me.svg'
import GigLogo from '../../static/images/home/gig.svg'
import SmeLogo from '../../static/images/home/sme.svg'
import BizLogo from '../../static/images/home/biz.svg'

import Check from '../../static/images/home/check.svg'
import Ex from '../../static/images/home/eks.svg'

import ArrowLeft from '../../static/icons/arrow_left.svg'
import ArrowRight from '../../static/icons/arrow_right_black.svg'

const AccountLevelsMobile = () => {

    const accountLevels = ['me_','gig','sme','biz']
    const segments = localStorage.getItem('segments')

    const [selectedSegment, setSelectedSegment] = React.useState(0)

    const accountLimits = {
        'me_': {
            logo: MeLogo,
            dailyCashIn: '₱ 100,000.00',
            dailyCashOut: '₱ 100,000.00',
            monthlyCashIn: '₱ 100,000.00',
            monthlyCashOut: '₱ 100,000.00',
            annualCashIn: '₱ 1,200,000.00',
            annualCashOut: '₱ 1,200,000.00',
            paymentLinks: true,
            batchhPaymentlink: false,
            customInvoicing: false,
            myCheckout: true,
            plugins: true,
            api: false,
            sentro: true,
            multiwallet: false,
            otc: true,
            web: false,
            ewalllet: false,
            debit_credit: true,
            buyNowPayLater: true
        },
        'gig': {
            logo: GigLogo,
            dailyCashIn: '₱ 500,000.00',
            dailyCashOut: '₱ 500,000.00',
            monthlyCashIn: '₱ 500,000.00',
            monthlyCashOut: '₱ 500,000.00',
            annualCashIn: '₱ 6,000,000.00',
            annualCashOut: '₱ 6,000,000.00',
            paymentLinks: true,
            batchhPaymentlink: false,
            customInvoicing: false,
            myCheckout: true,
            plugins: true,
            api: false,
            sentro: true,
            multiwallet: false,
            otc: true,
            web: false,
            ewalllet: false,
            debit_credit: true,
            buyNowPayLater: true
        },
        'sme': {
            logo: SmeLogo,
            dailyCashIn: 'No limits',
            dailyCashOut: 'No limits',
            monthlyCashIn: 'No limits',
            monthlyCashOut: 'No limits',
            annualCashIn: 'No limits',
            annualCashOut: 'No limits',
            paymentLinks: true,
            batchhPaymentlink: true,
            customInvoicing: true,
            myCheckout: true,
            plugins: true,
            api: true,
            sentro: true,
            multiwallet: true,
            otc: true,
            web: true,
            ewalllet: true,
            debit_credit: true,
            buyNowPayLater: false
        },
        'biz': {
            logo: BizLogo,
            dailyCashIn: 'No limits',
            dailyCashOut: 'No limits',
            monthlyCashIn: 'No limits',
            monthlyCashOut: 'No limits',
            annualCashIn: 'No limits',
            annualCashOut: 'No limits',
            paymentLinks: true,
            batchhPaymentlink: true,
            customInvoicing: true,
            myCheckout: true,
            plugins: true,
            api: true,
            sentro: true,
            multiwallet: true,
            otc: true,
            web: true,
            ewalllet: true,
            debit_credit: true,
            buyNowPayLater: true
        },
    }

    return(
        <div style={{padding: '16px'}}>
            <Card style={style.cardStyle} bodyStyle={{padding: '40px 16px 16px 16px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '17px'}}>
                    {
                        selectedSegment >= 1 ? 
                        <img src={ArrowLeft} onClick={()=>setSelectedSegment(selectedSegment - 1)}/>
                        : <div style={{width: '15px'}}></div>
                    }
                    <img src={accountLimits[accountLevels[selectedSegment]].logo} style={{height: '50px'}}/>
                    {
                        selectedSegment <= 2 ? 
                        <img src={ArrowRight} onClick={()=>setSelectedSegment(selectedSegment + 1)}/>
                        : <div style={{width: '15px'}}></div>
                    }
                </div>
                <div style={style.tableHeaderStyle}>
                    <div style={{fontWeight: 'bold', fontSize: '14px', color:'#2b2d32'}}>Account Limits</div>
                </div>
                <div style={style.rowStyle}>
                    <div style={style.rowText}>Daily Cash-in</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].dailyCashIn}</div>
                </div>
                <div style={style.rowStyle}>
                    <div style={style.rowText}>Daily Cash-out</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].dailyCashOut}</div>
                </div>
                <div style={style.rowStyle}>
                    <div style={style.rowText}>Monthly Cash-in</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].monthlyCashIn}</div>
                </div>
                <div style={style.rowStyle}>
                    <div style={style.rowText}>Monthly Cash-out</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].monthlyCashOut}</div>
                </div>
                <div style={style.rowStyle}>
                    <div style={style.rowText}>Annual Cash-in</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].annualCashIn}</div>
                </div>
                <div style={style.rowStyle}>
                    <div style={style.rowText}>Annual Cash-out</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].annualCashOut}</div>
                </div>

                <div style={{...style.tableHeaderStyle, marginTop: '32px'}}>
                    <div style={{fontWeight: 'bold', fontSize: '14px', color:'#2b2d32'}}>Features</div>
                </div>
                <div style={style.rowStyle}>
                    <div style={style.rowText}>Payment Links (Invoicing)</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].paymentLinks ? <img src={Check} style={{height:'21px'}}/> : <img src={Ex} style={{height:'21px'}}/>}</div>
                </div>
                <div style={style.rowStyle}>
                    <div style={style.rowText}>Batch Payment Links (Invoicing)</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].batchhPaymentlink ? <img src={Check} style={{height:'21px'}}/> : <img src={Ex} style={{height:'21px'}}/>}</div>
                </div>
                {/* <div style={style.rowStyle}>
                    <div style={style.rowText}>Custom Invoicing</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].customInvoicing ? <img src={Check} style={{height:'21px'}}/> : <img src={Ex} style={{height:'21px'}}/>}</div>
                </div> */}
                <div style={style.rowStyle}>
                    <div style={style.rowText}>My Checkout / QR Payments</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].myCheckout ? <img src={Check} style={{height:'21px'}}/> : <img src={Ex} style={{height:'21px'}}/>}</div>
                </div>
                <div style={style.rowStyle}>
                    <div style={style.rowText}>Plugins</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].plugins ? <img src={Check} style={{height:'21px'}}/> : <img src={Ex} style={{height:'21px'}}/>}</div>
                </div>
                <div style={style.rowStyle}>
                    <div style={style.rowText}>API</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].api ? <img src={Check} style={{height:'21px'}}/> : <img src={Ex} style={{height:'21px'}}/>}</div>
                </div>
                <div style={style.rowStyle}>
                    <div style={style.rowText}>Sentro</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].sentro ? <img src={Check} style={{height:'21px'}}/> : <img src={Ex} style={{height:'21px'}}/>}</div>
                </div>
                <div style={style.rowStyle}>
                    <div style={style.rowText}>Multi-wallet</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].multiwallet ? <img src={Check} style={{height:'21px'}}/> : <img src={Ex} style={{height:'21px'}}/>}</div>
                </div>

                <div style={{...style.tableHeaderStyle, marginTop: '32px'}}>
                    <div style={{fontWeight: 'bold', fontSize: '14px', color:'#2b2d32'}}>Features</div>
                </div>
                <div style={style.rowStyle}>
                    <div style={style.rowText}>Over-the-counter</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].otc ? <img src={Check} style={{height:'21px'}}/> : <img src={Ex} style={{height:'21px'}}/>}</div>
                </div>
                <div style={style.rowStyle}>
                    <div style={style.rowText}>Online Banking</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].web ? <img src={Check} style={{height:'21px'}}/> : <img src={Ex} style={{height:'21px'}}/>}</div>
                </div>
                <div style={style.rowStyle}>
                    <div style={style.rowText}>E-wallets</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].ewalllet ? <img src={Check} style={{height:'21px'}}/> : <img src={Ex} style={{height:'21px'}}/>}</div>
                </div>
                <div style={style.rowStyle}>
                    <div style={style.rowText}>Debit and Credit Cards</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].debit_credit ? <img src={Check} style={{height:'21px'}}/> : <img src={Ex} style={{height:'21px'}}/>}</div>
                </div>
                <div style={style.rowStyle}>
                    <div style={style.rowText}>Buy Now Pay Later</div>
                    <div style={style.rowText}>{accountLimits[accountLevels[selectedSegment]].buyNowPayLater ? <img src={Check} style={{height:'21px'}}/> : <img src={Ex} style={{height:'21px'}}/>}</div>
                </div>
            </Card>

            <div style={{...style.rowText, fontSize: '12px', marginTop: '30px'}} align="center">Have some concerns? Feel free to reach out to us at</div>
             <div align="center"><a href="mailto:support@bux.ph" style={style.support} className={`link-text--${segments}`}>support@bux.ph</a></div>
        </div>
    )
}


const style = {

    cardStyle: {
        width: '100%',
        borderRadius: '4px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)'
    },
    tableHeaderStyle: {
        borderRadius: '8px 8px 0px 0px',
        height: '52px',
        width: '100%',
        backgroundColor: '#CFD3D9',
        padding: '16px'
    },
    rowStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        borderBottom: '1px solid #D4D5D8',
        padding: '16px'
    },
    rowText: {
        fontSize: '14px',
        color: '#2b2d32',
        fontWeight: 'normal',
        lineHeight: '20px',
        textAlign: 'center'
    },
    support:{
        fontWeight: 'bold',
        fontSize: '12px'
    }
}

export default AccountLevelsMobile