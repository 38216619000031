import React from 'react';
import { CardIcon, XenditIcon } from '../../../static/icons/enterprise_recon';
import { Card, Icon, Spin, Tooltip, Typography } from 'antd';
import { IconButton } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

const CardBalance = ({balance, isLoading}) => {
  const ubp_enabled = localStorage.getItem('cc_enabled') == 'UnionBank';

  return (
    <div style={styles.root} hidden={ubp_enabled}>
      <div style={styles.leftSide}>
        <img src={CardIcon} alt='icon' />
        <div style={styles.balContainer}>
          <Typography style={styles.text}>Debit / Credit Card Balance
            <Tooltip placement='topLeft' title={()=>
              <Typography style={{color: '#FFF', fontWeight: '400', fontSize: 20}}>
                Card payments balance for daily transactions is updated every 12mn.
              </Typography>}>
              <IconButton color="inherit" aria-label="info" variant="contained" style={{padding: 4}} >
                <Icon type="exclamation-circle" theme="filled" style={styles.exclamation} />
              </IconButton>
            </Tooltip>
          </Typography>
          <Typography style={styles.balance}>PHP {isLoading?<Spin size='small' />:
            <span>
            {balance? balance.toLocaleString('en-US', {minimumFractionDigits: 2}): '0.00'}
            </span>}
          </Typography>
        </div>
      </div>
      <div style={styles.rightSide}>
        <Typography style={styles.poweredBy}>Powered by</Typography>
        <img src={XenditIcon} alt='No Image' />
      </div>
    </div>
  )
}

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#FFF',
    padding: isMobile? 12 : '26px 32px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
    border: '1px solid #E6EAF0',
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: '#0D3D76',
    fontSize: isMobile? 10 : 12,
    fontWeight: '300',
  },
  balance: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#0D3D76',
  },
  poweredBy: {
    color: '#909196',
    fontSize: isMobile? 10 : 12,
    padding: '0 12px'
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: isMobile? 'column' : 'row',
  },
  balContainer: {
    padding: '0 12px'
  },
  exclamation: {
    color: '#909196', 
    backgroundColor: '#FFF', 
    borderRadius: 20, 
    fontSize: 12
  },
}

export default CardBalance;