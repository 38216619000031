import React from 'react';
import { Button, Card, Spin, Typography } from 'antd';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { randomize } from '../../../../constants/constants';

const visibleButtons = {
  button1: true,
  button2: true,
  button3: true,
  button4: true,
  button5: true,
  button6: true,
  button7: true,
  button8: true,
}

const buttonColors = {signups:'#8884d8',cumulative_signups:'#F5922F',transactions:'#E24C4C',cumulative_transactions:'#1DD28B',generated:'#65C1F2'};

const BuxTrends = ({data, isLoading}) => {
  const [visibleData, setVisibleData] = React.useState(visibleButtons);

  const buttons = ['Signups', 'Cumulative Signups', 'Transactions', 'Cumulative Transactions', 'Generated Codes'];
  const buttons2 = ['Cumulative Generated', 'Payouts', 'Cumulative Payouts'];

  const ChartButtons = ({buttons}) => {
    return (
      <div style={styles.buttonContainer}>
        {buttons&&buttons.map((item,i) => {
          return (
            <Button key={i} name={item} onClick={(e) => setVisibleData({...visibleData, ['button'+(i+1)]:!visibleData[`button${i+1}`]})}
              style={{...styles.button, backgroundColor: visibleData['button'+(i+1)]? '#FFF' :'#1DD28B', color: visibleData['button'+(i+1)]? '#1DD28B' : '#FFF' }}
            >
              {item}
            </Button>
          )
          })
        }
      </div>
    )
  }

  const ChartButtons2 = ({buttons}) => {
    return (
      <div style={styles.buttonContainer}>
        {buttons&&buttons.map((item,i) => {
          return (
            <Button key={i} name={item} onClick={(e) => setVisibleData({...visibleData, ['button'+(i+6)]:!visibleData[`button${i+6}`]})}
              style={{...styles.button, backgroundColor: visibleData['button'+(i+6)]? '#FFF' :'#1DD28B', color: visibleData['button'+(i+6)]? '#1DD28B' : '#FFF' }}
            >
              {item}
            </Button>
          )
          })
        }
      </div>
    )
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div style={styles.tooltipContent}>
          <Typography style={styles.tooltipLabel}>{label}</Typography>
          <div style={{padding: '4px 16px'}}>
            {payload && payload.map((item, i) => {
              return (
                <div key={i} style={{...styles.tooltipItem, color: buttonColors[payload[i].name]}}>
                  <span style={{color: '#000', paddingRight: 32}}>{payload[i].name}: </span>
                  <span>{payload[i].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
              )
            }) 
            }
          </div>
        </div>
      );
    }
  
    return null;
  };

  const Chart = ({data, loading}) => {
    return (
      !loading&&data ?
      <Card style={{borderRadius: 10}} bodyStyle={{padding: '46px 24px 24px 24px'}}>
        <ResponsiveContainer height={280}>
          <LineChart data={data} style={{zIndex: 99}}>
          <CartesianGrid strokeDasharray="3 0" vertical={false} />
            <XAxis dataKey="name" key={randomize()} interval={data.length >= 60 ? 30 : data.length >= 14 ? 7 : 1} />
            <YAxis />
            <Tooltip labelStyle={styles.tooltipLabel} itemStyle={{padding: '8px 16px'}} contentStyle={styles.tooltipContent} content={<CustomTooltip />} />
            { visibleData['button1']&& 
              <Line type="monotone" dataKey="signups" stroke="#8884d8" strokeWidth="2" dot={false}/>}
            { visibleData['button2']&& 
              <Line type="monotone" dataKey="cumulative_signups" stroke="#F5922F" strokeWidth="2" dot={false}/>}
            { visibleData['button3']&& 
              <Line type="monotone" dataKey="transactions" stroke="#E24C4C" strokeWidth="2" dot={false}/>}
            { visibleData['button4']&& 
              <Line type="monotone" dataKey="cumulative_transactions" stroke="#1DD28B" strokeWidth="2" dot={false}/>}
            { visibleData['button5']&& 
              <Line type="monotone" dataKey="generated" stroke="#65C1F2" strokeWidth="2" dot={false}/>}
            { visibleData['button6']&& 
              <Line type="monotone" dataKey="cumulative_generated" stroke="#65C1F2" strokeWidth="2" dot={false}/>}
            { visibleData['button7']&& 
              <Line type="monotone" dataKey="payouts" stroke="#65C1F2" strokeWidth="2" dot={false}/>}
            { visibleData['button8']&& 
              <Line type="monotone" dataKey="cumulative_payouts" stroke="#65C1F2" strokeWidth="2" dot={false}/>}
          </LineChart>
        </ResponsiveContainer>
        <ChartButtons buttons={buttons} />
        <ChartButtons2 buttons={buttons2} />
      </Card>
      : <Spin size='large' style={styles.spinner} />
    )
  }

  const AmountTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div style={styles.tooltipContent}>
          <Typography style={styles.tooltipLabel}>{label}</Typography>
          <div style={{padding: '4px 16px'}}>
            {payload && payload.map((item, i) => {
              return (
                <div style={{...styles.tooltipItem, color: '#E24C4C' }}>
                  <span style={{color: '#000', paddingRight: 32}}>{payload[i].name}: </span>
                  <span>PHP {payload[i].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
              )
            }) 
            }
          </div>
        </div>
      );
    }
  
    return null;
  };

  const AmountChart = ({data, loading}) => {
    let max_amount = data ? Math.max.apply(Math, data.map(function(o) { return o.amount; })) : 10000;
    return (
      !loading&&data ?
        <Card style={{borderRadius: 10}}>
          <Typography style={{...styles.title, padding: '0px 0 24px 0'}}>Amount</Typography>
          <ResponsiveContainer height={280}>
            <LineChart
              data={data}
              >
              <CartesianGrid strokeDasharray="3 0" vertical={false} />
              <XAxis dataKey="name" key={randomize()} interval={data.length >= 60 ? 30 : data.length >= 14 ? 7 : 1}/>
              <YAxis domain={[0, max_amount]}/>
              <Tooltip  content={<AmountTooltip />}/>
              <Line type="monotone" dataKey="amount" stroke="#E24C4C" strokeWidth="2" dot={false}/>
              <Line type="monotone" dataKey="payouts_total_amount" stroke="#1DD28B" strokeWidth="2" dot={false}/>
            </LineChart>
          </ResponsiveContainer>
        </Card>
        : <Spin size='large' style={styles.spinner} />
    )
  }

  return (
    <div style={styles.root} >
      <Typography style={styles.title}>BUx Trend</Typography>
      <div>
        <Chart data={data} loading={isLoading} />
      </div>
      <div style={{padding: '20px 0'}}>
        <AmountChart data={data} loading={isLoading} />
      </div>
    </div>
  )
}

const styles = {
  root: {

  },
  title: {
    fontSize: 16,
    fontWeight: '600',
    color: '#000',
    padding: '16px 0'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 0'
  },
  button: {
    margin: 4,
    height: 40,
    borderRadius: 10,
    border: '1px solid #1DD28B'
  },
  spinner: {
    // height: '100%', 
    width: '100%', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    height: 100,
  },
  tooltipLabel: {
    color:'#0D3D76', 
    fontWeight: '600', 
    fontSize: 16, 
    borderBottom: '1px solid #E6EAF0', 
    padding: 12
  },
  tooltipContent: {
    padding: 0, 
    borderRadius: 10, 
    boxShawdow: '0px 9px 14px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#FFF',
    border: '1px solid #E6EAF0'
  },
  tooltipItem: { 
    padding: '8px 0', 
    fontWeight: '600', 
    display: 'flex', 
    justifyContent: 'space-between'
  }
}

export default BuxTrends;