import axios from 'axios';
import {Handle401} from '../handle401/handle401'

const authorizedAxiosRequest = (value) => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const params = (params) => {
  if (typeof params == "number"){
    return ({
      id: params.toString(),
    })
  } else {
    return ({
      params:{
        target: params.target,
        type: params.type,
        page_number: params.page_number
      }
    })
  }
}

const notificationServices = {
  getNotifications: async (value) => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/notifications/`, params(value));
    return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  readNotification: async (value) => {
    try {
      const response = await authorizedAxiosRequest().put(`/api/notifications/`, params(value));
      return response;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
}

export default notificationServices;