import { getLocation, searchLocation } from './locatorServices';

export const LocationActionTypes = {

    LOCATION_REQUEST: "@LOCATION_REQUEST",
    LOCATION_SUCCESS: "@LOCATION_SUCCESS",
    LOCATION_FAILED: "@LOCATION_FAILED",

};

export function getLocations() {
    return async (dispatch) => {

        dispatch({
            type: LocationActionTypes.LOCATION_SUCCESS,
            payload: {}
        });
        try {
            dispatch({ type: LocationActionTypes.LOCATION_REQUEST });
            let response = await getLocation();

            dispatch({
                type: LocationActionTypes.LOCATION_SUCCESS,
                payload: response
            });
        }

        catch (error) {
            console.log(error)
            dispatch({
                type: LocationActionTypes.LOCATION_FAILED
            });

            console.log(error.message)
        }
    }
}

export function searchLocations(value) {
    return async (dispatch) => {
        
        dispatch({
            type: LocationActionTypes.LOCATION_SUCCESS,
            payload: {}
        });
        try {
            dispatch({ type: LocationActionTypes.LOCATION_REQUEST })

            let response = await searchLocation(value);
            console.log(value)
            dispatch({
                type: LocationActionTypes.LOCATION_SUCCESS,
                payload: response
            });

        }

        catch (error) {
            console.log(error)
            dispatch({
                type: LocationActionTypes.LOCATION_FAILED
            });

            console.log(error.message)
        }
    }
}