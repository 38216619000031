import React from 'react'
import axios from 'axios'
import FeeStyles from '../style'
import {CardIcon} from '../../../../../static/img/feeTableIcon.js'
import CustomInput from '../customInput'
import { Switch, Spin, Modal, message, Button } from 'antd'

const CardPayment = props => {
    const fee = FeeStyles()

    const yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isXenditEnable, setIsXenditEnable] = React.useState(false)
    const [isUnionBankEnable, setIsUnionBankEnable] = React.useState(false)
    const [ccAvailable, setCcAvailable] = React.useState("")
    const [searchValue, setSearchValue] = React.useState(null);

    const [activeChannels, setActiveChannels] = React.useState([]);
    const [disabledChannels, setDisabledChannels] = React.useState([]);
    const [listCC, setListCC] = React.useState([]);
    const [ubpList, setUbpList] = React.useState([])
    const [xenditList, setXenditList] = React.useState([])
    const [editMode, setEditMode] = React.useState(false);

    function passEditMode(val){
        setEditMode(val)
        props.getEditModeValue(val)
    }

    const renderData = (value) => {
        // let filteredData = [];  
        // if (listCC && value && value !== null) {
        //     filteredData = listCC.filter(data => data.channel_code.toLowerCase().includes(value.toLowerCase()));
        //     console.log("DITRO AKO NAPUNTA")
        //     console.log(filteredData)
        //     return filteredData;
        // }   
            return listCC;
    }

    function handleChange (e, index) {
        const { name, value } = e.target
        const list = [...listCC]
        list[index][name] = value
        setListCC(list)
    };

    const submitOtc = () =>{
        let params = {
            fees: listCC,
            user_id: props.pUid
        }

        props.openModal(params, true)
    }

    const setFees = () => {
        if(props.data){
            let cc = []
            if(ccAvailable === 'Xendit') {
                for(let i = 0; i< props.data.length; i++){
                    if(props.data[i].channel_code == "visamc"){
                        console.log(props.data[i])
                        cc.push(props.data[i])
                    }
                }
            }

            else if(ccAvailable === 'UnionBank') {
                for(let i = 0; i< props.data.length; i++){
                    if(props.data[i].channel_code == "ubpcs"){
                        console.log(props.data[i])
                        cc.push(props.data[i])
                    }
                }
            }


            console.log(cc)

            setListCC(get_ccdc(cc))
        }

        else {
            console.log(props.data)
        }
    }

    const get_ccdc = (data) => {
        let BLANK_CC = [{'channel_code': '',
                            'channel_fee': 0,
                            'channel_percent': 0,
                            'bux_fee': '',
                            'bux_percent': '',
                            'minimum': '',
                            'maximum': '',
                            'total_fee': 0,
                            'tier': '',
                            'partner': ''}];
        if (data && data.length) {
            let preloaded_cc = data.map((cc) => {
                return {
                    'channel_code': cc.channel_code,
                    'channel_fee': cc.channel_fee,
                    'channel_percent': cc.channel_percent,
                    'bux_fee': cc.bux_fee,
                    'bux_percent': cc.bux_percent,
                    'minimum': cc.minimum,
                    'maximum': cc.maximum,
                    'channel': cc.channel_partner,
                    'total_fee': cc.total_fee,
                    'tier': cc.tier,
                    'partner': cc.channel_partner,
                }
            });
            return preloaded_cc;
        }
        return BLANK_CC; 
    }

    const changeCCStatus = () => {
        if(isXenditEnable || isUnionBankEnable){
            setIsModalOpen(true)
        }

        else {
            let enabled = [...activeChannels]
            let disabled = [...disabledChannels]
            if(!isUnionBankEnable) {
                disabled.splice('ubpcs')
                disabled.push('visamc')
                enabled.push('ubpcs')
                setIsUnionBankEnable(true)
                setIsXenditEnable(false)
            }

            else if (!isXenditEnable) {
                disabled.splice('visamc')
                disabled.push('ubpcs')
                enabled.push('visamc')
                setIsUnionBankEnable(false)
                setIsXenditEnable(true)
            }
       
            setActiveChannels(enabled)
            disableCC(enabled, disabled)
            // setIsCcEnable(true)
        }
    }

    const disableCc = () => {
   
        setIsModalOpen(!isModalOpen)
        let enabled = [...activeChannels]
        let disabled = [...disabledChannels]
        enabled.splice('Card Payments')
        if(ccAvailable === 'UnionBank') {
            setIsUnionBankEnable(false)
            disabled.push('ubpcs')
        }
        else if(ccAvailable === 'Xendit') {
            setIsXenditEnable(false)
            disabled.push('visamc')
        }
        setDisabledChannels(disabled)
        disableCC(enabled, disabled)
    }

    const disableCC = async (enabled, disabled) => {
    
        try { 
            let params = {'enabled': enabled, 'disabled': disabled, 'user_id': props.pUid}
            let response = await axios.post(
                process.env.REACT_APP_API_URL + `/api/corporate/channels/${props.pUid}/`, params, yourConfig)


                if(response.status == 200){
                    message.destroy()
                    message.info('Settings Applied', 2)
                }

                else {
                    console.log(response)
                }
        }

        catch (error) {
            console.log(error)
        }
    }


    const getChannels = async() => {
        try {

            let response = await axios.get(
                process.env.REACT_APP_API_URL + `/api/corporate/channels/${props.pUid}/`,yourConfig)
                if(response.status == 200){
                    console.log(response.data)
                    let cs = response.data.channels
                    let enabled = []
                    let disabled = response.data.disabled
                    for(var i=0;i<cs.length;i++){

                        let ind = disabled.indexOf(cs[i].channel_code);
                        if(ind == -1){
                            enabled.push(cs[i].channel);
                        }
                    }
                    setActiveChannels(enabled)
                    setDisabledChannels(response.data.disabled)
                    setCcAvailable(response.data.cc_enabled)
                    if(response.data.cc_enabled === 'UnionBank' && !response.data.disabled.includes('ubpcs')) {
                        setIsUnionBankEnable(true)
                        setIsXenditEnable(false)
                    }

                    else if(response.data.cc_enabled === 'Xendit' && !response.data.disabled.includes('visamc')) {
                        setIsXenditEnable(true)
                        setIsUnionBankEnable(false)
                    }

                    // else {
                    //     setIsXenditEnable(false)
                    //     setIsUnionBankEnable(false)
                    // }
                    // if(response.data.disabled.includes("visamc")) {
                    //     setIsCcEnable(false)
                    // }

                    // setIssXenditEnable(response.data.cc_enabled)

                }

                else {
                    console.log(response)
                }
        }

        catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        setFees()
        getChannels()
    },[props.data])
    
    return(
        !props.loading ?
        <div>
            <Modal 
                centered
                width={360}
                visible={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}>
                    <div>
                        <div className={fee.cardModalHeaderText}>
                            {ccAvailable} card payments
                        </div>
                        <div className={fee.cardModalText}>
                            Are you sure you want to inactive {ccAvailable} card payments to this merchant?
                        </div>

                        <div className={fee.cardBtnDiv}>
                            <button className={fee.cancelBtn} onClick={() => setIsModalOpen(false)}>
                                Cancel
                            </button>
                            <button className={fee.confirmBtn} onClick={() => disableCc()}>
                                Confirm
                            </button>
                        </div>
                    </div>
            </Modal>
            <div className={fee.innerDiv} style={{padding: '12px 0'}} hidden={ccAvailable === null}>
                <div className={fee.spaceBetween}>
                    <div>
                        <img src={CardIcon} alt="wallet" /> <span className={fee.channelHeader}>Credit / Debit Card</span>
                    </div>
                </div>

                <div className={fee.spaceBetween} style={{padding: '12px 0'}}>
                    <div>

                    </div>
                    <div>
                        <div hidden={!editMode}>
                            <button className={fee.cancelBtn} style={{marginRight: 10}} onClick={() => passEditMode(!editMode)}>Cancel</button>
                            <button className={fee.editBtn} onClick={() => submitOtc()}>Save</button>
                        </div>

                        <div hidden={editMode}>
                            <Button type='link' style={{fontSize: 16, color: '#F5922F', fontWeight: 'bold', padding: 0, }} onClick={() => passEditMode(!editMode)} disabled={props.editModeVal}>Edit Fee</Button>
                        </div>
                    </div>
                </div>
                {
                    ccAvailable === 'Xendit' ? 
                    <div>
                    <div className={fee.cardTop}>
                        <div className={fee.cardText}>
                            Xendit card payment is <strong>{isXenditEnable ? 'active' : 'inactive'}.</strong>
                        </div>
                        <div>
                            <Switch checked={isXenditEnable} onChange={() => changeCCStatus()} 
                            style={{margin: '12px 20px 12px 12px', backgroundColor: `${isXenditEnable ? '#F5922F' : 'D1D5DD'}`}} />
                        </div>
                    </div>
                    <table style={{width: '100%'}}>
                            <tr className={fee.tableHeader}>
                                <td className={fee.tableHeaderData}>
                                    BUx Fee
                                </td>
                                <td className={fee.tableHeaderData}>
                                    BUx %
                                </td>
                                <td className={fee.tableHeaderData} style={{width: '20%'}}>
                                    Xendit Fee
                                </td>
                                <td className={fee.tableHeaderData}>
                                    Xendit %
                                </td>
                                <td className={fee.tableHeaderData}>
                                    Total
                                </td>
                            </tr>
                            {
                            renderData('visamc') && renderData('visamc').map((item, key) => {
                                return(
                                <tr>
                                    <td className={fee.tableChannelText}>
                                        <div hidden={editMode}>
                                        &#8369; {item.bux_fee} 
                                        </div>
                                        <div hidden={!editMode}>
                                            <CustomInput  value={item.bux_fee} name={"bux_fee"} handleChangeVal={handleChange} placeholder={"BUx Fee"} index={key}/>
                                        </div>
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <div hidden={editMode}>
                                        {item.bux_percent}%
                                        </div>
                                        <div hidden={!editMode}>
                                            <CustomInput value={item.bux_percent} name={"bux_percent"} placeholder={"Percent"}  handleChangeVal={handleChange} index={key}/>
                                        </div>
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <div hidden={editMode}>
                                        &#8369; {item.channel_fee}
                                        </div>
                                        <div hidden={!editMode}>
                                            <CustomInput value={item.channel_fee} name={"channel_fee"} placeholder={"Channel Fee"}  handleChangeVal={handleChange} index={key}/>
                                        </div>
                                        {/* &#8369; {item.channel_fee}  */}
                                    </td>
                                    <td className={fee.tableChannelText}>
                                    <div hidden={editMode}>
                                        {item.channel_percent}%
                                        </div>
                                        <div hidden={!editMode}>
                                            <CustomInput value={item.channel_percent} name={"channel_percent"} placeholder={"Channel Percent"}  handleChangeVal={handleChange} index={key}/>
                                        </div>
                                        {/* {item.channel_percent}% */}
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <div>
                                        &#8369; {item.total_fee} 
                                        </div>
                                    </td>
                                </tr>

                                )
                            })
                        }
                    </table>
                </div>
                :
                ccAvailable === 'UnionBank' ?
                <div hidden={ccAvailable === 'Xendit'}>
                    <div className={fee.cardTop}>
                        <div className={fee.cardText}>
                            UnionBank card payment is <strong>{isUnionBankEnable ? 'active' : 'inactive'}.</strong>
                        </div>
                        <div >
                            <Switch checked={isUnionBankEnable} onChange={() => changeCCStatus()} 
                            style={{margin: '12px 20px 12px 12px', backgroundColor: `${isUnionBankEnable ? '#F5922F' : 'D1D5DD'}`}} />
                        </div>
                    </div>
                    <table style={{width: '100%'}}>
                        <tr className={fee.tableHeader}>
                            <td className={fee.tableHeaderData}>
                                BUx Fee
                            </td>
                            <td className={fee.tableHeaderData}>
                                BUx %
                            </td>
                            <td className={fee.tableHeaderData} style={{width: '20%'}}>
                                UnionBank Fee
                            </td>
                            <td className={fee.tableHeaderData}>
                                UnionBank %
                            </td>
                            <td className={fee.tableHeaderData}>
                                Total
                            </td>
                        </tr>
                        {
                            renderData('ubpcs') && renderData('ubpcs').map((item, key) => {
                                return(
                                <tr>
                                    <td className={fee.tableChannelText}>
                                        <div hidden={editMode}>
                                        &#8369; {item.bux_fee} 
                                        </div>
                                        <div hidden={!editMode}>
                                            <CustomInput  value={item.bux_fee} name={"bux_fee"} handleChangeVal={handleChange} placeholder={"BUx Fee"} index={key}/>
                                        </div>
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <div hidden={editMode}>
                                        {item.bux_percent}%
                                        </div>
                                        <div hidden={!editMode}>
                                            <CustomInput value={item.bux_percent} name={"bux_percent"} placeholder={"Percent"}  handleChangeVal={handleChange} index={key}/>
                                        </div>
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <div hidden={editMode}>
                                        &#8369; {item.channel_fee}
                                        </div>
                                        <div hidden={!editMode}>
                                            <CustomInput value={item.channel_fee} name={"channel_fee"} placeholder={"Channel Fee"}  handleChangeVal={handleChange} index={key}/>
                                        </div>
                                        {/* &#8369; {item.channel_fee}  */}
                                    </td>
                                    <td className={fee.tableChannelText}>
                                    <div hidden={editMode}>
                                        {item.channel_percent}%
                                        </div>
                                        <div hidden={!editMode}>
                                            <CustomInput value={item.channel_percent} name={"channel_percent"} placeholder={"Channel Percent"}  handleChangeVal={handleChange} index={key}/>
                                        </div>
                                        {/* {item.channel_percent}% */}
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <div>
                                        &#8369; {item.total_fee} 
                                        </div>
                                    </td>
                                </tr>

                                )
                            })
                        }
                    </table>
                </div>
                :''
                }
            </div>

            <div className={fee.cardText} hidden={ccAvailable !== null}>
                Xendit or UnionBank are not yet approved.
            </div>
        </div>
        :
        <Spin style={{width: '100%', height: 212, display: 'flex', alignItems: 'center', justifyContent: 'center'}} />

    )
}

export default CardPayment