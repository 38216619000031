import React, {useEffect} from 'react';
import axios from 'axios';
import kycStyles from '../../../static/css/Kyc'
import {Grid} from '@material-ui/core'
import {Button, Input, message} from 'antd'
import * as Style from '../../complete_profile/complete_profile_style'
import {history} from '../../../store/history'


const { TextArea } = Input;

const MobileKycIndividual = props => {

    const ks = kycStyles();

    useEffect(() => {
        if(localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") == null){
            history.push('/login')
        }
        else{
            if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
              history.push('/dashboard')
            }
            else if(localStorage.getItem("userType") == "MA"){
              history.push('/overview')
            }
            else{
                fetchProfile()
            }
            
        }
    },[])

    const pUid = props.match.params.id;

    const [firstName, setFirstName] = React.useState("")
    const [middleName, setMiddleName] = React.useState("")
    const [lastName, setLastName] = React.useState("")
    const [birthDate, setBirthDate] = React.useState("")
    const [birthPlace, setBirthPlace] = React.useState("")
    const [presentAddress, setPresentAddress] = React.useState("")
    const [permanentAddress, setPermanentAddress] = React.useState("")
    const [nationality, setNationality] = React.useState("")
    const [civilStatus, setCivilStatus] = React.useState("")

    const [mobileNumber, setMobileNumber] = React.useState("")
    const [emailAddress, setEmailAddress] = React.useState("")

    const [employmentStatus, setEmploymentStatus] = React.useState("")
    const [employerName, setEmployerName] = React.useState("")
    const [employerNatureBusiness, setEmployerNatureBusiness] = React.useState("")
    const [occupation, setOccupation] = React.useState("")
    const [sourceFunds, setSourceFunds] = React.useState("")
    const [remarks, setRemarks] = React.useState("")

    const [idURL, setIdURL] = React.useState("")
    const [selfieBlob, setSelfieBlob] = React.useState("")
    const [idType, setIdType] = React.useState("")
    const [idNumber, setIdNumber] = React.useState("")


    const [step, setStep] = React.useState(0)

    const CivilStatus = ['Single', 'Married', 'Widowed', 'Separated', 'Divorced']
    const EmploymentStatus = ['Regular', 'Probationary', 'Contractual', 
                         'Retired', 'Self-Employed', 'Others', 'Student']
    const SourceOfFunds = ['Salary','Business Income','Rental Income','Investment',
                            'Personal Savings','Inheritance','Sale of Property','Pension','Others']

    const IdType = ['Drivers License', 'Passport', 'TIN ID', 'SSS or UMID', 'GSIS', 'PRC ID', 'IBP ID',
                            'OWWA ID', 'Diplomat ID', 'OFW ID', 'Senior Citizen ID', 'Voters ID', 'Govt Office ID',
                            'Digitized Postal ID']

    async function fetchProfile(){
    
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.get(process.env.REACT_APP_API_URL + '/api/kyc/' + pUid + '/', yourConfig)

        console.log(response.data)

        setFirstName(response.data.first_name)
        setMiddleName(response.data.middle_name)
        setLastName(response.data.last_name)
        setBirthDate(response.data.birthday)
        setBirthPlace(response.data.birthplace)
        setPresentAddress(response.data.present_address)
        setPermanentAddress(response.data.permanent_address)
        setNationality(response.data.nationality)
        setCivilStatus(response.data.civil_status)

        setMobileNumber(response.data.contact)
        setEmailAddress(response.data.email)

        setEmploymentStatus(response.data.employment_status)
        setEmployerName(response.data.employer_name)
        setEmployerNatureBusiness(response.data.employer_industry)
        setOccupation(response.data.occupation)
        setSourceFunds(response.data.source_of_funds)
        setStep(response.data.step)
        setRemarks(response.data.remarks)

        setIdType(response.data.id_type)
        setIdNumber(response.data.id_number)
        setIdURL(response.data.id_image_url)
        setSelfieBlob(response.data.selfie_image_url)
    }

    async function approveSubmission(approval){
    
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = {approved: approval, remarks: remarks}
        let response = await axios.post(process.env.REACT_APP_API_URL + '/api/kyc/' + pUid + '/', params, yourConfig)
        if(response.data.status == 'success'){
            if(approval==1){
                message.success('Successfully approved');
            }else{
                message.success('Successfully rejected');
            }
            history.push('/submissions')
        }else{
            message.error('Failed ');
        }


    }


    return(
        <div>
            <div className={ks.amainBody}>
                <div><a href="/submissions/" className={ks.statusHeader}>Submissions</a><span> / {firstName}</span></div>

                <div style={Style.aReviewCard} align='left'>
                    <div>
                        <div style={Style.reviewCardHeader}>
                            <div style={Style.headerStyle}>Personal Information</div>
                        </div>
                    </div>

                    <Grid container style={{padding: '32px 32px 0px 32px'}}>
                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Name:</div>
                            </Grid>
                        
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{firstName} {middleName} {lastName}</div>
                            </Grid>
                        </Grid>
                        <Grid container style={{padding: '32px 32px 0px 32px'}}>
                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Birthdate:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{birthDate}</div>
                            </Grid>
                        </Grid>
                        <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Birthplace:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{birthPlace}</div>
                            </Grid>
                        </Grid>
                        <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Present Address:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{presentAddress}</div>
                            </Grid>
                        </Grid>

                        <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Permanent Address:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{permanentAddress}</div>
                            </Grid>
                        </Grid>

                        <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Nationality:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{nationality}</div>
                            </Grid>
                        </Grid>
                        <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Civil Status:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{CivilStatus[civilStatus]}</div>
                            </Grid>
                        </Grid>

                        <div style={Style.reviewCardHeader}>
                            <div style={Style.headerStyle}>Contact Information</div>
                        
                        </div>


                        <Grid container style={{padding: '32px 32px 0px 32px'}}>
                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Mobile Number:</div>
                            </Grid>
                        
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{mobileNumber}</div>
                            </Grid>
                        </Grid>

                        <Grid container style={{padding: '32px 32px 0px 32px'}}>
                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Email:</div>
                            </Grid>
                        
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{emailAddress}</div>
                            </Grid>
                        </Grid>

                        <div style={Style.reviewCardHeader}>
                            <div style={Style.headerStyle}>Employment Information</div>
                        </div>
                        
                        {/** Employment Info Edit View */}
                        <Grid container style={{padding: '32px 32px 0px 32px'}}>
                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Employment Status:</div>
                            </Grid>
                        
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{EmploymentStatus[employmentStatus]}</div>
                            </Grid>
                        </Grid>
                        <Grid container style={{padding: '32px 32px 0px 32px'}}>
                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Employer Name:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{employerName}</div>
                            </Grid>
                        </Grid>
                        <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Employer Nature of Business:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{employerNatureBusiness}</div>
                            </Grid>
                        </Grid>
                        <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Occupation:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{occupation}</div>
                            </Grid>
                        </Grid>

                        <Grid container style={{padding: '32px 32px 0px 32px'}}>

                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Source of funds:</div>
                            </Grid>
                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{SourceOfFunds[sourceFunds]}</div>
                            </Grid>
                        </Grid>

                        <div style={Style.reviewCardHeader}>
                            <div style={Style.headerStyle}>Identity Verification</div>
                        </div>

                        <Grid container style={{padding: '32px 32px 0px 32px'}}>
                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>ID Type:</div>
                            </Grid>

                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{IdType[idType]}</div>
                            </Grid>
                        </Grid>

                        <Grid container style={{padding: '32px 32px 0px 32px'}}>
                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>ID Number:</div>
                            </Grid>

                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>{idNumber}</div>
                            </Grid>
                        </Grid>

                        <Grid container style={{padding: '32px 32px 0px 32px'}}>
                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>Selfie Photo:</div>
                            </Grid>

                            <Grid xs={6} align='right'>
                                <div style={Style.PresentStyle}>
                                {selfieBlob ? <img src={selfieBlob} alt="avatar" style={{ width: '100%', height: '160px'}}/> : "None"}
                                    
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container style={{padding: '32px 32px 0px 32px'}}>
                            <Grid xs={6}>
                                <div style={Style.PresentStyle}>ID Photo:</div>
                            </Grid>

                            <Grid xs={6} align='right'>
                            <div style={Style.PresentStyle}>
                                {idURL ? <img src={idURL} alt="avatar" style={{ width: '100%' }} /> : "None"}
                            </div>
                            </Grid>
                        </Grid>

                </div>

            </div>

            <div style={Style.footerCard} align='center'>
            <div style={Style.footerHeader}>Approval</div>
            <div style={Style.aFooterDesc} align="left">
                Status: {
                    step == 10 ?

                    <span style={Style.ApprovedStyle}>Approved</span> :

                    step == 9 ? 

                    <span style={Style.RejectedStyle}>Rejected</span> :

                    <span style={Style.PendingStyle}>Pending</span>

                }
            </div>

            <div style={Style.aFooterDesc} align="left">
                Remarks: <span style={Style.PermaStyle}>{ remarks }</span>

            </div>

            <div style={{display: step == 7 || step == 8 || step == 10 ? '' : 'none', padding: '20px 20px 15px 20px'}} align='left'>

                <div style={{marginBottom: '20px'}}>
                    <div style={{width: '100%'}}>
                        <div style={Style.PresentStyle}>Remarks:</div>
                        <TextArea style={{ marginTop: '10px'}} rows={4} onChange={(e)=>setRemarks(e.target.value)} maxLength={200}/>
                    </div>
                </div>
                <Grid container>
                    <Grid lg={12}>
                        <Button style={Style.submitBtnStyle} onClick={() => approveSubmission(1)}>Approve</Button>
                    </Grid>
                    <Grid lg={12}>
                        <Button style={Style.cancelBtnStyle} onClick={() => approveSubmission(0)}>Reject</Button>
                    </Grid>
                </Grid>
            </div>

        </div> 

        </div>
    )
}


export default MobileKycIndividual;