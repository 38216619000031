import React,{} from 'react';
import { Buffer } from 'buffer';
import crypto from 'crypto';
import moment from 'moment';
import { Modal, Typography, Button, Divider } from 'antd';
import { PaybillsCreditCard } from '../../../static/icons/dashboard_icons';

const CardInfoModal = ({ visible, closeModal, cardInfo, encKey }) => {


  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");

  const expiry = cardInfo && cardInfo.expiryDate ? cardInfo.expiryDate : ''
  const splitExpiry = (expiry.split("/"))
  const segments = localStorage.getItem('segments');
  
 function decryptor(encryptedMessage) {
    try{
      let key = encKey;
      let encryptedMessageArray = encryptedMessage.split(':');
      let iv = Buffer.from(encryptedMessageArray.shift(), 'base64');
      let encryptedText = Buffer.from(encryptedMessageArray.join(':'), 'base64');
      let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from( key ), iv);
      let decryption = decipher.update(encryptedText);
      decryption = Buffer.concat([decryption, decipher.final()]);
      return decryption.toString();
    }catch(err){}
  }

 return (
    <Modal
        visible={visible} 
        footer={null} 
        onCancel={closeModal} 
        maskClosable={false} 
        width={360}
      >
      <div>

        <div style={styles.title}>
          < img src={PaybillsCreditCard} alt="No Icon" />
          <strong style={styles.regularFont}>Card Information</strong>
        </div>
        <br/>
        <div style={styles.content}>
          <div>
            <Typography style={styles.subtitle}>Card Number</Typography>
            <Typography style={styles.values}><strong>{cardInfo.apiCardNumber && encKey ? decryptor(cardInfo.apiCardNumber) : ''}</strong></Typography>
          </div>
          <Divider type='vertical' style={styles.divider} />
          <div>
            <Typography style={styles.subtitle}>Account Name</Typography>
            <Typography style={styles.values}><strong>{firstName + " " + lastName}</strong></Typography>
          </div>
        </div>
        <br />
        <div style={styles.date}>
          <Typography style={styles.subtitle}>Valid thru:</Typography>
          <Typography style={{padding: '0 0 0 4px'}}>{ cardInfo ? moment(cardInfo.expiryDate).format('MMM ') + '20'+splitExpiry[1] : '' }</Typography>
        </div>
      </div>
      <button className={`btn--${segments}`} onClick={closeModal} style={styles.modalBtn}>Got It</button>
    </Modal>
 )
}

const styles = {
  modalBtn: {
    margin: '16px 0px 0px 0px',
    height: 40,
    width: '100%',
    // backgroundColor: '#F5922F',
    // color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 4
  },
  title: {
    textAlign: 'left',
    display: 'flex', 
    alignItems: 'center',
    width: '100%',
    padding: '20px 0 0 0'
  },
  regularFont: {
    fontSize: 16,
    color: '#000000',
    padding: '0 0 0 12px'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: 12,
    border: '1px solid #F1F1F1',
    borderRadius: 13
  },
  subtitle: {
    fontSize: 12,
    color: '#2B2D33'
  },
  divider: {
    height: 45,
    margin: '0 16px'
  },
  values: {
    color: '#2B2D33'
  },
  date: {
    display: 'flex',
    alignItems: 'center'
  }
}


export default CardInfoModal;