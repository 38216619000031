import axios from 'axios'
import { Handle401 } from '../../handle401/handle401'

const authorizedAxiosRequest = () => {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    });
}

const addMoneySettlementServices = {

    getAddMoneySettlement: async (token, type, page, page_size = 10, start = "", end = "", summary = "Today", search = "", is_export = "", email = "") => {
        try {
            const res = await authorizedAxiosRequest().get(`api/add_money_settlement/?token=${token}&type=${type}&page=${page}&page_size=${page_size}&start=${start}&end=${end}&summary=${summary}&search=${search}&is_export=${is_export}&email=${email}`);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },

    approveAddMoney: async(params) => {
        try {
            const res = await authorizedAxiosRequest().post(`api/add_money_settlement/`, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    }
}


export default addMoneySettlementServices;