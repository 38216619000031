import React from "react";
import { Select, Input, Form } from "antd";
import { sourceOfFunds } from "../../constants";
import { FormWrapper } from "../FormWrapper";
import { 
  validateEmail, 
  validateMobileNumber, 
  validateNoSpecialCharacters 
} from "../../../../../../utils/validators";

export function SenderForm({
  sender_name,
  sender_gender,
  sender_address,
  sender_mobile_number,
  sender_email,
  sender_source_fund,
  purpose,
  form,
  updateFields,
}) {
  return (
    <FormWrapper title="Sender Details">
      <Form.Item
        label="Name"
        style={{ margin: 0 }}
      >
        {form.getFieldDecorator("sender_name", {
          initialValue: sender_name,
          rules: [
            { required: true, message: "Please input name" },
            { validator: (_, value) => validateNoSpecialCharacters(value) }
          ]
        })(
          <Input
            type="text"
            onChange={(e) => updateFields({ sender_name: e.target.value })}
            size="large"
          />
        )}
      </Form.Item>

      <Form.Item label="Gender" style={{ margin: 0 }}>
        {form.getFieldDecorator("sender_gender", {
          initialValue: sender_gender,
          rules: [
            { required: true, message: "Please select a gender" }
          ]
        })(
          <Select
            size="large"
            placeholder="Select Gender"
            onChange={(e) => updateFields({ sender_gender: e })}
          >
            <Select.Option key="male" value="male">
              Male
            </Select.Option>
            <Select.Option key="female" value="female">
              Female
            </Select.Option>
          </Select>
        )}

      </Form.Item>

      <Form.Item label="Address" style={{ margin: 0 }}>
        {form.getFieldDecorator("sender_address", {
          initialValue: sender_address,
          rules: [
            { required: true, message: "Please input address" }
          ]
        })(
          <Input
            type="text"
            onChange={(e) => updateFields({ sender_address: e.target.value })}
            size="large"
          />
        )}
      </Form.Item>

      <Form.Item
        label="Mobile number"
        style={{ margin: 0 }}
      >
        {form.getFieldDecorator("sender_mobile_number", {
          initialValue: sender_mobile_number,
          rules: [
            { required: true, message: "Please input mobile number" },
            { validator: (_, value) => validateMobileNumber(value) }
          ]
        })(
          <Input
            addonBefore="+63"
            type="number"
            onChange={(e) =>
              updateFields({ sender_mobile_number: e.target.value })
            }
            size="large"
          />
        )}
      </Form.Item>

      <Form.Item
        label="Email"
        style={{ margin: 0 }}
      >
        {form.getFieldDecorator("sender_email", {
          initialValue: sender_email,
          rules: [
            { required: true, message: "Please input email address" },
            { validator: (_, value) => validateEmail(value) }
          ]
        })(
          <Input
            type="email"
            onChange={(e) => updateFields({ sender_email: e.target.value })}
            size="large"
          />
        )}
      </Form.Item>

      <Form.Item label="Source of funds" style={{ margin: 0 }}>
       {form.getFieldDecorator("sender_source_fund", {
          initialValue: sender_source_fund,
          rules: [
            { required: true, message: "Please select source of fund" }
          ]
        })(
          <Select
            size="large"
            placeholder="Select source of funds"
            onChange={(e) => updateFields({ sender_source_fund: e })}
          >
            {sourceOfFunds.map((fund) => (
              <Select.Option key={fund} value={fund}>
                {fund}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <Form.Item label="Purpose">
        {form.getFieldDecorator("purpose", {
          initialValue: purpose,
          rules: [
            { required: true, message: "Please input purpose of transfer" }
          ]
        })(
          <Input.TextArea
            onChange={(e) => updateFields({ purpose: e.target.value })}
          />
        )}
      </Form.Item>
    </FormWrapper>
  );
}