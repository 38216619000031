import React, { useEffect } from "react";
import axios from "axios";
import fundStyle from "../../../../../static/css/Funds";
import MetaTag from "../../../../../components/meta_tag/meta_tag";
import { message, Button, DatePicker, Input, Icon, Table, Dropdown, Menu, Modal, Pagination, Select, Card } from "antd";
import { history } from "../../../../../store/history";
import { Handle401 } from "../../../../../components/handle401/handle401";
import moment from 'moment'
import { randomize } from '../../../../../components/constants/constants';
import ActionModal from "../../../../../components/AdminComponents/payout/actionModal";
import ClearFilter from '../../../../../components/AdminComponents/filter_btns/clear_filter_btn';
import UploadModal from '../../../../../components/AdminComponents/payout/upload_modal';
import PaymentHistoryModal from "./payment-history-modal";

const ButtonGroup = Button.Group;
const { Option } = Select

const CashbuxSettlement = props => {
    useEffect(() => {
        if (
            localStorage.getItem("jwtToken") === "" ||
            localStorage.getItem("jwtToken") == null
        ) {
            history.push("/login");
        } else {
            if (userType == "ME") {
                history.push("/dashboard");
            } else if (userType == "PS") {
                history.push("/orders");
            } else if (userType == "PF") {
                history.push("/recon");
            } else if (userType == "MA") {
                history.push("/overview");
            } else if (userType == "KC") {
                history.push("/submissions");
            } else {
                fetchCounts();
                fetchCollections("", statusSelected, 1, "", "", 10);
            }
        }
    }, []);

    //variables
    const userType = localStorage.getItem("userType")
    const segments = localStorage.getItem("segments")
    const yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    };

    const f = fundStyle();

    const dateFormat = 'MM-DD-YYYY';
    const status = ["Checking", "Approval", "Reprocess", "Audit"]

    //variables state
    //strings
    const [statusSelected, setStatusSelected] = React.useState(userType == "EN" ? "Checking" : userType == "AP" ? 'Approval' : userType == "AD" ? 'Reprocess' : '')
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [searchValue, setSearchValue] = React.useState('');
    const [collectionCounts, setCollectionCounts] = React.useState({ "Checking": 0, "Approval": 0, "Reprocess": 0, "Audit": 0 });
    
    //Payout Selection
    const [payoutSelection, setPayoutSelection] = React.useState('All')
    
    //nums
    const [totalPayout, setTotalPayout] = React.useState(0)
    const [adjustAmount, setAdjustAmount] = React.useState("0")
    const [selectedID, setSelectedID] = React.useState(0)
    const [totalMerchant, setTotalMerchant] = React.useState(0)
    const [totalCollectionCount, setTotalCollectionCount] = React.useState(0);
    const [totaltobeSettled, setTotaltobeSettled] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10)
    const [currentPage, setCurrentPage] = React.useState(1);
    const [paymentHistoryId, setPaymentHistoryId] = React.useState(null);

    //arrays
    const [rowsCheckedOnTable, setRowsCheckedOnTable] = React.useState([])
    const [rowsCheckedData, setRowsCheckedData] = React.useState([])
    const [selectedIDs, setSelectedIDs] = React.useState([])
    const [txnHistoryMultiple, setTxnHistoryMultiple] = React.useState([]);
    const [fundsData, setFundsData] = React.useState([]);

    //booleans
    const [hideClear, setHideClear] = React.useState(true);
    const [ready, setReady] = React.useState(false)
    const [disabledBtn, setDisabledBtn] = React.useState(true);
    const [showTotalModal, setShowTotalModal] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [rejectSelected, setRejectSelected] = React.useState(false);
    const [showSpin, setShowSpin] = React.useState(true);
    const [adjustModal, setAdjustModal] = React.useState(false);
    const [showUploadCSVModal, setShowUploadCSVModal] = React.useState(false);

    useEffect(() => {
        hideClear && fetchCounts();
    }, [hideClear])

    //functions
    function disabledStartDates(current) {
        // Cannot select days before today and today
        let isDisabled = current && current > moment().endOf('day');

        if (endDate) {
            // Cannot select days after the end date, if available
            isDisabled = isDisabled || current > moment(endDate, dateFormat).startOf('day');
        }

        return isDisabled;
    }

    function disabledEndDates(current) {
        // Cannot select days in the future
        let isDisabled = current && current > moment().endOf('day');

        if (startDate) {
            // Cannot select days before the start date, if available
            isDisabled = isDisabled || current < moment(startDate, dateFormat).startOf('day');
        }

        return isDisabled;
    }

    function onStartDateChange(date, dateString) {
        setStartDate(dateString);
        setDisabledBtn(false)
        setHideClear(false)
    }

    function onEndDateChange(date, dateString) {
        setEndDate(dateString);
        setDisabledBtn(false)
        setHideClear(false)
    }

    function closePaymentModal() {
        setShowTotalModal(false)
        setShowModal(false)
        setTxnHistoryMultiple([]); 
    }

    function onPageChange(selectedPage) {
        clearTableSelection();
        setCurrentPage(selectedPage);
        fetchCounts()
        fetchCollections(searchValue, statusSelected, selectedPage, startDate, endDate, pageSize);
    }

    function onPageSizeChange(ev) {
        setPageSize(ev);
        setCurrentPage(currentPage);
        clearTableSelection();
        fetchCounts()
        fetchCollections(searchValue, statusSelected, currentPage, startDate, endDate, ev);
    }

    function applyFilter() {
        fetchCounts()
        retrieveCollections();
    }

    async function clearFilter() {
        setPayoutSelection("All")
        setSearchValue("")
        setStartDate("")
        setEndDate("")
        setHideClear(true)
        setDisabledBtn(true)

        if (userType == "EN") {
            setStatusSelected("Checking")
            fetchCollections("", "Checking", 1, "", "", 10)
        }

        else if (userType == "AP") {
            setStatusSelected("Approval")
            setPageSize(10)
            fetchCollections("", "Approval", 1, "", "", 10)
        }

        else {
            setStatusSelected("Reprocess")
            setPageSize(10)
            fetchCollections("", "Reprocess", 1, "", "", 10)
        }
    }

    async function resendReport(record) {
        const endpoint_url = `/api/cashbux/collections/payouts/`;
        const params = {
            target_id: record.id,
        };

        try {
            let response = await axios.put(
                process.env.REACT_APP_API_URL + endpoint_url, params,
                yourConfig
            );
        
            if (response.status === 'failed') {
                throw new Error(response.message);
            }

            message.success(response.data.message);
        } catch (error) {
            message.destroy();
            message.error(error.message);
        }
    }

    async function exportTable() {
        const endpoint_url = process.env.REACT_APP_API_URL + "/api/cashbux/collections/";
        let query_params = [
            'status=' + statusSelected.toLocaleLowerCase(),
            'collection_type=' + payoutSelection,
            'export=true',
            'start=' + startDate,
            'end=' + endDate,
            'search=' + searchValue,
        ];
        const full_url = endpoint_url + '?' + query_params.join("&");

        try {
            let response = await axios.get(full_url, yourConfig);
            message.success(response.data.message);

        } catch (error) {
            if (error.response && error.response.data) {
                message.error(error.response.data.message, 10);
            } else if (error.response && error.response.status == 401) {
                Handle401();
            }
        }
    };

    async function exportHistory() {
        const ids_to_query = paymentHistoryId ? paymentHistoryId : rowsCheckedData.length > 0 ? rowsCheckedData.map(row => row.id) : [];

        const endpoint_url = process.env.REACT_APP_API_URL + "/api/cashbux/collections/transactions/export/";
        let query_params = [
            'ids=[' + ids_to_query.join() + ']',
            'all=true',
        ];
        const full_url = endpoint_url + '?' + query_params.join("&");

        try {
            let response = await axios.get(full_url, yourConfig);

            const blob = new Blob([response.data], { type: 'text/csv' })
            const blobURL = window.URL.createObjectURL(blob)
            const tempLink = document.createElement('a')
            tempLink.style.display = 'none'
            tempLink.href = blobURL
            tempLink.download = 'cashbux_collection_history.csv'
            tempLink.setAttribute('target', '_blank')
            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
            window.URL.revokeObjectURL(blobURL)

        } catch (error) {
            if (error.response && error.response.data) {
                message.error(error.response.data.message, 10);
            } else if (error.response && error.response.status == 401) {
                Handle401();
            }
        }
    };

    const workflowColumns = [
        {
            title: "Merchant Name",
            // width: 200,
            render: (text, record) => {
                return (
                    <div>
                        <div className={f.descSyle}><b>{record.merchant_business}</b></div>
                    </div>)
            }
        },
        {
            title: 'Transaction Type',
            // width: 200,
            render: (text, record) => {
                return (
                    <div className={f.descSyle}>{record.collection_type}</div>
                )
            }
        },
        {
            title: 'Amount',
            // width: 300,
            render: (text, record) => {
                return (
                    <div className={f.descSyle}>{"Php " + parseFloat(record.amount.replace(/,/g, '')).toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
                )
            }
        },
        {
            title: "Name and Email Address",
            dataIndex: "name_email",
            render: (text, record) => {
                return (
                    <div style={{ display: "flex", flexFlow: "column nowrap" }}>
                        <strong>{record.merchant_name}</strong>
                        <small>{record.merchant_email}</small>
                    </div>
                )
            }
        },
        {
            title: "Date Computed",
            // width: 200,
            render: (record) => {
                return (
                    <div>
                        {record.date_computed ? record.date_computed : ''}
                    </div>
                )
            }
        },
        {
            title: "Basis Date",
            // width: 200,
            render: (record) => {
                return (
                    <div>
                        {record.basis_date ? record.basis_date : ''}
                    </div>
                )
            }
        },
        {
            title: "Settlement Date",
            // width: 200,
            render: (record) => {
                return (
                    <div>
                        {record.settlement_date}
                    </div>
                )
            }
        },
        {
            title: 'Action',
            render: (text, record) => (
                <div>
                    <Dropdown overlay={() => menu(record)}
                        disabled={record.step < 8}
                        placement="bottomCenter" trigger={['click']} >
                        <Icon type="ellipsis" />
                    </Dropdown>
                </div>
            )
        }
    ];

    const clearTableSelection = () => {
        setRowsCheckedOnTable([])
        recomputeTotals([])
        setSelectedIDs([])
    };

    const rowSelected = {
        selectedRowKeys: rowsCheckedOnTable,
        onChange: (selectedRowKeys, selectedRows) => {
            let c_ids = selectedRows.map((item) => {
                return item.id
            });
            setRowsCheckedData(selectedRows)
            setRowsCheckedOnTable(selectedRowKeys)

            setSelectedIDs(c_ids)
            recomputeTotals(c_ids)

        },
    };

    const recomputeTotals = (selected_ids) => {
        let total_amount = 0, merchant_count = 0

        for (let id of selected_ids) {
            let captured_row = fundsData.find(x => x.id == id)
            total_amount += parseFloat(captured_row.amount.replace(/,/g, ''))
        }

        merchant_count = selected_ids.length
        setTotalMerchant(merchant_count)
        setTotalPayout(total_amount)
    };

    let menu = (record) => (
        <Menu>
            <Menu.Item key="0" onClick={() => onOpenHistoryModal(record)}>
                Payment History
            </Menu.Item>
            <Menu.Item
                key="1"
                onClick={() => resendReport(record)}
                hidden={statusSelected !== 'Audit'}
            >
                Resend Settlement Email
            </Menu.Item>
            <Menu.Item
                key="2"
                onClick={() => downloadReport(record)}
                hidden={statusSelected !== 'Audit'}
            >
                Download Settlement Report
            </Menu.Item>
            <Menu.Item
                key="3"
                onClick={() => { setSelectedID(record.id); setAdjustModal(true); }}
                hidden={statusSelected !== 'Checking'}
            >
                Adjust Payout Amount
            </Menu.Item>
        </Menu>
    );


    async function downloadReport(record) {
        if(record.settlement_report != null) {
            window.open(record.settlement_report, '_blank')
        }
    }


    let totalMenu = () => (
        <Menu style={{ width: '180px' }}>
            <Menu.Item key="0" onClick={() => onOpenHistoryModal()}>
                Total Payment History
            </Menu.Item>
        </Menu>
    );

    async function onOpenHistoryModal(record) {
        setPaymentHistoryId(record ? [record.id] : null);
        const ids_to_query = record ? [record.id] : rowsCheckedData.map(row => row.id);
        let transactions = await fetchTransactions(ids_to_query, 1, 5);
    
        const multipleHistoryData = ids_to_query.map(id => {
            let matchedRow = fundsData.find(x => x.id == id);
            return {
                ...matchedRow,
                transaction_list: transactions["results"].hasOwnProperty(id) ? transactions["results"][id]["transactions"] : [],
                total_count: transactions["results"].hasOwnProperty(id) ? transactions["results"][id]["count"] : 0
            }
        });
    
        setTxnHistoryMultiple(multipleHistoryData);
        setShowTotalModal(true)
    }

    async function onHistoryChangePage(page, id) {
        const ids_to_query = paymentHistoryId ? paymentHistoryId : rowsCheckedData.length > 0 ? rowsCheckedData.map(row => row.id) : [];
        let transactions = await fetchTransactions([id], page, 5);

        const multipleHistoryData = ids_to_query.map(id => {
            let matchedRow = fundsData.find(x => x.id == id);
            let matchedRowFromExistingHistory = txnHistoryMultiple.find(x => x.id == id);

            return {
                ...matchedRow,
                transaction_list: transactions["results"].hasOwnProperty(id) ? transactions["results"][id]["transactions"] : matchedRowFromExistingHistory["transaction_list"],
                total_count: transactions["results"].hasOwnProperty(id) ? transactions["results"][id]["count"] : matchedRowFromExistingHistory["total_count"]
            }
        });

        setTxnHistoryMultiple(multipleHistoryData);
    }

    async function fetchTransactions(ids_to_query, page = 1, page_size = 10) {
        const endpoint_url = process.env.REACT_APP_API_URL + "/api/cashbux/collections/transactions/"
        let query_params = [
            'page=' + page,
            'page_size=' + page_size,
            'ids=[' + ids_to_query.join() + ']'
        ];
        const full_url = endpoint_url + '?' + query_params.join("&");

        try {
            let response = await axios.get(full_url, yourConfig);
            return response.data;

        } catch (error) {
            if (error.response && error.response.data) {
                message.error(error.response.data.message, 10);
                return {}
            } else if (error.response && error.response.status == 401) {
                Handle401();
            }
        }
    }

    async function fetchCounts() {
        const endpoint_url = process.env.REACT_APP_API_URL + "/api/cashbux/collections/counts/"
        let query_params = [
            'collection_type=' + payoutSelection,
            'start=' + startDate,
            'end=' + endDate,
            'search=' + searchValue,
        ];
        const full_url = endpoint_url + '?' + query_params.join("&");

        try {
            let response = await axios.get(full_url, yourConfig);
            setCollectionCounts({
                Checking: response.data.checking,
                Approval: response.data.approval,
                Reprocess: response.data.reprocess,
                Audit: response.data.audit,
            });
            setReady(true);
        } catch (error) {
            if (error.response && error.response.data) {
                message.error(error.response.data.message, 10);
            } else if (error.response && error.response.status == 401) {
                Handle401();
            }
        }
    }

    async function adjustSubmit(){

        let params = {
            collection_id: selectedID,
            amount: adjustAmount
        }

        try {
            let response = await axios.post(
                process.env.REACT_APP_API_URL + "/api/cashbux/collections/adjust/",
                params,
                yourConfig
            );

            fetchCounts();
            setAdjustModal(false);
            message.success(response.data.message, 6)
            retrieveCollections();
        } catch (error) {

                if(error.response){
                    message.error(error.response.data, 10)
                }
      
                else if (error.response && error.response.status == 401) {
                    Handle401();
                }
        }
        setAdjustModal(false);
        setAdjustAmount(0);
    }

    function retrieveCollections() {
        setCurrentPage(1);
        fetchCollections(searchValue, statusSelected, 1, startDate, endDate, pageSize);
    }

    async function fetchCollections(search, approval_status, page_number, start_date, end_date, page_size) {

        if (!approval_status) {
            return;
        }

        if(!page_size) {
            page_size = 10;
        }

        const endpoint_url = process.env.REACT_APP_API_URL + "/api/cashbux/collections/";
        let query_params = [
            'status=' + approval_status.toLocaleLowerCase(),
            'collection_type=' + payoutSelection,
            'start=' + start_date,
            'end=' + end_date,
            'page=' + page_number,
            'page_size=' + page_size,
            'search=' + search,
            
        ];
        const full_url = endpoint_url + '?' + query_params.join("&");

        try {
            let response = await axios.get(full_url, yourConfig);
            setFundsData(response.data.results);
            setTotalCollectionCount(response.data.total);
            setTotaltobeSettled(response.data.total_settle_amount ? response.data.total_settle_amount : 0);
            setReady(true);

        } catch (error) {
            if (error.response && error.response.data) {
                message.error(error.response.data.message, 10);
                setFundsData([]);
            } else if (error.response && error.response.status == 401) {
                Handle401();
            }
        }
    }

    async function onEndorseCollection(setShowSpin, setRemarks, remarks){
        let params = {
            remarks: remarks,
            collection_ids: selectedIDs
        }

        const endpoint = "/api/cashbux/collections/"

        try {
            let response = await axios.patch(
                process.env.REACT_APP_API_URL + endpoint,
                params,
                yourConfig
            );

            onSuccess(response, setShowSpin, setRemarks);
        } catch (error) {
            onError(error, setShowSpin, setRemarks);
        }
    }

    async function onRejectCollection(setShowSpin, setRemarks, remarks){
        let params = {
            remarks: remarks,
            collection_ids: selectedIDs
        }

        const endpoint = "/api/cashbux/collections/"

        try {
            let response = await axios.put(
                process.env.REACT_APP_API_URL + endpoint, params,
                yourConfig
            );
      
            onSuccess(response, setShowSpin, setRemarks);
        } catch (error) {
            onError(error, setShowSpin, setRemarks);
        }
    }

    async function onApproveCollection(setShowSpin, setRemarks, remarks){
        let params = {
            remarks: remarks,
            collection_ids: selectedIDs,
        }

        const endpoint = "/api/cashbux/collections/"

        try {
            let response = await axios.post(
                process.env.REACT_APP_API_URL + endpoint, params,
                yourConfig
            );
      
            onSuccess(response, setShowSpin, setRemarks);
        } catch (error) {
            onError(error, setShowSpin, setRemarks);
        }
    }

    async function onReprocessCollection(setShowSpin, setRemarks, remarks){
        let params = {
            collection_ids: selectedIDs
        }

        const endpoint = "/api/cashbux/collections/reprocess/";
        
        try {
            let response = await axios.post(
                process.env.REACT_APP_API_URL + endpoint, params,
                yourConfig
            );

            onSuccess(response, setShowSpin, setRemarks);
        } catch (error) {
            onError(error, setShowSpin, setRemarks);
        }
    }

    function onError(error, setShowSpin, setRemarks) {
        setShowSpin(true);

        if(error.response){
            setRemarks("");
            closePaymentModal();
            message.error(error.response.data.message, 5);
        }

        if (error.response && error.response.status == 401) {
            Handle401();
        }
    }

    function onSuccess(response, setShowSpin, setRemarks) {
        let { status, errors } = response.data;

        if(status == "success") {
            fetchCounts();
            retrieveCollections();
            setRemarks("");
            closePaymentModal();
            setShowSpin(true);
            if (response.data.message) {
                message.success(response.data.message, 6);
            }
        } else if (errors.length) {
            errors.forEach((msg) => {
                message.error(msg, 6);
            })
        }
    }

    function getColumns() {
        if (statusSelected == "Reprocess") {
            const items = workflowColumns;
            const j = items.findIndex(item => item.title === "Action");
            items.splice([j], 1);
            return items;
        }

        return workflowColumns;
    }

    return (
        <div hidden={!ready} style={{ margin: '24px 2.5em 2.5em', paddingBottom: '50px' }}>
            <MetaTag title="Cashbux Settlement" />
            <PaymentHistoryModal totalHistory={txnHistoryMultiple} openModal={showTotalModal} closeModal={closePaymentModal} title={"Payment History"} onChangePage={onHistoryChangePage} onExport={exportHistory}/>
            <ActionModal
                openModal={showModal}
                closeModal={closePaymentModal}
                statusSelected={statusSelected}
                rejectSelected={rejectSelected}
                selectedIDs={selectedIDs}
                clearTableSelection={clearTableSelection}
                setCurrentPage={setCurrentPage}
                fetchReprocess={retrieveCollections}
                fetchCounts={fetchCounts}
                fetchCollections={retrieveCollections} 
                onEndorseAction={onEndorseCollection}
                onRejectAction={onRejectCollection}
                onApproveAction={onApproveCollection}
                onRetryPayoutAction={onReprocessCollection}
            />

            <Modal
                title="Adjust Amount"
                width={350}
                visible={adjustModal}
                onCancel={()=>setAdjustModal(false)}
                footer={null}>

                <div style={{marginTop: 16}}>
                    <div style={{marginBottom: 8, fontSize: 12, lineHeight: '24px'}}>
                        Enter new amount
                    </div> 

                <Input
                    placeholder="Amount"
                    style={{width: '100%'}}
                    value={adjustAmount}
                    onChange={e => { setAdjustAmount(e.target.value);}}
                />
                </div>

                <div style={{marginTop: 32, display: 'flex', justifyContent: 'space-between'}}>
                <Button style={{
                                marginRight: '16px',
                                width: '50%',
                                fontWeight: 600,
                                backgroundColor: "#f5f5f4",
                                border: "#2b2d33",
                                height: "40px",
                                borderRadius: "10px",
                }}
                onClick={() => setAdjustModal(false)}>
                    Cancel
                </Button>
                <Button
                    style={{
                        width: '50%',
                        backgroundColor: rejectSelected ? "#d9534f" : "#1DD28B",
                        border: rejectSelected ? "#d9534f" : "#1DD28B",
                        height: "40px",
                        borderRadius: "10px",
                        opacity: !showSpin ? '0.5' : '',
                        cursor: !showSpin ? 'not-allowed' : 'pointer'
                    }}

                    onClick={() => adjustSubmit()}
                >
                Submit
                </Button>
                </div>
            </Modal>
            <div className="settlement-header">Settlement</div>
            <div className="settlement-header-buttons">
                <ButtonGroup>
                    {status.map((item, i) => {
                        return (
                            <Button
                                key={item.toLocaleLowerCase()}
                                onClick={() => {
                                    clearTableSelection();
                                    setStatusSelected(item);
                                    setPageSize(pageSize ? pageSize : 10)
                                    setCurrentPage(1);
                                    fetchCollections(searchValue, item, 1, startDate, endDate, pageSize);
                                }}
                                className={"cashbux-settlement-tab-btn " + (statusSelected === item ? ' active' : '')}
                            >
                                {item}
                                ({collectionCounts[item]})
                            </Button>
                        );
                    })}
                </ButtonGroup>

                <div>
                    <Button
                        hidden={statusSelected != "Checking"}
                        style={{
                            marginRight: '12px'
                        }}
                        size="large"
                        onClick={()=>setShowUploadCSVModal(true)}
                        >
                            <Icon type="upload"/>
                            Upload CSV
                    </Button>

                    <Button
                        size="large"
                        onClick={() => exportTable()}
                    >
                        <Icon type="download"/>
                        Export CSV
                    </Button>
                    <UploadModal handleRefresh={() => retrieveCollections()} handleClose={()=>setShowUploadCSVModal(false)} modal={showUploadCSVModal} visible={showUploadCSVModal} onUploadEndpoint={"/api/cashbux/collections/batch_adjust/"}/>
                </div>
            </div>
            
            <Card className="settlement-card">
                <div className={f.pillFlex} style={{ marginBottom: '16px' }}>
                    <div style={{ display: 'flex' }}>
                        <ClearFilter hideFilterBtn={hideClear} clearFilter={() => {clearFilter()}} />
                    </div>

                    <div hidden>
                        <div className={f.noteStyle}><Icon type="info-circle" theme="filled" style={{ paddingRight: 4 }} />Click <b>Apply</b> button to show results.</div>
                    </div>
                </div>
                
                <div className="settlement-filter">
                    <Select 
                        className="collection-type-select"
                        value={payoutSelection}
                        onChange={(e) => {setPayoutSelection(e); setDisabledBtn(false); setHideClear(false)}}
                        >
                        <Option value="All">All</Option>
                        <Option value="cashout">Cash Out</Option>
                        <Option value="debit" >POS Debit</Option>
                    </Select> 
                    {   statusSelected !== "Reprocess" &&
                        <>
                            <DatePicker
                                hidden={statusSelected === "Reprocess"}
                                value={startDate ? moment(startDate) : null}
                                format={dateFormat}
                                disabledDate={disabledStartDates}
                                onChange={onStartDateChange}
                                placeholder='Start Date'
                                className="date-pic"
                                allowClear={false}
                                disabled={statusSelected === "Reprocess"} 
                                style={{ display: (statusSelected === "Reprocess") ? 'none' : 'block'}}
                            />
                            <span className="date-pic-hyphen">-</span>
                            <DatePicker
                                hidden={statusSelected === "Reprocess"}
                                value={endDate ? moment(endDate) : null}
                                format={dateFormat}
                                disabledDate={disabledEndDates}
                                onChange={onEndDateChange}
                                placeholder='End Date'
                                className="date-pic"
                                allowClear={false}
                                disabled={statusSelected === "Reprocess"} 
                                style={{ display: (statusSelected === "Reprocess") ? 'none' : 'block'}}
                            />  
                        </>
                    }

                    <Input
                        placeholder="Search"
                        className="search-input"
                        suffix={<Icon type="search" style={{ fontSize: '18px'}} />}
                        onChange={e => { setSearchValue(e.target.value); setDisabledBtn(false); setHideClear(false) }}
                        value={searchValue}
                    />
                    <Button
                        disabled={disabledBtn}
                        onClick={() => applyFilter()}
                    >Apply</Button>
                </div>

                <div className="settlement-totals">
                    <div>
                        <span>Total Payout: </span>
                        <span className="value-label">
                        Php 
                            { statusSelected == "Audit" ?
                                ` ${parseFloat(totaltobeSettled).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
                            :
                                ` ${parseFloat(totalPayout).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
                            }
                        </span>
                    </div>

                    <div>
                        <span>Total {statusSelected == "Reprocess" ? "Payout" : "Merchant"}(s): </span>
                        <span className="value-label">
                            {statusSelected == "Audit" ? totalCollectionCount : totalMerchant} {statusSelected == "Reprocess" ? "payout" : "merchant"}(s)
                        </span>
                    </div>

                    {   statusSelected !== "Audit" &&

                        <div>
                            <span>Total to be Settled: </span>
                            <span className="value-label">
                                Php {parseFloat(totaltobeSettled).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                            </span>
                        </div>
                    }
                </div>

                <Table
                    rowKey={record => record.id}
                    size="middle"
                    pagination={false}
                    rowSelection={statusSelected == 'Audit' ? false : { ...rowSelected }}
                    rowClassName={(record, index) =>
                        index % 2 === 0 ? "table-row-light" : "table-row-dark"
                    }
                    dataSource={fundsData}
                    columns={getColumns()}
                    scroll={{ x: true }}
                />
                <div align="right" style={{ width: '100%', marginTop: '20px' }} hidden={statusSelected == 'Audit'}>
                    <div hidden={userType !== 'EN' && userType !== 'AP' && userType !== 'OP' && userType !== "AD"}>
                        <Button
                            className={`right-4`}
                            style={{
                                display: statusSelected == "Approval" ? 'inline' : 'none'
                            }}
                            disabled={selectedIDs.length == 0}
                            onClick={() => {
                                setShowModal(true);
                                setRejectSelected(true)
                            }}
                        >
                            Reject
                        </Button>

                        <Button
                            className={`right-8`}
                            type="primary"
                            disabled={selectedIDs.length == 0}
                            onClick={() => {
                                setShowModal(true);
                                setRejectSelected(false)
                            }}
                        >
                                {
                                    statusSelected == "Reprocess" ? "Retry Payout" :
                                        statusSelected == "Checking" ? "Endorse" :
                                            statusSelected == "Approval" ? "Payout" : ''}
                        </Button>
                        {statusSelected !== "Reprocess" ?
                            <Dropdown overlay={() => totalMenu()}
                                disabled={selectedIDs.length == 0}
                                style={{ marginRight: 16, cursor: selectedIDs.length == 0 ? 'not-allowed' : 'pointer' }}
                                placement="bottomCenter" trigger={['click']} >
                                <Icon type="ellipsis" />
                            </Dropdown>
                            : ''
                        }
                    </div>
                </div>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <div style={{ width: '100%', display: 'flex', textAlign: 'right' }}>
                        <Select
                            showSearch
                            onChange={(ev) => {
                                setPageSize(ev);
                                onPageSizeChange(ev);
                            }}
                            placeholder="Page Size"
                            style={{ width: '110px', marginBottom: '10px', marginLeft: 'auto', marginRight: '20px' }}
                            size="small"
                        >
                            <Option key={1} value={10}>Show 10</Option>
                            <Option key={2} value={20}>Show 20</Option>
                            <Option key={3} value={50}>Show 50</Option>
                            <Option key={4} value={100}>Show 100</Option>
                            <Option key={5} value={150}>Show 150</Option>

                        </Select>

                        <Pagination
                            size="small"
                            total={totalCollectionCount}
                            showTotal={(total, range) => `${range[0]}-${range[1]} out of ${total}`}
                            onChange={onPageChange}
                            defaultCurrent={1}
                            key={randomize()}
                            pageSize={pageSize}
                            current={currentPage}
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default CashbuxSettlement;
