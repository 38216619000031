import { axios } from "../../../../../../lib/axios";
import { useState, useEffect } from "react";

export function getSubmissions(currentPage, searchQuery, status) {
  return axios.get(
    `api/cashbux/bank_submissions/?page=${currentPage}&search=${searchQuery}&status=${status}`,
  );
}

export function useSubmissions(currentPage = 1, searchQuery = "", status = "") {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getSubmissions(currentPage, searchQuery, status);
        setData(response);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, [currentPage, searchQuery, status]);

  return { data, isLoading, error };
}
