import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import { createTransfer, submitOTP } from "../../api";
import {
  TransferReceipt,
  PesonetDisclaimerModal,
} from "../../routes/merchant/components";
import { INITIAL_FORM_DATA } from "./constants";
import {
  Confirmation,
  OtpEmail,
  OtpSms,
  ReceiverForm,
  SenderForm,
} from "./forms";
import { useMultistepForm } from "./hooks";

function MultistepForm({ form, onClose, balance, banks }) {
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [transferDetails, setTransferDetails] = useState(null);
  const [otpDetails, setOtpDetails] = useState({
    code: "",
    otp_type: 1,
    uid: "",
  });
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [smsOtpActivated, setSmsOtpActivated] = useState(false);
  const [transferError, setTransferError] = useState(null);
  const [otpError, setOtpError] = useState(null);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpLoading, setIsOtpLoading] = useState(false);

  const [mode, setMode] = useState("");
  const [isPesonetModalVisible, setIsPesonetModalVisible] = useState(false);

  useEffect(() => {
    if (otpError || transferError) {
      setShowErrorAlert(true);
      const timer = setTimeout(() => {
        setShowErrorAlert(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [otpError, transferError]);

  const updateFields = (fields) => {
    setFormData((prev) => {
      return { ...prev, ...fields };
    });
  };

  const {
    steps,
    currentStepIndex,
    step,
    isFirstStep,
    isLastStep,
    back,
    next,
    goTo,
  } = useMultistepForm([
    <SenderForm
      form={form}
      {...formData}
      updateFields={updateFields}
      setTransferError={setTransferError}
    />,
    <ReceiverForm
      form={form}
      {...formData}
      balance={balance}
      banks={banks}
      mode={mode}
      setMode={setMode}
      updateFields={updateFields}
      transferError={transferError}
      showErrorAlert={showErrorAlert}
    />,
    <Confirmation {...formData} />,
    smsOtpActivated ? (
      <OtpSms
        otpDetails={otpDetails}
        setOtpDetails={setOtpDetails}
        showErrorAlert={showErrorAlert}
        otpError={otpError}
        setOtpError={setOtpError}
        isOtpLoading={isOtpLoading}
        setIsOtpLoading={setIsOtpLoading}
      />
    ) : (
      <OtpEmail
        otpDetails={otpDetails}
        setOtpDetails={setOtpDetails}
        otpError={otpError}
        showErrorAlert={showErrorAlert}
        setOtpError={setOtpError}
        smsOtpActivated={smsOtpActivated}
        setSmsOtpActivated={setSmsOtpActivated}
        isOtpLoading={isOtpLoading}
        setIsOtpLoading={setIsOtpLoading}
      />
    ),
  ]);

  const onSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, _) => {
      if (currentStepIndex === 1 && mode === "PesoNet") {
        return setIsPesonetModalVisible(true);
      }

      if (!err && currentStepIndex < 3) {
        return next();
      }

      if (currentStepIndex === 1 && mode === "AmountLimitError") return;
      if (currentStepIndex === 1 && mode === "UnsupportedBank") return;
      if (isLastStep) {
        const otpType = smsOtpActivated ? "/api/otp/" : "/api/email_otp/";
        setIsLoading(true);
        submitOTP(otpType, otpDetails)
          .then((response) => {
            const {
              receiver_account_name,
              receiver_account_number,
              receiver_bank,
              receiver_amount,
              code,
            } = formData;

            const transferDetails = {
              account_name: receiver_account_name,
              account_number: receiver_account_number,
              amount: receiver_amount,
              bank: receiver_bank,
              code,
              bank_account_id: "",
              mode,
              payout_uid: response.trans_uid,
              is_sw_transfer: true,
              rail_type: balance.rail_type,
              transfer_details: {
                ...formData,
              },
            };

            createTransfer(transferDetails)
              .then((data) => {
                if (data.status === "failed") {
                  setTransactionStatus(data.status);
                  setTransferError(data);
                  setOtpDetails({
                    code: "",
                    otp_type: 1,
                    uid: "",
                  });
                  setSmsOtpActivated(false);
                  setIsLoading(false);
                  goTo(1);
                  return;
                }

                setTransferDetails({
                  ...transferDetails,
                  transferResults: {
                    ...data,
                  },
                });
                setTransactionStatus(data.status);
              })
              .finally(() => setIsLoading(false));
          })
          .catch((error) => {
            const { status, code, message } = error.response.data;
            setOtpError({ status, code, message });
          })
          .finally(() => setIsLoading(false));
      }
    });
  };

  const handleOnClose = () => {
    setFormData(INITIAL_FORM_DATA);
    setOtpDetails({ code: "", otp_type: 1, uid: "" });
    setTransactionStatus(null);
    setSmsOtpActivated(false);
    goTo(0);
    onClose();
  };

  return (
    <div
      style={{
        position: "relative",
        background: "white",
        padding: "1rem",
        height: "100%",
      }}
    >
      <Form onSubmit={onSubmit} style={{ height: "100%" }}>
        {transactionStatus !== "success" && !isOtpLoading && (
          <div
            style={{
              position: "absolute",
              top: "0",
              right: ".5rem",
              fontSize: "12px",
              borderRadius: "0.5em",
              padding: "0 0.5em",
              border: "1px solid lightgray",
            }}
          >
            {currentStepIndex + 1} / {steps.length}
          </div>
        )}
        {transactionStatus !== "success" && step}
        {transactionStatus !== "success" && (
          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              gap: ".5rem",
              justifyContent: "flex-end",
            }}
          >
            {!isFirstStep && !isLastStep && (
              <Form.Item>
                <Button
                  size="large"
                  htmlType="button"
                  onClick={back}
                  style={{
                    backgroundColor: "rgb(0, 81, 176)",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  Back
                </Button>
              </Form.Item>
            )}
            {!isOtpLoading && (
              <Form.Item>
                <Button
                  size="large"
                  loading={isLoading}
                  htmlType="submit"
                  style={{
                    backgroundColor: "rgb(0, 81, 176)",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                  disabled={
                    (currentStepIndex === 1 && mode === "AmountLimitError") ||
                    (currentStepIndex === 1 && mode === "UnsupportedBank") ||
                    (currentStepIndex === 1 && mode === "")
                  }
                >
                  {isLastStep ? "Finish" : "Next"}
                </Button>
              </Form.Item>
            )}
          </div>
        )}
        {transactionStatus === "success" && (
          <TransferReceipt
            transferDetails={transferDetails}
            onClose={handleOnClose}
          />
        )}
      </Form>
      <PesonetDisclaimerModal
        isPesonetModalVisible={isPesonetModalVisible}
        setIsPesonetModalVisible={setIsPesonetModalVisible}
        next={next}
        goTo={goTo}
        mode={mode}
      />
    </div>
  );
}

export default Form.create({ name: "create_bank_transfer" })(MultistepForm);
