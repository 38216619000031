import React,{ useState } from 'react';
import { Card, Switch } from 'antd';
import {Handle401} from '../handle401/handle401';
import settingServices from './settingServices';
import { isMobile } from 'react-device-detect'

const EmailNotifsToggle = ({initialSwitchValue}) => {
  const [isSwitchOn, setIsSwitchOn] = useState(initialSwitchValue);
  const [isSwitchMovable, setIsSwitchMovable] = useState(true);

  const onSwitchFlip = (event) => {
    setIsSwitchMovable(false);
    valueUpdate(!isSwitchOn);
  }

    async function valueUpdate(switchValue){

      let params = {
        'name': 'receives_payment_related_emails',
        'type': 'bool',
        'value': switchValue,
      }

      try {
        const userID = localStorage.getItem("userId");
        let res = await settingServices.saveUserDefinedSettings(userID, params);

        if (res.status === "success"){
          setIsSwitchOn(switchValue);
        }

      } catch(error) {
        if((error.response)&&(error.response.status === 401)){
          Handle401()
        }
      }

      setIsSwitchMovable(true);
    }
  return (
    <div>
    {
      isMobile
      ?
        <div>
          <div style={styles.twoCol}>
            <div style={styles.font3}>
            Payment Request Email Notification
            </div>
            <div>
              <Switch checked={isSwitchOn} onChange={onSwitchFlip} style={{margin: '12px 20px 12px 12px', backgroundColor: `${isSwitchOn ? '#F5922F' : '#D1D5DD'}`}} disabled={!isSwitchMovable}/>
            </div>
          </div>

          <div style={styles.textContent}>
            {isSwitchOn
            ?
              <p>Your email notifications are currently <b>enabled</b>. You will receive emails about your paid payment requests.</p>
            :
              <p>Your email notifications are currently <b>disabled</b>. No emails about your paid payment requests will be sent to you.</p>
            }
          </div>
        </div>

      :
        <Card size="small" style={{...styles.root, width: 700}}>
          <div style={styles.main}>
            <div style={styles.textContent}>
              <strong style={styles.font1}>Payment Request Email Notification</strong>
              { isSwitchOn
              ?
                <div>
                  <label style={styles.font2}>Your email notifications are currently <strong>enabled</strong>.</label><br/>
                  <label style={styles.font2}>You will receive emails about your paid payment requests.</label>
                </div>
              :
                <div>
                  <label style={styles.font2}>Your email notifications are currently <strong>disabled</strong>.</label><br/>
                  <label style={styles.font2}>No emails about your paid payment requests will be sent to you.</label>
                </div>
              }
            </div>
            <Switch
              checked={isSwitchOn}
              onChange={onSwitchFlip}
              style={{marginRight: 5, backgroundColor: `${isSwitchOn ? '#F5922F' : '#D1D5DD'}`}}
              disabled={!isSwitchMovable}
            />
          </div>
        </Card>
    }
    </div>
  )
}

const styles = {
  root: {
    margin: '0px 0px 20px 0px'
  },
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: "3px 0px 3px 5px"
  },
  textContent: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  font1: {
    color: '#0D3D76',
    fontSize: 16,
    marginBottom: 10
  },
  font2: {
    color: '#2B2D33',
    fontSize: 14,
    textAlign: 'left'
  },

  font3: {
    color: '#0D3D76',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '32px'
  },

  twoCol: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}

export default EmailNotifsToggle;