import React, { useCallback } from "react";
import multiwalletServices from "../../multiwalletServices";
import {
  Typography,
  Button,
  Icon,
  Table,
  Pagination,
  Input,
  message,
  Form,
  InputNumber,
  Dropdown,
  Menu,
} from "antd";
import { useSelector } from "react-redux";
import OtpModal from "../otp_modal";
import { history } from "../../../../store/history";
import SuccessTransferRequestModal from "./success_transfer_request_modal";

const statusDict = {
  full: "Fully Approved",
  partial: "Partially Approved",
  pending: "Pending Approval",
};

const StatusChip = ({ current, total }) => {
  const type =
    current === 0
      ? statusDict.pending
      : current < total
      ? statusDict.partial
      : statusDict.full;

  const statusChipStyles = {
    [statusDict.full]: {
      background: "#E4F7EC",
      color: "#3A843F",
    },
    [statusDict.partial]: {
      background: "#C2DDF9",
      color: "#3363BB",
    },
    [statusDict.pending]: {
      background: "#EEEEEE",
      color: "#50535E",
    },
  };

  const style = statusChipStyles[type];

  return (
    <div
      style={{
        ...style,
        height: 24,
        whiteSpace: "nowrap",
        padding: "0 8px",
        borderRadius: 8,
        display: "flex",
        alignItems: "center",
        fontWeight: 700,
        width: "min-content",
        fontSize: "12px",
      }}
    >
      {type}
    </div>
  );
};

const TransferMoneyRequestTable = (props) => {
  const { segments, data, isLoading, onTransferSuccess, subwalletData, enableTransfer } = props;
  const [loading, setLoading] = React.useState(false);
  const [searchVal, setSearchVal] = React.useState(null);
  const [transferSuccessShow, setTransferSuccessShow] = React.useState(false);
  const [otpModalShow, setOtpModalShow] = React.useState(false);
  const [otpData, setOtpData] = React.useState(null);
  const [resetTimer, setResetTimer] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [minPage, setMinPage] = React.useState(0);
  const [maxPage, setMaxPage] = React.useState(pageSize);
  const [isEmailOtp, setIsEmailOtp] = React.useState(false);
  const [tablePreviewData, setTablePreviewData] = React.useState(null);
  const testMode = history.location.pathname.indexOf("/test") > -1;
  const [transferRequestData, setTransferRequestData] = React.useState([]);
  const [transferRequestResult, setTransferRequestResult] = React.useState({});
  const [totalCount, setTotalCount] = React.useState(0);
  const userId = parseInt(localStorage.getItem("userId"));

  const definedWalletSettings = JSON.parse(
    localStorage.getItem("defined_wallet_settings")
  );
  const minimumApproverCount = parseInt(
    localStorage.getItem("minimum_approver_count") || 0
  );
  const { wallet_transfer_id_enabled: walletTransferIdEnabled = false } =
    definedWalletSettings;

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "recipient",
      render: (recipient) => recipient && recipient.name,
    },
    {
      title: "Email Address",
      dataIndex: "recipient",
      render: (recipient) => recipient && recipient.email,
    },
    {
      title: "Mobile Number",
      dataIndex: "recipient",
      render: (recipient) => recipient && recipient.contact,
    },
    {
      title: "Transfer ID",
      dataIndex: "transfer_id",
      render: (_, record) =>
        (record.payload &&
          record.payload.field1 &&
          record.payload.field1.value) ||
        "-",
      hidden: !walletTransferIdEnabled,
    },
    {
      title: "No. of Approval",
      dataIndex: "approver_list",
      align: "center",
      hidden: !minimumApproverCount,
      render: (approvers) => `${approvers.length}/${minimumApproverCount}`,
    },
    {
      title: "Approval Status",
      dataIndex: "approver_list",
      hidden: !minimumApproverCount,
      render: (approvers) => (
        <StatusChip current={approvers.length} total={minimumApproverCount} />
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Action",
      dataIndex: "approver_list",
      width: "10%",
      align: "center",
      hidden: !enableTransfer,
      render: (approvers = [], record) => (
        <Dropdown
          overlay={
            <Menu>
              {!approvers.includes(userId) && minimumApproverCount > 0 && (
                <Menu.Item
                  onClick={() => partialApproveTransferMoneyRequest(record.id)}
                >
                  Approve
                </Menu.Item>
              )}
              <Menu.Item onClick={() => rejectTransferRequest(record.id)}>
                Remove
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Icon type="ellipsis" />
        </Dropdown>
      ),
    },
  ].filter((c) => !c.hidden);

  const handleSearch = (e) => {
    setSearchVal(e.target.value);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const getTransferRequests = async () => {
    const params = {
      page_size: pageSize,
      page: currentPage,
      search: searchVal,
    };
    try {
      const data = await multiwalletServices.getTransferMoneyRequests(params);
      const { results: swResults = [] } = subwalletData || {};
      setTransferRequestData(
        data.results.map((r) => ({
          ...swResults.find((sw) => sw.id === r.destination),
          ...r,
        }))
      );
      setTotalCount(data.total);
      setMaxPage(data.pages);
    } catch (error) {
      message.error(error.message);
    }
  };

  const rejectTransferRequest = async (id) => {
    try {
      await multiwalletServices.rejectTrasnferMoneyRequest(id);
      message.info("Transfer request rejected.");
      getTransferRequests();
    } catch (error) {
      message.error(error.message);
    }
  };

  const partialApproveTransferMoneyRequest = async (id) => {
    try {
      await multiwalletServices.partialApproveTransferMoneyRequest(id);
      message.info("Transfer request approved.");
      getTransferRequests();
    } catch (error) {
      message.error(error.message);
    }
  };

  const onTransfer = async (transfer_money_uid) => {
    setLoading(true);
    try {
      const data = {
        ids: transferRequestData
          .filter((r) => r.approver_list.length >= minimumApproverCount)
          .map((r) => r.id),
      };
      const params = { transfer_money_uid };
      const res = await multiwalletServices.approveTransferMoneyRequests(
        data,
        params
      );
      if (res.status === "success") {
        setTransferRequestResult(res);
        setTransferSuccessShow(true);
      } else {
        message.error(res.message);
      }
    } catch (error) {
      message.error("Please try again later.");
    } finally {
      getTransferRequests();
    }
    setLoading(false);
  };

  const fetchOTP = async () => {
    setLoading(true);
    try {
      const res = await multiwalletServices.getOTP(testMode);
      if (res.status === "success") {
        setOtpData(res);
        setOtpModalShow(true);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleEmailOtp = async () => {
    setLoading(true);
    try {
      const response = await multiwalletServices.getOtpEmail("transfer_money");
      if (response.status === "success") {
        setOtpData("");
        setIsEmailOtp(true);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (!isLoading) {
      getTransferRequests();
    }
  }, [isLoading, currentPage]);

  const totalAmount = transferRequestData
    .filter((data) => data.approver_list.length >= minimumApproverCount)
    .reduce((total, current) => (total += parseFloat(current.amount)), 0);

  const enableTransferButton =
    transferRequestData.length > 0 &&
    transferRequestData.some(
      (data) => data.approver_list.length >= minimumApproverCount
    );

  return (
    <div style={styles.root}>
      <div style={styles.headerDiv}>
        <Typography style={styles.title}>Transfer Money Box</Typography>
      </div>
      <div style={styles.body}>
        <div
          style={{
            ...styles.tableHeader,
            justifyContent: !(transferRequestData && transferRequestData.length)
              ? "flex-end"
              : "space-between",
          }}
        >
          <Typography
            hidden={!(transferRequestData && transferRequestData.length)}
            style={styles.title}
          >
            {transferRequestData && transferRequestData.length} sub-wallet added
          </Typography>
          <Input
            placeholder="Search"
            style={{
              width: "200px",
              height: "40px",
              borderRadius: "4px",
              padding: "0 8px 0 0px",
            }}
            suffix={<Icon hidden type="search" style={{ fontSize: "18px" }} />}
            onChange={handleSearch}
            value={searchVal}
            hidden
          />
        </div>
        <Table
          rowClassName="table-row-light"
          dataSource={!isLoading && transferRequestData}
          loading={isLoading || loading}
          columns={tableColumns}
          pagination={false}
        />
        <Pagination
          size="small"
          total={totalCount}
          defaultPageSize={10}
          defaultCurrent={1}
          current={currentPage}
          onChange={(page, pageSize) => changePage(page, pageSize)}
          style={{ padding: "12px 0 8px 0", textAlign: "right" }}
        />
      </div>
      <div style={styles.footer}>
        <div style={styles.balDiv}>
          <Typography style={styles.balTitle}>Total Transfer Amount</Typography>
          <Typography style={styles.bal}>
            ₱{totalAmount.toLocaleString("en-US", { minimumFractionDigits: 2 })}
          </Typography>
        </div>
        {enableTransfer && <button
          className={`btn--${segments} btn-height`}
          disabled={!enableTransferButton}
          loading={loading}
          onClick={testMode ? onTransfer : fetchOTP}
        >
          Transfer Money
        </button>}
      </div>
      <SuccessTransferRequestModal
        segments={segments}
        visible={transferSuccessShow}
        onClose={() => setTransferSuccessShow(false) / onTransferSuccess()}
        data={transferRequestResult}
      />
      <OtpModal
        visible={otpModalShow}
        resetTimer={resetTimer}
        setResetTimer={() => setResetTimer(false)}
        closeModal={() => setOtpModalShow(false) / setIsEmailOtp(false)}
        otpData={otpData}
        onSuccess={(transfer_money_uid) => onTransfer(transfer_money_uid)}
        onResend={fetchOTP}
        onEmailOtp={handleEmailOtp}
        isEmailOtp={isEmailOtp}
      />
    </div>
  );
};

const styles = {
  root: {
    margin: "24px 0",
    backgroundColor: "transparent",
    padding: "0px 16px",
    backgroundColor: "#FFF",
    border: "1px solid #E6EAF0",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
  },
  title: {
    color: "#2B2D32",
    fontWeight: "bold",
    fontSize: 18,
  },
  headerDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px 0",
  },
  body: {},
  transferBtn: {
    height: 40,
    fontSize: 16,
    fontWeight: "600",
    borderRadius: 4,
    backgroundColor: "#1DD28B",
    color: "#FFF",
    textAlign: "right",
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "24px 0",
  },
  dload: {
    fontSize: 16,
    fontWeight: "600",
    height: 40,
    // borderRadius: 10,
    borderColor: "#1DD28B",
    marginRight: 8,
  },
  bal: {
    color: "#2B2D32",
    fontWeight: "bold",
    fontSize: 28,
  },
  balTitle: {
    color: "#2B2D32",
    fontWeight: "500",
    fontSize: 16,
  },
  balDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px 0",
  },
  removeBtn: {
    backgroundColor: "#E24C4C",
    borderRadius: "50%",
    color: "#FFF",
    width: 16,
    height: 16,
    lineHeight: "13px",
    textAlign: "center",
    margin: "0 8px 0 16px",
    fontWeight: "bold",
  },
  amountDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default TransferMoneyRequestTable;
