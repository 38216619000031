import React from 'react'
import axios from 'axios'
import {Dropdown, Menu, Icon, message, Input} from 'antd'
import ActionConfirmationModal from '../confirmation_modal'
import PermissionsDrawer from './mobile_permissions_drawer'
import mobileUserStyle from '../style'
import UserModal from '../new_user_modal'

const MobileUserAccount = props => {
    const userType = localStorage.getItem('userType') 
    const pUid = userType == 'CO' || userType == "ME" ? '' : props.props.match.params.id;

    const mu = mobileUserStyle()

    const [showModal, setShowModal] = React.useState(false)
    const [userData, setUserData] = React.useState([])

    // Confirmation Modal
    const [isConfirmModalVisible, setIsConfirmModalVisible] = React.useState(false);
    const [confirmModalHeaderText, setConfirmModalHeaderText] = React.useState('');
    const [confirmModalBodyText, setConfirmModalBodyText] = React.useState('');
    const [confirmModalCallback, setConfirmModalCallback] = React.useState(null);
    const [searchValue, setSearchValue] = React.useState('')

    // Edit Permissions Modal
    const [isPermissionsDrawerVisible, setIsPermissionsDrawerVisible] = React.useState(false);

    function closeModal(){
        setShowModal(false)
    }

    React.useEffect(() => {
        getCorporate()
    }, [])

    
    async function getCorporate() {
        try {

            const yourConfig = {
                headers: {
                  Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            };
            
            let base_url = process.env.REACT_APP_API_URL + "/api/enterprise/alias/"
            let url = localStorage.getItem("userType") == "CO" ? base_url : base_url+`?parent_id=${pUid}`

            let response = await axios.get(url,yourConfig)

            console.log(response.data)
            setUserData(response.data)
        }

        catch(error) {
            console.log(error)
        }
    }

    async function patchUser(id, action) {
        try {
            const yourConfig = {
                headers: {
                  Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            };

            let params = {
                alias_id: id,
                action
            }

            if(localStorage.getItem('userType') !== "CO"){
                params.parent_id = props.id
            }

            let url = process.env.REACT_APP_API_URL + "/api/enterprise/alias/";
            let response = await axios.patch(url,params,yourConfig)

            message.info(response.data, 2)
            getCorporate()
        }

        catch(error) {
            console.log(error)
        }
    }

    let menu = (alias_user) => (
      
        <Menu style={{ width: '240px' }}>
    
            <Menu.Item
                key="0"
                onClick={() => {
                    setConfirmModalCallback(() => {
                        return () => {
                            patchUser(alias_user.id, "suspend");
                            setIsConfirmModalVisible(false);
                        }
                    });

                    setConfirmModalHeaderText("Deactivate Account");
                    setConfirmModalBodyText(
                        <span>deactivate the account of <b>{alias_user.full_name}</b></span>
                    );
                    setIsConfirmModalVisible(true);
                }}
                style={{color: '#e61f1f'}}
                hidden={alias_user.suspended}
            >
                Deactivate
            </Menu.Item>
            <Menu.Item
                key="1"
                onClick={() => {
                    setConfirmModalCallback(() => {
                        return () => {
                            patchUser(alias_user.id, "reactivate");
                            setIsConfirmModalVisible(false);
                        }
                    });

                    setConfirmModalHeaderText("Reactivate Account")
                    setConfirmModalBodyText(
                        <span>reactivate the account of <b>{alias_user.full_name}</b></span>
                    );
                    setIsConfirmModalVisible(true);
                }}
                style={{color: "#4e922f"}}
                hidden={!alias_user.suspended}
            >
                Reactivate
            </Menu.Item>
            <Menu.Item
                key="2"
                onClick={() => {
                    setConfirmModalCallback(() => {
                        return () => {
                            patchUser(alias_user.id, "subscribe_settlement_reports");
                            setIsConfirmModalVisible(false);
                        }
                    });

                    setConfirmModalHeaderText("Subscribe to Settlement Reports")
                    setConfirmModalBodyText(
                        <span>subscribe the account of <b>{alias_user.full_name}</b> to settlement reports</span>
                    );
                    setIsConfirmModalVisible(true);
                }}
                hidden={alias_user.suspended || alias_user.can_view_settlement_report}
            >
                Subscribe to Settlement Reports
            </Menu.Item>
            <Menu.Item
                key="3"
                onClick={() => {
                    setConfirmModalCallback(() => {
                        return () => {
                            patchUser(alias_user.id, "stop_settlement_reports");
                            setIsConfirmModalVisible(false);
                        }
                    });

                    setConfirmModalHeaderText("Stop Settlement Reports")
                    setConfirmModalBodyText(
                        <span>stop sending settlement reports to <b>{alias_user.full_name}</b></span>
                    );
                    setIsConfirmModalVisible(true);
                }}
                hidden={alias_user.suspended || !alias_user.can_view_settlement_report}
            >
                Stop Settlement Reports
            </Menu.Item>
        </Menu>
    );

    function renderSingleFields(val, status) {
        
        let status_filtered = [];

        status_filtered = userData;
    
        if(val=="" || val==null) {
            return status_filtered
        }

        let filtered_data = status_filtered.filter(single_user => single_user.full_name.toLowerCase().includes(val.toLowerCase()) || 
                                                                    single_user.username.toLowerCase().includes(val.toLowerCase()))
        
        return filtered_data
    }

    return ( 
        <div className={mu.mobileMainDiv} style={{height: renderSingleFields(searchValue).length == 0 ? '100vh' : '100%'}}>

            <Input
                style={{marginBottom: 16}}
                placeholder="Search"
                onChange={(e)=> setSearchValue(e.target.value)}
                prefix={<Icon type="search" />}
            />

            <div className={mu.twoCol}>
                <button
                    onClick={() => setIsPermissionsDrawerVisible(true)}
                    className="hoverable-edit-button"
                    style={user_account_styles.editButton}
                >
                    Edit Permissions
                </button>

                <div>
                    <button onClick={() => setShowModal(true)} className={mu.newBtn}>+ Add New User</button>
                </div>
            </div>

            {
               renderSingleFields(searchValue).length != 0 && renderSingleFields(searchValue) != null ?
               renderSingleFields(searchValue).length > 0 && renderSingleFields(searchValue).map((alias_user, i) => {
               return (
                <div className={mu.mobileCard} style={{position: 'relative'}}>
                    <div style={{padding: 16}}>
                        <div style={{position: 'absolute', top: 20, right: 20}}>
                            <Dropdown overlay={() => menu(alias_user)} 
                                        placement="bottomRight" trigger={['click']} >
                                        <Icon type="ellipsis" />
                            </Dropdown>
                        </div>
                        <div>
                            <div className={mu.mobileUserName}>{alias_user.full_name}</div>
                        </div>
                    </div>
            
                    <div className={mu.mobileCardFooterDiv}>
                        <div className={mu.twoCol}>
                            <div>Email:</div>
                            <div>{alias_user.email}</div>
                        </div>
                        <div className={mu.twoCol}>
                            <div>Mobile Number:</div>
                            <div>{alias_user.contact}</div>
                        </div>
                        <div className={mu.twoCol}>
                            <div>Settlement Report:</div>
                            <div>{alias_user.can_view_settlement_report ? "Subscribed" : 'Not Available'}</div>
                        </div>
                    </div>
            
                    <div className={mu.mobileCardFooterDiv}>
                        <div className={mu.twoCol}>
                            <div>Status:</div>
                            <div style={{
                                width: 80,
                                height: '34px',
                                borderRadius: '4px',
                                padding: '4px 8px',
                                color: '#000000',
                                border: alias_user.suspended ? '1px solid #909196' : '1px solid #1DD28B',
                                borderLeft: alias_user.suspended ? '4px solid #909196' : '4px solid #1dd28b',
                                background: alias_user.suspended ? 'rgba(144, 145, 150, 0.1)' : 'rgba(29, 210, 139, 0.1)'
                            }}>
                                {alias_user.suspended ? "Inactive" : 'Active'}
                            </div>
                        </div>
                    </div>
                </div>
               )
               },this)
               :
               <div className={mu.nothingToShow} align="center">
                   Nothing to show
               </div>
            }
            

            <UserModal id={pUid} getCorporate={getCorporate} showModal={showModal} closeModal={closeModal} />
            <ActionConfirmationModal
                isConfirmModalVisible={isConfirmModalVisible}
                setIsConfirmModalVisible={setIsConfirmModalVisible}
                confirmModalCallback={confirmModalCallback}
                confirmModalHeaderText={confirmModalHeaderText}
                confirmModalBodyText={confirmModalBodyText}
            />
            <PermissionsDrawer
                isPermissionsDrawerVisible={isPermissionsDrawerVisible}
                setIsPermissionsDrawerVisible={setIsPermissionsDrawerVisible}
                pUid={pUid}
                disabled={!userData.length}
            />
            
        </div>
    )
}

const user_account_styles = {
    editButtonDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    editButton: {
        border: 'none',
        background: 'none',
        color: '#e6892e',
        outline: 'none',
        fontWeight: 600,
        margin: '0px 10px 0px 0px',
        fontSize: '16px'
    }
}

export default MobileUserAccount