import { getPaymentNow } from "./paymentServices";

export const PaymentActionTypes = {
    GET_PAYMENT_REQUEST: "@GET_PAYMENT_REQUEST",
    GET_PAYMENT_SUCCESS: "@GET_PAYMENT_SUCCESS",
    GET_PAYMENT_FAILED: "@GET_PAYMENT_FAILED",
};

export function paymentGet(uid, testMode) {
    return async (dispatch) => {
      try {
        dispatch({ type: PaymentActionTypes.GET_PAYMENT_REQUEST });
        let response = await getPaymentNow(uid, testMode);

        dispatch({
          type: PaymentActionTypes.GET_PAYMENT_SUCCESS,
          payload: response.data
        });

        
      } catch (error) {
        dispatch({
          type: PaymentActionTypes.GET_PAYMENT_FAILED
        });
        console.log(error.message)
      }
    };
  }