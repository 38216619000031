import React from 'react';
import { Input, Typography, Card, Button, Radio, Dropdown, Popover, Checkbox, Select } from 'antd';
import BG from '../../../static/icons/questionnaire.svg';
import surveyServices from '../surveyServices';
import { isMobile } from 'react-device-detect';
import { history } from '../../../store/history';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {getLatestBalance} from '../../../layouts/Main'

const defaultAns = {
  3:{
    question_id: 3, 
    answer_id: '', 
    text_answer: '',
    question_type: 'CB'
  },
  4: {
    question_id: 4, 
    answer_id: '', 
    text_answer: '',
    question_type: 'CB'
  }
}

const QuestionCard = ({data, isLoading}) => {
  const [answers, setAnswers] = React.useState(defaultAns);
  const [loading, setLoading] = React.useState(false);
  const [indeterminate, setIndeterminate] = React.useState([]);

  const autoFormat = (value) => value == null ? null : value.replace(/\D/g, '');

  const handleChange = (event, value, questionType) => {
  if(value) {
    setAnswers({...answers, [event]:{
      question_id: event,
      answer_id: value,
      text_answer: value,
      question_type: questionType,
    }});
  } else if (event.target.name === 2) {
    setAnswers({...answers, [event.target.name]:{
      question_id: event.target.name,
      answer_id: event.target.value,
      text_answer: event.target.value,
      question_type: 'RD',
    }});
  }
    else {
      setAnswers({...answers, [event.target.name]:{
        question_id: parseInt(event.target.name),
        answer_id: autoFormat(event.target.value),
        text_answer: autoFormat(event.target.value),
        question_type: event.target.id,
        }
      }) 
    }
  }

  const questionTwo = (choices, questionID, questionType) => {
    // let filteredValues = []
    // Object.keys(choices).map(i => i != 0 && filteredValues.push(choices[i].id))

    const handleCheckChange = (values) => {
     if(values.includes(3) && indeterminate[questionID] || values.includes(8) && indeterminate[questionID]) {
      setAnswers({...answers, [questionID]:{
        question_id: questionID,
        answer_id: choices,
        text_answer: '',
        question_type: questionType,
      }})
     } else if (values.includes(3) && !indeterminate[questionID] || values.includes(8) && !indeterminate[questionID]) {
      setAnswers({...answers, [questionID]:{
        question_id: questionID,
        answer_id: choices,
        text_answer: '',
        question_type: questionType,
      }})
     }
      else {
      setAnswers({...answers, [questionID]:{
        question_id: questionID,
        answer_id: values,
        text_answer: '',
        question_type: questionType,
      }})
     }
    }

    const onCheckAll = (id) => {
      setIndeterminate({...indeterminate, [id]: !indeterminate[id]});
    }

   return (
    <Checkbox.Group style={styles.checkboxGroup} onChange={(checkedValues) => handleCheckChange(checkedValues)}>
      {
        choices.map((choice, i) => {
          return <Checkbox style={{marginLeft: 0}} onClick={ i==0 ? () => onCheckAll(questionID) : null } indeterminate={indeterminate[questionID]} checked={answers[questionID].answer_id.includes(choice.id)} value={choice.id}>{choice.value}</Checkbox>
        })
      }
    </Checkbox.Group>
   )
  }

  const submitAnswers = async () => {
    let params = [];
    Object.keys(answers).map( i => params.push(answers[i]));
    setLoading(true);
    try {
      const res = await surveyServices.submitAnswers({answers: params});
      if(res.status === 'success'){
        localStorage.setItem('survey_enterprise', true);
        getLatestBalance()
        history.push('/welcome_page');
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const returnSelected = (question,id) => {
    let val;
    Object.keys(data[question].choices).map(i => {
      if(data[question].choices[i].id == id) {
        val = data[question].choices[i].value;
      } 
    });
    return val;
  }

  const disabledBtn = () => {
    let inputFields = [];
    for (let i = 0; i < 8; i++) {
      (i == 0 || i == 4 || i == 5)&&inputFields.push(answers[i+1]&&answers[i+1].answer_id)
    }
    const isEmpty = Object.values(inputFields).some(x => (x === null || x === ''));
    if(Object.keys(answers).length < 7 || answers[2].answer_id === 1 && !answers[3].answer_id || answers[2].answer_id === 1 && answers[3].answer_id.length == 0 || answers[2].answer_id === 1 && answers[4].answer_id.length == 0 || answers[2].answer_id === 1 && !answers[4].answer_id || isEmpty ) {
      return true;
    }
    return false;
  }

  React.useEffect(() => {
  },[])

  return (
    <Card
      style={{...styles.root, marginTop: !isMobile&&answers[2]&&answers[2].answer_id == 1 ? '28%' : isMobile? '14%' : '21%'}}
      bodyStyle={{padding: 0}}
      loading={isLoading}
    >
      <div style={styles.titleContainer}>
        <Typography style={styles.title}>
          We want to learn more <br /> about your business
        </Typography>
        <Typography style={styles.subTitle}>
          You are just a few steps away in using Bux. Complete the details below to get started now! 
        </Typography>
      </div>
      <div style={styles.body}>
        {
           data&&data!==[]&&data.map((question, i) => {
            return  (  
              question.choices.length === 0 ?
                <div style={{...styles.questionContainer, position: i === 5? 'relative' : 'block'}}>
                  <label style={styles.label}>{question.questions}</label>
                  <Input
                    onChange={handleChange}
                    name={i+1}
                    id={question.question_type}
                    value={answers[i+1]&&answers[i+1].answer_id}
                    style={{...styles.input, paddingLeft: i === 5 && isMobile ? 56 : i === 5? '10%' : null}}
                  />
                  <Typography hidden={i !== 5} style={styles.php}>PHP | </Typography>
                </div>
                : i === 1 ? 
                <div style={styles.questionContainer}>
                  <label style={styles.label}>{question.questions}</label>
                  <Radio.Group buttonStyle={{backgroundColor: '#F5922F'}} name={i+1} id={question.question_type} onChange={handleChange} >  
                  {  
                    question.choices.map((choice, i) => {
                      return <Radio color='#F5922F' value={choice.id}>{choice.value}</Radio>
                    })
                  }
                  </Radio.Group>
                </div>
                : i === 2 || i === 3 ? 
                <div style={ isMobile? {...styles.questionContainer} : i===3? {...styles.mutltiSelect2} : {...styles.mutltiSelect1}} hidden={!answers[2] || answers[2]&&answers[2].answer_id === 2}>
                    <label style={styles.label}>{question.questions}</label>
                    <Popover getPopupContainer={triggerNode => triggerNode.parentNode } content={questionTwo(question.choices, i+1, question.question_type)} trigger='click' placement='bottom' overlayStyle={{width: i===2? '38%' : '100%'}}>
                      <Button style={styles.popOverBtn}>{answers[i+1].answer_id.length === 5 ? 'All' : answers[i+1].answer_id.length != 0 && answers[i+1].answer_id ? returnSelected(i, answers[i+1].answer_id[0]) : 'Select Channel'}<ExpandMoreIcon style={{ color: '#2B2D33', fontSize: 32 }} /></Button>
                    </Popover>
                </div>
    
                : <div style={styles.questionContainer}>
                    <label style={styles.label}>{question.questions}</label>
                    <Select getPopupContainer={triggerNode => triggerNode.parentNode } style={styles.select} name={5} size='large' value={answers[i+1]?returnSelected(i,answers[i+1].answer_id):'Select'} onChange={(value) => handleChange((i+1), value, question.question_type)}>
                      {question.choices.map((choice) => <Select.Option key={choice.id}>{choice.value}</Select.Option>)}
                    </Select>
                  </div>
            )
          })
        }
        <Button style={styles.btn} onClick={submitAnswers} loading={loading} disabled={disabledBtn()} >Save and Continue</Button>
      </div>
    </Card>
  )
}

const styles= {
  root: {
    padding: 0,
    borderRadius: 20,
    margin: 16,
    width: 684,
    backgroundColor: '#FFF',
    marginTop: '10%'
    // height: '100%'
  },
  titleContainer: {
    backgroundImage: `url(${BG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: isMobile? 22 : 32,
    fontWeight: 'bold',
    color: '#0D3D76',
    textAlign: 'center',
    paddingTop: '42px',
    width: isMobile ? '80%' : '60%'
  },
  subTitle: {
    fontSize: 16,
    fontWeight: '300',
    color: '#2B2D33',
    textAlign: 'center',
    padding: '16px 0',
    width: '90%'
  },
  questionContainer: {
    padding: '12px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start'
  },
  body: {
    padding: isMobile? 16 : '16px 48px',
    // width: '90%',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
  },
  label: {
    fontSize: 12,
    color: '#212B36',
    padding: '4px 0'
  },
  input: {
    width: '100%', 
    height: '40px', 
    borderRadius:'4px',
    fontSize: 16,
    color: '#2B2D33',
  },
  btn: {
    width: '100%',
    height: 40,
    backgroundColor: '#F5922F',
    color: '#FFF',
    fontSize: 14,
    fontWeight: '400',
    textAlign: 'center',
    borderRadius: 4,
    margin: '16px 0'
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  mutltiSelect1: {
    padding: '12px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '45%',
  },
  mutltiSelect2: {
    padding: '12px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '45%',
    position: 'absolute',
    top: '46.4%',
    left: '48%',
  },
  php: {
    position: 'absolute',
    top: `64%`,
    left: isMobile? 32 : '6%',
    transform: 'translate(-50%, -50%)',
    fontSize: 16,
    color: '#2B2D33',
    fontWeight: 'normal',
    zIndex: '99',
  },
  select: {
    height: 48,
  },
  popOverBtn: {
    height: 40,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
}

export default QuestionCard;