import axios from 'axios';
import {Handle401} from '../../handle401/handle401';

const authorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const overviewServices = {
  getAnalytics: async (start, end, bux_segment) => {
    const url = start? `/api/analytics/?segment=${bux_segment}&start=${start}&end=${end}` : `/api/analytics/?segment=${bux_segment}`;
    try {
      const res = await authorizedAxiosRequest().get(url);
      console.log(res.data)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getUserAnalytics: async (start, end, bux_segment) => {
    const url = start? `/api/overview/users/?segment=${bux_segment}&start=${start}&end=${end}` : `/api/overview/users/?segment=${bux_segment}`;
    try {
      const res = await authorizedAxiosRequest().get(url);
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getQualityAnalytics: async (start, end, bux_segment) => {
    const url = start? `/api/quality_analytics/?segment=${bux_segment}&start=${start}&end=${end}` : `/api/quality_analytics/?segment=${bux_segment}`;
    try {
      const res = await authorizedAxiosRequest().get(url);
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getPaidAnalytics: async (start, end, bux_segment) => {
    const url = start? `/api/paidactivity_analytics/?segment=${bux_segment}&start=${start}&end=${end}` : `/api/paidactivity_analytics/?segment=${bux_segment}`;
    try {
      const res = await authorizedAxiosRequest().get(url);
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getPendingAnalytics: async (start, end, bux_segment) => {
    const url = start? `/api/pendingactivity_analytics/?segment=${bux_segment}&start=${start}&end=${end}` : `/api/pendingactivity_analytics/?segment=${bux_segment}`;
    try {
      const res = await authorizedAxiosRequest().get(url);
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getTopMerhantsChannels: async (start, end, bux_segment) => {
    const url = start? `/api/top_merchants_channels/?segment=${bux_segment}&start=${start}&end=${end}` : `/api/top_merchants_channels/?segment=${bux_segment}`;
    try {
      const res = await authorizedAxiosRequest().get(url);
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getAnalyticsDownload: async (key, start, end, summary, txn) => {
    const url = `/api/analytics/downloads/?key=${key}&summary=${summary}&start=${start}&end=${end}&transactions=${txn}`;
    try {
      const res = await authorizedAxiosRequest().get(url);
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  
}

export default overviewServices;