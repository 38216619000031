import {
  Button,
  Card,
  Pagination,
  Table,
  Typography,
  notification,
  Form
} from "antd";
import React, { useState, useEffect } from "react";
import { AddUserModal } from "./components";
import {
  getWalletDefinedSettings,
  createSubwallet,
  getPeersHistory,
} from "./api";
import { useDataFetching } from "../../hooks";

function AdminUserManagement({ form }) {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const { data, loading, error, refetchData } = useDataFetching(() =>
    getPeersHistory(currentPage)
  );

  // Modal states
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  // Form states
  const [formData, setFormData] = useState({
    merchantType: "SW",
    first_name: "",
    last_name: "",
    email: "",
    contact: "",
    sw_type: "",
    multiwallet_privileges: {},
  });

  const [walletDefinedSettings, setWalletDefinedSettings] = useState(null);

  const updateFields = (fields) => {
    setFormData((prev) => {
      return { ...prev, ...fields };
    });
  };

  useEffect(() => {
    refetchData();
  }, [currentPage]);

  useEffect(() => {
    getWalletDefinedSettings().then((data) => {
      updateFields({ multiwallet_privileges: data.multiwallet_privileges });
      setWalletDefinedSettings(data);
    });
  }, []);

  const tableColumns = [
    {
      title: "Subwallet ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
    },
    {
      title: "Subwallet Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Role",
      dataIndex: "subuser_role",
      key: "subuser_role",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Wallet Balance",
      dataIndex: "balance",
      key: "balance",
      render: (balance) => (
        <Typography style={{ whiteSpace: "nowrap" }}>
          {balance.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            style: "currency",
            currency: "PHP",
          })}
        </Typography>
      ),
    },
  ];

  const openNotification = (status, message) => {
    notification.info({
      message: status,
      description: message,
      placement: "topRight",
    });
  };

  const showModal = () => setOpen(true);

  const handleCancel = () => setOpen(false);

  const handleOk = (e) => {
    e.preventDefault();
    form.validateFields((err, _) => {
      if (!err) {
        setConfirmLoading(true);
        createSubwallet(formData)
          .then((res) => {
            setOpen(false);
            openNotification(res.status, "User has been created");
            refetchData();
            form.setFieldsValue({
              first_name: "",
              last_name: "",
              email: "",
              contact: "",
              role: ""
            });
            setFormData({
              merchantType: "SW",
              first_name: "",
              last_name: "",
              email: "",
              contact: "",
              sw_type: "",
              multiwallet_privileges: {},
            });
          })
          .catch((error) => console.error(error))
          .finally(() => setConfirmLoading(false));
      }
    });
  };

  return (
    <div
      style={{
        margin: "5em 2.5em 2.5em 0",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1
          style={{
            width: "100%",
            color: "rgb(0, 0, 0)",
            fontWeight: "bold",
            fontSize: "28px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Users Management
        </h1>

        <Button
          onClick={showModal}
          type="primary"
          htmlType="submit"
          size="large"
          style={{
            backgroundColor: "rgb(0, 81, 176)",
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          Add New User
        </Button>
      </div>

      <Card>
        {error ? (
          <div style={{ padding: "16px", color: "red" }}>
            Error fetching data. Please try again.
          </div>
        ) : (
          <>
            <Table
              rowClassName="table-row-light"
              columns={tableColumns}
              dataSource={data ? data.results : []}
              pagination={false}
              loading={loading}
              scroll={{ x: true }}
              style={{ maxWidth: "82dvw" }}
            />
            <Pagination
              size="small"
              total={(data && data.total) || 0}
              defaultPageSize={pageSize}
              pageSize={pageSize}
              current={currentPage}
              onChange={setCurrentPage}
              style={{ paddingTop: "12px", textAlign: "right" }}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
            />
          </>
        )}
      </Card>
      <AddUserModal
        form={form}
        {...formData}
        open={open}
        handleOk={handleOk}
        confirmLoading={confirmLoading}
        handleCancel={handleCancel}
        walletDefinedSettings={walletDefinedSettings}
        updateFields={updateFields}
      />
    </div>
  );
}

export default Form.create({ name: "create_new_user" })(
  AdminUserManagement
);