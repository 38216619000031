import React,{ useState }  from 'react';
import cardServices from './cardServices';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import { List, Spin, Card, Typography, Input, Pagination, Icon, Divider } from 'antd';

const TransactionCards = (data) => {
  return (
    data ? 
    <Card size='small' style={styles.card} bodyStyle={{padding: 0}}>
      <div style={styles.upper}>
        <div>
          <Typography style={{...styles.boldText, padding: '0 0 10px 0'}}>{data.id}</Typography>
          <Typography style={styles.boldText}>{returnType(data.name)}</Typography>
        </div>
        {/* <a style={styles.link}>...</a> */}
      </div>
      <div style={styles.lower}>
        <Typography style={styles.data}>{data.date_str}</Typography>
        <Typography style={styles.boldText}>{data.type === 'D'? '- PHP' : 'PHP '} {data.amount}</Typography>
      </div>
    </Card>
    : null
  )
}

const returnType = (type) => {
  switch (type) {
    case 'Ibft Realtime':
      return 'Top up';
    case 'Pay Bills':
     return 'Bills Payment';
    case 'Pay Bills Reversal':
      return 'Bills Payment Reversal';
    default:
      return type;
  }
}

const renderTransactions = (data) => {
  return (
    data ? 
    <List 
      loading={!data}
      dataSource={data}
      renderItem={ item => 
        <div>
          <TransactionCards {...item} />
        </div>
      }
    />
    : <Spin size='small' style={styles.spinner} />
    )
}

const TransactionHistoryMobile = () => {
  const [tableData, setTableData] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [minPage, setMinPage] = useState(0);
  const [maxPage, setMaxPage] = useState(5);
  const [filterVisible, setFilterVisible] = React.useState(false);

  const getHistory = async () => {
    try {
      const response = await cardServices.getTransactionHistory('eon');
      setTableData(response);
    } catch (error) {
      console.log(error);
    }
  }

  if(!initialized) {
    getHistory();
    setInitialized(true);
  }

  const renderData = (history) => {
    let filteredData = [];   

    if (history && history !== null) {
      filteredData = tableData.filter(data => data.date_str.toLowerCase().includes(history.toLowerCase())
                                          || data.name.toLowerCase().includes(history.toLowerCase())
                                          || data.id.toLowerCase().includes(history.toLowerCase()) );
      return filteredData ? filteredData.slice(minPage, maxPage) : null;
    } else {
      return tableData ? tableData.slice(minPage, maxPage) : null;
    }
  
  }

  const handleChangeSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const changePage = (page, pageSize) => {
    setMaxPage(page * pageSize)
    setMinPage((page - 1) * pageSize)
    setCurrentPage(page)
  }

  return (
    <div style={styles.root}>
      <div hidden={!filterVisible}>
        <Input
          placeholder="search date/ tranx no."
          style={styles.searchInput}
          suffix={<Icon type='close' onClick={() => setFilterVisible(false)} style={{fontSize: 16, fontWeight: '500'}} />}
          onChange={handleChangeSearch}
        />
      </div>
     <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 20}} hidden={filterVisible}>
      <div style={styles.filterDiv} onClick={() => setFilterVisible(true)}>
          <a style={{color: '#2B2D33'}}><CalendarTodayOutlinedIcon /></a>
          <Divider type='vertical' />
          <a style={{color: '#2B2D33'}}><Icon type='search' style={{fontSize: 22}} /></a>
        </div>
     </div>
      {renderTransactions(renderData(searchValue))}
      <Pagination
        size='small'
        total={tableData ? tableData.length : 0}
        defaultPageSize={5}
        defaultCurrent={1}
        current={currentPage}
        onChange={(page, pageSize) => changePage(page, pageSize)}
        style={{display: 'flex', justifyContent: 'center'}}
      />
    </div>
  )
}

const styles = {
  root: {
    height: '100vh'
  },
  upper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px'
  },
  lower: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #D1D5DD',
    padding: '10px 16px 16px 16px'
  },
  card: {
    margin: '16px 0',
    borderRadius: 4,
    padding: 0
  },
  boldText: {
    color: '#2B2D33',
    fontSize: 14,
    fontWeight: '600',
  },
  dateText: {
    color: '#2B2D33',
    fontSize: 14
  },
  link: {
    color: '#DDD',
    fontSize: 24,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'flex-end',
    height: 18
  },
  searchInput: {
    width: '100%', 
    height: '50px',
    margin: '20px 0 0 0'
  },
  spinner: {
    height: 200, 
    width: '100%', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  filterDiv: {
    display: 'flex', 
    alignItems: 'center', 
    borderRadius: 4, 
    border: '1px solid #E6EAF0', 
    backgroundColor: '#FFF', 
    padding: '8px 12px 4px 12px',
    height: 40,
  }
}

export default TransactionHistoryMobile;