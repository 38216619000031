import React from 'react';
import fraudServices from '../fraudServices';
import { Button, Icon, Input, Modal, Typography, message } from 'antd';

const AddNewModal = ({visible, closeModal, onSuccess}) => {
  const [cancelBtnStyle, setCancelBtnStyle] = React.useState({color: '#F5922F', backgroundColor: '#FFF'});
  const [isLoading, setIsLoading] = React.useState(false);
  const [domainName, setDomainName] = React.useState(null);

  const cancelBtnHover = () => {
    setCancelBtnStyle({
      color: '#FFF',
      backgroundColor: '#F5922F',
    })
  }

  const cancelBtnLeave = () => {
    setCancelBtnStyle({
      color: '#F5922F',
      backgroundColor: '#FFF',
    })
  }

  const handleChange = (event) => {
    setDomainName(event.target.value);
  }

  const success = () => {
    message.success('New domain added');
  };

  const errorPrompt = (msg) => {
    message.error(msg);
  };

  const addNewDomain = async () => {
    setIsLoading(true);
    try {
      const res = await fraudServices.addNewDomain({domain:domainName});
      if(res.status==='success'){
        onSuccess();
        success();
      } else {
        errorPrompt(res.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      errorPrompt(error);
    }
  }

  return (
    <Modal
    visible={visible}
    footer={null}
    onCancel={closeModal}
    destroyOnClose
    afterClose={()=>setDomainName(null)}
    width={392}
    centered
    closeIcon={<Icon type="close" style={{color:'#F5922F'}}/>}
    bodyStyle={styles.root}>
    <Typography style={styles.title}>Add a Blacklisted Domain name?</Typography>
   <label style={styles.label}>Domain Name</label>
   <Input 
      name='domaneName'
      placeholder="Domain Name"
      style={styles.input}
      onChange={handleChange}
      disabled={isLoading}
    />
    <div style={styles.footerBtn}>
      <Button style={{...styles.cancelBtn, ...cancelBtnStyle}}
        onClick={closeModal}
        onMouseLeave={cancelBtnLeave}
        onMouseOver={cancelBtnHover}>Cancel</Button>
      <Button style={{...styles.confirmBtn, opacity: !domainName? '0.5' : '1' }} 
        disabled={!domainName} 
        onClick={addNewDomain}
        loading={isLoading}>Confirm</Button>
    </div>
  </Modal>
  )
}

const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  title: {
    fontSize: 20,
    color: '#2B2D33',
    padding: '32px 0 18px 0',
  },
  cancelBtn: {
    width: '48%',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    height: 48,
    border: '1px solid #F5922F',
  },
  confirmBtn: {
    height: 48,
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    width: '48%',
  },
  footerBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 20,
  },
  label: {
    fontSize: 12,
    color: '#212B36',
  },
  input: {
    padding: '11px 16px 11px 16px',
    fontSize: 16,
    height: 48,
    margin: '4px 0px 0px 0px',
    color: '#2B2D33'
  },
}


export default AddNewModal;