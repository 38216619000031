import React, { useState } from 'react';
import GroupButton from '../../components/custom_components/groupButton';
import MetaTag from '../../components/meta_tag/meta_tag';
import { Button, Typography, Icon, DatePicker, Input, message } from 'antd';
import Table from './table';
import { history } from '../../store/history';
import moment from 'moment';
import ExportCsvModal from './exportCsvModal';
import HeaderInfo from './payout_header_card';
import ReminderModal from './reminder_modal';
import { openPayoutDrawer } from '../../components/payout_drawer/payout_drawer';
import AdvancePayoutModal from './advance_payout_modal';
import payoutServices from '../../services/payout';
import { isMobile } from 'react-device-detect';
import MobilePayoutCards from './payout_table_mobile';
import MobileFilter from './mobile_filter';
import fundsServices from '../../components/funds/fundServices';
import payoutAdvancingServices from '../../components/AdminComponents/advance_payout/payoutAdvancingServices';
import AdvancePayoutSuccess from './success_payout_modal';
import { BulbIcon } from '../../static/new_segment/tutorials/pages_tutorials';
import PayoutTutorial from './tutorial';

const Payout = () => {
    const [dateRange, setDateRange] = React.useState({ startDate: '', endDate: '' });
    const [selected, setSelected] = React.useState('All');
    const userType = localStorage.getItem('userType');
    const subuserRole = localStorage.getItem('subuser_role');
    const [searchVal, setSearchVal] = React.useState('');
    const [visibleClearBtn, setVisibleClearBtn] = React.useState(false);
    const [tableData, setTableData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [showExportModal, setShowExportModal] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [page, setPage] = React.useState(1);
    const segments = localStorage.getItem('segments');
    const status = ['All', 'Success', 'Pending', 'Failed'];
    const [reminderShow, setReminderShow] = React.useState(false);
    const [advanceShow, setAdvanceShow] = React.useState(false);
    const [showFilterMobile, setShowFilterMobile] = React.useState(false);
    const isChild = localStorage.getItem('is_child') === 'true';
    const isNotCorporate = userType === 'ME' || userType == "SW" || userType === "CO" && localStorage.getItem("is_multiwallet") != "true" || isChild;
    const transferableBal = isNotCorporate ? localStorage.getItem("balance") : localStorage.getItem("float_balance");
    const [summary, setSummary] = React.useState('All');

    // advancing
    const [settingsParams, setSettingsParams] = useState([]);
    const userId = localStorage.getItem('userId');
    const [advanceSuccess, setAdvanceSuccess] = React.useState(false);
    const [successData, setSuccessData] = React.useState([]);
    const [advancingLoading, setAdvancingLoading] = React.useState(false);
    
    const [infoActiveHover,setInfoActiveHover] = React.useState(false);
    const [showTutorial, setShowTutorial] = React.useState(false);

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    const rangeSelect = (dates, dateString, id) => {
        setDateRange({ ...dateRange, [id]: moment(dateString).format('MM-DD-YYYY') });
        setSummary('Custom');
    }

    const errorPrompt = (msg) => {
        message.error(msg);
    };

    const handleSearch = (e) => {
        setSearchVal(e.target.value);
    }

    const onFilterApply = () => {
        getData(dateRange.startDate, dateRange.endDate, selected, searchVal, summary, email, false);
        setVisibleClearBtn(true);
    }

    const onChangePage = (page) => {
        setPage(page);
        getData(dateRange.startDate, dateRange.endDate, selected, searchVal, summary, email, false, page);
    }

    const advancePayout = async (params) => {
        setAdvancingLoading(true);
        try {
            const res = await payoutAdvancingServices.advancePayout(params);

            console.log('PAYOUT_ADVANCE_SETTING', res);
            if (res.status === 'success') {
                setAdvanceShow(false);
                setAdvanceSuccess(true);
                setSuccessData(params);
            } else {
                if (res && res.message && res.message['amount']) {
                    message.error(res.message['amount']);
                } else if (res && res.message && res.message['email']) {
                    message.error(res.message['email']);
                } else {
                    message.error('Error try again');
                    // message.error('Error try again');
                }
            }

        } catch (error) {
            console.log(error);
            message.error('Error try again');
        }
        setAdvancingLoading(false);
    }

    const getPayoutSettings = async () => {

        try {
            const res = await payoutAdvancingServices.getPayoutAdvancingSetting(userId);

            console.log('PAYOUT_ADVANCE_SETTING', res);
            if (res.status === 'success') {
                if (res && res.data) {
                    setSettingsParams(res.data);
                }
            } else {
                message.warn('Error try again');
            }

        } catch (error) {
            console.log(error);
            message.warn('Error try again');
        }
    }

    const getData = async (start, end, status, search, summary, email, isExport = false, page) => {
        setIsLoading(true);
        try {
            const res = isExport ? await fundsServices.exportCsv(start, end, status, search, summary, email, isExport) : await payoutServices.getPayout(start, end, status, search, summary, email, isExport, page);
            if (res) {
                if (showExportModal && res.status === 'success') {
                    message.success(res.message);
                    setShowExportModal(false);
                } else if (res && res.results) {
                    setTableData(res);
                } else {
                    errorPrompt('Please try again');
                }
            } else {
                errorPrompt('Please try again');
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            errorPrompt('Please try again');
        }
    }

    const exportEmail = () => {
        getData(dateRange.startDate, dateRange.endDate, selected, searchVal, summary, email, true);
    }

    const redirectPage = () => {
        if (localStorage.getItem("jwtToken") === "" || !localStorage.getItem("jwtToken")) {
            history.push('/login')
        }
        // else if (['ME', 'CO'].includes(localStorage.getItem("userType"))) {
        //     history.push('/dashboard')
        // }
        else if (['PS', 'PF'].includes(localStorage.getItem("userType"))) {
            history.push('/submissions_kyb')
        }
    }

    const resetFilters = () => {
        setDateRange({ startDate: '', endDate: '' });
        setSearchVal('');
        setVisibleClearBtn(false);
        getData('', '', '');
        setSummary('All');
        setEmail('');
    }

    React.useEffect(() => {
        redirectPage();
        getData(dateRange.startDate, dateRange.endDate, selected, searchVal, false, email, false);
        getPayoutSettings();

        // if(userType == "SW" && subuserRole == "CO"){
        //     history.push('/dashboard')
        // }
        // else{
        //     redirectPage();
        //     getData(dateRange.startDate, dateRange.endDate, selected, searchVal, false, email, false);
        //     getPayoutSettings();
        // }

    }, [selected])

    return (
        <div style={styles.root}>
            <MetaTag title="Payout" />
            <div style={{display: 'inline-flex', alignItems: 'center', gap: '16px', margin: '38px 6px 0px 20px'}} hidden={userType === "AD" || userType === "SW"}>
                <Typography style={styles.title}>Payout</Typography>
                <Button style={{...styles.infoBtn}} shape={!infoActiveHover && "circle"}
                    onMouseOver={() => setInfoActiveHover(true)} onMouseLeave={() => setInfoActiveHover(false)}
                    onClick={() => isMobile ?  history.push('/payout/mobile/how_to_use') : setShowTutorial(true)}>
                    <img src={BulbIcon} alt="Info" />
                    {infoActiveHover && "What is this?"}
                </Button>
            </div>
            <HeaderInfo bal={transferableBal} payoutType='manual' segments={segments}
                onPayoutClick={() => setReminderShow(true)} onAdvanceClick={() => setAdvanceShow(true)} advancingData={settingsParams} />
            <div style={styles.titleContainer}>
                <Typography style={styles.title} hidden={userType !== 'AD'}>All Payout</Typography>
                <div style={styles.btnContainer}>
                    <GroupButton status={status} selected={selected} segments={segments} handleClick={(e) => setSelected(e.target.value)} />
                    <div style={styles.exportBtnDiv}>
                        <button hidden={!isMobile} className={`btn--${segments}`} style={{ ...styles.exportBtn, marginRight: 8 }} onClick={() => setShowFilterMobile(true)} ><Icon type='filter' theme='outlined' style={{ paddingRight: 8 }} />Filter</button>
                        <button className={`outline-btn--${segments}`} style={styles.exportBtn} onClick={() => setShowExportModal(true)} ><Icon type='download' /> Export CSV</button>
                    </div>
                </div>
            </div>
            <div style={styles.body} hidden={isMobile}>
                <div style={styles.subContainer}>
                    <div style={styles.clearDiv}>
                        <Typography style={styles.filterText}>Filter</Typography>
                        <a style={styles.clearBtnDiv} hidden={!visibleClearBtn} onClick={resetFilters}>
                            <Icon style={styles.clearIcon} type="close-circle" theme="filled" />
                            <span style={styles.clearBtn}>Clear Filter</span>
                        </a>
                    </div>
                    <Typography hidden style={styles.reminder}><Icon type="exclamation-circle" theme="filled" /> Click <b>Apply</b> button to show results.</Typography>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '0 0 24px 0' }}>
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledDate}
                        // defaultValue={moment()}
                        value={dateRange.startDate && moment(dateRange.startDate)}
                        size="large"
                        picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'startDate')}
                        placeholder='Start Date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <span style={{ paddingTop: 8 }}>-</span>
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledDate}
                        // defaultValue={moment()}
                        value={dateRange.endDate && moment(dateRange.endDate)}
                        size="large"
                        picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'endDate')}
                        placeholder='End Date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <Input
                        placeholder="Search"
                        style={{ width: '200px', height: '40px', borderRadius: '4px', padding: '0 8px 0 0px' }}
                        suffix={<Icon type="search" style={{ fontSize: '18px' }} />}
                        onChange={handleSearch}
                        value={searchVal}
                    />
                    <button className={`btn--${segments}`} style={styles.applyBtn} onClick={onFilterApply} >Apply</button>
                </div>
                <Table
                    data={tableData}
                    // onUpdate={() => getData('', selected, dateRange.startDate, dateRange.endDate)}
                    isLoading={isLoading}
                    // length={totalLength}
                    selected={selected}
                    currentPage={page}
                    // sort={tableSort}
                    onChangePage={onChangePage}
                />
            </div>
            <ExportCsvModal visible={showExportModal} onClose={() => setShowExportModal(false)}
                onSend={() => exportEmail()} onChange={(e) => setEmail(e)} segments={segments} />
            <ReminderModal visible={reminderShow} onClose={() => setReminderShow(false)}
                onContinue={(is_payout_restricted) => openPayoutDrawer(is_payout_restricted) / setReminderShow(false)} segments={segments} />
            <AdvancePayoutModal visible={advanceShow} onClose={() => setAdvanceShow(false)} segments={segments}
                onNext={(params) => advancePayout(params)} payoutSettings={settingsParams} bal={transferableBal} isLoading={advancingLoading} />
            <MobilePayoutCards hidden={!isMobile} isLoading={isLoading} data={tableData} currentPage={page} selected={[]} onChangePage={onChangePage} />
            <MobileFilter visible={showFilterMobile} onClose={() => setShowFilterMobile(false)}
                segments={segments} handleSearch={handleSearch} rangeSelect={rangeSelect} dateRange={dateRange}
                searchVal={searchVal} onFilterApply={(e) => { setShowFilterMobile(false); onFilterApply(e) }} />
            <AdvancePayoutSuccess segments={segments}
                visible={advanceSuccess} onClose={() => setAdvanceSuccess(false)} data={settingsParams} successData={successData} />
            <PayoutTutorial  
                visible={showTutorial}
                onClose={() => setShowTutorial(false)}
                userType={userType} />
        </div>
    )
}

const styles = {
    root: {
        padding: isMobile ? '0 0 102px 0' : '32px 32px 0 0',
        width: '100%',
        backgroundColor: isMobile && 'rgb(244, 246, 249)'
    },
    titleContainer: {
        padding: isMobile ? '0px 20px 0px 20px' : '32px 20px 0px 20px'
    },
    title: {
        backgroundColor: 'transparent',
        width: '100%',
        color: '#000',
        fontWeight: 'bold',
        fontSize: 28,
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 8,
    },
    btnGroup: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: '0 32px',
    },
    body: {
        backgroundColor: 'transparent',
        padding: '0px 16px',
        backgroundColor: '#FFF',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        margin: '38px 20px'
    },
    addBtn: {
        color: '#FFF',
        backgroundColor: '#1DD28B',
        height: 40,
        borderRadius: 4,
        padding: '8px, 16px, 8px, 16px',
        fontSize: 16,
        fontWeight: '600'
    },
    btnContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: isMobile ? 'column' : 'row'
    },
    body: {
        backgroundColor: 'transparent',
        padding: '0px 16px',
        backgroundColor: '#FFF',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        margin: '38px 20px'
    },
    subContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 0'
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 178,
        padding: '0 8px 0 8px'
    },
    filterText: {
        color: '#000',
        fontSize: 14,
        fontWeight: 'bold'
    },
    reminder: {
        fontSize: 12,
        color: '#909196',
    },
    applyBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: 72,
        cursor: 'pointer'
    },
    clearBtnDiv: {
        backgroundColor: '#E9EEF4',
        borderRadius: 20,
        padding: '6px 12px',
        margin: '0 12px',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #D4D5D8'
    },
    clearBtn: {
        color: '#2b2d32',
        fontSize: 14,
        paddingLeft: 4,
        fontWeight: '500',
        opacity: '0.8'
    },
    clearDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    clearIcon: {
        color: '#2b2d32',
        opacity: '0.8',
        fontSize: 12,
    },
    exportBtn: {
        cursor: 'pointer',
        margin: 0,
        height: 40
    },
    exportBtnDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: isMobile && '100%',
        height: '52px'
    },
    infoBtn: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
        height: '32px',
        fontSize: '12px',
        fontWeight: '400',
        textDecoration: 'underline',
        gap: '12px',
        color: '#E68932',
        backgroundColor: '#FFF',
        borderRadius: '50px',
        marginTop: '-8px'
    }
}

export default Payout;

