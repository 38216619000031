import React, { useState } from "react";
import { useSubmerchants } from "../api/get-submerchants";
import { Card, Pagination, Table } from "antd";
import { UserDataCell } from "../../../../../Transfer/components";

export function SubmerchantsList({ merchantId }) {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const { data, isLoading, error } = useSubmerchants(merchantId, currentPage);

  return (
    <Card>
      {error ? (
        <div style={{ padding: "16px", color: "red" }}>
          Error fetching data. Please try again by refreshing the page.
        </div>
      ) : (
        <>
          <Table
            rowClassName="table-row-light"
            dataSource={data ? data.results : []}
            columns={[
              {
                title: "Sub-merchant ID",
                dataIndex: "id",
                key: "id",
                fixed: "left",
              },
              {
                title: "Business Name",
                dataIndex: "business_name",
                key: "business_name",
              },
              {
                title: "Name & Email",
                dataIndex: "full_name",
                key: "full_name",
                render: (_, record) => (
                  <UserDataCell name={record.full_name} email={record.email} />
                ),
              },
              {
                title: "Role",
                dataIndex: "role",
                key: "role",
              },
            ]}
            pagination={false}
            loading={isLoading}
            scroll={{ x: true }}
            style={{ maxWidth: "82dvw" }}
          />
          <Pagination
            size="small"
            total={(data && data.total) || 0}
            defaultPageSize={pageSize}
            pageSize={pageSize}
            current={currentPage}
            onChange={setCurrentPage}
            style={{ paddingTop: "12px", textAlign: "right" }}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
          />
        </>
      )}
    </Card>
  );
}
