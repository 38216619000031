import React,{ useState } from 'react';
import fraudActions from '../fraudActions';
import fraudServices from '../fraudServices';
import { Modal, Typography, Button, Icon, List, Spin, Pagination, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

const renderDomains = (data, type) => {
  return (
    data ? 
    <List 
      loading={!data}
      dataSource={data}
      style={{width:'100%'}}
      renderItem={ (item, index) => 
        <div style={{...styles.tableData, backgroundColor: item.problem !== null&&'rgb(226 76 76 / 0.2)',}}>
          <Typography 
             style={{...styles.tableDataText, width: '50%'}}>
               {type==='domain'?item.name:item.email}</Typography>
          <Typography 
            style={{...styles.tableDataText, width: '50%'}}>{item.problem}</Typography>
        </div>
      }
    />
    : <Spin size='small' style={styles.spinner} />
    )
}

const getOnlyIndex = (data) => {
  let arr = new Array();
  data&&data.res&&data.res.errors.map((item) => {
    arr.push(item.split('(').pop().split(')')[0].substr(item.split('(').pop().split(')')[0].indexOf(' ')+1));
  })
  return arr;
}

const DomainCheckingModal = ({visible, closeModal, data, csv, onSuccess}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [minPage, setMinPage] = useState(0);
  const [maxPage, setMaxPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [cancelBtnStyle, setCancelBtnStyle] = useState({color: '#F5922F', backgroundColor: '#FFF'});
  const dispatch = useDispatch();
  const selected = useSelector(state => state.fraud.emailBlcklstSelected);

  const getOnlyDomains = () => {
    let arr = new Array();
    data&&data.res&&data.res.errors.map((item) => {
      arr.push(item.split('(').pop().split(')')[0].substr(item.split('(').pop().split(')')[0].indexOf(' ')+1));
    })
    return arr;
  }

  const flattenData = (file) => {
    const domains = getOnlyDomains();
    let arr = new Array;
    file&&Object.keys(file).map((key,i) => {
      Object.keys(file[i].data).map((key,index) => {
        arr.push(file[i].data[key]);
      })
    })
    return arr;
   }

  const changePage = (page, pageSize) => {
    setMaxPage(page * pageSize)
    setMinPage((page - 1) * pageSize)
    setCurrentPage(page)
  }

  const success = () => {
    message.success('New domain/s added');
  };

  const errorPrompt = (msg) => {
    message.error(msg);
  };

  const handleConfim = async (csv) => {
    setIsLoading(true);
    let res;
    try {
      if(selected === 'Bounce List'){
        res = await fraudServices.uploadBounceListCsv(csv, false);
      } else {
        res = await fraudServices.uploadBlacklistCsv(csv, false, returnType());
      }

      if(res.success.length>0 || res.errors.length>0) {
        dispatch(fraudActions.setDomainCSV(null));
        dispatch(fraudActions.setCheckModalData([]));
        success();
        onSuccess();
      } else {
        errorPrompt(res.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      errorPrompt(error);
    }
  }

  const cancelBtnHover = () => {
    setCancelBtnStyle({
      color: '#FFF',
      backgroundColor: '#F5922F',
    })
  }

  const cancelBtnLeave = () => {
    setCancelBtnStyle({
      color: '#F5922F',
      backgroundColor: '#FFF',
    })
  }

  const returnType = () =>  {
    switch (selected) {
      case 'Email Domain':
        return 'domain';
      case 'Bounce List':
        /* falls through */
      case 'Email Address':
        return 'email';
      default:
        return 'domain';
    }
  }

  return (
    <Modal 
      visible={visible} 
      bodyStyle={styles.root} 
      footer={null}
      centered
      width={400}
      onCancel={closeModal}
      destroyOnClose
      closeIcon={<Icon type="close" style={{color:'#F5922F'}}/>}
    >
      <Typography style={styles.title}>Batch CSV</Typography>
      <div style={{padding: '12px 0'}}>
        <Typography style={styles.msg}>
          Some of the item/s highlighted in red are already added to your list, 
          by clicking <strong>Proceed</strong> this item/s won’t be added.
        </Typography>
      </div>
      <div style={styles.headerContainer}>
        <Typography style={{...styles.header, width: '50%'}}>{returnType()==='domain'?'Domain Name':'Email Address'}</Typography>
        <Typography style={{...styles.header, width: '50%'}}>Notes</Typography>
      </div>
      {renderDomains(data.slice(minPage, maxPage), returnType())}
      <div style={{width:'100%', textAlign: 'right'}}>
        <Pagination
          size='small'
          total={data? data.length : 0}
          defaultPageSize={5}
          defaultCurrent={1}
          current={currentPage}
          onChange={(page, pageSize) => changePage(page, pageSize)}
          style={{padding:'12px 0 8px 0'}}
        />
      </div>
      <div style={styles.mainBtn}>
        <Button style={{...styles.cancelBtn, ...cancelBtnStyle}}
          onClick={closeModal}
          onMouseLeave={cancelBtnLeave}
          onMouseOver={cancelBtnHover} 
          loading={isLoading}>Cancel
        </Button>
        <Button style={{...styles.btn, borderColor: '#F5922F'}}
          onClick={closeModal}
          loading={isLoading}
          onClick={() => handleConfim(csv)}>
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 24,
    color: '#2B2D33',
    fontWeight: '300',
    textAlign: 'left',
    padding: '24px 0 4px 0'
  },
  mainBtn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 20,
  },
  btn: {
    height: 48,
    width: '48%',
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 10,
    color: '#FFF', 
    backgroundColor: '#F5922F'
  },
  msg: {
    backgroundColor: 'rgba(245, 146, 47, 0.19)',
    borderLeft: '5px solid #F5922F',
    padding: 12,
    fontSize: 12,
    borderRadius: 4,
    color: '#2B2D33',
  },
  header: {
    fontSize: 12,
    color: '#000',
  },
  cancelBtn: {
    width: '48%',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    height: 48,
    border: '1px solid #F5922F',
  },
  headerContainer: {
    backgroundColor: '#D1D5DD',
    padding: 12,
    width: '100%',
    display: 'flex',
  },
  tableData: {
    padding: 12,  
    width: '100%',
    border: 'solid #DDD',
    borderWidth: 'thin',
    display: 'flex',
    alignItems: 'center',
  },
  tableDataText: {
    fontSize:12,
    color: '#000',
  },  
}

export default DomainCheckingModal;