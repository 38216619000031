import React from 'react'
import pricingStyle from '../../static/css/Pricing';
import {Grid} from '@material-ui/core'
import {Icon} from 'antd'
import { Metrobank, UB, Bpi, Rcbc, MainInstapay, MainPchc } from '../../static/icons/payment_method_icons/index'
import { backToTop } from '../constants/constants'

const MobileWebBanking = props => {
    
    const p = pricingStyle();

    const userType = localStorage.getItem('userType')

    const channels = [
        {
            icon: MainInstapay,
            name: 'InstaPay',
            fee: '₱ 15.00 ',
            min: '₱ 50.00 ',
            max: '₱ 30,000.00 ',
            emax: '₱ 50,000.00 ',
            surcharge: 'Depends on Bank'
        },        
        {
            icon: MainPchc,
            name: 'PCHC Paygate',
            fee: '₱ 25.00 ',
            min: '₱ 50.00 ',
            max: '₱ 30,000.00 ',
            emax: '₱ 1,000,000.00 ',
            surcharge: 'Depends on Bank'
        },        
        {
            icon: UB,
            name: 'UnionBank Internet Banking',
            fee: '₱ 15.00 ',
            min: '₱ 50.00',
            max: '₱ 30,000.00',
            emax: '₱ 80,000.00',
            surcharge: '₱ 10.00'
        },        
        {
            icon: Bpi,
            name: 'BPI Online/Mobile',
            fee: '₱ 15.00 ',
            min: '₱ 50.00',
            max: '₱ 30,000.00',
            emax: '₱ 49,000.00',
            surcharge: '₱ 15.00'
        }, 
        {
            icon: Rcbc,
            name: 'RCBC Online Banking',
            fee: '₱ 15.00 ',
            min: '₱ 50.00',
            max: '₱30,000.00',
            emax: '₱ 80,000.00',
            surcharge: '₱ 5.00'
        }, 
        {
            icon: Metrobank,
            name: 'Metrobank Online Banking',
            fee: '₱ 15.00 ',
            min: '₱ 50.00',
            max: '₱ 30,000.00',
            emax: '₱ 80,000.00',
            surcharge: '₱ 5.00'
        }, 
    ]

    return(
        <div>
            <div align="center" className={p.subDes}>
            Below is the list of available online banking channels.
            </div>
            {
                channels.map((item => {
                    return(
                        <div className={p.cards}>
                            <div className={p.cardHeader} style={{display: 'flex', justifyContent: 'space-between'}}align='center'>
                                <div style={{paddingRight: '16px'}}>
                                    <img style={{width: 100, height: '36px'}} src={item.icon} />
                                </div>
                                <div style={{marginTop: '3px',}} align='right'>{item.name}</div>
                            </div>
                            
                            <Grid container className={p.cardInfo}>

                                <Grid item xs={7}>
                                    <div className={p.cardPlaceholder}>
                                        Minimum Amount
                                    </div>
                                </Grid>

                                <Grid item xs={5}>
                                    <div className={p.cardVal} align='right'>
                                        {item.min}
                                    </div>
                                </Grid>
                            
                            </Grid>

                            <Grid container className={p.cardInfo}>

                                <Grid item xs={7}>
                                    <div className={p.cardPlaceholder}>
                                        Maximum Amount
                                    </div>
                                </Grid>

                                <Grid item xs={5}>
                                    <div className={p.cardVal2} align='right'>
                                    {
                                        userType == 'CO' || userType == 'SW' ? 
                                            item.emax :
                                            item.max
                                    }
                                    </div>
                                </Grid>
                            </Grid>

                            
                            <Grid container className={p.cardInfo}>

                                <Grid item xs={7}>
                                    <div className={p.cardPlaceholder}>
                                        Surcharge
                                    </div>
                                </Grid>

                                <Grid item xs={5}>
                                    <div className={p.cardVal2} align='right'>
                                        {item.surcharge}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    )
                }))
            }

            <div align="center" style={{padding: 16}}>
                <b>*surcharge fees are additional fees charged to the customer.</b>
            </div>

            <div className={p.compleShareDiv} align='left'>
                <button className={`outline-btn--${props.segments} btn-height btn-mobile-width`} onClick={backToTop}><Icon type="up" style={{marginRight: '8px' }}/> Back to Top</button>
            </div>


        </div>
    )
}

export default MobileWebBanking;