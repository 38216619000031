import { Modal, Checkbox, Input, message } from "antd";
import React from "react";
import ActivityLogs from "../components/activityLogs";

const ViewEntryModal = props => {
    const {
        visible,
        onCancel,
        merchantId,
        businessName,
        businessEmail,
        businessType,
        userId,
        selectedTab
    } = props
    const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
    const isMac = window.screen.width * window.devicePixelRatio == 1440 && window.screen.height * window.devicePixelRatio == 900;


    return(
        <Modal
            destroyOnClose={true}
            visible={visible}
            centered={true}
            onCancel={onCancel}
            footer={null}>
            <div style={{...styles.flexBetween, margin: '0px 32px 12px 0'}}>
                <b style={styles.header}>{businessName}</b>
                <button hidden={selectedTab === "signup"} className={`outline-btn--nil`} onClick={()=>window.open(`/admin/user_details/${userId}/#userInfo`)}>User details</button>
            </div>
            <p style={styles.info}>{businessEmail}</p>
            <hr style={styles.hr} />
            <ActivityLogs merchantEntryId={merchantId} businessType={businessType}/>
        </Modal>
    )
}

const styles = {
    activityDiv: {
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '15px 0px'
    },
    activityLogDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '10px 0px',
        width: '100%',
    },
    activityAvatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '35px',
        height: '35px',
        borderRadius: '9999px',
        backgroundColor: 'blue',
        color: 'white'
    },
    header: {
        color: '#000000',
        fontWeight: '700',
        fontSize: '18px'
    },
    subHeader: {
        color: '#000000',
        fontWeight: '400',
        fontSize: '18px'
    },
    info: {
        color: '#000000',
        fontWeight: '400',
        fontSize: '16px'
    },
    hr: {
        border: '1px solid #EEEEEE',
    },
    title: {
        color: '#2B2D32',
        fontWeight: '700',
        fontSize: '18px'
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    body: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    label: {
        color: 'rgba(43, 45, 50, 0.8)',
        fontSize: '12px',
        fontWeight: '500',
        textAlign: 'center'
    },
    viewStyle: {
        fontWeight: '700',
        fontSize: '14px',
        color: '#254A76',
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    inlineFlex: {
        display: 'inline-flex', 
        alignItems: 'center', 
        gap: '12px',
        marginBottom: '12px'
    }
}
export default ViewEntryModal