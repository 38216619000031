import React from 'react';
import { bindActionCreators, } from 'redux';
import { Button, Table, Input, Icon, Menu, Modal, DatePicker, Divider, message, Select } from 'antd';
import Pagination from '@material-ui/lab/Pagination';
import { history } from '../../store/history'
import {isMobile} from 'react-device-detect';
import { getSinglePayouts } from './fundsAction';
import { connect } from 'react-redux';
import { PAYMENT_MODE } from '../constants/constants'
import '../../static/css/antd.css'
import LimitImg from '../../static/img/limit_level.svg'
import MetaTag from '../meta_tag/meta_tag'
import PayoutMaintenance from '../../static/img/payout_maintenance.svg'
import BuxBlue from '../../static/icons/bux_blue.svg'
import moment from 'moment';
import PayoutDisclaimer from '../payout_drawer/payout_disclaimer'
import Schedule from '../../static/icons/sched.svg'
import * as style from './fundsStyle'
import { ExportToCsv } from 'export-to-csv';
import ExportCsvModal from './exportCsvModal';
import fundsServices from './fundServices';
import { PayoutIconBalance } from '../../static/images/multiwallet';


const ButtonGroup = Button.Group;
const payout_enabled= localStorage.getItem("payout_enabled");
const floatBalance = localStorage.getItem("float_balance") && localStorage.getItem("float_balance").toLocaleString("en-US", { minimumFractionDigits: 2 }).replace(/[^\d\.]/g, '');
const Level = localStorage.getItem("Level");
const kybRequired = localStorage.getItem("kybRequired");
const disbaledPayoutForSub = (localStorage.getItem("userType") === 'SW' && Level == "0" && kybRequired && parseFloat(floatBalance) <= 0);

const mapStateToProps = (state) => {
  const { single_payouts, loading, error, response, exportCsv } = state.funds;
  const { endpoints } = state.child_endpoints;

  return {
    single_payouts,
    loading,
    error,
    response,
    endpoints,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSinglePayouts: bindActionCreators(getSinglePayouts, dispatch),
  };
};

const pagination = { position: 'none' };

const today = moment().format('MM-DD-YYYY')
const userType = localStorage.getItem('userType')

class Funds extends React.Component {

  constructor(props){
    super(props);

  }

  componentDidMount() {
    let current_path = window.location.pathname
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
        history.push('/login?next='+current_path)
      }
    else{
      if(isMobile){
        history.push('/mobile/funds')
      }
      else{
        if(localStorage.getItem("userType") == "AD" || localStorage.getItem("userType") == "OP" || localStorage.getItem("userType") == "KB" || localStorage.getItem("userType") == "FI" || localStorage.getItem("userType") == "BS" || localStorage.getItem("userType") == "AP" || localStorage.getItem("userType") == "EN"){
          history.push('/orders')
        }
        else if(localStorage.getItem("userType") == "PS"){
            history.push('/orders')
        }
        else if(localStorage.getItem("userType") == "PF"){
          history.push('/recon/7_eleven')
        }
        else if(localStorage.getItem("userType") == "MA"){
          history.push('/overview')
        }
        else if (localStorage.getItem("userType") == "KC") {
          history.push('/submissions')
        }
        else if (localStorage.getItem("userType") == "SD") {
          history.push('/submissions_kyb')
        }
        else{
          this.props.getSinglePayouts('', '', this.state.title, '', this.state.summary);
        }
      }
    }
    const elements = PAYMENT_MODE;

    for (const key in elements) {
      let value = elements[key];
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  single_data = this.props.single_payouts ? this.props.single_payouts : []

  exportTable = () => {
    const { single_payouts } = this.props

    let options = { 
      filename: 'Payout-Summary_'+moment().format("MM-DD-YYYY_HH:mm:ss")+'_'+this.state.title,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Payout Summary',
      useTextFile: false,
      useBom: true,
      // useKeysAsHeaders: true
      headers: ["ID", "Account Name", "Account Number", "Business Name", "Bank",  "Amount",  "Payout Status",  "Remarks", "Transaction Date"],
    }
      
    let csvExporter = new ExportToCsv(options);

    if(single_payouts != null){
      csvExporter.generateCsv(single_payouts);
    }
  };

  state = {
    title: 'All',
    maxPage: 5,
    minPage: 0,
    currentPage: 1,
    startDate: '',
    endDate: '',
    endOpen: false,
    searchValue: '',
    disabledBtn: true,
    hideClear: true,
    showDisclaimer: false,
    showExportModal: false,
    email: null,
    summary: 'All'
  }

  clearFilter = () => {
    this.setState({
      hideClear: true,
      disabledBtn: true,
      startDate: '',
      endDate: '',
      searchValue: '',
      summary: 'All'
    })

    this.props.getSinglePayouts('', '', this.state.title, '', 'All');
  }

  getData = () => {
      this.props.getSinglePayouts(this.state.startDate && this.state.startDate.format("MM-DD-YYYY"), this.state.endDate && this.state.endDate.format("MM-DD-YYYY"), this.state.title, this.state.searchValue, this.state.summary);
      this.setState({
        hideClear: false
      });
  }

  exportCsv = async () => {
    try {
      const res = await fundsServices.exportCsv(this.state.startDate && this.state.startDate.format("MM-DD-YYYY"), this.state.endDate && this.state.endDate.format("MM-DD-YYYY"), this.state.title, this.state.searchValue, this.state.summary, this.state.email, true);
      if(res.status === 'success'){
        message.success(res.message);
      } else {
        message.error(res.message);
      }
    } catch (error) {
      if(error.response.data && error.response.data.message){
        this.msgNotif(error.response.data.message);
      } else {
        this.msgNotif('Error try again');
      }
    }
  }

  msgNotif = (msg) => {
    if( msg && msg.data && msg.data.status === "success") {
      message.success(msg.data.message);
    } else if(msg && msg.data && msg.data.status === "failed") {
      message.error(msg.data.message);
    } else {
      message.error(msg);
    }
  }

  disabledStartDate = startValue => {
    var self = this
    if (!startValue || !self.state.endDate) {
      return false;
    }
    return startValue && startValue > moment().endOf('day');
  };

  disabledEndDate = endValue => {
    var self = this
    if (!endValue || !self.state.startDate) {
      return false;
    }
    return endValue.valueOf() <= self.state.startDate.valueOf();
  };
  
  onStartChange = value => {
    var self = this;
    if(value != null && self.state.endDate != ""){
      self.setState({
        startDate: value,
        disabledBtn: false,
        summary: 'Custom'
      })

    }

    else if(value != null){
      self.setState({
        startDate: value,
        disabledBtn: false,
        summary: 'Custom'
      })
    }
    else{
      self.setState({
        startDate: "",
      })
    }
  }

  onEndChange = value => {
    var self = this
    if(value != null && self.state.startDate != ""){
      self.setState({
        endDate: value,
        disabledBtn: false,
        summary: 'Custom'
      })
    }
    else{
      self.setState({
        endDate: "",
      })
    }
  }

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({
        endOpen: true
      })
    }
  };

  handleEndOpenChange = open => {
    this.setState({
      endOpen: open
    })
  };

  closeDisclaimerModal = () => {
    this.setState({
      showDisclaimer: false
    })
  }

  status = [
    'All', 'Success', 'Pending', 'Failed'
  ]
  changePage = (event,page) => {
    console.log(page)
    this.setState({
      maxPage: page * 5,
      minPage: (page-1) * 5,
      currentPage: page
    })
  }
  renderSingleFields(val, status) {
    const { single_payouts } = this.props
    let status_filtered = [];

    if(status != "All"){
      status_filtered = single_payouts.filter(single_payout => single_payout.status.toLowerCase().includes(status.toLowerCase()))
      
    }else{
      status_filtered = single_payouts;
    }
    
    if((val=="")||(val==null)){
      return status_filtered
    }

    let filtered_single_payout = status_filtered.filter(single_payout => single_payout.account_number.toLowerCase().includes(val.toLowerCase()) || 
                                                single_payout.account_name.toLowerCase().includes(val.toLowerCase()) || 
                                                single_payout.bank.toLowerCase().includes(val.toLowerCase()))

    return filtered_single_payout
  }

  single_columns = [
    {
      title: 'Bank',
      dataIndex: 'bank',
      render: (text, record) => {
        return <div style={{paddingLeft: '10px'}}><a style={style.codeStyle}>{record.bank}</a></div>
      }
    },
    {
      title: 'Account',
      dataIndex: 'account_number',
      width: 150,
      render: (text, record) => {
        return <div>
          <a style={style.codeStyle}>{record.account_number}</a>
          <div style={style.descSyle}>{record.account_name}</div></div>
      }
    },
    {
      title: 'Business Name',
      dataIndex: 'business_name',
      width: 150,
      render: (text, record) => {
        return <div style={{paddingLeft: '10px'}}><a style={style.codeStyle}>{record.business_name}</a></div>
      }
    },
    {
      title: 'Date',
      dataIndex: 'date_paid',
      render: text => {
        return <span style={style.dataStyle}>{moment(text).format('MMM DD YYYY, h:mm A')}</span>
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      render: text => {
        return <div style={{
          backgroundColor: text.includes("Failed") ? 'rgba(226, 76, 76, 0.1)' : text.includes("Pending") ? 'rgba(245, 146, 47, 0.1)' : 'rgba(29, 210, 139, 0.1)',
          border: text.includes("Failed") ? '1px solid #E24C4C' : text.includes("Pending") ? '1px solid #F5922F' : '1px solid #1DD28B',
          borderLeft: text.includes("Failed") ? '5px solid #E24C4C' : text.includes("Pending") ? '5px solid #F5922F' : '5px solid #1DD28B',
          ...style.statusStyle
        }}>
          {text}
        </div>
      }
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      render: text => {
        return <span style={style.dataStyle}>{text == '' ? '--' : text}</span>
      }
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: text => {
        return <span style={style.codeStyle}>{"₱" + text.slice(3).toLocaleString("en-US", {minimumFractionDigits: 2})}</span>
      }
    },
  ];

  statusStyle = (text) =>{
    
  }

  menu = (
    <Menu style={{ width: '180px' }}>
      <Menu.Item key="0" style={this.menuStyle}>
        View
        </Menu.Item>
    </Menu>
  );

  render() {
    const { title, maxPage, minPage, currentPage } = this.state
    const { single_payouts, endpoints } = this.props;
    // const payout = payoutStyles();

    
    const isChild = localStorage.getItem("is_child") === "true";
    if (isChild) {
      // Return null component while endpoints are fetched
      if (!endpoints.length) {
        return null;
      }

      // Redirect if URL was manually typed but access is restricted
      if (!endpoints.includes('funds_enterprise')) {
        history.push(endpoints[0]);
      }
    }

    if (userType === "SW" && !endpoints.includes('funds')) {
      history.push(endpoints[0]);
    }

    return (
      <div style={style.mainPadding}>
        <MetaTag title="Payout"/>
          <div style={style.twoCol}>
            <div style={style.orderStyle}>Payout</div>
          </div>

          <div style={{ margin: '12px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <ButtonGroup>
              {
                this.status.map((item) => {
                  return <Button
                    key={item.toLocaleLowerCase()}
                    onClick={() => {this.setState({
                      title: item,
                      maxPage: 5,
                      minPage: 0,
                      currentPage: 1
                    }); 
                    this.props.getSinglePayouts(this.state.startDate && this.state.startDate.format("MM-DD-YYYY"), this.state.endDate && this.state.endDate.format("MM-DD-YYYY"), item, this.state.searchValue, this.state.summary);
                    }}
                    style={{
                      ...style.btnGrpStyle,
                      borderTopLeftRadius: item == "All" ? '10px' : '0px',
                      borderBottomLeftRadius: item == "All" ? '10px' : '0px',
                      borderTopRightRadius: item == "Failed" ? '10px' : '0px',
                      borderBottomRightRadius: item == "Failed" ? '10px' : '0px',
                      color: title === item ? '#ffffff' : '#2b2d33',
                      backgroundColor: title === item ? '#0D3D76' : '#fff',
                    }}>{item}</Button>
                })
              }
            </ButtonGroup>
            <Button
                style={{
                  ...style.exportBtnStyle,
                  opacity: single_payouts.results && single_payouts.results.length == 0 ? '0.5' : '',
                  cursor: single_payouts.results && single_payouts.results.length == 0 ? 'not-allowed' : 'pointer'
                }}
                disabled={single_payouts.results && single_payouts.results.length == 0}
                onClick={ ()=>this.setState({showExportModal: true}) }
              >
              <Icon type="download" style={{paddingRight: 8}}/>
              <div style={style.textStyle}>Export CSV</div>
              </Button>
          </div>

          <div style={{
              ...style.maintenanceStyle,
              display: payout_enabled != "true" ? 'block' : 'none'
            }}>
            <div style={{display: 'flex'}}>
              <img src={PayoutMaintenance} alt="Icon"></img>
              <div style={{marginLeft: '20px'}}>
                  <div style={style.codeStyle}>Payout Temporarily Disabled</div>
                  <div style={style.descSyle}>InstaPay and Pesonet is currently under maintenance. It will only take a while. Thank you for your patience.</div>
              </div>
              
            </div>

          </div>

          <div style={style.buxBalSchedDiv}>
            <div>
              <div style={{display: 'flex', paddingBottom: 4}} hidden={userType === 'SW'}>
                <img src={BuxBlue} alt="BUx Icon" style={{marginRight:'10px', marginTop:'-2px'}}/>
                <div style={{lineHeight: '18px'}}>
                  <div style={{fontSize:'12px'}}>BUx Wallet Balance</div>
                  <div style={style.balStyle}>{localStorage.getItem("balance")}</div>
                </div>
              </div>
              <div style={{display: 'flex', paddingTop: 4}} hidden={userType !== 'SW'}>
                <img src={PayoutIconBalance} alt="BUx Icon" style={{marginRight:'10px', marginTop:'-2px'}}/>
                <div style={{lineHeight: '18px'}}>
                  <div style={{fontSize:'12px'}}>Transferable Balance</div>
                  <div style={style.balStyle}>{localStorage.getItem("float_balance")}</div>
                </div>
              </div>
            </div>

            {/* <Divider type="vertical" style={{height: '80%', width:'2px'}}/> */}

            <div>
              <div style={{display: 'flex', marginRight: 16}} 
              // hidden={userType != "CO" || localStorage.getItem("is_multiwallet") == "true"} 
              >
                <img src={Schedule} alt="Icon" style={{marginRight:'10px', marginTop:'-10px'}}/>
                <a href="/payout_schedule" style={style.sched}>View Payout Schedule</a>
              </div>
              
              <Button hidden={userType == "CO" && localStorage.getItem("is_multiwallet") != "true"} style={{
                  ...style.payoutBtnStyle,
                  opacity: payout_enabled != "true" || (Level == 0 && ['ME', 'CO'].includes(userType)) || disbaledPayoutForSub ? '0.5' : '1',
                }}
                onClick={() => this.setState({showDisclaimer: true})}
                disabled={ payout_enabled != "true" || (Level == 0 && ['ME', 'CO'].includes(userType)) || disbaledPayoutForSub }
              >
              <div style={style.textStyle}>Payout</div>
              </Button>
            </div>
          </div>

          <div style={style.tableDiv}>
            <div style={style.pillFlex}>
              <div style={{display: 'flex'}}>
                <div style={{...style.codeStyle, fontSize: 16}}>Filter</div>
                <div style={{marginLeft: 10}} hidden={this.state.hideClear}>
                  <button style={style.clearBtn} onClick={this.clearFilter}><Icon type="close-circle" theme="filled" style={{paddingRight: 4}} /> Clear Filter</button>
                </div>
              </div>
              
              <div>
                <div style={style.noteStyle}><Icon type="info-circle" theme="filled" style={{paddingRight: 4}} />Click <b>Apply</b> button to show results.</div>
              </div>
            </div>

            <div align="right">
              <Select 
                size="large"
                value={this.state.summary}
                onChange={
                  (e)=> {
                    this.setState({
                      summary: e,
                      clearBtn: false,
                      disabledBtn: false,
                    });
                  }
                  }
                style={{color: '#000', marginRight: 8}}
                >
                  <Select.Option value="All">All Time</Select.Option>
                  <Select.Option value="Today">Today</Select.Option>
                  <Select.Option value="Week" >Last 7 days</Select.Option>
                  <Select.Option value="Month" >Last 30 days</Select.Option>
                  <Select.Option value="Custom" >Custom</Select.Option>
              </Select>
              <DatePicker
                style={style.datePickerStyle}
                disabledDate={this.disabledStartDate}
                size="large"
                format='MM-DD-YYYY'
                value={this.state.startDate || null}
                placeholder="Start"
                onChange={this.onStartChange}
                onOpenChange={this.handleStartOpenChange}
              />
              <span style={{padding: '0 8px'}}>-</span> 
              <DatePicker
                style={{...style.datePickerStyle, marginRight: '12px'}}
                disabledDate={this.disabledEndDate}
                size="large"
                format='MM-DD-YYYY'
                value={this.state.endDate || null}
                placeholder="End"
                onChange={this.onEndChange}
                open={this.state.endOpen}
                onOpenChange={this.handleEndOpenChange}
              />

              <Input
                placeholder="Search"
                style={style.searchStyle}
                onChange={(e)=>this.setState({
                  searchValue: e.target.value,
                  disabledBtn: false,
                  maxPage: 5,
                  minPage: 0,
                  currentPage: 1
                })}
                value={this.state.searchValue}
                suffix={<Icon type="search" />}
              />

              <button 
                style={this.state.disabledBtn ? style.disabledBtn : style.activeBtn}
                disabled={this.state.disabledBtn}
                onClick={this.getData}>
                Apply
              </button>
            </div>
          </div>
          
          <Table
            size="middle"
            pagination={{pageSize: 10, size: 'small'}}
            rowClassName="table-row-light"
            columns={this.single_columns}
            dataSource={this.props.single_payouts.results ? this.props.single_payouts.results : []}/>

          {/* <div style={{marginTop: '10px', display:'flex', justifyContent:'space-between'}} hidden={this.renderSingleFields(this.state.searchValue, this.state.title).length / 5 < 1}>
            <div>
              {minPage + 1} - {maxPage > this.renderSingleFields(this.state.searchValue, this.state.title).length ? this.renderSingleFields(this.state.searchValue, this.state.title).length : maxPage} out of {this.renderSingleFields(this.state.searchValue, this.state.title).length}
            </div>
            <Pagination 
              count={this.renderSingleFields(this.state.searchValue, this.state.title).length % 5 != 0 ? parseInt(this.renderSingleFields(this.state.searchValue, this.state.title).length / 5) + 1 : parseInt(this.renderSingleFields(this.state.searchValue, this.state.title).length / 5)} 
              shape="rounded" 
              size="small"
              onChange={this.changePage}
            />
          </div> */}

          <Modal
            visible={this.state.limitModal}
            onCancel={()=>this.setState({limitModal: false})}
            footer={null}
            width={690}
            >
            <div style={this.orderStyle}>Limits by Level</div>
            <img src={LimitImg} alt="Icon" style={{marginTop: '30px'}}/>
          </Modal>

          <PayoutDisclaimer visible={this.state.showDisclaimer} closeModal={this.closeDisclaimerModal} />
          <ExportCsvModal visible={this.state.showExportModal} onClose={() => this.setState({ showExportModal: false })} onSend={() => {this.exportCsv(); this.setState({ showExportModal: false});}} onChange={(e) => this.setState({ email: e}) } />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Funds);