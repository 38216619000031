import React from 'react';
// import WelcomeModal from './welcomeModal';
import Guide from './guide';
import { history } from '../../../store/history';
import tutorialActions from '../tutorialActions';
import { useDispatch, useSelector } from 'react-redux';

const DashboardTutorial = ({custId}) => {
  const lastLogin = localStorage.getItem('lastLogin') === '0';
  const welcomeModalShow = useSelector(state => state.tutorials.startModal);
  const startDemo = useSelector(state => state.tutorials.startDemo);
  const userType = localStorage.getItem('userType');
  const [initialize, setInitialize]= React.useState(false);
  const dispatch = useDispatch();

  const showDialog = () => {
    const body = document.body;
    // const scrollY = body.style.top;
    // const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    // body.style.position = 'fixed';
    // body.style.overflowY = 'hidden';
    // body.style.overflow =' hidden';
    history.push('/dashboard');
  };

  const closeDialog = () => {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }

  const skipTutorial = () => {
    dispatch(tutorialActions.setTutorialStartModal(false));
    dispatch(tutorialActions.setStartDemo(false));
    localStorage.setItem('lastLogin', '1');
    setStep(null);
    closeDialog();
  }

  const setStep = (step) => {
    dispatch(tutorialActions.setDemoStep(step));
  }

  React.useEffect(() => {
    const path = history.location.pathname;
    // const mainBodyDOMRect = document.getElementById('content-body')&&document.getElementById('content-body').offsetHeight;
    let tutorialRoot = document.getElementById('db-tutorial-root')&&document.getElementById('db-tutorial-root');
    if(userType==='ME'&&startDemo||userType==='CO'&&startDemo){
      // tutorialRoot.style.height = path==='/dashboard'? '151%': path==='/orders'&&userType==='CO'? '120%': path==='/orders'&&userType==='ME'||path==='/funds'&&userType==='ME'?'180%': path==='/refer_lender'? '216%' : path ==='/link_stores'?'107%': path==='/integration'?'117%':'100%';
      tutorialRoot.style.height = path==='/recon'? '250%' : path==='/link_stores'?'200%' : path==='/vouchers'?'230%' : path === "/multiwallet"? '230%' : path === "/account_settings"? '350%' : path==='/integration'?'250%' : '200%';
    }
  }, [window.location.href, startDemo])

  if(!initialize&&lastLogin&&userType!=="SW"){
    setInitialize(true);
    // dispatch(tutorialActions.setStartDemo(true));
    // dispatch(tutorialActions.setTutorialStartModal(true));
  }

  React.useEffect(() => {
    if (startDemo) {
        showDialog();
        setStep(0);
    }
  },[startDemo])
  
  return (
    startDemo&&<div id='db-tutorial-root' style={styles.root}>
      {/* <WelcomeModal visible={welcomeModalShow} 
        closeModal={skipTutorial} 
        startTurorial={()=>{dispatch(tutorialActions.setTutorialStartModal(false));showDialog();setStep(0);}} /> */}
      <Guide custId={custId} />
    </div>
  )
}

const styles = {
  root: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 999,
    backgroundColor: 'rgb(0 0 0 / 80%)',
    overflowY: 'auto',
  },
  nextBtn: {
    height: 48,
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
  },
  footerBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 20,
  },
  backBtn: {
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10,
    height: 48,
    border: '1px solid #F5922F',
    width: '48%',
  },

}

export default DashboardTutorial;