import { makeStyles } from '@material-ui/core';

const ecommerceStyle = makeStyles(theme => ({

    main: {
        width: '50%',
        marginTop: '3%',
        paddingLeft: '10%',
        paddingRight: '10%',

    },

    header: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#0D3D76',
        marginBottom: '20px'
    },

    forgotStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
        color: '#2B2D33',
        opacity: '0.5',
        float: 'right'
    },

    rememberMeStyle: {
    
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2B2D33',
        marginTop: '20px'
    },

    loginBtnStyle: {
        borderRadius: '4px',
        backgroundColor: '#1D82B8',
        width: '100%',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        height: '50px',
        marginTop: '20px'
    }
    

}))

export default ecommerceStyle;