import React from 'react'


const TestModeCard = props => {

    const {
        imageSource,
        header,
        subText,
        cardWidth,
        hidden,
    } = props

    return(
        <div hidden={hidden} style={{width: cardWidth, ...styles.mainCardStyle}}>
            <div style={styles.subCardDiv}>
                <img src={imageSource} alt="#" />
                <div style={styles.textDiv}>
                    <div style={styles.headerStyle}>{header}</div>
                    <div style={styles.subTextStyle}>{subText}</div>
                </div>
            </div>

            
        </div>
    )
}


const styles = {
    mainCardStyle: {
        background: '#fff',
        borderRadius: 4,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        border: '1px solid #e6eaf0',
        padding: '8px 16px',
        textAlign: 'left',
        marginBottom: 32
    },

    subCardDiv: {
        display: 'flex'
    },

    textDiv: {
        marginTop: '2vh',
        marginLeft: '1vw'
    },

    headerStyle: {
        fontWeight: 'bold',
        fontSize: 16,
        lineHeight: '20px',
        color: '#000000'
    },

    subTextStyle: {
        fontWeight: 'normal',
        fontSize: 12,
        lineHeight: '16px',
        color: '#000000',
        marginTop: 4
    }
}

export default TestModeCard


