import React from 'react';
import axios from 'axios';
import * as styles from './style';
import { Button, message } from 'antd';
import CredentialInput from './credentialsInput';

const CustomCredentials = props => {

    const {
        pUid, title, type
    } = props;

    const [profileName, setProfileName] = React.useState("");
    const [profileId, setProfileId] = React.useState("");
    const [secretKey, setSecretKey] = React.useState("");
    const [accessKey, setAccessKey] = React.useState("");
    const [btnText, setBtnText] = React.useState("Add");
    const [editBtn, setEditBtn] = React.useState(true);

    let yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    };

    let url = type === "sandbox" ? 
                process.env.REACT_APP_API_URL + `/api/sandbox/credentials/` 
            : 
                process.env.REACT_APP_API_URL + `/api/credentials/`


    async function getCreds(){

        let response = await axios.get(url+`${pUid}/?type=${type}`,yourConfig);
        
        if(response.data.status === "success") {
            setBtnText("Save");
            setEditBtn(false)
            setProfileName(response.data.data.profile_name);
            setProfileId(response.data.data.profile_id);
            setSecretKey(response.data.data.secret_key);
            setAccessKey(response.data.data.access_key);
        }
    };

    async function submitCreds() {
        let params = {
            user_id: pUid,
            type: type,
            profile_id: profileId,
            profile_name: profileName,
            secret_key: secretKey,
            access_key: accessKey,
        };

        try {
            let response = await axios.post(url,params, yourConfig);

            console.log(response.data)
            if(response.data.status === "failed") {
                message.destroy();
                message.error(response.data.message, 3);
            }

            else if (response.data.status === "success") {
                message.destroy();
                message.success(response.data.message, 3);
                getCreds();
            }
        }

        catch(error) {
            console.log(error)
        }
    };

    const isValidForm = () => {
        if (!profileId || !profileName || !secretKey || !accessKey) {
            return true
        }

        else {
            return false
        }
    }
 

    React.useEffect(() => {
        getCreds();
    }, [window.location.hash === '#credentials']);

    return(
        <div style={styles.subDiv}>
            <div style={styles.twoCol}>
                <div style={styles.subHeader}>{title}</div>
                
                <div>
                    <Button style={{...styles.btnStyle}} 
                        onClick={() => setEditBtn(!editBtn)} 
                        hidden={editBtn}>
                            Edit
                    </Button>
                </div>
            </div>

            <div style={styles.twoCol}>
                <CredentialInput placeholder="Profile Name" val={profileName} changeVal={setProfileName} width="13vw" type="text" editBtn={editBtn} />
                <CredentialInput placeholder="Profile ID" val={profileId} changeVal={setProfileId} width="13vw" type="text" editBtn={editBtn} />
            </div>

            <CredentialInput placeholder="Access Key" val={accessKey} changeVal={setAccessKey} width="100%" type="text" editBtn={editBtn} />
            <CredentialInput placeholder="Secret Key" val={secretKey} changeVal={setSecretKey} width="100%" type="password" editBtn={editBtn} />


            <div align="right" style={{marginTop: 16}} hidden={!editBtn}>
                <Button style={{...styles.cancelBtn, marginRight: '8px'}} 
                    onClick={() => setEditBtn(!editBtn)} 
                    hidden={btnText == "Add"}>
                        Cancel
                </Button>

                <Button style={{...styles.btnStyle, opacity: isValidForm() ? 0.5 : ''}} 
                onClick={() => submitCreds()} 
                disabled={isValidForm()}>
                    {btnText}
                </Button>
            </div>
        </div>
    )
};

export default CustomCredentials;