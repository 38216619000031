export function Logout () {
    localStorage.setItem("userId", "");
    localStorage.setItem("isAlias", "");
    localStorage.setItem("KYBStep", "");
    localStorage.setItem("KYBType", "");
    localStorage.setItem("jwtToken", "");
    localStorage.setItem("firstName", "");
    localStorage.setItem("lastName", "");
    localStorage.setItem("middleName", "");
    localStorage.setItem("suffix", "");
    localStorage.setItem("username", "");
    localStorage.setItem("email", "");
    localStorage.setItem("contact", "");
    localStorage.setItem("userType", "")
    localStorage.setItem("isCompleted", "")
    localStorage.setItem("isVerified", "")
    localStorage.setItem("Step", "")
    localStorage.setItem("Level", "")
    localStorage.setItem("lastLogin", "")
    localStorage.setItem("shipping_enabled", "")
    localStorage.setItem("isWaive", "")
    localStorage.setItem("changePw", "")
    localStorage.setItem("expiryDate", "");
    localStorage.setItem("merchant_bias", "")
    localStorage.setItem("is_multiwallet", "")
    localStorage.setItem("is_child", "");
    localStorage.setItem("isAlias", "");
    localStorage.setItem("is_sb_multiwallet", "")
    localStorage.setItem("subuser_role", "")
    localStorage.setItem("kybRequired", "true")
    localStorage.setItem("subwallet_type", "");
    localStorage.setItem("subwallet_types_enabled", "");
}