import React, {useEffect} from 'react'
import axios from 'axios'
import {Spin, Icon, message, Upload, Alert, Modal, Input, Checkbox, Select, Button} from 'antd';
import MetaTag from '../meta_tag/meta_tag'
import kycStyles from '../../static/css/Kyc'
import { Divider, Grid } from '@material-ui/core';
import greenCheck from '../../static/icons/greenCheck.svg'
import Submitted from '../../static/icons/submitted.svg'
import {history} from '../../store/history'


const {Option} = Select
const max_width = 2000;
const max_height = 2000;

const MobileBusinessUpgrade = props => {

    const kyc = kycStyles();


    useEffect(() => {
        fetchProfile();
    }, [])

    const [addressModal, setAddressModal] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [fileType, setFileType] = React.useState(true);
    const [upload, setUpload] = React.useState("");
    const [presentAddress, setPresentAddress] = React.useState("");
    const [permanentAddress, setPermanentAddress] = React.useState("");
    const [permanentAddressC, setPermanentAddressC] = React.useState(false);
    const [uploadedDoc, setUploadedDoc] = React.useState("");
    const [file, setFile] = React.useState({});
    const [nameDoc, setNameDoc] = React.useState("");
    const [ext, setExt] = React.useState("");
    const [uploadBtn, setUploadBtn] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(true);
    const [uploading, setUploading] = React.useState(true);
    const [pm, setPm] = React.useState(true)
    const [submittedModal, setSubmittedModal] = React.useState(false)
    const [step, setStep] = React.useState("")
    const [remarks, setRemarks] = React.useState("")
    let fileUpload = React.createRef();


    async function fetchProfile(){

        const yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let response = await axios.get(process.env.REACT_APP_API_URL + '/api/kyc/', yourConfig)

        console.log(response)

        if(response.data.present_address == response.data.permanent_address){
            setPermanentAddressC(true)
        }

        else{
            setPermanentAddressC(false)
        }

        setPresentAddress(response.data.present_address);
        setPermanentAddress(response.data.permanent_address);
        setRemarks(response.data.remarks);

        let c, d,e;

        if(response.data.pob_image_url == ""){
            setUploadBtn(false)
        
        }

        else{
            setUploadBtn(true)
            c = response.data.pob_image_url
            d = c.split(".")
            e = d[3].split("/")

            if(d[4] === "pdf"){
                setNameDoc(e[4]+".pdf")
                setUploadedDoc(response.data.pob_image_url)
                setExt("pdf")
            }
    
            else {
    
                setUploadedDoc(response.data.pob_image_url)
            }
    
        }
        
        

        if(response.data.step){
            setStep(response.data.step)
        }
        
        if(response.data.step < 10){
            window.location.href = "/mobile/upgrade";
        }
        // if(response.data.step == 9) {
        //     window.location.href = '/mobile/review_application'
        // }

        // else if (response.data.step == 10){
        //     setLoading(!loading)

        // }
        
        // else {
        //     setLoading(!loading)
        // }
    }

    const ff = [
        `Bank/Credit Card`, 'Phone/Telephone Bill', 'Utility Bills (Electric, Water, Internet/Cable etc.)', 'Insurance Statement'
    ]

    const req = [
        'Must be clear and readable', <span>Dated within the last <b>2 months</b></span>, 'The address of the document you are about to upload should match with your existing information.'
    ]

    function setPermaAdd(){
        if(!permanentAddressC){
            setPermanentAddressC(true)
            setPermanentAddress(presentAddress)
        }
        else{
            setPermanentAddressC(false)
        }
    }

    function openModal(){
        setSubmittedModal(true)
    }

    const handleDocUpload = (info) => {


        setUploading(false)
        setUploadBtn(false)
        console.log(info)

        const isJpgOrPng = info.type === 'image/jpeg' || info.type === 'image/png' || info.type === 'application/pdf';
        if (!isJpgOrPng) {
            message.error('File not supported, must be PNG/JPG or PDF only', 5)
            setUploading(true)
            setUploadBtn(true)
        }
        else{
            setPm(true)
            setFile(info)

            setNameDoc("")
            if(info.type.includes('image')){
    
                var reader = new FileReader();
                reader.readAsArrayBuffer(info);
                
                reader.onload = function (event) {
                    // blob stuff
                    console.log(event)
                    var blob = new Blob([event.target.result]); // create blob...
                    window.URL = window.URL || window.webkitURL;
                    setUploadedDoc(window.URL.createObjectURL(blob)) // and get it's URL
                    setUploadBtn(true)
                    setUploading(true)
                
                    
                };
    
            }
            else{
                setNameDoc(info.name)
                setUploadedDoc("#")
                setUploadBtn(true)
                setUploading(true)
            }
        }

        

        

    };
   

    async function submitUpload(step) {

        setUploading(false)

        let formData = new FormData()    

        if(file != {}){
            formData.append('pob_image', file)
            
        }
        formData.append('step', 11)

        const yourConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.post(process.env.REACT_APP_API_URL + '/api/kyc/', formData, yourConfig)
    
        setUploading(true)
        if(response.data.status === "success"){
             openModal()
        }

        else{
            message.error('Error Submitting File', 5)
        }
        
        
    }

    function closePM () {
        setPm(true)
    }

    function submitPob(step){

        let params = {}

        if(step == 10){

           
            if(permanentAddressC){
                if(presentAddress == "" || presentAddress == null){
                    setShowAlert(!showAlert)

                    return
                }

                else{
                    params = {
                        present_address: presentAddress,
                        permanent_address: presentAddress,
                        step: 10,
                    }
                }
            }

            else {
                if(presentAddress == "" || presentAddress == null || permanentAddress == "" || permanentAddress == null){
                    setShowAlert(!showAlert)
                    return

                }

                else{
                    params = {
                        present_address: presentAddress,
                        permanent_address: permanentAddress,
                        step: 10,
                    }
                }
            }
        }

        if (step == 11){
            params = {
                step: 11,
            }
        }


        submission(params)
    }


    async function submission(val) {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.post(
            process.env.REACT_APP_API_URL + "/api/kyc/",
            val,yourConfig
        );


        console.log(response)

        if(response.data.step == 11 && response.data.status == "success"){
            setAddressModal(!addressModal)
            setShowAlert(!showAlert)
        }

        if(response.data.step == 12 || response.data.step == 13 && response.data.status == "success"){
            window.location.href = '/mobile/upgrade/'
        }

        if(response.data.status == "success"){
            setAddressModal(false);
        }

    }

    return(
        <div>
            <MetaTag title="Upgrade Account"/>

            <Modal
                visible={addressModal}
                onCancel={()=>setAddressModal(false)}
                footer={null}
                >
                <div className={kyc.modalHeader}>Edit Address</div>


                <div hidden={showAlert}>
                    <Alert message="Please fill up the field" type="error" style={{borderLeft: '4px solid red'}}/>
                </div>
                <Grid container>
                    <Grid item xs={12}>
                      <div style={{marginTop: 16}}>
                            <div className={kyc.textStyle}>Present Address</div>
                            <Input style={{ width: '100%'}} value={presentAddress} onChange={(e)=>{setPresentAddress(e.target.value); if(permanentAddressC){setPermanentAddress(e.target.value)}}}/>
                        </div>
                    </Grid>

                    <Grid item xs={12}>

                        <div style={{marginTop: 16}} hidden={permanentAddressC}>
                            <div className={kyc.textStyle} >Permanent Address</div>
                            <Input style={{ width: '100%'}} value={permanentAddress} onChange={(e)=>setPermanentAddress(e.target.value)}/>
                        </div>

                    </Grid>

                    <Grid item xs={12}>

                        <div style={{marginTop: 16}}>
                            <div className={kyc.PermaStyle}>Permanent Address</div>
                            <div style={{display: 'flex'}}>
                                <Checkbox style={{marginRight: '10px'}} onChange={setPermaAdd} checked={permanentAddressC}/>
                                <div className={kyc.PresentStyle}>
                                    Same as Present Address
                                </div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div style={{marginTop: '16px'}}>
                            
                            <button className={kyc.submitButton} onClick={()=> submitPob(10)}>Save</button>
                        </div>
                        
                    </Grid>

                    <Grid item xs={12}>
                        <div style={{marginTop: '16px'}}>
                            
                            <button className={kyc.cancelBtn} onClick={() => setAddressModal(false)}>Cancel</button>
                        </div>
                        
                    </Grid>
                </Grid>
                
            </Modal>

            

            <div align="center" style={{ 
                margin: 0,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateY(50%, 50%)'}} hidden={loading}>
                <Spin size="large" />
            </div> 

            <div className={kyc.mainBody} align='center' hidden={!pm}>

                <div className={kyc.rejectedDiv} align='left' hidden={step != 14}>
                    <div className={kyc.rejectedHeader}>Upgrade Application was declined</div>
                    <div className={kyc.rejectedText}>Kindly check your uploaded file or image and resubmit it. Please make sure the document meets our requirements.</div>
                    <div className={kyc.rejectedText}>Remarks: {remarks}</div>
                </div>

                <div className={kyc.header} align='left'>Upgrade Application</div>
                

                <div className={kyc.statusDiv} align='left'>
                    <div className={kyc.businessHeader}>Proof of Billings</div>
                    <div className={kyc.businessHeader}>Can be any of the following:</div>
                
                    <ul className={kyc.ul}>
                        {
                            ff.map((item) => {
                                return(
                                    <li className={kyc.bills}>{item}</li>
                                )
                            })
                        }
                    </ul>
                    
                    <ul className={kyc.ul} style={{listStyleImage: `url(${greenCheck})`}}>
                        {
                            req.map((item) => {
                                return(
                                    <li>{item}</li>
                                )
                            })
                        }

                    </ul>

                    <div className={kyc.addressDiv}>
                        <div>{presentAddress}</div>
                    </div>

                    <div className={kyc.matchAddress}>
                        Does it match the address in your document? <span style={{fontWeight:'bold', color: '#1d82b8'}}><a onClick={() => setAddressModal(true)}>Edit Address</a></span>
                    </div>

                    <Divider />

                    <div className={kyc.uploadDiv}>
                        <div>Upload Proof of Billings</div> 

                        <div hidden={fileType} >
                        <Alert type="error" message="File type not allowed" style={{borderLeft: '4px solid red', marginTop: '16px'}}/>
                        </div>
                        
                         <input type="file"
                            ref={fileUpload}
                            style={{opacity: '0'}}
                            onChange={(e)=>handleDocUpload(e.target.files[0])}
                            accept=".jpg,.png,.jpeg,.pdf"
                           >
                        </input>
                        <div hidden={uploadBtn}>
                            <button className={kyc.uploadButton} onClick={()=>fileUpload.current.click()}>
                                <Icon type="upload"/> <span style={{paddingLeft: '16px'}}>Upload Photo or PDF</span>
                            </button>
                        </div>

                        <div className={kyc.previewDiv} align="center" hidden={!uploadBtn}>

                            <div hidden={uploading}>
                                <Spin size="large" />
                            </div>
                            <div hidden={!uploading}>
                            {   
                                nameDoc === "" || nameDoc === null ? 
                                <div>
                                <img src={uploadedDoc} style={{ width: '100%', marginBottom: '16px' }} />

                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            
                                <div>
                                    <a style={{fontWeight:'bold', color: '#1d82b8', fontSize: '14px'}} onClick={() => setPm(false)}>View Photo</a>
                                </div>

                                {/*<Upload    
                                    beforeUpload={beforeIDUpload} 
                                    accept={".pdf, .png, .jpg"}
                                    onChange={handleDocUpload} 
                                    name="avatar"
                                    className="avatar-uploader"
                                    action="https://bux.ph"
                                    showUploadList={false}
                                    style={{width: '100%'}}
                                >   */}

                                <a style={{fontWeight:'bold', color: '#1d82b8'}} onClick={()=>fileUpload.current.click()}>Replace Photo</a>
                                {/*</Upload>*/}
                                </div>

                                </div>

                                :
                                
                                <div>

                                { uploadedDoc == "#" ? <p align='center' style={{marginBottom: '16px', width: "100%", textOverflow: "ellipsis", color: '#2b3d33',
                                whiteSpace: "nowrap",
                                fontWeight: '600',
                                overflow: "hidden"}}>{nameDoc}</p> :
                                 <a href={uploadedDoc} target="_blank"><p align='center' style={{marginBottom: '16px', width: "100%", textOverflow: "ellipsis", color: '#2b3d33',
                                whiteSpace: "nowrap",
                                fontWeight: '600',
                                overflow: "hidden"}}>{nameDoc}</p></a>}

                                <a style={{fontWeight:'bold', color: '#1d82b8'}} onClick={()=>fileUpload.current.click()}>Replace File</a>
                               
                                </div>
                            }

                        
                            </div>
                        </div>
                        
                        <div style={{marginTop: '16px'}} align='center'>
                        Photo or PDF with less than <b>10 MB</b> size are accepted.
                        </div>
                    </div>
                    
                    <div className={kyc.uploadDiv}>
                        <button className={uploadedDoc && uploading ? kyc.submitButton : kyc.disabledSubmitButton} disabled={!uploadedDoc} onClick={() => submitUpload(11)}>{step == 14 ? "Resubmit Proof of Billings" : "Submit Proof of Billings"}</button>
                    </div>

                    
                </div>
            </div>

            <div className={kyc.previewModal} hidden={pm}>
                
                <div align="right"><button className={kyc.closePM} onClick={() =>closePM()}><Icon type="close" /></button></div>
                
                <div align='center' style={{verticalAlign: 'middle', marginTop: '30%'}}>
                    <img src={uploadedDoc}  style={{width: '100%', height: '50%'}}align='center' />
                </div>

                <div style={{marginTop: '10%'}}>
                    <button className={kyc.replaceBtn} onClick={()=>fileUpload.current.click()}>
                        <span style={{paddingLeft: '16px'}}>Replace Photo</span>
                    </button>
                </div>
            </div>

            <Modal
                visible={submittedModal}
                onCancel={()=>setSubmittedModal(false)}
                footer={null}
                >
                <div style={{marginTop: '50'}} align='center'>
                    <img src={Submitted} />
                    <div className={kyc.tipsStyle}>Successfully Submitted</div>
                    <div className={kyc.textStyle3}>We’ve got your Profile information, and currently reviewing it. Kindly give us a maximum of 48 hours to evaluate your application to upgrade your account.</div>
                    <div className={kyc.textStyle3}>Haven’t received your status? Please contact us at <a href="mailto:support@bux.ph" style={{color: '#1d82b3'}}>support@bux.ph</a></div>
                    
                    <Button className={ kyc.submitBtnStyle } onClick={() =>  history.push('/mobile/upgrade')}>Done</Button>

                </div>
            </Modal>

        </div>
    )

}

export default MobileBusinessUpgrade;