import React, { useState } from "react";
import { Card, Typography, Button, Spin } from "antd";
import { history } from '../../../store/history';
import { isMobile } from 'react-device-detect';

const BoxStats = (data) => {
  return (
    <div className="box-stats">
      <Typography className="smallTextStyle liveColor">
        {data.name}
      </Typography>
      <Typography style={{ fontSize: isMobile? 16 : '1vw', color: `${data.color}`}}>
        {data.value || data.value == 0  ? <strong>{data.value}</strong> : <Spin size='small' /> }
      </Typography>
    </div>
  )
}

const renderStats = (data) => {
  return (
    <div style={{display: 'flex', width: '100%'}}>
    { data ? data.map((item, index) => {
        return (
          <BoxStats key={index} {...item} />
        )
      })
    : <Spin size="small" />}
  </div>
  )
}

const renderHeader = (value, testMode) => {
  const trueValue = value !== null ? value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : [];

  return (
    <div className="top" style={{padding:testMode? '0px 4px 8px 4px' : '0px 4px 14px 4px'}}>
      <div><Typography className="boldTextStyle">Today's Sales</Typography></div>
      { value !== null ? 
        <div className="flex-center">
          {/* <Typography style={styles.header.peso}>₱</Typography> */}
          <Typography className="semiBoldText" style={{padding: '0 2px 0 0'}}>₱ </Typography>
          <Typography className="boldTextStyle">{trueValue}</Typography>
          {/* <Typography style={styles.header.decimal}>{decimalValue}</Typography> */}
        </div>
      : <Spin size="small" className="flex-center" style={{height: 42}} /> }
    </div>
  )
}

const OrderSalesCard = (props) => {
  const { data } = props;
  const [headerValue, setHeaderValue] = useState(null);
  const [bodyValues, setBodyValues] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const testAccess = ['CO', 'SW']
  const testMode = (history.location.pathname.indexOf('/test') > -1&& testAccess.includes(localStorage.getItem('userType')));
  const segments = localStorage.getItem("segments")

  let value = [
    {
      name: "Paid",
      value: data.datas && data.datas.cashin_limits ? data.datas.paid : null,
      color: '#1DD28B',
      bgColor: 'rgb(232, 250, 243)'
    },
    {
      name: "Pending",
      value: data.datas && data.datas.cashin_limits ? data.datas.pending : null,
      color: '#F5922F',
      bgColor: 'rgb(254, 242, 231)'
    },
    {
      name: "Expired",
      value: data.datas && data.datas.cashin_limits ? data.datas.expired : null,
      color: '#E24C4C',
      bgColor: 'rgb(251, 234, 236)'
    }
  ]

  if (bodyValues === null) {
    setBodyValues(value);
  }

  if (!initialized && (data.lastEntry) && data.datas  ) {
    setHeaderValue(data.lastEntry.amount);
    setBodyValues(value);
    setInitialized(true);
  }

  return (
    <Card style={{ marginBottom: isMobile && 16}} className="tracker-card" size='small' align="center">
     <div hidden={!testMode} align='right'>
      <div className="test-mode-pill">Test Mode</div>
     </div>
      {renderHeader(headerValue, testMode)}
      {/* <Divider style={styles.divider} /> */}
      {/* <hr style={styles.divider} /> */}
      {renderStats(bodyValues)}
      <Button 
        // hidden={isMobile} 
        type='link' 
        onClick={() => isMobile ? window.location.href = '/orders' : history.push(testMode ? '/test/orders/' : '/orders')} 
        style={{marginTop: 16}}
        className={`smallBoldStyle text-${segments}`}>View History</Button>
    </Card>
  )
}


export default OrderSalesCard;