import React from 'react';
import { Card, List, Table, Typography } from 'antd';
import HeaderCard from './userInfo/headerCard';
import AccountCard from './userInfo/accountCard';
import MultiwalletCard from './userInfo/multiwalletCard';
import MyCheckoutCard from './userInfo/myCheckoutCard';
import CredentialCard from './userInfo/credentialCard';
import BalanceCard from './userInfo/balanceCard';

const sampleData = [
    {
        title: 'Account Type',
        value: 'Master Wallet',
    },
    {
        title: 'Business Name',
        value: 'Suysing',
    },
    {
        title: 'KYC Level',
        value: 'Enterprise',
    },
    {
        title: 'Email',
        value: 'sample@sample.com',
    },
    {
        title: 'Mobile Number',
        value: '+639651588702',
    },
    {
        title: 'No. of Sub-wallet',
        value: '2,000 Sub-wallets',
    },
    {
        title: 'Signup Link',
        value: 'suysing.bux.ph/signup',
    },
]

const infoData =
    {
        name: 'Alvin Gernonimo',
        email: 'alvinsgeronimo@ubs.ph',
        mobile: '09123456789'
    }

const UserInfo = (props) => {

    const { data } = props;

    return (
        <div style={styles.root} bodyStyle={styles.cardBody}>
            {/* <div style={{padding: '16px 0'}}>
                <Typography style={styles.name}>Full Name</Typography>
                <Typography style={styles.balance}>Wallet: ₱{(1200).toLocaleString("en-US", { minimumFractionDigits: 2 })}</Typography>
            </div> */}
            <MultiwalletCard data={data} />
            {/* <List
                dataSource={sampleData}
                bordered
                renderItem={item => (
                    <List.Item>
                        <Typography>{item.title}</Typography>
                        <Typography>{item.value}</Typography>
                    </List.Item>
                )}
            /> */}
            <div style={styles.body}>
                <div style={styles.side}>
                    <AccountCard data={data} />
                    <MyCheckoutCard data={data} />
                </div>
                <div style={styles.side}>
                    <BalanceCard data={data} />
                    <CredentialCard data={data} />
                </div>
            </div>
        </div>
    )
}

const styles = {
    root: {
        width: '964px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: 20,
    },
    title: {
        color: '#0D3D76',
        fontWeight: '300',
        fontSize: 24,
    },
    cardBody: {
        // display: 'flex',
        // alignItems: 'center'
        width: '100%'
    },
    balance: {
        color: '#000',
        fontSize: 16,
        fontWeight: 'bold',
        padding: '4px 0'
    },
    body: {
        width: '100%',
        display: 'flex',
    },
    side: {
        width: '50%'
    }
}

export default UserInfo;