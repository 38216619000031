import React,{ useState } from 'react';
import { useSelector } from 'react-redux';
import { history } from '../../../store/history';
import { Modal, Card, Typography, Button, Divider } from 'antd';
import { useLocation } from 'react-router'
import { isMobile } from 'react-device-detect';
import {Basic, Approve, Exclamation, Clock} from '../../../static/icons/index';
import ErrorIcon from '@material-ui/icons/Error';

const BillsPaymentSuccess = (props) => {
  const location = useLocation();
  const paybillsId = location.state;
  const segments = localStorage.getItem('segments');

  const value = location.state ? location.state.val : '';

  const returnName = (key) => {
    switch (key) {
      case 'Electricity':
        return 'Electric Company';
      case 'Telecoms':
        return 'Telecom Compnay';
      case 'Cable':
        return 'Cable Provider';
      case 'Water':
        return 'Water Provider';
      case 'Credit Card':
        return 'Credit Card'; 
      case 'Schools':
        return 'School/University'; 
      case 'Insurance':
        return 'Insurance Company';
      case 'Others':
        return 'Selected Company';
      default:
        return 'Selected Company'
    }
  }

  if (!value) {
    history.goBack()
  }

  return (
    <div style={styles.root}>
      <Card size="small" style={styles.mainCard} bodyStyle={{padding: 20}}>
        <div style={styles.circle}>
          <img src={Approve} alt='No Icon' style={{height: 56}} />
        </div>
        <div style={styles.title}>
          <Typography style={styles.ty}>Thank You!</Typography>
          <Typography style={styles.success}>Your payment was successful</Typography>
        </div>
        <div style={{position: 'relative'}}>
          <div style={styles.leftCircle}></div>
          <Divider dashed style={{borderWidth: '2px 0 0 0'}} />
          <div style={styles.rightCircle}></div>
        </div>
        <div style={styles.contentContainer}>
          <Typography style={styles.subtitle}>Payment Details</Typography>
          <Card size="small" style={styles.detailsCard} bodyStyle={{padding: 0}}>
            <Typography style={styles.biller}>{value.key}</Typography>
            <div style={styles.detailsContent}>
              <Typography style={styles.textName}>{returnName(value.key)}</Typography>
              <Typography style={styles.textDesc}>{value.name}</Typography>
            </div>
            { value.field1 ? <div style={styles.detailsContent}>
                <Typography style={styles.textName}>{value.field1}</Typography>
                <Typography style={styles.textDesc}>{value.val1}</Typography>
              </div> 
            : null }
            { value.field2 ? <div style={styles.detailsContent}>
                <Typography style={styles.textName}>{value.field2}</Typography>
                <Typography style={styles.textDesc}>{value.val2}</Typography>
              </div> 
            : null }
            { value.field3 ? <div style={styles.detailsContent}>
                <Typography style={styles.textName}>{value.field3}</Typography>
                <Typography style={styles.textDesc}>{value.val3}</Typography>
              </div> 
            : null }
            <div style={styles.amount}>
              <Typography style={{color: '#2B2D33'}}>Amount</Typography>
              <Typography style={styles.textDesc}>
                { value.amount ? 
                'PHP ' + parseFloat(value.amount['amount']).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
                  : 'PHP ' + 0
                }
              </Typography>
            </div>
          </Card>
          { value.biller === 'MERALCO'
            ? <Typography style={styles.msg}>
              <ErrorIcon style={{color:'#F5922F', margin: '0 10px 0 0'}} />
                Meralco payment will be posted next 2-3 business days.
              </Typography> 
            : null}
          <button className={`btn--${segments}`} style={styles.btn} onClick={() =>  history.goBack()}>Done</button>
        </div>
      </Card>
    </div>
  )
}

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '100px 0px',
    backgroundColor: '#F4F6F9'
  },
  mainCard: {
    borderRadius: 10,
    width: isMobile ? '90%' : 540,
    // padding: 20,
    position: 'relative'
  },
  contentContainer: {
    // padding: '32px 0 0 0'
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0 0 0',
  },
  subtitle: {
    padding: '0 0 16px 0',
    color: '#2B2D33',
    fontSize: '16px'
  },
  ty: {
    fontWeight: 'bold', 
    color:'#2B2D33', 
    fontSize: 16
  },
  success: {
    color: '#909196', 
    fontSize: 16,
    fontWeight: '600'
  },
  detailsCard: {
    textAlign: 'left',
    margin: '0 0 22px 0'
  },
  detailsContent: {
    display: 'flex',
    padding: '7px 20px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  amount: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '7px 20px',
    borderTop: '.2px solid #E6EAF0'
  },
  biller: {
    backgroundColor: '#E6EAF0',
    padding: '7px 20px',
    fontSize: 16,
    color: '#2B2D33'
  },
  textName: {
    fontSize: 12,
    color: '#2B2D33'
  },
  textDesc: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2B2D33'
  },
  msg: {
    padding: '0 0 16px 0',
    textAlign: 'center',
    color: '#000000',
    fontSize: 16,
    display: 'flex',
    justifyContent: 'center',
  },
  btn: {
    height: 40,
    width: '100%',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
  },
  circle: {
    background: '#FFF',
    borderRadius: '50%',
    width: 70,
    height: 70,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '-2%',
    left: '49.5%',
    borderTop: '1px solid #DDD',
    position: 'absolute', 
    transform: 'translate(-50%, -50%)',
  },
  leftCircle: {
    background: 'rgb(244, 244, 244)',
    borderRadius: '50%',
    width: 33,
    height: 33,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '41%',
    left: isMobile ? '-7%' : '-4%',
    borderRight: '1px solid #DDD',
    position: 'absolute', 
    transform: 'translate(-50%, -50%)',
  },
  rightCircle: {
    background: 'rgb(244, 244, 244)',
    borderRadius: '50%',
    width: 33,
    height: 33,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '41%',
    right: isMobile ? '-18%' : '-11%',
    borderLeft: '1px solid #DDD',
    position: 'absolute', 
    transform: 'translate(-50%, -50%)',
  },
}

export default BillsPaymentSuccess;