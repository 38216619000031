import React,{useEffect} from 'react'
import {isMobile} from 'react-device-detect'
import Main from '../../layouts/Main'
import MobileMain from '../../layouts/Mobile/MobileMain'
import fundsStyle from '../../static/css/Funds'
import {Divider, Breadcrumb} from 'antd'
import {Grid} from '@material-ui/core'

const PayoutSchedule = props => {

    const f = fundsStyle();

    const[bank, setBank] = React.useState([])

    useEffect(() => {
        getBankAccount()
        
    }, [])

    async function getBankAccount(){
        let yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let url = process.env.REACT_APP_API_URL + "/api/bank_accounts/"

             
        let res = await fetch(url, yourConfig);

        if (res.ok) { // if HTTP-status is 200-299
        // get the response body (the method explained below)
        let json = await res.json();
        console.log(json)
        setBank(json)
        } else {
        alert("HTTP-Error: " + res.status);
        }

    }

    const schedule = [
        {
            dates: <b>Sat, Sun and Mon</b>,
            sched: <b>Wednesday</b>,
            time: <b>12:00 PM</b>
        },
        {
            dates: <b>Tuesday</b>,
            sched: <b>Thursday</b>,
            time: <b>12:00 PM</b>
        },
        {
            dates: <b>Wednesday</b>,
            sched: <b>Friday</b>,
            time: <b>12:00 PM</b>
        },
        {
            dates: <b>Thursday</b>,
            sched: <b>Monday</b>,
            time: <b>12:00 PM</b>
        },
        {
            dates: <b>Friday</b>,
            sched: <b>Tuesday</b>,
            time: <b>12:00 PM</b>
        }
    ]

    return(
        <div>
            {
                isMobile ?

                <MobileMain title={"Payout Schedule"}>
                    <div style={{padding: '30px 16px 20px 16px'}}>
                        <div style={{marginBottom: '20px'}}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <a href="/funds_enterprise" className={f.fundsDetail}>Funds</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item> 
                                <b><span style={{color: '#000000'}}>Payout Schedule</span></b>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        </div>

                        {/* <div className={f.credSchedule}>
                            <div className={f.twoCol}>
                                <div style={{display: 'flex'}}>
                                    <div style={{marginRight: 3}}>
                                        <div className={f.fundText}>
                                            Payout
                                        </div>
                                        <div className={f.fundBold}>
                                            May 25, 2020
                                        </div>
                                    </div>

                                    <Divider type="vertical" style={{height: '100%', width:'1px', color: ''}}/>

                                    <div style={{marginLeft: 3}}>
                                        <div className={f.fundText}>
                                            Crediting Schedule
                                        </div>
                                        <div className={f.fundBold}>
                                            May 28, 2020
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className={f.statusDiv}>
                                        In Progress
                                    </div>
                                </div>

                            </div>
                        </div> */}
                    
                        <div className={f.header}>Crediting Schedule</div>

                        <Grid container style={{padding: 16, background: '#D1D5DD', marginTop: 20, color: '#000000'}}>
                                <Grid item xs={4}>
                                    <div>Transaction<br/> Dates</div>
                                </Grid>
                                <Grid item xs={5}>
                                    <div>Crediting<br/> Schedule</div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div>Time</div>
                                </Grid>
                            </Grid>

                            {
                                schedule.map((item, i) => {
                                    return(

                                    <Grid key={i} container className={f.schedData}>
                                        <Grid item xs={4}>
                                            <div>{item.dates}</div>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <div>{item.sched}</div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div>{item.time}</div>
                                        </Grid>
                                    </Grid>
                                    )
                                })
                            }

                    </div>

                </MobileMain>

                :

                <Main>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{marginTop: '50px', width: '60%'}}>
                            <div>
                                <a href="/funds_enterprise" className={f.fundsDetail}>Funds</a> / <b><span style={{color: '#000000'}}>Payout Schedule</span></b>
                            </div>

                            {/* <div className={f.credSchedule}>
                                <div style={{padding: 20, borderBottom: '1px solid #D1D5DD'}}>
                                {
                                    bank.map((item) => {
                                        return(
                                            <div hidden={!item.is_primary}>
                                                <div className={f.fundBold}>{item.bank}</div>
                                                <div className={f.fundBold} style={{color: '#909196', marginTop: '4px'}}>{item.account_number}</div>
                                            </div>
                                            
                                        )
                                    })
                                }
                                </div>
                                <div className={f.twoCol}>
                                    <div style={{display: 'flex'}}>
                                        <div style={{marginRight: 20}}>
                                            <div className={f.fundText}>
                                                Payout
                                            </div>
                                            <div className={f.fundBold}>
                                                May 25, 2020
                                            </div>
                                        </div>

                                        <Divider type="vertical" style={{height: '100%', width:'1px', color: ''}}/>

                                        <div style={{marginLeft: 20}}>
                                            <div className={f.fundText}>
                                                Crediting Schedule
                                            </div>
                                            <div className={f.fundBold}>
                                                May 28, 2020
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className={f.statusDiv}>
                                            In Progress
                                        </div>
                                    </div>

                                </div>
                            </div>
 */}

                            <div className={f.header}>Crediting Schedule</div>

                            <Grid container style={{padding: 16, background: '#D1D5DD', marginTop: 20, color: '#000000'}}>
                                <Grid item lg={4}>
                                    <div>Transaction dates</div>
                                </Grid>
                                <Grid item lg={4}>
                                    <div>Crediting Schedule</div>
                                </Grid>
                                <Grid item lg={4}>
                                    <div>Time</div>
                                </Grid>
                            </Grid>

                            {
                                schedule.map((item, i) => {
                                    return(

                                    <Grid key={i} container className={f.schedData}>
                                        <Grid item lg={4}>
                                            <div>{item.dates}</div>
                                        </Grid>
                                        <Grid item lg={4}>
                                            <div>{item.sched}</div>
                                        </Grid>
                                        <Grid item lg={4}>
                                            <div>{item.time}</div>
                                        </Grid>
                                    </Grid>
                                    )
                                })
                            }

                        </div>
                    </div>
                </Main>
            }
        </div>
    )
}

export default PayoutSchedule