import { useState, useEffect } from "react";

const defaultQueries = {
  mobile: "(max-width: 768px)",
  tablet: "(min-width: 769px) and (max-width: 1080px)",
  desktop: "(min-width: 1081px)",
};

const useCustomMediaQuery = (queries = defaultQueries) => {
  const getMatches = () => {
    return Object.keys(queries).reduce((acc, key) => {
      acc[key] = window.matchMedia(queries[key]).matches;
      return acc;
    }, {});
  };

  const [matches, setMatches] = useState(getMatches);

  useEffect(() => {
    const handleChange = () => setMatches(getMatches);

    const mediaQueryLists = Object.values(queries).map((query) =>
      window.matchMedia(query)
    );

    mediaQueryLists.forEach((mql) =>
      mql.addEventListener("change", handleChange)
    );
    return () =>
      mediaQueryLists.forEach((mql) =>
        mql.removeEventListener("change", handleChange)
      );
  }, [queries]);

  return matches;
};

export default useCustomMediaQuery;
