export const headerStyle={
    fontWeight: 'Bold',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-1%',
    color: '#000000'
}
export const titleStyle = {
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    color: '#000000'
}
export const StepStyle = {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000'
}
export const textStyle = {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#000000'
}

export const PermaStyle = {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    wordWrap: 'break-word'
}

export const ApprovedStyle = {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1DD28B',
    wordWrap: 'break-word'
}

export const PendingStyle = {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'orange',
    wordWrap: 'break-word'
}

export const RejectedStyle = {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#E24C4C',
    wordWrap: 'break-word'
}

export const PresentStyle = {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#54575F',
}

export const submitBtnStyle = {
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#fafafa',
    backgroundColor: '#1D82B8',
    borderRadius: '4px',
    height: '42px',
    width: '100%'
}

export const tipsBtnStyle = {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '12ox',
    lineHeight: '16px',
    color: '#2B2D33',
    height: '32px',
    borderRadius: '33px',
    border: '1px solid #e6eaf0',
    alignItems: 'center'
}

export const statusStyle = {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#F5922F',
}

export const takeSelfieStyle = {
    border: '1px solid #1d82b8',
    borderRadius: '4px',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#1d82b8',
    height: '32px',
    width: '100%',
    marginTop: '20px'
}

export const textStyle2 = {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#54575F',
    textAlign: 'center',
    marginTop: '10px'
}

export const editStyle = {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#1D82B8',
}

export const cancelBtnStyle =  {
        border: '2px solid #d1d5dd',
        backgroundColor: '#fff',
        color: '#2B2D33',
        fontWeight: '600',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        borderRadius: '4px',
        height: '42px',
        width: '100%'

    }