import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducer";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialStates) {
  return createStore(
    rootReducer,
    initialStates,
    composeEnhancers(applyMiddleware(thunk))
  );
}