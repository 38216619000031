import React from 'react'
import {Card, Button} from 'antd'
import {isMobile} from 'react-device-detect'
import * as Style from './kyc_style'
import {submitDetails, setStep} from './application_main'
import {ADDRESS} from '../../constants/constants'

const EmployeeInfo = props => {

    const segments = localStorage.getItem("segments")
    const color = segments == 'me_' ? '#0DAED0' : segments == 'gig' ? '#41BD5C' : '#F5922F'
    const [selected, setSelected] = React.useState("")

    const [employmentStatus, setEmploymentStatus] = React.useState(props.details.employment_status)
    const [employerName, setEmployerName] = React.useState(props.details.employer_name)
    const [employerNatureBusiness, setEmployerNatureBusiness] = React.useState(props.details.employer_industry)
    const [sourceFunds, setSourceFunds] = React.useState(props.details.source_of_funds)

    const [employerStreet, setEmployerStreet] = React.useState(props.details.employer_address.street)
    const [employerProvince, setEmployerProvince] = React.useState(props.details.employer_address.province)
    const [employerCity, setEmployerCity] = React.useState(props.details.employer_address.city)
    const [employerBarangay, setEmployerBarangay] = React.useState(props.details.employer_address.barangay)
    const [employerZip, setEmployerZip] = React.useState(props.details.employer_address.postalCode == 0 ? "" : props.details.employer_address.postalCode)

    const [loading, setLoading] = React.useState(false)
    const [hover, setHover] = React.useState(false)

    const [currentField, setCurrentField] = React.useState(0)

    const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;

    function handleProvince(e) {
        setEmployerProvince(e.target.value)
        setEmployerCity("")
        setEmployerBarangay("")
    }

    function handleCity(e) {
        setEmployerCity(e.target.value)
        setEmployerBarangay("")
    }
    function handleBarangay(e) {
        setEmployerBarangay(e.target.value)
    }
    function handleEmploymentStatus(e){
        if(e.target.value.includes("UE")){
            setEmploymentStatus(e.target.value)
            setEmployerNatureBusiness("OTH")
            setEmployerName("")
            setSourceFunds("001")
            setEmployerZip("")
            setEmployerBarangay("")
            setEmployerCity("")
            setEmployerProvince("")
            setEmployerStreet("")
        }
        else if(e.target.value.includes("SE")){
            setEmploymentStatus(e.target.value)
            setEmployerName("")
            setEmployerZip("")
            setEmployerBarangay("")
            setEmployerCity("")
            setEmployerProvince("")
            setEmployerStreet("")
        }
        else{
            setEmploymentStatus(e.target.value)
        }
        
    }
    function getProvinces() {
        let provinces = []
        for(var i in ADDRESS){
            provinces.push(<option key={i} value={i}>{i}</option>);
        }
        return provinces
    }
    function getCities(type) {
        let cities = []
        if(type in ADDRESS){
            for(var i in ADDRESS[type]['Municipality_list']){
                cities.push(<option key={i} value={i}>{i}</option>);
            }
        }
        return cities
    }
    function getBarangay(typeCity,typeProvince) {
        let baranggays = []
        if(typeProvince in ADDRESS){
            if(typeCity in ADDRESS[typeProvince]['Municipality_list']){
                let baranggay_list = ADDRESS[typeProvince]['Municipality_list'][typeCity]['Barangay_list'];
                for(var i=0;i<baranggay_list.length;i++){
                    baranggays.push(<option key={i} value={baranggay_list[i]}>{baranggay_list[i]}</option>);
                }
            }
        }
        return baranggays
    }
    const employmentStatuses = () => {
        return(
            Object.keys(EmploymentStatus).map((key, i) => (
                <option key={i} value={key}>{EmploymentStatus[key]}</option>
            ))
        )
    }
    const natures = () => {
        return(
            Object.keys(NatureOfWork).map((key, i) => (
                <option key={i} value={key}>{NatureOfWork[key]}</option>
            ))
        )
    }
    const sources = () => {
        if(employmentStatus.includes("UE") || employmentStatus.includes("SE")){
            return(
                Object.keys(SourceFunds2).map((key, i) => (
                    <option key={i} value={key}>{SourceFunds2[key]}</option>
                ))
            )
        }
        else{
            return(
                Object.keys(SourceFunds).map((key, i) => (
                    <option key={i} value={key}>{SourceFunds[key]}</option>
                ))
            )
        }
        
    }

    const EmploymentStatus = {
        'EMP': 'Employed',
        'CON': 'Consultant',
        'SEB': 'Self-employed (Business)',
        'SEF': 'Self-employed (Freelancer)',
        'UEH': 'Unemployed',
        'UER': 'Unemployed (Retired)',
        'UES': 'Unemployed (Student)'
    }

    const NatureOfWork = {
        'ADM': 'Administrative/Human Resources',
        'AGRI': 'Agriculture',
        'BNK': 'Banking Institutions',
        'IT': 'Computer and Information Technology Services',
        'CONS': 'Construction/Contractors',
        'AGN': 'Consultancy/Agencies',
        'EDUC': 'Education',
        'ENG': 'Engineering',
        'ENT': 'Entertainment',
        'FIN': 'Financial Services',
        'FOV': 'Government',
        'HRS': 'Hotel and Restaurant Services',
        'HSE': 'Household Employees',
        'INV': 'Manufacturing and Inventory',
        'MED': 'Medical and Health Services',
        'SOC': 'Community, Social, Personal Services',
        'OTH': 'Others',
        'PR': 'Public Relations',
        'EST': 'Real Estate',
        'RENT': 'Rental Services',
        'MKTG': 'Sales/Marketing/Advertising',
        'SCI': 'Science and Technology Services',
        'STD': 'Student',
        'TCS': 'Transportation and Communication Services'
    }


    const SourceFunds  = 
    ['EMP', 'CON'].includes(employmentStatus) ?
    {
        '001': 'Allowance',
        '002':'Business Profit',
        '003':'Commissions',
        '004':'Inheritance',
        '005':'Loan',
        '006':'Salary',
        '007':'Pension',
        '008':'Remittance'
    } :
    {
        '001': 'Allowance',
        '002':'Business Profit',
        '003':'Commissions',
        '004':'Inheritance',
        '005':'Loan',
        '007':'Pension',
        '008':'Remittance'
    }

    const SourceFunds2  = {
        '001': 'Allowance',
        '002':'Business Profit',
        '003':'Commissions',
        '004':'Inheritance',
        '005':'Loan',
        '007':'Pension',
        '008':'Remittance'
    }

    async function checkDetails(){
        setLoading(true)
        let details = {
            employment_status: employmentStatus,
            employer_name: employerName,
            employer_industry: employerNatureBusiness,
            source_of_funds: sourceFunds,
            employer_street: employerStreet,
            employer_barangay: employerBarangay,
            employer_city: employerCity,
            employer_province: employerProvince,
            employer_postcode: employerZip,
            step: 2
        }

        if(props.testMode) {
            props.fakeKycDetails(3)
            window.scrollTo(0, 0);
            setLoading(false)
        }

        else {
            let response = await submitDetails(details)
            if(response){
                setLoading(false)
            }
        }

       
    }

    function disabledButton(){
        if(employmentStatus == "EMP" || employmentStatus == "CON"){
            if(employerStreet == "" || employerProvince == "" || employerCity == "" || employerBarangay == "" || employerZip == "" || employerName == "" || employerZip.length < 4){
                return true
            }
        }
        else{
            return false
        }
    } 

    const customizeInput = (index, code, inputValue, inputText, setValue, dataTest) =>{
        return(
            <input
                data-test={dataTest}
                autoComplete="new-password"
                style={{...Style.inputStyle, 
                    border: selected === code ? `1px solid ${color}` : (inputValue == "" || !inputValue) && currentField > index ? '1px solid #E24C4C' : '1px solid #D1D5DD',
                    padding: '10px'}} 
                placeholder={inputText}
                value={inputValue}
                onChange={(e)=>setValue(e.target.value.replace(/[^\w\s]/gi, ''))}
                onFocus={()=>{setCurrentField(index); setSelected(code)}}
                onMouseLeave={()=>setSelected("")}/>
        )
    }
    const customizeSelect = (index, code, selectValue, setValue, option, selectText, dataTest) => {
        return(
            <select
                data-test={dataTest}
                onFocus={()=>{setSelected(code); setCurrentField(index)}}
                onChange={(e)=>{if(index == 1 || index == 6 || index == 7 || index == 8){setValue(e)}else{setValue(e.target.value)}}}
                onMouseLeave={()=>setSelected("")}
                value={selectValue}
                style={{...Style.selectStyle, 
                    border: selected === code ? `1px solid ${color}` : selectValue == "" && currentField > index ? '1px solid #E24C4C' : '1px solid #D1D5DD',
                    padding: '10px 40px 10px 10px'
                    }}>
                    {
                       <option value="">Select {selectText}</option>
                    }
                    {option}
            </select>
        )
    }
    return(
        <div>
            <Card
                style={Style.formCardStyle}
                bodyStyle={{padding: isMobile? '12px 16px' : '32px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        {/* <div style={{...Style.normalText, color: '#000'}}>Step 3 of 5</div> */}
                        <div hidden={isMobile} style={{...Style.headerStyle, fontSize: isMobile ? '16px' : '20px'}}>Employment Information</div>
                    </div>
                    <div hidden={props.details.step != 6 && props.details.step != 9}>
                    {
                         isMobile ? 
                         <div style={{...Style.boldText, color: '#F5922F'}} onClick={()=>setStep(6)}>Cancel</div>
                         :
                         <button 
                            onClick={()=>setStep(6)}                            
                            className={`outline-btn--${segments} btn-height`}> Cancel</button>
                    }
                    </div>
                </div>
                <div style={{display: isMobile ? 'block' : 'flex', marginTop: isHighReso? '14px' : '20px'}}>
                    <div style={{width: isMobile || employmentStatus.includes("UE") || employmentStatus.includes("SE") ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Employment Status</div>
                        {customizeSelect(1,"emp_status", employmentStatus, handleEmploymentStatus, employmentStatuses(), "", "input-emp-status")}
                        <div style={{display: employmentStatus == '' && currentField > 1 ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}} hidden={employmentStatus.includes("UE") || employmentStatus.includes("SE")}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Employer Name</div>
                        {customizeInput(2,"emp_name", employerName, "Employer Name", setEmployerName, "input-emp-name")}
                        <div style={{display: (employerName == "" || !employerName) && currentField > 2 && ['EMP', 'CON'].includes(employmentStatus) ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                </div>

                <div style={{display: isMobile ? 'block' : 'flex', marginTop: isHighReso? '14px' : '20px', marginBottom: employmentStatus.includes("UE") || employmentStatus.includes("SE") ? '20px' : '0px'}}>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}} hidden={employmentStatus.includes("UE") || employmentStatus.includes("SE")}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Employer Nature of Business</div>
                        {customizeSelect(3,"nature",employerNatureBusiness,setEmployerNatureBusiness,natures(), "", "input-emp-nob")}
                        <div style={{display: employerNatureBusiness == "" && currentField > 3 ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                    <div style={{width: isMobile || (employmentStatus.includes("UE") || employmentStatus.includes("SE")) ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Souce of Funds</div>
                        {customizeSelect(4,"source",sourceFunds,setSourceFunds,sources(), "", "input-emp-sof")}
                        <div style={{display: sourceFunds == "" && currentField > 4 ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                    </div>
                </div>
                                
                <div hidden={employmentStatus.includes("UE") || employmentStatus.includes("SE")}>
                    <div style={{margin: isHighReso?  '20px 0 10px 0' : '30px 0px 10px 0px'}}>
                        <div style={{...Style.boldText, color: '#000'}}>
                            Employer Address
                        </div>
                    </div>
                    <div style={{display: isMobile ? 'block' : 'flex', marginTop: isHighReso? '14px' : '20px'}}>
                        <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                            <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Street</div>
                            {customizeInput(5,"street",employerStreet,"Street",setEmployerStreet, "input-emp-st")}
                            <div style={{display: employerStreet == "" && currentField > 5 && ['EMP', 'CON'].includes(employmentStatus) ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                        </div>
                        <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                            <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Province</div>
                            {customizeSelect(6,"province",employerProvince,handleProvince,getProvinces(),"Province", "input-emp-province")}
                            <div style={{display: employerProvince == "" && currentField > 6 && ['EMP', 'CON'].includes(employmentStatus) ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                        </div>
                    </div>
                    <div style={{display: isMobile ? 'block' : 'flex', marginTop: isHighReso? '14px' : '20px'}}>
                        <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                            <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>City</div>
                            {customizeSelect(7,"city",employerCity,handleCity,getCities(employerProvince),"City", "input-emp-city")}
                            <div style={{display: employerCity == "" && currentField > 7 && ['EMP', 'CON'].includes(employmentStatus) ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                        </div>
                        <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                            <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Barangay</div>
                            {customizeSelect(8,"barangay",employerBarangay,handleBarangay,getBarangay(employerCity,employerProvince),"Barangay", "input-emp-brgy")}
                            <div style={{display: employerBarangay == "" && currentField > 8 && ['EMP', 'CON'].includes(employmentStatus) ? 'block' : 'none', ...Style.requiredStyle}}>Required Field</div>
                        </div>
                    </div>
                    <div style={{display: isMobile ? 'block' : 'flex', marginTop: isHighReso? '14px' : '20px', marginBottom: '20px'}}>
                        <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                            <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Country</div>
                            <select
                                onFocus={()=>setSelected("country")}
                                onMouseLeave={()=>setSelected("")}
                                value="PH"
                                disabled={true}
                                style={{...Style.selectStyle, 
                                    border: selected === "country" ? '1px solid #F5922F' : '1px solid #D1D5DD',
                                    padding: '10px 40px 10px 10px', backgroundColor: "#f4f4f4"
                                    }}>
                                <option>PH</option>
                            </select>
                        </div>
                        <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                            <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>Zipcode (must be 4 digits)</div>
                            <input 
                                data-test="input-emp-zip"
                                autoComplete="new-password"
                                style={{...Style.inputStyle, 
                                    border: selected === "zip" ? '1px solid #F5922F' : '1px solid #D1D5DD',
                                    padding: '10px'}} 
                                placeholder="Zipcode"
                                type="number"
                                value={employerZip}
                                onChange={e => { if(e.target.value.length <= 4){setEmployerZip(e.target.value)}}}
                                onSelect={()=>{setSelected("zip");setCurrentField(9)}}
                                onMouseLeave={()=>setSelected("")}/>
                        </div>
                    </div>
                </div>

                <div style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}} hidden={isMobile}>
                    <div style={{...Style.backToDashboard, margin: '0px'}}> <a className={`text-${segments}`} onClick={()=>setStep(1)}>Back</a></div>
                    <Button 
                        data-test="button-submit"
                        loading={loading}
                        style={{...Style.submitBtnStyle,
                        opacity: disabledButton() ? 0.5 : 1.0
                    }} 
                        onClick={()=>checkDetails()} 
                        hidden={isMobile}
                        disabled={disabledButton()}
                    >
                        <div style={{...Style.boldText, display: 'inline-block', marginLeft: loading ? '20px' : '0px'}}>{props.details.step == 2 ? 'Submit and next' : 'Save'}</div>
                    </Button>
                </div>

            </Card>
            <div style={{
                width: '100%', 
                position: 'fixed', 
                display: 'flex',
                backgroundColor:'#fff', 
                height: '74px', 
                bottom: '0', 
                marginLeft: '-16px',
                padding:'10px 20px 10px 20px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }} hidden={!isMobile}>
                <button onClick={()=>setStep(1)} style={Style.backBtnMobile} className={`outline-btn--${segments}`}>
                    Back
                </button>
                <Button 
                    loading={loading}
                    style={{...Style.submitBtnStyle,opacity: disabledButton() ? 0.5 : 1.0}}
                    disabled={disabledButton()} 
                    onClick={()=>checkDetails()}>
                    <div style={{...Style.boldText, display: 'inline-block', marginLeft: loading ? '20px' : '0px'}}>{props.details.step == 2 ? 'Submit and next' : 'Save'}</div>
                </Button>
            </div>
        </div>
    )
}

export default EmployeeInfo