import axios from 'axios';
import {Handle401} from '../../../handle401/handle401'

const authorizedAxiosRequest = (value) => {
    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Authorization: "Token " + localStorage.getItem("jwtToken")
      }
    });
}

const allowAnyAxiosRequest = (value) => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
}

const qrServices = {

  getQrDetails: async (isTestMode, tradeName, masterTradeName) => {
    let endpoint = isTestMode ? '/api/sandbox/qr_details/' : '/api/qr_details/'
    let url = endpoint+`?trade_name=${tradeName}`;
    if (masterTradeName) {
      url = url + `&master_trade_name=${masterTradeName}`;
    }

    try {
      let response;

      if (tradeName) {
        response = await allowAnyAxiosRequest().get(tradeName ? url : endpoint)
      }

      else {
        response = await authorizedAxiosRequest().get(tradeName ? url : endpoint)
      }

      return response
    }

    catch (error) {
      if((error.response)&&(error.response.status === 400 || error.response.status == 401)){
        return error.response
      }
    }
  },

  updateQrDetails: async (isTestMode, params) => {
    let endpoint = isTestMode ? '/api/sandbox/update_qr_details/' : '/api/update_qr_details/'

    try {
      const response = await authorizedAxiosRequest().post(endpoint, params)
      console.log(response)
      return response
    }

    catch (error) {
      if((error.response)&&(error.response.status === 400 || error.response.status === 401)){
        return error.response
      }
    }
  },

  setupTradeName: async (isTesMode, params) => {
    let endpoint = isTesMode ? "/api/sandbox/setup_qr/" : "/api/setup_qr/"

    try {
      
      const response = await authorizedAxiosRequest().post(endpoint, params)
      console.log(response)
      return response
    }

    catch (error) {
      if((error.response)&&(error.response.status === 400 || error.response.status === 401)){
        return error.response
      }
    }
  },

  changeTradeName: async (isTestMode, params) => {
    let endpoint = isTestMode ? '/api/sandbox/change_qr_trade_name/' : '/api/change_qr_trade_name/'

    try {
      const response = await authorizedAxiosRequest().post(endpoint, params)
      console.log(response)
      return response
    }

    catch (error) {
      if((error.response)&&(error.response.status === 400 || error.response.status === 401)){
        return error.response
      }
    }
  },

  checkoutQR: async (isTestMode, params) => {
    let endpoint = isTestMode ? `/api/sandbox/qr_generate/` : `/api/orders/qr_generate/`

    try {
      const response = await allowAnyAxiosRequest().post(endpoint, params)
      console.log(response)
      return response
    }

    catch (error) {
      if((error.response)&&(error.response.status === 400 || error.response.status === 401)){
        return error.response
      }
    }
  },

  checkoutQRV2: async (isTestMode, params) => {
    let endpoint = isTestMode ? `/api/sandbox/qr_generate/` : `/api/orders/qr_generate_v2/`

    try {
      const response = await allowAnyAxiosRequest().post(endpoint, params)
      console.log(response)
      return response
    }

    catch (error) {
      if((error.response)&&(error.response.status === 400 || error.response.status === 401)){
        return error.response
      }
    }
  }
}

export default qrServices