import React from 'react';
import { Table, Pagination, Dropdown, Icon, Menu, message, Typography } from 'antd';
import { history } from '../../../../../../store/history';
import TransferMoneyModal from '../modal/transfer_money_modal';
import TransferSuccessModal from '../modal/success_transfer_modal';
import multiwalletServices from '../../../../multiwalletServices';
import OtpModal from '../../../otp_modal';
import RequestPaymentModal from '../modal/request_payment_modal';
import { useSelector } from 'react-redux';
import DeactivateSubwalletModal from '../modal/deactivate_subwallet_modal';
import AuthorizedPersonModal from '../modal/authorized_person_modal';

const MultiwalletTable = (props) => {
    const { segments, data, isLoading, pageSize, onChangePage, onTransferSuccess, buxBalance, onUpdateAuthorizedPerson, onDeactivateSubwallet } = props;
    const [loading, setLoading] = React.useState(false);
    const [transferModalShow, setTransferModalShow] = React.useState(false);
    const [details, setDetails] = React.useState(null);
    const [transferSuccesShow, setTransferSuccesShow] = React.useState(false);
    const [amountValue, setAmountValue] = React.useState(0);
    const [otpModalShow, setOtpModalShow] = React.useState(false);
    const [otpData, setOtpData] = React.useState(null);
    const [resetTimer, setResetTimer] = React.useState(false);
    const [tranxMoneyParams, setTranxMoneyParams] = React.useState(null);
    const [isEmailOtp, setIsEmailOtp] = React.useState(false);
    const [requestModalShow, setRequestModalShow] = React.useState(false);
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const [notifShow, setNotifShow] = React.useState(false);
    const [notifMsg, setNotifMsg] = React.useState('');
    const [notifType, setNotifType] = React.useState('success');
    const endpoints = useSelector(state => state.child_endpoints.endpoints);


    const definedWalletSettings = JSON.parse(localStorage.getItem('defined_wallet_settings'));
    const {
        is_maker_approver_enabled: isMakerApproverEnabled = false,
        wallet_transfer_id_enabled: walletTransferIdEnabled = false,
    } = definedWalletSettings;

    const isChild = localStorage.getItem("is_child") === 'true';
    const isMaker = !isChild || endpoints.includes('maker_wallet_transfer');
    const [deactivateSubwallet, setDeactivateSubwallet] = React.useState(null)
    const [deactivateSubwalletModal, setDeactivateSubwalletModal] = React.useState(false);
    const [authorizedPerson, setAuthorizedPerson] = React.useState(null);
    const [authorizedPersonModal, setAuthorizedPersonModal] = React.useState(false);

    const returnKycLevel = (level) => {
        switch (level) {
            case 0:
                return 'Non-KYC';
            case 1:
                return 'Approved KYC';
            case 2:
                return 'Advanced';
            case 3:
                return 'Business';
            default:
                return 'Basic';
        }
    }

    const columns = [
        {
            title: 'Sub-wallet ID',
            dataIndex: 'sub_id',
            render: (text) => text && text !== ' ' ? `SW${text}` : 'N/A'
        },
        {
            title: 'Business Name',
            dataIndex: 'business_name',
            render: (text) => text && text !== ' ' ? text : 'N/A'
        },
        {
            title: 'Name',
            dataIndex: 'full_name',
            render: (text) => text && text !== ' ' ? text : 'N/A'
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            render: (text) => text ? text : 'N/A'
        },
        {
            title: 'Mobile Number',
            dataIndex: 'contact',
            render: (text) => text ? text : 'N/A'
        },
        {
            title: 'KYC Level',
            dataIndex: 'eon_level',
            render: (record) => returnKycLevel(record && record)
        },
        {
            title: 'Wallet Balance',
            dataIndex: 'balance',
            render: (record) => <Typography style={{ fontSize: 14, fontWeight: 'bold', color: '#2B2D32' }}>₱ {record.toLocaleString("en-US", { minimumFractionDigits: 2 })}</Typography>
        },
        {
            title: 'Action',
            render: (record) => (
                <Dropdown overlay={() => menu(record)} placement="bottomCenter" trigger={['click']}>
                    <Icon type="ellipsis" />
                </Dropdown>
            )
        },
    ]
    const onKYCViewClick = (record) => {
        history.push({ pathname: testMode ? `/test/subwallet/kyc/${record.sub_id}` : `/subwallet/kyc/${record.sub_id}`, state: { subWalletDetails: record } });
    }

    const onUserHistoryClick = (record) => {
        history.push({ pathname: testMode ? `/test/user_history/subwallet/${record.sub_id}` : `/user_history/subwallet/${record.sub_id}`, state: { subWalletDetails: record } });
    }

    const onDeactivateSubwalletClick = (record) => {
        setDeactivateSubwallet(record);
        setDeactivateSubwalletModal(true);
    }

    const onDeactivateSubwalletCancel = () => {
        setDeactivateSubwallet(null);
        setDeactivateSubwalletModal(false);
    }

    const onDeactivateSubwalletConfirm = (record) => {
        onDeactivateSubwallet(record);
        setDeactivateSubwallet(null);
        setDeactivateSubwalletModal(false);
    }

    const onAuthorizedPersonClick = (record) => {
        setAuthorizedPerson(record);
        setAuthorizedPersonModal(true);
    }

    const onAuthorizedPersonCancel = () => {
        setAuthorizedPerson(null);
        setAuthorizedPersonModal(false);
    }

    const onAuthorizedPersonSubmit = (data) => {
        onUpdateAuthorizedPerson(data);
        setAuthorizedPerson(null);
        setAuthorizedPersonModal(false);
    }

    const menu = (record) => (
        <Menu>
            <Menu.Item key="0">
                <a onClick={() => onKYCViewClick(record)} >View KYC</a>
            </Menu.Item>
            <Menu.Item key="1">
                <a onClick={() => onUserHistoryClick(record)} >User History</a>
            </Menu.Item>
            <Menu.Item key="2" hidden={(record.eon_level === 0 && record.kyb_required && !testMode) || !record.transfer_money_enabled || (isMakerApproverEnabled && !isMaker)}>
                <a onClick={() => setTransferModalShow(true) / setDetails(record)} >Transfer Money</a>
            </Menu.Item>
            <Menu.Item key="3" hidden={(record.eon_level === 0 && record.kyb_required && !testMode) || !record.transfer_money_enabled}>
                <a onClick={() => setRequestModalShow(true) / setDetails(record)} >Request Payment</a>
            </Menu.Item>
            <Menu.Item key="4" hidden={!['CMP', 'BRN'].includes(record.subwallet_type)}>
                <a onClick={() => onAuthorizedPersonClick(record)}>Edit Authorized Person</a>
            </Menu.Item>
            <Menu.Item key="5">
                <a onClick={() => onDeactivateSubwalletClick(record)}>Deactivate Sub-wallet</a>
            </Menu.Item>
        </Menu>
    )

    const toastAlert = (type, str) => {
        setNotifType(type);
        setNotifMsg(str)
        setNotifShow(true);
        setTimeout(() => {
            setNotifShow(false);
        }, 3000);
    };

    const sendRequestPayment = async (params) => {
        setLoading(true);
        try {
            const res = await multiwalletServices.sendRequestPayment(testMode, params);
            if (res.status === 'success') {
                setRequestModalShow(false);
                toastAlert('success', 'Payment request sent successfully.')
            } else if (res.status === 'failed') {
                toastAlert('error', res.message);
            }
            setLoading(false);
        } catch (error) {
            console.log(error)
            toastAlert('error', 'Payment request error.')
            setLoading(false);
        }
    }

    const fetchOTP = async () => {
        setLoading(true);
        try {
            const res = await multiwalletServices.getOTP(testMode);
            if (res.status === 'success') {
                setOtpData(res);
                setTransferModalShow(false);
                setOtpModalShow(true);
            } else if (res.status === 'failed') {
                message.error(res.message);
            }
            setLoading(false);
        } catch (error) {
            console.log(error)
            setLoading(false);
        }
    }

    const handleEmailOtp = async () => {
        setLoading(true);
        try {
            const response = await multiwalletServices.getOtpEmail('transfer_money');
            if (response.status === 'success') {
                setOtpData('');
                setIsEmailOtp(true);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const onNext = (params) => {
        setTranxMoneyParams(params);
        if (testMode) {
            transferMoney(params, "")
        }
        else if (!testMode) {
            fetchOTP();
        }
    }

    const changePage = (page, pageSize) => {
        onChangePage(page);
    }

    const generatePayload = (transferId) => {
        return {
          field1: {
            name: "Transfer ID",
            value: transferId,
          },
        };
      };

    const transferMoney = async (params, transfer_money_uid) => {
        setLoading(true);
        let newDetails = details;
        try {
            const transferRequestData = {...params, transfer_money_uid};
            const transferRequestDataWithPayload = {...params, transfer_money_uid, payload: generatePayload(params.transfer_id)};

            const res = !isMakerApproverEnabled ? await multiwalletServices.transferMoney(testMode, params, transfer_money_uid)
            : await multiwalletServices.transferMoneyRequest(walletTransferIdEnabled ? transferRequestDataWithPayload:transferRequestData);

            if (res.status === 'success') {
            
                setTransferModalShow(false);
                setTransferSuccesShow(true);
                newDetails['transfer_id'] = res.transfer_id;
                setAmountValue(parseFloat(params['amount']));
                    
                setDetails(newDetails);
                onTransferSuccess();

            } else {
                message.error(res.message);
            }
        } catch (error) {
            message.error('Please try again later.');
        }
        setLoading(false);
    }

    return (
        <div>
            <Typography style={{
                color: '#FFF',
                fontSize: 16,
                fontWeight: '600',
                padding: '12px 24px',
                zIndex: '999',
                borderRadius: 4,
                position: 'fixed',
                top: '6vh',
                left: '45vw',
                backgroundColor: notifType === 'error' ? '#E24C4C' : '#1DD28B'
            }} hidden={!notifShow}>{notifMsg}</Typography>
            <Table
                rowClassName="table-row-light"
                columns={columns}
                dataSource={data ? data.results : []}
                loading={isLoading || loading}
                pagination={false}
            />
            <Pagination
                size='small'
                total={data && data['total']}
                defaultPageSize={pageSize}
                pageSize={pageSize}
                defaultCurrent={1}
                current={data && data['page']}
                onChange={(page, pageSize) => changePage(page, pageSize)}
                style={{ padding: '12px 0 8px 0', textAlign: 'right' }}
            />
            <TransferMoneyModal segments={segments} visible={transferModalShow} onClose={() => setTransferModalShow(false)} buxBalance={buxBalance} details={details} onNext={onNext} isLoading={loading} />
            <TransferSuccessModal segments={segments} visible={transferSuccesShow} onClose={() => { setTransferSuccesShow(false) }} details={details} amount={amountValue} request={isMakerApproverEnabled} />
            <RequestPaymentModal segments={segments} visible={requestModalShow} onClose={() => setRequestModalShow(false)} details={details} onSend={sendRequestPayment} isLoading={loading} />
            <OtpModal segments={segments} visible={otpModalShow} resetTimer={resetTimer}
                setResetTimer={() => setResetTimer(false)} closeModal={() => {setOtpModalShow(false); setIsEmailOtp(false)}}
                otpData={otpData} onSuccess={(transfer_money_uid)=>transferMoney(tranxMoneyParams, transfer_money_uid)} onResend={fetchOTP} onEmailOtp={handleEmailOtp}
                isEmailOtp={isEmailOtp} />
            <DeactivateSubwalletModal 
                data={deactivateSubwallet} 
                visible={deactivateSubwalletModal} 
                onCancel={onDeactivateSubwalletCancel} 
                onConfirm={onDeactivateSubwalletConfirm}
            />
            <AuthorizedPersonModal 
                data={authorizedPerson}
                visible={authorizedPersonModal}
                onCancel={onAuthorizedPersonCancel} 
                onSubmit={onAuthorizedPersonSubmit}
            />
        </div>
    )
}

export default MultiwalletTable;