import React from 'react';
import { Button, Card, Divider, Icon, Input, Modal, Pagination, Typography } from 'antd';
import BannerImg from '../../../../static/img/promo_modal_banner.png';
import { Handle401 } from '../../../handle401/handle401';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import moment from 'moment';

const CheckoutPromoModal = (props) => {
    const [searchVal, setSearchVal] = React.useState('');
    const [selected, setSelected] = React.useState('')
    const { amount, trade_name, uid } = props;
    const segments = localStorage.getItem('segments');
    const [promoData, setPromoData] = React.useState([]);
    const [promoTableData, setPromoTableData] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(6);
    const [minPage, setMinPage] = React.useState(0);
    const [maxPage, setMaxPage] = React.useState(6);
    const [currentPage, setCurrentPage] = React.useState(1);

    async function fetchPromos (search='', page=1) {

      
        let yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }

        try{
            let endpoint = `/api/checkout/promo_codes/?uid=${uid}&page=${page}&search=${search}`
            if (!uid) {
                endpoint = `/api/checkout/promo_codes/?amount=${amount}&trade_name=${trade_name}&page=${page}&search=${search}`
            }
          let response = await axios.get(
            process.env.REACT_APP_API_URL + endpoint
          );
            if(response && response.data && response.data.results) {
                setPromoData(response.data.results);
                setPromoTableData(response.data)
            }
          console.log(response.data)
        }

        catch(error){
          if((error.response)&&(error.response.status == 401)){
            Handle401()
          }
        }
  
        
    }

    // const fetchPromos = () async => {
    //     try {
            
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    const samplePromo = [
        {
            title: 'SAMPLEPROMO',
            code: 'SAMPLE',
            minAmount: 100,
            expiry: 'Sat 23, 22',
            amount: 20,
            remaining: 100
        },
        {
            title: 'PROMOMOMO',
            code: 'PROM123',
            minAmount: 50,
            expiry: 'Sat 23, 22',
            amount: 5,
            remaining: 50
        },
        {
            title: 'YESPRROMO',
            code: 'YESYES',
            minAmount: 200,
            expiry: 'Monday 23, 22',
            amount: 10,
            remaining: 80
        },
    ]

    React.useEffect(() => {
        fetchPromos();
    },[props.visible])


//     <Card
//     style={{borderTopWidth: selected === i && '2px', borderRightWidth: selected === i && '2px', borderBottomWidth: selected === i && '2px', 
//         borderLeftColor: selected === i? '#41BD5C' : '#3A73CC', borderTopColor: selected === i && '#41BD5C', borderRightColor: selected === i && '#41BD5C',
//         borderBottomColor: selected === i && '#41BD5C', ...promoStyles.root,}}
//     bodyStyle={promoStyles.body}
//     hoverable={true}
//     onClick={() => setSelected(i)}
// >
//     <div>
//         <Typography style={{...promoStyles.title, color: selected === i? '#41BD5C': '#3A73CC'}}>{p.title}</Typography>
//         <Typography style={{...promoStyles.normal, fontWeight: 'bold'}}>{p.code}</Typography>
//         <Typography style={promoStyles.normal}><b>₱{p.minAmount}</b> min. spend</Typography>
//         <Typography style={promoStyles.expiry}>Valid until {p.expiry}</Typography>
//     </div>
//     <Divider type='vertical' dashed style={{height: '10vh', margin: '0 26px'}} />
//     <div>
//         <Typography style={{...promoStyles.discountOff, color: selected === i? '#41BD5C' : '#3A73CC', backgroundColor: selected === i? '#E4F7EC' : '#E5F2FD' }}>₱{p.amount} OFF</Typography>
//         <Typography style={promoStyles.expiry}>{p.remaining} left</Typography>
//     </div>
// </Card>

    const renderDiscount = (promo) => {
        const { discount_type, fixed_discount, percentage_discount } = promo;
        switch (discount_type) {
            case 'FI':
                return `₱ ${fixed_discount.toLocaleString()}`;
            case 'PE':
                return `${percentage_discount}%`;
            default:
                return `₱ ${fixed_discount.toLocaleString()}`;
        }
    }

    const renderPromos = (promos) => {
        return (
            promos && promos.map((p, i) => {
                return (
                    <Card
                        style={{borderTopWidth: selected === p.code && '2px', borderRightWidth: selected === p.code && '2px', borderBottomWidth: selected === p.code && '2px', 
                            borderLeftColor: selected === p.code? '#41BD5C' : '#3A73CC', borderTopColor: selected === p.code && '#41BD5C', borderRightColor: selected === p.code && '#41BD5C',
                            borderBottomColor: selected === p.code && '#41BD5C', ...promoStyles.root,}}
                        bodyStyle={promoStyles.body}
                        hoverable={true}
                        onClick={() => {setSelected(p.code)}}
                    >
                        <div>
                            <Typography style={{...promoStyles.title, color: selected === p.code? '#41BD5C': '#3A73CC'}}>{p.name || 'N/A'}</Typography>
                            <Typography style={{...promoStyles.normal, fontWeight: 'bold'}}>{p.code}</Typography>
                            <Typography style={promoStyles.normal}><b>₱{p.min_amount}</b> min. spend</Typography>
                            <Typography style={promoStyles.expiry}>Valid until {moment(p.date_expiry).format('lll')}</Typography>
                        </div>
                        <Divider type='vertical' dashed style={{height: '10vh', margin: '0 12px'}} />
                        <div>
                            <Typography style={{...promoStyles.discountOff, color: selected === p.code? '#41BD5C' : '#3A73CC', backgroundColor: selected === p.code? '#E4F7EC' : '#E5F2FD' }}>{renderDiscount(p)} OFF</Typography>
                            <Typography style={{...promoStyles.expiry, textAlign: 'center', paddingTop: '4px'}}>{(p.user_redemption - p.owner_redemption_count < 0) ? 0 : p.user_redemption - p.owner_redemption_count + '/' + p.user_redemption} left</Typography>
                        </div>
                    </Card>
                )
            })
        )
    }

    const promoStyles = {
        root: {
            padding: '12px',
            borderRadius: '8px',
            borderLeftWidth: '8px',
            height: '100%'
        },
        body: {
            display: 'flex',
            alignItems: 'center',
            padding: '0',
            height: '100%',
            justifyContent: 'space-between'
        },
        title: {
            fontWeight: 'bold',
            fontSize: '14px',
            color: '#3A73CC'
        },
        normal: {
            fontSize: '12px',
            color: '#2B2D32'
        },
        expiry: {
            fontSize: '10px',
            color: 'rgba(43, 45, 50, 0.64)'
        },
        discountOff: {
            borderRadius: '30px',
            backgroundColor: '#E5F2FD',
            padding: '2px 10px',
            color: '#3A73CC',
            fontWeight: 'bold'
        }
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value);
        fetchPromos(e.target.value)
    }

    const renderData = (search) => {
        let filteredData = [];

        if(search !== '' && search !== null) {
            filteredData =  promoData && promoData.filter((data) => data.code.toLowerCase().includes(search.toLowerCase()))
            return filteredData && filteredData;
        } 
        
        return promoData && promoData;
    }

    const changePage = (page) => {
        setMaxPage(page * pageSize);
        setMinPage((page - 1) * pageSize);
        setCurrentPage(page);
        fetchPromos(searchVal, page);
    }

    return (
        <Modal
            onCancel={() => props.onClose()}
            visible={props.visible}
            footer={false}
            closeIcon={false}
            destroyOnClose
            width={720}
            closable={false}
        >
            <img alt='New Discount' src={BannerImg} style={{width: '100%', filter: 'drop-shadow(rgba(0, 20, 25, 0.20)4px 14px 8px)', margin: '12px 0 32px 0' }} />
            <div style={styles.filterWrapper}>
                <label style={{color: 'rgba(43, 45, 50, 0.8)', fontSize: '14px'}}>Promo Code</label>
                <Input
                    placeholder="Enter Promo Code"
                    style={{ width: '100%', height: '40px', borderRadius: '4px', }}
                    // suffix={<Icon type="search" style={{ fontSize: '18px' }} />}
                    onChange={handleSearch}
                    value={searchVal}
                />
            </div>
            <Typography style={styles.select}>Select available vouchers</Typography>
            <div style={styles.cardWrapper}>
                {renderPromos(promoData || [])}
            </div>
           <div style={{textAlign: 'right'}}>
            <Pagination 
                    size="small" 
                    total={promoTableData ? promoTableData['total_data'] : 0}
                    // showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    onChange={(page, pageSize) => changePage(page, pageSize)}
                    defaultCurrent={1}
                    current={currentPage ? currentPage : 1}
                    defaultPageSize={6}
                    pageSize={pageSize}
                />
           </div>
            <div style={styles.footerDiv}>
                <Button size='large' style={styles.cancelBtn} onClick={() => props.onClose()}>Cancel</Button>
                <Button size='large' style={{...styles.applyBtn, opacity: !selected ? '.5' : '1'}} disabled={!selected} onClick={() => props.applyVoucher(selected)}>Apply Voucher</Button>
            </div>
        </Modal>
    )
}

const styles = {
    root: {

    },
    cardWrapper: {
        width: '100%',
        display: 'grid',
        gridGap: '12px',
        columnGap: '14px',
        gridTemplateColumns: isMobile? 'repeat(1, 1fr)' : 'repeat(2, 1fr)',
        margin: '12px 0',
    },
    filterWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        marginBottom: '12px'
    },
    select: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#2B2D32'
    },
    cancelBtn: {
        heigth: '40px',
        color: '#F5922F',
        border: '1px solid #F5922F',
        fontSize: '16px'
    },
    applyBtn: {
        heigth: '40px',
        color: '#FFF',
        backgroundColor: '#F5922F',
        border: '1px solid #F5922F',
        fontSize: '16px'
    },
    footerDiv: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '14px'
    },
}

export default CheckoutPromoModal;