import React, { useState, useEffect } from "react";
import { Typography, Table, Input, Icon, Pagination, DatePicker } from "antd";
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import { history } from '../../store/history';
import axios from 'axios';
import { Handle401 } from '../handle401/handle401';
import { render } from "react-dom";
import moment from 'moment';

const columns = [
  {
    title: 'Reference Code',
    dataIndex: 'id',
    width: '25%',
    render: (id) => (
      <strong>{id}</strong>
    )
  },
  {
    title: 'Transaction Type',
    dataIndex: 'name',
    width: '25%',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (name) => (
      <strong>{returnType(name)}</strong>
    )
  },
  {
    title: 'Date',
    dataIndex: 'date_str',
    width: '30%'
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    width: '20%',
    render: (amount, record) => (
      <strong>{record.type === 'C' ? 'PHP ' + amount : 'PHP -' + amount}</strong>
    )
  },
  // {
  //   title: 'Action',
  //   key: 'action',
  //   width: '10%',
  //   render: () => (
  //     <a style={styles.tableAction}>...</a>
  //   )
  // },
]

const sampleTransactions = [
  {
    amount: 100,
    date_str: "May 26, 2020",
    desc: "Payment",
    name: "test",
    type: "C"
  },
  {
    amount: 200,
    date_str: "Jan 26, 2020",
    desc: "Transfer",
    name: "test",
    type: "C"
  },
  {
    amount: 2000,
    date_str: "June 26, 2020",
    desc: "Widraw",
    name: "test",
    type: "FA"
  },
]

const returnType = (type) => {
  switch (type) {
    case 'Ibft Realtime':
      return 'Top up';
    case 'Pay Bills':
     return 'Bills Payment';
    case 'Pay Bills Reversal':
      return 'Bills Payment Reversal';
    default:
      return type;
  }
}

const { Column } = Table;

const TransactionHstoryTable = () => {
  const [tableData, setTableData] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [minPage, setMinPage] = useState(0);
  const [maxPage, setMaxPage] = useState(pageSize);
  const [totalPage, setTotalPage] = useState(0);

  const getHistory = async () => {
    let yourConfig = {
      headers: {
        Authorization: "Token " + localStorage.getItem("jwtToken")
      }
    }

    try {

      let response = await axios.get(
        process.env.REACT_APP_API_URL + '/api/history/?type=eon', yourConfig
      );
      setTableData(response.data)
      setTotalPage(response.data ? response.data.length : 0);
    }

    catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }

    }
  }



  useEffect(() => {
    getHistory();
    // setTableData(sampleTransactions);
  }, []);


  const renderData = (history) => {
    let filteredData = [];  
    
    if (history && history !== null) {
      // filteredData = tableData.filter(data => data.date_str.toLowerCase().includes(history.toLowerCase()));
      filteredData = tableData.filter(data => moment(data.date_str).format('ll')>=moment(history[0]).format('ll') && moment(data.date_str).format('ll')<=moment(history[1]).format('ll'));
      console.log(moment(tableData.date_str).format('ll'))
      return filteredData ? filteredData.slice(minPage, maxPage) : null;
    } else {
      return tableData ? tableData.slice(minPage, maxPage) : null;
    }

  }

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }

  const handleChangeSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const handleDateRange = (dateRange) => {
    console.log(moment(dateRange[0]).format('ll'));
    console.log(dateRange[1]);
    setSearchValue(dateRange);
  }

  const changePage = (page, pageSize) => {
    setMaxPage(page * pageSize)
    setMinPage((page - 1) * pageSize)
    setCurrentPage(page)
  }

  const changePageSize = (current, size) => {
    setMaxPage(current * size)
    setMinPage((current - 1) * size)
    setPageSize(size)
  }

  return (
    <div>
      <div style={styles.titleContainer}>
        <Typography style={styles.title}>Transaction History</Typography>
        {/* <Input
          placeholder="Jan 20, 2020"
          style={{ width: '200px', height: '35px' }}
          // prefix={<Icon type="search" />}
          suffix={<CalendarTodayOutlinedIcon />}
          onChange={handleChangeSearch}
        /> */}
        <DatePicker.RangePicker
          format='MM-DD-YYYY' 
          disabledDate={disabledDate} 
          size="large"
          suffixIcon={<CalendarTodayOutlinedIcon style={{marginTop: -12}} />}
          onChange={(dates, dateString) => handleDateRange(dates, dateString)}
          style={{width: '45%'}}
          allowClear={false}
          disabled={!tableData}
        />
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={renderData(searchValue)}
          rowClassName='table-row-light'
          pagination={{ defaultPageSize: pageSize }}
          loading={!tableData}
          pagination={false}
        >
        </Table>
        <Pagination
          size='small'
          total={totalPage}
          defaultPageSize={pageSize}
          defaultCurrent={1}
          current={currentPage}
          showSizeChanger
          pageSize={pageSize}
          showTotal={(totalPage, range) => `${range[0]}-${range[1]} of ${totalPage} items`}
          onChange={(page, pageSize) => changePage(page, pageSize)}
          onShowSizeChange={(current, size) => changePageSize(current, size)}
          style={styles.pagination}
        />
      </div>
    </div>
  )
}

const styles = {
  root: {

  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 0 16px 0'
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#000000',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '30px 0'
  },
  tableAction: {
    fontSize: 26,
    color: '#909196',
    fontWeight: 'bold'
  }
}

export default TransactionHstoryTable;