import React from 'react'
import axios from 'axios'

import {Table, Input, Modal, Select, message, Switch} from 'antd'
import CustomInput from './customInput'
import FeeStyles from '../../style'

const {Option} = Select

const ApprovedCard = props => {

    const [showModal, setShowModal] = React.useState(false)
    const [currPartner, setCurrPartner] = React.useState(null);
    const [addPartner, setAddPartner] = React.useState('');
    const [pubKey, setPubKey] = React.useState("")
    const [secretKey, setSecretKey] = React.useState("")
    const [GID, setGID] = React.useState("")
    const [GName, setGName] = React.useState("")
    const [fscEnabled, setFSCEnabled] = React.useState(false)
    const [fscPercent, setFSCPercent] = React.useState("")
    const [fscSellerCode, setSellerCode] = React.useState("")
    const [fscSellerEmail, setSellerEmail] = React.useState("")
    const [fscSellerName, setSellerName] = React.useState("")

    const [MID, setMID] = React.useState("")

    const partners = props.partners;
    const fee = FeeStyles()
    const columns = [
            {
              title: 'Group ID',
              dataIndex: 'code',
            },
            {
              title: 'Group Name',
              dataIndex: 'description',
            },
            {
              title: 'FSC Enabled',
            render: record => {
                if(record.fsc_enabled){
                    return "Yes"
                }else{
                    return "No"
                }
            }
            },
            {
              title: 'FSC Percent',
              dataIndex: 'fsc_percent',
            },
            {
              title: 'FSC Seller Name',
              dataIndex: 'fsc_seller_name',
            },
            {
              title: 'FSC Seller Code',
              dataIndex: 'fsc_seller_code',
            },
            {
              title: 'FSC Seller Email',
              dataIndex: 'fsc_seller_email',
            },
            {
                title: 'Action',
                render: record => {
                    return <a onClick={ ()=> { setData(record); setShowModal(true);}}>View</a>
                }
            }]
  
  const defaultData = {"id":0,
                       "code":"",
                       "fsc_enabled":false,
                       "fsc_percent":"0.00",
                       "description":"",
                       "fsc_seller_code":"",
                       "fsc_seller_name":"",
                       "fsc_seller_email":""}
  const setData = (partner) => {
        setMID(partner.id);
        setGID(partner.code);
        setFSCEnabled(partner.fsc_enabled);
        setFSCPercent(partner.fsc_percent);
        if(partner.description){
            setGName(partner.description);
        }else{
            setGName("");
        }
        if(partner.fsc_seller_code){
            setSellerCode(partner.fsc_seller_code);
        }else{
            setSellerCode("");
        }
        if(partner.fsc_seller_name){
            setSellerName(partner.fsc_seller_name);
        }else{
            setSellerName("");
        }
        if(partner.fsc_seller_email){
            setSellerEmail(partner.fsc_seller_email);
        }else{
            setSellerEmail("");
        }
  }

    async function pushGroup(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = {'code': GID,
                      'description': GName,
                      'enabled': fscEnabled,
                      'percent': fscPercent,
                      'seller_email': fscSellerEmail,
                      'seller_code': fscSellerCode,
                      'seller_name': fscSellerName}
        if(MID!=0){
            params['merchant_group_id'] = MID;
            try {

                let response = await axios.patch(
                process.env.REACT_APP_API_URL + `/api/merchant_groups/${props.uid}/`,params, yourConfig)
                if(response.data.status == 'success'){
                    message.success('Merchant Group updated');
                    window.location.reload();
                }else{
                    message.error(response.data.message)
                }
            }

            catch (error) {
                console.log(error)
            }
        }else{

            try {

                let response = await axios.post(
                process.env.REACT_APP_API_URL + `/api/merchant_groups/${props.uid}/`,params, yourConfig)
                if(response.data.status == 'success'){
                    message.success('Merchant Group created');
                    window.location.reload();
                }else{
                    message.error(response.data.message)
                }
            }

            catch (error) {
                console.log(error)
            }
        }


    }

  const handleChange = (event) => {
    setFSCEnabled(!fscEnabled);
  }
    
    return(
        <div style={{paddingLeft: 20, paddingRight: 20, marginTop: -1}}>
            <Modal
                visible={showModal}
                onCancel={() => setShowModal(false)}
                width={640}
                footer={null}>

                <div style={{
                    marginTop: 32,
                    fontSize: 24,
                    lineHeight: '32px',
                    fontWeight: '300',
                    width: 600
                }}>
                    <div className={fee.innerDiv} style={{ marginTop: -1}}>
                        <div className={fee.spaceBetween} style={{borderBottom: '1px solid', borderBottomColor: '#E6EAF0'}}>
                            <div>
                                <div><span className={fee.channelHeader}>Details</span></div>
                            </div>
                        </div>
                        <div style={{ padding: 20}}>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>Group ID</div>
                            <Input value={GID} onChange={(e) => setGID(e.target.value)} style={{ marginTop: 10}}/>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>Group Name</div>
                            <Input value={GName} onChange={(e) => setGName(e.target.value)} style={{ marginTop: 10}}/>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>FSC Enabled</div>
                            <Switch checked={fscEnabled} onChange={handleChange} style={{margin: '12px 20px 12px 12px', backgroundColor: `${fscEnabled ? '#F5922F' : '#D1D5DD'}`}}/>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>FSC Percent</div>
                            <Input value={fscPercent} onChange={(e) => setFSCPercent(e.target.value)} style={{ marginTop: 10}}/>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>FSC Seller Name</div>
                            <Input value={fscSellerName} onChange={(e) => setSellerName(e.target.value)} style={{ marginTop: 10}}/>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>FSC Seller Code</div>
                            <Input value={fscSellerCode} onChange={(e) => setSellerCode(e.target.value)} style={{ marginTop: 10}}/>
                            <div className={fee.inputHeader} style={{ marginTop: 10}}>FSC Seller Email</div>
                            <Input value={fscSellerEmail} onChange={(e) => setSellerEmail(e.target.value)} style={{ marginTop: 10}}/>
                            <div align='right' style={{marginTop: 20}}>
                                <button className={fee.approveBtn} onClick={() => pushGroup()}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className={fee.innerDiv}>
                <div className={fee.spaceBetween} style={{padding: 20, borderBottom: '1px solid', borderBottomColor: '#E6EAF0'}}>
                    <div>
                        <div><span className={fee.channelHeader}>Merchant Groups</span></div>
                    </div>

                    <div>
                        <div>
                            <button className={fee.editBtnTrans} onClick={ ()=> {setData(defaultData); setShowModal(true);}}>Add</button>
                        </div>
                    </div>

                </div>


                <div style={{ padding: 10}}>

                    <Table
                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                    dataSource={partners}
                    pagination={false}
                    columns={columns} />
                </div>
            </div>
        </div>
    )
}

export default ApprovedCard