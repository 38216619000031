import React from "react";
import { Modal, Typography } from "antd";

export function AdminSuspendModal({
    isOpen,
    handleOk,
    isLoading,
    handleCancel
}){

    return (
        <Modal
            title="Suspend User"
            visible={isOpen}
            onOk={handleOk}
            confirmLoading={isLoading}
            onCancel={handleCancel}
            width={360}
            centered
            >
            <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
                <Typography style={{ fontWeight: "bold" }}>
                    Are you sure you want to suspend this user?
                </Typography>
                <Typography style={{ color: "#555" }}>
                    <strong>Please note:</strong> Suspending this user will also affect their associated makers and approvers, if applicable.
                </Typography>
            </div>
        </Modal>
    )
}