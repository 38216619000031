import React from 'react';


export const TERMS = [
    {
        q: 'About Bux',
        d: 
        <div>
            <p>
            BUx is an end-to-end payments and logistics solutions for e-commerce that aims to provide a seamless experience to both buyers and sellers.  The platform is available via web or via Facebook Messenger. This solution allows buyers to pay through a wide array of payment facilities and sellers to accept these payments. Sellers also have the ability to ship their products by choosing from the list of logistics partners integrated in the system. Sellers are then capable of withdrawing their funds received from buyers through any bank through Instapay, Pesonet, Unionbank Fund Transfers and EON network in near real time. BUx supports both web and Facebook Messenger.  
            </p>
            <p>
            The terms “Platform,” and “website” shall be interchangeably used with BUx in this document and shall refer to BUx as a platform or website.
            </p>
        </div>
    },
    {
        q: 'User Agreement',
        d: 
        <div>
            <p>
            The following are terms of the legal agreement (the "Agreement") between you and BUx (the “Platform”) that will govern your use of the Platform through www.bux.ph and other BUx channels. The Platform is owned and operated by UBX Philippines Corporation (“UBX PH”), a duly organized and existing entity under the laws of the Republic of the Philippines with office address at 33rd Floor UnionBank Plaza, Meralco Ave. corner Onyx and Sapphire Roads, Ortigas Center, Pasig City, Philippines. By accessing, browsing and/or using the Platform, you acknowledge that you have read, understood, and agree to be bound by the Terms and Conditions of this Agreement and to comply with all the terms and conditions herein and any future revisions or amendments to the same, applicable laws and regulations. The Terms and Conditions of this Agreement form an essential basis of the agreement between you and UBX PH.
            </p>
            <p>
            UBX PH reserves the right to amend this Agreement at any time. All changes shall have immediate effect and your continued use of the Platform shall be deemed a continuing acceptance of, understanding or agreement to all the provisions in this Agreement and to its future revised versions.  UBX PH may terminate, suspend, amend, or restrict your access to all or any part of the Platform without need of prior notice or liability.  Any amendment or revision to this Agreement shall be posted in the Platform for your information or reference. 
            </p>
        </div>
        
    },
    {
        q: 'Limitations of Use',
        d: 
        <div>
            <p>
            The copyright in all materials on this website and in the Platform, including without limitation, the text, data, articles, design, source code, software, photos, images and other information (collectively the "Content") is owned by UBX PH, protected by Philippine laws and international copyright laws or treaties. Any Content may not be copied, reproduced, distributed, republished, displayed, posted or transmitted in any form or by any means, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, without the express prior written consent of UBX PH as copyright owner. The content is and shall remain the property of UBX PH at all times. You may not modify, participate in the sale or transfer of, or create derivative works based on any Content, in whole or in part. The use of the Content on any other website, including by linking or framing, or in any networked computer environment for any purpose is prohibited without UBX PH’s prior written consent. The prohibition likewise extends to the "mirroring" of any Content contained in this website or the Platform on any server. Any unauthorized use of any Content shall be construed as violation of the applicable laws on copyright, trademark or intellectual property, among others.  
            </p>
            <p>
            All data obtained from or provided by UBX PH, regardless of the method of delivery, is explicitly prohibited from publication and distribution and is subject to UBX PH’s data terms of use.   Moreover, any data provided by UBX PH, regardless of the method of delivery, used for any competing purpose and such data may only be used for the transactions performed in BUx or with other products or services of UBX PH.  The use of the website, Platform and their Contents may only be used for lawful purposes. All participants in the website or Platform are prohibited from using the site, Platform and their contents that would constitute a violation of any applicable law, regulation, rule or ordinance of any nationality, state, or locality or of any international law or treaty, or that could give rise to any civil, criminal, or administrative liability to UBX PH or any third party. Any unauthorized use of the website, Platform and their Contents, including but not limited to, unauthorized entry into UBX PH’s systems, misuse of passwords, or misuse of any information posted on the Platform is strictly prohibited. Any eligibility for a particular product or service in the Platform is subject to the final determination by UBX PH.
            </p>
        </div>
    },
    {
        q: 'Trademarks',
        d: 
        <p>
            UBX PH logo and all related logos (collectively the "trademarks") are trademarks or service marks of UBX PH. Other company, product, and service names and logos used and displayed on this website or in the Platform may be trademarks or service marks owned by other individuals or entities or other companies participating in the Platform. Nothing in this website or Platform should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of the trademarks displayed in this website or Platform without the prior written consent of the trademark owners.  
        </p>
    },
    {
        q: 'Consent to Doing Business Electronically',
        d: 
        <div>
            <p>
            You may access your profile by signing in to the Platform using your web browser or your mobile device.
            </p>
            <p>
            You allow BUx to impose fees for the use of our services such as payment channels. The applicable fees are posted in the website and you will be informed of the exact amount to be collected from you for every transaction that you will make in Bux.  
            </p>
            <p>
            Signing up to the Platform shall be done via website, Facebook Messenger. During sign up, you must provide the necessary information needed such as, but not limited to, your complete name, email address, mobile number, legal business name, company position, company industry, business type, business contact information, personal or business documents, financial statements, identification cards, other details about yourself or your business, etc.  It shall be assumed that all the information you have provided are correct, true and accurate.  The information you have provided can and will be used as a basis for allowing you to avail of certain products or services offered in the Platform.  Whenever any information that you have provided turns out to be false or inaccurate or misleading but was used as a basis to enter into or avail of a service in BUx you shall be considered as acting in a fraudulent, deceptive or illegal manner and may be held criminally or civilly liable for such. This is without prejudice to UBX PH cancelling, terminating, suspending or restricting your continued availment of the product or service or your account in BUx altogether. 
            </p>
            <p>
                Once signed up, the Platform will allow you to choose payment facilities, withdraw funds and
                book logistics services based on your preferences. All payment-related transactions and
                logistics services are based on the Terms & Conditions of the payment processors, UnionBank
                of the Philippines and the logistics company selected. Once any of these services are
                requested, the Platform will allow you to receive and accept the following communications sent
                by the Platform (1) payment and my checkout; (2) order forms; (3) InstaPay, Pesonet, Unionbank
                Fund Transfers and EON Bank Transactions; (4) documents, and; (5) information as required by
                the law and participating partners, among others.
            </p>
            <p>
            You assume full responsibility and liability for all transactions made by or under your profile or account through the use of the Platform. It is understood that your password is known only to you and, as such, any transaction effected using your password and/or One-Time Password (OTP) shall be conclusively presumed to have been done, executed or authorized by you.  UBX PH will not be liable for any claim arising from your transaction made through the Platform. 
            </p>
            <p>
            Before proceeding with the payout transaction, an OTP will be sent to your registered mobile number or email address for security and verification purposes.
            </p>
            <p>
            You agree that the Platform, at its sole discretion, is entitled to act on the instructions it has received from you after the correct entry of your password and/or one-time PIN, or your other biometric authentication methods such as, but not limited to, fingerprint scanning, facial recognition or retina scan. You further agree that the Platform shall not be liable and you agree to indemnify the Platform for any loss, damages or costs the Platform incurs for acting in accordance with or based on instructions it has received from you.
            </p>

            <p>
            This section informs you of your rights when receiving electronic communications from the Platform.   
            </p>

            <p>
                <b>Electronic Communications. </b> 
                You agree that all communications from UBX PH relating to your use of the Platform or related services may be provided or made available to you electronically by electronic mail, SMS, at the Platform, or website.   
            </p>
            <p>
                <b>Scope of Consent. </b> 
                Your consent to receive electronic communications and do business electronically, and our agreement to do so, applies to all your interactions or transactions to which such electronic communications relate, whether between you and UBX PH or other participants in the Platform involved in your transaction.
            </p>
            <p>
                <b>Hardware and Software Requirements. </b> 
                To access and retain the communications electronically, you will need to use a device with an internet connection and an up-to-date browser capable of attaching files in the form of portable document format (PDF), images (JPG/PNG), and other file types. 
            </p>
            <p>
                <b>Mobile Technology. </b> 
                Mobile devices such as tablets, smart phones or similar devices should be able to access and retain electronic communications when accessing the website or Platform.  If you are accessing our site electronically through a mobile device, such as a tablet, smartphone or similar device, you must also be able to access and retain the communications electronically. 
            </p>
            <p>
                <b>Withdrawing Consent. </b> 
                You may withdraw your consent to receive electronic communications in the manner described below. Once consent is withdrawn, no further transactions will be allowed and any transaction shall be considered cancelled.  The withdrawal of your consent will not affect the legal validity and enforceability of existing transactions or any electronic communications provided or business transacted prior to the withdrawal of your consent.
            </p>
            <p>
                <b>Changes in Your Contact Information. </b> 
                You agree to keep us informed of any changes in your mobile number, email address, and other contact information you have provided to enable continuous receipt of electronic communications from the website.  Your information can be updated at any time by accessing your profile in the website.      
            </p>

            
        </div>
    },
    {
        'q': 'Password Security',
        'd': 
        <p>
            It is your duty to keep your password and other profile information confidential and secure at all times.  The website provides tips on confidentiality and security.  If you believe that your password, confidential information, access points (i.e., mobile phones, tablets, laptop, computers, etc.) have been compromised, lost or stolen or used without permission, you should contact UBX PH immediately to de-activate your compromised ID or password.  The website or UBX PH is not responsible for any loss, damage or cost incurred that results from the compromise or loss or unauthorized use of your password, confidential information or access points or any transactions resulting therefrom.
        </p>
    },
    {
        'q': 'Data Storing and Monitoring',
        'd': 
        <p>
            The Platform will collect personal or business information from you in order to provide products or services.  By providing these information, you consent to the storing, processing, and monitoring of these information that are provided in the website, mobile app and other channels such as Facebook Messenger, Viber, email, SMS, or WhatsApp  for purposes which may include:  Know-Your-Customer, risk monitoring, compliance or anti-fraud checks or investigations, credit scoring, or marketing/cross-selling of financial products from UBX PH or UnionBank or from UnionBank’s group of companies. Furthermore, you consent to us providing your information to the participating payment processors, UnionBank of the Philippines and logistics company as you select when generating a payment related to transactions, crediting funds to your bank account and availing of logistics services. 
        </p>
    },
    {
        'q': 'New Features or Services',
        'd': 
        <p>
            The website may, from time to time, introduce new features or services. Any new feature or service will be announced or posted in the website or sent via email or SMS blast or other quick communication means.  Your continuous use of the website or maintenance of your account in the website is understood to be your continuous and continued acceptance of these new features or services regardless of your actual use of these new features or services.
        </p>
    },
    {
        'q': 'Non-Liability for Loss, Damage or Profit',
        'd': 
        <p>
            The website or UBX PH shall not be liable for any direct, indirect, incidental or consequential loss or damage or loss of profit of whatever nature arising from:  
        </p>
    },
    {
        'd': 
        <div>
            <ol>
                <li>
                    <p>
                    Disruption, failure or delay relating to or in connection with the website and/or its services due to circumstances beyond the control of UBX PH, unforeseen events, fortuitous events such as, but not limited to, prolonged power outages, breakdown in computers, software, operating systems and telecommunication facilities, government orders, typhoons, floods, public disturbances, calamities or other acts of nature or Acts of God. 
                    </p>
                </li>
                <li>
                    <p>
                    Any fraudulent or unauthorized access or utilization of the website due to theft, unauthorized disclosure of personal information, mobile phone numbers, emails, passwords or unauthorized usage of biometrics or other security measures used in the website with or without the participation of the information owner.
                    </p>
                </li>
                <li>
                    <p>
                    Inaccurate, incomplete or delayed information received due to disruption or failure of any information or communication facilities or telecommunication network, internet or software or hardware.     
                    </p>
                </li>
                <li>
                    <p> Failure or inability to use the website.</p>
                </li>
                <li>
                    <p> 
                    Any disclosure of information concerning your profile and/or transactions to persons for any reason whatsoever, including, but not limited to, wiretapping of communication lines or erroneous connection by telecommunication switches, or errors in transmitted information due to faulty lines, and any and all forms of high technology surveillance or fraud.
                    </p>
                </li>
                <li>
                    <p>
                    Loss of your mobile phone, mobile devices and/or other electronic devices enrolled to the Platform.
                    </p>
                </li>
            </ol>
        </div>
    },
    {
        'q': 'Services Provision',
        'd': 
        <p>
            The following are the Services that will be provided by the website: payment processing, logistics and disbursal via Unionbank Fund Transfer, InstaPay/Pesonet or EON.
        </p>
    },
    {
        'q': 'Shipping and Valuation Fees',
        'd': 
        <p>
            Unless the parties agree to different terms regarding Shipping and Valuation Fees, the following will depend on the terms and conditions of your chosen logistics provider and may change from time to time. 
        </p>
    },
    {
        'd':
        <ol>
            <li>
                Shipping fees may be subject to VAT or other taxes, fees or charges.
            </li>
            <li>
                Packaging options and shipping fees as detailed in the pricing section found in your dashboard.
            </li>
            <li>
                Some shipping or deliveries maybe subject to a valuation fee. Please refer to the latest fees of your selected shipper.
            </li>
            <li>
                The shipping or valuation fees or both and any inclusive taxes, charges or fees related thereto shall be for the account of the Seller. 
            </li>
            <li>
                Return-To-Sender (RTS) fees, except when waived in writing by the chosen logistics partner shall likewise be for the account of the Seller.  RTS fees are imposed after an undelivered package is returned to the point of origin (pickup address). The amount of RTS fee shall depend on the chosen logistics partner.
            </li>
        </ol>

    },
    {
        'q': 'Fee Changes',
        'd': 
        <p>
            UBX PH reserves the right to modify the transactions fees, charges, or billing methods at any time subject to a  60-day prior notice.  The updated schedule of applicable transaction fees, charges or billing methods shall be posted in the website.  Your continued use of the website after the effectivity of any change shall indicate your agreement to and acceptance of such changes.  Non-payment of the fees or charges for the use of the website or any of its products or services is a ground for UBX PH to terminate, suspend, restrict or deny your use or access or transaction in the website.
        </p>
    },
    {
        'q': <span>Acceptable Use<br />Order Link and Payment Processing</span>,
        'd': 
        <p>
        You warrant and undertake that the Order Link and Payment processing feature is to be used solely for Buyers or your Clients to purchase or book orders for delivery from you. You agree and undertake not to use, directly or indirectly through your employees, personnel, agents, or associates, the Order Link feature to purchase on behalf of Buyers. Violation of this provision is a ground for UBX PH, at its sole discretion, to terminate or deactivate your profile or account, suspend, restrict or deny your use or access to the website. Any claim against UBX PH under this provision is void and invalid.  
        </p>
    },
    {
        'q': '1. Logistics and Order Delivery Feature',
        'd': 
        <p>
        You warrant and undertake that  the information entered in the Book Delivery Feature pertaining to your Buyer, including but not limited to the Buyer’s name, contact information, address, item description, (collectively, the “Buyer Information”) is complete, correct, true and accurate.  Incomplete, wrong, false or inaccurate Buyer Information will impact your sustainable use metrics.
        </p>
    },
    {
        'q': '2. Plug-ins',
        'd': 
        <p>
        You warrant and undertake that the Buyer Information you input using Plug-in(s) Feature is correct and you undertake to use your best efforts to verify these Buyer Information before approving orders for processing.
        </p>
    },
    {
        'q': '3. Manipulation of Ratings and Reviews',
        'd': 
        <p>
        Ratings and Reviews Feature is created to increase public trust for your business, protect Buyers, and maintain the integrity of the services that the website provides. You warrant and undertake not to manipulate ratings and reviews of your Account by allowing Buyers to independently rate and review their experience with you. If you are found to be manipulating your Ratings and Reviews through any means, UBX PH, at its sole discretion, may terminate, deactivate your profile or account, suspend, restrict or deny your use or access to the website at any time without need of a prior notice to you.  
        </p>
    },
    {
        'q': 'Disbursal of Transactions Proceeds',
        'd': 
        <ol>
            <li>
            Sales and proceeds of sales from using the Services shall be treated as a sale by you.
            </li>
            <li>
            A sale is only concluded if and when: (i) an order is placed that contains all pertinent and required details via the Service; (ii) payment is received by our account; and (iii) the goods have been delivered and actually received by the intended recipient.
            </li>
            <li>
            Disbursement of the proceeds of sales due to you, if applicable, shall be disbursed to you in accordance with the usual and standard InstaPay, Pesonet, Unionbank Fund Transfer and EON payout schedule as implemented by UnionBank of the Philippines.  Where applicable, any changes in the payout schedule shall be duly posted in the website for the information of all participants.   
            </li>
        </ol>
    },
    {
        'q': 'Dispute Resolution',
        'd': 
        <div>
            <p>
            BY AVAILING OF OUR SERVICES YOU WARRANT AND UNDERTAKE TO COMPLY WITH DISPUTE RESOLUTION TERMS.
            </p>
            <p>
            All disputes arising out of, relating to, or in connection with penalties imposed on you can be settled by signing a Release, Waiver, and Quitclaim agreement (RWQ agreement). If you do not sign the RWQ agreement, you agree and undertake that the remaining recourse for solution is to go through resolution before the proper courts of law.
            </p>
            <p>
            All disputes arising out of, relating to, or in connection with the interpretation or application of this Agreement and Terms or Your use of the Services that cannot be resolved informally or will be resolved through binding arbitration on an individual basis, except that you and us are not required to arbitrate any dispute in which either party seeks equitable relief for the alleged unlawful use of copyrights, trademarks, trade names, logos, trade secrets, or patents. Arbitration will be initiated solely through the Philippine Dispute Resolution Center, Inc. (“PDRCI”). This arbitration agreement will survive the termination of your relationship with UBX PH.  Notwithstanding the foregoing, UBX PH may choose to bring an individual action in court.
            </p>
        </div>
    },
    {
        'q': 'Arbitration',
        'd': 
        <div>
            <p>
            Any dispute, claim or difference directly or indirectly arising out of or in connection with this Agreement, including any question regarding its existence, interpretation, construction, performance, validity or termination shall be referred to and finally determined and settled by arbitration in accordance with the PDRCI arbitration rules (the “Rules”) by three (3) arbitrators appointed in accordance with the Rules. The arbitration shall be held in Pasig City, Philippines and shall be conducted in English. 
            </p>
            <p>
            These Terms and Conditions shall be exclusively governed and interpreted under the laws of the Republic of the Philippines without regard to its conflict of laws provisions.
            </p>
        </div>
    },
    {
        'q': 'Disclaimers',
        'd': 
        <div>
            <p>
            You undertake and warrant that the items, goods or services you are selling, offering, marketing or advertising via the website are not illegal or in violation of any laws, rules or regulations and satisfy the standard expectations of a buyer and do not and will not infringe any third party rights and are of an acceptable quality and consistent to Your released product information.
            </p>
            <p>
            In any event, you agree and understand that UBX PH is not a party to the transaction between you and your Buyer. As such UBX PH shall not be liable for or answerable to any damages or losses arising from your transaction with your Buyer, including non-fulfilment or non-delivery of the services or goods.
            </p>
        </div>
    },
    {
        'q': 'Third Party Services',
        'd': 
        <div>
            <p>
            You will have access to services of third parties through the website (the <b>“Third Party Services”</b>) for Payment Processing, Logistics and Disbursement. In those cases, said Third Party Services shall be subject to the terms, conditions, and limitations imposed by those third parties, which shall also be binding on you whenever you use them. UBX PH reserves the right to reject or refuse any Third Party Service used by you in conjunction with its services.  
            </p>
            <p>
            Your transactions, correspondence or business dealings with, or participation in promotions of third parties, including payment and delivery of related goods or services, and any other terms, conditions, warranties, or representations associated with such transactions, correspondence, dealings or participation, are solely between you and such third party. UBX PH shall not be responsible or liable for any loss or damage incurred as the result of any such transactions, correspondence, dealings or participation or as the result of the presence of such third party advertisers on the website. 
            </p>
            <p>
            When you acquire goods or services from, or when you sell or offer to sell goods or services to a third party through our website, you understand and agree that (i) UBX PH is not a party to the contract between you and the third party; (ii) UBX PH is under no obligation to monitor the third party service you have contracted with, and; (iii) the third party will be responsible for all obligations under the contract with you, including, without limitation, warranties or representations.</p>
            <p>
            UBX PH shall not be liable and/or responsible with respect to the title, quantity, quality or any other aspect related to the goods and/or services that you offer, and no warranty, or indemnity of any kind shall be given or deemed to be given by UBX PH in respect thereof.</p>
            {/* this is last point */}
            <p>
            You shall be solely liable for the loss incurred by you due to unauthorized transactions where the loss is due to an act or omission attributable to you, such as, but not limited to, where you have failed to secure your credentials or account.
            </p>
            <p>
            You agree and acknowledge that nothing in this Agreement limits you from entering into similar arrangements or availing of similar services with third parties. Accordingly, you agree and acknowledge that UBX PH shall not be liable for any loss incurred by you due to such similar arrangements or services with third party.
            </p>
        </div>
    },
    {
        'q': 'Venue of Actions',
        'd': 
        <p>
            The venue of all legal actions or proceedings arising out of or in connection with these Terms and Conditions shall be brought exclusively in the proper courts of Pasig City to the exclusion of all other venues.
        </p>
    },
    {
        'q': 'Complaints against You',
        'd': 
        <p>
            Any complaint that UBX PH receives against you in relation to your use of your BUx account, BUx services, the transaction done using BUx or delivery of the goods that was transacted using BUx may be used as grounds by UBX PH to suspend, deactivate, terminate, or cancel on a temporary or permanent basis your account in BUx or the services you have availed of.  The complaint also gives UBX PH the right to investigate the transaction being complained about and to provide your relevant information to law enforcement, quasi-judicial bodies, investigative or regulatory bodies or relevant information regarding the transaction being complained about whenever UBX PH is requested to do so. Where possible, UBX PH may also withhold the crediting of the proceeds of the transaction being complained about.  
        </p>
    },
    {
        'q': 'Double Crediting',
        'd': 
        <p>
            Any double or multiple posting or crediting of proceeds arising from one and the same transaction to your designated account creates an obligation for you to return the excess amount to UBX PH.  Failure to return the excess amount to UBX PH shall be a ground for UBX PH, at its sole discretion, to terminate or deactivate your profile or account, suspend, restrict or deny your use or access to the website. UBX PH may likewise refuse crediting of future proceeds to your account to offset the amount due from the double or multiple posting that you have failed to return.     
        </p>
    },
    {
        'q': 'Funds Remaining in Your Account',
        'd': 
            <p>
                In the event that your BUx account will be deactivated, terminated or cancelled on a permanent basis by UBX PH, any remaining funds therein may be credited to your designated bank account provided that none of the following conditions, events or incidents exist:
            </p>
    },
    {
        'd':
            <div>
                <ol type="a">
                    <li>
                        There is no allegation or claim of non-delivery from your end in any of your transactions in BUx by a third party;
                    </li>
                    <li>
                        There is no allegation of non-fulfilment from your end in any of your transactions in BUx by a third party;
                    </li>
                    <li>
                        There is no complaint from your client or counterparty for non-delivery or non-fulfilment of any of your transactions wherein you used BUx to facilitate payment.
                    </li>
                </ol>
            </div>
    },
    {
        'q': 'Co-branding with EON',
        'd': 
        <p>
            UBX PH shall be responsible in handling any customer issues regarding general product inquiries about EON, including securing an EON Accountholder’s written consent for UnionBank to disclose account-related information to UBX PH such as, but not limited to, account balance or account history.
        </p>
    },


]