import React from 'react';
import { Layout, Typography } from 'antd';
import { isMobile } from 'react-device-detect';
import { BUx } from '../../../static/icons/bux_qr';
import NewCheckouBG from '../../../static/icons/newCheckoutBGTop.svg';
import WooCommerceLogo from '../../../static/icons/woo_commerce1.svg';
import { Step1, Step2, Step3, Step4 } from '../../../static/new_segment/tutorials/presta_shop';
import VideoModal from '../../how_it_works/reusable_content/video_modal';
import * as Icon from '../../../static/icons/how_it_works/index';

const { Header } = Layout;

const stepStyle = {
    instruction: {
        color: '#000',
        fontWeight: '400',
        fontSize: '16px',
        textAlign: 'center'
    },
}

const steps = [
    {
        instruction: <Typography style={stepStyle.instruction}>On your Dashboard, Click <b>Integration</b>.</Typography>,
        img: Step1,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>Copy the <b>Integration Credentials</b> (API Key, Client ID, Client Secret).</Typography>,
        img: Step2,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>Switch to your <b>WooCommerce</b> website , Select <b>BUx</b> in WooCommerce dashboard.</Typography>,
        img: Step3,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>Paste the <b>API Key, Client ID, Client Secret</b> then <b>save changes</b> to complete.</Typography>,
        img: Step4,
    },
]

const WoocommerceTutorial = (props) => {
    const [modalState, setModalState] = React.useState(false);
    const baseURL = "https://developers.bux.ph";
    const segments = localStorage.getItem('segments');

    const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
    const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
    const isDell1 = window.screen.width * window.devicePixelRatio === 1366 && window.screen.height * window.devicePixelRatio === 768;

    const smallReso = isSmallReso || isDell || isDell1

    let play_icon = segments === 'me_' ? Icon.Play_me_ :
        segments === 'gig' ? Icon.Play_gig :
            segments === 'sme' ? Icon.Play_sme :
                segments === 'biz' ? Icon.Play_biz : ""

    return (
        <div style={styles.root}>
            {/* <div hidden={!isMobile} style={{ zIndex: '1', width: '100%', height: '60px', display: 'flex', justifyContent: 'center', background: 'linear-gradient(102.61deg, #004888 0%, #00BBFF 100%)', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)' }}>
                <img src={BUx} style={{ maxWidth: '72px' }} alt="#" />
            </div> */}
            <div style={{ zIndex: '1', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '24px 0' }}>
                <img src={BUx} alt="BUx" />
            </div>
            <div style={styles.body}>
                <Typography style={styles.title}>How to Integrate</Typography>
                <img src={WooCommerceLogo} alt="Woocommerce" />
                <button className="play-video" onClick={() => setModalState(true)} style={{margin: '32px 0 62px 0'}}>
                    <img src={play_icon} alt="play" />
                </button>
                {
                    steps.map((step, i) => {
                        return (
                            <>
                                {/* <img src={step.img} alt={`Step ${i + 1}`} style={{ marginTop: '32px', width: isMobile && '100%' }} /> */}
                                <div style={styles.inlineFlex}>
                                    <Typography style={styles.stepBtn}>Step {i + 1}</Typography>
                                    {step.instruction}
                                </div>
                            </>
                        )
                    })
                }
                <VideoModal
                    source={baseURL + '/woocommerce.mp4'}
                    openModal={modalState}
                    setModalState={setModalState}
                    segments={segments}
                    smallReso={smallReso} />
            </div>
        </div>
    )
}

const styles = {
    root: {
        backgroundColor: '#F1F2F6',
        backgroundImage: `url(${NewCheckouBG})`,
        backgroundRepeat: 'no-repeat',
        textAlign: 'center',
        width: '100%',
        paddingBottom: '2px'
    },
    body: {
        backgroundColor: '#FFF',
        borderRadius: '4px',
        padding: '40px 40px 72px 40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '80%',
        margin: '12px auto 72px auto',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)'
    },
    title: {
        color: '#000',
        fontSize: '24px',
        fontWeight: '700',
    },
    stepBtn: {
        color: '#FFF',
        fontSize: '16px',
        fontWeight: '700',
        textAlign: 'center',
        borderRadius: '10px',
        backgroundColor: '#F5922F',
        padding: '6px 8px',
        minWidth: '80px'
    },
    inlineFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '14px',
        flexDirection: isMobile ? 'column' : 'row',
        margin: '12px 0',
        width: '80%'
    }
}

export default WoocommerceTutorial;