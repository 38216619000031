import React, { useState, useEffect } from "react";
import { Pagination, Table, Card, Typography, Button, Icon, Input } from "antd";
import { history } from "../../../store/history";
import SegmentFeesModal from "./segment_fees_modal";
import SegmentFeesViewModel from "./segment_fees_viewmodel";
import "./css/segment_fees.css";
import ClearFilter from "../filter_btns/clear_filter_btn";
import UploadModal from "./upload_modal";

const SegmentFees = (props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [showUploadCSVModal, setShowUploadCSVModal] = useState(false);

  const { fetchSegmentFeeList, segmentFeeData, isLoading } =
    SegmentFeesViewModel();

  function closeModal() {
    setOpenModal(false);
    fetchSegmentFeeList(searchQuery, currentPage);
  }

  function onSubmitModal() {
    setOpenModal(false);
    setCurrentPage(1);
    fetchSegmentFeeList(searchQuery, 1);
  }

  function openEditModal(record) {
    setEditData(record);
    setOpenModal(true);
  }

  useEffect(() => {
    if (
      localStorage.getItem("jwtToken") == "" ||
      localStorage.getItem("jwtToken") == null
    ) {
      history.push("/login");
    } else {
      if (
        localStorage.getItem("userType") == "ME" ||
        localStorage.getItem("userType") == "CO"
      ) {
        history.push("/dashboard");
      } else if (localStorage.getItem("userType") == "PS") {
        history.push("/orders");
      } else if (localStorage.getItem("userType") == "MA") {
        history.push("/overview");
      } else {
        fetchSegmentFeeList("", currentPage);
      }
    }
  }, []);

  useEffect(() => {
    fetchSegmentFeeList(searchQuery, currentPage);
  }, [currentPage]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      fixed: "left",
    },
    {
      title: "Channel",
      dataIndex: "channel",
    },
    {
      title: "Category Code",
      dataIndex: "category_code",
    },
    {
      title: "Category Name",
      dataIndex: "name",
    },
    {
      title: "Channel Fee",
      dataIndex: "channel_fee",
    },
    {
      title: "Channel Fee Percent",
      dataIndex: "channel_fee_percent",
    },
    {
      title: "Date Updated",
      dataIndex: "modified_at",
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <a style={{ color: "#0A315E" }} onClick={() => openEditModal(record)}>
            Edit
          </a>
        );
      },
    },
  ];

  return (
    <div
      style={{
        margin: "2em 2.5em 2.5em 2.5em",
        display: "flex",
        gap: "2em",
        flexDirection: "column",
        paddingBottom: "50px",
      }}
    >
      <div>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "20px",
            color: "#2B2D33",
          }}
        >
          Segment Fees
        </Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <div className="button-container">
          <div></div>
          <div style={{ display: "flex", gap: "1em" }}>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                setEditData(null);
                setOpenModal(true);
              }}
              className="primary-btn"
            >
              <Icon type="plus" />
              Add Segment Fee
            </Button>
            <Button
              size="large"
              className="secondary-btn"
              onClick={() => setShowUploadCSVModal(true)}
            >
              <Icon type="upload" />
              Upload CSV
            </Button>
          </div>
        </div>
        <Card className="segment-fees-card">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <ClearFilter
                hideFilterBtn={!isFiltered}
                clearFilter={() => {
                  setSearchQuery("");
                  setTempSearchQuery("");
                  setIsFiltered(false);
                  setCurrentPage(1);
                  fetchSegmentFeeList("", 1);
                }}
              />

              <div className="filter-container">
                <Input
                  placeholder="Search Channel, Category Code, or Category Name"
                  value={tempSearchQuery}
                  onChange={(e) => {
                    setTempSearchQuery(e.target.value);
                  }}
                  suffix={<Icon type="search" />}
                />

                <button
                  className={`admin-apply-btn`}
                  onClick={() => {
                    setCurrentPage(1);
                    fetchSegmentFeeList(tempSearchQuery, 1);
                    setSearchQuery(tempSearchQuery);
                    setIsFiltered(tempSearchQuery !== "");
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              dataSource={segmentFeeData.data}
              columns={columns}
              pagination={false}
              loading={isLoading}
              scroll={{ x: true }}
            />

            <Pagination
              size="small"
              total={(segmentFeeData && segmentFeeData.count) || 0}
              defaultPageSize={pageSize}
              defaultCurrent={1}
              current={currentPage}
              onChange={setCurrentPage}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              style={{ paddingTop: "12px", textAlign: "right" }}
            />
          </div>
        </Card>
      </div>

      <SegmentFeesModal
        editData={editData}
        openModal={openModal}
        closeModal={closeModal}
        submitModal={onSubmitModal}
      />
      <UploadModal
        handleRefresh={() => fetchSegmentFeeList(searchQuery, currentPage)}
        handleClose={() => {
          fetchSegmentFeeList(searchQuery, currentPage);
          setShowUploadCSVModal(false);
        }}
        modal={showUploadCSVModal}
        visible={showUploadCSVModal}
      />
    </div>
  );
};

export default SegmentFees;
