import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Card,
  Typography,
  Button,
  Icon,
  Input,
  message,
} from "antd";
import { history } from "../../../store/history";
import MerchantIDsV2Modal from "./merchant_ids_v2_modal";
import MerchantIDsV2ViewModel from "./merchant_ids_v2_viewmodel";
import "./css/merchant_ids_v2.css";
import ClearFilter from "../filter_btns/clear_filter_btn";

const MerchantIDsV2 = (_) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const [columns, setColumns] = useState([]);

  const {
    getAllChannelSubmerchantIDSettings,
    getChannelSubmerchantIDs,
    setDisplayMessage,
    isSuccess,
    messageString,
    displayMessage,
    allChannelSubmerchantIdSettings,
    channelSubmerchantIds,
    isLoading,
  } = MerchantIDsV2ViewModel();

  function closeModal() {
    setOpenModal(false);
    getChannelSubmerchantIDs(searchQuery, currentPage, pageSize);
  }

  function onSubmitModal() {
    setOpenModal(false);
    setCurrentPage(1);
    getChannelSubmerchantIDs(searchQuery, 1, pageSize);
  }

  function openEditModal(record) {
    setEditData(record);
    setOpenModal(true);
  }

  useEffect(() => {
    if (
      localStorage.getItem("jwtToken") == "" ||
      localStorage.getItem("jwtToken") == null
    ) {
      history.push("/login");
    } else if (
      localStorage.getItem("userType") == "ME" ||
      localStorage.getItem("userType") == "CO"
    ) {
      history.push("/dashboard");
    } else if (localStorage.getItem("userType") == "PS") {
      history.push("/orders");
    } else if (localStorage.getItem("userType") == "MA") {
      history.push("/overview");
    } else {
      getAllChannelSubmerchantIDSettings();
      getChannelSubmerchantIDs("", currentPage, pageSize);
    }
  }, []);

  useEffect(() => {
    getChannelSubmerchantIDs(searchQuery, currentPage, pageSize);
  }, [currentPage]);

  useEffect(() => {
    if (!displayMessage) {
      return;
    }

    if (isSuccess) {
      message.success(messageString, 3, () => { setDisplayMessage(false) });
    } else {
      message.error(messageString, 3, () => { setDisplayMessage(false) });
    }
  }, [displayMessage]);

  useEffect(() => {
    let columns = [
      {
        title: "User ID",
        dataIndex: "merchant_id",
        fixed: "left",
        render: (_, record) => <span>{record.user.id}</span>,
      },
      {
        title: "User Name and Email",
        fixed: "left",
        render: (_, record) => (
          <div className="name-and-email">
            <p>{record.user.full_name}</p>
            <p>{record.user.email}</p>
          </div>
        ),
      },
    ];

    for (const [channelSubmerchantIdSettingIdx, channelSubmerchantIdSetting] of allChannelSubmerchantIdSettings.entries()) {
      columns.push({
        title: `${channelSubmerchantIdSetting.channel_name} MID`,
        render: (_, record) => {
          if (record.channel_submerchant_ids[channelSubmerchantIdSettingIdx]) {
            let metadataString = "";
            if (record.channel_submerchant_ids[channelSubmerchantIdSettingIdx].metadata != null) {
              metadataString = JSON.stringify(record.channel_submerchant_ids[channelSubmerchantIdSettingIdx].metadata);
            }
            return (
              <div>
                <p style={{ margin: '0px' }}>{record.channel_submerchant_ids[channelSubmerchantIdSettingIdx].value}</p>
                <p style={{ 
                  margin: '0px', 
                  paddingTop: '4px', 
                  maxWidth: '250px',
                  overflowWrap: 'break-word', 
                }}>
                  {metadataString}
                </p>
              </div>
            );
          }
        },
      });
    }

    columns.push(
      {
        title: "Action",
        fixed: "right",
        render: (record) => {
          return (
            <a style={{ color: "#0A315E" }} onClick={() => openEditModal(record)}>
              Edit
            </a>
          );
        },
      },
    );

    setColumns(columns);
  }, [allChannelSubmerchantIdSettings]);

  return (
    <div
      style={{
        margin: "2em 2.5em 2.5em 2.5em",
        display: "flex",
        gap: "2em",
        flexDirection: "column",
        paddingBottom: "50px",
      }}
    >
      <div>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "20px",
            color: "#2B2D33",
          }}
        >
          Merchant IDs v2
        </Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '1em',
        }}>
          <div style={{ display: "flex", gap: "1em" }}>
            <Button
              size="large"
              className="secondary-btn"
              onClick={() => {
                history.push("/merchant_id_settings");
              }}
            >
              View Merchant ID Settings
            </Button>
          </div>
          <div style={{ display: "flex", gap: "1em" }}>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                setEditData(null);
                setOpenModal(true);
              }}
              className="primary-btn"
            >
              <Icon type="plus" />
              Add Merchant ID/s
            </Button>
          </div>
        </div>
        <Card className="merchant-ids-v2-card">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <ClearFilter
                hideFilterBtn={!isFiltered}
                clearFilter={() => {
                  setSearchQuery("");
                  setTempSearchQuery("");
                  setIsFiltered(false);
                  setCurrentPage(1);
                  getChannelSubmerchantIDs("", 1, pageSize);
                }}
              />

              <div className="merchant-ids-v2-container">
                <Input
                  placeholder="Search User Email"
                  value={tempSearchQuery}
                  onChange={(e) => {
                    setTempSearchQuery(e.target.value);
                  }}
                  suffix={<Icon type="search" />}
                />

                <button
                  className={`admin-apply-btn`}
                  onClick={() => {
                    setCurrentPage(1);
                    getChannelSubmerchantIDs(
                      tempSearchQuery,
                      1,
                      pageSize,
                    );
                    setSearchQuery(tempSearchQuery);
                    setIsFiltered(tempSearchQuery !== "");
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
            <Table
              rowClassName={(_, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              dataSource={(channelSubmerchantIds && channelSubmerchantIds.data)}
              rowKey={record => record.user.id}
              columns={columns}
              pagination={false}
              loading={isLoading}
              scroll={{ x: true }}
            />

            <Pagination
              size="small"
              total={(channelSubmerchantIds && channelSubmerchantIds.count)}
              defaultPageSize={pageSize}
              defaultCurrent={1}
              current={currentPage}
              onChange={setCurrentPage}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              style={{ paddingTop: "12px", textAlign: "right" }}
            />
          </div>
        </Card>
      </div>

      <MerchantIDsV2Modal
        editData={editData}
        openModal={openModal}
        closeModal={closeModal}
        submitModal={onSubmitModal}
        allChannelSubmerchantIdSettings={allChannelSubmerchantIdSettings}
      />
    </div>
  );
};

export default MerchantIDsV2;
