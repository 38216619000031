import React, { useEffect } from 'react';
import MetaTag from '../../../../meta_tag/meta_tag';
import { Badge, Button, Icon, message, Typography } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { history } from '../../../../../store/history';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import MultiwalletSettings from './components/multiwallet_settings';
import UserHistoryTable from '../../../../multiwallet/components/user_history/user_history_table';
import UserInfo from './components/user_info';
import { useLocation } from 'react-router';
import KYBSubmission from '../../../kyc/kyb-admin';
import KYCIndividual from '../../../kyc/kyc_individual_view';
import KYCIndividual2 from '../../../kyc/kyc_user_2';
import KYCIndividual3 from '../../../kyc/kyc_user_3';
import Fee from '../../../fees/kyb_fee/index';
import Channels from '../../../channels/components/index'
import Main from '../../../../../layouts/Main';
import SubWallets from '../../../subwallets';
import axios from 'axios';
import { TransferSettings } from '../../../../../screens/Transfer';

const UserHistory = (props) => {
    const user = localStorage.getItem('userType');
    const testMode = (window.location.pathname.indexOf('/test') > -1 && localStorage.getItem("userType") == "CO");
    const batchEnabled = true;
    const [selected, setSelected] = React.useState('User Information')
    const ubp_enabled = localStorage.getItem('cc_enabled') == 'UnionBank';
    const xendit_enabled = localStorage.getItem('cc_enabled') == 'Xendit';

    const KYBApproved = localStorage.getItem("userType") == "CO" && localStorage.getItem("KYBStep") >= 11
    const endpoints = useSelector(state => state.child_endpoints.endpoints);
    const isChild = localStorage.getItem("is_child") === "true";
    const location = useLocation();
    const [data, setData] = React.useState(location && location.state && location.state.data ? location.state.data : []);
    const segments = localStorage.getItem("segments")
    const Name = data && data.business_name? data.business_name : data && data.full_name? data.full_name : 'User Details'; 
    const hash = window.location.hash;
    const pUid = props.match.params.id;

    
    const admin_tabs = [
        {
            title: 'User Information',
            hidden: false,
            hash: '#userInfo'
        },
        {
            title: 'KYC Information',
            hidden: (data && data.user_type !== 'ME') || data.eon_level === 0,
            hash: '#kycInfo'
        },
        {
            title: 'KYB Information',
            hidden: (data && data.user_type === 'ME') || data.eon_level === 0,
            hash: '#kybInfo'
        },
        {
            title: 'Sub-wallets',
            hidden: data && !data.multiwallet,
            hash: '#subWallets'
        },
        {
            title: 'Transactions',
            hidden: data && data.eon_level === 0,
            hash: '#transactions'
        },
        {
            title: 'Channel',
            hidden: (data && data.eon_level === 0),
            hash: '#channels'
        },
        {
            title: 'Fee',
            hidden: data && data.user_type === 'ME',
            hash: '#fee'
        },
        {
            title: 'Multi-wallet Settings',
            hidden: (data && !data.multiwallet),
            hash: '#multiwalletSettings'
        },
        {
            title: 'Sub-wallet Settings',
            hidden: (data && data.user_type !== 'SW'),
            hash: '#subwalletSettings'
        },
        {
            title: 'Transfer Settings',
            hidden: (data && data.user_type === 'ME'),
            hash: '#transferSettings'
        }
    ]


    const changeSelectedTab = (e) => {
        setSelected(e.title);
        window.location.hash = e.hash;
    }
    
    const customButtons = admin_tabs.map((data, i) => {
        return (
            <Button
                key={data.title}
                value={data.title}
                onClick={() => changeSelectedTab(data)}
                style={{
                    ...styles.btnGroup,
                    color: hash === data.hash ? '#0D3D76' : '#909196',
                    backgroundColor: 'transparent',
                    borderBottom: hash === data.hash ? '2px solid #0D3D76' : 'transparent'
                }}
                hidden={data.hidden}
            >
                {data.title}
            </Button>
        )
    })

    const ButtonTab = ({ rootStyle, array }) => {

        const status = {...array};
        
        return (
            <div style={styles.btnTabContainer}>
                <ButtonGroup>
                    {   
                        // Object.keys(array).forEach(function(key) {
                        //     return (
                        //         <Button
                        //             key={array[key].title}
                        //             value={array[key].title}
                        //             onClick={() => changeSelectedTab(array[key])}
                        //             style={{
                        //                 ...styles.btnGroup,
                        //                 color: selected === array[key].title ? '#0D3D76' : '#909196',
                        //                 backgroundColor: 'transparent',
                        //                 borderBottom: selected === array[key].title ? '2px solid #0D3D76' : 'transparent'
                        //             }}
                        //             hidden={array[key].hidden}
                        //         >
                        //             {array[key].title}
                        //         </Button>
                        //     )
                        // })
                        customButtons
                    }
                </ButtonGroup>
            </div>
        )
    }

    const redirect = () => {
        let current_path = window.location.pathname
        if (localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null) {
            history.push('/login?next=' + current_path)
        } else if (['PS', 'PF'].includes(localStorage.getItem("userType"))) {
            history.push('/submissions_kyb')
        }
    }

    // const getCardInfo = async () => {
    //   try {
    //     const res = await dbService.getCardInfo();
    //     setCCEnabled(res.cc_enabled);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    React.useEffect(() => {
        redirect();
    }, [])


    let yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }

    const fetchUserDetails = async () => {
        try {
            const res = await axios.get(
                process.env.REACT_APP_API_URL + '/api/'+pUid+'/balance/',yourConfig
            );
            if(res.data) {
                setData(res.data);
            } else {
                message.error('Error, try again later');
            }

        } catch (error) {
            console.log(error);
            message.error('Error, try again later');
        }
    }

    const selectKYCView = (data) => {
        const level = data && data.eon_level;
        switch (level) {
            case 1:
                return <KYCIndividual2 isAdminHistory={true} match={{ params: { id: data.id } }} />;
            case 2:
                return <KYCIndividual2 isAdminHistory={true} match={{ params: { id: data.id } }} />;
            case 3:
                return <KYCIndividual3 isAdminHistory={true} match={{ params: { id: data.id } }} />;
            default:
                return <KYCIndividual2  isAdminHistory={true} match={{ params: { id: data.id } }} />;
        }
    }

    React.useEffect(() => {
        fetchUserDetails();
    },[])

    return (
        <Main>
            <div style={{ marginLeft: '-30px' }}>
                <MetaTag title="User History" />
                <div style={styles.titleContainer}>
                    <div style={styles.titleDiv}>
                        <div style={{display: 'inline-flex', alignItems: 'center', gap: '12px'}}>
                            <a style={{ textDecoration: 'none'}} onClick={() => history.goBack()} className={`link-text--${segments}`}>
                                <Icon type="arrow-left" style={{fontSize: '32px', color: '#0A315E' }} /></a>
                            <strong style={styles.title}>{Name}</strong>
                        </div>
                        <Button style={styles.btnBack} onClick={() => history.push('/users')}>{<Icon type="close" />}Close</Button>
                    </div>
                    <ButtonTab array={admin_tabs} />
                </div>
                <div style={styles.children}>
                    {
                        hash === '#userInfo' ? <UserInfo data={data} />
                            : hash === '#kycInfo' ? selectKYCView(data)
                                : hash === '#kybInfo' ? <KYBSubmission props={props} />
                                    : hash === '#subWallets' ? <SubWallets props={props} />
                                        : hash === '#transactions' ? <UserHistoryTable segments={segments} isUserHistory={true} props={{...props}} />
                                            : hash === '#channels' ? <Channels props={props} />
                                                : hash === '#fee' ? <Fee props={props} />
                                                    : hash === '#multiwalletSettings' ? <MultiwalletSettings data={data} id={pUid}/>
                                                        : hash === '#subwalletSettings' ? <MultiwalletSettings data={data} id={pUid}/>
                                                            : hash === '#transferSettings' ? <TransferSettings data={data} id={pUid} />
                                                                : ""
                    }
                </div>
            </div>
        </Main>
    )
}

const styles = {
    root: {

    },
    title: {
        width: '100%',
        color: '#000',
        fontWeight: 'bold',
        fontSize: 28,
        display: 'flex',
        alignItems: 'center',
    },
    btnGroup: {
        fontSize: 14,
        fontWeight: '500',
        textAlign: 'center',
        height: 40,
        padding: '10px 16px 36px 16px',
        borderColor: 'transparent'
    },
    btnTabContainer: {
        backgroundColor: 'transparent',
        width: '100%',
        padding: '20px, 20px 0 20px',
    },
    titleContainer: {
        background: 'transparent',
        marginLeft: '-28px',
        padding: '32px 124px 0 124px',
    },
    children: {
        padding: '32px 42px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%'
    },
    titleDiv: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: ' 0 0 24px 0',
    },
    btnBack: {
        border: '1px solid #F5922F',
        borderRadius: 40,
        color: '#F5922F',
        fontSize: 16,
        // boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.102751)'
    },
};

export default UserHistory;