import React from 'react';
import * as Icon from '../../../static/icons/how_it_works/index';
import * as Img from '../../../static/images/how_it_works/index';
import ContentCard from '../content_card';


const SettingsStep = props => {
    
    const {
        segments, step
    } = props;


    let settings_icon = segments == 'me_' ? Icon.Settings_me_ : 
                                segments == 'gig' ? Icon.Settings_gig : 
                                    segments == 'sme' ? Icon.Settings_sme : 
                                        segments == 'biz' ? Icon.Settings_biz: ""
                                        

    return (
        <div>
            <ContentCard step={step}
                icon={settings_icon}
                image={Img.Settings}
                title="Settings"
                content={"This page allows you to perform configurations on fees, notifications, access, and others."} />
        </div>
    )
}

export default SettingsStep;