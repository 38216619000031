import React, { useState, useEffect } from "react";
import { Card, Typography, Spin, Button, Tag } from "antd";
import { history } from '../../../store/history';
import Lock from '../../../static/icons/lock.svg'

const AccountLimitCorporate = (props) => {
  const testMode = (history.location.pathname.indexOf('/test') > -1&&localStorage.getItem("userType") == "CO");

  return (
    <Card style={styles.root} bodyStyle={{padding: 0}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Typography style={styles.title}><strong>Funds Tracker</strong></Typography>
        <div style={{ backgroundColor: testMode? '#454545' : '#F5922F', 
                      borderRadius: 40, 
                      padding: '2px 8px', 
                      fontSize: 10, 
                      width: testMode? 76:64, color: '#FFFFFF',
                      textAlign: 'center' }}
        >{testMode? 'Test Mode':'Enterprise'} </div>
      </div>
      <hr style={styles.divider} />
      
        <div style={{marginTop: 32}} align="center">
            <img src={Lock} />

            <div style={{color: '#2b2d33', opacity: '0.5', fontSize: 14}}>
                This feature will be unlocked once your application is approved
            </div>
        </div>
    </Card>
  )
}

const styles = {
  root: {
    height: 198,
    borderRadius: 10,
    padding: '16px'
  },
  title: {
    fontSize: 14,
    color: '#2B2D33',
    textAlign: 'left',
  },
  subtitle: {
    fontSize: 14,
    color: '#000000',
    textAlign: 'left',
  },
  btn: {
    fontSize: 12,
    color: '#54575F',
    textAlign: 'center',
    padding: '10px 0px 16px 0px',
    width: '100%',

  },
  btnClicked: {
    daily: {
      backgroundColor: ''
    },
  },
  radioBtn: {
    width: '33%',
    height: 64,
    paddingTop: 16,
    display: 'flex',
    textAlign: 'center',
  },

  proText: {
    color: '#97979c',
    fontSize: 12
  },
  footer: {
    fontSize: 14,
    color: '#F5922F',
    margin: '0 0 0 0',
    fontWeight: '600',
    padding: localStorage.getItem('Level') == 0 ? '4px 0 0 0' : 0 
  },

  footerDisable: {
    fontSize: 12,
    color: '#97979c',
    margin: '0 0 0 0',
    fontWeight: '600',
    padding: '0px 4px'
  },
  spinner:{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: 94,
  },
  divider: {
    borderTop: '.2px solid #E6EAF0', 
    width: '100%', 
    margin: '9px 0'
  },
};

export default AccountLimitCorporate;