import {
    GET_CONTENT_BEGIN,
    GET_CONTENT_SUCCESS,
    GET_CONTENT_ERROR,
} from './contentConstants';

const initialState = {
    content: [],
    loading: false,
    error: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTENT_BEGIN:
            return {
                ...state, 
                loading: true,
                content: initialState.content
            };
        case GET_CONTENT_SUCCESS:
            return {
                ...state, 
                loading: false, 
                content: action.payload,
            };
        case GET_CONTENT_ERROR:
            return {...state, loading: false, error: action.payload, content: []};
        default:
            return state;
    }
}

export default reducer;