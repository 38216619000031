import React from 'react'
import axios from 'axios';
import {Input, Icon, Table, Button, Modal, Alert, Dropdown, Menu, Select, message, DatePicker} from 'antd'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {TextField, Grid, makeStyles} from '@material-ui/core';
import { useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {history} from '../../../store/history'
import '../../../static/css/antd.css'
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { Handle401 } from '../../handle401/handle401';

const dateFormat = 'MM-DD-YYYY';

function disabledDate(current) {
  // Can not select days before today and today
  return current < moment().startOf('day');
}


const GrantsView = props => {
  
    const dispatch = useDispatch();
    const { Option } = Select;

    const [modalOpen, setModalOpen] = React.useState(false);
    const [visible, setVisible] = React.useState(true);
    const [submissions, setSubmissions] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [userId, setUserId] = React.useState("");
    const [userRole, setUserRole] = React.useState("");
    const [start, setStart] = React.useState("");
    const [end, setEnd] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [ready, setReady] = React.useState(false);


    const useStyles = makeStyles(theme => ({
      container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginRight: '100%',
        width: 230,
      },
    }));


    const classes = useStyles();

    const handleOpenModal = () => {
      setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const info = () => {
      message.success("Successful");
    };


    const openModal = () => {
    setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const update = () => {


              
          let info = {
              userId: userId,
              target: userRole,
              start: start,
              end: end
          };

          updateSettings(info)
          console.log(info)

    }

    
    async function updateSettings (value) {
        let yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }
  
        let response = await axios.post(
          process.env.REACT_APP_API_URL + '/api/admin/users/grant/', value,yourConfig
        );
  
        console.log(response)
  
        if(response.data.status == "success"){
            window.location.reload()
        }
        else{
          setVisible(false)
          setMessage(response.data.message)
        }
    }

    useEffect(() => {

      if(localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") == null){
        history.push('/login')
      }
      else{
        if(localStorage.getItem("userType") == "ME"){
          history.push('/dashboard')
        }
        else if(localStorage.getItem("userType") == "PS"){
          history.push('/orders')
        }
        else if(localStorage.getItem("userType") == "PF"){
          history.push('/recon/7_eleven')
        }
        else if(localStorage.getItem("userType") == "MA"){
          history.push('/overview')
        }
        else{
          fetchSubmissions()
        }
        
      }
      
    }, [])

    const orderStyle = {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '40px'
      }
    const menuStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33',
        padding: '10px',
        margin: 'auto'
    }



    const columns = [
        {
          title: 'Username',
          dataIndex: 'user',
        },
        {
          title: 'Previous Role',
          dataIndex: 'prev_role',
        },
        {
          title: 'Target Role',
          dataIndex: 'target_role',
        },
        {
          title: 'Start',
          dataIndex: 'start',
        },
        {
          title: 'End',
          dataIndex: 'end',
        },
      ];

    const handleChangeDate1 = (date, dateString) => {
        setStart(dateString);
      }

    const handleChangeDate2 = (date, dateString) => {
        setEnd(dateString)
      }
    
    async function fetchSubmissions () {

      
        let yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }

        try{
          let response = await axios.get(
            process.env.REACT_APP_API_URL + '/api/admin/users/grant/',
            yourConfig
          );
    
          setSubmissions(response.data.access)
          setUsers(response.data.users)
          setReady(true)
        }

        catch(error){
          if((error.response)&&(error.response.status == 401)){
            Handle401()
          }
        }
  
        
    }
    return(
      <div style={{padding: '0 56px 64px 0'}}>
        <div className="twoCol">
          <div className="screen-title-text">
              Grants
          </div>
          <div>
              {/* <Button 
                  className="admin-download-btn"
                  onClick={handleOpenModal}>
                  <Icon type='download' />
                  Download CSV             
              </Button> */}

              <Button 
                onClick={handleOpenModal} 
                className="admin-download-btn"
                icon="plus">
                Add User
              </Button>

          </div>
        </div>

        <div className="table-card-div">
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
            dataSource={submissions}
            columns={columns} />
        </div>

        <Modal
          title="Add Access"
          centered
          visible={modalOpen}
          onCancel={closeModal}
          footer={[
              <Button key="back" onClick={closeModal} className="admin-download-btn right-8">
                Cancel
              </Button>,
              <Button key="submit" type="primary" onClick={update} className="add-user">
                Update
              </Button>,
            ]}
          >
            <div>User</div>
            <Select
                  showSearch
                  style={{ width: '100%', height: '40px' }}
                  size="large"
                  onChange={setUserId}
                  optionFilterProp="children"
              >
              {
                  users.map((item, i) => (
                  <Option value={item.id}>[{item.user_role} - {item.username}] ({item.first_name} {item.last_name})</Option>
                  ))
                }
            </Select>
            <div style={{ marginTop: 20}}>Target Role</div>
            <Select
                  showSearch
                  style={{ width: '100%', height: '40px' }}
                  size="large"
                  onChange={setUserRole}
                  optionFilterProp="children"
              >
              <Option value="AP">Approver</Option>
              <Option value="EN">Endorser</Option>
              <Option value="MA">Marketing</Option>
              <Option value="OP">Operations</Option>
              <Option value="SD">Sales Admin</Option>
            </Select>

            <div className="twoCol">
              <div>
                <div style={{ marginTop: 20}}>Start</div>                      
                <DatePicker
                  disabledDate={disabledDate} format={dateFormat} onChange={handleChangeDate1} />
              </div>
              
              <div>
                <div style={{ marginTop: 20}}>End</div>
                  <DatePicker
                  disabledDate={disabledDate} format={dateFormat} onChange={handleChangeDate2} />
                </div>
            </div>
        </Modal>
      </div>
    )
  }

export default GrantsView;