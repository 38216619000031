import React,{useEffect} from 'react'
import pricingStyle from '../../static/css/Pricing';
import PaymentMethod from '../../components/pricing/payment_method';
import PaymentChannel from '../../components/pricing/payment_channels';
import WebBanking from '../../components/pricing/web_banking';
import EWallet from '../../components/pricing/e-wallets';
import CreditDebit from '../../components/pricing/credit_debit'
import ShipmentFee from '../../components/pricing/shipment';
import {history} from '../../store/history'
import {isMobile} from 'react-device-detect'
import MetaTag from '../../components/meta_tag/meta_tag'
import { Button } from 'antd'
import { segmentStroke } from '../../components/constants/constants';
import ButtonGroup from 'antd/lib/button/button-group';

const Pricing = props => {
    
    const p = pricingStyle();

    useEffect(() => {

        let current_path = window.location.pathname

        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            history.push('/login?next='+current_path)
        }
        else{
            if(isMobile) {
                history.push('/mobile/pricing/')
            }
        }

    })

    const [selected, setSelected] = React.useState('Over-the-counter')
    const segments = localStorage.getItem('segments')
    const userType = localStorage.getItem('userType')

    const ButtonTab = () => {
        const pricingBtn = ['Over-the-counter', 'Online banking', 'E-wallet', 'Credit / Debit card', 'Payout']
        const handleClick = (event) => {
            setSelected(event.target.value);
        }

        const isHidden = (btn) => {
            if(userType === "CO" && btn === "Payout") {
                return true;
            }

            if(userType === "ME" && btn === "Credit / Debit card") {
                return true;
            }

            return false;
        }

        return(
            <div className="button-tab-width top-20">
                <ButtonGroup>
                    {
                        pricingBtn.map((btn, i) => {
                            return (
                                <Button
                                    hidden={isHidden(btn)}
                                    key={btn}
                                    value={btn}
                                    onClick={handleClick}
                                    style={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        height: 40,
                                        padding: '0 32px',
                                        color: btn === selected ? '#FFF' : '#2b2d32',
                                        backgroundColor: btn === selected ? segmentStroke[0] : '#FFF',
                                        borderRadius: 4
                                    }}
                                >
                                    {btn}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </div>
        )
    }
    return(
        <div className="top-48 bottom-32">
            <MetaTag title="Pricing"/>
            
            <div className={p.menu} align='center'>

                <div className={p.header} align='left'>
                    Pricing Table
                </div>

                <ButtonTab />

                <PaymentChannel segments={segments} hidden={selected !== "Over-the-counter"} />
                <WebBanking segments={segments} hidden={selected !== "Online banking"}/>
                <EWallet segments={segments} hidden={selected !== "E-wallet"}/>
                <CreditDebit segments={segments} hidden={selected !== "Credit / Debit card"}/>
                <PaymentMethod segments={segments} hidden={selected !== "Payout" }/>
                {/* <ShipmentFee /> */}
            </div>
        </div>
    )
}

export default Pricing;