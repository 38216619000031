import React from 'react'
import { Grid, Tooltip } from '@material-ui/core'
import { Modal, Input, message } from 'antd';
import accountStyles from '../../static/css/Account';
import * as Icon from '../../static/icons/bux_qr/index';

const CustomAmountModal = props => {

    const ac = accountStyles();
    const segments = localStorage.getItem('segments');
    const userType = localStorage.getItem("userType")
    const maxAmount = process.env.REACT_APP_MAXIMUM_AMOUNT ? process.env.REACT_APP_MAXIMUM_AMOUNT : 30000
    // const entMaxAmount = process.env.REACT_APP_MAXIMUM_AMOUNT ? process.env.REACT_APP_MAXIMUM_AMOUNT : 80000
    const minAmount = process.env.REACT_APP_MINIMUM_AMOUNT ? process.env.REACT_APP_MINIMUM_AMOUNT : 50
    const {
        customModal, setCustomModal, listAmount, amountLabel, setListAmount,
        setAmountLabel, removeIcon, gettingParams, getDetails
    } = props

    const [error, setError] = React.useState([true, '', ''])

    const addAmount = () => {
        setListAmount([...listAmount, "50"])
        setAmountLabel([...amountLabel, `Amount ${listAmount.length+1}`])
    }

    const removeAmount = (index) => {
        const list = [...listAmount]
        const labelList = [...amountLabel]
        list.splice(index, 1)
        labelList.splice(index,1)
        setListAmount(list)
        setAmountLabel(labelList)
    }

    const handleChangeLabel = (e, index) => {
        const { value } = e.target
        const list = [...amountLabel]

        if (value.length <= 10) {
            list[index] = value
        }
        setAmountLabel(list)
    }

    const handeleChangeAmount = (e, index) => {

        const { value } = e.target
        var numbers = /^[0-9]+$/;
        const list = [...listAmount]

        if (value.length <= 5) {
            list[index] = value
        }

        setListAmount(list)

        for (let i = 0; i < list.length; i++) {
            if (userType == "ME" && (parseFloat(list[i]) < minAmount || parseFloat(list[i]) > maxAmount)) {
                setError([false, 'Amount must be ₱ 50 up to ₱ 30, 000'], index)
                console.log(error)
                return
            }

            else if (userType == "CO" && parseFloat(list[i]) < minAmount) {
                setError([false, 'Amount must be ₱ 50 up to ₱ 80, 000'], index)
                console.log(error)
                return
            }

            // else if (!numbers.test(list[i])){
            //     setError([false, 'This field must numbers only', index])
            //     return
            // }

            else {
                setError([true, '', ''])
            }
        }
    }

    const onSave = () => {
        const set = new Set(listAmount);
        const amountHasDuplicates = set.size < listAmount.length;
        if (amountHasDuplicates) {
            message.error('Duplicate amount is not allowed');
        } else {
            gettingParams();
            setCustomModal(false);
        }
    }
    
    return (
        <Modal visible={customModal}
            onCancel={() => { setCustomModal(false); getDetails() }}
            footer={null}>
            <div align="center">
                <h1 style={{ marginTop: '32px' }}>Custom Amount List</h1>
                <Grid container>
                    {
                        listAmount && listAmount.map((item, key) => {
                            return (
                                <Grid item lg={6}>
                                    <div align='left'>
                                        <div className={ac.fieldPlacehold}>
                                            <Input size='small' 
                                                style={{border:'none',
                                                    fontSize: '12px',
                                                    lineHeight: '24px'
                                                }} 
                                                value={amountLabel[key]} 
                                                onChange={(e)=>handleChangeLabel(e, key)}/>
                                        </div>
                                        <div style={{ width: '98%' }}>
                                            <Input
                                                size="large"
                                                style={{ height: '40px' }}
                                                type="number"
                                                name="amount" value={item}
                                                addonBefore={'₱'}
                                                suffix={
                                                    <Tooltip title="Remove" placement="top" hidden={key === 0}>
                                                        <a onClick={() => removeAmount(key)}>
                                                            <img src={Icon.RemoveIcon} />
                                                        </a>
                                                    </Tooltip>
                                                }
                                                onChange={(e) => handeleChangeAmount(e, key)} />
                                            <div style={{ color: 'red' }} hidden={error[0] || error[2] != key}>{error[1]}</div>
                                        </div>
                                    </div>
                                </Grid>
                            )
                        })
                    }

                    <Grid item lg={6} hidden={listAmount && listAmount.length >= 8}>
                        <div align='left'>
                            <div className={ac.fieldPlacehold}>
                                Amount {listAmount && listAmount.length + 1}
                            </div>
                            <button onClick={() => addAmount()} className={`link-text--${segments}`} style={styles.addAmount}>+ Add New Amount</button>
                        </div>
                    </Grid>
                </Grid>


                <div style={styles.footerDiv}>
                    <button className={`outline-btn--${segments}`} style={{ ...styles.editBtn, margin: '0 4px' }} onClick={() => { setCustomModal(false); getDetails() }}>Cancel</button>
                    <button className={`btn--${segments}`}
                        disabled={!error[0]}
                        style={{ opacity: !error[0] ? 0.5 : '', cursor: !error[0] ? 'not-allowed' : 'pointer', width: 70, }}
                        onClick={onSave}>
                        Save
                    </button>
                </div>
            </div>
        </Modal>
    )
}

const styles = {
    link: {
        fontSize: 16,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0',
        maxWidth: 328,
        textDecoration: 'none',
        fontWeight: '500',
        cursor: 'pointer'
    },
    editBtn: {
        cursor: 'pointer',
        height: 34,
        padding: '0 12px',
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        // width: 174,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    footerDiv: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    addAmount: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '16px',
        border: '1px solid #d1d5dd',
        width: '100%',
        height: '40px',
        outline: '0',
        cursor: 'pointer',
        background: '#fff',
        borderRadius: '4px'
    }
}

export default CustomAmountModal;

