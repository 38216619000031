import React from 'react';
import {Graph, KYB, KYC, EON} from '../../../../../static/icons/dashboard_icons'

const AcquisitionAnalytic = ({data, isLoading}) => {

  const isMac = window.screen.width * window.devicePixelRatio === 1440 && window.screen.height * window.devicePixelRatio === 900;

  const applications = [
    {
      title: 'Acquisition Analytics',
      icon: Graph,
      row_1: data && data.all_users,
      row_2: data && data.verified_signups,
      row_3: data && data.logins,
      row_4: data && data.non_verified,
    },
    {
      title: 'KYC Application',
      icon: KYC,
      row_1: data && data.total_kyc,
      row_2: data && data.approved_kyc,
      row_3: data && data.total_pending_kyc,
      row_4: data && data.rejected_kyc,
    },
    {
      title: 'KYB Application',
      icon: KYB,
      row_1: data && data.total_kyb,
      row_2: data && data.approved_kyb,
      row_3: data && data.total_pending_kyb,
      row_4: data && data.rejected_kyb,
    },
    {
      title: '',
      icon: EON,
      row_1: data && data.total_eon,
      row_2: data && data.approved_eon,
      row_3: data && data.total_pending_eon,
      row_4: data && data.rejected_eon,
    },
  ]

  return (
    <div className="user-submission-div">
      <div className="twoCol">
      {
        applications.map((item,i)=> {
          return(
            !isLoading && 
            <div key={i}>
              <div className="card-style" style={{
                width: isMac ? '18vw' : ''
              }}>
                  <div className="card-title">
                    <img src={item.icon} alt="#"/>
                    <div style={{margin: '4px 0px 0px 10px'}}>{item.title}</div>
                  </div>
                  <div style={{marginTop: 4}}>
                    <div className="twoCol top-16">
                      <div className="card-text">
                        {i === 0 ? "Total User:" : "Total Application:"}
                      </div>
                      <div className="card-text">
                        {item.row_1}
                      </div>
                    </div>
                    <div className="twoCol top-16">
                      <div className="card-text">
                        {i === 0 ? "Verified Users:" : "Approved:"}
                      </div>
                      <div className="card-text success-color">
                        {item.row_2}
                      </div>
                    </div>
                    <div className="twoCol top-16">
                      <div className="card-text">
                        {i === 0 ? "Active Users:" : "Pending:"}
                      </div>
                      <div className={`card-text ${i === 0 ? "success-color" : "pending-color"}`}>
                        {item.row_3}
                      </div>
                    </div>
                    <div className="twoCol top-16">
                      <div className="card-text">
                        {i === 0 ? "Non Verified:" : "Rejected"}
                      </div>
                      <div className="card-text rejected-color">
                        {item.row_4}
                      </div>
                    </div>
                  
                  </div>

              </div>
            </div>
          )
        })
      }
      </div>
    </div>
  )
}

export default AcquisitionAnalytic;