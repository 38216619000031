import React from 'react';
import { Modal } from 'antd';
import moment from 'moment'

const SaveModal = props => {
    const {
        modalState, setModalState, addPromoCode, updatePromoCode, params, type
    } = props;

    const savePromo = () => {
        if(params['banner_blob'] != "") {
            const formData = new FormData()
            for(let key in params) {
                
                if(key === 'banner_blob') {
                    if(params['banner_blob'] instanceof Blob){
                        formData.append(key, params[key], 'promo_banner.jpg')
                    }
                }
                else if(key === 'start_date' || key === 'end_date'){
                    formData.append(key, moment(params[key]).format('MM-DD-YYYY'))
                }
                else{
                    formData.append(key, params[key])
                }
            }
            if(type == 'add' || type == 'draft'){
                addPromoCode(formData)
            }
            else{
                updatePromoCode(formData)
            }
        }
        else{
            if(type == 'add' || type == 'draft'){
                addPromoCode(params)
            }
            else{
    
                params.start_date = moment(params.start_date).format('MM-DD-YYYY')
                params.end_date = moment(params.end_date).format('MM-DD-YYYY')
    
                updatePromoCode(params)
            }
        }
        
    }


    return(
        <Modal visible={modalState}
            centered
            onCancel={() => setModalState(false)}
            footer={null}>
            <div>
                <div className="modal-header-text">
                    {
                        type == 'draft' ?
                        'Save as draft?'
                        :
                        'Save this promo'
                    }
                </div>
                
                {
                    type == 'add' &&
                    <div className="modal-sub-text top-32">
                        If you confirm, this will be saved on your promo list.
                    </div>
                }

                <div className="top-40" align="right" onClick={() => setModalState(false)}>
                    <button className="outline-btn--nil btn-height right-16">
                        Back
                    </button>
                    <button className="btn--nil btn-height" onClick={() => savePromo()}>
                        Confirm
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default SaveModal;