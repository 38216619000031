import axios from 'axios';
import {Handle401} from '../../handle401/handle401';

const authorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const endorsementServices = {
  getPayout: async (search, page, type, accountType, start, end, summary) => {
    try {
      const res = await authorizedAxiosRequest().get(`api/admin/payouts/?page=${page}&page_size=10&search=${search}&status=${type}&start=${start}&end=${end}&mode=All&user_role=${accountType}&summary=${summary}`)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getOrders: async (search, page, type, paymentMethods, accountType, start, end, summary, token) => {
    try {
      const res = await authorizedAxiosRequest().get(`/api/orders/?type=${type}&page=${page}&page_size=10&start=${start}&end=${end}&summary=${summary}&search=${search}&payment_methods=&user_type=${accountType}&is_export=&token=${token}`);
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getEndorsements: async (search, type, start, end) => {
    try {
      const res = await authorizedAxiosRequest().get(`/api/payment_payout_approval/?search=${search}&type=${type}&user_type=&start=${start}&end=${end}`)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  endorse: async (params) => {
    try {
      const res = await authorizedAxiosRequest().post(`/api/payment_payout_approval/`, params)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  updateEndorsementStatus: async (status) => {
    try {
      const res = await authorizedAxiosRequest().put(`/api/payment_payout_approval/`, status)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
}

export default endorsementServices;