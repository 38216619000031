import React, {useEffect} from 'react';
import axios from 'axios'
import enterpriseStyle from '../../../static/css/Enterprise';
import UploadIDImg from '../../../static/img/upload_id.svg'
import {Input, Upload, Button, message, Modal, Card} from 'antd';
import {UploadLogo} from '../../../static/icons/index'
import tipsImage from '../../../static/img/tipsImage.svg'
import * as Style from '../../complete_profile/complete_profile_style'
import ESignatory from './esignatory'

const max_width = 2000;
const max_height = 2000;

const SignatoryInformation = props => {


    const es = enterpriseStyle();

    const [step, setStep] = React.useState()
    const [signatory, setSignatory] = React.useState('');
    const [designation, setDesignation] = React.useState('');
    const [tipsModal, setTipsModal] = React.useState(false)
    //personal Photo
    const [sigFile, setSigFile] = React.useState("")
    const [sigFileURL, setSigFileURL] = React.useState("")

    let sigUpload = React.useRef()


    const handleUploadImage = (info,type) =>{
        var reader = new FileReader();
        reader.readAsArrayBuffer(info);
        
        reader.onload = function (event) {
        // blob stuff
        var blob = new Blob([event.target.result]); // create blob...
        window.URL = window.URL || window.webkitURL;
            
            if(type == 'sigID'){
                setSigFile(info)
                setSigFileURL(window.URL.createObjectURL(blob))
            }
    
        }
    }

    async function submitStep3(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        
        const formData = new FormData();

 
            //numbers
            formData.append('authorized_signatory', signatory)
            formData.append('designation', designation)
            if(sigFile != "" && sigFileURL != ""){
                formData.append('signatory_id_attachment', sigFile)
            }
            formData.append('step', props.step == 8 ? 9 : 4)
        
        try{
            let response = await axios.put(process.env.REACT_APP_API_URL + "/api/kyb/", formData,yourConfig)
            console.log(response.data)
            props.addStep()

        }
        catch(error){
            message.error("Unable to proceed. Please try again");
        }
    }

    async function fetchProfile(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let userId = localStorage.getItem('userId')

        let response = await axios.get(
            process.env.REACT_APP_API_URL + `/api/kyb/${userId}`,yourConfig)

            console.log(response.data)

            setSignatory(response.data.authorized_signatory)
            setDesignation(response.data.designation)
            setSigFileURL(response.data.signatory_id_attachment)

    }

    useEffect(() => {
        fetchProfile()
    }, [])


    return(
        <div hidden={props.step != 3 && props.step != 8}>

            <Modal
                visible={tipsModal}
                onCancel={()=>setTipsModal(false)}
                footer={null}
                >
                <div style={Style.modalBodyTips} align='center'>
                    <img src={tipsImage} />
                    <div style={Style.tipsStyle}>Tips in Uploading ID</div>
                    <ul align='left' style={{marginTop: 16}}>
                        <li style={{paddingBottom: 16, wordWrap: 'break-word'}}>The Personal information you entered should be the same with your ID.</li>
                        <li style={{paddingBottom: 16, wordWrap: 'break-word'}}>Make sure that your ID is readable and clear and has no glare before submission.</li>
                        <li style={{paddingBottom: 16, wordWrap: 'break-word'}}>Make sure that your ID is up to date and not expired.</li>
                        <li style={{paddingBottom: 16, wordWrap: 'break-word'}}>List of valid IDs
                            <div style={{padding: '16px 0px 0px 16px'}}>Driver’s License, Passport, SSS or UMID,
                            GSIS, PRC ID, IBP ID,
                            OWWA ID, Diplomat ID,OFW ID,Senior Citizen ID,Voters ID, Govt Office ID,
                            Digitized Postal ID</div>
                        </li>
                    </ul>

                    <button style={Style.submitBtnStyle} onClick={() => setTipsModal(false)}> Got It </button>

                </div>
            </Modal>
         
            <div className={es.biMain} >
                <div className={es.biHeader}>Signatory Information</div>
                
                <div className={es.biPlaceholder}>
                    Authorized Signatory Name
                </div>
                
                <div className={es.biInput}>
                    <Input size="large" value={signatory} onChange={e => setSignatory(e.target.value)}/>
                </div>

                <div className={es.biPlaceholder}>
                    Designation
                </div>
                
                <div className={es.biInput}>
                    <Input size="large" value={designation} onChange={e => setDesignation(e.target.value)}/>
                </div>

                <div className={es.biSocMedBtn}>
                    <div>
                        <div style={{fontSize: 16}}>Signatory Photo</div>
                    </div>
                    <Card 
                        bodyStyle={{
                            padding:'15px 10px 15px 10px'
                        }}
                        style={{
                            width: '49%',
                            marginRight: '2%'
                        }}>

                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>

                            {sigFileURL ? <img src={sigFileURL} alt="avatar" style={{ width: '100%' }}/> : <img src={UploadIDImg}/>}
                            
                            </div>

                            <input type="file"
                                ref={sigUpload}
                                style={{display: 'none'}}
                                onChange={(e)=>handleUploadImage(e.target.files[0], "sigID")}
                                accept=".jpg,.png,.jpeg"
                                >
                            </input>

                            <Button style={Style.takeSelfieStyle} onClick={()=>sigUpload.current.click()}>{sigFileURL ? 'Replace' : 'Valid ID of Owner'}</Button>
                            <div style={Style.textStyle2}>
                                You can check here the list of valid IDs.
                            </div>
                    </Card>
                </div>

            </div>
            
            <ESignatory step={props.step} addStep={props.addStep} setBack={props.setBack}/>


            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <button className={es.Back} onClick={() => props.setBack()}>Back</button>
                </div>

                <div>
                    <button className={es.submit1} onClick={props.addStep} onClick={() => submitStep3()}
                    style={{opacity: signatory == null || designation == null || sigFileURL == null || signatory == "" || designation == "" || sigFileURL == "" ? "0.5" : "",
                    cursor: signatory == null || designation == null ||sigFileURL == null || signatory == "" || designation == "" || sigFileURL == ""
                     ? "not-allowed" : "pointer"}}>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default SignatoryInformation;