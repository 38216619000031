import React from 'react';
import reconServices from '../reconServices';
import reconActions from '../reconActions';
import { Dropdown, Icon, Menu, Table, message, Pagination, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

const ReconTable = ({data, onDeleted, isLoading, length, selected, sort}) => {
  const currentPage = useSelector(state => state.fraud.tablePage);
  const [minPage, setMinPage] = React.useState(0);
  const [maxPage, setMaxPage] = React.useState(20);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [columns, setColumns] = React.useState(null);
  const [showDelModal, setShowDelModal] = React.useState(false);
  const [keyTodelete, setKeyToDelete] = React.useState([]);

  const accountedColumns = [
    {
            title: 'Ref Code',
            render:(record) => {
                return(
                  <div style={{fontWeight: 'bold'}}>
                      {record.ref_code}
                  </div>
                )
              },
        },
        {
            title: 'Total Amount',
            render:(record) => {
                return(
                    <div style={{ color: record.payment_method == 'REFUND' ? '#e24c4c' : '#000000'}}>Php {record.amount.toFixed(2)}</div>
                )
            } 

        },
        {
            title: 'Receivable',
            render:(record) => {
                return(
                    <div style={{ color: record.payment_method == 'REFUND' ? '#e24c4c' : '#000000'}}>Php {record.receivable.toFixed(2)}</div>
                )
            }

        },
        {
            title: 'Transaction Date',
            dataIndex: 'transaction_date'

        },
        {
            title: 'Settlement Date',
            dataIndex: 'settlement_date'
        },
  ]

  const unaccountedColumns = [
    {
            title: 'Ref Code',
            render:(record) => {
                return(
                  <div style={{fontWeight: 'bold'}}>
                      {record.ref_code}
                  </div>
                )
              },
        },
        {
            title: 'Total Amount',
            render:(record) => {
                return(
                    <div style={{ color: record.payment_method == 'REFUND' ? '#e24c4c' : '#000000'}}>Php {record.amount.toFixed(2)}</div>
                )
            } 

        },
        {
            title: 'Receivable',
            render:(record) => {
                return(
                    <div style={{ color: record.payment_method == 'REFUND' ? '#e24c4c' : '#000000'}}>Php {record.receivable.toFixed(2)}</div>
                )
            }

        },
        {
            title: 'Transaction Date',
            dataIndex: 'transaction_date'

        },
  ]

  const success = (msg) => {
    message.success(msg);
  };

  const errorPrompt = (msg) => {
    message.error(msg);
  };

  const handleDelete = async (id) => {
    // setLoading(true);
    // try {
    //   const res = await fraudServices.deleteBlacklist(selected==='Individual Account'?'kyc':'kyb',{target_id:id})
    //   if(res.status==='success'){
    //     success(res.message);
    //     onDeleted();
    //   } else {
    //     errorPrompt(res.message);
    //   }
    //   setLoading(false);
    // } catch (error) {
    //   console.log(error);
    //   setLoading(false);
    //   errorPrompt(error);
    // }
  }

  const handleDeleteRow = (id) => {
    setKeyToDelete(id);
    setShowDelModal(true);
  }

  const confirmDeleteRow = () => {
    handleDelete(keyTodelete);
    setShowDelModal(false);
  }

  const handleChangePage = async (page) => {
    // setLoading(true);
    // try {
    //   const res = await fraudServices.getBlacklist('', page, returnType(), sort);
    //   if(res&&res.results){
    //     dispatch(fraudActions.setTableData(res.results));
    //   } 
    //   setLoading(false);
    // } catch (error) {
    //   console.log(error);
    //   setLoading(false);
    // }
  }

  const returnType = () =>  {
    switch (selected) {
      case 'Accounted':
        return 'accounted';
      case 'Unaccounted':
        return 'unaccounted';
      default:
        return 'accounted';
    }
  }

  const changePage = (page, pageSize) => {
    setMaxPage(page * pageSize)
    setMinPage((page - 1) * pageSize)
    // dispatch(fraudActions.setTablePage(page));
    handleChangePage(page);
  }

  React.useEffect(() => {
    setLoading(true);
    setColumns([]);
    setTimeout(()=> {
      setColumns(selected==='Accounted'?accountedColumns:unaccountedColumns);
      setLoading(false);
    },100)
  },[selected])

  return (
    <div>
      <Table 
        rowClassName="table-row-light"
        columns={columns}
        dataSource={data}
        pagination={{pageSize:20}}
        loading={isLoading || loading}
        pagination={false}
      />
      <Pagination
        size='small'
        total={length}
        defaultPageSize={20}
        defaultCurrent={1}
        current={currentPage}
        onChange={(page, pageSize) => changePage(page, pageSize)}
        style={{padding:'12px 0 8px 0', textAlign: 'right'}}
      />
    </div>
  )
}

export default ReconTable;