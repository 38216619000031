import React from 'react'
import Avatar from '../../static/images/signup_and_login/login_avatar.png'
import BackgroundImage from '../../static/images/signup_and_login/bg.png'
import NewBuxLogo from '../../static/images/signup_and_login/new_bux_logo.svg'
import { isMobile } from 'react-device-detect';
import BuxModal from '../../components/BuxModal/modal';
import { MetaTags } from 'react-meta-tags';

const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
const isMac = window.screen.width * window.devicePixelRatio == 1440 && window.screen.height * window.devicePixelRatio == 900;

const LoginAndSignUp = (props) => {

    const {children} = props

    return(
        <div>
            <MetaTags>
                <title>Bux | All-in-One Payment Gateway for Online Sellers, SMEs</title>
                <meta
                    name="description"
                    content="Bux | All-in-One Payment Gateway for Online Sellers, SMEs"
                />
                <meta property="og:description" 
                    content="Send and receive payments through over-the-counter channels, online banking and e-wallets with Bux, the Philippines’ leading payment gateway."/>
                <meta property="og:image" content="%PUBLIC_URL%/buxcover.jpg" />

            </MetaTags>
            <BuxModal/>
            <div style={style.mainDiv}>
                <div style={{height: '90vh'}} hidden={isMobile}>
                    <img src={NewBuxLogo} alt="#" style={{height: !isHighReso && '35px'}}/>
                    <div style={{...style.titleText, marginTop: '24px'}}>All-in-One</div>
                    <div style={style.titleText}>Digital Banking Platform</div>
                    <img src={Avatar} style={style.avatarStyle}/>
                </div>
                <div style={{height: '100%', marginTop: !isMobile && '-2%', width: isMobile && '100%'}}>
                    {children}
                </div>
            </div>
        </div>
        
    )
}

const style  = {
    
    mainDiv:{
        padding: isMobile ? '0px' : isHighReso ? '90px 80px 0px 80px' : '50px 60px 0px 60px',
        width: '100%',
        height: '100vh',
        background:`url(${BackgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100vh",
        display: 'flex', 
        justifyContent: 'space-between' 
    },
    titleText:{
        fontFamily: 'Montserrat',
        color: '#FFFFFF',
        fontSize: isHighReso ? '40px' : '30px',
    },
    avatarStyle:{
        height: '70%', 
        marginLeft: !isMac && '16%', 
        marginTop: isHighReso ? '30px' : '20px',
    }
}

export default LoginAndSignUp