import React from 'react'
import {Table, Menu, Dropdown, Icon} from 'antd'
import DeleteScheduleModal from '../modal/delete_schedule_modal';
import recurringServices from '../recurringServices';
import { history } from '../../../store/history';

const ScheduleTable = (props) => {

    const {data, fetchData} = props;
    const [deleteModal, showDeleteModal] = React.useState(false)
    const [deleteData, setDeleteData] = React.useState(false)
    const [summary, setSummary] = React.useState([])
    const testMode = (history.location.pathname.indexOf('/test') > -1 && localStorage.getItem("userType") == "CO")


    let actionMenu = (record) => (

        <Menu style={{ width: 'auto' }}>
          <Menu.Item key="0" onClick={() => openDeleteModal(record)} style={{ color: '#FF0000' }}>
            Delete scheduled request
          </Menu.Item>
        </Menu>
    );


    function openDeleteModal(record) {
        setDeleteData(record)
        showDeleteModal(true)
    }  

    const columns = [
        {
            title: 'Name',
            dataIndex: 'buyer_name',
        },
        {
            title: 'Email',
            dataIndex: 'buyer_email',
            
        },
        {
            title: 'Mobile No.',
            dataIndex: 'buyer_phone',
            render: (text) => <div>0{text}</div>
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
        },

        {
            title: 'Frequency',
            dataIndex: 'cycle',
        },
        {
            title: 'No. of Request',
            dataIndex: 'number_of_request',
        },
        {
            title: 'Date',
            dataIndex: 'trigger_date',

        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => (
                <div>
                  <Dropdown overlay={() => actionMenu(record)}
                    placement="bottomCenter" trigger={['click']} >
                    <Icon type="ellipsis" />
                  </Dropdown>
                </div>
              )

        }
    
    ];

    return(
        
        <div>
            <Table
                pagination={false}
                dataSource={data} 
                columns={columns}/>
            <DeleteScheduleModal deleteVisible={deleteModal}
                closeModal={()=>showDeleteModal(false)}
                fetchData = {fetchData}
                cancelVisible={deleteModal}
                deleteData={deleteData}/>
        </div>
        
    )
}

export default ScheduleTable
