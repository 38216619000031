import React from 'react';
import accountSettingsServices from '../../services/account_settings';
import NewUserModal from './new_user_modal';
import PermissionsModal from '../../components/AdminComponents/userAccount/edit_permissions_modal';
import { Typography, Divider, Card, Input, Icon, List, Menu, Dropdown, Switch, Modal, message } from 'antd';
import { history } from '../../store/history';

const sampleData = [
    {
        name: 'Sam Smith',
        email: 'samSmith@smith.com',
        mobile: '+639651588702',
        status: true,
    },
    {
        name: 'Marc Horn',
        email: 'alins@smith.com',
        mobile: '+639651588702',
        status: false,
    }
]

const UserAccess = (props) => {
    const segments = localStorage.getItem('segments');
    const userType = localStorage.getItem('userType');
    const pUid = userType == 'CO' || userType == "ME" ? '' : props && props.props && props.props.match.params.id;
    const [userData, setUserData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [confirmModal, setConfirmModal] = React.useState(false);
    const [selectedData, setSelectedData] = React.useState('');
    const [mode, setMode] = React.useState('');
    const [searchVal, setSearchVal] = React.useState('');
    const [showNewUserModal, setShowNewUserModal] = React.useState(false);
    const [isPermissionsModalVisible, setIsPermissionsModalVisible] = React.useState(false);
    const testMode = (history.location.pathname.indexOf('/test') > -1);

    const TranxCard = (props) => {
        const { data, selected, onRefund, onConfirm, isLoading } = props;
        return (
            <List
                // loading={!data}
                dataSource={userData && userData.length > 0 ? renderSingleFields(searchVal) : []}
                renderItem={item =>
                    <div>
                        <TransactionCards data={{ ...item }} selected={selected} onRefund={() => onRefund()} mode={mode} onConfirm={onConfirm} isLoading={isLoading} />
                    </div>
                }
            />
        )
    }


    const ConfirmModal = (props) => {
        const { visible, onClose, onConfirm, data, mode } = props;

        return (
            <Modal
                maskClosable={false}
                closable={false}
                visible={visible}
                footer={false}
                width={382}
                destroyOnClose
                style={{ borderRadius: 8 }}
                onCancel={onClose}
            >
                <Typography style={styles.msg}>
                    {data && data.suspended ? 'Reactivate account?' : 'Deactivate account?'}
                </Typography>
                <div style={styles.btnDiv}>
                    <button className={`outline-btn--${segments}`} style={styles.cancelBtn} onClick={onClose} >Cancel</button>
                    <button className={`btn--${segments}`} style={styles.continueBtn} onClick={() => onConfirm(mode)} >Confirm</button>
                </div>
            </Modal>
        )
    }

    const TransactionCards = (props) => {
        const { data, selected, onRefund, onConfirm, isLoading } = props;

        const menu = (data) => (
            <Menu>
                <Menu.Item hidden onClick={() => { setSelectedData(data); setMode('settlement'); setConfirmModal(true); }}>
                    {data.can_view_settlement_report ? 'Stop settlement reports' : 'Subscribe to Settlement Reports'}
                </Menu.Item>
                <Menu.Item onClick={() => { setSelectedData(data); setMode('suspend'); setConfirmModal(true); }}>{data.suspended ? 'Reactivate Account' : 'Deactivate Account'}</Menu.Item>
            </Menu>
        )

        return (
            data ?
                <Card size='small' style={styles.card} bodyStyle={{ padding: 0 }} loading={isLoading}>
                    <div style={styles.upper}>
                        <div style={{ textAlign: 'left' }}>
                            <Typography style={styles.dataName} >{data.full_name}</Typography>
                            <Typography style={{ ...styles.normalFont, opacity: '.8' }} >{data.email}</Typography>
                            <Typography style={{ ...styles.normalFont, opacity: '.8' }} >{data.contact}</Typography>
                        </div>
                        <div style={styles.actionDiv}>
                            <Typography style={{
                                ...styles.normalFont,
                                borderRadius: 4, padding: '4px 8px', height: 32, border: !data.suspended ? '1px solid #62CE90' : '1px solid #E77352',
                                backgroundColor: !data.suspended ? '#E4F7EC' : '#F6E9E7'
                            }}>
                                {!data.suspended ? 'Active' : 'Inactive'}
                            </Typography>
                            <Dropdown overlay={() => menu(data)} placement="bottomLeft" trigger={['click']} >
                                <a style={styles.link}>...</a>
                            </Dropdown>
                        </div>
                    </div>
                    <div style={styles.lower}>
                        <Typography style={styles.normalFont}>Settlement Report</Typography>
                        <Switch size='small' className={data && data.can_view_settlement_report && `switch-btn--${segments}`}
                            checked={data.can_view_settlement_report}
                            // onClick
                            onChange={() => { setSelectedData(data); setMode('settlement'); onConfirm('settlement', data); }} />
                    </div>
                </Card>
                : null
        )
    }

    const onConfirm = async (mode, data) => {
        let params
        if (mode === 'settlement') {
            params = {
                alias_id: data && data.id,
                action: data && data.can_view_settlement_report ?
                    'stop_settlement_reports' : 'subscribe_settlement_reports'
            }
        } else if (mode === "suspend") {
            params = {
                alias_id: selectedData && selectedData.id,
                action: selectedData && selectedData.suspended ?
                    'reactivate' : 'suspend'
            }
        }
        activationUserToggle(mode, params);
    }


    const activationUserToggle = async (mode, params) => {
        if (localStorage.getItem('userType') !== "CO") {
            params.parent_id = pUid
        }
        try {
            const res = await accountSettingsServices.suspendChildToggle(params);
            setConfirmModal(false);

            if (mode === 'settlement') {
                if (res && res.status === 'failed') {
                    message.error(res.message, 2);
                } else {
                    message.info(res, 2)
                }
            } else if (mode === 'suspend') {
                if (res && res.status === 'failed') {
                    message.error(res.message, 2);
                } else {
                    message.info(res, 2)
                }
            }
            getUserAccessData();
        } catch (error) {

        }
    }

    const renderSingleFields = (val) => {
        if (!val) {
            return userData;
        }

        return userData.filter(data => data.full_name.toLowerCase().includes(val.toLowerCase()) ||
            data.email.toLowerCase().includes(val.toLowerCase()) ||
            data.contact.toLowerCase().includes(val.toLowerCase()));
    }

    const patchUserDetails = async () => {
        try {

            let params = {
                alias_id: selectedData && selectedData.id,
                action: selectedData && selectedData.can_view_settlement_report ?
                    'stop_settlement_reports' : 'subscribe_settlement_reports'
            }
            if (localStorage.getItem('userType') !== "CO") {
                params.parent_id = pUid
            }

            const res = await accountSettingsServices.suspendChildToggle(params);
            setConfirmModal(false);
            message.info(res.message, 2);
            getUserAccessData();
        } catch (error) {

        }
    }

    const addChildAccount = async (params) => {
        setLoading(true);
        try {
            const res = await accountSettingsServices.addChildAccount(params);
            if (res.status == "failed") {
                message.error(res.message)
            } else {
                message.success("User added successfully")
                setShowNewUserModal(false);
            }
        } catch (error) {
            message.destroy();
            message.error('An error has occured. Please try again later.')
            console.log(error);
            setShowNewUserModal(false);
        }
    }

    const getUserAccessData = async () => {
        setLoading(true);
        try {
            const res = await accountSettingsServices.getUserAccessData(pUid === '' ? null : pUid);
            setUserData(res);

        } catch (error) {

        }
        setLoading(false);
    }

    const pageRedirect = () => {
        if(segments.includes('me_','sme' || userType === 'SW' || testMode )) {
            window.location.hash = '#my_checkout';
        }
    }

    React.useEffect(() => {
        pageRedirect();
        getUserAccessData();
    }, [])

    return (
        <Card
            style={styles.root}
            bodyStyle={styles.cardBody}>
            <Typography style={styles.title}>User Access</Typography>
            <div style={styles.searchDiv}>
                <Input
                    placeholder="Search"
                    style={styles.inputSearch}
                    suffix={<Icon type="search" style={{ fontSize: '18px', marginRight: 6 }} />}
                    onChange={(e) => setSearchVal(e.target.value)}
                    value={searchVal}
                />
                <button className={`btn--${segments}`} style={styles.addBtn} onClick={() => setShowNewUserModal(true)} >
                    <Icon type='plus' style={{ paddingRight: 8 }} />Add new user</button>
            </div>
            <Divider />
            <div style={{ textAlign: 'right' }}>
                <button className={`outline-btn--${segments}`} style={styles.editBtn} onClick={() => history.push('/edit_permissions')} >
                    <Icon type='edit' theme='filled' style={{ paddingRight: 8 }} />Edit permissions
                </button>
                <TranxCard onConfirm={onConfirm} isLoading={loading} />
            </div>
            <ConfirmModal visible={confirmModal} onClose={() => setConfirmModal(false)}
                onConfirm={(mode) => onConfirm(mode)} data={selectedData} mode={mode} />
            <NewUserModal
                id={pUid}
                getCorporate={getUserAccessData}
                showModal={showNewUserModal}
                closeModal={() => setShowNewUserModal(false)}
                submitForm={addChildAccount}
            />
            <PermissionsModal
                isPermissionsModalVisible={isPermissionsModalVisible}
                setIsPermissionsModalVisible={setIsPermissionsModalVisible}
                userData={userData}
                pUid={pUid}
                getCorporate={getUserAccessData}
                disabled={userData && !userData.length}
            />
        </Card>
    )
}

const styles = {
    root: {
        padding: 0,
        width: '100%'
    },
    cardBody: {
        padding: 0
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#2B2D32',
        padding: '32px 32px 0 32px'
    },
    addBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: '40%',
        cursor: 'pointer',
        margin: 0
    },
    searchDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px 32px 0 32px',
        width: '100%'
    },
    inputSearch: {
        width: '200px',
        height: '40px',
        borderRadius: '4px',
        padding: '0 8px 0 0px',
        width: '60%'
    },
    editBtn: {
        cursor: 'pointer',
        height: 40,
        margin: '0 32px',
        padding: '0 12px'
    },


    // cards
    card: {
        margin: '16px 0',
        borderRadius: 4,
        padding: 0,
        margin: '20px 32px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
    },
    upper: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px'
    },
    link: {
        color: '#000000',
        fontSize: 24,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'flex-end',
        height: 18,
        opacity: '.5',
        margin: '8px 0 0 8px'
    },
    dataName: {
        color: '#2B2D32',
        fontSize: 16,
        fontWeight: '500'
    },
    lower: {
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: '1px solid #D1D5DD',
        padding: '10px 16px 16px 16px'
    },
    normalFont: {
        color: '#2B2D32',
        fontSize: 14,
    },
    actionDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    msg: {
        color: '#2B2D33',
        fontSize: 16,
        textAlign: 'left',
        padding: '16px 0 32px 0'
    },
    cancelBtn: {
        cursor: 'pointer',
        margin: '0px 6px',
        height: 40,
        padding: '0 12px'
    },
    continueBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        cursor: 'pointer',
        width: 78,
        margin: 0
    },
    btnDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
}

export default UserAccess;