import React,{ useState } from 'react';
import { Modal, Typography, Button, Icon } from 'antd';

const ApplyModal = (props) => {
  const [btnStyle, setBtnStyle] = useState({color: '#F5922F', backgroundColor: '#FFF' })

  const handleMouseOver = () => {
    setBtnStyle({
      backgroundColor: '#F5922F',
      color: '#FFF'
    })
  }

  const handleMouseLeave = () => {
    setBtnStyle({
      backgroundColor: '#FFF',
      color: '#F5922F'
    })
  }

  return (
    <Modal 
      visible={props.visible} 
      bodyStyle={styles.root} 
      footer={null}
      centered
      width={360}
      onCancel={props.closeModal}
      closeIcon={<Icon type="close" style={{color:'#F5922F'}}/>}
    >
      <Typography style={styles.title}>Apply Filter?</Typography>
      <div style={styles.mainBtn}>
        <Button style={{...styles.btn, borderColor: '#F5922F', ...btnStyle}} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} onClick={props.closeModal}>
          Cancel
        </Button>
        <Button style={{...styles.btn, color: '#FFF', backgroundColor: '#F5922F', borderColor: '#F5922F'}} onClick={() => {props.onApply(); props.closeModal();}}>
          Apply
        </Button>
      </div>
    </Modal>
  )
}

const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 24,
    color: '#2B2D33',
    fontWeight: '300',
    textAlign: 'left',
    padding: '24px 0 10px 0'
  },
  msg: {
    fontSize: 14,
    color: '#000',
    textAlign: 'left',
    padding: '10px 0'
  },
  mainBtn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 10,
  },
  btn: {
    height: 48,
    width: '45%',
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 10,
  },

}

export default ApplyModal;