import React from "react";
import { Button, Input, Alert } from "antd";
import { history } from "../../../store/history";
import { useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";
import { showModal } from "../../../components/BuxModal/modalAction";
import axios from "axios";
import BuxModal from "../../../components/BuxModal/modal";

import NewBuxLogo from "../../../static/images/signup_and_login/new_bux_logo2.svg";

const isHighReso =
  window.screen.width * window.devicePixelRatio >= 1440 &&
  window.screen.height * window.devicePixelRatio >= 900;
const isSubDomain = process.env.REACT_APP_ROOT_URL != window.location.hostname;

const ForgotPassword = (props) => {
  const dispatch = useDispatch();

  const initEmail =
    Object.keys(props).length && props.match ? props.match.params.email : "";
  const testMode = history.location.pathname.indexOf("/test") > -1;
  let remove_part = `.${process.env.REACT_APP_SUB_URL}`;
  let trade_name = window.location.hostname.replace(remove_part, "");

  const [email, setEmail] = React.useState(initEmail);
  const [alert, setAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const resendVerification = async () => {
    setAlert(false);
    const endpoint = isSubDomain
      ? `/api/reset_password/${trade_name}/`
      : `/api/reset_password/`;
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + endpoint,
        { email },
      );

      if (response.data.status === "success") {
        dispatch(
          showModal({
            title: "Recovery Link",
            message:
              "You will receive a password recovery link at your email address in few minutes",
            returnType: "login",
          }),
        );
      } else {
        setAlert(true);
        setMessage(response.data.message);
      }
    } catch (error) {
      if (error.response.status == 429) {
        setMessage("Too many requests. Try again later.");
      } else {
        setMessage(error.response.data.message);
      }
      setAlert(true);
    }
  };

  const resendVerificationTestMode = async () => {
    setAlert(false);
    const endpoint = isSubDomain
      ? `/api/sandbox/reset_password/${trade_name}/`
      : `/api/reset_password/`;
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + endpoint,
        { email },
      );

      if (response.data.status === "success") {
        dispatch(
          showModal({
            title: "Recovery Link",
            message:
              "You will receive a password recovery link at your email address in few minutes",
            returnType: "login",
          }),
        );
      } else {
        setAlert(true);
        setMessage(response.data.message);
      }
    } catch (error) {
      setMessage(error.response.data.message);
      setAlert(true);
    }
  };

  return (
    <div style={style.mainDiv}>
      <BuxModal />
      {isMobile && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={NewBuxLogo} alt="#" />
        </div>
      )}
      <div style={style.header}>Forgot Password</div>
      <Alert
        message={message}
        type="error"
        style={{
          display: alert ? "block" : "none",
          margin: "10px 0px 10px 0px",
          borderLeft: "5px solid red",
        }}
      />
      <div style={style.message}>
        Enter your email address and we will send you instructions to reset your
        password.
      </div>
      <Input
        placeholder="Enter email"
        value={email}
        block
        style={{ height: "40px", marginTop: "20px" }}
        onChange={(e) => setEmail(e.target.value)}
      />
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          type="primary"
          style={
            isMobile
              ? email
                ? style.appearsClickable
                : style.appearsDisabled
              : !isMobile && email.trim()
                ? style.appearsClickable
                : style.appearsDisabled
          }
          size="large"
          block
          onClick={() =>
            testMode ? resendVerificationTestMode() : resendVerification()
          }
          disabled={isMobile ? !email : !email.trim()}
        >
          Send Reset Instructions
        </Button>
      </div>

      {trade_name !== "cashbux" && (
        <div style={style.dontHaveStyle}>
          Don’t have an account yet?{" "}
          <a href="/signup" style={{ color: "#F5922F" }}>
            Sign up
          </a>
        </div>
      )}
    </div>
  );
};

const style = {
  mainDiv: {
    width: isMobile ? "100%" : isHighReso ? "529px" : "430px",
    height: isMobile ? "100%" : "70%",
    borderRadius: !isMobile && "8px",
    backgroundColor: "#fff",
    boxShadow: "0px 8px 24px rgba(0, 35, 11, 0.12)",
    paddingTop: isHighReso ? "60px" : "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: isSubDomain && "50px",
    borderRadius: isSubDomain && 10,
  },
  header: {
    display: "flex",
    justifyContent: "center",
    fontSize: "28px",
    color: "#2b2d33",
    fontWeight: "bold",
    marginTop: isMobile && "30px",
    marginBottom: "26px",
  },
  message: {
    width: "340px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "24px",
    color: "#2b2d33",
  },

  appearsClickable: {
    color: "#fff",
    background: "#F5922F",
    border: 0,
  },

  appearsDisabled: {
    color: "#fff",
    background: "#F5922F",
    opacity: "0.5",
    border: 0,
  },
  dontHaveStyle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#2B2D33",
    marginTop: "20px",
  },
};

export default ForgotPassword;

