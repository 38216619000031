import React from 'react'
import { Modal } from 'antd'
import accountStyles from '../../../static/css/Account'

const CustomInfoModal = props => {

    const ac = accountStyles();

    const {
        showModal, setShowModal, setCustomModal, customImg
    } = props

    function showCustomModal() {
        setCustomModal(true)
        setShowModal(false)
    }

    return (
        <Modal visible={showModal}
        onCancel={()=>setShowModal(false)}
        footer={null}>
            <div align="center">
                <h1 style={{marginTop: '32px'}}>Custom Amount List</h1>
                <div>
                    <img src={customImg} alt="Custom Amount" />
                </div>

                <div className={ac.customModalText}>
                    By Creating a customize Amount list to your Checkout page, 
                    Your customer can easily choose from your desired requested amount without typing and worrying the right amount.
                </div>

                <div style={{marginTop: 16}}>
                    <button className={ac.createcustomListBtn} onClick={() => showCustomModal()}>
                        + Create Custom List
                    </button>

                    <div style={{marginTop: 8}}>
                        <a className={ac.qrLinkText} onClick={() => setShowModal(false)}>Not Now</a>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CustomInfoModal;

