import React from 'react'
import pricingStyle from '../../static/css/Pricing';
import { Grid } from "@material-ui/core";
import {SevenEleven, Instapay, PesoNet} from '../../static/icons/index'

const PaymentMethod = props => {
    const userType = localStorage.getItem('userType')
    
    const p = pricingStyle();

    const payouts = [
        {
            icon: Instapay,
            limit: 'PHP 50,000 per day',
            funds: 'real-time'
        },
        {
            icon: PesoNet,
            limit: 'no limit',
            funds: <div>
                <div style={{marginBottom: '4px'}}>Below PHP 50,000.00 - real time</div> <div>Above PHP 50,000.00 - after 2-3 business days.</div>
            </div>
        },
    ]

    return(
        <div className="pricing-table-div top-20" hidden={userType === "CO" || props.hidden}>
            <div className="pricing-header">
                <div className="wc-header">
                    {/* <img src={CC} className="right-8" alt="icon" /> */}
                    Payout
                </div>

                <div className="top-20 normal-text-size liveColor">
                    List of methods for Cashout. Transaction fees are <span className={`text-${props.segments} wc-header`}>FREE</span>.
                </div>
            </div>

            <div className="padding-lr-40 padding-bot-40">
                <div className="twoCol wc-header padding-20">
                    <Grid container>
                        <Grid item lg={4}>
                            Payment Type
                        </Grid>

                        <Grid item lg={4}>
                            Transaction Limit
                        </Grid>

                        <Grid item lg={4}>
                            Funds Availability
                        </Grid>
                    </Grid>
                </div>
                {
                    payouts.map((item, key) => {
                        return(
                            <div className="pricing-data normal-text-size liveColor">
                                <Grid key={key} container>
                                    <Grid item lg={4} >
                                        <img src = {item.icon} alt="icon" />
                                    </Grid>

                                    <Grid item lg={4}>
                                        <div className={p.funds}>{item.limit}</div>
                                    </Grid>

                                    <Grid item lg={4}>
                                        <div className={p.funds}>{item.funds}</div>
                                    </Grid> 
                                </Grid>
                            </div>
                        )   
                    })
                }
            </div>
        </div>
    )
}

export default PaymentMethod;