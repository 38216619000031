import React, {useEffect} from 'react'
import axios from 'axios'
import {Modal, Radio, message} from 'antd'
import {Grid} from '@material-ui/core'
import questionnaireStyle from '../../static/css/Questionnaire'
import headerLogo from '../../static/icons/questionnaire.svg'
import RadioSelected from '../../static/icons/radio.svg'
import {Handle401} from '../handle401/handle401'
import {isMobile} from 'react-device-detect'

const Questionnaire = props => {

    useEffect(() => {
        getQuestionnaire()
        
    }, []) 

    const q = questionnaireStyle()

    const[step, setStep] = React.useState(0)
    const[questions, setQuestions] = React.useState([])
    const[question, setQuestion] = React.useState("")
    const[answer, setAnswer] = React.useState("")
    const[showModal, setModal] = React.useState(true)
    

    const level = localStorage.getItem("Level")
    const kycStep = localStorage.getItem("Step");

    const yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }

    async function getQuestionnaire(){
    
        
        let response = await axios.get(
            process.env.REACT_APP_API_URL + `/api/survey/?type=${level}`,yourConfig
        )


            setQuestions(response.data);
            setQuestion(response.data[0]);
            setAnswer("");
    }

    async function submitAns(val){

        let response = await axios.post(
            process.env.REACT_APP_API_URL + "/api/survey/", val, yourConfig
        )

        console.log(response.data)
        if(response.data.status == "success"){
            let new_step = step+1;
            setStep(new_step);
            setQuestion(questions[new_step]);
            setAnswer("");
            if(new_step == 5 && level == 1){
                getLatestBalance()
                props.onClose();
                
            }

            else if(new_step == 5 && level == 2){
                getLatestBalance()
                props.onClose();
            }
        }

        else{
            message.error("Please try again", 3)
        }

      
    }

    async function getLatestBalance(){
        let url = process.env.REACT_APP_API_URL + "/api/get_latest_balance/"
        const yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        try{
            if(window.location.pathname == '/dashboard')
            {
              url = url+"?step="+localStorage.getItem("Step")
            }

            let response = await axios.get(
                url,yourConfig
            )

            localStorage.setItem('Step', response.data.kyc_step)

        }

        catch(error){
          let balanceError = error? error.response? error.response.status : "" : ''
          switch(balanceError){
            case '401' : Handle401(); break;
            default :  console.log('errorr')
          }          
        }
    }


    function addStep(){
        let params  = {
            question: question.id,
            answer: answer
        }
    
        submitAns(params);
    }

    function prevStep(){
        let prev_step = step-1;
        setStep(prev_step);
        setAnswer("");
        setQuestion(questions[prev_step]);
    }

    return(
        <div>
            <Modal visible={props.open}
            centered
            width={683}
            closable={false}
            footer={null}
            >
                <div style={{position: 'absolute'}} hidden={isMobile}>
                    <img src={headerLogo} className={q.headerLogo} />
                </div>

                <div align="right">
                    <div className={q.steps}>{step+1} out of 5</div>
                </div>

                <div align="center" style={{padding: isMobile ? '32px 20px 0px 20px' : '0px 110px 0px 110px'}}>
                    <div className={q.header}>
                        Upgrade Application </div>

                       {

                        questions.map((question, index) => {
                               return (<div key={index} style={{display: index != step ? 'none' : ''}}>
                                    <div className={q.question}>{question.questions}</div>
                                    <div style={{marginTop: '20px'}}>
                                    {
                                        question.choices.map((item, index) => {
                                            return(
                                            <div key={index} style={{marginBottom: '8px'}}>
                                                <button className={answer == item.id ? q.selectedAns : q.notSelectedAns} onClick={() => setAnswer(item.id)}>
                                                    {
                                                        answer != item.id ? 
                                                            <Radio/> 
                                                            :
                                                            <img src={RadioSelected} /> } {item.value}
                                                </button>
                                            </div>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                                )
                            })
                       }

                    <div className={q.twoCol} hidden={step == 0}>
                        <div>
                            <button className={q.prevBtn} onClick={() => prevStep()}>
                                Previous
                            </button>
                        </div>

                        <div>
                            <button className={q.nextBtn1} onClick={() => addStep()}
                            style={{opacity: answer ? '' : 0.5, cursor: answer ? 'pointer' : 'not-allowed'}} 
                            disabled={answer == ""}
                            >
                               {step == 4 ? "Done" : "Next"}
                            </button>
                        </div>
                    </div>

                    <button className={q.nextBtn} 
                    style={{opacity: answer ? '' : 0.5, cursor: answer ? 'pointer' : 'not-allowed'}} 
                    onClick={() => addStep()} 
                    hidden={step != 0}
                    disabled={answer == ""}>Next</button>
                </div>
            </Modal>
        </div>
    )
}


export default Questionnaire