import React from 'react'
import FeeStyles from '../../style'

const HeaderCard = props => {
    
    const fee = FeeStyles();
    const userType = localStorage.getItem("userType");
    return(
        <div>

            <div className={fee.spaceBetween}>
                <div className={fee.cardLeftDiv}>

                </div>
            </div>
        </div>
    )
}

export default HeaderCard