import React from 'react';
import { Modal, Typography, Button, Icon, Input, Table } from 'antd';
import TagModalTable from './tagModalTable';
import fraudServices from '../fraudServices';

const TagModal = ({visible, closeModal, tagData, selected, onSuccessTagged}) => {
  const [tableData, setTableData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const getTableData = async (e) => {
    setLoading(true);
    try {
      const res = await fraudServices.getUsersToTag(selected==='Individual Account' ? 'KYC' : 'KYB', 'string', e.target.value? e.target.value : undefined);
      setTableData(res);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  React.useEffect(() => {
    setTableData(null)
  },[selected]);

  return (
    <Modal 
      visible={visible} 
      bodyStyle={styles.root} 
      footer={null}
      centered
      width={917}
      onCancel={() => setTableData(null)/closeModal()}
      closeIcon={<Icon type="close" style={{color:'#F5922F'}}/>}
      destroyOnClose
    >
      <Typography style={styles.title}>Tag a {selected === 'Individual Account' ? 'KYC' : 'KYB'} to this entry?</Typography>
      <div>
        <Typography style={{...styles.selected, fontWeight: 'bold'}}>
          {selected === 'Individual Account' && tagData ? tagData.first_name + ' ' + tagData.last_name : tagData&&tagData.business_name }
        </Typography>
        <Typography style={styles.selected}>{tagData&&tagData.kyb_code}</Typography>
      </div>
      <Input 
        placeholder={selected === 'Individual Account' ? 'Search Business Name, Full Name, Email, Mobile No.' : 'Search Business Name, Email, Mobile No.'}
        style={styles.input}
        onChange={(e) => getTableData(e)}
      />
      <Typography style={{fontSize: 14, color: '#000', paddingBottom: 12}}><strong>Result: </strong>{tableData&&tableData.length + ' Matches'}</Typography>
      <TagModalTable data={tableData} 
        closeModal={() => setTableData(null)/closeModal()} 
        selected={selected} blacklistId={tagData&&tagData.id} 
        onSuccessTagged={() => onSuccessTagged()}
        tableLoading={loading} />
    </Modal>
  )
}

const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 24,
    color: '#2B2D33',
    fontWeight: '300',
    textAlign: 'left',
    padding: '24px 0 10px 0'
  },
  msg: {
    fontSize: 14,
    color: '#000',
    textAlign: 'left',
    padding: '10px 0'
  },
  mainBtn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 10,
  },
  btn: {
    height: 48,
    width: '45%',
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 10,
  },
  selected: {
    fontSize: 14,
    color: '#000',
    textAlign: 'left',
    fontWeight: '500',
  },
  input: {
    margin: '16px 0',
    width: '100%'
  },
}

export default TagModal;