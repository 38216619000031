import React,{} from 'react';
import { Modal, Typography, Button, Icon } from 'antd';
import BG from '../../../../../static/images/corpo_payment_link_modal_bg.svg';
import { segmentStroke } from '../../../../constants/constants';

const ImportBatchModal = (props) => {
    const { segments, visible, onClose, exportTemplate, onSkipStep } = props
  return (
    <Modal 
      visible={visible}
      footer={null}
      onCancel={onClose}
      width={450}
      centered
      closeIcon={<Icon type="close" className={`text-${segments}`}/>}
      bodyStyle={styles.root}>
        <img src={BG} alt="No Image" style={{width: '90%'}} />
        <Typography style={styles.title}>Import batch list</Typography>
        <Typography style={{...styles.normalFont}}>Start by downloading CSV template</Typography>
        <button className={`btn--${segments} top-12`} onClick={exportTemplate}>
          <Icon type='download' className="right-4" />
          Download CSV Template
        </button>
        <Typography style={{...styles.normalFont, paddingTop: 20}}>I already have the template</Typography>
        <a href className={`text-${segments} wc-header bottom-32`} onClick={onSkipStep}>Skip this step</a>
    </Modal>
  ) 
}

const styles = {
  root: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    margin: '16px 0px 0px 0px',
    height: 40,
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 4,
    padding: '0 12px'
  },
  title: {
    fontSize: 24,
    fontWeight: '300',
    color: '#000',
    padding: '22px 0 12px 0'
  },
  normalFont: {
    fontSize: 16,
    color: '#000',
  },
  link: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#F5922F',
    marginBottom: 36,
  }
}

export default ImportBatchModal;