import React from 'react';
import moment from 'moment';
import { Drawer, DatePicker, Icon, Input, Typography } from 'antd';

const MobileFilter = (props) => {
    const { visible, handleSearch, searchVal, segments, onFilterApply, onClose, rangeSelect, dateRange } = props;

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    const Title = () => {
        return (
            <div style={styles.mobileHeader}>
                <a className={`link-text--${segments}`} onClick={onClose}><Icon type='arrow-left' theme='outlined' /></a>
                <Typography style={styles.title}>Filter</Typography>
            </div>
        )
    }

    return (
        <Drawer
            visible={visible}
            placement='bottom'
            onClose={onClose}
            height={'100%'}
            title={<Title />}
            headerStyle={{ padding: 0 }}
            closable={false}
        >
            <div style={{padding: '0 16px'}}>
                <Input
                    placeholder="Search"
                    style={{ width: '100%', height: '40px', borderRadius: '4px', margin: '0px 0 12px 0' }}
                    suffix={<Icon type="search" style={{ fontSize: '18px' }} />}
                    onChange={handleSearch}
                    value={searchVal}
                    size='large'
                />
                <div style={styles.calendarDiv}>
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledDate}
                        // defaultValue={moment()}
                        value={dateRange.startDate && moment(dateRange.startDate)}
                        size="large"
                        picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'startDate')}
                        placeholder='Start Date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledDate}
                        // defaultValue={moment()}
                        value={dateRange.endDate && moment(dateRange.endDate)}
                        size="large"
                        picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'endDate')}
                        placeholder='End Date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                </div>
                <button className={`btn--${segments}`} style={styles.applyBtn} onClick={onFilterApply} >Apply</button>
            </div>
        </Drawer>

    )
}

const styles = {
    root: {

    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: '48%',
        // padding: '0 8px 0 8px'
    },
    calendarDiv: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '12px 0'
    },
    applyBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: '100%',
        cursor: 'pointer',
        margin: '12px 0'
    },
    title: {
        color: '#2B2D32',
        fontSize: 18,
        fontWeight: 'bold',
        padding: '0 16px',
        textAlign: 'center',
        width: '100%'
    },
    mobileHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '14px 16px',
        backgroundColor: '#FFF',
        margin: '0 0 32px 0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
    }
}

export default MobileFilter;