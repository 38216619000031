import React, {useEffect} from 'react'
import {Card, Button, Breadcrumb, message, Modal, Radio, Input} from 'antd'
import {history} from '../../../store/history'
import Main from '../../../layouts/Main'
import ArrowDown from '../../../static/icons/arrow_down_orange.svg'
import axios from 'axios'
import Visa from '../../../static/icons/card_type/visa.svg'
import MasterCard from '../../../static/icons/card_type/mastercard.svg'
import * as orderStyle from '../../../components/orders/orderStyle'

const ChargedDetails = props => {

    const testMode = (history.location.pathname.indexOf('/test') > -1 && localStorage.getItem("userType") == "CO")
    const [hover, setHover] = React.useState(false)
    const [details, setDetails] = React.useState([])
    const [cardLoading, setCardLoading] = React.useState(true)
    const [refundVisible, setRefundVisible] = React.useState(false)
    const [reason ,setReason] = React.useState("Item/s no longer available")
    const [otherText, setOtherText] = React.useState('')

    const ubp_enabled = localStorage.getItem('cc_enabled') == 'UnionBank';
    const xendit_enabled = localStorage.getItem('cc_enabled') == 'Xendit';

    var linkStyle;
    if (hover) {
        linkStyle = {
            color: 'blue',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '24px',
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    } else {
        linkStyle = {
            color: '#000',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '24px',
        }
    }

    useEffect(()=>{
        let current_path = window.location.pathname

        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            history.push('/login?next='+current_path)
        }
        else{
            getDetails()
        }
        
        
    }, [])

    async function getDetails(){
        try {
            const yourConfig = {
                headers: {
                   Authorization: "Token " + localStorage.getItem("jwtToken")
                }
             }

            let response = await axios.get(
                process.env.REACT_APP_API_URL + '/api/payment/'+ props.match.params.uuid, yourConfig
            );
            
            if(response){
                setDetails(response.data)
                setCardLoading(false)
                console.log(response.data)
            }
            
        }
    
        catch (error) {
            console.log(error);
        }
    }

    const refundOrderNow = async() => {
        let params = {
          id: props.match.params.id,
          reason: reason,
          otherText: otherText
        }
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        const url = '/api/orders/refund/';
        let response = await axios.post(process.env.REACT_APP_API_URL + url, params, yourConfig);
        
        if(response.data.status == "success"){
          setRefundVisible(false)
          message.success("Successfully requested refund")
        }else{
          message.error(response.data.message)
        }
      }

    return(
        <Main>
            <div style={{padding: '38px 42px 0px 42px'}}>
                <div>
                    <div style={{...Style.breadCrumbStyle, top: testMode? 32: 0}}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <a onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} onClick={()=>history.push(testMode?'/test/orders':'/orders')} style={linkStyle}>Payment Request</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item style={Style.textStyle}>Charged Details</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '28px'}}>
                    <Button style={Style.backBtn} onClick={()=>history.push('/orders')}>
                        <img src={ArrowDown} style={{transform: 'rotate(90deg)', marginLeft: '-10px'}}/>
                        <span style={{marginTop: '2px'}}>Charge Details</span>
                    </Button>
                    <Button style={Style.refundBtn} onClick={()=>setRefundVisible(true)} hidden={details.status == 'Cancelled' || ubp_enabled}>
                        Refund
                    </Button>
                </div>
                <div className="twoCol top-24 charged-div">
                    <Card
                        loading={cardLoading}
                        style={Style.cardStyle}
                        bodyStyle={Style.cardBodyStyle}>
                        
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>
                                <div style={Style.headerStyle}>Transaction Amount:</div>
                                <div style={Style.amountStyle}>₱ {details.amount}</div>
                            </div>
                            <div style={Style.statusDiv}>{details.status == 'Paid' ? 'Charged' : 'Refunded'}</div>
                        </div>
                        <hr style={{borderTop: '1px solid gray', margin: '16px 0px 16px 0px'}}></hr>
                        <div style={{display:'flex', justifyContent: 'space-between'}}>
                            <div style={Style.labelStyle}>Charged ID</div>
                            <div style={Style.valueStyle}>{details.code}</div>
                        </div>
                        <hr style={{borderTop: '1px solid gray', margin: '16px 0px 16px 0px'}}></hr>
                        <div style={{display:'flex', justifyContent: 'space-between'}}>
                            <div style={Style.labelStyle}>Bank Reconciliation ID</div>
                            <div style={Style.valueStyle}>{details.payload && details.payload['bank_reconciliation_id']}</div>
                        </div>
                        <hr style={{borderTop: '1px solid gray', margin: '16px 0px 16px 0px'}}></hr>
                        <div style={{display:'flex', justifyContent: 'space-between'}}>
                            <div style={Style.labelStyle}>Charge Approval Code</div>
                            <div style={Style.valueStyle}>{details.payload && details.payload['approval_code']}</div>
                        </div>
                        <hr style={{borderTop: '1px solid gray', margin: '16px 0px 16px 0px'}}></hr>
                        <div style={{display:'flex', justifyContent: 'space-between'}}>
                            <div style={Style.labelStyle}>Date Created</div>
                            <div style={Style.valueStyle}>{details.created}</div>
                        </div>
                        <hr style={{borderTop: '1px solid gray', margin: '16px 0px 16px 0px'}}></hr>
                        <div style={{display:'flex', justifyContent: 'space-between'}}>
                            <div style={Style.labelStyle}>Merchant Descriptor</div>
                            <div style={Style.valueStyle}>{details.owner}</div>
                        </div>    
                        <hr style={{borderTop: '1px solid gray', margin: '16px 0px 16px 0px'}}></hr>
                        <div style={{...Style.textStyle, marginBottom: '16px'}}>Description</div>
                        <div style={{...Style.valueStyle, marginBottom: '16px'}}>{details.description}</div>
                    </Card>
                    <Card
                        loading={cardLoading}
                        style={{...Style.cardStyle, height: '180px'}}
                        bodyStyle={Style.cardBodyStyle}>

                        <div style={Style.headerStyle}>Transaction Amount:</div>        
                        <hr style={{borderTop: '1px solid gray', margin: '16px 0px 16px 0px'}}></hr>
                        <div style={{display:'flex', justifyContent: 'space-between'}}>
                            <div style={Style.labelStyle}>Card Number</div>
                            <div style={Style.valueStyle}>•••• {details.payload && details.payload['last_four_digits'].slice(-4)}</div>
                        </div>
                        <hr style={{borderTop: '1px solid gray', margin: '16px 0px 16px 0px'}}></hr>
                        <div style={{display:'flex', justifyContent: 'space-between'}}>
                            <div style={Style.labelStyle}>Card Type</div>
                            <div style={Style.valueStyle}><img alt="card" src={
                                (details.payload && (details.payload['card_brand'] == 'VISA' || details.payload['card_type'] == 'Visa')) ? Visa : MasterCard}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <Modal
              visible={refundVisible}
              footer={null}
              onCancel={()=>setRefundVisible(false)}
            >
              <div style={{ padding: '40px 30px 40px 30px' }}>
                <div style={orderStyle.cancelOrder}>Refund</div>
                <div style={orderStyle.choicesStyle}>
                Are you sure you want to refund this transaction? This can’t be undone.
                </div>
                <div style={orderStyle.choicesStyle}>Provide a reason</div>
                <div style={{marginBottom: '10px'}}>
                  <Radio.Group onChange={e => setReason(e.target.value)} value={reason}>
                    <Radio style={orderStyle.radioStyle} value="Item or service is no longer available">
                      Item or service is no longer available
                        </Radio>
                    <Radio style={orderStyle.radioStyle} value="Wrong item or service selected">
                      Wrong item or service selected
                        </Radio>
                    <Radio style={orderStyle.radioStyle} value="Unable to deliver the item or service">
                      Unable to deliver the item or service
                        </Radio>
                    <Radio style={orderStyle.radioStyle} value="Payment was declined">
                      Payment was declined
                        </Radio>
                    <Radio style={orderStyle.radioStyle} value="Others">
                      Others <Input hidden={reason!="Others"} onChange={e=>setOtherText(e.target.value)}/>
                      </Radio>
                  </Radio.Group>
                </div>
                <div>
                  <Button type="danger" style={orderStyle.cancelButtonStyle} onClick={() => refundOrderNow()}>Proceed with Refund</Button>
                </div>
                <Button style={orderStyle.dontCancelButton} onClick={()=>setRefundVisible(false)}>Cancel</Button>
              </div>
            </Modal>
        </Main>
    )
}

const Style = {
    cardStyle: {
        width: '446px',
        borderRadius: '10px',
        border: '1px solid #D1D5DD',
        backgroundColor: '#fff',
    },

    statusDiv:{
        width: '86px',
        height: '32px',
        fontSize: '12px',
        color: '#2B2D33',
        lineHeight: '24px',
        borderRadius: '4px',
        padding: '4px 0px 4px 13px',
        border: '1px solid #1DD28B',
        borderLeft: '5px solid #1DD28B',
        backgroundColor: 'rgba(29, 210, 139, 0.1)'
    },
    cardBodyStyle: {
        padding: '20px'
    },
    breadCrumbStyle: {
        top: '0',
        paddingTop: '35px',
        zIndex: '1',
        paddingLeft: '0px'
    },
    textStyle: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000',
    },
    backBtn: {
        borderRadius: '40px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.102751)',
        border: '1px solid #fff',
        fontWeight: '600',
        fontSize: '16px',
        color: '#F5922F'
    },
    refundBtn: {
        height: '40px',
        backgroundColor: '#F5922F',
        border: '1px solid #F5922f',
        borderRadius: '10px',
        color: '#fff',
        fontWeight: '600',
        fontSize: '16px',
    },
    labelStyle: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#909196'
    },
    valueStyle: {
        fontSize: '14px',
        fontWeight: '600',
        color: '#000000'
    },
    headerStyle:{
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#000'
    },
    amountStyle: {
        fontSize: '20px',
        fontWeight: '900',
        color: '#1DD28B'
    },
    textStyle: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#000000'
    }
}
export default ChargedDetails