import React from 'react'
import { Drawer, Icon, Button, Select, DatePicker, Input} from 'antd'
import PaymentMethodsDrawer from './paymentMethodsDrawer';
import * as Style from './mobile_order_style';

const FilterDrawer = props => {

    const { Option } = Select;

    const [paymentMethodVisiblity, setPaymentMethodVisibility] = React.useState(false)
    
    const {
        visible, onClose,
        checkAllOTC, onCheckAllOtcChange, OTC_LIST, otcCheckedList, onChangeOtc,
        checkAllWeb, onCheckAllWebChange, BANK_LIST, webCheckedList, onChangeWeb,
        checkAllWallet, onCheckAllWalletChange, MOBILE_LIST, walletCheckedList, onChangeWallet,
        checkAllCard, onCheckAllCardChange, CARD_LIST, cardCheckedList, onChangeCard,
        //setCCSelected,
        submitApplyFilter,
        dayFilter, setDayFilter, setDateFiltered, endOpen,
        disabledStartDate, startMoment, onStartChange, handleStartOpenChange,
        disabledEndDate, endMoment, onEndChange, handleEndOpenChange,
        searchValue, setSearchValue, segments
    } = props
   
      
    return (
        <Drawer
            placement="bottom"
            width="100%"
            height="100%"
            title={
                <div align="center" style={{marginTop: 32}}>Filter</div>
            }
            visible={visible}
            onClose={onClose}>
                <PaymentMethodsDrawer
                    paymentMethodVisiblity={paymentMethodVisiblity} onClose={()=>setPaymentMethodVisibility(false)}
                    checkAllOTC={checkAllOTC} onCheckAllOtcChange={onCheckAllOtcChange} OTC_LIST={OTC_LIST} otcCheckedList={otcCheckedList} onChangeOtc={onChangeOtc}
                    checkAllWeb={checkAllWeb} onCheckAllWebChange={onCheckAllWebChange} BANK_LIST={BANK_LIST} webCheckedList={webCheckedList} onChangeWeb={onChangeWeb}
                    checkAllWallet={checkAllWallet} onCheckAllWalletChange={onCheckAllWalletChange} MOBILE_LIST={MOBILE_LIST} walletCheckedList={walletCheckedList} onChangeWallet={onChangeWallet}
                    checkAllCard={checkAllCard} onCheckAllCardChange={onCheckAllCardChange} CARD_LIST={CARD_LIST} cardCheckedList={cardCheckedList} onChangeCard={onChangeCard}
                    segments={segments}
                />
                <div style={{padding: 16}}>

                    <Input
                        value={searchValue}
                        placeholder="Search"
                        style={{ borderRadius:'4px', zIndex: 1, height: '40px', marginBottom: '16px'}}
                        onChange={(e)=> setSearchValue(e.target.value)}
                        suffix={<Icon type="search" style={{fontSize:'18px'}}/>}
                    />

                    <Button style={{...Style.paymentMethodBtn, marginBottom: '16px'}} onClick={()=>setPaymentMethodVisibility(true)}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{fontSize: '16px', color: '#2b2d32'}}>Payment method</div>
                            <Icon type="caret-down" />
                        </div>
                    </Button>
                
                    <Select 
                        suffixIcon={<Icon type="caret-down" />}
                        style={{width: '100%'}}
                        size="large"
                        value={dayFilter}
                        onChange={
                            (e)=> {setDayFilter(e); setDateFiltered(true)} }
                        >
                        <Option value="All">All Time</Option>
                        <Option value="Today">Today</Option>
                        <Option value="Week" >Last 7 days</Option>
                        <Option value="Month" >Last 30 days</Option>
                        <Option value="Custom" >Custom</Option>
                    </Select>
                    
                    <div style={{marginTop: 16}}>
                    <DatePicker
                        style={{zIndex: 1, width: '46%'}}
                        disabledDate={disabledStartDate}
                        size="large"
                        format='MM-DD-YYYY'
                        value={startMoment}
                        placeholder="Start"
                        onChange={onStartChange}
                        onOpenChange={handleStartOpenChange}
                    />
                    <span style={{padding:'8px'}}>-</span> 
                    <DatePicker
                        style={{zIndex: 1, width: '47%'}}
                        disabledDate={disabledEndDate}
                        size="large"
                        format='MM-DD-YYYY'
                        value={endMoment}
                        placeholder="End"
                        onChange={onEndChange}
                        open={endOpen}
                        onOpenChange={handleEndOpenChange}
                    />  
                    </div>      
                </div>
                <button className={`btn--${segments}`} style={Style.mobileApplyBtn} onClick={submitApplyFilter}>
                    Apply
                </button>
        </Drawer>
    )
}

export default FilterDrawer;