import React, {useRef} from 'react'
import {Card, Button, Upload, message, Modal, Icon, Spin} from 'antd'
import * as Style from './kyc_style'
import {isMobile, isFirefox, isIOS, isMobileSafari, isSafari, isEdge} from 'react-device-detect'
import {submitDetails,setStep} from './application_main'
import TipsImage from '../../../static/img/tipsImage.svg'
import UploadIDImg from '../../../static/img/upload_id.svg'
import SelfieImg from '../../../static/img/selfie.svg'
import CameraIcon from '../../../static/img/cameraImg.svg'
import Close from '../../../static/icons/close_icon_orange.svg'
import kycServices from '../kycServices';

const IdentityVerification = props => {

    const max_width = 1000;
    const max_height = 1000;
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
    
    const segments = localStorage.getItem("segments")
    const color = segments == 'me_' ? '#0DAED0' : segments == 'gig' ? '#41BD5C' : '#F5922F'

    const canvasRef = useRef(null);
    const playerRef = useRef(null);

    let uploadFrontIDRef = React.useRef()
    let uploadBackIDRef = React.useRef()

    const [selected, setSelected] = React.useState("")

    const [idType, setIdType] = React.useState(props.details.id_type != "" ? props.details.id_type : "SSS")
    const [idNumber, setIdNumber] = React.useState(props.details.id_number)

    const [uploadingFrontID, setUploadingFrontID] = React.useState(false)
    const [uploadingBackID, setUploadingBackID] = React.useState(false)

    const [frontIdFileName, setFrontIDFileName] = React.useState(props.details.front_id_image_url)
    const [backIdFileName, setBackIDFileName] = React.useState(props.details.back_id_image_url)
    const [selfieFileName, setSelfieFileName] = React.useState(props.details.selfie_image_url)

    const [frontIdURL, setFrontIdURL] = React.useState(props.details.front_id_image_url)
    const [backIdURL, setBackIdURL] = React.useState(props.details.back_id_image_url)
    const [selfieBlob, setSelfieBlob] = React.useState(props.details.selfie_image_url)

    const [step4ErrorAlert, setStep4ErrorAlert] = React.useState(false)
    const [step4ErrorMessage, setStep4ErrorMessage] = React.useState("")
    const [idFormatError, setIdFormatError] = React.useState(false)

    const [messageModal, setMessageModal] = React.useState("")
    const [cameraModal, setCameraModal] = React.useState(false)
    const [tipsModal, setTipsModal] = React.useState(false)
    const [blockModal, setBlockModal] = React.useState(false)
    const [hover, setHover] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    function beforeIDUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }

        return isJpgOrPng;
    }

    function handleIDtype(e){
        setIdType(e.target.value)
        if(idNumber){
            setIdFormatError(checkIdFormat(e.target.value, idNumber));
        }
    }


    const IDType = { 
        'SSS': 'SSS',
        'GSIS': ' GSIS',
        'DRL': `Driver's License`,
        'AICR': 'Alien Certificate of Registration Identity Card',
        'PAS': 'Passport',
        'UMID': ' UMID',
        'PRC': 'PRC ID',
        'POS': 'Digitize Postal ID',
        'PIC': 'Philippine Identification Card'
    }

    const checkIdFormat = (id, value) => {
        switch (id) {
            case 'DRL':
               if (value.match(/^([a-zA-Z]\d{2})-(\d{2})-(\d{6})$/)){
                return false
               } else {
                return true
               }
            case 'PAS':
                if (value.match(/^([a-zA-Z])(\d{7})([a-zA-Z])$/)){
                    return false
                } 
                else if(value.match(/^([a-zA-Z])([a-zA-Z])(\d{7})$/)){
                    return false
                }
                else {
                    return true
                }
            case 'UMID':
                if (value.match(/^(\d{4})-(\d{7})-(\d)$/)){
                    return false
                } else {
                    return true
                }
            case 'SSS':
                if (value.match(/^(\d{2})-(\d{7})-(\d)$/)){
                    return false
                } else {
                    return true
                }     
            case 'GSIS':
                if (value.match(/^(\d{4})-(\d{7})-(\d)$/)){
                    return false
                } else {
                    return true
                } 
            case 'AICR':
                if (value.match(/^([a-zA-Z])(\d{10})([a-zA-Z])([a-zA-Z])$/)){
                    return false
                } else {
                    return true
                } 
            case 'POS':
                if (value.match(/^([a-zA-Z0-9]{12}) ([a-zA-Z])$/)){
                    return false
                } else {
                    return true
                }
            case 'PIC':
                return !value.match(/\b[\w]{4}-[\w]{4}-[\w]{4}-[\w]{4}\b/g)
            default:
                return false
        }
    }

    const idFormatErrorMessage = () => {
        switch (idType) {
            case 'DRL':
                return (`Invalid Driver's License ID no. | 
                        Format should be X00-00-000000`)
            case 'PAS':
                return (`Invalid Passport ID no. | 
                        Format should be X0000000X`)
            case 'SSS':
                return (`Invalid SSS ID no. | 
                        Format should be 00-0000000-0`)
            case 'UMID':
                return (`Invalid SSS or UMID ID no. | 
                        Format should be 0000-0000000-0`)
            case 'GSIS':
                return (`Invalid GSIS ID no. | 
                        Format should be 0000-0000000-0`)
            case 'PRC':
                return (`Invalid PRC ID no. |  
                        Format should be 0000000`)
            case 'AICR':
                return (`Invalid ACR ID no. |  
                        Format should be X0000000000XX`)
            case 'POS':
                return (`Invalid Postal ID no. | 
                        Format should be 000000000000 X`)
            case 'PIC':
                return (`Invalid PIC ID no. |
                        Format should be xxxx-xxxx-xxxx-xxxx`)
            default:
                return (`Invalid ID no. | 
                        Format example: 000000000X`)
        }
    }
    
    const handleIdNumberChange = (e) => {
        const value = e.target.value ;
        const formatValue = () => {
            switch (idType) {
                case 'DRL':
                   return (value).replace(/^([a-zA-Z]\d{2})(\d{2})(\d{6})$/,"$1-$2-$3").toUpperCase();
                case 'PAS':
                   return (value).replace(/^([a-zA-Z])(\d{7})([a-zA-Z])$/,"$1$2$3").toUpperCase();
                case 'UMID':
                    return (value).replace(/^(\d{4})(\d{7})(\d)$/, "$1-$2-$3").toUpperCase();
                case 'GSIS':
                    return (value).replace(/^(\d{4})(\d{7})(\d)$/, "$1-$2-$3").toUpperCase();
                case 'SSS':
                    return (value).replace(/^(\d{2})(\d{7})(\d)$/, "$1-$2-$3").toUpperCase();
                case 'POS':
                    return (value).replace(/^([a-zA-Z0-9]{12})([a-zA-Z])$/, "$1 $2").toUpperCase();
                default:
                    return (value);
            };
        }
        setIdFormatError(checkIdFormat(idType, formatValue()))
        setIdNumber(formatValue());
    }

    function handleCameraPermission(){

        playerRef.current.setAttribute('playsinline', '');
        playerRef.current.setAttribute('autoplay', '');
        playerRef.current.setAttribute('muted', '');
        playerRef.current.style.width = '100%';
        playerRef.current.style.height = '400px';

        /* Setting up the constraint */
        var facingMode = "user"; // Can be 'user' or 'environment' to access back or front camera (NEAT!)
        var constraints = {
          audio: false,
          video: {
           facingMode: facingMode
          }
        };

        if(!isIOS){
            setMessageModal('Please allow your camera access to take your selfie by enabling on your browser security settings. Remove bux.ph from list of blocked sites')
            
            if(isFirefox || isEdge){
                navigator.mediaDevices.getUserMedia(constraints)
                .then(function(stream) {
                    setCameraModal(true)
                    playerRef.current.srcObject = stream;
                })
                .catch(function(err) {
                    setBlockModal(true)
                });
            }
            else{
                navigator.permissions.query({name: 'camera'})
                .then((permissionObj) => {
                    if(permissionObj.state == "denied"){
                        setBlockModal(true)
                    }
                    else{
                        navigator.getMedia = ( navigator.getUserMedia ||
                            navigator.webkitGetUserMedia ||
                            navigator.mozGetUserMedia ||
                            navigator.msGetUserMedia);

                        if (navigator.getUserMedia) {
                            navigator.getUserMedia(constraints, function success(stream) {
                            setCameraModal(true)
                            playerRef.current.srcObject = stream;
                            }, function(error){
                            
                            setBlockModal(true)
                            });
                        }
                    }
                })
                .catch((error) => {
                    setBlockModal(true)
                })
            }
        }else{
            if(isMobileSafari || isSafari){
                setMessageModal(`iPhone Safari Blocked Access:
                Please allow camera access to bux.ph. If you have permanently blocked camera access, please follow these steps:
                Go to Setttings >  Go to Safari > Scroll to Privacy & Security > Enable Camera & Microphone Access`)
                navigator.mediaDevices.getUserMedia(constraints).then(function success(stream) {
                    setCameraModal(true)
                    playerRef.current.srcObject = stream;
                }).catch((error) => {
                    setBlockModal(true)
                });
    
            }
            else {
                setMessageModal(`Camera access is only enabled for Safari on iOS devices. Please use Safari to use the selfie feature`)
                setBlockModal(true)
            }
        }
    }

    function handleTakePhoto (dataUri) {
        processfile(dataURItoBlob(dataUri), 4);
        try{
            let stream = playerRef.current.srcObject;
            let tracks = stream.getTracks();
            tracks[0].stop()
        }catch(error){}
        setCameraModal(false)

    }

    function closeCamera(){
        try{
            let stream = playerRef.current.srcObject;
            let tracks = stream.getTracks();
            tracks[0].stop()
        }catch(error){}

        setCameraModal(false)
    }

    function captureImage(dataURI){

        const context = canvasRef.current.getContext('2d');
        context.drawImage(playerRef.current , 0, 0, canvasRef.current.width, canvasRef.current.height);

        var dataURL =  canvasRef.current.toDataURL();
        handleTakePhoto(dataURL)
    }


    const handleIDUpload = (info, type) => {
        processfile(info, 3, type)
    };

    function processfile(info, step, type) {
        if((step == 3 && (info.type === 'image/jpeg' || info.type === 'image/png')) || step == 4)
        {            
            var reader = new FileReader();
            reader.readAsArrayBuffer(info);
            
            reader.onload = function (event) {
    
                var blob = new Blob([event.target.result]); 
                window.URL = window.URL || window.webkitURL;
                var blobURL = window.URL.createObjectURL(blob);
                console.log(info)
                
                if(step == 3 && type == 0){
                    setFrontIdURL(blobURL)
                    setFrontIDFileName(info.name)
                }
                else if(step == 3 && type == 1){
                    setBackIdURL(blobURL)
                    setBackIDFileName(info.name)
                }
                else{
                    setSelfieBlob(blobURL)
                }
    
                var image = new Image();
                image.src = blobURL;
                image.onload = function() {
                    var resized = resizeMe(image); 
                    submitPhoto(resized, step, type)
                }
            };
        }   
    }

    function resizeMe(img) {
      
      var canvas = document.createElement('canvas');

      var width = img.width;
      var height = img.height;

      if (width > height) {
        if (width > max_width) {
          height = Math.round(height *= max_width / width);
          width = max_width;
        }
      } else {
        if (height > max_height) {
          width = Math.round(width *= max_height / height);
          height = max_height;
        }
      }
      
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      
      return canvas.toDataURL("image/jpeg",0.8); 

    }

    function dataURItoBlob(dataUri) {
        var byteString = atob(dataUri.split(',')[1]);
        var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], {type: mimeString});
        return blob
    }

    async function submitPhoto(dataURI, step, type) {
        
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        var blob = dataURItoBlob(dataURI);
        const formData = new FormData();

        if(step == 4){
            formData.append('selfie_image', blob, 'selfie_image.png');
            formData.append('step', '4')
        }else{
            if(type == 0){
                formData.append('front_id_image', blob, 'id_image.png');
            }
            else{
                formData.append('back_id_image', blob, 'id_image.png');
            }
            formData.append('id_type', idType)
            formData.append('id_number', idNumber)
            formData.append('step', '3')

        }

        let response = await kycServices.submitDetails(formData);

        if(response.status == "success"){
            setUploadingFrontID(false)
            setUploadingBackID(false)
        }
        else{
            setStep4ErrorMessage(response.message)
            setStep4ErrorAlert(true)
            setUploadingFrontID(false)
            setUploadingBackID(false)
            window.scrollTo(0, 0);
        }

    }

    async function checkDetails(){
        if(idNumber.length == 0){
            setStep4ErrorMessage("ID number is required")
            setStep4ErrorAlert(true)
            window.scrollTo(0, 0);
        }
        else if (IDType[idType] == "") {
            setStep4ErrorMessage("ID type is required")
            setStep4ErrorAlert(true)
            window.scrollTo(0, 0);
        }
        else if (idFormatError && !props.testMode) {
            setStep4ErrorMessage(idFormatErrorMessage())
            setStep4ErrorAlert(true)
            window.scrollTo(0, 0);
        }

        else{
            let details = {
                id_type: idType,
                id_number: idNumber,
                step: 5
            }
            setLoading(true)
            if(props.testMode) {
                props.fakeKycDetails(5)
                window.scrollTo(0, 0);
                setLoading(false)
            }

            else {
                let response = await submitDetails(details)
                if(response == "error"){
                    setStep4ErrorMessage("ID Number already registered")
                    setStep4ErrorAlert(true)
                    window.scrollTo(0, 0);
                }
                else{
                    setLoading(false)
                }
            }
          
            
        }
        
    }

    function checkPhotoComplete(){
        if(idType != 'PAS'){
            if(frontIdURL == "" || backIdURL == ""){
                return true
            }
            else{
                return false
            }
        }
        else{
            if(frontIdURL == ""){
                return true
            }
            else{
                return false
            }
        }
    }

    function validatePhotos(){
        if(idType != 'PAS'){
            if(frontIdURL == "" || backIdURL == "" || idNumber == "" || idType == "" || selfieBlob == ""){
                return true
            }
            else{
                return false
            }
        }
        else{
            if(frontIdURL == "" || idNumber == "" || idType == "" || selfieBlob == ""){
                return true
            }
            else{
                return false
            }
        }
    }


    function removeFrontIDFile() {
        setFrontIdURL("")
        setFrontIDFileName("")
    }
    function removeBackIDFile() {
        setBackIdURL("")
        setBackIDFileName("")
    }
    function removeSelfie() {
        setSelfieBlob("")
    }

    return(
        <div>
            <Card 
                style={Style.formCardStyle}
                bodyStyle={{padding: isMobile? '12px 16px' : '32px'}}>
                
                <div style={{display: 'flex', justifyContent: isMobile? 'flex-end' : 'space-between'}}>
                    <div hidden={isMobile}>
                        <div style={{...Style.headerStyle, fontSize: isMobile ? '16px' : '20px'}}>Identity Verification</div>
                    </div>
                    <div hidden={props.details.step != 6 && props.details.step != 9}>
                    {
                         isMobile ? 
                         <div style={{...Style.boldText, color: '#F5922F'}} onClick={()=>setStep(6)}>Cancel</div>
                         :
                         <button 
                            onClick={()=>setStep(6)}                            
                            className={`outline-btn--${segments} btn-height`}> Cancel</button>
                    }
                    </div>
                </div>

                <div
                    style={{
                        backgroundColor: 'rgba(226, 76, 76, 0.1)',
                        borderLeft: '4px solid #E24C4C',
                        width: '100%',
                        height: '100%',
                        borderRadius: '4px',
                        marginTop: '20px',
                        textAlign: 'center',
                        padding: '10px 30px',
                        display: step4ErrorAlert ? "block" : "none"
                    }}>
                    <span style={Style.normalText}>{step4ErrorMessage}</span>
                </div>
                <div style={{display: isMobile ? 'block' : 'flex', marginTop: '20px'}}>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>ID Type</div>
                        <select
                            onFocus={()=>setSelected("id_type")}
                            onMouseLeave={()=>setSelected("")}
                            onChange={handleIDtype}
                            value={idType}
                            style={{...Style.selectStyle, 
                                border: selected === "id_type" ? `1px solid ${color}` : '1px solid #D1D5DD',
                                padding: '10px 40px 10px 10px'
                                }}>
                                {
                                    Object.keys(IDType).map((key, i) => (
                                    <option value={key}>{IDType[key]}</option>
                                    ))
                                }
                        </select>
                    </div>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div style={{...Style.miniText, color: '#212B36', marginBottom: '5px'}}>ID Number</div>
                        <input 
                            style={{...Style.inputStyle, 
                                border: selected === "id_number" ? `1px solid ${color}` : '1px solid #D1D5DD',
                                padding: '10px'}} 
                            placeholder="ID Number"
                            value={idNumber}
                            onChange={handleIdNumberChange}
                            onSelect={()=>setSelected("id_number")}
                            onMouseLeave={()=>setSelected("")}/>
                    </div>
                </div>
                <div style={{fontSize: '12px', marginTop: '20px'}}>Front ID Photo</div>
                <div style={Style.uploadDiv}>
                    <div style={{display: 'flex'}}>
                        <div style={{display: 'flex'}} hidden={frontIdURL == ""}>
                            <img src={frontIdURL} alt="#" style={Style.uploadedImage}/>
                            <span style={Style.fileName} hidden={isMobile}>{frontIdFileName}</span>
                        </div>
                    </div>
                    {
                        frontIdURL != "" ?
                        <a onClick={()=>removeFrontIDFile()} style={{marginTop: '18px'}}>
                            <Icon type="close" style={{color: color, fontSize: '20px'}}/>
                        </a>
                        :
                        <Button style={Style.uploadPhoto} onClick={()=>uploadFrontIDRef.current.click()}><Icon type="upload" style={{fontSize: '12px'}}/> Upload</Button>
                    }
                    <input type="file"
                        data-test="upload-file-input"
                        ref={uploadFrontIDRef}
                        style={{display: 'none'}}
                        onChange={(e)=>handleIDUpload(e.target.files[0], 0)}
                        accept=".jpg,.png,.jpeg"
                        >
                    </input>
                </div>
                <div style={{fontSize: '12px', marginTop: '20px'}} hidden={idType == 'PAS'}>Back ID Photo</div>
                <div style={Style.uploadDiv} hidden={idType == 'PAS'}>
                    <div style={{display: 'flex'}}>
                        <div style={{display: 'flex'}} hidden={backIdURL == ""}>
                            <img src={backIdURL} alt="#" style={Style.uploadedImage}/>
                            <span style={Style.fileName} hidden={isMobile}>{backIdFileName}</span>
                        </div>
                    </div>
                    {
                        backIdURL != "" ?
                        <a onClick={()=>removeBackIDFile()} style={{marginTop: '18px'}}>
                            <Icon type="close" style={{color: color, fontSize: '20px'}}/>
                        </a>
                        :
                        <Button style={Style.uploadPhoto} onClick={()=>uploadBackIDRef.current.click()}><Icon type="upload" style={{fontSize: '12px'}}/> Upload</Button>
                    }
                    <input type="file"
                        data-test="upload-file-input"
                        ref={uploadBackIDRef}
                        style={{display: 'none'}}
                        onChange={(e)=>handleIDUpload(e.target.files[0], 1)}
                        accept=".jpg,.png,.jpeg"
                        >
                    </input>
                </div>
                <div style={{fontSize: '12px', marginTop: '20px'}}>Selfie Photo</div>
                <div style={{...Style.uploadDiv, marginBottom: '20px'}}>
                    <div style={{display: 'flex'}}>
                        <div style={{display: 'flex'}} hidden={selfieBlob == ""}>
                            <img src={selfieBlob} alt="#" style={Style.uploadedImage}/>
                            <span style={{...Style.fileName, width: 300}} hidden={isMobile}>selfie.png</span>
                        </div>
                    </div>
                    {
                        selfieBlob != "" ?
                        <a onClick={()=>removeSelfie()} style={{marginTop: '18px'}}>
                            <Icon type="close" style={{color: color, fontSize: '20px'}}/>
                        </a>
                        :
                        <Button style={Style.uploadPhoto} onClick={()=>handleCameraPermission()}><Icon type="camera" style={{fontSize: '12px'}}/> Take a selfie</Button>
                    }
                </div>
                <div style={{fontSize: '12px', fontStyle:'italic', paddingBottom: '20px'}}>Note: Please allow camera permissions on your device.</div>
                
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}} hidden={isMobile}>
                    <div style={{...Style.backToDashboard, margin: '0px'}}> <a className={`text-${segments}`} onClick={()=>setStep(2)}>Back</a></div>
                    <Button loading={loading} style={{...Style.submitBtnStyle, opacity: validatePhotos() ? 0.5 : 1}} onClick={()=>checkDetails()} disabled={validatePhotos()}>
                        <div style={{...Style.boldText, color: "#fff", display: 'inline-block', marginLeft: loading ? '20px' : '0px'}}>{props.details.step == 3 || props.details.step == 4 || props.details.step == 5 ? 'Submit and next' : 'Save'}</div>
                    </Button>
                </div>
               
            </Card>

            <div style={{
                width: '100%', 
                position: 'fixed', 
                display: 'flex',
                backgroundColor:'#fff', 
                height: '74px', 
                bottom: '0', 
                marginLeft: '-16px',
                padding:'10px 20px 10px 20px'
            }} hidden={!isMobile}>
                <button onClick={()=>setStep(2)} style={Style.backBtnMobile} className={`outline-btn--${segments}`}>
                    Back
                </button>
                <Button loading={loading} style={{...Style.submitBtnStyle, opacity: validatePhotos() ? 0.5 : 1}} onClick={()=>checkDetails()} disabled={validatePhotos()}>
                    <div style={{...Style.boldText, color: "#fff", display: 'inline-block', marginLeft: loading ? '20px' : '0px'}}>{props.details.step == 3 || props.details.step == 4 || props.details.step == 5 ? 'Submit and next' : 'Save'}</div>
                </Button>
            </div>

            <Modal
                visible={blockModal}
                onCancel={()=>setBlockModal(false)}
                footer={null}
                >
                <div style={{marginTop: '50px'}} align='center'>
                    <img src={CameraIcon} />
                    <div style={{...Style.boldText, color: '#2b2d33'}}>Camera access is disabled</div>
                    <div style={{...Style.normalText, marginTop: '20px'}}>
                        {messageModal}
                    </div>
                    <button style={{...Style.submitBtnStyle, marginTop: '20px', color:'#fff', fontWeight: '600'}} onClick={() => setBlockModal(false)}> Got It </button>
                </div>
            </Modal>

            <div style={Style.cameraCardWeb} align='center' hidden={!cameraModal}>
                <div align="right"><button style={Style.closeCamera} onClick={() =>closeCamera()}><Icon type="close" /></button></div>
                <video ref={playerRef} style={{ width: '100%', height: '90%'}} playsInline />
                <button style={Style.captureBtn} onClick={() => captureImage(playerRef.current)}/>
                <canvas ref={canvasRef} width="1000" height="600" style={{ display: 'none', position: 'absolute'}} />
            </div>
        </div>
    )
}

export default IdentityVerification