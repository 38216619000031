import React from 'react';
import { Modal, Typography, Icon, message } from 'antd';
import { Handle401 } from '../../components/handle401/handle401';
import { history } from '../../store/history';
import axios from 'axios'
import { getLatestBalance } from '../../components/get_balance/get_balance';

const ConvertConfirmationModal = (props) => {

    const { segments, visible, type, onClose, requestedSegment} = props;

    const userId = localStorage.getItem('userId');
    
    const segmentChoices = {
        'me_': 'BUxME',
        'gig': 'BUxGIG',
        'sme': 'BUxSME',
        'biz': 'BUxBIZ'
    }

    const upgradeAccount = async () => {
        let params = {
            userId: userId,
        }

        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try {
            const url = '/api/user/convert/';
            let response = await axios.post(
                process.env.REACT_APP_API_URL + url, params, yourConfig
            );

            if (response.data.status === "success") {
                await getLatestBalance();
                if (segments == 'sme') {
                    history.push('/survey_enterprise');
                } else {
                    history.push('/dashboard');
                }
                //message.info('Successfully upgraded your account level!', 3);
                //setShowConvertConfirmModal(false);

            }

            else {
                message.info(response.data.message, 3);
                onClose();
            }
            //setDatas(response.data)
            //setDashboard(response.data.week)
            //setShowConvertWelcomeModal(response.data.show_conversion_welcome)
        }

        catch (error) {
            if ((error.response) && (error.response.status === 401)) {
                Handle401()
            }

        }

    }

    const requestConversion = async () => {
        let params = {
            userId: userId,
            requestedSegment: requestedSegment.toUpperCase(),
        }

        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try {
            const url = '/api/request/convert/';
            let response = await axios.post(
                process.env.REACT_APP_API_URL + url, params, yourConfig
            );

            if (response.data.status === "success") {
                message.info('Successfully requested account conversion!', 3);
                onClose();
            }

            else {
                message.info(response.data.message, 3);
                onClose();
            }
        }

        catch (error) {
            if ((error.response) && (error.response.status === 401)) {
                Handle401()
            }

        }

    }

    return (
        <Modal
            width={360}
            title={type === 0 ? "Convert User" : "Send Request"}
            visible={visible}
            onCancel={onClose}
            footer={[
                <button key="back"
                    onClick={onClose}
                    className={`outline-btn--${segments}`}>
                    Cancel
                </button>,
                <button key="submit" type="primary"
                    onClick={type === 0 ? () => upgradeAccount() : () => requestConversion()}
                    className={`btn--${segments}`}>
                    Proceed
                </button>,
            ]}>
            {
                type === 0 ?
                    <div style={{ fontSize: '16px', textAlign: 'left', padding: '10px 0px' }}>
                        Are you sure you want to upgrade your account level from {segmentChoices[segments]} to {segmentChoices[requestedSegment]}?
                    </div> :
                    <div style={{ fontSize: '16px', textAlign: 'left', padding: '10px 0px' }}>
                        Are you sure you want to request for account conversion from {segmentChoices[segments]} to {segmentChoices[requestedSegment]}?
                    </div>
            }
        </Modal>
    )
}

export default ConvertConfirmationModal;