import React from "react";
import { Modal, Typography } from "antd";

const DeactivateSubwalletModal = ({ visible, onCancel, onConfirm, data }) => {
  const segments = localStorage.getItem("segments");

  const handleConfirm = () => {
    onConfirm(data);
  };

  return (
    <Modal width={360} visible={visible} onCancel={onCancel} footer={null}>
      <Typography.Title style={styles.title}>
        Deactivate Sub-wallet
      </Typography.Title>
      <Typography.Paragraph style={styles.body}>
        Are you sure you want to deactivate this sub-wallet?
        <br />
        {data && data.email && (
          <div style={{ textAlign: "center" }}>
            <b>{data.email}</b>
          </div>
        )}
      </Typography.Paragraph>
      <div style={styles.actions}>
        <button className={`outline-btn--${segments}`} onClick={onCancel}>
          Cancel
        </button>
        <button className={`btn--${segments}`} onClick={handleConfirm}>
          Confirm
        </button>
      </div>
    </Modal>
  );
};

export default DeactivateSubwalletModal;

const styles = {
  title: {
    fontSize: 20,
  },
  body: {
    fontSize: 16,
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 4,
  },
};
