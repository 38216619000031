import React, {useEffect} from 'react'
import axios from 'axios'
import moment from 'moment'
import {Card, Button, Select, Icon, Badge, Divider} from 'antd'
import ReconStyle from '../../static/css/Recon'
import Avatar from '../../static/icons/Avatar.svg'
import MetaTag from '../meta_tag/meta_tag'
import { Handle401 } from '../handle401/handle401';
import {history} from '../../store/history'
import { useSelector } from 'react-redux';
import {isMobile} from 'react-device-detect'
import {OTC_LIST, BANK_LIST, MOBILE_LIST, randomize, OTC, BANK, MOBILE} from '../constants/constants'
import FilterDrawer from './filterDrawer'

const MobileReconEnterprise = props => {

    const rs = ReconStyle()
    const ButtonGroup = Button.Group;
    const {Option, OptGroup} = Select;

    const [show, setShow] = React.useState('Accounted')
    const [drawerTitle, setDrawerTitle] = React.useState("")
    const [datas, setDatas] = React.useState([])
    const [totalAmount, setTotalAmount] = React.useState(0)
    const [totalReceivable, setTotalReceivable] = React.useState(0)

    const [startDate, setStartDate] = React.useState('')
    const [endDate, setEndDate] = React.useState('')
    const [startMoment, setStartMoment] = React.useState("")
    const [endMoment, setEndMoment] = React.useState("")
    
    const [hideClear, setHideClear] = React.useState(true)
    const [methodFiltered, setMethodFiltered] = React.useState(false) 
    const [visible, setVisible] = React.useState(false);
    const [dayFilter, setDayFilter] = React.useState("Today")
    const [searchValue, setSearchValue] = React.useState("")
    const [dateFiltered, setDateFiltered] = React.useState(false) 

    const [checkAllOTC, setCheckAllOTC] = React.useState(false)
    const [checkAllWeb, setCheckAllWeb] = React.useState(false)
    const [checkAllWallet, setCheckAllWallet] = React.useState(false)
    const [ccSelected, setCCSelected] = React.useState(false)
    const [endOpen, setEndOpen] = React.useState(false)

    const [otcCheckedList, setOtcCheckedList] = React.useState([])
    const [webCheckedList, setWebCheckedList] = React.useState([])
    const [walletCheckedList, setWalletCheckedList] = React.useState([])

    const endpoints = useSelector(state => state.child_endpoints.endpoints);
    const isChild = localStorage.getItem("is_child") === "true"


    const status = [
        'Accounted', 'Unaccounted'
    ]

    const disabledStartDate = startValue => {
      
        if (!startValue || !endMoment) {
          return false;
        }
        return startValue && startValue > moment().endOf('day');
      };
    
    const disabledEndDate = endValue => {
        if (!endValue || !startMoment) {
            return false;
        }
        return endValue.valueOf() <= startMoment.valueOf();
    };
    
    const onStartChange = value => {
        if(value != null && endDate != ""){
            setStartMoment(value)
            setStartDate(value.format("MM-DD-YYYY"))
            setHideClear(false)
            setDateFiltered(true)
        }
        else if(value != null){
            setStartMoment(value)
            setStartDate(value.format("MM-DD-YYYY"))
        }
        else{
            setStartMoment(null)
            setStartDate("")
            setHideClear(false)
            setDateFiltered(true)
            
        }
    }
    const onEndChange = value => {

        if(value != null && startDate != ""){
            setEndMoment(value)
            setEndDate(value.format("MM-DD-YYYY"))
            setHideClear(false)
            setDateFiltered(true)

        }
        else{
            setEndMoment(null)
            setEndDate("")
            setHideClear(false)
            setDateFiltered(true)
        }
    }

    const handleStartOpenChange = open => {
        if (!open) {
            setEndOpen(true)
        }
    };

    const handleEndOpenChange = open => {
        setEndOpen(open)
    };

    const clearFilter = () => {
        setOtcCheckedList([])
        setWebCheckedList([])
        setWalletCheckedList([])
        setCheckAllOTC(false)
        setCheckAllWeb(false)
        setCheckAllWallet(false)
        setStartDate(moment().format("MM-DD-YYYY"))
        setEndDate(moment().format("MM-DD-YYYY"))
        setStartMoment("")
        setEndMoment("")
        setSearchValue("")
        setDayFilter("Today")
        setDateFiltered(false)
        setMethodFiltered(false)
        setHideClear(true)
        getRecon(show, moment().format("MM-DD-YYYY"), moment().format("MM-DD-YYYY"))
    }

    const submitApplyFilter = () => {
        getAllKeys(otcCheckedList, webCheckedList, walletCheckedList, ccSelected)
        getRecon(show,startDate, endDate)
        setHideClear(false)
        setVisible(false)
    }

    const getAllKeys = (otcList, webList, walletList, ccSelect) => {
        let all_otc = []
        let all_web = []
        let all_wallet = []
    
        for(let i=0; i < otcList.length; i++){
          let name = otcList[i]
          all_otc.push(OTC[name].code)
        }
        for(let i=0; i < webList.length; i++){
          let name = webList[i]
          all_web.push(BANK[name].code)
        }
        for(let i=0; i < walletList.length; i++){
          let name = walletList[i]
          all_wallet.push(MOBILE[name].code)
        }
    
        let otc_w_web = all_otc.concat(all_web)
        let all_channels = otc_w_web.concat(all_wallet)
        if(ccSelect){
          all_channels.push('visamc')
        }

        console.log(all_channels)
        return all_channels
    }

    const showDrawer = (e) => {
        console.log(e)
        if(e == 'payment') {
          setDrawerTitle('Filter')
        }
    
        else if (e == 'calendar') {
          setDrawerTitle('Date Filter')
        }
    
        else if (e == 'search') {
          setDrawerTitle('Search Payout')
        }
    
        setVisible(true)
    }

    const onClose = () => {
        setVisible(false)
    }

    const onCheckAllOtcChange = e => {
        let new_list = e.target.checked ? OTC_LIST : []
        setOtcCheckedList(new_list)
        setCheckAllOTC(e.target.checked)
        setMethodFiltered(e.target.checked)
        setHideClear(false)
    };
    
    const onChangeOtc = list => {
        setOtcCheckedList(list)
        setCheckAllOTC(list.length == OTC_LIST.length)
        setMethodFiltered(true)
        setHideClear(false)
    };
    
    const onCheckAllWebChange = e => {
        let new_list = e.target.checked ? BANK_LIST : []
        setWebCheckedList(new_list)
        setCheckAllWeb(e.target.checked)
        setMethodFiltered(e.target.checked)
        setHideClear(false)
    }
    
    const onChangeWeb = list => {
        setWebCheckedList(list)
        setCheckAllWeb(list.length == BANK_LIST.length)
        setMethodFiltered(true)
        setHideClear(false)
    }
    
    const onCheckAllWalletChange = e => {
        let new_list = e.target.checked ? MOBILE_LIST : []
        setWalletCheckedList(new_list)
        setCheckAllWallet(e.target.checked)
        setMethodFiltered(e.target.checked)
        setHideClear(false)
    }
    
    const onChangeWallet = list => {
        setWalletCheckedList(list)
        setCheckAllWallet(list.length == MOBILE_LIST.length)
        setMethodFiltered(true)
        setHideClear(false)
    }

    async function getRecon (val, start_date, end_date) {
        console.log(val, start_date, end_date)
        let yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }

        try{
          let response = await axios.get(
            process.env.REACT_APP_API_URL + 
            `/api/recon/?accounting_status=${val.toLowerCase()}&filter_type=transaction_date&start=${start_date}&end=${end_date}`,
            yourConfig
          );

          console.log(response.data)
          
          setDatas(response.data)
          let amount = 0, receivable = 0;

            for(let i=0;i<response.data.length;i++){
                amount += response.data[i].amount
                receivable += response.data[i].receivable
            }
            datas.push(['Total',amount,receivable])

            setTotalAmount(amount)
            setTotalReceivable(receivable)

        }

        catch(error){
          if((error.response)&&(error.response.status == 401)){
            Handle401()
          }
        }
    }

    useEffect(() => {
        let current_path = window.location.pathname
        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            history.push('/messenger?next='+current_path)
        }
        else{
            if(!isMobile){
            history.push('/messenger/error')
            }
            else{
                getRecon(show,moment().format("MM-DD-YYYY"), moment().format("MM-DD-YYYY"))
                setStartDate(moment().format("MM-DD-YYYY"))
                setEndDate(moment().format("MM-DD-YYYY"))
                // getCCStatus();
                
                // delete MLhuillier for individual
                if(localStorage.getItem('userType') === 'ME') {
                    delete OTC['MLhuillier'];
                }
            }
        }

    }, [])


    useEffect(() => {
        if (!isChild || !endpoints.length) {
          return;
        }
    
        // Redirect if URL was manually typed
        if(isMobile && !endpoints.includes('recon')) {
          history.push(endpoints[0]);
        }
    }, [endpoints])


    // If endpoints have not been loaded yet for mobile view. Hook on endpoints will handle redirect
    if (isChild && isMobile && !endpoints.includes('recon')) {
        return null;
    }

    return(
        <div style={{padding: 20, backgroundColor: '#F4F6F9', height: datas.length == 0 ? '100vh' : '100%'}}>
            <MetaTag title="Reconciliation" />

            <FilterDrawer 
                title={drawerTitle} visible={visible} onClose={onClose}
                checkAllOTC={checkAllOTC} onCheckAllOtcChange={onCheckAllOtcChange} OTC_LIST={OTC_LIST} otcCheckedList={otcCheckedList} onChangeOtc={onChangeOtc}
                checkAllWeb={checkAllWeb} onCheckAllWebChange={onCheckAllWebChange} BANK_LIST={BANK_LIST} webCheckedList={webCheckedList} onChangeWeb={onChangeWeb}
                checkAllWallet={checkAllWallet} onCheckAllWalletChange={onCheckAllWalletChange} MOBILE_LIST={MOBILE_LIST} walletCheckedList={walletCheckedList} onChangeWallet={onChangeWallet}
                setCCSelected={setCCSelected} submitApplyFilter={submitApplyFilter}
                dayFilter={dayFilter} setDayFilter={setDayFilter} setDateFiltered={setDateFiltered}
                disabledStartDate={disabledStartDate} disabledEndDate={disabledEndDate} onStartChange={onStartChange} onEndChange={onEndChange} handleStartOpenChange={handleStartOpenChange} handleEndOpenChange={handleEndOpenChange}
                startMoment={startMoment} endMoment={endMoment} endOpen={endOpen}
            />

            <div align="center" style={{marginTop: 12}}>
                <ButtonGroup>
                    {
                        status.map((item) => {
                            return(
                                <Button 
                                key={item.toLocaleLowerCase()}
                                onClick={() => {setShow(item); getRecon(item, startDate, endDate)}}
                                className={rs.btnGrp}
                                style={{
                                    color: show === item ? '#fff' : '#2b2d33',
                                    border: show === item ? '1px solid rgb(10,49,94)' : '',
                                    borderTopLeftRadius: item === "Accounted" ? '10px' : '',
                                    borderBottomLeftRadius: item === "Accounted" ? '10px' : '',
                                    borderBottomRightRadius: item === "Unaccounted" ? '10px' : '',
                                    borderTopRightRadius:  item === "Unaccounted" ? '10px' : '',
                                    backgroundColor: show === item ? 'rgb(10, 49, 94)' : '#fff',
                                }}>
                                    {item}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>

                <Card style={{marginTop: 16}}
                    title={
                    <div className={rs.pillFlex}>
                        <button className={rs.dayPill} disabled={hideClear} onClick={clearFilter}>
                        {hideClear ? 'Today' : <span> <Icon type="close-circle" theme="filled" style={{paddingRight: 4}} />Clear Filter </span> }
                        </button>

                        <div>
                        {/* <a className={rs.iconLinkStyle} onClick={() => showDrawer('payment')}>
                            <Icon type="filter" /> 
                            <Badge hidden={!methodFiltered} className={rs.badgePos} status="warning" />
                        </a> */}

                        <Divider type="vertical" />
                        
                        <a className={rs.iconLinkStyle} onClick={() => showDrawer('calendar')}>
                            <Icon type="calendar" />
                            <Badge hidden={!dateFiltered} className={rs.badgePos} status="warning" />

                        </a>

                        <Divider type="vertical" />

                        {/* <a style={{color: '#000000'}} onClick={() => showDrawer('search')}>
                            <Icon type="search" />
                        </a> */}
                        </div>
                    </div>
                    }>
                        <div className={rs.pillFlex}>
                            <div>
                                Total Amount:
                            </div>
                            <div className={rs.mainText} align="right">
                                PHP {parseFloat(totalAmount).toFixed(2)}
                            </div>
                        </div>
                        <div className={rs.pillFlex}>
                            <div>
                                Total Receivable:
                            </div>
                            <div className={rs.mainText} align="right">
                                PHP {parseFloat(totalReceivable).toFixed(2)}
                            </div>
                        </div>

                </Card>
                
                <div style={{marginTop: 16}}>
                    <div className={rs.transactionText}>
                    Transactions
                    </div>

                    {
                        datas != null && datas.length != 0 ?
                            datas.length > 0 && datas.map((item, i) => {
                                return(
                                    <div className={rs.reconCard}>
                                        <Card hoverable
                                            title={
                                                <div>
                                                    <div className={rs.mainText}>
                                                        {item.ref_code}
                                                    </div>
                                                    <div className={rs.pillFlex} style={{marginBottom: 0 }}>
                                                        <div>
                                                            {
                                                                OTC_LIST.includes(item.payment_method) ?
                                                                "Over-the-counter" :
                                                                BANK_LIST.includes(item.payment_method) ?
                                                                "Web Banking" :
                                                                "Others"
                                                            }
                                                        </div>

                                                        <div className={rs.mainText} align="right">
                                                            {item.payment_method}
                                                        </div>
                                                    </div>
                                                </div>
                                            }>
                                                <div>
                                                    <div className={rs.pillFlex}>
                                                        <div>Total Amount</div>
                                                        <div className={rs.mainText} align="right">
                                                            PHP {parseFloat(item.amount).toFixed(2)}
                                                        </div>
                                                    </div>
                                                    <div className={rs.pillFlex}>
                                                        <div>Receivable</div>
                                                        <div className={rs.mainText} align="right">
                                                            PHP {parseFloat(item.receivable).toFixed(2)}
                                                        </div>
                                                    </div>

                                                    <div className={rs.cardFooter}>
                                                        <div className={rs.pillFlex}>
                                                            <div style={{fontSize: 12}}>
                                                                Transaction Date:
                                                            </div>
                                                            <div style={{fontSize: 11}}>
                                                                {item.transaction_date}
                                                            </div>
                                                        </div>
                                                        <div className={rs.pillFlex} hidden={show === "Unaccounted"}>
                                                            <div style={{fontSize: 11}}>
                                                                Settlement Date:
                                                            </div>
                                                            <div style={{fontSize: 12}}>
                                                                {item.settlement_date}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </Card>
                                    </div>
                                )
                            }, this) 

                            :
                            
                            <div className={rs.nothingToShow} align="center">
                                Nothing to show
                            </div>
                    }
                </div>
            </div>

        </div>
    )
}


export default MobileReconEnterprise