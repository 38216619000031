import React from 'react';
import { isMobile } from 'react-device-detect';
import { history } from '../../store/history';

const ContentCard = props => {
    const {
        step, icon, image, title, content, additional_content, hasAdditional
    } = props;


    const segments = localStorage.getItem('segments')
    const userType = localStorage.getItem('userType')
    const level = localStorage.getItem('Level')
    const overall = userType === 'ME' && level == 0 ? "09" :
                        userType === 'ME' && level != 0 ? "08" :
                            userType === 'CO' && level != 0 ? "10" : "11"

    const nextBtn = () => {
        let nextStep = step + 1;
        window.location.href = `/how_it_works#${nextStep}`
    }

    const previousBtn = () =>  {
        let prevStep = step - 1;
        window.location.href = `/how_it_works#${prevStep}`
    }

    const redirectComplete = () => {
        if(userType === "ME") {
            history.push('/complete_profile')
        }

        else {
            history.push('/upgrade_enterprise')
        }
    }

    const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
    const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
    const isDell1 = window.screen.width * window.devicePixelRatio === 1366 && window.screen.height * window.devicePixelRatio === 768;

    const smallReso = isSmallReso || isDell || isDell1

    return(
        <div className={`card-hiw ${!isMobile && 'top-20'}`}>
            <div className="twoCol">
                <div className={`userNameStyle text-${segments}`}>
                
                   {step < 10 && "0"}{step} / {overall}
                </div>

                <div>
                    {
                        step > 1 && 
                            <button className={`outline-btn--${segments} ${isMobile ? 'right-8' : 'right-16'}`} 
                                onClick={() => previousBtn()}>
                                Previous
                            </button>
                    }
                    
                    {
                        (step < parseInt(overall) && userType === 'ME' ||
                        step < parseInt(overall) && userType === 'CO') &&
                        <button className={`btn--${segments}`} onClick={
                            () => nextBtn()
                        }>
                            Next
                        </button>
                    }

                    {   
                        (level == 0 && step == 9 && userType === 'ME' ||
                        level == 0 && step == 11 && userType === 'CO') &&
                        <button className={`btn--${segments}`} onClick={
                            () => redirectComplete()
                        }>
                            Complete {userType === 'ME' ? "KYC" : "KYB"} {!isMobile && "now"}
                        </button>
                    }
 
                </div>
            </div>
            {
                isMobile ?
                    <div className="top-40">
                        {
                            image &&
                            <img src={image} style={{width: 234}} alt="spiel" />
                        }

                        <div className="top-32" align="left">
                            {
                                icon &&
                                <img src={icon} style={{width: 24}} className="right-16" alt="icon"/>
                            }
                            <span className="bold-text-size top-4">
                                {title}
                            </span>

                            <div className="top-16 modal-sub-text">
                                {content}
                            </div>
                        </div>
                    </div>
                :
                
                <div className="twoCol top-40">
                    <div align="left" className="top-60" style={{width: step == '7' ? '100%' : '50%'}}>
                        {
                            icon && 
                            <img src={icon} className="right-16" alt="icon"/>
                        }
                        <span className="modal-header-text top-4">
                            {title}
                        </span>

                        <div className="top-24 modal-sub-text">
                            {content}
                        </div>
                    </div>

                    <div hidden={step == '7'} style={{width: '50%'}}>
                        {
                            image &&
                            <img src={image} style={{width: smallReso && 300}} alt="spiel" />

                        }
                    </div>
                    
                </div>
            }

            {
                hasAdditional &&
                additional_content
            }

        </div>
    )
}

export default ContentCard;