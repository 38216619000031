import React from 'react';
import { Button, Layout, message, Typography } from 'antd';
import { isMobile } from 'react-device-detect';
import { BUx } from '../../../static/icons/bux_qr';
import NewCheckouBG from '../../../static/icons/newCheckoutBGTop.svg';
import Shopify from '../../../static/icons/shopify.svg';
import { Step2, Step3_1, Step3_2, Step4, Step5, Step6 } from '../../../static/new_segment/tutorials/shopify';

const { Header } = Layout;

const stepStyle = {
    instruction: {
        color: '#000',
        fontWeight: '400',
        fontSize: '16px'
    },
    specialChar: {
        color: 'rgba(43, 45, 50, 0.8)',
        fontSize: '16px',
        fontWeight: '400',
    },
    center: {
        display: 'flex',
        justifyContent: 'center'
    },
    textareaStyle: {
        backgroundColor: '#EEEEEE',
        width: isMobile? '100%' : '84%',
        height: '100%',
        fontFamily: 'monospace',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#000',
        marginTop: '40px',
        border: '1px solid #EEEEEE',
        resize: 'none',
        borderRadius: '8px', 
        padding: '24px'
    },
    copyBtn: {
        height: '40px',
        width: '86px',
        borderRadius: '4px',
        backgroundColor: '#F5922F',
        color: '#fff',
        border: '1px solid #F5922F',
        fontWeight: '600',
        fontSize: '16px',
        position: 'absolute',
        marginTop: '50px',
        marginLeft: '-100px'
    },
}

const shopifyCode =
    `{% if order.gateway == 'Bux Payments' %}
<script>
var base_url = 'https://bux.ph/shopify/';
var client_id = '<BUX_CLIENT_ID>';
var order_id = {{ checkout.order_id }};
var redirect_url = base_url + client_id + '/' + order_id;

    Shopify.Checkout.OrderStatus.addContentBox(
    '<h2> BUx Payments </h2>',
    '<h4> You need to go to BUx to complete your payment. <br/> <br/> <br/><a href="'+redirect_url+'" onclick="location.href=this.href;return false;"> Go to BUx</a></h4>')
</script>
{% endif %}`

let shopify_code

function copyCode() {
    shopify_code.select()
    document.execCommand("copy")
    message.destroy()
    message.info("Copied Shopify script")
    shopify_code.blur()
}

const steps = [
    {
        instruction: <Typography style={stepStyle.instruction}>Create and install a custom app.</Typography>,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>Add Read and Write admin API permissions for the custom app for Order Editing and Orders.</Typography>,
        img: Step2,
        note: `Copy your Client ID from Integration Credentials to “<BUX_CLIENT_ID>”`,
        specialInstruction: <div style={{ textAlign: 'left', padding: !isMobile && '0 48px' }}>
            <br /><br />
            <Typography style={stepStyle.specialChar}>
                After you've enabled custom app development, you can create and install a custom app in your Shopify admin.
                You need to set API scopes to determine which parts of your store the custom app can access.
                After the app is installed, you'll receive access tokens that the app uses to access information from your store using Shopify's APIs.
                <br /><br />
                The store owner, and collaborators and staff members with the relevant permissions, can create and install custom apps.
            </Typography>
            <br />
            <Typography style={{ ...stepStyle.specialChar, fontWeight: '700' }}>How to create the app</Typography>
            <br />
            <ol style={{ ...stepStyle.specialChar, paddingLeft: !isMobile && '20px' }}>
                <li>
                    From your Shopify admin, go to Apps.
                </li>
                <li>
                    Click Develop apps.
                </li>
                <li>
                    Click Create a custom app.
                </li>
                <li>
                    In the modal window, enter the App name and select an App developer. The app developer can be the store owner,
                    or any staff or collaborator account with the develop apps permissions.
                </li>
                <li>
                    Click Create app.
                </li>
            </ol>
        </div>,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>Go to Checkout Settings and under Orders Processing add the following code to additional script</Typography>,
        img: Step3_1,
        // img2: Step3_2,
        specialInstruction: <div style={{ ...stepStyle.center, width: '100%' }}>
            <div style={{ width: isMobile ? '92%' : '664px', height: '322px', marginBottom: '24px' }}>

                <textarea style={stepStyle.textareaStyle} ref={(textarea) => shopify_code = textarea} readOnly>
                    {shopifyCode}
                </textarea>
                <Button style={stepStyle.copyBtn} onClick={() => copyCode()}>Copy</Button>
            </div>
        </div>
    },
    {
        instruction: <Typography style={{...stepStyle.instruction, width: isMobile && '100%', whiteSpace: 'break-spaces', textOverflow: 'unset', overflow: 'scroll'}}>
            Add webhook for Order Creation, JSON format.
            Pointing to the url: "https://api.bux.ph/v1/api/shopify/
            {`<client_id>`}/generate/" (Replace {`<client_id>`} 
            with your CLIENT_ID on integration credentials).
            Save the verification token below for the next step.
        </Typography>,
        img: Step4,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>On your BUx Dashboard, Click <b>Link Store</b>.</Typography>,
        img: Step5,
    },
    {
        instruction: <Typography style={stepStyle.instruction}>On your BUx Dashboard, Click <b>Link Store</b>.</Typography>,
        img: Step6,
    },
    {
        instruction: <div style={{ textAlign: 'left' }}>
            <Typography style={stepStyle.instruction}>Click the <b>Shopify</b> card and enter the following credentials:</Typography>
            <br />
            <ul style={stepStyle.instruction}>
                <li>
                    APP API Key - APP API key of the BUx Private App
                </li>
                <li>
                    APP Password - APP Password of the BUx Private App
                </li>
                <li>
                    Webhook Token - Verification token indicated on the webhook page
                </li>
            </ul>
        </div>,
        type: 'list'
    },
]

const ShopifyTutorial = (props) => {

    return (
        <div style={styles.root}>
            {/* <div hidden={!isMobile} style={{ zIndex: '1', width: '100%', height: '60px', display: 'flex', justifyContent: 'center', background: 'linear-gradient(102.61deg, #004888 0%, #00BBFF 100%)', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)' }}>
                <img src={BUx} style={{ maxWidth: '72px' }} alt="#" />
            </div> */}
            <div style={{ zIndex: '1', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: !isMobile && '24px 0' }}>
                <img src={BUx} alt="BUx" />
            </div>
            <div style={styles.body}>
                <Typography style={styles.title}>How to Integrate</Typography>
                <img src={Shopify} alt="Shopify" style={{ marginBottom: '48px' }} />
                {
                    steps.map((step, i) => {
                        return (
                            <>
                                {step.specialInstruction && step.specialInstruction}
                                <img hidden={!step.img} src={step.img} alt={`Step ${i + 1}`} style={{ marginTop: '32px', width: isMobile && '100%' }} />
                                <img hidden={!step.img2} src={step.img2} alt={`Step ${i + 1}`} style={{ margin: '32px 0', width: isMobile && '100%' }} />
                                <Typography hidden={!step.note} style={styles.note}>{step.note}</Typography>
                                <div style={{ ...styles.inlineFlex, alignItems: (typeof (step.type) === "undefined" || isMobile) ? 'center' : 'flex-start', marginTop: steps.length === i + 1 && '32px' }}>
                                    <Typography style={styles.stepBtn}>Step {i + 1}</Typography>
                                    {step.instruction}
                                </div>
                            </>
                        )
                    })
                }
            </div>
        </div>
    )
}

const styles = {
    root: {
        backgroundColor: '#F1F2F6',
        backgroundImage: `url(${NewCheckouBG})`,
        backgroundRepeat: 'no-repeat',
        textAlign: 'center',
        width: '100%',
        paddingBottom: '2px'
    },
    body: {
        backgroundColor: '#FFF',
        borderRadius: '4px',
        padding: '40px 40px 72px 40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '80%',
        margin: '12px auto 72px auto',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)'
    },
    title: {
        color: '#000',
        fontSize: '24px',
        fontWeight: '700',
        marginBottom: '24px'
    },
    stepBtn: {
        color: '#FFF',
        fontSize: '16px',
        fontWeight: '700',
        textAlign: 'center',
        borderRadius: '10px',
        backgroundColor: '#F5922F',
        padding: '6px 8px',
        minWidth: '80px'
    },
    inlineFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '14px',
        flexDirection: isMobile ? 'column' : 'row',
        width: isMobile ? '90%' : '100%'
    },
    note: {
        color: '#000',
        fontSize: '16px',
        fontWeight: '400',
        margin: '24px 0'
    }
}

export default ShopifyTutorial;