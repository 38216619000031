import React from 'react';

const channels = [
        {
                c: '7-Eleven',
                min: '50 PHP',
                max: '10,000 PHP',
                emax: '10,000 PHP',
        },
        {
                c: 'Bayad Center',
                min: '50 PHP',
                max: '20,000 PHP',
                emax: '80,000 PHP',
        },
        {
                c: 'Cebuana Lhuillier',
                min: '50 PHP',
                max: '30,000 PHP',
                emax: '80,000 PHP',
        },
        {
                c: 'MLhuillier',
                min: '180 PHP',
                max: '30,000 PHP',
                emax: '80,000 PHP',

        },
        {
                c: 'ECPay',
                min: '50 PHP',
                max: '30,000 PHP',
                emax: '80,000 PHP',

        },
        {
                c: 'LBC',
                min: '50 PHP',
                max: '30,000 PHP',
                emax: '80,000 PHP',

        },
        {
                c: 'Robinsons Dept Store',
                min: '50 PHP',
                max: '30,000 PHP',
                emax: '80,000 PHP',

        },
        {
                c: 'Ruralnet',
                min: '50 PHP',
                max: '30,000 PHP',
                emax: '80,000 PHP',

        },
        {
                c: 'SM Department/Supermarket Savemore Counter',
                min: '50 PHP',
                max: '30,000 PHP',
                emax: '80,000 PHP',

        },
        {
                c: 'Expresspay',
                min: '50 PHP',
                max: '20,000 PHP',
                emax: '20,000 PHP',

        },
        {
                c: 'RD Pawnshop',
                min: '50 PHP',
                max: '20,000 PHP',
                emax: '20,000 PHP',
        },
        {
                c: 'Palawan Pawnshop',
                min: '50 PHP',
                max: '20,000 PHP',
                emax: '20,000 PHP',
        },
        {
                c: 'Da5',
                min: '50 PHP',
                max: '20,000 PHP',
                emax: '20,000 PHP',
        },
        {
                c: 'Pay&Go',
                min: '100 PHP',
                max: '5,000 PHP',
                emax: '5,000 PHP',
        },
        {
                c: 'Posible',
                min: '50 PHP',
                max: '10,000 PHP',
                emax: '10,000 PHP',
        },
        {
                c: 'USSC',
                min: '50 PHP',
                max: '20,000 PHP',
                emax: '20,000 PHP',
        },
        {
                c: 'Rustans Supercenter',
                min: '50 PHP',
                max: '20,000 PHP',
                emax: '20,000 PHP',
        },
        {
                c: 'Shopwise',
                min: '50 PHP',
                max: '20,000 PHP',
                emax: '20,000 PHP',
        },
        {
                c: 'Tambunting Pawnshop',
                min: '50 PHP',
                max: '20,000 PHP',
                emax: '20,000 PHP',
        },
        {
                c: 'CVM Pawnshop',
                min: '50 PHP',
                max: '20,000 PHP',
                emax: '20,000 PHP',
        },
        {
                c: 'Wellcome',
                min: '50 PHP',
                max: '20,000 PHP',
                emax: '20,000 PHP',
        }
]

const online_banking = [
        {
                c: 'BPI Online/Mobile',
                min: '50 PHP',
                max: '30,000 PHP',
                emax: '49,000 PHP',
                fee: '15 PHP'
        },
        {
                c: 'RCBC Online Banking',
                min: '50 PHP',
                max: '30,000 PHP',
                emax: '80,000 PHP',
                fee: '5 PHP'
        },
        {
                c: 'Unionbank Internet Banking',
                min: '50 PHP',
                max: '30,000 PHP',
                emax: '80,000 PHP',
                fee: '10 PHP'
        },
]

const e_wallets = [
        {
                c: 'GCash',
                min: '50 PHP',
                emax: '80,000 PHP',
                max: '30,000 PHP',
        },
        {
                c: 'Grabpay',
                min: '50 PHP',
                emax: '80,000 PHP',
                max: '30,000 PHP',
        }
]

const credit_debit = [
        // {
        //         c: 'Mastercard',
        //         min: '50 PHP',
        //         emax: 'No Limit',
        //         max: '10,000 PHP',
        // },
        // {
        //         c: 'Visa',
        //         min: '50 PHP',
        //         emax: 'No Limit',
        //         max: '10,000 PHP',
        // }
]

export const BUX_MAIN =[
    {
            id: '1',
            q: 'Do I have to pay to create an account in Bux?',
            a: 'Creating a BUx account is free..'
    },
    {
            id: '2',
            q: 'How do I Create an Account?',
            a: <div>
                        <p>
                                To create an account you may sign up via web or Facebook. 
                                To sign up via web, go to <a href={process.env.REACT_APP_BASE_URL} target="_blank">www.bux.ph</a> and click Sign Up. 
                                Proceed by selecting Individual Account and provide your email address, full name, and mobile password.
                                A verification link will be sent to you via email and you may then proceed to creating your profile by providing more information and continuing the registration process. 
                                To sign up via Facebook, simply chat with the BUx chatbot via your Facebook Messenger and follow the instructions given. 
                                The verification process goes the same way as the email process. 
                                Once you log in, an OTP will be sent to your mobile number. Use this OTP to log in via BUx or via email.
                        </p>
                </div>
      
    },
    {
            id: '3',
            q: 'How does it work?',
            a: <div>
                        <p>
                                After your successful sign-up, you need to complete your KYC information to be able to use BUx fully. 
                                Your KYC will then be approved within 1-2 business days. 
                                Once your KYC is approved, you can then create payment links via the web and Facebook Messenger or integrate any of your Sentro, Prestashop or woocommerce, store to our platform. 
                                You can then transfer funds collected from your customers to your bank account or mobile wallet. 
                                This can be launched via the web or Facebook Messenger.
                        </p>
                </div>
    },
    
 
]

export const FAQ_CORPORATE = [
        {
                id: '1',
                q: 'Do I have to pay to create a Enterprise Account in Bux? ',
                a: 'Creating a BUx account is free.',
        },
        {
                id: '2',
                q: 'How do I create an account in Bux?',
                a: 
                        <div>
                                <p>
                                        To create an account, you may sign up via the web or Facebook. 
                                        To sign up via the web, go to <a href={process.env.REACT_APP_BASE_URL} target="_blank">www.bux.ph</a>, and click “Sign Up”. 
                                        Proceed by selecting "Enterprise Account” tab and providing your Business Name, Nature of Business, email address, mobile number, and password. 
                                        After verifying your account through a verification link sent to your email, you may then proceed with the registration process by completing your KYB and providing more information. 
                                </p>
                        </div>
        },
        {
                id: '3',
                q: 'How do I complete my KYB application?',
                a: 
                        <p>
                                After your successful sign-up, you need to complete your KYB information to be able to unlock other features in Bux. 
                                Your KYB will then be approved within 1-2 business days. 
                                Once your KYB is approved, you can then generate payment links via the web and Facebook Messenger, integrate via API or integrate any of your Sentro, PrestaShop, or WooCommerce store to our platform. 
                                If you have not completed your KYB but wish to test our platform, you may enable our "test mode" button found on the sidebar.
                        </p>
        },
        {
                id: '4',
                q: 'How do I edit my KYB application?',
                a: 
                        <p>
                                After completing the initial KYB application, you will then be redirected to the review page. 
                                On this page, you may review and update your information by clicking the “Edit” button. 
                                Click “Submit” button once you are done to complete your application.
                        </p>
        },
        {
                id: '5',
                q: 'Who is Xendit and how do they help me accept Credit and Debit Card Payments?',
                a: 
                        <p>
                                Xendit is a Payment Gateway company specializing in Credit and Debit Card payments. 
                                BUx embeds Xendits card payment services inside BUx and this will allow you to manage all types of payments using one platform.
                        </p>
        },
        {
                id: '6',
                q: 'How do I open an account with Xendit for Credit and Debit Card Payments?',
                a: 
                        <p>
                                You must first complete your KYB application process in Bux. 
                                Our team will reach out and you may inform us if you want to enable debit and credit card payments in Bux. 
                                If you wish to have card payments activated, you will receive an email that contains Xendit card payments contract that you can review in advance. 
                                Our team will reach out to you within 24-48 hours for next steps to activate your account. 
                                Full activation of your account will take 2-7 business days.
                        </p>
        },
        {
                id: '7',
                q: 'What is Test Mode?',
                a: 
                        <p>
                                Test mode will allow you to do test transactions on our test environment. 
                                There is no need to be KYB verified to use this feature. Payment links and APIs can be used and tested in this environment. 
                                For ecommerce plugins, you may download the plugins on the Integration page but you need a fully registered KYB account to do transactions as plugins do not support test transactions. 
                                You may also simulate paid transactions by going to the payment request tab → pending transaction →click on the action button, then click on simulate payment to tag a test payment into a paid one.
                        </p>
        },
        {
                id: '8',
                q: 'What is the Integration tab?',
                a: 
                        <p>
                                The integration tab will allow you to view our ecommerce plugins for Sentro, PrestaShop and WooCommerce as well as our APIs for direct API and BUx checkout page API. 
                                This is available both in our live environment and test environment under test mode. 
                                You will also be able to access Xendits API for Credit and Debit Card payments on this tab.
                        </p>
        },
        {
                id: '9',
                q: 'How does it work?',
                a: 
                        <p>
                               After your successful sign-up, you need to complete your KYB information to be able to unlock other features in Bux. 
                               Once your account is activated, you may create payment links via the web and Facebook Messenger, integrate via API, or via our ecommerce plugins for Sentro, PrestaShop and WooCommerce. 
                               For Enterprise Accounts, funds will then be transferred directly to your bank account within 1-2 business days.
                        </p>
        },
        {
                id: '10',
                q: 'How to add and remove users on my account?',
                a: 
                        <p>
                               For Enterprise Accounts, you may add users during your KYB process on the “Contact Person Info” step, which is done by the person who created the account. 
                               Once the account is activated, all users will be activated as well and they will receive an email to complete the verification process.  
                               Once completed, users can sign in. The person who created the account will be assigned as the Master Admin. 
                               The Master Admin can add or remove users by going to the account settings page, under User’s Access tab. If you wish to add additional users not included on the "Contact Info" step, you will first need to fully activate your account. 
                               Under your settings tab, you may add additional users. 
                               They will also receive an email to complete the verification process and once completed, users can sign in.
                        </p>
        },
        {
                id: '11',
                q: 'What is the difference between a Master Admin and a Regular Admin?',
                a: 
                        <p>
                               The Master Admin is the person who created the BUx account. 
                               A Regular Admin/s is/are the user/s who are added by the Master Admin as additional users to the BUx account. 
                               Both have the same features except that only the Master Admin can add additional users.
                        </p>
        },
        {
                id: '12',
                q: 'Can I transfer my access rights to a Master Admin?',
                a: 
                        <p>
                               This is not possible at the moment, but this feature will be available soon.
                        </p>
        },
        {
                id: '13',
                q: 'Can I configure the emails being sent?',
                a: 
                        <p>
                               You can only configure who will be the recipient of the payment by email. 
                               By default, all users will receive emails that will contain pending and paid transactions, as well as settlement report emails. 
                               You may opt-in to add and remove access for each user.
                        </p>
        },
        {
                id: '14',
                q: 'How do I set up my Business Name, Business logo, Social Media Page, and Website?',
                a: 
                        <p>
                               After a successful registration via the web or Facebook Messenger, you will receive a verification link and you will be redirected to a page where you can provide your business name, nature of business, social media links, and business website. 
                               All fields are optional except for the nature of business which is required. 
                               You may change your business logo on the Account’s Settings page under “My Profile” tab.
                        </p>
        },
        {
                id: '15',
                q: 'How do I set up my address?',
                a: 
                        <p>
                               You may provide this during the KYB process.
                        </p>
        },
        {
                id: '16',
                q: 'How do I set up my bank account?',
                a: 
                        <p>
                               You may provide this during the KYB process and in your contract (PFA).
                        </p>
        },
        {
                id: '17',
                q: 'How do I start accepting OTC, Online Banking and E-Wallet Payments?',
                a: 
                        <p>
                                Once your KYB application is approved and your account is activated, you can immediately start using these payment channels.
                        </p>
        },
        {
                id: '18',
                q: 'How do I start accepting Credit and Debit Card Payments?',
                a: 
                        <p>
                                Once your KYB application is approved, our sales team will reach out to you. 
                                You will be asked if you want to apply for Credit and Debit Card Payments on the BUx platform. Our Xendit team will reach out to you within 24-48 hours. 
                                Additional information might be required before this feature gets activated subject to approval and you will then receive an email notification 
                                to inform you that your application is under processing together with an attachment of a separate contract that you will need to sign for card payments from our partner Xendit.
                        </p>
        },
        {
                id: '19',
                q: 'Can my application for Credit and Debit Card Payments via Xendit be rejected?',
                a: 
                        <p>
                                Yes, Application for credit and debit card payments is subject to approval of our acquiring bank UnionBank of the Philippines.
                        </p>
        },
        {
                id: '20',
                q: 'How long does the Credit and Debit Card application process take?',
                a: 
                        <p>
                                Application takes 2-7 business days after submission of all documents and information required. 
                        </p>
        },
        {
                id: '21',
                q: 'If my Credit and Debit Card Payment application gets denied, can i still use OTC, Online Banking and E-Wallet Payments?',
                a: 
                        <p>
                                Yes, you still can use the other payment channels.
                        </p>
        },
        {
                id: '22',
                q: 'How do i avail Credit and Debit Card Payments',
                a: 
                        <p>
                                Credit and Debit Card payment acceptance is done through our partner Xendit. 
                                After completing your KYB process, our sales team will contact you for more required information, onboarding, and accreditation. 
                                Once approved, this payment method will be available to you. 
                                Approval takes 2-7 business days.
                        </p>
        },
        {
                id: '23',
                q: 'How is the settlement of funds made?',
                a: 
                        <p>
                               For OTC / Web / Online Banking transactions, all funds collected will be remitted to your preferred bank account within 1-2 days after the transaction is made. 
                               For credit and debit cards, funds will be transferred to your bank account within 3-4 business days.
                        </p>
        },
        {
                id: '24',
                q: 'Are there reports we can receive for payouts or can we generate this ourselves?',
                a: 
                        <p>
                               All users will receive a payout summary report via email containing all transactions made via all payment channels during the cutoff period. 
                               You may also go to your recon tab to view, filter, and export reports manually.
                        </p>
        },
        {
                id: '25',
                q: 'What is the Reconciliation tab found on the dashboard?',
                a: 
                        <p>
                               Reconciliation tab will allow you to view, filter, and export reports manually. 
                               All transactions on the “accounted” tab are paid transactions that have been successfully settled to your settlement bank account while on the “unaccounted” tab are paid transactions that have not been settled yet to your settlement bank account.
                        </p>
        },
        {
                id: '26',
                q: 'Where can I view all funds settled to my bank account?',
                a: 
                        <p>
                               All OTC, Web and E-Wallet payments settled to you can be found on your Recon tab. Under the Recon tab, 
                               all accounted transactions are transactions settled to your bank account while all unaccounted transactions are for pending settlement. 
                               For Credit and Debit Card payments, all accounted and unaccounted transactions will be available on Xendits portal.
                        </p>
        },
        {
                id: '27',
                q: 'How do i have access to Xendits Dashboard?',
                a: 
                        <p>
                               Access to Xendits dashboard will be provided to you separately by our team via email.
                        </p>
        },
        {
                id: '28',
                q: 'What is viewable on the BUx Dashboard and Xendits Dashboard?',
                a: 
                        <p>
                               You may view all your transactions for OTC, Mobile, Web and Card Payments on the BUx dashboard. 
                               You may also generate payments and integrate via API in Bux. For reconciliation purposes however, 
                               you may only view settlement transactions (transactions that are settled to your bank account) on your BUx dashboard under the Recon tab. 
                               For Card Payments reconciliation, this is only available on Xendits dashboard for now. 
                               Our upcoming features and enhancements will allow you to view all of this on your BUx dashboard.
                        </p>
        },
        {
                id: '29',
                q: 'Do I receive an email report?',
                a: 
                        <p>
                               Yes, you will receive email reports of all paid transactions that will be credited to your bank account for all OTC, Online Banking and E-Wallet transactions. 
                               For Credit and Debit Card payments, there are no reports that are sent but this is available on the Xendit Dashboard.
                        </p>
        },
        {
                id: '30',
                q: 'I have support related concerns on my transactions and settlement. Who do I contact?',
                a: 
                        <p>
                               You may chat on the intercom icon found on the website or your BUx dashboard.For transactional or settlement related concerns, you may contact us at <a href="mailto:support@bux.ph" style={{color: '#1d82b8', fontWeight: 600}}>support@bux.ph</a>. 
                               You may include Transactional Concern or Settlement Concern on the subject line for faster resolution.

                        </p>
        },
        {
                id: '31',
                q: 'Where do I find the contract and NDA?',
                a: 
                        <p>
                                BUx contract for OTC, Online Banking, and E-Wallet is found during your KYB application process. 
                                You may download the template and upload the countersigned copy. For credit and debit card payments, 
                                after successfully completing the KYB process, our team will reach out to you to ask if you want to avail of debit and credit card payments, 
                                if you opt in, an email notification will be sent to you stating that your application is for processing and a separate contract will be provided to you via email as an attachment if this feature is to be availed. 
                        
                        
                        </p>
        },
        {
                id: '32',
                q: 'How do I reset my password?',
                a: 
                        <p>
                                You can reset your password on the settings page under “My Profile” tab, 
                                You can also reset it on the login page by clicking the Forgot Password link and providing your email. 
                                The recovery link and how to reset your password instruction will be sent to your email.
                        </p>
        },
        {
                id: '33',
                q: 'Do you have plug-ins that I can use for my website?',
                a: 
                        <p>
                                BUx supports plug-ins for Sentro, WooCommerce, and Prestashop. 
                                Simply copy the token code to your e-commerce store and track orders and sales in one place.
                        </p>
        },
        {
                id: '34',
                q: 'How do I generate a payment link?',
                a: 
                        <div>
                                <p>
                                        Generation of payment links can be done in three ways: via the web, mobile web, and Facebook Messenger.
                                </p>
                                <p>
                                        For the web, go to your dashboard and click generate payment link on the top header. 
                                        Proceed by providing the amount, description, customer’s name, email, and mobile number. 
                                        You can share the payment link via Facebook Messenger and email, or copy the payment link.
                                </p>
                                <p>
                                        For mobile web and Facebook Messenger, go to your dashboard and click “Generate Payment Link.” Proceed by providing the amount, description, customer’s name, email, mobile number, and choose the expiration for the payment link. 
                                        You can share the generated payment link, via Facebook Messenger, WhatsApp, Viber, email, or by copying the payment link.
                                </p>
                        </div>
                    
        },
        {
                id: '35',
                q: 'How do I get notified if payment is successful?',
                a: 
                        <p>
                                Once payment is made, a notification email will be sent to you and your customer.
                        </p>
        },
        {
                id: '36',
                q: 'Where can I see my pending and expired payment transactions?',
                a: 
                        <p>
                                You can go to the “Payment Request” page and see all the tabs for paid, pending, and expired payment links.
                        </p>
        },
        {
                id: '37',
                q: 'Can I cancel my order?',
                a: 
                        <p>
                                Yes, you can cancel any order. 
                                Navigate on the “Payment Request” tab and click on the 3 dots found on the right side of the reference number you want to cancel. 
                                All cancelled orders can be found on the “Cancelled” tab. 
                                Take note only pending orders can be canceled.
                        </p>
        },
        {
                id: '38',
                q: 'Can I resend the payment link?',
                a: 
                        <p>
                                Yes, you can resend the payment link.
                                Navigate on the “Payment Request” page and click on the 3 dots found on the right side of the reference number and you will have the option to resend the link to your customer.
                        </p>
        },
        {
                id: '39',
                q: 'Can I offer discounts and promotional codes for my customers?',
                a: 
                        <p>
                                Not at this time, unfortunately.  Only BUx can provide promotions to both you and your customers. 
                        </p>
        },
        {
                id: '40',
                q: 'Can I view all expired orders?',
                a: 
                        <p>
                                Yes, you can view all expired orders by going to the “Expired” tab found on the "Payment Request" page. 
                        </p>
        },
        {
                id: '41',
                q: 'Who do I contact about issues?',
                a: 
                        <p>
                                You may chat on the intercom icon found on the website or your BUx dashboard.  
                                Alternatively, you can also send an email to Bux’s support team: <a href="mailto:support@bux.ph" style={{color: '#1d82b8', fontWeight: 600}}>support@bux.ph</a>.    
                        </p>
        },
        {
                id: '42',
                q: 'How do I cash out and how fast can I receive my funds?',
                a: 
                        <p>
                                Once your customers pay for the purchased product or service, a settlement will be done within 1-2 business days and will be credited to your nominated bank account.
                        </p>
        },
        {
                id: '43',
                q: 'Are there fees to be paid in Bux? ',
                a: 
                        <p>
                                Yes, BUx discloses the fees to be paid in your dashboard.  This may change from time to time and depending on the service type.  
                                For all over-the-counter channels, the fee is PHP 20. For mobile wallets, fees are 2.0% + PHP 10, while online banking fee is PHP 15 per transaction. 
                                Surcharge fees may also apply to online banking channels. Cash-out to any bank or mobile wallet is free via Instapay of PESONet.
                        </p>
        },
        {
                'id': '44',
                'q': 'What are the KYB requirements, upgrade limits, and benefits?',
                'a': <div>
                        <div>The following are the KYB requirements</div>
                        
                        <table style={{marginTop: '16px',width: '100%', border: '1px solid #000000'}}>
                                <tr>
                                        <td style={{width: '33%', padding: '12px'}} align='center'>
                                                <div><b>Level</b></div>
                                        </td>
                                        <td style={{width: '33%', padding: '12px', border: '1px solid #000000'}} align='center'>
                                                <div><b>KYB</b></div>
                                        </td>
                                        <td style={{width: '33%', padding: '12px', border: '1px solid #000000'}} align='center'>
                                                <div><b>Minimum Transactions to Upgrade</b></div>
                                        </td>
                                </tr>

                                <tr>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>Basic - Level 0</div>
                                        </td>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>Personal and Contact Information - First Name, Last Name, Email, Mobile Number and Password</div>
                                        </td>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>Created upon sign up</div>
                                        </td>
                                </tr>

                                <tr>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>Enterprise</div>
                                        </td>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>
                                                <p>Business Information, Contact List, Signatory IDs and Information, PFA and NDA</p>
                                                
                                                <p>Sole Proprietary Documents</p>
                                                <ul>
                                                        <li>
                                                        DTI or SEC Certificate
                                                        </li>
                                                        <li>
                                                        Mayor's Permit
                                                        </li>
                                                        <li>
                                                        Business Permit
                                                        </li>
                                                        <li>
                                                        BIR Tax Docs or Proof of Receipt from BIR
                                                        </li>
                                                        <li>
                                                        Valid ID of Authorized Signatory and Owner
                                                        </li>
                                                </ul>
                                                <p>Corporation or Partnership Documents</p>
                                                <ul>
                                                        <li>
                                                        SEC Registration
                                                        </li>
                                                        <li>
                                                        Articles of Incorporation
                                                        </li>
                                                        <li>
                                                        Bi-Laws
                                                        </li>
                                                        <li>
                                                        BIR 2303
                                                        </li>
                                                        <li>
                                                        Latest GIS
                                                        </li>
                                                        <li>
                                                        Secretary Certificate noting that the authorized signatory as the designated person to sign.
                                                        </li>
                                                        <li>
                                                        Business Permit
                                                        </li>
                                                        <li>
                                                        Valid ID of Authorized Signatory and Owner
                                                        </li>
                                                </ul>
                                                </div>
                                        </td>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>No minimum transactions required and No set up fees / annual fees</div>
                                        </td>
                                </tr>

                        
                        </table>

                        <div style={{marginTop: '32px'}}>Below are the limits and benefits table of KYB tiers</div>

                        <table style={{marginTop: '16px',width: '100%', border: '1px solid #000000'}}>
                                <tr>
                                        <td style={{width: '23%', padding: '12px',border: '1px solid #000000'}} align='center'>
                                                <div><b>Level</b></div>
                                        </td>
                                        <td style={{width: '23%', padding: '12px', border: '1px solid #000000'}} align='center'>
                                                <div></div>
                                        </td>
                                        <td style={{width: '23%', padding: '12px',border: '1px solid #000000'}} align='center'>
                                                <div><b>Cash In</b></div>
                                        </td>
                                        <td style={{width: '23%', padding: '12px', border: '1px solid #000000'}} align='center'>
                                                <div><b>Cash Out</b></div>
                                        </td>
                                </tr>

                                <tr style={{background: '#efefef'}} align='center'>
                                        <td style={{width: '23%', border: '1px solid #000000',padding: '12px'}}>
                                        Enterprise
                                        </td>

                                        <td style={{width: '23%', border: '1px solid #000000',}}>
                                                Monthly
                                        </td>

                                        <td style={{width: '23%', border: '1px solid #000000',}}>
                                                No Limits
                                        </td>

                                        <td style={{width: '23%', border: '1px solid #000000',}}>
                                                No Limits
                                        </td>
                                </tr> 
                        </table>

                        </div>

        },
        {
                'id': '45',
                q: 'Which IDs are accepted for the identity verification process?',
                a: <div>
                        <ul>
                                <li><b>Philippine Passport</b> - Department of Foreign Affairs</li>
                                <li><b>Driver's License</b> - Land Transportation Office</li>
                                <li><b>SSS</b> -  Social Security System</li>
                                <li><b>UMID Card</b> -  Social Security System</li>
                                <li><b>GSIS eCard</b> - Government Services and Insurance Corporation</li>
                                <li><b>Digitized Postal ID</b> - Philippine Postal Corporation</li>
                                <li><b>PRC ID</b> - Professional Regulation Commission</li>
                                <li><b>Alien Certificate of Registration</b> - ACR-I Card</li>
                        </ul>
                </div>
        },
        {
                'id': '46',
                q: 'How do I put my business name on the receipt instead of my full name',
                a: 
                        <div>
                                A business name can be provided upon successful registration of your account. 
                                After clicking on the verification link, you will be redirected to a page where you can provide your business name and on your KYB application. 
                                Once your business name is included, your payment notifications and receipts will show your business name instead of your full name for OTC payments. 
                                Changes will only apply to payment links that are generated after you input your business name.
                        </div>
        },
        {
                'id': '47',
                q: 'How to create a Enterprise account please follow the steps below:',
                a: 
                        <div>
                                Go to <a href={process.env.REACT_APP_BASE_URL} target="_blank">www.bux.ph</a> and sign up. 
                                Select Enterprise Account and complete all the information required. 
                                A verification link will be sent via email. 
                                Click the verification link and log in.
                        </div>
        },
        {
                'id': '48',
                q: 'What documents are required to be submitted?',
                a: 
                        <div>
                                <p>
                                On the KYB registration page, you will need to provide your business information, Signatory IDs and information, PFA and NDA and the following documents:
                                </p>

                                <p>
                                For Sole Proprietary or Partnership, the following documents are required
                                </p>

                                <p>
                                        <ul>
                                                <li>
                                                DTI or SEC Certificate
                                                </li>
                                                <li>
                                                Mayor's Permit
                                                </li>
                                                <li>
                                                Business Permit
                                                </li>
                                                <li>
                                                BIR Tax Docs or Proof of Receipt from BIR
                                                </li>
                                                <li>
                                                Valid ID of Authorized Signatory and Owner
                                                </li>
                                        </ul>
                                </p>

                                <p>Corporation or Partnership Documents</p>
                                                <ul>
                                                        <li>
                                                        SEC Registration
                                                        </li>
                                                        <li>
                                                        Articles of Incorporation
                                                        </li>
                                                        <li>
                                                        Bi-Laws
                                                        </li>
                                                        <li>
                                                        BIR 2303
                                                        </li>
                                                        <li>
                                                        Latest GIS
                                                        </li>
                                                        <li>
                                                        Secretary Certificate noting that the authorized signatory as the designated person to sign.
                                                        </li>
                                                        <li>
                                                        Business Permit
                                                        </li>
                                                        <li>
                                                        Valid ID of Authorized Signatory and Owner
                                                        </li>
                                                </ul>
                        </div>
        },
        {
                'id': '49',
                'q': 'What are the available channels and fees per channel?',
                'a': 
                        <div>
                                <p>
                                        Below is the list of available over-the-counter channels. Fee for over-the-counter payments is PHP 20 per transaction.
                                </p>

                                <table style={{marginTop: '16px', border: '1px solid #000000', width: '100%'}}>
                                        <tr>
                                                <td style={{padding: 5, width: '33%', border: '1px solid #000000'}}>

                                                </td>

                                                <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                                        <div>Minimum Amount</div>
                                                </td>

                                                <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                                        <div>Maximum Amount</div>
                                                </td>
                                        </tr>
                                        {
                                                channels.map((item, index) => {
                                                        return(
                                                                <tr key={index}>
                                                                        <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                                                        <div>{item.c}</div>
                                                                        </td>
                                
                                                                        <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                                                        <div>{item.min}</div>
                                                                        </td>
                                
                                                                        <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                                                        <div>{item.emax}</div>
                                                                        </td>
                                                                </tr>
                                                        )
                                                })
                                        }
                                </table><br/>

                        <div>Below are the list of available over the online banking channels. Fee for online banking payments is 15 Php per transaction.</div>
                        <table style={{marginTop: '16px', border: '1px solid #000000', width: '100%'}}>
                             <tr>
                                     <td style={{padding: 5, width: '60%', border: '1px solid #000000', textAlign: 'center'}}>
                                        Web Banking Channel (Online Banking Only)
                                     </td>

                                     <td style={{padding: 5, width: '15%', border: '1px solid #000000'}} align='center'>
                                        <div>Minimum Amount</div>
                                     </td>

                                     <td style={{padding: 5, width: '15%', border: '1px solid #000000'}} align='center'>
                                        <div>Maximum Amount</div>
                                     </td>
                                     <td style={{padding: 5, width: '10%', border: '1px solid #000000'}} align='center'>
                                        <div>Surcharge Fees</div>
                                     </td>
                             </tr>
                            {
                                    online_banking.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                        <td style={{padding: 5, width: '60%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.c}</div>
                                                        </td>
                
                                                        <td style={{padding: 5, width: '15%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.min}</div>
                                                        </td>
                
                                                        <td style={{padding: 5, width: '15%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.emax}</div>
                                                        </td>
                                                        <td style={{padding: 5, width: '10%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.fee}</div>
                                                        </td>
                                                </tr>
                                            )
                                    })
                            }
                        </table><br/>
                        <div><strong>*surcharge fees are additional  fees charged to the customer. </strong></div><br/>
                        <div>Below are the list of available over mobile wallet channels. Fees for mobile wallet payments is 2.0% + 10 Php per transaction.</div>
                        <table style={{marginTop: '16px', border: '1px solid #000000', width: '100%'}}>
                             <tr>
                                     <td style={{padding: 5, width: '33%', border: '1px solid #000000', textAlign: 'center'}}>
                                        Mobile Wallet Channels
                                     </td>

                                     <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                        <div>Minimum Amount</div>
                                     </td>

                                     <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                        <div>Maximum Amount</div>
                                     </td>
                             </tr>
                            {
                                    e_wallets.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                        <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.c}</div>
                                                        </td>
                
                                                        <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.min}</div>
                                                        </td>
                
                                                        <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.emax}</div>
                                                        </td>
                                                </tr>
                                            )
                                    })
                            }
                        </table><br/>

                        {/* <div>
                                <p>
                                Below is the fees for the Credit and Debit Card Payments. Fees for Credit and Debit Card payments is 3.5% + 12 Php.
                                </p>
                        </div>

                        <table style={{marginTop: '16px', border: '1px solid #000000', width: '100%'}}>
                             <tr>
                                     <td style={{padding: 5, width: '33%', border: '1px solid #000000', textAlign: 'center'}}>
                                     Credit and Debit Card Payments*
                                     </td>

                                     <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                        <div>Minimum Amount</div>
                                     </td>

                                     <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                        <div>Maximum Amount</div>
                                     </td>
                             </tr>
                            {
                                    credit_debit.map((item) => {
                                            return(
                                                <tr>
                                                        <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.c}</div>
                                                        </td>
                
                                                        <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.min}</div>
                                                        </td>
                
                                                        <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.emax}</div>
                                                        </td>
                                                </tr>
                                            )
                                    })
                            }
                        </table><br/>
                        <div><strong>*Additional 1 % international fee for cards issued outside the Philippines</strong></div><br/> */}

                        </div>
        },
        {
                'id': '50',
                'q': 'How do I set an expiration on my payment link?',
                'a': 
                        <p>
                                Upon creating the payment link, you can then choose the time when your payment link will expire. 
                                Choose from 6 hours, 12 hours, 1 day, 2 days, 3 days, or 7 days. By default, a payment link expiry is 6 hours. 
                                Note that this is expiry only of the payment link.
                        </p>
        },
        {
                'id': '51',
                'q': 'When my customers select a payment channel, when will this expire?',
                'a': 
                        <p>
                                By default, expiry is 2 days for over-the-counter channels and 1 hour for online banking and mobile wallet
                        </p>
        },
        {
                'id': '52',
                'q': 'How does my customer pay upon receiving an instructional receipt for Over-the-counter payments?',
                'a': 
                <div>
                        <p>
                                7-Eleven -  Your customers just need to show the barcode found on the receipt.
                        </p> 
                        <p>
                                Other over-the-counter channels - If through other over-the-counter channels, customers need to get a payment slip from the branch and fill it out.
                        </p>
                        <p>
                                Write the following on the payment slip:
                        </p>
                        <ol>
                                <li>
                                        BUX or Dragonpay as the biller name.
                                </li>
                                <li>
                                        Exact reference number should be provided. If the reference number is incorrect, the transaction will not be accepted.
                                </li>
                                <li>
                                        Ensure that the correct full name is provided as seen on the receipt.
                                </li>
                                <li>    
                                        Provide the correct amount as indicated on the receipt. It's important that the amount provided is correct for the transaction to push through.
                                </li>
                                <li>
                                        A mobile number should be provided to ensure that we can contact the customers immediately in the event that there are issues on the transactions.
                                </li>
                        </ol>

                        <p>
                        Instructions are also provided on the instructional receipt received by your customers. Please remind your customers to bring a valid ID.
                        </p>
                </div>
        },
        {
                id: '53',
                q: 'How can my customers pay via online banking channels - direct debit?',
                a: <div>
                     <p><strong>Online Banking Direct Debit  - The following banks support this instruction</strong></p>

                     <p>
                     BPI Online/Mobile, RCBC Online Banking, and Unionbank Internet Banking - Upon selecting the following online banks and clicking the “Pay Now” button, you will need to agree to its Terms and Conditions to proceed. 
                     You will be redirected to the bank’s login page. Input your username and password and click accept to proceed. 
                     You will receive a message from the bank that you have successfully registered  and you will be prompted to enter a one time password (OTP) that will be sent to you via SMS (SMS includes the amount you want to pay). 
                     Once you have input the correct OTP, just click “Confirm.” The payment success page will appear.
                     </p>
                </div>

        },
        {
                id: '54',
                q: 'How can my customers pay via the E-Wallet channel - direct debit?',
                a: <div>
                     <p><strong>GCash Direct Debit</strong></p>

                     <p>
                     For GCash - Upon selecting the “E-Wallet” option and Gcash, you will be redirected to the GCash login page. 
                     Input your mobile number then click “Accept” to proceed. You will receive a message from GCash confirming your successful registration and you will be prompted to enter a One-Time Password (OTP) that will be sent to you via SMS (SMS includes the amount you want to pay). 
                     Once you have input the correct OTP, just click “Confirm.” 
                     The payment success page will appear. Expiry is 1 hour by default.
                     </p>

                     <p><strong>Grabpay Direct Debit</strong></p>

                        <p>
                        For Grapay - Upon selecting the “E-Wallet” and Grabpay option, you will be redirected to the Grabpay app. 
                        You will be required to enter your login credentials. After successful login, an authorization for payment page will be displayed. 
                        Click “Confirm.” 
                        The payment success page will appear. Expiry is 1 hour by default.
                        </p> 
                </div>

        },
        {
                id: '55',
                q: 'Transaction Fees',
                a: 
                        <div>
                                <p>
                                By default, all transaction fees will be deducted on the total amount. 
                                </p>

                                <p>
                                If you want your transaction fees to be on top of the total amount, you may go to “Account Settings” and under the “General Settings” tab, click on the toggle button if you want the transaction fees to be on top of the total amount. 
                                Please note that transaction fees will vary depending on the payment channel selected by your customers. Please see the fee table for transaction fees for reference.
                                </p>

                        </div>
        },
        {
                id: '56',
                q: 'Is my BUx wallet balance updated real time?',
                a: 
                        <div>
                             For OTC / Web and E-Wallet your BUx wallet balance is updated real time if transactions are tagged as paid. 
                             For credit and debit card transactions, wallet balance is updated daily every 12mn.
                        </div>
        },
        {
                id: '57',
                q: 'I have an existing BUx Individual Account, can i upgrade this to an Enterprise account?',
                a: 
                        <div>
                             Yes you may, Please send us an email request at <a href="mailto:support@bux.ph" style={{color: '#1d82b8', fontWeight: 600}}>support@bux.ph</a> and we will process your request. 
                             Please note that you need to cashout / payout all your remaining balance in your BUx Individual account prior to having your account upgraded.
                        </div>
        }
]

export const FAQ_PROFILE = [
        {
                id: '1',
                q: 'How do I set up my username?',
                a: `After successful registration via web or facebook messenger, 
                go to the link provided and you will be instructed to provide your username. 
                This is the next registration process.`
        },
        {
                id: '2',
                q: 'How do I set up my address?',
                a: `You may provide this during the KYC process.`
        },
        {
                id:'3',
                q: 'How do I set up my bank account?',
                a:
                <div>
                        <p>
                                You may link your bank account details on your settings under Bank Account Tab. 
                                The maximum number of banks you can link is 3. You may change this from time to time. 
                                Payout will only be allowed if your bank account is named under the same full name you provided on your KYC profile.
                        </p>
                </div>
        },
        {
                id: '4',
                q: 'How do I reset my password?',
                a: 
                <div>
                        <p>
                                In the meantime, resetting of passwords can only be done via the web.  
                                In the log-in page, click on the Forgot Password link and provide your email. 
                                Recovery link and password change will be sent to your email. 
                        </p>
                </div>
        },
        
]

export const FAQ_PAYMENT = [
        {
                id: '1',
                q: 'How do I generate a payment link?',
                a: <div>
                        <p>
                                Generation of payment links can be done in three ways: via the web, mobile web, and Facebook Messenger.
                        </p>
                        <p>
                                For the web, go to your dashboard and click generate payment link on the top header. Proceed by providing the amount, description, customer’s name, email, and mobile number. 
                                You can share the payment link via Facebook Messenger and email, or copy the payment link.
                        </p>
                        <p>
                                For mobile web and facebook messenger, go to your dashboard and click “Generate Payment Link.” 
                                Proceed by providing the amount, description, customer’s name, email, mobile number, and choose expiration link. 
                                You can share the generated payment link, via Facebook Messenger, WhatsApp, Viber, email, or copying the payment link.
                        </p>
                </div>
        },
        {
                id: '2',
                q: 'How do I get notified if payment is successful?',
                a: 'Once payment is made, a notification email will be sent to you and your customer.'
        },
        {
                id: '3',
                q: 'Where can I see my pending and expired payment transactions?',
                a: 'You can go to the "Payment Request" page and see all the tabs for pending and expired payment links.'
        },
        // {
        //         id: '4',
        //         q: 'How do I send an order form?',
        //         a: `After payment is complete. You can send an order form where the customer inputs his/her order details.`
        // },
        // {
        //         id: '5',
        //         q: 'I have issues with my payments on my account, who do I contact?',
        //         a: `For any payment related issues you can click on the help tab(Contact us) on the dashboard and fill out the fields and our support will contact the seller directly. 
        //         You can also send an email to our support team support@bux.ph and we will respond back to you.`
        // },
        {       
                id: '6',
                q: 'Can I resend the payment link?',
                a:
                        <div>
                                Yes, you can resend the payment link.
                                Navigate on the "Payment Request" page and click on the 3 dots found on the right side of the reference number and you will have the option to resend the link to your customer.
                        </div> 
        },
        {
                id: '7',
                q: 'Can I offer discounts and promotional codes to my buyers?',
                a: 'Not at this time, unfortunately. Only BUx can only provide promotions to both you and your customers.'
        },
        {
                id: '8',
                q: 'Can I view all expired orders?',
                a: 'Yes, you can view all expired orders by going to the expired tab found on the orders tab.',
        },
        {
                id: '9',
                q: 'Who do I contact about payment link issues?',
                a: <div>
                        <p>
                        You can click on the Help tab on your dashboard and fill out the required fields. 
                        BUx will contact you directly or you may chat on the intercom icon found on the website or your BUx dashboard. 
                        Alternatively, you can also send an email to Bux’s support team at <a href="mailto:support@bux.ph" style={{color: '#1d82b8', fontWeight: 600}}>support@bux.ph</a>.
                        </p>
                </div>
        },
        {
                id: '10',
                q: 'How do I cash out and how fast can I receive my funds?',
                a: 
                        <div>
                                <p>
                                        Once your customers pay for the purchased product or service, you may directly cash out from your bank or mobile wallet in real-time.  
                                        However, for transaction amounts that are more than PHP 50,000.00, crediting may take 2 to 3 days.  
                                        Payout is free.
                                </p>
                        </div>
        },
       
        {
                id: '11',
                q: 'How do I set an expiration on my payment link?',
                a: `Upon creating the payment link, you can then choose the time when your payment link will expire. 
                        Choose from 6 hours, 12 hours, 1 day, 2 days, 3 days, or 7 days. By default, a payment link expiry is 6 hours. 
                        Note that this is expiry only of the payment link.`
        },
        {
                id: '12',
                q: 'When my customers select a payment channel and an instructional receipt is received, when will this expire?',
                a: 'By default, expiry is 2 days for over-the-counter channels and 1 hour for online banking and mobile wallets.',
        },
        {
                id: '13',
                q: 'How does my customer pay upon receiving an instructional receipt?',
                a: <div>
                        <p>
                                7-Eleven -  Your customers just need to show the barcode found on the receipt.
                        </p> 
                        <p>
                                Other over-the-counter channels - If through other over-the-counter channels, customers need to get a payment slip from the branch and fill it out.
                        </p>
                        <p>
                                Write the following on the payment slip:
                        </p>
                        <ol>
                                <li>
                                        BUX or Dragonpay as the biller name.
                                </li>
                                <li>
                                        Exact reference number should be provided. If the reference number is incorrect, the transaction will not be accepted.
                                </li>
                                <li>
                                        Ensure that the correct full name is provided as seen on the receipt.
                                </li>
                                <li>    
                                        Provide the correct amount as indicated on the receipt. It's important that the amount provided is correct for the transaction to push through.
                                </li>
                                <li>
                                        A mobile number should be provided to ensure that we can contact the customers immediately in the event that there are issues on the transactions.
                                </li>
                        </ol>

                     <p>
                     Instructions are also provided on the instructional receipt received by your customers. Please remind your customers to bring a valid ID.
                     </p>
                </div>

        },
        {
                id: '14',
                q: 'How can my customers pay via online banking channels - direct debit?',
                a: <div>
                     <p><strong>Online Banking Direct Debit  - The following banks support this instructio</strong></p>

                     <p>
                     BPI Online/Mobile, RCBC Online Banking, and Unionbank Internet Banking - Upon selecting the following online banks and clicking the “Pay Now” button, you will need to agree to its Terms and Conditions to proceed. 
                     You will be redirected to the bank’s login page. Input your username and password and click accept to proceed. 
                     You will receive a message from the bank that you have successfully registered  and you will be prompted to enter a one time password (OTP) that will be sent to you via SMS (SMS includes the amount you want to pay). 
                     Once you have input the correct OTP, just click “Confirm.” The payment success page will appear.
                     </p>
                </div>

        },
        {
                id: '15',
                q: 'How can my customers pay via the E-Wallet channel - direct debit?',
                a: <div>
                        <p><strong>GCash Direct Debit</strong></p>

                        <p>
                        For GCash - Upon selecting the “Following This” option, you will be redirected to the GCash login page. Input your mobile number then click “Accept” to proceed. 
                        You will receive a message from GCash confirming your successful registration and you will be prompted to enter a one time password (OTP) that will be sent to you via SMS (SMS includes the amount you want to pay). 
                        Once you have input the correct OTP, just click “Confirm.” The payment success page will appear.
                        </p>

                        <p><strong>Grabpay Direct Debit</strong></p>

                        <p>
                        For Grapay - Upon selecting the “E-Wallet” and Grabpay option, you will be redirected to Grabpay app. 
                        You will be required to enter your login credentials. After successful login, an authorization for payment page will be displayed. 
                        Click “Confirm.” The payment success page will appear.
                        </p>
                </div>

        },
        {
                id: '16',
                q: 'How do I cashout - payout to my bank account or mobile wallet?',
                a: 
                        <div>
                                <p>
                                        You may click on the “Funds” tab found on your dashboard. 
                                        Once inside the “Funds” tab, click “Payout.” You may then select the bank or mobile wallet you have linked to your account settings. 
                                        If you did not put any bank account or mobile wallet, you may click "Link Bank Account - Mobile Wallet" and you will be redirected to the “Link Bank Account - Mobile Wallet” page. 
                                        You can then add up to 3 banks or mobile wallets. For transactions below PHP 50,000, funds will be received in real-time. For above PHP 50,000, funds will be received in real-time if the payout is done before 3:00 pm. If after 3:00 pm, funds will be remitted to your bank account or mobile wallet on the next day. If a payout is done on a Friday then it will be credited on Monday. If a payout is done on a Holiday, it will be credited on the next banking day. Once you have selected a bank or mobile wallet you will receive an OTP that will indicate the amount for payout. Enter the OTP to proceed with the payouts. Payout is free. You may also choose to credit your BUx wallet account to your EON Account with a BUx Virtual Visa Card or an EON Physical Visa Card or use your wallet funds to pay bills or top-ups.
                                </p>
                        </div>
        },
        {
                id: '17',
                q: 'Is my BUx wallet balance updated real time?',
                a: 
                        <div>
                                <p>
                                For OTC / Web and E-Wallet your BUx wallet balance is updated real time if transactions are tagged as paid. 
                                For credit and debit card transactions, wallet balance is updated daily every 12mn.
                                </p>
                        </div>
        },


]

export const FAQ_FEES = [
        {
                id: '1',
                q: 'Transaction Fees',
                a: 
                        <div>
                                <p>
                                        By default, all transaction fees will be deducted on the total amount. 
                                </p>
                                <p>
                                        If you want your transaction fees to be on top of the total amount, you may go to “Account Settings” and under the “General Settings” tab, click on the toggle button if you want the transaction fees to be on top of the total amount. Please note that transaction fees will vary depending on the payment channel selected by your customers. 
                                        Please see the fee table for transaction fees for reference.
                                </p>
                        </div>
        },
        {
                id: '2',
                q: 'Are there fees to be paid in Bux? ',
                a: 
                        <div>
                                <p>
                                        Yes, BUx discloses the fees to be paid in your dashboard.  
                                        This may change from time to time and depending on the service type.  
                                        For all over-the-counter channels, the fee is PHP 20. For mobile wallets, fees are 2.0% + PHP 10, while online banking fee is PHP 15 per transaction. 
                                        Surcharge fees may also apply to online banking channels. 
                                        Cash-out to  any bank or mobile wallet is free via InstaPay of PESONet.
                                </p>
                        </div>
        },
        {
                id: '3',
                q: 'What are the available channels and fees per channel?',
                a: <div>
                        <div>Below are the list of available over-the-counter channels. Fee for over-the-counter payments is 20 Php per transaction.</div>
                        <table style={{marginTop: '16px', border: '1px solid #000000', width: '100%'}}>
                             <tr>
                                     <td style={{padding: 5, width: '33%', border: '1px solid #000000'}}>

                                     </td>

                                     <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                        <div>Minimum Amount</div>
                                     </td>

                                     <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                        <div>Maximum Amount</div>
                                     </td>
                             </tr>
                            {
                                    channels.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                        <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.c}</div>
                                                        </td>
                
                                                        <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.min}</div>
                                                        </td>
                
                                                        <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.max}</div>
                                                        </td>
                                                </tr>
                                            )
                                    })
                            }

                
                        </table><br/>
                        <div>Below are the list of available over the online banking channels. Fee for online banking payments is 15 Php per transaction.</div>
                        <table style={{marginTop: '16px', border: '1px solid #000000', width: '100%'}}>
                             <tr>
                                     <td style={{padding: 5, width: '60%', border: '1px solid #000000', textAlign: 'center'}}>
                                        Web Banking Channel (Online Banking Only)
                                     </td>

                                     <td style={{padding: 5, width: '15%', border: '1px solid #000000'}} align='center'>
                                        <div>Minimum Amount</div>
                                     </td>

                                     <td style={{padding: 5, width: '15%', border: '1px solid #000000'}} align='center'>
                                        <div>Maximum Amount</div>
                                     </td>
                                     <td style={{padding: 5, width: '10%', border: '1px solid #000000'}} align='center'>
                                        <div>Surcharge Fees</div>
                                     </td>
                             </tr>
                            {
                                    online_banking.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                        <td style={{padding: 5, width: '60%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.c}</div>
                                                        </td>
                
                                                        <td style={{padding: 5, width: '15%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.min}</div>
                                                        </td>
                
                                                        <td style={{padding: 5, width: '15%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.max}</div>
                                                        </td>
                                                        <td style={{padding: 5, width: '10%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.fee}</div>
                                                        </td>
                                                </tr>
                                            )
                                    })
                            }
                        </table><br/>
                        <div><strong>*surcharge fees are additional  fees charged to the customer. </strong></div><br/>
                        <div>Below are the list of available over mobile wallet channels. Fees for mobile wallet payments is 2.0% + 10 Php per transaction.</div>
                        <table style={{marginTop: '16px', border: '1px solid #000000', width: '100%'}}>
                             <tr>
                                     <td style={{padding: 5, width: '33%', border: '1px solid #000000', textAlign: 'center'}}>
                                        Mobile Wallet Channels
                                     </td>

                                     <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                        <div>Minimum Amount</div>
                                     </td>

                                     <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                        <div>Maximum Amount</div>
                                     </td>
                             </tr>
                            {
                                    e_wallets.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                        <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.c}</div>
                                                        </td>
                
                                                        <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.min}</div>
                                                        </td>
                
                                                        <td style={{padding: 5, width: '33%', border: '1px solid #000000'}} align='center'>
                                                        <div>{item.max}</div>
                                                        </td>
                                                </tr>
                                            )
                                    })
                            }
                        </table><br/>
                </div>
        },
]

export const FAQ_SHIPPING = [
        {
                id: '1',
                q: 'Can I view all the expired orders?',
                a: `You can view all of the expired orders by going to the expired tab found on the orders tab.`
        },
        {
                id: '2',
                q: 'What if my goods got stolen or lost?',
                a: `For incomplete or damaged orders, you may coordinate directly with your selected courier. `
        },
        {
                id: '3',
                q: 'I have issues on my shipments and I also want to process filling for a claim.',
                a: <p>Claims should be filled directly to your chosen logistics partner. Support contact information can be found below
                        <br />
                        <ol>
                                <li>
                                        Shippo - <a href="ph.support@shippo.ph">ph.support@shippo.ph</a> / 09150916356 (Globe) 09454254952 (Globe)
    
                                </li>
                                <li>
                                        Xend - <a href="info@xend.com.ph">info@xend.com.ph</a>
                                </li>
                                <li>
                                        Courier.ph - <a href="sales@courier.ph">sales@courier.ph</a> / 09773310483 (Globe) 09955621579 (Smart) 09472820799 (Smart)
                                </li>
                                <li>
                                        QuadX - <a href="info@quadx.xyz">info@quadx.xyz</a> / 88778234 (Landline)
                                </li>
                                <li>
                                        Payo - <a href="info@payo.asia">info@payo.asia</a> / 09177064831 (Globe)
                                </li>
                        </ol>
                        <br />
                        <ul>
                                <li>At the minimum, you will need to prepare the following information below</li>
                        </ul>
                        <br />
                        <ol>
                                <li>Reason of what claims will be filled.</li>
                                <li>Tracking Number.</li>
                                <li>Delivery Date.</li>
                                <li>Attach applicable supporting documents:</li>
                                <li>Receipt of pick up.</li>
                                <li>Order page screenshot with the tracking information.</li>
                                <li>Photos of the items and packaging (all angles for pictures should be taken).</li>
                                <li>Supporting documents.</li>
                        </ol>
                        </p>
                     
        },
        {
                id: '4',
                q: 'What sizes should be allowed?',
                a: 'You may refer to the shipping fee table on the prices and sizes available.'
        },
        {
                id: '5',
                q:  'What are the prohibited items for Shipping?',
                a: <p>Below are the list of prohibited items
                        <ol>
                                <li>
                                Any kind of explosive. (solid, liquid, or gas). 
                                </li>
                                <li>
                                Flammable solids and organic peroxides.    
                                </li>
                                <li>
                                Lottery tickets.
                                </li>
                                <li>
                                Money (coins, currency, paper money, negotiable instruments equivalent to cash such as endorsed stocks, bonds, cash letters, checks).
                                </li>
                                <li>
                                Wet ice (frozen water).
                                </li>
                                <li>
                                Any shipment whose carriage is prohibited by Philippine law, statute, or regulations.
                                </li>
                                <li>
                                Live animals and plants.
                                </li>
                        </ol>
                        <br/>
                        <ul>
                                <li>
                                Sellers must declare the contents to the logistics companies and if category of items are on this list, they will be sent back to the seller. 
                                If items are misdeclared delays on sending this back to the seller is not the liability of the courier partner.  
                                </li>
                        </ul>
                </p>
        },
        {
                id: '6',
                q: 'How can I track my shipments?',
                a: `You can track your shipments by going through the shipment tracking tab found on your dashboard.`
        },
        {
                id: '7',
                q: 'How long will be the delivery lead time?',
                a: <p>
                     Nationwide delivery schedules vary per logistics company, 
                     but the standard guidelines for most are as follows: 
                     3 days within Metro Manila and 5-7 days for provincial delivery addresses (excluding holidays and natural circumstances beyond one’s control). 
                     Items coming from any province to any destination will be covered by the 5-7 days provincial schedule. 
                     You or your buyer will be provided with an estimated delivery date upon successful order confirmation or booking of a delivery.   
                </p>
        },
        {
                id: '8',
                q: 'Can my buyers pick up items from other partner locations?',
                a: 'Currently, we do not have this facility but later in the future, drop-off points will be available in selected 7-Eleven branches and MLhuillier branches.'
        },

        {
                id: '9',
                q: 'What are serviceable fees?',
                a: 'Serviceable areas vary per logistics partner. You may see the serviceable area table on the list of areas available per logistics company.'
        },
        // {
        //         id: '9',
        //         q: 'Do you have plugins that I can use for my website?',
        //         a: `BUx support plugins for WooCommerce, PrestaShop and Shopify. 
        //         Simply copy the token code to your e-commerce store and track orders, sales and shipments in one place.`
        // },
       
        // {
        //         id: '13',
        //         q: 'How do I send an order form?',
        //         a: 'After payment is completed. The seller can now send an order form where the buyer can input their order details.'
        // },
        // {
        //         id: '14',
        //         q: 'How do I ship my items?',
        //         a: 'Once the order form is completed. Sellers can go to the Orders tab and ship directly to the buyer.'
        // },
        // {
        //         id: '15',
        //         q: 'Can I cancel my order?',
        //         a: `You can cancel any order. 
        //         Navigate on the order tab and click on the 3 dots found on the right side of the reference number you want to cancel. 
        //         All cancelled orders can be found on the cancelled tab.`
        // },

        // {
        //         id: '17',
        //         q: 'Can I offer discounts and promotional codes to my buyers?',
        //         a: `For now, only BUx can provide promotions to both buyers and sellers. In the future, BUx will allow sellers to generate promo codes for their buyers.`
        // },


]

export const FAQ_ECOM = [

        {
                'id': '1',
                'q': 'What is link stores? Do you have plug-ins that I can use for my website?',
                'a': 
                        <div>
                                <p>
                                BUx supports plug-ins for Sentro, WooCommerce, and Prestashop. 
                                Simply copy the token code to your e-commerce store and track orders and sales in one place.
                                </p>
                        </div>
        },
        // {
        //         'id': '2',
        //         'q': 'What is Loan?',
        //         'a': 
        //                 <div>
        //                         <p>
        //                         Loans tab is a feature that will allow you or your customers to apply for a microloan through our list of lenders. 
        //                         This is available on your dashboard and you may use or send this out to your customers. 
        //                         Customers will also be able to see this on the payment instructions receipt for OTC payments.
        //                         </p>
        //                 </div>
        // }
]

const monthly = [
        {    
                cashIn: 'PHP 5,000.00',
                cashOut: 'PHP 0.00',
                level: 'Basic'
        },
        {
                cashIn: 'PHP 100,000.00',
                cashOut: 'PHP 100,000.00',
                level: 'Pro'
        },
        {
                cashIn: 'PHP 500,000.00',
                cashOut: 'PHP 500,000.00',
                level: 'Advanced'
        },
        {
                cashIn: 'PHP 1,000,000.00',
                cashOut: 'PHP 1,000,000.00',
                level: 'Business',
        },
        {
                cashIn: 'NO LIMITS',
                cashOut: 'NO LIMITS',
                level: 'Enterprise'
        },

    ]

export const FAQ_KYC = [

        {
                'id': '1',
                'q': 'What are the KYC requirements, upgrade limits, and benefits?',
                'a': <div>
                        <div>The following are the KYC requirements</div>
                        
                        <table style={{marginTop: '16px',width: '100%', border: '1px solid #000000'}}>
                                <tr>
                                        <td style={{width: '33%', padding: '12px'}} align='center'>
                                                <div><b>Level</b></div>
                                        </td>
                                        <td style={{width: '33%', padding: '12px', border: '1px solid #000000'}} align='center'>
                                                <div><b>KYC</b></div>
                                        </td>
                                        <td style={{width: '33%', padding: '12px', border: '1px solid #000000'}} align='center'>
                                                <div><b>Minimum Transactions to Upgrade</b></div>
                                        </td>
                                </tr>

                                <tr>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>Basic - Level 0</div>
                                        </td>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>Personal and Contact Information - First Name, Last Name, Email, Mobile Number and Password</div>
                                        </td>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>Created upon sign up</div>
                                        </td>
                                </tr>

                                <tr>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>Pro - Level 1</div>
                                        </td>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>First Name, Last Name, Middle Name, Birthplace, Birthday, Present Address, Permanent 
                                                        Address, Gender, Mothers Maiden Name, Street, Barangay, City, Province, Zip Code, 
                                                        Source of Funds, Employment Status, Employment Industry, Employer Business Name, 
                                                        Employment Barangay/District, Employment City, Employment Province/Region, Employment Zip Code, 
                                                        Nationality/Country (3 Letter Abbreviation, ID Type, ID Number, Image Selfie, Front ID, 
                                                        Back ID Image</div>
                                        </td>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>None</div>
                                        </td>
                                </tr>

                                <tr>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>Advanced - Level 2</div>
                                        </td>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>Same as Pro</div>
                                        </td>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>20 Paid transactions at any given time</div>
                                        </td>
                                </tr>

                                <tr>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>Business - Level 3</div>
                                        </td>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>Same as Pro</div>
                                        </td>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>100 Paid transactions at any given time</div>
                                        </td>
                                </tr>

                                <tr>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>Enterprise</div>
                                        </td>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>Enterprise Documents</div>
                                        </td>
                                        <td style={{padding: '12px', border: '1px solid #000000'}}>
                                                <div>No minimum transactions required</div>
                                        </td>
                                </tr>
                        </table>

                        <div style={{marginTop: '32px'}}>Below are the limits and benefits table of KYC tiers</div>

                        <table style={{marginTop: '16px',width: '100%', border: '1px solid #000000'}}>
                                <tr>
                                        <td style={{width: '23%', padding: '12px',border: '1px solid #000000'}} align='center'>
                                                <div><b>Level</b></div>
                                        </td>
                                        <td style={{width: '23%', padding: '12px', border: '1px solid #000000'}} align='center'>
                                                <div></div>
                                        </td>
                                        <td style={{width: '23%', padding: '12px',border: '1px solid #000000'}} align='center'>
                                                <div><b>Cash In</b></div>
                                        </td>
                                        <td style={{width: '23%', padding: '12px', border: '1px solid #000000'}} align='center'>
                                                <div><b>Cash Out</b></div>
                                        </td>
                                </tr>

                                {
                                        monthly.map((item, index) => {
                                                return(
                                                        <tr key={index} style={{background: (index %2 != 0) ? '#fff' : '#efefef'}} align='center'>
                                                                <td style={{width: '23%', border: '1px solid #000000',padding: '12px'}}>
                                                                        {item.level}
                                                                </td>

                                                                <td style={{width: '23%', border: '1px solid #000000',}}>
                                                                        Monthly
                                                                </td>

                                                                <td style={{width: '23%', border: '1px solid #000000',}}>
                                                                        {item.cashIn}
                                                                </td>

                                                                <td style={{width: '23%', border: '1px solid #000000',}}>
                                                                        {item.cashOut}
                                                                </td>
                                                        </tr>  
                                                )
                                        })  

                                }                            

                        </table>


                        </div>

        },
        {
                id: '2',
                q: 'Which IDs are accepted for the identity verification process?',
                a: <div>
                        <ul>
                                <li><b>Philippine Passport</b> - Department of Foreign Affairs</li>
                                <li><b>Driver's License</b> - Land Transportation Office</li>
                                <li><b>SSS</b> -  Social Security System</li>
                                <li><b>UMID Card</b> -  Social Security System</li>
                                <li><b>GSIS eCard</b> - Government Services and Insurance Corporation</li>
                                <li><b>Digitized Postal ID</b> - Philippine Postal Corporation</li>
                                <li><b>PRC ID</b> - Professional Regulation Commission</li>
                                <li><b>Alien Certificate of Registration</b> - ACR-I Card</li>
                        </ul>
                </div>

        },
        {
                id: '3',
                q: 'How to increase my transaction limit and upgrade my account?',
                a: 
                        <div>
                                <p>
                                        You may complete your KYC to increase your transaction limits. 
                                        You must first perform the minimum paid transaction count needed. Once eligible for an upgrade, you may click on the upgrade button found on your dashboard. 
                                        You will also be required to answer our questionnaire for us to evaluate your upgrade.
                                </p>
                        </div>
        },
        {
                id: '4',
                q: 'How do I track my transaction limits?',
                a: `Account limit tracker can be found on your dashboard.`
        },
        {
                id: '4',
                q: 'How to upgrade your account from Basic to Pro?',
                a: <div>
                        <p>To upgrade your account from Basic to Pro, please follow the steps below:</p>
                        <ol>
                                <li>Go to your dashboard and you will see a tab that has “Upgrade your Account”</li>
                                <li>You will be redirected to the Basic upgrade page where you will be required to complete your profile and upload valid ID and selfie.</li>
                                <li>Once the upgrade is complete, this will be changed to “Success Application” and a confirmation of a successful upgrade status notification will be sent via email.</li>
                        </ol>
                        <p>List of Valid IDs</p>
                        <ul>
                                <li><b>Passport</b> - Any passport</li>
                                <li><b>PRC ID</b> - Philippine Regulatory Commission ID</li>
                                <li><b>Driver's License</b> - Land Transportation Office</li>
                                <li><b>SSS</b> -  Social Security System</li>
                                <li><b>UMID Card</b> - Social Security System</li>
                                <li><b>GSIS eCard</b> - Government Services and Insurance Corporation</li>
                                <li><b>Digitized Postal ID</b> - Philippine Postal Corporation</li>
                                <li><b>ACR</b> - Alien Certificate of Registration</li>
                        </ul>
                </div>
        },
        {
                id: '5',
                q: 'How to upgrade your account from Pro to Advanced?',
                a: <div>
                        <p>
                        To  upgrade your account from Pro to Advanced, please follow the steps below:
                        </p>
                        <ol>
                                <li>Complete the registration process of BASIC account to PRO account.</li>
                                <li>You must at least perform 20 "Paid" transactions on the platform to be able to upgrade from Pro to Business. These transactions can be viewed and monitored on your “Account Limits Tracker” tab found on your dashboard.</li>
                                <li>Once you have performed 20 transactions, the upgrade button will now be available on your dashboard. You may then click on this link.</li>
                                <li>You will be routed to a survey page. Complete the survey and click submit.</li>
                                <li>Your upgrade will now be subjected to review and approval.</li>
                                <li>Once the upgrade is complete this will be changed to “Success Application”.</li>
                                <li>A notification from your dashboard card will show “Pending Application” and an email notification will be sent.</li>
                                <li>Once the upgrade is complete, this will be changed to “Success Application” and an upgrade notification will be sent.</li>
                        </ol>
                        
                </div>
        },
        {
                id: '6',
                q: ' How to upgrade your account from Advanced to Business?',
                a: <div>
                        <ol>
                                <li>Complete the registration process of Basic, Pro, and Advanced accounts and get verified.</li>
                                <li>
                                        You must at least perform 100 "Paid" transactions on the platform to be able to upgrade from Advanced to Business. 
                                        These transactions can be viewed and monitored on your “Account Limits Tracker” tab found on your dashboard.
                                </li>
                                <li>Once you have performed 100 transactions, the “Upgrade” button will now be available on your dashboard. You may then click on this button.</li>
                                <li>You will be routed to a survey page. Complete the survey and click submit.</li>
                                <li>Your upgrade will now be subjected to review and approval.</li>
                                <li>Once the upgrade is complete this will be changed to “Success Application”.</li>
                                <li>A notification from your dashboard card will show “Pending Application” and an email notification will be sent.</li>
                                <li>Once the upgrade is complete, this will be changed to “Success Application” and an upgrade notification will be sent.</li>
                        </ol>
                        
                </div>
        },
]

export const FAQ_BUSINESS = [

        {
                'id': '1',
                'q': 'How do I put my business name in the receipt instead of my full name?',
                'a': <p>Go to your account settings and input your business name. 
                        Once your business name is included, your payment notifications and receipts will show your business name instead of your full name. 
                        Changes will only apply to payment codes that are generated after you input your business name.</p>
        },{
                'id': '2',
                'q': 'How do I set up my Business Name, Social Media Page, and Website?',
                'a': 
                        <div>
                                <p>
                                        After a successful registration via the web or Facebook Messenger, you will receive a verification link and you will be redirected to a page where you can provide your business name, nature of business, social media links, and business website. 
                                        All fields are optional except for the nature of business which is required to be provided.
                                </p>
                        </div>
        }
]

const styles = {
        table: {
                marginTop: '16px',
                width: '100%', 
                border: '1px solid #000000'
        },
        tHeader: {
                padding: '12px', 
                border: '1px solid #000000', 
                textAlign: 'center'
                },
        tData: {
                padding: '12px', 
                border: '1px solid #000000'
        }
}

export const FAQ_EON = [
        {
                id: '1',
                q: 'What is BUx E-money Account?',
                a: <p>Once you have completed your KYC process in BUx you are also eligible to have a BUx E-Money Account subject to approval. 
                        Once your BUx E-Money Account is approved, you may use your BUx wallet balance to fund your BUx E-Money Account with a 
                        Virtual BUx Visa Card or an EON Visa Card and do cashless transactions,  bills payment, or buy top-ups.</p>
        },
        {
                id: '2',
                q: 'What is a Virtual BUx Visa Card and EON Visa Card?',
                a: <p>
                        Once you have completed your KYC process in BUx you are also eligible to have a BUx E-Money Account subject to approval. 
                        Once your BUx E-Money Account is approved, you may use your BUx wallet balance to fund your BUx E-Money Account with a 
                        Virtual BUx Visa Card or an EON Visa Card and do cashless transactions,  bills payment, or buy top-ups.
                  </p>
        },
        {
                id: '3',
                q: 'What is the limit?',
                a: <div>
                        <div>
                                You can load your BUx E-Money Account using your BUx wallet funds. Loading your BUx E-Money Account is treated as a 
                                cash-out option, thus, the payout limit applies the same as your InstaPay/PESONet cash-out.
                        </div>
                        <table style={styles.table}>
                               <tr>
                                        <th  style={styles.tHeader}>Payout Channel</th>
                                        <th  style={styles.tHeader}>Limits</th>
                               </tr>
                               <tr>
                                   <td style={styles.tData}>
                                        Load to EON Visa Card  via BUx Wallet
                                   </td> 
                                   <td style={styles.tData}>
                                        No Limits
                                   </td>    
                               </tr>
                               <tr>
                                   <td style={styles.tData}>
                                        Virtual BUx Visa Card
                                   </td> 
                                   <td style={styles.tData}>
                                        The  EON Visa Card has a daily withdrawal limit of PhP 50,000.00
                                   </td>    
                               </tr>
                               <tr>
                                   <td style={styles.tData}>
                                        Top Up EON (Utility, Beep, Telco, Gaming) and Bills Payment
                                   </td> 
                                   <td>
                                        No Limits
                                   </td>    
                               </tr>
                        </table>
                   </div>
        },
        {
                id: '4',
                q: 'How do I view my Virtual BUx Visa Card or EON Visa Card Number?',
                a: <p>
                        Once you click on the sidebar, you will be routed to a page where you can view your card number and CVV. By default, 
                        you will have a Virtual BUx Visa Card powered by EON but once you attach your EON Visa Card, it will be replaced.             
                   </p>
        },
        {
                id: '5',
                q: 'Where can I buy the EON Visa Card and is there a fee?',
                a: <p>
                        You can see the list of EON retail shops on the “Upgrade to EON Visa Card” by filtering the available location. 
                        The EON Visa Card is sold at PHP 100.00 each.
                   </p>
        },
        {
                id: '6',
                q: 'How do I link the EON Visa Card to my BUx account?',
                a: <p>
                        Click on the “BUx E-Money Account” on the sidebar and select “Upgrade to EON Visa Card” and enter the Visa Card Number 
                        found on the EON Visa Card. You will then be notified via email once completed. Once you have linked your EON Visa Card, 
                        the card number of your Virtual BUx Visa Card shall be replaced.
                   </p>
        },
        {
                id: '7',
                q: 'How do I load my BUx E-Money Account?',
                a: <p>
                        Go to your “BUx E-Money Account” tab found on the right side of the dashboard or load it directly on the BUx E-Money 
                        Account page. Just click the “Load your Virtual BUx Visa Card” and a modal will pop up where you will indicate the amount. 
                        Click load to proceed.
                   </p>
        },
        {
                id: '8',
                q: 'How do I view all my transactions?',
                a: <p>
                        On the right side of the dashboard, go to the transaction history card and click the “BUx E-Money Account” tab. 
                        You can also view all your transactions directly on your BUx E-Money Account page.
                   </p>
        },
        {
                id: '9',
                q: 'Can I replace my EON Visa Card?',
                a: <p>
                        Even though you have successfully linked your EON Visa Card, you may still replace the card by clicking the 
                        “Change your EON Visa Card” on the card page. You will then be asked to enter your new EON Visa Card number. 
                        Once you have linked your new EON Visa Card, the card number of your Virtual BUx Visa Card should get replaced as well.
                   </p>
        },
        {
                id: '10',
                q: 'Can I deactivate my EON Visa Card?',
                a: <p>
                       To deactivate your EON Visa Card, you may send an email to BUx Customer Service at support@bux.ph 
                   </p>
        },
        {
                id: '11',
                q: 'I have issues with my Virtual BUx Visa Card. Who do I contact?',
                a: <p>
                        You may email BUx Customer Service at support@bux.ph
                   </p>
        },
        {
                id: '12',
                q: 'What is bills payment / top-ups and how do I pay?',
                a: 
                        <div>
                                <div>
                                        Once your BUx E-Money Account is activated, you may pay various billers under the categories below using your 
                                        BUx wallet account. To pay, simply go to the BUx E-Money Account section at the right side of the dashboard and 
                                        select “Bills Payments” or “Top-Ups” tab and select your preferred category. You can also do bills payments and 
                                        top-ups directly on your EON Visa Card page.   
                                </div>
                                <div>Categories:</div>
                                <br />
                                <table style={styles.table}>
                                        <thead>
                                                <tr>
                                                        <th colSpan={4} style={styles.tHeader}>Bills Payments</th>
                                                </tr>
                                                <tr>
                                                        <th style={styles.tHeader}>Utilities</th>
                                                        <th style={styles.tHeader}>Credit Card</th>
                                                        <th style={styles.tHeader}>Government</th>
                                                        <th style={styles.tHeader}>Others</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                <tr>
                                                        <td style={{...styles.tData, width: '25%'}}>Cable, Electricity, Telecoms and Water</td>
                                                        <td style={{...styles.tData, width: '25%'}}></td>
                                                        <td style={{...styles.tData, width: '25%'}}></td>
                                                        <td style={{...styles.tData, width: '25%'}}>Schools and Insurance</td>
                                                </tr>        
                                        </tbody>
                                </table>
                                <br />
                                <table style={styles.table}>
                                        <thead>
                                                <tr>
                                                        <th colSpan={4} style={styles.tHeader}>Top Ups</th>
                                                </tr>
                                                <tr>
                                                        <th style={styles.tHeader}>Gaming</th>
                                                        <th style={styles.tHeader}>Telecom</th>
                                                        <th style={styles.tHeader}>Transportation</th>
                                                        <th style={styles.tHeader}>Utilities</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                    
                                        </tbody>
                                </table>
                        </div>
        },
        // {
        //         id: '13',
        //         q: 'How to change my current ATM PIN?',
        //         a: <p>
        //              To change your current ATM PIN, go to “Card Settings” on the card page and click “Edit ATM PIN”. 
        //              Enter your current ATM PIN then set a new ATM PIN.  
        //            </p>
        // },
        // {
        //         id: '14',
        //         q: 'I forgot my ATM PIN?',
        //         a: <p>
        //                 You may email BUx Customer Service at support@bux.ph
        //            </p>
        // },
        {
                id: '15',
                q: 'Are there additional fees to pay when I do bills payment or buy top-up?',
                a: <p>
                        Bills payment and purchase of top-ups are free.
                   </p>
        },
        {
                id: '16',
                q: 'Are bills payment transactions and purchase of top-ups real time?',
                a: <p>
                        Top-ups are real-time while most bills payment transactions are processed by batch, hence, not real-time.
                   </p>
        },
        {
                id: '17',
                q: 'Can I accept credit and debit card payments as a BUx Individual Account user',
                a: <p>
                        Unfortunately no. Credit and debit card payments are only available for Enterprise Account users.
                   </p>
        },
        {
                id: '17',
                q: 'Is my BUx wallet balance updated real time',
                a: <p>
                        Yes your BUx wallet balance is updated real time if transactions are tagged as paid.
                   </p> 
        }
]