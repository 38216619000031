import React from 'react';
import { Button, Icon, Input, Modal, Typography } from 'antd';

const ExportCsvModal = (props) => {
    const { isExportModalVisible, onCancelOrClose, onSend } = props;
    const [email, setEmail] = React.useState(null);

    return (
        <Modal
            maskClosable
            onCancel={() => {setEmail(null); onCancelOrClose();}}
            visible={isExportModalVisible}
            footer={false}
            closeIcon={<Icon type="close" style={{color:'#F5922F'}}/>}
            width={360}
            destroyOnClose
        >
            <Typography style={styles.title}>Export CSV</Typography>
            <Typography style={styles.msg}>
                Please enter your email address to send CSV reports directly to your email.
            </Typography>
            <div>
                <label style={{fontSize: 12}}>Send to Email</label>
                <Input
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={styles.input}
                 />
            </div>
            <button
                className="send-button"
                style={{opacity: !email? '0.5' : '1'}}
                disabled={!email}
                onClick={() => {onSend(email); setEmail(null)}}
            >
                Send
            </button>
        </Modal>
    )
}

const styles = {
    title: {
        color: '#2B2D33',
        fontSize: 24,
        fontWeight: '400',
        textAlign: 'left'
    },
    msg: {
        color: '#2B2D33',
        fontSize: 16,
        textAlign: 'left',
        padding: '12px 0'
    },
    btn: {
        height: 48,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 4,
        width: '100%',
    },
    input: {
        padding: '11px 16px 11px 16px',
        fontSize: 16,
        height: 48,
        margin: '4px 0px 12px 0px',
        color: '#2B2D33'
    },
}

export default ExportCsvModal;