import { SET_CHILD_ENDPOINTS } from './mobileAppConstants';

const initialState = {
    endpoints: []
};

function mobileAppReducers(state=initialState, action) {
    switch (action.type) {
        case SET_CHILD_ENDPOINTS:
            return {
                ...state,
                endpoints: action.endpoints
            }

        default:
            return state;
    }
}

export default mobileAppReducers;