import React, { useEffect, useState } from "react";
import { Input, Modal, Typography } from "antd";

const AuthorizedPersonModal = ({ data, visible, onCancel, onSubmit }) => {
  const segments = localStorage.getItem("segments");

  const initialData = {
    id: data ? data.id : "",
    firstName: data ? data.first_name : "",
    lastName: data ? data.last_name : "",
    email: data ? data.email : "",
    contact: data ? data.contact : "",
  };

  const [authorizedPerson, setAuthorizedPerson] = useState(initialData);

  const handleCancel = () => {
    onCancel();
  };

  const handleReset = () => {
    setAuthorizedPerson(initialData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(authorizedPerson);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setAuthorizedPerson((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  useEffect(() => {
    if (data) {
      setAuthorizedPerson({
        id: data.id,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        contact: data.contact,
      });
    }
  }, [data]);

  const enableSubmitButton =
    JSON.stringify(initialData) !== JSON.stringify(authorizedPerson) &&
    Object.values(authorizedPerson).every((value) => !!value);

  return (
    <Modal width={360} visible={visible} onCancel={onCancel} footer={null}>
      <Typography.Title style={styles.title}>
        Edit Authorized Person
      </Typography.Title>
      <form style={styles.form} onSubmit={handleSubmit} onReset={handleReset}>
        <label htmlFor="firstName">
          <span style={styles.label}>First Name</span>
          <Input
            id="firstName"
            placeholder="First Name"
            height={40}
            value={authorizedPerson.firstName}
            onChange={handleInputChange}
          />
        </label>
        <label htmlFor="lastName">
          <span style={styles.label}>Last Name</span>
          <Input
            id="lastName"
            placeholder="Last Name"
            height={40}
            value={authorizedPerson.lastName}
            onChange={handleInputChange}
          />
        </label>
        <label htmlFor="email">
          <span style={styles.label}>Email</span>
          <Input
            id="email"
            placeholder="Email"
            type="email"
            height={40}
            value={authorizedPerson.email}
            onChange={handleInputChange}
          />
        </label>
        <label htmlFor="contact">
          <span style={styles.label}>Mobile Number</span>
          <Input
            id="contact"
            placeholder="Mobile Number"
            addonBefore="+63"
            height={40}
            value={authorizedPerson.contact}
            onChange={handleInputChange}
          />
        </label>
        <div style={styles.actions}>
          <button
            className={`outline-btn--${segments}`}
            onClick={handleCancel}
            type="button"
          >
            Cancel
          </button>
          <button
            className={`outline-btn--${segments}`}
            type="reset"
            disabled={!enableSubmitButton}
          >
            Reset
          </button>
          <button
            className={`btn--${segments}`}
            type="submit"
            disabled={!enableSubmitButton}
          >
            Confirm
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AuthorizedPersonModal;

const styles = {
  title: {
    fontSize: 20,
  },
  body: {
    fontSize: 16,
  },
  actions: {
    marginTop: 12,
    display: "flex",
    justifyContent: "flex-end",
    gap: 4,
  },
  form: {
    display: "flex",
    flexFlow: "column nowrap",
    gap: 12,
  },
  label: {
    fontSize: 12,
    color: "#212B36",
  },
};
