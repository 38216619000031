export const mainPadding = {
    paddingTop: '38px', 
    paddingLeft: '42px', 
    paddingRight: '42px' 
}

export const twoCol = {
    display: 'flex', justifyContent: 
    'space-between' 
}

export const codeStyle = {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#2B2D33'
  }

export const descSyle = {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '24px',
    color: '#2B2D33'
  }

export const dataStyle = {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000000'
  }

export const orderStyle = {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    color: '#000000',
  }

export const textStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#FFFFFF'
  }

export const menuStyle = {

    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#2b2d33',
    padding: '10px',
    margin: 'auto'
}

export const balStyle = {
    fontSize:'16px',
    fontWeight:'bold',
    color:'#0D3D76',
}

export const payoutBtnStyle = {
    backgroundColor: '#F5922F',
    borderRadius: 4,
    fontWeight: '600',
    fontSize: 16,
    color: '#FFF',
    height: 40,
    padding: '4px 24px',
}

export const maintenanceStyle = {
    backgroundColor: '#ffffff',
    width: '100%',
    borderRadius: '5px',
    padding: '10px',
    marginBottom: '20px',
}

export const btnGrpStyle = {
    padding: '13px 18px 13px 18px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight:'16px',
    height:'40px',
}

export const tableDiv = {
    padding: '15px 20px',
    background: '#fff',
    border: '1px solid #E6EAF0',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
}

export const pillFlex = {
    marginBottom: '20px', 
    display: 'flex', 
    justifyContent: 'space-between' 
}

export const clearBtn =  {
    borderRadius: '24px',
    background: '#E6EAF0',
    color: '#000000',
    fontSize: 12,
    width: 120,
    height: '29px',
    border: '1px solid #e6eaf0',
    cursor: 'pointer',
    outline: 0,
    '&:hover': {
        opacity: '0.8',
    },

    '&:active': {
        opacity: 1,
    }
}

export const noteStyle =  {
    fontSize: 12,
    lineHeight: '21px',
    color: '#909196'
}

export const acSelectStyle = {
    width:'16%', 
    marginRight: '12px', 
    zIndex: 1,
    color: '#000'
}

export const datePickerStyle = {
    width: '16%',
    zIndex: 1,
}

export const searchStyle = {
    width: '16%', 
    height: '40px', 
    zIndex: 1,
    marginRight: '12px',
}

export const disabledBtn = {
    outline: 0,
    background: '#f5922f',
    border: '1px solid #f5922f',
    borderRadius: '4px',
    color: '#fff',
    height: '40px',
    width: 93,
    opacity: 0.5,
    cursor: 'not-allowed'
}

export const activeBtn =  {
    background: '#f5922f',
    border: '1px solid #f5922f',
    borderRadius: '4px',
    color: '#fff',
    height: '40px',
    width: 93,
    cursor: 'pointer',
    outline: 0,
    '&:hover': {
        opacity: '0.8',
    },

    '&:active': {
        opacity: 1,
    }
}

export const sched = {
    marginTop: -4,
    color: '#2b2d33',
    fontWeight: 600,
    lineHeight: '24px',
    textDecoration: 'underline',
}

export const statusStyle = {
    fontSize: '12px',
    color: '#2b2d33',
    borderRadius: '4px',
    textAlign: 'center',
    height: '32px',
    padding: 10,
    height: '100%'
}

export const buxBalSchedDiv = {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    border: '1px solid #E6EAF0',
    display:'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center',
    width: '100%', 
    backgroundColor: '#FFF', 
    padding: '20px 16px',
    margin: '20px 0'
}

export const exportBtnStyle = {
    backgroundColor: '#1DD28B',
    height: 40,
    borderRadius: 4,
    padding: '8px 16px',
    color: '#FFF',
    fontWeight: '600',
    fontSize: 16,
    display: 'inline-flex',
    alignItems: 'center'
}