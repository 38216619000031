import React from 'react';
import { Modal, Button } from 'antd';
import Help2Icon from '../../../static/icons/help2.svg';

const HelpModal = props => {

    const {
        helpModal,
        setHelpModal,
    } = props;

    const segments = localStorage.getItem("segments");

    return (
        <Modal
            visible={helpModal}
            footer={null}
            width="360px"
            onCancel={()=>setHelpModal(false)}
        >
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
                <img src={Help2Icon} alt="#"/>
            </div>
            <div style={{margin: '20px 0px 16px 0px', textAlign: 'center'}}>
                Your “<b>My Checkout</b>” page is where your customer will pay your payment request, This is also the page where customers will be redirected once they scan your <b>QR Code</b>.
            </div>
            
            <Button 
                onClick={()=>setHelpModal(false)}
                className={`big-btn bg-${segments} border-${segments}`}>Got it
            </Button>
            
        </Modal>
    );
};

export default HelpModal;