import React from 'react'
import axios from 'axios'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button, Select, Table, Divider, DatePicker, Pagination, Input, message} from 'antd'
import {history} from '../../../store/history'
import { getRecon, getCutoffs } from './reconAction';
import moment from 'moment';
import '../../../static/css/antd.css'
import {ExportToCsv} from 'export-to-csv'
import UploadModal from './upload_modal'

export function closeModal1(){
  this.setState({
    modal: false
  })
}
const { Option } = Select;
const ButtonGroup = Button.Group;


const mapStateToProps = (state) => {
  const { recon, cutoffs, loading, error } = state.recon;
  return {
    recon,
    cutoffs,
    loading,
    error,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    getCutoffs: bindActionCreators(getCutoffs, dispatch),
    getRecon: bindActionCreators(getRecon, dispatch),
  };
};

const type_dict = {'Unaccounted (Bux)': 'Unaccounted',
                   'Accounted': 'Accounted',
                   'Unaccounted (Partner)': 'Missing'}

const dateFormat = 'MM-DD-YYYY';

function disabledDate(current) {
  // Can not select days before today and today
  return current && current.day() != 3 || current > moment().endOf('day');
}

class Recon extends React.Component{

  componentDidMount(){
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
      history.push('/login')
    }
    else{
      if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
        history.push('/dashboard')
      }
      else if(localStorage.getItem("userType") == "PS"){
        history.push('/orders')
      }
      else if(localStorage.getItem("userType") == "MA"){
        history.push('/overview')
      }
      else{
        this.props.getCutoffs();
      }

    }
  }

  constructor(props){
    super(props)
    closeModal1 = closeModal1.bind(this)
  }

  state = {
      cutoff : '',
      page: 1,
      count: 0,
      page_size: 10,
      fileList: [],
      uploading: false,
      alertMessage: '',
      showAlert: false,
      alert: 'success',
      show: 'Unaccounted (Bux)',
      emailReceipt: '',
      hover: false,
      modal: false
  }

  handleChangeDate(date, dateString) {
    this.setState({
      cutoff: dateString,
      page: 1
    });
    this.props.getRecon('', dateString, this.state.page_size, 1, '7_eleven', type_dict[this.state.show]);
  }

  pageSelect = (e) => {
    console.log(e)
    this.setState({
      page: e,
    })
    this.props.getRecon('', this.state.cutoff, this.state.page_size, e, '7_eleven', type_dict[this.state.show]);
  }

  changePageSize = (e) => {
    console.log(e)
    this.setState({
      page_size: e,
      page: 1
    })
    this.props.getRecon('', this.state.cutoff, e, 1, '7_eleven', type_dict[this.state.show]);
  }

  getCount = () => {
    let recon = this.props.recon
    return recon && recon.count ? recon.count : 0;
  }

  handleChange(selectorFiles: FileList)
  {
      console.log(selectorFiles);
  }

  // renderUnaccounted(){
  //   const {
  //     recon
  //   } = this.props;
  //   if(recon)
  //     return recon.transactions
  //   else
  //     return []
  // }


  // renderAccounted(){
  //   const {
  //     recon
  //   } = this.props;
  //   if(recon)
  //     return recon.transactions
  //   else
  //     return []
  // }

  // renderMissing(){
  //   const {
  //     recon
  //   } = this.props;
  //   if(recon)
  //     return recon.transactions
  //   else
  //     return []
  // }

  headerStyle = {
    
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    color: '#000000',
    marginBottom: '30px'
  }


  tableHeaderStyle = {
    
    fontStyle: 'normal',
    fontSize: '16px',
    color: '#333333',
    marginBottom: '20px',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '20px',
  }

  columns = [
    {
        title: 'Ref. Code',
        dataIndex: 'ref_code',
        render: text => {
            if(text === "Total" || text === "Individual" || text === "Corporation") return <b>{text}</b>
            else return text
        }
    },
    {
      title: 'Total Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Receivable',
      dataIndex: 'receivable',
    },
    {
      title: 'Merchant Receivable',
      dataIndex: 'merchant_receivable',
    },
    {
      title: 'Date',
      dataIndex: 'date_paid',
    },
  ];

  status = [
    'Unaccounted (Bux)', 'Accounted', 'Unaccounted (Partner)'
  ]


    exportTable = async() => {   
      const yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
       }
      
      let response = await axios.get(
        process.env.REACT_APP_API_URL + '/api/recon/7_eleven/?email='+this.state.emailReceipt+'&start='+this.state.cutoff+'&end='+this.state.cutoff+'&export='+true+'&type='+type_dict[this.state.show] ,yourConfig
      );
      if(response.data.status=='success'){
        
        message.success(response.data.message)
      }else{
        message.error(response.data.message)
      }
    };

  render(){

    let cutoffList = this.props.cutoffs.length > 0 && this.props.cutoffs.map((item, i) => {
      return (
        <Option key={i} value={item.id}>{item.name}</Option>
      )
    }, this)

    let recon = this.props.recon ? this.props.recon : {"transactions": []};
    return(
      <div style={{ paddingTop: '38px', paddingLeft: '42px', paddingRight: '42px' }}>
        <div style={this.headerStyle}>7-Eleven (Direct) Recon</div>
        <div style={{ marginBottom: '25px', display: 'flex', justifyContent: 'space-between' }}>
          <ButtonGroup>
            {
              this.status.map((item) => {
                return <Button
                  key={item.toLocaleLowerCase()}
                  onClick={() => { 
                    this.setState({
                    show: item
                    });
                    this.props.getRecon('', this.state.cutoff, this.state.page_size, 1, '7_eleven', type_dict[item]);
                  }}
                  style={{
                    padding: '12px 18px 13px 18px',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    lineHeight:'16px',
                    height:'40px',
                    borderTopLeftRadius: item == "Unaccounted (Bux)" ? '10px' : '0px',
                    borderBottomLeftRadius: item == "Unaccounted (Bux)" ? '10px' : '0px',
                    borderTopRightRadius: item == "Unaccounted (Partner)" ? '10px' : '0px',
                    borderBottomRightRadius: item == "Unaccounted (Partner)" ? '10px' : '0px',
                    color: this.state.show === item ? '#ffffff' : '#2b2d33',
                    backgroundColor: this.state.show === item ? '#0D3D76' : '#fff',
                  }}>{item}</Button>
              })
            }
          </ButtonGroup>
          <div>
          <Input
            placeholder="Email"
            style={{ width: '150px', height: '40px',marginRight: '10px' }}
            onChange={(e)=> {
              this.setState({emailReceipt: e.target.value});  } }
          /> 
          <Button
              style={{
                backgroundColor: '#1DD28B',
                border: '#1DD28B',
                height: '40px',
                borderRadius:'10px',
                zIndex: 1
              }}
              onClick={()=>this.exportTable()}>
            
            <div style={{fontSize: '14px', fontWeight:'600', color: '#fff'}}>Export CSV</div>
          </Button>
          </div>
        </div>
        <div style={{ marginBottom: '20px', float: 'right' }}>
          <DatePicker
            placeholder="Cut Off"
            size="large"
            disabledDate={disabledDate} 
            format={dateFormat} 
            onChange={this.handleChangeDate.bind(this)} 
            style={{marginRight: '10px', zIndex: 1}}
          />
          {/* <Select
            showSearch
            placeholder="Choose Cutoff"
            style={{ width: '250px', marginBottom: '10px', marginRight: '10px', zIndex: 1 }}
            size="large"
            optionFilterProp="children"
            onChange={(ev)=>this.setCutoff(ev)}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            { cutoffList }
          </Select> */}
          <Divider type="vertical" style={{height: '35px', width: '1px', backgroundColor: '#000'}}/>
          <Button
              style={{
                backgroundColor: this.state.hover ? '#1DD28B' : '#fff',
                border: '1px solid #1DD28B',
                height: '40px',
                borderRadius:'10px',
                zIndex: 1,
                opacity: this.state.cutoff == '' ? '0.5' : 1
              }}
              disabled={this.state.cutoff == ''}
              onClick={()=>this.setState({modal: true})}
              onMouseEnter={()=>this.setState({hover: true})}
              onMouseLeave={()=>this.setState({hover: false})}
              >
            <div style={{fontSize: '14px', fontWeight:'600', color: this.state.hover ? '#fff' : '#1DD28B'}}>Upload CSV</div>
          </Button>
          <UploadModal modal={this.state.modal} visible={this.state.modal} channel="7_eleven" cutoff={this.state.cutoff}/>
        </div>
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
            columns={this.columns}
            dataSource={recon && recon.transactions ? recon.transactions : []}
            pagination={false} 
            />
        <div style={{ width: '100%', display: 'flex', marginTop: '20px', }}>
            <Select
                showSearch
                onChange={(ev)=>this.changePageSize(ev)}
                placeholder="Page Size"
                style={{ width: '11%', marginBottom: '10px', marginLeft: 'auto', marginRight: '20px' }}
                size="small"
            >
                <Option key={1} value={5}>Show 5</Option>
                <Option key={2} value={10}>Show 10</Option>
                <Option key={3} value={20}>Show 20</Option>
                <Option key={4} value={50}>Show 50</Option>

            </Select>

            <Pagination 
            size="small"
            total={this.getCount()} 
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            onChange={this.pageSelect} 
            defaultCurrent={1} key={Math.random()} 
            pageSize={this.state.page_size} 
            current={this.state.page}/>
          </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recon);