import React, { useEffect, useState } from "react";
import { getTransferDetails, updateTransferDetails } from "../../api";
import { Button, InputField, RadioButton } from "../../components";
import "./TransferSettings.css";

export function TransferSettings({ data, id }) {
  const [payoutDetails, setPayoutDetails] = useState({
    fee: 10,
    percentage: 0.2,
    rail: "UBP",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getTransferDetails(id)
      .then((response) => {
        setPayoutDetails(response);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
    return () => {};
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const newPayoutDetails = {
      fee: parseInt(payoutDetails.fee),
      percentage: parseFloat(payoutDetails.percentage),
      rail: payoutDetails.rail,
    };

    updateTransferDetails(id, newPayoutDetails)
      .then((response) => {
        setPayoutDetails(response);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    const inputValue =
      type === "radio" ? (checked ? value : payoutDetails[name]) : value;

    setPayoutDetails((prevDetails) => ({
      ...prevDetails,
      [name]: inputValue,
    }));
  };

  return (
    <form className="base" onSubmit={handleSubmit}>
      <article className="card payout-card-1">
        <div className="fee-container">
          <InputField
            label="BUx Fee"
            name="fee"
            value={payoutDetails.fee}
            onChange={handleInputChange}
          />
          <InputField
            label="BUx %"
            name="percentage"
            value={payoutDetails.percentage}
            onChange={handleInputChange}
          />
        </div>
        <p className="payout-text">
          The <span style={{ fontWeight: "bold" }}>Transaction Fee</span> will
          be deducted from the total amount upon merchant transfer.
        </p>
      </article>

      <article className="card">
        <div className="rail-container">
          <div className="rail-text">
            Payment Rail
            <p className="payout-text">
              The selected payment rail will be used for all transfer requests
              including sub-wallets of multi-wallet accounts.
            </p>
          </div>

          <RadioButton
            label="UBP"
            name="rail"
            value="UBP"
            checked={payoutDetails.rail === "UBP"}
            onChange={handleInputChange}
          />
          <RadioButton
            label="Allbank"
            name="rail"
            value="ALB"
            checked={payoutDetails.rail === "ALB"}
            onChange={handleInputChange}
          />
        </div>
      </article>

      <Button className="button" type="submit" isLoading={isLoading}>
        Submit
      </Button>
    </form>
  );
}
