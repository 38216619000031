import { authUser, logout, fbLogin, googleLogin } from "./authServices";
import {history} from '../../store/history'
import {showAlert} from '../alert/alertAction'
import {showModal} from '../BuxModal/modalAction'
import { isMobile } from "react-device-detect";

export const LoginActionTypes = {
    LOGIN_REQUEST: "@@LOGIN_REQUEST",
    LOGIN_SUCCESS: "@@LOGIN_SUCCESS",
    LOGIN_FAILED: "@@LOGIN_FAILED",
    CLEAR: "@@CLEAR"
  };

const DEFAULT_REDIR = {
  'KC': '/submissions', 
  'KB': '/submissions_kyb', 
  'SD': '/submissions_kyb'
}

const adminRole = ['AD', 'OP', 'PS', 'PF', 'MA','KC', 'AP', 'SD', 'EN']

function search(optional_redir='') {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let next = params.get('next');

  if(next != null && next.length > 0) {
    window.location.href = next
  } else if (optional_redir) {
    window.location.href = isMobile ? '/mobile/' + optional_redir : '/' + optional_redir;
  } else{
    window.location.href = isMobile ? '/mobile/dashboard' : '/dashboard'
  }
}

function redirect(redirect_user_type) {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let next = params.get('next');

  if(next != null && next.length > 0) {
    window.location.href = next

  }
  else if(redirect_user_type in DEFAULT_REDIR){
    window.location.href = DEFAULT_REDIR[redirect_user_type]

  }
  else{
      window.location.href = '/overview'
  }
}

const storeToLocalStorage = (data) => {
  let userDetails = data.data.user_details;
  let token = data.data.access_token;

  if(adminRole.includes(data.data.user_details.user_type)){
              
    localStorage.setItem("userId", userDetails.id);
    localStorage.setItem("jwtToken", token);
    localStorage.setItem("firstName", userDetails.first_name);
    localStorage.setItem("lastName", userDetails.last_name);
    localStorage.setItem("middleName", userDetails.middle_name);
    localStorage.setItem("suffix", userDetails.suffix);
    localStorage.setItem("username", userDetails.username);
    localStorage.setItem("email", userDetails.email);
    localStorage.setItem("contact", userDetails.contact);
    localStorage.setItem("balance", userDetails.balance);
    localStorage.setItem("userType", userDetails.user_type)

    redirect(data.data.user_details.user_type)

  }
  else if(data.data.access_token){
    
    localStorage.setItem("jwtToken", token);
    localStorage.setItem("userId", userDetails.id);
    localStorage.setItem("firstName", userDetails.first_name);
    localStorage.setItem("lastName", userDetails.last_name);
    localStorage.setItem("middleName", userDetails.middle_name);
    localStorage.setItem("suffix", userDetails.suffix);
    localStorage.setItem("username", userDetails.username);
    localStorage.setItem("email", userDetails.email);
    localStorage.setItem("contact", userDetails.contact);
    localStorage.setItem("balance", userDetails.balance);
    localStorage.setItem("userType", userDetails.user_type)
    localStorage.setItem("isCompleted", userDetails.is_completed)
    localStorage.setItem("isVerified", userDetails.is_verified)
    localStorage.setItem("has_address", userDetails.address)
    localStorage.setItem('Level', userDetails.kyc_level)
    localStorage.setItem("Step", userDetails.kyc_step)
    localStorage.setItem("lastLogin", userDetails.last_login)
    localStorage.setItem("shipping_enabled", userDetails.shipping_enabled)
    localStorage.setItem("admin_711_enabled", userDetails.admin_711_enabled)
    localStorage.setItem("banks_disabled", JSON.stringify(userDetails.banks_disabled))
    localStorage.setItem("channels_disabled", JSON.stringify(userDetails.channels_disabled))
    localStorage.setItem("segments", userDetails.bux_segment.toLowerCase())
    if(userDetails.banner_url){
      localStorage.setItem('banner_url', userDetails.banner_url)
    }

    search();
  }
}

export function login(value) {
  return async (dispatch) => {
    try {
      dispatch({ type: LoginActionTypes.LOGIN_REQUEST });
      let response = await authUser(value);
      dispatch({
        type: LoginActionTypes.LOGIN_SUCCESS,
        payload: response.data
      });
      console.log(response.data)
      if (response.data.status == "success"){
        storeToLocalStorage(response);
        if(response.data.user_details.user_type == 'ME' || response.data.user_details.user_type == "CO" || response.data.user_details.user_type == "SW"){
          if(!response.data.user_details.is_verified){
            dispatch(showModal({ title: "Verify account", message: "Before continuing, we need to verify your email address. Please check your inbox for a confirmation link.", returnType: 'login', showResendLink: true, email: value.username }));
          }
          else if(response.data.access_token){
            search(response.data.user_details.alias_redir)
          }
        }
        else if(response.data.user_details.user_type in DEFAULT_REDIR){
          window.location.href = DEFAULT_REDIR[response.data.user_details.user_type]

        }
        else{
            window.location.href = '/overview'
        }
      }
      else{
        dispatch({
          type: LoginActionTypes.LOGIN_FAILED
        })
        dispatch(showAlert({ type: "error", message: response.data.message }));
      }
    } 
    catch (error){
      console.log(error);
      dispatch({
        type: LoginActionTypes.LOGIN_FAILED
      });
      dispatch(showAlert({ type:"error", message: "Something went wrong. Please check your internet connection or contact us."}))   
    }
  }
}

export function fb_login(value) {
  return async dispatch => {
    try {
      dispatch({ type: LoginActionTypes.LOGIN_REQUEST });
      let response = await fbLogin(value);
      
      if(response.data.status == "success"){
        dispatch({
          type: LoginActionTypes.LOGIN_SUCCESS,
          payload: response.data
        })

        storeToLocalStorage(response);
        
       }
       else{ 
        dispatch(showAlert({ type: "error", message: response.data.message }));
       }
    } 
    catch (error) {
      dispatch({
        type: LoginActionTypes.LOGIN_FAILED
      })
    }
  }
}

export function google_login(value) {
  return async dispatch => {
    try {
      dispatch({ type: LoginActionTypes.LOGIN_REQUEST });
      let response = await googleLogin(value);

      if(response.data.status == "success"){
        dispatch({
          type: LoginActionTypes.LOGIN_SUCCESS,
          payload: response.data
        });
        
        console.log(response.data);
          
        storeToLocalStorage(response);
       }
       else{ 
          dispatch(showAlert({ type: "error", message: response.data.message }));
       }
    } 
    catch (error) {
      dispatch({
        type: LoginActionTypes.LOGIN_FAILED
      });
    }
  };
}