import axios from 'axios';
import { Handle401 } from '../handle401/handle401'

const authorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const settingServices = {
  uploadProfilePic: async (params) => {
    try {
      const response = await authorizedAxiosRequest().post(`/api/upload_profile_picture/`, params);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  saveBusinessDetails: async (params) => {
    try {
      const response = await authorizedAxiosRequest().patch(`api/update_business/`, params);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  getProfilePic: async () => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/upload_profile_picture/`);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  getProfileDetails: async (params) => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/details/`, params);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  getKycDetails: async (params) => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/kyc/`, params);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  changeMobile: async () => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/update_mobile/`);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  changePassword: async (pass) => {
    try {
      const response = await authorizedAxiosRequest().post(`/api/change_password/`, pass);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  businessInfoUpdate: async (params) => {
    try {
      const response = await authorizedAxiosRequest().post(`/api/update_address/`, params);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  fetchMyAPI: async () => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/details/`);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  setPrimaryBank: async (params) => {
    try {
      const response = await authorizedAxiosRequest().post(`/api/bank_accounts/set_primary/`, params);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  removeBank: async (params) => {
    try {
      const response = await authorizedAxiosRequest().delete(`/api/bank_accounts/`, { data: params });
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  getBankAccounts: async () => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/bank_accounts/`);
      return response;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  getGenSettings: async () => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/settings/`);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  getUserDefinedSettings: async(userID, query_params) => {
    try {
      const response = await authorizedAxiosRequest().get(`api/user/${userID}/custom_settings/` + query_params);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  saveUserDefinedSettings: async(userID, params) => {
    try {
      const response = await authorizedAxiosRequest().put(`api/user/${userID}/custom_settings/`, params);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  transactionFeeToggle: async (params) => {
    try {
      const response = await authorizedAxiosRequest().post(`/api/settings/`, params);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  getKybDetails: async (userId) => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/kyb/${userId}/`);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  getCorporateAlias: async (userId) => {
    const endpoint = userId ? `/api/enterprise/alias/?parent_id=${userId}` : `/api/enterprise/alias/`;
    try {
      const response = await authorizedAxiosRequest().get(endpoint);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  swapSPOCandAlias: async (params) => {
    try {
      const response = await authorizedAxiosRequest().post(`api/enterprise/spoc/switch/`, params);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  childAccountSuspendToggle: async (params) => {
    try {
      const response = await authorizedAxiosRequest().patch(`/api/enterprise/alias/`, params);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  addChildAccount: async (params) => {
    try {
      const response = await authorizedAxiosRequest().post(`/api/enterprise/alias/`, params);
      return response.data;
    } catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        Handle401()
      }
    }
  },
  getAliasPermissions: async (parentID) => {
    try {
        const response = await authorizedAxiosRequest().get(`api/enterprise/${parentID}/alias_settings/`);
        return response.data;

    } catch (error) {
        if (error.response) {
            if (error.response.status === 401) {
                Handle401()
            } else {
                throw new Error(error.response.data.message);
            }
        } else {
            throw new Error('No response from backend');
        }
    }
  },
  saveAliasPermissions: async (parentID, params) => {
    try {
        const response = await authorizedAxiosRequest().patch(`api/enterprise/${parentID}/alias_settings/`, params);
        return response.data;

    } catch (error) {
        if (error.response) {
            if (error.response.status === 401) {
                Handle401()
            } else {
                throw new Error(error.response.data.message);
            }
        } else {
            throw new Error('No response from backend');
        }
    }
  },
}

export default settingServices;