import React from 'react'
import axios from 'axios'

import {Button, Icon, Typography, Input, Spin, Menu, Dropdown, Switch} from 'antd'
import FeeStyles from '../style'
import {OTCIcon} from '../../../../../static/img/feeTableIcon.js'
import CustomInput from '../customInput'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { render } from 'enzyme/build'

const OTCFee = props => {

    // const otcData = props.OtcData
    const fee = FeeStyles()
    const [listOtc, setListOtc] = React.useState([]);
    
    const [editMode, setEditMode] = React.useState(false)
    const [tier, setTier] = React.useState('Tier 1');
    const [channel, setChannel] = React.useState('All Channels');
    const [editModeDP, setEditModeDP] = React.useState(false);

    const [editModeECT1, setEditModeECT1] = React.useState(false);
    const [editModeECT2, setEditModeECT2] = React.useState(false);
    const [editModeBayadT1, setEditModeBayadT1] = React.useState(false);
    const [editModeBayadT2, setEditModeBayadT2] = React.useState(false);

    const [DPChannels, setDPChannels] = React.useState([]);
    const [ECT1Channel, setECT1Channel] = React.useState([]);
    const [ECT2Channel, setECT2Channel] = React.useState([]);
    const [BayadT1Channel, setBayadT1Channel] = React.useState([]);
    const [BayadT2Channel, setBayadT2Channel] = React.useState([]);

    const [selectedChannel, setSelectedChannel] = React.useState('All Channels');
    const [searchValue, setSearchValue] = React.useState(null);
    const tier1Visible = selectedChannel === 'All Channels' || selectedChannel.toLowerCase().indexOf('ecpay') > -1 && tier === 'Tier 1';
    const tier2Visible = selectedChannel === 'All Channels' || selectedChannel.toLowerCase().indexOf('ecpay') > -1 && tier === 'Tier 2' ; 

    const handleChannelClick = ({key}) => {
        setSelectedChannel(key);
        setTier('Tier 1')
    }

    const menu = (
        <Menu onClick={handleChannelClick} >
            <Menu.Item key={'All Channels'}>
                All Channels
            </Menu.Item>
          {
              props.data && props.data.map((channel, i) => {
                  return channel.channel_type === 'Non Bank OTC' &&
                    <Menu.Item key={channel.channel}>{channel.channel}</Menu.Item>
              })
          }
        </Menu>
    )

    const tierMenu = (
        <Menu onClick={({key}) => setTier(key)} >
          <Menu.Item key="Tier 1">
            Tier 1
          </Menu.Item>
          <Menu.Item key="Tier 2">
            Tier 2
          </Menu.Item>
        </Menu>
    )

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
    }

    function passEditMode(val){
        setEditMode(val)
        props.getEditModeValue(val)
    }

    function get_otc(data) {
        let BLANK_OTC = [{'channel_code': '',
                            'channel_fee': 0,
                            'channel_percent': 0,
                            'bux_fee': '',
                            'bux_percent': '',
                            'minimum': '',
                            'maximum': '',
                            'total_fee': 0,
                            'tier': '',
                            'partner': '',
                            'other_fee': 0,
                            'other_fee_percent': 0}];
        if (data && data.length) {
            let preloaded_otc = data.map((otc) => {
                return {
                    'channel_code': otc.channel_code,
                    'channel_fee': otc.channel_fee,
                    'channel_percent': otc.channel_percent,
                    'bux_fee': otc.bux_fee,
                    'bux_percent': otc.bux_percent,
                    'minimum': otc.minimum,
                    'maximum': otc.maximum,
                    'channel': otc.channel,
                    'total_fee': otc.total_fee,
                    'tier': otc.tier,
                    'partner': otc.channel_partner,
                    'other_fee': otc.other_fee,
                    'other_fee_percent': otc.other_fee_percent,
                    'user_fee': otc.user_fee,
                }
            });
            return preloaded_otc;
        }
        // return BLANK_OTC; 
    }

    function handleChange (e, index) {
        const { name, value } = e.target
        const list = editMode ? [...listOtc] : 
            editModeDP ? [...DPChannels] : 
            editModeECT1 ? [...ECT1Channel] : 
            editModeECT2 ? [...ECT2Channel] :
            editModeBayadT1 ? [...BayadT1Channel] : 
            [...BayadT2Channel];
            
        list[index][name] = value
        if(editMode) {
            setListOtc(list);
        } else if(editModeDP) {
            setDPChannels(list);
        } else if(editModeECT1) {
            setECT1Channel(list);
        } else if(editModeECT2) {
            setECT2Channel(list);
        } else if(editModeBayadT1) {
            setBayadT1Channel(list);
        } else {
            setBayadT2Channel(list)
        }
    };

    function submitOtc(){
        let params = {
            fees: editMode ? listOtc : 
                editModeDP ? DPChannels : 
                editModeECT1 ? ECT1Channel : 
                editModeECT2 ? ECT2Channel : 
                editModeBayadT1 ? BayadT1Channel :
                BayadT2Channel,
            user_id: props.pUid
        }

        props.openModal(params, true)
    }

    const userFeeChange = (toggle, fees) => {
        let params = {
            fees: fees,
            user_id: props.pUid,
            user_fee: toggle,
        }
        props.toggleUserFeeModal(params);
    }

    const disabledECPayChannels = ['rustan', 'cvm', 'shopwise', 'wellcome']

    const setFees = () => {
        if(props.data){
            let otc = []
            for(let i = 0; i< props.data.length; i++){
                if(props.data[i].channel_type == "Non Bank OTC" && (props.data[i].channel_partner === "Direct" || props.data[i].channel_partner == "")){
                    otc.push(props.data[i])
                }
            }
            let dragonpay = [];
            for(let i = 0; i< props.data.length; i++){
                if(props.data[i].channel_type == "Non Bank OTC" && props.data[i].channel_partner == "Dragonpay" && props.data[i].channel_code != 'LBC'){
                    dragonpay.push(props.data[i])
                }
            }
            let ecpayt1 = [];
            for(let i = 0; i< props.data.length; i++){
                if(props.data[i].channel_type == "Non Bank OTC" && props.data[i].channel_partner == "ECPay" && props.data[i].tier == 1 && !disabledECPayChannels.includes(props.data[i].channel_code)){
                    ecpayt1.push(props.data[i])
                }
            }
            let ecpayt2 = [];
            for(let i = 0; i< props.data.length; i++){
                if(props.data[i].channel_type == "Non Bank OTC" && props.data[i].channel_partner == "ECPay" && props.data[i].tier == 2 && !disabledECPayChannels.includes(props.data[i].channel_code)){
                    ecpayt2.push(props.data[i])
                }
            }

            let bayadt1 = [];
            for(let i = 0; i< props.data.length; i++){
                if(props.data[i].channel_type == "Non Bank OTC" && props.data[i].channel_partner == "Bayad Center" && props.data[i].tier == 1){
                    bayadt1.push(props.data[i])
                }
            }

            let bayadt2 = [];
            for(let i = 0; i< props.data.length; i++){
                if(props.data[i].channel_type == "Non Bank OTC" && props.data[i].channel_partner == "Bayad Center" && props.data[i].tier == 2){
                    bayadt2.push(props.data[i])
                }
            }
            setListOtc(get_otc(otc))
            setDPChannels(get_otc(dragonpay));
            setECT1Channel(get_otc(ecpayt1));
            setECT2Channel(get_otc(ecpayt2));
            setBayadT1Channel(get_otc(bayadt1));
            setBayadT2Channel(get_otc(bayadt2));
        }

        else {
            console.log(props.data)
        }
    }

    const renderOTCData = (value) => {
        let filteredData = [];  
    
        if (listOtc !== [] && selectedChannel === 'All Channels' && value && value !== null) {
            filteredData = listOtc.filter(data => data.channel.toLowerCase().includes(value.toLowerCase()));
            return filteredData;
        }   else if( listOtc !== [] && selectedChannel !== 'All Channels') {
            filteredData = listOtc.filter(data => data.channel.toLowerCase().includes(selectedChannel.toLowerCase()));
            return filteredData;
        }
            return listOtc;

    }

    const renderDPData = (value) => {
        let filteredData = [];  
    
        if (DPChannels && selectedChannel === 'All Channels' && value && value !== null) {
            filteredData = DPChannels.filter(data => data.channel.toLowerCase().includes(value.toLowerCase()));
            return filteredData;
        }   else if(DPChannels && selectedChannel !== 'All Channels' && value && value !== null) {
            filteredData = DPChannels.filter(data => data.channel.toLowerCase().includes(selectedChannel.toLowerCase()) ||
                                                    data.channel.toLowerCase().includes(value.toLowerCase()));
            return filteredData;
        } else if(DPChannels && selectedChannel !== 'All Channels' && !value) {
            return [];
        }
            return DPChannels;

    }

    const renderECP1Data = (value) => {
        let filteredData = [];  
    
        if (ECT1Channel && selectedChannel === 'All Channels' && value && value !== null) {
            filteredData = ECT1Channel.filter(data => data.channel.toLowerCase().includes(value.toLowerCase()));
            return filteredData;
        }   else if(ECT1Channel && selectedChannel !== 'All Channels' && value && value !== null) {
            filteredData = ECT1Channel.filter(data => data.channel.toLowerCase().includes(selectedChannel.toLowerCase()) ||
                                                        data.channel.toLowerCase().includes(value.toLowerCase()));
            return filteredData;
        }   else if(ECT1Channel && selectedChannel !== 'All Channels' && !value) {
            filteredData = ECT1Channel.filter(data => data.channel.toLowerCase().includes(selectedChannel.toLowerCase()));
        }
            return ECT1Channel;

    }

    const renderECP2Data = (value) => {
        let filteredData = [];  
    
        if (ECT2Channel && selectedChannel === 'All Channels' && value && value !== null) {
            filteredData = ECT2Channel.filter(data => data.channel.toLowerCase().includes(value.toLowerCase()));
            return filteredData;
        }   else if(ECT2Channel && selectedChannel !== 'All Channels' && value && value !== null) {
            filteredData = ECT2Channel.filter(data => data.channel.toLowerCase().includes(selectedChannel.toLowerCase()) ||
                                                        data.channel.toLowerCase().includes(value.toLowerCase()));
            return filteredData;
        } else if(ECT2Channel && selectedChannel !== 'All Channels' && value == '') {
            filteredData = ECT1Channel.filter(data => data.channel.toLowerCase().includes(selectedChannel.toLowerCase()));
        }
            return ECT2Channel;

    }


    const renderBayadT1Data = (value) => {
        let filteredData = [];  
    
        if (BayadT1Channel && selectedChannel === 'All Channels' && value && value !== null) {
            filteredData = BayadT1Channel.filter(data => data.channel.toLowerCase().includes(value.toLowerCase()));
            return filteredData;
        }   else if(BayadT1Channel && selectedChannel !== 'All Channels' && value && value !== null) {
            filteredData = BayadT1Channel.filter(data => data.channel.toLowerCase().includes(selectedChannel.toLowerCase()) ||
                                                        data.channel.toLowerCase().includes(value.toLowerCase()));
            return filteredData;
        }   else if(BayadT1Channel && selectedChannel !== 'All Channels' && !value) {
            filteredData = BayadT1Channel.filter(data => data.channel.toLowerCase().includes(selectedChannel.toLowerCase()));
        }
            return BayadT1Channel;

    }

    const renderBayadT2Data = (value) => {
        let filteredData = [];  
    
        if (BayadT2Channel && selectedChannel === 'All Channels' && value && value !== null) {
            filteredData = BayadT2Channel.filter(data => data.channel.toLowerCase().includes(value.toLowerCase()));
            return filteredData;
        }   else if(BayadT2Channel && selectedChannel !== 'All Channels' && value && value !== null) {
            filteredData = BayadT2Channel.filter(data => data.channel.toLowerCase().includes(selectedChannel.toLowerCase()) ||
                                                        data.channel.toLowerCase().includes(value.toLowerCase()));
            return filteredData;
        } else if(BayadT2Channel && selectedChannel !== 'All Channels' && value == '') {
            filteredData = BayadT2Channel.filter(data => data.channel.toLowerCase().includes(selectedChannel.toLowerCase()));
        }
            return BayadT2Channel;

    }

    const renderTable = (channel) => {
        const data = channel === 'dp'? renderDPData(searchValue) : 
            channel === 'ecpay1'? renderECP1Data(searchValue) : 
            channel === 'ecpay2'? renderECP2Data(searchValue) :
            channel === 'bayad1'? renderBayadT1Data(searchValue) :
            renderBayadT2Data(searchValue)

        const edit = channel === 'dp' ? editModeDP : 
            channel === 'ecpay1'? editModeECT1 : 
            channel === 'ecpay2' ? editModeECT2:
            channel === 'bayad1' ? editModeBayadT1 :
            editModeBayadT2

        const handleEdit = () => {
            props.getEditModeValue(!edit);
            if(channel === 'dp') {
                setEditModeDP(!edit);
            } else if(channel === 'ecpay1') {
                setEditModeECT1(!edit);
            } else if(channel === 'ecpay2'){
                setEditModeECT2(!edit);
            } else if(channel === 'bayad1'){
                setEditModeBayadT1(!edit)
            } else{
                setEditModeBayadT2(!edit)
            }
        }

        return (
            <div>
                <div className={fee.spaceBetween} style={{padding: '12px 0', alignItems: 'center'}}>
                        <div>
                            <Typography hidden={channel != 'dp'} style={styles.title}>Dragonpay Channels</Typography>
                            <Typography hidden={channel != 'ecpay1' && channel != 'ecpay2'} style={styles.title}>ECPay Channels | <span style={{color: '#909196'}}>{ channel.includes("1") ? "Tier 1" : "Tier 2"}</span></Typography> 
                            <Typography hidden={channel != 'bayad1' && channel != 'bayad2'} style={styles.title}>Bayad Center Channels | <span style={{color: '#909196'}}>{ channel.includes("1") ? "Tier 1" : "Tier 2"}</span></Typography> 
                        </div>

                        <div>
                            <div hidden={!edit}>
                                <button className={fee.cancelBtn} style={{marginRight: 10}} onClick={handleEdit}>Cancel</button>
                                <button className={fee.editBtn} onClick={() => submitOtc()}>Save</button>
                            </div>

                            <div hidden={edit}>
                                <Button type='link' style={{fontSize: 16, color: '#F5922F', fontWeight: 'bold', padding: 0, }} onClick={handleEdit} disabled={props.editModeVal}>Edit Fee</Button>
                            </div>
                        </div>
                    </div>

                    <table style={{width: '100%'}}>
                        <tr className={fee.tableHeader}>
                            <td className={fee.tableHeaderData}>
                                Channel
                            </td>
                            <td className={fee.tableHeaderData}>
                                Minimum Amount
                            </td>
                            <td className={fee.tableHeaderData}>
                                Maximum Amount
                            </td>
                            <td className={fee.tableHeaderData}>
                                BUx Fee
                            </td>
                            <td className={fee.tableHeaderData}>
                                BUx %
                            </td>
                            <td className={fee.tableHeaderData}>
                                Total
                            </td>
                            <td className={fee.tableHeaderData}>
                                Other Fee
                            </td>
                            <td className={fee.tableHeaderData}>
                                Other Fee %
                            </td>
                            <td className={fee.tableHeaderData}>
                                API Control
                            </td>
                        </tr>
                        {
                            data&&data.map((item, key) => {
                                return(
                                <tr>
                                    <td className={fee.tableChannelHeader}>

                                        {item.channel}
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        &#8369; {item.minimum} 
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <div hidden={edit}>
                                        &#8369; {item.maximum} 
                                        </div>
                                        <div hidden={!edit}>
                                            <CustomInput name={"maximum"} value={item.maximum} placeholder={"Max Amount"} handleChangeVal={handleChange} index={key}/>
                                        </div>
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <div hidden={edit}>
                                        &#8369; {item.bux_fee} 
                                        </div>
                                        <div hidden={!edit}>
                                            <CustomInput  value={item.bux_fee} name={"bux_fee"} handleChangeVal={handleChange} placeholder={"BUx Fee"} index={key}/>
                                        </div>
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <div hidden={edit}>
                                        {item.bux_percent}%
                                        </div>
                                        <div hidden={!edit}>
                                            <CustomInput value={item.bux_percent} name={"bux_percent"} placeholder={"Percent"}  handleChangeVal={handleChange} index={key}/>
                                        </div>
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <div>
                                        &#8369; {item.total_fee} 
                                        </div>
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <div hidden={edit}>
                                        {item.other_fee}
                                        </div>
                                        <div hidden={!edit}>
                                            <CustomInput value={item.other_fee} name={"other_fee"} placeholder={"Other Fee"}  handleChangeVal={handleChange} index={key}/>
                                        </div>
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <div hidden={edit}>
                                        {item.other_fee_percent}%
                                        </div>
                                        <div hidden={!edit}>
                                            <CustomInput value={item.other_fee_percent} name={"other_fee_percent"} placeholder={"Percent"}  handleChangeVal={handleChange} index={key}/>
                                        </div>
                                    </td>
                                    <td className={fee.tableChannelText}>
                                        <div>
                                            <Switch checked={item.user_fee} onChange={() => userFeeChange(!item.user_fee, item)} style={{margin: '12px 20px 12px 12px', backgroundColor: `${item.user_fee ? '#F5922F' : '#D1D5DD'}`}} disabled={edit || editMode} />
                                        </div>
                                    </td>
                                </tr>

                                )
                            })
                        }
                    </table>
            </div>

        )
    }

    React.useEffect(() => {
        setFees();
    },[props.data])
    
    return(
        !props.loading?
        <div>
            <div className={fee.innerDiv}>
                <div className={fee.spaceBetween} style={{padding: '12px 0'}}>
                    <div>
                        <img src={OTCIcon} alt="OTC Icon" /> <span className={fee.channelHeader}>Over-the-counter</span>
                    </div>
                    <div style={styles.dateBtn}>
                        <Dropdown overlay={menu} trigger='click'>
                            <a style={styles.dropdownItems} >
                            {selectedChannel.charAt(0).toUpperCase() + selectedChannel.substring(1)}
                            <ExpandMoreIcon style={styles.dropdownArror} />
                            </a>
                        </Dropdown>
                        <Dropdown overlay={tierMenu} trigger='click' disabled={selectedChannel.toLowerCase().indexOf('ecpay') <= -1}>
                            <a style={{...styles.dropdownItems, margin: '0 16px', backgroundColor: selectedChannel.toLowerCase().indexOf('ecpay') <= -1 ? '#E6EAF0' : 'transparent'}} >
                            {tier.charAt(0).toUpperCase() + tier.substring(1)}
                            <ExpandMoreIcon style={styles.dropdownArror} />
                            </a>
                        </Dropdown>
                        <Input
                            placeholder="Search"
                            style={{ width: '200px', height: '40px', borderRadius:'4px' }}
                            prefix={<Icon type="search" style={{fontSize:'18px'}}/>}
                            onChange={handleSearch}
                        />
                    </div>
                </div>

                <div className={fee.spaceBetween} style={{padding: '12px 0'}}>
                    <div style={styles.title}>
                       <span>OTC Channels</span>
                    </div>

                    <div>
                        <div hidden={!editMode}>
                            <button className={fee.cancelBtn} style={{marginRight: 10}} onClick={() => passEditMode(!editMode)}>Cancel</button>
                            <button className={fee.editBtn} onClick={() => submitOtc()}>Save</button>
                        </div>

                        <div hidden={editMode}>
                            <Button type='link' style={{fontSize: 16, color: '#F5922F', fontWeight: 'bold', padding: 0, }} onClick={() => passEditMode(!editMode)} disabled={props.editModeVal}>Edit Fee</Button>
                        </div>
                    </div>
                </div>

                <table style={{width: '100%'}}>
                    <tr className={fee.tableHeader}>
                        <td className={fee.tableHeaderData}>
                            Channel
                        </td>
                        <td className={fee.tableHeaderData}>
                            Minimum Amount
                        </td>
                        <td className={fee.tableHeaderData}>
                            Maximum Amount
                        </td>
                        <td className={fee.tableHeaderData}>
                            BUx Fee
                        </td>
                        <td className={fee.tableHeaderData}>
                            BUx %
                        </td>
                        <td className={fee.tableHeaderData}>
                            Total
                        </td>
                        <td className={fee.tableHeaderData}>
                            Other Fee
                        </td>
                        <td className={fee.tableHeaderData}>
                            Other Fee %
                        </td>
                        <td className={fee.tableHeaderData}>
                            API Control
                        </td>
                    </tr>
                    {
                        renderOTCData(searchValue) && renderOTCData(searchValue).map((item, key) => {
                            return(
                            <tr>
                                <td className={fee.tableChannelHeader}>

                                    {item.channel}
                                </td>
                                <td className={fee.tableChannelText}>
                                    &#8369; {item.minimum} 
                                </td>
                                <td className={fee.tableChannelText}>
                                    <div hidden={editMode}>
                                    &#8369; {item.maximum} 
                                    </div>
                                    <div hidden={!editMode}>
                                        <CustomInput name={"maximum"} value={item.maximum} placeholder={"Max Amount"} handleChangeVal={handleChange} index={key}/>
                                    </div>
                                </td>
                                <td className={fee.tableChannelText}>
                                    <div hidden={editMode}>
                                    &#8369; {item.bux_fee} 
                                    </div>
                                    <div hidden={!editMode}>
                                        <CustomInput  value={item.bux_fee} name={"bux_fee"} handleChangeVal={handleChange} placeholder={"BUx Fee"} index={key}/>
                                    </div>
                                </td>
                                <td className={fee.tableChannelText}>
                                    <div hidden={editMode}>
                                    {item.bux_percent}%
                                    </div>
                                    <div hidden={!editMode}>
                                        <CustomInput value={item.bux_percent} name={"bux_percent"} placeholder={"Percent"}  handleChangeVal={handleChange} index={key}/>
                                    </div>
                                </td>
                                <td className={fee.tableChannelText}>
                                    <div>
                                    &#8369; {item.total_fee} 
                                    </div>
                                </td>
                                <td className={fee.tableChannelText}>
                                    <div hidden={editMode}>
                                    {item.other_fee}
                                    </div>
                                    <div hidden={!editMode}>
                                        <CustomInput value={item.other_fee} name={"other_fee"} placeholder={"Other Fee"}  handleChangeVal={handleChange} index={key}/>
                                    </div>
                                </td>
                                <td className={fee.tableChannelText}>
                                    <div hidden={editMode}>
                                    {item.other_fee_percent}%
                                    </div>
                                    <div hidden={!editMode}>
                                        <CustomInput value={item.other_fee_percent} name={"other_fee_percent"} placeholder={"Percent"}  handleChangeVal={handleChange} index={key}/>
                                    </div>
                                </td>
                                <td className={fee.tableChannelText}>
                                    <div>
                                        <Switch checked={item.user_fee} onChange={() => userFeeChange(!item.user_fee, item)} style={{margin: '12px 20px 12px 12px', backgroundColor: `${item.user_fee ? '#F5922F' : '#D1D5DD'}`}} disabled={editMode} />
                                    </div>
                                </td>
                            </tr>

                            )
                        })
                    }
                </table>
                { renderDPData(searchValue) && renderDPData(searchValue).length > 0 ? renderTable('dp') : null }
                { tier1Visible && renderECP1Data(searchValue) && renderECP1Data(searchValue).length > 0 ? renderTable('ecpay1') : null }
                { tier2Visible && renderECP2Data(searchValue) && renderECP2Data(searchValue).length > 0 ? renderTable('ecpay2') : null }
                { tier1Visible && renderBayadT1Data(searchValue) && renderBayadT1Data(searchValue).length > 0 ? renderTable('bayad1') : null }
                { tier2Visible && renderBayadT2Data(searchValue) && renderBayadT2Data(searchValue).length > 0 ? renderTable('bayad2') : null }
            </div>
        </div>
        :
        <Spin style={{width: '100%', height: 212, display: 'flex', alignItems: 'center', justifyContent: 'center'}} />
    )
}

const styles = {
    dateBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    dropdownItems: {
        display: 'flex', 
        alignItems: 'center', 
        padding: '7px 4px 7px 16px',
        fontSize: 16, 
        color: '#2B2D33',
        border: '1px solid #D1D5DD',
        borderRadius: 5,
    },
    dropdownArror: {
        color: '#2B2D33', 
        fontSize: 24, 
        margin: '0 4px'
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#000',
        padding: '16px 0'
    },
}

export default OTCFee