import React from 'react';
import { TransferMoneySuccess, TransferMoneyFailedIcon } from '../../../../static/images/multiwallet';
import moment from 'moment';
import { Card, Icon, Modal, Typography } from 'antd';

const TransferSuccessModal = (props) => {
    const { visible, onClose, data, successData, amount } = props;
    const [totalAmount, setTotalAmount] = React.useState(0);
    const successTransfer = successData && successData.successful > 0;

    const getTotalAmount = () => {
        let total = 0;
        data && data.map((row, i) => {
            total = total + parseFloat(row.amount);
        })
        setTotalAmount(total);
    }

    React.useEffect(() => {
        getTotalAmount();
    }, [data])

    return (
        <Modal
            maskClosable
            onCancel={() => onClose()}
            visible={visible}
            footer={false}
            closeIcon={<Icon type="close" style={{ color: '#F5922F' }} />}
            width={530}
            destroyOnClose
        >
            <div style={styles.titleDiv}>
                <img style={styles.logo} alt='No Image' src={successTransfer ? TransferMoneySuccess : TransferMoneyFailedIcon} />
                <Typography style={styles.title}>{successTransfer ? 'Money Sent Successfully' : 'Send money transaction failed'}</Typography>
            </div>
            <Card size="small" style={styles.detailsCard} bodyStyle={{ padding: 12 }}>
                <div style={styles.flexSpace}>
                    <Typography style={styles.key}>Total</Typography>
                    <Typography style={styles.value}>{successData && successData.total} Sub-user/s</Typography>
                </div>
                <div style={styles.flexSpace}>
                    <Typography style={styles.key}>Successful transferred money to</Typography>
                    <Typography style={styles.value}>{successData && successData.successful} Sub-user/s</Typography>
                </div>
                <div style={styles.flexSpace}>
                    <Typography style={styles.key}>Date</Typography>
                    <Typography style={styles.value}>{moment().format('MMM D, YYYY h:mm A')}</Typography>
                </div>
                <div style={styles.flexSpace}>
                    <Typography style={styles.key}>Amount</Typography>
                    <Typography style={styles.value}>₱ {amount ? amount.toLocaleString("en-US", { minimumFractionDigits: 2 }) : 0}</Typography>
                </div>
            </Card>
        </Modal>
    )
}

const styles = {
    titleDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '32px 0 18px 0'
    },
    title: {
        color: '#2B2D32',
        fontSize: 18,
        fontWeight: 'bold',
    },
    logo: {
        height: 90,
        width: 90
    },
    msg: {
        color: '#2B2D33',
        fontSize: 16,
        textAlign: 'left',
        padding: '12px 0'
    },
    btn: {
        height: 48,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 4,
        width: '100%',
    },
    input: {
        padding: '11px 16px 11px 16px',
        fontSize: 16,
        height: 48,
        margin: '4px 0px 12px 0px',
        color: '#2B2D33'
    },
    detailsCard: {
        textAlign: 'left',
        margin: '0 0 22px 0',
        boxShadow: '0px 8px 24px rgba(0, 35, 11, 0.12)',
        borderRadius: 8
    },
    flexSpace: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 8,
    },
    key: {
        color: 'rgba(43, 45, 50, 0.64)',
        fontSize: 16,
    },
    value: {
        color: 'rgba(43, 45, 50, 0.8)',
        fontSize: 18,
        fontWeight: '500'
    },
    divider: {
        backgroundColor: '#2B2D32',
        margin: 0
    }
}

export default TransferSuccessModal;