import React from 'react';
import profile from "../../../static/icons/profile.svg";


const AdminHeader = props => {

    const {username, fname, lname} = props.datas;
    const { collapsed } = props;

    return(
        <div style={{display: 'flex'}}>
            <img src={profile} style={{marginTop: 12, width: 40}} alt="profile" />

            <span hidden={collapsed}>
                <div className="nameDivSide">
                    { 
                    fname ? 
                        <div className="nameStyle">
                        {(fname || '') + " " + (lname || '')}
                        </div> 
                        :
                        <div className="nameStyle">
                        {username}
                        </div> 
                    }
                </div>
                {/* <div className="buxTeam">
                    BUx Team
                </div> */}
            </span>
        </div>
    );
};

export default AdminHeader;