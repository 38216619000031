import React, { useState } from 'react'
import * as Icons from '../../../static/icons/mobile_main_icons/index'
import {history} from '../../../store/history'
import {backToTop} from '../../../components/constants/constants'
import { connect } from 'react-redux';
import * as Icon from '../../../static/icons/sidebar/index';

const mapStateToProps = (state) => {
    return {
        'endpoints': state.child_endpoints.endpoints
    }
}

const MobileFooterMenu = props => {

    const {
        segments
    } = props;

    const pathname = window.location.pathname;
    const userType = localStorage.getItem("userType");
    const has_merchant_bias = localStorage.getItem("merchant_bias") === 'true';
    const [forcedRenderKey, setForcedRenderKey] = useState(0);
    const isPayout = pathname === "/funds_enterprise" || 
                        pathname === "/mobile/funds" || 
                        pathname === "/mobile/payout" || 
                        pathname === "/payout_schedule"
    function goToLink(link) {
        history.push(link)
        backToTop()
        setForcedRenderKey(forcedRenderKey + 1);
    }

    return (
        <div className="mobileFooterMenu">
            <div className="space-around">
                <div hidden={!props.endpoints.includes('dashboard')} align="center">
                    <a href onClick={() => goToLink('/dashboard')}>
                        <img alt="Menu Icon" src={pathname === "/dashboard" ? Icon.Dashboard : Icon.InDashboard} className={`icon-style ${pathname === "/dashboard" ? `bg-${segments}` : ""}`} />
                        <div className={`link-style ${pathname === "/dashboard" ? `text-${segments}` : "liveColor"}`}>
                            Dashboard
                        </div>
                    </a>
                </div>
                <div hidden={!props.endpoints.includes('orders')} align="center">
                    <a href onClick={() => goToLink('/orders')}>
                        <img alt="Menu Icon" src={pathname === "/orders" ? Icon.PaymentReq : Icon.InPaymentReq} className={`icon-style ${pathname === "/orders" ? `bg-${segments}` : ""}`}/>
                        <div className={`link-style ${pathname === "/orders" ? `text-${segments}` : "liveColor"}`}>
                            Request
                        </div>
                    </a>
                </div>
                <div align="center" hidden={!props.endpoints.includes('funds_enterprise') && userType === 'CO' || !props.endpoints.includes('funds') && userType === 'ME' || !props.endpoints.includes('funds') && userType === 'SW'}>
                    <a href onClick={() => goToLink('/mobile/funds')}>
                        <img alt="Menu Icon" src={
                            isPayout ?
                            Icon.Cashout 
                            : 
                            Icon.InCashout} 
                            className={`icon-style ${isPayout ? `bg-${segments}` : ""}`}
                            />
                        
                        <div className={`link-style ${isPayout ? `text-${segments}` : "liveColor"}`}>
                            Payout
                        </div>
                    </a>
                </div>
                <div
                    align="center"
                    hidden={
                        ! (props.endpoints.includes('recon')
                        || props.endpoints.includes('integration')
                        || props.endpoints.includes('account_settings'))
                    }
                >
                    <a href onClick={() => goToLink('/mobile/more')}>
                        <img alt="Menu Icon" src={pathname === "/mobile/more" ? Icon.Burger : Icon.InBurger} className={`icon-style ${pathname === '/mobile/more' ? `bg-${segments}` : ""}`}/>
                        <div className={`link-style ${pathname === "/mobile/more" ? `text-${segments}` : "liveColor"}`}>
                            More
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(MobileFooterMenu);