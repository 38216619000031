import { Card, Pagination, Table } from "antd";
import React, { useState } from "react";
import { statusMap } from "../../../constants/statusMap";
import { StatusChip, UserDataCell } from "../../Transfer/components";
import { useFundTransfers } from "../api/getFundTransfers";

export function FundTransfersList() {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const { data, isLoading, error } = useFundTransfers(currentPage);

  return (
    <Card>
      {error ? (
        <div style={{ padding: "16px", color: "red" }}>
          Error fetching data. Please try again by refreshing the page.
        </div>
      ) : (
        <>
          <Table
            rowClassName="table-row-light"
            dataSource={data ? data.results : []}
            columns={[
              {
                title: "Reference ID",
                dataIndex: "id",
                key: "id",
                fixed: "left",
              },
              {
                title: "Receiver",
                dataIndex: "receiver_name",
                key: "receiver_name",
                render: (_, record) => (
                  <UserDataCell
                    name={record.receiver_name}
                    email={record.receiver_email}
                  />
                ),
              },
              {
                title: "Requestor",
                dataIndex: "requestor_name",
                key: "requestor_name",
                render: (_, record) => (
                  <UserDataCell
                    name={record.requestor_name}
                    email={record.requestor_email}
                  />
                ),
              },
              {
                title: "Date Generated",
                dataIndex: "created_at",
                key: "created_at",
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (text) => (
                  <StatusChip status={text} label={statusMap[text] || text} />
                ),
              },
              { title: "Amount", dataIndex: "funds_value", key: "funds_value" },
              {
                title: "Updated by",
                dataIndex: "approver_name",
                key: "approver_name",
                render: (_, record) => (
                  <UserDataCell
                    name={record.approver_name}
                    email={record.approver_email}
                  />
                ),
              },
            ]}
            pagination={false}
            loading={isLoading}
            scroll={{ x: true }}
            style={{ maxWidth: "82dvw" }}
          />
          <Pagination
            size="small"
            total={(data && data.total) || 0}
            defaultPageSize={pageSize}
            pageSize={pageSize}
            current={currentPage}
            onChange={setCurrentPage}
            style={{ paddingTop: "12px", textAlign: "right" }}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
          />
        </>
      )}
    </Card>
  );
}
