import React,{} from 'react';
import { Modal, Typography, Button, Divider, Card, Icon } from 'antd';
import { PaybillsCreditCard } from '../../../../static/icons/dashboard_icons';
import Visa from '../../../../static/icons/card_type/visa.svg';
import MasterCard from '../../../../static/icons/card_type/mastercard.svg';
import { RefundSuccess } from '../../../../static/icons/dashboard_icons'

const SuccessRefundModal = ({ visible, closeModal, data }) => {
 return (
    <Modal
        visible={visible} 
        footer={null} 
        onCancel={closeModal} 
        maskClosable={false} 
        width={360}
        closeIcon={<Icon type="close" style={{color:'#F5922F'}}/>}
      >
      <div>
        <div style={styles.title}>
          <img src={RefundSuccess} alt="No Icon" />
          <strong style={{...styles.regularFont, paddingTop: 16}}>Refund has been sent</strong>
        </div>
        <br/>
        <div style={styles.contentContainer}>
        <Typography style={styles.subtitle}>Banks usually send refunds to their cardholders within 2-14 days.</Typography>
          <Card size="small" style={styles.detailsCard} bodyStyle={{padding: 0}}>
            <Typography style={styles.biller}>Refund Charge Details</Typography>
            <div style={styles.detailsContent}>
              <Typography style={styles.textName}>Reference Code</Typography>
              <Typography style={styles.textDesc}>{data.code}</Typography>
            </div>
            <div style={styles.detailsContent}>
              <Typography style={styles.textName}>Account No.</Typography>
              <div style={{display: 'flex'}}>
                <Typography style={styles.textDesc}>•••• {data.acc_no}</Typography>
                <img alt='No Image' src={data.card_brand === 'VISA' ? Visa : MasterCard} style={{paddingLeft: 8}} />
              </div>
            </div> 
            <div style={styles.amount}>
              <Typography style={{color: '#2B2D33'}}>Amount</Typography>
              <Typography style={styles.textDesc}>
                {data&&
                'PHP ' + parseFloat(data.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              </Typography>
            </div>
          </Card>
        </div>
      </div>
      <Button onClick={closeModal} style={styles.modalBtn}>Done</Button>
    </Modal>
 )
}

const styles = {
  modalBtn: {
    margin: '16px 0px 0px 0px',
    height: 48,
    width: '100%',
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 10
  },
  title: {
    textAlign: 'left',
    display: 'flex', 
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '20px 0 0 0'
  },
  regularFont: {
    fontSize: 16,
    color: '#000000',
    padding: '0 0 0 12px'
  },
  subtitle: {
    padding: '0 0 16px 0',
    color: '#2B2D33',
    fontSize: '16px',
    textAlign: 'center',
  },
  detailsCard: {
    textAlign: 'left',
    margin: '0 0 22px 0'
  },
  biller: {
    backgroundColor: '#E6EAF0',
    padding: '7px 20px',
    fontSize: 16,
    color: '#2B2D33'
  },
  detailsContent: {
    display: 'flex',
    padding: '7px 20px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  textName: {
    fontSize: 12,
    color: '#2B2D33'
  },
  textDesc: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2B2D33'
  },
  amount: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '7px 20px',
    borderTop: '.2px solid #E6EAF0'
  },
}


export default SuccessRefundModal;