import React from 'react';
import { Input } from 'antd';
import * as styles from './style';

const CredentialInput = props => {

    const {
        placeholder, val, changeVal, width, type, editBtn
    } = props;

    return(
        <div style={styles.customDiv}>
            <div style={styles.placeHolder}>
                {placeholder}
            </div>

            <Input disabled={!editBtn} style={{width: width}} value={val} onChange={(e) => changeVal(e.target.value)} type={type} />
        </div>
    );
};

export default CredentialInput;