import { history } from "../../../store/history";

const testMode = (history.location.pathname.indexOf('/test') > -1);

export const headerStyle = {
    width:'100%', 
    height: '60px', 
    display: 'flex', 
    justifyContent: 'center', 
    backgroundColor: '#fff', 
    boxShadow: '0px 3px 5px rgba(57,63,72,0.1)', 
    position: 'fixed', 
    zIndex: '1',
    top: 0
}

export const headerStyle1 = {
    width:'100%', 
    display: 'flex', 
    justifyContent: 'center', 
    position: 'fixed', 
    zIndex: '1',
    top: 60
}

export const logoStyle = {
    marginBottom: '20px', 
    marginTop: '12px', 
    maxWidth: 80
}

export const mainDiv = {
    marginTop: testMode ? '12vh' : '10vh',
}

export const subDiv = {
    border: '1px solid #000000',
    borderRadius: 4,
    width: '30vw',
    padding: 32
}

export const twoColDiv = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
}

export const halfDiv = {
    width: '50%'
}

export const submitBtn = {
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#fafafa',
    backgroundColor: '#f5922f',
    border: '1px solid #f5922f',
    borderRadius: '4px',
    height: '48px',
    width: '100%',
    marginTop: 16,
}