import React from 'react'
import {Card, Button} from 'antd'
import { history } from '../../store/history'
import { isMobile } from 'react-device-detect'
//Image and icons
import WelcomeImage from '../../static/img/welcome_image.svg'

const  WelcomeScreen = () => {

    const segments = localStorage.getItem('segments');


    const me_gig_requirements = [
        'Personal information',
        'Contact information',
        'Employment information',
        'Documents (Valid ID and Selfie)',
    ]

    const sme_biz_requirements = [
        'Business information',
        'Designated contact persons',
        'Business document',
        'Signatory information',
        'Signed MOA and NDA',
        'Bank account information'
    ]

    return(
        <div style={{display: 'flex', justifyContent: 'center', padding: isMobile ? '0px 0px 48px 0px' : '48px 0px 48px 0px'}}>
            <Card style={{width: isMobile ? '100%' : '646px'}} bodyStyle={{padding: isMobile ? '48px 20px 48px 20px' : '48px 56px 48px 56px'}}>
                <div align='center'>
                    <img src={WelcomeImage} style={{width: isMobile && '100%'}}/>
                </div>
                <div style={Style.header}>Welcome to BUx</div>
                <div style={{...Style.text, marginTop: '26px'}}>We’re excited to see you begin your journey on making payments easier for you and your customers! You’re only one step away from unlocking more of our features. Start by completing your profile now.</div>
                <div style={{...Style.text, marginTop: '26px'}}>Requirements:</div>
                <ul>
                {
                    ['me_', 'gig'].includes(segments) ?
                    me_gig_requirements.map((item,i) => {

                        return(
                            <li style={{...Style.text, marginTop: '5px'}}>{item}</li>
                        )
                    })
                    :
                    sme_biz_requirements.map((item,i) => {

                        return(
                            <li style={{...Style.text, marginTop: '5px'}}>{item}</li>
                        )
                    })
                }
                </ul>
                <div style={Style.buttonsDiv}>
                    <a style={{textAlign: isMobile && 'center'}} onClick={()=>history.push('/how_it_works#1')}>
                        <div className={`text-${segments}`} style={{fontSize: 16}}>Take a quick tour</div>
                    </a>
                    <div style={{display: 'flex', justifyContent:'center'}}>
                        <button
                            onClick={()=> {
                                if(['me_', 'gig'].includes(segments)){
                                    history.push('/complete_profile')
                                }else{
                                    history.push('/upgrade_enterprise')
                                }
                            }}
                            className={`btn--${segments}`} 
                            style={Style.completeBtn} 
                        >Complete my profile</button>
                    </div>
                </div>

            </Card>
        </div>
    )

}

const Style = {
    header: {
        color: '#2b2d33',
        fontSize: '28px',
        fontWeight: 'bold',
        marginTop: '40px'
    },
    text:{
        color: 'rgba(43, 45, 50, 0.8)',
        fontSize: '16px',
        fontWeight: 'normal',
    },
    buttonsDiv:{
        display: !isMobile && 'flex',  
        justifyContent: !isMobile && 'flex-end', 
        alignItems: 'center',
        marginTop: '20px'
    },
    completeBtn:{
        marginTop: isMobile && '20px',
        height: '40px',
        width: '203px',
        borderRadius: '4px',
        marginLeft: !isMobile && '30px',
    }
}

export default WelcomeScreen