import React, {useEffect} from 'react'
import axios from 'axios'
import {message, Spin} from 'antd'
import ApproveCard from './approve'
//contract expiry
import ReviewContractExpiry from '../../kyb/forms/review_form/review_contract_expiry'
//business info
import BusinessInfo from '../../kyb/forms/application_form/business_info'
import ReviewBusinessInfo from '../../kyb/forms/review_form/review_business_info'
//contact info
import ContactInfo from '../../kyb/forms/application_form/contact_info'
import ReviewContactInfo from '../../kyb/forms/review_form/review_contact_info'
//business doc
import BusinessDocu from '../../kyb/forms/application_form/business_docu'
import ReviewBusinessDocu from '../../kyb/forms/review_form/reveiw_business_docu'
//signatroy
import SignatoryInfo from '../../kyb/forms/application_form/signatory_info'
import ReviewSignatoryInfo from '../../kyb/forms/review_form/review_signatory_info'
//moa and nda
import MoaNda from '../../kyb/forms/application_form/moa_nda'
import ReviewMoaNda from '../../kyb/forms/review_form/review_moa_nda'
//bank acct
import BankAccount from '../../kyb/forms/application_form/bank_account'
import ReviewBankAccount from '../../kyb/forms/review_form/review_bank_account'
import { history } from '../../../store/history'
import { backToTop } from '../../constants/constants'


const MainReview = props => {

    let segments = localStorage.getItem("segments")

    const {
        cardDivsRefs,
        submissionId,
        childData, setChildData,
        backToTop, alertMessage, setAlertMessage,
        setShowAlert, showAlert, showSpin,
    } = props

    const userType = localStorage.getItem("userType");
    const [failedStep1, setFailedStep1] = React.useState(true)
    const [failedStep2, setFailedStep2] = React.useState(true)
    const [failedStep3, setFailedStep3] = React.useState(true)
    const [failedStep4, setFailedStep4] = React.useState(true)
    const [failedStep5, setFailedStep5] = React.useState(true)
    const [failedStep6, setFailedStep6] = React.useState(true)
    const [editStep1, setEditStep1] = React.useState(true)
    const [editStep2, setEditStep2] = React.useState(true)
    const [editStep3, setEditStep3] = React.useState(true)
    const [editStep4, setEditStep4] = React.useState(true)
    const [editStep5, setEditStep5] = React.useState(true)
    const [editStep6, setEditStep6] = React.useState(true)
    const [openModal, setOpenModal] = React.useState(false)
    const [editMode, setEditMode] = React.useState(false)
    const [offSpin, setOffSpin] = React.useState(true)
    
    const [step, setStep] = React.useState(0)
    const [bt, setBT] = React.useState(0)
    const [moaFile, setMoaFile] = React.useState('')
    const [ndaFile, setNdaFile] = React.useState('')
    const yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }        

    let alertDiv = <div hidden={showAlert} 
                        style={{
                            backgroundColor: 'rgba(226, 76, 76, 0.1)',
                            borderLeft: '4px solid #E24C4C',
                            width: '100%',
                            borderRadius: '4px',
                            marginBottom: '20px',
                            marginTop: '20px',
                            textAlign: 'left',
                            padding: '10px',
                        }}>
                        {alertMessage}
                    </div>


    function setFile(file, type){
        if(type == 'MOA'){
            setMoaFile(file)
        }

        else if(type == 'NDA'){
            setNdaFile(file)
        }
    }


    function setFailedSteps1(val, step){
        setFailedStep1(val)
        props.getFailedSteps(val, failedStep2, failedStep3, failedStep4, failedStep5, failedStep6)
    }

    function setFailedSteps2(val, step){
        setFailedStep2(val)
        props.getFailedSteps(failedStep1, val, failedStep3, failedStep4, failedStep5, failedStep6)

    }

    function setFailedSteps3(val, step){
        setFailedStep3(val)
        props.getFailedSteps(failedStep1, failedStep2, val, failedStep4, failedStep5, failedStep6)

    }

    function setFailedSteps4(val, step){
        setFailedStep4(val)
        props.getFailedSteps(failedStep1, failedStep2, failedStep3, val, failedStep5, failedStep6)

    }

    function setFailedSteps5(val, step){
        setFailedStep5(val)
        props.getFailedSteps(failedStep1, failedStep2, failedStep3, failedStep4, val, failedStep6)

    }

    function setFailedSteps6(val, step){
        setFailedStep6(val)
        props.getFailedSteps(failedStep1, failedStep2, failedStep3, failedStep4, failedStep5, val)

    }
    
        
    function editStep(step){
        backToTop()
        if(step === 1){
            setEditStep1(!editStep1)
            setEditMode(!editMode)
        }
        else if(step === 2){
            setEditStep2(!editStep2)
            setEditMode(!editMode)

        }
        else if(step === 3){
            setEditStep3(!editStep3)
            setEditMode(!editMode)

        }
        else if(step === 4){
            setEditStep4(!editStep4)
            setEditMode(!editMode)

        }
        else if(step === 5){
            setEditStep5(!editStep5)
            setEditMode(!editMode)

        }
        else if(step === 6){
            setEditStep6(!editStep6)
            setEditMode(!editMode)

        }
    }

    async function submitStep(params, step){
        setOffSpin(false)

        try{

            if(step === 1 || step === 2 || step === 6){
                params.submission_id = submissionId
            }

            else if(step === 4){
                params.append("submission_id", submissionId)
            }

            if(childData.step > 1 && step === 1) {
                if(params.email != localStorage.getItem('spocEmail')) {
                    setOffSpin(true)
                    setShowAlert(false)
                    backToTop()
                    setAlertMessage("Email Address is not editable");
                    setTimeout(() => {
                        setShowAlert(true)
                    }, 5000)

                    return;

                }
            }

            let url = process.env.REACT_APP_API_URL + "/api/kyb/";
            let response = await axios.patch(url,params,yourConfig)
            //response
            if(response.data.status == "failed"){
                setShowAlert(false)
                backToTop()
                setOffSpin(true)
                setAlertMessage(response.data.message);
                setTimeout(() => {
                    setShowAlert(true)
                }, 5000)
            }

            else if(response.data.status == "success"){
                backToTop()
                setOffSpin(true)
                setShowAlert(true)
                setChildData(response.data.data)
                setStep(response.data.data.step)
                setBT(response.data.data.business_type)
                if(step == 1){
                    if(localStorage.getItem('lastBusinessType') != response.data.data.business_type)
                    {
                        message.warning("Please update your business documents", 5)
                    }
                    setEditMode(!editMode)
                    setEditStep1(!editStep1)                
                }
                else if(step === 2){
                    setEditStep2(!editStep2)
                    setEditMode(!editMode)

                }
                else if(step === 3){
                    setEditStep3(!editStep3)
                    setEditMode(!editMode)

                }
                else if(step === 4){
                    setEditStep4(!editStep4)
                    setEditMode(!editMode)
                }
                else if(step === 5){
                    setEditStep5(!editStep5)
                    setEditMode(!editMode)
                }
                else if(step === 6){
                    setEditStep6(!editStep6)
                    setEditMode(!editMode)
                }
                else if(response.data.data.step == 8 || response.data.data.step == 9){
                    history.push('/dashboard')
                    window.location.reload()
                }
            }
        }
        
        catch(error){
            setOffSpin(true)

            console.log(error.message)
        }
    }
    
    return(
        <div>   
                <div hidden={!editStep1 || !editStep2 || !editStep3 || !editStep4 || !editStep5 || !editStep6 || userType=='PS' || userType=='PF' }>
                    <ApproveCard failedSteps={[failedStep1, failedStep2, failedStep3, failedStep4, failedStep5, failedStep6]} moaFile={moaFile} ndaFile={ndaFile} userId={props.userId} />
                </div>

                <div hidden={showSpin} align="center" style={{marginTop: 32}}>
                    <Spin size="large" />
                </div>
                <div hidden={!editStep1 || !editStep2 || !editStep3 || !editStep4 || !editStep5 || !editStep6 || userType != 'AD'}>
                    <ReviewContractExpiry failedSteps={[failedStep1, failedStep2, failedStep3, failedStep4, failedStep5, failedStep6]} moaFile={moaFile} ndaFile={ndaFile} userId={props.userId} />
                </div>

                <div hidden={showSpin} align="center" style={{marginTop: 32}}>
                    <Spin size="large" />
                </div>
                {
                childData ? 
                    <div hidden={!showSpin}>
                    {
                    editStep1 ? 
                    <div hidden={!editStep2 || !editStep3 || !editStep4 || !editStep5 || !editStep6}>
                        <ReviewBusinessInfo segments={segments} datas={childData} curStep={step} openEdit={editStep} setFailedStep={setFailedSteps1} cardDivRef={cardDivsRefs.business_info_div_ref}/>
                    </div>
                    :  
                    <BusinessInfo segments={segments} openEdit={editStep} offSpin={offSpin} submitStep={submitStep} datas={childData} alertMessage={alertDiv}/>
                
                    }
                    {
                    editStep2 ? 
                    
                    <div hidden={!editStep1 || !editStep3 || !editStep4 || !editStep5 || !editStep6}>
                        <ReviewContactInfo segments={segments} datas={childData} curStep={step} openEdit={editStep} setFailedStep={setFailedSteps2} userId={props.userId} cardDivRef={cardDivsRefs.contact_info_div_ref}/>
                    </div>
                    :
                    <ContactInfo segments={segments} userId={props.userId} offSpin={offSpin} openEdit={editStep} submitStep={submitStep} alertMessage={alertDiv}/>
                    }
                    {
                    !editStep3 ?
                    <BusinessDocu segments={segments} datas={childData} userId={props.userId} offSpin={offSpin} editMode={editMode} openEdit={editStep} submitStep={submitStep} businessType={bt} alertMessage={alertDiv}/>
                    :
                    <div hidden={!editStep1 || !editStep2 || !editStep4 || !editStep5 || !editStep6}>
                        <ReviewBusinessDocu segments={segments} datas={childData} curStep={step} openEdit={editStep} setFailedStep={setFailedSteps3} userId={props.userId} cardDivRef={cardDivsRefs.business_document_div_ref}/>
                    </div>
                    }
                    {
                    !editStep4 ?
                    <SignatoryInfo segments={segments} datas={childData} userId={props.userId} offSpin={offSpin} editMode={editMode} openEdit={editStep} submitStep={submitStep} alertMessage={alertDiv}/>
                    :
                    <div hidden={!editStep1 || !editStep2 || !editStep3 || !editStep5 || !editStep6}>
                        <ReviewSignatoryInfo segments={segments} datas={childData} curStep={step} userId={props.userId} openEdit={editStep} setFailedStep={setFailedSteps4} userId={props.userId} cardDivRef={cardDivsRefs.signatory_info_div_ref}/>
                    </div>

                    }
                    {
                    !editStep5 ?
                    <MoaNda segments={segments} userId={submissionId} offSpin={offSpin} editMode={editMode} openEdit={editStep} submitStep={submitStep} datas={childData} alertMessage={alertDiv}/>
                    :
                    <div hidden={!editStep1 || !editStep2 || !editStep3 || !editStep4 || !editStep6}>
                        <ReviewMoaNda segments={segments} datas={childData} curStep={step} openEdit={editStep} setFile={setFile} setFailedStep={setFailedSteps5} userId={props.userId} cardDivRef={cardDivsRefs.moa_nda_div_ref}/>
                    </div>
        
                    }

                    {
                    !editStep6 ?
                    <BankAccount segments={segments} userId={props.userId} openEdit={editStep} offSpin={offSpin} editMode={editMode} submitStep={submitStep} datas={childData.bank_account} alertMessage={alertDiv}/>
                    :
                    <div hidden={!editStep1 || !editStep2 || !editStep3 || !editStep4 || !editStep5}>
                        <ReviewBankAccount segments={segments} datas={childData} curStep={step} openEdit={editStep} setFailedStep={setFailedSteps6} userId={props.userId} cardDivRef={cardDivsRefs.bank_account_div_ref}/>

                    </div>
                    }
                    {/* <ReviewBankAccount curStep={step} openEdit={editStep} setFailedStep={setFailedSteps6} userId={props.userId} cardDivRef={cardDivsRefs.bank_account_div_ref}/> */}

                    {/* <div hidden={!editStep1 || !editStep2 || !editStep3 || !editStep4 || !editStep5}>
                        <ReviewBankAccount curStep={step} openEdit={editStep} setFailedStep={setFailedSteps6} userId={props.userId} cardDivRef={cardDivsRefs.bank_account_div_ref}/>
                    </div> */}
                    </div> : ''
                }
                
        </div>
    )
}

export default MainReview