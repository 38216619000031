import React from 'react';
import accountSettingsServices from '../../services/account_settings';
import PromoCards from './promotion_cards';
import { Typography, Divider, Card, Icon, Switch, message } from 'antd';
import { history } from '../../store/history';

const TransactionFees = (props) => {
    const [tranxFee, setTranxFee] = React.useState(false);
    const segments = localStorage.getItem('segments');
    const [loading, setLoading] = React.useState(false);
    const testMode = (history.location.pathname.indexOf('/test') > -1);

    // const isEnterprise = localStorage.getItem('userType') === 'CO';

    const getGenSettings = async () => {
        setLoading(true);
        try {
            const res = await accountSettingsServices.getGenSettings();
            setTranxFee(res && res.shoulder_enabled);
            console.log('RESPONSE_GEN', res);
        } catch (error) {

        }
        setLoading(false);
    }

    const updateTranxFee = async () => {
        setLoading(true);
        try {
            const res = await accountSettingsServices.toggleTranxFee({ "shoulder_enabled": !tranxFee });
            if (res && res.status === "success") {
                setTranxFee(!tranxFee);
            } else {
                message.error(res.message);
            }
        } catch (error) {
            console.log('EEROR', error)
            message.error('Someting went wrong, try again.');
        }
        setLoading(false);
    }

    React.useEffect(() => {
        if (testMode) {
            window.location.hash = '#my_checkout';
        }
        getGenSettings();
    }, [])

    return (
        <Card
            style={styles.root}
            bodyStyle={styles.cardBody}>
            <Typography style={styles.title}>Transaction fees</Typography>
            <Divider />
            <div style={styles.content}>
                <div style={styles.reminderDiv} hidden={['me_', 'gig', 'me'].includes(segments)}>
                    <Icon type="exclamation-circle" theme="filled" style={styles.exclamation} />
                    <div>
                        <Typography style={styles.reminderFont}>If you want to have this changed, please contact us at <b>support@bux.ph</b></Typography>
                    </div>
                </div>
                <div>
                    <div style={{ ...styles.switchDiv, padding: ['me_', 'gig', 'me'].includes(segments) ? 0 : '24px 0 8px 0' }}>
                        <Typography style={styles.tranxSub}>Your Transaction Fees are currently {tranxFee ? 'enabled' : 'disabled'}.</Typography>
                        <Switch size='small' checked={tranxFee} className={tranxFee && tranxFee && `switch-btn--${segments}`}
                            onClick={() => updateTranxFee()} disabled={loading} />
                    </div>
                    <Typography style={styles.desc}>
                        {tranxFee ? 'The transaction fee will be deducted from the total amount requested.' : 'The transaction fee will be added to the total amount upon customer checkout.'}
                    </Typography>
                </div>
            </div>
            <PromoCards />
        </Card>
    )
}

const styles = {
    root: {
        padding: 0,
        width: '100%'
    },
    cardBody: {
        padding: 0
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#2B2D32',
        padding: '32px 32px 0 32px'
    },
    content: {
        padding: '0 32px 24px 32px'
    },
    reminderFont: {
        fontSize: 14,
        color: '#2B2D32',
        opacity: '0.8'
    },
    reminderDiv: {
        display: 'flex',
        backgroundColor: 'rgba(119, 178, 242, 0.16)',
        border: '1px solid #77B2F2',
        padding: '8px 16px'
    },
    switchDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '24px 0 8px 0'
    },
    desc: {
        fontSize: 14,
        color: '#2B2D32',
        opacity: '0.8',
        lineHeight: '20px',
    },
    tranxSub: {
        color: '#2B2D32',
        fontSize: 16,
        fontWeight: '500'
    },
    exclamation: {
        color: '#77B2F2',
        border: '1px solid #77B2F2',
        borderRadius: 25,
        backgroundColor: '#FFF',
        fontSize: 14,
        height: 'fit-content',
        margin: '4px 12px 0 0'
    },
}

export default TransactionFees;