import React from 'react'
import axios from 'axios';
import {Icon, Table, Button, Modal, Dropdown, Menu} from 'antd'
import {TextField, Grid, makeStyles} from '@material-ui/core';
import { useEffect } from 'react'
import {history} from '../../../store/history'
import '../../../static/css/antd.css'
import { Handle401 } from '../../handle401/handle401';
import { message as antdMessage } from 'antd'


const SettingsView = props => {

    const [modalOpen, setModalOpen] = React.useState(false);
    const [visible, setVisible] = React.useState(true);
    const [submissions, setSubmissions] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const [settings_name, setName] = React.useState("");
    const [settings_type, setType] = React.useState(0);
    const [settings_value, setValue] = React.useState("");
    const [settings_id, setId] = React.useState("");
    const [ready, setReady] = React.useState(false)


    const useStyles = makeStyles(theme => ({
      container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginRight: '100%',
        width: 230,
      },
    }));


    const classes = useStyles();

    const openModal = () => {
    setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const update = () => {
          let info = {
              name: settings_name,
              id: settings_id,
              value: settings_value,
              type: settings_type
          };

          updateSettings(info)
          console.log(info)

    }

    const errorMessage = (msg) => {
      antdMessage.destroy()
      antdMessage.error(msg, 2)
    }
    
    async function updateSettings (value) {
        let yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }
  
        let response = await axios.post(
          process.env.REACT_APP_API_URL + '/api/admin_settings/', value,yourConfig
        );
  
        console.log(response)
  
        if(response.data.status == "success"){
            window.location.reload()
        }
        else{
          setVisible(false)
          setMessage(response.data.message)
          errorMessage('Failed to add/update admin setting')
        }
    }

    useEffect(() => {

      if(localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") == null){
        history.push('/login')
      }
      else{
        if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
          history.push('/dashboard')
        }
        else if(localStorage.getItem("userType") == "PS"){
          history.push('/orders')
        }
        else if(localStorage.getItem("userType") == "PF"){
          history.push('/recon/7_eleven')
        }
        else if(localStorage.getItem("userType") == "MA"){
          history.push('/overview')
        }
        else{
          fetchSubmissions()
        }
        
      }
      
    }, [])

    const orderStyle = {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '40px'
    }
    let menu = (id, name, type, value) => (
        <Menu style={{ width: '180px' }}>

          <Menu.Item key="0" onClick={() => {setId(id); setName(name); setType(type); setValue(value); setModalOpen(true);}}>
            Edit
          </Menu.Item>
        </Menu>
    );
    const columns = [
        {
          key: 'name',
          title: 'Settings Name',
          dataIndex: 'name',
        },
        {
          key: 'formatted_value',
          title: 'Formatted Value',
          dataIndex: 'formatted_value',
        },
        {
          key: 'formatted_type',
          title: 'Type',
          dataIndex: 'formatted_type'
        },
        {
          key: 'Action',
          title: 'Action',
          render: (text, record) => (
            <div>
            <Dropdown overlay={() => menu(record.id, record.name, record.type, record.value)} placement="bottomCenter" trigger={['click']} >
              <Icon type="ellipsis" />
            </Dropdown>
            </div>
          )
        }
    ];
    async function fetchSubmissions () {
      let yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
      }

      try{
        let response = await axios.get(
          process.env.REACT_APP_API_URL + '/api/admin_settings/',
          yourConfig
        );
        setReady(true)
        setSubmissions(response.data)
      }

      catch(error){
        if((error.response)&&(error.response.status == 401)){
          Handle401()
        }
      }        
    }

    return(
        <div style={{ paddingTop: '38px', paddingLeft: '42px', paddingRight: '42px' }} hidden={!ready}>
            <div style={{marginBottom: '30px'}}><span style={orderStyle}>Admin Settings</span></div> 

            <Button onClick={() => {setId(0);  setName(""); setType(0); setValue(""); setModalOpen(true);}} type="primary" shape="round" icon="plus" style={{ marginBottom: '25px'}}>
              Add Settings
            </Button>

            <Table
            rowKey={record => record.id}
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
            dataSource={submissions}
            columns={columns} />

            <Modal
                title="Admin Settings"
                centered
                visible={modalOpen}
                //onOk={}
                onCancel={closeModal}
                footer={[
                    <Button key="back" onClick={closeModal}>
                      Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={update}>
                      Update
                    </Button>,
                  ]}
                >

                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Val"
                        className={classes.textField}
                        SelectProps={{
                            native: true,
                            MenuProps: {
                            className: classes.menu,
                            },
                        }}
                        value={settings_type}
                        onChange={ e => setType(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        >
                        <option key="0" value="0">
                        String
                        </option>
                        <option key="1" value="1">
                        Integer
                        </option>
                        <option key="2" value="2">
                        Boolean
                        </option>
                        <option key="3" value="3">
                        Dict
                        </option>
                    </TextField>
                   
                    

                    <TextField
                        id="outlined-basic"
                        label="Name"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        value={settings_name}
                        className={classes.textField}
                        onChange={ e => setName(e.target.value)}

                        />
                    <TextField
                        id="outlined-basic"
                        label="Value"
                        margin="normal"
                        variant="outlined"
                        value={settings_value}
                        fullWidth
                        className={classes.textField}
                        onChange={ e => setValue(e.target.value)}

                        />
                </Modal>

        </div>
    )
    
}

export default SettingsView;