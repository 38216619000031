import React from 'react';
import { Layout, Menu } from 'antd';
import {history} from '../store/history'
const { Sider } = Layout;


const side_tabs = [
    'Basic Information', 'Email and Password', 'Business detail', 'Business address', 'Billing'
  ]

  
class ProfileSideBar extends React.Component {

    constructor(props) {
        super(props)
    
        this.state = {
          title: 'Basic Information'
        }
      }
    
    headerStyle = {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '36px',
        lineHeight: '40px',
        color: '#000000',
        marginBottom: '60px',
        paddingLeft: '165px',
      }

    render() {
        const { title } = this.state
        return (
            <Sider width={400} style={{ background: '#fff', paddingTop: '38px', marginTop:'1vh'}}>
                <div style={this.headerStyle}>Profile</div>
                <Menu defaultSelectedKeys={['1']} style={{ height: '100%', borderRight: 0 }} >
                    {
                        side_tabs.map((item) => {
                            return <Menu.Item
                            onClick={ () => history.push(`${item.toLowerCase().replace(/\s+/g, '_')}`)/this.setState({ title: item})}
                            style = {{
                                opacity: title === item ? '1' : '0.5',
                                marginLeft: '150px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '16px',
                                color: '#000000',
                            }}
                            >{item}
                            </Menu.Item>
                        })
                    }
                </Menu>
            </Sider>
        )
    }
}
export default ProfileSideBar