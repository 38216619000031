import {
    GET_LEDGER_BEGIN,
    GET_LEDGER_SUCCESS,
    GET_LEDGER_ERROR,
} from './ledgerConstants';

const initialState = {
    ledger: [],
    summary: null,
    loading: false,
    error: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LEDGER_BEGIN:
            return {...state, loading: true};
        case GET_LEDGER_SUCCESS:
            return {...state, loading: false, ledger: action.payload.ledger, summary: action.payload.summary};
        case GET_LEDGER_ERROR:
            return {...state, loading: false, error: action.payload.ledger, summary: action.payload.summary};
        default:
            return state;
    }
}

export default reducer;