import React, { useEffect } from 'react'
import accountStyle from '../../../static/css/Account';
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import { Icon, Switch, message, Modal, Alert, Card, Typography } from 'antd';
import { isMobile } from 'react-device-detect'
import { history } from '../../../store/history';
import settingServices from '../settingServices';
import { StatusChip } from '../../../screens/Transfer/components';
import { getLatestBalance } from '../../../layouts/Main';

const { Text, Title } = Typography;
const statusMap = {
  AP: "Approved",
  PE: "Pending",
  RE: "Rejected",
  RS: "Resubmitted"
};

const BankAccount = props => {
  const acct = accountStyle();

  const [primary, setPrimary] = React.useState(true)
  const [edit, setEdit] = React.useState(false)
  const [acctNumber, setAcctNumber] = React.useState('')
  const [acctBank, setAcctBank] = React.useState('')
  const [acctName, setAcctName] = React.useState('')
  const [saveModal, setSaveModal] = React.useState(false)
  const [banks, setBanks] = React.useState([])
  const userType = localStorage.getItem("userType")
  const segments = localStorage.getItem('segments');
  const subCheckout = localStorage.getItem('sub_checkout_enabled') === 'true';
  const hasCashbuxPrivilege = localStorage.getItem('has_cashbux_privilege') === 'true';
  const [isBankAccountLinkable, setIsBankAccountLinkable] = React.useState(false);

  function newBank(bank = null) {
    if (banks.length == 3) {
      message.error("Bank Account Linking, Max of 3 Only", 3)
    } else if (bank) {
      history.push({ pathname: "/link_bank", state: bank });
    } else {
      history.push('/link_bank');
    }
  }

  async function setPrimaryBank(acct_number, acct_bank) {

    let params = {
      account_number: acct_number,
      bank: acct_bank,
    }

    let res = await settingServices.setPrimaryBank(params);

    window.location.reload()

  }

  async function removeBank(acct_number, acct_bank) {
    let params = {
      account_number: acct_number,
      bank: acct_bank,
    }

    let res = await settingServices.removeBank(params);

    window.location.reload()
  }

  async function getBankAccount() {

    let res = await settingServices.getBankAccounts();

    if (res.status === 200) {
      setBanks(res.data);
    } else {
      alert("HTTP-Error: " + res.status);
    }

  }

  function openModal(acct_name, acct_number, acct_bank) {
    setAcctName(acct_name)
    setAcctNumber(acct_number)
    setAcctBank(acct_bank)
    setSaveModal(true)

  }

  const pageRedirect = () => {
    if ((userType === 'SW' && !subCheckout) || props.hidden) {
      window.location.hash = '#transaction_fees';
    }
  }

  useEffect(() => {
    pageRedirect();
    getBankAccount()
  }, [])

  useEffect( () => {
    getPermissions();
  }, [])

  const getPermissions = async () => {
    let data = await getLatestBalance();
    setIsBankAccountLinkable(data.is_bank_account_linkable);
  }

  return (
    <div
      className={acct.personal}
      style={{ width: isMobile ? "100%" : 530, margin: isMobile && 0 }}
    >
      <Modal
        width={360}
        visible={saveModal}
        onCancel={() => setSaveModal(false)}
        footer={null}
      >
        <div align="left">
          <div className={acct.modalTitle}>Unlink Bank Account</div>
          <div className={acct.modalHeader}>
            Are you sure you want to Unlink This Bank:
          </div>

          <div className={acct.modalBankCard}>
            <div className={acct.cardHeader}>{acctBank}</div>
            <div className={acct.cardDetails}>
              <div>
                {" "}
                Account Name: <b>{acctName}</b>
              </div>
              <div style={{ marginTop: "4px" }}>
                {" "}
                Account Number: <b>{acctNumber}</b>
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
            align="center"
          >
            <button
              className={`outline-btn--${segments}`}
              onClick={() => setSaveModal(false)}
              style={{ marginRight: 4 }}
            >
              Cancel
            </button>
            <button
              className={`btn--${segments}`}
              onClick={() => removeBank(acctNumber, acctBank)}
            >
              Proceed
            </button>
          </div>
        </div>
      </Modal>

      <div>
        <div className={acct.personalMenu} style={{ alignItems: "center" }}>
          <div
            className={acct.headerProfile}
            style={{ fontSize: 18, fontWeight: "bold", color: "#2B2D32" }}
          >
            Bank Account
          </div>

          {hasCashbuxPrivilege && banks.length > 0 ? (
            <>
              {banks[0].status === "AP" || banks[0].status === "RE" ? (
                <button
                  className={`btn--${segments}`}
                  style={{ height: 40 }}
                  onClick={() => newBank(banks[0])}
                >
                  <Icon type="plus" style={{ paddingRight: "8px" }} />
                  Edit Bank Account
                </button>
              ) : (
                ""
              )}
            </>
          ) : (
            <div hidden={!isBankAccountLinkable}>
              <button
                className={`btn--${segments}`}
                style={{ height: 40 }}
                onClick={() => newBank()}
              >
                <Icon type="plus" style={{ paddingRight: "8px" }} />
                Link New Account
              </button>
            </div>
          )}
        </div>

        {hasCashbuxPrivilege ? (
          <>
            {banks.length > 0 ? (
              <Card
                style={{ borderLeft: "4px solid #F5922F", marginTop: "1em" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1em",
                  }}
                >
                  <div>
                    <Title level={4}>{banks[0].bank}</Title>
                    <Text type="secondary">{`${banks[0].account_name} | ${banks[0].account_number}`}</Text>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1em",
                    }}
                  >
                    <div>
                      <Title level={4}>Status</Title>
                      <div style={{ width: "120px" }}>
                        <StatusChip
                          status={banks[0].status}
                          label={statusMap[banks[0].status] || banks[0].status}
                        />
                      </div>
                    </div>
                    <div>
                      <Title level={4}>Remarks</Title>
                      <Text type="secondary">
                        {banks[0].remarks
                          ? banks[0].remarks
                          : "Waiting for admin's action"}
                      </Text>
                    </div>
                  </div>
                </div>
              </Card>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <div className={acct.infoDiv} hidden={userType != "CO"}>
              <Alert
                type="info"
                message={
                  <div>
                    If you want to have this changed, please contact us at <b>support@bux.ph</b>
                  </div>
                }
              />
            </div>
            {banks.map((item) => {
              return (
                <div
                  className={acct.bankAcctDiv}
                  style={{
                    borderLeft: item.is_primary ? "4px solid #F5922F" : "",
                  }}
                >
                  <div style={{ lineHeight: isMobile && "3" }}>
                    <div className={acct.bankStyle}>{item.bank}</div>
                    <div
                      className={acct.detailStyle}
                      style={{ margin: isMobile && 0 }}
                    >
                      <span className={acct.name}>{item.account_name}</span> |{" "}
                      <span className={acct.number}>
                        {item.account_number}
                      </span>
                    </div>
                    <div
                      className={acct.primaryStyle}
                      hidden={!item.is_primary}
                    >
                      Primary Bank Account
                    </div>
                    <div
                      className={acct.makePrimaryStyle}
                      hidden={item.is_primary}
                      disabled={!edit}
                    >
                      <div hidden={userType == "CO"}>
                        <Switch
                          className={
                            item && item.isActive && `switch-btn--${segments}`
                          }
                          size="small"
                          style={{ marginRight: "8px" }}
                          checked={item.isActive}
                          onChange={() =>
                            setPrimaryBank(item.account_number, item.bank)
                          }
                        />{" "}
                        Make Primary Bank Account
                      </div>
                      <div className={acct.name} hidden={userType != "CO"}>
                        Secondary Bank Account
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ marginTop: "32px" }}
                    hidden={
                      item.is_primary ||
                      localStorage.getItem("isAlias") == "true"
                    }
                  >
                    <a
                      className={acct.edit}
                      onClick={() =>
                        openModal(
                          item.account_name,
                          item.account_number,
                          item.bank
                        )
                      }
                    >
                      <DeleteOutlineOutlined />
                    </a>
                  </div>
                </div>
              );
            }, this)}
          </>
        )}
      </div>
    </div>
  );
}

export default BankAccount;
