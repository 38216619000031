import axios from "axios";
import {Handle401} from '../handle401/handle401'


async function getPaymentNow(uid, testMode) {
  const url = testMode? '/api/sandbox/': '/api/payment/';
  try {
    let response = await axios.get(
      
      process.env.REACT_APP_API_URL + `${url}${uid}/`
    );
    return response;

  } 
  catch (error) {
    if((error.response)&&(error.response.status == 401)){
        Handle401()
    }
  }
}

export { getPaymentNow };