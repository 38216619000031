import React, {useEffect} from 'react';
import {Layout, Spin} from 'antd';
import logo from '../../static/icons/logo.svg';
import axios from 'axios';
import {history} from '../../store/history';

const XenditC = props => {

    const {Header} = Layout;
    const pUid = props.match.params.uuid;
    const testMode = (history.location.pathname.indexOf('/test') > -1);

    useEffect(() => {
       processPayment()
    }, [])



    async function processPayment() {
        try{

            let endpoint = testMode?`/test/api/xendit/${pUid}`:`/api/xendit/${pUid}`;
            let response = await axios.post(
                process.env.REACT_APP_API_URL + endpoint
            );

        }catch(error){
        }

        let r_endpoint = testMode?`/test/payment/${pUid}`:`/payment/${pUid}`;
        history.push(r_endpoint);
    }

    return(
        <div>
             <Header style={{zIndex: '100', position: 'fixed', top: 0, width: '100%', height: '60px', display: 'flex', justifyContent: 'center', backgroundColor: '#fff', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)'}}>
                <img src={logo} style={{marginBottom: '20px', marginTop: '12px'}} alt="#" />
            </Header>

            <div style={{margin: 0,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateY(50%, 50%)'}}>
                <Spin size="large" />
            </div>
        </div>
    )
}

export default XenditC;