import React from 'react'
import axios from 'axios'
import {Modal} from 'antd'
import feeStyle from './style'


const SetDefaultModal = props => {

    const fee = feeStyle()

    return(
        <Modal
        centered
        width={360}
        visible={props.openModal}
        onCancel={() => props.closeModal()}
        footer={null}>
            <div className={fee.defaultBtn}>Set to Default</div>

            <div className={fee.confirmText}>
            Are you sure you want to overwrite the current Channel’s Fee with the default settings.
            </div>

            <div className={fee.btnDiv}>
                <button className={fee.cancelBtn} onClick={() => props.closeModal()} style={{width: '150px'}} disabled={props.loading}>Cancel</button>
                <button className={fee.editBtn} onClick={() => props.submitDefault()}style={{width: '150px'}} disabled={props.loading}>Confirm</button>

            </div>

    </Modal>
    )
}

export default SetDefaultModal