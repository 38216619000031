import React from 'react';
import accountSettingsServices from '../../services/account_settings';
import { history } from '../../store/history';
import ExportCsvModal from './exportCsvModal';
import moment from 'moment';
import { Typography, Divider, Card, Input, Icon, List, DatePicker, message, Pagination } from 'antd';

const isWidthLowest = window.screen.width < 445;

const sampleData = [
    {
        name: 'Sam Smith',
        email: 'samSmith@smith.com',
        mobile: '+639651588702',
        status: true,
    },
    {
        name: 'Marc Horn',
        email: 'alins@smith.com',
        mobile: '+639651588702',
        status: false,
    }
]

const StatementOfTransactions = (props) => {
    const [dateRange, setDateRange] = React.useState({ startDate: '', endDate: '' });
    const segments = localStorage.getItem('segments');
    const userType = localStorage.getItem('userType');
    const subuserRole = localStorage.getItem('subuser_role');
    const pUid = userType == 'CO' || userType == "ME" ? '' : props && props.props && props.props.match.params.id;
    const [tableData, setTableData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [searchVal, setSearchVal] = React.useState('');
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const [showExportModal, setShowExportModal] = React.useState(false);
    const [email, setEmail] = React.useState(null);
    const [page, setPage] = React.useState(1);

    const TranxCard = (props) => {
        const { isLoading } = props;
        return (
            <List
                // loading={!data}
                dataSource={tableData && tableData.data && tableData.data.length > 0 ? tableData.data : []}
                renderItem={item =>
                    <div>
                        <TransactionCards data={{ ...item }} isLoading={isLoading} />
                    </div>
                }
            />
        )
    }

    const TransactionCards = (props) => {
        const { data, isLoading } = props;

        return (
            data ?
                <Card size='small' style={styles.card} bodyStyle={{ padding: 0 }} loading={isLoading}>
                    <div style={styles.upper}>
                        <Typography style={styles.dataName} >{data.code}</Typography>
                    </div>
                    <div style={styles.lower}>
                        <div style={{ ...styles.flexDiv, padding: '6px 0' }}>
                            <Typography style={styles.normalFont}>Date</Typography>
                            <Typography style={styles.cardValue}>{data.month}</Typography>
                        </div>
                        <div style={{ ...styles.flexDiv, padding: '6px 0' }}>
                            <Typography style={styles.normalFont}>Transaction Count</Typography>
                            <Typography style={styles.cardValue}>{parseInt(data.transaction_volume).toFixed()}</Typography>
                        </div>
                        <div style={{ ...styles.flexDiv, padding: '6px 0' }}>
                            <Typography style={styles.normalFont}>Transaction Volume</Typography>
                            <Typography style={styles.cardValue}>&#8369;{data.amount}</Typography>
                        </div>
                    </div>
                </Card>
                : null
        )
    }

    const exportEmail = () => {
        getData(searchVal, dateRange.startDate, dateRange.endDate, page, 10, true, email);
    }

    const onChangePage = (page) => {
        setPage(page);
        getData(searchVal, dateRange.startDate, dateRange.endDate, page);
    }

    const rangeSelect = (dates, dateString, id) => {
        const days = id === 'endDate' ? (moment(dateString).daysInMonth() - 1) : 0
        setDateRange({ ...dateRange, [id]: moment(dateString).add(days, 'd').format('MM-DD-YYYY') });
        console.log('DAYS', days);
    }

    const onFilterApply = () => {
        getData(searchVal, dateRange.startDate, dateRange.endDate);
    }

    const getData = async (search, start, end, page, pageSize, isExport, email) => {
        setIsLoading(true);
        try {
            const res = await accountSettingsServices.getMonthlySummary(search, start, end, page, pageSize = 5, isExport, email);

            if (isExport && res.status === 'success') {
                setShowExportModal(false);
                message.success(res.message);
            } else if (res.data) {
                setTableData(res);
            } else {
                message.error(res.message);
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            message.error('Please try again');
        }
    }

    const resetFilters = () => {
        setDateRange({ startDate: '', endDate: '' });
        setSearchVal('');
        getData('', '', '');
    }

    const pageRedirect = () => {
        if (localStorage.getItem("jwtToken") === "" || !localStorage.getItem("jwtToken")) {
            history.push('/login')
        }
        else if (!['ME', 'CO', 'SW'].includes(localStorage.getItem("userType"))) {
            history.push('/overview')
        }
        else if (['PS', 'PF'].includes(localStorage.getItem("userType"))) {
            history.push('/submissions_kyb')
        }
        if ((userType === 'SW' && subuserRole == "ME") || testMode) {
            window.location.hash = '#my_checkout';
        }
    }

    React.useEffect(() => {
        pageRedirect();
        resetFilters();
    }, [])

    return (
        <Card
            style={styles.root}
            bodyStyle={styles.cardBody}>
            <div style={{ ...styles.flexDiv, padding: '32px 32px 0 32px', flexDirection: isWidthLowest && 'column' }}>
                <Typography style={styles.title}>Statement of Transactions</Typography>
                <button className={`outline-btn--${segments}`} style={styles.exportBtn} onClick={() => setShowExportModal(true)} ><Icon type='download' /> Export CSV</button>
            </div>
            <Divider />
            <div style={styles.searchDiv}>
                <div style={styles.calendarDiv}>
                    <DatePicker.MonthPicker
                        // format='MM/DD/YYYY'  
                        // disabledDate={disabledDate}
                        // defaultValue={moment()}
                        value={dateRange.startDate && moment(dateRange.startDate)}
                        size="large"
                        picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'startDate')}
                        placeholder='Start Month'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <DatePicker.MonthPicker
                        // format='MM/DD/YYYY'
                        // disabledDate={disabledDate}
                        // defaultValue={moment()}
                        value={dateRange.endDate && moment(dateRange.endDate)}
                        size="large"
                        picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'endDate')}
                        placeholder='End Date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                </div>
                <div style={{ ...styles.searchDiv, flexDirection: 'row', margin: '4px 0', padding: 0 }}>
                    <Input
                        placeholder="Search"
                        style={styles.inputSearch}
                        suffix={<Icon type="search" style={{ fontSize: '18px', marginRight: 6 }} />}
                        onChange={(e) => setSearchVal(e.target.value)}
                        value={searchVal}
                    />
                    <button className={`btn--${segments}`} style={styles.applyBtn} onClick={onFilterApply} >Apply</button>
                </div>
            </div>
            <div style={{ textAlign: 'right' }}>
                <TranxCard isLoading={isLoading} />
                <div style={styles.paginationDiv}>
                    <Pagination
                        size='small'
                        total={tableData && tableData.data ? tableData['pages'] * tableData['page_size'] : 0}
                        defaultPageSize={tableData && tableData.data ? tableData['page_size'] : 5}
                        pageSize={tableData && tableData.data ? tableData['page_size'] : 5}
                        defaultCurrent={1}
                        current={tableData && tableData.data ? tableData['page_number'] : 1}
                        onChange={(page, pageSize) => onChangePage(page, pageSize)}
                    />
                </div>
            </div>
            <ExportCsvModal visible={showExportModal} onClose={() => setShowExportModal(false)}
                onSend={exportEmail} onChange={(e) => setEmail(e)} segments={segments} />
        </Card>
    )
}

const styles = {
    root: {
        padding: 0,
        width: '100%'
    },
    cardBody: {
        padding: 0
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#2B2D32',
        padding: isWidthLowest && '0 0 20px 0'
    },
    addBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: '40%',
        cursor: 'pointer',
        margin: 0
    },
    searchDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 32px',
        width: '100%',
        flexDirection: 'column'
    },
    inputSearch: {
        width: '200px',
        height: '40px',
        borderRadius: '4px',
        padding: '0 8px 0 0px',
        width: '100%'
    },
    editBtn: {
        cursor: 'pointer',
        height: 40,
        margin: '0 32px',
        padding: '0 12px'
    },


    // cards
    card: {
        margin: '16px 0',
        borderRadius: 4,
        padding: 0,
        margin: '20px 32px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
    },
    upper: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '16px',
        alignItems: 'center'
    },
    link: {
        color: '#000000',
        fontSize: 24,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'flex-end',
        height: 18,
        opacity: '.5',
        margin: '8px 0 0 8px'
    },
    dataName: {
        color: '#2B2D32',
        fontSize: 17,
        fontWeight: 'bold'
    },
    lower: {
        borderTop: '1px solid #D1D5DD',
        padding: '10px 16px 16px 16px'
    },
    normalFont: {
        color: '#2B2D32',
        fontSize: 14,
    },
    actionDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    msg: {
        color: '#2B2D33',
        fontSize: 16,
        textAlign: 'left',
        padding: '16px 0 32px 0'
    },
    cancelBtn: {
        cursor: 'pointer',
        margin: '0px 6px',
        height: 40,
        padding: '0 12px'
    },
    continueBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        cursor: 'pointer',
        width: 78,
        margin: 0
    },
    btnDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    flexDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: isWidthLowest ? 'flex-start' : 'center'
    },
    exportBtn: {
        cursor: 'pointer',
        margin: 0,
        height: 40
    },
    cardValue: {
        color: '#2B2D32',
        fontSize: 16,
        fontWeight: '500'
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: '48%',
        // padding: '0 8px 0 8px'
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    calendarDiv: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '8px 0'
    },
    applyBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: '20%',
        cursor: 'pointer',
        // margin: '12px 0'
    },
    paginationDiv: {
        padding: '0 32px 32px 32px', 
        textAlign: 'right'
    }
}

export default StatementOfTransactions;