import React, { useState } from "react";
import { Table, Pagination } from "antd";
import { UserDataCell } from "../../../../Transfer/components";
import { useUsers } from "../api/get-users";

function UsersList({ selectedUserType, searchQuery, startDate, endDate }) {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const { data, isLoading, error } = useUsers(
    currentPage,
    searchQuery,
    "",
    startDate,
    endDate,
    selectedUserType,
    "All",
    selectedUserType === "BUx" ? false : true,
  );

  return (
    <div>
      {error ? (
        <div style={{ padding: "16px", color: "red" }}>
          Error fetching data. Please try again by refreshing the page.
        </div>
      ) : (
        <>
          <Table
            rowClassName="table-row-light"
            dataSource={data ? data.results : []}
            columns={[
              {
                title: "ID",
                dataIndex: "id",
                key: "id",
                fixed: "left",
                render: (_, record) => (
                  <UserDataCell
                    name={record.id}
                    email={record.bux_merchant_id}
                  />
                ),
              },
              {
                title: "Full name & Email",
                dataIndex: "full_name",
                key: "full_name",
                render: (_, record) => (
                  <UserDataCell name={record.full_name} email={record.email} />
                ),
              },
              {
                title: "Role",
                dataIndex: "user_role",
                key: "user_role",
              },
              {
                title: "Date created",
                dataIndex: "created_at",
                key: "created_at",
              },
              {
                title: "Account",
                dataIndex: "key_account",
                key: "key_account",
                render: (_, record) =>
                  record.key_account ? <div>Key</div> : <div>Non-Key</div>,
              },
            ]}
            pagination={false}
            loading={isLoading}
            scroll={{ x: true }}
          />
          <Pagination
            size="small"
            total={(data && data.total) || 0}
            defaultPageSize={pageSize}
            current={currentPage}
            onChange={setCurrentPage}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            style={{ paddingTop: "12px", textAlign: "right" }}
          />
        </>
      )}
    </div>
  );
}

export default UsersList;
