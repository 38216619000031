import axios from 'axios';
import { Handle401 } from '../../../handle401/handle401';

const authorizedAxiosRequest = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const fraudServices = {
  getBlacklistData: async (search='', page=1, type='', sort='newewst', start='', end='', dump=false) => {
    try {
      const res = await authorizedAxiosRequest().get(`/api/admin/blacklists/${type}/?search=${search}&page=${page}&sort_by=${sort}&start=${start}&end=${end}&dump=${dump}`)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getBounceListData: async (search='', page=1, type='', sort='newewst', start='', end='', dump=false) => {
    try {
      const res = await authorizedAxiosRequest().get(`/api/admin/unreachable_emails/?search=${search}&page=${page}&sort_by=${sort}&start=${start}&end=${end}&dump=${dump}`)
      return res.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  addNewDomain: async (params) => {
    try {
      const response = await authorizedAxiosRequest().put(`/api/admin/blacklists/domain/`, params);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getBlacklist: async (search, page, type, sort, start, end, dump) => {
    try {
      const res = await authorizedAxiosRequest().get(`/api/admin/blacklists/${type}/?search=${search}&page=${page}&sort_by=${sort}&start=${start}&end=${end}&dump=${dump}`)
      if(res){
        return res.data;
      }
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      } else if(error.response&&error.response.status == 400) {
        return error.response.data;
      }
      console.log(error);
    }
  },
  addNewBlacklist: async (type, params) => {
    try {
      const response = await authorizedAxiosRequest().put(`/api/admin/blacklists/${type}/`, params);
      if(response){
        return response.data;
      }
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      } else if(error.response&&error.response.status == 400) {
        return error.response.data;
      }
      console.log(error);
    }
  },
  addNewBouncelist: async (params) => {
    try {
      const response = await authorizedAxiosRequest().put(`/api/admin/unreachable_emails/`, params);
      if(response){
        return response.data;
      }
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      } else if(error.response&&error.response.status == 400) {
        return error.response.data;
      }
      console.log(error);
    }
  },
  deleteBlacklist: async (type, params) => {
    try {
      const response = await authorizedAxiosRequest().delete(`/api/admin/blacklists/${type}/`, {data:params});
      if(response){
        return response.data;
      }
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      } else if(error.response&&error.response.status == 400) {
        return error.response.data;
      }
      console.log(error);
    }
  },
  deleteBounceList: async (params) => {
    try {
      const response = await authorizedAxiosRequest().delete(`/api/admin/unreachable_emails/`, {data:params});
      if(response){
        return response.data;
      }
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      } else if(error.response&&error.response.status == 400) {
        return error.response.data;
      }
      console.log(error);
    }
  },
  uploadBlacklistCsv: async (csv, validation, type) => {
    const formData = new FormData();
    formData.append('source_file', csv);
    if(validation){
      formData.append('check_only', true);
    }

    try {
      const response = await authorizedAxiosRequest().post(`/api/admin/blacklists/${type}/`, formData);
      if(response){
        return response.data;
      }
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      } else if(error.response&&error.response.status == 400) {
        return error.response.data;
      }
      console.log(error);
    }
  },
  uploadBounceListCsv: async (csv, validation) => {
    const formData = new FormData();
    formData.append('source_file', csv);
    if(validation){
      formData.append('check_only', true);
    }

    try {
      const response = await authorizedAxiosRequest().post(`/api/admin/unreachable_emails/`, formData);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getKybToTag: async (page, status, search, account_type, segments) => {
    const endpoint_url = '/api/admin/kyb/';
    let query_params = [
      'page=' + page,
      'status=' + status,
      'search=' + search,
      'key_account_type=' + account_type,
      'segments=' + segments,
    ]
    const full_url = endpoint_url + '?' + query_params.join("&")
    try {
      const res = await authorizedAxiosRequest().get(full_url);
      if(res){
        return res.data;
      }
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      } else if(error.response&&error.response.status == 400) {
        return error.response.data;
      }
      console.log(error);
    }
  },
  tagToPending: async (params) => {
    try {
      const res = await authorizedAxiosRequest().patch(`/api/merchant_entries/`, params);
      if(res){
        return res.data;
      }
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      } else if(error.response&&error.response.status == 400) {
        return error.response.data;
      }
      console.log(error);
    }
  },
}

export default fraudServices;