import React from 'react'
import axios from 'axios'
import {Modal, Spin, message} from 'antd'
import feeStyle from './style'
import { Checkbox, Grid } from "@material-ui/core";


const CustomizeModal = ({pUid, closeModal, showModal, channels, activeMethod, disabled, disabledName, setDisabled, successUpdate}) => {

    const fee = feeStyle()

    const [isReady, setIsReady] = React.useState(true)

    async function setChannels(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        setIsReady(false)

        try {
            let params = {'disabled': disabledName, 'user_id': pUid, 'type': 'channels'}
            let response = await axios.post(
                process.env.REACT_APP_API_URL + `/api/corporate/channels/${pUid}/`, params, yourConfig)
                
                if(response.status === 200){
                    setIsReady(true)
                    closeModal()
                    message.destroy()
                    message.success('Channels Updated!', 5)
                    successUpdate();
                }
                
                else {
                    setIsReady(true)
                    console.log(response)
                }
        }

        catch (error) {
            console.log(error)
        }
    }


    function checkIfDisabled(channel, tier){
        if(disabledName.includes(channel)){
            return false;
        }

        else {
            return true;
        }
    }

    console.log(disabledName)

    return(
        <Modal
        centered
        width={724}
        visible={showModal}
        onCancel={() => closeModal()}
        footer={null}>
            <div style={{
                marginTop: 32,
                fontSize: 24,
                lineHeight: '32px',
                fontWeight: '300',
                color: '#2b2d33'
            }}>{activeMethod}
            </div>

            <div style={{
                marginBottom: 20,
                paddingBottom: 20,
                fontSize: '14px',
                lineHeight: '18px',
                color: '#2b2d33', borderBottom: '1px solid',
                borderBottomColor: '#E6EAF0'
            }}>
            { channels.length != null ? channels.length +" Channels are enabled." : "" }
            </div>

            <div>
                <div align='center' style={{marginTop: 32}} hidden={isReady}>
                    <Spin size="large"/>
                </div>

                <Grid container hidden={!isReady}>

                    {
                        channels.map((item, key) => {
                            return (<Grid item lg={3} xs={6}>
                                    <span>
                                        <Checkbox style={{paddingRight: '10px'}} 
                                        onChange={() => setDisabled(item.channel_code, item.tier, item.channel)} 
                                        checked={checkIfDisabled(item.channel_code, item.tier)}/>
                                        {item.channel}
                                    </span>
                            </Grid>)
                        })
                    }
                </Grid>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 20
            }}>
                <div>
                    <button className={fee.cancelBtn} onClick={() => closeModal()} style={{width: '150px'}}>Cancel</button>
                </div>

                <div>
                    <button className={fee.editBtn} disabled={!isReady} onClick={() => setChannels()} style={{width: '150px'}}>Confirm</button>
                </div>

            </div>

    </Modal>
    )
}

export default CustomizeModal