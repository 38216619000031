import React from 'react'
import pricingStyle from '../../static/css/Pricing';
import {Grid} from '@material-ui/core'
import {Icon} from 'antd'
import { SevenEleven, BayadCenter, Cebuana_Lhuillier, Ecpay, Expresspay, Ruralnet, Sm_new,
    Lbc, ML, Palawan, RD, Robinson, Rural, Sm, Pay_N_Go, DA5,Posible, USSC, Rustan, Shopwise, 
    Tambunting, CVM, Wellcome, Villarica, Petnet } from '../../static/icons/payment_method_icons/index'
import { backToTop } from '../constants/constants'


const MobilePaymentChannel = props => {
    const userType = localStorage.getItem('userType')
    
    const p = pricingStyle();

    
        const channels = [
            {
                icon: SevenEleven,
                name: '7-Eleven',
                min: '₱ 50.00',
                max: '₱ 10,000.00',
                emax: '₱ 10,000.00'
            },
            {
                icon: BayadCenter,
                name: 'Bayad Center',
                min: '₱ 50.00',
                max: '₱ 30,000.00',
                emax: '₱ 80,000.00'
    
            },
            {
                icon: Cebuana_Lhuillier,
                name: 'Cebuana Lhuillier',
                min: '₱ 50.00',
                max: '₱ 30,000.00',
                emax: '₱ 50,000.00'
            },
            {
                icon: ML,
                name: 'MLhuillier',
                min: '₱ 180.00',
                max: '₱ 30,000.00',
                emax: '₱ 50,000.00'
            },
            {
                icon: Ecpay,
                name: 'ECPay',
                min: '₱ 50.00',
                max: '₱ 30,000.00',
                emax: '₱ 50,000.00'
            },
            {
                icon: Lbc,
                name: 'LBC',
                min: '₱ 50.00',
                max: '₱ 30,000.00',
                emax: '₱ 80,000.00'
            },
            {
                icon: Robinson,
                name: 'Robinsons Dept Store',
                min: '₱ 50.00',
                max: '₱ 30,000.00',
                emax: '₱ 80,000.00'
            },
            {
                icon: Ruralnet,
                name: 'Ruralnet',
                min: '₱ 50.00',
                max: '₱ 30,000.00',
                emax: '₱ 80,000.00'
            },
            {
                icon: Sm_new,
                name: 'SM Department / Supermarket Savemore Counter',
                min: '₱ 50.00',
                max: '₱ 30,000.00',
                emax: '₱ 80,000.00'
            },
            {
                icon: Expresspay,
                name: 'Expresspay',
                min: '₱ 50.00',
                max: '₱ 20,000.00',
                emax: '₱ 20,000.00'
            },
            {
                icon: RD,
                name: 'RD Pawnshop',
                min: '₱ 50.00',
                max: '₱ 20,000.00',
                emax: '₱ 20,000.00'
    
            },
            {
                icon: Palawan,
                name: 'Palawan Pawnshop',
                min: '₱ 50.00',
                max: '₱ 20,000.00',
                emax: '₱ 20,000.00'
            },
            {
                icon: Pay_N_Go,
                name: 'Pay&Go',
                min: '₱ 100.00',
                max: '₱ 5,000.00',
                emax: '₱ 5,000.00'
            },
            {
                icon: DA5,
                name: 'DA5',
                min: '₱ 50.00',
                max: '₱ 20,000.00',
                emax: '₱ 20,000.00'
            },
            {
                icon: Posible,
                name: 'Posible',
                min: '₱ 50.00',
                max: '₱ 10,000.00',
                emax: '₱ 10,000.00',
            },
            {
                icon: USSC,
                name: 'USSC',
                min: '₱ 50.00',
                max: '₱ 20,000.00',
                emax: '₱ 20,000.00',
            },
            // {
            //     icon: Rustan,
            //     name: 'Rustan Supercenter',
            //     min: '₱ 50.00',
            //     max: '₱ 20,000.00',
            //     emax: '₱ 20,000.00',
            // },
            // {
            //     icon: Shopwise,
            //     name: 'Shopwise',
            //     min: '₱ 50.00',
            //     max: '₱ 20,000.00',
            //     emax: '₱ 20,000.00',
            // },
            // {
            //     icon: Wellcome,
            //     name: 'Wellcome',
            //     min: '₱ 50.00',
            //     max: '₱ 20,000.00',
            //     emax: '₱ 20,000.00',
            // },
            {
                icon: Tambunting,
                name: 'Tambunting Pawnshop',
                min: '₱ 50.00',
                max: '₱ 20,000.00',
                emax: '₱ 20,000.00',
            },
            {
                icon: CVM,
                name: 'CVM Pawnshop',
                min: '₱ 50.00',
                max: '₱ 20,000.00',
                emax: '₱ 30,000.00',
            },
            {
                icon: Villarica,
                name: 'Villarica',
                min: '₱ 50.00',
                max: '₱ 20,000.00',
                emax: '₱ 20,000.00',
            },
            {
                icon: Petnet,
                name: 'Petnet, Inc.',
                min: '₱ 50.00',
                max: '₱ 20,000.00',
                emax: '₱ 20,000.00',
            },
        ]
    
    channels.sort(function (a, b){
        let nameA = a.name.toLowerCase();
        let nameB = b.name.toLowerCase();
        if(nameA < nameB) {
            return -1;
        }

        if (nameA > nameB) {
            return 1;
        }

        return 0;
    });

    return(
        <div>
            <div align="center" className={p.subDes}>
            Below is the list of available over-the-counter channels. Fee for over-the-counter payments is <span className={`text-${props.segments} wc-header`}>₱ 20.00</span> per transaction.
            </div>
            {
                channels.map((item => {
                    return(
                        <div className={p.cards}>
                            <div className={p.cardHeader} style={{display: 'flex', justifyContent: 'space-between'}}align='center'>
                                <div style={{paddingRight: '16px'}}><img src={item.icon} style={{height: item.name == 'Posible' && '60%'}}/></div>
                                <div style={{marginTop: '3px',}} align='right'>{item.name}</div>
                            </div>
                            
                            <Grid container className={p.cardInfo}>

                                <Grid item xs={7}>
                                    <div className={p.cardPlaceholder}>
                                        Minimum Amount
                                    </div>
                                </Grid>

                                <Grid item xs={5}>
                                    <div className={p.cardVal} align='right'>
                                        {item.min}
                                    </div>
                                </Grid>
                            
                            </Grid>

                            <Grid container className={p.cardInfo}>

                                <Grid item xs={7}>
                                    <div className={p.cardPlaceholder}>
                                        Maximum Amount
                                    </div>
                                </Grid>

                                <Grid item xs={5}>
                                    <div className={p.cardVal2} align='right'>
                                        {
                                            userType == 'CO' || userType == 'SW' ? 
                                                item.emax :
                                                item.max
                                        }
                                    </div>
                                </Grid>
                            </Grid>

                        </div>
                    )
                }))
            }

            <div align="center" style={{padding: 16}}>
                <b>*surcharge fees are additional fees charged to the customer.</b>
            </div>

            <div className={p.compleShareDiv} align='left'>
                <button className={`outline-btn--${props.segments} btn-height btn-mobile-width`} onClick={backToTop}><Icon type="up" style={{marginRight: '8px' }}/> Back to Top</button>
            </div>


        </div>
    )
}

export default MobilePaymentChannel;