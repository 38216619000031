import React, {useEffect} from 'react'
import { Input } from 'antd'

const PromoSocialMediaPost = props => {

    const {
        headerText, handleChange, params, setParams, isLoading, view_path, id 
    } = props

    const ValueColumnDiv = (key, value) => {
        return(
            <div className="twoCol promotion-value-div liveColor">
                <div className="normalTextStyle">
                    {key}
                </div>
                <div className="normalTextStyle medium-font-weight liveColor">
                    {value}
                </div>
            </div>
        )
    }

    useEffect(()=>{
        console.log(params)
    },[params])

    const ViewPromo = () => {
        return(
            <div>
                <div className="promotion-header">
                    Social Media Post Link
                </div>

                <div className="top-32">
                    {ValueColumnDiv('Facebook Post Link', params['facebook_post'])}
                    {ValueColumnDiv('Instagram Post Link', params['instagram_post'])}
                    {ValueColumnDiv('Twitter Post Link', params['twitter_post'])}
                </div>
            </div>
        )
    }

    return(
        <div className="promotion-card padding-32 top-32">
            {
                view_path === 'view_promotion' ?
                <ViewPromo />
                :
                <div>
                    <div className="promotion-header">
                        {headerText}
                    </div>

                    <div className="twoCol top-32" align='left'>
                        <div style={{width: '49%'}} className="rigth-8">
                            <div className="wc-text placeholder-text-color">
                                Facebook Post Link
                            </div>
                            <div className="top-4">
                                <Input 
                                    placeholder="Facebook Post Link"
                                    name="facebook_post"
                                    value={params['facebook_post']}
                                    onChange={handleChange}
                                    disabled={isLoading}
                                    maxLength={250}/>
                            </div>
                        </div>
                        <div style={{width: '49%'}} className="left-8">
                            <div className="wc-text placeholder-text-color">
                                Instagram Post Link
                            </div>
                            <div className="top-4">
                                <Input 
                                    placeholder="Instagram Post Link"
                                    name="instagram_post"
                                    value={params['instagram_post']}
                                    onChange={handleChange}
                                    disabled={isLoading}
                                    maxLength={250}/>
                            </div>
                        </div>
                    </div>

                    <div className="twoCol top-32" align='left'>
                        <div style={{width: '49%'}} className="rigth-8">
                            <div className="wc-text placeholder-text-color">
                                Twitter Post Link
                            </div>
                            <div className="top-4">
                                <Input 
                                    placeholder="Twitter Post Link"
                                    name="twitter_post"
                                    value={params['twitter_post']}
                                    onChange={handleChange}
                                    disabled={isLoading}
                                    maxLength={250}/>
                            </div>
                        </div>
                    </div>


                </div>
            }
        </div>
    )
}

export default PromoSocialMediaPost