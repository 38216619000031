import React from 'react'
import {isMobile} from 'react-device-detect'
import NewBankAccount from './newBankAcct'
import Main from '../../../layouts/Main'
import MobileMain from '../../../layouts/Mobile/MobileMain'

const NewBank = props => {
    
    return(
        <div>
            <Main>
                <NewBankAccount/>
            </Main>
        </div>
    )
}

export default NewBank