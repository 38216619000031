import React from 'react';
import { Card, Typography } from 'antd';
import { LargePayoutIcon } from '../../../../static/images/multiwallet';

const BalanceCard = (props) => {
    const { bal } = props;
    return (
        <div className="twoCol" style={{padding: '32px 0 12px 0'}}
        >
            <div style={styles.div1}>
                <Typography style={styles.title}>Transfer Money</Typography>
            </div>
            <div style={styles.div2}>
                <img alt='No Image' src={LargePayoutIcon} style={{paddingRight: 16}} />
                <div>
                    <Typography style={styles.text}>
                        Transferable Balance: <span className="large-bold-text"> ₱ {bal? bal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") : '0.00'} </span>
                    </Typography>
                </div>
            </div>
        </div>
    )
}

const styles = {
    root: {
        width: '100%',
    },
    title: {
        color: '#000',
        fontWeight: 'bold',
        fontSize: 29
    },
    bodyStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    div1: {
        width: '50%', 
        textAlign: 'left'
    },
    div2: {
        width: '50%', 
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    text: {
        color: '#2B2D32',
        fontSize: 14,
        fontWeight: '500'
    }
}

export default BalanceCard;