import React from 'react';
import TestModeBanner from './testModeBanner';
import { Button, Card, Divider, Typography } from 'antd';
import { CardIcon, AccountCardBG } from '../../../static/images/test_mode';
import { isMobile } from 'react-device-detect';

const AuthorizedCard = (props) => {
    const type = props.type;
    const details = props.details;
    const [loading, setLoading] = React.useState(false);

    const onClick = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            props.onAuthorized();
        }, 3000);
    }

    const AccountCard = (
        <div style={styles.accountCard}>
            <div style={styles.upperCard}>
                <img src={CardIcon} style={{paddingRight: 6}} />
                <div>
                    <Typography style={{...styles.title, color: '#FFF'}}>{props.name? props.name : 'Test Account'}</Typography>
                    <Typography style={{...styles.details, fontWeight: '300', color: '#FFF'}}>BUx Test Card</Typography>
                </div>
            </div>
            <div style={styles.lowerCard}>
                <Typography style={{...styles.details, fontWeight: '300', color: '#FFF'}}>{props.accountNumber? props.accountNumber : '**** **** 1234'}</Typography>
                <div>
                    <Typography style={styles.availBal}>Available Balance</Typography>
                    <Typography style={{...styles.details, fontWeight: 'bold', color: '#FFF', textAlign: 'right'}} >₱ 100,000.00</Typography>
                </div>
            </div>
        </div>
    )       

    return (
        <Card style={{width: isMobile? '100%' : 568}} bodyStyle={styles.root} hidden={props.hidden}>
            <TestModeBanner type={type} />
            <div style={{padding: isMobile? 16 : '16px 86px', textAlign: 'left'}}>
                <div style={styles.paymentDiv}>
                    <Typography style={styles.normalBold}>Amount to Pay</Typography>
                    <Typography style={styles.amount}>₱ {details ? parseFloat(details.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '500.00'}</Typography>
                </div>
                <div hidden={type==='GCASH'}>
                    <div>
                        <Typography style={styles.title}>Account Details</Typography>
                        <Divider style={styles.divider}  />
                    </div>
                    {AccountCard}
                </div>
                <div>
                    <Typography style={styles.title}>Payment Details</Typography>
                    <Divider  style={styles.divider}  />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', padding: '16px 0'}}>
                    <Typography style={{...styles.normalBold, fontWeight: 'normal'}}>Merchant Name</Typography>
                    <Typography style={styles.normalBold}>{details && details.owner? details.owner : details && details.name? details.name : 'Jonh Wick'}</Typography>
                </div>
                <Typography hidden={type==='GCASH'} style={styles.details}>
                    {details ? details.description : '1 Daniel Wellington rose gold 1 Daniel Wellington rose gold1 Daniel Wellington rose gold1 Daniel Wellington rose gold'}
                </Typography>
                <div style={{textAlign: 'center'}}>
                    <Button
                        style={styles.btn}
                        onClick={onClick}
                        loading={loading}
                    >
                        {type === 'GCASH' ? 'Pay Now' : 'Authorized Payment'}
                    </Button>
                </div>
            </div>
        </Card>
    );
}

const styles = {
    root: {
        padding: 0,
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
    },
    btn: {
        margin: '20px 0px 0px 0px',
        height: 48,
        width: isMobile ? '80%' : '60%',
        backgroundColor: '#F5922F',
        color: '#FAFAFA',
        borderRadius: 4,
        fontSize: 16,
        fontWeight: '600',
        textAlign: 'center',
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#000',
    },
    normalBold: {
        fontWeight: 'bold',
        fontSize: 16,
        color: '#2B2D33',
    },
    details: {
        fontSize: 12,
        color: '#000',
    },
    paymentDiv: {
        textAlign: 'center',
        padding: '16px 0 32px 0',
        lineHeight: '1.2',
    },
    amount: {
        fontSize: 28,
        fontWeight: 'bold',
        color: '#0D3D76'
    },
    divider: {
        color: '#000',
        margin: '2px 0',
        borderTop: '1px solid #000', 
    },
    accountCard: {
        // backgroundImage: `url('${AccountCardBG}')`,
        // backgroundRepeat: 'no-repeat',
        // backgroundAttachment: 'fixed',
        // backgroundPosition: 'center',
        // backgroundSize: '110% 120%',
        background: 'linear-gradient(251.08deg, #044B9B 25.77%, #251F5D 68.49%)',
        boxShadow: '0px 0px 11px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
        padding: 14,
        width: '100%',
        margin: '16px 0' 
    },
    upperCard: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center'
    },
    lowerCard: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    availBal: {
        fontSize: 8,
        fontWeight: '600',
        color: '#FFF',
    }
}

export default AuthorizedCard;