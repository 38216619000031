import React, { } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Typography, Icon, Button } from 'antd';
import LocationOnIcon from '@material-ui/icons/LocationOn';


const renderLocations = (location) => {
  return (
   location ? location.map((item, index) => {
     return (
      <div key={index} style={{border: '1px solid #E6EAF0', borderRadius: 10, padding: '16px', margin: '4px 0px'}}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <LocationOnIcon style={{ color: '#E24C4C', fontSize: 32, margin: '0px 16px 0px 6px' }} />
          <div>
          <Typography style={{fontSize: 16, color: '#000000'}}><strong>{item.name}</strong></Typography>
          <Typography style={{fontSize: 14, color: '#000000'}}>{item.address}</Typography>
          </div>
        </div>
        {/*<a><Typography style={{fontSize: 14, color: '#F5922F', textAlign: 'right', fontWeight: 'bold', padding: '8px 0 0 0'}}>See Map</Typography></a>*/}
      </div>
     )
   })
   
 : <Typography>No Retail Shop in the Area</Typography>
  )
}

const RetailShopResultModal = ({ closeModal, locations, city, province }) => {
  const visibleSuccessModal = useSelector(state => state.card['retail_shop_result']);
  const segments = localStorage.getItem('segments');
  
  return (
    <Modal visible={visibleSuccessModal} footer={null} askClosable={false} centered width={384} bodyStyle={{ padding: 20 }} onCancel={closeModal}>
      <Typography style={{ fontSize: 24 }}>Retail Shop Result</Typography>
      <Typography style={{padding: '24px 0 0 0', color: '#000000'}}>{city}, {province}</Typography>
      <div style={{maxHeight: 440, overflowY: 'auto'}}>
        {renderLocations(locations)}
      </div>
      <button style={styles.btn} className={`btn--${segments}`} onClick={closeModal}>Got It</button>
    </Modal>
  )
}

const styles  = {
btn: {
  margin: '16px 0px 0px 0px',
  height: 40,
  width: '100%',
  // backgroundColor: '#F5922F',
  // color: '#FFFFFF',
  borderRadius: 4,
  fontSize: 14,
  fontWeight: '600',
}

}

export default RetailShopResultModal;