import React from 'react';
import moment from 'moment';
import { Button, DatePicker, Divider, Icon, Input, message, Modal, Popover, Select, Typography, Checkbox, Row } from 'antd';
import { history } from '../../../store/history';
import TextArea from 'antd/lib/input/TextArea';
import promotionServices from './promotionServices';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { OTC_PROMO,  OTC_LIST_PROMO, OTC_NAME_PROMO } from '../../constants/constants';

const PromoModal = (props) => {
    const { mode, data, onSuccess } = props;
    const [isLoading, setIsloading] = React.useState(false);
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const [dateRange, setDateRange] = React.useState({ startDate: '', endDate: '' });
    const segments = localStorage.getItem('segments');
    const [indeterminateSeg, setIndeterminateSeg] = React.useState(false);
    const [checkAllSeg, setCheckAllSeg] = React.useState(false);
    const [indeterminateCha, setIndeterminateCha] = React.useState(true);
    const [checkAllCha, setCheckAllCha] = React.useState(true);
    const [checkedList, setCheckedList] = React.useState(OTC_LIST_PROMO);
    const [params, setParams] = React.useState({
        code: '',
        segments: ['ME_', 'GIG'],
        discount_type: 'FI',
        percentage_discount: 0,
        fixed_discount: 0,
        description: '',
        channels: OTC_LIST_PROMO,
        start_date: '',
        end_date: '',
    });

    const defaultSegments = ['ME_', 'GIG', 'SME', 'BIZ', 'CO'];

    const validateFields = () => {

        if (Object.keys(params['code']).length <= 0) {
            message.error('Code is required');
        } else if (Object.keys(params['code']).length < 5) {
            message.error('Promo code minimum character must be more than 4');
        } else if (Object.keys(params['fixed_discount']).length === 0 && Object.keys(params['fixed_discount']) <= 0) {
            message.error('Promo amount is required');
        } else if (Object.keys(params['start_date']).length <= 0 || Object.keys(params['end_date']).length <= 0) {
            message.error('Start and End date are required');
        } 
        else {
            mode === 'edit' ? updatePromoCode() : addPromoCode();
        }
    }

    const dateFormat = 'MM-DD-YYYY';

    const datePickerHelperFunctions = {
        disabledStartDates: (current) => {
            // Can not select days before today
            let isDisabled = current && current < moment().subtract(1, 'days').endOf('day');

            if(params.end_date) {
                 // Cannot select days after the start date, if available
                isDisabled = isDisabled || current > moment(params.end_date, dateFormat).startOf('day');
            }

            return isDisabled
        },
    
        disabledEndDates: (current) => {
            // Cannot select days in the future
            let isDisabled = current && current > moment().endOf('day');
    
            if (params.start_date) {
                // Cannot select days before the start date, if available
                isDisabled = current < moment(params.start_date, dateFormat).startOf('day');
            }
    
            return isDisabled;
        }
      };

    const disabledDate = (current) => {
        return current && current < moment().subtract(1, 'days').endOf('day');
    }

    const rangeSelect = (dates, dateString, id) => {
        setParams({ ...params, [id]: moment(dateString).format('MM-DD-YYYY') });
    }

    const resetFields = () => {
        arrangeParams();
    }

    const addPromoCode = async () => {
        setIsloading(true);

        try {
            const res = await promotionServices.createPromoCode(params);
            if (res.status === 'success') {
                message.success(res.message);
                resetFields();
                onSuccess();
                props.onClose();
            } else if (res.status === 'error') {
                message.error(res.message);
            }
        } catch (error) {
            console.log(error);
            message.error('Error please try again');
        }
        setIsloading(false);
    }

    const updatePromoCode = async () => {
        setIsloading(true);

        try {
            const res = await promotionServices.updatePromoCode(params);
            if (res.status === 'success') {
                message.success(res.message);
                resetFields();
                onSuccess();
                props.onClose();
            } else if (res.status === 'error') {
                message.error(res.message);
            }
        } catch (error) {
            console.log(error);
            message.error('Error please try again');
        }
        setIsloading(false);
    }

    // ## discount types
    // PERCENTAGE = 'PE'
    // FIXED = 'FI'
    // COMBO = 'CO'

    const handleChange = (event, type) => {
        const name = type ? type : event.target.name;
        const value = type ? event : event.target.value;

        if (name === 'percentage_discount' || name === 'fixed_discount') {
            setParams({ ...params, [name]: autoFormat(value) })
        } else if (name === 'code') {
            setParams({ ...params, [name]: (value).toUpperCase() })
        } else {
            setParams({ ...params, [name]: value })
        }
    }

    const autoFormat = (value) => {
        const formattedValue = value.replace(/\D/g, '')
        if (value !== null) {
            if(formattedValue > 20) {
                return 20;
            }
            return formattedValue;
        }
        return value;
    }

    const sliceSymbols = (text) => {
        const formattedValue = text.replace(/[^a-zA-Z0-9 ]/g, "")
        if (text !== null) {
            return formattedValue;
        }
        return text;
    }


    const renderSegments = (e) => {
        // let filteredValues = []
        // Object.keys(choices).map(i => i != 0 && filteredValues.push(choices[i].id))

        const onChange = list => {

            setParams({ ...params, segments: list });
            setIndeterminateSeg(!!list.length && list.length < defaultSegments.length);
            setCheckAllSeg(list.length === defaultSegments.length);
        };

        const onCheckAll = (value) => {
            setIndeterminateSeg(false);
            setCheckAllSeg(value);
            setParams({ ...params, segments: value ? defaultSegments : [] });
        }

        return (
            <div style={styles.optionDiv}>
                <Row>
                    <Checkbox indeterminate={indeterminateSeg} onChange={() => onCheckAll(!checkAllSeg)} checked={checkAllSeg} >
                        Check All
                    </Checkbox>
                    <Checkbox.Group style={{ display: 'flex', flexDirection: 'column' }} options={defaultSegments} onChange={(e) => onChange(e)} value={params && params['segments']}>
                    </Checkbox.Group>
                </Row>
            </div>
        )
    }

    const renderChannels = (e) => {
        // let filteredValues = []
        // Object.keys(choices).map(i => i != 0 && filteredValues.push(choices[i].id))

        const onChange = list => {
            setCheckedList(list);
            setIndeterminateCha(!!list.length && list.length < OTC_LIST_PROMO.length);
            setCheckAllCha(list.length === OTC_LIST_PROMO.length);
            let channel_selected = []
            try {
                list.map((channel, i) => {
                    if (typeof OTC_PROMO[channel] && OTC_PROMO[channel].code !== 'undefined') {
                        channel_selected.push(OTC_PROMO[channel].code);
                    }
                });
            } catch (error) {

            }
            setParams({ ...params, channels: channel_selected });
        };

        const onCheckAll = (value) => {
            setCheckedList(value ? OTC_LIST_PROMO : []);
            setIndeterminateCha(false);
            setCheckAllCha(value);
            let channel_selected = []
            try {
                OTC_LIST_PROMO.map((channel, i) => {
                    if (OTC_PROMO[channel].code) {
                        channel_selected.push(OTC_PROMO[channel].code);
                    }
                });
            } catch (error) {

            }
            setParams({ ...params, channels: value ? channel_selected : [] });
        }

        return (
            <div style={styles.optionDiv}>
                <Row>
                    <Checkbox indeterminate={indeterminateCha} onChange={() => onCheckAll(!checkAllCha)} checked={checkAllCha} >
                        Check All
                    </Checkbox>
                    <Checkbox.Group style={{ display: 'flex', flexDirection: 'column' }} options={OTC_LIST_PROMO} onChange={(e) => onChange(e)} value={checkedList}>
                    </Checkbox.Group>
                </Row>
            </div>
        )
    }

    const hasBlanks = () => {
        // const isEmpty = Object.values(params).some(x => (x === null || x === ''));
        // if (isEmpty === true || Object.keys(params).length < 5) {
        //     return true;
        // }

        // if (params.start_date.length <= 0 || params.end_date.length <= 0 || params['discount_type'].length <= 0) {
        //     return true;
        // }
        return false;
    }

    const selectAllChannels = (value) => {
        setCheckedList(value ? OTC_LIST_PROMO : []);
        setIndeterminateCha(false);
        setCheckAllCha(value);
        let channel_selected = []
        try {
            OTC_LIST_PROMO.map((channel, i) => {
                if (OTC_PROMO[channel].code) {
                    channel_selected.push(OTC_PROMO[channel].code);
                }
            });
        } catch (error) {

        }
        setParams({ ...params, channels: value ? channel_selected : [] });
    }


    const setSelectedChannels = (list) => {
        setIndeterminateCha(!!list.length && list.length < OTC_LIST_PROMO.length);
        setCheckAllCha(list && list.length === OTC_LIST_PROMO.length);
        let channel_selected = []
        try {
            list.map((cha, i) => {
                if (OTC_NAME_PROMO[cha].name) {
                    channel_selected.push(OTC_NAME_PROMO[cha].name)
                }
                setCheckedList(channel_selected);
            });
        } catch (error) {

        }
    }

    const arrangeParams = () => {
        if (mode === 'create') {
            setParams({
            code: '',
            segments: ['ME_', 'GIG'],
            discount_type: 'FI',
            percentage_discount: 0,
            fixed_discount: 0,
            description: '',
            channels: OTC_LIST_PROMO,
            start_date: '',
            end_date: '',
            id: ''
            })
            selectAllChannels(true);
        } else {
            setParams({
                code: data.code,
                segments: data.segments,
                discount_type: data.discount_type,
                percentage_discount: data.percentage_discount,
                fixed_discount: data.fixed_discount,
                description: data.description,
                channels: data.channels,
                start_date: moment(data.date_start).format('MM-DD-YYYY'),
                end_date: moment(data.date_expiry).format('MM-DD-YYYY'),
                id: data.id,
            })
            setCheckedList([]);
            if (data && data.channels && data.channels !== []) {
                setSelectedChannels(data.channels);
            }
        }
    }

    React.useEffect(() => {
        resetFields();

        if (mode === 'edit') {
            // setDateRange({
            //     startDate: data.date_start,
            //     endDate: data.date_expiry
            // })
        }
    }, [props.visible])

    return (
        <Modal
            maskClosable
            onCancel={() => props.onClose() / resetFields()}
            visible={props.visible}
            footer={false}
            closeIcon={<Icon type="close" style={{ color: '#F5922F' }} />}
            width={450}
            destroyOnClose
            centered
        >
            <Typography style={styles.title}>{mode === 'create' ? 'Create new' : 'Edit promo'}</Typography>
            <div style={{ padding: '8px 0' }}>
                <label style={styles.labelFont}>Promo code</label>
                <Input
                    style={styles.input}
                    name='code'
                    value={params['code']}
                    placeholder="Promo code"
                    onChange={handleChange}
                    disabled={isLoading}
                    maxLength={8}
                />
            </div>
            <div style={styles.drowpDownDiv}>
                <div style={styles.selectDiv}>
                    <label style={styles.labelFont}>Segment type</label>
                    {/* <Popover getPopupContainer={triggerNode => triggerNode.parentNode} disabled content={renderSegments()} trigger='click' placement='bottom'>
                        <Button size='large' style={styles.popOverBtn} >{params && params.segments ? params.segments.toString(", ") : 'Select Segment'}<ExpandMoreIcon style={{ color: '#2B2D33', fontSize: 32 }} /></Button>
                    </Popover> */}
                    <Button size='large' disabled style={styles.popOverBtn} >{params && params.segments ? params.segments.toString(", ") : 'Select Segment'}<ExpandMoreIcon style={{ color: '#2B2D33', fontSize: 32 }} /></Button>
                </div>
                <div style={styles.selectDiv}>
                    <label style={styles.labelFont}>Discount type</label>
                    <Select disabled size='large' value={params['discount_type']} onChange={(e) => handleChange(e, 'discount_type')} style={{ width: '100%', margin: '4px 0px 12px 0px', }}>
                        <Select.Option name='discount_type' value={'PE'}>Percentage</Select.Option>
                        <Select.Option name='discount_type' value={'FI'}>Fixed</Select.Option>
                        <Select.Option name='discount_type' value={'CO'} hidden>Combo</Select.Option>
                    </Select>
                </div>
            </div>
            <div style={styles.drowpDownDiv} >
                <div style={styles.selectDiv}>
                    <label style={styles.labelFont}>Channels</label>
                    {/* <Popover overlayStyle={{ maxHeight: 432, overflow: 'auto' }} disabled getPopupContainer={triggerNode => triggerNode.parentNode} content={renderChannels()} trigger='click' placement='bottomLeft'>
                        <Button size='large' style={styles.popOverBtn}>{params && params.channels ? 'Check All' : 'Select Channel'}<ExpandMoreIcon style={{ color: '#2B2D33', fontSize: 32 }} /></Button>
                    </Popover> */}
                    <Button disabled size='large' style={styles.popOverBtn}>{params && params.channels ? 'Check All' : 'Select Channel'}<ExpandMoreIcon style={{ color: '#2B2D33', fontSize: 32 }} /></Button>
                </div>
                <div style={styles.selectDiv}>
                    <label style={styles.labelFont}>
                        {params && params.discount_type === 'PE' ? 'Discount percentage' : 'Discount amount'}
                    </label>
                    <Input
                        style={styles.input}
                        name={params && params['discount_type'] === 'PE' ? 'percentage_discount' : 'fixed_discount'}
                        value={params && params['discount_type'] === 'PE' ? params['percentage_discount'] : params['fixed_discount']}
                        placeholder={params && params.discount_type === 'PE' ? 'Discount percentage' : 'Discount amount'}
                        onChange={handleChange}
                        disabled={isLoading}
                        addonBefore="₱"
                        size='large'
                    />
                </div>
            </div>
            <div style={styles.datepickerDiv}>
                <div style={styles.selectDiv}>
                    <label style={styles.labelFont}>Promo start</label>
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={datePickerHelperFunctions.disabledStartDates}
                        // defaultValue={moment()}
                        value={params.start_date && moment(params.start_date)}
                        size="large"
                        picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'start_date')}
                        placeholder='Start Date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                </div>
                <div style={styles.selectDiv}>
                    <label style={styles.labelFont}>Promo end</label>
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={datePickerHelperFunctions.disabledEndDates}
                        // defaultValue={moment()}
                        value={params.end_date && moment(params.end_date)}
                        size="large"
                        picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'end_date')}
                        placeholder='End Date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                </div>
            </div>
            <div style={{ padding: '8px 0' }}>
                <label style={styles.labelFont}>Description (optional)</label>
                <TextArea
                    rows={3}
                    placeholder='Description'
                    name='description'
                    onChange={handleChange}
                    value={params['description']}
                    style={{ margin: '4px 0 12px 0' }}
                />
            </div>
            <div style={styles.footerBtn}>
                <button className={`btn--${segments}`} style={styles.cancelBtn} onClick={() => props.onClose() / resetFields()} >Cancel</button>
                <button className={`btn--${segments}`} style={{ ...styles.saveBtn, opacity: hasBlanks() ? '0.5' : '1' }}
                    disabled={isLoading || hasBlanks()} onClick={validateFields} >
                    Save
                </button>
            </div>
        </Modal>
    )
}

const styles = {
    title: {
        color: '#2B2D32',
        fontSize: 28,
        fontWeight: '700',
        textAlign: 'left',
        padding: '16px 0'
    },
    msg: {
        color: '#2B2D33',
        fontSize: 16,
        textAlign: 'left',
        padding: '12px 0'
    },
    btn: {
        height: 48,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 4,
        width: '100%',
    },
    input: {
        // padding: '11px 16px 11px 16px',
        fontSize: 16,
        height: 40,
        margin: '4px 0px 12px 0px',
        color: '#2B2D33'
    },
    labelFont: {
        fontSize: 14,
        color: '#212B36'
    },
    mobilePrefix: {
        position: 'absolute',
        top: `58%`,
        left: '10%',
        transform: 'translate(-50%, -50%)',
        fontSize: 16,
        color: '#2B2D33',
        fontWeight: 'normal',
        zIndex: '99',
    },
    or: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#909196',
        alignSelf: 'center',
        padding: '0 12px'
    },
    divider: {
        padding: '0 21px'
    },
    dloadBtn: {
        fontSize: 16,
        fontWeight: '600',
        padding: '8px, 16px, 8px, 16px',
        border: '1px solid #F5922F',
        height: 40,
        marginRight: 12,
        color: '#F5922F',
        backgroundColor: '#FFF',
        width: '100%'
    },
    drowpDownDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '8px 0'
    },
    selectDiv: {
        width: '48%',
        display: 'flex',
        flexDirection: 'column'
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: '100%',
        margin: '4px 0px 12px 0px',
    },
    datepickerDiv: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '8px 0'

    },
    saveBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: 86,
        cursor: 'pointer',
        backgroundColor: '#0A315E',
        color: '#FFF',
        border: 0
    },
    cancelBtn: {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: '1px solid #0A315E',
        borderRadius: 4,
        height: 40,
        fontSize: 16,
        fontWeight: '500',
        color: '#0A315E',
        padding: '8px 16px',
        width: 'auto',
        marginRight: 8
    },
    footerBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '12px 0'
    },
    popOverBtn: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '4px 0px 12px'
    }
}

export default PromoModal;