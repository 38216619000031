import React from 'react'
import {Input, Button, Modal, Select, DatePicker, Icon, message, Table} from 'antd'
import moment from 'moment'
import axios from 'axios'
import { Handle401 } from '../../handle401/handle401'
import { history } from '../../../store/history'
import NoteIcon from '../../../static/icons/note.svg'

const ScheduleModal = props => {

    const segmentsColor = {
        'me_':{
            color: '#0DAED0',
            border: '4px solid #0DAED0'
        },
        'gig':{
            color: '#41BD5C',
            border: '4px solid #41BD5C'
        },
        'sme':{
            color: '#E3632A',
            border: '4px solid #E3632A'
        },
        'biz':{
            color: '#0051B0',
            border: '4px solid #0051B0'
        }
    }
    
    const { TextArea } = Input;
    const {Option} = Select;
    const {visibility, hideModal, fetchData} = props
    const userType = localStorage.getItem("userType")
    const segments = localStorage.getItem("segments")


    const [startDate, setStartDate] = React.useState("")
    const [startMoment, setStartMoment] = React.useState("")

    const [amount, setAmount] = React.useState(0)
    const [description, setDescription] = React.useState("")
    const [fullName, setFullname] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [contact, setContact] = React.useState("")
    const [cycle, setCycle] = React.useState("DA")
    const [totalNumberRequest, setTotalNumberRequest] = React.useState("1")
    const [listOfDates, setListOfDates] = React.useState([])
    const [showTable, setShowTable] = React.useState(false)
    const [showAlert, setShowAlert] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState("")
    const [specificDay, setSpecificDay] = React.useState("")

    const [nextSched, setNextSched] = React.useState("")
    const testMode = history.location.pathname.indexOf('/test') > -1;


    const disabledStartDate = startValue => {
        if (!startValue) {
          return false;
        }
        return startValue && startValue < moment().startOf('day');
    }

    const onStartChange = value => {

        if(value != null){
            setNextSched("")
            setStartDate(value.format("MM-DD-YY"))
            setStartMoment(value)
            setAllDates(value.clone())
        }
        
    }

    function settingAmount(val) {
        let splitted_val = val.split(".")

        if(val.length <= 8 && val <= 30000 && (userType == 'ME' || userType == 'SW')) {
            
            if(splitted_val.length == 1 || (splitted_val.length == 2 && splitted_val[1].length <= 2)){
                setAmount(val)
            }
        }    
        if(val.length <= 8 && val <= 50000 && userType == 'CO'){
            if(splitted_val.length == 1 || (splitted_val.length == 2 && splitted_val[1].length <= 2)){
                setAmount(val)
            }
        }
    }

    function settingCycle(val){
        setCycle(val)
        setStartMoment(null)
        setStartDate(null)  
    }

    function settingSpecificDay(val) {
        if(val <= 31){
            setStartMoment(null)
            setStartDate(null)  
            setSpecificDay(val)
        }
        if(val != ""){
           setAllDates(startMoment)
        }
       
    }

    function setAllDates(startMoment) {
        let allDates = [];
        let latestMoment = startMoment

        allDates.push(
            {
                no: '1st',
                date: latestMoment.clone().format('MMMM DD, YYYY')
            }
        )

        for(let i = 1; i<totalNumberRequest; i++){
            if(cycle == 'DA'){
                latestMoment = latestMoment.clone().add(1, 'days')
                allDates.push(
                    {
                        no: ordinal_suffix_of(i + 1),
                        date: latestMoment.format('MMMM DD, YYYY')
                    }
                )
            }
            else if(cycle == 'WE'){
                latestMoment = latestMoment.clone().add(7, 'days')
                allDates.push(
                    {
                        no: ordinal_suffix_of(i + 1),
                        date: latestMoment.format('MMMM DD, YYYY')
                    }
                )


            }
            else if(cycle == 'HM'){
                latestMoment = latestMoment.clone().add(15, 'days')
                allDates.push(
                    {
                        no: ordinal_suffix_of(i + 1),
                        date: latestMoment.format('MMMM DD, YYYY')
                    }
                )
            }
            else if(cycle == 'MO'){
                latestMoment = latestMoment.clone().add(30, 'days')
                allDates.push(
                    {
                        no: ordinal_suffix_of(i + 1),
                        date: latestMoment.format('MMMM DD, YYYY')
                    }
                )

            }
            else{
                if(latestMoment){
                    if(parseInt(latestMoment.format('DD')) < parseInt(specificDay) && !(parseInt(latestMoment.format('MM')) == 2 && (latestMoment.format('DD') == 29 || latestMoment.format('DD') == 28))){
                        let newDate = new Date(parseInt(latestMoment.format('YYYY')), parseInt(latestMoment.format('MM')) - 1, parseInt(specificDay))
                        latestMoment = moment(newDate)
                        allDates.push(
                            {
                                no: ordinal_suffix_of(i + 1),
                                date: latestMoment.format('MMMM DD, YYYY')
                            }
                        )
                    }
                    else{
                        if(parseInt(latestMoment.format('MM')) == 12){
                            let newDate = new Date(parseInt(latestMoment.format('YYYY')) + 1, 0, parseInt(specificDay))
                            latestMoment = moment(newDate)
                            allDates.push(
                                {
                                    no: ordinal_suffix_of(i + 1),
                                    date: latestMoment.format('MMMM DD, YYYY')
                                }
                            )
                            
                        }
                        else if(parseInt(latestMoment.format('MM')) == 1 && (specificDay == 30 || specificDay == 31)){
                            let newDate;
                            if(leapYear(latestMoment.year)){
                                newDate = new Date(parseInt(latestMoment.format('YYYY')) + 1, 1, 29)
                            }
                            else{
                                newDate = new Date(parseInt(latestMoment.format('YYYY')) + 1, 1, 28)
                            }
                            latestMoment = moment(newDate)
                            allDates.push(
                                {
                                    no: ordinal_suffix_of(i + 1),
                                    date: latestMoment.format('MMMM DD, YYYY')
                                }
                            )
                        }
                        else if(parseInt(latestMoment.format('MM')) == 2 && (specificDay == 30 || specificDay == 31 || (!leapYear(latestMoment.year) && specificDay == 29))){
                            let newDate = new Date(parseInt(latestMoment.format('YYYY')), 2, parseInt(specificDay))
                            latestMoment = moment(newDate)
                            allDates.push(
                                {
                                    no: ordinal_suffix_of(i + 1),
                                    date: latestMoment.format('MMMM DD, YYYY')
                                }
                            )
                        }
                        else{
                            let newDate = new Date(parseInt(latestMoment.format('YYYY')), parseInt(latestMoment.format('MM')), parseInt(specificDay))
                            latestMoment = moment(newDate)
                            allDates.push(
                                {
                                    no: ordinal_suffix_of(i + 1),
                                    date: latestMoment.format('MMMM DD, YYYY')
                                }
                            )
                        }
                    }
                }
            }
        }
        console.log(allDates)
        setListOfDates(allDates)
    }

    function leapYear(year)
    {
        return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
    }


    function validateDetails(){
        if(amount == 0){
            message.error("Please fill up amount")
        }
        else if(description == ""){
            message.error("Please fill up description")
        }
        else if(fullName == ""){
            message.error("Please fill up buyer name")
        }
        else if(email == ""){
            message.error("Please fill up buyer email")
        }
        else if(contact == ""){
            message.error("Please fill up buyer contact number")
        }
        else if(startDate == ""){
            message.error("Please choose a start date")
        }
        else if(description.length > 200){
            message.error("Maximum of 200 characters in description")
        }
        else if(cycle == 'SP' && specificDay == ""){
            message.error("Please fill up specific day")
        }
        else{
            scheduleNow()
        }

    }
    async function scheduleNow(){

        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let selectedCycle = cycle
        
        if(selectedCycle == 'SP'){
            selectedCycle = 'MO'
        }

        let params = {
            amount: amount,
            description: description,
            buyer_phone: contact,
            buyer_email: email,
            buyer_name: fullName,
            trigger_date: startDate,
            cycle: selectedCycle,
            specific_day: specificDay,
            number_of_request: totalNumberRequest
        }

        if(totalNumberRequest > 12 || totalNumberRequest < 1){
            setErrorMsg("Minimum of 1 and Maximum of 12 total number of request")
            setShowAlert(true)
            window.scrollTo(0, 0);
        }
        if(amount < 50){
            setErrorMsg("Amount must be Php 50 above")
            setShowAlert(true)
            window.scrollTo(0, 0);
        }

        else{
            try{
                let response = null
                if(!testMode){
                    response = await axios.post(
                        process.env.REACT_APP_API_URL + "/api/orders/recurring/",
                        params,yourConfig
                    );
                }
                else{
                    response = await axios.post(
                        process.env.REACT_APP_API_URL + "/api/sandbox/schedule_payment/",
                        params,yourConfig
                    );
                }
                
        
                if (response.data.status == "success"){
                   message.success("Successfully Added")
                   hideModal()
                   fetchData()
                }
            }
            
            catch(error){
                if((error.response)&&(error.response.status == 401)){
                    Handle401()
                }
            }
        }
     
    }

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    const columns = [
        {
            title: 'No. of Request',
            dataIndex: 'no',
            render: text => {
              return text
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
        
    ];

    return(
        <Modal
            style={{top: 20}}
            closeIcon={<Icon type="close" style={{color: `${segmentsColor[segments].color}`}}/>}
            width="495px"
            footer={null}
            onCancel={hideModal} 
            visible={visibility}>
            
            <div style={style.header}>Schedule Payment Request</div>
        
            <div hidden={showTable}>
                <div style={{...style.alert, marginTop: '20px'}} hidden={!showAlert}>
                    {errorMsg}
                </div>
                <div style={{marginTop: '20px'}}>
                    <div style={style.label}>Amount</div>
                    <Input style={{width: '100%'}} value={amount} size="large" addonBefore="₱" onChange={(e)=>settingAmount(e.target.value)}/>
                </div>
                <div style={{marginTop: '20px'}}>
                    <div style={style.label}>Description</div>
                    <TextArea  value={description} rows={3} onChange={(e)=>setDescription(e.target.value)}/>
                </div>
                <div style={{marginTop: '20px'}}>
                    <div style={style.label}>Full name</div>
                    <Input style={{width: '100%'}} value={fullName} size="large" onChange={(e)=>setFullname(e.target.value)}/>
                </div>
                <div style={{marginTop: '20px', display: 'flex'}}>
                    <div style={{width: '49%', marginRight: '2%'}}>
                        <div style={style.label}>Email address</div>
                        <Input style={{width: '100%'}} size="large" onChange={(e)=>setEmail(e.target.value)}/>
                    </div>
                    <div style={{width: '49%'}}>
                        <div style={style.label}>Mobile number</div>
                        <Input style={{width: '100%'}} value={contact} size="large" addonBefore="+63" maxLength="10" onChange={(e)=>setContact(e.target.value)}/>
                    </div>
                </div>
                <div style={{marginTop: '20px', display: 'flex'}}>
                    <div style={{width: '49%', marginRight: '2%'}}>
                        <div style={style.label}>Frequency</div>
                        <Select
                            value={cycle}
                            size="large"
                            onChange={(e)=>settingCycle(e)}
                            style={{width: '100%', zIndex: 1, color: '#000'}}
                            >
                            <Option value="DA">Daily</Option>
                            <Option value="WE" >Every 7 days</Option>
                            <Option value="HM" >Every 15 days</Option>
                            <Option value="MO">Every 30 days</Option>
                            <Option value="SP">Specific day of Month</Option>
                        </Select>
                    </div>
                    <div style={{width: '49%'}}>
                        <div style={style.label}>Total no. of requests to send</div>
                        <Input style={{width: '100%'}} value={totalNumberRequest} size="large" onChange={(e)=>{setTotalNumberRequest(e.target.value); startMoment && setAllDates(startMoment)}}/>
                    </div>
                </div>
                <div style={{marginTop: '20px', display: cycle == 'SP' && 'flex'}}>
                    <div style={{width: '49%'}} hidden={cycle != 'SP'}>
                        <div style={style.label}>Specific Day from 1 - 31</div>
                        <Input style={{width: '100%'}} value={specificDay} size="large" maxLength="2" onChange={(e)=>settingSpecificDay(e.target.value)}/>
                    </div>
                    <div style={{width: cycle != 'SP' ? '100%' : '49%', marginLeft: cycle == 'SP' && '2%'}}>
                        <div style={style.label}>Start Date</div>
                        <DatePicker
                            format='MM/DD/YYYY'
                            disabledDate={disabledStartDate}
                            value={startMoment}
                            size="large" 
                            picker="month"
                            onChange={onStartChange}
                            placeholder='Start date'
                            style={{...style.datePic, width: '100%'}}
                            suffixIcon={<div style={style.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                            />
                    </div>
                </div>
                <div style={{...style.nextSchedDiv, marginTop: '16px', display: 'flex'}} hidden={listOfDates.length < 2}>
                    <img src={NoteIcon} alt="!" style={{marginRight: '10px'}}/>
                    <div>
                        <span style={style.note}>The next payment request will be sent on 
                        {
                            listOfDates.length > 1 ? " " + listOfDates[1].date + ". " : " "
                        }
                        </span>
                        <a onClick={()=>setShowTable(true)} style={{...style.note, fontWeight: 'bold', textDecoration: 'underline'}}>
                            Check full schedule
                        </a>
                    </div>
                </div>
                <div style={{margin: '16px 0px 16px 0px'}}>
                    <button className={`btn--${segments}`} style={style.addBtn} onClick={()=>validateDetails()}> Add schedule</button>
                </div>
            </div>
            <div hidden={!showTable}>
                <div style={{display: 'flex', margin: '20px 0px 20px 0px'}}>
                    <Icon type="arrow-left" 
                        style={{color: `${segmentsColor[segments].color}`, fontSize: '20px', height: '25px', marginRight: '10px'}} 
                        onClick={()=>setShowTable(false)}
                    />
                    <div style={{...style.back, color: `${segmentsColor[segments].color}`}}>Back</div>
                </div>
                <Table
                    columns={columns}
                    dataSource={listOfDates}/>
            </div>
        </Modal>
    )

}

const style ={
    header: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#2b2d33',
        lineHeight: '28px'
    },
    label: {
        fontSize: '12px',
        fontWeight: 'normal',
        color: 'rgba(43, 45, 50, 0.8)',
        lineHeight: '16px'
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },

    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
    },

    note:{
        fontSize: '14px',
        fontWeight: 'normal',
        color: 'rgba(43, 45, 50, 0.8)',
        lineHeight: '16px'
    },
    addBtn: {
        width: '100%',
        height: '40px',
        borderRadius: '4px',
        color: '#fff',
        fontWeight: '500',
        fontSize: '16px'
    },
    importBtn: {
        border: '1px solid #F5922F',
        borderRadius: '4px',
        height: '40px',
        width: '100%',
        fontWeight: '500',
        fontSize: '16px',
        color: '#F5922F'
    },
    nextSchedDiv: {
        backgroundColor: 'rgba(119, 178, 242, 0.16)',
        border: '1px solid #77B2F2',
        borderRadius: '2px',
        padding: '8px 16px 8px 16px'
    },
    back: {
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
    },
    alert:{ 
        borderLeft: '5px solid red',
        fontSize: '12px',
        borderRadius: '4px',
        background: 'rgba(226, 76, 76, 0.1)',
        padding: '9px 22px 9px 22px',
        marginBottom: '20px',
    },
}
export default ScheduleModal