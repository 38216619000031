import React from 'react'
import ExpiredLinkImg from '../../static/img/expired_link.svg'
import logo from '../../static/icons/logo.svg';
import {Layout} from 'antd'

const ExpiredLink = props => {

    const {Header} = Layout
    return(
        <div>
            <Header style={{height: '60px', display: 'flex', justifyContent: 'center', backgroundColor: '#fff', boxShadow: '0px 3px 5px rgba(57,63,72,0.1)'}}>
                <img src={logo} style={{marginBottom: '20px', marginTop: '12px'}} alt="#" />
            </Header>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '10%'}}>
                <img src={ExpiredLinkImg}></img>
                 
            </div>
        </div>
    )
}

export default ExpiredLink