import React from 'react'
import axios from 'axios';
import {Select, Input, Icon, Table, Pagination, Button, Modal, Alert, Dropdown, Menu, message, Switch} from 'antd'
import { makeStyles} from '@material-ui/core';
import { useEffect } from 'react'
import {useDispatch} from 'react-redux'
import {history} from '../../../store/history'
import '../../../static/css/antd.css'
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { Handle401 } from '../../handle401/handle401';
import { randomize } from '../../constants/constants';
import ClearFilter from '../filter_btns/clear_filter_btn';

const KycView = props => {
  
    const ButtonGroup = Button.Group;
    const [searchValue, setSearchValue] = React.useState("");
    const [submissions, setSubmissions] = React.useState([]);
    const [statusSelected, setStatusSelected] = React.useState("Resubmitted");
    const [page, setPage] = React.useState(1);
    const [ready, setReady] = React.useState(false);
    const [isFiltered, setIsFiltered] = React.useState(false)
    const [segmentSelected, setSegmentSelected] = React.useState("All")

    const segments = localStorage.getItem('segments')


    const status = [
      'Resubmitted', 'Submitted', 'Approved', 'Rejected'
    ]

    
    useEffect(() => {

      if(localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") == null){
        history.push('/login')
      }
      else{
        if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
          history.push('/dashboard')
        }
        else if(localStorage.getItem("userType") == "PS"){
          history.push('/orders')
        }
        else if(localStorage.getItem("userType") == "PF"){
          history.push('/recon')
        }
        else if(localStorage.getItem("userType") == "MA" || localStorage.getItem("userType") == "KB"){
          history.push('/overview')
        }
        else{
          fetchSubmissions(1, "Resubmitted", "", "All")
        }
        
      }
      
    }, [])


    let menu = (id) => (
        <Menu style={{ width: '180px' }}>

          <Menu.Item key="0" onClick={() => window.open(`/submissions/`+id+`/`,'_blank')}>
            View
          </Menu.Item>
        </Menu>
      );

    const columns = [
        {
          key: 'user',
          title: 'User',
          render: (text, record) => {
            return (<div>{record.email}<br/>{record.full_name}</div>)
          }
        },
        {
          key: 'duplicate',
          title: 'Duplicate?',
          dataIndex: 'duplicate',
        },
        {
          key: 'date_created',
          title: 'Date submitted',
          dataIndex: 'date_created',
        },
        statusSelected === 'Approved' ?
        {
          key: 'date_approved',
          title: 'Date approved',
          dataIndex: 'date_processed'
        } : {},
        {
          key: 'Status',
          title: 'Status',
          render: (text, record) => {
            return (<div><div>{record.status}</div>{ record.customer_id && (<div>{record.customer_id}</div>)}</div>)
          }
        },
        {
          key: 'Audited',
          title: 'Audited By',
          render: (text, record) => {
            return (<div>{ record.approver ? record.approver : record.status == 'Approved' ? 'EON' : '' }</div>)
          }
        },
        {
          key: 'remarks',
          title: 'Remarks',
          dataIndex: 'remarks'
        },
        {
          key: 'Action',
          title: 'Action',
          render: (text, record) => (
            <div>
            <Dropdown overlay={() => menu(record.id)} placement="bottomCenter" trigger={['click']} >
              <Icon type="ellipsis" />
            </Dropdown>
            </div>
          )
        }
      ];

    const pageSelect = (e) => {
        setPage(e);
        fetchSubmissions(e, statusSelected, searchValue, segmentSelected);

    }

    async function fetchSubmissions (page, status, search, segments) {

      
        let yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }

        try{
          let response = await axios.get(
            process.env.REACT_APP_API_URL + '/api/admin/kyc/?page='+page+"&status="+status+"&page_size=20&search="+search+"&segments="+segments,
            yourConfig
          );
    
          setSubmissions(response.data)
          setReady(true)
          console.log(response.data)
        }

        catch(error){
          if((error.response)&&(error.response.status == 401)){
            Handle401()
          }
        }
    }

    const exportTable = () => {   
      let options = { 
        filename: 'KYC_Level_1_'+moment().format("MM-DD-YYYY_HH:mm:ss")+'_'+statusSelected,
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: true,
        title: 'BUx KYC Level 1',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true
      };
   
      let csvExporter = new ExportToCsv(options);
  
      if(submissions.results.length != 0){
          csvExporter.generateCsv(submissions.results);
      }
    };

    const clearFilter = () => {
      setIsFiltered(false)
      setSearchValue("")
      setSegmentSelected("All")
      fetchSubmissions(1, statusSelected, "", "All");
    }

    const applyBtn = () => {
      fetchSubmissions(1, statusSelected, searchValue, segmentSelected)
    }

    return(
        <div style={{ padding: '0 56px 64px 0'}} hidden={!ready}>
          <div className="screen-title-text">
            KYC Submissions Level 1
          </div>

          <div className="top-16 twoCol">
            <div>
              <ButtonGroup>
              {
                status.map((item) => {
                  return <Button
                    key={item.toLocaleLowerCase()}
                    onClick={() => {setStatusSelected(item); 
                                    setPage(1);
                                    fetchSubmissions(1, item, searchValue, segmentSelected);}}
                    className={`userNameStyle ${item === statusSelected ? `bg-${segments}` : ``}`}
                    style={{
                      height:'40px',
                      borderRadius: item === 'Resubmitted' ? '4px 0px 0px 4px' : item === 'Rejected' ? '0px 4px 4px 0px' : 0,
                      color: statusSelected === item ? '#ffffff' : '#2b2d33',
                      backgroundColor: statusSelected === item ? '#0D3D76' : '#fff',
                    }}>{item}</Button>
                })
              }
              </ButtonGroup>
            </div>

            <div>
              <button
              className="admin-download-btn"
              onClick={()=>exportTable()}>
                <Icon type="download" className="right-4" /> Download CSV
              </button>
            </div>
          </div>

          <div className="table-card-div">
            <ClearFilter hideFilterBtn={!isFiltered} clearFilter={clearFilter} />
              
            <div className="filter-div">
                <Select
                size="large"
                value={segmentSelected}
                placeholder="BUx Segments"
                style={{width:'10vw', marginRight: '12px', zIndex: 1}}
                onChange={(e) => {
                  setSegmentSelected(e);
                  setIsFiltered(true)
                }}
              >
                <Select.Option value="All">All</Select.Option>
                <Select.Option value="ME_">BUxME</Select.Option>
                <Select.Option value="GIG">BUxGIG</Select.Option>
                <Select.Option value="SW">Sub-wallets</Select.Option>
              </Select>
              <Input
                placeholder="Search"
                value={searchValue}
                style={{ width: '200px', height: '40px' }}
                onChange={(e)=>{setSearchValue(e.target.value);setIsFiltered(true)}}
                prefix={<Icon type="search" />}
              />

              <button
                style={{marginLeft: 15}}
                className={`admin-apply-btn ${!isFiltered ? "admin-disabled-btn" : ""}`}
                disabled={!isFiltered}
                onClick={applyBtn}
              >
                Apply
              </button>
            </div>

            <Table
              rowKey={record => record.id}
              rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
              dataSource={submissions.results}
              columns={columns}
              pagination={{hideOnSinglePage: true, pageSize: 20}} />

            <div style={{ width: '100%', textAlign: 'right'}} className="top-20">
              <Pagination 
                size="small"
                total={submissions.total} 
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                onChange={pageSelect} 
                defaultCurrent={1} 
                key={randomize()} 
                pageSize={20} 
                current={page}/>
            </div>          
          </div>
        </div>
    )  
}

export default KycView;