import React, { useState } from "react";
import { Switch, Card, Typography, notification } from "antd";
import { updateAdmin } from "../api/update-admin";

const { Text } = Typography;

export function MechantMultiwalletPrivileges({
  data,
  refetchCashbuxMerchantSettings,
}) {
  const [updatedPrivileges, setUpdatedPrivileges] = useState(data.privileges);

  const handleToggle = async (privilegeName, newValue) => {
    try {
      const response = await updateAdmin(data.id, {
        privileges: {
          [privilegeName]: newValue,
        },
      });

      setUpdatedPrivileges({
        ...updatedPrivileges,
        [privilegeName]: newValue,
      });

      refetchCashbuxMerchantSettings();
      notification.success({
        message: "Success",
        description: response.message,
      });
    } catch (error) {
      console.error("Error updating privileges:", error);
      notification.success({
        message: "Failed",
        description: error.message,
      });
    }
  };

  return (
    <>
      {data &&
        data.privileges.map((privilege) => (
          <Card>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text style={{ fontSize: "16px" }}>
                  {privilege.field_name} is{" "}
                  {privilege.value ? "enabled" : "disabled"}
                </Text>
                <Text type="secondary">
                  When bank transfer is enabled, a separate "Bank Transfer
                  Settings" tab will appear where you can change the rail type
                  or service fees.
                </Text>
              </div>
              <Switch
                checked={privilege.value}
                onChange={(checked) => handleToggle(privilege.name, checked)}
              />
            </div>
          </Card>
        ))}
    </>
  );
}
