import React, {useEffect} from 'react'
import {Button, Table, DatePicker, Input, Icon, Select, Modal} from 'antd'
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination';
import axios from 'axios'
import Warning from '../../../static/icons/warning.svg'
import { ExportToCsv } from 'export-to-csv';

const ResponseLog = () => {

    const ButtonGroup = Button.Group
    const { Option } = Select;

    const [startDate, setStartDate] = React.useState("")
    const [startMoment, setStartMoment] = React.useState(null)
    const [endDate, setEndDate] = React.useState("")
    const [endMoment, setEndMoment] = React.useState(null)
    const [endOpen, setEndOpen] = React.useState(false)
    const [modalVisible, setModalVisible] = React.useState(false)

    const [searchValue, setSearchValue] = React.useState("")
    const [selectedStatus, setSelectedStatus] = React.useState("All")

    const [pageSize, setPageSize] = React.useState(10)
    const [currentPage, setCurrentPage] = React.useState(1)

    const [responseLog, setResponseLog] = React.useState([])
    const [requestData, setRequestData] = React.useState({})
    const [responseData, setResponseData] = React.useState({})
    const [summary, setSummary] = React.useState(null)

    const [isFiltered, setIsFiltered] = React.useState(false)

    const [dataExport, setDataExport] = React.useState([])


    useEffect(()=>{
        fetchResponseLog()
    },[])

    const fetchResponseLog = async(page_size=10, page_number=1, start_date='', end_date='', search="") => {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let response_p = await axios.get(
            process.env.REACT_APP_API_URL + `/api/response_log/?page_size=${page_size}&page_number=${page_number}&start=${start_date}&end=${end_date}&search=${search}`
            ,yourConfig
        );

        if(response_p){

            setResponseLog(response_p.data.response_log)
            setSummary(response_p.data.summary)
            
        }
    }

    const disabledApplyButton = () => {
        return startDate == '' && 
        endDate == '' && 
        searchValue == '' &&
        selectedStatus == 'All'
    }

    const disabledStartDate = startValue => {
        if (!startValue || endMoment) {
          return false;
        }
        return startValue && startValue > moment().endOf('day');
      };
    
    const disabledEndDate = endValue => {
        var self = this
        if (!endValue || !startMoment) {
          return false;
        }
        return endValue.valueOf() <= startMoment.valueOf();
    };

    const onStartChange = value => {
        if(value != null && endDate != ""){
            setStartDate(value.format("MM-DD-YYYY HH:mm:ss"))
            setStartMoment(value)
            setIsFiltered(true)
        }
        else if(value != null){
            setStartDate(value.format("MM-DD-YYYY HH:mm:ss"))
            setStartMoment(value)
            setIsFiltered(true)
        }
        else{
            setStartDate("")
            setStartMoment(null)
        }
    }

    const onEndChange = value => {
        if(value != null && startDate != ""){
            setEndDate(value.format("MM-DD-YYYY HH:mm:ss"))
            setEndMoment(value)
            setIsFiltered(true)
        }
        else{
            setEndDate("")
            setEndMoment(null)
        } 
    }
    const handleStartOpenChange = open => {
        if(!open) {
            setEndOpen(true)
        }
    };
    
    const handleEndOpenChange = open => {
        setEndOpen(open)
    };

    const changePage = (event,page) => {
        setCurrentPage(page)
        fetchResponseLog(pageSize, page, startDate, endDate, searchValue)
    }
    const changePageSize = (size) => {
        setPageSize(size)
        fetchResponseLog(size, 1, startDate, endDate, searchValue)
    }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            width: '25%'
        },
        {
            title: 'Reference ID',
            dataIndex: 'reference_id',
            width: '15%'
        },
        {
            title: 'Request',
            dataIndex: 'request',
            width: '20%'
        },
        
        {
            title: 'Details',
            width: 150,
            render: record => {
              return (
                <a>
                    <div 
                        style={{color: '#F5922F', 
                        fontSize: '16px', 
                        fontWeight: '600'
                    }} 
                    onClick={()=>DetailsModal(record.request_data, record.response_data)}> 
                        View Details
                     </div>
                </a>
              )
              
            }
          },
    ];
    const clearFilter = () => {
        setIsFiltered(false)
        setSearchValue("")
        setCurrentPage(1)
        setSelectedStatus("All")
        setStartMoment(null)
        setStartDate("")
        setEndMoment(null)
        setEndDate("")
        setEndOpen(false)

        fetchResponseLog()
    }

    const applyFilter = () => {
        setIsFiltered(true)
        fetchResponseLog(pageSize, 1, startDate, endDate, searchValue)

    }

    const exportTable = async() => {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let options = { 
            filename: 'Response_Logs_'+moment().format("MM-DD-YYYY_HH:mm:ss"),
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true, 
            showTitle: true,
            title: 'BUx Response Logs',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true
        };
        let csvExporter = new ExportToCsv(options);

        let response_p = await axios.get(
            process.env.REACT_APP_API_URL + `/api/response_log/?page_size=10&page_number=1&start=${startDate}&end=${endDate}&search=${searchValue}&is_export=true`
            ,yourConfig
        );
        if(response_p.data.length > 0){
            let all_data = []
            for(let i=0; i<response_p.data.length; i++){
                all_data.push({
                    'Date': response_p.data[i].date,
                    'Reference ID': response_p.data[i].reference_id,
                    'Request': response_p.data[i].request,
                    'Request Data': JSON.stringify(response_p.data[i].request_data),
                    'Response Data': JSON.stringify(response_p.data[i].response_data),
                })
            }
            
            csvExporter.generateCsv(all_data);

        }
        
    };


    const DetailsModal = (requestData, responseData) => {
        setModalVisible(true)
        setRequestData(requestData)
        setResponseData(responseData)
    }
    return(
        <div style={{ paddingTop: '38px', paddingLeft: '42px', paddingRight: '42px' }}>
            <div style={{display: 'flex', justifyContent:'space-between'}}>
                <div style={style.headerStyle}>Response Log</div>
                <Button
                    style={{
                        backgroundColor: '#1DD28B',
                        border: '#1DD28B',
                        height: '40px',
                    }}
                    onClick={()=>exportTable()}
                    >
                    <Icon type="download" style={{fontSize: '14px', marginRight: '2px', color: '#fff'}}/> 
                    <span style={style.textStyle}>Export CSV</span>
                </Button>
            </div>
            <div style={{backgroundColor: '#fff', padding: 20}}>
                <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '20px'}}>
                    <div style={{display: 'flex'}}>
                        <div style={{color: '#000', fontSize: '16px', fontWeight: 'bold'}}>Filter</div>
                        <a style={style.clearFilterStyle} hidden={!isFiltered} onClick={()=>clearFilter()}>
                            <Icon type="close-circle" theme="filled" style={{fontSize: '10px', marginRight: '2px'}}/> 
                            <span style={{fontSize: '12px', color: '#000'}}>  Clear Filter</span>
                        </a>
                    </div>
                    <div>
                    <img src={Warning} style={{transform: 'rotate(180deg)'}}/> <span style={{color: '#909196', fontSize: '12px'}}>Click <b>Apply</b> button to show results.</span>
                    </div>
                </div>
                <div style={{ marginBottom: '25px', float: 'right'}}>
                    <DatePicker
                        style={{zIndex: 1, width: '150px'}}
                        disabledDate={disabledStartDate}
                        size="large"
                        format='MM-DD-YYYY HH:mm'
                        value={startMoment}
                        placeholder="Start"
                        showTime
                        onChange={onStartChange}
                        onOpenChange={handleStartOpenChange}
                    />
                    <span style={{padding: '0 8px'}}>-</span> 
                    <DatePicker
                        style={{zIndex: 1, marginRight: '12px',width: '150px'}}
                        disabledDate={disabledEndDate}
                        size="large"
                        format='MM-DD-YYYY HH:mm'
                        value={endMoment}
                        placeholder="End"
                        showTime
                        onChange={onEndChange}
                        open={endOpen}
                        onOpenChange={handleEndOpenChange}
                    />
                    <Input
                        placeholder="Search"
                        style={{ width: '200px', height: '40px', zIndex: 1}}
                        onChange={(e)=>{
                            setSearchValue(e.target.value)
                            setIsFiltered(true)
                        }}
                        prefix={<Icon type="search" />}
                    />
                    <Button
                        disabled={disabledApplyButton()}
                        onClick={()=>applyFilter()}
                        style={{
                            ...style.applyBtn, 
                            marginLeft: '10px',
                            opacity:  disabledApplyButton() ? '0.5' : '1',
                            width: '93px',
                            zIndex: 1
                        }}>
                        Apply
                    </Button>
                </div>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                    columns={columns}
                    dataSource={responseLog}
                    pagination={false} 
                    rowKey={(record) => record.id}
                />

                {
                    summary && 
                    <div style={{padding: '20px', display:'flex', justifyContent:'space-between'}} hidden={summary.total_data / 10 < 1}>
                    <div>
                        {(currentPage - 1) * 10  + 1} 
                        - 
                        {(currentPage - 1) * 10  + summary.page_total} out of {summary.total_data}
                    </div>
                    <Pagination 
                        count={summary.pages} 
                        shape="rounded"
                        page={currentPage} 
                        size="small"
                        onChange={changePage}
                    />
                    </div>
                }
                <Modal
                    visible={modalVisible}
                    style={{top: 20}}
                    onCancel={()=>setModalVisible(false)}
                    footer={""}>
                        <div style={{fontSize: '24px', fontWeight: '600', color: '#000'}}>Response Details</div>
                        <div style={{fontSize: '16px', fontWeight:'normal', color: '#2b2d33', marginTop: '30px'}}>Request</div>
                        <div style={style.example}>{JSON.stringify(requestData)}</div>
                        <div style={{fontSize: '16px', fontWeight:'normal', color: '#2b2d33', marginTop: '10px'}}>Response</div>
                        <div style={style.example}>{JSON.stringify(responseData)}</div>

                </Modal>
            </div>
        </div>
    )
}

const style = {
    applyBtn: {
        color: '#fff',
        backgroundColor: '#F5922F',
        height: '40px',
        border: '1px solid #F5922F',
        borderRadius: '4px',
        fontWeight: '600',
        fontSize: '16px'
    },
    clearFilterStyle: {
        padding: '3px 8px 4px 8px',
        backgroundColor: '#E6EAF0',
        borderRadius: '50px',
        height: '29px',
        marginLeft: '10px',
        marginTop: '-5px',
        color: '#000'
    },
    headerStyle: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '40px'
    },
    textStyle: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#FFFFFF'
    },
    example: {
        padding: '16px',
        width: '100%',
        backgroundColor: '#303952',
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: 'Monospace',
        lineHeight: '18px',
        color: '#fff',
        marginTop: '10px'
    },

}

export default ResponseLog