import React, {useEffect} from 'react'
import {Card, Select, Input, Alert, Spin, Icon} from 'antd'
import enterpriseStyle from '../kybStyle'
import { isMobile } from 'react-device-detect'
import {ADDRESS} from '../../../constants/constants'
import {NATURE_BUSINESS, NATIONALITY1, POSITION, backToTop} from '../../../constants/constants'
import {FB, IG} from '../../../../static/icons/index'
import NoteCard from '../noteCard'
import TextInput from '../custom_component/textInput'

// import {SubmitStep1, SubmitStep2} from '../functions/post'

const {Option} = Select

const BusinessInfo = props => {
    
    const {
        segments, datas
    } = props;

    let data = datas
    
    const kybStep = localStorage.getItem('KYBStep')
    const countries = NATIONALITY1
    countries.sort()
    const kyb = enterpriseStyle()
    const note = "All the information should be the same as indicated in your contract."

    const [selected,setSelected] = React.useState("")

    //declaration of business info
    const [buxSegment, setBuxSegments] = React.useState(data && data.segment ? `BUx${data.segment}` : 'BUxSME');
    const [businessType, setBusinessType] = React.useState((
                                            segments === 'biz' && 
                                            (data && data.business_type === 2 && data.segment === 'BIZ')) || 
                                            data ? data.business_type : 1)
    const [businessName, setBusinessName] = React.useState(data ? data.business_name : "")
    const [natureBusiness, setNatureBusiness] = React.useState(data ? data.nature_of_business : 5)

    //declaration of business address
    const [line1, setLine1] = React.useState(data ? data.address_unit : "")
    const [streetAddress, setStreetAddress] = React.useState(data ? data.address_street : "")
    const [subdivision, setSubdivision] = React.useState(data ? data.address_subdivision : "")
    const [cityAddress, setCityAddress] = React.useState(data ? data.address_city : "")
    const [provinceAddress, setProvinceAddress] = React.useState(data ? data.address_province : "Metro Manila")
    const [brgyAddress, setBrgyAddress] = React.useState(data ? data.address_barangay : "")
    const [postalCode, setPostalCode] = React.useState(data ? data.address_zip_code : "")
    const country = "Philippines"
    //declaration of business declaration
    const [officePhone, setOfficePhone] = React.useState(data ? data.phone : "")
    const [faxNo, setFaxNo] = React.useState(data ? data.fax : "")
    const [emailAddress, setEmailAddress] = React.useState(data ? data.email : "")
    const [yearBusiness, setYearBusiness] = React.useState(data ? data.years_in_business : 0)
    const [website, setWebsite] = React.useState(data ? data.website : "")
    const [facebook, setFacebook] = React.useState(data ? data.facebook : "")
    const [instagram, setInstagram] = React.useState(data ? data.instagram : "")
    const [grossValue, setGrossValue] = React.useState(data ? data.collected_sales : 0)
    const [aveTicketSize, setAveTicketSize] = React.useState(data ? data.ave_ticket_size : 0)
    const [projected, setProjected] = React.useState(data ? data.projected_sales : 0)

    const [showError, setShowError] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState("")
    const [isError, setIsError] = React.useState(false)
    const paymentGateway = "Payment Gateway"

    const userRole = localStorage.getItem("userType")
    const subuserRole = localStorage.getItem("subuser_role")

    const adminRole = ['AD', 'SD', 'BS']
    const siteRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

    function get_directors(data) {
        const BLANK_DIRECTORS = [{first_name: "", middle_name: "", last_name: "", nationality: 61}];

        if (data && data.directors && data.directors.length) {
            let preloaded_directors = data.directors.map((director) => {
                return {
                    'first_name': director.first_name,
                    'middle_name': director.middle_name,
                    'last_name': director.last_name,
                    'nationality': director.nationality
                }
            });
            return preloaded_directors;
        }
        return BLANK_DIRECTORS;
    }

    function get_stockholders(data) {
        const BLANK_STOCKHOLDERS = [{first_name: "", middle_name: "", last_name: "", percent_shares: 0, nationality: 61}];

        if (data && data.stockholders && data.stockholders.length) {
            let preloaded_stockholders = data.stockholders.map((stockholder) => {
                return {
                    'first_name': stockholder.first_name,
                    'middle_name': stockholder.middle_name,
                    'last_name': stockholder.last_name,
                    'percent_shares': stockholder.percent_shares,
                    'nationality': stockholder.nationality
                }
            });
            return preloaded_stockholders;
        }
        return BLANK_STOCKHOLDERS;
    }

    function get_officers(data) {
        const BLANK_OFFICERS = [{first_name: "", middle_name: "", last_name: "", position: 0}];

        if (data && data.officers && data.officers.length) {
            let preloaded_officers = data.officers.map((officer) => {
                return {
                    'first_name': officer.first_name,
                    'middle_name': officer.middle_name,
                    'last_name': officer.last_name,
                    'position': officer.position
                }
            });
            return preloaded_officers;
        }
        return BLANK_OFFICERS;
    }

    //list of directors
    const [listDirectors, setListDirectors] = React.useState(get_directors(data));
    const [listStockholders, setListStockholder] = React.useState(get_stockholders(data));
    const [listOfficers, setListOfficers] = React.useState(get_officers(data));
    const [isFormSubmittable, setIsFormSubmittable] = React.useState(false)

    //array const 
    const BUSINESS_TYPE = segments === 'sme' || buxSegment === 'BUxSME' ?  ['Sole Proprietor', 'Corporation', 'Partnership'] : ['Partnership', 'Corporation']
    const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;


    //select function

    // useEffect(() => {
    //     handleBusinessType(0)
    // }, [])

    function handleSegment(value) {
        setBuxSegments(value)
        setBusinessType(1)
    }

    function handleBusinessType(value){
        if((segments === 'biz' || buxSegment === 'BUxBIZ') && value === 0) {
            setBusinessType(2)
        }

        else {
            setBusinessType(value)
        }
    }

    function handleNatureBusiness(value){
        setNatureBusiness(value)
    }

    function handleStateAddress(value) {
        setProvinceAddress(value)
        setCityAddress("")
    }


    function handleCityAddress(e) {
        setCityAddress(e)
        setBrgyAddress("")
    }
    function handleBrgyAddress(e) {
        setBrgyAddress(e)
    }


    const handleChangeInputs = (e, index, type) => {
        const { name, value } = e.target
        if(type == 0){
            const list = [...listDirectors]
            list[index][name] = value
            setListDirectors(list)
        }

        else if(type == 1){
            const list = [...listStockholders]
        list[index][name] = value
            setListStockholder(list)
        }

        else if(type == 2){
            const list = [...listOfficers]
            list[index][name] = value
            setListOfficers(list)
        }

    }

    //handle select
    function handleNationality(value, key, type) {
        // console.log(value, key, type)
        if(type == 0){
            const list = [...listDirectors]
            list[key]['nationality'] = value  
            setListDirectors(list)
        }
        else if(type == 1){
            const list = [...listStockholders]
            list[key]['nationality'] = value  
            setListStockholder(list)
        }
        else if(type == 2){
            const list = [...listOfficers]
            list[key]['position'] = value  
            setListOfficers(list)
        }
    }

    //mapping for arrays
    function getProvinces() {
        let provinces = []
        for(var i in ADDRESS){
            provinces.push(<Option key={i} value={i}>{i}</Option>);
        }
        return provinces
    }

    function getCities() {
        let cities = []
        if(provinceAddress in ADDRESS){
            for(var i in ADDRESS[provinceAddress]['Municipality_list']){
                cities.push(<Option key={i} value={i}>{i}</Option>);
            }
        }
        return cities
    }

    function getBarangay() {
        let baranggays = []
        if(provinceAddress in ADDRESS){
            if(cityAddress in ADDRESS[provinceAddress]['Municipality_list']){
                let baranggay_list = ADDRESS[provinceAddress]['Municipality_list'][cityAddress]['Barangay_list'];
                for(var i=0;i<baranggay_list.length;i++){
                    baranggays.push(<Option key={i} value={baranggay_list[i]}>{baranggay_list[i]}</Option>);
                }
            }
        }
        return baranggays
    }

    //action button function

    const handleRemoveClick = (type, index) => {

        if(type === 1){
            const list = [...listDirectors]
            list.splice(index, 1);
            setListDirectors(list);
        }

        else if(type === 2){
            const list = [...listStockholders]
            list.splice(index, 1);
            setListStockholder(list);
        }

        else if(type === 3){
            const list = [...listOfficers]
            list.splice(index, 1);
            setListOfficers(list);
        }
   
      };

    const addDirector = (type) => {
        if(type === 1){
            setListDirectors([...listDirectors, { first_name: "", middle_name: "", last_name: "", nationality: 61 }])
        }
        else if (type === 2){
            setListStockholder([...listStockholders, { first_name: "", middle_name: "", last_name: "", percent_shares: 0, nationality: 61 }]);
        }
        else if (type === 3){
            setListOfficers([...listOfficers, { first_name: "", middle_name: "", last_name: "", position: 0 }]);
        }
      };

    function errorBox(errorMsg){
        backToTop()
        setShowError(false);
        setErrorMessage(errorMsg)
        setIsError(true)
        setTimeout(() => {
            setShowError(true);
        }, 6000);
        return
    }

    function formSubmit(){

        let params = {
            //business ifno
            business_type: businessType,
            business_name: businessName,
            nature_of_business: natureBusiness,
            //business address
            address_unit: line1,
            address_street: streetAddress,
            address_subdivision: subdivision,
            address_city: cityAddress,
            address_barangay: brgyAddress,
            address_province: provinceAddress,
            address_zip_code: postalCode,
            country,
            //business declaration
            phone: officePhone,
            fax: faxNo,
            email: emailAddress,
            years_in_business: yearBusiness,
            website,
            facebook,
            instagram,
            collected_sales:grossValue,
            ave_ticket_size: aveTicketSize,
            projected_sales: projected,
            paymentGateway,
            directors: listDirectors,
            stockholders: listStockholders,
            officers: listOfficers,
            step_to_update: 1
            
        }

        if(adminRole.includes(userRole)) {
            if(buxSegment === 'BUxSME') {
                params['bux_segment'] = "SME";
            }

            else if(buxSegment === 'BUxBIZ') {
                params['bux_segment'] = "BIZ";
                
            }
        }
        // props.updateStep(1)

        console.log(params)
        props.submitStep(params, 1)
    }

    //submit 
    function submitStep1(){
        
        if(!businessName){
            errorBox("Business Name is required")
        }

        else if(!line1){
            errorBox("Line 1 Address is required")
        }

        else if(!streetAddress){
            errorBox("Street Address is required")
        }

        else if(!subdivision){
            errorBox("Subdivision Address is required")
        }

        else if(!provinceAddress){
            errorBox("Please select Province Address")
        }

        else if(!cityAddress){
            errorBox("Please select City Address")
        }

        else if(!brgyAddress){
            errorBox("Please select Barangay Address")
        }

        else if(!postalCode){
            errorBox("Postal Code is required")
        }

        else if(!officePhone){
            errorBox("Office Phone is required")
        }

        else if(yearBusiness <= 0){
            errorBox("Years in Business must be more than 0")
        }

        else if(website !== "" && website !== null && isWebSocmedValid(website)) {
            errorBox("Website format is invalid");
        }
        else if(facebook !== "" && facebook !== null && isWebSocmedValid(facebook, 'FB')) {
            errorBox("Facebook format is invalid");
        }

        else if(businessType >= 1){
            let totalPercentshares = 0;

            // if(businessType == 2) {
            //     for(let director of listDirectors){
            //         if(!director.first_name || !director.last_name || director.nationality < 0) {
            //             errorBox("Please complete Partners List")
            //             return;
            //         }
            //     }
            // }

            // else {
            //     for(let director of listDirectors){
            //         if(!director.first_name || !director.last_name || director.nationality < 0) {
            //             errorBox("Please complete Directors List")
            //             return;
            //         }
            //     }

            //     for(let stockholder of listStockholders){
            //         if(!stockholder.first_name || !stockholder.last_name || stockholder.percent_shares <= 0 || stockholder.nationality < 0){
            //             errorBox("Please complete Stockholders List")
            //             return;
            //         }
                    
            //         else {
            //             totalPercentshares += parseFloat(stockholder.percent_shares)
            //         }
            //     }
    
            //     if (parseFloat(totalPercentshares).toFixed(2) < parseFloat(100).toFixed(2) || parseFloat(totalPercentshares).toFixed(2) > parseFloat(100).toFixed(2)) {
            //         errorBox(`Percent Shares must be equal to 100 not ${parseFloat(totalPercentshares).toFixed(2)}`)
            //         return;
            //     }
    
    
        
            //     for(let officer of listOfficers){
            //         if(!officer.first_name || !officer.last_name || officer.position < 0){
            //             errorBox("Please complete Officers List")
            //             return;
            //         }
            //     }
            // }


            if (businessType === 1) {
                for(let officer of listOfficers){
                    if(!officer.first_name || !officer.last_name || officer.position < 0){
                        errorBox("Please complete Officers List")
                        return;
                    }
                }
            }
            
  
            formSubmit()
        }

        else {
            formSubmit()
        }
        
    }

    //edit button
    function editBusinessInfo(val){
        props.openEdit(val)
    }

    //socmed and website validation
    function isWebSocmedValid(val, type){
        if(type === 'FB') {
            if(val && (val.search(/https?:\/\/(www\.)?facebook\.com\/[A-z0-9\.]+/) !== -1)) {
                return false;
            }
            return true;
        }else if(type === 'IG') {
            if(val && (val.search(/https?:\/\/(www\.)?instagram\.com\/[A-z0-9\.]+/) !== -1)) {
                return false;
            }
            return true;
        } else
            if(val && (val.search(siteRegex) !== -1)) {
                return false;
            }
            return true;
    }

    return(
        <div>
        <Card 
        className={kyb.formCardStyle}
        bodyStyle={{padding: '20px 16px 20px 16px'}}>
            <div className={`bottom-32 twoCol`}>
                <div className={kyb.headerStyle}>Business Information</div>
                <div>
                    <a href className={`text-${segments} medium-font-weight`} onClick={() => editBusinessInfo(1)}
                     hidden={
                        ((userRole == "CO" || (userRole == 'SW'  && subuserRole == 'CO')) && kybStep == 0) || 
                        (localStorage.getItem("KYBType") == "creation" && data ? data.step < 7 : '' || 
                        localStorage.getItem("enterpriseId") == 0 )
                    }
                        >Cancel
                    </a>
                </div>
            </div>

            <NoteCard note={note} />

            {props.alertMessage}


            <div hidden={showError} className="top-20">
                <Alert type="error" message={errorMessage} style={{borderLeft: '4px solid red'}}/>
            </div>

            {/* Business Info */}
            <div className="top-20" hidden={!adminRole.includes(userRole)}>
                <div className={`${kyb.miniText} liveColor bottom-4`}>Choose Segment</div>
                <Select
                    showSearch
                    style={{ width: '49%' }}
                    value={buxSegment}
                    optionFilterProp="children"
                    onChange={handleSegment}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                    <Option value={"BUxSME"}>BUxSME</Option>
                    <Option value={"BUxBIZ"}>BUxBIZ</Option>
                </Select>
            </div>
            <div className={kyb.displayResponsive}>
                <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={`${kyb.miniText} liveColor bottom-4`}>Business Type</div>
                    <Select
                        data-test="business_type"
                        showSearch
                        style={{ width: '100%' }}
                        value={BUSINESS_TYPE[(segments === 'biz' || buxSegment === 'BUxBIZ') && businessType === 2 ? 0 : businessType]}
                        optionFilterProp="children"
                        onChange={handleBusinessType}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                       }
                    >
                        {
                            BUSINESS_TYPE.map((item, i) => {
                                return (
                                    <Option value={i}>{item}</Option>
                                )
                            })
                        }
                    </Select>
                    
                </div>
                <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={kyb.miniText}>Business Name</div>
                    {/* <Input maxLength={64} style={{height: '40px'}} placeholder="Business Name" value={businessName} onChange={(e)=>setBusinessName(e.target.value)}/> */}
                    <TextInput data-test="business_name" maxLength={100} placeholder={"Business Name"} name={"business_name"} 
                        value={businessName} 
                        changeValue={setBusinessName} 
                        isError={!businessName ? isError : false}
                        />

                </div>
            </div>

            <div style={{marginTop: 20}}>
                <div className={kyb.miniText}>Nature of Business</div>
                    <Select
                        data-test="business_nature"
                        showSearch
                        style={{ width: '98%' }}
                        optionFilterProp="children"
                        value={NATURE_BUSINESS[natureBusiness]}
                        onChange={handleNatureBusiness}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                                NATURE_BUSINESS.length > 0 && NATURE_BUSINESS.map((item, i) => {
                                return (
                                <Option key={i} value={i}>{item}</Option>
                                )
                            }, this)
                        }
                    </Select>

            </div>

            {/* Business Address */}

            <div style={{margin: '30px 0px 10px 0px'}}>
                <div className={kyb.headerStrong}>
                    Address
                </div>
            </div>

            <div className={kyb.displayResponsive}>
                <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={kyb.miniText}>Floor No./Room No./Bldg. No.</div>
                    {/* <Input maxLength={16} style={{height: '40px'}} placeholder="Floor No./Room No./Bldg. No." value={line1} onChange={(e)=>setLine1(e.target.value)}/> */}
                    <TextInput data-test="floor" maxLength={16} placeholder={"Floor No./Room No./Bldg. No."} name={"floor"} 
                        value={line1} 
                        changeValue={setLine1}
                        isError={!line1 ? isError : false}
                        />

                    
                </div>
                <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={kyb.miniText}>Street Name</div>
                    {/* <Input maxLength={64} style={{height: '40px'}} placeholder="Street Name" value={streetAddress} onChange={(e)=>setStreetAddress(e.target.value)}/> */}
                    <TextInput data-test="street" maxLength={64} placeholder={"Street Name"} name={"street_name"} 
                        value={streetAddress} 
                        changeValue={setStreetAddress}
                        isError={!streetAddress ? isError : false}
                        />

                </div>
            </div>

            <div className={kyb.displayResponsive}>
                <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={kyb.miniText}>Subdivision</div>
                    {/* <Input maxLength={32} style={{height: '40px'}} placeholder="Subdivision" value={subdivision} onChange={(e)=>setSubdivision(e.target.value)}/> */}
                    <TextInput data-test="subdivision" maxLength={32} placeholder={"Subdivision"} name={"subdiivision"} 
                        value={subdivision} 
                        changeValue={setSubdivision}
                        isError={!subdivision ? isError : false}
                        />

                    
                </div>
                <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={kyb.miniText}>Province</div>
                    <Select
                        data-test="province"
                        showSearch
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        value={provinceAddress}
                        onChange={handleStateAddress}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        
                        {getProvinces()}
                    </Select>
                </div>
            </div>

            <div className={kyb.displayResponsive}>
                <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={kyb.miniText}>City</div>
                    <Select
                        data-test="city"
                        disabled={!provinceAddress}
                        showSearch
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        value={cityAddress}
                        onChange={handleCityAddress}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        
                        {getCities()}
                    </Select>
                </div>
                <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={kyb.miniText}>Barangay</div>
                    <Select
                        data-test="barangay"
                        showSearch
                        disabled={!cityAddress}
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        value={brgyAddress}
                        onChange={handleBrgyAddress}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        
                        {getBarangay()}
                    </Select>
                </div>
            </div>

            <div className={kyb.displayResponsive}>
                <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={kyb.miniText}>Country</div>
                    <input className={kyb.inputReadOnly} style={{zIndex: '0'}} disabled value={country} />                        
                    
     
                </div>
                <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={kyb.miniText}>Zip Code</div>
                    {/* <Input type="number" size="large" placeholder="Postal Code" value={postalCode} onChange={e => { if(e.target.value.length <= 4){setPostalCode(e.target.value)}}}/> */}
                    <TextInput data-test="zip" type={"number"} placeholder={"Postal Code"} name={"postal"} 
                        value={postalCode} 
                        changeValue={setPostalCode}
                        isError={!postalCode ? isError : false}
                        />
      
                </div>
            </div>

            {/* Business Declaration */}

            <div style={{margin: '30px 0px 10px 0px'}}>
                <div className={kyb.headerStrong}>
                    Business Declaration
                </div>
            </div>

            <div className={kyb.displayResponsive}>
                <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={kyb.miniText}>Office Phone No.</div>
                    {/* <Input addonBefore="+63" size="large" type="number" placeholder="Office Phone No." value={officePhone} onChange={e => { if(e.target.value.length <= 10){setOfficePhone(e.target.value)}}}/> */}
                    <TextInput data-test="office_phone" addBefore="+63" maxLength={16} placeholder={"Office Phone No."} name={"office_phone"} 
                        value={officePhone} 
                        changeValue={setOfficePhone}
                        isError={!officePhone ? isError : false}
                        />
                </div>
                <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={kyb.miniText}>Email Address</div>
                    <div hidden={userRole != "CO" && userRole != 'SW'}>
                        <input className={kyb.inputReadOnly} style={{zIndex: '0'}} size="large" disabled value={emailAddress} />
                    </div>
                    <div hidden={userRole == "CO" || userRole == 'SW'}>
                        <TextInput placeholder={"Email Address"} name={"email"} value={emailAddress} changeValue={setEmailAddress} 
                        isError={!emailAddress ? isError : false}
                        />
                    </div>

                </div>
            </div>

            <div className={kyb.displayResponsive}>
                <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                    <div className={kyb.miniText}>Months In Business</div>
                    <TextInput data-test="yob" type={"number"} placeholder={"Year in Business"} name={"yob"} 
                        value={yearBusiness}        
                        changeValue={setYearBusiness}
                        isError={!yearBusiness ? isError : false}
                        />

                </div>
            </div>

            {/* if corporate */}

            <div hidden={businessType == 0}>
                {/* list of directors */}
                <div hidden>
                    <div style={{margin: '30px 0px 10px 0px'}}>
                        <div className={kyb.headerStrong}>
                            {businessType == 2 ? 'List of Partners' : 'List of Directors'}
                        </div>
                    </div>
                    
                    {
                        listDirectors.map((item, key) => {
                            return(
                                <div key={key}>

                                    <div style={{margin: '30px 0px 10px 0px', display: 'flex', justifyContent: 'space-between'}}>
                                        <div className={kyb.headerStrong}>
                                             {businessType == 2 ? `Partner ${key+1}` : `Director ${key+1}`}
                                        </div>
                                        <div hidden={key == 0} className="right-8">
                                            <div>
                                            {listDirectors.length !== 1 && <a href
                                            className={`text-${segments} wc-header`}
                                            onClick={() => handleRemoveClick(1, key)}>Remove</a>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={kyb.displayResponsive}>
                                        <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                                            <div className={kyb.miniText}>First Name</div>
                                            <TextInput data-test="fname_director" maxLength={30} placeholder={"First name"} name={"first_name"} 
                                                value={item.first_name} handlechangeSig={handleChangeInputs} step={1} index={key} vipType={0}
                                                isError={!item.first_name ? isError : false}
                                                />

                                            {/* <Input maxLength={30} size="large" name="first_name" placeholder="First Name" value={item.first_name} onChange={e => handleChangeInputs(e, key, 0)}/> */}
                                            
                                        </div>
                    
                                        <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                                            <div className={kyb.miniText}>Middle Name (Optional)</div>
                                            <Input data-test="mname_director" maxLength={30} name="middle_name" placeholder="Middle Name" value={item.middle_name} onChange={e => handleChangeInputs(e, key, 0)}/>
                                            
                                        </div>
                    
                                        <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                                            <div className={kyb.miniText}>Last Name</div>
                                            {/* <Input maxLength={30} size="large" name="last_name" placeholder="Last Name" value={item.last_name} onChange={e => handleChangeInputs(e, key, 0)}/> */}
                                            <TextInput data-test="lname_director" maxLength={30} placeholder={"Last name"} name={"last_name"} 
                                                value={item.last_name} handlechangeSig={handleChangeInputs} step={1} index={key} vipType={0}
                                                isError={!item.last_name ? isError : false}
                                                />
                    
                                        </div>
                                    </div>
                    
                                    <div className="top-20">
                                        <div className={kyb.miniText}>Nationality</div>
                                        <Select
                                                data-test="nationality_director"
                                                showSearch
                                                style={{ width: '98%' }}
                                                optionFilterProp="children"
                                                value={countries[item.nationality]}
                                                onChange={e => handleNationality(e, key, 0)}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                        countries.length > 0 && countries.map((item, i) => {
                                                        return (
                                                        <Option key={i} value={i}>{item}</Option>
                                                        )
                                                    }, this)
                                                }
                                        </Select>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="top-20">
                        <button className={`outline-btn--${segments} ${isMobile ? "btn-mobile-width" : "btn-web-width"} btn-align-left btn-height`} onClick={() => addDirector(1)}>
                            <Icon type="plus" className={`text-${segments} right-8`} />{businessType == 2 ? 'Add new partner' : 'Add new director'}
                        </button>       
                    </div>
                </div>

                {/* list of stockholders */}
                <div hidden={businessType == 2 || true}>
                    <div style={{margin: '30px 0px 10px 0px'}}>
                        <div className={kyb.headerStrong}>
                            List of Stockholders
                        </div>
                    </div>
                    
                    {
                        listStockholders.map((item, key) => {
                            return(
                                <div key={key}>

                                    <div style={{margin: '30px 0px 10px 0px', display: 'flex', justifyContent: 'space-between'}}>
                                        <div className={kyb.headerStrong}>
                                            Stockholder {key+1}
                                        </div>
                                        <div hidden={key == 0}>
                                            <div>
                                            {listStockholders.length !== 1 && <a href
                                            className={`text-${segments} wc-header`}
                                            onClick={() => handleRemoveClick(2, key)}>Remove</a>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={kyb.displayResponsive}>
                                        <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                                            <div className={kyb.miniText}>First Name</div>
                                            {/* <Input maxLength={30} size="large" name="first_name" placeholder="First Name" value={item.first_name} onChange={e => handleChangeInputs(e, key, 1)}/> */}
                                            <TextInput data-test="fname_stock" maxLength={30} placeholder={"First name"} name={"first_name"} 
                                                value={item.first_name} handlechangeSig={handleChangeInputs} step={1} index={key} vipType={1}
                                                isError={!item.first_name ? isError : false}
                                                />
                                            
                                        </div>
                    
                                        <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                                            <div className={kyb.miniText}>Middle Name (Optional)</div>
                                            <Input data-test="mname_stock" maxLength={30} name="middle_name" placeholder="Middle Name" value={item.middle_name} onChange={e => handleChangeInputs(e, key, 1)}/>
                                            
                                        </div>
                    
                                        <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                                            <div className={kyb.miniText}>Last Name</div>
                                            {/* <Input maxLength={30} size="large" name="last_name" placeholder="Last Name" value={item.last_name} onChange={e => handleChangeInputs(e, key, 1)}/> */}
                                            <TextInput data-test="lname_stock" maxLength={30} placeholder={"Last name"} name={"last_name"} 
                                                value={item.last_name} handlechangeSig={handleChangeInputs} step={1} index={key} vipType={1}
                                                isError={!item.last_name ? isError : false}
                                            />
                    
                                        </div>
                                    </div>
                    
                                    <div className={kyb.displayResponsive}>
                                        <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                                            <div className={kyb.miniText}>% of Shares</div>
                                            <TextInput data-test="shares_stock" type={"number"} placeholder={"Percent Share"} name={"percent_shares"} 
                                                value={item.percent_shares} handlechangeSig={handleChangeInputs} step={1} index={key} vipType={1}
                                                isError={!item.percent_shares ? isError : false}
                                                />
                                            
                                            <div className={kyb.miniText} style={{marginTop: 8, lineHeight: '16px',}}>
                                                Total Stockholders % should be 100%. If Partnership indicate any % value.
                                            </div>
                                        </div>

                                        <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>

                                        <div className={kyb.miniText}>Nationality</div>
                                        <Select
                                                data-test="nationality_stock"
                                                showSearch
                                                style={{ width: '100%' }}
                                                optionFilterProp="children"
                                                value={countries[item.nationality]}
                                                onChange={e => handleNationality(e, key, 1)}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                        countries.length > 0 && countries.map((item, i) => {
                                                        return (
                                                        <Option key={i} value={i}>{item}</Option>
                                                        )
                                                    }, this)
                                                }
                                        </Select>

                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="top-20">
                        <button className={`outline-btn--${segments} ${isMobile ? "btn-mobile-width" : "btn-web-width"} btn-align-left btn-height`} onClick={() => addDirector(2)}>
                            <Icon type="plus" className={`text-${segments} right-8`} /> Add new stockholder
                        </button>
                    </div>
                </div>

                {/* list of officers */}
                <div hidden={businessType==2}>
                    <div style={{margin: '30px 0px 10px 0px'}}>
                        <div className={kyb.headerStrong}>
                            List of Officers
                        </div>
                    </div>
                    
                    {
                        listOfficers.map((item, key) => {
                            return(
                                <div key={key}>
                                    <div style={{margin: '30px 0px 10px 0px', display: 'flex', justifyContent: 'space-between'}}>
                                        <div className={kyb.headerStrong}>
                                            Officer {key+1}
                                        </div>
                                        <div hidden={key == 0}>
                                            <div>
                                            {listOfficers.length !== 1 && <a href
                                            className={`text-${segments} wc-header`}
                                            onClick={() => handleRemoveClick(3, key)}>Remove</a>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={kyb.displayResponsive}>
                                        <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                                            <div className={kyb.miniText}>First Name</div>
                                            <TextInput data-test="fname_officer" maxLength={30} placeholder={"First name"} name={"first_name"} 
                                                value={item.first_name} handlechangeSig={handleChangeInputs} step={1} index={key} vipType={2}
                                                isError={!item.first_name ? isError : false}
                                                />
                                            
                                        </div>
                    
                                        <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                                            <div className={kyb.miniText}>Middle Name (Optional)</div>
                                            <Input data-test="mname_officer" maxLength={30} name="middle_name" placeholder="Middle Name" value={item.middle_name} onChange={e => handleChangeInputs(e, key, 2)}/>
                                            
                                        </div>
                    
                                        <div style={{width: isMobile ? '100%' : '32%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                                            <div className={kyb.miniText}>Last Name</div>
                                            {/* <Input maxLength={30} size="large" name="last_name" placeholder="Last Name" value={item.last_name} onChange={e => handleChangeInputs(e, key, 2)}/> */}
                                            <TextInput data-test="lname_officer" maxLength={30} placeholder={"Last name"} name={"last_name"} 
                                                value={item.last_name} handlechangeSig={handleChangeInputs} step={1} index={key} vipType={2}
                                                isError={!item.last_name ? isError : false}
                                                />
                    
                                        </div>
                                    </div>
                    
                                    <div className="top-20">
                                        <div className={kyb.miniText}>Position/Designation</div>
                                        <Select
                                                data-test="position_officer"
                                                showSearch
                                                style={{ width: '98%'}}
                                                optionFilterProp="children"
                                                value={POSITION[item.position]}
                                                onChange={e => handleNationality(e, key, 2)}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                        POSITION.length > 0 && POSITION.map((item, i) => {
                                                        return (
                                                        <Option key={i} value={i}>{item}</Option>
                                                        )
                                                    }, this)
                                                }
                                        </Select>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="top-20">
                        <button className={`outline-btn--${segments} ${isMobile ? "btn-mobile-width" : "btn-web-width"} btn-align-left btn-height`} onClick={() => addDirector(3)}>
                            <Icon type="plus" className={`text-${segments} right-8`} /> Add new officer
                        </button>
                    </div>
                </div>
            </div>

            {/* website  */}
            <div className="top-20">
                    <div className={kyb.headerStrong}>
                        Website and Social Media Account
                    </div>     

                    <div className="top-20">
                        <div className={kyb.miniText}>Website</div>
                        <Input 
                        className="text-input"
                        data-test="web"
                        maxLength={32} placeholder="https://sample.com" value={website} onChange={(e)=>setWebsite(e.target.value)}/>
                        <label hidden={website === "" || website === null || !isWebSocmedValid(website)} style={{fontSize: 12, color: '#E24C4C'}}>
                            Website format must be https://sample.com
                        </label>
                    </div>

                    <div className="top-20">
                        <div className={kyb.miniText}>Facebook</div>
                        <div className={kyb.miniText} style={{fontSize: 10}}>e.g: https://facebook.com/sample</div>

                        <Input 
                        className="text-input"
                        data-test="fb"
                        maxLength={32}
                        addonBefore={
                                <div> <img src={FB} alt="fb" /> </div>
                            } placeholder="https://facebook.com/sample" 
                            value={facebook} onChange={e => setFacebook(e.target.value)}
                            isError={!facebook ? isError : false}/>
                        <label hidden={facebook === "" || facebook === null || !isWebSocmedValid(facebook, 'FB')} style={{fontSize: 12, color: '#E24C4C'}}>Facebook format must be https://facebook.com/username</label>
                    </div>
                </div>
            
            <div hidden={isMobile} align="right">
                <button data-test="save_btn" className={`btn--${segments} btn-height top-20 right-8`} 
                        style={{
                            opacity:  !props.offSpin ? 0.5 : '', 
                            cursor: !props.offSpin ? 'not-allowed' : 'pointer'
                            // opacity: isFormSubmittable || !showSpin ? '' : 0.5, 
                            // cursor: isFormSubmittable || !showSpin ? 'pointer' : 'not-allowed'
                        }}
                        disabled={!props.offSpin} 
                        onClick={() => submitStep1()}> 
                        <div hidden={props.offSpin}><Spin /></div>
                        <div hidden={!props.offSpin}>
                        {
                            adminRole.includes(userRole) && localStorage.getItem("KYBType") != "creation" ? "Save Changes" : 
                            kybStep >= 6 ? "Save Changes" : 
                            "Submit and next"
                        }
                        </div> 
                </button>
            </div>
        </Card>
               
        <div className={kyb.stickyBtn} hidden={!isMobile}>
            <button data-test="save_btn" className={`btn--${segments} btn-mobile-width`} 
                    style={{
                        opacity:  !props.offSpin ? 0.5 : '', 
                        cursor: !props.offSpin ? 'not-allowed' : 'pointer'
                    }}
                    disabled={!props.offSpin} 
                    onClick={() => submitStep1()}> 
                    <div hidden={props.offSpin}><Spin /></div>
                    <div hidden={!props.offSpin}>
                    {
                        adminRole.includes(userRole) ? "Save Changes" : 
                        kybStep >= 6 ? "Save Changes" : 
                        "Submit and next"
                    }
                    </div> 
            </button>
        </div>
        </div>
    )
}

export default BusinessInfo