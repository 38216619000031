import React from 'react';
import Announcement from '../../../screens/annoucement/announcement';
import GcashImage from '../../../static/images/gcash.svg'
import Announce from '../../../static/icons/annouce.svg'
import { isMobile } from 'react-device-detect';
import moment from 'moment';

const GcashAnnouncement = props => {
    const segments = localStorage.getItem("segments")
    const GCash_date = localStorage.getItem('GCash_date')

    let formatted_date = moment(GCash_date).startOf('minutes').fromNow()

    const GCashContent = () => {
        return(
            <div>
                <div className={`modal-sub-text ${isMobile ? "top-16" : "top-32"}`}>
                        <p>
                        To enhance your experience in BUx, 
                        and to ensure a smooth integration with our channel partner GCash, 
                        please be advised that we will be sharing your business name and contact information to GCash by March 31, 2022.
                        </p>
                    </div>

                    <div className={`modal-sub-text ${isMobile ? "top-16" : "top-32"}`}>
                        <p>
                        Please be assured that your data is processed in accordance with our Privacy Policy and is kept secure and confidential in compliance with the Data Privacy Act of 2012 and other applicable laws, rules, and regulations.
                        </p>
                    </div>

                    <div className={`modal-sub-text ${isMobile ? "top-16" : "top-32"}`}>
                        <span className="right-4">
                            For more information, you may contact us at
                        </span> 
                        <a href="mailto:support@bux.ph" className={`wc-header text-${segments}`}>
                            support@bux.ph
                        </a>
                    </div>

                    <div className={`${isMobile ? "top-16" : "top-32"}`}>
                        <a href="/terms_and_privacy" className={`wc-header text-${segments}`} style={{textDecoration: 'underline'}}>
                            Privacy Policy
                        </a>
                    </div>
            </div>
        )
    }

    return(
        <div>
            <Announcement 
                icon={Announce}
                header={"GCash Announcement"}
                timeline={formatted_date}
                image={GcashImage}
                content={<GCashContent />} />
        </div>
    )
}

export default GcashAnnouncement;