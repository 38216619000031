import React from 'react';
import { Button, Typography } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { isMobile } from 'react-device-detect';

const SettingButtons = (props) => {
    const { status, rootStyle, segments, handleClick, selected, settingName } = props;

    return (
        <div style={{ ...styles.root, ...rootStyle }}>
            <Typography hidden={isMobile} style={styles.title}>{settingName}</Typography>
            <div></div>
            <ButtonGroup style={styles.btnDiv}>
                {
                    status.map((btn, i) => {
                        return (
                            <Button
                                key={btn.hash}
                                value={btn.hash}
                                onClick={handleClick}
                                hidden={btn.hidden}
                                // className={btn === selected ? `bg-${segments}` : `'`}
                                style={{
                                    ...styles.btn,
                                    color: btn.hash === selected ? '#2B2D32' : '#2B2D33',
                                    // opacity: btn.name === selected ? '1' : '0.2',
                                    backgroundColor: btn.hash === selected ? '#E9EEF4' : 'transparent',
                                }}
                            >
                                <img alt="" src={btn.icon} style={{ paddingRight: 12, width: 36, height: 25 }} />
                                {btn.name}
                            </Button>
                        )
                    })
                }
            </ButtonGroup>
        </div>
    )
}

const styles = {
    root: {
        width: '100%',
        padding: '0 20px',
        backgroundColor: isMobile ? '#FFF' : 'transparent'
    },
    title: {
        color: '#2B2D32',
        fontSize: 24,
        fontWeight: 'bold',
        padding: '0 0 12px 16px'
    },
    btn: {
        fontSize: 16,
        fontWeight: '500',
        textAlign: 'left',
        height: 40,
        padding: '0 16px',
        borderRadius: '8px',
        border: 0,
        margin: '6px 0',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    btnDiv: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 12
    }
}

export default SettingButtons;