import { makeStyles } from '@material-ui/core';

const ecomStyles = makeStyles(theme => ({

    preMain: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '60px',
    },
    
    main: {
        marginTop: '60px',
        width: '55%'

    },

    textField: {
        paddingRight: '5px',
    },

    logoDiv:{
        display:'flex',
        justifyContent: 'center',
    },

    cardDiv: {
        marginTop: 52,
        width: '100%',
        border: '1px solid #E6EAF0',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 20px rgba(0,0,0,0.04)',
        borderRadius: '10px',
        paddingLeft: '43px',
        paddingRight: '23px',
        paddingTop: 40,
        paddingBottom: 40 

    },

    cLeft: {
        paddingTop: 80,
    }, 

    cfLeft: {
        paddingTop: 80,
        paddingBottom: 80,
        borderRight: '1px solid #E6EAF0'

    },

    cdLeft: {
        paddingTop: 50,
        justifyContent: 'center',
    }, 
    
    clInfo: {
        marginTop: '31px',
        color: '#2b2d33',
        fontSize: 18,
        lineHeight: '20px',
        fontFamily: 'Inter'
    },

    clInfo1: {
        marginTop: '20px',
        color: '#54575F',
        fontSize: 14,
        lineHeight: '20px',
        fontFamily: 'Inter'
    },

    cld: {
        marginTop: '15px',
    },

    clLogo: {
        width: '14%',
        marginTop: '-8px'
    },

    cRight: {
        paddingTop: 25,
        paddingLeft: 40,
        borderLeft: '1px solid #E6EAF0'
    }, 

    cfRight: {
        paddingTop: 100,
        paddingLeft: 40,
    }, 

    crTop: {
        paddingBottom: '18px',
        borderBottom: '1px solid #D1D5DD',
    },

    cfrTop: {
    },

    crsTop: {
        paddingBottom: '18px',
    },

    crHeader: {
        paddingBottom: '10px',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight:'19px',
        color: '#2b2d33'
    },

    crsHeader: {
        paddingBottom: '10px',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight:'19px',
        color: '#0D3D76'
    },

    crDiscoubt: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
        color: 'gray',
        textDecoration: 'line-through'
    },

    crNow: {
        marginTop: '10px',
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '40px',
        color: '#F5922F',
    },

    crBot: {
        marginTop: '20px',

    },
    
    crbHeader: {
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '26px',
        color: '#2b2d33',
    },

    crbText: {
        marginTop: '8px',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#54575F',

    },

    crbfText: {
        marginTop: '18px',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#54575F',

    },

    buttonDiv: {
        backgroundColor: '#fafafa',
        paddingLeft: '34px',
        paddingRight: '34px',
        paddingTop: '21px',
        paddingBottom: '21px',
        display: 'flex',
        justifyContent:'center',
    },

    signUp: {
        width: '300px',
        height: '53px',
        fontSize: '20px',
        lineHeight: '24px',
        backgroundColor: '#1d82b8',
        borderRadius: '4px',
        border: '1px solid #fafafa',
        color: '#fff',

    },
 
    submit: {
        marginTop: '20px',
        color: '#fff',
        fontFamily: 'Inter',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
    
    },

    butSubmit: {
        width: '100%',
        height: '48px',
        border: '1px solid #1D82B8',
        backgroundColor: '#1D82B8',
        borderRadius: '4px',
    },

    


}))

export default ecomStyles;