import { Spin, DatePicker, Select, message } from 'antd';
import React from 'react';
import { TxnGraph, Contributor} from '../../../../../static/icons/dashboard_icons';
import moment from 'moment';
import overviewServices from '../../overviewServices';

const Option = Select.Option;

const DownloadAnalyticsCard = (props) => {
  const { title, key, isLoading } = props

  const isMac = window.screen.width * window.devicePixelRatio === 1440 && window.screen.height * window.devicePixelRatio === 900;
  const [startMoment, setStartMoment] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endMoment, setEndMoment] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [endOpen, setEndOpen] = React.useState(false);
  const [summary, setSummary] = React.useState('Daily');
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [txnCount, setTxnCount] = React.useState(0);

  const segment = localStorage.getItem('segments')

  const disabledStartDate = startValue => {
    if (!startValue && !endMoment) {
      return false;
    }
    return startValue && startValue > moment().endOf('day');
  };

  const disabledEndDate = endValue => {
    return endValue.valueOf() <= startMoment.valueOf();
  };

  const onStartChange = value => {
    if (value != null && endDate != "") {
      setStartMoment(value)
      setStartDate(value.format("MM-DD-YYYY"))
    }
    else if (value != null) {
      setStartMoment(value)
      setStartDate(value.format("MM-DD-YYYY"))
    }
    else {
      setStartMoment(null)
      setStartDate("")
    }
  }

  const onEndChange = value => {
    if (value != null && startDate != "") {
      setEndMoment(value)
      setEndDate(value.format("MM-DD-YYYY"))
    }
    else if (value != null) {
      setEndMoment(value)
      setEndDate(value.format("MM-DD-YYYY"))
    }
    else {
      setEndMoment(null)
      setEndDate("")
    }
  }

  const handleStartOpenChange = open => {
    if (!open) {
      setEndOpen(true)
    }
  };

  const handleEndOpenChange = open => {
    setEndOpen(open)
  };

  const handleFilter = () => {
  }

  const handleTxnCountChange = (e) => {
    setTxnCount(e);
  }

  const handleSummaryChange = (e) => {
    setSummary(e);
  }

  function exportToCSV(filename, rows) {
    const array = [Object.keys(rows[0])].concat(rows)

    let csvFile = array.map(it => {
      return Object.values(it).toString()
    }).join('\n')

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  const downloadData = async () => {
    try {
      setLoading(true);
      const res = await overviewServices.getAnalyticsDownload(title, startDate, endDate, summary, txnCount);
      const parsed_data = JSON.parse(res.data)
      if (parsed_data.length) {
        let filename = `${moment().format('YYYY-MM-DD')} ${title} Report`
        exportToCSV(filename, parsed_data)
      } else {
        message.error("No data fetched to download.")
      }

    } catch (error) {
      message.error("Something went wrong.")
    }
    setLoading(false);
  }

  return (
    <div className="card-style" style={{ width: isMac ? '43vw' : '43vw' }}>
      <div className="card-title">
        <img src={title === "Transaction Volume" ? TxnGraph : Contributor} alt="#" />
        <div style={{ margin: '4px 0px 0px 10px' }}>{title}</div>
      </div>
      <div>
        <div className="twoCol padding-top-16" style={{ alignItems: 'center' }}>
          <DatePicker
            style={{ zIndex: 1, width: '50%' }}
            disabledDate={disabledStartDate}
            size="large"
            format='MM-DD-YYYY'
            value={startMoment || null}
            placeholder="Start"
            onChange={onStartChange}
            onOpenChange={handleStartOpenChange}
          />
          <span style={{ padding: '0 8px' }}>-</span>
          <DatePicker
            style={{ zIndex: 1, width: '50%' }}
            disabledDate={disabledEndDate}
            size="large"
            format='MM-DD-YYYY'
            value={endMoment || null}
            placeholder="End"
            onChange={onEndChange}
            open={endOpen}
            onOpenChange={handleEndOpenChange}
          />
        </div>
        {
          title === "Transaction Volume" ?
            <div className="twoCol padding-top-16">
              <Select
                size="large"
                placeholder="Select filter"
                style={{ width: '100%', zIndex: 1 }}
                onChange={handleSummaryChange}
              >
                <Option value="Daily">Daily</Option>
                <Option value="Weekly">Weekly</Option>
                <Option value="Monthly">Monthly</Option>
              </Select>
            </div> :
            <div className="twoCol padding-top-16">
              <Select
                size="large"
                placeholder="Transaction Count"
                style={{ width: '50%', zIndex: 1 }}
                onChange={handleTxnCountChange}
              >
                <Option value={0}>&gt;= 0</Option>
                <Option value={100}>&gt;= 100</Option>
                <Option value={1000}>&gt;= 1000</Option>
                <Option value={10000}>&gt;= 10000</Option>
              </Select>
              <span style={{ padding: '0 10px' }}></span>
              <Select
                size="large"
                placeholder="Select filter"
                style={{ width: '50%', zIndex: 1 }}
                onChange={handleSummaryChange}
              >
                <Option value="Daily">Daily</Option>
                <Option value="Weekly">Weekly</Option>
                <Option value="Monthly">Monthly</Option>
              </Select>
            </div>
        }

        <div className="twoCol padding-top-16">
          <button
            className={`btn--${segment}`}
            style={{ width: '100%', height: '40px' }}
            onClick={downloadData}
            disabled={loading}>
            {loading ? <Spin size="small" /> : <span>Download CSV</span>}
          </button>
        </div>
      </div>
    </div>
  )
}

export default DownloadAnalyticsCard;