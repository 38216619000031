import React, { useRef } from 'react'
import {Modal, message} from 'antd'
import { isMobile } from 'react-device-detect'
import { TwitterShareButton, EmailShareButton, FacebookMessengerShareButton } from 'react-share'

//Img
import Confetti from '../../../../static/img/confetti.png'

//Icons
import FBIcon from '../../../../static/icons/voucher_drawer/fb.svg'
import IGIcon from '../../../../static/icons/voucher_drawer/ig.svg'
import TwitterIcon from '../../../../static/icons/voucher_drawer/twitter.svg'
import EmailIcon from '../../../../static/icons/voucher_drawer/email.svg'

const ShareModal = props => {

    const {detailsData, modalVisiblity, setModalVisibility} = props

    const segments = localStorage.getItem("segments")
    const promoRef = useRef();


    const segmentsColor = {
        'me_':{
            color: '#0DAED0',
        },
        'gig':{
            color: '#41BD5C',
        },
    }

    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
    const FB_APP_ID = process.env.REACT_APP_FB_ID
    const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL

    const link = `${REACT_APP_BASE_URL}${'/voucher/'}${detailsData.code}/${encodeURIComponent(detailsData.description)}/${encodeURIComponent(detailsData.banner_blob)}`

    const fbDisplay = () => {
        return(
            <a href={detailsData.facebook_post}>
                <img style={{marginRight: '12px', height: '40px'}} src={FBIcon} alt="#"/>
            </a>
        )
    }

    const igDisplay = () => {
        return (
            <a href={detailsData.instagram_post}>
                <img style={{marginRight: '12px', height: '40px'}} src={IGIcon} alt="#"/>
            </a>);
    }

    const twitterDisplay = () => {

        return(
            <a href={detailsData.twitter_post}>
                <img style={{marginRight: '12px', height: '40px'}} src={TwitterIcon} alt="#"/>
            </a>
        )
    }

    const emailDisplay = () => {

        let emailSubject = `BUx Voucher | ${detailsData.code}`

        return(
            <EmailShareButton subject={emailSubject} body={detailsData.facebook_post}>
                <img style={{marginRight: '12px', height: '40px'}} src={EmailIcon} alt="#"/>
            </EmailShareButton>
        )
    }

    return(
        <Modal
            width="376px"
            visible={modalVisiblity}
            closable={false}
            footer={null}
            bodyStyle={{
                padding: '70px 60px 32px 60px', 
                backgroundImage: `url(${Confetti})`, 
                backgroundRepeat: 'no-repeat'
            }}>
            
            <div style={{display: 'flex', justifyContent: 'center'}} ref={promoRef}>
                <div style={style.promoDiv}>
                    <div className={`voucher-amount-div voucher-amount-text`} 
                        style={{height: '108px', 
                                backgroundColor: detailsData.banner_blob == "" && segmentsColor[segments].color, 
                                backgroundImage: detailsData.banner_blob != "" && `url(${detailsData.banner_blob})`, 
                                backgroundSize: 'cover', 
                                backgroundPosition: 'center'
                        }}>
                        {
                            detailsData.discount_type == "FI" ? 
                            
                            <div style={style.discount}>
                                ₱ {detailsData.fixed_discount} OFF
                            </div>
                            :
                            <div style={style.discount}>
                                {detailsData.percentage_discount}% OFF
                            </div>

                        }
                    </div>
                    <div style={{padding: '16px'}}>
                        <div style={{...style.promoDesc, color: '#41BD5C'}}>{detailsData.description}</div>
                        <div style={{...style.promoDesc, color: '#2b2d32'}}>{detailsData.code}</div>
                    </div>
                </div>
            </div>
        
            <div style={style.share}
                hidden={detailsData && ((!detailsData.facebook_post || detailsData.facebook_post == "") && 
                (!detailsData.instagram_post || detailsData.instagram_post == "") &&
                (!detailsData.twitter_post || detailsData.twitter_post == ""))}>Share this voucher to your customers!</div>
            
            <div style={{padding: '20px 0px 20px 0px', display: 'flex', justifyContent: 'center'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div hidden={!detailsData.facebook_post || detailsData.facebook_post == ""}>
                        {fbDisplay()}
                    </div>
                    <div hidden={!detailsData.instagram_post || detailsData.instagram_post == ""}>
                        {igDisplay()}
                    </div>
                    <div hidden={!detailsData.twitter_post || detailsData.twitter_post == ""}>
                        {twitterDisplay()}
                    </div>
                    {/* <div hidden={!detailsData.facebook_post || detailsData.facebook_post == ""}>
                        {emailDisplay()}
                    </div> */}
                </div>
            </div>

            <div onClick={()=>setModalVisibility(false)} style={{fontSize: '16px', color: '#2b2d32', textAlign: 'center', cursor: 'pointer'}}>
                Cancel
            </div>

        </Modal>
    )
}

const style = {

    promoDiv: {
        width: '244px',
        borderRadius: '12px',
        overflow: 'hidden',
        boxShadow: '0px 8px 24px rgba(0, 35, 11, 0.12)'
    },

    discount: {
        fontWeight: '700',
        fontSize: '32px',
        color: '#FFF',
        display: 'flex',
        justifyContent: 'center'
    },

    promoDesc: {
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center'
    },

    share: {
        fontWeight: '700',
        fontSize: '24px',
        color: '#254A76',
        textAlign: 'center',
        marginTop: '16px'
    }

}

export default ShareModal