import { makeStyles } from '@material-ui/core';

const questionnaireStyle = makeStyles(theme => ({

    modalMainBody:{
        
    },

    headerLogo:{
        position: 'relative',
        left: '-24px',
        top: '-24px',
        zIndex: '1'
    },

    steps:{
        position: 'relative',
        right: '100',
        zIndex: '2',
        background: '#0D3D76',
        color: '#fff',
        width: 100,
        height: '42px',
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 600,
        textAlign:'center',
        marginRight: '-24px',
        paddingTop: '8px',
        borderRadius: '70px 0px 0px 70px'
    },

    header:{
        color: '#0D3D76',
        fontWeight: 'bold',
        marginTop: '16px',
        fontSize: '32px',
        lineHeight: '32px'
    },

    question:{
        marginTop: 16,
        color: '#2B2D33',
        fontSize: '24px',
        lineHeight: '32px',
        padding: '0px 16px 0px 16px'
    },

    selectedAns: {
        width: '100%',
        height: '60px',
        border: '2px solid #F5922F',
        background: '#fff',
        borderRadius: 10,
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'left',
        color: '#2B2D33',
        outline: 0,
        paddingLeft: 20,
        [theme.breakpoints.down("sm")]: {
            height: '100%',
            padding: 16
        }
    },
    
    notSelectedAns:{
        background: '#fff',
        borderRadius: 10,
        border: '1px solid #f1f1f1',
        width: '100%',
        height: '60px',
        color: '#2B2D33',
        fontSize: '16px',
        lineHeight: '24px',
        paddingLeft: 20,
        textAlign: 'left',
        '&:hover': {
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
            cursor: 'pointer'
        },
        [theme.breakpoints.down("sm")]: {
            height: '100%',
            padding: 16
        }
    },

    selectedAns1: {
        width: '200px',
        height: '60px',
        border: '2px solid #F5922F',
        background: '#fff',
        borderRadius: '10px',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'left',
        color: '#2B2D33',
        outline: 0,
        paddingLeft: 20,
        [theme.breakpoints.down("sm")]: {
            height: '100%',
            padding: 16
        }
    },
    
    notSelectedAns1:{
        background: '#fff',
        borderRadius: '10px',
        border: '1px solid #f1f1f1',
        width: '200px',
        height: '60px',
        color: '#2B2D33',
        fontSize: '16px',
        lineHeight: '24px',
        paddingLeft: 20,
        textAlign: 'left',
        '&:hover': {
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
            cursor: 'pointer'
        },
        [theme.breakpoints.down("sm")]: {
            height: '100%',
            padding: 16
        }
    },

    nextBtn:{
        background: '#F5922F',
        borderRadius: '10px',
        color: '#fff',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        width: '100%',
        outline: 0,
        height: '48px',
        marginTop: '20px',
        cursor: 'pointer',
        border: '1px solid #F5922F'
    },

    twoCol: {
        display: 'flex',
        marginTop: '20px',
        justifyContent: 'space-between',
        [theme.breakpoints.down("sm")]: {
            display: 'block',
            justifyContent: 'center',

        }
    },

    prevBtn: {
        border: '1px solid #F5922F',
        height: '48px',
        background: '#fff',
        outline: 0,
        borderRadius: '10px',
        color: '#F5922F',
        cursor: 'pointer',
        fontWeight: 600,
        width: 200,
        fontSize: '16px',
        lineHeight: '24px',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        }
    },

    nextBtn1:{
        background: '#F5922F',
        borderRadius: '10px',
        color: '#fff',
        outline: 0,
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        height: '48px',
        width: 200,
        cursor: 'pointer',
        border: '1px solid #F5922F',
        [theme.breakpoints.down("sm")]: {
            marginTop: 16,
            width: '100%',


        }
    }
}))

export default questionnaireStyle;