import React from 'react';
import * as Icon from '../../../static/icons/how_it_works/index';
import * as Img from '../../../static/images/how_it_works/index';
import ContentCard from '../content_card';
import { Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import VideoModal from '../reusable_content/video_modal';

const GenerateLink = props => {
    
    const {
        segments, step
    } = props;


    let generate_icon = segments == 'me_' ? Icon.Generate_me_ : 
                                segments == 'gig' ? Icon.Generate_gig : 
                                    segments == 'sme' ? Icon.Generate_sme : 
                                        segments == 'biz' ? Icon.Generate_biz: ""

    let play_icon = segments == 'me_' ? Icon.Play_me_ : 
                                        segments == 'gig' ? Icon.Play_gig : 
                                            segments == 'sme' ? Icon.Play_sme : 
                                                segments == 'biz' ? Icon.Play_biz : ""
    
    const [modalState, setModalState] = React.useState(false)
    const baseURL = "https://developers.bux.ph";

    const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
    const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
    const isDell1 = window.screen.width * window.devicePixelRatio === 1366 && window.screen.height * window.devicePixelRatio === 768;

    const smallReso = isSmallReso || isDell || isDell1
                                        
    const GenerateContent = () => {
        const generateStep = [
            {
                step: 1,
                instruction: <div>On your Dashboard, Click <b>Generate link</b>.</div>
            },
            {
                step: 2,
                instruction: <div>Input the necessary information <b>(amount, description and customer's information)</b>.</div>
            },
            {
                step: 3,
                instruction: <div><b>Share your link!</b> via Facebook Messenger, email or your favorite chat app.</div>
            },
        ]
        return(
            <div className="top-20" align="left">
                {
                    !isMobile ?
                    <div className="top-48 flex-center">
                        <div>
                            <button className="play-video" onClick={() => setModalState(true)}>
                                <img src={play_icon} alt="play" />
                            </button>
                        </div>

                        <div>
                            <img src={Img.Generate} alt="generate" />
                        </div>
                    </div>

                    :

                    <button className="play-video" onClick={() => setModalState(true)}>
                        <img src={play_icon} alt="play" />
                    </button>
                }       

                <div className="wc-header top-20">
                    How to generate payment link?
                </div>
    
                
                <VideoModal 
                    source={baseURL + '/generate_link.mp4'} 
                    openModal={modalState} 
                    setModalState={setModalState}
                    segments={segments}
                    smallReso={smallReso} />

                {
                    generateStep.map((item, key) => {
                        return(
                            <Grid container className="top-20">
                                <Grid item xl={1} lg={2} md={3} sm={3} xs={3}>
                                    <div className={
                                        `step-div`
                                    }>
                                        Step {item.step}
                                    </div>
                                </Grid>
            
                                <Grid item xl={11} lg={10} md={9} sm={9} xs={9}>
                                    <div className={!isMobile && "top-12"} style={{color: "#000000"}}>
                                        {item.instruction}
                                    </div>
                                </Grid>
                            </Grid>
                        )
                    }, [])
                }
            </div>
        )
    }

    return (
        <div>
            <ContentCard step={step}
                icon={generate_icon}
                image={ isMobile ? Img.Generate : ""}
                title="Generate Link"
                content={"This feature allows you to create and share payment links to your customers."}
                hasAdditional={true}
                additional_content={<GenerateContent />} />
        </div>
    )
}

export default GenerateLink;