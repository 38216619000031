import {
    GET_RECON_BEGIN,
    GET_RECON_SUCCESS,
    GET_RECON_ERROR,
    GET_SUBWALLET_PAYMENT_BEGIN,
    GET_SUBWALLET_PAYMENT_SUCCESS,
    GET_SUBWALLET_PAYMENT_ERROR,
    GET_CUTOFF_BEGIN,
    GET_CUTOFF_SUCCESS,
    GET_CUTOFF_ERROR
} from './reconConstants';

import axios from "axios";

export const getReconBegin = () => {
    return {type: GET_RECON_BEGIN};
}

export const clearRecon = () => {
    return (dispatch) => {
        dispatch(getReconBegin());
    }
}


export const getReconSuccess = (recon) => {
    return {type: GET_RECON_SUCCESS, payload: recon};
}

export const getReconError = (errors) => {
    return {type: GET_RECON_ERROR, payload: errors};
}

export const getRecon = (start, cutoff, pageSize, page, channel, type, mode="All", master_email="") => {
    return (dispatch) => {
        dispatch(getReconBegin());
        if(cutoff){
            const yourConfig = {
                headers: {
                   Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            }
            if(master_email == ""){
                axios.get(process.env.REACT_APP_API_URL+'/api/recon/'+channel+'/?start='+start+'&end='+cutoff+'&cutoff='+cutoff+'&page_size='+pageSize+'&page='+page+'&type='+type, 
                yourConfig)
                .then(
                    response => {dispatch(getReconSuccess(response.data));console.log(response)})
                .catch(error => dispatch(getReconError(error)));
            }
            else{
                let encodedMasterEmail = encodeURIComponent(master_email)
                axios.get(process.env.REACT_APP_API_URL+`/api/recon/${channel}/?start=${start}&end=${cutoff}&cutoff=${cutoff}&page_size=${pageSize}&page=${page}&type=${type}&mode=${mode}&master_email=${encodedMasterEmail}`, 
                yourConfig)
                .then(
                    response => {dispatch(getReconSuccess(response.data));console.log(response)})
                .catch(error => dispatch(getReconError(error)));
            }
            
        }

    }
}

export const getCutoffBegin = () => {
    return {type: GET_CUTOFF_BEGIN};
}

export const getCutoffSuccess = (cutoffs) => {
    return {type: GET_CUTOFF_SUCCESS, payload: cutoffs};
}

export const getCutoffError = (errors) => {
    return {type: GET_CUTOFF_ERROR, payload: errors};
}

export const getCutoffs = () => {
    return (dispatch) => {
        dispatch(getCutoffBegin());
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
         }
        axios.get(process.env.REACT_APP_API_URL+'/api/cutoffs/', 
                  yourConfig)
        .then(
            response => dispatch(getCutoffSuccess(response.data)))
        .catch(error => dispatch(getCutoffError(error)));

    }
}


export const getSubwalletReconBegin = () => {
    return {type: GET_SUBWALLET_PAYMENT_BEGIN};
}

export const clearSubwalletRecon = () => {
    return (dispatch) => {
        dispatch(getSubwalletReconBegin());
    }
}


export const getSubwalletReconSuccess = (recon) => {
    return {type: GET_SUBWALLET_PAYMENT_SUCCESS, payload: recon};
}

export const getSubwalletReconError = (errors) => {
    return {type: GET_SUBWALLET_PAYMENT_ERROR, payload: errors};
}

export const getSubwalletRecon = (start, cutoff, pageSize, page, channel, type, master_email="") => {
    return (dispatch) => {
        dispatch(getSubwalletReconBegin());
        if(cutoff){
            const yourConfig = {
                headers: {
                   Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            }
            let encodedMasterEmail = encodeURIComponent(master_email)
            axios.get(process.env.REACT_APP_API_URL+`/api/recon/${channel}/?start=${start}&end=${cutoff}&cutoff=${cutoff}&page_size=${pageSize}&page=${page}&type=${type}&master_email=${encodedMasterEmail}`, 
            yourConfig)
            .then(
                response => {dispatch(getSubwalletReconSuccess(response.data));console.log(response)})
            .catch(error => dispatch(getSubwalletReconError(error)));    
        }

    }
}