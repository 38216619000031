import "../css/admin-view.css";
import React from "react";
import { useParams } from "react-router-dom";
import { Typography, Tabs, Skeleton } from "antd";
import { useAdmin } from "../api/get-admin";
import { SubmerchantsList } from "./submerchants-list";
import { MerchantDetails } from "./admin-details-view";
import { MechantMultiwalletPrivileges } from "./admin-multiwallet-privileges-view";
import MerchantBankTransferSettings from "./admin-bank-transfer-settings-view";
import PayoutSettings from "./admin-payout-settings-view";
import Credentials from "./admin-credentials-view";

const { TabPane } = Tabs;

export function AdminView() {
  const { id } = useParams();
  const { data, isLoading, error, refetch } = useAdmin(id);

  // Filter the privileges array to find the "bank_transfer_enabled" privilege
  const bankTransferPrivilege =
    data &&
    data.data.privileges.find(
      (privilege) => privilege.name === "bank_transfer_enabled",
    );

  return (
    <div
      style={{
        margin: "2.5em",
        display: "flex",
        gap: "2em",
        flexDirection: "column",
      }}
    >
      <div>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "20px",
            color: "#2B2D33",
          }}
        >
          {isLoading ? (
            <Skeleton paragraph={{ rows: 1 }} />
          ) : (
            data && `${data.data.business_name}'s Settings`
          )}
        </Typography>
      </div>

      {!isLoading && (
        <div className="card-container">
          <Tabs type="card">
            <TabPane tab="Merchant Details" key="1">
              <MerchantDetails
                data={data.data}
                isLoading={isLoading}
                error={error}
              />
            </TabPane>
            <TabPane tab="Multiwallet Privileges" key="2">
              <MechantMultiwalletPrivileges
                data={data.data}
                refetchCashbuxMerchantSettings={refetch}
              />
            </TabPane>
            {bankTransferPrivilege && bankTransferPrivilege.value && (
              <TabPane tab="Bank Transfer Settings" key="3">
                <MerchantBankTransferSettings
                  data={data.data}
                  refetchCashbuxMerchantSettings={refetch}
                />
              </TabPane>
            )}
            <TabPane tab="Payout Settings" key="4">
              <PayoutSettings data={data.data} />
            </TabPane>
            <TabPane tab="Credentials" key="5">
              <Credentials merchantId={id} />
            </TabPane>
          </Tabs>
        </div>
      )}

      <div>
        <div>
          <Typography
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              lineHeight: "20px",
              color: "#2B2D33",
              marginBottom: "1em",
            }}
          >
            Sub-merchants
          </Typography>
        </div>
        <SubmerchantsList merchantId={id} />
      </div>
    </div>
  );
}
