import { makeStyles } from '@material-ui/core'
import { ImportantDevices } from '@material-ui/icons'
import { isMobile } from 'react-device-detect'


const adminStyle = makeStyles(theme => ({

    kybDiv: {
        paddingTop: '38px',
        paddingLeft: isMobile ? '150px' : '42px',
        paddingRight: '42px',
    },

    btnDiv: {
        marginBottom: '8px',
        paddingBottom: '10px'
    },
    filterDiv: {
        padding: '10px 20px 10px 20px',
        zIndex: 100,
        backgroundColor: '#FFF',
        display: 'flex',
        justifyContent: 'flex-start'
    },
    btnTabDiv: {
        backgroundColor: '#FFF',
        padding: '10px'
    },
    lineHrDiv: {
        backgroundColor: '#FFF',
        padding: '0 20px 20px 20px'
    },
    line: {
        padding: "0px 20px 0px 20px",
    },
    datePickerDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    datePickerLabel: {
        paddingRight: '15px',
        fontSize: '14px',
    },
    buttonStyle: {
        padding: '12px 18px 13px 18px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '16px',
        height: '40px',
    },

    orderStyle: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '40px'
    },

    doneStyle: {
        fontSize: 12,
        background: 'rgba(29, 210, 139, 0.1)',
        border: '1px solid #1dd28b',
        borderRadius: '4px',
        paddingLeft: 16,
        lineHeight: '32px',
        height: 32,
        width: 100,
        borderLeft: '4px solid #1dd28b',
        color: '#2B2D33'
    },
    ongoingStyle: {
        fontSize: 12,
        background: 'rgba(245, 146, 47, 0.1)',
        border: '1px solid #F5922F',
        borderRadius: '4px',
        paddingLeft: 16,
        lineHeight: '32px',
        height: 32,
        width: 100,
        borderLeft: '4px solid #F5922F',
        color: '#2B2D33'
    },

    exportBtn: {
        width: '120px',
        outline: 0,
        backgroundColor: 'transparent',
        marginRight: '20px',
        border: '1px solid #1DD28B',
        height: '40px',
        borderRadius: '4px',
        '&:hover': {
            cursor: 'pointer',
            color: '#1DD28B',
            opacity: 0.8
        },
    },

    createBtn: {
        width: '200px',
        outline: 0,
        backgroundColor: '#1DD28B',
        border: '#F5922F',
        height: '40px',
        borderRadius: '4px',
        '&:hover': {
            cursor: 'pointer',
            color: '#F5922F',
            opacity: 0.8
        },
    },
    applyBtn: {
        zIndex: 1, 
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: 72,
        cursor: 'pointer',
    },


    btnText: {
        fontSize: '14px',
        fontWeight: '600',
        color: '#fff'
    },

    exportBtnContent: {
        fontSize: '14px',
        fontWeight: '600',
        color: '#1DD28B',
    },

    approvedStyle: {
        fontSize: 12,
        background: 'rgba(29, 210, 139, 0.1)',
        border: '1px solid #1dd28b',
        borderRadius: '4px',
        paddingLeft: 16,
        lineHeight: '32px',
        height: 32,
        width: 100,
        borderLeft: '4px solid #1dd28b',
        color: '#2B2D33'
    },

    rejectedStyle: {
        fontSize: 12,
        background: 'rgba(226, 76, 76, 0.1)',
        border: '1px solid #E24C4C',
        borderRadius: '4px',
        paddingLeft: 16,
        lineHeight: '32px',
        height: 32,
        width: 100,
        borderLeft: '4px solid #E24C4C',
        color: '#2B2D33'
    },

    createdStyle: {
        fontSize: 12,
        background: 'rgba(101, 193, 242, 0.1)',
        border: '1px solid #65C1F2',
        borderRadius: '4px',
        paddingLeft: 16,
        lineHeight: '32px',
        height: 32,
        width: 100,
        borderLeft: '4px solid #65C1F2',
        color: '#2B2D33'
    },

    submittedStyle: {
        fontSize: 12,
        background: 'rgba(245, 146, 47, 0.1)',
        border: '1px solid #F5922F',
        borderRadius: '4px',
        paddingLeft: 16,
        lineHeight: '32px',
        height: 32,
        width: 100,
        borderLeft: '4px solid #F5922F',
        color: '#2B2D33'
    },

    subHeader: {
        fontSize: '20px',
        fontWeight: 'bold',
        paddingTop: '15px',
        paddingBottom: '15px',
        color: '#000'
    }


}))

export default adminStyle