import React, {useEffect} from 'react'
import { makeStyles } from '@material-ui/core';
import {Card, Input, Button, DatePicker, Checkbox, Select, Modal, Icon, message} from 'antd'
import UpgradeAccount from '../../../static/img/upgrade_account.svg'
import * as Style from '../../upgrade_to_business/upgrade_to_business_style'
import * as Style2 from './kyc'
import {NATURE_BUSINESS, POSITION} from '../../constants/constants'
import {history} from '../../../store/history'
import TipsIcon from '../../../static/icons/tips.svg'
import SelfieImg from '../../../static/img/selfie.svg'
import UploadIDImg from '../../../static/img/upload_id.svg'
import LimitImg from '../../../static/img/limit_level.svg'
import {Grid} from '@material-ui/core'
import CheckIcon from '../../../static/icons/check.svg'
import axios from 'axios'
import moment from 'moment';
import {isMobile} from 'react-device-detect'

import Main from "../../../layouts/Main";

const { TextArea } = Input;

const KYCIndividual2 = props => {

    useEffect(()=>{
        if(localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") == null){
            history.push('/login')
          }
          else{
            if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
              history.push('/dashboard')
            }
            else if(localStorage.getItem("userType") == "PS"){
              history.push('/orders')
            }
            else if(localStorage.getItem("userType") == "PF"){
                history.push('/recon/7_eleven')
            }
            else if(localStorage.getItem("userType") == "MA"){
              history.push('/overview')
            }
            else{
                fetchProfile()
            }
            
        }
    },[])
    const { Option } = Select;

    const pUid = props.match.params.id;

    const [answers, setAnswers] = React.useState([])
    const [name, setName] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [transactions, setTransactions] = React.useState("")
    const [customers, setCustomers] = React.useState("")
    const [sales, setSales] = React.useState("")
    const [score, setScore] = React.useState("")

    const [approved, setApproved] = React.useState(false)

    const kycStyles = makeStyles(theme => ({
        breadCrumbs:{
            marginTop: '-1.32%',
            marginLeft: '0.7%',
            padding: '30px 0px 30px 11%',
            position: 'fixed',
            background: '#f5f5f4',
            marginBottom: '20px',
            width: '100%',
            zIndex: '100',

            [theme.breakpoints.down("md")]: {
                padding: '30px 0px 30px 11%',

            },

            [theme.breakpoints.down("lg")]: {
                padding: '30px 0px 30px 11%',

            },

        },

        breadCrumbsMobile:{


        },

        breadCrumbsHover: {
            color: '#000000',
            '&:hover': {
                color: '#1d82b8',
                textDecoration: 'underline'   
            }
    },}))



    async function fetchProfile(){
    
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.get(process.env.REACT_APP_API_URL + '/api/admin/survey/' + pUid + '/?type=1', yourConfig)

        setName(response.data.name);
        setEmail(response.data.email);
        setApproved(response.data.approved);
        setAnswers(response.data.answers);
        setCustomers(response.data.customer_count);
        setTransactions(response.data.transaction_count);
        setSales(response.data.gross_sales);
        setScore(response.data.score);
    }

    async function approveSubmission(approval){
    
        const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = {type: 1}
        let response = await axios.post(process.env.REACT_APP_API_URL + '/api/admin/survey/' + pUid + '/', params, yourConfig)
        if(response.data.status == 'success'){
            message.success('Successfully approved');
            history.push('/submissions_kyc2')
        }else{
            message.error('Failed ');
        }


    }

    const KYCMain2 = () => {
        return(
            <Grid container >
                <Grid lg={6}>
                <div>
                <Card title={<div style={Style2.headerStyle}>User Information</div>} style={{ width: '524px', marginTop: '60px' }}>
                            <Grid container>
                                <Grid lg={5} xs={12}>
                                    <div style={Style2.PresentStyle}>Name:</div>
                                </Grid>
                                <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                    <div style={Style2.PermaStyle}>{name}</div>
                                </Grid>
                                
                                <Grid lg={5} xs={12}>
                                    <div style={Style2.PresentStyle}>Email:</div>
                                </Grid>

                                <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                    <div style={Style2.PermaStyle}>{email}</div>
                                </Grid>

                        </Grid>

                    </Card>
                    <Card title={<div style={Style2.headerStyle}>Account Information</div>}  style={{ width: '524px'}}>
                        <Grid container>
                                <Grid lg={5} xs={12}>
                                    <div style={Style2.PresentStyle}>Gross Sales:</div>
                                </Grid>
                                <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                    <div style={Style2.PermaStyle}>{sales}</div>
                                </Grid>
                                <Grid lg={5} xs={12}>
                                    <div style={Style2.PresentStyle}>Transaction Count:</div>
                                </Grid>
                                <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                    <div style={Style2.PermaStyle}>{transactions}</div>
                                </Grid>
                                
                                <Grid lg={5} xs={12}>
                                    <div style={Style2.PresentStyle}>Customer Count:</div>
                                </Grid>

                                <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                    <div style={Style2.PermaStyle}>{customers}</div>
                                </Grid>

                                <Grid lg={5} xs={12}>
                                    <div style={Style2.PresentStyle}>Total Score:</div>
                                </Grid>

                                <Grid lg={7} xs={12} style={{marginBottom: '16px'}}>
                                    <div style={Style2.PermaStyle}>{score}</div>
                                </Grid>

                        </Grid>

                    </Card>
                    <div style={{justifyContent: 'center', background: '#FFF', width: '524px'}}>
                        
                        <div style={{display: 'flex', justifyContent: 'space-between',padding: '20px 20px 15px 20px'}}>
                            <div style={Style2.headerStyle}>Approval</div>
                        </div>
                        <hr></hr>
                        <div style={{padding: '20px 20px 15px 20px'}} >

                            <div style={{justifyContent: 'center', marginBottom: '20px'}}>
                                <div style={{display: 'flex', width: '95%'}}>

                                    <Grid lg={5}>
                                        <div style={Style2.PresentStyle}>Status:</div>
                                    </Grid>
                                    <Grid lg={7}>
                                    { approved  ? 
                                        <div style={Style2.ApprovedStyle}>Approved</div>
                                    : 
                                    <div style={Style2.PendingStyle}>Pending</div>
                                        
                                    }
                                    </Grid>
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: '20px 20px 15px 20px', display: approved ? 'none': ''}} >

                            <Grid container>
                                <Grid lg={6}>
                                    <Button style={Style2.submitBtnStyle} onClick={() => approveSubmission(2)}>Approve</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                </Grid>

                <Grid lg={4}>

                    <Card title={<div style={Style2.headerStyle}>Survey Answers</div>} style={{ marginTop: '60px'}}>
                        <div>
                            {
                                answers.map((item, index) => {
                                    return(
                                    <div key={index} style={{ marginBottom: 20}}>
                                            <div style={Style2.PresentStyle}>{index+1}. {item.question}</div>
                                            <br/>
                                            <div style={Style2.PermaStyle}>{item.answer}</div>
                                    </div>
                                    )
                                })
                            }
                        </div>

                    </Card>
                
                </Grid>
                </Grid>
        )
    }

    return(
        props && props.isAdminHistory ?
        <KYCMain2 />
        :
        <Main>
            <KYCMain2 />
        </Main>
    )
}

export default KYCIndividual2