import React, { useState } from "react";
import { Tooltip, Typography, Dropdown, Icon, Menu, Modal } from "antd";
import { Table, getStatusInfo } from "../../../components/Table";
import { StatusChip } from "../../../components/StatusChip";
import { ViewTransferDetails } from "./ViewTransferDetails";

export function HistoryTable({
  data,
  currentPage,
  setCurrentPage,
  columnsPerPage,
}) {
  const [viewDetailsVisible, setViewDetailsVisible] = useState(false);
  const [currentViewDetailsActiveId, setCurrentViewDetailsActiveId] =
    useState(0);

  const currentViewDetails =
    (data &&
      data.results &&
      data.results.filter(
        (detail) => detail.id === currentViewDetailsActiveId,
      )) ||
    [];
  const pageSize = columnsPerPage;
  const tableColumns = [
    {
      title: "Reference ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
    },
    {
      title: "Bank",
      dataIndex: "bank",
      key: "bank",
    },
    {
      title: "Account",
      dataIndex: "account_number",
      key: "account_number",
      render: (_, record) => (
        <div>
          <Typography>{record.account_number}</Typography>
          <Typography>{record.account_name}</Typography>
        </div>
      ),
    },
    {
      title: "Date Generated",
      dataIndex: "date_paid",
      key: "date_paid",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const statusInfo = getStatusInfo(status);
        return <StatusChip status={statusInfo} label={status} />;
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (remarks) => (
        <Tooltip title={remarks}>
          <Typography
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              maxWidth: 232,
            }}
          >
            {remarks}
          </Typography>
        </Tooltip>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => (
        <Typography style={{ whiteSpace: "nowrap" }}>
          {amount.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            style: "currency",
            currency: "PHP",
          })}
        </Typography>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <MenuItems
              record={record}
              setViewDetailsVisible={setViewDetailsVisible}
              setCurrentViewDetailsActiveId={setCurrentViewDetailsActiveId}
            />
          }
          trigger={["click"]}
          placement="bottomLeft"
          overlayStyle={{
            visibility: viewDetailsVisible ? "hidden" : "visible",
          }}
        >
          <Icon type="ellipsis" />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={tableColumns}
        data={data}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
      />
      <Modal
        visible={viewDetailsVisible}
        footer={null}
        onCancel={() => setViewDetailsVisible(false)}
        centered
      >
        <ViewTransferDetails currentViewDetails={currentViewDetails[0]} />
      </Modal>
    </>
  );
}

function MenuItems({
  record,
  setViewDetailsVisible,
  setCurrentViewDetailsActiveId,
}) {
  return (
    <Menu>
      <Menu.Item
        key="view-details"
        onClick={() => {
          setViewDetailsVisible(true);
          setCurrentViewDetailsActiveId(record.id);
        }}
      >
        View Details
      </Menu.Item>
    </Menu>
  );
}
