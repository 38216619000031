import React from 'react';
import axios from 'axios';
import moment from 'moment';
import payoutStyles from '../../AdminComponents/payout/payout_css'
import { Table, Select, DatePicker, Pagination, Button } from 'antd';

const Option = Select ;

const ReconPayout = props => {
    const dateFormat = 'MM-DD-YYYY';
    
    React.useEffect(() => {
        getPayoutSummary(mode, moment().format("MM-DD-YYYY"), moment().format("MM-DD-YYYY"), currentPage, maxPage);
        setStartDate(moment().format(dateFormat))
        setEndDate(moment().format(dateFormat))
    }, [])

    const ps = payoutStyles();
    const maxPage = 20;
    const [summaryData, setSummary] = React.useState([])
    const [mode, setMode] = React.useState("All");
    const [currentPage, setCurrentPage] = React.useState(1);
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [startMoment, setStartMoment] = React.useState('');
    const [endMoment, setEndMoment] = React.useState('');

    const columns = [
        {
            title: 'Transfer ID',
            render: (record) => {
                return(
                    <div style={{fontWeight: 'bold'}}>
                      {record.trans_id}
                  </div>
                )
            }
        },
        {
            title: 'User',
            render: (record) => {
                return(
                    <div style={{fontWeight: 'bold'}}>
                      {record.owner}
                  </div>
                )
            }
        },
        {
            title: 'Mode',
            render: (record) => {
                return(
                    <div style={{fontWeight: 'bold'}}>
                      <i>{record.mode}</i>
                  </div>
                )
            }
        },
        {
          title: 'Date Paid',
          dataIndex: 'date_paid'  
        }
    ]

    const modes = [
        'All', 'Instapay', 'Pesonet', 'EON', 'UnionBank'
    ]

    const disabledStartDate = startValue => {
        if (!startValue || !endMoment) {
          return false;
        }
        return startValue && startValue > moment().endOf('day');
    };

    const disabledEndDate = endValue => {
        if (!endValue || !startMoment) {
            return false;
        }
        return endValue.valueOf() <= startMoment.valueOf();
    };

    const handleStartChange = value => {
        if(value != null && endDate != ""){
            setStartMoment(value)
            setStartDate(value.format(dateFormat))
            getPayoutSummary(mode, value.format(dateFormat), endDate, 1, maxPage)
            //Put get async function here
        }

        else if(value != null){
            setStartMoment(value)
            setStartDate(value.format(dateFormat))
        }
        
        else{
            setStartMoment(moment().format(dateFormat))
            setStartDate(moment().format(dateFormat))
            getPayoutSummary(mode, moment().format(dateFormat), moment().format(dateFormat), 1, maxPage)
            //Put get async function here
        }
    }

    const handleEndChange = value => {

        if(value != null && startDate != ""){
            setEndMoment(value)
            setEndDate(value.format(dateFormat))
            getPayoutSummary(mode, startDate, value.format(dateFormat), 1, maxPage)
            //Put get async function here

        }

        else{
            setEndMoment(moment().format(dateFormat))
            setEndDate(moment().format(dateFormat))
            getPayoutSummary(mode, moment().format(dateFormat), moment().format(dateFormat), 1, maxPage)
            //Put get async function here
        }
    }

    const renderData = () => {
        let filteredData = []
        filteredData = summaryData.results

        return filteredData
    }

    function selectPage(e) {
        console.log(e)
        setCurrentPage(e)
        getPayoutSummary(mode, startDate, endDate, e, maxPage)
        //Put get async function here
    }

    async function getPayoutSummary (mode, start, end, page, page_size) {

        let yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try {
            
            let response = await axios.get(
                process.env.REACT_APP_API_URL + '/api/recon/payout/?mode='+mode+'&start='+start+'&end='+end+"&page="+page+"&page_size="+page_size, yourConfig
            )
            
            setSummary(response.data)
            console.log(response)
        }

        catch(error){
            if((error.response)&&(error.response.status == 401)){
              console.log(error.response)
            }
        }
    }

    async function DownloadPayouts(){
        console.log(startDate)
        console.log(endDate)
        let yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }
        let response = await axios.get(
          process.env.REACT_APP_API_URL + '/api/recon/payouts/download/?mode='+mode+'&start='+startDate+'&end='+endDate ,yourConfig
        );
        console.log(response);
        if(response.data){
          const type = response.headers['content-type'];
          const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Recon_Payouts_'+moment().format("MM-DD-YYYY_HH:mm:ss") +'.csv';
          link.click()
          link.remove();
        }
    }

    return(
        <div className={ps.mainBody}>
            <div className={ps.headerStyle}>
                UBP Remit
            </div>

            <div className={ps.btnDiv}>
                <div>
                    <Select
                        size="large"
                        placeholder="Mode"
                        style={{width:'120px', marginRight: '12px', zIndex: 1}}
                        onChange={(e) => {
                            setMode(e)
                            setCurrentPage(1)
                            getPayoutSummary(e, startDate, endDate, currentPage, maxPage)
                        }}>
                        
                        {
                            modes.map((item, i) => {
                                return(
                                    <Option key={i} value={item}>{item}</Option>
                                )
                            })
                        }

                    </Select>
                </div>
                
                <div>
                    <DatePicker
                    disabledDate={disabledStartDate}
                    placeholder="Start Date"
                    size="large"
                    format={dateFormat} 
                    onChange={handleStartChange} 
                    style={{zIndex: 1}}
                    />
                    <span style={{padding: '0 8px'}}>-</span> 
                    <DatePicker
                    disabledDate={disabledEndDate}
                    placeholder="End Date"
                    size="large"
                    format={dateFormat} 
                    onChange={handleEndChange} 
                    style={{marginRight: '10px', zIndex: 1}}
                    />
                </div>

                <div style={{zIndex: 100}}>
                    <Button
                        style={{
                            backgroundColor: '#1DD28B',
                            border: '#1DD28B',
                            height: '40px',
                            borderRadius:'10px',
                        }}
                        onClick={() => DownloadPayouts()}>
                        <div style={{color:'#fff'}}>Export CSV</div>
                    </Button>
                </div>
                
            </div>

            <Table 
                rowKey={record => record.trans_id}
                columns={columns}
                dataSource={renderData()}
                pagination={false}
            />

            <div className={ps.btnDiv} style={{marginTop: 20}}>

            <Pagination 
                size="small"
                total={summaryData.total} 
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                onChange={e => selectPage(e)} 
                defaultCurrent={1} key={Math.random()} 
                pageSize={maxPage} 
                current={currentPage}/>
            
            </div>

            <div className={ps.summaryDiv}>
                <div className={ps.summaryText} style={{fontWeight:'bold'}}>No. of Transactions:</div> 
                
                <div className={ps.summaryText}>Instapay:</div> 
                <div className={ps.summaryValue}>
                    {summaryData.total_instapay}
                </div>

                <div className={ps.summaryText}>Pesonet:</div> 
                <div className={ps.summaryValue}>
                    {summaryData.total_pesonet}
                </div>

                <div className={ps.summaryText}>EON:</div> 
                <div className={ps.summaryValue}>
                    {summaryData.total_eon}
                </div>

                <div className={ps.summaryText}>UnionBank:</div> 
                <div className={ps.summaryValue}>
                    {summaryData.total_ub}
                </div>

                <div className={ps.summaryText}>Total Billing:</div> 
                <div className={ps.summaryValue}>
                        PHP {parseFloat(summaryData.total).toLocaleString('en-US', {minimumFractionDigits: 2})}
                </div>
            </div> 
        </div>
    )
}

export default ReconPayout;