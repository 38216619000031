import React from 'react';
import axios from 'axios';
import { Modal, Button, message, Input, Icon, Switch } from 'antd';
import { Grid } from '@material-ui/core';

const EditUserModal = props => {

    const {
        openModal, setOpenModal, data
    } = props;

    const [toBeEditID, setToBeEditID] = React.useState(data.id);
    const [toBeEditUname, setToBeEditUname] = React.useState(data.username);
    const [toBeEditFname, setToBeEditFname] = React.useState(data.first_name);
    const [toBeEditLname, setToBeEditLname] = React.useState(data.last_name);
    const [toBeEditContact, setToBeEditContact] = React.useState(data.contact);
    const [toBeEditCompleted, setToBeEditCompleted] = React.useState(data.completed);
    const [toBeEditVerified, setToBeEditVerified] = React.useState(data.verified);

    const errorMessage = (text) => {
        message.destroy()
        message.error(text, 3)
    }

    const editUser = () =>{
        if(toBeEditFname.length == 0){
            errorMessage('Please fill up First name')
        }
        else if(toBeEditLname.length == 0){
            errorMessage('Please fill up Last name')
        }
        else if(toBeEditUname.length == 0){
          errorMessage("Please fill up Username")
        }
        else{
          editUserNow()
        }
    }

    const autoFormat = (value) => value == null ? null : value.replace(/[^\d.]/g, '');

    const editUserNow = async () => {
        let params = {
          id: toBeEditID,
          first_name: toBeEditFname,
          last_name: toBeEditLname,
          username: toBeEditUname,
          verified: toBeEditVerified,
          completed: toBeEditCompleted,
          contact: toBeEditContact,
        }
    
        let yourConfig = {
          headers: {
              Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }
    
        let response = await axios.post(
          process.env.REACT_APP_API_URL + '/api/edit_user/', params,yourConfig
        );
    
        if(response.data.status == 'success'){
            setOpenModal(false);
            window.location.reload();
        }
        else{
          errorMessage(response.data.message)
        }
    }

    return(
        <Modal
            title="Edit User"
            centered
            visible={openModal}
            onCancel={() => setOpenModal(false)}
            footer={[
                <Button key="back" onClick={() => setOpenModal(false)} className="admin-download-btn">
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={()=>editUser()} className="add-user">
                    Save
                </Button>,
            ]}>

                <Grid container>
                    <Grid item lg={12} sm={12} style={{marginBottom: '20px', marginTop: '20px'}}>
                        <label style={{fontSize: 12, color: '#000' }}>Username</label>
                        <Input
                          placeholder="Username"
                          style={{width: '100%', height: '50px'}}
                          onChange={ e => setToBeEditUname(e.target.value)}
                          value={toBeEditUname}
                          />
                    </Grid>

                    <Grid item lg={6} sm={12}>
                        <label style={{fontSize: 12, color: '#000' }}>First Name</label>
                      <Input
                          placeholder="First Name"
                          style={{width: '98%', marginRight: '4%', height: '50px', marginBottom: '20px'}}
                          onChange={ e => setToBeEditFname(e.target.value)}
                          value={toBeEditFname}
                          />
                    </Grid>
                  
                    <Grid item lg={6} sm={12}>
                        <label style={{fontSize: 12, color: '#000' }}>Last Name</label>
                      <Input
                          placeholder="Last Name"
                          style={{width: '100%', height: '50px', marginBottom: '20px'}}
                          onChange={ e => setToBeEditLname(e.target.value)}
                          value={toBeEditLname}
                          />
                    </Grid>

                    <Grid item lg={12} sm={12} style={{marginBottom: '10px', marginTop: '10px'}}>
                        <label style={{fontSize: 12, color: '#000' }}>Mobile Number</label>
                        <Input
                          placeholder="MObile Number"
                          style={{width: '100%', height: '50px'}}
                          onChange={ e => setToBeEditContact(autoFormat(e.target.value))}
                          value={toBeEditContact}
                          maxLength={10}
                          />
                    </Grid>

                    <Grid item lg={6} sm={12}>
                        <div>Verified</div>
                        <Switch
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />}
                        checked={toBeEditVerified}
                        onChange={(checked)=>setToBeEditVerified(checked)}
                        />
                    </Grid>
                  
                    <Grid item lg={6} sm={12}>
                        <div>Completed</div>
                        <Switch
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />}
                        checked={toBeEditCompleted}
                        onChange={(checked)=>setToBeEditCompleted(checked)}
                        />
                    </Grid>
                </Grid>
        
        </Modal>
    );
}

export default EditUserModal;