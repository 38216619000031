import React from 'react'
import { Drawer, Collapse, Icon, Checkbox, Button} from 'antd'
import * as Style from './mobile_order_style';

const PaymentMethodsDrawer = props => {

    const {
        paymentMethodVisiblity, onClose,
        checkAllOTC, onCheckAllOtcChange, OTC_LIST, otcCheckedList, onChangeOtc,
        checkAllWeb, onCheckAllWebChange, BANK_LIST, webCheckedList, onChangeWeb,
        checkAllWallet, onCheckAllWalletChange, MOBILE_LIST, walletCheckedList, onChangeWallet,
        checkAllCard, onCheckAllCardChange, CARD_LIST, cardCheckedList, onChangeCard,
        setCCSelected, segments
    } = props


    const { Panel } = Collapse;
    const CheckboxGroup = Checkbox.Group
    
    return(
        <Drawer
            placement="bottom"
            width="100%"
            height="100%"
            title={
                <div align="center" style={{marginTop: 32}}>Payment Method</div>
            }
            visible={paymentMethodVisiblity}
            onClose={onClose}>

            <div style={{marginBottom: 20}}>
                <Collapse 
                    bordered={false} 
                    expandIconPosition="right" 
                    expandIcon={
                        ({isActive}) => 
                        <Icon style={{color: '#F5922F', paddingRight: 32}} type="up" rotate={isActive ? 0 : 180} />
                    }>
                    <Panel header={
                        <div style={Style.panelHeader}>
                            <Checkbox
                                indeterminate={otcCheckedList.length && otcCheckedList.length < OTC_LIST.length} 
                                checked={checkAllOTC} 
                                onChange={(e)=>onCheckAllOtcChange(e)} 
                                value="All OTC" >
                                    Over-the-counter
                            </Checkbox>
                        </div>
                    }>  
                        <div style={Style.checkBoxStyle}>
                            <CheckboxGroup 
                                class="cb_payment_methods"
                                style={{width: 238}}
                                options={OTC_LIST}
                                value={otcCheckedList} 
                                onChange={(e)=>onChangeOtc(e)} />
                        </div>
                    </Panel>
                    <Panel header={
                        <div style={Style.panelHeader}>
                            <Checkbox checked={checkAllWeb} 
                                indeterminate={webCheckedList.length && webCheckedList.length < BANK_LIST.length} 
                                onChange={(e)=>onCheckAllWebChange(e)} 
                                value="All Web" >
                                    Web Banking
                            </Checkbox>
                        </div>
                    }> 
                        <div style={Style.checkBoxStyle}>

                        <CheckboxGroup options={BANK_LIST} 
                            class="cb_payment_methods"
                            value={webCheckedList} 
                            onChange={(e)=>onChangeWeb(e)} />

                        </div>
                    </Panel>
                    <Panel header={
                        <div style={Style.panelHeader}>
                            <Checkbox checked={checkAllWallet} 
                                indeterminate={walletCheckedList.length && walletCheckedList.length < MOBILE_LIST.length} 
                                onChange={(e)=>onCheckAllWalletChange(e)} 
                                value="All E-Wallet" >
                                    E-Wallet
                            </Checkbox>
                        </div>
                    }> 
                        <div style={Style.checkBoxStyle}>

                        <CheckboxGroup 
                            class="cb_payment_methods"
                            options={MOBILE_LIST} 
                            value={walletCheckedList} 
                            onChange={(e)=>onChangeWallet(e)} />
                        </div>
                    </Panel>
                    {
                        localStorage.getItem("userType") == "ME" ?
                        '' :
                        <Panel header={
                            <div style={Style.panelHeader}>
                                <Checkbox checked={checkAllCard} 
                                    indeterminate={cardCheckedList.length && cardCheckedList.length < CARD_LIST.length} 
                                    onChange={(e)=>onCheckAllCardChange(e)} 
                                    value="All Card Payments" >
                                        Card Payments
                                </Checkbox>
                            </div>
                        }> 
                            <div style={Style.checkBoxStyle}>

                            <CheckboxGroup 
                                class="cb_payment_methods"
                                options={CARD_LIST} 
                                value={cardCheckedList} 
                                onChange={(e)=>onChangeCard(e)} />
                            </div>
                        </Panel>
                    }
                    {
                        localStorage.getItem("userType") == "ME" ?
                        '' :
                        <Panel header={
                            <div style={Style.panelHeader}>
                                Buy Now Pay Later
                            </div>
                        }> 
                            <div style={Style.checkBoxStyle}>

                            <Checkbox onChange={e => 
                                setCCSelected(e.target.checked)
                                }>BillEase</Checkbox>
                            </div>
                        </Panel>
                    }
            
                </Collapse>
            </div>
            <button className={`btn--${segments}`} style={Style.mobileApplyBtn} onClick={onClose}>
                Apply
            </button>
        </Drawer>
    )
}

export default PaymentMethodsDrawer