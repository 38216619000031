export function validateMobileNumber(number) {
    const exactlyTenDigitsRegex = /^\d{10}$/;
  
    if (!exactlyTenDigitsRegex.test(number)) {
        return Promise.reject("Mobile number should be exactly 10 digits");
    } else if (number.startsWith('0')) {
        return Promise.reject("Mobile number should not start with zero");
    }
  
    return Promise.resolve();
}