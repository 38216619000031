import React from 'react'

export default function EcpayDisclaimer(props){

    return(
        <div style={{color: '#2B2D32'}}>
            <div>By using ECPay, you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} target="_blank" href="https://www.ecpay.com.ph/dpa/">Privacy Policy</a></div>
            <div hidden={props.channel == 'posible'}>Support: <a style={{color: '#000000', textDecoration: 'underline', fontWeight: 'bold'}} href="mailto:helpdesk@ecpay.com">helpdesk@ecpay.com</a> or via <b>(02)7906-9530</b></div> 
        </div>
    )
}