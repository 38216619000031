import { Alert, Select, Form, Input } from "antd";
import React from "react";
import { FormWrapper } from "../FormWrapper";
import { BankSelector } from "./BankSelector";
import {
  validateAmount,
  validateNoSpecialCharacters,
  validateMobileNumber,
  validateEmail,
  validateEnoughBalance,
} from "../../../../../../utils/validators";
import { formatToNumbers } from "../../../../../fund_transfer_management/utils";

export function ReceiverForm({
  form,
  receiver_amount,
  receiver_account_type,
  receiver_bank,
  code,
  receiver_account_number,
  receiver_account_name,
  receiver_gender,
  receiver_address,
  receiver_mobile_number,
  receiver_email,
  updateFields,
  transferError,
  showErrorAlert,
  balance,
  mode,
  setMode,
  banks,
}) {
  return (
    <FormWrapper title="Receiver Details">
      <Form.Item label="Transfer amount" style={{ margin: 0 }}>
        {showErrorAlert && transferError && (
          <Alert
            message={transferError.message}
            type="error"
            style={{ marginBottom: "1rem", fontSize: "11px" }}
          />
        )}
        {form.getFieldDecorator("receiver_amount", {
          initialValue: receiver_amount,
          rules: [
            { required: true, message: "Please input transfer amount!" },
            { validator: (_, value) => validateAmount(value) },
            {
              validator: (_, value) =>
                validateEnoughBalance(
                  value,
                  formatToNumbers(balance.float_balance),
                ),
            },
          ],
        })(
          <Input
            addonBefore="₱"
            type="number"
            onChange={(e) => updateFields({ receiver_amount: e.target.value })}
            size="large"
          />,
        )}
      </Form.Item>

      <BankSelector
        form={form}
        balance={balance}
        mode={mode}
        setMode={setMode}
        receiver_amount={receiver_amount}
        receiver_bank={receiver_bank}
        updateFields={updateFields}
        banks={banks}
      />

      <Form.Item label="Account Type" style={{ margin: 0 }}>
        {form.getFieldDecorator("receiver_account_type", {
          initialValue: receiver_account_type,
          rules: [{ required: true, message: "Please select account type" }],
        })(
          <Select
            size="large"
            placeholder="Select account type"
            onChange={(e) => updateFields({ receiver_account_type: e })}
          >
            <Select.Option key="savings" value="savings">
              Savings
            </Select.Option>
            <Select.Option key="checking" value="checking">
              Checking
            </Select.Option>
          </Select>,
        )}
      </Form.Item>

      <Form.Item label="Account Name" style={{ margin: 0 }}>
        {form.getFieldDecorator("receiver_account_name", {
          initialValue: receiver_account_name,
          rules: [
            { required: true, message: "Please input an account name" },
            { validator: (_, value) => validateNoSpecialCharacters(value) },
          ],
        })(
          <Input
            type="text"
            onChange={(e) =>
              updateFields({ receiver_account_name: e.target.value })
            }
            size="large"
          />,
        )}
      </Form.Item>

      <Form.Item label="Account Number" style={{ margin: 0 }}>
        {form.getFieldDecorator("receiver_account_number", {
          initialValue: receiver_account_number,
          rules: [
            { required: true, message: "Please input an account number" },
          ],
        })(
          <Input
            type="text"
            onChange={(e) =>
              updateFields({ receiver_account_number: e.target.value })
            }
            size="large"
          />,
        )}
      </Form.Item>

      <Form.Item label="Address" style={{ margin: 0 }}>
        {form.getFieldDecorator("receiver_address", {
          initialValue: receiver_address,
          rules: [{ required: true, message: "Please input an address" }],
        })(
          <Input
            type="text"
            onChange={(e) => updateFields({ receiver_address: e.target.value })}
            size="large"
          />,
        )}
      </Form.Item>

      <Form.Item label="Mobile Number" style={{ margin: 0 }}>
        {form.getFieldDecorator("receiver_mobile_number", {
          initialValue: receiver_mobile_number,
          rules: [
            { required: true, message: "Please input a mobile number" },
            { validator: (_, value) => validateMobileNumber(value) },
          ],
        })(
          <Input
            addonBefore="+63"
            type="number"
            onChange={(e) =>
              updateFields({ receiver_mobile_number: e.target.value })
            }
            size="large"
          />,
        )}
      </Form.Item>

      <Form.Item label="Select Gender" style={{ margin: 0 }}>
        {form.getFieldDecorator("receiver_gender", {
          initialValue: receiver_gender,
          rules: [{ required: true, message: "Please select a gender" }],
        })(
          <Select
            size="large"
            placeholder="Select Gender"
            onChange={(e) => updateFields({ receiver_gender: e })}
          >
            <Select.Option key="male" value="male">
              Male
            </Select.Option>
            <Select.Option key="female" value="female">
              Female
            </Select.Option>
          </Select>,
        )}
      </Form.Item>

      <Form.Item label="Email" style={{ margin: 0 }}>
        {form.getFieldDecorator("receiver_email", {
          initialValue: receiver_email,
          rules: [
            { required: true, message: "Please input email address" },
            { validator: (_, value) => validateEmail(value) },
          ],
        })(
          <Input
            type="email"
            onChange={(e) => updateFields({ receiver_email: e.target.value })}
            size="large"
          />,
        )}
      </Form.Item>
    </FormWrapper>
  );
}
