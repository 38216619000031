import React from 'react'
import MobileMain from '../../../layouts/Mobile/MobileMain'
import Main from '../../../layouts/Main'
import Application_Main from './application_main'
import {isMobile} from 'react-device-detect';
import { history } from '../../../store/history';

const Index = () => {

   React.useEffect(() => {
    let current_path = window.location.pathname
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
        history.push('/login?next='+current_path)
    }
    if(localStorage.getItem("userType") != "ME" && localStorage.getItem("userType") != "SW"){
            history.push('/dashboard')
    }
   },[])

    return(
        <div>
            {
                isMobile ?
                <MobileMain title={"Complete Profile"}>
                    <Application_Main/>
                </MobileMain>
                :
                <Main>
                    <Application_Main/>
                </Main>
            }
        </div>
    )
}

export default Index;