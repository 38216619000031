import React from 'react';
import enterpriseStyle from './kybStyle';
import { Icon } from 'antd';

const NoteCard = props => {
    const kyb = enterpriseStyle()

    return(
        <div className="note-div">
            <Icon type='info-circle' theme="filled" className="info-icon" />
            <span className="normalTextStyle liveColor left-8">{props.note}</span>
        </div>
    )
}

export default NoteCard