import React from 'react'
import axios from 'axios'
import FeeStyles from '../../style'
import HeaderCard from './headerCard'
import EndorsementCard from './endorsementCard'
import ApprovedCard from './approvedCard'
import {Spin, Input, message} from 'antd'
import CustomInput from './customInput'
import {Credentials, Fees} from '../../../../../static/icons/index'

const { TextArea } = Input;

const MerchantGroups = props => {
    const pUid = props.props && props.props.match && props.props.match.params.id;

    const fee = FeeStyles();
    const userType = localStorage.getItem("userType");
    const [partners, setPartners] = React.useState([]);
    const [approved, setApproved] = React.useState(0)
    const [eremarks, setERemarks] = React.useState("")
    const [remarks, setRemarks] = React.useState("")
    const [MID, setMID] = React.useState("")
    const [MCC, setMCC] = React.useState("")
    const [pubKey, setPubKey] = React.useState("")
    const [secretKey, setSecretKey] = React.useState("")
    const [channelMin, setChannelMin] = React.useState("")
    const [channelMax, setChannelMax] = React.useState("")
    const [buxFee, setBuxFee] = React.useState("")
    const [buxPercent, setBuxPercent] = React.useState("")
    const [channelFee, setChannelFee] = React.useState("")
    const [channelPercent, setChannelPercent] = React.useState("")

    const approval_status = ["Pending", "Approved", "Rejected"]

    async function getPartners(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try {

            let response = await axios.get(
                process.env.REACT_APP_API_URL + `/api/merchant_groups/${pUid}/`,yourConfig)


                setPartners(response.data.data)
        }

        catch (error) {
            console.log(error)
        }

    }

    async function pushApproval(approval){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let params = {'approved': approval, 'remarks': remarks}

        try {

            let response = await axios.post(
            process.env.REACT_APP_API_URL + `/api/kyb/${pUid}/partners/`,params, yourConfig)
            if(response.data.status == 'success'){
                message.success('Application was '+approval_status[approval])
                getPartners();
            }else{
                message.error(response.data.message)
            }
        }

        catch (error) {
            console.log(error)
        }

    }

    React.useEffect(() => {
        getPartners()
    },[])
    
    function reloadPage(){
        getPartners()
    }


    return(
        <div align='center'> 
            <div className={fee.feeCard} align="left">
                <HeaderCard/>
                <ApprovedCard partners={partners} uid={pUid}/>
            </div>
        </div>
    )
}

export default MerchantGroups