import React, {useEffect} from 'react'
import {history} from '../../store/history'
import {isMobile} from 'react-device-detect'
import HomeMobile from './HomeMobile'
import HomeWeb from './HomeWeb'
import LandingPage from './landingPage'
import { Redirect } from "react-router";

const Home = props => {

    useEffect(() => {
        window.location.href="https://bux.ph";
    }, [])


    return(
    	<>
        </>
    )
}

export default Home