import { useEffect, useState } from "react";
import { axios } from "../../../../../../lib/axios";

export function getSubmerchants(id, currentPage) {
  return axios.get(
    `api/cashbux/admins/${id}/submerchants/?page=${currentPage}`,
  );
}

export function useSubmerchants(id, currentPage) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getSubmerchants(id, currentPage);
        setData(response);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, [id, currentPage]);

  return { data, isLoading, error };
}
