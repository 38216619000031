import React from 'react'
import axios from 'axios'
import {Modal, message} from 'antd'
import * as Style from './mobile_order_style'

const OptionModal = props => {

    const {
        visible,
        setModalVisibility,
        orderDetails, orderType, setCancelModalVisibility,

    } = props

    const resend = async() =>{
        let params = {
          id: orderDetails.id
        }
        let yourConfig = {
          headers: {
              Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }
        let response = await axios.post(process.env.REACT_APP_API_URL + "/api/orders/resend/", params, yourConfig);
        
        if(response.data.status == "success"){
          setModalVisibility(false)
          message.success('Successfully send')
        }
    }
    

    return (
        <Modal
            style={{ top:  '40%' }}
            visible={visible}
            closable={false}
            footer={<div style={{display: 'flex', justifyContent: 'center', padding: '10px 0 10px 0'}}>
            <span style={Style.buttonStyle} onClick={()=>setModalVisibility(false)}> Cancel</span>
            </div>}
        >
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
            <a href={`/payment/${orderDetails.uid}/`} style={{color: '#2B2D33', opacity: '0.8'}}>
                <span size="large" style={Style.buttonStyle}> {orderType == "Paid" ? "Customer Receipt" : "Customer Checkout"} </span>
            </a>
            </div>

            <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
            <a href={`/order_details/${orderDetails.uid}/`} style={{color: '#2B2D33', opacity: '0.8'}}>
                <span size="large" style={Style.buttonStyle}>View Payment Details </span>
            </a>
            </div>
            
            {
            orderType == "Pending" && orderDetails.code ?
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                <span style={Style.buttonStyle} onClick={()=>resend()}>Resend payment link</span>
            </div>
            :""
            }
            
            {
                orderType == "Pending" &&
                <div style={{display: 'flex', justifyContent: 'center',color: '#E24C4C'}}>
                    <span style={Style.buttonStyle} onClick={()=>setCancelModalVisibility(true)/setModalVisibility(false)} >Cancel order</span>
                </div> 
            }
        
        </Modal>
    )
}

export default OptionModal