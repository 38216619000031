import React from 'react';
import * as Icon from '../../static/icons/how_it_works/index';
import * as Img from '../../static/images/how_it_works/index';
import ContentCard from './content_card';
import { Button, Select } from 'antd';
import { isMobile } from 'react-device-detect'
import WooCommerceLogo from '../../static/icons/woo_commerce1.svg'
import PrestaShopLogo from '../../static/icons/prestashop1.svg'
import Shopify from '../../static/icons/shopify2.svg'
import Kajabi from '../../static/icons/kajabi2.svg'
import { Grid } from '@material-ui/core'
// import WooComVideo from '../../media/woocommerce.mp4'
import VideoModal from './reusable_content/video_modal';
import * as Presta from '../../static/images/how_it_works/link_stores/prestashop/index';
import * as Shop from '../../static/images/how_it_works/link_stores/shopify/index';
import * as Kaj from '../../static/images/how_it_works/link_stores/kajabi/index';
import Shopify1 from '../../static/img/shopify/shopify1.png'
import Shopify2 from '../../static/img/shopify/shopify2.png'
import NoteCard from '../kyb/forms/noteCard';

const LinkStore = props => {
    
    const ButtonGroup = Button.Group
    const { Option } = Select

    const {
        segments
    } = props;

    const userType = localStorage.getItem('userType');
    const titleText = userType === "ME" ? "Link Stores" : "Integration"
    
    let link_store = segments == 'me_' ? Icon.LinkStore_me_ : 
                            segments == 'gig' ? Icon.LinkStore_gig : 
                                segments == 'sme' ? Icon.LinkStore_sme : 
                                    segments == 'biz' ? Icon.LinkStore_biz: ""

    let play_icon = segments == 'me_' ? Icon.Play_me_ : 
                                    segments == 'gig' ? Icon.Play_gig : 
                                        segments == 'sme' ? Icon.Play_sme : 
                                            segments == 'biz' ? Icon.Play_biz : ""

    const tabBtn = ['PrestaShop', 'WooCommerce', 'Shopify', 'Kajabi']
    const tabBtn1 = ['PrestaShop', 'WooCommerce', 'Shopify']

    const finalTab = userType === 'ME' ? tabBtn1 : tabBtn
    const [activeBtn, setActiveBtn] = React.useState("PrestaShop")
    const [modalState, setModalState] = React.useState(false)
    const baseURL = "https://developers.bux.ph";

    const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
    const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
    const isDell1 = window.screen.width * window.devicePixelRatio === 1366 && window.screen.height * window.devicePixelRatio === 768;

    const smallReso = isSmallReso || isDell || isDell1

    const PrestaShopContent = () => {

        const prestaStep = [
            {
                step: 1,
                img: userType === 'ME' ?  Presta.Step1 : Presta.Step_1,
                instruction: <div>On your BUx Dashboard, Click <b>{titleText}</b>.</div>
            },
            {
                step: 2,
                img: userType === 'ME' ?  Presta.Step2 : Presta.Step_2,
                instruction: <div>Copy the <b>Integration Credentials</b> {"(API Key, Client ID, Client Secret)"}.</div>
            },
            {
                step: 3,
                img: Presta.Step3,
                instruction: <div>Click <b>Download link</b> beside the PrestaShop logo.</div>
            },
            {
                step: 4,
                img: Presta.Step4,
                instruction: <div>Switch to your <b>PrestaShop</b> website, then go to <b>Modules</b>.</div>
            },
            {
                step: 5,
                img: Presta.Step5,
                instruction: <div><b>Upload new module</b> by selecting the file you downloaded from BUx, then click <b>Configure</b>.</div>
            },
            {
                step: 6,
                img: Presta.Step6,
                instruction: <div>Paste the <b>Integration Credentials</b> from BUx, then click <b>Save</b> to complete.</div>
            },
        ]
        return(
            <div className={isMobile ? 'top-12' : 'top-64'}>
                <div align="center">
                    <img src={PrestaShopLogo} alt="presta" />
                </div>
                
                {
                    prestaStep.map((item, key) => {
                        return(
                            <div align="center">
                                {
                                    item.img &&
                                    <div className={isMobile ? 'top-32' : 'top-64'}>
                                        <img style={{width: isMobile && '250px'}} src={item.img} alt={`step${item.step}`}/>
                                    </div>
                                }
                                {
                                    isMobile ?

                                    <Grid container className="top-20">
                                        <Grid item xl={1} lg={2} md={3} sm={3} xs={3}>
                                            <div className={
                                                `step-div`
                                            }>
                                                Step {item.step}
                                            </div>
                                        </Grid>
                    
                                        <Grid item xl={11} lg={10} md={9} sm={9} xs={9}>
                                        
                                            <div className={!isMobile && "top-12"} style={{color: "#000000"}} align="left">
                                                {item.instruction}
                                            </div>
                                        </Grid>
                                    </Grid>

                                    :

                                    <div className="flex-center top-20">
                                        <div className="step-div right-8">
                                            Step {item.step}
                                        </div>
                                        <div className="liveColor">
                                            {item.instruction}
                                        </div>
                                    </div>
                                }
                    
                            </div>
 
                        )
                    }, [])
                }

            </div>
        )
    }

    const WooCommerceContent = () => {

        const wooStep = [
            {
                step: 1,
                instruction: <div>On your Dashboard, Click <b>Integration</b>.</div>
            },
            {
                step: 2,
                instruction: <div>Copy the <b>API Key, Client ID, Client Secret</b>.</div>
            },
            {
                step: 3,
                instruction: <div>Switch to your <b>WooCommerce</b> website, Select <b>BUx</b> in WooCommerce dashboard.</div>
            },
            {
                step: 4,
                instruction: <div>Paste the <b>API key, Client ID, Client Secret</b> then <b>save changes</b> to complete.</div>
            },
        ]
        return(
            <div className={isMobile ? 'top-12' : 'top-64'}>

                <div align="center" >
                    <img src={WooCommerceLogo} alt="woo" />
                </div>

                <div align="center" className={
                    isMobile ? "top-24 bottom-32" : "top-48 bottom-64"
                }>
                    <button className="play-video" onClick={() => setModalState(true)}>
                        <img src={play_icon} alt="play" />
                    </button>
                  
                </div>

                <VideoModal 
                    source={baseURL + '/woocommerce.mp4'} 
                    openModal={modalState} 
                    setModalState={setModalState}
                    segments={segments} 
                    smallReso={smallReso} />
                
                {
                    wooStep.map((item, key) => {
                        return(
                            <Grid container className="top-20">
                                <Grid item xl={1} lg={2} md={3} sm={3} xs={3}>
                                    <div className={
                                        `step-div`
                                    }>
                                        Step {item.step}
                                    </div>
                                </Grid>
            
                                <Grid item xl={11} lg={10} md={9} sm={9} xs={9}>
                                    <div className={!isMobile && "top-12"} style={{color: "#000000"}}>
                                        {item.instruction}
                                    </div>
                                </Grid>
                            </Grid>
                        )
                    }, [])
                }

            </div>
        )
    }
    
    const ShopifyContent = () => {
        const shopifyStep = [
            {
                img: Shopify1,
            },
            {
                step: 2,
                img: Shopify2,
                instruction: 
                <div>
                    Add <b>Read and Write</b> admin api permissions for the custom app for <b>Order Editing and Orders</b>.
                </div>
            },
            {
                step: 3,
                img: Shop.Step3,
                instruction: 
                    <div>
                        Go to <b>Checkout Settings</b> and under <b>Orders Processing</b> add the following code to additional script.
                    </div>

            },
            {
                step: 4,
                img: Shop.Step4,
                img2: Shop.Step5,
                text: 'Copy your Client ID from Integration Credentials to “<BUX_CLIENT_ID>”',
                instruction:
                <div align="left">
                    Add webhook for Order Creation, JSON format. 
                    Pointing to the url: 
                    <div className="left-4 link-text">"https://api.bux.ph/v1/api/shopify/{"<client_id>"}/generate/"</div>
                    <div>
                        (Replace {"<client_id>"} with your CLIENT_ID on integration credentials). 
                    </div>
                    <div>
                        Save the verification token below for the next step.
                    </div>
                </div>
            },
            {
                step: 5,
                img: userType === 'ME' ? Presta.Step1 : Presta.Step_1,
                instruction: 
                    <div>
                        On your BUx Dashboard, Click <b>{titleText}</b>.
                    </div>

            },
            {
                step: 6,
                img: Shop.Step7,
                instruction: 
                    <div align="left">
                        Click the <b>Shopify</b> card and enter the following credentials:
                        <ul>
                            <li>
                                APP API Key - APP API key of the BUx Private App.
                            </li>
                            <li>
                                APP Password - APP Password of the BUx Private App.
                            </li>
                            <li>
                            Webhook Token - Verification token indicated on the webhook page.
                            </li>
                        </ul>
                    </div>

            },

        ]
        return(
            <div className={isMobile ? 'top-12' : 'top-64'}>
                <div align="center">
                    <img src={Shopify} alt="shopify" />
                </div>
                {
                    shopifyStep.map((item, key) => {
                        return(
                            <div align="center">
                                {
                                    item.img &&
                                    <div className={isMobile ? 'top-32' : 'top-64'}>
                                        <img style={{width: isMobile ? '250px' : '480px'}} src={item.img} alt={`step${item.step}`}/>
                                    </div>
                                }
                

                                {
                                    item.img2 &&
                                    <div>
                                        <div className="top-20 liveColor">
                                            {item.text}
                                        </div>
                                        <div className={isMobile ? 'top-32' : 'top-64'}>
                                            <img style={{width: isMobile ? '250px' : '480px'}} src={item.img2} alt={`step${item.step}`}/>
                                        </div>
                                    </div>
                                }
                                

                                {
                                    isMobile ?

                                    <Grid container className="top-20">
                                        <Grid item xl={1} lg={2} md={3} sm={3} xs={3}>
                                            {
                                                item.step &&
                                                <div className={
                                                    `step-div`
                                                }>
                                                    Step {item.step}
                                                </div>
                                            }
                                        </Grid>
                    
                                        <Grid item xl={11} lg={10} md={9} sm={9} xs={9}>
                                        
                                            <div className={!isMobile && "top-12"} style={{color: "#000000"}} align="left">
                                                {item.instruction}
                                            </div>
                                        </Grid>
                                    </Grid>

                                    :

                                    <div className="flex-center top-20">
                                        {
                                            item.step && 
                                            <div className="step-div right-8">
                                                Step {item.step}
                                            </div>
                                        }
                                      
                                        <div className="liveColor">
                                            {item.instruction}
                                        </div>
                                    </div>
                                }
                    
                            </div>
 
                        )
                    }, [])
                }
            </div>

        )
    }

    const KajabiContent = () => {

        const kajabiStep = [
            {
                step: 1,
                img: Kaj.Step1,
                text: 'Note: Make sure the currency is PHP - Philippine Peso',
                instruction: <div>On your <b>kajabi</b> website, create <b>Kajabi Offer</b> and choose <b>One-time Payment.</b></div>
            },
            {
                step: 2,
                img: Kaj.Step2,
                instruction: <div>Open the <b>Offer</b> and click <b>Get Link</b>.</div>
            },
            {
                step: 3,
                img: Kaj.Step3,
                instruction: <div>Copy the <b>Offer Link</b> and paste it in notes.</div>
            },
            {
                step: 4,
                img: Kaj.Step4,
                instruction: <div>Go to <b>More</b> and click <b>Webhooks</b>.</div>
            },
            {
                step: 5,
                img: Kaj.Step5,
                instruction: <div>
                            Copy the <b>Activation Webhook</b> and paste it in notes.
                        </div>
            },
            {
                step: 6,
                img: userType === 'ME' ? Presta.Step1 : Presta.Step_1,
                instruction: <div>
                            On your BUx Dashboard, Click <b>{titleText}</b>.
                        </div>
            },
            {
                step: 7,
                img: Kaj.Step7,
                instruction: <div>
                                Click <b>Go to offers</b> beside the Kajabi logo.
                            </div>
            },
            {
                step: 8,
                img: Kaj.Step8,
                instruction: <div align="left">
                                On your <b>Kajabi Offers</b>, Click <b>+ add New</b> and edit offer.
                                <ul>
                                    <li>
                                    Name can be the same as Kajabi for quick reference.
                                    </li>
                                    <li>
                                    Input the Kajabi Offer Link.
                                    </li>
                                    <li>
                                    Input the Kajabi Activation Webhook URL.    
                                    </li>
                                    <li>
                                    Input the URL of the Kajabi Thank You Page (for successful payments).
                                    </li>
                                </ul>
                                Click <b>Save</b>.
                            </div>
            },
            {
                step: 9,
                img: Kaj.Step9,
                instruction: <div>
                                On your <b>Kajabi Offers</b> page, open the <b>Offer Page.</b>
                            </div>
            },
            {
                step: 10,
                img: Kaj.Step10,
                instruction: <div>
                                Add a <b>Custom Block</b> - this is where you'll input the custom BUx Code.
                            </div>
            },
            {
                step: 11,
                img: Kaj.Step11,
                instruction: <div>
                                Copy the <b>Custom Code</b> in BUx and paste it in your <b>Custom Code Block</b> on the Kajabi Offer Page.
                            </div>
            },
            {
                step: 12,
                img: Kaj.Step12,
                instruction: <div align="left">
                                You should be good to go!
                                <ul>
                                    <li>
                                    The custom code (form) in BUx automatically embeds the name and/or email address of the user and the other details so that the customers only click pay then choose a payment option.
                                    </li>
                                    <li>
                                    Feel free to customize the design of the form to match your Kajabi website theme/colors.
                                    </li>
                                    <li>
                                    For easy tracking of BUx Payments/Users in your Kajabi Dashboard, add an Offer Automation.
                                    </li>
                                    <li>
                                    When offer is granted {">"} Then add a tag BUx.
                                    </li>
                                    <li>
                                    This will allow you to filter People based on the BUX tag.
                                    </li>
                                </ul>
                            </div>
            },
        ]
        return(
            <div className={isMobile ? 'top-12' : 'top-64'}>
                <div align="center">
                    <img src={Kajabi} alt="presta" />
                </div>
                
                {
                    kajabiStep.map((item, key) => {
                        return(
                            
                            <div align="center">
                                {
                                    item.img &&
                                    <div className={isMobile ? 'top-32' : 'top-64'}>
                                        <img style={{width: isMobile ? '250px' : '480px'}} src={item.img} alt={`step${item.step}`}/>
                                    </div>
                                }
                       
                                
                                {
                                    isMobile ?

                                    <Grid container className="top-20">
                                        <Grid item xl={1} lg={2} md={3} sm={3} xs={3}>
                                            {
                                                item.step &&
                                                <div className={
                                                item.step > 9 ? "step-div-2" : "step-div"
                                                }>
                                                    Step {item.step}
                                                </div>
                                            }
                                        </Grid>
                    
                                        <Grid item xl={11} lg={10} md={9} sm={9} xs={9}>
                                        
                                            <div className={!isMobile && "top-12"} style={{color: "#000000"}} align="left">
                                                {item.instruction}
                                            </div>
                                        </Grid>
                                    </Grid>

                                    :
                                    
                                    <div>
                                        <div className="flex-center top-20">
                                            <div className="step-div right-8"
                                            style={{
                                                width: item.step > 9 && '11%'
                                            }}>
                                                Step {item.step}
                                            </div>
                                            <div className="liveColor">
                                                {item.instruction}
                                            </div>
                                        </div>
                                        {item.text &&
                                            <div className="top-24 link-text">
                                                {item.text}
                                            </div>
                                        }
                                    </div>
                                }
                    
                            </div>
                        )
                    }, [])
                }

            </div>
        )
    }

    const LinkStoreStep = () => {
        return(
            <div className="top-20" align="left">
                <div className="wc-header">
                    How to integrate
                </div>

                {
                    isMobile && 
                    <div className="top-16">
                        <NoteCard note={
                            <span>
                            To link BUx to your existing ECommerce store, we recommend using your <b>desktop browser</b> to complete the steps below
                            </span>
                        } />
                    </div>
                }


                <div className="top-20">
                    {
                        isMobile ?
                        <div className="top-16">
                            <div style={{
                                color: "rgba(43, 45, 50, 0.8)",
                                fontSize: 12,
                                fontWeight: 400,
                            }}>
                                ECommerce store
                            </div>
                            <Select
                                size="large"
                                placeholder="Stores"
                                value={activeBtn}
                                style={{width:'100%'}}
                                onChange={(e) => {
                                    setActiveBtn(e);
                                }}
                            >
                                {
                                    finalTab.map((item, key) => {
                                        return(
                                            <Option key={key} value={item}>
                                                {item}
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>

                        :

                        <ButtonGroup>
                            {
                                finalTab.map((item, key) => {
                                    return(
                                        <button className={`
                                        ${activeBtn === item ? `btn--${segments} btn-height` : `group-btn`}`}
                                        onClick={() => setActiveBtn(item)}
                                        >
                                            {item}
                                        </button>
                                    )
                                }, [])
                            }
                        </ButtonGroup>
                    }

                </div>

                <div>
                    {
                        activeBtn === "PrestaShop" ?
                        <PrestaShopContent />
                        :
                        activeBtn === "WooCommerce" ?
                        <WooCommerceContent />
                        :
                        activeBtn === "Shopify" ? 
                        <ShopifyContent />
                        :
                        activeBtn === "Kajabi" ?
                        <KajabiContent />
                        :
                        ""
                    }
                </div>
            </div>
        )
    }


    return (
        <div>

            <ContentCard step={5}
                icon={link_store}
                image={Img.LinkStore}
                title={"Link Store"}
                content={"This tab allows you integrate BUx to ECommerce stores and other platforms via BUx API or plugins."}
                hasAdditional={true} 
                additional_content={<LinkStoreStep />}/>
            
        </div>
    )
}

export default LinkStore;