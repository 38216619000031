import { SpinActionTypes } from "./spinAction";
const initialState = {
  showSpin: false,
};
export default function spinReducer(state = initialState, action) {
  switch (action.type) {
    case SpinActionTypes.SHOW_SPIN:
      return {
        ...state,
        showSpin: true,
      };
    case SpinActionTypes.HIDE_SPIN:
      return initialState
    default:
      return initialState;
  }
}