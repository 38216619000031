import React from 'react';
import { Dropdown, Icon, Input, Menu, message, Typography, Table } from 'antd';
import endorsementServices from './endorsementServices';
import crypto from 'crypto';

const SearchById = (props) => {
    const [searchId, setSearchId] = React.useState(null);
    const [requestToken, setRequestToken] = React.useState(null);
    const [reqReturnToken, setReqReturnToken] = React.useState(null);
    const [totalCount, setTotalCount] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [tableData, setTableData] = React.useState(null);
    const [columns, setColumns] = React.useState(null);

    const PRColumns = [
        {
          title: 'Trans Id',
          dataIndex: 'id',
        },
        {
          title: 'Reference no.',
          render: (record) => <div><Typography>{record.code ? record.code : "Pending"}</Typography><Typography>{record.description}</Typography></div>
        },
        {
          title: 'Business Name',
          dataIndex: 'business_name',
        },
        {
          title: 'Date generated',
          dataIndex: 'timestamp',
        },
        {
          title: 'Expires after',
          dataIndex: 'expire_at',
        },
        {
          title: 'Amount(₱)',
          dataIndex: 'amount',
        },
        {
          title: 'Owner',
          dataIndex: 'owner',
        },
        {
          title: 'Action',
          render: (record) => (
            <Dropdown overlay={() => <Menu><Menu.Item><a onClick={()=>handleEndorseRow(record.id)}>Delete</a></Menu.Item></Menu>} placement="bottomCenter" trigger='click'>
              <Icon type="ellipsis" />
            </Dropdown>
          )
        }
      ]
    
      const PayoutColumns = [
        {
          title: 'Trans ID',
          dataIndex: 'id',
        },
        {
          title: 'Destination',
          dataIndex: 'account_number',
          render: (text, record) => (
            <div>
              <a>{record.account_number}</a>
              <div >{record.bank}<br/>{record.account_name}</div>
            </div>
          )
        },
        {
          title: 'Payment Date',
          dataIndex: 'date_paid',
        },
        {
          title: 'Status',
          dataIndex: 'status',
        },
        {
          title: 'Remarks',
          dataIndex: 'remarks',
        },
        {
          title: 'Amount (PHP)',
          dataIndex: 'amount',
        },
        {
          title: 'Owner',
          dataIndex: 'owner',
        },
        {
          title: 'Action',
          render: (record) => (
            <Dropdown overlay={() => <Menu><Menu.Item><a onClick={()=>handleEndorseRow(record.id, record.status, record.amount)}>Endorse</a></Menu.Item></Menu>} placement="bottomCenter" trigger='click'>
              <Icon type="ellipsis" />
            </Dropdown>
          )
        }
    ]

    const handleEndorseRow = (id, status, amount) => {
        // setKeyToEndorse({payment_id: id, approval_step: 'PE', transaction_type: 'PO', previous_status: 'PE'});
        // setShowEndorseModal(true);
      }

    const generateToken = () => {
        let generatedRequestToken = crypto.randomBytes(16).toString('hex');
        setRequestToken(generatedRequestToken);
        return generatedRequestToken
    }

    const success = (msg) => {
        message.success(msg);
    };
    
    const errorPrompt = (msg) => {
        message.error(msg);
    };

    const getData = async (searchVal) => {
        setIsLoading(true);
        try {
          let res;
          if(props.selected === 'Payment Request') {
            res = await endorsementServices.getOrders(searchVal, '1', 'Paid', '', 'All', '', '', 'All', generateToken());
          } else {
            res = await endorsementServices.getPayout(searchVal, '1', 'All', 'All', '', '', 'All');
          }
          if(res.results) {
            setTableData(res.results);
            setTotalCount(res.total);
          } else if(res.orders) {
            setTableData(res.orders);
            setTotalCount(res.summary.page_total);
            setReqReturnToken(res.token);
          }
           else {
            errorPrompt(res);
          }
          console.log('endorsement_DATA', res);
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
          errorPrompt('Please try again');
        }
      }
    

    const handleSearch = (e) => {
        setSearchId(e.target.value);
        getData(e.target.value);
    }

    const resetProps = () => {
        setSearchId('');
    }

    React.useEffect(() => {
        if(props.selected){
            getData(props.selected);
        }
        resetProps();
        setColumns([]);
        setTimeout(()=> {
            setColumns(props.selected==='Payment Request'?PRColumns : PayoutColumns);
        },100)
    },[props.selected])

    return (
        <div>
            <Input
                placeholder="Search by Id"
                style={{ width: '200px', height: '40px', borderRadius:'4px' }}
                suffix={<Icon type="search" style={{fontSize:'18px'}}/>}
                onChange={handleSearch}
                value={searchId}
            />
            <div hidden={!searchId} style={{padding: '20px 0'}}>
                <Table 
                    rowClassName="table-row-light"
                    columns={columns}
                    dataSource={tableData ? tableData : []}
                    pagination={{pageSize:10}}
                    loading={isLoading || props.loading}
                    pagination={false}
                />
            </div>
        </div>
    )
}

export default SearchById;