import React from 'react'
import Credentials from './credentials'
import Plugins from './plugins'
import {isMobile} from 'react-device-detect'

const PluginsMain = ({segments, creds, resetSecretSuccess}) => {
	const apiKey = creds.api_key ? creds.api_key : '';
	const apiSecret = creds.api_secret ? creds.api_secret : '';
	const clientID = creds.client_id ? creds.client_id : '';

    return(
        <div>
            <Credentials segments={segments} apiKey={apiKey} apiSecret={apiSecret} clientID={clientID} resetSecretSuccess={resetSecretSuccess}/>
            <Plugins segments={segments} creds={creds}/>
        </div>
    )
}

export default PluginsMain