import React from 'react';
import {history} from '../../store/history'
import {Card, Icon, Divider, Button, Checkbox, Input, Select} from 'antd'
import AppBar from '../appBar/AppBar'



const { Option } = Select;

class CustomerDetails extends React.Component {

    nameStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '32px',
        color: '#000000',
        marginBottom: '5px'
    }

    transactionStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000000'
    }
    
    btn = {
        width: '150px',
        height: '48px',
        
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        marginBottom: '30px'
    }

    headerStyle = {
        
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#000000'
    }
    labelStyle = {
        
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#2B2D33',
    }
      
    render(){
        return(
            <div>
                <AppBar />
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{paddingTop: '38px', paddingLeft: '52px', paddingRight: '52px', width: '60%'}}>
                        <div style={this.nameStyle}>Juan Alagas</div>
                        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
                            <div style={this.transactionStyle}>Last transaction on 24 July 12:09pm</div>
                            <div style={this.transactionStyle}>No. of transactions: 3</div>
                        </div>
                        <div style={{marginBottom: '10px'}}><span style={this.headerStyle}>Basic information</span> </div>
                        <Card style={{marginBottom: '20px'}}>
                            <div  style={{display: 'flex', width:'100%', marginBottom: '10px'}}>
                                <div style={{width: '49%', marginRight: '2%'}}>
                                <div style={this.labelStyle}>First name</div>
                                <Input style={{width: '100%', height: '40px'}}/>
                                </div>
                                <div style={{width: '49%'}}>
                                <div style={this.labelStyle}>Last name</div>
                                <Input style={{width: '100%', height: '40px'}}/>
                                </div>
                            </div>
                            <div  style={{display: 'flex', width:'100%', marginBottom: '10px'}}>
                                <div style={{width: '49%', marginRight: '2%'}}>
                                <div style={this.labelStyle}>Mobile Number</div>
                                <Input style={{width: '100%', height: '40px'}}/>
                                </div>
                                <div style={{width: '49%'}}>
                                <div style={this.labelStyle}>Email address</div>
                                <Input style={{width: '100%', height: '40px'}}/>
                                </div>
                            </div>
                        </Card>
                        <div style={{marginBottom: '10px'}}><span style={this.headerStyle}>Shipping address</span> </div>
                        <Card style={{marginBottom: '20px'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                                <div>
                                    <Checkbox defaultChecked onChange={(value)=>this.onChange(value)}>Set as primary</Checkbox>
                                </div>
                                <Icon type="delete" />
                            </div>
                            <div  style={{display: 'flex', width:'100%', marginBottom: '10px'}}>
                                <div style={{width: '100%'}}>
                                <div style={this.labelStyle}>Address</div>
                                <Input style={{width: '100%', height: '40px'}}/>
                                </div>
                            </div>
                            <div  style={{display: 'flex', width:'100%', marginBottom: '10px'}}>
                                <div style={{width: '49%', marginRight: '2%'}}>
                                    <div style={this.labelStyle}>Country</div>
                                    <Select
                                    showSearch
                                    style={{ width: '100%'}}
                                    size="large"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    >
                                    <Option value="Philippines">Philippines</Option>
                                    </Select>
                                    </div>
                                    <div style={{width: '49%'}}>
                                    <div style={this.labelStyle}>Province</div>
                                    <Select
                                    showSearch
                                    style={{ width: '100%'}}
                                    size="large"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    >
                                    <Option value="Metro Manila">Metro Manila</Option>
                                    </Select>
                                    </div>
                                </div>
                                <div  style={{display: 'flex', width:'100%', marginBottom: '10px'}}>
                                    <div style={{width: '49%', marginRight: '2%'}}>
                                    <div style={this.labelStyle}>City</div>
                                    <Select
                                    showSearch
                                    style={{ width: '100%'}}
                                    size="large"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    >
                                    <Option value="Caloocan">Caloocan</Option>
                                    <Option value="Las Pinas">Las Pinas</Option>
                                    <Option value="Makati">Makati</Option>
                                    <Option value="Malabon">Malabon</Option>
                                    <Option value="Mandaluyong">Mandaluyong	</Option>
                                    <Option value="Manila">Manila</Option>
                                    <Option value="Marikina">Marikina</Option>
                                    <Option value="Muntinlupa">Muntinlupa</Option>
                                    <Option value="Navotas">Navotas</Option>
                                    <Option value="Parañaque">Parañaque</Option>
                                    <Option value="Pasay">Pasay</Option>
                                    <Option value="Pasig">Pasig</Option>
                                    <Option value="Pateros">Pateros</Option>
                                    <Option value="Quezon City">Quezon City</Option>
                                    <Option value="San Juan">San Juan</Option>
                                    <Option value="Taguig">Taguig</Option>
                                    <Option value="Valenzuela">Valenzuela</Option>
                                    </Select>
                                    </div>
                                    <div style={{width: '49%'}}>
                                    <div style={this.labelStyle}>Postal / ZIP code (optional)</div>
                                    <Input style={{width: '100%', height: '40px'}}/>
                                </div>
                            </div>
                        </Card>
                        <Button style={{width: '100%'}}>Add new address</Button>
                        <Divider></Divider>
                        <div style={{display: 'flex', justifyContent:'space-between'}}>
                            <Button style={this.btn} onClick={ () => history.push('/orders')}>Cancel</Button>
                            <Button type="primary" style={this.btn} onClick={ () => history.push('/orders')}>Submit</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CustomerDetails