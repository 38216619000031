import React,{ useState } from "react";
import { Card, Typography, Input, Button, Icon } from "antd";
import eonImage from "../../static/images/eon_text_image.svg";
import OTPInput, { ResendOTP } from 'otp-input-react';
import { history } from '../../store/history';
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Visibility from "@material-ui/icons/Visibility";
import { IconButton } from "@material-ui/core";

const UpdateAtmPin = (step) => {
  const [pin, setPin] = useState(null);
  const [pinVisible, setPinVisible] = useState(true);
  // const [step, setStep] = useState(1);

  const togglePinSecure = () => {
    setPinVisible(!pinVisible);
  }

  const handleChange = (event) => {
    setPin(event)
  }

  const handleClickNext = () => {
    setPin(null);
    history.push('/update_atm_pin/step_2')
  }
  
  return (
    <div style={styles.root} align='center'>
      <div style={styles.title}>
        <img src={eonImage} />
        <strong style={styles.regularFont}>My Card</strong>
      </div>
      <Card style={styles.main}>
        <Typography style={styles.mainTitle}>Current ATM PIN</Typography>
        <Typography style={styles.mainSubtitle}>
          Change your ATM’s PIN directly from 
          here. Please enter current ATM PIN
        </Typography>
        <div style={{ padding: '20px 0px 20px 0px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <OTPInput
          value={pin}
          onChange={(event) => handleChange(event) }
          autoFocus
          OTPLength={6}
          otpType="number"
          disabled={false}
          secure={pinVisible}
          // onblur={{backgroundColor: '#000000'}}
          inputStyles={{
            height: 70,
            width: 34,
            fontSize: "32px",
            border: '1px solid #E6EAF0',
            backgroundColor: '#E6EAF0',
            margin: '0 10px 0 10px',
            borderRadius: 4,
            color: '#000000'
          }}
        />
        { step >= 2 ? 
          <div style={{ display: 'flex', marginBottom: '-20px', padding: '20px 0px 0px 0px', alignItems: 'center'}}>
            <IconButton
              onClick={togglePinSecure}
            >
              {pinVisible ? <Visibility /> : <VisibilityOutlinedIcon />}
            </IconButton>
            <Typography>Show PIN</Typography>
          </div>
        : null }
        </div>
        <Button style={{...styles.nextBtn, opacity: `${pin && pin.length == 6 ? '100%' : '50%'}`}} disabled={ pin && pin.length == 6 ? false : true }
          onClick={handleClickNext}
        >Next</Button>
        <Button style={styles.cancelBtn} onClick={() => history.push('/card') }>Cancel</Button>
      </Card>
    </div>
  )
}

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'   
  },
  title: {
    width: 540,
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    padding: '60px 0px 24px 0px',
  },
  regularFont: {
    fontSize: 28,
    color: '#000000',
  },
  main: {
    borderRadius: 10,
    padding: '0px 48px',
    width: 540
  },
  mainTitle: {
    fontSize: 28,
    color: '#000000',
    padding: '0 0 10px 0'
  },
  mainSubtitle: {
    fontSize: 16,
    color: '#000000',
    padding: '10px 0 16px 0'
  },
  nextBtn: {
    margin: '10px 0px 0px 0px',
    height: 48,
    width: '100%',
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    borderRadius: 10,
    fontSize: 14,
    fontWeight: '600',
  },
  cancelBtn: {
    margin: '20px 0px 0px 0px',
    height: 48,
    width: '100%',
    color: '#F5922F',
    borderRadius: 10,
    fontSize: 14,
    fontWeight: '600',
    borderColor: '#F5922F',
  }
}

export default UpdateAtmPin;