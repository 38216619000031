import React from 'react'
import pricingStyle from '../../static/css/Pricing';
import {Grid} from '@material-ui/core'
import {Icon} from 'antd'
import {SevenEleven, Instapay, PesoNet} from '../../static/icons/index'
import { backToTop } from '../constants/constants'

const MobilePaymentMethod = props => {
    
    const p = pricingStyle();
    
    const payouts = [
        {
            icon: Instapay,
            limit: '₱ 50,000 per day',
            funds: 'real-time'
        },
        {
            icon: PesoNet,
            limit: 'no limit',
            funds: 'Below ₱ 50,000.00 - real time'
        },
    ]

    return(
        <div>
            <div align="left" className={p.subDes} hidden={localStorage.getItem('userType') !== 'CO'}>
            List of methods for Cashout. Transaction fees are <span className={`text-${props.segments} wc-header`}>FREE</span>.
            </div>
            {
                payouts.map((item => {
                    return(
                        <div className={p.cards}>
                            <div className={p.cardHeader} align='center'>
                                <img src={item.icon} />
                            </div>
                            
                            <Grid container className={p.cardInfo}>

                                <Grid item xs={7}>
                                    <div className={p.cardPlaceholder}>
                                        Transaction Limit
                                    </div>
                                </Grid>

                                <Grid item xs={5}>
                                    <div className={p.cardVal} align='right'>
                                        {item.limit}
                                    </div>
                                </Grid>
                            
                            </Grid>

                            <Grid container className={p.cardInfo}>

                                <Grid item xs={7}>
                                    <div className={p.cardPlaceholder}>
                                        Funds Availability
                                    </div>
                                </Grid>

                                <Grid item xs={5}>
                                    <div className={p.cardVal2} align='right'>
                                        {item.funds}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    )
                }))
            }

            <div className={p.compleShareDiv} align='left'>
                <button className={`outline-btn--${props.segments} btn-height btn-mobile-width`} onClick={backToTop}><Icon type="up" style={{marginRight: '8px' }}/> Back to Top</button>
            </div>


        </div>
    )
}

export default MobilePaymentMethod;