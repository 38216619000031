import React from 'react';
import { Button, Icon, Input, Modal, Typography } from 'antd';
import { ThankYou } from '../../static/images/vouchers';

const max_timer = 5;

const ThankYouModal = (props) => {
    const [counter, setCounter] = React.useState(max_timer);

    const calculateDuration = (duration) => {
        const minutes = Math.floor(duration / 60).toString();
        const seconds = Math.floor(duration % 60);
        let seconds_str = seconds.toString();

        if (seconds < 10) {
            seconds_str = "0" + seconds.toString();
        }

        return parseInt(seconds_str);
    };

    React.useEffect(() => {

        const intervalId = setInterval(() => {
            if (counter > 0) {
                setCounter(counter - 1);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [counter]);

    React.useEffect(() => {
        setCounter(max_timer);
    },[props.visible])

    return (
        <Modal
            maskClosable
            closable={false}
            visible={props.visible}
            footer={false}
            closeIcon={null}
            width={376}
            destroyOnClose
            bodyStyle={{ padding: 0, borderRadius: '8px' }}
        >
            <img alt='thank you' src={ThankYou} />
            <div style={{ padding: '24px' }}>
                <Typography style={styles.title}>Thank you</Typography>
                <Typography style={styles.msg}>By making your voice heard, you help us improve our product. </Typography>
                <Typography style={styles.timer}>logging out after {calculateDuration(counter)} secs..</Typography>
            </div>
        </Modal>
    )
}

const styles = {
    title: {
        color: '#254A76',
        fontSize: 24,
        fontWeight: '700',
        textAlign: 'center'
    },
    msg: {
        color: '#000000',
        fontSize: 16,
        fontWeight: '400',
        textAlign: 'center',
        padding: '12px 0'
    },
    timer: {
        color: 'rgba(43, 45, 50, 0.64)',
        fontWeight: '400',
        fontSize: '16px',
        textAlign: 'center'
    },
    btn: {
        height: 48,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 4,
        width: '48%',
    },
    input: {
        padding: '11px 16px 11px 16px',
        fontSize: 16,
        height: 48,
        margin: '4px 0px 12px 0px',
        color: '#2B2D33'
    },
    btnCancel: {
        height: 40,
        width: '48%',
        borderRadius: 4,
        fontSize: 14,
        fontWeight: '600',
    },
    btnContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '24px'
    }
}

export default ThankYouModal;