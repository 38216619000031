import React from 'react'
import {Modal, DatePicker, Icon, Select, Typography} from 'antd'
import moment from 'moment'

const FilterDateModal = props => {

    const {
        applyFilter,
        segments,
        visibility,
        close,
        dateFilterType,
        setDateFilterType,
        startMoment,
        setStartMoment,
        endMoment,
        setEndMoment,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        endOpen,
        setEndOpen,
    } = props

    const {Option} = Select
    

    const disabledStartDate = startValue => {
        if (!startValue || !endMoment) {
          return false;
        }
        return startValue && startValue > moment().endOf('day');
    };
    const disabledEndDate = endValue => {
        if (!endValue || !startMoment) {
          return false;
        }
        return endValue.valueOf() <= startMoment.valueOf();
    }; 

    const onStartChange = value => {
        if(value != null){
          setStartMoment(value)
          setStartDate(value.format("MM-DD-YYYY"));
        }
        else{
          setStartMoment(null);
          setStartDate("");
        }
    }
    const onEndChange = value => {
        if(value != null && startDate != ""){
          setEndMoment(value);
          setEndDate(value.format("MM-DD-YYYY"));
        }
        else{
          setEndMoment(null);
          setEndDate("");
        }
    }
    const handleStartOpenChange = open => {
        if (!open) {
          setEndOpen(true);
        }
    };
    const handleEndOpenChange = open => {
        setEndOpen(open)
    };

    return(
        <Modal
            visible={visibility}
            footer={null}
            onCancel={()=>close()}>
            <Typography style={style.header}>Filter Date</Typography>
            <div>
                <div style={style.label}>Date type</div>
                <Select 
                    size="large"
                    value={dateFilterType}
                    onChange={(e)=>setDateFilterType(e)}
                    style={{width: '100%', zIndex: 1, color: '#000'}}
                    >
                    <Option value="created">Date created</Option>
                    <Option value="approved">Date approved</Option>
                </Select> 
            </div>
            <div style={{display: 'flex', width: '100%', margin: '28px 0px 28px 0px'}}>
                <div style={{width: '50%'}}>
                    <div style={style.label}>Start Date</div>
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledStartDate}
                        value={startMoment}
                        size="large"
                        picker="month"
                        onChange={onStartChange}
                        onOpenChange={handleStartOpenChange}
                        placeholder='Start Date'
                        style={style.datePic}
                        suffixIcon={<div style={style.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                </div>
                <span style={{ padding: '30px 8px 0px 8px' }}>-</span>
                <div style={{width: '50%'}}>
                    <div style={style.label}>End Date</div>
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledEndDate}
                        value={endMoment}
                        size="large"
                        picker="month"
                        onChange={onEndChange}
                        onOpenChange={handleEndOpenChange}
                        placeholder='End Date'
                        open={endOpen}
                        style={style.datePic}
                        suffixIcon={<div style={style.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                </div>
            </div>
            <div align="right">
                <button
                    onClick={()=>{
                        setStartDate("")
                        setStartMoment("")
                        setEndDate("")
                        setEndMoment("")
                        close()
                    }}
                    className={`outline-btn--${segments}`} 
                    style={{...style.applyBtn, marginRight: '10px'}}
                >Cancel</button>

                <button
                    onClick={()=>applyFilter()}
                    className={`btn--${segments}`} 
                    style={style.applyBtn}
                >Apply</button>
            </div>
        </Modal>
    )
}

const style = {
    header: {
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '20px',
        color: '#2B2D33',
        marginBottom: '20px'
    },
    label:{
        fontSize: '14px',
        fontWeight: '400',
        color: 'rgba(43, 45, 50, 0.8);',
    },  
    applyBtn: {
        zIndex: 1, 
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: '92px',
        cursor: 'pointer',
    },
    datePic: {
        zIndex: 1, 
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: '100%',
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
}

export default FilterDateModal