import React from 'react';
import { Icon, Input, message, Modal, Pagination, Table, Typography } from 'antd';
import fraudServices from '../kybServices';

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        render: (text, record) =>
            <div>
                <Typography style={styles.receiverName}>{record.id}</Typography>
                <Typography style={styles.receiverEmail}>{record.user}</Typography>
            </div>
    },
    {
        title: 'Business Name',
        dataIndex: 'business_name',
        render: (text) =>
            <div>
                <Typography style={styles.receiverEmail}>{text}</Typography>
            </div>
    },
    {
        title: 'Email',
        dataIndex: 'email',
        render: (text) => <Typography style={styles.receiverEmail}>{text}</Typography>
    },
    {
        title: 'Mobile Number',
        dataIndex: 'mobile_number',
        render: (text) => <Typography style={styles.receiverEmail}>+63{text}</Typography>
    },
]

const MoveTagModal = (props) => {
    const [searchVal, setSearchVal] = React.useState('');
    const [selectedRowKeys, setSelectedRowKeys] = React.useState(null);
    const [tableData, setTableData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [params, setParams] = React.useState([]);
    const segments = localStorage.getItem('segments');
    const { isLoading, selectedRowData } = props;
    const selectedEntryId = (selectedRowData && selectedRowData.id) || null;
    const [currentPage, setCurrentPage] = React.useState(1);

    const tableCheckbox = {
        type: 'radio',
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => { setParams({ submission_id: selectedRows[0].id, entry_id: selectedEntryId }); setSelectedRowKeys(selectedRowKeys) }
    }

    const handleChangeSelect = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value);
        fetchKyb(1, "Alltime", e.target.value, "All", "All");
        setCurrentPage(1);
    }

    const tagToPending = async () => {
        if(!selectedRowKeys) {
            return false;
        }
        setLoading(true);
        try {
            const res = await fraudServices.tagToPending(params);
            console.log('TAGGED', res);
            if(res && res.status === "success") {
                message.success('Entry successfully tagged and moved to pending')
                props.taggedSuccess();
            } else {
                message.error('Tagging failed. Try again')
            }
        } catch (error) {
            console.log(error);
            message.error('Error, try again')
        }
        setLoading(false)
    }

    const changePage = (page, pageSize) => {
        setCurrentPage(page)
        fetchKyb(page, "Alltime", searchVal, "All", "All");
      }

    const fetchKyb = async (page, status, search, account_type, segments) => {
        setLoading(true);
        try {
            const res = await fraudServices.getKybToTag(page, status, search, account_type, segments);
            console.log(res);
            if (res && res.results) {
                setTableData(res);
            } else {
                message.error('Error, try again');
            }
        } catch (error) {
            console.log(error);
            message.error('Error, try again')
        }
        setLoading(false);
    }

    const resetFields = () => {
        setCurrentPage(1);
        setSearchVal("");
        setSelectedRowKeys(null);
    }

    React.useEffect(() => {
        if (props.visible) {
            fetchKyb(1, "Alltime", "", "All", "All");
        }
        resetFields();
    }, [props.visible])

    return (
        <Modal
            maskClosable
            onCancel={() => { props.onClose(); }}
            visible={props.visible}
            footer={false}
            closeIcon={<Icon type="close" className={`link-text--${segments}`} />}
            width={912}
            destroyOnClose
        >
            <Typography style={styles.title}>{(selectedRowData && selectedRowData.business_name) || 'N/A'}</Typography>
            <Typography style={styles.subTitle}>Tag a created account with no KYB to this merchant</Typography>
            <Typography style={styles.msg}>By tagging this account, this will be automatically moved to Pending Application or Review Application tab.</Typography>
            <div style={{ margin: '12px 0' }}>
                <Input
                    placeholder="Search"
                    style={{ width: '200px', height: '40px', borderRadius: '4px', padding: '0 8px 0 0px' }}
                    suffix={<Icon type="search" style={{ fontSize: '18px' }} />}
                    onChange={handleSearch}
                    value={searchVal}
                />
            </div>
            <div style={{ marginTop: '12px' }}>
                <Table
                    rowClassName="table-row-light"
                    dataSource={(tableData && tableData.results) || []}
                    columns={columns}
                    pagination={false}
                    rowSelection={{ ...tableCheckbox }}
                    loading={isLoading}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => { window.open(`/submissions_kyb/${record.user}#kyb_form`, 'new_tab')}, // click row
                        };
                    }}
                />
            </div>
            <Pagination
                size='small'
                total={(tableData && tableData.total) || 0}
                defaultPageSize={10}
                pageSize={10}
                defaultCurrent={1}
                current={(tableData && currentPage) || 1}
                onChange={(page, pageSize) => changePage(page, pageSize)}
                style={{ padding: '12px 0 8px 0', textAlign: 'right' }}
            />
            <div style={styles.btnWrapper}>
                <button className={`admin-download-btn padding-lr-16`} onClick={() => props.onClose()} >Cancel</button>
                <button className={`btn--${segments}`} style={{ ...styles.btn, opacity: selectedRowKeys? '1' : '0.5', cursor: selectedRowKeys? 'pointer' : 'not-allowed' }}
                    disabled={!selectedRowKeys || selectedRowKeys === "" || !selectedRowKeys.length}
                    onClick={tagToPending} >
                    Tag and move entry
                </button>
            </div>
        </Modal>
    )
}

const styles = {
    title: {
        color: '#2B2D32',
        fontSize: 24,
        fontWeight: '700',
        textAlign: 'left',
        marginTop: '32px'
    },
    subTitle: {
        color: '#2B2D32',
        fontSize: 18,
        fontWeight: '700',
        textAlign: 'left'
    },
    msg: {
        color: '#2B2D32',
        fontSize: 16,
        textAlign: 'left',
    },
    btn: {
        height: 40,
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 4,
    },
    btnWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '12px',
        marginTop: '12px'
    },
    input: {
        padding: '11px 16px 11px 16px',
        fontSize: 16,
        height: 48,
        margin: '4px 0px 12px 0px',
        color: '#2B2D32'
    },
    receiverName: {
        color: '#2B2D32',
        fontSize: 14,
        fontWeight: '700',
        textAlign: 'left',
    },
    receiverEmail: {
        color: '#2B2D32',
        fontSize: 14,
        fontWeight: '400',
        textAlign: 'left',
        maxWidth: '304px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    amount: {
        fontSize: 14,
        fontWeight: 'bold'
    }
}

export default MoveTagModal;