import React from 'react';
import MetaTag from '../../meta_tag/meta_tag';
import CardBalance from './cardBalance';
import reconActions from '../reconActions';
import { useDispatch, useSelector } from '../../dashboard/react-redux-hooks';
import moment from 'moment';
import ReconTable from './table';
import ButtonGroup from 'antd/lib/button/button-group';
import reconServices from '../reconServices';
import { jsonToCSV } from 'react-papaparse';
import { Button, DatePicker, Typography, Input, Icon, Spin } from 'antd';
import { segmentStroke } from '../../constants/constants';

const ButtonTab = ({rootStyle}) => {
  const dispatch = useDispatch();
  const selected = useSelector(state => state.enterpriseRecon.cardSelectedKey);
  
  const status = ['Accounted', 'Unaccounted'];
  
  const handleClick = (event) => {
    dispatch(reconActions.setCardSelectedKey(event.target.value));
  }
  
  return (
    <div style={rootStyle} >
      <ButtonGroup style={{padding: '20px 0'}}>
        {
          status.map((btn, i) => {
            return (
              <Button
                key={btn}
                value={btn}
                onClick={handleClick}
                style={{...styles.btnGroup, 
                  color: btn === selected? '#FFF' : '#2B2D33', 
                  backgroundColor: btn === selected? `${segmentStroke[0]}` : '#FFF',
                  borderRadius: i === 0? '4px 0 0 4px' : i === 1? '0 4px 4px 0' : 0 }}
                  >
                  {btn}
              </Button>
            )
          })
        }
      </ButtonGroup>
    </div>
  )
}


const TableStats = ({incoming, outgoing, isLoading}) => {
  return (
    <div style={{display: 'flex', backgroundColor: '#FFF'}}>
      <div style={{display: 'flex', padding: '12px 20px'}}>
        <Typography style={styles.tableStatsText}>Incoming Amount</Typography>
        <Typography style={{...styles.tableStatsText, paddingLeft: 8}}>₱ {isLoading?<Spin size='small' />:<span style={{color: '#1DD28B'}}>
          {incoming?incoming.toLocaleString('en-US', {minimumFractionDigits: 2}):'0.00'}
          </span>}</Typography>
      </div>
      <div style={{display: 'flex', padding: '12px 20px'}}>
        <Typography style={styles.tableStatsText}>Outgoing Amount</Typography>
        <Typography style={{...styles.tableStatsText, paddingLeft: 8}}>₱ {isLoading?<Spin size='small' />:<span style={{color: '#E24C4C'}}>
          {outgoing?outgoing.toLocaleString('en-US', {minimumFractionDigits: 2}):'0.00'}</span>}
        </Typography>
      </div>
    </div>
  )
}

const DebitCreditCard = () => {
  const [dateRange, setDateRange] = React.useState({startDate: moment().format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY')});
  const [dateSearch, setDateSearch] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [totalLength, setTotalLength] = React.useState(0);
  const selected = useSelector(state => state.enterpriseRecon.cardSelectedKey);
  const [tableData, setTableData] = React.useState([]);
  const [incoming, setIncoming] = React.useState(0);
  const [outgoing, setOutgoing] = React.useState(0);
  const cc_balance = localStorage.getItem("cc_balance") ? parseFloat(localStorage.getItem("cc_balance")) : 0;
  const segments = localStorage.getItem('segments')

  const exportCsv = async () => {
    let arr = new Array;
    let data;
    let res;
    setIsLoading(true);
    try {
      res = await reconServices.getEnterpriseRecon(returnType(), dateRange['startDate'], dateRange['endDate']);
      if(res&&res.data){
        data = res.data;
      }
      console.log(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }

    data&&data.map((item,i) => {
      if(returnType()==='accounted'){
        arr.push({
          "Reference Code": item.ref_code,
          "Total Amount": item.amount,
          "Receivable": item.receivable,
          "Transaction Date": item.transaction_date,
          "Settlement Date": item.settlement_date,
        })
      }else {
        arr.push({
          "Reference Code": item.ref_code,
          "Total Amount": item.amount,
          "Receivable": item.receivable,
          "Transaction Date": item.transaction_date,
        })
      }
    });

    const csv = jsonToCSV(arr);
    const blob = new Blob([csv]);
		const a = window.document.createElement("a");
	    a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
	    a.download = returnType()==='accounted'?'Recon Credit/Debit_Card_Accounted.csv':'Recon_Credit/Debit_Card_Unaccounted.csv';
	    document.body.appendChild(a);
	    a.click();
	    document.body.removeChild(a);
  }

  const getTableData = async (type, start, end) => {
    setIsLoading(true);
    try {
      const res = await reconServices.getEnterpriseRecon(type, start, end);
      setTableData(res.data);
      if(res.data.length>0){
          let total_inc = 0;
          let total_out = 0;
          for(var i=0;i<res.data.length;i++){
            if(res.data[i].receivable>0){
              total_inc+=res.data[i].receivable;
            }else{
              total_out+= -1*res.data[i].receivable;
            }
          }
          setOutgoing(total_out)
          setIncoming(total_inc)
      }else{
        setOutgoing(0)
        setIncoming(0)
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }

  const rangeSelect = (dates, dateString, id) => {
    setDateRange({...dateRange, [id]: moment(dateString).format('MM-DD-YYYY')});
    if(id==='startDate') {
      getTableData(returnType(), moment(dateString).format('MM-DD-YYYY'), dateRange['endDate']);
    } else {
      getTableData(returnType(), dateRange['startDate'], moment(dateString).format('MM-DD-YYYY'));
    }
    setDateSearch(true);
  };

  const returnType = () =>  {
    switch (selected) {
      case 'Accounted':
        return 'accounted';
      case 'Unaccounted':
        return 'unaccounted';
      default:
        return 'accounted';
    }
  }

  const resetProps = () => {
    setDateSearch(false);
    setDateRange({startDate: moment().format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY')});
  }

  React.useEffect(() => {
    resetProps();
    getTableData(returnType(),  dateRange['startDate'],  dateRange['endDate']);
  },[selected])

  return (
    <div style={styles.root}>
      <MetaTag title="Credit Card Reconciliation" />
      <CardBalance isLoading={isLoading} balance={cc_balance} />
      <div style={{backgroundColor: '#FFF', padding: 20, borderBottom: '1px solid #D1D5DD', marginTop: 20 }}>
      <div style={styles.btnContainer}>
        <ButtonTab />
        <button className={`btn--${segments} btn-height`} onClick={exportCsv}>
          Export CSV
        </button>
      </div>
      <div style={styles.filterContainer}>
        <DatePicker 
          format='MM/DD/YYYY' 
          disabledDate={disabledDate} 
          defaultValue={moment()} 
          value={dateRange&&moment(dateRange.startDate)} 
          size="large" picker="month" 
          onChange={ (dates, dateString) => rangeSelect(dates, dateString, 'startDate')} 
          placeholder='Start date' 
          style={{...styles.datePic, padding: '0 8px 0 0'}} 
          suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{fontSize: 22}} /></div>}
          allowClear={false}/>
        <span style={styles.span}>-</span>
        <DatePicker 
          format='MM/DD/YYYY' 
          disabledDate={disabledDate} 
          defaultValue={moment()}
          value={dateRange&&moment(dateRange.endDate)} 
          size="large" 
          picker="month" 
          onChange={ (dates, dateString) => rangeSelect(dates, dateString, 'endDate')} 
          placeholder='End date' 
          style={styles.datePic}
          suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{fontSize: 22}} /></div>}
          allowClear={false}/>
        <Input
          placeholder="Search"
          style={{ width: '40%', height: '40px', borderRadius:'4px' }}
          suffix={<Icon type="search" style={{fontSize:'18px'}}/>}
        />
      </div>
      </div>
      <TableStats isLoading={isLoading} outgoing={outgoing} incoming={incoming} />
      <ReconTable
        isLoading={isLoading} 
        length={totalLength}
        selected={selected}
        data={tableData}
      />
    </div>
  )
}

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '20px 42px 0px 42px',
  },
  titleContainer: {
    padding: '12px 0'
  },
  title: {
    backgroundColor: 'transparent',
    width: '100%',
    color: '#000',
    fontWeight: 'bold',
    fontSize: 28,
    display: 'flex',
    alignItems: 'center',
  },
  subTitle: {
    color: '#000',
    fontSize: 12
  },
  btnTabContainer: {
    backgroundColor: '#FFF',
    width: '100%',
    padding: '20px, 20px 0 20px',
    borderBottom: '1px solid #D1D5DD'
  },
  btnGroup: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '0 32px'
  },
  exportBtn: {
    fontSize: 16,
    fontWeight: '600',
    height: 40,
    borderRadius: 10,
    borderColor: '#1DD28B',
    // marginRight: 8,
    color: '#FFF',
    backgroundColor: '#1DD28B'
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
  },
  suffixIcon: {
    marginTop: '-12px', 
    marginRight: 12, 
    color: '#000'
  },
  datePic: {
    height: 40,
    fontSize: 14,
    color: '#2F3542',
    width: '34%',
    padding: '0 8px 0 8px'
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '20px 0 0 0',
  },
  tableStatsText: {
    fontSize: 14, 
    fontWeight: 'bold', 
    color: '#0D3D76'
  },
}

export default DebitCreditCard;