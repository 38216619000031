import axios from 'axios';

const createRequestConfig = () => {
  return {
    headers: {
      Authorization: `Token ${localStorage.getItem("jwtToken")}`,
    }
  }
}

export const StaticQRPHServices = {
  getStaticQRPHApi: async () => {
    const requestConfig = createRequestConfig();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/static_qrph/`,
      requestConfig,
    );

    const imageUrl = response.data.image_url;
    const displayedName = response.data.displayed_name;

    return { imageUrl, displayedName };
  },

  saveStaticQRPHBuxImageApi: async () => {
    const requestConfig = createRequestConfig();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/static_qrph/save/`,
      requestConfig,
    );

    return response.data.image_url;
  },
}