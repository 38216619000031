import React from 'react';
import ButtonGroup from 'antd/lib/button/button-group';
import EmoneyIcon from '../../../static/images/emoney_icon_instruction.svg';
import VisaSS from '../../../static/images/visa_ss.svg';
import { Modal, Typography, Icon, Button, List } from 'antd';
import { EON_BRANCHES } from '../../constants/constants';
const EonHowToModal = (props) => {
    const { segments, visible, onContinue, onClose } = props;

    const [selected, setSelected] = React.useState('How to activate EON Card?');
    const options = ['How to activate EON Card?', 'Where to purchase?']

    const ButtonTab = ({ rootStyle }) => {

        const handleClick = (event) => {
            setSelected(event.target.value)
        }

        return (
            <div style={rootStyle}>
                <ButtonGroup>
                    {
                        options.map((btn, i) => {
                            return (
                                <Button
                                    key={btn}
                                    value={btn}
                                    onClick={handleClick}
                                    className={btn === selected ? `bg-${segments}` : `'`}
                                    style={{
                                        ...styles.btnGroup,
                                        color: btn === selected ? '#FFF' : '#2B2D33',
                                        backgroundColor: '#FFF',
                                        borderRadius: i === 0 ? '4px 0 0 4px' : options && i === options.length - 1 ? '0 4px 4px 0' : 0
                                    }}
                                >
                                    {btn}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </div>
        )
    }

    const instructions = [
        {
            val: <Typography style={styles.rowText}>To activate your EON Card, Click <img style={{ marginBottom: 2 }} src={EmoneyIcon} alt='No Image' />  <b>BUx E-Money Account.</b></Typography>
        },
        {
            val: <Typography style={styles.rowText}>In the BUx E-Money page, click <b>Upgrade to EON Visa card.</b></Typography>
        },
        {
            val: <Typography style={styles.rowText}>Enter your <b>Visa card number</b> found on your EON physical card.</Typography>,
            img: <img style={{ padding: '12px 18px' }} src={VisaSS} alt='No Image' />
        },
        {
            val: <Typography style={styles.rowText}>You will then be notified via email once completed.</Typography>
        },
        {
            val: <Typography style={styles.rowText}>Once you have linked your EON physical visa card, the card number of your BUx virtual visa card shall be replaced.</Typography>
        },
    ]

    const Instruction = () => {
        return (
            instructions.map((ins, i) => {
                return <div>
                    <div style={styles.howTorow}>
                        <Typography style={styles.numberCircle}>{1 + i}</Typography>
                        {ins.val}
                    </div>
                    {ins && ins.img}
                </div>
            })
        )
    }

    const EonBranches = () => {

        return (
            <div style={styles.branchDiv}>
                <Typography style={styles.branchTableHeader}>Available Branches</Typography>
                <List
                    dataSource={EON_BRANCHES}
                    renderItem={(item, i) => (
                        <Typography style={styles.branchTableRow}>{item}</Typography>
                        )
                    }
                />
            </div>
        )
    }

    return (
        <Modal
            visible={visible}
            footer={false}
            width={660}
            destroyOnClose
            style={{ borderRadius: 8, padding: 32 }}
            centered
            onClose={onClose}
            closeIcon={<Icon onClick={onClose} type="close" className={`link-text--${segments}`} />}
        >
            <Typography style={styles.title}>EON Card</Typography>
            <ButtonTab />
            <div style={styles.howToBody}>
                {
                    selected === 'How to activate EON Card?' ?
                        <Instruction />
                        : <EonBranches />
                }
            </div>
            {/* <div style={styles.btnDiv}>
                <button className={`btn--${segments}`} style={styles.continueBtn} onClick={onClose} >Got it</button>
            </div> */}
        </Modal>
    )
}

const styles = {
    title: {
        color: '#2B2D32',
        fontSize: 28,
        fontWeight: 'bold',
        textAlign: 'left',
        paddingBottom: '20px'
    },
    msg: {
        color: '#2B2D33',
        fontSize: 16,
        textAlign: 'left',
        padding: '16px 0 32px 0'
    },
    cancelBtn: {
        cursor: 'pointer',
        margin: '0px 16px',
        height: 40,
        padding: '0 12px'
    },
    continueBtn: {
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        cursor: 'pointer',
    },
    btnDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    btnGroup: {
        fontSize: window.innerWidth < 476 ? 10 : 12,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: window.innerWidth < 476 ? '0 12px' : '0 16px',
    },
    numberCircle: {
        height: '23px',
        width: '23px',
        backgroundColor: '#254A76',
        borderRadius: '50%',
        display: 'inline-block',
        color: '#FFF',
        fontWeight: '700',
        fontSize: 12,
        textAlign: 'center',
        lineHeight: '2',
        padding: '0 8px'
    },
    howTorow: {
        display: 'inline-flex',
        padding: '14px 0',
        justifyContent: 'flex-start',
    },
    howToBody: {
        padding: '32px 0px 12px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    rowText: {
        color: '#2B2D32',
        fontSize: 14,
        fontWeight: '400',
        padding: '0 16px'
    },
    branchTableHeader: {
        color: '#2B2D32',
        backgroundColor: '#CFD3D9',
        fontSize: 14,
        fontWeight: 'bold',
        padding: '14px 16px'
    },
    branchTableRow: {
        color: '#2B2D32',
        fontSize: 14,
        padding: 12,
        borderBottom: 'solid 1px #D4D5D8'
    },
    branchDiv: {
        maxHeight: '740px',
        overflow: 'auto'
    }
}

export default EonHowToModal;