import React from 'react'
import { Modal } from 'antd'
import { openPayoutDrawer} from './payout_drawer'
import PayoutDisclaimerIcon from '../../static/icons/payout_disclaimer.svg'
import * as Style from './payout_drawer_style'
import { isMobile } from 'react-device-detect'
import { history } from '../../store/history'

export default function PayoutDisclaimer(props) {
    const { 
        funds, visible, closeModal
    } = props

    const [openModal, setModal] = React.useState(true)

    return(
        <Modal 
            centered
            width={360}
            visible={visible}
            onCancel={() => closeModal(false)}
            footer={null}>
            <div>
                <div style={Style.disclaimerIconDiv}>
                    <img src={PayoutDisclaimerIcon} alt="icon" />
                </div>
                <div style={Style.disclaimerTextDiv}>
                    You acknowledge that you have provided your goods and services to your customers by continuing with the payout.
                </div>

                <div style={Style.disclaimerBtnDiv}>
                    <button style={Style.cancelBtn} onClick={() => closeModal(false)}>
                        Cancel
                    </button>
                    <button style={Style.payoutBtn} onClick={() => {isMobile ? history.push('/mobile/payout') : openPayoutDrawer(); closeModal(false)}}>
                        Payout
                    </button>
                </div>

            </div>
        </Modal>
    )
}