export const Style = {
    profilePic: {
        width: '40px', 
        height: '40px',
        borderRadius: '50%',
        marginTop: 20,
    },
    nameDiv: {
        fontWeight: 'normal',
        marginLeft: '5px',
        fontSize: '20px',
        color: '#fff'
    },
    username:{
        marginLeft: '5px', 
        fontSize: '18px', 
        color: '#fff', 
        overflowWrap: 'anywhere'
    },
    myCheckoutBtn:{
        border: '1px solid #F5922F',
        boxSizing: 'border-box',
        borderRadius: '24px',
        padding: '8px 0px 8px 0px',
        color: '#ffffff',
        fontSize: 14,
        lineHeight: '12px',
        background: '#F5922F',
        height: '32px',
        width: '200px',
        outline: 0,
        cursor: 'pointer',
        marginBottom: '12px',
    },
    upgrade: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff'
    },
    step: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '10px',
        lineHeight: '10px',
        color: '#f4f4f4'
    },

    completeText: {
        fontWeight: '600', 
        fontSize: '16px', 
        lineHeight: '24px', 
        color: '#fafafa', 
        width: '90%',
        textDecoration: 'underline',
        
    },

    completeProfileBtn: {
        width: '171px',
        height: '28px',
        backgroundColor: '#f5922f',
        color: '#fff',
        border: '1px solid #f5922f',
        borderRadius: '24px',
        fontSize: '14px',
        fontWeight: 'bold'
    }
}