import React from 'react'
import logo from "../../static/icons/logo.svg";
import ecomStyles from '../../static/css/Ecomx';
import { Grid } from "@material-ui/core";
import ecomx from '../../static/images/ecomx.svg'


const RegFailed = props => {
    const ecom = ecomStyles();
    
    return(
        <div>
            <div className={ecom.preMain}>
                <div className={ecom.main}>
                    <div className={ecom.logoDiv}>
                        <img src={logo} />
                    </div>
                    <div className={ecom.cardDiv}>
                        <Grid container>
                            <Grid lg={6}>
                                <div className={ecom.cfLeft}>
                                    <img src={ecomx} />

                                    <div className={ecom.clInfo}>
                                        <span>Date: December 10, 2019</span><br/>
                                        <span>Venue: UnionBank Plaza Bldg</span>
                                    </div>

                                    <div className={ecom.clInfo1}>
                                        <span>By: Nick Peroni | Powered by <img src={logo} className={ecom.clLogo}/></span> 
                                    </div>

                                </div>
                            </Grid>
                            <Grid lg={6}>
                                <div className={ecom.cfRight}>
                                    <div className={ecom.cfrTop}>
                                        <div className={ecom.crsHeader}>
                                            <span>Sorry</span>
                                        </div>

                                        <div className={ecom.crbfText}>
                                            <span>Seats are already fully-booked at this time.</span>
                                            <p>Come back to check availability.</p>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegFailed;