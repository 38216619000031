import axios from 'axios';
import {Handle401} from '../handle401/handle401'

const authorizedAxiosRequest = (value) => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  });
}

const par = (params) => {
//   console.log('###########PRAMS########3', params)
  if (typeof params == "number"){
    return ({
      id: params,
    })
  } else {
    return ({
      params:{
        target: params,
      }
    })
  }
}

const data = {
    id: 26
}

const cardServices = {
  callLock: async (lock) => {
    let mode = 'lock';
    if(!lock){
      mode = 'unlock';
    }

    let params = {
        mode: mode 
    }
    try {
      const response = await authorizedAxiosRequest().post(`/api/card/lock/`, params);
    return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getCardInfo: async (isTestMode) => {
    const url = isTestMode? `/api/sandbox/card/`:`/api/card/`
    try {
      const response = await authorizedAxiosRequest().get(url);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getFullCardInfo: async (value) => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/card/full/?key=${value}`);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getBranches: async (value) => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/locator/eon/?city=${value}`);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getBillerPaybills: async (value) => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/bills_payment/billers/`);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  validatePaybills: async (params) => {
    try {
      const response = await authorizedAxiosRequest().post(`/api/bills_payment/validate/`, params);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  confirmPaybills: async (params) => {
    try {
      const response = await authorizedAxiosRequest().post(`api/bills_payment/confirm/`, params);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  activateCard: async (params) => {
    try {
      const response = await authorizedAxiosRequest().post(`api/card/activate/`, params);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getBuxBalAndCardNum: async () => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/dashboard/`);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  loadEonCard: async (amount) => {
    try {
      const response = await authorizedAxiosRequest().post(`/api/card/topup/`, amount);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  setCvv: async (cvv) => {
    try {
      const response = await authorizedAxiosRequest().post(`/api/card/cvv/`, cvv);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getTransactionHistory: async (type) => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/history/?type=${type}`);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getLinkedBanks: async () => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/bank_accounts/`);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  getOTP: async () => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/otp/`);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  submitOtp: async (params) => {
    const otpMode = Object.keys(params).length <= 1 ? 'email_otp' : 'otp';
    try {
      const response = await authorizedAxiosRequest().post(`/api/${otpMode}/`, params);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      } else {
        return error.response;
      }
    }
  },
  getOtpEmail: async (subj) => {
    try {
      const response = await authorizedAxiosRequest().get(`/api/email_otp/?subject=${subj}`);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  linkBank: async (params) => {
    try {
      const response = await authorizedAxiosRequest().post(`/api/bank_accounts/`, params);
      return response;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
  createPayout: async (params) => {
    try {
      const response = await authorizedAxiosRequest().post(`/api/payouts/create/`, params);
      return response.data;
    } catch (error) {
      if((error.response)&&(error.response.status == 401)){
        Handle401()
      }
    }
  },
}

export default cardServices;