import React from "react";
import {
  useDispatch,
  useSelector,
} from "../../../../__test__/react-redux-hooks";
import multiwalletActions from "../../multiwalletActions";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Typography,
  Button,
  Icon,
  Pagination,
  message,
} from "antd";
import { history } from "../../../../store/history";
import multiwalletServices from "../../multiwalletServices";
import TransferRequestModal from "./transfer_request_modal";
import { jsonToCSV } from "react-papaparse";
import UploadCsvModal from "../transfer_money/uploadCsvModal";
import TablePreviewModal from "../transfer_money/tablePreviewModal";

const templateData = [
  {
    full_name: "Johny Bravo",
    email: "johny@sample.com",
    contact: "9123456789",
    amount: 200,
  },
  {
    full_name: "John Week",
    email: "weekjohn@ubx.ph",
    contact: "9221234555",
    amount: 300,
  },
  {
    full_name: "Sam Smyth",
    email: "sammy@sample.com",
    contact: "9191231232",
    amount: 400,
  },
];

const defaultCsvTemplate = {
  full_name: "Full Name",
  email: "Email",
  contact: "Mobile Number",
  amount: "Amount",
  transfer_id: "Transfer ID",
};

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

const EditableCell = (props) => {
  const [isEditing, setIsEditing] = React.useState(false);

  const toggleEdit = () => {
    const editing = !isEditing;
    setIsEditing({ editing }, () => {
      if (editing) {
        props.input.focus();
      }
    });
  };

  const save = (e, form) => {
    const { record, handleSave } = props;
    form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      // toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  const renderCell = (form, input) => {
    // this.form = form;
    const { children, dataIndex, record, title } = props;
    // const { editing } = this.state;
    return !isEditing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: false,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(
          props.inputType === "number" ? (
            <Input
              type="number"
              style={{ width: "100%" }}
              ref={(node) => (input = node)}
              onPressEnter={(e) => save(e, form)}
              onBlur={(e) => save(e, form)}
              onChange={(e) => save(e, form)}
            />
          ) : (
            <Input
              style={{ width: "100%" }}
              ref={(node) => (input = node)}
              onPressEnter={(e) => save(e, form)}
              onBlur={(e) => save(e, form)}
              maxLength={50}
            />
          )
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  };

  const {
    editable,
    dataIndex,
    title,
    record,
    index,
    handleSave,
    children,
    ...restProps
  } = props;
  return (
    <td {...restProps}>
      {editable ? (
        <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>
      ) : (
        children
      )}
    </td>
  );
};

const SubwalletTable = (props) => {
  const {
    segments,
    data,
    isLoading,
    onApplyFilter,
    onChangePage,
    onAmountChange,
    setLoading,
    onTransferRequestSuccess,
  } = props;
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [selectedRowsData, setSelectedRowsData] = React.useState([]);
  const [searchVal, setSearchVal] = React.useState("");
  const testMode =
    history.location.pathname.indexOf("/test") > -1 &&
    localStorage.getItem("userType") == "CO";
  const [transferRequestResult, setTransferRequestResult] = React.useState({});
  const [transferRequestModal, setTransferRequestModal] = React.useState(false);
  const [uploadModalShow, setUploadModalShow] = React.useState(false);
  const [tablePreviewShow, setTablePreviewShow] = React.useState(false);
  const [tablePreviewData, setTablePreviewData] = React.useState(null);
  const [transferRequestLoading, setTransferRequestLoading] =
    React.useState(false);
  const [transferCsvTemplate, setTransferCsvTemplate] = React.useState(null);
  const [transferCsvTemplateLoading, setTransferCsvTemplateLoading] =
    React.useState(false);

  const userId = localStorage.getItem("userId");
  const csvTemplate = transferCsvTemplate || defaultCsvTemplate;
  const isChild = localStorage.getItem("is_child") === "true";

  const transferMoneyData = useSelector(
    (state) => state.multiwallet.transferMoneyData
  );

  const definedWalletSettings = JSON.parse(
    localStorage.getItem("defined_wallet_settings")
  );
  const { wallet_transfer_id_enabled: walletTransferIdEnabled = false } =
    definedWalletSettings;

  const tableColumns = [
    {
      title: "User ID",
      dataIndex: "sub_id",
    },
    {
      title: "Name",
      dataIndex: "full_name",
    },
    {
      title: "Email Address",
      dataIndex: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "contact",
    },
    ...(walletTransferIdEnabled
      ? [
          {
            title: "Transfer ID",
            dataIndex: "transfer_id",
            editable: true,
            width: "20%",
          },
        ]
      : []),
    {
      title: "Amount",
      dataIndex: "amount",
      editable: true,
      width: "30%",
    },
  ];

  const subusers = () => {
    if (data) {
      let filtered_users = data["results"].filter(
        (filtered_user) => filtered_user.transfer_money_enabled == true
      );
      return filtered_users;
    } else {
      return [];
    }
  };

  const handleAdd = () => {
    const { count, dataSource } = this.state;
    const newData = {
      key: count,
      name: `Edward King ${count}`,
      age: 32,
      address: `London, Park Lane no. ${count}`,
    };
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    });
  };

  const handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  };

  const mappedTemplateData = templateData.map((row) => {
    const mappedRow = {};
    Object.entries(row).forEach(([key, value]) => {
      mappedRow[csvTemplate[key]] = value;
    });
    return mappedRow;
  });

  const exportTemplate = () => {
    const dataWithTransferId = mappedTemplateData.map((t) => ({
      ...t,
      [csvTemplate.transfer_id]: "1234",
      [csvTemplate.amount]: t[csvTemplate.amount],
    }));

    const csv = jsonToCSV(
      walletTransferIdEnabled ? dataWithTransferId : mappedTemplateData
    );
    const blob = new Blob([csv]);
    const a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
    a.download = "subwallet_money_transfer_template.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  };
  const columns = tableColumns.map((col) => {
    // if (!col.editable) {
    //   return col;
    // }
    return {
      ...col,
      onCell: (record, index) => ({
        record,
        inputType: col.dataIndex === "amount" ? "number" : "text",
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: (row) => onAmountChange(row, index),
      }),
    };
  });

  const hasEmptyAmount = (data) => {
    const isEmpty = data.every(
      (item) => item.amount && item.amount && item.amount >= 1
    );
    if (isEmpty) {
      return false;
    }
    return true;
  };

  const getTotalAmount = (data) => {
    let total = 0;
    data &&
      data.map((row, i) => {
        total = total + parseFloat(row.amount ? row.amount : 0);
      });
    return total;
  };

  const generatePayload = (transferId) => {
    return {
      field1: {
        name: "Transfer ID",
        value: transferId,
      },
    };
  };

  const addToTransferMoneyBox = async (data) => {
    try {
      setTransferRequestLoading(true);
      const dataWithPayload = data.map((d) => ({
        ...d,
        payload: generatePayload(d.transfer_id),
      }));
      const res = await multiwalletServices.batchTransferMoneyRequest(
        walletTransferIdEnabled ? dataWithPayload : data
      );
      setTransferRequestResult(res);
      setTransferRequestModal(true);
      onTransferRequestSuccess();
    } catch (error) {
      message.error(error.message);
    } finally {
      setTransferRequestLoading(false);
      setLoading();
    }
  };

  const tableCheckbox = {
    type: "checkbox",
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) =>
      handleChangeSelect(selectedRowKeys, selectedRows),
  };

  const handleChangeSelect = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const onAddToMoneyBox = () => {
    const selectedRows = [];
    data &&
      data["results"].map((row, i) => {
        if (selectedRowKeys.includes(i)) {
          selectedRows.push(row);
        }
      });

    addToTransferMoneyBox(selectedRows);
  };

  const handleSearch = (e) => {
    setSearchVal(e.target.value);
  };

  const changePage = (page, pageSize) => {
    onChangePage(page);
  };

  const onFilterApply = () => {
    onApplyFilter(searchVal);
  };

  const fetchTransferCsvTemplate = async () => {
    try {
      setTransferCsvTemplateLoading(true);
      const params = { is_child: isChild };
      const response = await multiwalletServices.fetchTransferCsvTemplate(
        userId,
        params
      );
      setTransferCsvTemplate(response.data);
    } catch (error) {
      setTransferCsvTemplate(null);
    } finally {
      setTransferCsvTemplateLoading(false);
    }
  };

  React.useEffect(() => {
    setSelectedRowKeys([]);
    fetchTransferCsvTemplate();
  }, [data]);

  const enableTransferRequestButton =
    selectedRowKeys.length > 0 && !transferRequestLoading;

  const enableBatchActions = !transferCsvTemplateLoading;

  return (
    <div style={styles.root}>
      <div style={styles.body}>
        <div className="twoCol">
          <div className="bold-text-size liveColor top-20">
            Select the sub-wallet you want to transfer funds.
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "24px 0",
            }}
          >
            <Input
              placeholder="Search"
              style={{
                width: "200px",
                height: "40px",
                borderRadius: "4px",
                padding: "0 8px 0 0px",
              }}
              suffix={<Icon type="search" style={{ fontSize: "18px" }} />}
              onChange={handleSearch}
              value={searchVal}
            />
            <button
              className={`btn--${segments} btn-height`}
              onClick={onFilterApply}
            >
              Search
            </button>
          </div>
        </div>

        <Table
          components={components}
          rowClassName="table-row-light"
          dataSource={subusers()}
          columns={columns}
          pagination={false}
          rowSelection={{ ...tableCheckbox }}
          loading={isLoading}
        />
        <Pagination
          size="small"
          total={data && data["total"]}
          cd
          defaultPageSize={10}
          pageSize={10}
          defaultCurrent={data && data["page"] ? data["page"] : 1}
          current={data && data["page"] ? data["page"] : 1}
          onChange={(page, pageSize) => changePage(page, pageSize)}
          style={{ padding: "12px 0 8px 0", textAlign: "right" }}
        />

        <div className="bottom-32 top-16" align="right">
          <button
            disabled={!enableBatchActions}
            onClick={exportTemplate}
            name="exportBtn"
            className={`outline-btn--${segments} btn-height right-8`}
          >
            Download Template
          </button>
          <button
            disabled={!enableBatchActions}
            className={`outline-btn--${segments} btn-height right-8`}
            onClick={() => setUploadModalShow(true)}
          >
            <Icon type="download" className="right-4" />
            Import Batch list
          </button>
          <button
            disabled={!enableTransferRequestButton}
            className={`btn--${segments} btn-height normal-text-size`}
            onClick={onAddToMoneyBox}
          >
            <Icon type="plus" className="right-4" />
            Submit Transfer for Approval ({selectedRowKeys.length})
          </button>
        </div>
      </div>
      <TransferRequestModal
        data={transferRequestResult}
        visible={transferRequestModal}
        onClose={() => setTransferRequestModal(false)}
      />
      <UploadCsvModal
        segments={segments}
        visible={uploadModalShow}
        closeModal={() => setUploadModalShow(false)}
        exportTemplate={exportTemplate}
        onConfirm={(data) => {
          setUploadModalShow(false);
          setTablePreviewShow(true);
          setTablePreviewData(data);
        }}
        csvTemplate={csvTemplate}
      />
      <TablePreviewModal
        segments={segments}
        visible={tablePreviewShow}
        data={transferMoneyData}
        closeModal={() => setTablePreviewShow(false)}
        addTableData={(data) => addToTransferMoneyBox(data)}
        tableData={tablePreviewData}
      />
    </div>
  );
};

const styles = {
  root: {
    margin: "24px 0",
  },
  transferBtn: {
    fontSize: 16,
    fontWeight: "600",
    padding: "8px, 16px, 8px, 16px",
    border: "1px solid #1DD28B",
    height: 40,
    color: "#FFF",
    backgroundColor: "#1DD28B",
  },

  headerDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px 0",
  },
  body: {
    backgroundColor: "transparent",
    padding: "0px 16px",
    backgroundColor: "#FFF",
    border: "1px solid #E6EAF0",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
  },
  applyBtn: {
    height: 40,
    width: 92,
    fontSize: 16,
    fontWeight: "600",
    borderRadius: 4,
    backgroundColor: "#F5922F",
    color: "#FFF",
  },
};

const EditableSubwalletTable = Form.create()(SubwalletTable);

export default EditableSubwalletTable;
