import React, { useState, useEffect } from 'react';
import OTPInput from "otp-input-react";
import cardActions from '../cardActions';
import cardServices from '../cardServices';
import { Card, Typography, Modal, Button, Spin, Affix, Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import CancelIcon from '@material-ui/icons/Cancel';
import eonImage from "../../../static/images/eon_text_image.svg";
import { isMobile } from 'react-device-detect';

const OtpCard = () => {
  const [OTP, setOTP] = useState('');
  const [counter, setCounter] = useState(0);
  const [initialized, setInitialized] = useState(false);
  const resetTimer = useSelector(state => state.card.otpTimerReset)
  const dispatch = useDispatch();
  const [btnStyle, setBtnStyle] = useState({ backgroundColor: 'transparent', color: '#F5922F' })
  const [notifMsg, setNotifMsg] = useState(null);
  const [isError, setIsError] = useState(false);
  const [resBtnLoading, setResBtnLoading] = useState(false);
  const [subBtnLoading, setSubBtnLoading] = useState(false);
  const [emailBtnLoading, setEmailBtnLoading] = useState(false);
  const [emailOtp, setEmailOtp] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const attemptError = useSelector(state => state.card['attempt_error']);
  const visibleOTP = useSelector(state => state.card['otp_mobile']);
  const otpParams = useSelector(state => state.card.otpParams ? state.card.otpParams : '');
  const attemptErrorMsg = useSelector(state => state.card.attemptErrorMsg);
  const segments = localStorage.getItem('segments');

  const handleChange = (code) => {
    setOTP(code)
  }

  if (resetTimer) {
    setCounter(60);
    setResBtnLoading(false);
    setBtnStyle({
      backgroundColor: 'transparent',
      color: '#F5922F'
    });
    setEmailOtp(false);
    setOTP('');
    dispatch(cardActions.hideModal('attempt_error'));
    dispatch(cardActions.setAttempMsg(null));
    dispatch(cardActions.resetOTPTimer(false));
  }

  if (isError || isSuccess) {
    setTimeout(() => {
      setIsError(false)
      setIsSuccess(false)
    }, 5000);
  }

  const submitOTP = async () => {
    setSubBtnLoading(true)
    let params = emailOtp ? { code: OTP } :
      {
        uid: otpParams.uid,
        code: OTP,
        spin: true,
        otp_type: 1
      }
    let response = {}
    try {
      response = await cardServices.submitOtp(params);
      if (response.status === 'success') {
        setSubBtnLoading(false)
        setOTP('')
        dispatch(cardActions.hideModal('otp_mobile'))
        dispatch(cardActions.setOtpUID(response.trans_uid));
        dispatch(cardActions.setOtpSuccess(true));
      }
      // console.log(response);
    } catch (error) {
      console.log(error);
      if (response.code === 'attempts') {
        dispatch(cardActions.showModal('attempt_error'));
        dispatch(cardActions.setAttempMsg(response.unblock));
        setCounter(0);
      } else {
        setNotifMsg(response.message);
        setIsError(true);
      }
      setSubBtnLoading(false);
    }
  }

  const returnOtpSubj = () => {
    const path = window.location.pathname;
    console.log(path)
    switch (path) {
      case '/physical_card_upgrade':
      case '/mobile/physical_card_upgrade':
        return 'replace_card';
      case '/cards/bills_payment':
      case '/mobile/bills_payment':
        return 'bills_pay';
      case '/request_cvv':
      case '/mobile/request_cvv':
        return 'cvv';
      default:
        return 'payout';
    }
  }

  const fetchOTP = async () => {
    try {
      const response = await cardServices.getOTP();
      if (response.status === 'success') {
        dispatch(cardActions.setOtpParams({mobile:response.mobile, uid:response.uid, subj: returnOtpSubj()}));
        dispatch(cardActions.resetOTPTimer(true))
      } else {
        if(response.code==='attempts'){
          dispatch(cardActions.setAttempMsg(response.unblock));
          dispatch(cardActions.showModal('attempt_error'));
          setResBtnLoading(false);
        } else {
          setNotifMsg(response.message);
          setIsError(true);
          setResBtnLoading(false);
        }
      }
    } catch (error) {
      console.log(error)
      setNotifMsg(error);
      setIsError(true);
      setResBtnLoading(false)
    }
  }

  const handleResend = () => {
    setResBtnLoading(true)
    fetchOTP()
  }

  const handleOtpEmail = async () => {
    setEmailBtnLoading(true);
    try {
      const response = await cardServices.getOtpEmail(otpParams.subj);
      // console.log(response);
      if (response.status === 'success') {
        setEmailOtp(true)
        setIsSuccess(true)
        setNotifMsg('OTP PIN has been sent to your email')
        setEmailBtnLoading(false);
        setOTP('');
      } else {
        setNotifMsg(response.message);
        setIsError(true);
        setEmailBtnLoading(false);
      }
    } catch (error) {
      console.log(error);
      setNotifMsg(error);
      setIsError(true);
      setEmailBtnLoading(false);
    }
  }

  const handleMouseOver = () => {
    setBtnStyle({
      backgroundColor: '#F5922F',
      color: '#FAFAFA'
    });
  }

  const handleMouseLeave = () => {
    setBtnStyle({
      backgroundColor: 'transparent',
      color: '#F5922F'
    });
  }

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter])

  const onClose = () => {
    dispatch(cardActions.hideModal('attempt_error'));
    dispatch(cardActions.setAttempMsg(null));
    setSubBtnLoading(false);
    setCounter(0);
    setEmailOtp(false);
    setOTP('');
  }

  return (
  //  <div hidden={!visibleOTP} style={styles.root}>
  //   <div style={styles.eonTitle}>
  //     <img src={eonImage} />
  //     <strong style={styles.regularFont}>Card</strong>
  //   </div>
    <Card hidden={!visibleOTP}  style={styles.card} size='small' bodyStyle={{padding: 32}} >
      {isError || isSuccess ?
        <Affix offsetTop={20} style={styles.affix}>
          <div
            style={{
              ...styles.notif,
              backgroundColor: isError ? '#FFF' : '#1DD28B',
              color: isError ? '#E24C4C' : '#FFFFFF',
            }}
          >
            {isError ? <CancelIcon
              style={{
                margin: '0 10px 0 0'
              }}
            /> : null}
            <div>
              <div align='center'>{notifMsg}</div>
            </div>
          </div>
        </Affix>
        : null
      }
      <div style={styles.body}>
        <Typography style={styles.subtitle}>One-Time PIN</Typography>
        {emailOtp ?
          <Typography style={styles.normalFont}>
           You will receive a One-Time Password (OTP) on your registered email address.
          </Typography>
          : <Typography style={styles.normalFont}>To proceed, a 6-digit code will be sent via SMS on your registered number{ attemptError ? null : ' ending in ' }
              <strong>{otpParams && !attemptError ? otpParams.mobile.replace(/.(?=.{4})/g, '') : null}</strong>.
            </Typography>
        }
        <OTPInput
          value={OTP}
          onChange={handleChange}
          autoFocus
          OTPLength={6}
          otpType="number"
          disabled={counter == 0 && !emailOtp}
          style={styles.otp}
          secure
          inputStyles={{ ...styles.otpInput, opacity: counter == 0 && !emailOtp ? '0.5' : '' }}
        />
        <div hidden={emailOtp} align='center'>
          <div hidden={ !attemptError }>
            <Typography style={{ ...styles.normalFont, color: '#E24C4C' }}>
              Oops! You have reached OTP Limits, You can request a new OTP on
            </Typography>
            <Typography style={{ ...styles.normalFont, color: '#E24C4C' }}>
              {attemptErrorMsg}.
            </Typography>
          </div>
          <button
            style={{ ...styles.resBtn, opacity: counter !== 0 ? '0.5' : '' }}
            disabled={counter !== 0 || resBtnLoading || attemptError}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onClick={handleResend}
            hidden={ attemptError }
            className={`outline-btn--${segments}`}
          >
            {resBtnLoading ? <Spin size='small' /> : 'Resend Code'}
          </button>
          <Typography style={{ ...styles.normalFont, padding: 0 }} hidden={attemptError}>
            “<strong>Resend Code</strong>” will be enabled after <strong>{counter}</strong>.
          </Typography>
        </div>
      </div>
      <button
        onClick={submitOTP}
        style={{ ...styles.subBtn, opacity: OTP.length < 4 || counter === 0 && !emailOtp ? '.5' : '1' }}
        disabled={subBtnLoading || OTP.length < 4 || counter === 0 && !emailOtp}
        className={`btn--${segments}`}
      >
        {subBtnLoading ? <Spin size='small' /> : 'Submit'}
      </button>
      <div hidden={emailOtp} style={{textAlign: 'center'}}>
        <div style={styles.dividerDiv}>
          <Divider style={styles.divider} />
          <Typography style={styles.or}>OR</Typography>
          <Divider style={styles.divider} />
        </div>
        <a
          // style={{ color: '#F5922F' }}
          style={styles.btnEmail}
          className={`link-text--${segments}`}
          onClick={handleOtpEmail}
          disabled={emailBtnLoading || subBtnLoading}
        >Send OTP code via Email</a>
      </div>
    </Card>
  //  </div>
  )
}

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    // margin: '0 0 0 -30px',
    padding: 20,
  },
  eonTitle: {
    textAlign: 'left',
    display: 'flex', 
    alignItems: 'center',
    width: isMobile ? '100%' : 477,
    padding: '20px 0'
  },
  regularFont: {
    fontSize: 28,
    color: '#000000',
    padding: '0 6px'
  },
  card: {
    width: isMobile ? '100%' : 477,
  },
  title: {
    color: '#000000',
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '24px 0 20px 0'
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subtitle: {
    color: '#000000',
    fontSize: 28,
    fontWeight: '300',
    // padding: '20px 0 0 0'
  },
  normalFont: {
    color: '#000000',
    fontSize: 16,
    textAlign: 'center',
    padding: '0 26px',
  },
  otp: {
    padding: '32px 0 20px 0'
  },
  otpInput: {
    height: 70,
    width: 32,
    fontSize: "28px",
    color: "#2b2d33",
    fontWeight: "600",
    background: "#E6EAF0",
    boxSizing: "border-box",
    border: "0px solid #d1d5dd",
    borderRadius: "4px",
    margin: '0 10px'
  },
  subBtn: {
    margin: '32px 0px 20px 0px',
    height: 40,
    width: '100%',
    // backgroundColor: '#F5922F',
    // color: '#FFFFFF',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: '600',
  },
  resBtn: {
    margin: '16px 0',
    height: 40,
    width: '60%',
    // color: '#F5922F',
    borderRadius: 40,
    fontSize: 14,
    fontWeight: '600',
    // borderColor: '#F5922F',
  },
  notif: {
    backgroundColor: '#1DD28B',
    fontSize: 14,
    fontWeight: 'bold',
    width: '100%',
    borderRadius: 4,
    padding: '8px 24px',
    textAlign: 'center',
    display: 'flex',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.24)',
    alignItems: 'center',
  },
  affix: {
    // position: 'absolute',
    // zIndex: '100',
    // top: '18%',
    // width: '88%'
  },
  divider: {
    margin: '0 20px',
    padding: 1
  },
  dividerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22%',
    left: '39%',
    position: 'relative',
    color: '#909196',
    padding: '10px 0'
  },
  btnEmail: {
    fontSize: 16,
    // color: '#F5922F',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '12px 0'
  },
  or: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#909196'
  }
}

export default OtpCard;