import React, {useEffect} from 'react'
import pricingStyle from '../../static/css/Pricing';
import {Tabs} from 'antd';
import MobilePaymentMethod from './mobilePaymentMethod';
import MobilePaymentChannel from './mobilePaymentChannels';
import MobileWebBanking from './mobileWebBanking'
import MobileEWallet from './mobileEwallet';
import MobileCreditDebit from './mobileCreditDebit';
import MobileShipment from './mobileShipment';
import {history} from '../../store/history'
import {isMobile} from 'react-device-detect'
import MetaTag from '../meta_tag/meta_tag'

const { TabPane } = Tabs;

const MobilePricing = props => {
    
    const p = pricingStyle();
    const segments = localStorage.getItem('segments');

    useEffect(() => {

        let current_path = window.location.pathname

        if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
            history.push('/login?next='+current_path)
        }
        else{
            if(!isMobile) {
                history.push('/pricing/')
            }
        }

    })

    return(
        <div className={p.mainBody}>
            <MetaTag title="Pricing"/>
            
            <div className={p.menu} align='center'>

                <div className={p.tabs} align='left'>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={<div style={{fontWeight: 600, color: '#000000'}}>Over-the-counter</div>} key="1">
                            <MobilePaymentChannel segments={segments} />
                        </TabPane>
                        <TabPane tab={<div style={{fontWeight: 600, color: '#000000'}}>Online Banking</div>} key="2">
                            <MobileWebBanking segments={segments}/>
                        </TabPane>
                        <TabPane tab={<div style={{fontWeight: 600, color: '#000000'}}>E-wallet</div>} key="3">
                            <MobileEWallet segments={segments}/>
                        </TabPane>
                        { localStorage.getItem('userType') === 'ME'? null :
                            <TabPane tab={<div style={{fontWeight: 600, color: '#000000'}}>Credit / Debit</div>} key="4">
                                <MobileCreditDebit segments={segments}/>
                            </TabPane>
                        }
                        {localStorage.getItem('userType') !== 'CO'? null :
                            <TabPane tab={<div style={{fontWeight: 600, color: '#000000'}}>Payout</div>} key="5">
                                <MobilePaymentMethod segments={segments}/>
                            </TabPane>}
                        {/* <TabPane tab="Shipment Fee" key="2">
                            <MobileShipment/>
                        </TabPane> */}
                    </Tabs>
                </div>
            
            </div>
        </div>
    )
}

export default MobilePricing;