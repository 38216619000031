import React from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { paymentGet } from '../../components/payment/paymentAction'
import { history } from '../../store/history';
import { Layout, message } from 'antd'
import * as Icons from '../../static/images/home/index';
import * as Icon from '../../static/icons/bux_qr/index'
import * as style from '../../components/checkout/NewCheckout/tppStyle'
import SignedDataFields from '../../components/checkout/NewCheckout/tpp/signedDataFields';
import UnsignedDataFields from '../../components/checkout/NewCheckout/tpp/unsignedDataFields';
import '../../App.scss';
import { TestModeBanner } from '../../components/test_mode/components';
import { Spin } from 'antd';

const { Header } = Layout

const mapStateToProps = (state) => {
    const { payment } = state.payment;
    return { payment }
}

const mapDispatchToProps = (dispatch) => {
    return {
      getPayment: bindActionCreators(paymentGet, dispatch),
    };
};

const testMode = (history.location.pathname.indexOf('/test') > -1);

class SecureCheckout extends React.Component {
    formRef = React.createRef();
    paymentDetails = {}

    componentDidMount() {
        this.props.getPayment(this.uuid, testMode)
    }

    componentDidUpdate() {
        if(this.props.payment.status !== "Pending") {
            if (!testMode) {
                history.push(`/payment/${this.uuid}`)
            } else {
                history.push(`/test/payment/${this.uuid}`)
            }
        }
        if (!this.state.fieldsAutoPopulate && this.props.payment.payload && this.props.payment.payload.hasOwnProperty('sender_details')) {
            const signed_data = this.props.payment.payload.sender_details
            this.setState({
                bill_to_forename: signed_data.bill_to_forename,
                bill_to_surname: signed_data.bill_to_surname,
                bill_to_address_line1: signed_data.bill_to_address_line1,
                bill_to_address_city: signed_data.bill_to_address_city,
                bill_to_address_state: signed_data.bill_to_address_state,
                bill_to_address_country: signed_data.bill_to_address_country,
                bill_to_address_postal_code: signed_data.bill_to_address_postal_code,
                bill_to_email: signed_data.bill_to_email,
                bill_to_phone: signed_data.bill_to_phone,
            })
            this.setState({fieldsAutoPopulate: true});
            setTimeout(() => {
                this.formRef.dispatchEvent(new Event("submit"));
            }, 1000);
        }
        if (this.state.fetchLoading === true) {
            setTimeout(() => {
                this.setState({fetchLoading: false});
            }, 2000);
            
        }
        
    }

    constructor(props) {
        super(props);
        this.uuid = this.props.match.params.uuid
        this.handleChange = this.handleChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleExpiryChange = this.handleExpiryChange.bind(this);
    }

    state = {
        bill_to_forename: "",
        bill_to_surname: "",
        bill_to_address_line1: "",
        bill_to_address_city: "",
        bill_to_address_state: "",
        bill_to_address_country: "",
        bill_to_address_postal_code: "",
        bill_to_email: "",
        bill_to_phone: "",
        card_type: "",
        card_number: "",
        card_expiry_date: "",
        card_cvn: "",
        signature: "",
        isSubmitted: false,
        signedData: "",
        toSignedData: "",
        secret_key: "",
        errorField: "",
        hasError: true,
        cardExpiryMoment: "",
        merchant_defined_data41: "",
        merchant_defined_data42: "",
        fieldsAutoPopulate: false,
        fetchLoading: true,
    }

    errorMessageDiv (msg) {
        message.destroy()
        message.config({
            top: 100,
            duration: 4,
            maxCount: 1
        })
        message.error(msg)
    }

    autoFormat = (value) => value == null ? null : value.replace(/[^\d.]/g, '');


    handleChange(e) {
        let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
        let mastercard = new RegExp('^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$');
    
        const { name, value } = e.target
        if(name === 'bill_to_address_postal_code') {
            if(value.length <= 10 ) {
                this.setState({
                    bill_to_address_postal_code: value
                })    
            }
        }

        else if(name === 'card_number') {
            if(value.length <= 16 ) {
                this.setState({
                    card_number: value
                })    
            }

            if(visa.test(value)) {
                this.setState({
                    card_type: "001"
                })
            }

            if(mastercard.test(value)) {
                this.setState({
                    card_type: "002"
                })
            }
        }

        else if(name === 'card_cvn') {
            if(value.length <= 3 ) {
                this.setState({
                    card_cvn: value
                })    
            }
        }

        // else if (name === "bill_to_phone") {
        //     this.setState({
        //         [name]: this.autoFormat(value)
        //     })
        // }

        else {
            this.setState({
                [name]: value
            })  
        } 
    }

    handleCountryChange(e) {
        this.setState({
            bill_to_address_country: e
        })
    }

    handleRegionChange(e) {
        console.log(e)
        this.setState({
                bill_to_address_state: e,
                bill_to_address_city: ""
        })
    }

    handleCityChange(e) {
        this.setState({
            bill_to_address_city: e
        })
    }

    handleExpiryChange(e) {
        let formattedData;

        if(e != null) {
            formattedData = e.format('MM-YYYY')
            this.setState({
                card_expiry_date: formattedData,
                cardExpiryMoment: e
            })
        }

        else { 
            this.setState({
                card_expiry_date: "",
                cardExpiryMoment: ""
            })
        }

     
    }

    handleValidation(data) {
        for(let key in data) {
            if(data[key] == "" && key !== 'bill_to_address_state' ) {
                this.setState({
                    errorField: key
                })
                if (key === 'bill_to_forename') {
                    this.errorMessageDiv("First name must not be empty")
                }

                if (key === 'bill_to_surname') {
                    this.errorMessageDiv("Last name must not be empty")
                }

                // if (key === 'bill_to_phone') {
                //     this.errorMessageDiv("Mobile number must not be empty")
                // }

                if (key === 'bill_to_address_line1') {
                    this.errorMessageDiv("Address 1 must not be empty")
                }

                if (key === 'bill_to_address_city') {
                    this.errorMessageDiv("City must not be empty")
                }

                // if (key === 'bill_to_address_state') {
                //     this.errorMessageDiv("Province must not be empty")
                // }

                if (key === 'bill_to_address_postal_code') {
                    this.errorMessageDiv("Postal must not be empty")
                }

                return false;
            }
            else if (key === 'bill_to_address_state' && data[key] == "" && (['US', 'CA', 'CN']).includes(data['bill_to_address_country'])) {
                this.errorMessageDiv("Province must not be empty")
                return false;
            }

            else if (key === 'bill_to_address_postal_code' && data[key].length < 4) {
                this.errorMessageDiv("Postal should be atleast 4 digits")

                return false;

            }
        }

        this.setState({
            errorField: "",
            
        })

        return true
    }
    

    handleSubmit(e) {
        e.preventDefault()
        let data = new FormData(e.target)
        let formProps = Object.fromEntries(data);
        let isFormValid = this.handleValidation(formProps)
        
        if(isFormValid){
            this.genSignature(data, formProps)
            this.setState({
                toSignedData: data,
            })
        }
  
    }

    disabledSubmit() {
        let valid_cvv = /^[0-9]{3,4}$/;
        let cvv_check = valid_cvv.exec(this.state.card_cvn);
        if(!this.state.card_number || !this.state.card_expiry_date || !this.state.card_cvn) {
            return true
        }

        else if(this.state.card_number.length < 16 || this.state.card_cvn.length < 3) {
            return true
        } 

        else if(this.state.card_cvn != cvv_check) {
            console.log(cvv_check, this.state.card_cvn)
            message.error("Invalid CVV. Must be a number", 5)
            return true
        }

        else {
            return false
        }
    }

    async genSignature(data, formProps) {
        try{
            const url = testMode ? `/api/sandbox/generate_signature/` : `/api/generate_signature/`

            let dataSigned = this.buildDataToSign(data);

            console.log(formProps)

            let params = {
                data: dataSigned,
                uid: this.uuid,
                to_be_signed: formProps,
            }

            let response = await axios.post(
                process.env.REACT_APP_API_URL + url, params
            );

            if(response.data.message == "success"){
                this.setState({
                    signature: response.data.signature,
                })

                this.props.getPayment(this.uuid, testMode)

            }

        }
        
        catch(error){
            console.log(error)
        }

    }

    buildDataToSign(params) {
        let signedFieldNames = params.get("signed_field_names").split(",")
        let dataToSign = []
        signedFieldNames.forEach((item) => {
            dataToSign.push(item+"="+params.get(item))
        })
        this.setState({
            signedData: signedFieldNames
        })

        return this.commaSeperate(dataToSign)
    }

    commaSeperate(data) {
        return data.join(",")
    }

    render(){
        // console.log(this.props.payment.payload && this.props.payment.payload.hasOwnProperty('signature'))
        return (
            <div className="padding-bot-32">
                <Header style={style.headerStyle}>
                    <img src={Icons.WebBux} style={style.logoStyle} alt="#" onClick={()=>history.push('/login')}/>
                </Header>
                
                <div style={style.headerStyle1}>
                    <TestModeBanner width={'100%'} />
                </div>
                {/* <TestModeBanner width={'100%'} top={jwtToken == "" || isMobile?60:64} right={0} backgroundColor='rgb(244, 246, 249)' height={33} /> */}
                
                <div hidden={!this.state.fetchLoading}>
                    <Spin size='medium'style={{height: '400px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}/>
                </div>
                <div style={style.mainDiv} align="center" hidden={this.state.fetchLoading}>
                    <div align="center" className="sub-div" hidden={this.state.fetchLoading}>
                        {/* Form data for Signed Data Fields */}
                        <form id="payment_confirmation" ref={f => (this.formRef = f)} onSubmit={this.handleSubmit} hidden={this.state.fieldsAutoPopulate ||
                            this.props.payment.payload && this.props.payment.payload.hasOwnProperty('signature')
                        }>
                            <fieldset>
                                <div id="paymentDetailsSection" className="section">

                                    <SignedDataFields 
                                        bill_to_forename={this.state.bill_to_forename}
                                        bill_to_surname={this.state.bill_to_surname}
                                        bill_to_address_line1={this.state.bill_to_address_line1}
                                        bill_to_address_city={this.state.bill_to_address_city}
                                        bill_to_address_state={this.state.bill_to_address_state} 
                                        bill_to_address_country={this.state.bill_to_address_country}
                                        bill_to_address_postal_code={this.state.bill_to_address_postal_code} 
                                        bill_to_email={this.state.bill_to_email}
                                        bill_to_phone={this.state.bill_to_phone}
                                        handleChange={this.handleChange}
                                        handleRegionChange={this.handleRegionChange}
                                        handleCityChange={this.handleCityChange}
                                        handleCountryChange={this.handleCountryChange}
                                        payment={this.props.payment}
                                        merchant_defined_data41={this.state.merchant_defined_data41}
                                        merchant_defined_data42={this.state.merchant_defined_data42} />

                                </div>
                                
                            </fieldset>

                            <input className="submit-btn-bux" type="submit" id="submit" name="submit" value="Submit and next"/>

                        </form>

                        {/* Form data for Unsigned Data Fields */}
                        <form id="payment_confirmation"
                            action={testMode || ['STG', 'DEV'].includes(process.env.REACT_APP_ENV) ? "https://testsecureacceptance.cybersource.com/silent/pay" : "https://secureacceptance.cybersource.com/silent/pay"}
                            method="post" 
                            hidden={this.props.payment.payload && !this.props.payment.payload.hasOwnProperty('signature')}>
                            <fieldset>
                            {
                                this.props.payment.payload && this.props.payment.payload.hasOwnProperty('signature') ?
                                 Object.entries(this.props.payment.payload.to_be_signed).sort().map((item, i) => {
                                    return(
                                        <input key={i} type="hidden" name={item[0]} value={item[1]} />
                                    )
                                })
                                :
                                this.state.signedData && this.state.signedData.map((item, i) => {
                                    return(
                                        <input key={i} type="hidden" name={item} value={this.state.toSignedData.get(item)} />
                                    )
                                })
                            }
                            {/* <input type="hidden" name="submit" value="Submit" /><br/> */}
                            <input type="hidden" id="signature" name="signature" value={
                                this.props.payment.payload && this.props.payment.payload.hasOwnProperty('signature') ?
                                    this.props.payment.payload.signature : this.state.signature
                            } />

                                <div id="UnsignedDataSection" className="section">

                                    <UnsignedDataFields 
                                        card_type={this.state.card_type}
                                        card_number={this.state.card_number}
                                        card_expiry_date={this.state.card_expiry_date}
                                        card_cvn={this.state.card_cvn}
                                        payment={this.props.payment}
                                        handleChange={this.handleChange}
                                        handleExpiryChange={this.handleExpiryChange}
                                        cardExpiryMoment={this.state.cardExpiryMoment}
                                         />

                                </div>
                            </fieldset>

                            <input disabled={this.disabledSubmit()} className="submit-btn-bux" type="submit" id="submit" name="submit" value="Submit"/>
                        </form>
                    </div>
                </div>
                
                <div className={"padding-top-32"} align='center' hidden={this.state.fetchLoading}>
                    <img src={Icon.PCI} alt="pci-dss" className="right-24" /><img src={Icon.SecuredBux} alt="Secured Bux" />
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SecureCheckout)