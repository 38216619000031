import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ButtonTab from '../../filter_btns';
import { Button, Icon, message, Select, Table, Dropdown, Pagination,
        DatePicker, Input } from 'antd';
import ExportCsvModal from '../../merchant_ids/export_csv_modal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from '../usersAction';
import { history } from '../../../../store/history';
import TableValue from '../../table_value/table_render';
import { segments, NATURE_BUSINESS, numberWithCommas } from '../../../constants/constants';
import { year_choices, channel_choice, payment_choices, transaction_choices,
        ticket_choices, activation_choices, test_account_choices, key_account_choices,
        years} from './constants';
import ClearFilter from '../../filter_btns/clear_filter_btn';
import moment from 'moment';
import UserMenu from './userHistory/components/menu';
import AddUsers from './addUsersForm';
import FilterUser from './modal/filterUser';

const User = props => {

  const Option  = Select.Option;
  const dispatch = useDispatch();

  const users = useSelector(state => state.users.users)
  const [selectedSegments, setSelectedSegments] = useState([segments.BUx_ME, segments.BUx_GIG, segments.BUx_SME, segments.BUx_BIZ, segments.Unsegmented])
  // const [emailReceipt, setEmailReceipt] = useState("");

  //main filter
  const [isFiltered, setIsFiltered] = useState(false)
  const [selected, setSelected] = useState("All")
  const [selectedLevel, setSelectedLevel] = useState("All")
  const [searchValue, setSearchValue] = useState("");
  const [startMoment, setStartMoment] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endMoment, setEndMoment] = useState("")
  const [endDate, setEndDate] = useState(moment().format('MM-DD-YYYY'))
  const [endOpen, setEndOpen] = useState(false)
  const [page, setPage] = useState(1);
  const [testAccountChecked, setTestAccountChecked] = useState(['Real'])

  const [responseMessage, setResponseMessage] = useState("");
  const [openExportModal, setOpenExportModal] = useState(false);
  const [keyAccountChecked, setKeyAccountChecked] = useState([])
  const [isCorporateFiltered, setCorporateFiltered] = useState(false)

  //filter modal
  const [filterUserModal, setFilterUserModal] = React.useState(false);
  const [acceptOnline, setAcceptOnline] = useState("")
  const [yearsChecked, setYearsChecked] = useState([])
  const [naturesChecked, setNatureChecked] = useState([])
  const [channelsChecked, setChannelsChecked] = useState([])
  const [typesChecked, setTypesChecked] = useState([])
  const [transactionChecked, setTransactionChecked] = useState([])
  const [avgTicketChecked, setAvgTicketChecked] = useState([])
  const [activationsChecked, setActivationChecked] = useState([])

  const [kybStep, setKybStep] = useState(null);
  const [showMenu, setShowMenu] = React.useState(true);
  const isIndividual = selectedSegments.includes('ME_') || selectedSegments.includes('GIG')
  // edit user modal
  const bux_segments = {
    "ME_": "BUxME",
    "GIG": "BUxGIG",
    "SME": "BUxSME",
    "BIZ": "BUxBIZ",
  }


  const onPageChange = (selectedPage) => {
    setPage(selectedPage);
    dispatch(fetchUsers(selectedPage, "", startDate, endDate, selectedSegments.toString(), selectedLevel))
  }

  /* const function for handling of changes in filter */
  const getYearsKey = () => {
    let all_years = []

    for(let i=0; i < yearsChecked.length; i++){
      let year = yearsChecked[i]
      all_years.push(year_choices[year].value)
    }
    return all_years
  }

  const getNatureKey = () => {
    let all_natures = []

    for(let i=0; i < naturesChecked.length; i++){
      let nature = naturesChecked[i]
      all_natures.push(NATURE_BUSINESS.indexOf(nature))
    }
    return all_natures
  }

  const getChannelsKey = () => {
    let all_channels = []

    for(let i=0; i < channelsChecked.length; i++){
      let channel = channelsChecked[i]
      all_channels.push(channel_choice[channel].value)
    }
    return all_channels
  }

  const getPaymentsKey = () => {
    let all_payments = []

    for(let i=0; i < typesChecked.length; i++){
      let payment = typesChecked[i]
      all_payments.push(payment_choices[payment].value)
    }
    return all_payments
  }

  const getTransKey = () => {
    let all_transactions = []

    for(let i=0; i < transactionChecked.length; i++){
      let transaction = transactionChecked[i]
      all_transactions.push(transaction_choices[transaction].value)
    }
    return all_transactions
  }

  const getTicketKey = () => {
    let all_tickets = []

    for(let i=0; i < avgTicketChecked.length; i++){
      let ticket = avgTicketChecked[i]
      all_tickets.push(ticket_choices[ticket].value)
    }
    return all_tickets
  }

  const getActivationKey = () => {
    let all_activations = []

    for(let i=0; i < activationsChecked.length; i++){
      let activation = activationsChecked[i]
      all_activations.push(activation_choices[activation].value)
    }
    return all_activations
  }

  const getKeyAccountKey = () => {
    let all_key_accounts = []

    for(let i=0; i < keyAccountChecked.length; i++){
      let accounts = keyAccountChecked[i]
      all_key_accounts.push(key_account_choices[accounts].value)
    }
    return all_key_accounts
  }

  const getTestAccountKey = () => {
    let all_test_accounts = []

    if(selected === 'Test Accounts') {
      return 'Test'
    }

    for(let i=0; i < testAccountChecked.length; i++){
      let accounts = testAccountChecked[i]
      all_test_accounts.push(test_account_choices[accounts].value)
    }
    return all_test_accounts
  }

  const disabledStartDate = startValue => {
    
    if (!startValue || !endMoment) {
      return false;
    }
    return startValue && startValue > moment().endOf('day');
  };

  const disabledEndDate = endValue => {
    
    if (!endValue || !startMoment) {
      return false;
    }
    return endValue.valueOf() <= startMoment.valueOf();
  };

  const handleStartOpenChange = open => {
    if (!open) {
      setEndOpen(true)
    }
  };

  const handleEndOpenChange = open => {
    setEndOpen(open)
  };

  const onCheckAllChange = (e, allChoices, setChecked, setCheckAll) => {
    setChecked(e.target.checked ? allChoices : [])
    setCheckAll(e.target.checked)
  };

  const onCheckChange = (list, setChecked, setCheckAll) => {
    setChecked(list)
    setCheckAll(list.length == years.length)
  };

  const menuState = () => {
    setShowMenu(true)
  }

  /* end of const function for handling of changes in filter */

  /* columns available in table */
  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      render: (text, record) => (
        TableValue(record.id, record.bux_merchant_id)
      )
    },
    {
      key: 'business_name',
      title: 'Business Name',
      dataIndex: 'business_name',
      render: (text, record) => (
        TableValue(record.business_name)
      ),
      width: '15%',
    },
    {
      key: 'email',
      title: 'Full name and Email',
      width: '15%',
      render: (text, record) => (
        TableValue(record.full_name, record.email)
      )
    },
    {
      key: 'segment',
      title: 'Role',
      dataIndex: 'segment',
      width: '10%',
      render: (text, record) => (
        TableValue(record.bux_segment == "" || record.bux_segment == "NIL" ? "- -" : bux_segments[record.bux_segment])
      )
    },
    {
      key: 'eon_level',
      title: 'Level',
      dataIndex: 'eon_level',
      width: '10%',
      render: (text, record) => (
        TableValue(record.eon_level)
      )
    },
    // {
    //   title: 'Balance',
    //   dataIndex: 'balance',
    // },
    {
      key: 'created_at',
      title: 'Date created',
      dataIndex: 'created_at',
      render: (text, record) => (
        TableValue(record.created_at)
      )
    },
    {
      key: 'user_approval_date',
      title: selectedSegments.includes('BIZ') || selectedSegments.includes('SME') ? 'Date approved' : 'Submission date',
      dataIndex: 'user_approval_date',
      render: (text, record) => (
        TableValue(record.user_approval_date)
      )
    },
    {
      title: 'Account',
      dataIndex: 'key_account',
      render: (text, record) => (
        TableValue(text ? "Key" : "Non-Key")
      )
    },
    {
      title: 'Action',
      render: (text) => (
        <Dropdown overlay={
          <UserMenu data={text} applyBtn={applyBtn} showMenu={showMenu} setShowMenu={setShowMenu} />
        } 
        placement="bottomCenter" trigger={['click']}>
          <a href onClick={() => menuState()}>
            <Icon type="ellipsis" />
          </a>
        </Dropdown>
        // TableValue(text ? "Key" : "Non-Key")
      )
    }
  ];

  console.log(showMenu)

  const columns2 = [
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
    },
    {
      key: 'full_name',
      title: 'Full Name',
      dataIndex: 'full_name',
    },
    {
      key: 'created_at',
      title: 'Date created',
      dataIndex: 'created_at',
    }
  ];

  /* end oof columns available in table */


  const kyb_steps = [
    'All',
    'Business Info',
    'Contact Persons',
    'Business Docs',
    'Signatory Info',
    'MOA and NDA',
    'Bank Account',
    'For Review',
    'Submitted',
    'Resubmitted',
    'Rejected KYB',
    'Approved KYB',
    'Post Approval KYB'
  ];

  /* Start of Filter Function */
  const handleFilter = () => {
    setPage(1);
    setIsFiltered(true);
  }

  const handleKeyAccChange = (e) => {
    const val = e === 'All' ?
                  ['Key','Non-Key'] 
                : 
                e === 'Key' ? 
                  [e] 
                : 
                  ['Non-Key'];
    setKeyAccountChecked(val);
    handleFilter();
  }

  const handleStepChange = (e) => {
      const val = e === 0 ? null : e;
      setKybStep(val);
      handleFilter();

  }

  const handleLevelChange = (e) => {
    setSelectedLevel(e);
    handleFilter();

  }

  const handleSearch = (e) => {
    setSearchValue(e.target.value); 
    handleFilter();
  }

  const onStartChange = value => {
    console.log(value)
    if(value != null){
      setStartMoment(value)
      setStartDate(value.format("MM-DD-YYYY"))
      setIsFiltered(true)
    }
    else{
      setStartMoment(null)
      setStartDate("")
    }
  }
  const onEndChange = value => {

    if(value != null && startDate != ""){
      setEndMoment(value)
      setEndDate(value.format("MM-DD-YYYY"))
      setIsFiltered(true)
    }
    else{
      setEndMoment(null)
      setEndDate("")        

    }
  }

  const clearFilter = () => {
    setIsFiltered(false)
    setKeyAccountChecked([]);
    setSelectedLevel("All");
    setKybStep(null);
    setPage(1);
    setStartDate("")
    setEndDate(moment().format('MM-DD-YYYY'));
    setStartMoment("")
    setEndMoment("")
    setSearchValue("")
    dispatch(fetchUsers(1, "", "", moment().format('MM-DD-YYYY'), selectedSegments.toString(), "All"))

  }

  const applyBtn = () => {
    dispatch(fetchUsers(1, searchValue, startDate, endDate, selectedSegments.toString(), selectedLevel, 
                        isCorporateFiltered, getYearsKey(), getNatureKey(), 
                        acceptOnline, getChannelsKey(), getPaymentsKey(),
                        getTransKey(), getTicketKey(), getActivationKey(), 
                        getKeyAccountKey(), getTestAccountKey(), kybStep));
  }

  /* End of Filter Function */

  /* Start of Download CSV Function */
  const DownloadUsers = async (email) => {
    if(email == ''){
      setResponseMessage("Please input an email address")
      return;
    }
    let yourConfig = {
      headers: {
          Authorization: "Token " + localStorage.getItem("jwtToken")
      }
    }
    let query_params = [
      'email=' + email,
      'search=' + searchValue,
      'start=' + startDate,
      'end=' + endDate,
      'type=' + selected, 
      'segment=' + selectedSegments,
      'level=' + selectedLevel
    ];
    let response = await axios.get(
      process.env.REACT_APP_API_URL + '/api/admin/users/download/?'+ query_params.join("&"),yourConfig
    );
    
    if(response.data){
      message.success(response.data.message);
      setOpenExportModal(false)
    }
    else{
      message.error(response.data.message)
    }
  }

  /* End of Download CSV Function */

  /* Edit user modal function */

  useEffect(() => {

    if(localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") == null){
      history.push('/login')
    }
    else{
      if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
        history.push('/dashboard')
      }
      else if(localStorage.getItem("userType") == "PS"){
        history.push('/orders')
      }
      else if(localStorage.getItem("userType") == "PF"){
        history.push('/recon/7_eleven')
      }
      else{
        
        dispatch(fetchUsers(1, "", startDate, endDate, selectedSegments.toString(), selectedLevel))
      }
      
    }
  }, [selectedSegments])

  let total = users.total ? users.total : 0;
  return (
    <div style={{padding: '0 32px 64px 0'}}>
      <div style={{display: 'flex'}}>
        <div className="screen-title-text">
          All Users 
        </div>
        <div style={{marginTop: '-8px', marginLeft: 8}}>
          <button className="total-user normalTextStyle">
            Total {numberWithCommas(total)}
          </button>
        </div>
      </div>

      <div className="twoCol top-20">
        <div>
          <ButtonTab rootStyle={{padding: '0'}} 
          setSelected={setSelected}
          setSelectedSegments={setSelectedSegments}
          segments={segments}  />
        </div>

        <div className="flex-around">
          {localStorage.getItem("userType") === "AD" ?
              <AddUsers /> : ""
          }
          <Button
            className="admin-download-btn"
            onClick={() => setOpenExportModal(true)}>
              <Icon type='download' />
              Download CSV
          </Button>
        </div>
      </div>

      <div className="table-card-div">
        <ClearFilter hideFilterBtn={!isFiltered} clearFilter={clearFilter} />

        <div className="filter-div">
          <Select 
              size="large" 
              placeholder="Key Account Type" 
              style={{width: '10%', marginRight: '12px', zIndex: 1}}
              onChange={handleKeyAccChange}
            >
              <Option value="All">All</Option>
              <Option value="Key">Key</Option>
              <Option value="Non-Key">Non-Key</Option>
          </Select>
          
          <div hidden={isIndividual} className="flex">
            <Select 
                  className="right-16"
                  size="large" 
                  placeholder="KYB step" 
                  style={{width:'120px', marginRight: '12px', zIndex: 1}}
                  onChange={handleStepChange}
                  value={kybStep}
                >
                  {kyb_steps.map((step,i) => <Option value={i === 0? 'All' : i}>{i !==0? `[${i}] ` + step : step}</Option>)}
            </Select>

            <button className="filter-user-btn right-16"
              style={{
                border: isCorporateFiltered ? '1px solid #0D3D76' : '1px solid #c5cacf'
              }}
              onClick={() => setFilterUserModal(true)}>
              <div className="space-around">
                <span>
                  Filter User
                </span>
                <span>
                 <Icon type="filter" theme="filled" hidden={isCorporateFiltered} />
                 <Icon type="close-circle" theme="filled" hidden={!isCorporateFiltered} onClick={(e)=>{
                    isCorporateFiltered && 
                    e.stopPropagation()
                    setCorporateFiltered(false);
                    dispatch(fetchUsers(1, searchValue, startDate, endDate, selectedSegments, "All", false, null, null, null, null, null, null, null, null, getKeyAccountKey(), getTestAccountKey(), kybStep));
                  }}/>
                </span>
              </div>
            </button>
          </div>

          <Select 
                size="large" 
                placeholder="Level" 
                style={{width: isIndividual ? '12%' : '150px', marginRight: '12px', zIndex: 1}}
                onChange={handleLevelChange}
                value={selectedLevel}
              >
            <Option value="All">All</Option>
            <Option value="0">Unverified</Option>
            <Option value="4">KYC Approved</Option>
            <Option value="5">KYB Approved</Option>
          </Select>

          <DatePicker
                style={{zIndex: 1, width: isIndividual ? '12%' : '11vw'}}
                disabledDate={disabledStartDate}
                size="large"
                format='MM-DD-YYYY'
                value={startMoment || null}
                placeholder="Start"
                onChange={onStartChange}
                onOpenChange={handleStartOpenChange}
              />
          <span style={{padding: '8px'}}>-</span> 
          <DatePicker
              style={{zIndex: 1, marginRight: '12px', width: isIndividual ? '12%' : '11vw'}}
              disabledDate={disabledEndDate}
              size="large"
              format='MM-DD-YYYY'
              value={endMoment || null}
              placeholder="End"
              onChange={onEndChange}
              open={endOpen}
              onOpenChange={handleEndOpenChange}
          />

          <Input
              placeholder="Search"
              value={searchValue}
              style={{ width: isIndividual ? '11%' : '10vw', height: '40px', zIndex: '2' }}
              onChange={handleSearch}
              suffix={<Icon type="search" />}
            /> 

          <button
              style={{marginLeft: 15, width: isIndividual ? '11%' : ''}}
              className={`admin-apply-btn ${!isFiltered ? "admin-disabled-btn" : ""}`}
              disabled={!isFiltered}
              onClick={applyBtn}
          >
              Apply
          </button>
          
        </div>
       
        <Table
            rowKey={user => user.id}
            style={{width: '100%'}}
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
            dataSource={users.results}
            // rowSelection={rowSelection}
            pagination={{hideOnSinglePage: true, pageSize: 20}}
            columns={localStorage.getItem('userType') == 'MA' ? columns2 : columns} />
      
          <div align="right" className="top-20">
              <Pagination
                  size="small"
                  total={users.total}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  onChange={onPageChange}
                  defaultCurrent={1}
                  pageSize={20}
                  current={page}
              />
          </div>
      </div>

      <ExportCsvModal isExportModalVisible={openExportModal} 
          onCancelOrClose={() => setOpenExportModal(false)}
          onSend={DownloadUsers}
      />

      <FilterUser openModal={filterUserModal} setOpenModal={setFilterUserModal}
        onCheckAllChange={onCheckAllChange} onCheckChange={onCheckChange} 
        acceptOnline={acceptOnline} setAcceptOnline={setAcceptOnline}
        yearsChecked={yearsChecked} setYearsChecked={setYearsChecked}
        naturesChecked={naturesChecked} setNatureChecked={setNatureChecked}
        channelsChecked={channelsChecked} setChannelsChecked={setChannelsChecked}
        typesChecked={typesChecked} setTypesChecked={setTypesChecked}
        transactionChecked={transactionChecked} setTransactionChecked={setTransactionChecked}
        avgTicketChecked={avgTicketChecked}  setAvgTicketChecked={setAvgTicketChecked}
        activationsChecked={activationsChecked} setActivationChecked={setActivationChecked}
        keyAccountChecked={keyAccountChecked} setKeyAccountChecked={setKeyAccountChecked}
        setCorporateFiltered={setCorporateFiltered} applyBtn={applyBtn}
        />
    </div>
  )
}

export default User;