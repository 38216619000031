import React,{ useState, useEffect } from "react";
import { Card, Button, Typography, Icon, Spin, List } from "antd";
import { TopUpsGaming, TopUpsTelecom, TopUpsTranspo, TopUpsUtilities } from '../../../../static/icons/dashboard_icons';
import PropTypes from 'prop-types';
import { history } from '../../../../store/history';
import axios from 'axios'
import { Handle401 } from '../../../handle401/handle401';
import moment from 'moment';

const topUpsButtons = [
  {
    name: 'Gaming',
    icon: <img src={TopUpsGaming} />,
    key: 'gaming'
  },
  {
    name: 'Telecoms',
    icon: <img src={TopUpsTelecom} />,
    key: 'telecom'
  },
  {
    name: 'Transpo',
    icon: <img src={TopUpsTranspo} />,
    key: 'transpo'
  },
  {
    name: 'Utilities',
    icon: <img src={TopUpsUtilities} />,
    key: 'utilities'
  },
]


const IconButton = ({icon, name}) => {
  return (
    <div>
     {/* <Icon component={PaybillsGaming} /> */}
      <div style={{width: '100%', border: '1px solid #DDDDDD', borderRadius: 10, padding: 10}}><a>{icon}</a></div>
      <Typography>{name}</Typography>
    </div>
  )
}

IconButton.propTypes = {
  icon: PropTypes.element,
  name: PropTypes.string,
};

const renderButtons = (buttons) => {
  return (
    buttons.map((item, index) => {
      return (
        <IconButton key={index} name={item.name} icon={item.icon} />
      )
    })
  );
}

const ButtonGroup = ({ btnClicked, handleClick, custId, segments }) => {
  const kyc_lvl = localStorage.getItem('Level');
  const isMulitwallet = localStorage.getItem("is_multiwallet") === 'true';
  const userType = localStorage.getItem("userType");
  const isChild = localStorage.getItem("is_child") === 'true';
  
  return (
    <div hidden={isChild || (!isMulitwallet && userType === 'CO') || userType === 'SW' && !custId || !custId && kyc_lvl >= 0 && userType === 'ME'}>
      <Button.Group style={styles.groupBtn}>
        <Button id='buxBtn' value='bux'
        className={btnClicked === 'bux' ? `bg-${segments}` : `'`}
        style={{
          width: !custId && kyc_lvl >= 1 && userType === 'ME' ? '100%' : '50%',
          height: 48,
          color: `${btnClicked === 'bux' ? '#FFFFFF' : '#2B2D33'}`,
          backgroundColor: '#FFFFFF',
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
          borderTopRightRadius: !custId && kyc_lvl >= 1 && userType === 'ME' ? 10 : 0,
          borderBottomRightRadius: !custId && kyc_lvl >= 1 && userType === 'ME' ? 10 : 0,
          fontSize: 12,
          fontWeight: 'bold'
        }}
          hidden={ !custId && kyc_lvl >= 1 && userType === 'ME'} 
          onClick={handleClick}>BUx</Button>
        <Button id='eonBtn' value='eon' style={{
          width: '50%',
          height: 48,
          color: `${btnClicked === 'eon' ? '#FFFFFF' : '#2B2D33'}`,
          backgroundColor: `${btnClicked === 'eon' ? '#0D3D76' : '#FFFFFF'}`,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
          fontSize: 12,
          fontWeight: 'bold'
        }} 
          hidden={ !custId && kyc_lvl >= 1 || userType === 'CO'}
          onClick={handleClick}>EON</Button>
        <Button id='multiwallet' value='multiwallet' 
        className={btnClicked === 'multiwallet' ? `bg-${segments}` : `'`}
        style={{
          width: '50%',
          height: 48,
          color: `${btnClicked === 'multiwallet' ? '#FFFFFF' : '#2B2D33'}`,
          backgroundColor: '#FFFFFF',
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
          fontSize: 12,
          fontWeight: 'bold'
        }} 
          hidden={userType === 'ME'}
          onClick={handleClick}>Wallet History</Button>
    </Button.Group>
    </div>
  )
}  

const returnType = (type) => {
  switch (type) {
    case 'Ibft Realtime':
      return 'Top up';
    case 'Pay Bills':
     return 'Bills Payment';
    case 'Pay Bills Reversal':
      return 'Bills Payment Reversal';
    case true:
      return 'Transfer Money to';
    case false:
      return 'Receive money from';
    default:
      return type;
  }
}

const returnNA = (data) => {
  if(!data || data === '' || data === undefined) {
    return "N/A";
  }
    return data;
}

const renderTransaction = (transactions, btn) => {
  return (
    transactions ?
    <div>
      <List
        dataSource={transactions}
        renderItem={item => 
          <List.Item style={{width: '100%'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
              <div align='left'>
                <Typography style={styles.desc}>{item.desc}</Typography>  
                <Typography style={styles.name}>{returnType(item.name)}</Typography>  
              </div>
              <div align='right'>
                <Typography style={styles.desc}>{moment(item.date_str).format('MMMM D, YYYY')}</Typography>  
                <Typography 
                  style={{
                    fontSize: 16,
                    color: `${ item.type === 'D' ? '#E24C4C'
                              : '#1DD28B' }`, fontWeight: 'bold'}}
                >
                  {item.type === 'D' ? '-' : '+'}₱{item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>  
              </div>
            </div>
          </List.Item>
        }
      />
    </div>
    : <Spin size='default' />
  )
}

const renderMuliwalletTranx = (transactions, btn) => {
  return (
    transactions ?
    <div>
      <List
        dataSource={transactions}
        renderItem={item => 
          <List.Item style={{width: '100%'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
              <div align='left'>
                <Typography style={styles.desc}>{returnType(item.is_sender)}</Typography>  
                <Typography style={styles.name}>{item.is_sender? returnNA(item.receiver.name) : returnNA(item.sender.name)}</Typography>  
              </div>
              <div align='right'>
                <Typography style={styles.desc}>{moment(item.timestamp).format('MMMM D, YYYY')}</Typography>  
                <Typography 
                  style={{
                    fontSize: 16,
                    color: item.is_sender? '#C0492C' : '#62CE90', fontWeight: 'bold'}}
                >
                  {item.is_sender? '-':'+'}₱{item.amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>  
              </div>
            </div>
          </List.Item>
        }
      />
    </div>
    : <Spin size='default' />
  )
}

const Content = ({ toggleModal, sideBar, custId, isTestMode }) => {
  const [showLoadCardModal, setShowLoadCardModal] = useState(false);
  const [btnClicked, setBtnClicked] = useState('bux');
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [transactions, setTransactions] = useState([])
  const segments = localStorage.getItem('segments')

  const getHistory = async (target) => {
      let yourConfig = {
          headers: {
              Authorization: "Token " + localStorage.getItem("jwtToken")
          }
      }

      try {
          let url = target === 'multiwallet' ?
                     isTestMode ?
                      `/api/sandbox/multiwallet/transfers/?type=all`
                      :
                      `/api/multiwallet/transfers/?type=all` : 
                      isTestMode ?
                      '/api/sandbox/history/?type='+target :
                      '/api/history/?type='+target;

          let response = await axios.get(process.env.REACT_APP_API_URL + url, yourConfig);
          if(target === 'multiwallet') {
            setTransactions(response.data.results.slice(0, 5))
          } else {
            setTransactions(response.data.slice(0, 5))
          }
      }

      catch (error) {
          if ((error.response) && (error.response.status == 401)) {
              Handle401()
          }

      }
  }

  const onViewAllClick = () => {
    if(btnClicked==='bux'){
      history.push(isTestMode?'/test/orders':'/orders');
    } else if(btnClicked==='eon') {
      history.push('/card');
    } else {
      history.push({ pathname: `/multiwallet`, state: { selectedTab: 'tranx' } });
    }
  }

  const handleClick = (event) => {
    setBtnClicked(event.target.value);
    setTransactions(null)
    setIsButtonLoading(true);
    setTimeout(() => {
      setIsButtonLoading(false);
    }, 300);
    if (event.target.value === 'bux') {
      getHistory('bux');
    } else if (event.target.value === 'eon') {
      getHistory('eon');
    } else {
      getHistory('multiwallet');
    }
  }

  useEffect(() => {
      getHistory('bux');
  }, []);

  return (
    <div id='mainDiv'>
      <Card size="small" bordered={false} bodyStyle={styles.cardBody} style={{ borderRadius:`${ sideBar ? '0px 0px 4px 4px' : '4px' }`, padding: '0px 12px 12px 12px' }}>
        <div style={styles.mainContent} >
          <ButtonGroup id='tranxBtn' btnClicked={btnClicked} handleClick={handleClick} custId={custId} segments={segments} />
          { transactions && !isButtonLoading 
            ? btnClicked === 'multiwallet'? renderMuliwalletTranx(transactions) : renderTransaction(transactions, btnClicked)
            : transactions && isButtonLoading 
            ? <Spin size='large' 
                style={{height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '600' }} 
              />
            : <Spin size='large' 
                style={{height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '600' }} 
              /> }
        </div>
        <hr style={styles.divider}></hr>
        <Typography hidden={transactions && transactions.length == 0} style={{ padding: 10, fontWeight: '600', textAlign: 'center'}}><a style={{ fontSize: 14, textAlign: 'center'}} className={`text-${segments}`} onClick={onViewAllClick}>View all</a></Typography>
      </Card>
    </div>
  )
}

const styles = {
  regularFont: {
    fontSize: 12,
    color: '#000000'
  },
  input: {
    padding: '11px 16px 11px 16px',
    fontSize: 16,
    height: 48,
    margin: '4px 0px 0px 0px',
  },
  btn: {
    margin: '16px 0px 0px 0px',
    height: 48,
    width: '100%',
    backgroundColor: '#F5922F',
    color: '#FFFFFF',
    borderRadius: 10,
    fontSize: 14,
    fontWeight: '600',
  },
  mainContent: {
    // display: 'flex',
    // justifyContent: 'space-around',
    // alignItems: 'center'
  },
  cardBody: {
    padding: 0,
  },
  groupBtn: {
    fontSize: 12,
    color: '#54575F',
    textAlign: 'center',
    padding: '0px 0px 16px 0px',
    width: '100%',
  },
  divider: {
    borderTop: '.1px solid #E6EAF0', 
    width: '100%', 
    // margin: '10px 0px 0px 0px'
  },
  desc: {
    fontSize: 12,
    color: '#2B2D32'
  },
  name: {
    fontSize: 16,
    color: '#2B2D33',
    fontWeight: '600'
  }
}

export default Content;