import { axios } from "../../../../../../lib/axios";
import { useState, useEffect } from "react";
import moment from "moment";

export function getRevenues(
  currentPage,
  walletId,
  searchQuery,
  startDate,
  endDate,
) {
  const encodedSearchQuery = encodeURIComponent(searchQuery);
  const formattedStartDate = startDate
    ? moment(startDate, "MM-DD-YYYY").format("MM-DD-YYYY")
    : "";
  const formattedEndDate = endDate
    ? moment(endDate, "MM-DD-YYYY").format("MM-DD-YYYY")
    : "";
  const summary = formattedStartDate || formattedEndDate ? "Custom" : false;

  return axios.get(
    `api/transfer/revenue/?page=${currentPage}&revenue_wallet_id=${walletId}&search=${encodedSearchQuery}&start=${formattedStartDate}&end=${formattedEndDate}&summary=${summary}`,
  );
}

export function useRevenues(
  currentPage,
  walletId,
  searchQuery,
  startDate,
  endDate,
) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getRevenues(
          currentPage,
          walletId,
          searchQuery,
          startDate,
          endDate,
        );
        setData(response);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, [currentPage, walletId, searchQuery, startDate, endDate]);

  return { data, isLoading, error };
}
