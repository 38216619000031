import React from 'react';
import { Card, Typography, Button } from 'antd';
import { history } from '../../../store/history';
import { BestViewedMobileIcon } from '../../../static/images/multiwallet';
import { isMobile } from 'react-device-detect';

const MultiwalletMobile = (props) => {

    const redirectPage = () => {
        let current_path = window.location.pathname
        if (localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null) {
            history.push('/login?next=' + current_path)
        } else if (!['CO', 'SW'].includes(localStorage.getItem("userType"))) {
            history.push('/dashboard')
        } else if (!isMobile) {
            if (localStorage.getItem("userType") === "SW") {
                history.push('/bux_wallet');
            } else {
                history.push('/multiwallet')
            }
        }
    }

    React.useEffect(() => {
        redirectPage();
    })

    return (
        <div style={styles.root}>
            <Card
                style={styles.cardRoot}
                bodyStyle={styles.cardBody}
            >
                <img alt='No Image' src={BestViewedMobileIcon} style={{ height: 132 }} />
                <Typography style={styles.text}>This page is best viewed in our Desktop page.</Typography>
                <Button hidden={localStorage.getItem("userType") === "SW"} style={styles.btn} type='link' onClick={() => history.push('dashboard')}>
                    Back to Dashboard
                </Button>
            </Card>
        </div>
    )
}

const styles = {
    root: {
        height: '100vh',
        backgroundColor: '#F4F6F9',
        paddingTop: 1,
    },
    cardRoot: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        border: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: 10,
        margin: '26px 16px'
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        aligItems: 'content'
    },
    text: {
        color: '#2B2D33',
        fontSize: 16,
        padding: '16px 16px 7px 16px',
        textAlign: 'center'
    },
    btn: {
        color: '#F5922F',
        fontSize: 17,
        fontWeight: '600',
        padding: '7px 0',
        textAlign: 'center'
    }
}

export default MultiwalletMobile;