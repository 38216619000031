import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, message, Input, Switch, Form, Row, Col } from 'antd';
import { validateEmail, validateMobileNumber } from '../../../../utils/validators';

const EditUserModal = Form.create({ name: 'edit_user_modal' })(({ form, openModal, setOpenModal, data }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { getFieldDecorator, setFieldsValue } = form;

  useEffect(() => {
    if (data) {
      setFieldsValue({
        merchant_name: data.merchant_name || '',
        email: data.email || '',
        first_name: data.first_name || '',
        last_name: data.last_name || '',
        contact: data.contact || '',
        verified: data.verified || false,
        completed: data.completed || false,
      });
    }
  }, [data, setFieldsValue]);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (err) return;
      setIsLoading(true);

      try {
        const params = {
          id: data.id,
          ...values,
        };

        const yourConfig = {
          headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        };

        const response = await axios.post(
          process.env.REACT_APP_API_URL + '/api/edit_user/',
          params,
          yourConfig
        );

        if (response.data.status === 'success') {
          setOpenModal(false);
          window.location.reload();
        } else {
          message.error(response.data.message);
        }
      } catch (error) {
        message.error('An error occurred. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    });
  };

  return (
    <Modal
      title="Edit User"
      centered
      visible={openModal}
      onCancel={() => setOpenModal(false)}
      footer={[
        <Button key="back" onClick={() => setOpenModal(false)} className="admin-download-btn">
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit} className="add-user" loading={isLoading}>
          Save
        </Button>,
      ]}
    >
      <Form onSubmit={handleSubmit}>
        <Form.Item label="Merchant Name" style={{ marginBottom: '20px' }}>
          {getFieldDecorator('merchant_name', {
            rules: [{ required: true, message: 'Please input merchant name!' }],
          })(<Input placeholder="Merchant Name" />)}
        </Form.Item>

        <Form.Item label="Email" style={{ marginBottom: '20px' }}>
          {getFieldDecorator('email', {
            rules: [
              { required: true, message: 'Please input email!' },
              { validator: (_, value) => validateEmail(value) },
            ],
          })(<Input placeholder="Email" />)}
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="First Name" style={{ marginBottom: '20px' }}>
              {getFieldDecorator('first_name', {
                rules: [{ required: true, message: 'Please input first name!' }],
              })(<Input placeholder="First Name" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last Name" style={{ marginBottom: '20px' }}>
              {getFieldDecorator('last_name', {
                rules: [{ required: true, message: 'Please input last name!' }],
              })(<Input placeholder="Last Name" />)}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Mobile Number" style={{ marginBottom: '20px' }}>
          {getFieldDecorator('contact', {
            rules: [
              { required: true, message: 'Please input mobile number!' },
              { validator: (_, value) => validateMobileNumber(value) },
            ],
          })(<Input placeholder="Mobile Number" addonBefore="+63" />)}
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Verified" style={{ marginBottom: '20px' }}>
              {getFieldDecorator('verified', {
                valuePropName: 'checked',
              })(<Switch />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Completed" style={{ marginBottom: '20px' }}>
              {getFieldDecorator('completed', {
                valuePropName: 'checked',
              })(<Switch />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});

export default EditUserModal;