import React from 'react'
import * as Style from './kyc_style'
import {isMobile, isAndroid} from 'react-device-detect'
import {Card, Button, Icon, Typography} from 'antd'
import {submitDetails, setStep} from './application_main'
import { history } from '../../../store/history'
import faker from 'faker'

const ReviewSubmit = props => {
    
    const [btnSelected, setBtnSelected] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const segments = localStorage.getItem('segments')

    const GENDER = {
        'M': 'Male',
        'F': 'Female'
    }

    const CivilStatus = {
        'S': 'Single', 
        'M': 'Married',
        'W': 'Widowed', 
        'D': 'Divorced'
    }

    const EmploymentStatus = {
        'EMP': 'Employed',
        'CON': 'Consultant',
        'SEB': 'Self-employed (Business)',
        'SEF': 'Self-employed (Freelancer)',
        'UEH': 'Unemployed (Housewife)',
        'UER': 'Unemployed (Retired)',
        'UES': 'Unemployed (Student)'
    }

    const NatureOfWork = {
        'ADM': 'Administrative/Human Resources',
        'AGRI': 'Agriculture',
        'BNK': 'Banking Institutions',
        'IT': 'Computer and Information Technology Services',
        'CONS': 'Construction/Contractors',
        'AGN': 'Consultancy/Agencies',
        'EDUC': 'Education',
        'ENG': 'Engineering',
        'ENT': 'Entertainment',
        'FIN': 'Financial Services',
        'FOV': 'Government',
        'HRS': 'Hotel and Restaurant Services',
        'HSE': 'Household Employees',
        'INV': 'Manufacturing and Inventory',
        'MED': 'Medical and Health Services',
        'SOC': 'Community, Social, Personal Services',
        'OTH': 'Others',
        'PR': 'Public Relations',
        'EST': 'Real Estate',
        'RENT': 'Rental Services',
        'MKTG': 'Sales/Marketing/Advertising',
        'SCI': 'Science and Technology Services',
        'STD': 'Student',
        'TCS': 'Transportation and Communication Services'
    }

    const SourceFunds  = {
        '001': 'Allowance',
        '002':'Business Profit',
        '003':'Commissions',
        '004':'Inheritance',
        '005':'Loan',
        '006':'Salary',
        '007':'Pension',
        '008':'Remittance'
    }

    const IDType = { 
        'SSS': 'SSS',
        'GSIS': ' GSIS',
        'DRL': `Driver's License`,
        'AICR': ' ACR',
        'NBI': 'NBI',
        'PAS': 'Passport',
        'PHID': ' Philhealth ID',
        'SCH': 'School ID',
        'UMID': ' UMID',
        'VID': 'Voters ID',
        'PRC': 'PRC ID',
        'POS': 'Digitize Postal ID',
        'PIC': 'Philippine Identification Card'
    }

    const randomProperty = (obj) => {
        let keys = Object.keys(obj)
        return obj[keys[ keys.length * Math.random() << 0]];
    }

    const datas = [

        {
            'title': 'Personal Information',
            'code': 0,
            'data': [
                {
                    'title': 'Name',
                    'value': testMode ? localStorage.getItem("firstName") + " " + localStorage.getItem("lastName") :  props.details.first_name + " " + props.details.middle_name + " " + props.details.last_name
                },
                {
                    'title': 'Gender',
                    'value': testMode ? randomProperty(GENDER) : GENDER[props.details.gender]
                },
                {
                    'title': 'Status',
                    'value': testMode ? randomProperty(CivilStatus) : CivilStatus[props.details.civil_status]
                },
                {
                    'title': 'Nationality',
                    'value': props.details.nationality
                },
                {
                    'title': 'Birthday',
                    'value': testMode ? faker.date.month() + ` ${Math.floor(Math.random() * 31)}` +", 1994" : props.details.birthday
                },
                {
                    'title': 'Birthplace',
                    'value': testMode ? faker.address.city() : props.details.birthplace
                },
                {
                    'title': 'Mother’s Maiden Name',
                    'value': testMode ? faker.name.firstName() + " "+ faker.name.lastName() : props.details.mothers_maiden_name
                },
            ]
        },
        {
            'title': 'Contact Information',
            'code': 1,
            'data': [
                {
                    'title': 'Mobile Number',
                    'value': "+63"+props.details.contact
                },
                {
                    'title': 'Email',
                    'value': props.details.email
                },
            ]
        },
        {
            'title': 'Employment Information',
            'code': 2,
            'data': [
                {
                    'title': 'Employment Status',
                    'value': EmploymentStatus[props.details.employment_status]
                },
                {
                    'title': 'Employer Name',
                    'value': testMode ? faker.company.companyName() : props.details.employer_name != "" ? props.details.employer_name : "--"
                },
                {
                    'title': 'Employer Nature of Business',
                    'value': testMode ? randomProperty(NatureOfWork) : NatureOfWork[props.details.employer_industry]
                },
                {
                    'title': 'Source of Funds',
                    'value': testMode ? randomProperty(SourceFunds) : SourceFunds[props.details.source_of_funds]
                },
            ]
        },
        {
            'title': 'Identity Verification',
            'code': 4,
            'data': [
                {
                    'title': 'ID Type',
                    'value': testMode ? randomProperty(IDType) : IDType[props.details.id_type]
                },
                {
                    'title': 'ID Number',
                    'value': testMode ? Math.floor(Math.random() * 9999999999) : props.details.id_number
                },
                
                {
                    'title': 'Front ID Photo',
                    'value': <img style={{width: isMobile ? '70%' : '50%', float: isMobile ? 'right' : 'left'}} src={props.details.front_id_image_url} alt="front id"/>
                },
        
                {
                    'title': 'Back ID Photo',
                    'value': props.details.id_type == "PAS" ? "--" : <img style={{width: isMobile ? '70%' : '50%', float: isMobile ? 'right' : 'left'}} src={props.details.back_id_image_url} alt="back id" />
                },
        
                {
                    'title': 'Selfie Photo',
                    'value': <img style={{width: isMobile ? '70%' : '50%', float: isMobile ? 'right' : 'left'}} src={props.details.selfie_image_url}></img>
                },
            ]
        },
        {
            'title': 'Business Information',
            'code': 5,
            'data': [
                {
                    'title': 'Business Name',
                    'value': testMode ? faker.company.companyName() : props.details.business_details.name
                },
                {
                    'title': 'Industry',
                    'value': testMode ? "" : props.details.business_details.industry
                },
                {
                    'title': 'Sub Category',
                    'value': testMode ? "": props.details.business_details.sub_category
                },
                {
                    'title': 'Website',
                    'value': props.details.business_details.web != "" ? props.details.business_details.web : "Add Website"
                },
                {
                    'title': 'Facebook',
                    'value': props.details.business_details.fb != "" ? props.details.business_details.fb : "Add Facebook"
                },
                {
                    'title': 'Instagram',
                    'value': props.details.business_details.ig != "" ? props.details.business_details.ig : "Add Instagram"
                },
            ]
        },
        
    ]

    function allGoodSubmit(){
        let details = {
            step: 6
        }
        setLoading(true)
        submitDetails(details)
    }
    return(
        <div style={{paddingTop: (!isMobile) && props.details.step == 6 && props.details.business_details.name != ""? '16px' : (isAndroid || isMobile) && props.details.step == 6 && props.details.business_details.name != "" ? '32px' : '0px', backgroundColor: '#F4F6F9'}}>
            
            {
                datas.map((item,i)=>{
                    return(
                        <div id={item.code} key={i} >
                        <Card
                            style={{...Style.formCardStyle, marginTop: i == 0 ? '0px' : '20px'}}
                            bodyStyle={{padding: '20px 16px 20px 16px'}}>
                            
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div style={{...Style.headerStyle, 
                                    fontSize: isMobile ? '16px' : '20px', }}>{item.title}
                                </div>
                                {
                                    isMobile ? 
                                    <div hidden={props.details.step == 7 || props.details.step == 8 || props.details.step == 10} style={{...Style.boldText, color: '#F5922F'}} onClick={()=>setStep(item.code)}>EDIT</div>
                                    :
                                    <button 
                                        onClick={()=>setStep(item.code)}
                                        hidden={testMode || props.details.step == 7 || props.details.step == 8 || props.details.step == 10}
                                        className={`outline-btn--${segments} btn-height`}><Icon type="edit" className={`right-4`}/> Edit</button>
                                }
                                
                            </div>

                            <table style={{border: '1px solid #E6EAF0', width: '100%', borderRadius: '4px', marginTop: '20px'}}>
                                <tbody>
                                    {
                                        item.data.map((item,j)=>{
                                            return(
                                                <tr key={j} style={{border: '1px solid #E6EAF0'}}>
                                                    <td>
                                                        <div style={{
                                                            padding: '16px 22px 16px 22px', 
                                                            display:'flex',
                                                            justifyContent: isMobile ? 'space-between' : '',
                                                            width: '100%'
                                                        }}>
                                                            <div style={{...Style.normalText, color: '#000', width: '45%'}}>{item.title}:</div>
                                                            <div style={{...Style.normalText, color: '#000', width: i == 3 && j > 1 ? '55%' : ''}} className="hyphens">{item.value}</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                i  == 0 ? 
                                <div>
                                    <div style={{...Style.normalText, color: '#000', margin: '20px 0px 20px 0px'}}>Present Address</div>
                                
                                    <div style={{border: '1px solid #E6EAF0'}}>
                                        <div style={{
                                            padding: '16px 22px 16px 22px',
                                            width: '100%'
                                        }}>
                                            <div style={{...Style.boldText, color: '#000'}}>
                                                {
                                                    testMode ?
                                                        <div>
                                                            {faker.address.streetAddress()},
                                                            {faker.address.secondaryAddress()},
                                                            {faker.address.cityName()},
                                                            {faker.address.state()},
                                                            PH,
                                                            {faker.address.zipCode()}
                                                        </div>
                                                        :
                                                        <div>
                                                            {props.details.present_address.street},
                                                            {props.details.present_address.barangay},
                                                            {props.details.present_address.city},
                                                            {props.details.present_address.province},
                                                            PH
                                                            {props.details.present_address.postalCode}
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                i == 2 ?
                                <div>
                                    <div style={{...Style.normalText, color: '#000', margin: '20px 0px 20px 0px'}}>Employer Address</div>
                                
                                    <div style={{border: '1px solid #E6EAF0'}}>
                                        <div style={{
                                            padding: '16px 22px 16px 22px',
                                            width: '100%'
                                        }}>
                                            <div style={{...Style.boldText, color: '#000'}}>
                                                {
                                                    testMode ?
                                                        <div>
                                                            {faker.address.streetAddress()},
                                                            {faker.address.secondaryAddress()},
                                                            {faker.address.cityName()},
                                                            {faker.address.state()},
                                                            PH,
                                                            {faker.address.zipCode()}
                                                        </div>
                                                        :
                                                    !(props.details.employment_status.includes("UE")||props.details.employment_status.includes("SE")) ?
                                                        props.details.employer_address.street+", "+props.details.employer_address.barangay+", "+props.details.employer_address.city+", "+props.details.employer_address.province+", PH, "+props.details.employer_address.postalCode
                                                        : "--"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div> : ""
                            }
                            
                        </Card>
                        </div>
                    )
                })
            }
            <div style={{...Style.reminderStyle}} hidden={!isMobile}>
                <Icon type="exclamation-circle" theme="filled" style={{color: '#909196', backgroundColor: '#FFF', borderRadius: 20, fontSize: 16, paddingRight: 8}} />
                <Typography style={{fontSize: 12, color: '#54575F'}}>For iOS users use Safari browser for best experience.</Typography>
            </div>

            <div
                style={{
                width: '100%', 
                position: 'fixed', 
                backgroundColor:'#fff', 
                height: '74px', 
                bottom: '0', 
                marginLeft: '-16px',
                padding:'10px 20px 10px 20px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }} hidden={!isMobile || props.details.step != 6}>
                <Button style={{...Style.submitBtnStyle, width: '100%'}} onClick={()=>allGoodSubmit()} loading={loading}>
                    <div style={{...Style.boldText, color: "#fff", display: 'inline-block', marginLeft: loading ? '20px' : '0px'}}>Submit</div>
                </Button>
            </div>
             
        </div>
    )
}

export default ReviewSubmit