import React, {useEffect} from 'react'
import { isMobile } from 'react-device-detect'
import { Button, Alert, Icon, Divider, message } from "antd";
import OTPInput from "otp-input-react";
import axios from 'axios'
import { history } from '../../store/history';

const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;

const code_expiry_timer = 300;
const max_timer = 60;

const OTPCard = props => {

    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const [OTP, setOTP] = React.useState("");
    const [alert, setAlert] = React.useState(true);
    const [mobileVerified, setMobileVerified] = React.useState(false);
    const [disable, setDisable] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [uid, setUid] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [msg, setMsg] = React.useState('');
    const [counter, setCounter] = React.useState(max_timer);
    const [codeExpiryCounter, setCodeExpiryCounter] = React.useState(code_expiry_timer);
    const [emailOtp, setEmailOtp] = React.useState(false);

    useEffect(() => {
        if(uid == ''){
          setUid(props.otp_uid)
    
          if(props.mobile_verified){
            setMobileVerified(props.mobile_verified)
          }
        }
        const counterInterval = setInterval(() => {
          if(counter>0){
            setCounter(counter - 1);
          }
        }, 1000);

        const codeExpiryInterval = setInterval(() => {
            if(codeExpiryCounter>0){
              setCodeExpiryCounter(codeExpiryCounter - 1);
            }
          }, 1000);
    
        return () => {clearInterval(codeExpiryInterval); clearInterval(counterInterval)};
    }, [codeExpiryCounter, counter, props]);


    const calculateDuration = (duration) => {
        const minutes = Math.floor(duration/60).toString();
        const seconds = Math.floor(duration%60);
        let seconds_str = seconds.toString();
  
        if(seconds < 10){
            seconds_str = "0" +seconds.toString();
        }
  
        return minutes + ":" + seconds_str
    };
  
    async function refetchOTP() {
        try{
            setLoading(true);
            let response = await axios.get(
                process.env.REACT_APP_API_URL + "/api/otp/?id="+props.userid+"&uuid="+props.otp
            );
            setUid(response.data.uid);
            setMobileVerified(response.data.mobile_verified);
            setCounter(max_timer);
            setCodeExpiryCounter(code_expiry_timer)
            setLoading(false);
        }
        catch(error){
            // if((error.response)&&(error.response.status == 401)){
            //     Handle401()
            // }
            message.destroy();
            message.error('An error has occured. Please try again later.');
            setLoading(true);
            setCounter(max_timer);
            setLoading(false);
            console.log(error)

        }
      
    }

    async function submitAPI() {

        let params = {
          uid : uid,
          id: props.userid,
          uuid : props.otp,
          code: OTP
        }
      
        setDisable(true);
        setLoading(true);
      
        try{
            let response = {};
            if(emailOtp){
      
              response = await axios.post(
                  process.env.REACT_APP_API_URL + "/api/email_otp/",params
              );
            }else{
      
              response = await axios.post(
                  process.env.REACT_APP_API_URL + "/api/otp/",params
              );
            }

            if(response.data.status == 'success'){

                let token = response.data.access_token;
                let userDetails = response.data.user_details;

                localStorage.setItem("jwtToken", token);
                localStorage.setItem("changePw", userDetails.prompt_password_change);
                localStorage.setItem("userId", userDetails.id);
                localStorage.setItem("firstName", userDetails.first_name);
                localStorage.setItem("lastName", userDetails.last_name);
                localStorage.setItem("middleName", userDetails.middle_name);
                localStorage.setItem("suffix", userDetails.suffix);
                localStorage.setItem("username", userDetails.username);
                localStorage.setItem("isAlias", userDetails.is_alias);
                localStorage.setItem("merchant_bias", userDetails.merchant_bias);
                localStorage.setItem("email", userDetails.email);
                localStorage.setItem("contact", userDetails.contact);
                localStorage.setItem("balance", userDetails.balance);
                localStorage.setItem("userType", userDetails.user_type)
                localStorage.setItem("isCompleted", userDetails.is_completed)
                localStorage.setItem("isVerified", userDetails.is_verified)
                localStorage.setItem("has_address", userDetails.address)
                localStorage.setItem('Level', userDetails.kyc_level)
                localStorage.setItem("Step", userDetails.kyc_step)
                localStorage.setItem("lastLogin", userDetails.last_login)
                localStorage.setItem("shipping_enabled", userDetails.shipping_enabled)
                localStorage.setItem("admin_711_enabled", userDetails.admin_711_enabled)
                localStorage.setItem("banks_disabled", JSON.stringify(userDetails.banks_disabled))
                localStorage.setItem("channels_disabled", JSON.stringify(userDetails.channels_disabled))
                localStorage.setItem("segments", userDetails.bux_segment.toLowerCase())
                if(localStorage.getItem('voucherModalView' != 'true')) {
                    localStorage.setItem('voucherModalView', false)

                }

                let defined_wallet_settings = {
                    'generate_payment_link_enabled': userDetails.generate_payment_link_enabled,
                    'checkout_enabled': userDetails.checkout_enabled,
                    'add_money_enabled': userDetails.add_money_enabled,
                    'transfer_money_enabled': userDetails.transfer_money_enabled,
                    'payout_enabled': userDetails.payout_enabled,
                    'payout_type': userDetails.payout_type,
                    'link_store_enabled': userDetails.link_store_enabled,
                    'exclusive_banners_enabled': userDetails.exclusive_banners_enabled
                }

                localStorage.setItem("defined_wallet_settings", JSON.stringify(defined_wallet_settings));


                let search = window.location.search;
                let params = new URLSearchParams(search);
                let next = params.get('next');

                localStorage.setItem("GCash_date", userDetails.gcash_created_at)
                
                if('subuser_role' in userDetails){
                    localStorage.setItem("subuser_role", userDetails.subuser_role)
                }
                if('kyb_required' in userDetails){
                    localStorage.setItem("subuser_role", userDetails.subuser_role)
                }
                if(userDetails.bux_segment == 'NIL' && userDetails.user_type !== 'SW'){
                    window.location.href = '/more_about'
                }
                else if(next != null && next.length > 0) {
                  window.location.href = next
      
                }
                else if (userDetails.prompt_password_change) {
                  window.location.href = '/password_expiry'
      
                }
                else{
                    if(testMode){
                        window.location.href = '/test/dashboard'
                    }
                    else {
                        window.location.href = '/dashboard'
                    }
                }
            }
            else{
              setStatus('error');
              setLoading(false);
              setMsg(response.data.message);
              setAlert(false);
      
            }
        }
        catch(error){
            // if((error.response)&&(error.response.status == 401)){
            //     Handle401()
            // }
            setStatus('error');
            setLoading(false);
            if (error.response.data.message){
                setMsg(error.response.data.message);
            } else if (error.response.data.detail){
                setMsg(error.response.data.detail);
            } else {
                setMsg('An error has occured.');
            }
            setAlert(false);
        }
        setDisable(false);
        
    }
  
    async function fetchEmailOtp() {
        setEmailOtp(true);
        try{
            setLoading(true);
            let response = await axios.get(
                process.env.REACT_APP_API_URL + `/api/email_otp/?subject=login&uuid=${props.otp}&id=${props.userid}`
            );
            if(response.status === 'success') {
                setCounter(max_timer);
                setLoading(false);
                setEmailOtp(true);
            } else {
                setLoading(false);
            }
        }
        catch(error){
            // if((error.response)&&(error.response.status == 401)){
            //     Handle401()
            // }
            console.log(error);
            setLoading(false);
        }
    }

    function submitOtp() {
        console.log(OTP);
        submitAPI();
    }
    
    function onOTPChange(val) {
        if (val.length == 6) {
            setDisable(false);
        } else if (val.length < 6) {
            setDisable(true);
        }
        setOTP(val)
    }

    const handleOtpEmail = () => {
        fetchEmailOtp();
    }

    function handleKeyPress(event) {
        if(event.key === 'Enter' && !disable){
            submitOtp();
        }
    }

    return(
        <div id="terms" style={{...style.mainDiv, width: isMobile ? '100%' : isHighReso ? '529px' : '430px', overflowY: 'auto'}}>
            <div align='right'>
                <a style={style.cancel} onClick={()=>window.location.reload()}>Cancel</a>
            </div>
            <div style={{marginTop: '20px'}} align='center'>
                <Alert
                    message={msg}
                    type={status}
                    style={{
                        width: '376px',
                        display: alert ? "none" : "block",
                        borderLeft: status == 'error' ? "4px solid red" : status == 'success' ? "4px solid green" : '' 
                    }}
                />
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                <div>
                    <div style={style.oneTimePin}>One-Time Pin</div>
                    <div style={{...style.text, marginTop: '20px', marginBottom: '20px'}}>
                        {
                            emailOtp ?
                            "You will receive a One-Time Password (OTP) on your registered email address." :
                            <div>
                                A 6-digit code will be sent on your registered number <b>+639*****{props.mobile.substr(props.mobile.length - 4)}</b>.
                            </div>
                        }
                    </div>
                    <OTPInput
                        value={OTP}
                        onChange={(e) => onOTPChange(e)}
                        autoFocus
                        OTPLength={6}
                        otpType="number"
                        style={{justifyContent: 'center'}}
                        inputStyles={{
                            height: 64,
                            width: 38,
                            fontSize: "24px",
                            color: "#2b2d33",
                            fontWeight: "600",
                            background: "#fff",
                            boxSizing: "border-box",
                            border: "0px solid #000",
                            borderRadius: "4px",
                            margin: '0 10px',
                            backgroundColor: '#EEEEEE'
                        }}
                    />
                    <div style={{...style.text, marginTop: '20px'}}>
                        Code will expire in <b>{calculateDuration(codeExpiryCounter)}</b>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
                        <Button style={{...style.submitBtn, opacity: disable ? 0.5 : 1}} onClick={() => submitOtp()} disabled={disable || loading}>Submit</Button>
                    </div>
                    <div style={{...style.resend, opacity: counter == 0 ? 1 : 0.5, marginTop: '20px'}} hidden={loading && !emailOtp || emailOtp}>
                        <a style={{color: '#F5922F'}} onClick={() => {if(counter==0)refetchOTP()}}>Resend Code</a>
                    </div>
                    <div style={{...style.text2, marginTop: '20px'}} hidden={loading && !emailOtp || emailOtp}>
                        <b>“Resend Code”</b> will be enabled after <b>{calculateDuration(counter)}</b>.
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}} hidden={emailOtp || !mobileVerified}>
                        <div style={{width: '270px'}}>
                            <Divider style={{width: '10px'}}>OR</Divider>
                        </div>
                    </div>
                    <div style={{...style.resend, marginTop: '10px'}} hidden={emailOtp || !mobileVerified}>
                        <a style={{color: '#F5922F'}} onClick={handleOtpEmail}>Send OTP code via Email</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

const style = {
    mainDiv : {
        height: '100%',
        borderRadius: !isMobile && '8px',
        backgroundColor: '#fff',
        boxShadow: !isMobile && '0px 8px 24px rgba(0, 35, 11, 0.12)',
        paddingTop: isHighReso ? '50px' : '30px',
        paddingBottom: '30px'
    },
    cancel:{
        fontSize: '16px',
        fontWeight: '500',
        color: '#F5922F',
        marginRight: '30px'
    },
    oneTimePin:{
        display: 'flex', 
        justifyContent: 'center', 
        fontSize: '28px', 
        color: '#000', 
        fontWeight: 'bold'
    },
    text:{
        width: '376px',
        fontSize: '16px', 
        color: '#2b2d33', 
        fontWeight: 'normal',
        textAlign: 'center'
    },
    text2:{
        fontSize: '16px', 
        color: 'rgba(43, 45, 50, 0.64)', 
        fontWeight: 'normal',
        textAlign: 'center'
    },
    submitBtn:{
        width: '308px',
        height: '40px',
        backgroundColor: '#F5922F',
        border: '1px solid #F5922F',
        borderRadius: '4px',
        fontSize: '16px',
        color: '#fff',
        fontWeight: 'bold'
    },
    resend:{
        fontSize: '16px', 
        fontWeight: 'bold',
        textAlign: 'center',
    }
}

export default OTPCard