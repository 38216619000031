import { GetCustomerActionTypes } from "./customerAction";
const initialState = {
  customers: {},
  loading: false,
  error: ""
};
export default function customerReducers(state = initialState, action) {
  switch (action.type) {
    case GetCustomerActionTypes.GET_CUSTOMERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GetCustomerActionTypes.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: action.payload
      };
    case GetCustomerActionTypes.GET_CUSTOMERS_FAILED:
      return {
        ...state,
        loading: false,
        customers: {},
        error: action.payload
      };
    default:
      return initialState;
  }
}