import React, { useState } from "react";
import { usePayouts } from "../api/get-payouts";
import { Table, Tooltip, Typography, Pagination } from "antd";
import {
  UserDataCell,
  StatusChip,
  getStatusInfo,
} from "../../../../../Transfer/components";

function PayoutsList({ searchQuery, selectedStatus, startDate, endDate }) {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const { data, isLoading, error } = usePayouts(
    currentPage,
    searchQuery,
    selectedStatus,
    startDate,
    endDate,
  );

  return (
    <div>
      {error ? (
        <div style={{ padding: "16px", color: "red" }}>
          Error fetching data. Please try again by refreshing the page.
        </div>
      ) : (
        <>
          <Table
            rowClassName="table-row-light"
            dataSource={data ? data.results : []}
            columns={[
              {
                title: "Transfer ID",
                dataIndex: "id",
                key: "id",
                fixed: "left",
              },
              {
                title: "Account",
                dataIndex: "account_name",
                key: "account_name",
                render: (_, record) => (
                  <UserDataCell
                    name={record.account_name}
                    email={record.email}
                  />
                ),
              },
              {
                title: "Merchant Name",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "Bank",
                dataIndex: "bank",
                key: "bank",
              },

              {
                title: "Date Generated",
                dataIndex: "date_paid",
                key: "date_paid",
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (status) => {
                  const statusInfo = getStatusInfo(status);
                  return <StatusChip status={statusInfo} label={status} />;
                },
              },
              {
                title: "Remarks",
                dataIndex: "remarks",
                key: "remarks",
                render: (remarks) => (
                  <Tooltip title={remarks}>
                    <Typography
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: 232,
                      }}
                    >
                      {remarks}
                    </Typography>
                  </Tooltip>
                ),
              },
              {
                title: "Amount",
                dataIndex: "amount",
                key: "amount",
              },
            ]}
            pagination={false}
            loading={isLoading}
            scroll={{ x: true }}
          />
          <Pagination
            size="small"
            total={(data && data.total) || 0}
            defaultPageSize={pageSize}
            pageSize={pageSize}
            current={currentPage}
            onChange={setCurrentPage}
            style={{ paddingTop: "12px", textAlign: "right" }}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
          />
        </>
      )}
    </div>
  );
}

export default PayoutsList;
