import React from 'react'
import {history} from '../../store/history'
import {Icon, Input, Switch, Button, Card} from 'antd'
import AppBar from '../appBar/AppBar'
import masterLogo from '../../static/icons/master_card.svg'
import cvvLogo from '../../static/icons/cvv.svg'


class UpdateCard extends React.Component{

    headerStyle = {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '32px',
        lineHeight: '40px',
        color: '#000000',
        marginTop: '20px'
    }
    labelStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#2b2d33',
    }

    cardNoStyle = {
        fontSize: '26px',
        lineHeight: '32px',
    }

    expiryStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33'
    }

    setAsStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2b2d33',
        marginRight: '20px'
    }

    linkBtn = {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        width: '100%',
        height: '40px',
        marginTop: '20px'
    }
    render(){
        return(
            <div>
                <AppBar/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{width: '35%', padding: '40px'}}>
                        <Icon type="close" style={{float: 'right'}} onClick={()=> history.push('/card_detail/')}/>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={this.headerStyle}>Update Debit Card</div>
                        </div>
                        <Card style={{height: '228px', paddingTop: '140px', marginTop: '20px'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>
                                <div style={this.cardNoStyle}>**** 3238</div>
                                <div style={this.expiryStyle}>06/23</div>
                            </div>
                            <img src={masterLogo} alt="#"/>
                            </div>
                        </Card>
                        <div  style={{display: 'flex', width:'100%', marginTop: '20px', marginBottom: '20px'}}>
                            <div style={{width: '49%', marginRight: '2%'}}>
                            <div style={this.labelStyle}>Expiry date</div>
                            <Input style={{width: '100%', height: '40px'}}/>
                            </div>
                            <div style={{width: '35%'}}>
                            <div style={this.labelStyle}>CVV</div>
                            <Input style={{width: '100%', height: '40px'}}/>
                            </div>
                            <div style={{width: '14%'}}>
                            <img src={cvvLogo} style={{width: '100%', marginTop: '20px'}}/>
                            </div>
                        </div>
                        <span style={this.setAsStyle}>Set as primary</span>  
                        <Switch />
                        <div>
                            <Button type="primary" style={this.linkBtn}>Link Card</Button>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                            <Icon type="lock" style={{marginTop: '4px', marginRight: '10px'}}/>This is a secured connection
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UpdateCard