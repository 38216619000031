import React from 'react';
import { isMobile } from 'react-device-detect';

export const POLICY = [

    {
        q: '',
        d: 
        <div>
            <p>
                DA5 Payment System (​ DAPS​ ) transactions can be sent and picked up at most Direct Agent 5, Inc. (DA5)
                locations and partner-locations nationwide. Customers may call the number listed below for the address
                and hours of nearby locations:
            </p>
            <div style={{display:'flex', justifyContent: 'center'}}>
                <div style={{textAlign: 'center'}}>
                <p>
                (02) 8958195 loc. 1025
                </p>
                <p>
                (+63) 923 669 8344
                </p>
                <p>
                (+63) 935 877 3280
                </p>
                </div>
            </div>
            <p>
                Regular money transfers are usually available within minutes for pick-up by the receiver, subject to the
                opening hours of the receiving location.
            </p>
            <p>
                Money transfers will be paid in cash. All cash payments are subject to availability, receivers showing
                documentary evidence of their identity and providing all details about the money transfer required by a
                <strong> DAPS</strong> partner-merchant, including sender’s and receiver’s names, country of origin, approximate sum,
                MTCN and any other conditions or requirements applicable at the location.
            </p>
            <p>
                The sender authorizes ​<strong>DAPS</strong> to honor the receiver’s choice of method to receive funds even if it differs
                from the sender’s. Cash money transfers shall be paid to the person that our locations deem entitled to
                receive the transaction after verification of identity often through examination of identification documents.
                Such payment can be made even when the form filled out by the receiver contains minor errors.
            </p>
            <p>
                Applicable law prohibits money transmitters from doing business with certain individuals and countries.
                <strong> DAPS</strong> is required to screen all transactions against lists of names provided by our sanctions list database
                such as the US Treasury Department’s Office of Foreign Assets Control (OFAC), European Union (EU)
                and the United Nations (UN). If a potential match is identified, <strong>DAPS</strong> researches the transaction to
                determine if the name matched is the individual on the relevant list. On occasion, customers are required
                to provide additional identification or information, delaying transactions. This is a legal requirement for all
                transactions processed by DAPS (inclusive of transfers that originate and terminate outside of the US).
            </p>
        </div>
        
    },
    {
        q: 'SPECIAL SERVICES',
        d: 
        <div>
            <p>
                <strong>SMS</strong> – Where available, <strong>DAPS</strong> offers free SMS notification to indicate that the transaction has been
                collected by the receiver (for the sender) or that funds are available for collection (for the receiver).
                Charges applied by the service provider are the exclusive responsibility of the sender or receiver. If
                permitted by applicable law, the SMS will be sent to the sender’s and/or receiver’s mobile number they
                have provided. <strong>DAPS</strong> will send SMS messages to a third party gateway for delivery. <strong>DAPS</strong> is not
                responsible for undelivered SMS or technical malfunctions that occur outside of its proprietary systems
            </p>
            <p>
                <strong>ACCOUNT BASED TRANSFERS</strong> – .Where available, the receiver may incur additional fees for receiving
                the sender’s funds through a mobile telephone or to a bank or other account. Transfers should be sent to
                a local (receiver) currency account, otherwise the receiving institution may convert the funds at its own
                exchange rate or reject the transaction. The receiver’s agreement with its mobile phone service, bank or
                other account provider governs the account and determines their rights, liability, fees, funds availability
                and account limitations. In the event that the account number provided (including mobile phone numbers
                for mobile accounts) does not belong to named receiver, the transfer will be credited to the account
                number provided by the sender. <strong>DAPS</strong> may make money from fees associated with use of an account but
                accepts no responsibility to the sender nor to any account holder for any fees, exchange rates used for
                conversion to non-local currency, acts or omissions of the destination or intermediary financial service
                providers.
            </p>
            <p>
                <strong>REFUND</strong> – One of DAPS Services - Western Union will refund the principal amount of a money transfer
                (at the applicable exchange rate described herein in effect at the time the refund is made) upon the
                written request of the sender if payment to the receiver is not made or credited within 45 days. Transfer
                fee refunds are made upon sender’s written request if the money transfer is not available to the receiver
                within the time specified for the selected service, subject to the business hours of, and availability of funds
                at, the location selected for payment and other conditions, including, without limitation, conditions beyond
                the control of Western Union or its Agents, such as inclement weather or telecommunications failure.
                Transfer fees are not refunded if the transfer is stopped at the sender’s request. Payment of some money
                transfers may be delayed as a result of the application of United States or other applicable laws. To the
                extent allowed by law, Western Union may deduct an administrative charge from money transfers that are
                not picked up within one year of the send date.
            </p>
            <p>
                <strong>LIABILITY</strong> – DAPS DOES NOT GUARANTEE THE DELIVERY OR SUITABILITY OF ANY GOODS OR
                SERVICES PAID FOR BY MEANS OF ANY MONEY TRANSFER SERVICE AVAILABLE IN ITS
                SYSTEM. THE SENDER’S TRANSACTION DATA IS CONFIDENTIAL TO HIM AND SHOULD NOT BE
                SHARED WITH ANY OTHER PERSON OTHER THAN HIS RECEIVER. THE SENDER IS CAUTIONED
                AGAINST SENDING MONEY TO ANY PERSON WHOM HE DOES NOT KNOW. IN NO EVENT SHALL
                DIRECT AGENT 5, INC. OR ANY OF ITS SUB-AGENTS, PARTNERS OR LOCATIONS BE LIABLE IF
                THE SENDER COMMUNICATES TRANSACTIONAL DATA TO ANY PERSON OTHER THAN HIS
                RECEIVER. IN NO EVENT SHALL DA5 OR ANY OF ITS SUB-AGENTS, PARTNERS OR LOCATIONS
                BE LIABLE FOR DAMAGES FOR DELAY, NONPAYMENT OR UNDERPAYMENT OF THIS MONEY
                TRANSFER, OR NON-DELIVERY OF ANY SUPPLEMENTAL MESSAGE, WHETHER CAUSED BY
                NEGLIGENCE ON THE PART OF THEIR EMPLOYEES OR AGENTS OR OTHERWISE.
            </p>
            <p>
                IN NO EVENT WILL DIRECT AGENT 5 OR ITS SUB-AGENTS, PARTNERS OR LOCATIONS BE
                LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES.
            </p>
            <p>
                DA5 and its Sub-gents may refuse to provide service to any person.
            </p>
            <p>
                <strong>DATA PROTECTION</strong> – Your personal information is processed under applicable laws and is controlled
                by <strong>DAPS</strong>​. We use personal information you provide to us when using our products and services, as well
                as other information that is collected or generated during our relationship with you. This includes
                information from other services like money transfers, bill payments, loyalty or membership program
                details, previous use of our services history, and marketing choices. This information is used to provide
                you with the services you have asked for and for activities such as administration, customer service,
                anti-money laundering, compliance and legal duties, validation of details, to help us understand our
                consumers by doing analysis and research of the information we hold, to help prevent and detect fraud,
                debt and theft, to help us improve our products, services and operations, and, subject to your choices,send you commercial communications by electronic mail, telephone, SMS and by any other relevant
                channel.
            </p>
            <p>
                <strong>DIRECT AGENT 5, INC​</strong>. may use, collect from and share with other businesses that work with us,
                information from other products and services and convenience and/or rewards programs, for which you
                have registered. This information may be used for any of the purposes in this section. We will hold and
                retain the information that you give us about another person including the details of the receiver of our
                services in order to execute the transaction. Prior to providing this information you are obliged to notify
                and secure authorization from the other person on the use of this information as set out in this section.
                The provision of this information is optional information, but needed to execute the transaction and
                provide these services to you. Without it, ​ <strong>DAPS</strong> is unable to execute the money transfer or payment
                service, facilitate convenience activities or other requested services.
            </p>
            <p>
                We may provide the information we hold to parties located in countries other than the country in which the
                information originally was collected or created, including to affiliates in the United States, for the purposes
                set out in this section. The categories of data transferred include personally identifiable information,
                contact details and information relating to the money transfer, transaction history, and any other
                information supplied by you. We may also provide the information to other organizations and authorized
                service providers, including those that help us run our business, if there is a reasonable need to do so, to
                carry out or aide the money transfer, future services, or for any of the reasons or uses set out in this
                section.
            </p>
            <p>
                We may add to information you provide with information from other businesses or individuals, including
                information to validate the accuracy of your information provided by you. DA5 may disclose your personal
                information, including without limitation your name, customer ID number, address and bank account
                information, (i) if we are required to do so by domestic or foreign law or legal process or (ii) to law
                enforcement authorities or other government officials for purposes such as detecting, investigating,
                prosecuting and preventing crimes, including money laundering and related criminal activity, and the
                recipients may further disclose the information for these and other related purposes.
            </p>
            <p>
                You have a right to ask us to see and get a copy of your information, for which we may charge a small
                fee. You can also correct, erase or limit our use of the information which is incomplete, inaccurate or
                out-of-date. And you may object at any time on legitimate reasons to the use of your information, where
                the processing is not required to complete the service, or required by a law or regulation
            </p>
            <p>
                <strong>CUSTOMER RELATIONS</strong> – Our contract is with the sender. We have no obligations to any other party,
                such as a party the sender sends funds on behalf of. If you are not satisfied with the service you should
                telephone the number below. A representative will investigate your concerns fairly and speedily.
            </p>
            <p>
                Copyright 2020. DIRECT AGENT 5, INC.
            </p>
        </div>
    },
    
]
