import React from "react";
import "./RadioButton.css";

export function RadioButton({ label, name, value, checked, onChange, disabled }) {
  return (
    <label className="rail-radio">
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {label}
    </label>
  );
}
