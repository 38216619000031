import React from 'react';
import { Menu, Dropdown, Icon } from 'antd';
import EditUserModal from '../../modal/editUser';
import ResendModal from '../../modal/resend';
import ChangePasswordModal from '../../modal/changePassword';
import ConvertUserModal from '../../modal/convertUser';
import TagUserToggleModal from '../../tagUserToggleModal';
import { history } from '../../../../../../store/history';
import UserHistoryModal from '../../modal/userHistory';
import CredentialModal from '../../credentialModal';
import EnterpriseSurveyModal from '../../enterpriseSurveyModal';

const UserMenu = (props) => {

    const {
        data, applyBtn, showMenu, setShowMenu,
    } = props;

    const userType = localStorage.getItem("userType");

    const [editModal, setEditModal] = React.useState(false);
    const [resendModal, setResendModal] = React.useState(false)
    const [resendType, setResendType] = React.useState("")
    const [resendTitle, setResendTitle] = React.useState("")
    const [resendContent, setResendContent] = React.useState("")
    const [changePassModal, setChangePassModal] = React.useState(false)
    const [convertUserModal, setConvertUserModal] = React.useState(false)
    const [showTagUser, setShowTagUser] = React.useState(false);
    const [tagType, setTagType] = React.useState(null);
    const [userHistoryModal, setUserHistoryModal] = React.useState(false);
    const [showCredsModal, setShowCredsModal] = React.useState(false);
    const [surveyModalVisible, setSurveyModalVisible] = React.useState(false);
    const [surveyAnswers, setSurveyAnswers] = React.useState(null);

    const hideHistoryBtn = (role) => {
        if(role !== 'Merchant' && role !== 'Corporation') {
          return true;
        }
        return false;
    }

    const buxList = ['OP', 'BS', 'EN', 'SD']
    
    let menu = () => (
        <Menu style={{width: 180}} hidden={!showMenu}>

        </Menu>
    )

    const convertModal = () => {
        setConvertUserModal(true)
        setShowMenu(false)
    }

    const tagUser = () => {
        setShowTagUser(true)
        setTagType('Key Account')
        setShowMenu(false)
    }

    const userHistory = () => {
        setUserHistoryModal(true)
        setShowMenu(false)
    }

    const editKyb = (data) => {
        window.open(`/submissions_kyb/${data.id}/#kyb_form`)
        setShowMenu(false)
    }

    const onSubmit = (type) => {
        setShowMenu(false)
        if(type === "reset_password") {
            setResendTitle("Reset Password")
            setResendContent("Are you sure you want to send code to reset his/her password?")
        }

        else if(type === "resend_verification") {
            setResendTitle("Resend code")
            setResendContent("Are you sure you want to send the verification code again?")
        }

        else if(type === "verify_user") {
            setResendTitle("Verify User")
            setResendContent("Are you sure you want to verify this user?")
        }

        else if(type === "unblock_user") {
            setResendTitle("Unblock User")
            setResendContent("Are you sure you want to unblock this user?")
        }

        else if(type === "logout_user") {
            setResendTitle("Logout User")
            setResendContent("Are you sure you want to logout this user?")
        }

        else if(type === "convert_user") {
            setResendTitle("Convert User")
            setResendContent("Are you sure you want to convert this user?")
        }

        else if(type === "suspend_user") {
            setResendTitle(data.suspended ? "Activate User" : "Deactivate User")
            setResendContent(`Are you sure you want to ${data.suspended ? 'reactivate' : 'deactivate'} this user?`)
        }

        setResendType(type)
        setResendModal(true);
    }

    if(userType === "AD") {
        menu = () => (
            <Menu hidden={!showMenu}>
                 <Menu.Item key="0" className="menu-style" onClick={() => {history.push({pathname: `/admin/user_details/${data.id}/#userInfo`, state: {data: data}}); setShowMenu(false)}}>
                    User Details
                </Menu.Item>
                <Menu.Item key="1" className="menu-style" onClick={() => {setEditModal(true);setShowMenu(false)}}>
                    Edit user
                </Menu.Item>
                <Menu.Item key="2" className="menu-style" onClick={() => onSubmit("reset_password")}>
                    Reset password
                </Menu.Item>
                <Menu.Item key="3" className="menu-style" onClick={() => onSubmit("resend_verification")}>
                    Resend verification
                </Menu.Item>
                <Menu.Item key="4" className="menu-style" onClick={() => {setChangePassModal(true);setShowMenu(false)}}>
                    Change password
                </Menu.Item>
                <Menu.Item key="5" className="menu-style" onClick={() => onSubmit("verify_user")}>
                    Verify user
                </Menu.Item>
                <Menu.Item key="6" className="menu-style" onClick={() => onSubmit("unblock_user")}>
                    Unblock user
                </Menu.Item>
                <Menu.Item key="7" className="menu-style" onClick={() => onSubmit("logout_user")}>
                    Logout user
                </Menu.Item>
                <Menu.Item key="8" className="menu-style" onClick={() => convertModal()}>
                    Convert user
                </Menu.Item>
                <Menu.Item key="9" className="menu-style" onClick={() => onSubmit("suspend_user")} >
                    {data.suspended ? 'Reactivate' : 'Deactivate'}
                </Menu.Item>
                <Menu.Item key="10" className="menu-style" hidden={data.user_role !== 'Corporation'} onClick={() => editKyb(data)}>
                    Edit KYB
                </Menu.Item>
                <Menu.Item key="11" className="menu-style" hidden={data.user_role !== 'Corporation'} onClick={() => tagUser()}>
                    {!data.key_account ? 'Tag as Key Account' : 'Untag as Key Account'}
                </Menu.Item>
                {/* <Menu.Item key="13" hidden={hideHistoryBtn(data.user_role)} className="menu-style" onClick={() => userHistory()} >
                    User History
                </Menu.Item> */}
            </Menu>
        )
    }

    else if(buxList.includes(userType)) {
        menu = () => (
            <Menu hidden={!showMenu}>
                <Menu.Item key="1" hidden={userType !== "BS"} className="menu-style" onClick={() => {setEditModal(true);setShowMenu(false)}}>
                    Edit user
                </Menu.Item>
                <Menu.Item key="1" className="menu-style" onClick={() => onSubmit("reset_password")}>
                    Reset password
                </Menu.Item>
                <Menu.Item key="5" className="menu-style" onClick={() => onSubmit("verify_user")}>
                    Verify user
                </Menu.Item>
                <Menu.Item key="6" className="menu-style" onClick={() => onSubmit("unblock_user")}>
                    Unblock user
                </Menu.Item>
                <Menu.Item key="2" className="menu-style" onClick={() => onSubmit("resend_verification")}>
                    Resend verification
                </Menu.Item>
                <Menu.Item key="3" className="menu-style" onClick={() => {history.push({pathname: `/admin/user_details/${data.id}/#userInfo`, state: {data: data}});setShowMenu(false)}}>
                    User Details
                </Menu.Item>
                <Menu.Item key="4" className="menu-style" onClick={() => convertModal()}>
                    Convert user
                </Menu.Item>
                {
                    userType === "SD" || userType === "BS" &&
                    <Menu.Item key="5" className="menu-style" onClick={() => onSubmit("suspend_user")} >
                        {data.suspended ? 'Reactivate' : 'Deactivate'}
                    </Menu.Item>
                }
                {
                    userType === "SD" || userType === "BS" &&
                    <Menu.Item key="6" className="menu-style" hidden={data.user_role !== 'Corporation'} onClick={() => editKyb(data)}>
                        Edit KYB
                    </Menu.Item>
                }
                <Menu.Item key="7" className="menu-style" hidden={data.user_role !== 'Corporation'} onClick={() => tagUser()}>
                    {!data.key_account ? 'Tag as Key Account' : 'Untag as Key Account'}
                </Menu.Item>
            </Menu>
        )
    }

    return(
        <div>
            {menu()}
            <EditUserModal openModal={editModal} setOpenModal={setEditModal} data={data} />
            <ResendModal title={resendTitle} content={resendContent} 
                        openModal={resendModal} setOpenModal={setResendModal} 
                        type={resendType} data={data} />
            <ConvertUserModal openModal={convertUserModal} setOpenModal={setConvertUserModal} data={data}/>
            <ChangePasswordModal openModal={changePassModal} setOpenModal={setChangePassModal} data={data} />
            <TagUserToggleModal visible={showTagUser} closeModal={() => setShowTagUser(false)} userDetails={data} onSuccess={applyBtn} tagType={tagType} />
            <UserHistoryModal openModal={userHistoryModal} setOpenModal={setUserHistoryModal} data={data} setShowCredsModal={setShowCredsModal} setSurveyModalVisible={setSurveyModalVisible} setSurveyAnswers={setSurveyAnswers}/>
            <CredentialModal visible={showCredsModal} closeModal={() => setShowCredsModal(false)} details={data} />
            <EnterpriseSurveyModal visible={surveyModalVisible} closeModal={() => setSurveyModalVisible(false)} answers={surveyAnswers} details={data}/>

        </div>
    )
}

export default UserMenu;