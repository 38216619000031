import React from 'react';
import { Typography, Card, Row, Col, Button, Icon, message, Spin, Divider, Switch, InputNumber } from 'antd';
import { Grid } from '@material-ui/core';
import { history } from '../../store/history';
import accountSettingsServices from '../../services/account_settings';
import { isMobile } from 'react-device-detect';
import { Messenger } from '../../static/icons/bux_qr';
import multiwalletServices from '../../components/multiwallet/multiwalletServices';

const MultiwalletSettings = (props) => {
    const tradeName = localStorage.getItem('tradeName');
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    let sub_remove_part = window.location.protocol+'//';
    const reactBaseApp = process.env.REACT_APP_BASE_URL.replace(sub_remove_part, "");
    const signupLink = `https://${tradeName}.${reactBaseApp}${testMode ? '/test' : ''}/signup`;
    const max_width = 2000;
    const max_height = 2000;
    const [bannerUrl, setBannerUrl] = React.useState(null);
    let profilePicUpload = React.useRef()
    const [loading, setLoading] = React.useState(false);
    const segments = localStorage.getItem('segments');
    const userType = localStorage.getItem('userType');
    const [checkoutEnabled, setCheckoutEnabled] = React.useState(false);
    const pUid = localStorage.getItem('userId');
    const [editApproverCount, setEditApproverCount] = React.useState(false);
    const [approverCount, setApproverCount] = React.useState(0);

    const isChild = localStorage.getItem('is_child') == 'true';
    const definedWalletSettings = JSON.parse(localStorage.getItem('defined_wallet_settings'));
    const {
        is_maker_approver_enabled: isMakerApproverEnabled = false,
    } = definedWalletSettings;

    const copyLink = (e) => {
        var range = document.createRange();
        range.selectNode(document.getElementById("signupLink"));
        window.getSelection().removeAllRanges(); // clear current selection
        window.getSelection().addRange(range); // to select text
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        message.destroy()
        message.info("Signup link copied")
    }

    async function uploadProfilePic(dataUri) {
        try {
            var blob = dataURItoBlob(dataUri);

            const formData = new FormData();
    
            formData.append('banner', blob, 'id_image.png')
            setLoading(true);
    
            let res = await accountSettingsServices.updateMultiwalletBanner(testMode, formData);
    
            if (res.status === 'success') {
                setBannerUrl(res.url);
                message.success('Banner is updated')
            } else {
                message.error(res.message);
            }
            setLoading(false);
    
        } catch (error) {
            message.destroy();
            message.error('An error has occured. Please try again later.');
            console.log(error);
            setLoading(false);

        }
    }

    const handleUploadImage = (info, type) => {
        // read the files
        var reader = new FileReader();
        reader.readAsArrayBuffer(info);

        reader.onload = function (event) {
            // blob stuff
            var blob = new Blob([event.target.result]); // create blob...
            window.URL = window.URL || window.webkitURL;
            var blobURL = window.URL.createObjectURL(blob); // and get it's URL

            // if (type == "profilePicID") {
            //       setProfilePic(info)
            //       setProfilePicURL(blobURL)
            // }
            // helper Image object
            var image = new Image();
            image.src = blobURL;
            image.onload = function () {
                // have to wait till it's loaded
                var resized = resizeMe(image); // resized image url
                uploadProfilePic(resized)
            }
        };
    }

    function dataURItoBlob(dataUri) {
        var byteString = atob(dataUri.split(',')[1]);
        var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], { type: mimeString });
        return blob
    }

    function resizeMe(img) {

        var canvas = document.createElement('canvas');

        var width = img.width;
        var height = img.height;

        // calculate the width and height, constraining the proportions
        if (width > height) {
            if (width > max_width) {
                //height *= max_width / width;
                height = Math.round(height *= max_width / width);
                width = max_width;
            }
        } else {
            if (height > max_height) {
                //width *= max_height / height;
                width = Math.round(width *= max_height / height);
                height = max_height;
            }
        }

        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        return canvas.toDataURL("image/png", 0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)

        // you can get BLOB too by using canvas.toBlob(blob => {});

    }

    const onLogoRemove = () => {
        var canvas = document.createElement("canvas");

        // set desired size of transparent image
        canvas.width = 200;
        canvas.height = 200;

        // extract as new image (data-uri)
        var url = canvas.toDataURL();
        uploadProfilePic(url);
    }

    const getUserDetails = async () => {
        try {
            const res = await accountSettingsServices.getEnterpriseDetails(testMode, tradeName);
            if (res && res.banner_url) {
                setBannerUrl(res.banner_url);
                setCheckoutEnabled(res.checkout_enabled);
            }
            if (res && res.minimum_approver_count) {
                setApproverCount(parseInt(res.minimum_approver_count));
            }
        } catch (error) {
            console.error(error);
        }
    }

    const pageRedirect = () => {
        if (segments.includes('me_', 'sme') || userType === 'SW') {
            window.location.hash = '#my_checkout';
        }
    }

    const updateCheckoutEnabled = async () => {
        setCheckoutEnabled(!checkoutEnabled);
        let enabled = 0;
        if(!checkoutEnabled){
          enabled = 1;
        }
        try {
            const res = await accountSettingsServices.multiwalletCheckoutToggle(pUid, enabled);
            if (res && res.status !== 'success') {
                message.error('Someting went wrong, try again.');
                setCheckoutEnabled(!checkoutEnabled);
            }
            console.log('TOGGLE', res);
        } catch (error) {
            console.error(error);
            message.error('Someting went wrong, try again.');
            setCheckoutEnabled(!checkoutEnabled);
        }
    }

    const handleApproverCountChange = (value) => {
        setApproverCount(value);
    }

    const onTransferRequestApproverCount = async () => {
        const data = {minimum_approver_count: approverCount};
        try {
            
        const res = await multiwalletServices.updateMinimumApproverCount(data);
        if (res.status === "success") {
            setEditApproverCount(false);
            message.success(res.message);
          } else {
            message.error(res.message);
          }
        } catch (error) {
            message.error("Please try again later.");
        }
    }

    React.useEffect(() => {
        pageRedirect();
        getUserDetails();
    }, [])

    const enableMinimumApproverCountSetting = isMakerApproverEnabled && !isChild;

    return (
        <Card style={styles.root} bodyStyle={styles.cardBody}>
            <Typography style={styles.title}>
                Multi-wallet
            </Typography>
            <div style={styles.body}>
                <div style={styles.table}>
                    <Grid item style={{ ...styles.row, flexDirection: "column", alignItems: 'flex-start', maxWidth: isMobile && '100%' }} hidden={testMode}>
                        <Typography style={styles.label} hidden={testMode}>Signup display logo</Typography>
                        <div style={styles.logoDiv}>
                            {loading ?
                                <Spin size='small' />
                                :
                                <div>
                                    <input type="file"
                                        ref={profilePicUpload}
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleUploadImage(e.target.files[0], "profilePicID")}
                                        accept=".jpg,.png,.jpeg"
                                    >
                                    </input>
                                    <a style={{ ...styles.flex, flexDirection: "row" }} type="file" onClick={() => profilePicUpload.current.click()}>
                                        <img alt='No Image' src={bannerUrl} hidden={!bannerUrl} style={{ height: 32, width: 64 }} />
                                        <span style={{ padding: '0 16px', color: '#54575F', fontSize: 16 }}>{bannerUrl ? 'banner.png' : 'Upload banner'}</span>
                                    </a>
                                </div>}
                            <a className={`link-text--${segments}`}
                                style={styles.replaceBtn}
                                // hidden={false || !bannerUrl || loading} disabled={loading}
                                onClick={() => profilePicUpload.current.click()}>{!bannerUrl ? 'Replace logo' : 'Replace logo'}</a>
                        </div>
                    </Grid>
                    <Grid item style={{ ...styles.row, borderTop: '1px solid #E6EAF0', maxWidth: isMobile && '100%' }}>
                        <div style={{ ...styles.flex, alignItems: 'flex-start', width: '80%' }}>
                            <Typography style={styles.linkSub}>Sub-wallet signup link</Typography>
                            <label className={`link-text--${segments}`} style={{ ...styles.link, padding: 0, width: '100%' }} id='signupLink' value={signupLink}>{signupLink}</label>
                        </div>
                        <button className={`outline-btn--${segments}`} style={styles.copyBtn} onClick={copyLink}>
                            <Icon style={{ paddingRight: 4 }} type={'copy'} theme="filled" />Copy
                        </button>
                    </Grid>
                    <Grid hidden item style={{ ...styles.row, borderTop: '1px solid #E6EAF0', maxWidth: isMobile && '100%', flexDirection: 'column' }}>
                        <div style={{ ...styles.switchDiv, padding: ['me_', 'gig', 'me'].includes(segments) ? 0 : '12px 0 8px 0' }}>
                            <Typography style={styles.tranxSub}>Your Sub-wallet checkout are currently {checkoutEnabled ? 'enabled' : 'disabled'}.</Typography>
                            <Switch size='small' checked={checkoutEnabled} className={checkoutEnabled && checkoutEnabled && `switch-btn--${segments}`}
                                onClick={() => updateCheckoutEnabled()} disabled={loading} />
                        </div>
                        <Typography style={styles.desc}>
                            {checkoutEnabled ? 'Your sub-wallets can view payment request and generate payment link manually or via QR.' : 'Your Sub-wallets does not have access to payment request and cannot generate payment link.'}
                        </Typography>
                    </Grid>
                    {enableMinimumApproverCountSetting && <Grid item style={{ ...styles.row, borderTop: '1px solid #E6EAF0', maxWidth: isMobile && '100%', flexWrap: 'wrap' }}>
                        <div style={{ ...styles.flex, alignItems: 'flex-start' }}>
                            <Typography style={styles.linkSub}>Transfer Money Minimum Approver</Typography>
                            <label hidden={editApproverCount} style={{ ...styles.value }} id='signupLink'>{approverCount || 'No minimum set'}</label>
                        </div>
                        <div hidden={editApproverCount}>
                            <button className={`outline-btn--${segments}`} style={{ ...styles.copyBtn, marginRight: 4 }} onClick={() => setEditApproverCount(true)}>
                                <Icon style={{ paddingRight: 4 }} theme='filled' type={'edit'} />Edit
                            </button>
                        </div>
                        <div hidden={!editApproverCount} style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <InputNumber style={{ height:34 }} type='number' onChange={handleApproverCountChange} min={0} max={3} value={approverCount}/>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                                <button className={`outline-btn--${segments}`} style={{ ...styles.editBtn, margin: '0 4px' }} onClick={() => setEditApproverCount(false)}>
                                    Cancel
                                </button>
                                <button className={`btn--${segments}`} style={{ ...styles.editBtn }} onClick={onTransferRequestApproverCount}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </Grid>}
                </div>
            </div>
        </Card>
    )
}

const styles = {
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        margin: 0
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#2B2D32',
        padding: '32px 32px 0 32px'
    },
    cardBody: {
        // display: 'flex',
        // alignItems: 'center'
        width: '100%',
        padding: 0
    },
    body: {
        padding: '20px 0 0 0'
    },
    row: {
        // border: '1px solid #E6EAF0'
        display: 'flex',
        justifyContent: 'space-between',
        padding: '14px 32px',
        alignItems: 'center'
    },
    table: {
        border: '1px solid #E6EAF0',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textDecoration: 'none',

    },
    label: {
        color: 'rgba(43, 45, 50, 0.8)',
        fontSize: 12,
    },
    copyBtn: {
        cursor: 'pointer',
        height: 34,
        padding: '0 12px',
        margin: 0,
    },
    link: {
        fontSize: 16,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0',
        maxWidth: '100%',
        textDecoration: 'none',
        fontWeight: '500',
        cursor: 'pointer'
    },
    logoDiv: {
        border: '1px solid #B7B9C0',
        margin: '8px 0px',
        padding: 8,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        borderRadius: 4,
    },
    replaceBtn: {
        fontSize: 16,
        fontWeight: '500',
    },
    linkSub: {
        color: '#2B2D32',
        fontSize: 16,
        fontWeight: '500'
    },
    switchDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '24px 0 8px 0',
        width: '100%'
    },
    desc: {
        fontSize: 14,
        color: '#2B2D32',
        opacity: '0.8',
        lineHeight: '20px',
    },
    tranxSub: {
        color: '#2B2D32',
        fontSize: 16,
        fontWeight: '500'
    },
    editBtn: {
        cursor: 'pointer',
        height: 34,
        padding: '0 12px',
        display: 'flex',
        alignItems: 'center'
    },
    value: {
        fontSize: 14,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0',
        maxWidth: '100%',
        textDecoration: 'none',
        fontWeight: '500',
    },
}

export default MultiwalletSettings;